import React from 'react';
import Ic from '..';

const IC246Edit = (args) => (
    <Ic {...args}>
        <path d="M69.66 49.332l-4.606-4.62-31.136 30.954-9.352-9.352L55.8 35.374l-4.62-4.606-35.476 35.35L11 89.4l23.282-4.704M19.4 81l2.352-11.704 9.352 9.338L19.4 81zm51.408-60.76l9.352 9.338-4.228 4.172 4.62 4.634 8.848-8.792L70.808 11l-8.834 8.792 4.62 4.62M54.928 26.878l2.97-2.97L76.48 42.49l-2.97 2.97z" />
    </Ic>
);

IC246Edit.displayName = 'IC246Edit';

export default IC246Edit;
