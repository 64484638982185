// OFFICIAL STYLEGUIDE COLOURS
export const alizarinCrimson = '#ea1c24';
export const athensGray = '#eeeef1';
export const black = '#000';
export const coal = '#0d0f19';
export const geyser = '#dadee6'; // Used on poster, planned to deprecate
export const ironStone = '#2a2c37';
export const kayoGreen = '#06e277';
export const midnight = '#0a0a10';
export const red = '#ff2323';
export const satellite = '#aaafb8';
export const shark = '#202129';
export const silverLining = '#d2d2db';
export const slate = '#aaacb8';
export const white = '#fff';
export const woodsmoke = '#14141a';
export const active = '#088c4d';

// IONIC PALETTE TRANSFERS
export const akela = '#dadbe0';
export const blanc = '#fafafa';
export const moonstruck = '#e6ebf0';
export const poseidon = '#050f23';
export const selene = '#181d2b';
export const vader = '#050a19';

export default {
    alizarinCrimson,
    athensGray,
    black,
    coal,
    geyser,
    ironStone,
    kayoGreen,
    midnight,
    red,
    satellite,
    shark,
    silverLining,
    slate,
    white,
    woodsmoke,
    active,
};
