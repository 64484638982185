import React from 'react';
import Ic from '..';

const IC14Skb = (args) => (
    <Ic {...args}>
        <path d="M7.6 21h5v22.1h6.2V16.8H7.6zM46.5 34.3c0-6.1-5.4-8.6-9.6-8.6-1.8 0-2.8.2-3.7.6l.8-4.7h11.4v-4.7h-16l-2.1 12.9 2.8 1.6c1.2-.8 3.2-1.4 5-1.4 2.5 0 5.2 1.3 5.2 4.4 0 3.2-2.8 4.5-5.2 4.5s-5.2-1.1-6.7-3.2l-2.9 3.6c1.3 1.9 4.6 4.3 10.1 4.3 5.4 0 10.9-3.1 10.9-9.3zM71.8 25.5l9.9-3.6-1.9-5.2-16.5 6c-1.4.5-2.2 2.1-1.7 3.6l6 16.5 5.2-1.9-3.7-10.2c11.1 2.8 18 11.6 17.7 23.1C86.5 67.7 75.9 78 62.1 78h-.5c-8.3-.1-33.4 0-33.7 0v5.6c.3 0 25.3-.1 33.6 0h.6C78.7 83.6 92 70.7 92.4 54c.3-13.9-7.8-24.7-20.6-28.5z" />
    </Ic>
);

IC14Skb.displayName = 'IC14Skb';

export default IC14Skb;
