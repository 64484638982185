import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';

import {mediaQuery, stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {Section, Sup} from '../../../../common/normalized-styled-components';
import {SCREEN_1920_DESKTOP, SCREEN_1024_DESKTOP} from '../../../../common/screen-sizes';
import {TERM_TYPE_SHORT} from '../../../../common/billing-constants';
import {termTypePropType} from '../../../../common/custom-proptypes';
import {blueCharcoal, graphite, smoke, fog, flash, black, white} from '../../../../common/palette';
import splitPrice from '../../../../common/split-price';
import IC31Tick from '../../../atoms/ic/31-tick';

const HeaderBox = styled.div`
    display: flex;
    position: relative;
    border-radius: 6px;
    box-shadow: 0 1px 1px 0 ${rgba(black, 0.3)};
    background-color: ${white};
    padding: 14px;
`;

const PlanInfo = styled.div`
    flex: 2;
`;

const PlanPrice = styled.div`
    position: inherit;
    flex: 0 1 auto;
    margin: auto;
    padding-right: 7px;
    color: ${blueCharcoal};
    font: var(--infinity-header-2-bold);

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        padding-right: 14px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding-right: 24px;
    `}
`;

const Heading = styled.h3`
    margin: 0;
    text-transform: uppercase;
    color: ${blueCharcoal};
    font: var(--infinity-header-7-bold);
`;

const Summary = styled.div`
    color: ${graphite};
    font: var(--infinity-body-copy-4);
`;

const PlanPriceFraction = styled(Sup)`
    position: absolute;
    top: 3px;
    font: var(--infinity-body-copy-4);
    line-height: 1; /* stylelint-disable-line order/properties-order */
`;

const PlanPeriod = styled.span`
    color: ${graphite};
    font: var(--infinity-body-copy-6);
`;

const Checkedmark = styled.span`
    position: absolute;
    top: -11px;
    right: -11px;
    width: 23px;
    height: 23px;
`;

const Wrapper = styled.div`
    border: 1px solid ${smoke};
    border-radius: 6px;
`;

const List = styled.ul`
    margin: 0;
    padding: 19px 14px 20px 45px;
    text-shadow: 0 1px 1px ${rgba(black, 0.3)};
    color: ${rgba(fog, 0.75)};
    font: var(--infinity-body-copy-4-light);

    ${stylesWhen('hasSeparator')`
        border-top: 1px solid ${rgba(smoke, 0.4)};
    `}
`;

const ListItem = styled.li`
    :not(:last-of-type) {
        margin-bottom: 7px;
    }

    > a {
        color: inherit;
        font: var(--infinity-body-copy-4-bold);

        &:focus,
        &:hover {
            text-decoration: none;
        }
    }
`;

const ListPrePostItem = styled(ListItem)`
    list-style-type: none;
`;

const OR49SumPnl = ({
    billingPrice = 0,
    billingPeriod = 'month',
    children,
    className,
    currencyDecimalPlaces = 2,
    currencyPrefix = '$',
    listItems = [],
    listItemsPreNode,
    listItemsPostNode,
    heading = '',
    summary = '',
}) => {
    const [billingPriceUnits, billingPriceFraction] = splitPrice(billingPrice, currencyDecimalPlaces);
    const planPeriodLabel = TERM_TYPE_SHORT[billingPeriod];
    const hasChildren = !!React.Children.count(children);

    return (
        <Section className={classnames('OR49SumPnl', className)}>
            <HeaderBox>
                <PlanInfo>
                    {!!heading && (
                        <Heading>{heading}</Heading>
                    )}
                    {!!summary && (
                        <Summary>{summary}</Summary>
                    )}
                </PlanInfo>
                <PlanPrice hasFraction={!!billingPriceFraction} aria-label={billingPrice ? `${currencyPrefix}${billingPrice} per ${billingPeriod}` : undefined}>
                    {billingPrice ? (
                        <React.Fragment>
                            {currencyPrefix}{billingPriceUnits}

                            {!!billingPriceFraction && (
                                <PlanPriceFraction>
                                    .{billingPriceFraction}
                                </PlanPriceFraction>
                            )}

                            {!!planPeriodLabel && (
                                <PlanPeriod>
                                    /{planPeriodLabel}
                                </PlanPeriod>
                            )}
                        </React.Fragment>
                    ) : 'FREE'}
                </PlanPrice>
                <Checkedmark>
                    <IC31Tick color={flash} secondaryColor={fog} />
                </Checkedmark>
            </HeaderBox>
            {(hasChildren || !!listItems.length) && (
                <Wrapper>
                    {children}
                    {!!(listItems.length || listItemsPreNode || listItemsPostNode) && (
                        <List hasSeparator={hasChildren}>
                            {!!listItemsPreNode && <ListPrePostItem>{listItemsPreNode}</ListPrePostItem>}
                            {listItems.map((descLine, index) => (
                                <ListItem key={index}>
                                    {descLine}
                                </ListItem>
                            ))}
                            {!!listItemsPostNode && <ListPrePostItem>{listItemsPostNode}</ListPrePostItem>}
                        </List>
                    )}
                </Wrapper>
            )}
        </Section>
    );
};

OR49SumPnl.propTypes = {
    /** Contents, like the offer features */
    children: propTypes.node,
    /** Classname if passed down */
    className: classNameType,
    /** Currency decimal places */
    currencyDecimalPlaces: propTypes.number,
    /** Currency prefix */
    currencyPrefix: propTypes.oneOf(['$']),
    /** Heading */
    heading: propTypes.string,
    /** Optional summary to display when the header is sticking or we want to show the summary for any other reason */
    summary: propTypes.node,
    /** Billing price */
    billingPrice: propTypes.number,
    /** Billing period eg. month */
    billingPeriod: termTypePropType,
    /** Terms, other features, etc. */
    listItems: propTypes.arrayOf(propTypes.node),
    /** Node that goes above listItems */
    listItemsPreNode: propTypes.node,
    /** Node that goes below listItems */
    listItemsPostNode: propTypes.node,
};

OR49SumPnl.displayName = 'OR49SumPnl';

export default OR49SumPnl;
