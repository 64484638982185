import React, {useState} from 'react';
import propTypes from 'prop-types';
import noop from 'lodash/noop';
import {rgba} from 'polished';
import styled from 'styled-components';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {Input} from '../../../../common/normalized-styled-components';
import {black, fog, white} from '../../../../common/palette';
import VisuallyHidden from '../../../../common/visually-hidden';

import {IC02Sea, IC135Cross} from '../../ic';

const INPUT_HEIGHT = '38px';

const StyledLabel = styled.label`
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    height: ${INPUT_HEIGHT};
`;

const FocusIndicator = styled.span`
    position: absolute;
    top: 50%;
    right: 7px;
    transform: translateY(-50%);
    transition: ${transition('color')};
    width: 20px;
    height: 20px;
    color: ${({hasFocus}) => hasFocus ? white : fog};

    &:hover {
        color: ${white};
    }
`;

const ClearSearchButton = styled.button`
    appearance: none;
    margin: 0;
    border: 0;
    background: transparent;
    padding: 0;
    color: inherit;

    &:focus {
        outline: 0;
    }
`;

const SearchInput = styled(Input)`
    box-sizing: border-box;
    border: solid ${rgba(white, 0.2)};
    border-width: 0 0 1px;
    background-color: transparent;
    padding: 0 ${INPUT_HEIGHT} 0 7px;
    width: 100%;
    height: 100%;
    text-shadow: 0 1px 1px ${rgba(black, 0.3)};
    color: ${white};
    font: var(--infinity-body-copy-3);

    &::placeholder {
        color: ${rgba(fog, 0.7)};
    }

    &:focus {
        outline: 0;
    }
`;

const IA04InputSea = React.forwardRef(({
    className,
    defaultValue,
    label,
    onChange = noop,
    ...htmlAttributes
}, ref) => {
    const innerRef = ref || React.createRef(); // when useFallbackRef gets moved to a common location, use that instead (its currently in MUI-CTV)
    const [hasFocus, setHasFocus] = useState(false);
    const [hasValue, setHasValue] = useState(!!defaultValue);

    const handleOnChange = (e) => {
        setHasValue(!!e.currentTarget.value.trim().length);
        onChange(e);
    };

    const handleOnClick = (event) => {
        event.preventDefault();

        setNativeValue(innerRef.current, '');

        // We need to fire the onChange handler for input box to reset UI state
        // But clearing the value manually doesn't trigger a 'change' event, so we'll fire one of our own
        innerRef.current.dispatchEvent(new Event('change', {bubbles: true, composed: true}));
    };

    return (
        <StyledLabel className={className}>
            <VisuallyHidden as="span">{label}</VisuallyHidden>
            <SearchInput
                {...htmlAttributes}
                defaultValue={defaultValue}
                onBlur={() => setHasFocus(false)}
                onChange={handleOnChange} // note: React's "onChange" event behaves like "onInput" https://github.com/facebook/react/issues/3964
                onFocus={() => setHasFocus(true)}
                placeholder={label}
                ref={innerRef}
                title={label}
                type="search"
            />
            <FocusIndicator hasFocus={hasFocus}>
                {hasValue
                    ? (
                        <ClearSearchButton
                            title="Clear search"
                            aria-label="Clear search"
                            onClick={handleOnClick}
                            tabIndex="-1" // keyboard users can just press "esc", this button is redundant so remove the tabstop
                            type="reset" // so this button works before JS mount, if need be
                        >
                            <IC135Cross />
                        </ClearSearchButton>
                    )
                    : <IC02Sea size="25px" />
                }
            </FocusIndicator>
        </StyledLabel>
    );
});

IA04InputSea.propTypes = {
    /** Additional class(es) */
    className: classNameType,
    /** If supplied, will be initial value of the search field */
    defaultValue: propTypes.string,
    /** Placeholder label for search box, which disappears on text input */
    label: propTypes.string.isRequired,
    /** Callback on search field value change */
    onChange: propTypes.func,
};

IA04InputSea.displayName = 'IA04InputSea';

export default IA04InputSea;

// dispatchEvent on input/textarea is ignored
// https://github.com/facebook/react/issues/10135#issuecomment-314441175
function setNativeValue(element, value) {
    const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set;
    const prototype = Object.getPrototypeOf(element);
    const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set;

    if (valueSetter && valueSetter !== prototypeValueSetter) {
        prototypeValueSetter.call(element, value);
    } else {
        valueSetter.call(element, value);
    }
}
