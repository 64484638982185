import React from 'react';

import Ic from '..';

const IC257Discover = (args) => (
    <Ic {...args}>
        <path d="M49.636 21.455c.502 0 .91-.489.91-1.091V17.09c0-.603-.408-1.091-.91-1.091s-.909.488-.909 1.09v3.274c0 .602.407 1.09.91 1.09zm0 56.363c-.502 0-.909.489-.909 1.091v3.273c0 .602.407 1.09.91 1.09.501 0 .908-.488.908-1.09v-3.273c0-.602-.407-1.09-.909-1.09zm32.546-29.091h-3.273c-.602 0-1.09.407-1.09.91 0 .501.488.908 1.09.908h3.273c.602 0 1.09-.407 1.09-.909s-.488-.909-1.09-.909zm-61.818 0H17.09c-.603 0-1.091.407-1.091.91 0 .501.488.908 1.09.908h3.274c.602 0 1.09-.407 1.09-.909s-.488-.909-1.09-.909zm7.39-19.414c.215.216.497.323.78.323.282 0 .564-.107.78-.323.43-.43.43-1.128 0-1.56l-2.34-2.339c-.43-.43-1.13-.43-1.56 0-.43.43-.43 1.129 0 1.56l2.34 2.34zm0 40.647l-2.34 2.339c-.43.43-.43 1.129 0 1.56.215.215.497.323.78.323.282 0 .564-.108.78-.323l2.34-2.34c.43-.43.43-1.129 0-1.56-.43-.43-1.129-.43-1.56 0zM70.74 29.636c.281 0 .564-.107.779-.323l2.34-2.34c.43-.43.43-1.128 0-1.559-.43-.43-1.13-.43-1.56 0l-2.34 2.34c-.43.43-.43 1.129 0 1.56.216.215.498.322.78.322zm-.13 35.021c-.19-.208-.455-.332-.736-.344-.267-.006-.532.08-.736.254l-2.332-2.43c2.577-3.48 4.103-7.777 4.103-12.43 0-11.587-9.442-20.98-21.09-20.98-11.65 0-21.092 9.393-21.092 20.98 0 11.586 9.443 20.978 21.091 20.978 4.207 0 8.122-1.23 11.413-3.34l2.302 2.396-.184.165.012.014c-.325.395-.323.975.031 1.365l9.965 10.954c.624.666 1.488 1.034 2.432 1.034.91 0 1.79-.345 2.477-.971l1.995-1.82c.72-.655 1.154-1.57 1.19-2.51.036-.882-.272-1.715-.853-2.333L70.61 64.657zm-39.774-14.95c0-10.412 8.515-18.882 18.982-18.882 10.467 0 18.982 8.47 18.982 18.881s-8.515 18.881-18.982 18.881c-10.467 0-18.982-8.47-18.982-18.88zm32.125 16.401c.895-.71 1.734-1.489 2.502-2.333l2.188 2.28-2.55 2.282-2.14-2.229zm15.876 12.828l-1.996 1.819c-.297.27-.67.42-1.052.42-.352 0-.668-.13-.879-.355l-9.246-10.164 4.165-3.73 9.217 10.132c.263.28.307.607.299.832-.016.384-.2.765-.508 1.046z" />
        <path d="M49.182 34.182c-8.786 0-15.91 7.123-15.91 15.909 0 8.786 7.124 15.909 15.91 15.909 8.786 0 15.909-7.123 15.909-15.91 0-8.785-7.123-15.908-15.91-15.908zm0 29.697c-7.603 0-13.788-6.186-13.788-13.788 0-7.603 6.185-13.788 13.788-13.788 7.602 0 13.788 6.185 13.788 13.788 0 7.602-6.186 13.788-13.788 13.788z" />
        <path d="M56.34 41.528l-10.196 3.869c-.248.094-.451.273-.57.502l-4.917 9.525c-.198.382-.128.84.175 1.154.208.215.498.331.793.331.134 0 .27-.024.399-.073L52.22 52.97c.248-.094.451-.274.57-.502l4.917-9.526c.197-.383.128-.842-.175-1.155-.303-.313-.777-.417-1.192-.258zm-9.602 6.779l3.392 3.237-6.297 2.388 2.905-5.625zm4.888 1.75l-3.392-3.236 6.296-2.389-2.904 5.626z" />
    </Ic>
);

IC257Discover.displayName = 'IC257Discover';

export default IC257Discover;
