import bacon from 'baconjs';

import {getApiFromRetryWithError} from '@fsa-streamotion/request-manager';

import {responseConsideredValidWith404} from '../../response-considered-valid';
import {RESPONSE_CODE} from '../../../utils/constants';

export const KAYO_RESOURCES_URL = {
    production: 'https://resources.kayosports.com.au/production',
    staging:    'https://resources.kayosports.com.au/staging',
};

/**
 * Our MVP implementation of a sports nav menu. Based on JSON endpoints
 *
 * Example URL
 * https://resources.kayosports.com.au/staging/sport-menu/lists/afl/1.json
 *
 * @param  {Object} options                   - see below
 * @param  {string} [options.sport='default'] - API sport ID e.g. football
 * @param  {string} [options.series]          - API series ID e.g. 1
 * @param  {string} [options.resourcesEnv]    - API env e.g. prod
 *
 * @returns {Object}                          - Returns object of sport menu items
 */
export default function sportMenu({
    sport = 'default',
    series,

    resourcesEnv,
}) {
    const url = `${KAYO_RESOURCES_URL[resourcesEnv]}/sport-menu/lists/${sport}${series ? `/${series}` : ''}.json`;

    return getApiFromRetryWithError({
        url,
        responseConsideredValidWith404,
    })
        .flatMapLatest((response) => {
            if (response.responseConsideredValid) {
                return response.parseResponseText();
            } else if (response.responseStatus === RESPONSE_CODE.notFound) {
                return []; // some sports may have a missing config. don't panic!
            } else {
                return new bacon.Error(
                    `Accounts-Widgets: couldn't get sports menu data (${url}): ${response.responseStatus} ${response.superAgentErr}`
                );
            }
        });
}
