import React from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

const StyledSection = styled.section`
    width: 100%;
`;

const OR02Stand = ({children, className}) => (
    <StyledSection className={classNames('OR02Stand', className)}>
        {children}
    </StyledSection>
);

OR02Stand.displayName = 'OR02Stand';

OR02Stand.propTypes = {
    /** Additional Class(es) */
    className: classNameType,
    /** Children components */
    children: propTypes.node,
};

export default OR02Stand;
