import bacon from 'baconjs';
import superagent from 'superagent';

import {BILLING_API_URL} from '@fsa-streamotion/streamotion-web-widgets-common';

import stringableBaconError from '../../../utils/stringable-bacon-error';

/**
 * Verify a custom 'journey' workflow.
 *
 * @see [Swagger Docs: Validate](https://billingapi-staging.streamotion.com.au/api-doc/index.html#/Validation/validateUsingPOST)
 * @see [Validate (note: requires bearer token)](https://billingapi-staging.streamotion.com.au/validate)
 *
 * @param  {Object} options (see below)
 * @param  {string} options.accessToken the user's access token
 * @param  {string} options.platformEnv billing API environment, e.g. production
 * @param  {string} options.voucherCode voucher code entered by the user
 * @param  {string} options.ratePlanId price id of the chosen plan
 * @param  {string} options.journeyDetails user entered details specific to this journey e.g. for Qantas: {qffn, surname}
 *
 * @returns {bacon.Observable} stream containing response from endpoint
 */
export default function journeyDetailsValidate({
    accessToken,
    platformEnv,
    voucherCode,
    ratePlanId,
    journeyDetails,
}) {
    const url = `${BILLING_API_URL[platformEnv]}/validate`;

    return bacon.fromBinder((sink) => {
        superagent
            .post(url)
            .set('Authorization', `Bearer ${accessToken}`)
            .accept('json')
            .send({
                voucherCode,
                ratePlanId,
                journeyDetails,
            })
            .then((response) => void sink(response?.body))
            .catch((error) => {
                const errorBody = JSON.parse(error?.response?.text || '""');

                sink(stringableBaconError({
                    code: errorBody.body?.code,
                    status: error.status,
                    message: errorBody.body?.longMessage
                        || errorBody.body?.message
                        || errorBody.body
                        || 'Failed to verify your details. Please retry shortly.',
                    debugMessage: `Couldn't POST journey details (voucherCode: ${voucherCode}, ratePlanId: ${ratePlanId}, journeyDetails: ${journeyDetails}) (${url}): ${error.status} ${error.message}`,
                }));
            })
            .finally(() => void sink(new bacon.End()));

        return;
    });
}
