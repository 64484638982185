import React from 'react';
import bacon from 'baconjs';

import journeyDetailsValidate from '../../../../../todo-move-to-widgets-common/streams/endpoints/billing/journey-details-validate';
import QantasJourney from '../../../components/branded/checkout/journey/qantas';

export default function journeyQantas({
    platformEnv,
    ratePlanId,
    user,
    voucherCode,
    brand,
}) {
    const clearErrorMessageBus = new bacon.Bus();
    const journeySkipBus = new bacon.Bus();
    const validateJourneyBus = new bacon.Bus();

    const journeyDetailsValidated$ = validateJourneyBus
        .map(async (qantas) => ({
            platformEnv,
            accessToken: await user.getAccessToken(),
            voucherCode,
            ratePlanId,
            journeyDetails: {
                qantas,
            },
        }))
        .flatMapLatest(bacon.fromPromise)
        .flatMapLatest((args) => journeyDetailsValidate(args).map(args.journeyDetails)) // Success has no body/value/anything at this stage so map `journeyDetails` cause it ends up in subscribe call at the end.
        .toProperty(null);

    const journeyDetailsError$ = bacon.mergeAll(
        validateJourneyBus.map(undefined),
        clearErrorMessageBus.map(undefined),
        journeyDetailsValidated$.map(undefined),
        journeyDetailsValidated$
            .errors()
            .mapError('.message')
    )
        .toProperty(undefined);

    // If the API errors, and says with a code we can skip, we can skip.
    // But not before we try once and see that message.
    // Any change to text fields, and we shouldn't be able to skip again until next validate.
    const canSkip$ = bacon.mergeAll(
        clearErrorMessageBus.map(false),  // clear ability on a change in any field
        validateJourneyBus.map(false), // clear ability when checking validate again

        journeyDetailsValidated$
            .errors()
            .mapError(({code} = {}) => code === 'journey.not.applicable.skip')
    ).toProperty(false);

    const journeyDetails$ = bacon.mergeAll(
        journeyDetailsValidated$,

        validateJourneyBus
            .toProperty() // Keep the last known attempted validated stuff
            .sampledBy(journeySkipBus) // Let it through when someone hits skip
            .map((journeyDetailsNotValidated) => ({
                qantas: journeyDetailsNotValidated,
            }))
    ).toProperty(null);

    const isJourneyCompleted$ = journeyDetails$
        .map('.qantas')
        .map(Boolean)
        .skipDuplicates()
        .startWith(false);

    const isLoading$ = bacon.mergeAll(
        validateJourneyBus.map(true),
        journeyDetails$.map(false),
        journeyDetailsError$.filter(Boolean).map(false)
    )
        .toProperty(false)
        .skipDuplicates();

    const isSkipped$ = journeySkipBus
        .map(Boolean)
        .startWith(false);

    const journeyComponent$ = bacon.combineTemplate({
        isCompleted: isJourneyCompleted$,
        isLoading: isLoading$,
        canSkip: canSkip$,
        isSkipped: isSkipped$,

        acceptedQffn: journeyDetails$.map('.qantas.qffn'),
        acceptedSurname: journeyDetails$.map('.qantas.surname'),

        errorMessage: journeyDetailsError$,

        onChange: () => void clearErrorMessageBus.push(),
        onClickEdit: () => void journeySkipBus.push(),
        onSkip: () => void journeySkipBus.push(true),
        onSubmit: (details) => void validateJourneyBus.push(details),
    })
        .map((props) => (
            <QantasJourney
                brand={brand}
                key="qantas"
                {...props}
            />
        ));

    return bacon.combineTemplate({
        // We always show either summary or the active form asking for details
        isJourneyShown: true,

        journeyComponent: journeyComponent$,
        isJourneyCompleted: isJourneyCompleted$,
        journeyDetails: journeyDetails$,
    });
}
