import React, {useEffect, useState, Fragment} from 'react';
import clamp from 'lodash/clamp';
import styled from 'styled-components';
import propTypes from 'prop-types';
import cls from 'classnames';
import {rgba} from 'polished';

import {mediaQuery, stylesIfElse} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {IC247FAQPlus, IC248FAQMinus} from '../../../atoms/ic';
import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import {ink, black, hubblGreen} from '../../../../common/palette';

const Wrapper = styled.div`
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    border-radius: 48px;
    box-shadow: 0 1px 1px 0 ${rgba(black, 0.3)};
    background: ${rgba(ink, 0.1)};
    padding: 4px 5px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding: 5.4px 6.8px;
        border-radius: 62px;
    `}
`;

const ValueWrapper = styled.div`
    display: flex;
    margin: 0 14px;
    color: ${ink};
    font: var(--quicksilver-body-copy-3);

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        font-size: 22px;
    `}
`;

const ValueText = styled.div`
    /*
     * avoid change number make the size of the picker change
     * because each number have diff size exp: '1' is 10px, but '2' is '13px'
     * and for now we only apply for single digit
    */
    min-width: 10px;
    text-align: center;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        min-width: 14px;
    `}
`;

const CircleButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 100%;
    box-shadow: 0 1px 1px 0 ${rgba(black, 0.3)};
    background: ${rgba(ink, 0.1)};
    cursor: pointer;
    padding: 10px;
    width: 40px;
    height: 40px;
    color: ${ink};

    ${stylesIfElse('disabled')`
        background: ${rgba(ink, 0.03)};
        cursor: not-allowed;
    ``
        &:hover {
            background: ${hubblGreen};
        }
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 54px;
        height: 54px;
        padding: 13.5px;
    `}
`;

// eslint-disable-next-line
/**
 * Quantity Picker
 *
 * @see {@link https://zpl.io/AmXJ0nB}
 */
const GM21QtyPicker = ({
    className,
    defaultValue = 1,
    isDisabled,
    maxValue = 5,
    minValue = 1,
    onChange,
    prefix = 'Qty',
    value,
}) => {
    const clampValue = (newValue) => clamp(newValue, minValue, maxValue);

    const [innerValue, setInnerValue] = useState(clampValue(defaultValue));

    useEffect(() => {
        // allow the inner value controlled by outer value
        if (value !== undefined) {
            setInnerValue(value);
        }
    }, [value]);

    const increaseValue = () => {
        setInnerValue((oldValue) => {
            const newValue = clampValue(oldValue + 1);

            if (onChange) {
                onChange(newValue);
            }

            return newValue;
        });
    };

    const decreaseValue = () => {
        setInnerValue((oldValue) => {
            const newValue = clampValue(oldValue - 1);

            if (onChange) {
                onChange(newValue);
            }

            return newValue;
        });
    };

    return (
        <Wrapper className={cls('GM21QtyPicker', className)}>
            <CircleButton
                type="button"
                aria-label="decrease quantity by 1"
                onClick={decreaseValue}
                disabled={isDisabled || innerValue <= minValue}
            >
                <IC248FAQMinus />
            </CircleButton>

            <ValueWrapper>
                {!!prefix && <Fragment>{prefix}&nbsp;</Fragment>}
                <ValueText>{innerValue}</ValueText>
            </ValueWrapper>

            <CircleButton
                type="button"
                aria-label="increase quantity by 1"
                onClick={increaseValue}
                disabled={isDisabled || innerValue >= maxValue}
            >
                <IC247FAQPlus />
            </CircleButton>
        </Wrapper>
    );
};

GM21QtyPicker.propTypes = {
    /** additional CSS classnames to be applied */
    className: classNameType,
    /** allow uncontrolled value but have initial value we want */
    defaultValue: propTypes.number,
    /** to disable both increase and descrease buttons */
    isDisabled: propTypes.bool,
    /** max value allowed */
    maxValue: propTypes.number,
    /** min value allowed */
    minValue: propTypes.number,
    /** allow controlled value by higher component */
    value: propTypes.number,
    /** text before the quantity count default is `Qty` */
    prefix: propTypes.string,
    /** callback on value change */
    onChange: propTypes.func,
};

GM21QtyPicker.displayName = 'GM21QtyPicker';

export default GM21QtyPicker;
