import React from 'react';
import Ic from '..';

const IC229Settings = (args) => (
    <Ic {...args}>
        <defs>
            <linearGradient x1="44.358%" y1="94.276%" x2="60.035%" y2="45.662%" id="ic229-settings__linear-gradient">
                <stop stopColor="currentColor" stopOpacity="0" offset="0%" />
                <stop stopColor="currentColor" offset="100%" />
            </linearGradient>
        </defs>
        <g fill="none" fillRule="nonzero">
            <path
                d="M53.075 37.605c7.126.875 12.193 7.361 11.318 14.487-.875 7.127-7.36 12.194-14.487 11.32-7.126-.876-12.194-7.362-11.319-14.488.875-7.126 7.361-12.194 14.488-11.32Zm-.61 4.963a8 8 0 1 0-1.95 15.88 8 8 0 0 0 1.95-15.88Z"
                fill="currentColor"
            />
            <path
                d="m29.875 27.216 3.8 3.25-.63.73-.548.617-.318.35-.802.87-8.349-.488-4.148 9.77 6.14 5.661-.068 1.18a26.465 26.465 0 0 0-.041 1.697l.01.595.028 1.157-6.253 5.584 3.907 9.775 8.35-.367.79.88c.382.427.774.837 1.172 1.226l.4.382.866.806-.53 8.387 9.657 4.1 5.65-6.2 1.183.063c.579.03 1.182.038 1.853.025l.412-.01 1.172-.036 5.528 6.253 9.758-3.976-.28-7.22 4.995-.195.418 10.723-16.286 6.638-6.34-7.172-.288-.001-6.466 7.095-16.178-6.867.605-9.596-.096-.095-.108-.112-9.562.42-6.525-16.321 7.159-6.395.004-.328-7.013-6.468 6.9-16.255 9.48.554.592-.686Z"
                fill="currentColor"
            />
            <path
                d="m72.824 28.455 8.314-2.507 7.763 16.172-6.693 6.883.019.316 7.466 5.956-5.75 16.7-9.553.11-.08.094c-.149.17-.3.338-.455.508l-.474.51-3.63-3.438c.415-.438.8-.87 1.16-1.297l.35-.427.74-.923 8.36-.096 3.455-10.033-6.533-5.21-.015-1.186a24.99 24.99 0 0 0-.079-1.688l-.052-.572-.118-1.16 5.864-6.03-4.4-9.166-7.067 2.132-2.89-2.87 3.522-3.548.776.77Z"
                fill="url(#ic229-settings__linear-gradient)"
                transform="rotate(4 79.11 49.573)"
            />
            <path
                d="m52.115 18.616 6.482-7.1 16.177 6.866-.606 9.597.25.255.378.399.386.42-3.7 3.362a28.475 28.475 0 0 0-1.33-1.378l-.255-.242-.865-.806.529-8.388-9.659-4.1-5.666 6.208-1.181-.063c-.549-.029-1.11-.039-1.678-.03l-.57.015-1.183.044-5.537-6.263-9.757 3.977.322 8.4-.863.782c-.427.387-.838.784-1.227 1.187l-.383.405-3.675-3.39c.302-.327.614-.65.936-.968l.127-.125-.367-9.599 16.285-6.637 6.339 7.17.29.002Z"
                fill="currentColor"
            />
        </g>
    </Ic>
);

IC229Settings.displayName = 'IC229Settings';

export default IC229Settings;
