import {rgba} from 'polished';
import {Section} from 'normalized-styled-components';
import propTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import round from 'lodash/round';

import {colorType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {white, silverLining, ironStone} from '../../../../common/palette';
import IM02DurSldr from '../../../molecules/im/02-dur-sldr';

const Card = styled.div`
    box-sizing: border-box;
    background-color: ${rgba(ironStone, 0.7)};
    padding: 35px 14px;
    height: 100%;
`;

const StyledSection = styled(Section)`
    margin-bottom: 25px;
`;

const SectionTitle = styled.h2`
    margin: 0 0 7px;
    text-align: center;
    color: ${white};
    font: var(--mui-header-7-bold);
`;

const TextStatRow = styled.dl`
    display: flex;
    justify-content: space-between;
    margin: 0 0 18px;
`;

const Title = styled.dt`
    flex-shrink: 0;
    margin: 0;
    padding: 0 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${silverLining};
    font: var(--mui-hud-tile-copy-light);
`;

const StatValue = styled.dd`
    margin: 0;
    color: ${silverLining};
    font: var(--mui-hud-tile-copy-bold);
`;

const OR126H2hBlock = ({
    sections = [],
    sport,
    teamA = {},
    teamB = {},
    getTeamColor,
    teamADefaultColor,
    teamBDefaultColor,
    className,
    onClickOdds,
    onClickLink,
    imageUrlToSrcsetOptions,
    ...htmlAttributes
}) => {
    const teamAColor = getTeamColor({sport, id: teamA.id, fallbackColor: teamADefaultColor});
    const teamBColor = getTeamColor({sport, id: teamB.id, fallbackColor: teamBDefaultColor});

    return (
        <Card className={classnames('OR126H2hBlock', className)} {...htmlAttributes}>
            {sections.map((section, index) => (
                <StyledSection key={`h2h-blk-${index}`}>
                    <SectionTitle>{section.title}</SectionTitle>
                    {section.stats.map((stat, index) => (
                        (stat.type === 'string'
                            ? (
                                <TextStatRow key={index}>
                                    <Title style={{order: 1}}>{stat.title}</Title>
                                    <StatValue style={{order: 0}}>{stat.left}</StatValue>
                                    <StatValue style={{order: 2, textAlign: 'right'}}>{stat.right}</StatValue>
                                </TextStatRow>
                            ) : (
                                <IM02DurSldr
                                    key={`h2h-${index}`}
                                    title={stat.title}
                                    teamAValue={round(stat.left, 1)}
                                    teamBValue={round(stat.right, 1)}
                                    teamAColor={teamAColor}
                                    teamBColor={teamBColor}
                                    teamAName={teamA.name}
                                    teamBName={teamB.name}
                                />
                            ))
                    ))}
                </StyledSection>
            ))}
        </Card>
    );
};

const teamShape = propTypes.shape({
    id: propTypes.number,
    name: propTypes.string,
});

OR126H2hBlock.propTypes = {
    sport: propTypes.string,
    teamA: teamShape,
    teamB: teamShape,
    sections: propTypes.arrayOf(propTypes.shape({
        title: propTypes.string,
        stats: propTypes.arrayOf(propTypes.shape({
            title: propTypes.string,
            type: propTypes.oneOf(['number', 'string', 'decimal', 'integer']),
            left: propTypes.oneOfType([propTypes.number, propTypes.string]),
            right: propTypes.oneOfType([propTypes.number, propTypes.string]),
        })),
    })),
    /** Function that returns a color given a sport and team ID.
     * Accepts an object of {sport: 'my-sport', id: 'my-team-id', fallbackColor: 'my-fallback-color'} */
    getTeamColor: propTypes.func,
    /** Color to use for team A if unable to retrieve a color */
    teamADefaultColor: colorType,
    /** Color to use for team B if unable to retrieve a color */
    teamBDefaultColor: colorType,
    /** Optional extra class name(s) for the component */
    className: classNameType,
    /* Intentionally ignored props to sanitize htmlAttributes */
    onClickOdds: propTypes.any,
    onClickLink: propTypes.any,
    imageUrlToSrcsetOptions: propTypes.any,
};

OR126H2hBlock.displayName = 'OR126H2hBlock';

export default OR126H2hBlock;
