import bacon from 'baconjs';
import {getApiFromRetryWithError} from '@fsa-streamotion/request-manager';

import {responseConsideredValidWith404} from '../../../../../../todo-move-to-widgets-common/streams/response-considered-valid';

import AEM_URL from '../temp-envs';

const AEM_MODEL = {
    kayo: 'kayo-landing-page',
    binge: 'binge-landing-page',
};

/**
 * Fetch AEM (Adobe Experience Manager) Config for Landing Page
 *
 *  https://content-dev.streamotion.com.au/graphql/execute.json/magneto/Get-List-by-Model;model=web-sales-glasstv-customise;source=web
 *
 * @param {Object} options options object
 * @param {string} options.brand change the config we grab based on the site we're on
 * @param {string} options.landingType determines the landing config to grab, ie. one of home, puck, or tv
 * @param {string} options.platformEnv platform environment e.g. staging
 * @param {string} options.marketingConfigName marketing config parameter, to signify a different variant of the landing page
 * @returns {bacon.Observable} Stream containing the payload returned at the path
 */
export default function getLandingAemConfigStream({
    brand,
    landingType,
    platformEnv,
    marketingConfigName = 'default',
}) {
    const path = encodeURIComponent(AEM_MODEL[brand]);

    const url = new URL(`${AEM_URL[brand][platformEnv]}${path}${marketingConfigName ? `;pg=${marketingConfigName}` : ''}`);

    return getApiFromRetryWithError({
        url: url.toString(),
        validateResponse: responseConsideredValidWith404,
    })
        .first() // Force it to close, as getApiFromRetryWithError doesn't close by default
        .flatMapLatest((response) => {
            // if there was no superAgent error (responseConsideredValid) in calling the api AND the aem response is not empty items;
            // this second bit is added because an aem response with empty items array is still a 200 but we want that to error
            if (response.responseConsideredValid && response.parseResponseText()?.data?.catalogModuleList?.items.length !== 0) {
                try {
                    return response.parseResponseText();
                } catch (err) {
                    return new bacon.Error({
                        message: `ACC-Widgets: Failed to parse response of AEM Landing ${landingType} (${url})`,
                        err,
                    });
                }
            } else {
                return new bacon.Error({
                    message: `ACC-Widgets: couldn't get AEM config for Landing ${landingType} (${url}): ${response.responseStatus} ${response.superAgentErr}`,
                    statusCode: response.responseStatus,
                });
            }
        });
}
