import React from 'react';

import Ic from '..';

const IC15Skf = (args) => (
    <Ic {...args}>
        <path fillRule="nonzero" d="M63.958 42.55h-3.759V18.796h-5.408V16h9.167zm16.685.42a10.33 10.33 0 0 1-9.083-4.458l2.166-2.152a8.09 8.09 0 0 0 6.889 3.536c3.116 0 6.4-1.845 6.4-5.87 0-4.024-3.242-5.883-6.4-5.883a8.119 8.119 0 0 0-4.89 1.398l-1.957-1.118L76.17 16h13.876v2.99h-11.08l-1.398 6.987a10.02 10.02 0 0 1 3.926-.698c4.472 0 9.405 2.878 9.405 8.692 0 5.813-5.045 8.999-10.257 8.999zM23.556 22.593l6.107 2.295C17.12 30.763 10.176 44.417 12.82 58.015c2.643 13.598 14.195 23.657 28.027 24.405h16.126V76.3H41.175a24.102 24.102 0 0 1-9.062-45.825l-2.22 6.084a3.087 3.087 0 0 0 1.818 3.95 3.071 3.071 0 0 0 3.95-1.795l5.969-15.847-15.976-5.964a3.017 3.017 0 0 0-3.885 1.783 3.047 3.047 0 0 0 1.787 3.907z" />
    </Ic>
);

IC15Skf.displayName = 'IC15Skf';

export default IC15Skf;
