import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {kayoGreen, midnight, slate} from '../../../../common/palette';
import {IC50Success} from '../../ic';

const FEATURE_BULLET_SIZE_PX = 18;

const StyledListItem = styled.li`
    display: grid;
    grid-template-columns: ${FEATURE_BULLET_SIZE_PX}px 1fr;
    grid-gap: 14px;
    align-items: flex-start;
    width: 100%;
    list-style: none;
    color: ${({isLightTheme}) => isLightTheme ? midnight : slate};
    font: var(--mui-body-copy-4);
`;

const StyledDiv = styled.div`
    grid-column-start: 2;
`;

const XX02Feature = ({
    isLightTheme,
    icon = <IC50Success color={kayoGreen} />,
    children,
    ...htmlAttributes
}) => (
    <StyledListItem isLightTheme={isLightTheme} {...htmlAttributes}>
        {icon}
        <StyledDiv>
            {children}
        </StyledDiv>
    </StyledListItem>
);

XX02Feature.displayName = 'XX02Feature';

XX02Feature.propTypes = {
    /** Light or dark (default) theme */
    isLightTheme: propTypes.bool,
    /** IC icon to use */
    icon: propTypes.node,
    /** Content of item, e.g. text */
    children: propTypes.node,
};

export default XX02Feature;
