import React from 'react';

import Ic from '..';

const IC21Mute = (args) => (
    <Ic {...args}>
        <g fillRule="nonzero">
            <path d="m62.816 40.594 2.964-2.964 22.46 22.46-2.964 2.964z" />
            <path d="m62.816 60.113 22.46-22.46 2.964 2.964-22.46 22.46zM46.67 32.04v35.828L34.357 59.82V40.256L46.67 32.15m8.384-15.65-29.08 19.116v28.675l29.08 19.13V16.5zM12 62.936l8.384.768V36.12L12 36.89z" />
        </g>
    </Ic>
);

IC21Mute.displayName = 'IC21Mute';

export default IC21Mute;
