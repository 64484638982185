import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import styled from 'styled-components';
import property from 'lodash/property';
import {Figure, FigCaption} from 'normalized-styled-components';
import {readableColor} from 'polished';

import {colorType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {slate, ironStone, white, silverLining} from '../../../../common/palette';
import VisuallyHidden from '../../../../common/visually-hidden';

const Container = styled(Figure)`
    margin: 0;
`;

const Title = styled(FigCaption)`
    margin: 0 0 7px;
    text-align: center;
    color: ${silverLining};
    font: var(--mui-hud-tile-header);
`;

const SegmentedBar = styled.dl`
    display: flex;
    flex-wrap: nowrap;
    height: 22px;
    overflow: hidden;
    margin-block-end: unset;
    margin-block-start: unset;
    margin-inline-end: unset;
    margin-inline-start: unset;
`;

const Value = styled.dd`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    background-color: ${property('teamColor')};
    min-width: 28px;
    height: 100%;
    text-shadow: 0 1px 1px ${getContrastingTextShadowColor};
    color: ${getContrastingTextColor};
    font: var(--mui-hud-tile-copy-bold);
    margin-inline-start: unset;
`;

const LeftValue = styled(Value)`
    justify-content: flex-start;
    border-radius: 14px 0 0 14px;
    padding-left: 10px;
    text-align: left;
`;

const RightValue = styled(Value)`
    justify-content: flex-end;
    border-radius: 0 14px 14px 0;
    padding-right: 10px;
`;

const OR20HdToHd = ({
    title,
    teamAName,
    teamBName,
    drawName = 'Draw',
    teamAColor = slate,
    teamBColor = ironStone,
    drawColor = silverLining,
    teamAValue = 50,
    teamBValue = 50,
    drawValue = 0,
    className,
}) => {
    const totalValue = teamAValue + teamBValue + drawValue;

    const teamAPercentage = Math.round((100 * teamAValue) / totalValue);
    const teamBPercentage = drawValue ? Math.round((100 * teamBValue) / totalValue) : 100 - teamAPercentage;
    const drawPercentage = 100 - teamAPercentage - teamBPercentage;

    return (
        <Container className={classnames(className, 'OR20HdToHd')}>
            <Title>{title}</Title>
            {/* Intentionally not using aria-role="progress" because that's for communicating the progress of a task */}
            <SegmentedBar>
                <VisuallyHidden as="dt">{teamAName}</VisuallyHidden>
                <LeftValue
                    teamColor={teamAColor}
                    style={{width: `${teamAPercentage}%`}}
                >
                    {teamAPercentage}%
                </LeftValue>
                {!!drawPercentage && (
                    <React.Fragment>
                        <VisuallyHidden as="dt">{drawName}</VisuallyHidden>
                        <Value
                            teamColor={drawColor}
                            style={{width: `${drawPercentage}%`}}
                        >
                            {drawPercentage}%
                        </Value>
                    </React.Fragment>
                )}
                <VisuallyHidden as="dt">{teamBName}</VisuallyHidden>
                <RightValue
                    teamColor={teamBColor}
                    style={{width: `${teamBPercentage}%`}}
                >
                    {teamBPercentage}%
                </RightValue>
            </SegmentedBar>
        </Container>
    );
};

OR20HdToHd.propTypes = {
    /** Title for the stat, e.g. Possession */
    title: propTypes.string,
    /** Team A Name, for accessibility */
    teamAName: propTypes.string,
    /** Team B Name, for accessibility */
    teamBName: propTypes.string,
    /** Optional name for a draw, for accessibility */
    drawName: propTypes.string,
    /** Color for the left side of the progress bar */
    teamAColor: colorType,
    /** Color for the teamB side of the progress bar */
    teamBColor: colorType,
    /** Color for the middle section of the progress bar */
    drawColor: colorType,
    /** Value for the left side of the progress bar */
    teamAValue: propTypes.number,
    /** Value for the teamB side of the progress bar */
    teamBValue: propTypes.number,
    /** Value for the middle section of the progress bar */
    drawValue: propTypes.number,
    /** Optional extra class name(s) for the component */
    className: classNameType,
};

OR20HdToHd.displayName = 'OR20HdToHd';

export default OR20HdToHd;

function getContrastingTextColor({teamColor}) {
    return readableColor(teamColor, ironStone, white);
}

function getContrastingTextShadowColor({teamColor}) {
    return readableColor(teamColor, white, ironStone);
}
