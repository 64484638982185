import React from 'react';

import Ic from '..';

const IC48Close = (args) => (
    <Ic {...args}>
        <path d="M11.1 47.2h77.8v5.6H11.1z" />
    </Ic>
);

IC48Close.displayName = 'IC48Close';

export default IC48Close;
