import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {ironStone, white} from '../../../../common/palette';
import {SCREEN_PHONE, SCREEN_LG_DESKTOP, SCREEN_TABLET} from '../../../../common/screen-sizes';
import GA92BrandLogo from '../../../atoms/ga/92-brand-logo';
import TM24AlertTile from '../../../molecules/tm/24-alert-tile';

const LOGO_WRAPPER_HEIGHT_PX = [
    [SCREEN_PHONE, 63, 63],
    [SCREEN_LG_DESKTOP, 78, 0],
];

const Heading = styled.h2`
    margin: 0;
    padding-bottom: ${({hasAlertContent}) => hasAlertContent ? '21px' : '42px'};
    text-align: center;
    color: ${white};
    font: var(--mui-header-6);

    ${mediaQuery({minWidth: SCREEN_TABLET})`
        padding-bottom: ${({hasAlertContent}) => hasAlertContent ? '21px' : '36px'};
        font: var(--mui-header-4);
    `}

    ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
        padding-bottom: ${({hasAlertContent}) => hasAlertContent ? '21px' : '78px'};
        font: var(--mui-header-4-bold);
    `}

    ${LOGO_WRAPPER_HEIGHT_PX.map(([minWidthPx, , fixedHeightPx]) => mediaQuery({minWidthPx})`
        padding-top: ${fixedHeightPx}px;
    `)}
`;

const Section = styled.section`
    box-sizing: border-box;
    flex-grow: 1;
    margin: 0 auto;
    background: ${ironStone};
    padding-bottom: 35px;
    width: calc(100% - 14px);

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        width: 698px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
        width: 1100px;
    `}
`;

const GridLayout = styled.div`
    display: grid;
    grid-row-gap: 56px;

    ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
        grid-column-gap: 76px;
        grid-row-gap: 70px;
        grid-template-columns: 370px 1fr;
        grid-template-rows: repeat(${({hasJourneyDetails}) => hasJourneyDetails ? 3 : 2}, auto);
    `}
`;

const ProductContainer = styled.div`
    width: 100%;

    ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
        grid-row: 1 / -1;
    `}
`;

const StepContainer = styled.div`
    ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
        grid-column: 2;
    `}
`;

const PersonalDetailsStepContainer = styled(StepContainer)`
    ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
        grid-row: 1;
    `}
`;

const LogoWrapper = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    z-index: 1; /* stylelint-disable-line scale-unlimited/declaration-strict-value */
    background-color: ${ironStone};
    width: 100%;

    ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
        position: static;
        top: unset;
        left: unset;
    `}

    ${LOGO_WRAPPER_HEIGHT_PX.map(([minWidthPx, heightPx, fixedHeightPx]) => fixedHeightPx ? mediaQuery({minWidthPx})`
        padding-bottom: 7px;
        height: ${heightPx}px;
        mask-image: linear-gradient(to bottom, #000, #000 calc(100% - 7px), transparent 100%);
    ` : mediaQuery({minWidthPx})`
        height: ${heightPx}px;
    `)}
`;

const LogoBox = styled.div`
    margin: 0 auto;
    width: 80px;

    ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
        width: 97px;
    `}
`;

const TemplateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    width: 100%;
`;

const StyledTM24AlertTile = styled(TM24AlertTile)`
    margin: 14px auto 21px;

    ${mediaQuery({minWidth: SCREEN_TABLET})`
        margin-bottom: 34px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
        max-width: 60%;
        margin-bottom: 42px;
    `}
`;

const CheckoutPageTemplate = ({
    className,
    heading = 'Almost there! 2 more steps and you can start watching.',
    alertContent,
    product,
    personalDetails,
    journeyDetails,
    payment,
    error,
    footer,
}) => (
    <TemplateWrapper>
        <Section className={classnames('CheckoutPageTemplate', className)}>
            <LogoWrapper>
                <LogoBox>
                    <GA92BrandLogo isInternalMode={false} />
                </LogoBox>
            </LogoWrapper>

            <Heading hasAlertContent={!!alertContent}>
                {heading}
            </Heading>

            {!!alertContent && (
                <StyledTM24AlertTile>
                    {alertContent}
                </StyledTM24AlertTile>
            )}

            <GridLayout hasJourneyDetails={!!journeyDetails}>
                <ProductContainer>
                    {product}
                </ProductContainer>

                <PersonalDetailsStepContainer>
                    {personalDetails}
                </PersonalDetailsStepContainer>

                {!!journeyDetails && (
                    <StepContainer>
                        {journeyDetails}
                    </StepContainer>
                )}

                {!!payment && (
                    <StepContainer>
                        {payment}
                    </StepContainer>
                )}

                {!!error && (
                    <StepContainer>
                        {error}
                    </StepContainer>
                )}
            </GridLayout>
        </Section>
        {footer}
    </TemplateWrapper>
);

CheckoutPageTemplate.displayName = 'CheckoutPageTemplate';

CheckoutPageTemplate.propTypes = {
    className: classNameType,
    heading: propTypes.string,
    alertContent: propTypes.node,
    product: propTypes.node,
    personalDetails: propTypes.node,
    journeyDetails: propTypes.node,
    payment: propTypes.node,
    error: propTypes.node,
    footer: propTypes.node,
};

export default CheckoutPageTemplate;
