import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import noop from 'lodash/noop';
import {rgba} from 'polished';

import {mediaQuery, stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType, srcsetOptionsType} from '@fsa-streamotion/custom-prop-types';

import {SCREEN_375_PHABLET, SMALLEST_SUPPORTED_WIDTH} from '../../../../common/screen-sizes';
import {transition} from '../../../../common/animations';
import {black} from '../../../../common/palette';
import AppDockHeader from './components/app-dock-header';
import AppDockTray from './components/app-dock-tray';

const AppDock = styled.aside`
    display: flex;
    position: relative;
    flex-direction: column;
    transform: translateX(0);
    transition: ${transition('transform', 'box-shadow')};
    border-bottom-left-radius: 3px;
    background: transparent;
    width: ${SMALLEST_SUPPORTED_WIDTH}px;
    height: calc(100vh - 69px);
    overflow: hidden auto;
    font: var(--infinity-body-copy-3);
    scrollbar-width: none; /* Firefox - hide scrollbar */
    -ms-overflow-style: none; /*  IE - hide scrollbar */

    ${stylesWhen('isActive')`
        transform: translateX(-100%);
        box-shadow: 7px -7px 21px ${rgba(black, 0.5)};
    `}

    ${mediaQuery({minWidthPx: SCREEN_375_PHABLET})`
        width: 375px;
    `}

    &::-webkit-scrollbar { /* hide scrollbar */
        display: none;
    }
`;

const OR31AppDock = ({
    className,
    isActive,
    isAuthenticated,

    // header
    dockNavItems,
    avatarUrl,
    onProfileClick = noop,
    profileName,
    hasSignupCtaSection,
    ctaMessage,
    signInCtaCopy = 'Sign In',
    signUpCtaCopy = 'Sign Up',
    signUpUrl = '/',
    onClickLogin = noop,

    // tray
    catTabItems,
    onSelectCatTabItem = noop,
    selectedCatTabItemIndex = 0,
}) => (
    <AppDock
        className={classnames('OR31AppDock', className)}
        isActive={isActive}
        aria-hidden={!isActive}
    >
        <AppDockHeader
            dockNavItems={dockNavItems}
            isAuthenticated={isAuthenticated}

            avatarUrl={avatarUrl}
            onProfileClick={onProfileClick}
            profileName={profileName}

            hasSignupCtaSection={hasSignupCtaSection}
            ctaMessage={ctaMessage}
            signInCtaCopy={signInCtaCopy}
            signUpCtaCopy={signUpCtaCopy}
            signUpUrl={signUpUrl}
            onSigninClick={onClickLogin}
        />

        <AppDockTray
            catTabItems={catTabItems}
            onSelectCatTabItem={onSelectCatTabItem}
            selectedCatTabItemIndex={selectedCatTabItemIndex}
        />
    </AppDock>
);

OR31AppDock.displayName = 'OR31AppDock';

OR31AppDock.propTypes = {
    /** Optional additional class(es) */
    className: classNameType,
    /** Is the app dock currently active (i.e. open, visible and clickable)? */
    isActive: propTypes.bool,
    /** User is authenticated and logged in */
    isAuthenticated: propTypes.bool,

    // ==== HEADER SECTION === //
    // DOCK NAV
    /** `NM02DockNav` prop: **menuItems** */
    dockNavItems: propTypes.any,
    // USER PROFILE
    /** User profile avatar */
    avatarUrl: propTypes.string,
    /** User profile action when clicked */
    onProfileClick: propTypes.func,
    /** User profile name */
    profileName: propTypes.string,
    // SIGNUP CTA SECTION
    /** Is the Signup CTA section rendered (visible only viewports smaller than 1920) */
    hasSignupCtaSection: propTypes.bool,
    /** Shows Signup CTA label for new users */
    ctaMessage: propTypes.string,
    /** Sign In CTA copy */
    signInCtaCopy: propTypes.string,
    /** Sign Up CTA copy */
    signUpCtaCopy: propTypes.string,
    /** URL for sign-up */
    signUpUrl: propTypes.string,
    /** Action on clicking the signin button in Signup CTA section */
    onClickLogin: propTypes.func,

    // ==== TRAY SECTION === //
    // CAT TAB
    /** Dock tray tab items */
    catTabItems: propTypes.arrayOf(propTypes.shape({
        href: propTypes.string,
        label: propTypes.string,
        value: propTypes.any,
        tabType: propTypes.oneOf(['list', 'selector']),
        listContent: propTypes.shape({
            menuItems: propTypes.arrayOf(
                propTypes.shape({
                    href: propTypes.string,
                    icon: propTypes.node,
                    label: propTypes.string,
                    onClick: propTypes.func,
                })
            ),
            ctaCopy: propTypes.string,
            onClickCta: propTypes.func,
        }),
        selectorContent: propTypes.shape({
            isLoading: propTypes.bool,
            selectors: propTypes.arrayOf(
                propTypes.shape({
                    sectionHeading: propTypes.string,
                    tiles: propTypes.arrayOf(
                        propTypes.shape({
                            tileImageSrcset: srcsetOptionsType,
                            focusedTileImageSrcset: srcsetOptionsType,
                            href: propTypes.string,
                            displayName: propTypes.string,
                        })
                    ),
                })
            ),
            emptySelectorHeader: propTypes.string,
            emptySelectorDescription: propTypes.string,
            ctaCopy: propTypes.string,
            ctaUrl: propTypes.string,
            onClickCta: propTypes.func,
        }),
    })),
    /** On select dock tray tab item */
    onSelectCatTabItem: propTypes.func,
    /** Selected dock tray tab item index */
    selectedCatTabItemIndex: propTypes.number,
};

export default OR31AppDock;
