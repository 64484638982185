import React from 'react';

import Ic from '..';

const IC14Skb = (args) => (
    <Ic {...args}>
        <path fillRule="nonzero" d="M17.167 42.55h-3.76V18.796H8V16h9.167zm16.685.42a10.33 10.33 0 0 1-9.083-4.458l2.166-2.152a8.09 8.09 0 0 0 6.875 3.536c3.13 0 6.4-1.845 6.4-5.87 0-4.024-3.228-5.883-6.4-5.883a8.077 8.077 0 0 0-4.877 1.398l-1.956-1.118L29.367 16h13.89v2.99H32.174l-1.397 6.987a9.991 9.991 0 0 1 3.912-.698c4.486 0 9.405 2.878 9.405 8.692 0 5.813-5.045 8.999-10.243 8.999zm41.969-20.732-6.107 2.295C82.258 30.408 89.2 44.062 86.558 57.66 83.914 71.258 72.363 81.317 58.53 82.065H42.404v-6.12h15.798a24.102 24.102 0 0 0 9.062-45.825l2.22 6.083a3.087 3.087 0 0 1-1.818 3.95 3.071 3.071 0 0 1-3.95-1.794l-5.969-15.847 15.976-5.964a3.017 3.017 0 0 1 3.885 1.783 3.047 3.047 0 0 1-1.787 3.907z" />
    </Ic>
);

IC14Skb.displayName = 'IC14Skb';

export default IC14Skb;
