import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {fog} from '../../../../common/palette';
import {
    SCREEN_PHONE,
    SCREEN_375_PHABLET,
    SCREEN_768_TABLET,
    SCREEN_1920_DESKTOP,
    SCREEN_1024_DESKTOP,
    SCREEN_1280_DESKTOP,
    SCREEN_1680_DESKTOP,
} from '../../../../common/screen-sizes';
import GA92BrandLogo from '../../../atoms/ga/92-brand-logo';

// @TODO: the exported values from GA92BrandLogo has now been fixed and equal to these values
// we should get rid of these and import those and use those.
const LOGO_PHONE_SIZE_PX = 193;
const LOGO_DESKTOP_SIZE_PX = 254;

const LOGO_SIZES_DEFINITION = {
    [SCREEN_PHONE]: LOGO_PHONE_SIZE_PX,
    [SCREEN_1920_DESKTOP]: LOGO_DESKTOP_SIZE_PX,
};

const WelcomeSection = styled.section`
    margin: 0 auto;
    width: 660px;
    max-width: 100%;
    text-align: center;
    color: ${fog};

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 847px;
    `}
`;

const StyledHeading = styled.div`
    display: flex;
    position: relative;
    left: 50%;
    flex-direction: column;
    transform: translateX(-50%);
    margin-top: 179px;
    width: ${LOGO_SIZES_DEFINITION[SCREEN_PHONE]}px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        margin-top: 315px;
        margin-bottom: 56px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        margin-top: 213px;
        margin-bottom: 58px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        margin-top: 157px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        margin-top: 127px;
        width: ${LOGO_SIZES_DEFINITION[SCREEN_1920_DESKTOP]}px;
    `}
`;

const StyledGA92BrandLogo = styled(GA92BrandLogo)`
    width: ${LOGO_SIZES_DEFINITION[SCREEN_PHONE]}px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: ${LOGO_SIZES_DEFINITION[SCREEN_1920_DESKTOP]}px;
    `}
`;

const ContentWrapper = styled.div`
    margin: 35px auto;
    font: var(--infinity-header-5-light);

    ${mediaQuery({minWidthPx: SCREEN_375_PHABLET})`
        width: 300px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: 350px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1680_DESKTOP})`
        width: 400px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 530px;
    `}
`;

const defaultWelcomeMessage = 'Welcome to Flash... hold on for a second while we collect your favourites...';

const OR93WelcomeBox = ({
    className,
    welcomeMessage = defaultWelcomeMessage,
}) => (
    <WelcomeSection className={classnames(className, 'OR93WelcomeBox')}>
        <StyledHeading>
            <StyledGA92BrandLogo />
        </StyledHeading>
        <ContentWrapper>{welcomeMessage}</ContentWrapper>
    </WelcomeSection>
);

OR93WelcomeBox.propTypes = {
    /** Optional classnames */
    className: classNameType,
    /** Welcome message which could be more than just a string, it could be a styled text */
    welcomeMessage: propTypes.node,
};

OR93WelcomeBox.displayName = 'OR93WelcomeBox';

export default OR93WelcomeBox;
