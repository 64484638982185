import {isServer, getLocalStorageValue, setLocalStorageValue} from '@fsa-streamotion/browser-utils';
import {PLAYBACK_HANDLERS} from '@fsa-streamotion/player-tech';

export const PLAYBACK_CAPABILITIES_STORAGE_KEY = {
    binge: 'ares-playback-details',
    flash: 'playback-capabilities',
    kayo: 'playback-capabilities',
    lifestyle: 'lifestyle-playback-details',
};
const PLAYBACK_CAPABILITIES_STORAGE_TTL_MS = 24 * 60 * 60 * 1000;

const {PlaybackNative, PlaybackDash} = PLAYBACK_HANDLERS;

const avcHardwareDecode = {
    audioContentType: 'audio/mp4; codecs="mp4a.40.2"',
    audioRobustness: 'HW_SECURE_DECODE',
    videoContentType: 'video/mp4; codecs="avc1.64002A"',
    videoRobustness: 'HW_SECURE_DECODE',
};
const avcSoftwareDecode = {
    audioContentType: 'audio/mp4; codecs="mp4a.40.2"',
    audioRobustness: 'SW_SECURE_CRYPTO',
    videoContentType: 'video/mp4; codecs="avc1.64002A"',
    videoRobustness: 'SW_SECURE_DECODE',
};

const hevcHardwareDecode = {
    audioContentType: 'audio/mp4; codecs="mp4a.40.2"',
    audioRobustness: 'HW_SECURE_DECODE',
    videoContentType: 'video/mp4; codecs="hvc1.1.6.L90.B0"',
    videoRobustness: 'HW_SECURE_DECODE',
};
const hevcSoftwareDecode = {
    audioContentType: 'audio/mp4; codecs="mp4a.40.2"',
    audioRobustness: 'SW_SECURE_CRYPTO',
    videoContentType: 'video/mp4; codecs="hvc1.1.6.L90.B0"',
    videoRobustness: 'SW_SECURE_DECODE',
};

/**
 * Runs the capability tests for the given browser.
 *
 * @returns {{canPlayHevc: boolean, canPlayHighDrm: boolean, all: Object}} - The test results.
 */
async function runPlaybackTests() {
    // All native HLS is considered 'high' drm.
    const fairPlayTests = {
        avcHigh: PlaybackNative.canPlaySource({
            src: 'https://domain/hls.m3u8',
            type: 'application/x-mpegURL',
            keySystems: {
                'com.apple.fps.1_0': {
                    audioContentType: 'audio/mp4; codecs="mp4a.40.2"',
                    videoContentType: 'video/mp4; codecs="avc1.64002A"',
                },
            },
        }),

        // HEVC is a bit weird for safari. We've found looking to decodingInfo and ensuring supported + powerEfficient to be successful.
        // Macs prior to 2017 don't support 'hardware' decoding HEVC and will report as being power inefficiency.
        // I've read mixed reports that there's a difference between 24fps and 25fps hevc playback support,
        // but even using navigator.mediaCapabilities.decodingInfo with a given framerate will still say it can be played.
        // No success in canPlayType or even safari specific WebKitMediaKeys.isTypeSupported either.
        hevcHigh: window.WebKitMediaKeys?.isTypeSupported
            && window.navigator.mediaCapabilities?.decodingInfo?.({ // eslint-disable-line compat/compat
                type: 'file',
                video: {
                    contentType: 'video/mp4; codecs="hvc1.1.6.L90.B0"', width: 1920, height: 1080, bitrate: 7000000, framerate: 25,
                },
            })
                .then(({supported, powerEfficient} = {}) => supported && powerEfficient)
                .catch(false),
    };

    const playReadyTests = {
        avcHigh: PlaybackDash.canPlaySource({
            src: 'https://domain/dash.mpd',
            type: 'application/dash+xml',
            keySystems: {'com.microsoft.playready': avcHardwareDecode},
        }),
        avcLow: PlaybackDash.canPlaySource({
            src: 'https://domain/dash.mpd',
            type: 'application/dash+xml',
            keySystems: {'com.microsoft.playready': avcSoftwareDecode},
        }),
        hevcHigh: PlaybackDash.canPlaySource({
            src: 'https://domain/dash.mpd',
            type: 'application/dash+xml',
            keySystems: {'com.microsoft.playready': hevcHardwareDecode},
        }),
        hevcLow: PlaybackDash.canPlaySource({
            src: 'https://domain/dash.mpd',
            type: 'application/dash+xml',
            keySystems: {'com.microsoft.playready': hevcSoftwareDecode},
        }),
    };

    const widevineTests = {
        avcHigh: PlaybackDash.canPlaySource({
            src: 'https://domain/dash.mpd',
            type: 'application/dash+xml',
            keySystems: {'com.widevine.alpha': avcHardwareDecode},
        }),
        avcLow: PlaybackDash.canPlaySource({
            src: 'https://domain/dash.mpd',
            type: 'application/dash+xml',
            keySystems: {'com.widevine.alpha': avcSoftwareDecode},
        }),
        hevcHigh: PlaybackDash.canPlaySource({
            src: 'https://domain/dash.mpd',
            type: 'application/dash+xml',
            keySystems: {'com.microsoft.playready': hevcHardwareDecode},
        }),
        hevcLow: PlaybackDash.canPlaySource({
            src: 'https://domain/dash.mpd',
            type: 'application/dash+xml',
            keySystems: {'com.microsoft.playready': hevcSoftwareDecode},
        }),
    };

    const testResults = {
        all: {},
        canPlayHighDrm: false,
        canPlayHevc: false,
    };

    // Test FairPlay first, then PlayReady, then Widevine. Order specific. But run all the tests together anyway. We cache results.
    await Promise.all([
        fairPlayTests.avcHigh,
        fairPlayTests.hevcHigh,

        playReadyTests.avcHigh,
        playReadyTests.avcLow,
        playReadyTests.hevcHigh,
        playReadyTests.hevcLow,

        widevineTests.avcHigh,
        widevineTests.avcLow,
        widevineTests.hevcHigh,
        widevineTests.hevcLow,
    ]).then(([
        fairPlayAvcHigh,
        fairPlayHevcHigh,
        playReadyAvcHigh,
        playReadyAvcLow,
        playReadyHevcHigh,
        playReadyHevcLow,
        widevineAvcHigh,
        widevineAvcLow,
        widevineHevcHigh,
        widevineHevcLow,
    ]) => {
        testResults.all = {fairPlayAvcHigh, fairPlayHevcHigh, playReadyAvcHigh, playReadyAvcLow, playReadyHevcHigh, playReadyHevcLow, widevineAvcHigh, widevineAvcLow, widevineHevcHigh, widevineHevcLow}; // eslint-disable-line max-len

        // Sometimes Edge suggests HEVC under DRM (but not standalone) which causes dash.js problems
        // So we'll make sure it can actually play and for extra safety we'll check fairplay and widevine as well
        const videoElement = document.createElement('video');
        const hasDrmFreeHevcSupport = !!videoElement.canPlayType(hevcSoftwareDecode.videoContentType);

        if (fairPlayAvcHigh || fairPlayHevcHigh) {
            // Any fairplay can be played, it will be preferred first.
            testResults.canPlayHighDrm = true; // FairPlay considered 'high-drm' always.
            testResults.canPlayHevc = hasDrmFreeHevcSupport && !!fairPlayHevcHigh;
        } else if (playReadyAvcHigh || playReadyAvcLow || playReadyHevcHigh || playReadyHevcLow) {
            // PlayReady being the next most picky, anything in here is what our players will use.
            testResults.canPlayHighDrm = !!playReadyAvcHigh || !!playReadyHevcHigh;
            testResults.canPlayHevc = hasDrmFreeHevcSupport && (testResults.canPlayHighDrm ? !!playReadyHevcHigh : !!playReadyHevcLow); // Only go for HEVC if supported with the DRM high vs low.
        } else {
            // Widevine is honey badger, if we're unsure from the above it'll end up widevine.
            // It is possible for android devices to do mix of high and hevc.
            // We'll at worse suggest false high drm, false can play hevc.
            testResults.canPlayHighDrm = !!widevineAvcHigh || !!widevineHevcHigh;
            testResults.canPlayHevc = hasDrmFreeHevcSupport && (testResults.canPlayHighDrm ? !!widevineHevcHigh : !!widevineHevcLow); // Only go for HEVC if supported with the DRM high vs low.
        }
    })
        .catch((e) => {
            console.error('WidgetsCommon: Thrown issue in playback tests.', e);
        });

    return testResults;
}

/**
 * Retrieves all of the playback capabilities.
 *
 * @param {string} brand - Brand string eg binge, flash, kayo
 * @returns {Promise<*>} - The Promise which resolves into the object which contains all the capabilities.
 */
async function getPlaybackCapabilities(brand) {
    if (isServer()) {
        return {
            canPlayHevc: false,
            canPlayHighDrm: false,
        };
    }

    const previouslyDetectedCapabilities = getLocalStorageValue({
        key: PLAYBACK_CAPABILITIES_STORAGE_KEY[brand],
        defaultValue: false,
    });

    if (previouslyDetectedCapabilities) {
        return previouslyDetectedCapabilities;
    }

    const runPlaybackTestResults = await runPlaybackTests();

    setLocalStorageValue({
        key: PLAYBACK_CAPABILITIES_STORAGE_KEY[brand],
        value: runPlaybackTestResults,
        expiresIn: PLAYBACK_CAPABILITIES_STORAGE_TTL_MS,
    });

    return runPlaybackTestResults;
}

/**
 * Retrieves the playback capabilities of the player.
 *
 * @param {string} brand - Brand string eg binge, flash, kayo
 * @returns {Promise<*>} - The Promise which resolves into the object containing the player's playback capabilities.
 */
export default async function getVideoPlaybackCapabilities(brand) {
    return await getPlaybackCapabilities(brand).then((capabilities) => ({
        canPlayHevc: capabilities.canPlayHevc,
        canPlayHighDrm: capabilities.canPlayHighDrm,
    }));
}
