import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import {indigo} from '../../../../common/palette';

import Ic from '..';

const IC17Plus = ({
    secondaryColor = indigo,
    ...args
}) => (
    <Ic {...args}>
        <g fillRule="nonzero">
            <path d="M49.62 7C26.083 7 7 26.082 7 49.62c0 23.54 19.082 42.621 42.62 42.621 23.54 0 42.621-19.082 42.621-42.62C92.234 26.085 73.156 7.008 49.621 7z" />
            <path fill={secondaryColor} d="M69.631 45.428H53.813V30.323h-8.385v15.105H28.912v8.385h16.517v16.629h8.384v-16.63h15.82z" />
        </g>
    </Ic>
);

IC17Plus.propTypes = {
    /* Color for the middle icon */
    secondaryColor: colorType,
};

IC17Plus.displayName = 'IC17Plus';

export default IC17Plus;
