import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import {Button} from 'normalized-styled-components';
import {white} from '../../../../../common/palette';
import {transition} from '../../../../../common/animations';

const StyledButton = styled(Button)`
    transition: ${transition('opacity', 'border-left')};
    opacity: 0.5;
    margin: 0;
    border: 0;
    border-left: 2px solid transparent;
    background-color: transparent;
    padding: 0 0 0 28px;
    width: 100%;
    max-width: 250px;
    height: 31px;
    overflow: hidden;
    text-align: left;
    text-transform: capitalize;
    white-space: nowrap;
    color: ${white};
    font: var(--mui-body-copy-2-bold);

    &[aria-selected='true'] {
        opacity: 1;
        border-left: 2px solid ${white};
    }
    /* We explicitly put these at the end so the hover/focus state takes precedence over aria-selected */
    /* stylelint-disable order/order */
    &:hover,
    &:focus {
        opacity: 1;
        outline: none;
        border-left: 2px solid ${rgba(white, 0.5)};
    }
    /* stylelint-enable order/order */
`;

const VPBA02TraySectionListItem = ({
    label,
    onClick,
    isActive,
}) => (
    <StyledButton
        onClick={onClick}
        aria-selected={isActive}
    >
        {label}
    </StyledButton>
);

VPBA02TraySectionListItem.displayName = 'VPBA02TraySectionListItem';

VPBA02TraySectionListItem.propTypes = {
    /** Is the button active? */
    isActive: propTypes.bool,
    /** Label associated with the button */
    label: propTypes.string.isRequired,
    /** OnClick handler */
    onClick: propTypes.func,
};

export default VPBA02TraySectionListItem;
