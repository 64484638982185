// eslint-disable-next-line import/no-named-as-default
import Checkout from './widgets/checkout';
import Landing from './widgets/landing';
import LegalDisclaimer from './widgets/legal-disclaimer';
import Offer from './widgets/offer';
import PackageSummary from './widgets/package-summary';
import Redirect from './widgets/redirect';

const client = {
    Checkout,
    Landing,
    LegalDisclaimer,
    Offer,
    PackageSummary,
    Redirect,
};

if (window) {
    window.accountsWidgets = client;
}

export default client;
