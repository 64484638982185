import React from 'react';
import Ic from '..';

const IC291RecordDefault = (args) => (
    <Ic {...args}>
        <g fillRule="evenodd" transform="translate(7 7)">
            <circle cx="43" cy="43" r="22" />
            <path fillRule="nonzero" d="M43 0C19.252 0 0 19.252 0 43s19.252 43 43 43 43-19.252 43-43S66.748 0 43 0zm0 7.432c19.644 0 35.568 15.924 35.568 35.568S62.644 78.568 43 78.568 7.432 62.644 7.432 43 23.356 7.432 43 7.432z" />
        </g>
    </Ic>
);

IC291RecordDefault.displayName = 'IC291RecordDefault';

export default IC291RecordDefault;
