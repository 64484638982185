import React from 'react';
import Ic from '..';

const IC239WatchTrailer = (args) => (
    <Ic {...args}>
        <g fillRule="nonzero">
            <path
                d="M50.252 8C26.921 8 8.007 26.914 8.007 50.245c0 23.33 18.914 42.244 42.245 42.244 23.33 0 42.244-18.913 42.244-42.244C92.489 26.917 73.58 8.008 50.252 8z"
                opacity=".2"
            />
            <path d="M43.506 34.51v31.469l20.347-15.734z" />
        </g>
    </Ic>
);

IC239WatchTrailer.displayName = 'IC239WatchTrailer';

export default IC239WatchTrailer;
