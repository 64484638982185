import React from 'react';
import Ic from '..';

const IC01Ham = (args) => (
    <Ic {...args}>
        <path d="M0 0H84.414V9.13H0zM0 30.921H84.414V40.051H0zM0 61.842H84.414V70.972H0z" transform="translate(8 15)" />
    </Ic>
);

IC01Ham.displayName = 'IC01Ham';

export default IC01Ham;
