import React from 'react';

import Ic from '..';

const IC116SiriRemote = (args) => (
    <Ic {...args}>
        <g fillRule="nonzero" transform="translate(35 7)">
            <path d="M0 0v86.345h30.645V0H0zm27.85 2.795V32.49H2.795V2.795H27.85zM2.795 83.55V35.284H27.85v48.267H2.795z" />
            <circle cx="9.768" cy="22.191" r="3.102" />
            <circle cx="9.768" cy="43.627" r="3.102" />
            <circle cx="9.768" cy="57.908" r="3.577" />
            <path d="M24.245 55.463a3.032 3.032 0 0 1-6.05 0V43.319a3.032 3.032 0 0 1 6.05 0v12.144z" />
            <circle cx="21.227" cy="22.191" r="3.102" />
        </g>
    </Ic>
);

IC116SiriRemote.displayName = 'IC116SiriRemote';

export default IC116SiriRemote;
