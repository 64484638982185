import React from 'react';

import Ic from '..';

const IC57Download = (args) => (
    <Ic {...args}>
        <path d="M29 79.882h42.56v4.2H29z" />
        <path d="M63.762 47.654l-9.282 9.282V16l-8.4.112v40.824l-9.282-9.282-5.936 5.95L50.28 73.022l19.418-19.418z" />
    </Ic>
);

IC57Download.displayName = 'IC57Download';

export default IC57Download;
