import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';

import {stylesWhen, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {A, Button} from '../../../../common/normalized-styled-components';
import {flash, blueCharcoal, white} from '../../../../common/palette';
import GA89Tile from '../../../atoms/ga/89-tile';
import IC31Tick from '../../../atoms/ic/31-tick';

const containerBaseStyles = css`
    position: relative;
    transition: ${transition('background-color', 'color')};
    outline: 0;
    border-radius: 4px;
    color: ${white};

    ${stylesWhen('isClickable')`
        color: ${white};
        background-color: ${rgba(white, 0.2)};

        &:focus,
        &:hover {
            background-color: ${rgba(white, 0.8)};
            color: ${blueCharcoal};
        }
    `}

    ${stylesWhen('isSelected')`
        background-color: ${white};
        color: ${blueCharcoal};
    `}
`;

const ContainerDiv = styled.div`
    ${containerBaseStyles}
`;

const ContainerButton = styled(Button).attrs(({isSelected}) => ({
    'type': 'button',
    'aria-pressed': isSelected,
}))`
    ${containerBaseStyles}

    appearance: none;
    box-sizing: content-box;
    border: 0;
    padding: 0;
    width: 100%;
    text-align: unset;
`;

const ContainerAnchor = styled(A).attrs(({isSelected}) => ({
    'aria-current': isSelected,
}))`
    ${containerBaseStyles}

    display: block;
    text-decoration: none;
`;

const TickWrapper = styled.span`
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    width: 28px;
    height: 28px;
`;

const Layout = styled(GA89Tile)`
    display: grid;
    grid-template-columns: 48px auto;
    grid-gap: 14px;
    align-items: center;
    background-color: transparent;
    color: inherit;
`;

const Rows = styled.div`
    display: grid;
    grid-gap: 5px;

    ${stylesWhenNot('hasIcon')`
        grid-column-start: 2;
    `}
`;

function getCtaType({href, onClick}) {
    if (href) {
        return ContainerAnchor;
    }
    if (onClick) {
        return ContainerButton;
    }

    return ContainerDiv;
}

const TM19Pmnt = ({
    children,
    className,
    href,
    icon,
    isSelected,
    onClick,
    ...htmlAttributes
}) => {
    const StyledElement = getCtaType({href, onClick});

    return (
        <StyledElement
            {...htmlAttributes}
            className={classnames('TM19Pmnt', className)}
            href={href}
            isSelected={isSelected}
            onClick={onClick}
            isClickable={!!(onClick || href)}
        >
            <Layout>
                {icon}

                <Rows hasIcon={!!icon}>
                    {children}
                </Rows>
            </Layout>
            {!!isSelected && (
                <TickWrapper>
                    <IC31Tick color={flash} />
                </TickWrapper>
            )}
        </StyledElement>
    );
};

TM19Pmnt.propTypes = {
    /** Content of tile, e.g. payment method details */
    children: propTypes.node,
    /** Additional class name(s) */
    className: classNameType,
    /** If it’s actually a link, provide a href */
    href: propTypes.string,
    /** Payment method icon, e.g. Visa or Mastercard logo */
    icon: propTypes.node,
    /** Is this payment method selected?  */
    isSelected: propTypes.bool,
    /** Callback on element click */
    onClick: propTypes.func,
};

TM19Pmnt.displayName = 'TM19Pmnt';

export default TM19Pmnt;
