import React from 'react';
import Ic from '..';

const IC280TurnOff = (args) => (
    <Ic {...args}>
        <path fillRule="evenodd" d="M51 84.614c-8.048 0-15.457-2.769-21.342-7.394l48.564-48.562c4.623 5.886 7.392 13.295 7.392 21.341 0 19.087-15.528 34.615-34.614 34.615m0-69.228c8.022 0 15.41 2.75 21.286 7.349l-48.55 48.55c-4.598-5.875-7.35-13.263-7.35-21.286 0-19.086 15.528-34.613 34.614-34.613M51 7C27.253 7 8 26.25 8 50c0 23.749 19.253 43 43 43 23.749 0 43-19.251 43-43C94 26.25 74.749 7 51 7" />
    </Ic>
);

IC280TurnOff.displayName = 'IC280TurnOff';

export default IC280TurnOff;
