import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import GA88PayPal from '../../../atoms/ga/88-pay-pal';
import TM19Pmnt from '../19-pmnt';

const OverflowEllipsis = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const TM19PmntPp = ({
    email,
    name,
    className,
    ...htmlAttributes
}) => (
    <TM19Pmnt
        {...htmlAttributes}
        className={classnames('TM19PmntPp', className)}
        icon={<GA88PayPal />}
    >
        <OverflowEllipsis>
            {name}
        </OverflowEllipsis>

        <div>
            {email}
        </div>
    </TM19Pmnt>
);

TM19PmntPp.displayName = 'TM19PmntPp';

TM19PmntPp.propTypes = {
    /** Email address for the user, e.g. martian@foxsports.com.au */
    email: propTypes.string.isRequired,
    /** Name of the user */
    name: propTypes.string.isRequired,
    /** Additional class(es) */
    className: classNameType,
};

export default TM19PmntPp;
