import React from 'react';
import Ic from '..';

const IC05Exp = (args) => (
    <Ic {...args}>
        <path d="M24 42.17V32h19.07v-6H18v16.17zm0 16h-6v16h25.07v-6H24zm52-16h6V26H59.07v6H76zm0 16v10H59.07v6H82v-16z" />
    </Ic>
);

IC05Exp.displayName = 'IC05Exp';

export default IC05Exp;
