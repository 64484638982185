import React, {cloneElement, useState} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';
import get from 'lodash/get';

import {stylesWhen, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {A, Sup} from '../../../../common/normalized-styled-components';
import {TERM_TYPE_SHORT} from '../../../../common/billing-constants';
import {termTypePropType} from '../../../../common/custom-proptypes';
import splitPrice from '../../../../common/split-price';
import {fog, white, flash, blueCharcoal} from '../../../../common/palette';

import CA01Feature from '../../../atoms/ca/01-feature';
import GA20PixelDiv from '../../../atoms/ga/20-pixel-div';
import GA89Tile from '../../../atoms/ga/89-tile';
import IC25ArrowR from '../../../atoms/ic/25-arrow-r';
import IC32Success from '../../../atoms/ic/32-success';
import CM03FeatureList from '../../cm/03-feature-list';

const StyledCA01Feature = styled(CA01Feature)`
    color: ${rgba(white, 0.7)};
    font: var(--infinity-package-inclusions);
`;

const PlanPrice = styled.span`
    position: relative;
    flex-shrink: 0;
    padding-left: 21px;
    color: ${white};
    font: var(--infinity-sub-price);
`;

const RegularPriceBadge = styled.div`
    color: ${rgba(fog, 0.7)};
    font: var(--infinity-voucher-terms);
`;

const IconWrapper = styled.div`
    width: 60px;
    line-height: 0;
    color: ${white};
`;

const CtaTextWrapper = styled.div`
    flex: 1 0 auto;
    text-align: right;
    color: ${rgba(fog, 0.7)};
    font: var(--infinity-body-copy-3-light);
    user-select: none;
`;

const StyledGA89Tile = styled(GA89Tile).attrs(({isPressed}) => isPressed && ({
    'aria-current': isPressed,
}))`
    display: block;
    border: 1px solid transparent;
    border-radius: 6px;
    background-color: ${blueCharcoal};
    padding: 0;
    text-decoration: none;
    color: ${white};
    font: var(--infinity-voucher-terms);

    ${stylesWhen('hasAction')`
        &:hover {
            background-color: ${flash};
        }
    `}
`;

const CurrentPlan = styled.section`
    padding: ${({isShortForm}) => isShortForm ? '14px' : '21px'} 14px;
`;

const PlanDetails = styled.div`
    display: flex;
    position: relative;
    margin: 8px 0 0;
`;

const PlanDescription = styled.div`
    flex-grow: 1;
`;

const PlanName = styled.h4`
    margin-top: 0;
    margin-bottom: 7px;
    text-transform: uppercase;
    font: var(--infinity-package-name);
`;

const PlanPriceFraction = styled(Sup)`
    position: absolute;
    top: 3px;
    font: var(--infinity-billing-frequency);
`;

const StyledCM03FeatureList = styled(CM03FeatureList)`
    margin-left: 4px;
`;

const TermWrapper = styled.span`
    font: var(--infinity-billing-frequency);
`;

const IconCtaWrapper = styled.span`
    display: flex;

    ${stylesWhenNot('hasNameOrPrice')`
        align-items: center;
    `}
`;

const StyledIC25ArrowR = styled(IC25ArrowR)`
    margin-bottom: -2px;
    margin-left: 8px;
`;

const TM10SubTile = ({
    children,
    className,
    packageDetails = {},
    href,
    icon,
    isPressed,
    onClick,
    ...htmlAttributes
}) => {
    const {displayName, features = []} = packageDetails;
    const {ctaText} = packageDetails?.displayDescription || {};
    const {termType, displayRegularAmount, displayAmount, currencyDecimalPlaces = 2, currencyPrefix = '$'} = packageDetails?.price || {};
    const [priceIntegers, priceDecimals] = splitPrice(displayAmount, currencyDecimalPlaces);
    const termTypeShort = get(TERM_TYPE_SHORT, termType);
    const hasNameOrPrice = displayAmount >= 0 || !!displayName;
    const hasAction = !!(href || onClick);
    const hasPlanDescription = !!(displayName || features.length);
    const hasPlanPrice = displayAmount >= 0;
    const hasPlanDetails = hasPlanDescription || hasPlanPrice;
    const [hasFocus, setHasFocus] = useState(false);

    return (
        <StyledGA89Tile
            {...htmlAttributes}
            as={hasAction ? A : undefined}
            href={href}
            className={classnames('TM10SubTile', className)}
            isPressed={isPressed}
            hasAction={hasAction}
            onClick={onClick}
            onFocus={() => setHasFocus(true)}
            onBlur={() => setHasFocus(false)}
        >
            <CurrentPlan isShortForm={!hasPlanDetails}>
                {!!(icon || ctaText) && (
                    <IconCtaWrapper hasNameOrPrice={hasNameOrPrice}>
                        {!!icon && <IconWrapper>{cloneElement(icon, {isMesh: hasFocus})}</IconWrapper>}
                        {!!ctaText && hasAction && (
                            <CtaTextWrapper>
                                {ctaText}
                                <StyledIC25ArrowR size="14px" display="inline-block" />
                            </CtaTextWrapper>
                        )}
                    </IconCtaWrapper>
                )}
                {hasPlanDetails && (
                    <PlanDetails>
                        {hasPlanDescription && (
                            <PlanDescription>
                                {!!displayName && (
                                    <PlanName>
                                        {displayName}
                                    </PlanName>
                                )}
                                {!!features.length && (
                                    <StyledCM03FeatureList isBlock={true} listGapSet={[{breakpoint: 0, gapPx: 0}]}>
                                        {features.map(({title, a11yTitle, value, a11yValue}) => (
                                            <StyledCA01Feature
                                                key={`${title}__${value}`}
                                                icon={<IC32Success color={flash} size="14px" />}
                                                {...(!!(a11yTitle || a11yValue) && ({
                                                    'aria-label': `${a11yTitle}: ${a11yValue}`,
                                                }))}
                                            >
                                                {title}: {value}
                                            </StyledCA01Feature>
                                        ))}
                                    </StyledCM03FeatureList>
                                )}
                            </PlanDescription>
                        )}

                        {hasPlanPrice && (
                            <PlanPrice aria-label={displayAmount > 0 ? `${currencyPrefix}${displayAmount} per ${termType}` : undefined}>
                                {displayAmount
                                    ? (
                                        <React.Fragment>
                                            {currencyPrefix}{priceIntegers}

                                            {!!priceDecimals && (
                                                <PlanPriceFraction>
                                                    .{priceDecimals}
                                                </PlanPriceFraction>
                                            )}

                                            {!!termTypeShort && (
                                                <TermWrapper>
                                                    /{termTypeShort}
                                                </TermWrapper>
                                            )}

                                            {displayAmount === displayRegularAmount && (
                                                <RegularPriceBadge>Regular Price</RegularPriceBadge>
                                            )}
                                        </React.Fragment>
                                    )
                                    : 'Free'}
                            </PlanPrice>
                        )}
                    </PlanDetails>
                )}
            </CurrentPlan>

            {React.Children.map(children, (child) => {
                if (child === null) {
                    return null;
                }

                return (
                    <div key={child.key}>
                        <GA20PixelDiv />
                        {child}
                    </div>
                );
            })}
        </StyledGA89Tile>
    );
};

TM10SubTile.propTypes = {
    /** Children */
    children: propTypes.node,
    /** Optional additional class(es) */
    className: classNameType,
    /** Package object from API */
    packageDetails: propTypes.shape({
        displayDescription: propTypes.shape({
            /** The whole thing is a CTA, but put the pertinent CTA text here */
            ctaText: propTypes.string,
        }),
        /** Name of the package */
        displayName: propTypes.string,
        /** Package features */
        features: propTypes.arrayOf(propTypes.shape({
            title: propTypes.node,
            a11yTitle: propTypes.string,
            value: propTypes.node,
            a11yValue: propTypes.string,
        })),
        /** Package pricing, right now we’re pulling out month specifically */
        price: propTypes.shape({
            termType: termTypePropType,
            displayRegularAmount: propTypes.number,
            displayAmount: propTypes.number,
            /** Whether, and how many decimal places to display */
            currencyDecimalPlaces: propTypes.number,
            /** What currency symbol to use */
            currencyPrefix: propTypes.oneOf(['$']),
        }),
    }),
    /** Link to go to */
    href: propTypes.string,
    /** Brand logo */
    icon: propTypes.node,
    /** is active/selected */
    isPressed: propTypes.bool,
    /** Click handler for parent node */
    onClick: propTypes.func,
};

TM10SubTile.displayName = 'TM10SubTile';

export default TM10SubTile;
