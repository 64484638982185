import {css, keyframes} from 'styled-components';

export const TRANSITION_DURATION_S = 0.1;
export const TRANSITION_DURATION_MS = Math.round(TRANSITION_DURATION_S * 1000);
export const TRANSITION_TIMING_FUNCTION = 'ease-in';

export const TRANSITION_CLASSNAMES = {
    appear: 'appear',
    appearActive: 'appear-active',
    enter: 'enter',
    enterActive: 'enter-active',
    enterDone: 'enter-done',
    exit: 'exit',
    exitActive: 'exit-active',
    exitDone: 'exit-done',
};

/**
 * Create the value of a transition rule for one or more CSS properties, using standard
 * values for duration and timing function.
 *
 * @param {string[]} props - an array of CSS properties
 * @returns {string} the transition rule value
 */
export const transition = (...props) => props
    .map((prop) => `${prop} ${TRANSITION_DURATION_S}s ${TRANSITION_TIMING_FUNCTION}`)
    .join(', ');

export const fadeInOut = css`
    transition: opacity ${TRANSITION_DURATION_MS}ms linear;

    &.enter {
        opacity: 0;
    }

    &.enter-active {
        opacity: 1;
    }

    &.enter-done {
        opacity: 1;
    }

    &.exit {
        opacity: 1;
    }

    &.exit-active {
        opacity: 0;
    }

    &.exit-done {
        opacity: 0;
    }
`;

export const fadeAnimation = keyframes`
    0% { opacity: 0.2; }

    100% { opacity: 1; }
`;

export const subtleFadeAnimation = keyframes`
    0% { opacity: 0.8; }

    100% { opacity: 1; }
`;

const SCALE_OUT_FACTOR = 0.95;

export const scaleInOut = css`
    transition: ${transition('transform', 'opacity')};

    &.enter {
        transform: scale(${SCALE_OUT_FACTOR});
        opacity: 0;
    }

    &.enter-active {
        transform: scale(1);
        opacity: 1;
    }

    &.enter-done {
        transform: scale(1);
        opacity: 1;
    }

    &.exit {
        transform: scale(1);
        opacity: 1;
    }

    &.exit-active {
        transform: scale(${SCALE_OUT_FACTOR});
        opacity: 0;
    }

    &.exit-done {
        transform: scale(${SCALE_OUT_FACTOR});
        opacity: 0;
    }
`;

export default {
    TRANSITION_DURATION_S,
    TRANSITION_DURATION_MS,
    TRANSITION_TIMING_FUNCTION,
    TRANSITION_CLASSNAMES,
    transition,
    scaleInOut,
    fadeInOut,
};
