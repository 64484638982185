import styled from 'styled-components';

import {palette as infinityPalette} from '@fsa-streamotion/streamotion-web-infinity';
import {palette as ionicPalette} from '@fsa-streamotion/streamotion-web-ionic';
import {palette as muiPalette} from '@fsa-streamotion/streamotion-web-mui';
import {palette as nucleusPalette} from '@fsa-streamotion/streamotion-web-nucleus';
import {stylesWhenBinge, stylesWhenFlash, stylesWhenKayo, stylesWhenLifestyle} from '../../utils/styles-when-brand';

const {blanc, eli} = ionicPalette;
const {smoke, fog} = infinityPalette;
const {shark, silverLining} = muiPalette;
const {slate} = nucleusPalette;

export const Paragraph = styled.p`
    ${stylesWhenBinge`
        color: ${blanc};
        font: var(--ionic-body-copy-3);

        > b,
        > strong {
            font: var(--ionic-body-copy-3-bold);
        }
    `}

    ${stylesWhenFlash`
        color: ${fog};
        font: var(--infinity-body-copy-3);

        > b,
        > strong {
            font: var(--infinity-body-copy-3-bold);
        }
    `}

    ${stylesWhenKayo`
        color: ${silverLining};
        font: var(--mui-body-copy-2-light);

        > b,
        > strong {
            font: var(--mui-body-copy-2-bold);
        }
    `}

    ${stylesWhenLifestyle`
        color: ${blanc};
        font: var(--nucleus-body-copy-3);

        > b,
        > strong {
            font: var(--nucleus-body-copy-3-bold);
        }
    `}
`;

export const ParagraphFooter = styled.p`
    text-align: center;

    ${stylesWhenBinge`
        color: ${eli};
        font: var(--ionic-body-copy-4);

        > b,
        > strong {
            font: var(--ionic-body-copy-4-bold);
        }
    `}

    ${stylesWhenFlash`
        color: ${smoke};
        font: var(--infinity-body-copy-4);

        > b,
        > strong {
            font: var(--infinity-body-copy-4-bold);
        }
    `}

    ${stylesWhenKayo`
        text-align: center;
        color: ${shark};
        font: var(--mui-body-copy-3);

        > b,
        > strong {
            font: var(--mui-body-copy-3-bold);
        }
    `}

    ${stylesWhenBinge`
        color: ${slate};
        font: var(--nucleus-body-copy-4);

        > b,
        > strong {
            font: var(--nucleus-body-copy-4-bold);
        }
    `}
`;
