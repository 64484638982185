import React from 'react';
import Ic from '..';

const IC97Eq = (args) => (
    <Ic {...args}>
        <path d="M39.042 34.844h8.4V82.85h-8.4zM25 54.01h8.4v28.798H25zM53.084 16h8.4v67.2h-8.4zm14.098 38.01h8.4v28.798h-8.4z" />
    </Ic>
);

IC97Eq.displayName = 'IC97Eq';

export default IC97Eq;
