import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {Nav} from '../../../../common/normalized-styled-components';
import {flash} from '../../../../common/palette';
import asNavigationRow from '../../../util/navigation/hocs/as-row';
import TB01NavT, {StyledLink} from '../../../atoms/tb/01-nav-t';

const StyledNav = styled(Nav)`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 64px;
    text-align: center;
`;

const ItemsList = asNavigationRow(styled.ul`
    display: flex;
    position: relative;
    justify-content: space-evenly;
    gap: 8px;
    margin: 0;
    padding: 0 10px;
    list-style: none;
`);

const StyledLabel = styled.span`
    text-align: center;
    font: var(--infinity-doc-nav-title);
`;

const StyledIcon = styled.div`
    align-self: center;
    width: 28px;
    height: 28px;
`;

const StyledTB01NavT = styled(TB01NavT)`
    flex: 1 0 52px;
    /* selected state */
    ${stylesWhen('isActive')`
        ${StyledLabel} {
            font-weight: 600;
        }

        ${StyledLink} {
            color: ${flash};
        }
    `}
    /* press state */
    &[aria-pressed='true'],
    &:active {
        ${StyledLabel} {
            font-weight: 600;
        }
    }

    ${StyledLink} {
        text-shadow: none;
    }
`;

export default function NM02DockNav({className, menuItems = []}) {
    return (
        <StyledNav className={classnames('NM02DockNav', className)}>
            <ItemsList isLoop={true}>
                {menuItems.map(({title, icon, isActive, location, onClick}) => (
                    <StyledTB01NavT
                        key={title}
                        isActive={isActive}
                        url={location}
                        onClick={onClick}
                        tabIndex={isActive ? 0 : -1} // make the active item tabbable. the others will be accessed via cursors
                    >
                        <StyledIcon>
                            {icon}
                        </StyledIcon>
                        <StyledLabel>
                            {title}
                        </StyledLabel>
                    </StyledTB01NavT>
                ))}
            </ItemsList>
        </StyledNav>
    );
}

NM02DockNav.displayName = 'NM02DockNav';

NM02DockNav.propTypes = {
    /** Additional className(s) string. e.g. for extending component */
    className: classNameType,
    /** Menu item objects which are to appear in the dock nav */
    menuItems: propTypes.arrayOf(
        propTypes.shape({
            /** Action to take on click of this menu item */
            onClick: propTypes.func,
            /** Anchor href to render with */
            location: propTypes.string,
            /** Icon to use... typically an ICXX */
            icon: propTypes.node,
            /** Is this menu item considered "active", e.g. represents the current route */
            isActive: propTypes.bool,
            /** title to show in the menu item */
            title: propTypes.string,
        })
    ),
};
