import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import noop from 'lodash/noop';
import {rgba} from 'polished';
import {white} from '../../../../../common/palette';
import {slate, black, coal} from '../../../../../common/deprecated-mui-palette';
import {fontFamily} from '../../../../../common/typography';
import {transition} from '../../../../../common/animations';

const KEY_MOMENT_SHADOW = `0 2px 4px 0 ${rgba(black, 0.29)}`;

const KeyMomentContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    width: 37px;
    pointer-events: none;
`;

const Stem = styled.div`
    box-shadow: ${KEY_MOMENT_SHADOW};
    background-color: ${slate};
    width: 2px;
    height: 10px;
    pointer-events: none;
`;

const Thumb = styled.div`
    border-radius: 50%;
    box-shadow: ${KEY_MOMENT_SHADOW};
    background-color: ${slate};
    width: 9px;
    height: 9px;
    pointer-events: none;
`;

const Head = styled.button`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    transition: ${transition('width', 'height')};
    outline: none;
    border: none;
    border-radius: 2px;
    box-shadow: ${KEY_MOMENT_SHADOW};
    background-color: ${slate};
    padding: 0 8px;
    width: 100%;
    height: 24px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    color: ${coal};
    font-family: ${fontFamily};
    font-weight: 600;
    pointer-events: all;

    &:active,
    &:focus,
    &:hover {
        background-color: ${white};
        width: calc(100% + 4px);
        height: 26px;

        ~ ${Thumb} {
            background-color: ${white};
        }

        ~ ${Stem} {
            transition: ${transition('height')};
            background-color: ${white};
            height: 12px;
        }
    }
`;

class VPGA10KeyMoment extends React.Component {
    static displayName = 'VPGA10KeyMoment';

    static propTypes = {
        /** Abbreviated text */
        abbrText: propTypes.string.isRequired,
        /** Title */
        title: propTypes.string,
        /** Function to execute on click */
        onClick: propTypes.func,
        /** Function to execute on mouse enter */
        onMouseEnter: propTypes.func,
        /** Function to execute on mouse leave */
        onMouseLeave: propTypes.func,
        /** Func to exected on mount */
        componentWillUnmount: propTypes.func,
        /** Function to execute on focus */
        onFocus: propTypes.func,
        /** Function to execute on blur */
        onBlur: propTypes.func,
    }

    static defaultProps = {
        title: '',
        onClick: noop,
    }

    componentWillUnmount() {
        if (this.props.componentWillUnmount) {
            this.props.componentWillUnmount();
        }
    }

    render() {
        const {
            abbrText,
            title,
            onClick,
            onMouseEnter,
            onMouseLeave,
            onFocus,
            onBlur,
        } = this.props;

        return (
            <KeyMomentContainer>
                <Head
                    onClick={onClick}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onFocus={onFocus}
                    onBlur={onBlur}
                >
                    <abbr style={{textDecoration: 'none'}} title={title}>
                        {abbrText}
                    </abbr>
                </Head>
                <Stem />
                <Thumb />
            </KeyMomentContainer>
        );
    }
}

export default VPGA10KeyMoment;
