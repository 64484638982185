import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

const StyledSvg = styled.svg`
    display: block;
    width: auto;
    height: 100%; /* Scaled by HEIGHT :O */
    fill: currentColor;
`;

const GA107Hd = ({
    className,
    ...htmlAttributes
}) => (
    <StyledSvg
        {...htmlAttributes}
        className={classnames('GA107Hd', className)}
        viewBox="0 0 167 100"
    >
        <path d="M166.667 0v100H0V0h166.667zM43.759 23.619H31.416V76.19H43.76V54.171h21.105v22.02h12.343V23.618H64.864v20.343H43.759V23.619zm63.117 0H89.048V76.19h17.828c16.61 0 28.8-8.685 28.8-26.285 0-17.524-12.266-26.286-28.8-26.286zm.686 10.21c9.98 0 15.619 6.171 15.619 16.076 0 9.752-5.867 15.847-15.62 15.847h-6.247V33.83z" />
    </StyledSvg>
);

GA107Hd.propTypes = {
    /** Additional class(es) */
    className: classNameType,
};

GA107Hd.displayName = 'GA107Hd';

export default GA107Hd;
