import React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';
import {Progress} from 'normalized-styled-components';
import styled from 'styled-components';
import property from 'lodash/property';

import {classNameType, colorType} from '@fsa-streamotion/custom-prop-types';

import {slate, kayoGreen} from '../../../../common/palette';

const StyledProgress = styled(Progress)`
    appearance: none;
    display: block;
    border: 0;
    border-radius: 4px;
    background-color: ${slate};
    width: 100%;
    height: 5px;

    ::-moz-progress-bar {
        background-color: ${property('color')};
    }

    ::-webkit-progress-value {
        background-color: ${property('color')};
    }

    ::-webkit-progress-bar {
        background-color: inherit;
    }

    ::-ms-fill {
        border: none;
        background-color: ${property('color')};
    }
`;

const GA01ProBar = ({
    className,
    current = 0,
    total = 100,
    progressColor = kayoGreen,
    ...htmlAttributes
}) => (
    <StyledProgress
        className={classnames('GA01ProBar', className)}
        value={current}
        max={total}
        color={progressColor}
        {...htmlAttributes}
    />
);

GA01ProBar.displayName = 'GA01ProBar';

GA01ProBar.propTypes = {
    /** Additional CSS class(es) */
    className: classNameType,
    /** Current value of progress bar, e.g. elapsed time */
    current: propTypes.number,
    /** Total value of progress bar, e.g. video duration */
    total: propTypes.number,
    /** Color of the progress bar - defaults to KayoGreen */
    progressColor: colorType,
};

export default GA01ProBar;
