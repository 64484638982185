import styled from 'styled-components';
import {rgba} from 'polished';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import {onyx, smoke} from '../../../../common/palette';

const GA104TMFooter = styled.footer`
    box-sizing: border-box;
    margin: 0;
    border: none;
    box-shadow: 0 11px 30px 0 ${rgba(onyx, 0.3)};
    background-color: ${rgba(onyx, 0.4)};
    padding: 7px;
    width: 100%;
    overflow-x: hidden;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${smoke};
    font: var(--nucleus-tile-descriptor);

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding: 11px;
    `}
`;

GA104TMFooter.displayName = 'GA104TMFooter';

export default GA104TMFooter;
