import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import {ink} from '../../../../common/palette';

import Ic from '..';

const IC217MultiViewBtn = ({
    secondaryColor = ink,
    ...args
}) => (
    <Ic {...args}>
        <path opacity=".2" d="M50 6.698C26.637 6.698 7.698 25.637 7.698 49c0 23.364 18.939 42.302 42.302 42.302S92.302 72.364 92.302 49c0-23.363-18.94-42.302-42.302-42.302" />
        <g fill={secondaryColor}>
            <path opacity=".35" d="M50.99 31.86h17.15v11.27H50.99z" />
            <path d="M69.218 29.508H49.863a1.37 1.37 0 0 0-1.372 1.372v13.622a1.37 1.37 0 0 0 1.372 1.372h19.355a1.37 1.37 0 0 0 1.372-1.372V30.831c0-.735-.637-1.323-1.372-1.323zm-1.372 13.573h-16.66V32.203h16.66v10.878zM51.97 53.126H31.096a.67.67 0 0 0-.686.686v15.043a.67.67 0 0 0 .686.686H51.97a.67.67 0 0 0 .686-.686V53.812a.7.7 0 0 0-.686-.686zm-.686 15.043H31.782V54.498h19.502v13.671zM34.087 49.365c.05-5.586 2.842-10.486 7.35-13.28l-.611 3.553a1.018 1.018 0 1 0 2.009.343l1.282-7.472-7.307-1.12a1.037 1.037 0 0 0-1.18.85 1.023 1.023 0 0 0 .833 1.18l3.21.56a18.638 18.638 0 0 0-8.33 15.386h2.744zm32.877-.061c-.049 5.586-2.842 10.486-7.35 13.279l.611-3.553a1.018 1.018 0 1 0-2.008-.343l-1.282 7.473 7.307 1.12a1.037 1.037 0 0 0 1.178-.85 1.023 1.023 0 0 0-.832-1.18l-3.21-.56a18.638 18.638 0 0 0 8.33-15.386h-2.744z" />
        </g>
    </Ic>
);

IC217MultiViewBtn.propTypes = {
    /** The icon's secondary color */
    secondaryColor: colorType,
};

IC217MultiViewBtn.displayName = 'IC217MultiViewBtn';

export default IC217MultiViewBtn;
