import React, {cloneElement} from 'react';
import styled, {css} from 'styled-components';
import propTypes from 'prop-types';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import EMPTY_IMAGE_SRC from '../../../../common/empty-image-src';
import tileSize from '../../../../common/standard-tile-size';
import {transition} from '../../../../common/animations';

import {white} from '../../../../common/palette';

import TileProgressBar from '../../../../common/tile-progress-bar';

const baseCssToReplaceWithAsOperatorInStyledComponentsV4 = css`
    ${tileSize};
    display: block;
    position: relative;
    transform: translateZ(0);
    /** Port over GA07's base hover/focus behavior */
    &:hover,
    &:focus {
        transform: scale(1.1);
        transition: ${transition('transform')};
        transition-delay: 100ms;
    }
`;

const NonInteractive = styled.div`
    ${baseCssToReplaceWithAsOperatorInStyledComponentsV4}
`;

const StyledButton = styled(NonInteractive.withComponent('button'))`
    ${baseCssToReplaceWithAsOperatorInStyledComponentsV4}

    appearance: none;
    border: 0;
    background: transparent;
    padding: 0;

    &:focus {
        outline: none;
    }
`;

const StyledLink = styled(NonInteractive.withComponent('a'))`
    ${baseCssToReplaceWithAsOperatorInStyledComponentsV4}

    text-decoration: inherit;
    color: inherit;

    &:focus {
        outline: none;
    }
`;

function getCtaType(href, onClick) {
    if (href) {
        return StyledLink;
    } else if (onClick) {
        return StyledButton;
    } else {
        return NonInteractive;
    }
}

const TM21VideoTile = React.forwardRef(({
    card,
    className,
    href,
    isBeingLazy = false,
    label,
    lazySrc = EMPTY_IMAGE_SRC,
    onClick,
    progressBar: {
        currentPercentage = 0,
        progressLabel,
        barColor = white,
    } = {},
    ...htmlAttributes
}, ref) => {
    const Cta = getCtaType(href, onClick);

    return (
        <Cta
            {...htmlAttributes}
            ref={ref}
            aria-label={label}
            className={classnames('TM21VideoTile', className)}
            href={href}
            onClick={onClick}
        >
            {cloneElement(
                card,
                {isBeingLazy, lazySrc}
            )}
            {!!currentPercentage && (
                <TileProgressBar
                    barColor={barColor}
                    currentPercentage={currentPercentage}
                    progressLabel={progressLabel}
                />
            )}
        </Cta>
    );
});

TM21VideoTile.propTypes = {
    /** Tile image `eg. GA0TileImg (Standard) or GA90FixTil (Fixture)` */
    card: propTypes.node,
    /** Additional Class(es) */
    className: classNameType,
    /** URL to redirect user to when tile is clicked */
    href: propTypes.string,
    /** Is the image being lazy loaded? */
    isBeingLazy: propTypes.bool,
    /** Aria label for the tile */
    label: propTypes.string,
    /** Image source URL for lazy loading */
    lazySrc: propTypes.string,
    /** onClick handler */
    onClick: propTypes.func,
    /** Progress / Continue watching indicator */
    progressBar: propTypes.shape({
        barColor: propTypes.string,
        currentPercentage: propTypes.number,
        progressLabel: propTypes.string,
    }),
    /** Resume label */
    resumeLabel: propTypes.string,
};

TM21VideoTile.displayName = 'TM21VideoTile';

export default TM21VideoTile;
