import React from 'react';
import Ic from '..';

const IC25ArrowR = (args) => (
    <Ic {...args}>
        <path d="M5.641 80L0 74.369 34.432 40 0 5.631 5.641 0 45.714 40z" transform="translate(29 10)" />
    </Ic>
);

IC25ArrowR.displayName = 'IC25ArrowR';

export default IC25ArrowR;
