import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

const StyledSection = styled.section`
    width: 100%;
`;

const OR02Stand = ({children, className, ...htmlAttributes}) => (
    <StyledSection
        {...htmlAttributes}
        className={classnames('OR02Stand', className)}
    >
        {children}
    </StyledSection>
);

OR02Stand.propTypes = {
    /** Carousel CAM01Stand */
    children: propTypes.node,
    /** Additional CSS classnames to be applied */
    className: classNameType,
};

OR02Stand.displayName = 'OR02Stand';

export default OR02Stand;
