import React from 'react';
import Ic from '..';

const IC122ActionBack = (args) => (
    <Ic {...args}>
        <path d="M49.128 7.397c-23.157 0-41.93 18.772-41.93 41.93 0 23.157 18.773 41.928 41.93 41.928 23.156 0 41.928-18.77 41.928-41.929 0-23.157-18.772-41.929-41.928-41.929m0 5.5c20.088 0 36.43 16.342 36.43 36.43 0 20.087-16.342 36.43-36.43 36.43s-36.43-16.343-36.43-36.43c0-20.088 16.342-36.43 36.43-36.43" />
        <path fillRule="nonzero" d="M33.183 54.96a5.942 5.942 0 0 1-3.435.873h-5.54V42.44h4.556a7.244 7.244 0 0 1 3.297.568 2.992 2.992 0 0 1 1.703 2.77 3.13 3.13 0 0 1-1.953 2.95 3.269 3.269 0 0 1 2.77 3.186 3.393 3.393 0 0 1-1.398 3.047zm-3.089-9.916a2.355 2.355 0 0 0-1.385-.277h-1.302v3.088h1.233a2.452 2.452 0 0 0 1.454-.29c.447-.27.703-.768.665-1.289.018-.5-.237-.972-.665-1.232zm.651 5.29a2.77 2.77 0 0 0-1.676-.36h-1.676v3.408h1.731a2.77 2.77 0 0 0 1.621-.277 1.676 1.676 0 0 0 0-2.77zm18.435 5.541h-3.42l-.943-2.77H40.04l-.984 2.77h-3.227l5.042-13.408h3.269l5.041 13.408zm-5.083-5.152-1.676-4.807-1.662 4.807h3.338zm17.784 3.517a6.371 6.371 0 0 1-4.82 1.815 6.635 6.635 0 0 1-7.022-6.925 6.648 6.648 0 0 1 7.022-6.926 6.58 6.58 0 0 1 4.599 1.51l-.97 2.604a5.11 5.11 0 0 0-3.449-1.385 3.85 3.85 0 0 0-4.016 4.155 3.864 3.864 0 0 0 4.016 4.155c1.34.05 2.64-.45 3.602-1.385l1.038 2.383zM75.4 55.875h-3.768l-3.352-5.54-1.066 1.19v4.35h-3.13V42.44h3.13v4.89l4.155-4.89h3.698l-4.709 5.332z" />
    </Ic>
);

IC122ActionBack.displayName = 'IC122ActionBack';

export default IC122ActionBack;
