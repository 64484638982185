export const XS_HERO_FIXED_HEIGHT_PX = 600;
export const XL_HERO_FIXED_HEIGHT_PX = 960;
export const XS_HERO_VERTICAL_OVERFLOW_PX = 130;
export const XL_HERO_VERTICAL_OVERFLOW_PX = 180;

export const XS_FEATURE_HEIGHT_PX = 30;
export const MD_FEATURE_HEIGHT_PX = 32;
export const LG_FEATURE_HEIGHT_PX = 36;

export const CONTENT_EDGE_SPACING_PERCENT = 5;

export default {
    XS_HERO_FIXED_HEIGHT_PX,
    XL_HERO_FIXED_HEIGHT_PX,
    XS_HERO_VERTICAL_OVERFLOW_PX,
    XL_HERO_VERTICAL_OVERFLOW_PX,
    XS_FEATURE_HEIGHT_PX,
    MD_FEATURE_HEIGHT_PX,
    LG_FEATURE_HEIGHT_PX,
    CONTENT_EDGE_SPACING_PERCENT,
};
