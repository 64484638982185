import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {NAV_BAR_HEIGHT_PX} from '../../../../../../common/header-nav-constants';
import GA92BrandLogo from '../../../../../atoms/ga/92-brand-logo';

const HomeLink = styled.div`
    align-self: flex-start;
    cursor: default;
    text-decoration: none;
`;

const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    height: ${NAV_BAR_HEIGHT_PX}px;
`;

// eslint-disable-next-line jsdoc/require-param, jsdoc/require-returns
/**
 * This contains the brand logo which is also a link to the homepage
 */
const LogoHomeLink = ({
    brandLogoId,
    homeLink = '/',
    homeLinkAssistiveLabel = 'Home page',
    onHomeLinkClick,
}) => (
    <HomeLink
        className="OR33HeaderDsk-HomeLink"
        href={homeLink}
        title={homeLinkAssistiveLabel}
        onClick={onHomeLinkClick}
        aria-label={homeLinkAssistiveLabel}
    >
        <LogoContainer>
            <GA92BrandLogo
                width="122px"
                id={brandLogoId}
            />
        </LogoContainer>
    </HomeLink>
);

LogoHomeLink.displayName = 'LogoHomeLink';

LogoHomeLink.propTypes = {
    /** Hydrated Id for the logo */
    brandLogoId: propTypes.string,
    /** For the logo link to go home */
    homeLink: propTypes.string,
    /** Assistive label text For the logo link */
    homeLinkAssistiveLabel: propTypes.string,
    /** Callback on home link click */
    onHomeLinkClick: propTypes.func,
};

export default LogoHomeLink;
