import findKey from 'lodash/findKey';

const PAYMENT_FIELD_NAMES = {
    creditCardHolderName: 'name on card',
    creditCardNumber: 'card number',
    creditCardExpirationMonth: 'expiry date',
    creditCardExpirationYear: 'expiry date',
    cardSecurityCode: 'CVV',
};

export function parseZuoraClientSideError({key, code, message}) {
    // Zuora client side validation error codes and the corresponding error messages.
    // 001: Required field not completed
    // 002: Invalid card number
    // 003: Invalid card type
    // 004: Invalid CVV number
    if (code === '001') {
        const fieldName = PAYMENT_FIELD_NAMES[key];

        if (fieldName) {
            return `${fieldName} is missing or invalid`;
        } else {
            return 'credit card details are missing or invalid';
        }
    }

    return message;
}

const SOFT_ERRORS = [
    'Do Not Honor',
    'Insufficient Funds',
    'Limit Exceeded',
    'Cardholder\'s Activity Limit Exceeded',
    'Voice Authorization Required',
    'Processor Declined – Possible Lost Card',
    'Processor Declined – Possible Stolen Card',
    'Processor Declined – Fraud Suspected',
    'Transaction Not Allowed',
    'Duplicate Transaction',
    'Invalid Transaction',
    'Violation',
    'Declined – Updated Cardholder Available',
    'Processor Does Not Support This Feature',
    'Card Type Not Enabled',
    'Set Up Error – Merchant',
    'Invalid Merchant ID',
    'Set Up Error – Amount',
    'Set Up Error – Hierarchy',
    'Set Up Error – Card',
    'Set Up Error – Terminal',
    'Encryption Error',
    'Surcharge Not Permitted',
    'Partial Approval For Amount In Group III Version',
    'Authorization could not be found to reverse',
    'Processor Declined',
    'Declined – Call For Approval',
    'Call Issuer. Pick Up Card',
    'Card reported as lost or stolen',
    'Issuer or Cardholder has put a restriction on the card',
    'Merchant not Mastercard SecureCode enabled',
    'No Data Found - Try Another Verification Method',
];

const HARD_ERRORS = [
    'Invalid Credit Card Number',
    'Invalid Expiration Date',
    'No Account',
    'Card Account Length Error',
    'No Such Issuer',
    'Card Issuer Declined CVV',
    'Security Violation',
    'Gateway Rejected: fraud',
    'Gateway Rejected: fraud',
    'Gateway Rejected: 3D Secure',
    'Gateway Rejected: Application Incomplete',
    'Gateway Rejected: AVS',
    'Gateway Rejected: CVV',
    'Gateway Rejected: Duplicate',
    'Gateway Rejected: Token Issuance',
];

const NETWORK_ERRORS = [
    'Inconsistent Data',
    'No Action Taken',
];

const CUSTOMER_CARE_ERRORS = [
    'Invalid Merchant Number',
    'Invalid Amount',
    'Invalid SKU Number',
    'Invalid Transaction Division Number',
    'Transaction amount exceeds the transaction division limit',
    'Invalid Currency Code',
    'PayPal Merchant Account Configuration Error',
];

const EXPIRED_CARD_ERRORS = [
    'Expired Card',
];

const PAYMENT_METHOD_ERRORS = [
    'Address Verification Failed',
    'Address Verification and Card Security Code Failed',
    'PayPal Business Account Restricted',
    'Authorization Expired',
    'PayPal Business Account Locked or Closed',
    'PayPal Blocking Duplicate Order IDs\t',
    'PayPal Buyer Revoked Future Payment Authorization',
    'PayPal Payee Account Invalid Or Does Not Have a Confirmed Email',
    'PayPal Payee Account Invalid Or Does Not Have a Confirmed Email',
    'PayPal Validation Error',
    'Funding Instrument In The PayPal Account Was Declined By The Processor Or Bank, Or It Can\'t Be Used For This Payment',
    'Payer Account Is Locked Or Closed',
    'Payer Cannot Pay For This Transaction With PayPal',
    'Transaction Refused Due To PayPal Risk Model',
    'PayPal pending payments are not supported',
    'PayPal Domestic Transaction Required',
    'PayPal Phone Number Required',
    'PayPal Tax Info Required',
    'PayPal Payee Blocked Transaction',
    'PayPal Transaction Limit Exceeded',
    'PayPal reference transactions not enabled for your account',
    'Address',
    'Currency not enabled for your PayPal seller account',
    'PayPal payee email permission denied for this request',
    'PayPal account not configured to refund more than settled amount',
    'Currency of this transaction must match currency of your PayPal account',
];

const DEFAULT_MESSAGE = 'One or more general system errors have occurred. Please contact Customer Care.';

/**
 * Zuora error parser. Some server side error messages examples:
 *
 *  - [GatewayTransactionError]&nbsp; Transaction declined.CREDIT_CARD_CVV_IS_I - CVV must be 4 digits for American Express and 3 digits for other card types.
 *  - [GatewayTransactionError]&nbsp; Transaction declined.GATEWAY_REJECTED - Gateway Rejected: fraud
 *  - [GatewayTransactionError]&nbsp; Transaction declined.PROCESSOR_DECLINED - Do Not Honor
 *  - [BusinessValidationError]&nbsp; Expiration date must be a future date.
 *
 * @param   {Object} zuoraDetailedErrorMessage     The server-side error message to be parsed
 * @returns {string} parsedErrorMessage            an error message on any condition to be displayed to the user.
 */

export function parseZuoraServerSideError(zuoraDetailedErrorMessage) {
    if (!zuoraDetailedErrorMessage) {
        return DEFAULT_MESSAGE;
    }

    let error;

    const zuoraErrorMapObj = {
        'Your transaction was declined. Please use another card or contact your bank.': SOFT_ERRORS,
        'Your transaction was declined. Please confirm the details provided or contact your bank.': HARD_ERRORS,
        'Your transaction was declined due to network issues. Please confirm the details provided, and resubmit your payment.': NETWORK_ERRORS, // eslint-disable-line max-len
        'Your transaction was declined. Please contact Customer Care.': CUSTOMER_CARE_ERRORS,
        'Your transaction was declined as your credit card has expired, please update your payment details.': EXPIRED_CARD_ERRORS,
        'Your transaction was declined. Please use another payment method.': PAYMENT_METHOD_ERRORS,
    };

    const zuoraErrorCode = zuoraDetailedErrorMessage.substring(
        zuoraDetailedErrorMessage.indexOf('[') + 1, zuoraDetailedErrorMessage.indexOf(']')
    );

    switch (zuoraErrorCode) {
        case 'GatewayTransactionError':
            [, error] = zuoraDetailedErrorMessage.split('-');
            break;

        case 'BusinessValidationError':
            [, error] = zuoraDetailedErrorMessage.split(';');
            break;

        default:
            break;
    }

    if (error) {
        const errorMessageToDisplay = findKey(zuoraErrorMapObj, (errorType) => errorType.includes(error.trim()));

        return errorMessageToDisplay || error.trim();
    }

    return zuoraDetailedErrorMessage;
}
