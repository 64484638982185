import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {satellite} from '../../../../common/palette';
import GA89Tile from '../../../atoms/ga/89-tile';

const TileTitle = styled.h4`
    margin: 0;
    color: ${satellite};
    font: var(--mui-body-copy-3);
`;

const TileBody = styled.p`
    margin: 0;
    padding-top: 4px;
    color: ${satellite};
    font: var(--mui-body-copy-4);
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const TM27InfoTile = ({
    icon,
    title,
    children,
    className,
}) => (
    <GA89Tile className={classnames('TM27InfoTile', className)}>
        <TitleContainer>
            {icon}
            <TileTitle>
                {title}
            </TileTitle>
        </TitleContainer>
        <TileBody>
            {children}
        </TileBody>
    </GA89Tile>
);

TM27InfoTile.displayName = 'TM27InfoTile';

TM27InfoTile.propTypes = {
    className: classNameType,
    children: propTypes.node,
    /** Icon for the tile */
    icon: propTypes.node,
    /** Title for the tile */
    title: propTypes.node,
};

export default TM27InfoTile;
