import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

const Score = styled.abbr`
    display: block;
    text-decoration: none;
    line-height: 1;

    :not(:first-of-type) {
        margin-top: 7px;
    }
`;

const CricketScore = ({
    isAllOut,
    isDeclared,
    runs,
    wickets,
}) => {
    const scoreDescription = [[isDeclared, 'Declared'], [wickets, 'Wickets'], [runs, 'Runs']]
        .filter(([scoreFragment]) => scoreFragment !== null)
        .map(([scoreFragment, label]) => {
            let massagedScoreFragment = scoreFragment;

            if (typeof scoreFragment === 'boolean') {
                massagedScoreFragment = scoreFragment ? 'Yes' : 'No';
            }

            return `${label}: ${massagedScoreFragment}`;
        })
        .join(', ');

    return (
        <Score
            className="BM01GnrBnr__score"
            aria-label={scoreDescription}
            title={scoreDescription}
        >
            {!isAllOut && `${wickets}/`}{runs}{!!isDeclared && 'd'}
        </Score>
    );
};

CricketScore.displayName = 'CricketScore';

CricketScore.propTypes = {
    isAllOut: propTypes.bool,
    isDeclared: propTypes.bool,
    runs: propTypes.number,
    wickets: propTypes.number,
};

export default CricketScore;
