/**
 * @typedef {Object} Time
 * @property {number} minutes           The minutes
 * @property {number} remainingSeconds  The remaining seconds
 */

/**
 * Converts seconds to minutes and seconds
 *
 * @param {Number} seconds  An integer denoting the amount of seconds to convert
 * @returns {Time}          An object containing the converted minutes and seconds
 */
export function convertToMinutesAndSeconds(seconds) {
    if (typeof seconds !== 'number') {
        throw new Error(`getMinutesAndSeconds error: invalid type was parsed. Expected number but got ${typeof seconds}`);
    }

    return ({
        minutes: Math.floor(seconds / 60),
        remainingSeconds: seconds % 60,
    });
}

/**
 * Converts seconds to a timer denoting the minutes and seconds
 *
 * @param {Number} seconds  An integer denoting the amount of seconds to convert
 * @returns {string}        A string in the form of "xx:xx"
 */
export function secondsToTime(seconds) {
    if (seconds === 0) {
        return '00:00';
    }

    const padZero = (num) => num.toString().padStart(2, '0');

    const {minutes, remainingSeconds} = convertToMinutesAndSeconds(seconds);

    return `${padZero(minutes)}:${padZero(remainingSeconds)}`;
}

/**
 * Builds a plural/non-plural string based on the value
 *
 * @param {Number} value    A value to check
 * @param {string} name     The name of the value
 * @returns {string}        A pluralised string
 */
function buildPluralString(value, name) {
    return `${value} ${name}${value === 1 ? '' : 's'}`;
}

/**
 * Converts seconds to a string denoting the minutes and seconds
 *
 * @param {Number} seconds  An integer denoting the amount of seconds to convert
 * @returns {string}        A string labelling the amount of minutes and seconds
 */
export function buildTimeLabel(seconds) {
    if (seconds === 0) {
        return '0 seconds';
    }

    const {minutes, remainingSeconds} = convertToMinutesAndSeconds(seconds);

    const minuteString = `${minutes ? buildPluralString(minutes, 'minute') : ''}`;
    const secondString = `${remainingSeconds ? buildPluralString(remainingSeconds, 'second') : ''}`;

    return `${minuteString}${minuteString && secondString ? ' and ' : ''}${secondString}`;
}
