import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {palette as ionicPalette, screenSizes} from '@fsa-streamotion/streamotion-web-ionic';
import {palette as infinityPalette} from '@fsa-streamotion/streamotion-web-infinity';

import {stylesWhenBinge, stylesWhenLifestyle} from '../../../../utils/styles-when-brand';

import {BrandedGA05BgImg} from '../../../../utils/branded-components';

import {RESOURCES_IMWIDTHS} from '../../../../../../todo-move-to-widgets-common/utils/imwidth';
import {imageUrlToSrcsetOptions} from '../../../../../../todo-move-to-widgets-common/utils/srcset-helpers';

const {
    SMALLEST_SUPPORTED_WIDTH,
    SCREEN_1024_DESKTOP,
} = screenSizes;

const {vader} = ionicPalette;
const {black} = infinityPalette;

const ZINDEX_FOR_OFFER_CONTENT_BG = -1; // Make sure the background stays at the back

const StyledGA05BgImg = styled(BrandedGA05BgImg)`
    z-index: ${ZINDEX_FOR_OFFER_CONTENT_BG};

    &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';

        ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
            height: 100%;
            width: 71%;
            left: unset;
            right: 0;
        `}
    }
    /* stylelint-disable order/order */
    ${stylesWhenBinge`
        &::after {
            background: linear-gradient(to top, ${({backgroundImageTintColor}) => `${rgba(backgroundImageTintColor, 0.86)} 30%, transparent 70%`});

            ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
                background: linear-gradient(to left, ${({backgroundImageTintColor}) => `${rgba(backgroundImageTintColor, 0.86)} 45%, transparent 70%`});
            `}
        }
    `}

    ${stylesWhenLifestyle`
        &::after {
            background: linear-gradient(to top, ${({backgroundImageTintColor}) => `${rgba(backgroundImageTintColor, 0.86)} 30%, transparent 70%`});

            ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
                background: linear-gradient(to left, ${({backgroundImageTintColor}) => `${rgba(backgroundImageTintColor, 0.86)} 45%, transparent 70%`});
            `}
        }
    `}
    /* stylelint-enable order/order */
`;

const BackgroundImage = ({
    brand,
    backgroundImage,
}) => {
    const backgroundColor = {
        binge: vader,
        lifestyle: vader,
        flash: rgba(black, 0.86),
    };

    return (
        brand !== 'kayo' && !!backgroundImage && (
            <StyledGA05BgImg
                brand={brand}
                backgroundImageTintColor={backgroundColor[brand]}
                srcsetOptions={imageUrlToSrcsetOptions({
                    imageUrl: backgroundImage,
                    sizes: RESOURCES_IMWIDTHS.filter((sizePx) => sizePx >= SMALLEST_SUPPORTED_WIDTH),
                })}
            />
        )
    );
};

BackgroundImage.displayName = 'Background Image';

BackgroundImage.propTypes = {
    /** Product brand name */
    brand: propTypes.string,
    /**  Background image URL */
    backgroundImage: propTypes.string,
};

export default BackgroundImage;
