import {getLocalStorageValue, isServer} from '@fsa-streamotion/browser-utils';
import {ACTIVE_PROFILE_ID_STORAGE_KEY} from './constants';

export default function getActiveProfileIdFromLocalStorage({platformEnv, brand}) {
    if (isServer()) {
        return '';
    }

    getLocalStorageValue({key: ACTIVE_PROFILE_ID_STORAGE_KEY[platformEnv][brand]});
}
