import React, {useLayoutEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import classNames from 'classnames';
import noop from 'lodash/noop';

import {classNameType, colorType} from '@fsa-streamotion/custom-prop-types';

import {useInterval} from '../../../../common/custom-hooks';
import {buildTimeLabel, secondsToTime} from '../../../../common/format-time-utils';
import {ink, cloudy, hubblGreen} from '../../../../common/palette';

import Ic from '../../ic';

const ProgressCircle = styled.circle`
    fill: ${cloudy};
    stroke-linecap: round;
    stroke-dasharray: ${({circumference}) => `${circumference} ${circumference}`};
    stroke-dashoffset: ${({circumference, start, finish}) => circumference * start / finish};
    transform: rotate(-90deg);
    transform-origin: center;
    transition: stroke-dashoffset 1000ms linear;
`;

const TimerText = styled.text`
    fill: ${ink};
    font: var(--quicksilver-body-copy-2);
    font-size: 1.8em;
`;

/**
 * A Countdown timer including a analog clock-like graphic
 */
const GA169Countdown = ({
    bottomColor = hubblGreen,
    className,
    initialSecondsFromStart = 0,
    isTimerPaused = false,
    onTimerEnd = noop,
    topColor = hubblGreen,
    totalSeconds,
    ...htmlAttributes
}) => {
    const clampedTotalSeconds = Math.max(0, totalSeconds);
    const endTime = useRef(new Date(Date.now() + (clampedTotalSeconds * 1000)));
    const calculateSecondsLeft = () => Math.ceil((endTime.current.getTime() - Date.now()) / 1000);

    const [secondsLeft, setSecondsLeft] = useState(clampedTotalSeconds - initialSecondsFromStart);

    const [radius, setRadius] = useState(null);
    const progressRef = useRef(null);

    // Starts an interval that checks every 250 milliseconds if the timer clocks over to the next second. Returns a method to manually stop the interval once the timer stops
    const {stopInterval} = useInterval(function countdownSeconds() {
        const newSecondsLeft = calculateSecondsLeft();

        if (!isTimerPaused) {
            if (newSecondsLeft <= 0) {
                setSecondsLeft(0);
                stopInterval();
                onTimerEnd();
            } else {
                setSecondsLeft(newSecondsLeft);
            }
        }
    }, isTimerPaused ? undefined : 250);

    useLayoutEffect(function measureRadius() {
        if (progressRef.current?.getBBox) {
            setRadius(progressRef.current.getBBox().width / 2);
        }
    }, []);

    return (
        <Ic ariaLabel="Countdown Timer" role="group" className={classNames('GA169Countdown', className)} {...htmlAttributes}>
            <defs role="group">
                <linearGradient
                    id="ga-169-countdown__linear-gradient"
                    x1="0" y1="0" x2="0" y2="1"
                    gradientTransform="rotate(-90)"
                >
                    <stop stopColor={bottomColor} offset="0%" />
                    <stop stopColor={topColor} offset="100%" />
                </linearGradient>
            </defs>
            <ProgressCircle
                aria-label="Progress timer"
                r="48" cx="50" cy="50"
                circumference={(2 * Math.PI * radius)}
                finish={clampedTotalSeconds}
                ref={progressRef}
                role="img"
                start={Math.max(0, clampedTotalSeconds - secondsLeft)}
                stroke="url(#ga-169-countdown__linear-gradient)"
            />
            <TimerText
                x="50" y="50"
                alignmentBaseline="central"
                aria-label={`${buildTimeLabel(secondsLeft)} left`}
                dominantBaseline="central"
                role="timer"
                textAnchor="middle"
            >
                {secondsToTime(secondsLeft)}
            </TimerText>
        </Ic>
    );
};

GA169Countdown.displayName = 'GA169Countdown';

GA169Countdown.propTypes = {
    /** Color for the bottom part of the timer line */
    bottomColor: colorType,
    /** Additional class(es) */
    className: classNameType,
    /** An initial time to set (mainly for Vizard tests) */
    initialSecondsFromStart: propTypes.number,
    /** Pause the timer (mainly for Vizard tests) */
    isTimerPaused: propTypes.bool,
    /** Callback to run when the timer finishes */
    onTimerEnd: propTypes.func,
    /** Color for the top part of the timer line */
    topColor: colorType,
    /** Number of seconds to start the countdown from */
    totalSeconds: propTypes.number,
};

export default GA169Countdown;
