import React from 'react';
import {rgba} from 'polished';
import styled from 'styled-components';
import propTypes from 'prop-types';
import {Button} from 'normalized-styled-components';
import classnames from 'classnames';

import {stylesWhen, mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {midnight, blanc, onyx} from '../../../../common/palette';
import {transition} from '../../../../common/animations';
import {SCREEN_768_TABLET, SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';

const TitleContainer = styled.span`
    position: absolute;
    right: 0;
    bottom: 8px;
    left: 0;
    transition: ${transition('opacity')};
    opacity: ${({isPressed}) => isPressed ? 1 : 0.35};
    height: 37px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        bottom: 10px;
        height: auto;
    `}
`;

const IconContainer = styled.span`
    transition: ${transition('background-color')};
    border-radius: 3px;
    padding: 1px;
    width: 76px;
    height: 76px;
    color: ${({isLightTheme}) => isLightTheme ? onyx : blanc};
    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 110px;
        height: 110px;
    `}
`;

const StyledButton = styled(Button).attrs(({isPressed}) => ({
    'type': 'button',
    'aria-pressed': isPressed,
    'style': {
        font: 'var(--nucleus-body-copy-4)',
    },
}))`
    box-sizing: border-box;
    display: inline-flex;
    position: relative;
    justify-content: center;
    border: solid 3px transparent;
    border-radius: 4px;
    background: transparent;
    padding: 0 0 50px;
    color: ${({isLightTheme}) => isLightTheme ? midnight : blanc};

    &:focus,
    &:hover {
        outline: 0;

        > ${TitleContainer} {
            opacity: 1;
        }
    }

    &:focus {
        background: ${({isLightTheme}) => isLightTheme ? rgba(midnight, 0.1) : rgba(blanc, 0.1)};
    }

    &::after {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        transition: ${transition('background-color')};
        border-radius: 2px;
        background-color: transparent;
        height: 3px;
        content: '';
    }

    &[aria-pressed='true'] {
        &::after {
            background-color: ${blanc};

            ${stylesWhen('isLightTheme')`
                background-color: ${midnight};
            `}
        }
    }
`;

const BA32OfferFeature = React.forwardRef(({
    className,
    title,
    isPressed,
    isLightTheme,
    children,
    onClick,
    ...htmlAttributes
}, ref) => (
    <StyledButton
        {...htmlAttributes}
        className={classnames('BA32OfferFeature', className)}
        isLightTheme={isLightTheme}
        ref={ref}
        isPressed={isPressed}
        onClick={onClick}
    >
        <IconContainer isLightTheme={isLightTheme}>
            {children}
        </IconContainer>
        <TitleContainer
            isLightTheme={isLightTheme}
            isPressed={isPressed}
        >
            {title}
        </TitleContainer>
    </StyledButton>
));

BA32OfferFeature.displayName = 'BA32OfferFeature';

BA32OfferFeature.propTypes = {
    /** additional CSS classnames to be applied */
    className: classNameType,
    /** Visible label or title of the button */
    title: propTypes.string,
    /** aria-pressed attribute describes a toggle button */
    isPressed: propTypes.bool,
    /** Is the button going to be used in a light themed background  */
    isLightTheme: propTypes.bool,
    /** Content of button, e.g. icons or images */
    children: propTypes.node.isRequired,
    /** Callback to call on element click */
    onClick: propTypes.func,
};

export default BA32OfferFeature;
