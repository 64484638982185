import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import property from 'lodash/property';
import {rgba} from 'polished';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {srcsetOptionsType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {Img, Section} from '../../../../common/normalized-styled-components';
import {white, black} from '../../../../common/palette';
import SrcsetSource from '../../../../common/srcset-source';
import {SCREEN_768_TABLET, SCREEN_1024_DESKTOP, SCREEN_1280_DESKTOP, SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import BA01BtnPr from '../../../atoms/ba/01-btn-pr';

const StyledSection = styled(Section)`
    display: flex;
    position: relative;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    color: ${white};

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        text-align: left;
        justify-content: flex-end;
    `}
`;

const BackgroundContainer = styled.div`
    position: relative;
    background-color: ${property('backgroundImageTintColor')};
    padding-bottom: 150%; /* maintain 2x3 size */
    width: 100%;
    height: 0;
    overflow: hidden;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        padding: 0;
        height: 565px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        height: 371px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        height: 500px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        height: 574px;
    `}

    &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        background: linear-gradient(to top, ${({backgroundImageTintColor}) => `${rgba(backgroundImageTintColor, 0.85)} 30%, transparent 70%`});
        width: 100%;
        height: 83%;
        content: '';

        ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
            height: 92%;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
            height: 100%;
            width: 82%;
            background: linear-gradient(to right, ${({backgroundImageTintColor}) => `${rgba(backgroundImageTintColor, 0.85)} 18%, transparent 67%`});
        `}
    }
`;

const StyledPicture = styled.picture`
    display: block;
    width: 100%;
    height: 100%;
`;

const StyledImg = styled(Img)`
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
`;

const BlockContentContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    position: absolute;
    bottom: 0;
    flex-direction: column;
    align-items: start;
    justify-content: flex-end;
    padding-bottom: 34px;
    width: 100%;

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        width: 390px;
        height: 100%;
        left: 0;
        justify-content: center;
        padding-bottom: initial;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        width: 600px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 700px;
    `}
`;

const BlockContent = styled.div`
    border-left: 4px solid ${property('accentColor')};
    padding-left: 17px;
    max-width: 75%;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        padding-left: 49px;
        max-width: 370px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        border-left-width: 10px;
        padding-left: 99px;
    `}
`;

const StyledH2 = styled.h2`
    margin: 0 0 7px;
    font: var(--infinity-mar-blk-title);
`;

const Copy = styled.p`
    margin: 0;
    font: var(--infinity-body-copy-3);
`;

const StyledBA01BtnPr = styled(BA01BtnPr)`
    align-self: center;
    margin-top: 37px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        align-self: initial;
        margin-left: 49px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        margin-left: 99px;
    `}
`;

const OR129MarBlk = ({
    className,
    accentColor,
    title,
    copy,
    link,
    linkText,
    backgroundImageSrcsetOptionsTablet,
    backgroundImageSrcsetOptionsMobile,
    backgroundImageSrcsetOptions,
    backgroundImageFallback,
    backgroundImageCaption,
    backgroundImageTintColor = black,
    ...htmlAttributes
}) => (
    <StyledSection
        {...htmlAttributes}
        className={classnames('OR129MarBlk', className)}
    >
        <BackgroundContainer backgroundImageTintColor={backgroundImageTintColor}>
            <StyledPicture>
                <SrcsetSource
                    minWidthPx={backgroundImageSrcsetOptionsMobile ? SCREEN_1024_DESKTOP : undefined}
                    srcsetOptions={backgroundImageSrcsetOptions}
                />

                {!!backgroundImageSrcsetOptionsTablet && (
                    <SrcsetSource
                        minWidthPx={backgroundImageSrcsetOptionsTablet ? SCREEN_768_TABLET : undefined}
                        srcsetOptions={backgroundImageSrcsetOptionsTablet} sizes="100vw"
                    />
                )}

                {!!backgroundImageSrcsetOptionsMobile && (
                    <SrcsetSource srcsetOptions={backgroundImageSrcsetOptionsMobile} sizes="100vw" />
                )}

                <StyledImg
                    src={backgroundImageFallback}
                    alt={backgroundImageCaption}
                />
            </StyledPicture>
        </BackgroundContainer>
        <BlockContentContainer>
            <BlockContent accentColor={accentColor}>
                {!!title && <StyledH2>{title}</StyledH2>}
                {!!copy && <Copy>{copy}</Copy>}
            </BlockContent>
            {!!link && !!linkText && <StyledBA01BtnPr href={link}>{linkText}</StyledBA01BtnPr>}
        </BlockContentContainer>
    </StyledSection>
);

OR129MarBlk.propTypes = {
    /** Additional class name(s) */
    className: classNameType,
    /** Accent colour that controld the left border colour */
    accentColor: propTypes.string,
    /** CMS block heading copy */
    title: propTypes.string,
    /** CMS copy */
    copy: propTypes.string,
    /** CTA Link */
    link: propTypes.string,
    /** CTA Text */
    linkText: propTypes.string,
    /** Background image for desktop */
    backgroundImageSrcsetOptions: srcsetOptionsType,
    /** Background image for mobile */
    backgroundImageSrcsetOptionsMobile: srcsetOptionsType,
    /** Background image for tablet */
    backgroundImageSrcsetOptionsTablet: srcsetOptionsType,
    /** background image for when srcset is not supported */
    backgroundImageFallback: propTypes.string,
    /** Background image tint colour */
    backgroundImageTintColor: propTypes.string,
    /** background image caption */
    backgroundImageCaption: propTypes.string,
};

OR129MarBlk.displayName = 'OR129MarBlk';

export default OR129MarBlk;
