import React, {cloneElement} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';
import {A, Sup} from 'normalized-styled-components';
import get from 'lodash/get';

import {stylesWhenNot, stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {PERIOD_SHORT} from '../../../../common/billing-constants';
import {billingPeriodType} from '../../../../common/custom-proptypes';
import splitPrice from '../../../../common/split-price';
import {kayoGreen, ironStone, silverLining, midnight, slate} from '../../../../common/palette';

import CA01Feature from '../../../atoms/ca/01-feature';
import GA20PixelDiv from '../../../atoms/ga/20-pixel-div';
import GA89Tile from '../../../atoms/ga/89-tile';
import IC25ArrowR from '../../../atoms/ic/25-arrow-r';
import IC50Success from '../../../atoms/ic/50-success';
import CM03FeatureList from '../../cm/03-feature-list';

const StyledCA01Feature = styled(CA01Feature)`
    color: ${slate};
    font: var(--mui-body-copy-4);
`;

const IconWrapper = styled.span`
    display: block;
    width: 60px;
    line-height: 0;
    color: ${silverLining};
`;

const CtaTextWrapper = styled.span`
    display: block;
    flex: 1 0 auto;
    text-align: right;
    color: ${rgba(silverLining, 0.7)};
    font: var(--mui-body-copy-4-light);
    user-select: none;
`;

const StyledGA89Tile = styled(GA89Tile).attrs(
    ({isPressed}) => (
        isPressed && ({
            'aria-current': isPressed,
        })
    )
)`
    display: block;
    border: 1px solid ${rgba(ironStone, 0.1)};
    border-radius: 6px;
    background-color: ${rgba(midnight, 0.7)};
    padding: 0;
    text-decoration: none;
    color: ${silverLining};
    font: var(--mui-body-copy-7);

    ${stylesWhen('hasAction')`
        &:hover {
            border-color: ${ironStone};
        }
    `}

    &:focus,
    &[aria-current='true'] {
        border-color: ${ironStone};
        background-color: ${midnight};

        ${GA20PixelDiv} { /* @TODO: WEB-2237 | Remove if confirmed not needed */
            border-color: ${ironStone};
        }
    }
`;

const CurrentPlan = styled.section`
    padding: ${(props) => props.isShortForm ? '14px' : '21px'} 14px;
`;

const PlanDetails = styled.div`
    display: flex;
    position: relative;
    margin: 8px 0 0;
`;

const PlanDescription = styled.div`
    flex-grow: 1;
`;

const PlanName = styled.h4`
    display: block;
    margin-top: 0;
    margin-bottom: 7px;
    text-transform: uppercase;
    font: var(--mui-header-7);
`;

/** **** PRICE SECTION ******/

const PlanPrice = styled.span`
    position: relative;
    flex-shrink: 0;
    padding-left: 21px;
    color: ${silverLining};
    font: var(--mui-header-1-bold);

    ${stylesWhen('hasFractionAndFootnoteMarker')`
        padding-right: 10px;
    `}
`;

const SupContainer = styled(Sup)`
    position: absolute;
    top: 3px;
    padding-right: 2px;
`;

const PlanPriceFraction = styled.span`
    font: var(--mui-body-copy-3);
`;

const PlanPriceFootnoteMarker = styled.span`
    position: absolute;
    padding-left: 1px;
    font: var(--mui-footnote-marker-2);
`;

const TermWrapper = styled.span`
    font: var(--mui-body-copy-3);
`;

const RegularPriceBadge = styled.span`
    display: block;
    color: ${rgba(silverLining, 0.35)};
    font: var(--mui-header-10);
    font-style: italic;
`;

/** **** FEATURE LIST ******/

const StyledCM03FeatureList = styled(CM03FeatureList)`
    margin-left: 4px;

    a {
        color: inherit;
    }
`;

const IconCtaWrapper = styled.span`
    display: flex;

    ${stylesWhenNot('hasNameOrPrice')`
        align-items: center;
    `}
`;

const StyledIC25ArrowR = styled(IC25ArrowR)`
    margin-bottom: -2px;
    margin-left: 8px;
`;

const ChildWrapper = styled.span`
    display: block;
`;

const TM10SubTile = ({
    children,
    className,
    ctaText,
    currencyDecimalPlaces = 2,
    currencyPrefix = '$',
    displayName,
    href,
    icon,
    isPressed,
    features = [],
    descriptionList = [],
    price = {},
    footnoteMarker,
    onClick,
    ...htmlAttributes
}) => {
    const {termType, displayRegularAmount, displayAmount} = price;
    const [priceIntegers, priceDecimals] = splitPrice(displayAmount, currencyDecimalPlaces);
    const termTypeShort = get(PERIOD_SHORT, termType);
    const hasNameOrPrice = displayAmount >= 0 || !!displayName;
    const hasAction = !!(href || onClick);
    const hasPlanDescription = !!(displayName || features.length);
    const hasPlanPrice = displayAmount >= 0;
    const hasPlanDetails = hasPlanDescription || hasPlanPrice;

    return (
        <StyledGA89Tile
            {...htmlAttributes}
            as={hasAction ? A : undefined}
            href={href}
            className={classnames('TM10SubTile', className)}
            isPressed={isPressed}
            hasAction={hasAction}
            onClick={onClick}
        >
            <CurrentPlan isShortForm={!hasPlanDetails}>
                {!!(icon || ctaText) && (
                    <IconCtaWrapper hasNameOrPrice={hasNameOrPrice}>
                        {!!icon && <IconWrapper>{cloneElement(icon)}</IconWrapper>}
                        {!!ctaText && hasAction && <CtaTextWrapper>{ctaText}<StyledIC25ArrowR size="14px" display="inline-block" /></CtaTextWrapper>}
                    </IconCtaWrapper>
                )}
                {hasPlanDetails && (
                    <PlanDetails>
                        {hasPlanDescription && (
                            <PlanDescription>
                                {!!displayName && (
                                    <PlanName>
                                        {displayName}
                                    </PlanName>
                                )}
                                {Array.isArray(features) && !!features.length && (
                                    <StyledCM03FeatureList isBlock={true} listGapSet={[{breakpoint: 0, gapPx: 0}]}>
                                        {features.map(({title, a11yTitle, value, a11yValue}) => (
                                            <StyledCA01Feature
                                                key={`${title}__${value}`}
                                                icon={<IC50Success color={kayoGreen} size="14px" />}
                                                {...(!!(a11yTitle || a11yValue) && ({
                                                    'aria-label': `${a11yTitle}${a11yValue ? `: ${a11yValue}` : ''}`,
                                                }))}
                                            >
                                                {title}{value ? `: ${value}` : ''}
                                            </StyledCA01Feature>
                                        ))}
                                    </StyledCM03FeatureList>
                                )}
                                {Array.isArray(descriptionList) && !!descriptionList.length && (
                                    <StyledCM03FeatureList isBlock={true} listGapSet={[{breakpoint: 0, gapPx: 0}]}>
                                        {descriptionList.map((descriptor, index) => (
                                            <StyledCA01Feature
                                                key={index}
                                                icon={descriptor?.icon || <IC50Success color={kayoGreen} size="14px" />}
                                            >
                                                {descriptor?.desc ?? descriptor}
                                            </StyledCA01Feature>
                                        ))}
                                    </StyledCM03FeatureList>
                                )}
                            </PlanDescription>
                        )}

                        {hasPlanPrice && (
                            <PlanPrice
                                aria-label={displayAmount > 0 ? `${currencyPrefix}${displayAmount} per ${termType}` : undefined}
                                hasFractionAndFootnoteMarker={!!priceDecimals && !!footnoteMarker}
                            >
                                {displayAmount
                                    ? (
                                        <React.Fragment>
                                            {currencyPrefix}{priceIntegers}

                                            {(!!priceDecimals || !!footnoteMarker) && (
                                                <SupContainer>
                                                    {!!priceDecimals && (
                                                        <PlanPriceFraction>
                                                            .{priceDecimals}
                                                        </PlanPriceFraction>
                                                    )}

                                                    {!!footnoteMarker && (
                                                        <PlanPriceFootnoteMarker>
                                                            {footnoteMarker}
                                                        </PlanPriceFootnoteMarker>
                                                    )}
                                                </SupContainer>
                                            )}

                                            {!!termTypeShort && (
                                                <TermWrapper>
                                                    /{termTypeShort}
                                                </TermWrapper>
                                            )}

                                            {displayAmount === displayRegularAmount && (
                                                <RegularPriceBadge>Regular Price</RegularPriceBadge>
                                            )}
                                        </React.Fragment>
                                    )
                                    : 'Free'}
                            </PlanPrice>
                        )}
                    </PlanDetails>
                )}
            </CurrentPlan>

            {React.Children.map(children, (child) => {
                if (child === null) {
                    return null;
                }

                return (
                    <ChildWrapper key={child.key}>
                        <GA20PixelDiv />
                        {child}
                    </ChildWrapper>
                );
            })}
        </StyledGA89Tile>
    );
};

TM10SubTile.displayName = 'TM10SubTile';

TM10SubTile.propTypes = {
    /** Children */
    children: propTypes.node,
    /** Optional additional class(es) */
    className: classNameType,
    /** The whole thing is a CTA, but put the pertinent CTA text here */
    ctaText: propTypes.string,
    /** Whether, and how many decimal places to display */
    currencyDecimalPlaces: propTypes.number,
    /** What currency symbol to use */
    currencyPrefix: propTypes.oneOf(['$']),
    /** Name of the package */
    displayName: propTypes.string,
    /** Link to go to */
    href: propTypes.string,
    /** Icon */
    icon: propTypes.node,
    /** is active/selected */
    isPressed: propTypes.bool,
    /** Package features */
    features: propTypes.arrayOf(propTypes.shape({
        title: propTypes.node,
        a11yTitle: propTypes.string,
        value: propTypes.node,
        a11yValue: propTypes.string,
    })),
    /** Similar to package features, but array of pre-formatted nodes */
    descriptionList: propTypes.arrayOf(propTypes.oneOfType([
        propTypes.node,
        propTypes.shape({
            icon: propTypes.node,
            desc: propTypes.node,
        }),
    ])),
    /** Package pricing, right now we’re pulling out month specifically */
    price: propTypes.shape({
        termType: billingPeriodType,
        displayRegularAmount: propTypes.number,
        displayAmount: propTypes.number,
    }),
    /** Footnote marker shown next to price like `*, ^, ~ or #` */
    footnoteMarker: propTypes.string,
    /** Click handler for parent node */
    onClick: propTypes.func,
};

export default TM10SubTile;
