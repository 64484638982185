import React from 'react';

import Ic from '..';

const IC336Property = ({
    ...otherProps
}) => (
    <Ic {...otherProps}>
        <g transform="scale(3.5714)" opacity="0.8">
            <path
                d="M4.3,18.7c0-0.7,0-1.3,0-2c0-0.2,0.1-0.4,0.2-0.5c1.9-1.9,3.8-3.8,5.7-5.7c0.3-0.3,0.3-0.5,0.2-0.8
                C9.9,8.3,10.1,7,10.9,5.8C12,4.1,14,3.3,16,3.9c0.4,0.1,0.6,0,0.7-0.2c0.1-0.3-0.1-0.5-0.4-0.6c-0.7-0.2-1.4-0.3-2.2-0.2
                c-3.3,0.4-5.4,3.6-4.6,6.8c0.1,0.2,0,0.3-0.1,0.4c-1.9,1.9-3.8,3.8-5.7,5.7c-0.2,0.2-0.2,0.3-0.2,0.6c0,0.9,0,1.8,0,2.6
                c0,0.4,0.1,0.5,0.5,0.5c0.9,0,1.8,0,2.7,0c0.4,0,0.5-0.1,0.5-0.5c0-0.4,0-0.7,0-1.1c0.4,0,0.8,0,1.1,0c0.4,0,0.5-0.1,0.5-0.5
                c0-0.4,0-0.7,0-1.1c0.4,0,0.8,0,1.2,0c0.1,0,0.3-0.1,0.4-0.1c0.8-0.7,1.5-1.5,2.2-2.2c0.1-0.1,0.1-0.3,0.1-0.4
                C12.7,13,12.3,13,12,13.2c-0.7,0.6-1.3,1.3-2,1.9c-0.1,0.1-0.2,0.1-0.4,0.1c-0.4,0-0.8,0-1.2,0c-0.4,0-0.5,0.1-0.5,0.5
                c0,0.4,0,0.7,0,1.1c0,0-0.1,0-0.1,0c-0.3,0-0.6,0-1,0c-0.5,0-0.6,0.1-0.6,0.6c0,0.3,0,0.7,0,1C5.6,18.7,5,18.7,4.3,18.7z"
                opacity="0.2"
            />
            <path
                d="M20.1,14c0.2-0.1,0.3-0.2,0.5-0.3c1.5-1,2.3-2.7,2-4.5c-0.2-1.4-1.1-2.6-2.3-3.2l0,0c-0.1,0-0.1-0.1-0.2-0.1
                c-0.9-0.5-1.8-0.6-2.8-0.5c-1.9,0.3-3.5,1.8-3.8,3.8c-0.3,2,0.7,3.9,2.4,4.8c0.3,0.2,0.4,0.4,0.4,0.7c0,2.5,0,5,0,7.6
                c0,0.3,0.1,0.5,0.3,0.7c0.5,0.4,0.9,0.9,1.3,1.3c0.5-0.5,1-1,1.5-1.5c-0.3-0.3-0.6-0.5-0.8-0.8c-0.3-0.3-0.3-0.5,0-0.8
                c0.3-0.3,0.6-0.6,0.9-0.9c-0.3-0.3-0.6-0.6-0.9-0.9c-0.2-0.3-0.2-0.5,0-0.7c0.3-0.3,0.6-0.6,0.9-0.9c0.2-0.1,0.2-0.3,0.2-0.5
                c0-0.9,0-1.8,0-2.7C19.7,14.3,19.8,14.1,20.1,14 M19.2,4.6c2.2,0.5,3.9,2.3,4.2,4.5c0.1,0.9,0,1.9-0.3,2.7
                c-0.4,1.2-1.3,2.2-2.4,2.8c-0.2,0.1-0.2,0.2-0.2,0.4c0,0.1,0,0.2,0,0.3l0.2-0.7c-0.2,0.1-0.2,0.2-0.2,0.4c0,0.9,0,1.8,0,2.7
                c0,0.2-0.1,0.4-0.2,0.5c-0.3,0.3-0.6,0.6-0.9,0.9c0.3,0.3,0.6,0.6,0.9,0.9c0.3,0.3,0.3,0.5,0,0.8c-0.3,0.3-0.6,0.6-0.9,0.9
                c0.3,0.3,0.6,0.6,0.9,0.9c0.3,0.3,0.3,0.5,0,0.8c-0.6,0.6-1.3,1.3-1.9,1.9c-0.3,0.3-0.5,0.3-0.8,0c-0.6-0.6-1.2-1.2-1.8-1.8
                c-0.2-0.2-0.3-0.4-0.3-0.6c0-2.6,0-5.1,0-7.7c0-0.2-0.1-0.3-0.2-0.4c-2-1.1-3-3.3-2.7-5.5c0.3-2.2,2-4,4.2-4.5
                C18,4.4,19.1,4.5,20.2,5"
            />
            <path
                d="M4.3,18.7c0.7,0,1.4,0,2.1,0c0-0.3,0-0.7,0-1C6.4,17.1,6.5,17,7,17c0.3,0,0.6,0,1,0c0,0,0,0,0.1,0
                c0-0.4,0-0.7,0-1.1c0-0.4,0.1-0.5,0.5-0.5c0.4,0,0.8,0,1.2,0c0.1,0,0.3-0.1,0.4-0.1c0.7-0.6,1.3-1.3,2-1.9
                c0.3-0.3,0.6-0.2,0.7,0.1c0,0.1,0,0.3-0.1,0.4c-0.7,0.8-1.5,1.5-2.2,2.2c-0.1,0.1-0.3,0.1-0.4,0.1c-0.4,0-0.8,0-1.2,0
                c0,0.4,0,0.8,0,1.1c0,0.4-0.1,0.5-0.5,0.5c-0.4,0-0.7,0-1.1,0c0,0.4,0,0.8,0,1.1c0,0.4-0.1,0.5-0.5,0.5c-0.9,0-1.8,0-2.7,0
                c-0.4,0-0.5-0.1-0.5-0.5c0-0.9,0-1.8,0-2.6c0-0.2,0.1-0.4,0.2-0.6c1.9-1.9,3.8-3.8,5.7-5.7C9.5,10,9.5,9.9,9.5,9.7
                C8.6,6.5,10.8,3.3,14,2.9c0.7-0.1,1.5,0,2.2,0.2c0.4,0.1,0.5,0.3,0.4,0.6C16.6,3.9,16.3,4,16,3.9c-2-0.6-4,0.2-5.1,1.9
                C10.1,7,9.9,8.3,10.4,9.7c0.1,0.3,0.1,0.6-0.2,0.8c-1.9,1.9-3.8,3.8-5.7,5.7c-0.1,0.1-0.2,0.3-0.2,0.5C4.3,17.3,4.3,18,4.3,18.7z"
            />
            <path
                d="M18.1,6.2c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2C20.1,7.1,19.2,6.2,18.1,6.2z M18.1,9.4
                c-0.6,0-1.2-0.5-1.2-1.2c0-0.6,0.5-1.2,1.2-1.2s1.2,0.5,1.2,1.2C19.2,8.9,18.7,9.4,18.1,9.4z"
            />
        </g>
    </Ic>
);

IC336Property.displayName = 'IC336Property';

export default IC336Property;
