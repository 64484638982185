/* eslint-disable arrow-body-style */
import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {H1} from 'normalized-styled-components';
import noop from 'lodash/noop';
import pick from 'lodash/pick';

import {palette as infinityPalette, screenSizes} from '@fsa-streamotion/streamotion-web-infinity';
import {palette as ionicPalette} from '@fsa-streamotion/streamotion-web-ionic';
import {palette as muiPalette} from '@fsa-streamotion/streamotion-web-mui';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import brandPropType from '../../../../common/brand-proptype';

import {stylesWhenBinge, stylesWhenFlash, stylesWhenKayo, stylesWhenLifestyle} from '../../../../utils/styles-when-brand';
import {
    BrandedBA01BtnPr,
    BrandedBA22EditBtn,
    BrandedOR12VchInp,
    BrandedOR56Modal,
} from '../../../../utils/branded-components';

import PackageSelector from '../package-selector';

const {SCREEN_768_TABLET, SCREEN_1920_DESKTOP} = screenSizes;

const {blanc} = ionicPalette;
const {fog, white} = infinityPalette;
const {white: muiWhite} = muiPalette;

const Z_INDEX_OVER_HEADER = 1;

const ZIndexPositionContainer = styled.div`
    position: relative;
    z-index: ${Z_INDEX_OVER_HEADER};
`;

const CenterTextContainer = styled.div`
    display: grid;
    grid-row-gap: 14px;
    justify-content: center;
    justify-items: center;
    padding: 14px 0;
`;

const ModalHeader = styled(H1)`
    margin: 0 14px;
    text-align: center;

    ${stylesWhenBinge`
        color: ${blanc};
        font: var(--ionic-header-4);
    `}

    ${stylesWhenFlash`
        color: ${white};
        font: var(--infinity-header-3-bold);
    `}

    ${stylesWhenKayo`
        color: ${muiWhite};
        font: var(--mui-header-2);
    `}

    ${stylesWhenLifestyle`
        color: ${blanc};
        font: var(--nucleus-header-4);
    `}
`;

const ModalParagraph = styled.p`
    margin: 0 14px;
    text-align: center;

    ${stylesWhenBinge`
        color: ${blanc};
        font: var(--ionic-body-copy-2-light);
    `}

    ${stylesWhenFlash`
        color: ${fog};
        font: var(--infinity-body-copy-3);
    `}

    ${stylesWhenKayo`
        color: ${muiWhite};
        font: var(--mui-body-copy-2);
    `}

    ${stylesWhenLifestyle`
        color: ${blanc};
        font: var(--nucleus-body-copy-2-light);
    `}

    > a {
        color: inherit;
    }
`;

const StyledOR12VchInp = styled(BrandedOR12VchInp)`
    overflow: hidden; /** This is a hack. Someone come back and fix the underlying issue, of the hidden controls causing the dialog to overflow */
`;

const StyledDiv = styled.div`
    display: grid;
    grid-row-gap: 14px;
    align-items: center;
    margin-top: 21px;
`;

// this should probably be the default style for BA22EditBtn contents...
const StyledSpan = styled.span`
    display: block;
    text-align: center;
`;

const StyledBrandedOR56Modal = styled(BrandedOR56Modal)`
    ${stylesWhenBinge`
        padding: 21px 0 57px;
    `}

    ${stylesWhenFlash`
        padding: 63px 21px;

        ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
            padding: 63px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            padding: 93px;
        `}
    `}

    ${stylesWhenLifestyle`
        padding: 21px 0 57px;
    `}
`;

const InvalidOfferModal = ({
    dismissModalFunc = noop,
    shouldDismissModalWithClickOutside = false,
    userGreeting,
    errorMessage,
    shouldSelectNextBestOffer = false,
    packageSelector,
    voucherDisplay,
    hasContinueButton,
    onContinueFunction,
    continueButtonText = 'Continue',
    brand,
}) => {
    const voucherInputProps = pick(voucherDisplay, [
        'headingText', 'isExpanded', 'onExpand', 'hasExpandButton', 'label', 'inputId', 'inputName', 'voucherCode', 'onChangeVoucherCode', 'errorIcon', 'errorMessage', 'submitText', 'isSubmitDisabled', 'onSubmit', 'displaySubtext', 'displayHeader',
    ]);

    const voucherForm = (
        <React.Fragment>
            {!!voucherDisplay && (
                <StyledOR12VchInp
                    brand={brand}
                    {...voucherInputProps}
                />
            )}
            {!!voucherDisplay?.showRemoveVoucher && (
                <BrandedBA22EditBtn
                    brand={brand}
                    onClick={voucherDisplay.onRemoveVoucher}
                >
                    <StyledSpan>Remove Voucher</StyledSpan>
                </BrandedBA22EditBtn>
            )}
        </React.Fragment>
    );

    return (
        <ZIndexPositionContainer>
            <StyledBrandedOR56Modal
                brand={brand}
                isVisible={true}
                canBeDeactivated={shouldDismissModalWithClickOutside}
                onCloseClick={dismissModalFunc}
            >
                <CenterTextContainer>
                    {!!userGreeting && <ModalHeader brand={brand}>{userGreeting}</ModalHeader>}

                    {!!errorMessage && <ModalParagraph brand={brand}>{errorMessage}</ModalParagraph>}

                    {!!shouldSelectNextBestOffer
                        && <ModalParagraph brand={brand}>Would you like to continue with one of these offers instead?</ModalParagraph>
                    }

                    {!!shouldSelectNextBestOffer && (
                        <PackageSelector
                            brand={brand}
                            {...packageSelector}
                        />
                    )}

                    <StyledDiv>
                        {!!shouldSelectNextBestOffer && (voucherForm)}

                        {!!hasContinueButton && (
                            <BrandedBA01BtnPr
                                brand={brand}
                                isBlock={true}
                                onClick={onContinueFunction}
                            >
                                {continueButtonText}
                            </BrandedBA01BtnPr>
                        )}
                    </StyledDiv>
                </CenterTextContainer>
            </StyledBrandedOR56Modal>
        </ZIndexPositionContainer>
    );
};

InvalidOfferModal.displayName = 'InvalidOfferModal';

InvalidOfferModal.propTypes = {
    /** Function to call when the Close button is clicked */
    dismissModalFunc: propTypes.func,
    /** Can this modal be deactivated by clicking close, clicking outside of the modal or pressing Escape? */
    shouldDismissModalWithClickOutside: propTypes.bool,
    /** User greeting message */
    userGreeting: propTypes.node,
    /** Error message */
    errorMessage: propTypes.node,
    /** Toggle for showing or not showing the next best offers list */
    shouldSelectNextBestOffer: propTypes.bool,
    /** All the props needed for `PackageSelector` */
    packageSelector: propTypes.any,
    /** Voucher displaying controls `OR12VchInp` */
    voucherDisplay: propTypes.object,
    /** Does it have Continue button? */
    hasContinueButton: propTypes.bool,
    /** Continue button message */
    continueButtonText: propTypes.string,
    /** Function to call when the Continue button is clicked */
    onContinueFunction: propTypes.func,
    /** Brand name */
    brand: brandPropType,
};

export default InvalidOfferModal;
