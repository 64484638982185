import React from 'react';
import FocusTrap from 'focus-trap-react';
import propTypes from 'prop-types';

const FocusTrapWrapper = ({
    canBeDeactivated,
    children,
    deactivateFocusTrap,
    hasFocus,
    hasFocusTrap = true,
}) => {
    if (!hasFocusTrap) {
        return children;
    }

    return (
        <FocusTrap
            active={hasFocus}
            focusTrapOptions={{
                onDeactivate: deactivateFocusTrap,
                escapeDeactivates: canBeDeactivated,
                clickOutsideDeactivates: canBeDeactivated,
            }}
        >
            {children}
        </FocusTrap>
    );
};

FocusTrapWrapper.propTypes = {
    /** Can this modal be deactivated by clicking close, clicking outside of the modal or pressing Escape? */
    canBeDeactivated: propTypes.bool,
    /** Children content */
    children: propTypes.node,
    /** Callback to run for the Focus Trap when deactivating the focus on the component */
    deactivateFocusTrap: propTypes.func,
    /** Does the Focus Trap have focus?  */
    hasFocus: propTypes.bool,
    /** Optionally render the Focus Trap */
    hasFocusTrap: propTypes.bool,
};

FocusTrapWrapper.displayName = 'FocusTrapWrapper';

export default FocusTrapWrapper;
