import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {white} from '../../../../common/palette';
import {kayoGreen} from '../../../../common/deprecated-mui-palette';
import {SCREEN_2560_DESKTOP, SCREEN_768_TABLET, SCREEN_1920_DESKTOP, SCREEN_1024_DESKTOP} from '../../../../common/screen-sizes';

const ALLOWANCE_FOR_ONE_ROW_MENU_MOBILE_PX = 106;

const StyledSection = styled.section`
    margin: 0 auto;
    width: 100%;
    color: ${white};
`;

const StyledHeader = styled.header`
    margin: 0 auto 9px;
    width: calc(100% - 14px);
    text-align: center;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: 600px;
        margin-bottom: 13px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        /* width isn't from design, just some sensible logic because who knows how many paragraphs of copy marketing will put here */
        width: 75%;
        max-width: 1200px;
        margin-bottom: 13px;
    `}
`;

const StyledTitle = styled.h2`
    margin: 0;
    font: var(--ionic-header-3-bold);
`;

const StyledLink = styled.a`
    display: block;
    text-transform: uppercase;
    text-decoration: none;
    color: ${kayoGreen};
    font: var(--ionic-body-copy-2);

    &:hover {
        text-decoration: underline;
    }

    &:nth-child(2) { /* detect if we need to allow space between this link and a preceeding header title */
        margin-top: 7px; /* wanted to use display: grid and row-gap, however that resulted in the hyperlink clickbox being far too wide */

        ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
            margin-top: 25px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            margin-top: 29px;
        `}
    }
`;

const Overlay = styled.div`
    margin: 0 auto;
    padding-bottom: ${ALLOWANCE_FOR_ONE_ROW_MENU_MOBILE_PX}px;
    padding-left: 14px;
    max-width: 1151px;
    height: 418px;
    overflow: hidden;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        height: 485px;
        padding-left: 56px;
        padding-bottom: 0;
    `}
`;

const Description = styled.div`
    box-sizing: border-box;
    position: relative;
    margin-top: 21px;
    padding-right: 14px;
    font: var(--ionic-body-copy-1);

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        padding-right: 40px;
        margin-top: 27px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        max-width: 651px;
    `}
`;

const BackgroundImage = styled.img`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin: ${ALLOWANCE_FOR_ONE_ROW_MENU_MOBILE_PX}px auto 0;
    width: 100%;
    max-width: ${SCREEN_2560_DESKTOP}px;
    height: 418px;
    object-fit: cover;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        height: 485px;
        margin-top: 0;
    `}
`;

const SportMenus = styled.div`
    display: grid;
    grid-row-gap: 35px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        grid-row-gap: 61px;
    `}
`;

export default function OR14SeaBlk({
    children,
    className,
    seeMoreCopy = 'See our full list of sports',
    seeMoreHref,
    sportMenu,
    backgroundImageUrl,
    title,
}) {
    const isHeaded = !!(title || seeMoreHref);

    return (
        <StyledSection className={classnames('OR14SeaBlk', className)}>
            {isHeaded && (
                <StyledHeader>
                    {!!title && (
                        <StyledTitle>
                            {title}
                        </StyledTitle>
                    )}
                    {!!seeMoreHref && (
                        <StyledLink
                            href={seeMoreHref}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {seeMoreCopy}
                        </StyledLink>
                    )}
                </StyledHeader>
            )}

            <Overlay>
                <BackgroundImage src={backgroundImageUrl} alt="" />
                <SportMenus>{sportMenu}</SportMenus>
                <Description>{children}</Description>
            </Overlay>
        </StyledSection>
    );
}

OR14SeaBlk.displayName = 'OR14SeaBlk';

OR14SeaBlk.propTypes = {
    /** Additional class(es) */
    className: classNameType,
    seeMoreCopy: propTypes.string,
    seeMoreHref: propTypes.string,
    title: propTypes.string,

    children: propTypes.node,
    sportMenu: propTypes.node,
    backgroundImageUrl: propTypes.string,
};
