import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import {mediaQuery, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';

import {scaleInOut, transition} from '../../../../../common/animations';
import {Button} from '../../../../../common/normalized-styled-components';
import {mist, indigo, cobalt, ink, cloudy} from '../../../../../common/palette';
import {SCREEN_768_TABLET} from '../../../../../common/screen-sizes';

import BA01BtnPr from '../../../../atoms/ba/01-btn-pr';
import IM01Input from '../../../../molecules/im/01-input';
import {IC43Valid, IC42Error, IC135Cross} from '../../../../atoms/ic';

import {ButtonIconBox, StyledIC103Loading} from './common';
import useMobileUpdateModal from './use-mobile-update-modal';

/** ********************************************
 *                  WRAPPER
 ***********************************************/

const EditContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    ${stylesWhenNot('isNewMobileNumber')`
        position: absolute;
        top: 0;
    `}

    ${scaleInOut}
`;

/** ********************************************
 *                  FORM
 ***********************************************/

const EditMobileNumberEditor = styled.form`
    box-sizing: border-box;
    display: grid;
    position: relative;
    flex-basis: 600px;
    grid-row-gap: 28px;
    justify-items: center;
    margin: 7px;
    border-radius: 3px;
    box-shadow: 0 0 21px ${mist};
    background: ${rgba(cloudy, 0.84)};
    padding: 28px 49px;
`;

const EditMobileNumberHeading = styled.h4`
    margin: 0;
    padding-bottom: 7px;
    text-align: center;
    color: ${cobalt};
    font: var(--quicksilver-header-5);
`;

const EditMobileNumberSubheading = styled.p`
    margin: 0;
    text-align: center;
    color: ${ink};
    font: var(--quicksilver-body-copy-5);
`;

const CloseButton = styled(Button).attrs({
    'type': 'button',
    'aria-label': 'Close Message',
})`
    appearance: none;
    box-sizing: content-box;
    position: absolute;
    top: 0;
    right: 0;
    transition: ${transition('color')};
    border: 0;
    background: transparent;
    padding: 14px;
    width: 20px;
    color: ${rgba(indigo, 0.42)};

    &:hover,
    &:focus {
        outline: 0;
        color: ${rgba(indigo, 0.84)};
    }
`;

const SuccessIconBox = styled.div`
    width: 40px;
    height: 40px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: 50px;
        height: 50px;
    `}
`;

/* * ********************************************
 *   Main
 ***********************************************/

const MobileUpdateModal = ({
    hasSubmittedMobileNumber,
    isEditingMobileNumber,
    isSubmittingMobileNumber,
    mobileNumberError,
    mobileNumberExternal,
    onCancelEditMobileNumber,
    onSubmitMobileNumber,
    processMobileNumberInput,
    validateMobileNumber,
}) => {
    const {
        isMobileNumberValid,
        mobileNumberEditorRef,
        mobileNumberInputRef,
        onChangeMobileNumber,
        onClickCloseModalButton,
        onSubmitMobileUpdate,
        processedMobileNumber,
    } = useMobileUpdateModal({
        isEditingMobileNumber,
        mobileNumberExternal,
        onCancelEditMobileNumber,
        onSubmitMobileNumber,
        processMobileNumberInput,
        validateMobileNumber,
    });

    return (
        <EditContainer isNewMobileNumber={!mobileNumberExternal}>
            <EditMobileNumberEditor
                ref={mobileNumberEditorRef}
                onSubmit={onSubmitMobileUpdate}
            >
                {mobileNumberExternal ? (
                    <CloseButton
                        disabled={isSubmittingMobileNumber}
                        onClick={onClickCloseModalButton}
                    >
                        <IC135Cross color={ink} />
                    </CloseButton>
                ) : (
                    <div>
                        <EditMobileNumberHeading>
                            Please enter your mobile number
                        </EditMobileNumberHeading>
                        <EditMobileNumberSubheading>
                            We're going to send a verification code to your phone
                        </EditMobileNumberSubheading>
                    </div>
                )}

                <IM01Input
                    contextualHelp={mobileNumberError}
                    disabled={hasSubmittedMobileNumber || isSubmittingMobileNumber}
                    icon={mobileNumberError ? <IC42Error color={ink} secondaryColor={cloudy} /> : undefined}
                    isLabelCentered={true}
                    isTextCentered={true}
                    label="Mobile number"
                    name="mobileNumber"
                    onChange={onChangeMobileNumber}
                    ref={mobileNumberInputRef}
                    type="tel"
                    value={processedMobileNumber}
                />

                {hasSubmittedMobileNumber ? (
                    <SuccessIconBox>
                        <IC43Valid color={cobalt} />
                    </SuccessIconBox>
                ) : (
                    <BA01BtnPr
                        type="submit"
                        onClick={() => { /* eslint-disable-line no-empty-function */
                        /**
                         * This is because noop not considered onClick,
                         * and with no onClick we're a div and not a button...
                         */
                        }}
                        disabled={isSubmittingMobileNumber || !isMobileNumberValid}
                    >
                        {isSubmittingMobileNumber ? ( // eslint-disable-line no-nested-ternary
                            <ButtonIconBox>
                                <StyledIC103Loading />
                            </ButtonIconBox>
                        ) : mobileNumberExternal ? (
                            'Update'
                        ) : (
                            'Submit'
                        )}
                    </BA01BtnPr>
                )}
            </EditMobileNumberEditor>
        </EditContainer>
    );
};

MobileUpdateModal.displayName = 'MobileUpdateModal';

MobileUpdateModal.propTypes = {
    /** Has the mobile number been successfully submitted? */
    hasSubmittedMobileNumber: propTypes.bool,
    /** Are we editing the mobile number? */
    isEditingMobileNumber: propTypes.bool,
    /** Is the updated mobile number being submitted? */
    isSubmittingMobileNumber: propTypes.bool,
    /** The mobile number passed down by parent */
    mobileNumberExternal: propTypes.string,
    /** Mobile number error message */
    mobileNumberError: propTypes.node,
    /** Handle cancellation of editing the mobile number */
    onCancelEditMobileNumber: propTypes.func,
    /** Mobile number submit handler */
    onSubmitMobileNumber: propTypes.func,
    /** Validate and format a change to the mobile number in the editor: (oldValue, newValue) => result */
    processMobileNumberInput: propTypes.func,
    /** Validate a mobile number for completeness: (mobileNumber) => true/false */
    validateMobileNumber: propTypes.func,

};

export default MobileUpdateModal;
