import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import {hubblGreen} from '../../../../common/palette';

import Ic from '..';

const IC133Scrub = ({
    color = hubblGreen,
    ...args
}) => (
    <Ic color={color} {...args}>
        <circle cx="12.941" cy="12.167" r="12.167" fillRule="nonzero" transform="translate(36.226 37)" />
    </Ic>
);

IC133Scrub.propTypes = {
    /** The icon's primary color */
    color: colorType,
};

IC133Scrub.displayName = 'IC133Scrub';

export default IC133Scrub;
