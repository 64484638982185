import React from 'react';
import Ic from '..';

const IC08Cc = (args) => (
    <Ic {...args}>
        <path d="M91.7 16.7H8.3c-1.5 0-2.8 1.2-2.8 2.8v14.9h5.6V22.2h77.8v55.6H54.3v5.6h37.4c1.5 0 2.8-1.2 2.8-2.8V19.4c-.1-1.5-1.3-2.7-2.8-2.7zM12.9 75.7c-2-2-4.5-3.5-7.3-4.2v3.8c1.8.6 3.4 1.6 4.7 3 1.4 1.4 2.5 3.2 3.1 5.1h3.8c0-.1-.1-.3-.1-.4-.8-2.8-2.2-5.3-4.2-7.3zM22.1 66.5c-4.4-4.4-9.9-7.4-15.8-8.9-.2-.1-.4-.1-.6-.1v5.6c4.8 1.3 9.1 3.8 12.6 7.3 3.6 3.6 6.1 8.1 7.4 13h5.6c0-.2-.1-.4-.1-.5-1.5-6.3-4.6-11.9-9.1-16.4z" /><path d="M32.8 55.7c-7.3-7.3-16.5-12-26.7-13.5-.2 0-.4-.1-.5-.1v7.4c8.4 1.4 16 5.3 22.1 11.4s10.1 13.9 11.4 22.5h7.4c0-.2 0-.3-.1-.5-1.5-10.4-6.2-19.8-13.6-27.2z" />
    </Ic>
);

IC08Cc.displayName = 'IC08Cc';

export default IC08Cc;
