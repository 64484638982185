import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import AflTile from './tiles/afl';
import CricketTile from './tiles/cricket';
import FootballTile from './tiles/football';
import MotorTile from './tiles/motor';
import TeamABTile from './tiles/team-a-b';
import DefaultTile from './tiles/default';

const BM01GnrBnr = ({
    className,
    hasNoSpoilers = true,
    sportId,
    ...attrs
}) => {
    let Tile;

    switch (sportId) {
        case 'afl':
            Tile = AflTile;
            break;

        case 'cricket':
            Tile = CricketTile;
            break;

        case 'football':
            Tile = FootballTile;
            break;

        case 'motor':
            Tile = MotorTile;
            break;

        // Team A/B Default
        case 'baseball':
        case 'basketball':
        case 'gridiron':
        case 'icehockey':
        case 'league':
        case 'netball':
        case 'nrl':
        case 'rugby':
            Tile = TeamABTile;
            break;

        default:
            Tile = DefaultTile;
    }

    return (
        <Tile
            {...attrs}
            className={classnames('BM01GnrBnr', className)}
            hasNoSpoilers={hasNoSpoilers}
        />
    );
};

BM01GnrBnr.displayName = 'BM01GnrBnr';

BM01GnrBnr.propTypes = {
    /** className */
    className: classNameType,
    /** Don't show any spoilers, i.e match results, scores */
    hasNoSpoilers: propTypes.bool,
    /** For switching between custom tiles */
    sportId: propTypes.string,
};

export default BM01GnrBnr;
