/**
 * Strip non-numeric characters from a mobile number.
 *
 * @param {string} text - some input text
 * @returns {string} the input text with non-numeric characters stripped out
 */
export function stripNonNumericChars(text) {
    return text.replace(/\D/g, '');
}

/**
 * Is the specified mobile number valid?
 *
 * It's valid if it doesn't contain any letters and, when stripping out any other non-numeric characters, it has exactly
 * 10 digits, starting with "04".
 *
 * NOTE: Australian mobile numbers only
 *
 * @param {string} mobileNumber - a mobile phone number
 * @returns {boolean} <code>true</code> if the mobile phone number is valid, otherwise <code>false</code>
 */
export function isValidMobileNumber(mobileNumber) {
    if (!mobileNumber) {
        return false;
    }

    // If there are any alphabetical characters, it is not valid
    if (/[A-Za-z]/.test(mobileNumber)) {
        return false;
    }

    // Strip out non-numeric characters and validate the numeric string
    const unformatted = stripNonNumericChars(mobileNumber);

    return /^04\d{8}$/.test(unformatted);
}

/**
 * Format a mobile phone number in the standard format.
 *
 * This assumes that <code>isValidMobileNumber(mobileNumber) === true/code>.
 *
 * NOTE: Australian mobile numbers only
 *
 * @param {string} mobileNumber - a valid mobile phone number
 * @returns {string} the formatted mobile phone number
 */
export function formatMobileNumber(mobileNumber) {
    const unformatted = stripNonNumericChars(mobileNumber);

    return `${unformatted.substring(0, 4)} ${unformatted.substring(4, 7)} ${unformatted.substring(7, 10)}`;
}

/**
 * Internationalise a valid Australian mobile phone number, formatted or otherwise.
 *
 * For example, "0411222333" becomes "+61411222333".
 *
 * If the number is already internationalised, it is returned as is, with any non-numeric characters (except the
 * leading +) stripped out.
 *
 * @param {string} mobileNumber - a valid mobile phone number
 * @returns {string} the mobile number in internationalised form
 */
export function internationaliseMobileNumber(mobileNumber) {
    return mobileNumber.startsWith('+61')
        ? `+${stripNonNumericChars(mobileNumber)}`
        : `+61${stripNonNumericChars(mobileNumber).substring(1)}`;
}

/**
 * Localise a valid Australian mobile phone number in internationalised format.
 *
 * For example, "+61411222333" becomes "0411222333".
 *
 * If the number is already localised, it is returned as it, with any non-numeric characters stripped out.
 *
 * @param {string} mobileNumber - valid, potentially internationalised, mobile phone number
 * @returns {string} the mobile number in localised form
 */
export function localiseMobileNumber(mobileNumber) {
    return mobileNumber.startsWith('+61')
        ? `0${stripNonNumericChars(mobileNumber.substring(3))}`
        : stripNonNumericChars(mobileNumber);
}

/**
 * Validate a mobile phone number as it is being entered.
 *
 * We're assuming <code>oldValue</code> is either an empty string or a string previously returned by this function.
 *
 * NOTE: Australian mobile numbers only
 *
 * @param {string} [oldValue] - the previous value
 * @param {string} newValue   - the new value
 * @returns {string} the progressively formatted mobile number
 */
export function progressivelyFormatAndValidateMobileNumber(oldValue = '', newValue = '') {
    // An empty string is valid
    if (!newValue) {
        return newValue;
    }

    // If the new number is too long, return the old number.
    if (newValue.replace(/ /g, '').length > 10) {
        return oldValue;
    }

    // If the new number isn't the start of a valid mobile number (with or without spaces), return the old number.
    if (!/^0(4(?:\d(?:\d(?: ?(?:\d(?:\d(?:\d(?: ?(?:\d{1,3})?)?)?)?)?)?)?)?)?$/.test(newValue)) {
        return oldValue;
    }

    // If we have 5 to 7 digits entered with no spaces, insert a space between the 4th and 5th digits.
    if (/^04\d{3,5}$/.test(newValue)) {
        return `${newValue.substring(0, 4)} ${newValue.substring(4)}`;
    }

    // If we have 8 to 10 digits entered with no spaces, insert a space between the 7th and 8th digits.
    if (/^04\d{6,8}$/.test(newValue)) {
        return `${newValue.substring(0, 4)} ${newValue.substring(4, 7)} ${newValue.substring(7, 10)}`;
    }

    // If we have 4 digits, a space, and 4 to 6 additional digits entered, insert a space between the 7th and 8th digits.
    if (/^04\d{2} \d{4,6}$/.test(newValue)) {
        return `${newValue.substring(0, 8)} ${newValue.substring(8, 10)}`;
    }

    // If we're deleting digits from the end, trim spaces.
    return oldValue.startsWith(newValue) ? newValue.trim() : newValue;
}
