import {css} from 'styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {SMALLEST_SUPPORTED_WIDTH, SCREEN_768_TABLET, SCREEN_1024_DESKTOP, SCREEN_1280_DESKTOP, SCREEN_1920_DESKTOP, SCREEN_2560_DESKTOP} from './screen-sizes';
import {sizesAtViewportsToSizes} from './stringify-srcset-options';

export const TILE_PX_WIDTHS = {
    [SMALLEST_SUPPORTED_WIDTH]: 97,
    [SCREEN_768_TABLET]: 126,
    [SCREEN_1024_DESKTOP]: 143,
    [SCREEN_1280_DESKTOP]: 224,
    [SCREEN_1920_DESKTOP]: 324,
    [SCREEN_2560_DESKTOP]: 437,
};

export const TILE_SIZES_ATTRIBUTE = sizesAtViewportsToSizes(TILE_PX_WIDTHS);

/**
 * This is bad and should be moved into the relevant components.
 * However I need to work with Claire further on this before I want to go too far down that rabbit hole
 */
export default css`
    width: ${TILE_PX_WIDTHS[SMALLEST_SUPPORTED_WIDTH]}px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: ${TILE_PX_WIDTHS[SCREEN_768_TABLET]}px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        width: ${TILE_PX_WIDTHS[SCREEN_1024_DESKTOP]}px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        width: ${TILE_PX_WIDTHS[SCREEN_1280_DESKTOP]}px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: ${TILE_PX_WIDTHS[SCREEN_1920_DESKTOP]}px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
        width: ${TILE_PX_WIDTHS[SCREEN_2560_DESKTOP]}px;
    `}
`;

