import React from 'react';

import Ic from '..';

const IC13FF = (args) => (
    <Ic {...args}>
        <path fillRule="nonzero" d="M41.454 37.348v5.24L21.192 27.384v44.633l20.262-15.203v5.24L17 80.402V19zm13.974-1.579v27.864l18.586-13.89-18.585-13.974M47.044 19l40.944 30.743-40.944 30.743V19z" />
    </Ic>
);

IC13FF.displayName = 'IC13FF';

export default IC13FF;
