import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import {ink, slate} from '../../../../common/palette';

import Ic from '..';

const IC325Warranty = ({
    color = ink,
    secondaryColor = slate,
    ...args
}) => (
    <Ic color={color} {...args}>
        <g fill="none" fillRule="evenodd" transform="translate(10 10)">
            <path
                fill={secondaryColor}
                fillOpacity=".35"
                d="m41.297 5.749 1.807.92a9.06 9.06 0 0 0 4.092.978h2.042c1.624 0 3.144.774 4.067 2.07l1.16 1.63a8.76 8.76 0 0 0 3.154 2.71l1.808.921c1.438.732 2.413 2.103 2.61 3.667l.245 1.965a8.417 8.417 0 0 0 1.495 3.822l1.16 1.63a4.672 4.672 0 0 1 .553 4.423l-.724 1.851a8.34 8.34 0 0 0-.508 4.057l.246 1.966a4.717 4.717 0 0 1-1.629 4.166l-1.528 1.313a8.57 8.57 0 0 0-2.394 3.364l-.724 1.851c-.539 1.378-1.698 2.434-3.137 2.873l7.625 16.353a1 1 0 0 1-1.063 1.41l-7.32-1.163a1 1 0 0 0-1.015.473l-3.815 6.355a1 1 0 0 1-1.764-.092l-7.827-16.786-7.827 16.786a1 1 0 0 1-.293.368l-.098.067a1 1 0 0 1-1.372-.343L26.508 69a1 1 0 0 0-1.015-.473l-7.32 1.163a1 1 0 0 1-1.063-1.41l7.438-15.952-1.34-.32c-1.576-.376-2.862-1.48-3.438-2.954l-.724-1.85a8.567 8.567 0 0 0-2.394-3.365l-1.528-1.313a4.717 4.717 0 0 1-1.628-4.165l.246-1.966a8.34 8.34 0 0 0-.508-4.058l-.724-1.85a4.672 4.672 0 0 1 .554-4.424l1.159-1.63a8.417 8.417 0 0 0 1.495-3.822l.246-1.965c.196-1.564 1.171-2.935 2.61-3.667l1.807-.92a8.759 8.759 0 0 0 3.154-2.71l1.16-1.63c.923-1.297 2.443-2.07 4.067-2.07h2.042a9.06 9.06 0 0 0 4.092-.979l1.807-.92a5.08 5.08 0 0 1 4.594 0zM38.13 56.081l1.475.69c.12.056.223.132.308.223a.964.964 0 0 1 .307-.223l.986-.461-.1-.023a9.101 9.101 0 0 0-2.976-.206zM40 12c-10.493 0-19 8.507-19 19s8.507 19 19 19 19-8.507 19-19-8.507-19-19-19z"
            />
            <path
                stroke={color}
                strokeWidth="3"
                d="M38.197 4.931a3.983 3.983 0 0 1 3.606 0l1.955.997c1.57.8 3.336 1.223 5.107 1.223h2.208c1.266 0 2.456.598 3.177 1.611l1.254 1.766a10.985 10.985 0 0 0 3.954 3.403l1.955.997c1.105.563 1.864 1.614 2.015 2.821l.266 2.13a10.63 10.63 0 0 0 1.883 4.822l1.254 1.765a3.57 3.57 0 0 1 .425 3.376l-.784 2.006a10.55 10.55 0 0 0-.64 5.126l.266 2.13c.15 1.198-.323 2.389-1.252 3.189l-1.653 1.422a10.788 10.788 0 0 0-3.008 4.235l-.783 2.005c-.447 1.145-1.45 1.995-2.672 2.287l-2.143.514a11.162 11.162 0 0 0-4.665 2.379l-1.653 1.422a3.958 3.958 0 0 1-3.497.833l-2.144-.513a11.342 11.342 0 0 0-5.256 0l-2.145.513a3.956 3.956 0 0 1-3.496-.833l-1.653-1.422a11.167 11.167 0 0 0-4.665-2.38l-2.144-.513c-1.221-.292-2.224-1.142-2.671-2.287l-.783-2.005a10.785 10.785 0 0 0-3.008-4.235l-1.653-1.422a3.616 3.616 0 0 1-1.252-3.19l.266-2.129a10.55 10.55 0 0 0-.64-5.127l-.783-2.004a3.572 3.572 0 0 1 .425-3.377l1.253-1.764a10.63 10.63 0 0 0 1.883-4.824l.266-2.128c.15-1.208.91-2.259 2.015-2.822l1.955-.997a10.983 10.983 0 0 0 3.954-3.403l1.254-1.765c.72-1.014 1.91-1.612 3.177-1.612h2.208c1.772 0 3.537-.423 5.107-1.223z"
            />
            <circle cx="40" cy="31" r="17.5" stroke={color} strokeWidth="3" />
            <path
                stroke={color}
                strokeLinejoin="round"
                strokeWidth="3"
                d="m23.567 52.105-6.92 16.393 9.785-1.906 5.426 8.363 7.46-17.811m17.132-5.039 6.92 16.393-9.785-1.906-5.426 8.363-7.46-17.811"
            />
        </g>
    </Ic>
);

IC325Warranty.propTypes = {
    /** The icon's primary color */
    color: colorType,
    /** The icon's secondary color */
    secondaryColor: colorType,
};

IC325Warranty.displayName = 'IC325Warranty';

export default IC325Warranty;
