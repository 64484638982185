import React from 'react';
import Ic from '..';

const IC14Skb = (args) => (
    <Ic {...args}>
        <path d="M17 43L13.309 43 13.309 18.842 8 18.842 8 16 17 16zM33.93 42.984c-3.538.2-6.922-1.49-8.93-4.46l2.13-2.153c1.517 2.269 4.062 3.6 6.759 3.537 3.077 0 6.292-1.846 6.292-5.872 0-4.027-3.174-5.886-6.292-5.886-1.705-.07-3.386.42-4.795 1.398l-1.923-1.119L29.52 16h13.656v2.992H32.28l-1.374 6.99c1.224-.492 2.532-.73 3.847-.698 4.41 0 9.246 2.88 9.246 8.696s-4.96 9.004-10.07 9.004zM60.88 46L69.363 45.972 67.511 28.987 85 25.2 82.582 17 59 22.609z" />
        <path d="M61.047 74.65c5.636.207 11.115-1.877 15.183-5.775 4.067-3.898 6.375-9.276 6.393-14.903.168-6.874-3.417-11.313-8.605-15.223l-.781-5.691L78.927 32c6.793 5.107 12.316 11.66 12.065 22.18C90.77 70.26 77.568 83.139 61.452 83h-.545c-7.615-.14-30.684 0-30.907 0v-8.35c.893-.013 23.334-.138 31.047 0z" />
    </Ic>
);

IC14Skb.displayName = 'IC14Skb';

export default IC14Skb;
