import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import {slate, ink} from '../../../../common/palette';

import Ic from '..';

const IC314Cart = ({
    color = ink,
    secondaryColor = slate,
    ...args
}) => (
    <Ic {...args}>
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h100v100H0z" />
            <path fill={secondaryColor} fillOpacity=".35" d="M24.137 27.285h61.599l-5.341 30.9a9.566 9.566 0 0 1-9.426 7.936H39.393a9.566 9.566 0 0 1-9.4-7.792l-5.856-31.044z" />
            <path fill={color} fillRule="nonzero" d="m85.736 27.285-5.343 30.908c-.792 4.583-4.748 7.928-9.374 7.928H39.343c-4.571 0-8.496-3.268-9.348-7.783l-5.858-31.053h61.599zm-4.242 3.586h-53.05l5.057 26.799a5.957 5.957 0 0 0 5.591 4.859l.251.005H71.02c2.891 0 5.364-2.09 5.859-4.955l4.616-26.708zM43.452 70.32c4.324 0 7.83 3.525 7.83 7.872 0 4.348-3.506 7.873-7.83 7.873-4.325 0-7.83-3.525-7.83-7.873 0-4.347 3.505-7.872 7.83-7.872zm0 3.587c-2.354 0-4.263 1.919-4.263 4.285 0 2.367 1.909 4.286 4.263 4.286s4.262-1.919 4.262-4.286c0-2.366-1.908-4.285-4.262-4.285zm24.013-3.587c4.324 0 7.83 3.525 7.83 7.872 0 4.348-3.506 7.873-7.83 7.873-4.325 0-7.83-3.525-7.83-7.873 0-4.347 3.505-7.872 7.83-7.872zm0 3.587c-2.354 0-4.263 1.919-4.263 4.285 0 2.367 1.909 4.286 4.263 4.286s4.262-1.919 4.262-4.286c0-2.366-1.908-4.285-4.262-4.285z" />
            <path fill={color} fillRule="nonzero" d="M15.38 15.374c5.45-1.277 9.252.733 10.642 5.77l.25.935c1.147 4.418 2.873 12.66 5.19 24.767a1.793 1.793 0 0 1-1.415 2.1 1.785 1.785 0 0 1-2.089-1.423l-.635-3.298c-2.148-11.08-3.731-18.467-4.74-22.122-.855-3.101-2.732-4.093-6.394-3.236a1.784 1.784 0 0 1-2.142-1.34 1.794 1.794 0 0 1 1.333-2.153z" />
        </g>
    </Ic>
);

IC314Cart.propTypes = {
    /** The icon's primary color */
    color: colorType,
    /** The icon's secondary color */
    secondaryColor: colorType,
};

IC314Cart.displayName = 'IC314Cart';

export default IC314Cart;
