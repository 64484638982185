import React from 'react';

import Ic from '..';

const IC63SmartTv = (args) => (
    <Ic {...args}>
        <path d="M19.2 73.2c0 .7.8.7 1.5.7h47.7c.8 0 1.5 0 1.5-.7v-2.1H19.2v2.1zM74.6 23.9H14.5c-.4 0-.7.3-.7.7v43c0 .4.3.7.7.7h60.1c.4 0 .7-.3.7-.7v-43c0-.4-.3-.7-.7-.7zM73.9 67H15.2V25.4h58.6V67zM81.1 54.6h1.1v1.2h-1.1zM81.1 51.6h1.1v1.2h-1.1z" />
        <path d="M84.1 45.9H79c-.5 0-.9.4-.9.9V73c0 .5.4.9.9.9h5.1c.5 0 .9-.4.9-.9V46.8c.1-.5-.3-.9-.9-.9zm-.9 1.9v12.1H80V47.8h3.2z" />
    </Ic>
);

IC63SmartTv.displayName = 'IC63SmartTv';

export default IC63SmartTv;
