import React, {useState} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import noop from 'lodash/noop';

import {stylesWhen, mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';
import {rgba} from 'polished';
import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import {ink} from '../../../../common/palette';
import {Section} from '../../../../common/normalized-styled-components';
import BA01BtnPr from '../../../atoms/ba/01-btn-pr';
import IC27ArrowD from '../../../atoms/ic/27-arrow-d';

const PaymentSection = styled(Section)`
    width: 100%;
    color: ${rgba(ink, 0.7)};
`;

const ChildrenWrapper = styled.div`
    font: var(--quicksilver-body-copy-4);
`;

const TabRow = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0 7px;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 25px;

    ${stylesWhen('isBlock')`
        grid-auto-columns: 1fr;
    `}
`;

const TabButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const StyledIC27ArrowD = styled(IC27ArrowD)`
    margin-top: 4px;
    width: 8px;
    height: 14px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 10px;
        height: 17px;
    `}
`;

/**
 * OR52TabPanels
 *  - Used in My Account
 *
 * @see {@link zpl.io/ewMEj9W}
 */
const OR52TabPanels = ({
    className,
    isBlock,
    onTabChange = noop,
    sections = [],
    shouldPersistDom,
}) => {
    const [visibleIndex, setVisibleIndex] = useState(0);

    return (
        <PaymentSection className={classnames('OR52TabPanels', className)}>
            {!!sections.length && (
                <React.Fragment>
                    <TabRow isBlock={isBlock}>
                        {sections.map(({label}, index) => (
                            <TabButtonWrapper key={label}>
                                <BA01BtnPr
                                    isBlock={isBlock}
                                    aria-pressed={index === visibleIndex}
                                    onClick={(event) => {
                                        setVisibleIndex(index);
                                        onTabChange({label, event, index});
                                    }}
                                >
                                    {label}
                                </BA01BtnPr>
                                {index === visibleIndex && (
                                    <StyledIC27ArrowD />
                                )}
                            </TabButtonWrapper>
                        ))}
                    </TabRow>
                    <ChildrenWrapper>
                        {shouldPersistDom
                            ? sections.map(({children, label}, index) => (
                                <div key={label} hidden={index !== visibleIndex}>
                                    {children}
                                </div>
                            ))
                            : sections[visibleIndex]?.children
                        }
                    </ChildrenWrapper>
                </React.Fragment>
            )}
        </PaymentSection>
    );
};

OR52TabPanels.propTypes = {
    /** CSS class name(s) */
    className: classNameType,
    /** When true enforce the tab grid to take the full width of the parent */
    isBlock: propTypes.bool,
    /** Pass the relevant change to the parent */
    onTabChange: propTypes.func,
    /** Tab labels and their children */
    sections: propTypes.arrayOf(propTypes.shape({
        label: propTypes.string,
        children: propTypes.node,
    })),
    /** Whether to keep DOM in tact when switching tabs (vital for reuse payment switching to showing/hiding Zuora iframe) */
    shouldPersistDom: propTypes.bool,
};

OR52TabPanels.displayName = 'OR52TabPanels';

export default OR52TabPanels;
