import bacon from 'baconjs';
import merge from 'lodash/merge';

import {isServer} from '@fsa-streamotion/browser-utils';

import getHydrationLifecycle from '../../../../todo-move-to-widgets-common/utils/hydration-lifecycle-singleton';
import {getPersonalisedPanelData} from '../../../../todo-move-to-widgets-common/utils/xpapi-helpers';

import getContent from '../../../../todo-move-to-widgets-common/streams/endpoints/xpapi/content';

import localiseContentDisplay from './localise-content-display';

const later0 = bacon.later.bind(null, 0);
const SSR_REPLAY = Symbol('SSR replay - pseudo profileId for SSR replay');

/**
 * @typedef  {Object}   panelData
 * @property {Object[]} contents        content items related to the panel
 * @property {Object}   links           Hypermedia links associated with the panel
 * @property {string}   links.panels    URL to resolve the panel, used for lazy evaluation
 * @property {boolean}  personalised    Indicates if content is volatile based on profile ID. If false, it means that server side rendered content is always safe for client hydration.
 */
/**
 * Returns a stream which manages the complicated dance between personalisation and hydration for a single panel
 *
 *  landing API hit, with evaluate 5. Using the first item in the collection as an example:
 *  1) The panel model **panelData** from the landing API is passed to this function
 *  2) `shouldEvaluate` should be set to `true` (to keep symmetry with the landing's evaluate param)
 *  3) Look at `panelData.personalised`
 *      false: Localise `panelData.contents` using AEST and return. This content is static and safe for client hydration
 *      true: Content is not hydrated and is fetched
 *  4) On client mount, when this function is called again (now with its hydration data) first return any hydration data
 *  5) If the user is active subscription and the content is personalised, a second event will fire containing personalised carousel content
 *
 * @param   {Object}    options                     See below
 * @param   {Object[]}  options.hydration           Hydration payload (reflecting **panel.contents**). This should be the return value of this stream when it was run on the server against the exact same params
 * @param   {string}    options.platformEnv         e.g. 'production'
 * @param   {boolean}   options.shouldEvaluate      Are these contents from a widget which should defer loading? Generally this should be derived by the landing page index against same logic as the to the "evaluate" arg provided to API
 * @param   {panelData} options.panelData           An object representing a single "panel" from the API
 * @param   {string}    options.accessToken         Access Token from Auth0
 * @param   {Object}    options.profile             Auth0 Profile Object
 * @returns {bacon.Observable} Stream containing an array of contents and/or undefined. Stream will emit two values in some cases (e.g. when on clientside + content is personalised + profileId is provided)
 */
export default function panelDataContents({
    // baseUrl,
    // platformEnv,
    accessToken,
    hydration,
    shouldEvaluate,
    panelData: {
        contents,
        links = {},
        personalised,
        panelType,
        // title,
        // id,
    } = {},
    profile = {},
}) {
    if (!shouldEvaluate) {
        // yeah, nah, I'll get content later.... EVEN IF you gave me content ima throw it away
        // logCarousel(title, id, personalised, 'shouldEvaluate=false, so returning undefined');
        return later0(undefined);
    } else if (isServer()) {
        // logCarousel(title, id, personalised, 'localise any contents to AEST');
        if (!Array.isArray(contents)) { // omit contents, as its not an array (mainly to protect against API weirdness)
            return later0(undefined);
        }

        // If this is a personalised panel, and have no contents
        // return undefined, so we start with a placeholder loader, and then remove on client side if no data
        if (personalised && !contents.length) {
            return later0(undefined);
        }

        // If this is a personalised panel, let's omit the CTAs at individual panel and not here
        // Reason being, if client side fetch fails, then we can fallback to hydration data to render
        return later0(contents).map(contentDisplaySansLocalisation);
    }

    // NOTE: from this point down, all code paths are clientside only

    if (Array.isArray(contents) && !personalised) {
        // logCarousel(title, id, personalised, 'using contents array provided on clientside');

        return later0(contentDisplayWithLocalisation(contents));
    }

    const profileIdHydrationLifeCycled$ = bacon
        .fromPromise(getHydrationLifecycle().isAwaitingFirstClientRenderPromise)
        .map(() => profile.activeProfileId)
        .startWith(SSR_REPLAY);

    return profileIdHydrationLifeCycled$
        .flatMapConcat((profileId) => { // using flatMapConcat here to ensure previous stream value resolves (flatMapConcat vs flatMap ensures they resolve in order). if we were to use flatMapLatest, we  up with a race condition as to which stream resolves "latest" - but we need both AND in the right order
            // For client side navigation (i.e. going from shows to watchlist)
            // hydration would be undefined, so use contents if it is available
            const hydrationOrContents = Array.isArray(contents) ? hydration || contents : hydration;

            if (profileId === SSR_REPLAY) {
                // For personalised panels, we want to treat hydration data in the following manner
                // For hero carousel, discard just the CTAs, as content is guaranteed to remain the same
                // For other panels discard completely i.e. return undefined, so we show a loader instead, and load actual data after client hydration completes
                return later0(
                    personalised
                        ? (panelType === 'hero-carousel' && hydrationOrContents) ? hydrationOrContents.map(getPersonalisedPanelData) : undefined
                        : hydrationOrContents // SSR replay. don't mess with it
                );
            } else if (hydrationOrContents && !personalised) { // For personalised content, always fetch client side i.e. getContent below
                return later0(contentDisplayWithLocalisation(hydrationOrContents)); // post hydration render. localise our hydration data
            } else if (!links.panels) {
                return [];
            }

            return getContent({
                accessToken,
                url: links.panels,
                profileId,
            })
                .first()
                .map('.contents')
                .mapError() // fallback to hydration/contents
                .map((apiContents) => apiContents || hydrationOrContents || []) // If contents fetch failed for some reason, fallback to hydration/contents/ (may happen for personalised panels, but no harm to keep in general) or [] so we remove it from the page
                .map(contentDisplayWithLocalisation)
                .map(defaultToEmptyArray);
        });
}

function contentDisplaySansLocalisation(contents) {
    if (!contents) {
        return contents;
    }

    return contents.map((contentItem) => (
        merge({}, contentItem, {
            data: {
                contentDisplayTemplate: contentItem.data.contentDisplay,
                contentDisplay: localiseContentDisplay(contentItem.data.contentDisplay, false),
            },
        })
    ));
}

function contentDisplayWithLocalisation(contents) {
    if (!contents) {
        return contents;
    }

    return contents.map((contentItem) => {
        const contentDisplayTemplate = contentItem.data.contentDisplayTemplate
            || contentItem.data.contentDisplay;

        return merge({}, contentItem, {
            data: {
                contentDisplay: localiseContentDisplay(contentDisplayTemplate, true),
            },
        });
    });
}

function defaultToEmptyArray(arr) {
    if (!arr) {
        // .bind(panelData) on use to get some nice debugging info
        // console.warn(this.title, this.id, 'defaultToEmptyArray');
        return [];
    }

    return arr;
}

// Re-enable this guy to debug API hydration
// function logCarousel(...args) {
//     if (args[1] === '0D840OdjPqa7') {
//         console.info('🎠 CAROUSEL', isServer() ? 'SSR' : 'CLIENT', ...args);
//     }
// }
