import React from 'react';
import propTypes from 'prop-types';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import {hubblGreen} from '../../../../common/palette';

import Ic from '..';

const IC08Cc = ({
    isConnected = false,
    accentColor = hubblGreen,
    color,
    ...args
}) => {
    const radioSignalsColor = isConnected ? accentColor : color;

    return (
        <Ic {...args}>
            <g fill={color}>
                <path opacity={isConnected ? 0.35 : 0} d="M17.796 20.006h67.65c5.522 0 10 4.477 10 10v43.237c0 5.523-4.478 10-10 10H54.429c-.676-13.418-5.31-24.292-13.899-32.622-8.59-8.33-19.501-12.797-32.734-13.399v-7.216c0-5.523 4.477-10 10-10z" />
                <path fillRule="nonzero" d="M86.246 16.995H17.992c-6.728.008-12.18 5.54-12.188 12.367v8.067h4.875v-8.067c.004-4.096 3.276-7.416 7.313-7.42h68.254c4.037.004 7.308 3.324 7.312 7.42v44.525c-.004 4.096-3.275 7.416-7.312 7.42H54.21v4.947h32.036c6.728-.008 12.18-5.54 12.188-12.367V29.362c-.008-6.827-5.46-12.36-12.188-12.367z" />
            </g>
            <g fill={radioSignalsColor} fillRule="nonzero">
                <path d="M8.24 66.18a2.445 2.445 0 0 0-2.436 2.454 2.445 2.445 0 0 0 2.436 2.454c6.413.008 11.61 5.245 11.616 11.708a2.445 2.445 0 0 0 2.436 2.455 2.445 2.445 0 0 0 2.436-2.455c-.01-9.173-7.386-16.606-16.488-16.617z" />
                <path d="M8.217 55.138a2.422 2.422 0 0 0-2.413 2.431 2.422 2.422 0 0 0 2.413 2.432c12.499.014 22.628 10.222 22.642 22.818a2.422 2.422 0 0 0 2.413 2.432 2.422 2.422 0 0 0 2.412-2.432C35.667 67.54 23.38 55.155 8.217 55.138z" />
                <path d="M8.265 44.096a2.47 2.47 0 0 0-2.461 2.48 2.47 2.47 0 0 0 2.46 2.48c19.018.021 34.43 15.553 34.451 34.719a2.47 2.47 0 0 0 2.46 2.48 2.47 2.47 0 0 0 2.462-2.48C47.612 61.87 29.999 44.12 8.265 44.096z" />
            </g>
        </Ic>
    );
};

IC08Cc.propTypes = {
    color: colorType,
    /** Is chromecast connected? */
    isConnected: propTypes.bool,
    /** Accent color for when chromecast is connected */
    accentColor: colorType,
};

IC08Cc.displayName = 'IC08Cc';

export default IC08Cc;
