import React from 'react';
import propTypes from 'prop-types';
import {rgba} from 'polished';
import styled, {css} from 'styled-components';
import {Button} from 'normalized-styled-components';
import classnames from 'classnames';

import {stylesWhen, mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {ariaPressedType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {kayoGreen, white, midnight, black} from '../../../../common/palette';
import {transition} from '../../../../common/animations';
import {SCREEN_PRETTY_LG_DESKTOP} from '../../../../common/screen-sizes';
import IC31Tick from '../../ic/31-tick';

const CHECKMARK_SIZE_PX = 17;
const CHECKMARK_SIZE_PX_1920 = 23;
const CHECKMARK_OFFSET_HOVER_PX = (CHECKMARK_SIZE_PX / 2);
const CHECKMARK_OFFSET_HOVER_PX_1920 = (CHECKMARK_SIZE_PX_1920 / 2);

const hoverAndFocusStyles = css`
    background-color: ${kayoGreen};
    color: ${midnight};
    font-weight: 500;
`;

const StyledButton = styled(Button)`
    appearance: none;
    box-sizing: border-box;
    position: relative;
    transition: ${transition('border-color')};
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: ${midnight};
    padding: 0 21px;
    min-width: 104px;
    max-width: 222px;
    min-height: 40px;
    color: ${rgba(white, 0.6)};
    font: var(--mui-body-copy-4);

    ${mediaQuery({minWidthPx: SCREEN_PRETTY_LG_DESKTOP})`
        min-height: 60px;
        max-width: 391px;
        min-width: 186px;
        padding: 0 28px;
    `}

    ${stylesWhen('isBlock')`
        width: 100%;
    `}

    &:hover,
    &:focus {
        ${hoverAndFocusStyles};
    }

    &:active,
    &[aria-pressed='true'] {
        ${hoverAndFocusStyles};
        background-color: ${rgba(kayoGreen, 0.6)};
    }

    &[aria-pressed='true'] {
        &:hover,
        &:focus {
            ${hoverAndFocusStyles};
        }
    }

    &:disabled {
        background-color: ${rgba(black, 0.3)};
        cursor: not-allowed;
        color: ${rgba(white, 0.3)};
    }
`;

const Checkedmark = styled.span`
    position: absolute;
    top: var(--BA28SecBtn-checkbox-offset, -${CHECKMARK_OFFSET_HOVER_PX}px);
    right: var(--BA28SecBtn-checkbox-offset, -${CHECKMARK_OFFSET_HOVER_PX}px);
    transform: scale(${({isPressed}) => isPressed ? '100%' : 0});
    transition: scale 300ms cubic-bezier(0.68, -0.55, 0.27, 1.55); /* https://easings.net/#easeInOutBack */
    width: ${CHECKMARK_SIZE_PX}px;
    height: ${CHECKMARK_SIZE_PX}px;

    ${mediaQuery({minWidthPx: SCREEN_PRETTY_LG_DESKTOP})`
        top: var(--BA28SecBtn-checkbox-offset-1920, -${CHECKMARK_OFFSET_HOVER_PX_1920}px);
        right: var(--BA28SecBtn-checkbox-offset-1920, -${CHECKMARK_OFFSET_HOVER_PX_1920}px);
        width: ${CHECKMARK_SIZE_PX_1920}px;
        height: ${CHECKMARK_SIZE_PX_1920}px;
    `}
`;

const BA28SecBtn = React.forwardRef(({
    'aria-pressed': isPressed,
    children,
    disabled,
    className,
    hasDropshadow,
    onClick,
    ...htmlAttributes
},
ref) => (
    <StyledButton
        {...htmlAttributes}
        onClick={onClick}
        className={classnames('BA28SecBtn', className)}
        aria-pressed={isPressed}
        type="button"
        disabled={disabled}
        ref={ref}
    >
        {children}
        <Checkedmark isPressed={isPressed} role="presentation">
            <IC31Tick hasDropshadow={hasDropshadow} />
        </Checkedmark>
    </StyledButton>
));

BA28SecBtn.displayName = 'BA28SecBtn';

/* eslint-disable quote-props */
BA28SecBtn.propTypes = {
    /** aria-pressed attribute describes a toggle button */
    'aria-pressed': ariaPressedType,
    // eslint-disable-next-line react/boolean-prop-naming
    disabled: propTypes.bool,
    /** Display checkmark's dropshadow  */
    hasDropshadow: propTypes.bool,
    /** Callback on element click */
    onClick: propTypes.func,
    /** Content of button, e.g. text */
    children: propTypes.node.isRequired,
    /** Additional CSS class(es) */
    className: classNameType,
};
/* eslint-enable quote-props */

export default BA28SecBtn;
