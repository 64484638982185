import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import styled from 'styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {kayoGreen, white, black} from '../../../../common/palette';
import {SCREEN_PHONE, SCREEN_TABLET, SCREEN_LG_DESKTOP, SCREEN_PRETTY_LG_DESKTOP} from '../../../../common/screen-sizes';

const StyledSvg = styled.svg.attrs({
    viewBox: '0 0 300 160',
    xmlns: 'http://www.w3.org/2000/svg',
})`
    display: inline-block;
`;

const BRANDING_WIDTH_PX = 91;
const BRANDING_WIDTH_LG_DESKTOP_PX = 151;
const BRANDING_WIDTH_PLG_DESKTOP_PX = 227;

// If you're using `Branding` (below), this is the sizesDefinition for optimal scaling of the nested GA92BrandLogo
export const BRANDING_SIZES_DEFINITION = {
    [SCREEN_PHONE]: BRANDING_WIDTH_PX,
    [SCREEN_LG_DESKTOP]: BRANDING_WIDTH_LG_DESKTOP_PX,
    [SCREEN_PRETTY_LG_DESKTOP]: BRANDING_WIDTH_PLG_DESKTOP_PX,
};

export const Branding = styled.div`
    padding: 14px;
    width: ${BRANDING_WIDTH_PX}px;

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        padding: 24px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
        paddinng: 30px;
        width: ${BRANDING_WIDTH_LG_DESKTOP_PX}px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_PRETTY_LG_DESKTOP})`
       padding: 45px;
        width: ${BRANDING_WIDTH_PLG_DESKTOP_PX}px;
    `}
`;

const GA92BrandLogo = ({
    className,
    height,
    title = 'Kayo',
    width = '100%',
    isInternalMode = true,
    isSportsMode = false,
    ...htmlAttributes
}) => {
    const logoColor = isInternalMode ? white : kayoGreen;

    return (
        <StyledSvg
            {...htmlAttributes}
            className={classnames('GA92BrandLogo', className)}
            fill={logoColor}
            style={{
                width,
                height,
            }}
        >
            <title>{title}</title>

            {isSportsMode ? (
                <React.Fragment>
                    {/* Sports Text Background */}
                    <path
                        fill={isInternalMode ? white : black}
                        d="M164.27 158.78H0l4.51-36.81h164.27l-4.51 36.81z"
                    />
                    {/* Sports Text */}
                    <path
                        fill={isInternalMode ? black : white}
                        d="M24.45 134.63a7.47 7.47 0 0 0-5.22-2.53c-1.67 0-3.34.72-3.34 2.53 0 3.34 9.68 2.54 9.68 9.28s-6.12 9.21-10.48 9.21a10.67 10.67 0 0 1-8.19-3.84l3.41-4.28a8 8 0 0 0 5.8 3.19c2.1 0 3.62-1.09 3.62-2.9 0-3.48-9.64-2.46-9.64-9.06 0-5.95 4.42-8.92 10-8.92a9.54 9.54 0 0 1 7.36 3.15ZM42.77 143.44h-2.94l-1.45 9.25h-5.69l4-25h8.63c4.09 0 7.68 1.63 7.68 6.27 0 6.26-4.25 9.52-10.23 9.48Zm1-11.2h-2.2l-1 6.63h2.17c2.54 0 4.43-1.26 4.43-4-.05-2.09-1.65-2.63-3.42-2.63ZM69.78 153.12c-6.34 0-10.91-3.88-10.91-10.4 0-8.74 6.2-15.45 15-15.45 6.38 0 10.91 3.88 10.91 10.44.01 8.7-6.19 15.41-15 15.41Zm3.3-20.81c-5 0-8.34 4.61-8.34 9.36 0 3.62 2.22 6.16 5.88 6.16 5.07 0 8.37-4.61 8.37-9.35.01-3.63-2.21-6.17-5.91-6.17ZM105.94 142.35l4.49 10.34h-6.63l-3.55-9.54h-1.74L97 152.69h-5.67l4-25h8.63c4.1 0 8 1.49 8 6.2a8.83 8.83 0 0 1-6.02 8.46Zm-3.52-10.11h-2.21l-1 6.34h2.21c2.47 0 4.61-1 4.61-3.8.02-2.14-1.87-2.54-3.61-2.54ZM137.71 127.67l-.73 4.72h-6.2l-3.22 20.3h-5.69l3.22-20.3h-6.23l.76-4.72ZM158.16 134.63a7.46 7.46 0 0 0-5.22-2.53c-1.66 0-3.33.72-3.33 2.53 0 3.34 9.68 2.54 9.68 9.28s-6.13 9.21-10.48 9.21a10.65 10.65 0 0 1-8.19-3.84L144 145a8 8 0 0 0 5.81 3.19c2.1 0 3.62-1.09 3.62-2.9 0-3.48-9.64-2.46-9.64-9.06 0-5.95 4.42-8.92 10-8.92a9.54 9.54 0 0 1 7.36 3.15Z"
                    />
                </React.Fragment>
            ) : null}

            {/* Kayo Text */}
            <path d="M46.7 64.1l21.7 46.7h12.2L54.4 54.5zM53.1 110.8h12.4L44.8 66.4l-8 9.8z" />
            <path d="M18.4 110.8h11.1l4.9-35.4L95.6 0H82.2L24 71.3zM78.7 0H66.5L40.4 32.3 44.9 0H34l-9.3 66.4zM20.5 0L5.1 110.8h11L31.6 0zM186.4 88.9c-6.3 0-7.6-5.5-7.1-8.1l8.3-56.7h-11.1l-7.9 57.3c-1.1 13 7.9 20.8 22.8 17.5l1.9-12.9s-3.7 2.9-6.9 2.9z" />
            <path d="M180.2 101.8c-2.3-.4-15.4-3.6-14.1-20.7l7.8-57h-10.7L155 79.9c-3 22.3 12.5 34.8 34.6 32l1.4-10.3c-3.5.6-7.2.6-10.8.2z" />
            <path d="M201.7 121.5L215.8 24h-11.5l-14.4 101.3c-.8 5.3-6.7 10.1-12.2 8.7l-1.5 11c17.7 1.6 23.8-12.3 25.5-23.5z" />
            <path d="M228.7 24h-10.3l-14 96.4c-1.6 11.9-7.1 29.2-28.5 27.1l-1.4 10.1c30.7 1.1 38.5-20.5 39.8-31.4L228.7 24zM103.6 81.7l3.6-26.3c2.2-9.8 12.5-11.1 15.7-6.8l1.6-11.6c-2.4-.8-5-1.1-7.5-1.1-8.3 0-18.5 6.9-19.8 16.6l-4.1 29.9c-.8 7.8 4 15.7 15 16.5 2.8.2 5.5-.3 8.1-1.3l1.5-11c-9.5 7.1-14.8-1.1-14.1-4.9z" />
            <path d="M90.7 82.5l4.1-29.8c2.5-15.3 18.5-21.9 30.1-18.1l1.4-10.1c-28.9-4-40.6 18.1-41.9 28.1L80.3 82c-1.2 9.1 2.6 32.7 34 29.1l1.5-10.8c-12 4-26.8-3.3-25.1-17.8zM140.3 24.1h-11l-11.9 86.7h10.7zM153.1 24.1h-10.3l-12.2 86.7h10.6zM270.9 21.6c-26.3 0-35.7 21.1-37 31.6l-4.1 30.4c-1.3 9.4 3.1 30.2 29.1 30.2 19.5 0 34.2-12.7 36.8-31.7l4-28.7c1.7-11.7-3.1-31.8-28.8-31.8zm13.6 62.6c-.3 1.8-3.9 19.8-24.8 19.8-16.6 0-19.9-14.6-19.2-21.1.3-2.5 4.2-31.1 4.7-33.5.7-3.4 6.6-17.8 23.2-17.8 21.7 0 20.8 19.2 20.4 22.3-.2 2-4 28.7-4.3 30.3z" />
            <path d="M268.4 33.8c-15.3 0-20.4 13.5-21.1 16.1-.6 2.3-4.5 31.2-4.7 33.8-.4 5.4 2.3 18.1 17.4 18.1 14.9 0 21.4-11.3 22.3-17.9.2-1.6 4.1-28.1 4.3-30.2s1.5-19.9-18.2-19.9zM261.2 91c-5.7 0-8-5-7.5-9l3.9-28.1c.8-5.1 5.9-9.7 10.8-9.7 4.1-.1 7.6 3.2 7.6 7.4 0 .5 0 1-.1 1.5L272 81.4c-.7 5-5.8 9.6-10.8 9.6z" />
        </StyledSvg>
    );
};

GA92BrandLogo.displayName = 'GA92BrandLogo';

GA92BrandLogo.propTypes = {
    /** Additional CSS classname(s) to use */
    className: classNameType,
    /** Accessible label for logo */
    title: propTypes.string,
    /** Optional width of logo */
    width: propTypes.string,
    /** Optional height of logo */
    height: propTypes.string,
    /** Optional internal mode (white) */
    isInternalMode: propTypes.bool,
    /** Optional sports mode */
    isSportsMode: propTypes.bool,
};

export default GA92BrandLogo;
