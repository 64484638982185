import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';
import {Button} from '../../../../common/normalized-styled-components';
import {ink} from '../../../../common/palette';
import iconBaseCtaStyles from '../../../../common/button-utils/icon-base-styles';

const StyledButton = styled(Button)`
    background-color: ${ink};
    /* This goes after in case user passes custom backgroundColor */
    ${iconBaseCtaStyles}
`;

/**
 *
 * A secondary navigation button component that can be a link or a button.
 *
 * @param {string} aria-label - Accessible name of element
 * @param {node} children - Child to accept
 * @param {Function} onClick - Callback to call on element click
 * @param {boolean} disabled - Disables the button
 * @param {string} className - Additional CSS class(es)
 * @param {string} backgroundColor - Button background color
 *
 * @returns {JSX.Element} - The rendered component.
 */

const BA39BtnIconSecondary = React.forwardRef(({
    'aria-label': assistiveLabel,
    children,
    onClick,
    backgroundColor,
    disabled = false,
    className,
    ...htmlAttributes
}, ref) => (
    <StyledButton
        {...htmlAttributes}
        aria-label={assistiveLabel}
        disabled={disabled}
        title={assistiveLabel}
        onClick={onClick}
        backgroundColor={backgroundColor}
        className={classnames('BA39BtnIconSecondary', className)}
        type="button"
        ref={ref}
    >
        {children}
    </StyledButton>
));

/* eslint-disable quote-props */
BA39BtnIconSecondary.propTypes = {
    /** Child to accept */
    children: propTypes.node.isRequired,
    /** Assistive label required for screen readers due to graphical only representation of content */
    'aria-label': propTypes.string.isRequired,
    /** Callback to call on element click */
    onClick: propTypes.func,
    /** Disable the button */
    disabled: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    /** Additional CSS class(es) */
    className: classNameType,
    /** Button background color */
    backgroundColor: propTypes.string,
};
/* eslint-enable quote-props */

BA39BtnIconSecondary.displayName = 'BA39BtnIconSecondary';

export default BA39BtnIconSecondary;
