import React from 'react';
import PropTypes from 'prop-types';
import bacon from 'baconjs';

import {palette as palette__infinity} from '@fsa-streamotion/streamotion-web-infinity';
import {palette as palette__ionic} from '@fsa-streamotion/streamotion-web-ionic';
import {palette as palette__mui} from '@fsa-streamotion/streamotion-web-mui';
import {palette as palette__lifestyle} from '@fsa-streamotion/streamotion-web-nucleus';
import {BrandedOR129MarBlk} from '../../../utils/branded-components';

function getFallbackImage(backgroundImageSrcsetOptions = {}) {
    const viewportsKey = Object.keys(backgroundImageSrcsetOptions)
        .map((numberString) => parseInt(numberString))
        .filter((num) => !isNaN(num));

    // We want the largest image from the list
    return viewportsKey.length ? backgroundImageSrcsetOptions[Math.max(...viewportsKey).toString()] : '';
}

export default function MarketingBlock({
    brand,
    hydration,
    accentColor,
    backgroundImageSrcsetOptions,
    ...otherProps
}) {
    const reactElement = (
        <BrandedOR129MarBlk
            {...otherProps}
            brand={brand}
            backgroundImageSrcsetOptions={backgroundImageSrcsetOptions}
            hydration={hydration || {}}
            accentColor={accentColor || ({
                binge: palette__ionic.blade,
                flash: palette__infinity.white,
                kayo: palette__mui.red,
                lifestyle: palette__lifestyle.lifestyle,
            })[brand]}
            backgroundImageFallback={getFallbackImage(backgroundImageSrcsetOptions)}
        />
    );

    return bacon.combineTemplate({reactElement, hydration});
}

MarketingBlock.propTypes = {
    brand: PropTypes.string,
    accentColor: PropTypes.string,
    hydration: PropTypes.object,
    title: PropTypes.string,
    copy: PropTypes.string,
    link: PropTypes.string,
    linkText: PropTypes.string,
    backgroundImageSrcsetOptions: PropTypes.string,
    backgroundImageSrcsetOptionsMobile: PropTypes.string,
    backgroundImageSrcsetOptionsTablet: PropTypes.string,
    backgroundImageTintColor: PropTypes.string,
    backgroundImageCaption: PropTypes.string,
};
