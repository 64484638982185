import React from 'react';
import styled from 'styled-components';
import {Svg} from 'normalized-styled-components';

const StyledSvg = styled(Svg)`
    display: block;
    width: auto;
    height: 100%; /* Scaled by HEIGHT :O */
`;

const GA115ParentalControlsM = ({
    ...htmlAttributes
}) => (
    <StyledSvg
        {...htmlAttributes}
        viewBox="0 0 69 58"
    >
        <path fill="#00A0C6" d="M0 0h69v58H0z" />
        <path fill="#FFF" d="M40.247 42V28.056l-4.617 8.983h-3.26l-4.617-8.983V42H23V18h4.617L34 31.006 40.247 18H45v24z" />
        <path fill="#FFF" d="M15.668 29.5c0-10.389 8.478-18.838 18.901-18.838 10.424 0 18.902 8.45 18.902 18.838 0 10.389-8.478 18.838-18.902 18.838-10.423 0-18.901-8.45-18.901-18.838zM14 29.5C14 40.72 23.173 50 34.57 50 45.826 50 55 40.72 55 29.5S45.827 9 34.57 9C23.172 9 14 18.28 14 29.5z" />
    </StyledSvg>
);

GA115ParentalControlsM.displayName = 'GA115ParentalControlsM';

export default GA115ParentalControlsM;
