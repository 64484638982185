import React from 'react';

import Ic from '..';

const IC231Profiles = (args) => (
    <Ic {...args}>
        <g transform="translate(47 66.84)">
            <path opacity=".35" d="M9.552 2.482c-3.903 0-7.077 3.184-7.077 7.098 0 .605.492 1.098 1.095 1.098h35.86c.603 0 1.095-.493 1.095-1.098 0-3.914-3.174-7.098-7.076-7.098H9.552z" />
            <path d="M9.552 2.482c-3.903 0-7.077 3.184-7.077 7.098 0 .605.492 1.098 1.095 1.098h35.86c.603 0 1.095-.493 1.095-1.098 0-3.914-3.174-7.098-7.076-7.098H9.552zM39.43 13.16H3.57A3.58 3.58 0 0 1 0 9.58C0 4.298 4.285 0 9.552 0h23.897C38.715 0 43 4.298 43 9.58a3.579 3.579 0 0 1-3.57 3.58z" />
        </g>
        <path opacity=".35" d="M68.5 62.353c-10.233 0-18.559-9.276-18.559-20.677C49.941 29.31 57.4 21 68.5 21c11.1 0 18.559 8.309 18.559 20.676 0 11.401-8.326 20.677-18.559 20.677" />
        <path d="M68.5 23.482c-9.771 0-16.084 7.142-16.084 18.194 0 10.032 7.215 18.194 16.084 18.194 8.868 0 16.084-8.162 16.084-18.194 0-11.052-6.313-18.194-16.084-18.194m0 38.87c-10.233 0-18.559-9.275-18.559-20.676C49.941 29.31 57.4 21 68.5 21c11.1 0 18.559 8.309 18.559 20.676 0 11.401-8.326 20.677-18.559 20.677" />
        <g fillOpacity=".35" stroke="currentColor" strokeWidth="2.5">
            <g transform="translate(11 69.963)">
                <path d="M25.67 1.25c1.677 0 3.197.68 4.298 1.778a6.026 6.026 0 0 1 1.782 4.279 1.489 1.489 0 0 1-1.49 1.48H2.74a1.49 1.49 0 0 1-1.49-1.48c0-1.67.682-3.183 1.782-4.28A6.073 6.073 0 0 1 7.33 1.25z" />
            </g>
            <path d="M27.5 66.54c-7.853 0-14.243-7.074-14.243-15.77C13.257 41.337 18.981 35 27.5 35c8.52 0 14.243 6.337 14.243 15.77 0 8.696-6.39 15.77-14.243 15.77" />
        </g>
    </Ic>
);

IC231Profiles.displayName = 'IC231Profiles';

export default IC231Profiles;
