import cuid from 'cuid'; // eslint-disable-line no-restricted-imports

/**
 * CUID doesn't stub properly, so here is a dumb wrapper that can
 *
 * @returns {string} output from running cuid
 */
export default function muid() {
    return cuid();
}
