import React from 'react';
import propTypes from 'prop-types';

import {colorType} from '@fsa-streamotion/custom-prop-types';
import {panther} from '../../../../common/palette';
import muid from '../../../../common/muid';
import Ic from '..';

const IC254GuiltyPleasure = ({color = panther, id = muid(), ...args}) => (
    <Ic id={id} {...args}>
        <g fill={color}>
            <path d="M37.837 74.966c3.697 0 6.93-1.28 9.663-3.433 1.469-1.157 2.565-2.365 3.295-3.386-.683.037-1.375.056-2.072.056-10.03 0-18.78-3.818-23.415-9.48-.243.23-.473.512-.684.843-1.404 2.209-1.415 5.806-.024 8.035.702 1.125 1.898 2.559 3.602 3.919 2.71 2.163 5.933 3.446 9.635 3.446z" />
            <g transform="translate(22.71 23.525)">
                <mask id={`ic-254-guilty-pleasure-${id}__b`} fill="#fff">
                    <path d="M55.926 15.435C49.624 26.1 40.362 31.432 28.14 31.432 16.33 31.911 6.95 26.69 0 15.768 7.39 5.163 17.74.172 28.862.102c11.4-.073 21.525 7.286 27.064 15.333z" />
                </mask>
                <path d="M20.79-7.35c5.432 0 10.122 3.172 12.32 7.765 9.634 1.482 17.976 7.988 22.816 15.02C49.624 26.1 40.362 31.432 28.14 31.432 16.33 31.911 6.95 26.69 0 15.768c2.172-3.116 4.6-5.748 7.227-7.913-.057-.51-.087-1.03-.087-1.555 0-7.539 6.111-13.65 13.65-13.65zM6.477 15.896c5.683 8.613 12.972 12.842 22.196 12.407 9.303 0 16.598-4.246 21.904-12.7-4.04-5.517-9.805-10.322-16.386-11.912.163.844.249 1.717.249 2.609 0 7.539-6.111 13.65-13.65 13.65-5.13 0-9.6-2.83-11.932-7.015l-.211.237a38.46 38.46 0 00-2.17 2.724zm22.97-12.762c-.29.002-.579.01-.867.02A8.4 8.4 0 0112.871 9.11c-.57.456-1.13.938-1.682 1.447A10.503 10.503 0 0020.79 16.8c5.799 0 10.5-4.701 10.5-10.5 0-1.089-.166-2.14-.474-3.127-.454-.029-.91-.042-1.37-.039zM20.79-4.2c-5.559 0-10.109 4.32-10.476 9.785a32.89 32.89 0 012.345-1.401A8.402 8.402 0 0126.553.189a36.353 36.353 0 012.309-.087h.404A10.485 10.485 0 0020.79-4.2z" mask={`url(#ic-254-guilty-pleasure-${id}__b)`} />
            </g>
        </g>
    </Ic>
);

IC254GuiltyPleasure.propTypes = {
    /* Icon colour */
    color: colorType,
    /** Element id/svg id uniquifier */
    id: propTypes.string,
};

IC254GuiltyPleasure.displayName = 'IC254GuiltyPleasure';

export default IC254GuiltyPleasure;
