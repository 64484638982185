import React from 'react';
import Ic from '..';

const IC24ArrowL = (args) => (
    <Ic {...args}>
        <path d="M40 80L0 40 40 0 45.631 5.631 11.262 40 45.631 74.369z" transform="translate(25 10)" />
    </Ic>
);

IC24ArrowL.displayName = 'IC24ArrowL';

export default IC24ArrowL;
