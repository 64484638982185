import {format, parseISO, isValid, differenceInCalendarDays} from 'date-fns';

// TODO Repeating logic from LifeStyle widget, This file will be deleted as part of WEB-2187 */

function isValidIso(isoDateString) {
    return isValid(parseISO(isoDateString));
}

function formatIso(isoDateString, formatStr) {
    return format(parseISO(isoDateString), formatStr);
}

export function makeHumanised({nowTimeDate, startTimeDate}) {
    if (!isValidIso(startTimeDate)) {
        return '';
    }

    // Between 2017-01-01T23:59 and 2017-01-02T00:01 there is zero days, but one calendar day
    const daysDiff = differenceInCalendarDays(startTimeDate, nowTimeDate);
    const isToday = daysDiff === 0;
    const isYesterday = daysDiff === -1;
    const isTomorrow = daysDiff === 1;
    // const isOlderThanYesterday = daysDiff < -1;
    const isWithinForwardWeek = daysDiff <= 7 && daysDiff >= 1;
    const isThisYear = formatIso(nowTimeDate, 'yyyy') === formatIso(startTimeDate, 'yyyy');
    let dateDisplay;

    if (isToday) {
        dateDisplay = 'today';
    } else if (isTomorrow) {
        dateDisplay = 'tomorrow';
    } else if (isYesterday) {
        dateDisplay = 'yesterday';
    } else if (isWithinForwardWeek) {
        dateDisplay = formatIso(startTimeDate, 'dddd');
    } else if (isThisYear) {
        dateDisplay = formatIso(startTimeDate, 'dd MMM');
    } else {
        dateDisplay = formatIso(startTimeDate, 'dd MMM YY');
    }

    // If API moves away from having "${DATE_HUMANISED} ${TIME}" together, and we want time added, this is where you do it.
    // dateDisplay += ' ' + formatIso(startTimeDate, 'h:mmA');

    return dateDisplay;
}

/**
 * Parse a date according to a given date format from the API. Parsed from either ${DAY_HUMANISED} or ${date_format},
 * where the date_format should match according to date-fns's format guidelines
 *
 * @param {string} dateStringFormatTemplate Format string to format the date to
 * @param {string} transmissionTime Transmission time of a video which comes from the API
 * @returns {string} Parsed datetime string
 */
export default function parseDateTime(dateStringFormatTemplate, transmissionTime) {
    if (!dateStringFormatTemplate || !transmissionTime) {
        return '';
    }

    const nowTimeDate = new Date().toISOString();

    try {
        return dateStringFormatTemplate.replace(/\$\{.+?\}/gi, (part) => {
            const slicedPart = part.slice(2, -1);

            return slicedPart === 'DAY_HUMANISED'
                ? makeHumanised({nowTimeDate, transmissionTime})
                : format(parseISO(transmissionTime), slicedPart);
        });
    } catch (e) {
        // Leaving this here so we can catch any other date-fns built-in errors but won't block the user
        // eslint-disable-next-line no-console
        console.error('Parse Info Line DateTime (date-fns) error:', e);

        return dateStringFormatTemplate;
    }
}
