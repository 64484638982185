import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import VisuallyHidden from '../../../../../common/visually-hidden';

/** **********************************************************************
 *                         TABLE HEADER ROW
 ************************************************************************/

const ColumnHeaderCell = styled.th.attrs({
    scope: 'col',
})`
    padding: 0 0 8px;
    word-wrap: break-word;
    font: var(--quicksilver-header-10);
`;

const TableHeader = ({
    headers,
}) => (
    <React.Fragment>
        <VisuallyHidden as="caption">Our streaming packages</VisuallyHidden>
        <thead>
            <tr>
                <VisuallyHidden as="th" scope="col">Package Name</VisuallyHidden>
                {headers.map(({title, a11yTitle}) => (
                    <ColumnHeaderCell
                        key={title}
                        {...(!!a11yTitle && {'aria-label': a11yTitle})}
                    >
                        {title}
                    </ColumnHeaderCell>
                ))}
            </tr>
        </thead>

    </React.Fragment>
);

TableHeader.displayName = 'PackageSelectorTableHeader';

TableHeader.propTypes = {
    headers: propTypes.arrayOf(propTypes.shape({
        title: propTypes.node,
        a11yTitle: propTypes.string,
    })),
};

export default TableHeader;
