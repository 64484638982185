import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {rgba} from 'polished';
import classnames from 'classnames';

import {mediaQuery, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {buttonType, ariaPressedType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {SCREEN_1920_DESKTOP, SMALLEST_SUPPORTED_WIDTH} from '../../../../common/screen-sizes';
import {flash, white, black, buttonActiveState} from '../../../../common/palette';
import {transition} from '../../../../common/animations';
import {A, Button} from '../../../../common/normalized-styled-components';
import getCheckedMarkStylesMap from '../../../../common/get-checked-mark-styles-map';
import IC31Tick from '../../ic/31-tick';

const CHECKMARK_SIZE_PX_MAP = {
    [SMALLEST_SUPPORTED_WIDTH]: 17,
    [SCREEN_1920_DESKTOP]: 26,
};
const CHECKED_MARK_STYLES_BY_WIDTH = getCheckedMarkStylesMap(CHECKMARK_SIZE_PX_MAP);

const Z_INDEX_ABOVE_BUTTON = 1;

const baseStyles = css`
    box-sizing: border-box;
    position: relative;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    transform-origin: left center;
    transition: ${transition('background-color', 'opacity', 'color', 'text-shadow')};
    box-shadow: 0 1px 1px 0 ${rgba(black, 0.3)};
    background-color: ${rgba(white, 0.2)};
    padding: 0 21px;
    height: 40px;
    text-align: center;
    text-shadow: 0 1px 1px ${rgba(black, 0.3)};
    letter-spacing: 0.4px;
    white-space: nowrap;
    color: ${white};
    font: var(--infinity-primary-button);

    ${({isBlock}) => isBlock ? css`
        display: flex;
        width: 100%;
    ` : css`
        display: inline-flex;
        min-width: 104px;

        ${stylesWhenNot('theme.isAppDock')`
            ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
                min-width: 186px;
            `}
        `}
    `}

    ${stylesWhenNot('theme.isAppDock')`
        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            height: 60px;
        `}
    `}

    &:hover,
    &:focus {
        ${stylesWhenNot('disabled')`
            outline: 0;
            background-color: ${flash};
            text-shadow: currentColor 0 0 1px;
        `}
    }

    &:active,
    &[aria-pressed='true'] {
        background-color: ${buttonActiveState};
        text-shadow: currentColor 0 0 1px;
        color: ${white};
    }

    &:disabled {
        background-color: ${rgba(white, 0.1)};
        cursor: not-allowed;
        color: ${rgba(white, 0.2)};
    }
`;

const StyledButton = styled(Button)`
    ${baseStyles}

    appearance: none;
    border: 0;
`;

const StyledLink = styled(A)`
    ${baseStyles}

    text-decoration: none;
`;

const CheckedMarkCircle = styled.span`
    position: absolute;
    transform: scale(${({isPressed}) => isPressed ? '100%' : 0});
    transition: transform 300ms cubic-bezier(0.68, -0.55, 0.27, 1.55); /* https://easings.net/#easeInOutBack */
    stroke: ${rgba(black, 0.3)};
    z-index: ${Z_INDEX_ABOVE_BUTTON};
    ${CHECKED_MARK_STYLES_BY_WIDTH[SMALLEST_SUPPORTED_WIDTH]}
    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        ${CHECKED_MARK_STYLES_BY_WIDTH[SCREEN_1920_DESKTOP]}
    `}
`;
const StyledIC31Tick = styled(IC31Tick)`
    border-radius: 50%;
    box-shadow: -1px 1px 1px 0 ${rgba(black, 0.3)};
`;

const BA01BtnPr = React.forwardRef(({
    children,
    disabled,
    href,
    isBlock,
    'aria-pressed': isPressed,
    onClick,
    type = 'button',
    className,
    ...htmlAttributes
}, ref) => {
    // coerce disabled CTAs into being buttons
    const StyledElement = (href && !disabled) ? StyledLink : StyledButton;

    return (
        <StyledElement
            aria-pressed={isPressed}
            disabled={disabled}
            href={href}
            {...htmlAttributes}
            {...{
                ref,
                isBlock,
                onClick,

            }}
            className={classnames('BA01BtnPr', className)}
            type={href ? undefined : type}
        >
            {children}

            <CheckedMarkCircle isPressed={isPressed} role="presentation">
                <StyledIC31Tick color={flash} secondaryColor={white} />
            </CheckedMarkCircle>

        </StyledElement>
    );
});

/* eslint-disable quote-props */
BA01BtnPr.propTypes = {
    /** Content of button, e.g. text */
    children: propTypes.node.isRequired,
    /** Is this button disabled? */
    disabled: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    /** aria-pressed attribute describes a toggle button */
    'aria-pressed': ariaPressedType,
    /** If it’s actually a link, provide a href */
    href: propTypes.string,
    /** Whether the button fills its parent's width or not */
    isBlock: propTypes.bool,
    /** Callback on element click */
    onClick: propTypes.func,
    /** The HTML type of the button */
    type: buttonType,
    /** Additional CSS class(es) */
    className: classNameType,
};

BA01BtnPr.displayName = 'BA01BtnPr';

export default BA01BtnPr;
