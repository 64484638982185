import bacon from 'baconjs';
import {isServer} from '@fsa-streamotion/browser-utils';

import {initialiseAdobeDefinitionsConfig, trackFromAdobeDefinitions} from '../utils/adobe';
import getActiveProfileIdFromLocalStorage from '../utils/active-profile-id';

export const LOGIN_TRACKING_SENT = 'loginTrackingSent';
const SATELLITE_RETRIES = 10;
const SATELLITE_RETRY_BACKOFF_MS = 500;

/**
 * Get login tracking stream
 *
 * @param {Object} options options
 * @param {string} options.auth0ID auth0 id (from accessTokenDetails.sub)
 * @param {'binge'|'flash'|'kayo'} options.brand possible values 'binge', 'flash' or 'kayo'
 * @param {string} options.hashedEmail hashed user email
 * @param {string} options.platformEnv possible values 'production' or 'staging'
 * @param {string} options.resourcesEnv possible values 'production' or 'staging'
 * @param {{bingeStatus: string, kayoStatus: string, flashStatus: string}} options.allBrandsSubAccountStatus all brands' subaccount status
 * @returns {bacon.Observerable} loginTrackingStream
 */
export default function getLoginTrackingStream({
    auth0ID,
    brand,
    hashedEmail,
    platformEnv,
    resourcesEnv,
    allBrandsSubAccountStatus,
}) {
    const {bingeStatus, kayoStatus, flashStatus} = allBrandsSubAccountStatus;

    if (isServer() || window.sessionStorage.getItem(LOGIN_TRACKING_SENT)) {
        return bacon.later(0, {});
    }

    initialiseAdobeDefinitionsConfig({resourcesEnv, brand});

    const getTracking = () => {
        if (window._satellite === undefined) {
            return bacon.later(0, new bacon.Error({
                errorMessage: 'window._satellite not loaded',
            }));
        }

        return bacon.combineTemplate({
            MCID: window._satellite?.getVisitorId?.()?.getMarketingCloudVisitorID?.(),
            profileID: getActiveProfileIdFromLocalStorage({platformEnv, brand}),
        })
            .doAction(({MCID, profileID}) => {
                window.sessionStorage.setItem(LOGIN_TRACKING_SENT, true);

                trackFromAdobeDefinitions({
                    definitionsPath: 'eventTracking.login.auto',
                    eventTypePayloadKey: 'data.event.name',
                    templateValues: {auth0ID, profileID, MCID, hashedEmail, bingeStatus, kayoStatus, flashStatus},
                });

                trackFromAdobeDefinitions({
                    definitionsPath: 'visitorSync',
                    eventType: 'syncvisitor',
                    templateValues: {auth0ID, profileID},
                });
            });
    };

    return bacon.retry({
        source: () => getTracking(),
        retries: SATELLITE_RETRIES,
        delay: ({retriesDone}) => retriesDone * SATELLITE_RETRY_BACKOFF_MS, // Back off a bit each time
    });
}
