import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {A, Button} from 'normalized-styled-components';
import {rgba} from 'polished';
import classnames from 'classnames';

import {mediaQuery, stylesWhen, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {buttonType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {coal, ironStone, white, geyser, satellite, midnight} from '../../../../common/palette';
import {SCREEN_TABLET, SCREEN_REALLY_LG_DESKTOP} from '../../../../common/screen-sizes';

const ANIMATE_IN_TRANSITIONS = [
    {
        type: 'color',
        speed: 100,
        style: 'ease-in',
        delay: 0,
    },
    {
        type: 'transform',
        speed: 100,
        style: 'ease-in',
        delay: 200,
    },
];

const commonStyles = css`
    box-sizing: border-box;
    transform: scaleX(${({isVisible}) => isVisible ? 1 : 0});
    transform-origin: left center;
    border: 0;
    border-radius: 3px;
    box-shadow: 0 0 14px 0 ${rgba(midnight, 0.35)};
    background: ${rgba(ironStone, 0.85)};
    padding: 2px 21px;
    min-width: 114px;
    height: 40px;
    color: ${({isVisible}) => isVisible ? white : 'transparent'};
    font: var(--mui-body-copy-2-bold);
    line-height: 1; /* stylelint-disable-line order/properties-order */

    ${stylesWhen('isVisible')`
        transition: ${ANIMATE_IN_TRANSITIONS.map(({type, speed, style, delay}) => `${type} ${speed}ms ${style} ${delay}ms`).join(', ')};
    `}

    ${stylesWhen('isHiddenOnMobile')`
        display: none;

        ${mediaQuery({minWidthPx: SCREEN_TABLET})`
            display: unset;
        `}
    `}

    ${mediaQuery({minWidthPx: SCREEN_REALLY_LG_DESKTOP})`
        font-size: 19px;
        height: 48px;
        min-width: 136px;
    `}

    &:not([disabled]) {
        &:hover,
        &:focus {
            transition: ${transition('background', 'color')};
            outline: 0;
            background: linear-gradient(${rgba(white, 0.66)}, ${rgba(white, 0.66)}), linear-gradient(to bottom, ${geyser}, ${satellite});
            color: ${coal};
        }
    }

    &:disabled {
        opacity: 0.35;
        cursor: not-allowed;
    }
`;

const StyledButton = styled(Button)`
    appearance: none;

    ${commonStyles}
`;

const StyledLink = styled(A)`
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    ${commonStyles}
`;

const ButtonContentFader = styled.span`
    ${stylesWhen('isVisible')`
        transition: opacity 100ms ease-in 400ms;
    `}
    ${stylesWhenNot('isVisible')`
        opacity: 0;
    `}
`;

const BA12BtnPost = ({
    children,
    disabled,
    isVisible = true,
    isHiddenOnMobile,
    href,
    className,
    onClick,
    type = 'button',
    ...htmlAttributes
}) => {
    // coerce disabled CTAs into being buttons
    const StyledElement = (href && !disabled) ? StyledLink : StyledButton;

    return (
        <StyledElement
            {...htmlAttributes}
            {...{
                href,
                isVisible,
                isHiddenOnMobile,
                onClick,
                disabled,
            }}
            className={classnames('BA12BtnPost', className)}
            type={href ? undefined : type}
        >
            <ButtonContentFader isVisible={isVisible}>
                {children}
            </ButtonContentFader>
        </StyledElement>
    );
};

BA12BtnPost.propTypes = {
    /** Content of button, e.g. text */
    children: propTypes.node.isRequired,
    /** href attribute, which will cause a link to be rendered instead of a button */
    href: propTypes.string,
    /** Callback on element click */
    onClick: propTypes.func,
    /** The HTML type of the button */
    type: buttonType,
    /** Is this button disabled? */
    disabled: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    /** Whether the button is visible (and thus, animated in) or not */
    isVisible: propTypes.bool,
    /** Whether the button is hidden at mobile viewports (below `768x` viewport)
     * - used for the RaceView (ie SplitView) button */
    isHiddenOnMobile: propTypes.bool,
    /** Additional CSS class(es) */
    className: classNameType,
};

BA12BtnPost.displayName = 'BA12BtnPost';

export default BA12BtnPost;
