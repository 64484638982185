import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {srcsetOptionsType} from '@fsa-streamotion/custom-prop-types';

import SrcsetImg from '../../../../../common/srcset-img';

const SponsorImage = styled(SrcsetImg)`
    display: block;
    margin: 0 0 0 auto;
`;

const SponsorSection = ({
    section,
    logoSrcsetOptions,
}) => (
    <SponsorImage
        alt={section.title}
        srcsetOptions={logoSrcsetOptions}
        sizes="93px"
    />
);

SponsorSection.propTypes = {
    /** Object containing data about this section */
    section: propTypes.shape({
        title: propTypes.string,
    }),
    /** Srcset options for sponsor logo */
    logoSrcsetOptions: srcsetOptionsType,
};

SponsorSection.displayName = 'SponsorSection';

export default SponsorSection;
