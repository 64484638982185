import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import {blanc, vader} from '../../../../common/palette';
import Ic from '..';

const IC69Stop = ({
    color = blanc,
    secondaryColor = vader,
    ...otherProps
}) => (
    <Ic {...otherProps}>
        <path fill={color} d="M49.7 7C26.117 7 7 26.117 7 49.7c0 23.583 19.117 42.7 42.7 42.7 23.583 0 42.7-19.117 42.7-42.7C92.392 26.12 73.28 7.008 49.7 7z" />
        <path fill={secondaryColor} d="M36.4 36.4H63V63H36.4z" />
    </Ic>
);

IC69Stop.propTypes = {
    /** The icon's primary color */
    color: colorType,
    /** The icon's secondary color */
    secondaryColor: colorType,
};

IC69Stop.displayName = 'IC69Stop';

export default IC69Stop;
