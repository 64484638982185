import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {mediaQuery, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {midnight, white} from '../../../../common/palette';
import {SCREEN_DESKTOP} from '../../../../common/screen-sizes';

const ListItem = styled.div`
    display: grid;
    grid-auto-flow: row;
    width: 100px;
    list-style-type: none;
    text-align: center;
    white-space: nowrap;
    color: ${({isLightTheme}) => isLightTheme ? midnight : white};

    ${stylesWhenNot('theme.isAppDock')`
        ${mediaQuery({minWidthPx: SCREEN_DESKTOP})`
            width: 186px;
        `}
    `}
`;

const StyledListTitle = styled.dt`
    order: 2;
    text-transform: uppercase;
    font: var(--mui-body-copy-6);

    ${stylesWhenNot('theme.isAppDock')`
        ${mediaQuery({minWidthPx: SCREEN_DESKTOP})`
            font: var(--mui-body-copy-3);
        `}
    `}
`;

const StyledListDefinition = styled.dd`
    order: 1;
    margin: 0;
    font: var(--mui-header-7-bold);

    ${stylesWhenNot('theme.isAppDock')`
        ${mediaQuery({minWidthPx: SCREEN_DESKTOP})`
            font: var(--mui-header-4);
        `}
    `}
`;

const XX03ScreenOption = ({
    isLightTheme,
    numberOfScreens,
    packageName,
}) => (
    <ListItem isLightTheme={isLightTheme}>
        <StyledListTitle>
            {packageName}
        </StyledListTitle>
        <StyledListDefinition>
            {numberOfScreens}
        </StyledListDefinition>
    </ListItem>
);

XX03ScreenOption.displayName = 'XX03ScreenOption';

XX03ScreenOption.propTypes = {
    /** Light or dark (default) theme */
    isLightTheme: propTypes.bool,
    /** The number of screens associated with the package */
    numberOfScreens: propTypes.number,
    /** The name of the package */
    packageName: propTypes.string,
};

export default XX03ScreenOption;
