import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {fog} from '../../../../common/palette';
import IA05InputHelp from '../../../atoms/ia/05-input-help';
import IA07InputPre from '../../../atoms/ia/07-input-pre';

const InputContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    color: ${fog};
`;

const IM05InputPre = React.forwardRef(({
    className,
    contextualHelp,
    defaultValue,
    label,
    onChange,
    ...htmlAttributes
}, ref) => (
    <InputContainer className={classnames('IA07InputPre', className)}>
        <IA07InputPre
            {...{
                defaultValue,
                label,
                onChange,
                ref,
                ...htmlAttributes,
            }}
        />
        {!!contextualHelp && (
            <IA05InputHelp>
                {contextualHelp}
            </IA05InputHelp>
        )}
    </InputContainer>
));

IM05InputPre.propTypes = {
    /** Additional CSS classnames to be applied */
    className: classNameType,
    /** Represents a validation warning, with contextual help */
    contextualHelp: propTypes.node,
    /** Passed into atom IA07InputPre, for its attribute value */
    defaultValue: propTypes.string,
    /** Passed into atom IA07InputPre, for its label text */
    label: propTypes.node,
    /** Passed into atom IA07InputPre, for what to do as input changes value */
    onChange: propTypes.func,
};

IM05InputPre.displayName = 'IM05InputPre';

export default IM05InputPre;
