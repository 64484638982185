import React from 'react';

import Ic from '..';

const IC261ArrowDTail = (args) => (
    <Ic {...args}>
        <path d="M63.876 51.192l-9.546 9.084V25h-8.66v35.276l-9.56-9.084L30 56.999 50.007 76 70 56.999z" />
    </Ic>
);

IC261ArrowDTail.displayName = 'IC261ArrowDTail';

export default IC261ArrowDTail;
