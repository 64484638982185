import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import noop from 'lodash/noop';

import {srcsetOptionsType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../../common/animations';
import {white} from '../../../../../common/palette';
import OR69CatTab from '../../69-cat-tab';
import CatTabSelector from './cat-tab-selector';
import CatTabList from './cat-tab-list';
import {backgroundEffect} from './common';

const DockTray = styled.section`
    ${backgroundEffect}
    box-sizing: border-box;
    flex: 1 0 auto;
    padding: 7px 0 0;
    width: 100%;
    overflow-x: hidden;
    color: ${white};
`;

/**
 * CAT TAB NAV SECTION
 */

const CatTabSubItems = styled.section`
    box-sizing: border-box;
    display: grid;
    grid-auto-columns: 100%;
    transition: ${transition('transform')};
`;

const SubMenu = styled.div`
    box-sizing: border-box;
    grid-row: 1;
    overflow-x: hidden;

    &[aria-hidden='true'] {
        pointer-events: none;
    }
`;

/**
 * MAIN
 */

const AppDockTray = ({
    catTabItems = [],
    onSelectCatTabItem = noop,
    selectedCatTabItemIndex = 0,
}) => (
    <DockTray>
        <OR69CatTab
            items={catTabItems}
            onSelectItem={onSelectCatTabItem}
            selectedItemIndex={selectedCatTabItemIndex}
        />
        <CatTabSubItems style={{transform: `translateX(${100 * -selectedCatTabItemIndex}%)`}}>
            {catTabItems.map(({label, tabType, listContent, selectorContent}, index) => (
                <SubMenu key={label} aria-hidden={selectedCatTabItemIndex !== index}>
                    {tabType === 'list'
                        ? <CatTabList {...listContent} />
                        // tabType: selector
                        : <CatTabSelector {...selectorContent} />
                    }
                </SubMenu>
            ))}
        </CatTabSubItems>
    </DockTray>
);

AppDockTray.displayName = 'AppDockTray';

AppDockTray.propTypes = {
    /** Dock tray tab items */
    catTabItems: propTypes.arrayOf(propTypes.shape({
        href: propTypes.string,
        label: propTypes.string,
        value: propTypes.any,
        tabType: propTypes.oneOf(['list', 'selector']),
        listContent: propTypes.shape({
            menuItems: propTypes.arrayOf(
                propTypes.shape({
                    href: propTypes.string,
                    icon: propTypes.node,
                    label: propTypes.string,
                    onClick: propTypes.func,
                })
            ),
            ctaCopy: propTypes.string,
            onClickCta: propTypes.func,
        }),
        selectorContent: propTypes.shape({
            isLoading: propTypes.bool,
            selectors: propTypes.arrayOf(
                propTypes.shape({
                    sectionHeading: propTypes.string,
                    tiles: propTypes.arrayOf(
                        propTypes.shape({
                            tileImageSrcset: srcsetOptionsType,
                            focusedTileImageSrcset: srcsetOptionsType,
                            href: propTypes.string,
                            displayName: propTypes.string,
                        })
                    ),
                })
            ),
            emptySelectorHeader: propTypes.string,
            emptySelectorDescription: propTypes.string,
            ctaCopy: propTypes.string,
            ctaUrl: propTypes.string,
            onClickCta: propTypes.func,
        }),
    })),
    /** On select dock tray tab item */
    onSelectCatTabItem: propTypes.func,
    /** Selected dock tray tab item index */
    selectedCatTabItemIndex: propTypes.number,
};

export default AppDockTray;
