/* eslint-disable import/prefer-default-export */
import {useEffect, useRef} from 'react';

/**
 * A custom hook that allows declarative usage of the setInterval API
 * Usage is similar to setInterval(), however is used as a hook
 * Thanks Dan Abramov: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 *
 * @param {Function} callback   A callback to run for the interval
 * @param {Number}   delayMs    An amount of time in milliseconds to wait before running the callback again
 * @returns {Object}            A function to stop the timer
 */
export function useInterval(callback, delayMs) {
    const savedCallback = useRef();
    const intervalId = useRef();

    function stopInterval() {
        if (intervalId.current) {
            clearInterval(intervalId.current);
        }
    }

    useEffect(function updateSavedCallback() {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(function setupInterval() {
        if (delayMs !== undefined) {
            intervalId.current = setInterval(() => void savedCallback.current(), delayMs);

            return () => clearInterval(intervalId.current);
        }
    }, [delayMs]);

    return {stopInterval};
}
