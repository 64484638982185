import React from 'react';
import {IC304Facebook, IC305Instagram, IC306Twitter, IC307TikTok, IC308YouTube} from '../../../atoms/ic';

export default {
    facebook: <IC304Facebook />,
    instagram: <IC305Instagram />,
    twitter: <IC306Twitter />,
    tiktok: <IC307TikTok />,
    youtube: <IC308YouTube />,
};
