import React from 'react';
import propTypes from 'prop-types';

import styled from 'styled-components';
import property from 'lodash/property';
import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {fadeAnimation} from '../../../../common/animations';
import {white} from '../../../../common/palette';

const SKELETON_GRADIENT = 'linear-gradient(270deg, rgba(221, 221, 229, 0.01) 0%, rgba(221, 221, 229, 0.92) 100%)';

const WhiteContainer = styled.div`
    margin-top: ${property('marginTopPx')}px;
    background-color: ${white};
    width: ${property('width')};
    height: ${property('height')};
    ${stylesWhen('isRounded')`
        border-radius: 6px;
    `}
`;

const SkeletonCard = styled.div`
    border-radius: inherit;
    background: ${SKELETON_GRADIENT};
    width: 100%;
    height: 100%;
    overflow: hidden;
    animation: ease-in-out ${fadeAnimation} 1s infinite alternate;
`;

/**
 * Renders a skeleton card component.
 *
 * @param {string} height - height eg. "200px"
 * @param {string} width - width eg. "200px"
 * @param {string} marginTopPx - top margin in px
 * @param {boolean} isRounded - if skeleton card should have rounded corners.
 * @returns {React.ReactElement} XXSkeletonCard
 */
const XXSkeletonCard = ({height = '100%', width = '100%', marginTopPx, isRounded}) => (
    <WhiteContainer width={width} height={height} marginTopPx={marginTopPx} isRounded={isRounded}>
        <SkeletonCard />
    </WhiteContainer>
);

XXSkeletonCard.displayName = 'XXSkeletonCard';

XXSkeletonCard.propTypes = {
    width: propTypes.string,
    height: propTypes.string,
    marginTopPx: propTypes.number,
    isRounded: propTypes.bool,
};

export default XXSkeletonCard;
