import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {colorType, classNameType} from '@fsa-streamotion/custom-prop-types';
import {silverLining} from '../../../../common/palette';
import VisuallyHidden from '../../../../common/visually-hidden';
import GA02StatBar from '../../../atoms/ga/02-stat-bar';

const Figure = styled.figure`
    margin: 0 0 14px;
    width: 100%;
`;

const FlexRow = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 4px;
    overflow-x: hidden;
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 7px;
`;

const Title = styled.h4`
    margin: 0;
    padding: 0 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${silverLining};
    font: var(--mui-hud-tile-copy-light);
`;

const StatValue = styled.div`
    color: ${silverLining};
    font: var(--mui-hud-tile-copy-bold);
`;

const IM02DurSldr = ({
    title,
    teamAValue,
    teamBValue,
    teamAColor = silverLining,
    teamBColor = silverLining,
    teamAName,
    teamBName,
    relativeTotal,
    className,
}) => (
    <Figure className={classnames('IM02DurSldr', className)}>
        <FlexRow>
            <Title style={{order: 1}}>{title}</Title>
            <StatValue style={{order: 0}}>{teamAValue}</StatValue>
            <StatValue style={{order: 2}}>{teamBValue}</StatValue>
        </FlexRow>
        <Grid>
            <GA02StatBar
                current={teamAValue}
                total={relativeTotal || (teamAValue + teamBValue)}
                color={teamAColor}
                isRightToLeft={true}
            />
            <GA02StatBar
                current={teamBValue}
                total={relativeTotal || (teamAValue + teamBValue)}
                color={teamBColor}
                isRightToLeft={false}
            />
        </Grid>
        <VisuallyHidden as="figcaption">
            {`${title}: ${teamAName} ${teamAValue}, ${teamBName} ${teamBValue}`}
        </VisuallyHidden>
    </Figure>
);

IM02DurSldr.displayName = 'IM02DurSldr';

IM02DurSldr.propTypes = {
    /** Title for the stat comparison e.g. Disposals */
    title: propTypes.string,
    /** Team A's value for that stat */
    teamAValue: propTypes.number,
    /** Team B's value for that stat */
    teamBValue: propTypes.number,
    /** Team A Name, for accessibility */
    teamAName: propTypes.string,
    /** Team B Name, for accessibility */
    teamBName: propTypes.string,
    /** Team A's color */
    teamAColor: colorType,
    /** Team B's color */
    teamBColor: colorType,
    /** The value the stats are relative to */
    relativeTotal: propTypes.number,
    /** Optional extra class names */
    className: classNameType,
};

export default IM02DurSldr;
