import React from 'react';
import Ic from '..';

const IC72Min = (args) => (
    <Ic {...args}>
        <path d="M83.564 40.542H58.322V16h8.4v16.142h16.842zm-42.322 0H16v-8.4h16.842V16h8.4zm25.48 42.616h-8.4V58.616h25.242v8.4H66.722zm-25.48 0h-8.4V67.016H16v-8.4h25.242z" />
    </Ic>
);

IC72Min.displayName = 'IC72Min';

export default IC72Min;
