/* eslint-disable max-len, react/jsx-max-props-per-line */

import React from 'react';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import Ic from '../../ic';

// TODO: Placeholder implementation until we get the real thing, which may not be an SVG and/or have a 1:1 aspect ratio.
const GA93Telstra = ({
    className,
    ...htmlAttributes
}) => (
    <Ic
        {...htmlAttributes}
        className={classnames('GA93Telstra', className)}
        preserveColors={true}
        ariaLabel="Telstra"
    >
        <g fill="none" fillRule="evenodd">
            <rect fill="#FAFAFA" width="99.86" height="81.9" rx="2" />
            <g fillRule="nonzero">
                <path
                    d="M61.538 19.1c1.112-.063 2.237-.038 3.35-.038h4.024c-.387 2.075-.762 4.188-1.15 6.263-.162.763-.637 1.537-1.337 1.913-.513.287-1.15.35-1.725.325h-6.963c-.675 3.825-1.312 7.687-1.975 11.524-.537 3.163-1.087 6.288-1.6 9.45-.125.613-.162 1.25-.412 1.826-.25.537-.675 1.05-1.213 1.274-.512.225-1.087.288-1.625.25h-8.337c1.275-6.35 2.587-12.737 3.862-19.087.35-1.75.7-3.475 1.05-5.238H37.212c.413-2.075.8-4.15 1.213-6.225.162-.675.512-1.275 1.05-1.687a2.88 2.88 0 011.75-.512h14.3c2.025-.038 4-.038 6.012-.038z"
                    fill="#0058A9"
                />
                <path
                    d="M35.462 30.375c.575-.037 1.113-.1 1.688-.063 3.225.125 6.387 1.113 9.263 2.525-1.313 6.35-2.588 12.738-3.863 19.088h8.337a3.638 3.638 0 001.625-.25 2.51 2.51 0 001.213-1.275c.25-.575.325-1.212.412-1.825.538-3.162 1.088-6.287 1.6-9.45C59.35 42.35 62.6 46.05 64.8 50.387c.737 1.438 1.337 2.938 1.725 4.5.287 1.213.45 2.488.325 3.738a7.442 7.442 0 01-1.175 3.325c-.325.537-.863.925-1.338 1.275-1.725 1.212-3.9 1.6-5.937 1.438-2.138-.163-4.213-.8-6.225-1.538-4.788-1.788-9.188-4.625-12.925-8.113-3.325-3.037-6.263-6.575-8.238-10.625-.9-1.912-1.662-3.925-1.85-6.037-.1-.988-.062-1.975.163-2.938.287-1.15.825-2.262 1.687-3.062 1.163-1.188 2.813-1.825 4.45-1.975z"
                    fill="#00BAF2"
                />
            </g>
        </g>
    </Ic>
);

GA93Telstra.propTypes = {
    /** Additional class(es) */
    className: classNameType,
};

GA93Telstra.displayName = 'GA93Telstra';

export default GA93Telstra;
