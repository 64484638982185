import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import {flash, fog} from '../../../../common/palette';

import {IC32Success} from '../../ic';

const StyledListItem = styled.li`
    display: flex;
    align-items: first baseline;
    list-style: none;
    color: ${fog};
    font: var(--infinity-body-copy-3-light);
`;

const IconContainer = styled.div`
    flex-shrink: 0;
    transform: translateY(20%);
    margin-right: 14px;
    width: 17px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 27px;
    `}
`;

const CA01Feature = ({
    icon = <IC32Success color={flash} />,
    children,
    ...htmlAttributes
}) => (
    <StyledListItem {...htmlAttributes}>
        {!!icon && <IconContainer>{icon}</IconContainer>}

        <div>{children}</div>
    </StyledListItem>
);

CA01Feature.propTypes = {
    /** IC icon to use */
    icon: propTypes.node,
    /** Content of item, e.g. text */
    children: propTypes.node,
};

CA01Feature.displayName = 'CA01Feature';

export default CA01Feature;
