import React from 'react';
import Ic from '..';

const IC266ClosedCaptionsOff = (args) => (
    <Ic {...args}>
        <g fillRule="evenodd">
            <path d="M67.305 22 46.463 43.697l1.394-3.738c-.774-.757-3.632-3.61-10.896-3.61-7.74 0-15.242 5.59-15.242 14.85 0 5.389 2.492 9.44 6.135 11.87L12.55 79H7V22h60.305zM93 22v57H34.139l18.49-19.536c2.785 4.032 7.605 6.235 12.672 6.235 7.68 0 10.658-3.26 11.253-3.785l-2.143-5.474s-2.739 2.97-7.68 2.97c-4.11 0-7.313-2.335-8.276-6.102l9.99-10.555c3.164.527 5.047 2.122 5.787 2.797l2.084-5.59c-.4-.391-1.352-1.339-3.178-2.165L88.085 22H93zM37.854 42.58c3.71 0 6.106 1.488 7.322 2.459L32.948 57.768c-2.013-1.483-3.25-3.893-3.25-6.919 0-5.183 3.631-8.269 8.156-8.269z" />
            <path fillRule="nonzero" d="M84.63 11.561 12.417 87.676a2.056 2.056 0 0 0 .079 2.909c.826.782 2.13.747 2.913-.079l72.096-75.992a2.144 2.144 0 0 0-.02-2.971 1.98 1.98 0 0 0-2.855.018z" />
        </g>
    </Ic>
);

IC266ClosedCaptionsOff.displayName = 'IC266ClosedCaptionsOff';

export default IC266ClosedCaptionsOff;
