import React from 'react';

import Ic from '..';

const IC249Watched = (args) => (
    <Ic {...args}>
        <path d="M54.807 61.692c2.123-.973 3.838-2.453 5.143-4.443 1.305-1.99 1.957-4.196 1.957-6.622 0-2.445-.667-4.682-2.003-6.712a11.94 11.94 0 00-5.22-4.382c4.978.846 9.16 2.948 12.553 6.3 1.412 1.407 2.613 3 3.601 4.78-3.372 6.1-8.715 9.794-16.031 11.08zm14.96-18.297c-5.486-4.812-12.3-7.22-20.437-7.22-8.139 0-14.96 2.408-20.468 7.22-2.283 2.001-4.222 4.407-5.812 7.219 1.59 2.817 3.529 5.217 5.812 7.203 5.507 4.823 12.33 7.233 20.468 7.233 8.137 0 14.95-2.41 20.437-7.233 2.283-1.986 4.212-4.386 5.783-7.203-1.571-2.812-3.5-5.218-5.783-7.219zM56.551 57.43c-1.897 1.871-4.182 2.81-6.855 2.81-2.672 0-4.958-.937-6.854-2.808-1.898-1.871-2.845-4.14-2.845-6.806 0-1.2.224-2.367.673-3.505l-2.453-.775a12.251 12.251 0 00-.768 4.28c0 2.418.655 4.62 1.961 6.605a12.23 12.23 0 005.173 4.462c-4.96-.87-9.14-2.978-12.54-6.319a21.195 21.195 0 01-3.608-4.76c3.427-6.104 8.843-9.798 16.246-11.08l1.01 2.322a9.515 9.515 0 014.007-.868c2.671 0 4.956.942 6.853 2.826 1.896 1.884 2.845 4.15 2.845 6.8 0 2.671-.949 4.943-2.845 6.816zm-1.348-12.468c-1.446-1.38-3.188-2.07-5.223-2.07-1.027 0-2.005.2-2.93.6l3.548 7.312-7.565-3.32a6.798 6.798 0 00-.464 2.482c0 1.934.72 3.588 2.159 4.964 1.44 1.377 3.189 2.063 5.244 2.063 2.037 0 3.78-.69 5.23-2.07 1.45-1.379 2.175-3.041 2.175-4.981s-.725-3.6-2.174-4.98z" />
    </Ic>
);

IC249Watched.displayName = 'IC249Watched';

export default IC249Watched;
