import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {SCREEN_PRETTY_LG_DESKTOP} from '../../../../common/screen-sizes';
import {silverLining} from '../../../../common/palette';

const StyledSection = styled.section`
    margin: 0 auto;
    width: 720px;
    max-width: 100%;
    text-align: center;
    color: ${silverLining};

    ${mediaQuery({minWidthPx: SCREEN_PRETTY_LG_DESKTOP})`
        width: 790px;
    `}
`;

const StyledTitle = styled.h2`
    text-align: center;
    color: ${silverLining};
    font: var(--mui-header-2-bold);
`;

const StyledP = styled.p`
    text-align: center;
    color: ${silverLining};
    font: var(--mui-body-copy-1);
`;

const OR55SkipOnboarding = ({
    className,
    ...htmlAttributes
}) => (
    <StyledSection
        {...htmlAttributes}
        className={classnames('OR55SkipOnboarding', className)}
    >
        <StyledTitle>
            Sure you want to skip?
        </StyledTitle>
        <StyledP>
            You can always do it later; but choosing your favourite teams and sports now means you'll get the
            best Kayo experience straight out of the gates.
        </StyledP>
    </StyledSection>
);

OR55SkipOnboarding.displayName = 'OR55SkipOnboarding';

OR55SkipOnboarding.propTypes = {
    /** Additional classes */
    className: classNameType,
};

export default OR55SkipOnboarding;
