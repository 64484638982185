import React from 'react';
import bacon from 'baconjs';

import {
    screenSizes,
} from '@fsa-streamotion/streamotion-web-ionic';

import {BrandedOR120PictureCar} from '../../../utils/branded-components';
import {imageUrlToSrcsetOptions} from '../../../../../todo-move-to-widgets-common/utils/srcset-helpers';
import {RESOURCES_IMWIDTHS} from '../../../../../todo-move-to-widgets-common/utils/imwidth';
import addParamsToUrl from '../../../../../todo-move-to-widgets-common/utils/add-params-to-url';
import BRANDED_ICON_LIST from './branded-icon-list';

const {SCREEN_768_TABLET, SCREEN_PHONE, SCREEN_2560_DESKTOP} = screenSizes;
const MOBILE_IMWIDTHS = RESOURCES_IMWIDTHS.filter((sizePx) => sizePx >= SCREEN_PHONE && sizePx < SCREEN_2560_DESKTOP);
const DESKTOP_IMWIDTHS = RESOURCES_IMWIDTHS.filter((sizePx) => sizePx >= SCREEN_768_TABLET);

export default function DevicesOrFeaturesCarousel({
    hydration = {},
    key,
    title,
    footerLink,
    footerText,
    footerBlock,
    itemList = [],
    isLightTheme,
    brand,
}) {
    const itemListMapped = itemList
        .map(({description, icon, iconUrl, title, posterImage, posterImageMobile} = {}) => ({
            iconUrl: iconUrl ? addParamsToUrl(iconUrl, {imwidth: 320}).toString() : undefined,
            icon: BRANDED_ICON_LIST[brand][icon],
            description,
            title,
            srcsetLandscape: imageUrlToSrcsetOptions({imageUrl: posterImage, sizes: DESKTOP_IMWIDTHS}),
            srcsetPortrait: imageUrlToSrcsetOptions({imageUrl: posterImageMobile, sizes: MOBILE_IMWIDTHS}),
            fallbackImgSrc: undefined, // @TODO what is this going to be
        }));

    const reactElement = (
        <BrandedOR120PictureCar
            brand={brand}
            title={title}
            key={key}
            itemList={itemListMapped}
            footerLink={footerLink}
            footerText={footerText}
            footerBlock={footerBlock}
            isLightTheme={isLightTheme}
        />
    );

    return bacon.combineTemplate({reactElement, hydration});
}
