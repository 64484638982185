import React from 'react';
import styled, {css} from 'styled-components';
import propTypes from 'prop-types';
import {A, Button} from 'normalized-styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';
import method from 'lodash/method';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {buttonType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {blanc, panther, vader} from '../../../../common/palette';
import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';

import IC103Loading from '../../ic/103-loading';

const commonStyles = css`
    box-sizing: border-box;
    border: 0;
    box-shadow: 0 1px 1px 0 ${rgba(panther, 0.3)};
    background-color: ${rgba(blanc, 0.25)};
    padding: 0;
    width: 250px;
    min-height: 50px;
    text-shadow: 0 1px 1px ${rgba(panther, 0.3)};
    color: ${blanc};
    font: var(--ionic-button-sec-bold);

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 400px;
        min-height: 72px;
    `}
`;

const interactiveStyles = css`
    &:not([aria-busy='true']) {
        &:hover,
        &:focus {
            transition: ${transition('background-color', 'color')};
            outline: 0;
            background-color: ${rgba(blanc, 0.8)};
            color: ${vader};
        }

        &:active,
        &[aria-pressed='true'],
        &[aria-current='true'] {
            background-color: ${blanc};
            color: ${vader};
        }
    }

    &[aria-busy='true'] {
        background-color: ${blanc};
        cursor: wait;
    }
`;

const StyledButton = styled(Button).attrs(({isPressed}) => ({
    'aria-pressed': isPressed,
}))`
    ${commonStyles}
    ${interactiveStyles}

    appearance: none;
`;

const StyledLink = styled(A).attrs(({isPressed}) => ({
    'aria-current': isPressed,
}))`
    ${commonStyles}
    ${interactiveStyles}

    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
`;

const NonInteractive = styled.div`
    ${commonStyles}

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${rgba(blanc, 0.15)};
    cursor: not-allowed;
    color: ${rgba(blanc, 0.3)};
`;

const LoadingWrapper = styled.span`
    display: inline-block;
    transform: translateY(2px);
    font-size: 30px; /* only way to adaptively increase loading spinner size */

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        transform: translateY(4px);
        font-size: 44px;
    `}
`;

const BA12BtnPost = React.forwardRef(({
    children,
    className,
    href,
    isHiddenOnMobile,
    isLoading,
    isPressed,
    onClick,
    type = 'button',
    ...htmlAttributes
}, ref) => {
    const StyledElement = getCtaType({href, onClick, type});

    return (
        <StyledElement
            {...htmlAttributes}
            aria-busy={isLoading}
            className={classnames('BA12BtnPost', className)}
            href={href}
            isHiddenOnMobile={isHiddenOnMobile}
            isPressed={isPressed}
            onClick={isLoading ? method('preventDefault') : onClick} // suppress clicks while in a pending state
            ref={ref}
            type={type}
        >
            {isLoading ? (
                <LoadingWrapper>
                    <IC103Loading color={rgba(panther, 0.33)} size="1em" />
                </LoadingWrapper>
            ) : (
                children
            )}
        </StyledElement>
    );
});

BA12BtnPost.propTypes = {
    /** Content of button, e.g. text */
    children: propTypes.node,
    /** additional CSS classnames to be applied */
    className: classNameType,
    /** If it’s actually a link, provide a href */
    href: propTypes.string,
    /** Callback on element click */
    onClick: propTypes.func,
    /** The HTML type of the button */
    type: buttonType,
    /** Whether the button is hidden at mobile viewports (e.g. the RaceView button) */
    isHiddenOnMobile: propTypes.bool,
    /** Whether the button is signifying a loading state */
    isLoading: propTypes.bool,
    /** Whether the button is currently selected */
    isPressed: propTypes.bool,
};

function getCtaType({href, onClick, type}) {
    if (href) {
        return StyledLink;
    } else if (onClick || ['submit', 'reset'].includes(type)) { // if the button has an attached action OR is a type which has form related behaviour, its a button
        return StyledButton;
    }

    return NonInteractive;
}

BA12BtnPost.displayName = 'BA12BtnPost';

export default BA12BtnPost;
