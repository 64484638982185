import {parse} from 'querystring';
import React from 'react';
import bacon from 'baconjs';
import isEqual from 'lodash/isEqual';
import matchesProperty from 'lodash/matchesProperty';
import property from 'lodash/property';
import trimStart from 'lodash/trimStart';
import noop from 'lodash/noop';

import {getUser, getAllBrandsSubAccountStatus, accountsEnvs, getResourcesUrlBasePath} from '@fsa-streamotion/streamotion-web-widgets-common';
import {isBrowser, isServer, getLocalStorageValue, setLocalStorageValue, removeLocalStorageValue} from '@fsa-streamotion/browser-utils';
import {asBool} from '@fsa-streamotion/transformers';
import {hydrateStream} from '@fsa-streamotion/bacon-widget';
import {OffersPage__SportsSeriesSelectorWrapper} from '@fsa-streamotion/streamotion-web-mui';

import StyledBaconWidget from '../../../../todo-move-to-widgets-common/utils/styled-bacon-widget';
import {imageUrlToSrcsetOptions} from '../../../../todo-move-to-widgets-common/utils/srcset-helpers';
import getHydrationLifecycle from '../../../../todo-move-to-widgets-common/utils/hydration-lifecycle-singleton';
import {
    getEmailHash,
    initialiseAdobeDefinitionsConfig,
    trackFromAdobeDefinitions,
} from '../../../../todo-move-to-widgets-common/utils/adobe';
import getAppMeasurementLoadedEventStream from '../../../../todo-move-to-widgets-common/utils/adobe/adobe-loaded-state';
import getLoginTrackingStream from '../../../../todo-move-to-widgets-common/streams/login-tracking';

import getKbotStream, {KBOT_STAGES, setKbotStage} from '../../../../todo-move-to-widgets-common/streams/kbot';
import mapErrorsForFiso from '../../../../todo-move-to-widgets-common/utils/map-errors-for-fiso';
import getBrandDisplayName from '../../../../todo-move-to-widgets-common/utils/get-brand-display-name';
import {MARKETING_STORAGE_KEYS, NEW_SIGNUP, PARTIAL_SIGNUP, RETURNING_SIGNUP, STORAGE_KEY_PREFIX, BILLING_PRODUCT_NAME_MAP} from '../../../../todo-move-to-widgets-common/utils/constants';
import {prependQueryString} from '../../../../todo-move-to-widgets-common/utils/add-params-to-url';
import getActiveProfileIdFromLocalStorage from '../../../../todo-move-to-widgets-common/utils/active-profile-id';
import getOfferStream from '../../../../todo-move-to-widgets-common/streams/endpoints/billing/offer';
import getResourcesAsset from '../../../../todo-move-to-widgets-common/streams/endpoints/resources';

import OfferComponent from '../../components/branded/offer';

import clientSideFeatureFlags from '../../utils/clientside-feature-flags';
import getInvalidOfferModalStream from '../common/invalid-offer-stream';
import getPackageSelectorPropsStream from '../common/package-selector-stream';
import getVoucherDisplayPropsStream from '../common/voucher-stream';
import getNormalisedBrand from '../../utils/get-normalised-brand';
import validateBrand from '../../utils/validate-brand';
import {DISABLE_SIGNUP_HEADING_TEXT} from '../../utils/constants';
import {MOBILE_IMWIDTHS, DESKTOP_IMWIDTHS} from '../../../../todo-move-to-widgets-common/utils/imwidths-helpers';

import {
    BrandedBA30TextBtn,
    BrandedOR17AccBlk,
    BrandedDevicesCarouselWrapper,
    BrandedFeaturesCarouselWrapper,
    BrandedMarketingBlockWrapper,
    BrandedMarketingLinkWrapper,
    BrandedQuestionsAccordionWrapper,
    BrandedStreamotionBlurbWrapper,
    BrandedTabbedContentCarouselWrapper,
} from '../../utils/branded-components';
import generateFootnoteMarker from '../../utils/generate-footnote-marker';

import getMarketingConfigStreamWithFallback from './streams/marketing';
import getResubscribePromoModalStream from './streams/resubscribe-promo-modal';

import marketingBlock from './marketing-block';
import devicesOrFeaturesCarousel from './devices-or-features-carousel';
import streamotionBlurb from './streamotion-blurb';
import tabbedContentCarousel from './tabbed-content-carousel';
import sportsSeriesSelectorWidget from './sports-series-selector';
import redirectAwayFromOffer from './redirect-url';

const {envsFromWidgetSettings} = accountsEnvs;

const VOUCHER_LOCAL_STORAGE_TTL_MS = 30 * 60 * 1000; // 30 minutes

const MARKETING_PG_LOCAL_STORAGE_TTL_MS = 30 * 60 * 1000; // 30 minutes

const OFFER_NAME_LOCAL_STORAGE_TTL_MS = 30 * 60 * 1000; // 30 minutes

const MARKETING_DEFAULT_CONFIG = {
    binge: 'ares-default',
    kayo: 'martian-default',
    flash: 'flash-default',
    lifestyle: 'lifestyle-default',
};

const SIGN_IN_WORDS = 'Sign In';
const SIGN_OUT_WORDS = 'Sign Out';

const MAX_ADTECH_WAIT_MS = 10000;

const VALID_OFFER_ERRORS = {
    accountRequired: 'existing.account.required',
};

class Offer extends StyledBaconWidget {
    static widgetName = 'accw-offer';
    component = OfferComponent;

    clientSideHydratedBus = new bacon.Bus();
    clientSideHydrated$ = this.clientSideHydratedBus.take(1);

    constructor(settings, element) {
        super(settings, element);

        const brand = validateBrand(getNormalisedBrand(settings.brand));
        const {platformEnv, ...envSettings} = envsFromWidgetSettings(settings);

        Object.assign(
            this.config,
            {
                commonWidgetSettings: {
                    ...envSettings,
                    brand,
                    clientSideFeatureFlags: clientSideFeatureFlags(platformEnv), // these flags specifically pertain to CLIENTSIDE ONLY feature changes. if you need them server side, look at https://bitbucket.foxsports.com.au/projects/MAR/repos/ares-web-server/browse/node-app/src/js/routes/index.js#64-71
                    platformEnv,
                },

                // producers referring to marketing config as pg or page config.
                marketingConfigName: this.settings.pg || MARKETING_DEFAULT_CONFIG[brand],
                offerName: this.settings.offerName || '',
                showPromo: asBool(this.settings.pm, false),

                redirectActiveAccountLink: this.settings.redirectActiveAccountLink,
                redirectActiveAccountAndActiveProfileLink: this.settings.redirectActiveAccountAndActiveProfileLink,
                redirectNonPremiumAccountAndActiveProfileLink: this.settings.redirectNonPremiumAccountAndActiveProfileLink,
                redirectCheckoutLink: this.settings.redirectCheckoutLink,
                redirectLoginSuccessLink: this.settings.redirectLoginSuccessLink,
            },
        );
    }

    rememberOfferName(key) {
        if (isBrowser()) {
            const offerNameFromParam = new URLSearchParams(window.location.search).get('offer-name');

            if (offerNameFromParam) {
                setLocalStorageValue({
                    key,
                    value: offerNameFromParam,
                    expiresIn: OFFER_NAME_LOCAL_STORAGE_TTL_MS,
                });
            }
        }
    }

    forgetOfferName(key) {
        if (isBrowser()) {
            removeLocalStorageValue(key);
        }
    }

    rememberMarketingConfigName(key) {
        if (isBrowser()) {
            const marketingConfigNameFromParam = getMarketingParameterFromUrl();

            if (marketingConfigNameFromParam) {
                setLocalStorageValue({
                    key,
                    value: marketingConfigNameFromParam,
                    expiresIn: MARKETING_PG_LOCAL_STORAGE_TTL_MS,
                });
            }
        }
    }

    forgetMarketingConfigName(key) {
        if (isBrowser()) {
            removeLocalStorageValue(key);
        }
    }

    rememberVoucher(voucher, key) {
        if (isBrowser() && voucher) {
            setLocalStorageValue({
                key,
                value: voucher,
                expiresIn: VOUCHER_LOCAL_STORAGE_TTL_MS,
            });
        }
    }

    forgetVoucher(key) {
        if (isBrowser()) {
            removeLocalStorageValue(key);
        }
    }

    /**
     * On client side, the API throws us errors that we don't actually want.
     * These errors are things like 'voucher.required' or 'existing.account.required'
     * These errors need to be shown on other pages, but not on the offer page,
     * were we present things differently according to other fields/options.
     *
     * Otherwise we show 'Voucher Required' as an error when landing on an offer
     * that requires voucher input. So it's not an error here.
     *
     * @param   {Object} offer Offer Payload obtained within offersStream.
     * @returns {Object}       Modified offer payload, with removed error details if not needed for offers page.
     */
    removeErrorObjectsNotRequiredOnOfferPage(offer) {
        const errorCodesNotWantedClientSide = ['voucher.required'];
        const offerErrorCode = offer?.error?.code;

        if (errorCodesNotWantedClientSide.includes(offerErrorCode)) {
            return {
                ...offer,
                error: {},
            };
        }

        return offer;
    }

    trackEvent({lifecycle, selectedPackageName}) {
        return getAppMeasurementLoadedEventStream().toPromise()
            .then(() => Promise.all([
                new Promise((resolve) => {
                    if (window._satellite === undefined) {
                        return resolve();
                    }

                    const listenerArgs = ['finishedAdTechCalls', resolve, {once: true}];

                    // redirect when finishedAdTechCalls event dispatched
                    document.addEventListener(...listenerArgs);

                    // redirect after 5 seconds without waiting for finishedAdTechCalls event
                    setTimeout(() => {
                        document.removeEventListener(...listenerArgs);

                        resolve();
                    }, MAX_ADTECH_WAIT_MS);
                }),
                trackFromAdobeDefinitions({
                    definitionsPath: 'eventTracking.signUp.packageselection',
                    eventTypePayloadKey: 'data.event.name',
                    templateValues: {lifecycle, package: selectedPackageName},
                }),
                trackFromAdobeDefinitions({
                    definitionsPath: 'screenTracking.signup.personalDetails',
                    eventType: 'screenload',
                }),
            ]));
    }

    getData(hydration = {}) {
        const {commonWidgetSettings} = this.config;
        const {brand, platformEnv, resourcesEnv} = commonWidgetSettings;

        const offerNameKey = [STORAGE_KEY_PREFIX[brand].offerName, platformEnv].join('-');
        const voucherKey = [STORAGE_KEY_PREFIX[brand].voucher, platformEnv].join('-');
        const marketingKey = [STORAGE_KEY_PREFIX[brand].marketingPg, platformEnv].join('-');
        const clientSideOfferName = getLocalStorageValue({key: offerNameKey});
        const isApp = isBrowser() && new URLSearchParams(window.location.href).get('is-app') === 'true';
        const isReferral = isBrowser() && !!(
            new URLSearchParams(window.location.href).get('channel')
            || getLocalStorageValue({key: MARKETING_STORAGE_KEYS[brand].channel})
        );

        function getHasConditionalOfferApproval(offer) {
            const offerErrorCode = offer?.error?.code;
            const offerVoucherCode = offer?.voucher?.code; // means successful voucher
            const hasAppliedButUnsuccessfulVoucher = !offerVoucherCode && offer?.packages?.some(({voucherApplied}) => voucherApplied);

            switch (offerErrorCode) {
                case VALID_OFFER_ERRORS.accountRequired: // appears for vouchers requiring existing subscription
                    return hasAppliedButUnsuccessfulVoucher;
                default:
                    return false;
            }
        }

        const userEnteredVoucherBus = new bacon.Bus();
        const userEntered$ = userEnteredVoucherBus.toProperty(); // initial value of this property comes from local storage or blank at clientSideVoucher$
        const isUserNavigatingBus = new bacon.Bus(); // Navigating with auth0 sdk .login() can take a little time :S  So provide feedback stuff happening.
        const isUserNavigating$ = isUserNavigatingBus.toProperty(false);

        const user$ = this.clientSideHydrated$
            .flatMapLatest(() => bacon.fromPromise(getUser({brand, platformEnv})));

        const userIsAuthenticated$ = user$.flatMapLatest((user) => bacon.fromPromise(user.isAuthenticated()));
        const userAccountStatus$ = user$.flatMapLatest((user) => bacon.fromPromise(user.getAccountStatusesByBrand(brand)).map('.account_status'));

        const userAccessToken$ = bacon.combineTemplate({
            user: user$,
            userIsAuthenticated: userIsAuthenticated$,
        })
            .flatMapLatest(({user, userIsAuthenticated}) => {
                if (userIsAuthenticated) {
                    return bacon.fromPromise(user.getAccessToken());
                } else {
                    return null;
                }
            })
            .mapError(null) // This will be something like, not logged in.
            .first();

        const userId$ = bacon.combineTemplate({
            user: user$,
            userIsAuthenticated: userIsAuthenticated$,
        })
            .flatMapLatest(({user, userIsAuthenticated}) => {
                if (userIsAuthenticated) {
                    return bacon.fromPromise(user.getAccessTokenDetails());
                } else {
                    return null;
                }
            })
            .map(property('http://foxsports.com.au/martian_id'))
            .startWith(null);

        const userLogInOrOutFunction$ = user$.combine(userIsAuthenticated$, (user, userIsAuthenticated) => {
            if (userIsAuthenticated) {
                return () => {
                    isUserNavigatingBus.push(true);
                    user.logout();
                };
            } else {
                return () => {
                    isUserNavigatingBus.push(true);
                    user.login({redirectAfterProcessing: this.config.redirectLoginSuccessLink});
                };
            }
        }).startWith(null);

        const userResubscribeFunction$ = user$.flatMapLatest((user) => () => {
            // User has shown their intent to resubscribe, dont redirect them to freebies!
            sessionStorage.setItem([STORAGE_KEY_PREFIX[brand].shouldPreventFreebiesRedirection, platformEnv].join('-'), true);

            isUserNavigatingBus.push(true);
            user.login({redirectAfterProcessing: `${this.config.redirectLoginSuccessLink}#products`}); // focus on package selector
        }).startWith(null);

        const userLogInOrOutWording$ = userIsAuthenticated$
            .map((loggedIn) => loggedIn ? SIGN_OUT_WORDS : SIGN_IN_WORDS)
            .startWith(null);

        const prefetchedMarketingConfig$ = hydrateStream(
            hydration.prefetchedMarketingConfig$,
            getMarketingConfigStreamWithFallback({
                brand,
                resourcesEnv,
                marketingConfigName: this.config.marketingConfigName,
                defaultMarketingConfigName: MARKETING_DEFAULT_CONFIG[brand],
            })
        )
            .toProperty()
            .doAction(() => {
                this.rememberMarketingConfigName(marketingKey);
            });

        const prefetchedOffer$ = hydrateStream(
            // never used a SSR prefetched offer if we have a client value for it.
            // We get away with this not being exact playback SSR->Client because of our loading state.
            clientSideOfferName ? undefined : hydration.prefetchedOffer$,
            getOfferStream({
                accessToken: undefined,
                brand,
                offer: this.config.offerName || clientSideOfferName || '', // Use page's ?offer-name before clientSideOfferName if there, or continue falling back to empty string/offer name.
                platformEnv,
                voucher: undefined,
            })
        )
            .map(this.removeErrorObjectsNotRequiredOnOfferPage)
            .toProperty();

        const clientSideMarketingName$ = this.clientSideHydrated$
            .map(() => {
                const marketingFromStorage = getLocalStorageValue({key: marketingKey});

                // local storage first, widget setting (url), then default
                return marketingFromStorage || this.config.marketingConfigName || MARKETING_DEFAULT_CONFIG[brand];
            });

        const clientSideVoucher$ = this.clientSideHydrated$
            .map(() => {
                const queryParams = parse(trimStart(window.location.search, '?'));
                const voucherFromParam = queryParams.voucher;
                const voucherFromStorage = getLocalStorageValue({key: voucherKey});

                return voucherFromParam || voucherFromStorage || null;
            })
            .concat(userEntered$);

        const usePrefetchedMarketing$ = clientSideMarketingName$
            .map((clientSideMarketingName) => clientSideMarketingName === this.config.marketingConfigName);

        const usePrefetchedOffer$ = bacon.combineWith(
            usePrefetchedMarketing$,
            clientSideVoucher$,
            userAccessToken$,
            (usePrefetchedMarketing, clientSideVoucher, userAccessToken) => {
                // If we're using prefetched marketing config, no client side voucher and no access token, use what we fetched.
                if (
                    usePrefetchedMarketing
                    && !clientSideVoucher
                    && !userAccessToken
                ) {
                    return true;
                }
            }
        )
            .map(Boolean)
            .skipDuplicates();

        const shouldForwardToFreebies$ = isApp
            ? bacon.constant(false)
            : getResourcesAsset({
                brand,
                resourcesEnv,
                path: 'freemium/landing.json',
            })
                .map('.shouldForwardToFreebies')
                .doError(console.error.bind(null, 'Accounts-Widgets: Could not get shouldForwardToFreebies value from config. Falling back to default value'))
                .mapError(false);

        // Account status will drive if redirections happen.
        // We don't care about other flows for returning / partial sign ups.
        const userHasActiveSubscriptionRedirect$ = bacon.combineTemplate({
            shouldForwardLoggedOutUsersToFreebies: shouldForwardToFreebies$,
            userAccountStatus: userAccountStatus$,
            voucher: clientSideVoucher$.first(),
        })
            .map(({shouldForwardLoggedOutUsersToFreebies, userAccountStatus, voucher}) => ({
                brand,
                hasProfileId: !!getActiveProfileIdFromLocalStorage({platformEnv, brand}),
                hasTelstraSignupJwt: !!sessionStorage.getItem('signupTelstraJwt'),
                isApp,
                isReferral,
                setShouldPreventFreebiesRedirection(newValue) {
                    sessionStorage.setItem([STORAGE_KEY_PREFIX[brand].shouldPreventFreebiesRedirection, platformEnv].join('-'), newValue);
                },
                shouldForwardLoggedOutUsersToFreebies,
                shouldPreventFreebiesRedirection: !!sessionStorage.getItem([STORAGE_KEY_PREFIX[brand].shouldPreventFreebiesRedirection, platformEnv].join('-')),
                userAccountStatus,
                voucher,
                voucherKey,
                platformEnv,

                // These links are a bit confusing, but allow docs pages to override redirection logic.
                // Theres definitely a better way, but not something I'm going to solve today
                // I gave them names which match their current usage today, so don't go changing them on webserver
                browseUrl: this.config.redirectActiveAccountAndActiveProfileLink,
                freebiesUrl: this.config.redirectNonPremiumAccountAndActiveProfileLink,
                profileUrl: this.config.redirectActiveAccountLink,
            }))
            .map(redirectAwayFromOffer); // function has the side effect of navigating us away from offer page if we shouldn't be here (and return true) otherwise return false if we are to remain here.

        const offerName$ = bacon.constant(clientSideOfferName || this.config.offerName);

        // Marketing config being SSR and then updating client side okay. Shouldn't happen often.
        const marketingConfig$ = prefetchedMarketingConfig$
            .concat(
                bacon.combineTemplate({
                    prefetchedMarketingConfig: prefetchedMarketingConfig$,
                    clientSideMarketingName: clientSideMarketingName$,
                    usePrefetchedMarketing: usePrefetchedMarketing$,
                }).flatMapLatest(({prefetchedMarketingConfig, clientSideMarketingName, usePrefetchedMarketing}) => {
                    if (usePrefetchedMarketing) {
                        return prefetchedMarketingConfig; // Keep same config. Nothing changed.
                    } else {
                        return getMarketingConfigStreamWithFallback({
                            brand,
                            resourcesEnv,
                            marketingConfigName: clientSideMarketingName,
                            defaultMarketingConfigName: MARKETING_DEFAULT_CONFIG[brand],

                            onMarketingConfigNameUsed: () => this.rememberMarketingConfigName(marketingKey),
                            onDefaultMarketingConfigUsed: () => this.forgetMarketingConfigName(marketingKey),
                        });
                    }
                })
            )
            .skipDuplicates(isEqual); // @TODO: this prevents duplicate API calls in tabbed-content-carousel - see if we can achieve this elsewhere as it's an expensive operation

        const footerConfig$ = marketingConfig$
            .map(({marketingItems}) => marketingItems.find(
                ({type}) => type === 'footer'
            ))
            .combine(userId$, (footerMarketingItem, userId) => ({
                userId,
                ...footerMarketingItem,
            }));

        const isUserNotGettingRedirected$ = userHasActiveSubscriptionRedirect$
            .not()
            .toProperty();

        const offerSettings$ = bacon.combineTemplate({
            usePrefetchedOffer: usePrefetchedOffer$,
            prefetchedOffer: prefetchedOffer$,
            offer: offerName$.map((offerName) => offerName || ''), // use the offer suggested by marketing config or keep blank
            voucher: clientSideVoucher$,
            userIsAuthenticated: userIsAuthenticated$, // this will be pre-organised by this stream, but when asking again in the future (past 5 minutes) - token will NEED to be refreshed.
            isUserNotGettingRedirected: isUserNotGettingRedirected$,
        })
            .filter('.isUserNotGettingRedirected');

        const offer$ = offerSettings$.combine(user$, (offerSettings, user) => ({offerSettings, user}))
            .flatMapLatest(({user, offerSettings}) => {
                const {usePrefetchedOffer, prefetchedOffer, offer, voucher, userIsAuthenticated} = offerSettings;

                if (usePrefetchedOffer) {
                    return bacon.later(0, prefetchedOffer);
                } else {
                    // ensure we have a non-expired token for talking to offer api.
                    const accessToken$ = userIsAuthenticated ? bacon.fromPromise(user.getAccessToken()) : bacon.constant(undefined);

                    return accessToken$
                        .flatMapLatest((accessToken) => { // eslint-disable-line arrow-body-style
                            return getOfferStream({
                                accessToken,
                                brand,
                                offer,
                                platformEnv,
                                voucher,
                            });
                        });
                }
            })
            .map(this.removeErrorObjectsNotRequiredOnOfferPage)
            .startWith(null); // Don't start with anything on an offer. Totally client side.

        const onContinueWithoutVoucher$ = offer$
            .map(({offer}) => {
                if (!offer.voucherRequired) {
                    return undefined;
                }

                return () => {
                    const nextBestOfferName = offer?.nextBestOffer || '';
                    const reloadUrl = new URL(window.location.href);

                    reloadUrl.searchParams.delete('voucher');
                    reloadUrl.searchParams.set('offer-name', nextBestOfferName);

                    // Ensure we do not duplicate params on reload
                    reloadUrl.searchParams.delete('offerName');

                    this.forgetVoucher(voucherKey);
                    this.forgetOfferName(offerNameKey);

                    // wait a tick to make sure local storage updates take effect before navigating
                    setTimeout(() => {
                        window.location.replace(reloadUrl);
                    });
                };
            })
            .startWith(undefined);

        // Only remember offer-name if it's come from prefetchedOffer.  Will not include voucher or user details ever.
        // If a user enters a voucher, don't remember that voucher's offer, just remember the voucher itself.
        // So this will represent what the user lands on via url param, vs what COG is trying to move me to.
        const saveOfferNameToStorage$ = prefetchedOffer$
            .first()
            .doAction((offerPayload) => {
                const offerName = offerPayload?.offer?.code;
                const errorCode = offerPayload?.error?.code;
                const pageLoadedOfferName = this.config.offerName;

                if (offerName === pageLoadedOfferName && !errorCode) {
                    this.rememberOfferName(offerNameKey);
                }
                // @TODO Review forgetOfferName. I can't think right now how I'd validly know not to ask the api.
            });

        const warningModal$ = getInvalidOfferModalStream({
            user$,
            userIsAuthenticated$,
            prefetchedOffer$,
            offer$,
            getHasConditionalOfferApproval,

            shouldSelectNextBestOffer: false,
            shouldReloadedPageToNextBestOfferIfTelstra: true,

            forgetVoucherFunction: () => this.forgetVoucher(voucherKey),
            forgetOfferNameFunction: () => this.forgetOfferName(offerNameKey),

            brand,
            platformEnv,
        });

        const promoModal$ = getResubscribePromoModalStream({
            brand,
            offer$,
            marketingConfig$,
            userResubscribeFunction$,
            warningModal$,
            userIsAuthenticated$,
            showPromo: this.config.showPromo,
        });

        // When we apply a voucher without error, we should store it. If it's applied but in error because the user isn't logged in, still store it so we can validate later when they become logged in
        // If we detect a voucher is invalid we should remove.
        const saveVoucherToStorage$ = bacon.combineTemplate({
            offer: offer$,
            clientSideVoucher: clientSideVoucher$,
        })
            .doAction(({offer, clientSideVoucher}) => {
                const offerErrorCode = offer?.error?.code;
                const offerVoucherCode = offer?.voucher?.code; // means successful voucher
                const hasConditionalOfferApproval = getHasConditionalOfferApproval(offer);
                const voucherCode = hasConditionalOfferApproval ? clientSideVoucher : offerVoucherCode;

                if (hasConditionalOfferApproval || !offerErrorCode) {
                    return void this.rememberVoucher(voucherCode, voucherKey);
                } else {
                    return void this.forgetVoucher(voucherKey);
                }
            });

        const marketingDisplay$ = bacon.combineTemplate({
            backgroundImage: marketingConfig$.map('.backgroundImage'),
            resubscribeButtonText: marketingConfig$.map('.resubscribeButtonText'),
            title: marketingConfig$.map('.title'),
            packageSelectPrompt: marketingConfig$.map('.packageSelectPrompt'),
            posterVideo: marketingConfig$.map('.headerVideo.posterVideo'),
            posterVideoMobile: marketingConfig$.map('.headerVideo.posterVideoMobile'),
            posterImageSrcsetOptionsLandscapeArray: marketingConfig$
                .map('.headerImages.posterImages')
                .map((posterImages = []) => { // eslint-disable-line arrow-body-style
                    return posterImages
                        .map((imageUrl) => imageUrlToSrcsetOptions({imageUrl, sizes: DESKTOP_IMWIDTHS}));
                }),
            posterImageSrcsetOptionsPortraitArray: marketingConfig$
                .map('.headerImages.posterImagesMobile')
                .map((posterImages = []) => { // eslint-disable-line arrow-body-style
                    return posterImages
                        .map((imageUrl) => imageUrlToSrcsetOptions({imageUrl, sizes: MOBILE_IMWIDTHS}));
                }),
            fallbackImgSrc: `${getResourcesUrlBasePath({resourcesEnv, brand})}/marketing/images/offer-fallback-landscape.png?impolicy=crop&w=1280&h=600`,
        });

        const offerDisplay$ = bacon.combineTemplate({
            isSignupDisabled: offer$.map('.disableSignup'),
            disableSignupText: offer$.map('.disableSignupText'),
            ctaHeader: offer$.map('.offer.ctaHeader'),
            ctaSubtext: offer$.map('.offer.ctaSubtext'),
            packageHeader: offer$.map('.offer.packageHeader'),
            packageSubtext: offer$.map('.offer.packageSubtext'),
            voucherRequired: offer$.map('.offer.voucherRequired'),
        })
            .map(({isSignupDisabled, disableSignupText, ctaHeader, ctaSubtext, packageHeader, packageSubtext, voucherRequired}) => ({
                ctaHeader,
                ctaSubtext,
                packageHeader: isSignupDisabled ? DISABLE_SIGNUP_HEADING_TEXT : packageHeader,
                packageSubtext: isSignupDisabled ? disableSignupText : packageSubtext,
                voucherRequired,
            }))
            .startWith({});

        const packageSelectorProps$ = getPackageSelectorPropsStream({
            offerSettings$,
            offer$,
            prefetchedOffer$,
        });

        const footnote$ = offer$
            .map(({packages}) => {
                const subjectToChangeTexts = packages
                    .map(property('subjectToChangeText'))
                    .filter(Boolean);

                if (!subjectToChangeTexts.length) {
                    return null;
                }

                return subjectToChangeTexts.map((subjectToChangeText, index) => (
                    <div key={index}>
                        {`${generateFootnoteMarker({count: index + 1})}${subjectToChangeText}`}
                    </div>
                ));
            })
            .startWith(undefined);

        const voucherDisplay$ = getVoucherDisplayPropsStream({
            clientSideVoucher$,
            userEnteredVoucherBus,
            offer$,
            prefetchedOffer$,
            hydration,
        });

        const selectedPackagePriceId$ = packageSelectorProps$.map('.selectedPackagePriceId');
        const hasContinueButton$ = selectedPackagePriceId$.map(Boolean).and(packageSelectorProps$.map('.isProductAvailableForSelection'));

        const onContinueFunction$ = bacon.combineTemplate({
            userIsAuthenticated: userIsAuthenticated$,
            user: user$,
            selectedOffer: offer$,
            selectedPackagePriceId: selectedPackagePriceId$,
            clientSideVoucher: clientSideVoucher$,
        })
            .filter(({selectedPackagePriceId}) => selectedPackagePriceId) // Skip updates here until we have selected package price ids.
            .map(({userIsAuthenticated, user, selectedPackagePriceId, selectedOffer, clientSideVoucher}) => {
                const offerName = selectedOffer?.offer?.code;
                const offerVoucher = selectedOffer?.voucher?.code;
                const hasAppliedButUnsuccessfulVoucher = !offerVoucher // offer didn't return a voucher
                    && selectedOffer?.packages?.some(({voucherApplied}) => voucherApplied) // but the voucher did apply successfully
                    && selectedOffer?.error?.code === VALID_OFFER_ERRORS.accountRequired; // it's just that there's a condition on voucher use in the error code
                const voucher = hasAppliedButUnsuccessfulVoucher ? clientSideVoucher : offerVoucher;

                const packages = selectedOffer?.packages || [];
                const forceLoginOverCreateAuth0Flow = selectedOffer?.offer?.forceLoginOverCreate; // This flow is 'kayo only' style of offer. So we never ask to create a user account.

                const selectedPackage = packages.find((thisPackage) => {
                    const thisPackagePrices = thisPackage?.prices || [];

                    return thisPackagePrices.some((price) => price.priceId === selectedPackagePriceId);
                });

                const selectedPackageId = selectedPackage.packageId;
                const selectedPackageName = selectedPackage.planName;
                const auth0PageType = forceLoginOverCreateAuth0Flow ? 'login' : 'register';

                // Where we go after processing login.
                const checkoutLink = prependQueryString({
                    url: this.config.redirectCheckoutLink,
                    parameters: {
                        'offer-name': offerName,
                        'selected-package-id': selectedPackageId,
                        'selected-package-price-id': selectedPackagePriceId,
                        voucher,
                        brand, // The value for this is hardcoded on the respective web-server, however we need this here for proper transition from offer to checkout on docs pages
                    },
                });

                if (userIsAuthenticated) {
                    return () => {
                        isUserNavigatingBus.push(true);

                        Promise.all([
                            user.isPartialSignup(),
                            user.isStatusReturning(),
                        ])
                            .then(([isPartialSignup, isReturning]) => {
                                if (isPartialSignup) {
                                    return PARTIAL_SIGNUP;
                                } else if (isReturning) {
                                    return RETURNING_SIGNUP;
                                }

                                return null;
                            })
                            .then((lifecycle) => this.trackEvent({lifecycle, selectedPackageName}))
                            .then(() => {
                                window.location.href = checkoutLink;
                            });
                    };
                } else {
                    return () => {
                        isUserNavigatingBus.push(true);

                        const callback = () => user.login({
                            redirectAfterProcessing: checkoutLink,
                            metaData: {
                                'page': auth0PageType,
                                'platform': BILLING_PRODUCT_NAME_MAP[brand],
                                'offer-name': offerName,
                                'selected-package-id': selectedPackageId,
                                'selected-package-price-id': selectedPackagePriceId,
                                'voucher': voucher || undefined, // make sure we don't send this as empty string.
                                'telstra-jwt': window.sessionStorage.getItem('signupTelstraJwt') || undefined,
                            },
                        });

                        this.trackEvent({lifecycle: NEW_SIGNUP, selectedPackageName})
                            .then(callback);
                    };
                }
            })
            .startWith(noop);

        const getWidgetTypeConfigs = getWidgetConfigByType({
            ...this.config,
        });

        const isLightTheme$ = marketingConfig$
            .map('.isLightTheme')
            .map(Boolean);

        const wrappedMarketingWidgets$ = marketingConfig$
            .map('.marketingItems')
            .combine(isLightTheme$, (marketingItems = [], isLightTheme) => (marketingItems.map(
                ({
                    type,
                    backgroundImagePortrait,
                    backgroundImageTablet,
                    backgroundImageDesktop,
                    backgroundImageSrcsetOptions,
                    backgroundImageSrcsetOptionsMobile,
                    backgroundImageSrcsetOptionsTablet,
                    ...marketingItems
                }, index) => {
                    const {constructorPartial, Wrapper} = getWidgetTypeConfigs(type, brand);

                    if (!constructorPartial) { // probably an unknown widget or a typo in the marketing config. move on
                        return {};
                    }

                    const Component = hydration?.wrappedMarketingWidgets?.[index];

                    // Is it the old style?
                    // @TODO: deprecate the old producer-created srcsets
                    const isSrcset = backgroundImageSrcsetOptions
                        && backgroundImageSrcsetOptionsMobile
                        && backgroundImageSrcsetOptionsTablet;

                    // New style where we srcset for producers. Tablet is optional
                    const willNeedSrcsetting = backgroundImagePortrait
                        && backgroundImageDesktop;

                    const newSrcsets = {
                        ...(isSrcset && !willNeedSrcsetting) && ({
                            backgroundImageSrcsetOptions,
                            backgroundImageSrcsetOptionsMobile,
                            backgroundImageSrcsetOptionsTablet,
                        }),
                        ...willNeedSrcsetting && ({
                            backgroundImageSrcsetOptionsMobile: imageUrlToSrcsetOptions({
                                imageUrl: backgroundImagePortrait,
                                sizes: MOBILE_IMWIDTHS,
                            }),
                            backgroundImageSrcsetOptions: imageUrlToSrcsetOptions({
                                imageUrl: backgroundImageDesktop,
                                sizes: DESKTOP_IMWIDTHS,
                            }),
                            // Have tablet? throw it in there
                            ...backgroundImageTablet && ({
                                backgroundImageSrcsetOptionsTablet: imageUrlToSrcsetOptions({
                                    imageUrl: backgroundImageTablet,
                                    sizes: DESKTOP_IMWIDTHS,
                                }),
                            }),
                        }),
                    };

                    const marketingWidgetProps = {
                        isLightTheme,
                        ...marketingItems,
                        ...newSrcsets,
                        ...this.config,
                        hydration,
                    };

                    return constructorPartial(marketingWidgetProps)
                        .startWith(() => bacon.later(0, ({
                            reactElement: <Component {...marketingWidgetProps} />,
                        })))
                        .map(({reactElement, ...rest}) => ({
                            reactElement: <Wrapper key={`${type}-${index}`} brand={brand} isLightTheme={marketingWidgetProps.isLightTheme}>{reactElement}</Wrapper>,
                            ...rest, // e.g. hydration, data
                        }));
                }
            )))
            .flatMapLatest(bacon.combineAsArray);

        // The offers loading effect should only be in effect until the first offer$ resolves (client side).
        const isInitialOfferLoading$ = offer$
            .filter(Boolean) // Make sure it's not startWith(null) though.
            .map(false)
            .first()
            .startWith(true);

        const populateHydrationSingletonOnRender$ = this.clientSideHydrated$.doAction(() => {
            getHydrationLifecycle().onFirstClientRender();
        });

        const webAppHead$ = marketingConfig$.flatMapLatest((marketingConfig) => { // eslint-disable-line arrow-body-style
            // Ideally, use the `meta` sub-object of the marketing config, but we have a fallback in case it's missing.
            return marketingConfig?.meta || bacon.combineTemplate({
                // Note that we're never saying noIndex=true here - we're relying on the WebAppHead widget to provide a canonicalUrl to the basic offer page
                // More info on why that's better: https://moz.com/blog/rel-canonical
                description: marketingConfig$
                    .map('.marketingItems')
                    .map((marketingItems = []) => marketingItems.find(matchesProperty('type', 'marketingBlock')))
                    .map('.copy'),
                title: getBrandDisplayName(brand),
                ogType: 'product',
                ogImageUrl: marketingConfig$.map('.headerImages.posterImages.0'),
                ogImageWidth: null,  // We don't know the proportions, but set null so we don't use the default in web-app-head/component.js which is designed for the default image
                ogImageHeight: null,
                twitterCard: 'summary_large_image',
            });
        });

        // Track this offer screen page view in adobe analytics
        initialiseAdobeDefinitionsConfig({resourcesEnv, brand});
        const trackScreenLoadForAdobe$ = offer$
            .doAction((offer) => {
                const hasError = !!offer?.error?.code;
                const offerCode = offer?.offer?.code;

                trackFromAdobeDefinitions({
                    definitionsPath: `screenTracking.offer.${hasError ? 'offerError' : 'offer'}`,
                    eventType: 'screenload',
                    templateValues: {offerCode},
                });
            });

        const loginTracking$ = bacon.combineAsArray(
            user$,
            getAppMeasurementLoadedEventStream(),
        )
            .flatMapLatest(([user]) => user)
            .map((user) => ({
                user,
                platformEnv,
                resourcesEnv,
                accessTokenDetails: bacon.fromPromise(user.getAccessTokenDetails()),
                hashedEmail: bacon.fromPromise(
                    user.getUserDetails()
                        .then((userDetails) => getEmailHash(userDetails?.email))
                ),
            }))
            .flatMapLatest(bacon.combineTemplate)
            .flatMapLatest(({accessTokenDetails, ...userDetails}) => {
                const martianId = accessTokenDetails?.['http://foxsports.com.au/martian_id'];

                // Call window.AfxIdentity?.reportData here
                window.AfxIdentity?.reportData({martianId});

                return getLoginTrackingStream({
                    ...userDetails,
                    brand,
                    allBrandsSubAccountStatus: getAllBrandsSubAccountStatus({accessTokenDetails}),
                    auth0ID: accessTokenDetails?.sub,
                });
            });

        setKbotStage(KBOT_STAGES.OFFERS);

        return bacon.combineTemplate({
            props: {
                children: wrappedMarketingWidgets$.map((widgets = []) => widgets.map(property('reactElement'))),
                footerConfig: footerConfig$,
                isLightTheme: isLightTheme$,
                marketingDisplay: marketingDisplay$,
                offerDisplay: offerDisplay$,
                brand,

                displayResubscribeButton: marketingDisplay$
                    .map('.resubscribeButtonText')
                    .map(Boolean)
                    .and(userIsAuthenticated$.not())
                    .startWith(undefined),

                packageSelector: packageSelectorProps$.map('.props'),
                packagesSupplementaryList: marketingConfig$.map('.packagesSupplementaryList'),

                footnote: footnote$,

                voucherDisplay: voucherDisplay$.map('.props'),

                hasContinueButton: hasContinueButton$,
                onContinue: onContinueFunction$,
                isContinueLoading: isUserNavigating$,
                isSignUpDisabled: offer$.map('.disableSignup').startWith(false),

                signInWords: userLogInOrOutWording$,
                onSignIn: userLogInOrOutFunction$,
                isSignInLoading: isUserNavigating$,
                onResubscribe: userResubscribeFunction$,

                onClickOfferCta: () => trackFromAdobeDefinitions({
                    definitionsPath: 'eventTracking.signUp.startSubscription',
                    eventTypePayloadKey: 'data.event.name',
                }),

                onContinueWithoutVoucher: onContinueWithoutVoucher$,

                // This loading is the 'whole' offer loading effect.
                isLoading: isInitialOfferLoading$,

                warningModal: warningModal$,
                promoModal: promoModal$,
            },
            hydration: {
                prefetchedMarketingConfig$,
                prefetchedOffer$,
                voucherDisplay$: voucherDisplay$.map('.hydration'),
                children: wrappedMarketingWidgets$.map((widgets = []) => widgets.map(property('hydration'))), // note: don't filter because indexes are important
                inputId: voucherDisplay$.map('.hydration.inputId'),
            },

            webAppHead: webAppHead$,
            webAppJsonLd: [],

            _forcedSubscriptions: isServer()
                ? null
                : bacon.mergeAll(
                    populateHydrationSingletonOnRender$,
                    saveOfferNameToStorage$,
                    saveVoucherToStorage$,
                    userHasActiveSubscriptionRedirect$,
                    // saveMarketingToStorage managed by the stream that fetches (and falls back).
                    trackScreenLoadForAdobe$,
                    loginTracking$,
                    getKbotStream({platformEnv, brand}),
                )
                    .filter(false) // Don't let their events cause a re-render
                    .startWith(null),
        })
            .flatMapError(mapErrorsForFiso)
            .doAction(() => {
                hydration = {}; // eslint-disable-line no-param-reassign
            });
    }
}

export default function OfferWidget(settings = {}, element = null) {
    return new Offer(settings, element);
}

Offer.pageBoot();

function getWidgetConfigByType() {
    return function widgetConfigByType(type, brand) {
        switch (type) {
            // skipping this for launch
            // case 'contentCarousel':
            //     return {
            //         constructorPartial: (pageWidgetConfig) => contentCarousel({...pageWidgetConfig, key: 'contentCarousel'}),
            //         Wrapper: ContentCarouselWrapper,
            //     };

            case 'marketingBlock':
                return {
                    constructorPartial: (pageWidgetConfig) => marketingBlock({...pageWidgetConfig, key: 'marketingBlock', brand}),
                    Wrapper: BrandedMarketingBlockWrapper,
                };

            case 'marketingLink':
                return {
                    constructorPartial: (pageWidgetConfig) => bacon.combineTemplate({reactElement: <BrandedBA30TextBtn brand={brand} {...pageWidgetConfig} children={pageWidgetConfig.text} key="marketingLink" />}),
                    Wrapper: BrandedMarketingLinkWrapper,
                };

            case 'tabbedContentCarousel':
                return {
                    constructorPartial: (pageWidgetConfig) => tabbedContentCarousel({...pageWidgetConfig, key: 'tabbedContentCarousel', brand}),
                    Wrapper: BrandedTabbedContentCarouselWrapper,
                };

            case 'devicesCarousel':
                return {
                    constructorPartial: (pageWidgetConfig) => devicesOrFeaturesCarousel({...pageWidgetConfig, key: 'devicesCarousel', brand}),
                    Wrapper: BrandedDevicesCarouselWrapper,
                };

            case 'featuresCarousel':
                return {
                    constructorPartial: (pageWidgetConfig) => devicesOrFeaturesCarousel({...pageWidgetConfig, key: 'featuresCarousel', brand}),
                    Wrapper: BrandedFeaturesCarouselWrapper,
                };

            case 'streamotionBlurb':
                return {
                    constructorPartial: (pageWidgetConfig) => streamotionBlurb({...pageWidgetConfig, key: 'streamotionBlurb'}),
                    Wrapper: BrandedStreamotionBlurbWrapper,
                };

            case 'questionsAccordion':
                return {
                    constructorPartial: (pageWidgetConfig) => bacon.combineTemplate({reactElement: <BrandedOR17AccBlk brand={brand} {...pageWidgetConfig} key="questionsAccordion" />}),
                    Wrapper: BrandedQuestionsAccordionWrapper,
                };

            // Kayo specific
            case 'sportsSeriesSelector':
                return {
                    constructorPartial: (pageWidgetConfig) => sportsSeriesSelectorWidget({...pageWidgetConfig, key: 'sportsSeriesSelector'}),
                    Wrapper: OffersPage__SportsSeriesSelectorWrapper,
                };

            default:
                if (type !== 'contentCarousel' && type !== 'footer') { // @TODO: remove this check when contentCarousel is a thing after launch
                    console.warn(`Accounts Widgets: ignoring unrecognised offer widget type: ${type}`);
                }

                return {};
        }
    };
}

function getMarketingParameterFromUrl() {
    const searchParams = new URLSearchParams(window.location.search);

    return searchParams.get('pg') || searchParams.get('marketing') || searchParams.get('marketingConfigName');
}
