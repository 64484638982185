import React from 'react';

import Ic from '..';

const IC43Valid = (args) => (
    <Ic {...args}>
        <path d="M37.924 82L14 55.682l6.19-5.605 17.734 19.501L84.81 18 91 23.606z" />
    </Ic>
);

IC43Valid.displayName = 'IC43Valid';

export default IC43Valid;
