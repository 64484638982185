import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';
import noop from 'lodash/noop';

import {mediaQuery, stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {Section} from '../../../../common/normalized-styled-components';
import {ink, white} from '../../../../common/palette';
import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import TM36ProductTile from '../../../molecules/tm/36-product-tile';
import ProductDetailsAndControlsPanel from './product-details-and-controls-panel';

/* * ********************************************
 *   Container Block
 ***********************************************/

const StyledContainer = styled(Section)`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    row-gap: 7px;
    padding: 7px;
    width: 100%;
    overflow: hidden;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding: 14px;
        row-gap: 14px;
    `}

    ${stylesWhen('isCardedBlock')`
        border-radius: 6px;
        background-color: ${white};
    `}
`;

/** ********************************************
 *   Subs Card
 ***********************************************/

const SubscriptionWrapper = styled(Section)`
    display: flex;
    border-radius: 6px;
    background-color: ${rgba(ink, 0.05)};
    padding: 14px;
    gap: 14px;

    ${stylesWhen('isActive')`
        opacity: 0.5;
    `}

    ${stylesWhen('isSelected')`
        background-color: ${rgba(ink, 0.1)};
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding: 21px;
        gap: 21px;
    `}
`;

// === LEFT CONTENT: image tile

const StyledTM36ProductTile = styled(TM36ProductTile)`
    width: 92px;
    height: 92px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 125px;
        height: 125px;
    `}
`;

const StyledUnavailableProduct = styled.div`
    display: flex;
    align-items: center;
    color: ${ink};
    font: var(--quicksilver-body-copy-4-light);
`;

/**
 * OR139SubscriptionCard
 *  - Used on Subscriptions Page
 *
 * @see {@link https://zpl.io/ewMvzLD}
 */
const OR139SubscriptionCard = ({
    className,
    subscriptionItems,
    aemNode,
    isCardedBlock = true,
    ...htmlAttributes
}) => (
    <StyledContainer
        {...htmlAttributes}
        className={classnames('OR139SubscriptionCard', className)}
        isCardedBlock={isCardedBlock}
    >
        {aemNode}
        {subscriptionItems.map(({
            isSelected,
            isPreviewCancellation,
            imageAltText,
            srcsetOptions,
            srcsetSizes,
            price,
            isUnavailable = false,
            unavailableText = 'Temporarily unavailable',
            statusDescription,
            productCode,
            productTileIcon,
            buttons,
            inDunning,
            isAddButtonDisabled,
            isEditButtonDisabled,
            isButtonSectionNarrow = false,
            handleAddOnClick = noop,
            handleEditOnClick = noop,
            handleRemoveOnClick = noop,
            editHref,
            values,
            productTileId,
        }) => (
            <SubscriptionWrapper isSelected={isSelected && !inDunning} key={productCode}>
                <StyledTM36ProductTile
                    caption={imageAltText}
                    icon={productTileIcon}

                    // previous logic is `!isUnavailable && isSelected && !inDunning`
                    // keep it here in case there will be any regression, we can revert it back
                    isActive={!isEditButtonDisabled}
                    srcsetOptions={srcsetOptions}
                    srcsetSizes={srcsetSizes}
                    href={editHref}

                    // will be undefined of editHref is undefined to avoid to be clickable
                    onClick={editHref ? handleEditOnClick : undefined}
                    id={productTileId}
                />
                {isUnavailable ? (
                    <StyledUnavailableProduct>{unavailableText}</StyledUnavailableProduct>
                ) : (
                    <ProductDetailsAndControlsPanel
                        isSelected={isSelected}
                        isPreviewCancellation={isPreviewCancellation}
                        price={price}
                        statusDescription={statusDescription}
                        buttons={buttons}
                        isInDunning={inDunning}
                        isAddButtonDisabled={isAddButtonDisabled}
                        isEditButtonDisabled={isEditButtonDisabled}
                        isButtonSectionNarrow={isButtonSectionNarrow}
                        handleAddOnClick={handleAddOnClick}
                        handleEditOnClick={handleEditOnClick}
                        handleRemoveOnClick={handleRemoveOnClick}
                        editHref={editHref}
                        values={values}
                    />
                )}
            </SubscriptionWrapper>
        ))}
    </StyledContainer>
);

OR139SubscriptionCard.propTypes = {
    /** AEM module content */
    aemNode: propTypes.node,
    /** If enabled, the entire component will look carded - with transparent ink background */
    isCardedBlock: propTypes.bool,
    /** Whether or not the add button is disabled */
    isAddButtonDisabled: propTypes.bool,
    /** Whether or not the edit button is disabled */
    isEditButtonDisabled: propTypes.bool,
    /** Subscription items */
    subscriptionItems: propTypes.arrayOf(propTypes.shape({
        /** Subscriptions that user already selected */
        isSelected: propTypes.bool,
        /** Subscriptions that user is subscribed to, and is cancelling but not yet completed checkout to confirm */
        isPreviewCancellation: propTypes.bool,
        /** ID of selected subscription */
        productCode: propTypes.string,
        /** IconInfo on product tile */
        productTileIcon: propTypes.node,
        /** Tier values */
        values: propTypes.shape({
            /** Tier display name, e.g. "Basic","Standard","Premium", "All Access", etc */
            tierDisplayName: propTypes.string,
        }),
        /** Text on the bottom of tile, e.g. "Charged on the 14th" */
        statusDescription: propTypes.node,
        price: propTypes.shape({
            /** term period */
            termType: propTypes.oneOf(['Daily', 'Weekly', 'Monthly', 'Annually']),
            /** Discounted price */
            discountedAmount: propTypes.number,
            /** Regular price */
            regularAmount: propTypes.number,
        }),
        /** To show that this product is temporarily unavailable */
        isUnavailable: propTypes.bool,
        /** In shows unavailable a custom text to display on */
        unavailableText: propTypes.string,
        /** Image caption that serves as descriptive text for screen readers, e.g. alt text. */
        imageAltText: propTypes.string,
        /** Srcset for subscription logo, e.g. Binge or Kayo */
        srcsetOptions: propTypes.object,
        /** Srcset sizes */
        srcsetSizes: propTypes.string,
        /** Any buttons that should show up on right side of subscription card */
        buttons: propTypes.arrayOf(propTypes.node),
        /** Is button section narrow (responsive, aka as wide as content)
         *  or wide (always 100% width of parent element)? */
        isButtonSectionNarrow: propTypes.bool,
        /** Subscription in dunning payment error flag */
        inDunning: propTypes.bool,
        /** Add button on click handler */
        handleAddOnClick: propTypes.func,
        /** Edit button on click handler */
        handleEditOnClick: propTypes.func,
        /** Remove button on click handler */
        handleRemoveOnClick: propTypes.func,
        /** Edit button href */
        editHref: propTypes.string,
        /** ID of particular product type */
        productTileId: propTypes.string,
    })),
    /** Additional class name(s) */
    className: classNameType,
};

OR139SubscriptionCard.displayName = 'OR139SubscriptionCard';
export default OR139SubscriptionCard;
