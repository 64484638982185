import React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Main} from 'normalized-styled-components';

import {palette as infinityPalette} from '@fsa-streamotion/streamotion-web-infinity';
import {palette as ionicPalette, BRANDING_SIZES_DEFINITION as BRANDING_SIZES_DEFINITION_IONIC} from '@fsa-streamotion/streamotion-web-ionic';
import {palette as nucleusPalette, BRANDING_SIZES_DEFINITION as BRANDING_SIZES_DEFINITION_NUCLEUS} from '@fsa-streamotion/streamotion-web-nucleus';
import {palette as muiPalette} from '@fsa-streamotion/streamotion-web-mui';
import {stylesWhenBinge, stylesWhenFlash, stylesWhenKayo, stylesWhenLifestyle} from '../../../utils/styles-when-brand';
import {BrandedLogoContainer, BrandedGA92BrandLogo, BrandedOR16MarBlk} from '../../../utils/branded-components';

import BrandedLoadingIndicator from '../loading-indicator';
import brandPropType from '../../../common/brand-proptype';

const {white, flash} = infinityPalette;
const {blanc, ares} = ionicPalette;
const {coal, kayoGreen} = muiPalette;
const {lifestyle} = nucleusPalette;

const RedirectNode = styled(Main)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    ${stylesWhenFlash`
        font: var(--infinity-body-copy-2);
    `}

    ${stylesWhenBinge`
        font: var(--ionic-body-copy-2);
    `}

    ${stylesWhenKayo`
        font: var(--mui-body-copy-2);
    `}

    ${stylesWhenLifestyle`
        font: var(--nucleus-body-copy-2);
    `}
`;

const RedirectLinkWrapper = styled.p`
    margin: 28px 0;
    ${stylesWhenFlash`
        color: ${white};
    `}

    ${stylesWhenBinge`
        color: ${blanc};
    `}

    ${stylesWhenKayo`
        color: ${coal};
    `}

    ${stylesWhenLifestyle`
        color: ${blanc};
    `}
`;

const RedirectLink = styled.a`
    text-decoration: underline;
    ${stylesWhenFlash`
        color: ${flash};
    `}

    ${stylesWhenBinge`
        color: ${ares};
    `}

    ${stylesWhenKayo`
        color: ${kayoGreen};
    `}

    ${stylesWhenLifestyle`
        color: ${lifestyle};
    `}

    &:hover {
        text-decoration: none;
    }
`;

const StyledBrandedLogoContainer = styled(BrandedLogoContainer)`
    position: absolute;
    ${stylesWhenKayo`
        top: 0;
        left: 0;
    `}
`;

export default function Redirect({
    brand,
    brandLogoSrcsetOptions,
    header,
    message,
    redirectUrl,
    isLoading,
    className,
}) {
    const BRANDING_SIZES_DEFINITIONS = {
        binge: BRANDING_SIZES_DEFINITION_IONIC,
        lifestyle: BRANDING_SIZES_DEFINITION_NUCLEUS,
    };

    return (
        <RedirectNode className={classnames('Redirect', className)}>
            <StyledBrandedLogoContainer brand={brand}>
                <BrandedGA92BrandLogo
                    brand={brand}
                    sizesDefinition={BRANDING_SIZES_DEFINITIONS[brand] || {}}
                    srcsetOptions={brandLogoSrcsetOptions}
                />
            </StyledBrandedLogoContainer>
            {
                isLoading
                    ? <BrandedLoadingIndicator brand={brand} />
                    : <BrandedOR16MarBlk displayHeader={header} displaySubtext={message} brand={brand} />
            }
            {
                !!redirectUrl && (
                    <RedirectLinkWrapper>
                        {/** TODO Better contextual copy for the link*/}
                        <RedirectLink href={redirectUrl}>Return to previous page</RedirectLink>
                    </RedirectLinkWrapper>
                )
            }
        </RedirectNode>
    );
}

Redirect.displayName = 'Redirect';

Redirect.propTypes = {
    /** brand (binge|flash|kayo|lifestyle) */
    brand: brandPropType,
    /** Optional srcset to use for the brand logo */
    brandLogoSrcsetOptions: propTypes.objectOf(propTypes.string),
    /** Additional CSS class(es) */
    className: propTypes.string,
    /** Display header for OR16MarBlk */
    header: propTypes.node,
    /** Display sub text for OR16MarBlk */
    message: propTypes.node,
    /** Redirect link */
    redirectUrl: propTypes.string,
    /**  Is it in loading state? */
    isLoading: propTypes.bool,
};
