import React from 'react';
import Ic from '..';

const IC26ArrowU = (args) => (
    <Ic {...args}>
        <path d="M86.9 71.8L50 34.9 13.1 71.8l-3.9-3.9L48 29c1.1-1.1 2.8-1.1 3.9 0l38.9 38.9-3.9 3.9z" />
    </Ic>
);

IC26ArrowU.displayName = 'IC26ArrowU';

export default IC26ArrowU;
