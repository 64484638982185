import React from 'react';
import Ic from '..';

const IC03Not = (args) => (
    <Ic {...args}>
        <path d="M32.2 8.512c8.792-1.05 18.298 5.166 19.18 12.6l1.4 11.494.21 1.75.91 1.526L64.05 52.92l-52.234 6.258 5.824-18.984.56-1.694-.21-1.764L16.688 25.9c-.868-7.308 6.496-15.876 14.882-17.276l.63-.112m-.952-8.33l-1.106.154c-12.306 2.072-23.31 14.196-21.826 26.6L9.618 37.8 0 69.062l77.84-9.338-16.688-28.182-1.4-11.48C58.254 7.364 44.1-1.4 31.248.182zM27.888 71.4c2.961 3.861 7.739 5.876 12.57 5.302 4.833-.575 9.005-3.654 10.978-8.102l-23.548 2.8z" transform="translate(11 12)" />
    </Ic>
);

IC03Not.displayName = 'IC03Not';

export default IC03Not;
