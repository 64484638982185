import React from 'react';
import propTypes from 'prop-types';
import {rgba} from 'polished';
import styled from 'styled-components';
import {Button} from 'normalized-styled-components';
import classnames from 'classnames';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {ariaPressedType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {onyx, blanc} from '../../../../common/palette';
import {SCREEN_768_TABLET, SCREEN_1920_DESKTOP, SCREEN_2560_DESKTOP} from '../../../../common/screen-sizes';
import {transition} from '../../../../common/animations';
import IC31Tick from '../../ic/31-tick';

const CHECKMARK_SIZE_PX = 21;
const CHECKMARK_OFFSET_HOVER_PX = CHECKMARK_SIZE_PX / -2;
const BUTTON_WIDTH_DEFAULT_PX = 188;
const BUTTON_WIDTH_SCREEN_768_PX = 216;
const BUTTON_WIDTH_SCREEN_1920_PX = 267;
const BUTTON_WIDTH_SCREEN_2560_PX = 356;
const PADDING_PX = 21 * 2; // * 2 accounts for both ends

const StyledButton = styled(Button)`
    position: relative;
    transition: ${transition('background-color', 'color')};
    outline: none;
    border: none;
    background-color: ${rgba(blanc, 0.2)};
    padding: 0;
    width: ${BUTTON_WIDTH_DEFAULT_PX}px;
    height: 41px;
    color: ${blanc};
    font: var(--nucleus-body-copy-4-light);

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: ${BUTTON_WIDTH_SCREEN_768_PX}px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: ${BUTTON_WIDTH_SCREEN_1920_PX}px;
        height: 61px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
        width: ${BUTTON_WIDTH_SCREEN_2560_PX}px;
        height: 71px;
    `}

    &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: ${BUTTON_WIDTH_DEFAULT_PX - PADDING_PX}px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        content: attr(data-label);

        ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
            width: ${BUTTON_WIDTH_SCREEN_768_PX - PADDING_PX}px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            width: ${BUTTON_WIDTH_SCREEN_1920_PX - PADDING_PX}px;

        `}

        ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
            width: ${BUTTON_WIDTH_SCREEN_2560_PX - PADDING_PX}px;
        `}
    }

    &:hover {
        background-color: ${rgba(blanc, 0.8)};
        color: ${onyx};
    }

    &:focus {
        background-color: ${rgba(blanc, 0.8)};
        color: ${onyx};
        font: var(--nucleus-body-copy-4-bold);
    }

    &:active {
        background-color: ${blanc};
        color: ${onyx};
    }

    &[aria-pressed='true'] {
        background-color: ${blanc};
        color: ${onyx};
        font: var(--nucleus-body-copy-4-bold);

        &:hover {
            background-color: ${rgba(blanc, 0.8)};
        }
    }
`;

const CheckedMarkCircle = styled.span`
    position: absolute;
    top: ${CHECKMARK_OFFSET_HOVER_PX}px;
    right: ${CHECKMARK_OFFSET_HOVER_PX}px;
    transform: scale(${({isPressed}) => isPressed ? '100%' : 0});
    transition: transform 300ms cubic-bezier(0.68, -0.55, 0.27, 1.55); /* https://easings.net/#easeInOutBack */
`;

const BA35AudioSubRadBtn = ({
    'aria-pressed': isPressed,
    className,
    label,
    onClick,
    ...htmlAttributes
}) => (
    <StyledButton
        {...htmlAttributes}
        aria-pressed={isPressed}
        className={classnames('BA35AudioSubRadBtn', className)}
        onClick={onClick}
        type="button"
        data-label={label}
    >
        <CheckedMarkCircle isPressed={isPressed} role="presentation">
            <IC31Tick secondaryColor={blanc} size={`${CHECKMARK_SIZE_PX}px`} />
        </CheckedMarkCircle>
    </StyledButton>
);

BA35AudioSubRadBtn.displayName = 'BA35AudioSubRadBtn';

/* eslint-disable quote-props */
BA35AudioSubRadBtn.propTypes = {
    /** aria-pressed attribute describes a toggle button */
    'aria-pressed': ariaPressedType,
    /** additional CSS classnames to be applied */
    className: classNameType,
    /** Callback on element click */
    onClick: propTypes.func,
    /** Text content of button */
    label: propTypes.string.isRequired,
};
/* eslint-enable quote-props */

export default BA35AudioSubRadBtn;
