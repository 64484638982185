import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import {ink, hubblGreen} from '../../../../common/palette';

import Ic from '..';

const IC276SurfMode = ({
    color = hubblGreen,
    secondaryColor = ink,
    ...args
}) => (
    <Ic {...args}>
        <g fillRule="evenodd">
            <g opacity=".8" transform="translate(7 7)">
                <circle fill={color} cx="43" cy="43" r="43" />
            </g>
            <path fill={secondaryColor} fillRule="nonzero" d="M50.59 30.004c-9.998.242-19.133 4.725-32.313 21.622A86.277 86.277 0 0 1 16 54.463 34.857 34.857 0 0 0 33.648 80c2.461-2.937 4.922-5.93 7.34-9.023a74.044 74.044 0 0 1 8.485-9.378 17.902 17.902 0 0 1-.82-7.874C49.925 39.538 65 39.652 65 39.652s-4.412-9.889-14.41-9.648zm16.625 49.751a13.746 13.746 0 0 1-.284-4.718C67.84 64.975 78.59 64.877 79 64.877a13.547 13.547 0 0 0-6.183-6.068 10.496 10.496 0 0 0-4.255-.807c-7.389.181-14.182 3.424-23.882 15.615a380.716 380.716 0 0 1-6.68 8.1c9.554 3.625 20.253 2.906 29.215-1.962z" />
        </g>
    </Ic>
);

IC276SurfMode.propTypes = {
    /** The icon's primary color */
    color: colorType,
    /** The icon's secondary color */
    secondaryColor: colorType,
};
IC276SurfMode.displayName = 'IC276SurfMode';

export default IC276SurfMode;
