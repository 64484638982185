import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import classnames from 'classnames';
import {rgba} from 'polished';
import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {ariaPressedType, classNameType} from '@fsa-streamotion/custom-prop-types';
import {black, hubblGreen, ink} from '../palette';
import {SMALLEST_SUPPORTED_WIDTH, SCREEN_1920_DESKTOP} from '../screen-sizes';
import {IC31Tick} from '../../components/atoms/ic';
import getCheckedMarkStylesMap from './get-checked-mark-styles-map';

const CHECKMARK_SIZE_PX_MAP = {
    [SMALLEST_SUPPORTED_WIDTH]: 17,
    [SCREEN_1920_DESKTOP]: 26,
};
const CHECKED_MARK_STYLES_BY_WIDTH = getCheckedMarkStylesMap(CHECKMARK_SIZE_PX_MAP);

const Z_INDEX_ABOVE_BUTTON = 2;

const CheckMarkWrapper = styled.span`
    position: absolute;
    transform: scale(${({isPressed}) => isPressed ? '100%' : 0});
    transition: transform 300ms cubic-bezier(0.68, -0.55, 0.27, 1.55); /* https://easings.net/#easeInOutBack */
    stroke: ${rgba(black, 0.3)};
    z-index: ${Z_INDEX_ABOVE_BUTTON};
    ${CHECKED_MARK_STYLES_BY_WIDTH[SMALLEST_SUPPORTED_WIDTH]}
    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        ${CHECKED_MARK_STYLES_BY_WIDTH[SCREEN_1920_DESKTOP]}
    `}
`;
const StyledIC31Tick = styled(IC31Tick)`
    border-radius: 50%;
    box-shadow: -1px 1px 1px 0 ${rgba(black, 0.3)};
`;

/**
 * Check mark circle to indicate button is selected
 *
 * @param {Object} options options
 * @param {string} options.className  className
 * @param {string} options.color color
 * @param {boolean} options.isPressed isPressed
 * @param {string} options.secondaryColor secondaryColor
 * @returns {React.ReactElement} check mark (✔)
 */
const CheckMarkCircle = ({
    className,
    color = hubblGreen,
    isPressed,
    secondaryColor = ink,
    ...htmlAttributes
}) => (
    <CheckMarkWrapper
        {...htmlAttributes}
        className={classnames('CheckMarkCircle', className)}
        isPressed={isPressed}
        role="presentation"

    >
        <StyledIC31Tick color={color} secondaryColor={secondaryColor} />
    </CheckMarkWrapper>
);

CheckMarkCircle.displayName = 'CheckMarkCircle';

CheckMarkCircle.propTypes = {
    className: classNameType,
    isPressed: ariaPressedType,
    color: propTypes.string,
    secondaryColor: propTypes.string,
};

export default CheckMarkCircle;
