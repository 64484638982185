const TRANSITION_DURATION_S = 0.1;
const TRANSITION_TIMING_FUNCTION = 'ease-in';

/**
 * Create the value of a transition rule for one or more CSS properties, using standard
 * values for duration and timing function.
 *
 * @param {string[]} props - an array of CSS properties
 * @returns {string} the transition rule value
 */
export function transition(...props) {
    return props
        .map((prop) => `${prop} ${TRANSITION_DURATION_S}s ${TRANSITION_TIMING_FUNCTION}`)
        .join(', ');
}

export default transition;
