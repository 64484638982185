import React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import throttle from 'lodash/throttle';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import muid from '../../../../common/muid';
import {white, black} from '../../../../common/palette';
import {SCREEN_TABLET, SCREEN_REALLY_LG_DESKTOP} from '../../../../common/screen-sizes';
import {
    XS_HERO_FIXED_HEIGHT_PX,
    XL_HERO_FIXED_HEIGHT_PX,
} from '../../../../common/style-constants';
import Swipeable, {KeyboardNavGuide} from '../../../../common/swipeable';
import GA23CaArrow from '../../../atoms/ga/23-ca-arrow';

const ZINDEX_ACTIVE_SLIDE = 1;

const CarouselContainer = styled.ol`
    display: block;
    margin: 0;
    background-color: ${black};
    padding: 0;
    width: 100%;
    height: 100%;
    list-style: none;

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        height: ${XS_HERO_FIXED_HEIGHT_PX}px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_REALLY_LG_DESKTOP})`
        height: ${XL_HERO_FIXED_HEIGHT_PX}px;
    `}
`;

const CarouselSlot = styled.li`
    position: absolute;
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    z-index: ${({isActive, isFadingOut}) => {
        if (isActive) {
            return ZINDEX_ACTIVE_SLIDE;
        } else if (isFadingOut) {
            return ZINDEX_ACTIVE_SLIDE - 1;
        } else {
            return ZINDEX_ACTIVE_SLIDE - 2;
        }
    }};
    width: 100%;
    height: 100%;
`;

const NextButton = styled.button`
    position: absolute;
    top: 50%;
    right: 56px;
    transform: translateY(-50%);
    transition: ${transition('color', 'opacity')};
    opacity: 0;
    z-index: ${ZINDEX_ACTIVE_SLIDE};
    border: 0;
    background: 0;
    padding: 0;
    width: 57px;
    height: 98px;
    color: ${rgba(white, 0.35)};
    /* On smaller screen sizes only show the arrow on hover, as they will probably swipe instead */
    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        opacity: 1;
    `}

    &:hover,
    &:focus {
        outline: 0;
        color: ${white};
    }
`;

const OuterContainer = styled.section`
    position: relative;
    transform: scale(1);
    width: 100%;

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        height: ${XS_HERO_FIXED_HEIGHT_PX}px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_REALLY_LG_DESKTOP})`
        height: ${XL_HERO_FIXED_HEIGHT_PX}px;
    `}

    &::before {
        display: block;
        position: relative;
        padding-bottom: calc(116 / 75 * 100%);
        width: 100%;
        height: 0;
        content: '';

        ${mediaQuery({minWidthPx: SCREEN_TABLET})`
            display: none;
        `}
    }

    &:hover ${NextButton} {
        opacity: 1;
    }
`;

/**
 * Inspired by https://github.com/FoundersFactory/react-css-carousel
 */

export default class OR01Hero extends React.Component {
    static displayName = 'OR01Hero';

    static propTypes = {
        /** Additional Class(es) */
        className: classNameType,
        /** Carousel slides, e.g. collection of CAM02HeroSlide */
        children: propTypes.node,
        /** Pass in an ID for rehydration purposes */
        instructionsDomId: propTypes.string,
        /** Lazy load images on slides that are not visible or one click away from visible */
        shouldLazyLoadImages: propTypes.bool,
    };

    static defaultProps = {
        children: [],
        instructionsDomId: muid(),
        shouldLazyLoadImages: true,
    };

    state = {
        activeIndex: 0,
        isAnimating: false,
        isKbGuideEnabled: false,
        // If we're not lazy loading, fill with true, otherwise, start by loading first 2 images
        lazyImageIndexes: Array.from({length: React.Children.count(this.props.children)}, (_, index) => this.props.shouldLazyLoadImages && index > 1), // eslint-disable-line max-len
        oldActiveIndex: null,
    };

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeypress);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeypress);
        this.clearTimeouts.forEach(clearTimeout);
    }

    clearTimeouts = [];

    prevSlide = throttle(() => {
        const nextIndex = this.state.activeIndex ? this.state.activeIndex - 1 : React.Children.count(this.props.children) - 1;

        this.changeSlide(this.state.activeIndex, nextIndex);
    }, 250, {trailing: false});

    nextSlide = throttle(() => {
        const nextIndex = this.state.activeIndex === React.Children.count(this.props.children) - 1 ? 0 : this.state.activeIndex + 1;

        this.changeSlide(this.state.activeIndex, nextIndex);
    }, 250, {trailing: false});

    changeSlide = (fromIndex, toIndex) => {
        this.clearTimeouts.forEach(clearTimeout);
        this.clearTimeouts.length = 0;

        this.setState(({lazyImageIndexes}) => ({
            activeIndex: toIndex,
            isAnimating: true,
            oldActiveIndex: fromIndex,
            lazyImageIndexes: Object.assign([...lazyImageIndexes], {[toIndex]: false, [toIndex + 1]: false}),
        }), () => {
            this.clearTimeouts.push(
                setTimeout(() => {
                    this.setState({isAnimating: false});
                }, 1000)
            );
        });
    };

    handleKeypress = ({key}) => {
        if (key === 'Tab' && !this.state.isKbGuideEnabled) {
            return void this.setState({isKbGuideEnabled: true});
        } else if (
            document.activeElement !== this.containerEl // the root element is NOT in focus
            && !this.containerEl.contains(document.activeElement) // and neither is any of its descendants
        ) {
            return;
        }

        switch (key) {
            case 'ArrowLeft':
                return void this.prevSlide();

            case 'ArrowRight':
                return void this.nextSlide();

            default:
                return;
        }
    };

    handleWheel = ({deltaX}) => {
        if (deltaX > 400) {
            this.nextSlide();
        } else if (deltaX < -400) {
            this.prevSlide();
        }
    };

    render() {
        const hasMultipleSlides = React.Children.count(this.props.children) > 1;

        return (
            <OuterContainer
                className={classnames('OR01Hero', this.props.className)}
                ref={(el) => { this.containerEl = el; }}
                onWheel={this.handleWheel}
            >
                <Swipeable
                    aria-describedby={this.props.instructionsDomId}
                    aria-label="Video Carousel"
                    onSwipedLeft={this.nextSlide}
                    onSwipedRight={this.prevSlide}
                    role="region"
                    tabIndex="0"
                >
                    <CarouselContainer>
                        {React.Children.toArray(this.props.children)
                            .map((child, index) => (
                                React.cloneElement(child, { // eslint-disable-next-line max-len
                                    isVisible: this.state.activeIndex === index || (this.state.isAnimating && this.state.oldActiveIndex === index), // this is the active carousel slide OR this was the previous carousel slide and we're still animating away from it so let it remain visible
                                    isBeingLazy: this.state.lazyImageIndexes[index],
                                })
                            ))
                            .map((carouselContent, index) => (
                                <CarouselSlot
                                    isActive={this.state.activeIndex === index}
                                    isFadingOut={this.state.oldActiveIndex === index}
                                    key={carouselContent.key}
                                >
                                    {carouselContent}
                                </CarouselSlot>
                            ))}
                    </CarouselContainer>
                    {hasMultipleSlides && (
                        <NextButton
                            onClick={this.nextSlide}
                            aria-label="View next"
                            tabIndex="-1"
                        >
                            <GA23CaArrow />
                        </NextButton>
                    )}
                </Swipeable>

                {hasMultipleSlides && (
                    <KeyboardNavGuide id={this.props.instructionsDomId} isKbGuideEnabled={this.state.isKbGuideEnabled}>
                        &lt; use keys to change slides &gt;
                    </KeyboardNavGuide>
                )}
            </OuterContainer>
        );
    }
}
