import bacon from 'baconjs';
import cuid from 'cuid';
import noop from 'lodash/noop';
import invoke from 'lodash/invoke';
import get from 'lodash/get';

import {accountsEnvs} from '@fsa-streamotion/streamotion-web-widgets-common';

import StyledBaconWidget from '../../../../todo-move-to-widgets-common/utils/styled-bacon-widget';
import mapErrorsForFiso from '../../../../todo-move-to-widgets-common/utils/map-errors-for-fiso';

import interpolateString from '../../../../todo-move-to-widgets-common/utils/interpolate-string';

import LegalDisclaimerComponent from '../../components/branded/legal-disclaimer';
import getNormalisedBrand from '../../utils/get-normalised-brand';
import safelyHandleAuth0Params from '../../utils/safely-handle-auth0-params';
import getLegalDisclaimerStream from './stream';

const {envsFromWidgetSettings} = accountsEnvs;

class LegalDisclaimer extends StyledBaconWidget {
    static widgetName = 'accw-legal-disclaimer';
    component = LegalDisclaimerComponent;

    constructor(settings, element) {
        super(settings, element);

        Object.assign(
            this.config,
            {
                commonWidgetSettings: {
                    brand: getNormalisedBrand(safelyHandleAuth0Params(settings.brand)),
                    ...envsFromWidgetSettings(settings),
                },
            },
        );
    }

    initBrowser = noop; // Not expecting this widget to be initialised on browser

    getData(/* hydration = {} */) {
        const {brand, resourcesEnv} = this.config.commonWidgetSettings;

        const legalDisclaimer$ = getLegalDisclaimerStream({resourcesEnv});

        const legalDisclaimerNode = legalDisclaimer$
            .map((displayDescription = {message: ''}) => {
                if (displayDescription && displayDescription.message === '') {
                    return '';
                }

                return invoke(displayDescription, 'message.split', '\n')
                    .map((template, index) => interpolateString(template, get(displayDescription, 'replacements') || {}, undefined, index)); // This widget is to be embeded on Auth0 registration page, will never have userId
            });

        return bacon.combineTemplate({
            props: {
                brand,
                children: legalDisclaimerNode,
            },
            hydration: {}, // Not expecting this widget to ever clientside boot - hydration not needed
        })
            .flatMapError(mapErrorsForFiso);
    }

    // Specifically destroying all Javascript Booting things.
    renderServer({props} = {}) {
        const uniqueId = cuid();

        const {prependedHtml = '', html = '', appendedHtml = ''} = this.renderServerComponent(props);

        return `${prependedHtml}<div id="${uniqueId}">${html}</div>${appendedHtml}`;
    }
}

export default function legalDisclaimerWidget(settings = {}, element = null) {
    return new LegalDisclaimer(settings, element);
}
