import {rgba} from 'polished';

import {black, blueCharcoal} from '../../../../../common/palette';
import backdropFilter from '../../../../../common/backdrop-filter';

/* eslint-disable import/prefer-default-export */
export const backgroundEffect = `
    background-image: linear-gradient(to bottom, ${rgba(blueCharcoal, 0.8)}, ${rgba(black, 0.7)} 99%);

    ${backdropFilter}
`;
