import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';
import {lifestyle, midnight} from '../../../../common/palette';

import Ic from '..';

const IC31Tick = ({
    color = lifestyle,
    secondaryColor = midnight,
    ...otherProps
}) => (
    <Ic {...otherProps}>
        <g transform="translate(8 8)">
            <circle fill={color} cx="42.7" cy="42.7" r="42.7" />
            <path fill={secondaryColor} d="M32.564 65.17L17.08 49.686l5.936-5.936 9.548 9.548 29.764-29.764 5.936 5.95z" />
        </g>
    </Ic>
);

IC31Tick.propTypes = {
    /** The icon's primary color */
    color: colorType,
    /** The icon's secondary color */
    secondaryColor: colorType,
};

IC31Tick.displayName = 'IC31Tick';

export default IC31Tick;
