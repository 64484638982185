import styled from 'styled-components';
import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import property from 'lodash/property';
import propTypes from 'prop-types';
import {black, satellite, white} from '../../../../../common/palette';

const THUMBNAIL_HEIGHT = 115;
const LARGE_THUMBNAIL_HEIGHT = THUMBNAIL_HEIGHT + 10;

const VPGA03Thumbnail = styled.img`
    margin: 0 ${({thumbnailGap}) => thumbnailGap / 2}px;
    border-radius: 5px;
    box-shadow: 0 0 30px 0 ${black};
    background-color: ${satellite};
    width: ${({thumbnailWidth}) => thumbnailWidth / 2}px;
    height: ${THUMBNAIL_HEIGHT / 2}px;

    ${stylesWhen('isTabletUp')`
        width: ${property('thumbnailWidth')}px;
        height: ${THUMBNAIL_HEIGHT}px;
    `}

    ${stylesWhen('isLarge')`
           border: 1px solid ${({theme}) => theme.primaryAccent || white};
           height: ${LARGE_THUMBNAIL_HEIGHT / 2}px;
           width: ${({thumbnailWidth}) => thumbnailWidth / 2}px;

           ${stylesWhen('isTabletUp')`
               width: ${property('thumbnailWidth')}px;
               height: ${LARGE_THUMBNAIL_HEIGHT}px
           `}
    `}
`;

VPGA03Thumbnail.propTypes = {
    /** Is the screen large? */
    isLarge: propTypes.bool,
    /** Is the screen larger than tablets? */
    isTabletUp: propTypes.bool,
    /** Width of the thumbnail */
    thumbnailWidth: propTypes.number.isRequired,
};

VPGA03Thumbnail.defaultProps = {
    thumbnailGap: 0,
};

export default VPGA03Thumbnail;
