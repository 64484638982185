import React from 'react';

import styled from 'styled-components';
import propTypes from 'prop-types';
import get from 'lodash/get';
import {rgba} from 'polished';
import classnames from 'classnames';

import {srcsetOptionsType, classNameType} from '@fsa-streamotion/custom-prop-types';

import LazyImageSwitcher from '../../../../common/lazy-image-switcher';
import {black} from '../../../../common/palette';
import {transition} from '../../../../common/animations';
import stringifySrcsetOptions from '../../../../common/stringify-srcset-options';

/**
 * Takes an object of srcset key/values and returns the largest image.
 *
 * @param {Object} srcsetOptions    - list of srcSet sizes
 * @returns {string}                - the largest img size
 */
function getLargestImage(srcsetOptions = {}) {
    const [firstValue] = Object.entries(srcsetOptions)
        .sort(([sizePxA], [sizePxB]) => sizePxB - sizePxA);

    return get(firstValue, '1');
}

const DATA_1X1_PNG = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

const StyledDiv = styled.div`
    position: relative;
    transform: scale(${({isActive}) => isActive ? 1.1 : 1});
    transition: ${transition('transform')};
    /* transition delay here prevents scroll jank when fixed cursor triggers scale transformations on scroll */
    transition-delay: 100ms;
    box-shadow: 0 0 20px 0 ${rgba(black, 0.3)};
    width: 100%;
    overflow: hidden;

    &::before {
        display: block;
        position: relative;
        padding-bottom: 56.25%;
        width: 100%;
        height: 0;
        overflow: hidden;
        content: '';
    }
`;

const StyledLazyImageSwitcher = styled(LazyImageSwitcher)`
    position: absolute;
    top: 0;
    left: 0;
`;

const BadgeImg = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
`;

const StyledImg = styled.img`
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0 0 30px 0 ${rgba(black, 0.3)};
    background-color: ${rgba(black, 0.35)};
    width: 100%;
    height: 100%;
`;

const GA07TileImg = ({
    badgeSrcsetOptions,
    caption,
    className,
    defaultImageSrc,
    isActive,
    isBeingLazy,
    onClick,
    srcsetOptions,
    srcsetOptionsLazyLoadPlaceholder,
    srcsetSizes,
    ...htmlAttributes
}) => {
    const placeholderComponent = !!srcsetOptionsLazyLoadPlaceholder && (
        <StyledImg
            srcSet={stringifySrcsetOptions(srcsetOptionsLazyLoadPlaceholder)}
            sizes={srcsetSizes}
            alt={caption}
        />
    );

    return (
        <StyledDiv
            className={classnames('GA07TileImg', className)}
            isActive={isActive}
            onClick={onClick}
            {...htmlAttributes}
        >
            <StyledLazyImageSwitcher
                isBeingLazy={isBeingLazy}
                placeholderComponent={placeholderComponent || undefined}
            >
                <StyledImg
                    src={defaultImageSrc || getLargestImage(srcsetOptions)}
                    srcSet={stringifySrcsetOptions(srcsetOptions)}
                    sizes={srcsetSizes}
                    alt={caption}
                />

            </StyledLazyImageSwitcher>

            {!!badgeSrcsetOptions && (
                <BadgeImg
                    srcSet={badgeSrcsetOptions ? stringifySrcsetOptions(badgeSrcsetOptions) : {1: DATA_1X1_PNG}}
                    sizes={srcsetSizes}
                    src={DATA_1X1_PNG}
                    alt=""
                />
            )}
        </StyledDiv>
    );
};

GA07TileImg.displayName = 'GA07TileImg';

GA07TileImg.propTypes = {
    /** Srcset for badge overlay, e.g. "New Show" */
    badgeSrcsetOptions: srcsetOptionsType,
    /** Image caption that serves as descriptive text for screen readers, e.g. alt text */
    caption: propTypes.string.isRequired,
    /** Additional class(es) */
    className: classNameType,
    /** Fall back image if there's no srcset options for image */
    defaultImageSrc: propTypes.string,
    /** Whether the item is activated (e.g. passed in via carousel or wrapping TM21) */
    isActive: propTypes.bool,
    /** Whether or not to use the lazySrc instead of the proper URL */
    isBeingLazy: propTypes.bool,
    /** Callback on click. NOTE: this probably doesn't belong here but there is no molecule yet combining this and duration bar */
    onClick: propTypes.func,
    /** Srcset for landscape image */
    srcsetOptions: srcsetOptionsType,
    /** Srcset for lazy load image placeholder */
    srcsetOptionsLazyLoadPlaceholder: srcsetOptionsType,
    /** Srcset sizes */
    srcsetSizes: propTypes.string,
};

export default GA07TileImg;
