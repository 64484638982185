import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {mediaQuery, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {classNameType, srcsetOptionsType} from '@fsa-streamotion/custom-prop-types';

import {Button} from '../../../../common/normalized-styled-components';
import {transition} from '../../../../common/animations';
import {flash, white} from '../../../../common/palette';
import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import SrcsetImg from '../../../../common/srcset-img';
import IC31Tick from '../../ic/31-tick';

const StyledSrcsetImage = styled(SrcsetImg)`
    transition: ${transition('opacity')};
    opacity: 1;
    margin-bottom: -4px;
    width: 100%;
`;

const StyledButton = styled(Button).attrs(({isPressed}) => ({
    'type': 'button',
    'aria-pressed': !!isPressed,
}))`
    appearance: none;
    position: relative;
    border: none;
    background-color: transparent;
    padding: 0;
    width: 106px;

    &[aria-pressed='false'] > ${StyledSrcsetImage} {
        /* The order of rules here is important, so the 0.4 opacity is overridden by the others */
        opacity: 0.4;
    }

    /* stylelint-disable selector-type-case, selector-type-no-unknown, order/order */
    &:hover > ${StyledSrcsetImage},
    &:focus > ${StyledSrcsetImage} {
        opacity: 0.8;
    }

    &:active > ${StyledSrcsetImage} {
        opacity: 1;
    }
    /* stylelint-enable selector-type-case, selector-type-no-unknown, order/order */

    &:focus {
        outline: 0;
    }
`;

const Text = styled.span`
    display: inline-block;
    padding: 7px 10px 0;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    word-break: break-word;
    overflow-wrap: break-word;
    color: ${white};
    font: var(--infinity-body-copy-3);

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding-top: 21px;
    `}

    @supports (-webkit-line-clamp: 3) { /* Where supported, lets add nice ellipsis on overflow https://caniuse.com/#search=line-clamp */
        /* stylelint-disable value-no-vendor-prefix */
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        /* stylelint-enable value-no-vendor-prefix */
    }
`;

const Checkedmark = styled.span`
    position: absolute;
    top: 7px;
    right: 7px;
    transition: ${transition('transform')};
    width: 24px;
    height: 24px;

    ${stylesWhenNot('isVisible')`
        transform: scale(0);
    `}
`;

const BA33LogoBtn = React.forwardRef(({
    ariaLabel,
    isPressed,
    children,
    srcsetOptions,
    className,
    ...htmlAttributes
}, ref) => (
    <StyledButton
        {...htmlAttributes}
        ref={ref}
        title={ariaLabel}
        aria-label={ariaLabel}
        isPressed={isPressed}
        className={classnames('BA33LogoBtn', className)}
    >
        <StyledSrcsetImage
            alt=""
            srcsetOptions={srcsetOptions}
        />
        {!!children && (
            <Text>{children}</Text>
        )}
        <Checkedmark isVisible={isPressed} aria-hidden={true}>
            <IC31Tick
                color={flash}
            />
        </Checkedmark>
    </StyledButton>
));

BA33LogoBtn.propTypes = {
    /** Assistive label required for screen readers due to graphical only representation of content */
    ariaLabel: propTypes.string,
    /** Logo srcset options */
    srcsetOptions: srcsetOptionsType.isRequired,
    /** aria-pressed attribute describes a toggle button */
    isPressed: propTypes.bool,
    /** Additional CSS class(es) */
    className: classNameType,
    /** Subtitle for the logo */
    children: propTypes.node,
};

BA33LogoBtn.displayName = 'BA33LogoBtn';

export default BA33LogoBtn;
