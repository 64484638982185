import React, {cloneElement} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {tabIndexType} from '../../../../common/custom-proptypes';
import {SCREEN_PRETTY_LG_DESKTOP} from '../../../../common/screen-sizes';

const Z_INDEX_ABOVE_NEXT_TILE = 1;

const StyledLi = styled.li`
    box-sizing: border-box;
    display: inline-block;
    border-right: 14px solid transparent;

    ${mediaQuery({minWidthPx: SCREEN_PRETTY_LG_DESKTOP})`
        border-right: 26px solid transparent;
    `}

    @supports (scroll-snap-align: start) { /* Edge and FF currently don't properly implement. Causes more problems than its worth... */
        scroll-snap-align: start;
        scroll-margin: 0;
    }

    > * {
        &:focus,
        &:hover {
            z-index: ${Z_INDEX_ABOVE_NEXT_TILE};
        }
    }
`;

const CarouselSlot = React.forwardRef(({
    children,
    isBeingLazy = true,
    tabIndex,
    ...htmlAttributes
}, ref) => (
    <StyledLi {...htmlAttributes} ref={ref}>
        {cloneElement(
            React.Children.only(children),
            {isBeingLazy, tabIndex},
        )}
    </StyledLi>
));

CarouselSlot.displayName = 'CarouselSlot';

CarouselSlot.propTypes = {
    tabIndex: tabIndexType,
    children: propTypes.element,
    isBeingLazy: propTypes.bool,
};

export default CarouselSlot;
