import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Button} from 'normalized-styled-components';
import {black, silverLining, white} from '../../../../../common/palette';
import {transition} from '../../../../../common/animations';

const StyledButton = styled(Button)`
    display: block;
    transition: ${transition('border-color')};
    margin: 0;
    outline: none;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 0 13px 0 ${black};
    background-color: ${black};
    padding: 20px;
    width: 100%;
    max-width: 200px;
    text-align: left;
    color: ${white};
    font: var(--mui-body-copy-4-bold);

    &:hover,
    &:focus,
    &[aria-pressed='true'] {
        border: 1px solid ${white};
    }

    &::before {
        margin-right: 16px;
        border-radius: 4px;
        background-color: ${silverLining};
        padding: 8px;
        text-align: center;
        text-transform: uppercase;
        color: ${black};
        content: attr(data-short-label);
    }
`;

const VPBA03QualityButton = ({
    label,
    shortLabel,
    onClick,
    isActive,
}) => (
    <StyledButton
        onClick={onClick}
        aria-pressed={isActive}
        data-short-label={shortLabel}
    >
        {label}
    </StyledButton>
);

VPBA03QualityButton.displayName = 'VPBA03QualityButton';

VPBA03QualityButton.propTypes = {
    /** Button label */
    label: propTypes.string.isRequired,
    /** Smaller label next to the main text */
    shortLabel: propTypes.string.isRequired,
    /** OnClick handler */
    onClick: propTypes.func,
    /** Is the button active? */
    isActive: propTypes.bool,
};

export default VPBA03QualityButton;
