import React from 'react';
import propTypes from 'prop-types';
import {rgba} from 'polished';
import styled, {css} from 'styled-components';
import {Button} from 'normalized-styled-components';
import noop from 'lodash/noop';

import {coal, kayoGreen, silverLining} from '../../../../common/palette';
import {transition} from '../../../../common/animations';

const StyledButtonHover = css`
    outline: 0;
    border-color: ${kayoGreen};
`;

const StyledButton = styled(Button)`
    appearance: none;
    box-sizing: border-box;
    display: flex;
    transition: ${transition('border-color')};
    border: 2px solid transparent;
    border-radius: 4px;
    background-color: ${rgba(coal, 0.6)};
    padding: 0;
    width: 156px;
    height: 50px;
    color: ${silverLining};
    font: var(--mui-body-copy-5-bold);
    line-height: 1; /* stylelint-disable-line order/properties-order */

    &:focus,
    &:hover {
        ${StyledButtonHover}
    }

    &[aria-pressed='true'] {
        position: relative;
        border-color: ${rgba(kayoGreen, 0.35)};

        &:focus,
        &:hover {
            ${StyledButtonHover}
        }
    }
`;

const StyledImg = styled.img`
    box-sizing: border-box;
    flex-shrink: 0;
    margin: 3px 5px;
    border-radius: 3px;
    width: 40px;
    height: 40px;
`;

const StyledDiv = styled.div`
    box-sizing: border-box;
    display: inline-block;
    margin: 14px 0;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${silverLining};
    font: var(--mui-body-copy-5-bold);
`;

const NM06TrigBtn = ({
    imageUrl,
    'aria-pressed': isPressed,
    children,
    onClick = noop,
    ...htmlAttributes
}) => (
    <StyledButton
        {...htmlAttributes}
        type="button"
        onClick={onClick}
        aria-pressed={isPressed}
    >
        {!!imageUrl && (
            <StyledImg
                alt="Avatar"
                src={imageUrl}
            />
        )}
        <StyledDiv>{children}</StyledDiv>
    </StyledButton>
);

NM06TrigBtn.displayName = 'NM06TrigBtn';

/* eslint-disable quote-props */
NM06TrigBtn.propTypes = {
    /** aria-pressed attribute describes a toggle button */
    'aria-pressed': propTypes.oneOf([true, false, 'mixed']),
    /** Content of button, text */
    children: propTypes.node,
    /** Url of the image */
    imageUrl: propTypes.string,
    /** Callback on element click */
    onClick: propTypes.func,
};
/* eslint-enable quote-props */

export default NM06TrigBtn;
