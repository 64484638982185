import React from 'react';

import Ic from '..';

const IC307TikTok = (args) => (
    <Ic {...args}>
        <path d="M49.9986335,0 C77.6121163,0 99.9972671,22.3857625 99.9972671,50 C99.9972671,77.6142375 77.6121163,100 49.9986335,100 C22.3851507,100 0,77.6142375 0,50 C0,22.3857625 22.3851507,0 49.9986335,0 Z M63.0165961,17.8571429 L63.0165961,17.8764292 L52.0017767,17.8764292 L52.0017767,56.2755322 C53.6609963,76.7769116 33.7103796,73.6718109 32.1711036,63.1800419 C31.3714796,57.7027213 34.2501257,54.5976206 39.3277376,52.9389954 C41.0269384,52.3796915 42.886064,51.955392 44.625246,51.6853833 L44.625246,51.6853833 L44.625246,40.8657465 C21.7959833,41.6564864 17.4380329,61.3864123 24.5546858,72.6303485 C35.629477,90.1037726 63.1765209,82.4856683 63.1765209,58.1077345 L63.1765209,58.1077345 L63.1765209,38.9178262 C68.5939729,42.0807859 73.2517822,43.7586975 78.5692813,43.2572527 L78.5692813,43.2572527 L78.5692813,32.9969198 C69.3935968,32.4183296 64.116079,26.9795817 63.0165961,17.8571429 L63.0165961,17.8571429 Z" />
    </Ic>
);

IC307TikTok.displayName = 'IC307TikTok';

export default IC307TikTok;
