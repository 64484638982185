import React, {useState} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {stylesWhen, mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {transition} from '../../../../common/animations';
import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import {ink} from '../../../../common/palette';

import BA22EditBtn from '../../../atoms/ba/22-edit-btn';

const Container = styled.div`
    line-height: 1.2;
`;

const ExpanderContent = styled.div`
    margin-bottom: 27px;
    color: ${ink};
    font: var(--quicksilver-body-copy-4);
`;

const ButtonLabel = styled.span`
    display: flex;
    flex-direction: row;

    ${stylesWhen('shouldShowIconOnRight')`
        flex-direction: row-reverse;
    `}
`;

const IconContainer = styled.span`
    transition: ${transition('color', 'opacity')};
    margin: 1px 8px;
    width: 22px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 28px;
    `}
`;

// eslint-disable-next-line
/**
 * GM11Expander
 *  - Used in My Account and Checkout
 *
 * @see {@link https://zpl.io/AmXJ0nB}
 */
const GM11Expander = ({
    collapseIcon,
    expandIcon,
    shouldShowIconOnRight,
    expandButtonLabel,
    collapseButtonLabel,
    isInitiallyExpanded,
    children,
    className,
    ...htmlAttributes
}) => {
    const [isExpanded, setIsExpanded] = useState(isInitiallyExpanded);

    return (
        <Container
            {...htmlAttributes}
            className={classnames('GM11Expander', className)}
        >
            {!!isExpanded && <ExpanderContent>{children}</ExpanderContent>}

            <BA22EditBtn
                className="GM11Expander__expand-button"
                onClick={() => setIsExpanded((isCurrentlyExpanded) => !isCurrentlyExpanded)}
                aria-pressed={!!isExpanded}
            >
                <ButtonLabel shouldShowIconOnRight={shouldShowIconOnRight}>
                    <IconContainer>
                        {isExpanded ? collapseIcon : expandIcon}
                    </IconContainer>
                    {isExpanded ? collapseButtonLabel : expandButtonLabel}
                </ButtonLabel>
            </BA22EditBtn>
        </Container>
    );
};

GM11Expander.displayName = 'GM11Expander';

GM11Expander.propTypes = {
    /** Icon for collapsed state */
    collapseIcon: propTypes.node,
    /** Icon for expanded state */
    expandIcon: propTypes.node,
    /** Whether or not to display icon on right hand side */
    shouldShowIconOnRight: propTypes.bool,
    /** Button label for expanded state */
    expandButtonLabel: propTypes.string,
    /** Button label for collapsed state */
    collapseButtonLabel: propTypes.string,
    /** Is expander expanded upon loading? */
    isInitiallyExpanded: propTypes.bool,
    /** Expander content */
    children: propTypes.node,
    /** Passed down classnames */
    className: propTypes.string,
};

export default GM11Expander;
