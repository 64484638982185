import React from 'react';
import styled from 'styled-components';
import {rgba} from 'polished';
import propTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {mediaQuery, stylesWhen, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';

import IC103Loading from '../../../../atoms/ic/103-loading';

import IA05InputHelp from '../../../../atoms/ia/05-input-help';
import {blanc, vader} from '../../../../../common/palette';
import {transition} from '../../../../../common/animations';

import {SCREEN_1024_DESKTOP, SCREEN_568_MOBILE_LANDSCAPE} from '../../../../../common/screen-sizes';

const ChromecastControlsContainer = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transition: ${transition('opacity')};
    opacity: ${({isVisible}) => isVisible ? 1 : 0};
`;

const ScrubberAndButtonRowContainer = styled.div`
    display: flex;
    flex-direction: column;
    /* stylelint-disable-next-line color-no-hex */
    background: linear-gradient(to bottom, #164993, ${vader} 99%) ${vader};
    padding: 14px 0;

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        flex-direction: row;
        flex-wrap: wrap;
    `}
`;

const TitleAndCastIconContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 0 10px;

    ${stylesWhen('hasMarkerButtons')`
        margin-bottom: 14px;
        border-bottom: 1px solid ${rgba(blanc, 0.1)};
        padding: 0 15px 10px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        box-sizing: border-box;
        margin-bottom: 0;
        border: none;
        padding: 0 10px;
        width: ${({hasControls, hasMarkerButtons}) => (hasControls && hasMarkerButtons) ? '50%' : '100%'};
    `}
`;

const ScrubberWrapper = styled.div`
    margin-bottom: 18px;
    padding: 0 21px;

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        box-sizing: border-box;
        width: 100%;
    `}
`;

const MarkerButtonWrapper = styled.div`
    box-sizing: border-box;
    margin-bottom: 14px;
    border-bottom: 1px solid ${rgba(blanc, 0.1)};
    padding: 7px 14px 21px;
    text-align: center;
    color: ${blanc};
    font: var(--ionic-body-copy-5);

    ${stylesWhen('hasMarkerCopy')`
        padding-top: 0;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        display: flex;
        align-items: center;
        margin: 0;
        border: 0;
        padding: 0;
        width: 50%;
        text-align: right;
    `}
`;

const MarkerTitle = styled.span`
    display: block;
    color: ${blanc};
    font: var(--ionic-body-copy-5-bold);
`;

const MarkerCopy = styled.span`
    display: block;
    margin-bottom: 14px;
    color: ${blanc};
    font: var(--ionic-body-copy-5);

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        margin-bottom: 0;
    `};
`;

const MarkerButtonText = styled.div`
    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        margin: 0 14px 0 auto;
    `};
`;

const MarkerButtons = styled.div`
    display: inline-grid;
    grid-auto-columns: min-content;
    grid-auto-flow: column;
    grid-column-gap: 7px;

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        margin-right: 21px;

        ${stylesWhenNot('hasMarkerCopy')`
            margin-left: auto;
        `}
    `};
`;

const ButtonRowLayoutItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
    background-color: transparent;
    padding: 0 10px;

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        width: 100%;
        box-sizing: border-box;
    `}
`;

const ElementWrapper = styled.div`
    margin: 0 3px;
`;

const ClosedCaptionsWrapper = styled(ElementWrapper)`
    margin-left: auto;
`;

const Divider = styled.div`
    display: none;
    margin: 0 14px;
    border-right: 1px solid ${rgba(blanc, 0.1)};
    width: 0;
    height: 30px;

    ${mediaQuery({minWidthPx: SCREEN_568_MOBILE_LANDSCAPE})`
        display: block;
        margin: 0 14px;

        ${stylesWhen('hideOnMobile')`
            display: none;
        `}
    `}

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        margin: 0 24px;
    `}
`;

const StatusWrapper = styled.header`
    box-sizing: border-box;
    margin: 0;
    padding: 0 21px 0 7px;
    width: 100%;
    overflow: hidden;
    text-align: center;
    font: var(--ionic-body-copy-3-bold);
    /* stylelint-disable-next-line color-no-hex */
    mask-image: linear-gradient(to left, transparent, #000 21px);

    ${stylesWhenNot('hasBackButton')`
        margin-left: 30px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        display: inline-flex;
        align-items: center;
        margin-left: 0;
        width: unset;

        ${stylesWhenNot('isFullWidth')`
            flex-grow: 1;
        `}
    `}
`;

const StyledChromecastStatus = styled.h3`
    display: inline;
    padding: 0 5px 0 0;
    white-space: nowrap;
    color: ${blanc};
    font: var(--ionic-body-copy-3-bold);
`;

const StyledMediaHeading = styled.h3`
    display: inline;
    padding: 0;
    white-space: nowrap;
    color: ${blanc};
    font: var(--ionic-body-copy-3-light);
`;

const LoadingIconContainer = styled.div`
    display: flex;
    margin-right: 7px;
`;

const StyledIA05InputHelp = styled(IA05InputHelp)`
    margin: 14px;
`;

const VolumeWrapper = styled.div`
    display: none;

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        display: flex;
    `}
`;

const ChromecastButtonWrapper = styled(ElementWrapper)`
    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        ${stylesWhenNot('hasControls')`
            margin-left: auto;
        `}
    `}
`;

const VPOR04ChromecastControls = ({
    backButton,
    chromecastButton,
    chromecastStatus,
    closedCaptionsButton,
    displayTime,
    errorMessage,
    goToLiveButton,
    hasControls,
    isLoading,
    isVisible,
    markerButtons,
    markerCopy,
    markerTitle,
    mediaTitle,
    nextUpButton,
    playButton,
    scrubber,
    skipBackButton,
    skipForwardButton,
    volumeSlider,
}) => (
    <ChromecastControlsContainer
        isVisible={isVisible}
        className="VPOR04ChromecastControls"
    >
        {!!errorMessage && (
            <StyledIA05InputHelp hasDownArrow={true}>
                {errorMessage}
            </StyledIA05InputHelp>
        )}
        <ScrubberAndButtonRowContainer>
            <TitleAndCastIconContainer hasControls={hasControls} hasMarkerButtons={!isEmpty(markerButtons)}>
                {!!backButton && (
                    <ElementWrapper>
                        {backButton}
                    </ElementWrapper>
                )}
                {!!(chromecastStatus || mediaTitle) && (
                    <StatusWrapper hasBackButton={!!backButton} isFullWidth={hasControls && !isEmpty(markerButtons)}>
                        {!!isLoading && (
                            <LoadingIconContainer>
                                <IC103Loading color={blanc} size="22px" />
                            </LoadingIconContainer>
                        )}
                        {!!chromecastStatus && (
                            <StyledChromecastStatus>
                                {chromecastStatus}
                                {mediaTitle ? ' - ' : ' '}
                            </StyledChromecastStatus>
                        )}
                        {!!mediaTitle && (
                            <StyledMediaHeading>{mediaTitle}</StyledMediaHeading>
                        )}
                    </StatusWrapper>
                )}
                {!!chromecastButton && (
                    <ChromecastButtonWrapper hasControls={hasControls}>
                        {chromecastButton}
                    </ChromecastButtonWrapper>
                )}
            </TitleAndCastIconContainer>

            {!!hasControls && !isEmpty(markerButtons) && (
                <MarkerButtonWrapper hasMarkerCopy={markerTitle || markerCopy}>
                    {!!(markerTitle || markerCopy) && (
                        <MarkerButtonText>
                            {!!markerTitle && <MarkerTitle>{markerTitle}</MarkerTitle>}
                            {!!markerCopy && <MarkerCopy>{markerCopy}</MarkerCopy>}
                        </MarkerButtonText>
                    )}
                    <MarkerButtons hasMarkerCopy={markerTitle || markerCopy}>
                        {markerButtons}
                    </MarkerButtons>
                </MarkerButtonWrapper>
            )}

            {!!hasControls && (
                <ScrubberWrapper>
                    {scrubber}
                </ScrubberWrapper>
            )}
            {!!hasControls && (
                <ButtonRowLayoutItem>
                    {
                        [skipBackButton, playButton, skipForwardButton, goToLiveButton]
                            .filter(Boolean)
                            .map((element, index) => (
                                <ElementWrapper key={index}>
                                    {element}
                                </ElementWrapper>
                            ))
                    }
                    {!!volumeSlider && (
                        <VolumeWrapper>
                            <Divider />
                            <ElementWrapper>
                                {volumeSlider}
                            </ElementWrapper>
                        </VolumeWrapper>
                    )}

                    {!!nextUpButton && (
                        <React.Fragment>
                            <Divider />
                            <ElementWrapper>
                                {nextUpButton}
                            </ElementWrapper>
                        </React.Fragment>
                    )}
                    {!!displayTime && (
                        <React.Fragment>
                            <Divider hideOnMobile={true} />
                            <ElementWrapper>
                                {displayTime}
                            </ElementWrapper>
                        </React.Fragment>
                    )}
                    {!!closedCaptionsButton && (
                        <ClosedCaptionsWrapper>
                            {closedCaptionsButton}
                        </ClosedCaptionsWrapper>
                    )}
                </ButtonRowLayoutItem>
            )}
        </ScrubberAndButtonRowContainer>
    </ChromecastControlsContainer>
);

VPOR04ChromecastControls.propTypes = {
    /** Back Button */
    backButton: propTypes.node,
    /** Closed captions button */
    closedCaptionsButton: propTypes.node,
    /** Chromecast Button */
    chromecastButton: propTypes.node,
    /** Chromecast Status, e.g: Casting to Kayo Sports */
    chromecastStatus: propTypes.string,
    /** Disply time node */
    displayTime: propTypes.node,
    /** Error message node, could later on contain links */
    errorMessage: propTypes.node,
    /** Button to skip to live */
    goToLiveButton: propTypes.node,
    /** Are the controls currently visible? */
    hasControls: propTypes.bool,
    /** Is this component visible? */
    isVisible: propTypes.bool,
    /** Marker buttons (Skip intro, Skip recap, etc) */
    markerButtons: propTypes.node,
    /** Marker button  title */
    markerTitle: propTypes.node,
    /** Marker button copy */
    markerCopy: propTypes.node,
    /** Title of media currently in chromecast */
    mediaTitle: propTypes.string,
    /** Upnext Button */
    nextUpButton: propTypes.node,
    /** Play or pause button */
    playButton: propTypes.node,
    /** Scrubber node */
    scrubber: propTypes.node,
    /** Skip button */
    skipBackButton: propTypes.node,
    /** Skip forward button */
    skipForwardButton: propTypes.node,
    /** Volume Slider */
    volumeSlider: propTypes.node,
    /** Is Chromecast loading media */
    isLoading: propTypes.bool,
};

VPOR04ChromecastControls.displayName = 'VPOR04ChromecastControls';

export default VPOR04ChromecastControls;
