import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {srcsetOptionsType, classNameType} from '@fsa-streamotion/custom-prop-types';

import EMPTY_IMAGE_SRC from '../../../../common/empty-image-src';
import {midnight} from '../../../../common/palette';
import {SCREEN_REALLY_LG_DESKTOP, SCREEN_TABLET} from '../../../../common/screen-sizes';
import SrcsetSource from '../../../../common/srcset-source';
import {
    XS_HERO_FIXED_HEIGHT_PX,
    XL_HERO_FIXED_HEIGHT_PX,
} from '../../../../common/style-constants';
import GA21CaGrd from '../21-ca-grad';

const XS_MINIMUM_16_9_WIDTH_PX = XS_HERO_FIXED_HEIGHT_PX / 9 * 16;
const XL_MINIMUM_16_9_WIDTH_PX = XL_HERO_FIXED_HEIGHT_PX / 9 * 16;

const FixedHeightViewport = styled.span`
    display: block;
    position: relative;
    background-color: ${midnight};
    width: 100%;

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        height: 100%;
        overflow: hidden;
    `}
`;

const ControlledOverflow = styled.span`
    display: block;
    width: 100%;

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        position: absolute;
        top: 0;
        left: 50%;
        margin: 0;
        transform: translateX(-50%);
        min-width: ${XS_MINIMUM_16_9_WIDTH_PX}px;
        min-height: 100%;
    `}

    ${mediaQuery({minWidthPx: SCREEN_REALLY_LG_DESKTOP})`
        min-width: ${XL_MINIMUM_16_9_WIDTH_PX}px;
    `}

    &::after {
        display: block;
        position: relative;
        padding-bottom: calc((116 / 75) * 100%); /* because 9x16 would be too easy.... */
        width: 100%;
        height: 0;
        content: '';

        ${mediaQuery({minWidthPx: SCREEN_TABLET})`
            padding-bottom: 56.25%;
        `}
    }
`;

const StyledPicture = styled.picture`
    display: block;
    position: absolute;
    top: 0;
    margin: 0;
    width: 100%;
    height: 100%;
`;

const StyledImg = styled.img`
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
`;

const StyledGA21CaGrd = styled(GA21CaGrd)`
    position: absolute;
    right: 0;
    bottom: -1px; /* Prevent rounding issue bug on safari - see MWL-215 */
    left: 0;
    pointer-events: none;
`;

function getLargestImage(obj = {}) { // lodash/head is stupidly huge so ima roll my own
    const [firstValue] = Object.entries(obj)
        .slice()
        .sort(([sizePxA], [sizePxB]) => sizePxA - sizePxB);

    return firstValue;
}

const GA05BgImg = ({
    alt = '',
    className,
    defaultImageSrc,
    isBeingLazy,
    lazySrc = EMPTY_IMAGE_SRC,
    mobilePhabletSrcsetOptions,
    srcsetOptions,
}) => (
    <FixedHeightViewport className={classnames('GA05BgImg', className)}>
        <ControlledOverflow>
            {isBeingLazy
                ? <StyledImg src={lazySrc} alt="" />
                : (
                    <StyledPicture>
                        <SrcsetSource
                            srcsetOptions={srcsetOptions}
                            minWidthPx={mobilePhabletSrcsetOptions ? SCREEN_TABLET : undefined}
                        />

                        {!!mobilePhabletSrcsetOptions && (
                            <SrcsetSource srcsetOptions={mobilePhabletSrcsetOptions} />
                        )}

                        <StyledImg
                            src={defaultImageSrc || getLargestImage(srcsetOptions)}
                            alt={alt}
                        />
                    </StyledPicture>
                )}
        </ControlledOverflow>
        <StyledGA21CaGrd />
    </FixedHeightViewport>
);

GA05BgImg.displayName = 'GA05BgImg';

GA05BgImg.propTypes = {
    /** Image alternate text, e.g. for screen readers and image placeholders */
    alt: propTypes.string,
    /** additional CSS classnames to be applied */
    className: classNameType,
    /** Image to use for browsers that don't support <picture>. Defaults to largest srcSetOption screen size */
    defaultImageSrc: propTypes.string,
    /** Whether or not to use the lazySrc instead of the proper URL */
    isBeingLazy: propTypes.bool,
    /** The source of the image to load if we're lazily delaying using the main image */
    lazySrc: propTypes.string,
    /** SrcsetOptions object to use for mobile and phablet viewports */
    mobilePhabletSrcsetOptions: srcsetOptionsType,
    /** SrcsetOptions object to use for everything other than mobile and phablet viewports */
    srcsetOptions: srcsetOptionsType,
};

export default GA05BgImg;
