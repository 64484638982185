import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import formatCurrency from '../../../../common/format-currency';
import {slate} from '../../../../common/deprecated-mui-palette';
import GA130GiftCard from '../../../atoms/ga/130-gift-card';
import TM19Pmnt from '../19-pmnt';

const Card = styled.div`
    box-sizing: border-box;
    display: grid;
    align-items: center;
    border-radius: 2px;
    background: ${rgba(slate, 0.1)};
    padding: 4.5px 4.5px 0;
    width: 48px;
    height: 37px;
`;

const Balance = styled.strong`
    font: var(--ionic-body-copy-3-bold);
`;

const TM19PmntGiftCard = ({
    currencyPrefix = '$',
    currencyDecimalPlaces = 2,
    balance,
    className,
    ...htmlAttributes
}) => (
    <TM19Pmnt
        {...htmlAttributes}
        className={classnames('TM19PmntGiftCard', className)}
        icon={(
            <Card>
                <GA130GiftCard />
            </Card>
        )}
    >
        <div>
            Kayo Gift Card
        </div>
        <div>
            Balance remaining:
            {' '}
            <Balance>
                {formatCurrency({amount: balance, currencyPrefix, currencyDecimalPlaces})}
            </Balance>
        </div>
    </TM19Pmnt>
);

TM19PmntGiftCard.propTypes = {
    /** Currency prefix */
    currencyPrefix: propTypes.oneOf(['$']),
    /** Currency decimal places */
    currencyDecimalPlaces: propTypes.number,
    /** Gift card balance */
    balance: propTypes.number,
    /** Additional class(es) */
    className: classNameType,
};

TM19PmntGiftCard.displayName = 'TM19PmntGiftCard';

export default TM19PmntGiftCard;
