import React from 'react';
import Ic from '..';

const IC181ContextualOkRoku = (args) => (
    <Ic {...args}>
        <path d="M48.929 8C25.772 8 7 26.772 7 49.929c0 23.158 18.772 41.929 41.929 41.929 23.157 0 41.929-18.771 41.929-41.93C90.858 26.773 72.086 8 48.928 8m0 5.5c20.09 0 36.43 16.342 36.43 36.429 0 20.088-16.34 36.43-36.43 36.43-20.086 0-36.429-16.342-36.429-36.43 0-20.087 16.343-36.43 36.43-36.43" />
        <path fillRule="nonzero" d="M39.91 58.5c-5.112 0-9.128-3.324-9.128-8.879 0-5.554 4.016-8.906 9.127-8.906 5.111 0 9.128 3.338 9.128 8.906 0 5.568-4.017 8.879-9.128 8.879zm0-14.336a4.959 4.959 0 0 0-5.042 5.388 5.056 5.056 0 1 0 10.083 0 4.972 4.972 0 0 0-5.042-5.388zm27.424 14.031H62.5l-4.252-7.175-1.385 1.51v5.665h-4.059V40.992h4.059v6.289l5.318-6.289h4.793l-6.067 6.857z" />
    </Ic>
);

IC181ContextualOkRoku.displayName = 'IC181ContextualOkRoku';

export default IC181ContextualOkRoku;
