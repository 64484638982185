import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import StyledLink from '../styled-link';

const SizedStyledLink = styled(StyledLink)`
    margin: 0 auto;
    width: 50%;
`;

const LinkSection = ({
    onClickLink,
    section = {},
}) => (
    <SizedStyledLink
        onClick={onClickLink}
        href={section.link}
        target="_blank"
        rel="noopener"
    >
        {section.title}
    </SizedStyledLink>
);

LinkSection.propTypes = {
    /** Optional extra click handler */
    onClickLink: propTypes.func,
    /** Object containing data about this section */
    section: propTypes.shape({
        title: propTypes.string,
        link: propTypes.string,
    }),
};

LinkSection.displayName = 'LinkSection';

export default LinkSection;
