/* eslint-disable arrow-body-style */
import React from 'react';
import bacon from 'baconjs';
import styled from 'styled-components';

import invoke from 'lodash/invoke';
import get from 'lodash/get';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {palette as infinityPalette} from '@fsa-streamotion/streamotion-web-infinity';
import {screenSizes} from '@fsa-streamotion/streamotion-web-ionic';
import {palette as muiPalette} from '@fsa-streamotion/streamotion-web-mui';

import {BrandedBA01BtnPr, BrandedIC103Loading, BrandedTM24AlertTile} from '../../utils/branded-components';
import {stylesWhenFlash} from '../../utils/styles-when-brand';

import interpolateString from '../../../../todo-move-to-widgets-common/utils/interpolate-string';
import {Paragraph} from '../common/styled-elements';

const {SCREEN_1920_DESKTOP} = screenSizes;

const {smoke} = infinityPalette;
const {coal} = muiPalette;

const StyledBrandedBA01BtnPr = styled(BrandedBA01BtnPr)`
    width: 250px;
    height: 50px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 400px;
        min-height: 80px;
    `}

    ${stylesWhenFlash`
        height: 40px;

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            min-height: 60px;
        `}
    `}
`;

// Only overriding Paragraph styles for Flash, Binge should stay the same
const StyledParagraph = styled(Paragraph)`
    ${stylesWhenFlash`
        color: ${smoke};
        font: var(--infinity-body-copy-4);
    `}
`;

// eslint-disable-next-line no-empty-pattern
export default function getStepTermsAndStartSubscriptionStream({
    prefetchedOffer$,
    errors$,

    isPpvOffer$,
    isSubscriptionButtonDisabled$,
    isSubscriptionButtonLoading$,

    startSubscriptionBus,

    brand,
} = {}) {
    const paymentLegalTerms$ = prefetchedOffer$
        .map('.offer.legalDisclaimer')
        .map((legalDisclaimer = {message: ''}) => {
            return invoke(legalDisclaimer, 'message.split', '\n')
                .map((template, index) => interpolateString(template, get(legalDisclaimer, 'replacements') || {}, 'TODOUSERID', index)); // @TODO: User ID (whatever that is)
        });

    const offerCtaSubtext$ = bacon.combineTemplate({
        ctaSubtext: prefetchedOffer$.map('.offer.ctaSubtext'),
        isPpvOffer: isPpvOffer$,
    })
        .map(({ctaSubtext, isPpvOffer}) => isPpvOffer && ctaSubtext ? ctaSubtext : 'Start Subscription Now');

    // @TODO If you were ever to bring in the 'TICK THIS TO ACCEPT' checkbox, it'll be in this fellow,
    // and add your own error to errors$ and hold back the startSubscriptionBus push until it's there.
    // There's a lot of 'discussion' around the checkbox to confirm, as everything is still stating
    // 'by clicking start subscription you agree to', vs 'by checking I agree'.

    const reactElement$ = bacon.combineTemplate({
        paymentLegalTerms: paymentLegalTerms$,
        offerCtaSubtext: offerCtaSubtext$,
        errors: errors$,

        isSubscriptionButtonDisabled: isSubscriptionButtonDisabled$,
        isSubscriptionButtonLoading: isSubscriptionButtonLoading$,
    })
        .map(({paymentLegalTerms, offerCtaSubtext, errors, isSubscriptionButtonDisabled, isSubscriptionButtonLoading}) => (
            <React.Fragment>
                <StyledParagraph brand={brand}>
                    {paymentLegalTerms}
                </StyledParagraph>

                {!!errors && (
                    <BrandedTM24AlertTile brand={brand}>
                        {errors}
                    </BrandedTM24AlertTile>
                )}

                <Paragraph brand={brand}>
                    <StyledBrandedBA01BtnPr
                        disabled={isSubscriptionButtonDisabled || isSubscriptionButtonLoading}
                        onClick={() => void startSubscriptionBus.push()}
                        brand={brand}
                    >
                        {isSubscriptionButtonLoading
                            // Kayo only styling, color is not used on ionic or infinity IC103
                            ? <BrandedIC103Loading brand={brand} size="30px" color={coal} />
                            : offerCtaSubtext
                        }
                    </StyledBrandedBA01BtnPr>
                </Paragraph>
            </React.Fragment>
        ));

    return bacon.combineTemplate({
        reactElement: reactElement$,
    });
}
