import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Section} from 'normalized-styled-components';
import {rgba} from 'polished';
import noop from 'lodash/noop';

import {silverLining} from '../../../../../common/palette';

import StyledLink from '../styled-link';

const Subsection = styled(Section)`
    color: ${silverLining};
`;

const SubsectionHeading = styled.h3`
    display: flex;
    margin: 0;
    text-align: center;
    font: var(--mui-hud-tile-copy-light);

    &::before,
    &::after {
        flex: 1;
        margin: auto 0;
        border-bottom: 1px solid ${rgba(silverLining, 0.25)};
        content: '';
    }

    &::before {
        margin-right: 21px;
    }

    &::after {
        margin-left: 21px;
    }
`;

const SubsectionBody = styled.dl`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 7px;
    margin: 7px 0;
`;

const OddsKey = styled.dt`
    margin: auto 0;
    font: var(--mui-hud-tile-copy);
`;

const OddsValue = styled.dd`
    margin: auto 0;
    text-align: right;
    font: var(--mui-hud-tile-copy-bold);
`;

const SizedStyledLink = styled(StyledLink)`
    min-width: 60%;
    font: var(--mui-hud-tile-copy-bold);
`;

const MarketSubsection = ({
    market: {
        title,
        odds = [],
    },
    onClickOdds = noop,
}) => (
    <Subsection>
        <SubsectionHeading>{title}</SubsectionHeading>

        <SubsectionBody>
            {odds.map((currentOdds = {}, index) => (
                <React.Fragment key={index}>
                    <OddsKey>{currentOdds.title}</OddsKey>
                    {/* If odd contains a URL, show a link instead */}
                    <OddsValue>
                        {currentOdds.url
                            ? (
                                <SizedStyledLink
                                    href={currentOdds.url}
                                    target="_blank"
                                    rel="noopener"
                                    onClick={(event) => {
                                        onClickOdds(event, currentOdds);
                                    }}
                                >
                                    {currentOdds.price}
                                </SizedStyledLink>
                            )
                            : currentOdds.price
                        }
                    </OddsValue>
                </React.Fragment>
            ))}
        </SubsectionBody>
    </Subsection>
);

MarketSubsection.propTypes = {
    /** Object containing data about this subsection */
    market: propTypes.shape({
        title: propTypes.string,
        odds: propTypes.arrayOf(propTypes.shape({
            title: propTypes.string,
            price: propTypes.string,
            url: propTypes.string,
        })),
    }),
    /** Click handler for odds links */
    onClickOdds: propTypes.func,
};

MarketSubsection.displayName = 'MarketSubsection';

export default MarketSubsection;
