import React from 'react';
import Ic from '..';

const IC135Cross = (args) => (
    <Ic {...args}>
        <path d="M85.378 21.1l-5.914-5.914-28.775 28.775-28.775-28.775L16 21.1l28.775 28.775L16 78.65l5.914 5.914 28.775-28.775 28.775 28.775 5.914-5.914-28.775-28.775z" />
    </Ic>
);

IC135Cross.displayName = 'IC135Cross';

export default IC135Cross;
