import React, {useEffect, useRef, useState} from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import classnames from 'classnames';
import invoke from 'lodash/invoke';
import get from 'lodash/get';
import noop from 'lodash/noop';
import {rgba} from 'polished';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';

import {isBrowser} from '@fsa-streamotion/browser-utils';
import {stylesWhenNot, mediaQuery, stylesIfElse} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition, TRANSITION_TIMING_FUNCTION} from '../../../../common/animations';
import {A, Button, Nav} from '../../../../common/normalized-styled-components';
import {white, black, fog, flash} from '../../../../common/palette';
import {SCREEN_768_TABLET, SCREEN_1024_DESKTOP, SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';

const BorderedWrapper = styled.div`
    position: relative;
    margin: 0 5%;

    &::after {
        display: block;
        position: absolute;
        bottom: 0;
        background-color: ${rgba(white, 0.1)};
        width: 100%;
        height: 1px;
        overflow: hidden;
        content: '';
    }
`;

const CarouselWrapper = styled(Nav)`
    position: relative;
    overflow: hidden;
    /* stylelint-disable-next-line color-no-hex */
    mask-image: linear-gradient(to right, transparent, #000 35px, #000 calc(100% - 35px), transparent); /* don’t use palette black in case the colour changes from #000. This is a mask and we’re playing with black and transparent as opacity placeholders. */
`;

const ItemsWrapper = styled.ul`
    display: flex;
    margin: 0;
    padding: 16px 0 0;
    width: 100%;
    overflow-x: scroll;
    list-style: none;
    scroll-snap-type: x mandatory;
    scroll-snap-stop: always;
    scrollbar-width: none;
    -ms-overflow-style: none;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding-top: 7px;
    `}

    &::before,
    &::after {
        min-width: 50%;
        content: '';
        pointer-events: none;
        scroll-snap-align: none;
    }

    &::-webkit-scrollbar {
        display: none;
    }
`;

const navItemBaseCss = css`
    box-sizing: border-box;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    transition: ${transition('color', 'text-shadow', 'transform')};
    margin: 0 6px;
    outline: 0;
    border: 0;
    background: transparent;
    padding: 0 7px;
    max-width: 220px;
    height: 40px;
    overflow: hidden;
    text-shadow: 0 1px 1px ${rgba(black, 0.3)};
    white-space: nowrap;
    font: var(--infinity-header-8);
    scroll-snap-align: center;

    ${stylesIfElse('isSelected')`
        color: ${white};
    ``
        text-shadow: none;
        color: ${fog};
        font-weight: 400;
    `}

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        margin-right: 17px;
        margin-left: 17px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        margin-right: 24px;
        margin-left: 24px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        margin-right: 31px;
        margin-left: 31px;
        height: 70px;
    `}

    &:hover,
    &:focus {
        text-shadow: 0 1px 1px ${rgba(black, 0.3)};

        &::after {
            transform: scaleX(1);
            transition: transform 0.3s ${TRANSITION_TIMING_FUNCTION};
            background-color: ${flash};
        }
    }

    &::after { /* stylelint-disable-line */
        position: absolute;
        bottom: 0;
        background-color: ${white};
        /* We want 80% of the total width but we need to include the padding in the calculation as well */
        width: calc((100% - 14px) * 0.8);
        height: 2px;
        content: '';

        ${stylesWhenNot('isSelected')`
            transform: scaleX(0);
        `}
    }
`;

const NavItemButton = styled(Button)`
    ${navItemBaseCss}

    appearance: none;
`;

const NavItemAnchor = styled(A)`
    ${navItemBaseCss}

    text-decoration: none;
`;

const StyledText = styled.span`
    display: block;
    overflow-x: hidden;
    mask-image: linear-gradient(to right, #000 185px, transparent); /* stylelint-disable-line color-no-hex */
`;

const OR69CatTab = ({
    items,
    onSelectItem = noop,
    selectedItemIndex,
    className,
}) => {
    const [hasDoneFirstScroll, setHasDoneFirstScroll] = useState(false);
    const [focusedItemIndex, setFocusedItemIndex] = useState(selectedItemIndex);
    const itemsWrapperRef = useRef(null);
    const previousItemRef = useRef(null);
    const focusedItemRef = useRef(null);
    const nextItemRef = useRef(null);
    const isAnimated = useRef(true);

    const refs = [previousItemRef, focusedItemRef, nextItemRef];

    useEffect(function setIsAnimatedOnMount() {
        if (isAnimated?.current !== undefined) {
            isAnimated.current = isBrowser() && !window.matchMedia('(prefers-reduced-motion: reduce), (update: slow)').matches;
        }
    }, [isAnimated]);

    useEffect(() => {
        const currentRef = get(focusedItemRef, 'current');

        if (!currentRef) {
            return;
        } else if (hasDoneFirstScroll) {
            scrollIntoView(currentRef, {
                behavior: hasDoneFirstScroll && isAnimated.current ? 'smooth' : 'auto',
                block: 'nearest',
                inline: 'center',
            });
        } else {
            // on load, we don't want to scrollIntoView because it half scrolls down from the hero carousel above it, so secretly scroll "not into view"
            itemsWrapperRef.current.scrollTo({
                left: currentRef.offsetLeft // how far away element is
                    + (currentRef.offsetWidth / 2) // plus half its width for aligning to its centre point
                    - (itemsWrapperRef.current.offsetWidth / 2), // minus half the container width for horizontal centring on page
                behavior: 'instant',
            });

            setHasDoneFirstScroll(true);
        }
    }, [focusedItemIndex, hasDoneFirstScroll]); // eslint-disable-line react-hooks/exhaustive-deps

    // When selectedItemIndex changes, set that guy as the focused item
    useEffect(() => {
        setFocusedItemIndex(selectedItemIndex);
    }, [selectedItemIndex]);

    return (
        <BorderedWrapper className={classnames('OR69CatTab', className)}>
            <CarouselWrapper
                onKeyDown={(e) => {
                    if (e.key === 'ArrowLeft') {
                        e.preventDefault();
                        e.stopPropagation();
                        invoke(previousItemRef, 'current.focus');
                    } else if (e.key === 'ArrowRight') {
                        e.preventDefault();
                        e.stopPropagation();
                        invoke(nextItemRef, 'current.focus');
                    }
                }}
            >
                <ItemsWrapper ref={itemsWrapperRef}>
                    {items
                        .map(({href, label, value}, index) => {
                            const isSelected = selectedItemIndex === index;

                            const NavItem = href ? NavItemAnchor : NavItemButton;

                            return (
                                <li key={label}>
                                    <NavItem
                                        onFocus={() => {
                                            // set focussed item for both keyboard & mouse selection
                                            setFocusedItemIndex(index);
                                        }}
                                        onKeyDown={(event) => {
                                            // only for keyboard selection
                                            if (event.key === 'Enter' || (event.key === ' ' && !href)) {
                                                onSelectItem({label, value, href, index, event});
                                            }
                                        }}
                                        onMouseDown={(event) => {
                                            // If you click too slowly, the item index changes and it begins to scroll, and onClick will never fire. So do it on mouse down.
                                            onSelectItem({label, value, href, index, event});
                                        }}
                                        onClick={(event) => {
                                            // don’t go to href if you’re a quick clicker
                                            event.preventDefault();
                                        }}
                                        tabIndex={isSelected ? 0 : -1}
                                        isSelected={isSelected}
                                        href={href}
                                        ref={refs[index - focusedItemIndex + 1] || null}
                                    >
                                        <StyledText>
                                            {label}
                                        </StyledText>
                                    </NavItem>
                                </li>
                            );
                        })}
                </ItemsWrapper>
            </CarouselWrapper>
        </BorderedWrapper>
    );
};

OR69CatTab.displayName = 'OR69CatTab';

OR69CatTab.propTypes = {
    /** Tab items */
    items: propTypes.arrayOf(propTypes.shape({
        href: propTypes.string,
        label: propTypes.string,
        value: propTypes.any,
    })),
    /** Callback on item select */
    onSelectItem: propTypes.func,
    /** Index of selected item */
    selectedItemIndex: propTypes.number,
    /** Additional className(s) */
    className: classNameType,
};

export default OR69CatTab;
