export const ENVIRONMENT = {
    staging: 'staging',
    production: 'production',
    testing: 'testing',
    development: 'development',
};

/**
 * String union of available environments
 *
 * @typedef {ENVIRONMENT[keyof ENVIRONMENT]} Environment
 */

export const BRAND = {
    BINGE: 'binge',
    KAYO: 'kayo',
    FLASH: 'flash',
    LIFESTYLE: 'lifestyle',
    MAGNETO: 'magneto',
};

/**
 * String union of available brands
 *
 * @typedef {BRAND[keyof BRAND]} Brand
 */

/** * @type {Object.<Brand, Object.<Environment, Object.<Url, Object.<string, string>>>>} */
export const BASE_BILLING_HOSTED_PAYMENT_PAGE = {
    [BRAND.BINGE]: {
        [ENVIRONMENT.production]: {
            'https://binge.com.au': {
                id: '2c92a00c71a71a430171a9ea03ad0d48',
                gateway: 'hubbl-binge-production',
            },
            'https://staging.binge.com.au': {
                id: '2c92a00e71a719fe0171a9ec91ef5808',
                gateway: 'hubbl-binge-production',
            },
            'http://localhost': {
                id: '2c92a00c720cc43c01722bb683b30e33',
                gateway: 'hubbl-binge-production',
            },
        },
        [ENVIRONMENT.staging]: {
            'https://binge.com.au': {
                id: '2c92c0f8712986230171328d351c48fc',
                gateway: 'hubbl-binge-stg',
            },
            'https://staging.binge.com.au': {
                id: '2c92c0f9712998a40171328ecccd75af',
                gateway: 'hubbl-binge-stg',
            },
            'http://localhost': {
                id: '2c92c0f97077144401707984424023e0',
                gateway: 'hubbl-binge-stg',
            },
            'https://testhost': {
                id: '2c92c0f9725046a0017253534b9c5e12',
                gateway: 'hubbl-binge-stg',
            }, // e2e test
        },
    },
    [BRAND.LIFESTYLE]: {
        [ENVIRONMENT.production]: {
            'https://lifestyle.com.au': {
                id: '8a1282048c3a4daa018c426d4cc17989',
                gateway: 'hubbl-lifestyle-production',
            },
            'https://staging.lifestyle.com.au': {
                id: '8a1281f38c3a4da2018c4270cab34ce5',
                gateway: 'hubbl-lifestyle-production',
            },
            'https://sit.lifestyle.com.au': {
                id: '8a1283368c3a4db2018c426fa63541eb',
                gateway: 'hubbl-lifestyle-production',
            },
            'http://localhost': {
                id: '8a129c288c3a7b1f018c426e8f74360e',
                gateway: 'hubbl-lifestyle-production',
            },
        },
        [ENVIRONMENT.staging]: {
            'https://lifestyle.com.au': {
                id: '8ad09be48c3eff2f018c425c837f6f5f',
                gateway: 'hubbl-lifestyle-stg',
            }, // SBX2/staging
            'https://staging.lifestyle.com.au': {
                id: '8ad08ad58c3ef040018c4259130750ac',
                gateway: 'hubbl-lifestyle-stg',
            }, // SBX2/staging
            'https://sit.lifestyle.com.au': {
                id: '8ad08f068c3ef04b018c425a8e062205',
                gateway: 'hubbl-lifestyle-stg',
            }, // SBX2/staging
            'http://localhost': {
                id: '8ad099a98c3eff2b018c425babaf4244',
                gateway: 'hubbl-lifestyle-stg',
            }, // SBX2/staging
            'https://testhost': {
                id: '8ad08ae28c6299a4018c6560e80d0598',
                gateway: 'hubbl-lifestyle-stg',
            }, // e2e test, SBX2/staging
        },
        [ENVIRONMENT.development]: {
            'https://lifestyle.com.au': {
                id: '8ad09be48c3eff2f018c426194684d9b',
                gateway: 'hubbl-lifestyle-sit',
            }, // SBX1/dev
            'https://staging.lifestyle.com.au': {
                id: '8ad08f068c3ef04b018c425e2b5d61da',
                gateway: 'hubbl-lifestyle-sit',
            }, // SBX1/dev
            'https://sit.lifestyle.com.au': {
                id: '8ad099a98c3eff2b018c425face30515',
                gateway: 'hubbl-lifestyle-sit',
            }, // SBX1/dev
            'http://localhost': {
                id: '8ad09be48c3eff2f018c426098523b5e',
                gateway: 'hubbl-lifestyle-sit',
            }, // SBX1/dev
            'https://testhost': {
                id: '8ad08ad58c6299bb018c6563ddf429dc',
                gateway: 'hubbl-lifestyle-sit',
            }, // e2e test, SBX1/dev
        },
    },
    [BRAND.FLASH]: {
        [ENVIRONMENT.production]: {
            'https://flashnews.com.au': {
                id: '2c92a00d7ac85d0e017acca753527a3b',
                gateway: 'hubbl-flash-production',
            },
            'https://staging.flashnews.com.au': {
                id: '2c92a0077ac84f4d017acca48d882d5f',
                gateway: 'hubbl-flash-production',
            },
            'http://localhost': {
                id: '2c92a00d7ac85d17017acca35d137a1f',
                gateway: 'hubbl-flash-production',
            },
        },
        [ENVIRONMENT.staging]: {
            'https://flashnews.com.au': {
                id: '2c92c0f97a9ef7a2017aa28a90c517f4',
                gateway: 'hubbl-flash-stg',
            },
            'https://staging.flashnews.com.au': {
                id: '2c92c0f87a9eddeb017aa288a0353afd',
                gateway: 'hubbl-flash-stg',
            },
            'http://localhost': {
                id: '2c92c0f97a9ef71d017aa292fed85b00',
                gateway: 'hubbl-flash-stg',
            },
            'https://testhost': {
                id: '2c92c0f97a9ef7a2017aa2aa34137a98',
                gateway: 'hubbl-flash-stg',
            }, // e2e test
        },
    },
    [BRAND.KAYO]: {
        [ENVIRONMENT.production]: {
            'https://kayosports.com.au': {
                id: '2c92a0fe6620696401663cfb085375ee',
                gateway: 'hubbl-kayosports-production',
            },
            'https://staging.kayosports.com.au': {
                id: '2c92a0ff6657698201666152ca10213b',
                gateway: 'hubbl-kayosports-production',
            },
            'http://martian:8080': {
                id: '2c92a0fe66575bde01666154bfbe2d42',
                gateway: 'hubbl-kayosports-production',
            },
            'http://martian': {
                id: '2c92a0fd6ff0e9a80170141b261877c9',
                gateway: 'hubbl-kayosports-production',
            },
            'http://localhost': {
                id: '2c92a00c720cc43c01722bb683b30e33',
                gateway: 'hubbl-kayosports-production',
            },
        },
        [ENVIRONMENT.staging]: {
            'https://kayosports.com.au': {
                id: '2c92c0f96566029801656af5107e3b96',
                gateway: 'hubbl-kayosports-stg',
            },
            'https://staging.kayosports.com.au': {
                id: '2c92c0f96566028d016569a605a35329',
                gateway: 'hubbl-kayosports-stg',
            },
            'http://martian:8080': {
                id: '2c92c0f86565f40101656af392d75f1a',
                gateway: 'hubbl-kayosports-stg',
            },
            'http://martian': {
                id: '2c92c0f86ff07d04017014231d8f4e71',
                gateway: 'hubbl-kayosports-stg',
            },
            'http://localhost': {
                id: '2c92c0f86ff07d04017014231d8f4e71',
                gateway: 'hubbl-kayosports-stg',
            },
            'https://testhost': {
                id: '2c92c0f97a9ef7a2017aa2aa34137a98',
                gateway: 'hubbl-kayosports-stg',
            }, // e2e test
        },
        [ENVIRONMENT.testing]: {
            'https://kayosports.com.au': {
                id: '2c92c0f96566029801656af5107e3b96',
                gateway: 'hubbl-kayosports-sit',
            },
            'https://staging.kayosports.com.au': {
                id: '2c92c0f96566028d016569a605a35329',
                gateway: 'hubbl-kayosports-sit',
            },
            'http://martian:8080': {
                id: '2c92c0f86565f40101656af392d75f1a',
                gateway: 'hubbl-kayosports-sit',
            },
            // 'http://martian':                    '', // testing not in use?
        },
    },
    // leave magneto with the old stuff, it may all change again later, and they don't need the gateway information as they are able to get it from Billing API
    [BRAND.MAGNETO]: {
        [ENVIRONMENT.production]: {
            'https://hubbl.com.au':                   '8a129c288c101a08018c188dcfe127e7',
            'https://staging.hubbl.com.au':           '8a1299c28c101a13018c1893ec5c57af',
            'https://sit.hubbl.com.au':               '8a128f2a8c0ff900018c1892e5ca516a',
            'http://localhost':                       '8a128f2a8c0ff900018c189218e24bc7',
        },
        [ENVIRONMENT.staging]: {
            'https://hubbl.com.au':                   '8ad08ad58c15450d018c191d6a65782a',
            'https://staging.hubbl.com.au':           '8ad09c9f8c104509018c14a40e3d6fc3',
            'https://sit.hubbl.com.au':               '8ad09be48c104526018c14a90ff6464c',
            'http://localhost':                       '8ad08ad58c103068018c14ab1ab45653',
            'https://testhost':                       '8ad08f068c10306c018c14aef9b50b66', // e2e test
        },
        [ENVIRONMENT.development]: {
            'https://hubbl.com.au':                   '8ad09c9f8c15562b018c1913f498459a',
            'https://staging.hubbl.com.au':           '8ad08ad58c103068018c14b2dc0a7101',
            'https://sit.hubbl.com.au':               '8ad099a98c104508018c14b42079023c',
            'http://localhost':                       '8ad09be48c104526018c14b536cb7fb8',
            'https://testhost':                       '8ad09c9f8c104509018c14b65dc77f98', // e2e test
        },
    },
};

export const DEFAULT_ENV_ORDER = [ENVIRONMENT.production, ENVIRONMENT.staging, ENVIRONMENT.testing, ENVIRONMENT.development];

// first env is the default  (why do we have other non-defaults? they're a whitelist of allowed envs)
export const BASE_DEFAULT_SETTINGS = {
    platformEnv: DEFAULT_ENV_ORDER,
    resourcesEnv: DEFAULT_ENV_ORDER,
    statsEnv: DEFAULT_ENV_ORDER,
    youboraEnv: DEFAULT_ENV_ORDER,
    receiverEnv: [...DEFAULT_ENV_ORDER, 'dev1', 'dev2', 'qa1', 'qa2'],
};
