import {mediaQuery, stylesWhen} from '@fsa-streamotion/styled-component-helpers';

/**
 * Given <code>srcsetOptions</code> (a mapping of minimum widths to background images), generate media queries to show
 * the appropriate image at each screen resolution.
 *
 * This is an alternative to using <code>srcset</code> itself, in case we want to guarantee that specific versions of an
 * image will be displayed at various resolutions, preventing the browser from falling back to lower resolution images.
 *
 * @param {string} propName - the name of a styled component's attribute
 * @returns {Function} a function to insert into a styled component
 */
export default function backgroundImageFromSrcsetOptions(propName) {
    /* eslint-disable indent */
    return stylesWhen(propName)`
        ${({[propName]: srcsetOptions}) => (
            Object
                .entries(srcsetOptions)
                .sort(([width1], [width2]) => width1 - width2)
                .map(([minWidthPx, url], index) => (
                    index
                        ? mediaQuery({minWidthPx})`background-image: url(${url});`
                        : `background-image: url(${url});`
                ))
        )}
    `;
    /* eslint-enable indent */
}
