import styled from 'styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {cloudy, raven, poseidon, blanc} from '../../../common/palette';
import {SCREEN_768_TABLET, SCREEN_1024_DESKTOP, SCREEN_1280_DESKTOP, SCREEN_1920_DESKTOP} from '../../../common/screen-sizes';

/* To future devs:
   We had an original templating system but found the designers were making each row bespoke.
   This new base row template makes fewer assumptions, so hopefully it could still be useful
   as a generic row, but also can be extended to offer control over top/bottom/side padding,
   whether it is a thin content section, whatever whacky things the designers come up with.
   If you don’t like it, then we’re sorry for the mess. - <3 SEAN AND MING
*/
const Row = styled.div`
    /* Override these CSS properties to control row behaviour */
    --offers-page__row-padding-top: 28px;                                       /* padding on top of row */
    --offers-page__row-padding-bottom: var(--offers-page__row-padding-top);     /* padding on bottom of row */
    --offers-page__row-padding-horizontal: 14px;                                /* padding on sides of row */
    --offers-page__row-padding-horizontal-thin-content: calc(50% - 375px);      /* for the thin content sections, aka max-width 750px */
    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        --offers-page__row-padding-top: 56px;
    `}

    box-sizing: border-box;
    padding: var(--offers-page__row-padding-top, 0) var(--offers-page__row-padding-horizontal) var(--offers-page__row-padding-bottom, 0);
    width: 100%;
`;

export const ContentCarouselWrapper = styled(Row).attrs({className: 'offers-page__row--content-carousel'})`
    --offers-page__row-padding-top: 0;
    --offers-page__row-padding-bottom: 85px;
    --offers-page__row-padding-horizontal: 0;
    background-color: ${({isLightTheme}) => isLightTheme ? blanc : poseidon};

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        --offers-page__row-padding-top: 0;
        --offers-page__row-padding-bottom: 85px;
    `}
`;

ContentCarouselWrapper.displayName = 'ContentCarouselWrapper';

export const MarketingBlockWrapper = styled(Row).attrs({className: 'offers-page__row--marketing-block'})`
    --offers-page__row-padding-bottom: 0;
    --offers-page__row-padding-horizontal: 0;
    --offers-page__row-padding-top: 0;
    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        --offers-page__row-padding-top: 0;
    `}
`;

MarketingBlockWrapper.displayName = 'MarketingBlockWrapper';

export const MarketingLinkWrapper = styled(Row).attrs({className: 'offers-page__row--marketing-link'})`
    text-align: center;
`;

MarketingLinkWrapper.displayName = 'MarketingLinkWrapper';

export const TabbedContentCarouselWrapper = styled(Row).attrs({className: 'offers-page__row--tabbed-content-carousel'})`
    --offers-page__row-padding-top: 0;
    --offers-page__row-padding-bottom: 85px;
    --offers-page__row-padding-horizontal: 0;
    background-color: ${({isLightTheme}) => isLightTheme ? blanc : '#257a9a'}; /* TODO: for now this color does not exist in palette but is in the latest designs for offer page, design team has been asked to provide a color name */

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        --offers-page__row-padding-top: 0;
        --offers-page__row-padding-bottom: 85px;
    `}
`;

TabbedContentCarouselWrapper.displayName = 'TabbedContentCarouselWrapper';

export const DevicesCarouselWrapper = styled(Row).attrs({className: 'offers-page__row--devices-carousel'})`
    background-color: ${({isLightTheme}) => isLightTheme ? cloudy : raven};
    padding: 0;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        --offers-page__row-padding-horizontal: var(--offers-page__row-padding-horizontal-thin-content);
    `}
`;

DevicesCarouselWrapper.displayName = 'DevicesCarouselWrapper';

export const FeaturesCarouselWrapper = styled(Row).attrs({className: 'offers-page__row--features-carousel'})`
    --offers-page__row-padding-top: 0;
    background-color: ${({isLightTheme}) => isLightTheme ? cloudy : raven};
    padding: 0;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        --offers-page__row-padding-top: 0;
        --offers-page__row-padding-horizontal: var(--offers-page__row-padding-horizontal-thin-content);
    `}
`;

FeaturesCarouselWrapper.displayName = 'FeaturesCarouselWrapper';

export const StreamotionBlurbWrapper = styled(Row).attrs({className: 'offers-page__row--streamotion-blurb'})`
    --offers-page__row-padding-top: 84px;
    --offers-page__row-padding-bottom: 45px;
    position: relative;
    /* stylelint-disable-next-line color-no-hex, scale-unlimited/declaration-strict-value */
    background-color: #fff;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        --offers-page__row-padding-top: 98px;
        --offers-page__row-padding-horizontal: calc(50% - 300px);
    `}

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        --offers-page__row-padding-horizontal: calc(50% - 336px);
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        --offers-page__row-padding-horizontal: var(--offers-page__row-padding-horizontal-thin-content);
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        --offers-page__row-padding-top: 114px;
        --offers-page__row-padding-bottom: 67px;
    `}
`;

StreamotionBlurbWrapper.displayName = 'StreamotionBlurbWrapper';

export const QuestionsAccordionWrapper = styled(Row).attrs({className: 'offers-page__row--questions-accordion'})`
    --offers-page__row-padding-horizontal: 34px;
    background-color: ${({isLightTheme}) => isLightTheme ? blanc : raven};

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        --offers-page__row-padding-horizontal: calc(50% - 300px);
    `}

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        --offers-page__row-padding-horizontal: calc(50% - 336px);
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        --offers-page__row-padding-horizontal: var(--offers-page__row-padding-horizontal-thin-content);
    `}
`;

QuestionsAccordionWrapper.displayName = 'QuestionsAccordionWrapper';
