import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import {blanc, vader} from '../../../../common/palette';
import Ic from '..';

const IC263ContextualAudioSet = ({
    color = blanc,
    secondaryColor = vader,
    ...otherProps
}) => (
    <Ic {...otherProps}>
        <g transform="translate(7 7)">
            <circle cx="42.7" cy="42.7" r="42.7" fill={color} />
            <path fill={secondaryColor} d="M55.314 41.79h4.564v19.152h-4.564zm-25.41-17.346H25.34v10.15h-2.38v6.09h9.702v-6.09h-2.8l.042-10.15zm.476 14H25.2v-1.568h5.18v1.568zm-5.04 5.194h4.564v17.318H25.34zm19.124-19.194H39.9V49h-2.562v6.09h9.688V49h-2.562V24.444zm.336 28.378h-5.194v-1.526H44.8v1.526zm-4.9 4.956h4.564v3.178H39.9zM59.878 33.04v-8.596h-4.564v8.596h-2.576v6.16h9.702v-6.16h-2.562zm.28 3.808H55.02v-1.526h5.18l-.042 1.526z" />
        </g>
    </Ic>
);

IC263ContextualAudioSet.propTypes = {
    /** The icon's primary color */
    color: colorType,
    /** The icon's secondary color */
    secondaryColor: colorType,
};

IC263ContextualAudioSet.displayName = 'IC263ContextualAudioSet';

export default IC263ContextualAudioSet;
