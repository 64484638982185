import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {colorType} from '@fsa-streamotion/custom-prop-types';

const StyledSvg = styled.svg`
    display: block;
    width: auto;
    height: 100%; /* Scaled by HEIGHT :O */
    fill: ${({color}) => color || 'currentColor'};

    ${stylesWhen('isLeft')`
        transform: rotate(180deg);
    `}
`;

const GA23CaArrow = ({
    color,
    isLeft,
    ...htmlAttributes
}) => (
    <StyledSvg
        {...htmlAttributes}
        isLeft={isLeft}
        color={color}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
    >
        <path /* This path doesn't fill the viewbox, causing Sean a very bad time tweaking the sizing. Would be nice to get this re-exported... */
            d="M32.1 90.9L28.2 87l36.9-37-36.9-36.9 3.9-3.9L71 48l2 2c-.957.949-1.623 1.615-2 2L32.1 90.9z"
            fillRule="evenodd"
        />
    </StyledSvg>
);

GA23CaArrow.displayName = 'GA23CaArrow';

GA23CaArrow.propTypes = {
    /** The fill color of the arrow */
    color: colorType,
    /** Whether or not to show the left arrow instead of default right */
    isLeft: propTypes.bool,
};

export default GA23CaArrow;
