import React from 'react';
import Ic from '..';

const IC71Thtr = (args) => (
    <Ic {...args}>
        <path d="M79.704 86.422H19.77v-8.764h4.2v4.564h51.534v-4.564h4.2zm14.672-12.53H5L5.098 14h89.376l-.098 59.892zm-80.976-8.4h72.59l.07-43.092H13.484L13.4 65.492z" />
    </Ic>
);

IC71Thtr.displayName = 'IC71Thtr';

export default IC71Thtr;
