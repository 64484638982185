import {format, parseISO} from 'date-fns';

/**
 * Parse a date according to a given date format from the API. Parsed from ${date_format},
 * where the date_format should match according to date-fns's format guidelines
 *
 * @param {string} dateStringFormatTemplate Format string to format the date to
 * @param {string} transmissionTime Transmission time of a video which comes from the API
 * @returns {string} Parsed datetime string
 */
export default function parseDateTime(dateStringFormatTemplate, transmissionTime) {
    if (!dateStringFormatTemplate || !transmissionTime) {
        return '';
    }

    try {
        return dateStringFormatTemplate.replace(/\$\{.+?\}/gi, (part) => {
            const slicedPart = part.slice(2, -1);

            return format(parseISO(transmissionTime), slicedPart);
        });
    } catch (e) {
        // Leaving this here so we can catch any other date-fns built-in errors but won't block the user
        // eslint-disable-next-line no-console
        console.error('Parse Info Line DateTime (date-fns) error:', e);

        return dateStringFormatTemplate;
    }
}
