import {TERM_TYPE} from './normalise-term-type';

/**
 * Splits a string into parts based on a specified delimiter.
 *
 * @param {Object} options - The options for splitting.
 * @param {string} options.input - The input string to be split.
 * @param {string} options.delimiter - The delimiter to use for splitting the input string.
 * @returns {Array} - An array containing the parts of the input string split by the delimiter.
 * @example
 * const result = splitString({ input: "monthlycopy-left|annualcopy-right", delimiter: "|" });
 * console.log(result); // ["monthlycopy-left", "annualcopy-right"]
 */
const splitString = ({input, delimiter}) => input?.split(delimiter);

/**
 * Returns a curried function that takes a termType and returns a function
 * that takes text and returns the term-specific part of the text.
 * If the termType is 'annual', returns the right side of the pipe.
 * If the termType is 'monthly', returns the left side of the pipe or the whole string if there is no pipe.
 *
 * @param {'annual' | 'monthly'} termType - The term type ('annual' or 'monthly').
 * @returns {function} - A function that takes text and returns the term-specific part of the text.
 * @example
 * const getAnnualText = getTermtypeBasedCopy('annual');
 * const result = getAnnualText('left|right');
 * console.log(result); // 'right'
 */
const getTermtypeBasedCopy = (termType) => (text) => {
    const parts = splitString({input: text, delimiter: '|'});

    if (termType === TERM_TYPE.annual && parts?.length > 1) {
        return parts[1]; // right
    }

    return parts?.[0] || ''; // left
};

export {splitString, getTermtypeBasedCopy};
