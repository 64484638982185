import styled, {css} from 'styled-components';
import {mediaQuery, stylesIfElse, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {transition} from '../animations';
import {black, cobalt, deepIndigo, white} from '../palette';
import {SCREEN_1920_DESKTOP} from '../screen-sizes';

const interactiveStyles = css`
    /** FOCUS / HOVER STATE */
    &:hover,
    &:focus {
        outline: 0;
        background-color: ${cobalt};
        color: ${white};
        font: var(--quicksilver-button-cta-medium);
    }
    /** ON PRESS / SELECTED STATE */
    &:active,
    &[aria-pressed='true'],
    &[aria-current='true'] {
        background-color: ${deepIndigo};
        color: ${white};
        font: var(--quicksilver-button-cta-medium);
    }
    /** SELECTED & IN FOCUS/HOVER STATE */
    &[aria-pressed='true'],
    &[aria-current='true'] {
        &:hover,
        &:focus {
            background-color: ${cobalt};
            color: ${white};
            font: var(--quicksilver-button-cta-medium);
        }
    }
`;

/** Handles:
 * - text overflow without affecting the CheckMarkCircle
 * - button not shifting on font weight change
 *
 * @param {Object} props props
 *
 * @returns {Object} children wrapper
 */
export const ChildrenWrapper = styled.section`
    width: 100%;
    overflow: hidden;
    /* stylelint-disable function-parentheses-space-inside, function-comma-space-after */
    /**
     * Add fading text to the right - replacing ellipsis -- for text overflow
     */
    mask-image: linear-gradient(
        to right,
        ${black} var(--quicksilver-non-masked-width),
        transparent 100%
    );
    /* stylelint-enable function-parentheses-space-inside, function-comma-space-after */

    /**
     * Hack from Ming! This is so button won't shift
     * when font weight changes to medium in interactiveStyles
     */
    &::before {
        display: block;
        visibility: hidden;
        height: 0;
        overflow: hidden;
        font: var(--quicksilver-button-cta-medium);
        /** NOTE: Only apply if content is string */
        content: ${(props) => typeof props['data-content'] === 'string' ? 'attr(data-content)' : 'none'};
    }
`;

ChildrenWrapper.displayName = 'ChildrenWrapper';

const baseCtaButtonStyles = css`
    box-sizing: border-box;
    position: relative;
    align-items: center;
    justify-content: center;
    transform-origin: left center;
    transition: ${transition('background-color', 'opacity', 'color')};
    border-radius: 20px;
    padding: 0 21px;
    min-height: 40px;
    text-align: center;
    letter-spacing: normal;
    white-space: nowrap;
    font: var(--quicksilver-button-cta);

    ${stylesIfElse('isBlock')`
        display: flex;
        width: 100%;
        --quicksilver-non-masked-width: 90%;
    ``
        display: inline-flex;
        min-width: 104px;
        max-width: 250px;
        --quicksilver-non-masked-width: 180px;

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            min-width: 186px;
            max-width: 333px;
            --quicksilver-non-masked-width: 230px;
        `}
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        min-height: 60px;
        border-radius: 30px;
    `}

    ${stylesWhenNot('disabled')`
        ${interactiveStyles};
    `}
`;

export default baseCtaButtonStyles;
