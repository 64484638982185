import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import get from 'lodash/get';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {fadeInOut} from '../../../../common/animations';
import {white} from '../../../../common/palette';
import BA01BtnPr from '../../../atoms/ba/01-btn-pr';
import BA08BtnSec from '../../../atoms/ba/08-btn-sec';
import IC103Loading from '../../../atoms/ic/103-loading';
import TM24AlertTile from '../../../molecules/tm/24-alert-tile';
import TM27InfoTile from '../../../molecules/tm/27-info-tile';
import {IC61Ios} from '../../../atoms/ic';

const CancelSubContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    text-align: center;
    color: ${white};
`;

const Content = styled.div`
    display: grid;
    grid-gap: 28px;
`;

const Header = styled.h2`
    font: var(--ionic-header-6-bold);
`;

const InfoText = styled.p`
    margin: 21px 0;
    font: var(--ionic-body-copy-4);
`;

const ConfirmText = styled.p`
    margin: 21px auto;
    font: var(--ionic-header-7-bold);
`;

const Buttons = styled.div`
    display: grid;
    grid-gap: 7px;
    margin: 0 auto;
    max-width: 210px;
`;

const LOADING_ICON_SIZE = '47px';

const LoadingBox = styled.div`
    padding-top: 21px;
    min-height: ${LOADING_ICON_SIZE};
`;

const LoadingWrapper = styled.div`
    text-align: center;

    ${fadeInOut}
`;

const DEFAULT_INFO_TEXT = [
    'We are sorry to see you go. You are confirming that you wish to cancel your subscription.',
    'You may continue to watch great live and on demand sport until the end of your current billing period. If you wish to use Kayo in the future, you can sign in using the same email and password to reactivate your account.', // eslint-disable-line max-len
];

const OR103CancelSub = ({
    className,
    headingText = 'Cancel your subscription',
    infoText = DEFAULT_INFO_TEXT,
    confirmText = 'Please confirm your subscription change below.',
    errorMsg,
    isSubmitting,
    submitButtonText = 'I agree',
    cancelButtonText = 'Cancel',
    isItunesSubscription,
    itunesSubscriptionTitle,
    itunesSubscriptionMessage,
    submitButtonHref,
    onSubmit,
    onCancel,
}) => {
    if (isItunesSubscription) {
        return (
            <TM27InfoTile
                className={classnames('OR103CancelSub', className)}
                icon={<IC61Ios size="43px" />}
                title={itunesSubscriptionTitle}
            >
                {itunesSubscriptionMessage}
            </TM27InfoTile>
        );
    }

    return (
        <CancelSubContainer className={classnames('OR103CancelSub', className)}>
            <Header>
                {headingText}
            </Header>
            <Content>
                <section>
                    {infoText.map((line, index) => (
                        <InfoText key={typeof line === 'string' ? line : get(line, 'key', index)}>
                            {line}
                        </InfoText>
                    ))}
                    <ConfirmText>
                        {confirmText}
                    </ConfirmText>
                </section>

                <section>
                    <Buttons>
                        <BA01BtnPr
                            type="button"
                            isBlock={true}
                            disabled={isSubmitting}
                            onClick={onSubmit}
                            href={submitButtonHref}
                            target="_blank"
                        >
                            {submitButtonText}
                        </BA01BtnPr>
                        <BA08BtnSec
                            type="button"
                            isBlock={true}
                            disabled={isSubmitting}
                            onClick={onCancel}
                        >
                            {cancelButtonText}
                        </BA08BtnSec>
                    </Buttons>

                    <LoadingBox>
                        {!!isSubmitting && (
                            <LoadingWrapper>
                                <IC103Loading size={LOADING_ICON_SIZE} />
                            </LoadingWrapper>
                        )}

                        {!!(errorMsg && !isSubmitting) && (
                            <TM24AlertTile>
                                {errorMsg}
                            </TM24AlertTile>
                        )}
                    </LoadingBox>

                </section>
            </Content>
        </CancelSubContainer>
    );
};

OR103CancelSub.propTypes = {
    /** Class name property */
    className: classNameType,
    /** Heading text */
    headingText: propTypes.string,
    /** Info text */
    infoText: propTypes.arrayOf(propTypes.node),
    /** Confirmation copy */
    confirmText: propTypes.node,
    /** If set, an error message to be displayed to the user */
    errorMsg: propTypes.string,
    /** Whether or not the form has been submitted and is awaiting a response */
    isSubmitting: propTypes.bool,
    /** Submit button text */
    submitButtonText: propTypes.string,
    /** Cancel button text */
    cancelButtonText: propTypes.string,
    /** Link for submit button */
    submitButtonHref: propTypes.string,
    /** If set shows itunes message */
    isItunesSubscription: propTypes.bool,
    /** Title for the itunes message */
    itunesSubscriptionTitle: propTypes.node,
    /** Content of the itunes message */
    itunesSubscriptionMessage: propTypes.node,
    /** A callback to handle submission of the form data */
    onSubmit: propTypes.func,
    /** A callback to handle going back */
    onCancel: propTypes.func,
};

OR103CancelSub.displayName = 'OR103CancelSub';

export default OR103CancelSub;
