import React from 'react';
import Ic from '..';

const IC107Prog = (args) => (
    <Ic {...args}>
        <path d="M55.7 38.4c1.4.7 2.8 1 4.2 1 3.6 0 7-2 8.7-5.4 2.3-4.8.4-10.6-4.4-12.9-1.4-.7-2.8-1-4.2-1-3.6 0-7 2-8.7 5.4-2.3 4.8-.4 10.6 4.4 12.9zm-1.9-11.7c1.2-2.4 3.5-3.8 6.2-3.8 1.1 0 2.1.2 3 .7 1.6.8 2.9 2.2 3.5 4 .6 1.7.5 3.6-.3 5.2-1.2 2.4-3.5 3.8-6.2 3.8-1.1 0-2.1-.2-3-.7-3.5-1.6-4.9-5.8-3.2-9.2zM38.5 44.3c2.3-4.8.4-10.6-4.4-12.9-1.4-.7-2.8-1-4.2-1-3.6 0-7 2-8.7 5.4-2.3 4.8-.4 10.6 4.4 12.9 1.4.7 2.8 1 4.2 1 3.6-.1 7-2 8.7-5.4zM36 43c-1.2 2.4-3.5 3.8-6.2 3.8-1.1 0-2.1-.2-3-.7-1.6-.8-2.9-2.2-3.5-4-.6-1.7-.5-3.6.3-5.2 1.2-2.4 3.5-3.8 6.2-3.8 1.1 0 2.1.2 3 .7 3.5 1.7 4.9 5.8 3.2 9.2zM44.3 61.4c-1.4-.7-2.8-1-4.2-1-3.6 0-7 2-8.7 5.4-2.3 4.8-.4 10.6 4.4 12.9 1.4.7 2.8 1 4.2 1 3.6 0 7-2 8.7-5.4 2.4-4.7.4-10.5-4.4-12.9zm1.9 11.7c-1.2 2.4-3.5 3.8-6.2 3.8-1.1 0-2.1-.2-3-.7-3.4-1.7-4.8-5.8-3.1-9.2 1.2-2.4 3.5-3.8 6.2-3.8 1.1 0 2.1.2 3 .7 1.6.8 2.9 2.2 3.5 4 .6 1.7.5 3.6-.4 5.2zM61.5 55.6c-2.3 4.8-.4 10.6 4.4 12.9 1.4.7 2.8 1 4.2 1 3.6 0 7-2 8.7-5.4 2.3-4.8.4-10.6-4.4-12.9-1.4-.7-2.8-1-4.2-1-3.6 0-7 2-8.7 5.4zm2.5 1.2c1.2-2.4 3.5-3.8 6.2-3.8 1.1 0 2.1.2 3 .7 3.4 1.7 4.8 5.8 3.1 9.2-1.2 2.4-3.5 3.8-6.2 3.8-1.1 0-2.1-.2-3-.7-3.3-1.7-4.7-5.8-3.1-9.2zM54 51.9c1.1-2.2.2-4.8-2-5.9s-4.8-.2-5.9 2c-1.1 2.2-.2 4.8 2 5.9s4.8.1 5.9-2zm-1.6-.8c-.6 1.3-2.2 1.8-3.5 1.2-1.3-.6-1.8-2.2-1.2-3.5.6-1.3 2.2-1.8 3.5-1.2 1.3.6 1.8 2.2 1.2 3.5z" />
        <path d="M91.7 49.9C91.7 27 73 8.3 50 8.3 27 8.3 8.4 27 8.4 49.9c0 22.3 17.6 40.5 39.5 41.6v.1H84V86H70.8c12.5-7.2 20.9-20.6 20.9-36.1zm-77.8 0C13.9 30 30.1 13.8 50 13.8c19.9 0 36.1 16.2 36.1 36.1C86.1 69.8 69.9 86 50 86c-19.9 0-36.1-16.2-36.1-36.1z" />
    </Ic>
);

IC107Prog.displayName = 'IC107Prog';

export default IC107Prog;
