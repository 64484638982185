import bacon from 'baconjs';

import journeyClubs from './journey/clubs';
import journeyQantas from './journey/qantas';

export default function getStepJourneyStream({
    journeyType,
    platformEnv,
    ratePlanId,
    user,
    voucherCode,
    brand,
}) {
    const moduleArgs = {platformEnv, ratePlanId, user, voucherCode, brand};
    let journeyModule$;

    switch (journeyType) {
        case 'CLUBS':
            journeyModule$ = journeyClubs(moduleArgs);
            break;

        case 'QANTAS':
            journeyModule$ = journeyQantas(moduleArgs);
            break;

        case 'TELSTRA':
        case 'OPTUS':
        case 'FREEMIUM': // freemium journey causes variations in step 5, which is (currently) outside the scope of the journey step so has no effect here.
        default:
            journeyModule$ = bacon.later(0, {
                isJourneyCompleted: true,
                isJourneyShown: false,
                journeyComponent: null,
            });
    }

    const reactElement$ = journeyModule$
        .map('.isJourneyShown')
        .decode({
            true: journeyModule$.map('.journeyComponent'),
        });

    return bacon.combineTemplate({
        reactElement: reactElement$.startWith(null),
        hydration: {},
        isJourneyActive: journeyModule$.map('.isJourneyShown'),
        isJourneyCompleted: journeyModule$.map('.isJourneyCompleted'),
        journeyDetails: journeyModule$.map('.journeyDetails'),
    });
}
