import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import propTypes from 'prop-types';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {silverLining} from '../../../../common/palette';

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
`;

const Container = styled.div`
    margin-bottom: 7px;
    color: ${silverLining};
`;

const Title = styled.h3`
    margin: 0;
    font: var(--mui-hud-tile-header-bold);
`;

const StatValue = styled.span`
    font: var(--mui-hud-tile-score-bold);
`;

const Text = styled.span`
    font: var(--mui-hud-tile-copy-light);
`;

const StatSubtitle = styled(Text)`
    padding-left: 7px;
`;

const IGM07TlPlayer = ({
    className,
    playerName,
    playerSubtitle,
    statValue,
    statSubtitle,
}) => (
    <Container className={classNames('IGM07TlPlayer', className)}>
        <FlexRow>
            <Title>{playerName}</Title>
            <FlexRow>
                <StatValue>{statValue}</StatValue>
                {!!statSubtitle && (
                    <StatSubtitle>{statSubtitle}</StatSubtitle>
                )}
            </FlexRow>
        </FlexRow>
        {!!playerSubtitle && (
            <Text>{playerSubtitle}</Text>
        )}
    </Container>
);

IGM07TlPlayer.displayName = 'IGM07TlPlayer';

IGM07TlPlayer.propTypes = {
    /** Additional class(es) */
    className: classNameType,
    /** The player's name */
    playerName: propTypes.node,
    /** An optional subtitle for the player, e.g. RH Medium Pace */
    playerSubtitle: propTypes.node,
    /** The stat value to show, e.g. 127 */
    statValue: propTypes.node,
    /** A qualifying description for that stat, e.g. 68.3 overs */
    statSubtitle: propTypes.node,
};

export default IGM07TlPlayer;
