import React from 'react';

import Ic from '..';

const IC11AirP = (args) => (
    <Ic {...args}>
        <path d="M50.26 61 69 85H32z" opacity=".35" />
        <path fillRule="nonzero" d="M29.41 85.303a2 2 0 0 1 .413-1.217l18.946-24.713a2 2 0 0 1 3.175 0L70.89 84.086a2 2 0 0 1-1.587 3.217H31.41a2 2 0 0 1-2-2zm5.04-1.5h31.812L50.355 63.056 34.45 83.803z" />
        <g transform="translate(5 12)">
            <path d="m71.467 67-3-4.718h11.246c3.06 0 5.55-2.48 5.55-5.53V10.248c0-3.049-2.49-5.529-5.55-5.529H10.288c-3.06 0-5.552 2.48-5.552 5.53v46.505c0 3.049 2.491 5.529 5.552 5.529h12.237l-3 4.718h-9.237C4.614 67 0 62.402 0 56.753V10.247C0 4.597 4.614 0 10.288 0h69.425C85.385 0 90 4.597 90 10.247v46.506C90 62.403 85.385 67 79.713 67h-8.246z" />
        </g>
    </Ic>
);

IC11AirP.displayName = 'IC11AirP';

export default IC11AirP;
