import React from 'react';
import Ic from '..';

const IC238More = (args) => (
    <Ic {...args}>
        <path fillRule="nonzero" d="M38.979 61.644h-5.314l-.907-16.28h-.07l-4.951 16.28h-4.282l-4.994-16.28h-.097l-.837 16.28h-5.328l1.743-23.901h6.82l4.827 15.732h.07l4.756-15.732h6.89l1.674 23.901zm10.545.39c-5.217 0-9.178-3.363-9.178-8.877 0-5.513 3.933-8.905 9.178-8.905 5.244 0 9.136 3.392 9.136 8.905 0 5.514-3.92 8.877-9.136 8.877zm0-13.409c-2.371 0-3.752 1.906-3.752 4.431 0 2.526 1.394 4.431 3.752 4.431 2.357 0 3.752-1.905 3.752-4.43 0-2.526-1.41-4.432-3.752-4.432zm36.7-2.098c1.577 1.593 2.428 3.803 2.34 6.082.028.823-.042 1.833-.042 1.833H76.778a3.97 3.97 0 0 0 1.368 2.692c.788.671 1.803.986 2.817.873a8.994 8.994 0 0 0 4.951-1.63l2.106 2.886c-2.158 1.946-4.966 2.941-7.825 2.77-5.09 0-8.8-3.261-8.8-8.904 0-5.644 4.016-8.877 8.898-8.877 2.2-.145 4.355.682 5.932 2.275zM71.716 44.57l-.265 5.095h-.237a4.143 4.143 0 0 0-1.939-.376 3.142 3.142 0 0 0-2.483 1.242 6.289 6.289 0 0 0-.837 3.565v7.591h-5.356V44.57h5.356v2.424c.694-1.69 2.312-2.776 4.087-2.742a3.696 3.696 0 0 1 1.674.318zm8.508 3.276a3.301 3.301 0 0 0-2.393.978 3.537 3.537 0 0 0-1.024 2.443l6.793.014a3.481 3.481 0 0 0-.988-2.454 3.247 3.247 0 0 0-2.388-.981z" />
    </Ic>
);

IC238More.displayName = 'IC238More';

export default IC238More;
