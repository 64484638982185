import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import {mist, ink} from '../../../../common/palette';

import Ic from '..';

const IC58Info = ({
    color = mist,
    secondaryColor = ink,
    ...args
}) => (
    <Ic {...args}>
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h100v100H0z" />
            <path fill={color} fillRule="nonzero" d="M49.245 7C25.914 7 7 25.914 7 49.245c0 23.33 18.914 42.244 42.245 42.244 23.33 0 42.244-18.913 42.244-42.244C91.482 25.917 72.573 7.008 49.245 7z" />
            <path fill={secondaryColor} d="M53.65 68.901h-7.563v-24.93h7.562zM49.937 38.86a4.155 4.155 0 1 1 0-8.311 4.017 4.017 0 0 1 4.156 4.155 4.003 4.003 0 0 1-4.156 4.155z" />
        </g>
    </Ic>
);

IC58Info.propTypes = {
    /** The icon's primary color */
    color: colorType,
    /** The icon's secondary color */
    secondaryColor: colorType,
};

IC58Info.displayName = 'IC58Info';

export default IC58Info;
