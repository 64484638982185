/* Lifted and shifted wholly from martian. Could likely do with love. */
/* eslint-disable arrow-body-style */
import React from 'react';
import bacon from 'baconjs';
import identity from 'lodash/identity';
import negate from 'lodash/negate';
import styled from 'styled-components';
import {rgba} from 'polished';

import {isBrowser} from '@fsa-streamotion/browser-utils';
import {palette as infinityPalette} from '@fsa-streamotion/streamotion-web-infinity';
import {animations, palette as ionicPalette} from '@fsa-streamotion/streamotion-web-ionic';
import {palette as muiPalette} from '@fsa-streamotion/streamotion-web-mui';
import {BrandedOR53Veri} from '../../utils/branded-components';
import {stylesWhenBinge, stylesWhenFlash, stylesWhenKayo} from '../../utils/styles-when-brand';

import {trackFromAdobeDefinitions} from '../../../../todo-move-to-widgets-common/utils/adobe';
import requestVerificationCode from '../../../../todo-move-to-widgets-common/streams/endpoints/auth0/request-verification-code';
import validateVerificationCode from '../../../../todo-move-to-widgets-common/streams/endpoints/signup/validate-verification-code';

import {
    progressivelyFormatAndValidateMobileNumber,
    isValidMobileNumber,
    formatMobileNumber,
    internationaliseMobileNumber, localiseMobileNumber,
} from './phone-utils';

const CONFIRMATION_DELAY_MS = 750;
const MOBILE_VERIFICATION_CODE_RESEND_DELAY_MS = 5000;
const {white} = ionicPalette;
const {fog} = infinityPalette;
const {silverLining} = muiPalette;

const PHONE_ALREADY_LINKED = (
    <span>
        This mobile number is already connected to a Hubbl account.
        <a href="/logout">Sign In</a> using your existing Flash, Binge or Kayo Sports email address or click <em>Update Mobile</em>.
    </span>
);

const INCORRECT_CODE = (
    <span>
        The code is incorrect or expired. Please enter the code we sent to your mobile or click <em>Resend Code</em> to receive a new code.
    </span>
);

const StyledBrandedOR53Veri = styled(BrandedOR53Veri)`
    ${stylesWhenBinge`
        border-bottom: 1px solid ${rgba(white, 0.2)};
        padding-bottom: 35px;
    `}

    ${stylesWhenFlash`
        border-bottom: 1px solid ${rgba(fog, 0.2)};
        padding-bottom: 35px;
    `}

    ${stylesWhenKayo`
        border-bottom: 1px solid ${rgba(silverLining, 0.2)};
        padding-bottom: 42px;
    `}
`;

export default function getStepMobileStream({
    offer$,
    user$,
    userDetails$,
    userAccountMainStatus$,
    brand,
    platformEnv,
} = {}) {
    const initialMobileNumber$ = userDetails$
        .first()
        .map('.phoneNumber')
        .map((phoneNumber) => localiseMobileNumber(phoneNumber));

    const needToVerifyMobile$ = bacon.combineTemplate({
        isSignUpDisabled: offer$.map('.disableSignup'),
        userAccountMainStatus: userAccountMainStatus$,
    })
        .flatMapLatest(({isSignUpDisabled, userAccountMainStatus}) => {
            if (isSignUpDisabled) {
                return false;
            }

            return !userAccountMainStatus || userAccountMainStatus === 'INIT';
        })
        .skipDuplicates();

    const editMobileNumberBus = new bacon.Bus();
    const cancelEditMobileNumberBus = new bacon.Bus();
    const submitMobileNumberBus = new bacon.Bus();

    // const validatePhoneNumber = USER_FORM_FIELDS.filter(({name}) => name === 'phoneNumber')[0].validate;

    const mobileNumber$ = initialMobileNumber$
        .combine(user$, (initialMobileNumber, user) => ({initialMobileNumber, user}))
        .flatMapLatest(({initialMobileNumber, user}) => {
            return submitMobileNumberBus
                // Validate mobile number before requesting a verification code.
                .flatMapLatest((mobileNumber) => {
                    // @TODO Validate Mobile Number.
                    const errorMessage = null; // validatePhoneNumber(mobileNumber);

                    return errorMessage ? new bacon.Error({errorMessage}) : mobileNumber;
                })
                .flatMapLatest((mobileNumber) => {
                    return bacon.fromPromise((user.getAccessToken())).flatMapLatest((accessToken) => {
                        return requestVerificationCode({
                            brand,
                            platformEnv,
                            accessToken,
                            mobileNumber: internationaliseMobileNumber(mobileNumber),
                        })
                            .map(mobileNumber)
                            .doAction(saveVerificationCodeRequest);
                    });
                })
                .map(formatMobileNumber)
                .startWith(isValidMobileNumber(initialMobileNumber) ? formatMobileNumber(initialMobileNumber) : '');
        });

    // Split error out from submitted mobile number so we don't propagate mobile number errors further than we need to
    const submittedMobileNumber$ = mobileNumber$
        .skipErrors();
    const submittedMobileNumberError$ = mobileNumber$
        .errors()
        .mapError(identity);

    // Handle verification code input

    const verificationInputBus = new bacon.Bus();

    const verification$ = bacon
        .combineAsArray(
            user$,
            submittedMobileNumber$.filter(Boolean),
            verificationInputBus
        )
        .sampledBy(verificationInputBus)

        // Validate code
        .flatMapLatest(([user, mobileNumber, verificationCode]) => (
            bacon.fromPromise(user.getAccessToken())
                .flatMapLatest((accessToken) => {
                    return validateVerificationCode({
                        platformEnv,
                        accessToken,
                        mobileNumber: internationaliseMobileNumber(mobileNumber),
                        verificationCode,
                    });
                })
        ))
        .doAction(clearSavedVerificationCodeMobileNumber);

    const verificationError$ = verification$
        .errors()
        .mapError('.errorMessage')
        .map((errorMessage) => (
            // @TODO: Update OR53Veri to take errorMessage as propTypes.node
            errorMessage === '[400] Phone is already linked'
                ? PHONE_ALREADY_LINKED
                : INCORRECT_CODE
        ));

    const isVerificationLoading$ = verificationInputBus
        .awaiting(bacon.mergeAll(
            verification$,
            verificationError$
        ));

    const isSuccessful$ = bacon.when(
        [verification$], () => true,
        [verificationError$], () => false,
    )
        .toProperty(false);

    const trackVerificationSuccess$ = isSuccessful$
        .filter(Boolean)
        .first()
        .doAction(() => trackFromAdobeDefinitions({
            definitionsPath: 'eventTracking.signUp.verification',
            eventTypePayloadKey: 'data.event.name',
        }))
        .startWith(null);

    // Handle requests to re-send the verification code to the user

    const resendBus = new bacon.Bus();

    const resend$ = submittedMobileNumber$.combine(user$, (submittedMobileNumber, user) => ({submittedMobileNumber, user}))
        .sampledBy(resendBus)
        .flatMapLatest(({submittedMobileNumber, user}) => {
            return bacon.fromPromise(user.getAccessToken())
                .flatMapLatest((accessToken) => {
                    return requestVerificationCode({
                        brand,
                        platformEnv,
                        accessToken,
                        mobileNumber: internationaliseMobileNumber(submittedMobileNumber),
                    })
                        .map(submittedMobileNumber)
                        .doAction(saveVerificationCodeRequest);
                });
        });

    const resendError$ = resend$
        .errors()
        .mapError('.errorMessage');

    // The calls to this API are unique, delay a bit before allowing user to click on "Resend" button again.
    const isTooSoonSinceLastResend$ = resend$
        .filter(Boolean)
        .delay(isBrowser() ? MOBILE_VERIFICATION_CODE_RESEND_DELAY_MS : 0)
        .map(false);

    const isResendLoading$ = resendBus
        .awaiting(bacon.mergeAll(
            resendError$,
            isTooSoonSinceLastResend$
        ));

    const mobileNumberError$ = bacon.mergeAll(
        // Pass through the error message verbatim
        submittedMobileNumberError$
            .map('.errorMessage'),

        // Clear error messages on submission
        submitMobileNumberBus.map(''),

        // Include resend errors
        resendError$,

        // Clear when resending
        resendBus.map(''),
    )
        .startWith('');

    const isEditingMobileNumber$ = bacon.mergeAll(
        // If we don't have an initial mobile number set, we're initially editing.
        submittedMobileNumber$
            .skipWhile(user$.toProperty().not())
            .map(Boolean)
            .skipDuplicates()
            .filter(negate(Boolean))
            .map(true),

        // We're editing if the edit button was clicked.
        editMobileNumberBus
            .map(true),

        // We're no longer editing if the close button was clicked.
        cancelEditMobileNumberBus
            .map(false),

        // We're no longer editing if the mobile number changed (delayed a bit to show confirmation).
        submittedMobileNumber$
            .filter(Boolean)
            .delay(isBrowser() ? CONFIRMATION_DELAY_MS : 0)
            .map(false),
    )
        .toProperty(false);

    const isSubmittingMobileNumber$ = submitMobileNumberBus.awaiting(
        bacon.mergeAll(
            submittedMobileNumber$.filter(Boolean),
            submittedMobileNumberError$
        )
    );

    const hasSubmittedMobileNumber$ = bacon.mergeAll(
        // Is submitting? false
        isSubmittingMobileNumber$
            .filter(Boolean)
            .map(false),

        // Mobile number change event? true
        submittedMobileNumber$
            .filter(Boolean)
            .map(true),

        // No longer editing? false (after waiting for transition delay)
        isEditingMobileNumber$
            .filter(negate(Boolean))
            .map(false)
            .delay(isBrowser() ? animations.TRANSITION_DURATION_MS : 0),
    )
        .toProperty(false);

    // Send the initial phone number verification request, preventing it being sent on every page refresh.
    const sendInitialVerificationCode$ = submittedMobileNumber$.combine(needToVerifyMobile$, (mobileNumber, needToVerifyMobile) => {
        if (
            needToVerifyMobile
            && isBrowser()
            && mobileNumber
            && isValidMobileNumber(localiseMobileNumber(mobileNumber))
            && !hasRequestedVerificationCode(mobileNumber)
        ) {
            resendBus.push();
        }
    })
        .first(); // the initial mobile number, if valid, will be in this event

    const errorMessage$ = bacon.mergeAll(
        verificationError$,
        verificationInputBus.map(''),
        resendBus.map(''),
        submitMobileNumberBus.map(''),
    )
        .startWith('');

    const verificationCode$ = bacon.update(
        '',

        // Sync to onComplete callback
        [verificationInputBus], (state, value) => value,

        // Clear on error
        [verificationError$.filter(Boolean)], () => ''
    );

    const reactElement = bacon.combineTemplate({
        // The mobile number to display for confirmation and prepopulating the mobile number editor
        mobileNumber: submittedMobileNumber$,

        errorMessage: errorMessage$,
        isResendLoading: isResendLoading$,
        isSuccessful: isSuccessful$,
        isVerificationLoading: isVerificationLoading$,
        lowerHelpText: undefined,
        numberOfDigits: 6,
        verificationCode: verificationCode$,
        onComplete: (code) => void verificationInputBus.push(code),
        onResendClick: () => void resendBus.push(),
        onClickEditMobileNumber: (event) => {
            event.stopPropagation();

            editMobileNumberBus.push();
        },
        isEditingMobileNumber: isEditingMobileNumber$,
        onCancelEditMobileNumber: () => void cancelEditMobileNumberBus.push(),
        onSubmitMobileNumber: (mobileNumber) => void submitMobileNumberBus.push(mobileNumber),
        isSubmittingMobileNumber: isSubmittingMobileNumber$,
        mobileNumberError: mobileNumberError$,
        hasSubmittedMobileNumber: hasSubmittedMobileNumber$,
        processMobileNumberInput: progressivelyFormatAndValidateMobileNumber,
        validateMobileNumber: isValidMobileNumber,
    })
        .combine(needToVerifyMobile$,
            (props, needToVerifyMobile) => needToVerifyMobile && <StyledBrandedOR53Veri brand={brand} {...props} />)
        .startWith(null);

    return bacon.combineTemplate({
        reactElement,
        isCompleted: bacon.mergeAll(
            needToVerifyMobile$.not().startWith(false),
            isSuccessful$.delay(CONFIRMATION_DELAY_MS + animations.TRANSITION_DURATION_MS)
        ),

        sendInitialVerificationCode$: sendInitialVerificationCode$.startWith(null),
        trackVerificationSuccess$,
    });
}

function saveVerificationCodeRequest(mobileNumber) {
    if (isBrowser() && isValidMobileNumber(mobileNumber)) {
        sessionStorage.setItem('verificationCodeSent', internationaliseMobileNumber(mobileNumber));
    }
}

function hasRequestedVerificationCode(mobileNumber) {
    return (
        isBrowser()
        && isValidMobileNumber(mobileNumber)
        && sessionStorage.getItem('verificationCodeSent') === internationaliseMobileNumber(mobileNumber)
    );
}

function clearSavedVerificationCodeMobileNumber() {
    if (isBrowser()) {
        sessionStorage.removeItem('verificationCodeSent');
    }
}
