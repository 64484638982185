import React from 'react';

import Ic from '..';

const IC340RemoveAssetFilled = (args) => (
    <Ic {...args}>
        <path d="M50 9a41 41 0 0 1 41 41c0 22.644-18.356 41-41 41S9 72.644 9 50 27.356 9 50 9zM26.22 46.72l.003 7.354 50.017.026-.003-7.354-50.017-.026z" fillRule="nonzero" />
    </Ic>
);

IC340RemoveAssetFilled.displayName = 'IC340RemoveAssetFilled';

export default IC340RemoveAssetFilled;
