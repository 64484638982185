import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Section} from 'normalized-styled-components';

import {satellite, white, coal} from '../../../../common/palette';

import BA20NavBtn from '../../../atoms/ba/20-nav-btn';
import TM19PmntCc from '../../../molecules/tm/19-pmnt-cc';
import TM19PmntGiftCard from '../../../molecules/tm/19-pmnt-gift-card';
import TM19PmntPp from '../../../molecules/tm/19-pmnt-pp';
import TM19PmntTelstra from '../../../molecules/tm/19-pmnt-telstra';
import GA89Tile from '../../../atoms/ga/89-tile';

const PAYMENT_METHOD_COMPONENT = {
    creditCard: TM19PmntCc,
    paypal: TM19PmntPp,
    tbill: TM19PmntTelstra,
};

const GroupHeading = styled.h3`
    margin: 0 0 14px;
    padding: 0 14px;
    color: ${white};
    font: var(--mui-header-6-bold);
`;

const HeaderTitleContainer = styled.div`
    display: flex;
    padding: 0 14px;
    color: ${satellite};
    font: var(--mui-body-copy-3-bold);
`;

const Group = styled(Section)`
    margin: 0 0 35px;
`;

const Groups = styled.div`
    display: grid;
    grid-row-gap: 7px;
`;

const ItemTitleContainer = styled.div`
    display: flex;
    font: var(--mui-body-copy-3);
`;

const PaymentPart = styled.div`
    flex: 1 1 0%;
    padding-right: 7px;
`;

const PaymentDate = PaymentPart;

const PaymentAmount = styled(PaymentPart)`
    text-align: right;
`;

const PaymentRowContainer = styled(GA89Tile)`
    margin: 7px 0;
`;

const NoPaymentHistory = styled.p`
    text-align: center;
    color: ${satellite};
    font: var(--mui-body-copy-3);
`;

const Title = ({amount, date}) => (
    <React.Fragment>
        <PaymentDate>
            {date}
        </PaymentDate>
        <PaymentAmount>
            {amount}
        </PaymentAmount>
    </React.Fragment>
);

Title.displayName = 'Title';

Title.propTypes = {
    amount: propTypes.string,
    date: propTypes.string,
};

const BillingTemplate = ({
    currencyPrefix,
    currencyDecimalPlaces,
    paymentMethod = {},
    accountBalance,
    onClickChangePaymentMethod,
    paymentHistory = [],
}) => {
    const {details, method} = paymentMethod;
    const PaymentMethod = PAYMENT_METHOD_COMPONENT[method];

    return (
        <Groups>
            <Group>
                <GroupHeading>
                    Payment method
                </GroupHeading>

                <Groups>
                    {!!accountBalance && (
                        <TM19PmntGiftCard
                            currencyPrefix={currencyPrefix}
                            currencyDecimalPlaces={currencyDecimalPlaces}
                            balance={accountBalance}
                        />
                    )}

                    {!!PaymentMethod && (
                        <PaymentMethod {...details} />
                    )}

                    <BA20NavBtn
                        label={`${PaymentMethod ? 'Change' : 'Add'} payment method`}
                        onClick={onClickChangePaymentMethod}
                    />
                </Groups>
            </Group>

            <Group>
                <GroupHeading>
                    Your invoice history
                </GroupHeading>

                {paymentHistory.length ? (
                    <React.Fragment>
                        <HeaderTitleContainer>
                            <Title amount="Total" date="Date" method="Method" />
                        </HeaderTitleContainer>

                        {paymentHistory.map(({amount, date, method}, index) => (
                            <PaymentRowContainer key={index} backgroundColor={coal} color={white}>
                                <ItemTitleContainer>
                                    <Title {...{amount, date, method}} />
                                </ItemTitleContainer>
                            </PaymentRowContainer>
                        ))}
                    </React.Fragment>
                ) : (
                    <NoPaymentHistory>
                        No invoices to display
                    </NoPaymentHistory>
                )}
            </Group>
        </Groups>
    );
};

BillingTemplate.displayName = 'BillingTemplate';

BillingTemplate.propTypes = {
    /** Currency prefix (defaults to $) */
    currencyPrefix: propTypes.string,
    /** Currency decimal places (defaults to 2) */
    currencyDecimalPlaces: propTypes.number,

    /** Current account balance (e.g. from gift cards) */
    accountBalance: propTypes.number,

    paymentMethod: propTypes.shape({
        method: propTypes.oneOf(['creditCard', 'paypal', 'tbill']),
        details: propTypes.oneOfType([
            // if method === 'creditCard'
            propTypes.shape({
                type: propTypes.oneOf(['mastercard', 'visa']),
                number: propTypes.string,
                expiry: propTypes.string,
            }),
            // if method === 'paypal'
            propTypes.shape({
                email: propTypes.string,
                name: propTypes.string,
            }),
            // if method === 'tbill'
            propTypes.shape({
                name: propTypes.string,
            }),
        ]),
    }),
    onClickChangePaymentMethod: propTypes.func,
    paymentHistory: propTypes.arrayOf(propTypes.shape({
        amount: propTypes.string,
        date: propTypes.string,
        description: propTypes.string,
        method: propTypes.string,
    })),
};

export default BillingTemplate;
