import React from 'react';

import Ic from '..';

const IC221NextUp = (args) => (
    <Ic {...args}>
        <path d="M33.4 35.8v27.916L52.006 49.8 33.4 35.8M25 19l41.006 30.8L25 80.516V19zm46.018 0h4.2v61.516h-4.2z" />
    </Ic>
);

IC221NextUp.displayName = 'IC221NextUp';

export default IC221NextUp;
