import React, {useState} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import noop from 'lodash/noop';

import {stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';

const TARGET_TIMEOUT_MS = 3_000;

const ALIGNMENT_FLEX = {
    center: 'center',
    right: 'flex-end',
};

const Wrapper = styled.div`
    position: relative;
`;

const LoadingWrapper = styled.div`
    position: absolute;
    inset: 0;
`;

const ChildrenWrapper = styled.div`
    display: flex;
    justify-content: ${({alignment}) => ALIGNMENT_FLEX[alignment] || 'flex-start'};

    ${stylesWhenNot('isTargetComplete')`
        visibility: hidden;
    `}
`;

export default function TargetAemWrapper({
    alignment,
    className,
    loadingEl,
    moduleId, // need this to define the per-fragment mbox
    adobeTargetParams,
    render = noop,
    ...restProps
}) {
    const [isTargetComplete, setIsTargetComplete] = useState(false);
    const mbox = `target-${moduleId}-mbox`;
    const {loginStatus, userSubStatus} = adobeTargetParams;

    async function onReadyForTarget({selector = `#${mbox}`} = {}) {
        // Only try to getOffer and applyOffer if we have a moduleId, and Adobe Target is on the page
        if (!moduleId) {
            console.error('TARGET-AEM-WRAPPER: No module ID defined!');

            return void setIsTargetComplete(true);
        } else if (!window?.adobe?.target) {
            console.warn('TARGET-AEM-WRAPPER: Adobe Target not found on the page');

            return void setIsTargetComplete(true);
        }

        await window.adobe.target.getOffer({
            mbox,
            params: {
                loginStatus,
                bingeStatus: userSubStatus?.bingeStatus,
                kayoStatus: userSubStatus?.kayoStatus,
                flashStatus: userSubStatus?.flashStatus,
                lifestyleStatus: userSubStatus?.lifestyleStatus,
            },
            success(offer) {
                if (offer?.length) {
                    window.adobe.target.applyOffer({
                        mbox,
                        offer,
                        selector,
                    });
                }

                setIsTargetComplete(true); // offers applied, unhide
            },
            error(status, error) {
                console.error('TARGET-AEM-WRAPPER: Adobe Target has encountered an error', status, error);

                setIsTargetComplete(true);
            },
            timeout: TARGET_TIMEOUT_MS,
        });
    }

    return (
        <Wrapper
            {...restProps}
            className={classnames('TargetAemWrapper', className)}
            id={moduleId ? mbox : undefined}
        >
            <LoadingWrapper {...isTargetComplete && {hidden: true}}>
                {loadingEl}
            </LoadingWrapper>
            <ChildrenWrapper isTargetComplete={isTargetComplete} alignment={alignment}>
                {render({onReadyForTarget})}
            </ChildrenWrapper>
        </Wrapper>
    );
}

TargetAemWrapper.propTypes = {
    /** The module alignment (left, right, center) */
    alignment: propTypes.oneOf(['left', 'center', 'right', null]),

    /** Additional CSS class(es) */
    className: propTypes.string,

    /** Initial loading element to show until Adobe Target has finished mutations */
    loadingEl: propTypes.node,

    /** ID of the module from endpoint, used here for mbox targeting */
    moduleId: propTypes.string.isRequired,

    /** Adobe Target params*/
    adobeTargetParams: propTypes.object,

    /** render prop, will be invoked with `onReadyForTarget`, which the child can use to communicate to this component when it's ready for target */
    render: propTypes.func,
};

TargetAemWrapper.displayName = 'TargetAemWrapper';
