import React from 'react';
import Ic from '..';

const IC105Home = (args) => (
    <Ic {...args}>
        <path d="m53.51 12.046 20.424 14.759.001-12.174c0-1.16.94-2.096 2.1-2.096 1.16 0 2.099.936 2.099 2.096v14.616c0 .188-.025.37-.07.542l7.59 5.484a6.73 6.73 0 0 1 3.068 5.67v41.975a4.673 4.673 0 0 1-4.656 4.666l-23.648.068h-.017a3.417 3.417 0 0 1-2.414-.997 3.413 3.413 0 0 1-1.01-2.428V52.128H42.732l.013 32.17a3.437 3.437 0 0 1-3.415 3.432l-23.647.068h-.014a4.638 4.638 0 0 1-3.3-1.367 4.622 4.622 0 0 1-1.37-3.3V41.157c0-2.31 1.16-4.432 3.103-5.687l32.01-23.339c2.314-1.492 5.18-1.496 7.397-.085zM47.88 26.75 15.814 50.13a3.989 3.989 0 0 0-.62.494v32.508c0 .16.075.272.14.333.064.064.298.176.335.139l22.881-.065V52.6c0-2.13 1.048-4.666 6.04-4.666h10.71c4.853 0 5.87 2.445 5.87 4.497v31.024l22.885-.062a.471.471 0 0 0 .471-.474V50.394a3.728 3.728 0 0 0-.584-.462L51.807 26.71c-1.164-.74-2.724-.736-3.928.04zm2.029-11.576c-.485 0-.99.136-1.425.417l-32.01 23.34c-.838.545-1.279 1.352-1.279 2.227v7.006l31.579-23.028c1.892-1.218 4.283-1.224 6.128-.044l31.624 22.852.001-7c0-.885-.44-1.692-1.183-2.167l-.102-.067L51.15 15.516a2.337 2.337 0 0 0-1.241-.343z" />
    </Ic>
);

IC105Home.displayName = 'IC105Home';

export default IC105Home;
