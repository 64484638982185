import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Svg} from 'normalized-styled-components';

import {colorType} from '@fsa-streamotion/custom-prop-types';

const StyledSvg = styled(Svg)`
    display: block;
    width: auto;
    height: 100%; /* Scaled by HEIGHT :O */
`;

const GA126Streamotion = ({
    title = 'Streamotion',
    primaryColor = '#EF5353',
    secondaryColor = '#334A55',
    ...htmlAttributes
}) => (
    <StyledSvg
        {...htmlAttributes}
        viewBox="0 0 233 28"
    >
        <title>{title}</title>

        <path fill={primaryColor} d="M.368 8.032c0 8.508 12.2 7.772 12.2 11.897 0 1.437-1.558 2.32-3.4 2.32-1.804 0-3.571-.736-5.67-2.467L0 24.275c3.093 2.505 5.965 3.61 9.168 3.61 5.23 0 9.28-3.684 9.28-7.956 0-8.509-11.783-7.772-11.783-11.898 0-1.326 1.141-2.136 2.688-2.136 1.546 0 3.092.626 4.897 2.1L17.711 3.5C14.913 1.217 12.261.26 9.353.26 4.418.26.368 3.87.368 8.032zM89.444.628l-11.967 26.52h5.965l1.363-2.983h9.279l1.362 2.984h4.375v.008h1.988V5.337l10.67 11.333 10.634-11.333v21.82h1.987V.665h-.331l-12.29 13.136L100.152.665h-.331v22.228L89.775.628h-.331zm0 13.224l2.246 5.01h-4.492l2.246-5.01zM61.622.997v26.152h14.802v-5.635h-8.837V16.32h8.1v-5.635h-8.1V6.633h8.837V.997H61.622zm-21.82 0v26.152h5.966V15.62l7.88 11.529h7.044l-6.677-9.172c2.651-1.62 4.272-4.235 4.272-7.219 0-6.262-4.898-9.761-11.157-9.761h-7.327zM45.768 6.3h1.362c2.835 0 4.86 1.621 4.86 4.458 0 2.689-1.767 4.494-5.117 4.494h-1.105V6.3zM18.988.997v5.636h6.627v20.516h5.965V6.633h6.628V.997h-19.22z" />
        <path fill={secondaryColor} d="M211.659.547V27.04h1.987V4.814l14.645 13.725V.914h-1.988V13.94L211.99.547h-.331zm-29.244 13.43c0 7.396 6.035 13.43 13.431 13.43 7.396 0 13.43-6.034 13.43-13.43S203.242.547 195.846.547s-13.43 6.034-13.43 13.43zm1.988 0c0-6.366 5.15-11.554 11.443-11.554 6.292 0 11.444 5.188 11.443 11.554 0 6.366-5.151 11.554-11.443 11.554s-11.443-5.187-11.443-11.554zm-57.758.085c0 7.396 6.035 13.431 13.43 13.431 7.397 0 13.43-6.035 13.43-13.43 0-7.397-6.033-13.431-13.43-13.431-7.395 0-13.43 6.034-13.43 13.43zm1.987 0c0-6.366 5.151-11.554 11.443-11.554 6.293 0 11.444 5.188 11.444 11.554 0 6.366-5.152 11.554-11.444 11.554-6.291 0-11.443-5.188-11.443-11.554zM230.277.914v22.262L215.634 9.451V27.04h1.987V14.05l14.313 13.358h.332V.914h-1.988zm-52.018 26.21h1.987V1.001h-1.987v26.123zm-3.974 0h1.987V1.001h-1.987v26.123zM152.967 2.877h19.207V1h-19.207v1.876zm33.422 11.101c0 5.299 4.268 9.677 9.457 9.677 5.188 0 9.457-4.378 9.457-9.677 0-5.298-4.269-9.677-9.457-9.677-5.188 0-9.457 4.379-9.457 9.677zm1.987 0c0-4.452 3.349-7.8 7.47-7.8 4.12 0 7.47 3.348 7.47 7.8s-3.35 7.8-7.47 7.8c-4.12 0-7.47-3.347-7.47-7.8zm-57.756.085c0 5.299 4.267 9.677 9.455 9.677 5.189 0 9.457-4.378 9.457-9.677 0-5.298-4.268-9.677-9.457-9.677-5.188 0-9.455 4.379-9.455 9.677zm1.986 0c0-4.452 3.348-7.8 7.47-7.8 4.12 0 7.47 3.348 7.47 7.8s-3.35 7.8-7.47 7.8c-4.122 0-7.47-3.348-7.47-7.8zm30.958-9.31v22.373h1.987V6.629h6.623V4.754h-8.61zm-10.597 0V6.63h6.624v20.496h1.986V4.753h-8.61zM112.48 19.512l-8.684-9.238v16.853h1.987V15.279l6.513 6.918h.331l6.513-6.918v11.849h1.987V10.274l-8.647 9.236z" />
    </StyledSvg>
);

GA126Streamotion.propTypes = {
    /** Accessible label for logo */
    title: propTypes.string,
    /** Optional color of `STREAM` */
    primaryColor: colorType,
    /** Optional color of `MOTION` */
    secondaryColor: colorType,
};

GA126Streamotion.displayName = 'GA126Streamotion';

export default GA126Streamotion;
