import React from 'react';
import Ic from '..';

const IC238More = (args) => (
    <Ic {...args}>
        <path
            d="M86.225 46.527c1.577 1.593 2.427 3.804 2.34 6.082.027.823-.042 1.833-.042 1.833H76.778a3.974 3.974 0 001.369 2.693c.787.67 1.802.985 2.816.872a8.988 8.988 0 004.951-1.63l2.107 2.886c-2.158 1.947-4.967 2.941-7.825 2.771-5.091 0-8.801-3.262-8.801-8.905 0-5.644 4.017-8.877 8.898-8.877 2.2-.144 4.355.682 5.932 2.275zm-36.701-2.274c5.244 0 9.136 3.392 9.136 8.906 0 5.513-3.92 8.876-9.136 8.876-5.217 0-9.178-3.363-9.178-8.876 0-5.514 3.934-8.906 9.178-8.906zm22.191.318l-.265 5.095h-.237a4.134 4.134 0 00-1.939-.375 3.14 3.14 0 00-2.482 1.24 6.292 6.292 0 00-.837 3.566v7.592h-5.356V44.57h5.356v2.425c.694-1.692 2.311-2.777 4.087-2.743a3.698 3.698 0 011.673.318zm-50.952-6.828l4.826 15.732h.07l4.756-15.732h6.89l1.674 23.901h-5.314l-.907-16.28h-.07l-4.949 16.279h-4.283l-4.994-16.279h-.098l-.837 16.279h-5.328l1.744-23.9h6.82zm28.761 10.883c-2.371 0-3.752 1.905-3.752 4.43 0 2.526 1.395 4.432 3.752 4.432 2.357 0 3.752-1.906 3.752-4.431 0-2.526-1.409-4.431-3.752-4.431zm30.7-.78a3.306 3.306 0 00-2.394.978 3.543 3.543 0 00-1.024 2.443l6.793.014a3.48 3.48 0 00-.988-2.454 3.245 3.245 0 00-2.387-.98z"
            fillRule="nonzero"
        />
    </Ic>
);

IC238More.displayName = 'IC238More';

export default IC238More;
