const BRAND_TO_ACCOUNT_PRODUCT_KEY = {
    martian: 'kayo',
    kayo: 'kayo',
    ares: 'ares',
    binge: 'ares',
    flash: 'flash',
    lifestyle: 'lifestyle',
};

/**
 * For v2 token product level account status key
 *
 * @param {string} brand    - String that resembles a brand e.g. martian, ares
 * @returns {string}        - Normalised brand string e.g. binge, kayo, flash
 */
export default function getAccountProductKey(brand) {
    const lowerCaseBrand = brand?.toLowerCase();

    const productKey = BRAND_TO_ACCOUNT_PRODUCT_KEY[lowerCaseBrand];

    if (!productKey) {
        console.warn(`[getAccountProductKey] Brand '${brand}' is not recognized!`);

        return lowerCaseBrand;
    }

    return productKey;
}
