import React from 'react';
import Ic from '..';

const IC231Profiles = (args) => (
    <Ic {...args}>
        <path d="M71.554 36.464l-.42-.098c-3.92-.91-8.4-1.932-9.156-3.738-.247-.91-.066-1.884.49-2.646 5.138-9.464 6.104-17.808 2.8-23.478C62.776 2.518 58.33.185 53.634.4 49.002.207 44.619 2.502 42.14 6.42c-3.374 5.6-2.422 14 2.646 23.548.535.766.708 1.727.476 2.632-.826 1.806-5.334 2.8-9.296 3.766h-.252c-7.63 1.764-7.602 6.286-7.56 12.012v2.8l7.168-.672v-1.792c0-4.788 0-4.788 2.268-5.32 5.852-1.4 11.9-2.8 14.196-7.77 1.263-2.933 1.017-6.297-.658-9.016-3.738-7-4.802-13.23-2.8-16.492 1.22-1.711 3.236-2.674 5.334-2.548 2.122-.109 4.15.887 5.362 2.632 1.974 3.304.896 9.436-2.8 16.38-1.698 2.707-1.97 6.072-.728 9.016 2.296 5.068 8.288 6.44 14.098 7.784 2.114.49 2.394.728 2.408 3.92l7.098-.7c-.07-4.858-.91-8.61-7.546-10.136zM0 50.002c0-3.962.238-6.202 4.984-7.35h.21c3.962-.924 7.7-1.778 8.708-3.99.446-1.193.29-2.527-.42-3.584C9.59 27.672 8.806 21.4 11.27 17.27c1.723-2.763 4.812-4.372 8.064-4.2 5.782 0 9.506 3.864 9.506 9.8-.139 3.769-1.176 7.45-3.024 10.738l-.07.14-.56 1.176-.182.406H21l.392-.966c.252-.63.574-1.4.938-2.142 1.792-3.906 4.2-9.184 1.918-13.034-1.1-1.59-2.941-2.5-4.872-2.408-1.9-.085-3.714.8-4.816 2.352-1.736 2.884-.882 7.994 2.324 14 1.309 2.108 1.502 4.723.518 7-1.092 2.38-3.304 3.92-7.392 5.11-1.316.378-2.324.616-3.15.798-2.968.686-3.052.812-3.01 4.466v5.6L0 56.54v-6.538z" transform="translate(10 21)" />
    </Ic>
);

IC231Profiles.displayName = 'IC231Profiles';

export default IC231Profiles;
