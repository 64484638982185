import React from 'react';
import Ic from '..';

const IC234Home = (args) => (
    <Ic {...args}>
        <g transform="translate(14.762 12.952)">
            <path opacity=".2" d="M30.39 34.25h9.517c3.817 0 6.922 3.093 6.922 6.896v23.688a3.676 3.676 0 0 0 3.679 3.666h11.546c2.756 0 4.999-2.235 4.999-4.98V26.033a4.959 4.959 0 0 0-2.23-4.146L37.915 4.066a5.01 5.01 0 0 0-5.536 0L5.473 21.887a4.962 4.962 0 0 0-2.23 4.146V63.52c0 2.744 2.242 4.979 4.999 4.979h11.546c2.029 0 3.68-1.644 3.68-3.666V41.146c0-3.803 3.104-6.895 6.922-6.895" />
            <path d="M30.39 34.25h9.517c3.817 0 6.922 3.093 6.922 6.896v23.688a3.676 3.676 0 0 0 3.679 3.666h11.546c2.756 0 4.999-2.235 4.999-4.98V26.033a4.959 4.959 0 0 0-2.23-4.146L37.915 4.066a5.01 5.01 0 0 0-5.536 0L5.473 21.887a4.962 4.962 0 0 0-2.23 4.146V63.52c0 2.744 2.242 4.979 4.999 4.979h11.546c2.029 0 3.68-1.644 3.68-3.666V41.146c0-3.803 3.104-6.895 6.922-6.895m31.664 37.48H50.508c-3.817 0-6.922-3.093-6.922-6.897V41.146a3.675 3.675 0 0 0-3.679-3.665H30.39a3.675 3.675 0 0 0-3.68 3.665v23.688c0 3.804-3.104 6.896-6.922 6.896H8.242C3.697 71.73 0 68.048 0 63.52V26.033a8.181 8.181 0 0 1 3.679-6.836l26.906-17.82a8.248 8.248 0 0 1 9.125 0l26.907 17.819a8.18 8.18 0 0 1 3.679 6.836V63.52c0 4.527-3.698 8.21-8.242 8.21" />
        </g>
    </Ic>
);

IC234Home.displayName = 'IC234Home';

export default IC234Home;
