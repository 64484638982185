import React from 'react';
import propTypes from 'prop-types';
import {Progress} from 'normalized-styled-components';
import styled from 'styled-components';
import {rgba} from 'polished';

import {blanc} from '../../../../common/palette';
import theme from '../../../../common/theme-color';

const StyledProgress = styled(Progress)`
    appearance: none;
    display: block;
    border: 0;
    background-color: ${rgba(blanc, 0.2)};
    width: 100%;
    height: 4px;

    &::-moz-progress-bar {
        background-color: ${({isThemed}) => isThemed ? theme.primaryColor : blanc};
    }

    &::-webkit-progress-value {
        background-color: ${({isThemed}) => isThemed ? theme.primaryColor : blanc};
    }

    &::-webkit-progress-bar {
        background-color: inherit;
    }

    &::-ms-fill {
        border: none;
        background-color: ${({isThemed}) => isThemed ? theme.primaryColor : blanc};
    }
`;

const GA01ProBar = ({
    current = 0,
    total = 100,
    isThemed,
    ...htmlAttributes
}) => (
    <StyledProgress
        value={current}
        max={total}
        isThemed={isThemed}
        {...htmlAttributes}
    />
);

GA01ProBar.displayName = 'GA01ProBar';

GA01ProBar.propTypes = {
    /** Current value of progress bar, e.g. elapsed time */
    current: propTypes.number,
    /** Total value of progress bar, e.g. video duration */
    total: propTypes.number,
    /* Is global theme to be applied */
    isThemed: propTypes.bool,
};

export default GA01ProBar;
