import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import property from 'lodash/property';
import range from 'lodash/range';
import {mediaQuery, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {SCREEN_LG_DESKTOP, SCREEN_PRETTY_LG_DESKTOP} from '../../../../common/screen-sizes';

const ImageList = styled.ul`
    position: relative;
    margin: 0;
    padding: 0;
    height: 480px;
    overflow-y: hidden;
    list-style: none;

    ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
        height: 600px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_PRETTY_LG_DESKTOP})`
        height: 900px;
    `}
`;

const ImageListItem = styled.li`
    position: absolute;
    top: 0;
    transition: opacity ${property('durationMs')}ms ease-in ${({isActive, durationMs}) => isActive ? 0 : Math.round(durationMs / 2)}ms;
    z-index: ${({isActive}) => isActive ? 1 : 0};
    width: 100%;
    height: 100%;

    ${stylesWhenNot('isActive')`
        opacity: 0;
    `}

    @media (prefers-reduced-motion: reduce) {
        transition: reset;
    }
`;

export default class GM07SlidingPictures extends React.Component {
    static displayName = 'GM07SlidingPictures';

    static propTypes = {
        /** The components to cycle through */
        children: propTypes.node,
        /** How long sides are shown for (milliseconds) */
        slideDurationMs: propTypes.number,
        /** How long slides take to fade in/out (milliseconds) */
        transitionDurationMs: propTypes.number,
        /** additional CSS classnames to be applied */
        className: propTypes.string,
    };

    static defaultProps = {
        slideDurationMs: 5 * 1000,
        transitionDurationMs: 500,
    };

    state = {
        // Which slide is currently being shown
        activeSlide: 0,
        // Which slides should we load (we use this to avoid fetching images for slides we're not close to showing yet)
        // We always load the current image, and shortly after mounting we'll pre-fetch the second slide
        // From there, we'll always load one slide ahead
        shouldLoadSlide: range(React.Children.count(this.props.children)).map((index) => index === 0),
    };

    componentDidMount() {
        // Start prefetching the second image shortly after mounting (but not straight away - let other images on the page take precedence)
        this.prefetchSecondSlideTimeout = React.Children.count(this.props.children) > 1
            ? setTimeout(() => {
                this.setState(({shouldLoadSlide}) => ({
                    shouldLoadSlide: Object.assign(shouldLoadSlide, {1: true}),
                }));
            }, Math.min(1000, this.props.slideDurationMs / 3)) // Wait either one second or a third of a transition, whichever is shorter
            : null;

        this.slideInterval = React.Children.count(this.props.children) > 1
            ? setInterval(() => {
                this.setState(({activeSlide, shouldLoadSlide}, {children}) => {
                    // Intentionally recomputing on each loop in case our children count changes over time
                    const numSlides = React.Children.count(children);
                    // We're going to show this slide now
                    const nextSlide = (activeSlide + 1) % numSlides;
                    // We should start loading this slide image now, if we haven't already
                    const nextNextSlide = (activeSlide + 2) % numSlides;

                    return {
                        activeSlide: nextSlide,
                        shouldLoadSlide: Object.assign(shouldLoadSlide, {[nextNextSlide]: true}),
                    };
                });
            }, this.props.slideDurationMs)
            : null;
    }

    componentWillUnmount() {
        clearTimeout(this.prefetchSecondSlideTimeout);
        clearInterval(this.slideInterval);
    }

    render() {
        return (
            <ImageList className={classnames('GM07SlidingPictures', this.props.className)}>
                {React.Children.map(this.props.children, (child, index) => (
                    <ImageListItem isActive={this.state.activeSlide === index} durationMs={this.props.transitionDurationMs}>
                        {/* We skip showing some slides to avoid pre-loading too many images that we don't need just yet */}
                        {this.state.shouldLoadSlide[index] && child}
                    </ImageListItem>
                ))}
            </ImageList>
        );
    }
}
