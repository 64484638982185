import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {mediaQuery, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {midnight, white} from '../../../../common/palette';
import {SCREEN_TABLET} from '../../../../common/screen-sizes';

const ListContainer = styled.section`
    margin: 0 auto;
    width: 100%;
    text-align: center;
    color: ${({isLightTheme}) => isLightTheme ? midnight : white};
`;

const List = styled.dl`
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
`;

const ListTitle = styled.h1`
    margin: 0 0 6px;
    font: var(--mui-body-copy-3);

    ${stylesWhenNot('theme.isAppDock')`
        ${mediaQuery({minWidthPx: SCREEN_TABLET})`
            margin-bottom: 9px;
        `}
    `}
`;

const XX04ScreenOptions = ({
    isLightTheme,
    listTitle,
    className,
    children,
}) => (
    <ListContainer
        className={classnames('XX04ScreenOptions', className)}
        isLightTheme={isLightTheme}
    >
        {!!listTitle && (
            <ListTitle>
                {listTitle}
            </ListTitle>
        )}
        <List>
            {children}
        </List>
    </ListContainer>
);

XX04ScreenOptions.displayName = 'XX04ScreenOptions';

XX04ScreenOptions.propTypes = {
    /** Light or dark (default) theme */
    isLightTheme: propTypes.bool,
    /** Optional title that is associated with the list */
    listTitle: propTypes.string,
    /** List items */
    children: propTypes.node,
    /** Optional additional class(es) */
    className: classNameType,
};

export default XX04ScreenOptions;
