import {createElement} from 'react';

import {
    Branding as Branding__ionic,
    BA01BtnPr as BA01BtnPr__ionic,
    BA08BtnSec as BA08BtnSec__ionic,
    BA22EditBtn as BA22EditBtn__ionic,
    BA30TextBtn as BA30TextBtn__ionic,
    BA43CatTile as BA43CatTile__ionic,
    BA45BtnSecSml as BA45BtnSecSml__ionic,
    BA46BtnPrMarketing as BA46BtnPrMarketing__ionic,
    CA02FeatureStacked as CA02FeatureStacked__ionic,
    CAM01Stand as CAM01Stand__ionic,
    CAM03Finite as CAM03Finite__ionic,
    CA01Feature as CA01Feature__ionic,
    CM03FeatureList as CM03FeatureList__ionic,
    CM04FeatureBlock as CM04FeatureBlock__ionic,
    GA05BgImg as GA05BgImg__ionic,
    GA06PostImg as GA06PostImg__ionic,
    GA07TileImg as GA07TileImg__ionic,
    GA92BrandLogo as GA92BrandLogo__ionic,
    GA149WordmarkOnWhite as GA149WordmarkOnWhite__ionic,
    GM07SlidingPictures as GM07SlidingPictures__ionic,
    IC25ArrowR as IC25ArrowR__ionic,
    IC31Tick as IC31Tick__ionic,
    IC42Error as IC42Error__ionic,
    IC43Valid as IC43Valid__ionic,
    IC50Success as IC50Success__ionic,
    IC103Loading as IC103Loading__ionic,
    IM01Input as IM01Input__ionic,
    NM10Footer as NM10Footer__ionic,
    OR08MarBlk as OR08MarBlk__ionic,
    OR12VchInp as OR12VchInp__ionic,
    OR16MarBlk as OR16MarBlk__ionic,
    OR17AccBlk as OR17AccBlk__ionic,
    OR49SumPnl as OR49SumPnl__ionic,
    OR52TabPanels as OR52TabPanels__ionic,
    OR53Veri as OR53Veri__ionic,
    OR56Modal as OR56Modal__ionic,
    OR117GiftCheckout as OR117GiftCheckout__ionic,
    OR120PictureCar as OR120PictureCar__ionic,
    OR128StreamotionBlk as OR128StreamotionBlk__ionic,
    OR129MarBlk as OR129MarBlk__ionic,
    TabbedContentCarouselWrapper as TabbedContentCarouselWrapper__ionic,
    TM14ShowTil as TM14ShowTil__ionic,
    TM19PmntCc as TM19PmntCc__ionic,
    TM19PmntTelstra as TM19PmntTelstra__ionic,
    TM24AlertTile as TM24AlertTile__ionic,
    DevicesCarouselWrapper as DevicesCarouselWrapper__ionic,
    FeaturesCarouselWrapper as FeaturesCarouselWrapper__ionic,
    MarketingBlockWrapper as MarketingBlockWrapper__ionic,
    MarketingLinkWrapper as MarketingLinkWrapper__ionic,
    QuestionsAccordionWrapper as QuestionsAccordionWrapper__ionic,
    StreamotionBlurbWrapper as StreamotionBlurbWrapper__ionic,
} from '@fsa-streamotion/streamotion-web-ionic';

import {
    Branding as Branding__infinity,
    BA01BtnPr as BA01BtnPr__infinity,
    BA28BtnSec as BA28BtnSec__infinity,
    BA22EditBtn as BA22EditBtn__infinity,
    BA30TextBtn as BA30TextBtn__infinity,
    BA43CatTile as BA43CatTile__infinity,
    BA46BtnPrMarketing as BA46BtnPrMarketing__infinity,
    CA02FeatureStacked as CA02FeatureStacked__infinity,
    CAM01Stand as CAM01Stand__infinity,
    CAM03Finite as CAM03Finite__infinity,
    CA01Feature as CA01Feature__infinity,
    CM03FeatureList as CM03FeatureList__infinity,
    CM04FeatureBlock as CM04FeatureBlock__infinity,
    GA05BgImg as GA05BgImg__infinity,
    GA06PostImg as GA06PostImg__infinity,
    GA07TileImg as GA07TileImg__infinity,
    GA92BrandLogo as GA92BrandLogo__infinity,
    GM07SlidingPictures as GM07SlidingPictures__infinity,
    IC25ArrowR as IC25ArrowR__infinity,
    IC31Tick as IC31Tick__infinity,
    IC32Success as IC32Success__infinity,
    IC42Error as IC42Error__infinity,
    IC43Valid as IC43Valid__infinity,
    IC103Loading as IC103Loading__infinity,
    IM01Input as IM01Input__infinity,
    NM10Footer as NM10Footer__infinity,
    OR08MarBlk as OR08MarBlk__infinity,
    OR12VchInp as OR12VchInp__infinity,
    OR16MarBlk as OR16MarBlk__infinity,
    OR17AccBlk as OR17AccBlk__infinity,
    OR49SumPnl as OR49SumPnl__infinity,
    OR52TabPanels as OR52TabPanels__infinity,
    OR53Veri as OR53Veri__infinity,
    OR56Modal as OR56Modal__infinity,
    OR117GiftCheckout as OR117GiftCheckout__infinity,
    OR120PictureCar as OR120PictureCar__infinity,
    OR128StreamotionBlk as OR128StreamotionBlk__infinity,
    OR129MarBlk as OR129MarBlk__infinity,
    // TabbedContentCarouselWrapper as TabbedContentCarouselWrapper__infinity,
    TM14ShowTil as TM14ShowTil__infinity,
    TM19PmntCc as TM19PmntCc__infinity,
    TM19PmntTelstra as TM19PmntTelstra__infinity,
    TM24AlertTile as TM24AlertTile__infinity,
    DevicesCarouselWrapper as DevicesCarouselWrapper__infinity,
    FeaturesCarouselWrapper as FeaturesCarouselWrapper__infinity,
    // MarketingBlockWrapper as MarketingBlockWrapper__infinity,
    MarketingLinkWrapper as MarketingLinkWrapper__infinity,
    QuestionsAccordionWrapper as QuestionsAccordionWrapper__infinity,
    StreamotionBlurbWrapper as StreamotionBlurbWrapper__infinity,
} from '@fsa-streamotion/streamotion-web-infinity';

import {
    GA92BrandLogo__Branding as Branding__mui,
    BA01BtnPr as BA01BtnPr__mui,
    BA08BtnSec as BA08BtnSec__mui,
    BA22EditBtn as BA22EditBtn__mui,
    BA30TextBtn as BA30TextBtn__mui,
    BA43CatTile as BA43CatTile__mui,
    BA46BtnPrMarketing as BA46BtnPrMarketing__mui,
    CA02FeatureStacked as CA02FeatureStacked__mui,
    CAM01Stand as CAM01Stand__mui,
    CAM03Finite as CAM03Finite__mui,
    CA01Feature as CA01Feature__mui,
    CM03FeatureList as CM03FeatureList__mui,
    CM04FeatureBlock as CM04FeatureBlock__mui,
    GA05BgImg as GA05BgImg__mui,
    GA06PostImg as GA06PostImg__mui,
    GA07TileImg as GA07TileImg__mui,
    GA92BrandLogo as GA92BrandLogo__mui,
    GM07SlidingPictures as GM07SlidingPictures__mui,
    IC25ArrowR as IC25ArrowR__mui,
    IC31Tick as IC31Tick__mui,
    IC42Error as IC42Error__mui,
    IC50Success as IC50Success__mui,
    IC103Loading as IC103Loading__mui,
    IM01Input as IM01Input__mui,
    NM10Footer as NM10Footer__mui,
    OR08MarBlk as OR08MarBlk__mui,
    OR12VchInp as OR12VchInp__mui,
    OR16MarBlk as OR16MarBlk__mui,
    OR17AccBlk as OR17AccBlk__mui,
    OR49SumPnl as OR49SumPnl__mui,
    OR52TabPanels as OR52TabPanels__mui,
    OR53Veri as OR53Veri__mui,
    OR56Modal as OR56Modal__mui,
    OR117GiftCheckout as OR117GiftCheckout__mui,
    OR120PictureCar as OR120PictureCar__mui,
    OR128StreamotionBlk as OR128StreamotionBlk__mui,
    OR129MarBlk as OR129MarBlk__mui,
    TabbedContentCarouselWrapper as TabbedContentCarouselWrapper__mui,
    TM14ShowTil as TM14ShowTil__mui,
    TM19PmntCc as TM19PmntCc__mui,
    TM19PmntTelstra as TM19PmntTelstra__mui,
    TM24AlertTile as TM24AlertTile__mui,
    DevicesCarouselWrapper as DevicesCarouselWrapper__mui,
    FeaturesCarouselWrapper as FeaturesCarouselWrapper__mui,
    MarketingBlockWrapper as MarketingBlockWrapper__mui,
    OffersPage__MarketingLinkWrapper as OffersPage__MarketingLinkWrapper__mui, // eslint-disable-line camelcase
    QuestionsAccordionWrapper as QuestionsAccordionWrapper__mui,
    StreamotionBlurbWrapper as StreamotionBlurbWrapper__mui,
} from '@fsa-streamotion/streamotion-web-mui';

import {
    Branding as Branding__nucleus,
    BA01BtnPr as BA01BtnPr__nucleus,
    BA08BtnSec as BA08BtnSec__nucleus,
    BA22EditBtn as BA22EditBtn__nucleus,
    BA30TextBtn as BA30TextBtn__nucleus,
    BA43CatTile as BA43CatTile__nucleus,
    BA45BtnSecSml as BA45BtnSecSml__nucleus,
    BA46BtnPrMarketing as BA46BtnPrMarketing__nucleus,
    CA02FeatureStacked as CA02FeatureStacked__nucleus,
    CAM01Stand as CAM01Stand__nucleus,
    CAM03Finite as CAM03Finite__nucleus,
    CA01Feature as CA01Feature__nucleus,
    CM03FeatureList as CM03FeatureList__nucleus,
    CM04FeatureBlock as CM04FeatureBlock__nucleus,
    GA05BgImg as GA05BgImg__nucleus,
    GA06PostImg as GA06PostImg__nucleus,
    GA07TileImg as GA07TileImg__nucleus,
    GA92BrandLogo as GA92BrandLogo__nucleus,
    GM07SlidingPictures as GM07SlidingPictures__nucleus,
    IC25ArrowR as IC25ArrowR__nucleus,
    IC31Tick as IC31Tick__nucleus,
    IC42Error as IC42Error__nucleus,
    IC43Valid as IC43Valid__nucleus,
    IC50Success as IC50Success__nucleus,
    IC103Loading as IC103Loading__nucleus,
    IM01Input as IM01Input__nucleus,
    NM10Footer as NM10Footer__nucleus,
    OR08MarBlk as OR08MarBlk__nucleus,
    OR12VchInp as OR12VchInp__nucleus,
    OR16MarBlk as OR16MarBlk__nucleus,
    OR17AccBlk as OR17AccBlk__nucleus,
    OR49SumPnl as OR49SumPnl__nucleus,
    OR52TabPanels as OR52TabPanels__nucleus,
    OR53Veri as OR53Veri__nucleus,
    OR56Modal as OR56Modal__nucleus,
    OR117GiftCheckout as OR117GiftCheckout__nucleus,
    OR120PictureCar as OR120PictureCar__nucleus,
    OR128StreamotionBlk as OR128StreamotionBlk__nucleus,
    OR129MarBlk as OR129MarBlk__nucleus,
    TabbedContentCarouselWrapper as TabbedContentCarouselWrapper__nucleus,
    TM14ShowTil as TM14ShowTil__nucleus,
    TM19PmntCc as TM19PmntCc__nucleus,
    TM19PmntTelstra as TM19PmntTelstra__nucleus,
    TM24AlertTile as TM24AlertTile__nucleus,
    DevicesCarouselWrapper as DevicesCarouselWrapper__nucleus,
    FeaturesCarouselWrapper as FeaturesCarouselWrapper__nucleus,
    MarketingBlockWrapper as MarketingBlockWrapper__nucleus,
    MarketingLinkWrapper as MarketingLinkWrapper__nucleus,
    QuestionsAccordionWrapper as QuestionsAccordionWrapper__nucleus,
    StreamotionBlurbWrapper as StreamotionBlurbWrapper__nucleus,
} from '@fsa-streamotion/streamotion-web-nucleus';

import {
    IC31Tick as IC31Tick__quicksilver,
} from '@fsa-streamotion/streamotion-web-quicksilver';

import brandPropType from '../common/brand-proptype';

export const BrandedLogoContainer = ({brand, ...props}) => createElement(({
    binge: Branding__ionic,
    flash: Branding__infinity,
    kayo: Branding__mui,
    lifestyle: Branding__nucleus,
})[brand], props);
BrandedLogoContainer.displayName = 'BrandedLogoContainer';
BrandedLogoContainer.propTypes = {brand: brandPropType};

export const BrandedBA01BtnPr = ({brand, ...props}) => createElement(({
    binge: BA01BtnPr__ionic,
    flash: BA01BtnPr__infinity,
    kayo: BA01BtnPr__mui,
    lifestyle: BA01BtnPr__nucleus,
})[brand], props);
BrandedBA01BtnPr.displayName = 'BrandedBA01BtnPr';
BrandedBA01BtnPr.propTypes = {brand: brandPropType};

export const BrandedBA08BtnSec = ({brand, ...props}) => createElement(({
    binge: BA08BtnSec__ionic,
    flash: BA28BtnSec__infinity,
    kayo: BA08BtnSec__mui,
    lifestyle: BA08BtnSec__nucleus,
})[brand], props);
BrandedBA08BtnSec.displayName = 'BrandedBA08BtnSec';
BrandedBA08BtnSec.propTypes = {brand: brandPropType};

// all small secondary buttons
export const BrandedSecondaryBtn = ({brand, ...props}) => createElement(({
    binge: BA45BtnSecSml__ionic,
    flash: BA28BtnSec__infinity,
    kayo: BA08BtnSec__mui,
    lifestyle: BA45BtnSecSml__nucleus,
})[brand], props);
BrandedSecondaryBtn.displayName = 'BrandedSecondaryBtn';
BrandedSecondaryBtn.propTypes = {brand: brandPropType};

export const BrandedBA22EditBtn = ({brand, ...props}) => createElement(({
    binge: BA22EditBtn__ionic,
    flash: BA22EditBtn__infinity,
    kayo: BA22EditBtn__mui,
    lifestyle: BA22EditBtn__nucleus,
})[brand], props);
BrandedBA22EditBtn.displayName = 'BrandedBA22EditBtn';
BrandedBA22EditBtn.propTypes = {brand: brandPropType};

export const BrandedBA30TextBtn = ({brand, ...props}) => createElement(({
    binge: BA30TextBtn__ionic,
    flash: BA30TextBtn__infinity,
    kayo: BA30TextBtn__mui,
    lifestyle: BA30TextBtn__nucleus,
})[brand], props);
BrandedBA30TextBtn.displayName = 'BrandedBA30TextBtn';
BrandedBA30TextBtn.propTypes = {brand: brandPropType};

export const BrandedBA43CatTile = ({brand, ...props}) => createElement(({
    binge: BA43CatTile__ionic,
    flash: BA43CatTile__infinity,
    kayo: BA43CatTile__mui,
    lifestyle: BA43CatTile__nucleus,
})[brand], props);
BrandedBA43CatTile.displayName = 'BrandedBA43CatTile';
BrandedBA43CatTile.propTypes = {brand: brandPropType};

export const BrandedBA46BtnPrMarketing = ({brand, ...props}) => createElement(({
    binge: BA46BtnPrMarketing__ionic,
    flash: BA46BtnPrMarketing__infinity,
    kayo: BA46BtnPrMarketing__mui,
    lifestyle: BA46BtnPrMarketing__nucleus,
})[brand], props);
BrandedBA46BtnPrMarketing.displayName = 'BrandedBA46BtnPrMarketing';
BrandedBA46BtnPrMarketing.propTypes = {brand: brandPropType};

export const BrandedCA02FeatureStacked = ({brand, ...props}) => createElement(({
    binge: CA02FeatureStacked__ionic,
    flash: CA02FeatureStacked__infinity,
    kayo: CA02FeatureStacked__mui,
    lifestyle: CA02FeatureStacked__nucleus,
})[brand], props);
BrandedCA02FeatureStacked.displayName = 'BrandedCA02FeatureStacked';
BrandedCA02FeatureStacked.propTypes = {brand: brandPropType};

export const BrandedCAM01Stand = ({brand, ...props}) => createElement(({
    binge: CAM01Stand__ionic,
    flash: CAM01Stand__infinity,
    kayo: CAM01Stand__mui,
    lifestyle: CAM01Stand__nucleus,
})[brand], props);
BrandedCAM01Stand.displayName = 'BrandedCAM01Stand';
BrandedCAM01Stand.propTypes = {brand: brandPropType};

export const BrandedCAM03Finite = ({brand, ...props}) => createElement(({
    binge: CAM03Finite__ionic,
    flash: CAM03Finite__infinity,
    kayo: CAM03Finite__mui,
    lifestyle: CAM03Finite__nucleus,
})[brand], props);
BrandedCAM03Finite.displayName = 'BrandedCAM03Finite';
BrandedCAM03Finite.propTypes = {brand: brandPropType};

export const BrandedCA01Feature = ({brand, ...props}) => createElement(({
    binge: CA01Feature__ionic,
    flash: CA01Feature__infinity,
    kayo: CA01Feature__mui,
    lifestyle: CA01Feature__nucleus,
})[brand], props);
BrandedCA01Feature.displayName = 'BrandedCA01Feature';
BrandedCA01Feature.propTypes = {brand: brandPropType};

export const BrandedCM03FeatureList = ({brand, ...props}) => createElement(({
    binge: CM03FeatureList__ionic,
    flash: CM03FeatureList__infinity,
    kayo: CM03FeatureList__mui,
    lifestyle: CM03FeatureList__nucleus,
})[brand], props);
BrandedCM03FeatureList.displayName = 'BrandedCM03FeatureList';
BrandedCM03FeatureList.propTypes = {brand: brandPropType};

export const BrandedCM04FeatureBlock = ({brand, ...props}) => createElement(({
    binge: CM04FeatureBlock__ionic,
    flash: CM04FeatureBlock__infinity,
    kayo: CM04FeatureBlock__mui,
    lifestyle: CM04FeatureBlock__nucleus,
})[brand], props);
BrandedCM04FeatureBlock.displayName = 'BrandedCM04FeatureBlock';
BrandedCM04FeatureBlock.propTypes = {brand: brandPropType};

export const BrandedGA05BgImg = ({brand, ...props}) => createElement(({
    binge: GA05BgImg__ionic,
    flash: GA05BgImg__infinity,
    kayo: GA05BgImg__mui,
    lifestyle: GA05BgImg__nucleus,
})[brand], props);
BrandedGA05BgImg.displayName = 'BrandedGA05BgImg';
BrandedGA05BgImg.propTypes = {brand: brandPropType};

export const BrandedGA07TileImg = ({brand, ...props}) => createElement(({
    binge: GA07TileImg__ionic,
    flash: GA07TileImg__infinity,
    kayo: GA07TileImg__mui,
    lifestyle: GA07TileImg__nucleus,
})[brand], props);
BrandedGA07TileImg.displayName = 'BrandedGA07TileImg';
BrandedGA07TileImg.propTypes = {brand: brandPropType};

export const BrandedGA06PostImg = ({brand, ...props}) => createElement(({
    binge: GA06PostImg__ionic,
    flash: GA06PostImg__infinity,
    kayo: GA06PostImg__mui,
    lifestyle: GA06PostImg__nucleus,
})[brand], props);
BrandedGA06PostImg.displayName = 'BrandedGA06PostImg';
BrandedGA06PostImg.propTypes = {brand: brandPropType};

export const BrandedGA92BrandLogo = ({brand, ...props}) => createElement(({
    binge: GA92BrandLogo__ionic,
    flash: GA92BrandLogo__infinity,
    kayo: GA92BrandLogo__mui,
    lifestyle: GA92BrandLogo__nucleus,
})[brand], props);
BrandedGA92BrandLogo.displayName = 'BrandedGA92BrandLogo';
BrandedGA92BrandLogo.propTypes = {brand: brandPropType};

export const BrandedGM07SlidingPictures = ({brand, ...props}) => createElement(({
    binge: GM07SlidingPictures__ionic,
    flash: GM07SlidingPictures__infinity,
    kayo: GM07SlidingPictures__mui,
    lifestyle: GM07SlidingPictures__nucleus,
})[brand], props);
BrandedGM07SlidingPictures.displayName = 'BrandedGM07SlidingPictures';
BrandedGM07SlidingPictures.propTypes = {brand: brandPropType};

// Binge SVG brand logo is GA149
export const BrandedLogoGraphic = ({brand, ...props}) => createElement(({
    binge: GA149WordmarkOnWhite__ionic,
    flash: GA92BrandLogo__infinity,
    kayo: GA92BrandLogo__mui,
    lifestyle: GA92BrandLogo__nucleus,
})[brand], props);
BrandedLogoGraphic.displayName = 'BrandedLogoGraphic';
BrandedLogoGraphic.propTypes = {brand: brandPropType};

export const BrandedIC25ArrowR = ({brand, ...props}) => createElement(({
    binge: IC25ArrowR__ionic,
    flash: IC25ArrowR__infinity,
    kayo: IC25ArrowR__mui,
    lifestyle: IC25ArrowR__nucleus,
})[brand], props);
BrandedIC25ArrowR.displayName = 'BrandedIC25ArrowR';
BrandedIC25ArrowR.propTypes = {brand: brandPropType};

export const BrandedIC31Tick = ({brand, ...props}) => createElement(({
    binge: IC31Tick__ionic,
    flash: IC31Tick__infinity,
    kayo: IC31Tick__mui,
    quicksilver: IC31Tick__quicksilver,
    lifestyle: IC31Tick__nucleus,
})[brand], props);
BrandedIC31Tick.displayName = 'BrandedIC31Tick';
BrandedIC31Tick.propTypes = {brand: brandPropType};

export const BrandedIC42Error = ({brand, ...props}) => createElement(({
    binge: IC42Error__ionic,
    flash: IC42Error__infinity,
    kayo: IC42Error__mui,
    lifestyle: IC42Error__nucleus,
})[brand], props);
BrandedIC42Error.displayName = 'BrandedIC42Error';
BrandedIC42Error.propTypes = {brand: brandPropType};

// IC43 and IC50 are mixed usage between the 3 brands, using the majority as base
export const BrandedIC43Valid = ({brand, ...props}) => createElement(({
    binge: IC43Valid__ionic,
    flash: IC43Valid__infinity,
    kayo: IC50Success__mui,
    lifestyle: IC43Valid__nucleus,
})[brand], props);
BrandedIC43Valid.displayName = 'BrandedIC43Valid';
BrandedIC43Valid.propTypes = {brand: brandPropType};

// IC43 and IC50 are mixed usage between the 3 brands, using the majority as base
export const BrandedIC50Success = ({brand, ...props}) => createElement(({
    binge: IC50Success__ionic,
    flash: IC43Valid__infinity,
    kayo: IC50Success__mui,
    lifestyle: IC50Success__nucleus,
})[brand], props);
BrandedIC50Success.displayName = 'BrandedIC50Success';
BrandedIC50Success.propTypes = {brand: brandPropType};

export const BrandedSuccessIcon = ({brand, ...props}) => createElement(({
    binge: IC50Success__ionic,
    flash: IC32Success__infinity,
    kayo: IC50Success__mui,
    lifestyle: IC50Success__nucleus,
})[brand], props);
BrandedSuccessIcon.displayName = 'BrandedSuccessIcon';
BrandedSuccessIcon.propTypes = {brand: brandPropType};

export const BrandedIC103Loading = ({brand, ...props}) => createElement(({
    binge: IC103Loading__ionic,
    flash: IC103Loading__infinity,
    kayo: IC103Loading__mui,
    lifestyle: IC103Loading__nucleus,
    magneto: IC103Loading__nucleus, // @TODO: update this
})[brand], props);
BrandedIC103Loading.displayName = 'BrandedIC103Loading';
BrandedIC103Loading.propTypes = {brand: brandPropType};

export const BrandedIM01Input = ({brand, ...props}) => createElement(({
    binge: IM01Input__ionic,
    flash: IM01Input__infinity,
    kayo: IM01Input__mui,
    lifestyle: IM01Input__nucleus,
})[brand], props);
BrandedIM01Input.displayName = 'BrandedIM01Input';
BrandedIM01Input.propTypes = {brand: brandPropType};

export const BrandedNM10Footer = (({brand, ...props}) => createElement(({
    binge: NM10Footer__ionic,
    flash: NM10Footer__infinity,
    kayo: NM10Footer__mui,
    lifestyle: NM10Footer__nucleus,
})[brand], props));
BrandedNM10Footer.displayName = 'BrandedNM10Footer';
BrandedNM10Footer.propTypes = {brand: brandPropType};

export const BrandedOR08MarBlk = ({brand, ...props}) => createElement(({
    binge: OR08MarBlk__ionic,
    flash: OR08MarBlk__infinity,
    kayo: OR08MarBlk__mui,
    lifestyle: OR08MarBlk__nucleus,
})[brand], props);
BrandedOR08MarBlk.displayName = 'BrandedOR08MarBlk';
BrandedOR08MarBlk.propTypes = {brand: brandPropType};

export const BrandedOR12VchInp = ({brand, ...props}) => createElement(({
    binge: OR12VchInp__ionic,
    flash: OR12VchInp__infinity,
    kayo: OR12VchInp__mui,
    lifestyle: OR12VchInp__nucleus,
})[brand], props);
BrandedOR12VchInp.displayName = 'BrandedOR12VchInp';
BrandedOR12VchInp.propTypes = {brand: brandPropType};

export const BrandedOR16MarBlk = ({brand, ...props}) => createElement(({
    binge: OR16MarBlk__ionic,
    flash: OR16MarBlk__infinity,
    kayo: OR16MarBlk__mui,
    lifestyle: OR16MarBlk__nucleus,
})[brand], props);
BrandedOR16MarBlk.displayName = 'BrandedOR16MarBlk';
BrandedOR16MarBlk.propTypes = {brand: brandPropType};

export const BrandedOR17AccBlk = ({brand, ...props}) => createElement(({
    binge: OR17AccBlk__ionic,
    flash: OR17AccBlk__infinity,
    kayo: OR17AccBlk__mui,
    lifestyle: OR17AccBlk__nucleus,
})[brand], props);
BrandedOR17AccBlk.displayName = 'BrandedOR17AccBlk';
BrandedOR17AccBlk.propTypes = {brand: brandPropType};

export const BrandedOR49SumPnl = ({brand, ...props}) => createElement(({
    binge: OR49SumPnl__ionic,
    flash: OR49SumPnl__infinity,
    kayo: OR49SumPnl__mui,
    lifestyle: OR49SumPnl__nucleus,
})[brand], props);
BrandedOR49SumPnl.displayName = 'BrandedOR49SumPnl';
BrandedOR49SumPnl.propTypes = {brand: brandPropType};

export const BrandedOR52TabPanels = ({brand, ...props}) => createElement(({
    binge: OR52TabPanels__ionic,
    flash: OR52TabPanels__infinity,
    kayo: OR52TabPanels__mui,
    lifestyle: OR52TabPanels__nucleus,
})[brand], props);
BrandedOR52TabPanels.displayName = 'BrandedOR52TabPanels';
BrandedOR52TabPanels.propTypes = {brand: brandPropType};

export const BrandedOR53Veri = ({brand, ...props}) => createElement(({
    binge: OR53Veri__ionic,
    flash: OR53Veri__infinity,
    kayo: OR53Veri__mui,
    lifestyle: OR53Veri__nucleus,
})[brand], props);
BrandedOR53Veri.displayName = 'BrandedOR53Veri';
BrandedOR53Veri.propTypes = {brand: brandPropType};

export const BrandedOR56Modal = ({brand, ...props}) => createElement(({
    binge: OR56Modal__ionic,
    flash: OR56Modal__infinity,
    kayo: OR56Modal__mui,
    lifestyle: OR56Modal__nucleus,
})[brand], props);
BrandedOR56Modal.displayName = 'BrandedOR56Modal';
BrandedOR56Modal.propTypes = {brand: brandPropType};

export const BrandedOR117GiftCheckout = ({brand, ...props}) => createElement(({
    binge: OR117GiftCheckout__ionic,
    flash: OR117GiftCheckout__infinity,
    kayo: OR117GiftCheckout__mui,
    lifestyle: OR117GiftCheckout__nucleus,
})[brand], props);
BrandedOR117GiftCheckout.displayName = 'BrandedOR117GiftCheckout';
BrandedOR117GiftCheckout.propTypes = {brand: brandPropType};

export const BrandedOR120PictureCar = ({brand, ...props}) => createElement(({
    binge: OR120PictureCar__ionic,
    flash: OR120PictureCar__infinity,
    kayo: OR120PictureCar__mui,
    lifestyle: OR120PictureCar__nucleus,
})[brand], props);
BrandedOR120PictureCar.displayName = 'BrandedOR120PictureCar';
BrandedOR120PictureCar.propTypes = {brand: brandPropType};

export const BrandedOR128StreamotionBlk = ({brand, ...props}) => createElement(({
    binge: OR128StreamotionBlk__ionic,
    flash: OR128StreamotionBlk__infinity,
    kayo: OR128StreamotionBlk__mui,
    lifestyle: OR128StreamotionBlk__nucleus,
})[brand], props);
BrandedOR128StreamotionBlk.displayName = 'BrandedOR128StreamotionBlk';
BrandedOR128StreamotionBlk.propTypes = {brand: brandPropType};

export const BrandedOR129MarBlk = ({brand, ...props}) => createElement(({
    binge: OR129MarBlk__ionic,
    flash: OR129MarBlk__infinity,
    kayo: OR129MarBlk__mui,
    lifestyle: OR129MarBlk__nucleus,
})[brand], props);
BrandedOR129MarBlk.displayName = 'BrandedOR129MarBlk';
BrandedOR129MarBlk.propTypes = {brand: brandPropType};

export const BrandedTM14ShowTil = ({brand, ...props}) => createElement(({
    binge: TM14ShowTil__ionic,
    flash: TM14ShowTil__infinity,
    kayo: TM14ShowTil__mui,
    lifestyle: TM14ShowTil__nucleus,
})[brand], props);
BrandedTM14ShowTil.displayName = 'BrandedTM14ShowTil';
BrandedTM14ShowTil.propTypes = {brand: brandPropType};

export const BrandedTM19PmntCc = ({brand, ...props}) => createElement(({
    binge: TM19PmntCc__ionic,
    flash: TM19PmntCc__infinity,
    kayo: TM19PmntCc__mui,
    lifestyle: TM19PmntCc__nucleus,
})[brand], props);
BrandedTM19PmntCc.displayName = 'BrandedTM19PmntCc';
BrandedTM19PmntCc.propTypes = {brand: brandPropType};

export const BrandedTM19PmntTelstra = ({brand, ...props}) => createElement(({
    binge: TM19PmntTelstra__ionic,
    flash: TM19PmntTelstra__infinity,
    kayo: TM19PmntTelstra__mui,
    lifestyle: TM19PmntTelstra__nucleus,
})[brand], props);
BrandedTM19PmntTelstra.displayName = 'BrandedTM19PmntTelstra';
BrandedTM19PmntTelstra.propTypes = {brand: brandPropType};

export const BrandedTM24AlertTile = ({brand, ...props}) => createElement(({
    binge: TM24AlertTile__ionic,
    flash: TM24AlertTile__infinity,
    kayo: TM24AlertTile__mui,
    lifestyle: TM24AlertTile__nucleus,
})[brand], props);
BrandedTM24AlertTile.displayName = 'BrandedTM24AlertTile';
BrandedTM24AlertTile.propTypes = {brand: brandPropType};

export const BrandedDevicesCarouselWrapper = ({brand, ...props}) => createElement(({
    binge: DevicesCarouselWrapper__ionic,
    flash: DevicesCarouselWrapper__infinity,
    kayo: DevicesCarouselWrapper__mui,
    lifestyle: DevicesCarouselWrapper__nucleus,
})[brand], props);
BrandedDevicesCarouselWrapper.displayName = 'BrandedDevicesCarouselWrapper';
BrandedDevicesCarouselWrapper.propTypes = {brand: brandPropType};

export const BrandedFeaturesCarouselWrapper = ({brand, ...props}) => createElement(({
    binge: FeaturesCarouselWrapper__ionic,
    flash: FeaturesCarouselWrapper__infinity,
    kayo: FeaturesCarouselWrapper__mui,
    lifestyle: FeaturesCarouselWrapper__nucleus,
})[brand], props);
BrandedFeaturesCarouselWrapper.displayName = 'BrandedFeaturesCarouselWrapper';
BrandedFeaturesCarouselWrapper.propTypes = {brand: brandPropType};

export const BrandedMarketingBlockWrapper = ({brand, ...props}) => createElement(({
    binge: MarketingBlockWrapper__ionic,
    flash: MarketingBlockWrapper__ionic, // @TODO: MarketingBlockWrapper__infinity
    kayo: MarketingBlockWrapper__mui,
    lifestyle: MarketingBlockWrapper__nucleus,
})[brand], props);
BrandedMarketingBlockWrapper.displayName = 'BrandedMarketingBlockWrapper';
BrandedMarketingBlockWrapper.propTypes = {brand: brandPropType};

export const BrandedMarketingLinkWrapper = ({brand, ...props}) => createElement(({
    binge: MarketingLinkWrapper__ionic,
    flash: MarketingLinkWrapper__infinity,
    kayo: OffersPage__MarketingLinkWrapper__mui, // eslint-disable-line camelcase
    lifestyle: MarketingLinkWrapper__nucleus,
})[brand], props);
BrandedMarketingLinkWrapper.displayName = 'BrandedMarketingLinkWrapper';
BrandedMarketingLinkWrapper.propTypes = {brand: brandPropType};

export const BrandedTabbedContentCarouselWrapper = ({brand, ...props}) => createElement(({
    binge: TabbedContentCarouselWrapper__ionic,
    flash: TabbedContentCarouselWrapper__ionic, // @TODO: TabbedContentCarouselWrapper__infinity,
    kayo: TabbedContentCarouselWrapper__mui,
    lifestyle: TabbedContentCarouselWrapper__nucleus,
})[brand], props);
BrandedTabbedContentCarouselWrapper.displayName = 'BrandedTabbedContentCarouselWrapper';
BrandedTabbedContentCarouselWrapper.propTypes = {brand: brandPropType};

export const BrandedQuestionsAccordionWrapper = ({brand, ...props}) => createElement(({
    binge: QuestionsAccordionWrapper__ionic,
    flash: QuestionsAccordionWrapper__infinity,
    kayo: QuestionsAccordionWrapper__mui,
    lifestyle: QuestionsAccordionWrapper__nucleus,
})[brand], props);
BrandedQuestionsAccordionWrapper.displayName = 'BrandedQuestionsAccordionWrapper';
BrandedQuestionsAccordionWrapper.propTypes = {brand: brandPropType};

export const BrandedStreamotionBlurbWrapper = ({brand, ...props}) => createElement(({
    binge: StreamotionBlurbWrapper__ionic,
    flash: StreamotionBlurbWrapper__infinity,
    kayo: StreamotionBlurbWrapper__mui,
    lifestyle: StreamotionBlurbWrapper__nucleus,
})[brand], props);
BrandedStreamotionBlurbWrapper.displayName = 'BrandedStreamotionBlurbWrapper';
BrandedStreamotionBlurbWrapper.propTypes = {brand: brandPropType};
