import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';

import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {geyser, kayoGreen, white, black} from '../../../../common/palette';

export const Title = styled.dt`
    margin-bottom: 5px;
    color: ${white};
    font: var(--mui-header-7-bold);
`;

export const SubTitle = styled.span`
    display: block;
    margin-bottom: 7px;
    color: ${rgba(white, 0.7)};
    font: var(--mui-body-copy-4);
`;

export const DescWrapper = styled.dd`
    margin: 0;
    color: ${rgba(geyser, 0.7)};
    font: var(--mui-body-copy-7);
`;

const RibbonWrapper = styled.span`
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    transform: rotate(45deg) translate(40px, -10px);
    background-color: ${kayoGreen};
    padding: 2px 21px 1px;
    width: 110px;
    overflow-x: hidden;
    text-align: center;
    text-transform: uppercase;
    text-shadow: none;
    font: var(--mui-header-9);
`;

const RibbonMask = styled.span`
    display: block;
    padding: 0 14px 0 28px;
    overflow-x: hidden;
    white-space: nowrap;
    color: ${black};
    mask-image: linear-gradient(to left, transparent 14px, ${black} 21px);
`;

const Wrapper = styled.dl`
    position: relative;
    margin: 0;
    border-radius: 6px;
    padding: 12px 14px;
    overflow: hidden; /* to clip the ribbon easily */
    text-shadow: 0 1px 1px ${rgba(black, 0.3)};

    ${stylesWhen('isLightTheme')`
        background-color: ${white};

        ${Title} {
            color: ${black};
        }

        ${SubTitle},
        ${DescWrapper} {
            color: ${rgba(black, 0.7)};
        }
    `}

    ${stylesWhen('hasRibbonText')`
        padding-right: 90px;
        min-height: 66px; /* prevent ribbon from being affected by border-radius when not much text */
    `}
`;

const TM01Vouch = ({
    className,
    isLightTheme,
    title,
    subTitle,
    ribbonText,
    children,
    ...htmlAttributes
}) => (
    <Wrapper
        {...htmlAttributes}
        className={classnames('TM01Vouch', className)}
        isLightTheme={isLightTheme}
        hasRibbonText={!!ribbonText}
    >
        <Title>{title}</Title>
        {!!(subTitle || children || ribbonText) && (
            <DescWrapper>
                {!!subTitle && <SubTitle>{subTitle}</SubTitle>}
                {children}
                {!!ribbonText && <RibbonWrapper><RibbonMask>{ribbonText}</RibbonMask></RibbonWrapper>}
            </DescWrapper>
        )}
    </Wrapper>
);

TM01Vouch.displayName = 'TM01Vouch';

TM01Vouch.propTypes = {
    /** CSS class name(s) */
    className: classNameType,
    /** Light or dark (default) theme */
    isLightTheme: propTypes.bool,
    /** SubTitle to display in the ribbon */
    ribbonText: propTypes.string,
    /** Big text */
    title: propTypes.string,
    /** SubTitle under Title, slightly smaller than, but bigger than children text */
    subTitle: propTypes.string,
    /** Children to display in small text */
    children: propTypes.node,
};

export default TM01Vouch;
