import React from 'react';

import Ic from '..';

const IC28RelVid = (args) => (
    <Ic {...args}>
        <path fillOpacity=".35" d="M36 24h49v34H36z" />
        <g fillRule="nonzero">
            <path d="M78 20c5.523 0 10 4.477 10 10v22c0 5.523-4.477 10-10 10H43c-5.523 0-10-4.477-10-10V30c0-5.523 4.477-10 10-10h35Zm0 4H43a6 6 0 0 0-5.996 5.775L37 30v22a6 6 0 0 0 5.775 5.996L43 58h35a6 6 0 0 0 5.996-5.775L84 52V30a6 6 0 0 0-5.775-5.996L78 24Z" />
            <path d="M68.894 71.872H22.847V41h4.12v26.752h41.927z" />
            <path d="M59.048 81.596H13V50.723h4.12v26.753h41.928z" />
        </g>
    </Ic>
);

IC28RelVid.displayName = 'IC28RelVid';

export default IC28RelVid;
