import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {A, Button} from 'normalized-styled-components';
import noop from 'lodash/noop';
import property from 'lodash/property';
import classnames from 'classnames';
import {rgba} from 'polished';

import {mediaQuery, stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {colorType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {akela, ares, blanc, vader} from '../../../../common/palette';
import theme from '../../../../common/theme-color';
import {CONTENT_EDGE_SPACING_PERCENT} from '../../../../common/style-constants';
import VisuallyHidden from '../../../../common/visually-hidden';
import {SCREEN_1024_DESKTOP, SCREEN_1280_DESKTOP, SCREEN_1400_DESKTOP} from '../../../../common/screen-sizes';

import BA01BtnPr from '../../../atoms/ba/01-btn-pr';
import BA52ConversionBtn from '../../../atoms/ba/52-conversion-btn';
import GA92BrandLogo from '../../../atoms/ga/92-brand-logo';
import IC01Ham from '../../../atoms/ic/01-ham';
import IC02Sea from '../../../atoms/ic/02-sea';
import IC03Not from '../../../atoms/ic/03-not';
import IC08Cc from '../../../atoms/ic/08-cc';

const BADGE_SIZE = '18px';
const BADGE_SIZE_LG = '20px';

const OuterContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${rgba(blanc, 0.1)};
    background-color: ${theme.secondaryColor.opacity(0.75)};
    padding: 0 ${CONTENT_EDGE_SPACING_PERCENT}%;
    width: 100%;
    height: 69px;

    &::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: ${transition('opacity')};
        opacity: ${({isAppDockOpen}) => isAppDockOpen ? 1 : 0};
        background: ${vader};
        content: '';
    }
`;

const FlexRow = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    height: 69px;
`;

const HomeLink = styled(A)`
    align-self: flex-start;
    margin-right: 23px;
    text-decoration: none;
`;

const ShowHide = styled.div`
    display: none; /* specifically not accessible because App Dock will render NM07TopNav until SCREEN_1280_DESKTOP */
    align-self: flex-end;
    margin-top: 11px;

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        display: block;
    `}
`;

const RightSideContainer = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    text-align: right;
`;

const SignupCtaContainer = styled.div`
    display: none;

    ${mediaQuery({minWidthPx: SCREEN_1400_DESKTOP})`
        display: flex;
        padding: 0 20px;
    `}
`;

const SignupCtaMessage = styled.p`
    display: block;
    padding-right: 30px;
    max-height: 1.2em;
    overflow: hidden;
    color: ${akela};
    font: var(--ionic-nav-copy-default);
    mask-image: linear-gradient(to right, #000 520px, transparent); /* stylelint-disable-line color-no-hex */
`;

const CtaButtonsWrapper = styled.span`
    display: flex;
    align-items: center;
    column-gap: 10px;
`;

const StyledBA01BtnPr = styled(BA01BtnPr)`
    ${mediaQuery({minWidthPx: SCREEN_1400_DESKTOP})`
        padding: 7px 21px;
        min-width: 110px;
        height: 40px;
        font-size: 14px;
    `}
`;

const HeaderFunctionsContainer = styled.div`
    flex: 1 0 auto;
    margin: 11px 0;
    text-align: right;

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        flex-grow: 0;
    `}
`;

const HeaderButton = styled(Button)`
    appearance: none;
    position: relative;
    transition: ${transition('color')};
    border-width: 0;
    background-color: transparent;
    padding: 8px;
    width: 40px;
    height: 40px;
    color: ${rgba(blanc, 0.7)};

    &:focus,
    &:hover {
        outline: 0;
        color: ${property('color')};
    }

    &::after {
        position: absolute;
        right: 5px;
        bottom: -15px;
        left: 5px;
        transform: scaleX(0);
        transition: ${transition('transform')};
        background-color: ${blanc};
        height: 2px;
        content: '';
    }

    &[aria-current='true']::after {
        transform: none;
    }

    /* stylelint-disable-next-line order/order */
    &[aria-current='true'],
    &[aria-expanded='true'] {
        outline: 0;
        color: ${blanc};
    }

    &[aria-expanded='true']:focus {
        transform: scale(1.1);
    }
`;

const NotificationBadge = styled.span`
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    transform: translate(-2px, -3px);
    border-radius: 50%;
    background-color: ${blanc};
    min-width: ${BADGE_SIZE};
    height: ${BADGE_SIZE};
    color: ${vader};
    font: var(--ionic-body-copy-6-bold);
    line-height: 1; /* stylelint-disable-line order/properties-order */

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        height: ${BADGE_SIZE_LG};
        min-width: ${BADGE_SIZE_LG};
        transform: translate(-1px, -4px);
    `}

    ${stylesWhen(({notificationCount}) => notificationCount > 99)`
        border-radius: 9px;
        padding: 0 5px;
        transform: translate(9px, -4px);

        ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
            transform: translate(13px, -5px);
        `}
    `}
`;

const StyledBA52ConversionBtn = styled(BA52ConversionBtn)`
    position: fixed;
    bottom: 0;
    left: 0;

    ${mediaQuery({minWidthPx: SCREEN_1400_DESKTOP})`
        display: none;
    `}
`;

const OR33HeaderDsk = ({
    brandLogoId,
    brandLogoSrcsetOptions,
    children,
    className,
    color = ares,
    hasChromecastIcon,
    hasNotificationsIcon,
    hasHeaderButtons = true,
    hasSearchIcon,
    homeLink = '/',
    homeLinkAssistiveLabel = 'Home page',
    isAppDockOpen,
    isChromecastConnected,
    isHeaderFunctionsHidden,
    isMesh,
    isNotificationModalOpen,
    isSearchFullPage,
    isSearchModalOpen,
    notificationCount,
    onAppDockClick = noop,
    onHomeLinkClick,
    onNotificationsClick = noop,
    onSearchClick = noop,
    onChromecastClick = noop,
    // > signup cta
    shouldShowSignInCta,
    shouldShowSignUpCta,
    ctaMessage,
    signInCtaCopy = 'Sign In',
    signUpCtaCopy = 'Sign Up',
    signUpUrl = '/',
    onSignInClick = noop,
    ...htmlAttributes
}) => (
    <OuterContainer {...htmlAttributes} isAppDockOpen={isAppDockOpen} className={classnames('OR33HeaderDsk', className)}>
        <FlexRow>
            <HomeLink
                href={homeLink}
                title={homeLinkAssistiveLabel}
                onClick={onHomeLinkClick}
                aria-label={homeLinkAssistiveLabel}
            >
                <GA92BrandLogo
                    color={theme.primaryColor}
                    id={brandLogoId}
                    isMesh={isMesh}
                    sizes="113px"
                    srcsetOptions={brandLogoSrcsetOptions}
                    width="113px"
                />
            </HomeLink>

            <ShowHide>
                {children}
            </ShowHide>
        </FlexRow>

        {!isHeaderFunctionsHidden && (
            <RightSideContainer>
                {!!ctaMessage && !!shouldShowSignUpCta && (
                    <StyledBA52ConversionBtn href={signUpUrl}>
                        {ctaMessage}
                    </StyledBA52ConversionBtn>
                )}
                {!!shouldShowSignUpCta && (
                    <SignupCtaContainer>
                        {!!ctaMessage && (
                            <SignupCtaMessage>
                                {ctaMessage}
                            </SignupCtaMessage>
                        )}

                        <CtaButtonsWrapper>
                            <StyledBA01BtnPr isBlock={false} href={signUpUrl}>{signUpCtaCopy}</StyledBA01BtnPr>
                            {!!shouldShowSignInCta && (
                                <StyledBA01BtnPr isBlock={false} onClick={onSignInClick}>{signInCtaCopy}</StyledBA01BtnPr>
                            )}
                        </CtaButtonsWrapper>
                    </SignupCtaContainer>
                )}
                {!!hasHeaderButtons && (
                    <HeaderFunctionsContainer>
                        {!!hasChromecastIcon && (
                            <HeaderButton onClick={onChromecastClick} aria-pressed={isChromecastConnected} aria-label="Chromecast" color={color}>
                                <IC08Cc isConnected={isChromecastConnected} accentColor={color} />
                            </HeaderButton>
                        )}
                        {!!hasSearchIcon && (
                            <HeaderButton onClick={onSearchClick} aria-haspopup="true" aria-expanded={isSearchModalOpen} aria-current={!!isSearchFullPage} color={color}>
                                <IC02Sea ariaLabel="Search" />
                            </HeaderButton>
                        )}
                        {!!hasNotificationsIcon && (
                            <HeaderButton
                                onClick={onNotificationsClick}
                                aria-haspopup="true"
                                aria-expanded={isNotificationModalOpen}
                                color={color}
                            >
                                <IC03Not ariaLabel="Notifications" />
                                {notificationCount > 0 ? (
                                    <NotificationBadge notificationCount={notificationCount}>
                                        {notificationCount > 99 ? '99+' : notificationCount}
                                        <VisuallyHidden> new</VisuallyHidden>
                                    </NotificationBadge>
                                ) : (
                                    <VisuallyHidden>all caught up</VisuallyHidden>
                                )}
                            </HeaderButton>
                        )}
                        <HeaderButton onClick={onAppDockClick} aria-haspopup="true" aria-expanded={isAppDockOpen} color={color}>
                            <IC01Ham ariaLabel="Main menu" />
                        </HeaderButton>
                    </HeaderFunctionsContainer>
                )}
            </RightSideContainer>
        )}
    </OuterContainer>
);

OR33HeaderDsk.propTypes = {
    /** Hydrated Id for the logo */
    brandLogoId: propTypes.string,
    /** Optional srcset to use for the brand logo */
    brandLogoSrcsetOptions: propTypes.objectOf(propTypes.string),
    /** NM07TopNav, lol */
    children: propTypes.node,
    /** Classnames for restyling purposes */
    className: classNameType,
    /** The color for the logo and Chromecast connected icons to take (driven by VCC API) */
    color: colorType,
    /** Is the chromecast control rendered */
    hasChromecastIcon: propTypes.bool,
    /** Should we show the header functions including the app dock toggle (Hamburger button)? */
    hasHeaderButtons: propTypes.bool,
    /** Is the notification control rendered */
    hasNotificationsIcon: propTypes.bool,
    /** Is the search control rendered */
    hasSearchIcon: propTypes.bool,
    // > SIGNUP CTA
    /** Should the Sign In button(s) show */
    shouldShowSignInCta: propTypes.bool,
    /** Should the Sign Up button(s) show */
    shouldShowSignUpCta: propTypes.bool,
    /** Shows Signup CTA label for new users */
    ctaMessage: propTypes.node,
    /** Sign In CTA copy */
    signInCtaCopy: propTypes.string,
    /** Sign Up CTA copy */
    signUpCtaCopy: propTypes.string,
    /** URL for sign-up */
    signUpUrl: propTypes.string,
    /** Action on clicking sign-in */
    onSignInClick: propTypes.func,
    /** For the logo link to go home */
    homeLink: propTypes.string,
    /** Assistive label text For the logo link */
    homeLinkAssistiveLabel: propTypes.string,
    /** For aria-expanded */
    isAppDockOpen: propTypes.bool,
    /** Is the Chromecast connected */
    isChromecastConnected: propTypes.bool,
    /** Hides the notification, search, and app dock buttons */
    isHeaderFunctionsHidden: propTypes.bool,
    /** Whether to show the mesh logo */
    isMesh: propTypes.bool,
    /** For aria-expanded */
    isNotificationModalOpen: propTypes.bool,
    /** For route is /search or equivalent */
    isSearchFullPage: propTypes.bool,
    /** For aria-expanded */
    isSearchModalOpen: propTypes.bool,
    /** What number to display in the notification badge */
    notificationCount: propTypes.number,
    /** Function for when Hamburger menu is clicked */
    onAppDockClick: propTypes.func,
    /** Function for when Chromecast icon is clicked */
    onChromecastClick: propTypes.func,
    /** Callback on home link click */
    onHomeLinkClick: propTypes.func,
    /** Function for when Notification bell is clicked */
    onNotificationsClick: propTypes.func,
    /** Function for when Search magnifying-glass is clicked */
    onSearchClick: propTypes.func,
};

OR33HeaderDsk.displayName = 'OR33HeaderDsk';

export default OR33HeaderDsk;
