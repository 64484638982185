import React from 'react';
import propTypes from 'prop-types';

import {colorType} from '@fsa-streamotion/custom-prop-types';
import {lifestyle} from '../../../../common/palette';
import Ic from '..';

const IC08Cc = ({isConnected = false, accentColor = lifestyle, color, ...otherProps}) => (
    <Ic color={color} {...otherProps}>
        <g transform="translate(4 15)">
            <path d="M4.2 0v25.2c2.845.304 5.657.867 8.4 1.68V8.4h72.8v50.638H44.072c.747 2.749 1.239 5.56 1.47 8.4H93.8V0H4.2z" />
            <path color={isConnected ? accentColor : color} d="M20.076 70.756h-4.2c0-8.77-7.106-15.882-15.876-15.89v-4.2c11.087.015 20.068 9.003 20.076 20.09z" />
            <path color={isConnected ? accentColor : color} d="M30.156 70.756h-4.2C25.941 56.427 14.329 44.816 0 44.8v-4.2c16.648.015 30.14 13.508 30.156 30.156z" />
            <path color={isConnected ? accentColor : color} d="M40.04 70.756h-4.2C35.825 50.966 19.79 34.926 0 34.902v-4.2c22.106.03 40.017 17.948 40.04 40.054z" />
            <path fillOpacity={isConnected ? 1 : 0} d="M18.2 14v14.98c10.857 4.842 19.452 13.652 24.024 24.626h37.478V14H18.2z" />
        </g>
    </Ic>
);

IC08Cc.propTypes = {
    /** The colour of the small radio signals on the icon */
    accentColor: colorType,
    /** Whether to fill the inner rectange */
    isConnected: propTypes.bool,
    /** Icon colour */
    color: colorType,
};

IC08Cc.displayName = 'IC08Cc';

export default IC08Cc;
