import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {transition} from '../../../../common/animations';
import {fontFamily} from '../../../../common/typography';
import {white, kayoGreen} from '../../../../common/palette';
import {SCREEN_PHABLET} from '../../../../common/screen-sizes';

import IC88Beta from '../../../atoms/ic/88-beta';

const itemColor = white;

const BetaLogoContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    /* Using percentages because pixel values were not playing nice on mobile browsers */
    margin-top: -10%;
    width: 50%;
`;

const StyledLink = styled.a`
    display: block;
    position: relative;
    transition: ${transition('border-color', 'opacity')};
    opacity: 0.4;
    border-bottom: 1px solid transparent;
    padding: 2px 14px 25px;
    width: 18px;
    height: 18px;
    text-align: center;
    color: ${itemColor};
    font: 300 11px/1.36 ${fontFamily};

    ${mediaQuery({minWidthPx: SCREEN_PHABLET})`
        padding-right: 17px;
        padding-left: 17px;
        width: 30px;
        height: 30px;
    `}

    &[aria-current='true'] {
        opacity: 1;
        border-color: ${white};

        &:focus,
        &:hover,
        &:active {
            border-color: ${kayoGreen};
        }
    }

    &:focus {
        opacity: 1;
        outline: none;
        color: ${white};
    }

    &:hover {
        opacity: 1;
        text-decoration: none;
    }
`;

const StyledLabel = styled.span`
    position: absolute;
    right: 0;
    bottom: 7px;
    left: 0;
    text-align: center;
`;

const MenuItem = React.forwardRef(({
    location,
    title,
    isActive,
    isBeta,
    onClick,
    icon,
    ...htmlAttributes
}, ref) => (
    <StyledLink
        aria-current={isActive}
        href={location}
        onClick={onClick}
        ref={ref}
        {...htmlAttributes}
    >
        {/* Explicitly pass color in case any icons rely on it */}
        {React.cloneElement(icon, {color: itemColor})}

        {/* Leaving Beta logo support in just in case but can be deleted if needed */}
        {!!isBeta && (
            <BetaLogoContainer>
                <IC88Beta ariaLabel="Beta" color={itemColor} />
            </BetaLogoContainer>
        )}

        <StyledLabel>
            {title}
        </StyledLabel>
    </StyledLink>
));

MenuItem.displayName = 'MenuItem';

MenuItem.propTypes = {
    location: propTypes.string,
    icon: propTypes.node,
    isActive: propTypes.bool,
    isBeta: propTypes.bool,
    title: propTypes.string,
    onClick: propTypes.func,
};

export default MenuItem;
