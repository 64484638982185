import {css} from 'styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {SMALLEST_SUPPORTED_WIDTH, SCREEN_PRETTY_LG_DESKTOP, SCREEN_REALLY_LG_DESKTOP} from './screen-sizes';
import {sizesAtViewportsToSizes} from './stringify-srcset-options';

export const TILE_PX_WIDTHS = {
    [SMALLEST_SUPPORTED_WIDTH]: 225,
    [SCREEN_PRETTY_LG_DESKTOP]: 327,
    [SCREEN_REALLY_LG_DESKTOP]: 437,
};

export const TILE_SIZES_ATTRIBUTE = sizesAtViewportsToSizes(TILE_PX_WIDTHS);

/**
 * This is bad and should be moved into the relevant components.
 * However I need to work with Claire further on this before I want to go too far down that rabbit hole
 */
export default css`
    width: ${TILE_PX_WIDTHS[SMALLEST_SUPPORTED_WIDTH]}px;

    ${mediaQuery({minWidthPx: SCREEN_PRETTY_LG_DESKTOP})`
        width: ${TILE_PX_WIDTHS[SCREEN_PRETTY_LG_DESKTOP]}px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_REALLY_LG_DESKTOP})`
        width: ${TILE_PX_WIDTHS[SCREEN_REALLY_LG_DESKTOP]}px;
    `}
`;

