import React from 'react';
import propTypes from 'prop-types';
import {rgba} from 'polished';
import styled from 'styled-components';
import smoothscroll from 'smoothscroll-polyfill';
import IntersectionObserver from 'inteobs';
import attempt from 'lodash/attempt';
import debounce from 'lodash/debounce';
import invoke from 'lodash/invoke';
import findLastIndex from 'lodash/findLastIndex';
import ResizeObserver from 'resize-observer-polyfill';
import classnames from 'classnames';

import {mediaQuery, stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';
import {isBrowser} from '@fsa-streamotion/browser-utils';

import {Section} from '../../../../common/normalized-styled-components';
import {black, white} from '../../../../common/palette';
import BA26CarouselBtn from '../../../atoms/ba/26-carousel-btn';

import {
    SCREEN_320_MOBILE,
    SCREEN_768_TABLET,
    SCREEN_1024_DESKTOP,
    SCREEN_1280_DESKTOP,
    SCREEN_1680_DESKTOP,
    SCREEN_1920_DESKTOP,
    SCREEN_2560_DESKTOP,
} from '../../../../common/screen-sizes';
import {CONTENT_EDGE_SPACING_PERCENT} from '../../../../common/style-constants';

import CarouselSlot from './components/carousel-slot';
import Filmstrip from './components/filmstrip';

import {
    ZOOM_ALLOWANCE_PX,
    Z_INDEX_CONTROLS,
    SCROLL_DURATION_MS,
    Z_INDEX_FILMSTRIP,
} from './components/constants';

/**
 * Optionally takes breakpointPageSizes and returns the smallest value.
 *
 * @param {Object[]} [breakpointPageSizes] object with key: value of breakpoint: numberOfVisibleTilesPerRow
 *
 * @returns {number} Smallest number of visible tiles for default value of --cam-05-tiles-per-row
 */
function leastNumberOfVisibleTilesPerRow({breakpointPageSizes}) {
    return Math.min(...Object.values(breakpointPageSizes));
}

/**
 * Optionally takes breakpointPageFontSizes and returns the largest value.
 *
 * @param {Object[]} [breakpointPageFontSizes] object with key: value of breakpoint: fontSizeForTile
 *
 * @returns {number} Font size for smallest number of visible tiles for default value of --infinity-cam-tiles-font-size
 */
function defaultFontSizeForTiles({breakpointPageFontSizes}) {
    return Math.max(...Object.values(breakpointPageFontSizes));
}

/**
 * Optionally takes breakpointPageSizes and returns an array of media queries containing --cam-05-tiles-per-row for
 * each key/value of breakpointPageSizes. Internally this calls our mediaQuery function.
 *
 * @param {Object[]} [breakpointPageSizes] object with shape breakpointSizeInPixels: numberOfVisibleTilesPerRow
 *
 * @returns {Array} Array of media queries containing number of visible tiles against --cam-05-tiles-per-row
 */
function breakpointPageSizesMediaQueries({breakpointPageSizes}) {
    return (
        Object.entries(breakpointPageSizes)
            .map(([breakpointPx, numVisibleTiles]) => mediaQuery({minWidthPx: breakpointPx})`
                --cam-05-tiles-per-row: ${numVisibleTiles};
            `)
    );
}

/**
 * Optionally takes breakpointPageFontSizes and returns an array of media queries containing --infinity-cam-tiles-font-size for
 * each key/value of breakpointPageFontSizes. Internally this calls our mediaQuery function.
 *
 * @param {Object[]} [breakpointPageFontSizes] object with shape breakpointSizeInPixels: fontSize for tiles
 *
 * @returns {Array} Array of media queries containing font size of tiles against --infinity-cam-tiles-font-size
 */
function getBreakpointPageFontSizesMediaQueries({breakpointPageFontSizes}) {
    return (
        Object.entries(breakpointPageFontSizes)
            .map(([breakpointPx, fontSize]) => mediaQuery({minWidthPx: breakpointPx})`
                --infinity-cam-tiles-font-size: ${fontSize}vw;
            `)
    );
}

const StyledSection = styled(Section)`
    --cam-05-gutter: 12px;
    --cam-05-tiles-per-row: ${leastNumberOfVisibleTilesPerRow};
    --infinity-cam-tiles-font-size: ${defaultFontSizeForTiles};
    --infinity-cam-tiles-line-height: 1.3;
    position: relative;
    z-index: 0; /* Because children of this component use z-indexes, wrap them in a 0 so they don't interfere with other components on a page */
    margin: 0;
    padding-bottom: 28px;
    width: 100%;

    ${breakpointPageSizesMediaQueries}

    ${getBreakpointPageFontSizesMediaQueries}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        --cam-05-gutter: 18px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1680_DESKTOP})`
        --cam-05-gutter: 24px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
        --cam-05-gutter: 30px;
    `}
`;

const StyledBA26CarouselBtn = styled(BA26CarouselBtn).attrs({
    stopMarker: 0.75,
})`
    display: flex;
    z-index: ${Z_INDEX_CONTROLS};
    margin-top: ${ZOOM_ALLOWANCE_PX / 2}px;
    padding: 0;
    width: ${CONTENT_EDGE_SPACING_PERCENT}%;
    height: calc(100% - ${ZOOM_ALLOWANCE_PX}px);
`;

const OuterViewport = styled.div`
    position: relative;
    width: 100%;
`;

const Viewport = styled.div`
    --mid-panel-start: ${CONTENT_EDGE_SPACING_PERCENT - 0.5}%;
    --mid-panel-end: ${100 - CONTENT_EDGE_SPACING_PERCENT + 0.5}%;
    -webkit-overflow-scrolling: touch;
    display: flex;
    position: relative;
    transform: translateX(0);
    transition: transform ${SCROLL_DURATION_MS}ms ease-out;
    z-index: ${Z_INDEX_FILMSTRIP};
    margin: -${ZOOM_ALLOWANCE_PX}px 0;
    padding: ${ZOOM_ALLOWANCE_PX}px 0;
    width: 100%;
    overflow-x: scroll;
    white-space: nowrap;
    scroll-behavior: ${(({isAnimated}) => isAnimated ? 'smooth' : 'auto')};

    scrollbar-width: none;
    -ms-overflow-style: none;

    /* stylelint-disable function-parentheses-space-inside, function-comma-space-after */
    /* Sorry Stylelint, we know what's best for readability */
    mask-image: linear-gradient(
        to right,
        ${rgba(black, 0.4)} var(--mid-panel-start),
        ${black} var(--mid-panel-start) var(--mid-panel-end),
        ${rgba(black, 0.4)} var(--mid-panel-end)
    );
    /* stylelint-enable function-parentheses-space-inside, function-comma-space-after */

    ${stylesWhen('isWaitingToMount')`
        &::before {
            display: inline-block;
            margin-left: -200%;
            width: 100%;
            content: '';
        }
    `}

    @supports (
        (scroll-snap-align: start)
        and (not (-webkit-overflow-scrolling: touch)) /* Unfortunately scroll snap seems to cause jitter on iOS devices when combined with lazy loading. This is a crude way of disabling it */
    ) {
        scroll-snap-type: x mandatory;
        scroll-snap-align: start;
        scroll-padding: ${CONTENT_EDGE_SPACING_PERCENT}%;
    }

    &::before,
    &::after {
        display: block;
        min-width: ${CONTENT_EDGE_SPACING_PERCENT}%;
        content: '';
    }

    &::-webkit-scrollbar {
        display: none;
    }

    > * {
        white-space: initial; /* prevent white-space inheritance */
    }
`;

const StyledTitleWithIconWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const TextWrapper = styled.span`
    color: ${white};
`;

export const CarouselTitleIconContainer = ({icon, children}) => (
    <StyledTitleWithIconWrapper>
        {!!icon && icon}
        <TextWrapper>{children}</TextWrapper>
    </StyledTitleWithIconWrapper>
);

CarouselTitleIconContainer.displayName = 'CarouselTitleIconContainer';

CarouselTitleIconContainer.propTypes = {
    icon: propTypes.node,
    children: propTypes.node,
};

/**
 * Step through each group to find the panel group and slide index based on the starting index
 * if the slides were in a flat array
 *
 * @param {Array} carouselData A list of panel groups, containing the title of the group and a list of elements
 * @param {number} startIndex the slide index the carousel should be
 * @returns {Object} If found, return the panel and slide index, if not, then the start
 */
function getSlideIndices(carouselData, startIndex) {
    const foundIndices = carouselData.reduce(
        (acc, {items}, panelIndex) => {
            if (acc.slideIndex === -1) {
                return items.length - 1 < acc.currentLength
                    ? {
                        // it's not in this list, because the startIndex is greater than the amount of indices in this list, so subtract these indices from currentLength
                        ...acc,
                        currentLength: acc.currentLength - items.length,
                    } : {
                        // it's in this list! So provide new panelIndex, and the currentLength should be the slideIndex
                        ...acc,
                        panelIndex,
                        slideIndex: acc.currentLength,
                    };
            }

            return acc;
        },
        {panelIndex: 0, slideIndex: -1, slotIndex: startIndex, currentLength: startIndex}
    );

    return foundIndices.slideIndex === -1
        ? {panelIndex: 0, slideIndex: 0, slotIndex: 0}
        : foundIndices;
}

/**
 * ASCII ART BECAUSE THINGS ARE COMPLICATED
 *
 *  - This is a specific component that relies on the carouselData prop in a specific structure where it has a "title" and a "data" prop. The "data" prop
 *    can be any React component, but it must have a title associated with it, for this component to process the slides into different panels
 *  - There are two indices that track each panel (which can have a differing number of slides) and each slide
 *
 * +-----------------------------------------------+-----------------------------------------------+-----------------------------------------------+
 * |                panel 0                        |                panel 1                        |                panel 2                        |
 * +-----------------------------------------------+-----------------------------------------------+-----------------------------------------------+
 * + Title 1                                       + Title 2                                       + Title 3                                       +
 * +-----------------------------------------------+-----------------------------------------------+-----------------------------------------------+
 * |     |     |     |     |     |     |     |     |     |     |     |     |     |     |     |     |     |     |     |     |     |     |     |     |
 * |slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|
 * |1    |2    |3    |4    |5    |6    |7    |8    |1    |2    |3    |4    |5    |6    |7    |8    |1    |2    |3    |4    |5    |6    |7    |8    |
 * +-----------------------------------------------+-----------------------------------------------+-----------------------------------------------+
 *
 */
//  Can't translate this to Hooks API due to 'getSnapshotBeforeUpdate()'
export default class CAM05EPG extends React.Component {
    static displayName = 'CAM05EPG';

    static propTypes = {
        /** How many slides to display for different screensizes */
        breakpointPageSizes: propTypes.objectOf(propTypes.number),
        /** Font size in VW for slide descriptions for different screensizes */
        breakpointPageFontSizes: propTypes.objectOf(propTypes.number),
        /** An array of items containing an element with an associated title */
        carouselData: propTypes.arrayOf(propTypes.shape({
            title: propTypes.node,
            items: propTypes.arrayOf(propTypes.any),
        })),
        /** Additional CSS classnames to be applied */
        className: classNameType,
        /** Show partial fake tiles at both the beginning and ends of carousels to make them look infinite. */
        hasHints: propTypes.bool,
        /** Show partial fake tiles at the beginning of carousels to make them look infinite. */
        hasLeftHint: propTypes.bool,
        /** For lazy evaluation purposes, what is the maximum slides that could appear on initial render? Use -1 to force eager loading */
        maxSlidesPerPage: propTypes.number,
        /** Callback for OR60SeaEpi to hook into for the tabs view update */
        onClickNavButtons: propTypes.func,
        /** Callback for OR60SeaEpi to hook into for the tabs view update */
        onScrollCarousel: propTypes.func,
        /** Index of the carousel based on the carouselData array */
        startIndex: propTypes.number,
        /** An object with the titles set as the keys, where it contains an object with at least an icon property */
        titleIcons: propTypes.arrayOf(propTypes.shape({
            key: propTypes.any,
            icon: propTypes.node,
        })),
    };

    static defaultProps = {
        breakpointPageSizes: {
            [SCREEN_320_MOBILE]: 1.6,
            [SCREEN_768_TABLET]: 3,
            [SCREEN_1024_DESKTOP]: 4,
            [SCREEN_1280_DESKTOP]: 5,
        },
        breakpointPageFontSizes: {
            [SCREEN_320_MOBILE]: 3.5,
            [SCREEN_768_TABLET]: 1.85,
            [SCREEN_1024_DESKTOP]: 1.4,
            [SCREEN_1280_DESKTOP]: 1.1,
            [SCREEN_1920_DESKTOP]: 1.05,
        },
        maxSlidesPerPage: 5,
        hasHints: true,
        carouselData: [],
        startIndex: 0,
        titleIcons: [],
    };

    state = {
        isActive: false,
        hasNextButton: false,
        hasNextFrame: false,
        hasPrevButton: false,
        hasPrevFrame: false,
        wakeupIndex: this.props.maxSlidesPerPage, // always show our initial page
        hasLeftHint: this.props.hasLeftHint,
        isWaitingToMount: true,
        viewportWidthPx: SCREEN_1024_DESKTOP,
        ...getSlideIndices(this.props.carouselData, this.props.startIndex),
    };

    componentDidMount() {
        if (this.isAnimated) {
            smoothscroll.polyfill(); // currently only safari and edge need this. used for the scrolling effect on prev/next button clicks
        }

        this.setNavigationButtonVisibility();

        if (this.props.maxSlidesPerPage < 0) {
            return; // we've been told to load everything. don't bother using an observer
        }

        // for performance reasons, we're going to use a single observer on the carousel
        this.intersectionObserver = new IntersectionObserver((elements) => { // eslint-disable-line compat/compat
            const intersectingEls = elements
                .filter(({intersectionRatio}) => intersectionRatio > 0)
                .map(({target}) => target);

            const highestVisibleIndex = findLastIndex(this.slotEls, (slotEl) => intersectingEls.includes(slotEl));

            const wakeupIndex = Math.min(
                this.props.carouselData[0]?.items?.length || 0, // don't wake up children we don't have
                highestVisibleIndex + this.props.maxSlidesPerPage, // whatever is visible,
            );

            if (wakeupIndex > this.state.wakeupIndex) {
                // once you wake your children, they wont EVER go back to sleep >_>
                // this means we can stop observing any children before our wakeup index
                this.slotEls
                    .slice(this.state.wakeupIndex, wakeupIndex)
                    .forEach((slotEl) => this.intersectionObserver.unobserve(slotEl));

                this.setState({wakeupIndex});
            }
        });

        this.resizeObserver = new ResizeObserver(this.debouncedHandleResize);
        this.resizeObserver.observe(this.rootRef.current);

        // now connect each of our carousel slot elements to the observer
        this.slotEls.forEach((slotEl) => void this.intersectionObserver.observe(slotEl));
    }

    /**
     * We use this react lifecycle hook to allow us to clean up intersection observer listeners on elements that will soon not exist anymore
     * https://reactjs.org/docs/react-component.html#getsnapshotbeforeupdate
     *
     * @returns {null} returns null
     */
    getSnapshotBeforeUpdate() {
        if (this.intersectionObserver && this.slotEls.length) {
            return this.slotEls.slice(); // hold on to our old slot references. if one or more is removed, we need to disconnect observers
        }

        return null;
    }

    componentDidUpdate(prevProps, prevState, prevSlotEls) {
        if (!prevSlotEls || !prevSlotEls.length || !this.intersectionObserver) {
            return;
        }

        // move the carousel if startIndex changes
        if (prevProps.startIndex !== this.props.startIndex) {
            // send through decrement/increment based on if the new startIndex is earlier than the current slotIndex (currently active tile)
            const indexChange = Math.abs(this.state.slotIndex - this.props.startIndex)
                * (this.state.slotIndex > this.props.startIndex ? -1 : 1);

            this.debouncedHandleIndexChange({indexChange});
        }

        // stop observing elements that have been removed
        prevSlotEls
            .filter((prevSlotEl) => prevSlotEl && !this.slotEls.includes(prevSlotEl))
            .forEach((unobserveEl) => void this.intersectionObserver.unobserve(unobserveEl));

        // start observing elements that have been added
        this.slotEls
            .filter((slotEl) => slotEl && !prevSlotEls.includes(slotEl))
            .forEach((unobserveEl) => void this.intersectionObserver.observe(unobserveEl));
    }

    componentWillUnmount() {
        invoke(this.intersectionObserver, 'disconnect');
        invoke(this.resizeObserver, 'disconnect');
        invoke(this.debouncedSetNavigationButtonVisibility, 'cancel'); // e.g. cancel debounce
        invoke(this.debouncedHandleResize, 'cancel');
        clearTimeout(this.filmstripAnimationTimeout);
    }

    isAnimated = isBrowser() && !window.matchMedia('(prefers-reduced-motion: reduce), (update: slow)').matches;

    setNavigationButtonVisibility = (increment = 0) => {
        const currentPanel = this.panelEls[this.state.panelIndex];

        if (!currentPanel) {
            return;
        }

        // We need to know if we've "crossed state lines"--travelled into another panel's territory
        const newSlideIndices = getSlideIndices(this.props.carouselData, this.state.slotIndex + increment);

        const {offsetLeft: panelOffsetLeft} = this.panelEls[newSlideIndices.panelIndex];

        const {
            scrollWidth: viewportScrollWidth,
            offsetWidth: viewportWidth,
        } = this.viewportEl;
        const {width} = this.slotEls[this.state.slotIndex].getBoundingClientRect();

        let hasNextFrame = viewportWidth < viewportScrollWidth;

        let hasReachedEndOfScroll = viewportScrollWidth - panelOffsetLeft < viewportWidth;

        if (increment) {
            const {offsetLeft: slideLeft} = this.slotEls[this.state.slotIndex + increment];

            this.viewportEl.scrollTo({left: panelOffsetLeft + slideLeft});

            //  We are at the end of the carousel when we ran out of space to scroll further to the right, so we stop showing the right arrow
            hasReachedEndOfScroll = viewportScrollWidth - (panelOffsetLeft + slideLeft) < viewportWidth;
            hasNextFrame = viewportScrollWidth - (panelOffsetLeft + slideLeft) > viewportWidth;
        }

        // On the initial state, sometimes the carousel is shorter than the viewport width
        // Firefox is saying viewportScrollLeft = 0 (initial value), while Chrome is the value post-scrollTo, so I had to use a different way to calculate whether it has a previous frame or not
        const hasPrevFrame = newSlideIndices.slotIndex > 0 && hasNextFrame;

        if (this.state.hasNextFrame !== hasNextFrame || this.state.hasPrevFrame !== hasPrevFrame) {
            // We've run out of filmstrip to prev/next to
            this.setState(
                ({hasLeftHint, slideIndex, slotIndex}) => ({
                    hasNextButton: hasNextFrame || (!hasReachedEndOfScroll && this.props.hasHints && hasPrevFrame),
                    hasNextFrame,
                    hasPrevButton: hasPrevFrame || (slotIndex + increment > 0 && hasLeftHint && hasNextFrame),
                    hasPrevFrame,
                    carouselWidthPx: width,
                    slideIndex: slideIndex + increment,
                    slotIndex: slotIndex + increment,
                }),
                () => attempt(this.props.onClickNavButtons, {slotIndex: this.state.slotIndex}),
            );
        } else {
            // There should still be more filmstrip to prev/next to
            this.setState(
                ({hasLeftHint, slideIndex, slotIndex}) => ({
                    carouselWidthPx: width,
                    slideIndex: slideIndex + increment,
                    slotIndex: slotIndex + increment,
                    hasPrevButton: hasPrevFrame || (slotIndex + increment > 0 && hasLeftHint && hasNextFrame),
                    hasPrevFrame,
                }),
                () => attempt(this.props.onClickNavButtons, {slotIndex: this.state.slotIndex}),
            );
        }
    };

    filmstripAnimationTimeout = null;

    prevFrame = () => void this.setNavigationButtonVisibility(-1);
    nextFrame = () => void this.setNavigationButtonVisibility(1);
    previousPanel = () => {
        const {panelIndex, slotIndex} = this.state;

        if (panelIndex > 0) {
            this.viewportEl.scrollTo({
                left: this.panelEls[panelIndex - 1].offsetLeft + this.slotEls[slotIndex - 1].offsetLeft,
            });

            this.setState(({panelIndex, slotIndex}) => (
                Object.assign(
                    {
                        panelIndex: panelIndex - 1,
                        slideIndex: this.props.carouselData[panelIndex - 1].items.length - 1,
                        slotIndex: slotIndex - 1,
                        // @TODO: Figure out wakeup Index
                    },
                    this.props.hasHints && {hasLeftHint: true}
                )
            ), () => {
                this.setNavigationButtonVisibility();
            });
        } else {
            this.setNavigationButtonVisibility();
        }
    };

    nextPanel = () => {
        const {panelIndex, slotIndex} = this.state;
        const nextPanelIndex = (panelIndex + 1) % this.panelEls.length;

        this.viewportEl.scrollTo({
            left: this.panelEls[nextPanelIndex].offsetLeft,
        });

        this.setState(() => (
            Object.assign(
                {
                    panelIndex: nextPanelIndex,
                    slideIndex: 0,
                    slotIndex: panelIndex === this.panelEls.length - 1 ? 0 : (slotIndex + 1),
                    // @TODO: Figure out wakeup Index
                },
                this.props.hasHints && {hasLeftHint: true}
            )
        ), () => {
            this.setNavigationButtonVisibility();
        });
    };

    focusNext = () => {
        const nextSlotIndex = this.slotEls.findIndex((el) => el.contains(document.activeElement)) + 1;

        invoke(
            this.slotEls[nextSlotIndex < this.slotEls.length ? nextSlotIndex : 0].querySelector('button, a'),
            'focus'
        );
    };

    focusPrev = () => {
        const prevSlotIndex = this.slotEls.findIndex((el) => el.contains(document.activeElement)) - 1;

        invoke(
            this.slotEls[prevSlotIndex < 0 ? this.slotEls.length - 1 : prevSlotIndex].querySelector('button, a'),
            'focus'
        );
    };

    handleKeyDown = (event) => {
        switch (event.key) {
            case 'ArrowRight':
            case 'Right':
                event.preventDefault();

                return void this.focusNext();

            case 'ArrowLeft':
            case 'Left':
                event.preventDefault();

                return void this.focusPrev();

            default:
                return;
        }
    };

    visibleFilmStripEl = null;
    debouncedSetNavigationButtonVisibility = debounce(() => void this.setNavigationButtonVisibility(), 100); // check if we've reached either end of the carousel after a short interval
    debouncedHandleResize = debounce(() => {
        this.viewportEl.scrollLeft = (this.panelEls[this.state.panelIndex]?.offsetLeft || 0)
            + (this.slotEls[this.props.startIndex]?.offsetLeft || 0);

        this.setState({
            viewportWidthPx: this.viewportEl.offsetWidth,
        });

        this.setNavigationButtonVisibility();
    }, 50);

    // use setNavigationButtonVisibility to perform the scrolling and updating of indices’ state
    debouncedHandleIndexChange = debounce(({indexChange}) => void this.setNavigationButtonVisibility(indexChange), 50);

    intersectionObserver = null;
    resizeObserver = null;
    slotEls = [];
    rootRef = React.createRef();
    panelEls = [];
    childrenOffsetLefts = [];
    viewportRef = (el) => {
        if (!el) {
            return;
        }

        if (!this.viewportEl) {
            this.setState({isWaitingToMount: false});
        }

        this.viewportEl = el;
    };

    activate = () => void this.setState({isActive: true});
    deactivate = () => void this.setState({isActive: false});

    render() {
        const {
            carouselData,
            titleIcons,
            breakpointPageSizes,
            breakpointPageFontSizes,
            className,
            hasHints,
            maxSlidesPerPage,
        } = this.props;

        this.slotEls.length = carouselData.reduce((acc, {items}) => acc + items.length, 0);

        return (
            <StyledSection
                breakpointPageSizes={breakpointPageSizes}
                breakpointPageFontSizes={breakpointPageFontSizes}
                className={classnames('CAM05EPG', className)}
                onKeyDown={this.handleKeyDown}
                ref={this.rootRef}
            >
                <OuterViewport
                    onBlur={this.deactivate}
                    onFocus={this.activate}
                    onMouseEnter={this.activate}
                    onMouseLeave={this.deactivate}
                >
                    {!!this.state.hasPrevButton && (
                        <StyledBA26CarouselBtn
                            isActive={this.state.isActive}
                            direction="left"
                            onClick={this.state.slideIndex === 0 ? this.previousPanel : this.prevFrame}
                        />
                    )}
                    <Viewport
                        id="viewport"
                        ref={this.viewportRef}
                        isAnimated={this.isAnimated}
                        isWaitingToMount={this.state.isWaitingToMount}
                        hasHint={!(this.state.hasLeftHint && this.state.hasPrevButton)}
                        onScroll={(event) => attempt(this.props.onScrollCarousel, {event, childrenOffsetLefts: this.childrenOffsetLefts})}
                    >
                        {carouselData.map(({title, items}, panelIndex) => {
                            const titleIconsMatchingIndex = titleIcons.findIndex(({key}) => key === title);

                            const titleToRender = titleIconsMatchingIndex > -1
                                ? (
                                    <CarouselTitleIconContainer icon={titleIcons[titleIconsMatchingIndex]?.icon}>
                                        {title}
                                    </CarouselTitleIconContainer>
                                )
                                : title;

                            return (
                                <Filmstrip
                                    ref={(panelEl) => void Object.assign(
                                        this.panelEls,
                                        {[panelIndex]: panelEl}
                                    )}
                                    onScroll={this.debouncedSetNavigationButtonVisibility}
                                    key={panelIndex}
                                    hasNextHint={hasHints && this.state.hasNextButton}
                                    hasPrevHint={this.state.hasLeftHint && this.state.hasPrevButton}
                                    data-index={panelIndex}
                                    carouselWidthPx={this.state.carouselWidthPx}
                                    title={titleToRender}
                                >
                                    {items.map((slide, index) => {
                                        const lengthOfPrevLists = carouselData
                                            .slice(0, panelIndex)
                                            .reduce((acc, {items}) => acc + items.length, 0);

                                        return (
                                            <CarouselSlot
                                                ref={
                                                    (slotEl) => {
                                                        Object.assign(this.slotEls, {[lengthOfPrevLists + index]: slotEl}); // specifically not using array.push below as ref callbacks don't fire in DOM order

                                                        Object.assign(this.childrenOffsetLefts,
                                                            {[lengthOfPrevLists + index]: slotEl?.getClientRects()[0]?.left});
                                                    }}
                                                isBeingLazy={maxSlidesPerPage > -1 && index > this.state.wakeupIndex}
                                                key={`panel-${panelIndex}-slide-${index}`}
                                                viewportWidthPx={this.state.viewportWidthPx}
                                            >
                                                {slide}
                                            </CarouselSlot>
                                        );
                                    })}
                                </Filmstrip>
                            );
                        })
                        }
                    </Viewport>
                    {!!this.state.hasNextButton && (
                        <StyledBA26CarouselBtn
                            isActive={this.state.isActive}
                            direction="right"
                            onClick={this.state.slideIndex === carouselData[this.state.panelIndex].items.length - 1
                                ? this.nextPanel : this.nextFrame
                            }
                        />
                    )}
                </OuterViewport>
            </StyledSection>
        );
    }
}
