import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import property from 'lodash/property';
import {rgba} from 'polished';

import {colorType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {coal} from '../../../../common/palette';

const StyledDiv = styled.div`
    background: linear-gradient(${property('degrees')}deg, ${property('colorFrom')} 0%, ${getStopMarkerColor} ${property('stopMarkerPerc')}%, ${property('colorTo')} 100%);
    width: ${({widthPx}) => widthPx ? `${widthPx}px` : '100%'};
    height: ${({heightPx}) => heightPx ? `${heightPx}px` : '100%'};
`;

const GA25CaGrad = ({
    className,
    colorFrom = coal,
    colorTo = 'transparent',
    heightPx,
    isFromLeft,
    widthPx,
    stopMarker = 0,
    ...htmlAttributes
}) => (
    <StyledDiv
        {...htmlAttributes}
        className={classnames('GA25CaGrad', className)}
        degrees={isFromLeft ? '90' : '270'}
        heightPx={heightPx}
        colorFrom={colorFrom}
        colorTo={colorTo}
        stopMarkerPerc={stopMarker * 100}
        widthPx={widthPx}
    />
);

GA25CaGrad.displayName = 'GA25CaGrad';

GA25CaGrad.propTypes = {
    className: classNameType,
    colorFrom: colorType,
    colorTo: colorType,
    heightPx: propTypes.number,
    isFromLeft: propTypes.bool,
    stopMarker: propTypes.number,
    widthPx: propTypes.number,
};

export default GA25CaGrad;

function getStopMarkerColor({colorFrom}) {
    if (!colorFrom || colorFrom === 'transparent') {
        return 'transparent';
    }

    return rgba(colorFrom, 0.5);
}
