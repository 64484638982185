export const AUDIENCE = {
    development: 'dev.streamotion.com.au',
    testing: 'dev.streamotion.com.au',
    staging: 'staging.streamotion.com.au',
    production: 'streamotion.com.au',
};

export const DOMAIN = {
    development: 'auth-dev.streamotion.com.au',
    testing: 'auth-dev.streamotion.com.au',
    staging: 'auth-staging.streamotion.com.au',
    production: 'auth.streamotion.com.au',
};

export const REALM = {
    development: 'dev-martian-database',
    testing: 'dev-martian-database',
    staging: 'stg-martian-database',
    production: 'prod-martian-database',
};

export const ENVIRONMENT = {
    development: 'development',
    testing: 'testing',
    staging: 'staging',
    production: 'production',
};

export const BRAND = {
    binge: 'binge',
    kayo: 'kayo',
    flash: 'flash',
    magneto: 'magneto',
    lifestyle: 'lifestyle',
};

export const CLIENT_ID = {
    development: {
        magneto: 'NEPlvEauEKuiQ1rCL3Hz9GCX20Fob7b7',
        lifestyle: 'A8f7hEhNw262dcjAYQlnZvMVu5XXqfsq',
    },
    testing: {
        binge: 'sPyiBgfIrRjaagUk9ko4lA0aFe2qtWQ4',
        lifestyle: '7bTTQ6UxUyEhVzxizs7xEx5Qs0N8ClUZ',
        flash: 'uAXrtFUnM8Akuy9WDuprpZn3Q8n9yWKv',
        kayo: '0yj3FUdRjfQHSulkQUJJu0V87z10EPU6',
    },
    staging: {
        binge: 'XPYxkZvdVKVGL0LdgKy22hBR6FkBwDOJ',
        lifestyle: '7bTTQ6UxUyEhVzxizs7xEx5Qs0N8ClUZ',
        flash: 'uAXrtFUnM8Akuy9WDuprpZn3Q8n9yWKv',
        kayo: 'm4jJdUXvZpME8TcCEcv7D9df0rZIMOFl',
        magneto: 'uZki3LdAkUybia2LnyYc24tsUPPYnw0c',
    },
    production: {
        binge: 'pM87TUXKQvSSu93ydRjDTqBgdYeCbdhZ',
        lifestyle: 'myeLJ32zE2UQyXmhcfPQGH2h1VDGFgom',
        flash: 'VAikyVpcSOohjVwfumZTynhQ8PPsK0O3',
        kayo: '3sC2Dx5X1tTwtGlW8f6cN6YnkOuUIjcj',
        magneto: 'lb4eHxd9AO7Y2NN9SVI8klM3FCqNl2Yh',
    },
};

export const TOKEN_SERVICE_BASE_URL = {
    development: 'https://tokenservice-sit.streamotion.com.au',
    staging:     'https://tokenservice-staging.streamotion.com.au',
    production:  'https://tokenservice.streamotion.com.au',
};

export const TOKEN_SERVICE_V2_BASE_URL = {
    development: 'https://tokenservice-sit.streamotion.com.au/v2',
    staging:     'https://tokenservice-staging.streamotion.com.au/v2',
    production:  'https://tokenservice.streamotion.com.au/v2',
};

export const SHOULD_FORCE_REFRESH_USER_TOKEN_STORAGE_KEY = {
    development: {
        magneto: 'magneto-development-force-refresh-user-token',
    },
    staging: {
        binge: 'ares-staging-force-refresh-user-token',
        lifestyle: 'lifestyle-staging-force-refresh-user-token',
        flash: 'flash-staging-force-refresh-user-token',
        kayo: 'martian-staging-force-refresh-user-token',
        magneto: 'magneto-staging-force-refresh-user-token',
    },
    production: {
        binge: 'ares-production-force-refresh-user-token',
        lifestyle: 'lifestyle-production-force-refresh-user-token',
        flash: 'flash-production-force-refresh-user-token',
        kayo: 'martian-production-force-refresh-user-token',
    },
};

/** V2 token product level - account status  */
export const PRODUCT_STATUS_KEY = 'https://streamotion.com.au/product/status';

/** V2 token overall - account status  */
export const ACCOUNT_STATUS_KEY = 'https://streamotion.com.au/account/status';

export const STATUS_KEYS = {
    binge: 'https://ares.com.au/status',
    lifestyle: 'https://lifestyle.com.au/status',
    flash: 'https://flash.com.au/status',
    kayo: 'https://kayosports.com.au/status',
};

export const USER_DETAILS_KEY = 'https://streamotion.com.au/user_details';

export const VIMOND_GEO_BLOCK_DETAILS_KEY = 'https://vimond/geoblock';

export const ID_KEY = 'sub';

export const POST_LOGIN_REDIRECT_STORAGE_KEY = {
    binge: 'ares-post-login-redirect',
    lifestyle: 'lifestyle-post-login-redirect',
    flash: 'flash-post-login-redirect',
    kayo: 'kayo-post-login-redirect',
    magneto: 'magneto-post-login-redirect',
};

export const POST_PROFILE_REDIRECT_STORAGE_KEY = {
    binge: 'ares-post-profile-redirect',
    lifestyle: 'lifestyle-post-profile-redirect',
    flash: 'flash-post-profile-redirect',
    kayo: 'kayo-post-profile-redirect',
    magneto: 'magneto-post-profile-redirect',
};

/**
 * @typedef {Object} CONTENT_ENTITLEMENT User content entitlements
 * @property {string} PREMIUM Full paying customer, no typical restrictions (not counting PPV)
 * @property {string} FREEMIUM They're not paying, but have given consent, so can only watch freebies
 * @property {string} NO_FREEMIUM_ALLOWED They need to at least agree to the freemium terms first!
 */
export const CONTENT_ENTITLEMENT = {
    PREMIUM: 'premium',
    FREEMIUM: 'freemium',
    NO_FREEMIUM_ALLOWED: 'no-freemium-allowed',
};

export const BILLING_PRODUCT_NAME_MAP = {
    binge: 'ares',
    lifestyle: 'lifestyle',
    flash: 'flash',
    kayo: 'kayo',
    magneto: 'magneto',
};

export const ACCOUNT_STATUS = {
    INIT: 'INIT', // user has a streamotion account, but hasn't verified their mobile
    VERIFIED_IDENTITY: 'VERIFIED_IDENTITY', // user has confirmed their mobile. for freemium users, this is the minimum account status for using the app
    ACTIVE_SUBSCRIPTION: 'ACTIVE_SUBSCRIPTION', // paid up customer
    INACTIVE_SUBSCRIPTION: 'INACTIVE_SUBSCRIPTION', // customer who has lapsed / is no longer a paying customer
};

export const ACCOUNT_STATUS_ACTIVE_SUBSCRIPTION_STATUSES = [
    'ACTIVE_SUBSCRIPTION',
];

export const ACCOUNT_STATUS_VERIFIED = [
    'VERIFIED_IDENTITY',
];

export const ACCOUNT_STATUS_PARTIAL_SIGNUP = [
    'INIT',
    'VERIFIED_IDENTITY',
];

export const ACCOUNT_STATUS_RETURNING = [
    'INACTIVE_SUBSCRIPTION',
];

export const ACCOUNT_AND_SUB_STATUSES = {
    INIT: [
        'INIT',
        'PROFILE_CREATED',
    ],
    VERIFIED_IDENTITY: [
        'VERIFIED_MOBILE',
    ],
    ACTIVE_SUBSCRIPTION: [
        'FREE_TRIAL',
        'PAYING_SUBSCRIPTION',
        'PENDING_CANCELLATION',
        'PENDING_DOWNGRADE',
        'PENDING_UPGRADE',
    ],
    INACTIVE_SUBSCRIPTION: [
        'SUBSCRIPTION_CANCELLED',
        'SUBSCRIPTION_DEACTIVATED',
        'PAYMENT_FAILED',
    ],
};

export const SUBSCRIPTION_TYPES = {
    ITUNES: 'ITUNES',
};

//  LocalStorage key for access_token (Entitled)
export const LOCALSTORAGE_KEY_ENTITLED_ACCESS_TOKEN = 'entitled-access-token';

//  LocalStorage keys for E2E override tokens
export const LOCALSTORAGE_KEY_AUTH0_ACCESS_TOKEN_OVERRIDE = 'auth0-access-token-e2e-override';
export const LOCALSTORAGE_KEY_ENTITLED_ACCESS_TOKEN_OVERRIDE = 'entitled-access-token-e2e-override';
export const LOCALSTORAGE_KEY_ID_TOKEN_OVERRIDE = 'id-token-e2e-override';
