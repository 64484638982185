import React from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {colorType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {coal} from '../../../../common/palette';

const StyledDiv = styled.div`
    background: ${({from, fromPerc, to}) => `linear-gradient(to bottom, ${from} ${fromPerc}%, ${to} 100%)`};
    width: ${({widthPx}) => widthPx ? `${widthPx}px` : '100%'};
    height: ${({heightPx}) => heightPx ? `${heightPx}px` : '100%'};
`;

const GA21CaGrd = ({
    className,
    colorFrom = 'transparent',
    colorTo = coal,
    fromPerc = 60,
    heightPx,
    widthPx,
    ...htmlAttributes
}) => (
    <StyledDiv
        {...htmlAttributes}
        className={classNames('GA21CaGrd', className)}
        fromPerc={fromPerc}
        from={colorFrom}
        to={colorTo}
        heightPx={heightPx}
        widthPx={widthPx}
    />
);

GA21CaGrd.displayName = 'GA21CaGrd';

GA21CaGrd.propTypes = {
    /** Additional class(es) */
    className: classNameType,
    /** Colour for the start of the gradient */
    colorFrom: colorType,
    /** Colour for the end of the gradient */
    colorTo: colorType,
    /** How much of the "colorFrom" do we see before transitioning to the second colour? */
    fromPerc: propTypes.number,
    /** Height of the component */
    heightPx: propTypes.number,
    /** Width of the component */
    widthPx: propTypes.number,
};

export default GA21CaGrd;
