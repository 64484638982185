import React from 'react';
import propTypes from 'prop-types';
import {useSwipeable} from 'react-swipeable';
import styled from 'styled-components';

import {stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';

import {white} from './palette';
import {visuallyHiddenBaseStyles} from './visually-hidden';

const ZINDEX_ACTIVE_SLIDE = 1;

export const KeyboardNavGuide = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0; /* note this is set by sibling selector of the StyledDiv */
    z-index: ${ZINDEX_ACTIVE_SLIDE};
    background: ${white};
    padding: 7px;
    font: var(--infinity-body-copy-3);

    ${stylesWhenNot('isKbGuideEnabled')`
        ${visuallyHiddenBaseStyles}
    `}
`;

const StyledDiv = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    &:focus {
        outline: 0;

        + ${KeyboardNavGuide} {
            opacity: 0.75;
        }
    }

    &:focus-within + ${KeyboardNavGuide} {
        opacity: 0.75;
    }
`;

const Swipeable = ({children, ...props}) => {
    const handlers = useSwipeable(props);

    return (
        <StyledDiv {...handlers}>
            {children}
        </StyledDiv>
    );
};

Swipeable.propTypes = {
    children: propTypes.node,
};

Swipeable.displayName = 'Swipeable';

export default Swipeable;
