import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import noop from 'lodash/noop';
import property from 'lodash/property';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType, srcsetOptionsType} from '@fsa-streamotion/custom-prop-types';

import {Main, Button} from '../../../common/normalized-styled-components';
import {fog} from '../../../common/palette';
import {SCREEN_768_TABLET, SCREEN_1024_DESKTOP, SCREEN_1280_DESKTOP, SCREEN_1920_DESKTOP} from '../../../common/screen-sizes';
import {Branding, brandingSizeStyles} from '../../../common/branding';

import GA05BgImg from '../../atoms/ga/05-bg-img';
import GA23CaArrow from '../../atoms/ga/23-ca-arrow';
import GA92BrandLogo from '../../atoms/ga/92-brand-logo';
import IC103Loading from '../../atoms/ic/103-loading';
import TM24AlertTile from '../../molecules/tm/24-alert-tile';
import OR55SkipOnboarding from '../../organisms/or/55-skip-onboarding';
import OR41PageNav from '../../organisms/or/41-page-nav';

const PageWrapper = styled(Main)`
    width: 100%;
    height: 100%;
    color: ${fog};
`;

const StickyWrapper = styled.div`
    display: grid;
    position: relative;
    grid-template: 35px auto min-content min-content 1fr auto / minmax(0, 1fr);
    grid-template-areas: 'backBtn'
        'header'
        '.'
        'content'
        '.'
        'section';
    height: 100%;
    min-height: 100vh;
`;

const StyledGA05BgImg = styled(GA05BgImg)`
    position: fixed;
    top: 0;
    left: 0;
`;

const BackBtn = styled(Button)`
    grid-area: backBtn;
    margin: 8px auto 0 7px;
    border: 0;
    background: 0;
    height: 19px;
    color: ${fog};
`;

const StyledHeading = styled(Branding)`
    display: flex;
    left: 50%;
    flex-direction: column;
    grid-area: header;
    transform: translateX(-50%);
`;

const StyledGA92BrandLogo = styled(GA92BrandLogo)`
    margin-top: 65px; /* 100px from top of browser. Calculated as: 100px - 35px header = 65px */
    ${brandingSizeStyles}
`;

/**
 * the value of margin top variant for different pages of onboarding based on designs should be:
 * - welcome screen: large
 * - skip/remove screens: medium
 * - notification screen (not used in onboarding yet): small
 * - channels/categories/summary screens: small
 */
const ContentWrapper = styled.div`
    --onboarding-content-wrapper-margin-top--small: 35px;
    --onboarding-content-wrapper-margin-top--medium: 68px;
    --onboarding-content-wrapper-margin-top--large: 76px;
    display: grid;
    grid-area: content;
    grid-gap: 7px;
    align-items: center;
    justify-content: center;
    justify-items: center;
    margin-top: var(--onboarding-content-wrapper-margin-top--${property('marginTopVariant')}, 0);
    margin-bottom: 30%;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        --onboarding-content-wrapper-margin-top--small: 42px;
        --onboarding-content-wrapper-margin-top--medium: 85px;
        --onboarding-content-wrapper-margin-top--large: 110px;
        margin-bottom: 20%;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        --onboarding-content-wrapper-margin-top--small: 62px;
        --onboarding-content-wrapper-margin-top--medium: 68px;
        margin-bottom: 10%;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        --onboarding-content-wrapper-margin-top--medium: 73px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        --onboarding-content-wrapper-margin-top--medium: 141px;
        --onboarding-content-wrapper-margin-top--large: 143px;
    `}
`;

const InstructionsContainer = styled.div`
    margin: 0 auto 27px;
    padding: 0 28px;
    max-width: 313px;
    text-align: center;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        padding: 0;
        max-width: 480px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        max-width: 694px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        max-width: 866px;
    `}
`;

const InstructionsTitle = styled.h4`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 10px;
    font: var(--infinity-header-4);
`;

const InstructionsText = styled.p`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 5px;
    font: var(--infinity-header-4);
`;

const StyledPageNav = styled(OR41PageNav)`
    position: fixed;
    bottom: 0;
`;

const CenteredLoader = styled(IC103Loading).attrs({
    size: '60px',
})`
    margin: 50px auto;
`;

const OnboardingTemplate = ({
    children,
    className,
    contentMarginTop = 'small',
    copy,
    defaultImageSrc,
    errorMessage,
    footerButtons,
    isLoading,
    isSkipping,
    mobilePhabletSrcsetOptions,
    onCancel = noop,
    onKeyDown = noop,
    onPrev,
    skipCopy,
    skipTitle,
    srcsetOptions = {},
    title,
    ...htmlAttributes
}) => (
    <PageWrapper
        {...htmlAttributes}
        aria-busy={isLoading}
        className={classnames(className, 'OnboardingTemplate')}
        onKeyDown={(e) => {
            if (e.key === 'Escape' && !e.defaultPrevented) {
                onCancel(e);
            } else {
                onKeyDown(e);
            }
        }}
    >
        {!!(srcsetOptions || defaultImageSrc) && (
            <StyledGA05BgImg
                alt=""
                defaultImageSrc={defaultImageSrc}
                mobilePhabletSrcsetOptions={mobilePhabletSrcsetOptions}
                srcsetOptions={srcsetOptions}
            />
        )}

        <StickyWrapper>
            {onPrev && (
                <BackBtn
                    onClick={onPrev}
                    aria-label="Go Back"
                    tabIndex="-1"
                >
                    <GA23CaArrow isLeft={true} />
                </BackBtn>
            )}

            <StyledHeading>
                <StyledGA92BrandLogo />
            </StyledHeading>

            <ContentWrapper marginTopVariant={isSkipping ? 'medium' : contentMarginTop}>
                {isLoading
                    ? <CenteredLoader />
                    : isSkipping
                        ? (
                            <OR55SkipOnboarding
                                title={skipTitle}
                                copy={skipCopy}
                            />
                        )
                        : (
                            <React.Fragment>
                                <InstructionsContainer>
                                    {!!title && <InstructionsTitle>{title}</InstructionsTitle>}
                                    {!!copy && <InstructionsText>{copy}</InstructionsText>}
                                </InstructionsContainer>
                                {!isLoading && !isSkipping && children}
                                {!!errorMessage && (<TM24AlertTile>{errorMessage}</TM24AlertTile>)}
                            </React.Fragment>
                        )}
            </ContentWrapper>

            {!!footerButtons && (
                <StyledPageNav
                    buttonRight={footerButtons.buttonRight}
                    buttonLeft={footerButtons.buttonLeft}
                    buttonCenter={footerButtons.buttonCenter}
                />
            )}
        </StickyWrapper>
    </PageWrapper>
);

OnboardingTemplate.propTypes = {
    /** Optional classnames */
    className: classNameType,
    /** Content node */
    children: propTypes.node,
    /** Configurable content for the copy */
    copy: propTypes.node,
    /** Image to use for browsers that don't support <picture>. Defaults to largest srcSetOption screen size */
    defaultImageSrc: propTypes.string,
    /** Optional error message feedback for the user */
    errorMessage: propTypes.string,
    /** Action buttons */
    footerButtons: propTypes.shape({
        buttonRight: propTypes.node,
        buttonLeft: propTypes.node,
        buttonCenter: propTypes.node,
    }),
    /** Is the template in a loading state */
    isLoading: propTypes.bool,
    /** Is the template in a skipping state */
    isSkipping: propTypes.bool,
    /** srcset for the page background image */
    mobilePhabletSrcsetOptions: srcsetOptionsType,
    /** Callback on the Escape key */
    onCancel: propTypes.func,
    /** Callback on any key except the Escape key */
    onKeyDown: propTypes.func,
    /** Callback on the back button click (toggles the `<` button on top left corner) */
    onPrev: propTypes.func,
    /** Configurable content for the skip dialog copy */
    skipCopy: propTypes.node,
    /** Configurable content for the skip dialog title */
    skipTitle: propTypes.node,
    /** SrcsetOptions object to use for everything other than mobile and phablet viewports */
    srcsetOptions: srcsetOptionsType,
    /** Configurable content for the title */
    title: propTypes.node,
    /** Margin between the content and the logo */
    contentMarginTop: propTypes.oneOf(['small', 'medium', 'large']),
};

OnboardingTemplate.displayName = 'OnboardingTemplate';

export default OnboardingTemplate;
