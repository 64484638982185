import React from 'react';
import Ic from '..';

const IC72Min = (args) => (
    <Ic {...args}>
        <path d="M36.2 36.6H15.5v5.6H39c1.5 0 2.8-1.2 2.8-2.8V16.7h-5.6v19.9zM63.8 36.6v-20h-5.6v22.7c0 1.5 1.2 2.8 2.8 2.8h23.5v-5.6l-20.7.1zM39 57.8H15.5v5.6h20.7v20h5.6V60.6c0-1.6-1.2-2.8-2.8-2.8zM58.2 60.6v22.7h5.6v-20h20.7v-5.6H61c-1.6.1-2.8 1.3-2.8 2.9z" />
    </Ic>
);

IC72Min.displayName = 'IC72Min';

export default IC72Min;
