import bacon from 'baconjs';
import superagent from 'superagent';

import {BILLING_API_URL} from '@fsa-streamotion/streamotion-web-widgets-common';

import {BILLING_PRODUCT_NAME_MAP} from '../../../utils/constants';
import stagingGiftCardHack from '../gifts/staging-gift-card-hack';

export default function getSubscribeToProductStream({
    brand,

    accessToken,
    platformEnv,
    email,
    firstName,
    lastName,
    giftCode,
    giftPin,
    phoneNumber,
    paymentGateway,
    paymentRefId,
    // promotionRatePlanIds = [],

    offerCode,
    ratePlanId,
    voucherCode,
    journeyDetails,

    // Optus SubHub Partnership IDs
    ddSubscriptionId, // docomo-id
    merchantSubscriptionId, // streamotion-id

    // telstra/partner stuff
    telstraOrderItemId, // from signupTelstraOrderItemNumber in sessionStorage
    channel, // from ?channel
    source, // from ?extcamp
    referralId, // from ?referralid
    marketing, // ?marketing
    partnerOffer, // voucher code if we have telstraOrderItemId, otherwise undefined
    campaign, // ?campaign
}) {
    const sorry4HaxGiftCard = stagingGiftCardHack({giftCard: giftCode, platformEnv}); // TODO: remove me sometime after launch pls
    const partnerOrderId = telstraOrderItemId; // yuck 🤮
    const product = BILLING_PRODUCT_NAME_MAP[brand];

    const requestPayload = {
        email,
        firstName,
        lastName,
        giftCard: sorry4HaxGiftCard,
        giftCardPin: giftPin,
        phoneNumber,
        paymentGateway,
        paymentRefId,
        // promotionRatePlanIds,
        offerCode, // offerName
        ratePlanId, // this is really the packagePriceId from COG.
        voucherCode,
        journeyDetails: journeyDetails || undefined,

        ddSubscriptionId,
        merchantSubscriptionId,

        telstraOrderItemId,
        source,
        referralId,
        channel: telstraOrderItemId ? 'tlsweb' : channel, // Override for telstra
        marketing,
        partnerOffer: partnerOrderId ? (partnerOffer || voucherCode) : null, // Only send the voucher as a partner offer when we have a partner order ID and it's not specifically been overridden
        campaign,
        partnerOrderId,
    };

    const url = `${BILLING_API_URL[platformEnv]}/subscribe/${product}`;

    return bacon.fromBinder((sink) => {
        superagent
            .post(url)
            .set('Authorization', `Bearer ${accessToken}`)
            .accept('json')
            .send(requestPayload)
            .then((response) => void sink(response.body))
            .catch((error) => {
                const errorCode = error.status;
                const errorBody = JSON.parse(error?.response?.text || '""');
                const errorDetails = errorBody
                    || {
                        code: errorCode,
                        message: 'An unknown error occurred during subscribe. Please try again shortly.',
                        longMessage: 'An unknown error occurred during subscribe. Please try again shortly.',
                        shortMessage: 'An unknown error occurred during subscribe. Please try again shortly.',
                    };

                sink(new bacon.Error({
                    errorCode,
                    errorDetails,
                    fullErrorMessage: `AW-Widgets: Couldn't create user subscription (${url}): ${errorCode} ${errorDetails.message}`,
                }));
            })
            .finally(() => void sink(new bacon.End()));

        return;
    });
}
