import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import {ink, slate} from '../../../../common/palette';

import Ic from '..';

const IC36Mail = ({
    color = ink,
    secondaryColor = slate,
    ...args
}) => (
    <Ic {...args}>
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h100v100H0z" />
            <path fill={secondaryColor} opacity=".35" d="M16.656 29.885a8.021 8.021 0 0 0-3.307 6.495v28.239c0 4.419 3.576 8.014 7.972 8.014h55.23c4.396 0 7.972-3.595 7.972-8.014V36.38a8.018 8.018 0 0 0-3.107-6.344c.013.052-10.814 7.798-32.48 23.238-21.584-15.572-32.344-23.368-32.28-23.389z" />
            <path fill={color} d="M21.32 28.367c-4.395 0-7.97 3.594-7.97 8.013v28.239c0 4.419 3.575 8.014 7.97 8.014h55.232c4.395 0 7.971-3.595 7.971-8.014V36.38c0-4.419-3.576-8.013-7.971-8.013H21.32zm0 47.633C15.08 76 10 70.894 10 64.619V36.38C10 30.105 15.079 25 21.32 25h55.232c6.242 0 11.32 5.105 11.32 11.38v28.239c0 6.275-5.078 11.381-11.32 11.381H21.32z" />
            <path fill={color} d="m48.84 54.94.156.006a1.677 1.677 0 0 1-1.156-.465l.097.085a1.672 1.672 0 0 1-.048-.04l-.05-.045a1.714 1.714 0 0 1-.041-.042l-.012-.013-32.578-23.579a1.69 1.69 0 0 1 .054-2.382 1.669 1.669 0 0 1 2.367.055l31.376 22.71 31.83-22.71a1.669 1.669 0 0 1 2.245-.16l.122.105c.67.645.693 1.71.055 2.382l-33.05 23.579a1.7 1.7 0 0 1-.054.055l-.044.04a1.672 1.672 0 0 1-.054.046l.098-.086a1.688 1.688 0 0 1-.804.428 1.971 1.971 0 0 1-.162.027c-.063.007-.127.01-.19.01l.161-.007a1.678 1.678 0 0 1-.162.007z" />
            <path fill={color} d="M40.732 47.55 13.208 67.1a1.69 1.69 0 0 0 .054 2.381c.668.64 1.729.617 2.367-.055l27.523-19.55a1.69 1.69 0 0 0-.054-2.381 1.665 1.665 0 0 0-2.366.055zm16.932.27L84.572 67.1a1.69 1.69 0 0 1-.055 2.381 1.669 1.669 0 0 1-2.367-.055l-26.906-19.28a1.69 1.69 0 0 1 .053-2.381 1.665 1.665 0 0 1 2.367.055z" />
        </g>
    </Ic>
);

IC36Mail.propTypes = {
    /** The icon's primary color */
    color: colorType,
    /** The icon's secondary color */
    secondaryColor: colorType,
};

IC36Mail.displayName = 'IC36Mail';

export default IC36Mail;
