import React, {Component} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Fieldset} from 'normalized-styled-components';
import classnames from 'classnames';
import invoke from 'lodash/invoke';
import noop from 'lodash/noop';
import range from 'lodash/range';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import VisuallyHidden from '../../../../common/visually-hidden';
import IA06NumberBox from '../../../atoms/ia/06-number-box';

const UnstyledFieldset = styled(Fieldset)`
    display: inline-block;
    margin: 0;
    border: 0;
    padding: 0;
    text-align: center;
`;

class IM04Code extends Component {
    static displayName = 'IM04Code';

    static propTypes = {
        disabled: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
        numberOfDigits: propTypes.number,
        /**
         * Allows the value to be changed externally. Synchronise with value (like you would with any controlled
         * component) from onComplete to make this work properly.
         */
        value: propTypes.string,
        onComplete: propTypes.func,
        className: classNameType,
    };

    static defaultProps = {
        numberOfDigits: 6,
        value: '',
        onComplete: noop,
    };

    state = {
        numberOfDigits: this.props.numberOfDigits, // eslint-disable-line react/no-unused-state
        value: '',
        values: Object.assign(
            Array.from({length: this.props.numberOfDigits}, () => ''),
            Object.values(this.props.value.slice(0, this.props.numberOfDigits).split(''))
        ),
    };

    static getDerivedStateFromProps(props, state) {
        const {numberOfDigits: oldNumberOfDigits, value: oldValue} = state;
        const {numberOfDigits, value} = props;

        const newState = {
            numberOfDigits,
            value,
        };

        // If this happens, just clear the value. It shouldn't change while we're entering digits.
        if (oldNumberOfDigits !== numberOfDigits) {
            newState.values = Array.from({length: props.numberOfDigits}, () => '');

            // Nothing more to do
            return newState;
        }

        // If the value changed from the outside, apply it to the state. Lets us externally clear the form.
        if (oldValue !== value) {
            newState.values = Object.assign(
                Array.from({length: numberOfDigits}, () => ''),
                Object.values(value.slice(0, numberOfDigits).split(''))
            );
        }

        return newState;
    }

    componentDidMount() {
        this.applyValueIfUpdated('');
    }

    componentDidUpdate(prevProps, prevState) {
        this.applyValueIfUpdated(prevState.value);
    }

    applyValueIfUpdated(previousValue) {
        if (this.state.value !== previousValue && this.fieldRefs.length > 0) {
            this.fieldRefs.forEach((fieldRef, index) => {
                fieldRef.current.value = this.state.values[index] || '';
            });

            if (this.checkHasCompleteValue()) {
                this.props.onComplete(this.props.value);
            } else {
                this.moveFocus(this.props.value.length);
            }
        }
    }

    fieldRefs = range(this.props.numberOfDigits).map(React.createRef);

    checkHasCompleteValue() {
        return this.state.values.filter(Boolean).length === this.props.numberOfDigits;
    }

    moveFocus(destinationIndex) {
        // Don't promote along from the last field
        invoke(this.fieldRefs, [destinationIndex, 'current', 'focus']);
    }

    // Handles logic around updating internal values, and moving focus between input fields
    onFieldChange(fieldIndex, fieldValue) {
        const value = fieldValue.trim();
        const newValues = this.state.values.slice(); // eslint-disable-line react/no-access-state-in-setstate

        if (value.length) {
            // If the new value would be more than one character (e.g. after copy paste),
            // Set the next fields along rather than putting multiple characters into this field
            const charactersEntered = value.split('');

            charactersEntered.forEach((character, index) => {
                if (fieldIndex + index < this.props.numberOfDigits) {
                    newValues[fieldIndex + index] = character;
                }
            });
        } else {
            newValues[fieldIndex] = '';
        }

        this.setState({values: newValues}, () => {
            // Update field values
            this.state.values.forEach((value, index) => {
                this.fieldRefs[index].current.value = value;
            });

            if (this.checkHasCompleteValue()) {
                this.props.onComplete(newValues.join(''));
            }

            const nextIndex = value.length
                ? fieldIndex + value.length // If data was entered, go to the first cell where no data exists
                : fieldIndex; // On backspaces, don't change our focus, as that's handled by the onKeyDown event

            this.moveFocus(nextIndex);
        });
    }

    // Handles special logic around arrow key navigation and deletions
    onKeyDown = (index, event) => {
        const isBackSpaceOrDelete = event.key === 'Delete' || event.key === 'Backspace';

        if (event.target.value.length && isBackSpaceOrDelete) {
            // On backspace/delete we want to clear the field, but we can't trust the browser to do this
            // because we don't have guarantees about where the cursor is
            // Therefore we manually set the input to blank, and prevent the event from propagating to stop onFieldChange firing twice
            this.onFieldChange(index, '');
            event.preventDefault();
        }

        if (
            event.key === 'ArrowLeft' // Left arrow key
            || (!event.target.value.length && isBackSpaceOrDelete) // Delete button presses for empty fields
        ) {
            // Move focus backwards
            this.moveFocus(index - 1);
        } else if (event.key === 'ArrowRight') {
            // Move focus forwards
            this.moveFocus(index + 1);
        }

        if (event.target.value.length && event.key.length === 1) {
            // If the input has a value and we typed a letter, clear the value and let onChange change it
            this.fieldRefs[index].current.value = '';
        }
    };

    render() {
        return (
            <UnstyledFieldset className={classnames('IM04Code', this.props.className)}>
                <VisuallyHidden as="legend">Verification Code</VisuallyHidden>
                {range(this.props.numberOfDigits).map((index) => (
                    <IA06NumberBox
                        key={index}
                        onChange={(event) => void this.onFieldChange(index, event.target.value)}
                        onKeyDown={(event) => void this.onKeyDown(index, event)}
                        disabled={this.props.disabled}
                        ref={this.fieldRefs[index]}
                        aria-label={`Verification code digit ${index + 1}`}
                    />
                ))}
            </UnstyledFieldset>
        );
    }
}

export default IM04Code;
