import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import {onyx} from '../../../../common/palette';
import Ic from '..';

const IC253Liked = ({color = onyx, ...args}) => (
    <Ic {...args}>
        <g fill="none">
            <path fill={color} d="M30.733 29.36c.31-.4.595-.802.918-1.174 1.319-1.521 3.001-2.52 5.109-2.763 2.507-.287 4.774.251 6.587 1.977 1.139 1.084 1.743 2.411 1.997 3.89.434 2.53-.497 4.677-2.15 6.628-1.534 1.813-3.41 3.286-5.42 4.624a49.3 49.3 0 01-6.754 3.74.579.579 0 01-.544 0c-3.344-1.549-6.506-3.355-9.334-5.629-1.561-1.254-2.963-2.64-3.981-4.324-.76-1.253-1.168-2.588-1.105-4.03.087-1.97.756-3.724 2.33-5.116 1.128-1 2.487-1.55 4.037-1.75 1.497-.192 2.935-.053 4.317.512 1.67.68 2.912 1.8 3.86 3.219l.133.195zm37.8 0c.31-.4.595-.802.918-1.174 1.319-1.521 3.001-2.52 5.109-2.763 2.507-.287 4.774.251 6.587 1.977 1.139 1.084 1.743 2.411 1.997 3.89.434 2.53-.497 4.677-2.15 6.628-1.534 1.813-3.41 3.286-5.42 4.624a49.3 49.3 0 01-6.754 3.74.579.579 0 01-.544 0c-3.344-1.549-6.506-3.355-9.334-5.629-1.561-1.254-2.963-2.64-3.981-4.324-.76-1.253-1.168-2.588-1.105-4.03.087-1.97.756-3.724 2.33-5.116 1.128-1 2.487-1.55 4.037-1.75 1.497-.192 2.935-.053 4.317.512 1.67.68 2.912 1.8 3.86 3.219l.133.195z" />
            <path stroke={color} strokeLinecap="round" strokeWidth="7" d="M26.55 63.85c7.56 8.167 14.973 12.25 22.24 12.25 7.267 0 14.437-4.083 21.51-12.25" />
        </g>
    </Ic>
);

IC253Liked.propTypes = {
    /** Icon colour */
    color: colorType,
};

IC253Liked.displayName = 'IC253Liked';

export default IC253Liked;
