import toNumber from 'lodash/toNumber';
import parseDateTime from './parse-date-time';
import {CLASSIFICATION, YEARS, SHOW_EDITION, SCHEDULE_TAGLINE, EPISODE, IMDB, DATETIME, RESOLUTION, LENGTH, SEASONS, STATUS} from './infoline-types-constants';

const resolutionMap = {
    HD: 'HD',
    FHD: 'HD',
    UHD: 'HD',
};

/**
 * Given an infoline entry from the API, process it according to its type
 *
 * @param {Object} infoLineObject.infoLineItems Values describing the attributes of the video coming from the API
 * @param {string} shouldShowResolution Shows Resolution image if the value is true
 * @returns {Object} Parsed info line value
 */
export default function getInfoLineProps({infoLineItems = [], shouldShowResolution}) {
    return infoLineItems
        .filter(({type, value}) => {
            switch (type) {
                case IMDB:
                    return Number.isFinite(toNumber(value)); // remove imdb items if there's no imdb score instead of just showing "-"
                case RESOLUTION:
                    return shouldShowResolution && resolutionMap[value?.toUpperCase()]; // hide resolution field until 4k is launched
                case CLASSIFICATION:
                case DATETIME:
                case EPISODE:
                case LENGTH:
                case SEASONS:
                case STATUS:
                case SHOW_EDITION:
                case SCHEDULE_TAGLINE:
                case YEARS:
                    return true;
                default: // ignore unknown types
                    return false;
            }
        })
        .map?.((infoLineItem) => {
            switch (infoLineItem.type) {
                case IMDB:
                    return ({icon: 'IMDB', description: infoLineItem.value});
                case DATETIME:
                    return ({description: parseDateTime(infoLineItem.value, infoLineItem.transmissionTime)});
                case RESOLUTION:
                    return {icon: resolutionMap[infoLineItem.value.toUpperCase()]};
                default:
                    return ({description: infoLineItem.value});
            }
        });
}
