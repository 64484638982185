import React from 'react';
import Ic from '..';

const IC173KeyMPlayHeadOn = (args) => (
    <Ic {...args}>
        <path d="M48.5 46.8l2.8-11.1 11.1-2.8-11.1-2.7-2.8-11.1-2.7 11.1-11.1 2.7 11.1 2.8zM29.9 50.5l1.4-5.7 5.7-1.4-5.7-1.4-1.4-5.7-1.4 5.7-5.7 1.4 5.7 1.4zM64.3 27.5l.8-3.4 3.4-.8-3.4-.8-.8-3.4-.8 3.4-3.4.8 3.4.8zM61.8 42.3l6.2 1.5 1.5 6.2 1.5-6.2 6.3-1.5-6.3-1.5-1.5-6.2-1.5 6.2z" />
        <path d="M85.4 8.2H14.6c-1.5 0-2.8 1.2-2.8 2.8v47.5c0 1.5 1.2 2.8 2.8 2.8h33.7V76c-3.6.8-6.2 4-6.2 7.8 0 4.4 3.6 8 8 8s8-3.6 8-8c0-3.8-2.7-7-6.2-7.8V61.3h33.7c1.5 0 2.8-1.2 2.8-2.8V11c-.2-1.5-1.4-2.8-3-2.8zm-2.8 47.6H17.4v-42h65.3v42h-.1z" />
    </Ic>
);

IC173KeyMPlayHeadOn.displayName = 'IC173KeyMPlayHeadOn';

export default IC173KeyMPlayHeadOn;
