import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import {A} from 'normalized-styled-components';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import IA04InputSea from '../../../atoms/ia/04-input-sea';
// import IC24ArrowL from '../../../atoms/ic/24-arrow-l';
// import BA10BtnBack from '../../../atoms/ba/10-btn-back';
import VisuallyHidden from '../../../../common/visually-hidden';
import {slate} from '../../../../common/palette';

// Back button is phase 2+ (when show-results api actually implemented)
// const BackButton = styled(BA10BtnBack)`
//     position: absolute;
//     top: 0;
//     bottom: 0;
//     margin: auto;
// `;

const StyledForm = styled.form`
    margin: 0 auto;
    width: 100%;
    max-width: 800px;
    font: var(--mui-body-copy-1);
`;

const RightAlignedContainer = styled.div`
    text-align: right;
`;

const StyledA = styled(A)`
    opacity: 0.7;
    color: ${slate};
    font: var(--mui-body-copy-5);
`;

const OR06Search = React.forwardRef(({
    // backButtonHref,
    // backButtonLabel = 'Back',
    // displayState,
    // onBackButtonClick,
    className,
    formAction,
    helpText,
    initialSearchText,
    searchInputName = 'q',
    supportingText,
    supportingLink,

    onChangeSearchText,
    onSubmitForm,
}, ref) => (
    <div className={classnames('OR06Search', className)}>
        {/* {displayState === 'active' && onBackClicked && (
            <BackButton
                aria-label={backButtonLabel}
                href={backButtonHref}
                icon={<IC24ArrowL />}
                onClick={onBackClicked}
            />
        )} */}
        <StyledForm
            action={formAction}
            onSubmit={onSubmitForm}
        >
            <IA04InputSea
                defaultValue={initialSearchText}
                label={helpText}
                name={searchInputName}
                onChange={onChangeSearchText}
                ref={ref}
            />
            {
                /*
                ** When there is only one single-line text input field in a form, the user agent should accept Enter in that field as a request to submit the form.
                ** Added this hidden input so it always submits the form on enter, in case there are other inputs in future
                ** https://www.w3.org/MarkUp/html-spec/html-spec_8.html#SEC8.2
                */
            }
            <VisuallyHidden as="input" type="submit" tabIndex="-1" />
            {!!supportingLink && !!supportingText && (
                <RightAlignedContainer>
                    <StyledA
                        target="_blank"
                        href={supportingLink}
                        rel="noopener noreferrer"
                    >
                        {supportingText}
                    </StyledA>
                </RightAlignedContainer>
            )}
        </StyledForm>
    </div>
));

OR06Search.propTypes = {
    // /* This specifies the specified state the organism can be */
    // displayState: propTypes.oneOf(['collapsed', 'overlay', 'active']),
    // /* This is called when user clicks on the back button */
    // onBackClicked: propTypes.func,
    /* CSS class name(s) */
    className: classNameType,
    /* action value for the form */
    formAction: propTypes.string,
    /* This is the placeholder text for the search input box */
    helpText: propTypes.string.isRequired,
    /* This is the initial text for the search to begin with */
    initialSearchText: propTypes.string,
    /* "name" attribute for the search input field, e.g. for use in native <form> submission */
    searchInputName: propTypes.string,
    /* This is the supporting text for the link below the icon */
    supportingText: propTypes.string,
    /* This is the supporting link destination */
    supportingLink: propTypes.string,

    /* This is called everytime user types in, so we can decide when to call search, supports capability for auto-search */
    onChangeSearchText: propTypes.func,
    /* This is called when form is submitted/submit button is pressed (future) */
    onSubmitForm: propTypes.func,
};

// Storybook renders the displayName for the component name if a forwardRef element provides it, else it is displayed as Unknown
OR06Search.displayName = 'OR06Search';

export default OR06Search;
