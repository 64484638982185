import React from 'react';
import Ic from '..';

const IC228MyAccount = (args) => (
    <Ic {...args}>
        <defs>
            <linearGradient x1="77.964%" y1="14.543%" x2="72.878%" y2="75.537%" id="ic228-my-account__linear-gradient--top">
                <stop stopColor="currentColor" stopOpacity="0" offset="0%" />
                <stop stopColor="currentColor" offset="100%" />
            </linearGradient>
            <linearGradient x1="50%" y1="19.259%" x2="50%" y2="84.201%" id="ic228-my-account__linear-gradient--bottom">
                <stop stopColor="currentColor" offset="0%" />
                <stop stopColor="currentColor" stopOpacity="0" offset="100%" />
            </linearGradient>
        </defs>
        <g fill="none" fillRule="nonzero">
            <path
                d="M80.898 30.76c8.577 13.265 7.416 30.711-3.174 42.775-12.95 14.752-35.417 16.447-50.463 3.913l-.454-.385 3.262-3.79c12.968 11.164 32.627 9.803 43.897-3.036 9.005-10.258 10.08-25.03 2.976-36.38l-.243-.381 4.2-2.715Z"
                fill="url(#ic228-my-account__linear-gradient--top)"
            />
            <path
                d="M30.83 20.02c16.55-10.642 38.62-6.138 49.598 10.024l.329.493-4.181 2.742c-9.354-14.264-28.629-18.321-43.042-9.053-14.397 9.26-18.486 28.31-9.142 42.559C33.653 80.905 52.637 85.023 67 76.11l.434-.274 2.704 4.206c-16.717 10.75-39.065 6.046-49.927-10.516C9.34 52.949 14.099 30.78 30.83 20.02Z"
                fill="currentColor"
            />
            <path
                d="M50.582 30.502c4.34-.08 8.42 2.109 10.773 5.85 2.838 4.837 2.34 9.617-1.42 16.884l-.44.833-.13.24.169.073.16.065.17.065.368.13c.129.043.264.086.405.13l.447.131.494.136.545.141.602.148 1.806.422c5.457 1.246 7.462 4.232 7.66 9.426l.009.317.006 12.027-5 .002-.005-11.917-.007-.249-.022-.471c-.157-2.647-.878-3.563-3.53-4.208l-2.117-.495-1.047-.259-.642-.169-.599-.167c-2.696-.783-4.037-1.581-4.766-3.153l-.076-.171-.064-.153-.044-.16a4.832 4.832 0 0 1 .622-3.922l.025-.038.149-.27.201-.379.382-.733.354-.706c.34-.693.637-1.345.894-1.962l.243-.605c1.377-3.565 1.297-5.929.032-8.201l-.106-.186A7.407 7.407 0 0 0 50.75 35.5l-.276.008h-.217c-2.63-.109-5.115 1.185-6.49 3.342-1.538 2.535-1.578 5.161.223 9.433l.277.637.303.662.162.34.343.7.182.361.385.742.151.285.032.049a4.906 4.906 0 0 1 .653 3.591l-.057.247-.056.216-.093.204c-.69 1.498-1.802 2.272-4.159 3.023l-.524.161-.564.162-.607.164a62.37 62.37 0 0 1-.319.083l-1.025.258-1.837.445-.196-.001-.322.081-.367.102-.338.106c-1.625.545-2.226 1.324-2.424 3.303l-.035.412c-.005.072-.01.145-.013.22l-.02.462c-.004.08-.006.162-.008.246l-.01.518-.001.825.002.29v10.344h-5V67.196l-.003-.722.002-.663.01-.61.018-.563c.008-.18.017-.353.028-.52l.037-.482c.116-1.241.357-2.141.821-3.154 1.084-2.364 3.183-3.905 6.407-4.718l.64-.151.176-.001 1.21-.292.866-.218.505-.135.454-.127.41-.123.372-.12.341-.12.161-.06.32-.13.049-.022-.343-.656-.42-.831c-3.458-6.978-3.902-11.65-1.207-16.295l.162-.273a12.365 12.365 0 0 1 10.604-5.707l.224.006.233-.007Z"
                fill="url(#ic228-my-account__linear-gradient--bottom)"
            />
        </g>
    </Ic>
);

IC228MyAccount.displayName = 'IC228MyAccount';

export default IC228MyAccount;
