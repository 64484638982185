import React from 'react';
import Ic from '..';

const IC03NotHeadsUpMarketing = (args) => (
    <Ic {...args}>
        <path d="M74.1 63.5l-9.2-16.4v-6.3c0-4.1-1.6-7.9-4.6-10.7-2.4-2.3-5.5-3.7-8.8-4v-4.2h-3v4.2c-7.6.8-13.4 7.2-13.4 15.1v5.9l-9.2 16.4c-1 1.7-.7 3.8.5 5.2.6.6 1.7 1.4 3.5 1.4H70c1.8 0 2.9-.8 3.5-1.4 1.3-1.3 1.5-3.4.6-5.2zm-2.7 3.2c-.1.1-.4.5-1.3.5H29.9c-.6 0-1-.2-1.3-.5-.4-.4-.4-1.1-.1-1.7l9.4-16.7c.1-.2.2-.5.2-.7v-6.3c0-6.6 5-11.9 11.3-12.2h.6c3.1 0 6 1.2 8.2 3.3 2.4 2.3 3.7 5.3 3.7 8.6v6.7c0 .3.1.5.2.7L71.5 65c.3.6.3 1.3-.1 1.7zM50 76.5c-2.4 0-4.7-1.3-6-3.4l-1.3.8c1.5 2.6 4.3 4.2 7.3 4.2s5.8-1.6 7.3-4.2l-1.3-.8c-1.3 2.1-3.6 3.4-6 3.4z" />
    </Ic>
);

IC03NotHeadsUpMarketing.displayName = 'IC03NotHeadsUpMarketing';

export default IC03NotHeadsUpMarketing;
