import React from 'react';
import Ic from '..';

const IC283Channels = (args) => (
    <Ic {...args}>
        <g transform="translate(16.75 20.937)">
            <path opacity=".35" d="M55.46 3.281c4.3 0 7.8 3.504 7.8 7.81v37.834c0 4.306-3.5 7.81-7.8 7.81H11.075c-4.3 0-7.8-3.504-7.8-7.81V11.09c0-4.306 3.5-7.81 7.8-7.81zm-1.102 18.701h-42.18a2.932 2.932 0 0 0-2.927 2.93v10.191a2.931 2.931 0 0 0 2.926 2.93h42.181a2.931 2.931 0 0 0 2.927-2.93v-10.19a2.932 2.932 0 0 0-2.927-2.93z" />
            <path d="M11.076 3.281c-4.3 0-7.8 3.504-7.8 7.81v37.834c0 4.306 3.5 7.81 7.8 7.81H55.46c4.3 0 7.8-3.504 7.8-7.81V11.09c0-4.306-3.5-7.81-7.8-7.81H11.076zM55.46 60.016H11.076C4.97 60.016 0 55.04 0 48.925V11.09C0 4.976 4.969 0 11.076 0H55.46c6.108 0 11.077 4.976 11.077 11.09v37.835c0 6.115-4.969 11.09-11.077 11.09z" />
        </g>
        <g transform="translate(48.603 8)">
            <path d="M1.639 15.946a1.643 1.643 0 0 1-1.185-2.774L12.54.507a1.64 1.64 0 1 1 2.37 2.267L2.824 15.439a1.63 1.63 0 0 1-1.185.507" />
        </g>
        <path d="M49.795 23.946c-.433 0-.863-.17-1.184-.507L36.523 10.774a1.64 1.64 0 1 1 2.369-2.267l12.087 12.665a1.64 1.64 0 0 1-1.184 2.774M71.108 42.92a2.932 2.932 0 0 1 2.927 2.93v10.19a2.931 2.931 0 0 1-2.927 2.93h-42.18A2.931 2.931 0 0 1 26 56.04V45.85a2.932 2.932 0 0 1 2.926-2.93zm-37.15 2.968h-2.742v9.953h6.667l.277-2.08h-4.2v-7.873zm10.964 0h-2.743v9.953h2.743v-9.954zm6.776 0h-2.897l3.022 9.953h3.27l3.196-9.954h-2.797c-.091.404-1.044 3.813-1.136 4.232-.317 1.16-.608 2.301-.802 3.33h-.049c-.164-1.01-.416-2.096-.748-3.378-.08-.404-.977-3.78-1.059-4.184zm17.381 0h-6.977v9.952h6.977l.308-2.058h-4.641V51.75h4.165v-2.073h-4.165V47.96h4.333v-2.072z" />
    </Ic>
);

IC283Channels.displayName = 'IC283Channels';

export default IC283Channels;
