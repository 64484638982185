import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {palette as infinityPalette} from '@fsa-streamotion/streamotion-web-infinity';
import {palette as ionicPalette, screenSizes} from '@fsa-streamotion/streamotion-web-ionic';
import {palette as muiPalette} from '@fsa-streamotion/streamotion-web-mui';

import {
    BrandedBA01BtnPr,
    BrandedBA08BtnSec,
    BrandedIC103Loading,
    BrandedIC42Error,
    BrandedIM01Input,
    BrandedTM24AlertTile,
} from '../../../../../utils/branded-components';
import {stylesWhenBinge, stylesWhenFlash, stylesWhenKayo, stylesWhenLifestyle} from '../../../../../utils/styles-when-brand';

import {Paragraph} from '../../../../../widgets/common/styled-elements';
import Summary from '../summary';

const {blanc} = ionicPalette;
const {fog} = infinityPalette;
const {white} = muiPalette;

const {SCREEN_1920_DESKTOP} = screenSizes;

const buttonBaseStyles = css`
    width: 250px;
    height: 50px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 400px;
        height: 80px;
    `}

    ${stylesWhenFlash`
        width: 228px;
        height: 40px;

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            width: 333px;
            height: 60px;
        `}
    `}
`;

const StyledBrandedBA01BtnPr = styled(BrandedBA01BtnPr)`
    margin: 14px 0;
    ${buttonBaseStyles}
`;

const StyledBrandedBA08BtnSec = styled(BrandedBA08BtnSec)`
    ${buttonBaseStyles}
`;

const StyledForm = styled.form`
    display: flex;
    flex-flow: column nowrap;

    &:invalid {
        ${StyledBrandedBA01BtnPr} {
            opacity: 0.35;
            pointer-events: none;
        }
    }
`;

const StyledH2 = styled.h2`
    display: block;
    margin: 0;
    width: 100%;

    ${stylesWhenBinge`
        color: ${blanc};
        font: var(--ionic-body-copy-2-bold);
    `}

    ${stylesWhenFlash`
        color: ${fog};
        font: var(--infinity-body-copy-2);
    `}

    ${stylesWhenKayo`
        color: ${white};
        font: var(--mui-body-copy-2-bold);
    `}

    ${stylesWhenLifestyle`
        color: ${blanc};
        font: var(--nucleus-body-copy-2-bold);
    `}
`;

const StyledText = styled.p`
    margin: 21px 0;
    width: 100%;

    ${stylesWhenBinge`
        color: ${blanc};
        font: var(--ionic-body-copy-5);
    `}

    ${stylesWhenFlash`
        color: ${fog};
        font: var(--infinity-body-copy-2);
    `}

    ${stylesWhenKayo`
        color: ${white};
        font: var(--mui-body-copy-5);
    `}

    ${stylesWhenLifestyle`
        color: ${blanc};
        font: var(--nucleus-body-copy-5);
    `}
`;

const StyledSpan = styled.span`
    word-break: break-all;

    ${stylesWhenFlash`
        font: var(--infinity-body-copy-2-light);
    `}
`;

const StyledBrandedTM24AlertTile = styled(BrandedTM24AlertTile)`
    margin: 14px 0;
    min-width: 320px;
`;

const StyledBrandedIM01Input = styled(BrandedIM01Input)`
    max-width: 300px;
`;

const QFFN_FIELD_NAME = 'qffn-id';
const SURNAME_FIELD_NAME = 'surname-id';

const Journey = ({
    isCompleted,
    isLoading,
    canSkip,
    isSkipped,
    acceptedQffn,
    acceptedSurname,
    errorMessage,
    onChange,
    onClickEdit,
    onSkip,
    onSubmit,

    brand,
}) => {
    const submitHandler = (event) => {
        event.preventDefault();
        onSubmit({
            qffn: event.target[QFFN_FIELD_NAME].value,
            surname: event.target[SURNAME_FIELD_NAME].value,
        });
    };

    let icon = null;

    if (isCompleted || isSkipped) {
        return (
            <Summary
                brand={brand}
                header="3. Qantas Frequent Flyer"
                isSkipped={isSkipped}
                onClickEdit={onClickEdit}
            >
                {!!acceptedQffn && !!acceptedSurname && (
                    <StyledSpan brand={brand}>
                        {acceptedQffn}<br />
                        {acceptedSurname}
                    </StyledSpan>
                )}
            </Summary>
        );
    }

    if (errorMessage) {
        icon = <BrandedIC42Error brand={brand} />;
    } else if (isLoading) {
        icon = <BrandedIC103Loading brand={brand} />;
    }

    return (
        <StyledForm
            brand={brand}
            onSubmit={submitHandler}
        >
            <StyledH2 brand={brand}>3. Qantas Frequent Flyer</StyledH2>
            <Paragraph brand={brand}>Enter your Qantas membership details.</Paragraph>

            <StyledBrandedIM01Input
                autocomplete="off"
                brand={brand}
                disabled={isLoading}
                icon={icon}
                label="Membership Number"
                name={QFFN_FIELD_NAME}
                onChange={onChange}
                required={true}
                defaultValue={acceptedQffn}
            />

            <StyledBrandedIM01Input
                autocomplete="family-name"
                brand={brand}
                disabled={isLoading}
                icon={icon}
                label="Last Name"
                name={SURNAME_FIELD_NAME}
                onChange={onChange}
                required={true}
                defaultValue={acceptedSurname}
            />

            <StyledText brand={brand}>
                Please enter a valid QFF within 5 days of sign up to qualify for points awarding
            </StyledText>

            {!!errorMessage && (
                <StyledBrandedTM24AlertTile brand={brand}>
                    {errorMessage}
                </StyledBrandedTM24AlertTile>
            )}

            <StyledBrandedBA01BtnPr
                brand={brand}
                disabled={isLoading}
                isBlock={true}
                type="submit"
            >
                Continue
            </StyledBrandedBA01BtnPr>

            {!!canSkip && (
                <StyledBrandedBA08BtnSec
                    brand={brand}
                    onClick={onSkip}
                    isBlock={true}
                >
                    Skip
                </StyledBrandedBA08BtnSec>
            )}
        </StyledForm>
    );
};

Journey.propTypes = {
    /** is form completed? */
    isCompleted: propTypes.bool,
    /** is submission loading? */
    isLoading: propTypes.bool,
    /** show skip button? */
    canSkip: propTypes.bool,
    /** is step skipped? */
    isSkipped: propTypes.bool,
    /** accepted qantas frequent flyer number */
    acceptedQffn: propTypes.string,
    /** accepted surname */
    acceptedSurname: propTypes.string,
    /** error message */
    errorMessage: propTypes.string,
    /** callback on change */
    onChange: propTypes.func,
    /** callback on clicking edit */
    onClickEdit: propTypes.func,
    /** callback on skip */
    onSkip: propTypes.func,
    /** callback on submit */
    onSubmit: propTypes.func,

    /** brand name */
    brand: propTypes.string,
};

Journey.displayName = 'Journey-Qantas';

export default Journey;
