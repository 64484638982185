import getVideoPlaybackCapabilities from './get-video-playback-capabilities';

const DRM_TYPES = {
    HIGH: 'drm:high',
    LOW: 'drm:low',
};

/**
 * * SW_SECURE_CRYPTO, L3 (software, low)
 * * SW_SECURE_DECODE, L3 (software, low)
 * * HW_SECURE_CRYPTO, L2 (software, low)
 * * HW_SECURE_DECODE, L1 (hardware, high)
 * * HW_SECURE_ALL, L1 (hardware, high)
 *
 * We need to work out if this device can handle fairplay (drm:high) or
 * widevine drm:(low|high) so we can use that as the scope to auth0.
 *
 * @param {Object} options          see below
 * @param {string} options.brand    Brand string eg binge, flash, kayo
 * @returns {Promise<DRM_TYPES>}    DRM protection level
 */
export default async function getScopeFromEme({brand}) {
    const {canPlayHighDrm} = await getVideoPlaybackCapabilities(brand);

    return canPlayHighDrm ? DRM_TYPES.HIGH : DRM_TYPES.LOW;
}
