import React, {createRef} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import noop from 'lodash/noop';

import {rgba} from 'polished';
import {Section} from 'normalized-styled-components';
import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';

import {palette as infinityPalette} from '@fsa-streamotion/streamotion-web-infinity';
import {palette as ionicPalette} from '@fsa-streamotion/streamotion-web-ionic';
import {palette as muiPalette} from '@fsa-streamotion/streamotion-web-mui';
import {palette as nucleusPalette} from '@fsa-streamotion/streamotion-web-nucleus';

import brandPropType from '../../../../common/brand-proptype';

import {BrandedIC103Loading} from '../../../../utils/branded-components';
import {stylesWhenBinge, stylesWhenFlash, stylesWhenKayo, stylesWhenLifestyle} from '../../../../utils/styles-when-brand';

import TableHeader from './components/table-header';
import PackageRow from './components/package-row';
import PricingColumnCell from './components/pricing-column-cell';
import FeatureColumnCells from './components/feature-column-cells';
import SelectionIndicatorCell from './components/selection-indicator-cell';
import usePackageSelector from './use-package-selector';

const {fog, graphite, white: white__infinity} = infinityPalette;
const {akela, blanc} = ionicPalette;
const {slate, white: white__mui} = muiPalette;
const {smoke} = nucleusPalette;

const BRANDED_LOADING_ICON_COLOR = {
    binge: blanc,
    kayo: white__mui,
    lifestyle: blanc,
};

/** ********************************************
 *                   WRAPPER
 ***********************************************/

const Wrapper = styled(Section)`
    position: relative;
`;

const Table = styled.table`
    position: relative;
    width: 100%;
    min-width: 315px;
    table-layout: auto;
    border-collapse: collapse;

    ${stylesWhenBinge`
        color: ${blanc};
        font: var(--ionic-body-copy-3);
    `}

    ${stylesWhenFlash`
        color: ${white__infinity};
        font: var(--infinity-body-copy-3);
    `}

    ${stylesWhenKayo`
        color: ${slate};
        font: var(--mui-body-copy-3);
    `}

    ${stylesWhenLifestyle`
        color: ${blanc};
        font: var(--nucleus-body-copy-3);
    `}

    ${stylesWhen('isDisabled')`
        opacity: 0.3;
        cursor: not-allowed;
    `}
`;

const StyledTableBody = styled.tbody`
    ${stylesWhenBinge`
        background-color: ${rgba(akela, 0.1)};
    `}

    ${stylesWhenFlash`
        border-bottom: 1px solid ${graphite};
        background-color: ${rgba(fog, 0.1)};
    `}

    ${stylesWhenKayo`
        background-color: ${rgba(slate, 0.1)};
    `}

    ${stylesWhenLifestyle`
        background-color: ${rgba(smoke, 0.1)};
    `}
`;

const LoadingWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
`;

/** ********************************************
 *                  MAIN
 ***********************************************/

const PackageSelector = ({
    brand,
    defaultSelectedPackagePriceId,
    headers = [],
    isDisabled,
    isLoading,
    onChange = noop,
    packages = [],
    ...htmlAttributes
}) => {
    const {
        onKeyDown,
        onPackageSelect,
        selectedPackagePriceId,
    } = usePackageSelector({
        defaultSelectedPackagePriceId,
        isDisabled,
        isLoading,
        onChange,
        packages,
    });

    const packagesWithRefs = packages.map(({ref, ...rest}) => ({
        ref: ref || createRef(),
        ...rest,
    }));

    return (
        <Wrapper
            {...htmlAttributes}
            className="PackageSelector"
        >
            <Table brand={brand} isDisabled={isDisabled || isLoading}>

                <TableHeader brand={brand} headers={headers} />

                <StyledTableBody brand={brand}>
                    {packagesWithRefs.map(({
                        ref,
                        displayName,
                        currencyPrefix = '$',
                        features,
                        prices,
                        currencyDecimalPlaces = 2,
                        footnoteMarker,
                        secondaryText,
                    }) => {
                        /*
                         * Picking the first price as the main price
                         *  - following price values will only be used if it's annual pricing (used for secondary text)
                         */
                        const [{priceId}] = prices;

                        return (
                            <PackageRow
                                brand={brand}
                                key={priceId}
                                ref={ref}
                                isDisabled={isDisabled || isLoading}
                                onKeyDown={(event) => onKeyDown(event, priceId)}
                                onClick={() => onPackageSelect(priceId)}
                                aria-pressed={priceId === selectedPackagePriceId}
                            >
                                <PricingColumnCell
                                    brand={brand}
                                    currencyDecimalPlaces={currencyDecimalPlaces}
                                    currencyPrefix={currencyPrefix}
                                    displayName={displayName}
                                    footnoteMarker={footnoteMarker}
                                    prices={prices}
                                    secondaryText={secondaryText}
                                />

                                <FeatureColumnCells
                                    brand={brand}
                                    features={features}
                                    isDisabled={isDisabled || isLoading}
                                />

                                <SelectionIndicatorCell brand={brand} isSelected={priceId === selectedPackagePriceId} />
                            </PackageRow>
                        );
                    })}
                </StyledTableBody>

            </Table>

            {!!isLoading && (
                <LoadingWrapper>
                    <BrandedIC103Loading brand={brand} color={BRANDED_LOADING_ICON_COLOR[brand]} />
                </LoadingWrapper>
            )}
        </Wrapper>
    );
};

PackageSelector.displayName = 'PackageSelector';

PackageSelector.propTypes = {
    /** Product */
    brand: brandPropType,
    /** If provided, the package with this ID will be selected by default */
    defaultSelectedPackagePriceId: propTypes.string,
    /** Table th headings */
    headers: propTypes.arrayOf(propTypes.shape({
        title: propTypes.node,
        a11yTitle: propTypes.string,
    })),
    /** Whether a package is selectable */
    isDisabled: propTypes.bool,
    /** Whether component is in loading state due to getting offers from the billing api */
    isLoading: propTypes.bool,
    /** What to do once package has been selected */
    onChange: propTypes.func,
    /** PackagesPricing Details */
    packages: propTypes.arrayOf(propTypes.shape({
        currencyDecimalPlaces: propTypes.number,
        currencyPrefix: propTypes.string,
        displayName: propTypes.string,
        features: propTypes.arrayOf(propTypes.shape({
            value: propTypes.node,
            a11yValue: propTypes.string,
        })),
        footnoteMarker: propTypes.string,
        secondaryText: propTypes.string,
        prices: propTypes.arrayOf(propTypes.shape({
            priceId: propTypes.string,
            displayAmount: propTypes.number,
            displayRegularAmount: propTypes.number,
            termType: propTypes.oneOf(['annual', 'month', 'week', 'day']),
        })),
    })),
};

export default PackageSelector;
