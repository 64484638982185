import React, {useEffect, useRef, useState} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import noop from 'lodash/noop';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import setRef from '../../../../common/set-ref';
import BA22EditBtn from '../../../atoms/ba/22-edit-btn';
import IC42Error from '../../../atoms/ic/42-error';
import IM01Input from '../../../molecules/im/01-input';
import IA05InputHelp from '../../../atoms/ia/05-input-help';
import GA98GiftCardExpand from '../../../atoms/ga/98-gift-card-expand';
import {blueCharcoal, fog} from '../../../../common/palette';

const GiftInputContainer = styled.section`
    display: grid;
    grid-row-gap: 14px;
`;

const GiftCardInstructions = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 26px;
    align-items: center;
    color: ${blueCharcoal};
    font: var(--infinity-body-copy-4-light);
`;

const GiftCardPinInstructions = styled(GiftCardInstructions)`
    align-items: start;

    >span {
        display: grid;
        grid-auto-flow: row;
        grid-gap: 21px;
    }
`;

const StyledBA22EditBtn = styled(BA22EditBtn)`
    width: fit-content;
    font: var(--infinity-body-copy-4);
`;

const OR115GiftInp = React.forwardRef(({
    className,
    giftCode,
    giftPin,
    onChangeGiftCode = noop,
    onChangeGiftPin = noop,
    name,
    pinName,
    codeContextualHelpText = 'Find your 30 digit gift card code on the back of your card, below the bar code.',
    pinContextualHelpTexts = [
        'Find your 4 digit PIN code on the back of your card, above the bar code.',
        'If your card has no PIN, please email help@flashnews.com.au with an image of the back of the gift card and, if you\'re an existing customer, the mobile number linked to your account so we can apply it on your behalf.',
    ],
    errorMessage,
    codeHelpButtonText = 'Where can I find my gift card code?',
    pinHelpButtonText = 'Where can I find my gift card PIN?',
    giftCodeLabel = 'Gift Card Code',
    giftPinLabel = 'Gift Card PIN',
    giftCardIcon = <GA98GiftCardExpand width="143" />,
    giftCardPinIcon = <GA98GiftCardExpand width="143" isPinDisplay={true} />,
    disabled,
    isRequired,
    ...htmlAttributes
}, ref) => {
    const codeInputRef = ref || React.createRef(); // NOTE: Simplified this to follow existing pattern

    const codeInput = useRef();
    const pinInput = useRef();
    const [showCodeContextualHelp, setShowCodeContextualHelp] = useState(false);
    const [showPinContextualHelp, setShowPinContextualHelp] = useState(false);

    // Focus and select erroneous gift code when there is a new error message
    useEffect(function highlightInputTextOnErrorAndGiftCode() {
        if (errorMessage && giftCode) {
            codeInput.current.select();
        }
    }, [errorMessage, giftCode]);

    return (
        <GiftInputContainer
            {...htmlAttributes}
            className={classnames('OR115GiftInp', className)}
        >
            <IM01Input
                ref={(el) => {
                    setRef(codeInputRef, el);
                    codeInput.current = el;
                }}
                autocomplete="off"
                contextualHelp={errorMessage}
                icon={errorMessage ? <IC42Error /> : undefined}
                label={giftCodeLabel}
                value={giftCode}
                onChange={({target: {value}}) => void onChangeGiftCode(value)}
                name={name}
                type="text"
                inputMode="numeric"
                pattern="^\d{30}$"
                maxLength={30}
                disabled={disabled}
                required={isRequired}
            />
            <StyledBA22EditBtn
                className="OR115GiftInp__code-help-button"
                onClick={() => setShowCodeContextualHelp(!showCodeContextualHelp)}
            >
                {codeHelpButtonText}
            </StyledBA22EditBtn>
            {showCodeContextualHelp && (
                <IA05InputHelp
                    arrowOrientation="left"
                    messageColor={fog}
                    showArrow={true}
                >
                    <GiftCardInstructions>
                        {codeContextualHelpText}
                        {giftCardIcon}
                    </GiftCardInstructions>
                </IA05InputHelp>
            )}

            <IM01Input
                ref={(el) => {
                    pinInput.current = el;
                }}
                autocomplete="off"
                label={giftPinLabel}
                value={giftPin}
                onChange={({target: {value}}) => void onChangeGiftPin(value)}
                name={pinName}
                type="text"
                inputMode="numeric"
                pattern="^\d{4}$"
                maxLength={4}
                disabled={disabled}
                required={isRequired}
            />
            <StyledBA22EditBtn
                className="OR115GiftInp__pin-help-button"
                onClick={() => setShowPinContextualHelp(!showPinContextualHelp)}
            >
                {pinHelpButtonText}
            </StyledBA22EditBtn>
            {showPinContextualHelp && (
                <IA05InputHelp
                    arrowOrientation="left"
                    messageColor={fog}
                    showArrow={true}
                >
                    <GiftCardPinInstructions>
                        <span>
                            {Array.isArray(pinContextualHelpTexts) && pinContextualHelpTexts.map((paragraph, index) => (
                                <span key={index}>{paragraph}</span>
                            ))}
                        </span>
                        {giftCardPinIcon}
                    </GiftCardPinInstructions>
                </IA05InputHelp>
            )}
        </GiftInputContainer>
    );
});

OR115GiftInp.propTypes = {
    /** Classname if passed down */
    className: classNameType,
    /** The entered gift code */
    giftCode: propTypes.string,
    /** The entered gift PIN */
    giftPin: propTypes.string,
    /** Where to grab the gift card icon from */
    giftCardIcon: propTypes.node,
    /** Where to grab the gift card PIN icon from */
    giftCardPinIcon: propTypes.node,
    /** Entered gift code changed */
    onChangeGiftCode: propTypes.func,
    /** Entered gift PIN changed */
    onChangeGiftPin: propTypes.func,
    /** Name of the gift code input for form submission */
    name: propTypes.string,
    /** Name of the gift PIN input for form submission */
    pinName: propTypes.string,
    /** Contextual help error message */
    errorMessage: propTypes.node,
    /** Text that appears after you click on code help button */
    codeContextualHelpText: propTypes.string,
    /** Text that appears after you click on pin help button */
    pinContextualHelpTexts: propTypes.arrayOf(propTypes.string),
    /** Text for contextual code help button */
    codeHelpButtonText: propTypes.string,
    /** Text for contextual pin help button */
    pinHelpButtonText: propTypes.string,
    /** Gift code input label */
    giftCodeLabel: propTypes.string,
    /** Gift PIN input label */
    giftPinLabel: propTypes.string,
    /** Is the input disabled? */
    disabled: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    /** HTML5 required validation on the input */
    isRequired: propTypes.bool,
};

OR115GiftInp.displayName = 'OR115GiftInp';

// NOTE: Simplified this to follow existing pattern
// export default React.forwardRef((props, ref) => <OR115GiftInp {...props} inputRef={ref || undefined} />);
export default OR115GiftInp;
