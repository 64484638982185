import React from 'react';
import classnames from 'classnames';
import {colorType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {Svg} from '../../../../common/normalized-styled-components';

/**
 *
 * A functional component that renders an arrow icon.
 *
 * @param {string} className - Additional class(es) to be applied to the component.
 * @param {string} color='currentColor' - The fill color of the arrow.
 * @returns {JSX.Element} - The rendered arrow icon.
 */
const GA43ArrowInd = ({
    className,
    color = 'currentColor',
    ...htmlAttributes
}) => (
    <Svg
        {...htmlAttributes}
        className={classnames('GA43ArrowInd', className)}
        width="9"
        height="14"
        xmlns="http://www.w3.org/2000/svg"
        fill={color}
    >
        <path d="M.969 13.677 0 12.714l5.912-5.876L0 .963.969 0l6.88 6.838z" fillRule="nonzero" />
    </Svg>
);

GA43ArrowInd.displayName = 'GA43ArrowInd';

GA43ArrowInd.propTypes = {
    /** Additional class(es) */
    className: classNameType,
    /** The fill color of the arrow */
    color: colorType,
};

export default GA43ArrowInd;
