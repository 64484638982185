const BRAND_LOGO_RESOURCE_URL = 'https://resources.streamotion.com.au/production/binge/brand-logos/binge-wedge.png';
const BRAND_OPTUS_LOGO_RESOURCE_URL = 'https://resources.streamotion.com.au/production/common/auth0/binge-subhub-sponsorship_logo-5000px.png';
const SRCSET_SIZES = [100, 320, 415, 768];

// These sizes are chosen intentionally
// The smallest this is used is at 100px wide on mobile for the offer page and profile pages
// The largest this would be is 3x pixel density and 227px wide (total 681px) on the offer page and profile pages
export default function getBrandLogoSrcset(getImageFromPath) {
    return SRCSET_SIZES.reduce((curr, widthPx) => ({
        ...curr,
        [widthPx]: getImageFromPath(widthPx),
    }), {});
}

export const defaultBrandLogoSrcset = ({isOptus}) => getBrandLogoSrcset((widthPx) => isOptus ? BRAND_OPTUS_LOGO_RESOURCE_URL : `${BRAND_LOGO_RESOURCE_URL}?imwidth=${widthPx}`);
export const vizardBrandLogoSrcset = ({isOptus}) => getBrandLogoSrcset((widthPx) => isOptus ? '../data/binge-subhub-sponsorship_logo-5000px.png' : `../data/binge-wedge-${widthPx}px.png`);
