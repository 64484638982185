import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import {Figure, FigCaption} from 'normalized-styled-components';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {silverLining} from '../../../../common/deprecated-mui-palette';

const MsgContainer = styled(Figure)`
    display: ${({isInline}) => isInline ? 'inline-grid' : 'grid'};
    position: relative;
    grid-template-columns: ${({hasIcon}) => hasIcon ? 'min-content auto' : 'auto'};
    grid-gap: 7px;
    align-items: center;
    margin: 0;
    color: ${silverLining};
`;

const IconContainer = styled.div`
    align-self: start;
`;

const ChildrenContainer = styled(FigCaption)`
    font: var(--ionic-body-copy-3);
`;

const GM01IconMsg = ({
    icon,
    isInline,
    className,
    children,
}) => (
    <MsgContainer
        isInline={isInline}
        className={classnames('GM01IconMsg', className)}
        hasIcon={!!icon}
    >
        {!!icon && (
            <IconContainer>
                {icon}
            </IconContainer>
        )}
        <ChildrenContainer>
            {children}
        </ChildrenContainer>
    </MsgContainer>
);

GM01IconMsg.propTypes = {
    /** Icon to sit next to content */
    icon: propTypes.node,
    /** Using inline display mode */
    isInline: propTypes.bool,
    /** Additional CSS class(es) */
    className: classNameType,
    /** Content of message, e.g. text */
    children: propTypes.node,
};

GM01IconMsg.displayName = 'GM01IconMsg';

export default GM01IconMsg;
