import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {A} from 'normalized-styled-components';
import classnames from 'classnames';

import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {white} from '../../../../common/palette';
import IC88Beta from '../../../atoms/ic/88-beta';

const OuterContainer = styled.div`
    display: flex;
    align-items: flex-end;
    height: 100%;
    color: ${white};
`;

const StyledUl = styled.ul`
    display: flex;
    margin: 0;
    padding-left: 0;
    list-style-type: none;
`;

const Link = styled(A)`
    display: block;
    position: relative;
    margin-left: 5px;
    padding: 10px 21px 0;
    text-decoration: none;
    color: ${white};
    font: var(--mui-body-copy-3);

    &::after {
        display: block;
        transition: ${transition('background-color')};
        margin: 18px auto 0;
        border-radius: 3px;
        background-color: transparent;
        height: 3px;
        content: '';

        ${stylesWhen('isActive')`
            background-color: ${white};
        `}
    }

    &:hover::after {
        background-color: ${white};
    }
`;

const BetaLogoContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -4px;
    width: 30px;
`;

const NM07TopNav = ({
    items,
    className,
    ...htmlAttributes
}) => (
    <OuterContainer {...htmlAttributes} className={classnames('NM07TopNav', className)}>
        <StyledUl>
            {items.map(({href, isActive, isBeta, onClick, title}) => (
                <li key={title}>
                    <Link
                        {...{
                            href,
                            isActive,
                            onClick,
                        }}
                    >
                        {title}
                        {!!isBeta && (
                            <BetaLogoContainer>
                                <IC88Beta ariaLabel="Beta" color={white} />
                            </BetaLogoContainer>
                        )}
                    </Link>
                </li>
            ))}
        </StyledUl>
    </OuterContainer>
);

NM07TopNav.displayName = 'NM07TopNav';

NM07TopNav.propTypes = {
    /** Handed in classname, e.g. for use with styled components */
    className: classNameType,
    /** Array of objects for the navigation links */
    items: propTypes.arrayOf(propTypes.shape({
        href: propTypes.string,
        isActive: propTypes.bool,
        /** Whether to show the Beta logo for this item  */
        isBeta: propTypes.bool,
        onClick: propTypes.func,
        title: propTypes.string,
    })).isRequired,
};

export default NM07TopNav;
