import constant from 'lodash/constant';
import get from 'lodash/get';

/**
 * Given a ref and optional parentOnFocus, creates a function whereby on an event (typically onFocus), the parentOnFocus will be invoked (if present) and that ref will be focused if (and only if) the event target was this container
 * In this way this function will not interfere with onFocus events that take place inside the container that uses this
 *
 * @param {Object} ref              - A React ref
 * @param {Function} parentOnFocus  - Optional onFocus for parent node, to be invoked if present
 *
 * @returns {Function} - An event handler as described above
 */
export function getPassFocusToRef(ref, parentOnFocus) {
    return (event) => void passFocusToRef(event, ref, parentOnFocus);
}

export function passFocusToRef(event, ref = {}, parentOnFocus) {
    if (parentOnFocus) {
        parentOnFocus(event);
    }

    if (event.currentTarget === event.target && ref.current) {
        ref.current.focus();
    }
}

const alwaysTrue = constant(true);

const elementTypeToIsInteractableFunction = {
    a: alwaysTrue,
    button: alwaysTrue,
    details: alwaysTrue,
    embed: alwaysTrue,
    iframe: alwaysTrue,
    label: alwaysTrue,
    select: alwaysTrue,
    textarea: alwaysTrue,
    audio: (element) => !!get(element, 'controls'),
    image: (element) => !!get(element, 'usemap'),
    input: (element) => get(element, 'type') !== 'hidden',
    menu: (element) => get(element, 'type') === 'toolbar',
    object: (element) => !!get(element, 'usemap'),
    video: (element) => !!get(element, 'controls'),
};

/**
 * Given a react element, tells us if it's interactable by default
 * Sourced from https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/Content_categories#Interactive_content
 *
 * @param {Node} reactElement - A react element, with props etc
 * @returns {boolean} - Whether or not the element is interactable
 */
export function isReactElementInteractable({type = {}, props = {}, ref = {}} = {}) {
    try {
        if (props.tabIndex >= 0) {
            // Tab Index has been explicitly set in react, so we trust that this can handle focus events
            return true;
        } else if (type.isFlowController) {
            // Strong sign of being able to handle focus, set in GenericFlowController
            return true;
        } else if (ref && ref.current) {
            // If we have a ref with a current attachment, inspect that downstream element
            const element = ref.current;

            /*
            There are a few cases we cover here.
            - For usually-not-interactable things like divs, we look straight to the `tabIndex` being >= 0 (as -1 is the default, not undefined like you'd expect)
            - For usually-interactable things, we run them through their respective function. This is necessary because <input type="hidden"/> has a tabIndex of 0, but should not be focusable.
             */
            const isFocusableFunction = elementTypeToIsInteractableFunction[element.tagName.toLowerCase()]
                || (({tabIndex}) => tabIndex >= 0);

            return isFocusableFunction(element);
        } else {
            // Otherwise, since it has no ref, look at the react element type
            const rootHtmlElementType = get(type, 'target', type);

            return elementTypeToIsInteractableFunction[rootHtmlElementType](props);
        }
    } catch (_) {
        return false;
    }
}
