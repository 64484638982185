import React from 'react';
import propTypes from 'prop-types';
import {Input} from 'normalized-styled-components';
import {rgba} from 'polished';
import styled from 'styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {midnight, ironStone, kayoGreen, white} from '../../../../common/palette';
import {SCREEN_TABLET} from '../../../../common/screen-sizes';

const StyledInput = styled(Input)`
    margin-bottom: 4px;
    border: 1px solid ${ironStone};
    border-radius: 3px;
    background-color: ${white};
    padding: 1px 0;
    width: 36px;
    height: 34px;
    text-align: center;
    text-shadow: 0 0 0 ${midnight}; /* Hide cursor */
    color: transparent;
    font-size: 20px;

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        margin-bottom: 7px;
        width: 50px;
        height: 57px;
        font-size: 34px;
    `}

    &:focus,
    &:hover {
        outline: 0;
        box-shadow: inset 0 1px 6px 2px ${rgba(kayoGreen, 0.5)};
    }

    &:disabled {
        background-color: ${rgba(white, 0.8)};
        cursor: not-allowed;
    }

    &::selection {
        background: none;
    }

    :not(:last-of-type) {
        margin-right: 4px;
    }
`;

const IA06NumberBox = React.forwardRef(({
    'aria-label': ariaLabel,
    disabled,
    onChange,
    onKeyDown,
}, ref) => (
    <StyledInput
        aria-label={ariaLabel}
        disabled={disabled}
        ref={ref}
        onChange={onChange}
        onKeyDown={onKeyDown}
        pattern="[0-9]*"
        inputMode="numeric"
        type="text"
    />
));

IA06NumberBox.displayName = 'IA06NumberBox';

IA06NumberBox.propTypes = {
    /** If the field is in a disabled state */
    'disabled': propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    /** Change callback */
    'onChange': propTypes.func,
    /** KeyDown callback */
    'onKeyDown': propTypes.func,
    /** The screenreader assistive label of the input */
    'aria-label': propTypes.string.isRequired,
};

export default IA06NumberBox;
