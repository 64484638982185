import React from 'react';
import Ic from '..';

const IC173KeyMPlayHeadOff = (args) => (
    <Ic {...args}>
        <path d="M85.4 8.2H81l1.9-1.9-3.9-4-5.9 5.9H14.6c-1.5 0-2.8 1.2-2.8 2.8v47.5c0 1.5 1.2 2.8 2.8 2.8H20l-4.5 4.5 3.9 3.9 8.4-8.4h20.4V76c-3.6.8-6.2 4-6.2 7.8 0 4.4 3.6 8 8 8s8-3.6 8-8c0-3.8-2.7-7-6.2-7.8V61.2h33.7c1.5 0 2.8-1.2 2.8-2.8V10.9c-.1-1.5-1.3-2.7-2.9-2.7zM25.6 55.7h-8.2v-42h50.2l-42 42zm57 0H33.5l42-42h7.2l-.1 42z" />
    </Ic>
);

IC173KeyMPlayHeadOff.displayName = 'IC173KeyMPlayHeadOff';

export default IC173KeyMPlayHeadOff;
