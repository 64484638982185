import React from 'react';
import Ic from '..';

const IC118Tap = (args) => (
    <Ic {...args}>
        <path fillOpacity=".35" d="M51 91c11.598 0 20.308-3.296 25.12-20.829C80.93 52.64 79.78 49.026 51 42.927a49.36 49.36 0 0 0-4.894 2.398c-1.934 1.086 5.519-26.702-5.88-23.919-8.46 2.066-3.929 31.447-5.59 33.726C31.733 59.12 30 63.764 30 69c0 12.15 9.402 22 21 22z" />
        <g fillRule="nonzero">
            <path d="M22.635 34.662A18.74 18.74 0 0 1 21 26.95a18.949 18.949 0 1 1 36.472 7.25l-3.87-1.607a14.757 14.757 0 1 0-27.138.364l-3.829 1.704z" />
            <path d="M78.629 52.843a6.89 6.89 0 0 0-6.875-6.876h-.448a6.75 6.75 0 0 0-3.283.88 7.127 7.127 0 0 0-6.498-4.261 6.987 6.987 0 0 0-3.717 1.076 7.113 7.113 0 0 0-6.4-3.927h-.224c-.916.074-1.814.3-2.655.67v-3.06c0-1.397 0-2.683.07-3.954.153-5.227.28-9.782-2.25-12.34a6.847 6.847 0 0 0-5.115-1.872 8.3 8.3 0 0 0-6.26 2.348 9.502 9.502 0 0 0-2.501 6.33v26.55a15.008 15.008 0 0 0-4.458 5.59c-3.563 8.091-.028 18.502 1.565 21.856l.098.167c1.747 3.578 5.38 10.998 25.642 10.998 16.392 0 21.24-14.855 21.604-23.407a6.847 6.847 0 0 0 1.705-4.485V52.843zm-7.323-2.683h.49a2.697 2.697 0 0 1 2.682 2.683v12.283a2.683 2.683 0 0 1-2.683 2.683h-.489a2.683 2.683 0 0 1-2.683-2.683V52.843a2.697 2.697 0 0 1 2.683-2.683zm-19.843-6.233a2.865 2.865 0 0 1 2.865 2.865v1.202a40.93 40.93 0 0 0-5.841.88v-2.571a3.018 3.018 0 0 1 2.976-2.376zM36.721 27.843a4.304 4.304 0 0 1 4.513-4.472c1.482 0 1.957.476 2.11.643 1.286 1.398 1.188 5.199 1.076 9.307 0 1.3-.083 2.669-.083 4.066v12.465c-1.398.35-3.033.755-4.821 1.244a20.57 20.57 0 0 0-2.795.95V27.843zm18.6 60.983c-17.65 0-20.403-5.59-21.884-8.65l-.098-.182c-1.3-2.655-4.374-11.71-1.397-18.166 1.509-3.326 4.332-5.506 8.664-6.68 8.873-2.39 12.576-2.878 14.113-2.948.182 1.872 0 4.388-1.118 5.058-3.256 1.803-14.575 6.638-14.672 6.638l1.635 3.899c.419-.182 11.668-4.989 15.05-6.987 4.192-2.418 3.297-8.888 3.004-10.439a1.886 1.886 0 0 0-.112-.559v-.154a2.92 2.92 0 1 1 5.841 0v20.892a2.92 2.92 0 0 1-5.84 0v-4.682l-4.193 1.929v4.793a2.865 2.865 0 0 1-2.865 2.864h-.111a2.865 2.865 0 0 1-2.795-2.864v-2.096l-4.192 1.914v.182a7.07 7.07 0 0 0 6.987 7.057h.111a6.987 6.987 0 0 0 5.94-3.27 7.169 7.169 0 0 0 4.066 1.285 7.099 7.099 0 0 0 6.987-6.246c.88.39 1.832.59 2.795.587h.489c.237.014.475.014.712 0-.824 5.617-4.15 16.825-17.118 16.825z" />
        </g>
    </Ic>
);

IC118Tap.displayName = 'IC118Tap';

export default IC118Tap;
