import isValid from 'date-fns/isValid';
import differenceInSeconds from 'date-fns/differenceInSeconds';

const TIME_UNTIL_NOW_IN_SECONDS = 30;

/**
 * @description Checks whether the input unix timestamp is 30 seconds or less from today's date
 * @param {number} currentExpiry - Unix timestamp expiry date
 * @returns {boolean} - Boolean value
 */
export default function isExpiringSoon(currentExpiry) {
    const currentExpiryDate = currentExpiry && new Date(currentExpiry * 1000);
    const today = new Date();

    return (
        currentExpiryDate
        && isValid(currentExpiryDate)
        && differenceInSeconds(currentExpiryDate, today) < TIME_UNTIL_NOW_IN_SECONDS
    );
}
