import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import classnames from 'classnames';

import {buttonType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {A, Button} from '../../../../common/normalized-styled-components';
import {transition} from '../../../../common/animations';
import {ink, indigo} from '../../../../common/palette';

const disabledStyle = css`
    cursor: default;
    text-decoration: underline;

    &:hover {
        opacity: 0.5;
        cursor: default;
        text-decoration: underline;
    }
`;

const commonStyle = css`
    transition: ${transition('opacity')};
    opacity: 0.5;
    margin: 0;
    border: 0;
    background: transparent;
    cursor: pointer;
    padding: 0;
    text-decoration: underline;
    letter-spacing: 0.4px;
    color: ${ink};
    font: var(--quicksilver-body-copy-3);

    &[aria-pressed='true'] {
        opacity: 1;
        text-decoration: none;
        color: ${ink};
    }

    &:hover {
        opacity: 1;
        text-decoration: none;
        color: ${indigo};
    }

    &:active {
        opacity: 0.7;
    }

    &[aria-disabled='true'] {
        ${disabledStyle}
    }

    &:disabled {
        ${disabledStyle}
    }
`;

const StyledButton = styled(Button)`
    ${commonStyle};
    appearance: none;
    outline: none;
`;

const StyledLink = styled(A)`
    ${commonStyle}
`;

const StyledContent = styled.span`
    display: block;
    height: 100%;
    text-align: start;
`;

/**
 * Edit Button
 * - Used in the checkout
 *
 * @param {Object} options options
 * @param {*} options.children children
 * @param {String} options.href href
 * @param {Function} options.onClick onClick
 * @param {String} options.type type
 * @param {String} options.className className
 *
 * @returns {React.ReactElement} BA22EditBtn
 */
const BA22EditBtn = ({
    children,
    href,
    onClick,
    type = 'button',
    className,
    ...htmlAttributes
}) => {
    const StyledElement = href ? StyledLink : StyledButton;

    return (
        <StyledElement
            {...htmlAttributes}
            {...{
                href,
                onClick,
            }}
            className={classnames('BA22EditBtn', className)}
            type={href ? undefined : type}
        >
            {!!(children && React.Children.count(children)) && (
                <StyledContent>
                    {children}
                </StyledContent>
            )}
        </StyledElement>
    );
};

BA22EditBtn.propTypes = {
    /** Content of button, e.g. text */
    children: propTypes.node.isRequired,
    /** If it’s actually a link, provide a href */
    href: propTypes.string,
    /** Callback on element click */
    onClick: propTypes.func,
    /** The HTML type of the button */
    type: buttonType,
    /** Additional CSS class(es) */
    className: classNameType,
};

BA22EditBtn.displayName = 'BA22EditBtn';

export default BA22EditBtn;
