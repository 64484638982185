import React from 'react';

import Ic from '..';

const IC298LiveIcon = (args) => (
    <Ic {...args}>
        <path
            d="M50 0c27.614 0 50 22.386 50 50s-22.386 50-50 50S0 77.614 0 50 22.386 0 50 0zm0 23.81c-14.465 0-26.19 11.725-26.19 26.19 0 14.465 11.725 26.19 26.19 26.19 14.465 0 26.19-11.725 26.19-26.19 0-14.465-11.725-26.19-26.19-26.19z"
            fillRule="nonzero"
        />
    </Ic>
);

IC298LiveIcon.displayName = 'IC298LiveIcon';

export default IC298LiveIcon;
