import React from 'react';

import Ic from '..';

const IC178Playlists = (args) => (
    <Ic {...args}>
        <path d="M15.4 73.3h69.3v4H15.4v-4zm11 9.9h47.4v2.7H26.4v-2.7zm63.3-69.1H10.4c-1.4 0-2.7 1.1-2.7 2.7v47.5c0 1.4 1.1 2.7 2.7 2.7h79.1c1.4 0 2.7-1.1 2.7-2.7V16.8c0-1.5-1.1-2.7-2.5-2.7zM87 61.6H13.1V19.4H87v42.2z" />
        <path d="M46.2 51.4c.1.1.3.1.4.1.2 0 .4-.1.5-.2l13.8-10.2c.2-.2.4-.5.4-.7 0-.2-.1-.6-.4-.7L47.1 29.6c-.3-.2-.7-.2-.9-.1-.3.2-.5.5-.5.8v20.3c0 .4.1.7.5.8z" />
    </Ic>
);

IC178Playlists.displayName = 'IC178Playlists';

export default IC178Playlists;
