/* eslint-disable arrow-body-style */
import React from 'react';
import bacon from 'baconjs';
import cuid from 'cuid';
import {format, isValid} from 'date-fns';
import get from 'lodash/get';
import invoke from 'lodash/invoke';
import matchesProperty from 'lodash/matchesProperty';

import {asBool} from '@fsa-streamotion/transformers';
import {accountsEnvs} from '@fsa-streamotion/streamotion-web-widgets-common';

import getOfferStream from '../../../../todo-move-to-widgets-common/streams/endpoints/billing/offer';
import interpolateString from '../../../../todo-move-to-widgets-common/utils/interpolate-string';

import StyledBaconWidget from '../../../../todo-move-to-widgets-common/utils/styled-bacon-widget';
import mapErrorsForFiso from '../../../../todo-move-to-widgets-common/utils/map-errors-for-fiso';
import {isEqualFp} from '../../../../todo-move-to-widgets-common/utils/fp-helpers';

import safelyHandleAuth0Params from '../../utils/safely-handle-auth0-params';
import getNormalisedBrand from '../../utils/get-normalised-brand';
import {FOOTNOTE_MARKER} from '../../utils/generate-footnote-marker';
import {createSecondaryTextForAdTierPackage} from '../../utils/binge-ad-tier-package-helpers';

import PackageSummaryComponent from '../../components/branded/package-summary';
import {BILLING_PRODUCT_NAME_MAP} from '../../../../todo-move-to-widgets-common/utils/constants';

const {envsFromWidgetSettings} = accountsEnvs;

class PackageSummary extends StyledBaconWidget {
    static widgetName = 'accw-package-summary';
    component = PackageSummaryComponent;

    constructor(settings, element) {
        super(settings, element);

        /*
            *** BRAVE SOUL.  A NOTE ABOUT PRICE ID VS PACKAGE ID.
            As of 10/02/2020 - Platform are reusing packageId as their priceId, because
            they'll "never have more than one".  we asked for prices to specifically be an array set
            on packages as they are related to the package and will one day users will have to be shown an option.
            Nando and Fed have both been briefed that tomorrow when we have to give users the choice, we'll continue down
            the path of using priceId.
            Dazz: 'So in the future, when we need to update client side to show the option for termType, we'll continue using price id'
            Nando: 'ok we’ll keep that in mind.'
        */

        Object.assign(
            this.config,
            {
                commonWidgetSettings: {
                    brand: getNormalisedBrand(safelyHandleAuth0Params(settings.brand)),
                    ...envsFromWidgetSettings(settings),
                },

                expandTerms: asBool(settings.expandTerms, false),
                selectedPackageId: safelyHandleAuth0Params(settings.selectedPackageId),
                selectedPackagePriceId: safelyHandleAuth0Params(settings.selectedPackagePriceId),
                redirectForgotVoucherLink: settings.redirectForgotVoucherLink,

                // Optus SubHub IDs passed in on ESI Include
                ddSubscriptionId: safelyHandleAuth0Params(settings.ddSubscriptionId),
                merchantSubscriptionId: safelyHandleAuth0Params(settings.merchantSubscriptionId),

                // These settings are for SSR only variant of the widget.
                // Will never consider user details itself.
                offerName: safelyHandleAuth0Params(settings.offerName) || '',
                voucher: safelyHandleAuth0Params(settings.voucher) || undefined,
                termType: safelyHandleAuth0Params(settings.termType),

                // Hand this in if you want the offer$ to update display (as per user eligibility for example).
                offer$: settings.offer$,
            },
        );
    }

    getData(/* hydration = {} */) {
        const {commonWidgetSettings} = this.config;
        const {brand, platformEnv} = commonWidgetSettings;
        let offer$;

        if (this.config.offer$) {
            offer$ = this.config.offer$; // eslint-disable-line prefer-destructuring
        } else if (BILLING_PRODUCT_NAME_MAP[brand]) {
            offer$ = getOfferStream({
                accessToken: undefined,
                brand,
                offer: this.config.offerName,
                platformEnv,
                voucher: this.config.voucher,
                ddSubscriptionId: this.config.ddSubscriptionId,
                merchantSubscriptionId: this.config.merchantSubscriptionId,
                termType: this.config.termType,
            });
        } else {
            // Auth0 hosted page, can ESI include this widget without valid values for brand and such.
            // We dont want to error our or hit any APIs unnecessarily.
            offer$ = bacon.later(0, {});
        }

        // @TODO userDetails$ here, we need it's id for listItems$.

        const journeyType$ = offer$.map('.offer.journeys.0').startWith(null);
        const isOptusOffer$ = journeyType$.map(isEqualFp('OPTUS'));

        // Optus offer will not come with selectedPackageId value
        const selectedPackageId$ = isOptusOffer$.decode({
            true: offer$.map('.packages.0.packageId'),
            false: this.config.selectedPackageId,
        });

        const selectedPackage$ = bacon.combineTemplate({
            packages: offer$.map('.packages'),
            selectedPackageId: selectedPackageId$,
        })
            .map(({packages = [], selectedPackageId}) => packages?.find(
                matchesProperty('packageId', selectedPackageId) // @TODO What if we don't have a selected package?  :grimace:
            ))
            .map(createSecondaryTextForAdTierPackage);

        // Optus offer will not come with selectedPackagePriceId values
        const selectedPackagePriceId$ = isOptusOffer$.decode({
            true: selectedPackage$.map('.prices.0.priceId'),
            false: this.config.selectedPackagePriceId,
        });

        const selectedPackagePrice$ = bacon.combineTemplate({
            prices: selectedPackage$.map('.prices'),
            selectedPackagePriceId: selectedPackagePriceId$,
        })
            .map(({prices = [], selectedPackagePriceId}) => prices.find(
                matchesProperty('priceId', selectedPackagePriceId) // @TODO What if we don't have the price?  :grimace:
            ));

        const subjectToChangeText$ = selectedPackage$
            .map('.subjectToChangeText')
            .startWith(null);

        const footnoteMarker$ = subjectToChangeText$
            .map((subjectToChangeText) => subjectToChangeText ? FOOTNOTE_MARKER : null);

        const listItems$ = selectedPackage$
            .map('.displayDescription')
            .combine(subjectToChangeText$, (displayDescription = {message: ''}, subjectToChangeText) => {
                const splitLineBreakMessage = invoke(displayDescription, 'message.split', '\n') || []
                    .filter(Boolean) // Clean up any extra dot points that would show blank.
                    .map((template, index) => interpolateString(template, get(displayDescription, 'replacements') || {}, 'TODOUSERID', index)); // @TODO: User ID (whatever that is)

                const listItems = this.config.expandTerms ? splitLineBreakMessage : []; // OR49 listItems prop requires an array value passed

                if (subjectToChangeText) { // if package has price change disclaimer, prepend subjectToChangeText to list
                    listItems.unshift(`${FOOTNOTE_MARKER}${subjectToChangeText}`);
                }

                return listItems;
            });

        const firstPaymentText$ = selectedPackagePrice$
            .map('.firstInvoiceDate')
            .map((firstInvoiceDate) => {
                const firstInvoiceDateObject = new Date(firstInvoiceDate);

                // Make sure we check firstInvoiceDate was a thing before asking isValid.
                // because new Date(null) is apparently valid for making Thu Jan 01 1970 10:00:00 GMT+1000
                if (!firstInvoiceDate || !isValid(firstInvoiceDateObject)) {
                    return '';
                }

                // @TODO Should we worry about client side time differences on this?
                return `First payment ${format(firstInvoiceDateObject, 'dd MMM yyyy')}`;
            });

        const forgotVoucherNode$ = bacon.combineTemplate({
            voucherCode: offer$.map('.voucher.code'),
            isOptusOffer: isOptusOffer$,
        })
            .map(({voucherCode, isOptusOffer}) => {
                return (voucherCode && this.config.redirectForgotVoucherLink) || isOptusOffer || this.config.termType === 'annual'
                    ? undefined
                    : <a href={this.config.redirectForgotVoucherLink}>Forgot a voucher code?</a>;
            });

        const shouldRenderBlank$ = selectedPackagePrice$
            .map(Boolean)
            .not();

        return bacon.combineTemplate({
            props: {
                brand: brand || 'binge', // Safe fallback for ESI includes
                shouldRenderBlank: shouldRenderBlank$, // Short circuit to be blank.
                shouldShowPrice: isOptusOffer$.not(), // Price should be hidden on the package summary when an Optus offer is present

                heading: selectedPackage$.map('.displayName'),
                secondaryText: selectedPackage$.map('.secondaryText'),
                summary: selectedPackage$.map('.displayTag'),
                currencyDecimalPlaces: 2,
                currencyPrefix: '$',
                listItems: listItems$,
                listItemsPreNode: !!this.config.expandTerms && firstPaymentText$,
                listItemsPostNode: !!this.config.expandTerms && forgotVoucherNode$,
                price: selectedPackagePrice$.map('.displayAmount'),
                termType: selectedPackagePrice$.map('.termType'),
                footnoteMarker: footnoteMarker$,
                features: selectedPackage$.map('.features'),
            },
            hydration: {}, // This widget doesn't believe in it's own hydration.
        })
            .flatMapError(mapErrorsForFiso);
    }

    // Specifically destroying all Javascript Booting things.
    renderServer({props} = {}) {
        const uniqueId = cuid();

        const {prependedHtml = '', html = '', appendedHtml = ''} = this.renderServerComponent(props);

        return `${prependedHtml}<div id="${uniqueId}">${html}</div>${appendedHtml}`;
    }
}

export default function packageSummaryWidget(settings = {}, element = null) {
    return new PackageSummary(settings, element);
}
