import React from 'react';

import Ic from '..';

const IC265ClosedCaptionsOn = (args) => (
    <Ic {...args}>
        <path d="M93 21v58H7V21h86zM35.5656895 36C27.6612378 36 20 41.7142857 20 51.1785714 20 60.5833333 27.4180239 66 35.4440825 66c7.8436482 0 10.883822-3.3333333 11.4918567-3.8690476l-2.1889251-5.5952381-.0172851.0182462c-.2416322.2514557-3.0090696 3.0174681-7.8263631 3.0174681-5.0466884 0-8.7557003-3.452381-8.7557003-8.75 0-5.2976191 3.7090119-8.452381 8.330076-8.452381 4.5602606 0 7.17481 2.202381 8.0868621 3.0357143l2.1281216-5.7142857C45.9022801 38.9166667 42.9837134 36 35.5656895 36zm29.0640608 0c-7.9044517 0-15.5656895 5.7142857-15.5656895 15.1785714C49.0640608 60.5833333 56.4820847 66 64.5081433 66 72.3517915 66 75.3919653 62.6666667 76 62.1309524l-2.1889251-5.5952381-.0172851.0182462c-.2416322.2514557-3.0090696 3.0174681-7.8263631 3.0174681-5.0466884 0-8.7557003-3.452381-8.7557003-8.75 0-5.2976191 3.7090119-8.452381 8.330076-8.452381 4.5602606 0 7.17481 2.202381 8.0868621 3.0357143l2.1281216-5.7142857C74.9663409 38.9166667 72.0477742 36 64.6297503 36z" />
    </Ic>
);

IC265ClosedCaptionsOn.displayName = 'IC265ClosedCaptionsOn';

export default IC265ClosedCaptionsOn;
