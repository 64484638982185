import regexpType from './regexp-type';
import customizeError from './util/customize-error';

const regexp =
    /^(?!.)|^([a-zA-Z]{1}[a-zA-Z0-9-_]*)+(\s[a-zA-Z]{1}[a-zA-Z0-9-_]*)*$/;

/**
 * Validate a className string using regex. Must be two characters, first character a letter, remaining characters alphanumeric.
 * Multiple class names can be delimited with a single space
 */
const classNameType = customizeError(regexpType(regexp), {
    prefix: '[classNameType] ',
    expected: `a string that matches ${regexp}`,
});

export default classNameType;
