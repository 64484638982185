import React from 'react';
import Ic from '..';

const IC239WatchTrailer = (args) => (
    <Ic {...args}>
        <g fillRule="nonzero">
            <path opacity=".2" d="M50.251 8C26.921 8 8.007 26.914 8.007 50.245c0 23.33 18.913 42.244 42.244 42.244s42.245-18.913 42.245-42.244C92.488 26.917 73.579 8.008 50.251 8z" />
            <path d="M43.506 34.51v31.47l20.347-15.735z" />
        </g>
    </Ic>
);

IC239WatchTrailer.displayName = 'IC239WatchTrailer';

export default IC239WatchTrailer;
