import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

const SizerOuter = styled.div`
    display: block;
    position: relative;
    padding-bottom: ${({width, height}) => height * 100 / width}%;
    width: 100%;
    height: 0;
    overflow: ${({isOverflowVisible}) => isOverflowVisible ? 'visible' : 'hidden'};
`;

const SizerInner = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const ResponsiveSizer = React.forwardRef(({
    width,
    height,
    children,
    isOverflowVisible,
    className,
    ...htmlAttributes
}, ref) => (
    <SizerOuter ref={ref} {...htmlAttributes} {...{width, height, isOverflowVisible, className}}>
        <SizerInner>
            {children}
        </SizerInner>
    </SizerOuter>
));

ResponsiveSizer.propTypes = {
    className: classNameType,
    children: propTypes.node,
    height: propTypes.number.isRequired,
    isOverflowVisible: propTypes.bool,
    width: propTypes.number.isRequired,
};

ResponsiveSizer.displayName = 'ResponsiveSizer';

export default ResponsiveSizer;
