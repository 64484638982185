import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {rgba} from 'polished';
import noop from 'lodash/noop';
import classnames from 'classnames';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {buttonType, classNameType, srcsetOptionsType} from '@fsa-streamotion/custom-prop-types';

import {A, Button} from '../../../../common/normalized-styled-components';
import {transition} from '../../../../common/animations';
import {flash, white, black} from '../../../../common/palette';
import {SCREEN_1920_DESKTOP, SCREEN_768_TABLET, SCREEN_320_MOBILE, SMALLEST_SUPPORTED_WIDTH} from '../../../../common/screen-sizes';
import SrcsetImg from '../../../../common/srcset-img';
import getCheckedMarkStylesMap from '../../../../common/get-checked-mark-styles-map';
import IC31Tick from '../../ic/31-tick';

const TILE_IMAGE_WIDTH_PX_MAP = {
    [SCREEN_1920_DESKTOP]: 273,
    [SCREEN_768_TABLET]: 163,
    [SCREEN_320_MOBILE]: 132,
};
const CHECKMARK_SIZE_PX_MAP = {
    [SMALLEST_SUPPORTED_WIDTH]: 17,
    [SCREEN_1920_DESKTOP]: 26,
};
const CHECKED_MARK_STYLES_BY_WIDTH = getCheckedMarkStylesMap(CHECKMARK_SIZE_PX_MAP);

const TileImageBase = styled(SrcsetImg).attrs(() => ({
    sizesDefinition: TILE_IMAGE_WIDTH_PX_MAP,
}))`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;
const TileImage = styled(TileImageBase)``;

const FocusedTileImage = styled(TileImageBase)`
    opacity: 0;
`;

const baseStyles = css`
    display: inline-block;
    position: relative;

    border: 0;
    background: none;
    padding: 0;

    min-width: ${TILE_IMAGE_WIDTH_PX_MAP[SCREEN_320_MOBILE]}px;
    min-height: 59px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        min-width: ${TILE_IMAGE_WIDTH_PX_MAP[SCREEN_768_TABLET]}px;
        min-height: 72px;
    `}
    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        min-width: ${TILE_IMAGE_WIDTH_PX_MAP[SCREEN_1920_DESKTOP]}px;
        min-height: 120px;
    `}
`;
const NonInteractive = styled.div`
    ${baseStyles}
    cursor: not-allowed;

    ${TileImage} {
        opacity: 0.5;
    }
`;

const interactiveStyles = css`
    &:active {
        ${TileImage} {
            opacity: 0;
        }

        ${FocusedTileImage} {
            opacity: 1;
        }
    }

    @media (hover: hover) {
        &:hover {
            outline: 0;

            ${TileImage} {
                opacity: 0;
            }

            ${FocusedTileImage} {
                opacity: 1;
            }
        }
    }
`;

const StyledButton = styled(Button).attrs(({isPressed}) => ({
    'aria-pressed': isPressed,
}))`
    ${baseStyles}
    ${interactiveStyles}

    appearance: none;
    color: ${white};
`;

const StyledLink = styled(A).attrs(({isPressed}) => ({
    'aria-current': isPressed,
}))`
    ${baseStyles}
    ${interactiveStyles}

    text-decoration: none;
    color: ${white};
`;

const Checkedmark = styled.span`
    position: absolute;
    transform: scale(${({isPressed}) => isPressed ? '100%' : 0});
    transition: ${transition('transform')};
    stroke: ${rgba(black, 0.3)};
    ${CHECKED_MARK_STYLES_BY_WIDTH[SMALLEST_SUPPORTED_WIDTH]}
    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        ${CHECKED_MARK_STYLES_BY_WIDTH[SCREEN_1920_DESKTOP]}
    `}
`;

const StyledIC31Tick = styled(IC31Tick)`
    border-radius: 50%;
    box-shadow: -1px 1px 1px 0 ${rgba(black, 0.3)};
`;

const TR02SetTil = React.forwardRef(({
    disabled,
    tileImageSrcset,
    focusedTileImageSrcset,
    href,
    isPressed,
    ariaLabel,
    type = 'button',
    onClick,
    className,
    altText = '',
    shouldShowToggle = true,
    ...htmlAttributes
}, ref) => {
    const isNonInteractive = disabled || (!href && (!onClick || onClick === noop));
    const StyledElement = getCtaType({isNonInteractive, href, onClick});

    return (
        <StyledElement
            {...htmlAttributes}
            aria-label={ariaLabel}
            className={classnames('TR02SetTil', className)}
            isPressed={isPressed}
            ref={ref}
            {
                ...isNonInteractive ? null : {
                    href,
                    onClick,
                    type: href ? undefined : type,  // We only want to put a type for buttons
                }
            }
        >
            <TileImage srcsetOptions={tileImageSrcset} alt={altText} />
            <FocusedTileImage srcsetOptions={focusedTileImageSrcset} alt={altText} />

            {!!shouldShowToggle && (
                <Checkedmark isPressed={isPressed}>
                    <StyledIC31Tick color={flash} secondaryColor={white} />
                </Checkedmark>
            )}
        </StyledElement>
    );
});

TR02SetTil.propTypes = {
    /** Disable the toggle */
    disabled: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    /** The button image */
    tileImageSrcset: srcsetOptionsType,
    /** Image behind the button when focused*/
    focusedTileImageSrcset: srcsetOptionsType,
    /** If it’s actually a link, provide a href */
    href: propTypes.string,
    /** Whether the button is currently selected */
    isPressed: propTypes.bool,
    /** Whether to show the tick on button */
    shouldShowToggle: propTypes.bool,
    /** The HTML type of the button */
    type: buttonType,
    /** Function to execute when button is clicked */
    onClick: propTypes.func,
    /** Let screen read know the content of the button */
    ariaLabel: propTypes.string.isRequired,
    /** Additional CSS class(es) */
    className: classNameType,
    /** alt text for the image */
    altText: propTypes.string,
};

function getCtaType({isNonInteractive, href, onClick}) {
    if (href && !isNonInteractive) {
        return StyledLink;
    } else if (onClick && !isNonInteractive) {
        return StyledButton;
    }

    return NonInteractive;
}

TR02SetTil.displayName = 'TR02SetTil';

export default TR02SetTil;
