import React, {useLayoutEffect, useRef} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import noop from 'lodash/noop';
import {Section} from 'normalized-styled-components';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import setRef from '../../../../common/set-ref';
import BA22EditBtn from '../../../atoms/ba/22-edit-btn';
import GA98GiftCardExpand from '../../../atoms/ga/98-gift-card-expand';
import IC42Error from '../../../atoms/ic/42-error';
import IM01Input from '../../../molecules/im/01-input';
import OR121TooltipCta from '../121-tooltip-cta';

const ContextualHelpInfo = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 7px;
    width: 100%;
    max-width: 375px;
`;

const ContextualHelpPinInfo = styled(ContextualHelpInfo)`
    align-items: start;

    >span {
        display: grid;
        grid-auto-flow: row;
        grid-gap: 21px;
    }
`;

const StyledBA22EditBtn = styled(BA22EditBtn)`
    font: var(--mui-body-copy-4);
`;

const OR115GiftInp = React.forwardRef(({
    className,
    giftCode,
    giftPin,
    onChangeGiftCode = noop,
    onChangeGiftPin = noop,
    name,
    pinName,
    codeContextualHelpText = 'Find your 30 digit gift card code on the back of your card, below the bar code.',
    pinContextualHelpTexts = [
        'Find your 4 digit PIN code on the back of your card, above the bar code.',
        'If your card has no PIN, please email help@kayosports.com.au with an image of the back of the gift card and, if you\'re an existing customer, the mobile number linked to your account so we can apply it on your behalf.',
    ],
    errorMessage,
    codeHelpButtonText = 'Where can I find my gift card code?',
    pinHelpButtonText = 'Where can I find my gift card PIN?',
    giftCodeLabel = 'Gift Card Code',
    giftPinLabel = 'Gift Card PIN',
    disabled,
}, ref) => {
    const codeInputRef = ref || React.createRef(); // NOTE: Simplified this to follow existing pattern

    const codeInput = useRef();
    const pinInput = useRef();

    // Focus and select erroneous gift code when there is a new error message
    useLayoutEffect(() => {
        if (errorMessage && giftCode) {
            codeInput.current.select();
        }
    }, [errorMessage]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Section className={classnames('OR115GiftInp', className)}>
            <IM01Input
                ref={(el) => {
                    setRef(codeInputRef, el);
                    codeInput.current = el;
                }}
                contextualHelp={errorMessage}
                icon={errorMessage ? <IC42Error /> : undefined}
                label={giftCodeLabel}
                value={giftCode}
                onChange={({target: {value}}) => void onChangeGiftCode(value)}
                name={name}
                type="text"
                inputMode="numeric"
                pattern="^\d{30}$"
                maxLength={30}
                disabled={disabled}
                isCondensed={true}
            />

            <OR121TooltipCta
                cta={(
                    <StyledBA22EditBtn className="OR115GiftInp__code-help-button">
                        {codeHelpButtonText}
                    </StyledBA22EditBtn>
                )}
                isLightTheme={true}
            >
                <ContextualHelpInfo>
                    {codeContextualHelpText}
                    <GA98GiftCardExpand />
                </ContextualHelpInfo>
            </OR121TooltipCta>

            <IM01Input
                ref={(el) => {
                    pinInput.current = el;
                }}
                label={giftPinLabel}
                value={giftPin}
                onChange={({target: {value}}) => void onChangeGiftPin(value)}
                name={pinName}
                type="text"
                inputMode="numeric"
                pattern="^\d{4}$"
                maxLength={4}
                disabled={disabled}
                isCondensed={true}
            />

            <OR121TooltipCta
                cta={(
                    <StyledBA22EditBtn className="OR115GiftInp__pin-help-button">
                        {pinHelpButtonText}
                    </StyledBA22EditBtn>
                )}
                isLightTheme={true}
            >
                <ContextualHelpPinInfo>
                    <span>
                        {Array.isArray(pinContextualHelpTexts) && pinContextualHelpTexts.map((paragraph, index) => (
                            <span key={index}>{paragraph}</span>
                        ))}
                    </span>
                    <GA98GiftCardExpand isPinDisplay={true} />
                </ContextualHelpPinInfo>
            </OR121TooltipCta>
        </Section>
    );
});

OR115GiftInp.displayName = 'OR115GiftInp';

OR115GiftInp.propTypes = {
    /** Classname if passed down */
    className: classNameType,
    /** The entered gift code */
    giftCode: propTypes.string,
    /** The entered gift pin */
    giftPin: propTypes.string,
    /** Entered gift code changed */
    onChangeGiftCode: propTypes.func,
    /** Entered gift PIN changed */
    onChangeGiftPin: propTypes.func,
    /** Name of the input for form submission */
    name: propTypes.string,
    /** Name of the PIN input for form submission */
    pinName: propTypes.string,
    /** Contextual help error message */
    errorMessage: propTypes.node,
    /** Text that appears after you click on code help button */
    codeContextualHelpText: propTypes.string,
    /** Text that appears after you click on pin help button */
    pinContextualHelpTexts: propTypes.arrayOf(propTypes.string),
    /** Text for contextual code help button */
    codeHelpButtonText: propTypes.string,
    /** Text for contextual pin help button */
    pinHelpButtonText: propTypes.string,
    /** Gift code input label */
    giftCodeLabel: propTypes.string,
    /** Gift pin input label */
    giftPinLabel: propTypes.string,
    /** Is the input disabled? */
    disabled: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
};

// NOTE: Simplified this to follow existing pattern
// export default React.forwardRef((props, ref) => <OR115GiftInp {...props} inputRef={ref || undefined} />);
export default OR115GiftInp;
