import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import noop from 'lodash/noop';
import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {SCREEN_1920_DESKTOP} from '../../../../../common/screen-sizes';

import BA20NavBtn from '../../../../atoms/ba/20-nav-btn';
import BA28BtnSec from '../../../../atoms/ba/28-btn-sec';

/** ***** CTA ****** */

const CtaWrapper = styled.div`
    margin: 28px auto;
    width: 250px;
    text-align: center;
`;

const StyledBA28BtnSec = styled(BA28BtnSec)`
    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        border-width: 1px;
        padding: 7px 21px;
        min-width: 250px;
        min-height: 50px;
    `}
`;

const CatTabList = ({
    menuItems = [],
    ctaCopy,
    onClickCta = noop,
}) => (
    <React.Fragment>
        {menuItems.map(({href, icon, label, onClick, ...htmlAttributes}) => (
            <BA20NavBtn
                key={label}
                {...{
                    href,
                    icon,
                    label,
                    onClick,
                    ...htmlAttributes,
                }}
            />
        )) }
        {!!ctaCopy && (
            <CtaWrapper>
                <StyledBA28BtnSec isBlock={true} onClick={onClickCta}>{ctaCopy}</StyledBA28BtnSec>
            </CtaWrapper>
        ) }
    </React.Fragment>
);

CatTabList.displayName = 'CatTabList';

CatTabList.propTypes = {
    menuItems: propTypes.arrayOf(
        propTypes.shape({
            href: propTypes.string,
            icon: propTypes.node,
            label: propTypes.string,
            onClick: propTypes.func,
        })
    ),
    ctaCopy: propTypes.string,
    onClickCta: propTypes.func,
};

export default CatTabList;
