import React from 'react';
import Ic from '..';

const IC21Mute = (args) => (
    <Ic {...args}>
        <path d="M73.547 33.994H77.747V65.816H73.547z" transform="rotate(-45 75.647 49.905)" />
        <path d="M59.736 47.828H91.55799999999999V52.028000000000006H59.736z" transform="rotate(-45 75.647 49.928)" />
        <path d="M46.734 31.568v35.896L34.4 59.4V39.8l12.334-8.12m8.4-15.68L26 35.152V63.88l29.134 19.166V16zM12 62.522L20.4 63.292 20.4 35.656 12 36.426z" />
    </Ic>
);

IC21Mute.displayName = 'IC21Mute';

export default IC21Mute;
