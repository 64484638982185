import React from 'react';
import Ic from '..';

const IC233About = (args) => (
    <Ic {...args}>
        <g transform="translate(11 11)">
            <path d="M39.2 8.4C56.21 8.4 70 22.19 70 39.2S56.21 70 39.2 70 8.4 56.21 8.4 39.2 22.19 8.4 39.2 8.4m0-8.4C17.55 0 0 17.55 0 39.2c0 21.65 17.55 39.2 39.2 39.2 21.65 0 39.2-17.55 39.2-39.2 0-10.396-4.13-20.367-11.481-27.719C59.567 4.13 49.596 0 39.2 0z" />
            <path d="M39.046 27.44c-1.624-.084-2.882-1.454-2.826-3.08.055-1.625 1.403-2.906 3.029-2.88 1.626.027 2.93 1.352 2.933 2.978.005.82-.329 1.605-.923 2.17-.594.564-1.395.858-2.213.812zm2.422 28.826h-4.83v-23.94l4.83-.812v24.752z" />
        </g>
    </Ic>
);

IC233About.displayName = 'IC233About';

export default IC233About;
