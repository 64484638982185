import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {coal, silverLining, kayoGreen} from '../../../common/palette';

import BA01BtnPr from '../../atoms/ba/01-btn-pr';
import BA04TeamTi from '../../atoms/ba/04-team-ti';
import BA05AddBtn from '../../atoms/ba/05-add-btn';
import BA08BtnSec from '../../atoms/ba/08-btn-sec';
import CAM03Finite from '../../molecules/cam/03-finite';
import IC103Loading from '../../atoms/ic/103-loading';
import IC30Back from '../../atoms/ic/30-back';
import IC50Success from '../../atoms/ic/50-success';
import IM05InputPre from '../../molecules/im/05-input-pre';
import XX01PageBackground from '../../atoms/xx/01-page-background';
import {SCREEN_LG_DESKTOP, SCREEN_DESKTOP, SCREEN_TABLET} from '../../../common/screen-sizes';

const StyledSection = styled.section`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    color: ${silverLining};

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        padding: 20px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
        margin-top: 100px;
    `}
`;

const Header = styled.h1`
    margin: 14px auto;
    text-align: center;
    font: var(--mui-header-3);
`;

const InfoPara = styled.p`
    text-align: center;
    font: var(--mui-body-copy-2);
`;

const Subheading = styled.h2`
    margin-bottom: 7px;
    text-align: center;
    font: var(--mui-header-7);

    ${mediaQuery({minWidthPx: SCREEN_DESKTOP})`
        margin-bottom: 14px;
    `}
`;

const ButtonWrapper = styled.div`
    display: grid;
    grid-row-gap: 20px;
    justify-items: center;
    margin-top: 35px;
    min-width: 250px;
`;

const StyledWrapper = styled.section`
    margin: 30px;
    width: 100%;
    max-width: 600px;

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        width: 50%;
    `}
    ${mediaQuery({minWidthPx: SCREEN_DESKTOP})`
        width: 100%;
    `}
`;

const HeaderContainer = styled.header`
    position: relative;
    padding: 14px;
`;

const StyledH3 = styled.h3`
    margin: 0;
    text-align: center;
    color: ${silverLining};
    font: var(--mui-body-copy-3);
`;

const StyledButton = styled.button`
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    border: none;
    background-color: transparent;
    width: 44px;
    height: 44px;
    color: ${silverLining};
`;

const AvatarImg = styled.img`
    width: 100%;
    height: 100%;
`;

const ErrorText = styled.p`
    color: ${silverLining};
    font: var(--mui-body-copy-3);
`;

const ProfileTemplate = ({
    avatarHeadText,
    avatarList = [],
    bgSrc,
    bgSize,
    errorMessage,
    hasAvatarLabel,
    hasAddProfile,
    hasContinue,
    hasDelete,
    hasHeader,
    hasManage,
    hasNameInput,
    hasSuccessIcon,
    headingText,
    infoText,
    isBgRepeating,
    isContinueDisabled,
    isLoading,
    nameContextualHelp,
    onAddProfile,
    onAvatarClick,
    onBack,
    onContinue,
    onDelete,
    onManage,
    onNameChange,
    profileName,
}) => (
    <React.Fragment>
        {!!bgSrc && <XX01PageBackground src={bgSrc} bgColor={coal} isRepeating={isBgRepeating} bgSize={bgSize} />}
        {!!hasHeader && (
            <HeaderContainer>
                <StyledButton onClick={onBack} aria-label="Click to go back">
                    <IC30Back color={silverLining} size="24px" />
                </StyledButton>
                <StyledH3>Edit my profile</StyledH3>
            </HeaderContainer>
        )}
        <StyledSection>
            {!!hasSuccessIcon && <IC50Success color={kayoGreen} size="100px" />}
            {!!headingText && <Header>{headingText}</Header>}
            {Array.isArray(infoText)
                ? infoText.filter(Boolean).map((infoText, index) => (<InfoPara key={index}>{infoText}</InfoPara>))
                : !!infoText && (<InfoPara>{infoText}</InfoPara>)}
            {!!hasNameInput && (
                <StyledWrapper>
                    <IM05InputPre
                        contextualHelp={nameContextualHelp}
                        defaultValue={profileName}
                        onChange={({target: {value: name}} = {}) => void onNameChange(name)}
                        label="Your profile name"
                        maxLength="10"
                    />
                </StyledWrapper>
            )}
            <StyledWrapper>
                {!!avatarHeadText && <Subheading>{avatarHeadText}</Subheading>}
                {!!(hasAddProfile || avatarList.length) && (
                    <CAM03Finite
                        verticalPaddingPx={7}
                        isCentered={true}
                    >
                        {
                            [
                                ...avatarList.map(({name, id, url, isSelected}, index) => (
                                    <BA04TeamTi
                                        key={id}
                                        aria-label={`${name || `Select avatar variant ${index + 1}`}`}
                                        isPressed={isSelected}
                                        isLabelVisible={hasAvatarLabel}
                                        onClick={() => void onAvatarClick(id)}
                                    >
                                        <AvatarImg src={url} alt={name ? `User Avatar for ${name}` : `Avatar variant ${index + 1}`} />
                                    </BA04TeamTi>
                                )),
                                hasAddProfile && <BA05AddBtn key="addProfile" aria-label="Add a new profile" onClick={onAddProfile} />,
                            ]
                                .filter(Boolean)
                        }
                    </CAM03Finite>
                )}
            </StyledWrapper>
            <ButtonWrapper>
                {isLoading ? (
                    <IC103Loading size="47px" />
                ) : (
                    <React.Fragment>
                        {!!hasManage && <BA08BtnSec isBlock={true} onClick={onManage}>Manage</BA08BtnSec>}
                        {!!hasContinue && <BA01BtnPr isBlock={true} onClick={onContinue} disabled={isContinueDisabled}>Continue</BA01BtnPr>}
                        {!!hasDelete && <BA08BtnSec isBlock={true} onClick={onDelete}>Delete</BA08BtnSec>}
                    </React.Fragment>
                )}
            </ButtonWrapper>
            {!!errorMessage && (
                <ErrorText role="alert">{errorMessage}</ErrorText>
            )}
        </StyledSection>
    </React.Fragment>
);

ProfileTemplate.displayName = 'ProfileTemplate';

ProfileTemplate.propTypes = {
    /** Background image source */
    bgSrc: propTypes.string,
    /** Background image size */
    bgSize: propTypes.string,
    /** Is background repeating */
    isBgRepeating: propTypes.bool,
    /** Has header with a back button */
    hasHeader: propTypes.bool,
    /** Click handler for back button */
    onBack: propTypes.func,
    /** Show success icon */
    hasSuccessIcon: propTypes.bool,
    /** Show input molecule for name */
    hasNameInput: propTypes.bool,
    /** Contextual help or error for name input */
    nameContextualHelp: propTypes.string,
    /** Profile name */
    profileName: propTypes.string,
    /** Handler for input name change */
    onNameChange: propTypes.func,
    /** Optional heading atop avatarList */
    avatarHeadText: propTypes.string,
    /** List of avatar/profile */
    avatarList: propTypes.arrayOf(
        propTypes.shape({
            /** Profile/Avatar id */
            id: propTypes.oneOfType([
                propTypes.string,
                propTypes.number,
            ]),
            /** Avatar/profile has been selected */
            isSelected: propTypes.bool,
            /** Name of the profile */
            name: propTypes.string,
            /** Avatar url */
            url: propTypes.string,
        })
    ),
    /** Avatar/profile click handler which returns id */
    onAvatarClick: propTypes.func,
    /** Show avatar label, i.e profile name */
    hasAvatarLabel: propTypes.bool,
    /** Show add button with profile list */
    hasAddProfile: propTypes.bool,
    /** Handler for add button click */
    onAddProfile: propTypes.func,
    /** Error message */
    errorMessage: propTypes.string,
    /** Show loading icon in place for buttons */
    isLoading: propTypes.bool,
    /** Show manage button */
    hasManage: propTypes.bool,
    /** Manage button click handler */
    onManage: propTypes.func,
    /** Show continue button */
    hasContinue: propTypes.bool,
    /** Continue button click handler */
    onContinue: propTypes.func,
    /** Is continue button disabled */
    isContinueDisabled: propTypes.bool,
    /** Show delete button */
    hasDelete: propTypes.bool,
    /** Delete button click handler */
    onDelete: propTypes.func,
    /** Heading text e.g 'Your profile' */
    headingText: propTypes.string,
    /** Text under the main heading */
    infoText: propTypes.oneOfType([
        propTypes.string,
        propTypes.array,
    ]),
};
export default ProfileTemplate;
