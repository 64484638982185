import React from 'react';
import Ic from '..';

const IC234Home = (args) => (
    <Ic {...args}>
        <defs>
            <linearGradient x1="50%" y1="5.132%" x2="51.313%" y2="63.696%" id="ic234-home__linear-gradient">
                <stop stopColor="currentColor" stopOpacity="0" offset="0%" />
                <stop stopColor="currentColor" offset="100%" />
            </linearGradient>
        </defs>
        <g fill="none" fillRule="nonzero">
            <path
                d="M79.2 41.572v40.965H56.487l-.001-24.842-12.713 3.84v21.002H21.2v-8.683h5v3.683h12.573V57.822l22.714-6.859-.001 26.574H74.2V41.572z"
                fill="currentColor"
            />
            <path
                d="M11.17 49.506 50 14.64l38.83 34.866-3.34 3.72L50 21.36 14.51 53.226z"
                fill="currentColor"
            />
            <path
                d="M26.2 49.696v30.858h-5V49.696z"
                fill="url(#ic234-home__linear-gradient)"
            />
        </g>
    </Ic>
);

IC234Home.displayName = 'IC234Home';

export default IC234Home;
