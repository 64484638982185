/* stylelint-disable custom-property-pattern */
import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {rgba} from 'polished';

import {mediaQuery, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';

import {palette as infinityPalette} from '@fsa-streamotion/streamotion-web-infinity';
import {palette as ionicPalette} from '@fsa-streamotion/streamotion-web-ionic';
import {palette as muiPalette} from '@fsa-streamotion/streamotion-web-mui';
import {palette as nucleusPalette} from '@fsa-streamotion/streamotion-web-nucleus';

import {stylesWhenBinge, stylesWhenFlash, stylesWhenKayo, stylesWhenLifestyle} from '../../../../../utils/styles-when-brand';

import {transition} from '../../../../../common/animations';
import brandPropType from '../../../../../common/brand-proptype';
import {SCREEN_375_PHABLET, SCREEN_1280_DESKTOP} from '../../../../../common/screen-sizes';

import PackageRow from './package-row';

const {blueCharcoal} = infinityPalette;
const {vader} = ionicPalette;
const {coal, silverLining} = muiPalette;
const {midnight} = nucleusPalette;

/** **********************************************************************
 *          MIDDLE COLUMNS: Features (alternating dark and regular)
 ************************************************************************/

const brandingStyles = css`
    ${stylesWhenBinge`
        --feature-cell-dark-background-color: ${rgba(vader, 0.54)};
        --feature-cell-pressed-hover-background-color: ${rgba(vader, 0.1)};
        font: var(--ionic-body-copy-2);
    `}

    ${stylesWhenFlash`
        --feature-cell-dark-background-color: ${rgba(blueCharcoal, 0.54)};
        --feature-cell-pressed-hover-background-color: ${rgba(blueCharcoal, 0.1)};
        font: var(--infinity-body-copy-2);
    `}

    ${stylesWhenKayo`
        --feature-cell-dark-background-color: ${rgba(coal, 0.54)};
        --feature-cell-pressed-hover-background-color: transparent;

        font: var(--mui-body-copy-2);
        background-color: ${rgba(coal, 0.2)}; /* regular feature cell only */
        color: ${silverLining}; /* overwrite table color slate */
    `}

    ${stylesWhenLifestyle`
        --feature-cell-dark-background-color: ${rgba(midnight, 0.54)};
        --feature-cell-pressed-hover-background-color: ${rgba(midnight, 0.1)};
        font: var(--nucleus-body-copy-2);
    `}
`;

const FeatureCellDark = styled.td`
    ${brandingStyles};
    transition: ${transition('background-color')};
    margin: 0;
    background-color: var(--feature-cell-dark-background-color);
    width: 54px;
    text-align: center;

    ${mediaQuery({minWidthPx: SCREEN_375_PHABLET})`
        width: 66px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        width: 103px;
    `}
    /* stylelint-disable selector-type-no-unknown, selector-type-case */
    ${PackageRow}:hover &,
    ${PackageRow}[aria-pressed='true'] & {
        ${stylesWhenNot('isDisabled')`
            background-color: var(--feature-cell-pressed-hover-background-color);
        `}
    }
`;

const FeatureCell = styled.td`
    ${brandingStyles};
    width: 60px;
    text-align: center;

    ${mediaQuery({minWidthPx: SCREEN_375_PHABLET})`
        width: 66px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        width: 103px;
    `}
`;

const FeatureColumnCells = ({
    brand,
    features,
    isDisabled,
}) => (
    features.map(({value, a11yValue}, index) => {
        const DataCell = index % 2 ? FeatureCell : FeatureCellDark;

        return (
            <DataCell
                brand={brand}
                isDisabled={isDisabled}
                key={index}
                {...(!!a11yValue && {'aria-label': a11yValue})}
            >
                {value}
            </DataCell>
        );
    })
);

FeatureColumnCells.displayName = 'PackageSelectorFeatureCells';

FeatureColumnCells.propTypes = {
    brand: brandPropType,
    features: propTypes.arrayOf(propTypes.shape({
        value: propTypes.node,
        a11yValue: propTypes.string,
    })),
    isDisabled: propTypes.bool,
};

export default FeatureColumnCells;
