// https://app.zeplin.io/project/5f88df809c9d7a5c5904dfbb/screen/5f88dfd00752a33fb63e4adb

// Core colors
export const flash = '#0049ff';
export const live = '#f34353';
export const white = '#ffffff';
export const black = '#000000';

// Secondary colors
export const blueCharcoal = '#020a25';
export const graphite = '#323232';
export const smoke = '#919191';
export const fog = '#e2e3e6';
export const sky = '#4dbbff';

// Calculated colors - these are tinted versions of the colors above that are used for interaction states
// The polished babel plugin freaks out if we directly export the result of a polished function call;
/**
 * `buttonActiveState` is technically mix(0.6, flash, blueCharcoal), but we've manually computed it here to work around this error.
 **/
export const buttonActiveState = '#0030a7';

/** Palette */
export default {
    flash,
    live,
    white,
    black,
    blueCharcoal,
    graphite,
    smoke,
    fog,
    sky,
    buttonActiveState,
};
