import bacon from 'baconjs';
import {getApiFromRetryWithError} from '@fsa-streamotion/request-manager';
import {getResourcesUrlBasePath} from '@fsa-streamotion/streamotion-web-widgets-common';

import {responseConsideredValidWith404} from '../../response-considered-valid';

/**
 * Fetch an arbitrary object from Streamotion's NetStorage instance
 *
 * @param {Object} options options object
 * @param {string} options.brand brand name for resource to fetch
 * @param {string} options.resourcesEnv resources environment e.g. staging
 * @param {string} options.path relative path to the asset
 * @returns {bacon.Observable} Stream containing the payload returned at the path
 */
export default function getResourcesAsset({
    brand,
    resourcesEnv,
    path,
}) {
    const url = `${getResourcesUrlBasePath({brand, resourcesEnv})}/${path}`;

    return getApiFromRetryWithError({
        url,
        validateResponse: responseConsideredValidWith404,
    })
        .first() // Force it to close, as getApiFromRetryWithError doesn't close by default
        .flatMapLatest((response) => {
            if (response.responseConsideredValid) {
                try {
                    return response.parseResponseText();
                } catch (e) {
                    return new bacon.Error(e);
                }
            } else {
                return new bacon.Error({
                    message: `UMW: couldn't get resources asset (${url}): ${response.responseStatus} ${response.superAgentErr}`,
                    statusCode: response.responseStatus,
                });
            }
        });
}
