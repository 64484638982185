/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import property from 'lodash/property';
import {rgba} from 'polished';
import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {SCREEN_1680_DESKTOP, SCREEN_1920_DESKTOP} from '../../../../../common/screen-sizes';
import {fog, white} from '../../../../../common/palette';

// @TODO: If this ListItem with Separator styled <li> is no longer a common style for multiple components,
// create and use separate components instead.
export const ListItem = styled.li`
    display: inline-flex;
    position: relative;
    align-items: center;
    letter-spacing: 0.5px;
    color: ${fog};

    ${mediaQuery({minWidthPx: SCREEN_1680_DESKTOP})`
        letter-spacing: 0.63px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        letter-spacing: 0.5px;
    `}

    &:not(:first-of-type) {
        margin-left: 29px;

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            margin-left: 59px;
        `}

        &::before {
            position: absolute;
            left: -16px;
            color: ${({separatorOpacity}) => rgba(white, separatorOpacity)};
            content: '${property('separator')}';

            ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
                left: -32px;
            `}
        }
    }
`;

ListItem.displayName = 'ListItem';
