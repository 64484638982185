// OFFICIAL STYLEGUIDE COLOURS
export const black = '#000';
export const coal = '#0d0f19';
export const ironStone = '#2a2c37';
export const kayoGreen = '#00bf6f';
export const midnight = '#0a0a10';
export const red = '#ff2323';
export const satellite = '#aaafb8';
export const silverLining = '#d2d2db';
export const slate = '#aaacb8';

export default {
    black,
    coal,
    ironStone,
    kayoGreen,
    midnight,
    red,
    satellite,
    silverLining,
    slate,
};
