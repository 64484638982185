import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import {mediaQuery, stylesWhen} from '@fsa-streamotion/styled-component-helpers';

import {
    SCREEN_375_PHABLET,
    SCREEN_768_TABLET,
    SCREEN_1024_DESKTOP,
    SCREEN_1280_DESKTOP,
    SCREEN_1480_DESKTOP,
    SCREEN_1920_DESKTOP,
    SCREEN_2560_DESKTOP,
} from '../../../common/screen-sizes';
import {CONTENT_EDGE_SPACING_PERCENT} from '../../../common/style-constants';
import {white} from '../../../common/palette';

export const GenericContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({color}) => rgba(color, 0.7)};
    height: 500px;
    text-align: center;
    color: ${white};
    font: var(--infinity-header-3);
`;

GenericContent.displayName = 'GenericContent';

export const Panel = styled.div`
    position: relative;
`;

Panel.displayName = 'Panel';

/*
 * On standard pages, use this to wrap the hero carousel / OR01Hero panel
 *  - hasRaisedCta is for adjusting the hero panel when a sticky nav is present
 **/
export const HeroWrapper = styled(Panel)`
    width: 100%;
    height: 150vw; /* source image is in ratio of 1:1.79 - this will ensure we never overstretch the image */
    min-height: 540px;
    max-height: calc(100vh - 100px);

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        height: 600px;
        min-height: 600px;
        max-height: 600px;

        ${stylesWhen('hasRaisedCTA')`
            height: 640px;
            max-height: 100vh;
        `}
    `}

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        height: 60vw;
        max-height: calc(100vh - 200px);

        ${stylesWhen('hasRaisedCTA')`
            height: 70vw;
            max-height: 100vh;
        `}
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        height: 80vh;
        max-height: calc(100vh - 180px);

        ${stylesWhen('hasRaisedCTA')`
            height: 100vh;
            min-height: 720px;
            max-height: 851px;
        `}
    `}

    ${mediaQuery({minWidthPx: SCREEN_1480_DESKTOP})`
        ${stylesWhen('hasRaisedCTA')`
            height: 85vh;
            max-height: 100vh;
        `}
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        max-height: calc(100vh - 160px);
        min-height: 840px;

        ${stylesWhen('hasRaisedCTA')`
            height: 90vh;
            max-height: 100vh;
        `}
    `}
`;

HeroWrapper.displayName = 'HeroWrapper';

/**
 * Used for CAM01Stand
 */
export const GenericCarouselWrapper = styled(Panel)`
    margin-bottom: 17px;
    width: 100%;

    ${mediaQuery({minWidthPx: SCREEN_375_PHABLET})`
        margin-bottom: 23px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        margin-bottom: 28px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1480_DESKTOP})`
        margin-bottom: 19px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        margin-bottom: 10px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
        margin-bottom: 14px;
    `}
`;

GenericCarouselWrapper.displayName = 'GenericCarouselWrapper';

/**
 * Used for OR60SeaEpi
 */
export const SeasonEpisodeCarouselWrapper = styled(Panel)`
    margin-bottom: 28px;
    width: 100%;

    ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
        margin-bottom: 35px;
    `}
`;

SeasonEpisodeCarouselWrapper.displayName = 'SeasonEpisodeCarouselWrapper';

/**
 * Provides left and right padding consistent with the rest of the product
 * Useful for CAM01Stands on content pages
 */
export const BoxedPanelRow = styled(Panel)`
    padding: 0 ${CONTENT_EDGE_SPACING_PERCENT}%;
`;

BoxedPanelRow.displayName = 'BoxedPanelRow';

const PanelTemplate = ({
    children,
    ...htmlAttributes
}) => (
    <main {...htmlAttributes}>
        {children}
    </main>
);

PanelTemplate.propTypes = {
    /** Content of the panel; expected to be pre-wrapped with HeroWrapper, BoxedPanelRow, GenericCarouselWrapper or SeasonEpisodeCarouselWrapper */
    children: propTypes.node,
};

PanelTemplate.displayName = 'PanelTemplate';

export default PanelTemplate;
