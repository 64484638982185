import React from 'react';

import Ic from '..';

const IC251DidntLike = (args) => (
    <Ic {...args}>
        <path d="M69.475 25.521L49.08 45.916 28.685 25.521l-3.164 3.164L45.916 49.08 25.521 69.475l3.164 3.164L49.08 52.244l20.395 20.395 3.164-3.164L52.244 49.08l20.395-20.395z" />
    </Ic>
);

IC251DidntLike.displayName = 'IC251DidntLike';

export default IC251DidntLike;
