import React, {useState} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import classnames from 'classnames';
import noop from 'lodash/noop';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {SCREEN_TABLET} from '../../../../common/screen-sizes';
import BA01BtnPr from '../../../atoms/ba/01-btn-pr';
import BA08BtnSec from '../../../atoms/ba/08-btn-sec';
import BA22EditBtn from '../../../atoms/ba/22-edit-btn';
import IM01Input from '../../../molecules/im/01-input';
import {midnight, silverLining} from '../../../../common/palette';
import GA89Tile from '../../../atoms/ga/89-tile';
import TM24AlertTile from '../../../molecules/tm/24-alert-tile';
import IC103Loading from '../../../atoms/ic/103-loading';

const Container = styled.section`
    padding: 0 14px;
    color: ${silverLining};
`;

const FullWidthForm = styled.form`
    width: 100%;
    text-align: center;
`;

const HelpText = styled.p`
    text-align: left;
    color: ${rgba(silverLining, 0.7)};
    font: var(--mui-body-copy-4);
`;

const InfoText = styled.p`
    padding: 0 7px;
    font: var(--mui-body-copy-4);
`;

const PaddedText = styled(InfoText)`
    margin: 21px 0;
    padding: 0 49px;
    text-align: center;
`;

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 14px;
`;

const ButtonSet = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 110px;
`;

const PlanContainer = styled.div`
    width: 115px;

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        width: 192px;
    `}
`;

const PlanPreviewTextContainer = styled.div`
    font: var(--mui-body-copy-3);
`;

const PromotionHeader = styled.h3`
    margin: 0;
    font: var(--mui-header-5);
`;

const TermsAndConditionsContainer = styled.div`
    display: inline-block;
    margin-top: 14px;
`;

const VoucherWarningTile = styled(TM24AlertTile)`
    margin-bottom: 21px;
    text-align: left;
`;

const VoucherWarningText = styled.p`
    margin: 0 0 7px;
`;

const VoucherWarningList = styled.ul`
    margin: 0;
    padding-left: 21px;
`;

const FormButtonRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 21px;
`;

const LoadingBlock = styled(IC103Loading).attrs({
    color: silverLining,
    size: 36,
})`
    display: block;
    margin: 14px auto 0;
`;

const TemplateRedeemVoucher = ({
    initialInputValue,
    voucherCodeError,
    hasVerifiedVoucherCode,
    onVerifyVoucherSubmit = noop,
    enterVoucherCodeHelpText = 'Enter your 6 - 16 character code',
    promotionTagLine,
    promotionDescription,
    promotionStartDate,
    promotionEndDate,
    voucherCode,
    termsAndConditionsUrl,
    previousOfferPlanRadBtn,
    newOfferPlanRadBtn,
    onApplyVoucherClick,
    onRemoveVoucherClick,
    isLoading,
    onChangeVoucherCode,
    applyVoucherError,
    vouchersToDrop = [],
    dropVouchersWarningMessage,
    onConfirmDropVouchers = noop,
    className,
}) => {
    const [inputValue, setInputValue] = useState(initialInputValue);

    function handleVoucherCodeChange(event) {
        setInputValue(event.currentTarget.value);
        onChangeVoucherCode(event);
    }

    return (
        <Container className={classnames('TemplateRedeemVoucher', className)}>
            {hasVerifiedVoucherCode
                ? (
                    <React.Fragment>
                        <GA89Tile color={silverLining} backgroundColor={rgba(midnight, 0.7)}>
                            <PlanPreviewTextContainer>
                                <PromotionHeader>
                                    {promotionTagLine}
                                </PromotionHeader>
                                <p>{`Code: ${voucherCode}`}</p>
                                <p>
                                    {`Applies from ${promotionStartDate} to ${promotionEndDate}`}
                                </p>
                                <p>Regular price for subscribed package charged from end of offer period.</p>
                                {promotionDescription}
                                <TermsAndConditionsContainer>
                                    <BA22EditBtn href={termsAndConditionsUrl} target="_blank">
                                        Terms and Conditions
                                    </BA22EditBtn>
                                </TermsAndConditionsContainer>
                            </PlanPreviewTextContainer>
                        </GA89Tile>
                        <PaddedText>By selecting Apply Voucher, you are updating your plan from:</PaddedText>
                        <FlexRow>
                            <PlanContainer>
                                {previousOfferPlanRadBtn}
                            </PlanContainer>
                            <InfoText>to</InfoText>
                            <PlanContainer>
                                {newOfferPlanRadBtn}
                            </PlanContainer>
                        </FlexRow>
                        {!!applyVoucherError && (
                            <TM24AlertTile>{applyVoucherError}</TM24AlertTile>
                        )}
                        <ButtonSet>
                            <BA01BtnPr
                                onClick={onApplyVoucherClick}
                                disabled={isLoading}
                            >
                                Apply Voucher
                            </BA01BtnPr>
                            <BA22EditBtn
                                onClick={onRemoveVoucherClick}
                                disabled={isLoading}
                            >
                                Remove Voucher
                            </BA22EditBtn>
                        </ButtonSet>
                        {!!isLoading && <LoadingBlock />}
                    </React.Fragment>
                )
                : (vouchersToDrop.length ? (
                    <FullWidthForm
                        onSubmit={(e) => {
                            e.preventDefault();
                            onConfirmDropVouchers(e.target.voucher.value);
                        }}
                    >
                        <IM01Input
                            name="voucher"
                            label="Enter your voucher here"
                            disabled={true}
                            value={voucherCode}
                        />
                        <VoucherWarningTile>
                            <VoucherWarningText>
                                {dropVouchersWarningMessage}
                            </VoucherWarningText>
                            <VoucherWarningList>
                                {vouchersToDrop.map((voucher) => (
                                    <li key={voucher}>{voucher}</li>
                                ))}
                            </VoucherWarningList>
                        </VoucherWarningTile>
                        <FormButtonRow>
                            <BA01BtnPr
                                isBlock={true}
                                type="button"
                                onClick={onRemoveVoucherClick}
                                disabled={!!isLoading || !inputValue}
                            >
                                Go Back
                            </BA01BtnPr>
                            <BA08BtnSec
                                isBlock={true}
                                type="submit"
                                disabled={!!isLoading || !inputValue}
                            >
                                OK
                            </BA08BtnSec>
                        </FormButtonRow>
                        {!!isLoading && <LoadingBlock />}
                    </FullWidthForm>
                ) : (
                    <FullWidthForm
                        onSubmit={(e) => {
                            e.preventDefault();
                            onVerifyVoucherSubmit(e.target.voucher.value);
                        }}
                    >
                        <IM01Input
                            name="voucher"
                            required={true}
                            onChange={handleVoucherCodeChange}
                            label="Enter your voucher here"
                            disabled={isLoading}
                            contextualHelp={voucherCodeError}
                            defaultValue={initialInputValue}
                        />
                        <HelpText>{enterVoucherCodeHelpText}</HelpText>
                        <BA01BtnPr
                            type="submit"
                            disabled={!!isLoading || !inputValue}
                        >
                            Redeem
                        </BA01BtnPr>
                        {!!isLoading && <LoadingBlock />}
                    </FullWidthForm>
                ))}
        </Container>
    );
};

TemplateRedeemVoucher.displayName = 'TemplateRedeemVoucher';

TemplateRedeemVoucher.propTypes = {
    /** Optional extra class names */
    className: classNameType,
    /** Optional initial value for the input field */
    initialInputValue: propTypes.string,
    /** An optional error from validation */
    voucherCodeError: propTypes.node,
    /** Has a voucher code been added and verified? */
    hasVerifiedVoucherCode: propTypes.bool,
    /** Is anything here in a pending state */
    isLoading: propTypes.bool,
    /** Called when the voucher is typed in and submitted */
    onVerifyVoucherSubmit: propTypes.func,
    /** Optional custom help text for the voucher input field */
    enterVoucherCodeHelpText: propTypes.node,
    /** A short name for the promotion */
    promotionTagLine: propTypes.node,
    /** A longer description of the promotion */
    promotionDescription: propTypes.node,
    /** Formatted string representing when the promotion starts */
    promotionStartDate: propTypes.string,
    /** Formatted string representing when the promotion ends */
    promotionEndDate: propTypes.string,
    /** The voucher code the user typed in */
    voucherCode: propTypes.string,
    /** The URL to the terms and conditions page */
    termsAndConditionsUrl: propTypes.string,
    /** A BA14OfferRadBtn summary of the previous plan */
    previousOfferPlanRadBtn: propTypes.node,
    /** A BA14OfferRadBtn summary of the new plan */
    newOfferPlanRadBtn: propTypes.node,
    /** Called when the voucher is finally confirmed, to apply the voucher to the user */
    onApplyVoucherClick: propTypes.func,
    /** Removes the previously verified voucher */
    onRemoveVoucherClick: propTypes.func,
    /** Called when the voucher input field is changed */
    onChangeVoucherCode: propTypes.func,
    /** A list of vouchers that will be dropped if we apply this new voucher */
    vouchersToDrop: propTypes.arrayOf(propTypes.string),
    /** Called when we confirm we want to drop the old vouchers and apply the new one */
    onConfirmDropVouchers: propTypes.func,
    /** A warning message explaining the the user will need to drop vouchers */
    dropVouchersWarningMessage: propTypes.node,
    /** An optional voucher from applying the voucher */
    applyVoucherError: propTypes.node,
};

export default TemplateRedeemVoucher;
