import React from 'react';
import Ic from '..';

const IC135Cross = (args) => (
    <Ic {...args}>
        <path fillRule="evenodd" d="M79 23.836L76.164 21 50 47.235 23.836 21 21 23.836 47.235 50 21 76.164 23.836 79 50 52.765 76.164 79 79 76.164 52.765 50z" />
    </Ic>
);

IC135Cross.displayName = 'IC135Cross';

export default IC135Cross;
