import React from 'react';

import Ic from '..';

const IC554Up = (args) => (
    <Ic {...args}>
        <path d="M68.6 67.6H5.9c-.3 0-.6-.2-.6-.6V32.9c0-.3.2-.6.6-.6h62.7c.3 0 .6.2.6.6v34.2c0 .3-.3.5-.6.5zM6.5 66.5h61.6v-33H6.5v33zM94 67.6H73.7c-.3 0-.6-.2-.6-.6v-8.4c0-.3.2-.6.6-.6H94c.3 0 .6.2.6.6V67c-.1.4-.3.6-.6.6zm-19.7-1.1h19.1v-7.3H74.3v7.3zM94 55.2H73.7c-.3 0-.6-.2-.6-.6v-8.4c0-.3.2-.6.6-.6H94c.3 0 .6.2.6.6v8.4c-.1.4-.3.6-.6.6zm-19.7-1.1h19.1v-7.3H74.3v7.3zM93.5 42.8H74.2c-.6 0-1.1-.5-1.1-1.1v-8.2c0-.6.5-1.1 1.1-1.1h19.3c.6 0 1.1.5 1.1 1.1v8.2c0 .6-.5 1.1-1.1 1.1zm-18.2-2.2h17.1v-6H75.3v6z" />
    </Ic>
);

IC554Up.displayName = 'IC554Up';

export default IC554Up;
