import React from 'react';

import Ic from '..';

const IC175Casting = (args) => (
    <Ic {...args}>
        <path d="M67.9 59.5l2.6-1.7c-4.4-6.6-11.9-11-20.5-11s-16.1 4.4-20.6 11l2.5 1.7c3.9-5.9 10.5-9.7 18-9.7s14.2 3.9 18 9.7zM58.5 65.8c-1.8-2.7-4.9-4.6-8.5-4.6s-6.7 1.9-8.5 4.6l2.5 1.7c1.3-2 3.5-3.2 6-3.2s4.7 1.3 6 3.2l2.5-1.7z" />
        <path d="M50 57.2c4.9 0 9.4 2.5 11.9 6.4l2.5-1.7c-3.1-4.7-8.4-7.7-14.4-7.7S38.6 57.3 35.5 62l2.6 1.7c2.6-4 7-6.5 11.9-6.5z" />
        <path d="M82.1 24H18c-.4 0-.8.3-.8.8v45.6c0 .4.3.8.8.8h5v2.1c0 .8.9.8 1.6.8h20.1l-4.4-3-2.1-1.4H18.6V25.5h62.6v44.1H62L59.8 71l-4.4 3h20c.8 0 1.6 0 1.6-.8v-2.1h5c.4 0 .8-.3.8-.8V24.8c0-.4-.3-.8-.7-.8z" />
        <path d="M46.5 69.2s1.5 1.1 2.1 1.4c.8-.3 1.7-.3 2.5 0l2.1-1.4c-1.7-2.2-4.8-2.2-6.7 0z" />
    </Ic>
);

IC175Casting.displayName = 'IC175Casting';

export default IC175Casting;
