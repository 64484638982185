import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';
import {black, white} from '../../../../common/palette';

import Ic from '..';

const IC31Tick = ({
    color = black,
    secondaryColor = white,
    ...rest
}) => (
    <Ic {...rest}>
        <g transform="scale(3.8 3.8)">
            <circle cx="12.965" cy="12.965" r="12.965" fill={color} />
            <path fill={secondaryColor} d="M9.887 19.787L5.186 15.086 6.988 13.284 9.887 16.183 18.924 7.146 20.727 8.952z" />
        </g>
    </Ic>
);

IC31Tick.propTypes = {
    color: colorType,
    secondaryColor: colorType,
};

IC31Tick.displayName = 'IC31Tick';

export default IC31Tick;
