import React from 'react';
import Ic from '..';

const IC03Not = (args) => (
    <Ic {...args}>
        <path d="M88.6 71.2L74 45.3v-9.9c0-6.6-2.6-12.8-7.4-17.3-3.8-3.7-8.6-5.9-13.8-6.5V5.4h-5.6v6.2C35.2 13 26 23.4 26 36v9.2l-14.6 26c-1.6 2.9-1.3 6.4.9 8.7 1 1.1 2.8 2.4 5.9 2.4h63.6c3 0 4.9-1.3 5.9-2.4 2.1-2.3 2.5-5.8.9-8.7zm-5 5c-.1.2-.6.6-1.8.6H18.2c-1.2 0-1.7-.5-1.8-.6-.5-.5-.5-1.5-.1-2.2l14.9-26.5c.2-.4.4-.9.4-1.4V36c0-10.2 7.7-18.5 17.5-19h.9c4.8 0 9.2 1.8 12.7 5.1 3.7 3.5 5.7 8.2 5.7 13.3V46c0 .5.1.9.4 1.4l14.9 26.5c.4.8.4 1.7-.1 2.3zM50 91.8c-3.8 0-7.4-2-9.4-5.3l-2.4 1.4c2.5 4.1 7 6.7 11.8 6.7 4.8 0 9.3-2.6 11.8-6.7l-2.4-1.4c-2 3.2-5.6 5.3-9.4 5.3z" />
    </Ic>
);

IC03Not.displayName = 'IC03Not';

export default IC03Not;
