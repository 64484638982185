import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {akela, blanc, panther} from '../../../../common/palette';
import {IC42Error} from '../../../atoms/ic';

const Tile = styled.div.attrs({
    role: 'alert',
})`
    display: grid;
    position: relative;
    grid-template-columns: 35px 1fr;
    grid-column-gap: 14px;
    align-items: center;
    border: 1px solid ${rgba(akela, 0.4)};
    border-radius: 6px;
    box-shadow: 0 1px 1px 0 ${rgba(panther, 0.1)};
    padding: 14px;
    color: ${rgba(blanc, 0.9)};
    font: var(--ionic-body-copy-4);

    a {
        color: inherit;
    }
`;

const TM24AlertTile = ({
    children,
    className,
    icon = <IC42Error />,
    ...htmlAttributes
}) => (
    <Tile
        {...htmlAttributes}
        className={classnames('TM24AlertTile', className)}
    >
        {icon}

        <div>
            {children}
        </div>
    </Tile>
);

TM24AlertTile.propTypes = {
    /** Content for the alert panel */
    children: propTypes.node.isRequired,
    /** Optional class(es) */
    className: classNameType,
    /** Icon */
    icon: propTypes.node,
};

TM24AlertTile.displayName = 'TM24AlertTile';

export default TM24AlertTile;
