import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import noop from 'lodash/noop';
import classnames from 'classnames';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import ResponsiveSizer from '../../../../common/responsive-sizer';
import {white, black, coal} from '../../../../common/palette';
import {SCREEN_TABLET, SCREEN_PHABLET} from '../../../../common/screen-sizes';

import CAM03Finite from '../../../molecules/cam/03-finite';
import BA04TeamTi from '../../../atoms/ba/04-team-ti';
import BA30TextBtn from '../../../atoms/ba/30-text-btn';

const StyledSection = styled.section`
    width: 100%;
    color: ${white};
`;

const StyledHeader = styled.header`
    margin: 0 auto 14px;
    width: calc(100% - 14px);
    text-align: center;
`;

const StyledTitle = styled.h2`
    margin: 0;
    font: var(--mui-header-3-bold);
`;

const StyledLink = styled(BA30TextBtn)`
    &:nth-child(2) { /* detect if we need to allow space between this link and a preceeding header title */
        margin-top: 7px; /* wanted to use display: grid and row-gap, however that resulted in the hyperlink clickbox being far too wide */
    }
`;

const StyledBA04TeamTi = styled(BA04TeamTi)`
    margin-bottom: 8px; /* allow space for the nav indicator, which is outside document flow */
`;

const StyledOverlayText = styled.p`
    position: absolute;
    top: 25%;
    margin: 0;
    padding-left: 2%;
    width: 80%;
    text-shadow: 1px 1px 4px ${rgba(black, 0.5)};
    font: var(--mui-header-7-bold);

    ${mediaQuery({minWidthPx: SCREEN_PHABLET})`
        font: var(--mui-header-5-bold);
    `}

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        top: 9%;
        width: 50%;
        padding-left: 14%;
    `}
`;

const TintLayer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    background: ${rgba(coal, 0.35)};
    width: 100%;
    height: 100%;
`;

/**
 * This is a legacy component for backwards compatibility with marketing configs. It is the old implementation of OR14SeaBlk.
 * Once all the configs have been updated, this component can be deprecated
 */
export default class XX01SeaBlk extends React.Component {
    static displayName = 'XX01SeaBlk';

    static propTypes = {
        className: classNameType,
        sports: propTypes.arrayOf(
            propTypes.shape({
                icon: propTypes.node,
                label: propTypes.string.isRequired,
                previewAsset: propTypes.node,
                text: propTypes.string,
            })
        ),
        title: propTypes.string,
        onClickSport: propTypes.func,
        seeMoreHref: propTypes.string,
        seeMoreCopy: propTypes.string,
    };

    static defaultProps = {
        sports: [],
        onClickSport: noop,
        seeMoreCopy: 'See our full list of sports',
    };

    state = {
        expandedItemIndex: 0,
    };

    render() {
        const {
            className,
            onClickSport,
            seeMoreCopy,
            seeMoreHref,
            sports = [],
            title,
        } = this.props;
        const {previewAsset, text} = sports[this.state.expandedItemIndex] || {};

        return (
            <StyledSection className={classnames('XX01SeaBlk', className)}>
                {!!(title || seeMoreHref) && (
                    <StyledHeader>
                        {!!title && (
                            <StyledTitle>
                                {title}
                            </StyledTitle>
                        )}
                        {!!seeMoreHref && (
                            <StyledLink
                                href={seeMoreHref}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {seeMoreCopy}
                            </StyledLink>
                        )}
                    </StyledHeader>
                )}
                <CAM03Finite>
                    {sports
                        .map(({label, icon}, index) => (
                            <StyledBA04TeamTi
                                className={`XX01SeaBlk-team-tile-${index}`}
                                key={label}
                                aria-label={label}
                                isNavIndicatorOnHover={true}
                                isNavIndicatorOn={this.state.expandedItemIndex === index}
                                onClick={() => {
                                    this.setState({expandedItemIndex: index});
                                    onClickSport(label);
                                }}
                            >
                                {icon}
                            </StyledBA04TeamTi>
                        ))}
                </CAM03Finite>

                {!!previewAsset && (
                    <ResponsiveSizer className="XX01SeaBlk-preview-asset" width={16} height={9} aria-live="polite">
                        <TintLayer />
                        {!!text && (
                            <StyledOverlayText className="XX01SeaBlk-text-overlay">
                                {text}
                            </StyledOverlayText>
                        )}
                        {previewAsset}
                    </ResponsiveSizer>
                )}
            </StyledSection>
        );
    }
}
