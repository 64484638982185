import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import noop from 'lodash/noop';
import {rgba} from 'polished';
import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';

import {Button} from '../../../../common/normalized-styled-components';
import {white, black, flash} from '../../../../common/palette';
import {transition} from '../../../../common/animations';

const ImageWrapper = styled.div`
    position: relative;
    flex-shrink: 0;
    transition: ${transition('transform')};
    width: 56px;
    height: 56px;

    ${stylesWhen('hasChildren')`
        margin-right: 14px;
    `}
    ::after {
        position: absolute;
        right: 7px;
        bottom: -10px;
        left: 7px;
        transform: scaleX(0);
        transition: ${transition('transform')};
        background: ${flash};
        height: 2px;
        content: ' ';
    }
`;

const StyledImg = styled.img`
    width: 100%;
    height: 100%;
`;

const StyledButton = styled(Button)`
    appearance: none;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background-color: transparent;
    padding: 0;
    max-width: calc(100% - 42px);
    height: 56px;
    color: ${white};
    font: var(--infinity-success-header);

    &:focus,
    &:hover {
        ${ImageWrapper} {
            transform: scale(1.08);

            &::after {
                transform: scaleX(1);
            }
        }
    }

    &:focus {
        outline: 0;
    }
`;

/* stylelint-disable font-family-no-missing-generic-family-keyword */
const StyledDiv = styled.div`
    display: inline-flex;
    align-items: center;
    height: 100%;
    overflow: hidden;
    text-shadow: 0 1px 1px ${rgba(black, 0.3)};
    white-space: nowrap;
    mask-image: linear-gradient(to right, #000 200px, transparent); /* stylelint-disable-line color-no-hex */
    font-family: Gibson;
    font-size: 26px;
    font-weight: 300;
`;

const NM06TrigBtn = React.forwardRef(({
    imageUrl,
    'aria-pressed': isPressed,
    children,
    onClick = noop,
    ...htmlAttributes
}, ref) => (
    <StyledButton
        {...htmlAttributes}
        type="button"
        onClick={onClick}
        aria-pressed={isPressed}
        ref={ref}
    >
        {!!imageUrl && (
            <ImageWrapper hasChildren={!!children}>
                <StyledImg
                    alt="Avatar"
                    src={imageUrl}
                />
            </ImageWrapper>
        )}
        <StyledDiv>{children}</StyledDiv>
    </StyledButton>
));

/* eslint-disable quote-props */
NM06TrigBtn.propTypes = {
    /** aria-pressed attribute describes a toggle button */
    'aria-pressed': propTypes.oneOf([true, false, 'mixed']),
    /** Content of button, text */
    children: propTypes.node,
    /** Url of the image */
    imageUrl: propTypes.string,
    /** Callback on element click */
    onClick: propTypes.func,
};
/* eslint-enable quote-props */

NM06TrigBtn.displayName = 'NM06TrigBtn';

export default NM06TrigBtn;
