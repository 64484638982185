import React from 'react';
import Ic from '..';

const IC57Download = (args) => (
    <Ic {...args}>
        <path d="M28.968 80.591h42.797v4.221H28.968zm34.955-32.392l-9.333 9.329V16.384l-8.447.113v41.031l-9.334-9.329-5.969 5.98 19.526 19.517 19.526-19.517z" />
    </Ic>
);

IC57Download.displayName = 'IC57Download';

export default IC57Download;
