import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import noop from 'lodash/noop';
import property from 'lodash/property';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {srcsetOptionsType} from '@fsa-streamotion/custom-prop-types';

import {
    SCREEN_320_MOBILE,
    SCREEN_375_PHABLET,
} from '../../../../../common/screen-sizes';
import BA28BtnSec from '../../../../atoms/ba/28-btn-sec';
import IC103Loading from '../../../../atoms/ic/103-loading';
import TR02SetTil from '../../../../atoms/tr/02-set-til';

const TILE_IMAGE_WIDTH_PX_MAP = {
    [SCREEN_320_MOBILE]: 132,
    [SCREEN_375_PHABLET]: 161,
};

const StyledTR02SetTil = styled(TR02SetTil)`
    width: 132px;
    max-width: 161px;
    height: 57px;
    max-height: 72px;

    ${mediaQuery({minWidthPx: SCREEN_375_PHABLET})` // override gigantic sizes in TR02
        width: 161px;
        min-width: 161px;
        height: 72px;
        min-height: 72px;
    `}
`;

const CatTabSelectorContainer = styled.div`
    padding: 18px;
`;

/** ***** CTA ****** */

const CtaWrapper = styled.div`
    margin: 0 auto;
    width: 250px;
    text-align: center;
`;

/** ***** LOADING ****** */

const StyledIC103Loading = styled(IC103Loading)`
    display: block;
    margin: 80px auto;
`;

/** ****** NON-EMPTY STATE ***********/

const SelectorSection = styled.section`
    padding-bottom: 16px;
`;

const SectionHeading = styled.div`
    margin: 0 54px 12px 0;
    font: var(--infinity-carousel-header);
`;

const Tiles = styled.div`
    --infinity-selector-tile-size: ${TILE_IMAGE_WIDTH_PX_MAP[SCREEN_320_MOBILE]}px;
    display: grid;
    grid-template-columns: repeat(2, minmax(var(--infinity-selector-tile-size), auto));
    grid-gap: 14px;
    justify-content: space-between;
    min-height: 57px;

    ${mediaQuery({minWidthPx: SCREEN_375_PHABLET})`
        --infinity-onboarding-tile-size: ${TILE_IMAGE_WIDTH_PX_MAP[SCREEN_375_PHABLET]}px;
        min-height: 72px;
    `}
`;

/** ****** EMPTY STATE ***********/

const EmptySection = styled.section`
    margin: 105px 21px 22px;
    text-align: center;
`;

const EmptySectionHeader = styled.div`
    margin-bottom: 14px;
    font: var(--infinity-header-4-bold);
`;

const EmptySectionDescription = styled.div`
    margin: 0 14px;
    font: var(--infinity-body-copy-3-light);
`;

const CatTabSelector = ({
    isLoading,
    selectors = [],
    emptySelectorHeader,
    emptySelectorDescription,
    ctaCopy,
    ctaUrl,
    onClickCta = noop,
}) => (
    <CatTabSelectorContainer>
        {isLoading
            ? <StyledIC103Loading size="100px" />
            : (
                <React.Fragment>
                    {selectors.length > 0
                        ? selectors
                            .filter(property('tiles.length'))
                            .map(({sectionHeading, tiles}) => (
                                <SelectorSection key={sectionHeading}>
                                    <SectionHeading>{sectionHeading}</SectionHeading>
                                    <Tiles>
                                        {tiles.map(({tileImageSrcset, focusedTileImageSrcset, href, displayName, onClick}) => (
                                            <StyledTR02SetTil
                                                key={displayName}
                                                ariaLabel={displayName}
                                                disabled={false}
                                                onClick={onClick}
                                                {...{
                                                    tileImageSrcset,
                                                    focusedTileImageSrcset,
                                                    href,
                                                }}
                                            />
                                        ))}
                                    </Tiles>
                                </SelectorSection>
                            ))
                        : (
                            <EmptySection>
                                <EmptySectionHeader>{emptySelectorHeader}</EmptySectionHeader>
                                <EmptySectionDescription>{emptySelectorDescription}</EmptySectionDescription>
                            </EmptySection>
                        )
                    }
                    {!!ctaCopy && (
                        <CtaWrapper>
                            <BA28BtnSec href={ctaUrl} onClick={onClickCta}>{ctaCopy}</BA28BtnSec>
                        </CtaWrapper>
                    )}
                </React.Fragment>
            )
        }
    </CatTabSelectorContainer>
);

CatTabSelector.displayName = 'CatTabSelector';

CatTabSelector.propTypes = {
    isLoading: propTypes.bool,
    selectors: propTypes.arrayOf(
        propTypes.shape({
            sectionHeading: propTypes.string,
            tiles: propTypes.arrayOf(
                propTypes.shape({
                    tileImageSrcset: srcsetOptionsType,
                    focusedTileImageSrcset: srcsetOptionsType,
                    href: propTypes.string,
                    displayName: propTypes.string,
                })
            ),
        })
    ),
    emptySelectorHeader: propTypes.string,
    emptySelectorDescription: propTypes.string,
    ctaCopy: propTypes.string,
    ctaUrl: propTypes.string,
    onClickCta: propTypes.func,
};

export default CatTabSelector;
