import bacon from 'baconjs';
import superagent from 'superagent';
import get from 'lodash/get';

import {getApiFromRetryWithError} from '@fsa-streamotion/request-manager';

import {RESPONSE_CODE} from '../../../utils/constants';
import addParamsToUrl from '../../../utils/add-params-to-url';
import isPrivate from '../../../utils/xpapi-helpers';

import {responseConsideredValidWith404} from '../../response-considered-valid';

/**
 * Get content url by key
 *
 * @param {Object} options                  - (see below)
 * @param {string} options.url              - url to call
 * @param {string} [options.accessToken]    - auth0 access token
 * @param {string} [options.profileId]      - profile param to append
 * @param {number} [options.retries]        - Clientside retries on failure
 *
 * @returns {bacon.Observable}              - Stream containing api response object
 */
export default function getContent({
    accessToken,
    profileId,
    retries,
    url,
} = {}) {
    const isPrivateUrl = isPrivate(url);

    if (isPrivateUrl) {
        if (!profileId || !accessToken) {
            return bacon.later(0, new bacon.Error(
                `Couldn't get content (${url}): missing required param profile or access token.`,
            ));
        }

        const contentUrl = addParamsToUrl(
            url,
            {
                profile: profileId,
            }
        );

        return bacon.fromBinder((sink) => {
            superagent
                .get(contentUrl.href)
                .set('Authorization', `Bearer ${accessToken}`)
                .then((response) => void sink(response.body))
                .catch((error) => {
                    const errorBody = JSON.parse(error?.response?.text || '""');
                    const errorMessage = get(errorBody, 'message', error.toString());

                    sink(new bacon.Error({
                        errorCode: error.status,
                        errorMessage,
                        fullErrorMessage: `Couldn't get content (${url}): ${error.status} ${errorMessage}`,
                    }));
                })
                .finally(() => void sink(new bacon.End()));

            return;
        });
    } else {
        return getApiFromRetryWithError({
            url,
            retries,
            validateResponse: responseConsideredValidWith404,
        })
            .first()
            .flatMapLatest((response) => {
                if (response.responseConsideredValid) {
                    return response.parseResponseText();
                } else if (response.responseStatus === RESPONSE_CODE.notFound) {
                    return [];
                } else {
                    return new bacon.Error(
                        `Couldn't get content (${url}): ${response.responseStatus} ${response.superAgentErr}`
                    );
                }
            });
    }
}
