import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {satellite, midnight, white} from '../../../../common/palette';
import {SCREEN_REALLY_LG_DESKTOP} from '../../../../common/screen-sizes';

const StyledSection = styled.section`
    margin: 0 auto;
    width: 100%;
    text-align: center;
    color: ${({isLightTheme}) => isLightTheme ? midnight : white};
`;

const StyledH2 = styled.h2`
    margin: 0 0 5px;
    font: var(--mui-header-4-bold);

    ${mediaQuery({minWidthPx: SCREEN_REALLY_LG_DESKTOP})`
        margin-bottom: 14px;
    `}
`;

const Copy = styled.p`
    margin: 0;
    color: ${({isLightTheme}) => isLightTheme ? midnight : satellite};
    font: var(--mui-body-copy-3);
`;

const OR16MarBlk = ({
    isLightTheme,
    displaySubtext,
    displayHeader,
    ...htmlAttributes
}) => (
    <StyledSection
        className="OR16MarBlk"
        isLightTheme={isLightTheme}
        {...htmlAttributes}
    >
        {!!displayHeader && <StyledH2>{displayHeader}</StyledH2>}
        {!!displaySubtext && <Copy isLightTheme={isLightTheme}>{displaySubtext}</Copy>}
    </StyledSection>
);

OR16MarBlk.propTypes = {
    /** Light or dark (default) theme */
    isLightTheme: propTypes.bool,
    /** CMS copy */
    displaySubtext: propTypes.node,
    /** CMS block title copy */
    displayHeader: propTypes.string,
};

OR16MarBlk.displayName = 'OR16MarBlk';

export default OR16MarBlk;
