import React from 'react';
import styled, {css} from 'styled-components';
import propTypes from 'prop-types';
import {A, Button} from 'normalized-styled-components';
import {rgba} from 'polished';
import classnames from 'classnames';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {blanc, midnight} from '../../../../common/palette';
import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';

const baseStyles = css`
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: ${transition('background-color', 'color')};
    border: none;
    border-radius: 3px;
    background-color: ${rgba(midnight, 0.2)};
    padding: 7px 0;
    width: 84px;
    height: 40px;
    color: ${blanc};
    font: var(--nucleus-header-7-light);

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding: 14px 0;
        width: 125px;
        height: 54px;
    `}

    &:hover,
    &:focus {
        outline: 0;
        background-color: ${rgba(blanc, 0.8)};
        color: ${midnight};
    }

    &:active {
        background-color: ${blanc};
        color: ${midnight};
        font: var(--nucleus-header-7);
    }
`;

const StyledButton = styled(Button)`
    ${baseStyles}
    appearance: none;
`;

const StyledLink = styled(A)`
    ${baseStyles}
    text-decoration: none;
`;

const BA40BtnSignIn = React.forwardRef(({
    children,
    className,
    href,
    ...htmlAttributes
}, ref) => {
    const StyledElement = href ? StyledLink : StyledButton;

    return (
        <StyledElement
            {...htmlAttributes}
            className={classnames('BA40BtnSignIn', className)}
            ref={ref}
            href={href}
        >
            {children}
        </StyledElement>
    );
});

BA40BtnSignIn.displayName = 'BA40BtnSignIn';

BA40BtnSignIn.propTypes = {
    /** Content of button, e.g. text */
    children: propTypes.node,
    /** additional CSS classnames to be applied */
    className: classNameType,
    /** If it’s actually a link, provide a href */
    href: propTypes.string,
};

export default BA40BtnSignIn;
