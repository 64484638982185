import React, {Fragment, useEffect, useState} from 'react';
import classnames from 'classnames';
import noop from 'lodash/noop';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {ink} from '../../../../common/palette';
import {SCREEN_1920_DESKTOP, SCREEN_768_TABLET} from '../../../../common/screen-sizes';

import BA01BtnPr from '../../../atoms/ba/01-btn-pr';
import BA28BtnSec from '../../../atoms/ba/28-btn-sec';
import IC103Loading from '../../../atoms/ic/103-loading';
import IM04Code from '../../../molecules/im/04-code';

const IS_VERIFYING = 'isVerifying';
const IS_ENTERING_CODE = 'isEnteringCode';
const IS_CODE_COMPLETE = 'isCodeComplete';
const IS_REGISTERING = 'isRegistering';

const DEFAULT_CONTEXTUAL_EDUCATION_TEXT = 'If you do not recognise this device, or the device number is incorrect, select cancel';

const Container = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: ${ink};
    gap: 26px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        gap: 35px;
    `}
`;

const InputCodeHeading = styled.h3`
    margin: 0;
    font: var(--quicksilver-body-copy-3-light);
`;

const CTAButtonSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 7px;
    min-width: 220px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        gap: 14px;
        min-width: 336px;
    `}
`;

const ContextualEducationContainer = styled.section`
    opacity: 0.6;
    width: 282px;
    text-align: center;
    font: var(--quicksilver-body-copy-5-light);

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 468px;
    `}
`;

const ButtonIconBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledIC103Loading = styled(IC103Loading)`
    width: 30px;
    height: 30px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: 35px;
        height: 35px;
    `}
`;

export const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 35px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        gap: 49px;
    `}
`;

const LoadingIcon = () => (
    <ButtonIconBox>
        <StyledIC103Loading ariaLabel="Loading" />
    </ButtonIconBox>
);

LoadingIcon.displayName = 'LoadingIcon';

/**
 * Device Registration form
 */
const OR158RegDevice = ({
    className,
    code,
    codeLength = 6,
    contextualEducationText = DEFAULT_CONTEXTUAL_EDUCATION_TEXT,
    contextualHelpText,
    isDeviceCodeValid,
    onClickCancel = noop,
    onClickConfirm = noop,
    onComplete = noop,
    onSubmitCode = noop,
    ...htmlAttributes
}) => {
    const [formState, setFormState] = useState(IS_ENTERING_CODE);
    const [resetAttemptCount, setResetAttemptCount] = useState(0);

    const handleOnCancel = () => {
        setResetAttemptCount((prevResetAttemptCount) => prevResetAttemptCount + 1);
        setFormState(IS_ENTERING_CODE);
        onClickCancel();
    };

    const handleOnComplete = (code) => {
        setFormState(IS_CODE_COMPLETE);
        onComplete(code);
    };

    const handleOnSubmit = (e) => {
        e.preventDefault();

        if (isDeviceCodeValid) {
            setFormState(IS_REGISTERING);
            onClickConfirm();
        } else {
            setFormState(IS_VERIFYING);
            onSubmitCode();
        }
    };

    const handleOnKeyDown = ({key}) => {
        const isBackSpaceOrDelete = ['Backspace', 'Delete'].includes(key);

        if ((formState !== IS_ENTERING_CODE) && isBackSpaceOrDelete) {
            setFormState(IS_ENTERING_CODE);
        }
    };

    useEffect(function resetFormStateAfterError() {
        setFormState(IS_ENTERING_CODE);
    }, [contextualHelpText]);

    return (
        <Container {...htmlAttributes} className={classnames('OR158RegDevice', className)}>
            <InputCodeHeading>{codeLength} Digit Activation Code</InputCodeHeading>

            <StyledForm onSubmit={handleOnSubmit}>
                <IM04Code
                    code={code}
                    codeLength={codeLength}
                    contextualHelpText={contextualHelpText}
                    disabled={formState === IS_VERIFYING || isDeviceCodeValid}
                    legend="Device Registration Code"
                    onComplete={handleOnComplete}
                    onKeyDown={handleOnKeyDown}
                    resetAttemptCount={resetAttemptCount}
                />

                <CTAButtonSection>
                    {isDeviceCodeValid
                        ? (
                            <Fragment>
                                <BA01BtnPr disabled={formState === IS_REGISTERING} isBlock={true} type="submit">
                                    {formState === IS_REGISTERING ? <LoadingIcon /> : 'Confirm'}
                                </BA01BtnPr>
                                <BA28BtnSec disabled={formState === IS_REGISTERING} isBlock={true} onClick={handleOnCancel}>
                                    Cancel
                                </BA28BtnSec>
                            </Fragment>
                        )
                        : (
                            <BA01BtnPr disabled={formState === IS_ENTERING_CODE} isBlock={true} type="submit">
                                {formState === IS_VERIFYING ? <LoadingIcon /> : 'Continue'}
                            </BA01BtnPr>
                        )
                    }
                </CTAButtonSection>
            </StyledForm>

            {isDeviceCodeValid && !!contextualEducationText && (
                <ContextualEducationContainer>
                    {contextualEducationText}
                </ContextualEducationContainer>
            )}
        </Container>
    );
};

OR158RegDevice.propTypes = {
    /** Additional CSS classnames to be applied */
    className: classNameType,
    /** A code string to continuously show on the input */
    code: propTypes.string,
    /** Length of code */
    codeLength: propTypes.number,
    /** Message to show when user has validated the code */
    contextualEducationText: propTypes.string,
    /** Message to show in the contextual help balloon underneath the code */
    contextualHelpText: propTypes.node,
    /** Is the code valid? Should be set via **onSubmitCode** */
    isDeviceCodeValid: propTypes.bool,
    /** A callback to run when a user clicks on the CTA button to cancel. This MUST change the `isDeviceCodeValid` prop */
    onClickCancel: propTypes.func,
    /** A callback to run when a user clicks on the CTA button to confirm the code  */
    onClickConfirm: propTypes.func,
    /** A callback to run when a user finishes entering all digits within the code input */
    onComplete: propTypes.func,
    /** A callback to run when a user clicks on the CTA button to validate the code */
    onSubmitCode: propTypes.func,
};

OR158RegDevice.displayName = 'OR158RegDevice';

export default OR158RegDevice;
