import React from 'react';

import Ic from '..';

// TODO: This is a placeholder icon which should be replaced before launch

const ICXXTBD = (args) => (
    <Ic {...args}>
        <path d="M13.1,64.9l-9.3,9.7l-3.5-3.3l22.6-23.6l3.5,3.3l-9.3,9.7l28.4,27.1l-4,4.2L13.1,64.9z" />
        <path d="M26.7,44.5c1.3-2.2,3.8-5.3,6.5-8.2c3.9-4.1,7.2-6.1,10.7-6.5c2.8-0.5,5.7,0.3,8.2,2.7c3.1,2.9,3.8,7.5,1.9,12.1l0.1,0.1 c3.6-2.3,9.5-3.5,14.3,1.1c2.7,2.6,3.8,5.7,3.8,8.8c-0.1,4.2-2.6,8.7-7.5,13.9c-2.7,2.8-5,4.8-6.5,6L26.7,44.5z M43.7,52.9l3.6-3.7 c4.2-4.4,4.3-9.1,1.3-12c-3.7-3.6-7.9-2.1-11.9,2c-1.8,1.9-2.7,3.1-3.2,3.9L43.7,52.9z M59,67.5c0.9-0.7,2.1-1.8,3.5-3.3 c4.1-4.3,6.3-9.7,1.6-14.1c-4.4-4.2-9.7-2.1-14,2.4l-3.3,3.4L59,67.5z" />
        <path d="M51.2,18.9c2-2.9,4.6-6.1,7.7-9.4c5.6-5.9,11-8.8,16.3-9.1C80.5,0,85.8,1.9,90.9,6.8c5.2,5,7.9,10.6,8,16.4 c0.1,5.8-3,12.3-8.9,18.5c-2.8,2.9-5.3,5.3-7.5,7.1L51.2,18.9z M83.3,41.7c1.2-0.9,2.7-2.3,4.2-3.9c8.4-8.8,8.1-18.3-0.6-26.5 C79.5,4,70.6,3.7,62.3,12.4c-2,2.1-3.4,3.9-4.2,5.2L83.3,41.7z" />
    </Ic>
);

ICXXTBD.displayName = 'ICXXTBD';

export default ICXXTBD;
