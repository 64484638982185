import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {white, slate} from '../../../../common/palette';

import BA08BtnSec from '../../../atoms/ba/08-btn-sec';

import BA01BtnPr from '../../../atoms/ba/01-btn-pr';
import IC103Loading from '../../../atoms/ic/103-loading';
import TM24AlertTile from '../../../molecules/tm/24-alert-tile';
import {fadeInOut} from '../../../../common/animations';
import TM27InfoTile from '../../../molecules/tm/27-info-tile';
import {IC61Ios} from '../../../atoms/ic';

const ConfirmChangeContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    text-align: center;
    color: ${white};
`;

const Content = styled.section`
    padding: 0 32px;
`;

const ProductInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: auto;
    text-align: center;
`;

const Byline = styled.p`
    text-align: center;
    color: ${white};
    font: var(--mui-body-copy-3);
`;

const Header = styled.h2`
    font: var(--mui-header-6-bold);
`;
const SubHeader = styled.h3`
    font: var(--mui-header-7-bold);
`;

const InfoText = styled.div`
    margin: 21px 0;
    color: ${slate};
    font: var(--mui-body-copy-3);
`;

const CenterText = styled.p`
    padding: 10px;
    font: var(--mui-body-copy-4-bold);
`;

const ConfirmText = styled.p`
    margin: 21px auto;
    font: var(--mui-header-7-bold);
`;

const Buttons = styled.div`
    display: grid;
    grid-gap: 7px;
    margin: 0 auto;
    max-width: 210px;
`;

const LOADING_ICON_SIZE = '47px';

const LoadingBox = styled.div`
    padding-top: 21px;
    min-height: ${LOADING_ICON_SIZE};
`;

const LoadingWrapper = styled.div`
    text-align: center;
    ${fadeInOut}
`;

const ConfirmChangeSubscriptionTemplate = ({
    cancelButtonText = 'Cancel',
    chargesInfoText = [],
    confirmText = 'Please confirm your change',
    currentProduct,
    errorMsg,
    headingText = 'You are updating your subscription',
    isItunesSubscription,
    isSubmitting,
    itunesSubscriptionMessage,
    itunesSubscriptionTitle,
    subHeadingText,
    submitButtonText = 'I agree',
    targetProduct,
    updatePlanInfoText,

    onCancel,
    onSubmit,
}) => {
    if (isItunesSubscription) {
        return (
            <TM27InfoTile
                icon={<IC61Ios size="43px" />}
                title={itunesSubscriptionTitle}
            >
                {itunesSubscriptionMessage}
            </TM27InfoTile>
        );
    }

    return (
        <ConfirmChangeContainer>
            <Header>
                {headingText}
            </Header>
            {!!subHeadingText && (
                <SubHeader>
                    {subHeadingText}
                </SubHeader>
            )}
            <Content>
                {!!updatePlanInfoText && (
                    <Byline>
                        {updatePlanInfoText}
                    </Byline>
                )}
                <ProductInfo>
                    {currentProduct}
                    <CenterText>to</CenterText>
                    {targetProduct}
                </ProductInfo>
                <InfoText>
                    {chargesInfoText.map((updateParagraph, index) => <p key={index}>{updateParagraph}</p>)}
                </InfoText>
            </Content>
            <section>
                <ConfirmText>
                    {confirmText}
                </ConfirmText>
                <Buttons>
                    <BA01BtnPr
                        type="button"
                        isBlock={true}
                        disabled={isSubmitting}
                        onClick={onSubmit}
                    >
                        {submitButtonText}
                    </BA01BtnPr>
                    <BA08BtnSec
                        type="button"
                        isBlock={true}
                        disabled={isSubmitting}
                        onClick={onCancel}
                    >
                        {cancelButtonText}
                    </BA08BtnSec>
                </Buttons>

                <LoadingBox>
                    {!!isSubmitting && (
                        <LoadingWrapper>
                            <IC103Loading size={LOADING_ICON_SIZE} />
                        </LoadingWrapper>
                    )}

                    {!!(errorMsg && !isSubmitting) && (
                        <TM24AlertTile>
                            {errorMsg}
                        </TM24AlertTile>
                    )}
                </LoadingBox>
            </section>
        </ConfirmChangeContainer>
    );
};

ConfirmChangeSubscriptionTemplate.displayName = 'ConfirmChangeSubscriptionTemplate';

ConfirmChangeSubscriptionTemplate.propTypes = {
    /** Header to be displayed to the user */
    headingText: propTypes.string,
    /** Sub header to be displayed to the user */
    subHeadingText: propTypes.string,
    /** Update plan information to be displayed to the user */
    updatePlanInfoText: propTypes.string,
    /** Plan charges information to be displayed to the user */
    chargesInfoText: propTypes.arrayOf(propTypes.node),
    /** Confirm Message to be displayed to the user */
    confirmText: propTypes.string,
    /** If set, an error message to be displayed to the user */
    errorMsg: propTypes.string,
    /** Whether or not the update has been submitted and is awaiting a response */
    isSubmitting: propTypes.bool,
    /** Submit button text */
    submitButtonText: propTypes.string,
    /** Cancel button text */
    cancelButtonText: propTypes.string,
    /** A callback to handle submission */
    onSubmit: propTypes.func,
    /** A callback to handle going back */
    onCancel: propTypes.func,
    /** Currently subscribed product */
    currentProduct: propTypes.node.isRequired,
    /** Target product to change to */
    targetProduct: propTypes.node.isRequired,
    /** If set shows itunes message */
    isItunesSubscription: propTypes.bool,
    /** Title for the itunes message */
    itunesSubscriptionTitle: propTypes.node,
    /** Content of the itunes message */
    itunesSubscriptionMessage: propTypes.node,
};

export default ConfirmChangeSubscriptionTemplate;
