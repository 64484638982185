import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import styled from 'styled-components';
import property from 'lodash/property';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {fog} from '../../../../common/palette';
import {
    SCREEN_320_MOBILE,
    SCREEN_768_TABLET,
    SCREEN_1280_DESKTOP,
    SCREEN_1920_DESKTOP,
} from '../../../../common/screen-sizes';
import {Section} from '../../../../common/normalized-styled-components';

const TILE_IMAGE_WIDTH_PX_MAP = {
    [SCREEN_320_MOBILE]: 132,
    [SCREEN_768_TABLET]: 163,
    [SCREEN_1280_DESKTOP]: 163,
    [SCREEN_1920_DESKTOP]: 273,
};

const StyledSection = styled(Section)`
    width: 100%;
`;

const SettingsHeader = styled.header`
    margin: 0 45px 10px;
    text-align: ${property('alignment')};
    color: ${fog};

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        margin: 0 10px 10px;
    `}
`;

const SettingsTitle = styled.p`
    font: var(--infinity-header-5-bold);
`;

const SettingsDescription = styled.p`
    font: var(--infinity-header-4);
    font-weight: normal;
`;

const SettingsList = styled.ul`
    --infinity-onboarding-tile-size: ${TILE_IMAGE_WIDTH_PX_MAP[SCREEN_320_MOBILE]}px;
    --infinity-onboarding-tile-count: auto-fill;
    display: grid;
    grid-template-columns: repeat(var(--infinity-onboarding-tile-count), minmax(var(--infinity-onboarding-tile-size), auto));
    grid-gap: 14px;
    justify-content: center;
    margin: 0 10px;
    padding: 0;
    min-height: 59px;
    list-style: none;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        --infinity-onboarding-tile-size: ${TILE_IMAGE_WIDTH_PX_MAP[SCREEN_768_TABLET]}px;
        --infinity-onboarding-tile-count: 3;
        justify-content: ${property('alignment')};
        min-height: 72px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        --infinity-onboarding-tile-size: ${TILE_IMAGE_WIDTH_PX_MAP[SCREEN_1280_DESKTOP]}px;
        --infinity-onboarding-tile-count: 5;
        min-height: 121px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        --infinity-onboarding-tile-size: ${TILE_IMAGE_WIDTH_PX_MAP[SCREEN_1920_DESKTOP]}px;
        --infinity-onboarding-tile-count: 6;
    `}
`;

const OR44SetSelec = ({
    children,
    description,
    tileAlignment = 'center',
    title,
    titleAlignment = 'center',
    className,
    ...htmlAttributes
}) => (
    <StyledSection
        className={classnames('OR44SetSelec', className)}
        {...htmlAttributes}
    >
        <SettingsHeader alignment={titleAlignment}>
            {!!title && (
                <SettingsTitle>{title}</SettingsTitle>
            )}
            {!!description && (
                <SettingsDescription>{description}</SettingsDescription>
            )}
        </SettingsHeader>

        {!!React.Children.count(children) && (
            <SettingsList
                alignment={tileAlignment}
            >
                {React.Children.map(children, (child) => (
                    <li>{child}</li>
                ))}
            </SettingsList>
        )}
    </StyledSection>
);

OR44SetSelec.propTypes = {
    /** Various settings tile to load, like Show tiles (TR02-SetTil) */
    children: propTypes.node,
    /** additional CSS classnames to be applied */
    className: classNameType,
    /** Description for the settings */
    description: propTypes.string,
    /** Alignment for the child i.e. start, center, or end  */
    tileAlignment: propTypes.oneOf(['start', 'center', 'end']),
    /** Title for the settings */
    title: propTypes.string,
    /** Alignment for the title i.e. left, center, or right  */
    titleAlignment: propTypes.oneOf(['left', 'center', 'right']),
};

OR44SetSelec.displayName = 'OR44SetSelec';

export default OR44SetSelec;
