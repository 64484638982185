{
    "data": {
        "catalogModuleList": {
            "items": [
                {
                    "pg": "default",
                    "meta": {
                        "pageTitle": "Watch Sport Live & On-Demand | Kayo Sports",
                        "language": "en",
                        "thumbnail": {},
                        "canonicalLink": "https://kayosports.com.au/",
                        "metaDescription": "Stream over 50 sports live & on-demand on Kayo including AFL, NRL, Cricket, F1, Supercars, NBA, NFL and more. New to Kayo? Start your free trial today.",
                        "ogDescription": "Stream over 50 sports live & on-demand on Kayo including AFL, NRL, Cricket, F1, Supercars, NBA, NFL and more. New to Kayo? Start your free trial today.",
                        "ogImage": "https://resources.kayosports.com.au/production/marketing/images/op/kayo-ogimage.gif",
                        "ogLocale": "en_GB",
                        "ogSiteName": "Kayo Sports",
                        "ogTitle": "Watch Sport Live & On-Demand | Kayo Sports",
                        "ogUrl": null,
                        "ogType": "product",
                        "twitterSite": "@kayosports",
                        "twitterCard": null,
                        "twitterImage": "https://resources.kayosports.com.au/production/marketing/images/op/kayo-ogimage.gif",
                        "twitterTitle": "Watch Sport Live & On-Demand | Kayo Sports",
                        "twitterDescription": "Stream over 50 sports live & on-demand on Kayo including AFL, NRL, Cricket, F1, Supercars, NBA, NFL and more. New to Kayo? Start your free trial today."
                    },
                    "modules": [
                        {
                            "id": "kayo-landing-hero-banner-block",
                            "sectionTitle": "Watch Sport Live and On-Demand",
                            "type": "hero-banner",
                            "cta": "Restart your subscription",
                            "copy": null,
                            "copylines": null,
                            "url": {
                                "_publishUrl": "https://content.streamotion.com.au/content/experience-fragments/kayo/site/homepage/default_575816590/default/hero-banner-video.html"
                            }
                        },
                        {
                            "type": "promo-modal-cta",
                            "sectionTitle": null,
                            "copylines": null,
                            "ctaPrimary": "Had Kayo, Binge, or Flash before?",
                            "ctaSecondary": "New Customer"
                        },
                        {
                            "id": "kayo-landing-placeholder-block-1",
                            "sectionTitle": null,
                            "type": "aem",
                            "cta": null,
                            "copy": null,
                            "copylines": null,
                            "url": {
                                "_publishUrl": "https://content.streamotion.com.au/content/experience-fragments/kayo/site/homepage/default_575816590/placeholder-fragment/master.html"
                            }
                        },
                        {
                            "id": "kayo-landing-default-package-selector-default",
                            "sectionTitle": "Select a subscription below",
                            "type": "package-selector",
                            "cta": null,
                            "copy": null,
                            "copylines": [
                                "Over 50 sports live & on-demand​",
                                "No lock-in contract, cancel anytime​",
                                "Watch NRL, AFL, Formula 1, Cricket and Netball content live in 4K on Kayo Basic*​",
                                "Stream on TV via compatible smart TVs, Apple TV, Google TV, Telstra TV, PlayStation, Chromecast and Fire TV ​",
                                "Stream on the go with compatible mobile, tablet and laptop devices​"
                            ],
                            "url": null
                        },
                        {
                            "id": "kayo-landing-placeholder-block-2",
                            "sectionTitle": null,
                            "type": "aem",
                            "cta": null,
                            "copy": null,
                            "copylines": null,
                            "url": {
                                "_publishUrl": "https://content.streamotion.com.au/content/experience-fragments/kayo/site/homepage/default_575816590/placeholder-fragment/master.html"
                            }
                        },
                        {
                            "id": "kayo-landing-marketing-block",
                            "sectionTitle": null,
                            "type": "aem",
                            "cta": null,
                            "copy": null,
                            "copylines": null,
                            "url": {
                                "_publishUrl": "https://content.streamotion.com.au/content/experience-fragments/kayo/site/homepage/default_575816590/placeholder-fragment/master.html"
                            }
                        },
                        {
                            "id": "kayo-landing-placeholder-block-3",
                            "sectionTitle": null,
                            "type": "aem",
                            "cta": null,
                            "copy": null,
                            "copylines": null,
                            "url": {
                                "_publishUrl": "https://content.streamotion.com.au/content/experience-fragments/kayo/site/homepage/default_575816590/placeholder-fragment/master.html"
                            }
                        },
                        {
                            "id": "kayo-landing-feature-icon-text-block",
                            "sectionTitle": null,
                            "type": "aem",
                            "cta": null,
                            "copy": null,
                            "copylines": null,
                            "url": {
                                "_publishUrl": "https://content.streamotion.com.au/content/experience-fragments/kayo/site/homepage/default_575816590/features-icon-text-block/master.html"
                            }
                        },
                        {
                            "id": "kayo-landing-placeholder-block-4",
                            "sectionTitle": null,
                            "type": "aem",
                            "cta": null,
                            "copy": null,
                            "copylines": null,
                            "url": {
                                "_publishUrl": "https://content.streamotion.com.au/content/experience-fragments/kayo/site/homepage/default_575816590/placeholder-fragment/master.html"
                            }
                        },
                        {
                            "id": "kayo-landing-device-icon-text-block",
                            "sectionTitle": null,
                            "type": "aem",
                            "cta": null,
                            "copy": null,
                            "copylines": null,
                            "url": {
                                "_publishUrl": "https://content.streamotion.com.au/content/experience-fragments/kayo/site/homepage/default_575816590/device-icon-text-block/master.html"
                            }
                        },
                        {
                            "id": "kayo-landing-placeholder-block-5",
                            "sectionTitle": null,
                            "type": "aem",
                            "cta": null,
                            "copy": null,
                            "copylines": null,
                            "url": {
                                "_publishUrl": "https://content.streamotion.com.au/content/experience-fragments/kayo/site/homepage/default_575816590/placeholder-fragment/master.html"
                            }
                        },
                        {
                            "id": "kayo-landing-sports-selector-block-default",
                            "sectionTitle": null,
                            "type": "aem",
                            "cta": null,
                            "copy": null,
                            "copylines": null,
                            "url": {
                                "_publishUrl": "https://content.streamotion.com.au/content/experience-fragments/kayo/site/homepage/default_575816590/sports-selector/master.html"
                            }
                        },
                        {
                            "id": "kayo-landing-placeholder-block-6",
                            "sectionTitle": null,
                            "type": "aem",
                            "cta": null,
                            "copy": null,
                            "copylines": null,
                            "url": {
                                "_publishUrl": "https://content.streamotion.com.au/content/experience-fragments/kayo/site/homepage/default_575816590/placeholder-fragment/master.html"
                            }
                        },
                        {
                            "id": "kayo-landing-faq-block",
                            "sectionTitle": null,
                            "type": "aem",
                            "cta": null,
                            "copy": null,
                            "copylines": null,
                            "url": {
                                "_publishUrl": "https://content.streamotion.com.au/content/experience-fragments/kayo/site/homepage/default_575816590/faq/master.html"
                            }
                        },
                        {
                            "id": "kayo-landing-placeholder-block-7",
                            "sectionTitle": null,
                            "type": "aem",
                            "cta": null,
                            "copy": null,
                            "copylines": null,
                            "url": {
                                "_publishUrl": "https://content.streamotion.com.au/content/experience-fragments/kayo/site/homepage/default_575816590/placeholder-fragment/master.html"
                            }
                        },
                        {
                            "id": "qantas-frequent-flyer-link",
                            "sectionTitle": null,
                            "type": "aem",
                            "cta": null,
                            "copy": null,
                            "copylines": null,
                            "url": {
                                "_publishUrl": "https://content.streamotion.com.au/content/experience-fragments/kayo/site/homepage/default_575816590/qantas-frequent-flyer-block/master.html"
                            }
                        },
                        {
                            "id": "kayo-landing-placeholder-block-8",
                            "sectionTitle": null,
                            "type": "aem",
                            "cta": null,
                            "copy": null,
                            "copylines": null,
                            "url": {
                                "_publishUrl": "https://content.streamotion.com.au/content/experience-fragments/kayo/site/homepage/default_575816590/placeholder-fragment/master.html"
                            }
                        },
                        {
                            "id": "kayo-landing-footer-block",
                            "sectionTitle": null,
                            "type": "aem",
                            "cta": null,
                            "copy": null,
                            "copylines": null,
                            "url": {
                                "_publishUrl": "https://content.streamotion.com.au/content/experience-fragments/kayo/site/homepage/default_575816590/footer/master.html"
                            }
                        }
                    ]
                }
            ]
        }
    }
}