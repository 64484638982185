import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import {formatSecondsIntoStandardLabel} from '../../../../../common/format-time';
import {white} from '../../../../../common/palette';
import {black} from '../../../../../common/deprecated-mui-palette';

const borderRadiusPx = 3;
const horizontalPaddingPx = 10;
const verticalPaddingPx = 5;
const widthPx = 60;
const stemSizePx = 6;
const color = white;
const backgroundColor = rgba(black, 0.3);

const StyledToolTipContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: ${widthPx}px;
`;

const StyledTooltip = styled.output`
    transform-origin: bottom right;
    transition: transform 0.2s 0.1s ease, opacity 0.2s 0.1s ease;
    border-radius: ${borderRadiusPx}px;
    background: ${backgroundColor};
    padding: ${verticalPaddingPx}px ${horizontalPaddingPx}px;
    width: ${widthPx}px;
    text-align: center;
    color: ${color};
    font: var(--nucleus-body-copy-5-bold);
    pointer-events: none;
`;

const StyledTooltipStem = styled.div`
    box-sizing: border-box;
    border-top: ${stemSizePx}px solid ${backgroundColor};
    border-right: ${stemSizePx}px solid transparent;
    border-left: ${stemSizePx}px solid transparent;
    width: ${stemSizePx}px;
    height: ${stemSizePx}px;
    content: '';
`;

const VPGA02TimeTooltip = ({
    seekingTime = 0,
}) => (
    <StyledToolTipContainer>
        <StyledTooltip>
            {formatSecondsIntoStandardLabel(seekingTime)}
        </StyledTooltip>
        <StyledTooltipStem />
    </StyledToolTipContainer>
);

VPGA02TimeTooltip.propTypes = {
    /** Seeking time */
    seekingTime: propTypes.number,
};

VPGA02TimeTooltip.displayName = 'VPGA02TimeTooltip';

export default VPGA02TimeTooltip;
