export const RESPONSE_CODE = { // eslint-disable-line import/prefer-default-export
    ok:               200,
    noContent:        204,
    movedTemporarily: 302,
    badRequest:       400,
    notFound:         404,
    gone:             410,
    failedDependency: 424,
};

/**
 * @typedef OnboardingStatuses
 * @type {Object} Onboarding statuses
 * @property {string} firstTimeScreen first root profile, on a brand new account, not yet saved
 * @property {string} welcomeScreen new secondary profile, not yet saved
 * @property {string} onboardingIncomplete profile saved, onboarding not yet complete
 */

/** @type {OnboardingStatuses} */
export const ONBOARDING_STATUS = {
    firstTimeScreen: 'firstTimeScreen',
    welcomeScreen: 'welcomeScreen',
    onboardingIncomplete: 'onboardingIncomplete',
};

/**
 * Sequence of onboarding statuses.
 * Status should only ever progress, never regress, so the order is important
 *
 * @type {string[]}
 */
export const ONBOARDING_STATUS_SEQUENCE = [
    ONBOARDING_STATUS.firstTimeScreen,
    ONBOARDING_STATUS.welcomeScreen,
    ONBOARDING_STATUS.onboardingIncomplete,
];

/**
 * Local storage key prefixes
 */
export const STORAGE_KEY_PREFIX = {
    binge: {
        marketingPg: 'ares-marketing',
        offerName: 'ares-offer-name',
        voucher: 'ares-voucher',
    },
    flash: {
        marketingPg: 'flash-marketing',
        offerName: 'flash-offer-name',
        voucher: 'flash-voucher',
    },
    kayo: {
        checkoutConfirmationAnalyticsPayload: 'martian-checkout-confirmation',
        marketingPg: 'martian-marketing',
        offerName: 'martian-offer-name',
        shouldPreventFreebiesRedirection: 'martian-should-prevent-freebies-redirection',
        voucher: 'martian-voucher',
    },
    lifestyle: {
        marketingPg: 'lifestyle-marketing',
        offerName: 'lifestyle-offer-name',
        voucher: 'lifestyle-voucher',
    },
};

export const NEW_SIGNUP = 'New';
export const PARTIAL_SIGNUP = 'Abandoned';
export const RETURNING_SIGNUP = 'Return';

export const BILLING_PRODUCT_NAME_MAP = {
    kayo: 'kayo',
    binge: 'ares',
    flash: 'flash',
    lifestyle: 'lifestyle',
};

// Active profile storage key needs to be in sync with the respective widgets repo
export const ACTIVE_PROFILE_ID_STORAGE_KEY = {
    development: {
        binge: 'aresProfile-staging-activeProfileId',
        flash: 'flashProfile-staging-activeProfileId',
        kayo: 'martianProfile-staging-activeProfileId',
        lifestyle: 'lifestyleProfile-staging-activeProfileId', // TODO: change later when key prefix is changed in life widgets, currently it is still `aresProfile`
    },
    staging: {
        binge: 'aresProfile-staging-activeProfileId',
        flash: 'flashProfile-staging-activeProfileId',
        kayo: 'martianProfile-staging-activeProfileId',
        lifestyle: 'aresProfile-staging-activeProfileId', // TODO: change later when key prefix is changed in life widgets, currently it is still `aresProfile`
    },
    production: {
        binge: 'aresProfile-production-activeProfileId',
        flash: 'flashProfile-production-activeProfileId',
        kayo: 'martianProfile-production-activeProfileId',
        lifestyle: 'lifestyleProfile-production-activeProfileId', // TODO: change later when key prefix is changed in life widgets, currently it is still `aresProfile`
    },
};

// storage key for vimondSubProfileToken
export const VIMOND_SUB_PROFILE_TOKEN_KEY = {
    development: {
        binge: 'aresProfile-staging-vimondSubProfileToken',
        flash: 'flashProfile-staging-vimondSubProfileToken',
        kayo: 'martianProfile-staging-vimondSubProfileToken',
        lifestyle: 'lifestyleProfile-staging-vimondSubProfileToken', // TODO: change later when key prefix is changed in life widgets, currently it is still `aresProfile`
    },
    staging: {
        binge: 'aresProfile-staging-vimondSubProfileToken',
        flash: 'flashProfile-staging-vimondSubProfileToken',
        kayo: 'martianProfile-staging-vimondSubProfileToken',
        lifestyle: 'lifestyleProfile-staging-vimondSubProfileToken', // TODO: change later when key prefix is changed in life widgets, currently it is still `aresProfile`
    },
    production: {
        binge: 'aresProfile-production-vimondSubProfileToken',
        flash: 'flashProfile-production-vimondSubProfileToken',
        kayo: 'martianProfile-production-vimondSubProfileToken',
        lifestyle: 'lifestyleProfile-production-vimondSubProfileToken', // TODO: change later when key prefix is changed in life widgets, currently it is still `aresProfile`
    },
};

export const ADOBE_ANALYTICS_DEFINITIONS_PATH = {
    binge: 'analytics/adobe-analytics-definitions.json',
    flash: 'analytics/adobe-analytics-definitions.json',
    kayo: 'analytics/adobe-analytics-definitions--web.json',
    lifestyle: 'analytics/adobe-analytics-definitions.json',
};

// These values are set by the respective web-server, any update here should also be reflected in web-server and vice-versa
export const MARKETING_STORAGE_KEYS = {
    kayo: {
        channel: 'martian-channel',
        extcamp: 'martian-extcamp',
        referralId: 'martian-referralid',
        campaign: 'martian-campaign',
    },
    binge: {
        channel: 'ares-channel',
        extcamp: 'ares-extcamp',
        referralId: 'ares-referralid',
        campaign: 'ares-campaign',
    },
    flash: {
        channel: 'flash-channel',
        extcamp: 'flash-extcamp',
        referralId: 'flash-referralid',
        campaign: 'flash-campaign',
    },
    lifestyle: {
        channel: 'lifestyle-channel',
        extcamp: 'lifestyle-extcamp',
        referralId: 'lifestyle-referralid',
        campaign: 'lifestyle-campaign',
    },
};

export const BRAND_DISPLAY_NAME = {
    kayo: 'Kayo Sports',
    binge: 'Binge',
    flash: 'Flash News',
    lifestyle: 'Lifestyle',
};

export const DEFAULT_BRAND_DISPLAY_NAME = 'Streamotion';

export const BACKGROUND_REL_SRCS = {
    binge: {
        relBgSrc: 'ares.png',
        relBgSrcPortrait: 'ares-portrait.png',
    },
    kayo: {
        relBgSrc: 'kayo.png',
        relBgSrcPortrait: 'kayo-portrait.png',
    },
    flash: {
        relBgSrc: 'flash.png',
        relBgSrcPortrait: 'flash-portrait.png',
    },
    lifestyle: {
        relBgSrc: 'lifestyle.png',
        relBgSrcPortrait: 'lifestyle-portrait.png',
    },
};

export const ACTIVE_SUBSCRIPTION_COPY = {
    userGreeting: 'Active subscription',
    errorMessage: 'This offer cannot be applied as you already have an active subscription. You will be redirected to the content.',
};
