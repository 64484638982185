import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {A, Button} from 'normalized-styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';

import {buttonType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {akela, blanc} from '../../../../common/palette';
import GA43ArrowInd from '../../ga/43-arrow-ind';

const SubLabelWrapper = styled.span`
    flex: 0 0 auto;
    transition: ${transition('color')};
    padding-right: 14px;
    color: ${rgba(blanc, 0.7)};
`;

const ArrowWrapper = styled.span`
    transition: ${transition('color')};
    margin-bottom: -2px;
    color: ${rgba(blanc, 0.7)};
`;

const baseStyle = css`
    box-sizing: border-box;
    display: flex;
    position: relative;
    align-items: center;
    transition: ${transition('color')};
    padding: 5px 35px;
    width: 100%;
    min-height: 42px;
    color: ${rgba(akela, 0.7)};
    font: var(--ionic-body-copy-3-light);

    &[aria-pressed='true'],
    &[aria-current='true'] {
        color: ${akela};

        &::before {
            content: '';
        }

        /* stylelint-disable-next-line declaration-block-semicolon-newline-after, rule-empty-line-before,order/order */
        ${SubLabelWrapper},
        ${ArrowWrapper} {
            color: ${blanc};
        }
    }

    &:focus,
    &:hover {
        outline: 0;
        color: ${akela};

        /* stylelint-disable-next-line declaration-block-semicolon-newline-after,rule-empty-line-before */
        ${SubLabelWrapper},
        ${ArrowWrapper} {
            color: ${blanc};
        }
    }

    &::before {
        position: absolute;
        top: 4px;
        bottom: 4px;
        left: 21px;
        background-color: ${blanc};
        width: 2px;
    }

    &:active::before {
        content: '';
    }
`;

const StyledButton = styled(Button).attrs(({isPressed}) => ({
    'aria-pressed': isPressed,
}))`
    ${baseStyle}

    appearance: none;
    border: 0;
    background: none;
    text-align: left;
`;

const StyledLink = styled(A).attrs(({isPressed}) => ({
    'aria-current': isPressed,
}))`
    ${baseStyle}

    text-decoration: none;
`;

const LabelWrapper = styled.span`
    flex: 1 1 auto;
    padding: 0 7px;
    overflow-x: hidden;
    white-space: nowrap;
    /* stylelint-disable-next-line color-no-hex */
    mask-image: linear-gradient(to left, transparent 7px, #000 35px);
`;

const BA42NavBtnSec = React.forwardRef(({
    className,
    href,
    isPressed,
    label,
    subLabel,
    type = href ? undefined : 'button',

    onClick,

    ...htmlAttributes
}, ref) => {
    const Cta = href ? StyledLink : StyledButton;

    return (
        <Cta
            {...htmlAttributes}
            className={classnames('BA42NavBtnSec', className)}
            href={href}
            isPressed={isPressed}
            onClick={onClick}
            ref={ref}
            type={type}
        >
            <LabelWrapper>{label}</LabelWrapper>
            {!!subLabel && <SubLabelWrapper>{subLabel}</SubLabelWrapper>}
            <ArrowWrapper><GA43ArrowInd /></ArrowWrapper>
        </Cta>
    );
});

BA42NavBtnSec.propTypes = {
    /** additional CSS classnames to be applied */
    className: classNameType,
    /** hyperlink URL */
    href: propTypes.string,
    /** aria-pressed attribute describes a toggle button */
    isPressed: propTypes.oneOf([true, false, 'mixed']),
    /** label of the button */
    label: propTypes.string.isRequired,
    /** secondary text in between label and arrow */
    subLabel: propTypes.string,
    /** callback to fire on button click */
    onClick: propTypes.func,
    /** react ref, e.g. as passed in by React.forwardRef */
    ref: propTypes.string,
    /** html button type */
    type: buttonType,
};

BA42NavBtnSec.displayName = 'BA42NavBtnSec';

export default BA42NavBtnSec;
