import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {A} from 'normalized-styled-components';

import {kayoGreen, white} from '../../../../common/palette';
import {transition} from '../../../../common/animations';

const BLUE_RING_COLOR_FTW = '#619dd7';

const StyledListItem = styled.li`
    box-sizing: border-box;
    position: relative;
    height: 39px;
    list-style: none;
    text-align: center;
    line-height: 39px;

    &::after {
        position: absolute;
        right: 0;
        bottom: -5px;
        left: 0;
        transition: ${transition('background-color')};
        border-radius: 2px;
        background-color: ${({isSelected}) => isSelected ? kayoGreen : 'transparent'};
        height: 5px;
        content: '';
    }
`;

const StyledLink = styled(A)`
    display: block;
    padding: 0 14px;
    height: 100%;
    text-decoration: none;
    color: ${white};
    font: var(--mui-body-copy-3-bold);

    &:focus {
        outline: 1px solid ${BLUE_RING_COLOR_FTW};
    }
`;

const TB01NavT = ({
    children,
    isSelected = false,
    url,
    ...htmlAttributes
}) => (
    <StyledListItem isSelected={isSelected}>
        <StyledLink href={url} {...htmlAttributes}>
            {children}
        </StyledLink>
    </StyledListItem>
);

TB01NavT.displayName = 'TB01NavT';

TB01NavT.propTypes = {
    /** Content of button, e.g. text */
    children: propTypes.node.isRequired,
    /** Url for the nav tab to point to */
    url: propTypes.string.isRequired,
    /** Whether or not this nav Tab is selected */
    isSelected: propTypes.bool,
};

export default TB01NavT;
