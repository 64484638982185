import bacon from 'baconjs';
import result from 'lodash/result';

/**
 * Given a hydration payload and a stream
 *     - if payload is not undefined, return that
 *     - else return the stream
 *
 * Formerly known as initialLaterOr
 *
 * @param  {Mixed}      hydrate Value which will be returned in a stream if not undefined
 * @param  {Observable} stream$ If hydrate was undefined, this stream will be returned
 *
 * @returns {Observable} stream containing either the hydration value or the provided stream
 */
export function hydrateStream(hydrate, stream$) {
    if (hydrate === undefined) {
        return stream$;
    }

    return bacon.later(0, hydrate);
}

/**
 * HYDRATE - CONCAT - UNTIL.
 *
 *  Hydrate with a value (if you have it), move to the stream$, check if we should keep this stream or end it.
 *
 * Concat a stream using an initial payload, after an optional delay and optionally end stream when callback returns true
 *
 * Formerly known as pollUntil.
 * Differs slightly in
 *     - specifically checks for undefined vs falsey
 *     - returns an event stream instead of property
 *     - args renamed to be more descriptive
 *
 * @param  {Mixed}      hydrate                   Initial stream value. Note: undefined will cause hydration step to be skipped
 * @param  {Number}     concatAfterMs             How long to hold hydrate value until appending the stream using bacon concat
 * @param  {Observable} stream$                   Stream that should be appended after initial value
 * @param  {Function}   shouldStreamStopCallback  A callback which will be applied to each stream value - once it returns true, the stream will end
 *
 * @returns {Observable} A bacon event stream containing an initial value, then after a delay appending a stream until told to end
 */
export function hcuStream({
    hydrate,
    concatAfterMs = 30 * 1000,
    shouldStreamStopCallback = () => false,
    stream$,
}) {
    if (concatAfterMs > 24 * 60 * 60 * 1000) {
        console.error('hydrateStream: Large delays not supported! Callbacks just happen immediately!');
    }

    return bacon
        .later(0, hydrate) // Using later, as once still only seen by first subscriber
        .flatMapLatest((initialStreamData) => {
            if (initialStreamData === undefined) {
                return stream$;
            }

            return bacon
                .later(concatAfterMs, null)
                .skip(1)
                .concat(stream$)
                .startWith(initialStreamData);
        })
        // TODO refactor the below so that the eslint-disable can be removed
        /* eslint-disable-next-line func-names */
        .withHandler(function (event) { // apply our shouldStreamStopCallback
            let baconReturn = this.push(event); // eslint-disable-line no-invalid-this

            if (result(event, 'hasValue') && shouldStreamStopCallback(result(event, 'value'))) {
                baconReturn = this.push(new bacon.End()); // eslint-disable-line no-invalid-this
            }

            return baconReturn;
        });
}
