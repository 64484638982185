import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

const Score = styled.abbr`
    text-decoration: none;
`;

const FootballScore = ({
    isShootoutScoreVisible,
    score,
    shootoutScore,
}) => {
    const scoreDescription = [[score, 'Score'], [shootoutScore, 'Penalties']]
        .filter(([scoreFragment]) => (isShootoutScoreVisible || scoreFragment !== null))
        .map(([scoreFragment, label]) => {
            let parsedScoreFragment = scoreFragment;

            if (!!isShootoutScoreVisible && parsedScoreFragment === null) {
                parsedScoreFragment = 0;
            }

            return `${label}: ${parsedScoreFragment}`;
        })
        .join(', ');

    return (
        <Score
            className="BM01GnrBnr__score"
            aria-label={scoreDescription}
            title={scoreDescription}
        >
            {score}{!!isShootoutScoreVisible && ` (${shootoutScore || 0})`}
        </Score>
    );
};

FootballScore.displayName = 'FootballScore';

FootballScore.propTypes = {
    score: propTypes.number,
    shootoutScore: propTypes.number,
    isShootoutScoreVisible: propTypes.bool,
};

export default FootballScore;
