import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Button} from 'normalized-styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {blanc} from '../../../../common/palette';
import IC49Open from '../../ic/49-open';

const StyledButton = styled(Button).attrs({
    type: 'button',
})`
    appearance: none;
    position: relative;
    transition: ${transition('opacity')};
    opacity: 0.4;
    border: none;
    background-color: ${rgba(blanc, 0.2)};
    padding: 31px;
    width: 106px;

    &:hover,
    &:focus {
        opacity: 0.8;
    }

    &:active {
        opacity: 1;
    }

    &:focus {
        outline: 0;
    }
`;

const BA05AddBtn = React.forwardRef(({
    'aria-label': assistiveLabel = 'Add',
    className,
    ...htmlAttributes
}, ref) => (
    <StyledButton
        {...htmlAttributes}
        className={classnames('BA05AddBtn', className)}
        ref={ref}
        title={assistiveLabel}
        aria-label={assistiveLabel}
    >
        <IC49Open color={blanc} />
    </StyledButton>
));

/* eslint-disable quote-props */
BA05AddBtn.propTypes = {
    /** Assistive label required for screen readers due to graphical only representation of content */
    'aria-label': propTypes.string,
    /** additional CSS classnames to be applied */
    className: classNameType,
};
/* eslint-enable quote-props */

BA05AddBtn.displayName = 'BA05AddBtn';

export default BA05AddBtn;
