import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Button} from 'normalized-styled-components';
import {kayoGreen, silverLining} from '../../../../common/palette';
import {transition} from '../../../../common/animations';

const StyledButton = styled(Button)`
    appearance: none;
    position: relative;
    transition: ${transition('opacity')};
    border: none;
    background: transparent;
    padding: 14px;
    width: 77px;
    height: 77px;
    color: ${silverLining};

    &[aria-selected='false'] {
        opacity: 0.3;
    }

    &[aria-selected='true']::after {
        background-color: ${kayoGreen};
    }

    /* stylelint-disable-next-line order/order */
    &:hover,
    &:focus {
        opacity: 1;
        outline: 0;

        &[aria-selected='true']::after {
            background-color: ${silverLining};
        }
    }

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        transform: translateY(14px);
        transition: ${transition('background-color', 'height')};
        border-radius: 1px;
        background-color: transparent;
        width: 3px;
        height: 49px;
        content: '';
    }
`;

const IGM28HudBtn = React.forwardRef(({
    children,
    isSelected,
    ...htmlAttributes
}, ref) => (
    <StyledButton aria-selected={!!isSelected} {...htmlAttributes} ref={ref}>
        {children}
    </StyledButton>
));

IGM28HudBtn.displayName = 'IGM28HudBtn';

IGM28HudBtn.propTypes = {
    children: propTypes.node,
    isSelected: propTypes.bool,
};

export default IGM28HudBtn;
