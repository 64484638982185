import React from 'react';
import bacon from 'baconjs';

import {getResourcesUrlBasePath} from '@fsa-streamotion/streamotion-web-widgets-common';

import {BrandedOR128StreamotionBlk} from '../../../utils/branded-components';

import {BRAND_DISPLAY_NAME} from '../../../../../todo-move-to-widgets-common/utils/constants';

const iconMappings = {
    ares: {file: 'binge.svg', alt: 'Binge'},
    kayo: {file: 'kayo.svg', alt: 'Kayo'},
    flash: {file: 'flash.svg', alt: 'Flash'},
    watchAfl: {file: 'watch-afl.svg', alt: 'Watch AFL'},
    watchNrl: {file: 'watch-nrl.svg', alt: 'Watch NRL'},
};

function getMappedIcon(type, resourcesEnv, brand) {
    const {file, alt} = iconMappings[type];

    return {
        src: `${getResourcesUrlBasePath({resourcesEnv, brand})}/marketing/images/${file}`,
        alt,
    };
}

export default function StreamotionBlurb({
    hydration = {},
    commonWidgetSettings = {},
    key,
    icons = [],
    headerLogo,
    subTitle,
    copy,
    footerLink,
    footerText,
}) {
    const {brand, resourcesEnv} = commonWidgetSettings;
    const productLogos = icons.map((icon) => getMappedIcon(icon, resourcesEnv, brand));
    const paragraphs = copy.split('\n\n');

    const reactElement = (
        <BrandedOR128StreamotionBlk
            brand={brand}
            key={key}
            headerLogo={{src: headerLogo, alt: `${BRAND_DISPLAY_NAME[brand]} - a Hubbl company`}}
            productHeadingLogoUrl={`${getResourcesUrlBasePath({resourcesEnv, brand})}/marketing/images/streamotion-logo.svg`}
            productLogos={productLogos}
            heading={subTitle}
            paragraphs={paragraphs}
            footerLink={footerLink}
            footerText={footerText}
        />
    );

    return bacon.combineTemplate({
        reactElement,
        hydration,
    });
}
