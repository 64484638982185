import {colorType} from '@fsa-streamotion/custom-prop-types';
import React from 'react';

import Ic from '..';
import {ink} from '../../../../common/palette';

const IC246Edit = ({
    color = ink,
    ...args
}) => (
    <Ic {...args}>
        <defs xmlns="http://www.w3.org/2000/svg">
            <path id="s3yk45m4ga" d="M0 0h80v80H0z" />
        </defs>

        <g xmlns="http://www.w3.org/2000/svg" fill="none" fillRule="evenodd">
            <path d="M0 0h100v100H0z" />
            <g transform="translate(10.993 10.993)">
                <path
                    d="M43.134-4.366c1.312 0 2.5.532 3.359 1.391a4.735 4.735 0 0 1 1.39 3.359v70.812c0 .34-.098.671-.283.955L36.762 88.795a.748.748 0 0 1-1.037.22L24.667 72.15a1.75 1.75 0 0 1-.283-.955V.384c0-1.312.532-2.5 1.391-3.359a4.735 4.735 0 0 1 3.359-1.391z"
                    stroke={color} strokeWidth="4.5" transform="rotate(45 36.134 43.634)"
                />
                <g fill={color}>
                    <path d="M53.038 10.394 70.01 27.365l-2.828 2.828-16.97-16.97zM10.173 51.967l16.97 16.971-2.828 2.828-16.97-16.97z" />
                </g>
            </g>
        </g>
    </Ic>
);

IC246Edit.propTypes = {
    /** The icon's primary color */
    color: colorType,
};

IC246Edit.displayName = 'IC246Edit';

export default IC246Edit;
