import React from 'react';

import Ic from '..';

const IC239WatchTrailer = (args) => (
    <Ic {...args}>
        <path opacity=".2" d="M50.5 8C27.028 8 8 27.028 8 50.5S27.028 93 50.5 93 93 73.972 93 50.5C92.992 27.031 73.969 8.008 50.5 8z" />
        <path d="M44 35v32l21-16z" />
    </Ic>
);

IC239WatchTrailer.displayName = 'IC239WatchTrailer';

export default IC239WatchTrailer;
