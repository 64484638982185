import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import classnames from 'classnames';
import {A, H1} from 'normalized-styled-components';
import {rgba} from 'polished';

import {mediaQuery, stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType, srcsetOptionsType} from '@fsa-streamotion/custom-prop-types';

import {blanc, cloudy} from '../../../common/palette';
import {SCREEN_PHONE, SMALLEST_SUPPORTED_WIDTH, SCREEN_1280_DESKTOP, SCREEN_1920_DESKTOP} from '../../../common/screen-sizes';

import IC103Loading from '../../atoms/ic/103-loading';
import IC24ArrowL from '../../atoms/ic/24-arrow-l';
import GA05BgImg from '../../atoms/ga/05-bg-img';
import TM24AlertTile from '../../molecules/tm/24-alert-tile';

const spacerSetToQueries = ({sizeSet}) => {
    if (!sizeSet) {
        return null;
    }

    const sortedSet = Object.keys(sizeSet).sort();

    return sortedSet.map((key) => {
        const sizeValue = sizeSet[key];
        const isDefault = parseFloat(key) === SCREEN_PHONE
            || parseFloat(key) === SMALLEST_SUPPORTED_WIDTH;
        const isSizeZero = new RegExp(/^[0]+[a-z%]*$/).test(sizeValue);

        if (isDefault && isSizeZero) {
            // if the height is 0 for the minimum width, don’t create a useless CSS property
            return null;
        } else if (isDefault) {
            // otherwise, create a default CSS property
            return css`
                margin-bottom: ${sizeValue};
            `;
        }

        // for everything else, create CSS properties inside media queries
        return mediaQuery({minWidthPx: key})`
            margin-bottom: ${sizeValue};
        `;
    });
};

// Usage
// <VerticalSpacer sizeSet={{320: '5%', 415: '14px', 768: '2em'}} />
// <VerticalSpacer sizeFixed="14px" />

export const VerticalSpacer = styled.div.attrs(({sizeFixed, sizeSet}) => !sizeSet && ({
    style: {
        marginBottom: sizeFixed,
    },
}))`
    display: block;
    content: '';

    ${spacerSetToQueries}
`;

const MyAccountCtasLabel = styled.p`
    margin: 0 0 21px;
    text-align: center;
    font: var(--nucleus-header-8);
`;

const ButtonWrapper = styled.table`
    margin: 0 auto;
    min-width: 250px;
    border-spacing: 14px;
`;

export const MyAccountCtas = ({
    label,
    children,
}) => (
    <React.Fragment>
        {!!label && <MyAccountCtasLabel>{label}</MyAccountCtasLabel>}
        <ButtonWrapper>
            <tbody>
                {React.Children.map(children, (child) => (
                    <tr key={child.key}>
                        <td>
                            {child}
                        </td>
                    </tr>
                ))}
            </tbody>
        </ButtonWrapper>
    </React.Fragment>
);

MyAccountCtas.propTypes = {
    label: propTypes.node,
    children: propTypes.node,
};

MyAccountCtas.displayName = 'MyAccountCtas';

const HeadingWrapper = styled.div`
    display: grid;
    grid-template-columns: 44px [heading] auto  44px;
    align-items: center;
    margin-bottom: 49px;
    padding-top: 28px;
`;

const StyledLink = styled(A)`
    box-sizing: border-box;
    display: inline-block;
    padding: 5px 24px 5px 0; /* design is left-aligned arrow, but keep the good clickable size */
    height: 100%;
    text-decoration: none;
    color: ${rgba(blanc, 0.7)};

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        padding-right: 20px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding-right: 12px;
    `}

    &:focus,
    &:hover {
        outline: 0;
        color: ${blanc};
    }
`;

const Heading = styled(H1)`
    grid-column-start: heading;
    margin: 0;
    padding: 0 14px;
    text-align: center;
    text-transform: capitalize;
    color: ${blanc};
    font: var(--nucleus-header-5-light);
`;

const Wrapper = styled.div`
    height: 100%;
    min-height: 100vh;
`;

const StyledGA05BgImg = styled(GA05BgImg)`
    position: absolute;
    top: 0;
    left: 0;
`;

const Page = styled.div`
    box-sizing: border-box;
    position: relative;
    margin: 0 auto;
    padding: 70px 14px 21px;
    width: 100%;
    max-width: 644px;
    color: ${cloudy};
    font: var(--nucleus-body-copy-4);

    ${stylesWhen('isApp')`
        padding-top: 0;
    `}
`;

const ErrorMessageContainer = styled.div`
    margin: 0 auto 14px;
    width: 100%;
    max-width: 565px;
`;

const StyledIC103Loading = styled(IC103Loading)`
    margin: 0 auto;
`;

const loadingSpinner = (
    <StyledIC103Loading
        color={blanc}
        display="block"
        size="70px"
        ariaLabel="Loading page"
    />
);

const MyAccountPage = ({
    backUrl,
    banner,
    children,
    className,
    defaultImageSrc,
    errorMessage = '',
    isApp,
    isLoading,
    onBackClick,
    srcsetOptions,
    title,
}) => {
    let content;

    if (errorMessage) {
        content = (
            <ErrorMessageContainer>
                <TM24AlertTile>
                    {errorMessage}
                </TM24AlertTile>
            </ErrorMessageContainer>
        );
    } else if (isLoading) {
        content = loadingSpinner;
    } else {
        content = children;
    }

    return (
        <Wrapper className={classnames('TemplateMyAccountPage', className)}>
            {!!(srcsetOptions || defaultImageSrc) && (
                <StyledGA05BgImg
                    srcsetOptions={srcsetOptions}
                    defaultImageSrc={defaultImageSrc}
                />
            )}
            <Page isApp={isApp}>
                {banner}
                <HeadingWrapper>
                    {!!(onBackClick || backUrl) && (
                        <StyledLink aria-label="Go back" href={backUrl} onClick={onBackClick}>
                            <IC24ArrowL />
                        </StyledLink>
                    )}
                    <Heading>
                        {title}
                    </Heading>
                </HeadingWrapper>

                {content}
            </Page>
        </Wrapper>
    );
};

MyAccountPage.propTypes = {
    /** URL to visit on back click */
    backUrl: propTypes.string,
    /** Banner that seemingly goes above the template itself */
    banner: propTypes.node,
    /** children to display with page */
    children: propTypes.node,
    /** Additional class(es) */
    className: classNameType,
    /** Image to use for browsers that don't support <picture>. Defaults to largest srcSetOption screen size */
    defaultImageSrc: propTypes.string,
    /** Generic error message that replaces all content */
    errorMessage: propTypes.node,
    /** Whether the page is viewed within an app */
    isApp: propTypes.bool,
    /** Is the page loading */
    isLoading: propTypes.bool,
    /** What to do on back click */
    onBackClick: propTypes.func,
    /** SrcsetOptions object to use for background image */
    srcsetOptions: srcsetOptionsType,
    /** Title of the page */
    title: propTypes.string,
};

MyAccountPage.displayName = 'MyAccountPage';

export default MyAccountPage;
