import React from 'react';
import Ic from '..';

const IC321Telstra = (args) => (
    <Ic {...args}>
        <path d="m85 10-1.857 11.63a4 4 0 0 1-3.95 3.37H66.345l-6.777 39.674A4 4 0 0 1 55.625 68H40l7.346-43H29l1.857-11.63a4 4 0 0 1 3.95-3.37H85z" />
        <path d="M17.966 37.69c4.33-5.16 13.767-4.926 24.218-.294L36.444 71h19.181l.243-.004a7 7 0 0 0 6.657-5.817l1.982-11.606C75.622 65.46 80.172 78.68 74.653 85.257c-6.488 7.732-24.438 3.353-40.092-9.782C18.908 62.34 11.478 45.423 17.966 37.69z" />
    </Ic>
);

IC321Telstra.displayName = 'IC321Telstra';

export default IC321Telstra;
