import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {black, blueCharcoal, fog} from '../../../../common/palette';
import {transition} from '../../../../common/animations';
import {A} from '../../../../common/normalized-styled-components';
import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';

const StyledLink = styled(A)`
    display: inline-block;
    transition: ${transition('box-shadow', 'background-color', 'color')};
    margin: 0;
    padding: 14px 22px;
    min-width: 236px;
    text-align: center;
    text-decoration: none;
    color: ${rgba(fog, 0.5)};
    font: var(--infinity-body-copy-2);

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding: 23px 34px;
        min-width: 386px;
    `}

    &:hover {
        color: ${fog};
    }

    &:focus {
        outline: 0;
        box-shadow: 0 1px 1px 0 ${rgba(black, 0.3)};
        background-color: ${fog};
        color: ${blueCharcoal};
    }
`;

const BA30TextBtn = React.forwardRef(({
    children,
    className,
    href,
    ...htmlAttributes
}, ref) => (
    <StyledLink
        {...htmlAttributes}
        className={classnames('BA30TextBtn', className)}
        ref={ref}
        href={href}
    >
        {children}
    </StyledLink>
));

BA30TextBtn.propTypes = {
    /** children elements / Link display */
    children: propTypes.node,
    /** Additional CSS class(es) */
    className: classNameType,
    /** CMS href / Outgoing link */
    href: propTypes.string,
};

BA30TextBtn.displayName = 'BA30TextBtn';

export default BA30TextBtn;
