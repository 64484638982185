import React from 'react';
import Ic from '..';

const IC28RelVid = (args) => (
    <Ic {...args}>
        <path d="M86.3 17.7H37.1c-1.5 0-2.8 1.2-2.8 2.8v10.6h-9.6c-.8 0-1.4.6-1.4 1.4v10.6h-11c-.8 0-1.4.6-1.4 1.4V81c0 .8.6 1.4 1.4 1.4h49.1c.8 0 1.4-.6 1.4-1.4V70.3h11c.8 0 1.4-.6 1.4-1.4v-9.2h11c1.5 0 2.8-1.2 2.8-2.8V20.4c.1-1.5-1.2-2.7-2.7-2.7zM60.1 79.6H13.7V45.8h9.6v23.1c0 .8.6 1.4 1.4 1.4H60l.1 9.3zm12.4-12H26.1V33.8h8.2v23.1c0 1.5 1.2 2.8 2.8 2.8h35.3v7.9h.1zm11-13.4H39.9v-31h43.6v31z" />
    </Ic>
);

IC28RelVid.displayName = 'IC28RelVid';

export default IC28RelVid;
