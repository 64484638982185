import React from 'react';
import Ic from '..';

const IC107Prog = (args) => (
    <Ic {...args}>
        <path d="M49.363 9.361c22.253 0 40.36 18.107 40.36 40.36 0 15.833-9.163 29.563-22.462 36.171h21.936a2.076 2.076 0 1 1 0 4.152l-38.112.001a40.858 40.858 0 0 1-3.445 0h-.137c-.096 0-.19-.007-.283-.02C25.956 88.908 9 71.26 9 49.721c0-22.253 18.107-40.36 40.364-40.36zm0 4.156c-19.965 0-36.204 16.24-36.204 36.204 0 19.446 15.41 35.363 34.664 36.172h3.08c19.25-.81 34.66-16.726 34.66-36.172 0-19.965-16.239-36.204-36.2-36.204zM35.969 54.02c4.576 0 8.299 3.723 8.299 8.299 0 4.573-3.723 8.292-8.3 8.292-4.572 0-8.295-3.72-8.295-8.292 0-4.576 3.723-8.3 8.296-8.3zm26.783 0c4.576 0 8.3 3.723 8.3 8.299 0 4.573-3.724 8.292-8.3 8.292-4.573 0-8.296-3.72-8.296-8.292 0-4.576 3.723-8.3 8.296-8.3zm-26.783 1.938a6.366 6.366 0 0 0-6.357 6.361 6.365 6.365 0 0 0 6.357 6.357c3.504 0 6.36-2.853 6.36-6.357a6.369 6.369 0 0 0-6.36-6.36zm26.783 0a6.366 6.366 0 0 0-6.357 6.361 6.365 6.365 0 0 0 6.357 6.357c3.504 0 6.36-2.853 6.36-6.357a6.369 6.369 0 0 0-6.36-6.36zm-26.783-27.13c4.576 0 8.299 3.723 8.299 8.3 0 4.572-3.723 8.295-8.3 8.295-4.572 0-8.295-3.723-8.295-8.296 0-4.576 3.723-8.299 8.296-8.299zm26.783 0c4.576 0 8.3 3.723 8.3 8.3 0 4.572-3.724 8.295-8.3 8.295-4.573 0-8.296-3.723-8.296-8.296 0-4.576 3.723-8.299 8.296-8.299zm-26.783 1.939a6.366 6.366 0 0 0-6.357 6.36 6.365 6.365 0 0 0 6.357 6.357c3.504 0 6.36-2.852 6.36-6.357a6.369 6.369 0 0 0-6.36-6.36zm26.783 0a6.366 6.366 0 0 0-6.357 6.36 6.365 6.365 0 0 0 6.357 6.357c3.504 0 6.36-2.852 6.36-6.357a6.369 6.369 0 0 0-6.36-6.36z" />
    </Ic>
);

IC107Prog.displayName = 'IC107Prog';

export default IC107Prog;
