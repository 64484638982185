export const AKAMAI_IMAGE_MANAGER_SIZES = [
    100,
    320,
    640,
    1024,
    2048,
];

export function imageUrlToSrcsetOptions({
    imageUrl,
    sizes = AKAMAI_IMAGE_MANAGER_SIZES,
}) {
    if (!imageUrl) {
        return;
    } else if (sizes === AKAMAI_IMAGE_MANAGER_SIZES) {
        console.warn(`using default AKAMAI_IMAGE_MANAGER_SIZES - this is probably a mistake! (${imageUrl})`);
    }

    const customAppendCharacter = getQueryArgPrefix(imageUrl);

    return sizes.reduce((srcsetOptions, sizePx) => ({
        ...srcsetOptions,
        [sizePx]: `${imageUrl}${customAppendCharacter}imwidth=${sizePx}`,
    }), {});
}

// Instead of just getting Akamai to resize the image, this crops it to certain dimensions
export function imageUrlToCroppedSrcsetOptions({
    imageUrl,
    heightsAndWidths = [],
}) {
    if (!imageUrl) {
        return;
    }

    const customAppendCharacter = getQueryArgPrefix(imageUrl);

    return heightsAndWidths.reduce((srcsetOptions, {heightPx, widthPx}) => ({
        ...srcsetOptions,
        [widthPx]: `${imageUrl}${customAppendCharacter}impolicy=crop&w=${widthPx}&h=${heightPx}`,
    }), {});
}

export function imageUrlToSrcset({
    imageUrl,
    sizes = AKAMAI_IMAGE_MANAGER_SIZES,
}) {
    if (!imageUrl) {
        return;
    } else if (sizes === AKAMAI_IMAGE_MANAGER_SIZES) {
        console.warn(`using default AKAMAI_IMAGE_MANAGER_SIZES - this is probably a mistake! (${imageUrl})`);
    }

    const customAppendCharacter = getQueryArgPrefix(imageUrl);

    return sizes
        .map((sizePx) => `${imageUrl}${customAppendCharacter}imwidth=${sizePx} ${sizePx}w`)
        .join(', ');
}

/**
 * Given a template URL in the format `'/team-logos/16x9/afl/20018.png?imwidth=${WIDTH}'`
 * and a specified array of imwidth pixel values, return a srcsetOptions object
 *
 * @param {Object} options see below
 * @param {string} options.templateUrl url string in expected syntax
 * @param {number[]} options.sizes list of imwidths to apply
 *
 * @returns {Object} srcsetOptions object
 */
export function templateUrlToSrcsetOptions({
    templateUrl,
    sizes = AKAMAI_IMAGE_MANAGER_SIZES,
}) {
    if (!templateUrl || !templateUrl.includes('imwidth=${WIDTH}')) { // eslint-disable-line no-template-curly-in-string
        return;
    } else if (sizes === AKAMAI_IMAGE_MANAGER_SIZES) {
        console.warn(`using default AKAMAI_IMAGE_MANAGER_SIZES - this is probably a mistake! (${templateUrl})`);
    }

    return sizes.reduce((srcsetOptions, sizePx) => ({
        ...srcsetOptions,
        [sizePx]: templateUrl.replace('imwidth=${WIDTH}', `imwidth=${sizePx}`), // eslint-disable-line no-template-curly-in-string
    }), {});
}

function getQueryArgPrefix(url) {
    return /\?/.test(url) ? '&' : '?';
}
