import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

const Score = styled.abbr`
    text-decoration: none;
`;

const Total = styled.span`
    margin-left: 3px;
    font: var(--mui-header-6-bold);
`;

const AflScore = ({
    behinds,
    goals,
    score,
    superGoals,
}) => {
    const scoreDescription = [[superGoals, 'Super Goals'], [goals, 'Goals'], [behinds, 'Behinds'], [score, 'Total']]
        .filter(([scoreFragment]) => scoreFragment !== null)
        .map(([scoreFragment, label]) => `${label}: ${scoreFragment}`)
        .join(', ');

    return (
        <Score
            className="BM01GnrBnr__score"
            aria-label={scoreDescription}
            title={scoreDescription}
        >
            {superGoals !== null && `${superGoals}.`}{goals}.{behinds} <Total aria-hidden="true">{score}</Total>
        </Score>
    );
};

AflScore.displayName = 'AflScore';

AflScore.propTypes = {
    behinds: propTypes.number,
    goals: propTypes.number,
    score: propTypes.number,
    superGoals: propTypes.number,
};

export default AflScore;
