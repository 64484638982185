import React from 'react';
import styled, {css} from 'styled-components';
import propTypes from 'prop-types';
import classnames from 'classnames';
import {A, Button} from 'normalized-styled-components';
import {rgba} from 'polished';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType, srcsetOptionsType} from '@fsa-streamotion/custom-prop-types';

import {SCREEN_768_TABLET, SCREEN_1280_DESKTOP, SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import {onyx} from '../../../../common/palette';
import {transition} from '../../../../common/animations';
import GA01ProBar from '../../../atoms/ga/01-pro-bar';
import GA07TileImg from '../../../atoms/ga/07-tile-img';
import GA104TMFooter from '../../../atoms/ga/104-tm-footer';

const baseCss = css`
    display: block;
    position: relative;
    width: 204px;
    height: 115px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: 227px;
        height: 128px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        width: 220px;
        height: 124px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 281px;
        height: 158px;
    `}
`;

const interactiveCss = css`
    &:hover,
    &:focus {
        transform: scale(1.1);
        transition: ${transition('transform')};
        outline: none;
    }
`;

const NonInteractive = styled.div`
    ${baseCss}
`;

const StyledButton = styled(Button)`
    ${baseCss}
    ${interactiveCss}

    appearance: none;
    border: 0;
    background: transparent;
    padding: 0;
`;

const StyledLink = styled(A)`
    ${baseCss}
    ${interactiveCss}

    text-decoration: inherit;
    color: inherit;
`;

const StyledGA07TileImg = styled(GA07TileImg)`
    transform: none;

    &::before {
        padding-bottom: 56.25%;
    }
`;

const TileInfo = styled.div`
    position: absolute;
    bottom: 0;
    opacity: 0.9;
    background: linear-gradient(to top, ${onyx}, transparent);
    width: 100%;
    height: 27px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        height: 49px;
    `}
`;

const StyledGA104TMFooter = styled(GA104TMFooter)`
    box-shadow: none;
    background: none;
    padding: 7px;
    text-align: left;
    text-shadow: 0 1px 1px ${rgba(onyx, 0.3)};
    letter-spacing: 0.5px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding: 14px;
        letter-spacing: 0.83px;
    `}
`;

const StyledGA01ProBar = styled(GA01ProBar)`
    position: absolute;
    bottom: 0;
`;

const TM31RelVidTile = React.forwardRef(({
    badgeSrcsetOptions,
    className,
    currentProgressPerc,
    footerText,
    href,
    label,
    onClick,
    srcsetOptions,
    srcsetSizes,
    ...htmlAttributes
}, ref) => {
    const Cta = getCtaType(href, onClick);

    return (
        <Cta
            {...htmlAttributes}
            aria-label={label}
            className={classnames('TM31RelVidTile', className)}
            href={href}
            onClick={onClick}
            ref={ref}
        >
            <StyledGA07TileImg
                badgeSrcsetOptions={badgeSrcsetOptions}
                caption={footerText || label}
                srcsetOptions={srcsetOptions}
                srcsetSizes={srcsetSizes}
            />
            {!!(footerText || currentProgressPerc) && (
                <TileInfo>
                    {!!footerText && <StyledGA104TMFooter>{footerText}</StyledGA104TMFooter>}
                    {!!currentProgressPerc && (
                        <StyledGA01ProBar hasFooter={!!footerText} current={currentProgressPerc} />
                    )}
                </TileInfo>
            )}
        </Cta>
    );
});

function getCtaType(href, onClick) {
    if (href) {
        return StyledLink;
    } else if (onClick) {
        return StyledButton;
    } else {
        return NonInteractive;
    }
}

TM31RelVidTile.propTypes = {
    badgeSrcsetOptions: srcsetOptionsType,
    className: classNameType,
    currentProgressPerc: propTypes.number,
    footerText: propTypes.string,
    href: propTypes.string,
    label: propTypes.string,
    onClick: propTypes.func,
    srcsetOptions: srcsetOptionsType,
    srcsetSizes: propTypes.string,
};

TM31RelVidTile.displayName = 'TM31RelVidTile';

export default TM31RelVidTile;
