import React, {useState, useCallback, useEffect} from 'react';
import propTypes from 'prop-types';
import noop from 'lodash/noop';
import invoke from 'lodash/invoke';
import styled from 'styled-components';
import {Button, Input} from 'normalized-styled-components';
import {format} from 'date-fns';

import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';

import {transition} from '../../../../common/animations';
import {blanc, eli, jaws, panther} from '../../../../common/palette';
import {fontFamily} from '../../../../common/typography';

const StyledLabel = styled.label`
    position: relative;
    border-radius: 3px;
    width: 100%;
    height: 44px;
    color: ${eli};
`;

const FloatingLabelText = styled.span`
    position: absolute;
    top: 45%;
    left: 7px;
    transform: translateY(-50%);
    transition: ${transition('transform', 'opacity', 'color')};
    opacity: ${({isVisible}) => isVisible ? 1 : 0};
    font-family: ${fontFamily};
    font-size: 11px;

    ${stylesWhen(({isVisible}) => !!isVisible)`
        transform: translateY(-19px);
    `}
`;

const IconButton = styled(Button)`
    display: ${({hidden}) => hidden ? 'none' : 'block'};
    position: absolute;
    top: 50%;
    right: ${({hasIcon}) => hasIcon ? 49 : 14}px;
    transform: translateY(-50%);
    border: 0;
    border-bottom: 1px solid ${blanc};
    background: transparent;
    padding: 0;
    color: ${blanc};
    font: var(--ionic-body-copy-4);

    &:hover,
    &:focus {
        outline: 0;
    }
`;

const StyledInput = styled(Input)`
    box-sizing: border-box;
    transition: ${transition('box-shadow', 'border-color')};
    border: 0;
    border-bottom: 1px solid ${eli};
    border-radius: 0;
    background-color: inherit;
    padding: 14px 7px 5px;
    width: 100%;
    height: 100%;
    color: ${blanc};

    ${stylesWhen(({hasRevealPasswordButton, hasIcon}) => !hasRevealPasswordButton && !!hasIcon)`
        /* has icon, but not reveal password */
        padding-right: 49px;
    `}

    ${stylesWhen(({hasRevealPasswordButton, hasIcon}) => !!hasRevealPasswordButton && !hasIcon)`
        /* has reveal password, but not the icon */
        padding-right: 63px;
    `}

    ${stylesWhen(({hasRevealPasswordButton, hasIcon}) => !!(hasRevealPasswordButton && hasIcon))`
        /* has both reveal password and icon */
        padding-right: 105px;
    `}

    ${stylesWhen('isTextCentered')`
        text-align: center;
    `}

    &::placeholder {
        color: ${blanc};
    }

    &:empty::placeholder {
        color: ${eli};
    }

    /* stylelint-disable-next-line order/order */
    &::-ms-reveal,
    &::-ms-clear {
        display: none; /* Hide the eyeball icon on Edge */
    }

    &:invalid,
    &:required {
        /* Rely on our own custom designs to communicate invalid and required states */
        box-shadow: none;
    }

    &:empty + ${FloatingLabelText} {
        color: ${eli};
    }

    &:focus {
        outline: 0;
        border-color: ${jaws};
    }
    /* make label legible when background goes yellow due to chrome autofill form */
    &:-webkit-autofill + ${FloatingLabelText} {
        color: ${panther};
    }

    &:disabled {
        opacity: 0.4;

        &:hover {
            cursor: not-allowed;
        }
    }
`;

const DISPLAY_DATE_FORMAT = 'dd/MM/yyyy'; // our data is YYYY-MM-DD - but is validated as DD/MM/YYYY in Safari where the input is text
const DISPLAY_DATE_LABEL = 'dd/mm/yyyy'; // the chosen label for Safari though is lowercase, making it consistent across browsers

const IA01Input = React.forwardRef(({
    defaultValue,
    hasIcon,
    hasRevealPasswordButton,
    isTextCentered,
    label,
    onBlur = noop,
    onChange = noop,
    onFocus = noop,
    pattern,
    type = 'text',
    ...htmlAttributes
}, forwardedRef) => {
    const ref = forwardedRef || React.createRef();
    const [hasFocus, setHasFocus] = useState(false);
    const [hasValue, setHasValue] = useState(!!defaultValue);
    const [showPassword, setShowPassword] = useState(false);
    const [isDateWorkaroundNeeded, setIsDateWorkaroundNeeded] = useState(type === 'date' ? undefined : false);

    useEffect(function checkIsDateWorkaroundNeeded() { // note: all our supported browsers other than safari on macOS support type=date. rather than implementing an awful 3rd party date picker, do a couple of small things to make it workable on safari macOS until it catches up
        setIsDateWorkaroundNeeded(type === 'date' && ref.current.type === 'text'); // we asked for date but got text, because our browser didn't support it :(
    }, [type]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = useCallback(function sanitiseInputValue(event) {
        setHasValue(!!event.target.value);

        if (!isDateWorkaroundNeeded) {
            return void onChange(event); // we dont need to mess with these. we just want to prevent "bad" dates getting passed back
        }

        const [day, month, year] = invoke(event.target.value, 'split', '/') || [];
        const birthdayDate = year && month && day && new Date(year, month, day);

        const normalisedDate = checkDateIsValid(birthdayDate)
            ? event.target.value // valid dates we'll return. someone downstream will need to normalise though!
            : ''; // invalid dates we'll return as empty strings

        onChange(normalisedDate);
    }, [onChange, isDateWorkaroundNeeded]);

    const handleFocus = useCallback(function handleFocus(event) {
        setHasFocus(true);
        onFocus(event);
    }, [onFocus]);

    const handleBlur = useCallback(function handleBlur(event) {
        setHasFocus(false);
        onBlur(event);
    }, [onBlur]);

    const toggleShowPassword = useCallback(function handleToggleShowPassword(event) {
        event.preventDefault();
        setShowPassword(!showPassword);
    }, [showPassword]);

    const showLabel = hasValue || hasFocus || type === 'date';

    const edgeInlineStyleOverride = {font: 'var(--ionic-body-copy-3)'}; // Setting inline style due to Edge and Styled Components not working well with CSS variables

    const placeholder = isDateWorkaroundNeeded
        ? (showLabel ? DISPLAY_DATE_LABEL : `${label} (${DISPLAY_DATE_LABEL})`)
        : (showLabel ? null : label);

    return (
        <StyledLabel>
            <StyledInput
                {...htmlAttributes}
                defaultValue={isDateWorkaroundNeeded ? formatDateStrIntoDisplayDate(defaultValue) : defaultValue}
                hasIcon={hasIcon}
                hasRevealPasswordButton={hasRevealPasswordButton}
                isTextCentered={isTextCentered}
                key={isDateWorkaroundNeeded} // dirty trick to force re-mount if we need the workaround
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={handleFocus}
                pattern={isDateWorkaroundNeeded ? '^[0-3][0-9]/[0-1][0-9]/(19|20)[0-9]{2}$' : pattern}
                placeholder={placeholder}
                ref={ref}
                style={edgeInlineStyleOverride}
                title={label}
                type={showPassword ? 'text' : type}
            />

            <FloatingLabelText isVisible={showLabel}>
                {label}{isDateWorkaroundNeeded && hasValue && ` ${DISPLAY_DATE_LABEL}`}
            </FloatingLabelText>

            {type === 'password' && (
                <IconButton
                    aria-label="Reveal Password"
                    aria-pressed={!!showPassword}
                    hasIcon={hasIcon}
                    hidden={!hasRevealPasswordButton}
                    onClick={toggleShowPassword}
                    type="button"
                >
                    {showPassword ? 'Hide' : 'Show'}
                </IconButton>
            )}
        </StyledLabel>
    );
});

IA01Input.displayName = 'IA01Input';

IA01Input.propTypes = {
    /** If supplied, will be initial value of the search field */
    defaultValue: propTypes.string,
    /** Placeholder label for input field, which floats above on text input */
    label: propTypes.string.isRequired,
    /** The HTML type of the input field */
    type: propTypes.oneOf(['text', 'password', 'email', 'tel', 'url', 'search', 'date', 'number']),
    /** For password inputs, whether or not to render the show/hide button */
    hasRevealPasswordButton: propTypes.bool,
    /** Does it have an icon? */
    hasIcon: propTypes.bool,
    /** Forwarded ref to the input element */
    inputRef: propTypes.oneOfType([propTypes.object, propTypes.func]),
    /** Is text centered */
    isTextCentered: propTypes.bool,
    /** Callback on text field value change */
    onChange: propTypes.func,
    /** Focus event handler */
    onFocus: propTypes.func,
    /** Blur event handler */
    onBlur: propTypes.func,
    /** Validation regex pattern */
    pattern: propTypes.string,
};

export default IA01Input;

function formatDateStrIntoDisplayDate(dateStr) {
    if (!dateStr) {
        return;
    }

    const [year, month, day] = invoke(dateStr, 'split', '-') || [];
    const dateToValidate = year
        && month
        && day
        && new Date(
            Number(year),
            Number(month) - 1, // omg monthIndex vs month. why javascript whyyyyy
            Number(day)
        );

    return checkDateIsValid(dateToValidate)
        ? format(dateToValidate, DISPLAY_DATE_FORMAT)
        : undefined;
}

function checkDateIsValid(dateInstance) {
    return !!dateInstance // undefined/falsey dates are invalid for our purposes
        && !!dateInstance.valueOf() // unix epoch 0 we can pretty safely assume is an invalid date in this use case
        && !Number.isNaN(dateInstance.valueOf()); // the date string was an invalid date
}
