// Reference: https://css-tricks.com/a-super-flexible-css-carousel-enhanced-with-javascript-navigation/
import ResizeObserver from 'resize-observer-polyfill';
import {useEffect, useRef, useState} from 'react';

/**
 * Get element _before_ the FIRST element in the list of elements
 * - For example:
 *   - _GIVEN:_
 *       - list: `[d, e, f]`
 *       - complete carouel items: `[c, d, e, f, g]`
 *       - target element is `list[0]: d`
 *   - _RETURN:_
 *       - element before `d`: `c`
 *
 * @param {HTMLElement[]} list array of visible elements in the carousel
 * @returns {HTMLElement} prevElement
 */
const getPrevElement = (list) => {
    const sibling = list[0].previousElementSibling;

    return sibling;
};

/**
 * Get element _after_ the LAST element in the list of elements
 * - For example:
 *   - _GIVEN:_
 *       - list: `[d, e, f]`
 *       - complete carouel items: `[c, d, e, f, g]`
 *       - target element is `list[2]: f`
 *   - _RETURN:_
 *       - element after `f`: `g`
 *
 * @param {HTMLElement[]} list array of visible elements in the carousel
 * @returns {HTMLElement|null} nextElement
 */
const getNextElement = (list) => {
    const sibling = list[list.length - 1].nextElementSibling;

    if (sibling instanceof HTMLElement) {
        return sibling;
    }

    return null;
};

/**
 * This hook does the following:
 *  - if initialFocusIndex is set, scroll corresponding element into view on load
 *  - sets possible previous and next elements to center on next scroll based on fully visible items
 *  - enables scrolling when swiping
 *  - creates reference, callbacks and flags to be used by carousel controls
 *
 * @param {{initialFocusIndex: number}} options options
 * @returns {{carouselContainerRef: React.Ref, hasItemsOnLeft: boolean, hasItemsOnRight: boolean, scrollLeft: Function, scrollRight: Function}} flags and callbacks
 */
const useFiniteCarousel = ({initialFocusIndex}) => {
    const carouselContainerRef = useRef(null);
    const [prevElement, setPrevElement] = useState(null);
    const [nextElement, setNextElement] = useState(null);

    /**
     * Scrolls the carousel to position initial focus element to the furthest left of visible items
     * + computed peek width (50% of previous element if any) to show partial previous element
     */
    useEffect(function scrollInitialFocusElementIntoView() {
        const carouselContainer = carouselContainerRef.current;

        const scrollIntoInitialFocusIndex = () => {
            const childToFocus = carouselContainer.children[initialFocusIndex];

            if (childToFocus instanceof HTMLElement) {
                const peekWidth = childToFocus.previousElementSibling?.getBoundingClientRect().width * 0.5; // previous element peek width
                const newScrollPosition = childToFocus.offsetLeft - peekWidth;

                carouselContainer.scroll({left: newScrollPosition});
            }
        };

        scrollIntoInitialFocusIndex();
    }, [initialFocusIndex]);

    /**
     * Updates `prevElement` and `nextElement` to _possibly_ center on whenever:
     * - [scroll] navigation buttons are clicked or carousel is swiped
     * - [resize] or carousel container resizes (hence changing visible items)
     */
    useEffect(function setPossibleNextPrevTargetElements() {
        const carouselContainer = carouselContainerRef.current;

        const updateTargets = () => {
            const containerDimensions = carouselContainer.getBoundingClientRect();

            const fullyVisibleElements = Array.from(carouselContainer.children).filter((child) => {
                const childDimensions = child.getBoundingClientRect();

                // checks if current child is fully visible within container
                return (containerDimensions.left <= childDimensions.left) && (childDimensions.right <= containerDimensions.right);
            });

            if (fullyVisibleElements.length > 0) {
                setPrevElement(getPrevElement(fullyVisibleElements));
                setNextElement(getNextElement(fullyVisibleElements));
            }
        };

        const resizeObserver = new ResizeObserver(updateTargets);

        updateTargets();

        // Ref: https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#improving_scrolling_performance_with_passive_listeners
        carouselContainer.addEventListener('scroll', updateTargets, {passive: true});
        resizeObserver.observe(carouselContainer);

        return () => {
            carouselContainer.removeEventListener('scroll', updateTargets, {passive: true});
            resizeObserver.disconnect();
        };
    }, []);

    /**
     * Scrolls the carousel to position target element to the center of visible items
     *
     * @param {HTMLElement} targetElement target element
     */
    const scrollToElement = (targetElement) => {
        const carouselContainer = carouselContainerRef.current;

        if (!carouselContainer || !targetElement) {
            return;
        }

        /** Center position, hence, `/ 2` */
        const newScrollPosition = targetElement.offsetLeft // how far away element is from the left
            + (targetElement.getBoundingClientRect().width / 2) // plus half its width for aligning to its centre point
            - (carouselContainer.getBoundingClientRect().width / 2); // minus half the container width for horizontal centring on page

        carouselContainer.scroll({left: newScrollPosition, behavior: 'smooth'});
    };

    /*
     * Scrolls to the right and positions `nextElement` to the center
     */
    const scrollRight = () => void scrollToElement(nextElement);

    /*
     * Scrolls to the left and positions `prevElement` to the center
     */
    const scrollLeft = () => void scrollToElement(prevElement);

    return {
        carouselContainerRef,
        hasItemsOnLeft: prevElement !== null,
        hasItemsOnRight: nextElement !== null,
        scrollLeft,
        scrollRight,
    };
};

export default useFiniteCarousel;
