import React from 'react';
import propTypes from 'prop-types';

import {getResourcesUrlBasePath} from '@fsa-streamotion/streamotion-web-widgets-common';

import StandardTile from './slide--standard';

const TILE_TYPE_COMPONENT = {
    standard: StandardTile,
};

const PanelTile = ({contentDisplay = {}, brand, resourcesEnv, ...rest}) => {
    const TileComponent = TILE_TYPE_COMPONENT[contentDisplay.type] || StandardTile;

    return (
        <TileComponent
            contentDisplay={contentDisplay}
            defaultLandscapeImageSrcOptions={{320: `${getResourcesUrlBasePath({brand, resourcesEnv})}/web/images/tiles/default_tile_landscape.png?imwidth=320`}}
            defaultPortraitImageSrcsetOptions={{320: `${getResourcesUrlBasePath({brand, resourcesEnv})}/web/images/tiles/default_tile_portrait.png?imwidth=320`}}
            {...rest}
        />
    );
};

PanelTile.displayName = 'PanelTile';

PanelTile.propTypes = {
    brand: propTypes.string,
    resourcesEnv: propTypes.string,
    contentDisplay: propTypes.shape({
        type: propTypes.oneOf(Object.keys(TILE_TYPE_COMPONENT)),
    }),
};

export default PanelTile;
