import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {SCREEN_1280_DESKTOP, SCREEN_1024_DESKTOP, SCREEN_768_TABLET, SCREEN_415_PHABLET} from '../../../../common/screen-sizes';

const StyledUl = styled.ul`
    display: grid;
    /* 3 per row. not sure why this is invalid CSS according to csstree/validator? investigate later */
    grid-template-columns: repeat(auto-fit, 33.3%);
    grid-row-gap: 15px;
    justify-content: left;
    margin: 0 auto;
    padding: 0;
    max-width: 1061px;
    min-height: 100px;
    list-style: none;

    ${mediaQuery({minWidthPx: SCREEN_415_PHABLET})`
        grid-template-columns: repeat(auto-fit, 25%); // 4 per row
    `}

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        grid-template-columns: repeat(auto-fit, 16.6%); // 6 per row
    `}

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        grid-template-columns: repeat(auto-fit, 12.5%); // 8 per row
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        grid-template-columns: repeat(auto-fit, 10%); // 10 per row
    `}
`;

const StyledLi = styled.li`
    text-align: center;
`;

const TRM01Blk = ({
    className,
    children,
}) => (
    <section
        className={classnames('TRM01Blk', className)}
    >
        <StyledUl>
            {React.Children.map(children, (child) => (
                <StyledLi key={child.key}>{child}</StyledLi>
            ))}
        </StyledUl>
    </section>
);

TRM01Blk.propTypes = {
    children: propTypes.node,
    /** Optional additional class(es) */
    className: classNameType,
};

TRM01Blk.displayName = 'TRM01Blk';

export default TRM01Blk;
