import React from 'react';
import Ic from '..';

const IC135Cross = (args) => (
    <Ic {...args}>
        <path d="M85 20.967L79.033 15 50 44.033 20.967 15 15 20.967 44.033 50 15 79.033 20.967 85 50 55.967 79.033 85 85 79.033 55.967 50z" />
    </Ic>
);

IC135Cross.displayName = 'IC135Cross';

export default IC135Cross;
