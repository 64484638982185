import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import {Section} from 'normalized-styled-components';
import {rgba} from 'polished';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {blanc, onyx} from '../../../../common/palette';

const Tile = styled(Section)`
    box-sizing: border-box;
    border-radius: 4px;
    background-color: ${rgba(blanc, 0.2)};
    padding: 14px;
    width: 100%;
    text-shadow: 0 1px 1px ${rgba(onyx, 0.3)};
    color: ${blanc};
    font: var(--nucleus-body-copy-3);
`;

const StyledHeading = styled.h4`
    margin: 0 0 7px;
    font: var(--nucleus-header-8-bold);
`;

const GA89Tile = ({
    children,
    className,
    title,
    ...htmlAttributes
}) => (
    <Tile {...htmlAttributes} className={classnames('GA89Tile', className)}>
        {!!title && (
            <StyledHeading>{title}</StyledHeading>
        )}

        {children}
    </Tile>
);

GA89Tile.propTypes = {
    /** Content of tile, e.g. text */
    children: propTypes.node,
    /** Additional class name(s) */
    className: classNameType,
    /** Title to show in tile */
    title: propTypes.node,
};

GA89Tile.displayName = 'GA89Tile';

export default GA89Tile;
