import React from 'react';
import Ic from '..';

const IC245Dolby51 = (args) => (
    <Ic {...args}>
        <path d="M10.267 7.829h9.924c10.297 0 18.909 10.234 18.909 22.307 0 12.44-8.612 22.485-18.91 22.485h-9.923V7.829zm56.578 0h9.952v44.792h-9.952c-10.31 0-18.904-10.044-18.904-22.485 0-12.073 8.593-22.307 18.904-22.307zM0 60.457h87.046V0H0v60.457z" />
    </Ic>
);

IC245Dolby51.displayName = 'IC245Dolby51';

export default IC245Dolby51;
