import React from 'react';
import {midnight} from '../../../../common/palette';
import Ic from '..';

const IC155MultiAdd4 = (args) => (
    <Ic {...args}>
        <circle cx="50" cy="50" r="42.4" />
        <path fill={midnight} d="M62.4 57.7h-3.9v5.8h-6.6v-5.8H37.7v-5L51 34.2h7.6v18.2h3.9v5.3zm-10.3-5.2V41.2l-8.2 11.3h8.2z" />
    </Ic>
);

IC155MultiAdd4.displayName = 'IC155MultiAdd4';

export default IC155MultiAdd4;
