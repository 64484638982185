import React from 'react';
import {colorType} from '@fsa-streamotion/custom-prop-types';

import {indigo, ink} from '../../../../common/palette';

import Ic from '..';

const IC69Stop = ({
    color = indigo,
    secondaryColor = ink,
    ...args
}) => (
    <Ic {...args}>
        <path fill={secondaryColor} d="M49.62 6C26.083 6 7 25.082 7 48.62c0 23.54 19.082 42.621 42.62 42.621 23.54 0 42.621-19.082 42.621-42.62C92.234 25.085 73.156 6.008 49.621 6z" />
        <path fill={color} d="M36.345 35.345h26.551v26.551H36.345z" />
    </Ic>
);

IC69Stop.propTypes = {
    /** The icon's primary color */
    color: colorType,
    /** The icon's secondary color */
    secondaryColor: colorType,
};

IC69Stop.displayName = 'IC69Stop';

export default IC69Stop;
