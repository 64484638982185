import React from 'react';
import Ic from '..';

const IC156MatchSummary = (args) => (
    <Ic {...args}>
        <g fillRule="nonzero">
            <path d="M42.1 43.3H67v2.8H42.1v-2.8zm0 9.6H67v2.8H42.1v-2.8zm0 9.5H67v2.8H42.1v-2.8zm0-28.6H67v2.8H42.1v-2.8z" />
            <path d="M74.9 17.5H25.1c-4.6 0-8.4 3.8-8.4 8.4v52.9c0 1.5 1.2 2.8 2.8 2.8H75c4.6 0 8.4-3.8 8.4-8.4V25.9c0-4.6-3.8-8.4-8.5-8.4zm2.9 55.6c0 1.6-1.3 2.9-2.9 2.9H22.2V25.9c0-1.6 1.3-2.9 2.9-2.9H75c1.6 0 2.9 1.3 2.9 2.9v47.2h-.1z" />
            <path d="M33 43.3h4v2.8h-4v-2.8zm0 9.6h4v2.8h-4v-2.8zm0 9.5h4v2.8h-4v-2.8zm0-28.6h4v2.8h-4v-2.8z" />
        </g>
    </Ic>
);

IC156MatchSummary.displayName = 'IC156MatchSummary';

export default IC156MatchSummary;
