import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Main} from 'normalized-styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {athensGray, black, ironStone, midnight, white} from '../../../common/palette';
import {SCREEN_TABLET} from '../../../common/screen-sizes';

/* stylelint-disable declaration-empty-line-before */
const TemplateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
`;

/* To future devs:
   We had an original templating system but found the designers were making each row bespoke.
   This new base row template makes fewer assumptions, so hopefully it could still be useful
   as a generic row, but also can be extended to offer control over top/bottom/side padding,
   whether it is a thin content section, whatever whacky things the designers come up with.
   If you don’t like it, then we’re sorry for the mess. - <3 SEAN AND MING
*/
const Row = styled.div`
    /* Override these CSS properties to control row behaviour */
    --offers-page__row-padding-top: 28px;                                       /* padding on top of row */
    --offers-page__row-padding-bottom: var(--offers-page__row-padding-top);     /* padding on bottom of row */
    --offers-page__row-padding-horizontal: 14px;                                /* padding on sides of row */
    --offers-page__row-padding-horizontal-thin-content: calc(50% - 330px);      /* for the thin content sections, aka max-width 660px */

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        --offers-page__row-padding-top: 56px;
    `}

    box-sizing: border-box;
    padding: var(--offers-page__row-padding-top, 0) var(--offers-page__row-padding-horizontal) var(--offers-page__row-padding-bottom, 0);
`;

export const HeroWrapper = styled(Row).attrs({className: 'offers-page__row--hero'})`
    --offers-page__row-padding-top: 0;
    --offers-page__row-padding-horizontal: 0;

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        --offers-page__row-padding-top: 0;
    `}

    background-color: ${black};
`;

export const ProductsWrapper = styled(Row).attrs({className: 'offers-page__row--products'})`
    --offers-page__row-padding-top: 41px;
    --offers-page__row-padding-bottom: 28px;

    display: flex;
    justify-content: center;
    background-color: ${({isLightTheme}) => isLightTheme ? athensGray : ironStone};

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        --offers-page__row-padding-top: 86px;
        --offers-page__row-padding-bottom: 28px;
        --offers-page__row-padding-horizontal: var(--offers-page__row-padding-horizontal-thin-content);
    `}
`;

export const FeaturesWrapper = styled(Row).attrs({className: 'offers-page__row--features'})`
    --offers-page__row-padding-top: 0;

    background-color: ${({isLightTheme}) => isLightTheme ? athensGray : ironStone};

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        --offers-page__row-padding-top: 0;
        --offers-page__row-padding-horizontal: var(--offers-page__row-padding-horizontal-thin-content);
    `}
`;

export const VoucherWrapper = styled(Row).attrs({className: 'offers-page__row--voucher'})`
    --offers-page__row-padding-top: 41px;

    background-color: ${({isLightTheme}) => isLightTheme ? athensGray : ironStone};

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        --offers-page__row-padding-top: 86px;
        --offers-page__row-padding-horizontal: calc(50% - 225px); /* aka max-width 550px */
    `}
`;

export const SportsSeriesSelectorWrapper = styled.div.attrs({className: 'offers-page__row--sports-series-selector'})`
    background: ${midnight};
    padding-top: 55px;

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        padding-top: 95px;
    `}
`;

export const SeriesSelectorWrapper = styled(Row).attrs({className: 'offers-page__row--series-selector'})`
    --offers-page__row-padding-top: 84px;
    --offers-page__row-padding-bottom: 56px;
    --offers-page__row-padding-horizontal: calc(50% - 640px); /* aka max-width 1280px */

    background-color: ${({isLightTheme}) => isLightTheme ? athensGray : ironStone};

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        --offers-page__row-padding-top: 140px;
        --offers-page__row-padding-bottom: 112px;
    `}
`;

export const DeviceCarouselWrapper = styled(Row).attrs({className: 'offers-page__row--device-carousel'})`
    background-color: ${({isLightTheme}) => isLightTheme ? athensGray : ironStone};

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        --offers-page__row-padding-horizontal: var(--offers-page__row-padding-horizontal-thin-content);
    `}
`;

export const PictureCarouselWrapper = styled(Row).attrs({className: 'offers-page__row--picture-carousel'})`
    --offers-page__row-padding-top: 0;
    --offers-page__row-padding-bottom: 85px;
    --offers-page__row-padding-horizontal: 0;

    background-color: ${({isLightTheme}) => isLightTheme ? white : midnight};

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        --offers-page__row-padding-top: 0;
        --offers-page__row-padding-bottom: 85px;
    `}
`;

export const FaqsWrapper = styled(Row).attrs({className: 'offers-page__row--faqs'})`
    background-color: ${({isLightTheme}) => isLightTheme ? athensGray : ironStone};

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        --offers-page__row-padding-horizontal: var(--offers-page__row-padding-horizontal-thin-content);
    `}
`;

export const MarketingBlockWrapper = styled(Row).attrs({className: 'offers-page__row--marketing'})`
    --offers-page__row-padding-top: 112px;

    background-color: ${({isLightTheme}) => isLightTheme ? athensGray : ironStone};

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        --offers-page__row-padding-top: 112px;
        --offers-page__row-padding-horizontal: var(--offers-page__row-padding-horizontal-thin-content);
    `}
`;

export const MarketingLinkWrapper = styled(Row).attrs({className: 'offers-page__row--marketing-link'})`
    text-align: center;
`;

export const AppStoreLinksWrapper = styled(Row).attrs({className: 'offers-page__row--app-store-links'})`
    background-color: ${({isLightTheme}) => isLightTheme ? athensGray : ironStone};

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        --offers-page__row-padding-horizontal: var(--offers-page__row-padding-horizontal-thin-content);
    `}
`;

const OffersPage = ({children, footer}) => (
    <TemplateWrapper>
        <Main>
            {children}
        </Main>
        {footer}
    </TemplateWrapper>
);

OffersPage.displayName = 'OffersPage';

OffersPage.propTypes = {
    children: propTypes.node,
    footer: propTypes.node,
};

export default OffersPage;
