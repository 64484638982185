import React, {useEffect, useRef, useState} from 'react';
import propTypes from 'prop-types';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import styled from 'styled-components';
import {A, Nav} from 'normalized-styled-components';
import classnames from 'classnames';
import get from 'lodash/get';
import invoke from 'lodash/invoke';
import noop from 'lodash/noop';
import property from 'lodash/property';
import {rgba} from 'polished';

import {stylesWhen, stylesWhenNot, mediaQuery, stylesIfElse} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import theme from '../../../../common/theme-color';
import {transition} from '../../../../common/animations';
import {blanc, onyx} from '../../../../common/palette';
import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';

const CarouselWrapper = styled(Nav)`
    position: relative;
    overflow: hidden;
    /* Don’t use palette onyx in case the colour changes from #000.
       This is a mask and we’re playing with black and transparent as opacity placeholders. */
    /* stylelint-disable-next-line color-no-hex */
    mask-image: linear-gradient(to right, transparent, #000 35px, #000 calc(100% - 35px), transparent);
`;

const StyledDivWhichShouldBeLi = styled.div`
    display: flex;
    position: relative;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        margin: 0 14px;

        ${stylesWhen('isSelected')`
            margin: 0 7px;
        `}
    `};

    &::before,
    &::after {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 14px;
        content: '';

        ${stylesWhenNot('isSelected')`
            transform: scaleX(0);
        `}
    }

    &::before {
        left: -14px;
        background: linear-gradient(to left, ${rgba(onyx, 0.1)}, transparent);
    }

    &::after {
        right: -14px;
        background: linear-gradient(to right, ${rgba(onyx, 0.1)}, transparent);
    }
`;

const StyledUl = styled.ul`
    display: flex;
    margin: 0;
    background: linear-gradient(to right, transparent, ${rgba(blanc, 0.05)} 50%, transparent);
    width: 100%;
    overflow-x: scroll;
    list-style: none;
    scroll-snap-type: x mandatory;
    scroll-snap-stop: always;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::before,
    &::after {
        min-width: 50%;
        content: '';
        pointer-events: none;
        scroll-snap-align: none;
    }

    &::-webkit-scrollbar {
        display: none;
    }
`;

const NavItemAnchor = styled(A)`
    appearance: none;
    display: flex;
    position: relative;
    align-items: center;
    transition: ${transition('color', 'text-shadow', 'background-color')};
    outline: 0;
    border: 0;
    background: transparent;
    padding: 0 21px;
    max-width: 168px;
    height: 56px;
    overflow: hidden;
    text-transform: uppercase;
    text-decoration: none;
    text-shadow: 0 1px 1px ${rgba(onyx, 0.3)};
    text-overflow: ellipsis;
    white-space: nowrap;
    font: var(--nucleus-body-copy-3);
    scroll-snap-align: center;

    ${stylesIfElse('isSelected')`
        color: ${blanc};
        background-color: ${theme.secondaryColor};
        font: var(--nucleus-body-copy-3-bold);
    ``
        text-shadow: none;
        color: ${rgba(blanc, 0.4)};
        font: var(--nucleus-body-copy-3-light);
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding: 0 28px;
        height: 77px;
    `}

    &:hover,
    &:focus {
        text-shadow: 0 1px 1px ${rgba(onyx, 0.3)};
        color: ${blanc};
    }

    &::after {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        transition: ${transition('transform')};
        border-radius: 1px;
        background-color: ${blanc};
        height: 2px;
        content: '';

        ${stylesWhenNot('isSelected')`
            transform: scaleX(0);
        `}
    }
`;

const StyledText = styled.span`
    display: block;
    overflow-x: hidden;
    /* stylelint-disable-next-line color-no-hex */
    mask-image: linear-gradient(to right, #000 145px, transparent);
`;

export default function OR68CatNav({
    items = [],
    onSelectItem = noop,
    selectedItemValue,
    className,
}) {
    const selectedItemIndex = items.findIndex(({href}) => href === selectedItemValue);
    const [hasDoneFirstScroll, setHasDoneFirstScroll] = useState(false);
    const [focusedItemIndex, setFocusedItemIndex] = useState(selectedItemIndex);
    const previousItemRef = useRef(null);
    const focusedItemRef = useRef(null);
    const nextItemRef = useRef(null);
    const isAnimated = useRef(true);

    const refs = [previousItemRef, focusedItemRef, nextItemRef];

    useEffect(() => {
        isAnimated.current = !window.matchMedia('(prefers-reduced-motion: reduce), (update: slow)').matches;
    }, []);

    useEffect(() => {
        const currentRef = get(focusedItemRef, 'current');

        if (!currentRef) {
            return;
        }

        // Firefox interrupts my smooth scrolling without await
        scrollIntoView(currentRef, {
            behavior: hasDoneFirstScroll && isAnimated.current ? 'smooth' : 'auto',
            block: 'nearest',
            inline: 'center',
        })
            .then(() => {
                if (!hasDoneFirstScroll) {
                    setHasDoneFirstScroll(true);
                }
            });
    }, [focusedItemIndex, hasDoneFirstScroll]);

    // When selectedItemIndex changes, set that guy as the focused item
    useEffect(() => {
        setFocusedItemIndex(selectedItemIndex);
    }, [selectedItemIndex]);

    return (
        <CarouselWrapper
            className={classnames('OR68CatNav', className)}
            onKeyDown={(e) => {
                if (e.key === 'ArrowLeft') {
                    e.preventDefault();
                    e.stopPropagation();
                    invoke(previousItemRef, 'current.focus');
                } else if (e.key === 'ArrowRight') {
                    e.preventDefault();
                    e.stopPropagation();
                    invoke(nextItemRef, 'current.focus');
                }
            }}
        >
            <StyledUl>
                {items
                    .filter(property('href'))
                    .map(({href, label}, index) => {
                        const isSelected = selectedItemIndex === index;

                        return (
                            // TODO: <ul> only permitted to have <li> as direct descendants: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/ul
                            <StyledDivWhichShouldBeLi isSelected={isSelected} key={href}>
                                <li key={label}>
                                    <NavItemAnchor
                                        onFocus={() => {
                                            // set focussed item for both keyboard & mouse selection
                                            setFocusedItemIndex(index);
                                        }}
                                        onKeyDown={(event) => {
                                            // only for keyboard selection
                                            if (event.key === 'Enter' || (event.key === ' ')) {
                                                onSelectItem({event, href, index, label});
                                            }
                                        }}
                                        onMouseDown={(event) => {
                                            // If you click too slowly, the item index changes and it begins to scroll, and onClick will never fire. So do it on mouse down.
                                            if (event.button === 0) { // check for left click
                                                onSelectItem({event, href, index, label});
                                            }
                                        }}
                                        onClick={(event) => {
                                            // prevent default, and let it be handled via onMouseDown
                                            event.preventDefault();
                                        }}
                                        tabIndex={isSelected ? 0 : -1}
                                        isSelected={isSelected}
                                        href={href}
                                        ref={refs[index - focusedItemIndex + 1] || null}
                                    >
                                        <StyledText>
                                            {label}
                                        </StyledText>
                                    </NavItemAnchor>
                                </li>
                            </StyledDivWhichShouldBeLi>
                        );
                    })}
            </StyledUl>
        </CarouselWrapper>
    );
}

OR68CatNav.displayName = 'OR68CatNav';

OR68CatNav.propTypes = {
    items: propTypes.arrayOf(propTypes.shape({
        href: propTypes.string,
        label: propTypes.string,
    })),
    onSelectItem: propTypes.func,
    selectedItemValue: propTypes.any,
    className: classNameType,
};
