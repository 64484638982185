import React from 'react';
import styled from 'styled-components';
import {rgba} from 'polished';
import propTypes from 'prop-types';
import {stylesWhen, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';

import {black, white} from '../../../../../common/palette';
import {transition} from '../../../../../common/animations';

const LowerControlsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transform: translateY(${({isVisible}) => isVisible ? 0 : 314}px);
    transition: ${transition('height', 'transform')};
    width: 100%;

    &::before {
        position: absolute;
        bottom: 0;
        background-image: linear-gradient(${rgba(black, 0)}, ${black} 60%);
        width: 100%;
        height: 100%;
        min-height: 160px;
        content: '';
        pointer-events: none;

        ${stylesWhen(({isSeeking, hasThumbnailCarousel}) => isSeeking && hasThumbnailCarousel)`
            background-image: linear-gradient(${rgba(black, 0.25)}, ${black} 80%);
        `}

        ${stylesWhen('isTabletUp')`
            min-height: 200px;
        `}
    }
`;

const ScrubberAndButtonRowContainer = styled.div`
    position: relative;

    ${stylesWhen('isMobileLandscapeUp')`
        margin: 0 35px 12px;
    `}

    ${stylesWhen('isReallyLargeDesktopUp')`
        margin: 0 181px 40px;
    `}
`;

const ButtonRow = styled.div`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;

    ${stylesWhen('isDesktopUp')`
        margin-top: 6px;
    `}

    ${stylesWhen('isReallyLargeDesktopUp')`
        padding: 0 10px;
    `}
`;

const ButtonRowLayoutItem = styled.div`
    display: flex;
    align-items: center;
`;

const Divider = styled.div`
    margin: 0 15px;
    border-right: 1px solid ${rgba(white, 0.25)};
    width: 0;
    height: 40px;
`;

const MiddleButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;

    ${stylesWhen('isDesktopUp')`
        flex-grow: 0.5;
    `}
`;

const MobileScrubberContainer = styled.div`
    position: relative;
    transform: translateY(${({isVisible}) => isVisible ? 0 : 30}px);
    width: 100%;
    height: 30px;
`;

const DisplayTimeContainer = styled.div`
    min-width: ${({isDesktopUp}) => isDesktopUp ? '180px' : '160px'};

    ${stylesWhenNot('isTabletUp')`
        /* Avoids awkward asymmetrical margins on mobile landscape size */
        margin-left: 7px;
    `}
`;

const VPOR01LowerControls = ({
    closedCaptionsButton,
    skipBackButton,
    skipForwardButton,
    playButton,
    playbackSpeedButton,
    goToLiveButton,
    volumeSlider,
    displayTime,
    fullscreenButton,
    scrubber,
    popOutPlayerButton,
    relatedVideosButton,
    toggleKeyMomentsButton,
    hoverThumbnail,
    thumbnailCarousel,

    isSeeking,
    isVisible,
    isMobileLandscapeUp,
    isTabletUp,
    isDesktopUp,
    isReallyLargeDesktopUp,
}) => {
    if (!isMobileLandscapeUp) {
        return (
            <MobileScrubberContainer isVisible={isVisible}>
                {displayTime}
                {scrubber}
            </MobileScrubberContainer>
        );
    }

    return (
        <LowerControlsContainer
            isSeeking={isSeeking}
            hasThumbnailCarousel={!!thumbnailCarousel}
            isVisible={isVisible}
            isTabletUp={isTabletUp}
            isDesktopUp={isDesktopUp}
            isReallyLargeDesktopUp={isReallyLargeDesktopUp}
            className="VPOR01LowerControls"
        >
            {thumbnailCarousel}
            <ScrubberAndButtonRowContainer
                isMobileLandscapeUp={isMobileLandscapeUp}
                isDesktopUp={isDesktopUp}
                isReallyLargeDesktopUp={isReallyLargeDesktopUp}
            >
                {hoverThumbnail}
                {scrubber}
                <ButtonRow isDesktopUp={isDesktopUp} isReallyLargeDesktopUp={isReallyLargeDesktopUp}>
                    <ButtonRowLayoutItem>
                        {!!isTabletUp && (
                            <React.Fragment>
                                {skipBackButton}
                                {playButton}
                                {skipForwardButton}
                            </React.Fragment>
                        )}
                        {playbackSpeedButton}
                        {goToLiveButton}
                        {!!isTabletUp && <Divider />}
                        {!!isMobileLandscapeUp && (
                            <React.Fragment>
                                {volumeSlider}
                                <Divider />
                            </React.Fragment>
                        )}
                        <DisplayTimeContainer isDesktopUp={isDesktopUp} isTabletUp={isTabletUp}>
                            {displayTime}
                        </DisplayTimeContainer>
                    </ButtonRowLayoutItem>
                    {!!isMobileLandscapeUp && (
                        <MiddleButtonsWrapper isDesktopUp={isDesktopUp}>
                            {!!isTabletUp && relatedVideosButton}
                            {!!toggleKeyMomentsButton && (
                                <React.Fragment>
                                    {!!isDesktopUp && (
                                        <Divider />
                                    )}
                                    {toggleKeyMomentsButton}
                                </React.Fragment>
                            )}
                        </MiddleButtonsWrapper>
                    )}
                    <ButtonRowLayoutItem>
                        {closedCaptionsButton}
                        {popOutPlayerButton}
                        {!!isDesktopUp && <Divider />}
                        {fullscreenButton}
                    </ButtonRowLayoutItem>
                </ButtonRow>
            </ScrubberAndButtonRowContainer>
        </LowerControlsContainer>
    );
};

VPOR01LowerControls.displayName = 'VPOR01LowerControls';

VPOR01LowerControls.propTypes = {
    closedCaptionsButton: propTypes.node,
    skipBackButton: propTypes.node,
    skipForwardButton: propTypes.node,
    playButton: propTypes.node,
    playbackSpeedButton: propTypes.node,
    goToLiveButton: propTypes.node,
    volumeSlider: propTypes.node,
    displayTime: propTypes.node,
    fullscreenButton: propTypes.node,
    scrubber: propTypes.node,
    popOutPlayerButton: propTypes.node,
    relatedVideosButton: propTypes.node,
    toggleKeyMomentsButton: propTypes.node,
    hoverThumbnail: propTypes.node,
    thumbnailCarousel: propTypes.node,

    /** determines if the thumbnails are visible */
    isSeeking: propTypes.bool,
    isVisible: propTypes.bool,
    isMobileLandscapeUp: propTypes.bool,
    /** faux media query decided by width of root container */
    isTabletUp: propTypes.bool,
    /** faux media query decided by width of root container */
    isDesktopUp: propTypes.bool,
    /** faux media query decided by width of root container */
    isReallyLargeDesktopUp: propTypes.bool,
};

export default VPOR01LowerControls;
