import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {Button} from 'normalized-styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {SCREEN_1280_DESKTOP, SCREEN_1920_DESKTOP, SCREEN_2560_DESKTOP} from '../../../../common/screen-sizes';
import IC24ArrowL from '../../ic/24-arrow-l';
import IC25ArrowR from '../../ic/25-arrow-r';
import {blanc, panther} from '../../../../common/palette';

export const NAV_BUTTON_WIDTH_PX = 56;

const CarouselNavButton = styled(Button)`
    appearance: none;
    box-sizing: border-box;
    display: flex;
    position: absolute; /* @TODO: position absolute should never be on the root of a component */
    top: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    transition: ${transition('color', 'opacity')};
    border: 0;
    background-color: transparent;
    padding: 0;
    width: ${NAV_BUTTON_WIDTH_PX}px;
    height: 100%;
    line-height: 1;
    color: ${({isLightTheme}) => isLightTheme ? rgba(panther, 0.3) : rgba(blanc, 0.3)};

    ${({position}) => css`
        ${position}: 0;

        padding-${position}: 7px;

        ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
            padding-${position}: 14px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
            padding-${position}: 7px;
        `}
    `}

    &:hover,
    &:active {
        color: ${({isLightTheme}) => isLightTheme ? panther : blanc};
    }

    &:focus {
        outline: 0;
    }

    &:disabled {
        pointer-events: none;
    }
`;

const label = {
    left: 'See previous items',
    right: 'See next items',
};

const LeftArrow = styled(IC24ArrowL)`
    position: relative;
`;

const RightArrow = styled(IC25ArrowR)`
    position: relative;
`;

const IconWrapper = styled.div`
    width: 30px;
    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 42px;
    `}
`;

const icon = {
    left: <LeftArrow />,
    right: <RightArrow />,
};

const BA26CarouselBtn = ({
    className,
    isActive,
    direction,
    isLightTheme,
    ...htmlAttributes
}) => (
    <CarouselNavButton
        {...htmlAttributes}
        isLightTheme={isLightTheme}
        aria-label={label[direction]}
        className={classnames('BA26CarouselBtn', className)}
        position={direction}
        tabIndex="-1"
    >
        {!!isActive && (
            <IconWrapper>
                {icon[direction]}
            </IconWrapper>
        )}
    </CarouselNavButton>
);

BA26CarouselBtn.displayName = 'BA26CarouselBtn';

BA26CarouselBtn.propTypes = {
    /** additional CSS classnames to be applied */
    className: classNameType,
    /** Whether or not this carousel button is in active state */
    isActive: propTypes.bool,
    /** This button's direction */
    direction: propTypes.oneOf(['left', 'right']),
    /** To determine the button colour for the light themed carousel */
    isLightTheme: propTypes.bool,
};

export default BA26CarouselBtn;
