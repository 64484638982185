import React from 'react';
import Ic from '..';

const IC246Edit = (args) => (
    <Ic {...args}>
        <path d="M68.991 48.893l-4.554-4.568L33.653 74.93l-9.247-9.247 30.882-30.59-4.568-4.555-35.077 34.951-4.65 23.02 23.02-4.651m-14.716-3.655l2.326-11.572 9.246 9.232-11.572 2.34zm50.83-60.074l9.246 9.232-4.18 4.125 4.568 4.582 8.748-8.693-18.382-18.382-8.735 8.693 4.568 4.568m-11.534 2.438l2.937-2.937 18.371 18.372-2.937 2.936z" />
    </Ic>
);

IC246Edit.displayName = 'IC246Edit';

export default IC246Edit;
