import React from 'react';
import Ic from '..';

const IC26ArrowU = (args) => (
    <Ic {...args}>
        <path fillRule="nonzero" d="M83.69 71.185 50.062 37.209 16.433 71.185l-5.51-5.566 39.139-39.543L89.199 65.62z" />
    </Ic>
);

IC26ArrowU.displayName = 'IC26ArrowU';

export default IC26ArrowU;
