import React from 'react';
import Ic from '..';

const IC161ChampStandings = (args) => (
    <Ic {...args}>
        <path fillRule="nonzero" d="M79.9 20.9l.5-2c.2-.8 0-1.7-.5-2.4s-1.3-1.1-2.2-1.1H22.4c-.8 0-1.6.4-2.1 1s-.7 1.5-.6 2.3l.4 2.1c-6.3 2.8-10 9.8-8.3 16.8 1.6 6.5 7.3 11 13.7 11.3.8 12.1 10.9 21.7 23.2 21.7v7.1h-8.8s-2.4 0-2.4 2.5v4.2h25.1v-4.2s0-2.5-2.4-2.5h-8.8v-7.2C62.9 69.2 72.6 59.9 73.5 49h.5c2.7 0 5.3-.7 7.7-2.2 3.3-2.1 5.7-5.3 6.6-9.1 1.7-7-2-14-8.4-16.8zM48.6 65.1c-9.8 0-17.7-8-17.7-17.7 0-.2 0-.4-.1-.5l-3.7-18.8h45.4l-4.4 18.7c0 .2-.1.4-.1.6 0 9.5-9 17.7-19.4 17.7zm24.5-39.8H26.6l-.8-4.3h48.4l-1.1 4.3zM15.8 36.8c-1.1-4.7 1.1-9.4 5.1-11.6l3.9 19.7c-4.3-.6-8-3.7-9-8.1zm68.4 0c-.7 2.7-2.3 5.1-4.7 6.5-1.6 1-3.4 1.5-5.2 1.6L78.9 25c4.2 2.2 6.5 7 5.3 11.8z" />
    </Ic>
);

IC161ChampStandings.displayName = 'IC161ChampStandings';

export default IC161ChampStandings;
