import padEnd from 'lodash/padEnd';

const TERM_TYPE_SHORT = {
    day: 'day',
    week: 'wk',
    month: 'mth',
    annual: 'yr',

    daily: 'day',
    weekly: 'wk',
    monthly: 'mth',
    annually: 'yr',
};

/**
 * Get term type in shorten form
 *
 * @param {string} termType long form of term type exp: 'day' | 'week' | 'month' | 'annual'
 * @returns {string} shorten form of term type
 */
export const getShortTermTypeText = (termType) => TERM_TYPE_SHORT[termType?.toLowerCase()]
    ? `/${TERM_TYPE_SHORT[termType?.toLowerCase()]}`
    : '';

/**
 * Split a price into its whole (e.g. dollars) and partial (e.g. cents) components.
 * - If the price is integer, only the whole amount will be returned.
 * - If the price is not an integer, the whole amount and the partial amount to the specified number of decimal places will be returned.
 * - If the specified number of decimal places is zero, only the whole amount will be returned.
 *
 * There is no rounding up.
 *
 * This returns the results as an array of strings to be used for display purposes.
 *
 * @param {number} [price=0]     - the price to display
 * @param {number} decimalPlaces - the number of decimal places
 * @param {number} includeZero   - show `00` even if it's a whole number
 * @returns {string[]} the stringified price components as described above
 */
export const splitPrice = (price = 0, decimalPlaces, includeZero) => {
    const [units, fraction] = price.toString().split('.');

    return decimalPlaces && (parseInt(fraction) || includeZero)
        ? [units, padEnd(fraction, decimalPlaces, '0').slice(0, decimalPlaces)]
        : [units];
};
