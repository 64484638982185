/**
 * Extended error instance for dealing with Token Service API errors
 */
export class TokenServiceApiError extends Error {
    /**
     * @param {string} message - Message associated with this error
     * @param {number} [status] - Optional status associated with this error
     */
    constructor(message, status) {
        super();

        this.message = message;
        this.status = status || 500;
    }
}

/**
 * Recoverable errors are errors that won't force-logout the user.
 *
 * @type {Object.<string, string>} - Map of recoverable error reasons
 */
export const RECOVERABLE_ERRORS = {
    ERROR_NETWORK_OFFLINE: 'Token Service unreachable',
};

/**
 * Unrecoverable errors are errors that will force-logout the user
 *
 * @type {Object.<string, string>} - Map of unrecoverable error reasons
 */
export const UNRECOVERABLE_ERRORS = {
    ERROR_FIELDS_MISSING_FROM_RESPONSE: 'Required fields missing from token response',
    ERROR_UNKNOWN: 'Received non-200 response code',
};

const MAPPED_ERRORS = [
    {
        test: (message) => message.includes('the network is offline'),
        reason: RECOVERABLE_ERRORS.ERROR_NETWORK_OFFLINE,
    },
];

/**
 * Maps a known message from Token Service API or defaults to an unknown error if no message is provided
 *
 * @param {Error | {response: Error}} error - The error thrown
 * @returns {string} -
 */
export const mapErrorToReason = (error) => {
    const {message} = error.response?.error || error || {};

    let reason = UNRECOVERABLE_ERRORS.ERROR_UNKNOWN;

    if (message) {
        ({reason} = MAPPED_ERRORS.find(({test}) => test(message)) || {reason: message});
    }

    return reason;
};

/**
 * Allows us to determine whether an error originates from
 * the Token Service API, and that the error reason matches one that
 * we deem unrecoverable.
 *
 * @param {Error | TokenServiceApiError} error - The error thrown
 * @returns {boolean} -
 */
export const isUnrecoverableError = (error) => (
    error
    && error instanceof TokenServiceApiError
    && Object.values(UNRECOVERABLE_ERRORS).includes(error.message)
);
