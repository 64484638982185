import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import {Button} from 'normalized-styled-components';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {white} from '../../../../common/palette';
import IC42Error from '../../../atoms/ic/42-error';
import IC191Gift from '../../../atoms/ic/191-gift';
import GM01IconMsg from '../../../molecules/gm/01-icon-msg';
import OR121TooltipCta from '../121-tooltip-cta';

const MsgContainer = styled.div`
    display: grid;
    position: relative;
    grid-template-columns: 1fr auto;
    grid-gap: 7px;
    color: ${white};
`;

const StyledOR121TooltipCta = styled(OR121TooltipCta)`
    padding-right: 14px;
`;

const HelpButton = styled(Button).attrs(({helpButtonLabel}) => ({
    'aria-label': helpButtonLabel,
    'title': helpButtonLabel,
}))`
    appearance: none;
    border: 0;
    background: none;
    padding: 0;
    color: inherit;
`;

const OR119NoGift = ({
    contextualHelp,
    className,
    ...htmlAttributes
}) => (
    <MsgContainer className={classnames('OR119NoGift', className)}>
        <GM01IconMsg
            icon={<IC191Gift size="21px" />}
            {...htmlAttributes}
        >
            Gift Card unavailable
        </GM01IconMsg>
        {!!contextualHelp && (
            <StyledOR121TooltipCta
                cta={(
                    <HelpButton
                        helpButtonLabel="Why is gift card redemption unavailable?"
                    >
                        <IC42Error size="21px" />
                    </HelpButton>
                )}
                arrowOrientation="right"
                isLightTheme={true}
                isFullWidthTooltip={true}
            >
                {contextualHelp}
            </StyledOR121TooltipCta>
        )}
    </MsgContainer>
);

OR119NoGift.displayName = 'OR119NoGift';

OR119NoGift.propTypes = {
    /** Represents a validation warning, with contextual help */
    contextualHelp: propTypes.node,
    /** Additional CSS class(es) */
    className: classNameType,
};

export default OR119NoGift;
