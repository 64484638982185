import bacon from 'baconjs';
import {createRef} from 'react';
import {muid} from '@fsa-streamotion/streamotion-web-ionic';
import {trackFromAdobeDefinitions} from '../../../../todo-move-to-widgets-common/utils/adobe';

const RESOURCES_HOSTNAME = 'https://resources.kayosports.com.au';

export default function getVoucherDisplayPropsStream({
    clientSideVoucher$,
    userEnteredVoucherBus,
    offer$,
    prefetchedOffer$,
    hydration = {},
}) {
    const inputId = hydration.inputId || muid();
    const refForOR12VchInp = createRef();
    const voucherInputChangeBus = new bacon.Bus();
    const expandBus = new bacon.Bus();
    const voucherRemoveBus = new bacon.Bus();

    // The offer page can load with one offer, and then go get another offer (yay local storage).
    // We need to know what offer we're loading on for the isVoucherRequiredTemplate$.
    // So the moment we see a voucher entered, it's valid, that next offer saying 'voucherRequired'
    // is invalid to us. We don't change the layout.
    // But we still need to listen to marketing loading and taking us to a different offer.
    const isVoucherAccepted$ = offer$
        .map(({voucher, error}) => {
            const voucherLabel = voucher?.code;
            const errorCode = error?.code;
            const isVoucherAccepted = !errorCode && voucherLabel;

            if (isVoucherAccepted) {
                trackFromAdobeDefinitions({
                    definitionsPath: 'eventTracking.signUp.voucherSuccess',
                    eventTypePayloadKey: 'data.event.name',
                    templateValues: {voucherLabel},
                });
            }

            return isVoucherAccepted;
        })
        .toProperty();

    // Only setup the page of the offer we're prefetched for. Not an offer that's loaded with say a voucher applied on boot.
    const isVoucherRequiredTemplate$ = prefetchedOffer$
        .take(1)
        .map('.offer.voucherRequired')
        .startWith(false);

    // Voucher Images
    // * Mobile Image Size.
    //   'Fit' smallest view is 306px by whatever height.
    //   Should go for 5x size (we'll now reduce as needed)
    //   Image Width Size 1530px minimum.
    // * Desktop Image Size
    //   'Fit' largest view is 850px by whatever height.
    //   Should go for 5x size (we'll now reduce as needed)
    //   Image Width Size 4250px minimum.
    const voucherImages$ = offer$
        .map((offerDetails) => {
            // sometimes null, sometimes empty shape of...
            const displayHeader = offerDetails?.displayHeader || '';
            const displaySubtext = offerDetails?.displaySubtext || '';
            const altText = offerDetails?.imageAltText || `${displayHeader} ${displaySubtext}`.trim();
            const mobileImage = offerDetails?.voucher?.imageLinkMobile;
            const desktopImage = offerDetails?.voucher?.imageLinkDesktop;

            return {
                imageAltText: altText,
                imageLinkMobile: mobileImage && `${RESOURCES_HOSTNAME}${mobileImage}`,
                imageLinkDesktop: desktopImage && `${RESOURCES_HOSTNAME}${desktopImage}`,
            };
        })
        .startWith({});

    // In order for us to know _this_ offer$ value is related to us as a voucher error, we have to pair with clientSideVoucher$.
    // This is because offer$ can be an error when first fetched (offer-not-found for example).  So make sure to pair up with the clientSideVoucher$.
    // The first offer$ event will either be the prefetched server side, played back, OR the user's personalised version. But it'll always start with 1.
    const voucherSubmittedReturned$ = bacon.zipWith(clientSideVoucher$, offer$.skip(1), () => true);

    const offerErrorObject$ = offer$
        .map('.error');

    const errorMessage$ = bacon.mergeAll(
        voucherInputChangeBus.map(null),
        offerErrorObject$
            .sampledBy(voucherSubmittedReturned$.filter(Boolean)) // Only take error messages if we have a voucher being submitted.
            .map('.detail'),

        // If the offer API wets the bed, display something generic.
        offer$
            .errors()
            .mapError('There was an error in checking your voucher. Please try again later.')
    ).toProperty(null);

    const isExpanded$ = bacon.mergeAll(
        isVoucherRequiredTemplate$,
        isVoucherAccepted$.filter(Boolean).map(false), // Close the offer when we're accepted.
        expandBus,
        voucherRemoveBus.map(false), // always close if we were open.
        errorMessage$.filter(Boolean).map(true), // we get an error message (specifically on load) open us, so we see the message.

        // If the submit is hit and same voucher as one we've accepted, just close the window.
        isVoucherAccepted$
            .combine(userEnteredVoucherBus, (previouslyAcceptedVoucher, newVoucher) => previouslyAcceptedVoucher === newVoucher)
            .filter(Boolean)
            .map(false)
    );

    // The expand button text....
    const headingText$ = bacon.mergeAll(
        isVoucherRequiredTemplate$.map((voucherRequired) => voucherRequired ? 'Unlock Offers' : 'Got a voucher?'),
        isVoucherRequiredTemplate$
            .sampledBy(voucherRemoveBus)
            .map((voucherRequired) => voucherRequired ? 'Unlock Offers' : 'Got a voucher?'),

        isVoucherAccepted$.filter(Boolean).map(() => 'Voucher Accepted!')
    );

    // Show 'Got a voucher?'/unlock button
    const hasExpandButton$ = isExpanded$.not();

    // After expanded, what's the submit button say.
    const submitText$ = isVoucherRequiredTemplate$
        .map((isVoucherRequired) => isVoucherRequired ? 'Unlock Offers' : 'Submit');

    const isSubmitDisabled$ = bacon.mergeAll(
        voucherSubmittedReturned$.map(false),
        userEnteredVoucherBus.skipDuplicates().map(true), // stop disabling us if we just send same thing.

        errorMessage$.map(false),
    );

    // Don't show remove voucher unless voucher isn't accepted,
    // or user explicitly removes it (saves waiting for API before updating UI for the button)
    const showRemoveVoucher$ = bacon.mergeAll(
        voucherRemoveBus.map(false),
        isVoucherAccepted$,
    )
        .skipDuplicates()
        .toProperty(false);

    return bacon.combineTemplate({
        props: {
            isVoucherRequiredTemplate: isVoucherRequiredTemplate$,
            voucherImages: voucherImages$,
            showRemoveVoucher: showRemoveVoucher$,

            inputId, // uniqueId for hydration
            voucherCode: clientSideVoucher$, // defaultValue

            headingText: headingText$,
            submitText: submitText$,

            // disabled={voucherDisplay.isVoucherLoading}
            hasExpandButton: hasExpandButton$,
            isExpanded: isExpanded$,

            // label // 'Enter your voucher code',
            inputName: 'voucher', // needed to get a value on submit, vs monitoring changeBus.
            // errorIcon, // <IC42Error color={white} />
            errorMessage: errorMessage$,

            isSubmitDisabled: isSubmitDisabled$,

            // These are for marketing blocks above the voucher.
            // displaySubtext,
            // displayHeader,

            onExpand: () => void expandBus.push(true),
            onSubmit: (voucherCode) => {
                if (voucherCode) {
                    userEnteredVoucherBus.push(voucherCode);
                } else {
                    expandBus.push(false);
                }
            },
            onChangeVoucherCode: () => voucherInputChangeBus.push(true),
            onRemoveVoucher: () => {
                voucherRemoveBus.push(true); // collapse and things.
                userEnteredVoucherBus.push('');     // will only update offers if there was a previous voucher.
                if (refForOR12VchInp?.current) {
                    refForOR12VchInp.current.value = '';
                }
            },
            ref: refForOR12VchInp,
        },
        hydration: {
            inputId,
        },
    }).startWith({
        props: {inputId},
        hydration: {inputId},
    });
}
