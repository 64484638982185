import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';

import {CONTENT_EDGE_SPACING_PERCENT} from '../../../../../common/style-constants';

import {ZOOM_ALLOWANCE_PX, Z_INDEX_FILMSTRIP, SCROLL_DURATION_MS, VIEWPORT_PERC} from './constants';
import CarouselSlot from './carousel-slot';

/**
 *  This is a fake slide to trick users into believing this carousel goes forever
 */
const FirstSlideTeaser = styled(CarouselSlot).attrs({
    'aria-hidden': true,
})`
    position: relative;
    flex: unset;
    border-right: 0;
    width: unset;
    min-width: calc(${CONTENT_EDGE_SPACING_PERCENT}% - var(--cam-01-stand-gutter));
    overflow: hidden;
    pointer-events: none;
`;

const FirstSlideTeaserInner = styled.div`
    box-sizing: border-box;
    border-right: var(--cam-01-stand-gutter) solid transparent;
`;

const LastSlideTeaser = styled(CarouselSlot).attrs({
    'aria-hidden': true,
    'isBeingLazy': false,
    'tabIndex': -1,
})`
    position: absolute;
    transform: translateX(-100%);
    margin-left: ${CONTENT_EDGE_SPACING_PERCENT}%;
    pointer-events: none;
`;

const StyledOl = styled.ol`
    -webkit-overflow-scrolling: touch;
    display: inline-flex;
    position: relative;
    transition: transform ${SCROLL_DURATION_MS}ms ease-out;
    z-index: ${Z_INDEX_FILMSTRIP};
    margin: ${ZOOM_ALLOWANCE_PX * -1}px 0;
    padding: ${ZOOM_ALLOWANCE_PX}px 0;
    width: 100%;
    overflow-x: scroll;
    list-style: none;

    ${stylesWhenNot('hasNextHint')`
        &::after {
            display: block;
            min-width: calc(${CONTENT_EDGE_SPACING_PERCENT}% - var(--cam-01-stand-gutter));
            content: '';
        }
    `}

    &::before {
        display: block;
        min-width: ${CONTENT_EDGE_SPACING_PERCENT}%;
        content: '';
    }

    @supports (
        (scroll-snap-align: start)
        and (not (-webkit-overflow-scrolling: touch)) /* Unfortunately scroll snap seems to cause jitter on iOS devices when combined with lazy loading. This is a crude way of disabling it */
    ) {
        scroll-snap-type: x mandatory;
        scroll-padding: ${CONTENT_EDGE_SPACING_PERCENT}%;
    }

    scrollbar-width: none; /* stylelint-disable-line */
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;

const FilmStrip = React.forwardRef(({
    children,
    firstSlideTeaser,
    hasNextHint,
    hasPrevHint,
    lastSlideTeaser,
    onScroll,
    carouselWidthPx,
    ...htmlAttributes
}, ref) => (
    <StyledOl
        {...htmlAttributes}
        hasNextHint={hasNextHint}
        ref={ref}
        onScroll={onScroll}
    >
        {!!hasPrevHint && !!lastSlideTeaser && (
            <LastSlideTeaser>
                {lastSlideTeaser}
            </LastSlideTeaser>
        )}
        {children}
        {!!hasNextHint && !!firstSlideTeaser && (
            <FirstSlideTeaser>
                <FirstSlideTeaserInner
                    style={{
                        minWidth: `calc((${VIEWPORT_PERC} / 100) * ${carouselWidthPx}px / var(--cam-01-tiles-per-row) + (var(--cam-01-stand-gutter) / var(--cam-01-tiles-per-row)))`,
                    }}
                >
                    {React.cloneElement(
                        firstSlideTeaser,
                        {isBeingLazy: false, tabIndex: -1},
                    )}
                </FirstSlideTeaserInner>
            </FirstSlideTeaser>
        )}
    </StyledOl>
));

FilmStrip.propTypes = {
    carouselWidthPx: propTypes.number,
    children: propTypes.node,
    hasNextHint: propTypes.bool,
    hasPrevHint: propTypes.bool,
    onScroll: propTypes.func,
    firstSlideTeaser: propTypes.element,
    lastSlideTeaser: propTypes.element,
};

FilmStrip.displayName = 'FilmStrip';

export default FilmStrip;
