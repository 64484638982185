import React from 'react';
import Ic from '..';

const IC02Sea = (args) => (
    <Ic {...args}>
        <path d="M97.5 93.7L81.2 77.3C87.5 70 91.4 60.5 91.4 50c0-23-18.7-41.7-41.7-41.7S8 27 8 50c0 23 18.7 41.7 41.7 41.7 10.6 0 20.2-3.9 27.5-10.4l16.3 16.3 4-3.9zm-47.8-7.6c-19.9 0-36.1-16.2-36.1-36.1s16.2-36.1 36.1-36.1S85.8 30.1 85.8 50 69.6 86.1 49.7 86.1z" />
    </Ic>
);

IC02Sea.displayName = 'IC02Sea';

export default IC02Sea;
