import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {classNameType, srcsetOptionsType} from '@fsa-streamotion/custom-prop-types';

import SrcsetImg from '../../../../common/srcset-img';

const DEFAULT_LOGO_URL = 'https://resources.streamotion.com.au/production/binge/brand-logos/binge-on-white.png';
const DEFAULT_SIZES_AT_VIEWPORTS_PX = { // {screensize: img-width}
    415: 320,
    768: 415,
    1024: 768,
};
const DEFAULT_SRCSET_OPTIONS = generateSrcSetOption({src: DEFAULT_LOGO_URL});

const ImageWrapper = styled.figure`
    display: inline-block;
    margin: 0;
`;

const StyledSrcsetImage = styled(SrcsetImg)`
    display: inline-block;
`;

const GA149WordmarkOnWhite = ({
    className,
    height,
    width = '100%',
    sizesDefinition = DEFAULT_SIZES_AT_VIEWPORTS_PX,
    srcsetOptions = DEFAULT_SRCSET_OPTIONS,
    title = 'Binge',
    ...htmlAttributes
}) => (
    <ImageWrapper
        className={classnames('GA149WordmarkOnWhite', className)}
        title={title}
        {...htmlAttributes}
    >
        <StyledSrcsetImage
            alt={title}
            sizesDefinition={sizesDefinition}
            srcsetOptions={srcsetOptions}
            style={{
                width,
                height,
            }}
        />
    </ImageWrapper>
);

GA149WordmarkOnWhite.displayName = 'GA149WordmarkOnWhite';

GA149WordmarkOnWhite.propTypes = {
    /** Additional CSS classname(s) to use */
    className: classNameType,
    /** Accessible label for logo & tooltip on mouse hover */
    title: propTypes.string,
    /** Optional width of logo */
    width: propTypes.string,
    /** Optional height of logo */
    height: propTypes.string,
    /** Srcset to use for the brand logo */
    srcsetOptions: srcsetOptionsType,
    /** Object with key:value pairs corresponding to viewport:elementWidth pixel values (works with srcsetOptions) */
    sizesDefinition: propTypes.objectOf(propTypes.number),
};

export default GA149WordmarkOnWhite;

/**
 * Generate srcset option with ?imwidth (in conjunction with akamai image manager)
 *
 * @param {string} options.src image src url
 * @param {Array[Number]} options.sizes array of image width sizes
 *
 * @returns {Object} Object with key:value pairs corresponding to widthpx:imageUrlWithAkamaiImWidth
 */
export function generateSrcSetOption({src, sizes = Object.values(DEFAULT_SIZES_AT_VIEWPORTS_PX)}) {
    return sizes.reduce((acc, size) => ({
        ...acc,
        [size]: `${src}?imwidth=${size}`,
    }), {});
}
