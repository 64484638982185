import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';
import {silverLining} from '../../../../common/palette';

const Container = styled.div`
    color: ${silverLining};
`;

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const MainScore = styled(FlexRow)`
    margin-bottom: 14px;
`;

const BigScore = styled.div`
    width: 80px;
    font: var(--mui-hud-large-score);
`;

const RightAlignedScore = styled(BigScore)`
    text-align: right;
`;

const CentreBlock = styled.div`
    width: 108px;
    text-align: center;
`;

const MatchStatus = styled.div`
    font: var(--mui-hud-tile-copy-light);

    ${stylesWhen('isShiftedDown')`
        margin-top: 9px;
    `}
`;

const NormalText = styled.div`
    font: var(--mui-hud-tile-copy);
`;

const Title = styled.h3`
    margin: 14px 0 21px;
    text-align: center;
    color: ${silverLining};
    font: var(--mui-hud-tile-header-bold);
`;

const SBM13PrevEnc = ({
    title,
    venue,
    teamAScore,
    teamBScore,
    matchStatus,
    matchTime,
    className,
}) => (
    <Container className={classnames('SBM13PrevEnc', className)}>
        <Title>
            {title}
            <br />
            {venue}
        </Title>
        <MainScore>
            {(!!teamAScore || teamAScore === 0) && (
                <RightAlignedScore>
                    {teamAScore}
                </RightAlignedScore>
            )}
            <CentreBlock>
                {!!matchStatus && (
                    <MatchStatus isShiftedDown={!matchTime}>{matchStatus}</MatchStatus>
                )}
                {!!matchTime && (
                    <NormalText>{matchTime}</NormalText>
                )}
            </CentreBlock>
            {(!!teamBScore || teamBScore === 0) && (
                <BigScore>
                    {teamBScore}
                </BigScore>
            )}
        </MainScore>
    </Container>
);

SBM13PrevEnc.displayName = 'SBM13PrevEnc';

SBM13PrevEnc.propTypes = {
    /** The title for the match, e.g. Round 6, Sun 14 Apr */
    title: propTypes.string,
    /** The venue name, e.g. Marvel Stadium */
    venue: propTypes.string,
    /** Team A's score */
    teamAScore: propTypes.node,
    /** Team B's score */
    teamBScore: propTypes.node,
    /** The current match status */
    matchStatus: propTypes.string,
    /** The current match time. Empty if not in progress. */
    matchTime: propTypes.string,
    /** Optional extra class name(s) for the component */
    className: classNameType,
};

export default SBM13PrevEnc;
