import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {A, Button} from 'normalized-styled-components';
import {rgba} from 'polished';
import noop from 'lodash/noop';

import {mediaQuery, stylesIfElse} from '@fsa-streamotion/styled-component-helpers';
import {buttonType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {blanc, midnight} from '../../../../common/palette';
import {SCREEN_1680_DESKTOP, SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import IC31Tick from '../../ic/31-tick';

const CHECKMARK_SIZE_PX = 20;
const CHECKMARK_SIZE_PX_1920 = 23;
const FOCUS_BORDER_WIDTH_PX = 1;
const CHECKMARK_OFFSET_HOVER_PX = (CHECKMARK_SIZE_PX / 2) - (FOCUS_BORDER_WIDTH_PX / 2);
const CHECKMARK_OFFSET_HOVER_PX_1920 = (CHECKMARK_SIZE_PX_1920 / 2) - (FOCUS_BORDER_WIDTH_PX / 2);

const baseStyles = css`
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-items: stretch;
    transition: ${transition('background-color', 'color', 'border-color')};
    border: 1px solid ${rgba(blanc, 0.7)};
    background-color: transparent;
    padding: 9px 14px;
    min-height: 40px;
    color: ${rgba(blanc, 0.7)};
    font: var(--nucleus-primary-button-light);

    ${stylesIfElse('isBlock')`
        display: flex;
        width: 100%;
    ``
        display: inline-flex;
        min-width: 110px;

        ${mediaQuery({minWidthPx: SCREEN_1680_DESKTOP})`
            padding: 16px 21px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            min-width: 183px;
        `}
    `}

    ${mediaQuery({minWidthPx: SCREEN_1680_DESKTOP})`
        min-height: 50px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        min-height: 60px;
    `}
`;

const interactiveStyles = css`
    position: relative;

    &:hover,
    &:focus {
        outline: 0;
        border-color: ${blanc};
        color: ${blanc};
    }

    &:active,
    &[aria-pressed='true'],
    &[aria-current='true'] {
        background-color: ${blanc};
        color: ${midnight};
        font: var(--nucleus-primary-button);
    }

    &[aria-pressed='true']:focus,
    &[aria-pressed='true']:hover,
    &[aria-current='true']:focus,
    &[aria-current='true']:hover {
        border-color: transparent;
        background-color: ${rgba(blanc, 0.8)};
    }
`;

const StyledButton = styled(Button).attrs(({isPressed}) => ({
    'aria-pressed': isPressed,
}))`
    ${baseStyles}
    ${interactiveStyles}

    appearance: none;
`;

const StyledLink = styled(A).attrs(({isPressed}) => ({
    'aria-current': isPressed,
}))`
    ${baseStyles}
    ${interactiveStyles}

    text-decoration: none;
`;

const NonInteractive = styled.div`
    ${baseStyles}

    border-color: ${rgba(blanc, 0.2)};
    cursor: not-allowed;
    color: ${rgba(blanc, 0.3)};
`;

const StaticText = styled.span`
    display: block;
    padding-top: 5px;
    font-weight: 300;
`;

const Checkedmark = styled.span`
    position: absolute;
    top: var(--TR02SetTilDT-checkbox-offset, -${CHECKMARK_OFFSET_HOVER_PX}px);
    right: var(--TR02SetTilDT-checkbox-offset, -${CHECKMARK_OFFSET_HOVER_PX}px);
    transform: scale(${({isPressed}) => isPressed ? '100%' : 0});
    transition: ${transition('transform')};
    width: ${CHECKMARK_SIZE_PX}px;
    height: ${CHECKMARK_SIZE_PX}px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        top: var(--TR02SetTilDT-checkbox-offset-1920, -${CHECKMARK_OFFSET_HOVER_PX_1920}px);
        right: var(--TR02SetTilDT-checkbox-offset-1920, -${CHECKMARK_OFFSET_HOVER_PX_1920}px);
        width: ${CHECKMARK_SIZE_PX_1920}px;
        height: ${CHECKMARK_SIZE_PX_1920}px;
    `}
`;

const TR02SetTilDT = React.forwardRef(({
    children,
    subChildren,
    disabled,
    href,
    isBlock,
    isPressed,
    type = 'button',
    onClick,
    ...htmlAttributes
}, ref) => {
    const isNonInteractive = disabled || (!href && (!onClick || onClick === noop));
    const StyledElement = getCtaType({isNonInteractive, href, onClick});

    return (
        <StyledElement
            {...htmlAttributes}
            ref={ref}
            isBlock={isBlock}
            isPressed={isPressed}
            // We only want to put a type for buttons
            href={isNonInteractive ? undefined : href}
            onClick={isNonInteractive ? undefined : onClick}
            type={href || isNonInteractive ? undefined : type}
        >
            {children}
            {React.Children.map(subChildren, (child, index) => (
                <StaticText key={child.key || index}>{child}</StaticText>
            ))}
            <Checkedmark isPressed={isPressed}>
                <IC31Tick secondaryColor={blanc} />
            </Checkedmark>
        </StyledElement>
    );
});

TR02SetTilDT.propTypes = {
    /** Content of button, e.g. text */
    children: propTypes.node.isRequired,
    /** Children that go below main children, that do not bold on pressed */
    subChildren: propTypes.node,
    // eslint-disable-next-line react/boolean-prop-naming
    disabled: propTypes.bool,
    /* If it’s actually a link, provide a href */
    href: propTypes.string,
    /** Whether the button fills its parent's width or not */
    isBlock: propTypes.bool,
    /** Whether the button is currently selected */
    isPressed: propTypes.bool,
    /** The HTML type of the button */
    type: buttonType,
    /** Function to execute when button is clicked */
    onClick: propTypes.func,
};

function getCtaType({isNonInteractive, href, onClick}) {
    if (href && !isNonInteractive) {
        return StyledLink;
    } else if (onClick && !isNonInteractive) {
        return StyledButton;
    }

    return NonInteractive;
}

TR02SetTilDT.displayName = 'TR02SetTilDT';

export default TR02SetTilDT;
