import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import {hubblGreen} from '../../../../common/palette';

import Ic from '..';

const IC218XtraInfo = ({
    secondaryColor = hubblGreen,
    ...args
}) => (
    <Ic {...args}>
        <g fillRule="nonzero" transform="translate(7 8)">
            <circle cx="42.245" cy="42.245" r="42.245" fill={secondaryColor} />
            <path d="M41.895 25.68c-2.43-.124-4.31-2.153-4.228-4.562.083-2.408 2.099-4.307 4.53-4.267 2.433.039 4.385 2.002 4.388 4.411a4.378 4.378 0 0 1-1.38 3.215 4.464 4.464 0 0 1-3.31 1.204zm3.622 42.711h-7.224v-35.47l7.224-1.204v36.674z" />
        </g>
    </Ic>
);

IC218XtraInfo.propTypes = {
    /** The icon's secondary color */
    secondaryColor: colorType,
};

IC218XtraInfo.displayName = 'IC218XtraInfo';

export default IC218XtraInfo;
