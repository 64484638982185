import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {transition} from '../../../../../common/animations';
import VPGA03Thumbnail from '../../../../atoms/vp/ga/03-thumbnail';

const CONTAINER_HEIGHT = 165;
const getCenterThumbnailWidth = (width) => width + 10;

const Container = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    transition: ${transition('opacity')};
    background-color: transparent;
    width: 100%;
    height: ${CONTAINER_HEIGHT / 2}px;
    overflow: hidden;

    ${stylesWhen('isTabletUp')`
        height: ${CONTAINER_HEIGHT}px;
    `}

    &.enter {
        opacity: 0;
    }

    &.enter-active {
        opacity: 1;
    }

    &.exit {
        opacity: 1;
    }

    &.exit-active {
        opacity: 0;
    }
`;

const Thumbnails = styled.div`
    display: flex;
    flex-wrap: nowrap;
`;

const CenterThumbnail = styled.div`
    position: absolute;
`;

const ThumbnailCarouselView = ({
    urls = [],
    centerThumbnailTimeOffset = 0,
    isTabletUp = false,
    thumbnailWidth,
    thumbnailGap,
}) => {
    const widthOffset = centerThumbnailTimeOffset * thumbnailWidth;
    const centerUrl = urls[Math.floor(urls.length / 2)];

    return (
        <Container isTabletUp={isTabletUp}>
            <Thumbnails
                style={{
                    transform: `translateX(${-widthOffset}px)`,
                }}
            >
                {urls.map((url) => (
                    <VPGA03Thumbnail
                        key={url}
                        src={url}
                        thumbnailWidth={thumbnailWidth}
                        thumbnailGap={thumbnailGap}
                        isTabletUp={isTabletUp}
                    />
                ))}
            </Thumbnails>
            <CenterThumbnail>
                <VPGA03Thumbnail
                    src={centerUrl}
                    thumbnailWidth={getCenterThumbnailWidth(thumbnailWidth)}
                    isLarge={true}
                    isTabletUp={isTabletUp}
                />
            </CenterThumbnail>
        </Container>
    );
};

ThumbnailCarouselView.displayName = 'ThumbnailCarouselView';

ThumbnailCarouselView.propTypes = {
    urls: propTypes.arrayOf(propTypes.string),
    centerThumbnailTimeOffset: propTypes.number,
    isTabletUp: propTypes.bool,
    thumbnailGap: propTypes.number.isRequired,
    thumbnailWidth: propTypes.number.isRequired,
};

export default ThumbnailCarouselView;
