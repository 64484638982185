import React from 'react';

import Ic from '..';

const IC020Live = (args) => (
    <Ic {...args}>
        <g fillRule="nonzero">
            <path fill="#E20018" d="M13 29h73.042v43.25H13z" />
            <path fill="#DFDFDF" d="M37.692 56.864h-7.825V43.365h3.158v10.886h4.667zm5.38 0H39.9V43.365h3.172zm14.98-13.499-5.016 13.5h-3.019l-5.003-13.5h3.368l3.158 9.293 3.158-9.293zm10.844 13.499h-8.915V43.365h8.747v2.53h-5.59v2.794h5.283v2.53h-5.296v3.13h5.771z" />
        </g>
    </Ic>
);

IC020Live.displayName = 'IC020Live';

export default IC020Live;
