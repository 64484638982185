import React from 'react';
import Ic from '..';

const IC180ContextualBackRoku2 = (args) => (
    <Ic {...args}>
        <path d="M49.302 7C25.939 7 7 25.939 7 49.302c0 23.364 18.939 42.302 42.302 42.302s42.302-18.938 42.302-42.302C91.604 25.939 72.665 7 49.302 7m0 5.548c20.267 0 36.754 16.488 36.754 36.754 0 20.267-16.487 36.754-36.754 36.754-20.266 0-36.754-16.487-36.754-36.754 0-20.266 16.488-36.754 36.754-36.754" />
        <path fillRule="nonzero" d="M50.506 36.341v10.927h18.949a2.298 2.298 0 0 1 2.298 2.299v.866a2.298 2.298 0 0 1-2.298 2.299h-18.95v10.927L26.225 50l24.282-13.659z" />
    </Ic>
);

IC180ContextualBackRoku2.displayName = 'IC180ContextualBackRoku2';

export default IC180ContextualBackRoku2;
