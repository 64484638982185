import React from 'react';
import classnames from 'classnames';
import property from 'lodash/property';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {mediaQuery, stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {onyx} from '../../../../common/palette';
import {SCREEN_768_TABLET} from '../../../../common/screen-sizes';
import {CONTENT_EDGE_SPACING_PERCENT} from '../../../../common/style-constants';
import BA26CarouselBtn, {NAV_BUTTON_WIDTH_PX} from '../../../atoms/ba/26-carousel-btn';
import useFiniteCarousel from './use-finite-carousel';

/**
 * Generate mediaQueries for diff screensizes and item gap values provided
 *
 * @param {Object} options options
 * @param {Object} options.breakpointPageItemGapPxSizes object with shape `{breakpointSizeInPixels: gapPx}`
 * @returns {Array} Array of media queries containing item gap
 */
const getBreakpointPageItemGapPxSizesMediaQueries = ({breakpointPageItemGapPxSizes}) => (
    Object.entries(breakpointPageItemGapPxSizes || {})
        .map(([breakpointPx, gapPx]) => mediaQuery({minWidthPx: breakpointPx})`
                gap: ${gapPx}px;
            `)
);

/** ********************************************
 *                  WRAPPER
 ***********************************************/

const CarouselContainer = styled.section`
    display: flex;
    position: relative;
    overflow: hidden;

    ${stylesWhen('isCentered')`
        justify-content: center;
    `}

    @media (hover: hover) {
        ${stylesWhen('isLeftButtonVisible')`
            &:hover {
                .CAM03Finite__left-carousel-button {
                    opacity: 1;
                    pointer-events: auto;
                }
            }
        `}

        ${stylesWhen('isRightButtonVisible')`
            &:hover {
                .CAM03Finite__right-carousel-button {
                    opacity: 1;
                    pointer-events: auto;
                }
            }
        `}
    }
`;

/** ********************************************
 *                   BUTTONS
 ***********************************************/

const StyledBA26CarouselBtn = styled(BA26CarouselBtn)`
    position: absolute;
    top: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;

    .GA25CaGrad {
        display: none;
    }

    /* stylelint-disable order/order */
    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        ${stylesWhen('isVisible')`
            opacity: 1;
            pointer-events: auto;
        `}

        .GA25CaGrad {
            display: inherit;
        }
    `}
    /* stylelint-enable order/order */
`;

const LeftCarouselBtn = styled(StyledBA26CarouselBtn).attrs({
    direction: 'left',
    className: 'CAM03Finite__left-carousel-button',
})``;

const RightCarouselBtn = styled(StyledBA26CarouselBtn).attrs({
    direction: 'right',
    className: 'CAM03Finite__right-carousel-button',
})``;

/** ********************************************
 *                   LIST ITEMS
 ***********************************************/

const CarouselInnerContainer = styled.ul`
    display: flex;
    margin: 0;
    /* NOTE: 2% padding right added so right most child doesn't
     *  get stuck under right button in some cases.
     */
    padding: ${property('verticalPaddingPx')}px 2% ${property('verticalPaddingPx')}px 0;
    padding-inline-start: 0;

    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    -ms-overflow-style: none;
    scrollbar-width: none;

    list-style: none;
    ${getBreakpointPageItemGapPxSizesMediaQueries}

    ${stylesWhen('hasOuterGap')`
        padding-left: ${CONTENT_EDGE_SPACING_PERCENT}%;
        padding-right: ${CONTENT_EDGE_SPACING_PERCENT}%;
    `}

    ${stylesWhen(({fadeLeft, fadeRight}) => fadeLeft && !fadeRight)`
        mask-image: linear-gradient(to right, transparent, ${onyx} ${NAV_BUTTON_WIDTH_PX}px, ${onyx} 100%);
    `}

    ${stylesWhen(({fadeLeft, fadeRight}) => !fadeLeft && fadeRight)`
        mask-image: linear-gradient(to left, transparent, ${onyx} ${NAV_BUTTON_WIDTH_PX}px, ${onyx} 100%);
    `}

    ${stylesWhen(({fadeLeft, fadeRight}) => fadeLeft && fadeRight)`
        mask-image: linear-gradient(to right, transparent, ${onyx} ${NAV_BUTTON_WIDTH_PX}px, ${onyx} calc(100% - ${NAV_BUTTON_WIDTH_PX}px), transparent 100%);
    `}

    &::-webkit-scrollbar {
        display: none;
    }
`;

const CarouselItem = styled.li`
    grid-row: 1;
    margin: 0;
    padding: 0;
    object-fit: cover;
    height: 100%;
`;

/** ********************************************
 *                  MAIN
 ***********************************************/

const CAM03Finite = ({
    breakpointPageItemGapPxSizes = {
        1: 7, // same as binge, lifestyle has a constant gap for all viewports
    },
    children,
    className,
    hasOuterGap,
    initialFocusIndex = 0,
    isCentered,
    verticalPaddingPx,
    isLightTheme,
    ...htmlAttributes
}) => {
    const {
        hasItemsOnLeft,
        hasItemsOnRight,
        scrollLeft,
        scrollRight,
        carouselContainerRef,
    } = useFiniteCarousel({initialFocusIndex});

    return (
        <CarouselContainer
            {...htmlAttributes}
            className={classnames('CAM03Finite', className)}
            isLeftButtonVisible={hasItemsOnLeft}
            isRightButtonVisible={hasItemsOnRight}
            isCentered={isCentered}
        >
            <CarouselInnerContainer
                fadeLeft={hasItemsOnLeft}
                fadeRight={hasItemsOnRight}
                hasOuterGap={hasOuterGap}
                breakpointPageItemGapPxSizes={breakpointPageItemGapPxSizes}
                ref={carouselContainerRef}
                verticalPaddingPx={verticalPaddingPx}
            >
                {React.Children.map(children, (child) => (
                    <CarouselItem>{child}</CarouselItem>
                ))}
            </CarouselInnerContainer>

            <LeftCarouselBtn
                isActive={hasItemsOnLeft}
                isVisible={hasItemsOnLeft}
                onClick={scrollLeft}
                isLightTheme={isLightTheme}
            />

            <RightCarouselBtn
                isActive={hasItemsOnRight}
                isVisible={hasItemsOnRight}
                onClick={scrollRight}
                isLightTheme={isLightTheme}
            />
        </CarouselContainer>
    );
};

CAM03Finite.displayName = 'CAM03Finite';

CAM03Finite.propTypes = {
    /** Item gap px value for different screensizes */
    breakpointPageItemGapPxSizes: propTypes.objectOf(propTypes.number),
    /** Items to display */
    children: propTypes.node,
    /** Additional CSS classnames to be applied */
    className: classNameType,
    /**
     * Add gap on both ends of the carousel? <br>
     * _**NOTE:** Gap is computed_ `CONTENT_EDGE_SPACING_PERCENT`
     */
    hasOuterGap:  propTypes.bool,
    /** Index of element to scroll into view _(furthest left possible of carousel)_ */
    initialFocusIndex: propTypes.number,
    /** Are the items horizontally centered? */
    isCentered: propTypes.bool,
    /** Padding to be applied to the top and bottom of carousel inner container */
    verticalPaddingPx: propTypes.number,
    /** Is the carousel light or dark (default) themed? */
    isLightTheme: propTypes.bool,
};

export default CAM03Finite;
