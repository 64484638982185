import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

const StyledSvg = styled.svg`
    display: block;
    width: auto;
    height: 100%; /* Scaled by HEIGHT :O */
    fill: currentColor;
`;

const GA108Sd = ({
    className,
    ...htmlAttributes
}) => (
    <StyledSvg
        {...htmlAttributes}
        className={classnames('GA108Sd', className)}
        viewBox="0 0 167 100"
    >
        <path d="M167 0v100H0V0h167zM55.0813714 23.8095238c-6.1074285 0-10.9170285 1.6761905-13.8944 4.1904762-3.0537143 2.5142857-5.4966857 6.4761905-5.4966857 11.5047619 0 4.952381 1.8322286 8 4.1225143 10.1333333 2.2902857 2.2095238 4.9622857 3.2 6.2601143 3.8095238.7803936.3386244 1.9981093.7976485 3.3113321 1.2767392l4.7046679 1.6946894c2.2902857.8380953 6.1837714 2.2095238 6.1837714 5.7904762 0 3.5047619-3.0537143 5.4095238-7.0235428 5.4095238-4.0461715 0-9.9245715-2.6666666-13.7417143-6.6285714L33.4 69.9809524c4.2752 3.9619047 11.0697143 7.9238095 18.704 7.9238095 5.6493714 0 10.5353143-1.3714286 14.2761143-4.3428571 3.8171428-2.9714286 6.1837714-7.5428572 6.1837714-12.5714286 0-4.7238095-1.9085714-7.9238095-4.1225143-10.1333333-2.3666285-2.3619048-5.8784-3.7333334-7.3289143-4.2666667-1.4929269-.6095238-4.7351421-1.7608466-7.045744-2.6513032l-1.7882895-.7060668c-2.136944-.8886406-4.3732807-2.1740586-4.3732807-4.9473919 0-3.2 2.9010285-4.7238095 6.8708571-4.7238095 3.9698286 0 8.7030857 2.2857142 11.7568 5.3333333l5.2676571-8.6095238c-3.5881142-3.5809524-10.6116571-6.4761905-16.7190857-6.4761905zm45.7293716.6095238H82.9465143v52.5714286h17.8642287c9.161143 0 15.955657-2.5904762 20.3072-6.2476191 4.427886-3.7333333 8.5504-9.8285714 8.5504-20.0380952 0-10.2095238-4.122514-16.3047619-8.5504-19.9619048-4.351543-3.7333333-11.146057-6.3238095-20.3072-6.3238095zm.687086 10.2095238c5.114971 0 8.779428 1.6761905 10.993371 3.7333334 2.213943 2.1333333 4.656914 5.8666666 4.656914 12.3428571 0 6.4761905-2.442971 9.9809524-4.656914 12.0380952-2.213943 2.1333334-5.8784 3.8095239-10.993371 3.8095239h-6.2601147V34.6285714h6.2601147z" />
    </StyledSvg>
);

GA108Sd.propTypes = {
    /** Additional class(es) */
    className: classNameType,
};

GA108Sd.displayName = 'GA108Sd';

export default GA108Sd;
