import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

const StyledSection = styled.section`
    width: 100%;
`;

const OR02Stand = ({children}) => (
    <StyledSection className="OR02Stand">
        {children}
    </StyledSection>
);

OR02Stand.propTypes = {
    children: propTypes.node,
};

OR02Stand.displayName = 'OR02Stand';

export default OR02Stand;
