import React from 'react';

import Ic from '..';

const IC230ParentalControls = (args) => (
    <Ic {...args}>
        <path
            fillOpacity=".35"
            stroke="currentColor"
            strokeWidth="2.81"
            d="M73.015 37.823c1.94 0 3.696.786 4.968 2.057a7.003 7.003 0 0 1 2.057 4.968v33.464c0 1.94-.786 3.697-2.057 4.968a7.003 7.003 0 0 1-4.968 2.058H26.43a7.003 7.003 0 0 1-4.967-2.058 7.003 7.003 0 0 1-2.058-4.968V44.848c0-1.94.786-3.696 2.058-4.968a7.003 7.003 0 0 1 4.967-2.057z"
        />
        <path fillRule="nonzero" d="M71.384 36.836h-2.63c0-11.702-8.523-21.218-18.979-21.218s-18.991 9.516-18.991 21.218h-2.63C28.153 23.694 37.86 13 49.774 13c11.916 0 21.609 10.694 21.609 23.836zM44.505 58.942a6.058 6.058 0 0 0 2.801 6.54l.244.138v4.62c0 .516.421.936.941.936h3.946l.11-.006a.938.938 0 0 0 .83-.93v-4.652l.077-.04a6.077 6.077 0 0 0 3.093-5.251 6.079 6.079 0 0 0-5.3-6.03c-3.037-.397-5.899 1.509-6.688 4.454l-.054.221z" />
    </Ic>
);

IC230ParentalControls.displayName = 'IC230ParentalControls';

export default IC230ParentalControls;
