import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {SCREEN_1280_DESKTOP, SCREEN_768_TABLET, SCREEN_1920_DESKTOP} from '../../../../../common/screen-sizes';
import ScreenArea from './screen-area';

const CentreControls = styled.div`
    box-sizing: border-box;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    margin: 0 14px;
    height: 100%;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        margin: 0 40px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        margin: 0 70px;
    `}
`;

const MiddleScreenContainer = styled.div`
    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        display: none;
    `}
`;

const VPTM05CentreControls = ({
    className,
    topCentreControls,
    topLeftControls,
    topRightControls,
    middleCentreControls,
    middleLeftControls,
    middleRightControls,
    bottomCentreControls,
    bottomLeftControls,
    bottomRightControls,
}) => (
    <CentreControls
        className={classnames('VPTM05CentreControls', className)}
    >
        {!!(topCentreControls || topLeftControls || topRightControls) && (
            <ScreenArea
                verticallyAlign="top"
                centreControls={topCentreControls}
                leftControls={topLeftControls}
                rightControls={topRightControls}
            />
        )}

        {!!(middleCentreControls || middleLeftControls || middleRightControls) && (
            <MiddleScreenContainer>
                <ScreenArea
                    centreControls={middleCentreControls}
                    leftControls={middleLeftControls}
                    rightControls={middleRightControls}
                />
            </MiddleScreenContainer>
        )}

        {!!(bottomCentreControls || bottomLeftControls || bottomRightControls) && (
            <ScreenArea
                verticallyAlign="bottom"
                centreControls={bottomCentreControls}
                leftControls={bottomLeftControls}
                rightControls={bottomRightControls}
            />
        )}
    </CentreControls>
);

VPTM05CentreControls.propTypes = {
    /** Addtional class names(s), e.g. for custom styled component styles */
    className: classNameType,
    /** top centre controls (e.g. buttons)  */
    topCentreControls: propTypes.node,
    /** top left controls (e.g. buttons)  */
    topLeftControls: propTypes.node,
    /** top right controls (e.g. buttons)  */
    topRightControls: propTypes.node,
    /** middle centre controls (e.g. buttons)  */
    middleCentreControls: propTypes.node,
    /** middle left controls (e.g. buttons)  */
    middleLeftControls: propTypes.node,
    /** middle right controls (e.g. buttons)  */
    middleRightControls: propTypes.node,
    /** bottom centre controls (e.g. buttons)  */
    bottomCentreControls: propTypes.node,
    /** bottom left controls (e.g. buttons)  */
    bottomLeftControls: propTypes.node,
    /** bottom right controls (e.g. buttons)  */
    bottomRightControls: propTypes.node,
};

VPTM05CentreControls.displayName = 'VPTM05CentreControls';

export default VPTM05CentreControls;
