import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {rgba} from 'polished';

import {mediaQuery, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';

import {transition} from '../../../../../common/animations';
import {SCREEN_1920_DESKTOP} from '../../../../../common/screen-sizes';
import {hubblGreen, ink} from '../../../../../common/palette';
import {IC31Tick} from '../../../../atoms/ic';
import PackageRow from './package-row';

/** **********************************************************************
 *          MIDDLE COLUMNS: Features (alternating dark and regular)
 ************************************************************************/
const commonStyle = css`
    position: relative;
    border-top: 1px solid ${rgba(ink, 0.05)};
    text-align: center;
    font: var(--quicksilver-body-copy-2);
`;

const FeatureCellDark = styled.td`
    ${commonStyle};
    transition: ${transition('background-color')};
    margin: 0;
    background-color: ${rgba(ink, 0.15)};

    /* stylelint-disable selector-type-no-unknown,selector-type-case */
    ${PackageRow}:hover &,
    ${PackageRow}[aria-pressed='true'] & {
        ${stylesWhenNot('isDisabled')`
            background-color: ${rgba(ink, 0.15)};
        `}
    }
    /* stylelint-enable selector-type-no-unknown,selector-type-case */
`;

const FeatureCell = styled.td`
    ${commonStyle};
`;

const TickWrapper = styled.span`
    position: absolute;
    transition: ${transition('opacity')};
    opacity: 0;
    width: 23px;
    height: 23px;

    > svg {
        /* Stupid svg’s style="display: block" overrides me */
        opacity: 0;
    }

    /* stylelint-disable-next-line rule-empty-line-before,declaration-block-semicolon-newline-after,order/order */
    ${PackageRow}[aria-pressed='true'] & {
        top: -11px;
        right: 1px;
        opacity: 1;
        width: 20px;
        height: 20px;

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
                top: -15px;
                right: -10px;
                width: 23px;
                height: 23px;
        `}

        > svg {
            opacity: 1;
        }
    }
`;

const FeatureColumnCells = ({
    features,
    isDisabled,
}) => (
    features.map(({value, a11yValue}, index) => {
        const DataCell = index % 2 ? FeatureCell : FeatureCellDark;
        const isLastColumn = index === features.length - 1;

        return (
            <DataCell
                isDisabled={isDisabled}
                key={index}
                featureCount={features.length}
                {...(!!a11yValue && {'aria-label': a11yValue})}
            >
                {value}
                {isLastColumn && (
                    <TickWrapper>
                        <IC31Tick color={hubblGreen} />
                    </TickWrapper>
                )}
            </DataCell>
        );
    })
);

FeatureColumnCells.displayName = 'PackageSelectorFeatureCells';

FeatureColumnCells.propTypes = {
    features: propTypes.arrayOf(propTypes.shape({
        value: propTypes.node,
        a11yValue: propTypes.string,
    })),
    isDisabled: propTypes.bool,
};

export default FeatureColumnCells;
