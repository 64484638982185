import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import Ic from '..';
import {indigo, ink} from '../../../../common/palette';

const IC262ContextualRelVid = ({
    color = indigo,
    secondaryColor = ink,
    ...args
}) => (
    <Ic {...args}>
        <g fillRule="nonzero" transform="translate(6 7)">
            <circle cx="42.621" cy="42.621" r="42.621" opacity=".2" fill={color} />
            <g fill={secondaryColor}>
                <path d="M59.474 31.276v14.363H36.932V31.276h22.542m4.806-4.725H32.14v23.755h32.14V26.551z" />
                <path d="M53.101 55.896h-26.55V37.73h2.38v15.735h24.17z" />
                <path d="M47.512 61.486H20.96V43.319h2.38v15.75h24.17z" />
            </g>
        </g>
    </Ic>
);

IC262ContextualRelVid.propTypes = {
    /** The icon's primary color */
    color: colorType,
    /** The icon's secondary color */
    secondaryColor: colorType,
};

IC262ContextualRelVid.displayName = 'IC262ContextualRelVid';

export default IC262ContextualRelVid;
