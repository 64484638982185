import React from 'react';
import Ic from '..';

const IC167Synopsis = (args) => (
    <Ic {...args}>
        <path fill="none" d="M0 0h100v100H0z" />
        <path d="M28.962 34.437h59.342v-5.535H28.962v5.535zm-17.725 0h7.828v-5.535h-7.828v5.535zM28.962 74.42h59.342v-2.768H28.962v2.768zm-17.725 0h7.828v-2.768h-7.828v2.768zm17.514-13.098h59.343v-4.151H28.751v4.151zm-17.725 0h7.828v-4.151h-7.828v4.151zm17.725-13.096h59.343v-5.535H28.751v5.535zm-17.725 0h7.828v-5.535h-7.828v5.535z" />
    </Ic>
);

IC167Synopsis.displayName = 'IC167Synopsis';

export default IC167Synopsis;
