/**
 * @param {string} brand    - String that resembles a brand e.g. martian, ares
 * @returns {string}        - Normalised brand string e.g. binge, kayo, flash
 */
export default function getNormalisedBrand(brand) {
    const lowerCaseBrand = brand?.toLowerCase();

    switch (lowerCaseBrand) {
        case 'martian':
            return 'kayo';
        case 'ares':
            return 'binge';
        case 'magneto':
            return 'magneto';
        default:
            return lowerCaseBrand;
    }
}
