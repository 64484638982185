import get from 'lodash/get';

import {STATUS_KEYS} from './constants';

/*
    Note this is a function to check, vs the usual ACCOUNT_STATUS_RETURNING, because I believe we may move into a world
    where it could potentially be a mix of account status + sub account status that defines this.
    This is here from MWL-680 Payment failed - returning work flow.
 */
export function isPaymentFailedFromAccountStatuses(accountStatus, subAccountStatus) {
    return accountStatus === 'INACTIVE_SUBSCRIPTION'
        && subAccountStatus === 'PAYMENT_FAILED';
}

/**
 * Get all brands' sub_account_status from access token details
 * > NOTE: If a brand has no sub_account_status, we default it to 'NO_ACCOUNT' instead
 *
 * @param {{accessTokenDetails: Object}} accessTokenDetails access token details
 * @returns {{bingeStatus: string, kayoStatus: string, flashStatus: string, lifestyleStatus: string}} all brands sub account status
 */
export function getAllBrandsSubAccountStatus({accessTokenDetails}) {
    const getSubAccountStatusByBrand = (brand) => get(accessTokenDetails, [STATUS_KEYS[brand], 'sub_account_status'], 'NO_ACCOUNT');

    return {
        bingeStatus: getSubAccountStatusByBrand('binge'),
        lifestyleStatus: getSubAccountStatusByBrand('lifestyle'),
        kayoStatus: getSubAccountStatusByBrand('kayo'),
        flashStatus: getSubAccountStatusByBrand('flash'),
    };
}
