import type {Requireable, Validator} from 'prop-types';

import isRequired from './is-required';
import generateError from './util/generate-error';
import getTypeName from './util/get-type-name';
import type {ObjectPropType} from './util/types';

/**
 * Create a React `propTypes` validator that checks whether prop values match the specified regular expression.
 *
 * @param regexp - the regular expression to check against
 * @returns the regular expression `propTypes` validator
 */
function baseRegexpType(regexp: RegExp): Validator<string | undefined | null> {
    return (props, propName, componentName, location, propFullName) => {
        // Don't let prop-types lower our type-checking standards ({[key: string]: any} vs. {[key: string]: PropType})
        const propValue = (props as ObjectPropType)[propName];

        if (propValue === null || propValue === undefined) {
            return null;
        }

        if (!(typeof propValue === 'string') || !regexp.test(propValue)) {
            const actualType = getTypeName(propValue);

            return generateError({
                prefix: '[regexp] ',
                location,
                propFullName,
                actualType,
                componentName,
                expected: `a string that matches ${regexp}`,
            });
        }

        return null;
    };
}

export default function regexpType(regexp: RegExp): Requireable<string> {
    const baseType = baseRegexpType(regexp);

    return Object.assign(baseType, {isRequired: isRequired(baseType)});
}
