import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';

const HEADER_TEXT_FONTS = {
    small: 'var(--infinity-poster-tile-small)',
    medium: 'var(--infinity-poster-tile-medium)',
    large: 'var(--infinity-poster-tile-large)',
    default: 'var(--infinity-poster-tile-default)',
};

/* Override the line heights of text so that three lines will fit */
const HeaderText = styled.div`
    font: ${({size}) => HEADER_TEXT_FONTS[size] || HEADER_TEXT_FONTS.default};
    line-height: 1.08em; /* stylelint-disable-line */ /* Confirmed with Design */
    /* for multi-line curated title, each line entry is restricted to a single line */
    ${stylesWhen('isOverflowHidden')`
        max-height: 1.08em;
        overflow: hidden;
    `}
`;

const Title = ({lines = [], size, value}) => (
    lines.length > 0
        ? (
            <div className={classnames('TitleContainer')}>
                {lines.map(({size, value}) => (
                    <HeaderText
                        key={value}
                        size={size}
                        isOverflowHidden={true}
                    >
                        {value}
                    </HeaderText>
                ))}
            </div>
        )
        : (
            <HeaderText
                className={classnames('TitleContainer')}
                size={size}
                isOverflowHidden={false}
            >
                {value}
            </HeaderText>
        )
);

Title.displayName = 'HeroTitleContainer';

Title.propTypes = {
    lines: propTypes.arrayOf(
        propTypes.shape({
            value: propTypes.string,
            size: propTypes.oneOf(['small', 'medium', 'large', 'default']),
        })
    ),
    value: propTypes.string,
    size: propTypes.oneOf(['small', 'medium', 'large', 'default']),
};

export default Title;
