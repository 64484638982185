import React from 'react';
import propTypes from 'prop-types';
import noop from 'lodash/noop';
import styled from 'styled-components';
import {Button, Input} from 'normalized-styled-components';
import {rgba} from 'polished';

import {stylesWhen, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';

import {transition} from '../../../../common/animations';
import {kayoGreen, satellite, white, midnight, black, ironStone, silverLining} from '../../../../common/palette';
import {fontFamily} from '../../../../common/typography';

const StyledLabel = styled.label`
    position: relative;
    border-radius: 3px;
    width: 100%;
    color: ${silverLining};

    ${stylesWhen('isCondensed')`
        height: 44px;
    `}

    ${stylesWhenNot('isCondensed')`
        height: 69px;
    `}
`;

const FloatingLabelText = styled.span`
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    transition: ${transition('transform', 'opacity', 'color')};
    opacity: ${({isVisible}) => isVisible ? 1 : 0};
    font-family: ${fontFamily};

    ${stylesWhen('isCondensed')`
        font-size: 11px;
    `}

    ${stylesWhenNot('isCondensed')`
        font-size: 13px;
    `}

    ${stylesWhen(({isVisible, isCondensed}) => !!isVisible && !isCondensed)`
        transform: translateY(-28px);
    `}

    ${stylesWhen(({isVisible, isCondensed}) => !!(isVisible && isCondensed))`
        transform: translateY(-19px);
    `}
`;

const IconButton = styled(Button)`
    display: ${({hidden}) => hidden ? 'none' : 'block'};
    position: absolute;
    right: ${({hasIcon}) => hasIcon ? 49 : 14}px;
    transform: translateY(-50%);
    transition: ${transition('border-color')};
    border: 0;
    border-bottom: 1px solid ${satellite};
    background: transparent;
    padding: 0;
    color: ${satellite};

    ${stylesWhen('isCondensed')`
        top: calc(50% - 2px); /* because border-bottom makes it look not-vertically-centred */
        font: var(--mui-body-copy-4);
    `}

    ${stylesWhenNot('isCondensed')`
        top: calc(50% - 3px); /* because border-bottom makes it look not-vertically-centred */
        font: var(--mui-body-copy-3);
    `}

    &:hover,
    &:focus {
        outline: 0;
        border-color: ${kayoGreen};
    }
`;

const StyledInput = styled(Input)`
    box-sizing: border-box;
    transition: ${transition('background-color', 'box-shadow')};
    border-width: 1px;
    border-style: solid;
    border-radius: 3px;
    border-color: transparent transparent ${satellite};
    background-color: inherit;
    padding: 21px 14px;
    width: 100%;
    height: 100%;
    color: ${({isLightTheme}) => isLightTheme ? satellite : white};

    ${stylesWhen(({hasRevealPasswordButton, hasIcon}) => !hasRevealPasswordButton && !!hasIcon)`
        /* has icon, but not reveal password */
        padding-right: 49px;
    `}

    ${stylesWhen(({hasRevealPasswordButton, hasIcon}) => !!hasRevealPasswordButton && !hasIcon)`
        /* has reveal password, but not the icon */
        padding-right: 63px;
    `}

    ${stylesWhen(({hasRevealPasswordButton, hasIcon}) => !!(hasRevealPasswordButton && hasIcon))`
        /* has both reveal password and icon */
        padding-right: 105px;
    `}

    &::placeholder {
        color: ${white};
    }

    &:empty::placeholder {
        color: ${satellite};
    }

    /* stylelint-disable-next-line */
    &::-ms-reveal,
    &::-ms-clear {
        display: none; /* Hide the eyeball icon on Edge */
    }

    &:invalid,
    &:required {
        /* Rely on our own custom designs to communicate invalid and required states */
        box-shadow: none;
    }

    &:empty + ${FloatingLabelText} {
        color: ${satellite};
    }

    &:focus {
        outline: 0;
        border-color: ${ironStone};
        background: ${white};
        color: ${midnight};

        ${stylesWhenNot('isLightTheme')`
            box-shadow: inset 0 1px 6px 2px ${rgba(black, 0.5)};
        `}

        + ${FloatingLabelText} {
            color: ${midnight};
        }

        ~ ${IconButton} {
            border-color: ${midnight};
            color: ${midnight};
        }
    }

    &:-webkit-autofill + ${FloatingLabelText} { /* make label legible when background goes yellow due to chrome autofill form */
        color: ${midnight};
    }
`;

class IA01Input extends React.Component {
    static displayName = 'IA01Input';

    static propTypes = {
        /** Forwarded ref to the input element */
        inputRef: propTypes.oneOfType([propTypes.object, propTypes.func]),
        /** If supplied, will be initial value of the search field */
        defaultValue: propTypes.string,
        /** Placeholder label for input field, which floats above on text input */
        label: propTypes.string.isRequired,
        /** Callback on text field value change */
        onChange: propTypes.func,
        /** Focus event handler */
        onFocus: propTypes.func,
        /** Blur event handler */
        onBlur: propTypes.func,
        /** The HTML type of the input field */
        type: propTypes.oneOf(['text', 'password', 'email', 'tel', 'url', 'search', 'date', 'number']),
        /** For password inputs, whether or not to render the show/hide button */
        hasRevealPasswordButton: propTypes.bool,
        /** Does it have an icon? */
        hasIcon: propTypes.bool,
        /** For a slimmer, more svelte input */
        isCondensed: propTypes.bool,
        /** For an input that suits a light themed background */
        isLightTheme: propTypes.bool,
    };

    static defaultProps = {
        onChange: noop,
        onFocus: noop,
        onBlur: noop,
        type: 'text',
        hasRevealPasswordButton: false,
    };

    state = {hasValue: !!this.props.defaultValue, hasFocus: false, showPassword: false};

    handleChange = (event) => {
        this.setState({hasValue: !!event.target.value});
        this.props.onChange(event);
    };

    handleFocus = (event) => {
        this.setState({hasFocus: true});
        this.props.onFocus(event);
    };

    handleBlur = (event) => {
        this.setState({hasFocus: false});
        this.props.onBlur(event);
    };

    toggleShowPassword = (event) => {
        event.preventDefault();
        this.setState((prevState) => ({showPassword: !prevState.showPassword}));
    };

    render() {
        const {
            defaultValue,
            label,
            type,
            hasRevealPasswordButton,
            hasIcon,
            isCondensed,
            isLightTheme,
            ...htmlAttributes
        } = this.props;

        const showLabel = this.state.hasValue || this.state.hasFocus;

        // Setting inline style due to Edge and Styled Components not working well with CSS variables
        const inputFont = {
            font: 'var(--mui-body-copy-3)',
        };

        return (
            <StyledLabel isCondensed={isCondensed}>
                <StyledInput
                    {...htmlAttributes}
                    ref={this.props.inputRef}
                    defaultValue={defaultValue}
                    placeholder={showLabel ? null : label}
                    title={label}
                    type={this.state.showPassword ? 'text' : type}
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    isCondensed={isCondensed}
                    hasRevealPasswordButton={hasRevealPasswordButton}
                    hasIcon={hasIcon}
                    style={inputFont}
                    isLightTheme={isLightTheme}
                />
                <FloatingLabelText
                    isVisible={showLabel}
                    isCondensed={isCondensed}
                >
                    {label}
                </FloatingLabelText>
                {type === 'password' && (
                    <IconButton
                        type="button"
                        hidden={!hasRevealPasswordButton}
                        hasIcon={hasIcon}
                        onClick={this.toggleShowPassword}
                        isCondensed={isCondensed}
                    >
                        {this.state.showPassword ? 'Hide' : 'Show'}
                    </IconButton>
                )}
            </StyledLabel>
        );
    }
}

export default React.forwardRef((props, ref) => <IA01Input {...props} inputRef={ref} />);
