import React from 'react';
import propTypes from 'prop-types';
import {useSwipeable} from 'react-swipeable';
import styled from 'styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {white} from './palette';
import {SCREEN_TABLET, SCREEN_REALLY_LG_DESKTOP} from './screen-sizes';
import {
    XS_HERO_FIXED_HEIGHT_PX,
    XL_HERO_FIXED_HEIGHT_PX,
} from './style-constants';

const ZINDEX_ACTIVE_SLIDE = 1;

export const KeyboardNavGuide = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0; /* note this is set by sibling selector of the StyledSwipable */
    z-index: ${({isKbGuideEnabled}) => isKbGuideEnabled ? ZINDEX_ACTIVE_SLIDE : -1};
    background: ${white};
    padding: 7px;
    font: var(--mui-body-copy-3);
`;

const StyledDiv = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        height: ${XS_HERO_FIXED_HEIGHT_PX}px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_REALLY_LG_DESKTOP})`
        height: ${XL_HERO_FIXED_HEIGHT_PX}px;
    `}

    &:focus {
        outline: 0;

        + ${KeyboardNavGuide} {
            opacity: 0.75;
        }
    }

    &:focus-within + ${KeyboardNavGuide} {
        opacity: 0.75;
    }
`;

const Swipeable = ({children, ...props}) => {
    const handlers = useSwipeable(props);

    return (
        <StyledDiv {...handlers}>
            {children}
        </StyledDiv>
    );
};

Swipeable.displayName = 'Swipeable';

Swipeable.propTypes = {
    children: propTypes.node,
};

export default Swipeable;
