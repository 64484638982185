import {RESPONSE_CODE} from '../utils/constants';

/**
 * Detect 404 or valid response and prevent unnecessary clientside retries
 *
 * @param  {Object}  response                          - Superagent response object
 * @param  {boolean} response.responseConsideredValid  - Flag indicating 200 response
 * @param  {number}  response.responseStatus           - Response code to interpret
 * @returns {boolean}                                  - Whether this response is considered "valid" for network purposes
 */
export function responseConsideredValidWith404({responseConsideredValid, responseStatus}) {
    return responseConsideredValid || responseStatus === RESPONSE_CODE.notFound;
}

/**
 * Checks the response's status code against the passed array of status codes. Used to prevent unnecessary clientside retries.
 *
 * @param  {Object}     response                          - Superagent response object
 * @param  {boolean}    response.responseConsideredValid  - Flag indicating 200 response
 * @param  {number}     response.responseStatus           - Response code to interpret
 * @param  {number[]}   validStatusCodes                  - An array of status codes we want to consider as "valid"
 * @returns {boolean}                                     - Whether this response is considered "valid" for network purposes
 */
export default function isResponseConsideredValid({responseConsideredValid, responseStatus}, validStatusCodes = []) {
    return responseConsideredValid || validStatusCodes.includes(responseStatus);
}

/**
 * Return a function that validates a superagent response against an array of status codes.
 * Useful if you need to pass isResponseConsideredValid.
 *
 * @param {number[]} validStatusCodes   - An array of status codes we want to consider as "valid"
 * @returns {Function}                  - Returns a function to validate the response
 */
export function getIsResponseConsideredValid(validStatusCodes) {
    return (superAgentObject) => isResponseConsideredValid(superAgentObject, validStatusCodes);
}
