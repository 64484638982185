import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import property from 'lodash/property';

import {palette} from '@fsa-streamotion/streamotion-web-ionic';
import {stylesWhen, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {colorType} from '@fsa-streamotion/custom-prop-types';
import {transition} from '../../../common/animations';

const {panther} = palette;

export {default as IC304Facebook} from './304-facebook';
export {default as IC305Instagram} from './305-instagram';
export {default as IC306Twitter} from './306-twitter';
export {default as IC307TikTok} from './307-tiktok';
export {default as IC308YouTube} from './308-youtube';

/**
 * There are two methods to set the colour of the SVG
 *     1. Set the color of a container element and the colour will be inherited by the SVG
 *     2. Set the color property
 */

const Ic = styled.svg.attrs({
    viewBox: '0 0 100 100',
})`
    display: ${property('display')};
    pointer-events: none; /* Don't allow icons to be event targets */

    ${stylesWhen('hasDropshadow')`
        filter: drop-shadow(1px 1px 1px ${panther});
    `}

    ${stylesWhenNot('preserveColors')`
        fill: currentColor;

        * {
            transition: ${transition('fill', 'stroke')};
        }
    `}
`;

const Icon = ({
    hasDropshadow,
    ariaLabel,
    children,
    color,
    preserveColors,
    display = 'block',
    size = '100%',

    ariaHidden = ariaLabel ? undefined : true,
    ...htmlAttributes
}) => ( // using aria-hidden true on the assumption that these icons will only be used in the context of a button and are thus decorative
    <Ic
        {...htmlAttributes}
        hasDropshadow={hasDropshadow}
        aria-hidden={ariaHidden}
        aria-label={ariaLabel}
        color={preserveColors ? null : color}
        preserveColors={preserveColors}
        display={display}
        style={preserveColors ? {
            fill: color,
            stroke: color,
            width: size,
        } : {
            width: size,
        }}
    >
        {children}
    </Ic>
);

Icon.propTypes = {
    ariaHidden: propTypes.bool,  // eslint-disable-line react/boolean-prop-naming
    ariaLabel: propTypes.string,
    children: propTypes.node,
    color: colorType,
    preserveColors: propTypes.bool,  // eslint-disable-line react/boolean-prop-naming
    display: propTypes.oneOf(['block', 'inline-block', 'flex', 'inline-flex']),
    size: propTypes.string,
    hasDropshadow: propTypes.bool,
};

Icon.displayName = 'Icon';

export default Icon;
