import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import property from 'lodash/property';
import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {colorType} from '@fsa-streamotion/custom-prop-types';
import {rgba} from 'polished';

import {black, white} from '../../../../../common/palette';
import {SCREEN_768_TABLET, SCREEN_1920_DESKTOP} from '../../../../../common/screen-sizes';
import sizingStyle from './common';

const ContextContainer = styled.div`
    margin-top: 15px; /* 5px + 10px (arrow container top) */

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        margin-top: 20px; /* 7px + 13px (arrow container top) */
    `}
`;

const ContextualHelp = styled.div`
    position: relative;
    border-left: 4px solid ${property('messageColor')};
    box-shadow: 0 1px 1px 0 ${rgba(black, 0.3)}, 0 -1px 2px -1px ${rgba(black, 0.3)};
    background-color: ${white};
    padding: 14px;
    text-align: left;
    color: ${black};
    font: var(--quicksilver-body-copy-4-light);
    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        border-left-width: 5px;
    `}

    a {
        color: inherit;
    }
`;

/**
 * This is to align the ^ to the center of the last input box
 */
const ArrowContainer = styled.div`
    ${sizingStyle};
    display: flex;
    position: absolute;
    top: -10px;
    right: 0;
    justify-content: center;
    margin-left: auto;
    width: var(--im04-box-input-width);

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        top: -13px;
    `}
`;

const StyledArrow = styled.div`
    ${sizingStyle};
    filter: drop-shadow(0 -1px 1px ${rgba(black, 0.1)});
    border-right: var(--im04-context-arrow-size) solid transparent;
    border-bottom: var(--im04-context-arrow-size) solid ${white};
    border-left: var(--im04-context-arrow-size) solid transparent;
    width: 0;
    height: 0;
`;

const ContextualHelpBalloon = ({
    children,
    messageColor,
    ...htmlAttributes
}) => (
    <ContextContainer {...htmlAttributes}>
        <ContextualHelp messageColor={messageColor}>
            <ArrowContainer><StyledArrow /></ArrowContainer>
            {children}
        </ContextualHelp>
    </ContextContainer>

);

ContextualHelpBalloon.propTypes = {
    /** Content for the help dialog */
    children: propTypes.node.isRequired,
    /** Optional color to denote message type */
    messageColor: colorType,
};

ContextualHelpBalloon.displayName = 'ContextualHelpBalloon';

export default ContextualHelpBalloon;
