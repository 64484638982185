/* eslint-disable max-len, react/jsx-max-props-per-line */

import React from 'react';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import Ic from '../../ic';

const GA93Telstra = ({
    className,
    ...htmlAttributes
}) => (
    <Ic
        {...htmlAttributes}
        className={classnames('GA93Telstra', className)}
        preserveColors={true}
        ariaLabel="Telstra"
    >
        <g fill="none" fillRule="evenodd" transform="translate(0 8)">
            <rect width="99.86" height="81.909" x=".07" y=".85" fill="#FFF" rx="4" />
            <path fill="#35B1DA" stroke="#35B1DA" strokeWidth=".5" d="M27.7891491,21.6244698 C28.7620101,21.569784 29.6808937,21.460198 30.6539664,21.5148837 C36.1135029,21.7340558 41.4648732,23.4316739 46.329813,25.8412795 C44.1135697,36.7394047 41.9513037,47.6922158 39.7892493,58.590341 C44.4918339,58.590341 49.1946303,58.590341 53.8974266,58.590341 C54.8163103,58.6450268 55.7893829,58.5354408 56.6540776,58.1522114 C57.573173,57.7140818 58.3299132,56.8925084 58.7081774,55.9615634 C59.1406306,54.9757182 59.2487969,53.8805014 59.4109404,52.8399705 C60.329824,47.418358 61.2489194,42.0514312 62.1136141,36.6298187 C68.2219363,42.1610172 73.7354501,48.5135747 77.4651738,55.9615634 C78.7085561,58.4260692 79.7353943,60.9999466 80.38418,63.6834099 C80.8706105,65.7642574 81.1409202,67.9549054 80.9245877,70.0906532 C80.708467,72.1170294 80.059893,74.0885053 78.9246769,75.7861235 C78.3840575,76.7170685 77.4651738,77.3743701 76.6544563,77.9767715 C73.7354501,80.0578335 70.0597037,80.7149206 66.6002897,80.4410628 C62.9785206,80.167205 59.4651293,79.0719882 56.0596926,77.8124997 C47.9514596,74.7455925 40.4918005,69.8716956 34.1675693,63.9023675 C28.5458893,58.6997126 23.5727833,52.6210129 20.2213238,45.6658396 C18.7078435,42.3801893 17.4104838,38.9300527 17.0861968,35.3154299 C16.9240534,33.6178118 16.9780306,31.9201936 17.3565066,30.2772612 C17.842937,28.3057853 18.7620324,26.3889951 20.2213238,25.0199205 C22.2214464,22.9935443 25.0322864,21.8983276 27.7891491,21.6244698" />
            <path fill="#194F91" d="M71.6707391,3.56682842 C73.5388659,3.45981924 75.4067837,3.51342854 77.2749106,3.51342854 C79.5167464,3.51342854 81.7583732,3.51342854 84,3.51342854 C83.3595949,6.98944692 82.7189808,10.5188652 82.0785757,13.9946742 C81.8116706,15.2781561 81.0111643,16.561638 79.8369489,17.2032743 C78.9829361,17.6847109 77.9155247,17.7915107 76.954708,17.7381108 C73.0583531,17.7381108 69.2155046,17.7381108 65.3191496,17.7381108 C64.1984407,24.1553111 63.1308202,30.6259112 62.0101113,37.0431115 C61.1028011,42.3372389 60.1952819,47.5779664 59.3414781,52.8720938 C59.1278704,53.8881575 59.074573,54.9576211 58.6475666,55.9202849 C58.2205602,56.8293394 57.5266487,57.684994 56.6193385,58.059212 C55.7653257,58.4336395 54.8045091,58.5406486 53.8971989,58.4870393 C49.253635,58.4870393 44.6102801,58.4870393 39.9667162,58.4870393 C42.1017482,47.8453846 44.2898686,37.1501207 46.4249005,26.5082566 C47.0120082,23.5670747 47.5991159,20.6792927 48.1862235,17.7381108 C42.4752481,17.7381108 36.7109754,17.7381108 31,17.7381108 C31.6937025,14.2620924 32.3343166,10.7862834 33.0280191,7.31026505 C33.2949242,6.18719218 33.8820319,5.17112849 34.7895511,4.47588293 C35.6433549,3.88764655 36.6574689,3.56682842 37.7250894,3.62043772 C41.8348431,3.62043772 45.9445967,3.62043772 50.0010529,3.62043772 C53.8971989,3.62043772 57.7935538,3.62043772 61.6364024,3.62043772 C64.9989471,3.56682842 68.3081944,3.56682842 71.6707391,3.56682842" />
        </g>
    </Ic>
);

GA93Telstra.displayName = 'GA93Telstra';

GA93Telstra.propTypes = {
    /** Additional class(es) */
    className: classNameType,
};

export default GA93Telstra;
