import {parse} from 'querystring';
import bacon from 'baconjs';
import React, {Fragment} from 'react';
import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';
import property from 'lodash/property';
import trim from 'lodash/trim';
import trimStart from 'lodash/trimStart';

import {hydrateStream} from '@fsa-streamotion/bacon-widget';
import {isBrowser, isServer, getLocalStorageValue, setLocalStorageValue, removeLocalStorageValue} from '@fsa-streamotion/browser-utils';
import {getUser, getAllBrandsSubAccountStatus, accountsEnvs} from '@fsa-streamotion/streamotion-web-widgets-common';
import {asBool} from '@fsa-streamotion/transformers';

import {prependQueryString} from '../../../../todo-move-to-widgets-common/utils/add-params-to-url';
import getBrandDisplayName from '../../../../todo-move-to-widgets-common/utils/get-brand-display-name';
import getLoginTrackingStream from '../../../../todo-move-to-widgets-common/streams/login-tracking';
import getActiveProfileIdFromLocalStorage from '../../../../todo-move-to-widgets-common/utils/active-profile-id';
import {MARKETING_STORAGE_KEYS, NEW_SIGNUP, PARTIAL_SIGNUP, RETURNING_SIGNUP, STORAGE_KEY_PREFIX, BILLING_PRODUCT_NAME_MAP} from '../../../../todo-move-to-widgets-common/utils/constants';
import getAppMeasurementLoadedEventStream from '../../../../todo-move-to-widgets-common/utils/adobe/adobe-loaded-state';
import getOfferStream from '../../../../todo-move-to-widgets-common/streams/endpoints/billing/offer';
import StyledBaconWidget from '../../../../todo-move-to-widgets-common/utils/styled-bacon-widget';
import getHydrationLifecycle from '../../../../todo-move-to-widgets-common/utils/hydration-lifecycle-singleton';

import mapErrorsForFiso from '../../../../todo-move-to-widgets-common/utils/map-errors-for-fiso';
import {
    getEmailHash,
    initialiseAdobeDefinitionsConfig,
    trackFromAdobeDefinitions,
} from '../../../../todo-move-to-widgets-common/utils/adobe';
import getResourcesAsset from '../../../../todo-move-to-widgets-common/streams/endpoints/resources';

import clientSideFeatureFlags from '../../utils/clientside-feature-flags';
import getNormalisedBrand from '../../utils/get-normalised-brand';
import validateBrand from '../../utils/validate-brand';

import getInvalidOfferModalStream from '../common/invalid-offer-stream';
import getPackageSelectorPropsStream from '../common/package-selector-stream';
import getVoucherDisplayPropsStream from '../common/voucher-stream';

import LandingPageTemplate from '../../components/branded/landing';
import redirectAwayFromOffer from '../offer/redirect-url';
import {DISABLE_SIGNUP_HEADING_TEXT} from '../../utils/constants';
import generateFootnoteMarker from '../../utils/generate-footnote-marker';
import getModuleComponents from '../../components/modules';
import normaliseTermType, {TERM_TYPE} from '../../utils/normalise-term-type';
import getLandingAemConfigStreamWithFallback from './streams/landing';

const {envsFromWidgetSettings} = accountsEnvs;

const NoopComponent = Object.assign(() => null, {displayName: 'AEM Module (empty)'});

const VOUCHER_LOCAL_STORAGE_TTL_MS = 30 * 60 * 1000; // 30 minutes

const MARKETING_PG_LOCAL_STORAGE_TTL_MS = 30 * 60 * 1000; // 30 minutes

const OFFER_NAME_LOCAL_STORAGE_TTL_MS = 30 * 60 * 1000; // 30 minutes

const MARKETING_DEFAULT_CONFIG = {
    binge: 'default',
    kayo: 'default',
    flash: 'default',
    lifestyle: 'default',
};

const SIGN_IN_WORDS = 'Sign In';
const SIGN_OUT_WORDS = 'Sign Out';

const MAX_ADTECH_WAIT_MS = 10000;

const VALID_OFFER_ERRORS = {
    accountRequired: 'existing.account.required',
};

class Landing extends StyledBaconWidget {
    static widgetName = 'accw-landing';
    component = LandingPageTemplate;

    clientSideHydratedBus = new bacon.Bus();
    clientSideHydrated$ = this.clientSideHydratedBus.take(1);

    constructor(settings, element) {
        super(settings, element);

        const baseUrl = trim(settings.baseUrl, '/');
        const path = trim(settings.path, '/');
        const landingType = trim(settings.landingType, 'home');

        const brand = validateBrand(getNormalisedBrand(settings.brand));
        const {platformEnv, ...envSettings} = envsFromWidgetSettings(settings);

        Object.assign(
            this.config,
            {
                commonWidgetSettings: {
                    ...envSettings,
                    baseUrl,
                    brand,
                    landingType,
                    clientSideFeatureFlags: clientSideFeatureFlags(platformEnv), // these flags specifically pertain to CLIENTSIDE ONLY feature changes. if you need them server side, look at https://bitbucket.foxsports.com.au/projects/MAR/repos/ares-web-server/browse/node-app/src/js/routes/index.js#64-71
                    path,
                    platformEnv,
                },

                // producers referring to marketing config as pg or page config.
                marketingConfigName: this.settings.pg || MARKETING_DEFAULT_CONFIG[brand],
                offerName: this.settings.offerName || '',
                showPromo: asBool(this.settings.pm, false),

                redirectActiveAccountLink: this.settings.redirectActiveAccountLink,
                redirectActiveAccountAndActiveProfileLink: this.settings.redirectActiveAccountAndActiveProfileLink,
                redirectNonPremiumAccountAndActiveProfileLink: this.settings.redirectNonPremiumAccountAndActiveProfileLink,
                redirectCheckoutLink: this.settings.redirectCheckoutLink,
                redirectLoginSuccessLink: this.settings.redirectLoginSuccessLink,
            },
        );
    }

    rememberOfferName(key) {
        if (isBrowser()) {
            const offerNameFromParam = new URLSearchParams(window.location.search).get('offer-name');

            if (offerNameFromParam) {
                setLocalStorageValue({
                    key,
                    value: offerNameFromParam,
                    expiresIn: OFFER_NAME_LOCAL_STORAGE_TTL_MS,
                });
            }
        }
    }

    forgetOfferName(key) {
        if (isBrowser()) {
            removeLocalStorageValue(key);
        }
    }

    rememberMarketingConfigName(key) {
        if (isBrowser()) {
            const marketingConfigNameFromParam = getMarketingParameterFromUrl();

            if (marketingConfigNameFromParam) {
                setLocalStorageValue({
                    key,
                    value: marketingConfigNameFromParam,
                    expiresIn: MARKETING_PG_LOCAL_STORAGE_TTL_MS,
                });
            }
        }
    }

    forgetMarketingConfigName(key) {
        if (isBrowser()) {
            removeLocalStorageValue(key);
        }
    }

    rememberVoucher(voucher, key) {
        if (isBrowser() && voucher) {
            setLocalStorageValue({
                key,
                value: voucher,
                expiresIn: VOUCHER_LOCAL_STORAGE_TTL_MS,
            });
        }
    }

    forgetVoucher(key) {
        if (isBrowser()) {
            removeLocalStorageValue(key);
        }
    }

    /**
     * On client side, the API throws us errors that we don't actually want.
     * These errors are things like 'voucher.required' or 'existing.account.required'
     * These errors need to be shown on other pages, but not on the offer page,
     * were we present things differently according to other fields/options.
     *
     * Otherwise we show 'Voucher Required' as an error when landing on an offer
     * that requires voucher input. So it's not an error here.
     *
     * @param   {Object} offer Offer Payload obtained within offersStream.
     * @returns {Object}       Modified offer payload, with removed error details if not needed for offers page.
     */
    removeErrorObjectsNotRequiredOnOfferPage(offer) {
        const errorCodesNotWantedClientSide = ['voucher.required'];
        const offerErrorCode = offer?.error?.code;

        if (errorCodesNotWantedClientSide.includes(offerErrorCode)) {
            return {
                ...offer,
                error: {},
            };
        }

        return offer;
    }

    trackEvent({lifecycle, selectedPackageName}) {
        return getAppMeasurementLoadedEventStream().toPromise()
            .then(() => Promise.all([
                new Promise((resolve) => {
                    if (window._satellite === undefined) {
                        return resolve();
                    }

                    const listenerArgs = ['finishedAdTechCalls', resolve, {once: true}];

                    // redirect when finishedAdTechCalls event dispatched
                    document.addEventListener(...listenerArgs);

                    // redirect after 5 seconds without waiting for finishedAdTechCalls event
                    setTimeout(() => {
                        document.removeEventListener(...listenerArgs);

                        resolve();
                    }, MAX_ADTECH_WAIT_MS);
                }),
                trackFromAdobeDefinitions({
                    definitionsPath: 'eventTracking.signUp.packageselection',
                    eventTypePayloadKey: 'data.event.name',
                    templateValues: {lifecycle, package: selectedPackageName},
                }),
                trackFromAdobeDefinitions({
                    definitionsPath: 'screenTracking.signup.personalDetails',
                    eventType: 'screenload',
                }),
            ]));
    }

    getData(hydration = {}) {
        const {commonWidgetSettings: configCommonWidgetSettings, marketingConfigName} = this.config;
        const {brand, landingType, path, platformEnv, resourcesEnv} = configCommonWidgetSettings;

        const offerNameKey = [STORAGE_KEY_PREFIX[brand].offerName, platformEnv].join('-');
        const voucherKey = [STORAGE_KEY_PREFIX[brand].voucher, platformEnv].join('-');
        const marketingKey = [STORAGE_KEY_PREFIX[brand].marketingPg, platformEnv].join('-');
        const clientSideOfferName = getLocalStorageValue({key: offerNameKey});
        const isApp = isBrowser() && new URLSearchParams(window.location.href).get('is-app') === 'true';
        const isReferral = isBrowser() && !!(
            new URLSearchParams(window.location.href).get('channel')
            || getLocalStorageValue({key: MARKETING_STORAGE_KEYS[brand].channel})
        );

        function getHasConditionalOfferApproval(offer) {
            const offerErrorCode = offer?.error?.code;
            const offerVoucherCode = offer?.voucher?.code; // means successful voucher
            const hasAppliedButUnsuccessfulVoucher = !offerVoucherCode && offer?.packages?.some(({voucherApplied}) => voucherApplied);

            switch (offerErrorCode) {
                case VALID_OFFER_ERRORS.accountRequired: // appears for vouchers requiring existing subscription
                    return hasAppliedButUnsuccessfulVoucher;
                default:
                    return false;
            }
        }

        const userEnteredVoucherBus = new bacon.Bus();
        const userEntered$ = userEnteredVoucherBus.toProperty(); // initial value of this property comes from local storage or blank at clientSideVoucher$
        const isUserNavigatingBus = new bacon.Bus(); // Navigating with auth0 sdk .login() can take a little time :S  So provide feedback stuff happening.
        const isUserNavigating$ = isUserNavigatingBus.toProperty(false);

        const isModalOpenBus = new bacon.Bus();
        const isModalOpen$ = isModalOpenBus.toProperty(true);

        /** ************************************
         * USER
         **************************************/

        // Add userInstance to window for external devs to access on landing
        // - getUser does this for us
        const user$ = this.clientSideHydrated$
            .flatMapLatest(() => bacon.fromPromise(getUser({brand, platformEnv})))
            .startWith({});

        const commonWidgetSettings$ = bacon.combineTemplate({
            ...configCommonWidgetSettings,
            user: user$, // keep this in commonWidgetSettings because consistent with other places we do this
        });

        const userIsAuthenticated$ = user$
            .flatMapLatest((user) => user?.isAuthenticated && bacon.fromPromise(user.isAuthenticated()))
            .startWith(false);

        const userAccountStatus$ = user$.flatMapLatest((user) => user?.getAccountStatusesByBrand && bacon.fromPromise(user.getAccountStatusesByBrand(brand)).map('.account_status'));

        // const userAccessToken$ = bacon.combineTemplate({
        //     user: user$,
        //     userIsAuthenticated: userIsAuthenticated$,
        // })
        //     .flatMapLatest(({user, userIsAuthenticated}) => {
        //         if (userIsAuthenticated) {
        //             return bacon.fromPromise(user.getAccessToken());
        //         } else {
        //             return null;
        //         }
        //     })
        //     .mapError(null) // This will be something like, not logged in.
        //     .first();

        const userLogInOrOutFunction$ = user$.combine(userIsAuthenticated$, (user, userIsAuthenticated) => {
            if (userIsAuthenticated) {
                return () => {
                    isUserNavigatingBus.push(true);
                    user.logout();
                };
            } else {
                return () => {
                    isUserNavigatingBus.push(true);
                    user.login({redirectAfterProcessing: this.config.redirectLoginSuccessLink});
                };
            }
        }).startWith(null);

        const userResubscribeFunction$ = user$.flatMapLatest((user) => () => {
            // User has shown their intent to resubscribe, dont redirect them to freebies!
            sessionStorage.setItem([STORAGE_KEY_PREFIX[brand].shouldPreventFreebiesRedirection, platformEnv].join('-'), true);

            isUserNavigatingBus.push(true);
            user.login({redirectAfterProcessing: `${this.config.redirectLoginSuccessLink}#products`}); // focus on package selector
        }).startWith(null);

        const userLogInOrOutWording$ = userIsAuthenticated$
            .map((loggedIn) => loggedIn ? SIGN_OUT_WORDS : SIGN_IN_WORDS)
            .startWith(null);

        /** ************************************
         * AEM
         **************************************/

        const aemConfig$ = hydrateStream(
            hydration.aemConfig$,
            getLandingAemConfigStreamWithFallback({
                brand,
                landingType,
                platformEnv,
                marketingConfigName: marketingConfigName || getLocalStorageValue({key: marketingKey}) || MARKETING_DEFAULT_CONFIG[brand],
                defaultMarketingConfigName: MARKETING_DEFAULT_CONFIG[brand],
                onMarketingConfigNameUsed: () => this.rememberMarketingConfigName(marketingKey),
                onDefaultMarketingConfigUsed: () => this.forgetMarketingConfigName(marketingKey),
            })
                .map('.data.catalogModuleList.items.0')
        )
            .toProperty()
            .doAction(() => this.rememberMarketingConfigName(marketingKey));

        const aemModules$ = aemConfig$.map('.modules');

        // NOTE: Currently only binge AEM template returns termType
        const aemTermType$ = brand === 'binge'
            ? bacon.combineTemplate({
                termType: aemConfig$.map('.termType'),
                pg: aemConfig$.map('.pg'),
                brand,
            })
                .map(normaliseTermType)
            : bacon.constant(TERM_TYPE.monthly);

        /** ************************************
         * BILLING / OFFER
         **************************************/

        const prefetchedOffer$ = hydrateStream(
            // never used a SSR prefetched offer if we have a client value for it.
            // We get away with this not being exact playback SSR->Client because of our loading state.
            clientSideOfferName ? undefined : hydration.prefetchedOffer$,
            aemTermType$.flatMapLatest((termType) => getOfferStream({
                accessToken: undefined,
                brand,
                offer: this.config.offerName || clientSideOfferName || '', // Use page's ?offer-name before clientSideOfferName if there, or continue falling back to empty string/offer name.
                platformEnv,
                voucher: undefined,
                termType,
            }))
        )
            .map(this.removeErrorObjectsNotRequiredOnOfferPage)
            .toProperty();

        const clientSideVoucher$ = this.clientSideHydrated$
            .map(() => {
                const queryParams = parse(trimStart(window.location.search, '?'));
                const voucherFromParam = queryParams.voucher;
                const voucherFromStorage = getLocalStorageValue({key: voucherKey});

                return voucherFromParam || voucherFromStorage || null;
            })
            .concat(userEntered$);

        // const clientSideAemName$ = this.clientSideHydrated$
        //     .map(() => {
        //         const marketingFromStorage = getLocalStorageValue({key: marketingKey});

        //         // local storage first, widget setting (url), then default
        //         return marketingFromStorage || marketingConfigName || MARKETING_DEFAULT_CONFIG[brand];
        //     });

        // const usePrefetchedAem$ = clientSideAemName$
        //     .map((clientSideAemName) => clientSideAemName === marketingConfigName);

        // const usePrefetchedOffer$ = bacon.combineWith(
        //     usePrefetchedAem$,
        //     clientSideVoucher$,
        //     userAccessToken$,
        //     (usePrefetchedAem, clientSideVoucher, userAccessToken) => {
        //         // If we're using prefetched marketing config, no client side voucher and no access token, use what we fetched.
        //         if (
        //             usePrefetchedAem
        //             && !clientSideVoucher
        //             && !userAccessToken
        //         ) {
        //             return true;
        //         }
        //     }
        // )
        //     .map(Boolean)
        //     .skipDuplicates();

        // @TODO: we keep this resources file for now, presumably at some point it'll get removed, but not now
        const shouldForwardToFreebies$ = isApp
            ? bacon.constant(false)
            : getResourcesAsset({
                brand,
                resourcesEnv,
                path: 'freemium/landing.json',
            })
                .map('.shouldForwardToFreebies')
                .doError(console.error.bind(null, 'Accounts-Widgets: Could not get shouldForwardToFreebies value from config. Falling back to default value'))
                .mapError(false);

        // Account status will drive if redirections happen.
        // We don't care about other flows for returning / partial sign ups.
        const userHasActiveSubscriptionRedirect$ = bacon.combineTemplate({
            shouldForwardLoggedOutUsersToFreebies: shouldForwardToFreebies$,
            userAccountStatus: userAccountStatus$,
            voucher: clientSideVoucher$.first(),
        })
            .map(({shouldForwardLoggedOutUsersToFreebies, userAccountStatus, voucher}) => ({
                brand,
                hasProfileId: !!getActiveProfileIdFromLocalStorage({platformEnv, brand}),
                hasTelstraSignupJwt: !!sessionStorage.getItem('signupTelstraJwt'),
                isApp,
                isReferral,
                setShouldPreventFreebiesRedirection(newValue) {
                    sessionStorage.setItem([STORAGE_KEY_PREFIX[brand].shouldPreventFreebiesRedirection, platformEnv].join('-'), newValue);
                },
                shouldForwardLoggedOutUsersToFreebies,
                shouldPreventFreebiesRedirection: !!sessionStorage.getItem([STORAGE_KEY_PREFIX[brand].shouldPreventFreebiesRedirection, platformEnv].join('-')),
                userAccountStatus,
                voucher,
                voucherKey,
                platformEnv,

                // These links are a bit confusing, but allow docs pages to override redirection logic.
                // Theres definitely a better way, but not something I'm going to solve today
                // I gave them names which match their current usage today, so don't go changing them on webserver
                browseUrl: this.config.redirectActiveAccountAndActiveProfileLink,
                freebiesUrl: this.config.redirectNonPremiumAccountAndActiveProfileLink,
                profileUrl: this.config.redirectActiveAccountLink,
            }))
            .map(redirectAwayFromOffer); // function has the side effect of navigating us away from offer page if we shouldn't be here (and return true) otherwise return false if we are to remain here.

        const offerName$ = bacon.constant(clientSideOfferName || this.config.offerName);

        const isUserNotGettingRedirected$ = userHasActiveSubscriptionRedirect$
            .not()
            .toProperty();

        const offerSettings$ = bacon.combineTemplate({
            // usePrefetchedOffer: usePrefetchedOffer$,
            // prefetchedOffer: prefetchedOffer$,
            offer: offerName$.map((offerName) => offerName || ''), // use the offer suggested by marketing config or keep blank
            voucher: clientSideVoucher$,
            userIsAuthenticated: userIsAuthenticated$, // this will be pre-organised by this stream, but when asking again in the future (past 5 minutes) - token will NEED to be refreshed.
            isUserNotGettingRedirected: isUserNotGettingRedirected$,
        })
            .filter('.isUserNotGettingRedirected');

        // Totally client side only
        const offer$ = bacon.combineTemplate({
            offerSettings: offerSettings$,
            termType: aemTermType$,
            user: user$,
        })
            .flatMapLatest(({offerSettings, termType, user}) => {
                // const {usePrefetchedOffer, prefetchedOffer, offer, voucher, userIsAuthenticated} = offerSettings;
                const {offer, voucher, userIsAuthenticated} = offerSettings;

                // if (usePrefetchedOffer) {
                //     return bacon.later(0, prefetchedOffer);
                // } else {
                // ensure we have a non-expired token for talking to offer api.
                const accessToken$ = userIsAuthenticated ? bacon.fromPromise(user.getAccessToken()) : bacon.constant(undefined);

                return accessToken$
                    .flatMapLatest((accessToken) => { // eslint-disable-line arrow-body-style
                        return getOfferStream({
                            accessToken,
                            brand,
                            offer,
                            platformEnv,
                            voucher,
                            termType,
                        });
                    });
                // }
            })
            .map(this.removeErrorObjectsNotRequiredOnOfferPage)
            .startWith(null); // Don't start with anything on an offer. Totally client side.

        const onContinueWithoutVoucher$ = offer$
            .map(({offer}) => {
                if (!offer.voucherRequired) {
                    return undefined;
                }

                return () => {
                    const nextBestOfferName = offer?.nextBestOffer || '';
                    const reloadUrl = new URL(window.location.href);

                    reloadUrl.searchParams.delete('voucher');
                    reloadUrl.searchParams.set('offer-name', nextBestOfferName);

                    // Ensure we do not duplicate params on reload
                    reloadUrl.searchParams.delete('offerName');

                    this.forgetVoucher(voucherKey);
                    this.forgetOfferName(offerNameKey);

                    // wait a tick to make sure local storage updates take effect before navigating
                    setTimeout(() => {
                        window.location.replace(reloadUrl);
                    });
                };
            })
            .startWith(undefined);

        // Only remember offer-name if it's come from prefetchedOffer.  Will not include voucher or user details ever.
        // If a user enters a voucher, don't remember that voucher's offer, just remember the voucher itself.
        // So this will represent what the user lands on via url param, vs what COG is trying to move me to.
        const saveOfferNameToStorage$ = prefetchedOffer$
            .first()
            .doAction((offerPayload) => {
                const offerName = offerPayload?.offer?.code;
                const errorCode = offerPayload?.error?.code;
                const pageLoadedOfferName = this.config.offerName;

                if (offerName === pageLoadedOfferName && !errorCode) {
                    this.rememberOfferName(offerNameKey);
                }
                // @TODO Review forgetOfferName. I can't think right now how I'd validly know not to ask the api.
            });

        const warningModal$ = getInvalidOfferModalStream({
            user$,
            userIsAuthenticated$,
            prefetchedOffer$,
            offer$,
            getHasConditionalOfferApproval,

            shouldSelectNextBestOffer: false,
            shouldReloadedPageToNextBestOfferIfTelstra: true,

            forgetVoucherFunction: () => this.forgetVoucher(voucherKey),
            forgetOfferNameFunction: () => this.forgetOfferName(offerNameKey),

            brand,
            platformEnv,
        }).toProperty();

        const hasOfferError$ = bacon.combineAsArray(
            offer$.map('.error'),
            warningModal$,
        )
            .map((values) => values.some(Boolean))
            .startWith(false);

        // When we apply a voucher without error, we should store it. If it's applied but in error because the user isn't logged in, still store it so we can validate later when they become logged in
        // If we detect a voucher is invalid we should remove.
        const saveVoucherToStorage$ = bacon.combineTemplate({
            offer: offer$,
            clientSideVoucher: clientSideVoucher$,
        })
            .doAction(({offer, clientSideVoucher}) => {
                const offerErrorCode = offer?.error?.code;
                const offerVoucherCode = offer?.voucher?.code; // means successful voucher
                const hasConditionalOfferApproval = getHasConditionalOfferApproval(offer);
                const voucherCode = hasConditionalOfferApproval ? clientSideVoucher : offerVoucherCode;

                if ((hasConditionalOfferApproval || !offerErrorCode) && Boolean(voucherCode)) {
                    return void this.rememberVoucher(voucherCode, voucherKey);
                } else {
                    return void this.forgetVoucher(voucherKey);
                }
            });

        const offerDisplay$ = bacon.combineTemplate({
            isSignupDisabled: offer$.map('.disableSignup'),
            disableSignupText: offer$.map('.disableSignupText'),
            ctaHeader: offer$.map('.offer.ctaHeader'),
            ctaSubtext: offer$.map('.offer.ctaSubtext'),
            packageHeader: offer$.map('.offer.packageHeader'),
            packageSubtext: offer$.map('.offer.packageSubtext'),
            voucherRequired: offer$.map('.offer.voucherRequired'),
        })
            .map(({isSignupDisabled, disableSignupText, ctaHeader, ctaSubtext, packageHeader, packageSubtext, voucherRequired}) => ({
                ctaHeader,
                ctaSubtext,
                packageHeader: isSignupDisabled ? DISABLE_SIGNUP_HEADING_TEXT : packageHeader,
                packageSubtext: isSignupDisabled ? disableSignupText : packageSubtext,
                voucherRequired,
            }))
            .startWith({});

        const packageSelectorProps$ = getPackageSelectorPropsStream({
            offerSettings$,
            offer$,
            prefetchedOffer$,
        });

        const voucherDisplay$ = getVoucherDisplayPropsStream({
            clientSideVoucher$,
            userEnteredVoucherBus,
            offer$,
            prefetchedOffer$,
            hydration,
        });

        const selectedPackagePriceId$ = packageSelectorProps$.map('.selectedPackagePriceId');
        const hasContinueButton$ = selectedPackagePriceId$.map(Boolean).and(packageSelectorProps$.map('.isProductAvailableForSelection'));

        const onContinueFunction$ = bacon.combineTemplate({
            userIsAuthenticated: userIsAuthenticated$,
            user: user$,
            selectedOffer: offer$,
            selectedPackagePriceId: selectedPackagePriceId$,
            clientSideVoucher: clientSideVoucher$,
            termType: aemTermType$,
        })
            .filter(({selectedPackagePriceId}) => selectedPackagePriceId) // Skip updates here until we have selected package price ids.
            .map(({userIsAuthenticated, user, selectedPackagePriceId, selectedOffer, clientSideVoucher, termType}) => {
                const offerName = selectedOffer?.offer?.code;
                const offerVoucher = selectedOffer?.voucher?.code;
                const hasAppliedButUnsuccessfulVoucher = !offerVoucher // offer didn't return a voucher
                    && selectedOffer?.packages?.some(({voucherApplied}) => voucherApplied) // but the voucher did apply successfully
                    && selectedOffer?.error?.code === VALID_OFFER_ERRORS.accountRequired; // it's just that there's a condition on voucher use in the error code
                const voucher = hasAppliedButUnsuccessfulVoucher ? clientSideVoucher : offerVoucher;

                const packages = selectedOffer?.packages || [];
                const forceLoginOverCreateAuth0Flow = selectedOffer?.offer?.forceLoginOverCreate; // This flow is 'kayo only' style of offer. So we never ask to create a user account.

                const selectedPackage = packages.find((thisPackage) => {
                    const thisPackagePrices = thisPackage?.prices || [];

                    return thisPackagePrices.some((price) => price.priceId === selectedPackagePriceId);
                });

                const selectedPackageId = selectedPackage.packageId;
                const selectedPackageName = selectedPackage.planName;
                const auth0PageType = forceLoginOverCreateAuth0Flow ? 'login' : 'register';

                // Where we go after processing login.
                const checkoutLink = prependQueryString({
                    url: this.config.redirectCheckoutLink,
                    parameters: {
                        'offer-name': offerName,
                        'selected-package-id': selectedPackageId,
                        'selected-package-price-id': selectedPackagePriceId,
                        termType,
                        voucher,
                        brand, // The value for this is hardcoded on the respective web-server, however we need this here for proper transition from offer to checkout on docs pages
                    },
                });

                if (userIsAuthenticated) {
                    return () => {
                        isUserNavigatingBus.push(true);

                        Promise.all([
                            user.isPartialSignup(),
                            user.isStatusReturning(),
                        ])
                            .then(([isPartialSignup, isReturning]) => {
                                if (isPartialSignup) {
                                    return PARTIAL_SIGNUP;
                                } else if (isReturning) {
                                    return RETURNING_SIGNUP;
                                }

                                return null;
                            })
                            .then((lifecycle) => this.trackEvent({lifecycle, selectedPackageName}))
                            .then(() => {
                                window.location.href = checkoutLink;
                            });
                    };
                } else {
                    return () => {
                        isUserNavigatingBus.push(true);

                        const callback = () => user.login({
                            redirectAfterProcessing: checkoutLink,
                            metaData: {
                                'page': auth0PageType,
                                'platform': BILLING_PRODUCT_NAME_MAP[brand],
                                'offer-name': offerName,
                                'selected-package-id': selectedPackageId,
                                'selected-package-price-id': selectedPackagePriceId,
                                'voucher': voucher || undefined, // make sure we don't send this as empty string.
                                'telstra-jwt': window.sessionStorage.getItem('signupTelstraJwt') || undefined,
                                termType,
                            },
                        });

                        this.trackEvent({lifecycle: NEW_SIGNUP, selectedPackageName})
                            .then(callback);
                    };
                }
            })
            .startWith(noop);

        const populateHydrationSingletonOnRender$ = this.clientSideHydrated$.doAction(() => {
            getHydrationLifecycle().onFirstClientRender();
        });

        /** ************************************
         * ADOBE ANALYTICS
         **************************************/

        // Track this offer screen page view in adobe analytics
        initialiseAdobeDefinitionsConfig({resourcesEnv, brand});
        const trackScreenLoadForAdobe$ = offer$
            .doAction((offer) => {
                const hasError = !!offer?.error?.code;
                const offerCode = offer?.offer?.code;

                trackFromAdobeDefinitions({
                    definitionsPath: `screenTracking.offer.${hasError ? 'offerError' : 'offer'}`,
                    eventType: 'screenload',
                    templateValues: {offerCode},
                });
            });

        const loginTracking$ = bacon.combineAsArray(
            user$,
            getAppMeasurementLoadedEventStream(),
        )
            .flatMapLatest(([user]) => user)
            .map((user) => ({
                user,
                platformEnv,
                resourcesEnv,
                accessTokenDetails: bacon.fromPromise(user.getAccessTokenDetails()),
                hashedEmail: bacon.fromPromise(
                    user.getUserDetails()
                        .then((userDetails) => getEmailHash(userDetails?.email))
                ),
            }))
            .flatMapLatest(bacon.combineTemplate)
            .flatMapLatest(({accessTokenDetails, ...userDetails}) => {
                const martianId = accessTokenDetails?.['http://foxsports.com.au/martian_id'];

                // Call window.AfxIdentity?.reportData here
                window.AfxIdentity?.reportData({martianId});

                return getLoginTrackingStream({
                    ...userDetails,
                    brand,
                    allBrandsSubAccountStatus: getAllBrandsSubAccountStatus({accessTokenDetails}),
                    auth0ID: accessTokenDetails?.sub,
                });
            });

        const loginAemTracking$ = bacon.combineTemplate({
            userIsAuthenticated: userIsAuthenticated$,
            user: user$,
        })
            .map(({userIsAuthenticated, user}) => ({
                userIsAuthenticated,
                accessTokenDetails: userIsAuthenticated ? bacon.fromPromise(user.getAccessTokenDetails()) : undefined,
            }))
            .flatMapLatest(bacon.combineTemplate)
            .map(({userIsAuthenticated, accessTokenDetails}) => ({
                adobeTargetParams: {
                    loginStatus: userIsAuthenticated ? 'logged-in' : 'logged-out',
                    userSubStatus: {
                        ...getAllBrandsSubAccountStatus({accessTokenDetails}),
                    },
                },
            }))
            .startWith(undefined);

        /** ************************************
         * CONTENT
         **************************************/

        // The offers loading effect should only be in effect until the first offer$ resolves (client side).
        const isInitialOfferLoading$ = offer$
            .filter(Boolean) // Make sure it's not startWith(null) though.
            .map(false)
            .first()
            .startWith(true);

        const footnote$ = offer$
            .map(({packages}) => {
                const subjectToChangeTexts = packages
                    .map(property('subjectToChangeText'))
                    .filter(Boolean);

                if (!subjectToChangeTexts.length) {
                    return null;
                }

                return subjectToChangeTexts.map((subjectToChangeText, index) => (
                    <div key={index}>
                        {`${generateFootnoteMarker({count: index + 1})}${subjectToChangeText}`}
                    </div>
                ));
            })
            .startWith(undefined);

        const billingProps$ = bacon.combineTemplate({
            packageSelector: packageSelectorProps$.map('.props'),

            voucherDisplay: voucherDisplay$.map('.props'),

            hasContinueButton: hasContinueButton$,
            onContinue: onContinueFunction$,
            isContinueLoading: isUserNavigating$,
            isSignUpDisabled: offer$.map('.disableSignup').startWith(false),

            onContinueWithoutVoucher: onContinueWithoutVoucher$,
            offerDisplay: offerDisplay$,
            footnote: footnote$,
            offer: offer$,
            hasOfferError: hasOfferError$,

            onClickOfferCta: () => trackFromAdobeDefinitions({
                definitionsPath: 'eventTracking.signUp.startSubscription',
                eventTypePayloadKey: 'data.event.name',
            }),
        })
            .skipDuplicates(isEqual);

        const userProps$ = bacon.combineTemplate({
            userIsAuthenticated: userIsAuthenticated$,
            signInWords: userLogInOrOutWording$,
            onSignIn: userLogInOrOutFunction$,
            isSignInLoading: isUserNavigating$,
            onResubscribe: userResubscribeFunction$,
        })
            .skipDuplicates(isEqual);

        const promoModalProps$ = bacon.combineTemplate({
            shouldShowPromo: this.config.showPromo,
            isModalOpen: isModalOpen$,
            onClickNewUserCta: () => void isModalOpenBus.push(false),
        })
            .skipDuplicates(isEqual);

        const reactElement$ = bacon.combineTemplate({
            billingProps: billingProps$,
            brand,
            commonWidgetSettings: commonWidgetSettings$,
            isLoading: isInitialOfferLoading$,
            modules: aemModules$,
            termType: aemTermType$,
            userProps: userProps$,
            path,
            promoModalProps: promoModalProps$,
            loginAemTracking: loginAemTracking$,
        })
            .map(({loginAemTracking, ...rest}) => (
                <Fragment>
                    {getModuleComponents({
                        ...rest,
                        adobeTargetParams: loginAemTracking?.adobeTargetParams,
                    })}
                </Fragment>
            ))
            .startWith(<NoopComponent />);

        /** ************************************************
         * WEB-APP HEAD
         ***************************************************/

        // @TODO Consume this later on https://foxsportsau.atlassian.net/browse/WEB-3952
        const webAppHead$ = aemConfig$
            .map('.meta')
            .map((meta) => ({
            // Note that we're never saying noIndex=true here - we're relying on the WebAppHead widget to provide a canonicalUrl to the basic offer page
            // More info on why that's better: https://moz.com/blog/rel-canonical
                ...(meta ? meta : {
                    description: getBrandDisplayName(brand),
                    title: getBrandDisplayName(brand),
                    ogType: 'product',
                    ogImageUrl: null,
                    ogImageWidth: null,  // We don't know the proportions, but set null so we don't use the default in web-app-head/component.js which is designed for the default image
                    ogImageHeight: null,
                    twitterCard: 'summary_large_image',
                }),
            }));

        /** ************************************************
         * WIDGET ARGS
         ***************************************************/

        return bacon.combineTemplate({
            props: {
                children: reactElement$, // children to LandingPageTemplate
                brand,

                // if there is an error in offer (user entitlements or offer stream) show the warning modal even if showPromo is true
                warningModal: (this.config.showPromo && !hasOfferError$.toProperty()) ? null : warningModal$,
            },
            hydration: {
                prefetchedOffer$,
                aemConfig$,
                voucherDisplay$: voucherDisplay$.map('.hydration'),
                inputId: voucherDisplay$.map('.hydration.inputId'),
            },

            webAppHead: webAppHead$,
            webAppJsonLd: [],

            _forcedSubscriptions: isServer()
                ? null
                : bacon.mergeAll(
                    populateHydrationSingletonOnRender$,
                    saveOfferNameToStorage$,
                    saveVoucherToStorage$,
                    userHasActiveSubscriptionRedirect$,
                    // saveMarketingToStorage managed by the stream that fetches (and falls back).
                    trackScreenLoadForAdobe$,
                    loginTracking$,
                )
                    .filter(false) // Don't let their events cause a re-render
                    .startWith(null),
        })
            .flatMapError(mapErrorsForFiso)
            .doAction(() => {
                hydration = {}; // eslint-disable-line no-param-reassign
            });
    }
}

export default function LandingWidget(settings = {}, element = null) {
    return new Landing(settings, element);
}

Landing.pageBoot();

function getMarketingParameterFromUrl() {
    const searchParams = new URLSearchParams(window.location.search);

    return searchParams.get('pg') || searchParams.get('marketing') || searchParams.get('marketingConfigName');
}
