import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import invoke from 'lodash/invoke';
import throttle from 'lodash/throttle';
import omit from 'lodash/omit';
import noop from 'lodash/noop';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import muid from '../../../../common/muid';
import Swipeable, {KeyboardNavGuide} from '../../../../common/swipeable';

const ZINDEX_ACTIVE_SLIDE = 1;

const CarouselContainer = styled.ol`
    display: block;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    list-style: none;
`;

const CarouselSlot = styled.li`
    position: absolute;
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    z-index: ${({isActive, isFadingOut}) => {
        if (isActive) {
            return ZINDEX_ACTIVE_SLIDE;
        } else if (isFadingOut) {
            return ZINDEX_ACTIVE_SLIDE - 1;
        } else {
            return ZINDEX_ACTIVE_SLIDE - 2;
        }
    }};
    width: 100%;
    height: 100%;
`;

const IcebergOverflowHider = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
`;

const OuterContainer = styled.section`
    position: relative;
    transform: scale(1);
    width: 100%;
    height: 100%; /* this allows hero to "grow" into a custom height provided by the PanelPageTemplate wrapper */
`;

/**
 * Inspired by https://github.com/FoundersFactory/react-css-carousel
 */

export default class OR01Hero extends React.Component {
    static displayName = 'OR01Hero';

    static propTypes = {
        /** Carousel slides, e.g. collection of CAM02HeroSlide */
        children: propTypes.node,
        /** Pass in an ID for rehydration purposes */
        instructionsDomId: propTypes.string,
        /** Lazy load images on slides that are not visible or one click away from visible */
        shouldLazyLoadImages: propTypes.bool,
        /** Slide change callback */
        onSlideChange: propTypes.func,
        /** Optional extra class names */
        className: classNameType,
    };

    static defaultProps = {
        children: [],
        instructionsDomId: muid(),
        shouldLazyLoadImages: true,
        onSlideChange: noop,
    };

    state = {
        activeIndex: 0,
        isAnimating: false,
        isKbGuideEnabled: false,
        // If we're not lazy loading, fill with true, otherwise, start by loading first 2 images
        lazyImageIndexes: Array.from({length: React.Children.count(this.props.children)}, (_, index) => this.props.shouldLazyLoadImages && Math.abs(index) > 1), // eslint-disable-line max-len
        oldActiveIndex: null,
    };

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeypress);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeypress);
        this.clearTimeouts.forEach(clearTimeout);
    }

    clearTimeouts = [];

    prevSlide = throttle((shouldFocusSlide) => {
        const nextIndex = this.state.activeIndex ? this.state.activeIndex - 1 : React.Children.count(this.props.children) - 1;

        this.changeSlide(this.state.activeIndex, nextIndex, shouldFocusSlide);
    }, 250, {trailing: false});

    nextSlide = throttle((shouldFocusSlide) => {
        const nextIndex = this.state.activeIndex === React.Children.count(this.props.children) - 1 ? 0 : this.state.activeIndex + 1;

        this.changeSlide(this.state.activeIndex, nextIndex, shouldFocusSlide);
    }, 250, {trailing: false});

    changeSlide = (fromIndex, toIndex, shouldFocusSlide) => {
        this.clearTimeouts.forEach(clearTimeout);
        this.clearTimeouts.length = 0;
        this.props.onSlideChange(toIndex);

        this.setState(({lazyImageIndexes}) => ({
            activeIndex: toIndex,
            isAnimating: true,
            oldActiveIndex: fromIndex,
            lazyImageIndexes: Object.assign([...lazyImageIndexes], {[toIndex]: false, [toIndex + 1]: false, [toIndex - 1]: false}),
        }), () => {
            if (shouldFocusSlide) {
                invoke(this.activeSlideRef, 'current.focus');
            }

            this.clearTimeouts.push(
                setTimeout(() => {
                    this.setState({isAnimating: false});
                }, 1000)
            );
        });
    };

    handleKeypress = (event) => {
        const {key, defaultPrevented} = event;

        if (key === 'Tab' && !this.state.isKbGuideEnabled) {
            return void this.setState({isKbGuideEnabled: true});
        } else if (
            (
                document.activeElement !== this.containerEl // the root element is NOT in focus
                && !this.containerEl.contains(document.activeElement) // and neither is any of its descendants
            )
            || defaultPrevented // Someone else (e.g. nav hoc) has done stuff with this event already
        ) {
            return;
        }

        switch (key) {
            case 'ArrowLeft':
                return void this.prevSlide(true);

            case 'ArrowRight':
                return void this.nextSlide(true);

            default:
                return;
        }
    };

    handleWheel = ({deltaX}) => {
        if (deltaX > 400) {
            this.nextSlide();
        } else if (deltaX < -400) {
            this.prevSlide();
        }
    };

    activeSlideRef = React.createRef();

    render() {
        const hasMultipleSlides = React.Children.count(this.props.children) > 1;

        return (
            <OuterContainer
                className={classnames('OR01Hero', this.props.className)}
                ref={(el) => { this.containerEl = el; }}
                onWheel={this.handleWheel}
            >
                <Swipeable
                    aria-describedby={this.props.instructionsDomId}
                    aria-label="Video Carousel"
                    onSwipedLeft={this.nextSlide}
                    onSwipedRight={this.prevSlide}
                    role="region"
                    tabIndex={hasMultipleSlides ? 0 : -1}
                >
                    <CarouselContainer>
                        {React.Children.toArray(this.props.children)
                            .map((child, index, slides) => {
                                // this is the active carousel slide OR this was the previous carousel slide and we're still animating away from it so let it remain visible
                                /* eslint-disable-next-line max-len */
                                const isVisible = this.state.activeIndex === index || (this.state.isAnimating && this.state.oldActiveIndex === index);
                                const isBeingLazy = this.state.lazyImageIndexes[index];

                                const activeSlideProps = {
                                    ...child.props,
                                    ...hasMultipleSlides && {
                                        onPrev: () => void this.prevSlide(),
                                        onNext: () => void this.nextSlide(),
                                    },
                                    ref: this.state.activeIndex === index ? this.activeSlideRef : child.ref,
                                    isVisible,
                                    isBeingLazy,
                                };

                                const nextSlide = slides[(index + 1) % slides.length];
                                const nextSlideProps = {
                                    ...omit(nextSlide.props, 'key'),
                                    isVisible,
                                    isBeingLazy,
                                    isPreview: true,
                                };

                                return (
                                    <CarouselSlot
                                        key={child.key}
                                        isActive={this.state.activeIndex === index}
                                        isFadingOut={this.state.oldActiveIndex === index}
                                    >
                                        {React.cloneElement(child, activeSlideProps)}
                                        {hasMultipleSlides && (
                                            <IcebergOverflowHider role="presentation">
                                                {React.cloneElement(nextSlide, nextSlideProps)}
                                            </IcebergOverflowHider>
                                        )}
                                    </CarouselSlot>
                                );
                            })}
                    </CarouselContainer>

                </Swipeable>

                {hasMultipleSlides && (
                    <KeyboardNavGuide id={this.props.instructionsDomId} isKbGuideEnabled={this.state.isKbGuideEnabled}>
                        &lt; use keys to change slides &gt;
                    </KeyboardNavGuide>
                )}
            </OuterContainer>
        );
    }
}
