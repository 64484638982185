import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import noop from 'lodash/noop';

import {classNameType} from '@fsa-streamotion/custom-prop-types';
import CommonTransition from '../../../../common/common-transition';
import {Section} from '../../../../common/normalized-styled-components';

import VerificationContainer from './components/verification-container';
import MobileUpdateModal from './components/mobile-update-modal';

const StyledSection = styled(Section)`
    position: relative;
    width: 100%;
    min-width: 260px;
`;

/**
 * ### Mobile Verification / OTP
 * Used in 2 scenarios:
 * 1. Account Creation (Signup Journey in Digital Sales)
 *   - Used in mobile verification step
 *   - `IMPORTANT:` Has an option to update your mobile number
 *
 * 2. One Time Code (Alternative security when PIN is turned off in My Account)
 *   - For large purchases (>$100) of hardware, subscriptions, accessories,
 *     if the user has their PIN set to off, we have added a OTP as an extra
 *     security measure. This code will be sent to the user’s mobile on their account
 *     and they will be prompted to enter this code as the last step before their order is processed.
 *   - `IMPORTANT:` Has **NO** option to update your mobile number
 */
const OR53Veri = ({
    // base verification
    className,
    codeLength = 6,
    errorMessage,
    hasMobileNumberInMainInfoText,
    isCodeResetOnErrorEnabled,
    isCodeResetOnResendEnabled = true,
    isEditingMobileNumber,
    isResendLoading,
    isSuccessful,
    isVerificationLoading,
    lowerHelpText = 'This is to help us verify the account transaction',
    mainInfoText = 'Please verify your mobile number. Enter the code we sent to your mobile.',
    mobileNumber = '',
    onCancelClick,
    onComplete,
    onClickEditMobileNumber,
    onResendClick,
    verificationCode,
    // mobile update modal
    hasSubmittedMobileNumber,
    isSubmittingMobileNumber,
    isUpdateMobileEnabled,
    mobileNumberError,
    onCancelEditMobileNumber = noop,
    onSubmitMobileNumber = noop,
    processMobileNumberInput = (_oldMobileNumber, newMobileNumber) => newMobileNumber,
    validateMobileNumber = () => true,
    ...htmlAttributes
}) => (
    <StyledSection
        {...htmlAttributes}
        disabled={isUpdateMobileEnabled ? isEditingMobileNumber : false}
        className={classnames('OR53VeriCopy', className)}
    >
        {/* BASE VERIFICATION PAGE */}
        <CommonTransition in={!!mobileNumber}>
            <VerificationContainer
                errorMessage={errorMessage}
                hasMobileNumberInMainInfoText={hasMobileNumberInMainInfoText}
                isCodeResetOnResendEnabled={isCodeResetOnResendEnabled}
                isEditingMobileNumber={isEditingMobileNumber}
                isResendLoading={isResendLoading}
                isSuccessful={isSuccessful}
                isUpdateMobileEnabled={isUpdateMobileEnabled}
                isVerificationLoading={isVerificationLoading}
                lowerHelpText={lowerHelpText}
                mainInfoText={mainInfoText}
                mobileNumber={mobileNumber}
                codeLength={codeLength}
                isCodeResetOnErrorEnabled={isCodeResetOnErrorEnabled}
                onCancelClick={onCancelClick}
                onClickEditMobileNumber={onClickEditMobileNumber}
                onComplete={onComplete}
                onResendClick={onResendClick}
                verificationCode={verificationCode}
            />
        </CommonTransition>

        {/* MOBILE UPDATE MODAL */}
        <CommonTransition in={isUpdateMobileEnabled && isEditingMobileNumber}>
            <MobileUpdateModal
                hasSubmittedMobileNumber={hasSubmittedMobileNumber}
                isEditingMobileNumber={isEditingMobileNumber}
                isSubmittingMobileNumber={isSubmittingMobileNumber}
                mobileNumberError={mobileNumberError}
                mobileNumberExternal={mobileNumber}
                onCancelEditMobileNumber={onCancelEditMobileNumber}
                onSubmitMobileNumber={onSubmitMobileNumber}
                processMobileNumberInput={processMobileNumberInput}
                validateMobileNumber={validateMobileNumber}
            />
        </CommonTransition>

    </StyledSection>
);

OR53Veri.propTypes = {
    // ------ base verification
    /** Optional CSS class(es) */
    className: classNameType,
    /** The length of a valid verification code */
    codeLength: propTypes.number,
    /** An optional error message to denote an incorrect verification code */
    errorMessage: propTypes.node,
    /** Append mobile number to main info message */
    hasMobileNumberInMainInfoText: propTypes.bool,
    /** Whether or not we should clear the code when we're in an error state' */
    isCodeResetOnErrorEnabled: propTypes.bool,
    /** If enabled, code input fields are cleared when Resend Code button is clicked */
    isCodeResetOnResendEnabled: propTypes.bool,
    /** Are we editing the mobile number? */
    isEditingMobileNumber: propTypes.bool,
    /** If the resend code button is in a loading state */
    isResendLoading: propTypes.bool,
    /** If the verification was successful */
    isSuccessful: propTypes.bool,
    /** Is update mobile feature enabled? If false, show cancel button instead and never display update mobile modal  */
    isUpdateMobileEnabled: propTypes.bool,
    /** If a verification request is loading */
    isVerificationLoading: propTypes.bool,
    /** The help text to render beneath the main info message */
    lowerHelpText: propTypes.string,
    /** Main info message */
    mainInfoText: propTypes.node,
    /** The mobile number to display for confirmation and prepopulating the mobile number editor */
    mobileNumber: propTypes.string,
    /** Callback when user clicks Cancel <br />_**Note:** this is only used when update mobile modal is disabled_ */
    onCancelClick: propTypes.func,
    /** Complete callback, called when the user has filled the whole verification code */
    onComplete: propTypes.func,
    /** Event handler for when Update Mobile button is clicked <br />_**Note:** this is only used when update mobile modal is enabled_ */
    onClickEditMobileNumber: propTypes.func,
    /** Callback when user clicks Resend Code */
    onResendClick: propTypes.func,
    /** Verification code (if controlling from the outside) */
    verificationCode: propTypes.string,
    // ---- mobile update modal
    /** Has the mobile number been successfully submitted? */
    hasSubmittedMobileNumber: propTypes.bool,
    /** Is the updated mobile number being submitted? */
    isSubmittingMobileNumber: propTypes.bool,
    /** Mobile number error message */
    mobileNumberError: propTypes.node,
    /** Handle cancellation of editing the mobile number */
    onCancelEditMobileNumber: propTypes.func,
    /** Mobile number submit handler */
    onSubmitMobileNumber: propTypes.func,
    /** Validate and format a change to the mobile number in the editor: (oldValue, newValue) => result */
    processMobileNumberInput: propTypes.func,
    /** Validate a mobile number for completeness: (mobileNumber) => true/false */
    validateMobileNumber: propTypes.func,
};

OR53Veri.displayName = 'OR53Veri';

export default OR53Veri;
