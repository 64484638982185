import get from 'lodash/get';

export default class Response {
    constructor(
        err,
        response,
        {requestFormat = 'json'} = {}
    ) {
        this.superAgentErr = err;
        this.superAgentResponse = response;
        this.requestFormat = requestFormat;

        // (Strangely, request. is server based, req. is browser based.)
        this.requestUrl = get(this.superAgentResponse, 'req.url') || get(this.superAgentResponse, 'request.url');

        this.responseOk = get(this.superAgentResponse, 'ok');
        this.responseStatus = get(this.superAgentResponse, 'status', 0); // remove 'undefined' from status code error messages. Happens on cors/timeouts.
        this.responseText = get(this.superAgentResponse, 'text');

        // responseConsideredValid means no superAgentErr, responseOk, and somewhere between 200 && 399 status codes
        this.responseConsideredValid = this.superAgentErr === null
            && this.responseOk
            && this.responseStatus >= 200
            && this.responseStatus < 400;
    }

    parseResponseText() {
        let parsedResponseText = this.responseText;

        if (this.requestFormat === 'json') {
            try {
                parsedResponseText = JSON.parse(parsedResponseText);
            } catch (e) {
                throw `Unable to parse (${this.requestUrl}): ${parsedResponseText}`;
            }
        }

        this.responseParsed = parsedResponseText;

        return parsedResponseText;
    }
}
