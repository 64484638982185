import propTypes from 'prop-types';

import regexpType from './regexp-type';

/**
 * Validate colour values. Specifically excluding "named" colours like "red" or "blue", accepting hex/rgba only.
 */
const colorType = propTypes.oneOfType([
    // transparent
    propTypes.oneOf(['transparent']),

    // hex colour
    regexpType(/^\#[a-f0-9]{3}$|\#[a-f0-9]{6}$/i),

    // rgba colour
    regexpType(
        /^rgba\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*(\d*(?:\.\d+)?)\)$/
    ),
]);

export default colorType;
