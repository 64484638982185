import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {fadeInOut} from '../../../../../common/animations';
import CommonTransition from '../../../../../common/common-transition';
import View from './view';

const Container = styled.section`
    display: flex;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    pointer-events: none;
    ${fadeInOut}
`;

const VPTM03ThumbnailCarousel = ({
    centerThumbnailTimeOffset,
    thumbnailWidth,
    thumbnailGap,
    urls,
    isVisible,
    isTabletUp,
}) => (
    <CommonTransition in={isVisible}>
        <Container>
            <View
                {...{
                    urls,
                    thumbnailWidth,
                    thumbnailGap,
                    centerThumbnailTimeOffset,
                    isTabletUp,
                }}
            />
        </Container>
    </CommonTransition>
);

VPTM03ThumbnailCarousel.displayName = 'VPTM03ThumbnailCarousel';

VPTM03ThumbnailCarousel.propTypes = {
    thumbnailWidth: propTypes.number,
    thumbnailGap: propTypes.number,
    centerThumbnailTimeOffset: propTypes.number,
    urls: propTypes.arrayOf(propTypes.string),
    isTabletUp: propTypes.bool,
    isVisible: propTypes.bool,
};

export default VPTM03ThumbnailCarousel;
