import React from 'react';
import Ic from '..';

const IC167Synopsis = (args) => (
    <Ic {...args}>
        <path d="M30.406 26H83.2v8.4H30.406zm0 16.702H83.2v5.6H30.406zm0 14.602H83.2v4.2H30.406zm0 14.616H83.2v2.8H30.406zM16 26h8.806v8.4H16zm0 16.702h8.806v5.6H16zm0 14.602h8.806v4.2H16zm0 14.616h8.806v2.8H16z" />
    </Ic>
);

IC167Synopsis.displayName = 'IC167Synopsis';

export default IC167Synopsis;
