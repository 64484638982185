import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Section} from 'normalized-styled-components';
import times from 'lodash/times';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {screenSizes} from '@fsa-streamotion/streamotion-web-ionic';

import {loadingBaseStyles, loadingAnimationStyles} from './constants';

const {SCREEN_768_TABLET, SCREEN_1280_DESKTOP, SCREEN_1920_DESKTOP} = screenSizes;

const BrandedLoadingWrapper = styled.div`
    /* Apply the animation on the wrapper so the animation stays synced across all children */
    ${loadingAnimationStyles}
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
`;

const BrandedMarBlkHeading = styled.div`
    ${loadingBaseStyles}
    margin-bottom: 14px;
    width: 200px;
    height: 24px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 280px;
        height: 35px;
    `}
`;

const BrandedMarBlkSubtext = styled.div`
    ${loadingBaseStyles}
    margin-bottom: 42px;
    width: 280px;
    height: 14px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 360px;
        height: 29px;
    `}
`;

const BrandedPackageSelectorHeading = styled.div`
    ${loadingBaseStyles}
    margin-bottom: 28px;
    width: 240px;
    height: 14px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 320px;
        height: 29px;
    `}
`;

const PackagesAndFeaturesSection = styled(Section)`
    display: grid;
    grid-gap: 56px;
    justify-items: center;
    margin-bottom: 53px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        grid-template-columns: max-content max-content;
        grid-gap: 38px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        margin-bottom: 66px;
    `}
`;

const PackagesSection = styled(Section)`
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 3fr 1fr 1fr 1fr;
    grid-auto-rows: 74px;
    grid-gap: 5px;
    width: 375px;

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        grid-auto-rows: 94px;
        width: 588px;
    `}
`;

const PackagesColumnHeader = styled.div`
    display: grid;
    grid-gap: 3px;
    justify-items: center;
`;

const BrandedPackageColumnTopLine = styled.div`
    ${loadingBaseStyles}
    width: 40px;
    height: 6px;
`;

const BrandedPackageColumnBottomLine = styled.div`
    ${loadingBaseStyles}
    width: 50px;
    height: 6px;
`;

const BrandedPackageCell = styled.div`
    ${loadingBaseStyles}
`;

const BrandedPackageMainCell = styled.div`
    ${loadingBaseStyles}
    clip-path: polygon(100% 0%, 0% 0%, 0% 100%, 100% 100%, 100% 85%, 9% 85%, 9% 40%, 62% 40%, 62% 85%, 100% 85%, 100% 31%, 9% 32%, 9% 15%, 35% 15%, 35% 32%, 100% 31%);
`;

const FeaturesSection = styled.div`
    display: grid;
    grid-auto-rows: max-content;
    grid-gap: 14px;
    align-content: center;
    justify-items: center;
    width: 327px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        grid-gap: 28px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        width: 444px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        grid-gap: 35px;
    `}
`;

const BrandedFeatureListHeading = styled.div`
    ${loadingBaseStyles}
    width: 217px;
    height: 24px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        height: 36px;
    `}
`;

const BrandedFeatureListItem = styled.div`
    ${loadingBaseStyles}
    width: 100%;
    height: 24px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        height: 36px;
    `}
`;

const OfferLoadingSkeleton = ({brand}) => (
    <BrandedLoadingWrapper brand={brand}>
        <BrandedMarBlkHeading brand={brand} />
        <BrandedMarBlkSubtext brand={brand} />
        <BrandedPackageSelectorHeading brand={brand} />

        <PackagesAndFeaturesSection>
            <PackagesSection>
                <span />
                {times(3, (index) => (
                    <PackagesColumnHeader key={index}>
                        <BrandedPackageColumnTopLine brand={brand} />
                        <BrandedPackageColumnBottomLine brand={brand} />
                    </PackagesColumnHeader>
                ))}

                {times(3, (index) => (
                    <React.Fragment key={index}>
                        <BrandedPackageMainCell brand={brand} />
                        {times(3, (index) => <BrandedPackageCell key={index} brand={brand} />)}
                    </React.Fragment>
                ))}
            </PackagesSection>

            <FeaturesSection>
                <BrandedFeatureListHeading />
                <BrandedFeatureListItem />
                <BrandedFeatureListItem />
                <BrandedFeatureListItem />
            </FeaturesSection>
        </PackagesAndFeaturesSection>
    </BrandedLoadingWrapper>
);

OfferLoadingSkeleton.displayName = 'OfferLoadingSkeleton';

OfferLoadingSkeleton.propTypes = {
    /** brand name */
    brand: propTypes.string,
};

export default OfferLoadingSkeleton;
