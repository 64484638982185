import React from 'react';
import {colorType} from '@fsa-streamotion/custom-prop-types';
import {midnight} from '../../../../common/deprecated-mui-palette';
import Ic from '..';

const IC23Hud = ({color, ...rest}) => (
    <Ic color={color} {...rest}>
        <g fill="none" fillRule="evenodd" transform="translate(7 7)">
            <circle cx="43" cy="43" r="42.4" fill={color} />
            <circle cx="43.4" cy="43" r="5.1" fill={midnight} />
            <circle cx="43.4" cy="58.7" r="5.1" fill={midnight} />
            <circle cx="43.4" cy="27.3" r="5.1" fill={midnight} />
        </g>
    </Ic>
);

IC23Hud.propTypes = {
    color: colorType,
};

IC23Hud.displayName = 'IC23Hud';

export default IC23Hud;
