import React from 'react';
import Ic from '..';

const IC25ArrowR = (args) => (
    <Ic {...args}>
        <path d="M32.1 90.9L28.2 87l36.9-37-36.9-36.9 3.9-3.9L71 48c.5.5.8 1.2.8 2 0 .7-.3 1.4-.8 2L32.1 90.9z" />
    </Ic>
);

IC25ArrowR.displayName = 'IC25ArrowR';

export default IC25ArrowR;
