import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {Nav} from 'normalized-styled-components';
import {palette as ionicPalette} from '@fsa-streamotion/streamotion-web-ionic';
import {palette as muiPalette} from '@fsa-streamotion/streamotion-web-mui';
import {palette as infinityPalette} from '@fsa-streamotion/streamotion-web-infinity';
import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {stylesWhenKayo, stylesWhenFlash} from '../../../../utils/styles-when-brand';
import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';

import prependQueryString from '../../../../../../todo-move-to-widgets-common/utils/prepend-query-string';
import SOCIAL_ICON_LIST from './social-icon-list';

const {white, vader, ares, active} = ionicPalette;
const {coal, kayoGreen, active: muiActive} = muiPalette;
const {flash, buttonActiveState} = infinityPalette;

const BrandedSocialFooterContainer = styled.div`
    display: flex;
    background-color: ${white};

    ${stylesWhenKayo`
        background-color: ${coal};
    `}
`;

const StyledFooter = styled.div`
    margin: 0 auto;
    padding: 20px 0;
`;

const Link = styled.a`
    margin: 0 15px;
    text-decoration: none;
    color: ${vader};
    ${stylesWhenKayo`
        color: ${white};
    `}
    &:hover {
        color: ${ares};
        ${stylesWhenKayo`
            color: ${kayoGreen};
        `}
        ${stylesWhenFlash`
            color: ${flash};
        `}
    }

    &:active {
        color: ${active};
        ${stylesWhenKayo`
            color: ${muiActive};
        `}
        ${stylesWhenFlash`
            color: ${buttonActiveState};
        `}
    }
`;

const StyledNav = styled(Nav)`
    display: flex;
`;

const StyledIcon = styled.div`
    display: inline-block;
    width: 21px;
    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 28px;
    `}
`;

const SocialMediaFooter = ({
    socialMedia,
    brand,
    userId,
}) => (
    <BrandedSocialFooterContainer brand={brand}>
        <StyledFooter>
            <StyledNav>
                {
                    getSocialMediaLinks(socialMedia, userId)
                        .map(({title, href, icon, ...commonAttrs}) => (
                            <Link
                                brand={brand}
                                key={title}
                                href={href}
                                {...commonAttrs}
                            >
                                <StyledIcon className={`icon_${title}`}>
                                    {icon}
                                </StyledIcon>
                            </Link>
                        ))
                }
            </StyledNav>
        </StyledFooter>
    </BrandedSocialFooterContainer>
);

SocialMediaFooter.displayName = 'SocialMediaFooter';

SocialMediaFooter.propTypes = {
    /** list of social media links like Facebook, Instagram etc */
    socialMedia: propTypes.arrayOf(propTypes.shape({
        title: propTypes.string,
        href: propTypes.string,
        icon: propTypes.node,
    })),
    /** brand name */
    brand: propTypes.string,
    /** user id */
    userId: propTypes.string,
};

/**
 * Get a list of social media links with title, href and icon.
 *
 * @param {Array} links         - links from marketing config (if any)
 * @param {Array} socialMedia   - social media list from marketing config (if any)
 * @param {string} userId       - auth0 id to be prepended to query params
 *
 * @returns {Array} list of social media links with title, href, target, rel and icon.
 */
function getSocialMediaLinks(socialMedia, userId) {
    const parameters = userId ? {userId} : {};
    const attributes = {target: '_blank', rel: 'noopener noreferrer'};
    const titlesUrlsEntries = Array.isArray(socialMedia) ? socialMedia.map(Object.values) : [];

    return titlesUrlsEntries.map(([title, url, icon]) => ({
        href: prependQueryString({url, parameters}),
        title,
        icon: SOCIAL_ICON_LIST[icon],
        ...attributes,
    }));
}

export default SocialMediaFooter;
