import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';

import {buttonType, classNameType} from '@fsa-streamotion/custom-prop-types';
import {A, Button} from '../../../../common/normalized-styled-components';
import {transition} from '../../../../common/animations';
import {flash, white, black, buttonActiveState, blueCharcoal} from '../../../../common/palette';

const baseStyles = css`
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    transform-origin: left center;
    transition: ${transition('background-color', 'color')};
    box-shadow: 0 1px 1px 0 ${rgba(black, 0.3)};
    background-color: ${flash};
    padding: 0 21px;
    width: 100%;
    height: 40px;
    overflow: hidden;
    text-align: center;
    text-decoration: underline;
    text-shadow: 0 1px 1px ${rgba(flash, 0.3)};
    white-space: nowrap;
    color: ${white};
    font: var(--infinity-nav-copy-default);
    font-weight: normal;
    object-fit: contain;

    &:hover,
    &:focus {
        outline: 0;
        /** Disable the stylelint, this structure is more readable */
        /* stylelint-disable */
        background-image: linear-gradient(
            to bottom,
            ${rgba(blueCharcoal, 0)},
            ${rgba(blueCharcoal, 0.6)}
        ), linear-gradient(
            to bottom,
            ${flash},
            ${flash}
        );
        text-decoration: none;
        text-shadow: currentColor 0 0 1px;
        color: ${white};
    }

    &[aria-pressed='true'],
    &[aria-current='true'],
    &:active {
        background-color: ${buttonActiveState};
        text-decoration: none;
        text-shadow: currentColor 0 0 1px;
        color: ${white};
    }
`;

const StyledButton = styled(Button)`
    ${baseStyles}
    appearance: none;
    border: 0;
`;

const StyledLink = styled(A)`
    ${baseStyles}
`;

const StyledSpan = styled.span`
    width: 100%;
    max-width: 278px;
    mask-image: linear-gradient(to left, transparent, rgb(0, 0, 0) 21px);
`;

const BA52ConversionBtn = React.forwardRef(({
    children,
    className,
    href,
    onClick,
    type = 'button',
    ...htmlAttributes
}, ref) => {
    const StyledElement = href ? StyledLink : StyledButton;

    return (
        <StyledElement
            {...htmlAttributes}
            {...{
                ref,
                href,
                onClick,
                type: href ? undefined : type,
            }}
            className={classnames('BA52ConversionBtn', className)}
            href={href}
        >
            <StyledSpan>{children}</StyledSpan>
        </StyledElement>
    );
});

BA52ConversionBtn.propTypes = {
    /** Content of button, e.g. text */
    children: propTypes.node.isRequired,
    /** additional CSS classnames to be applied */
    className: classNameType,
    /** If it’s actually a link, provide a href */
    href: propTypes.string,
    /** Callback on element click */
    onClick: propTypes.func,
    /** The HTML type of the button */
    type: buttonType,
};

BA52ConversionBtn.displayName = 'BA52ConversionBtn';

export default BA52ConversionBtn;
