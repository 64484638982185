import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {kayoGreen, silverLining} from '../../../../common/palette';

import {IC50Success} from '../../ic';

const StyledListItem = styled.li`
    display: flex;
    align-items: first baseline;
    list-style: none;
    color: ${silverLining};
    font: var(--mui-body-copy-4);
`;

const IconContainer = styled.div`
    flex-shrink: 0;
    transform: translateY(0.2em);
    margin-right: 7px;
    width: 17px;
`;

const CA01Feature = ({
    icon = <IC50Success color={kayoGreen} />,
    children,
    ...htmlAttributes
}) => (
    <StyledListItem {...htmlAttributes}>
        {!!icon && <IconContainer>{icon}</IconContainer>}

        <div>{children}</div>
    </StyledListItem>
);

CA01Feature.displayName = 'CA01Feature';

CA01Feature.propTypes = {
    /** IC icon to use */
    icon: propTypes.node,
    /** Content of item, e.g. text */
    children: propTypes.node,
};

export default CA01Feature;
