/* eslint-disable arrow-body-style */
import React from 'react';
import bacon from 'baconjs';
import styled from 'styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {screenSizes} from '@fsa-streamotion/streamotion-web-ionic';

import {BrandedTM19PmntTelstra} from '../../utils/branded-components';
// import {Paragraph} from '../common/styled-elements';

const {SCREEN_1920_DESKTOP, SCREEN_1280_DESKTOP} = screenSizes;

const StyledBrandedTM19PmntTelstra = styled(BrandedTM19PmntTelstra)`
    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        width: 333px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 400px;
    `}
`;

export default function getPaymentTbillStream({
    user,
    brand,
    paymentMethod,
    telstraOrderItemId,

    startSubscription$,
}) {
    const paymentDetails$ = startSubscription$
        .map(() => {
            return {
                telstraOrderItemId,
            };
        });

    const paymentSuccess$ = startSubscription$
        .map(true);

    const userDetails$ = bacon.fromPromise(user.getUserDetails());

    const reactElement$ = userDetails$.map(({firstName, lastName}) => (
        <React.Fragment>
            {/* <Paragraph>
                Insert words about telstra payment here that's not in zeplin.
            </Paragraph> */}

            <StyledBrandedTM19PmntTelstra
                name={`${firstName} ${lastName}`}
                isSelected={true}
                brand={brand}
            />
        </React.Fragment>
    ));

    return bacon.combineTemplate({
        label: 'Pay with Telstra',
        reactElement: reactElement$,

        paymentLoaded: bacon.later(1000, true).startWith(false),
        paymentErrors: null,

        paymentMethod,

        paymentDetails: paymentDetails$.startWith(null),
        paymentSuccessful: paymentSuccess$.startWith(false),
    });
}
