import React from 'react';

import Ic from '..';

const IC117SwipeD = (args) => (
    <Ic {...args}>
        <path d="M33.174 22.338l-6.412 6.398V6h-4.2v22.932l-6.594-6.594L13 25.306 24.578 36.87l11.564-11.564zM87.2 53.082a6.888 6.888 0 00-6.888-6.888h-.476a6.998 6.998 0 00-3.304.868 7.071 7.071 0 00-10.248-3.192 7.112 7.112 0 00-6.412-3.934h-.154a8.077 8.077 0 00-2.66.658v-3.01c0-1.4 0-2.702.07-3.976.14-5.236.266-9.8-2.268-12.362a6.844 6.844 0 00-5.124-1.918 8.4 8.4 0 00-6.216 2.366A9.421 9.421 0 0041 28.036v26.6a14.76 14.76 0 00-4.466 5.6c-3.626 8.022-.14 18.466 1.512 21.826l.084.182c1.75 3.57 5.404 11.018 25.704 11.018 16.408 0 21.266-14.896 21.644-23.464a6.901 6.901 0 001.722-4.41V53.082zm-7.364-2.688h.476A2.685 2.685 0 0183 53.082v12.306a2.688 2.688 0 01-2.688 2.674h-.476a2.688 2.688 0 01-2.688-2.674V53.082c0-1.48 1.195-2.68 2.674-2.688h.014zm-19.852-6.258a2.87 2.87 0 012.8 2.87v1.204a39.651 39.651 0 00-5.866.896v-2.59a3.051 3.051 0 013.066-2.38zM45.2 28.022a4.295 4.295 0 014.508-4.494 2.66 2.66 0 012.114.644c1.302 1.4 1.19 5.222 1.078 9.324 0 1.316-.07 2.688-.07 4.088v12.474c-1.4.35-3.038.77-4.816 1.246-.957.235-1.897.535-2.814.896V28.022zm18.648 61.11c-17.682 0-20.454-5.6-21.938-8.666l-.084-.196c-1.316-2.66-4.396-11.732-1.4-18.2 1.512-3.332 4.354-5.516 8.694-6.692 8.89-2.38 12.6-2.884 14.14-2.954.182 1.876 0 4.396-1.134 5.068-3.178 1.848-14.56 6.692-14.658 6.734l1.68 3.85c.42-.182 11.704-4.984 15.092-7 4.2-2.422 3.29-8.904 3.01-10.458a2.508 2.508 0 00-.126-.56v-.154a2.94 2.94 0 015.866 0v20.902a2.94 2.94 0 01-5.866 0v-4.69l-4.2 1.918v4.802a2.871 2.871 0 01-2.8 2.8h-.126a2.871 2.871 0 01-2.8-2.8v-2.1l-4.2 1.932v.168a7.084 7.084 0 007 7h.126a7.082 7.082 0 005.95-3.276 7.012 7.012 0 004.06 1.288 7.113 7.113 0 007-6.258 6.856 6.856 0 002.8.588h.476c.238.014.476.014.714 0-.924 5.712-4.298 16.954-17.276 16.954z" />
    </Ic>
);

IC117SwipeD.displayName = 'IC117SwipeD';

export default IC117SwipeD;
