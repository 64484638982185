/**
 * @typedef {Object} newCustomEvent~params
 * @property {boolean} [bubbles] bubbles
 * @property {boolean} [cancelable] cancelable
 * @property {boolean} [detail] detail
 */

/**
 * Create a new CustomEvent using the constructor, falling back to an alternative method
 * on IE11/safari.
 *
 * @param {string} type - the event type
 * @param {newCustomEvent~params} [params] - optional event params
 * @returns {CustomEvent} a new custom event
 */
export default function newCustomEvent(type, params) {
    if (typeof CustomEvent === 'function') {
        return new CustomEvent(type, params);
    } else {
        const {bubbles, cancelable, detail} = params || {};
        const event = document.createEvent('CustomEvent');

        event.initCustomEvent(type, bubbles, cancelable, detail);

        return event;
    }
}
