import bacon from 'baconjs';
import noop from 'lodash/noop';

import getMarketingConfigStream from '../../../../../todo-move-to-widgets-common/streams/endpoints/resources/marketing-config';

import DEFAULT_MARKETING_CONFIG_WHEN_BROKE from '../backup-config';

/*
    The poster images are center cropped with a fixed height, so instead of getting image manager to resize them,
    We get it to crop them to our predefined breakpoints with fixed heights
*/
export default function getMarketingConfigStreamWithFallback({
    brand,
    resourcesEnv,
    marketingConfigName,
    defaultMarketingConfigName,
    // used primarily to disable retries while testing
    retries,

    onMarketingConfigNameUsed = noop,
    onDefaultMarketingConfigUsed = noop,
}) {
    return getMarketingConfigStream({resourcesEnv, marketingConfigName, retries, brand})
        .doAction(() => void onMarketingConfigNameUsed())
        .flatMapError((error) => {
            // Missed marketing config - goto ares-default config.
            // otherwise let the original error proceed.
            if (
                marketingConfigName !== defaultMarketingConfigName
                && error.statusCode === 404
            ) {
                // This is us no longer seeing a specific marketing name we were looking for.
                // If someone asks us to do something specific when this happens (like,
                // forgetting this from local storage), do that.
                onDefaultMarketingConfigUsed();

                return getMarketingConfigStream({
                    brand,
                    resourcesEnv,
                    marketingConfigName: defaultMarketingConfigName,
                    retries,
                });
            } else {
                return new bacon.Error(error);
            }
        })
        .flatMapError((error) => {
            // Is this offer 404 or is it invalid JSON? Fall back to a safe default.
            // Otherwise let the error propagate and FISO will 400 us.
            if (error.type === 'syntax-error' || error.statusCode === 404) {
                console.error('Accounts Widgets: Marketing config error!', error);

                return DEFAULT_MARKETING_CONFIG_WHEN_BROKE;
            } else {
                return new bacon.Error(error.message); // only send back a string for FISO error.
            }
        });
}
