import React from 'react';
import {midnight} from '../../../../common/palette';
import Ic from '..';

const IC134Minus = (args) => (
    <Ic {...args}>
        <path d="M50 7.6C26.6 7.6 7.6 26.6 7.6 50s19 42.4 42.4 42.4 42.4-19 42.4-42.4S73.4 7.6 50 7.6z" />
        <path fill={midnight} d="M68.7 52.8H31.3v-5.6h37.4v5.6z" />
    </Ic>
);

IC134Minus.displayName = 'IC134Minus';

export default IC134Minus;
