import React, {useEffect, useRef} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import TargetAemWrapper from '@fsa-streamotion/target-aem-wrapper';

import aemModuleType from '../../custom-proptypes/aem-module-type';
import brandPropType from '../../../common/brand-proptype';
import termTypePropType from '../../../common/term-type-proptype';

import {
    BrandedBA46BtnPrMarketing,
    BrandedBA08BtnSec,
    BrandedOR08MarBlk,
} from '../../../utils/branded-components';
import useHtml from '../utils/use-html';
import FixedWidthContainer from '../../branded/landing/fixed-width-container';
import BrandedHeroBannerLoadingSkeleton from '../../branded/landing/loading-skeleton/hero-banner';

import {getTermtypeBasedCopy} from '../../../utils/get-term-type-based-copy';
import Poster from './poster';

const StyledBrandedBA46BtnPrMarketing = styled(BrandedBA46BtnPrMarketing)`
    min-width: var(--acw-offer-button-width);
`;

const StyledBrandedBA28BtnSec = styled(BrandedBA08BtnSec)`
    min-width: var(--acw-offer-button-width);
`;

/*
 * AEM Module Type: `hero-banner`
 * - this is the top hero banner for the landing page, we use some props from the AEM endpoint response as well as have our own react components
 */
const HeroBanner = ({
    moduleKey,
    brand,
    isLoading,
    commonWidgetSettings,

    // from aem
    module,
    termType,

    // from auth/billing
    userProps,
    billingProps,

    // for adobe target
    adobeTargetParams,
}) => {
    const {clientSideFeatureFlags: {useAdobeTarget}} = commonWidgetSettings;
    const {alignment, id: moduleId, cta, sectionTitle, url: {_publishUrl: publishUrl}} = module;
    const {signInWords, onSignIn, isSignInLoading, onResubscribe, userIsAuthenticated} = userProps;
    const {offerDisplay = {}, onClickOfferCta} = billingProps;
    const displayResubscribeButton = !!cta && !userIsAuthenticated;
    const onReadyForTargetRef = useRef();
    const HERO_BANNER_AEM_BG_ID = `${moduleId}-background`;

    const html = useHtml(publishUrl);
    const getCopy = getTermtypeBasedCopy(termType);

    useEffect(function invokeOnReadyForTargetWhenLoaded() {
        if (!isLoading) {
            try {
                onReadyForTargetRef.current?.({selector: `#${HERO_BANNER_AEM_BG_ID}`});
            } catch (e) {
                console.error('[HERO BANNER] Error while running onReadyForTarget - ignoring');
            }
        }
    }, [isLoading, HERO_BANNER_AEM_BG_ID]);

    const Component = (
        <BrandedOR08MarBlk
            poster={(
                <Poster
                    onSignIn={onSignIn}
                    isSignInLoading={isSignInLoading}
                    brand={brand}
                    signInWords={signInWords}
                    html={html}
                    bannerBackgroundId={HERO_BANNER_AEM_BG_ID}
                />
            )}
            brand={brand}
            title={sectionTitle}
        >
            <FixedWidthContainer>
                <StyledBrandedBA46BtnPrMarketing
                    primaryText={getCopy(offerDisplay.ctaHeader)}
                    subText={getCopy(offerDisplay.ctaSubtext)}
                    href="#products"
                    isBlock={true}
                    onClick={onClickOfferCta}
                    brand={brand}
                />
                {!!displayResubscribeButton && !!onResubscribe && (
                    <StyledBrandedBA28BtnSec
                        onClick={onResubscribe}
                        isBlock={true}
                        id="resubscribe"
                        brand={brand}
                    >
                        {cta}
                    </StyledBrandedBA28BtnSec>
                )}
            </FixedWidthContainer>
        </BrandedOR08MarBlk>
    );

    if (useAdobeTarget) {
        return (
            <TargetAemWrapper
                alignment={alignment}
                data-module={moduleKey}
                brand={brand}
                loadingEl={<BrandedHeroBannerLoadingSkeleton brand={brand} />}
                moduleId={moduleId}
                adobeTargetParams={adobeTargetParams}
                render={({onReadyForTarget}) => {
                    onReadyForTargetRef.current = onReadyForTarget;

                    return Component;
                }}
            />
        );
    } else {
        // Note: we won't have loading skeleton or spinner in this case but is ok as the feature flag
        // is temporary and only for development purposes
        return Component;
    }
};

HeroBanner.displayName = 'HeroBanner';

HeroBanner.propTypes = {
    /** Module key info for reference */
    moduleKey: propTypes.string,
    /** AEM Module object */
    module: aemModuleType,
    /** AEM term type (monthly | annual) */
    termType: termTypePropType,
    /** The brand, Flash | Kayo | Binge | Lifestyle */
    brand: brandPropType,
    /** Globalish settings object */
    commonWidgetSettings: propTypes.shape({ // @TODO: complete this shape later
        /** The brand, Flash | Kayo | Binge | Lifestyle */
        brand: brandPropType,
        /** The auth0 user object */
        user: propTypes.object,
        /** Handler helper for our SPA routing */
        newRouteHandler: propTypes.func,
        /** Object containing all the clientside feature flags */
        clientSideFeatureFlags: propTypes.object,
    }),
    /** Whether we're in a loading state */
    isLoading: propTypes.bool,
    /** Representing props calculated from user streams */
    userProps: propTypes.shape({
        /** Whether the user is authenticated or not */
        userIsAuthenticated: propTypes.bool,
        /** String to show on sign in button in landing page (not used in this component) */
        signInWords: propTypes.string,
        /** Callback for sign in action */
        onSignIn: propTypes.func,
        /** Used to show a loading spinner on sign in button as user streams come through */
        isSignInLoading: propTypes.bool,
        /** Callback if user clicks on resubscribe button (used in hero banner) */
        onResubscribe: propTypes.func,
    }).isRequired,
    /* Representing the props provided by billing api */
    billingProps: propTypes.shape({
        /** Footnote message usually used to display price/package change message */
        footnote: propTypes.node,
        /** Page header: `OR16MarBlkOfferDisplay` props */
        offerDisplay: propTypes.object,
        /** `PackageSelector` props */
        packageSelector: propTypes.object,
        /** Voucher images and toggle for `voucherForm` */
        voucherDisplay: propTypes.shape({
            voucherImages: propTypes.shape({
                imageAltText: propTypes.string,
                imageLinkMobile: propTypes.string,
                imageLinkDesktop: propTypes.string,
            }),
            /** Display voucherVorm? */
            isVoucherRequiredTemplate: propTypes.bool,
        }),
        /** Dispay continue button? */
        hasContinueButton: propTypes.bool,
        /** Callback when continue button is clicked */
        onContinue: propTypes.func,
        /** Is continue button loading? */
        isContinueLoading: propTypes.bool,
        /** If signup is disabled, we hide everything below page header */
        isSignUpDisabled: propTypes.bool,
        /** Callback for clicking on "Continue without voucher" button */
        onContinueWithoutVoucher: propTypes.func,
        /** Callback for clicking on Join now button */
        onClickOfferCta: propTypes.func,
    }).isRequired,
    /** Adobe Target Params */
    adobeTargetParams: propTypes.object,
};

export default HeroBanner;
