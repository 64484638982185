import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';

import {coal, white} from '../../../../common/palette';

const ContextualHelp = styled.div`
    position: relative;
    margin-top: 7px;
    border-radius: 4px;
    background-color: ${white};
    padding: 14px;
    color: ${coal};
    font: var(--mui-body-copy-4);

    a {
        color: inherit;
    }
`;

const StyledArrow = styled.div`
    position: absolute;
    top: -14px;
    right: 11px;
    border-right: 14px solid transparent;
    border-bottom: 14px solid ${white};
    border-left: 14px solid transparent;
    width: 0;
    height: 0;

    ${stylesWhen('isLeftAligned')`
        left: 11px;
    `}

    ${stylesWhen('hasDownArrow')`
        transform: rotate(180deg);
        top: unset;
        bottom: -14px;
    `}
`;

const IA05InputHelp = ({
    arrowOrientation = 'right',
    children,
    hasDownArrow,
    showArrow = true,
    ...htmlAttributes
}) => (
    <ContextualHelp {...htmlAttributes}>
        {!!showArrow && (
            <StyledArrow
                hasDownArrow={hasDownArrow}
                isLeftAligned={arrowOrientation === 'left'}
            />
        )}
        {children}
    </ContextualHelp>
);

IA05InputHelp.displayName = 'IA05InputHelp';

IA05InputHelp.propTypes = {
    /** Positioning of the arrow header */
    arrowOrientation: propTypes.oneOf(['left', 'right']),
    /** Whether to show the arrow in downward direction */
    hasDownArrow: propTypes.bool,
    /** Content for the help dialog */
    children: propTypes.node.isRequired,
    /** Whether to show the arrow head or not */
    showArrow: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
};

export default IA05InputHelp;
