import React from 'react';
import {rgba} from 'polished';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';
import {ink} from '../../../../../common/palette';

export const PayTodayTitle = styled.span`
    margin-top: 5px;
`;

const PayTodayContentWrapper = styled.ul`
    margin-top: 15px;
    padding: 0;
    width: 100%;
    color: ${rgba(ink, 0.7)};
    font: var(--quicksilver-body-copy-4-light);
`;

const LineItem = styled.li`
    display: flex;
    flex-direction: row;
    padding-top: 5px;
`;

const LineItemName = styled.div`
    flex: 1;
    margin-right: 20px;
`;

const PayTodayContent = ({lineItems, className}, ...htmlAttributes) => (
    <PayTodayContentWrapper
        {...htmlAttributes}
        className={classNames('PayTodayContent', className)}
    >
        {lineItems.map(({name, price}, index) => (
            <LineItem key={index}>
                <LineItemName>{name}</LineItemName>
                {price}
            </LineItem>
        ))}
    </PayTodayContentWrapper>
);

PayTodayContent.propTypes = {
    /** List of existing products */
    lineItems: propTypes.arrayOf(propTypes.shape({
        name: propTypes.string,
        price: propTypes.string,
    })),
    /** Additional class name(s) */
    className: classNameType,
};

PayTodayContent.displayName = 'PayTodayContent';
export default PayTodayContent;
