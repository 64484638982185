import styled from 'styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {SCREEN_768_TABLET} from '../../../../../common/screen-sizes';

import IC103Loading from '../../../../atoms/ic/103-loading';

export const ButtonIconBox = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledIC103Loading = styled(IC103Loading)`
    width: 30px;
    height: 30px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: 35px;
        height: 35px;
    `}
`;
