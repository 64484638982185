import React from 'react';

import Ic from '..';

const IC33Fav = (args) => (
    <Ic {...args}>
        <path d="M73.4 88.7c-.4 0-.9-.1-1.3-.3L50 76.8 27.9 88.4c-.9.5-2.1.4-2.9-.2-.9-.6-1.3-1.7-1.1-2.7l4.2-24.6-17.8-17.4c-.8-.7-1-1.8-.7-2.8.3-1 1.2-1.7 2.2-1.9l24.7-3.6 11-22.4c.5-.9 1.4-1.5 2.5-1.5s2 .6 2.5 1.5l11 22.4 24.7 3.6c1 .2 1.9.9 2.2 1.9.3 1 .1 2.1-.7 2.8L71.9 60.9l4.2 24.6c.2 1-.2 2.1-1.1 2.7-.5.3-1.1.5-1.6.5zM18.2 43.5L33 57.9c.7.6 1 1.6.8 2.5l-3.5 20.5 18.4-9.7c.8-.4 1.8-.4 2.6 0l18.4 9.7-3.5-20.5c-.2-.9.1-1.8.8-2.5l14.9-14.5-20.5-3c-.9-.1-1.7-.7-2.1-1.5L50 20.3 40.8 39c-.4.8-1.2 1.4-2.1 1.5l-20.5 3z" />
    </Ic>
);

IC33Fav.displayName = 'IC33Fav';

export default IC33Fav;
