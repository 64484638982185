// @TODO: This whole component could potentially change when given proper design assets.
import React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {colorType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {white, flash} from '../../../../common/palette';

const StyledSvg = styled.svg`
    display: inline-block;
`;

const GA92BrandLogo = ({
    className,
    title = 'Flash',
    width = '100%',
    height,
    backFillColor = flash,
    copyFillColor = white,
    ...htmlAttributes
}) => (
    <StyledSvg
        {...htmlAttributes}
        aria-label={title}
        className={classnames('GA92BrandLogo', className)}
        role="img"
        style={{width, height}}
        viewBox="0 0 234 64"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill={backFillColor}
            d="M17.3954545 0L0 64h216.808547L234 0z"
        />

        <path
            fill={copyFillColor}
            d="M27.011917 12.2916706l-10.8747064 39.408694h11.2792768l3.2640208-11.7931472-3.5920103-2.2808664h23.2947602l2.4866278-9.064158H33.7797834l1.8525291-6.7008611h21.1142416l2.662099-9.5696613zM133.958192 45.1695248l7.230785-7.2637446c3.933943 3.548157 8.042713 5.3487846 12.496849 5.3487846 2.662099 0 4.568471-1.1255528 4.568471-2.925324 0-1.5220763-1.096802-2.3089142-6.768081-4.4476144-6.420571-2.3656521-10.526982-5.0674499-10.526982-10.8673549 0-7.8236306 7.288059-13.3985107 17.180513-13.3985107 7.115591 0 12.726592 2.253675 16.60326 5.856643l-6.940978 7.5418677c-3.066455-2.5889642-6.595827-4.3349949-10.704598-4.3349949-2.71637 0-4.396432 1.2407416-4.396432 2.8165584 0 1.576245 1.214784 2.1393425 6.768296 4.2778287 6.248317 2.3084859 10.530199 5.0674499 10.530199 10.9793321 0 8.1049652-7.173509 13.6231073-17.24122 13.6231073-7.925375 0-14.461139-2.6472009-18.800082-7.2065783M63.5618707 12.2918633H74.841362l-7.8294883 28.5473341-.3588792 1.2891294h21.5333988l-6.6503136 9.5720164H52.6869498zM118.192796 36.1618263l-1.447315-11.3148355-7.57808 11.3148355h9.025395zm-3.586647-23.9374276h10.992259l6.306021 39.4752808H120.1026l-.985041-7.0367927H98.4954091l3.7316579 2.1498337-3.5866475 4.886959h-12.207043l28.1727725-39.4752808zM207.069426 12.2916706l-4.553884 16.2527516h-14.015597l4.518275-16.1910892-11.46247-.0616624-10.874278 39.408694 11.543771-.010277 3.11472-11.7145706-3.605525-2.2483223h18.373417l-3.915709 13.9731699h11.279491l10.87728-39.408694z"
        />
    </StyledSvg>
);

GA92BrandLogo.displayName = 'GA92BrandLogo';

GA92BrandLogo.propTypes = {
    /** Additional class(es) */
    className: classNameType,

    // Accessibility
    /** aria-label for logo */
    title: propTypes.string,

    // Logo color/styling
    /** Optional width of logo */
    width: propTypes.string,
    /** Optional height of logo */
    height: propTypes.string,
    /** Back-fill color value */
    backFillColor: colorType,
    /** Copy-fill `FLASH` color value */
    copyFillColor: colorType,
};

export default GA92BrandLogo;
