import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import noop from 'lodash/noop';
import propTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import {transition} from '../../../../common/animations';
import {ink} from '../../../../common/palette';

const StyledImg = styled.img`
    flex-shrink: 0;
    transition: ${transition('transform', 'border-color')};
    margin-right: 14px;
    border-radius: 50%;
    padding: 1px;
    width: 56px;
    height: 56px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 75px;
        height: 75px;
        padding: 1.2px;
    `}
`;

const StyledButton = styled.button`
    appearance: none;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background-color: transparent;
    padding: 0;
    color: ${ink};
    font: var(--quicksilver-header-4);
`;

const StyledDiv = styled.div`
    display: inline-block;
    max-width: 125px;
    overflow: hidden;
    white-space: nowrap;
    /* stylelint-disable-next-line color-no-hex */
    mask-image: linear-gradient(to right, #000 115px, transparent);

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
       max-width: 165px;
       mask-image: linear-gradient(to right, #000 155px, transparent);
    `}
`;

const NM12HorizAvatar = React.forwardRef(({
    imageUrl,
    isPressed,
    children,
    onClick = noop,
    ...htmlAttributes
}, ref) => (
    <StyledButton
        {...htmlAttributes}
        type="button"
        onClick={onClick}
        aria-pressed={isPressed}
        ref={ref}
    >
        {!!imageUrl && (
            <StyledImg
                alt="Avatar"
                src={imageUrl}
            />
        )}
        <StyledDiv>{children}</StyledDiv>
    </StyledButton>
));

NM12HorizAvatar.propTypes = {
    /** aria-pressed attribute describes a toggle button */
    isPressed: propTypes.oneOf([true, false, 'mixed']),
    /** Content of button, text */
    children: propTypes.node,
    /** Url of the image */
    imageUrl: propTypes.string,
    /** Callback on element click */
    onClick: propTypes.func,
};

NM12HorizAvatar.displayName = 'NM12HorizAvatar';

export default NM12HorizAvatar;
