import React, {createRef, useState} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';
import attempt from 'lodash/attempt';
import noop from 'lodash/noop';
import {Section, Sup} from 'normalized-styled-components';

import {mediaQuery, stylesWhenNot, stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {PERIOD_SHORT} from '../../../../common/billing-constants';
import {billingPeriodType} from '../../../../common/custom-proptypes';
import {slate, white, midnight, coal, silverLining, kayoGreen} from '../../../../common/palette';
import {SCREEN_PHABLET, SCREEN_375_PHABLET, SCREEN_LG_DESKTOP, SCREEN_PRETTY_LG_DESKTOP} from '../../../../common/screen-sizes';
import splitPrice from '../../../../common/split-price';
import VisuallyHidden, {visuallyHiddenBaseStyles} from '../../../../common/visually-hidden';

import IC25ArrowR from '../../../atoms/ic/25-arrow-r';
import IC31Tick from '../../../atoms/ic/31-tick';
import IC103Loading from '../../../atoms/ic/103-loading';

const Wrapper = styled(Section)`
    position: relative;
`;

const Table = styled.table`
    position: relative;
    border-bottom: 1px solid ${rgba(slate, 0.1)};
    width: 100%;
    table-layout: auto;
    border-collapse: collapse;
    color: ${slate};
    font: var(--mui-body-copy-3);

    ${stylesWhen('isDisabled')`
        opacity: 0.3;
        cursor: not-allowed;
    `}
`;

const StyledTableBody = styled.tbody`
    background-color: ${rgba(slate, 0.1)};
`;

const Package = styled.tr.attrs({
    role: 'button',
    tabIndex: 0,
})`
    transition: ${transition('background-color', 'color')};
    outline: 0;
    border-top: 1px solid ${rgba(slate, 0.1)};
    background-color: transparent;

    ${stylesWhenNot('isDisabled')`
        &:focus {
            box-shadow: 0 0 3px 1px ${slate};
        }

        &:hover {
            background-color: ${coal};
            color: ${slate};
        }
    `}

    &[aria-pressed='true'] {
        box-shadow: none;
        background-color: ${midnight};
        color: ${midnight};
    }
`;

const TableHeader = styled.th.attrs({
    scope: 'col',
})`
    padding: 0 0 5px;
    word-wrap: break-word;
    font: var(--mui-header-10);

    ${mediaQuery({minWidthPx: SCREEN_PHABLET})`
        padding-right: 5px;
        padding-left: 5px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
        padding-right: 24px;
        padding-left: 24px;
    `}
`;

const TableCellName = styled.th.attrs({
    scope: 'row',
})`
    padding: 28px 0 21px 15px; /* For 320 viewport, lessen right and left padding so decimal price, footnote marker and package period stay in place */
    text-align: left;
    color: ${white};

    ${mediaQuery({minWidthPx: SCREEN_375_PHABLET})`
        padding-right: 21px;
        padding-left: 21px;
    `}
`;

const CellNameWrapper = styled.span`
    display: block;
    position: relative;

    ${mediaQuery({minWidthPx: SCREEN_375_PHABLET})`
        padding-left: 14px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
        padding-right: 22px;
    `}
`;

const PackageName = styled.span`
    display: block;
    text-transform: uppercase;
    font: var(--mui-body-copy-5);
`;

const PackagePrice = styled.span`
    position: relative;
    font: var(--mui-plan-price);
`;

const SupContainer = styled(Sup)`
    position: absolute;
    top: 2px;

    ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
        top: 0;
    `}

    ${mediaQuery({minWidthPx: SCREEN_PRETTY_LG_DESKTOP})`
        top: -1px;
    `}
`;

const PackagePriceDecimals = styled.span`
    font: var(--mui-body-copy-6);
`;

const FootnoteMarker = styled.span`
    padding-left: 2px;
    font: var(--mui-footnote-marker-2);
`;

const PackagePeriod = styled.span`
    margin-left: 2px;
    font: var(--mui-body-copy-6);
`;

const PackageSecondaryText = styled.span`
    display: block;
    font: var(--mui-body-copy-6);
`;

const ArrowWrapper = styled.td`
    width: 14px;
    height: 14px;
    vertical-align: middle;

    ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
        width: 22px;
        height: 22px;
    `}
`;

const TableCellDark = styled.td`
    transition: ${transition('background-color')};
    margin: 0;
    background-color: ${rgba(coal, 0.54)};
    width: 54px;
    text-align: center;
    color: ${silverLining};
    font: var(--mui-body-copy-2);

    ${mediaQuery({minWidthPx: SCREEN_PHABLET})`
        width: 66px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
        width: 103px;
    `}

    /* stylelint-disable selector-type-no-unknown, selector-type-case */
    ${Package}:hover &,
    ${Package}[aria-pressed='true'] & {
        ${stylesWhenNot('isDisabled')`
            background-color: transparent;
        `}
    }
    /* stylelint-enable selector-type-no-unknown, selector-type-case */
`;

const TableCell = styled.td`
    transition: ${transition('background-color')};
    background-color: ${rgba(coal, 0.2)};
    width: 60px;
    text-align: center;
    color: ${silverLining};
    font: var(--mui-body-copy-2);

    ${mediaQuery({minWidthPx: SCREEN_PHABLET})`
        width: 66px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
        width: 103px;
    `}

    /* stylelint-disable selector-type-no-unknown, selector-type-case */
    ${Package}:hover &,
    ${Package}[aria-pressed='true'] & {
        ${stylesWhenNot('isDisabled')`
            background-color: transparent;
        `}
    }
    /* stylelint-enable selector-type-no-unknown, selector-type-case */
`;

const TableCellSelected = styled.td`
    position: relative;
    transition: ${transition('background-color')};
    background-color: ${coal};
    width: 21px;
    /* Force height determination by other table cells. This is so height 100%
    on the ArrowDiv works */
    height: 1px;
    vertical-align: top;
    /*
        Firefox won't respect the computed height though, reverts to 1px for children,
        but is fine with 100%, so use that here.
    */
    @-moz-document url-prefix() { /* stylelint-disable-line */
        height: 100%;
    }

    /* stylelint-disable rule-empty-line-before, declaration-block-semicolon-newline-after */
    ${Package}[aria-pressed='true'] & {
        ${stylesWhenNot('isDisabled')`
            background-color: ${midnight};
        `}
    }
    /* stylelint-enable rule-empty-line-before, declaration-block-semicolon-newline-after */
`;

const ArrowDiv = styled.table`
    margin: 0 auto;
    height: 100%;
`;

const TickWrapper = styled.span`
    position: absolute;
    transition: ${transition('opacity')};
    opacity: 0;
    width: 23px;
    height: 23px;

    > svg {
        /* Stupid svg’s style="display: block" overrides me */
        opacity: 0;
    }

    /* stylelint-disable-next-line rule-empty-line-before,declaration-block-semicolon-newline-after,order/order */
    ${Package}[aria-pressed='true'] & {
        transform: translate(calc(50% - 7px), calc(-50% - 1px)); /* 1px row border */
        opacity: 1;

        ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
            width: 30px;
            height: 30px;
        `}

        > svg {
            opacity: 1;
        }
    }
`;

const SelectedLabel = styled.span`
    display: none;

    /* stylelint-disable-next-line rule-empty-line-before,declaration-block-semicolon-newline-after */
    ${Package}[aria-pressed='true'] & {
        display: unset;

        ${visuallyHiddenBaseStyles}
    }
`;

const StyledUppercase = styled.span`
    text-transform: uppercase;
`;

const LoadingWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
`;

const OR94PackageSelector = ({
    className,
    defaultSelectedPackagePriceId,
    headers = [],
    isDisabled,
    isLoading,
    onChange = noop,
    packages = [],
    ...htmlAttributes
}) => {
    const packagesWithRefs = packages.map(({ref, ...otherProps}) => ({
        ref: ref || createRef(),
        ...otherProps,
    }));

    const [selectedPackagePriceId, setSelectedPackagePriceId] = useState(defaultSelectedPackagePriceId);

    const onPackageSelect = (packagePriceId) => {
        if (isDisabled || isLoading) {
            return;
        }

        const newSelectedPackagePriceId = packagePriceId === selectedPackagePriceId ? null : packagePriceId;

        setSelectedPackagePriceId(newSelectedPackagePriceId);

        if (onChange) {
            attempt(onChange(newSelectedPackagePriceId));
        }
    };

    const onKeyDown = (event, id) => {
        if (event.key === 'Enter' || event.key === ' ') { // Space key is ' '
            event.preventDefault(); // Stop the Space key from scrolling the page

            onPackageSelect(id);
        }
    };

    return (
        <Wrapper
            {...htmlAttributes}
            className={classnames('OR94PackageSelector', className)}
        >
            <Table isDisabled={isDisabled || isLoading}>
                <VisuallyHidden as="caption">Our streaming packages</VisuallyHidden>
                <thead>
                    <tr>
                        <VisuallyHidden as="th" scope="col">Package Name</VisuallyHidden>
                        {headers.map(({title, a11yTitle}) => (
                            <TableHeader key={title} {...(!!a11yTitle && {'aria-label': a11yTitle})}>{title}</TableHeader>
                        ))}
                        <VisuallyHidden as="th" scope="col">Selected?</VisuallyHidden>
                    </tr>
                </thead>
                <StyledTableBody>
                    {packagesWithRefs.map(({
                        ref,
                        displayName,
                        currencyPrefix = '$',
                        features,
                        prices,
                        currencyDecimalPlaces = 2,
                        footnoteMarker,
                    }) => {
                        const [{priceId, termType, displayAmount, displayRegularAmount}] = prices; // Picking the first price for now, the prices array should only contain one value. Note: when we do the payment period selection workflow, this component is likely to need refactoring
                        const [priceIntegers, priceDecimals] = splitPrice(displayAmount, currencyDecimalPlaces);

                        return (
                            <Package
                                key={priceId}
                                ref={ref}
                                isDisabled={isDisabled || isLoading}
                                onKeyDown={(event) => onKeyDown(event, priceId)}
                                onClick={() => onPackageSelect(priceId)}
                                aria-pressed={priceId === selectedPackagePriceId}
                            >
                                <TableCellName>
                                    <CellNameWrapper>
                                        <PackageName>
                                            {displayName}
                                        </PackageName>
                                        {displayAmount === 0
                                            ? (
                                                <PackagePrice>
                                                    <StyledUppercase>Free</StyledUppercase>
                                                </PackagePrice>
                                            )
                                            : (
                                                <PackagePrice aria-label={`${currencyPrefix}${displayAmount}${termType ? ` per ${termType}` : ''}`}>
                                                    {currencyPrefix}{priceIntegers}

                                                    {(!!priceDecimals || !!footnoteMarker) && (
                                                        <SupContainer>
                                                            {!!priceDecimals && (
                                                                <PackagePriceDecimals>
                                                                    .{priceDecimals}
                                                                </PackagePriceDecimals>
                                                            )}

                                                            {/* Only appear if there's no discount applied, because it doesn't make sense to display on both */}
                                                            {!!footnoteMarker && displayRegularAmount === displayAmount && (
                                                                <FootnoteMarker>
                                                                    {footnoteMarker}
                                                                </FootnoteMarker>
                                                            )}

                                                        </SupContainer>
                                                    )}

                                                    <PackagePeriod>
                                                        {PERIOD_SHORT[termType] ? `/${PERIOD_SHORT[termType]}` : ''}
                                                    </PackagePeriod>
                                                </PackagePrice>
                                            )}
                                        <PackageSecondaryText>
                                            {displayRegularAmount === displayAmount
                                                ? prices
                                                    .filter(({termType}) => termType === 'annual')
                                                    .map(({termType: annualTermType, displayAmount: annualDisplayAmount}) => (
                                                        <span
                                                            key={`${annualTermType}__${annualDisplayAmount}`}
                                                            aria-label={`Or ${currencyPrefix}${annualDisplayAmount}${annualTermType ? ` per ${annualTermType}` : ''}`}
                                                        >
                                                            Or {currencyPrefix}{annualDisplayAmount}{PERIOD_SHORT[annualTermType] ? `/${PERIOD_SHORT[annualTermType]}` : ''}
                                                        </span>
                                                    ))
                                                : (
                                                    <span aria-label={`Was ${currencyPrefix}${displayRegularAmount}${termType ? ` per ${termType}` : ''}`}>
                                                        was
                                                        {' '}
                                                        <del>
                                                            {`${currencyPrefix}${displayRegularAmount}${footnoteMarker || ''}${PERIOD_SHORT[termType] ? `/${PERIOD_SHORT[termType]}` : ''}`}
                                                        </del>
                                                    </span>
                                                )}
                                        </PackageSecondaryText>
                                    </CellNameWrapper>
                                </TableCellName>
                                {features.map(({value, a11yValue}, index) => {
                                    const CellElement = index % 2 ? TableCell : TableCellDark;

                                    return (
                                        <CellElement isDisabled={isDisabled || isLoading} key={index} {...(!!a11yValue && {'aria-label': a11yValue})}>
                                            {value}
                                        </CellElement>
                                    );
                                })}
                                <TableCellSelected>
                                    <TickWrapper>
                                        <IC31Tick color={kayoGreen} />
                                    </TickWrapper>
                                    <SelectedLabel>Selected</SelectedLabel>
                                    <ArrowDiv>
                                        <tbody>
                                            <tr>
                                                <ArrowWrapper>
                                                    <IC25ArrowR color={kayoGreen} />
                                                </ArrowWrapper>
                                            </tr>
                                        </tbody>
                                    </ArrowDiv>
                                </TableCellSelected>
                            </Package>
                        );
                    })}
                </StyledTableBody>
            </Table>
            {!!isLoading && (
                <LoadingWrapper>
                    <IC103Loading color={white} />
                </LoadingWrapper>
            )}
        </Wrapper>
    );
};

OR94PackageSelector.propTypes = {
    /** Whether a package is selectable */
    isDisabled: propTypes.bool,
    /** Whether component is in loading state */
    isLoading: propTypes.bool,
    /** Table th headings */
    headers: propTypes.arrayOf(propTypes.shape({
        title: propTypes.node,
        a11yTitle: propTypes.string,
    })),
    /** Foreground items */
    packages: propTypes.arrayOf(propTypes.shape({
        currencyDecimalPlaces: propTypes.number,
        currencyPrefix: propTypes.string,
        id: propTypes.string,
        features: propTypes.arrayOf(propTypes.shape({
            value: propTypes.node,
            a11yValue: propTypes.string,
        })),
        prices: propTypes.arrayOf(propTypes.shape({
            priceId: propTypes.string,
            termType: billingPeriodType,
            displayRegularAmount: propTypes.number,
            displayAmount: propTypes.number,
        })),
        footnoteMarker: propTypes.string,
        displayName: propTypes.string,
    })),
    /** className */
    className: classNameType,
    /** If provided, the package with this ID will be selected by default */
    defaultSelectedPackagePriceId: propTypes.string,
    /** What to do once package has been selected */
    onChange: propTypes.func,
};

OR94PackageSelector.displayName = 'OR94PackageSelector';

export default OR94PackageSelector;
