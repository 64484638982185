import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import noop from 'lodash/noop';
import {rgba} from 'polished';
import {stylesWhenNot, mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {white} from '../../../../../common/palette';
import {slate, black} from '../../../../../common/deprecated-mui-palette';
import {transition} from '../../../../../common/animations';
import {SCREEN_768_TABLET} from '../../../../../common/screen-sizes';

const STEP = 0.1;

const Slider = styled.label`
    ${stylesWhenNot('isVisible')`
        visibility: hidden;
    `}
    box-sizing: border-box;
    display: flex;
    position: absolute;
    top: -10px;
    left: 50%;
    align-items: center;
    touch-action: none;
    transform: rotate(-90deg);
    transform-origin: center left;
    /* this is required to sit above the progress bar in the player */
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    z-index: 1;
    border: 18px solid transparent;
    border-radius: 4px;
    background: transparent;
    width: 142px;
    height: 42px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: 150px;
    `}

    &::before {/* This element is to retain mouse "hover" state when mouse is in the gutter between button and slider */
        position: absolute;
        left: -20px;
        width: 30px;
        height: 42px;
        content: '';
    }
`;

const Level = styled.progress`
    appearance: none;
    transition: ${transition('color', 'background')};
    border: 0;
    border-radius: 5px;
    background: ${rgba(white, 0.5)};
    width: 100%;
    height: 100%;
    overflow: hidden;
    color: ${({isDisabled}) => isDisabled ? slate : white};

    &::-webkit-progress-bar {
        background-color: inherit;
    }

    &::-webkit-progress-value {
        transition: none;
        border-radius: 0;
        background: currentColor;
        min-width: 8px;
        max-width: 99%;
    }

    &::-moz-progress-bar {
        transition: none;
        border-radius: 0;
        background: currentColor;
        min-width: 8px;
        max-width: 99%;
    }

    &::-ms-fill {
        display: none;
    }
`;

const InteractiveControl = styled.input`
    appearance: none;
    box-sizing: border-box;
    display: block;
    position: absolute;
    left: 0;
    margin: 0;
    border: none;
    background: transparent;
    padding: 0;
    width: 100%;
    /* stylelint-disable-next-line plugin/declaration-block-no-ignored-properties */
    vertical-align: middle;
    touch-action: manipulation;

    ${['webkit-slider', 'moz-range', 'ms'].map((vendor) => css`
        &::-${vendor}-thumb {
            appearance: none;
            box-sizing: border-box;
            position: relative;
            transition: ${transition('background', 'border', 'transform')};
            border: 2px solid transparent;
            border-radius: 100%;
            box-shadow: 0 0 12px 0 ${black};
            background: ${({isDisabled}) => isDisabled ? slate : white};
            width: 10px;
            height: 10px;

            ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
                width: 20px;
                height: 20px;
            `}
        }
    `)}

    &::-ms-thumb {
        /* box-shadow on the thumb causes overflow cut off in edge */
        box-shadow: none;
    }
    /* stylelint-disable-next-line order/order */
    ${['webkit-slider-runnable', 'moz-range'].map((vendor) => css`
        &::-${vendor}-track {
            display: flex;
            align-items: center;
            border: 0;
            width: 100%;
            background: transparent !important;
            height: 100%;
            user-select: none;
        }
    `)}

    &::-ms-track {
        border: 0;
        background: transparent;
        height: 20px;
        color: transparent;
    }

    &::-ms-fill-upper {
        border: 0;
        border-radius: 4px;
        background: transparent;
        height: 8px;
        user-select: none;
    }

    &::-ms-fill-lower {
        border: 0;
        border-radius: 4px;
        background: ${({isDisabled}) => isDisabled ? slate : white};
        height: 6px;
        user-select: none;
    }

    &::-ms-tooltip {
        display: none;
    }

    &::-moz-focus-outer {
        border: 0;
    }

    &.tab-focus:focus {
        outline-offset: 3px;
    }

    &:active { /* Pressed styles */
        &::-webkit-slider-thumb {
            border-color: ${white};
        }

        &::-moz-range-thumb {
            border-color: ${white};
        }

        &::-ms-thumb {
            border-color: ${white};
        }
    }

    /* stylelint-disable-next-line order/order */
    &:focus,
    &:hover { /* Focus styles: e.g. pressed or keyboard focus */
        outline: 0;

        &::-webkit-slider-thumb {
            transform: scale(1.3);
        }

        &::-moz-range-thumb {
            transform: scale(1.3);
        }

        &::-ms-thumb {
            /*
            scaling causes overflow cut off in edge  but also
            edge seems to apply -webkit rule so explicitly override here
            */
            transform: scale(1);
        }
    }
`;

const visuallyHidden = css`
    /* stylelint-disable declaration-no-important */
    position: absolute !important;
    border: 0 !important;
    padding: 0 !important;
    width: 1px !important;
    height: 1px !important;
    /* stylelint-enable declaration-no-important */
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
`;

const HiddenText = styled.span` ${visuallyHidden} `;

const VPIA01VertRange = ({
    value,
    label,
    isDisabled,
    isVisible,
    onFocus,
    onValueChange,
    ...htmlAttributes
}) => (
    <Slider
        isVisible={isVisible}
        aria-hidden={!isVisible}
    >
        <HiddenText>{label}</HiddenText>
        <Level
            aria-hidden="true"
            max="1"
            value={value}
            isDisabled={isDisabled}
        />

        <InteractiveControl
            {...htmlAttributes}
            isDisabled={isDisabled}
            onChange={({target}) => void onValueChange(target.value)}
            onFocus={onFocus}
            min="0"
            max="1"
            step={STEP}
            type="range"
            value={value}
        />
    </Slider>
);

VPIA01VertRange.propTypes = {
    value: propTypes.number,
    label: propTypes.string,
    isDisabled: propTypes.bool,
    isVisible: propTypes.bool,
    onFocus: propTypes.func,
    onValueChange: propTypes.func,
};

VPIA01VertRange.defaultProps = {
    value: 1,
    label: '',
    isDisabled: false,
    isVisible: false,

    onFocus: noop,
    onValueChange: noop,
};

VPIA01VertRange.displayName = 'VPIA01VertRange';

export default VPIA01VertRange;
