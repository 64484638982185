import {useEffect, useState, useRef} from 'react';
import IntersectionObserver from 'inteobs';
import range from 'lodash/range';

/**
 * This hook does the following:
 *  - calculates contracted height of carousel container & translateY value
 *  - registers keyUp observer for expansion/contraction
 *  - registers intersection observer to make rows container stick on scroll
 *  - creates event callbacks and flags to be used by carousel controls
 *
 * @param {Object} options options
 * @param {boolean} options.isInitiallyExpanded is carousel initially expanded?
 * @param {number} options.numChildren number of children
 *
 * @returns {{contract: Function, contractedHeightPx: number, contractedTranslateYPx: number, expand: Function, handleKeyDown: Function, isExpanded: boolean, isSticky: boolean, rowsContainerRef: React.Ref, scrollSentinelRef: React.Ref}} flags and callbacks
 */
const useCascadeCarousel = ({
    isInitiallyExpanded,
    numChildren,
}) => {
    const rowsContainerRef = useRef(null);
    const scrollSentinelRef = useRef(null);

    const [isSticky, setIsSticky] = useState(false);
    const [isExpanded, setIsExpanded] = useState(isInitiallyExpanded);
    const [{contractedHeightPx, contractedTranslateYPx}, setContractedPxValues] = useState({
        contractedHeightPx: 0,
        contractedTranslateYPx: 0,
    });

    /**
     * Calculate contracted height & translateY value
     */
    useEffect(function calculateContractedPxValues() {
        const rowsContainer = rowsContainerRef.current;

        const rows = rowsContainer.children;
        const numRows = rows.length;

        const lastRowIndex = numRows - 1;
        const lastRowHeightPx = rows[lastRowIndex].clientHeight;

        /* --- MULTI ROW --- */
        if (numRows > 1) {
            // Use 30% peek height
            const peekHeightPx = lastRowHeightPx * 0.30;

            // Assume it's possible to have rows with different heights, let's get the individual row heights instead
            const expandedHeightPx = range(lastRowIndex).reduce((sum, index) => (
                sum + rows[index].clientHeight
            ), 0);

            setContractedPxValues({
                contractedHeightPx: peekHeightPx + lastRowHeightPx,
                contractedTranslateYPx: peekHeightPx - expandedHeightPx,
            });
        /* ---- SINGLE ROW --- */
        } else {
            setContractedPxValues({
                contractedHeightPx: lastRowHeightPx,
                contractedTranslateYPx: 0,
            });
        }
    }, [numChildren]); // recalculate when number of children changes

    /**
     * Register keyUp observer that controls expansion/contraction
     * when user tabs into/out of the carousel
     */
    useEffect(function setKeyUpListeners() {
        const rowsContainer = rowsContainerRef.current;

        const onKeyUp = ({key}) => {
            if (!!rowsContainer && key === 'Tab') {
                if (document.activeElement === rowsContainer || rowsContainer.contains(document.activeElement)) {
                    setIsExpanded(true); // expand
                } else {
                    setIsExpanded(false); // contract
                }
            }
        };

        document.addEventListener('keyup', onKeyUp);

        return () => {
            document.removeEventListener('keyup', onKeyUp);
        };
    }, []);

    /**
     *  Intersection observer to make container stick on scroll
     */
    useEffect(function setIntersectionObserver() {
        const observer = new IntersectionObserver(
            ([topSentinel]) => {
                if (!topSentinel.rootBounds) {
                    return;
                }

                if (topSentinel.boundingClientRect.bottom < topSentinel.rootBounds.top && !isSticky) {
                    setIsSticky(true);
                    setIsExpanded(false);
                } else if (topSentinel.boundingClientRect.bottom >= topSentinel.rootBounds.top && isSticky) {
                    setIsSticky(false);
                }
            }
        );

        if (scrollSentinelRef.current) {
            observer.observe(scrollSentinelRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Event callback for onMouseOut & onBlur
     *
     * @returns {void} nothing
     */
    const contract = () => void setIsExpanded(false);

    /**
     * Event callback for onMouseOver & onFocus
     *
     * @returns {void} nothing
     */
    const expand = () => void setIsExpanded(true);

    /**
     * Event callback for onKeyDown
     * - when user navigates up and down the rows, we always set focus on first item in the target row
     *
     * @param {KeyboardEvent} e event
     */
    const handleKeyDown = (e) => {
        const childrenArray = [...rowsContainerRef.current.childNodes];
        const currentRowIndex = childrenArray.findIndex((node) => node.contains(document.activeElement));
        const isArrowDown = ['ArrowDown', 'Down'].includes(e.key);
        const isArrowUp = ['ArrowUp', 'Up'].includes(e.key);

        if (isArrowDown || isArrowUp) {
            e.preventDefault();
            e.stopPropagation();

            // When navigating up & down through rows, always focus on the first item/child in the target row
            const targetChild = isArrowDown
                ? childrenArray[currentRowIndex + 1]
                : childrenArray[currentRowIndex - 1];

            targetChild?.querySelector('a, button')?.focus();
        }
    };

    return {
        contract,
        contractedHeightPx,
        contractedTranslateYPx,
        expand,
        handleKeyDown,
        isExpanded,
        isSticky,
        rowsContainerRef,
        scrollSentinelRef,
    };
};

export default useCascadeCarousel;
