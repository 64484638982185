import React from 'react';
import Ic from '..';

const IC159Manhattan = (args) => (
    <Ic {...args}>
        <path fillRule="nonzero" d="M27.9 35.7h5.6v47.6h-5.6V35.7zm58 0h5.6v47.6h-5.6V35.7zM8.6 54.8h5.6v28.6H8.6V54.8zm38.6-38.1h5.6v66.7h-5.6V16.7zm19.3 38.1h5.6v28.6h-5.6V54.8z" />
    </Ic>
);

IC159Manhattan.displayName = 'IC159Manhattan';

export default IC159Manhattan;
