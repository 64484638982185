import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import {Button} from 'normalized-styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {transition} from '../../../../../common/animations';
import {blanc, onyx} from '../../../../../common/palette';
import {SCREEN_1920_DESKTOP, SCREEN_2560_DESKTOP} from '../../../../../common/screen-sizes';

const StyledButton = styled(Button)`
    transition: ${transition('color', 'border-left')};
    margin: 0;
    border: 0;
    border-left: 2px solid transparent;
    background-color: transparent;
    padding: 4px 24px 5px 38px;
    width: 100%;
    max-width: 250px;
    text-align: left;
    text-transform: capitalize;
    text-shadow: 0 1px 1px ${rgba(onyx, 0.3)};
    color: ${rgba(blanc, 0.6)};
    font: var(--nucleus-header-7-light);
    /* stylelint-disable-next-line color-no-hex */
    mask-image: linear-gradient(to left, transparent 24px, #000 42px);

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        border-left-width: 6px;
        padding: 7px 24px 7px 36px;
        max-width: 345px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
        border-left-width: 8px;
        padding: 9px 24px 10px 62px;
        max-width: 460px;
    `}

    &[aria-selected='true'] {
        border-left-color: ${blanc};
        color: ${blanc};
        font: var(--nucleus-header-7);
    }
    /* We explicitly put these at the end so the hover/focus state takes precedence over aria-selected */
    /* stylelint-disable-next-line order/order */
    &:hover,
    &:focus {
        outline: none;
        color: ${blanc};
    }
`;

const StyledLabel = styled.span`
    overflow-y: hidden;

    @supports (-webkit-line-clamp: 3) { /* Where supported, lets add nice ellipsis on overflow https://caniuse.com/#search=line-clamp */
        /* stylelint-disable-next-line value-no-vendor-prefix */
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
`;

const VPBA02TraySectionListItem = ({
    label,
    onClick,
    isActive,
}) => (
    <StyledButton
        onClick={onClick}
        aria-selected={isActive}
    >
        <StyledLabel>{label}</StyledLabel>
    </StyledButton>
);

VPBA02TraySectionListItem.propTypes = {
    /** Button label */
    label: propTypes.string.isRequired,
    /** Function to execute when button is clicked */
    onClick: propTypes.func,
    /** is button active? */
    isActive: propTypes.bool,
};

VPBA02TraySectionListItem.displayName = 'VPBA02TraySectionListItem';

export default VPBA02TraySectionListItem;
