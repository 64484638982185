import React, {Fragment} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import noop from 'lodash/noop';
import toLower from 'lodash/toLower';

import {stylesWhen, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';

import {ink, cobalt} from '../../../../common/palette';
import BA22EditBtn from '../../../atoms/ba/22-edit-btn';
import IC323RemoveC from '../../../atoms/ic/323-remove-c';
import IC49Open from '../../../atoms/ic/49-open';
import BA38BtnIconPrimary from '../../../atoms/ba/38-btn-icon-primary';
import IC50Success from '../../../atoms/ic/50-success';
import {getShortTermTypeText} from '../../../../common/price-term';

// === MID CONTENT: pricing & tier details, product description

const SubscriptionText = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    color: ${ink};
    font: var(--quicksilver-body-copy-4-light);
`;

// ===== pricing detail: regular

const SubscriptionLineTop = styled.div`
    opacity: 0.8;
`;

const TopText = styled.span`
    vertical-align: super;
`;

const BoldText = styled.span`
    font: var(--quicksilver-body-copy-1);
`;

// ===== pricing detail: discounted

const SubscriptionLineStrike = styled.span`
    opacity: 0.3;
    text-decoration: line-through;

    ${stylesWhen('isSelected')`
        opacity: 0.5;
    `}
`;

// ===== tier screen number and description

const SubscriptionLineTier = styled.div`
    opacity: 0.6;

    ${stylesWhen('isSelected')`
        opacity: 0.8;
    `}
`;

const SubscriptionLineDescription = styled.div`
    opacity: 0.3;

    ${stylesWhen('isSelected')`
        opacity: 0.5;
    `}
`;

// === RIGHT CONTENT: custom buttons / base controls

// ===== custom buttons

const SubscriptionButtons = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: space-around;
    gap: 7px;
    max-width: 251px;

    ${stylesWhenNot('isButtonSectionNarrow')`
        flex-grow: 1;
    `}
`;

// ===== base controls

const SubscriptionControls = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: space-between;
`;

const StyledBA22EditBtn = styled(BA22EditBtn)`
    margin-bottom: 14px;

    &:disabled {
        opacity: 0.4;
        color: ${rgba(ink, 0.8)};
    }
`;

const StyledBA38BtnIconPrimary = styled(BA38BtnIconPrimary)`
    margin-top: 14px;
    cursor: pointer;

    &:disabled {
        cursor: default;
    }
`;

/**
 * ProductDetailsAndControlsPanel
 *  - The main part of OR139SubscriptionCard
 *  - Will be shown if the product is available
 *  - Used on OR139SubscriptionCard
 *
 * @param {boolean} isSelected - Subscriptions that user already selected.
 * @param {boolean} isPreviewCancellation - Subscriptions that user is subscribed to, and is cancelling but not yet completed checkout to confirm.
 * @param {object} price - See below
 * @param {string} price.termType - Term period
 * @param {number} price.discountedAmount - Discounted price
 * @param {number} price.regularAmount - Regular price
 * @param {React.ReactNode} statusDescription - Text on the bottom of tile
 * @param {array} buttons - Any buttons that should show up on right side of subscription card
 * @param {boolean} isInDunning - Subscription in dunning payment error flag
 * @param {boolean} isAddButtonDisabled - Whether or not the add button is disabled
 * @param {boolean} isEditButtonDisabled - Whether or not the edit button is disabled
 * @param {boolean} isButtonSectionNarrow - Is button section narrow (responsive, aka as wide as content) or wide (always 100% width of parent element)?
 * @param {Function} handleAddOnClick - Add button on click handler
 * @param {Function} handleEditOnClick - Edit button on click handler
 * @param {Function} handleRemoveOnClick - Remove button on click handler
 * @param {string} editHref - Edit button href
 * @param {object} values - See below
 * @param {string} value.tierDisplayName - Tier display name, e.g. "Basic","Standard","Premium", "All Access", etc
 * @returns {React.ReactElement} Product details information like product name, price, and action buttons
 */
const ProductDetailsAndControlsPanel = ({
    isSelected,
    isPreviewCancellation,
    price,
    statusDescription,
    buttons,
    isInDunning,
    isAddButtonDisabled,
    isEditButtonDisabled,
    isButtonSectionNarrow = false,
    handleAddOnClick = noop,
    handleEditOnClick = noop,
    handleRemoveOnClick = noop,
    editHref,
    values,
}) => {
    const btnPrimaryProps = {
        'aria-disabled': isAddButtonDisabled,
        'disabled': isAddButtonDisabled,
        ...isSelected ? {
            'aria-label': 'Remove subscription from basket',
            'backgroundColor': isAddButtonDisabled ? '' : cobalt,
            'children': isInDunning ? <IC323RemoveC /> : <IC50Success />,
            'onClick': handleRemoveOnClick,
        } : {
            'aria-label': 'Add subscription to basket',
            'children': isPreviewCancellation ? <IC323RemoveC /> : <IC49Open />,
            'onClick': handleAddOnClick,
        },
    };

    return (
        <Fragment>
            <SubscriptionText>
                {!!price
                && (
                    <SubscriptionLineTop>
                        <TopText>$</TopText>
                        <BoldText>{price.discountedAmount ? price.discountedAmount : price.regularAmount}</BoldText>
                        {getShortTermTypeText(toLower(price.termType))}
                    </SubscriptionLineTop>
                )
                }

                {!!price?.discountedAmount
                && (
                    <SubscriptionLineStrike isSelected={isSelected && !isInDunning}>
                        ${price.regularAmount}{getShortTermTypeText(toLower(price.termType))}
                    </SubscriptionLineStrike>
                )
                }
                {!!values?.tierDisplayName
                && (<SubscriptionLineTier isSelected={isSelected && !isInDunning}>{values.tierDisplayName}</SubscriptionLineTier>)}
                <SubscriptionLineDescription isSelected={isSelected && !isInDunning}>{statusDescription}</SubscriptionLineDescription>
            </SubscriptionText>

            {buttons
                ? (
                    <SubscriptionButtons isButtonSectionNarrow={!!isButtonSectionNarrow}>
                        {buttons}
                    </SubscriptionButtons>
                )
                : (
                    <SubscriptionControls>
                        <StyledBA22EditBtn
                            onClick={handleEditOnClick}
                            href={editHref}
                            disabled={isEditButtonDisabled}
                            aria-disabled={isEditButtonDisabled}
                        >
                            Edit
                        </StyledBA22EditBtn>
                        <StyledBA38BtnIconPrimary {...btnPrimaryProps} />
                    </SubscriptionControls>
                )}
        </Fragment>
    );
};

ProductDetailsAndControlsPanel.propTypes = {
    /** Whether or not the add button is disabled */
    isAddButtonDisabled: propTypes.bool,
    /** Whether or not the edit button is disabled */
    isEditButtonDisabled: propTypes.bool,
    /** Subscriptions that user already selected */
    isSelected: propTypes.bool,
    /** Subscriptions that user is subscribed to, and is cancelling but not yet completed checkout to confirm */
    isPreviewCancellation: propTypes.bool,
    /** Tier values */
    values: propTypes.shape({
        /** Tier display name, e.g. "Basic","Standard","Premium", "All Access", etc */
        tierDisplayName: propTypes.string,
    }),
    /** Text on the bottom of tile, e.g. "Charged on the 14th" */
    statusDescription: propTypes.node,
    price: propTypes.shape({
        /** term period */
        termType: propTypes.oneOf(['Daily', 'Weekly', 'Monthly', 'Annually']),
        /** Discounted price */
        discountedAmount: propTypes.number,
        /** Regular price */
        regularAmount: propTypes.number,
    }),
    /** Any buttons that should show up on right side of subscription card */
    buttons: propTypes.arrayOf(propTypes.node),
    /** Is button section narrow (responsive, aka as wide as content)
     *  or wide (always 100% width of parent element)? */
    isButtonSectionNarrow: propTypes.bool,
    /** Subscription in dunning payment error flag */
    isInDunning: propTypes.bool,
    /** Add button on click handler */
    handleAddOnClick: propTypes.func,
    /** Edit button on click handler */
    handleEditOnClick: propTypes.func,
    /** Remove button on click handler */
    handleRemoveOnClick: propTypes.func,
    /** Edit button href */
    editHref: propTypes.string,
};

ProductDetailsAndControlsPanel.displayName = 'ProductDetailsAndControlsPanel';
export default ProductDetailsAndControlsPanel;
