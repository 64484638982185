import React from 'react';
import classNames from 'classnames';
import {Hr} from 'normalized-styled-components';
import styled from 'styled-components';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {slate} from '../../../../common/palette';

const StyledHr = styled(Hr)`
    margin: 0 auto;
    border: 0;
    border-radius: 4px;
    background-color: ${slate};
    width: 180px;
    height: 4px;
`;

const GA03ContentDiv = ({className, ...htmlAttributes}) => (
    <StyledHr
        {...htmlAttributes}
        className={classNames('GA03ContentDiv', className)}
    />
);

GA03ContentDiv.displayName = 'GA03ContentDiv';

GA03ContentDiv.propTypes = {
    /** Additional class(es) */
    className: classNameType,
};

export default GA03ContentDiv;
