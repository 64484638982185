import React from 'react';
import {vader} from '../../../../common/palette';
import Ic from '..';

const IC19JLive = (args) => (
    <Ic {...args}>
        <g transform="translate(5 16)">
            <path d="M16.324 24.556H89.502V67.886H16.324z" />
            <path fill={vader} d="M41.062 52.472L33.222 52.472 33.222 38.948 36.4 38.948 36.4 49.854 41.09 49.854zM46.452 52.472L43.274 52.472 43.274 38.948 46.452 38.948zM61.446 38.948L56.434 52.472 53.41 52.472 48.384 38.948 51.8 38.948 54.978 48.258 58.142 38.948zM72.324 52.472L63.378 52.472 63.378 38.948 72.156 38.948 72.156 41.482 66.556 41.482 66.556 44.282 71.848 44.282 71.848 46.816 66.542 46.816 66.542 49.952 72.324 49.952z" />
            <path d="M64.12 0l-2.8 3.122 5.026 4.522H26.6C3.486 7.644 0 28.336 0 28.546l4.2.644c0-.714 2.94-17.346 22.4-17.346h39.634l-4.2 4.69 3.122 2.8 9.156-10.178L64.12 0z" />
        </g>
    </Ic>
);

IC19JLive.displayName = 'IC19JLive';

export default IC19JLive;
