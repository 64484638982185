import React from 'react';
import Ic from '..';

const IC137Blog = (args) => (
    <Ic {...args}>
        <g fillRule="nonzero">
            <path d="M33 61.3h34v2.8H33v-2.8zm0 8.2h34v2.8H33v-2.8zm0-16.2h34v2.8H33v-2.8zm16.1-5.5H35.3c-.8 0-1.4-.6-1.4-1.4v-9.1c0-.8.6-1.4 1.4-1.4h13.8c.8 0 1.4.6 1.4 1.4v9.1c0 .8-.7 1.4-1.4 1.4zM36.7 45h11v-6.3h-11V45zM33 27.7h34v2.8H33v-2.8z" />
            <path d="M74.9 87.9H19.4c-1.5 0-2.8-1.2-2.8-2.8V20.5c0-4.6 3.8-8.4 8.4-8.4h49.9c4.6 0 8.4 3.8 8.4 8.4v58.9c.1 4.7-3.7 8.5-8.4 8.5zm-52.7-5.6H75c1.6 0 2.9-1.3 2.9-2.9V20.5c0-1.6-1.3-2.9-2.9-2.9H25.1c-1.6 0-2.9 1.3-2.9 2.9v61.8z" />
        </g>
    </Ic>
);

IC137Blog.displayName = 'IC137Blog';

export default IC137Blog;
