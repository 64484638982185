import React from 'react';

import Ic from '..';

const IC308YouTube = (args) => (
    <Ic {...args}>
        <path d="M50.5723772,16.0009095 C52.6197075,16.0066264 59.8732584,16.0437859 67.6697187,16.2853231 L68.7357996,16.319543 C76.9281705,16.591857 85.4822515,17.0961421 89.0694825,18.0300035 C93.3723415,19.1470704 96.7604239,22.4384968 97.9104288,26.6183123 C99.8301118,33.5771427 99.986365,47.4813721 99.9990833,49.6987476 L99.9997996,49.8443824 C99.9998817,49.8648089 99.9999474,49.8832873 100,49.8997796 L100,50.1001033 C99.9999474,50.1165952 99.9998817,50.1350729 99.9997996,50.1554988 L99.9990833,50.301129 C99.986365,52.5184359 99.8301118,66.4222845 97.9104288,73.3821224 C96.7604239,77.5613895 93.3723415,80.8528159 89.0694825,81.9704312 C85.4822515,82.9040403 76.9281705,83.4081892 68.7357996,83.6804297 L67.6697187,83.7146403 C59.6960661,83.9616003 52.290281,83.9948449 50.4409088,83.9993201 L50.082954,83.9999614 C50.064684,83.9999797 50.0493769,83.9999919 50.0370874,84 L49.9629126,84 C49.9506231,83.9999919 49.935316,83.9999797 49.917046,83.9999614 L49.5590912,83.9993201 C47.709719,83.9948449 40.3039339,83.9616003 32.3302813,83.7146403 L31.2642004,83.6804297 C23.0718295,83.4081892 14.5177485,82.9040403 10.9305175,81.9704312 C6.62822669,80.8528159 3.23957608,77.5613895 2.08957124,73.3821224 C0.169888222,66.4222845 0.0136349524,52.5184359 0.000916663066,50.301129 L0.000916663066,49.6987476 C0.0136349524,47.4813721 0.169888222,33.5771427 2.08957124,26.6183123 C3.23957608,22.4384968 6.62822669,19.1470704 10.9305175,18.0300035 C14.5177485,17.0961421 23.0718295,16.591857 31.2642004,16.319543 L32.3302813,16.2853231 C40.1267416,16.0437859 47.3802925,16.0066264 49.4276228,16.0009095 Z M39.7726843,35.6491704 L39.7726843,64.3507159 L65.9091578,50.0004915 L39.7726843,35.6491704 Z" />
    </Ic>
);

IC308YouTube.displayName = 'IC308YouTube';

export default IC308YouTube;
