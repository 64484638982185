import React from 'react';
import Ic from '..';

const IC15Skf = (args) => (
    <Ic {...args}>
        <path d="M58.4 43.1h6.2V16.7H53.5v4.2h4.9zM82.8 25.6c-1.8 0-2.8.2-3.7.6l.8-4.7h11.4v-4.7h-16l-2.1 12.9 2.8 1.6c1.2-.8 3.2-1.4 5-1.4 2.5 0 5.2 1.3 5.2 4.4 0 3.2-2.8 4.5-5.2 4.5-2.3 0-5.2-1.1-6.7-3.2l-2.9 3.6c1.3 1.9 4.6 4.3 10.1 4.3 5.4 0 11-3.1 11-9.2-.1-6.2-5.5-8.7-9.7-8.7zM38.4 77.8c-14 .2-24.8-10.2-25.2-24.2-.3-11.5 6.6-20.3 17.7-23.1l-3.7 10.2 5.2 1.9 6-16.5c.5-1.4-.2-3-1.7-3.6l-16.5-6-1.9 5.2 9.9 3.6c-12.8 4-20.9 14.8-20.6 28.4.4 16.8 13.7 29.7 30.3 29.7h.6c8.3-.1 33.3 0 33.6 0v-5.6c-.3 0-25.4-.1-33.7 0z" /><path d="M36.5 83.3C20.6 83.3 7.9 71 7.5 55c-.3-12.8 7.2-23.1 19.1-26.9L18 20.2l3.8-4.1 12.5 11.4c.8.7 1.1 1.8.8 2.9-.3 1-1.2 1.8-2.3 1.9-12.3 1.7-20 10.6-19.7 22.5C13.4 68.1 23.7 78 37 77.7c7.9-.1 31.8 0 32.1 0v5.6c-.2 0-24.1-.1-31.9 0h-.7z" />
    </Ic>
);

IC15Skf.displayName = 'IC15Skf';

export default IC15Skf;
