import React, {useState} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';

import {stylesIfElse, stylesWhen, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';
import {Button} from '../../../../common/normalized-styled-components';

import {transition} from '../../../../common/animations';
import {black, white} from '../../../../common/palette';

const TooltipContainer = styled.dialog`
    display: block;
    position: relative;
    transform-origin: ${({isLeftAligned}) => isLeftAligned ? '18px' : 'calc(100% - 18px)'} 0; /* 11px left + (14px arrow width / 2) === position of the tip of the arrow */
    transition: ${transition('transform', 'opacity')};
    border: 0;
    border-radius: 4px;
    box-shadow: 0 1px 1px 0 ${rgba(black, 0.3)}, 0 -1px 2px -1px ${rgba(black, 0.3)};
    background-color: ${white};
    padding: 14px;
    width: unset;
    height: unset;
    color: ${black};
    font: var(--quicksilver-body-copy-4);

    ${stylesWhen('isArrowVisible')`
        margin-top: 7px;

        &::before {
            position: absolute;
            top: -14px;
            filter: drop-shadow(0 -1px 1px ${rgba(black, 0.1)});
            border-right: 14px solid transparent;
            border-bottom: 14px solid ${white};
            border-left: 14px solid transparent;
            width: 0;
            height: 0;
            content: '';

            ${stylesIfElse('isLeftAligned')`
                left: 11px;
            ``
                right: 11px;
            `}
        }
    `}

    ${stylesWhenNot('open')`
        transform: scale(0.95);
        opacity: 0;
        pointer-events: none;
    `}

    a {
        color: inherit;
    }
`;

const CloseHelpButton = styled(Button)`
    appearance: none;
    display: block;
    margin-left: auto;
    border: 0;
    background: none;
    padding: 0;
    text-align: right;
    text-decoration: underline;
    color: inherit;
    font-family: inherit;
`;

const BM02Tooltip = ({
    arrowOrientation = 'right',
    children,
    className,
    dismissTooltipText,
    isArrowVisible = true,
    isInitiallyVisible,
    ...htmlAttributes
}) => {
    const [isTooltipVisible, setIsTooltipVisible] = useState(isInitiallyVisible);

    return (
        <TooltipContainer
            {...htmlAttributes}
            className={classnames('BM02Tooltip', className)}
            isArrowVisible={isArrowVisible}
            isLeftAligned={arrowOrientation === 'left'}
            open={isTooltipVisible}
        >
            {children}
            {!!dismissTooltipText && (
                <CloseHelpButton
                    onClick={() => setIsTooltipVisible(false)}
                    tabIndex={isTooltipVisible ? undefined : -1}
                >
                    {dismissTooltipText}
                </CloseHelpButton>
            )}
        </TooltipContainer>
    );
};

BM02Tooltip.displayName = 'BM02Tooltip';

BM02Tooltip.propTypes = {
    /** Positioning of the arrow header */
    arrowOrientation: propTypes.oneOf(['left', 'right']),
    /** Content for the help dialog */
    children: propTypes.node.isRequired,
    /** className */
    className: classNameType,
    /** Dismiss tooltip text, if set, becomes clicking-to-close-type of modal */
    dismissTooltipText: propTypes.string,
    /** Whether to show the arrow head or not */
    isArrowVisible: propTypes.bool,
    /** Is tooltip visible? */
    isInitiallyVisible: propTypes.bool,
};

export default BM02Tooltip;
