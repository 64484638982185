import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import noop from 'lodash/noop';
import {Button} from 'normalized-styled-components';

import {stylesWhen, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';

import {transition} from '../../../../common/animations';
import {blanc, midnight} from '../../../../common/palette';

import IC48Close from '../../../atoms/ic/48-close';
import IC49Open from '../../../atoms/ic/49-open';

const AccordionItemBox = styled.div`
    border-bottom: 1px solid ${({isLightTheme}) => isLightTheme ? midnight : blanc};
    width: 100%;
    color: ${({isLightTheme}) => isLightTheme ? midnight : blanc};
    font: var(--nucleus-body-copy-3-light);

    &:not(:last-child) {
        margin-bottom: 7px;
    }
`;

const IconBox = styled.span`
    float: right;
    transition: ${transition('color', 'opacity')};
    opacity: 0.5;
    margin-right: -26px;
    padding-left: 14px;
    width: 22px;

    ${stylesWhen('isExpanded')`
        opacity: 1;
    `}
`;

const StyledButton = styled(Button)`
    appearance: none;
    box-sizing: border-box;
    transition: ${transition('color', 'opacity')};
    border-width: 0;
    background: none;
    padding: 14px 40px 14px 14px;
    width: 100%;
    text-align: left;
    color: inherit;
    font: inherit;

    &:focus {
        outline: 0;
        color: ${({isLightTheme}) => isLightTheme ? midnight : blanc};

        ${IconBox} {
            opacity: 1;
        }
    }

    &:hover ${IconBox} {
        opacity: 0.8;
        color: inherit;
    }

    &[aria-expanded='true'] {
        font: var(--nucleus-body-copy-3);
    }
`;

const StyledDef = styled.dd`
    transform: scaleY(1);
    transform-origin: top;
    transition: ${transition('transform')};
    opacity: 1;
    margin: 0;
    border-bottom: 1px solid transparent;
    padding: 0 48px 0 14px;
    height: auto;
    overflow-x: hidden;
    word-wrap: break-word;

    ${stylesWhenNot('isExpanded')`
        transform: scaleY(0);
        opacity: 0;
        height: 0;
        overflow: hidden;
    `}

    > p,
    > ul,
    > ol {
        margin: 0 0 14px;

        a {
            text-decoration: underline;
            color: ${({isLightTheme}) => isLightTheme ? midnight : blanc};

            &:hover {
                text-decoration: none;
            }
        }
    }

    > img {
        display: block;
        margin: 0 0 14px;
        max-width: 100%;
        height: auto;
    }
`;

export default class AccordionItem extends React.Component {
    static displayName = 'AccordionItem';

    static propTypes = {
        /** Light or dark (default) theme */
        isLightTheme: propTypes.bool,
        /** Definition (dd) content */
        children: propTypes.node,
        /** Icon for collapsed state */
        collapseIcon: propTypes.node,
        /** Icon for expanded state */
        expandIcon: propTypes.node,
        /** Whether the item is expanded or not (whether dd is visible or not) */
        isExpanded: propTypes.bool,
        /** Definition title (dt) */
        title: propTypes.node,
        /** Fired when an item is clicked to expand its contents */
        onExpandItem: propTypes.func,
    };

    static defaultProps = {
        collapseIcon: <IC48Close />,
        expandIcon: <IC49Open />,
        isExpanded: false,
        onExpandItem: noop,
    };

    state = {
        isExpanded: this.props.isExpanded,
    };

    toggleItem() {
        this.setState((prevState) => ({isExpanded: !prevState.isExpanded}), () => {
            if (this.state.isExpanded) {
                this.props.onExpandItem();
            }
        });
    }

    render() {
        return (
            <AccordionItemBox isLightTheme={this.props.isLightTheme}>
                <dt>
                    <StyledButton
                        isLightTheme={this.props.isLightTheme}
                        onClick={() => this.toggleItem()}
                        aria-expanded={this.state.isExpanded}
                    >
                        <IconBox isExpanded={this.state.isExpanded}>
                            {React.cloneElement(
                                this.state.isExpanded ? this.props.collapseIcon : this.props.expandIcon,
                                {
                                    'aria-hidden': true,
                                    'focusable': 'false',
                                }
                            )}
                        </IconBox>
                        {this.props.title}
                    </StyledButton>
                </dt>
                <StyledDef isLightTheme={this.props.isLightTheme} isExpanded={this.state.isExpanded}>
                    {React.Children.map(this.props.children, (child) => (
                        React.isValidElement(child) ? child : <p>{child}</p>
                    ))}
                </StyledDef>
            </AccordionItemBox>
        );
    }
}
