import React from 'react';
import propTypes from 'prop-types';
import {rgba} from 'polished';
import styled from 'styled-components';
import classnames from 'classnames';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {ariaPressedType, classNameType} from '@fsa-streamotion/custom-prop-types';
import {Button} from '../../../../common/normalized-styled-components';

import {flash, white, buttonActiveState, black} from '../../../../common/palette';
import {SCREEN_1920_DESKTOP, SMALLEST_SUPPORTED_WIDTH} from '../../../../common/screen-sizes';
import {transition} from '../../../../common/animations';
import getCheckedMarkStylesMap from '../../../../common/get-checked-mark-styles-map';
import IC31Tick from '../../ic/31-tick';

const CHECKMARK_SIZE_PX_MAP = {
    [SMALLEST_SUPPORTED_WIDTH]: 17,
    [SCREEN_1920_DESKTOP]: 26,
};
const CHECKED_MARK_STYLES_BY_WIDTH = getCheckedMarkStylesMap(CHECKMARK_SIZE_PX_MAP);

const BUTTON_WIDTH_DEFAULT_PX = 188;
const BUTTON_WIDTH_SCREEN_1920_PX = 280;
const PADDING_PX = 21 * 2; // * 2 accounts for both ends

const StyledButton = styled(Button)`
    position: relative;
    transition: ${transition('background-color', 'color')};
    outline: none;
    border: none;
    background-color: ${rgba(white, 0.2)};
    padding: 0;
    width: ${BUTTON_WIDTH_DEFAULT_PX}px;
    height: 41px;
    letter-spacing: 0.4px;
    color: ${white};
    font: var(--infinity-primary-button);

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: ${BUTTON_WIDTH_SCREEN_1920_PX}px;
        height: 61px;
    `}

    &:hover,
    &:focus {
        background-color: ${flash};
        text-shadow: currentColor 0 0 1px;
    }

    &:disabled {
        background-color: ${rgba(white, 0.1)};
        cursor: not-allowed;
        color: ${rgba(white, 0.2)};
    }

    &:active {
        background-color: ${buttonActiveState};
        text-shadow: currentColor 0 0 1px;
        color: ${white};
    }

    &[aria-pressed='true'] {
        text-shadow: currentColor 0 0 1px;
    }

    &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: ${BUTTON_WIDTH_DEFAULT_PX - PADDING_PX}px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        content: attr(data-label);

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            width: ${BUTTON_WIDTH_SCREEN_1920_PX - PADDING_PX}px;

        `}
    }
`;

const CheckedMarkCircle = styled.span`
    position: absolute;
    transform: scale(${({isPressed}) => isPressed ? '100%' : 0});
    transition: transform 300ms cubic-bezier(0.68, -0.55, 0.27, 1.55); /* https://easings.net/#easeInOutBack */
    stroke: ${rgba(black, 0.3)};
    ${CHECKED_MARK_STYLES_BY_WIDTH[SMALLEST_SUPPORTED_WIDTH]}
    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        ${CHECKED_MARK_STYLES_BY_WIDTH[SCREEN_1920_DESKTOP]}
    `}
`;

const StyledIC31Tick = styled(IC31Tick)`
    border-radius: 50%;
    box-shadow: -1px 1px 1px 0 ${rgba(black, 0.3)};
`;

const BA35AudioSubRadBtn = ({
    'aria-pressed': isPressed,
    label,
    onClick,
    className,
    ...htmlAttributes
}) => (
    <StyledButton
        {...htmlAttributes}
        aria-pressed={isPressed}
        onClick={onClick}
        type="button"
        data-label={label}
        className={classnames('BA35AudioSubRadBtn', className)}
    >
        <CheckedMarkCircle isPressed={isPressed} role="presentation">
            <StyledIC31Tick color={flash} secondaryColor={white} />
        </CheckedMarkCircle>
    </StyledButton>
);

/* eslint-disable quote-props */
BA35AudioSubRadBtn.propTypes = {
    /** aria-pressed attribute describes a toggle button */
    'aria-pressed': ariaPressedType,
    /** Callback on element click */
    onClick: propTypes.func,
    /** Text content of button */
    label: propTypes.string.isRequired,
    /** Additional CSS class(es) */
    className: classNameType,
    /** Is this button disabled? */
    // eslint-disable-next-line react/boolean-prop-naming
    disabled: propTypes.bool,
};
/* eslint-enable quote-props */

BA35AudioSubRadBtn.displayName = 'BA35AudioSubRadBtn';

export default BA35AudioSubRadBtn;
