import React from 'react';

import Ic from '..';

const IC12RR = (args) => (
    <Ic {...args}>
        <path fillRule="nonzero" d="M58.52 37.348v5.24l20.276-15.204v44.633L58.519 56.814v5.24l24.469 18.348V19zm-13.975-1.579v27.864l-18.571-13.89 18.571-13.974M52.93 19 12 49.743l40.93 30.743V19z" />
    </Ic>
);

IC12RR.displayName = 'IC12RR';

export default IC12RR;
