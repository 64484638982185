import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {mediaQuery, stylesWhen} from '@fsa-streamotion/styled-component-helpers';

import {SCREEN_TABLET, SCREEN_PRETTY_LG_DESKTOP} from '../../../../../common/screen-sizes';

const ActionContainer = styled.div`
    display: inline-flex;
    position: relative;
    margin-right: 7px;

    ${stylesWhen('isHiddenOnMobile')`
        display: none;

        ${mediaQuery({minWidthPx: SCREEN_TABLET})`
            display: unset;
        `}
    `}

    ${mediaQuery({minWidthPx: SCREEN_PRETTY_LG_DESKTOP})`
        margin-right: 14px;
    `}
`;

const ActionWrapper = React.forwardRef(({
    actionButton,
    progressBar,
    ...htmlAttributes
}, ref) => (
    <ActionContainer>
        {!!actionButton && React.cloneElement(actionButton, {ref, ...htmlAttributes})}
        {!!progressBar && progressBar}
    </ActionContainer>
));

ActionWrapper.displayName = 'ActionWrapper';

ActionWrapper.propTypes = {
    actionButton: propTypes.element,
    progressBar: propTypes.node,
};

export default ActionWrapper;
