import React from 'react';

import Ic from '..';

const IC232Help = (args) => (
    <Ic {...args}>
        <path fillRule="nonzero" d="M13 54.486v9.38c0 2.975 2.392 5.395 5.333 5.395h1.934c.712 3.35 3.658 5.873 7.181 5.873h3.066a1.55 1.55 0 0 0 1.543-1.56V44.698c0-.862-.69-1.56-1.543-1.56H27.53c-1.62 0-3.108.541-4.33 1.434v-3.764c0-14.715 11.84-26.688 26.393-26.688 14.552 0 26.391 11.973 26.391 26.688v3.763a7.312 7.312 0 0 0-4.329-1.432h-2.984a1.55 1.55 0 0 0-1.543 1.56v28.875c0 .862.69 1.56 1.543 1.56h2.984a7.364 7.364 0 0 0 4.336-1.423c-.053 4.468-3.655 8.09-8.087 8.09H57.058c-.918-2.373-3.851-4.078-7.465-4.078-4.371 0-7.796 2.478-7.796 5.639 0 3.16 3.425 5.638 7.796 5.638 3.614 0 6.546-1.705 7.465-4.078h10.846c6.167 0 11.182-5.072 11.182-11.307V69.26h1.766c2.94 0 5.333-2.42 5.333-5.395v-9.38c0-2.973-2.392-5.392-5.333-5.392H79.07v-8.286C79.07 24.37 65.846 11 49.593 11c-16.255 0-29.479 13.371-29.479 29.808v8.286h-1.78c-2.942 0-5.334 2.419-5.334 5.392zM49.593 85.88c-2.696 0-4.71-1.33-4.71-2.518 0-1.189 2.014-2.518 4.71-2.518 2.695 0 4.708 1.33 4.708 2.518 0 1.188-2.013 2.518-4.708 2.518zm29.493-33.667h1.766c1.24 0 2.248 1.02 2.248 2.273v9.38c0 1.254-1.008 2.275-2.248 2.275h-1.766V52.213zm-7.43 19.8h-1.442V46.259h1.441c2.34 0 4.24 1.884 4.329 4.23v17.213c0 .02.01.036.011.055-.03 2.39-1.923 4.258-4.34 4.258zM23.2 67.702V50.487c.089-2.345 1.99-4.229 4.33-4.229h1.442v25.756h-1.524c-2.333 0-4.23-1.906-4.26-4.258.001-.02.012-.035.012-.055zm-7.115-13.215c0-1.253 1.008-2.273 2.248-2.273h1.765v13.928h-1.765c-1.24 0-2.248-1.02-2.248-2.275v-9.38z" />
        <path opacity=".35" d="M49.593 85.88c-2.696 0-4.71-1.33-4.71-2.518 0-1.189 2.014-2.518 4.71-2.518 2.695 0 4.708 1.33 4.708 2.518 0 1.188-2.013 2.518-4.708 2.518zM23.2 67.7V50.488c.089-2.345 1.99-4.229 4.33-4.229h1.442v25.756h-1.524c-2.333 0-4.23-1.906-4.26-4.258.001-.02.012-.035.012-.055zm48.455 4.314h-1.441V46.258h1.441c2.34 0 4.24 1.884 4.329 4.23v17.213c0 .02.01.036.011.055-.03 2.39-1.923 4.258-4.34 4.258z" />
    </Ic>
);

IC232Help.displayName = 'IC232Help';

export default IC232Help;
