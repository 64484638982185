import React from 'react';
import {Hr} from 'normalized-styled-components';
import styled from 'styled-components';
import {slate} from '../../../../common/deprecated-mui-palette';

const StyledHr = styled(Hr)`
    margin: 0 auto;
    border: 0;
    border-radius: 4px;
    background-color: ${slate};
    width: 180px;
    height: 4px;
`;

const GA03ContentDiv = () => (
    <StyledHr />
);

GA03ContentDiv.displayName = 'GA03ContentDiv';

export default GA03ContentDiv;
