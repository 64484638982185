import propTypes from 'prop-types';

/**
 * Validate against aria-pressed attributes.
 *
 * @see https://www.w3.org/TR/wai-aria-1.1/#aria-pressed
 */
const ariaPressedType = propTypes.oneOf([true, false, 'mixed'] as const);

export default ariaPressedType;
