/**
 * Manually set a React ref.
 *
 * This allows us to create a function-based ref which sets multiple refs.
 *
 * @param {Object|Function} ref - a React ref
 * @param {*} value - the value to set
 */
export default function setRef(ref, value) {
    if (Object.prototype.hasOwnProperty.call(ref, 'current')) {
        ref.current = value;
    } else if (typeof ref === 'function') {
        ref(value);
    }
}
