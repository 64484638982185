import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {Sup} from '../../../../../common/normalized-styled-components';

import {
    SCREEN_1280_DESKTOP,
} from '../../../../../common/screen-sizes';
import {splitPrice, getShortTermTypeText} from '../../../../../common/price-term';
import {ink} from '../../../../../common/palette';

/** ********************************************
 *                   WRAPPER
 ***********************************************/

const RowHeaderCell = styled.th.attrs({
    scope: 'row',
})`
    border-top: 1px solid ${rgba(ink, 0.05)};
    padding: 15px 2px 15px 21px;
    text-align: left;
`;

const ContentWrapper = styled.section`
    display: block;
    position: relative;
    min-width: 127px;
`;

/** ********************************************
 *          PACKAGE PRICING DETAILS
 ***********************************************/

// line 1 - Package name

const PackageName = styled.span`
    display: block;
    text-transform: uppercase;
    font: var(--quicksilver-body-copy-5);
`;

// line 2: price & term

const PackagePrice = styled.div`
    position: relative;
    font: var(--quicksilver-offer-price-footer);
`;

// FREE price wrapper + Secondary Text
const StyledUppercase = styled.span`
    text-transform: uppercase;
`;

// Wrapper for decimals & footnote marker
const SupContainer = styled(Sup)`
    position: absolute;
    top: 3px;

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        top: 2px;
    `}
`;

const PackagePriceDecimals = styled.span`
    display: inline-block;
    font: var(--quicksilver-body-copy-5);
`;

const FootnoteMarker = styled.span`
    padding-left: 1px;
    font: var(--quicksilver-footnote-marker-2);
`;

const PackagePeriod = styled.span`
    margin-left: 2px;
    font: var(--quicksilver-body-copy-6);
`;

// Line 3: Secondary text (price[1]): ie. "or $100/yr", "was $15/mth"

const PackageSecondaryText = styled.span`
    display: block;
    font: var(--quicksilver-offer-period-footer);
`;

/** ********************************************
 *                  MAIN
 ***********************************************/

const TierPricingColumnCell = ({
    currencyDecimalPlaces,
    currencyPrefix,
    displayName,
    footnoteMarker,
    secondaryText,
    prices,
}) => {
    // Picking the first price for now, the prices array should only contain one value.
    const [{termType, displayAmount, displayRegularAmount}] = prices;
    const [priceIntegers, priceDecimals] = splitPrice(displayAmount, currencyDecimalPlaces);

    const hasNoPriceChange = displayRegularAmount === displayAmount;

    return (
        <RowHeaderCell>
            <ContentWrapper>

                <PackageName>
                    {displayName}
                </PackageName>

                {displayAmount === 0
                    ? (/* FREE */
                        <PackagePrice>
                            <StyledUppercase>Free</StyledUppercase>
                        </PackagePrice>
                    )
                    : (
                        <PackagePrice aria-label={`${currencyPrefix}${displayAmount} per ${termType}`}>
                            {currencyPrefix}{priceIntegers}

                            {(!!priceDecimals || !!footnoteMarker) && (
                                <SupContainer>
                                    {!!priceDecimals && (
                                        <PackagePriceDecimals>
                                            .{priceDecimals}
                                        </PackagePriceDecimals>
                                    )}

                                    {/* Only appear if there's no price change, because it doesn't make sense to display on secondarytext as well */}
                                    {!!footnoteMarker && hasNoPriceChange && (
                                        <FootnoteMarker>
                                            {footnoteMarker}
                                        </FootnoteMarker>
                                    )}

                                </SupContainer>
                            )}

                            <PackagePeriod>
                                {getShortTermTypeText(termType)}
                            </PackagePeriod>
                        </PackagePrice>
                    )}

                {!!secondaryText && (
                    <PackageSecondaryText>
                        <StyledUppercase>{secondaryText}</StyledUppercase>
                    </PackageSecondaryText>
                )}

                <PackageSecondaryText>
                    {hasNoPriceChange
                        ? prices /* ANNUAL */
                            .filter(({termType}) => termType === 'annual')
                            .map(({termType: annualTermType, displayAmount: annualDisplayAmount}) => (
                                <span
                                    key={`${annualTermType}__${annualDisplayAmount}`}
                                    aria-label={`Or ${currencyPrefix}${annualDisplayAmount} per ${annualTermType}`}
                                >
                                    Or {currencyPrefix}{annualDisplayAmount}{getShortTermTypeText(annualTermType)}
                                </span>
                            ))
                        : (/* PRICE CHANGE */
                            <span aria-label={`Was ${currencyPrefix}${displayRegularAmount} per ${termType}`}>
                                was
                                {' '}
                                <del>
                                    {currencyPrefix}{displayRegularAmount}{footnoteMarker || ''}{getShortTermTypeText(termType)}
                                </del>
                            </span>
                        )}
                </PackageSecondaryText>

            </ContentWrapper>
        </RowHeaderCell>
    );
};

TierPricingColumnCell.displayName = 'TierPricingColumnCell';

TierPricingColumnCell.propTypes = {
    /** Number of decimal places for currency. */
    currencyDecimalPlaces: propTypes.number,
    /** Prefix for currency. */
    currencyPrefix: propTypes.string,
    /** Display name for the cell. */
    displayName: propTypes.string,
    /** Marker for footnotes. */
    footnoteMarker: propTypes.string,
    /** Secondary text for the cell. */
    secondaryText: propTypes.string,
    /** Array of pricing information. */
    prices: propTypes.arrayOf(propTypes.shape({
        /** Type of term (e.g., 'annual', 'month', 'week', 'day'). */
        termType: propTypes.oneOf(['annual', 'month', 'week', 'day']),
        /** Display regular amount for the pricing. */
        displayRegularAmount: propTypes.number,
        /** Display amount for the pricing. */
        displayAmount: propTypes.number,
    })),
};

export default TierPricingColumnCell;
