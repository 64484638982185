import React from 'react';
import {black} from '../../../../common/palette';
import Ic from '..';

const IC69Stop = (args) => (
    <Ic {...args}>
        <circle cx="50" cy="50" r="42.4" />
        <path d="M36.8 36.8h26.4v26.4H36.8z" fill={black} />
    </Ic>
);

IC69Stop.displayName = 'IC69Stop';

export default IC69Stop;
