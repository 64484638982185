import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import noop from 'lodash/noop';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {palette as infinityPalette} from '@fsa-streamotion/streamotion-web-infinity';
import {palette as ionicPalette, screenSizes} from '@fsa-streamotion/streamotion-web-ionic';
import {palette as muiPalette} from '@fsa-streamotion/streamotion-web-mui';
import {palette as nucleusPalette} from '@fsa-streamotion/streamotion-web-nucleus';
import {
    BrandedGA92BrandLogo,
    BrandedBA01BtnPr,
    BrandedIC103Loading,
} from '../../../../../utils/branded-components';

import {stylesWhenBinge, stylesWhenFlash, stylesWhenKayo, stylesWhenLifestyle} from '../../../../../utils/styles-when-brand';
import {loadingBaseStyles, loadingAnimationStyles} from '../constants';

const {black} = infinityPalette;
const {vader} = ionicPalette;
const {coal, silverLining, slate} = muiPalette;
const {midnight} = nucleusPalette;

const {SCREEN_768_TABLET, SCREEN_1920_DESKTOP, SCREEN_1280_DESKTOP} = screenSizes;

const LoadingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
`;

const LogoAndSignInButton = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 15px 0 200px;
    width: 100%;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        margin-top: 28px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        margin-bottom: 280px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        margin-bottom: 600px;
        margin-top: 45px;
    `}
`;

const StyledBrandedGA92BrandLogo = styled(BrandedGA92BrandLogo)`
    margin-left: 20px;
    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        margin-left: 45px;
    `}
`;

const StyledBrandedBA01BtnPr = styled(BrandedBA01BtnPr)`
    margin-right: 20px;
    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        margin-right: 45px;
    `}
    ${stylesWhenFlash`
        background-color: ${rgba(black, 0.2)};
    `}

    ${stylesWhenBinge`
        background-color: ${rgba(vader, 0.2)};
    `}

    ${stylesWhenLifestyle`
        background-color: ${rgba(midnight, 0.2)};
    `}

    ${stylesWhenKayo`
        border: 1px solid ${rgba(slate, 0.35)};
        background-color: ${rgba(coal, 0.35)};
        color: ${silverLining};

        &:hover {
            color: ${coal};
        }
    `}
`;

const BrandedHeadingBlk = styled.div`
    ${loadingBaseStyles}
    ${loadingAnimationStyles}
    margin-bottom: 10px;
    width: 300px;
    min-width: var(--acw-offer-button-width);
    height: 79px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: 650px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 850px;
    `}
`;

const BrandedButtonBlk = styled.div`
    ${loadingBaseStyles}
    ${loadingAnimationStyles}
    width: 200px;
    height: 90px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: 300px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 350px;
    `}
`;

const BrandedHeroBannerLoadingSkeleton = ({
    brand,

    // just for vizard - binge
    srcsetOptions,
}) => (
    <LoadingWrapper>
        <LogoAndSignInButton>
            <StyledBrandedGA92BrandLogo brand={brand} width="114px" srcsetOptions={srcsetOptions} />
            <StyledBrandedBA01BtnPr
                onClick={noop}
                disabled={true}
                brand={brand}
            >
                <BrandedIC103Loading size="30px" brand={brand} />
            </StyledBrandedBA01BtnPr>
        </LogoAndSignInButton>
        <BrandedHeadingBlk brand={brand} />
        <BrandedButtonBlk brand={brand} />
    </LoadingWrapper>
);

BrandedHeroBannerLoadingSkeleton.displayName = 'BrandedHeroBannerLoadingSkeleton';

BrandedHeroBannerLoadingSkeleton.propTypes = {
    /** brand name */
    brand: propTypes.string,
    /** brand logo img src (just for vizard of Binge) */
    srcsetOptions: propTypes.objectOf(propTypes.string),
};

export default BrandedHeroBannerLoadingSkeleton;
