import React from 'react';
import Ic from '..';

const IC218XtraInfoHud = (args) => (
    <Ic {...args}>
        <g fill="none" fillRule="evenodd" transform="scale(2.5 2.5)">
            <path d="M0 0h40v40H0z" />
            <path d="M19.476 10.797A2.4 2.4 0 1 1 22 8.397a2.39 2.39 0 0 1-2.524 2.4zM21.426 34h-3.888V14.73l3.887-.653V34z" fill="currentColor" fillRule="nonzero" />
        </g>
    </Ic>
);

IC218XtraInfoHud.displayName = 'IC218XtraInfoHud';

export default IC218XtraInfoHud;
