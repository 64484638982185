import React from 'react';

import Ic from '..';

const IC60Android = (args) => (
    <Ic {...args}>
        <path d="M36.5 39.1h-1.9V65c0 1 .9 1.9 1.9 1.9h3.9v8.2c0 1.8 1.4 3.2 3.2 3.2 1.8 0 3.2-1.4 3.2-3.2v-8.2h6v8.2c0 1.8 1.4 3.2 3.2 3.2s3.2-1.4 3.2-3.2v-8.2h3.9c1 0 1.9-.8 1.9-1.9V39.1H36.5zM70.2 38.1c-1.8 0-3.2 1.4-3.2 3.2V56c0 1.8 1.4 3.2 3.2 3.2 1.8 0 3.2-1.4 3.2-3.2V41.4c.1-1.9-1.4-3.3-3.2-3.3zM29.8 38.1c-1.8 0-3.2 1.4-3.2 3.2V56c0 1.8 1.4 3.2 3.2 3.2S33 57.8 33 56V41.4c0-1.9-1.4-3.3-3.2-3.3zM57.4 25.9l2.6-3.7c.1-.1 0-.3-.2-.3-.2-.1-.3-.2-.3-.1l-2.7 3.9c-1.9-.7-4.1-1.2-6.8-1.2s-4.9.4-6.8 1.1l-2.7-3.8c-.1-.1-.3-.1-.3.1-.2 0-.3.1-.2.2l2.6 3.7c-7.6 3.3-7.8 11.6-7.8 11.6h30.4c0-.1-.3-8.2-7.8-11.5zm-14.1 6.7c-1 0-1.9-.8-1.9-1.9s.8-1.9 1.9-1.9 1.9.9 1.9 1.9-.9 1.9-1.9 1.9zm13.4 0c-1 0-1.9-.8-1.9-1.9s.9-1.9 1.9-1.9 1.9.9 1.9 1.9c-.1 1-.9 1.9-1.9 1.9z" />
    </Ic>
);

IC60Android.displayName = 'IC60Android';

export default IC60Android;
