import React from 'react';
import Ic from '..';

const IC170ContextualDown = (args) => (
    <Ic {...args}>
        <path d="M49.128 7.397c-23.157 0-41.93 18.772-41.93 41.93 0 23.157 18.773 41.928 41.93 41.928 23.156 0 41.928-18.77 41.928-41.929 0-23.157-18.772-41.929-41.928-41.929m0 5.5c20.088 0 36.43 16.342 36.43 36.43 0 20.087-16.342 36.43-36.43 36.43s-36.43-16.343-36.43-36.43c0-20.088 16.342-36.43 36.43-36.43" />
        <path fillRule="nonzero" d="M30.052 39.282a4.154 4.154 0 0 1 5.873.194l12.058 12.886a2 2 0 0 0 2.92 0l12.06-12.886a4.154 4.154 0 1 1 6.066 5.679L50.904 64.523a2 2 0 0 1-2.92 0L29.857 45.155a4.155 4.155 0 0 1 .194-5.873z" />
    </Ic>
);

IC170ContextualDown.displayName = 'IC170ContextualDown';

export default IC170ContextualDown;
