import React from 'react';
import Ic from '..';

const IC22Float = (args) => (
    <Ic {...args}>
        <g transform="translate(5 13)">
            <path d="M57.61 29.03v25.494H18.2V29.03h39.41m8.4-8.4H9.8v42.294h56.21V20.63z" />
            <path d="M46.942 72.766L0 72.766 0 41.294 4.2 41.294 4.2 68.566 46.942 68.566zM86.086 12.104l-3.808 5.32C80.615 8.084 72.814 1.072 63.35.41c-9.464-.662-18.165 5.197-21.112 14.214l3.99 1.302c2.345-7.173 9.257-11.843 16.787-11.34 7.53.501 13.761 6.047 15.133 13.468L72.8 14.302l-2.436 3.416 11.13 7.98 7.98-11.2-3.388-2.394z" />
        </g>
    </Ic>
);

IC22Float.displayName = 'IC22Float';

export default IC22Float;
