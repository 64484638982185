import React from 'react';

import Ic from '..';

const IC252Rewatchable = (args) => (
    <Ic {...args}>
        <path d="M43.65 24.366h-8.754V15h-2.387v9.366H22.65v2.268h9.86V36h2.386v-9.366h8.754zm31.5 0h-8.754V15h-2.387v9.366H54.15v2.268h9.86V36h2.386v-9.366h8.754zM20.544 73.271C27.557 78.507 38.06 85.56 48.475 85.56c10.403 0 21.37-6.155 28.185-12.289 3.338-3.004 7.676-8.98 9.336-12.62 1.99-4.364 3.18-7.781 0-10.958-3.179-3.176-10.279-4.14-14.243-.116-.56.568-2.257 1.866-5.044 3.293-4.988 2.554-11.04 4.11-18.234 4.11-7.18 0-13.168-1.549-18.062-4.087-1.631-.845-2.993-1.729-4.082-2.577-.541-.422-.806-.664-.79-.646-3.915-4.076-12.3-2.365-14.244-.07-1.943 2.296-1.943 7.38 0 11.051 1.717 4.63 5.409 9.754 9.247 12.62z" />
    </Ic>
);

IC252Rewatchable.displayName = 'IC252Rewatchable';

export default IC252Rewatchable;
