import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import {Section} from 'normalized-styled-components';
import {rgba} from 'polished';

import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {blanc, panther} from '../../../../common/palette';

const StyledSection = styled(Section)`
    margin: 0 auto;
    width: 100%;
    text-shadow: 0 0 3px ${rgba(panther, 0.3)};
    color: ${blanc};

    ${stylesWhen('isHorizontallyCentered')`
        text-align: center;
    `}
`;

const StyledH2 = styled.h2`
    margin: 0 0 7px;
    font: var(--ionic-body-copy-2-light);
`;

const Copy = styled.div`
    margin: 0;
    color: ${rgba(blanc, 0.7)};
    font: var(--ionic-header-8-light);
`;

const OR130AccountBlk = ({
    className,
    displayHeader,
    displaySubtext,
    isHorizontallyCentered,
    isUpgrade,
    ...htmlAttributes
}) => (
    <StyledSection
        {...htmlAttributes}
        className={classnames('OR130AccountBlk', className)}
        isHorizontallyCentered={isHorizontallyCentered}
    >
        {/* The additional class names added here are for the Adobe analytics team to be able to target the element and pull the change type */}
        {!!displayHeader && <StyledH2 className={classnames('changeType', isUpgrade ? 'upgrade' : 'downgrade')}>{displayHeader}</StyledH2>}
        {!!displaySubtext && <Copy>{displaySubtext}</Copy>}
    </StyledSection>
);

OR130AccountBlk.propTypes = {
    /** Additional class name(s) */
    className: classNameType,
    /** CMS block heading copy */
    displayHeader: propTypes.node,
    /** CMS copy */
    displaySubtext: propTypes.node,
    /** Whether to horizontally centre the text */
    isHorizontallyCentered: propTypes.bool,
    /** Is upgrading or downgrading */
    isUpgrade: propTypes.bool,
};

OR130AccountBlk.displayName = 'OR130AccountBlk';

export default OR130AccountBlk;
