import React from 'react';
import {rgba} from 'polished';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {A} from 'normalized-styled-components';

import {stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {blanc, onyx} from '../../../../common/palette';

const StyledListItem = styled.li`
    position: relative;
    margin: auto;
    width: fit-content;
    height: 39px;
    list-style: none;
    text-align: center;

    &::after {
        position: absolute;
        bottom: -15px;
        left: 50%;
        transform: translateX(-50%);
        transition: ${transition('transform')};
        background-color: ${blanc};
        width: 100%;
        height: 2px;
        content: '';

        ${stylesWhenNot('isActive')`
            transform: scaleX(0);
        `}
    }
`;

const StyledLink = styled(A)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: ${transition('color')};
    margin: auto 0;
    height: 100%;
    text-decoration: none;
    text-shadow: 0 1px 1px ${rgba(onyx, 0.3)};
    letter-spacing: 0.4px;
    color: ${({isActive}) => isActive ? blanc : rgba(blanc, 0.7)};
    font: ${({isActive}) => isActive ? 'var(--nucleus-nav-copy-selected)' : 'var(--nucleus-nav-copy-default)'};

    &:focus,
    &:active,
    &:hover {
        outline: 0;
        color: ${blanc};
    }

    &:active {
        font-weight: 500;
    }
`;

const TB01NavT = React.forwardRef(({
    children,
    isActive = false,
    url,
    className,
    ...htmlAttributes
}, ref) => (
    <StyledListItem isActive={isActive} className={className}>
        <StyledLink href={url} ref={ref} isActive={isActive} {...htmlAttributes}>
            {children}
        </StyledLink>
    </StyledListItem>
));

TB01NavT.displayName = 'TB01NavT';

TB01NavT.propTypes = {
    /** Content of button, e.g. text */
    children: propTypes.node.isRequired,
    /** Url for the nav tab to point to */
    url: propTypes.string,
    /** Whether or not this nav Tab is selected */
    isActive: propTypes.bool,
    className: classNameType,
};

export default TB01NavT;
