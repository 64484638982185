import React from 'react';
import Ic from '..';

const IC237Watchlist = (args) => (
    <Ic {...args}>
        <path d="M39.504 39.27h23.03v4.2h-23.03zM11 76.902v-35l30.142 17.472L11 76.902zm4.2-27.762v20.468l17.584-10.234L15.2 49.14z" />
        <path d="M20.1 19.278v21.336l8.4 4.886V26.992l45.038-3.85v53.872l-45.038-.28v-3.486l-8.4 4.886v6.944l61.838.392V14z" />
        <path d="M46.56 62.734l-7.056 4.102v.098h23.03v-4.2zm-7.056-11.732v.896l5.684 3.304h17.346v-4.2z" />
    </Ic>
);

IC237Watchlist.displayName = 'IC237Watchlist';

export default IC237Watchlist;
