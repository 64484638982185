import styled from 'styled-components';
import get from 'lodash/get';
import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {
    SMALLEST_SUPPORTED_WIDTH,
    SCREEN_768_TABLET,
    SCREEN_1920_DESKTOP,
} from './screen-sizes';

const TILE_PX_WIDTHS = {
    [SMALLEST_SUPPORTED_WIDTH]: 92,
    [SCREEN_768_TABLET]: 125,
    [SCREEN_1920_DESKTOP]: 170,
};

/** TILE DECORATORS - for story & vizard tests only */

export const TileWrapper = styled.div`
    width: ${TILE_PX_WIDTHS[SMALLEST_SUPPORTED_WIDTH]}px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: ${TILE_PX_WIDTHS[SCREEN_768_TABLET]}px;
    `}
`;

/**
 * Decorator for when the tile is used for device (example: TM36);
 *
 * @param {Object} props props
 * @param {number} props.mobileWidthPx for 0+ viewport
 * @param {number} props.tabletWidthPx for 320+ viewport
 * @param {number} props.desktopWidthPx for 768+ viewport
 *
 * @returns {styled.StyledComponent} DeviceWrapper
 */
export const DeviceWrapper = styled.div`
    width: ${({mobileWidthPx}) => mobileWidthPx ? `${mobileWidthPx}px` : '100px'};

    ${mediaQuery({minWidthPx: SMALLEST_SUPPORTED_WIDTH})`
        width: ${({tabletWidthPx}) => tabletWidthPx ? `${tabletWidthPx}px` : '120px'};
    `}
    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: ${({desktopWidthPx}) => desktopWidthPx ? `${desktopWidthPx}px` : '170px'};
    `}
`;

/** TILE UTIL FUNCTIONS */

/**
 * Builds an object containing different widths for a single image URL
 *
 * @param {string} imageURL url to the image
 * @returns {Object} an object with keys related to width length
 */
export function getSrcSetOptions(imageURL) {
    return Object.keys(TILE_PX_WIDTHS)
        .reduce(
            (result, width) => (
                {
                    ...result,
                    [width]: `${imageURL}?imwidth=${width}`,
                }
            ),
            {}
        );
}

/**
 * Takes an object of srcset key/values and returns the largest image.
 *
 * @param {Object} srcsetOptions    - list of srcSet sizes
 * @returns {string}                - the largest img size
 */
export function getLargestImage(srcsetOptions = {}) {
    const [firstValue] = Object.entries(srcsetOptions)
        .sort(([sizePxA], [sizePxB]) => sizePxB - sizePxA);

    return get(firstValue, '1');
}
