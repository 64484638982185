import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import IntersectionObserver from 'inteobs';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import {mediaQuery, stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {coal, midnight, white} from '../../../../common/palette';
import {SCREEN_TABLET} from '../../../../common/screen-sizes';

const StyledSection = styled.section`
    position: relative;
    width: 100%;

    ${stylesWhen('isSticky')`
        padding-bottom: ${({offerListHeight}) => offerListHeight ? `${offerListHeight}px` : 0}; /* Need this so that the component won't collapse when offer list is fixed */
    `}
`;

const StyledHeader = styled.header`
    margin-bottom: 21px;
    text-align: center;
    color: ${({isLightTheme}) => isLightTheme ? midnight : white};
`;

const PlanTitle = styled.h2`
    margin: 0;
    font: var(--mui-header-2-bold);
`;

const PlanSubHeader = styled.p`
    margin: 7px 0 0;
    font: var(--mui-body-copy-3);
`;

const OfferList = styled.ul`
    box-sizing: border-box;
    display: grid;
    grid-auto-columns: 115px;
    grid-column-gap: 7px;
    align-items: start;
    justify-content: center;
    margin: 0;
    background-color: transparent;
    padding: 7px 7px 0;
    width: 100%;
    list-style: none;

    ${stylesWhen('isSticky')`
        position: fixed;
        top: 0;
        left: 0;
        /* As this is fixed, it has different stacking context */
        z-index: 1; /* stylelint-disable-line scale-unlimited/declaration-strict-value */
        /* Keep transition inside this stylesWhen block so the fade in works, but the fade out is instant */
        transition: ${transition('background-color')};
        background-color: ${coal};
        padding-top: 18px;
        padding-bottom: 18px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        grid-auto-columns: 192px;
    `}
`;

const OfferListItem = styled.li`
    grid-row: 1;
`;

// https://developers.google.com/web/updates/2017/09/sticky-headers
const Sentinel = styled.div`
    position: absolute;
    left: 0;
    margin-top: -1px;
    width: 100%;
    /* observer must have at least 1px width and height to work on edge */
    height: 1px;
`;

export default class OR09OffPln extends React.Component {
    static displayName = 'OR09OffPln';

    static propTypes = {
        /** Light or dark (default) theme */
        isLightTheme: propTypes.bool,
        /** Additional classname(s) */
        className: classNameType,
        /** e.g. offer BA14OfferRadBtn links */
        children: propTypes.node,
        /** e.g. voucher description  */
        planSubHeader: propTypes.string,
        /** Heading content describing plans */
        planTitle: propTypes.string,
    };

    state = {
        isSticky: false,
    };

    componentDidMount() {
        this.observer = new IntersectionObserver(([topSentinel]) => { // eslint-disable-line compat/compat
            if (isEmpty(topSentinel)) {
                return;
            }

            if (topSentinel.boundingClientRect.bottom < topSentinel.rootBounds.top) {
                this.setState({
                    isSticky: true,
                    offerListHeight: this.offerList.clientHeight,
                });
            } else if (topSentinel.boundingClientRect.bottom >= topSentinel.rootBounds.top) {
                this.setState({isSticky: false});
            }
        });

        this.observer.observe(this.topSentinel);
    }

    componentWillUnmount() {
        this.observer.disconnect();
    }

    render() {
        const {isLightTheme, planTitle, planSubHeader, children, className, ...htmlAttributes} = this.props;

        return (
            <StyledSection
                {...htmlAttributes}
                className={classnames('OR09OffPln', className)}
                ref={(el) => { this.rootEl = el; }}
                isSticky={this.state.isSticky}
                offerListHeight={this.state.offerListHeight}
            >
                <StyledHeader isLightTheme={isLightTheme}>
                    <PlanTitle>{planTitle}</PlanTitle>
                    {!!planSubHeader && (
                        <PlanSubHeader>{planSubHeader}</PlanSubHeader>
                    )}
                </StyledHeader>

                <Sentinel ref={(el) => { this.topSentinel = el; }} />
                <OfferList
                    ref={(el) => { this.offerList = el; }}
                    isSticky={this.state.isSticky}
                >
                    {React.Children.map(children, (child) => (
                        <OfferListItem key={child.key}>
                            {child}
                        </OfferListItem>
                    ))}
                </OfferList>
            </StyledSection>
        );
    }
}
