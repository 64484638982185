import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import {kayoGreen, black} from '../../../../common/palette';

import Ic from '..';

const IC31Tick = ({
    color = kayoGreen,
    secondaryColor = black,
    ...otherProps
}) => (
    <Ic {...otherProps}>
        <circle fill={color} cx="50" cy="50" r="49.3023256" />
        <path fill={secondaryColor} d="M39.1860465,71.744186 C38.372093,71.744186 37.5581395,71.3953488 36.8604651,70.8139535 L23.6046512,57.4418605 L28.1395349,52.9069767 L39.1860465,63.9534884 L71.744186,31.3953488 L76.2790698,35.9302326 L41.3953488,70.8139535 C40.9302326,71.3953488 40.1162791,71.744186 39.1860465,71.744186 Z" />
    </Ic>
);

IC31Tick.displayName = 'IC31Tick';

IC31Tick.propTypes = {
    color: colorType,
    secondaryColor: colorType,
};

export default IC31Tick;
