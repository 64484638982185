import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

const HeadingContainer = styled.article`
    box-sizing: border-box;
    padding: 14px;
    width: 100%;
`;

const Heading = styled.h2`
    margin: 0;
    font: var(--mui-header-5);
`;

const SubHeading = styled.p`
    margin: 0;
    text-transform: uppercase;
    font: var(--mui-header-9);
`;

const DefaultTile = ({
    className,
    matchEndDate,
    matchStartDate,
    seriesName,
    venueName,
}) => (
    <HeadingContainer className={className}>
        {!!seriesName && (
            <Heading>
                {seriesName}
            </Heading>
        )}
        {!!venueName && (
            <SubHeading>
                {venueName}
            </SubHeading>
        )}
        {!!matchStartDate && (
            <SubHeading>
                {matchStartDate}
                {!!matchEndDate && ` to ${matchEndDate}`}
            </SubHeading>
        )}
    </HeadingContainer>
);

DefaultTile.propTypes = {
    /** className */
    className: classNameType,
    /** Parsed pretty string for match end date */
    matchEndDate: propTypes.string,
    /** Parsed pretty string for match start date */
    matchStartDate: propTypes.string,
    /** Name of the series */
    seriesName: propTypes.string,
    /** Where match is being held */
    venueName: propTypes.string,
};

DefaultTile.displayName = 'DefaultTile';

export default DefaultTile;
