import React from 'react';

import Ic from '..';

const IC43Valid = (args) => (
    <Ic {...args}>
        <path d="M34 79.8c-.7 0-1.4-.3-2-.8L9.1 56.1l3.9-3.9 21 20.9 52.9-52.9 3.9 3.9L36 79c-.6.6-1.3.8-2 .8z" />
    </Ic>
);

IC43Valid.displayName = 'IC43Valid';

export default IC43Valid;
