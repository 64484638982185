import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import {indigo} from '../../../../common/palette';

import Ic from '..';

const IC154MultiAdd3 = ({
    secondaryColor = indigo,
    ...args
}) => (
    <Ic {...args}>
        <path d="M49.63 8C26.638 8 8 26.638 8 49.63c0 22.99 18.638 41.629 41.63 41.629 22.99 0 41.629-18.638 41.629-41.63C91.252 26.642 72.618 8.009 49.629 8z" />
        <path fill={secondaryColor} d="M49.61 67.142c6.934 0 13.137-3.663 13.137-10.402 0-4.396-3.077-7.082-5.91-7.814v-.147c2.247-.928 4.835-2.881 4.835-7.374 0-5.665-5.616-8.986-11.72-8.986-6.154 0-9.28 2.148-11.624 4.2l2.93 4.981c2.735-2.198 5.372-3.272 8.058-3.272s4.884 1.319 4.884 3.956-1.856 3.956-4.493 3.956h-2.98v5.518h2.98c3.028 0 5.372 1.563 5.372 4.542 0 3.028-2.588 4.737-5.91 4.737-3.271 0-6.885-2.54-8.253-4.2l-3.614 4.689c1.172 1.709 5.324 5.616 12.307 5.616z" />
    </Ic>
);

IC154MultiAdd3.propTypes = {
    /** The icon's secondary color */
    secondaryColor: colorType,
};

IC154MultiAdd3.displayName = 'IC154MultiAdd3';

export default IC154MultiAdd3;
