import React from 'react';

import Ic from '..';

const IC42Error = (args) => (
    <Ic {...args}>
        <path d="M50,99.5 C77.3378576,99.5 99.5,77.3378576 99.5,50 C99.5,22.6621424 77.3378576,0.5 50,0.5 C22.6621424,0.5 0.5,22.6621424 0.5,50 C0.5,77.3378576 22.6621424,99.5 50,99.5 Z M43.4847082,25.5 L57.4934128,25.5 L56.3021089,62.378 L44.595838,62.378 L43.4847082,25.5 Z M58.004,72.489 C58.004,76.7573411 54.8856493,79.477 50.29,79.477 C45.6293392,79.477 42.5,76.7679289 42.5,72.489 C42.5,68.2092309 45.6291723,65.5 50.29,65.5 C54.8858152,65.5 58.004,68.2198197 58.004,72.489 Z" />
    </Ic>
);

IC42Error.displayName = 'IC42Error';

export default IC42Error;
