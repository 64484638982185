import React from 'react';
import Ic from '..';

const IC247FaqMinus = (args) => (
    <Ic {...args}>
        <path d="M10 45.935h77.516v8.787H10z" />
    </Ic>
);

IC247FaqMinus.displayName = 'IC247FaqMinus';

export default IC247FaqMinus;
