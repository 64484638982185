import React from 'react';
import Ic from '..';

const IC135Cross = (args) => (
    <Ic {...args}>
        <path d="m85.378 21.1-5.914-5.914L50.689 43.96 21.914 15.186 16 21.1l28.775 28.774L16 78.65l5.914 5.914 28.775-28.775 28.775 28.775 5.914-5.914-28.775-28.775z" />
    </Ic>
);

IC135Cross.displayName = 'IC135Cross';

export default IC135Cross;
