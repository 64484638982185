import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {silverLining, slate} from '../../../../common/palette';
import {SCREEN_DESKTOP} from '../../../../common/screen-sizes';

const ListItem = styled.div`
    min-width: 100px;
    text-align: center;
    white-space: nowrap;
    color: ${silverLining};
`;

const StyledListName = styled.dt`
    color: ${rgba(slate, 0.7)};
    font: var(--mui-body-copy-6);
`;

const StyledListValue = styled.dd`
    margin: 10px 0 0;
    text-transform: uppercase;
    font: var(--mui-body-copy-3);

    ${mediaQuery({minWidthPx: SCREEN_DESKTOP})`
        margin-top: 4px;
    `}
`;

const CA02FeatureStacked = ({
    title,
    a11yTitle,
    value,
    a11yValue,
    ...htmlAttributes
}) => (
    <ListItem {...htmlAttributes}>
        <StyledListName {...(a11yTitle && {'aria-label': a11yTitle})}>
            {title}
        </StyledListName>
        <StyledListValue {...(a11yValue && {'aria-label': a11yValue})}>
            {value}
        </StyledListValue>
    </ListItem>
);

CA02FeatureStacked.displayName = 'CA02FeatureStacked';

CA02FeatureStacked.propTypes = {
    /** The title of the package feature */
    title: propTypes.node,
    /** The accessible title of the package feature */
    a11yTitle: propTypes.string,
    /** The value of the package feature */
    value: propTypes.node,
    /** The accessible value */
    a11yValue: propTypes.string,
};

export default CA02FeatureStacked;
