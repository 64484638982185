import React from 'react';
import Ic from '..';

const IC02Sea = (args) => (
    <Ic {...args}>
        <path d="M34.868 8.4c10.552-.006 20.067 6.347 24.108 16.094 4.04 9.748 1.81 20.97-5.651 28.43-7.461 7.462-18.683 9.693-28.43 5.652-9.748-4.04-16.1-13.556-16.095-24.108C8.823 20.081 20.48 8.423 34.868 8.4m0-8.4C20.92 0 8.346 8.403 3.009 21.29-2.327 34.176.626 49.009 10.49 58.87c9.865 9.86 24.699 12.807 37.583 7.465 12.885-5.341 21.283-17.92 21.277-31.867 0-9.144-3.633-17.913-10.1-24.378C52.783 3.626 44.012-.004 34.868 0zM67.208 60.2c-1.764 2.182-3.743 4.18-5.908 5.964l10.164 10.164 5.936-5.992L67.208 60.2z" transform="translate(11 12)" />
    </Ic>
);

IC02Sea.displayName = 'IC02Sea';

export default IC02Sea;
