import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {
    // SCREEN_1024_DESKTOP,
    SCREEN_1480_DESKTOP,
    SCREEN_1920_DESKTOP, SCREEN_2560_DESKTOP,
    SCREEN_768_TABLET,
    SCREEN_375_PHABLET,
    SCREEN_1024_DESKTOP,
} from '../../../common/screen-sizes';
import {CONTENT_EDGE_SPACING_PERCENT} from '../../../common/style-constants';

export const Panel = styled.div`
    position: relative;
`;

Panel.displayName = 'Panel';

/* On standard pages, use this to wrap the hero carousel / OR01Hero panel */
export const HeroWrapper = styled(Panel)`
    width: 100%;
    height: 150vw; /* source image is in ratio of 1:1.79 - this will ensure we never overstretch the image */
    min-height: 500px;
    max-height: calc(100vh - 100px);

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        height: 600px;
        min-height: 600px;
        max-height: 600px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        height: 51.25vw;
        min-height: 600px;
        max-height: calc(100vh - 240px);
    `}
`;

HeroWrapper.displayName = 'HeroWrapper';

/**
 * Used for CAM01Stand
 */
export const GenericCarouselWrapper = styled(Panel)`
    margin-bottom: 17px;
    width: 100%;

    ${mediaQuery({minWidthPx: SCREEN_375_PHABLET})`
        margin-bottom: 23px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        margin-bottom: 28px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1480_DESKTOP})`
        margin-bottom: 19px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        margin-bottom: 10px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
        margin-bottom: 14px;
    `}
`;

GenericCarouselWrapper.displayName = 'GenericCarouselWrapper';

/**
 * Used for OR60SeaEpi
 */
export const SeasonEpisodeCarouselWrapper = styled(Panel)`
    margin-bottom: 28px;
    width: 100%;

    ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
        margin-bottom: 35px;
    `}
`;

SeasonEpisodeCarouselWrapper.displayName = 'SeasonEpisodeCarouselWrapper';

/**
 * Provides left and right padding consistent with the rest of the product
 * Useful for CAM01Stands on content pages
 */
export const BoxedPanelRow = styled(Panel)`
    padding: 0 ${CONTENT_EDGE_SPACING_PERCENT}%;
`;

BoxedPanelRow.displayName = 'BoxedPanelRow';

const PanelTemplate = ({
    children,
    ...htmlAttributes
}) => (
    <main {...htmlAttributes}>
        {children}
    </main>
);

PanelTemplate.propTypes = {
    children: propTypes.node,
};

PanelTemplate.displayName = 'PanelTemplate';

export default PanelTemplate;
