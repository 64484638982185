/* eslint-disable import/no-commonjs,import/unambiguous,import/no-unused-modules */
// The font matrix breakpoints are slightly different to our other breakpoints
const BREAKPOINT_MOBILE = 0; // This is the base base, so we do 0 instead of 320 here
const BREAKPOINT_TABLET_PORTRAIT = 768;
const BREAKPOINT_TABLET_LANDSCAPE = 1024;
const BREAKPOINT_DESKTOP = 1280;
const BREAKPOINT_LARGE_DESKTOP = 1680;
const BREAKPOINT_PRETTY_LARGE_DESKTOP = 1920;
const BREAKPOINT_REALLY_LARGE_DESKTOP = 2560;

module.exports.BREAKPOINTS = {
    BREAKPOINT_MOBILE,
    BREAKPOINT_TABLET_PORTRAIT,
    BREAKPOINT_TABLET_LANDSCAPE,
    BREAKPOINT_DESKTOP,
    BREAKPOINT_LARGE_DESKTOP,
    BREAKPOINT_PRETTY_LARGE_DESKTOP,
    BREAKPOINT_REALLY_LARGE_DESKTOP,
};

module.exports.FONT_MATRIX = {
    navCopyDefault: {
        [BREAKPOINT_MOBILE]: {size: 11, height: 1.36},
        [BREAKPOINT_DESKTOP]: {size: 16, height: 1.38},
    },
    navCopySelected: {
        [BREAKPOINT_MOBILE]: {weight: 600, size: 11, height: 1.36},
        [BREAKPOINT_DESKTOP]: {weight: 600, size: 16, height: 1.38},
    },
    header1Bold: {
        [BREAKPOINT_MOBILE]: {weight: 600, size: 44},
        [BREAKPOINT_TABLET_PORTRAIT]: {weight: 600, size: 48},
        [BREAKPOINT_DESKTOP]: {weight: 600, size: 44, height: 1.18},
    },
    header1: {
        [BREAKPOINT_MOBILE]: {size: 44},
        [BREAKPOINT_TABLET_PORTRAIT]: {size: 48},
        [BREAKPOINT_DESKTOP]: {size: 44, height: 1.18},
    },
    header2Bold: {
        [BREAKPOINT_MOBILE]: {weight: 600, size: 34, height: 1.18},
        [BREAKPOINT_DESKTOP]: {weight: 600, size: 38, height: 1.16},
    },
    header2: {
        [BREAKPOINT_MOBILE]: {size: 34, height: 1.18},
        [BREAKPOINT_TABLET_PORTRAIT]: {size: 34, height: 1.18},
        [BREAKPOINT_TABLET_LANDSCAPE]: {size: 34, height: 1.18},
        [BREAKPOINT_DESKTOP]: {size: 38, height: 1.16},
    },
    header3Bold: {
        [BREAKPOINT_MOBILE]: {weight: 600, size: 26, height: 1.23},
        [BREAKPOINT_TABLET_PORTRAIT]: {weight: 600, size: 26, height: 1.23},
        [BREAKPOINT_DESKTOP]: {weight: 600, size: 34, height: 1.18},
    },
    header3: {
        [BREAKPOINT_MOBILE]: {size: 26, height: 1.23},
        [BREAKPOINT_DESKTOP]: {size: 34, height: 1.18},
    },
    header3Light: {
        [BREAKPOINT_MOBILE]: {size: 26, height: 1.23, weight: 300},
        [BREAKPOINT_DESKTOP]: {size: 34, height: 1.18, weight: 300},
    },
    header4Bold: {
        [BREAKPOINT_MOBILE]: {weight: 600, size: 24, height: 1.25},
        [BREAKPOINT_DESKTOP]: {weight: 600, size: 28, height: 1.21},
    },
    header4: {
        [BREAKPOINT_MOBILE]: {size: 24, height: 1.25},
        [BREAKPOINT_DESKTOP]: {size: 28, height: 1.21},
    },
    header5Bold: {
        [BREAKPOINT_MOBILE]: {weight: 600, size: 20, height: 1.3},
        [BREAKPOINT_DESKTOP]: {weight: 600, size: 22, height: 1.27},
    },
    header5: {
        [BREAKPOINT_MOBILE]: {size: 20, height: 1.3},
        [BREAKPOINT_DESKTOP]: {size: 22, height: 1.27},
    },
    header5Light: {
        [BREAKPOINT_MOBILE]: {weight: 300, size: 20, height: 1.3},
        [BREAKPOINT_DESKTOP]: {weight: 300, size: 22, height: 1.27},
    },
    header6Bold: {
        [BREAKPOINT_MOBILE]: {weight: 600, size: 18, height: 1.33},
        [BREAKPOINT_DESKTOP]: {weight: 600, size: 20, height: 1.3},
    },
    header6: {
        [BREAKPOINT_MOBILE]: {size: 18, height: 1.33},
        [BREAKPOINT_DESKTOP]: {size: 20, height: 1.3},
    },
    header7Bold: {
        [BREAKPOINT_MOBILE]: {weight: 600, size: 16, height: 1.38},
        [BREAKPOINT_DESKTOP]: {weight: 600, size: 18, height: 1.33},
    },
    header7: {
        [BREAKPOINT_MOBILE]: {size: 16, height: 1.38},
        [BREAKPOINT_DESKTOP]: {size: 18, height: 1.33},
    },
    header8Bold: {
        [BREAKPOINT_MOBILE]: {weight: 600, size: 14, height: 1.14},
        [BREAKPOINT_DESKTOP]: {weight: 600, size: 16, height: 1.38},
    },
    header8: {
        [BREAKPOINT_MOBILE]: {size: 14, height: 1.14},
        [BREAKPOINT_DESKTOP]: {size: 16, height: 1.38},
    },
    header8Light: {
        [BREAKPOINT_MOBILE]: {weight: 300, size: 14, height: 1.14},
        [BREAKPOINT_DESKTOP]: {weight: 300, size: 16, height: 1.38},
    },
    header9Bold: {
        [BREAKPOINT_MOBILE]: {weight: 600, size: 11, height: 1.18},
        [BREAKPOINT_DESKTOP]: {weight: 600, size: 14, height: 1.14},
    },
    header9: {
        [BREAKPOINT_MOBILE]: {size: 11, height: 1.18},
        [BREAKPOINT_DESKTOP]: {size: 14, height: 1.14},
    },
    header10Bold: {
        [BREAKPOINT_MOBILE]: {weight: 600, size: 11, height: 1.18},
    },
    header10: {
        [BREAKPOINT_MOBILE]: {size: 11, height: 1.18},
    },
    carouselHeader: {
        [BREAKPOINT_MOBILE]: {weight: 600, size: 16, height: 1.38},
        [BREAKPOINT_DESKTOP]: {weight: 600, size: 20, height: 1.4},
        [BREAKPOINT_LARGE_DESKTOP]: {weight: 600, size: 32, height: 1.25},
    },
    carouselCopy: {
        [BREAKPOINT_MOBILE]: {weight: 600, size: 13, height: 1.15},
        [BREAKPOINT_TABLET_PORTRAIT]: {weight: 600, size: 14, height: 1.14},
        [BREAKPOINT_DESKTOP]: {weight: 600, size: 16, height: 1.13},
        [BREAKPOINT_LARGE_DESKTOP]: {weight: 600, size: 18, height: 1.11},
    },
    bodyCopy1Bold: {
        [BREAKPOINT_MOBILE]: {size: 20, height: 1.4},
    },
    bodyCopy1: {
        [BREAKPOINT_MOBILE]: {size: 20, height: 1.4},
    },
    bodyCopy2Bold: {
        [BREAKPOINT_MOBILE]: {size: 18, height: 1.33},
        [BREAKPOINT_TABLET_PORTRAIT]: {size: 18, height: 1.44},
        [BREAKPOINT_DESKTOP]: {size: 18, height: 0.89},
    },
    bodyCopy2: {
        [BREAKPOINT_MOBILE]: {size: 18, height: 1.33},
        [BREAKPOINT_TABLET_PORTRAIT]: {size: 18, height: 1.44},
        [BREAKPOINT_DESKTOP]: {size: 18, height: 1.3},
    },
    bodyCopy2Light: {
        [BREAKPOINT_MOBILE]: {weight: 300, size: 18, height: 1.33},
        [BREAKPOINT_TABLET_PORTRAIT]: {weight: 300, size: 18, height: 1.44},
        [BREAKPOINT_DESKTOP]: {weight: 300, size: 18, height: 1.3},
    },
    bodyCopy3Bold: {
        [BREAKPOINT_MOBILE]: {size: 16, height: 1.38},
    },
    bodyCopy3: {
        [BREAKPOINT_MOBILE]: {size: 16, height: 1.38},
    },
    bodyCopy3Light: {
        [BREAKPOINT_MOBILE]: {size: 16, height: 1.38, weight: 300},
    },
    bodyCopy4Bold: {
        [BREAKPOINT_MOBILE]: {size: 14, height: 1.29},
        [BREAKPOINT_DESKTOP]: {size: 16, height: 1.38},
    },
    bodyCopy4: {
        [BREAKPOINT_MOBILE]: {size: 14, height: 1.29},
        [BREAKPOINT_DESKTOP]: {size: 16, height: 1.38},
    },
    bodyCopy4Light: {
        [BREAKPOINT_MOBILE]: {weight: 300, size: 14, height: 1.29},
        [BREAKPOINT_DESKTOP]: {weight: 300, size: 16, height: 1.38},
    },
    bodyCopy5Bold: {
        [BREAKPOINT_MOBILE]: {size: 13, height: 1.31},
        [BREAKPOINT_DESKTOP]: {size: 14, height: 1.29},
    },
    bodyCopy5: {
        [BREAKPOINT_MOBILE]: {size: 13, height: 1.31},
        [BREAKPOINT_DESKTOP]: {size: 14, height: 1.29},
    },
    bodyCopy6Bold: {
        [BREAKPOINT_MOBILE]: {size: 11, height: 1.36},
        [BREAKPOINT_DESKTOP]: {size: 13, height: 1.31},
    },
    bodyCopy6: {
        [BREAKPOINT_MOBILE]: {size: 11, height: 1.36},
        [BREAKPOINT_DESKTOP]: {size: 13, height: 1.31},
    },
    bodyCopy6Light: {
        [BREAKPOINT_MOBILE]: {weight: 300, size: 11, height: 1.36},
        [BREAKPOINT_DESKTOP]: {weight: 300, size: 13, height: 1.31},
    },
    bodyCopy7Bold: {
        [BREAKPOINT_MOBILE]: {weight: 600, size: 11, height: 1.36},
        [BREAKPOINT_DESKTOP]: {size: 11, height: 1.36},
    },
    bodyCopy7: {
        [BREAKPOINT_MOBILE]: {size: 11, height: 1.36},
    },
    hudTileCopy: {
        [BREAKPOINT_MOBILE]: {size: 14, height: 1.29},
    },
    hudTileCopyBold: {
        [BREAKPOINT_MOBILE]: {weight: 600, size: 14, height: 1.29},
    },
    hudTileCopyLight: {
        [BREAKPOINT_MOBILE]: {weight: 300, size: 14, height: 1.29},
    },
    hudTileHeaderLight: {
        [BREAKPOINT_MOBILE]: {weight: 300, size: 16, height: 1.38},
    },
    hudTileHeader: {
        [BREAKPOINT_MOBILE]: {weight: 400, size: 16, height: 1.38},
    },
    hudTileHeaderBold: {
        [BREAKPOINT_MOBILE]: {weight: 600, size: 16, height: 1.38},
    },
    hudTileScore: {
        [BREAKPOINT_MOBILE]: {size: 20, height: 1.38},
    },
    hudTileScoreBold: {
        [BREAKPOINT_MOBILE]: {weight: 600, size: 20, height: 1.38},
    },
    hudLargeScore: {
        [BREAKPOINT_MOBILE]: {size: 34},
    },
    eventStatus: {
        [BREAKPOINT_MOBILE]: {size: 18, height: 0.89},
        [BREAKPOINT_DESKTOP]: {size: 24, height: 0.92},
    },
    posterTitle1: {
        [BREAKPOINT_MOBILE]: {weight: 600, size: 56, height: 0.79},
        [BREAKPOINT_TABLET_LANDSCAPE]: {weight: 600, size: 64, height: 0.78},
        [BREAKPOINT_DESKTOP]: {weight: 600, size: 76, height: 0.79},
    },
    posterTitle2: {
        [BREAKPOINT_MOBILE]: {weight: 600, size: 48, height: 0.79},
        [BREAKPOINT_DESKTOP]: {weight: 600, size: 66, height: 0.92},
    },
    posterTitle3: {
        [BREAKPOINT_MOBILE]: {weight: 600, size: 38, height: 0.79},
        [BREAKPOINT_DESKTOP]: {weight: 600, size: 56, height: 0.92},
    },
    synopsis: {
        [BREAKPOINT_MOBILE]: {size: 16, height: 1.25},
        [BREAKPOINT_DESKTOP]: {size: 20, height: 1.2},
    },
    category: {
        [BREAKPOINT_MOBILE]: {size: 18, height: 0.89},
        [BREAKPOINT_DESKTOP]: {size: 24, height: 0.92},
    },
    primaryButton: {
        [BREAKPOINT_MOBILE]: {size: 16},
        [BREAKPOINT_DESKTOP]: {size: 18},
    },
    primaryButtonLive: {
        [BREAKPOINT_MOBILE]: {weight: 600, size: 16},
        [BREAKPOINT_DESKTOP]: {weight: 600, size: 18},
    },
    offerCategoryDefault: {
        [BREAKPOINT_MOBILE]: {size: 22, height: 1.27},
    },
    offerCategorySelected: {
        [BREAKPOINT_MOBILE]: {weight: 600, size: 22, height: 1.27},
    },
    offerFeatureList: {
        [BREAKPOINT_MOBILE]: {size: 16, height: 1.69},
        [BREAKPOINT_TABLET_PORTRAIT]: {size: 18, height: 1.78},
        [BREAKPOINT_DESKTOP]: {weight: 600, size: 18, height: 2},
    },
    offerPeriod: {
        [BREAKPOINT_MOBILE]: {size: 16, height: 1.375},
        [BREAKPOINT_LARGE_DESKTOP]: {size: 20, height: 1.3},
    },
    offerPrice: {
        [BREAKPOINT_MOBILE]: {weight: 600, size: 30},
        [BREAKPOINT_TABLET_PORTRAIT]: {weight: 600, size: 52, height: 1.07},
    },
    planPrice: {
        [BREAKPOINT_MOBILE]: {weight: 600, size: 30},
    },
    footnoteMarker: {
        [BREAKPOINT_MOBILE]: {weight: 800, size: 18, height: 0.8},
        [BREAKPOINT_TABLET_PORTRAIT]: {weight: 800, size: 19, height: 0.9},
    },
    footnoteMarker2: {
        [BREAKPOINT_MOBILE]: {size: 16, height: 0.8},
        [BREAKPOINT_TABLET_PORTRAIT]: {size: 17, height: 0.9},
    },
    primaryNumber: {
        [BREAKPOINT_MOBILE]: {size: 60, height: 1.03},
        [BREAKPOINT_DESKTOP]: {size: 85},
    },
    secondaryNumber: {
        [BREAKPOINT_MOBILE]: {size: 54, height: 1.15},
    },
    subPrice: {
        [BREAKPOINT_MOBILE]: {weight: 600, size: 44, height: 0.9},
        [BREAKPOINT_LARGE_DESKTOP]: {weight: 600, size: 57, height: 0.93},
    },
    pieChartNumber: {
        [BREAKPOINT_MOBILE]: {size: 24, height: 1.25},
    },
    infoTileBadge: {
        [BREAKPOINT_MOBILE]: {size: 12, height: 1},
    },
    continueWatching: {
        [BREAKPOINT_MOBILE]: {weight: 600, size: 14},
        [BREAKPOINT_PRETTY_LARGE_DESKTOP]: {weight: 600, size: 20},
        [BREAKPOINT_REALLY_LARGE_DESKTOP]: {weight: 600, size: 27},
    },
    gamblingMessageBold: {
        [BREAKPOINT_MOBILE]: {weight: 600, size: 13, height: 1.31},
        [BREAKPOINT_DESKTOP]: {weight: 600, size: 14, height: 1.29},
    },
    gamblingMessageLight: {
        [BREAKPOINT_MOBILE]: {weight: 300, size: 13, height: 1.31},
        [BREAKPOINT_DESKTOP]: {weight: 300, size: 14, height: 1.29},
    },
};
