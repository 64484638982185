import React from 'react';
import bacon from 'baconjs';
import noop from 'lodash/noop';
import property from 'lodash/property';
import styled from 'styled-components';

import {BA04TeamTi, CAM03Finite, SrcsetImg} from '@fsa-streamotion/streamotion-web-mui';
import {hydrateStream} from '@fsa-streamotion/bacon-widget';
import {accountsEnvs} from '@fsa-streamotion/streamotion-web-widgets-common';

import getSportMenu, {KAYO_RESOURCES_URL} from '../../../../../todo-move-to-widgets-common/streams/endpoints/resources/sport-menu';

import {pickFp} from '../../../../../todo-move-to-widgets-common/utils/fp-helpers';

const {envsFromWidgetSettings} = accountsEnvs;

const StyledSrcsetImg = styled(SrcsetImg)`
    display: block;
    width: 100%;
    height: 100%;
`;

/**
 * Sports Nav component stream
 *
 * @param {Object}   options                    see below
 * @param {Object}   [options.hydration={}]     hydration object, pass me back what was returned on SSR
 * @param {string}   [options.resourcesEnv]     resourcesEnv (e.g. as passed in from web-app)
 * @param {Function} [options.onNavClick=noop]  onNavClick callback to fire when sport/series icon clicked
 *
 * @returns {bacon.Observable.<ComponentStreamEvent>} stream with event objects containing react element, hydration and data
 */
export default function sportsNav({
    hydration = {},
    onNavClick = noop,
    ...rest
}) {
    const {resourcesEnv} = envsFromWidgetSettings(rest);
    const getSportMenu = getSportMenuOfResourceEnv(resourcesEnv);

    const sportsMenuData$ = hydrateStream(
        hydration.sportsMenuData$,
        getSportMenu().first(),
    );

    const selectedStateBus = new bacon.Bus();
    const selectedState$ = sportsMenuData$
        .map('.0.sport')
        .map((sport) => ({
            sport,
            series: undefined,
        }))
        .concat(selectedStateBus)
        .slidingWindow(2, 1)
        .map(([prev, next]) => ({...prev, ...next}));

    const seriesMenuData$ = (hydration.seriesMenuData$ ? bacon.later(0, hydration.seriesMenuData$) : bacon.never())
        .concat(
            selectedState$
                .map(pickFp(['sport']))
                .flatMapLatest(getSportMenu)
                .skip(hydration.seriesMenuData$ ? 1 : 0)
        )
        .map((seriesData) => seriesData.filter(({id}) => !!id));

    const activeSportId$ = selectedState$.map('.sport');
    const activeSeriesId$ = seriesMenuData$
        .first()
        .map('.0.id')
        .concat(
            selectedState$
                .map('.series')
                .skip(1)
        );

    const combinedMenuData$ = bacon.combineTemplate({
        sportData: sportsMenuData$,
        seriesData: seriesMenuData$,
        sport: activeSportId$,
        series: activeSeriesId$,
    })
        .map(({sportData, sport, seriesData, series}) => (
            [
                sportData.map(getSportMassager(resourcesEnv, sport)),
                seriesData.map(getSeriesMassager(resourcesEnv, sport, series)),
            ]
                .filter(property('length'))
        ));

    const children$ = combinedMenuData$
        .map((menusCollection) => (
            menusCollection.map((menuItems, menuIndex) => (
                <CAM03Finite
                    initialFocusIndex={menuItems.findIndex(property('isActive'))}
                    key={`nav-row-${menuIndex}`}
                >
                    {menuItems.map(({isActive, imgSrc, title, sport, series}) => (
                        <BA04TeamTi
                            aria-label={title}
                            isNavIndicatorOnHover={true}
                            isPressed={isActive}
                            key={`${sport}-${series}`}
                            onClick={() => {
                                onNavClick({sportId: sport, seriesId: series, title});
                                selectedStateBus.push({sport, series});
                            }}
                            pressedIndicator="nav-indicator"
                            title={title}
                        >
                            <StyledSrcsetImg
                                alt={title}
                                sizes="96px" // note: is dependant on the fixed size of BA04TeamTi
                                srcsetOptions={{
                                    100: `${imgSrc}?imwidth=100`,
                                    320: `${imgSrc}?imwidth=320`,
                                }}
                            />
                        </BA04TeamTi>
                    ))}
                </CAM03Finite>
            ))
        ));

    return bacon.combineTemplate({
        reactElement: children$,
        hydration: {
            sportsMenuData$,
            seriesMenuData$,
        },
        data: {
            activeSeriesId: activeSeriesId$,
            activeSportId: activeSportId$,
            combinedMenuData: combinedMenuData$,
        },
    });
}

function getSportMenuOfResourceEnv(resourcesEnv) {
    return (args) => getSportMenu({resourcesEnv, ...args});
}

export function getSportMassager(resourcesEnv, activeSportId) {
    return ({sport, name}) => ({
        isActive: activeSportId === sport,
        imgSrc: `${KAYO_RESOURCES_URL[resourcesEnv]}/sport-logos/1x1/${sport}.png`,
        title: name,
        sport,
    });
}

export function getSeriesMassager(resourcesEnv, activeSportId, activeSeriesId) {
    return ({id, name, sport}) => ({
        isActive: activeSportId === sport && activeSeriesId === id,
        imgSrc: `${KAYO_RESOURCES_URL[resourcesEnv]}/series-logos/1x1/${sport}/${id}.png`,
        title: name,
        sport,
        series: id,
    });
}

/**
 * @typedef {object} ComponentStreamEvent
 * @property {Object} reactElement  component's react element
 * @property {Object} hydration     hydration data to rehydrate on client mount
 * @property {Object} data          internal data exposed for reference elsewhere
 */
