import React from 'react';
import Ic from '..';

const IC233About = (args) => (
    <Ic {...args}>
        <path d="M63.567 75.627v8.91c0 .98-.789 1.774-1.762 1.774H38.547a1.769 1.769 0 0 1-1.762-1.775v-8.91c0-.98.789-1.774 1.762-1.774h3.682c.973 0 1.762-.795 1.762-1.776v-22.9c0-.98-.789-1.774-1.762-1.774h-3.682a1.769 1.769 0 0 1-1.762-1.776v-8.904c0-.98.789-1.775 1.762-1.775h16.051c.973 0 1.762.795 1.762 1.775v35.354c0 .98.79 1.776 1.762 1.776h3.683c.973 0 1.762.794 1.762 1.775zM48.414 26.07c3.308 0 5.99-2.702 5.99-6.035 0-3.334-2.682-6.036-5.99-6.036-3.309 0-5.99 2.702-5.99 6.036 0 3.333 2.681 6.035 5.99 6.035z" />
    </Ic>
);

IC233About.displayName = 'IC233About';

export default IC233About;
