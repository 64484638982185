import React from 'react';

import Ic from '..';

const IC332Food = ({
    ...otherProps
}) => (
    <Ic {...otherProps}>
        <g>
            <g transform="translate(-140 -10)">
                <g transform="translate(140 10)">
                    <g transform="translate(3.452 3)">
                        <g transform="translate(0 .23)">
                            <defs>
                                <filter
                                    width={71}
                                    height={78.2}
                                    x={7.5}
                                    y={8.1}
                                    filterUnits="userSpaceOnUse"
                                >
                                    <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
                                </filter>
                            </defs>
                            <mask
                                id="mask-2_00000096780674198294247620000002818982076443400888_"
                                width={71}
                                height={78.2}
                                x={7.5}
                                y={8.1}
                                maskUnits="userSpaceOnUse"
                            >
                                <g>
                                    <path
                                        d="M7.5 8.1h71v78.2h-71z"
                                    />
                                </g>
                            </mask>
                            <path
                                d="M72.5 86.3c-2-.3-3.5-1.5-4.8-2.9-7.3-8-14.6-16.1-21.8-24.1-3.8-4.3-7.5-8.7-11.4-13-.4-.4-1.2-.9-1.5-.8-5.8 2.1-10.7.1-15-3.7-4.6-4.1-8.3-8.9-9.9-15-.2-.8-.4-1.7-.5-2.5V21c.3-1.2.5-2.4.9-3.5 2.6-7.9 11-11.6 19.2-8C36 13.3 42 19.8 44.1 29.1c1 4.2-.1 8-3.1 11.2-.7.8-1.6 1.4-2.5.5-.9-.9-.3-1.7.4-2.4 2.7-2.7 3.5-6.1 2.3-9.6-2.8-8.6-8.2-14.9-16.9-17.3-9-2.4-14.9 5-13.7 12.8 1.3 8.2 6.4 13.9 13.3 18 2.6 1.6 5.6 1.6 8.4.3 1.5-.7 2.5-.5 3.6.8C47 56 58.3 68.6 69.6 81.2c.7.8 1.8 1.5 2.9 1.8.8.2 2.2-.1 2.6-.7.5-.7.5-2.1.1-2.9-.7-1.5-1.6-2.9-2.7-4.1-8.1-9.4-16.2-18.7-24.3-28-1.2-1.3-2.3-2.6-3.5-3.9-.7-.8-1.1-1.6-.3-2.5.9-.9 1.7-.3 2.4.5 6.1 6.9 12.3 13.7 18.4 20.6 3.8 4.3 7.4 8.8 10.9 13.3 1.2 1.6 2 3.6 2.3 5.5.5 2.6-1 4.3-3.6 5-.3.1-.6.2-1 .3h-1.3z"
                                mask="url(#mask-2_00000096780674198294247620000002818982076443400888_)"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                fill="#dfdfdf"
                            />
                        </g>
                        <g transform="translate(11.745)">
                            <defs>
                                <filter
                                    width={22.9}
                                    height={32.1}
                                    x={37.9}
                                    y={7.5}
                                    filterUnits="userSpaceOnUse"
                                >
                                    <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
                                </filter>
                            </defs>
                            <mask
                                id="mask-4_00000108276694866977871000000013737358851282860699_"
                                width={22.9}
                                height={32.1}
                                x={37.9}
                                y={7.5}
                                maskUnits="userSpaceOnUse"
                            >
                                <g>
                                    <path
                                        d="M37.9 7.5h22.9v32.1H37.9z"
                                    />
                                </g>
                            </mask>
                            <path
                                d="M59.7 7.5c1.4 1.1 1.5 1.8.3 3.1-3.6 4-7.3 7.9-10.9 11.9-1.2 1.3-2.2 2.9-3.1 4.5-.9 1.5-.9 3.1-.3 4.7.2.6 0 1.5-.4 2-1.7 2-3.5 3.9-5.4 5.9-.6-.7-1.2-1.3-1.9-2.1 1.4-1.5 2.7-2.9 4-4.4.3-.4.8-.9.7-1.3-.9-3.8.6-6.9 2.9-9.5 4.4-5 9-9.9 13.5-14.8h.6z"
                                mask="url(#mask-4_00000108276694866977871000000013737358851282860699_)"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                fill="#dfdfdf"
                            />
                        </g>
                        <path
                            d="m86.5 22.7-11.1 12c-2.6 2.8-5.3 5.3-9.2 6.2-2.9.6-5.3-.3-7.4-2.1-.4-.4-.2-1.4-.3-2.1.7 0 1.6-.4 2 0 3.5 2.9 6.8 1.8 9.5-.8 3.8-3.6 7.3-7.6 11-11.4.9-.9 1.8-1.9 2.6-2.9 1-1.2 2-1.3 2.9.1v1z"
                        />
                        <path
                            d="M77.3 13.4c-.3.5-.5.9-.8 1.2-3.3 3.6-6.6 7.2-9.8 10.8-.7.8-1.5 1.3-2.4.5-1-.9-.4-1.7.3-2.4 3.3-3.6 6.6-7.2 9.9-10.7.4-.4 1.3-.6 2-.6.2 0 .5.7.8 1.2"
                        />
                        <path
                            d="M69.7 30.7c-.5-.4-1-.6-1.2-1-.2-.4-.1-1.1.1-1.4.7-1 1.6-1.8 2.4-2.7 2.6-2.8 5.1-5.6 7.7-8.4.7-.8 1.5-1.3 2.4-.5.9.8.4 1.6-.3 2.4-3.2 3.5-6.5 7.1-9.7 10.6-.2.3-.8.6-1.4 1"
                        />
                        <path
                            d="M58.3 47.2c-.9-1-1.5-1.7-2.3-2.6 1-.5 1.7-1 2.6-1.3.2-.1 1.1.7 1 1-.2.8-.7 1.6-1.3 2.9"
                        />
                        <path
                            d="M42.8 63.6c-4.1 4.5-8.2 9-12.3 13.4-1.8 1.9-3.5 3.9-5.4 5.8-4.8 4.9-9 1.5-11.2-2.8-.9-1.7-.1-3.5 1.2-4.8 6.4-7 12.8-14 19.4-21.1.6.7 1.3 1.3 1.9 2.1L23.5 70.3c-1.9 2-3.8 4-5.6 6.1-1.6 1.8-1.6 3.3-.1 4.8 1.6 1.5 3.6 1.5 5.1-.1 3.4-3.6 6.7-7.2 10-10.9 2.6-2.9 5.3-5.8 8-8.7.7.6 1.3 1.3 1.9 2.1"
                        />
                    </g>
                </g>
            </g>
        </g>
    </Ic>
);

IC332Food.displayName = 'IC332Food';

export default IC332Food;
