import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import {Button} from 'normalized-styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {panther, blanc} from '../../../../../common/palette';
import {transition} from '../../../../../common/animations';
import IC31Tick from '../../../ic/31-tick';
import {SCREEN_768_TABLET, SCREEN_1920_DESKTOP, SCREEN_2560_DESKTOP} from '../../../../../common/screen-sizes';

const CHECKMARK_SIZE_PX = 21;
const CHECKMARK_OFFSET_HOVER_PX = CHECKMARK_SIZE_PX / -2;

const StyledButton = styled(Button)`
    display: flex;
    position: relative;
    transition: ${transition('opacity')};
    outline: none;
    border: none;
    background-color: transparent;
    padding: 0;
    width: 188px;
    height: 41px;
    color: ${blanc};
    font: var(--ionic-body-copy-4-light);

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: 216px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 283px;
        height: 61px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
        width: 356px;
        height: 71px;
    `}

    &::before,
    &::after {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: ${transition('background-color', 'color')};
        background-color: ${rgba(blanc, 0.2)};
        height: 41px;

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            height: 61px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
            height: 71px;
        `}
    }

    &::before {
        margin-right: 2px;
        width: 65px;
        text-transform: uppercase;
        font: var(--ionic-header-8-bold);
        content: attr(data-short-label);

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            width: 99px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
            width: 120px;
        `}
    }

    &::after {
        box-sizing: border-box;
        width: 121px;
        content: attr(data-label);

        ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
            width: 149px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            width: 182px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
            width: 233px;
        `}
    }

    &:disabled {
        opacity: 0.35;
        cursor: not-allowed;
    }

    &:not(:disabled) {
        &:hover,
        &:focus,
        &[aria-pressed='true'] {
            &::before,
            &::after {
                background-color: ${blanc};
                color: ${panther};
            }

            &::after {
                font: var(--ionic-body-copy-4-bold);
            }
        }

        &:hover {
            &::before,
            &::after {
                background-color: ${rgba(blanc, 0.8)};
            }
        }
    }
`;

const CheckedMarkCircle = styled.span`
    position: absolute;
    top: ${CHECKMARK_OFFSET_HOVER_PX}px;
    right: ${CHECKMARK_OFFSET_HOVER_PX}px;
    transform: scale(${({isActive}) => isActive ? '100%' : 0});
    transition: transform 300ms cubic-bezier(0.68, -0.55, 0.27, 1.55); /* https://easings.net/#easeInOutBack */
`;

const VPBA03QualityButton = React.forwardRef(({
    label,
    shortLabel,
    onClick,
    isActive,
    ...htmlAttributes
}, ref) => (
    <StyledButton
        {...htmlAttributes}
        ref={ref}
        onClick={onClick}
        aria-pressed={isActive}
        data-label={label}
        data-short-label={shortLabel}
    >
        <CheckedMarkCircle isActive={isActive} role="presentation">
            <IC31Tick secondaryColor={blanc} size={`${CHECKMARK_SIZE_PX}px`} />
        </CheckedMarkCircle>
    </StyledButton>
));

VPBA03QualityButton.propTypes = {
    /** Button label */
    label: propTypes.string.isRequired,
    /** Shoter button label */
    shortLabel: propTypes.string.isRequired,
    /** Function to execute when button is clicked */
    onClick: propTypes.func,
    /** is button active? */
    isActive: propTypes.bool,
};

VPBA03QualityButton.displayName = 'VPBA03QualityButton';

export default VPBA03QualityButton;
