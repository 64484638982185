import React, {useState} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import noop from 'lodash/noop';
import {Section} from 'normalized-styled-components';

import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {blanc} from '../../../../common/palette';
import {visuallyHiddenBaseStyles} from '../../../../common/visually-hidden';
import BA08BtnSec from '../../../atoms/ba/08-btn-sec';
import IC27ArrowD from '../../../atoms/ic/27-arrow-d';

const PaymentSection = styled(Section)`
    color: ${blanc};
`;

const ChildrenWrapper = styled.div`
    font: var(--mui-body-copy-4);
`;

const TabPanel = styled(Section)`
    &[hidden] {
        ${visuallyHiddenBaseStyles}
    }
`;

const TabRow = styled.ul`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0 7px;
    align-items: flex-start;
    justify-content: center;
    margin: 0 0 25px;
    padding-left: 0;
    list-style: none;
    ${stylesWhen('isBlock')`
        grid-auto-columns: 1fr;
    `}
`;

const TabButtonWrapper = styled.li`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const StyledIC27ArrowD = styled(IC27ArrowD)`
    margin-top: 4px;
`;

const OR52TabPanels = ({
    className,
    isBlock,
    onTabChange = noop,
    sections = [],
    shouldPersistDom,
}) => {
    const [visibleIndex, setVisibleIndex] = useState(0);

    return (
        <PaymentSection className={classnames('OR52TabPanels', className)}>
            {!!sections.length && (
                <React.Fragment>
                    <TabRow isBlock={isBlock} role="tablist">
                        {sections.map(({label}, index) => (
                            <TabButtonWrapper key={label} role="presentation">
                                <BA08BtnSec
                                    isBlock={isBlock}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        setVisibleIndex(index);
                                        onTabChange({label, event, index});
                                    }}
                                    href={shouldPersistDom || index === visibleIndex ? `#tabpanel-${index}` : undefined}
                                    role="tab"
                                    id={`tab-${index}`}
                                    aria-selected={index === visibleIndex}
                                >
                                    {label}
                                </BA08BtnSec>
                                {index === visibleIndex && (
                                    <StyledIC27ArrowD size="14px" />
                                )}
                            </TabButtonWrapper>
                        ))}
                    </TabRow>
                    <ChildrenWrapper>
                        {shouldPersistDom
                            ? sections.map(({children, label}, index) => (
                                <TabPanel
                                    key={label}
                                    hidden={index !== visibleIndex}
                                    role="tabpanel"
                                    aria-labelledby={`tab-${index}`}
                                    id={`tabpanel-${index}`}
                                >
                                    {children}
                                </TabPanel>
                            ))
                            : (
                                <TabPanel
                                    role="tabpanel"
                                    aria-labelledby={`tab-${visibleIndex}`}
                                    id={`tabpanel-${visibleIndex}`}
                                >
                                    {sections[visibleIndex].children}
                                </TabPanel>
                            )}
                    </ChildrenWrapper>
                </React.Fragment>
            )}
        </PaymentSection>
    );
};

OR52TabPanels.displayName = 'OR52TabPanels';

OR52TabPanels.propTypes = {
    /** CSS class name(s) */
    className: classNameType,
    /** When true enforce the tab grid to take the full width of the parent */
    isBlock: propTypes.bool,
    /** Pass the relevant change to the parent */
    onTabChange: propTypes.func,
    /** Tab labels and their children */
    sections: propTypes.arrayOf(propTypes.shape({
        label: propTypes.string,
        children: propTypes.node,
    })),
    /** Whether to keep DOM in tact when switching tabs (vital for reuse payment switching to showing/hiding Zuora iframe) */
    shouldPersistDom: propTypes.bool,
};

export default OR52TabPanels;
