import React from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {silverLining} from '../../../../common/palette';

const StatDataList = styled.dl`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    color: ${silverLining};
`;

const StatName = styled.dt`
    font: var(--mui-hud-tile-copy-light);
`;

const StatValue = styled.dd`
    margin: 0;
    font: var(--mui-hud-tile-copy-bold);
`;

const IGM08CxStat = ({
    className,
    statValues = [],
}) => (
    <StatDataList className={classNames('IGM08CxStat', className)}>
        {statValues.map(({statName, statValue}, index) => (
            <span key={typeof statName === 'string' ? statName : `__${index}`}>
                <StatName>{statName}</StatName>
                <StatValue>{statValue}</StatValue>
            </span>
        ))}
    </StatDataList>
);

IGM08CxStat.displayName = 'IGM08CxStat';

IGM08CxStat.propTypes = {
    /** Additional Class(es) */
    className: classNameType,
    /** An array of statName/statValue objects to be rendered as a table-like structure */
    statValues: propTypes.arrayOf(propTypes.shape({
        statName: propTypes.node,
        statValue: propTypes.node,
    })),
};

export default IGM08CxStat;
