import React, {useState, useEffect} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import noop from 'lodash/noop';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import formatCurrency from '../../../../common/format-currency';
import {blanc} from '../../../../common/palette';

import Collapsed from './collapsed';
import Expanded from './expanded';
import Validated from './validated';

const ERROR_MESSAGE = 'The gift card code must be numbers only. Click the link below for help finding your code.';

const GiftCheckoutForm = styled.form`
    display: grid;
    grid-row-gap: 14px;
    margin-top: 35px;
    color: ${blanc};
`;

const GiftTitle = styled.h2`
    margin: 0;
    color: ${blanc};
    font: var(--ionic-body-copy-2);
`;

const OR117GiftCheckout = ({
    className,
    giftCode,
    giftPin,
    onChangeGiftCode,
    onChangeGiftPin,
    balance,
    cardValidatedBalance,
    currencyDecimalPlaces = 2,
    codeContextualHelpText = 'Find your 30 digit gift card code on the back of your card, below the bar code.',
    pinContextualHelpTexts = [
        'Find your 4 digit PIN code on the back of your card, above the bar code.',
        'If your card has no PIN, please email help@binge.com.au with an image of the back of the gift card and, if you\'re an existing customer, the mobile number linked to your account so we can apply it on your behalf.',
    ],
    errorMessage,
    hasValidated,
    codeHelpButtonText,
    pinHelpButtonText,
    isCollapsed,
    onClickExpand,
    isValidating,
    giftCodeLabel,
    giftPinLabel,
    onSubmitGiftCode = noop,
    onClickRemoveGiftCode,
    cardValidatedParagraphs = [
        'Once the gift card\'s credit has been exhausted we will switch to the alternate credit card to ensure you don\'t lose access.',
        'You can add additional Gift Cards in the My Account section.',
    ],
    giftCardTitle = 'Hubbl Gift Card',
    giftCardDescription = 'Gift Card will automatically apply to your next-billed Hubbl subscription.',
}) => {
    const [validationErrorMessage, setValidationErrorMessage] = useState();

    useEffect(function resetValidationErrorMessage() {
        //  If the user edits the gift code field after getting an error message, then we reset the validation message
        if (validationErrorMessage) {
            setValidationErrorMessage();
        }
    }, [giftCode]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <GiftCheckoutForm
            className={classnames('OR117GiftCheckout', className)}
            method="GET"
            noValidate={true}
            onSubmit={(event) => {
                event.preventDefault();
                setValidationErrorMessage('');
                const isValidInput = event.target.checkValidity();

                return isValidInput
                    ? onSubmitGiftCode()
                    : setValidationErrorMessage(ERROR_MESSAGE); // `patternMismatch` is the only invalid property we haven't accounted for, so let's show a more helpful message in this case.
            }}
        >
            {isCollapsed ? ( // eslint-disable-line no-nested-ternary
                <Collapsed
                    {...{
                        balance: balance ? formatCurrency({amount: balance, currencyDecimalPlaces}) : undefined,
                        onClickExpand,
                        giftCardTitle: <GiftTitle>{giftCardTitle}</GiftTitle>,
                        buttonLabel: 'Enter your gift card code here',
                    }}
                />
            ) : hasValidated ? (
                <Validated
                    {...{
                        cardValidatedBalance,
                        cardValidatedParagraphs,
                        currencyDecimalPlaces,
                        onClickRemoveGiftCode,
                    }}
                />
            ) : (
                <Expanded
                    {...{
                        isValidating,
                        codeContextualHelpText,
                        pinContextualHelpTexts,
                        errorMessage: errorMessage || validationErrorMessage,
                        codeHelpButtonText,
                        pinHelpButtonText,
                        giftCodeLabel,
                        giftPinLabel,
                        giftCode,
                        giftPin,
                        onChangeGiftCode,
                        onChangeGiftPin,
                        giftCardTitle: <GiftTitle>{giftCardTitle}</GiftTitle>,
                        giftCardDescription,
                    }}
                />
            )}
        </GiftCheckoutForm>
    );
};

OR117GiftCheckout.propTypes = {
    /** Remaining account balance */
    balance: propTypes.number,
    /** Classname if passed down */
    className: classNameType,
    /** The entered gift code */
    giftCode: propTypes.string,
    /** The entered gift PIN */
    giftPin: propTypes.string,
    /** Entered gift code change callback */
    onChangeGiftCode: propTypes.func,
    /** Entered gift PIN change callback */
    onChangeGiftPin: propTypes.func,
    /** Validated Card balance */
    cardValidatedBalance: propTypes.number,
    /** Text that appears after you click on code help button */
    codeContextualHelpText: propTypes.string,
    /** Text that appears after you click on PIN help button */
    pinContextualHelpTexts: propTypes.arrayOf(propTypes.string),
    /** Currency decimal places */
    currencyDecimalPlaces: propTypes.number,
    /** Contextual help error message */
    errorMessage: propTypes.string,
    /** Text for contextual code help button */
    codeHelpButtonText: propTypes.string,
    /** Text for contextual PIN help button */
    pinHelpButtonText: propTypes.string,
    /** Gift code input label */
    giftCodeLabel: propTypes.string,
    /** Gift pin input label */
    giftPinLabel: propTypes.string,
    /** Array of text info about validated gift card */
    cardValidatedParagraphs: propTypes.arrayOf(propTypes.string),
    /** Has gift card been validated */
    hasValidated: propTypes.bool,
    /** Gift card in collapse state */
    isCollapsed:  propTypes.bool,
    /** Expand button event handler */
    onClickExpand: propTypes.func,
    /** Loading state */
    isValidating: propTypes.bool,
    /** Gift code form submitted */
    onSubmitGiftCode: propTypes.func,
    /** Callback for remove card */
    onClickRemoveGiftCode: propTypes.func,
    /** Title to use for Gift Card button (collapsed) and heading (expanded) */
    giftCardTitle: propTypes.node,
    /** Description to use under Gift Card heading (expanded) */
    giftCardDescription: propTypes.node,
};

OR117GiftCheckout.displayName = 'OR117GiftCheckout';

export default OR117GiftCheckout;
