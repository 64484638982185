import React, {useEffect, useState} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';

import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {Input} from '../../../../../common/normalized-styled-components';
import {ink} from '../../../../../common/palette';
import sizingStyle from './common';

const StyledInput = styled(Input)`
    ${sizingStyle}
    border: 0;
    background-color: ${rgba(ink, 0.1)};
    padding: 0;
    width: var(--im04-box-input-width);
    height: var(--im04-box-input-height);
    text-align: center;
    color: ${ink};
    font: var(--quicksilver-header-4);

    ${stylesWhen('hasValue')`
        background-color: ${rgba(ink, 0.2)};
    `}

    &:focus,
    &:hover {
        outline: 0;
        background-color: ${rgba(ink, 0.2)};
    }

    &:disabled {
        background-color: ${rgba(ink, 0.1)};
        cursor: not-allowed;
    }

    &::selection {
        background: none;
    }
`;

const NumberBoxInput = React.forwardRef(({
    ariaLabel,
    ...htmlAttributes
}, ref) => {
    const [hasValue, setHasValue] = useState(false);

    /**
     * When input field has a value, we need to change
     * background opacity similar to when input field is active
     */
    useEffect(() => {
        const inputValue = ref?.current?.value.trim();

        setHasValue(!!inputValue);
    }, [ref]);

    return (
        <StyledInput
            {...htmlAttributes}
            aria-label={ariaLabel}
            className="NumberBoxInput"
            hasValue={hasValue}
            pattern="[0-9]*"
            required={true}
            ref={ref}
        />
    );
});

NumberBoxInput.propTypes = {
    /** The screenreader assistive label of the input */
    ariaLabel: propTypes.string.isRequired,
};

NumberBoxInput.displayName = 'NumberBoxInput';

export default NumberBoxInput;
