import React from 'react';
import Ic from '..';

const IC285Kids = (args) => (
    <Ic {...args}>
        <path d="M64.624 9L22 22.45l6.887 55.35L78 51.86 64.624 9zM29.858 27.527L59.93 18.04l9.434 30.235-34.645 18.3-4.86-39.048z" />
        <path d="M33.832 71.35l-3.217-1.665 28.014-53.16 3.217 1.665z" />
        <path d="M70.969 50.925L25.231 27.184l1.646-3.134 45.738 23.741zM34.208 95c-1.467 0-2.858-.38-4.144-1.343-10.153-7.583 3.43-27.895 4.013-28.757l2.93 2.03c-3.431 5.072-10.988 19.244-4.834 23.84 1.896 1.417 4.746.308 9.238-1.62 6.727-2.886 15.938-6.835 26.897-.157l-1.832 3.074c-9.41-5.734-16.99-2.481-23.678.388-3.02 1.296-5.931 2.545-8.59 2.545" />
    </Ic>
);

IC285Kids.displayName = 'IC285Kids';

export default IC285Kids;
