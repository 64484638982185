import React, {useRef, useEffect, useState} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import attempt from 'lodash/attempt';
import classnames from 'classnames';
import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {transition} from '../../../../common/animations';
import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import {ink, slate} from '../../../../common/palette';

import IC42Error from '../../../atoms/ic/42-error';

const Container = styled.div`
    box-sizing: border-box;
    display: flex;
    position: relative;
    flex-direction: column;
`;

const StyledTitle = styled.span`
    color: ${rgba(ink, 0.5)};
    font: var(--quicksilver-body-copy-2);
`;

const ColourName = styled.span`
    width: 200px;
    color: ${rgba(ink, 0.5)};
    font: var(--quicksilver-body-copy-2-light);
`;

const ColourGroup = styled.div`
    display: flex;
    padding-top: 30px;
    gap: 33px;
    width: 332px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 538px;
    `}
`;

const StyledLabel = styled.label`
    position: relative;
    padding: 3px;
`;

// disabling native styles of input
const StyledInput = styled.input`
    position: absolute;
    visibility: hidden;
`;

const StyledSpan = styled.span`
    display: block;
    transition: ${transition('transform', 'outline')};
    outline-offset: 3px;
    border-radius: 50%;
    background: ${({colourFrom, colourTo}) => `linear-gradient(227deg, ${colourFrom} 17%, ${colourTo} 48%, ${colourFrom} 87%)`};
    width: 40px;
    height: 40px;
    overflow: hidden;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 57px;
        height: 57px;
    `}

    &:hover {
        transform: scale(1.1);
    }

    &.picked {
        transform: scale(1.1);
        outline: 1px solid ${ink};
    }

    ${StyledInput}:checked + & { /* stylelint-disable-line order/order */
        transform: scale(1.1);
    }
`;

const StyledIcon = styled(IC42Error)`
    display: inline-block;
    position: absolute;
    top: -3px;
    right: -3px;
    width: 20px;
    height: 20px;
    object-fit: contain;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 24px;
        height: 24px;
    `}
`;

/**
 * GM08ColourPicker
 *  - Used in Glass TV details page
 *
 * @see {@link https://bit.ly/3oWGkyQ}
 */
const GM08ColourPicker = ({
    colours,
    pickedIndex = 1, // default is Ocean Blue if none provided
    onClick,
    className,
    ...htmlAttributes
}) => {
    const [currentlyPickedIndex, setCurrentlyPickedIndex] = useState(pickedIndex);
    const colourRefs = useRef([]);

    // on first render apply "picked" class to the initially picked colour
    useEffect(() => {
        colourRefs.current[pickedIndex]?.classList.add('picked');
        if (pickedIndex !== currentlyPickedIndex) {
            colourRefs.current[currentlyPickedIndex]?.classList.remove('picked');
            setCurrentlyPickedIndex(pickedIndex);
        }
    }, [pickedIndex]); /* eslint-disable-line react-hooks/exhaustive-deps */

    // gets called only on a 'click' event or Enter|Space key press event to distinguish an attribute of "checked"
    // equal to true that is the result of a 'click' or Enter|Space key press from other key downs. (because radio button
    // native bahviour would set the "checked" attribute to true even on left and right arrow keys navigation and we don't want
    // to call the onClick for those)
    const handleSelect = (index) => {
        colourRefs.current[currentlyPickedIndex]?.classList.remove('picked');
        colourRefs.current[index].classList.add('picked');
        setCurrentlyPickedIndex(index);

        attempt(onClick(index));
    };

    const handleClickOrKeyDown = ({event, index}) => {
        switch (event.type) {
            case 'click':
                // keyboard left/right trigger a 'click' on radio buttons, so check if you actually clicked
                if (event.clientX !== 0 && event.clientY !== 0) {
                    handleSelect(index);
                }
                break;
            case 'keydown':
                if (event.key === ' ' || event.key === 'Enter') {
                    handleSelect(index);
                }
                break;
            default:
                break;
        }
    };

    return (
        <Container
            {...htmlAttributes}
            className={classnames('GM08ColourPicker', className)}
        >
            {colours[currentlyPickedIndex]?.name && (
                <StyledTitle>Glass Colour: <ColourName>{colours[currentlyPickedIndex].name}</ColourName></StyledTitle>
            )}
            <ColourGroup role="radiogroup" tabIndex={0}>
                {colours.map(({id: colourId, name, hex1, hex2, isLowStock}, index) => (
                    <StyledLabel key={colourId} htmlFor={name}>
                        <StyledInput
                            id={name}
                            name="colour"
                            aria-label={name}
                            value={name}
                            type="radio"
                            onClick={(event) => handleClickOrKeyDown({event, index})}
                            onKeyDown={(event) => handleClickOrKeyDown({event, index})}
                            tabIndex={index === currentlyPickedIndex ? 0 : -1}
                        />
                        <StyledSpan
                            colourFrom={hex1}
                            colourTo={hex2}
                            data-name={name}
                            ref={(ref) => (colourRefs.current[index] = ref)}
                        />
                        {!!isLowStock && <StyledIcon color={slate} size="auto" />}
                    </StyledLabel>
                ))}
            </ColourGroup>
        </Container>
    );
};

GM08ColourPicker.displayName = 'GM08ColourPicker';

GM08ColourPicker.propTypes = {
    /** List of Sky Glass colours */
    colours: propTypes.arrayOf(
        propTypes.shape({
            /** Colour unique id */
            id: propTypes.number.isRequired,
            /** Colour unique name */
            name: propTypes.string.isRequired,
            /** Colour first hex value used for background gradient */
            hex1: propTypes.string.isRequired,
            /** Colour second hex value used for background gradient */
            hex2: propTypes.string.isRequired,
            /** If a certain colour is low in stock */
            isLowStock: propTypes.bool,
            /** If the colour is set as the default colour */
            default: propTypes.bool,
        })
    ).isRequired,
    /** Initially picked colour index */
    pickedIndex: propTypes.number,
    /** click callback */
    onClick: propTypes.func,
    /** Passed down classnames */
    className: propTypes.string,
};

export default GM08ColourPicker;
