import React from 'react';

import Ic from '..';

const IC52Cross = (args) => (
    <Ic {...args}>
        <polygon
            strokeWidth="0"
            fillRule="evenodd"
            points="49.063 43.775 88.85 4 94.125 9.275 54.337 49.063 96.056 90.781 90.781 96.056 49.063 54.337 9.275 94.125 4 88.85 43.788 49.063 5.925 11.194 11.2 5.919 49.063 43.781"
        />
    </Ic>
);

IC52Cross.displayName = 'IC52Cross';

export default IC52Cross;
