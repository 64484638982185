import React from 'react';

import Ic from '..';

const IC176InteractiveStats = (args) => (
    <Ic {...args}>
        <path d="M26.2 53.9h2.9v21.5h-2.9zM59.9 40.1h2.9v35.4h-2.9zM71.1 34.1H74v41.4h-2.9zM37.4 53.6h2.9v21.9h-2.9zM48.6 43.5h2.9v32h-2.9zM36.8 49.1H26.1v-2.8h10.2L50 33.8c.3-.3.6-.3.9-.3h10.8l10.4-8.9 1.9 2.1L63.1 36c-.3.3-.6.3-.9.3H51.5L37.8 48.7c-.3.2-.6.4-1 .4z" />
    </Ic>
);

IC176InteractiveStats.displayName = 'IC176InteractiveStats';

export default IC176InteractiveStats;
