import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import noop from 'lodash/noop';

import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';

import {transition} from '../../../common/animations';
import {IC103Loading} from '../../atoms/ic';

// Render app dock over page content
const PAGE_CONTENT_Z_INDEX = 0;
const APP_DOCK_Z_INDEX = 2; // same z-index as NAV_Z_INDEX and DOM order makes sure app dock is on top of other things
const NAV_Z_INDEX = 2; // nav needs to appear on top of genre menu, which really deserves its own named prop like search here :sob: after showcase

const PageAppDockComposer = styled.div`
    display: flex;
    flex-direction: row-reverse;
    flex-grow: 1;
    justify-content: space-between;

    ${stylesWhen('isFullScreenVideoPlayerOpen')`
        height: 0;
        opacity: 0;
        overflow-y: hidden;
    `}
`;

const HeaderNavLayer = styled.div`
    position: fixed;
    top: 0;
    z-index: ${NAV_Z_INDEX};
    margin-bottom: -69px;
    width: 100%;
`;

const AppDockLayer = styled.div`
    position: fixed;
    top: 69px;
    right: 0;
    z-index: ${APP_DOCK_Z_INDEX};
    width: 0;
`;

const PageContentRelativeContainer = styled.div`
    position: relative;
    flex-grow: 1;
    z-index: ${PAGE_CONTENT_Z_INDEX};
    width: 100%;
`;

const StyledIC103Loading = styled(IC103Loading)`
    display: none;
    position: fixed;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    pointer-events: none;
`;

const ChildrenLayer = styled.div`
    transition: ${transition('filter')};
    filter: brightness(100%); /* Not using opacity, so we dont inadvertantly reveal the green-screen player hiding behind our children */
    /* height: 100%; Adding height causes search reults page to have double scrollbar, we want height to be determined by the children */

    &[aria-busy='true'] {
        filter: brightness(50%);
        pointer-events: none;

        + ${StyledIC103Loading} {
            display: block;
        }
    }
`;

const PASSIVE_EVENT_ARGS = {passive: true};

export default class NavEnhancedTemplate extends React.Component {
    static displayName = 'NavEnhancedTemplate';

    static propTypes = {
        appDock: propTypes.node,
        children: propTypes.node,
        headerNav: propTypes.node,
        isAppDockActive: propTypes.bool,
        isFullScreenVideoPlayerOpen: propTypes.bool,
        isLoading: propTypes.bool,
        onCloseAppDock: propTypes.func,
        search: propTypes.node,
        loadingSpinnerId: propTypes.string,
    };

    static defaultProps = {
        onCloseAppDock: noop,
    };

    componentDidMount() {
        document.addEventListener('click', this.handleDocumentClick, PASSIVE_EVENT_ARGS);
        document.addEventListener('keydown', this.handleKeydown, PASSIVE_EVENT_ARGS);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleDocumentClick, PASSIVE_EVENT_ARGS);
        document.removeEventListener('keydown', this.handleKeydown, PASSIVE_EVENT_ARGS);
    }

    handleKeydown = ({key}) => {
        if (key === 'Escape') {
            this.props.onCloseAppDock();
        }
    };

    handleDocumentClick = ({target}) => {
        const refs = [this.headerNavRef, this.appDockRef];

        if (
            refs.includes(target) // one of our menus' divs was clicked on
            || refs.some((ref) => ref.contains(target)) // the descendant of one of our menus' divs was clicked on
        ) {
            return; // ignore all clicks within the menu
        }

        this.props.onCloseAppDock();
    };

    handlePageAppDockComposerFocus = ({currentTarget}) => {
        currentTarget.scrollLeft = 0;
    };

    render() {
        return (
            <PageAppDockComposer
                isFullScreenVideoPlayerOpen={this.props.isFullScreenVideoPlayerOpen}
                onFocus={this.handlePageAppDockComposerFocus}
            >
                <PageContentRelativeContainer>
                    <AppDockLayer
                        ref={(el) => { this.appDockRef = el; }}
                        isAppDockActive={this.props.isAppDockActive}
                    >
                        {this.props.appDock}
                    </AppDockLayer>

                    <HeaderNavLayer ref={(el) => { this.headerNavRef = el; }}>
                        {this.props.headerNav}
                    </HeaderNavLayer>

                    {this.props.search}

                    <ChildrenLayer aria-busy={this.props.isLoading}>
                        {this.props.children}
                    </ChildrenLayer>

                    {!!this.props.isLoading && (<StyledIC103Loading size="10vh" id={this.props.loadingSpinnerId} />)}

                </PageContentRelativeContainer>
            </PageAppDockComposer>
        );
    }
}
