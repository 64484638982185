import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {A, Button} from 'normalized-styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';

import {buttonType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {akela, blanc} from '../../../../common/palette';

import GA43ArrowInd from '../../ga/43-arrow-ind';

const IconWrapper = styled.span`
    flex-shrink: 0;
    transition: ${transition('color')};
    width: 34px;
    height: 100%;
    color: ${rgba(blanc, 0.7)};
`;

const ArrowWrapper = styled.span`
    transition: ${transition('color')};
    margin-bottom: -2px;
    color: ${rgba(blanc, 0.7)};
`;

const baseStyle = css`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    transition: ${transition('color')};
    border-left: 2px solid transparent;
    padding: 5px 20px 5px 25px;
    width: 100%;
    min-height: 42px;
    color: ${rgba(akela, 0.7)};
    font: var(--ionic-body-copy-3-light);

    &:focus,
    &:hover,
    &[aria-pressed='true'],
    &[aria-current='true'] {
        outline: 0;
        color: ${akela};

        /* stylelint-disable-next-line declaration-block-semicolon-newline-after, rule-empty-line-before */
        ${IconWrapper},
        ${ArrowWrapper} {
            color: ${blanc};
        }
    }

    &:active,
    &[aria-pressed='true'],
    &[aria-current='true'] {
        transition: ${transition('border-color')};
        border-color: ${blanc};
    }
`;

const StyledButton = styled(Button).attrs(({isPressed}) => ({
    'aria-pressed': isPressed,
}))`
    ${baseStyle}

    appearance: none;
    border: 0;
    background: none;
    text-align: left;
`;

const StyledLink = styled(A).attrs(({isPressed}) => ({
    'aria-current': isPressed,
}))`
    ${baseStyle}

    text-decoration: none;
`;

const LabelWrapper = styled.span`
    flex: 1 1 auto;
    padding: 0 7px;
    overflow-x: hidden;
    white-space: nowrap;
    /* stylelint-disable-next-line color-no-hex */
    mask-image: linear-gradient(to left, transparent 7px, #000 35px);
`;

const BA20NavBtn = React.forwardRef(({
    className,
    href,
    icon,
    isPressed,
    label,
    type = href ? undefined : 'button',

    onClick,

    ...htmlAttributes
}, ref) => {
    const Cta = href ? StyledLink : StyledButton;

    return (
        <Cta
            {...htmlAttributes}
            className={classnames('BA20NavBtn', className)}
            isPressed={isPressed}
            href={href}
            onClick={onClick}
            ref={ref}
            type={type}
        >
            {!!icon && <IconWrapper>{icon}</IconWrapper>}
            <LabelWrapper>{label}</LabelWrapper>
            <ArrowWrapper><GA43ArrowInd /></ArrowWrapper>
        </Cta>
    );
});

BA20NavBtn.propTypes = {
    /** additional CSS classnames to be applied */
    className: classNameType,
    /** hyperlink URL */
    href: propTypes.string,
    /** icon next to text */
    icon: propTypes.node,
    /** aria-pressed attribute describes a toggle button */
    isPressed: propTypes.oneOf([true, false, 'mixed']),
    /** label of the button */
    label: propTypes.string.isRequired,
    /** callback to fire on button click */
    onClick: propTypes.func,
    /** react ref, e.g. as passed in by React.forwardRef */
    ref: propTypes.string,
    /** html button type */
    type: buttonType,
};

BA20NavBtn.displayName = 'BA20NavBtn';

export default BA20NavBtn;
