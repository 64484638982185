import React from 'react';

import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import noop from 'lodash/noop';
import classnames from 'classnames';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {buttonType, classNameType, srcsetOptionsType} from '@fsa-streamotion/custom-prop-types';

import {A, Button} from '../../../../common/normalized-styled-components';
import {transition} from '../../../../common/animations';
import {flash, white} from '../../../../common/palette';
import {SCREEN_320_MOBILE, SCREEN_768_TABLET, SCREEN_1280_DESKTOP, SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import SrcsetImg from '../../../../common/srcset-img';

const TILE_IMAGE_PX_MAP = {
    [SCREEN_1920_DESKTOP]: 158, // NOTE: This size might be revisited later - temp disabled as it's too big on desktop browser
    [SCREEN_1280_DESKTOP]: 84,
    [SCREEN_768_TABLET]: 80,
    [SCREEN_320_MOBILE]: 74,
};

const TileImage = styled(SrcsetImg).attrs(() => ({
    sizesDefinition: TILE_IMAGE_PX_MAP,
}))`
    display: block;
    transition: ${transition('opacity')};

    width: ${TILE_IMAGE_PX_MAP[SCREEN_320_MOBILE]}px;
    height: ${TILE_IMAGE_PX_MAP[SCREEN_320_MOBILE]}px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: ${TILE_IMAGE_PX_MAP[SCREEN_768_TABLET]}px;
        height: ${TILE_IMAGE_PX_MAP[SCREEN_768_TABLET]}px;
    `}
    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        width: ${TILE_IMAGE_PX_MAP[SCREEN_1280_DESKTOP]}px;
        height: ${TILE_IMAGE_PX_MAP[SCREEN_1280_DESKTOP]}px;
    `}
`;

const FocusedTileImage = styled(TileImage)`
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
`;

const baseStyles = css`
    display: block;
    position: relative;

    border: 0;
    background: none;
    padding: 0;

    ::after {
        display: block;
        transition: ${transition('background-color')};
        margin: 7px auto 0;
        background-color: transparent;
        width: calc(100% - 14px); /* 7px margins on left and right */
        height: 2px;
        content: '';
        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            height: 4px;
        `}
    }

    /* stylelint-disable order/order */

    &:focus,
    &:hover {
        &::after {
            background-color: ${flash};
        }
    }

    /* stylelint-enable order/order */
`;

const NonInteractive = styled.div`
    ${baseStyles}

    cursor: not-allowed;

    ${TileImage} {
        opacity: 0.5;
    }
`;

const interactiveStyles = css`
    &:active,
    &:focus,
    &[aria-pressed='true'],
    &[aria-current='true'] {
        ${TileImage} {
            opacity: 0;
        }

        ${FocusedTileImage} {
            opacity: 1;
        }

        ::after {
            background-color: ${white};
        }

        /* stylelint-disable order/order */

        &:hover ::after {
            background-color: ${flash};
        }

        /* stylelint-enable order/order */
    }
`;

const StyledButton = styled(Button).attrs(({isPressed}) => ({
    'aria-pressed': isPressed,
}))`
    ${baseStyles}
    ${interactiveStyles}

    appearance: none;
`;

const StyledLink = styled(A).attrs(({isPressed}) => ({
    'aria-current': isPressed,
}))`
    ${baseStyles}
    ${interactiveStyles}

    text-decoration: none;
`;

const BA04ChnlTil = React.forwardRef(({
    disabled,
    tileImageSrcset,
    focusedTileImageSrcset,
    href,
    isPressed,
    ariaLabel,
    type = 'button',
    onClick,
    className,
    ...htmlAttributes
}, ref) => {
    const isNonInteractive = disabled || (!href && (!onClick || onClick === noop));
    const StyledElement = getCtaType({isNonInteractive, href, onClick});

    return (
        <StyledElement
            {...htmlAttributes}
            ref={ref}
            isPressed={isPressed}
            aria-label={ariaLabel}
            // We only want to put a type for buttons
            href={isNonInteractive ? undefined : href}
            onClick={isNonInteractive ? undefined : onClick}
            className={classnames('BA04ChnlTil', className)}
            type={href || isNonInteractive ? undefined : type}
        >
            <TileImage srcsetOptions={tileImageSrcset} alt="" />
            <FocusedTileImage srcsetOptions={focusedTileImageSrcset} alt="" />
        </StyledElement>
    );
});

BA04ChnlTil.propTypes = {
    /** Disable the button */
    disabled: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    /** The button image */
    tileImageSrcset: srcsetOptionsType,
    /** Image behind the button when focused*/
    focusedTileImageSrcset: srcsetOptionsType,
    /** If it’s actually a link, provide a href */
    href: propTypes.string,
    /** Whether the button is currently selected */
    isPressed: propTypes.bool,
    /** The HTML type of the button `[undefined, null, 'submit', 'reset', 'button']` */
    type: buttonType,
    /** Function to execute when button is clicked */
    onClick: propTypes.func,
    /** Let screen readers know the content of the button */
    ariaLabel: propTypes.string.isRequired,
    /** Additional CSS class(es) */
    className: classNameType,
};

function getCtaType({isNonInteractive, href, onClick}) {
    if (href && !isNonInteractive) {
        return StyledLink;
    } else if (onClick && !isNonInteractive) {
        return StyledButton;
    }

    return NonInteractive;
}

BA04ChnlTil.displayName = 'BA04ChnlTil';

export default BA04ChnlTil;
