import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import styled from 'styled-components';

import {stylesWhen, mediaQuery, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {classNameType, srcsetOptionsType} from '@fsa-streamotion/custom-prop-types';

import property from 'lodash/property';
import {A} from '../../../common/normalized-styled-components';
import {cloudy, indigo, ink, white} from '../../../common/palette';
import {SCREEN_768_TABLET, SCREEN_1280_DESKTOP, SCREEN_1680_DESKTOP, SCREEN_1920_DESKTOP} from '../../../common/screen-sizes';

import GA05BgImg from '../../atoms/ga/05-bg-img';
import IC24ArrowL from '../../atoms/ic/24-arrow-l';
import IC130Alert from '../../atoms/ic/130-alert';
import OR56Modal from '../../organisms/or/56-modal';
import OR157CheckoutFooter from '../../organisms/or/157-checkout-footer';
import {subtleFadeAnimation, transition} from '../../../common/animations';
import LoadingOverlay from '../../../common/loading-overlay';
import BA01BtnPr from '../../atoms/ba/01-btn-pr';
import XXSkeletonCard from '../../atoms/xx/skeleton-card';

const TOP_OF_CONTENT = 1;
const PAGE_CONTENT_Z_INDEX = 0;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${cloudy};
    height: 100%;
    min-height: 100vh;
`;

const StyledGA05BgImg = styled(GA05BgImg)`
    position: fixed;
    top: 0;
    left: 0;
`;

const Page = styled.div`
    box-sizing: border-box;
    position: relative;
    z-index: ${PAGE_CONTENT_Z_INDEX};
    margin: 0 auto;
    padding: 70px 0 21px;
    width: 100%;
    color: ${ink};
    font: var(--quicksilver-body-copy-4);

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        max-width: 580px;
    `}

    ${stylesWhen('hideSpaceForHeaderNav')`
        padding-top: 0;
    `}
`;

// Styling to allow for the banner content to go 100% width on mobile
const PageContentWrapper = styled.div`
    padding-right: 21px;
    padding-left: 21px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        padding-right: 0;
        padding-left: 0;
    `}
`;

/** ************************************************
 *                 HEADING BLOCK
 ***************************************************/

// if RHS grows, change this (so the heading grid area stays in the middle) at the moment it's decided to just be cart
const HEADING_SIDES_WIDTH_PX = 44;

const HeadingWrapper = styled.div`
    display: ${({display}) => display};
    align-items: center;
    padding-top: 28px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding-top: 35px;
    `}

    ${stylesWhen(({display}) => display === 'flex')`
        justify-content: space-between;

        ${stylesWhenNot('hasBackButton')`
            justify-content: flex-end;
        `}
    `}

    /** Unofficially called OR162SalesNavHeaderSec */
    ${stylesWhen('isSticky')`
        position: sticky;
        top: 0;
        left: 0;
        background-color: ${white};
        padding: 14px;
        z-index: ${TOP_OF_CONTENT};
    `}
`;

// Back button
const StyledLink = styled(A)`
    box-sizing: border-box;
    display: inline-block;
    flex: 0 0 ${HEADING_SIDES_WIDTH_PX}px;
    padding: 5px 24px 5px 0; /* design is left-aligned arrow, but keep the good clickable size */
    height: 100%;
    text-decoration: none;
    color: ${ink};

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        padding-right: 20px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding-right: 12px;
    `}

    &:focus,
    &:hover {
        outline: 0;
        color: ${indigo};
    }
`;

// Title
const Heading = styled.h1`
    flex: 1;
    margin: 0;
    padding: 0 14px;
    text-align: center;
    text-transform: capitalize;
    color: ${ink};
    font: var(--quicksilver-header-5-medium);

    ${stylesWhen('isStickyHeader')`
        font: var(--quicksilver-nav-copy-selected);
    `}

    ${stylesWhenNot('hasBackButton')`
        margin-left: ${HEADING_SIDES_WIDTH_PX}px;
    `}

    ${stylesWhenNot('hasCtaBlock')`
        margin-right: ${HEADING_SIDES_WIDTH_PX}px;
    `}
`;

// RHS CTA block

const HeadingCtaWrapper = styled.div`
    box-sizing: border-box;
    flex: 0 0 ${HEADING_SIDES_WIDTH_PX}px;
    padding: 1px 10px;

    ${mediaQuery({minWidthPx: SCREEN_1680_DESKTOP})`
        padding: 0 7px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding-right: 6px;
        padding-left: 6px;
    `}
`;

/** ************************************************
 *                 CONTENT
 ***************************************************/

// -- Children wrapper
const ChildrenLayer = styled.div`
    transition: ${transition('opacity')};

    &[aria-busy='true'] {
        pointer-events: none;
    }
`;

// -- Error content
const StyledOR56Modal = styled(OR56Modal)`
    text-align: center;
    color: ${ink};
`;

const ErrorMessageContainer = styled.div`
    padding: 0 28px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding: 0 138px;
    `}
`;

const ErrorTitleContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 21px;
    gap: 21px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding: 0 94px;
        gap: 35px;
    `}
`;

const ErrorIconContainer = styled.div`
    width: 42px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 67px;
    `}
`;

const ErrorTitle = ({children}) => (
    <ErrorTitleContainer>
        <ErrorIconContainer>
            <IC130Alert color={cloudy} secondaryColor={ink} />
        </ErrorIconContainer>
        {children}
    </ErrorTitleContainer>
);

ErrorTitle.displayName = 'ErrorTitle';

ErrorTitle.propTypes = {
    children: propTypes.node,
};

/** ************************************************
 *                 FOOTER
 ***************************************************/

const StyledOR157CheckoutFooter = styled(OR157CheckoutFooter)`
    margin-top: auto;
`;

const FooterContent = styled.div`
    padding-right: 21px;
    padding-left: 21px;

    ${stylesWhen('hasMultipleCTAs')`
        padding-right: 0;
        padding-left: 0;
    `}

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        margin: auto;
        max-width: 682px;
        padding-right: 0;
        padding-left: 0;
    `}
`;

/** ************************************************
 *                 SKELETON LOADER
 ***************************************************/

const SkeletonTitleContainer = styled.div`
    display: flex;
    grid-column-start: heading;
    align-items: center;
    justify-content: center;
    animation: ease-in-out ${subtleFadeAnimation} 1s infinite alternate;
`;

const StaticSkeletonCard = styled.div`
    border-radius: ${({rounded}) => (rounded ? '6px' : '0')};
    background-color: ${cloudy};
    width: ${property('width')};
    height: ${property('height')};
`;

const FooterSkeletonContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 14px;
    align-items: center;
    justify-content: center;
`;

const StyledSkeletonButton = styled(BA01BtnPr)`
    min-width: 240px;
    animation: ease-in-out ${subtleFadeAnimation} 1s infinite alternate;

    &:disabled {
        opacity: 1;
    }
`;

const skeletonHeader = (
    <SkeletonTitleContainer>
        <StaticSkeletonCard height="18px" width="240px" />
    </SkeletonTitleContainer>
);

const skeletonChildren = (
    <React.Fragment>
        <XXSkeletonCard height="30px" marginTopPx={100} />
        <XXSkeletonCard height="400px" marginTopPx={50} isRounded={true} />
    </React.Fragment>
);

const skeletonFooter = (
    <FooterSkeletonContainer>
        <StyledSkeletonButton disabled={true} />
    </FooterSkeletonContainer>
);
/** ************************************************
 *                 MAIN
 ***************************************************/

/**
 * Formerly called MyAccountTemplate
 * but since we use it in checkout & offer page now,
 * we have renamed it to something more generic
 */
const PageWrapper = ({
    className,
    isApp,
    // bg
    defaultImageSrc,
    mobilePhabletSrcsetOptions,
    srcsetOptions,
    // content above header
    banner,
    // header
    backUrl,
    headingCtaBlock,
    isStickyHeader = false,
    onBackClick,
    title,
    // content
    children,
    isPageTemplateLoading,
    isContentLoading,
    // error modal
    errorMessage = '',
    errorTitle = '',
    onCloseErrorModal,
    // footer
    footer,
    footerBackgroundColor = indigo,
    hasMultipleCTAs,
}) => {
    const headingWrapper = !!(onBackClick || backUrl || title || headingCtaBlock) && (
        <HeadingWrapper
            // Safari seems doesn't like grid in sticky position. Change it to flex
            display={isPageTemplateLoading ? 'block' : 'flex'}
            isSticky={isStickyHeader}
            hasBackButton={!!(onBackClick || backUrl)}
            hasCtaBlock={!!headingCtaBlock}
            hasTitle={!!title}
        >
            {isPageTemplateLoading ? skeletonHeader : (
                <React.Fragment>
                    {!!(onBackClick || backUrl) && (
                        <StyledLink aria-label="Go back" href={backUrl} onClick={onBackClick}>
                            <IC24ArrowL />
                        </StyledLink>
                    )}
                    {!!title && (
                        <Heading
                            isStickyHeader={isStickyHeader}
                            hasBackButton={!!(onBackClick || backUrl)}
                            hasCtaBlock={!!headingCtaBlock}
                        >
                            {title}
                        </Heading>
                    )}
                    {!!headingCtaBlock && (
                        <HeadingCtaWrapper>
                            {headingCtaBlock}
                        </HeadingCtaWrapper>
                    )}
                </React.Fragment>
            )}
        </HeadingWrapper>
    );

    return (
        <Wrapper className={classnames('PageWrapper', className)} hasFooter={!!footer}>
            {!!(srcsetOptions || defaultImageSrc) && (
                <StyledGA05BgImg
                    srcsetOptions={srcsetOptions}
                    defaultImageSrc={defaultImageSrc}
                    mobilePhabletSrcsetOptions={mobilePhabletSrcsetOptions}
                />
            )}
            {isStickyHeader && headingWrapper}
            <Page hideSpaceForHeaderNav={isApp || isStickyHeader}>
                {banner}
                <PageContentWrapper>
                    {!isStickyHeader && headingWrapper}
                    <ChildrenLayer aria-busy={isContentLoading}>
                        {isPageTemplateLoading ? skeletonChildren : children}
                    </ChildrenLayer>

                    {!!isContentLoading && (
                        <LoadingOverlay />
                    )}
                </PageContentWrapper>
            </Page>

            {!!footer && (
                <StyledOR157CheckoutFooter backgroundColor={footerBackgroundColor}>
                    {isPageTemplateLoading ? skeletonFooter : (
                        <FooterContent hasMultipleCTAs={hasMultipleCTAs}>
                            {footer}
                        </FooterContent>
                    )}
                </StyledOR157CheckoutFooter>
            )}

            {!!errorMessage && (
                <StyledOR56Modal
                    canBeDeactivated={true}
                    header={<ErrorTitle>{errorTitle}</ErrorTitle>}
                    isVisible={!!errorMessage}
                    onCloseClick={onCloseErrorModal}
                >
                    <ErrorMessageContainer>
                        {errorMessage}
                    </ErrorMessageContainer>
                </StyledOR56Modal>
            )}
        </Wrapper>
    );
};

PageWrapper.propTypes = {
    /** URL to visit on back click */
    backUrl: propTypes.string,
    /** Banner that seemingly goes above the template itself */
    banner: propTypes.node,
    /** children to display with page */
    children: propTypes.node,
    /** Additional class(es) */
    className: classNameType,
    /** Image to use for browsers that don't support `<picture>`. Defaults to largest `srcSetOption` screen size */
    defaultImageSrc: propTypes.string,
    /** Generic error message that replaces all content */
    errorMessage: propTypes.node,
    /** Generic error title that replaces all content */
    errorTitle: propTypes.node,
    /** CTA(s) for the heading block, eg. cart */
    headingCtaBlock: propTypes.node,
    /** Padding is reserved for the HeaderNav by default. If viewed within an app, we remove that padding */
    isApp: propTypes.bool,
    /** Is the page loading for the first time - if true, show skeleton content */
    isPageTemplateLoading: propTypes.bool,
    /** Is the page loading - if true, show loading spinner as content */
    isContentLoading: propTypes.bool,
    /** SrcsetOptions object to use for mobile and phablet viewports */
    mobilePhabletSrcsetOptions: srcsetOptionsType,
    /** What to do on back click */
    onBackClick: propTypes.func,
    /** Callback for when the user closes the modal */
    onCloseErrorModal: propTypes.func,
    /** SrcsetOptions object to use for everything other than mobile and phablet viewports */
    srcsetOptions: srcsetOptionsType,
    /** Title of the page */
    title: propTypes.string,
    /** When true, the heading should be sticky and removes reserved HeaderNav padding */
    isStickyHeader: propTypes.bool,
    /** Footer to display at the bottom of the page */
    footer: propTypes.node,
    /** Background color of the footer */
    footerBackgroundColor: propTypes.string,
    /** If the footer has multiple CTAs */
    hasMultipleCTAs: propTypes.bool,
};

PageWrapper.displayName = 'PageWrapper';

export default PageWrapper;
