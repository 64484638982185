import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';

import {mediaQuery, stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType, srcsetOptionsType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {black, fog, blueCharcoal} from '../../../../common/palette';
import {SCREEN_320_MOBILE, SCREEN_1280_DESKTOP, SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import SrcsetImg from '../../../../common/srcset-img';

const LOGO_IMAGE_PX_MAP = {
    [SCREEN_320_MOBILE]: 156,
    [SCREEN_1280_DESKTOP]: 202,
    [SCREEN_1920_DESKTOP]: 282,
};

const BannerContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: ${transition('background-color', 'opacity')};
    background-color: transparent;
    background-image: linear-gradient(to top, ${rgba(blueCharcoal, 0.5)} 2%, ${rgba(blueCharcoal, 0.15)} 99%); /* to top version of OR33HeaderDsk default state */
    padding-top: 24px;
    width: 100%;
    height: 65px;

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        height: 85px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        height: 100px;
    `}

    ${stylesWhen('isScrolled')`
        background-color: ${rgba(blueCharcoal, 0.8)};
    `}
`;

const LogoImg = styled(SrcsetImg)`
    padding: 0 35px;
    width: ${LOGO_IMAGE_PX_MAP[SCREEN_320_MOBILE]}px;

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        padding: 0 55px;
        width: ${LOGO_IMAGE_PX_MAP[SCREEN_1280_DESKTOP]}px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: ${LOGO_IMAGE_PX_MAP[SCREEN_1920_DESKTOP]}px;
    `}
`;

const Title = styled.h2`
    box-sizing: border-box;
    padding: 0 35px;
    width: 100%;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
    color: ${fog};
    font: var(--infinity-header-8-bold);
    mask-image: linear-gradient(to left, transparent, ${black} 10%);

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        padding: 0 35px;
    `}
`;

const OR18FlshPntHead = ({
    className,
    isScrolled,
    logoAltText = 'flash point',
    logoImageSrcsetOptions,
    title = '',
    ...htmlAttributes
}) => (
    <BannerContainer
        className={classnames('OR18FlshPntHead', className)}
        isScrolled={isScrolled}
        {...htmlAttributes}
    >
        <LogoImg
            sizesDefinition={LOGO_IMAGE_PX_MAP}
            srcsetOptions={logoImageSrcsetOptions}
            alt={logoAltText}
        />
        <Title>
            {title}
        </Title>
    </BannerContainer>
);

OR18FlshPntHead.propTypes = {
    /** Additional CSS classnames to be applied */
    className: classNameType,
    /** Alt text for logo */
    logoAltText: propTypes.string,
    /** Logo image srcset */
    logoImageSrcsetOptions: srcsetOptionsType,
    /** Flashpoint page title */
    title: propTypes.string,
    /** Did user scroll down (moved from position Y:0)? This changes background styling */
    isScrolled: propTypes.bool,
};

OR18FlshPntHead.displayName = 'OR18FlshPntHead';

export default OR18FlshPntHead;
