import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';

import formatCurrency from '../../../../common/format-currency';
import {blanc} from '../../../../common/palette';
import BA22EditBtn from '../../../atoms/ba/22-edit-btn';

const GiftCardInfoBox = styled.div`
    grid-template-columns: 1fr 24px;
    border-radius: 6px;
    background-color: ${rgba(blanc, 0.1)};
    padding: 14px 21px;
    color: ${blanc};
    font: var(--ionic-body-copy-4);
`;

const RemoveCardWrapper = styled.div`
    padding-top: 7px;
    text-align: right;
`;

const HighlightedInfo = styled.p`
    margin: 0;
    font: var(--ionic-header-6);
`;

const StyledP = styled.p`
    margin: 14px 0;

    &:last-child {
        margin-bottom: 0;
    }
`;

const Validated = ({
    cardValidatedBalance,
    cardValidatedParagraphs,
    currencyDecimalPlaces,
    onClickRemoveGiftCode,
}) => (
    <div>
        <GiftCardInfoBox>
            <div>
                {cardValidatedBalance > 0 && (
                    <HighlightedInfo>
                        {formatCurrency({
                            amount: cardValidatedBalance,
                            currencyDecimalPlaces,
                        })}
                        {' '}
                        Gift Card accepted
                    </HighlightedInfo>
                )}
                {cardValidatedParagraphs.map((content, index) => (
                    <StyledP key={index}>
                        {content}
                    </StyledP>
                ))}
            </div>
        </GiftCardInfoBox>
        <RemoveCardWrapper>
            <BA22EditBtn onClick={onClickRemoveGiftCode}>
                Remove Card
            </BA22EditBtn>
        </RemoveCardWrapper>
    </div>
);

Validated.propTypes = {
    cardValidatedBalance: propTypes.number,
    currencyDecimalPlaces: propTypes.number,
    cardValidatedParagraphs: propTypes.arrayOf(propTypes.node),
    onClickRemoveGiftCode: propTypes.func,
};

Validated.displayName = 'Validated';

export default Validated;
