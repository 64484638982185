import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {slate, white} from '../../../../../common/palette';
import {TRACK_HEIGHT_PX} from './constants';

const ProgressBar = styled.progress`
    appearance: none;
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: none;
    border: none;
    border-radius: 0;
    background-color: ${slate};
    padding: 0;
    height: ${TRACK_HEIGHT_PX}px;

    color: ${({theme, isDuration}) => isDuration ? (theme.primaryAccent || white) : white};

    &::-webkit-progress-bar {
        background-color: inherit;
    }

    &::-webkit-progress-value {
        transition: none;
        border-radius: 0;
        background: currentColor;
        min-width: 8px;
        max-width: 100%;
    }

    &::-moz-progress-bar {
        transition: none;
        border-radius: 0;
        background: currentColor;
        min-width: 8px;
        max-width: 100%;
    }

    &::-ms-fill {
        display: none;
    }
`;

const SliderControlProgressBar = ({
    start = 0,
    current = 0,
    end = 0,
    isDuration,
}) => (
    <ProgressBar
        aria-hidden="true"
        max={100}
        style={{
            left: `${start}%`,
            width: `${end - start}%`,
        }}
        value={((current / end) * 100) || 0}
        isDuration={isDuration}
    />
);

SliderControlProgressBar.displayName = 'SliderControlProgressBar';

SliderControlProgressBar.propTypes = {
    current: propTypes.number,
    end: propTypes.number,
    start: propTypes.number,
    isDuration: propTypes.bool,
};

export default SliderControlProgressBar;
