import React from 'react';

import Ic from '..';

const IC03Not = (args) => (
    <Ic {...args}>
        <g fillOpacity=".15" stroke="currentColor" strokeWidth="3">
            <path fillRule="nonzero" d="M50.5 8.5c.226 0 .43.097.578.247a.875.875 0 0 1 .252.616V16.2c6.382.23 12.106 3.124 16.128 7.61a23.35 23.35 0 0 1 5.966 15.63v18.437c0 .58.247 1.162.641 1.562l5.838 5.919a3.962 3.962 0 0 1 1.141 2.798v1.807A3.983 3.983 0 0 1 79.9 72.77a3.835 3.835 0 0 1-2.738 1.153H23.839a3.835 3.835 0 0 1-2.738-1.153 3.983 3.983 0 0 1-1.145-2.806v-1.807c0-1.055.404-2.052 1.14-2.798l5.839-5.92c.394-.399.641-.98.641-1.56V39.441a23.35 23.35 0 0 1 5.966-15.63c4.022-4.487 9.746-7.38 16.128-7.611V9.363c0-.24.096-.458.252-.616A.812.812 0 0 1 50.5 8.5z" />
            <path d="M38.958 74.141c2.848.007 7.375.016 11.902.021l1.086.001.865.001 1.284.001h1.673l2.743.001h1.421l.977-.002 1.894-.005.14-.001-.023.283c-.245 2.555-1.396 4.855-3.079 6.642l-.222.23a12.069 12.069 0 0 1-8.594 3.588 12.07 12.07 0 0 1-8.593-3.588 12.267 12.267 0 0 1-3.438-6.894l-.036-.278z" />
        </g>
    </Ic>
);

IC03Not.displayName = 'IC03Not';

export default IC03Not;
