import React from 'react';

import Ic from '..';

const IC339RemoveAsset = (args) => (
    <Ic {...args}>
        <path d="M50 16.393c18.56 0 33.607 15.047 33.607 33.607S68.56 83.607 50 83.607 16.393 68.56 16.393 50 31.44 16.393 50 16.393M50 9C27.356 9 9 27.356 9 50s18.356 41 41 41 41-18.356 41-41A41 41 0 0 0 50 9z" fillRule="nonzero" />
        <path d="m26.22 46.72 50.017.026.003 7.354-50.017-.026z" fillRule="nonzero" />
    </Ic>
);

IC339RemoveAsset.displayName = 'IC339RemoveAsset';

export default IC339RemoveAsset;
