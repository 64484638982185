import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {A, Button} from 'normalized-styled-components';
import {rgba} from 'polished';
import noop from 'lodash/noop';
import classnames from 'classnames';

import {mediaQuery, stylesIfElse} from '@fsa-streamotion/styled-component-helpers';
import {buttonType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {white, midnight} from '../../../../common/palette';
import {SCREEN_LG_DESKTOP, SCREEN_PRETTY_LG_DESKTOP} from '../../../../common/screen-sizes';

const baseStyles = css`
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    transition: ${transition('background-color', 'color', 'border-color')};
    border: 1px solid ${rgba(white, 0.7)};
    background-color: transparent;
    padding: 0 21px;
    height: 40px;
    overflow: hidden;
    white-space: nowrap;
    color: ${rgba(white, 0.7)};
    font: var(--mui-body-copy-3-light);

    ${stylesIfElse('isBlock')`
        display: flex;
        width: 100%;
    ``
        display: inline-flex;
        min-width: 110px;

        ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
            padding: 0 28px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_PRETTY_LG_DESKTOP})`
            min-width: 183px;
        `}
    `}

    ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
        height: 50px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_PRETTY_LG_DESKTOP})`
        height: 60px;
    `}
`;

const interactiveStyles = css`
    &:hover,
    &:focus {
        outline: 0;
        border-color: ${white};
        color: ${white};
    }

    &:active,
    &[aria-pressed='true'],
    &[aria-current='true'] {
        background-color: ${white};
        color: ${midnight};
        font: var(--mui-body-copy-3);
    }
`;

const StyledButton = styled(Button).attrs(({isPressed}) => ({
    'aria-pressed': isPressed,
}))`
    ${baseStyles}
    ${interactiveStyles}

    appearance: none;
`;

const StyledLink = styled(A).attrs(({isPressed}) => ({
    'aria-current': isPressed,
}))`
    ${baseStyles}
    ${interactiveStyles}

    text-decoration: none;
`;

const NonInteractive = styled.div`
    ${baseStyles}

    border-color: ${rgba(white, 0.2)};
    cursor: not-allowed;
    color: ${rgba(white, 0.3)};
`;

const BA45BtnSecSml = React.forwardRef(({
    children,
    disabled,
    href,
    isBlock,
    isPressed,
    type = 'button',
    className,
    onClick,
    ...htmlAttributes
}, ref) => {
    const isNonInteractive = disabled || (!href && (!onClick || onClick === noop));
    const StyledElement = getCtaType({isNonInteractive, href, onClick});

    return (
        <StyledElement
            {...htmlAttributes}
            {...{
                ref,
                isBlock,
                isPressed,
            }}
            className={classnames('BA45BtnSecSml', className)}
            href={isNonInteractive ? undefined : href}
            onClick={isNonInteractive ? undefined : onClick}
            type={href || isNonInteractive ? undefined : type}
        >
            {children}
        </StyledElement>
    );
});

BA45BtnSecSml.propTypes = {
    /** Content of button, e.g. text */
    children: propTypes.node.isRequired,
    // eslint-disable-next-line react/boolean-prop-naming
    disabled: propTypes.bool,
    /* If it’s actually a link, provide a href */
    href: propTypes.string,
    /** Whether the button fills its parent's width or not */
    isBlock: propTypes.bool,
    /** Whether the button is currently selected */
    isPressed: propTypes.bool,
    /** The HTML type of the button */
    type: buttonType,
    /** Function to execute when button is clicked */
    onClick: propTypes.func,
    /** additional CSS classnames to be applied */
    className: classNameType,
};

function getCtaType({isNonInteractive, href, onClick}) {
    if (href && !isNonInteractive) {
        return StyledLink;
    } else if (onClick && !isNonInteractive) {
        return StyledButton;
    }

    return NonInteractive;
}

BA45BtnSecSml.displayName = 'BA45BtnSecSml';

export default BA45BtnSecSml;
