import React, {createRef, useEffect} from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import styled, {css} from 'styled-components';
import {CSSTransition} from 'react-transition-group';
import get from 'lodash/get';
import noop from 'lodash/noop';
import {Section} from 'normalized-styled-components';

import {mediaQuery, stylesWhen, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {blanc} from '../../../../common/palette';
import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import {transition, TRANSITION_CLASSNAMES, TRANSITION_DURATION_S} from '../../../../common/animations';

import BA01BtnPr from '../../../atoms/ba/01-btn-pr';
import BA08BtnSec from '../../../atoms/ba/08-btn-sec';
import IC42Error from '../../../atoms/ic/42-error';
import IM01Input from '../../../molecules/im/01-input';

const commonWidthStyles = css`
    margin: 0 auto;
    width: 250px;
    max-width: 100%;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 400px;
    `}
`;

const StyledForm = styled.form`
    ${commonWidthStyles}

    transform-origin: 50% 0;
    opacity: 0;
    height: 0;
    pointer-events: none;

    &.${TRANSITION_CLASSNAMES.enterActive},
    &.${TRANSITION_CLASSNAMES.exit} {
        transition: ${transition('opacity', 'transform')};
    }

    &.${TRANSITION_CLASSNAMES.enter},
    &.${TRANSITION_CLASSNAMES.exitActive} {
        transform: scale(0.95);
    }

    /* stylelint-disable-next-line no-duplicate-selectors */
    &.${TRANSITION_CLASSNAMES.appear},
    &.${TRANSITION_CLASSNAMES.appearActive},
    &.${TRANSITION_CLASSNAMES.enterActive},
    &.${TRANSITION_CLASSNAMES.enterDone} {
        transform: unset;
        opacity: unset;
        pointer-events: unset;
    }

    /* stylelint-disable-next-line no-duplicate-selectors */
    &.${TRANSITION_CLASSNAMES.appear},
    &.${TRANSITION_CLASSNAMES.appearActive},
    &.${TRANSITION_CLASSNAMES.enter},
    &.${TRANSITION_CLASSNAMES.enterActive},
    &.${TRANSITION_CLASSNAMES.enterDone},
    &.${TRANSITION_CLASSNAMES.exitActive} {
        height: unset;
    }
`;

const StyledBA01BtnPr = styled(BA01BtnPr).attrs({
    type: 'submit',
})`
    margin-top: 7px;

    ${stylesWhen('isRelative')`
        position: relative;
    `}
`;

const StyledLabel = styled(BA08BtnSec)`
    ${commonWidthStyles}

    transition: ${transition('border-color')};

    ${stylesWhenNot('hasBorder')`
        border-color: transparent;

        &:hover {
            border-color: transparent;
        }
    `}
`;

const handleSubmit = (inputName, onSubmit) => (event) => {
    event.preventDefault();
    onSubmit(get(event, ['target', 'elements', inputName, 'value']));
};

const DisplayHeader = styled.h2`
    margin: 0;
    text-align: center;
    color: ${blanc};
    font: var(--nucleus-header-3);
`;

const DisplaySubtext = styled.p`
    margin: 4px 0 14px;
    text-align: center;
    color: ${blanc};
    font: var(--nucleus-body-copy-2);
`;

const OR12VchInp = React.forwardRef(({
    className,
    headingText = 'Got a voucher?',
    isExpanded,
    onExpand = noop,
    hasExpandButton = true,
    label = 'Enter your voucher code',
    inputId,
    inputName,
    voucherCode,
    onChangeVoucherCode,
    errorIcon = <IC42Error color={blanc} />,
    errorMessage,
    submitText = 'Enter',
    isSubmitDisabled,
    onSubmit = noop,
    displaySubtext,
    displayHeader,
    ...htmlAttributes
}, ref) => {
    const voucherInputRef = ref || createRef();

    useEffect(() => {
        if (isExpanded) {
            voucherInputRef.current.focus();
        }
    }, [isExpanded, errorMessage]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Section className={classnames('OR12VchInp', className)}>
            {!!displayHeader && (
                <DisplayHeader>{displayHeader}</DisplayHeader>
            )}
            {!!displaySubtext && (
                <DisplaySubtext>{displaySubtext}</DisplaySubtext>
            )}
            {!!hasExpandButton && (
                <StyledLabel
                    hasBorder={!isExpanded}
                    aria-label="Open voucher form"
                    htmlFor={inputId}
                    isBlock={true}
                    onClick={onExpand}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                            // space as PageDown happens on keypress
                            e.preventDefault();
                        }
                    }}
                    onKeyUp={({key}) => {
                        if (key === 'Enter' || key === ' ') {
                            // only expand when on keyup so it doesn’t keep trying if you hold the key down
                            onExpand();
                        }
                    }}
                    tabIndex="0"
                >
                    {headingText}
                </StyledLabel>
            )}
            <CSSTransition
                in={isExpanded}
                appear={true}
                timeout={TRANSITION_DURATION_S * 1000}
                classNames={TRANSITION_CLASSNAMES}
            >
                <StyledForm
                    aria-live="polite"
                    onSubmit={handleSubmit(inputName, onSubmit)}
                    method="GET"
                >
                    <IM01Input
                        ref={voucherInputRef}
                        id={inputId}
                        name={inputName}
                        tabIndex={isExpanded ? 0 : -1}
                        label={label}
                        icon={errorMessage ? errorIcon : undefined}
                        contextualHelp={errorMessage}
                        disabled={isSubmitDisabled}
                        {...htmlAttributes}
                        defaultValue={voucherCode}
                        onChange={onChangeVoucherCode ? ({target: {value}}) => onChangeVoucherCode(value) : undefined}
                    />

                    <CSSTransition
                        in={isExpanded}
                        unmountOnExit={true}
                        timeout={TRANSITION_DURATION_S * 1000}
                        classNames={{}}
                    >
                        <StyledBA01BtnPr
                            isRelative={!errorMessage}
                            isBlock={true}
                            disabled={isSubmitDisabled}
                        >
                            {submitText}
                        </StyledBA01BtnPr>
                    </CSSTransition>
                </StyledForm>
            </CSSTransition>
        </Section>
    );
});

OR12VchInp.propTypes = {
    /** Additional class name(s) */
    className: classNameType,
    /** A header which appears in all states of voucher validation */
    displayHeader: propTypes.string,
    /** Copy which appears in all states of voucher validation */
    displaySubtext: propTypes.string,
    /** Heading text */
    headingText: propTypes.string,
    /** Do we have a button to expand? */
    hasExpandButton: propTypes.bool,
    /** Is this component expanded, allowing the user to enter a voucher code? */
    isExpanded: propTypes.bool,
    /** Callback indicating a request to expand this voucher input */
    onExpand: propTypes.func,
    /** Voucher text field label */
    label: propTypes.string,
    /** The input field's ID */
    inputId: propTypes.string.isRequired,
    /** The field's input name, used for submitting the form before hydration */
    inputName: propTypes.string,
    /** Contents of the voucher text field */
    voucherCode: propTypes.string,
    /** Callback that is provided with the contents of the voucher text field when the user changes it (required when voucherCode is provided) */
    onChangeVoucherCode: propTypes.func,
    /** Icon that indicates an error */
    errorIcon: propTypes.node,
    /** Error message */
    errorMessage: propTypes.node,
    /** Text to display in the submit button */
    submitText: propTypes.string,
    /** Is the submit button disabled? */
    isSubmitDisabled: propTypes.bool,
    /** Voucher submission callback. (No value provided. Use value from last onChangeVoucherCode.) */
    onSubmit: propTypes.func,
};

OR12VchInp.displayName = 'OR12VchInp';

export default OR12VchInp;
