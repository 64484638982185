/* eslint-disable max-nested-callbacks */
import request from 'superagent';
import {useEffect, useState} from 'react';

const disallowedList = ['granite'];
const useHtml = (url) => {
    const [html, setHTML] = useState(null);

    useEffect(() => {
        request
            .get(url)
            .then((res) => {
                const doc = new DOMParser().parseFromString(res.text, 'text/html');
                const scriptTags = doc.getElementsByTagName('script');

                // Manually creating new script tags, setting the content, and adding it to the DOM so we have full control over when
                // and how script execution happens
                Array.from(scriptTags).forEach((oldScript) => {
                    const newScript = document.createElement('script');

                    Array.from(oldScript.attributes)
                        .filter(({value}) => {
                            const existingScript = document.querySelector(`script[src="${value}"]`);
                            const isDisallowed = disallowedList.some((url) => value.includes(url));

                            return !isDisallowed && !existingScript;
                        })
                        .forEach(({name, value}) => {
                            newScript.setAttribute(name, value);
                        });

                    newScript.innerHTML = oldScript.innerHTML;

                    document.body.appendChild(newScript);
                });

                // Remove script tags from the original HTML ensuring no inline scripts run when inserting HTML in React component
                // even though script tags won't run through dangerouslySetInnerHTML it is still a good practice to sanitise the HTML
                // to prevent potential XSS attacks
                setHTML(res.text.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, ''));
            })
            .catch((error) => console.error(error));
    }, [url]);

    return html;
};

export default useHtml;
