import React from 'react';
import propTypes from 'prop-types';
import {rgba} from 'polished';
import styled, {css} from 'styled-components';
import classnames from 'classnames';
import {stylesWhen, mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {ink, indigo, hubblGreen, white, mist} from '../../../../common/palette';
import {SCREEN_768_TABLET, SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import {A, Button} from '../../../../common/normalized-styled-components';

import {IC31Tick} from '../../../atoms/ic';

const containerBaseStyles = css`
    position: relative;
    transition: ${transition('background-color', 'color')};
    outline: 0;
    border-radius: 4px;
    color: ${ink};
    font: var(--quicksilver-body-copy-3);

    ${stylesWhen('isClickable')`
        color: ${ink};
        background-color: ${rgba(ink, 0.2)};

        &:focus,
        &:hover {
            background-color: ${rgba(ink, 0.8)};
            color: ${indigo};
        }
    `}

    ${stylesWhen('isSelected')`
        background-color: ${({isWithinCardedBlock}) => (isWithinCardedBlock ? rgba(mist, 0.3) : white)};
        color: ${indigo};
    `}
`;

const StyledContainer = styled.div`
    ${containerBaseStyles}
`;

const ContainerButton = styled(Button).attrs(({isSelected}) => ({
    'type': 'button',
    'aria-pressed': isSelected,
}))`
    ${containerBaseStyles}

    appearance: none;
    box-sizing: content-box;
    border: 0;
    padding: 0;
    width: 100%;
    text-align: unset;
`;

const ContainerAnchor = styled(A).attrs(({isSelected}) => ({
    'aria-current': isSelected,
}))`
    ${containerBaseStyles}

    display: block;
    text-decoration: none;
`;

const TickWrapper = styled.span`
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    width: 23px;
    height: 23px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 27px;
        height: 27px;
    `}
`;

const Layout = styled.div`
    box-sizing: border-box;
    display: grid;
    grid-gap: 14px;
    padding: 14px;
    width: 100%;
    min-height: 66px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        grid-gap: 21px;
        padding: 21px;
        min-height: 90px;
    `}

    ${stylesWhen('hasIcon')`
        grid-template-columns: min-content auto;
    `}
`;

const TileIcon = styled.span`
    width: 40px;
    height: 37px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: 48px;
        height: 42px;
    `}
`;

const Rows = styled.div`
    display: grid;
    grid-gap: 3px;
    align-content: start;
`;

function getCtaType({href, onClick}) {
    if (href) {
        return ContainerAnchor;
    } else if (onClick) {
        return ContainerButton;
    }

    return StyledContainer;
}

/**
 * TM19Pmnt
 *  - Used in My Account and Checkout
 *  -  Payment Details
 *
 * @see {@link https://zpl.io/jZeqOrA}
 */
const TM19Pmnt = ({
    children,
    className,
    href,
    icon,
    isSelected,
    isWithinCardedBlock,
    onClick,
    ...htmlAttributes
}) => {
    const StyledElement = getCtaType({href, onClick});

    return (
        <StyledElement
            {...htmlAttributes}
            className={classnames('TM19Pmnt', className)}
            href={href}
            isSelected={isSelected}
            onClick={onClick}
            isClickable={!!(onClick || href)}
            isWithinCardedBlock={isWithinCardedBlock}
        >
            <Layout hasIcon={!!icon}>
                {!!icon && (
                    <TileIcon>{icon}</TileIcon>
                )}

                <Rows>
                    {children}
                </Rows>
            </Layout>

            {!!isSelected && (
                <TickWrapper>
                    <IC31Tick color={hubblGreen} />
                </TickWrapper>
            )}
        </StyledElement>
    );
};

TM19Pmnt.propTypes = {
    /** Content of tile, e.g. payment method details */
    children: propTypes.node,
    /** Additional class name(s) */
    className: classNameType,
    /** If it’s actually a link, provide a href */
    href: propTypes.string,
    /** Is this payment method selected?  */
    isSelected: propTypes.bool,
    /** Toggle to change selected state backaground if used within a carded block */
    isWithinCardedBlock: propTypes.bool,
    /** Payment method icon, e.g. Visa or Mastercard logo */
    icon: propTypes.node,
    /** Callback on element click */
    onClick: propTypes.func,
};

TM19Pmnt.displayName = 'TM19Pmnt';

export default TM19Pmnt;
