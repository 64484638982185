import React from 'react';

import Ic from '..';

const IC111KeyMomMarketing = (args) => (
    <Ic {...args}>
        <path d="M76.3 22.7H23.8c-.4 0-.8.3-.8.8v35.3c0 .4.3.8.8.8h25.7V68c-2.2.4-3.8 2.3-3.8 4.7 0 2.6 2.1 4.8 4.8 4.8 2.6 0 4.8-2.1 4.8-4.8 0-2.3-1.6-4.2-3.8-4.7v-8.4h25.2c.4 0 .8-.3.8-.8V23.5c-.5-.4-.8-.8-1.2-.8zM75.6 58H24.4V24.2h51.1l.1 33.8z" />
        <path d="M36.7 48.3c2.4-.2 3.9-1 5.6-2.6l.4-5.4-5.4.4-.1 2.3 2.6-.2-.2 1.9c-.5.7-1.9.9-2.6 1-1.9.2-3.1-1.1-3.2-2.9-.2-2.5 1.1-5.1 3.7-5.3 1.2-.1 2.3.3 3.1 1.2l2-1.9c-1.4-1.5-2.9-2-4.9-1.9-4.4.3-7.2 4.1-6.9 8.4.3 3.5 2.7 5.3 5.9 5zM48.4 47.4c3.3-.3 5.4-2.9 5.2-6-.2-2.5-2.1-3.7-4.5-3.5-3.2.3-5.4 2.9-5.2 6.1.2 2.4 2.1 3.5 4.5 3.4zm.5-7.3c1.1-.1 1.7.7 1.8 1.7.1 1.4-.6 2.9-2.1 3.1-1.1.1-1.7-.7-1.8-1.7-.1-1.4.7-2.9 2.1-3.1zM58.3 46.5c1.2-.1 2.3-.7 3-1.6v.5c0 .4.1.7.1.7l2.6-.3s-.1-.3-.1-.7v-.7l.6-7.8-2.9.3c0 .3-.1.7-.1 1-.6-.9-1.7-1.1-2.6-1-3.1.3-4.7 3.3-4.5 6.1.4 2.2 1.7 3.7 3.9 3.5zm1.4-7.2c1.1-.1 1.8.6 1.9 1.7.1 1.4-.7 2.9-2.2 3.1-1.1.1-1.8-.6-1.9-1.7-.2-1.5.7-3 2.2-3.1zM66.2 45.7l2.9-.3s-.1-.3-.1-.7V44l.8-10.1V33c0-.4-.1-.6-.1-.6l-2.9.3s.1.3.1.6v.8l-.8 10.1v.8c.1.5.1.7.1.7z" />
    </Ic>
);

IC111KeyMomMarketing.displayName = 'IC111KeyMomMarketing';

export default IC111KeyMomMarketing;
