import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import {lifestyle} from '../../../../common/palette';
import Ic from '..';

const IC50Success = ({color = lifestyle, ...otherProps}) => (
    <Ic {...{...otherProps, color}}>
        <path d="M35.024 82.414L11 55.926l6.216-5.642 17.808 19.628L82.106 18l6.216 5.642z" />
    </Ic>
);

IC50Success.propTypes = {
    color: colorType,
};

IC50Success.displayName = 'IC50Success';

export default IC50Success;
