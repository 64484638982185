import React from 'react';
import Ic from '..';

const IC334OptusSubHubLogo = (args) => (
    <Ic {...args}>
        <path d="M18.864 49.113c-2.93-1.54-5.197-1.947-7.81-1.947-4.517 0-7.724 2.263-7.793 5.843-.07 3.317 2.087 5.028 6.068 5.62l.965.145c2.53.368 3.83.684 3.83 1.79 0 1.145-1.327 1.71-3.07 1.71-2.722 0-4.765-.973-6.246-1.934L2.5 63.92c2.141 1.54 5.294 2.342 8.514 2.342 4.147 0 8.115-1.829 8.115-6.212 0-3.014-2.365-4.685-6.125-5.304l-.802-.131c-2.14-.355-3.98-.632-3.98-1.79 0-1.092 1.286-1.659 3.04-1.659 2.267 0 4.16.764 5.543 1.554l2.06-3.607zM32.986 55.35h-3.654v5.776c0 1.355-.796 1.972-1.885 1.972-1.1 0-1.883-.617-1.883-1.972V55.35H21.9v6.069c0 3.337 2.482 4.843 5.547 4.843 3.057 0 5.539-1.506 5.539-4.833V55.35zM43.525 52.793c-1.679 0-2.825.651-3.61 1.576v-7.203h-4.158v18.758h4.025v-1.368c.785 1.003 1.973 1.706 3.743 1.706 3.492 0 6.09-2.97 6.09-6.747 0-3.752-2.598-6.722-6.09-6.722zm-1 3.804c1.68 0 2.8 1.263 2.8 2.93 0 1.668-1.12 2.932-2.8 2.932-1.637 0-2.782-1.238-2.782-2.931 0-1.694 1.145-2.931 2.782-2.931zM56.803 47.166h-4.417v19.096h4.417v-7.529h7.794v7.53h4.417V47.165h-4.417v7.352h-7.794zM82.871 55.35h-3.65v5.776c0 1.355-.797 1.972-1.886 1.972-1.1 0-1.885-.617-1.885-1.972V55.35h-3.664v6.069c0 3.337 2.482 4.843 5.55 4.843 3.056 0 5.535-1.506 5.535-4.833V55.35zM93.411 52.793c-1.68 0-2.825.651-3.61 1.576v-7.203h-4.158v18.758h4.025v-1.368c.785 1.003 1.97 1.706 3.743 1.706 3.492 0 6.089-2.97 6.089-6.747 0-3.752-2.597-6.722-6.089-6.722zm-1 3.804c1.679 0 2.797 1.263 2.797 2.93 0 1.668-1.118 2.932-2.797 2.932-1.637 0-2.786-1.238-2.786-2.931 0-1.694 1.15-2.931 2.786-2.931z" fillRule="nonzero" />
        <path d="M37.806 38.31c0 1.08-.773 1.595-1.88 1.595-1.105 0-1.828-.515-1.828-1.595v-4.312c0-.19-.038-.284-.267-.347-.178-.052-.48-.094-.877-.094-.398 0-.7.042-.878.094-.229.063-.267.158-.267.347v4.375c0 2.161 1.554 3.336 4.118 3.336 2.575 0 4.14-1.175 4.14-3.336v-4.375c0-.19-.034-.284-.265-.347-.179-.052-.469-.094-.867-.094-.395 0-.684.042-.866.094-.228.063-.263.158-.263.347v4.312zm-11.303 3.357c.398 0 .7-.042.878-.094.232-.063.267-.157.267-.346v-5.781h2.385c.228 0 .31-.074.372-.231.046-.126.096-.357.096-.65 0-.294-.05-.525-.096-.651-.062-.157-.144-.231-.372-.231h-7.056c-.228 0-.313.074-.372.23-.05.127-.096.358-.096.651 0 .294.047.525.096.65.059.158.144.232.372.232h2.385v5.78c0 .19.035.284.263.347.182.052.48.094.878.094zm-21.62-4.05c0 1.354 1.085 2.287 2.6 2.287 1.517 0 2.6-.933 2.6-2.287 0-1.353-1.083-2.287-2.6-2.287-1.515 0-2.6.934-2.6 2.287zm-2.383 0c0-2.36 2.057-4.092 4.984-4.092 2.923 0 4.984 1.732 4.984 4.092 0 2.36-2.06 4.092-4.984 4.092-2.927 0-4.984-1.731-4.984-4.092zm15.563-.073h-1.747v-2.161h1.747c.817 0 1.335.367 1.335 1.08 0 .693-.518 1.081-1.335 1.081zm.035-3.861h-3.766c-.181 0-.301.094-.301.262v7.282c0 .189.035.283.263.346.181.052.483.094.88.094.396 0 .697-.042.879-.094.228-.063.263-.157.263-.346v-2.015h1.806c2.347 0 3.6-1.112 3.6-2.748 0-1.68-1.253-2.781-3.624-2.781zm27.484 8.026c-1.347 0-2.866-.304-4.033-.933-.155-.084-.229-.19-.167-.378.12-.378.456-.923.696-1.133.158-.137.255-.147.46-.053 1.094.504 2.046.777 2.985.777 1.021 0 1.6-.294 1.6-.766 0-.41-.42-.567-1.901-.766l-.472-.063c-1.949-.262-3.043-.965-3.008-2.36.035-1.564 1.53-2.508 3.96-2.508 1.205 0 2.396.21 3.36.63.169.073.24.188.18.377-.12.378-.47.913-.71 1.123-.144.125-.264.115-.457.042a6.23 6.23 0 0 0-2.335-.462c-.975 0-1.543.241-1.543.671 0 .368.41.536 1.94.766l.407.063c2 .304 3.07.976 3.07 2.266 0 1.69-1.67 2.707-4.032 2.707z" />
    </Ic>
);

IC334OptusSubHubLogo.displayName = 'IC334OptusSubHubLogo';

export default IC334OptusSubHubLogo;
