import React from 'react';

import Ic from '..';

const IC287Locked = (args) => (
    <Ic {...args}>
        <path d="M50.044 49.12v.064c9.065 0 18.13-.007 27.194.003 4.779.005 7.893 3 7.921 7.721.045 7.6.027 15.2.008 22.8-.017 6.29-4.725 11.175-11.154 11.213-15.948.096-31.897.097-47.845 0-6.533-.038-11.164-4.905-11.166-11.326-.002-7.491-.002-14.981 0-22.471.005-4.943 3.02-7.921 8.016-7.94 4.532-.018 9.064-.05 13.597-.06 4.475-.012 8.952-.004 13.429-.004zm.066 6.824c-4.546 0-8.232 3.78-8.232 8.445a8.53 8.53 0 002.548 6.109c.337.33.7.63 1.088.897.395.597.846 1.605.822 3.074-.011.665-.017 1.465-.019 2.29v1.724c.005 1.6.016 2.972.019 3.209v.04c-.01.127-.14 2.65 3.132 2.585.97-.019 1.485-.027 1.728-.028h.127c.263.002-.47.026-.572.03h-.012c.154.03 3.118.594 3.206-2.414.027-.925.038-1.964.04-2.966v-.742c-.005-2.028-.04-3.728-.04-3.728s-.177-1.756.626-2.981c2.269-1.504 3.77-4.122 3.77-7.099 0-4.664-3.685-8.445-8.231-8.445zM56.563 8.633c9.433 1.948 14.548 8.68 16.63 17.664.511 2.205.622 4.51.673 6.82l.025 1.386c.013.693.025 1.384.048 2.071.104 3.14.048 6.284.026 9.426H64.14v-3.868c.004-3.116.01-6.233-.01-9.349-.025-4.056-.986-7.83-3.682-10.998-2.17-2.552-4.87-4.032-8.332-3.896-1.005.04-2.014.006-3.02.006-6.706 0-11.15 3.464-12.686 10.012a21.754 21.754 0 00-.55 4.62c-.067 4.49-.025 8.982-.027 13.474L26.006 46l-.004-1.69c0-1.819.004-3.637.004-5.454a16.885 16.885 0 01-.006-.391v-.56c.01-2.526.167-9.218 1.128-12.945 2.155-8.356 7.332-14.539 16.142-16.293 4.282-.852 9.028-.914 13.293-.034z" />
    </Ic>
);

IC287Locked.displayName = 'IC287Locked';

export default IC287Locked;
