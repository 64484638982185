import React from 'react';
import propTypes from 'prop-types';
// import styled from 'styled-components';
// import {rgba} from 'polished';
import {Button} from 'normalized-styled-components';
import noop from 'lodash/noop';
import styled from 'styled-components';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {kayoGreen, white} from '../../../../common/palette';
import {transition} from '../../../../common/animations';

const StyledButton = styled(Button)`
    appearance: none;
    box-sizing: border-box;
    transition: ${transition('border-color')};
    outline: 0;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: transparent;
    padding: 0 42px;
    min-width: 160px;
    max-width: 240px;
    height: 40px;
    color: ${white};
    font: var(--mui-body-copy-2-bold);
    line-height: 1; /* stylelint-disable-line order/properties-order */

    &:hover,
    &:focus {
        outline: 0;
        border: 1px solid ${kayoGreen};
    }
`;

const BA07TertiaryBtnSkip = ({
    children,
    className,
    onClick = noop,
    ...htmlAttributes
}) => (
    <StyledButton
        {...htmlAttributes}
        {...{
            onClick,
        }}
        className={classnames('BA07TertiaryBtnSkip', className)}
        type="button"
    >
        {children}
    </StyledButton>
);

BA07TertiaryBtnSkip.displayName = 'BA07TertiaryBtnSkip';

BA07TertiaryBtnSkip.propTypes = {
    /** Content of button, e.g. text */
    children: propTypes.node.isRequired,
    /** Callback on element click */
    onClick: propTypes.func,
    /** Additional CSS class(es) */
    className: classNameType,
};

export default BA07TertiaryBtnSkip;
