import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';
import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {Section} from '../../../../common/normalized-styled-components';
import {ink} from '../../../../common/palette';

const StyledSection = styled(Section)`
    margin: 0 auto;
    width: 100%;
    color: ${ink};

    ${stylesWhen('isHorizontallyCentered')`
        text-align: center;
    `}
`;

const StyledH2 = styled.h2`
    margin: 0 0 7px;
    font: var(--quicksilver-body-copy-1);
`;

const Copy = styled.p`
    margin: 0;
    color: ${rgba(ink, 0.7)};
    font: var(--quicksilver-header-8-light);
`;

/**
 * A component representing an account block with display header & copy pair for My Account.
 *
 * @param {string} className - Additional class name(s) to apply to the component.
 * @param {React.ReactNode} displayHeader - The header copy to display.
 * @param {React.ReactNode} displaySubtext - The subtext copy to display.
 * @param {boolean} isHorizontallyCentered=false - Whether to horizontally center the text.
 * @returns {JSX.Element} - The rendered component.
 */
const OR130AccountBlk = ({
    className,
    displayHeader,
    displaySubtext,
    isHorizontallyCentered,
    ...htmlAttributes
}) => (
    <StyledSection
        {...htmlAttributes}
        className={classnames('OR130AccountBlk', className)}
        isHorizontallyCentered={isHorizontallyCentered}
    >
        {!!displayHeader && <StyledH2>{displayHeader}</StyledH2>}
        {!!displaySubtext && <Copy>{displaySubtext}</Copy>}
    </StyledSection>
);

OR130AccountBlk.propTypes = {
    /** Additional class name(s) */
    className: classNameType,
    /** CMS block heading copy */
    displayHeader: propTypes.node,
    /** CMS copy */
    displaySubtext: propTypes.node,
    /** Whether to horizontally centre the text */
    isHorizontallyCentered: propTypes.bool,
};

OR130AccountBlk.displayName = 'OR130AccountBlk';

export default OR130AccountBlk;
