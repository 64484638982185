import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import {ink} from '../../../../common/palette';

import Ic from '..';

const IC278KeySegmentsContextualDefault = ({
    color = ink,
    ...args
}) => (
    <Ic {...args}>
        <g fill="none" fillRule="evenodd" transform="translate(7 7)">
            <circle
                cx="42.621"
                cy="42.621"
                r="42.621"
                fill={color}
                fillRule="nonzero"
                opacity=".2"
            />
            <path fill={color} fillOpacity=".35" d="m23.3 62.064.255-6.93C33.723 45.06 39.31 39.398 40.313 38.146c-.476-2.233.073-5.009 1.646-8.328 2.611-5.507 9.192-7.855 14.7-5.245a11.036 11.036 0 0 1 3.37 2.474c4.294 4.637 4.015 11.877-.621 16.17a11.443 11.443 0 0 1-5.208 2.755c-1.495.345-3.067.61-4.717.798l-7 5.99h-4.62v4.375h-4.247V61.6l-10.317.463zm28.402-31.562a3.004 3.004 0 0 0-.652 3.27 2.995 2.995 0 0 0 5.535 0 3.004 3.004 0 0 0-.652-3.27 2.991 2.991 0 0 0-4.23 0z" />
            <g fill={color} fillRule="nonzero">
                <path d="M60.901 24.94a13.32 13.32 0 0 1 .41 19.21 13.302 13.302 0 0 1-12.088 3.628L42.92 54.09l-3.665-.063v2.83c.015.453-.13.894-.405 1.25l-.11.128a1.878 1.878 0 0 1-1.333.558h-2.825V61.6a1.892 1.892 0 0 1-1.796 1.894L22.453 64l-.567-.505a1.706 1.706 0 0 1-.484-1.283l.295-7.038c.018-.475.213-.925.546-1.263L38.847 37.29a13.316 13.316 0 0 1 6.55-14.198A13.268 13.268 0 0 1 60.9 24.94zm-8.556-1.524c-2.599-.103-5.234.668-7.65 2.528a11.268 11.268 0 0 0-3.627 5.326c-.652 2.047-.721 4.279-.07 6.647l-17.35 17.36-.287 6.725 9.27-.458.006-4.1.018-.092a.693.693 0 0 1 .667-.515h3.983l.006-4.124.02-.092a.975.975 0 0 1 .208-.352.699.699 0 0 1 .5-.198l4.036.084 6.533-6.544.493.138.378.089a11.344 11.344 0 0 0 10.433-3.071 11.382 11.382 0 0 0-.328-16.394 11.457 11.457 0 0 0-7.24-2.957z" />
                <path d="M57.31 29.073a4.971 4.971 0 0 1 0 7.017 4.95 4.95 0 0 1-5.402 1.08 4.966 4.966 0 0 1-3.062-4.588 4.966 4.966 0 0 1 3.062-4.589 4.95 4.95 0 0 1 5.403 1.08zm-3.502.533a3.007 3.007 0 0 0-2.778 4.159 3.006 3.006 0 0 0 5.727-1.738 3.007 3.007 0 0 0-2.949-2.42z" />
            </g>
        </g>
    </Ic>
);

IC278KeySegmentsContextualDefault.propTypes = {
    /** The icon's primary color */
    color: colorType,
};

IC278KeySegmentsContextualDefault.displayName = 'IC278KeySegmentsContextualDefault';

export default IC278KeySegmentsContextualDefault;
