import React from 'react';
import bacon from 'baconjs';
import get from 'lodash/get';
import noop from 'lodash/noop';
import ComponentInvalidOfferModal from '../../components/branded/common/invalid-offer';

// Optus offers expire after 30 minutes
// Will need to display the API provided error message and redirect the user back to the Optus site

export default function getInvalidOptusOfferModalStream({
    brand,
    prefetchedOffer$,
    isActiveSubscription$,
    offer$,
}) {
    if (!brand) {
        console.error('Accounts Widgets: brand not provided to getInvalidOptusOfferModalStream');
    }

    const invalidLandingErrorMessage$ = bacon.combineTemplate({
        prefetchedError: prefetchedOffer$.map('.error'),
        offerError: offer$.map('.error'),
    })
        .take(1) // Only ever take the first loaded offer/prefetched offer.
        .map(({/* prefetchedError, */ offerError}) => {
            // We don't really 'care' about the prefetched error since the Optus values are not passed in through FISO and thus it will always fail this call
            const errorDetail = get(offerError, 'detail'); // || get(prefetchedError, 'detail');

            return errorDetail || false;
        });

    const onContinueFunction = () => {
        // User should be redirected back to Optus page if their offer has expired
        window.location.replace('https://subhub.optus.com.au/streaming-services/binge');
    };

    const modalProps$ = bacon.combineTemplate({
        brand,
        errorMessage: invalidLandingErrorMessage$,
        isActiveUser: isActiveSubscription$,
    })
        .filter(invalidLandingErrorMessage$);

    const reactElement$ = modalProps$
        .filter(({isActiveUser}) => !isActiveUser)
        .flatMapLatest(({
            brand,
            errorMessage,
        }) => (
            <ComponentInvalidOfferModal
                dismissModalFunc={noop}
                shouldDismissModalWithClickOutside={false}
                errorMessage={errorMessage}
                onContinueFunction={onContinueFunction}
                userGreeting="Offer has expired"
                shouldSelectNextBestOffer={false}

                hasContinueButton={true}
                continueButtonText="OK"
                brand={brand}
            />
        ))
        .startWith(null);

    return reactElement$;
}
