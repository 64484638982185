import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import {ServerStyleSheet} from 'styled-components';
import result from 'lodash/result';
import invoke from 'lodash/invoke';

import {BaseBaconWidget} from '@fsa-streamotion/bacon-widget';

export default class StyledBaconWidget extends BaseBaconWidget {
    pushOnRenderOrHydration = () => {
        if (this.clientSideHydratedBus) {
            this.clientSideHydratedBus.push(true);
        }
    };

    renderClient(props, shouldHydrate) {
        if (shouldHydrate) {
            if (process.env.NODE_ENV !== 'production') {
                console.info(  // eslint-disable-line no-console
                    `%c[StyledBaconWidget] ${this.constructor.widgetName}: renderClient() hydrated 💦 at:`,
                    'background: #0000CD; color: #CCF5FF;',
                    this.element
                );
            }

            ReactDOM.hydrate(<this.component {...props} />, this.element, this.pushOnRenderOrHydration());
        } else {
            ReactDOM.render(<this.component {...props} />, this.element, this.pushOnRenderOrHydration());
        }
    }

    renderServerComponent(props) {
        if (process && process.browser) {
            // @TODO re-evaluate using isBrowser() later, right now we can't use isBrowser() as `new ServerStyleSheet()` errors out in server environment
            // Could track issue, https://github.com/styled-components/styled-components/issues/1692 which might expose forceServer option
            // return super.renderServerComponent(props);

            return {
                html: ReactDOMServer.renderToString(<this.component {...props} />),
            };
        }

        const styleSheet = new ServerStyleSheet();
        const html = ReactDOMServer.renderToString(
            styleSheet.collectStyles(<this.component {...props} />)
        );
        const styleTags = styleSheet.getStyleTags();

        return {
            prependedHtml: styleTags,
            html,
        };
    }

    /**
     * This is copied from baseBaconWidget with one small change
     *
     * We want to support an error "event" being a toString-able rather than strictly a string
     *
     * @returns {Promise} Resolving to {expiresIn, html, statusCode} or rejecting as {expiresIn, html, contentType}
     */
    initServer() {
        return new Promise((resolve, reject) => {
            this.closeStreams = this.getData()
                .first()
                .subscribe((event) => {
                    if (result(event, 'isError')) {
                        const errorMessage = invoke(event, 'error.toString') || ''; // this is the only line that changed when forked off from baseBaconWidget
                        const errorMessageHtml = errorMessage
                            .replace(/&/g, '&amp;')
                            .replace(/</g, '&lt;')
                            .replace(/>/g, '&gt;')
                            .replace(/"/g, '&quot;');

                        console.error(`Error: ${this.constructor.widgetName} failed to render: ${errorMessage}`); // error so slack gets notified.

                        reject({
                            expiresIn: this.cacheFailureForSeconds * 1000, // in ms
                            html: `<div class="dev-only" style="display: none;"><pre><code>${errorMessageHtml}</code></pre></div>`,
                            statusCode: this.onErrorStatusCode,
                        });
                    } else if (result(event, 'hasValue')) {
                        resolve({
                            contentType: this.contentType,
                            expiresIn: this.cacheSuccessForSeconds * 1000, // in ms
                            html: this.renderServer(result(event, 'value')),
                        });
                    }
                });
        });
    }
}
