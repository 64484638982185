import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {A, Button} from 'normalized-styled-components';
import classnames from 'classnames';
import padEnd from 'lodash/padEnd';
import {rgba} from 'polished';

import {mediaQuery, stylesIfElse, stylesWhen, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {buttonType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {PERIOD_SHORT, PERIOD_LONG} from '../../../../common/billing-constants';
import {billingPeriodType} from '../../../../common/custom-proptypes';
import {black, kayoGreen, white} from '../../../../common/palette';
import {SCREEN_TABLET} from '../../../../common/screen-sizes';
import GA04NavInd from '../../ga/04-nav-ind';

const HoverIndicator = styled.span`
    display: flex;
    justify-content: center;
    transform: translateY(11px);
    transition: ${transition('opacity')};
    opacity: 0;
    margin-top: -4px;
    pointer-events: none;
`;

const commonStyles = css`
    box-sizing: border-box;
    display: block;
    border: 0;
    background: transparent;
    padding: 0;
    width: 100%;
    text-align: center;
    text-decoration: none;
    color: ${({isCurrent}) => isCurrent ? white : black};
    font: var(--mui-body-copy-6);

    ${stylesWhenNot(({disabled, isCurrent}) => disabled || isCurrent)`
        &:focus,
        &:hover {
            ${HoverIndicator} {
                opacity: 1;
            }
        }
    `}

    ${stylesWhen('disabled')`
        cursor: not-allowed;
        opacity: 0.6;
    `}

    &:focus {
        outline: none;
    }
`;

const StyledLink = styled(A)`
    text-decoration: none;

    ${commonStyles}
`;

const StyledButton = styled(Button)`
    appearance: none;

    ${commonStyles}
`;

const StyledBox = styled.div`
    ${commonStyles}
`;

const ShadowWrapper = styled.span`
    display: block;
    position: relative;
    border-radius: 4px;
    overflow: hidden;

    ${stylesWhenNot('isCurrent')`
        box-shadow: 0 0 14px 0 ${rgba(black, 0.35)};
    `}
`;

const Wrapper = styled.span`
    display: block;
    height: 100%;

    ${stylesIfElse('isCurrent')`
        border: 1px solid ${kayoGreen};
        /* adjust padding to account for border on top and bottom */
        padding-top: 20px;
        padding-bottom: 20px;
    ``
        background: ${white};
        padding-top: 21px;
        padding-bottom: 21px;
    `}

    ${stylesWhen(({hasOffer, isCurrent}) => !!hasOffer && !isCurrent)`
        &::before {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            border-width: 12px;
            border-style: solid;
            border-color: ${kayoGreen} transparent transparent ${kayoGreen};
            content: '';

            ${stylesWhenNot('theme.isAppDock')`
                ${mediaQuery({minWidthPx: SCREEN_TABLET})`
                    border-width: 18px;
                `}
            `}
        }
    `}
`;

const Period = styled.span`
    font: var(--mui-offer-period);

    ${stylesWhen('hasFraction')`
        position: absolute;
        transform: translate(-1px, 10px);

        ${stylesWhenNot('theme.isAppDock')`
            ${mediaQuery({minWidthPx: SCREEN_TABLET})`
                transform: translate(0px, 26px);
            `}
        `}
    `}
`;

const OfferName = styled.strong`
    display: inline-block;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    text-transform: uppercase;
    text-overflow: ellipsis;
    white-space: nowrap;
    font: var(--mui-body-copy-1-bold);

    &::after {
        position: absolute;
        right: 0;
        bottom: 5px;
        left: 0;
        border-bottom: 1px solid currentColor;
        content: '';
    }
`;

const OfferPrice = styled.span`
    display: block;

    ${stylesWhen('hasFraction')`
        position: relative;
        transform: translateX(-21px);
    `}
`;

const PriceUnits = styled.span`
    letter-spacing: -3px;
    font: var(--mui-offer-price);
`;

const PriceFraction = styled.sup`
    position: absolute;
    top: 2px;
    font: var(--mui-body-copy-6);
    line-height: 1; /* stylelint-disable-line order/properties-order */
    font-weight: normal;

    ${stylesWhenNot('theme.isAppDock')`
        ${mediaQuery({minWidthPx: SCREEN_TABLET})`
            top: 5px;
        `}
    `}
`;

const PrevPriceText = styled.span`
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    font: var(--mui-body-copy-6-bold);
`;

const SecondaryText = styled.span`
    display: block;
    background-color: ${kayoGreen};
    padding: 3px 7px;
    font: var(--mui-body-copy-5-bold);
`;

const BA14OfferRadBtn = ({
    isCurrent,
    currencyPrefix = '$',
    currencyDecimalPlaces = 2,
    description,
    period,
    prevPriceText,
    accessiblePrevPriceText,
    secondaryText,
    accessibleSecondaryText,
    price = 0,
    type = 'button',
    href,
    onClick,
    className,
    ...htmlAttributes
}) => {
    const isFree = !price;
    const [displayPriceUnits, priceFractionFragment] = price.toString().split('.');
    const displayPriceFraction = padEnd(priceFractionFragment, currencyDecimalPlaces, '0').slice(0, currencyDecimalPlaces);

    const periodLabel = PERIOD_SHORT[period];
    const periodLabelLong = PERIOD_LONG[period];
    const title = isFree ? 'Free' : [
        currencyPrefix,
        displayPriceUnits,
        priceFractionFragment ? `.${displayPriceFraction}` : '',
        periodLabelLong ? ` per ${periodLabelLong}` : '',
    ].join('');
    const massagedAccessiblePrevPriceText = accessiblePrevPriceText ? `, ${accessiblePrevPriceText}` : '';
    const massagedAccessibleSecondaryText = accessibleSecondaryText ? `, ${accessibleSecondaryText}` : '';

    // 'Basic: $20.99 per month, was $25 per month'
    const accessibleDesc = `${description}: ${title}${massagedAccessiblePrevPriceText}${massagedAccessibleSecondaryText}`;

    let StyledElement;

    if (isCurrent) {
        StyledElement = StyledBox;
    } else if (href) {
        StyledElement = StyledLink;
    } else {
        StyledElement = StyledButton;
    }

    return (
        <StyledElement
            {...htmlAttributes}
            className={classnames('BA14OfferRadBtn', className)}
            isCurrent={isCurrent}
            href={href}
            onClick={onClick}
            type={href ? undefined : type}
            aria-label={accessibleDesc}
            title={accessibleDesc}
        >
            <ShadowWrapper isCurrent={isCurrent}>
                <Wrapper
                    isCurrent={isCurrent}
                    hasOffer={!!(secondaryText || prevPriceText)}
                >
                    <OfferName>
                        {description}
                    </OfferName>

                    <OfferPrice hasFraction={!!priceFractionFragment}>
                        {isFree ? <PriceUnits>FREE</PriceUnits> : (
                            <React.Fragment>
                                <PriceUnits>
                                    {currencyPrefix}{displayPriceUnits}
                                </PriceUnits>

                                {!!priceFractionFragment && (
                                    <PriceFraction>.{displayPriceFraction}</PriceFraction>
                                )}

                                {!!periodLabel && (
                                    <Period hasFraction={!!priceFractionFragment}>
                                        /{periodLabel}
                                    </Period>
                                )}
                            </React.Fragment>
                        )}
                    </OfferPrice>
                    {!!prevPriceText && (
                        <PrevPriceText>{prevPriceText}</PrevPriceText>
                    )}
                </Wrapper>
                {!!secondaryText && (
                    <SecondaryText>{secondaryText}</SecondaryText>
                )}
            </ShadowWrapper>
            {!isCurrent && (
                <HoverIndicator>
                    <GA04NavInd />
                </HoverIndicator>
            )}
        </StyledElement>
    );
};

BA14OfferRadBtn.displayName = 'BA14OfferRadBtn';

BA14OfferRadBtn.propTypes = {
    /** Is this the user's current plan? */
    isCurrent: propTypes.bool,
    /** Currency prefix, will appear to the left of price */
    currencyPrefix: propTypes.oneOf(['$']),
    /** Currency decimal places (defaults to 2) */
    currencyDecimalPlaces: propTypes.number,
    /** e.g. Offer name */
    description: propTypes.string,
    /** Period that the price describes */
    period: billingPeriodType,
    /** Previous price text */
    prevPriceText: propTypes.string,
    /** Accessible previous price text (ie. what to display in the title attrib) */
    accessiblePrevPriceText: propTypes.string,
    /** Secondary text (eg. after voucher applied, special terms) */
    secondaryText: propTypes.string,
    /** Accessible secondary text (ie. what to display in the title attrib) */
    accessibleSecondaryText: propTypes.string,
    /** Current price / new price after voucher applied */
    price: propTypes.number,
    /** Button type */
    type: buttonType,
    /** Link to navigate to on click */
    href: propTypes.string,
    /** Click handler */
    onClick: propTypes.func,
    /** Additional class name(s) */
    className: classNameType,
};

export default BA14OfferRadBtn;
