import React from 'react';

import Ic from '..';

const IC105Home = ({
    ...args
}) => (
    <Ic {...args}>
        <g>
            <g transform="translate(-26 -10)">
                <g transform="translate(24 10)">
                    <g transform="translate(2)">
                        <path fill="none" d="M0 0h100v100H0z" />
                        <g transform="translate(4.133 3.907)">
                            <defs>
                                <filter
                                    id="Adobe_OpacityMaskFilter"
                                    width={63.8}
                                    height={65.3}
                                    x={13.9}
                                    y={13.3}
                                    filterUnits="userSpaceOnUse"
                                >
                                    <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
                                </filter>
                            </defs>
                            <mask
                                id="mask-2_00000058571697297346460980000017907149597807845296_"
                                width={63.8}
                                height={65.3}
                                x={13.9}
                                y={13.3}
                                maskUnits="userSpaceOnUse"
                            >
                                <g filter="url(#Adobe_OpacityMaskFilter)">
                                    <path
                                        d="M10.6 10h70.3v71.8H10.6z"
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                    />
                                </g>
                            </mask>
                            <path
                                d="M41 44.3h9.5c3.8 0 6.9 3.1 6.9 6.9v23.7c0 2 1.6 3.7 3.7 3.7h11.5c2.8 0 5-2.2 5-5V36.1c0-1.7-.8-3.2-2.2-4.1L48.5 14.1c-1.7-1.1-3.9-1.1-5.5 0L16.1 31.9c-1.4.9-2.2 2.5-2.2 4.1v37.5c0 2.7 2.2 5 5 5h11.5c2 0 3.7-1.6 3.7-3.7V51.2c0-3.8 3.1-6.9 6.9-6.9"
                                opacity="0.2"
                                mask="url(#mask-2_00000058571697297346460980000017907149597807845296_)"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                fill="#fafafa"
                            />
                            <defs>
                                <filter
                                    id="Adobe_OpacityMaskFilter_00000057126179330942480290000000704722702912148624_"
                                    width={70.3}
                                    height={71.7}
                                    x={10.6}
                                    y={10}
                                    filterUnits="userSpaceOnUse"
                                >
                                    <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
                                </filter>
                            </defs>
                            <mask
                                id="mask-2_00000048482969319430129490000014018349707335791774_"
                                width={70.3}
                                height={71.7}
                                x={10.6}
                                y={10}
                                maskUnits="userSpaceOnUse"
                            >
                                <g filter="url(#Adobe_OpacityMaskFilter_00000057126179330942480290000000704722702912148624_)">
                                    <path
                                        d="M10.6 10h70.3v71.8H10.6z"
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                    />
                                </g>
                            </mask>
                            <path
                                d="M41 44.3h9.5c3.8 0 6.9 3.1 6.9 6.9v23.7c0 2 1.6 3.7 3.7 3.7h11.5c2.8 0 5-2.2 5-5V36.1c0-1.7-.8-3.2-2.2-4.1L48.5 14.1c-1.7-1.1-3.9-1.1-5.5 0L16.1 31.9c-1.4.9-2.2 2.5-2.2 4.1v37.5c0 2.7 2.2 5 5 5h11.5c2 0 3.7-1.6 3.7-3.7V51.2c0-3.8 3.1-6.9 6.9-6.9m31.7 37.5H61.1c-3.8 0-6.9-3.1-6.9-6.9V51.2c0-2-1.6-3.7-3.7-3.7H41c-2 0-3.7 1.6-3.7 3.7v23.7c0 3.8-3.1 6.9-6.9 6.9H18.9c-4.5 0-8.2-3.7-8.2-8.2V36.1c0-2.8 1.4-5.3 3.7-6.8l26.9-17.8c2.8-1.8 6.4-1.8 9.1 0l26.9 17.8c2.3 1.5 3.7 4.1 3.7 6.8v37.5c-.1 4.5-3.8 8.2-8.3 8.2"
                                mask="url(#mask-2_00000048482969319430129490000014018349707335791774_)"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                fill="#fafafa"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </Ic>
);

IC105Home.displayName = 'IC105Home';

export default IC105Home;
