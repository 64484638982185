import React, {useMemo} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import property from 'lodash/property';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import classnames from 'classnames';

import {mediaQuery, stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {srcsetOptionsType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {midnight, white} from '../../../../common/palette';
import {SCREEN_TABLET, SCREEN_LG_DESKTOP} from '../../../../common/screen-sizes';
import VisuallyHidden from '../../../../common/visually-hidden';
import BM01GnrBnr from '../../../atoms/bm/01-gnr-bnr';

const RIGHT = 'right';
const LEFT = 'left';

const INFO_WIDTH = 200;
const INFO_WIDTH_LG_DESKTOP = 300;

const Scoreboard = styled.section`
    box-sizing: border-box;
    display: flex;
    position: relative;
    background-color: ${rgba(midnight, 0.89)};
    width: 100%;
    text-align: center;
    color: ${white};
    font-size: 40px;

    ${stylesWhen('isTeamAB')`
        align-items: center;
        justify-content: center;
        padding: 14px 0;
    `}

    &::before,
    &::after {
        position: absolute;
        bottom: 0;
        width: 50%;
        height: 2px;
        content: '';
    }

    &::before {
        left: 0;
        background-color: ${property('colorA')};
    }

    &::after {
        right: 0;
        background-color: ${property('colorB')};
    }
`;

const TileContainer = styled.div`
    flex: 0 0 calc(50% - ${INFO_WIDTH / 2}px);

    ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
        flex-basis: calc(50% - ${INFO_WIDTH_LG_DESKTOP / 2}px);
    `}

    ${stylesWhen(({tileAlign}) => tileAlign === LEFT)`
        order: 2;
    `}
`;

const Info = styled.div`
    box-sizing: border-box;
    display: grid;
    grid-gap: 7px;
    order: 1;
    padding-top: 7px;
    width: 100%;
    text-align: center;
    font: var(--mui-body-copy-4);

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        flex: 0 0 auto;
        padding-top: 0;
        width: ${INFO_WIDTH}px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
        width: ${INFO_WIDTH_LG_DESKTOP}px;
    `}
`;

const SingleLineText = styled.p`
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const OR18MtchHead = ({
    className,
    colorA,
    colorB,
    fixture,
    hasNoSpoilers = true,
    logoASrcsetOptions,
    logoBSrcsetOptions,

    matchStartDay,
    matchStartTime,
    matchStartPrefix,

    matchEndDay,
    matchEndTime,

    ...htmlAttributes
}) => {
    const {
        matchClock,
        matchState,
        matchStatus,
        roundName,
        seriesName,
        shortScoreSummary,
        sportId,
        teamA,
        teamB,
        venueCity,
        venueName,
    } = useMemo(() => ({ // little performance optimisation, based on the assumption that the "fixture" object only changes atomically
        matchClock: get(fixture, 'match_time'),
        matchState: get(fixture, 'match_status_normalised'),
        matchStatus: get(fixture, 'match_status'),
        roundName: get(fixture, 'round.name'),
        seriesName: get(fixture, 'series.short_name') || get(fixture, 'series.name'),
        shortScoreSummary: get(fixture, 'short_score_summary'),
        sportId: get(fixture, 'sport'),
        teamA: get(fixture, 'team_A'),
        teamB: get(fixture, 'team_B'),
        venueCity: get(fixture, 'venue.city'),
        venueName: get(fixture, 'venue.name'),
    }), [fixture]);

    const venueDescription = [venueName, venueCity]
        .filter(Boolean)
        .join(', ');

    return (
        <Scoreboard
            {...htmlAttributes}
            className={classnames('OR18MtchHead', className)}
            colorA={colorA}
            colorB={colorB}
            isTeamAB={!isEmpty(fixture)}
            state={matchState}
        >
            {!isEmpty(fixture) && !isEmpty(teamA) && !isEmpty(teamB)
                ? (
                    <React.Fragment>
                        <TileContainer tileAlign={RIGHT}>
                            <BM01GnrBnr
                                hasNoSpoilers={hasNoSpoilers}
                                logoSrcsetOptions={logoASrcsetOptions}
                                matchState={matchState}
                                otherTeamData={teamB}
                                sportId={sportId}
                                teamData={teamA}
                                tileAlign={RIGHT}
                            />
                        </TileContainer>
                        <VisuallyHidden>versus</VisuallyHidden>
                        <TileContainer tileAlign={LEFT}>
                            <BM01GnrBnr
                                hasNoSpoilers={hasNoSpoilers}
                                logoSrcsetOptions={logoBSrcsetOptions}
                                matchState={matchState}
                                otherTeamData={teamA}
                                sportId={sportId}
                                teamData={teamB}
                                tileAlign={LEFT}
                            />
                        </TileContainer>
                        <Info>
                            {matchState === 'pre' && paragraphs([
                                joinWithDashes(seriesName, roundName, matchStartDay),
                                joinWithDashes(
                                    joinWithSpaces(matchStartPrefix, matchStartTime),
                                    venueDescription,
                                ),
                            ])}

                            {matchState === 'live' && paragraphs(
                                hasNoSpoilers
                                    ? [
                                        joinWithDashes(seriesName, roundName),
                                        joinWithDashes('In Progress', venueDescription),
                                    ]
                                    : [
                                        joinWithDashes(
                                            seriesName,
                                            joinWithSpaces(matchStartDay, matchStartTime),
                                        ),
                                        shortScoreSummary || joinWithSpaces(matchStatus, matchClock),
                                    ]
                            )}

                            {matchState === 'post' && paragraphs(
                                [
                                    joinWithDashes(seriesName, roundName),
                                    joinWithDashes(matchStatus, venueDescription),
                                ]
                            )}
                        </Info>
                    </React.Fragment>
                )
                // Generic
                : (
                    <BM01GnrBnr
                        hasNoSpoilers={hasNoSpoilers}
                        matchEndDate={joinWithSpaces(matchEndDay, matchEndTime)}
                        matchStartDate={joinWithSpaces(matchStartPrefix, matchStartDay, matchStartTime)}
                        matchState={matchState}
                        seriesName={seriesName}
                        venueName={venueName}
                    />
                )}
        </Scoreboard>
    );
};

OR18MtchHead.propTypes = {
    className: classNameType,
    /** Colour for Team A */
    colorA: propTypes.string,
    /** Colour for Team B */
    colorB: propTypes.string,
    /** Data from stats api's `fixturesandresults` model, for match starting time, and team_A/team_B info etc. */
    fixture: propTypes.shape({
        match_status: propTypes.string,
        /** used in AFL, cricket, football, teamAB */
        match_status_normalised: propTypes.string, // 'pre', 'live', 'post
        /** used in  team ab info - live match */
        match_time: propTypes.string,
        /**  used in  team ab info */
        round: propTypes.shape({
            name: propTypes.string,
        }),
        /**  used in team ab info & default tile */
        series: propTypes.shape({
            name: propTypes.string,
            short_name: propTypes.string,
        }),
        /** used in team ab info - live match - spoiler turned on  */
        short_score_summary: propTypes.string,
        /** sport name ie 'afl', 'football', 'league', 'cricket', 'tennis', 'golf', 'motor' */
        sport: propTypes.string,
        /** team data for team a vs team b scoreboard */
        team_A: propTypes.shape({
            // AFL, cricket, football,  teamAB
            code: propTypes.string,
            name: propTypes.string,

            // AFL, football, teamAB
            score: propTypes.number,

            // AFL
            behinds: propTypes.number,
            goals: propTypes.number,
            super_goals: propTypes.number,

            // cricket
            first_innings: propTypes.shape({
                runs: propTypes.number,
                wickets: propTypes.number,
                is_all_out: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
                is_declared: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
            }),
            second_innings: propTypes.shape({
                runs: propTypes.number,
                wickets: propTypes.number,
                is_all_out: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
                is_declared: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
            }),

            // football
            shoot_out_score: propTypes.number,

        }),
        /** team data for team a vs team b scoreboard */
        team_B: propTypes.object,
        /** Used by team ab and default tile */
        venue: propTypes.shape({
            city: propTypes.string,
            name: propTypes.string,
        }),
    }),
    /** During live matches, don't show any spoilers, i.e match result, score */
    hasNoSpoilers: propTypes.bool,
    /** Team logo for Team A */
    logoASrcsetOptions: srcsetOptionsType,
    /** Team logo for Team B */
    logoBSrcsetOptions: srcsetOptionsType,
    /** Parsed pretty string for match end date */
    matchEndDay: propTypes.string,
    /** Parsed pretty string for match end time */
    matchEndTime: propTypes.string,
    /** Parsed pretty string for match start date */
    matchStartDay: propTypes.string,
    /** Parsed pretty string for match start time */
    matchStartTime: propTypes.string,
    /** Prefix for the match start string, e.g. "Begins"
     * - https://resources.kayosports.com.au/production/match-centre/scoreboard.json
     */
    matchStartPrefix: propTypes.string,
};

OR18MtchHead.displayName = 'OR18MtchHead';

export default OR18MtchHead;

function checkTruthyOrZero(payload) {
    return !!payload || payload === 0;
}

function joinWithDashes(...strings) {
    return strings
        .filter(checkTruthyOrZero)
        .join(' - ');
}

function joinWithSpaces(...strings) {
    return strings
        .filter(checkTruthyOrZero)
        .join(' ');
}

function paragraphs(contents) {
    if (!contents) {
        return null;
    }

    return contents
        .filter(checkTruthyOrZero) // zero is ok. everything else falsey we don't want to render a paragraph
        .map((copy) => (
            <SingleLineText key={copy}>
                {copy}
            </SingleLineText>
        ));
}
