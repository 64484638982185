import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import property from 'lodash/property';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {fontFamily, fontSize} from '../../../../../common/typography';
import {IC103Loading} from '../../../../atoms/ic';

const BufferingIndicator = styled.div`
    position: relative;
    width: 1em;
    height: 1em;
    font-size: ${property('sizePx')}px;

    &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 1px auto 0;
        font: 0.25em / 1 ${fontFamily};
        content: attr(title);
    }

    @media screen and (prefers-reduced-motion: reduce), (update: slow) {
        width: unset;
        height: unset;
        text-align: center;
        font-size: ${fontSize};

        &::after {
            display: block;
            position: static;
            transform: unset;
            margin-top: 7px;
            margin-right: 0;
            margin-left: 0.25em;
            text-align: center;
            font-size: inherit;
        }
    }
`;

const VPTM04BufferingIndicator = ({
    assistiveLabel = 'Buffering video',
    bufferPercentage,
    className,
    sizePx = 100,
}) => {
    const progressBarProps = typeof bufferPercentage === 'number' && isFinite(bufferPercentage) ? {
        'aria-valuemax': 100,
        'aria-valuemin': 0,
        'aria-valuenow': bufferPercentage,
        'aria-valuetext': assistiveLabel,
        'role': 'progressbar', // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_progressbar_role#Examples
        'title': `${bufferPercentage}%`,
    } : {
        'aria-label': assistiveLabel,
    };

    return (
        <BufferingIndicator
            {...progressBarProps}
            sizePx={sizePx}
            className={className}
        >
            <IC103Loading />
        </BufferingIndicator>
    );
};

VPTM04BufferingIndicator.propTypes = {
    /** Screen reader label for the loading indicator */
    assistiveLabel: propTypes.string,
    /** Total pixel width/height of element */
    sizePx: propTypes.number,
    /** Additional CSS class name(s) */
    className: classNameType,
    /** Number from 0-100 representing current buffer state */
    bufferPercentage: propTypes.number,
};

VPTM04BufferingIndicator.displayName = 'VPTM04BufferingIndicator';

export default VPTM04BufferingIndicator;
