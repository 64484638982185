import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import {ink, slate} from '../../../../common/palette';

import Ic from '..';

const IC324TechSpecs = ({
    color = ink,
    secondaryColor = slate,
    ...args
}) => (
    <Ic {...args}>
        <path
            fill={secondaryColor}
            fillOpacity=".35"
            stroke={color}
            strokeWidth="3"
            d="M50 14.5c6.765 0 12.89 2.742 17.324 7.176A24.423 24.423 0 0 1 74.5 39v42c0 .966-.392 1.841-1.025 2.475A3.489 3.489 0 0 1 71 84.5H29a3.489 3.489 0 0 1-2.475-1.025A3.489 3.489 0 0 1 25.5 81V18c0-.966.392-1.841 1.025-2.475A3.489 3.489 0 0 1 29 14.5z"
        />
        <path
            fill={color}
            fillRule="nonzero"
            d="M54 33.5c0 .777-.394 1.415-.899 1.492L53 35H36.001C35.448 35 35 34.328 35 33.5c0-.777.394-1.415.899-1.492L36 32h16.998c.553 0 1.001.672 1.001 1.5zm12 12c0 .777-.643 1.415-1.466 1.492l-.167.008H36.633C35.731 47 35 46.328 35 45.5c0-.777.643-1.415 1.466-1.492l.167-.008h27.734c.902 0 1.633.672 1.633 1.5zm0 12c0 .777-.643 1.415-1.466 1.492l-.167.008H36.633C35.731 59 35 58.328 35 57.5c0-.777.643-1.415 1.466-1.492l.167-.008h27.734c.902 0 1.633.672 1.633 1.5zm0 12c0 .777-.643 1.415-1.466 1.492l-.167.008H36.633C35.731 71 35 70.328 35 69.5c0-.777.643-1.415 1.466-1.492l.167-.008h27.734c.902 0 1.633.672 1.633 1.5z"
        />
    </Ic>
);

IC324TechSpecs.propTypes = {
    /** The icon's primary color */
    color: colorType,
    /** The icon's secondary color */
    secondaryColor: colorType,
};

IC324TechSpecs.displayName = 'IC324TechSpecs';

export default IC324TechSpecs;
