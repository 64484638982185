import React from 'react';
import bacon from 'baconjs';
import noop from 'lodash/noop';
import cleanAuth0NonceCookies from '../../common/clean-auth0-nonce-cookies';
import ComponentInvalidOfferModal from '../../components/branded/common/invalid-offer';
import {ACTIVE_SUBSCRIPTION_COPY} from '../../../../todo-move-to-widgets-common/utils/constants';

// Will need to display the message pop-up for active optus user on checkout page
export default function getActiveUserWarningModal({
    brand,
    profileUrl,
    isActiveSubscription$,
    hasOptusValues$,
}) {
    if (!brand) {
        console.error('Accounts Widgets: brand not provided to getActiveUserWarningModalStream');
    }

    const redirectToProfilePage = () => {
        cleanAuth0NonceCookies();
        // User should be redirected to profile page
        window.location.replace(profileUrl);
    };

    const modalProps$ = bacon.combineTemplate({
        isActiveUser:  isActiveSubscription$,
        isOptusOffer: hasOptusValues$,
    });

    const reactElement$ = modalProps$
        .filter(({isActiveUser, isOptusOffer}) => !!isActiveUser && !!isOptusOffer)
        .flatMapLatest(() => (
            <ComponentInvalidOfferModal
                dismissModalFunc={noop}
                shouldDismissModalWithClickOutside={false}
                errorMessage={ACTIVE_SUBSCRIPTION_COPY.errorMessage}
                onContinueFunction={redirectToProfilePage}
                userGreeting={ACTIVE_SUBSCRIPTION_COPY.userGreeting}
                shouldSelectNextBestOffer={false}
                hasContinueButton={true}
                continueButtonText="OK"
                brand={brand}
            />
        ))
        .startWith(null);

    return reactElement$;
}
