import React from 'react';

import Ic from '..';

const IC02Sea = (args) => (
    <Ic {...args}>
        <g fillOpacity=".15">
            <path fillRule="nonzero" d="M44.68 12c-13.223 0-25.143 8.027-30.202 20.336-5.058 12.31-2.26 26.479 7.092 35.898 9.352 9.419 23.414 12.234 35.628 7.131 12.214-5.102 20.175-17.117 20.17-30.44a33.053 33.053 0 0 0-9.575-23.286A32.55 32.55 0 0 0 44.68 12z" />
            <path d="m78.365 70.195 9.77 9.845a4.5 4.5 0 0 1 0 6.34l-1.331 1.341a4.5 4.5 0 0 1-6.388 0l-9.77-9.845a4.5 4.5 0 0 1 0-6.34l1.331-1.341a4.5 4.5 0 0 1 6.388 0z" />
        </g>
        <path fillRule="nonzero" d="M44.68 12a32.55 32.55 0 0 1 23.113 9.639 33.053 33.053 0 0 1 9.575 23.286c.005 13.323-7.956 25.338-20.17 30.44-12.214 5.103-26.276 2.288-35.628-7.131-9.351-9.42-12.15-23.588-7.092-35.898C19.538 20.026 31.458 12 44.68 12zm0 2.994c-12.02 0-22.857 7.297-27.456 18.488-4.599 11.19-2.054 24.071 6.447 32.634 8.502 8.563 21.285 11.122 32.389 6.483 11.104-4.638 18.34-15.56 18.336-27.674 0-7.94-3.131-15.555-8.704-21.168a29.59 29.59 0 0 0-21.011-8.763zm43.478 65.002c1.741 1.74 1.73 4.574-.024 6.328l-1.378 1.378c-1.754 1.754-4.587 1.765-6.328.024l-9.806-9.806c-1.74-1.74-1.73-4.574.024-6.328l1.378-1.378c1.754-1.754 4.588-1.765 6.328-.024l9.806 9.806zm-2.117 2.117-9.806-9.806a1.492 1.492 0 0 0-2.11.008l-1.377 1.378a1.492 1.492 0 0 0-.008 2.11l9.806 9.806c.58.58 1.524.576 2.109-.008l1.378-1.378c.585-.585.588-1.53.008-2.11z" />
    </Ic>
);

IC02Sea.displayName = 'IC02Sea';

export default IC02Sea;
