import React, {useEffect, useRef, Fragment} from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {rgba} from 'polished';
import get from 'lodash/get';
import matchesProperty from 'lodash/matchesProperty';

import {Section} from 'normalized-styled-components';
import {mediaQuery, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import TargetAemWrapper from '@fsa-streamotion/target-aem-wrapper';
import {palette as infinityPalette, screenSizes} from '@fsa-streamotion/streamotion-web-infinity';
import {palette as ionicPalette} from '@fsa-streamotion/streamotion-web-ionic';
import {palette as muiPalette, screenSizes as muiScreenSizes} from '@fsa-streamotion/streamotion-web-mui';
import {palette as nucleusPalette} from '@fsa-streamotion/streamotion-web-nucleus';
import {stylesWhenBinge, stylesWhenFlash, stylesWhenKayo, stylesWhenLifestyle} from '../../../utils/styles-when-brand';

import aemModuleType from '../../custom-proptypes/aem-module-type';
import brandPropType from '../../../common/brand-proptype';
import termTypePropType from '../../../common/term-type-proptype';

import {
    BrandedBA01BtnPr,
    BrandedCA01Feature,
    BrandedCM03FeatureList,
    BrandedIC103Loading,
    BrandedOR16MarBlk,
    BrandedIC25ArrowR,
} from '../../../utils/branded-components';

import VoucherForm from '../../branded/landing/voucher-form';
import VoucherImg, {VOUCHER_IMAGE_DESKTOP_MAX_WIDTH_PX} from '../../branded/voucher-image';
import PackageSelectorTable from '../../branded/common/package-selector';
import BrandedPackageSelectorLoadingSkeleton from '../../branded/landing/loading-skeleton/package-selector';
import {getTermtypeBasedCopy} from '../../../utils/get-term-type-based-copy';
import {SCREEN_375_PHABLET} from '../../../common/screen-sizes';

const {ares, blanc, panther} = ionicPalette;
const {flash, fog} = infinityPalette;
const {coal, ironStone, black, kayoGreen, slate} = muiPalette;
const {lifestyle, onyx} = nucleusPalette;

const {
    SCREEN_415_PHABLET,
    SCREEN_768_TABLET,
    SCREEN_1024_DESKTOP,
    SCREEN_1280_DESKTOP,
    SCREEN_1920_DESKTOP,
} = screenSizes;

const {
    SCREEN_TABLET,
    SCREEN_LG_DESKTOP,
    SCREEN_PRETTY_LG_DESKTOP,
} = muiScreenSizes;

const MOBILE_WIDTH = '306px';
const DESKTOP_WIDTH = '850px';

/** Common width styling for the buttons and voucher form */
const commonWidthStyling = css`
    padding: 0 21px 7px;
    width: 100%;

    ${mediaQuery({minWidthPx: SCREEN_375_PHABLET})`
        max-width: 309px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        max-width: 400px;
    `}
`;

/** **********************************************************
 *       PACKAGE SELECTOR SECTION: The entire content section
 ************************************************************/

const StyledSection = styled(Section).attrs(({publishUrl}) => !!publishUrl && ({
    style: {
        background: `url('${publishUrl}') 50% 0 / cover no-repeat`,
    },
}))`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    z-index: 0;
    width: 100%;

    ${stylesWhenBinge`
        padding-top: 26px;
        padding-bottom: 38px;

        ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
            padding-top: 86px;
            padding-bottom: 112px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
            padding-top: 92px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            padding-top: 62px;
            padding-bottom: 80px;
        `}
    `}

    ${stylesWhenFlash`
        padding: 56px 0;

        ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
            padding: 86px 0;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
            padding: 92px 0;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            padding: 125px 0;
        `}
    `}

    ${stylesWhenKayo`
        background-color: ${ironStone};

        ${mediaQuery({minWidthPx: SCREEN_TABLET})`
            padding-top: 86px;
            padding-bottom: 112px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
            padding-top: 92px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_PRETTY_LG_DESKTOP})`
            padding-top: 62px;
            padding-bottom: 80px;
        `}

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(to bottom, ${coal}, transparent);
            width: 100%;
            height: 29px;
            content: '';

            ${mediaQuery({minWidthPx: SCREEN_TABLET})`
                height: 35px;
            `}

            ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
                height: 44px;
            `}
        }
    `}

    ${stylesWhenLifestyle`
        padding-top: 26px;
        padding-bottom: 38px;

        ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
            padding-top: 86px;
            padding-bottom: 112px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
            padding-top: 92px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            padding-top: 62px;
            padding-bottom: 80px;
        `}
    `}
`;

/** **********************************************************
 *       HEADER: Offer Display
 ************************************************************/

const StyledOR16MarBlkOfferDisplay = styled(BrandedOR16MarBlk)`
    ${stylesWhenNot(matchesProperty('brand', 'kayo'))`
        width: 100%;
        max-width: ${MOBILE_WIDTH};

        ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
            max-width: ${DESKTOP_WIDTH};
        `}
    `}
`;

/** *********************************************************
 *       VOUCHER BLOCK
 ************************************************************/

const StyledVoucherImg = styled(VoucherImg)`
    margin: 28px auto;
`;

const VoucherContainerAboveForm = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 27px;
    margin-bottom: 15px;
    padding: 0 7px;
    width: 100%;
    max-width: 600px;
`;

const ThinContainer = styled.div`
    ${commonWidthStyling}
`;

/** *********************************************************
 *       SUB-HEADER: Marketing Display
 ************************************************************/

const StyledOR16MarBlk = styled(BrandedOR16MarBlk)`
    margin-top: 20px;
    margin-bottom: 24px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        margin-top: 33px;
        margin-bottom: 47px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        margin-bottom: 50px;
    `}
`;

const MarketingDisplayHeader = styled.span`
    ${stylesWhenBinge`
        color: ${ares};
        font: var(--ionic-body-copy-2-bold);
    `}

    ${stylesWhenFlash`
        color: ${flash};
        font: var(--infinity-body-copy-3-bold);
    `}

    ${stylesWhenKayo`
        color: ${kayoGreen};
        font: var(--mui-body-copy-3-bold);
    `}

    ${stylesWhenLifestyle`
        color: ${lifestyle};
        font: var(--nucleus-body-copy-2-bold);
    `}
`;

/** *********************************************************
 *   PACKAGE SELECTOR & FEATURES LIST
 ************************************************************/

// PackageSelector + CM03FeatureList
// + footnote & continue button on mobile
const PackagesAndFeaturesContainer = styled.div`
    display: grid;
    grid-auto-flow: row;
    grid-gap: 35px;
    padding-bottom: 35px;

    ${mediaQuery({minWidthPx: SCREEN_415_PHABLET})`
        padding-left: 7px;
        padding-right: 7px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        grid-auto-flow: column;
        grid-gap: 38px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        padding-bottom: 56px;
    `}
`;

const StyledCM03FeatureList = styled(BrandedCM03FeatureList)`
    padding: 0 21px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        padding: 0;
    `}

    li {
        margin-bottom: 0; // experiencing a margin-buttom of 1rem between 'li's and this fixes it, something to do with aem global styling?! As it does not happen on offer page with no aem components.
    }
`;

/** **************************************************************
 *       CONTINUE & SWITCH TERMTYPE BUTTONS
 *****************************************************************/

const StyledBA01BtnPr = styled(BrandedBA01BtnPr)`
    margin: 0 auto;
    border-radius: 6px;

    ${stylesWhenBinge`
        box-shadow: 0 1px 1px 0 ${rgba(panther, 0.5)};
        height: 50px;
        line-height: 50px;
        font: var(--ionic-body-copy-3);
    `}

    ${stylesWhenKayo`
        box-shadow: 0 1px 1px 0 ${rgba(black, 0.5)};
        height: 50px;
        line-height: 50px;
    `}

    ${stylesWhenFlash`
        border-radius: 0;
    `}

    ${stylesWhenLifestyle`
        box-shadow: 0 1px 1px 0 ${rgba(onyx, 0.5)};
        height: 50px;
        line-height: 50px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 400px;
    `}

    ${stylesWhenNot(matchesProperty('brand', 'kayo'))`
        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            height: 80px;
            line-height: 80px;
        `}
    `}
`;

const StyledIC103Loading = styled(BrandedIC103Loading)`
    display: inline-block;
    vertical-align: middle;

    ${stylesWhenKayo`
        svg {
            fill: ${coal};
        };
    `}
`;

const SwitchBtnContent = styled.span`
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1;
    gap: 7px;
`;

/** **************************************************************
 *       FOOTNOTE CONTAINER
 *****************************************************************/

const FootnoteContainer = styled.div`
    margin: 0 auto;
    width: 100%;
    max-width: 415px;
    text-align: center;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        max-width: 768px;
    `}

    ${stylesWhenBinge`
        color: ${rgba(blanc, 0.7)};
        font: var(--ionic-header-8-light);
    `}

    ${stylesWhenFlash`
        color: ${rgba(fog, 0.7)};
        font: var(--infinity-header-8-light);
    `}

    ${stylesWhenKayo`
        color: ${rgba(slate, 0.7)};
        font: var(--mui-body-copy-3);
    `}

    ${stylesWhenLifestyle`
        color: ${rgba(blanc, 0.7)};
        font: var(--nucleus-header-8-light);
    `}
`;

/** **************************************************************
 *       TABLET DOWN & UP WRAPPERS
 * These wrappers show/hide certain components that need to be
 * rearranged in smaller and larger viewports
 *****************************************************************/
/** Common styling for wrappers  */
const wrapperCommonStyling = css`
    ${commonWidthStyling}
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: 0 auto;

    ${mediaQuery({minWidthPx: SCREEN_375_PHABLET})`
        padding-bottom: 14px;
    `}
`;

/** Show only when viewport is smaller than 768px */
const ShowOnTabletDownWrapper = styled.div`
    ${wrapperCommonStyling}
    gap: 7px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        display: none;
    `}
`;

/** Show only when viewport is 768px or larger */
const ShowOnTabletUpWrapper = styled.div`
    display: none;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        ${wrapperCommonStyling}
        display: flex;
        gap: 14px;
        padding-top: 14px;
    `}
`;

const ContentWrapper = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    max-width: ${VOUCHER_IMAGE_DESKTOP_MAX_WIDTH_PX};
`;

/** *********** MAIN ****************/

const PackageSelector = ({
    brand,
    moduleKey,
    isLoading,
    commonWidgetSettings,

    // from auth/billing
    billingProps,

    // from aem
    module,
    termType,

    // for adobe target
    adobeTargetParams,
}) => {
    const {clientSideFeatureFlags: {useAdobeTarget}} = commonWidgetSettings;

    const {
        offerDisplay = {},
        packageSelector,
        voucherDisplay = {},
        hasContinueButton,
        onContinue,
        isContinueLoading,
        isSignUpDisabled,
        footnote,
        onContinueWithoutVoucher,
    } = billingProps;

    const {imageAltText, imageLinkMobile, imageLinkDesktop} = get(voucherDisplay, 'voucherImages') || {};

    const {sectionTitle = '', alignment, copylines = [], copy, cta, id: moduleId, url: moduleUrl} = module;
    const {_publishUrl: publishUrl} = moduleUrl || {};

    const isKayo = brand === 'kayo';
    const OfferContentWrapper = isKayo ? ContentWrapper : Fragment;
    const onReadyForTargetRef = useRef();

    const getCopy = getTermtypeBasedCopy(termType);

    // For this module, we are repurposing
    // - cta as a href field and
    // - copy as cta copy field
    const hasPackageSwitchButtonCta = !!copy && !!cta;

    // footnote & container get rearranged in smaller & larger viewports
    // wrapping in a function so we dont repeat the same code
    const getFootnoteAndContinueBtn = () => (
        <Fragment>
            {!!footnote && (
                <FootnoteContainer brand={brand}>{footnote}</FootnoteContainer>
            )}

            {!!hasContinueButton && (
                <StyledBA01BtnPr
                    onClick={onContinue}
                    disabled={!!isContinueLoading}
                    brand={brand}
                    isBlock={true}
                >
                    {isContinueLoading
                        ? <StyledIC103Loading size="30px" brand={brand} />
                        : 'Continue'
                    }
                </StyledBA01BtnPr>
            )}

            {!!hasPackageSwitchButtonCta && (
                <StyledBA01BtnPr
                    href={cta}
                    brand={brand}
                    isBlock={true}
                >
                    <SwitchBtnContent>
                        <span>{copy}</span> <BrandedIC25ArrowR size="21px" brand={brand} />
                    </SwitchBtnContent>
                </StyledBA01BtnPr>
            )}
        </Fragment>
    );

    useEffect(function invokeOnReadyForTargetWhenLoaded() {
        if (!isLoading) {
            try {
                onReadyForTargetRef.current?.();
            } catch (e) {
                console.error('Error while running onReadyForTarget - ignoring');
            }
        }
    }, [isLoading]);

    const Component = (
        <StyledSection
            brand={brand}
            id="products"
            publishUrl={publishUrl}
        >
            <OfferContentWrapper>
                <StyledOR16MarBlkOfferDisplay
                    displayHeader={getCopy(offerDisplay.packageHeader)}
                    displaySubtext={getCopy(offerDisplay.packageSubtext)}
                    brand={brand}
                />
                {!isSignUpDisabled && (
                    <Fragment>
                        {!!(imageLinkMobile || imageLinkDesktop) && (
                            <StyledVoucherImg
                                imageAltText={imageAltText}
                                imageLinkMobile={imageLinkMobile}
                                imageLinkDesktop={imageLinkDesktop}
                            />
                        )}

                        {!!voucherDisplay.isVoucherRequiredTemplate && (
                            <VoucherContainerAboveForm>
                                <VoucherForm
                                    {...{
                                        brand,
                                        voucherDisplay,
                                        onContinueWithoutVoucher,
                                    }}
                                />
                            </VoucherContainerAboveForm>
                        )}

                        <StyledOR16MarBlk
                            brand={brand}
                            displaySubtext={(
                                <MarketingDisplayHeader brand={brand}>
                                    {sectionTitle}
                                </MarketingDisplayHeader>
                            )}
                        />

                        <PackagesAndFeaturesContainer>
                            <PackageSelectorTable
                                brand={brand}
                                {...packageSelector}
                            />

                            {/* This is where the footnote and continue button go on mobile. */}
                            {(!!hasContinueButton || !!footnote || hasPackageSwitchButtonCta) && (
                                <ShowOnTabletDownWrapper>
                                    {getFootnoteAndContinueBtn()}
                                </ShowOnTabletDownWrapper>
                            )}

                            <StyledCM03FeatureList brand={brand}>
                                {copylines.map((item, index) => (
                                    <BrandedCA01Feature key={index} brand={brand}>{item}</BrandedCA01Feature>
                                ))}
                            </StyledCM03FeatureList>

                        </PackagesAndFeaturesContainer>

                        {/* And yay this is where the footnote and continue button go on tablet and higher. */}
                        {(!!hasContinueButton || !!footnote || hasPackageSwitchButtonCta) && (
                            <ShowOnTabletUpWrapper hasContinueButton={hasContinueButton}>
                                {getFootnoteAndContinueBtn()}
                            </ShowOnTabletUpWrapper>
                        )}

                        {/* this voucher bit used to be separate to package selector content section of the offer page so if we want that to still
                        be the case in landing we need to ask for a voucher aem module type that covers this bit or we could just consider it now as
                        part of the PackageSelector component as it is done here - keeping it here though will hide it from the page as Package
                        Selector section loads which is in contrast with how offer page was done. */}
                        {!isSignUpDisabled && !voucherDisplay.isVoucherRequiredTemplate && termType !== 'annual' && (
                            <ThinContainer>
                                <VoucherForm
                                    {...{
                                        brand,
                                        voucherDisplay,
                                        onContinueWithoutVoucher,
                                    }}
                                />
                            </ThinContainer>
                        )}
                    </Fragment>
                )}
            </OfferContentWrapper>
        </StyledSection>
    );

    if (useAdobeTarget) {
        return (
            <TargetAemWrapper
                alignment={alignment}
                data-mdoule={moduleKey}
                brand={brand}
                loadingEl={<BrandedPackageSelectorLoadingSkeleton brand={brand} />}
                moduleId={moduleId}
                adobeTargetParams={adobeTargetParams}
                render={({onReadyForTarget}) => {
                    onReadyForTargetRef.current = onReadyForTarget;

                    return Component;
                }}
            />
        );
    } else {
        // Note: we won't have loading skeleton or spinner in this case but is ok as the feature flag
        // is temporary and only for development purposes
        return Component;
    }
};

PackageSelector.displayName = 'PackageSelector';

PackageSelector.propTypes = {
    /* Representing the props provided by billing api */
    billingProps: propTypes.shape({
        /** Footnote message usually used to display price/package change message */
        footnote: propTypes.node,
        /** Page header: `OR16MarBlkOfferDisplay` props */
        offerDisplay: propTypes.object,
        /** `PackageSelector` props */
        packageSelector: propTypes.object,
        /** Voucher images and toggle for `voucherForm` */
        voucherDisplay: propTypes.shape({
            voucherImages: propTypes.shape({
                imageAltText: propTypes.string,
                imageLinkMobile: propTypes.string,
                imageLinkDesktop: propTypes.string,
            }),
            /** Display voucherVorm? */
            isVoucherRequiredTemplate: propTypes.bool,
        }),
        /** Dispay continue button? */
        hasContinueButton: propTypes.bool,
        /** Callback when continue button is clicked */
        onContinue: propTypes.func,
        /** Is continue button loading? */
        isContinueLoading: propTypes.bool,
        /** If signup is disabled, we hide everything below page header */
        isSignUpDisabled: propTypes.bool,
        /** Callback for clicking on "Continue without voucher" button*/
        onContinueWithoutVoucher: propTypes.func,
        /** Callback for clicking the Join button on hero banner block */
        onClickOfferCta: propTypes.func,
    }).isRequired,
    /** Module key info for reference */
    moduleKey: propTypes.string,
    /** AEM Module object */
    module: aemModuleType,
    /** AEM term type (monthly | annual) */
    termType: termTypePropType,
    /** The brand, Flash | Kayo | Binge | Lifestyle */
    brand: brandPropType,
    /** Globalish settings object */
    commonWidgetSettings: propTypes.shape({ // @TODO: complete this shape later
        /** The brand, Flash | Kayo | Binge | Lifestyle */
        brand: brandPropType,
        /** The auth0 user object */
        user: propTypes.object,
        /** Handler helper for our SPA routing */
        newRouteHandler: propTypes.func,
        /** Object containing all the clientside feature flags */
        clientSideFeatureFlags: propTypes.object,
    }),
    /** Whether we're in a loading state */
    isLoading: propTypes.bool,
    /** Representing props calculated from user streams */
    userProps: propTypes.shape({
        /** Whether the user is authenticated or not */
        userIsAuthenticated: propTypes.bool,
        /** String to show on sign in button in landing page (not used in this component) */
        signInWords: propTypes.string,
        /** Callback for sign in action */
        onSignIn: propTypes.func,
        /** Used to show a loading spinner on sign in button as user streams come through */
        isSignInLoading: propTypes.bool,
        /** Callback if user clicks on resubscribe button (used in hero banner) */
        onResubscribe: propTypes.func,
    }).isRequired,
    /** Adobe Target Params */
    adobeTargetParams: propTypes.object,
};

export default PackageSelector;
