import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import {midnight} from '../../../../common/deprecated-mui-palette';

const StyledContainer = styled.div`
    box-sizing: border-box;
    background-color: ${rgba(midnight, 0.8)};
    padding: 21px 7px;
    min-height: 100%;
    object-fit: contain;
    backdrop-filter: blur(7px);
`;

const GA72HudBg = ({children}) => (
    <StyledContainer>
        {children}
    </StyledContainer>
);

GA72HudBg.propTypes = {
    /** Content e.g. text */
    children: propTypes.node,
};

GA72HudBg.displayName = 'GA72HudBg';

export default GA72HudBg;
