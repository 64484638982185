import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {Button} from 'normalized-styled-components';
import {mediaQuery, stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {transition} from '../../../../../common/animations';
import {blanc} from '../../../../../common/palette';
import {SCREEN_768_TABLET, SCREEN_1024_DESKTOP, SCREEN_1920_DESKTOP} from '../../../../../common/screen-sizes';
import {visuallyHiddenBaseStyles} from '../../../../../common/visually-hidden';

const StyledButton = styled(Button)`
    appearance: none;
    display: flex;
    align-items: center;
    transition: ${transition('opacity')};
    opacity: 0.7;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    color: ${blanc};

    &:focus,
    &:hover {
        opacity: 0.8;
        outline: none;
    }

    &:active,
    &[aria-pressed='true'] {
        opacity: 1;
    }
`;

const IconContainer = styled.div`
    width: 30px;
    height: 30px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: 40px;
        height: 40px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 70px;
        height: 70px;
    `}
`;

const labelBaseStyles = css`
    position: initial;
    margin-left: 7px;
    width: auto;
    height: auto;
    overflow: visible;
    font: var(--nucleus-player-controls);

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        margin-left: 14px;
    `}
`;

const ButtonLabel = styled.span`
    ${labelBaseStyles}

    ${stylesWhen('shouldHideOnMobile')`
        ${visuallyHiddenBaseStyles}

        ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
            ${labelBaseStyles}
        `}
    `}
`;

const VPBA01CtrlBtn = React.forwardRef(({
    'aria-label': ariaLabel,
    children,
    label,

    isDisabled = false,
    isPressed = false,
    shouldHideLabelOnMobile = false,

    onClick,
    onFocus,
    onMouseEnter,
    onMouseLeave,
    ...htmlAttributes
}, ref) => (
    <StyledButton
        {...htmlAttributes}
        aria-pressed={isPressed}

        disabled={isDisabled}
        title={label || ariaLabel}

        onClick={onClick}
        onFocus={onFocus}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}

        ref={ref}
    >
        <IconContainer>
            {children}
        </IconContainer>
        {!!label && (
            <ButtonLabel shouldHideOnMobile={shouldHideLabelOnMobile}>{label}</ButtonLabel>
        )}
    </StyledButton>
));

/* eslint-disable quote-props */
VPBA01CtrlBtn.propTypes = {
    /** Assistive label */
    'aria-label': propTypes.string,
    /** Content */
    children: propTypes.node,
    /** Title */
    label: propTypes.string,
    /** Is disabled? */
    isDisabled: propTypes.bool,
    /** Is pressed? */
    isPressed: propTypes.bool,
    /** Whether to hide labels on mobile viewports */
    shouldHideLabelOnMobile: propTypes.bool,

    /** Function to execute when button is clicked */
    onClick: propTypes.func,
    /** Function to execute when button is focused */
    onFocus: propTypes.func,
    /** Function to execute when on mouse enter */
    onMouseEnter: propTypes.func,
    /** Function to execute when on mouse leave */
    onMouseLeave: propTypes.func,
};

VPBA01CtrlBtn.displayName = 'VPBA01CtrlBtn';

export default VPBA01CtrlBtn;
