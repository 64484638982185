import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {Button} from 'normalized-styled-components';
import noop from 'lodash/noop';
import classnames from 'classnames';

import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {kayoGreen, coal, white} from '../../../../common/palette';
import IC31Tick from '../../ic/31-tick';

const CHECKMARK_SIZE_PX = 15;
const FOCUS_BORDER_WIDTH_PX = 2;
const CHECKMARK_OFFSET_HOVER_PX = (CHECKMARK_SIZE_PX / 2) - (FOCUS_BORDER_WIDTH_PX / 2);

const baseStyle = css`
    box-sizing: border-box;
    position: relative;
    transition: ${transition('border-color', 'background-color')};
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: transparent;
    padding: 0;
    width: ${({isBlock}) => isBlock ? '100%' : '98px'};
    height: ${({isBlock}) => isBlock ? '100%' : '98px'};
    color: ${white};

    ${stylesWhen('isLabelVisible')`
        margin-bottom: 40px;
    `}

    ${stylesWhen('isNavIndicatorOnHover')`
        margin-bottom: 8px;

        &::after {
            position: absolute;
            right: 16px;
            bottom: -10px;
            left: 16px;
            transition: ${transition('background-color')};
            border-radius: 2px;
            background-color: transparent;
            height: 4px;
            content: '';
        }

        ${stylesWhen('isNavIndicatorOn')`
            &::after {
                background-color: ${kayoGreen};
            }
        `}

        &:focus,
        &:hover {
            &::after {
                background-color: ${kayoGreen};
            }
        }
    `}

    &[aria-pressed='true'],
    &[aria-current='true'] {
        background-color: ${coal};
    }

    &:focus,
    &:hover {
        position: relative;
        outline: 0;
        border-color: ${kayoGreen};
        background-color: ${coal};
    }
`;

const StyledButton = styled(Button).attrs(({isPressed}) => ({
    'type': 'button',
    'aria-pressed': isPressed,
    'style': {
        font: 'var(--mui-body-copy-2)',
    },
}))`
    ${baseStyle};
    appearance: none;
`;

const StyledLink = styled.a.attrs(({isPressed}) => ({
    'aria-current': isPressed,
    'style': {
        font: 'var(--mui-body-copy-2)',
    },
}))`
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    ${baseStyle};
`;

const Checkedmark = styled.span`
    position: absolute;
    top: -${CHECKMARK_OFFSET_HOVER_PX}px;
    right: -${CHECKMARK_OFFSET_HOVER_PX}px;
    transform: scale(${({isPressed}) => isPressed ? '100%' : 0});
    transition: ${transition('transform')};
    width: ${CHECKMARK_SIZE_PX}px;
    height: ${CHECKMARK_SIZE_PX}px;
`;

const VisibleLabel = styled.span.attrs({
    style: {
        font: 'var(--mui-body-copy-6)',
    },
})`
    position: absolute;
    right: 7px;
    bottom: -35px;
    left: 7px;
    margin: 0;
    overflow: hidden; /* not just -x, saves FOPFR: Flash of Pre-Font Rendering (vertical scroll appears for a split second) */
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${white};
`;

const BA04TeamTi = React.forwardRef(({
    'aria-label': assistiveLabel,
    isBlock,
    isPressed,
    children,
    href,
    isLabelVisible = false,
    isNavIndicatorOn,
    isNavIndicatorOnHover,
    className,
    onClick = noop,
    pressedIndicator = 'checkmark',
    ...htmlAttributes
}, ref) => {
    const StyledCta = href ? StyledLink : StyledButton;
    const showNavIndicator = isNavIndicatorOn || (isPressed && pressedIndicator === 'nav-indicator');

    return (
        <StyledCta
            {...htmlAttributes}
            {...{
                href,
                ref,
                isBlock,
                isLabelVisible,
                isPressed,
                onClick,
            }}
            className={classnames('BA04TeamTi', className)}
            title={isLabelVisible ? null : assistiveLabel}
            aria-label={isLabelVisible ? null : assistiveLabel}
            isNavIndicatorOnHover={showNavIndicator || isNavIndicatorOnHover}
            isNavIndicatorOn={showNavIndicator}
        >
            {pressedIndicator === 'checkmark' && (
                <Checkedmark isPressed={isPressed}>
                    <IC31Tick />
                </Checkedmark>
            )}
            {!!isLabelVisible && <VisibleLabel>{assistiveLabel}</VisibleLabel>}
            {children}
        </StyledCta>
    );
});

/* eslint-disable quote-props */
BA04TeamTi.propTypes = {
    /** Assistive label required for screen readers due to graphical only representation of content */
    'aria-label': propTypes.string.isRequired,
    /** Hyperlink URL */
    href: propTypes.string,
    /** aria-pressed attribute describes a toggle button */
    isPressed: propTypes.oneOf([true, false, 'mixed']),
    /** Content of button, e.g. text */
    children: propTypes.node.isRequired,
    /** Whether the button fills its parent's width or not */
    isBlock: propTypes.bool,
    /** Whether to display label visually, or just for assistive devices  */
    isLabelVisible: propTypes.bool,
    /** Whether to use GA04-Nav-Ind on hover state (e.g. true for sport/series, false for team) */
    isNavIndicatorOnHover: propTypes.bool,
    /** If true - force display of nav indicator, regardless of hover state */
    isNavIndicatorOn: propTypes.bool,
    /** How to represent the "pressed" state of this component */
    pressedIndicator: propTypes.oneOf(['checkmark', 'nav-indicator', 'none']),
    /** Callback to call on element click */
    onClick: propTypes.func,
    /** additional CSS classnames to be applied */
    className: classNameType,
};
/* eslint-enable quote-props */

BA04TeamTi.displayName = 'BA04TeamTi';

export default BA04TeamTi;
