import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {A, Button} from 'normalized-styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';

import {mediaQuery, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {buttonType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {blanc, panther, vader} from '../../../../common/palette';
import {SCREEN_1680_DESKTOP, SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';

const baseStyles = css`
    box-sizing: border-box;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    transform-origin: left center;
    transition: ${transition('background-color', 'opacity', 'color', 'text-shadow')};
    box-shadow: 0 1px 1px 0 ${rgba(panther, 0.3)};
    background-color: ${rgba(blanc, 0.2)};
    padding: 0 21px;
    height: 40px;
    overflow: hidden;
    text-align: center;
    text-shadow: 0 1px 1px ${rgba(panther, 0.3)};
    white-space: nowrap;
    color: ${blanc};
    font: var(--ionic-primary-button);

    ${({isBlock}) => isBlock ? css`
        display: flex;
        width: 100%;
    ` : css`
        display: inline-flex;
        min-width: 110px;

        ${stylesWhenNot('theme.isAppDock')`
            ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
                min-width: 183px;
            `}
        `}
    `}

    ${stylesWhenNot('theme.isAppDock')`
        ${mediaQuery({minWidthPx: SCREEN_1680_DESKTOP})`
            height: 50px;
            padding: 0 28px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            height: 60px;
        `}
    `}

    &:hover,
    &:focus {
        outline: 0;
        background-color: ${rgba(blanc, 0.8)};
        text-shadow: none;
        color: ${vader};
    }

    &:disabled {
        background-color: ${rgba(blanc, 0.1)};
        cursor: not-allowed;
        color: ${rgba(blanc, 0.1)};
    }

    &:active {
        background-color: ${blanc};
        text-shadow: none;
        color: ${vader};
    }
`;

const StyledButton = styled(Button)`
    ${baseStyles}

    appearance: none;
    border: 0;
`;

const StyledLink = styled(A)`
    ${baseStyles}

    text-decoration: none;
`;

const BA01BtnPr = React.forwardRef(({
    children,
    className,
    disabled,
    href,
    isBlock,
    onClick,
    type = 'button',
    ...htmlAttributes
}, ref) => {
    // coerce disabled CTAs into being buttons
    const StyledElement = (href && !disabled) ? StyledLink : StyledButton;

    return (
        <StyledElement
            {...htmlAttributes}
            {...{
                ref,
                href,
                isBlock,
                onClick,
                type: href ? undefined : type,
            }}
            className={classnames('BA01BtnPr', className)}
            disabled={disabled}
            href={href}
        >
            {children}
        </StyledElement>
    );
});

BA01BtnPr.propTypes = {
    /** Content of button, e.g. text */
    children: propTypes.node.isRequired,
    /** additional CSS classnames to be applied */
    className: classNameType,
    /** Is this button disabled? */
    disabled: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    /** If it’s actually a link, provide a href */
    href: propTypes.string,
    /** Whether the button fills its parent's width or not */
    isBlock: propTypes.bool,
    /** Callback on element click */
    onClick: propTypes.func,
    /** The HTML type of the button */
    type: buttonType,
};

BA01BtnPr.displayName = 'BA01BtnPr';

export default BA01BtnPr;
