/* eslint-disable max-len */

import React from 'react';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {blanc, vader} from '../../../../common/palette';
import Ic from '../../ic';

const GA85Apple = ({
    className,
    ...htmlAttributes
}) => (
    <Ic
        {...htmlAttributes}
        className={classnames('GA85Apple', className)}
        ariaLabel="Apple"
        preserveColors={true}
    >
        <g fill="none" fillRule="evenodd">
            <rect width="100" height="92.5" fill={blanc} rx="2" />
            <g transform="scale(2.5)" fill={vader}>
                <path d="M25.7 17.81c.03 3.29 2.87 4.38 2.9 4.4-.02.08-.45 1.56-1.5 3.1-.9 1.32-1.83 2.63-3.3 2.66-1.45.03-1.92-.86-3.57-.86-1.66 0-2.17.83-3.54.89-1.43.05-2.5-1.43-3.42-2.75-1.85-2.7-3.27-7.63-1.37-10.96a5.29 5.29 0 014.48-2.72c1.4-.03 2.71.94 3.56.94.86 0 2.46-1.16 4.14-1 .7.04 2.68.3 3.96 2.16-.1.07-2.37 1.39-2.34 4.14" />
                <path d="M23.05 9.8A5.6 5.6 0 0024.18 6c-1.1.05-2.42.8-3.2 1.8a5.3 5.3 0 00-1.15 3.7c1.22.1 2.46-.69 3.22-1.7" />
            </g>
        </g>
    </Ic>
);

GA85Apple.propTypes = {
    /** Additional class(es) */
    className: classNameType,
};

GA85Apple.displayName = 'GA85Apple';

export default GA85Apple;
