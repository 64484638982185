import React from 'react';
import Ic from '..';

const IC26ArrowU = (args) => (
    <Ic {...args}>
        <path d="M83.788 72.274l-34.1-34.1-34.101 34.1L10 66.688 49.688 27l39.687 39.688z" />
    </Ic>
);

IC26ArrowU.displayName = 'IC26ArrowU';

export default IC26ArrowU;
