import React from 'react';
import Ic from '..';

const IC24ArrowL = (args) => (
    <Ic {...args}>
        <path fillRule="nonzero" d="M64.542 89.276 25 50.138 64.542 11l5.567 5.51-33.976 33.628 33.976 33.628z" />
    </Ic>
);

IC24ArrowL.displayName = 'IC24ArrowL';

export default IC24ArrowL;
