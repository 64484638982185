/* eslint-disable react/jsx-pascal-case, quote-props */
import React from 'react';

// Flash / infinity only have 1 icon component in common
// Flash current config does not use icons in the devices or features carousel
// Defaulting Flash to use ionic based components
import {
    IC03NotHeadsUpMarketing as IC03NotHeadsUpMarketing__ionic,
    IC39ScoreMarketing as IC39ScoreMarketing__ionic,
    IC59Laptop as IC59Laptop__ionic,
    IC60Android as IC60Android__ionic,
    IC61Ios as IC61Ios__ionic,
    IC62Chrome as IC62Chrome__ionic,
    IC63SmartTv as IC63SmartTv__ionic,
    IC64TelstraTv as IC64TelstraTv__ionic,
    IC66AppleTv as IC66AppleTv__ionic,
    IC145HD as IC145HD__ionic,
    IC175Casting as IC175Casting__ionic,
    IC178PlaylistsMarketing as IC178PlaylistsMarketing__ionic,
    IC179Roku as IC179Roku__ionic,
    IC183TabletMobile as IC183TabletMobile__ionic,
    IC257Discover as IC257Discover__ionic,
    IC258SurpriseMe as IC258SurpriseMe__ionic,
    IC259BingeCentres as IC259BingeCentres__ionic,
} from '@fsa-streamotion/streamotion-web-ionic';

import {
    IC03NotHeadsUpMarketing as IC03NotHeadsUpMarketing__mui,
    IC39ScoreMarketing as IC39ScoreMarketing__mui,
    IC554UpMarketing as IC554UpMarketing__mui,
    IC59Laptop as IC59Laptop__mui,
    IC60Android as IC60Android__mui,
    IC61Ios as IC61Ios__mui,
    IC62Chrome as IC62Chrome__mui,
    IC63SmartTv as IC63SmartTv__mui,
    IC64TelstraTv as IC64TelstraTv__mui,
    IC66AppleTv as IC66AppleTv__mui,
    IC111KeyMomMarketing as IC111KeyMomMarketing__mui,
    IC145HD as IC145HD__mui,
    IC175Casting as IC175Casting__mui,
    IC176InteractiveStats as IC176InteractiveStats__mui,
    IC177MatchCentre as IC177MatchCentre__mui,
    IC178PlaylistsMarketing as IC178PlaylistsMarketing__mui,
    IC179Roku as IC179Roku__mui,
    IC183TabletMobile as IC183TabletMobile__mui,
} from '@fsa-streamotion/streamotion-web-mui';

import {
    IC03NotHeadsUpMarketing as IC03NotHeadsUpMarketing__nucleus,
    IC39ScoreMarketing as IC39ScoreMarketing__nucleus,
    IC59Laptop as IC59Laptop__nucleus,
    IC60Android as IC60Android__nucleus,
    IC61Ios as IC61Ios__nucleus,
    IC62Chrome as IC62Chrome__nucleus,
    IC63SmartTv as IC63SmartTv__nucleus,
    IC64TelstraTv as IC64TelstraTv__nucleus,
    IC66AppleTv as IC66AppleTv__nucleus,
    IC145HD as IC145HD__nucleus,
    IC175Casting as IC175Casting__nucleus,
    IC178PlaylistsMarketing as IC178PlaylistsMarketing__nucleus,
    IC179Roku as IC179Roku__nucleus,
    IC183TabletMobile as IC183TabletMobile__nucleus,
    IC257Discover as IC257Discover__nucleus,
    IC258SurpriseMe as IC258SurpriseMe__nucleus,
    IC259BingeCentres as IC259BingeCentres__nucleus,
} from '@fsa-streamotion/streamotion-web-nucleus';

export default {
    'binge': {
        'headsup':            <IC03NotHeadsUpMarketing__ionic />,
        'laptop':             <IC59Laptop__ionic />,
        'android':            <IC60Android__ionic />,
        'ios':                <IC61Ios__ionic />,
        'chrome':             <IC62Chrome__ionic />,
        'smarttv':            <IC63SmartTv__ionic />,
        'telstratv':          <IC64TelstraTv__ionic />,
        'appletv':            <IC66AppleTv__ionic />,
        'hd':                 <IC145HD__ionic />,
        'chromecast':         <IC175Casting__ionic />,
        'nospoilers':         <IC39ScoreMarketing__ionic />,
        'playlists':          <IC178PlaylistsMarketing__ionic />,
        'roku':               <IC179Roku__ionic />,
        'tabletmobile':       <IC183TabletMobile__ionic />,
        'discover':           <IC257Discover__ionic />,
        'surprise-me':        <IC258SurpriseMe__ionic />,
        'binge-centres':      <IC259BingeCentres__ionic />,
    },
    'flash': {
        'headsup':            <IC03NotHeadsUpMarketing__ionic />,
        'laptop':             <IC59Laptop__ionic />,
        'android':            <IC60Android__ionic />,
        'ios':                <IC61Ios__ionic />,
        'chrome':             <IC62Chrome__ionic />,
        'smarttv':            <IC63SmartTv__ionic />,
        'telstratv':          <IC64TelstraTv__ionic />,
        'appletv':            <IC66AppleTv__ionic />,
        'hd':                 <IC145HD__ionic />,
        'chromecast':         <IC175Casting__ionic />,
        'nospoilers':         <IC39ScoreMarketing__ionic />,
        'playlists':          <IC178PlaylistsMarketing__ionic />,
        'roku':               <IC179Roku__ionic />,
        'tabletmobile':       <IC183TabletMobile__ionic />,
        'discover':           <IC257Discover__ionic />,
        'surprise-me':        <IC258SurpriseMe__ionic />,
        'binge-centres':      <IC259BingeCentres__ionic />,
    },
    'kayo': {
        'headsup':            <IC03NotHeadsUpMarketing__mui />,
        'nospoilers':         <IC39ScoreMarketing__mui />,
        'splitview':          <IC554UpMarketing__mui />,
        'laptop':             <IC59Laptop__mui />,
        'android':            <IC60Android__mui />,
        'ios':                <IC61Ios__mui />,
        'chrome':             <IC62Chrome__mui />,
        'smarttv':            <IC63SmartTv__mui />,
        'telstratv':          <IC64TelstraTv__mui />,
        'appletv':            <IC66AppleTv__mui />,
        'keymoments':         <IC111KeyMomMarketing__mui />,
        'hd':                 <IC145HD__mui />,
        'chromecast':         <IC175Casting__mui />,
        'interactivestats':   <IC176InteractiveStats__mui />,
        'matchcentre':        <IC177MatchCentre__mui />,
        'playlists':          <IC178PlaylistsMarketing__mui />,
        'roku':               <IC179Roku__mui />,
        'tabletmobile':       <IC183TabletMobile__mui />,
    },
    'lifestyle': {
        'headsup':            <IC03NotHeadsUpMarketing__nucleus />,
        'laptop':             <IC59Laptop__nucleus />,
        'android':            <IC60Android__nucleus />,
        'ios':                <IC61Ios__nucleus />,
        'chrome':             <IC62Chrome__nucleus />,
        'smarttv':            <IC63SmartTv__nucleus />,
        'telstratv':          <IC64TelstraTv__nucleus />,
        'appletv':            <IC66AppleTv__nucleus />,
        'hd':                 <IC145HD__nucleus />,
        'chromecast':         <IC175Casting__nucleus />,
        'nospoilers':         <IC39ScoreMarketing__nucleus />,
        'playlists':          <IC178PlaylistsMarketing__nucleus />,
        'roku':               <IC179Roku__nucleus />,
        'tabletmobile':       <IC183TabletMobile__nucleus />,
        'discover':           <IC257Discover__nucleus />,
        'surprise-me':        <IC258SurpriseMe__nucleus />,
        'binge-centres':      <IC259BingeCentres__nucleus />,
    },
};
