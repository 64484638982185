import get from 'lodash/get';
import set from 'lodash/set';
import {isServer} from '@fsa-streamotion/browser-utils';
import getResourcesAsset from '../../streams/endpoints/resources';
import {str2ab, ab2HexString} from '../array-buffer';
import {ADOBE_ANALYTICS_DEFINITIONS_PATH} from '../constants';

let definitionsConfigPromise = null;

// Start downloading the definitions config so it's ready when we want to track events
// See https://resources.streamotion.com.au/staging/binge/analytics/adobe-analytics-definitions.json
export function initialiseAdobeDefinitionsConfig({resourcesEnv, brand}) {
    if (isServer()) {
        return;
    }

    if (!definitionsConfigPromise) {
        // If we haven't begun fetching the asset, start now
        definitionsConfigPromise = getResourcesAsset({
            brand,
            resourcesEnv,
            path: ADOBE_ANALYTICS_DEFINITIONS_PATH[brand], // Kayo config is deviated from Binge and Flash, hence this unique path.
        })
            .mapError({})
            .toPromise();
    }

    return definitionsConfigPromise;
}

// See https://resources.streamotion.com.au/staging/binge/analytics/adobe-analytics-definitions.json
// This is mostly split into a function for ease of testing, and to improve logging when it's missing
function getDefinitionsConfig() {
    if (!definitionsConfigPromise) {
        console.error('Attempted to access definitions config before it had been initialised.');

        return;
    }

    return definitionsConfigPromise;
}

export async function getTemplatisedPayload({
    definitionsPath,
    templateValues = {},
}) {
    if (isServer()) {
        return;
    }

    // The definitions config has to be asynchronously fetched
    const definitionsConfig = await getDefinitionsConfig();
    const templatisedPayload = get(definitionsConfig, definitionsPath);

    return Object.entries(templatisedPayload || {})
        .reduce((curr, [path, templateString]) => set(curr, path, interpolateTemplateValues(templateString, templateValues)), {});
}

/**
 * Tracks any generic event that is defined in the definitions file at https://resources.streamotion.com.au/staging/binge/analytics/adobe-analytics-definitions.json
 *
 * @param {Object} options                      - See below
 * @param {string} options.definitionsPath      - A path to a payload template in the definitions file, e.g. screenTracking.content.home
 * @param {string} options.eventType            - Event type, e.g. 'screenload'
 * @param {Object} [options.templateValues]     - An object of key -> string, defining things that could be interpolated into payload templates, e.g. {offerCode: '10OFF'}
 * @param {string} options.eventTypePayloadKey  - If the event type is stored as a value in payload
 *                                                you can pass payload path so that value will be used instead of eventType, e.g. 'data.event.name'
 * @returns {undefined}                         - No return value
 */
export async function trackFromAdobeDefinitions({ // eslint-disable-line import/prefer-default-export
    definitionsPath,
    templateValues,
    eventType,
    eventTypePayloadKey,
}) {
    const templatisedPayload = await getTemplatisedPayload({
        definitionsPath,
        templateValues,
    });

    if (!templatisedPayload) {
        // Couldn't find this line in the config? Let the data team and devs know
        console.debug(`Couldn't track "${eventType || eventTypePayloadKey}" event with Adobe Satellite because there is no entry in the definitions config for "${definitionsPath}"`);  // eslint-disable-line no-console

        return;
    }

    callAdobeSatelliteTrack(eventType || get(templatisedPayload, eventTypePayloadKey), templatisedPayload);
}

/**
 * Send a tracking event to Adobe via Satellite
 *
 * @param {string} eventType - Event type, e.g. 'screenload'
 * @param {Object} payload   - Some payload, ideally defined by the config file at https://resources.streamotion.com.au/staging/binge/analytics/adobe-analytics-definitions.json
 * @returns {undefined}
 */
export function callAdobeSatelliteTrack(eventType, payload) {
    if (isServer()) {
        return;
    }

    // Log this tracking event to the console so it's easier for the data team (and us) to debug
    console.debug(`window._satellite.track('${eventType}', ${JSON.stringify(payload, null, 2)})`); // eslint-disable-line no-console
    try {
        window._satellite.track(eventType, payload);
    } catch (_) {
        // Do nothing, fail silently
    }
}

/**
 * @example interpolateTemplateValues("Hi ${foo}, I'm ${bar.baz}", {foo: 'World', bar: {baz: 'Hal'}}) -> "Hi World, I'm Hal"
 *
 * @param {string} templateString - The string to be interpolated, e.g. "Hi ${foo}, I'm ${bar.baz}"
 * @param {Object} [values]       - Definitions to be interpolated into the string, e.g. {foo: 'World', bar: {baz: 'Hal'}}
 * @returns {string}              - The interpolated string
 */
function interpolateTemplateValues(templateString, values) {
    // Only operate on strings
    if (typeof templateString !== 'string' || !values) {
        return templateString;
    }

    // From "Hi ${foo}, I'm ${bar}" produce an array like ['${foo}', '${bar}']
    const specialEntries = templateString.match(/\${([a-z_A-Z0-9.\-\[\]]+)}/g);

    if (!Array.isArray(specialEntries)) {
        return templateString;
    }

    return specialEntries.reduce((currStr, specialEntry) => {
        // specialEntry: ${foo} -> dataKey: foo
        const dataKey = specialEntry.substr(2, specialEntry.length - 3);

        // Intentionally allow dataKey to represent a path with dots
        // Intentionally chuck in an empty string if we don't have the replacement
        const replacementValue = get(values, dataKey, '');

        return currStr.replace(specialEntry, replacementValue);
    }, templateString);
}

/**
 * Returns hashed user email for analytics object.
 *
 * If used in an insecure context (http) then all of the functions
 * on crypto are undefined. This will only work in secure (https) contexts.
 *
 * @param {string} email User email
 *
 * @returns {Promise} Hashed email
 */
export async function getEmailHash(email) {
    if (!email || !get(window, 'crypto.subtle')) {
        return;
    }

    return window.crypto.subtle.digest('SHA-256', str2ab(email.toLowerCase()))
        .then((arrayBufferDigest) => ab2HexString(arrayBufferDigest));
}
