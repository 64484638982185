import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import noop from 'lodash/noop';
import {white} from '../../../../../common/palette';
import VPBA01CtrlBtn from '../../../../atoms/vp/ba/01-ctrl-btn';
import VPIA01VertRange from '../../../../atoms/vp/ia/01-vert-range';

import {IC21Mute, IC04Vol} from '../../../../atoms/ic';

const VolumeControlContainer = styled.div`
    position: relative;
    width: 44px;
`;

export default class VPIM01InputVol extends React.Component {
    static displayName = 'VPIM01InputVol';

    static propTypes = {
        volume: propTypes.number,
        isTabletUp: propTypes.bool,
        isMuted: propTypes.bool,

        isIos: propTypes.bool,
        isMsEdge: propTypes.bool,

        onFocus: propTypes.func,
        setMutedFunction: propTypes.func,
        setVolumeFunction: propTypes.func,
        onKeyDown: propTypes.func,
    };

    static defaultProps = {
        volume: 1,
        isTabletUp: true,
        isMuted: true,

        isIos: false,
        isMsEdge: false,

        onFocus: noop,
        setMutedFunction: noop,
        setVolumeFunction: noop,
    };

    state = {
        isHovered: false,
        isTouchOpened: false,
        isKeyboardFocused: false,
    };

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyDown);
        this.containerRef.addEventListener('touchstart', this.handleTouchStart);
        this.containerRef.addEventListener('mouseenter', this.handleMouseEnter);
        this.containerRef.addEventListener('mouseleave', this.handleMouseLeave);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
        this.containerRef.removeEventListener('touchstart', this.handleTouchStart);
        this.containerRef.removeEventListener('mouseenter', this.handleMouseEnter);
        this.containerRef.removeEventListener('mouseleave', this.handleMouseLeave);
    }

    clearTimeouts = [];

    handleTouchStart = (event) => {
        this.recentlyTouched = true;

        setTimeout(() => {
            this.recentlyTouched = false;
        });

        if (this.props.isIos) {
            // Prevent default if ios - only allow mute/unmute
            event.preventDefault();
            this.props.setMutedFunction(!this.props.isMuted);
        } else if (this.volumeButtonRef === event.target || this.volumeButtonRef.contains(event.target)) {
            // Only prevent the default if we're on the icon to open/close.
            event.preventDefault();
            this.setState((prevState) => ({isTouchOpened: !prevState.isTouchOpened}));
        }
    };

    handleMouseEnter = () => {
        this.setState({isHovered: true});
    };

    handleMouseLeave = () => {
        this.setState({isHovered: false});
    };

    handleVolumeButtonClick = (event) => {
        event.preventDefault();

        if (this.props.isMsEdge) {
            this.handleTouchStart(event);
        } else if (!this.recentlyTouched) {
            this.props.setMutedFunction(!this.props.isMuted);
        }
    };

    handleKeyDown = (event) => {
        event.stopPropagation(); // prevent window scroll
        const checkVolumeContainsFocus = () => this.containerRef && this.containerRef.contains(document.activeElement); // work out if the keypress "relates" to the volume control by checking it currently contains focus

        switch (event.key) {
            case 'Tab':
                this.clearTimeouts.push(
                    setTimeout(() => void this.setState({isKeyboardFocused: checkVolumeContainsFocus()}))
                ); // wait a tick for the tab event to take effect
                break;

            case 'Escape':
                this.setState({isKeyboardFocused: false});
                break;

            default:
                break;
        }
    };

    render() {
        const volumeVisible = this.state.isHovered || this.state.isTouchOpened || this.state.isKeyboardFocused;

        return (
            <VolumeControlContainer
                ref={(el) => { this.containerRef = el; }}
                onKeyDown={this.props.onKeyDown}
            >
                <VPIA01VertRange
                    value={this.props.volume}
                    label="Adjust volume level"
                    tabIndex={-1}
                    isDisabled={this.props.isMuted}
                    isVisible={volumeVisible}
                    isTabletUp={this.props.isTabletUp}
                    onFocus={this.props.onFocus}
                    onValueChange={this.props.setVolumeFunction}
                />

                <VPBA01CtrlBtn
                    ref={(el) => { this.volumeButtonRef = el; }}
                    isPressed={this.props.isMuted}
                    label={this.props.isMsEdge ? 'Adjust volume level' : 'Toggle video mute'} // can't toggle mute on MS Edge
                    onFocus={this.props.onFocus}

                    onClick={this.handleVolumeButtonClick}
                    onDoubleClick={(event) => {
                        // Avoiding double-taps.
                        event.preventDefault();
                        event.stopPropagation();
                        event.stopImmediatePropagation();
                    }}
                >
                    {this.props.isMuted ? <IC21Mute color={white} /> : <IC04Vol color={white} volumePercentage={this.props.volume} />}
                </VPBA01CtrlBtn>
            </VolumeControlContainer>
        );
    }
}
