// https://app.zeplin.io/project/649b986a01c4f2231823fcf1

// Core colors
export const lifestyle = '#fea918';
export const blanc = '#fafafa';
export const onyx = '#000000';

// Dark/Natural/Light
export const midnight = '#050a19';
export const ink = '#050f23';
export const white = '#ffffff';

// Typography
export const cloudy = '#e6ebf0';
export const mist = '#c8d2dc';
export const smoke = '#dedede';
export const slate = '#5b5d68';
export const raven = '#181d2b';

// Gradient
export const poseidon = '#050f23';
export const sunset = '#f01235';

// Contextual
export const crimson = '#d0021b';
export const scarlet = '#f01235';

export const active = '#0f559e';

/**
 * @deprecated Removed as part of branding release. Use midnight for now.
 */
export const kong = midnight;

/** LifeStyle Palette */
export default {
    lifestyle,
    blanc,
    onyx,
    midnight,
    ink,
    white,
    cloudy,
    mist,
    smoke,
    slate,
    raven,
    poseidon,
    sunset,
    crimson,
    scarlet,
    active,
    kong,
};
