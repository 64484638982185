import React, {useEffect, useRef, useState} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Button} from 'normalized-styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';
import {format, addDays, isBefore, isSameDay, isToday} from 'date-fns';
import get from 'lodash/get';
import invoke from 'lodash/invoke';
import noop from 'lodash/noop';
import scrollIntoView from 'scroll-into-view-if-needed';

import {stylesIfElse, stylesWhen, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {colorType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {black, ironStone, kayoGreen, silverLining, white} from '../../../../common/palette';
import IC24ArrowL from '../../../atoms/ic/24-arrow-l';
import IC25ArrowR from '../../../atoms/ic/25-arrow-r';
import {transition} from '../../../../common/animations';

// styled component, so we override the fill property
const ArrowLeft = styled(IC24ArrowL)``;
const ArrowRight = styled(IC25ArrowR)``;

const CarouselWrapper = styled.nav`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
`;

const ScrollButton = styled(Button).attrs({
    tabIndex: -1,
})`
    appearance: none;
    display: flex;
    position: relative;
    justify-content: ${({isRight}) => isRight ? 'flex-end' : 'flex-start'};
    order: ${({isRight}) => isRight ? 3 : 1};
    outline: 0;
    border: 0;
    background: transparent;
    padding-bottom: 7px;

    ${stylesWhen('isHidden')`
        visibility: hidden;
    `}

    /* https://github.com/stylelint/stylelint/issues/3391 */
    /* stylelint-disable-next-line */
    &:hover ${ArrowLeft},
    &:hover ${ArrowRight} {
        fill: ${kayoGreen};
    }

    &::after {
        position: absolute;
        background-image: ${({backgroundColor, isRight}) => `linear-gradient(to ${isRight ? 'left' : 'right'}, ${backgroundColor} 30%, ${rgba(backgroundColor, 0)})`};
        width: 50px;
        height: 100%;
        content: '';

        ${stylesIfElse('isRight')`
            left: -50px;
        ``
            right: -50px;
        `}
    }
`;

const ItemsWrapper = styled.ol`
    display: flex;
    flex-direction: row;
    order: 2;
    margin: 0;
    padding: 0;
    width: calc(100%);
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    list-style: none;
    scroll-snap-stop: always;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::before,
    &::after {
        min-width: 50%;
        content: '';
        pointer-events: none;
        scroll-snap-align: none;
    }

    &::-webkit-scrollbar {
        display: none;
    }
`;

const NavItem = styled(Button)`
    appearance: none;
    position: relative;
    transition: ${transition('color', 'text-shadow')};
    margin: 0 8px;
    outline: 0;
    border: 0;
    background: transparent;
    max-width: 120px;
    height: 36px;
    overflow: hidden;
    text-transform: uppercase;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${({isSelected}) => isSelected ? white : silverLining};
    font: var(--mui-body-copy-3);
    scroll-snap-align: center;

    ${stylesWhenNot('isSelected')`
        text-shadow: 0 1px 1px ${black};
        font-weight: 300;
    `}

    &:hover,
    &:focus {
        text-shadow: none;
        color: ${white};
    }

    &::after {
        position: absolute;
        right: 4px;
        bottom: 0;
        left: 4px;
        transition: ${transition('transform')};
        border-radius: 1px;
        background-color: ${kayoGreen};
        height: 2px;
        content: '';

        ${stylesWhenNot('isSelected')`
            transform: scaleX(0);
        `}
    }
`;

const Abbr = styled.abbr`
    text-decoration: none;
`;

const createDateItems = (startDate, endDate) => {
    const dates = [];
    let d = startDate;

    while (isBefore(d, endDate) || isSameDay(d, endDate)) {
        dates.push(d);
        d = addDays(d, 1);
    }

    return dates.map((date) => ({
        label: isToday(date) ? 'Today' : format(date, 'ddd DD MMM'),
        value: date,
        accessibleText: isToday(date) ? 'Today' : format(date, 'dddd Do MMMM'),
    }));
};

export default function CAM04Range({
    items,
    onSelectItem = noop,
    selectedItemValue,
    backgroundColor = ironStone,
    startDate,
    endDate,
    className,
    ...htmlAttributes
}) {
    const isDateMode = !items;
    const normalisedItems = items || createDateItems(startDate, endDate);
    const selectedItemIndex = normalisedItems.findIndex(({value}) => isDateMode
        ? isSameDay(selectedItemValue, value)
        : value === selectedItemValue);
    const [hasDoneFirstScroll, setHasDoneFirstScroll] = useState(false);
    const [focusedItemIndex, setFocusedItemIndex] = useState(selectedItemIndex);
    const previousItemRef = useRef(null);
    const focusedItemRef = useRef(null);
    const nextItemRef = useRef(null);
    const [hasMounted, setHasMounted] = useState(false);

    const refs = [previousItemRef, focusedItemRef, nextItemRef];

    useEffect(() => {
        let timeout = null;

        if (!hasMounted) {
            timeout = setTimeout(() => setHasMounted(true));
        }

        return () => clearTimeout(timeout);
    }, [hasMounted]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            scrollIntoView(focusedItemRef.current, {
                behavior: hasDoneFirstScroll && hasMounted ? 'smooth' : 'auto',
                block: 'nearest',
                inline: 'center',
            });

            setHasDoneFirstScroll(hasMounted);
        }, 100); // Give the browser enough time understand the width/layout of this carousel before trying to scroll to center

        return () => clearTimeout(timeout);
    }, [focusedItemIndex, hasDoneFirstScroll, hasMounted]);

    // When selectedItemIndex changes, set that guy as the focused item
    useEffect(() => {
        setFocusedItemIndex(selectedItemIndex);
    }, [selectedItemIndex]);

    return (
        <CarouselWrapper
            {...htmlAttributes}
            className={classnames('CAM04Range', className)}
            onKeyDown={(e) => {
                if (e.key === 'ArrowLeft') {
                    e.preventDefault();
                    e.stopPropagation();
                    invoke(previousItemRef, 'current.focus');
                } else if (e.key === 'ArrowRight') {
                    e.preventDefault();
                    e.stopPropagation();
                    invoke(nextItemRef, 'current.focus');
                }
            }}
        >
            <ItemsWrapper>
                {normalisedItems
                    .map(({label, value, accessibleText}, index) => {
                        const isSelected = selectedItemIndex === index;

                        if (!hasMounted && !isSelected) {
                            // On the first render, just render the active item
                            // This protects against race conditions that make the centre scrolling go weird
                            return null;
                        }

                        return (
                            <li key={label}>
                                <NavItem
                                    onFocus={(e) => {
                                        // We don't want to do anything if this was the result of a click/touch because onClick will handle everything there
                                        const wasProgrammaticallyFocused = !get(e, 'nativeEvent.sourceCapabilities');

                                        if (wasProgrammaticallyFocused) {
                                            setFocusedItemIndex(index);
                                        }
                                    }}
                                    tabIndex={isSelected ? 0 : -1}
                                    onClick={() => {
                                        onSelectItem({label, value, index});
                                        setFocusedItemIndex(index);
                                    }}
                                    isSelected={isSelected}
                                    ref={refs[index - focusedItemIndex + 1] || null}
                                >
                                    {accessibleText ? (
                                        <Abbr title={accessibleText}>{label}</Abbr>
                                    ) : label}
                                </NavItem>
                            </li>
                        );
                    })}
            </ItemsWrapper>
            <ScrollButton
                isHidden={selectedItemIndex === 0}
                isRight={false}
                backgroundColor={backgroundColor}
                tabIndex={-1}
                onClick={() => {
                    const newIndex = Math.max(selectedItemIndex - 1, 0);

                    onSelectItem({...normalisedItems[newIndex], index: newIndex});
                }}
            >
                <ArrowLeft
                    ariaLabel="Scroll left"
                    size="16px"
                    color={white}
                />
            </ScrollButton>
            <ScrollButton
                isHidden={selectedItemIndex === normalisedItems.length - 1}
                isRight={true}
                backgroundColor={backgroundColor}
                tabIndex={-1}
                onClick={() => {
                    const newIndex = Math.min(selectedItemIndex + 1, normalisedItems.length - 1);

                    onSelectItem({...normalisedItems[newIndex], index: newIndex});
                }}
            >
                <ArrowRight
                    ariaLabel="Scroll right"
                    size="16px"
                    color={white}
                />
            </ScrollButton>
        </CarouselWrapper>
    );
}

CAM04Range.displayName = 'CAM04Range';

CAM04Range.propTypes = {
    /** List of items for the component. The existence of this component dictates if the list is for a normal list or a date list */
    items: propTypes.arrayOf(propTypes.shape({
        label: propTypes.string,
        value: propTypes.any,
    })),
    /** Event Handler to run when an item is selected */
    onSelectItem: propTypes.func,
    /** Initial selected value for the tab when it first renders */
    selectedItemValue: propTypes.any,
    /** Colour of the component's background */
    backgroundColor: colorType,
    /** If the list is in date mode, then this is the start date of the list */
    startDate: propTypes.object,
    /** If the list is in date mode, then this is the end date of the list */
    endDate: propTypes.object,
    /** Additional Class(es) */
    className: classNameType,
};
