import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import classnames from 'classnames';

import {silverLining} from '../../../../common/palette';
import BA22EditBtn from '../../../atoms/ba/22-edit-btn';

const StyledFooter = styled.footer`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`;

const DisplayText = styled.span`
    color: ${silverLining};
    font: var(--mui-body-copy-3-light);
`;

const StyledBA22EditBtn = styled(BA22EditBtn)`
    margin-top: 9px;
    font-weight: 300;
`;

const OR169RedirectFooter = ({
    className,
    displayText,
    ctaText,
    onClickCta,
    ...htmlAttributes
}) => (
    <StyledFooter
        {...htmlAttributes}
        className={classnames('OR169RedirectFooter', className)}
    >
        <DisplayText>{displayText}</DisplayText>
        <StyledBA22EditBtn onClick={onClickCta}>{ctaText}</StyledBA22EditBtn>
    </StyledFooter>
);

OR169RedirectFooter.propTypes = {
    /** Optional CSS class(es) for custom styling */
    className: propTypes.string,
    /** Text to be displayed in the footer */
    displayText: propTypes.string,
    /** Text for CTA button */
    ctaText: propTypes.string,
    /** Function to be called when the button is clicked */
    onClickCta: propTypes.func,
};

OR169RedirectFooter.displayName = 'OR169RedirectFooter';

export default OR169RedirectFooter;
