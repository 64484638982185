import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {silverLining} from '../../../../common/deprecated-mui-palette';
import {SCREEN_768_TABLET, SCREEN_2560_DESKTOP} from '../../../../common/screen-sizes';
import VisuallyHidden from '../../../../common/visually-hidden';
import GA92BrandLogo from '../../../atoms/ga/92-brand-logo';

const WelcomeSection = styled.section`
    margin: 0 auto;
    width: 660px;
    max-width: 100%;
    text-align: center;
    color: ${silverLining};

    ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
        width: 847px;
    `}
`;

const CenteredLogo = styled.div`
    margin: 35px auto;
    width: 180px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: 250px;
        margin: 49px auto;
    `}

    ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
        width: 301px;
        margin: 49px auto 84px;
    `}
`;

const StyledParagraph = styled.p`
    margin: 35px auto;
    font: var(--ionic-body-copy-1);
`;

const defaultParagraphs = [
    'Welcome to Binge.',
    'Watch your favourite sports at any time, anywhere - personalised for you.',
    'Take a few minutes to tell us your favourite team & sports and we\'ll help you choose what you want to watch, '
    + 'or let Binge surprise you.',
];

const OR93WelcomeBox = ({brandLogoSrcsetOptions, paragraphs = defaultParagraphs}) => ( // note: was previously OR80 but there was naming collision
    <WelcomeSection className="OR93WelcomeBox">
        <StyledParagraph>
            Welcome to <VisuallyHidden as="span">Kayo Sports</VisuallyHidden>
        </StyledParagraph>
        <CenteredLogo>
            <GA92BrandLogo srcsetOptions={brandLogoSrcsetOptions} />
        </CenteredLogo>
        {paragraphs.map((text, index) => (
            <StyledParagraph key={`${index}-${text}`}>{text}</StyledParagraph>
        ))}
    </WelcomeSection>
);

OR93WelcomeBox.propTypes = {
    /** Optional srcset to use for the brand logo */
    brandLogoSrcsetOptions: propTypes.objectOf(propTypes.string),
    paragraphs: propTypes.arrayOf(propTypes.string),
};

OR93WelcomeBox.displayName = 'OR93WelcomeBox';

export default OR93WelcomeBox;
