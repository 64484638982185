import propTypes from 'prop-types';

const baseModuleTypes = {
    /** The module alignment (left, right, center) */
    alignment: propTypes.oneOf(['left', 'center', 'right', null]),
    /** The CTA text. */
    cta: propTypes.string,
    /** Copy lines. */
    copylines: propTypes.arrayOf(propTypes.string),
    /** Sub text for CTA. */
    ctasubcopy: propTypes.string,
    /** Section title. */
    sectionTitle: propTypes.string,
    /** Identifier for CTA modules to open link in new tab or existing tab */
    targetCtaLink: propTypes.oneOf(['existingTab', 'newTab', null]),
    /** Either URL for a button/link or image */
    url: propTypes.shape({
        _publishUrl: propTypes.string,
    }),
    /** Data path for Adobe Analytics or Billing */
    dataPath: propTypes.shape({
        analytics: propTypes.string,
        billing: propTypes.string,
    }),
};
/**
 * ### AEM Module Object
 *
 * NOTE:
 * -  If you update this, please don't forget
 *   to also update controls in `.storybook/common/controls/aem-module.js`
 */
const aemModuleType = propTypes.shape({
    ...baseModuleTypes,
    /** Nested module to tackle edge cases like where two buttons share same background */
    subModules: propTypes.arrayOf(propTypes.shape(baseModuleTypes)),
});

export default aemModuleType;
