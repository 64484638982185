import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {red} from '../../../../../../common/palette';
import {SCREEN_PHABLET, SCREEN_TABLET} from '../../../../../../common/screen-sizes';

const Container = styled.article`
    box-sizing: border-box;
    border-bottom: 3px solid ${red}; /* hardcoded motor red */
    padding: 14px;
    width: 100%;

    ${mediaQuery({minWidthPx: SCREEN_PHABLET})`
        display: flex;
        align-items: center;
    `}
`;

const HeadingContainer = styled.div`
    box-sizing: border-box;
    flex: 1 1 auto;

    ${mediaQuery({minWidthPx: SCREEN_PHABLET})`
        padding-right: 14px;
        flex-basis: 60%;
    `}

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        flex-basis: 40%;
    `}
`;

const Heading = styled.h2`
    margin: 0;
    font: var(--mui-header-5);
`;

const SubHeading = styled.p`
    margin: 0;
    text-transform: uppercase;
    font: var(--mui-header-9);
`;

const MotorTile = ({
    className,
    matchEndDate,
    matchStartDate,
    motorMeetingName,
}) => (
    <Container className={className}>
        <HeadingContainer>
            <Heading>{motorMeetingName}</Heading>
            <SubHeading>{matchStartDate} to {matchEndDate}</SubHeading>
        </HeadingContainer>
    </Container>
);

MotorTile.displayName = 'MotorTile';

MotorTile.propTypes = {
    /** className */
    className: classNameType,
    /** Parsed pretty string for match end date */
    matchEndDate: propTypes.string,
    /** Parsed pretty string for match start date */
    matchStartDate: propTypes.string,
    /** State for the match, before starting (pre,) during (live,) and finished (post) */
    // matchState: propTypes.oneOf(['pre', 'live', 'post']),
    /** Meeting name */
    motorMeetingName: propTypes.string,
};

export default MotorTile;
