import React, {useEffect} from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import classnames from 'classnames';
import noop from 'lodash/noop';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';
import {
    SCREEN_TABLET,
    SCREEN_LG_DESKTOP,
    SCREEN_REALLY_LG_DESKTOP,
} from '../../../../common/screen-sizes';

const CardRow = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
`;

const Card = styled.div`
    flex-grow: 1;
    padding: 0 14px 14px 0;
    min-width: 100%;
    max-width: 100%;

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        min-width: 50%;
    `}

    ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
        min-width: calc(100% / 3);
        max-width: calc(100% / 3);
    `}

    ${mediaQuery({minWidthPx: SCREEN_REALLY_LG_DESKTOP})`
        padding: 0 21px 21px 0;
    `}
`;

export default function CAM10PanelCard({
    onMount = noop,
    children,
    className,
    ...htmlAttributes
}) {
    useEffect(onMount, [onMount]);

    return (
        <CardRow className={classnames('CAM10PanelCard', className)} {...htmlAttributes}>
            {React.Children.map(children, (child) => (
                <Card key={child.key}>
                    {child}
                </Card>
            ))}
        </CardRow>
    );
}

CAM10PanelCard.displayName = 'CAM10PanelCard';

CAM10PanelCard.propTypes = {
    /** Optional callback called on mount */
    onMount: propTypes.func,
    /** Optional extra class name */
    className: classNameType,
    /** Children as panel cards */
    children: propTypes.node,
};
