import styled from 'styled-components';
import {rgba} from 'polished';
import {stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';

import {transition} from '../../../../../common/animations';
import {hubblGreen, ink, white} from '../../../../../common/palette';

/*
 * NOTE:
 *  Moved this out of index file because it's also referenced by FeatureColumnCells
 *   > Keeping it within index causes circular dependencies
 */

const PackageRow = styled.tr.attrs({
    role: 'button',
    tabIndex: 0,
})`
    position: relative;
    transition: ${transition('background-color', 'color')};
    outline: 0;
    background-color: ${rgba(ink, 0.02)};

    ${stylesWhenNot('isDisabled')`
        &:focus {
            box-shadow: 0 0 3px 1px ${hubblGreen};
        }

        &:hover {
            background-color: ${rgba(ink, 0.07)};
        }
    `}

    &[aria-pressed='true'] {
        box-shadow: none;
        background-color: ${white};
    }
`;

PackageRow.displayName = 'PackageRow';

export default PackageRow;
