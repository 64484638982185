import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {kayoGreen} from '../../../../../common/palette';

const List = styled.ul`
    margin: 0;
    background-color: transparent;
    padding: 0;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    /* This scrollbar-* property is for Firefox 64+ */
    scrollbar-color: ${kayoGreen} transparent;

    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    ::-webkit-scrollbar {
        background-color: transparent;
        width: 4px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: ${kayoGreen};
    }
`;

const ListItem = styled.li`
    margin: 10px 0;
    width: 100%;
    min-width: 230px;
    max-width: 250px;
    overflow-x: hidden;
    list-style: none;

    :first-child {
        margin-top: 20px;
    }

    :last-child {
        margin-bottom: 20px;
    }
`;

const VPTM06TraySectionList = ({
    children,
    className,
}) => (
    <List className={classnames('VPTM06TraySectionList', className)} role="tablist">
        {React.Children.map(children, ({key, ...child}) => (
            <ListItem key={key} role="presentation">
                {child}
            </ListItem>
        ))}
    </List>
);

VPTM06TraySectionList.displayName = 'VPTM06TraySectionList';

VPTM06TraySectionList.propTypes = {
    /** Child components */
    children: propTypes.any,
    /** Additional Class(es) */
    className: classNameType,
};

export default VPTM06TraySectionList;
