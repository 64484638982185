import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import get from 'lodash/get';
import {Button} from 'normalized-styled-components';
import noop from 'lodash/noop';

import {mediaQuery, stylesWhen} from '@fsa-streamotion/styled-component-helpers';

import {transition} from '../../../../common/animations';
import {white} from '../../../../common/palette';
import {kayoGreen, coal, satellite} from '../../../../common/deprecated-mui-palette';
import {SCREEN_1280_DESKTOP, SCREEN_2560_DESKTOP, SCREEN_768_TABLET} from '../../../../common/screen-sizes';

const StyledSection = styled.section`
    margin: 0;
    width: 100%;
`;

const StyledTitle = styled.h2`
    margin: 0 7px 21px;
    text-align: center;
    color: ${white};
    font: var(--ionic-header-3-bold);

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        margin: 0 auto 21px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        margin-bottom: 28px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
        margin-bottom: 35px;
    `}
`;

const DeviceScrollList = styled.div`
    margin: 21px 7px 41px;
    overflow-x: auto;
    text-align: center;
    white-space: nowrap;

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        margin: 28px auto 63px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
        margin-top: 35px;
        margin-bottom: 49px;
    `}
`;

const IconStyled = styled.span`
    box-sizing: border-box;
    display: block;
    transition: ${transition('border-color', 'background-color')};
    border: 1px solid transparent;
    border-radius: 4px;
    height: 82px;
    color: ${satellite};

    ${stylesWhen('isSelected')`
        background-color: ${coal};
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        margin-bottom: 7px;
        height: 98px;
    `}
`;

const StyledButton = styled(Button)`
    appearance: none;
    box-sizing: border-box;
    position: relative;
    margin-right: 7px;
    outline: 0;
    border: none;
    background: transparent;
    padding: 0;
    width: 82px;
    color: ${satellite};
    font: var(--ionic-body-copy-5);

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        width: 98px;
    `}

    &:focus,
    &:hover {
        ${IconStyled} {
            border-color: ${kayoGreen};
        }
    }

    &:last-child {
        margin-right: 0;
    }

    &::after {
        display: block;
        transition: ${transition('background-color')};
        margin: 7px auto 0;
        border-radius: 2px;
        background-color: transparent;
        width: 54px;
        height: 3px;
        content: '';

        ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
            width: 64px;
        `}
    }

    &[aria-pressed='true'] {
        &::after {
            background-color: ${kayoGreen};
        }
    }
`;

const DeviceName = styled.span`
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const StyledP = styled.p`
    margin: 35px 21px;
    text-align: center;
    color: ${white};
    font: var(--ionic-body-copy-3);

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        margin-right: auto;
        margin-left: auto;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        margin: 49px auto;

        &:last-child {
            margin-bottom: 0;
        }
    `}

    &:last-child {
        margin-bottom: 0;
    }
`;

export default class OR15DevCar extends React.Component {
    static displayName = 'OR15DevCar';

    static propTypes = {
        deviceList: propTypes.arrayOf(
            propTypes.shape({
                description: propTypes.arrayOf(propTypes.string),
                icon: propTypes.node,
                title: propTypes.string,
            })
        ),
        title: propTypes.string,
        onClickDevice: propTypes.func,
    };

    static defaultProps = {
        deviceList: [],
        onClickDevice: noop,
    };

    state = {
        expandedItemIndex: 0,
    };

    render() {
        const description = get(this.props.deviceList, [this.state.expandedItemIndex, 'description'], []);

        return (
            <StyledSection
                className="OR15DevCar"
                aria-live="polite"
            >
                <StyledTitle>
                    {this.props.title}
                </StyledTitle>
                <DeviceScrollList>
                    {this.props.deviceList
                        .map(({title, icon}, index) => (
                            <StyledButton
                                title={title}
                                aria-label={`Click to know more about ${title}`}
                                aria-pressed={this.state.expandedItemIndex === index}
                                onClick={() => {
                                    this.setState({expandedItemIndex: index});
                                    this.props.onClickDevice(title);
                                }}
                                type="button"
                                key={title}
                            >
                                <IconStyled isSelected={this.state.expandedItemIndex === index}>{icon}</IconStyled>
                                <DeviceName>{title}</DeviceName>
                            </StyledButton>
                        ))}
                </DeviceScrollList>
                {description
                    .filter(Boolean)
                    .map((text) => <StyledP key={text}>{text}</StyledP>)}
            </StyledSection>
        );
    }
}
