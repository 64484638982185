import React, {useRef, useEffect} from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';
import styled from 'styled-components';
import noop from 'lodash/noop';

import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../common/animations';
import IC103Loading from '../../atoms/ic/103-loading';

// Render app dock over page content
const PAGE_CONTENT_Z_INDEX = 0;

/*
 * The search bar needs to be in between the main page z-index and the nav bar.
 * Unfortunately, this value is set in widgets...
 */
export const NAV_Z_INDEX = 2;
const APP_DOCK_Z_INDEX = NAV_Z_INDEX; // same z-index as NAV_Z_INDEX and DOM order makes sure app dock is on top of other things

const PageAppDockComposer = styled.div`
    display: flex;
    flex-direction: row-reverse;
    flex-grow: 1;
    justify-content: space-between;

    ${stylesWhen('isFullScreenVideoPlayerOpen')`
        height: 0;
        opacity: 0;
        overflow-y: hidden;
    `}
`;

const HeaderNavLayer = styled.div`
    position: fixed;
    top: 0;
    z-index: ${NAV_Z_INDEX};
    margin-bottom: -69px;
    width: 100%;
`;

const ScrollSentinel = styled.div`
    width: 1px;
    height: 1px;
`;

const AppDockLayer = styled.div`
    position: fixed;
    top: 69px;
    right: 0;
    z-index: ${APP_DOCK_Z_INDEX};
    width: 0;
`;

const PageContentRelativeContainer = styled.div`
    position: relative;
    flex-grow: 1;
    z-index: ${PAGE_CONTENT_Z_INDEX};
    width: 100%;
`;

const StyledIC103Loading = styled(IC103Loading)`
    position: fixed;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    pointer-events: none;
`;

const ChildrenLayer = styled.div`
    transition: ${transition('filter')};
    filter: brightness(100%); /* Not using opacity, so we dont inadvertantly reveal the green-screen player hiding behind our children */

    &[aria-busy='true'] {
        filter: brightness(50%);
        pointer-events: none;
    }
`;

const PASSIVE_EVENT_ARGS = {passive: true};

const NavEnhancedTemplate = ({
    appDock,
    children,
    className,
    headerNav,
    isAppDockActive,
    isFullScreenVideoPlayerOpen,
    isLoading,
    loadingSpinnerId,
    onCloseAppDock = noop,
    search,
    onDetachFromNav = noop,
}) => {
    const headerNavRef = useRef(null);
    const appDockRef = useRef(null);
    const scrollSentinelRef = useRef(null);

    /* close app dock when user presses ESC */
    const handleKeyDown = ({key}) => {
        if (key === 'Escape') {
            onCloseAppDock();
        }
    };

    /* close app dock when user clicks outside of headerNav or appdock */
    const handleDocumentClick = ({target}) => {
        const refs = [headerNavRef.current, appDockRef.current];

        if (
            refs.includes(target) // one of our menus' divs was clicked on
            || refs.some((ref) => ref?.contains(target)) // the descendant of one of our menus' divs was clicked on
        ) {
            return; // ignore all clicks within the menu
        }

        onCloseAppDock();
    };

    const handlePageAppDockComposerFocus = ({currentTarget}) => {
        currentTarget.scrollLeft = 0;
    };

    /* register click and keydown events listeners for closing app dock */
    useEffect(function setClickAndKeydownListeners() {
        // componentDidMount
        document.addEventListener('click', handleDocumentClick, PASSIVE_EVENT_ARGS);
        document.addEventListener('keydown', handleKeyDown, PASSIVE_EVENT_ARGS);

        // componentWillUnmount
        return () => {
            document.removeEventListener('click', handleDocumentClick, PASSIVE_EVENT_ARGS);
            document.removeEventListener('keydown', handleKeyDown, PASSIVE_EVENT_ARGS);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /* user scroll detector */
    useEffect(function setIntersectionObserver() {
        const observer = new IntersectionObserver(
            ([entry]) => {
                // set isScrolled state
                onDetachFromNav(!entry.isIntersecting);
            }
        );

        if (scrollSentinelRef.current) {
            observer.observe(scrollSentinelRef.current);
        }

        return () => observer.disconnect();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <PageAppDockComposer
            className={classnames('NavEnhancedTemplate', className)}
            isFullScreenVideoPlayerOpen={isFullScreenVideoPlayerOpen}
            onFocus={handlePageAppDockComposerFocus}
        >
            <PageContentRelativeContainer>
                <AppDockLayer
                    ref={appDockRef}
                    isAppDockActive={isAppDockActive}
                >
                    {appDock}
                </AppDockLayer>

                <HeaderNavLayer
                    ref={headerNavRef}
                >
                    {headerNav}
                </HeaderNavLayer>

                {search}

                <ChildrenLayer aria-busy={isLoading}>
                    <ScrollSentinel ref={scrollSentinelRef} />
                    {children}
                </ChildrenLayer>

                {!!isLoading && (
                    <StyledIC103Loading size="10vh" id={loadingSpinnerId} />
                )}
            </PageContentRelativeContainer>
        </PageAppDockComposer>
    );
};

NavEnhancedTemplate.displayName = 'NavEnhancedTemplate';

NavEnhancedTemplate.propTypes = {
    /** `OR31AppDock` component */
    appDock: propTypes.node,
    /** Content of a page - usually carousel panels */
    children: propTypes.node,
    /** Additional className for the component */
    className: classNameType,
    /** `OR33HeaderDsk` component */
    headerNav: propTypes.node,
    /** Whether the AppDock is visible */
    isAppDockActive: propTypes.bool,
    /** Whether the full screen video player is open */
    isFullScreenVideoPlayerOpen: propTypes.bool,
    /** Whether the page is in the loading state */
    isLoading: propTypes.bool,
    /** ID of loading spinner `IC103Loading` */
    loadingSpinnerId: propTypes.string,
    /** Callback on closing AppDock */
    onCloseAppDock: propTypes.func,
    /** `OR06Search` component */
    search: propTypes.node,
    /** Callback when user switches between scrolled and default state */
    onDetachFromNav: propTypes.func,
};

export default NavEnhancedTemplate;
