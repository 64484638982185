import isRequired from './is-required';
import generateError from './util/generate-error';
import getTypeName from './util/get-type-name';
import type {ObjectPropType} from './util/types';

const prefix = '[srcsetOptionsType] ';

/**
 * Validate an object of srcsetOptions. It accepts 2 types of values:
 *
 * 1. `undefined` or `null`
 * 2. objects in the form of `{[number]: string}`, with at least 1 key
 *
 * @param props         - React component's props object
 * @param propName      - name of prop to validate
 * @param componentName - display name of component
 * @param location      - the location of the prop (e.g. `"prop"` or `"context"`)
 * @param propFullName  - the full name of the prop
 *
 * @returns an error on validation failure
 */
function baseSrcsetOptionsType(
    props: ObjectPropType,
    propName: string,
    componentName: string,
    location: string,
    propFullName: string
): Error | null {
    const srcsetOptions = props[propName];

    if (srcsetOptions === null || srcsetOptions === undefined) {
        return null;
    }

    const propValue = props[propName];
    const actualType = getTypeName(propValue);

    if (Object.prototype.toString.call(srcsetOptions) !== '[object Object]') {
        return generateError({
            prefix,
            location,
            propFullName,
            actualType,
            componentName,
            expected: 'srcsetOptions to be a plain object',
        });
    }

    const srcsetOptionKeys = Object.keys(srcsetOptions);
    const srcsetOptionValues: unknown[] = Object.values(srcsetOptions);

    if (!srcsetOptionKeys.length) {
        return generateError({
            prefix,
            location,
            propFullName,
            actualType,
            componentName,
            expected: 'srcsetOptions to have at least one key',
        });
    }

    if (
        srcsetOptionKeys.some((widthPx) => !/^[0-9]+$/.test(widthPx)) ||
        srcsetOptionValues.some((src): src is string => typeof src !== 'string')
    ) {
        return generateError({
            prefix,
            location,
            propFullName,
            actualType,
            componentName,
            expected: 'srcsetOptions have numeric keys and string values',
        });
    }

    return null;
}

const srcsetOptionsType = Object.assign(baseSrcsetOptionsType, {
    isRequired: isRequired(baseSrcsetOptionsType),
});

export default srcsetOptionsType;
