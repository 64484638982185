import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Section} from 'normalized-styled-components';

import {mediaQuery, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';

import {billingPeriodType} from '../../../../common/custom-proptypes';
import {PERIOD_ADJECTIVE} from '../../../../common/billing-constants';
import {white} from '../../../../common/palette';
import {SCREEN_TABLET} from '../../../../common/screen-sizes';

import BA20NavBtn from '../../../atoms/ba/20-nav-btn';
import XX02Feature from '../../../atoms/xx/02-feature';
import TM10SubTile from '../../../molecules/tm/10-sub-tile';
import TM11VoucherTile from '../../../molecules/tm/11-voucher-tile';
import TM19PmntCc from '../../../molecules/tm/19-pmnt-cc';
import TM19PmntGiftCard from '../../../molecules/tm/19-pmnt-gift-card';
import TM19PmntPp from '../../../molecules/tm/19-pmnt-pp';
import TM19PmntTelstra from '../../../molecules/tm/19-pmnt-telstra';
import TM25UpdateTile from '../../../molecules/tm/25-update-tile';
import XX03FeatureList from '../../../molecules/xx/03-feature-list';
import OR13FeaBlk from '../../../organisms/or/13-fea-blk';
import XX04ScreenOptions from '../../../molecules/xx/04-screen-options';

import TM23CancellationTile from '../../../molecules/tm/23-cancellation-tile';
import {IC61Ios} from '../../../atoms/ic';
import TM27InfoTile from '../../../molecules/tm/27-info-tile';

const PAYMENT_METHOD_COMPONENT = {
    creditCard: TM19PmntCc,
    paypal: TM19PmntPp,
    tbill: TM19PmntTelstra,
};

const Group = styled(Section)`
    margin: 0 0 35px;
`;

const Groups = styled.div`
    display: grid;
    grid-row-gap: 7px;
`;

const BottomGroups = styled.div`
    display: grid;
    grid-row-gap: 35px;
`;

const GroupHeading = styled.h3`
    margin: 0 0 14px;
    padding: 0 14px;
    color: ${white};
    font: var(--mui-header-6-bold);
`;

const Byline = styled.p`
    text-align: center;
    color: ${white};
    font: var(--mui-body-copy-3);
`;

const Products = styled.ul`
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 5px;
    align-items: start;
    justify-content: center;
    margin: 0;
    padding: 0;
    min-height: 108px;
    list-style: none;

    ${stylesWhenNot('theme.isAppDock')`
        ${mediaQuery({minWidthPx: SCREEN_TABLET})`
            grid-auto-columns: 192px;
            grid-column-gap: 7px;
        `}
    `}
`;

const Product = styled.li`
    grid-row: 1;
    margin: 0;
    padding: 0;
`;

const ChangeSubscriptionTemplate = ({
    headingText = 'Your current subscription',
    subHeadingText,
    currencyPrefix,
    currencyDecimalPlaces,
    subscription: {
        billingPeriod,
        features,
        price,
        productName,
    } = {},
    voucher: {
        tagline,
        shortDescription,
    } = {},
    isPendingAdjustment,
    adjustmentType,
    adjustmentPlanName,
    adjustmentDate,
    adjustmentEffectiveDate,
    accountBalance,
    paymentMethod = {},
    products = [],
    featureList = [],
    finePrint,
    isItunesSubscription,
    itunesSubscriptionTitle,
    itunesSubscriptionMessage,
    onClickManagePaymentMethod,
    cancelAdjustmentError,
    isCancellingAdjustment,
    onCancelAdjustment,
    screenOptions = [],
}) => {
    const {details, method} = paymentMethod;
    const PaymentMethod = PAYMENT_METHOD_COMPONENT[method];
    const isAnnualSubscription = billingPeriod === 'annual';

    if (isItunesSubscription) {
        return (
            <TM27InfoTile
                icon={<IC61Ios size="43px" />}
                title={itunesSubscriptionTitle}
            >
                {itunesSubscriptionMessage}
            </TM27InfoTile>
        );
    }

    return (
        <Groups>
            <section>
                <GroupHeading>
                    {headingText}
                </GroupHeading>

                {!!subHeadingText && (
                    <Byline>
                        {subHeadingText}
                    </Byline>
                )}

                <TM10SubTile
                    {...{
                        billingPeriod,
                        currencyDecimalPlaces,
                        currencyPrefix,
                        features,
                        price,
                        productName,
                    }}
                />
            </section>

            {!!(tagline || shortDescription) && (
                <TM11VoucherTile
                    {...{
                        tagline,
                        shortDescription,
                    }}
                />
            )}

            {!!isPendingAdjustment && (adjustmentType === 'Downgrade' || adjustmentType === 'Upgrade') && (
                <BottomGroups>
                    <TM25UpdateTile
                        {...{
                            updateDate: adjustmentDate,
                            updateEffectiveDate: adjustmentEffectiveDate,
                            updateType: adjustmentType,
                            updateTypeText: adjustmentType === 'Downgrade' ? 'Downgraded' : 'Upgraded',
                            updatePlanName: adjustmentPlanName,
                            currentPlanName: productName,
                            cancelAdjustmentError,
                            isCancellingAdjustment,
                            onCancelAdjustment,
                        }}
                    />
                </BottomGroups>
            )}

            {isPendingAdjustment && adjustmentType === 'Cancel' ? (
                <TM23CancellationTile
                    {...{
                        cancellationDate: adjustmentDate,
                        cancellationEffectiveDate: adjustmentEffectiveDate,
                    }}
                />
            ) : (
                <React.Fragment>
                    <Group>
                        <Groups>
                            {!!accountBalance && (
                                <TM19PmntGiftCard
                                    currencyPrefix={currencyPrefix}
                                    currencyDecimalPlaces={currencyDecimalPlaces}
                                    balance={accountBalance}
                                />
                            )}

                            {!!PaymentMethod && (
                                <PaymentMethod {...details} />
                            )}

                            <BA20NavBtn
                                label={`${PaymentMethod ? 'Manage' : 'Add'} payment method`}
                                onClick={onClickManagePaymentMethod}
                            />
                        </Groups>
                    </Group>

                    <BottomGroups>
                        <Group>
                            <GroupHeading>
                                {
                                    isAnnualSubscription
                                        ? 'Unable to change your subscription at this time'
                                        : `Change your${billingPeriod ? ` ${PERIOD_ADJECTIVE[billingPeriod]}` : ''} subscription`
                                }
                            </GroupHeading>

                            {!isAnnualSubscription && (
                                <Products>
                                    {React.Children.map(products, (child) => (
                                        <Product key={child.key}>{child}</Product>
                                    ))}
                                </Products>
                            )}
                        </Group>

                        <OR13FeaBlk
                            title="Every subscription includes:"
                            screenOptions={screenOptions.length ? (
                                <XX04ScreenOptions listTitle="Devices you can watch on at the same time">
                                    {screenOptions}
                                </XX04ScreenOptions>
                            ) : undefined}
                            featureList={featureList.length ? (
                                <XX03FeatureList>
                                    {featureList.map((feature) => (
                                        <XX02Feature key={feature}>
                                            {feature}
                                        </XX02Feature>
                                    ))}
                                </XX03FeatureList>
                            ) : undefined}
                            finePrint={finePrint}
                        />
                    </BottomGroups>
                </React.Fragment>
            )}
        </Groups>
    );
};

ChangeSubscriptionTemplate.displayName = 'ChangeSubscriptionTemplate';

ChangeSubscriptionTemplate.propTypes = {
    /** Currency prefix (defaults to $) */
    currencyPrefix: propTypes.string,
    /** Currency decimal places (defaults to 2) */
    currencyDecimalPlaces: propTypes.number,

    /** Current subscription */
    subscription: propTypes.shape({
        billingPeriod: billingPeriodType,
        features: propTypes.arrayOf(propTypes.string),
        price: propTypes.number,
        productName: propTypes.string,
    }),

    /** Currently applied voucher */
    voucher: propTypes.shape({
        tagline: propTypes.string,
        shortDescription: propTypes.string,
    }),

    isPendingAdjustment: propTypes.bool,
    adjustmentType: propTypes.string,
    adjustmentPlanName: propTypes.string,
    adjustmentDate: propTypes.string,
    adjustmentEffectiveDate: propTypes.string,

    /** Current account balance (e.g. from gift cards) */
    accountBalance: propTypes.number,

    paymentMethod: propTypes.shape({
        method: propTypes.oneOf(['creditCard', 'paypal', 'tbill']),
        details: propTypes.oneOfType([
            // if method === 'creditCard'
            propTypes.shape({
                type: propTypes.oneOf(['mastercard', 'visa']),
                number: propTypes.string,
                expiry: propTypes.string,
            }),
            // if method === 'paypal'
            propTypes.shape({
                email: propTypes.string,
                name: propTypes.string,
            }),
            // if method === 'tbill'
            propTypes.shape({
                name: propTypes.string,
            }),
        ]),
    }),

    /** Available products to select */
    products: propTypes.arrayOf(propTypes.shape({
        isCurrent: propTypes.bool,
        title: propTypes.string,
        numberOfScreens: propTypes.number,
        price: propTypes.number,
        prevPriceText: propTypes.string,
        accessiblePrevPriceText: propTypes.string,
        secondaryText: propTypes.string,
        accessibleSecondaryText: propTypes.string,
        period: billingPeriodType,
        href: propTypes.string,
        onClick: propTypes.func,
    })),

    /** Feature list, shared by all products */
    featureList: propTypes.arrayOf(propTypes.string),
    /** Optional fine print below the feature list */
    finePrint: propTypes.string,
    /** Manage Payment button click handler */
    onClickManagePaymentMethod: propTypes.func,
    /** Cancel adjustment error */
    cancelAdjustmentError: propTypes.string,
    /** Is cancelling adjustment */
    isCancellingAdjustment: propTypes.bool,
    /** Cancel adjustment button click handler */
    onCancelAdjustment: propTypes.func,
    /** expected to be one or many XX03ScreenOption */
    screenOptions: propTypes.arrayOf(propTypes.node),

    headingText: propTypes.string,
    subHeadingText: propTypes.string,
    isItunesSubscription: propTypes.bool,
    itunesSubscriptionTitle: propTypes.node,
    itunesSubscriptionMessage: propTypes.node,
};

export default ChangeSubscriptionTemplate;
