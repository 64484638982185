import React, {Component} from 'react';
import propTypes from 'prop-types';
import get from 'lodash/get';
import noop from 'lodash/noop';
import {isReactElementInteractable} from '../helpers';

// For Left/Right scrolling
const ROW = 'row';
// For Up/Down scrolling
const COLUMN = 'column';
// Omnidirectional flows respond to both Left + Up to go backwards, and Right + Down to go forwards
// This is useful for components where buttons may wrap if they are too wide for one row
const OMNIDIRECTIONAL = 'omnidirectional';

export default function createFlowController(WrappedComponent, controlFlowType) {
    class GenericFlowController extends Component {
        static displayName = WrappedComponent.displayName || 'GenericFlowController';

        static propTypes = {
            /** If true, the component will attempt to manually focus one of its children when it mounts */
            shouldFocusOnMount: propTypes.bool,
            /** Which child should be automatically focused when the component mounts */
            indexToFocusOnMount: propTypes.number,
            /** When we navigate past the end of this list, should we wrap back around to the start? */
            isLoop: propTypes.bool,
            /** When this wrapper is re-focused, should focus revert to the last focused child (false), or back to the start (true)? */
            willResetFocusIndexOnBlur: propTypes.bool,
            /** A custom function called when the parent is generically focused */
            onFocus: propTypes.func,
            /** On click function */
            onClick: propTypes.func,
            /** Custom callback for onKeyDown - can preventDefault|cancelBubble to stop further navigation. */
            onKeyDown: propTypes.func,
            /** The children of the WrappedComponent, which we will cycle through */
            children: propTypes.node,
            /** Register this component's focus method with its direct parent if it's a GenericFlowController */
            registerFocus: propTypes.func,
            /** Inner ref to get at the DOM node */
            innerRef: propTypes.oneOfType([propTypes.object, propTypes.func]),
        };

        static defaultProps = {
            shouldFocusOnMount: false,
            indexToFocusOnMount: 0,
            isLoop: false,
            // If we don't really care about what happens when we're focused, we still need a default
            // Since this default signals to higher level parents that we're focusable
            willResetFocusIndexOnBlur: false,
            registerFocus: noop,
        };

        static getDerivedStateFromProps({children}) {
            const childrenArray = [];

            // Get all children, preserving gaps, and providing a default ref.
            React.Children.forEach(
                children,
                (child, index) => {
                    childrenArray[index] = React.isValidElement(child)
                        ? {...child, ref: child.ref || React.createRef()}
                        : child;
                },
            );

            return {childrenArray};
        }

        state = {
            currentFocusIndex: null,
        };

        componentDidMount() {
            if (this.props.shouldFocusOnMount) {
                this.focusChild(this.props.indexToFocusOnMount);
            }
        }

        handleOnFocus = (childIndex, childOnFocus) => (e) => {
            // When one of the children is focused, update our records of which one that is
            // We choose a reactive approach instead of doing this in `focusChild` because we may not be in charge of all focus events
            // e.g. the user may use a pointer to force focus to a child rather than using the keyboard
            this.setState(() => ({currentFocusIndex: childIndex}));

            if (childOnFocus) {
                childOnFocus(e);
            }
        };

        // Intercepts focuses on this parent. Since this parent is a wrapper, we pass that focus down to the appropriate child.
        focus = (e) => {
            // Don't try to get fancy if the focus event wasn't directly on us, the wrapper
            if (!e || e.currentTarget === e.target) {
                if (!this.props.willResetFocusIndexOnBlur && Number.isInteger(this.state.currentFocusIndex)) {
                    this.focusChild(this.state.currentFocusIndex);
                } else if (get(this.state.childrenArray, 'length')) {
                    const indexOfFirstChildToFocus = this.state.childrenArray.findIndex((child) => (
                        child && isReactElementInteractable(child)
                    ));

                    if (indexOfFirstChildToFocus >= 0) {
                        this.focusChild(indexOfFirstChildToFocus);
                    }
                }
            }
        };

        onFocus = (e) => {
            // Call provided focus handler
            if (this.props.onFocus) {
                this.props.onFocus(e);
            }

            // If this is the focused element, handle focus
            if (e.currentTarget === e.target) {
                this.focus();
            }
        };

        onClick = (e) => {
            // Call provided click handler
            if (this.props.onClick) {
                this.props.onClick(e);
            }

            // Focus on click
            this.focus(e);
        };

        handleOnKeyDown = (event) => {
            const {key} = event;
            const {currentFocusIndex, childrenArray} = this.state;
            const {onKeyDown, isLoop} = this.props;
            let nextChildIndex;

            // Call provided keydown handler
            if (onKeyDown) {
                onKeyDown(event);
            }

            if (currentFocusIndex === null && ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(key)) {
                console.warn('Tried to navigate to the next child without knowing the currentFocusIndex. Is your component possibly mutating or ignoring the onFocus prop passed in by the navigation HOC?');
            }

            // TODO: Handle non-standard directional arrow keys
            if (
                (key === 'ArrowRight' && (controlFlowType === ROW || controlFlowType === OMNIDIRECTIONAL))
                || (key === 'ArrowDown' && (controlFlowType === COLUMN || controlFlowType === OMNIDIRECTIONAL))
            ) {
                // Find the next focusable element, denoted by a truthy childRef
                // Wrap back to the start if we hit the end without finding any
                const orderedChildren = [
                    ...childrenArray.slice(currentFocusIndex + 1),
                    ...(isLoop ? childrenArray.slice(0, currentFocusIndex + 1) : []),
                ];
                const nextOrderedIndex = orderedChildren.findIndex((child) => child && isReactElementInteractable(child));

                if (nextOrderedIndex !== -1) {
                    nextChildIndex = (currentFocusIndex + nextOrderedIndex + 1) % childrenArray.length;
                }
            } else if (
                (key === 'ArrowLeft' && (controlFlowType === ROW || controlFlowType === OMNIDIRECTIONAL))
                || (key === 'ArrowUp' && (controlFlowType === COLUMN || controlFlowType === OMNIDIRECTIONAL))
            ) {
                // Find the previous focusable element, denoted by a truthy childRef
                // Wrap around to the end if we hit the start without finding any
                const orderedChildren = [
                    ...childrenArray.slice(0, Math.max(0, currentFocusIndex)).reverse(),
                    ...(isLoop ? childrenArray.slice(Math.max(0, currentFocusIndex)).reverse() : []),
                ];
                const nextOrderedIndex = orderedChildren.findIndex((child) => child && isReactElementInteractable(child));

                if (nextOrderedIndex !== -1) {
                    nextChildIndex = (currentFocusIndex - nextOrderedIndex - 1 + childrenArray.length) % childrenArray.length;
                }
            }

            if (![undefined, currentFocusIndex, -1].includes(nextChildIndex)) {
                // Pass focus on to that next child
                this.focusChild(nextChildIndex);
                // Don't let any navigable parents respond to this event - we've got it covered here
                event.stopPropagation();
                // Stop scrolling from happening on up/down
                event.preventDefault();
            }
        };

        // Registry of child GenericFlowController focus methods, updated every render
        childFocusMethods = [];

        // Attempt to focus the child at index
        // Note that the only children counted here are focusable ones - children with an onFocus handler
        focusChild = (index) => {
            const childIndex = Math.min(this.state.childrenArray.length - 1, index);
            const childFlowControllerFocus = this.childFocusMethods[childIndex];

            if (childFlowControllerFocus) {
                childFlowControllerFocus();
            } else {
                this.state.childrenArray[Math.min(this.state.childrenArray.length - 1, index)].ref.current.focus();
            }
        };

        render() {
            const needsForcedTabIndex = !isReactElementInteractable({type: WrappedComponent.type, props: this.props});

            const {
                isLoop,
                willResetFocusIndexOnBlur,
                children,
                registerFocus,
                innerRef,
                ...restProps
            } = this.props;

            const {
                childrenArray,
            } = this.state;

            // The magic happens here - we inject these generic key and focus handlers to the WrappedComponent
            const wrapperExtraProps = {
                onKeyDown: this.handleOnKeyDown,
                onFocus: this.onFocus,
                // Hijack click events to pass focus to the children where it would be otherwise captured by the parent
                onClick: this.onClick,
                // If this element type wouldn't usually be interactive, we give it a tab index so it can listen to children's focus events
                ...(needsForcedTabIndex ? {tabIndex: -1} : {}),
            };

            // Register this component's focus implementation with the parent
            registerFocus(this.focus);

            // Clear child focus registry so we're not hanging onto unmounted and/or unfocusable children.
            this.childFocusMethods.length = 0;

            return (
                <WrappedComponent
                    {...restProps}
                    {...wrapperExtraProps}
                    ref={innerRef}
                >
                    {React.Children.map(children, (child, index) => {
                        if (
                            child === null
                            || typeof child !== 'object'
                            || child.type === React.Fragment
                        ) {
                            return child;
                        }

                        const childOnFocus = get(child, 'props.onFocus');
                        const canChildHandleRef = child && (typeof child.type !== 'function' || get(child, 'type.isFlowController'));

                        const newProps = Object.assign(
                            {onFocus: this.handleOnFocus(index, childOnFocus)},
                            canChildHandleRef && {ref: childrenArray[index].ref}
                        );

                        // Note that we avoid React.cloneElement here because we need to control and possibly change the refs
                        return child
                            ? (
                                <child.type
                                    {...{
                                        ...child.props,
                                        ...newProps,
                                        key: child.key,
                                        ...(
                                            child.type.isFlowController
                                                ? {
                                                    registerFocus: (focus) => {
                                                        this.childFocusMethods[index] = focus;
                                                    },
                                                }
                                                : {}
                                        ),
                                    }}
                                />
                            )
                            : child;
                    })}
                </WrappedComponent>
            );
        }
    }

    const RefForwardedGenericFlowController = React.forwardRef((props, ref) => <GenericFlowController {...props} innerRef={ref} />);

    // Used in our algorithm to determine if elements can handle being focused
    RefForwardedGenericFlowController.isFlowController = true;

    // eslint-disable-next-line react/static-property-placement
    RefForwardedGenericFlowController.displayName = GenericFlowController.displayName;

    return RefForwardedGenericFlowController;
}
