import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import TB01NavT from '../../../atoms/tb/01-nav-t';
import {white} from '../../../../common/palette';
import asNavigationRow from '../../../util/navigation/hocs/as-row';

const OuterContainer = styled.nav`
    display: flex;
    align-items: stretch;
    max-width: 100%;
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    color: ${white};
`;

const StyledUl = asNavigationRow(styled.ul`
    display: flex;
    align-items: stretch;
    margin: 0;
    padding-left: 14px;
    list-style-type: none;
`);

const StyledTB01NavT = styled(TB01NavT)`
    margin: 0;
    min-width: 105px; /* This counteracts the width jitteriness when the tab is pressed. NOTE: This is potentially brittle as we do not know how long the text will be */
`;

const NM07TopNav = ({items, className, ...htmlAttributes}) => (
    <OuterContainer {...htmlAttributes} className={classnames('NM07TopNav', className)}>
        <StyledUl isLoop={true}>
            {items.map(({href, isActive, onClick, title}) => (
                <StyledTB01NavT
                    {...{
                        href,
                        isActive,
                        onClick,
                    }}
                    aria-current={!!isActive}
                    tabIndex={isActive ? 0 : -1 /* Only one tab catch, the rest is done via keyboard navigation */}
                    key={title}
                >
                    {title}
                </StyledTB01NavT>

            ))}
        </StyledUl>
    </OuterContainer>
);

NM07TopNav.displayName = 'NM07TopNav';

NM07TopNav.propTypes = {
    /** Additional className(s) string. e.g. for extending component */
    className: classNameType,
    /** Array of objects for the navigation links */
    items: propTypes.arrayOf(propTypes.shape({
        href: propTypes.string,
        isActive: propTypes.bool,
        onClick: propTypes.func,
        title: propTypes.string,
    })).isRequired,
};

export default NM07TopNav;
