import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import {slate} from '../../../../common/palette';

import Ic from '..';

const IC284Topics = ({
    color = slate,
    ...args
}) => (
    <Ic color={color} {...args}>
        <path
            fillOpacity=".35"
            stroke={color}
            strokeWidth="3"
            d="M81.036 21.235c2.07 0 3.946.84 5.303 2.197a7.477 7.477 0 0 1 2.197 5.303v33.692c0 2.07-.84 3.946-2.197 5.303a7.477 7.477 0 0 1-5.303 2.197H57.952a10.5 10.5 0 0 0-7.095 2.76L37.96 84.511c-.654.6-1.49.877-2.31.842a3.213 3.213 0 0 1-3.074-3.21v-1.716c0-2.9-1.176-5.525-3.076-7.425a10.467 10.467 0 0 0-7.425-3.075h-2.11a7.477 7.477 0 0 1-5.304-2.197 7.477 7.477 0 0 1-2.197-5.303V28.735c0-2.071.84-3.946 2.197-5.303a7.477 7.477 0 0 1 5.303-2.197z"
        />
    </Ic>
);

IC284Topics.propTypes = {
    /** The icon's secondary color */
    color: colorType,
};

IC284Topics.displayName = 'IC284Topics';

export default IC284Topics;
