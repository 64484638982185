import React from 'react';
import Ic from '..';

const IC32Success = (args) => (
    <Ic {...args}>
        <path d="M34.753 81.965L11 55.25l6.146-5.69 17.607 19.796L81.304 17l6.146 5.69z" />
    </Ic>
);

IC32Success.displayName = 'IC32Success';

export default IC32Success;
