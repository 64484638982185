import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import ScreenArea from './screen-area';

const CentreControls = styled.div`
    box-sizing: border-box;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;

    ${stylesWhenNot('isVisible')`
        visibility: hidden;
    `}
`;

const VPTM05CentreControls = ({
    isVisible = true,
    className,
    topCentreControls,
    topLeftControls,
    topRightControls,
    middleCentreControls,
    middleLeftControls,
    middleRightControls,
    bottomCentreControls,
    bottomLeftControls,
    bottomRightControls,
}) => (
    <CentreControls
        isVisible={isVisible}
        className={classnames('VPTM05CentreControls', className)}
    >
        {!!(topCentreControls || topLeftControls || topRightControls) && (
            <ScreenArea
                verticallyAlign="top"
                centreControls={topCentreControls}
                leftControls={topLeftControls}
                rightControls={topRightControls}
            />
        )}

        {!!(middleCentreControls || middleLeftControls || middleRightControls) && (
            <ScreenArea
                centreControls={middleCentreControls}
                leftControls={middleLeftControls}
                rightControls={middleRightControls}
            />
        )}

        {!!(bottomCentreControls || bottomLeftControls || bottomRightControls) && (
            <ScreenArea
                verticallyAlign="bottom"
                centreControls={bottomCentreControls}
                leftControls={bottomLeftControls}
                rightControls={bottomRightControls}
            />
        )}
    </CentreControls>
);

VPTM05CentreControls.displayName = 'VPTM05CentreControls';

VPTM05CentreControls.propTypes = {
    /** Addtional class names(s), e.g. for custom styled component styles */
    className: classNameType,
    /** Are the controls currently visible? */
    isVisible: propTypes.bool,
    /** top centre controls (e.g. buttons)  */
    topCentreControls: propTypes.node,
    /** top left controls (e.g. buttons)  */
    topLeftControls: propTypes.node,
    /** top right controls (e.g. buttons)  */
    topRightControls: propTypes.node,
    /** middle centre controls (e.g. buttons)  */
    middleCentreControls: propTypes.node,
    /** middle left controls (e.g. buttons)  */
    middleLeftControls: propTypes.node,
    /** middle right controls (e.g. buttons)  */
    middleRightControls: propTypes.node,
    /** bottom centre controls (e.g. buttons)  */
    bottomCentreControls: propTypes.node,
    /** bottom left controls (e.g. buttons)  */
    bottomLeftControls: propTypes.node,
    /** bottom right controls (e.g. buttons)  */
    bottomRightControls: propTypes.node,
};

export default VPTM05CentreControls;
