import React from 'react';

import Ic from '..';

const IC256DontWantToWatchIt = (args) => (
    <Ic {...args}>
        <path d="M69.915 25.961L49.52 46.356 29.125 25.961l-3.164 3.164L46.356 49.52 25.961 69.915l3.164 3.164L49.52 52.684l20.395 20.395 3.164-3.164L52.684 49.52l20.395-20.395z" />
    </Ic>
);

IC256DontWantToWatchIt.displayName = 'IC256DontWantToWatchIt';

export default IC256DontWantToWatchIt;
