import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {A, Button} from 'normalized-styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';

import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {buttonType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {black, kayoGreen, midnight, silverLining, slate} from '../../../../common/palette';
import GA43ArrowInd from '../../ga/43-arrow-ind';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    min-height: 33px;
`;

const SubLabelWrapper = styled.span`
    flex: 0 0 auto;
    transition: ${transition('color')};
    padding-right: 14px;
    color: ${silverLining};
`;

const ArrowWrapper = styled.span`
    transition: ${transition('color')};
    margin-bottom: -2px;
    color: ${silverLining};
`;

const baseStyle = css`
    box-sizing: border-box;
    position: relative;
    align-items: center;
    transition: ${transition('border-color', 'color')};
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: ${rgba(midnight, 0.7)};
    padding: 11px 14px;
    width: 100%;
    color: ${slate};
    font: var(--mui-body-copy-4-light);

    &[aria-pressed='true'],
    &[aria-current='true'] {
        color: ${silverLining};

        &::before {
            content: '';
        }

        /* stylelint-disable-next-line declaration-block-semicolon-newline-after, rule-empty-line-before, order/order */
        ${SubLabelWrapper},
        ${ArrowWrapper} {
            color: ${silverLining};
        }
    }

    &:focus,
    &:hover {
        outline: 0;
        border-color: ${kayoGreen};
        color: ${silverLining};

        /* stylelint-disable-next-line declaration-block-semicolon-newline-after, rule-empty-line-before */
        ${SubLabelWrapper},
        ${ArrowWrapper} {
            color: ${silverLining};
        }
    }

    &::before {
        position: absolute;
        top: 4px;
        bottom: 4px;
        left: 0;
        background-color: ${slate};
        width: 2px;
    }

    &:active::before {
        content: '';
    }
`;

const StyledButton = styled(Button).attrs(({isPressed}) => ({
    'aria-pressed': isPressed,
}))`
    ${baseStyle}

    appearance: none;
    text-align: left;
`;

const StyledLink = styled(A).attrs(({isPressed}) => ({
    'aria-current': isPressed,
}))`
    ${baseStyle}

    display: block;
    text-decoration: none;
`;

const LabelWrapper = styled.span`
    flex: 1 1 auto;
    overflow-x: hidden;
    white-space: nowrap;
    font: var(--mui-body-copy-3);
    mask-image: linear-gradient(to left, transparent 7px, ${black} 35px);

    ${stylesWhen('hasChildren')`
         font: var(--mui-header-6-bold);
         color: ${silverLining};
    `}
`;

const BA42NavBtnSec = React.forwardRef(({
    className,
    href,
    isPressed,
    label,
    subLabel,
    type = href ? undefined : 'button',

    onClick,
    children,

    ...htmlAttributes
}, ref) => {
    const Cta = href ? StyledLink : StyledButton;

    return (

        <Cta
            {...htmlAttributes}
            {...{
                ref,
                href,
                isPressed,
                type,
                onClick,
            }}
            className={classnames('BA42NavBtnSec', className)}
        >
            <Wrapper >
                <LabelWrapper hasChildren={!!children}>{label}</LabelWrapper>
                {!!subLabel && <SubLabelWrapper>{subLabel}</SubLabelWrapper>}
                <ArrowWrapper><GA43ArrowInd /></ArrowWrapper>
            </Wrapper>
            {!!children && (
                <div>
                    {children}
                </div>
            )}
        </Cta>
    );
});

BA42NavBtnSec.propTypes = {
    /** additional CSS classnames to be applied */
    className: classNameType,
    /** children to display with page */
    children: propTypes.node,
    /** hyperlink URL */
    href: propTypes.string,
    /** aria-pressed attribute describes a toggle button */
    isPressed: propTypes.oneOf([true, false, 'mixed']),
    /** label of the button */
    label: propTypes.string.isRequired,
    /** secondary text in between label and arrow */
    subLabel: propTypes.string,
    /** callback to fire on button click */
    onClick: propTypes.func,
    /** react ref, e.g. as passed in by React.forwardRef */
    ref: propTypes.string,
    /** html button type */
    type: buttonType,
};

BA42NavBtnSec.displayName = 'BA42NavBtnSec';

export default BA42NavBtnSec;
