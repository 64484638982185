import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import {blanc, midnight} from '../../../../common/palette';
import Ic from '..';

const IC218XtraInfo = ({
    color = blanc,
    secondaryColor = midnight,
    ...otherProps
}) => (
    <Ic {...otherProps}>
        <g transform="translate(7 7)">
            <circle cx="42.7" cy="42.7" r="42.7" fill={color} />
            <path fill={secondaryColor} d="M37.422 40.082V17.374l15.134 11.354zM19.824 57.134H68.39v4.2H19.824zm16.912 9.45H51.17v4.2H36.736zm-5.936-18.9h26.46v4.2H30.8z" />
        </g>
    </Ic>
);

IC218XtraInfo.propTypes = {
    /** The icon's primary color */
    color: colorType,
    /** The icon's secondary color */
    secondaryColor: colorType,
};

IC218XtraInfo.displayName = 'IC218XtraInfo';

export default IC218XtraInfo;
