import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {lighten} from 'polished';
import {Button, A} from 'normalized-styled-components';
import classnames from 'classnames';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {buttonType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {coal, kayoGreen} from '../../../../common/palette';
import {SCREEN_PRETTY_LG_DESKTOP} from '../../../../common/screen-sizes';

const baseStyles = css`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: ${transition('background-color', 'opacity')};
    border-radius: 6px;
    background-color: ${kayoGreen};
    padding: 15px 40px;
    width: 100%;
    min-height: 71px;
    text-align: center;
    color: ${coal};
    font: var(--mui-header-6-bold);

    ${mediaQuery({minWidthPx: SCREEN_PRETTY_LG_DESKTOP})`
        min-height: 96px;
    `}

    &:hover,
    &:focus {
        outline: 0;
        background-color: ${lighten(0.07, kayoGreen)};
    }

    &:disabled {
        opacity: 0.35;
        cursor: not-allowed;
    }
`;

const StyledButton = styled(Button)`
    ${baseStyles}

    appearance: none;
    border: 0;
`;

const StyledLink = styled(A)`
    ${baseStyles}

    text-decoration: none;
`;

const ButtonSubText = styled.span`
    display: block;
    font: var(--mui-body-copy-4);
`;

const BA46BtnPrMarketing = React.forwardRef(({
    href,
    onClick,
    type = 'button',
    primaryText,
    subText,
    className,
    ...htmlAttributes
}, ref) => {
    const StyledElement = href ? StyledLink : StyledButton;

    return (
        <StyledElement
            {...htmlAttributes}
            {...{
                ref,
                href,
                onClick,
            }}
            className={classnames('BA46BtnPrMarketing', className)}
            type={href ? undefined : type}
        >
            {!!primaryText && primaryText}
            {!!subText && <ButtonSubText>{subText}</ButtonSubText>}
        </StyledElement>
    );
});

BA46BtnPrMarketing.propTypes = {
    /** If it’s actually a link, provide a href */
    href: propTypes.string,
    /** Callback on element click */
    onClick: propTypes.func,
    /** The HTML type of the button */
    type: buttonType,
    /** Primary text on the button */
    primaryText: propTypes.string,
    /** Sub text on the button */
    subText: propTypes.string,
    /** additional CSS classnames to be applied */
    className: classNameType,
};

BA46BtnPrMarketing.displayName = 'BA46BtnPrMarketing';

export default BA46BtnPrMarketing;
