import {css} from 'styled-components';

export const SCREEN_PHONE = 0;
export const SCREEN_375_PHABLET = 375;
export const SCREEN_PHABLET = 415;
export const SCREEN_MOBILE_LANDSCAPE = 568;
export const SCREEN_TABLET = 768;
export const SCREEN_DESKTOP = 1024;
export const SCREEN_LG_DESKTOP = 1280;
export const SCREEN_PRETTY_LG_DESKTOP = 1920;
export const SCREEN_REALLY_LG_DESKTOP = 2560;

export const SMALLEST_SUPPORTED_WIDTH = 320;

export const SCREEN_PORTRAIT = 'portrait';
export const SCREEN_LANDSCAPE = 'landscape';

const screenMinPixel = {
    SCREEN_PHABLET,
    SCREEN_TABLET,
    SCREEN_DESKTOP,
    SCREEN_LG_DESKTOP,
    SCREEN_REALLY_LG_DESKTOP,
};

// https://www.styled-components.com/docs/advanced#media-templates
const MEDIA = Object.keys(screenMinPixel)
    .reduce((acc, label) => Object.assign(acc, {
        [label]: (...args) => css`
            @media (min-width: ${screenMinPixel[label]}px) {
                ${css(...args)}
            }
        `,
    }), {});

export default {
    SCREEN_PHONE,
    SCREEN_PHABLET,
    SCREEN_MOBILE_LANDSCAPE,
    SCREEN_TABLET,
    SCREEN_DESKTOP,
    SCREEN_LG_DESKTOP,
    SCREEN_PRETTY_LG_DESKTOP,
    SCREEN_REALLY_LG_DESKTOP,
    SMALLEST_SUPPORTED_WIDTH,
    SCREEN_PORTRAIT,
    SCREEN_LANDSCAPE,
    MEDIA,
};
