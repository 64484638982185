import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {A, Button} from 'normalized-styled-components';
import classnames from 'classnames';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {SCREEN_1280_DESKTOP} from '../../../../common/screen-sizes';
import {blanc} from '../../../../common/palette';

const baseStyle = css`
    position: relative;
    transition: ${transition('opacity')};
    background-color: transparent;
    padding: 0;
    width: 80px;
    height: 80px;
    color: ${blanc};
    font: var(--ionic-body-copy-2);

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        width: 98px;
        height: 98px;
    `}

    &[aria-pressed='true'],
    &[aria-current='true'] {
        &::after {
            background-color: ${blanc};
        }
    }

    &:focus,
    &:hover {
        position: relative;
        outline: 0;
    }

    &::after {
        position: absolute;
        right: 25%;
        bottom: 0;
        left: 25%;
        transition: ${transition('background-color')};
        background-color: transparent;
        height: 2px;
        content: '';
        pointer-events: none;
    }
`;

const StyledButton = styled(Button).attrs(({isPressed}) => ({
    'type': 'button',
    'aria-pressed': isPressed,
}))`
    ${baseStyle};
    appearance: none;
    display: block;
    border: 0;
`;

const StyledLink = styled(A).attrs(({isPressed}) => ({
    'aria-current': isPressed,
}))`
    ${baseStyle};
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
`;

const BA04GenTil = React.forwardRef(({
    'aria-label': assistiveLabel,
    className,
    isPressed,
    children,
    href,
    ...htmlAttributes
}, ref) => {
    const StyledCta = href ? StyledLink : StyledButton;

    return (
        <StyledCta
            {...htmlAttributes}
            className={classnames('BA04GenTil', className)}
            href={href}
            ref={ref}
            title={assistiveLabel}
            aria-label={assistiveLabel}
            isPressed={isPressed}
        >
            {children}
        </StyledCta>
    );
});

/* eslint-disable quote-props */
BA04GenTil.propTypes = {
    /** Assistive label required for screen readers due to graphical only representation of content */
    'aria-label': propTypes.string,
    /** additional CSS classnames to be applied */
    className: classNameType,
    /** Hyperlink URL */
    href: propTypes.string,
    /** aria-pressed attribute describes a toggle button */
    isPressed: propTypes.oneOf([true, false, 'mixed']),
    /** Content of button, e.g. text */
    children: propTypes.node.isRequired,
};
/* eslint-enable quote-props */

BA04GenTil.displayName = 'BA04GenTil';

export default BA04GenTil;
