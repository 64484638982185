import React from 'react';
import bacon from 'baconjs';
import pick from 'lodash/pick';

import {OR14SeaBlk} from '@fsa-streamotion/streamotion-web-mui';
import {accountsEnvs} from '@fsa-streamotion/streamotion-web-widgets-common';

// import {EVENT_TYPES, getAdobeEventTypeByCategory} from '../../../../utils/analytics/adobe/events';
// import trackUserInteraction from '../../../../utils/analytics/adobe/user-tracking';
import {KAYO_RESOURCES_URL} from '../../../../../todo-move-to-widgets-common/streams/endpoints/resources/sport-menu';
import getSportsNav from './sports-nav';

const {envsFromWidgetSettings} = accountsEnvs;

export default function sportsSeriesSelector({
    hydration = {},
    adobeId,
    defaultImage2560x485,
    defaultText,
    seeMoreCopy,
    seeMoreHref,
    sports = [],
    title,
    key,
    ...rest
}) {
    const {resourcesEnv} = envsFromWidgetSettings(rest);

    const config = {
        resourcesUrl: KAYO_RESOURCES_URL[resourcesEnv],
        // adobeEventType: getAdobeEventTypeByCategory(adobeId) || EVENT_TYPES.OFFERS_SPORTS_CAROUSEL,
        defaultImage2560x485,
        defaultText,
        seeMoreCopy,
        seeMoreHref,
        sports,
        title,
    };

    const activeSportSeriesBus = new bacon.Bus();

    const sportMenu$ = getSportsNav({
        ...config,
        onNavClick: ({sportId, seriesId}) => {
        // onNavClick: ({sportId, seriesId, title}) => {
            // trackUserInteraction({
            //     selection: [sportId, (seriesId && title)].filter(Boolean).join(':'), // sportId is human readable - seriesId is not, so we'll use the button title... developer driven analytics
            //     eventType: config.adobeEventType,
            // });
            activeSportSeriesBus.push({sportId, seriesId});
        },
        hydration: hydration.sportMenu,
    });

    const activeSportSeriesProducerConfig$ = sportMenu$
        .first()
        .map('.data')
        .map(({activeSeriesId, activeSportId}) => ({
            sportId: activeSportId,
            seriesId: activeSeriesId,
        }))
        .concat(activeSportSeriesBus)
        .map(({sportId, seriesId = ''}) => {
            const {text, image2560x485, series = []} = config.sports.find((sportConfig) => sportId === sportConfig.sportId) || {};

            if (!seriesId) {
                return {text, image2560x485};
            }

            const seriesConfig = series.find((seriesConfig) => seriesConfig.seriesId === seriesId.toString()) || {};

            return {
                text: seriesConfig.text || text,
                image2560x485: seriesConfig.image2560x485 || image2560x485,
            };
        })
        .map(({text, image2560x485}) => ({
            text: text || config.defaultText,
            image2560x485: image2560x485 || config.defaultImage2560x485,
        }));

    const reactElement$ = bacon.combineTemplate({
        ...pick(config, [
            'seeMoreCopy',
            'seeMoreHref',
            'title',
        ]),
        children: activeSportSeriesProducerConfig$.map('.text'),
        sportMenu: sportMenu$.map('.reactElement'),
        backgroundImageUrl: activeSportSeriesProducerConfig$.map('.image2560x485'),
        key,
    })
        .map((props) => <OR14SeaBlk {...props} />);

    return bacon.combineTemplate({
        reactElement: reactElement$,
        hydration: {
            sportMenu: sportMenu$.map('.hydration'),
        },
    });
}
