import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {satellite} from '../../../../../common/palette';
import VPGA03Thumbnail from '../../../../atoms/vp/ga/03-thumbnail';
import EMPTY_IMAGE_SRC from '../../../../../common/empty-image-src';

const STEM_SIZE_PX = 8;
const THUMBNAIL_WIDTH = 215;

const Container = styled.figure`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Stem = styled.div`
    border-top: ${STEM_SIZE_PX / 2}px solid ${satellite};
    border-right: ${STEM_SIZE_PX / 2}px solid transparent;
    border-left: ${STEM_SIZE_PX / 2}px solid transparent;
    width: 0;
    height: ${STEM_SIZE_PX};

    ${stylesWhen('isTabletUp')`
        border-top-width: ${STEM_SIZE_PX}px;
        border-right-width: ${STEM_SIZE_PX}px;
        border-left-width: ${STEM_SIZE_PX}px;
    `}
`;

const HoverThumbnailView = ({
    url = EMPTY_IMAGE_SRC,
    isTabletUp = false,
    thumbnailWidth = THUMBNAIL_WIDTH,
}) => (
    <Container isTabletUp={isTabletUp}>
        <VPGA03Thumbnail src={url} isTabletUp={isTabletUp} thumbnailWidth={thumbnailWidth} isLarge={true} />
        <Stem isTabletUp={isTabletUp} />
    </Container>
);

HoverThumbnailView.displayName = 'HoverThumbnailView';

HoverThumbnailView.propTypes = {
    url: propTypes.string,
    isTabletUp: propTypes.bool,
    thumbnailWidth: propTypes.number,
};

export default HoverThumbnailView;
