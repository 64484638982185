import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {A, Button} from 'normalized-styled-components';
import {CSSTransition} from 'react-transition-group';
import {lighten} from 'polished';
import classnames from 'classnames';

import {mediaQuery, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {buttonType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition, TRANSITION_CLASSNAMES} from '../../../../common/animations';
import {kayoGreen, coal} from '../../../../common/palette';
import {SCREEN_LG_DESKTOP, SCREEN_REALLY_LG_DESKTOP, SCREEN_TABLET} from '../../../../common/screen-sizes';

const ANIMATE_IN_TRANSITIONS = [
    {
        type: 'color',
        speed: 100,
        style: 'ease-in',
        delay: 400,
    },
    {
        type: 'transform',
        speed: 100,
        style: 'ease-in',
        delay: 200,
    },
];

const ANIMATION_DURATION_MS = ANIMATE_IN_TRANSITIONS.reduce((duration, {speed, delay}) => duration + speed + delay, 0);

const baseStyles = css`
    box-sizing: border-box;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    transform-origin: left center;
    transition: ${transition('background-color', 'opacity')};
    border-radius: 4px;
    background-color: ${kayoGreen};
    padding: 0 14px;
    height: 40px;
    text-align: center;
    color: ${coal};
    font: var(--mui-body-copy-3);

    ${({isBlock}) => isBlock ? css`
        display: flex;
        width: 100%;
    ` : css`
        display: inline-flex;
        min-width: 206px;
        max-width: 220px;

        ${stylesWhenNot('theme.isAppDock')`
            ${mediaQuery({minWidthPx: SCREEN_TABLET})`
                max-width: 280px;
            `}

            ${mediaQuery({minWidthPx: SCREEN_REALLY_LG_DESKTOP})`
                max-width: 320px;
                min-width: 280px;
            `}
        `}
    `}

    ${stylesWhenNot('theme.isAppDock')`
        ${mediaQuery({minWidthPx: SCREEN_TABLET})`
            height: 50px;
            padding-left: 21px;
            padding-right: 21px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
            padding-left: 42px;
            padding-right: 42px;
        `}
    `}

    /* stylelint-disable-next-line no-duplicate-selectors */
    &.${TRANSITION_CLASSNAMES.enter},
    &.${TRANSITION_CLASSNAMES.exit},
    &.${TRANSITION_CLASSNAMES.exitDone} {
        transform: scaleX(0);
        color: transparent;
    }

    &:hover,
    &:focus {
        outline: 0;
        background-color: ${lighten(0.07, kayoGreen)};
    }

    &:disabled {
        opacity: 0.35;
        cursor: not-allowed;
    }

    &.${TRANSITION_CLASSNAMES.enter} {
        transition: ${ANIMATE_IN_TRANSITIONS.map(({type, speed, style, delay}) => `${type} ${speed}ms ${style} ${delay}ms`).join(', ')}, ${transition('background-color', 'opacity')};
    }

    &.${TRANSITION_CLASSNAMES.enterActive} {
        transform: scaleX(1);
        color: ${coal};
    }
`;

const StyledButton = styled(Button)`
    ${baseStyles}

    appearance: none;
    border: 0;
`;

const StyledLink = styled(A)`
    ${baseStyles}

    text-decoration: none;
`;

const BA01BtnPr = React.forwardRef(({
    children,
    href,
    isBlock,
    isVisible = true,
    onClick,
    className,
    type = 'button',
    ...htmlAttributes
}, ref) => {
    const StyledElement = href ? StyledLink : StyledButton;

    return (
        <CSSTransition
            in={isVisible}
            timeout={ANIMATION_DURATION_MS}
            classNames={TRANSITION_CLASSNAMES}
        >
            <StyledElement
                {...htmlAttributes}
                {...{
                    ref,
                    href,
                    isBlock,
                    isVisible,
                    onClick,
                }}
                className={classnames('BA01BtnPr', className)}
                type={href ? undefined : type}
            >
                {children}
            </StyledElement>
        </CSSTransition>
    );
});

BA01BtnPr.propTypes = {
    /** Content of button, e.g. text */
    children: propTypes.node.isRequired,
    /* If it’s actually a link, provide a href */
    href: propTypes.string,
    /** Whether the button fills its parent's width or not */
    isBlock: propTypes.bool,
    /** Whether the button is visible (and thus, animated in) or not */
    isVisible: propTypes.bool,
    /** Callback on element click */
    onClick: propTypes.func,
    /** The HTML type of the button */
    type: buttonType,
    /** Additional CSS class(es) */
    className: classNameType,
};

BA01BtnPr.displayName = 'BA01BtnPr';

export default BA01BtnPr;
