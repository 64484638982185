/**
 * @type {number[]} `resources.streamotion.com.au` whitelisted imwidth values in the default akamai image manager policy
 */
export const RESOURCES_IMWIDTHS = [
    40,     // sidebar avatar image
    80,     // sidebar team logo
    100,    // sport menu logo
    320,    // SMALLEST_SUPPORTED_WIDTH
    415,
    768,
    1024,
    1280,
    1440,
    1920,
    2560,
    3840,
    5000,   // largest possible imwidth
];

/**
 * @type {number[]} `vmndims.binge.com.au` whitelisted imwidth values in the default akamai image manager policy
 */
export const VMNDIMS_IMWIDTHS = [
    225,    // smallest video carousel tile
    320,    // smallest supported screen size
    415,
    768,
    1024,
    1280,
    1440,
    1920,
    2560,
    3840,
    5000,   // largest possible imwidth
];
