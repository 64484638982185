import React from 'react';

import Ic from '..';

const IC133Scrub = (args) => (
    <Ic {...args}>
        <circle cx="12.6" cy="12.6" r="12.6" transform="translate(37 37)" />
    </Ic>
);

IC133Scrub.displayName = 'IC133Scrub';

export default IC133Scrub;
