import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Section} from 'normalized-styled-components';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import CommonTransition from '../../../../../common/common-transition';
import {fadeInOut} from '../../../../../common/animations';
import HoverThumbnailView from './view';

const Container = styled(Section)`
    pointer-events: none;
    ${fadeInOut}
`;

const VPTM02HoverThumbnail = React.forwardRef(({
    src,
    content,
    shouldShowArrow,
    isVisible = true,
    className,
}, ref) => (
    <CommonTransition in={isVisible}>
        <Container className={classnames('VPTM02HoverThumbnail', className)} ref={ref}>
            <HoverThumbnailView {...{src, content, shouldShowArrow}} />
        </Container>
    </CommonTransition>
));

VPTM02HoverThumbnail.propTypes = {
    src: propTypes.string,
    content: propTypes.node,
    shouldShowArrow: propTypes.bool,
    isVisible: propTypes.bool,
    /** Optional additional class name */
    className: classNameType,
};

VPTM02HoverThumbnail.displayName = 'VPTM02HoverThumbnail';

export default VPTM02HoverThumbnail;
