import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {mediaQuery, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {SCREEN_TABLET} from '../../../../common/screen-sizes';

const StyledList = styled.ul`
    box-sizing: border-box;
    display: grid;
    row-gap: 7px;
    grid-template-columns: 1fr;
    margin: 0 auto;
    padding: 0;
    width: ${({isBlock}) => isBlock ? '100%' : '300px'};

    ${stylesWhenNot('theme.isAppDock')`
        ${mediaQuery({minWidthPx: SCREEN_TABLET})`
            width: 100%;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: max-content;
        `}
    `}
`;

const XX03FeatureList = ({
    className,
    isBlock,
    children,
    ...htmlAttributes
}) => (
    <StyledList
        {...htmlAttributes}
        className={classnames('XX03FeatureList', className)}
        isBlock={isBlock}
        itemsPerColumn={Math.ceil(React.Children.count(children) / 2)}
    >
        {children}
    </StyledList>
);

XX03FeatureList.displayName = 'XX03FeatureList';

XX03FeatureList.propTypes = {
    /** Does the list occupy the full width of its parent? */
    isBlock: propTypes.bool,
    children: propTypes.node,
    /** Optional additional class(es) */
    className: classNameType,
};

export default XX03FeatureList;
