import React, {Fragment} from 'react';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import GA85Apple from '../../../atoms/ga/85-apple';
import OR130AccountBlk from '../../../organisms/or/130-account-blk';
import TM19Pmnt from '../19-pmnt';

const TM19PmntItunes = ({
    className,
    ...htmlAttributes
}) => (
    <Fragment>
        <TM19Pmnt
            {...htmlAttributes}
            className={classnames('TM19PmntItunes', className)}
            icon={<GA85Apple />}
        >
            <div>
                iTunes Subscription
            </div>
            <div>
                Your Binge subscription is managed by iTunes
            </div>
        </TM19Pmnt>
        <OR130AccountBlk
            displaySubtext={`To see details or make changes, use an iOS device or iTunes.
                If you cancel your membership, you’ll have access to Binge until the end of your billing period.
            `}
        />
    </Fragment>
);

TM19PmntItunes.propTypes = {
    /** Additional class(es) */
    className: classNameType,
};

TM19PmntItunes.displayName = 'TM19PmntItunes';

export default TM19PmntItunes;
