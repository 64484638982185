import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {akela} from '../../../../common/palette';

import {
    SCREEN_768_TABLET,
    SCREEN_1024_DESKTOP,
    SCREEN_1280_DESKTOP,
    SCREEN_1920_DESKTOP,
    SCREEN_2560_DESKTOP,
} from '../../../../common/screen-sizes';

const CarouselTitle = styled.h3`
    margin: 0;
    color: ${akela};
    font: var(--ionic-header-7-bold);
`;

const ItemList = styled.ul`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
    padding-left: 0;
    list-style-type: none;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        grid-gap: 13px;
        grid-template-columns: repeat(5, 1fr);
    `}

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        grid-gap: 14px;
        grid-template-columns: repeat(6, 1fr);
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        grid-gap: 17px;
        grid-template-columns: repeat(5, 1fr);
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        grid-gap: 23px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
        grid-gap: 35px;
    `}
`;

const CAM09Grid = ({
    className,
    label,
    children,
    ...htmlAttributes
}) => (
    <section
        {...htmlAttributes}
        className={classnames('CAM09Grid', className)}
    >
        {!!label && <CarouselTitle>{label}</CarouselTitle>}
        <ItemList>
            {React.Children.map(children, (child) => (
                <li key={child.key}>{child}</li>
            ))}
        </ItemList>
    </section>
);

CAM09Grid.propTypes = {
    /** Foreground items */
    children: propTypes.node,
    /** className */
    className: classNameType,
    /** Carousel Label */
    label: propTypes.string,
};

CAM09Grid.displayName = 'CAM09Grid';

export default CAM09Grid;
