import React from 'react';

import Ic from '..';

const IC52Cross = (args) => (
    <Ic {...args}>
        <path
            d="M91.659 0 100 8.341 58.201 50 100 91.659 91.659 100 50 58.201 8.341 100 0 91.659 41.799 50 0 8.341 8.341 0 50 41.799z"
            fillRule="evenodd"
        />
    </Ic>
);

IC52Cross.displayName = 'IC52Cross';

export default IC52Cross;
