import React from 'react';

import styled, {css} from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import propTypes from 'prop-types';
import classnames from 'classnames';
import {rgba} from 'polished';

import {mediaQuery, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {srcsetOptionsType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import stringifySrcsetOptions from '../../../../common/stringify-srcset-options';
import {getLargestImage} from '../../../../common/tile-utils';
import {white} from '../../../../common/palette';

const Z_INDEX_ABOVE_TILE_IMG = 1;

const baseProductTileStyles = css`
    position: relative;
    padding: 0;

    ${stylesWhenNot('isActive')`
        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 3px;
            background-color: ${rgba(white, 0.5)};
            position: absolute;
            top: 0;
            right:0;
            bottom:0;
            left: 0;

            ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
                border-radius: 5px;
            `}
        }
    `}
`;

const baseHoveredStyles = css`
    display: inline-block;
    text-decoration: none;

    &:hover,
    &:focus {
        transform: scale(107%);
    }
`;

const StyledTileWrapper = styled.div`
    ${baseProductTileStyles};
`;

const StyledTileWrapperButton = styled.button.attrs(() => ({
    type: 'button',
}))`
    ${baseProductTileStyles};
    ${baseHoveredStyles};

    appearance: none;
    border: 0;
`;

const StyledTileWrapperLink = styled.a`
    ${baseProductTileStyles};
    ${baseHoveredStyles};

    text-decoration: none;
`;

const TileImage = styled.img`
    display: block;
    border-radius: 3.3px;
    width: 100%;
    height: 100%;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        border-radius: 4.5px;
    `}
`;

const BadgeImg = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-50%);
    z-index: ${Z_INDEX_ABOVE_TILE_IMG};
    width: 100%;
`;

const IconWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(50%) translateY(-50%);
    z-index: ${Z_INDEX_ABOVE_TILE_IMG};
    border-radius: 50%;
    width: 14px;
    height: 14px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 18px;
        height: 18px;
    `}
`;

/**
 * A tile component that displays a product image, an optional badge overlay and an optional icon.
 * Wrapped by hyperlink element if it has href props, otherwise wrapped by button element
 *
 * @param {string} className - Additional class(es) for the component.
 * @param {boolean} isActive - Whether the item is active.
 * @param {Object} srcsetOptions - Srcset for product image.
 * @param {Object} badgeSrcsetOptions - Srcset for badge overlay, e.g. "Staff".
 * @param {string} caption - Image caption that serves as descriptive text for screen readers, e.g. alt text.
 * @param {string} badgeCaption - Badge caption that serves as descriptive text for screen readers, e.g. alt text.
 * @param {ReactNode} icon - Icon to use for product image.
 * @param {string} href - A URL to navigate to when the tile is clicked
 * @param {Function} onClick - A function that invoked when the tile is clicked
 * @param {string} srcsetSizes - Srcset sizes.
 * @param {Object} htmlAttributes - Additional attributes to apply to the HTML element.
 * @returns {JSX.Element} - A React element representing the product tile.
 */
const TM36ProductTile = ({
    badgeSrcsetOptions,
    icon,
    href,
    onClick,
    caption,
    badgeCaption = '',
    className,
    isActive,
    srcsetOptions,
    srcsetSizes,
    ...htmlAttributes
}) => {
    const StyledElement = (() => {
        if (isActive && href) {
            return StyledTileWrapperLink;
        } else if (isActive && onClick) {
            return StyledTileWrapperButton;
        } else {
            return StyledTileWrapper;
        }
    })();

    return (
        <StyledElement
            className={classnames('TM36ProductTile', className)}
            isActive={isActive}
            href={isActive ? href : undefined}
            onClick={isActive ? onClick : undefined}
            {...htmlAttributes}
        >
            <TileImage
                src={getLargestImage(srcsetOptions)}
                srcSet={stringifySrcsetOptions(srcsetOptions)}
                sizes={srcsetSizes}
                alt={caption}
            />
            {!isEmpty(badgeSrcsetOptions) && (
                <BadgeImg
                    srcSet={stringifySrcsetOptions(badgeSrcsetOptions)}
                    sizes={srcsetSizes}
                    src={getLargestImage(badgeSrcsetOptions)}
                    alt={badgeCaption}
                />
            )}
            {!!icon && (
                <IconWrapper>
                    {icon}
                </IconWrapper>
            )}
        </StyledElement>
    );
};

TM36ProductTile.displayName = 'TM36ProductTile';

TM36ProductTile.propTypes = {
    /** Additional class(es) */
    className: classNameType,
    /** Whether the item is active */
    isActive: propTypes.bool,
    /** Srcset for tile image */
    srcsetOptions: srcsetOptionsType,
    /** Srcset for badge overlay, e.g. "Staff" */
    badgeSrcsetOptions: srcsetOptionsType,
    /** Image caption that serves as descriptive text for screen readers, e.g. alt text */
    caption: propTypes.string,
    /** Badge caption that serves as descriptive text for screen readers, e.g. alt text */
    badgeCaption: propTypes.string,
    /** Icon to use for product image */
    icon: propTypes.node,
    /** If it’s actually a link, provide a href */
    href: propTypes.string,
    /** Callback on element click */
    onClick: propTypes.func,
    /** Srcset sizes */
    srcsetSizes: propTypes.string,
};

export default TM36ProductTile;
