import React from 'react';
import Ic from '..';

const IC38News = (args) => (
    <Ic {...args}>
        <path d="M74.7 17.5c-1.8-1.1-3.9-1.1-5.8-.1L39.8 33.2H14.1c-1.5 0-2.8 1.2-2.8 2.8v28c0 1.5 1.2 2.8 2.8 2.8h10.3v10.4H30V66.8h9.9L69 82.7c.9.5 1.8.7 2.8.7 1 0 2-.3 3-.8 1.8-1.1 2.8-2.9 2.8-5v-55c-.1-2.2-1.1-4.1-2.9-5.1zm-2.7 60s0 .2-.1.2c-.1.1-.2 0-.3 0L43.2 62.2v-7.6h-5.6v6.6H16.9V38.8h20.7v6.6h5.6v-7.6l28.4-15.5s.1-.1.3 0c.1.1.1.2.1.2v55zM83.7 41.4l-1.4 2.4c2.2 1.3 3.6 3.7 3.6 6.2 0 2.6-1.4 5-3.6 6.2l1.4 2.4c3.1-1.8 5-5.1 5-8.6 0-3.6-2-6.9-5-8.6z" />
    </Ic>
);

IC38News.displayName = 'IC38News';

export default IC38News;
