import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import noop from 'lodash/noop';

import {mediaQuery, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {white} from '../../../../common/palette';
import {SCREEN_415_PHABLET} from '../../../../common/screen-sizes';
import BA01BtnPr from '../../../atoms/ba/01-btn-pr';
import BA08BtnSec from '../../../atoms/ba/08-btn-sec';
import IA02Check from '../../../atoms/ia/02-check';
import {IC50Success, IC103Loading} from '../../../atoms/ic';
import TM24AlertTile from '../../tm/24-alert-tile';

const Container = styled.section`
    color: ${white};
`;

const TncContainer = styled.div`
    margin: 14px 0 28px;

    ${stylesWhenNot('theme.isAppDock')`
        ${mediaQuery({minWidthPx: SCREEN_415_PHABLET})`
            margin-bottom: 49px;
        `}
    `}
`;

const ErrorMessageContainer = styled.div`
    margin: 0 auto 14px;
    width: 100%;
    max-width: 565px;
`;

const SubmitBtnContainer = styled.div`
    display: flex;
    justify-content: center;

    > button:not(:last-of-type) {
        margin-right: 14px;
    }
`;

const TERMS_AND_CONDITIONS_DEFAULT = (
    <div>
        I agree to the
        {' '}
        <a href="/" target="_blank" rel="noreferrer noopener">
            terms and conditions
        </a>
        {', ' /* eslint-disable-line react/jsx-curly-brace-presence */}
        <a href="/" target="_blank" rel="noreferrer noopener">
            privacy policy
        </a>
        {' and ' /* eslint-disable-line react/jsx-curly-brace-presence */}
        <a href="/" target="_blank" rel="noreferrer noopener">
            collection statement
        </a>
    </div>
);

const IM06ConfirmBox = ({
    cancelText,
    className,
    buttonText,
    disabled,
    errorMessage,
    hasCheckbox = true,
    isLoading,
    isSuccessful,
    onCancelClick,
    onCheckboxChange = noop,
    onConfirmClick,
    termsAndConditions = TERMS_AND_CONDITIONS_DEFAULT,
    ...htmlAttributes
}) => (
    <Container
        className={classnames('IM06ConfirmBox', className)}
        {...htmlAttributes}
    >
        <TncContainer>
            {
                hasCheckbox ? (
                    <IA02Check
                        disabled={disabled || isLoading || isSuccessful}
                        onChange={(event) => void onCheckboxChange(!!event.target.checked)}
                        label={termsAndConditions}
                    />
                ) : termsAndConditions
            }
        </TncContainer>
        {!!errorMessage && (
            <ErrorMessageContainer role="alert">
                <TM24AlertTile>
                    {errorMessage}
                </TM24AlertTile>
            </ErrorMessageContainer>
        )}
        <SubmitBtnContainer>
            {isLoading ? ( // eslint-disable-line no-nested-ternary
                <IC103Loading
                    size="60px"
                    color={white}
                />
            ) : isSuccessful ? (
                <IC50Success
                    size="60px"
                />
            ) : (
                <React.Fragment>
                    {!!cancelText && (
                        <BA08BtnSec
                            disabled={disabled}
                            onClick={onCancelClick}
                        >
                            {cancelText}
                        </BA08BtnSec>
                    )}
                    {!!buttonText && (
                        <BA01BtnPr
                            disabled={disabled}
                            onClick={onConfirmClick}
                        >
                            {buttonText}
                        </BA01BtnPr>
                    )}
                </React.Fragment>
            )}
        </SubmitBtnContainer>
    </Container>
);

IM06ConfirmBox.propTypes = {
    /** Text for the confirmation button */
    buttonText: propTypes.string,
    /** Text for the cancellation button */
    cancelText: propTypes.string,
    /** Callback for cancel button */
    onCancelClick: propTypes.func,
    /** Callback passed down from Checkout Widget */
    onConfirmClick: propTypes.func,
    /** A node representing the label to describe the checkbox */
    termsAndConditions: propTypes.node,
    /** A node error message */
    errorMessage: propTypes.node,
    /** callback to manage state of checkbox */
    onCheckboxChange: propTypes.func,
    /** Are the checkbox and button disabled? */
    disabled: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    /** Loading state */
    isLoading: propTypes.bool,
    /** Successful state */
    isSuccessful: propTypes.bool,
    /** Additional class(es) */
    className: classNameType,
    /** Require confirmation checkbox */
    hasCheckbox: propTypes.bool,
};

IM06ConfirmBox.displayName = 'IM06ConfirmBox';

export default IM06ConfirmBox;
