import React from 'react';

import {indigo} from '../../../../common/palette';

import Ic from '..';

const IC19JLive = (args) => (
    <Ic {...args}>
        <g fillRule="nonzero">
            <path d="M21.294 40.51h73.042v43.25H21.294z" />
            <path fill={indigo} d="M45.986 68.375H38.16v-13.5h3.172v10.886h4.682zm5.38 0h-3.172v-13.5h3.172zm14.966-13.499-5.003 13.499h-3.018l-5.017-13.5h3.41l3.172 9.293 3.158-9.292zM77.19 68.375h-8.93v-13.5h8.762v2.53h-5.59V60.2h5.283v2.53h-5.297v3.13h5.772z" />
            <path d="m69 16-2.794 3.116 5.017 4.514H31.55C8.48 23.63 5 44.283 5 44.493l4.192.643c0-.713 2.935-17.314 22.359-17.314h39.56l-4.192 4.681 3.116 2.795 9.139-10.159L69.001 16z" />
        </g>
    </Ic>
);

IC19JLive.displayName = 'IC19JLive';

export default IC19JLive;
