import React from 'react';
import Ic from '..';

const IC322AppleLogo = (args) => (
    <Ic {...args}>
        <path d="M79.052 36.57c-.435.338-8.108 4.666-8.108 14.289 0 11.131 9.764 15.069 10.056 15.167-.045.24-1.551 5.393-5.148 10.643-3.207 4.62-6.556 9.233-11.651 9.233s-6.407-2.962-12.289-2.962c-5.732 0-7.77 3.06-12.43 3.06-4.661 0-7.913-4.275-11.652-9.526C23.5 70.308 20 60.73 20 51.639c0-14.581 9.471-22.315 18.792-22.315 4.953 0 9.082 3.256 12.191 3.256 2.96 0 7.576-3.45 13.21-3.45 2.136 0 9.809.194 14.859 7.44zM61.518 22.956c2.33-2.768 3.98-6.608 3.98-10.448 0-.533-.046-1.073-.143-1.508-3.792.143-8.302 2.528-11.022 5.686-2.136 2.43-4.129 6.27-4.129 10.163 0 .585.097 1.17.142 1.358.24.045.63.097 1.02.097 3.401 0 7.68-2.28 10.152-5.348z" />
    </Ic>
);

IC322AppleLogo.displayName = 'IC322AppleLogo';

export default IC322AppleLogo;
