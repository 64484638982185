import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {midnight, silverLining, white} from '../../../../common/palette';
import GA92BrandLogo from '../../../atoms/ga/92-brand-logo';
import IC103Loading from '../../../atoms/ic/103-loading';

const Main = styled.main`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    background-color: ${midnight};
    padding: 0 5vw;
`;

const Container = styled.div`
    width: 100%;
`;

const Header = styled.header`
    display: flex;
    align-items: center;
    background-color: ${midnight};
    padding: 0 5vw;
    min-height: 70px;
`;

const LogoWrapper = styled.span`
    padding: 5px 0;
    width: 100px;
`;

const HeaderText = styled.h1`
    margin-left: 40px;
    width: 100%;
    text-align: center;
    color: ${silverLining};
    font: var(--mui-header-6);
`;

const Error = styled.div`
    padding: 20px;
    width: 100%;
    text-align: center;
    color: ${silverLining};
    font: var(--mui-body-copy-1);
`;

const LoadingWrapper = styled.div`
    margin: 0 auto;
    padding: 30px;
    width: 100px;
`;

const OR131FailOpen = ({
    content,
    isError,
    isLoading,
    message,
}) => (
    <Container>
        <Header>
            <LogoWrapper>
                <GA92BrandLogo title="Kayo Sports" isInternalMode={true} />
            </LogoWrapper>
            {!!message && (
                <HeaderText>{message}</HeaderText>
            )}
        </Header>
        <Main>
            {isLoading && !isError ? (
                <LoadingWrapper aria-label="Loading content">
                    <IC103Loading color={white} />
                </LoadingWrapper>
            ) : content}
            {!!isError && <Error role="alert">Failed to fetch content</Error>}
        </Main>
    </Container>
);

OR131FailOpen.propTypes = {
    content: propTypes.node,
    isError: propTypes.bool,
    isLoading: propTypes.bool,
    message: propTypes.string,
};

OR131FailOpen.displayName = 'OR131FailOpen';

export default OR131FailOpen;
