import React from 'react';

import Ic from '..';

const IC99ExitThtr = (args) => (
    <Ic {...args}>
        <path d="M91.47 70.631h-5.836v-4.12h1.717V20.12H12.12v46.391h2.115v4.12H8V16h83.47z" />
        <path d="M71.956 46.035v29.773H27.858V46.035h44.098m8.24-8.24H19.618v46.253h60.578V37.795z" />
    </Ic>
);

IC99ExitThtr.displayName = 'IC99ExitThtr';

export default IC99ExitThtr;
