import React from 'react';
import Ic from '..';

const IC43Valid = (args) => (
    <Ic {...args}>
        <path d="M37.753 82.747L14 56.032l6.146-5.69 17.607 19.796 46.551-52.356 6.146 5.69z" />
    </Ic>
);

IC43Valid.displayName = 'IC43Valid';

export default IC43Valid;
