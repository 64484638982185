import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {Section} from '../../../../common/normalized-styled-components';
import {ink} from '../../../../common/palette';

const StyledSection = styled(Section)`
    margin: 0 auto;
    width: 100%;
    text-align: center;
    color: ${ink};
`;

const StyledH2 = styled.h2`
    margin: 0 0 5px;
    font: var(--quicksilver-header-4);
`;

const Copy = styled.div`
    font: var(--quicksilver-body-copy-3);
`;

const OR16MarBlk = ({
    className,
    displayHeader,
    children,
    ...htmlAttributes
}) => (
    <StyledSection
        {...htmlAttributes}
        className={classnames('OR16MarBlk', className)}
    >
        {!!displayHeader && <StyledH2>{displayHeader}</StyledH2>}
        {!!children && <Copy>{children}</Copy>}
    </StyledSection>
);

OR16MarBlk.propTypes = {
    /** Additional class name(s) */
    className: classNameType,
    /** CMS block heading copy */
    displayHeader: propTypes.node,
    /** CMS copy */
    children: propTypes.node,
};

OR16MarBlk.displayName = 'OR16MarBlk';

export default OR16MarBlk;
