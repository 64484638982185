
/**
 * Given an array of breakpoint sizes, returns an array of relevant media queries
 *
 * @param {Array} breakpointPageSizes  array of breakpoint sizes
 * @returns {Array}                    array of breakpoint size and relevant media query
 */
export default function getMediaQueries(breakpointPageSizes = []) {
    return breakpointPageSizes
        .map(((breakpoint) => parseInt(breakpoint)))
        .sort(((a, b) => a - b))
        .map((size, index, sortedBreakpoints) => {
            if (index === 0) {
                return [size, `(max-width: ${sortedBreakpoints[1] - 1}px)`];
            } else if (index === sortedBreakpoints.length - 1) {
                return [size, `(min-width: ${size}px)`];
            } else {
                return [size, `(min-width: ${size}px) and (max-width: ${sortedBreakpoints[index + 1] - 1}px)`];
            }
        });
}
