import React from 'react';

import Ic from '..';

const IC97Eq = (args) => (
    <Ic {...args}>
        <path fillRule="nonzero" d="M43.5 34.309a3.5 3.5 0 0 1 3.5 3.5v44.417h-7V37.809a3.5 3.5 0 0 1 3.5-3.5zm-15 19.131a3.5 3.5 0 0 1 3.5 3.5v25.244h-7V56.939a3.5 3.5 0 0 1 3.5-3.5zm30-37.94A3.5 3.5 0 0 1 62 19v63.575h-7V19a3.5 3.5 0 0 1 3.5-3.5zm15 38A3.5 3.5 0 0 1 77 57v25.245h-7V57a3.5 3.5 0 0 1 3.5-3.5z" />
    </Ic>
);

IC97Eq.displayName = 'IC97Eq';

export default IC97Eq;
