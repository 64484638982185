import React from 'react';
import Ic from '..';

const IC228MyAccount = (args) => (
    <Ic {...args}>
        <g transform="translate(11 11)">
            <path d="M57.12 58.478v-1.624c-.084-2.562-.434-3.29-3.332-3.962-5.04-1.162-9.8-2.254-11.508-6.076-.906-2.184-.687-4.673.588-6.664 3.682-6.79 4.606-12.684 2.534-16.156-1.376-2.054-3.733-3.23-6.202-3.094-2.419-.109-4.722 1.04-6.09 3.038-2.058 3.43-1.162 9.352 2.492 16.24 1.252 2.001 1.455 4.486.546 6.664-1.736 3.808-6.524 4.914-11.606 6.076-2.688.63-3.094 1.4-3.206 3.836l-.07 1.61-1.134-1.162c-.637-.654-1.24-1.341-1.806-2.058l-.126-.238V54.6c.448-2.702 1.96-4.06 5.362-4.844h.238c4.27-.98 8.288-1.918 9.352-4.2.445-1.25.273-2.635-.462-3.738-4.2-8.064-5.124-14.994-2.408-19.502 1.896-3.073 5.311-4.875 8.918-4.704 3.627-.194 7.072 1.604 8.988 4.69 2.8 4.578 1.848 11.466-2.464 19.432-.74 1.095-.922 2.475-.49 3.724 1.05 2.324 5.026 3.234 9.226 4.2l.392.084c3.472.798 4.984 2.198 5.39 4.97v.294l-.196.238c-.568.72-1.176 1.406-1.82 2.058l-1.106 1.176z" />
            <path d="M39.2 8.4C56.21 8.4 70 22.19 70 39.2S56.21 70 39.2 70 8.4 56.21 8.4 39.2 22.19 8.4 39.2 8.4m0-8.4C17.55 0 0 17.55 0 39.2c0 21.65 17.55 39.2 39.2 39.2 21.65 0 39.2-17.55 39.2-39.2 0-10.396-4.13-20.367-11.481-27.719C59.567 4.13 49.596 0 39.2 0z" />
        </g>
    </Ic>
);

IC228MyAccount.displayName = 'IC228MyAccount';

export default IC228MyAccount;
