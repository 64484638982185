import React from 'react';
import property from 'lodash/property';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {rgba} from 'polished';
import classnames from 'classnames';

import {mediaQuery, stylesIfElse} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {white} from '../../../../common/palette';
import {A, Button} from '../../../../common/normalized-styled-components';
import {transition} from '../../../../common/animations';
import {SCREEN_1280_DESKTOP} from '../../../../common/screen-sizes';

const baseStyle = css`
    box-sizing: border-box;
    position: relative;
    padding: 3px;
    width: 80px;
    height: 80px;
    overflow: hidden;
    font: var(--infinity-header-8);

    ${stylesIfElse('backgroundSrc')`
        transition: ${transition('opacity')};
        opacity: 0.6;
        background: 0 / cover url(${property('backgroundSrc')});
        color: ${white};
    ``
        transition: ${transition('background', 'color')};
        background: ${rgba(white, 0.1)};
        color: ${rgba(white, 0.6)};
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        width: 98px;
        height: 98px;
    `}

    &[aria-pressed='true'],
    &[aria-current='true'] {
        outline: 0;
        ${stylesIfElse('backgroundSrc')`
            opacity: 1;
        ``
            background: ${rgba(white, 0.2)};
            color: ${white};

            &:focus {
                color: ${white};
            }
        `}

        &::after {
            background-color: ${white};
        }
    }

    &[aria-pressed='false'],
    &[aria-current='false'] {
        &:focus {
            ${stylesIfElse('backgroundSrc')`
                opacity: 1;
                outline: 0;
            ``
                color: ${white};
            `}
        }
    }

    &:active {
        outline: 0;
    }

    &:hover {
        ${stylesIfElse('backgroundSrc')`
            opacity: 1;
        ``
            color: ${white};
        `}
    }

    &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        transition: ${transition('background-color')};
        background-color: transparent;
        width: 100%;
        height: 2px;
        content: '';
        pointer-events: none;
    }
`;

const StyledButton = styled(Button).attrs(({isPressed}) => ({
    'type': 'button',
    'aria-pressed': !!isPressed,
}))`
    ${baseStyle};
    appearance: none;
    display: block;
    border: 0;
`;

const StyledLink = styled(A).attrs(({isPressed}) => ({
    'aria-pressed': !!isPressed,
}))`
    ${baseStyle};
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-decoration: none;
`;

const BA43CatTile = React.forwardRef(({
    backgroundSrc,
    className,
    'aria-label': assistiveLabel,
    isPressed,
    children,
    href,
    ...htmlAttributes
}, ref) => {
    const StyledCta = href ? StyledLink : StyledButton;

    return (
        <StyledCta
            {...htmlAttributes}
            backgroundSrc={backgroundSrc}
            className={classnames('BA43CatTile', className)}
            href={href}
            ref={ref}
            title={assistiveLabel}
            aria-label={assistiveLabel}
            isPressed={isPressed}
        >
            {!backgroundSrc && children}
        </StyledCta>
    );
});

/* eslint-disable quote-props */
BA43CatTile.propTypes = {
    /** Background image source */
    backgroundSrc: propTypes.string,
    /** Additional CSS class(es) */
    className: classNameType,
    /** Assistive label required for screen readers due to graphical only representation of content */
    'aria-label': propTypes.string,
    /** If it’s actually a link, provide a href */
    href: propTypes.string,
    /** aria-pressed attribute describes a toggle button */
    isPressed: propTypes.oneOf([true, false, 'mixed']),
    /** Content of button, e.g. text */
    children: propTypes.node.isRequired,
};
/* eslint-enable quote-props */

BA43CatTile.displayName = 'BA43CatTile';

export default BA43CatTile;
