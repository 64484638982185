const INSET_OFFSET = 2;

/**
 * Generate a function that returns checked mark's size and position css for a given screen width.
 * Checked mark is assumed to be absolute-positioned.
 *
 * @example getCheckedMarkStylesMap({320: 17, 1920: 26})[320]
 * returns {top: -7.5px, right: -7.5px, width: 17px, height: 17px}
 *
 * @param {Object} checkmarkSizePxMap  Map screen min width to size of the check mark
 * @returns {Object} Object to map screen width to checkmark styles
 */
export default function getCheckedMarkStylesMap(checkmarkSizePxMap) {
    return Object
        .entries(checkmarkSizePxMap)
        .reduce((acc, [width, size]) => Object
            .defineProperty(acc, width, {
                get() {
                    const checkmarkOffsetHoverPx = (size - INSET_OFFSET) / -2;

                    return `
                        top: ${checkmarkOffsetHoverPx}px;
                        right: 0;
                        width: ${checkmarkSizePxMap[width]}px;
                        height: ${checkmarkSizePxMap[width]}px;
                    `;
                },
            }), {});
}
