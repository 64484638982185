import styled from 'styled-components';
import propTypes from 'prop-types';
import {Img} from 'normalized-styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {
    SCREEN_1024_DESKTOP,
    SCREEN_1280_DESKTOP,
    SCREEN_1920_DESKTOP,
    SCREEN_2560_DESKTOP,
} from '../../../../../common/screen-sizes';

const VPGA03Thumbnail = styled(Img)`
    display: block;
    margin: 0;
    width: 176px;
    height: 99px;

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        width: 220px;
        height: 124px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        width: 206px;
        height: 116px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 280px;
        height: 154px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
        width: 338px;
        height: 190px;
    `}
`;

VPGA03Thumbnail.propTypes = {
    src: propTypes.string,
};

VPGA03Thumbnail.displayName = 'VPGA03Thumbnail';

export default VPGA03Thumbnail;
