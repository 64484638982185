import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import property from 'lodash/property';

import {stylesWhen, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {colorType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../common/animations';
import {indigo} from '../../../common/palette';

/** *********** ICON LIST *********** **/
export {default as IC01Ham} from './01-ham';
export {default as IC02Sea} from './02-sea';
export {default as IC03Not} from './03-not';
export {default as IC03NotOff} from './03-not-off';
export {default as IC04Vol} from './04-vol';
export {default as IC05Exp} from './05-exp';
export {default as IC06Play} from './06-play';
export {default as IC07Pau} from './07-pau';
export {default as IC08Cc} from './08-cc';
export {default as IC10Mltv} from './10-mltv';
export {default as IC11AirP} from './11-airp';
export {default as IC12RR} from './12-rr';
export {default as IC13FF} from './13-ff';
export {default as IC14Skb} from './14-skb';
export {default as IC15Skf} from './15-skf';
export {default as IC17plus} from './17-plus';
export {default as IC19JLive} from './19-jlive';
export {default as IC20Live} from './20-live';
export {default as IC21Mute} from './21-mute';
export {default as IC22Float} from './22-float';
export {default as IC24ArrowL} from './24-arrow-l';
export {default as IC25ArrowR} from './25-arrow-r';
export {default as IC26ArrowU} from './26-arrow-u';
export {default as IC27ArrowD} from './27-arrow-d';
export {default as IC28RelVid} from './28-rel-vid';
export {default as IC30BackGlass} from './30-back-glass';
export {default as IC31Tick} from './31-tick';
export {default as IC36Mail} from './36-mail';
export {default as IC42Error} from './42-error';
export {default as IC43Valid} from './43-valid';
export {default as IC49Open} from './49-open';
export {default as IC50Success} from './50-success';
export {default as IC51Close} from './51-close';
export {default as IC58Info} from './58-info';
export {default as IC69Stop} from './69-stop';
export {default as IC71Thtr} from './71-thtr';
export {default as IC72min} from './72-min';
export {default as IC97Eq} from './97-eq';
export {default as IC99ExitThtr} from './99-exit-thtr';
export {default as IC107Pro} from './107-pro';
export {default as IC109Spor} from './109-spor';
export {default as IC114More} from './114-more';
export {default as IC115Mic} from './115-mic';
export {default as IC116SiriRemote} from './116-siri-remote';
export {default as IC117SwipeD} from './117-swipe-d';
export {default as IC118Tap} from './118-tap';
export {default as IC119SwipeU} from './119-swipe-u';
export {default as IC122ActionBack} from './122-action-back';
export {default as IC130Alert} from './130-alert';
export {default as IC131Power} from './131-power';
export {default as IC132LoopLoader} from './132-loop-loader';
export {default as IC133Scrub} from './133-scrub';
export {default as IC134Minus} from './134-minus';
export {default as IC135Cross} from './135-cross';
export {default as IC142SwipeLR} from './142-swipe-lr';
export {default as IC152MultiAdd1} from './152-multi-add-1';
export {default as IC153MultiAdd2} from './153-multi-add-2';
export {default as IC154MultiAdd3} from './154-multi-add-3';
export {default as IC155MultiAdd4} from './155-multi-add-4';
export {default as IC157Offer} from './157-offer';
export {default as IC167Synopsis} from './167-synopsis';
export {default as IC169ContextualPlay} from './169-contextual-play';
export {default as IC170ContextualDown} from './170-contextual-down';
export {default as IC171ContextualBack} from './171-contextual-back';
export {default as IC172ContextualMenu} from './172-contextual-menu';
export {default as IC173ContextualPlayPause} from './173-contextual-play-pause';
export {default as IC180ContextualBackRoku} from './180-contextual-back-roku';
export {default as IC180ContextualBackRoku2} from './180-contextual-back-roku-2';
export {default as IC181ContextualOkRoku} from './181-contextual-ok-roku';
export {default as IC209Fixtures} from './209-fixtures';
export {default as IC217MultiViewBtn} from './217-multi-view-btn';
export {default as IC218XtraInfo} from './218-xtra-info';
export {default as IC218XtraInfoDefault} from './218-xtra-info-default';
export {default as IC220AudioSettings} from './220-audio-settings';
export {default as IC221NextUp} from './221-next-up';
export {default as IC228MyAccount} from './228-my-account';
export {default as IC229Settings} from './229-settings';
export {default as IC230ParentalControls} from './230-parental-controls';
export {default as IC231Profiles} from './231-profiles';
export {default as IC232Help} from './232-help';
export {default as IC233About} from './233-about';
export {default as IC234Home} from './234-home';
export {default as IC237Watchlist} from './237-watchlist';
export {default as IC238More} from './238-more';
export {default as IC239WatchTrailer} from './239-watch-trailer';
export {default as IC246Edit} from './246-edit';
export {default as IC247FAQPlus} from './247-faq-plus';
export {default as IC248FAQMinus} from './248-faq-minus';
export {default as IC260Switch} from './260-switch';
export {default as IC261ArrowDTail} from './261-arrow-d-tail';
export {default as IC262ContextualRelVid} from './262-contextual-rel-vid';
export {default as IC265ClosedCaptionsOn} from './265-closed-captions-on';
export {default as IC266ClosedCaptionsOff} from './266-closed-captions-off';
export {default as IC270Remove} from './270-remove';
export {default as IC272RemoveSelected} from './272-remove-selected';
export {default as IC276SurfMode} from './276-surf-mode';
export {default as IC276SurfModeDefault} from './276-surf-mode-default';
export {default as IC277KeySegments} from './277-key-segments';
export {default as IC278KeySegmentsContextual} from './278-key-segments-contextual';
export {default as IC278KeySegmentsContextualDefault} from './278-key-segments-contextual-default';
export {default as IC280TurnOff} from './280-turn-off';
export {default as IC281KeySegSkipF} from './281-key-seg-skip-f';
export {default as IC282KeySegSkipB} from './282-key-seg-skip-b';
export {default as IC283Channels} from './283-channels';
export {default as IC284Topics} from './284-topics';
export {default as IC291RecordActive} from './291-record-active';
export {default as IC291RecordDefault} from './291-record-default';
export {default as IC298AccRestr} from './298-acc-restr';
export {default as IC313Credit} from './313-credit';
export {default as IC314Cart} from './314-cart';
export {default as IC316AppleLogo} from './316-apple-logo';
export {default as IC321Telstra} from './321-telstra';
export {default as IC322AppleLogo} from './322-apple-logo';
export {default as IC323RemoveC} from './323-remove-c';
export {default as IC324TechSpecs} from './324-tech-specs';
export {default as IC325Warranty} from './325-warranty';
export {default as IC328TelstraLogoCntnr} from './328-telstra-logo-cntnr';
export {default as IC334OptusSubHubLogo} from './334-optus-sub-hub-logo';
export {default as IC335OptusSubHubCntnrLogo} from './335-optus-sub-hub-cntnr-logo';
// export {default as IC } from './';

/**
 * There are two methods to set the colour of the SVG
 *     1. Set the color of a container element and the colour will be inherited by the SVG
 *     2. Set the color property
 */
const Ic = styled.svg.attrs({
    viewBox: '0 0 100 100',
})`
    display: ${property('display')};
    pointer-events: none; /* Don't allow icons to be event targets */

    ${stylesWhen('hasDropShadow')`
        filter: drop-shadow(1px 1px 1px ${indigo});
    `}

    ${stylesWhenNot('preserveColors')`
        fill: currentColor;

        * {
            transition: ${transition('fill', 'stroke')};
        }
    `}
`;

const Icon = ({
    hasDropShadow,
    ariaLabel,
    children,
    color,
    preserveColors,
    display = 'block',
    size = '100%',

    ariaHidden = ariaLabel ? undefined : true,
    ...htmlAttributes
}) => ( // using aria-hidden true on the assumption that these icons will only be used in the context of a button and are thus decorative
    <Ic
        {...htmlAttributes}
        hasDropShadow={hasDropShadow}
        aria-hidden={ariaHidden}
        aria-label={ariaLabel}
        color={preserveColors ? null : color}
        preserveColors={preserveColors}
        display={display}
        style={preserveColors ? {
            fill: color,
            stroke: color,
            width: size,
        } : {
            width: size,
        }}
    >
        {children}
    </Ic>
);

Icon.propTypes = {
    ariaHidden: propTypes.bool,  // eslint-disable-line react/boolean-prop-naming
    ariaLabel: propTypes.string,
    children: propTypes.node,
    color: colorType,
    preserveColors: propTypes.bool,  // eslint-disable-line react/boolean-prop-naming
    display: propTypes.oneOf(['block', 'inline-block', 'flex', 'inline-flex']),
    size: propTypes.string,
    hasDropShadow: propTypes.bool,
};

Icon.displayName = 'Icon';

export default Icon;
