import React from 'react';
import Ic from '..';

const IC27ArrowD = (args) => (
    <Ic {...args}>
        <path fillRule="nonzero" d="M50.062 72.109 10.924 32.566 16.434 27l33.628 33.976L83.69 27l5.51 5.566z" />
    </Ic>
);

IC27ArrowD.displayName = 'IC27ArrowD';

export default IC27ArrowD;
