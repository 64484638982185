import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {SCREEN_DESKTOP, SCREEN_PRETTY_LG_DESKTOP} from '../../../../common/screen-sizes';
import {ZOOM_ALLOWANCE_PX, Z_INDEX_FILMSTRIP, LEFT_GUTTER, SCROLL_DURATION_MS} from './constants';
import CarouselSlot from './carousel-slot';

const FirstSlideTeaser = styled(CarouselSlot).attrs({ // this is a fake slide to trick users into believing this carousel goes forever
    'aria-hidden': true,
})`
    position: relative;
    border: 0;
    pointer-events: none;
`;

const FirstSlideTeaserInner = styled.div`
    margin: ${ZOOM_ALLOWANCE_PX * -1}px 0 ${ZOOM_ALLOWANCE_PX * -1}px ${ZOOM_ALLOWANCE_PX * -1}px;
    padding: ${ZOOM_ALLOWANCE_PX}px 0 ${ZOOM_ALLOWANCE_PX}px ${ZOOM_ALLOWANCE_PX}px;
    width: 5vw;
    overflow: hidden;
`;

const LastSlideTeaser = styled(CarouselSlot).attrs({
    'aria-hidden': true,
    'isBeingLazy': false,
    'tabIndex': -1,
})`
    position: absolute;
    transform: translateX(-100%);
    margin-left: calc(5vw - 14px);
    border-right: 0;
    pointer-events: none;

    ${mediaQuery({minWidthPx: SCREEN_PRETTY_LG_DESKTOP})`
        border-right: 12px solid transparent;
    `}
`;

const StyledOl = styled.ol`
    -webkit-overflow-scrolling: touch;
    display: inline-flex;
    position: relative;
    transition: transform ${SCROLL_DURATION_MS}ms ease-out;
    z-index: ${Z_INDEX_FILMSTRIP};
    margin: ${ZOOM_ALLOWANCE_PX * -1}px 0;
    padding: ${ZOOM_ALLOWANCE_PX}px 0;
    width: 100%;
    overflow-x: scroll;
    list-style: none;

    ${FirstSlideTeaser} {
        display: none;

        ${mediaQuery({minWidthPx: SCREEN_DESKTOP})`
            display: block;
        `}
    }

    ${LastSlideTeaser} {
        display: none;

        ${mediaQuery({minWidthPx: SCREEN_DESKTOP})`
            display: block;
        `}
    }

    &::before { /* stylelint-disable-line order/order */
        display: block;
        margin-right: 14px;
        border-radius: 7px;
        padding-right: calc(${LEFT_GUTTER} - 14px);
        content: '';
    }

    @supports (
        (scroll-snap-align: start)
        and (not (-webkit-overflow-scrolling: touch)) /* Unforunately scroll snap seems to cause jitter on iOS devices when combined with lazy loading. This is a crude way of disabling it */
    ) {
        scroll-snap-type: x mandatory;
        scroll-padding: ${LEFT_GUTTER};
    }

    scrollbar-width: none; /* stylelint-disable-line */
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;

const FilmStrip = React.forwardRef(({
    children,
    firstSlideTeaser,
    hasNextHint,
    hasPrevHint,
    lastSlideTeaser,
    onScroll,
    ...htmlAttributes
}, ref) => (
    <StyledOl
        {...htmlAttributes}
        hasNextHint={hasNextHint}
        hasPrevHint={hasPrevHint}
        ref={ref}
        onScroll={onScroll}
    >
        {!!hasPrevHint && !!lastSlideTeaser && (
            <LastSlideTeaser>
                {lastSlideTeaser}
            </LastSlideTeaser>
        )}
        {children}
        {!!hasNextHint && !!firstSlideTeaser && (
            <FirstSlideTeaser>
                <FirstSlideTeaserInner>
                    {React.cloneElement(
                        firstSlideTeaser,
                        {isBeingLazy: false, tabIndex: -1},
                    )}
                </FirstSlideTeaserInner>
            </FirstSlideTeaser>
        )}
    </StyledOl>
));

FilmStrip.displayName = 'FilmStrip';

FilmStrip.propTypes = {
    children: propTypes.node,
    hasNextHint: propTypes.bool,
    hasPrevHint: propTypes.bool,
    onScroll: propTypes.func,
    firstSlideTeaser: propTypes.element,
    lastSlideTeaser: propTypes.element,
};

export default FilmStrip;
