import React from 'react';
import get from 'lodash/get';
import styled from 'styled-components';
import propTypes from 'prop-types';
import {rgba} from 'polished';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {srcsetOptionsType} from '@fsa-streamotion/custom-prop-types';

import SrcsetSource from '../../../../common/srcset-source';
import LazyImageSwitcher from '../../../../common/lazy-image-switcher';
import {vader, blanc, panther} from '../../../../common/palette';
import {transition} from '../../../../common/animations';
import {SCREEN_1280_DESKTOP, SCREEN_2560_DESKTOP} from '../../../../common/screen-sizes';

const DATA_1X1_PNG = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

/**
 * Takes an object of srcset key/values and returns the largest image.
 *
 * @param {Object} srcsetOptions    - list of srcSet sizes
 * @returns {string}                - the largest img size
 */
function getLargestImage(srcsetOptions = {}) {
    const [firstValue] = Object.entries(srcsetOptions)
        .sort(([sizePxA], [sizePxB]) => sizePxB - sizePxA);

    return get(firstValue, '1');
}

const StyledDiv = styled.div`
    position: relative;
    transform: scale(${({isActive}) => isActive ? 1.1 : 1});
    transition: ${transition('transform')};
    /* transition delay here prevents scroll jank when fixed cursor triggers scale transformations on scroll */
    transition-delay: 100ms;
    width: 100%;

    &::before {
        display: block;
        position: relative;
        padding-bottom: 150%; /* when we want a 2x3 (portrait) image */
        width: 100%;
        height: 0;
        overflow: hidden;
        content: '';

        ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
            padding-bottom: 56.25%; /* when we want a 16x9 (landscape) image */
        `}
    }
`;

const StyledLazyImageSwitcher = styled(LazyImageSwitcher)`
    position: absolute;
    top: 0;
    left: 0;
`;

const StyledPicture = styled.picture`
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const StyledTilePicture = styled(StyledPicture)`
    box-shadow: 0 0 30px 0 ${rgba(panther, 0.3)};
    background-color: ${rgba(panther, 0.35)};
`;

const BadgeImg = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
`;

const StyledImg = styled.img`
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0 0 30px 0 ${rgba(panther, 0.3)};
    background-color: ${rgba(panther, 0.35)};
    width: 100%;
    height: 100%;
`;

const Description = styled.strong`
    box-sizing: border-box;
    position: absolute;
    right: 0;
    bottom: 9px;
    margin-left: auto;
    border-top: 4px solid ${rgba(vader, 0.95)};
    border-bottom: 4px solid ${rgba(vader, 0.95)};
    background: ${rgba(vader, 0.95)};
    padding: 0 8px;
    max-width: 80%;
    overflow: hidden;
    text-align: right;
    color: ${blanc};
    font: var(--ionic-body-copy-4);

    ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
        border-width: 12px;
        padding: 0 12px;
    `}

    @supports (-webkit-line-clamp: 2) { /* Where supported, lets add nice ellipsis on overflow https://caniuse.com/#search=line-clamp */
        /* stylelint-disable value-no-vendor-prefix */
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        /* stylelint-enable value-no-vendor-prefix */
    }
`;

const PictureComponent = ({
    caption,
    defaultImageSrc,
    srcsetOptions,
    srcsetOptionsPortrait,
    srcsetSizes,
    ...htmlAttributes
}) => (
    <StyledTilePicture {...htmlAttributes}>
        {!!srcsetOptions && (
            <SrcsetSource
                srcsetOptions={srcsetOptions}
                minWidthPx={srcsetOptionsPortrait ? SCREEN_1280_DESKTOP : undefined}
                sizes={srcsetSizes}
            />
        )}

        {!!srcsetOptionsPortrait && (
            <SrcsetSource
                srcsetOptions={srcsetOptionsPortrait}
                sizes={srcsetSizes}
            />
        )}

        <StyledImg
            src={defaultImageSrc || getLargestImage(srcsetOptions || srcsetOptionsPortrait)}
            alt={caption}
        />
    </StyledTilePicture>
);

PictureComponent.displayName = 'PictureComponent';

PictureComponent.propTypes = {
    caption: propTypes.string.isRequired,
    defaultImageSrc: propTypes.string,
    srcsetOptions: srcsetOptionsType,
    srcsetOptionsPortrait: srcsetOptionsType,
    srcsetSizes: propTypes.string,
};

const GA07TileImg = ({
    badgePortraitSrcsetOptions,
    badgeSrcsetOptions,
    caption,
    defaultImageSrc,
    description,
    isActive,
    isBeingLazy,
    onClick,
    srcsetOptions,
    srcsetOptionsLazyLoadPlaceholder,
    srcsetOptionsPortrait,
    srcsetOptionsPortraitLazyLoadPlaceholder,
    srcsetSizes,
    ...htmlAttributes
}) => {
    const placeholderComponent = !!(srcsetOptionsPortraitLazyLoadPlaceholder || srcsetOptionsLazyLoadPlaceholder) && (
        <PictureComponent
            caption={caption}
            srcsetOptions={srcsetOptionsLazyLoadPlaceholder}
            srcsetOptionsPortrait={srcsetOptionsPortraitLazyLoadPlaceholder}
            srcsetSizes={srcsetSizes}
        />
    );

    return (
        <StyledDiv {...{isActive, onClick, ...htmlAttributes}}>
            <StyledLazyImageSwitcher
                isBeingLazy={isBeingLazy}
                placeholderComponent={placeholderComponent || undefined}
            >
                <PictureComponent
                    caption={caption}
                    defaultImageSrc={defaultImageSrc}
                    srcsetOptions={srcsetOptions}
                    srcsetOptionsPortrait={srcsetOptionsPortrait}
                    srcsetSizes={srcsetSizes}
                />
            </StyledLazyImageSwitcher>

            {!!(badgeSrcsetOptions || badgePortraitSrcsetOptions) && (
                // ensure that this always mirrors the tile <picture> component above i.e StyledTilePicture
                // if we don't have an appropriate image, don't show anything for the size that's missing, as it won't be in the right ratio and will look broken
                <StyledPicture>
                    <SrcsetSource
                        srcsetOptions={badgeSrcsetOptions || {1: DATA_1X1_PNG}}
                        minWidthPx={SCREEN_1280_DESKTOP}
                        sizes={srcsetSizes}
                    />

                    <SrcsetSource
                        srcsetOptions={badgePortraitSrcsetOptions || {1: DATA_1X1_PNG}}
                        sizes={srcsetSizes}
                    />

                    <BadgeImg
                        src={DATA_1X1_PNG}
                        alt=""
                    />
                </StyledPicture>
            )}

            {!!description && (
                <Description>{description}</Description>
            )}
        </StyledDiv>
    );
};

GA07TileImg.displayName = 'GA07TileImg';

GA07TileImg.propTypes = {
    /** Srcset for badge overlay on portrait orientation, e.g. "New Show" */
    badgePortraitSrcsetOptions: srcsetOptionsType,
    /** Srcset for badge overlay, e.g. "New Show" */
    badgeSrcsetOptions: srcsetOptionsType,
    /** Image caption, e.g. alt text */
    caption: propTypes.string.isRequired,
    /** Description text, e.g. title of the asset */
    description: propTypes.string,
    /** Fall back image if there's no srcset options for image */
    defaultImageSrc: propTypes.string,
    /** Whether the item is activated (e.g. passed in via carousel or wrapping TM21) */
    isActive: propTypes.bool,
    /** Whether or not to use the lazySrc instead of the proper URL */
    isBeingLazy: propTypes.bool,
    /** Callback on click. NOTE: this probably doesn't belong here but there is no molecule yet combining this and duration bar */
    onClick: propTypes.func,
    /** Srcset for landscape image */
    srcsetOptions: srcsetOptionsType,
    /** Srcset for lazy load image placeholder */
    srcsetOptionsLazyLoadPlaceholder: srcsetOptionsType,
    /** Srcset for portrait image */
    srcsetOptionsPortrait: srcsetOptionsType,
    /** Srcset for portrait lazy load image placeholder */
    srcsetOptionsPortraitLazyLoadPlaceholder: srcsetOptionsType,
    /** Srcset sizes */
    srcsetSizes: propTypes.string,
};

export default GA07TileImg;
