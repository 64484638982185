import React, {useRef, useEffect} from 'react';
import propTypes from 'prop-types';

import brandPropType from '../../../common/brand-proptype';
import ResubscribeModal from '../../branded/common/resubscribe-modal';
import TargetAemWrapperWithLoading from '../wrappers/target-aem-wrapper-with-loading';

const PromoModalCta = ({
    brand,
    userProps,
    billingProps,
    isLoading,
    promoModalProps,
    commonWidgetSettings,

    // from aem
    module,

    // for adobe target
    adobeTargetParams,
}) => {
    const {
        isModalOpen,
        shouldShowPromo,
        onClickNewUserCta,
    } = promoModalProps;

    const {offer, hasOfferError} = billingProps;
    const {clientSideFeatureFlags: {useAdobeTarget}} = commonWidgetSettings;

    const userGreeting = offer.customOfferSummary;
    const {onResubscribe, userIsAuthenticated} = userProps;
    const {ctaPrimary, ctaSecondary, id: moduleId, alignment} = module;

    const onReadyForTargetRef = useRef();

    useEffect(function invokeOnReadyForTargetWhenLoaded() {
        if (!isLoading) {
            try {
                onReadyForTargetRef.current?.();
            } catch (e) {
                console.error('Error while running onReadyForTarget - ignoring');
            }
        }
    }, [isLoading]);

    // Do not render if there is an offer error or the show flag is false
    // We have offer error if:
    // - there is an error in the offer stream or
    // - if it is an inactive account || user is unauthenticated and has a fremium or ppv
    if (hasOfferError || !shouldShowPromo || userIsAuthenticated) {
        return null;
    }

    const Component = (
        <ResubscribeModal
            brand={brand}
            isModalOpen={isModalOpen}
            userGreeting={userGreeting}

            // Existing customer: redirect to login page
            existingUserCta={ctaPrimary}
            onClickExistingUserCta={onResubscribe}

            // New customer: close modal and scroll to package selector
            newUserCta={ctaSecondary}
            newUserCtaHref="#products"
            onClickNewUserCta={onClickNewUserCta}
        />
    );

    if (useAdobeTarget) {
        return (
            <TargetAemWrapperWithLoading
                brand={brand}
                alignment={alignment}
                data-module={moduleId}
                moduleId={moduleId}
                adobeTargetParams={adobeTargetParams}
                render={({onReadyForTarget}) => {
                    onReadyForTargetRef.current = onReadyForTarget;

                    return Component;
                }}
            />
        );
    } else {
        // Note: we won't have loading skeleton or spinner in this case but is ok as the feature flag
        // is temporary and only for development purposes
        return Component;
    }
};

PromoModalCta.displayName = 'PromoModalCta';

PromoModalCta.propTypes = {
    module: propTypes.shape({
        ctaPrimary: propTypes.string,
        ctaSecondary: propTypes.string,
        id: propTypes.string,
        alignment: propTypes.string,
    }),
    /** Whether we're in a loading state */
    isLoading: propTypes.bool,
    /** The brand, Flash | Kayo | Binge | Lifestyle */
    brand: brandPropType,
    /** Representing props calculated from user streams */
    userProps: propTypes.shape({
        /** Whether the user is authenticated or not */
        userIsAuthenticated: propTypes.bool,
        /** String to show on sign in button in landing page (not used in this component) */
        signInWords: propTypes.string,
        /** Callback for sign in action */
        onSignIn: propTypes.func,
        /** Used to show a loading spinner on sign in button as user streams come through */
        isSignInLoading: propTypes.bool,
        /** Callback if user clicks on resubscribe button (used in hero banner) */
        onResubscribe: propTypes.func,
    }).isRequired,
    /** Representing the props only made for promo modal */
    promoModalProps: propTypes.shape({
        /** Should we show the promo modal? */
        shouldShowPromo: propTypes.bool,
        /** Is the promo modal open? */
        isModalOpen: propTypes.bool,
        /** Callback when clicking on "New Customer" button */
        onClickNewUserCta: propTypes.func,
    }).isRequired,
    /* Representing the props provided by billing api */
    billingProps: propTypes.shape({
        /** Footnote message usually used to display price/package change message */
        footnote: propTypes.node,
        /** Page header: `OR16MarBlkOfferDisplay` props */
        offerDisplay: propTypes.object,
        /** `PackageSelector` props */
        packageSelector: propTypes.object,
        /** Voucher images and toggle for `voucherForm` */
        voucherDisplay: propTypes.shape({
            voucherImages: propTypes.shape({
                imageAltText: propTypes.string,
                imageLinkMobile: propTypes.string,
                imageLinkDesktop: propTypes.string,
            }),
            /** Display voucherVorm? */
            isVoucherRequiredTemplate: propTypes.bool,
        }),
        /** Dispay continue button? */
        hasContinueButton: propTypes.bool,
        /** Callback when continue button is clicked */
        onContinue: propTypes.func,
        /** Is continue button loading? */
        isContinueLoading: propTypes.bool,
        /** If signup is disabled, we hide everything below page header */
        isSignUpDisabled: propTypes.bool,
        /** Callback for clicking on "Continue without voucher" button */
        onContinueWithoutVoucher: propTypes.func,
        /** Callback for clicking on Join now on hero banner */
        onClickOfferCta: propTypes.func,
        /** Offer object from billing api */
        offer: propTypes.object,
        /** if we have any error in offer stream or with account entitlements */
        hasOfferError: propTypes.bool,
    }).isRequired,
    /** Globalish settings object */
    commonWidgetSettings: propTypes.shape({ // @TODO: complete this shape later
        /** The brand, Flash | Kayo | Binge | Lifestyle */
        brand: brandPropType,
        /** The auth0 user object */
        user: propTypes.object,
        /** Handler helper for our SPA routing */
        newRouteHandler: propTypes.func,
        /** Object containing all the clientside feature flags */
        clientSideFeatureFlags: propTypes.object,
    }),
    /** Adobe Target Params */
    adobeTargetParams: propTypes.object,
};

export default PromoModalCta;
