import React from 'react';
import Ic from '..';

const IC97Eq = (args) => (
    <Ic {...args}>
        <path d="M35.6 35.7h5.6v47.6h-5.6zM12.4 54.8H18v28.6h-5.6zM58.8 16.7h5.6v66.7h-5.6zM82 54.8h5.6v28.6H82z" />
    </Ic>
);

IC97Eq.displayName = 'IC97Eq';

export default IC97Eq;
