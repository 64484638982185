import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import {indigo} from '../../../../common/palette';

import Ic from '..';

const IC155MultiAdd4 = ({
    secondaryColor = indigo,
    ...args
}) => (
    <Ic {...args}>
        <path d="M49.63 8C26.638 8 8 26.638 8 49.63c0 22.99 18.638 41.629 41.63 41.629 22.99 0 41.629-18.638 41.629-41.63C91.252 26.642 72.618 8.009 49.629 8z" />
        <path fill={secondaryColor} d="M57.423 66.214v-6.74h4.493V53.37h-4.493V32.419h-8.742L33.395 53.712v5.762h16.41v6.74h7.618zM50 53.516h-9.426L50 40.476v13.04z" />
    </Ic>
);

IC155MultiAdd4.propTypes = {
    /** The icon's secondary color */
    secondaryColor: colorType,
};

IC155MultiAdd4.displayName = 'IC155MultiAdd4';

export default IC155MultiAdd4;
