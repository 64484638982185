import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';
import {lifestyle, midnight} from '../../../../common/palette';

import Ic from '..';

const IC51Close = ({
    color = lifestyle,
    secondaryColor = midnight,
    ...otherProps
}) => (
    <Ic {...otherProps}>
        <path fill={color} d="M49.7 7C26.117 7 7 26.117 7 49.7c0 23.583 19.117 42.7 42.7 42.7 23.583 0 42.7-19.117 42.7-42.7C92.392 26.12 73.28 7.008 49.7 7z" />
        <path fill={secondaryColor} d="M67.214 38.136l-5.95-5.936L49.7 43.764 38.136 32.2 32.2 38.136 43.764 49.7 32.2 61.264l5.936 5.95L49.7 55.636l11.564 11.578 5.95-5.95L55.636 49.7z" />
    </Ic>
);

IC51Close.propTypes = {
    /** The icon's primary color */
    color: colorType,
    /** The icon's secondary color */
    secondaryColor: colorType,
};

IC51Close.displayName = 'IC51Close';

export default IC51Close;
