import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import noop from 'lodash/noop';
import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {colorType} from '@fsa-streamotion/custom-prop-types';

import {Button} from '../../../../../../common/normalized-styled-components';
import {transition} from '../../../../../../common/animations';
import {SCREEN_1024_DESKTOP} from '../../../../../../common/screen-sizes';
import {cobalt, ink} from '../../../../../../common/palette';

import {IC01Ham} from '../../../../../atoms/ic';

const HeaderFunctionsContainer = styled.div`
    flex: 1 0 auto;
    margin: 11px 0;
    text-align: right;

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        flex-grow: 0;
    `}
`;

const HeaderButton = styled(Button)`
    appearance: none;
    position: relative;
    transition: ${transition('color')};
    border-width: 0;
    background-color: transparent;
    padding: 8px;
    width: 40px;
    height: 40px;
    color: ${ink};

    &:focus,
    &:hover {
        outline: 0;
        color: ${cobalt};
    }

    &::after {
        position: absolute;
        right: 5px;
        bottom: -15px;
        left: 5px;
        transform: scaleX(0);
        transition: ${transition('transform')};
        background-color: ${ink};
        height: 2px;
        content: '';
    }

    &[aria-current='true']::after {
        transform: none;
    }

    /* stylelint-disable-next-line order/order */
    &[aria-current='true'],
    &[aria-expanded='true'] {
        outline: 0;
        color: ${cobalt};
    }

    &[aria-expanded='true']:focus {
        transform: scale(1.1);
    }
`;

// eslint-disable-next-line jsdoc/require-param, jsdoc/require-returns
/**
 * This section contains the header functions:
 * - appdock
 * - in the future: notifications, search and chromecast
 */
const HeaderFunctionSection = ({
    headerButtonsColor = ink,
    // App Dock
    hasAppDock = false,
    isAppDockOpen,
    onAppDockClick = noop,
}) => (
    <HeaderFunctionsContainer>
        {/** TODO: Post launch  - add chromecast, search and notif buttons */}
        {!!hasAppDock && (
            <HeaderButton
                onClick={onAppDockClick}
                aria-haspopup="true"
                aria-expanded={isAppDockOpen}
                color={headerButtonsColor}
            >
                <IC01Ham ariaLabel="Main menu" />
            </HeaderButton>
        )}
    </HeaderFunctionsContainer>
);

HeaderFunctionSection.displayName = 'HeaderFunctionSection';

HeaderFunctionSection.propTypes = {
    /** For `aria-expanded` & also **background styling** */
    isAppDockOpen: propTypes.bool,
    /** Accent color for header button icons hover/focus state and Chromecast connected icon  */
    headerButtonsColor: colorType,

    // APP DOCK
    /** Whether the appdock icon is visible */
    hasAppDock: propTypes.bool,
    /** Function for when Hamburger menu is clicked */
    onAppDockClick: propTypes.func,
};

export default HeaderFunctionSection;
