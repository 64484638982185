import React from 'react';
import styled, {css} from 'styled-components';
import propTypes from 'prop-types';
import {rgba} from 'polished';
import noop from 'lodash/get';
import classnames from 'classnames';

import {mediaQuery, stylesIfElse} from '@fsa-streamotion/styled-component-helpers';
import {buttonType, classNameType} from '@fsa-streamotion/custom-prop-types';
import {A, Button} from '../../../../common/normalized-styled-components';

import {transition} from '../../../../common/animations';
import {white, black, flash, blueCharcoal, buttonActiveState} from '../../../../common/palette';
import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';

const baseStyles = css`
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    transition: ${transition('background-color', 'color')};
    background-color: ${blueCharcoal};
    padding: 7px 21px;
    min-height: 40px;
    text-shadow: 0 1px 1px ${rgba(black, 0.3)};
    letter-spacing: 0.4px;
    color: ${rgba(white, 0.5)};
    font: var(--infinity-primary-button);

    ${stylesIfElse('isBlock')`
        display: flex;
        width: 100%;
    ``
        display: inline-flex;
        min-width: 104px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding: 14px 28px;
        min-width: 186px;
        min-height: 60px;
    `}
`;

const interactiveStyles = css`
    &:hover,
    &:focus {
        outline: 0;
        background-color: ${flash};
        text-shadow: currentColor 0 0 1px;
        color: ${white};
    }

    &:active,
    &[aria-pressed='true'],
    &[aria-current='true'] {
        background-color: ${buttonActiveState};
        text-shadow: currentColor 0 0 1px;
        color: ${white};
    }
`;

const StyledButton = styled(Button).attrs(({isPressed}) => ({
    'aria-pressed': isPressed,
}))`
    ${baseStyles}
    ${interactiveStyles}

    appearance: none;
    border: 0;
`;

const StyledLink = styled(A).attrs(({isPressed}) => ({
    'aria-current': isPressed,
}))`
    ${baseStyles}
    ${interactiveStyles}

    text-decoration: none;
`;

const NonInteractive = styled.div`
    ${baseStyles}

    opacity: 0.35;
    cursor: not-allowed;
    color: ${rgba(white, 0.3)};
`;

const BA28BtnSec = React.forwardRef(({
    children,
    disabled,
    href,
    isBlock,
    isPressed,
    onClick,
    className,
    type = 'button',
    ...htmlAttributes
}, ref) => {
    const isNonInteractive = disabled || (!href && (!onClick || onClick === noop));
    const StyledElement = getCtaType({isNonInteractive, href, onClick});

    return (
        <StyledElement
            {...htmlAttributes}
            ref={ref}
            isBlock={isBlock}
            isPressed={isPressed}
            // We only want to put a type for buttons
            href={isNonInteractive ? undefined : href}
            onClick={isNonInteractive ? undefined : onClick}
            className={classnames('BA28BtnSec', className)}
            type={href || isNonInteractive ? undefined : type}
        >
            {children}
        </StyledElement>
    );
});

BA28BtnSec.propTypes = {
    /** Content of button, e.g. text */
    children: propTypes.node,
    /** Is this button disabled? */
    // eslint-disable-next-line react/boolean-prop-naming
    disabled: propTypes.bool,
    /** If it’s actually a link, provide a href */
    href: propTypes.string,
    /** Whether the button fills its parent's width or not */
    isBlock: propTypes.bool,
    /** Whether the button is currently selected */
    isPressed: propTypes.bool,
    /** Callback on element click */
    onClick: propTypes.func,
    /** The HTML type of the button */
    type: buttonType,
    /** Additional CSS class(es) */
    className: classNameType,
};

function getCtaType({isNonInteractive, href, onClick}) {
    if (href && !isNonInteractive) {
        return StyledLink;
    } else if (onClick && !isNonInteractive) {
        return StyledButton;
    }

    return NonInteractive;
}

BA28BtnSec.displayName = 'BA28BtnSec';

export default BA28BtnSec;
