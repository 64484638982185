import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';

import {mediaQuery, stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType, srcsetOptionsType} from '@fsa-streamotion/custom-prop-types';

import noop from 'lodash/noop';
import {SCREEN_415_PHABLET, SCREEN_375_PHABLET, SMALLEST_SUPPORTED_WIDTH, SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import {transition} from '../../../../common/animations';
import {black, cloudy, ink} from '../../../../common/palette';
import {NAV_BAR_HEIGHT_PX} from '../../../../common/header-nav-constants';
import GA05BgImg from '../../../atoms/ga/05-bg-img';
import BA20NavBtn from '../../../atoms/ba/20-nav-btn';
import BA28BtnSec from '../../../atoms/ba/28-btn-sec';
import SignUpHeaderRow from './components/sign-up-row';
import UserProfileRow from './components/user-profile-row';

const AppDock = styled.aside`
    position: relative;
    transition: ${transition('transform', 'box-shadow')};
    background-color: ${cloudy};
    width: ${SMALLEST_SUPPORTED_WIDTH}px;
    height: calc(100vh - ${NAV_BAR_HEIGHT_PX}px);
    overflow: hidden;
    object-fit: contain;

    ${stylesWhen('isActive')`
        transform: translateX(-100%);
        box-shadow: 7px -7px 21px ${rgba(black, 0.3)};
    `}

    ${mediaQuery({minWidthPx: SCREEN_415_PHABLET})`
        width: ${SCREEN_375_PHABLET}px;
    `}
`;

const StyledGA05BgImg = styled(GA05BgImg)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const Header = styled.section`
    &::after {
        display: block;
        background-image: linear-gradient(to bottom, transparent, ${rgba(black, 0.03)} 98%);
        height: 14px;
        content: '';
    }
`;

Header.displayName = 'Header';

const InnerContainer = styled.div`
    box-sizing: border-box;
    display: grid;
    position: relative;
    grid-auto-columns: 100%;
    transition: ${transition('transform')};
    background-color: ${cloudy};
    height: 100%;
    object-fit: contain;
`;

InnerContainer.displayName = 'InnerContainer';

const NavSection = styled.section`
    overflow-x: hidden;
`;

export const NavSectionItems = styled.section.attrs(({selectedIndex = 0, style}) => ({
    style: {
        ...style,
        // Apply a CSS transform to translate the element horizontally based on the selected index if tab nav items.
        // Negative selectedIndex moves the element to the left, positive selectedIndex moves it to the right
        transform: `translateX(${100 * -selectedIndex}%)`,
    },
}))`
    box-sizing: border-box;
    display: grid;
    grid-auto-columns: 100%;
    transition: ${transition('transform')};
`;

const TabNavList = styled.ul`
    margin: 0;
    padding: 0;
    list-style-type: none;
`;

const TabNavListItem = styled.li`
    margin-top: 21px;
`;

const StyledBA20NavBtn = styled(BA20NavBtn)`
    padding: 0 21px;
    min-height: 35px;
`;

const Centered = styled.div`
    margin-top: 28px;
    text-align: center;
`;

const StyledBA28BtnSec = styled(BA28BtnSec)`
    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        border-width: 1px;
        padding: 7px 21px;
        min-width: 250px;
        min-height: 50px;
    `}
`;

const SignUpCtaMessage = styled.p`
    margin: 0;
    text-align: center;
    color: ${ink};
    font: var(--quicksilver-header-4);
`;

const OR31AppDock = ({
    className,
    isActive,
    bgSrcsetOptions,
    ctaMessage,
    signUpCtaCopy,
    signInCtaCopy,
    onSignUpClick,
    isAuthenticated,
    onSignInClick = noop,
    tabNavItems = [],
    onClickLogout = noop,
    selectedTabNavItemIndex = 0,
    avatarUrl,
    profileName,
    onProfileClick = noop,
}) => (
    <AppDock
        className={classnames('OR31AppDock', className)}
        isActive={isActive}
        aria-hidden={!isActive}
    >
        <Header>
            { isAuthenticated
                ? (
                    <UserProfileRow
                        avatarUrl={avatarUrl}
                        profileName={profileName}
                        onProfileClick={onProfileClick}
                    />
                )
                : (
                    <SignUpHeaderRow
                        signUpCtaCopy={signUpCtaCopy}
                        signInCtaCopy={signInCtaCopy}
                        onSignUpClick={onSignUpClick}
                        onSignInClick={onSignInClick}
                    >
                        {!!ctaMessage && <SignUpCtaMessage>{ctaMessage}</SignUpCtaMessage>}
                    </SignUpHeaderRow>
                ) }
        </Header>
        <InnerContainer>
            {!!bgSrcsetOptions && <StyledGA05BgImg srcsetOptions={bgSrcsetOptions} />}
            <NavSection>
                {tabNavItems.map(({menuItems, label}, index) => (
                    /** Tabs will come here. */
                    <NavSectionItems key={label} selectedIndex={selectedTabNavItemIndex}>
                        <TabNavList>
                            {menuItems.map(({label: menuItemLabel, ...props}) => (
                                <TabNavListItem key={`tab-item-${menuItemLabel}`}>
                                    <StyledBA20NavBtn
                                        key={menuItemLabel}
                                        aria-hidden={selectedTabNavItemIndex !== index}
                                        label={menuItemLabel}
                                        {...props}
                                    />
                                </TabNavListItem>
                            ))}
                        </TabNavList>
                    </NavSectionItems>
                ))}
                <Centered>
                    {!!isAuthenticated && (
                        <StyledBA28BtnSec onClick={onClickLogout}>
                            Log out
                        </StyledBA28BtnSec>
                    )}
                </Centered>
            </NavSection>
        </InnerContainer>
    </AppDock>
);

OR31AppDock.propTypes = {
    /** Additional class name(s) */
    className: classNameType,
    /** Is the app dock currently active (i.e. open, visible and clickable)? */
    isActive: propTypes.bool,
    /** Srcset options for the background image */
    bgSrcsetOptions: srcsetOptionsType,
    /** Shows Signup CTA label for new users */
    ctaMessage: propTypes.string,
    /** Sign In CTA copy */
    signInCtaCopy: propTypes.string,
    /** Sign Up CTA copy */
    signUpCtaCopy: propTypes.string,
    /** Action on clicking sign-in */
    onSignUpClick: propTypes.func,
    /** Action on clicking sign-in */
    onSignInClick: propTypes.func,
    /** Navigation items */
    tabNavItems: propTypes.arrayOf(propTypes.shape({
        /** Label for the tab nav item */
        label: propTypes.string,
        /** Value for the tab nav item */
        value: propTypes.string,
        /** Href for the tab nav item */
        href: propTypes.string,
        /** Menu items for the tab nav item */
        menuItems: propTypes.arrayOf(propTypes.shape({
            /** link for menu item. */
            href: propTypes.string,
            /** Icon for the menu item */
            icon: propTypes.node,
            /** Label for the menu item */
            label: propTypes.string,
            /** onClick action when menu item is clicked */
            onClick: propTypes.func,
        })),
    })),
    /** Index of the selected tab nav item */
    selectedTabNavItemIndex: propTypes.number,
    /** Action on selecting a tab nav item */
    // onSelectTabNavItem: propTypes.func,
    /** Action on clicking logout */
    onClickLogout: propTypes.func,
    // === USER PROFILE === //
    /** User is authenticated and logged in */
    isAuthenticated: propTypes.bool,
    /** User profile avatar */
    avatarUrl: propTypes.string,
    /** User profile action when clicked */
    onProfileClick: propTypes.func,
    /** User profile name */
    profileName: propTypes.string,
};

OR31AppDock.displayName = 'OR31AppDock';

export default OR31AppDock;
