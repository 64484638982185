import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Main} from 'normalized-styled-components';

import {colorType} from '@fsa-streamotion/custom-prop-types';

export const Panel = styled.div`
    position: relative;
    margin: 0 auto var(--panel-template-gap, 41px);
    width: var(--panel-template-width, auto);
`;

export const FullBleedPanelRow = styled(Panel)`
    --panel-template-width: 100%;
`;

export const BoxedPanelRow = styled(Panel)`
    --panel-template-width: calc(90% - 7px);
`;

const Wrapper = styled(Main)`
    min-height: 100%;
    overflow: hidden;
`;

const PanelTemplate = ({
    backgroundColor,
    children,
    ...htmlAttributes
}) => (
    <Wrapper
        {...htmlAttributes}
        style={{backgroundColor}}
    >
        {children}
    </Wrapper>
);

PanelTemplate.displayName = 'PanelTemplate';

PanelTemplate.propTypes = {
    backgroundColor: colorType,
    children: propTypes.node,
};

export default PanelTemplate;
