import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {blanc} from '../../../../common/palette';
import {transition} from '../../../../common/animations';

const Bar = styled.div`
    background-color: ${rgba(blanc, 0.3)};
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const Indicator = styled.span`
    display: block;
    transform: ${({position, range}) => `translateX(calc(100% * ${position} / ${range}))`};
    transition: ${transition('transform', 'width')};
    background-color: ${blanc};
    width: ${({max, range}) => `calc(100% * ${range} / ${max})`};
    height: 100%;
`;

const GA103Pips = ({
    max,
    position = 0,
    range = 1,
    className,
    ...htmlAttributes
}) => (
    <Bar
        role="progressbar"
        aria-valuenow={position}
        aria-valuemin="0"
        aria-valuemax={max}
        className={classnames('GA103Pips', className)}
        {...htmlAttributes}
    >
        <Indicator
            max={max}
            position={position}
            range={range}
        />
    </Bar>
);

GA103Pips.displayName = 'GA103Pips';

GA103Pips.propTypes = {
    /** Number of items this component should represent. For example, if putting this on a carousel with 20 items, pass in 20. */
    max: propTypes.number,
    /** Where the indicator should start. Should be >= 0 and < max. Zero-based index. */
    position: propTypes.number,
    /** How wide the indicator should be. For example, if there are 20 items, a range of 10 would make the indicator take up 50% width. */
    range: propTypes.oneOfType([propTypes.number, propTypes.string]),
    /** Additional class(es) */
    className: classNameType,
};

export default GA103Pips;
