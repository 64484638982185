import React from 'react';
import propTypes from 'prop-types';
import styled, {ThemeProvider} from 'styled-components';
import classnames from 'classnames';

import {mediaQuery, stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {ironStone, midnight} from '../../../../common/palette';
import {SCREEN_PHABLET, SMALLEST_SUPPORTED_WIDTH} from '../../../../common/screen-sizes';
import {transition} from '../../../../common/animations';

const AppDock = styled.aside`
    position: relative;
    transition: ${transition('transform', 'box-shadow')};
    border-bottom-left-radius: 3px;
    background-color: ${ironStone};
    width: ${SMALLEST_SUPPORTED_WIDTH}px;
    height: calc(100vh - 70px);
    overflow: hidden;
    font: var(--mui-body-copy-3);

    ${stylesWhen('isActive')`
        transform: translateX(-100%);
        box-shadow: 7px -7px 21px ${midnight};
    `}

    ${mediaQuery({minWidthPx: SCREEN_PHABLET})`
        width: 375px;
    `}
`;

const InnerContainer = styled.div.attrs(({selectedIndex = 0, style}) => ({
    style: {
        ...style,
        transform: `translateX(${100 * -selectedIndex}%)`,
    },
}))`
    box-sizing: border-box;
    display: grid;
    grid-auto-columns: 100%;
    transition: ${transition('transform')};
    height: 100%;
`;

const OR31AppDock = ({
    children,
    isActive,
    className,
    selectedIndex,
}) => {
    const cards = React.Children.toArray(children).filter(Boolean);
    const isReallyActive = isActive && cards.length > 0;

    return (
        <AppDock
            className={classnames('OR31AppDock', className)}
            isActive={isReallyActive}
            aria-hidden={!isReallyActive}
        >
            <ThemeProvider theme={{isAppDock: true}}>
                <InnerContainer
                    hidden={!isReallyActive} // Prevent children from being tabbed while preserving parent's transition
                    selectedIndex={selectedIndex}
                >
                    {cards.map((child, index) => !!child && React.cloneElement(child, {
                        isFirst: index === 0,
                        isCurrent: isActive && (index === selectedIndex),
                    }))}
                </InnerContainer>
            </ThemeProvider>
        </AppDock>
    );
};

OR31AppDock.displayName = 'OR31AppDock';

OR31AppDock.propTypes = {
    /** Optional additional class(es) */
    className: classNameType,
    /** A stack of app dock cards (OR108AppDockCard) from bottom to top */
    children: propTypes.node,
    /** Is the app dock currently active (i.e. open, visible and clickable)? */
    isActive: propTypes.bool,
    /** The index of the selected card */
    selectedIndex: propTypes.number,
};

export default OR31AppDock;
