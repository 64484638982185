import React from 'react';
import Ic from '..';

const IC01Ham = (args) => (
    <Ic {...args}>
        <path d="M8.036 14h84.85v9.177H8.036zm0 31.081h84.85v9.177H8.036zm0 31.081h84.85v9.177H8.036z" />
    </Ic>
);

IC01Ham.displayName = 'IC01Ham';

export default IC01Ham;
