import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import GA86Visa from '../../../atoms/ga/86-visa';
import GA87Mastercard from '../../../atoms/ga/87-mastercard';
import GA128Amex from '../../../atoms/ga/128-amex';
import TM19Pmnt from '../19-pmnt';

const ExpiryAbbr = styled.abbr`
    text-decoration: none;
`;

const TYPE_ICON_MAP = {
    amex: <GA128Amex />,
    mastercard: <GA87Mastercard />,
    visa: <GA86Visa />,
};

const TM19PmntCc = ({
    expiry,
    name,
    number,
    type,
    className,
    ...htmlAttributes
}) => (
    <TM19Pmnt
        {...htmlAttributes}
        className={classnames('TM19PmntCc', className)}
        icon={TYPE_ICON_MAP[type]}
    >
        {!!name && (
            <div>
                {name}
            </div>
        )}
        <div>
            {number}
        </div>
        <div>
            <ExpiryAbbr title="Expires">Exp</ExpiryAbbr> {expiry}
        </div>
    </TM19Pmnt>
);

TM19PmntCc.propTypes = {
    /** Card expiry (MM/YY format), e.g. 09/19 */
    expiry: propTypes.string.isRequired,
    /** Card number, e.g. xxxx xxxx xxxx 2135 */
    number: propTypes.string.isRequired,
    /** Card type, e.g. visa */
    type: propTypes.oneOf(['amex', 'mastercard', 'visa']).isRequired,
    /** Optional card holder name */
    name: propTypes.string,
    /** Additional class(es) */
    className: classNameType,
};

TM19PmntCc.displayName = 'TM19PmntCc';

export default TM19PmntCc;
