import propTypes from 'prop-types';

export const tabIndexType = propTypes.oneOf([-1, 0]);
export const termTypePropType = propTypes.oneOf(['annual', 'month', 'week', 'day']);
export const billingPeriodType = propTypes.oneOf(['annual', 'month', 'week', 'day']);
export const planFamilyType = propTypes.oneOf(['Annual', 'Monthly', 'Weekly', 'Daily']);

export function deprecatedType(props, propName, componentName) {
    if (props[propName] !== undefined) {
        return new Error(`Prop ${propName} in ${componentName} has been deprecated`);
    }
}

export default {
    tabIndexType,
    termTypePropType,
    billingPeriodType,
    planFamilyType,
    deprecatedType,
};
