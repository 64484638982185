import React from 'react';
import ReactDOM from 'react-dom';

const nicerRender = (element, inputWrappers, inputTarget) => {
    const target = inputTarget || inputWrappers;
    const wrappers = inputTarget ? inputWrappers : [];

    // wrappers array is reversed for ease of use
    return new Promise((resolve) => {
        ReactDOM.render(
            wrappers
                .slice()
                .reverse()
                .reduce((payload, Wrapper) => (
                    <Wrapper>{payload}</Wrapper>
                ), element),
            target,
            // we want the base element and its wrappers part of the goldie
            // resolved target is what vizard takes a screenshot of
            // See: node_modules/vizard/src/framework/toolkit.js#L38-39
            () => resolve(target),
        );
    });
};

export default nicerRender;
