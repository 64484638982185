import React from 'react';
import Ic from '..';

const IC27ArrowD = (args) => (
    <Ic {...args}>
        <path d="M49.688 72.274L10 32.587 15.587 27l34.101 34.101L83.788 27l5.587 5.587z" />
    </Ic>
);

IC27ArrowD.displayName = 'IC27ArrowD';

export default IC27ArrowD;
