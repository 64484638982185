import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import classnames from 'classnames';

import {mediaQuery, stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import GM24CreditIndicator from '../../../molecules/gm/24-credit-indicator';
import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import {ink, white} from '../../../../common/palette';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${white};
    padding-bottom: 14px;
    overflow: hidden;
`;

const ContentWrapper = styled.div`
    opacity: 0.6;
    color: ${ink};
    font: var(--quicksilver-body-copy-4-light);

    ${stylesWhen('hasTopLinearBorder')`
        padding: 11px 14px 0;

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            padding: 25px 20px 0;
        `}
    `}
`;

/**
 * Stack and Save
 *
 * @see {@link https://zpl.io/Q0GEM34}
 */
const OR141StacknSave = ({
    children,
    className,
    creditIndicator,
    hasTopLinearBorder = true,
}) => (
    <Wrapper className={classnames('OR141StacknSave', className)}>
        {!!creditIndicator && (
            <GM24CreditIndicator
                copyIcon={creditIndicator?.copyIcon}
                copyText={creditIndicator?.copyText}
                isBlock={creditIndicator?.isBlock}
                hasTopLinearBorder={hasTopLinearBorder}
                priceCallout={creditIndicator?.priceCallout}
            />
        )}

        {!!children && (
            <ContentWrapper hasTopLinearBorder={hasTopLinearBorder}>
                {children}
            </ContentWrapper>
        )}
    </Wrapper>
);

OR141StacknSave.propTypes = {
    /** content of the stackn n save */
    children: propTypes.node,
    /** additional CSS classnames to be applied */
    className: propTypes.string,
    /** indicator object (pass through props of `GM24CreditIndicator`) */
    creditIndicator: propTypes.shape({
        /** copy text on lhs */
        copyText: propTypes.node,
        /** extra content on rhs (usually the credit amount `$10/mth`) */
        priceCallout: propTypes.node,
        /** prefix icon */
        copyIcon: propTypes.node,
        /** enable block style, text will be aligned center */
        isBlock: propTypes.bool,
    }),
    /** whether the credit indicator has a top border and whether we need to apply the associated padding */
    hasTopLinearBorder: propTypes.bool,
};

OR141StacknSave.displayName = 'OR141StacknSave';

export default OR141StacknSave;
