import React from 'react';
import Ic from '..';

const IC237Watchlist = (args) => (
    <Ic {...args}>
        <defs>
            <linearGradient x1="86.273%" y1="3.935%" x2="86.273%" y2="68.011%" id="ic237-watchlist__linear-gradient">
                <stop stopColor="currentColor" stopOpacity="0" offset="0%" />
                <stop stopColor="currentColor" offset="100%" />
            </linearGradient>
        </defs>
        <g fill="none" fillRule="nonzero">
            <path
                d="M80.898 30.76c8.577 13.265 7.416 30.711-3.174 42.775-12.95 14.752-35.417 16.447-50.463 3.913l-.454-.385 3.262-3.79c12.968 11.164 32.627 9.803 43.897-3.036 9.005-10.258 10.08-25.03 2.976-36.38l-.243-.381 4.2-2.715Z"
                fill="url(#ic237-watchlist__linear-gradient)"
            />
            <path
                d="M30.83 20.02c16.55-10.642 38.62-6.138 49.598 10.024l.329.493-4.181 2.742c-9.354-14.264-28.629-18.321-43.042-9.053-14.397 9.26-18.486 28.31-9.142 42.559C33.653 80.905 52.637 85.023 67 76.11l.434-.274 2.704 4.206c-16.717 10.75-39.065 6.046-49.927-10.516C9.34 52.949 14.099 30.78 30.83 20.02Z"
                fill="currentColor"
            />
            <path
                d="m53.033 36.506-.001 11.979 11.16.029-.013 5-11.147-.029.001 11.037h-5l-.001-11.05-12.095-.03.013-5 12.082.03.001-11.966z"
                fill="currentColor"
            />
        </g>
    </Ic>
);

IC237Watchlist.displayName = 'IC237Watchlist';

export default IC237Watchlist;
