import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {black, white} from '../../../../../common/palette';
import {transition} from '../../../../../common/animations';

const GradientContainer = styled.section`
    display: flex;
    position: relative;
    align-items: center;
    transform: translateY(${({isVisible}) => isVisible ? 0 : -141}px);
    transition: ${transition('transform')};
    padding: 7px 0 0;
    width: 100%;

    ${stylesWhen('isTabletUp')`
        padding: 14px 0 0;
    `}

    ${stylesWhen('isDesktopUp')`
        padding: 35px 0 0;
    `}

    &::before {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        background-image: linear-gradient(${black}, ${rgba(black, 0)} 80%);
        width: 100%;
        height: 141px;
        content: '';
        pointer-events: none;
        z-index: -1; /* stylelint-disable-line */
    }
`;

const RowsContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 0 14px;
    width: 100%;
    overflow: hidden;

    ${stylesWhen('isMobileLandscapeUp')`
        margin: 0 35px;
    `}
`;

const LeftRow = styled.div`
    display: flex;
    flex: 1 0 0;
    align-items: center;
    overflow: hidden;
`;

const Title = styled.h3`
    flex-grow: 0;
    flex-shrink: 1;
    margin: 0;
    padding: 5px 0;
    overflow: hidden;
    text-align: center;
    text-shadow: ${black} 1px 1px;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${white};
    font: var(--mui-body-copy-2-bold);

    ${stylesWhen('isDesktopUp')`
        margin-left: 20px;
    `}
`;

const RightRow = styled.div`
    display: flex;
    flex-basis: 0;
    flex-grow: 0;
    justify-content: flex-end;

    ${stylesWhen('isTabletUp')`
        flex-grow: 1;
    `}
`;

const CentreRow = styled.div`
    display: flex;
    flex-grow: 0;
    padding: 0 15px;
`;

const ButtonContainer = styled.div`
    flex-shrink: 0;
`;

const VPOR03UpperControls = ({
    backButton,
    multiviewButton,
    airplayButton,
    chromecastButton,
    videoTitle,

    isVisible,
    isDesktopUp,
    isTabletUp,
    isMobileLandscapeUp,
}) => (
    <GradientContainer
        isVisible={isVisible}
        isTabletUp={isTabletUp}
        isDesktopUp={isDesktopUp}
        className="VPOR03UpperControls"
    >
        <RowsContainer isMobileLandscapeUp={isMobileLandscapeUp}>
            <LeftRow>
                <ButtonContainer>
                    {backButton}
                </ButtonContainer>
                <Title isDesktopUp={isDesktopUp}>{videoTitle}</Title>
            </LeftRow>
            {!!isTabletUp && (
                <CentreRow>
                    {multiviewButton}
                </CentreRow>
            )}
            <RightRow isTabletUp={isTabletUp}>
                {airplayButton}
                {chromecastButton}
            </RightRow>
        </RowsContainer>
    </GradientContainer>
);

VPOR03UpperControls.displayName = 'VPOR03UpperControls';

VPOR03UpperControls.propTypes = {
    backButton: propTypes.node,
    multiviewButton: propTypes.node,
    airplayButton: propTypes.node,
    chromecastButton: propTypes.node,
    videoTitle: propTypes.string,

    isVisible: propTypes.bool,
    isMobileLandscapeUp: propTypes.bool,
    isTabletUp: propTypes.bool,
    isDesktopUp: propTypes.bool,
};

export default VPOR03UpperControls;
