import styled from 'styled-components';
import {Hr} from 'normalized-styled-components';
import {rgba} from 'polished';

import {blanc} from '../../../../common/palette';

const GA20PixelDiv = styled(Hr)`
    margin: 0;
    border-width: 1px 0 0;
    border-style: solid;
    border-color: ${rgba(blanc, 0.1)};
`;

GA20PixelDiv.displayName = 'GA20PixelDiv';

export default GA20PixelDiv;
