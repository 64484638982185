import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import noop from 'lodash/noop';

import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import handleFormSubmit from '../../../../common/get-handle-form-submit';
import {kayoGreen, white} from '../../../../common/palette';
import {font} from '../../../../common/typography';
import IM01Input from '../../../molecules/im/01-input';
import BA01BtnPr from '../../../atoms/ba/01-btn-pr';
import IC103Loading from '../../../atoms/ic/103-loading';
import IC43Valid from '../../../atoms/ic/43-valid';
import IA02Check from '../../../atoms/ia/02-check';

const LoginFormOverlay = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

const Form = styled.form`
    padding: 28px;
    width: 100%;
    max-width: 560px;
    text-align: center;
`;

const FormFieldContainer = styled.div`
    margin-right: auto;
    margin-left: auto;
    padding: 8px 0;
    max-width: 320px;
    ${({textAlign}) => stylesWhen('textAlign')`
        text-align: ${textAlign};
    `}
`;

const Header = styled.h1`
    color: ${white};
    font: var(--mui-header-2);
    line-height: 1.18; /* stylelint-disable-line order/properties-order */
    font-size: 34px;
    font-weight: 600;
`;

const ErrorText = styled.p`
    color: ${white};
    font: var(--mui-body-copy-5);
    line-height: 1.33; /* stylelint-disable-line order/properties-order */
    font-size: 18px;
    font-weight: 300;
`;

const FormFieldContainerStyled = styled(FormFieldContainer)`
    padding: 30px 0;
`;

const SubHeading = styled.h2`
    text-align: left;
    color: ${white};
    font: ${font};
    font-weight: 600;
    line-height: 1.33; /* stylelint-disable-line order/properties-order */
`;

const HelpText = styled.p`
    text-align: left;
    color: ${white};
    font: ${font};
    line-height: 1.33; /* stylelint-disable-line order/properties-order */
`;

class OR65NewPw extends React.Component {
    static displayName = 'OR65NewPw';

    static propTypes = {
        /** If set, an error message to be displayed to the user */
        errorMsg: propTypes.string,
        /** A custom HTML name for the form */
        name: propTypes.string,
        /** Whether or not the form is in a loading state */
        isLoading: propTypes.bool,
        /** A callback to handle submission of the form data */
        onSubmit: propTypes.func,
        /** Text to describe password validation */
        passwordHelpText: propTypes.string,
    };

    state = {
        password: null,
        confirmPassword: null,
        passwordsMatch: false,
    };

    onUpdate = (name, value) => {
        this.setState({
            [name]: value,
        }, () => this.setState(({password, confirmPassword}) => ({
            passwordsMatch: (password === confirmPassword)
                && !!password && !!confirmPassword,
        })));
    };

    render() {
        const {
            errorMsg,
            name,
            isLoading,
            onSubmit = noop,
            passwordHelpText,
        } = this.props;

        return (
            <LoginFormOverlay className="OR65NewPw">
                <Form
                    onSubmit={handleFormSubmit(onSubmit, ['password', 'confirm-password', 'require-device-signin'])}
                    name={name}
                    method="POST"
                    // prevents form submission before js executes (see martian-web-server for implementation)
                    data-disabled-form-submit="true"
                >
                    <Header>Now please create your new password</Header>
                    <FormFieldContainer>
                        <SubHeading>New password</SubHeading>
                        <HelpText>{passwordHelpText}</HelpText>
                    </FormFieldContainer>
                    <FormFieldContainer>
                        <IM01Input
                            type="password"
                            label="Password"
                            name="password"
                            required={true}
                            disabled={isLoading}
                            onChange={({target}) => void this.onUpdate('password', target.value)}
                            icon={this.state.password ? <IC43Valid color={kayoGreen} /> : null}
                        />
                    </FormFieldContainer>
                    <FormFieldContainer>
                        <IM01Input
                            type="password"
                            label="Confirm password"
                            name="confirm-password"
                            required={true}
                            disabled={isLoading}
                            onChange={({target}) => void this.onUpdate('confirmPassword', target.value)}
                            icon={this.state.passwordsMatch ? <IC43Valid color={kayoGreen} /> : null}
                        />
                    </FormFieldContainer>
                    <FormFieldContainerStyled textAlign="left">
                        <IA02Check label="Require all devices to sign in again with new password" name="require-device-signin" />
                    </FormFieldContainerStyled>
                    <FormFieldContainer>
                        {isLoading ? (
                            <IC103Loading size="47px" />
                        ) : (
                            <BA01BtnPr
                                className="continue-btn"
                                type="submit"
                                disabled={this.state.passwordsMatch ? null : 'disabled'}
                            >
                                Continue
                            </BA01BtnPr>
                        )}
                    </FormFieldContainer>
                    {!!errorMsg && (
                        <ErrorText>{errorMsg}</ErrorText>
                    )}
                </Form>
            </LoginFormOverlay>
        );
    }
}

export default OR65NewPw;
