import React from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {silverLining} from '../../../../common/palette';

const StatDataList = styled.dl`
    margin: 0;
    color: ${silverLining};
`;

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
`;

const StatName = styled.dt`
    font: var(--mui-hud-tile-copy-light);
`;

const StatValue = styled.dd`
    margin-left: 4px;
    font: var(--mui-hud-tile-copy-bold);
`;

const LargeStatValue = styled(StatValue)`
    margin-bottom: 4px;
    font: var(--mui-hud-tile-score-bold);
`;

const IGM09RateTil = ({
    className,
    currentRunRate = 0,
    last10Overs,
}) => (
    <StatDataList className={classNames('IGM09RateTil', className)}>
        <FlexRow>
            <StatName>Current Run Rate:</StatName>
            <LargeStatValue>{currentRunRate.toFixed(2)}</LargeStatValue>
        </FlexRow>
        {!!last10Overs && (
            <FlexRow>
                <StatName>Last 10 Overs:</StatName>
                <StatValue>{last10Overs}</StatValue>
            </FlexRow>
        )}
    </StatDataList>
);

IGM09RateTil.displayName = 'IGM09RateTil';

IGM09RateTil.propTypes = {
    /** Additional class(es) */
    className: classNameType,
    /** Number of runs scored by number of overs bowled currently */
    currentRunRate: propTypes.number,
    /** Stats for the last 10 overs bowled (includes wickets taken/lost, runs, and run rate) */
    last10Overs: propTypes.node,
};

export default IGM09RateTil;
