import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import classnames from 'classnames';

import {mediaQuery, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {blanc, onyx} from '../../../../../common/palette';
import {transition} from '../../../../../common/animations';
import {
    SCREEN_768_TABLET,
    SCREEN_1024_DESKTOP,
    SCREEN_1280_DESKTOP,
    SCREEN_1920_DESKTOP,
    SCREEN_2560_DESKTOP,
} from '../../../../../common/screen-sizes';

const Container = styled.div`
    display: grid;
    position: relative;
    grid-template-columns: 1fr auto;
    transform: translateY(${({isVisible}) => isVisible ? 0 : -100}%);
    transition: ${transition('transform')};
    padding: 14px 14px 0;
    color: ${blanc};

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        padding: 21px 30px 0;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        padding: 21px 40px 0;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding: 70px 70px 0;
    `}

    &::before {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        transition: ${transition('transform')};
        background: linear-gradient(to bottom, ${rgba(onyx, 0.7)}, transparent);
        width: 100%;
        height: 51px;
        content: '';
        pointer-events: none;
        z-index: -1; /* stylelint-disable-line */
        /** The gradient is taller than the actual element so do another translateY() to push the gradient up a bit harder */
        ${stylesWhenNot('isVisible')`
            transform: translateY(-100%);
        `}

        ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
            height: 104px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
            height: 172px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            height: 258px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
            height: 344px;
        `}
    }
`;

// Container for back button and title
const LeftSection = styled.div`
    display: flex;
    align-items: center;
    min-width: 0; /** Allow the title to shrink */
`;

// Container for all the other buttons (settings, Chromecast, AirPlay etc.)
const RightSection = styled.div`
    display: grid;
    /* Next two lines let us have a dynamic number of columns that are sized to their children ＼(＾▽＾)／ */
    grid-auto-columns: max-content;
    grid-auto-flow: column;
    grid-gap: 14px;
    margin-left: 30px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        grid-gap: 21px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        grid-gap: 28px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
        grid-gap: 35px;
    `}
`;

const BackButtonContainer = styled.div`
    margin: 0 7px 0 -7px;

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        margin-left: -11px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        margin-left: -20px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
        margin: 0 21px 0 -21px;
    `}
`;

const Title = styled.h2`
    margin: 0;
    width: 100%;
    overflow: hidden;
    /* stylelint-disable-next-line color-no-hex */
    mask-image: linear-gradient(to left, transparent, #000 50px);
    white-space: nowrap;
    font: var(--nucleus-player-controls);
`;

const VerticalDivider = styled.div`
    margin: auto 0;
    background-color: ${rgba(blanc, 0.15)};
    width: 1px;
    height: 68%;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 2px;
    `}
`;

const VPOR03UpperControls = ({
    backButton,
    settingsButton,
    chromecastButton,
    airplayButton,

    title,
    isVisible,
    className,
}) => (
    <Container
        isVisible={isVisible}
        className={classnames('VPOR03UpperControls', className)}
    >
        <LeftSection>
            {!!backButton && (
                <BackButtonContainer>{backButton}</BackButtonContainer>
            )}
            {!!title && (
                <Title>{title}</Title>
            )}
        </LeftSection>

        <RightSection>
            {settingsButton}
            {!!settingsButton && !!(chromecastButton || airplayButton) && <VerticalDivider />}
            {chromecastButton}
            {airplayButton}
        </RightSection>
    </Container>
);

VPOR03UpperControls.propTypes = {
    backButton: propTypes.node,
    settingsButton: propTypes.node,
    chromecastButton: propTypes.node,
    airplayButton: propTypes.node,
    title: propTypes.string,

    isVisible: propTypes.bool,
    className: classNameType,
};

VPOR03UpperControls.displayName = 'VPOR03UpperControls';

export default VPOR03UpperControls;
