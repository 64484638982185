import React from 'react';

import Ic from '..';

const IC221NextUp = (args) => (
    <Ic {...args}>
        <path d="m2.15 3.715-.063 52.457c.019.004.042 2.257.064 2.259 9.326-7.522 34.124-26.093 36.834-28.643C14.441 12.402 2.163 3.711 2.151 3.715z" opacity=".35" />
        <path fillRule="nonzero" d="M2.884 0C3.52 0 4.136.21 4.64.597L40.282 28a2.893 2.893 0 0 1 0 4.585L4.64 59.987a2.88 2.88 0 0 1-4.044-.533A2.893 2.893 0 0 1 0 57.695V2.89A2.887 2.887 0 0 1 2.884 0zm2.163 7.285v46.014l29.926-23.007L5.047 7.285z" />
        <rect
            width="5.031" height="60.478" x="45.276" y=".39" fillRule="nonzero"
            rx="2.5"
        />
    </Ic>
);

IC221NextUp.displayName = 'IC221NextUp';

export default IC221NextUp;
