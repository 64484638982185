import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {kayoGreen} from '../../../../common/palette';

const StyledSpan = styled.span`
    display: block;
    margin: 0;
    border: 0;
    border-radius: 4px;
    background-color: ${kayoGreen};
    width: 114px;
    height: 4px;
`;

const GA04NavInd = ({className, ...htmlAttributes}) => (
    <StyledSpan
        {...htmlAttributes}
        className={classNames('GA04NavInd', className)}
    />
);

GA04NavInd.displayName = 'GA04NavInd';

GA04NavInd.propTypes = {
    /** Additional class(es) */
    className: classNameType,
};

export default GA04NavInd;
