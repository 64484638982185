import React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';
import styled from 'styled-components';
import property from 'lodash/property';

import {mediaQuery, stylesIfElse} from '@fsa-streamotion/styled-component-helpers';
import {colorType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {SCREEN_PRETTY_LG_DESKTOP} from '../../../../common/screen-sizes';
import {kayoGreen, midnight} from '../../../../common/palette';
import {fontFamily} from '../../../../common/typography';
import IC287Locked from '../../ic/287-locked';

const StyledIC287Locked = styled(IC287Locked)`
    margin: 2px 0;
    height: 100%;
`;

const Badge = styled.span`
    display: inline-flex;
    align-items: center;
    border-radius: 2px;
    padding: 0 9px;
    height: 17px;
    text-transform: uppercase;
    font: 600 10px/1 ${fontFamily};

    ${mediaQuery({minWidthPx: SCREEN_PRETTY_LG_DESKTOP})`
        padding: 0 11px;
        height: 21px;
        font-size: 16px;
    `}

    ${stylesIfElse('isLocked')`
        color: ${property('backgroundColor')};
        background-color: ${property('textColor')};
    ``
        color: ${property('textColor')};
        background-color: ${property('backgroundColor')};
    `}
`;

export default function GA27ConLabel({
    backgroundColor = kayoGreen,
    children,
    className,
    isLocked,
    textColor = midnight,
    ...htmlAttributes
}) {
    return (
        <Badge
            aria-label={isLocked ? 'Locked' : undefined}
            backgroundColor={backgroundColor}
            className={classnames('GA27ConLabel', className)}
            isLocked={isLocked}
            textColor={textColor}
            {...htmlAttributes}
        >
            {isLocked
                ? <StyledIC287Locked size="auto" />
                : children}
        </Badge>
    );
}

GA27ConLabel.displayName = 'GA27ConLabel';

GA27ConLabel.propTypes = {
    /** Colour to use for background */
    backgroundColor: colorType,
    /** Content of label, e.g. text */
    children: propTypes.node.isRequired,
    /** Additional class(es) */
    className: classNameType,
    /** When locked, the padlock is displayed and the text is hidden */
    isLocked: propTypes.bool,
    /** Colour to use for text or SVG colour */
    textColor: colorType,
};
