import React from 'react';
import bacon from 'baconjs';
import get from 'lodash/get';
import styled from 'styled-components';
import {Header} from 'normalized-styled-components';

import {hydrateStream} from '@fsa-streamotion/bacon-widget';
import {getApiFromRetryWithError} from '@fsa-streamotion/request-manager';
import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {palette as ionicPalette, screenSizes} from '@fsa-streamotion/streamotion-web-ionic';
import {palette as infinityPalette} from '@fsa-streamotion/streamotion-web-infinity';
import {palette as muiPalette} from '@fsa-streamotion/streamotion-web-mui';
import {palette as nucleusPalette} from '@fsa-streamotion/streamotion-web-nucleus';

import getPanel from '../../../widget-streams/panel';
import {stylesWhenBinge, stylesWhenFlash, stylesWhenKayo, stylesWhenLifestyle} from '../../../utils/styles-when-brand';

const {SCREEN_768_TABLET, SCREEN_1024_DESKTOP, SCREEN_1280_DESKTOP} = screenSizes;

const {fog, white} = infinityPalette;
const {akela, blanc} = ionicPalette;
const {white: muiWhite, silverLining} = muiPalette;
const {smoke} = nucleusPalette;

const TabbedContentCarouselWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const StyledHeader = styled(Header)`
    margin: 0 auto;
    width: calc(100% - 14px);
    text-align: center;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        margin-bottom: 14px;
        width: 600px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        width: 75%; /* this isn't from design, just some sensible logic because who knows how many paragraphs of copy marketing will put here */
        max-width: 1200px;
    `}
`;

const StyledTitle = styled.h2`
    margin-top: 70px;

    ${stylesWhenBinge`
        color: ${blanc};
        font: var(--ionic-header-4);
    `}

    ${stylesWhenFlash`
        color: ${white};
        font: var(--infinity-header-4-bold);
    `}

    ${stylesWhenKayo`
        color: ${muiWhite};
        font: var(--mui-header-2);
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        margin-top: 90px;
    `}

    ${stylesWhenLifestyle`
        color: ${blanc};
        font: var(--nucleus-header-4);
    `}
`;

const StyledSubTitle = styled.p`
    margin: 14px 0 0;

    ${stylesWhenBinge`
        color: ${akela};
        font: var(--ionic-body-copy-3);
    `}

    ${stylesWhenFlash`
        color: ${fog};
        font: var(--infinity-body-copy-3);
    `}

    ${stylesWhenKayo`
        color: ${silverLining};
        font: var(--mui-body-copy-2);
    `}

    ${stylesWhenLifestyle`
        color: ${smoke};
        font: var(--nucleus-body-copy-3);
    `}
`;

export default function TabbedContentCarousel({
    hydration = {},
    key,
    title,
    subTitle,
    offersUrl,
    commonWidgetSettings,
}) {
    const {brand} = commonWidgetSettings;
    const allPanelsDataFromHydration = get(hydration, 'children.1.allPanelsData$', undefined);

    const url = `${offersUrl}?evaluate=1`; // control evaluate here rather than in the marketing config

    const allPanelsDataFromApi$ = getApiFromRetryWithError({
        url,
    })
        .flatMapLatest((response) => {
            if (response.responseConsideredValid) {
                return response.parseResponseText();
            } else {
                return new bacon.Error(
                    `Accounts-Widgets: couldn't get double carousel content (${url}): ${response.responseStatus} ${response.superAgentErr}`
                );
            }
        });

    const allPanelsData$ = hydrateStream(
        allPanelsDataFromHydration,
        allPanelsDataFromApi$
    )
        .startWith();

    const reactElement$ = bacon.combineTemplate({
        commonWidgetSettings,
        key,
        panelData: allPanelsData$.map('.panels.0'),
        allPanelsData: allPanelsData$,
    })
        .flatMapLatest(getPanel)
        .map(({reactElement}) => (
            <TabbedContentCarouselWrapper key={key}>
                <StyledHeader>
                    {!!title && (
                        <StyledTitle brand={brand}>
                            {title}
                        </StyledTitle>
                    )}
                    {!!subTitle && (
                        <StyledSubTitle brand={brand}>
                            {subTitle}
                        </StyledSubTitle>
                    )}
                </StyledHeader>
                {reactElement}
            </TabbedContentCarouselWrapper>
        ));

    return bacon.combineTemplate({
        reactElement: reactElement$,
        hydration: {
            allPanelsData$,
        },
    });
}
