import React, {useState, useEffect, useRef} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Section, Hr} from 'normalized-styled-components';
import {rgba} from 'polished';
import classnames from 'classnames';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import CAM01Stand from '../../../molecules/cam/01-stand';
import {blanc, onyx, lifestyle} from '../../../../common/palette';

import {SCREEN_768_TABLET, SCREEN_1024_DESKTOP, SCREEN_1920_DESKTOP, SCREEN_1280_DESKTOP} from '../../../../common/screen-sizes';
import {CONTENT_EDGE_SPACING_PERCENT} from '../../../../common/style-constants';

import {IC103Loading} from '../../../atoms/ic';

const SPINNER_SIZE_PX = 70;

const StyledSection = styled(Section)`
    position: relative;
    background-image: linear-gradient(to bottom, transparent, ${rgba(onyx, 0.2)});
    padding: 37px ${CONTENT_EDGE_SPACING_PERCENT}vw 0;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        padding-top: 28px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding-top: 35px;
    `}

    &[aria-busy='true'] {
        ::before {
            display: block;
            min-height: 350px;
            content: '';
        }
    }
`;

// applying a transform to this component interferes with the
// transform on the animation keyframes.
const StyledIC103Loading = styled(IC103Loading)`
    position: absolute;
    top: calc(50% - ${SPINNER_SIZE_PX / 2}px);
    left: calc(50% - ${SPINNER_SIZE_PX / 2}px);
`;

const StackWrapper = styled.ol`
    margin: 0;
    padding: 0;
    list-style: none;
`;

const StyledCAM01Stand = styled(CAM01Stand)`
    margin: 0 -${CONTENT_EDGE_SPACING_PERCENT}vw;
    width: auto;
    overflow: visible;
`;

const BottomBorder = styled(Hr)`
    margin: 0;
    border-width: 1px;
    border-style: solid none none;
    border-color: ${rgba(blanc, 0.1)};
`;

const StackItem = styled.li`
    margin: 0 0 28px;
`;

const Title = styled.h3`
    margin: 0;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    color: ${blanc};
    font: var(--nucleus-carousel-header);

    > small {
        margin-left: 1em; /* distance should be proportional to font size */
        font: inherit;
        font-weight: normal;
    }
`;

export default function OR60SeaEpi({
    breakpointPageSizes = {
        [SCREEN_1024_DESKTOP]: 3,
        [SCREEN_1280_DESKTOP]: 5,
    },
    children,
    className,
    title,
    ...htmlAttributes
}) {
    const [currentView, setCurrentView] = useState('loading');
    const matchMedia = useRef(null);
    const hasChildren = !!React.Children.count(children);
    const isLoading = currentView === 'loading' || !hasChildren;

    useEffect(function updateCurrentViewAgainstMediaQuery() {
        matchMedia.current = window.matchMedia(`(min-width: ${SCREEN_1024_DESKTOP}px)`); // change orientation at this size
        setCurrentView(matchMedia.current.matches ? 'carousel' : 'stack'); // check synchronously

        const onMatchMedia = (event) => void setCurrentView(event.matches ? 'carousel' : 'stack');

        matchMedia.current.addListener(onMatchMedia); // then listen for async changes

        return () => matchMedia.current.removeListener(onMatchMedia); // unbind our listener on unmount
    }, []);

    return (
        <StyledSection
            {...htmlAttributes}
            aria-busy={isLoading}
            className={classnames('OR60SeaEpi', className)}
        >
            {!!title && (
                <Title>{title}</Title>
            )}
            {!!isLoading && (
                <StyledIC103Loading color={lifestyle} size={`${SPINNER_SIZE_PX}px`} />
            )}
            {currentView === 'stack' && (
                <StackWrapper>
                    {React.Children.map(children, ({key, ...slide}) => (
                        <StackItem key={key}>
                            {slide}
                        </StackItem>
                    ))}
                </StackWrapper>
            )}
            {currentView === 'carousel' && (
                <StyledCAM01Stand
                    breakpointPageSizes={breakpointPageSizes}
                    isPipIndicatorHidden={true}
                >
                    {children}
                </StyledCAM01Stand>
            )}
            <BottomBorder />
        </StyledSection>
    );
}

OR60SeaEpi.displayName = 'OR60SeaEpi';

OR60SeaEpi.propTypes = {
    /** Definitions for how large page size should be at different breakpoints (for carousel view) */
    breakpointPageSizes: propTypes.objectOf(propTypes.number),
    /** Carousel Slides */
    children: propTypes.node,
    /** CSS classname to apply (e.g. for styled-components restyling) */
    className: classNameType,
    /** Carousel Title */
    title: propTypes.node,
};
