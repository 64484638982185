import React from 'react';

import Ic from '..';

const IC228MyAccount = (args) => (
    <Ic {...args}>
        <g transform="translate(24.24 69.975)">
            <path opacity=".35" d="M11.652 3.035c-4.76 0-8.633 3.892-8.633 8.676 0 .74.6 1.342 1.337 1.342H48.1a1.34 1.34 0 0 0 1.336-1.342c0-4.784-3.873-8.676-8.633-8.676H11.652z" />
            <path d="M11.652 3.035c-4.76 0-8.633 3.892-8.633 8.676 0 .74.6 1.342 1.337 1.342H48.1a1.34 1.34 0 0 0 1.336-1.342c0-4.784-3.873-8.676-8.633-8.676H11.652zm36.45 13.053H4.355C1.953 16.088 0 14.125 0 11.711 0 5.254 5.227 0 11.652 0h29.152c6.425 0 11.652 5.254 11.652 11.711 0 2.414-1.954 4.377-4.355 4.377z" />
        </g>
        <path opacity=".35" d="M50.468 64.49c-12.483 0-22.64-11.34-22.64-25.277 0-15.119 9.098-25.276 22.64-25.276s22.64 10.157 22.64 25.276c0 13.938-10.156 25.277-22.64 25.277" />
        <path d="M50.468 16.971c-11.92 0-19.62 8.73-19.62 22.242 0 12.264 8.802 22.242 19.62 22.242 10.819 0 19.62-9.978 19.62-22.242 0-13.511-7.7-22.242-19.62-22.242m0 47.519c-12.483 0-22.64-11.34-22.64-25.277 0-15.119 9.098-25.276 22.64-25.276s22.64 10.157 22.64 25.276c0 13.938-10.156 25.277-22.64 25.277" />
    </Ic>
);

IC228MyAccount.displayName = 'IC228MyAccount';

export default IC228MyAccount;
