import React, {cloneElement} from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';

import {classNameType, srcsetOptionsType} from '@fsa-streamotion/custom-prop-types';

import EMPTY_IMAGE_SRC from '../../../../common/empty-image-src';
import {getCtaType, StyledTile, StyledHoverLine} from '../../../../common/tile-utils';
import GA01ProBar from '../../../atoms/ga/01-pro-bar';
import {white} from '../../../../common/palette';
import Description from '../common/description';

const StyledProgressBar = styled(GA01ProBar)`
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: ${rgba(white, 0.2)};
    width: 100%;
`;

const TM14ShowTil = React.forwardRef(({
    card,
    className,
    currentProgressPerc,
    description,
    href,
    isBeingLazy,
    label,
    lazySrc = EMPTY_IMAGE_SRC,
    onClick,
    ...htmlAttributes
}, ref) => {
    const Cta = getCtaType(href, onClick);

    return (
        <StyledTile className={classnames('TM14ShowTil', className)}>
            <Cta
                {...htmlAttributes}
                ref={ref}
                aria-label={label}
                href={href}
                onClick={onClick}
            >
                {cloneElement(
                    card,
                    {isBeingLazy, srcsetOptionsLazyLoadPlaceholder: lazySrc}
                )}
                {(!!description || !!currentProgressPerc) && (
                    <Description description={description}>
                        {!!currentProgressPerc && (
                            <StyledProgressBar current={currentProgressPerc} />
                        )}
                    </Description>
                )}
            </Cta>
            <StyledHoverLine />
        </StyledTile>
    );
});

TM14ShowTil.propTypes = {
    /** Tile image which will take in isBeingLazy and/or lazySrc props */
    card: propTypes.node,
    /** Additional className for the component */
    className: classNameType,
    /** A number which shows the current viewer's progress */
    currentProgressPerc: propTypes.number,
    /** Text shown on the tile describing the video, e.g. title of the asset */
    description: propTypes.node,
    /** If the component is intended as an anchor tag, this is the link to redirect the user to */
    href: propTypes.string,
    /** Is the inner component supposed to be lazy loaded? */
    isBeingLazy: propTypes.bool,
    /** A string to label the component via aria-label */
    label: propTypes.string,
    /** A URL or path to an image to be lazy loaded */
    lazySrc: srcsetOptionsType, // @TODO: This should be called lazySrcsetOptions
    /** If the component does not have a href property, use this event handler */
    onClick: propTypes.func,
};

TM14ShowTil.displayName = 'TM14ShowTil';

export default TM14ShowTil;
