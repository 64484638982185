import React from 'react';
import Ic from '..';

const IC07Pau = (args) => (
    <Ic {...args}>
        <path fillRule="nonzero" d="M37.034 5h.384a4 4 0 0 1 4 4v81.241a4.192 4.192 0 0 1-8.384 0V9a4 4 0 0 1 4-4zM62.62 5h.385a4 4 0 0 1 4 4v81.241a4.192 4.192 0 0 1-8.385 0V9a4 4 0 0 1 4-4z" />
    </Ic>
);

IC07Pau.displayName = 'IC07Pau';

export default IC07Pau;
