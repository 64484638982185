import styled from 'styled-components';
import {Img} from 'normalized-styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {
    SCREEN_768_TABLET,
    SCREEN_1024_DESKTOP,
    SCREEN_1280_DESKTOP,
    SCREEN_1920_DESKTOP,
    SCREEN_2560_DESKTOP,
} from '../../../../../common/screen-sizes';

const ThumbnailImage = styled(Img)`
    display: block;
    margin: 0;
    width: 144px;
    height: 81px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: 192px;
        height: 108px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        width: 240px;
        height: 135px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        width: 224px;
        height: 126px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 304px;
        height: 171px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
        width: 369px;
        height: 207px;
    `}
`;

ThumbnailImage.displayName = 'ThumbnailImage';

export default ThumbnailImage;
