import bacon from 'baconjs';
import superagent from 'superagent';
import get from 'lodash/get';
import property from 'lodash/property';

import {SIGNUP_API} from '@fsa-streamotion/streamotion-web-widgets-common';

/**
 * Request that a new verification code be sent to the user.
 *
 * @param {Object} options                  - see below
 * @param {string} options.brand            - the concerned brand eg. 'kayo', 'binge', 'flash'
 * @param {string} options.platformEnv      - the platform environment
 * @param {string} options.accessToken      - the current user's access token
 * @param {string} options.mobileNumber     - the mobile number to send the code to
 * @returns {Promise} a promise that resolves to the response body on success
 */
export default function requestVerificationCode({
    brand,
    platformEnv,
    accessToken,
    mobileNumber,
}) {
    const missingRequiredParams = getMissingRequiredParams({brand, platformEnv, accessToken, mobileNumber});

    if (missingRequiredParams.length) {
        throw new Error(`Missing required parameters: ${missingRequiredParams.join(', ')}`);
    }

    // Ensure mobile number has been validated and internationalised before calling this function.
    if (!/^\+614\d{8}$/.test(mobileNumber)) {
        throw new Error(`Mobile number not valid. Expected '+614xxxxxxxx'. Actual '${mobileNumber}'`);
    }

    const url = `${SIGNUP_API[platformEnv]}/user/create/phone`;
    const request = superagent
        .post(url)
        .accept('json')
        .set('Authorization', `Bearer ${accessToken}`)
        .send({
            connection: 'sms',
            phone_number: mobileNumber,
        })
        .then(property('body'))
        .catch((error) => {
            const errorBody = JSON.parse(error?.response?.text || '""');

            throw {
                errorCode: error.status,
                errorMessage: get(errorBody, 'message', error.toString()),
            };
        });

    return bacon.fromPromise(request);
}

function getMissingRequiredParams(requiredParams) {
    return Object
        .entries(requiredParams)
        .filter(([, value]) => !(value && value.length))
        .map(([key]) => key);
}
