import bacon from 'baconjs';

import getResourcesAsset from '../../../../todo-move-to-widgets-common/streams/endpoints/resources';

/*
const DisclaimerConfigLooksLikeThis = {
    message: 'By clicking Continue I agree to the ${TermsAndConditions}, ${PrivacyPolicy} and ${DataCollectionStatement} and that I am over 18 years of age. If you do not wish to receive marketing communications from us, please email ${PrivacyOfficerEmail}.',
    replacements: {
        TermsAndConditions: {
            type: 'link',
            display: 'Terms and Conditions',
            value: 'https://help.kayosports.com.au/s/terms-and-conditions',
        },
        PrivacyPolicy: {
            type: 'link',
            display: 'Privacy Policy',
            value: 'https://help.kayosports.com.au/s/privacy-policy',
        },
        DataCollectionStatement: {
            type: 'link',
            display: 'Data Collection Statement',
            value: 'https://help.kayosports.com.au/s/data-collection',
        },
        PrivacyOfficerEmail: {
            type: 'email',
            display: 'privacyofficer@streamotion.com.au',
            value: 'privacyofficer@streamotion.com.au',
        },
    }
}
*/

export default function getLegalDisclaimerStream({resourcesEnv}) {
    return getResourcesAsset({
        brand: 'common', // Its shared across all brands
        resourcesEnv,
        path: 'user-signup/legal-disclaimer.json',
    })
        .flatMapError((error) => new bacon.Error(error.message));
}
