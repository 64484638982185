import React from 'react';

import Ic from '..';

const IC66AppleTv = (args) => (
    <Ic {...args}>
        <path d="M57 32.3v6.9h8.6v2.5H57v20.4c0 2.1.3 3.7 1 4.8.7 1.1 1.8 1.7 3.5 1.7.8 0 1.5 0 2-.1.6-.1 1-.2 1.4-.3l.3 2.3c-1 .5-2.4.7-4.2.7-1.4 0-2.6-.2-3.5-.7-.9-.5-1.7-1.1-2.2-2-.5-.8-.9-1.9-1.1-3.1-.2-1.2-.3-2.5-.3-3.9V41.7h-5.1v-2.5h5.1v-5.7l3.1-1.2zm13.6 6.9l7.2 19.4c.6 1.5 1.1 2.9 1.6 4.3.5 1.3.9 2.7 1.4 4h.1c.4-1.3.9-2.6 1.4-3.9.5-1.4 1.1-2.8 1.7-4.3l7.2-19.4h3.3L82 70.4h-2.7l-12-31.2h3.3zM24.5 32.6c2.8-3.7 6.6-3.7 6.6-3.7s.6 3.4-2.2 6.7c-2.9 3.5-6.3 3-6.3 3s-.5-2.8 1.9-6zM23 41c1.4 0 4.1-2 7.5-2 5.9 0 8.3 4.2 8.3 4.2s-4.6 2.3-4.6 8c0 6.4 5.7 8.6 5.7 8.6S36 71 30.6 71c-2.5 0-4.4-1.7-7-1.7-2.6 0-5.3 1.7-7 1.7-4.9.1-11-10.5-11-19 0-8.4 5.2-12.8 10.2-12.8 3.1 0 5.6 1.8 7.2 1.8z" />
    </Ic>
);

IC66AppleTv.displayName = 'IC66AppleTv';

export default IC66AppleTv;
