import React from 'react';
import Ic from '..';

const IC10Mltv = (args) => (
    <Ic {...args}>
        <path d="M88.2 9.2H48.7c-1.5 0-2.8 1.2-2.8 2.8v27.8c0 1.5 1.2 2.8 2.8 2.8h39.5c1.5 0 2.8-1.2 2.8-2.8V11.9c0-1.5-1.3-2.7-2.8-2.7zm-2.8 27.7h-34V14.7h34v22.2zM53 57.4H10.4c-.8 0-1.4.6-1.4 1.4v30.7c0 .8.6 1.4 1.4 1.4H53c.8 0 1.4-.6 1.4-1.4V58.8c0-.8-.7-1.4-1.4-1.4zm-1.4 30.7H11.8V60.2h39.8v27.9zM10.9 49.8h5.6c.1-11.4 5.8-21.4 15-27.1L29.9 32l4.1.7 2.6-14.9c.1-.5 0-1.1-.3-1.6s-.8-.8-1.3-.9l-15-2.4-.8 4.1 8.6 1.5C17.5 25.3 11 37 10.9 49.8zM89.2 49.6h-5.6c-.1 11.4-5.8 21.4-15 27.1l1.6-9.3-4.1-.7-2.6 14.9c-.2 1.1.6 2.2 1.7 2.4l14.9 2.6.7-4.1-8.6-1.5c10.4-6.9 16.9-18.6 17-31.4z" />
    </Ic>
);

IC10Mltv.displayName = 'IC10Mltv';

export default IC10Mltv;
