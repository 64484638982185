import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import property from 'lodash/property';
import {Button} from 'normalized-styled-components';
import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {transition} from '../../../../../common/animations';
import {white} from '../../../../../common/palette';

const StyledButton = styled(Button)`
    appearance: none;
    display: flex;
    border: 0;

    background-color: transparent;
    cursor: pointer;
    padding: 7px;

    width: ${property('size')};
    height: ${property('size')};

    font: inherit;

    ${stylesWhen('isTabletUp')`
        width: ${({isLabelVisible, size}) => isLabelVisible ? 'auto' : size};
    `}

    &[disabled] {
        opacity: 0.3;
    }

    &:focus {
        outline: none;
    }
`;

const IconContainer = styled.div`
    transition: ${transition('transform', 'opacity')};
    width: 100%;

    ${stylesWhen('isLabelVisible')`
        width: 30px;
    `}

    /* there are too many false positive rules broken here to specify rules */
    ${StyledButton}:hover &,
    ${StyledButton}:focus & {
        transform: scale(1.15);
    }
`;

const ButtonLabel = styled.span`
    display: none;
    color: ${white};
    font: var(--mui-body-copy-3-bold);

    ${stylesWhen('isTabletUp')`
        display: inline-block;
        text-align: center;
        height: auto;
        margin: auto;
        margin-left: 10px;
    `}
    ${stylesWhen('isLabelOnLeft')`
        margin-left: 0;
        margin-right: 14px;
    `}
`;

const VPBA01CtrlBtn = React.forwardRef(({
    children,
    label,
    size = '44px',

    isTabletUp,
    isDisabled,
    isPressed,
    isLabelVisible,
    isLabelOnLeft = false,

    onClick,
    onFocus,
    onMouseEnter,
    onMouseLeave,
    ...htmlAttributes
}, ref) => (
    <StyledButton
        {...htmlAttributes}
        aria-label={!isLabelVisible && label}
        aria-pressed={isPressed}

        disabled={isDisabled}
        isLabelVisible={isLabelVisible}
        isLabelOnLeft={isLabelOnLeft}
        title={label}
        isTabletUp={isTabletUp}
        size={size}
        onClick={onClick}
        onFocus={onFocus}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}

        ref={ref}
    >
        {!!isLabelVisible && !!isLabelOnLeft && (
            <ButtonLabel isTabletUp={isTabletUp} isLabelOnLeft={isLabelOnLeft}>{label}</ButtonLabel>
        )}
        <IconContainer isLabelVisible={isLabelVisible}>
            {children}
        </IconContainer>
        {!!isLabelVisible && !isLabelOnLeft && (
            <ButtonLabel isTabletUp={isTabletUp}>{label}</ButtonLabel>
        )}
    </StyledButton>
));

VPBA01CtrlBtn.displayName = 'VPBA01CtrlBtn';

VPBA01CtrlBtn.propTypes = {
    /** The childen is actually an icon */
    children: propTypes.node,
    /** Text that appears on hover */
    label: propTypes.string,
    /** Size of the button */
    size: propTypes.string,
    /** Button label on left */
    isLabelOnLeft: propTypes.bool,
    /** Is the size of the screen above tablet size? */
    isTabletUp: propTypes.bool,
    /** Is the component disabled? */
    isDisabled: propTypes.bool,
    /** Has the component been pressed? */
    isPressed: propTypes.bool,
    /** Should the label be visible? */
    isLabelVisible: propTypes.bool,

    /** OnClick Handler */
    onClick: propTypes.func,
    /** OnFocus Handler */
    onFocus: propTypes.func,
    /** OnMouseEnter Handler */
    onMouseEnter: propTypes.func,
    /** OnMouseLeave Handler */
    onMouseLeave: propTypes.func,
};

export default VPBA01CtrlBtn;
