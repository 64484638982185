import type {Requireable, Validator} from 'prop-types';

import generateError from './generate-error';
import getTypeName from './get-type-name';
import type {PropType} from './types';

type Args = {
    prefix?: string;
    expected: string;
};

/**
 * Customize a `propTypes` validator's error message.
 *
 * @remarks
 * This doesn't work with `prop-types` built-in validators as they log warnings themselves.
 *
 * @param propType  - a `propTypes` validator
 * @param prefix    - the error message's optional prefix
 * @param expected  - a description of the expected value
 * @returns a validator that customized the provided validator's error message
 */
export default function customizeError<P extends PropType>(
    propType: Requireable<P>,
    {prefix, expected}: Args
): Requireable<P> {
    const baseValidator: Validator<P> = (...args): Error | null => {
        // Passing through args verbatim so propType doesn't detect being called directly.
        const result = propType(...args);

        if (!result) {
            return result;
        }

        const [props, propName, componentName, location, propFullName] = args;

        return generateError({
            prefix,
            location,
            propFullName,
            actualType: getTypeName(props[propName]),
            componentName,
            expected,
        });
    };

    return Object.assign(baseValidator, {isRequired: propType.isRequired});
}
