import React from 'react';

import Ic from '..';

const IC151Pip = (args) => (
    <Ic {...args}>
        <path d="M93.9 25.1H6.1c-.3 0-.6.2-.6.6v48.6c0 .3.2.6.6.6h87.8c.3 0 .6-.2.6-.6V25.7c0-.3-.3-.6-.6-.6zm-.5 48.7H6.6V26.2h86.7v47.6z" />
        <path d="M46.3 68.2h40.3c.6 0 1.1-.5 1.1-1.1v-19c0-.6-.5-1.1-1.1-1.1H46.3c-.6 0-1.1.5-1.1 1.1v19c0 .6.5 1.1 1.1 1.1zm1.1-19h38.1V66H47.4V49.2z" />
    </Ic>
);

IC151Pip.displayName = 'IC151Pip';

export default IC151Pip;
