import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Figure} from 'normalized-styled-components';
import {rgba} from 'polished';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {
    SCREEN_1024_DESKTOP,
    SCREEN_1280_DESKTOP,
    SCREEN_1920_DESKTOP,
} from '../../../../../common/screen-sizes';
import {blanc, panther} from '../../../../../common/palette';
import EMPTY_IMAGE_SRC from '../../../../../common/empty-image-src';

import ThumbnailImage from './image';

import Arrow from './arrow';

const Container = styled(Figure)`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
`;

const Thumbnail = styled.div`
    position: relative;
    border-bottom: 2px solid ${blanc};

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        border-bottom-width: 3px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        border-bottom-width: 4px;
    `}
`;

const Content = styled.div`
    box-sizing: border-box;
    display: flex;
    position: absolute;
    bottom: 0;
    flex-direction: column-reverse;
    background-image: linear-gradient(to top, ${rgba(panther, 0.9)}, transparent 28px);
    padding: 5px 7px;
    width: 100%;
    height: 100%;
    text-align: right;
    color: ${blanc};
    font: var(--ionic-tile-descriptor);

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        background-image: linear-gradient(to top, ${rgba(panther, 0.9)}, transparent 35px);
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        background-image: linear-gradient(to top, ${rgba(panther, 0.9)}, transparent 42px);
        padding: 10px 14px;
    `}
`;

const HoverThumbnailView = ({
    src = EMPTY_IMAGE_SRC,
    content,
    shouldShowArrow = true,
}) => (
    <Container>
        <Thumbnail>
            <ThumbnailImage src={src} />
            {!!content && (
                <Content>{content}</Content>
            )}
        </Thumbnail>
        {!!shouldShowArrow && (<Arrow />)}
    </Container>
);

HoverThumbnailView.propTypes = {
    /** URL to load the thumbnail image from */
    src: propTypes.string,
    /** Content to display over the thumbnail. Typically a timestamp. */
    content: propTypes.node,
    /** Whether to show an arrow at the bottom of the thumbnail */
    shouldShowArrow: propTypes.bool,
};

HoverThumbnailView.displayName = 'HoverThumbnailView';

export default HoverThumbnailView;
