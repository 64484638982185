import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import noop from 'lodash/noop';

import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import invoke from 'lodash/invoke';
import CommonTransition from '../../../../../common/common-transition';
import VPGA02TimeTooltip from '../../../../atoms/vp/ga/02-time-tooltip';
import {fadeInOut} from '../../../../../common/animations';
import VPGA10KeyMoment from '../../../../atoms/vp/ga/10-key-moment';
import {white} from '../../../../../common/palette';
import Scrubber from './scrubber';
import ProgressBar from './progress-bar';

const ACTIVE_KEY_MOMENT_ZINDEX = 1;

const Wrapper = styled.div`
    position: relative;
`;

const TimeTooltipContainer = styled.div`
    position: absolute;
    bottom: 0;
    transform: translate(-50%);
    margin-bottom: 24px;
    ${fadeInOut}
`;

const KeyMomentContainer = styled.div`
    position: absolute;
    bottom: calc(50% + 9px);
    transform: translateX(-50%);
    pointer-events: none;
`;

const KeyMomentPositioner = styled.div`
    position: absolute;
    z-index: 0;
    width: 100%;
    pointer-events: none;

    ${stylesWhen('isKeyMomentFocused')`
        z-index: ${ACTIVE_KEY_MOMENT_ZINDEX};
    `}

    &:hover {
        z-index: ${ACTIVE_KEY_MOMENT_ZINDEX};
    }
`;

const KeyMomentsDescription = styled.div`
    position: absolute;
    top: -60px;
    width: 100%;
    text-align: center;
    color: ${white};
    font: var(--mui-header-6);
`;

const getPositionOnScrubber = (time, duration) => (time / duration) * 100;

class VPIM02Scrubber extends React.Component {
    static displayName = 'VPIM02Scrubber';

    static propTypes = {
        bufferedTimeRanges: propTypes.arrayOf(propTypes.shape({
            start: propTypes.number,
            end: propTypes.number,
        })),
        currentTime: propTypes.number,
        duration: propTypes.number,
        seekingTime: propTypes.number,
        isSeeking: propTypes.bool,
        isSeekingTooltipEnabled: propTypes.bool,
        isUserHoldingControls: propTypes.bool,
        latestSecondsViewed: propTypes.number,
        isKeyMomentsSlowReveal: propTypes.bool,
        isKeyMomentsVisible: propTypes.bool,
        keyMoments: propTypes.arrayOf(propTypes.object),

        onChange: propTypes.func,
        onFocus: propTypes.func,
        onMouseDownUp: propTypes.func,
        onArrowKeyDown: propTypes.func,
        onChangeHover: propTypes.func,
    };

    static defaultProps = {
        bufferedTimeRanges: [],
        currentTime: 0,
        duration: 0,
        seekingTime: 0,
        keyMoments: [],
        onChange: noop,
    };

    state = {
        activeTitle: '',
    };

    keyMomentRefs = [];

    render() {
        const {
            bufferedTimeRanges,
            currentTime,
            duration,
            isSeeking,
            seekingTime,
            keyMoments,
            isSeekingTooltipEnabled,
            isUserHoldingControls,
            latestSecondsViewed,
            isKeyMomentsSlowReveal,
            isKeyMomentsVisible,
            onChange = noop,
            onChangeHover,
            onFocus,
            onMouseDownUp,
            onArrowKeyDown,
        } = this.props;

        return (
            <Wrapper
                className="VPIM01Scrubber"
                onKeyDown={onArrowKeyDown}
            >
                {!!this.state.activeTitle && (
                    <KeyMomentsDescription>
                        {this.state.activeTitle}
                    </KeyMomentsDescription>
                )}
                <ProgressBar
                    start={0}
                    current={(currentTime / duration) * 100}
                    end={100}
                    isDuration={true}
                />

                {bufferedTimeRanges
                    .filter(({end}) => end >= currentTime) // hide ranges before playhead
                    .map(({start, end}) => (
                        <ProgressBar
                            start={(Math.max(start, currentTime) / duration) * 100 /* Hide portion of buffered range behind playhead */}
                            end={(end / duration) * 100}
                            key={start}
                        />
                    ))}

                <Scrubber
                    defaultValue={currentTime}
                    displayTime={isSeeking ? seekingTime : currentTime}
                    duration={duration}
                    onChange={({target}) => void onChange(target.value)}
                    onChangeHover={onChangeHover}
                    onFocus={onFocus}
                    onMouseDownUp={onMouseDownUp}
                />

                {!!isKeyMomentsVisible && !!keyMoments && keyMoments
                    // if showSpoilers is false we only show key moments before current time
                    .filter(({displayTime}) => !isKeyMomentsSlowReveal || displayTime <= latestSecondsViewed)
                    .map(({code, name, displayTime, onClick}, index) => (
                        <KeyMomentPositioner
                            key={index}
                            style={{transform: `translateX(${getPositionOnScrubber(displayTime, duration)}%)`}}
                            ref={(positionerRef) => { this.keyMomentRefs[index] = positionerRef; }}
                            isKeyMomentFocused={invoke(this.keyMomentRefs, [index, 'contains'], document.activeElement)}
                        >
                            <KeyMomentContainer>
                                <VPGA10KeyMoment
                                    abbrText={code}
                                    title={name}
                                    onClick={onClick}
                                    onMouseEnter={() => this.setState({activeTitle: name})}
                                    onMouseLeave={() => this.setState({activeTitle: ''})}
                                    onFocus={() => this.setState({activeTitle: name})}
                                    onBlur={() => this.setState({activeTitle: ''})}
                                    componentWillUnmount={() => this.setState({activeTitle: ''})}
                                />
                            </KeyMomentContainer>
                        </KeyMomentPositioner>
                    ))
                }

                {!!duration && (
                    <CommonTransition in={isUserHoldingControls && isSeekingTooltipEnabled}>
                        <div
                            style={{
                                transform: `translateX(${getPositionOnScrubber(seekingTime, duration)}%)`,
                            }}
                        >
                            <TimeTooltipContainer>
                                <VPGA02TimeTooltip seekingTime={seekingTime} />
                            </TimeTooltipContainer>
                        </div>
                    </CommonTransition>
                )}
            </Wrapper>
        );
    }
}

export default VPIM02Scrubber;
