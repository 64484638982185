/**
 * @description Takes a raw JWT and returns decoded token.
 * Could / should be replaced with https://github.com/auth0/jwt-decode
 *
 * @param {string} dataBlob - Raw JWT string
 * @returns {object}        - Decoded token
 */
export default function decodeToken(dataBlob) {
    const [, unicodePayload] = dataBlob.split('.'); // <header>.<payload>.<signature>

    const payload = unicodePayload
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const decoded = atob(payload);
    const parsed = JSON.parse(decoded);

    return parsed; // we used to return the blob/decoded as well as parsed. But that's because we used to manage our own token cache.
}
