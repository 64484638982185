import propTypes from 'prop-types';

import colorType from './color-type';

/**
 * Prop type used for linear gradients.
 */
const gradientType = propTypes.shape({
    top: colorType.isRequired,
    bottom: colorType.isRequired,
});

export default gradientType;
