import React from 'react';

import Ic from '..';

const IC312Live = (args) => (
    <Ic {...args}>
        <path
            d="M50,0 C22.3857625,0 0,22.3857625 0,50 C0,77.6142375 22.3857625,100 50,100 C77.6142375,100 100,77.6142375 100,50 C100,22.3857625 77.6142375,0 50,0 Z"
            fillRule="nonzero"
        />
    </Ic>
);

IC312Live.displayName = 'IC312Live';

export default IC312Live;
