import React from 'react';
import propTypes from 'prop-types';
import noop from 'lodash/noop';
import {rgba} from 'polished';
import styled, {css} from 'styled-components';
import {Input} from 'normalized-styled-components';

import {transition} from '../../../../common/animations';
import {black, satellite, kayoGreen} from '../../../../common/palette';
import {fontFamily} from '../../../../common/typography';

const StyledLabel = styled.label`
    position: relative;
    width: 100%;
    height: 69px;
`;

const FloatingLabelText = styled.span`
    position: absolute;
    top: ${({visible}) => visible ? '5px' : '28px'};
    left: 50%;
    transform: translateX(-50%);
    transition: ${transition('top', 'opacity')};
    opacity: ${({visible}) => visible ? 1 : 0};
    text-shadow: 1px 1px 2px ${rgba(black, 0.75)};
    color: ${satellite};
    font-family: ${fontFamily};
    font-size: 13px;
`;

const StyledInput = styled(Input).attrs({
    type: 'text',
})`
    box-sizing: border-box;
    transition: ${transition('border-color')};
    border: 0;
    border-bottom: 1px solid ${satellite};
    background-color: transparent;
    padding: 21px 14px;
    width: 100%;
    height: 100%;
    text-align: center;
    text-shadow: 1px 1px 2px ${rgba(black, 0.75)};
    color: ${satellite};

    /* stylelint-disable */
    ${['::-webkit-input', '::-moz', ':-ms-input']
        .map((prefix) => css`
            /* stylelint-disable */
            ${prefix}-placeholder {
                color: ${satellite};
            }
            /* stylelint-enable */
        `)}
    /* stylelint-enable */

    /* Hide the eyeball icon on Edge */
    &::-ms-reveal,
    &::-ms-clear {
        display: none;
    }

    &:invalid,
    &:required {
        /* Rely on our own custom designs to communicate invalid and required states */
        box-shadow: none;
    }

    &:focus {
        outline: 0;
        border-color: ${kayoGreen};
    }
`;

class IA07InputPre extends React.Component {
    static displayName = 'IA07InputPre';

    static propTypes = {
        /** If supplied, will be initial value of the search field */
        defaultValue: propTypes.string,
        /** Placeholder label for input field, which floats above on text input */
        label: propTypes.string.isRequired,
        /** Callback on text field value change */
        onChange: propTypes.func,
        /* ref to be attached to the input element */
        forwardedRef: propTypes.object,
    };

    static defaultProps = {
        onChange: noop,
    };

    state = {hasValue: !!this.props.defaultValue, hasFocus: false};

    handleChange = (event) => void this.setState({hasValue: !!event.target.value});
    handleFocus = () => void this.setState({hasFocus: true});
    handleBlur = () => void this.setState({hasFocus: false});

    render() {
        const {
            defaultValue,
            label,
            onChange,
            forwardedRef,
            ...htmlAttributes
        } = this.props;

        const showLabel = this.state.hasValue || this.state.hasFocus;

        // Setting inline style due to Edge and Styled Components not working well with CSS variables
        const inputFont = {
            font: 'var(--mui-header-5)',
        };

        return (
            <StyledLabel>
                <FloatingLabelText visible={showLabel}>{label}</FloatingLabelText>
                <StyledInput
                    {...htmlAttributes}
                    ref={forwardedRef}
                    defaultValue={defaultValue}
                    placeholder={showLabel ? null : label}
                    title={label}
                    type="text"
                    onChange={(...args) => { // note: React's "onChange" event behaves like "onInput" https://github.com/facebook/react/issues/3964
                        this.handleChange(...args);
                        onChange(...args);
                    }}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    style={inputFont}
                />
            </StyledLabel>
        );
    }
}

export default React.forwardRef((props, ref) => <IA07InputPre forwardedRef={ref} {...props} />);
