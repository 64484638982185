/* eslint-disable max-len */

import React from 'react';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import Ic from '../../ic';

const GA130GiftCard = ({
    className,
    ...htmlAttributes
}) => (
    <Ic
        {...htmlAttributes}
        className={classnames('GA130GiftCard', className)}
        ariaLabel="Gift card"
        preserveColors={true}
    >
        <g fill="none" fillRule="evenodd">
            <rect fill="#FAFAFA" width="99.86" height="81.9" y="8.85" rx="2" />
            <path
                d="M50.265 22.831l.405.005c3.76.093 7.214 1.493 10.835 4.384l.12.096-2.408 3.133-.126-.103-.35-.281c-3.025-2.387-5.728-3.502-8.476-3.502l-.247.004c-2.698.085-4.493 1.56-4.493 3.723l.006.205c.162 2.845 3.701 4.4 7.784 6.193l.262.115C59.627 39.463 67 42.831 67 51.923l-.003.291c-.182 7.931-7.768 14.335-17.067 14.335l-.464-.005c-5.713-.107-10.92-2.21-16.346-6.594l-.12-.097 2.206-2.862.126.1.438.344c4.804 3.718 9.191 5.382 14.16 5.382l.333-.004c7.09-.144 12.595-4.875 12.595-10.89l-.005-.338c-.188-6.257-5.573-8.962-10.97-11.467l-.578-.268-.289-.134c-4.994-2.313-9.633-4.552-9.633-9.426 0-4.182 3.902-7.459 8.882-7.459zm0-6.831l.47.005c5.302.104 10.038 1.995 14.864 5.924l.12.098-2.206 2.862-.126-.102-.403-.318c-4.162-3.23-8.215-4.737-12.719-4.737l-.314.003c-6.687.143-12.076 4.823-12.076 10.555l.005.339c.184 6.145 5.382 8.864 10.68 11.332l.867.403.289.133c4.994 2.314 9.633 4.553 9.633 9.427l-.004.24c-.143 4.323-4.143 7.553-9.415 7.553l-.4-.005c-4.117-.096-7.914-1.686-12.248-5.12l-.123-.097 2.412-3.064.125.103.372.302c3.58 2.866 6.636 4.149 9.862 4.149l.268-.004c3.098-.083 5.01-1.622 5.01-4.057l-.006-.206c-.163-2.845-3.702-4.399-7.785-6.193l-.262-.115c-6.05-2.66-13.423-6.028-13.423-15.12 0-7.746 7.572-14.29 16.533-14.29z"
                fill="#F05454"
                transform="translate(0, 8)"
            />
        </g>
    </Ic>
);

GA130GiftCard.propTypes = {
    /** Additional class(es) */
    className: classNameType,
};

GA130GiftCard.displayName = 'GA130GiftCard';

export default GA130GiftCard;
