import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';

import {rgba} from 'polished';
import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import classnames from 'classnames';
import {ink} from '../../../../common/palette';
import {SCREEN_768_TABLET} from '../../../../common/screen-sizes';

const ScrollIndicator = styled.button.attrs({type: 'button'})`
    appearance: none;
    transition: background-color 0.2s ease-in-out;
    outline: none;
    border: none;
    border-radius: 100%;
    background-color: ${({isActive}) => rgba(ink, isActive ? 0.4 : 0.15)};
    cursor: pointer;
    padding: 0;
    width: 7px;
    height: 7px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: 10px;
        height: 10px;
    `}

    &:hover {
        background-color: ${rgba(ink, 0.6)};
    }

    &:active {
        background-color: ${ink};
    }

    &::before {
        position: relative;
        left: -6px;
        padding: 8px 11px;
        content: '';
    }
`;

const Wrapper = styled.div`
    display: flex;
    gap: 14px;
`;

/**
 * Scroll Indicator
 *
 * @see {@link https://zpl.io/p1YB06J}
 */
const GM17ScrollIndicator = ({
    className,
    focusIndex,
    length,
    onClick,
    ...htmlAttributes
}) => (
    <Wrapper className={classnames('GM17ScrollIndicator', className)} {...htmlAttributes}>
        {Array.from({length}).map((_, pageIndex) => (
            <ScrollIndicator
                aria-label={`Image ${pageIndex + 1}`}
                onClick={() => onClick(pageIndex)}
                key={`image${pageIndex + 1}`}
                isActive={focusIndex === pageIndex}
            />
        ))}
    </Wrapper>
);

GM17ScrollIndicator.propTypes = {
    /** Additional class name(s) */
    className: propTypes.string,
    /** Index of the active slide */
    focusIndex: propTypes.number,
    /** Number of slides */
    length: propTypes.number,
    /** Callback for when a slide is clicked */
    onClick: propTypes.func,
};

GM17ScrollIndicator.displayName = 'GM17ScrollIndicator';

export default GM17ScrollIndicator;
