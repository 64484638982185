{
    "data": {
        "catalogModuleList": {
            "items": [
                {
                    "pg": "default",
                    "meta": {
                        "pageTitle": "BINGE | Watch TV Shows & Movies Online",
                        "language": "en",
                        "thumbnail": {
                            "_publishUrl": "https://content.streamotion.com.au/content/dam/nc/fc/sm/bge/acq/offer-page/header/og/binge-ogimage-v2.gif"
                        },
                        "canonicalLink": "https://binge.com.au/",
                        "metaDescription": "BINGE is home to the TV shows and movies that everyone is talking about. Stream online now with a free trial. New customers only | I SAW IT ON BINGE",
                        "ogDescription": "BINGE is home to the TV shows and movies that everyone is talking about. Stream online now with a free trial. New customers only | I SAW IT ON BINGE",
                        "ogImage": "/content/dam/nc/fc/sm/bge/acq/offer-page/header/og/binge-ogimage-v2.gif",
                        "ogLocale": "en_GB",
                        "ogSiteName": "BINGE",
                        "ogTitle": "BINGE | Watch TV Shows & Movies Online",
                        "ogUrl": null,
                        "ogType": "product",
                        "twitterSite": "@binge",
                        "twitterCard": null,
                        "twitterImage": "/content/dam/nc/fc/sm/bge/acq/offer-page/header/og/binge-ogimage-v2.gif",
                        "twitterTitle": "BINGE | Watch TV Shows & Movies Online",
                        "twitterDescription": "BINGE is home to the TV shows and movies that everyone is talking about. Stream online now with a free trial. New customers only | I SAW IT ON BINGE"
                    },
                    "modules": [
                        {
                            "id": "binge-landing-hero-banner-block",
                            "sectionTitle": "Streaming The World's Most Talked About Shows & Movies",
                            "type": "hero-banner",
                            "cta": "Restart your subscription",
                            "copy": null,
                            "copylines": null,
                            "url": {
                                "_publishUrl": "https://content.streamotion.com.au/content/experience-fragments/binge/site/landingpage/default/hero-banner/master.html"
                            }
                        },
                        {
                            "type": "promo-modal-cta",
                            "sectionTitle": null,
                            "copylines": null,
                            "ctaPrimary": "Had Kayo, Binge, or Flash before?",
                            "ctaSecondary": "New Customer"
                        },
                        {
                            "id": "binge-landing-placeholder-block-1",
                            "sectionTitle": null,
                            "type": "aem",
                            "cta": null,
                            "copy": null,
                            "copylines": null,
                            "url": {
                                "_publishUrl": "https://content.streamotion.com.au/content/experience-fragments/binge/site/landingpage/placeholders/placeholder/master.html"
                            }
                        },
                        {
                            "id": "binge-landing-default-package-selector",
                            "sectionTitle": "Select a subscription below",
                            "type": "package-selector",
							"cta": "https://staging.binge.com.au",
                            "copy": "Switch to Monthly Subscriptions >",
                            "copylines": [
                                "Binge over 25,000 hours of the world's best shows & movies",
                                "Watch on popular TV, computer, mobile & tablet and gaming console devices",
                                "On-demand content is ad-free in Standard and Premium",
                                "Selected content available in 4K on Standard and Premium*",
                                "No lock in contract, cancel anytime"
                            ],
                            "url": {
                                "_publishUrl": "https://content.streamotion.com.au/content/dam/nc/fc/sm/bge/acq/offer-page/package-selector/binge-package-selector-background.png"
                            }
                        },
                        {
                            "id": "binge-landing-placeholder-block-2",
                            "sectionTitle": null,
                            "type": "aem",
                            "cta": null,
                            "copy": null,
                            "copylines": null,
                            "url": {
                                "_publishUrl": "https://content.streamotion.com.au/content/experience-fragments/binge/site/landingpage/placeholders/placeholder/master.html"
                            }
                        },
                        {
                            "id": "binge-landing-marketing-block",
                            "sectionTitle": null,
                            "type": "aem",
                            "cta": null,
                            "copy": null,
                            "copylines": null,
                            "url": {
                                "_publishUrl": "https://content.streamotion.com.au/content/experience-fragments/binge/site/landingpage/default/marketing-block/master.html"
                            }
                        },
                        {
                            "id": "binge-landing-placeholder-block-3",
                            "sectionTitle": null,
                            "type": "aem",
                            "cta": null,
                            "copy": null,
                            "copylines": null,
                            "url": {
                                "_publishUrl": "https://content.streamotion.com.au/content/experience-fragments/binge/site/landingpage/placeholders/placeholder/master.html"
                            }
                        },
                        {
                            "id": "binge-entertainment-carousel",
                            "sectionTitle": null,
                            "type": "aem",
                            "cta": null,
                            "copy": null,
                            "copylines": null,
                            "url": {
                                "_publishUrl": "https://content.streamotion.com.au/content/experience-fragments/binge/site/landingpage/default/entertainment-block/master.html"
                            }
                        },
                        {
                            "id": "binge-landing-placeholder-block-4",
                            "sectionTitle": null,
                            "type": "aem",
                            "cta": null,
                            "copy": null,
                            "copylines": null,
                            "url": {
                                "_publishUrl": "https://content.streamotion.com.au/content/experience-fragments/binge/site/landingpage/placeholders/placeholder/master.html"
                            }
                        },
                        {
                            "id": "binge-landing-device-icon-text-block",
                            "sectionTitle": null,
                            "type": "aem",
                            "cta": null,
                            "copy": null,
                            "copylines": null,
                            "url": {
                                "_publishUrl": "https://content.streamotion.com.au/content/experience-fragments/binge/site/landingpage/default/device-carousel/master.html"
                            }
                        },
                        {
                            "id": "binge-landing-placeholder-block-5",
                            "sectionTitle": null,
                            "type": "aem",
                            "cta": null,
                            "copy": null,
                            "copylines": null,
                            "url": {
                                "_publishUrl": "https://content.streamotion.com.au/content/experience-fragments/binge/site/landingpage/placeholders/placeholder/master.html"
                            }
                        },
                        {
                            "id": "binge-marketing-link",
                            "sectionTitle": null,
                            "type": "aem",
                            "cta": null,
                            "copy": null,
                            "copylines": null,
                            "url": {
                                "_publishUrl": "https://content.streamotion.com.au/content/experience-fragments/binge/site/landingpage/default/marketing-link.html"
                            }
                        },
                        {
                            "id": "binge-landing-placeholder-block-6",
                            "sectionTitle": null,
                            "type": "aem",
                            "cta": null,
                            "copy": null,
                            "copylines": null,
                            "url": {
                                "_publishUrl": "https://content.streamotion.com.au/content/experience-fragments/binge/site/landingpage/placeholders/placeholder/master.html"
                            }
                        },
                        {
                            "id": "binge-landing-faq-block",
                            "sectionTitle": null,
                            "type": "aem",
                            "cta": null,
                            "copy": null,
                            "copylines": null,
                            "url": {
                                "_publishUrl": "https://content.streamotion.com.au/content/experience-fragments/binge/site/landingpage/default/faq/master.html"
                            }
                        },
                        {
                            "id": "binge-landing-placeholder-block-7",
                            "sectionTitle": null,
                            "type": "aem",
                            "cta": null,
                            "copy": null,
                            "copylines": null,
                            "url": {
                                "_publishUrl": "https://content.streamotion.com.au/content/experience-fragments/binge/site/landingpage/placeholders/placeholder/master.html"
                            }
                        },
                        {
                            "id": "binge-landing-footer-block",
                            "sectionTitle": null,
                            "type": "aem",
                            "cta": null,
                            "copy": null,
                            "copylines": null,
                            "url": {
                                "_publishUrl": "https://content.streamotion.com.au/content/experience-fragments/binge/site/landingpage/default/footer/master.html"
                            }
                        },
                        {
                            "id": "binge-landing-placeholder-block-8",
                            "sectionTitle": null,
                            "type": "aem",
                            "cta": null,
                            "copy": null,
                            "copylines": null,
                            "url": {
                                "_publishUrl": "https://content.streamotion.com.au/content/experience-fragments/binge/site/landingpage/placeholders/placeholder/master.html"
                            }
                        }
                    ]
                }
            ]
        }
    }
}