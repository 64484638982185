/* eslint-disable arrow-body-style */
import React from 'react';
import bacon from 'baconjs';
import matchesProperty from 'lodash/matchesProperty';
import noop from 'lodash/noop';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import includes from 'lodash/includes';

import {ACCOUNT_STATUS_ACTIVE_SUBSCRIPTION_STATUSES, ACCOUNT_STATUS_RETURNING} from '@fsa-streamotion/streamotion-web-widgets-common';

import getOfferStream from '../../../../todo-move-to-widgets-common/streams/endpoints/billing/offer';
import {BRAND_DISPLAY_NAME} from '../../../../todo-move-to-widgets-common/utils/constants';

import ComponentInvalidOfferModal from '../../components/branded/common/invalid-offer';
import {BrandedBA01BtnPr} from '../../utils/branded-components';
import getPackageSelectorPropsStream from './package-selector-stream';
import getVoucherDisplayPropsStream from './voucher-stream';

/*
    WELCOME BRAVE DEVELOPER!
    SO!  If a user lands on a page the client offer has an error or mismatched offer to landing page
    we need to warn the user.  This could be something like, you can't have this offer, voucher expired, etc.

    Additionally we may be asked to 'validate the selected package' (for checkout direct landing),
    show the 'select next best offer' flow, which involves us calling the package-summary-widget inside here
    and getting out new offer and selected package id.

    If we land on a journey === Telstra, we need to ensure we have a JWT for the flow.

    We could in future compare prefetchedOffer.offer.code === offer.offer.code, notice a difference and warn.

    For the time being, we're going to listen to errors on offer$ first, then use errors on prefetchedOffer$
    The offer$ is the 100% user token - billing api questioned - source of truth.
    The prefetched offer$, is the offer that the page was initially loaded with SSR.

    As the offer API presents a default offer always (even in error), we need to compare payloads vs status codes.
*/

/*
     - If we fail telstra validation, we HAVE to call forth from nextBestOffer to display shouldSelectNextBestOffer
     - - - - or - force the landing offer page to refresh with offer-name=""
*/

export default function getInvalidOfferModalStream({
    user$,
    userIsAuthenticated$, // don't fetch user details if not logged in.
    prefetchedOffer$,
    offer$,
    getHasConditionalOfferApproval,
    isFreemiumOffer$, // this isn't getting passed in but QA are okay with how modal behaves, it seems
    isPpvOffer$, // this isn't getting passed in but QA are okay with how modal behaves, it seems

    selectedPackageId,
    selectedPackagePriceId,

    shouldSelectNextBestOffer = false,
    shouldReloadedPageToNextBestOfferIfTelstra = false,
    validatePackagePriceIdSelection = false,

    forgetVoucherFunction = noop,
    forgetOfferNameFunction = noop,

    brand,
    platformEnv,
}) {
    if (!brand) {
        console.error('Accounts Widgets: brand not provided to getInvalidOfferModalStream');
    }

    const userRelatedDetails$ = bacon.combineTemplate({
        user: user$,
        userIsAuthenticated: userIsAuthenticated$,
    }).flatMapLatest(({user, userIsAuthenticated}) => {
        if (userIsAuthenticated) {
            return bacon.combineTemplate({
                user,
                userDetails: bacon.fromPromise(user.getUserDetails()),
                accessToken: bacon.fromPromise(user.getAccessToken()),
                accountStatus: bacon.fromPromise(user.getAccountStatusesByBrand(brand)).map('.account_status'),
            });
        } else {
            // We will still be used on the 'invalid offer' on the offer page, where we might actually be
            // logged in.  But we shouldn't be fetching new offers and things there.
            return {user, userIsAuthenticated, userDetails: {}, accessToken: null, accountStatus: null};
        }
    });

    const hasConditionalOfferApproval$ = offer$.map(getHasConditionalOfferApproval);

    const selectedPackage$ = offer$
        .map('.packages')
        .map((packages = []) => packages.find(
            matchesProperty('packageId', selectedPackageId)
        ));

    const selectedPackagePrice$ = selectedPackage$
        .map('.prices')
        .map((prices = []) => prices.find(
            matchesProperty('priceId', selectedPackagePriceId)
        ));

    const validatedPackageSelection$ = selectedPackagePrice$
        .map((foundPackagePrice) => {
            if (validatePackagePriceIdSelection) {
                return !!foundPackagePrice;
            } else {
                return true;
            }
        });

    const telstraJourney$ = offer$
        .map('.offer.journeys')
        .map((journeys) => includes(journeys, 'TELSTRA'));

    const isTelstraJourneyButInvalid$ = telstraJourney$
        .map((isTelstraJourney) => {
            return isTelstraJourney && !window.sessionStorage.getItem('signupTelstraOrderItemNumber');
        });

    const invalidLandingErrorMessage$ = bacon.combineTemplate({
        isTelstraJourneyButInvalid: isTelstraJourneyButInvalid$,

        prefetchedError: prefetchedOffer$.map('.error'),
        offerError: offer$.map('.error'),
        user: user$,
        hasConditionalOfferApproval: hasConditionalOfferApproval$,

        validatedPackageSelection: validatedPackageSelection$,
    })
        .take(1) // Only ever take the first loaded offer/prefetched offer.  Otherwise we'll show later on for things like voucher changes.
        .map(({isTelstraJourneyButInvalid, offerError, validatedPackageSelection, user, hasConditionalOfferApproval}) => { /* prefetchedError, */
            if (isTelstraJourneyButInvalid) {
                return (
                    <React.Fragment>
                        This offer is only available to eligible Telstra customers with an active Telstra account. <a href="https://hub.telstra.com.au/" rel="noopener noreferrer">Sign in to your Telstra account</a> to claim your offer.
                    </React.Fragment>
                );
            }

            const errorDetail = offerError?.detail; // || get(prefetchedError, 'detail'); // We don't really 'care' about the prefetched error?  As 'returning only' on an offer, when we only know at offer$ applies later?

            // Make sure we don't have a more specific errorDetail about an invalid offer, before suggesting
            // the URL params are invalid.  Remember that invalidOffers return 'nextBestOffer' in the payload.
            if (!errorDetail && !validatedPackageSelection) {
                return 'The page you have landed on contains an invalid selected package.';
            }

            if (hasConditionalOfferApproval) {
                return (
                    <React.Fragment>
                        {errorDetail}
                        <br />
                        <br />
                        <BrandedBA01BtnPr brand={brand} onClick={() => void user.login({redirectAfterProcessing: window.location.href})}>
                            Sign In
                        </BrandedBA01BtnPr>
                    </React.Fragment>
                );
            }

            return errorDetail || false;
        }); // Can only do merge on event streams.

    const voucherBus = new bacon.Bus();
    const voucher$ = voucherBus.toProperty(undefined);

    /*
        This stream is all about the package selector for showing users the next best offer.
        Nothing is ever fetched on this stream if we aren't an invalid landing page.
        Additionally the logic here is 'if you're telstra, or a user is trying to use a voucher', refresh the offer to display.
        This is because the API by default, already HAS the nextBestOffer in the response with an error.
        For that reason we reuse the client-side offer$ data, and omit the error object off it.
    */
    const nextBestOffer$ = bacon.combineTemplate({
        isTelstraJourneyButInvalid: isTelstraJourneyButInvalid$,
        errorMessage: invalidLandingErrorMessage$,
        userRelatedDetails: userRelatedDetails$,
        offer: offer$,
        voucher: voucher$,
    })
        .filter(invalidLandingErrorMessage$) // Hold this if we don't have in invalid landing.
        .skipDuplicates(isEqual)
        .flatMapLatest(({isTelstraJourneyButInvalid, userRelatedDetails, offer, voucher}) => {
            const {user, userIsAuthenticated} = userRelatedDetails;
            const nextBestOffer = offer?.offer?.nextBestOffer;

            if (isTelstraJourneyButInvalid || voucher) {
                const accessToken$ = userIsAuthenticated ? bacon.fromPromise(user.getAccessToken()) : bacon.later(0, undefined);

                return accessToken$ // always ensure a fresh access token before hitting offers again.
                    .flatMapLatest((accessToken) => {
                        return getOfferStream({
                            accessToken,
                            brand,
                            offer: (isTelstraJourneyButInvalid && nextBestOffer) || '', // Only use the next best offer field if we're telstra.
                            platformEnv,
                            voucher,
                        });
                    });
            } else {
                // The default 'nextBestOffer' WILL contain an error, but we don't want to show this one to the user.
                return omit(offer, ['error']);
            }
        });

    const voucherDisplay$ = getVoucherDisplayPropsStream({
        clientSideVoucher$: voucher$,
        userEnteredVoucherBus: voucherBus,
        offer$: nextBestOffer$,
        prefetchedOffer$: nextBestOffer$.take(1), // this is used to define 'voucher required template'. Will be the first nextBestOffer returned to us.
        hydration: {}, // we'll never have hydration here.
    });

    const packageSelector$ = getPackageSelectorPropsStream({
        offer$: nextBestOffer$,
        offerSettings$: voucher$.changes(),
        prefetchedOffer$: nextBestOffer$.take(1), // this is used to define 'voucher required template'. Will be the first nextBestOffer returned to us.
    });

    const selectedPackagePriceId$ = packageSelector$.map('.selectedPackagePriceId');
    const hasContinueButton$ = selectedPackagePriceId$.map(Boolean).startWith(false);

    const onContinueFunction$ = bacon.combineTemplate({
        selectedOffer: nextBestOffer$,
        selectedPackagePriceId: selectedPackagePriceId$,
    })
        .filter(({selectedPackagePriceId}) => selectedPackagePriceId) // Skip updates here until we have selected package price ids.
        .map(({selectedOffer, selectedPackagePriceId}) => {
            const offerName = selectedOffer?.offer?.code;
            const voucher = selectedOffer?.voucher?.code;
            const packages = selectedOffer?.packages || [];

            const selectedPackage = packages.find((thisPackage) => {
                const thisPackagePrices = thisPackage?.prices || [];

                return thisPackagePrices.some((price) => price.priceId === selectedPackagePriceId);
            });

            const selectedPackageId = selectedPackage.packageId;

            return () => {
                const checkoutUrl = new URL(window.location.href);

                checkoutUrl.searchParams.set('voucher', voucher || ''); // make sure we don't set voucher in the url as undefined or null (yay to string)
                checkoutUrl.searchParams.set('offer-name', offerName);
                checkoutUrl.searchParams.set('selected-package-id', selectedPackageId);
                checkoutUrl.searchParams.set('selected-package-price-id', selectedPackagePriceId);

                // Ensure we do not duplicate params on reload
                checkoutUrl.searchParams.delete('offerName');
                checkoutUrl.searchParams.delete('selectedPackageId');
                checkoutUrl.searchParams.delete('selectedPackagePriceId');

                // Ideally - if users are bouncing all over the place, we don't want them to hit
                // back and come back to an invalid checkout page and do this all again.
                // So replace the current page url with our new URL and keep it out of back history.
                window.location.replace(checkoutUrl);
            };
        })
        .startWith(noop);

    const reloadPageWithNextBestOffer = (nextBestOffer) => {
        const offerName = nextBestOffer?.offer?.code || '';
        const reloadUrl = new URL(window.location.href);

        reloadUrl.searchParams.set('voucher', ''); // make sure we reset voucher. Next best offers are voucher-less.
        reloadUrl.searchParams.set('offer-name', offerName);

        // Ensure we do not duplicate params on reload
        reloadUrl.searchParams.delete('offerName');

        forgetVoucherFunction();
        forgetOfferNameFunction();

        // wait a tick to make sure local storage updates take effect before navigating
        setTimeout(() => {
            window.location.replace(reloadUrl);
        });
    };

    const dismissModalFunc$ = bacon.combineTemplate({
        isTelstraJourneyButInvalid: isTelstraJourneyButInvalid$,
        nextBestOffer: nextBestOffer$,
        hasConditionalOfferApproval: hasConditionalOfferApproval$,
    })
        .map(({isTelstraJourneyButInvalid, nextBestOffer, hasConditionalOfferApproval}) => {
            // If we're an invalid telstra journey, and being asked to reload this page
            // with the next offer name, lets do that!
            if (isTelstraJourneyButInvalid && shouldReloadedPageToNextBestOfferIfTelstra) {
                return () => reloadPageWithNextBestOffer(nextBestOffer);
            }

            return shouldSelectNextBestOffer || hasConditionalOfferApproval ? noop : () => reloadPageWithNextBestOffer(nextBestOffer);
        });

    const shouldNotShowModal$ = bacon.combineTemplate({
        isFreemiumOffer: isFreemiumOffer$,
        isPpvOffer: isPpvOffer$,
        userIsAuthenticated: userIsAuthenticated$,
        accountStatus: userRelatedDetails$.map('.accountStatus'),
    })
        .flatMapLatest(({
            isFreemiumOffer,
            isPpvOffer,
            userIsAuthenticated,
            accountStatus,
        }) => (
            // an active account, or
            ACCOUNT_STATUS_ACTIVE_SUBSCRIPTION_STATUSES.includes(accountStatus)
                    // Freemium/PPV offers redirect to register page
                    || (!userIsAuthenticated && (isFreemiumOffer || isPpvOffer))
        ));

    const modalProps$ = bacon.combineTemplate({
        shouldNotShowModal: shouldNotShowModal$,
        dismissModalFunc: dismissModalFunc$,
        hasConditionalOfferApproval: hasConditionalOfferApproval$,

        errorMessage: invalidLandingErrorMessage$,
        userRelatedDetails: userRelatedDetails$,
        packageSelector: packageSelector$.map('.props'),
        voucherDisplay: voucherDisplay$.map('.props'),

        hasContinueButton: hasContinueButton$,
        onContinueFunction: onContinueFunction$,

        brand,
    })
        .filter(invalidLandingErrorMessage$);

    const modal$ = modalProps$
        .map(({
            shouldNotShowModal,
            dismissModalFunc,
            hasConditionalOfferApproval,

            errorMessage,
            userRelatedDetails,
            packageSelector,
            voucherDisplay,

            hasContinueButton,
            onContinueFunction,

            brand,
        }) => {
            // @TODO MWL-2408 do this better
            // :point_up: i did it a bit better, does this need to be better-er?
            if (shouldNotShowModal) {
                return null;
            }

            const {userDetails, accountStatus} = userRelatedDetails;
            const userFirstName = userDetails.firstName || '';
            const returning = ACCOUNT_STATUS_RETURNING.includes(accountStatus);
            const welcomeMessage = userFirstName
                ? `Welcome${returning ? ' back' : ' '} ${userFirstName}!`
                : `Welcome to ${BRAND_DISPLAY_NAME[brand]}`;

            return (
                <ComponentInvalidOfferModal
                    dismissModalFunc={dismissModalFunc}
                    shouldDismissModalWithClickOutside={hasConditionalOfferApproval ? false : !shouldSelectNextBestOffer}

                    userGreeting={welcomeMessage}
                    errorMessage={errorMessage}

                    shouldSelectNextBestOffer={shouldSelectNextBestOffer}
                    packageSelector={packageSelector}
                    voucherDisplay={voucherDisplay}

                    // Continue Button needs a Next Best Offer to select from
                    hasContinueButton={hasContinueButton && shouldSelectNextBestOffer}
                    onContinueFunction={onContinueFunction}

                    brand={brand}
                />
            );
        });

    const reactElement$ = bacon.mergeAll(
        modal$,
    ).startWith(null);

    return reactElement$;
}
