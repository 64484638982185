import {isServer} from '@fsa-streamotion/browser-utils';
import bacon from 'baconjs';

import User from './user';
import TokenServiceV2User from './token-service-v2-user';

import createSsrOverrides from './utils/create-ssr-overrides';
import createE2eOverridesIfEnabled from './utils/create-e2e-overrides-if-enabled';
import getAuth0Details from './utils/get-auth0-details';
import getNormalisedBrand from './utils/get-normalised-brand';

let userInstance;

/**
 * @typedef {Object} UserOptions        - (See Below)
 * @property {Function} getActiveProfileIdFromLocalstorage - Function to retrieve value from profile stream
 * @property {string} offerUrl          - URL to the Offer Page if user not active
 * @property {string} profileUrl        - URL to send users to for profile selection if requestLogin wants a profile
 * @property {string} platform          - e.g. "ares" | "kayo" | "flash" | "magneto"
 * @property {string} [brand]           - Normalised brand e.g. "binge" | "flash" | "kayo"
 * @property {string} platformEnv       - e.g. production or staging
 * @property {boolean} isV2TokenService - toggle to use v2 token service and user instance
 */

/**
 * Returns a user instance with helper wrappers around the Auth0 implementation
 *
 * @param {UserOptions} options -
 * @returns {Promise<User>} -
 */
export async function getUser(options) {
    if (!userInstance) {
        const normalisedBrand = getNormalisedBrand(options.brand || options.platform);

        const auth0Details = await getAuth0Details({
            brand: normalisedBrand,
            platformEnv: options.platformEnv,
        });

        // eslint-disable-next-line require-atomic-updates
        userInstance = options?.isV2TokenService
            ? new TokenServiceV2User({
                ...options,
                auth0Details,
                normalisedBrand,
            })
            : new User({
                ...options,
                auth0Details,
                normalisedBrand,
            });

        //  Overrides instance methods for SSR
        if (isServer()) {
            Object.assign(userInstance, createSsrOverrides());
        } else {
            //  If we can detect E2E override tokens added to local storage
            //  we can assume that an E2E test is running, so in that case we
            //  can override specific methods to return these tokens
            const [isE2eEnabled, createE2eOverrideMethods] = createE2eOverridesIfEnabled({
                clientId: auth0Details.clientId,
                platformEnv: options.platformEnv,
            });

            if (
                isE2eEnabled
                && createE2eOverrideMethods
                && typeof createE2eOverrideMethods === 'function'
            ) {
                Object.assign(userInstance, createE2eOverrideMethods());
            }

            //  Instantiates the Auth0 client along with
            //  authentication and token checks
            await userInstance.setup();

            //  Adds the userInstance to window for debugging
            window.user = userInstance;
        }
    }

    return userInstance;
}

/**
 * Get a bacon stream containing a user instance with helper wrappers around the Auth0 implementation
 *
 * @param {UserOptions} options       -
 * @returns {bacon.Observable.<User>} -
 */
export default function getUserStream(options) {
    return bacon.fromPromise(getUser(options));
}

/**
 * Returns a "Binge" specific user instance
 *
 * @param {Omit<UserOptions, 'platform'>} options -
 * @returns {bacon.Observable.<User>} -
 */
export function getBingeUserStream(options) {
    return getUserStream({...options, platform: 'ares'});
}

/**
 * Returns a "Life" specific user instance
 *
 * @param {Omit<UserOptions, 'platform'>} options -
 * @returns {bacon.Observable.<User>} -
 */
export function getLifestyleUserStream(options) {
    return getUserStream({...options, platform: 'lifestyle'});
}

/**
 * Returns a "Flash" specific user instance
 *
 * @param {Omit<UserOptions, 'platform'>} options -
 * @returns {bacon.Observable.<User>} -
 */
export function getFlashUserStream(options) {
    return getUserStream({...options, platform: 'flash'});
}

/**
 * Returns a "Kayo" specific user instance
 *
 * @param {Omit<UserOptions, 'platform'>} options -
 * @returns {bacon.Observable.<User>} -
 */
export function getKayoUserStream(options) {
    return getUserStream({...options, platform: 'kayo'});
}

/**
 * Returns a "Magneto" specific user instance
 *
 * @param {Omit<UserOptions, 'platform'>} options -
 * @returns {bacon.Observable.<User>} -
 */
export function getMagnetoUserStream(options) {
    return getUserStream({...options, platform: 'magneto'});
}

/**
 * Used for testing purposes only
 */
export function destroyInstance() {
    userInstance = null;
}
