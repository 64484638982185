import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import propTypes from 'prop-types';

import {classNameType, srcsetOptionsType} from '@fsa-streamotion/custom-prop-types';

import GA09PlrPic from '../../../atoms/ga/09-plr-pic';
import {silverLining} from '../../../../common/palette';

const Container = styled.figure`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    margin: 0;
`;

// Needed so a super long title doesn't condense the picture
const PictureContainer = styled.div`
    width: 35px;
`;

const Title = styled.h3`
    margin: 7px 0 7px 7px;
    color: ${silverLining};
    font: var(--mui-hud-tile-header-bold);
`;

const IGM06IcHead = ({
    pictureSrcsetOptions,
    title,
    className,
}) => (
    <Container className={classnames('IGM06IcHead', className)}>
        <PictureContainer>
            {/* We specify an alt tag of "" here because the icon is purely decorated, and is better described by Title */}
            <GA09PlrPic srcsetOptions={pictureSrcsetOptions} size="35px" alt="" />
        </PictureContainer>
        <figcaption>
            <Title>{title}</Title>
        </figcaption>
    </Container>
);

IGM06IcHead.displayName = 'IGM06IcHead';

IGM06IcHead.propTypes = {
    /** Additional class(es) */
    className: classNameType,
    /** Srcset options for the team logo */
    pictureSrcsetOptions: srcsetOptionsType,
    /** Title for this header, e.g. "Batting - Ireland" */
    title: propTypes.string,
};

export default IGM06IcHead;
