import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import {Button} from 'normalized-styled-components';
import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {transition} from '../../../../../common/animations';
import {blanc, midnight} from '../../../../../common/palette';
import {SCREEN_1920_DESKTOP, SCREEN_2560_DESKTOP} from '../../../../../common/screen-sizes';

import IC26ArrowU from '../../../ic/26-arrow-u';
import IC27ArrowD from '../../../ic/27-arrow-d';

export const CLOSE_BUTTON_MAX_HEIGHT_PX = 54;

const CloseButton = styled(Button)`
    appearance: none;
    transition: ${transition('background-color', 'color')};
    outline: 0;
    border: 0;
    background-color: ${rgba(blanc, 0.2)};
    padding: 0 16px 0 34px;
    width: 73px;
    height: 28px;
    color: ${rgba(blanc, 0.6)};
    clip-path: polygon(${({isUpperTray}) => isUpperTray ? '0 0, 100% 0, 100% 100%, 30% 100%' : '30% 0, 100% 0, 100% 100%, 0 100%'});

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 92px;
        height: 36px;
        padding: 0 20px 0 42px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
        width: 138px;
        height: ${CLOSE_BUTTON_MAX_HEIGHT_PX}px;
        padding: 0 30px 0 64px;
    `}

    &:focus,
    &:hover {
        background-color: ${blanc};
        color: ${midnight};
    }
`;

const VPBA04CloseTray = ({
    isUpperTray,
    'aria-label': ariaLabel = 'Close Tray',
    ...htmlAttributes
}) => {
    const ArrowEle = isUpperTray ? IC26ArrowU : IC27ArrowD;

    return (
        <CloseButton {...htmlAttributes} isUpperTray={isUpperTray} aria-label={ariaLabel} title={ariaLabel}>
            <ArrowEle />
        </CloseButton>
    );
};

/* eslint-disable quote-props */
VPBA04CloseTray.propTypes = {
    /** Assitive label */
    'aria-label': propTypes.string,
    /** Is the button for the upper tray or lower tray */
    isUpperTray: propTypes.bool,
};
/* eslint-enable quote-props */

VPBA04CloseTray.displayName = 'VPBA04CloseTray';

export default VPBA04CloseTray;
