import React from 'react';
import Ic from '..';

const IC261ArrowDTail = (args) => (
    <Ic {...args}>
        <path d="m63.135 50.747-9.516 8.934V24.987h-8.633v34.694l-9.53-8.934-6.091 5.71 19.944 18.689L69.24 56.458z" />
    </Ic>
);

IC261ArrowDTail.displayName = 'IC261ArrowDTail';

export default IC261ArrowDTail;
