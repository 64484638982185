import muid from './muid';

/**
 * This should only be used as a last resort for generating react keys and only in cases where using index as key is dangerous
 *
 * Use to generate a unique key based on an object, e.g. which might be a react object
 *
 * 1) Tries to convert object to JSON string then base64 encode
 * 2) If object won't JSON.stringify, will try to base64 the object directly
 * 3) If object wont JSON.stringify or base64 encode, give up and provide a muid. NOTE: this will cause react to perform badly / remount objects unneccesarily
 *
 * @param  {Object} obj object to generate key for
 * @returns {string}    string that could be used as a unique key
 */
export default function keyFromObj(obj) {
    try {
        return btoa(JSON.stringify(obj));
    } catch (e) {} // eslint-disable-line no-empty

    try {
        return btoa(obj);
    } catch (e) {
        console.warn('Couldn\'t JSON.stringify or base64 encode object. Using muid');

        return muid();
    }
}
