import React from 'react';

import Ic from '..';

const IC250Watchlist = (args) => (
    <Ic {...args}>
        <path d="M81.225 47.458H51.992V18.225h-4.534v29.233H18.225v4.534h29.233v29.233h4.534V51.992h29.233z" />
    </Ic>
);

IC250Watchlist.displayName = 'IC250Watchlist';

export default IC250Watchlist;
