import {AUDIENCE, CLIENT_ID, DOMAIN, REALM, ENVIRONMENT, BRAND} from '../constants';

import getScopeFromEme from './get-scope-from-eme';

/**
 * @typedef {Object} Auth0Details   - (See below)
 * @property {string} audience      - https://auth0.github.io/auth0-spa-js/interfaces/auth0clientoptions.html#audience
 * @property {string} clientId      - https://auth0.github.io/auth0-spa-js/interfaces/auth0clientoptions.html#client_id
 * @property {string} domain        - https://auth0.github.io/auth0-spa-js/interfaces/auth0clientoptions.html#domain
 * @property {string} realm         - https://auth0.github.io/auth0-spa-js/interfaces/auth0clientoptions.html#connection
 * @property {string} scope         - https://auth0.github.io/auth0-spa-js/interfaces/auth0clientoptions.html#scope
 */

/**
 * @description Returns partial configuration for Auth0 client.
 * @param {Object} options                          - (See below)
 * @param {string} options.brand                    - Brand string e.g. binge, flash, kayo
 * @param {string } options.platformEnv             - Platform environment string e.g. staging, production
 * @returns {Promise<Auth0Details | undefined>}     - Auth0 details
 */
export default async function getAuth0Details({brand, platformEnv}) {
    if (!brand || !Object.values(BRAND).includes(brand)) {
        console.error('GetAuth0Details: Empty or invalid brand name supplied to get auth0 details');

        return;
    }

    if (!platformEnv || !Object.values(ENVIRONMENT).includes(platformEnv)) {
        console.error('GetAuth0Details: Empty or invalid platformEnv supplied to get auth0 details');

        return;
    }

    const scope = brand === BRAND.kayo
        ? 'openid email' // no DRM on kayo
        : `openid email ${await getScopeFromEme({brand})}`;

    return {
        clientId: CLIENT_ID[platformEnv][brand],
        domain: DOMAIN[platformEnv],
        realm: REALM[platformEnv],
        audience: AUDIENCE[platformEnv],
        scope,
    };
}
