import bacon from 'baconjs';
import noop from 'lodash/noop';

import getLandingAemConfigStream from '../../../streams/endpoints/aem/landing/landing';

import getBackupAemConfig from '../backup-aem-config';

export default function getLandingAemConfigStreamWithFallback({
    brand,
    platformEnv,
    marketingConfigName,
    defaultMarketingConfigName,
    // used primarily to disable retries while testing
    retries,

    onMarketingConfigNameUsed = noop,
    onDefaultMarketingConfigUsed = noop,
}) {
    return getLandingAemConfigStream({platformEnv, marketingConfigName, retries, brand})
        .doAction(() => void onMarketingConfigNameUsed())
        .flatMapError((error) => {
            // If we are here and marketingConfigName is anything other than the default one then it only means we are here
            // because the specific marketingConfigName is not valid so proceed with the default one
            // eslint-disable-next-line no-negated-condition
            if (marketingConfigName !== defaultMarketingConfigName) {
                // This is us no longer seeing a specific marketing name we were looking for.
                // If someone asks us to do something specific when this happens (like,
                // forgetting this from local storage), do that.
                onDefaultMarketingConfigUsed();

                return getLandingAemConfigStream({
                    brand,
                    platformEnv,
                    marketingConfigName: defaultMarketingConfigName,
                    retries,
                });
            } else {
                // otherwise let the original error proceed.
                return new bacon.Error(error);
            }
        })
        .flatMapError((error) => {
            // Is this aem config 404? Fall back to a safe default.
            // Otherwise let the error propagate and FISO will 400 us.
            if (error.type === 'syntax-error' || error.statusCode === 404) {
                console.error('Accounts Widgets: Aem config error!', error);

                return getBackupAemConfig({brand});
            } else {
                return new bacon.Error(error.message); // only send back a string for FISO error.
            }
        });
}
