import {BRAND_DISPLAY_NAME} from '../../../todo-move-to-widgets-common/utils/constants';
import {MY_ACCOUNT_LINK} from './constants';

export default function getPreviewVoucherMeta({isApp, banner, voucher, brand, platformEnv} = {}) {
    return {
        title: `Preview Voucher | My account | ${BRAND_DISPLAY_NAME[brand]}`,
        label: 'Preview Voucher',
        location: `${MY_ACCOUNT_LINK[platformEnv]}${addParamsToRelativeUrl(
            `/voucher/${voucher}/preview`,
            {
                'is-app': (isApp === true) || undefined,
                banner,
            }
        )}`,

        isApp,
        loginRedirectMessage: 'Please sign in to redeem your Voucher',
    };
}

/**
 * Copied over from Ares Widgets
 * Insted of doing all this, eventually move to passing in baseUrl to generate URLs or amend addParamsToUrl to be less fussy
 *
 * @param {string} url relative URL to append query params onto
 * @param {Object} params Key value pairs to be stringified. `Undefined` or `null` values will be dropped
 *
 * @returns {string} relative url with query params attached
 */
function addParamsToRelativeUrl(url, params = {}) {
    const paramString = new URLSearchParams(
        Object.entries(params)
            .filter(([, value]) => value !== undefined && value !== null)
    )
        .toString();

    if (!paramString) {
        return url;
    }

    return url.concat('?', paramString);
}
