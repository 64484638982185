import React from 'react';
import Ic from '..';

const IC323RemoveC = (args) => (
    <Ic {...args}>
        <path fillRule="evenodd" d="M54.32 45.935H15.191a4.192 4.192 0 1 0 0 8.384h69.871a4.192 4.192 0 0 0 0-8.384H54.32z" />
    </Ic>
);

IC323RemoveC.displayName = 'IC323RemoveC';

export default IC323RemoveC;
