import React, {cloneElement} from 'react';
import styled, {css} from 'styled-components';
import propTypes from 'prop-types';
import classnames from 'classnames';
import {A, Button} from 'normalized-styled-components';

import {mediaQuery, stylesIfElse} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import EMPTY_IMAGE_SRC from '../../../../common/empty-image-src';
import GA104TMFooter from '../../../atoms/ga/104-tm-footer';
import GA01ProBar from '../../../atoms/ga/01-pro-bar';
import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import {transition} from '../../../../common/animations';

const baseCss = css`
    display: block;
    position: relative;
    width: 100%;
`;

const interactiveCss = css`
    &:hover,
    &:focus {
        transform: scale(1.1);
        transition: ${transition('transform')};
        outline: none;
    }
`;

const NonInteractive = styled.div`
    ${baseCss}
`;

const StyledButton = styled(Button)`
    ${baseCss}
    ${interactiveCss}

    appearance: none;
    border: 0;
    background: transparent;
    padding: 0;
`;

const StyledLink = styled(A)`
    ${baseCss}
    ${interactiveCss}

    text-decoration: inherit;
    color: inherit;
`;

const StyledGA01ProBar = styled(GA01ProBar)`
    position: absolute;

    ${stylesIfElse('hasFooter')`
        bottom: 26px;
        ``
        bottom: 0;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        height: 6px;

        ${stylesIfElse('hasFooter')`
            bottom: 42px;
        ``
            bottom: 0;
        `}
    `}
`;

const TM14ShowTil = React.forwardRef(({
    card,
    className,
    currentProgressPerc,
    footerText,
    tooltipRemoveCopy,
    tooltipRemoveCopyA11y,
    onClickRemoveFunc,
    href,
    isBeingLazy = false,
    label,
    lazySrc = EMPTY_IMAGE_SRC,
    onClick,
    ...htmlAttributes
}, ref) => {
    const Cta = getCtaType(href, onClick);

    return (
        <Cta
            {...htmlAttributes}
            ref={ref}
            aria-label={label}
            className={classnames('TM14ShowTil', className)}
            href={href}
            onClick={onClick}
        >
            {cloneElement(
                card,
                {isBeingLazy, lazySrc, srcsetOptionsLazyLoadPlaceholder: {1: lazySrc}}
            )}
            {!!footerText && (
                <GA104TMFooter
                    tooltipRemoveCopy={tooltipRemoveCopy}
                    tooltipRemoveCopyA11y={tooltipRemoveCopyA11y}
                    onClickRemoveFunc={onClickRemoveFunc}
                >
                    {footerText}
                </GA104TMFooter>
            )}
            {!!currentProgressPerc && (
                <StyledGA01ProBar hasFooter={!!footerText} current={currentProgressPerc} />
            )}
        </Cta>
    );
});

function getCtaType(href, onClick) {
    if (href) {
        return StyledLink;
    } else if (onClick) {
        return StyledButton;
    } else {
        return NonInteractive;
    }
}

TM14ShowTil.propTypes = {
    /** Tile image which will take in isBeingLazy and/or lazySrc props */
    card: propTypes.node,
    /** Additional className for the component */
    className: classNameType,
    /** A number which shows the current viewer's progress */
    currentProgressPerc: propTypes.number,
    /** Text shown on the tile describing the video, e.g. title of the asset */
    footerText: propTypes.string,
    /** Copy for the Remove from Continue Watching button */
    tooltipRemoveCopy: propTypes.string,
    /** Accessible copy for the Remove from Continue Watching button */
    tooltipRemoveCopyA11y: propTypes.string,
    /** Function to run when removing from Continue Watching */
    onClickRemoveFunc: propTypes.func,
    /** If the component is intended as an anchor tag, this is the link to redirect the user to */
    href: propTypes.string,
    /** Is the inner component supposed to be lazy loaded? */
    isBeingLazy: propTypes.bool,
    /** A string to label the component via aria-label */
    label: propTypes.string,
    /** A URL or path to an image to be lazy loaded */
    lazySrc: propTypes.string,
    /** If the component does not have a href property, use this event handler */
    onClick: propTypes.func,
};

TM14ShowTil.displayName = 'TM14ShowTil';

export default TM14ShowTil;
