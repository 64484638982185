import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {mediaQuery, stylesIfElse} from '@fsa-streamotion/styled-component-helpers';

import {SCREEN_1024_DESKTOP, SCREEN_1920_DESKTOP} from '../../../../../common/screen-sizes';
import {ListItem} from './common';

const InfoLinesContainer = styled.ul`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
    height: 1em;
    overflow: hidden;
    text-transform: uppercase;
    font: var(--infinity-poster-attribute-tags);

    ${stylesIfElse('hasSubtitle')` /*** use halved padding values ***/
        padding: 4px 0 0;

        ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
            padding-top: 7px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            padding-top: 11px;
        `}
    ``/*** use original padding values ***/
        padding: 7px 0 0;

        ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
            padding-top: 14px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            padding-top: 21px;
        `}
    `}
`;

const InfoLineItems = ({children, hasSubtitle}) => (
    <InfoLinesContainer hasSubtitle={hasSubtitle}>
        {React.Children.map(children, (item) => (
            <ListItem
                separator="|"
                separatorOpacity={0.2}
            >{item}
            </ListItem>
        ))}
    </InfoLinesContainer>
);

InfoLineItems.propTypes = {
    children: propTypes.arrayOf(propTypes.node),
    /** if a subtitle is present, let's use smaller padding (ideally halved of default) */
    hasSubtitle: propTypes.bool,
};

InfoLineItems.displayName = 'InfoLineItems';

export default InfoLineItems;
