import React from 'react';

import Ic from '..';

const IC62Chrome = (args) => (
    <Ic {...args}>
        <path d="M63.1 44.5c-2.4-2.4-5.3-4-8.5-4.8V25c0-.4-.1-.7-.4-.9-.3-.2-.6-.4-.9-.4h-6.5c-.7 0-1.3.6-1.3 1.3l.1 14.6c-8.1 2-14.1 9.4-14.1 18.1 0 5 2 9.6 5.5 13.1s8.2 5.4 13.1 5.4h.1c5 0 9.6-2 13.1-5.5s5.4-8.2 5.4-13.2c-.1-4.9-2.1-9.5-5.6-13zM48 26.4h3.8l.2 12.8c-.6-.1-1.3-.1-2-.1h-.1c-.6 0-1.2 0-1.8.1L48 26.4zm1.9 15.4c.1 0 .1 0 0 0 4.3 0 8.3 1.6 11.3 4.6.9.8 1.6 1.8 2.2 2.8l-26 18.2c-2.1-2.7-3.3-6-3.3-9.6-.1-8.8 7-16 15.8-16zm11.4 27.1c-3 3-7 4.7-11.3 4.7h-.1c-4.6 0-8.8-2-11.7-5.2l25.9-18.1c1.2 2.2 1.8 4.7 1.8 7.3.1 4.3-1.6 8.3-4.6 11.3z" />
        <path d="M50.1 67v1.6h2.2c-.1.5-.4.9-.8 1.2-.4.2-.8.4-1.4.4-1 0-1.9-.7-2.2-1.7-.1-.2-.1-.5-.1-.8 0-.3 0-.5.1-.8.3-.9 1.2-1.7 2.2-1.7.6 0 1.1.2 1.5.6l1.1-1.1c-.7-.6-1.6-1-2.7-1-1.6 0-2.9.9-3.6 2.2-.3.5-.4 1.2-.4 1.8 0 .6.2 1.3.4 1.8.7 1.3 2 2.2 3.6 2.2 1.1 0 2-.4 2.7-1 .8-.7 1.2-1.7 1.2-2.9 0-.3 0-.6-.1-.8h-3.7z" />
    </Ic>
);

IC62Chrome.displayName = 'IC62Chrome';

export default IC62Chrome;
