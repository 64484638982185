import React from 'react';
import Ic from '..';

const IC282KeySegSkipB = (args) => (
    <Ic {...args}>
        <path fillOpacity=".35" d="M49.036 71.294c-.288-3.23.092-5.58 1.14-7.05 6.94-9.747 15.2-15.786 16.814-17.784-.507-2.364.073-5.3 1.74-8.809 2.81-5.914 9.884-8.43 15.798-5.62 1.348.641 2.564 1.531 3.582 2.623 4.366 4.681 4.11 12.015-.572 16.38a11.59 11.59 0 0 1-4.938 2.728 49.472 49.472 0 0 1-7.112 1.312c-1.791.291-7.973 5.698-18.546 16.22h-7.906zm30.401-31.442a1.917 1.917 0 0 0-.421 2.098c.3.72 1.005 1.19 1.788 1.19.783 0 1.489-.47 1.788-1.19.3-.72.133-1.548-.421-2.098a1.942 1.942 0 0 0-2.734 0z" />
        <g fillRule="nonzero">
            <path d="M89.045 32.41a14.123 14.123 0 0 1 4.601 10.124 14.116 14.116 0 0 1-4.163 10.31 14.315 14.315 0 0 1-12.948 3.86l-6.75 6.715-3.927-.067v3.01c.016.482-.139.951-.434 1.329l-.117.137a2.02 2.02 0 0 1-1.429.593h-3.026v2.988a2.019 2.019 0 0 1-1.924 2.014l-11.07.537-.607-.537a1.807 1.807 0 0 1-.518-1.365l.315-7.486a2.02 2.02 0 0 1 .585-1.343L65.42 45.548c-1.196-6.014 1.636-12.11 7.016-15.102a14.29 14.29 0 0 1 16.609 1.964zm-9.501-.851h-.34c-2.377.039-4.77.82-7.022 2.522a11.208 11.208 0 0 0-3.635 5.299 11.155 11.155 0 0 0-.121 6.414l.172.654L49.91 65.015l-.257 6.002 8.324-.408v-3.53l.008-.156a1.526 1.526 0 0 1 1.517-1.36h3.481v-3.532l.007-.135c.033-.315.177-.62.457-.946.295-.298.7-.462 1.12-.455l3.99.082 7.088-7.049.663.189.397.103c1.857.448 3.765.417 5.558-.05a11.385 11.385 0 0 0 5.165-2.944 11.291 11.291 0 0 0 3.33-8.23 11.296 11.296 0 0 0-3.659-8.092 11.524 11.524 0 0 0-7.216-2.936l-.34-.01z" />
            <path d="M84.556 44.91a5.33 5.33 0 0 1-5.788 1.149 5.282 5.282 0 0 1-3.28-4.88 5.282 5.282 0 0 1 3.28-4.881 5.33 5.33 0 0 1 5.788 1.148 5.262 5.262 0 0 1 0 7.464zm-5.119-5.058a1.917 1.917 0 0 0-.421 2.098c.3.72 1.005 1.19 1.788 1.19.783 0 1.489-.47 1.788-1.19.3-.72.133-1.548-.421-2.098a1.942 1.942 0 0 0-2.734 0z" />
        </g>
        <path fillRule="nonzero" d="m7 52 16.298-15.803a1.773 1.773 0 0 1 2.468 0 1.667 1.667 0 0 1 0 2.393L11.936 52l13.83 13.41a1.667 1.667 0 0 1 0 2.393 1.773 1.773 0 0 1-2.468 0L7 52z" />
    </Ic>
);

IC282KeySegSkipB.displayName = 'IC282KeySegSkipB';

export default IC282KeySegSkipB;
