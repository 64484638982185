import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import noop from 'lodash/noop';
import {rgba} from 'polished';
import {Section} from 'normalized-styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {akela, panther, vader} from '../../../../../common/palette';
import {SCREEN_1280_DESKTOP, SCREEN_1680_DESKTOP, SCREEN_1400_DESKTOP} from '../../../../../common/screen-sizes';

import BA01BtnPr from '../../../../atoms/ba/01-btn-pr';
import NM02DockNav from '../../../../molecules/nm/02-dock-nav';
import NM06TrigBtn from '../../../../molecules/nm/06-trig-btn';

const StyledSection = styled(Section)`
    position: relative;

    &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        opacity: 0.5;
        background-image: linear-gradient(to bottom, transparent, ${rgba(panther, 0.5)} 99%);
        width: 100%;
        height: 14px;
        content: '';
    }
`;

const StyledNM02DockNav = styled(NM02DockNav)`
    box-sizing: border-box;
    background-color: ${vader};
    padding: 14px 0;
    object-fit: contain;

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        display: none;
    `}
`;

const toggleRowStyle = css`
    display: flex;
    justify-content: space-around;
`;

const UserPofileRow = styled.div`
    ${toggleRowStyle}
    padding: 28px 0;
`;

const SignUpCtaRow = styled.div`
    ${toggleRowStyle}
    flex-direction: column;
    align-items: center;
    padding: 48px 0;

    ${mediaQuery({minWidthPx: SCREEN_1400_DESKTOP})`
        display: none;
    `}
`;

const SignUpCtaMessage = styled.p`
    display: block;
    margin: 0 15px 15px 14px;
    text-align: center;
    color: ${akela};
    font: var(--ionic-header-4-light);
`;

const CtaButtonsWrapper = styled.span`
    display: flex;
    align-items: center;
    column-gap: 15px;
`;

const StyledBA01BtnPr = styled(BA01BtnPr)`
    padding: 7px 21px;
    min-width: 110px;
    font-size: 14px;

    ${mediaQuery({minWidthPx: SCREEN_1680_DESKTOP})`
        height: 40px;
    `}
`;

const AppDockHeader = ({
    dockNavItems,

    isAuthenticated,
    avatarUrl,
    profileName,
    onProfileClick = noop,

    ctaMessage,
    signInCtaCopy,
    signUpCtaCopy,
    signUpUrl = '/',
    onSignInClick = noop,
}) => (
    <StyledSection>
        <StyledNM02DockNav menuItems={dockNavItems} />

        {isAuthenticated
            ? (!!(profileName || avatarUrl) && (
                <UserPofileRow>
                    <NM06TrigBtn onClick={onProfileClick} imageUrl={avatarUrl}>
                        {profileName}
                    </NM06TrigBtn>
                </UserPofileRow>
            ))
            : (
                <SignUpCtaRow>
                    {!!ctaMessage && <SignUpCtaMessage>{ctaMessage}</SignUpCtaMessage>}
                    <CtaButtonsWrapper>
                        <StyledBA01BtnPr isBlock={false} href={signUpUrl}>{signUpCtaCopy}</StyledBA01BtnPr>
                        <StyledBA01BtnPr isBlock={false} onClick={onSignInClick}>{signInCtaCopy}</StyledBA01BtnPr>
                    </CtaButtonsWrapper>
                </SignUpCtaRow>
            )
        }
    </StyledSection>
);

AppDockHeader.displayName = 'AppDockHeader';

AppDockHeader.propTypes = {
    // === DOCK NAV === //
    /** `NM02DockNav` prop: menuItems */
    dockNavItems: propTypes.any,
    // === USER PROFILE === //
    /** User is authenticated and logged in */
    isAuthenticated: propTypes.bool,
    /** User profile avatar */
    avatarUrl: propTypes.string,
    /** User profile action when clicked */
    onProfileClick: propTypes.func,
    /** User profile name */
    profileName: propTypes.string,
    // === SIGNUP CTA SECTION === //
    /** Shows Signup CTA label for new users */
    ctaMessage: propTypes.string,
    /** Sign In CTA copy */
    signInCtaCopy: propTypes.string,
    /** Sign Up CTA copy */
    signUpCtaCopy: propTypes.string,
    /** URL for sign-up */
    signUpUrl: propTypes.string,
    /** Action on clicking sign-in */
    onSignInClick: propTypes.func,
};

export default AppDockHeader;
