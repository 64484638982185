import React from 'react';

import Ic from '..';

const IC115Mic = (args) => (
    <Ic {...args}>
        <path fillOpacity=".35" stroke="currentColor" strokeWidth="4" d="M49.616 7c4.308.095 8.142 1.828 10.906 4.59A15.226 15.226 0 0 1 65 22.356v29.688a15.364 15.364 0 0 1-4.518 10.853 15.364 15.364 0 0 1-10.831 4.51c-4.301-.107-8.126-1.844-10.883-4.605a15.224 15.224 0 0 1-4.465-10.752V22.362a15.364 15.364 0 0 1 4.505-10.84A15.364 15.364 0 0 1 49.616 7z" />
        <path d="M74.085 46.162a2.047 2.047 0 0 0-2.046 2.063c.147 17.866-7.322 26.799-22.408 26.799-15.087 0-22.556-8.933-22.408-26.799a2.047 2.047 0 0 0-2.047-2.063c-1.18 0-2.142.945-2.164 2.124-.376 20.564 7.807 30.846 24.55 30.846v10.564h-15.65v4.193h35.452v-4.472H51.727V79.216c16.615-.082 24.785-10.392 24.511-30.93a2.153 2.153 0 0 0-2.153-2.124z" />
    </Ic>
);

IC115Mic.displayName = 'IC115Mic';

export default IC115Mic;
