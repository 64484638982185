import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import VisuallyHidden from '../../../../common/visually-hidden';
import IA04InputSea from '../../../atoms/ia/04-input-sea';

const StyledForm = styled.form`
    margin: 0 auto;
    width: 100%;
    max-width: 800px;
    font: var(--infinity-body-copy-1);
`;

const OR06Search = React.forwardRef(({
    className,
    formAction,
    helpText,
    initialSearchText,
    searchInputName = 'q',

    onChangeSearchText,
    onSubmitForm,
}, ref) => (
    <StyledForm
        action={formAction}
        className={classnames('OR06Search', className)}
        onSubmit={onSubmitForm}
    >
        <IA04InputSea
            defaultValue={initialSearchText}
            label={helpText}
            name={searchInputName}
            onChange={onChangeSearchText}
            ref={ref}
        />
        {
            /*
                ** When there is only one single-line text input field in a form, the user agent should accept Enter in that field as a request to submit the form.
                ** Added this hidden input so it always submits the form on enter, in case there are other inputs in future
                ** https://www.w3.org/MarkUp/html-spec/html-spec_8.html#SEC8.2
                */
        }
        <VisuallyHidden as="input" type="submit" tabIndex="-1" />
    </StyledForm>
));

OR06Search.propTypes = {
    /** Additional CSS classnames to be applied */
    className: classNameType,
    /** action value for the form */
    formAction: propTypes.string,
    /** This is the placeholder text for the search input box */
    helpText: propTypes.string.isRequired,
    /** This is the initial text for the search to begin with */
    initialSearchText: propTypes.string,
    /** "name" attribute for the search input field, e.g. for use in native <form> submission */
    searchInputName: propTypes.string,
    /** This is called everytime user types in, so we can decide when to call search, supports capability for auto-search */
    onChangeSearchText: propTypes.func,
    /** This is called when form is submitted/submit button is pressed (future) */
    onSubmitForm: propTypes.func,
};

// Storybook renders the displayName for the component name if a forwardRef element provides it, else it is displayed as Unknown
OR06Search.displayName = 'OR06Search';

export default OR06Search;
