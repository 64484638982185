import bacon from 'baconjs';
import superagent from 'superagent';
import get from 'lodash/get';
import property from 'lodash/property';

import {SIGNUP_API} from '@fsa-streamotion/streamotion-web-widgets-common';

/**
 * Validate the user-entered verification code.
 *
 * @param {Object} options                      - see below
 * @param {string} options.platformEnv          - the platform environment
 * @param {string} options.accessToken          - the current user's access token
 * @param {string} options.mobileNumber         - the mobile number to which the code was sent (+61 format)
 * @param {string} options.verificationCode     - the verification code entered by the user
 * @returns {Promise} a promise that resolves to the response body on success
 */
export default function validateVerificationCode({
    platformEnv,
    accessToken,
    mobileNumber,
    verificationCode,
}) {
    const missingRequiredParams = getMissingRequiredParams({platformEnv, accessToken, mobileNumber, verificationCode});

    if (missingRequiredParams.length) {
        throw new Error(`Missing required parameters: ${missingRequiredParams.join(', ')}`);
    }

    // Ensure mobile number has been validated
    if (!/^\+614\d{8}$/.test(mobileNumber)) {
        throw new Error(`Mobile number not valid. Expected '+614xxxxxxxx'. Actual '${mobileNumber}'`);
    }

    const url = `${SIGNUP_API[platformEnv]}/user/link/phone`;
    const request = superagent
        .post(url)
        .set('Authorization', `Bearer ${accessToken}`)
        .send({
            mobile: mobileNumber,
            otp: verificationCode,
        })
        .then(property('body'))
        .catch((error) => {
            const errorBody = JSON.parse(error?.response?.text || '""');

            throw {
                errorCode: error.status,
                errorMessage: get(errorBody, 'message', error.toString()),
            };
        });

    return bacon.fromPromise(request);
}

function getMissingRequiredParams(requiredParams) {
    return Object
        .entries(requiredParams)
        .filter(([, value]) => !(value && value.length))
        .map(([key]) => key);
}
