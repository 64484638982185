import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import styled from 'styled-components';
import noop from 'lodash/noop';
import range from 'lodash/range';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {Fieldset} from '../../../../common/normalized-styled-components';
import {errorState} from '../../../../common/palette';
import VisuallyHidden from '../../../../common/visually-hidden';
import ContextualHelpBalloon from './components/contextual-help';
import NumberBoxInput from './components/number-box-input';
import sizingStyle from './components/common';
import useCodeInput from './components/use-code-input';

/*
 * NOTE:
 *   Max width is calculated so both box input row and context help row will always
 * have the same width no matter the screensize or length of context help text or code length.
 *
 * Example: [▢ ▢ ▢ ▢]
 *   ▢ - box width 10px
 *   _ - space in b/w width 3px
 *
 * calculation:
 *   > code length = 4
 *   > max boxes width = (code length * box width) = ( 4 * 10px) = 40px
 *   > max spaces = (code length - 1) = 4 - 1 = 3
 *   > max spaces in b/w width = (max spaces * space width) = (3 * 3px) = 9px
 *
 *   ∴ max-width = (max boxes width + max spaces in b/w width) = (40px + 9px) = 49px
 */
const InputContainer = styled.div`
    ${sizingStyle};
    /* stylelint-disable-next-line order/order */
    --im04-total-boxes-width: ${({codeLength}) => `calc(${codeLength} * var(--im04-box-input-width))`};
    --im04-total-boxes-spacing: ${({codeLength}) => `calc((${codeLength} - 1) * var(--im04-box-input-space-in-between))`};
    width: calc(var(--im04-total-boxes-width) + var(--im04-total-boxes-spacing));
`;

const NumberBoxWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    gap: var(--im04-box-input-space-in-between);
`;

const UnstyledFieldset = styled(Fieldset)`
    margin: 0;
    border: 0;
    padding: 0;
`;

// eslint-disable-next-line
/**
 * Code Input Field
 * - This fields takes in a code and can handle it either as OTP or PIN (masked), and also shows a context help (usually used for validation errors).
 *
 * #### **NOTE**:
 * - This does NOT have any field validations!_
 */
const IM04Code = ({
    className,
    code = '',
    codeLength = 6,
    contextualHelpColor = errorState,
    contextualHelpText,
    disabled,
    hasError,
    isCodeResetOnErrorEnabled,
    isMasked,
    isOtp = true,
    isTypeAlphanumeric = false,
    legend = 'Verification Code',
    onComplete = noop,
    resetAttemptCount,
    ...htmlAttributes
}) => {
    const {
        fieldRefs,
        handleKeyDown,
        handleInputChange,
    } = useCodeInput({code, codeLength, hasError, onComplete, resetAttemptCount, isCodeResetOnErrorEnabled});

    return (
        <InputContainer
            {...htmlAttributes}
            className={classnames('IM04Code', className)}
            codeLength={codeLength}
        >
            <UnstyledFieldset name={legend} disabled={disabled}>
                <VisuallyHidden as="legend">{legend}</VisuallyHidden>
                <NumberBoxWrapper>
                    {range(codeLength)
                        .map((index) => (
                            <NumberBoxInput
                                autoComplete={isOtp ? 'one-time-code' : 'off'}
                                ariaLabel={`${legend} digit ${index + 1}`}
                                key={`code-${index + 1}`}
                                id={`code-${index + 1}`}
                                type={isMasked ? 'password' : 'text'}
                                inputMode={isTypeAlphanumeric ? 'text' : 'numeric'}
                                onKeyDown={(event) => handleKeyDown({index, event})}
                                onChange={(event) => handleInputChange({index, targetValue: event.target.value})}
                                ref={fieldRefs[index]}
                            />
                        ))
                    }
                </NumberBoxWrapper>
            </UnstyledFieldset>

            {!!contextualHelpText && (
                <ContextualHelpBalloon role="alert" messageColor={contextualHelpColor}>
                    {contextualHelpText}
                </ContextualHelpBalloon>
            )}
        </InputContainer>
    );
};

IM04Code.propTypes = {
    /** Additional CSS classnames to be applied */
    className: classNameType,
    /**
     * Allows the code to be changed externally. Synchronise with value (like you would with any controlled
     * component) from onComplete to make this work properly.
     */
    code: propTypes.string,
    /** Lenght of code */
    codeLength: propTypes.number,
    /** Optional color to denote message type in context help balloon */
    contextualHelpColor: propTypes.string,
    /** Message to show in the contextual help balloon */
    contextualHelpText: propTypes.node,
    /** If the fieldset is in a disabled state */
    disabled: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    /** Whether or not we're in an error state */
    hasError: propTypes.bool,
    /** Whether or not we should clear the code when we're in an error state' */
    isCodeResetOnErrorEnabled: propTypes.bool,
    /** If true, then the attribute `type="password"` is set, otherwise `type="text"` */
    isMasked: propTypes.bool,
    /**
     * If true, then the attribute `autocomplete=“one-time-code”` is added, otherwise `autocomplete=“off”`
     * - Read more about autocomplete for [SMS OTP here](https://developer.apple.com/documentation/security/password_autofill/enabling_password_autofill_on_an_html_input_element)
     */
    isOtp: propTypes.bool,
    /** Changes the type of keyboard display on mobile devices. If `false`, use numberic keypad */
    isTypeAlphanumeric: propTypes.bool,
    /** Legend ie `Verification Code` or `PIN Code` */
    legend: propTypes.string,
    /** Complete callback, called when the user has filled the whole verification code */
    onComplete: propTypes.func,
    /**
     * This enables parent component to trigger field reset
     * - on value update (increment / val > 0), we trigger clearing of input fields
     */
    resetAttemptCount: propTypes.number,
};

IM04Code.displayName = 'IM04Code';

export default IM04Code;
