import React, {useEffect, useRef, useState} from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {A, Button, Nav} from 'normalized-styled-components';
import classnames from 'classnames';
import invoke from 'lodash/invoke';
import get from 'lodash/get';
import noop from 'lodash/noop';
import {rgba} from 'polished';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';

import {stylesWhenNot, stylesIfElse} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {blanc, panther} from '../../../../common/palette';

const BorderedWrapper = styled.div`
    position: relative;
    margin: 0 5%;

    &::after {
        position: absolute;
        bottom: 0;
        background-color: ${rgba(blanc, 0.1)};
        width: 100%;
        height: 1px;
        overflow: hidden;
        content: '';
    }
`;

const CarouselWrapper = styled(Nav)`
    position: relative;
    overflow: hidden;
    /* Don’t use palette panther in case the colour changes from #000.
       This is a mask and we’re playing with black and transparent as opacity placeholders. */
    /* stylelint-disable-next-line color-no-hex */
    mask-image: linear-gradient(to right, transparent, #000 35px, #000 calc(100% - 35px), transparent);
`;

const ItemsWrapper = styled.ul`
    display: flex;
    margin: 0;
    padding: 16px 0 0;
    width: 100%;
    overflow-x: scroll;
    list-style: none;
    scroll-snap-type: x mandatory;
    scroll-snap-stop: always;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::before,
    &::after {
        min-width: 50%;
        content: '';
        pointer-events: none;
        scroll-snap-align: none;
    }

    &::-webkit-scrollbar {
        display: none;
    }
`;

const navItemBaseCss = css`
    box-sizing: border-box;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    transition: ${transition('color', 'text-shadow')};
    margin: 0 6px;
    outline: 0;
    border: 0;
    background: transparent;
    padding: 0 7px;
    min-width: 78px;
    max-width: 220px;
    overflow: hidden;
    white-space: nowrap;
    font: var(--ionic-package-inclusions);
    scroll-snap-align: center;

    ${stylesIfElse('isSelected')`
        text-shadow: 0 1px 1px ${rgba(panther, 0.3)};
        color: ${blanc};
        font-weight: 500;
    ``
        color: ${rgba(blanc, 0.4)};
        font-weight: 300;
    `}

    &:hover,
    &:focus {
        text-shadow: 0 1px 1px ${rgba(panther, 0.3)};
        color: ${blanc};
    }
`;

const NavItemButton = styled(Button)`
    ${navItemBaseCss}

    appearance: none;
`;

const NavItemAnchor = styled(A)`
    ${navItemBaseCss}

    text-decoration: none;
`;

const StyledText = styled.span`
    display: block;
    padding-bottom: 15px;
    overflow-x: hidden;
    /* stylelint-disable-next-line color-no-hex */
    mask-image: linear-gradient(to right, #000 185px, transparent);

    &::after {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        transition: ${transition('transform')};
        border-radius: 1px;
        background-color: ${blanc};
        height: 2px;
        content: '';

        ${stylesWhenNot('isSelected')`
            transform: scaleX(0);
        `}
    }
`;

export default function NM08TabNav({
    items,
    onSelectItem = noop,
    selectedItemIndex = -1,
    className,
}) {
    const [hasDoneFirstScroll, setHasDoneFirstScroll] = useState(false);
    const [focusedItemIndex, setFocusedItemIndex] = useState(selectedItemIndex);
    const previousItemRef = useRef(null);
    const focusedItemRef = useRef(null);
    const nextItemRef = useRef(null);
    const isAnimated = useRef(true);

    const refs = [previousItemRef, focusedItemRef, nextItemRef];

    useEffect(() => {
        isAnimated.current = !window.matchMedia('(prefers-reduced-motion: reduce), (update: slow)').matches;
    }, []);

    useEffect(() => {
        const currentRef = get(focusedItemRef, 'current');

        if (!currentRef) {
            return;
        }

        scrollIntoView(currentRef, {
            behavior: hasDoneFirstScroll && isAnimated.current ? 'smooth' : 'auto',
            block: 'nearest',
            inline: 'center',
        })
            .then(() => {
                if (!hasDoneFirstScroll) {
                    setHasDoneFirstScroll(true);
                }
            });
    }, [focusedItemIndex, hasDoneFirstScroll]);

    // When selectedItemIndex changes, set that guy as the focused item
    useEffect(() => {
        setFocusedItemIndex(selectedItemIndex);
    }, [selectedItemIndex]);

    return (
        <BorderedWrapper className={classnames('NM08TabNav', className)}>
            <CarouselWrapper
                onKeyDown={(e) => {
                    if (e.key === 'ArrowLeft') {
                        e.preventDefault();
                        e.stopPropagation();
                        invoke(previousItemRef, 'current.focus');
                    } else if (e.key === 'ArrowRight') {
                        e.preventDefault();
                        e.stopPropagation();
                        invoke(nextItemRef, 'current.focus');
                    }
                }}
            >
                <ItemsWrapper>
                    {items
                        .map(({href, label, value}, index) => {
                            const isSelected = selectedItemIndex === index;

                            const NavItem = href ? NavItemAnchor : NavItemButton;

                            return (
                                <li key={label}>
                                    <NavItem
                                        onFocus={() => {
                                            // set focussed item for both keyboard & mouse selection
                                            setFocusedItemIndex(index);
                                        }}
                                        onKeyDown={(event) => {
                                            // only for keyboard selection
                                            if (event.key === 'Enter' || (event.key === ' ' && !href)) {
                                                onSelectItem({event, href, index, label, value});
                                            }
                                        }}
                                        onMouseDown={(event) => {
                                            // If you click too slowly, the item index changes and it begins to scroll, and onClick will never fire. So do it on mouse down.
                                            onSelectItem({event, href, index, label, value});
                                        }}
                                        onClick={(event) => {
                                            // don’t go to href if you’re a quick clicker
                                            event.preventDefault();
                                        }}
                                        tabIndex={isSelected ? 0 : -1}
                                        isSelected={isSelected}
                                        href={href}
                                        ref={refs[index - focusedItemIndex + 1] || null}
                                    >
                                        <StyledText isSelected={isSelected}>
                                            {label}
                                        </StyledText>
                                    </NavItem>
                                </li>
                            );
                        })}
                </ItemsWrapper>
            </CarouselWrapper>
        </BorderedWrapper>
    );
}

NM08TabNav.displayName = 'NM08TabNav';

NM08TabNav.propTypes = {
    items: propTypes.arrayOf(propTypes.shape({
        href: propTypes.string,
        label: propTypes.string,
        value: propTypes.any,
    })),
    onSelectItem: propTypes.func,
    selectedItemIndex: propTypes.number,
    className: classNameType,
};
