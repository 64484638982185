import noop from 'lodash/noop';

import {ACCOUNT_STATUS} from '@fsa-streamotion/streamotion-web-widgets-common';
import {removeLocalStorageValue} from '@fsa-streamotion/browser-utils';

import getPreviewVoucherMeta from '../../utils/preview-voucher-meta';
import cleanAuth0NonceCookies from '../../common/clean-auth0-nonce-cookies';

/**
 * Compile a list of conditions and return the redirect URL, or return false to not redirect anywhere
 *
 * @param {Object} options options
 * @param {string} options.brand current brand used in getPreviewVoucherMeta
 * @param {boolean} options.hasProfileId do they have a profileId stored from a previous visit?
 * @param {boolean} options.hasTelstraSignupJwt signifies that the user began their journey from the Telstra portal
 * @param {boolean} options.isApp is the page being shown in an app webview (e.g. via the Android app)
 * @param {boolean} options.isReferral signifies user visited with paid referral link like '?channel=something' - keep them on offer
 * @param {Function} options.setShouldPreventFreebiesRedirection function to call to set the preventRedirection flag (e.g. when redirecting to /freebies)
 * @param {boolean} options.shouldForwardLoggedOutUsersToFreebies based on the value from the JSON file maintained by marketing
 * @param {boolean} options.shouldPreventFreebiesRedirection session storage for redirection, aka “I have purposely tried to access /offer, let me stay here”
 * @param {string} options.userAccountStatus status of account
 * @param {string} options.voucher voucher code, which comes into play for Telstra vouchers redeemed against existing accounts
 * @param {string} options.voucherKey voucher local storage key
 * @param {string} options.platformEnv platform environment (e.g. staging)
 * @param {string} options.browseUrl url of the browse page (e.g. /browse)
 * @param {string} options.freebiesUrl url of the freebies page (e.g. /freebies)
 * @param {string} options.profileUrl url of the profile page (e.g. /profile)
 *
 * @returns  {boolean} returns true if we're about to redirect, otherwise false
 */
export default function redirectAwayFromOffer({
    brand,
    hasProfileId,
    hasTelstraSignupJwt,
    isApp,
    isReferral,
    setShouldPreventFreebiesRedirection = noop,
    shouldForwardLoggedOutUsersToFreebies,
    shouldPreventFreebiesRedirection,
    userAccountStatus,
    voucher,
    voucherKey,
    platformEnv,

    browseUrl,
    freebiesUrl,
    profileUrl,
}) {
    switch (userAccountStatus) {
        case undefined: // logged out user
            if (
                hasTelstraSignupJwt
                || isApp
                || shouldPreventFreebiesRedirection
                || isReferral
                || !shouldForwardLoggedOutUsersToFreebies
                || !freebiesUrl
            ) {
                return false;
            } else {
                setShouldPreventFreebiesRedirection(true);

                return navigateAndReturnTrue(freebiesUrl);
            }

        default:
        case ACCOUNT_STATUS.INIT:
            if (
                hasTelstraSignupJwt
                || isApp
                || shouldPreventFreebiesRedirection
                || isReferral
                || !freebiesUrl
            ) {
                return false;
            } else {
                return navigateAndReturnTrue(freebiesUrl, true); // we know for sure this user doesn't have a profile
            }

        case ACCOUNT_STATUS.ACTIVE_SUBSCRIPTION: // logged in premium customer
            if (hasTelstraSignupJwt || !!voucher) {
                // Remove voucher from localStorage to prevent constant redirection
                // if the voucher is not valid for this users subscription
                removeLocalStorageValue(voucherKey);

                // logged in user + telstra JWT = queue voucher flow
                // or logged in user with a voucher = queue voucher flow too
                return navigateAndReturnTrue(getPreviewVoucherMeta({voucher, brand, platformEnv}).location);
            } else {
                return navigateAndReturnTrue(hasProfileId ? browseUrl : profileUrl);
            }

        case ACCOUNT_STATUS.VERIFIED_IDENTITY:
        case ACCOUNT_STATUS.INACTIVE_SUBSCRIPTION:
            if (
                hasTelstraSignupJwt
                || isApp
                || shouldPreventFreebiesRedirection
                || isReferral
                || !freebiesUrl
            ) {
                return false;
            } else if (freebiesUrl && hasProfileId) {
                return navigateAndReturnTrue(freebiesUrl);
            } else {
                return navigateAndReturnTrue(profileUrl);
            }
    }
}

function navigateAndReturnTrue(url) {
    cleanAuth0NonceCookies();

    window.location.replace(url);

    return true;
}
