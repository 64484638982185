import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Nav} from 'normalized-styled-components';
import invoke from 'lodash/invoke';
import zip from 'lodash/zip';
import classnames from 'classnames';
import {rgba} from 'polished';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {blanc} from '../../../../common/palette';
import TB01NavT from '../../../atoms/tb/01-nav-t';

const StyledNav = styled(Nav)`
    width: 100%;
    text-align: center;
`;

const ItemsList = styled.ul`
    display: flex;
    position: relative;
    justify-content: space-evenly;
    margin: 0;
    padding: 0 10px;
    list-style: none;

    &::after {
        position: absolute;
        bottom: -15px;
        background-color: ${rgba(blanc, 0.1)};
        width: 100%;
        height: 1px;
        overflow: hidden;
        content: '';
    }
`;

const StyledLabel = styled.span`
    text-align: center;
    font: var(--ionic-voucher-terms);
`;

const StyledIcon = styled.div`
    align-self: center;
    width: 24px;
    height: 24px;
`;

export default class NM02DockNav extends React.Component {
    static displayName = 'NM02DockNav';

    static propTypes = {
        /** Additional className(s) string. e.g. for extending component */
        className: classNameType,
        /** Menu item objects which are to appear in the dock nav */
        menuItems: propTypes.arrayOf(
            propTypes.shape({
                /** Action to take on click of this menu item */
                onClick: propTypes.func,
                /** Anchor href to render with */
                location: propTypes.string,
                /** Icon to use... typically an ICXX */
                icon: propTypes.node,
                /** Is this menu item considered "active", e.g. represents the current route */
                isActive: propTypes.bool,
                /** title to show in the menu item */
                title: propTypes.string,
            })
        ),
    };

    static defaultProps = {
        menuItems: [],
    };

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    menuItemRefs = this.props.menuItems.map(React.createRef); // for simplicity here, we're assuming that menu items are not added or removed after first render. if thats no longer the case, implement a more robust solution :P

    handleKeyDown = ({key}) => {
        const focusedRefIndex = this.menuItemRefs.findIndex(({current}) => current === document.activeElement);

        if (focusedRefIndex === -1) {
            return; // ignore keypresses unless one of our menu items is focused
        }

        switch (key) {
            case 'ArrowLeft':
                return void invoke(this.menuItemRefs, [focusedRefIndex - 1, 'current', 'focus']);

            case 'ArrowRight':
                return void invoke(this.menuItemRefs, [focusedRefIndex + 1, 'current', 'focus']);

            default:
                return;
        }
    };

    render() {
        return (
            <StyledNav className={classnames('NM02DockNav', this.props.className)}>
                <ItemsList>
                    {zip(this.props.menuItems, this.menuItemRefs)
                        .map(([{title, icon, isActive, location, onClick}, ref]) => (
                            <TB01NavT
                                key={title}
                                isActive={isActive}
                                url={location}
                                onClick={onClick}
                                tabIndex={isActive ? 0 : -1} // make the active item tabbable. the others will be accessed via cursors
                                ref={ref}
                            >
                                <StyledIcon>
                                    {icon}
                                </StyledIcon>
                                <StyledLabel>
                                    {title}
                                </StyledLabel>
                            </TB01NavT>
                        ))}
                </ItemsList>
            </StyledNav>
        );
    }
}
