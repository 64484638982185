import React from 'react';

import Ic from '..';

const IC209Fixtures = (args) => (
    <Ic {...args}>
        <path d="M75.03 14c1.12 0 2.031.911 2.031 2.031v6.876c2.728.007 5.372.192 7.5 1.713 2.636 1.884 3.868 5.394 3.868 11.045v42.544c0 5.182-3.946 8.402-10.299 8.402H22.298c-6.352 0-10.3-3.22-10.3-8.402V35.665c0-5.65 1.23-9.16 3.87-11.045 2.399-1.716 5.428-1.738 8.548-1.71H25v-6.879c0-1.12.91-2.031 2.03-2.031 1.121 0 2.032.911 2.032 2.031v6.879h43.938v-6.879c0-1.12.91-2.031 2.03-2.031zm9.537 26.365H15.86v37.844c0 3.753 3.498 4.543 6.437 4.543H78.13c2.936 0 6.437-.79 6.437-4.543V40.365zm-50.625 30.18a2.796 2.796 0 0 1 2.792 2.796 2.796 2.796 0 0 1-2.792 2.793 2.796 2.796 0 0 1-2.792-2.793 2.796 2.796 0 0 1 2.792-2.795zm16.535 0a2.796 2.796 0 0 1 2.793 2.796 2.796 2.796 0 0 1-2.793 2.793 2.796 2.796 0 0 1-2.792-2.793 2.796 2.796 0 0 1 2.792-2.795zm16.536 0a2.796 2.796 0 0 1 2.792 2.796 2.796 2.796 0 0 1-2.792 2.793 2.794 2.794 0 0 1 0-5.588zM33.942 59.28a2.794 2.794 0 0 1 0 5.585 2.794 2.794 0 0 1 0-5.585zm16.535 0a2.794 2.794 0 0 1 0 5.585 2.794 2.794 0 0 1 0-5.585zm16.536 0a2.794 2.794 0 0 1 0 5.585 2.79 2.79 0 0 1-2.79-2.793 2.79 2.79 0 0 1 2.79-2.792zM33.942 47.606a2.794 2.794 0 0 1 0 5.585 2.794 2.794 0 0 1 0-5.585zm16.535 0a2.794 2.794 0 0 1 0 5.585 2.794 2.794 0 0 1 0-5.585zm16.536 0a2.794 2.794 0 0 1 0 5.585 2.79 2.79 0 0 1-2.79-2.793 2.79 2.79 0 0 1 2.79-2.792zm9.362-20.836H24.083c-2.496 0-4.64.04-5.972.989-1.513 1.082-2.25 3.669-2.25 7.906v2.969h68.706v-2.97c0-4.236-.736-6.823-2.25-7.905-1.328-.945-3.42-.987-5.942-.99z" />
    </Ic>
);

IC209Fixtures.displayName = 'IC209Fixtures';

export default IC209Fixtures;
