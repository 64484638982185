import React from 'react';
import Ic from '..';

const IC277KeySegments = (args) => (
    <Ic {...args}>
        <path fillOpacity=".35" d="m17.165 81.773.427-11.55c16.946-16.787 26.256-26.224 27.93-28.311-.793-3.722.121-8.348 2.744-13.88 4.351-9.179 15.32-13.093 24.499-8.741a18.393 18.393 0 0 1 5.617 4.123c7.156 7.728 6.692 19.794-1.036 26.95a19.07 19.07 0 0 1-8.679 4.592 70.59 70.59 0 0 1-7.861 1.33s-3.89 3.326-11.668 9.981h-7.7v7.293H34.36v7.442l-17.194.771zM64.504 29.17a5.006 5.006 0 0 0-1.087 5.45 4.991 4.991 0 0 0 4.613 3.089c2.02 0 3.84-1.22 4.612-3.09a5.006 5.006 0 0 0-1.086-5.45 4.986 4.986 0 0 0-7.052 0z" />
        <g fillRule="nonzero">
            <path d="M79.835 19.899a22.196 22.196 0 0 1 7.16 15.862 22.2 22.2 0 0 1-6.477 16.154 22.17 22.17 0 0 1-20.147 6.049L49.87 68.484l-6.109-.106v4.717a3.23 3.23 0 0 1-.719 2.137l-.138.16a3.13 3.13 0 0 1-2.223.929H35.97v4.681a3.154 3.154 0 0 1-2.993 3.156L15.754 85l-.945-.842a2.843 2.843 0 0 1-.805-2.139l.49-11.73c.031-.79.356-1.54.91-2.103l27.674-27.703c-1.86-9.423 2.546-18.975 10.916-23.663a22.113 22.113 0 0 1 25.841 3.08zm-14.26-2.538c-4.331-.172-8.723 1.113-12.75 4.213a18.78 18.78 0 0 0-6.046 8.876c-1.086 3.41-1.2 7.132-.115 11.078L17.745 70.46l-.476 11.21 15.45-.764.01-6.834.023-.129a1.155 1.155 0 0 1 1.119-.883h6.637l.01-6.873.025-.128c.057-.21.181-.409.356-.61.222-.218.521-.337.832-.332l6.727.14 10.889-10.906.821.23.498.118a18.906 18.906 0 0 0 17.521-5.09 18.97 18.97 0 0 0-.547-27.322 19.095 19.095 0 0 0-12.066-4.927z" />
            <path d="M73.851 26.789a8.286 8.286 0 0 1 0 11.694 8.252 8.252 0 0 1-9.005 1.8 8.276 8.276 0 0 1-5.103-7.647 8.276 8.276 0 0 1 5.103-7.647 8.252 8.252 0 0 1 9.005 1.8zm-5.837.888a5.012 5.012 0 0 0-4.63 6.932 5.01 5.01 0 0 0 9.544-2.898 5.012 5.012 0 0 0-4.914-4.034z" />
        </g>
    </Ic>
);

IC277KeySegments.displayName = 'IC277KeySegments';

export default IC277KeySegments;
