import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import {ink, mist} from '../../../../common/palette';

import Ic from '..';

const IC42Error = ({
    color = mist,
    secondaryColor = ink,
    ...args
}) => (
    <Ic {...args}>
        <path fill={color} d="M49.245 7C25.914 7 7 25.914 7 49.245c0 23.33 18.914 42.244 42.245 42.244 23.33 0 42.244-18.913 42.244-42.244C91.482 25.917 72.573 7.008 49.245 7z" />
        <g fill={secondaryColor} transform="translate(45.782 26.391)">
            <circle cx="4.986" cy="43.007" r="4.155" />
            <path d="M.831.083h8.31v31.884H.831z" />
        </g>
    </Ic>
);

IC42Error.propTypes = {
    /** The icon's primary color */
    color: colorType,
    /** The icon's secondary color */
    secondaryColor: colorType,
};

IC42Error.displayName = 'IC42Error';

export default IC42Error;
