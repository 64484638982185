import React from 'react';
import propTypes from 'prop-types';
import {rgba} from 'polished';
import styled from 'styled-components';
import debounce from 'lodash/debounce';
import smoothscroll from 'smoothscroll-polyfill';
import IntersectionObserver from 'inteobs';
import invoke from 'lodash/invoke';
import findLastIndex from 'lodash/findLastIndex';
import ResizeObserver from 'resize-observer-polyfill';
import classnames from 'classnames';
import {Section} from 'normalized-styled-components';

import {mediaQuery, stylesWhen, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';
import {isBrowser} from '@fsa-streamotion/browser-utils';

import {blanc, onyx} from '../../../../common/palette';
import GA103Pips from '../../../atoms/ga/103-pips';
import BA26CarouselBtn from '../../../atoms/ba/26-carousel-btn';

import {
    SCREEN_375_PHABLET,
    SCREEN_768_TABLET,
    SCREEN_1024_DESKTOP,
    SCREEN_1280_DESKTOP,
    SCREEN_1680_DESKTOP,
    SCREEN_1920_DESKTOP,
    SCREEN_2560_DESKTOP,
} from '../../../../common/screen-sizes';
import {CONTENT_EDGE_SPACING_PERCENT} from '../../../../common/style-constants';

import CarouselSlot from './carousel-slot';
import Filmstrip from './filmstrip';

import {ZOOM_ALLOWANCE_PX, Z_INDEX_CONTROLS, FILMSTRIP_PREV, FILMSTRIP_CURR, FILMSTRIP_NEXT} from './constants';

const THRESHOLD_PX = 10; // for any edge cases with scroll snap. maybe its not required hmm

/**
 * Optionally takes breakpointPageSizes and returns the smallest value.
 *
 * @param {Object} [breakpointPageSizes] object with key: value of breakpoint: numberOfVisibleTilesPerRow
 *
 * @returns {number} Smallest number of visible tiles for default value of --cam-01-tiles-per-row
 */
function leastNumberOfVisibleTilesPerRow(breakpointPageSizes) {
    return Math.min(...Object.values(breakpointPageSizes));
}

/**
 * Optionally takes breakpointPageSizes and returns an array of media queries containing --cam-01-tiles-per-row for
 * each key/value of breakpointPageSizes. Internally this calls our mediaQuery function.
 *
 * @param {Object} [breakpointPageSizes] object with shape breakpointSizeInPixels: numberOfVisibleTilesPerRow
 *
 * @returns {Array} Array of media queries containing number of visible tiles against --cam-01-tiles-per-row
 */
function breakpointPageSizesMediaQueries(breakpointPageSizes) {
    return (
        Object.entries(breakpointPageSizes)
            .map(([breakpointPx, numVisibleTiles]) => mediaQuery({minWidthPx: breakpointPx})`
                --cam-01-tiles-per-row: ${numVisibleTiles};
            `)
    );
}

const StyledGA103Pips = styled(GA103Pips)`
    display: none;
    opacity: 0.3;
    width: 84px;
    height: 3px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        top: 20px;
        width: 133px;
        height: 4px;
    `}
`;

const StyledSection = styled(Section)`
    --cam-01-stand-gutter: 12px;
    --cam-01-tiles-per-row: ${({breakpointPageSizes}) => leastNumberOfVisibleTilesPerRow(breakpointPageSizes)};
    position: relative;
    /* Because children of this component use z-indexes, wrap them in a 0 so they don't interfere with other components on a page */
    z-index: 0;
    margin: 0;
    padding-bottom: 28px;
    width: 100%;
    overflow: hidden;

    ${({breakpointPageSizes}) => breakpointPageSizesMediaQueries(breakpointPageSizes)}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        --cam-01-stand-gutter: 18px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1680_DESKTOP})`
        --cam-01-stand-gutter: 24px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
        --cam-01-stand-gutter: 30px;
    `}

    ${stylesWhenNot('isPipIndicatorHidden')`
        &:hover,
        &:focus-within {
            ${StyledGA103Pips} {
                display: block;
                opacity: 1;
            }
        }
    `}
`;

const TitleProgressWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const CarouselTitle = styled.h3`
    box-sizing: border-box;
    margin: 3px 0 0 ${CONTENT_EDGE_SPACING_PERCENT}%;
    padding-right: 35px;
    max-width: calc(100% - 150px);
    overflow: hidden;
    white-space: nowrap;
    color: ${blanc};
    font: var(--nucleus-carousel-header);
    /* Don’t use palette onyx in case the colour changes from #000.
       This is a mask and we’re playing with black and transparent as opacity placeholders. */
    /* stylelint-disable-next-line color-no-hex */
    mask-image: linear-gradient(to left, transparent, #000 35px);
`;

const ProgressBarWrapper = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    margin: 3px ${CONTENT_EDGE_SPACING_PERCENT}% 0 0;
    margin-left: auto;
    min-height: 30px;
`;

const StyledBA26CarouselBtn = styled(BA26CarouselBtn).attrs({
    stopMarker: 0.75,
})`
    display: flex;
    z-index: ${Z_INDEX_CONTROLS};
    margin-top: ${ZOOM_ALLOWANCE_PX / 2}px;
    padding: 0;
    width: calc(${CONTENT_EDGE_SPACING_PERCENT}% - var(--cam-01-stand-gutter));
    height: calc(100% - ${ZOOM_ALLOWANCE_PX}px);
`;

const OuterViewport = styled.div`
    position: relative;
    width: 100%;
`;

const Viewport = styled.div`
    position: relative;
    transform: translateX(0);
    margin: -${ZOOM_ALLOWANCE_PX}px 0;
    padding: ${ZOOM_ALLOWANCE_PX}px 0;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;

    scrollbar-width: none;
    -ms-overflow-style: none;

    /* stylelint-disable function-parentheses-space-inside, function-comma-space-after */
    mask-image: linear-gradient(
        to right,
        ${rgba(onyx, 0.4)} 0%,
        ${rgba(onyx, 0.4)} ${CONTENT_EDGE_SPACING_PERCENT - 1}%,
        ${onyx} ${CONTENT_EDGE_SPACING_PERCENT - 1}%,
        ${onyx} ${100 - CONTENT_EDGE_SPACING_PERCENT + 1}%,
        ${rgba(onyx, 0.4)} ${100 - CONTENT_EDGE_SPACING_PERCENT + 1}%,
        ${rgba(onyx, 0.4)} 100%
    );
    /* stylelint-enable function-parentheses-space-inside, function-comma-space-after */

    ${stylesWhen('isWaitingToMount')`
        &::before {
            display: inline-block;
            margin-left: -200%;
            width: 100%;
            content: '';
        }
    `}

    &::-webkit-scrollbar {
        display: none;
    }

    > * {
        white-space: initial; /* prevent white-space inheritance */
    }
`;

const FILMSTRIPS = [FILMSTRIP_PREV, FILMSTRIP_CURR, FILMSTRIP_NEXT]; // the order here is crucial as we use it for generating react keys

/**
 * ASCII ART JUST FOR YOU GREG <3
 *
 *  - frame size is determined by the viewport. its the number of slides we increment every time nav buttons are pushed
 *  - a film strip is effectively a linear scroll list
 *  - we fake infinite scroll by putting the film strips in an infinite carousel
 *
 *                                                 +-----------------------+
 *                                                 |                       |
 *                                                 |   (BROWSER VIEWPORT)  |
 * +-----------------------------------------------+-----------------------------------------------+-----------------------------------------------+
 * |                film strip -1                  |                film strip 0                   |                film strip 1                   |
 * +-----------------------------------------------+-----------------------------------------------+-----------------------------------------------+
 * |        frame          |         frame         |        frame          |         frame         |        frame          |         frame         |
 * +-----+-----+-----+-----------+-----+-----+-----+-----+-----+-----+-----------+-----+-----+-----+-----+-----+-----+-----------+-----+-----+-----+
 * |     |     |     |     |     |     |     |     |     |     |     |     |     |     |     |     |     |     |     |     |     |     |     |     |
 * |slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|
 * |1    |2    |3    |4    |5    |6    |7    |8    |1    |2    |3    |4    |5    |6    |7    |8    |1    |2    |3    |4    |5    |6    |7    |8    |
 * +-----------------------------------------------+-----------------------------------------------+-----------------------------------------------+
 *                                                 |   (BROWSER VIEWPORT)  |
 *                                                 +-----------------------+
 *
 */

export default class CAM01Stand extends React.Component {
    static displayName = 'CAM01Stand';

    static propTypes = {
        /** additional CSS classnames to be applied */
        className: classNameType,
        /** Definitions for how large page size should be at different breakpoints */
        breakpointPageSizes: propTypes.objectOf(propTypes.number),
        /** Carousel Label */
        label: propTypes.string,
        /** Carousel Slides */
        children: propTypes.node,
        /** For lazy evaluation purposes, what is the maximum slides that could appear on initial render? Use -1 to force eager loading */
        maxSlidesPerPage: propTypes.number,
        /** Never show pip indicator */
        isPipIndicatorHidden: propTypes.bool,
        /** Show partial fake tiles at the beginning and ends of carousels to make them look infinite. */
        hasHints: propTypes.bool,
        /** Show partial fake tiles at the beginning of carousels to make them look infinite. */
        hasLeftHint: propTypes.bool,
    };

    static defaultProps = {
        breakpointPageSizes: {
            [SCREEN_375_PHABLET]: 3,
            [SCREEN_768_TABLET]: 5,
            [SCREEN_1024_DESKTOP]: 6,
            [SCREEN_1280_DESKTOP]: 5,
        },
        children: [],
        maxSlidesPerPage: 6,
        hasHints: true,
    };

    state = {
        isActive: false,
        filmstripIndex: 0,
        hasNextButton: false,
        hasNextFrame: false,
        hasPrevButton: false,
        hasPrevFrame: false,
        wakeupIndex: this.props.maxSlidesPerPage, // always show our initial page
        hasLeftHint: this.props.hasLeftHint,
        isWaitingToMount: true,
    };

    componentDidMount() {
        if (this.isAnimated) {
            smoothscroll.polyfill(); // currently only safari and edge need this. used for the scrolling effect on prev/next button clicks
        }

        this.setNavigationButtonVisibility();

        if (this.props.maxSlidesPerPage < 0) {
            return; // we've been told to load everything. don't bother using an observer
        }

        // for performance reasons, we're going to use a single observer on the carousel
        this.intersectionObserver = new IntersectionObserver((elements) => { // eslint-disable-line compat/compat
            const intersectingEls = elements
                .filter(({intersectionRatio}) => intersectionRatio > 0)
                .map(({target}) => target);

            const highestVisibleIndex = findLastIndex(this.slotEls, (slotEl) => intersectingEls.includes(slotEl));

            const wakeupIndex = Math.min(
                React.Children.count(this.props.children), // don't wake up children we don't have
                highestVisibleIndex + this.props.maxSlidesPerPage, // whatever is visible,
            );

            if (wakeupIndex > this.state.wakeupIndex) {
                // once you wake your children, they wont EVER go back to sleep >_>
                // this means we can stop observing any children before our wakeup index
                this.slotEls
                    .slice(this.state.wakeupIndex, wakeupIndex)
                    .forEach((slotEl) => this.intersectionObserver.unobserve(slotEl));

                this.setState({wakeupIndex});
            }
        });

        this.resizeObserver = new ResizeObserver(this.debouncedHandleResize);
        this.resizeObserver.observe(this.rootRef.current);

        // now connect each of our carousel slot elements to the observer
        this.slotEls.forEach((slotEl) => void this.intersectionObserver.observe(slotEl));
    }

    /**
     * We use this react lifecycle hook to allow us to clean up intersection observer listeners on elements that will soon not exist anymore
     * https://reactjs.org/docs/react-component.html#getsnapshotbeforeupdate
     *
     * @returns {null} returns null
     */
    getSnapshotBeforeUpdate() {
        if (this.intersectionObserver && this.slotEls.length) {
            return this.slotEls.slice(); // hold on to our old slot references. if one or more is removed, we need to disconnect observers
        }

        return null;
    }

    componentDidUpdate(prevProps, prevState, prevSlotEls) {
        if (!prevSlotEls || !prevSlotEls.length || !this.intersectionObserver) {
            return;
        }

        // stop observing elements that have been removed
        prevSlotEls
            .filter((prevSlotEl) => prevSlotEl && !this.slotEls.includes(prevSlotEl))
            .forEach((unobserveEl) => void this.intersectionObserver.unobserve(unobserveEl));

        // start observing elements that have been added
        this.slotEls
            .filter((slotEl) => slotEl && !prevSlotEls.includes(slotEl))
            .forEach((unobserveEl) => void this.intersectionObserver.observe(unobserveEl));
    }

    componentWillUnmount() {
        invoke(this.intersectionObserver, 'disconnect');
        invoke(this.resizeObserver, 'disconnect');
        invoke(this.debouncedSetNavigationButtonVisibility, 'cancel'); // e.g. cancel debounce
        invoke(this.debouncedHandleResize, 'cancel');
        clearTimeout(this.filmstripAnimationTimeout);
    }

    isAnimated = isBrowser() && !window.matchMedia('(prefers-reduced-motion: reduce), (update: slow)').matches;

    /**
     * We only want to show navigation buttons when the slides can't all be seen in the viewport
     *
     * @param {number} increment optionally provide a number of slides to increment by
     */
    setNavigationButtonVisibility = (increment) => {
        if (!this.visibleFilmStripEl) {
            return;
        }

        const {scrollLeft, scrollWidth} = this.visibleFilmStripEl;
        const {width} = this.visibleFilmStripEl.getBoundingClientRect();
        let hasPrevFrame = scrollLeft > THRESHOLD_PX;
        let hasNextFrame = scrollLeft + width < scrollWidth - THRESHOLD_PX;

        if (increment) {
            const newLeft = Math.max(0, scrollLeft + (width * increment));
            const newRight = Math.min(scrollWidth, newLeft + width);

            this.visibleFilmStripEl.scrollTo({behavior: this.isAnimated ? 'smooth' : 'auto', left: newLeft});

            hasNextFrame = newRight < scrollWidth - THRESHOLD_PX;
            hasPrevFrame = newLeft > THRESHOLD_PX;
        }

        const firstVisibleIndex = Math.ceil(scrollLeft / (this.visibleFilmStripEl.scrollWidth / this.slotEls.length));

        if (this.state.hasNextFrame !== hasNextFrame || this.state.hasPrevFrame !== hasPrevFrame) {
            this.setState(({hasLeftHint}) => ({
                hasNextButton: hasNextFrame || (this.props.hasHints && hasPrevFrame),
                hasNextFrame,
                hasPrevButton: hasPrevFrame || (hasLeftHint && hasNextFrame),
                hasPrevFrame,
                firstVisibleIndex,
                hasPipIndicator: this.props.isPipIndicatorHidden ? false : (hasNextFrame || hasPrevFrame),
                carouselWidthPx: width,
            }));
        } else {
            this.setState({
                firstVisibleIndex,
                carouselWidthPx: width,
            });
        }
    };

    filmstripAnimationTimeout = null;

    prevFrame = () => void this.setNavigationButtonVisibility(-1);
    nextFrame = () => void this.setNavigationButtonVisibility(1);
    prevFilmstrip = () => {
        this.setState(({filmstripIndex}) => (
            Object.assign(
                {
                    filmstripIndex: filmstripIndex - 1,
                    wakeupIndex: React.Children.count(this.props.children), // we've effectively jumped to the end of the carousel. Wake up everyone!
                },
                this.props.hasHints && {hasLeftHint: true}
            )
        ), () => {
            this.setNavigationButtonVisibility();
            this.viewportEl.scrollLeft = this.viewportEl.clientWidth * 2;
            this.viewportEl.scrollTo({
                behavior: this.isAnimated ? 'smooth' : 'auto',
                left: this.viewportEl.clientWidth,
            });
        });
    };

    nextFilmstrip = () => {
        this.setState(({filmstripIndex}) => (
            Object.assign(
                {
                    filmstripIndex: filmstripIndex + 1,
                },
                this.props.hasHints && {hasLeftHint: true}
            )
        ), () => {
            this.setNavigationButtonVisibility();
            this.viewportEl.scrollLeft = 0;
            this.viewportEl.scrollTo({
                behavior: this.isAnimated ? 'smooth' : 'auto',
                left: this.viewportEl.clientWidth,
            });
        });
    };

    focusNext = () => {
        const nextSlotIndex = this.slotEls.findIndex((el) => el.contains(document.activeElement)) + 1;

        invoke(
            this.slotEls[nextSlotIndex < this.slotEls.length ? nextSlotIndex : 0].querySelector('button, a'),
            'focus'
        );
    };

    focusPrev = () => {
        const prevSlotIndex = this.slotEls.findIndex((el) => el.contains(document.activeElement)) - 1;

        invoke(
            this.slotEls[prevSlotIndex < 0 ? this.slotEls.length - 1 : prevSlotIndex].querySelector('button, a'),
            'focus'
        );
    };

    handleKeyDown = (event) => {
        switch (event.key) {
            case 'ArrowRight':
            case 'Right':
                event.preventDefault();

                return void this.focusNext();

            case 'ArrowLeft':
            case 'Left':
                event.preventDefault();

                return void this.focusPrev();

            default:
                return;
        }
    };

    filmstripRefs = { // at all times we have three filmstrips. prev should be at the end, next should be at the start. current is the only one we need keep a reference to though
        [FILMSTRIP_PREV]: (filmStripPrevEl) => {
            if (filmStripPrevEl) {
                filmStripPrevEl.scrollLeft = filmStripPrevEl.scrollWidth; // "previous" filmstrip should always be internally scrolled to its end
            }
        },
        [FILMSTRIP_CURR]: (filmStripCurrentEl) => {
            this.visibleFilmStripEl = filmStripCurrentEl;
        },
        [FILMSTRIP_NEXT]: (filmStripNextEl) => {
            if (filmStripNextEl) {
                filmStripNextEl.scrollLeft = 0; // "next" filmstrip should always be internally scrolled to its start
            }
        },
    };

    visibleFilmStripEl = null;
    debouncedSetNavigationButtonVisibility = debounce(() => void this.setNavigationButtonVisibility(), 100); // check if we've reached either end of the carousel after a short interval
    debouncedHandleResize = debounce(() => {
        this.setNavigationButtonVisibility();
        this.viewportEl.scrollLeft = this.viewportEl.clientWidth; // ensure that the current carousel remains in the viewport
    }, 50);

    intersectionObserver = null;
    resizeObserver = null;
    slotEls = [];
    rootRef = React.createRef();
    viewportRef = (el) => {
        if (!el) {
            return;
        }

        if (!this.viewportEl) {
            this.setState({isWaitingToMount: false});
            el.scrollLeft = el.clientWidth;
        }

        this.viewportEl = el;
    };

    activate = () => void this.setState({isActive: true});
    deactivate = () => void this.setState({isActive: false});

    render() {
        const childrenArray = React.Children.toArray(this.props.children); // we need access to indexes and length

        this.slotEls.length = childrenArray.length; // garbage collect unused pointers

        return (
            <StyledSection
                breakpointPageSizes={this.props.breakpointPageSizes}
                className={classnames('CAM01Stand', this.props.className)}
                onKeyDown={this.handleKeyDown}
                ref={this.rootRef}
                isPipIndicatorHidden={this.props.isPipIndicatorHidden}
            >
                {(!!this.state.hasPipIndicator || !!this.props.label) && (
                    <TitleProgressWrapper>
                        {!!this.props.label && (
                            <CarouselTitle>{this.props.label}</CarouselTitle>
                        )}
                        {!!this.state.hasPipIndicator && (
                            <ProgressBarWrapper>
                                <StyledGA103Pips
                                    max={this.slotEls.length}
                                    position={this.state.firstVisibleIndex}
                                    range="var(--cam-01-tiles-per-row)"
                                />
                            </ProgressBarWrapper>
                        )}
                    </TitleProgressWrapper>
                )}
                <OuterViewport
                    onBlur={this.deactivate}
                    onFocus={this.activate}
                    onMouseEnter={this.activate}
                    onMouseLeave={this.deactivate}
                >
                    {!!this.state.hasPrevButton && (
                        <StyledBA26CarouselBtn
                            isActive={this.state.isActive}
                            direction="left"
                            onClick={this.state.hasPrevFrame ? this.prevFrame : this.prevFilmstrip}
                        />
                    )}
                    <Viewport
                        ref={this.viewportRef}
                        isWaitingToMount={this.state.isWaitingToMount}
                    >
                        {(this.props.hasHints ? FILMSTRIPS : [FILMSTRIP_CURR]) // if hasHints=true we render the carousel to the DOM three times. this isn't ideal - we only need the other two during a filmstrip transition. if some dev wants to optimise this later that would be awesome but I ran out of time. If hasHints=false && content is non-interactive, keyboard users have to navigate through all three carousels so we just take the current carousel to keep them at one.
                            .map((symbol, index) => ({
                                key: this.state.filmstripIndex + index - 1, // subtracting 1 so the initial keys are [-1, 0, 1] where zero is the initially visible filmstrip
                                symbol,
                            }))
                            .map(({key, symbol}) => (
                                <Filmstrip
                                    ref={this.filmstripRefs[symbol]}
                                    onScroll={this.debouncedSetNavigationButtonVisibility}
                                    key={key}
                                    hasNextHint={this.props.hasHints && this.state.hasNextButton}
                                    hasPrevHint={this.state.hasLeftHint && this.state.hasPrevButton}
                                    data-index={key}
                                    aria-hidden={symbol !== FILMSTRIP_CURR}
                                    firstSlideTeaser={childrenArray[0]}
                                    lastSlideTeaser={childrenArray[childrenArray.length - 1]}
                                    carouselWidthPx={this.state.carouselWidthPx}
                                >
                                    {childrenArray.map(({key, ...slide}, index) => (
                                        <CarouselSlot
                                            tabIndex={symbol === FILMSTRIP_CURR && !index ? 0 : -1}
                                            ref={symbol === FILMSTRIP_CURR // we only care about refs to the current carousel slides
                                                ? (slotEl) => void Object.assign(this.slotEls, {[index]: slotEl}) // specifically not using array.push below as ref callbacks don't fire in DOM order
                                                : null}
                                            isBeingLazy={this.props.maxSlidesPerPage > -1 && index > this.state.wakeupIndex}
                                            key={key}
                                            children={slide}
                                        />
                                    ))}
                                </Filmstrip>
                            ))}
                    </Viewport>
                    {!!this.state.hasNextButton && (
                        <StyledBA26CarouselBtn
                            isActive={this.state.isActive}
                            direction="right"
                            onClick={this.state.hasNextFrame ? this.nextFrame : this.nextFilmstrip}
                        />
                    )}
                </OuterViewport>
            </StyledSection>
        );
    }
}
