import React, {useState} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import classnames from 'classnames';
import property from 'lodash/property';
import noop from 'lodash/noop';
import {mediaQuery, stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType, colorType} from '@fsa-streamotion/custom-prop-types';

import {black, cloudy, indigo, ink} from '../../../../common/palette';
import {Button, Footer, Section} from '../../../../common/normalized-styled-components';
import CommonTransition from '../../../../common/common-transition';
import {fadeInOut, transition} from '../../../../common/animations';
import {
    SCREEN_375_PHABLET,
    SCREEN_768_TABLET,
    SCREEN_1024_DESKTOP,
    SCREEN_1280_DESKTOP,
    SCREEN_1920_DESKTOP,
} from '../../../../common/screen-sizes';

import IC135Cross from '../../../atoms/ic/135-cross';
import BA01BtnPr from '../../../atoms/ba/01-btn-pr';
import BA28BtnSec from '../../../atoms/ba/28-btn-sec';

import FocusTrapWrapper from './focus-trap-wrapper';

const Z_INDEX_ABOVE_CONTENT = 2;

const PageContainer = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    z-index: ${Z_INDEX_ABOVE_CONTENT}; // Not ideal but this is a hacky way to get around certain elements that decide to mess with the z-index. Needs to be a Portal at some point
    width: 100%;
    height: 100%;
    min-height: 100dvh;
    ${fadeInOut}
    /* fadeInOut uses opacity in its transition so we have it here too so we don't override it */
    /* stylelint-disable-next-line order/properties-order */
    transition: ${transition('background-color', 'opacity')};

    ${stylesWhen('overlayColor')`
        background-color: ${property('overlayColor')};
    `}
`;

const Modal = styled.dialog`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border: 0; /** Yucky browser styles */
    border-radius: 5px;
    box-shadow: 0 0 15px 4px ${rgba(black, 0.2)};
    background-color: ${cloudy};
    background-image: linear-gradient(${rgba(indigo, 0.05)}, transparent 74%);
    padding: 42px 0;
    width: 100%;
    max-height: 96dvh;
    overflow-y: auto;
    overscroll-behavior: none;

    ${mediaQuery({minWidthPx: SCREEN_375_PHABLET})`
        width: 333px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: 509px;
        padding: 63px 0;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        width: 725px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding: 84px 0;
    `}
`;

// CROSS X BUTTON ON TOP RIGHT CORNER

const ButtonContainer = styled(Footer)`
    position: absolute;
    top: 7px;
    right: 7px;
    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        top: 14px;
        right: 14px;
    `}
`;

const StyledButton = styled(Button)`
    box-sizing: content-box;
    border: 0;
    background: transparent;
    padding: 10px;
    width: 21px;
    height: 21px;
    color: ${ink};

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: 30px;
        height: 30px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 40px;
        height: 40px;
    `}
`;

// MIDDLE CONTENT

const ContentWrapper = styled(Section)`
    font: var(--quicksilver-body-copy-3-light);
`;

const HeaderWrapper = styled.section`
    margin-bottom: 14px;
    text-align: center;
    color: ${ink};
    font: var(--quicksilver-header-3-medium);

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        margin-bottom: 35px;
    `}
`;

// CLOSE CTA BUTTON AT THE BOTTOM

const StyledFooter = styled(Footer)`
    box-sizing: border-box;
    display: flex;
    align-self: center;
    justify-content: center;
    margin-top: 28px;
    width: 100%;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        margin-top: 42px;
    `}
`;

/**
 * ### Modal
 * Modal that displays over the page
 */
const OR56Modal = ({
    canBeDeactivated,
    children,
    className,
    closeButtonText,
    hasCrossButton,
    hasFocusTrap = true,
    hasOverlay = true,
    overlayColor,
    header,
    isSecondaryButton,
    isVisible,
    onCloseClick = noop,
    ...htmlAttributes
}) => {
    const [hasFocus, setHasFocus] = useState(false);

    const handleOnClick = () => {
        // let focus trap handle invoking callback onCloseClick via deactivateFocusTrap (hasFocus=false)
        // so we dont call the onCloseClick callback twice
        setHasFocus(false);
    };

    const CloseButtonComponent = isSecondaryButton ? BA28BtnSec : BA01BtnPr;

    return (
        <CommonTransition
            in={isVisible}
            onEntered={() => setHasFocus(true)} // Focus trap seems to prevent CommonTransition from fading in so we delay it until the transition ends
        >
            <PageContainer
                {...htmlAttributes}

                // cloudy at 0.85 is the default of overlay color
                overlayColor={hasOverlay && (overlayColor || rgba(cloudy, 0.85))}
            >
                <FocusTrapWrapper
                    canBeDeactivated={canBeDeactivated}
                    deactivateFocusTrap={() => {
                        if (canBeDeactivated) {
                            setHasFocus(false);
                            onCloseClick();
                        }
                    }}
                    hasFocus={hasFocus}
                    hasFocusTrap={hasFocusTrap}
                >
                    <Modal className={classnames('OR56Modal', className)} open={isVisible}>
                        <ContentWrapper>
                            {!!header && <HeaderWrapper>{header}</HeaderWrapper>}
                            {children}
                        </ContentWrapper>

                        {!!canBeDeactivated && (
                            hasCrossButton ? (
                                <ButtonContainer>
                                    <StyledButton
                                        aria-label="Close"
                                        onClick={handleOnClick}
                                    >
                                        <IC135Cross color={ink} />
                                    </StyledButton>
                                </ButtonContainer>
                            ) : (
                                <StyledFooter>
                                    <CloseButtonComponent onClick={handleOnClick}>
                                        {closeButtonText || 'Close'}
                                    </CloseButtonComponent>
                                </StyledFooter>
                            )
                        )}
                    </Modal>
                </FocusTrapWrapper>
            </PageContainer>
        </CommonTransition>
    );
};

OR56Modal.propTypes = {
    /** Content of modal */
    children: propTypes.node,
    /** Additional CSS classnames */
    className: classNameType,
    /** Custom Text for the Close button */
    closeButtonText: propTypes.string,
    /** Can this modal be deactivated by clicking close, clicking outside of the modal or pressing Escape? */
    canBeDeactivated: propTypes.bool,
    /** Will the modal contain a Focus Trap? */
    hasFocusTrap: propTypes.bool,
    /** Displays an overlay on top of the content behind the modal */
    hasOverlay: propTypes.bool,
    /** setting up overlay color if the modal has an overlay */
    overlayColor: colorType,
    /** If true, display X button at top right corner. If false, display close button instead */
    hasCrossButton: propTypes.bool,
    /** Content header of modal */
    header: propTypes.node,
    /** Do we show the close button as a secondary button? */
    isSecondaryButton: propTypes.bool,
    /** Is modal open? */
    isVisible: propTypes.bool,
    /** Function to call when either user clicks on close button, clicks outside of the modal or presses escape */
    onCloseClick: propTypes.func,
};

OR56Modal.displayName = 'OR56Modal';

export default OR56Modal;
