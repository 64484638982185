export default function getNormalisedBrand(brand) {
    const lowerCaseBrand = brand?.toLowerCase();

    switch (lowerCaseBrand) {
        case 'martian':
            return 'kayo';
        case 'ares':
            return 'binge';
        default:
            return lowerCaseBrand;
    }
}
