import React from 'react';
import Ic from '..';

const IC99ExitThtr = (args) => (
    <Ic {...args}>
        <path d="M93.092 70.692h-5.95v-4.2h1.75V19.2H12.2v47.292h2.156v4.2H8V15h85.092z" />
        <path d="M73.198 45.618V75.97H28.244V45.618h44.954m8.4-8.4H19.844V84.37h61.754V37.218z" />
    </Ic>
);

IC99ExitThtr.displayName = 'IC99ExitThtr';

export default IC99ExitThtr;
