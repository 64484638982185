import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {SCREEN_LG_DESKTOP} from '../../../../common/screen-sizes';

const StyledContainer = styled.div`
    margin: 0 auto;
    width: 333px;
    max-width: 100%;
    text-align: center;

    ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
        width: 408px;
    `}

    > *:not(:last-child) {
        margin-bottom: 7px;
    }
`;

const XX05FixedContainer = ({
    children,
}) => (
    <StyledContainer className="XX05FixedContainer">
        {children}
    </StyledContainer>
);

XX05FixedContainer.displayName = 'XX05FixedContainer';

XX05FixedContainer.propTypes = {
    /** Content of container, e.g. text */
    children: propTypes.node.isRequired,
};

export default XX05FixedContainer;
