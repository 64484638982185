import React from 'react';
import Ic from '..';

const IC49Open = (args) => (
    <Ic {...args}>
        <path d="M88.9 47.2H52.8V11.1h-5.6v36.1H11.1v5.6h36.1v36.1h5.6V52.8h36.1z" />
    </Ic>
);

IC49Open.displayName = 'IC49Open';

export default IC49Open;
