import React, {useEffect, useMemo, useRef, useState} from 'react';
import classNames from 'classnames';
import {rgba} from 'polished';
import propTypes from 'prop-types';
import styled from 'styled-components';
import throttle from 'lodash/throttle';

import {mediaQuery, stylesIfElse} from '@fsa-streamotion/styled-component-helpers';
import {srcsetOptionsType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import LazyImageSwitcher from '../../../../common/lazy-image-switcher';
import {red, black, white, midnight} from '../../../../common/palette';
import {SCREEN_PRETTY_LG_DESKTOP, SCREEN_REALLY_LG_DESKTOP} from '../../../../common/screen-sizes';
import SrcsetImg from '../../../../common/srcset-img';
import tileSize, {TILE_SIZES_ATTRIBUTE} from '../../../../common/standard-tile-size';
import {fontFamily} from '../../../../common/typography';

// an estimated constant related to font size
const FONT_CONSTANT = 2.0;

const StyledDiv = styled.div`
    position: relative;
    transform: scale(${({isActive}) => isActive ? 1.1 : 1});
    transition: ${transition('transform')};
    /* transition delay here prevents scroll jank when fixed cursor triggers scale transformations on scroll */
    transition-delay: 100ms;

    ${stylesIfElse('isBlockSized')`
        width: 100%;
    ``
        ${tileSize};
    `}

    &::before {
        display: block;
        position: relative;
        padding-bottom: 56.25%; /* 16x9 */
        width: 100%;
        height: 0;
        overflow: hidden;
        content: '';
    }
`;

const StyledLazyImageSwitcher = styled(LazyImageSwitcher)`
    position: absolute;
    top: 0;
    left: 0;
`;

const StyledSrcsetImg = styled(SrcsetImg)`
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px;
    box-shadow: 0 0 30px 0 ${black};
    background-color: ${rgba(black, 0.35)};
    width: 100%;
    height: 100%;
`;

const Badge = styled.em`
    box-sizing: border-box;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    border-top-right-radius: 4px;
    background-color: ${({isStreaming}) => isStreaming ? red : black};
    padding: 0 7px;
    max-width: 100%;
    overflow: hidden;
    text-transform: uppercase;
    text-shadow: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${white};
    font: 600 13px/1.85 ${fontFamily};

    ${mediaQuery({minWidthPx: SCREEN_PRETTY_LG_DESKTOP})`
        font-size: 15px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_REALLY_LG_DESKTOP})`
        font-size: 24px;
    `}
`;

const Description = styled.strong`
    box-sizing: border-box;
    position: absolute;
    right: 0;
    bottom: 9px;
    margin-left: auto;
    border-top: 4px solid ${rgba(midnight, 0.95)};
    border-bottom: 4px solid ${rgba(midnight, 0.95)};
    background: ${rgba(midnight, 0.95)};
    padding: 0 8px;
    max-width: 80%;
    overflow: hidden;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${white};
    font: var(--mui-body-copy-4);

    ${mediaQuery({minWidthPx: SCREEN_REALLY_LG_DESKTOP})`
        border-width: 12px;
        padding: 0 12px;
    `}
`;

const GA07TileImg = ({
    badge,
    caption,
    className,
    description,
    isActive,
    isBeingLazy,
    isBlockSized,
    isStreaming,
    onClick,
    srcsetOptions,
    srcsetSizes = isBlockSized ? undefined : TILE_SIZES_ATTRIBUTE, // when we're block sized, we can't know our rendered size in advance. the browser will default to the viewport
    freemiumSrcsetOptions,
    freemiumIconAlt = '',
}) => {
    const descriptionRef = useRef(null);
    const containerRef = useRef(null);
    const [descriptionWithLineBreak, setDescriptionWithLineBreak] = useState(description);

    const calculateDescription = useMemo(() => throttle(() => {
        if (!descriptionRef.current) {
            return;
        }

        const {width} = containerRef.current.getBoundingClientRect();
        const fontSize = window.getComputedStyle(descriptionRef.current).getPropertyValue('font-size');
        const charPerLine = Math.ceil(0.8 * width * FONT_CONSTANT / parseFloat(fontSize));

        if (description?.length > charPerLine) {
            const index = description.lastIndexOf(' ', charPerLine);

            setDescriptionWithLineBreak(
                <React.Fragment>
                    {description.substring(0, index)}
                    <br />
                    {description.substring(index + 1)}
                </React.Fragment>
            );
        } else {
            setDescriptionWithLineBreak(description);
        }
    }, 500), [description]);

    useEffect(function observeResizeWhenMount() {
        if (!containerRef.current) {
            return;
        }
        const resizeObserver = new ResizeObserver(calculateDescription);
        const observerRefVal = containerRef.current;

        resizeObserver.observe(observerRefVal);

        return () => {
            resizeObserver.unobserve(observerRefVal);
            calculateDescription.cancel();
        };
    }, [calculateDescription]);

    return (
        <StyledDiv className={classNames('GA07TileImg', className)} ref={containerRef} {...{isActive, onClick, isBlockSized}}>
            <StyledLazyImageSwitcher isBeingLazy={isBeingLazy}>
                <StyledSrcsetImg
                    alt={caption}
                    sizes={srcsetSizes}
                    srcsetOptions={srcsetOptions}
                />
            </StyledLazyImageSwitcher>
            {!!freemiumSrcsetOptions && (
                <StyledLazyImageSwitcher isBeingLazy={isBeingLazy}>
                    <StyledSrcsetImg
                        alt={freemiumIconAlt}
                        sizes={srcsetSizes}
                        srcsetOptions={freemiumSrcsetOptions}
                    />
                </StyledLazyImageSwitcher>
            )}
            {!!badge && (
                <Badge isStreaming={isStreaming}>{badge}</Badge>
            )}
            {!!description && (
                <Description ref={descriptionRef}>{descriptionWithLineBreak}</Description>
            )}
        </StyledDiv>
    );
};

GA07TileImg.propTypes = {
    /** Badge caption, e.g. live or replay */
    badge: propTypes.string,
    /** Image caption, e.g. alt text */
    caption: propTypes.string.isRequired,
    /** Additional class(es) */
    className: classNameType,
    /** Description text, e.g. title of the asset */
    description: propTypes.string,
    /** Whether the item is activated (e.g. passed in via carousel or wrapping TM21) */
    isActive: propTypes.bool,
    /** Whether or not to use the lazySrc instead of the proper URL */
    isBeingLazy: propTypes.bool,
    /** Should the component assume the width of its parent */
    isBlockSized: propTypes.bool,
    /** Use "is live" treatment on the badge */
    isStreaming: propTypes.bool,
    /** Callback on click. NOTE: this probably doesn't belong here but there is no molecule yet combining this and duration bar */
    onClick: propTypes.func,
    /** Srcset for image */
    srcsetOptions: srcsetOptionsType,
    /** "sizes" attribute for srcset image */
    srcsetSizes: propTypes.string,
    /** Srcset for freemium overlay */
    freemiumSrcsetOptions: srcsetOptionsType,
    /** alt attribute for freemium overlay */
    freemiumIconAlt: propTypes.string,
};

GA07TileImg.displayName = 'GA07TileImg';

export default GA07TileImg;
