import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {rgba} from 'polished';
import {Button} from 'normalized-styled-components';
import noop from 'lodash/noop';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {kayoGreen, white, coal} from '../../../../common/palette';
import {transition} from '../../../../common/animations';

const StyledButtonHover = css`
    outline: 0;
    border-color: ${kayoGreen};
`;

const StyledButton = styled(Button)`
    appearance: none;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    transition: ${transition('color', 'border-color')};
    border: 2px solid transparent;
    border-radius: 4px;
    background-color: ${rgba(coal, 0.6)};
    padding: 7px;
    width: 82px;
    height: 84px;
    line-height: 1.13;
    color: ${rgba(white, 0.3)};
    font-size: 16px;

    &:focus,
    &:hover {
        ${StyledButtonHover}
    }

    &[aria-pressed='true'] {
        color: ${white};

        &:focus,
        &:hover {
            ${StyledButtonHover}
        }
    }
`;

const IconBox = styled.span`
    display: inline-block;
    margin-bottom: 7px;
    width: 17px;
    height: 17px;
`;

const BA11BtnFav = ({
    'aria-pressed': isPressed,
    children,
    icon,
    className,
    onClick = noop,
    ...htmlAttributes
}) => (
    <StyledButton
        {...htmlAttributes}
        {...{
            onClick,
        }}
        className={classnames('BA11BtnFav', className)}
        aria-pressed={isPressed}
        type="button"
    >
        <IconBox>
            {icon}
        </IconBox>
        {children}
    </StyledButton>
);

BA11BtnFav.displayName = 'BA11BtnFav';

/* eslint-disable quote-props */
BA11BtnFav.propTypes = {
    /** Selected state */
    'aria-pressed': propTypes.oneOf([true, false, 'mixed']),
    /** IC icon to use */
    icon: propTypes.node,
    /** Content of button, e.g. text */
    children: propTypes.node.isRequired,
    /** Callback on element click */
    onClick: propTypes.func,
    /** Additional CSS class(es) */
    className: classNameType,
};
/* eslint-enable quote-props */

export default BA11BtnFav;
