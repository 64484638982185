import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Main, H1, Footer} from 'normalized-styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';
import noop from 'lodash/noop';

import {mediaQuery, stylesIfElse} from '@fsa-streamotion/styled-component-helpers';
import {classNameType, srcsetOptionsType} from '@fsa-streamotion/custom-prop-types';

import {smoke, ink, onyx} from '../../../common/palette';
import {SCREEN_1280_DESKTOP, SCREEN_768_TABLET} from '../../../common/screen-sizes';
import SrcsetImg from '../../../common/srcset-img';

import GA92BrandLogo, {Branding} from '../../atoms/ga/92-brand-logo';
import IC103Loading from '../../atoms/ic/103-loading';
import TM24AlertTile from '../../molecules/tm/24-alert-tile';

const PageWrapper = styled(Main)`
    width: 100%;
    height: 100%;
    color: ${smoke};
`;

const StickyWrapper = styled.div`
    display: grid;
    position: relative;
    grid-template-areas: 'header'
        '.'
        'content'
        '.'
        'footer';
    /* stylelint-disable declaration-block-no-redundant-longhand-properties */
    grid-template-rows: min-content minmax(40px, 1fr) min-content 1fr auto;
    grid-template-columns: minmax(0, 1fr);
    /* stylelint-enable declaration-block-no-redundant-longhand-properties */
    height: 100%;
    min-height: 100vh;
`;

const Background = styled(SrcsetImg)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const StyledHeading = styled(Branding)`
    left: 50%;
    transform: translateX(-50%);
    margin: 0;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        transform: none;
        left: 21px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        left: 42px;
    `}
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    grid-area: content;
    align-items: center;
`;

const InstructionsContainer = styled.div`
    margin: 0 auto 29px;
    padding: 0 28px;
    max-width: 313px;
    text-align: center;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        padding: 0;
        max-width: 386px;
    `}
`;

const Subheader = styled.h2`
    margin: 0 0 7px;
    font: var(--nucleus-header-5);
`;

const InstructionsText = styled.p`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    font: var(--nucleus-header-5-light);
`;

const StyledFooter = styled(Footer)`
    display: flex;
    position: absolute;
    bottom: 0;
    flex-direction: column;
    grid-area: footer;
    align-items: center;
    justify-content: center;
    box-shadow: 0 -3px 9px 0 ${rgba(onyx, 0.4)};
    background-color: ${rgba(ink, 0.9)};
    width: 100%;

    ${stylesIfElse('hasContextualEducation')`
        padding: 14px 0 20px;
    ``
        padding: 20px 0;
    `}
`;

const ContextualEducationText = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
    width: 100%;
    font: var(--nucleus-body-copy-5-light);
`;

const FooterButtons = styled.div`
    display: grid;
    grid-template-columns: min-content;
    grid-gap: 7px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        grid-template-columns: min-content min-content;
    `}
`;

const CenteredLoader = styled(IC103Loading).attrs({
    size: '60px',
})`
    margin: 50px auto;
`;

const ProfileTemplate = ({
    bgSrcsetOptions,
    className,
    children,
    contextualEducationText,
    errorMessage,
    footerButtons,
    headerTitle,
    headerDescription,
    isLoading,
    onCancel,
    onKeyDown = noop,
    ...htmlAttributes
}) => (
    <PageWrapper
        {...htmlAttributes}
        aria-busy={isLoading}
        className={classnames(className, 'ProfileTemplate')}
        onKeyDown={(e) => {
            if (e.key === 'Escape' && !e.defaultPrevented) {
                onCancel(e);
            } else {
                onKeyDown(e);
            }
        }}
    >
        {!!bgSrcsetOptions && (
            <Background alt="" srcsetOptions={bgSrcsetOptions} />
        )}

        <StickyWrapper>
            <StyledHeading as={H1}>
                <GA92BrandLogo />
            </StyledHeading>

            <ContentWrapper>
                <InstructionsContainer>
                    {!!headerTitle && <Subheader>{headerTitle}</Subheader>}
                    {!!headerDescription && <InstructionsText>{headerDescription}</InstructionsText>}
                </InstructionsContainer>
                {isLoading ? <CenteredLoader id="profile-loading" /> : children}
                {!!errorMessage && (
                    <TM24AlertTile>
                        {errorMessage}
                    </TM24AlertTile>
                )}
            </ContentWrapper>

            {!!(contextualEducationText || footerButtons) && (
                <StyledFooter hasContextualEducation={!!contextualEducationText}>
                    {!!contextualEducationText && (
                        <ContextualEducationText>{contextualEducationText}</ContextualEducationText>
                    )}

                    {!!footerButtons && (
                        <FooterButtons>{footerButtons}</FooterButtons>
                    )}
                </StyledFooter>
            )}
        </StickyWrapper>
    </PageWrapper>
);

ProfileTemplate.propTypes = {
    /** srcset for the page background image */
    bgSrcsetOptions: srcsetOptionsType,
    /** Optional classnames */
    className: classNameType,
    /** Content node */
    children: propTypes.node,
    /** Configurable copy to display in the footer */
    contextualEducationText: propTypes.node,
    /** Optional error message feedback for the user */
    errorMessage: propTypes.string,
    /** Action buttons  */
    footerButtons: propTypes.node,
    /** Configurable copy for header title */
    headerTitle: propTypes.node,
    /** Configurable copy for header description */
    headerDescription: propTypes.node,
    /** Is the template in a loading state */
    isLoading: propTypes.bool,
    /** Callback on the Escape key */
    onCancel: propTypes.func,
    /** Callback on any key except the Escape key */
    onKeyDown: propTypes.func,
};

ProfileTemplate.displayName = 'ProfileTemplate';

export default ProfileTemplate;
