import React from 'react';
import propTypes from 'prop-types';
import noop from 'lodash/noop';
import property from 'lodash/property';
import styled from 'styled-components';

import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {font} from '../../../../common/typography';
import {slate, white} from '../../../../common/palette';
import handleFormSubmit from '../../../../common/get-handle-form-submit';

import BA01BtnPr from '../../../atoms/ba/01-btn-pr';
import IC103Loading from '../../../atoms/ic/103-loading';
import IM01Input from '../../../molecules/im/01-input';

const LoginFormOverlay = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

const Form = styled.form`
    padding: 28px;
    width: 100%;
    max-width: 560px;
    text-align: center;
`;

const FormFieldContainer = styled.div`
    margin-right: auto;
    margin-left: auto;
    padding: 8px 0;
    max-width: 320px;
    ${stylesWhen('textAlign')`
        text-align: ${property('textAlign')};
    `}
`;

const Header = styled.h1`
    color: ${white};
    font: ${font};
    line-height: 1.18; /* stylelint-disable-line order/properties-order */
    font-size: 34px;
    font-weight: 600;
`;

const InfoText = styled.p`
    color: ${white};
    font: ${font};
    line-height: 1.33; /* stylelint-disable-line order/properties-order */
    font-size: 18px;
    font-weight: 300;
`;

const StyledLink = styled.a`
    background-color: transparent;
    text-decoration: underline;
    color: ${slate};
    font: var(--mui-body-copy-3);
`;

const OR59ForgotPw = ({
    campaign = 'Kayo',
    errorMsg,
    href,
    name,
    onClick,
    onSubmit = noop,
    isLoading,
    successMsg,
}) => (
    <LoginFormOverlay className="OR59ForgotPw">
        <Form
            onSubmit={handleFormSubmit(onSubmit, ['email'])}
            name={name}
            method="POST"
            // prevents form submission before js executes (see martian-web-server for implementation)
            data-disabled-form-submit="true"
        >
            <Header>Forgot your password</Header>

            {!successMsg && (
                <React.Fragment>
                    <FormFieldContainer>
                        <IM01Input type="email" label="Email Address" name="email" required={true} disabled={isLoading} />
                    </FormFieldContainer>

                    <FormFieldContainer>
                        {isLoading ? (
                            <IC103Loading size="47px" />
                        ) : (
                            <BA01BtnPr type="submit">Continue</BA01BtnPr>
                        )}
                    </FormFieldContainer>
                </React.Fragment>
            )}

            {!!(errorMsg || successMsg) && (
                <InfoText>
                    {errorMsg || successMsg}
                </InfoText>
            )}

            <StyledLink href={href} onClick={onClick}>
                Already have an account? Sign in to {campaign}
            </StyledLink>
        </Form>
    </LoginFormOverlay>
);

OR59ForgotPw.displayName = 'OR59ForgotPw';

OR59ForgotPw.propTypes = {
    /** Name of campaign e.g Martian */
    campaign: propTypes.string,
    /** If set, an error message to be displayed to the user */
    errorMsg: propTypes.string,
    /** Providing a href will render a link */
    href: propTypes.string,
    /** A custom HTML name for the form */
    name: propTypes.string,
    /** callback to handle clicking signin */
    onClick: propTypes.func,
    /** A callback to handle submission of the form data */
    onSubmit: propTypes.func,
    /** Whether or not the form is in a loading state */
    isLoading: propTypes.bool,
    /** Success msg e.g 'We have sent you an email to reset your password */
    successMsg: propTypes.string,
};

export default OR59ForgotPw;
