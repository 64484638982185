import React from 'react';
import styled from 'styled-components';
import {Svg} from 'normalized-styled-components';

const StyledSvg = styled(Svg)`
    display: block;
    width: auto;
    height: 100%; /* Scaled by HEIGHT :O */
`;

const GA114ParentalControlsMA = ({
    ...htmlAttributes
}) => (
    <StyledSvg
        {...htmlAttributes}
        viewBox="0 0 69 58"
    >
        <path fill="red" d="M0 0h69v58H0z" />
        <path fill="#FFF" d="M48.902 35v-9.743L47 27.064v-1.257L48.902 24H50v11zm8.163-.852c-.468.542-1.169.852-2.104.852-1.013 0-1.714-.31-2.26-.775-.467-.542-.701-1.162-.701-1.936h1.09c.157 1.084.702 1.704 1.871 1.704.468 0 1.013-.155 1.325-.465.545-.62.623-1.472.623-2.246 0-1.317-.312-2.634-1.87-2.634-.935 0-1.636.387-1.792 1.084h-1.013V24h5.532v1.007h-4.52v3.408c.39-.464 1.092-.697 1.949-.697.779 0 1.48.233 1.87.62.78.852.935 1.937.935 2.944 0 1.084-.078 2.091-.935 2.866zm6.447-2.709V34h-1.024v-2.561H60v-.951h2.488V28h1.024v2.488H66v.951zM22.198 37v-8.757l-2.725 5.676h-1.946l-2.725-5.676V37H12V22h2.725l3.814 8.108L22.275 22H25v15zM32.54 26.378l-1.834 5.514h3.668l-1.834-5.514zM35.97 37l-.878-2.676h-5.184L29.031 37H26l5.344-15h2.233L39 37h-3.03z" />
        <path fill="#FFF" d="M35.079 15.942c.315.393 8.504 13.587 8.819 14.058-.315.471-8.504 13.586-8.82 14.058H14.922C14.685 43.586 6.417 30.47 6.102 30c.315-.471 8.583-13.665 8.82-14.058h20.157zm.315-.942H14.37L5 30l9.37 15h21.26L45 30l-9.37-15h-.236z" />
    </StyledSvg>
);

GA114ParentalControlsMA.displayName = 'GA114ParentalControlsMA';

export default GA114ParentalControlsMA;
