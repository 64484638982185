import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';

import {blanc, onyx} from '../../../../../common/palette';
import {formatSecondsIntoStandardLabel} from '../../../../../common/format-time';

const DisplayTime = styled.span`
    opacity: 0.6;
    text-shadow: 0 1px 1px ${rgba(onyx, 0.3)};
    white-space: nowrap;
    color: ${blanc};
    font: var(--nucleus-player-controls);
`;

const StyleTime = styled.time`
    font-variant-numeric: tabular-nums;
`;

const Divider = styled.span`
    color: ${blanc};
`;

const VPTM01DisplayTime = ({
    currentTime,
    duration,
    isLiveStream,
    isOnEdge,
    scrubberType,
}) => {
    switch (!!isLiveStream && scrubberType) {
        case 'none':
            return null;

        case 'from-edge':
            return (
                <DisplayTime role="timer">
                    {isOnEdge ? '0:00' : `-${formatSecondsIntoStandardLabel(duration - currentTime)}`}
                </DisplayTime>
            );

        default:
        case false:
        case 'default':
            return (
                <DisplayTime role="timer">
                    <StyleTime>
                        {formatSecondsIntoStandardLabel(currentTime)}
                    </StyleTime>
                    <Divider> / </Divider>
                    <StyleTime>
                        {formatSecondsIntoStandardLabel(duration)}
                    </StyleTime>
                </DisplayTime>
            );
    }
};

VPTM01DisplayTime.propTypes = {
    currentTime: propTypes.number,
    duration: propTypes.number,
    isLiveStream: propTypes.bool,
    isOnEdge: propTypes.bool,
    scrubberType: propTypes.string,
};

VPTM01DisplayTime.displayName = 'VPTM01DisplayTime';

export default VPTM01DisplayTime;
