import React, {useMemo} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Header, Article} from 'normalized-styled-components';
import {rgba} from 'polished';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {colorType} from '@fsa-streamotion/custom-prop-types';

import XX03UpNextHero from '../03-up-next-hero';
import OR60SeaEpi from '../../or/60-sea-epi';
import {SCREEN_1024_DESKTOP} from '../../../../common/screen-sizes';
import theme, {themeColorCssVariables} from '../../../../common/theme-color';
import TM04Ft from '../../../molecules/tm/04-ft';
import {panther} from '../../../../common/palette';
import {UP_NEXT_SMALL_VIDEO_PLAYER_MIN_HEIGHT_PX} from './up-next-common';

const Z_INDEX_BELOW_CONTENT = 0;
const Z_INDEX_BELOW_ARTICLE = -1;

const StyledArticle = styled(Article)`
    display: block;
    position: relative;
    background: transparent;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    overflow: hidden;

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP, minHeightPx: UP_NEXT_SMALL_VIDEO_PLAYER_MIN_HEIGHT_PX})`
        background-color: ${theme.secondaryColor};
    `}

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        z-index: ${Z_INDEX_BELOW_ARTICLE};
        background-color: ${rgba(panther, 0.5)};
        width: 100%;
        height: 200vh;
        content: '';

        ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP, minHeightPx: UP_NEXT_SMALL_VIDEO_PLAYER_MIN_HEIGHT_PX})`
            display: none;
        `}
    }
`;

export const StyledHeader = styled(Header)`
    display: none;
    position: relative;
    z-index: ${Z_INDEX_BELOW_CONTENT};
    margin-bottom: -50vw;
    width: 100%;
    height: 150vw; /* source image is in ratio of 1:1.79 - this will ensure we never overstretch the image */

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP, minHeightPx: UP_NEXT_SMALL_VIDEO_PLAYER_MIN_HEIGHT_PX})`
        display: block;
        min-height: 50vw;
        height: 56.25vw; /* source image is in ratio of 100:66 */
        margin-bottom: -20vw;
        max-height: calc(100vh - 500px);
    `}
`;

const StyledOR60SeaEpi = styled(OR60SeaEpi)`
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: none;
`;

export default function XX02UpNext({
    backgroundImage,
    carouselTitle,
    children,
    colorPrimary,
    colorSecondary,
    countdownCopy,
    title,
    ...htmlAttributes
}) {
    const themeCssVars = useMemo(function calculateThemeCssVars() {
        return themeColorCssVariables({colorPrimary, colorSecondary});
    }, [colorPrimary, colorSecondary]);

    return (
        <StyledArticle {...htmlAttributes} style={themeCssVars}>
            <StyledHeader>
                <XX03UpNextHero
                    backgroundImage={backgroundImage}
                    gradientColor={colorSecondary}
                >
                    <TM04Ft
                        color={colorPrimary}
                        title={title}
                        isPreview={true}
                    />
                </XX03UpNextHero>
            </StyledHeader>

            {!!React.Children.count(children) && (
                <StyledOR60SeaEpi
                    title={countdownCopy
                        ? (
                            <React.Fragment>
                                {carouselTitle}
                                {!!countdownCopy && <small role="timer">{countdownCopy}</small>}
                            </React.Fragment>
                        )
                        : carouselTitle}
                >
                    {children}
                </StyledOR60SeaEpi>
            )}
        </StyledArticle>
    );
}

XX02UpNext.displayName = 'XX02UpNext';

XX02UpNext.propTypes = {
    /** Mobile background image */
    backgroundImage: propTypes.any,
    /** Carousel label */
    carouselTitle: propTypes.string,
    /** Carousel content item(s) */
    children: propTypes.node,
    /** Primary colour, used for accents */
    colorPrimary: colorType,
    /** Secondary colour, used gradients */
    colorSecondary: colorType,
    /** Copy describing how many seconds until "Up Next" video is applied */
    countdownCopy: propTypes.node,
    /** Title object, e.g. from XPAPI */
    title: propTypes.any,
};
