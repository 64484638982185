import React from 'react';
import styled from 'styled-components';
import {Svg} from 'normalized-styled-components';

const StyledSvg = styled(Svg)`
    display: block;
    width: auto;
    height: 100%; /* Scaled by HEIGHT :O */
`;

const GA113ParentalControlsR = ({
    ...htmlAttributes
}) => (
    <StyledSvg
        {...htmlAttributes}
        viewBox="0 0 69 58"
    >
        <path fill="#000" d="M0 0h69v58H0z" />
        <path fill="#FFF" d="M35.344 22H32v6h3.344C37.016 28 38 26.8 38 25s-.984-3-2.656-3zm3.146 18l-3.882-8.333h-2.727V40H28V19h7.762c3.986 0 6.399 2.889 6.399 6.444 0 2.89-1.678 4.778-3.671 5.445L43 40h-4.51z" />
        <path fill="#FFF" d="M34.5 10L54 29.5 34.5 49 15 29.5 34.5 10zm-.495-1.559L13 29.5 34.555 51 56 29.5 34.555 8l-.55.441z" />
    </StyledSvg>
);

GA113ParentalControlsR.displayName = 'GA113ParentalControlsR';

export default GA113ParentalControlsR;
