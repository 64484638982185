import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import classnames from 'classnames';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {buttonType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {A, Button} from '../../../../common/normalized-styled-components';
import {black, ink, indigo, white} from '../../../../common/palette';
import {transition} from '../../../../common/animations';
import {SCREEN_768_TABLET} from '../../../../common/screen-sizes';
import GA43ArrowInd from '../../ga/43-arrow-ind';

const SubLabelWrapper = styled.span`
    flex: 0 0 auto;
    padding-right: 14px;
    text-align: right;
`;

const ArrowWrapper = styled.span`
    transition: ${transition('color')};
    margin-bottom: -2px;
`;

const LabelWrapper = styled.span`
    flex: 1 1 auto;
    overflow-x: hidden;
    white-space: nowrap;
    mask-image: linear-gradient(to left, transparent 7px, ${black} 35px);
`;

const baseStyle = css`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    transition: ${transition('color')};
    border: 0;
    padding: 7px 21px;
    width: 100%;
    height: 46px;
    color: ${ink};
    font: var(--quicksilver-body-copy-3);

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        height: 56px;
        padding: 7px 35px;
    `}

    &:hover:not([aria-current='true']):not([aria-pressed='true']):not(:active) {
        color: ${indigo};
    }

    /* stylelint-disable-next-line order/order */
    &:active,
    &[aria-pressed='true'],
    &[aria-current='true'] {
        background-color: ${indigo};
        color: ${white};
    }
`;

const StyledButton = styled(Button).attrs(({isPressed}) => ({
    'aria-pressed': isPressed,
}))`
    ${baseStyle}
    appearance: none;
    background: none;
    text-align: left;
`;

const StyledLink = styled(A).attrs(({isPressed}) => ({
    'aria-current': isPressed,
}))`
    ${baseStyle}
    text-decoration: none;
`;

/**
 *
 * A secondary navigation button component that can be a link or a button.
 *
 * @param {string} className - Additional CSS classnames to be applied.
 * @param {string} href - The hyperlink URL if the component is a link.
 * @param {boolean|'mixed'} isPressed - The aria-pressed attribute that describes a toggle button.
 * @param {string} label - The label of the button.
 * @param {Function} onClick - The callback function to be fired on button click.
 * @param {string} ref - The React ref, as passed in by React.forwardRef.
 * @param {string} type - The HTML button type.
 * @returns {JSX.Element} - The rendered component.
 */
const BA42NavBtnSec = React.forwardRef(({
    className,
    href,
    isPressed,
    label,
    subLabel,
    type = href ? undefined : 'button',
    onClick,
    ...htmlAttributes
}, ref) => {
    const Cta = href ? StyledLink : StyledButton;

    return (
        <Cta
            {...htmlAttributes}
            className={classnames('BA42NavBtnSec', className)}
            isPressed={isPressed}
            href={href}
            onClick={onClick}
            ref={ref}
            type={type}
        >
            <LabelWrapper>{label}</LabelWrapper>
            {!!subLabel && <SubLabelWrapper>{subLabel}</SubLabelWrapper>}
            <ArrowWrapper><GA43ArrowInd /></ArrowWrapper>
        </Cta>
    );
});

BA42NavBtnSec.propTypes = {
    /** additional CSS classnames to be applied */
    className: classNameType,
    /** hyperlink URL */
    href: propTypes.string,
    /** aria-pressed attribute describes a toggle button */
    isPressed: propTypes.oneOf([true, false, 'mixed']),
    /** label of the button */
    label: propTypes.string.isRequired,
    /** callback to fire on button click */
    onClick: propTypes.func,
    /** react ref, e.g. as passed in by React.forwardRef */
    ref: propTypes.string,
    /** sublabel of the button */
    subLabel: propTypes.string,
    /** html button type */
    type: buttonType,

};

BA42NavBtnSec.displayName = 'BA42NavBtnSec';

export default BA42NavBtnSec;
