import React, {useState, useEffect, useRef, Fragment} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isEqual from 'date-fns/isEqual';
import debounce from 'lodash/debounce';
import invoke from 'lodash/invoke';
import noop from 'lodash/noop';
import pick from 'lodash/pick';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {Section} from '../../../../common/normalized-styled-components';
import {white, flash} from '../../../../common/palette';
import {SCREEN_768_TABLET, SCREEN_1024_DESKTOP, SCREEN_1280_DESKTOP, SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import {CONTENT_EDGE_SPACING_PERCENT} from '../../../../common/style-constants';

import IC103Loading from '../../../atoms/ic/103-loading';
import GA20PixelDiv from '../../../atoms/ga/20-pixel-div';
import CAM05EPG, {CarouselTitleIconContainer} from '../../../molecules/cam/05-epg';
import OR69CatTab from '../69-cat-tab';
import TM13ShoCon from '../../../molecules/tm/13-sho-con';

import getTransmissionTimeDate from './get-transmission-time-date';

const SPINNER_SIZE_PX = 70;

const StyledSection = styled(Section)`
    position: relative;
    padding: 37px ${CONTENT_EDGE_SPACING_PERCENT}vw 0;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        padding-top: 28px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding-top: 35px;
    `}

    &[aria-busy='true'] {
        ::before {
            display: block;
            min-height: 350px;
            content: '';
        }
    }
`;

// applying a transform to this component interferes with the
// transform on the animation keyframes.
const StyledIC103Loading = styled(IC103Loading)`
    position: absolute;
    top: calc(50% - ${SPINNER_SIZE_PX / 2}px);
    left: calc(50% - ${SPINNER_SIZE_PX / 2}px);
`;

// I need a higher z-index because the CAM05EPG is overlapping this CatTab with negative margins + padding
const StyledOR69CatTab = styled(OR69CatTab)`
    z-index: 1; /* stylelint-disable-line scale-unlimited/declaration-strict-value */
    margin-right: 0;
    margin-bottom: 28px;
    margin-left: 0;
`;

const StyledCAM05EPG = styled(CAM05EPG)`
    margin: 0 -${CONTENT_EDGE_SPACING_PERCENT}vw;
    width: auto;
`;

const StackWrapper = styled.ol`
    margin: 0;
    padding: 0;
    list-style: none;
`;

const StackItem = styled.li`
    margin: 0 0 28px;
`;

const StackGroup = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
`;

const StackTitle = styled.li`
    margin: 19px 0;
    color: ${white};
    font: var(--infinity-header-8-bold);

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        padding: 0 ${CONTENT_EDGE_SPACING_PERCENT}%;
    `}
`;

/**
 * Group an array with data and title properties by the title. This ensures the array order of the previous list
 *
 * @param {Array} panelList An array of {data, title} items
 * @returns {Array} An array of {title, items: [{data,...}]} items
 */
function groupByTitle(panelList = []) {
    return panelList.reduce((acc, {title, data}) => {
        const existingTitleIndex = acc.findIndex(({title: existingTitle}) => existingTitle === title);

        if (existingTitleIndex > -1) {
            // title already exists, push the data into this object
            acc[existingTitleIndex].items.push(data);
        } else {
            // it’s a new title, push everything to the array
            acc.push({
                title,
                items: [data],
            });
        }

        return acc;
    }, []);
}

function OR60SeaEpi({
    breakpointPageSizes = {
        [SCREEN_1024_DESKTOP]: 3,
        [SCREEN_1280_DESKTOP]: 5,
    },
    className,
    contentItemToCarouselItemSrcsetTile = noop,
    getInteractions = noop,
    getPanelTitle = noop,
    initialStartIndex,
    loadingSpinnerId,
    maxSlidesPerPage,
    panelContents = [],
    parseInfoLineItem = noop,
    templateUrlToSrcsetOptions = noop,
    titleIcons = [],
    tabTitles = [],
    ...htmlAttributes
}) {
    const [currentView, setCurrentView] = useState('loading');
    const [visibleTabIndex, setVisibleTabIndex] = useState(0);
    const [startIndex, setStartIndex] = useState(initialStartIndex);
    const [currentTime, setCurrentTime] = useState(Date.now());
    const matchMedia = useRef(null);
    const hasChildren = !!panelContents.length;
    const isLoading = currentView === 'loading' || !hasChildren;

    // On componentDidMount, start polling to get the current timestamp
    useEffect(function getCurrentTime() {
        const pollCurrentTime = setInterval(() => void setCurrentTime(Date.now()), 1000);

        return () => void clearInterval(pollCurrentTime);
    }, []);

    // Stack view for mobile and tablets, carousel view for desktops and up
    useEffect(function updateCurrentViewAgainstMediaQuery() {
        matchMedia.current = window.matchMedia(`(min-width: ${SCREEN_1024_DESKTOP}px)`); // change orientation at this size
        setCurrentView(matchMedia.current.matches ? 'carousel' : 'stack'); // check synchronously

        const onMatchMedia = (event) => void setCurrentView(event.matches ? 'carousel' : 'stack');

        matchMedia.current.addListener(onMatchMedia); // then listen for async changes

        return () => matchMedia.current.removeListener(onMatchMedia); // unbind our listener on unmount
    }, []);

    // We figure out the first indices of the times where the panel is live, and the panel is in the future
    const startTimes = panelContents.map(getTransmissionTimeDate);

    const firstFutureIndex = startTimes.findIndex((transmissionTime) => isAfter(transmissionTime, currentTime));
    const firstLiveIndex = firstFutureIndex >= 0 ? firstFutureIndex - 1 : 0;

    // on mount, set the startIndex to what we found is the first Live item, if we haven't explicitly set a start index
    useEffect(function setDefaultStartIndexToFirstLiveItem() {
        if (!Number.isInteger(initialStartIndex) && firstLiveIndex >= 0) {
            setStartIndex(firstLiveIndex);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    //  If we can't find a future time, then set it to the "beginning of time" to make the following conditional statement false
    const futureTime = firstFutureIndex >= 0 ? startTimes[firstFutureIndex] : new Date(0);
    const liveTime = firstLiveIndex >= 0 ? startTimes[firstLiveIndex] : currentTime;

    // Pick through the data we need, and return an array of React elements
    const massagedPanelContents = panelContents
        .map(({data = {}, links}, index) => {
            const {
                images = {},
                infoLine,
                synopsis = '',
                title = {},
            } = pick(data.contentDisplay, ['editorialLabel', 'images', 'infoLine', 'synopsis', 'title']);

            let panelTitle = getPanelTitle('past');
            const timestampDate = new Date(data?.clickthrough?.transmissionTime);

            if (isEqual(timestampDate, liveTime) || (isAfter(timestampDate, liveTime) && isBefore(timestampDate, futureTime))) {
                panelTitle = getPanelTitle('live');
            } else if (isEqual(timestampDate, futureTime) || isAfter(timestampDate, futureTime)) {
                panelTitle = getPanelTitle('future');
            }

            const {href, onClick} = getInteractions({
                contentDisplay: data.contentDisplay,
                clickthrough: data.clickthrough,
                links,
                playback: data.playback,
                type: data.type,
                carouselPositionX: index,
            }) || {};

            const infoLineItems = infoLine
                .filter(({value}) => Boolean(value))
                .map((infoLineItem) => ({
                    type: infoLineItem.type,
                    value: parseInfoLineItem(infoLineItem, currentTime),
                }));

            const Tile = (
                <TM13ShoCon
                    badgeSrcsetOptions={templateUrlToSrcsetOptions({
                        templateUrl: images?.badge,
                    })}
                    defaultImageSrc={invoke(images, 'tile.replace', 'imwidth=${WIDTH}', 'imwidth=415') /* eslint-disable-line no-template-curly-in-string */}
                    description={synopsis}
                    href={href}
                    imageCaption={title.value}
                    infoLine={infoLineItems}
                    key={data?.clickthrough?.url || title.value || index}
                    onClick={(event) => onClick({event})}
                    srcsetOptions={contentItemToCarouselItemSrcsetTile(data)}
                    title={title.value}
                />
            );

            return ({
                data: Tile,
                title: panelTitle,
            });
        });

    // Massage further by grouping the data that have a common title
    const carouselData = groupByTitle(massagedPanelContents);

    // Set up the data for the tab menu, hardcoded at the moment to just two
    // If we wanted a way to have multiple tabs based off API data, we’d need some more information as to the start and end indices belonging to each tab.
    const tabMenuItems = [
        {
            label: tabTitles[0],
            value: {
                selectedTabIndex: 0,
                startIndex: firstLiveIndex,
                endIndex: massagedPanelContents.length,
            },
        },
        {
            label: tabTitles[1],
            value: {
                selectedTabIndex: 1,
                startIndex: 0,
                endIndex: firstLiveIndex,
            },
        },
    ];

    // Slice up the panelContents into chunks that correspond to the tab menu data
    const tabbedCarouselData = firstLiveIndex > 0
        ? tabMenuItems
            .map(({value}) => (
                groupByTitle(massagedPanelContents.slice(
                    value.startIndex,
                    value.endIndex,
                ))
            ))
        : [];

    const stackedCarouselData = tabbedCarouselData[visibleTabIndex] || carouselData;

    const onScrollCarouselRef = useRef();

    useEffect(function createDebouncedOnScrollCarousel() {
        if (currentView === 'stack') {
            onScrollCarouselRef.current = undefined;

            return;
        }

        onScrollCarouselRef.current = debounce(function onScrollCarousel({event, childrenOffsetLefts}) {
            const currentPosition = Math.floor(event.target.scrollLeft + 50); // add a bit of extra so currentPosition has some buffer, to prevent off-by-one issues. The min width of a tile is ~300, so there's no danger of being too far ahead

            const currentScrolledIndex = childrenOffsetLefts.findIndex((offset, index) => {
                const nextIndex = Math.min(index + 1, childrenOffsetLefts.length - 1);

                // getClientRects()[0].left can contain negative values, because it's based on distance to viewport edge. I want the first item to "be 0" so we normalise the values here
                const normalisedOffset = Math.floor(offset - childrenOffsetLefts[0]);
                const normalisedNextOffset = Math.floor(childrenOffsetLefts[nextIndex] - childrenOffsetLefts[0]);

                return (
                    currentPosition > normalisedOffset
                    && currentPosition <= normalisedNextOffset
                );
            });

            // update the startIndex of the carousel
            setStartIndex(currentScrolledIndex);

            // update the currently selected tab
            if (currentScrolledIndex >= firstLiveIndex) {
                setVisibleTabIndex(0);
            } else {
                setVisibleTabIndex(1);
            }
        }, 500);

        return () => onScrollCarouselRef.current.cancel(); // cleanup pending debounce on unmount, or when currentView goes from 'stack' to 'carousel'
    }, [currentView, firstLiveIndex]);

    return (
        <StyledSection
            {...htmlAttributes}
            aria-busy={isLoading}
            className={classnames('OR60SeaEpi', className)}
        >
            {!!isLoading && (
                <StyledIC103Loading id={loadingSpinnerId} color={flash} size={`${SPINNER_SIZE_PX}px`} />
            )}
            {!isLoading && !!tabMenuItems.length && firstLiveIndex > 0 && (
                <StyledOR69CatTab
                    items={tabMenuItems}
                    selectedItemIndex={visibleTabIndex}
                    onSelectItem={({value}) => {
                        // set which tab is selected
                        setVisibleTabIndex(value.selectedTabIndex);

                        // update the startIndex of the carousel
                        setStartIndex(value.startIndex);
                    }}
                />
            )}
            {currentView === 'stack' && (
                <StackWrapper>
                    {stackedCarouselData.map(({title, items}, panelIndex) => {
                        const titleIconsMatchingIndex = titleIcons.findIndex(({key}) => key === title);

                        const titleToRender = titleIconsMatchingIndex > -1
                            ? (
                                <CarouselTitleIconContainer icon={titleIcons[titleIconsMatchingIndex]?.icon}>
                                    {title}
                                </CarouselTitleIconContainer>
                            )
                            : title;

                        return (
                            <Fragment key={panelIndex}>
                                <StackGroup
                                    key={panelIndex}
                                >
                                    <StackTitle>{titleToRender}</StackTitle>

                                    {items.map((slide, index) => (
                                        <StackItem key={`panel-${panelIndex}-slide-${index}`} >
                                            {slide}
                                        </StackItem>
                                    ))}
                                </StackGroup>
                                {panelIndex < stackedCarouselData.length - 1 && <GA20PixelDiv />}
                            </Fragment>
                        );
                    })}
                </StackWrapper>
            )}
            {currentView === 'carousel' && (
                <Fragment>
                    <StyledCAM05EPG
                        breakpointPageSizes={breakpointPageSizes}
                        isPipIndicatorHidden={true}
                        carouselData={carouselData}
                        maxSlidesPerPage={maxSlidesPerPage}
                        onScrollCarousel={onScrollCarouselRef.current}
                        onClickNavButtons={function checkCurrentSlotIndexAgainstTabs({slotIndex}) {
                            // update the startIndex with every nav press, so that CAM05EPG's "move the carousel if startIndex changes" on componentDidUpdate will actually work when the tabs are clicked
                            setStartIndex(slotIndex);

                            // when slotIndex changes, check if we've "crossed state lines"--travelled into another tab's territory
                            if (slotIndex >= firstLiveIndex) {
                                setVisibleTabIndex(0);
                            } else {
                                setVisibleTabIndex(1);
                            }
                        }}
                        startIndex={startIndex}
                        titleIcons={titleIcons}
                    />
                    <GA20PixelDiv />
                </Fragment>
            )}
        </StyledSection>
    );
}

OR60SeaEpi.displayName = 'OR60SeaEpi';

OR60SeaEpi.propTypes = {
    /** Definitions for how large page size should be at different breakpoints (for carousel view) */
    breakpointPageSizes: propTypes.objectOf(propTypes.number),
    /** An array of items containing an element with an associated title */
    panelContents: propTypes.arrayOf(propTypes.any),
    /** CSS classname to apply (e.g. for styled-components restyling) */
    className: classNameType,
    /** initial position for carousel */
    initialStartIndex: propTypes.number,
    /** Loading Component ID */
    loadingSpinnerId: propTypes.string,
    /** For lazy evaluation purposes, what is the maximum slides that could appear on initial render? Use -1 to force eager loading */
    maxSlidesPerPage: propTypes.number,
    /** Function to help convert a URL to srcset options */
    templateUrlToSrcsetOptions: propTypes.func,
    /** Function to convert an API object to carousel srcset tile */
    contentItemToCarouselItemSrcsetTile: propTypes.func,
    /** Functon to convert API data to usable href or onClick */
    getInteractions: propTypes.func,
    /** Function to massage the info line data */
    parseInfoLineItem: propTypes.func,
    /** Function to retrieve the panel title */
    getPanelTitle: propTypes.func,
    /** An object with the titles set as the keys, where it contains an object with at least an icon property */
    titleIcons: propTypes.arrayOf(propTypes.shape({
        key: propTypes.any,
        icon: propTypes.node,
    })),
    /** Array of titles for the tab items */
    tabTitles: propTypes.arrayOf(propTypes.node),
};

export default OR60SeaEpi;
