import React from 'react';
import Ic from '..';

const IC22Float = (args) => (
    <Ic {...args}>
        <path d="M69.2 31.6H18.5c-1.5 0-2.8 1.2-2.8 2.8v8H9.1c-.8 0-1.4.6-1.4 1.4v37.7c0 .8.6 1.4 1.4 1.4h52.1c.8 0 1.4-.6 1.4-1.4v-6.6h6.6c1.5 0 2.8-1.2 2.8-2.8V34.4c0-1.5-1.3-2.8-2.8-2.8zm-9.4 48.5H10.5V45.2h5.2v26.9c0 1.5 1.2 2.8 2.8 2.8h41.4v5.2h-.1zm6.6-10.8H21.2V37.2h45.2v32.1zM88.4 12.5l-3.2 8.9C83.2 13 74.8 6.6 64.8 6.6c-11.4 0-20.7 8.4-20.7 18.6h5.6c0-7.2 6.8-13.1 15.2-13.1 7.7 0 14 4.9 15 11.3l-8.4-3.1-1.5 4 14.4 5.2c.2.1.5.1.7.1.3 0 .6-.1.9-.2.5-.2.9-.7 1.1-1.2l5.2-14.4-3.9-1.3z" />
    </Ic>
);

IC22Float.displayName = 'IC22Float';

export default IC22Float;
