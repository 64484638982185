import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {Button} from '../../../../common/normalized-styled-components';
import {buttonActiveState, flash, white} from '../../../../common/palette';
import IC49Open from '../../ic/49-open';

const StyledButton = styled(Button).attrs({
    type: 'button',
})`
    appearance: none;
    transition: ${transition('background-color')};
    border: none;
    background-color: ${rgba(white, 0.2)};
    padding: 21px;
    width: 106px;

    &:hover,
    &:focus {
        background-color: ${flash};
    }

    &:active {
        background-color: ${buttonActiveState};
    }

    &:focus {
        outline: 0;
    }
`;

const BA05AddBtn = React.forwardRef(({
    'aria-label': assistiveLabel = 'Add',
    className,
    ...htmlAttributes
}, ref) => (
    <StyledButton
        {...htmlAttributes}
        ref={ref}
        title={assistiveLabel}
        aria-label={assistiveLabel}
        className={classnames('BA05AddBtn', className)}
    >
        <IC49Open color={white} />
    </StyledButton>
));

/* eslint-disable quote-props */
BA05AddBtn.propTypes = {
    /** Assistive label required for screen readers due to graphical only representation of content */
    'aria-label': propTypes.string,
    /** Additional CSS class(es) */
    className: classNameType,
};
/* eslint-enable quote-props */

BA05AddBtn.displayName = 'BA05AddBtn';

export default BA05AddBtn;
