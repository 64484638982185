import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import styled from 'styled-components';
import property from 'lodash/property';
import {Progress} from 'normalized-styled-components';
import {readableColor} from 'polished';

import {stylesIfElse} from '@fsa-streamotion/styled-component-helpers';
import {colorType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {white} from '../../../../common/palette';
import {slate, ironStone, silverLining} from '../../../../common/deprecated-mui-palette';
import VisuallyHidden from '../../../../common/visually-hidden';

const ABOVE_PROGRESS_BAR_Z = 1;

const ContainerFigure = styled.figure`
    margin: 14px 0;
`;

const Title = styled.h3`
    margin: 0 0 7px;
    text-align: center;
    color: ${silverLining};
    font: var(--ionic-hud-tile-header);
`;

const Positioner = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    border-radius: 11px;
    height: 22px;
    overflow: hidden;
`;

const StyledProgress = styled(Progress)`
    appearance: none;
    transform: scale(1.01); /* working around a windows chrome bug, where there is a 1px unstylable outline on progress bars */
    border: 0;
    background-color: ${property('teamBColor')};
    width: 100%;
    height: 100%;

    ::-moz-progress-bar {
        background-color: ${property('teamAColor')};
    }

    ::-webkit-progress-value {
        background-color: ${property('teamAColor')};
    }

    ::-webkit-progress-bar {
        background-color: inherit;
    }

    ::-ms-fill {
        border: none;
        background-color: ${property('teamAColor')};
    }
`;

const PercentageString = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    z-index: ${ABOVE_PROGRESS_BAR_Z}; /* working around a windows chrome bug, where there is a 1px unstylable outline on progress bars */
    background-color: ${property('teamColor')};
    height: 100%;
    text-shadow: 0 1px 1px ${getContrastingTextShadowColor};
    color: ${getContrastingTextColor};
    font: var(--ionic-hud-tile-copy-bold);

    ${stylesIfElse('isLeft')`
        padding-right: 4px;
        padding-left: 14px;
    ``
        padding-right: 14px;
        padding-left: 4px;
    `}
`;

const OR20HdToHd = ({
    title,
    teamAName,
    teamBName,
    teamAColor = slate,
    teamBColor = ironStone,
    teamAValue = 50,
    teamBValue = 50,
    className,
}) => {
    const teamAPercentage = Math.round((100 * teamAValue) / (teamAValue + teamBValue));
    const teamBPercentage = 100 - teamAPercentage;

    return (
        <ContainerFigure className={classnames(className, 'OR20HdToHd')}>
            <Title>{title}</Title>
            <Positioner>
                <PercentageString
                    isLeft={true}
                    teamColor={teamAColor}
                >
                    {teamAPercentage}%
                </PercentageString>
                <StyledProgress
                    value={teamAValue}
                    max={teamAValue + teamBValue}
                    teamAColor={teamAColor}
                    teamBColor={teamBColor}
                />
                <PercentageString
                    isLeft={false}
                    teamColor={teamBColor}
                >
                    {teamBPercentage}%
                </PercentageString>
            </Positioner>
            <VisuallyHidden as="figcaption">
                {title}: {teamAName} {teamAPercentage}%, {teamBName} {teamBPercentage}%
            </VisuallyHidden>
        </ContainerFigure>
    );
};

OR20HdToHd.propTypes = {
    /** Title for the stat, e.g. Possession */
    title: propTypes.string,
    /** Team A Name, for accessibility */
    teamAName: propTypes.string,
    /** Team B Name, for accessibility */
    teamBName: propTypes.string,
    /** Color for the left side of the progress bar */
    teamAColor: colorType,
    /** Color for the teamB side of the progress bar */
    teamBColor: colorType,
    /** Value for the left side of the progress bar */
    teamAValue: propTypes.number,
    /** Value for the teamB side of the progress bar */
    teamBValue: propTypes.number,
    /** Optional extra class name(s) for the component */
    className: classNameType,
};

OR20HdToHd.displayName = 'OR20HdToHd';

export default OR20HdToHd;

function getContrastingTextColor({teamColor}) {
    return readableColor(teamColor, ironStone, white);
}

function getContrastingTextShadowColor({teamColor}) {
    return readableColor(teamColor, white, ironStone);
}
