import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {palette as ionicPalette} from '@fsa-streamotion/streamotion-web-ionic';
import {palette as muiPalette} from '@fsa-streamotion/streamotion-web-mui';
import {BrandedNM10Footer} from '../../../../utils/branded-components';
import {stylesWhenKayo} from '../../../../utils/styles-when-brand';

import SocialMediaFooter from '../social-footer';
import prependQueryString from '../../../../../../todo-move-to-widgets-common/utils/prepend-query-string';

const {white} = ionicPalette;
const {midnight} = muiPalette;

const BrandedFooterContainer = styled.div`
    display: grid;
    position: relative;
    align-items: center;
    box-shadow: 0 4px 4px 0 rgb(0, 0, 0, 0.2);
    background-color: ${white};

    ${stylesWhenKayo`
        background-color: ${midnight};
        box-shadow: 0 4px 4px 0 #000;
    `}
`;

const Footer = ({
    brand,
    legalCopy,
    links,
    socialMedia,
    userId,
    year = new Date().getFullYear(),
}) => (
    <React.Fragment>
        <BrandedFooterContainer brand={brand}>
            <BrandedNM10Footer
                brand={brand}
                legalCopy={legalCopy}
                links={getFooterLinks(links, userId)}
                year={year}
            />
        </BrandedFooterContainer>
        <SocialMediaFooter socialMedia={socialMedia} brand={brand} userId={userId} />
    </React.Fragment>
);

Footer.displayName = 'OfferFooter';

Footer.propTypes = {
    /** brand name */
    brand: propTypes.string,
    /** Legal copy blurb */
    legalCopy: propTypes.string,
    /** Links to T&C, Privacy, etc */
    links: propTypes.arrayOf(propTypes.object),
    /** list of social media links like Facebook, Instagram etc */
    socialMedia: propTypes.arrayOf(propTypes.shape({
        title: propTypes.string,
        href: propTypes.string,
        icon: propTypes.string,
    })),
    /** user id */
    userId: propTypes.string,
    /** Copyright year */
    year: propTypes.number,
};

/**
 * Get a list of footer links with title and href.
 *
 * @param {Array}  links         - links from marketing config (if any)
 * @param {string} userId       - auth0 id to be prepended to query params
 *
 * @returns {Array} list of footer links with title, href, target and rel.
 */
function getFooterLinks(links, userId) {
    const parameters = userId ? {userId} : {};
    const commonAttrs = {target: '_blank', rel: 'noopener noreferrer'};
    const titlesUrlsEntries = Array.isArray(links) ? links.map(Object.values) : [];

    return titlesUrlsEntries.map(([title, url]) => ({
        href: prependQueryString({url, parameters}),
        title,
        ...commonAttrs,
    }));
}

export default Footer;
