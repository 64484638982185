import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {blanc, onyx} from '../../../../../common/palette';
import {SCREEN_1024_DESKTOP, SCREEN_1280_DESKTOP} from '../../../../../common/screen-sizes';

const LinesContainer = styled.div`
    position: relative;
    padding-left: 25px;
    text-shadow: 0 1px 1px ${rgba(onyx, 0.3)};
    color: ${blanc};
    font: var(--nucleus-body-copy-2-light);

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        padding-left: 49px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        padding-left: 56px;
    `}

    &::before {
        position: absolute;
        top: 4px;
        bottom: 8px;
        left: 0;
        background-color: ${blanc};
        width: 4px;
        content: '';
    }
`;

const TextLine = styled.p`
    margin: 0;
`;

const BolderTextLine = styled(TextLine)`
    font-weight: 500;
`;

export default function VPTM07Classification({
    lines = [],
    className,
    ...htmlAttributes
}) {
    return (
        <LinesContainer className={classnames('VPTM07Classification', className)} {...htmlAttributes}>
            {lines.map(({type, value}, index) => {
                const Component = type === 'rating' ? BolderTextLine : TextLine;

                return (
                    <Component key={index}>
                        {value}
                    </Component>
                );
            })}
        </LinesContainer>
    );
}

VPTM07Classification.displayName = 'VPTM07Classification';

VPTM07Classification.propTypes = {
    lines: propTypes.arrayOf(propTypes.shape({
        type: propTypes.oneOf(['rating', 'warning']),
        value: propTypes.node,
    })),
    className: classNameType,
};
