import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import property from 'lodash/property';
import {rgba} from 'polished';

import {Progress} from '../../../../common/normalized-styled-components';
import {white} from '../../../../common/palette';
import themeColor from '../../../../common/theme-color';

const {theme} = themeColor;

const StyledProgress = styled(Progress)`
    appearance: none;
    display: block;
    border: 0;
    background-color: ${rgba(white, 0.2)};
    height: 4px;

    &::-moz-progress-bar {
        background-color: ${property('progressBarColor')};
    }

    &::-webkit-progress-value {
        background-color: ${property('progressBarColor')};
    }

    &::-webkit-progress-bar {
        background-color: inherit;
    }

    &::-ms-fill {
        border: none;
        background-color: ${property('progressBarColor')};
    }
`;

const GA01ProBar = ({
    current = 0,
    total = 100,
    isThemed,
    ...htmlAttributes
}) => (
    <StyledProgress
        {...htmlAttributes}
        value={current}
        max={total}
        progressBarColor={isThemed ? theme.primaryColor : white}
    />
);

GA01ProBar.propTypes = {
    /** Current value of progress bar, e.g. elapsed time */
    current: propTypes.number,
    /** Total value of progress bar, e.g. video duration */
    total: propTypes.number,
    /** Is global theme to be applied */
    isThemed: propTypes.bool,
};

GA01ProBar.displayName = 'GA01ProBar';

export default GA01ProBar;
