import React, {useState} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {fadeInOut, transition} from '../../../../../common/animations';

import {ink, cobalt} from '../../../../../common/palette';
import {SCREEN_1920_DESKTOP, SCREEN_375_PHABLET, SCREEN_768_TABLET} from '../../../../../common/screen-sizes';

import BA28BtnSec from '../../../../atoms/ba/28-btn-sec';
import IM04Code from '../../../../molecules/im/04-code';
import IC103Loading from '../../../../atoms/ic/103-loading';
import {IC43Valid} from '../../../../atoms/ic';

import {ButtonIconBox, StyledIC103Loading} from './common';

/** ********************************************
 *                  WRAPPER
 ***********************************************/

const Container = styled.div`
    display: grid;
    grid-row-gap: 21px;
    justify-items: center;
    transition: ${transition('opacity')};
    text-align: center;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        grid-row-gap: 35px;
    `}

    ${fadeInOut}

    &[disabled] {
        opacity: 0.35;
    }
`;

/** ********************************************
 *   TOP SECTION: Code Input and text helpers
 ***********************************************/

const InfoBox = styled.div`
    max-width: 250px;
    color: ${ink};
    font: var(--quicksilver-body-copy-2-light);

    ${mediaQuery({minWidthPx: SCREEN_375_PHABLET})`
        max-width: 280px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        max-width: 290px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        max-width: 445px;
    `}
`;

const MobileNumber = styled.span`
    white-space: nowrap;
    font: var(--quicksilver-body-copy-2-medium);
`;

const InputContainer = styled.div`
    display: inline-block;
    position: relative;
`;

/** ********************************************
 *   BOTTOM SECTION: Status & CTA buttons
 ***********************************************/

// STATUS

const IconPositioner = styled.span`
    display: grid;
    align-self: center;
    margin: 7px 0;
`;

const SmallHelpText = styled.p`
    margin: 0;
    color: ${ink};
    font: var(--quicksilver-header-7-medium);
`;

const StyledIC43Valid = styled(IC43Valid)`
    display: inline-block;
    margin-bottom: -3px;
    margin-left: 10px;
`;

// CTA BUTTONS

const ButtonsContainer = styled.div`
    display: grid;
    grid-template-columns: 250px;
    grid-row-gap: 7px;
    align-items: center;
    justify-items: center;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        grid-template-columns: 400px;
        grid-row-gap: 14px;
    `}
`;

const getVerificationStatus = ({
    isSuccessful,
    isVerificationLoading,
}) => {
    if (isSuccessful) {
        return (
            <SmallHelpText>
                Mobile verified
                <StyledIC43Valid size="22px" color={cobalt} />
            </SmallHelpText>
        );
    } else if (isVerificationLoading) {
        return <IC103Loading size="22px" />;
    } else {
        return null;
    }
};
/* * ********************************************
 *   Main
 ***********************************************/

const VerificationContainer = ({
    errorMessage,
    hasMobileNumberInMainInfoText,
    isCodeResetOnErrorEnabled,
    isCodeResetOnResendEnabled,
    isEditingMobileNumber,
    isResendLoading,
    isSuccessful,
    isUpdateMobileEnabled,
    isVerificationLoading,
    lowerHelpText,
    mainInfoText,
    mobileNumber,
    codeLength,
    onCancelClick,
    onClickEditMobileNumber,
    onComplete,
    onResendClick,
    verificationCode,
}) => {
    const disableInteraction = isResendLoading || isVerificationLoading;
    const [resetAttemptCount, setResetAttemptCount] = useState(0);

    // NOTE:
    // - every time the counter increments, we clear the code input fields
    // - it's important to enable clearing when we request for new code to be sent
    //   because auto-complete will only work if the input fields are empty
    const resendAndReset = () => {
        onResendClick();
        // Reference: https://react.dev/reference/react/useState#updating-state-based-on-the-previous-state
        setResetAttemptCount((prevResetAttemptCount) => prevResetAttemptCount + 1);
    };

    return (
        <Container disabled={isUpdateMobileEnabled ? isEditingMobileNumber : false} >

            {!!mainInfoText && (
                <InfoBox>
                    {mainInfoText} {!!hasMobileNumberInMainInfoText && <MobileNumber>{mobileNumber}</MobileNumber>}
                </InfoBox>
            )}

            {!!lowerHelpText && (
                <InfoBox>
                    {lowerHelpText}
                </InfoBox>
            )}

            <InputContainer>
                <IM04Code
                    code={verificationCode}
                    codeLength={codeLength}
                    contextualHelpText={(!!errorMessage && !isVerificationLoading) ? errorMessage : undefined}
                    disabled={disableInteraction || isSuccessful}
                    hasError={!!errorMessage}
                    isCodeResetOnErrorEnabled={isCodeResetOnErrorEnabled}
                    isOtp={true}
                    onComplete={onComplete}
                    resetAttemptCount={resetAttemptCount}
                />
            </InputContainer>

            {isSuccessful || isVerificationLoading ? (
                <IconPositioner>
                    {getVerificationStatus({isSuccessful, isVerificationLoading})}
                </IconPositioner>
            ) : (
                <ButtonsContainer>
                    <BA28BtnSec
                        disabled={disableInteraction}
                        isBlock={true}
                        onClick={isCodeResetOnResendEnabled ? resendAndReset : onResendClick}
                    >
                        {isResendLoading ? (
                            <ButtonIconBox>
                                <StyledIC103Loading />
                            </ButtonIconBox>
                        ) : 'Resend Code'}
                    </BA28BtnSec>

                    {isUpdateMobileEnabled
                        ? (
                            <BA28BtnSec
                                disabled={disableInteraction}
                                isBlock={true}
                                onClick={onClickEditMobileNumber}
                            >
                                Update Mobile
                            </BA28BtnSec>
                        )
                        : (
                            <BA28BtnSec
                                disabled={disableInteraction}
                                isBlock={true}
                                onClick={onCancelClick}
                            >
                                Cancel
                            </BA28BtnSec>
                        )}
                </ButtonsContainer>
            )}
        </Container>
    );
};

VerificationContainer.propTypes = {
    /** The length of a valid verification code */
    codeLength: propTypes.number,
    /** Append mobile number to main info message */
    hasMobileNumberInMainInfoText: propTypes.bool,
    /** An optional error message to denote an incorrect verification code */
    errorMessage: propTypes.node,
    /** Whether or not we should clear the code when we're in an error state' */
    isCodeResetOnErrorEnabled: propTypes.bool,
    /** If enabled, code input fields are cleared when Resend Code button is clicked */
    isCodeResetOnResendEnabled: propTypes.bool,
    /** Are we editing the mobile number? */
    isEditingMobileNumber: propTypes.bool,
    /** If the resend code button is in a loading state */
    isResendLoading: propTypes.bool,
    /** If the verification was successful */
    isSuccessful: propTypes.bool,
    /** Is update mobile feature enabled? If false, show cancel button instead and never display modal  */
    isUpdateMobileEnabled: propTypes.bool,
    /** If a verification request is loading */
    isVerificationLoading: propTypes.bool,
    /** The help text to render beneath the main info message */
    lowerHelpText: propTypes.string,
    /** Verification code (if controlling from the outside) */
    verificationCode: propTypes.string,
    /** Callback when user clicks Cancel */
    onCancelClick: propTypes.func,
    /** Complete callback, called when the user has filled the whole verification code */
    onComplete: propTypes.func,
    /** Callback when user clicks resend */
    onResendClick: propTypes.func,
    /** Main info message */
    mainInfoText: propTypes.node,
    /** The mobile number to display for confirmation and prepopulating the mobile number editor */
    mobileNumber: propTypes.string,
    /** Mobile number edit button was clicked */
    onClickEditMobileNumber: propTypes.func,
};

VerificationContainer.displayName = 'VerificationContainer';

export default VerificationContainer;
