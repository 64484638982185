{
	"isLightTheme": false,
	"title": "Watch Sport Live and On-Demand",
	"meta": {
		"description": "Stream all your favourite sports Live & On-Demand including AFL, NRL, Football, Motorsport, Cricket and more. New customers get your 14-day free trial and start streaming instantly.",
		"title": "Watch Sport Live & On-Demand | Kayo Sports",
		"ogDescription": "Stream all your favourite sports Live & On-Demand including AFL, NRL, Football, Motorsport, Cricket and more. New customers get your 14-day free trial and start streaming instantly.",
		"ogImageHeight": null,
		"ogImageUrl": "https://resources.kayosports.com.au/production/marketing/images/09-series-logos.png",
		"ogImageWidth": null,
		"ogLocale": "en_GB",
		"ogSiteName": "Kayo Sports",
		"ogTitle": "Watch Sport Live & On-Demand | Kayo Sports",
		"ogType": "product",
		"twitterAccount": "@kayosports",
		"twitterCard": "summary_large_image",
		"twitterDescription": "Stream all your favourite sports Live & On-Demand including AFL, NRL, Football, Motorsport, Cricket and more. New customers get your 14-day free trial and start streaming instantly.",
		"twitterImageUrl": "https://resources.kayosports.com.au/production/marketing/images/09-series-logos.png",
		"twitterTitle": "Watch Sport Live & On-Demand | Kayo Sports"
	},
	"headerImages": {
		"posterImages": [
			"https://resources.kayosports.com.au/production/marketing/images/09-series-logos.png",
			"https://resources.kayosports.com.au/production/marketing/images/Header-Web-ausodi-multiplatform.png",
			"https://resources.kayosports.com.au/production/marketing/images/Header-Web-multisport.png"
		],
		"posterImagesMobile": [
			"https://resources.kayosports.com.au/production/marketing/images/09-series-logos-xs.png",
			"https://resources.kayosports.com.au/production/marketing/images/Header-Mobile-ausodi-multiplatform.png",
			"https://resources.kayosports.com.au/production/marketing/images/Header-Mobile-multisport.png"
		]
	},
	"backgroundImage": "https://resources.kayosports.com.au/volatile/site-background.jpg",
	"packageSelectPrompt": "Select from the packages below",
	"packagesSupplementaryList": {
		"header": "Every subscription includes",
		"items": [
			"Watch live & on-demand",
			"Cancel anytime",
			"Stream the action in HD*",
			"No spoilers",
			"Key moments",
			"Watch on popular devices"
		]
	},
	"marketingItems": [
		{
			"type": "sportsSeriesSelector",
			"adobeId": "sports-carousel",
			"defaultImage2560x485": "https://resources.kayosports.com.au/production/marketing/images/sports/afl.png",
			"defaultText": "The biggest Australian sports and the best from overseas are right here on Kayo",
			"title": "Over 50 Sports Live & On-Demand",
			"sports": [
				{
					"label": "Australian Rules Football",
					"sportId": "afl",
					"defaultImage2560x485": "https://resources.kayosports.com.au/production/marketing/images/sports/afl.png",
					"text": "Watch AFL Live & On-Demand",
					"series": [
						{
							"label": "AFL Premiership",
							"seriesId": "1",
							"text": "Relive the biggest matches from the 2020 Toyota AFL Premiership Season On-Demand before footy returns in 2021"
						},
						{
							"label": "AFLW",
							"seriesId": "4",
							"text": "Relive the biggest matches from the 2020 NAB AFL Women's Competition On-Demand before footy returns in 2021"
						}
					]
				},
				{
					"label": "Rugby League",
					"sportId": "league",
					"image2560x485": "https://resources.kayosports.com.au/production/marketing/images/sports/league.png",
					"text": "Watch Rugby League Live & On-Demand",
					"series": [
						{
							"label": "NRL Premiership",
							"seriesId": "1",
							"text": "Relive the biggest matches from the 2020 NRL Telstra Premiership Season On-Demand before footy returns in 2021"
						},
						{
							"label": "State of Origin",
							"seriesId": "3",
							"text": "Relive the 2018-2020 Holden State of Origin Series On-Demand"
						},
						{
							"label": "Super League",
							"seriesId": "10",
							"text": "Watch Live & On-Demand coverage of the 2020 Betfred Super League Season"
						},
						{
							"label": "Canterbury Cup NSW",
							"seriesId": "21",
							"text": "Relive selected games from the 2019 NSW Caterbury Cup"
						},
						{
							"label": "Intrust Super Cup",
							"seriesId": "22",
							"text": "Relive selected games from the 2019 QLD Intrust Super Cup"
						}
					]
				},
				{
					"label": "Cricket",
					"sportId": "cricket",
					"image2560x485": "https://resources.kayosports.com.au/production/marketing/images/sports/2560x485.png",
					"text": "Watch Cricket Live & On-Demand",
					"series": [
						{
							"label": "IPL",
							"seriesId": "35",
							"text": "Relive the biggest matches from the 2020 Indian Premier League On-Demand"
						},
						{
							"label": "International Tests",
							"seriesId": "25",
							"text": "Watch selected international Men's and Women's Cricket Tests including every Australian match on home soil Live & On-Demand"
						},
						{
							"label": "One Day Internationals",
							"seriesId": "26",
							"text": "Watch selected international Men's and Women's Cricket ODIs including every Australian match Live & On-Demand"
						},
						{
							"label": "T20 Internationals",
							"seriesId": "8",
							"text": "Watch selected international Men's and Women's T20 Cricket including every Australian match Live & On-Demand"
						},
						{
							"label": "Sheffield Shield",
							"seriesId": "27",
							"text": "Watch Live & On-Demand coverage from Australia's first-class domestic cricket competition"
						},
						{
							"label": "JLT One Day Cup",
							"seriesId": "3",
							"text": "Relive selected matches from The 2019 Marsh One-Day Cup On-Demand"
						},
						{
							"label": "BBL",
							"seriesId": "9",
							"text": "Watch every match of the 2020/21 KFC BBL Season Live & On-Demand with No Ad-Breaks During Play, the Fox Cricket commentary team and Kayo Mini condensed replays of every match"
						},
						{
							"label": "WBBL",
							"seriesId": "31",
							"text": "Watch every match of the 2020 Rebel WBBL Season Live & On-Demand"
						}
					]
				},
				{
					"label": "Football",
					"sportId": "football",
					"image2560x485": "https://resources.kayosports.com.au/production/marketing/images/sports/football.png",
					"text": "Watch Football Live & On-Demand",
					"series": [
						{
							"label": "Hyundai A-League",
							"seriesId": "1",
							"text": "Watch every match of the 2020/21 A-League Season Live & On-Demand with No Ad-breaks During Play and full and condesnsed Kayo mini replays of every match"
						},
						{
							"label": "LaLiga",
							"seriesId": "22",
							"text": "Watch LIVE coverage from every round of the 2020/21 LaLiga Season plus condensed replays with beIN Sports on Kayo."
						},
						{
							"label": "Bundesliga",
							"seriesId": "21",
							"text": "Watch LIVE coverage from every round of the 2020/21 Bundesliga Season plus condensed replays with beIN Sports on Kayo."
						},
						{
							"label": "Serie A",
							"seriesId": "23",
							"text": "Watch LIVE coverage of the 2020/21 Serie A Season with beIN Sports on Kayo"
						},
						{
							"label": "EFL",
							"seriesId": "51",
							"text": "Watch LIVE coverage from every round of the 2020/21 EFL Championship with beIN Sports on Kayo."
						},
						{
							"label": "FA Cup",
							"seriesId": "31",
							"text": "Watch LIVE coverage of The Emirates FA Cup with ESPN on Kayo."
						},
						{
							"label": "FFA Cup",
							"seriesId": "27",
							"text": ""
						},
						{
							"label": "SPFL",
							"seriesId": "56",
							"text": "Watch LIVE coverage of the 2020/21 Scottish Premier League Season with beIN Sports on Kayo"
						},
						{
							"label": "MLS",
							"seriesId": "54",
							"text": "Watch live coverage of the 2020 Major League Soccer season with beIN Sports & ESPN on Kayo."
						},
						{
							"label": "Ligue 1",
							"seriesId": "53",
							"text": "Watch LIVE coverage of the 2020/21 Ligue 1 Season with beIN Sports on Kayo"
						},
						{
							"label": "Westfield W-League",
							"seriesId": "9",
							"text": "Watch Live & On-Demand coverage of the 2020/21 W-League Season"
						},
						{
							"label": "Copa Libertadores",
							"seriesId": "",
							"text": ""
						},
						{
							"label": "Copa Sudamericana",
							"seriesId": "",
							"text": ""
						},
						{
							"label": "Asian Champions League",
							"seriesId": "4",
							"text": "Watch Live & On-Demand coverage of selected matches from the 2020 AFC Champions League"
						}
					]
				},
				{
					"label": "Rugby Union",
					"sportId": "rugby",
					"image2560x485": "https://resources.kayosports.com.au/production/marketing/images/sports/rugby.png",
					"text": "Stream the best rugby union action anywhere, anytime straight to your TV or favourite device in HD. Game changing features for the ultimate streaming experience.",
					"series": [
						{
							"label": "Super Rugby",
							"seriesId": "1",
							"text": "Relive the biggest games from Super Rugby AU and Super Rugby Aotearoa On-Demand."
						},
						{
							"label": "Guinness Six Nations",
							"seriesId": "5",
							"text": "Relive the biggest games from the 2020 Guinness Six Nations"
						},
						{
							"label": "Test Match Men",
							"seriesId": "2",
							"text": "Watch the biggest test matches from the Southern and Northern Hempispheres Live & On-Demand including Etoro 2020 Tri-Nations, Bledisloe Cup, Six Nations and Autumn Nations Cup"
						},
						{
							"label": "Tri-Nations",
							"seriesId": "4",
							"text": "Watch every match of the 2020 Tri-Nations Series Live & On-Demand and Ad-Break Free During Play"
						},
						{
							"label": "HSBC Sevens Series",
							"seriesId": "10",
							"text": "Relive coverage of the World Rugby HSBC Men's Sevens Series"
						},
						{
							"label": "HSBC Womens Sevens Series",
							"seriesId": "12",
							"text": "Relive coverage of the World Rugby HSBC Women's Sevens Series"
						},
						{
							"label": "National Rugby Championship",
							"seriesId": "9",
							"text": ""
						},
						{
							"label": "Mitre 10 Cup",
							"seriesId": "7",
							"text": "Watch Live & On-Demand Coverage of the 2020 Mitre-10 Cup"
						},
						{
							"label": "Premiership Rugby",
							"seriesId": "24",
							"text": ""
						},
						{
							"label": "Buildcorp Super W",
							"seriesId": "19",
							"text": ""
						}
					]
				},
				{
					"label": "Motorsport",
					"sportId": "motor",
					"image2560x485": "https://resources.kayosports.com.au/production/marketing/images/sports/motor.png",
					"text": "Watch The World's Best Motorsport Live & On-Demand",
					"series": [
						{
							"label": "Supercars",
							"seriesId": "1",
							"text": "Relive the biggest races from the 2020 Supercars Championship On-Demand before racing returns in 2021."
						},
						{
							"label": "MotoGP",
							"seriesId": "10",
							"text": "Watch every practice, qualifying session and race of the 2020 MotoGP World Championship Live & On-Demand and Ad-Break Free During Racing"
						},
						{
							"label": "F1",
							"seriesId": "30",
							"text": "Watch every practice, qualifying session and race of the 2020 FIA Formula One World Championship™ Live & On-Demand and Ad-Break Free During Racing"
						},
						{
							"label": "Moto2",
							"seriesId": "30",
							"text": ""
						},
						{
							"label": "Moto3",
							"seriesId": "11",
							"text": ""
						},
						{
							"label": "SBK Motul",
							"seriesId": "12",
							"text": ""
						},
						{
							"label": "W2C",
							"seriesId": "67",
							"text": ""
						},
						{
							"label": "F2",
							"seriesId": "60",
							"text": ""
						},
						{
							"label": "Formula-E",
							"seriesId": "61",
							"text": ""
						}
					]
				},
				{
					"label": "Basketball",
					"sportId": "basketball",
					"image2560x485": "https://resources.kayosports.com.au/production/marketing/images/sports/basketball.png",
					"text": "Watch The World's Best Basketball Live & On-Demand",
					"series": [
						{
							"label": "NBA",
							"seriesId": "39",
							"text": "Stay tuned for the 2021 NBA Season coming soon!"
						},
						{
							"label": "NBL",
							"seriesId": "9",
							"text": ""
						},
						{
							"label": "NCAA",
							"seriesId": "40",
							"text": ""
						},
						{
							"label": "WNBA",
							"seriesId": "56",
							"text": ""
						},
						{
							"label": "WNBL",
							"seriesId": "10",
							"text": "Kayo is the only place to see every game LIVE of the 2020 WNBL Season"
						}
					]
				},
				{
					"label": "Tennis",
					"sportId": "tennis",
					"image2560x485": "https://resources.kayosports.com.au/production/marketing/images/sports/tennis.png",
					"text": "Watch Tennis Live & On-Demand",
					"series": [
						{
							"label": "WTA Tour",
							"seriesId": "Watch LIVE coverage of the WTA Tour",
							"text": ""
						},
						{
							"label": "ATP Tour",
							"seriesId": "",
							"text": "Watch LIVE coverage of the ATP Tour"
						},
						{
							"label": "French Open",
							"seriesId": "41",
							"text": ""
						},
						{
							"label": "Wimbledon",
							"seriesId": "52",
							"text": ""
						},
						{
							"label": "US Open",
							"seriesId": "53",
							"text": "."
						}
					]
				},
				{
					"label": "Golf",
					"sportId": "golf",
					"image2560x485": "https://resources.kayosports.com.au/production/marketing/images/sports/golf.png",
					"text": "Watch Golf Live & On-Demand.",
					"series": [
						{
							"label": "USPGA",
							"seriesId": "500",
							"text": "Watch Live & On-Demand coverage of the USPGA Tour"
						},
						{
							"label": "European PGA",
							"seriesId": "498",
							"text": "Watch Live & On-Demand coverage of the PGA European Tour"
						},
						{
							"label": "USLPGA",
							"seriesId": "",
							"text": ""
						},
						{
							"label": "The Masters",
							"seriesId": "",
							"text": ""
						},
						{
							"label": "The Open",
							"seriesId": "",
							"text": ""
						},
						{
							"label": "US Open",
							"seriesId": "",
							"text": ""
						},
						{
							"label": "USPGA Championship",
							"seriesId": "",
							"text": ""
						}
					]
				},
				{
					"label": "Icehockey",
					"sportId": "icehockey",
					"image2560x485": "https://resources.kayosports.com.au/production/marketing/images/sports/icehockey.png",
					"text": "Watch Ice Hockey Live & On-Demand",
					"series": [
						{
							"label": "NHL",
							"seriesId": "55",
							"text": "Stay tuned for NHL returning soon in 2021..."
						},
						{
							"label": "Australian Ice Hockey League",
							"seriesId": "",
							"text": ""
						}
					]
				},
				{
					"label": "Boxing",
					"sportId": "boxing",
					"image2560x485": "https://resources.kayosports.com.au/production/marketing/images/sports/boxing.png",
					"text": "Watch Boxing Live & On-Demand"
				},
				{
					"label": "MMA",
					"sportId": "mma",
					"image2560x485": "https://resources.kayosports.com.au/production/marketing/images/sports/mma.png",
					"text": "Watch UFC Live with ESPN on Kayo. Stream Fight Night events in full, Pay-Per-View pre-show and prelim fights, Dana White's Contender Series, docos and more.",
					"series": [
						{
							"label": "UFC",
							"seriesId": "467",
							"text": ""
						}
					]
				},
				{
					"label": "Baseball",
					"sportId": "baseball",
					"image2560x485": "https://resources.kayosports.com.au/production/marketing/images/sports/baseball.png",
					"text": "Watch Baseball Live & On-Demand.",
					"series": [
						{
							"label": "Major League Baseball",
							"seriesId": "270",
							"text": "Stay tuned for MLB returning soon in 2021..."
						}
					]
				},
				{
					"label": "Gridiron",
					"sportId": "gridiron",
					"image2560x485": "https://resources.kayosports.com.au/production/marketing/images/sports/gridiron.png",
					"text": "Watch NFL and NCAA Football Live & On-Demand",
					"series": [
						{
							"label": "NFL",
							"seriesId": "271",
							"text": "Watch an average of 5 regular season NFL games LIVE each week on ESPN with Kayo plus every game of the Post-Season including Super Bowl LV"
						},
						{
							"label": "NCAA Football",
							"seriesId": "455",
							"text": "Watch Live Coverage of NCAA College Football"
						}
					]
				},
				{
					"label": "Athletics",
					"sportId": "athletics",
					"image2560x485": "https://resources.kayosports.com.au/production/marketing/images/sports/athletics.png",
					"text": ""
				},
				{
					"label": "Tenpin Bowling",
					"sportId": "tenpinbowling",
					"image2560x485": "https://resources.kayosports.com.au/production/marketing/images/sports/bowling.png"
				},
				{
					"label": "Cycling",
					"sportId": "cycling",
					"image2560x485": "https://resources.kayosports.com.au/production/marketing/images/sports/cycling.png",
					"text": ""
				},
				{
					"label": "Darts",
					"sportId": "darts",
					"image2560x485": "https://resources.kayosports.com.au/production/marketing/images/sports/darts.png",
					"text": ""
				},
				{
					"label": "e-sport",
					"sportId": "electronic",
					"image2560x485": "https://resources.kayosports.com.au/production/marketing/images/sports/esports.png",
					"text": ""
				},
				{
					"label": "Extreme",
					"sportId": "extreme",
					"image2560x485": "https://resources.kayosports.com.au/production/marketing/images/sports/extremesports.png",
					"text": "",
					"series": [
						{
							"label": "X-Games",
							"seriesId": "457",
							"text": ""
						}
					]
				},
				{
					"label": "Hockey",
					"sportId": "hockey",
					"image2560x485": "https://resources.kayosports.com.au/production/marketing/images/sports/fieldhockey.png",
					"text": "",
					"series": [
						{
							"label": "Field Hockey International Women",
							"seriesId": "447",
							"text": ""
						},
						{
							"label": "Field Hockey International Men",
							"seriesId": "448",
							"text": ""
						}
					]
				},
				{
					"label": "Gymnastics",
					"sportId": "gymnastics",
					"image2560x485": "https://resources.kayosports.com.au/production/marketing/images/sports/gymnastics.png",
					"text": "",
					"series": [
						{
							"label": "2019 Australian Gymnastics Championships",
							"seriesId": "459",
							"text": ""
						},
						{
							"label": "International Gymnastic Events",
							"seriesId": "",
							"text": ""
						}
					]
				},
				{
					"label": "Lawn Bowls",
					"sportId": "lawnbowls",
					"image2560x485": "https://resources.kayosports.com.au/production/marketing/images/sports/lawnbowls.png",
					"text": ""
				},
				{
					"label": "Sailing",
					"sportId": "sailing",
					"image2560x485": "https://resources.kayosports.com.au/production/marketing/images/sports/sailing.png",
					"text": "",
					"series": [
						{
							"label": "Sail GP",
							"seriesId": "",
							"text": ""
						},
						{
							"label": "Super Foiler GP",
							"seriesId": "",
							"text": ""
						}
					]
				},
				{
					"label": "Squash",
					"sportId": "squash",
					"image2560x485": "https://resources.kayosports.com.au/production/marketing/images/sports/squash.png",
					"text": ""
				},
				{
					"label": "Swimming",
					"sportId": "swimming",
					"image2560x485": "https://resources.kayosports.com.au/production/marketing/images/sports/swimming.png",
					"text": ""
				},
				{
					"label": "Table Tennis",
					"sportId": "tabletennis",
					"image2560x485": "https://resources.kayosports.com.au/production/marketing/images/sports/tabletennis.png",
					"text": ""
				},
				{
					"label": "Triathlon",
					"sportId": "triathlon",
					"image2560x485": "https://resources.kayosports.com.au/production/marketing/images/sports/triathlon.png",
					"text": ""
				},
				{
					"label": "Weightlifting",
					"sportId": "weightlifting",
					"image2560x485": "https://resources.kayosports.com.au/production/marketing/images/sports/weightlifting.png",
					"text": ""
				},
				{
					"label": "Winter Sport",
					"sportId": "winter",
					"image2560x485": "https://resources.kayosports.com.au/production/marketing/images/sports/wintersports.png"
				},
				{
					"label": "Other Sport",
					"sportId": "othersport",
					"image2560x485": "https://resources.kayosports.com.au/production/marketing/images/sports/other.png"
				}
			]
		},
		{
			"type": "devicesCarousel",
			"adobeId": "devices-carousel",
			"isLightTheme": true,
			"footerLink": "https://help.binge.com.au/s/article/Supported-Devices-Browsers",
			"footerText": "See our full list of devices",
			"itemList": [
				{
					"description": [
						"Kayo is available on mobile phones and tablets using iOS v11+ or Android OS v6+. Download the Kayo app and get streaming."
					],
					"iconUrl": "https://resources.kayosports.com.au/production/marketing/images/img_mobtab2x.png",
					"title": "Mobile & Tablet",
					"posterImage": "https://resources.kayosports.com.au/production/marketing/images/op/web-rwc-dev-mobile-5000px.png",
					"posterImageMobile": "https://resources.kayosports.com.au/production/marketing/images/op/mobile-rwc-dev-mobile-2301px.png"
				},
				{
					"description": [
						"You can stream Kayo with a compatible browser on your computer, laptop, tablet or mobile phone. Kayo is optimized for the latest version of Chrome, Safari, Microsoft Edge or Firefox."
					],
					"iconUrl": "https://resources.kayosports.com.au/production/marketing/images/img-macpc2x.png",
					"title": "Web",
					"posterImage": "https://resources.kayosports.com.au/production/marketing/images/op/web-laptop.png",
					"posterImageMobile": "https://resources.kayosports.com.au/production/marketing/images/op/mobile-laptop.png"
				},
				{
					"description": [
						"Download the Kayo app to enjoy Kayo Sports on 2017 Samsung TV's and above."
					],
					"iconUrl": "https://resources.kayosports.com.au/production/marketing/images/img-samsung.png",
					"title": "Samsung TV",
					"posterImage": "https://resources.kayosports.com.au/production/marketing/images/op/web-samsung.png",
					"posterImageMobile": "https://resources.kayosports.com.au/production/marketing/images/op/mobile-samsung.png"
				},
				{
					"description": [
						"Kayo is available on Apple TV version 4 or higher, running tvOS 11+. Simply visit the App Store, download Kayo, and start streaming."
					],
					"iconUrl": "https://resources.kayosports.com.au/production/marketing/images/img_appletv2x.png",
					"title": "Apple TV",
					"posterImage": "https://resources.kayosports.com.au/production/marketing/images/op/web-appletv.png",
					"posterImageMobile": "https://resources.kayosports.com.au/production/marketing/images/op/mobile-appletv.png"
				},
				{
					"description": [
						"Download the Kayo app and stream sport on selected Android TVs that feature Android OS: version 7.0+."
					],
					"iconUrl": "https://resources.kayosports.com.au/production/marketing/images/img-androidtvx2.png",
					"title": "Android TV",
					"posterImage": "https://resources.kayosports.com.au/production/marketing/images/op/web-androidtv.png",
					"posterImageMobile": "https://resources.kayosports.com.au/production/marketing/images/op/mobile-androidtv.png"
				},
				{
					"description": [
						"Kayo is available on Telstra TV on TTV1 (4200TL), TTV2 (4700TL) and TTV3 (4701TL)."
					],
					"iconUrl": "https://resources.kayosports.com.au/production/marketing/images/img-telstratv2x.png",
					"title": "Telstra TV",
					"posterImage": "https://resources.kayosports.com.au/production/marketing/images/op/web-rokutv.png",
					"posterImageMobile": "https://resources.kayosports.com.au/production/marketing/images/op/mobile-rokutv.png"
				},
				{
					"description": [
						"You can cast Kayo from compatible devices to a TV via the Chromecast Ultra, latest 3rd Generation devices or onto similar capability Android TVs."
					],
					"iconUrl": "https://resources.kayosports.com.au/production/marketing/images/img-chromecast2x.png",
					"title": "Chromecast",
					"posterImage": "https://resources.kayosports.com.au/production/marketing/images/op/web-chromecast.png",
					"posterImageMobile": "https://resources.kayosports.com.au/production/marketing/images/op/mobile-chromecast.png"
				}
			],
			"footerBlock": {
				"title": "Download the Kayo App",
				"itemList": [
					{
						"link": "https://play.google.com/store/apps/details?id=au.com.kayosports",
						"icon": "https://resources.streamotion.com.au/production/binge/marketing/images/icon_google-play.svg",
						"iconTitle": "Get it on Google Play"
					},
					{
						"link": "https://itunes.apple.com/au/app/kayo-sports/id1434518367?mt=8",
						"icon": "https://resources.streamotion.com.au/production/binge/marketing/images/icon_app-store.svg",
						"iconTitle": "Download on the App Store"
					}
				]
			}
		},
		{
			"type": "featuresCarousel",
			"adobeId": "feature-carousel",
			"footerLink": "https://help.kayosports.com.au/s/article/Discover-Kayo-s-game-changing-features",
			"footerText": "Learn more about our features",
			"itemList": [
				{
					"description": [
						"SplitView lets you watch up to four videos at once, all on the one screen, on selected devices. Keep the action right in front of you with multiple games, or throw in an extra camera angle. it’s more sports than ever, and that’s always a good thing."
					],
					"icon": "splitview",
					"iconUrl": "",
					"title": "SplitView",
					"posterImage": "https://resources.kayosports.com.au/production/marketing/images/op/web-splitview.png",
					"posterImageMobile": "https://resources.kayosports.com.au/production/marketing/images/op/mobile-splitview.png"
				},
				{
					"description": [
						"If you’re short on time or want to catch up on games you’ve missed, then Kayo Minis are the perfect way to enjoy more of the action. Stream a condensed version of key sport match ups and get all the highlights, all in about 20 minutes. Kayo Minis, made for busy lives on the go."
					],
					"icon": "kayomini",
					"iconUrl": "https://resources.kayosports.com.au/production/marketing/images/img-mini-2x.png",
					"title": "Kayo Mini",
					"posterImage": "https://resources.kayosports.com.au/production/marketing/images/op/web-kayomini.png",
					"posterImageMobile": "https://resources.kayosports.com.au/production/marketing/images/op/mobile-kayomini.png"
				},
				{
					"description": [
						"When the love for your favourite sport runs deep, Interactive Stats lets you dive into a world of facts and figures that takes you well beyond the game. Launched during a match, you’ll enjoy a real feast for the eyes with analysis & key statistics per team. Stash it all away because the more you know, the more you get out of Kayo."
					],
					"icon": "statsandmore",
					"iconUrl": "https://resources.kayosports.com.au/production/marketing/images/img-hud-2x.png",
					"title": "Stats & more",
					"posterImage": "https://resources.kayosports.com.au/production/marketing/images/op/interactive-web.png",
					"posterImageMobile": "https://resources.kayosports.com.au/production/marketing/images/op/interactive-mobile.png"
				},
				{
					"description": [
						"If you’re short on time or just want to watch the best bits, Key Moments lets you go right to the action that matters in selected matches. We’ve gone ahead and tagged the stuff you need to see, so all you need to do is jump through the video timeline like a boss, watching any epic moment you want. It’s the ultimate highlights package that you get to control."
					],
					"icon": "keymoments",
					"iconUrl": "",
					"title": "Key Moments",
					"posterImage": "https://resources.kayosports.com.au/production/marketing/images/op/web-keymoments.png",
					"posterImageMobile": "https://resources.kayosports.com.au/production/marketing/images/op/mobile-keymoment.png"
				},
				{
					"description": [
						"Get up close and personal with your favourite sport on Kayo. As good as being there live, you’ll see every bead of sweat, blade of grass and your team’s colours beaming proudly. It’s every glorious moment, delivered in the clearest detail. All content streamed in Kayo is available in a HD stream, provided you have the bandwidth."
					],
					"icon": "hd",
					"iconUrl": "",
					"title": "HD*",
					"posterImage": "https://resources.kayosports.com.au/production/marketing/images/op/web-hd.png",
					"posterImageMobile": "https://resources.kayosports.com.au/production/marketing/images/op/mobile-hd.png"
				},
				{
					"description": [
						"Kayo Sports has a built-in ‘No Spoilers’ setting. So, if you’re running late, or your plan’s gone off the rails, we’ve got your back by locking off the scores and results until you’re ready to hit play. It’s the peace of mind you’ve always wished for, right here."
					],
					"icon": "nospoilers",
					"iconUrl": "",
					"title": "Spoilers",
					"posterImage": "https://resources.kayosports.com.au/production/marketing/images/op/web-nospoilers.png",
					"posterImageMobile": "https://resources.kayosports.com.au/production/marketing/images/op/mobile-nospoilers.png"
				}
			]
		},
		{
			"type": "streamotionBlurb",
			"adobeId": "streamotion-blurb",
			"title": "STREAMOTION",
			"headerLogo": "https://resources.streamotion.com.au/production/binge/marketing/images/binge-streamotion-company.png?imwidth=232",
			"icons": [
				"ares",
				"watchAfl",
				"watchNrl"
			],
			"subTitle": "Streamotion. Unleashing the potential of streaming.",
			"copy": "Streamotion's mission is simple. We want to connect people to the content they love in ways they've never thought of. Streaming is one thing, but it's the experience around that we're always striving to improve. That means whenever you're enjoying downtime, either watching your favourite entertainment on Binge or the world's best sport on Kayo, it's the industry leading features that make life better for everyone that connects with us.",
			"footerLink": "https://help.kayosports.com.au/s/article/What-is-Streamotion",
			"footerText": "LEARN MORE ABOUT STREAMOTION"
		},
		{
			"type": "questionsAccordion",
			"adobeId": "questions-accordion",
			"title": "Got a question?",
			"isLightTheme": true,
			"items": [
				{
					"id": "What is Kayo?",
					"title": "What is Kayo?",
					"definition": [
						"Kayo is the proudly Australian game-changing sports streaming service created to give you the ultimate way to get closer to the sports you love.",
						"With Kayo, there's no such thing as watching from the sidelines. Tell us your favourite teams and we'll bring you a never-ending stream of live games and on-demand sports that's guaranteed to get you fired up. But that's just for starters.",
						"Kayo also has exclusive features that lets you watch how you want – on all your devices big and small. Get amongst it.",
						"Kayo has subscription options available to cover all sports lovers. Watch on your favourite device at home or on the go.",
						"With thousands of hours on demand match-replays, live news, shows and docos available any time, Kayo is a paradise for all types of fans.",
						"From spoiler free catch ups, interactive stats, alerts and more, the unique features on Kayo are the future of sports streaming in Australia. Best of all, they all give you control of your sports experience like never before."
					]
				},
				{
					"id": "How does a free trial work?",
					"title": "How does a free trial work?",
					"definition": [
						"Kayo offer a 14 day free trial on our Basic and Premium plan for new customers. Enjoy up to three simultaneous streams, including all of Kayo's game-changing features and our massive library of all things sport.",
						"And did we mention it's free? You won't have to pay a cent to Kayo for the first 14 days.* Then it's your call to decide if Kayo is for you.",
						"All you need to do is sign up, get streaming on your favourite device, and enjoy 14 days on us!",
						"You'll be in pole position to experience the very best sports, expert analysis and behind the scenes content from all over the globe.",
						"If you change your mind during the free trial, you can upgrade or downgrade at no extra charge.",
						"Welcome to Kayo!",
						"*Data charges from your ISP or network carrier apply"
					]
				},
				{
					"id": "What content is available as part of your Kayo subscription?",
					"title": "What content is available as part of your Kayo subscription?",
					"definition": [
						"With a ton of sports instantly streamed plus heaps more available any time, Kayo is a paradise for all types of fans.",
						"Australia's leading sports broadcasters, including the likes of Fox Sports, ESPN and beIN SPORTS, are key partners to Kayo, and their TV channels are the source of much of the great content that we provide you.",
						"You have the luxury of watching sports content on Kayo Sports in a variety of ways, one of which is via dedicated sports channels.",
						"So jump on in and enjoy a range of events or shows from each of these broadcasters."
					]
				},
				{
					"id": "How do I sign up to earn Qantas Points?",
					"title": "How do I sign up to earn Qantas Points?",
					"definition": [
						"As a Qantas Frequent Flyer member, you'll be able to link your Qantas Frequent Flyer and Kayo accounts when subscribing as a new customer to Kayo via kayosports.com.au/qantasfrequentflyer. During the sign-up process, you'll be prompted to validate your Qantas Frequent Flyer number.",
						"This offer is only available to new Kayo customers and via the link above. Note: A 14 day free trial isn't available with this offer and you can't earn points in conjunction with any other Kayo promotions.",
						"Not a Qantas Frequent Flyer member? You can join for free via https://www.qantaspoints.com/join-now?code=KAYO",
						"Points are dependent on the subscription you choose:",
						"Basic Plan/$25mth = Initial 1,000 Qantas Points when you sign up + 100 points per month you are subscribed (including the first month)*",
						"Premium Plan/$35mth = Initial 1,500 Qantas Points when you sign up + 150 points per month you are subscribed (including the first month)*",
						"For further details, please read the full FAQs at help.kayosports.com.au/s/article/Qantas-Frequent-Flyer-and-Kayo-FAQs"
					]
				},
				{
					"id": "What is Streamotion?",
					"title": "What is Streamotion?",
					"definition": [
						"Hubbl is home to Kayo, Australia's multi-sport streaming service and also Australia's newest entertainment streaming service, Binge.",
						"Our mission is to connect people to the world's best content and to do this, become the world-class, leading streaming entertainment company in Australia.",
						"When you sign up to Kayo Sports or Binge, you automatically become a Hubbl customer. Your account details are stored at a Hubbl level, not a specific product level. Therefore, your Kayo Sports or Binge subscription is linked to Streamotion.",
						"To find out more, please visit help.kayosports.com.au/s/article/What-is-Streamotion"
					]
				}
			],
			"contactUsCopy": "Not sure of something? Contact our Kayo support crew",
			"contactUsHref": "https://help.kayosports.com.au/s/"
		},
		{
			"type": "marketingLink",
			"isLightTheme": false,
			"href": "https://kayosports.com.au/qantasfrequentflyer",
			"text": "You can now link your Qantas Frequent Flyer and Kayo accounts. Sign up here to earn Qantas Points"
		}
	]
}
