import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {A} from 'normalized-styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {SCREEN_768_TABLET} from '../../../../common/screen-sizes';
import VisuallyHidden from '../../../../common/visually-hidden';
import {blanc} from '../../../../common/palette';

import AppStoreIcon from './app-store-icon';

const StyledContainer = styled.div`
    color: ${blanc};
`;

const StyledLinksContainer = styled.div`
    display: flex;
    position: relative;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        flex-direction: row;
    `}
`;

const StyledH2 = styled.h2`
    margin: 0 0 7px;
    text-align: center;
    font: var(--ionic-header-2-bold);
`;

const StyledLink = styled(A)`
    color: inherit;

    &:not(:last-child) {
        margin-bottom: 29px;

        ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
            margin-right: 29px;
            margin-bottom: 0;
        `}
    }
`;

const GA30AppStore = ({
    title,
    isLightTheme,
    links = [],
    ...htmlAttributes
}) => (
    <StyledContainer isLightTheme={isLightTheme}>
        {!!title && <StyledH2>{title}</StyledH2>}
        <StyledLinksContainer {...htmlAttributes}>
            {links.map((link) => (
                <StyledLink key={link.store} href={link.href} target="_blank">
                    <AppStoreIcon
                        store={link.store}
                        ariaLabel={link.label}
                    />
                    <VisuallyHidden as="span">
                        (opens in a new window)
                    </VisuallyHidden>
                </StyledLink>
            ))}
        </StyledLinksContainer>
    </StyledContainer>
);

GA30AppStore.displayName = 'GA30AppStore';

GA30AppStore.propTypes = {
    /** Light or dark (default) theme */
    isLightTheme: propTypes.bool,
    /** CMS title copy */
    title: propTypes.string,
    /** Array of links to app stores */
    links: propTypes.arrayOf(propTypes.shape({
        /** Store type, currently either 'apple' or 'google' */
        store: propTypes.string,
        /** For aria-label */
        label: propTypes.string,
        /** Link to app store */
        href: propTypes.string,
    })),
};

export default GA30AppStore;
