import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import classnames from 'classnames';
import {Button} from '../../../../common/normalized-styled-components';
import {SCREEN_768_TABLET} from '../../../../common/screen-sizes';
import {black, slate} from '../../../../common/palette';
import {IC24ArrowL, IC25ArrowR} from '../../ic';

const StyledIcon = styled.div`
    opacity: 0.7;
`;

const IndicatorButtonWrapper = styled(Button)`
    display: flex;
    flex-shrink: 0;
    align-items: center;
    transition: background-color 0.2s ease-in-out;
    border: none;
    border-radius: 100%;
    background-color: ${rgba(slate, 0.15)};
    width: 24px;
    height: 24px;
    color: ${rgba(black, 0.7)};

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: 40px;
        height: 40px;
        padding: 10px;
    `}

    &:hover {
        background-color: ${rgba(slate, 0.3)};
    }

    &:active {
        background-color: ${rgba(slate, 0.45)};
    }
`;

/**
 * Image Gallery Button
 * - Used in OR149ImageGallery
 *
 * @param {{
 *          onClick: function;
 *          className: string;
 *          direction: 'left' | 'right';
 * }} props
 */
const BA55ImgGallery = ({
    onClick,
    className,
    direction,
}) => {
    const Icon = direction === 'left' ? IC24ArrowL : IC25ArrowR;

    return (
        <IndicatorButtonWrapper
            type="button"
            onClick={onClick}
            aria-label={`${direction === 'left' ? 'Previous' : 'Next'} image`}
            className={classnames('BA55ImgGallery', className)}
        >
            <StyledIcon as={Icon} />
        </IndicatorButtonWrapper>
    );
};

BA55ImgGallery.propTypes = {
    /** onClick handler */
    onClick: propTypes.func,
    /** Additional CSS class(es) */
    className: classNameType,
    /** Direction */
    direction: propTypes.oneOf(['left', 'right']).isRequired,
};

BA55ImgGallery.displayName = 'BA55ImgGallery';

export default BA55ImgGallery;
