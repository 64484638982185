import matchesProperty from 'lodash/matchesProperty';

const AD_TIER_FEATURE_TITLE = 'Ad supported';

export const checkIfAdTier = matchesProperty('title', AD_TIER_FEATURE_TITLE);

/**
 * Adds flag to feature object within package
 * for use with TM10SubTile component
 *
 * @param {Object} currentPackage - offer package to process
 * @returns {Object}              - new offer package object
 */
export function createAdTierFlagForFeatures(currentPackage) {
    return {
        ...currentPackage,
        features: currentPackage?.features?.map((feature) => ({
            ...feature,
            shouldNotShowTitle: checkIfAdTier(feature),
        })),
    };
}

/**
 * Converts the ad-tier feature into secondaryText in the package object
 * for use with OR49SumPnl and OR94PackageSelector components
 *
 * @param {Object} currentPackage - offer package to process
 * @returns {Object}              - new offer package object
 */
export function createSecondaryTextForAdTierPackage(currentPackage) {
    return {
        ...currentPackage,
        features: currentPackage?.features?.filter((feature) => !checkIfAdTier(feature)),
        secondaryText: currentPackage?.features?.find(checkIfAdTier)?.value,
    };
}
