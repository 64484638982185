import React from 'react';
import Ic from '..';

const IC141Discuss = (args) => (
    <Ic {...args}>
        <g fillRule="nonzero">
            <path d="M20.7 39.5h29v2.8h-29v-2.8zm0 9.1h23.9v2.8H20.7v-2.8zm41.4 15.1h20.1v2.8H62.1v-2.8zm5.2-9.1h15v2.8h-15v-2.8z" />
            <path d="M82.7 39.5H59.9v-9.7c0-4.6-3.7-8.3-8.3-8.3H19.3c-4.6 0-8.3 3.7-8.3 8.3v36.7c0 1.5 1.2 2.8 2.8 2.8H47v2.9c0 3.5 2.8 6.3 6.3 6.3h34.3c.8 0 1.4-.6 1.4-1.4V45.8c0-3.5-2.8-6.3-6.3-6.3zm-66.2-9.7c0-1.5 1.2-2.8 2.8-2.8h32.2c1.5 0 2.8 1.2 2.8 2.8V61c0 1.5-1.2 2.8-2.8 2.8h-35v-34zm69.7 45.9h-33c-1.9 0-3.5-1.6-3.5-3.5v-2.9h1.8c4.6 0 8.3-3.7 8.3-8.3V42.3h22.8c1.9 0 3.5 1.6 3.5 3.5v29.9h.1z" />
        </g>
    </Ic>
);

IC141Discuss.displayName = 'IC141Discuss';

export default IC141Discuss;
