import styled from 'styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {SCREEN_TABLET, SCREEN_DESKTOP, SCREEN_LG_DESKTOP, SCREEN_PRETTY_LG_DESKTOP} from '../../../common/screen-sizes';
import {blanc, ironStone, moonstruck, poseidon, selene, vader, white} from '../../../common/palette';

/* To future devs:
   We had an original templating system but found the designers were making each row bespoke.
   This new base row template makes fewer assumptions, so hopefully it could still be useful
   as a generic row, but also can be extended to offer control over top/bottom/side padding,
   whether it is a thin content section, whatever whacky things the designers come up with.
   If you don’t like it, then we’re sorry for the mess. - <3 SEAN AND MING
*/
const Row = styled.div`
    /* Override these CSS properties to control row behaviour */
    --offers-page__row-padding-top: 28px;                                       /* padding on top of row */
    --offers-page__row-padding-bottom: var(--offers-page__row-padding-top);     /* padding on bottom of row */
    --offers-page__row-padding-horizontal: 14px;                                /* padding on sides of row */
    --offers-page__row-padding-horizontal-thin-content: calc(50% - 375px);      /* for the thin content sections, aka max-width 750px */
    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        --offers-page__row-padding-top: 56px;
    `}

    box-sizing: border-box;
    padding: var(--offers-page__row-padding-top, 0) var(--offers-page__row-padding-horizontal) var(--offers-page__row-padding-bottom, 0);
    width: 100%;
`;

export const ContentCarouselWrapper = styled(Row).attrs({className: 'offers-page__row--content-carousel'})`
    --offers-page__row-padding-top: 0;
    --offers-page__row-padding-bottom: 85px;
    --offers-page__row-padding-horizontal: 0;
    background-color: ${({isLightTheme}) => isLightTheme ? blanc : poseidon};

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        --offers-page__row-padding-top: 0;
        --offers-page__row-padding-bottom: 85px;
    `}
`;

export const MarketingBlockWrapper = styled(Row).attrs({className: 'offers-page__row--marketing-block'})`
    --offers-page__row-padding-bottom: 0;
    --offers-page__row-padding-horizontal: 0;
    --offers-page__row-padding-top: 0;
    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        --offers-page__row-padding-top: 0;
    `}
`;

export const TabbedContentCarouselWrapper = styled(Row).attrs({className: 'offers-page__row--tabbed-content-carousel'})`
    --offers-page__row-padding-top: 0;
    --offers-page__row-padding-bottom: 85px;
    --offers-page__row-padding-horizontal: 0;
    background-color: ${({isLightTheme}) => isLightTheme ? blanc : vader};

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        --offers-page__row-padding-top: 0;
        --offers-page__row-padding-bottom: 85px;
    `}
`;

export const DevicesCarouselWrapper = styled(Row).attrs({className: 'offers-page__row--devices-carousel'})`
    background-color: ${({isLightTheme}) => isLightTheme ? moonstruck : selene};
    padding: 0;

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        --offers-page__row-padding-horizontal: var(--offers-page__row-padding-horizontal-thin-content);
    `}
`;

export const FeaturesCarouselWrapper = styled(Row).attrs({className: 'offers-page__row--features-carousel'})`
    --offers-page__row-padding-top: 0;
    background-color: ${({isLightTheme}) => isLightTheme ? moonstruck : selene};
    padding: 0;

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        --offers-page__row-padding-top: 0;
        --offers-page__row-padding-horizontal: var(--offers-page__row-padding-horizontal-thin-content);
    `}
`;

export const StreamotionBlurbWrapper = styled(Row).attrs({className: 'offers-page__row--streamotion-blurb'})`
    --offers-page__row-padding-top: 84px;
    --offers-page__row-padding-bottom: 45px;
    position: relative;
    background-color: ${white};

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        --offers-page__row-padding-top: 98px;
        --offers-page__row-padding-horizontal: calc(50% - 300px);
    `}

    ${mediaQuery({minWidthPx: SCREEN_DESKTOP})`
        --offers-page__row-padding-horizontal: calc(50% - 336px);
    `}

    ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
        --offers-page__row-padding-horizontal: var(--offers-page__row-padding-horizontal-thin-content);
    `}

    ${mediaQuery({minWidthPx: SCREEN_PRETTY_LG_DESKTOP})`
        --offers-page__row-padding-top: 114px;
        --offers-page__row-padding-bottom: 67px;
    `}
`;

export const QuestionsAccordionWrapper = styled(Row).attrs({className: 'offers-page__row--questions-accordion'})`
    --offers-page__row-padding-horizontal: 34px;
    background-color: ${({isLightTheme}) => isLightTheme ? white : ironStone};

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        --offers-page__row-padding-horizontal: calc(50% - 300px);
    `}

    ${mediaQuery({minWidthPx: SCREEN_DESKTOP})`
        --offers-page__row-padding-horizontal: calc(50% - 336px);
    `}

    ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
        --offers-page__row-padding-horizontal: var(--offers-page__row-padding-horizontal-thin-content);
    `}
`;
