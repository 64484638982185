import styled from 'styled-components';

import {ironStone} from './palette';

export default styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background: ${ironStone};
    min-height: 100vh;
`;
