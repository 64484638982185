import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';
import {silverLining, white} from '../../../../common/palette';
import BA01BtnPr from '../../../atoms/ba/01-btn-pr';
import BA22EditBtn from '../../../atoms/ba/22-edit-btn';

const StyledMain = styled.main`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`;

const Title = styled.h1`
    margin-top: 21px;
    color: ${white};
    font: var(--mui-header-5-bold);
`;

const Text = styled.span`
    margin-top: 56px;
    color: ${silverLining};
    font: var(--mui-body-copy-2);
`;

const StyledBA01BtnPr = styled(BA01BtnPr)`
    width: 250px;
    font-weight: 500;
`;

const OR168SignInRedirect = ({
    className,
    displayHeader,
    signInText,
    signInHref,
    signUpHeader,
    signUpSubText,
    signUpHref,
    ...htmlAttributes
}) => (
    <StyledMain
        {...htmlAttributes}
        className={classnames('OR168SignInRedirect', className)}
    >
        <Title>{displayHeader}</Title>
        <StyledBA01BtnPr href={signInHref}>{signInText}</StyledBA01BtnPr>
        <Text>{signUpHeader}</Text>
        <BA22EditBtn href={signUpHref}>{signUpSubText}</BA22EditBtn>
    </StyledMain>
);

OR168SignInRedirect.propTypes = {
    /** Optional CSS class(es) for custom styling */
    className: classNameType,
    /** The header to be displayed as the title */
    displayHeader: propTypes.string,
    /** Label for the Sign In button */
    signInText: propTypes.string,
    /** URL to redirect to when the Sign In button is clicked */
    signInHref: propTypes.string,
    /** Header text for the Sign Up section */
    signUpHeader: propTypes.string,
    /** Subtext for the Sign Up section */
    signUpSubText: propTypes.string,
    /** URL to redirect to when the Sign Up button is clicked */
    signUpHref: propTypes.string,
};

OR168SignInRedirect.displayName = 'OR168SignInRedirect';

export default OR168SignInRedirect;
