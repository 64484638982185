import React, {useState} from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import classnames from 'classnames';
import {Button, Section} from 'normalized-styled-components';
import {rgba} from 'polished';

import {stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {IC50Success, IC52Cross} from '../../../atoms/ic';

import {akela, blanc} from '../../../../common/palette';

const SuccessTile = styled(Section)`
    box-sizing: border-box;
    position: relative;
    transform-origin: top center;
    transition: ${transition('height', 'padding', 'transform')};
    border-radius: 4px;
    padding: 35px;
    width: 100%;
    text-align: center;

    ${stylesWhenNot('isVisible')`
        transform: scaleY(0);
        padding: 0;
        height: 0;
    `}
`;

const Heading = styled.h3`
    margin: 29px 0 9px;
    text-align: center;
    color: ${blanc};
    font: var(--ionic-success-header);
`;

const Byline = styled.p`
    margin: 9px 0;
    text-align: center;
    color: ${akela};
    font: var(--ionic-success-text);
`;

const StyledButton = styled(Button).attrs({
    'type': 'button',
    'aria-label': 'Close Message',
})`
    appearance: none;
    box-sizing: content-box;
    position: absolute;
    top: 0;
    left: 0;
    transition: ${transition('color')};
    border: 0;
    background: transparent;
    padding: 16px;
    width: 16px;
    color: ${rgba(blanc, 0.7)};

    &:hover,
    &:focus {
        outline: 0;
        color: ${blanc};
    }
`;

const IconWrapper = styled.div`
    margin-right: auto;
    margin-left: auto;
    width: 75px;
    height: 75px;
`;

const TM22SuccessTile = ({
    className,
    heading = 'Success!',
    icon = <IC50Success />,
    isDismissable = true,
    message,
    ...htmlAttributes
}) => {
    const [isVisible, setIsVisible] = useState(true);

    return (
        <SuccessTile
            {...htmlAttributes}
            className={classnames('TM22SuccessTile', className)}
            isVisible={isVisible}
        >
            {!!isDismissable && (
                <StyledButton onClick={() => void setIsVisible(false)}>
                    <IC52Cross />
                </StyledButton>
            )}
            <IconWrapper>
                {icon}
            </IconWrapper>
            <Heading>
                {heading}
            </Heading>
            <Byline>
                {message}
            </Byline>
        </SuccessTile>
    );
};

TM22SuccessTile.propTypes = {
    /** Additional class(es) */
    className: classNameType,
    /** Heading text */
    heading: propTypes.node,
    /** Icon to use for banner */
    icon: propTypes.node,
    /** Whether tile can be closed or not */
    isDismissable: propTypes.bool,
    /** Message to be displayed */
    message: propTypes.string,
};

TM22SuccessTile.displayName = 'TM22SuccessTile';

export default TM22SuccessTile;
