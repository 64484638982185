import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import styled from 'styled-components';
import {rgba} from 'polished';

import {stylesWhen, mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import backdropFilter from '../../../../common/backdrop-filter';
import {transition} from '../../../../common/animations';
import {black, blueCharcoal} from '../../../../common/palette';
import {SCREEN_1024_DESKTOP} from '../../../../common/screen-sizes';
import IC103Loading from '../../../atoms/ic/103-loading';

const StyledSection = styled.section`
    ${backdropFilter}
    display: grid;
    grid-template-areas: 'left'
        'center'
        'right';
    grid-template-rows: auto auto auto;
    grid-gap: 10px;
    align-items: center;
    justify-content: center;
    transform: translateY(100%);
    transition: ${transition('transform', 'opacity')};
    opacity: 0;
    border-top: 1px solid ${rgba(black, 0.7)};
    background: linear-gradient(to bottom, ${rgba(blueCharcoal, 0.7)} 2%, ${rgba(black, 0.7)} 99%);
    padding: 15px 0;
    width: 100%;
    text-align: center;

    ${stylesWhen('isVisible')`
        transform: translateY(0);
        opacity: 1;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        grid-template-areas: 'left center right';
        grid-template-columns: 1fr auto 1fr;
        grid-template-rows: 1fr;
    `}
`;

const LeftDiv = styled.div`
    grid-area: left;
    min-width: 250px;

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        justify-self: end;
    `}
`;

const CenterDiv = styled.div`
    grid-area: center;
    min-width: 250px;
`;

const RightDiv = styled.div`
    grid-area: right;
    min-width: 250px;

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        justify-self: start;
    `}
`;

const OR41PageNav = ({
    buttonLeft,
    buttonRight,
    buttonCenter,
    isLoading,
    isVisible = true,
    className,
    ...htmlAttributes
}) => (
    <StyledSection
        className={classnames('OR41PageNav', className)}
        isVisible={isVisible}
        {...htmlAttributes}
    >
        {isLoading ? (
            <CenterDiv><IC103Loading size="47px" /></CenterDiv>
        ) : (
            <React.Fragment>
                {!!buttonLeft && <LeftDiv>{buttonLeft}</LeftDiv>}
                {!!buttonCenter && <CenterDiv>{buttonCenter}</CenterDiv>}
                {!!buttonRight && <RightDiv>{buttonRight}</RightDiv>}
            </React.Fragment>
        )}
    </StyledSection>
);

OR41PageNav.propTypes = {
    /** Button atom on the center */
    buttonCenter: propTypes.node,
    /** Button atom on the left */
    buttonLeft: propTypes.node,
    /** Button atom on the right */
    buttonRight: propTypes.node,
    /** additional CSS classnames to be applied */
    className: classNameType,
    /** If the organism is in loading state, to show the loading icon */
    isLoading: propTypes.bool,
    /** If the organism is visible or not, for animation */
    isVisible: propTypes.bool,
};

OR41PageNav.displayName = 'OR41PageNav';

export default OR41PageNav;
