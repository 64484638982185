import React, {useState} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import get from 'lodash/get';
import noop from 'lodash/noop';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {white} from '../../../../common/palette';
import BA01BtnPr from '../../../atoms/ba/01-btn-pr';
import IC103Loading from '../../../atoms/ic/103-loading';
import OR115GiftInp from '../115-gift-inp';

const GiftAccount = styled.form`
    display: grid;
    grid-row-gap: 21px;

    > :nth-child(2) {
        margin: 0 auto;
    }
`;

const ButtonIconBox = styled.div`
    width: 32px;
    height: 32px;
`;

export default function OR118GiftAccount({
    className,
    isRedeeming,
    errorMessage,
    onRedeem = noop,
    onChangeGiftCode = noop,
    onChangeGiftPin = noop,
}) {
    const [giftCode, setGiftCode] = useState('');
    const [giftPin, setGiftPin] = useState('');

    function handleGiftCodeChange(val) {
        setGiftCode(val);
        onChangeGiftCode(val);
    }

    function handleGiftPinChange(val) {
        setGiftPin(val);
        onChangeGiftPin(val);
    }

    return (
        <GiftAccount
            className={classnames('OR118GiftAccount', className)}
            method="GET"
            noValidate={true}
            onSubmit={(event) => {
                event.preventDefault();
                const value = get(event, 'target.elements.giftCard.value') || '';

                if (value) {
                    onRedeem(value);
                }
            }}
        >
            <OR115GiftInp
                name="giftCard"
                pinName="pin"
                giftCodeLabel="Enter your gift card here"
                giftPinLabel="Enter your gift card PIN here"
                errorMessage={errorMessage}
                disabled={isRedeeming}
                giftPin={giftPin}
                onChangeGiftCode={handleGiftCodeChange}
                onChangeGiftPin={handleGiftPinChange}
            />
            <BA01BtnPr
                type="submit"
                disabled={!!isRedeeming || !giftCode}
            >
                {isRedeeming ? (
                    <ButtonIconBox>
                        <IC103Loading color={white} />
                    </ButtonIconBox>
                ) : 'Redeem'}
            </BA01BtnPr>
        </GiftAccount>
    );
}

OR118GiftAccount.displayName = 'OR118GiftAccount';

OR118GiftAccount.propTypes = {
    className: classNameType,
    /** Are we waiting for a gift card redemption response? */
    isRedeeming: propTypes.bool,
    /** Error message to display */
    errorMessage: propTypes.node,
    /** Send a request to redeem a gift card code */
    onRedeem: propTypes.func,
    /** Entered gift code change callback */
    onChangeGiftCode: propTypes.func,
    /** Entered gift PIN change callback */
    onChangeGiftPin: propTypes.func,
};
