import React from 'react';

import Ic from '..';

const IC331Reallife = ({
    ...otherProps
}) => (
    <Ic {...otherProps}>
        <g>
            <path
                d="M77.9 13.7c1.9 0 3.7.8 5 2.1s2.1 3 2.1 5v33.7c0 1.9-.8 3.7-2.1 5-1.3 1.3-3 2.1-5 2.1H54.2c-2.4 0-4.8.9-6.6 2.5L34.5 75.9c-.7.6-1.5.9-2.4.8s-1.7-.4-2.3-1.1c-.5-.6-.8-1.4-.8-2.2v-2c0-2.7-1.1-5.2-2.9-7-1.8-1.8-4.3-2.9-7-2.9H16c-1.9 0-3.7-.8-5-2.1-1.3-1.3-2.1-3-2.1-5V20.7c0-1.9.8-3.7 2.1-5s3-2.1 5-2.1h61.9z"
                opacity="0.2"
            />
            <path
                d="M77.9 13.7c1.9 0 3.7.8 5 2.1s2.1 3 2.1 5v33.7c0 1.9-.8 3.7-2.1 5-1.3 1.3-3 2.1-5 2.1H54.2c-2.4 0-4.8.9-6.6 2.5L34.5 75.9c-.7.6-1.5.9-2.4.8s-1.7-.4-2.3-1.1c-.5-.6-.8-1.4-.8-2.2v-2c0-2.7-1.1-5.2-2.9-7-1.8-1.8-4.3-2.9-7-2.9H16c-1.9 0-3.7-.8-5-2.1-1.3-1.3-2.1-3-2.1-5V20.7c0-1.9.8-3.7 2.1-5s3-2.1 5-2.1h61.9z"
                fill="none"
                stroke="#dfdfdf"
                strokeWidth=".7875"
            />
        </g>
    </Ic>
);

IC331Reallife.displayName = 'IC331Reallife';

export default IC331Reallife;
