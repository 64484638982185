import React from 'react';
import {colorType} from '@fsa-streamotion/custom-prop-types';

import {white, black} from '../../../../common/palette';
import Ic from '..';

const IC192ClseCap = ({color = white, copyColor = black, ...args}) => (
    <Ic {...args} color={color}>
        <path d="M88.928 24H10.911C9.9 24 9 24.95 9 26.02v46.586c0 1.07.9 2.02 1.911 2.02h78.017c1.012 0 1.911-.95 1.911-2.02V26.02c0-1.188-.787-2.02-1.91-2.02z" />
        <path fill={copyColor} d="M48.239 53.8l1.796 4.193c-1.164 1.198-3.593 3.128-8.285 3.128-6.755 0-12.046-4.459-12.046-11.846 0-7.42 5.358-11.846 12.046-11.846 4.725 0 7.287 1.963 7.886 2.595l-1.664 4.46c-.432-.566-2.895-2.396-5.923-2.396-3.893 0-6.888 2.595-6.888 7.087 0 4.492 3.128 7.055 6.888 7.055 2.862 0 4.925-1.132 6.19-2.43zm21.629 0l1.796 4.193c-1.164 1.198-3.593 3.128-8.285 3.128-6.755 0-12.046-4.459-12.046-11.846 0-7.42 5.358-11.846 12.046-11.846 4.725 0 7.287 1.963 7.886 2.595l-1.664 4.46c-.432-.566-2.895-2.396-5.923-2.396-3.893 0-6.888 2.595-6.888 7.087 0 4.492 3.128 7.055 6.888 7.055 2.862 0 4.925-1.132 6.19-2.43z" />
    </Ic>
);

IC192ClseCap.displayName = 'IC192ClseCap';

IC192ClseCap.propTypes = {
    color: colorType,
    copyColor: colorType,
};

export default IC192ClseCap;
