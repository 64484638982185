import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import styled, {css} from 'styled-components';
import {mediaQuery, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {ariaPressedType, buttonType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {A, Button} from '../../../../common/normalized-styled-components';
import baseCtaButtonStyles, {ChildrenWrapper} from '../../../../common/button-utils/base-styles';
import CheckMarkCircle from '../../../../common/button-utils/checked-marked-circle';
import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import {
    cobalt,
    deepIndigo,
    hubblGreen,
    indigo,
    white,
} from '../../../../common/palette';

const baseStyles = css`
    ${baseCtaButtonStyles}
    border-radius: 35px;
    background-color: ${hubblGreen};
    padding: 14px 21px;
    height: 60px;
    color: ${indigo};

    ${stylesWhenNot('isBlock')`
        width: 250px;

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            width: 400px;
            max-width: 400px;
        `}
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding: 14px 21px;
        border-radius: 46px;
        height: 82px;
    `}

    &:disabled {
        opacity: 0.2;
        cursor: not-allowed;
    }

    // Design requirement for this button to appear default state when focused
    // Since this button is used exclusively in 2 places, both modals with focus traps
    &:focus {
        background-color: ${hubblGreen};
        color: ${indigo};
    }

    &:hover {
        background-color: ${cobalt};
        color: ${white};
    }

    &:active {
        background-color: ${deepIndigo};
        color: ${white};
    }
`;

const ButtonTextBase = styled.span`
    display: block;
`;

const ButtonPrimaryText = styled(ButtonTextBase)`
    font: var(--quicksilver-button-cta);
    font-size: 14px;
    font-weight: ${({fontWeight}) => fontWeight};
    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        font-size: 24px;
    `}
`;

const ButtonSubText = styled(ButtonTextBase)`
    margin-top: 5px;
    font: var(--quicksilver-button-cta);
    font-weight: 500;
    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        margin-top: 0px;
    `}
`;

const StyledButton = styled(Button).attrs(({isPressed}) => ({
    'aria-pressed': isPressed,
}))`
    ${baseStyles};

    appearance: none;
    border: 0;
`;

const StyledLink = styled(A).attrs(({isPressed}) => ({
    'aria-current': isPressed,
}))`
    ${baseStyles};

    text-decoration: none;
`;

/**
 * Double Primary CTA button
 *  - Used for the main primary user action of the page with secondary or sub text.
 *  - text is dynamic length
 *
 * @see {@link https://app.zeplin.io/project/61a0229d36013d8b6df665f7/screen/6565279a5d23431fa33196a0}
 */
const BA57BtnDoublPr = React.forwardRef(({
    primaryText = '',
    subText = '',
    primaryTextFontWeight = 'normal',
    className,
    disabled,
    href,
    isBlock,
    isPressed,
    onClick,
    type = 'button',
    ...htmlAttributes
}, ref) => {
    const isCtaLink = href && !disabled; // coerce disabled CTAs into being buttons
    const StyledElement = isCtaLink ? StyledLink : StyledButton;

    return (
        <StyledElement
            {...htmlAttributes}
            className={classnames('BA57BtnDoublPr', className)}
            disabled={disabled}
            href={href}
            isBlock={isBlock}
            isPressed={isPressed}
            onClick={onClick}
            ref={ref}
            // We only want to put a type for buttons
            type={isCtaLink ? undefined : type}
        >
            <ChildrenWrapper>
                {!!primaryText && <ButtonPrimaryText fontWeight={primaryTextFontWeight}>{primaryText}</ButtonPrimaryText>}
                {!!subText && <ButtonSubText>{subText}</ButtonSubText>}
            </ChildrenWrapper>

            <CheckMarkCircle isPressed={isPressed && !disabled} />
        </StyledElement>
    );
});

BA57BtnDoublPr.propTypes = {
    /** Primary text */
    primaryText: propTypes.string,
    /** Sub text */
    subText: propTypes.string,
    /** Primary text font weight. Accept all possible value of font-weight */
    primaryTextFontWeight: propTypes.string,
    /** Additional CSS class(es) */
    className: classNameType,
    /** Is this button disabled? */
    disabled: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    /** If it’s actually a link, provide a href */
    href: propTypes.string,
    /** Whether the button fills its parent's width or not */
    isBlock: propTypes.bool,
    /** passed to `aria-pressed` for buttons or `aria-current` for anchors to indicate selection status */
    isPressed: ariaPressedType,
    /** Callback on element click */
    onClick: propTypes.func,
    /** The HTML type of the button */
    type: buttonType,

};

BA57BtnDoublPr.displayName = 'BA57BtnDoublPr';

export default BA57BtnDoublPr;
