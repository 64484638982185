import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {SCREEN_PHONE, SCREEN_768_TABLET, SCREEN_1280_DESKTOP} from '../../../../common/screen-sizes';

import {IC50Success} from '../../../atoms/ic';
import GA30AppStore from '../../../atoms/ga/30-app-store';
import GA92BrandLogo from '../../../atoms/ga/92-brand-logo';
import BA01BtnPr from '../../../atoms/ba/01-btn-pr';
import {satellite, silverLining, ironStone} from '../../../../common/deprecated-mui-palette';

const LOGO_WRAPPER_HEIGHT_PX = [
    [SCREEN_PHONE, 63, 63],
    [SCREEN_1280_DESKTOP, 78, 0],
];

const PageContainer = styled.section`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${ironStone};
    width: 100%;
    min-height: 100vh;
`;

const LayoutContainer = styled.div`
    display: grid;
    grid-row-gap: 49px;
    margin: 0 auto;
    padding-top: 84px;
    width: 300px;
    text-align: center;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: 500px;
    `}
`;

const CopyContainer = styled.div`
    display: grid;
    grid-row-gap: 14px;
    margin: 0 auto;
`;

const Title = styled.h1`
    margin: 0;
    color: ${silverLining};
    font: var(--nucleus-header-2-bold);
`;

const Tick = styled(IC50Success)`
    display: inline-block;
    margin: 0 auto;
`;

const Copy = styled.p`
    margin: 0;
    color: ${satellite};
    font: var(--nucleus-body-copy-2);
`;

const EmailInfo = styled.div`
    display: grid;
    grid-row-gap: 7px;
    margin: 21px 0 56px;
`;

const Email = styled.p`
    margin: 14px 0 0;
    color: ${silverLining};
    font: var(--nucleus-body-copy-1);
`;

const ChangeEmailCopy = styled(Copy)`
    font: var(--nucleus-body-copy-3);
`;

const StyledLink = styled.a`
    display: block;
    color: inherit;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        display: none;
    `}
`;

const ConditionalButton = styled(BA01BtnPr)`
    display: none;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        display: inline-flex;
    `}
`;

const LogoWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;

    ${LOGO_WRAPPER_HEIGHT_PX.map(([minWidthPx, heightPx, fixedHeightPx]) => fixedHeightPx ? mediaQuery({minWidthPx})`
        padding-bottom: 7px;
        height: ${heightPx}px;
    ` : mediaQuery({minWidthPx})`
        height: ${heightPx}px;
    `)}
`;

const LogoBox = styled.div`
    margin: 0 auto;
    width: 80px;

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        width: 97px;
    `}
`;

const CheckoutPageSuccess = ({
    appStoreLinks,
    brandSrcsetOptions,
    email,
    onClickContinue,
    profileHref = '',
}) => (
    <PageContainer>
        <LogoWrapper>
            <LogoBox>
                <GA92BrandLogo srcsetOptions={brandSrcsetOptions} />
            </LogoBox>
        </LogoWrapper>
        <LayoutContainer>
            <CopyContainer>
                <Tick size="100px" />
                <Title>You're all done!</Title>
                <EmailInfo>
                    <Copy>You are now logged in with the following email address</Copy>
                    <Email>{email}</Email>
                    <ChangeEmailCopy>Wrong email? You can change it in My Account.</ChangeEmailCopy>
                </EmailInfo>
                <Copy>Time to go get the Kayo Sports app and start watching</Copy>
            </CopyContainer>
            <GA30AppStore links={appStoreLinks} />
            <Copy>
                <StyledLink href={profileHref} onClick={onClickContinue}>Continue to the web version</StyledLink>
                {/* render a button as the CTA on larger screens to add emphasis to primary action */}
                <ConditionalButton href={profileHref} onClick={onClickContinue}>Continue to Kayo</ConditionalButton>
            </Copy>
        </LayoutContainer>
    </PageContainer>
);

CheckoutPageSuccess.propTypes = {
    appStoreLinks: propTypes.arrayOf(propTypes.shape({
        store: propTypes.string,
        href: propTypes.string,
        label: propTypes.string,
    })),
    /** Optional srcset to use for the brand logo */
    brandSrcsetOptions: propTypes.objectOf(propTypes.string),
    email: propTypes.string,
    onClickContinue: propTypes.func,
    profileHref: propTypes.string,
};

CheckoutPageSuccess.displayName = 'CheckoutPageSuccess';

export default CheckoutPageSuccess;
