import React from 'react';

import Ic from '..';

const IC554UpMarketing = (args) => (
    <Ic {...args}>
        <path d="M64.7 35.5H15.2c-.4 0-.8.3-.8.8v27.6c0 .4.3.8.8.8h49.5c.4 0 .8-.3.8-.8V36.2c-.1-.4-.4-.7-.8-.7zm-.8 27.6h-48V37h48v26.1zM84.7 56.4h-16c-.4 0-.8.3-.8.8v6.6c0 .4.3.8.8.8h16c.4 0 .8-.3.8-.8v-6.6c0-.5-.4-.8-.8-.8zm-.8 6.6H69.5v-5.1H84l-.1 5.1zM84.7 46.3h-16c-.4 0-.8.3-.8.8v6.6c0 .4.3.8.8.8h16c.4 0 .8-.3.8-.8V47c0-.4-.4-.7-.8-.7zm-.8 6.5H69.5v-5.1H84l-.1 5.1zM84.2 35.4H69.6c-.8 0-1.4.6-1.4 1.4v6c0 .8.6 1.4 1.4 1.4h14.6c.8 0 1.4-.6 1.4-1.4v-6c-.1-.7-.7-1.4-1.4-1.4zm-1.5 6H70.9v-3.2h11.7l.1 3.2z" />
    </Ic>
);

IC554UpMarketing.displayName = 'IC554UpMarketing';

export default IC554UpMarketing;
