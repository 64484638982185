import React from 'react';
import bacon from 'baconjs';

import journeyDetailsValidate from '../../../../../todo-move-to-widgets-common/streams/endpoints/billing/journey-details-validate';
import ClubsJourney from '../../../components/branded/checkout/journey/clubs';

export default function journeyClubs({
    platformEnv,
    ratePlanId,
    user,
    voucherCode,
    brand,
}) {
    const validateJourneyBus = new bacon.Bus();
    const clearErrorMessage = new bacon.Bus();

    const journeyDetails$ = validateJourneyBus
        .map(async (clubs) => ({
            platformEnv,
            accessToken: await user.getAccessToken(),
            voucherCode,
            ratePlanId,
            journeyDetails: {
                clubs,
            },
        }))
        .flatMapLatest(bacon.fromPromise)
        .flatMapLatest((args) => journeyDetailsValidate(args).map(args.journeyDetails)) // Success has no body/value/anything at this stage so map `journeyDetails` cause it ends up in subscribe call at the end.
        .toProperty(null);

    const journeyDetailsError$ = bacon.mergeAll(
        validateJourneyBus.map(undefined),
        clearErrorMessage.map(undefined),
        journeyDetails$.map(undefined),
        journeyDetails$
            .errors()
            .mapError('.message')
    )
        .toProperty(undefined);

    const isJourneyCompleted$ = journeyDetails$.map((journeyDetails) => (
        journeyDetails !== null // done when we've got some valid journeyDetails
    ))
        .startWith(false);

    const isLoading$ = bacon.mergeAll(
        validateJourneyBus.map(true),
        journeyDetails$.map(false),
        journeyDetailsError$.filter(Boolean).map(false)
    )
        .toProperty(false)
        .skipDuplicates();

    const journeyComponent$ = bacon.combineTemplate({
        isCompleted: isJourneyCompleted$,
        isLoading: isLoading$,

        acceptedMembership: journeyDetails$.map('.clubs.membershipId'),

        errorMessage: journeyDetailsError$,

        onSubmit: (details) => void validateJourneyBus.push(details),
        onChange: () => void clearErrorMessage.push(),
    }).map((props) => (
        <ClubsJourney
            brand={brand}
            key="clubs"
            {...props}
        />
    ));

    return bacon.combineTemplate({
        // We always show either summary or the active form asking for details
        isJourneyShown: true,

        journeyComponent: journeyComponent$,
        isJourneyCompleted: isJourneyCompleted$,
        journeyDetails: journeyDetails$,
    });
}
