import React, {useEffect} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import noop from 'lodash/noop';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {blueCharcoal} from '../../../common/palette';

const Z_INDEX_BELOW_CONTENT = -1;

const WebAppContainer = styled.div`
    position: relative;

    &::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: ${Z_INDEX_BELOW_CONTENT};
        background-color: ${blueCharcoal};
        content: '';
    }
`;

const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    z-index: 0;
    height: 100%;
`;

const WebApp = ({
    children,
    modalComponent,
    greenScreen,
    onMount = noop,
    className,
}) => {
    useEffect(onMount, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <WebAppContainer className={classnames('WebApp', className)}>
            <FlexContainer>
                {greenScreen}
                {children}
            </FlexContainer>
            {modalComponent}
        </WebAppContainer>
    );
};

WebApp.propTypes = {
    children: propTypes.node,
    greenScreen: propTypes.node,
    modalComponent: propTypes.node,
    onMount: propTypes.func,
    className: classNameType,
};

WebApp.displayName = 'WebApp';

export default WebApp;
