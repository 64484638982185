/* eslint-disable camelcase */
// Prefer to use stats API names verbatim
import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import property from 'lodash/property';
import sortBy from 'lodash/sortBy';
import times from 'lodash/times';
import {readableColor, mix} from 'polished';
import {stylesIfElse} from '@fsa-streamotion/styled-component-helpers';
import {colorType} from '@fsa-streamotion/custom-prop-types';

import {ironStone, silverLining, slate} from '../../../../common/deprecated-mui-palette';

const BALLS_IN_STANDARD_OVER = 6;

const Container = styled.div`
    color: ${silverLining};
`;

const Title = styled.h3`
    margin: 0 0 7px;
    font: var(--ionic-hud-tile-copy-light);
`;

const BallListPositioner = styled.div`
    position: relative;
`;

const BallList = styled.ol`
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    margin: 7px 0;
    padding: 0;
    list-style: none;
`;

const HorizontalLine = styled.hr`
    position: absolute;
    top: ${({isLongerOver}) => isLongerOver ? '15px' : '17px'};
    left: 0;
    margin: 0;
    border: 0;
    border-bottom: 1px solid ${silverLining};
    width: 100%;
`;

const BallListItem = styled.li`
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(to left, ${({color, backgroundColor}) => mix(0.46, color, backgroundColor)}, ${property('color')});
    text-align: center;
    font: var(--ionic-hud-tile-copy-bold);

    ${stylesIfElse('isLongerOver')`
        height: 30px;
        width: 30px;
        border-radius: 15px;
    ``
        height: 35px;
        width: 35px;
        border-radius: 18px;
    `}
`;

const Abbr = styled.abbr`
    text-decoration: none;
`;

const OR88OverCar = ({
    title = 'This Over',
    balls = [],
    color = slate,
    backgroundColor = ironStone,
}) => (
    <Container className="OR88OverCar">
        <Title>{title}</Title>
        <BallListPositioner>
            <HorizontalLine
                isLongerOver={balls.length > BALLS_IN_STANDARD_OVER}
            />
            <BallList
                style={{color: readableColor(mix(0.27, color, backgroundColor))}}
            >
                {sortBy(balls, 'ball').map(({byes, total_runs, wides, leg_byes, is_wicket, no_balls}, index) => {
                    let prefix = '';
                    let symbol = '';

                    if (is_wicket) {
                        symbol = <Abbr title="Wicket">W</Abbr>;
                        if (total_runs) {
                            prefix = total_runs;
                        }
                    } else if (byes) {
                        prefix = total_runs;
                        symbol = <Abbr title="Byes">B</Abbr>;
                    } else if (wides) {
                        prefix = total_runs > 1 ? total_runs : prefix;
                        symbol = <Abbr title="Wide">WD</Abbr>;
                    } else if (leg_byes) {
                        prefix = total_runs;
                        symbol = <Abbr title="Leg byes">LB</Abbr>;
                    } else if (no_balls) {
                        prefix = total_runs > 1 ? total_runs : prefix;
                        symbol = <Abbr title="No ball">NB</Abbr>;
                    } else if (total_runs) {
                        prefix = total_runs;
                    } else {
                        // No runs and nothing else interesting, so show a dot ball
                        symbol = <Abbr title="Dot ball">·</Abbr>;
                    }

                    return (
                        <BallListItem
                            key={`ball_${index}`}
                            color={color}
                            backgroundColor={backgroundColor}
                            isLongerOver={balls.length > BALLS_IN_STANDARD_OVER}
                        >
                            {prefix}{symbol}
                        </BallListItem>
                    );
                })}
                {times(Math.max(0, BALLS_IN_STANDARD_OVER - balls.length), (index) => (
                    <BallListItem
                        key={`empty_${index + balls.length}`}
                        color={color}
                        backgroundColor={backgroundColor}
                        isLongerOver={false}
                    />
                ))}
            </BallList>
        </BallListPositioner>
    </Container>
);

OR88OverCar.propTypes = {
    /** Optional tile title */
    title: propTypes.string,
    /** API response from latestballbyball */
    balls: propTypes.arrayOf(propTypes.shape({
        ball: propTypes.number,
        byes: propTypes.number,
        total_runs: propTypes.number,
        wides: propTypes.number,
        leg_byes: propTypes.number,
        is_wicket: propTypes.bool,
        no_balls: propTypes.number,
    })),
    /** Team color */
    color: colorType,
    /** Card background color (used for gradient fade) */
    backgroundColor: colorType,
};

OR88OverCar.displayName = 'OR88OverCar';

export default OR88OverCar;
