import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';

export const visuallyHiddenBaseStyles = css`
    position: absolute;
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
`;

export const StyledVisuallyHidden = styled.div`
    ${visuallyHiddenBaseStyles}
`;

const VisuallyHidden = ({
    children,
    ...passThroughProps
}) => (
    <StyledVisuallyHidden {...passThroughProps}>
        {children}
    </StyledVisuallyHidden>
);

VisuallyHidden.propTypes = {
    children: propTypes.node,
};

VisuallyHidden.displayName = 'VisuallyHidden';

export default VisuallyHidden;
