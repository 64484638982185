import React, {useState} from 'react';
import noop from 'lodash/noop';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import VisuallyHidden from '../../../../common/visually-hidden';
import formatCurrency from '../../../../common/format-currency';
import {cobalt, ink, mist, white} from '../../../../common/palette';
import {SCREEN_1920_DESKTOP, SCREEN_375_PHABLET} from '../../../../common/screen-sizes';

import {IC58Info} from '../../../atoms/ic';

const PaymentTable = styled.table`
    border: 0;
    width: 100%;
    border-spacing: 0;
    color: ${rgba(ink, 0.7)};
    font: var(--quicksilver-body-copy-4);
`;

const DetailsHeading = styled.th`
    padding: 10px 0;
    text-align: left;
    font: var(--quicksilver-body-copy-4-medium);
`;

const PriceHeading = styled.th`
    padding: 10px 0;
    text-align: right;
    font: var(--quicksilver-body-copy-4-medium);

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 83px;
    `}
`;

const DateHeading = styled.th`
    padding: 10px 0;
    width: 55px;
    text-align: left;
    font: var(--quicksilver-body-copy-4-medium);

    ${mediaQuery({minWidthPx: SCREEN_375_PHABLET})`
        width: 100px;
    `}
`;

const DateRow = styled.th`
    padding: 10px 0;
    width: 55px;
    vertical-align: top;
    text-align: left;
    font: var(--quicksilver-body-copy-4);

    ${mediaQuery({minWidthPx: SCREEN_375_PHABLET})`
        width: 100px;
    `}
`;

const DetailsRow = styled.td`
    padding: 10px 0;
    vertical-align: top;
    font: var(--quicksilver-body-copy-4-light);
`;

const PriceRow = styled.td`
    padding: 10px 0;
    width: 49px;
    vertical-align: top;
    text-align: right;
    font: var(--quicksilver-body-copy-4-light);

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 83px;
    `}
`;

const IconRow = styled.td`
    padding: 10px 0;
    width: 35px;
    vertical-align: top;

    &:nth-child(4) {
        width: 50px;
    }
`;

const IconWrapper = styled.div`
    display: flex;
    justify-content: right;
    cursor: pointer;
`;

const SummaryRow = styled.th`
    padding: 0;
    font: var(--quicksilver-body-copy-4);
`;

const SummaryDetail = styled.dl`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 10px 0 28px;
    border-radius: 5px;
    background: ${white};
    padding: 15px;
`;

/** It's okay to wrap dt and dd tag with div
 * (https://html.spec.whatwg.org/multipage/grouping-content.html#the-dl-element)
 * */
const SummaryWrapper = styled.div`
    display: flex;
    flex-direction: column;

    ${mediaQuery({minWidthPx: SCREEN_375_PHABLET})`
        flex-direction: row;
    `}
`;

const SummaryKey = styled.dt`
    margin: 0 10px 0 0;
    text-align: left;
    font: var(--quicksilver-body-copy-4);

    &::after {
        content: ' :';
    }
`;

const SummaryValue = styled.dd`
    margin: 0;
    text-align: left;
    font: var(--quicksilver-body-copy-4-light);
`;

const PaymentInformation = styled.caption`
    padding: 20px 10px 10px;
    caption-side: bottom;
    font: var(--quicksilver-body-copy-4-medium);
`;

// A table displaying payment list in the user's account.
/**
 * @TODO - This table will have a download icon for each row later
 *         we can control the appearance of the button using props like either have a download functionality or not
 *         so we can reuse this payment table for another similar purpose which doesn't need download functionality
 */
const MyAccountPaymentTables = ({
    initialOpenedIndex = -1,
    billingSummary = [],
    noPaymentsInfo = 'No payment data',
    onInfoIconClick = noop,
    ...htmlAttributes
}) => {
    /** Set -1 as default as it's meant to be 0 based index */
    const [openedIndex, setOpenedIndex] = useState(initialOpenedIndex);
    const onInfoClickHandler = ({index, pmntRowData}) => () => {
        const isCurrentlyOpened = openedIndex === index;

        /** If user clicks the same index, then close it otherwise, open it */
        setOpenedIndex(isCurrentlyOpened ? -1 : index);

        onInfoIconClick({
            index,
            pmntRowData,
            isOpen: !isCurrentlyOpened,
        });
    };

    return (
        <PaymentTable {...htmlAttributes}>
            {
                Array.isArray(billingSummary) && billingSummary.length > 0
                    ? <VisuallyHidden as="caption">Payment Summary</VisuallyHidden>
                    : <PaymentInformation>{noPaymentsInfo}</PaymentInformation>
            }
            <thead>
                <tr>
                    <DateHeading scope="col">Date</DateHeading>
                    <DetailsHeading scope="col">Details</DetailsHeading>
                    <PriceHeading scope="col">Total</PriceHeading>
                    <VisuallyHidden as="th">Additional Information</VisuallyHidden>
                </tr>
            </thead>
            <tbody>
                {Array.isArray(billingSummary) && billingSummary.length > 0
                && billingSummary.map(({details, date, amount, additionalDetails}, index) => (
                    <React.Fragment key={index}>
                        <tr>
                            <DateRow scope="row">{date}</DateRow>
                            <DetailsRow>{details}</DetailsRow>
                            <PriceRow>{formatCurrency({amount})}</PriceRow>
                            <IconRow>
                                <IconWrapper
                                    onClick={onInfoClickHandler({
                                        index,
                                        pmntRowData: {details, date, amount, additionalDetails},
                                    })}
                                >
                                    <IC58Info
                                        ariaLabel={`View additional information for ${details} on ${date}`}
                                        color={index === openedIndex ? cobalt : undefined}
                                        secondaryColor={index === openedIndex ? mist : undefined}
                                        size="25px"
                                    />
                                </IconWrapper>
                            </IconRow>
                        </tr>
                        {index === openedIndex && (
                            <tr>
                                <SummaryRow scope="row" colSpan={4}>
                                    <SummaryDetail aria-label={`Additional information for ${details} on ${date}`}>
                                        {additionalDetails.map(({key, value}) => (
                                            <SummaryWrapper key={key}>
                                                <SummaryKey>{key}</SummaryKey>
                                                <SummaryValue>{value}</SummaryValue>
                                            </SummaryWrapper>
                                        ))}
                                    </SummaryDetail>
                                </SummaryRow>
                            </tr>
                        )}
                    </React.Fragment>
                ))}
            </tbody>
        </PaymentTable>
    );
};

MyAccountPaymentTables.displayName = 'MyAccountPaymentTables';

MyAccountPaymentTables.propTypes = {
    /** Initial opened additional detail */
    initialOpenedIndex: propTypes.number,
    /** Array of billing transactions */
    billingSummary: propTypes.arrayOf(
        propTypes.shape({
            /** Details, e.g Kayo, Binge, Flash, etc */
            details: propTypes.string,
            /** Date of transaction */
            date: propTypes.string,
            /** Total amount */
            amount: propTypes.number,
            /** Array of additional information like: Payment Method, Reference Number, etc */
            additionalDetails: propTypes.arrayOf(
                propTypes.exact({
                    /** Key represents the information like: 'Payment Method', 'Reference Number' */
                    key: propTypes.string,
                    /** Value represents the information like: 'Credit Card', '22664455467BILL' */
                    value: propTypes.string,
                }),
            ),
        }),
    ),
    /** Information showed when there is no billing or payment */
    noPaymentsInfo: propTypes.string,
    /** Event fired when information icon clicked */
    onInfoIconClick: propTypes.func,
};

export default MyAccountPaymentTables;
