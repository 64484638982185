import bacon from 'baconjs';
import superagent from 'superagent';

import {isServer, nicerStringify} from '@fsa-streamotion/browser-utils';
import {BILLING_API_URL, accountsEnvs} from '@fsa-streamotion/streamotion-web-widgets-common';

const {getBillingHostedPaymentPage} = accountsEnvs;

/**
 * Sample end-point
 *
 *  https://billing-api.platform-martian-foxsports-staging.com.au/token
 *
 * @param {Object} options (see below)
 * @param {string} options.accessToken - Access token for currently logged in user
 * @param {string} options.platformEnv - Billing API environment, e.g. production
 * @param {('binge'|'flash'|'kayo')} options.brand - Brand name of the product
 * @returns {bacon.Observable} - Bacon.js observable that emits the successful API response payload or error message
 */
export default function getHostedPageParams({
    brand,
    accessToken,
    platformEnv,
} = {}) {
    // https://knowledgecenter.zuora.com/CB_Billing/LA_Hosted_Payment_Pages/B_Payment_Pages_2.0/J_Client_Parameters_for_Payment_Pages_2.0
    if (isServer()) {
        return bacon.later(0, {
            style: 'inline',
            submitEnabled: false,
        });
    }
    const {id: hostedPageId, gateway} = getBillingHostedPaymentPage({brand, platformEnv, origin: window.location.origin});

    if (!hostedPageId) {
        return bacon.later(0, new bacon.Error({
            errorCode: 0,
            errorMessage: 'Unable to load payment form. Please try again later.',
            fullErrorMessage: `Couldn\'t get hosted page params: No page ID found for environment "${platformEnv}" and origin "${window.location.origin}"`, // eslint-disable-line max-len
        }));
    }

    return bacon.fromBinder((sink) => {
        const url = [
            `${BILLING_API_URL[platformEnv]}/token`,
            nicerStringify({
                pageId: hostedPageId || undefined,
            }),
        ]
            .join('?');

        superagent
            .get(url)
            .set('Authorization', `Bearer ${accessToken}`)
            .then((response) => void sink({
                // mutate response because we need to use our individualised gateway names instead of what we got from billingapi
                ...response.body,
                paymentGateway: {
                    ...response.body.paymentGateway, // keep the PayPal one
                    creditCard: gateway,
                },
            }))
            .catch((error) => {
                sink(new bacon.Error({
                    errorCode: error.status,
                    errorMessage: 'An unexpected error occurred. Please try again later.',
                    fullErrorMessage: 'ACWidgets: couldn\'t get hosted page params. Please try again later.',
                }));
            })
            .finally(() => void sink(new bacon.End()));
    });
}
