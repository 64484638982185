import React from 'react';
import Ic from '..';

const IC289NotOff = (args) => (
    <Ic {...args}>
        <path d="M61.856 80.346c-1.951 4.432-6.076 7.499-10.854 8.071a13.58 13.58 0 0 1-12.217-5.01l-.212-.272 23.283-2.79zm4.238-58.055l-5.901 6.268c-3.156-5.146-10.28-8.792-17.046-8.112l-.31.034-.623.111c-8.188 1.378-15.39 9.72-14.743 16.939l.028.273L28.787 48.6l.207 1.758-.553 1.687-4.607 15.13L11 80.806l9.51-31.146-1.288-10.824C17.768 26.602 28.418 14.641 40.437 12.4l.365-.065 1.093-.153c9.093-1.128 18.845 3.022 24.2 10.109zm5.308 20.636l.06.498 16.5 28.078-49.047 5.928 8.958-9.514 26.454-3.193-9.044-15.298 6.119-6.499zM85.321 9.12l5.614 5.81-76.896 81.672-5.842-5.568z" />
    </Ic>
);

IC289NotOff.displayName = 'IC289NotOff';

export default IC289NotOff;
