import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import {indigo} from '../../../../common/palette';

import Ic from '..';

const IC153MultiAdd2 = ({
    secondaryColor = indigo,
    ...args
}) => (
    <Ic {...args}>
        <path d="M49.63 8C26.638 8 8 26.638 8 49.63c0 22.99 18.638 41.629 41.63 41.629 22.99 0 41.629-18.638 41.629-41.63C91.252 26.642 72.618 8.009 49.629 8z" />
        <path fill={secondaryColor} d="M62.356 66.605V60.5H46.728l6.984-6.3c2.686-2.393 7.081-5.91 7.081-11.819 0-5.909-4.64-9.962-12.258-9.962-7.619 0-11.77 4.541-12.21 5.567l3.322 5.177c1.855-2.344 4.786-4.542 8.155-4.542 3.321 0 5.421 1.856 5.421 4.737 0 2.882-1.904 5.08-5.079 7.96L36.716 61.624l.928 4.982h24.712z" />
    </Ic>
);

IC153MultiAdd2.propTypes = {
    /** The icon's secondary color */
    secondaryColor: colorType,
};

IC153MultiAdd2.displayName = 'IC153MultiAdd2';

export default IC153MultiAdd2;
