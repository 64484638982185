import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import classnames from 'classnames';
import get from 'lodash/get';

import {mediaQuery, stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {TERM_TYPE_SHORT} from '../../../../common/billing-constants';
import {termTypePropType} from '../../../../common/custom-proptypes';
import {Section, Sup} from '../../../../common/normalized-styled-components';
import {black, blueCharcoal, flash, white} from '../../../../common/palette';
import {SCREEN_1280_DESKTOP} from '../../../../common/screen-sizes';
import splitPrice from '../../../../common/split-price';

import IC31Tick from '../../ic/31-tick';

const StyledIC31Tick = styled(IC31Tick)`
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    transition: ${transition('opacity')};
    opacity: 0;
    width: 23px;
    height: 23px;

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        width: 30px;
        height: 30px;
    `}
`;

const StyledSection = styled(Section)`
    appearance: none;
    display: flex;
    position: relative;
    flex-direction: column;
    transition: ${transition('background-color', 'color')};
    border: 1px solid ${rgba(white, 0.1)};
    border-radius: 6px;
    box-shadow: 0 1px 1px 0 ${rgba(black, 0.3)};
    background: transparent;
    padding: 21px 11px 16px;
    min-width: 150px;
    text-align: center;
    text-shadow: 0 1px 1px ${rgba(black, 0.3)};
    color: ${white};

    ${stylesWhen('isActive')`
        background-color: ${white};
        text-shadow: none;
        color: ${blueCharcoal};

        ${StyledIC31Tick} {
            opacity: 1;
        }
    `}
`;

const PackagePrice = styled.div`
    position: inherit;
    margin: auto;
    padding-right: 7px;
    font: var(--infinity-sub-price);
`;

const PackageName = styled.h3`
    margin: 0;
    text-transform: uppercase;
    font: var(--infinity-package-name);
`;

const DisplayDecimals = styled(Sup)`
    position: absolute;
    top: 2px;
    font: var(--infinity-billing-frequency);
`;

const TermType = styled.span`
    font: var(--infinity-billing-frequency);
`;

const BA44OfferBtn = ({
    className,
    isActive,
    packageDetails = {},
    ...htmlAttributes
}) => {
    const {displayName} = packageDetails || {};
    const {termType, displayAmount, currencyDecimalPlaces = 2, currencyPrefix = '$'} = packageDetails?.price || {};
    const [priceIntegers, priceDecimals] = splitPrice(displayAmount, currencyDecimalPlaces);
    const termTypeShort = get(TERM_TYPE_SHORT, termType);

    return (
        <StyledSection
            {...htmlAttributes}
            className={classnames('BA44OfferBtn', className)}
            isActive={isActive}
        >
            {!!displayName && (
                <PackageName>{displayName}</PackageName>
            )}
            <PackagePrice aria-label={displayAmount ? `${currencyPrefix}${displayAmount} per ${termType}` : undefined}>
                {displayAmount ? (
                    <React.Fragment>
                        {currencyPrefix}{priceIntegers}

                        {!!priceDecimals && (
                            <DisplayDecimals>
                                .{priceDecimals}
                            </DisplayDecimals>
                        )}

                        {!!termTypeShort && (
                            <TermType>
                                /{termTypeShort}
                            </TermType>
                        )}
                    </React.Fragment>
                ) : 'Free'}
            </PackagePrice>
            <StyledIC31Tick color={flash} />
        </StyledSection>
    );
};

BA44OfferBtn.propTypes = {
    /** additional CSS classnames to be applied */
    className: classNameType,
    /** Is the package you are up/downgrading to */
    isActive: propTypes.bool,
    /** Package object from API */
    packageDetails: propTypes.shape({
        /** Name of the package */
        displayName: propTypes.string,
        /** Package pricing, right now we’re pulling out month specifically */
        price: propTypes.shape({
            termType: termTypePropType,
            displayAmount: propTypes.number,
            /** Whether, and how many decimal places to display */
            currencyDecimalPlaces: propTypes.number,
            /** What currency symbol to use */
            currencyPrefix: propTypes.oneOf(['$']),
        }),
    }),
};

BA44OfferBtn.displayName = 'BA44OfferBtn';

export default BA44OfferBtn;
