import React from 'react';
import propTypes from 'prop-types';
import {rgba} from 'polished';
import styled, {css} from 'styled-components';
import classnames from 'classnames';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {buttonType, classNameType} from '@fsa-streamotion/custom-prop-types';
import {A, Button} from '../../../../common/normalized-styled-components';
import GA43ArrowInd from '../../ga/43-arrow-ind';

import {SCREEN_1920_DESKTOP, SCREEN_1280_DESKTOP} from '../../../../common/screen-sizes';
import {ink} from '../../../../common/palette';

/** ********************************************
 *               ICON COLUMN
 ***********************************************/

// @TODO: revisit these widths when we have designs with icon for BA58
const IconWrapper = styled.div`
    max-width: 70px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        max-width: 100px;
    `}
`;

/** ********************************************
 *               TEXT & ARROW > COLUMN
 ***********************************************/

const ContentWrapper = styled.div`
    display: grid;
    row-gap: 6px;
    width: 100%;
`;

const TextAndArrowWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Title = styled.span`
    flex: 1 1 auto;
    overflow-x: hidden;
    color: ${ink};
    font: var(--quicksilver-body-copy-3);
`;

const SubText = styled.span`
    max-width: 222px;
    font: var(--quicksilver-body-copy-3-light);
    line-height: 1.1; /* stylelint-disable-line order/properties-order */

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        max-width: 300px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        max-width: 360px;
    `}
`;

const ArrowWrapper = styled.span`
    display: flex;
    align-items: center;
    justify-content: right;
    margin-left: 5px;
    padding-right: 20px;
    width: 12px;
    height: 12px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 20px;
        height: 20px;
        padding-right: 27px;
    `}
`;

/** ********************************************
 *               MAIN BUTTON
 ***********************************************/

const baseStyle = css`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border: 0;
    width: 100%;
    color: ${rgba(ink, 0.7)};
    font: var(--quicksilver-body-copy-3-light);
    /** FOCUS / HOVER STATE */
    &:hover,
    &:focus {
        outline: 0;
        color: ${ink};
        font-weight: normal;
    }
    /** ON PRESS / SELECTED STATE */
    &:active,
    &[aria-pressed='true'],
    &[aria-current='true'] {
        color: ${ink};
        font-weight: normal;
    }
`;

const StyledButton = styled(Button).attrs(({isPressed}) => ({
    'aria-pressed': isPressed,
}))`
    ${baseStyle}
    appearance: none;
    background: none;
    padding: 0;
    text-align: left;
`;

const StyledLink = styled(A).attrs(({isPressed}) => ({
    'aria-current': isPressed,
}))`
    ${baseStyle}
    text-decoration: none;
`;

const BA58HardwareAddDev = React.forwardRef(({
    className,
    href,
    icon,
    isPressed,
    onClick,
    subText,
    title,
    type = href ? undefined : 'button',
    ...htmlAttributes
}, ref) => {
    const Cta = href ? StyledLink : StyledButton;

    return (
        <Cta
            {...htmlAttributes}
            className={classnames('BA58HardwareAddDev', className)}
            isPressed={isPressed}
            href={href}
            onClick={onClick}
            ref={ref}
            type={type}
        >
            {!!icon && (
                <IconWrapper>{icon}</IconWrapper>
            )}
            <ContentWrapper>
                {!!title && (
                    <TextAndArrowWrapper>
                        <Title>{title}</Title>
                        {!subText && (/* If there is not subText, display > next to title */
                            <ArrowWrapper><GA43ArrowInd /></ArrowWrapper>
                        )}
                    </TextAndArrowWrapper>
                )}

                {!!subText && (
                    <TextAndArrowWrapper>
                        <SubText>{subText}</SubText>
                        <ArrowWrapper><GA43ArrowInd /></ArrowWrapper>
                    </TextAndArrowWrapper>
                )}
            </ContentWrapper>
        </Cta>
    );
});

BA58HardwareAddDev.propTypes = {
    /** additional CSS classnames to be applied */
    className: classNameType,
    /** callback to fire on button click */
    onClick: propTypes.func,
    /** passed to `aria-pressed` for buttons or `aria-current` for anchors to indicate selection status */
    isPressed: propTypes.oneOf([true, false, 'mixed']),
    /** hyperlink URL */
    href: propTypes.string,
    /** Icon on the left side of the text */
    icon: propTypes.node,
    /** react ref, e.g., as passed in by React.forwardRef */
    ref: propTypes.string,
    /** Subtext under the title */
    subText: propTypes.node,
    /** title of the cta */
    title: propTypes.string.isRequired,
    /** html button type */
    type: buttonType,
};

BA58HardwareAddDev.displayName = 'BA58HardwareAddDev';

export default BA58HardwareAddDev;
