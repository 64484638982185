import {css} from 'styled-components';
import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {
    SCREEN_375_PHABLET,
    SCREEN_768_TABLET,
    SCREEN_1920_DESKTOP,
} from '../../../../../common/screen-sizes';

/**
 * All these values are later on used:
 * - to calculate the max-width of the entire input container
 * - and to keep things aligned no matter what screensize
 *
 * This is so:
 *  - both the input fields row and the contextual
 *    help row will ALWAYS have the same width
 *
 *  - AND the right ^ arrow of contextual help
 *    will ALWAYS be centered against the last
 *    box input
 */
const sizingStyle = css`
    --im04-box-input-width: 38px;
    --im04-box-input-height: 42.5px;
    --im04-context-arrow-size: 10px;
    --im04-box-input-space-in-between: 5px;
    ${mediaQuery({minWidthPx: SCREEN_375_PHABLET})`
        --im04-box-input-width: 50px;
        --im04-box-input-height: 57px;
        --im04-box-input-space-in-between: 5.3px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        --im04-box-input-width: 44px;
        --im04-box-input-height: 50px;
        --im04-context-arrow-size: 14px;
        --im04-box-input-space-in-between: 6px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        --im04-box-input-width: 64px;
        --im04-box-input-height: 74px;
        --im04-box-input-space-in-between: 14px;
    `}
`;

export default sizingStyle;
