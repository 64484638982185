import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';

import {stylesWhen, mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {transition} from '../../../../common/animations';
import {coal, ironStone} from '../../../../common/palette';
import {SCREEN_REALLY_LG_DESKTOP} from '../../../../common/screen-sizes';
import IC103Loading from '../../../atoms/ic/103-loading';

const StyledSection = styled.section`
    display: grid;
    bottom: 0;
    left: 0;
    grid-template-areas: 'left center right';
    grid-template-columns: 1fr auto 1fr;
    grid-gap: 10px;
    align-items: center;
    transform: translateY(100%);
    transition: ${transition('transform', 'opacity')};
    opacity: 0;
    border-top: 1px solid ${rgba(ironStone, 0.7)};
    background-color: ${rgba(coal, 0.9)};
    padding: 14px 0;
    width: 100%;
    text-align: center;

    ${stylesWhen('isVisible')`
        transform: translateY(0);
        opacity: 1;
    `}

    ${mediaQuery({minWidthPx: SCREEN_REALLY_LG_DESKTOP})`
        height: 177px;
    `}
`;

const LeftDiv = styled.div`
    grid-area: left;
    text-align: right;
`;

const CenterDiv = styled.div`
    grid-area: center;
`;

const RightDiv = styled.div`
    grid-area: right;
    text-align: left;
`;

const OR41PageNav = ({
    buttonLeft,
    buttonRight,
    buttonCenter,
    isLoading,
    isVisible = true,
}) => (
    <StyledSection
        className="OR41PageNav"
        isVisible={isVisible}
    >
        {isLoading ? (
            <CenterDiv>
                <IC103Loading size="47px" />
            </CenterDiv>
        ) : (
            <React.Fragment>
                {!!buttonLeft && <LeftDiv>{buttonLeft}</LeftDiv>}
                {!!buttonCenter && <CenterDiv>{buttonCenter}</CenterDiv>}
                {!!buttonRight && <RightDiv>{buttonRight}</RightDiv>}
            </React.Fragment>
        )}
    </StyledSection>
);

OR41PageNav.displayName = 'OR41PageNav';

OR41PageNav.propTypes = {
    /** Button atom on the center */
    buttonCenter: propTypes.node,
    /** Button atom on the left */
    buttonLeft: propTypes.node,
    /** Button atom on the right */
    buttonRight: propTypes.node,
    /** If the organism is in loading state, to show the loading icon */
    isLoading: propTypes.bool,
    /** If the organism is visible or not, for animation */
    isVisible: propTypes.bool,
};

export default OR41PageNav;
