import React from 'react';
import propTypes from 'prop-types';
import property from 'lodash/property';
import styled from 'styled-components';
import noop from 'lodash/noop';

import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {kayoGreen, white} from '../../../../common/palette';
import handleFormSubmit from '../../../../common/get-handle-form-submit';

import BA01BtnPr from '../../../atoms/ba/01-btn-pr';
import IC103Loading from '../../../atoms/ic/103-loading';
import IM01Input from '../../../molecules/im/01-input';
import IC43Valid from '../../../atoms/ic/43-valid';
import IA02Check from '../../../atoms/ia/02-check';

const FormOverlay = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

const Form = styled.form`
    padding: 28px;
    width: 100%;
    max-width: 560px;
    text-align: center;
`;

const FormFieldContainer = styled.div`
    margin-right: auto;
    margin-left: auto;
    padding: 8px 0;
    max-width: 320px;
    ${stylesWhen('textAlign')`
        text-align: ${property('textAlign')};
    `}
`;

const FormFieldContainerStyled = styled(FormFieldContainer)`
    padding: 30px 0;
    text-align: left;
`;

const InfoText = styled.p`
    color: ${white};
    font: var(--mui-body-copy-4);
`;

class OR109ChangePw extends React.Component {
    static displayName = 'OR109ChangePw';

    static propTypes = {
        /** If set, an error message to be displayed to the user */
        errorMsg: propTypes.string,
        /** A custom HTML name for the form */
        name: propTypes.string,
        /** A callback to handle submission of the form data */
        onSubmit: propTypes.func,
        /** Whether or not the form is in a loading state */
        isLoading: propTypes.bool,
        /** Text to describe password validation */
        passwordHelpText: propTypes.string,
        /** Success msg e.g 'We have sent you an email to reset your password */
        successMsg: propTypes.string,
    };

    static defaultProps = {
        passwordHelpText: 'Your password must contain at least 8 characters, including lower case letters, upper case letters, numbers, and special characters', // eslint-disable-line max-len
    };

    state = {
        currentPassword: null,
        newPassword: null,
        confirmPassword: null,
    };

    onUpdate = (name, value) => {
        this.setState({
            [name]: value,
        });
    };

    render() {
        const {
            errorMsg,
            name,
            isLoading,
            passwordHelpText,
            successMsg,
            onSubmit = noop,
        } = this.props;

        const passwordsExistAndMatch = this.state.newPassword === this.state.confirmPassword && !!this.state.newPassword;

        return (
            <FormOverlay>
                <Form
                    onSubmit={handleFormSubmit(onSubmit, ['current-password', 'new-password', 'confirm-password', 'require-device-signin'])}
                    name={name}
                    // prevents form submission before js executes (see martian-web-server for implementation)
                    data-disabled-form-submit="true"
                >
                    {!successMsg && (
                        <React.Fragment>
                            <FormFieldContainer>
                                <IM01Input
                                    type="password"
                                    label="Current password"
                                    name="current-password"
                                    required={true}
                                    disabled={isLoading}
                                    onChange={({target}) => void this.onUpdate('currentPassword', target.value)}
                                    icon={!!this.state.currentPassword && <IC43Valid color={kayoGreen} />}
                                />
                            </FormFieldContainer>
                            <InfoText>Please enter a new password</InfoText>
                            <InfoText>{passwordHelpText}</InfoText>
                            <FormFieldContainer>
                                <IM01Input
                                    type="password"
                                    label="New password"
                                    name="new-password"
                                    required={true}
                                    disabled={isLoading}
                                    onChange={({target}) => void this.onUpdate('newPassword', target.value)}
                                    icon={!!this.state.newPassword && <IC43Valid color={kayoGreen} />}
                                />
                            </FormFieldContainer>
                            <FormFieldContainer>
                                <IM01Input
                                    type="password"
                                    label="Confirm new password"
                                    name="confirm-password"
                                    required={true}
                                    disabled={isLoading}
                                    onChange={({target}) => void this.onUpdate('confirmPassword', target.value)}
                                    icon={!!passwordsExistAndMatch && <IC43Valid color={kayoGreen} />}
                                />
                            </FormFieldContainer>
                            <FormFieldContainerStyled>
                                <IA02Check
                                    label="Require all devices to sign in again with new password"
                                    name="require-device-signin"
                                    isChecked={true}
                                />
                            </FormFieldContainerStyled>
                            <FormFieldContainer>
                                {isLoading ? (
                                    <IC103Loading size="47px" ariaLabel="Loading form" />
                                ) : (
                                    <BA01BtnPr
                                        type="submit"
                                        disabled={!(!!passwordsExistAndMatch && !!this.state.currentPassword)}
                                    >Save
                                    </BA01BtnPr>
                                )}
                            </FormFieldContainer>
                        </React.Fragment>
                    )}

                    {!!(errorMsg || successMsg) && (
                        <InfoText>
                            {errorMsg || successMsg}
                        </InfoText>
                    )}
                </Form>
            </FormOverlay>
        );
    }
}

export default OR109ChangePw;
