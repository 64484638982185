/* eslint-disable arrow-body-style */
import React from 'react';
import bacon from 'baconjs';

import ResubscribeModal from '../../../components/branded/common/resubscribe-modal';

/**
 * Gets resubscribe promo modal
 *
 * @param {string} options.brand binge/kayo/flash
 * @param {bacon.Observable} options.marketingConfig$ marketing json config (for button CTA texts)
 * @param {bacon.Observable} options.offer$ offers from billing API (for user greeting copy)
 * @param {string} options.showPromo flag from query param `pm`
 * @param {string} options.userIsAuthenticated Whether the user is authenticated or not
 * @param {bacon.Observable} options.userResubscribeFunction$ callback to redirect user to login
 * @param {bacon.Observable} options.warningModal$ warning modal stream
 * @returns {bacon.Observable} resubscribe modal component
 */
export default function getResubscribePromoModalStream({
    brand,
    marketingConfig$,
    offer$,
    showPromo,
    userResubscribeFunction$,
    warningModal$,
    userIsAuthenticated$,
}) {
    if (!brand) {
        console.error('Accounts Widgets: brand not provided to getResubscribePromoModalStream');
    }

    const isModalOpenBus = new bacon.Bus();
    const isModalOpen$ = isModalOpenBus.toProperty(true);

    const userGreeting$ = offer$
        .map('.offer.customOfferSummary');

    const resubscribeModalConfig$ = marketingConfig$
        .map('.resubscribeModal');

    const hasOfferError$ = bacon.combineAsArray(
        offer$.map('.error'),
        warningModal$,
    )
        .map((values) => values.some(Boolean))
        .startWith(false);

    return bacon.combineTemplate({
        isModalOpen: isModalOpen$,
        resubscribeModalConfig: resubscribeModalConfig$,
        userResubscribeFunction: userResubscribeFunction$,
        userGreeting: userGreeting$,
        hasOfferError: hasOfferError$,
        userIsAuthenticated: userIsAuthenticated$,
    })
        .map(({
            isModalOpen,
            resubscribeModalConfig,
            userResubscribeFunction,
            userGreeting,
            hasOfferError,
            userIsAuthenticated,
        }) => {
            // Do not render if there is an offer error or the show flag is false
            if (hasOfferError || !showPromo || userIsAuthenticated) {
                return null;
            }

            return (
                <ResubscribeModal
                    brand={brand}
                    isModalOpen={isModalOpen}

                    userGreeting={userGreeting}

                    // Existing customer: redirect to login page
                    existingUserCta={resubscribeModalConfig?.existingUserCta || 'Had Kayo, Binge or Flash before?'}
                    onClickExistingUserCta={userResubscribeFunction}

                    // New customer: close modal and scroll to package selector
                    newUserCta={resubscribeModalConfig?.newUserCta || 'New Customer'}
                    newUserCtaHref="#products"
                    onClickNewUserCta={() => void isModalOpenBus.push(false)}
                />
            );
        })
        .startWith(null);
}
