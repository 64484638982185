import React from 'react';
import styled from 'styled-components';

import TargetAemWrapper from '@fsa-streamotion/target-aem-wrapper';

import brandPropType from '../../../common/brand-proptype';
import LoadingIndicator from '../../branded/loading-indicator';

const Z_INDEX_BLUR = 100; // need to be higher than AEM modules

const LoadingWrapper = styled.div`
    height: 100%;
    min-height: 100px;

    &::before {
        position: absolute;
        inset: 0;
        z-index: ${Z_INDEX_BLUR};
        backdrop-filter: blur(25px);
        content: '';
    }
`;

const LoadingIndicatorWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: ${Z_INDEX_BLUR + 1};
    margin: 0 auto;
    pointer-events: none;
`;

/**
 * Wrapper for the TargetAemWrapper to include the brand's loadingEl
 *
 * @param  {Object} options             - see below
 * @param  {string} options.brand       - brand for loading indicator
 * @returns {React.ReactElement}        - the TargetAemWrapper with loadingEl prop
 *  */
const TargetAemWrapperWithLoading = ({brand, ...rest}) => (
    <TargetAemWrapper
        {...rest}
        brand={brand}
        loadingEl={(
            <LoadingWrapper>
                <LoadingIndicatorWrapper>
                    <LoadingIndicator brand={brand} height="90px" />
                </LoadingIndicatorWrapper>
            </LoadingWrapper>
        )}
    />
);

TargetAemWrapperWithLoading.propTypes = {
    /** The brand, Flash | Kayo | Binge | Lifestyle */
    brand: brandPropType,
};

TargetAemWrapperWithLoading.displayName = 'TargetAemWrapperWithLoading';

export default TargetAemWrapperWithLoading;
