import type {Validator} from 'prop-types';

import type {ObjectPropType, PropType} from './util/types';

/**
 * Add `isRequired` to any non-required `propTypes` validator, failing a missing, `undefined` or
 * `null` property.
 *
 * @remarks
 * A non-required `propTypes` validator is one that tolerates `undefined` and `null` values.
 *
 * @typeParam P    - the prop types of the React component
 * @param propType - a non-required `propTypes` validator
 *
 * @returns the provided validator with `isRequired` validation added
 */
export default function isRequired<P extends PropType>(
    propType: Validator<P>
): Validator<NonNullable<P>> {
    return (...args) => {
        const [props, propName, componentName, location, propFullName] = args;

        // Don't let prop-types lower our type-checking standards ({[key: string]: any} vs. {[key: string]: PropType})
        const propValue = (props as ObjectPropType)[propName];

        if (propValue === null || propValue === undefined) {
            // eslint-disable-line no-eq-null
            return new Error(
                `The ${location} \`${propFullName}\` is marked as required in \`${componentName}\`, but its value is \`${propValue}\`.`
            );
        }

        // Passing through args verbatim so propType doesn't detect being called directly.
        return propType(...args);
    };
}
