import {parseToRgb} from 'polished';
import flow from 'lodash/flow';

import {lifestyle, midnight} from './palette';

const THEME_PRIMARY_CSS_VAR = '--nucleus-theme-color-primary';
const THEME_SECONDARY_CSS_VAR = '--nucleus-theme-color-secondary';

/**
 * Uses parseToRgb under the hood to parse
 *
 * @param {string} color colour to use
 * @param {fallbackColor} fallbackColor fallback color to use, if provided one is invalid
 *
 * @returns {string} string, for use inside a rgb/rgba css function
 *
 * @example
 * const StyledDiv = styled.div`
 *     color: rgb(${parseToRgbValues(lifestyle, '#000')});
 * `;
 */
export const parseToRgbValues = flow(
    (color, fallbackColor = '#000') => {
        if (!color) {
            return parseToRgb(fallbackColor);
        }

        try {
            return parseToRgb(color);
        } catch (error) {
            if (process.env.NODE_ENV !== 'production') {
                console.warn(`nucleus: non-parseable color passed to hexToRgb: ${color}. Using fallback color ${fallbackColor} instead.`);
            }

            return parseToRgb(fallbackColor);
        }
    },
    ({red, green, blue}) => [red, green, blue].join(','),
);

/**
 * Return an object which can be mapped onto inline styles (for setting theme colours)
 * In the future, perhaps this guy could be refactored to actually set the props (e.g. on an unrelated <style> tag, to save a re-render from root)
 *
 * @param {Object} options see below
 * @param {Object} [options.colorPrimary] primary theme colour
 * @param {Object} [options.colorSecondary] secondary theme colour
 *
 * @returns {Object} CSS props in object form
 */
export function themeColorCssVariables({colorPrimary, colorSecondary}) {
    return {
        [THEME_PRIMARY_CSS_VAR]: parseToRgbValues(colorPrimary, lifestyle),
        [THEME_SECONDARY_CSS_VAR]: parseToRgbValues(colorSecondary, midnight),
    };
}

/**
 * Some helper functions for wrapping a nucleus theme colour CSS prop
 */
class ThemeColor {
    constructor(cssProp, defaultColor) {
        if (defaultColor) {
            this.rgbValuesCssProp = `var(${cssProp}, ${parseToRgbValues(defaultColor)})`;
        } else {
            this.rgbValuesCssProp = `var(${cssProp})`;
        }
    }

    /**
     * Return a colour string with opacity defined.
     *  - If opacity <= 0 then `transparent` is returned.
     *  - If it is >= 1 then the base colour is returned (e.g. as rgb)
     *  - Else rgba value returned with opacity applied (toPrecision())
     *
     * @param {number} opacity opacity value
     * @returns {string} colour string
     */
    opacity(opacity) {
        if (!Number.isFinite(opacity) || opacity >= 1) {
            return `rgb(${this.rgbValuesCssProp})`;
        } else if (opacity <= 0) {
            return 'transparent';
        }

        return `rgba(${this.rgbValuesCssProp}, ${Number(opacity.toPrecision(2))})`;
    }

    toString() {
        return `rgb(${this.rgbValuesCssProp})`;
    }
}

/**
 * @example
 * const MyDiv = styled.div`
 *     color: ${theme.primaryColor};
 *     background-color: ${theme.primaryColor.opacity(0.5)};
 * `;
 */
const theme = {
    primaryColor: new ThemeColor(THEME_PRIMARY_CSS_VAR, lifestyle),
    secondaryColor: new ThemeColor(THEME_SECONDARY_CSS_VAR, midnight),
};

export default theme;
