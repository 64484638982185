import React from 'react';
import propTypes from 'prop-types';
import clamp from 'lodash/clamp';
import values from 'lodash/values';

import newCustomEvent from './new-custom-event';
import round from './round';

const EVENT_TYPES = {
    start: 'touchstart',
    move: 'touchmove',
    end: 'touchend',
};

// -----------------------------------------------------------------------------------
//
// ✨ Magical wrapper around input[type=range] to make iOS Safari sliders play nice 🦄
//
// <RangeTouch>
//     <input type="range" />
// </RangeTouch>
//
// Known issues
// * Doesn't handle vertical orientation
//
// Adapted from RangeTouch
//
// https://github.com/sampotts/rangetouch/blob/master/src/js/rangetouch.js
//
// -----------------------------------------------------------------------------------
export default class RangeTouch extends React.PureComponent {
    static displayName = 'RangeTouch';

    static propTypes = {
        thumbWidth: propTypes.number,
        children: propTypes.node,
    };

    static defaultProps = {
        thumbWidth: 15,
    };

    componentDidMount() {
        values(EVENT_TYPES)
        // .filter((eventType) => this.el[`on${eventType}`] !== undefined)
            .forEach((eventType) => {
                this.rangeTouchRef.current.addEventListener(eventType, this.onTouchEvent);
            });
    }

    componentWillUnmount() {
        values(EVENT_TYPES)
        // .filter((eventType) => this.el[`on${eventType}`] !== undefined)
            .forEach((eventType) => {
                this.rangeTouchRef.current.removeEventListener(eventType, this.onTouchEvent);
            });
    }

    onTouchEvent = (event) => {
        // If not enabled, bail
        if (event.target.disabled) {
            return;
        }

        event.preventDefault();

        // Set value - can't set event.target.value directly :(
        (Object.getOwnPropertyDescriptor(Object.getPrototypeOf(event.target), 'value').set).call(event.target, this.calculateValue(event));

        // Trigger input event
        this.rangeTouchRef.current.dispatchEvent(newCustomEvent(
            event.type === EVENT_TYPES.end ? 'change' : 'input',
            {bubbles: true}
        ));
    };

    calculateValue(event) {
        const input = event.target;
        const [touch] = event.changedTouches;

        let {min, max, step} = event.target;

        min = min ? parseFloat(min) : 0;
        max = max ? parseFloat(max) : 0;
        step = step && step !== 'any' ? parseFloat(step) : 1;
        const delta = max - min;

        // Calculate percentage
        const clientRect = input.getBoundingClientRect();
        const thumbWidth = (((100 / clientRect.width) * (this.props.thumbWidth / 2)) / 100);
        let percent = clamp((100 / clientRect.width) * (touch.clientX - clientRect.left), 0, 100);

        // Factor in the thumb offset
        if (percent < 50) {
            percent -= ((100 - (percent * 2)) * thumbWidth);
        } else if (percent > 50) {
            percent += (((percent - 50) * 2) * thumbWidth);
        }

        // Find the closest step to the mouse position
        return min + round(delta * (percent / 100), step);
    }

    render() {
        const child = React.Children.only(this.props.children);

        this.rangeTouchRef = child.ref;

        return React.cloneElement(
            child,
            {
                style: {
                    ...child.props.style,
                    userSelect: 'none',
                    WebkitUserSelect: 'none',
                    MozUserSelect: 'none',
                    msUserSelect: 'none',
                    touchAction: 'manipulation',
                },
            }
        );
    }
}
