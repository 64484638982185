import React from 'react';
import Ic from '..';

const IC50Success = (args) => (
    <Ic {...args}>
        <path d="M14.073 52.405a4.105 4.105 0 0 1 5.856.284l14.824 16.667L78.521 20.13a4.104 4.104 0 0 1 5.856-.285 4.273 4.273 0 0 1 .29 5.975L34.754 81.965 13.783 58.38a4.273 4.273 0 0 1 .29-5.975z" />
    </Ic>
);

IC50Success.displayName = 'IC50Success';

export default IC50Success;
