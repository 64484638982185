import React from 'react';

import Ic from '..';

const IC304Facebook = (args) => (
    <Ic {...args}>
        <path d="M100.003127,50.0019532 C100.003127,22.3866167 77.6166845,0 50.0015633,0 C22.3864421,0 0,22.3866167 0,50.0019532 C0,74.9593734 18.284849,95.6453377 42.188819,99.3964608 L42.188819,64.4556428 L29.4931096,64.4556428 L29.4931096,50.0019532 L42.188819,50.0019532 L42.188819,38.9858979 C42.188819,26.4541584 49.6537009,19.532013 61.0751517,19.532013 C66.5457329,19.532013 72.2678845,20.5086136 72.2678845,20.5086136 L72.2678845,32.8137818 L65.9628045,32.8137818 C59.7513799,32.8137818 57.8143076,36.6681316 57.8143076,40.6223876 L57.8143076,50.0019532 L71.6819286,50.0019532 L69.4650625,64.4556428 L57.8143076,64.4556428 L57.8143076,99.3964608 C81.7182776,95.6453377 100.003127,74.9593734 100.003127,50.0019532" />
    </Ic>
);

IC304Facebook.displayName = 'IC304Facebook';

export default IC304Facebook;
