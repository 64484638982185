import React from 'react';

import Ic from '..';

const IC521Up = (args) => (
    <Ic {...args}>
        <path d="M94 74.9H6c-.3 0-.6-.2-.6-.6V25.7c0-.3.2-.6.6-.6h88c.3 0 .6.2.6.6v48.6c-.1.3-.3.6-.6.6zM6.6 73.8h86.8V26.2H6.6v47.6z" />
    </Ic>
);

IC521Up.displayName = 'IC521Up';

export default IC521Up;
