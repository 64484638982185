import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';
import {A, Button} from 'normalized-styled-components';

import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {silverLining, midnight, slate, kayoGreen, white} from '../../../../common/palette';
import GA89Tile from '../../../atoms/ga/89-tile';
import IC31Tick from '../../../atoms/ic/31-tick';

// not clickable: transparent/silverLining
// clickable: silverLining@0.2/white;
// hover: Kayo Green@1.0/midnight;
// clicked: silverLining@1.0/midnight

const containerBaseStyles = css`
    position: relative;
    transition: ${transition('background-color', 'color')};
    outline: 0;
    border-radius: 4px;
    background-color: ${rgba(midnight, 0.35)};
    color: ${rgba(slate, 0.7)};
    font: var(--mui-body-copy-3);

    ${stylesWhen('isClickable')`
        background-color: ${rgba(silverLining, 0.2)};
        color: ${white};

        &:focus,
        &:hover {
            background-color: ${kayoGreen};
            color: ${midnight};
        }
    `}

    &[aria-pressed='true'],
    &[aria-current='true'] {
        background-color: ${silverLining};
        color: ${midnight};
    }
`;

const ContainerDiv = styled.div`
    ${containerBaseStyles}
`;

const ContainerButton = styled(Button).attrs(({isSelected}) => ({
    'type': 'button',
    'aria-pressed': isSelected,
}))`
    ${containerBaseStyles}

    appearance: none;
    box-sizing: content-box;
    border: 0;
    padding: 0;
    width: 100%;
    text-align: unset;
`;

const ContainerAnchor = styled(A).attrs(({isSelected}) => ({
    'aria-current': isSelected,
}))`
    ${containerBaseStyles}

    display: block;
    text-decoration: none;
`;

const TickWrapper = styled.span`
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    width: 28px;
    height: 28px;
`;

const StyledIC31Tick = styled(IC31Tick)`
    border-radius: 50%;
    box-shadow: -1px 1px 2px 0 ${rgba(midnight, 0.3)};
`;

const Layout = styled(GA89Tile)`
    display: grid;
    grid-template-columns: 48px auto;
    grid-gap: 14px;
    align-items: center;
    background-color: transparent;
    color: inherit;
`;

const Rows = styled.div`
    display: grid;
    grid-gap: 5px;
`;

const SubText = styled.p`
    width: 100%;
    color: ${slate};
    font: var(--mui-body-copy-6-light);
`;

function getCtaType({href, onClick}) {
    if (href) {
        return ContainerAnchor;
    }
    if (onClick) {
        return ContainerButton;
    }

    return ContainerDiv;
}

const TM19Pmnt = ({
    children,
    className,
    href,
    icon,
    isSelected,
    onClick,
    subText,
    ...htmlAttributes
}) => {
    const StyledElement = getCtaType({href, onClick});

    return (
        <StyledElement
            {...htmlAttributes}
            className={classnames('TM19Pmnt', className)}
            href={href}
            isSelected={isSelected}
            onClick={onClick}
            isClickable={!!(onClick || href)}
            data-cs-mask={true}
        >
            <Layout>
                {icon}

                <Rows style={icon ? null : {gridColumnStart: 2}}>
                    {children}
                </Rows>
            </Layout>

            {!!subText && <SubText>{subText}</SubText>}

            {!!isSelected && (
                <TickWrapper>
                    <StyledIC31Tick color={kayoGreen} />
                </TickWrapper>
            )}
        </StyledElement>
    );
};

TM19Pmnt.displayName = 'TM19Pmnt';

TM19Pmnt.propTypes = {
    children: propTypes.node,
    className: classNameType,
    href: propTypes.string,
    icon: propTypes.node,
    isSelected: propTypes.bool,
    onClick: propTypes.func,
    subText: propTypes.string,
};

export default TM19Pmnt;
