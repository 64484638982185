import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import {Section, Sup} from 'normalized-styled-components';
import classnames from 'classnames';

import {mediaQuery, stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {SCREEN_1280_DESKTOP} from '../../../../common/screen-sizes';
import {TERM_TYPE_SHORT} from '../../../../common/billing-constants';
import {termTypePropType} from '../../../../common/custom-proptypes';
import {blanc, knight, panther} from '../../../../common/palette';
import splitPrice from '../../../../common/split-price';

import IC31Tick from '../../ic/31-tick';

const StyledIC31Tick = styled(IC31Tick)`
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    transition: ${transition('opacity')};
    opacity: 0;
    width: 23px;
    height: 23px;

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        width: 30px;
        height: 30px;
    `}
`;

const StyledSection = styled(Section)`
    appearance: none;
    display: flex;
    position: relative;
    flex-direction: column;
    transition: ${transition('background-color', 'color')};
    border: 1px solid ${rgba(blanc, 0.1)};
    border-radius: 6px;
    box-shadow: 0 1px 1px 0 ${rgba(panther, 0.3)};
    background: transparent;
    padding: 21px 11px 16px;
    min-width: 150px;
    min-height: 110px;
    text-align: center;
    text-shadow: 0 1px 1px ${rgba(panther, 0.3)};
    color: ${blanc};

    ${stylesWhen('isActive')`
        background-color: ${blanc};
        text-shadow: none;
        color: ${knight};

        ${StyledIC31Tick} {
            opacity: 1;
        }
    `}
`;

const PackagePrice = styled.div`
    position: inherit;
    margin: auto;
    padding-right: 7px;
    font: var(--ionic-sub-price);
`;

const PackageName = styled.h3`
    margin: 0;
    text-transform: uppercase;
    font: var(--ionic-package-name);
`;

const SecondaryText = styled.span`
    padding: 4px 0 10px;
    text-transform: uppercase;
    font: var(--ionic-billing-frequency);
`;

const DisplayMantissa = styled(Sup)`
    position: absolute;
    top: 2px;
    font: var(--ionic-billing-frequency);
`;

const TermType = styled.span`
    font: var(--ionic-billing-frequency);
`;

const BA44OfferBtn = ({
    className,
    currencyDecimalPlaces = 2,
    currencyPrefix = '$',
    isActive,
    name,
    displayAmount,
    termType,
    secondaryText,
    ...htmlAttributes
}) => {
    const [displayAmountCharacteristic, displayAmountMantissa] = splitPrice(displayAmount, currencyDecimalPlaces);
    const termTypeShort = TERM_TYPE_SHORT[termType];

    return (
        <StyledSection
            {...htmlAttributes}
            className={classnames('BA44OfferBtn', className)}
            isActive={isActive}
        >
            {!!name && (
                <PackageName>{name}</PackageName>
            )}
            <PackagePrice
                hasMantissa={!!displayAmountMantissa}
                aria-label={displayAmount ? `${currencyPrefix}${displayAmount} per ${termType}` : undefined}
            >
                {displayAmount ? (
                    <React.Fragment>
                        {currencyPrefix}{displayAmountCharacteristic}

                        {!!displayAmountMantissa && (
                            <DisplayMantissa>
                                .{displayAmountMantissa}
                            </DisplayMantissa>
                        )}

                        {!!termTypeShort && (
                            <TermType>
                                /{termTypeShort}
                            </TermType>
                        )}
                    </React.Fragment>
                ) : 'Free'}
            </PackagePrice>
            <SecondaryText>
                {secondaryText}
            </SecondaryText>
            <StyledIC31Tick />
        </StyledSection>
    );
};

BA44OfferBtn.displayName = 'BA44OfferBtn';

BA44OfferBtn.propTypes = {
    /** additional CSS classnames to be applied */
    className: classNameType,
    /** Currency decimal places */
    currencyDecimalPlaces: propTypes.number,
    /** Currency prefix */
    currencyPrefix: propTypes.oneOf(['$']),
    /** Is the package you are up/downgrading to */
    isActive: propTypes.bool,
    /** Name of the package */
    name: propTypes.string,
    /** Package price */
    displayAmount: propTypes.number,
    /** Package Secondary Text */
    secondaryText: propTypes.string,
    /** Package term duration */
    termType: termTypePropType,
};

export default BA44OfferBtn;
