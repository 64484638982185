import React, {Fragment} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';

import get from 'lodash/get';
import matchesProperty from 'lodash/matchesProperty';

import {mediaQuery, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';

import {palette as infinityPalette, screenSizes} from '@fsa-streamotion/streamotion-web-infinity';
import {palette as ionicPalette} from '@fsa-streamotion/streamotion-web-ionic';
import {palette as muiPalette} from '@fsa-streamotion/streamotion-web-mui';
import {palette as nucleusPalette} from '@fsa-streamotion/streamotion-web-nucleus';

import {stylesWhenBinge, stylesWhenFlash, stylesWhenKayo, stylesWhenLifestyle} from '../../../../utils/styles-when-brand';

import {
    BrandedBA01BtnPr,
    BrandedCA01Feature,
    BrandedCM03FeatureList,
    BrandedIC103Loading,
    BrandedOR16MarBlk,
} from '../../../../utils/branded-components';

import VoucherImg from '../../voucher-image';
import PackageSelector from '../../common/package-selector';
import brandPropType from '../../../../common/brand-proptype';

const {ares, blanc, panther} = ionicPalette;
const {flash, fog} = infinityPalette;
const {kayoGreen, black, coal, slate} = muiPalette;
const {lifestyle, onyx} = nucleusPalette;

const {
    SCREEN_415_PHABLET,
    SCREEN_768_TABLET,
    SCREEN_1024_DESKTOP,
    SCREEN_1280_DESKTOP,
    SCREEN_1920_DESKTOP,
} = screenSizes;

const MOBILE_WIDTH = '306px';
const DESKTOP_WIDTH = '850px';

/** **********************************************************
 *       HEADER: Offer Display
 ************************************************************/

const StyledOR16MarBlkOfferDisplay = styled(BrandedOR16MarBlk)`
    ${stylesWhenNot(matchesProperty('brand', 'kayo'))`
        width: 100%;
        max-width: ${MOBILE_WIDTH};

        ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
            max-width: ${DESKTOP_WIDTH};
        `}
    `}
`;

/** *********************************************************
 *       VOUCHER BLOCK
 ************************************************************/

const StyledVoucherImg = styled(VoucherImg)`
    margin: 28px auto;
`;

const VoucherContainerAboveForm = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 27px;
    margin-bottom: 15px;
    padding: 0 7px;
    width: 100%;
    max-width: 600px;
`;

/** *********************************************************
 *       SUB-HEADER: Marketing Display
 ************************************************************/

const StyledOR16MarBlk = styled(BrandedOR16MarBlk)`
    margin-top: 20px;
    margin-bottom: 24px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        margin-top: 33px;
        margin-bottom: 47px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        margin-bottom: 50px;
    `}
`;

const MarketingDisplayHeader = styled.span`
    ${stylesWhenBinge`
        color: ${ares};
        font: var(--ionic-body-copy-2-bold);
    `}

    ${stylesWhenFlash`
        color: ${flash};
        font: var(--infinity-body-copy-3-bold);
    `}

    ${stylesWhenKayo`
        color: ${kayoGreen};
        font: var(--mui-body-copy-3-bold);
    `}

    ${stylesWhenLifestyle`
        color: ${lifestyle};
        font: var(--nucleus-body-copy-2-bold);
    `}
`;

/** *********************************************************
 *   PACKAGE SELECTOR & FEATURES LIST
 ************************************************************/

// PackageSelector + CM03FeatureList
// + footnote & continue button on mobile
const PackagesAndFeaturesContainer = styled.div`
    display: grid;
    grid-auto-flow: row;
    grid-gap: 35px;
    padding-bottom: 35px;

    ${mediaQuery({minWidthPx: SCREEN_415_PHABLET})`
        padding-left: 7px;
        padding-right: 7px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        grid-auto-flow: column;
        grid-gap: 38px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        padding-bottom: 56px;
    `}
`;

const StyledCM03FeatureList = styled(BrandedCM03FeatureList)`
    padding: 0 21px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        padding: 0;
    `}
`;

/** **************************************************************
 *       CONTINUE BUTTON
 *****************************************************************/

const StyledBA01BtnPr = styled(BrandedBA01BtnPr)`
    margin: 0 auto;
    border-radius: 6px;
    width: 250px;

    ${stylesWhenBinge`
        box-shadow: 0 1px 1px 0 ${rgba(panther, 0.5)};
        height: 50px;
        line-height: 50px;
    `}

    ${stylesWhenKayo`
        box-shadow: 0 1px 1px 0 ${rgba(black, 0.5)};
        height: 50px;
        line-height: 50px;
    `}

    ${stylesWhenFlash`
        border-radius: 0;
    `}

    ${stylesWhenLifestyle`
        box-shadow: 0 1px 1px 0 ${rgba(onyx, 0.5)};
        height: 50px;
        line-height: 50px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 400px;
    `}

    ${stylesWhenNot(matchesProperty('brand', 'kayo'))`
        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            height: 80px;
            line-height: 80px;
        `}
    `}
`;

const StyledIC103Loading = styled(BrandedIC103Loading)`
    display: inline-block;
    vertical-align: middle;

    ${stylesWhenKayo`
        svg {
            fill: ${coal};
        };
    `}
`;

/** **************************************************************
 *       FOOTNOTE CONTAINER
 *****************************************************************/

/**
 * This is **temporary** - will follow official design post release.
 * This footnote might have to be moved as part of OR94 which should handle
 * brand styling for binge & flash. Right now it's just Kayo.
 *  - REF: https://zpl.io/MdkkA8R
 */
const FootnoteContainer = styled.div`
    margin: 0 auto;
    width: 100%;
    max-width: 415px;
    text-align: center;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        max-width: 768px;
    `}

    ${stylesWhenBinge`
        color: ${rgba(blanc, 0.7)};
        font: var(--ionic-header-8-light);
    `}

    ${stylesWhenFlash`
        color: ${rgba(fog, 0.7)};
        font: var(--infinity-header-8-light);
    `}

    ${stylesWhenKayo`
        color: ${rgba(slate, 0.7)};
        font: var(--mui-body-copy-3);
    `}

    ${stylesWhenLifestyle`
        color: ${rgba(blanc, 0.7)};
        font: var(--nucleus-header-8-light);
    `}
`;

/** **************************************************************
 *       TABLET DOWN & UP WRAPPERS
 * These wrappers show/hide certain components that need to be
 * rearranged in smaller and larger viewports
 *****************************************************************/

/** Show only when viewport is smaller than 768px */
const ShowOnTabletDownWrapper = styled.div`
    display: grid;
    grid-auto-flow: row;
    grid-gap: 25px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        display: none;
    `}
`;

/** Show only when viewport is 768px or larger */
const ShowOnTabletUpWrapper = styled.div`
    display: none;
    padding-bottom: 7px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        display: grid;
        grid-auto-flow: row;
        grid-gap: 35px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        grid-gap: 45px;
        padding-bottom: 14px;
    `}

    ${stylesWhenNot('hasContinueButton')`
        padding-bottom: 35px;

        ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
            padding-bottom: 56px;
        `}
    `}
`;

/** *********** MAIN ****************/

const OfferContent = ({
    marketingDisplay,
    offerDisplay,
    packageSelector,
    packagesSupplementaryList,
    voucherDisplay = {},
    voucherForm,
    hasContinueButton,
    onContinue,
    isContinueLoading,
    isSignUpDisabled,
    brand,
    footnote,
}) => {
    const {imageAltText, imageLinkMobile, imageLinkDesktop} = get(voucherDisplay, 'voucherImages') || {};

    // footnote & container get rearranged in smaller & larger viewports
    // wrapping in a function so we dont repeat the same code
    const getFootnoteAndContinueBtn = () => (
        <Fragment>
            {!!footnote && (
                <FootnoteContainer brand={brand}>{footnote}</FootnoteContainer>
            )}

            {!!hasContinueButton && (
                <StyledBA01BtnPr
                    onClick={onContinue}
                    disabled={!!isContinueLoading}
                    brand={brand}
                    isBlock={true}
                >
                    {isContinueLoading
                        ? <StyledIC103Loading size="30px" brand={brand} />
                        : 'Continue'
                    }
                </StyledBA01BtnPr>
            )}
        </Fragment>
    );

    return (
        <Fragment>
            <StyledOR16MarBlkOfferDisplay
                displayHeader={offerDisplay.packageHeader}
                displaySubtext={offerDisplay.packageSubtext}
                brand={brand}
            />
            {!isSignUpDisabled && (
                <Fragment>
                    {!!(imageLinkMobile || imageLinkDesktop) && (
                        <StyledVoucherImg
                            imageAltText={imageAltText}
                            imageLinkMobile={imageLinkMobile}
                            imageLinkDesktop={imageLinkDesktop}
                        />
                    )}

                    {!!voucherDisplay.isVoucherRequiredTemplate && (
                        <VoucherContainerAboveForm>
                            {voucherForm}
                        </VoucherContainerAboveForm>
                    )}

                    <StyledOR16MarBlk
                        brand={brand}
                        displaySubtext={(
                            <MarketingDisplayHeader brand={brand}>
                                {marketingDisplay.packageSelectPrompt}
                            </MarketingDisplayHeader>
                        )}
                    />

                    <PackagesAndFeaturesContainer>
                        <PackageSelector
                            brand={brand}
                            {...packageSelector}
                        />

                        {/* This is where the footnote and continue button go on mobile. */}
                        {(!!hasContinueButton || !!footnote) && (
                            <ShowOnTabletDownWrapper>
                                {getFootnoteAndContinueBtn()}
                            </ShowOnTabletDownWrapper>
                        )}

                        <StyledCM03FeatureList header={packagesSupplementaryList.header} brand={brand}>
                            {packagesSupplementaryList.items.map((item, index) => (
                                <BrandedCA01Feature key={index} brand={brand}>{item}</BrandedCA01Feature>
                            ))}
                        </StyledCM03FeatureList>

                    </PackagesAndFeaturesContainer>

                    {/* And yay this is where the footnote and continue button go on tablet and higher. */}
                    {(!!hasContinueButton || !!footnote) && (
                        <ShowOnTabletUpWrapper hasContinueButton={hasContinueButton}>
                            {getFootnoteAndContinueBtn()}
                        </ShowOnTabletUpWrapper>
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

OfferContent.displayName = 'OfferContent';

OfferContent.propTypes = {
    /** Brand name */
    brand: brandPropType,
    /** Footnote message usually used to display price/package change message */
    footnote: propTypes.node,
    /** Page sub-header (color branded): `OR16MarBlk` props */
    marketingDisplay: propTypes.object,
    /** Page header: `OR16MarBlkOfferDisplay` props */
    offerDisplay: propTypes.object,
    /** `PackageSelector` props */
    packageSelector: propTypes.object,
    /** Features list (`CM03FeatureList` & `CA01Feature`) */
    packagesSupplementaryList: propTypes.shape({
        header: propTypes.node,
        items: propTypes.arrayOf(propTypes.node),
    }),
    /** Voucher images and toggle for `voucherForm` */
    voucherDisplay: propTypes.shape({
        voucherImages: propTypes.shape({
            imageAltText: propTypes.string,
            imageLinkMobile: propTypes.string,
            imageLinkDesktop: propTypes.string,
        }),
        /** Display voucherVorm? */
        isVoucherRequiredTemplate: propTypes.bool,
    }),
    /** Voucher form displayed if `voucherDisplay.isVoucherRequiredTemplate` is `true` */
    voucherForm: propTypes.node,
    /** Whether the page is in the loading state */
    isLoading: propTypes.bool,
    /** Dispay continue button? */
    hasContinueButton: propTypes.bool,
    /** Callback when continue button is clicked */
    onContinue: propTypes.func,
    /** Is continue button loading? */
    isContinueLoading: propTypes.bool,
    /** If signup is disabled, we hide everything below page header */
    isSignUpDisabled: propTypes.bool,
};

export default OfferContent;
