import React, {cloneElement, isValidElement, useRef} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import asNavigationColumn from '../../../util/navigation/hocs/as-column';

const Container = asNavigationColumn(
    styled.div`
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: stretch;
    `
);

const SimpleNavDiv = asNavigationColumn(
    styled.div``
);

const TM28FTStats = React.forwardRef(({
    className,
    tile,
    fixtureStats,
}, forwardedRef) => {
    const tileRef = forwardedRef || useRef(null); // eslint-disable-line react-hooks/rules-of-hooks
    const fixtureStatsRef = useRef(null);

    return (
        <Container className={classnames('TM28FTStats', className)}>
            {isValidElement(fixtureStats)
                ? (
                    [
                        <SimpleNavDiv key="tile" ref={tileRef}>
                            {tile}
                        </SimpleNavDiv>,
                        <SimpleNavDiv key="fixtureStats">
                            {cloneElement(fixtureStats, {ref: fixtureStatsRef})}
                        </SimpleNavDiv>,
                    ]
                )
                : isValidElement(tile) && cloneElement(
                    tile,
                    {ref: tileRef}
                )}
        </Container>
    );
});

TM28FTStats.propTypes = {
    /** Additional class(es) */
    className: classNameType,
    /** the tile */
    tile: propTypes.node,
    /** Stats to render */
    fixtureStats: propTypes.node,
};

TM28FTStats.displayName = 'TM28FTStats';

export default TM28FTStats;
