import invoke from 'lodash/invoke';

/**
 * Helper function for wrapping callbacks, where we want to suppress (e.g. event.preventDefault or event.stopPropagation)
 * Will also work with synthetic event signatures by checking if the first argument is in array format
 *
 * @example
 * ```
 * <a onClick={preventThen(this.props.handleClick)} href="http://somelinkwedontwannavisit.com">click me I'm a link</a>
 * ```
 *
 * @param  {Function} action                                        Callback to fire when event received
 * @param  {Object}   options                                       Options (see below)
 * @param  {string[]} options.thingsToPrevent=['preventDefault']    Array of methods against event to call (e.g. preventDefault, stopPropagation etc)
 * @param  {*}    options.payload                               Payload to pass to action callback
 * @returns {Function} Callback which can be invoked by an event listener
 */
export default function preventThen(action, {thingsToPrevent = ['preventDefault'], payload} = {}) {
    return (event) => {
        const eventOrSyntheticEvent = Array.isArray(event) ? event[0] : event; // appears to be called with "[Proxy]" args sometimes - possibly a bit weird due to forwardRef? investigate further later. could have beeen the reason some links are causing hard refreshes...

        thingsToPrevent.forEach((prevent) => void invoke(eventOrSyntheticEvent, prevent));

        action(payload);
    };
}
