import React from 'react';
import propTypes from 'prop-types';
import {rgba} from 'polished';
import styled from 'styled-components';
import classnames from 'classnames';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType, srcsetOptionsType} from '@fsa-streamotion/custom-prop-types';
import GA43ArrowInd from '../../../atoms/ga/43-arrow-ind';
import {Button} from '../../../../common/normalized-styled-components';
import {getLargestImage} from '../../../../common/tile-utils';

import {SCREEN_768_TABLET} from '../../../../common/screen-sizes';
import {ink, white} from '../../../../common/palette';
import GM25PriceCallout from '../../../molecules/gm/25-price-callout';
import stringifySrcsetOptions from '../../../../common/stringify-srcset-options';

/** ********************************************
 *   TOP BLOCK: Device Info & Image
 ***********************************************/
const DeviceInfoBlock = styled.div`
    display: flex;
    align-items: center;
`;

// ==== IMAGE

const ImageTile = styled.div`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-right: 28px;
    border-radius: 2.8px;
    background-color: ${white};
    padding: 5px;
    width: 102px;
    min-width: 102px;
    height: 102px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: 133px;
        min-width: 133px;
        height: 133px;
        margin-right: 35px;
    `}
`;

const DeviceImage = styled.img`
    display: block;
    width: 100%;
    object-fit: cover;
    object-position: center;
`;

// ==== DETAILS

const DeviceDetails = styled.div`
    display: grid;
    row-gap: 5px;
    max-width: 160px;
    color: ${ink};

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        max-width: 340px;
    `}
`;

const ModelName = styled.span`
    opacity: 0.9;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font: var(--quicksilver-body-copy-1);
`;

const SerialNumberBlock = styled.div`
    display: grid;
    font: var(--quicksilver-body-copy-4);
`;

const SerialNumberTitle = styled.span`
    opacity: 0.7;
`;

const SerialNumber = styled.span`
    opacity: 0.4;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

// ==== > ICON

const ArrowWrapper = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 13px;
    margin-left: auto;
    width: 14px;
    color: ${rgba(ink, 0.5)};
`;

/** ********************************************
 *   BOTTOM BLOCK: Device Pricing Details
 ***********************************************/

const PricingBlock = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
`;

const PricingNote = styled.span`
    margin-top: 10px;
    max-width: 147px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    overflow-wrap: break-word;
    color: ${rgba(ink, 0.4)};
    font: var(--quicksilver-body-copy-4-light);
    /* border: 1px dashed yellow; */

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        max-width: 213px;
    `}

    /* Where supported, lets add nice ellipsis on overflow https://caniuse.com/#search=line-clamp */
    @supports (-webkit-line-clamp: 3) {
        /* stylelint-disable value-no-vendor-prefix */
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        /* stylelint-enable value-no-vendor-prefix */
    }
`;

const StyledGM25PriceCallout = styled(GM25PriceCallout)`
    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        margin-right: 8px;
    `}
`;

/** ********************************************
 *               MAIN BUTTON
 ***********************************************/

const Cta = styled(Button).attrs(({isPressed}) => ({
    'aria-pressed': isPressed,
}))`
    border: 0;
    border-radius: 6px;
    background: ${white};
    padding: 14px;
    width: 100%;
    text-align: left;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        padding: 21px;
    `}

    // @TODO: this is also a guess that we probably want the entire top section (everything except footer) to get the hover and
    // active styles when hovered over or clicked on - should be re-visited after confirmation but the guess is based on BA58
    /** FOCUS / HOVER STATE */
    &:hover,
    &:focus {
        /* stylelint-disable */
        ${SerialNumberTitle},
        ${SerialNumber},
        ${ModelName},
        ${ArrowWrapper} {
            outline: 0;
            color: ${ink};
            font-weight: normal;
        }
    }

    /** ON PRESS / SELECTED STATE */
    &:active,
    &[aria-pressed='true'] {
        ${SerialNumberTitle},
        ${SerialNumber},
        ${ModelName},
        ${ArrowWrapper} {
            color: ${ink};
            font-weight: normal;
        }
    }
`;

/**
 * ### OR143DeviceList
 * This is to display user's individual registered devices
 */
const OR143DeviceList = React.forwardRef(({
    className,
    onClick,
    serialNumber,
    modelName,
    imageAltText,
    srcsetSizes,
    srcsetOptions,
    termType,
    pricingNote,
    displayAmount,
    isPressed,
    ...htmlAttributes
}, ref) => {
    const showPriceCallout = typeof displayAmount === 'number' && !!termType;
    const showPricingBlock = showPriceCallout || !!pricingNote;

    return (
        <Cta
            ref={ref}
            className={classnames('OR143DeviceList', className)}
            isPressed={isPressed}
            onClick={onClick}
            {...htmlAttributes}
        >
            <DeviceInfoBlock>
                {!!srcsetOptions && (
                    <ImageTile>
                        <DeviceImage
                            alt={imageAltText}
                            src={getLargestImage(srcsetOptions)}
                            srcSet={stringifySrcsetOptions(srcsetOptions)}
                            sizes={srcsetSizes}
                        />
                    </ImageTile>
                ) }
                <DeviceDetails>
                    {!!modelName && <ModelName>{modelName}</ModelName>}
                    {!!serialNumber
                    && (
                        <SerialNumberBlock>
                            <SerialNumberTitle>Serial Number</SerialNumberTitle>
                            <SerialNumber>{serialNumber}</SerialNumber>
                        </SerialNumberBlock>
                    )
                    }
                </DeviceDetails>
                <ArrowWrapper><GA43ArrowInd /></ArrowWrapper>
            </DeviceInfoBlock>

            {showPricingBlock && (
                <PricingBlock>
                    <PricingNote>{pricingNote}</PricingNote>
                    {showPriceCallout && (
                        <StyledGM25PriceCallout
                            displayAmount={displayAmount}
                            termType={termType}
                        />
                    )}
                </PricingBlock>
            )}
        </Cta>
    );
});

OR143DeviceList.propTypes = {
    /** additional CSS classnames to be applied */
    className: classNameType,
    /** onClick handler */
    onClick: propTypes.func,
    /** Alt text to use for the image tag */
    imageAltText: propTypes.string.isRequired,
    /** Device model name, e. g. "55" Glass TV" */
    modelName: propTypes.string,
    /** Device serial number */
    serialNumber: propTypes.string.isRequired,
    /** A note that explains the pricing */
    pricingNote: propTypes.string,
    /** Srcset options */
    srcsetOptions: srcsetOptionsType,
    /** Srcset sizes */
    srcsetSizes: propTypes.string,
    /** The price */
    displayAmount: propTypes.number,
    /** Term type */
    termType: propTypes.oneOf(['day', 'week', 'month', 'annual']),
    /** Is the cta (button) in a pressed state? */
    isPressed: propTypes.bool,
};

OR143DeviceList.displayName = 'OR143DeviceList';

export default OR143DeviceList;
