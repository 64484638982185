import React from 'react';
import propTypes from 'prop-types';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import OR20HdToHd from '../../20-hdtohd';
import SectionHeading from '../section-heading';

const TEAM_PROPTYPE = propTypes.shape({
    id: propTypes.number,
    name: propTypes.string,
    percentage: propTypes.number,
});

const H2HPercentageSection = ({
    section: {title, sport, teamA = {}, teamB = {}, draw = {}},
    getTeamColor,
    teamADefaultColor,
    teamBDefaultColor,
}) => (
    <section>
        <SectionHeading>{title}</SectionHeading>
        <OR20HdToHd
            teamAValue={teamA.percentage}
            teamAName={teamA.name}
            teamAColor={getTeamColor({
                sport,
                id: teamA.id,
                fallbackColor: teamADefaultColor,
            })}
            teamBValue={teamB.percentage}
            teamBName={teamB.name}
            teamBColor={getTeamColor({
                sport,
                id: teamB.id,
                fallbackColor: teamBDefaultColor,
            })}
            drawName={draw.name}
            drawValue={draw.percentage}
            drawColor={undefined}
        />
    </section>
);

H2HPercentageSection.propTypes = {
    /** Object containing data about this section */
    section: propTypes.shape({
        title: propTypes.string,
        sport: propTypes.string,
        teamA: TEAM_PROPTYPE,
        teamB: TEAM_PROPTYPE,
        draw: TEAM_PROPTYPE,
    }),
    /** Function that returns a color given a sport and team ID. Accepts an object of {sport: 'my-sport', id: 'my-team-id', fallbackColor: 'my-fallback-color'} */
    getTeamColor: propTypes.func,
    /** Color to use for team A if unable to retrieve a color */
    teamADefaultColor: colorType,
    /** Color to use for team B if unable to retrieve a color */
    teamBDefaultColor: colorType,
};

H2HPercentageSection.displayName = 'H2HPercentageSection';

export default H2HPercentageSection;
