import React from 'react';
import Ic from '..';

const IC247FaqPlus = (args) => (
    <Ic {...args}>
        <path d="M87.516 45.605H52.911V11h-8.306v34.605H10v8.306h34.605v34.605h8.306V53.911h34.605z" />
    </Ic>
);

IC247FaqPlus.displayName = 'IC247FaqPlus';

export default IC247FaqPlus;
