import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import {classNameType, colorType} from '@fsa-streamotion/custom-prop-types';
import {deepMint} from '../../../../common/palette';

const GA92BrandLogo = ({
    className,
    height,
    width,
    title = 'Hubbl',
    fill = deepMint,
    ...htmlAttributes
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 784.2 200"
        className={classnames('GA92BrandLogo', className)}
        {...(() => {
            if (width) {
                return {width};
            } else if (height) {
                return {height};
            } else {
                return {width: '100%'};
            }
        })()}
        {...htmlAttributes}
    >
        <title>{title}</title>
        <path
            fill={fill}
            d="M510.9 77.4c-6.3-12-15.2-21.3-26.3-27.8-11.1-6.5-24.1-9.8-38.5-9.8-9.5
            0-18.3 1.7-26.3 5-6.4 2.7-11.8 6.2-16.2 10.5V0h-45.9v196.6h45.9v-13.5c4.4
            4.5 9.7 8.1 15.8 10.7 7.7 3.3 16.5 4.9 26.2 4.9 14.6 0 27.7-3.3 38.9-9.8
            11.2-6.5 20.1-15.9 26.5-27.8 6.3-11.9 9.5-26 9.5-41.9-.1-15.8-3.3-29.9-9.6-41.8zm-41.1
            62.7c-2.9 5.9-7.1 10.6-12.5 13.9-5.4 3.3-11.5 5-18.2 5-7.1 0-13.3-1.6-18.5-4.9-5.2-3.2-9.4-7.9-12.4-13.8-3-6-4.6-13.1-4.6-21.1
            0-7.9 1.5-14.9 4.3-21 2.8-6 6.9-10.7 12.2-13.9 5.3-3.3 11.7-4.9 18.9-4.9
            6.7 0 12.8 1.7 18.2 4.9 5.4 3.2 9.6 7.9 12.5 13.8 2.9 6 4.4 13.1 4.4 21.1.1
            7.9-1.4 14.9-4.3 20.9zm219.1-62.7c-6.3-12-15.2-21.3-26.3-27.8-11.1-6.5-24.1-9.8-38.5-9.8-9.5
            0-18.3 1.7-26.3 5-6.4 2.7-11.8 6.2-16.2 10.5V0h-45.9v196.6h45.9v-13.5c4.4
            4.5 9.7 8.1 15.8 10.7 7.7 3.3 16.5 4.9 26.2 4.9 14.6 0 27.7-3.3 38.9-9.8 11.2-6.5
            20.1-15.9 26.5-27.8 6.3-11.9 9.5-26 9.5-41.9-.1-15.8-3.3-29.9-9.6-41.8zm-41.1
            62.7c-2.9 5.9-7.1 10.6-12.5 13.9-5.4 3.3-11.5 5-18.2 5-7.1 0-13.3-1.6-18.5-4.9-5.2-3.2-9.4-7.9-12.4-13.8-3-6-4.6-13.1-4.6-21.1
            0-7.9 1.5-14.9 4.3-21 2.8-6 6.9-10.7 12.2-13.9 5.3-3.3 11.7-4.9 18.9-4.9 6.7
            0 12.8 1.7 18.2 4.9 5.4 3.2 9.6 7.9 12.5 13.8 2.9 6 4.4 13.1 4.4 21.1.1 7.9-1.4
            14.9-4.3 20.9zm111 7.1V0h-45.9v142.4c0 30 24.3 54.3 54.3 54.3h17.1v-49.5h-25.5zM293.3
            45.6l-.2 82.1c0 15.6-12.6 28.2-28.2 28.2s-28.2-12.6-28.2-28.2V45.6h-45.4v80.9c0 40.6
            32.9 73.4 73.4 73.4 40.6 0 73.4-32.9 73.4-73.4l.6-80.9h-45.4zM125.8 0v75.5H46.4V0H0v196.6h46.4v-78.9h79.4v78.9h46.1V0z"
        />
    </svg>
);

GA92BrandLogo.displayName = 'GA92BrandLogo';

GA92BrandLogo.propTypes = {
    /** Additional class(es) */
    className: classNameType,
    // Logo color/styling
    /** Optional width of logo */
    width: propTypes.string,
    /** Optional height of logo */
    height: propTypes.string,
    /** Optional fill color */
    fill: colorType,
    /** Hubbl title */
    title: propTypes.string,
};

export default GA92BrandLogo;
