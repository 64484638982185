import React from 'react';
import Ic from '..';

const IC49Open = (args) => (
    <Ic {...args}>
        <path d="M53.91 45.599V15.146a4.153 4.153 0 1 0-8.305 0V45.6H15.153a4.153 4.153 0 1 0 0 8.305h30.452v30.453a4.153 4.153 0 1 0 8.306 0V53.904h30.452a4.153 4.153 0 1 0 0-8.305H53.911z" />
    </Ic>
);

IC49Open.displayName = 'IC49Open';

export default IC49Open;
