import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

const StyledSvg = styled.svg`
    display: block;
    width: auto;
    height: 100%; /* Scaled by HEIGHT :O */
    fill: currentColor;
`;

const GA106Imdb = ({
    className,
    ...htmlAttributes
}) => (
    <StyledSvg
        {...htmlAttributes}
        className={classnames('GA106Imdb', className)}
        viewBox="0 0 205 100"
    >
        <path d="M118.557592 30.5640158c-.589345-.3630106-1.708446-.5294816-3.331606-.5294816v37.8974311c2.157015 0 3.472655-.4185009 3.974528-1.2855714.505974-.8692573.758004-3.2203522.758004-7.0858136V37.1971596c0-2.6033982-.085559-4.2817755-.25203-5.0102569-.168658-.7536296-.563651-1.2874848-1.148896-1.6228869M155.953341 43.1872991c-.86707 0-1.454229.3354021-1.734141.9780511-.253943.6727177-.392805 2.295878-.392805 4.9287982v15.2565589c0 2.5476346.168657 4.1707949.448296 4.871121.305607.7260212.897139 1.0592365 1.789631 1.0592365.924748 0 2.129134-.3608238 2.409046-1.0890317.279638-.728208.420687-2.5178393.420687-5.2898954V49.0941484c0-2.4344671-.168658-4.0319324-.476178-4.7855621-.311894-.7284813-1.541975-1.1212872-2.464536-1.1212872" />
        <path d="M175.066741 65.3005502c0 3.4791107-.226382 6.0800234-.707583 7.8013578-.452764 1.6995243-1.557069 3.1986174-3.310704 4.4409597-1.725475 1.2724346-3.815371 1.8936058-6.19211 1.8936058-1.723543 0-3.925526-.3688376-5.426828-1.1020957-1.496884-.7655589-2.855454-1.8657221-4.069913-3.3648152l-.959641 3.7035606h-13.629323V20.3498436h14.588964v18.9713958c1.214459-1.3845216 2.573029-2.4305738 4.04203-3.1077884 1.498817-.707583 3.731168-1.0482609 5.454711-1.0482609 2.01011 0 3.733377.3105856 5.23247.9317568 1.470933.6253123 2.573029 1.498817 3.364539 2.6290724.761418 1.1324641 1.242342 2.2367684 1.387006 3.3107043.16813 1.0720034.226382 3.3648152.226382 6.8977608v16.3660658zm-39.439952-3.9277343c0 5.2305373-.282702 8.7353231-.791786 10.4867497-.478716 1.7533592-1.356638 3.1359482-2.573029 4.1281656-1.214182 1.0178925-2.743368 1.6973157-4.520746 2.0921045-1.811611.3947888-4.524887.5932875-8.144244.5932875h-18.348292V20.3498436h11.280469c7.322365 0 11.562618.3428865 14.135923 1.0223097 2.573029.675006 4.550838 1.7771017 5.879592 3.3062871 1.35857 1.5250442 2.2064 3.2505197 2.544869 5.1162418.310586 1.8936058.537244 5.5968903.537244 11.1098535v20.4682802zM96.0744743 78.6731232H82.8465652l-.0281598-39.3518838-5.3144644 39.3518838h-9.44263L62.4923044 40.169069l-.0582521 38.5040542H49.176051V20.3498436h19.6207266c.5932875 3.5348781 1.1862989 7.6912034 1.8356299 12.4405401l2.1503565 14.8131379 3.5047858-27.253678h19.7869245v58.3232796zm-67.1963646 0h15.1259321V20.3498436H28.8781097v58.3232796zM192.718494 0H11.6771898C5.23688708 0 0 5.24102822 0 11.6813309v76.6392707C0 94.7609043 5.23688708 100 11.6771898 100H192.718494c6.440302 0 11.68133-5.2390957 11.68133-11.6793984V11.6813309C204.399824 5.24102822 199.158796 0 192.718494 0z" fillRule="evenodd" />
    </StyledSvg>
);

GA106Imdb.propTypes = {
    /** Additional class(es) */
    className: classNameType,
};

GA106Imdb.displayName = 'GA106Imdb';

export default GA106Imdb;
