import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {palette as infinityPalette} from '@fsa-streamotion/streamotion-web-infinity';
import {palette as ionicPalette} from '@fsa-streamotion/streamotion-web-ionic';
import {palette as muiPalette} from '@fsa-streamotion/streamotion-web-mui';
import {palette as nucleusPalette} from '@fsa-streamotion/streamotion-web-nucleus';

import {stylesWhenBinge, stylesWhenFlash, stylesWhenKayo, stylesWhenLifestyle} from '../../../utils/styles-when-brand';

import {
    BrandedBA01BtnPr,
    BrandedIC103Loading,
} from '../../../utils/branded-components';
import {SCREEN_768_TABLET, SCREEN_1280_DESKTOP, SCREEN_1920_DESKTOP} from '../../../common/screen-sizes';
import brandPropType from '../../../common/brand-proptype';
import isNoop from '../../../utils/check-if-noop';

const ZINDEX_FOR_SIGN_IN = 2; // Because landing hero image is one itself, and we'll stack over it.

const {black} = infinityPalette;
const {vader} = ionicPalette;
const {coal, silverLining, slate} = muiPalette;
const {midnight} = nucleusPalette;

const HeroBannerBackground = styled.div`
    min-height: 480px;

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        min-height: 600px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        min-height: 900px;
    `}
`;

const StyledBrandedLoginBA01BtnPr = styled(BrandedBA01BtnPr)`
    position: absolute;
    top: 14px;
    right: 23px;
    z-index: ${ZINDEX_FOR_SIGN_IN};

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        top: 28px;
        right: 42px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        top: 43px;
        right: 56px;
    `}

    ${stylesWhenFlash`
        background-color: ${rgba(black, 0.2)};
    `}

    ${stylesWhenBinge`
        background-color: ${rgba(vader, 0.2)};
    `}

    ${stylesWhenLifestyle`
        background-color: ${rgba(midnight, 0.2)};
    `}

    ${stylesWhenKayo`
        border: 1px solid ${rgba(slate, 0.35)};
        background-color: ${rgba(coal, 0.35)};
        color: ${silverLining};

        &:hover {
            color: ${coal};
        }
    `}
`;

/*
 * Renders a sign-in button and a hero banner background.
 * - The sign-in button displays a loading spinner if the sign-in process is in progress.
 * - The hero banner background is set using dangerouslySetInnerHTML with trusted HTML content.
 */
const Poster = ({
    bannerBackgroundId,
    brand,
    html,
    isSignInLoading,
    onSignIn,
    signInWords,
}) => (
    <React.Fragment>
        {!!onSignIn && !isNoop(onSignIn) && (
            <StyledBrandedLoginBA01BtnPr
                onClick={onSignIn}
                disabled={!!isSignInLoading}
                brand={brand}
            >
                {isSignInLoading ? <BrandedIC103Loading size="30px" brand={brand} /> : signInWords}
            </StyledBrandedLoginBA01BtnPr>
        )}

        <HeroBannerBackground
            id={bannerBackgroundId}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
                __html: html, // this is fine because the html fragments are trusted
            }}
        />
    </React.Fragment>
);

Poster.displayName = 'Poster';

Poster.propTypes = {
    /** ID for targeting the banner background */
    bannerBackgroundId: propTypes.string,
    /** brand name */
    brand: brandPropType,
    /** Html node */
    html: propTypes.node,
    /** Is SignIn button loading? */
    isSignInLoading: propTypes.bool,
    /** Callback on SignIn click */
    onSignIn: propTypes.func,
    /** SignIn words */
    signInWords: propTypes.string,
};

export default Poster;
