import React from 'react';
import Ic from '..';

const IC230ParentalControls = (args) => (
    <Ic {...args}>
        <g transform="translate(16 11)">
            <path d="M67.536 78.302H0V26.124l67.536-6.09v58.268zM8.4 69.902h50.736V29.218L8.4 33.796v36.106z" />
            <path d="M56.826 25.494h-2.8c0-12.516-9.072-22.694-20.202-22.694-11.13 0-20.216 10.178-20.216 22.694h-2.8C10.808 11.438 21.14 0 33.824 0c12.684 0 23.002 11.438 23.002 25.494zM39.298 50.652c.004-2.757-2.038-5.09-4.771-5.45-2.734-.36-5.31 1.365-6.02 4.029-.71 2.664.667 5.441 3.217 6.489v6.104l4.2-.532v-5.6c2.033-.852 3.36-2.836 3.374-5.04z" />
        </g>
    </Ic>
);

IC230ParentalControls.displayName = 'IC230ParentalControls';

export default IC230ParentalControls;
