import React from 'react';
import {rgba} from 'polished';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {A} from '../../../../common/normalized-styled-components';
import {transition} from '../../../../common/animations';
import {black, white, flash, buttonActiveState} from '../../../../common/palette';
import GA04NavInd from '../../ga/04-nav-ind';

const StyledListItem = styled.li`
    position: relative;
    margin: auto;
    width: fit-content;
    height: 100%; /* Placing this here because this element does not know/care what the container's display properties are */
    list-style: none;
    text-align: center;
`;

const StyledGA04NavInd = styled(GA04NavInd)`
    color: transparent;
`;

export const StyledLink = styled(A)`
    ${stylesWhen('isActive')`
        ${StyledGA04NavInd} {
            color: currentColor;
        }
    `}

    display: inline-flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    transition: ${transition('color')};
    margin: auto 0;

    height: 100%;
    text-decoration: none;
    text-shadow: 0 1px 1px ${rgba(black, 0.3)};
    letter-spacing: 0.4px;
    color: ${({isActive}) => isActive ? white : rgba(white, 0.7)};
    font: ${({isActive}) => isActive ? 'var(--infinity-nav-copy-selected)' : 'var(--infinity-nav-copy-default)'};
    /* hover state */
    &:focus,
    &:hover {
        outline: 0;
        color: ${white};

        ${StyledGA04NavInd} {
            color: ${flash};
        }
    }
    /* press state */
    &[aria-pressed='true'],
    &:active {
        outline: 0;
        color: ${buttonActiveState};
        font-weight: 600;

        ${StyledGA04NavInd} {
            color: currentColor;
        }
    }
`;

const TB01NavT = React.forwardRef(({
    children,
    isActive = false,
    url,
    className,
    ...htmlAttributes
}, ref) => (
    <StyledListItem isActive={isActive} className={className}>
        <StyledLink href={url} ref={ref} isActive={isActive} {...htmlAttributes}>
            {children}
            <StyledGA04NavInd />
        </StyledLink>
    </StyledListItem>
));

TB01NavT.displayName = 'TB01NavT';

TB01NavT.propTypes = {
    /** Content of button, e.g. text */
    children: propTypes.node.isRequired,
    /** Url for the nav tab to point to */
    url: propTypes.string,
    /** Whether or not this nav Tab is selected */
    isActive: propTypes.bool,

    className: classNameType,
};

export default TB01NavT;
