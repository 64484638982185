import React from 'react';
import classnames from 'classnames';
import property from 'lodash/property';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {colorType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {StyledVisuallyHidden} from '../../../../common/visually-hidden';

const CardStack = styled.figure`
    display: inline-flex;
    position: relative;
    margin: 0;
    padding-left: 5px;
`;

const Card = styled.span.attrs(({offsetTopPx}) => ({
    style: {
        marginTop: `${offsetTopPx}px`,
    },
}))`
    margin-left: -5px;
    border-radius: 1px;
    background-color: ${property('color')};
    width: 6px;
    height: 9px;
`;

const GA102CardStack = ({
    'aria-label': assistiveLabel,
    className,
    cardColors = [],
    ...htmlAttributes
}) => (
    <CardStack
        {...htmlAttributes}
        className={classnames('GA102CardStack', className)}
        title={assistiveLabel}
    >
        <StyledVisuallyHidden as="figcaption">{assistiveLabel}</StyledVisuallyHidden>
        {cardColors.map((color, index) => (
            <Card
                key={`${color}__${index}`}
                color={color}
                offsetTopPx={index}
                role="img"
                aria-hidden="true"
            />
        ))}
    </CardStack>
);

GA102CardStack.displayName = 'GA102CardStack';

/* eslint-disable quote-props */
GA102CardStack.propTypes = {
    /** aria-label */
    'aria-label': propTypes.string.isRequired,
    /** card colors */
    cardColors: propTypes.arrayOf(colorType),
    /** Additional class(es) */
    className: classNameType,
};
/* eslint-enable quote-props */

export default GA102CardStack;
