/* eslint-disable arrow-body-style */
import React from 'react';
import bacon from 'baconjs';
import styled from 'styled-components';

import {format, isValid} from 'date-fns';
import invoke from 'lodash/invoke';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {screenSizes} from '@fsa-streamotion/streamotion-web-ionic';

import {BrandedTM19PmntCc} from '../../utils/branded-components';

import {Paragraph} from '../common/styled-elements';
import getStepGiftCardStream from './step-gift-card';

const {SCREEN_1920_DESKTOP, SCREEN_1280_DESKTOP} = screenSizes;

const PaymentTileWrapper = styled.div`
    width: 100%;
    min-width: 320px;
    max-width: 375px;
`;

const StyledBrandedTM19PmntCc = styled(BrandedTM19PmntCc)`
    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        width: 333px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 400px;
    `}
`;

export default function getPaymentReuseStream({
    existingPaymentMethod,
    brand,
    paymentMethod,
    platformEnv,
    telstraOrderItemId,
    user,
    startSubscription$,
}) {
    let paymentElement = null;
    let expiryDateObject, displayExpiryDate, creditCardSplit, creditCardDisplay;

    const stepGiftCard$ = getStepGiftCardStream({
        brand,
        platformEnv,
        user,
        startSubscription$: startSubscription$.toProperty(),
    })
        .startWith();

    const paymentDetails$ = bacon.combineWith(
        // Only include valid gift card data in the subscribe call
        stepGiftCard$.map((giftCard) => ({
            giftCode: giftCard.giftCode,
            giftPin: giftCard.giftPin,
        })), startSubscription$,
        ({giftCode, giftPin}) => ({
            dateRequested: new Date().toISOString(),
            giftCode,
            giftPin,
            paymentRefId: existingPaymentMethod.id,
            telstraOrderItemId,
        })
    );

    const paymentSuccess$ = startSubscription$.map(true);

    switch (existingPaymentMethod?.type) {
        case 'CreditCard':
            expiryDateObject = new Date(existingPaymentMethod.expiration);
            displayExpiryDate = isValid(expiryDateObject) && format(expiryDateObject, 'yyyy/MM');
            creditCardSplit = invoke(existingPaymentMethod.number, 'match', /.{1,4}/g) || []; // @TODO we have a method for this display credit card stuff?
            creditCardDisplay = creditCardSplit.join(' ');

            paymentElement = (
                <React.Fragment>
                    <Paragraph brand={brand}>
                        Your saved or existing Hubbl payment details are below. Select ‘Update Credit Card’ to change these.
                    </Paragraph>

                    <StyledBrandedTM19PmntCc
                        expiry={displayExpiryDate}
                        isSelected={true}
                        number={creditCardDisplay}
                        // onClick={}
                        type={invoke(existingPaymentMethod.cardType, 'toLowerCase')}
                        brand={brand}
                    />
                </React.Fragment>
            );

            break;

        case 'TBILL':
            // @TODO Check this the right case.
            // Platform suggest there's no opportunity to reuse a TBILL payment. You just add a new one.

            // might need to hang off user.getIdDetails();
            // paymentElement = (
            //     <TM19PmntTelstra
            //         name="Darren Nolan"
            //         isSelected={true}
            //         // onClick={}
            //     />
            // );
            console.error('AccW-StepPayment: Telstra reuse payment type not implemented');
            break;

        default:
            // iono why I'd be here... or what I'd do.... :grimace:
            console.error(`AccW-StepPayment: Unknown reuse payment type '${existingPaymentMethod?.type}'`);
            break;
    }

    const reactElement = (
        <React.Fragment>
            <PaymentTileWrapper>
                {paymentElement}
            </PaymentTileWrapper>
            {existingPaymentMethod?.type === 'CreditCard' && stepGiftCard$.map('.reactElement')}
        </React.Fragment>
    );

    return bacon.combineTemplate({
        label: 'Saved Details',
        reactElement,

        paymentLoaded: bacon.later(1000, true).startWith(false),
        paymentErrors: null,

        shouldDisableSubscribeButton: stepGiftCard$.map('.shouldDisableSubscribeButton'),

        paymentDetails: paymentDetails$.startWith(null),
        paymentSuccessful: paymentSuccess$.startWith(false), // @TODO I think we can drop this.

        paymentMethod,

        submitPayment$: paymentSuccess$.startWith(null),
    });
}
