import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {silverLining} from '../../../../common/palette';
import VisuallyHidden from '../../../../common/visually-hidden';

const Container = styled.div`
    color: ${silverLining};
`;

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const MainScore = styled(FlexRow)`
    margin-bottom: 14px;
`;

const BigScore = styled.div`
    font: var(--mui-hud-large-score);
`;

const CenterAligned = styled.div`
    text-align: center;
`;

const CentreBlock = styled.div`
    width: 108px;
    text-align: center;
`;

const QuarterScoreRow = styled(FlexRow)`
    margin-bottom: 4px;
`;

const NormalText = styled.div`
    font: var(--mui-hud-tile-copy);
`;

const LightText = styled.div`
    font: var(--mui-hud-tile-copy-light);
`;

const QuarterScores = styled(FlexRow)`
    justify-content: space-between;
`;

const ScoreCell = styled.span`
    margin: 0 auto;
    width: 25px;
    text-align: center;
`;

const Subscore = styled(ScoreCell)`
    font: var(--mui-hud-tile-copy);
`;

const QuarterScoreTotal = styled(ScoreCell)`
    font: var(--mui-hud-tile-copy-bold);
`;

const Title = styled.h3`
    margin: 14px 0 21px;
    text-align: center;
    color: ${silverLining};
    font: var(--mui-hud-tile-header-bold);
`;

const UndecoratedAbbr = styled.abbr`
    text-decoration: none;
`;

const MatchStatus = styled(LightText)`
    ${stylesWhen('isShiftedDown')`
        margin-top: 9px;
    `}
`;

const IGM26QtrScores = ({
    title,
    venue,
    periodscoreboards = [],
    matchStatus,
    matchTime,
    className,
}) => {
    // The API gives us the stats for each individual quarter, but we want to show the stats aggregated over each quarter
    // Don't get too freaked out by the .reduce going on here - we're just manipulating the contents of periodscoreboards[somequarter][someTeam][someStat]
    // and we're retaining the rest
    const aggregatedPeriodscoreboards = periodscoreboards.reduce((curr, quarter) => ([
        ...curr,
        {
            ...quarter,
            ...['team_A', 'team_B'].reduce((collectedTeams, team) => ({
                ...collectedTeams,
                [team]: {
                    ...quarter[team],
                    ...['points', 'goals', 'behinds', 'super_goals'].reduce((collectedStats, stat) => ({
                        ...collectedStats,
                        // If the stat is null (untracked), or this is the first quarter, don't manipulate it
                        [stat]: (curr.length === 0 || quarter[team][stat] === null)
                            ? quarter[team][stat]
                            : quarter[team][stat] + curr[curr.length - 1][team][stat],
                    }), {}),
                },
            }), {}),
        },
    ]), []);

    const pastQuarters = aggregatedPeriodscoreboards.slice(0, -1);
    const [mostRecentQuarter] = aggregatedPeriodscoreboards.slice(-1);

    /* eslint-disable camelcase */
    // Prefer to use stats fields verbatim, which involves snake_case
    const [teamAMainScore, teamBMainScore] = mostRecentQuarter ? ['team_A', 'team_B'].map((team) => {
        const {name, points, super_goals, goals, behinds} = mostRecentQuarter[team];
        const periodName = mostRecentQuarter.period.name;

        return (
            <React.Fragment>
                <VisuallyHidden>{`${name} ${periodName} stats`}</VisuallyHidden>
                <BigScore aria-label={`${points} total`}>
                    {points}
                </BigScore>
                <NormalText>
                    {super_goals !== null && (
                        <span aria-label={`${super_goals} super goals`}>{super_goals}.</span>
                    )}
                    <span aria-label={`${goals} goals`}>{goals}.</span>
                    <span aria-label={`${behinds} behinds`}>{behinds}</span>
                </NormalText>
            </React.Fragment>
        );
    }) : [];

    const quarterRows = pastQuarters.map((pastQuarter) => (
        <QuarterScoreRow key={pastQuarter.period.short_name}>
            {(() => {
                const periodName = pastQuarter.period.name;

                const [teamAScores, teamBScores] = ['team_A', 'team_B'].map((team) => {
                    const {name, points, super_goals, goals, behinds} = pastQuarter[team];

                    return (
                        <QuarterScores>
                            <VisuallyHidden>{`${name} ${periodName} stats`}</VisuallyHidden>
                            {super_goals !== null && (
                                <Subscore aria-label={`${super_goals} super goals`}>{super_goals}</Subscore>
                            )}
                            {goals !== null && (
                                <Subscore aria-label={`${goals} goals`}>{goals}</Subscore>
                            )}
                            {behinds !== null && (
                                <Subscore aria-label={`${behinds} behinds`}>{behinds}</Subscore>
                            )}
                            <QuarterScoreTotal aria-label={`${points} total`}>{points}</QuarterScoreTotal>
                        </QuarterScores>
                    );
                });

                return (
                    <React.Fragment>
                        {teamAScores}
                        <CentreBlock>
                            <LightText>
                                <UndecoratedAbbr title={periodName}>
                                    {pastQuarter.period.short_name}
                                </UndecoratedAbbr>
                            </LightText>
                        </CentreBlock>
                        {teamBScores}
                    </React.Fragment>
                );
            })()}
        </QuarterScoreRow>
    )).reverse();
    /* eslint-enable camelcase */

    return (
        <Container className={classnames('IGM26QtrScores', className)}>
            <Title>
                {title}
                <br />
                {venue}
            </Title>
            {!!mostRecentQuarter && (
                <MainScore>
                    <CenterAligned>
                        {teamAMainScore}
                    </CenterAligned>
                    <CentreBlock>
                        {!!matchStatus && (
                            <MatchStatus isShiftedDown={!matchTime}>{matchStatus}</MatchStatus>
                        )}
                        {!!matchTime && (
                            <NormalText>{matchTime}</NormalText>
                        )}
                    </CentreBlock>
                    <CenterAligned>
                        {teamBMainScore}
                    </CenterAligned>
                </MainScore>
            )}
            {quarterRows}
        </Container>
    );
};

// This just describes the actual API response from periodscoreboards. We've kept it this way to reduce complexity/be more explicit about API usage
// e.g. https://statsapi.foxsports.com.au/3.0/api/sports/afl/matches/AFL20182701/periodscoreboards.json?userkey=9024ec15-d791-4bfd-aa3b-5bcf5d36da4f
const periodscoreboardsType = propTypes.arrayOf(propTypes.shape({
    period: propTypes.shape({
        short_name: propTypes.string,
    }),
    team_A: propTypes.shape({
        points: propTypes.number,
        goals: propTypes.number,
        behinds: propTypes.number,
        super_goals: propTypes.number,
        name: propTypes.string,
    }),
    team_B: propTypes.shape({
        points: propTypes.number,
        goals: propTypes.number,
        behinds: propTypes.number,
        super_goals: propTypes.number,
        name: propTypes.string,
    }),
}));

IGM26QtrScores.displayName = 'IGM26QtrScores';

IGM26QtrScores.propTypes = {
    /** The title for the match, e.g. Round 6, Sun 14 Apr */
    title: propTypes.string,
    /** The venue name, e.g. Marvel Stadium */
    venue: propTypes.string,
    /** A response body from the periodscoreboards API */
    periodscoreboards: periodscoreboardsType,
    /** The current match status */
    matchStatus: propTypes.string,
    /** The current match time. Empty if not in progress. */
    matchTime: propTypes.string,
    /** Optional extra class name(s) for the component */
    className: classNameType,
};

export default IGM26QtrScores;
