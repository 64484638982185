import React from 'react';
import {midnight} from '../../../../common/palette';
import Ic from '..';

const IC152MultiAdd1 = (args) => (
    <Ic {...args}>
        <circle cx="50" cy="50" r="42.4" />
        <path fill={midnight} d="M54.7 63.5h-6.6V39h-5.3v-4.7h11.9v29.2z" />
    </Ic>
);

IC152MultiAdd1.displayName = 'IC152MultiAdd1';

export default IC152MultiAdd1;
