import React from 'react';
import styled, {css} from 'styled-components';
import propTypes from 'prop-types';
import {A, Button} from 'normalized-styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';
import noop from 'lodash/get';

import {mediaQuery, stylesIfElse} from '@fsa-streamotion/styled-component-helpers';
import {buttonType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {blanc, midnight} from '../../../../common/palette';
import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';

const baseStyles = css`
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    transition: ${transition('border-color', 'background-color', 'color')};
    border: 1px solid ${rgba(blanc, 0.3)};
    background-color: transparent;
    padding: 7px 21px;
    min-height: 50px;
    color: ${rgba(blanc, 0.5)};
    font: var(--nucleus-button-sec-light);

    ${stylesIfElse('isBlock')`
        display: flex;
        width: 100%;
    ``
        display: inline-flex;
        min-width: 250px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        border-width: 2px;
        padding: 14px 28px;
        min-width: 400px;
        min-height: 72px;
    `}
`;

const interactiveStyles = css`
    &:hover,
    &:focus {
        outline: 0;
        border-color: ${blanc};
        color: ${blanc};
    }

    &:active,
    &[aria-pressed='true'],
    &[aria-current='true'] {
        border-color: ${blanc};
        background-color: ${blanc};
        color: ${midnight};
        font: var(--nucleus-button-sec);
    }

    &:disabled {
        border-color: ${rgba(blanc, 0.2)};
        color: ${rgba(blanc, 0.3)};
    }
`;

const StyledButton = styled(Button).attrs(({isPressed}) => ({
    'aria-pressed': isPressed,
}))`
    ${baseStyles}
    ${interactiveStyles}

    appearance: none;
`;

const StyledLink = styled(A).attrs(({isPressed}) => ({
    'aria-current': isPressed,
}))`
    ${baseStyles}
    ${interactiveStyles}

    text-decoration: none;
`;

const NonInteractive = styled.div`
    ${baseStyles}

    border-color: ${rgba(blanc, 0.2)};
    cursor: not-allowed;
    color: ${rgba(blanc, 0.3)};
`;

const BA08BtnSec = React.forwardRef(({
    children,
    className,
    disabled,
    href,
    isBlock,
    isPressed,
    onClick,
    type = 'button',
    ...htmlAttributes
}, ref) => {
    const isNonInteractive = disabled || (!href && (!onClick || onClick === noop));
    const StyledElement = getCtaType({isNonInteractive, href, onClick});

    return (
        <StyledElement
            {...htmlAttributes}
            className={classnames('BA08BtnSec', className)}
            ref={ref}
            isBlock={isBlock}
            isPressed={isPressed}
            // We only want to put a type for buttons
            href={isNonInteractive ? undefined : href}
            onClick={isNonInteractive ? undefined : onClick}
            type={href || isNonInteractive ? undefined : type}
        >
            {children}
        </StyledElement>
    );
});

BA08BtnSec.propTypes = {
    /** Content of button, e.g. text */
    children: propTypes.node,
    /** additional CSS classnames to be applied */
    className: classNameType,
    /** Is this button disabled? */
    // eslint-disable-next-line react/boolean-prop-naming
    disabled: propTypes.bool,
    /* If it’s actually a link, provide a href */
    href: propTypes.string,
    /** Whether the button fills its parent's width or not */
    isBlock: propTypes.bool,
    /** Whether the button is currently selected */
    isPressed: propTypes.bool,
    /** Callback on element click */
    onClick: propTypes.func,
    /** The HTML type of the button */
    type: buttonType,
};

function getCtaType({isNonInteractive, href, onClick}) {
    if (href && !isNonInteractive) {
        return StyledLink;
    } else if (onClick && !isNonInteractive) {
        return StyledButton;
    }

    return NonInteractive;
}

BA08BtnSec.displayName = 'BA08BtnSec';

export default BA08BtnSec;
