import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import classnames from 'classnames';
import property from 'lodash/property';

import {rgba} from 'polished';
import {ink, indigo, white} from '../../../../common/palette';

const Wrapper = styled.div`
    position: sticky;
    bottom: 0;
    left: 0;
    border-top: 1px solid ${rgba(ink, 0.1)};
    background-color: ${property('backgroundColor')};
    padding: 14px;
    color: ${white}; // Any text content of footer should be white

    a {
        color: currentColor;
    }
`;

/**
 * Checkout Footer
 * This footer must be used inside of a Flex container with scrollable height in order to be sticky at the bottom
 *
 * @see {@link https://zpl.io/z80ZmRz}
 */
const OR157CheckoutFooter = ({
    className,
    children,
    backgroundColor = indigo,
}) => (
    <Wrapper
        className={classnames('OR157CheckoutFooter', className)}
        backgroundColor={backgroundColor}
    >
        {children}
    </Wrapper>
);

OR157CheckoutFooter.propTypes = {
    /** additional CSS classnames to be applied */
    className: propTypes.string,
    /** content of the checkout footer */
    children: propTypes.node,
    /** background color of the checkout footer */
    backgroundColor: propTypes.string,
};

OR157CheckoutFooter.displayName = 'OR157CheckoutFooter';

export default OR157CheckoutFooter;
