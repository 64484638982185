/* eslint-disable arrow-body-style */
import React from 'react';
import bacon from 'baconjs';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import {loadScript} from '@fsa-streamotion/browser-utils';

import {parseZuoraServerSideError, parseZuoraClientSideError} from '../common/parse-zuora-errors';
import getZuoraHostedPageParams from '../common/zuora-hosted-page-params';
import {Paragraph} from '../common/styled-elements';
import ZuoraIframeComponent from '../../components/branded/checkout/zuora-iframe';
import getStepGiftCardStream from './step-gift-card';

export const ZUORA_SCRIPT_ID = 'zuora-1.3.1';
export const ZUORA_LIBRARY_URL = 'https://static.zuora.com/Resources/libs/hosted/1.3.1/zuora-min.js';

// https://knowledgecenter.zuora.com/CB_Billing/LA_Hosted_Payment_Pages/B_Payment_Pages_2.0/J_Client_Parameters_for_Payment_Pages_2.0
const ZUORA_DEFAULT_PAGE_PARAMS = {
    style: 'inline',
    submitEnabled: false,
};

async function getHostedPageParams({brand, user, platformEnv}) {
    // Fetch a new access token to ensure we get a new zuora token
    const accessToken = await user.refreshAccessToken();
    const params = await getZuoraHostedPageParams({brand, accessToken, platformEnv})
        .firstToPromise();

    const hostedPageParams = {
        ...params,
        ...ZUORA_DEFAULT_PAGE_PARAMS,
        paymentGateway: params?.paymentGateway?.creditCard,
    };

    return hostedPageParams;
}

function generateZuoraSettings(userDetails, zuoraHostedPageParams, paymentGateway, paymentErrorBus) {
    const zuoraPaymentHandler = () => {
        return (paymentResponse) => {
            const {success, errorMessage} = paymentResponse;

            if (!success) {
                paymentErrorBus.push({message: errorMessage});
            }
        };
    };

    return [
        {
            ...ZUORA_DEFAULT_PAGE_PARAMS,
            ...zuoraHostedPageParams,
            paymentGateway, // zuoraHostedPageParams.paymentGateway: {creditCard: "braintree-martian-stg", paypal: "paypal-martian-stg"}
        },
        {
            // Zuora misinterprets names containing certain strings (e.g. "resize") as requests to do things other than pre-fill the card holder's name.
            // The card holder's name is upper cased to work around this bug.
            creditCardHolderName: `${userDetails.firstName} ${userDetails.lastName}`.toUpperCase(),
            creditCardCountry: 'AUS',
            phone: userDetails.phoneNumber,
            email: userDetails.email,
        },
        zuoraPaymentHandler(paymentGateway),
        (key, code, message) => {
            // If an error occurs during server side validation, 'error' will be returned as key,
            // code will be set to unknown, message contains the error message.
            if (key === 'error') {
                paymentErrorBus.push({message: parseZuoraServerSideError(message)});
            } else { // Error message generated by client-side validation.
                paymentErrorBus.push({message: parseZuoraClientSideError({key, code, message})});
            }
        },
    ];
}

async function createWindowPaymentBusMediator({paymentGateway, successBus, errorBus, brand, user, platformEnv}) {
    window._paymentBusMediator = {
        submitSuccess: (response) => void successBus.push({paymentGateway, ...response}),
        submitFail: async ({message}) => {
            // Re-generate zuora token & signature so that we can re-render iframe
            // as zuora token will expire after first use
            const [zuoraHostedPageParams, userDetails] = await Promise.all([
                getHostedPageParams({brand, user, platformEnv}),
                user.getUserDetails(),
            ]);

            // Re-render iframe with new params
            const zuoraSettings = generateZuoraSettings(userDetails, zuoraHostedPageParams, paymentGateway, errorBus);

            window.Z.renderWithErrorHandler(...zuoraSettings);
            // After iframe has rendered, display error message
            window.Z.runAfterRender(() => {
                // Push value to error bus to indicate that something has gone wrong
                errorBus.push({message: parseZuoraServerSideError(message)});
            });
        },
    };

    return window._paymentBusMediator;
}

export default function getPaymentCreditCardStream({
    user,
    brand,
    isPpvOffer,
    platformEnv,
    telstraOrderItemId,

    startSubscription$,
    existingPaymentMethod,
    paymentMethod,
    paymentText,
}) {
    const paymentErrorBus = new bacon.Bus();
    const paymentPageLoadedBus = new bacon.Bus();
    const paymentPageLoadingErrorBus = new bacon.Bus();
    const paymentSuccessBus = new bacon.Bus();

    const existingPaymentMethodType = get(existingPaymentMethod, 'type');
    let label;

    // No payment methods               -> 'Credit Card'
    // Payment Method != 'CreditCard'   -> 'New Credit Card'
    // Payment Method === 'CreditCard' AND NOT PPV offer -> 'Update Credit Card'
    // Note: For PPV offer - the credit card used is once off, and is not linked to their Hubbl account
    if (!isPpvOffer && existingPaymentMethodType === 'CreditCard') {
        label = 'Update Credit Card';
    } else if (existingPaymentMethodType && existingPaymentMethodType !== 'CreditCard') {
        label = 'New Credit Card';
    } else {
        label = 'Credit Card';
    }

    const zuoraJavascriptLoaded$ = bacon.fromPromise(loadScript({url: ZUORA_LIBRARY_URL, id: ZUORA_SCRIPT_ID}))
        .mapError(true) // even if we error, continue on, when we attempt to init the iframe, we'll push back ourselves an error message.
        .map(true);

    const zuoraPaymentPageRenderer$ = zuoraJavascriptLoaded$ // wait for zuora javascript to have loaded first.
        .flatMapLatest(() => bacon.combineTemplate({
            accessToken: bacon.fromPromise(user.getAccessToken()),
            userDetails: bacon.fromPromise(user.getUserDetails()),
        }))
        .flatMapLatest(({accessToken, userDetails}) => {
            return bacon.combineTemplate({
                zuoraHostedPageParams: getZuoraHostedPageParams({brand, accessToken, platformEnv}),
                userDetails,
            });
        })
        .map(({zuoraHostedPageParams, userDetails}) => {
            // @TODO If / when PayPal becomes a thing, we'll need toggle between in the response of params.
            const paymentGateway = zuoraHostedPageParams.paymentGateway.creditCard;

            const windowPaymentParams = {
                paymentGateway,
                successBus: paymentSuccessBus,
                errorBus: paymentErrorBus,
                brand,
                user,
                platformEnv,
            };

            createWindowPaymentBusMediator(windowPaymentParams);

            const zuoraSettings = generateZuoraSettings(userDetails, zuoraHostedPageParams, paymentGateway, paymentErrorBus);

            return () => {
                try {
                    window.Z.renderWithErrorHandler(...zuoraSettings);
                } catch (error) {
                    paymentPageLoadingErrorBus.push({message: 'The payment window failed to load, please refresh your page.'});
                }
            };
        })
        .startWith(null);

    const submitPayment$ = startSubscription$
        .doAction(() => {
            // @TODO Will need to work out payment methods required here and not just 100% zuora.
            window.Z.submit();
        })
        .startWith(undefined);

    const paymentSuccess$ = paymentSuccessBus
        .map(true)
        .startWith(false);

    const paymentErrorMessage$ = paymentErrorBus
        .map('.message')
        .filter(Boolean);

    const paymentErrorMessages$ = bacon
        .update(
            new Set(),

            // [paymentChangeBus], () => [],

            // Wait 100ms and collects each payment error from paymentErrorMessage$ (as they get pushed into errorBus one at a time from Zuora callback)
            [paymentErrorMessage$.bufferWithTime(100)], (prevErrors, errors) => new Set([...prevErrors, ...errors]),

            // // Overwrite errors with gift code validation errors
            // [submitPaymentErrorMessage$], (errors, error) => new Set([error]),

            // Clear accumulated errors upon submitting a payment
            [startSubscription$], () => []
        )
        .map((errors) => [...errors])
        .map((errors) => {
            return !!errors.length && errors.map((errorMessage, index) => (
                <React.Fragment key={index}>
                    {errorMessage}<br />
                </React.Fragment>
            ));
        })
        .startWith(null);

    const stepGiftCard$ = getStepGiftCardStream({
        brand,
        platformEnv,
        user,
        startSubscription$: startSubscription$.toProperty(),
        paymentErrorMessages$,
    })
        .startWith();

    const paymentDetails$ = bacon.combineTemplate({
        giftCode: stepGiftCard$.map('.giftCode'),
        giftPin: stepGiftCard$.map('.giftPin'),
        telstraOrderItemId,

        /*
            paymentGateway: "braintree-martian-stg"
            redirectUrl: "http://localhost"
            signature: "BjtqdfwQmGQrxowa5uDSFmgxUyz/mWhmxZki5mUKSIksSodfpLp0mmUwe3pM5lQxZK5Zt0xfESO14QbCHBQ7V6hoJC6qvW0Wb05iTXmF2/zrmcU4wcveLAvDZaTTPI6IP5Gc0ZCJgNkqYrt+9X9cDMgWjFamvuS9xlePHKodOr1nEcDdM063EKYlrwsUMrZWmPRNBMw+IXiaTdOyx0K2nCt0m3JYdA7SslS3fkC/aCT8y1t6YUm2VlaJyqhw7aWB+2p2u0dZZKqS5+2Sa9c2682C/MgMVJn3zfc88jPo7SHBddbxmqEzUazGaFe3a7uLpoUp8vTf5ayQKTZmMLyvvA=="
            success: "true"
            responseFrom: "Response_From_Submit_Page"
            refId: "2c92c0f870aa155d0170c1728f697f71"
            token: "BrHxK4Rc1vljxVpjX0uDNFjH6Ex3JY2c"

            // We need paymentGateway and paymentRefId from this payload and will only send as much.
        */
        paymentGateway: paymentSuccessBus.map('.paymentGateway'),
        paymentRefId: paymentSuccessBus.map('.refId'),
    })
        .startWith(null);

    const isSubmitting$ = startSubscription$.toProperty()
        .not()
        .and(paymentErrorMessages$.map(isEmpty))
        .startWith(false);

    const reactElement$ = bacon.combineTemplate({
        zuoraPaymentPageRenderer: zuoraPaymentPageRenderer$,
        stepGiftCard: stepGiftCard$.map('.reactElement'),
        paymentsPageLoadingErrorMessage: paymentPageLoadingErrorBus.toProperty(null).map('.message'),
        isSubmitting: isSubmitting$,
    })
        .skipDuplicates(isEqual)
        .map((props) => {
            return (
                <React.Fragment>
                    <Paragraph brand={brand}>{paymentText}</Paragraph>
                    {!!props.zuoraPaymentPageRenderer && (
                        <ZuoraIframeComponent
                            brand={brand}
                            isSubmitting={props.isSubmitting}
                            hostedPaymentPageRenderer={props.zuoraPaymentPageRenderer} // the thing that does the window.Z.renderWithErrorHandler
                            onHostedPaymentPageLoaded={() => void paymentPageLoadedBus.push(true)}
                            paymentsPageLoadingErrorMessage={props.paymentsPageLoadingErrorMessage}
                        />
                    )}

                    {!!props.stepGiftCard && props.stepGiftCard}
                </React.Fragment>
            );
        });

    return bacon.combineTemplate({
        label,
        reactElement: reactElement$.startWith(null),

        shouldDisableSubscribeButton: stepGiftCard$.map('.shouldDisableSubscribeButton'),

        paymentLoaded: paymentPageLoadedBus.toProperty(false),
        paymentErrors: paymentErrorMessages$,

        paymentDetails: paymentDetails$.startWith(null),
        paymentSuccessful: paymentSuccess$.startWith(false), // @TODO I think we can drop this.

        paymentMethod,

        submitPayment$: submitPayment$.startWith(null),
    });
}
