import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {A, Button} from 'normalized-styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';

import {buttonType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {blanc, onyx, midnight, sunset, lifestyle} from '../../../../common/palette';

const baseStyles = css`
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    transform-origin: left center;
    transition: ${transition('text-shadow')};
    box-shadow: 0 -7px 10px 0 ${rgba(onyx, 0.7)};
    background-color: ${lifestyle}; /* prevents FoUC */
    background-image: linear-gradient(277deg, ${sunset}, ${lifestyle});
    padding: 0 21px;
    width: 100%;
    height: 40px;
    overflow: hidden;
    text-align: center;
    text-shadow: 0 1px 1px ${rgba(onyx, 0.3)};
    white-space: nowrap;
    color: ${blanc};
    font: var(--nucleus-nav-copy-default);
    font-weight: normal;
    object-fit: contain;

    &:hover,
    &:focus {
        outline: 0;
        background-color: ${lifestyle}; /* prevents FoUC */
        background-image: linear-gradient(to bottom, transparent, ${rgba(midnight, 0.6)}), linear-gradient(277deg, ${sunset}, ${lifestyle});
        text-shadow: none;
    }

    &[aria-pressed='true'],
    &[aria-current='true'],
    &:active {
        background: ${sunset};
        text-shadow: none;
    }
`;

const StyledButton = styled(Button)`
    ${baseStyles}

    appearance: none;
    border: 0;
`;

const StyledLink = styled(A)`
    ${baseStyles}

    text-decoration: none;
`;

const BA52ConversionBtn = React.forwardRef(({
    children,
    className,
    href,
    onClick,
    type = 'button',
    ...htmlAttributes
}, ref) => {
    const StyledElement = href ? StyledLink : StyledButton;

    return (
        <StyledElement
            {...htmlAttributes}
            {...{
                ref,
                href,
                onClick,
                type: href ? undefined : type,
            }}
            className={classnames('BA52ConversionBtn', className)}
            href={href}
        >
            {children}
        </StyledElement>
    );
});

BA52ConversionBtn.propTypes = {
    /** Content of button, e.g. text */
    children: propTypes.node.isRequired,
    /** additional CSS classnames to be applied */
    className: classNameType,
    /** If it’s actually a link, provide a href */
    href: propTypes.string,
    /** Callback on element click */
    onClick: propTypes.func,
    /** The HTML type of the button */
    type: buttonType,
};

BA52ConversionBtn.displayName = 'BA52ConversionBtn';

export default BA52ConversionBtn;
