import React from 'react';
import Ic from '..';

const IC248FAQMinus = (args) => (
    <Ic {...args}>
        <path d="M10 45.935h77.516v8.787H10z" />
    </Ic>
);

IC248FAQMinus.displayName = 'IC248FAQMinus';

export default IC248FAQMinus;

