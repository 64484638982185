import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import {indigo} from '../../../../common/palette';

import Ic from '..';

const IC152MultiAdd1 = ({
    secondaryColor = indigo,
    ...args
}) => (
    <Ic {...args}>
        <path d="M49.63 8C26.638 8 8 26.638 8 49.63c0 22.99 18.638 41.629 41.63 41.629 22.99 0 41.629-18.638 41.629-41.63C91.252 26.642 72.618 8.009 49.629 8z" />
        <path fill={secondaryColor} d="M53.956 66.116V32.42H40.233v5.42h6.104v28.277z" />
    </Ic>
);

IC152MultiAdd1.propTypes = {
    /** The icon's secondary color */
    secondaryColor: colorType,
};

IC152MultiAdd1.displayName = 'IC152MultiAdd1';

export default IC152MultiAdd1;
