import React from 'react';

import Ic from '..';

const IC119SwipeU = (args) => (
    <Ic {...args}>
        <path d="M36.142 18.578L24.578 7 13 18.578l2.968 2.968 6.412-6.412V37.87h4.2V14.938l6.594 6.608zm49.966 34.51A6.888 6.888 0 0079.22 46.2h-.42a6.855 6.855 0 00-3.29.868 7.14 7.14 0 00-6.524-4.27 7.002 7.002 0 00-3.724 1.078 7.155 7.155 0 00-6.426-3.934h-.154a8.158 8.158 0 00-2.66.658v-3.01c0-1.4 0-2.702.07-3.976.14-5.236.266-9.8-2.268-12.362a6.816 6.816 0 00-5.124-1.918 8.4 8.4 0 00-6.3 2.366 9.422 9.422 0 00-2.478 6.3v26.6a14.76 14.76 0 00-4.466 5.6c-3.626 8.022-.14 18.48 1.512 21.826l.084.182c1.75 3.57 5.404 11.019 25.704 11.019 16.408.111 21.28-14.827 21.644-23.353a6.818 6.818 0 001.708-4.48V53.088zM78.8 50.4h.49a2.703 2.703 0 012.688 2.688v12.306a2.703 2.703 0 01-2.688 2.688h-.49a2.7 2.7 0 01-2.8-2.688V53.088a2.69 2.69 0 012.8-2.688zm-19.908-6.258a2.871 2.871 0 012.8 2.8v1.204a39.651 39.651 0 00-5.866.896v-2.1a.791.791 0 010-.182v-.308a3.052 3.052 0 013.066-2.31zM44.122 28a4.295 4.295 0 014.508-4.494 2.66 2.66 0 012.114.644c1.302 1.4 1.19 5.222 1.078 9.324 0 1.316-.07 2.688-.07 4.088v12.474c-1.4.35-3.038.77-4.816 1.246a21.87 21.87 0 00-2.8.952L44.122 28zm18.634 61.138c-17.682 0-20.44-5.6-21.924-8.666l-.098-.196c-1.316-2.66-4.396-11.732-1.4-18.2 1.512-3.332 4.354-5.516 8.694-6.678 8.89-2.394 12.6-2.898 14.14-2.968.182 1.876 0 4.396-1.12 5.068-3.248 1.82-14.616 6.664-14.728 6.706l1.68 3.85c.42-.182 11.704-4.984 15.092-7 4.2-2.436 3.29-8.918 3.01-10.472a2.508 2.508 0 00-.126-.56v-.14a2.939 2.939 0 014.461-2.714 2.94 2.94 0 011.405 2.714v20.93a2.94 2.94 0 01-5.866 0v-4.69l-4.2 1.932V72.8a2.87 2.87 0 01-2.8 2.87h-.126a2.87 2.87 0 01-2.8-2.87v-2.1l-4.2 1.932v.168a7.084 7.084 0 007 7.07h.126a6.999 6.999 0 005.824-3.22 7 7 0 004.074 1.288 7.126 7.126 0 007-6.258c.88.399 1.834.604 2.8.602h.49c.235-.014.469-.042.7-.084-.798 5.698-4.13 16.94-17.108 16.94z" />
    </Ic>
);

IC119SwipeU.displayName = 'IC119SwipeU';

export default IC119SwipeU;
