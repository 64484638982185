import React from 'react';
import Ic from '..';

const IC64TelstraTv = (args) => (
    <Ic {...args}>
        <path d="M67.1 20.3H32.9c-6.9 0-12.5 5.6-12.5 12.5V67c0 6.9 5.6 12.5 12.5 12.5h34.2c6.9 0 12.5-5.6 12.5-12.5V32.9c.1-6.9-5.6-12.6-12.5-12.6zm11.1 46.8c0 6.1-5 11.1-11.1 11.1H32.9c-6.1 0-11.1-5-11.1-11.1V32.9c0-6.1 5-11.1 11.1-11.1h34.2c6.1 0 11.1 5 11.1 11.1v34.2z" />
        <path d="M43.9 35.7c-.4.3-.6.7-.7 1.1-.3 1.3-.5 2.7-.8 4H49c-.2 1.1-.5 2.2-.7 3.4-.8 4.1-1.7 8.2-2.5 12.3h5.4c.3 0 .7 0 1-.2.3-.1.6-.5.8-.8.2-.4.2-.8.3-1.2.3-2 .7-4.1 1-6.1.4-2.5.8-5 1.3-7.4h4.5c.4 0 .8 0 1.1-.2.5-.2.8-.7.9-1.2.2-1.3.5-2.7.7-4H45c-.4-.1-.8 0-1.1.3z" />
        <path d="M60.2 55.5c-1.2-2.4-2.9-4.4-4.8-6.3-.3 1.7-.6 3.5-.9 5.2 0 .2 0 .3-.1.4-.1.3-.1.7-.3 1.1-.3.7-.8 1.2-1.4 1.4-.4.2-.8.3-1.3.3h-6.9l.3-1.4c.4-2.1.8-4.1 1.3-6.2.4-2 .8-4.1 1.2-6.1v-.2c-1.5-.6-3.2-1.1-4.9-1.2h-1.1c-1 .1-2.1.5-2.9 1.3-.6.5-.9 1.2-1.1 2-.1.6-.2 1.3-.1 1.9.1 1.4.6 2.7 1.2 3.9 1.3 2.6 3.2 4.9 5.3 6.9 2.4 2.2 5.2 4.1 8.3 5.2 1.3.5 2.6.9 4 1 1.3.1 2.7-.1 3.8-.9.3-.2.6-.5.9-.8.4-.7.7-1.4.8-2.1.1-.8 0-1.6-.2-2.4-.3-1.1-.6-2.1-1.1-3z" />
    </Ic>
);

IC64TelstraTv.displayName = 'IC64TelstraTv';

export default IC64TelstraTv;
