import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import property from 'lodash/property';

import {srcsetOptionsType} from '@fsa-streamotion/custom-prop-types';

import SrcsetImg from '../../../../common/srcset-img';

const StyledSrcsetImage = styled(SrcsetImg)`
    width: ${property('size')};
    height: ${property('size')};
`;

const GA09PlrPic = ({
    srcsetOptions,
    size = '100%',
    ...htmlAttributes
}) => (
    <StyledSrcsetImage size={size} srcsetOptions={srcsetOptions} {...htmlAttributes} />
);

GA09PlrPic.displayName = 'GA09PlrPic';

GA09PlrPic.propTypes = {
    /** Srcset for image */
    srcsetOptions: srcsetOptionsType,
    /** Width & height for image */
    size: propTypes.string,
};

export default GA09PlrPic;
