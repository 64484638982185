import React from 'react';
import Ic from '..';

const IC160Worm = (args) => (
    <Ic {...args}>
        <path fillRule="evenodd" d="M28.6 72.3H11.4v-5.6h16L49.2 45c.5-.5 1.2-.8 2-.8h17l16.5-16.5 3.9 3.9L71.2 49c-.5.5-1.2.8-2 .8h-17L30.6 71.5c-.6.5-1.3.8-2 .8z" />
    </Ic>
);

IC160Worm.displayName = 'IC160Worm';

export default IC160Worm;
