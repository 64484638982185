import React from 'react';

import Ic from '..';

const IC72Min = (args) => (
    <Ic {...args}>
        <path fillRule="nonzero" d="M40.846 40.846H19.542a3.192 3.192 0 1 1 0-6.385h14.92V18.843a3.192 3.192 0 1 1 6.384 0v22.003zm18.504 0h21.304a3.192 3.192 0 1 0 0-6.385h-14.92V18.843a3.192 3.192 0 1 0-6.385 0v22.003zM40.846 57.65H19.542a3.192 3.192 0 1 0 0 6.385h14.92v15.619a3.192 3.192 0 1 0 6.384 0V57.65zm18.504 0h21.304a3.192 3.192 0 1 1 0 6.385h-14.92v15.619a3.192 3.192 0 1 1-6.385 0V57.65z" />
    </Ic>
);

IC72Min.displayName = 'IC72Min';

export default IC72Min;
