import bacon from 'baconjs';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import toString from 'lodash/toString';

/**
 * At the end of all widgets sending data to FISO, we need to ensure
 * it's in a string format.  As we've introduced error models in some parts
 * of the code base, we need to make sure we sent it up as 1 string.
 *
 * use as .flapMapError(mapErrorsForFiso) after a getData's returned stream.
 *
 * @param {*} error Error value from a flapMapError
 *
 * @returns {bacon.Error} Error as pure string
 */
export default function mapErrorsForFiso(error) {
    if (get(error, 'fullErrorMessage')) {
        // convert error object with non-empty fullErrorMessage to just its string.
        return new bacon.Error(error.fullErrorMessage);
    } else if (isObject(error)) {
        // catch any other unexpected error model
        return new bacon.Error(JSON.stringify(error));
    } else {
        // just make sure we're stringish for fiso.
        return new bacon.Error(toString(error));
    }
}
