import React from 'react';

import Ic from '..';

const IC116SiriRemote = (args) => (
    <Ic {...args}>
        <g transform="translate(35 7)">
            <path d="M0 0v86.506h30.702V0H0zm27.902 2.8v29.75H2.8V2.8h25.102zM2.8 83.706V35.35h25.102v48.356H2.8z" />
            <circle cx="9.786" cy="22.232" r="3.108" />
            <circle cx="9.786" cy="43.708" r="3.108" />
            <circle cx="9.786" cy="58.016" r="3.584" />
            <path d="M24.29 55.566a3.038 3.038 0 01-6.062 0V43.4a3.038 3.038 0 016.062 0v12.166z" />
            <circle cx="21.266" cy="22.232" r="3.108" />
        </g>
    </Ic>
);

IC116SiriRemote.displayName = 'IC116SiriRemote';

export default IC116SiriRemote;
