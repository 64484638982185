import React from 'react';
import flatten from 'lodash/flatten';
import zip from 'lodash/zip';

import addParamsToUrl from './add-params-to-url';

/**
 * Given a string template, and a replacements object instructing us how to make nodes to inject in it, returns a React Node that composes those things
 *
 * @param {string} template     - The template we're putting nodes into, e.g. 'Hello there, ${Name}, I'm ${GreeterName}'
 * @param {Object} replacements - The replacements object from COG, defining what the internal nodes will look like
 * @param {string} userId       - The user's ID, which may be appended to some URLs
 * @param {string} key          - The key for the root react node we're making
 * @returns {Node} A react Node
 *
 * @example
 * interpolateString('please email ${HELP_EMAIL}', {
 *     HELP_EMAIL: {
 *         type: 'email',
 *         display: 'privacyofficer@streamotion.com.au',
 *         value: 'privacyofficer@streamotion.com.au',
 *     }
 * })
 */
export default function interpolateString(template = '', replacements = {}, userId, key) {
    const matchRegex = /\$?{\w+}/g;
    const pointsOfReplacement = template.match(matchRegex) || [];

    if (!pointsOfReplacement.length) { // nothing to replace? just return the template as is
        return <React.Fragment key={key}>{template}</React.Fragment>;
    }

    const templateSplitByReplacements = template.split(matchRegex);

    const replacementNodes = pointsOfReplacement.map((replacementName, index) => {
        const replacementKey = replacementName.substr(
            replacementName.includes('$') ? 2 : 1,
            replacementName.includes('$') ? replacementName.length - 3 : replacementName.length - 2,
        ); // ${Foo} --> Foo
        const replacementRecipe = replacements[replacementKey];

        if (!replacementRecipe) {
            return replacementName; // Do we even have instructions on how to do this replacement? If so, make a node from it, otherwise leave it
        } else if (typeof replacementRecipe === 'string') { // its a simple recipe!
            return replacementToNode({display: replacementRecipe}, `${index}_${replacementKey}`);
        }

        return replacementToNode(replacementRecipe, `${index}_${replacementKey}`, userId);
    });

    return (
        <React.Fragment key={key}>
            {/* A fancy way of folding the nodes made by `replacements` back into the string */}
            {flatten(zip(
                templateSplitByReplacements,
                replacementNodes
            ))}
        </React.Fragment>
    );
}

/**
 * Given an object describing how to do replacements, construct a node
 *
 * @param {Object} replacement             - The replacement object
 * @param {string} replacement.type        - What kind of node is it, e.g. 'link', 'email'
 * @param {string} replacement.display     - What should render on the user's screen
 * @param {string} replacement.value       - What's the value of the node (e.g. the href for the link)
 * @param {boolean} replacement.withUserId - Should the ?userId param be appended to the url
 * @param {string} key                     - A unique key for this react node
 * @param {string} userId                  - The user's ID
 * @returns {Node} a React Node, e.g. a link or email via <a>
 */
export function replacementToNode({type, display, value, withUserId}, key, userId) {
    switch (type) {
        case 'link':
            return (
                <a
                    href={(withUserId && userId) ? addParamsToUrl(value, {userId}) : value}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={key}
                >
                    {display}
                </a>
            );
        case 'email':
            return (
                <a
                    href={`mailto:${value}`}
                    key={key}
                >
                    {display}
                </a>
            );
        case 'bold':
            return <strong key={key}>{display}</strong>;
        default:
            return display;
    }
}

export function getMultiLineInterpolator(replacements, userId) {
    return function multiLineInterpolator(copy, key) {
        if (!copy) {
            return copy;
        }

        if (!Array.isArray(copy)) {
            if (typeof copy === 'object') {
                return interpolateString(
                    copy.template,
                    {...copy.replacements, ...replacements},
                    userId,
                    key,
                );
            }

            return interpolateString(copy, replacements, userId, key);
        }

        return (
            <React.Fragment key={key}>
                {copy.map((copyLine, index) => (
                    <p key={index}>
                        {typeof copyLine === 'object'
                            ? interpolateString(copyLine.template, {...copyLine.replacements, ...replacements}, userId, `${key}__${index}`)
                            : interpolateString(copyLine, replacements, userId, `${key}__${index}`)}
                    </p>
                ))}
            </React.Fragment>
        );
    };
}
