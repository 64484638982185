import React from 'react';
import propTypes from 'prop-types';
import {onyx} from '../../../../common/palette';
import Ic from '..';

/* eslint-disable quote-props */
const RATE_PATH_MAP = {
    '1': <path fill={onyx} d="M56.4 80.62h-4.368V64.394h-3.5v-3.108H56.4zm21.224 0h-5.096L69.42 76l-3.136 4.62h-5.096l5.6-7.196-4.956-6.622H66.9l2.576 4.2 2.548-4.2h5.068l-5.012 6.622z" />,
    '1.5': <path fill={onyx} d="M41.392 80.62h-4.368V64.394h-3.5v-3.108h7.868zm9.66.308a2.42 2.42 0 01-2.25-3.679 2.422 2.422 0 012.25-1.151 2.42 2.42 0 012.25 3.679 2.422 2.422 0 01-2.25 1.151zm14.616 0a8.287 8.287 0 01-7.112-3.164l2.044-2.66a6.158 6.158 0 004.76 2.296A3.363 3.363 0 0069 74.138a3.305 3.305 0 00-3.64-3.248 6.535 6.535 0 00-3.528 1.008l-1.96-1.176 1.484-9.436h11.312V64.8h-8.036l-.56 3.472a6.243 6.243 0 012.576-.42 6.365 6.365 0 014.735 1.712 6.366 6.366 0 012.013 4.616c0 4.48-3.892 6.748-7.728 6.748zm26.964-.308h-5.096L84.4 76l-3.108 4.62h-5.096l5.6-7.196-4.956-6.622h5.068l2.576 4.2 2.548-4.2H92.1l-4.956 6.622z" />,
    '2': <path fill={onyx} d="M60.852 80.62H46.684l-.532-2.8 6.552-6.02c1.82-1.652 2.912-2.912 2.912-4.564a2.8 2.8 0 00-3.108-2.716 6.287 6.287 0 00-4.676 2.604l-1.904-2.968a8.302 8.302 0 017-3.192c4.368 0 7 2.324 7 5.712s-2.52 5.404-4.06 6.776l-4.004 3.612h8.96l.028 3.556zm18.984 0H74.74L71.632 76l-3.136 4.62H63.4l5.6-7.196-4.956-6.622H69l2.576 4.2 2.548-4.2h5.068l-4.956 6.622z" />,
    '3': <path fill={onyx} d="M52.928 80.928a8.722 8.722 0 01-7-3.22L48 75.006a7.154 7.154 0 004.676 2.394c1.904 0 3.388-.98 3.388-2.8 0-1.82-1.4-2.604-3.08-2.604h-1.708V69h1.708c1.512 0 2.576-.756 2.576-2.268 0-1.512-1.26-2.268-2.8-2.268a7.335 7.335 0 00-4.62 1.876l-1.68-2.8a9.141 9.141 0 016.664-2.408c3.5 0 6.72 1.904 6.72 5.152a4.2 4.2 0 01-2.8 4.2v.084a4.565 4.565 0 013.388 4.48c.028 3.752-3.528 5.88-7.504 5.88zm26.908-.308H74.74L71.632 76l-3.136 4.62H63.4l5.6-7.196-4.956-6.622H69l2.576 4.2 2.548-4.2h5.068l-4.956 6.622z" />,
    'slo': <path fill={onyx} d="M48.336 79.612a8.218 8.218 0 01-5.236 1.596 10.474 10.474 0 01-6.86-2.912l2.24-3.304a7.962 7.962 0 005.04 2.408c1.4 0 2.576-.7 2.576-1.988s-1.4-1.82-2.268-2.128c-.868-.308-2.296-.812-2.94-1.092a7.553 7.553 0 01-2.296-1.4 4.776 4.776 0 01-1.512-3.724 5.446 5.446 0 012.016-4.2 7.896 7.896 0 015.096-1.54 9.52 9.52 0 016.132 2.38l-1.932 3.164a6.66 6.66 0 00-4.312-1.96c-1.4 0-2.52.56-2.52 1.736s1.092 1.624 1.96 1.96c.868.336 2.268.84 2.884 1.092.993.336 1.907.87 2.688 1.568a5.057 5.057 0 011.512 3.724 5.852 5.852 0 01-2.268 4.62zm17.556 1.26h-11.2v-19.32H59.2V77.12h6.692zm11.872.336c-5.74 0-10.248-3.724-10.248-9.968s4.508-9.996 10.248-9.996c5.74 0 10.248 3.752 10.248 9.996 0 6.244-4.508 9.968-10.248 9.968zm0-16.1a5.603 5.603 0 00-5.6 6.02 5.656 5.656 0 1011.312 0 5.6 5.6 0 00-5.712-6.02z" />,
};
/* eslint-enable quote-props */

const IC32PbRate = ({rate = 'slo', ...args}) => (
    <Ic {...args}>
        <path d="M21.064 71.408a32.892 32.892 0 01-2.8-2.394l-7.252 4.62a39.675 39.675 0 0010.066 7.756l-.014-9.982zm8.596-53.9a30.784 30.784 0 019.562-1.708l1.862-8.4a39.208 39.208 0 00-16.044 2.8l4.62 7.308zM11.922 33.216a30.566 30.566 0 015.6-7.966l-4.634-7.252a39.202 39.202 0 00-9.352 13.356l8.386 1.862zm41.384-14.294a30.728 30.728 0 017.952 5.6l7.252-4.62a39.092 39.092 0 00-13.342-9.352l-1.862 8.372zm-42.798 37.94A30.993 30.993 0 018.8 47.286L.4 45.424c-.2 5.49.754 10.96 2.8 16.058l7.308-4.62zm88.55 35.266H26.664v-38.71l72.394-6.664z" />
        {RATE_PATH_MAP[rate]}
        <path d="M78.926 42.988a39.291 39.291 0 00-2.66-10.948L69 36.66a31.018 31.018 0 011.568 7.14l8.358-.812z" />
    </Ic>
);

IC32PbRate.propTypes = {
    /** Playback rate */
    rate: propTypes.oneOf(['1', '1.5', '2', '3', 'slo']),
};

IC32PbRate.displayName = 'IC32PbRate';

export default IC32PbRate;
