import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import {rgba} from 'polished';

import {smoke, onyx} from '../../../../common/palette';

const Container = styled.span`
    display: flex;
    align-items: center;
    text-shadow: 0 1px 1px ${rgba(onyx, 0.3)};
    color: ${smoke};
    font: var(--nucleus-body-copy-6-bold);

    :not(:last-of-type)::after {
        opacity: 0.5;
        margin: 0 7px;
        border-radius: 50%;
        background-color: ${smoke};
        width: 2px;
        height: 2px;
        content: '';
    }
`;

const InfoLineItem = ({
    icon,
    description,
}) => (
    <Container>
        {!!icon && icon}
        {!!description && description}
    </Container>
);

InfoLineItem.propTypes = {
    icon: propTypes.node,
    description: propTypes.string,
};

InfoLineItem.displayName = 'InfoLineItem';

export default InfoLineItem;
