import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {mediaQuery, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';

import {SCREEN_2560_DESKTOP} from '../../../../common/screen-sizes';
import {transition} from '../../../../common/animations';
import {silverLining} from '../../../../common/deprecated-mui-palette';

import IA04InputSea from '../../../atoms/ia/04-input-sea';

const OuterContainer = styled.section`
    position: relative;
    margin: 0 auto;
    max-width: 1061px;

    ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
        max-width: 1021px;
    `}
`;

const TextContainer = styled.div`
    transform-origin: top;
    transition: ${transition('transform', 'opacity')};
    margin-bottom: 41px;

    ${stylesWhenNot('isVisible')`
        transform: scaleY(0);
        opacity: 0;
        height: 0;
        overflow: hidden;
    `}

    ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
        margin-bottom: 63px;
    `}
`;

const StyledHeading = styled.h1`
    padding-right: 14px;
    padding-left: 14px;
    text-align: center;
    color: ${silverLining};
    font: var(--ionic-header-2-bold);
`;

const StyledDescription = styled.p`
    padding-right: 14px;
    padding-left: 14px;
    text-align: center;
    color: ${silverLining};
    font: var(--ionic-body-copy-1);
`;

const InputContainer = styled.div`
    transition: ${transition('opacity')};
    margin: 0 auto;

    ${stylesWhenNot('isVisible')`
        opacity: 0;
        height: 0;
        overflow: hidden;
    `}
`;

const OR47SrcTry = ({
    desc,
    isVisible = true,
    label,
    onChange,
    title,
}) => (
    <OuterContainer className="OR47SrcTry">
        {!!(title || desc) && (
            <TextContainer isVisible={isVisible}>
                {!!title && (
                    <StyledHeading>
                        {title}
                    </StyledHeading>
                )}
                {!!desc && (
                    <StyledDescription>
                        {desc}
                    </StyledDescription>
                )}
            </TextContainer>
        )}
        <InputContainer isVisible={isVisible}>
            <IA04InputSea
                label={label}
                onChange={onChange}
            />
        </InputContainer>
    </OuterContainer>
);

OR47SrcTry.propTypes = {
    desc: propTypes.string,
    isVisible: propTypes.bool,
    label: propTypes.string,
    onChange: propTypes.func,
    title: propTypes.string,
};

OR47SrcTry.displayName = 'OR47SrcTry';

export default OR47SrcTry;
