import React from 'react';
import {rgba} from 'polished';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {mediaQuery, stylesIfElse} from '@fsa-streamotion/styled-component-helpers';

import {white, black} from '../../../../common/palette';
import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import backdropFilter from '../../../../common/backdrop-filter';

const DESCRIPTION_BACKGROUND_Z_INDEX = -1;

const StyledDescription = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    padding: 0.3em 0.3em 0.2em;
    overflow: hidden;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding: 0.5em 0.5em 0.3em;
    `}

    /* This is the background colour gradient component */
    &::before {
        ${stylesIfElse('hasDescription')`
            display: block;
            position: absolute;
            top: 0;
            transform: scale(1.1); /* A hack to make sure that the background covers the description box */
            z-index: ${DESCRIPTION_BACKGROUND_Z_INDEX};
            background: linear-gradient(to bottom, ${rgba(black, 0.9)}, ${rgba(black, 0.5)});
            width: 100%;
            height: 100%;
            content: '';
        ``
            display: none;
        `}

        ${backdropFilter}
    }
`;

/*
    This is done in a wrapper to make it easier to define the
    maxiumum line height by calculation, while at the same time
    defining the padding using the container
*/
const StyledTextBlock = styled.div`
    box-sizing: border-box;
    width: 100%;
    max-height: calc(var(--infinity-cam-tiles-line-height, var(--infinity-body-copy-4__line-height)) * 2);
    overflow: hidden;
    text-align: left;
    color: ${white};
    font: var(--infinity-body-copy-4-light);
    font-size: var(--infinity-cam-tiles-font-size, var(--infinity-body-copy-4__font-size));
    line-height: var(--infinity-cam-tiles-line-height, var(--infinity-body-copy-4__line-height)); /* stylelint-disable-line order/properties-order */
`;

const Description = ({
    description,
    children,
}) => (
    <StyledDescription hasDescription={!!description}>
        {!!description && (
            <StyledTextBlock>{description}</StyledTextBlock>
        )}
        {children}
    </StyledDescription>
);

Description.propTypes = {
    /** Description text, e.g. title of the asset */
    description: propTypes.node,
    /** Additional children to add after the description block  */
    children: propTypes.node,
};

Description.displayName = 'Description';

export default Description;
