import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {BrandedIC103Loading} from '../../utils/branded-components';
import brandPropType from '../../common/brand-proptype';

const CenteredContent = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 70px;
    text-align: center;
`;

const BrandedLoadingIndicator = ({
    brand,
    className,
    height = '210px',
    ...htmlAttributes
}) => brand === 'kayo' ? (
    <BrandedIC103Loading
        {...htmlAttributes}
        size="70px"
        title="Loading"
        brand={brand}
        className={classnames('default-loading-indicator', className)}
    />
) : (
    <CenteredContent
        {...htmlAttributes}
        aria-busy="true"
        role="alert"
        style={{height}}
        className={classnames('default-loading-indicator', className)}
    >
        <BrandedIC103Loading size="70px" brand={brand} />
    </CenteredContent>
);

BrandedLoadingIndicator.displayName = 'BrandedLoadingIndicator';

BrandedLoadingIndicator.propTypes = {
    /**  brand (flash|binge|kayo|lifestyle) */
    brand: brandPropType,

    /** Passed down classnames */
    className: propTypes.string,

    /** Height of the span */
    height: propTypes.string,
};

export default BrandedLoadingIndicator;
