import React from 'react';
import {rgba} from 'polished';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import classNames from 'classnames';
import {cobalt, ink} from '../../../../../common/palette';
import {SCREEN_1920_DESKTOP, SCREEN_768_TABLET} from '../../../../../common/screen-sizes';
import {getShortTermTypeText} from '../../../../../common/price-term';
import GM16SubTotal from '../../../../molecules/gm/16-sub-total';
import {IC157Offer} from '../../../../atoms/ic';

import disneyHack from './disney-hack';

const SubscriptionsSubSection = styled.div`
    margin-bottom: 10px;
`;

const SubscriptionsSubTitle = styled.div`
    opacity: 0.7;
    margin-bottom: 20px;
    font: var(--quicksilver-body-copy-3);
`;

const CopyMedium = styled.span`
    display: inline-block;
    font: var(--quicksilver-body-copy-4-medium);
`;

const CopyLight = styled.span`
    display: inline-block;
    font: var(--quicksilver-body-copy-4-light);
`;

const SubscriptionsTotalHeading = styled.span`
    display: inline-block;
    flex: 1;
    margin-top: 3px;
    font: var(--quicksilver-body-copy-4-light);
`;

const SubscriptionsTotalValueWrapper = styled.span`
    margin-top: -2px;
    text-align: center;
    font: var(--quicksilver-body-copy-5-light);

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        margin-top: 0;
        height: 35px;
    `}
`;

const DetailedContent = styled.section`
    width: 100%;
`;

const FullLineWrapper = styled.div`
    display: flex;
`;

const SubscriptionsContentWrapper = styled.ul`
    margin: 0;
    padding: 0;
    width: 100%;
    font: var(--quicksilver-body-copy-4-light);
`;

const HorizontalDivider = styled.div`
    margin: 21px -24px 21px -14px;
    border-bottom: 1px solid ${rgba(ink, 0.2)};
`;

const OfferTitle = styled.div`
    color: ${ink};
    font: var(--quicksilver-body-copy-3-medium);
`;

const OfferDetail = styled.div`
    display: inline-block;
    margin-bottom: 20px;
    border-radius: 4px;
    padding: 7px 10px 7px 0;
    max-width: 225px;
    color: ${rgba(cobalt, 0.8)};
    font: var(--quicksilver-body-copy-4);

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        max-width: 429px;
    `}
`;

const OfferTotalLine = styled.div`
    display: flex;
    margin-top: 11px;
    color: ${rgba(ink, 0.5)};
    font: var(--quicksilver-body-copy-4);
`;

const LineItem = styled.li`
    display: flex;
    flex-direction: row;
    padding-top: 0;
    padding-bottom: 11px;
    color: ${rgba(ink, 0.7)};
`;

const LineItemName = styled.div`
    display: flex;
    flex: 1;
    margin-right: 20px;
`;

const StyledIC157Offer = styled(IC157Offer)`
    display: inline-block;
    margin-right: 5px;
    width: auto;
`;

const formattedString = (string) => string?.toLowerCase().replace(/ /g, '');

const normalizedThirdPartyPaymentMethod = {
    tbill: 'Telstra',
    telstra: 'Telstra',
    optus: 'Optus',
    itunes: 'iTunes',
};

const billingPaymentMethods = ['creditcard', 'cash'];

function getSubscriptionTitle({productName, tierName, paymentMethod}) {
    const formattedPaymentMethod = formattedString(paymentMethod);

    return (
        <React.Fragment>
            <CopyMedium>{productName}</CopyMedium>
            &nbsp;|&nbsp;
            <CopyLight>
                {!paymentMethod || billingPaymentMethods.includes(formattedPaymentMethod) // new sub (new sub does NOT have paymentMethod) or existing foxtel billed
                    ? tierName
                    : normalizedThirdPartyPaymentMethod[formattedPaymentMethod]}
            </CopyLight>
        </React.Fragment>
    );
}

function getSubscriptionPrice({amount, term}) {
    return (
        <React.Fragment>
            <CopyLight>$</CopyLight>
            <CopyLight>{amount}{term}</CopyLight>
        </React.Fragment>
    );
}

/**
 *
 * Helper function to generate subscriptions content for OR140
 *
 * @param  {Object} options                  see below
 * @param  {string} options.title            Section title to display
 * @param  {array}  options.products         List of products
 * @param  {Object} options.subscriptions    Products and promotions to list
 * @param  {Boolean} options.shouldShowPrice Whether we should display the price for the line items
 * @returns {React.ReactElement}             React node to be rendered
 */
function getSubscriptionsSubSection({title, shouldShowPrice = true, subscriptions}) {
    const {products, promotions} = subscriptions || {};

    return (
        !!(products?.length || promotions?.length) && (
            <SubscriptionsSubSection>
                <SubscriptionsSubTitle>{title}</SubscriptionsSubTitle>

                {/* PROMOTIONS/ OFFERS */}
                {promotions
                    ?.filter(({products} = {}) => products?.length)
                    ?.map(({price, products, textResources} = {}, index) => (
                        <React.Fragment key={index}>
                            <OfferTitle>Subscription Offer</OfferTitle>
                            <OfferDetail>{disneyHack(textResources?.web?.promotionName) || 'Promotion'}</OfferDetail>
                            <SubscriptionsContentWrapper>
                                {products.map(({paymentMethod, textResources, tier} = {}, index) => (
                                    <LineItem key={index}>
                                        <LineItemName><StyledIC157Offer size="18px" />
                                            {getSubscriptionTitle({
                                                productName: disneyHack(textResources?.web?.productName),
                                                tierName: tier?.name,
                                                paymentMethod,
                                            })}
                                        </LineItemName>
                                    </LineItem>
                                ))}
                            </SubscriptionsContentWrapper>
                            {!!shouldShowPrice && (
                                <OfferTotalLine>
                                    <LineItemName>Total</LineItemName>
                                    {getSubscriptionPrice({
                                        amount: price?.discountedAmount ?? price?.regularAmount,
                                        term: getShortTermTypeText(price?.termType),
                                    })}
                                </OfferTotalLine>
                            )}
                            <HorizontalDivider />
                        </React.Fragment>
                    ))}

                {/* PRODUCTS */}
                <SubscriptionsContentWrapper>
                    {products?.map(({paymentMethod, price, textResources, tier}, index) => {
                        const amount = price.discountedAmount ?? price.regularAmount;

                        return (
                            <LineItem key={index}>
                                <LineItemName>
                                    {getSubscriptionTitle({
                                        productName: disneyHack(textResources?.web?.productName),
                                        tierName: tier?.name,
                                        paymentMethod,
                                    })}
                                </LineItemName>
                                {
                                    !!shouldShowPrice
                                && getSubscriptionPrice({amount, term: getShortTermTypeText(price?.termType?.toLowerCase())})
                                }
                            </LineItem>
                        );
                    })}
                </SubscriptionsContentWrapper>
            </SubscriptionsSubSection>
        )
    );
}

// Helper component to generate subscriptions content for OR140

const SubscriptionsContent = ({
    existingSubscriptions = {},
    removedSubscriptions = {},
    downgradedSubscriptions = {},
    upgradedSubscriptions = {},
    newSubscriptions = {},
    billingPrice,
    originalBillingPrice,
    billingPeriod,
    className,
    ...htmlAttributes
}) => (
    <DetailedContent
        {...htmlAttributes}
        className={classNames('SubscriptionsContent', className)}
    >
        {getSubscriptionsSubSection({
            title: 'Your Existing Subscriptions',
            subscriptions: existingSubscriptions,
            shouldShowPrice: false,
        })}
        {getSubscriptionsSubSection({
            title: 'Removed Subscriptions',
            subscriptions: removedSubscriptions,
            shouldShowPrice: false,
        })}
        {getSubscriptionsSubSection({
            title: 'Downgraded Subscriptions',
            subscriptions: downgradedSubscriptions,
        })}
        {getSubscriptionsSubSection({
            title: 'Upgraded Subscriptions',
            subscriptions: upgradedSubscriptions,
        })}
        {getSubscriptionsSubSection({
            title: 'Your New Subscriptions',
            subscriptions: newSubscriptions,
        })}

        {Number.isFinite(billingPrice) && (
            <FullLineWrapper>
                <SubscriptionsTotalHeading>Total</SubscriptionsTotalHeading>
                <SubscriptionsTotalValueWrapper>
                    <GM16SubTotal billingPrice={billingPrice} billingPeriod={billingPeriod} />
                    {!!originalBillingPrice && originalBillingPrice !== billingPrice && (
                        <React.Fragment>was <del>${originalBillingPrice}</del></React.Fragment>
                    )}
                </SubscriptionsTotalValueWrapper>
            </FullLineWrapper>
        )}
    </DetailedContent>
);

const pricePropType = propTypes.shape({
    discountedAmount: propTypes.number,
    regularAmount: propTypes.number,
    termType: propTypes.string,
});

const productsPropType = propTypes.arrayOf(propTypes.shape({
    name: propTypes.string,
    price: pricePropType,
    tier: propTypes.shape({
        tierDescription: propTypes.string,
    }),
}));

const promotionsPropType = propTypes.arrayOf(propTypes.shape({
    name: propTypes.string,
    price: pricePropType,
    products: productsPropType,
}));

const subscriptionsPropTypes = propTypes.shape({
    products: productsPropType,
    promotions: promotionsPropType,
});

SubscriptionsContent.propTypes = {
    /** List of existing products */
    existingSubscriptions: subscriptionsPropTypes,
    /** List of removed products */
    removedSubscriptions: subscriptionsPropTypes,
    /** List of downgraded products */
    downgradedSubscriptions: subscriptionsPropTypes,
    /** List of upgraded products */
    upgradedSubscriptions: subscriptionsPropTypes,
    /** List of new products */
    newSubscriptions: subscriptionsPropTypes,
    /** total billing price */
    billingPrice: propTypes.number,
    /** original billing price (if discounts have been applied) */
    originalBillingPrice: propTypes.number,
    /** billing period */
    billingPeriod: propTypes.string,
    /** Additional class name(s) */
    className: classNameType,
};

SubscriptionsContent.displayName = 'SubscriptionsContent';
export default SubscriptionsContent;
