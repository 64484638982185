import React from 'react';
import Ic from '..';

const IC265ClosedCaptionsOn = (args) => (
    <Ic {...args}>
        <path fillRule="evenodd" d="M93 21v58H7V21h86zM35.566 36C27.66 36 20 41.714 20 51.179 20 60.583 27.418 66 35.444 66c7.844 0 10.884-3.333 11.492-3.869l-2.189-5.595-.017.018c-.242.251-3.01 3.017-7.827 3.017-5.046 0-8.755-3.452-8.755-8.75 0-5.297 3.709-8.452 8.33-8.452 4.56 0 7.175 2.202 8.087 3.036l2.128-5.715c-.79-.773-3.71-3.69-11.127-3.69zm29.064 0c-7.905 0-15.566 5.714-15.566 15.179C49.064 60.583 56.482 66 64.508 66c7.844 0 10.884-3.333 11.492-3.869l-2.189-5.595-.017.018c-.242.251-3.01 3.017-7.827 3.017-5.046 0-8.755-3.452-8.755-8.75 0-5.297 3.709-8.452 8.33-8.452 4.56 0 7.175 2.202 8.087 3.036l2.128-5.715c-.79-.773-3.71-3.69-11.127-3.69z" />
    </Ic>
);

IC265ClosedCaptionsOn.displayName = 'IC265ClosedCaptionsOn';

export default IC265ClosedCaptionsOn;
