import React, {createRef} from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import classnames from 'classnames';
import {A} from 'normalized-styled-components';
import {rgba} from 'polished';
import noop from 'lodash/noop';
import property from 'lodash/property';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {ironStone, midnight, slate, white} from '../../../../common/palette';
import {getPassFocusToRef} from '../../../util/navigation/helpers';
import asNavigationColumn from '../../../util/navigation/hocs/as-column';

const NonInteractiveContainer = styled.div`
    border-radius: 4px;
    background: linear-gradient(to bottom, ${rgba(ironStone, 0.7)}, ${midnight});
    padding: 1px;
    color: ${white};
    font: var(--mui-body-copy-6);
`;

const NavContainer = asNavigationColumn(NonInteractiveContainer);

const StatRow = styled.dl`
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-gap: 14px;
    margin: 6px 7px;
    line-height: 1.45;
`;

const StatTitle = styled.dt`
    order: 1;
    text-align: center;
    text-transform: uppercase;
    color: ${slate};
`;

const baseDdStyles = css`
    margin-left: 0;
    overflow-x: hidden;
    text-overflow: ellipsis;
`;

const StatA = styled.dd`
    ${baseDdStyles}

    order: 0;
`;

const StatB = styled.dd`
    ${baseDdStyles}

    order: 2;
    text-align: right;
`;

const StatAbbr = styled.abbr`
    text-decoration: none;
`;

const StatLink = styled(A)`
    display: inline-block;
    border-radius: 3px;
    box-shadow: 0 0 0 1px ${ironStone};
    padding: 0 7px;
    text-decoration: none;
    color: inherit;

    &:focus,
    &:hover {
        outline-width: 0;
        box-shadow: 0 0 0 1px ${white};
        color: ${white};
    }
`;

const SBM14FixtureStats = React.forwardRef(({
    className,
    statsData = [],
    teamAName,
    teamBName,
    onClickStatLink = noop,
    onFocus,
    ...htmlAttributes
}, ref) => {
    const firstTabbableIndex = statsData.findIndex(property('url'));
    const hasManyTabbables = statsData.filter(property('url')).length > 1;

    const Container = hasManyTabbables ? NavContainer : NonInteractiveContainer;

    return (
        <Container
            {...htmlAttributes}
            className={classnames('SBM14FixtureStats', className)}
            {...(firstTabbableIndex !== -1 && ({
                onFocus: getPassFocusToRef(ref, onFocus),
            }))}
        >
            {statsData.map(({name, url, left, right}, index) => {
                const statAA11y = `${teamAName}: ${left}`;
                const statBA11y = `${teamBName}: ${right}`;
                const linkRef = index === firstTabbableIndex ? ref : createRef();

                return (
                    <StatRow
                        key={name}
                        {...(hasManyTabbables && !!url && ({
                            tabIndex: 0,
                            onFocus: getPassFocusToRef(linkRef),
                        }))}
                    >
                        <StatTitle>
                            {url
                                ? (
                                    <StatLink
                                        ref={linkRef}
                                        href={url}
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        onClick={(event) => {
                                            onClickStatLink({event, name});
                                        }}
                                    >
                                        {name}
                                    </StatLink>
                                )
                                : name}
                        </StatTitle>
                        <StatA>
                            <StatAbbr
                                aria-label={statAA11y}
                                title={statAA11y}
                            >
                                {left}
                            </StatAbbr>
                        </StatA>
                        <StatB>
                            <StatAbbr
                                aria-label={statBA11y}
                                title={statBA11y}
                            >
                                {right}
                            </StatAbbr>
                        </StatB>
                    </StatRow>
                );
            })}
        </Container>
    );
});

SBM14FixtureStats.displayName = 'SBM14FixtureStats';

SBM14FixtureStats.propTypes = {
    /** Optional extra class name(s) for the component */
    className: classNameType,
    /** Stats data */
    statsData: propTypes.arrayOf(propTypes.shape({
        name: propTypes.string,
        url: propTypes.string,
        left: propTypes.string,
        right: propTypes.string,
    })),
    /** Team A name */
    teamAName: propTypes.string,
    /** Team B name */
    teamBName: propTypes.string,
    /** Focus handler */
    onFocus: propTypes.func,
    /** Click handler */
    onClickStatLink: propTypes.func,
};

SBM14FixtureStats.displayName = 'SBM14FixtureStats';

export default SBM14FixtureStats;
