import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import {format, parseISO} from 'date-fns';
import {Section} from 'normalized-styled-components';
import {rgba} from 'polished';
import get from 'lodash/get';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {blanc, onyx} from '../../../../common/palette';
import IC103Loading from '../../../atoms/ic/103-loading';
import BA01BtnPr from '../../../atoms/ba/01-btn-pr';
import OR130AccountBlk from '../../../organisms/or/130-account-blk';
import TM24AlertTile from '../24-alert-tile';

const Container = styled(Section)`
    box-sizing: border-box;
    border-radius: 4px;
    padding: 21px;
    width: 100%;
    text-shadow: 0 1px 1px ${rgba(onyx, 0.3)};
    font: var(--nucleus-body-copy-3);
`;
const StyledHeading = styled.h3`
    margin: 7px 0;
    text-align: center;
    color: ${blanc};
    font: var(--nucleus-body-copy-3);
`;

const ButtonContainer = styled.div`
    margin: 20px;
    text-align: center;
`;

const dateFormat = 'yyyy/MM/dd';

const TM25UpdateTile = ({
    subscription: {
        cancelDate,
        adjustmentOfferAndPackage,
        currentOfferAndPackage,
    },
    isLoading,
    error,
    onButtonClick,
    className,
    ...htmlAttributes
}) => {
    let displaySubtext;
    let title;
    let cta;

    const scenario = cancelDate ? 'Cancel' : get(adjustmentOfferAndPackage, 'adjustmentType');
    const currentPlanName = get(currentOfferAndPackage, 'package.displayName');

    if (!currentPlanName || !scenario) {
        return null;
    }

    if (scenario === 'Cancel') {
        if (!cancelDate) {
            return null;
        }

        displaySubtext = `${currentPlanName} available until: ${format(parseISO(cancelDate), dateFormat)}`;
        title = 'Your cancelled package';
        cta = 'Reactivate Subscription';
    } else if (scenario === 'Downgrade') {
        if (!adjustmentOfferAndPackage || !adjustmentOfferAndPackage.adjustmentEffectiveDate) {
            return null;
        }

        const {adjustmentEffectiveDate, adjustmentRequestedOnDate, adjustmentDate} = adjustmentOfferAndPackage;
        const requestDate = adjustmentDate || adjustmentRequestedOnDate;
        const newPlanName = get(adjustmentOfferAndPackage, 'package.displayName');

        title = 'Your updated package';
        displaySubtext = `${currentPlanName} available until: ${format(parseISO(adjustmentEffectiveDate), dateFormat)}`;
        cta = 'Cancel Downgrade';

        if (requestDate && newPlanName) {
            displaySubtext = `Downgraded to ${newPlanName} on: ${format(parseISO(requestDate), dateFormat)}\n${displaySubtext}`;
        }
    }

    return (
        <Container
            {...htmlAttributes}
            className={classnames('TM25UpdateTile', className)}
        >
            <StyledHeading>{title}</StyledHeading>
            <OR130AccountBlk
                style={{whiteSpace: 'pre-line'}}
                isHorizontallyCentered={true}
                displaySubtext={displaySubtext}
            />
            <ButtonContainer>
                {isLoading ? (
                    <IC103Loading size="47px" />
                ) : (
                    <BA01BtnPr
                        onClick={onButtonClick}
                    >
                        {cta}
                    </BA01BtnPr>
                )}
            </ButtonContainer>
            {!!error && (
                <TM24AlertTile>
                    {error}
                </TM24AlertTile>
            )}
        </Container>
    );
};

TM25UpdateTile.propTypes = {
    /** Billing adjustmentOfferAndPackage */
    subscription: propTypes.shape({
        cancelDate: propTypes.string,
        adjustmentOfferAndPackage: propTypes.shape({
            adjustmentType: propTypes.oneOf(['Cancel', 'Downgrade']).isRequired,
            adjustmentEffectiveDate: propTypes.string.isRequired,
            adjustmentDate: propTypes.string,
            adjustmentRequestedOnDate: propTypes.string,
            package: propTypes.shape({
                displayName: propTypes.string.isRequired,
            }),
        }),
        currentOfferAndPackage: propTypes.shape({
            package: propTypes.shape({
                displayName: propTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
    /** Is cancelling adjustment */
    isLoading: propTypes.bool,
    /** Cancel adjustment error */
    error: propTypes.string,
    /** Cancel adjustment button click handler */
    onButtonClick: propTypes.func,
    /** Additional class(es) */
    className: classNameType,
};

TM25UpdateTile.displayName = 'TM25UpdateTile';

export default TM25UpdateTile;
