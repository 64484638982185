import React from 'react';
import classnames from 'classnames';
import {rgba} from 'polished';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import property from 'lodash/property';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Section} from 'normalized-styled-components';

import {mediaQuery, stylesWhen, stylesWhenNot, stylesIfElse} from '@fsa-streamotion/styled-component-helpers';
import {colorType, classNameType, srcsetOptionsType} from '@fsa-streamotion/custom-prop-types';

import asNavigationRow from '../../../util/navigation/hocs/as-row';
import {CONTENT_EDGE_SPACING_PERCENT} from '../../../../common/style-constants';
import {transition} from '../../../../common/animations';
import EMPTY_IMAGE_SRC from '../../../../common/empty-image-src';
import {smoke, blanc, cloudy, onyx, lifestyle} from '../../../../common/palette';
import {SCREEN_768_TABLET, SCREEN_1024_DESKTOP, SCREEN_1680_DESKTOP, SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import SrcsetImg from '../../../../common/srcset-img';
import BA01BtnPr from '../../../atoms/ba/01-btn-pr';
import GA01ProBar from '../../../atoms/ga/01-pro-bar';
import ActionWrapper from './action-wrapper';
import BroadcastStatus from './broadcast-status';

const HEADER_TEXT_FONTS = {
    small: 'var(--nucleus-poster-tile-small)',
    medium: 'var(--nucleus-poster-tile-medium)',
    large: 'var(--nucleus-poster-tile-large)',
    default: 'var(--nucleus-poster-tile-default)',
};

const StyledSection = styled(Section)`
    position: relative;
    transition: ${transition('opacity')};
    width: 100%;
    max-width: 290px;
    text-shadow: 0 1px 1px ${rgba(onyx, 0.3)};
    color: ${blanc};

    ${stylesWhenNot('isVisible')`
        opacity: 0;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        max-width: 330px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1680_DESKTOP})`
        max-width: 490px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        max-width: 600px;
    `}
`;

const AnchorPointPositioner = styled.div`
    position: absolute;
    top: 15px;
    transform: translateY(-100%);
    width: 100%;

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        top: 18px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1680_DESKTOP})`
        top: 29px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        top: 36px;
    `}
`;

const LeftBorderedContent = styled.div`
    ${stylesWhenNot('isPreview')`
        position: relative;

        &::before {
            position: absolute;
            left: calc(1px - ${CONTENT_EDGE_SPACING_PERCENT}vw);
            background-color: ${property('color')};
            width: 4px;
            height: calc(100% - 3px);
            content: '';

            ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
                width: 10px;
            `}
        }
    `}
`;

const HeaderSmall = styled.h3`
    margin: 0 0 15px;
    overflow: hidden;
    color: ${smoke};
    font: ${HEADER_TEXT_FONTS.default};
`;

const ImageTitle = styled(SrcsetImg)`
    max-height: 93px;

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        max-height: 108px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1680_DESKTOP})`
        max-height: 137px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        max-height: 200px;
    `}
`;

const Header = styled.h3`
    margin: 0 0 8px;
    overflow: hidden;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        margin: 0 0 14px;
    `}
`;

const HeaderText = styled.span`
    display: block;
    max-height: ${({maxLines = 2}) => maxLines}em;
    overflow: hidden;
    letter-spacing: 1.1px;
    font: ${({size}) => HEADER_TEXT_FONTS[size] || HEADER_TEXT_FONTS.default};

    ${stylesIfElse('shouldUpperCase')`
        text-transform: uppercase;

        ${stylesWhenNot(({size}) => size === 'small')`
            ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
                letter-spacing: 3px;
            `}

            ${mediaQuery({minWidthPx: SCREEN_1680_DESKTOP})`
                letter-spacing: 4.5px;
                max-height: ${({size}) => size === 'medium' ? '1.8em' : '2em'};
            `}

            ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
                letter-spacing: 4px;
                max-height: ${({size}) => size === 'medium' ? '1.7em' : '2em'};
            `}
        `}
        ``
        ${mediaQuery({minWidthPx: SCREEN_1680_DESKTOP})`
            letter-spacing: 1.6px;
        `}
    `}
`;

const EditorialLabel = styled.p`
    margin: 0 0 2px;
    max-width: 100%;
    overflow: hidden;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    white-space: nowrap;
    font: var(--nucleus-poster-editorial-label);

    ${mediaQuery({minWidthPx: SCREEN_1680_DESKTOP})`
        margin: 0 0 7px;
        letter-spacing: 1px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        letter-spacing: 0.8px;
    `}
`;

const Headline = styled(EditorialLabel)`
    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        margin: 0 0 7px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        margin-bottom: 14px;
    `}
`;

const DotPointLine = styled.ul`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 0 7px;
    padding: 0;
    height: 1em;
    overflow: hidden;
    color: ${cloudy};
    font: var(--nucleus-poster-attribute-tags);
`;

const DotPointItem = styled.li`
    display: inline-flex;
    position: relative;
    align-items: center;
    letter-spacing: 0.5px;

    ${mediaQuery({minWidthPx: SCREEN_1680_DESKTOP})`
        letter-spacing: 0.63px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        letter-spacing: 0.5px;
    `}

    &:not(:first-of-type) {
        margin-left: 17px;

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            margin-left: 33px;
        `}

        &::before {
            position: absolute;
            left: -11px;
            color: ${rgba(smoke, 0.5)};
            content: '·';

            ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
                left: -20px;
            `}
        }
    }
`;

const LowerContentWrapper = styled.div`
    margin-top: 10px;
    padding-top: 13px;

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        padding-top: 22px;
        margin-top: 9px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1680_DESKTOP})`
        padding-top: 37px;
        margin-top: 8px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding-top: 47px;
    `}
`;

const LongText = styled.p`
    margin-top: 0;
    margin-bottom: 12px;
    padding-top: 0;
    color: ${smoke};
    font: var(--nucleus-poster-synopsis);

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        margin-bottom: 18px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1680_DESKTOP})`
        margin-bottom: 25px;
    `}
`;

const ExtendedInfo = styled.dl`
    margin: 0 0 21px;
    padding: 0;
    color: ${smoke};
    font: var(--nucleus-poster-synopsis);

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        margin: 0 0 23px;
        padding: 0;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1680_DESKTOP})`
        margin: 0 0 25px;
    `}
`;

const ExtendedInfoLabel = styled.dt`
    display: inline;
    font: var(--nucleus-poster-editorial-label);

    &::before {
        display: block;
        content: '';
    }
`;

const ExtendedInfoValue = styled.dd`
    display: inline;
    margin-left: 0;
`;

const ButtonRow = asNavigationRow(
    styled.div`
        display: flex;
        flex-direction: row;
        padding-top: 7px;

        ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
            padding-top: 12px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1680_DESKTOP})`
            padding-top: 24px;
        `}
    `
);

const TagsList = styled(DotPointLine)`
    margin: 0 0 19px;

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        margin: 0 0 21px;
    `}
`;

const StyledBA01BtnPr = styled(BA01BtnPr)`
    ${stylesWhen('isHiddenOnMobile')`
        display: none;

        ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
            display: inherit;
        `}
    `}
`;

const StyledGA01ProBar = styled(GA01ProBar)`
    position: absolute;
    bottom: -4px;
    left: 0;
    transform: translateY(100%);
`;

const TagLine = styled.div`
    font: var(--nucleus-poster-synopsis);
`;

const TM04Ft = React.forwardRef(({
    actions = [],
    addButton,
    className,
    color = lifestyle,
    editorialLabel,
    extendedInfo = [],
    headline,
    headlineTag,
    shouldShowHeadlineIcon = false,
    infoLineItems = [],
    isBeingLazy,
    isPreview = false,
    isVisible = true,
    moreButton,
    synopsis,
    tagLine,
    tags = [],
    title,
    titleLogoSrcset,
}, ref) => {
    const titleLines = get(title, 'lines');
    const defaultTitle = get(title, 'value') || get(titleLines, ['0', 'value']);

    return (
        <StyledSection
            isVisible={isVisible}
            className={classnames('TM04Ft', className)}
        >
            <AnchorPointPositioner>
                <LeftBorderedContent color={color} isPreview={isPreview}>
                    {shouldShowHeadlineIcon && (
                        <BroadcastStatus
                            color={color}
                            shouldShowHeadlineIcon={shouldShowHeadlineIcon}
                            headlineTag={headlineTag}
                        />
                    )}
                    {headline ? (
                        <React.Fragment>
                            <Headline style={{color}}>
                                {headline}
                            </Headline>
                            <HeaderSmall>{defaultTitle}</HeaderSmall>
                        </React.Fragment>
                    ) : (
                        titleLogoSrcset ? (
                            <ImageTitle
                                alt={defaultTitle}
                                srcsetOptions={isBeingLazy ? {1: EMPTY_IMAGE_SRC} : titleLogoSrcset}
                            />
                        ) : (
                            <Header>
                                {
                                    isEmpty(titleLines)
                                        ? <HeaderText maxLines={3}>{get(title, 'value')}</HeaderText>
                                        : titleLines.map(({size = 'default', value}, index) => (
                                            <HeaderText shouldUpperCase={true} size={size} key={index}>{value}</HeaderText>
                                        ))
                                }

                            </Header>
                        )
                    )}
                    {!!editorialLabel && <EditorialLabel style={{color}}>{editorialLabel}</EditorialLabel>}
                    {infoLineItems.length > 0 && (
                        <DotPointLine>
                            {infoLineItems
                                .filter(Boolean)
                                .map((item, index) => (
                                    <DotPointItem key={index}>
                                        {item}
                                    </DotPointItem>
                                ))
                            }
                        </DotPointLine>
                    )}
                </LeftBorderedContent>
            </AnchorPointPositioner>
            <LowerContentWrapper>
                {!!synopsis && <LongText>{synopsis}</LongText>}
                {Array.isArray(tags) && tags.length > 0 && (
                    <TagsList>
                        {tags.map((tag, index) => (
                            <DotPointItem key={tag.key || index}>{tag}</DotPointItem>
                        ))}
                    </TagsList>
                )}
                {!!extendedInfo && extendedInfo.length > 0 && (
                    <ExtendedInfo>
                        {extendedInfo.map((cast, index) => (
                            <React.Fragment key={index}>
                                <ExtendedInfoLabel>{cast.name}:</ExtendedInfoLabel> <ExtendedInfoValue>{cast.value}</ExtendedInfoValue>
                            </React.Fragment>
                        ))}
                    </ExtendedInfo>
                )}
                {!!tagLine && <TagLine>{tagLine}</TagLine>}
            </LowerContentWrapper>
            {!!(actions.length || moreButton || addButton) && (
                <ButtonRow ref={ref} willResetFocusIndexOnBlur={true}>
                    {actions.map(({label, isHiddenOnMobile, onClick, href, progress, disabled}, index) => (
                        <ActionWrapper
                            key={label}
                            actionButton={(
                                <StyledBA01BtnPr
                                    tabIndex={index === 0 && isVisible && !isPreview ? 0 : -1}
                                    href={href}
                                    onClick={onClick}
                                    isHiddenOnMobile={isHiddenOnMobile}
                                    disabled={disabled}
                                >
                                    {label}
                                </StyledBA01BtnPr>
                            )}
                            progressBar={(!!progress && (
                                <StyledGA01ProBar
                                    current={progress.current}
                                    total={progress.total}
                                    isThemed={true} // this component is used in the context of hero slide only, so hard coding it to TRUE
                                />
                            ))}
                        />
                    ))
                        // Doing this as a concat because the Nav HOC only works if the children are all in the same array
                        // Icebergs don't need to show extra action buttons
                        .concat(!isPreview && !!isVisible ? [
                            !!moreButton && moreButton,
                            !!addButton && addButton,
                        ].filter(Boolean) : [])}
                </ButtonRow>
            )}
        </StyledSection>
    );
});

TM04Ft.displayName = 'TM04Ft';

TM04Ft.propTypes = {
    /** Array of buttons to place on the slide */
    actions: propTypes.arrayOf(
        propTypes.shape({
            label: propTypes.string,
            isHiddenOnMobile: propTypes.bool,
            onClick: propTypes.func,
        })
    ),
    /** Video Description, e.g. Vikings transports us to the brutal and mysterious world of Ragnar Lothbrok */
    synopsis: propTypes.string,
    /** Optional "More" button */
    moreButton: propTypes.node,
    /** Optional "Add" button */
    addButton: propTypes.node,
    titleLogoSrcset: srcsetOptionsType,
    /** The title broken up into its segments for stylisation */
    title: propTypes.shape({
        value: propTypes.string,
        lines: propTypes.arrayOf(propTypes.shape({
            size: propTypes.oneOf(['small', 'medium', 'large', 'default']),
            value: propTypes.string,
        })),
    }),
    /** Label e.g. Primetime Emmy Nominee */
    editorialLabel: propTypes.node,
    /** Headline text */
    headline: propTypes.string,
    /** Headline tag text */
    headlineTag: propTypes.string,
    /** shows Headline tag if the value is true */
    shouldShowHeadlineIcon: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    /** Branding color for the slide */
    color: colorType,
    /** Tags e.g. Action / Adventure / Drama */
    tags: propTypes.arrayOf(propTypes.node),
    /** Array of nodes to show on the info line. Info line shows things like ESRB rating, year, # seasons, IMDB score etc. */
    infoLineItems: propTypes.arrayOf(propTypes.node),
    /** Optional extra info e.g. cast information */
    extendedInfo: propTypes.arrayOf(propTypes.shape({
        name: propTypes.string,
        value: propTypes.string,
    })),
    /** Is the slide visible (for fade in/out animations) */
    isVisible: propTypes.bool,
    /** Is the slide still waiting to render its images? */
    isBeingLazy: propTypes.bool,
    /** Optional extra className */
    className: classNameType,
    /** Is TM04Ft rendered as iceberg/preview */
    isPreview: propTypes.bool,
    /** Tagline of teaser copy that appears just before the CTAs */
    tagLine: propTypes.string,
};

export default TM04Ft;
