/* eslint-disable no-unused-vars */
export {
    isBrowser,
    isServer,
    isSafariComputer,
    isSafari,
    isIos,
    isWindows,
    isMsEdge,
    isFirefox,
    isChromecast,
    isMsEdgeChromium,
    isMsEdgeLegacy,
    isChrome,
    isWebmaf,
    isHisense,
    isLg,
    isLg2020,
    isPs5,
    isPs4,
    shouldUseDashjsEmeForHisense,
    isHisenseU5,
    isHisenseU6,
    isTizen,
    isTizen2017,
    isTizen2018,
    isTizen2019,
    isTizen2020,
    isTizen2021,
    isTizen2022,
    isTizen2023,
    isXbox,
    isFullScreen,
    isElementFullscreen,
    supportsAirPlay,
    supportsFullscreen,
    getFullscreenPrefix,
    getPlatformName,
    getBrowserType,
} from './environment';
export {getEventListenerArgs, listenOnce} from './event-listener';
export {enterFullscreen, exitFullscreen} from './full-screen';
export {
    _loadedStyles,
    _loadedScripts,
    _pendingScriptPromises,
    loadStyle,
    loadScript,
    purgeLoadedStyles,
    purgeScriptRequests,
} from './lazy-load';
export {lockOrientation, unlockOrientation} from './orientation';
export {getScriptBasePath, clearScriptBasePaths} from './script-base-path';
export {
    localStorageFunctions,
    cookieFunctions,
    isLocalStorageAvailable,
    setEnvironment,
    removeLocalStorageValue,
    getLocalStorageValue,
    setLocalStorageValue,
} from './storage';
export {nicerStringify} from './url';
export {testWindowWidth} from './window';
export {version} from './version';
