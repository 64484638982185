import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import handleSubmitForm from '../../../../common/get-handle-form-submit';
import {slate, white} from '../../../../common/palette';
import IA02Check from '../../../atoms/ia/02-check';
import IC103Loading from '../../../atoms/ic/103-loading';
import BA01BtnPr from '../../../atoms/ba/01-btn-pr';
import IM01Input from '../../../molecules/im/01-input';
import {SCREEN_TABLET} from '../../../../common/screen-sizes';

const LoginFormOverlay = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

const Form = styled.form`
    padding: 28px;
    width: 100%;
    max-width: 560px;
    text-align: center;
`;

const FormFieldContainer = styled.div`
    margin-right: auto;
    margin-left: auto;
    padding: 8px 0;
    max-width: 320px;
`;

const Header = styled.h1`
    color: ${white};
    font: var(--mui-header-7-bold);
`;

const ErrorText = styled.p`
    color: ${white};
    font: var(--mui-body-copy-3);
`;

const StyledLink = styled.a`
    background-color: transparent;
    text-decoration: none;
    color: ${slate};
    font: var(--mui-body-copy-3);
`;

const FormFieldContainerStyled = styled(FormFieldContainer)`
    padding: 30px 0;
`;

const BoldLinkText = styled.span`
    color: ${white};
`;

const LinksContainer = styled.div`
    margin-top: 47px;

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        margin-top: 147px;
    `}
`;

const OR58SignIn = ({
    className,
    errorMsg,
    forgotPasswordHref,
    createAccountHref,
    isLoading,
    name,
    onClickCreateAccount,
    onClickForgotPassword,
    onSubmit,
    showRememberMe = true,
    signInHeaderMessage = 'Please sign in',
}) => (
    <LoginFormOverlay className={classnames('OR58SignIn', className)}>
        <Form
            onSubmit={handleSubmitForm(onSubmit, ['email', 'password'])}
            name={name}
            method="POST"
            // prevents form submission before js executes (see martian-web-server for implementation)
            data-disabled-form-submit="true"
        >
            <Header>{signInHeaderMessage}</Header>

            <FormFieldContainer>
                <IM01Input type="email" label="Email Address" name="email" required={true} disabled={isLoading} />
            </FormFieldContainer>

            <FormFieldContainer>
                <IM01Input type="password" label="Password" name="password" required={true} disabled={isLoading} />
            </FormFieldContainer>

            {!!showRememberMe && (
                <FormFieldContainerStyled>
                    <IA02Check label="Remember me" name="remember-me" />
                </FormFieldContainerStyled>
            )}

            <FormFieldContainer>
                {isLoading ? (
                    <IC103Loading size="47px" />
                ) : (
                    <BA01BtnPr type="submit">Login</BA01BtnPr>
                )}
            </FormFieldContainer>

            {!!errorMsg && (
                <ErrorText role="alert">{errorMsg}</ErrorText>
            )}

            <LinksContainer>
                {!!(forgotPasswordHref || onClickForgotPassword) && (
                    <FormFieldContainer style={{textAlign: 'center'}}>
                        <StyledLink href={forgotPasswordHref} onClick={onClickForgotPassword}>Forgot password?</StyledLink>
                    </FormFieldContainer>
                )}

                {!!(createAccountHref || onClickCreateAccount) && (
                    <FormFieldContainer style={{textAlign: 'center'}}>
                        <StyledLink href={createAccountHref} onClick={onClickCreateAccount}>
                            New to Kayo?&nbsp;&nbsp;
                            <BoldLinkText>
                                Create an Account
                            </BoldLinkText>
                        </StyledLink>
                    </FormFieldContainer>
                )}
            </LinksContainer>
        </Form>
    </LoginFormOverlay>
);

OR58SignIn.displayName = 'OR58SignIn';

OR58SignIn.propTypes = {
    /** Class name property */
    className: classNameType,
    /** If set, an error message to be displayed to the user */
    errorMsg: propTypes.string,
    /** link to forgot password page */
    forgotPasswordHref: propTypes.string,
    /** link to create account page */
    createAccountHref: propTypes.string,
    /** Whether or not the form is in a loading state */
    isLoading: propTypes.bool,
    /** A custom HTML name for the form */
    name: propTypes.string,
    /** A callback to handle routing to forgot password */
    onClickForgotPassword: propTypes.func,
    /** A callback to handle routing to create account */
    onClickCreateAccount: propTypes.func,
    /** A callback to handle submission of the form data */
    onSubmit: propTypes.func,
    /** Boolean toggle to show 'remember me' area. Defaults to true. */
    showRememberMe: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    /** Sign in header message. */
    signInHeaderMessage: propTypes.string,
};

export default OR58SignIn;
