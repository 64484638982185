import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';
import {black, white} from '../../../../common/palette';

import Ic from '..';

const IC42Error = ({
    color = white,
    secondaryColor = black,
    ...otherProps
}) => (
    <Ic {...otherProps}>
        <path fill={color} d="M49.7 7C26.117 7 7 26.117 7 49.7c0 23.583 19.117 42.7 42.7 42.7 23.583 0 42.7-19.117 42.7-42.7C92.392 26.12 73.28 7.008 49.7 7z" />
        <g fill={secondaryColor} transform="translate(46.2 25.8)">
            <circle cx="4.2" cy="43.2" r="4.2" />
            <path d="M0 0h8.4v32.228H0z" />
        </g>
    </Ic>
);

IC42Error.propTypes = {
    /** The icon's primary color */
    color: colorType,
    /** The icon's secondary color */
    secondaryColor: colorType,
};

IC42Error.displayName = 'IC42Error';

export default IC42Error;
