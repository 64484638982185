import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import IA01Input from '../../../atoms/ia/01-input';
import IA05InputHelp from '../../../atoms/ia/05-input-help';
import {transition} from '../../../../common/animations';
import {white} from '../../../../common/palette';

const InputContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    transition: ${transition('opacity')};
    width: 100%;
    color: ${white};

    &[disabled='true'] {
        opacity: 0.35;
    }
`;

const InlineIcon = styled.span`
    position: absolute;
    top: 12px;
    right: 14px;
    width: 21px;
    height: 21px;
`;

const InputHelp = styled(IA05InputHelp)`
    ${stylesWhenNot('isFullWidth')`
        align-self: flex-end;
    `}
`;

const IM01Input = React.forwardRef(({
    icon,
    contextualHelp,
    isFullWidthContextualHelp = true,
    isTextCentered,
    className,
    type,
    disabled,
    ...htmlAttributes
}, ref) => (
    <InputContainer className={classnames('IM01Input', className)}>
        <IA01Input
            {...htmlAttributes}
            ref={ref}
            type={type}
            disabled={disabled}
            hasRevealPasswordButton={type === 'password'}
            hasIcon={!!icon}
            isTextCentered={isTextCentered}
        />
        {!!icon && (
            <InlineIcon>
                {icon}
            </InlineIcon>
        )}
        {!!contextualHelp && (
            <InputHelp
                role="alert"
                isFullWidth={isFullWidthContextualHelp}
            >
                {contextualHelp}
            </InputHelp>
        )}
    </InputContainer>
));

IM01Input.propTypes = {
    /** The HTML type of the input field */
    type: propTypes.oneOf(['text', 'password', 'email', 'tel', 'url', 'search', 'date', 'number']),
    /** Is the input field disabled? */
    disabled: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    /** Represents a validation warning, with contextual help */
    contextualHelp: propTypes.node,
    /** Does contextual help occupy the full width of this component? (Default: true) */
    isFullWidthContextualHelp: propTypes.bool,
    /** Is text within the input field centered? */
    isTextCentered: propTypes.bool,
    /** Represents a successfully validated input */
    icon: propTypes.node,
    /** Additional CSS class(es) */
    className: classNameType,
};

IM01Input.displayName = 'IM01Input';

export default IM01Input;
