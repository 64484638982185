import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {white} from '../../../../common/palette';
import {PERIOD_LONG, GIFT_CARD_REDEEM_INFO_TEXT} from '../../../../common/billing-constants';
import {billingPeriodType} from '../../../../common/custom-proptypes';
import OR118GiftInpAccount from '../../../organisms/or/118-gift-account';
import TM27InfoTile from '../../../molecules/tm/27-info-tile';
import {IC61Ios} from '../../../atoms/ic';

const GroupHeading = styled.h3`
    margin: 0;
    padding: 28px 0 7px;
    text-align: center;
    color: ${white};
    font: var(--mui-header-6-bold);
`;

const GroupHeading2 = styled.h4`
    margin: 0;
    padding: 21px 0 7px;
    text-align: center;
    color: ${white};
    font: var(--mui-header-7-bold);
`;

const Byline = styled.p`
    text-align: center;
    color: ${white};
    font: var(--mui-body-copy-3);
`;

const Groups = styled.div`
    display: grid;
    grid-row-gap: 7px;
`;

const Paragraph = styled.p`
    margin: 0;
    padding: 7px 0;
    text-align: center;
    color: ${white};
    font: var(--mui-body-copy-3);
`;

const RedeemGiftCardTemplate = ({
    isAccepted,
    billingPeriod,
    isRedeeming,
    errorMessage,
    isItunesSubscription,
    itunesSubscriptionTitle,
    itunesSubscriptionMessage,
    onRedeem,
    onChangeGiftCode,
}) => {
    if (isItunesSubscription) {
        return (
            <TM27InfoTile
                icon={<IC61Ios size="43px" />}
                title={itunesSubscriptionTitle}
            >
                {itunesSubscriptionMessage}
            </TM27InfoTile>
        );
    }

    return (
        <Groups>
            {isAccepted ? (
                <React.Fragment>
                    <section>
                        <GroupHeading>
                            Gift card accepted!
                        </GroupHeading>
                        <Paragraph>
                            Your Hubbl Gift Card has been applied successfully
                        </Paragraph>
                    </section>
                    <section>
                        <GroupHeading2>
                            What happens next?
                        </GroupHeading2>
                        <Paragraph>
                            Your credit will start to be used at the end of your current billing cycle.
                        </Paragraph>
                    </section>
                    <section>
                        <GroupHeading2>
                            What happens when the Gift Card credit runs out?
                        </GroupHeading2>
                        <Paragraph>
                            {`Your subscription will be auto-renewed each ${PERIOD_LONG[billingPeriod] || 'billing period'} using your preferred payment method and plan.`}
                        </Paragraph>
                    </section>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <section>
                        <Byline>{GIFT_CARD_REDEEM_INFO_TEXT}</Byline>
                        <Byline>Enter your unique code below to redeem</Byline>
                    </section>
                    <section>
                        <OR118GiftInpAccount
                            {...{
                                isRedeeming,
                                errorMessage,
                                onRedeem,
                                onChangeGiftCode,
                            }}
                        />
                    </section>
                </React.Fragment>
            )}
        </Groups>
    );
};

RedeemGiftCardTemplate.displayName = 'RedeemGiftCardTemplate';

RedeemGiftCardTemplate.propTypes = {
    /** Has the gift card been redeemed successfully? */
    isAccepted: propTypes.bool,
    /** Billing period */
    billingPeriod: billingPeriodType,
    /** Is the gift card currently being redeemed? */
    isRedeeming: propTypes.bool,
    /** The error message if gift card redemption failed */
    errorMessage: propTypes.node,
    /** If set shows itunes message */
    isItunesSubscription: propTypes.bool,
    /** Title for the itunes message */
    itunesSubscriptionTitle: propTypes.node,
    /** Content of the itunes message */
    itunesSubscriptionMessage: propTypes.node,
    /** Entered gift code change callback */
    onChangeGiftCode: propTypes.func,
    onRedeem: propTypes.any,
};

export default RedeemGiftCardTemplate;
