import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

const StyledHr = styled.hr`
    margin: 0;
    border: 0;
`;

const Spacer = ({
    height = 49,
}) => (
    <StyledHr style={{paddingBottom: height}} />
);

Spacer.displayName = 'Spacer';

Spacer.propTypes = {
    /** Height of the Spacer */
    height: propTypes.oneOfType([propTypes.number, propTypes.string]),
};

export default Spacer;
