import React from 'react';
import Ic from '..';

const IC119SwipeU = (args) => (
    <Ic {...args}>
        <path fillRule="nonzero" d="M36.099 18.556 24.556 7 13 18.556l2.962 2.963 6.4-6.4v22.694h4.193v-22.89l6.582 6.596z" />
        <path fillOpacity=".35" d="M58 91c11.598 0 20.308-3.296 25.12-20.829C87.93 52.64 86.78 49.026 58 42.927a49.36 49.36 0 0 0-4.894 2.398c-1.934 1.086 5.519-26.702-5.88-23.919-8.46 2.066-3.929 31.447-5.59 33.726C38.733 59.12 37 63.764 37 69c0 12.15 9.402 22 21 22z" />
        <path fillRule="nonzero" d="M85.972 53.002a6.875 6.875 0 0 0-6.875-6.875h-.42a6.847 6.847 0 0 0-3.283.867 7.127 7.127 0 0 0-6.512-4.262 6.987 6.987 0 0 0-3.717 1.076 7.14 7.14 0 0 0-6.414-3.927h-.154a8.147 8.147 0 0 0-2.655.657v-3.005c0-1.397 0-2.697.07-3.968.14-5.227.265-9.782-2.264-12.34a6.805 6.805 0 0 0-5.114-1.914 8.384 8.384 0 0 0-6.289 2.362 9.405 9.405 0 0 0-2.473 6.288v26.55a14.743 14.743 0 0 0-4.458 5.59c-3.619 8.007-.14 18.446 1.51 21.786l.083.181c1.747 3.564 5.394 10.998 25.657 10.998 16.377.112 21.24-14.799 21.603-23.309a6.805 6.805 0 0 0 1.705-4.471V53.002zm-7.294-2.683h.489a2.697 2.697 0 0 1 2.683 2.683v12.284a2.697 2.697 0 0 1-2.683 2.683h-.49a2.697 2.697 0 0 1-2.794-2.683V53.002a2.683 2.683 0 0 1 2.795-2.683zm-19.871-6.246a2.865 2.865 0 0 1 2.795 2.795v1.202a39.56 39.56 0 0 0-5.856.894v-2.096a.824.824 0 0 1 0-.182v-.307a3.046 3.046 0 0 1 3.06-2.306zM44.064 27.961a4.29 4.29 0 0 1 4.5-4.486 2.655 2.655 0 0 1 2.11.643c1.3 1.398 1.188 5.212 1.076 9.307 0 1.313-.07 2.683-.07 4.08v12.451c-1.397.35-3.032.769-4.807 1.244a21.94 21.94 0 0 0-2.795.95l-.014-24.189zm18.6 61.024c-17.65 0-20.402-5.59-21.884-8.65l-.098-.195c-1.313-2.655-4.387-11.71-1.397-18.166 1.51-3.326 4.346-5.506 8.678-6.666 8.873-2.39 12.577-2.892 14.114-2.962.181 1.872 0 4.387-1.118 5.058-3.242 1.817-14.59 6.652-14.7 6.694l1.676 3.843c.42-.182 11.682-4.975 15.064-6.987 4.192-2.432 3.284-8.902 3.004-10.453a2.501 2.501 0 0 0-.125-.559v-.14a2.935 2.935 0 1 1 5.855 0v20.891a2.935 2.935 0 0 1-5.855 0v-4.68l-4.193 1.928v4.737a2.865 2.865 0 0 1-2.794 2.864h-.126a2.865 2.865 0 0 1-2.795-2.864v-2.096l-4.192 1.928v.168a7.07 7.07 0 0 0 6.987 7.057h.126a6.987 6.987 0 0 0 5.813-3.214 6.987 6.987 0 0 0 4.066 1.285 7.113 7.113 0 0 0 6.987-6.246c.878.398 1.831.603 2.795.6h.49c.233-.013.467-.041.698-.083-.797 5.687-4.123 16.908-17.076 16.908z" />
    </Ic>
);

IC119SwipeU.displayName = 'IC119SwipeU';

export default IC119SwipeU;
