import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import {cloudy, ink} from '../../../../common/palette';

import Ic from '..';

const IC130Alert = ({
    color = cloudy,
    secondaryColor = ink,
    ...args
}) => (
    <Ic {...args}>
        <g fill={color} fillRule="nonzero" transform="translate(7 7)">
            <circle cx="42.621" cy="42.621" r="42.621" fill={secondaryColor} />
            <circle cx="44.158" cy="62.953" r="4.192" />
            <path d="M39.966 19.647h8.384v32.168h-8.384z" />
        </g>
    </Ic>
);

IC130Alert.propTypes = {
    /** The icon's primary color */
    color: colorType,
    /** The icon's secondary color */
    secondaryColor: colorType,
};

IC130Alert.displayName = 'IC130Alert';

export default IC130Alert;
