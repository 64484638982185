import bacon from 'baconjs';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import uniqBy from 'lodash/uniqBy';
import pick from 'lodash/pick';
import flatten from 'lodash/flatten';
import property from 'lodash/property';
import matchesProperty from 'lodash/matchesProperty';

import generateFootnoteMarker from '../../utils/generate-footnote-marker';
import {checkIfAdTier, createSecondaryTextForAdTierPackage} from '../../utils/binge-ad-tier-package-helpers';

export default function getPackageSelectorPropsStream({
    prefetchedOffer$,

    offer$,
    offerSettings$,
}) {
    const selectedPackagePriceIdBus = new bacon.Bus();

    const packageHeaders$ = offer$
        .map('.packages')
        .map((packages) => {
            // eslint-disable-next-line arrow-body-style
            const packageHeadersWithDuplicates = packages?.reduce((headersCollection, thisPackage) => {
                return headersCollection.concat(
                    thisPackage.features
                        .filter((feature) => !checkIfAdTier(feature))
                        .map((feature) => pick(feature, 'title', 'a11yTitle'))
                );
            }, []);

            return uniqBy(packageHeadersWithDuplicates, 'title');
        })
        .startWith([]);

    const packages$ = offer$
        .map('.packages')
        .map(addFootnoteMarkerCount)
        .combine(packageHeaders$, (packages, packageHeaders) => (
            packages
                .map(createSecondaryTextForAdTierPackage)
                .map((thisPackage) => { // package is a reserved word, grumble....
                    const featuresByFeatureName = keyBy(thisPackage.features, 'title');

                    return {
                        displayName: thisPackage.displayName,
                        prices: thisPackage.prices,
                        footnoteMarker: generateFootnoteMarker({count: thisPackage.footnoteMarkerCount}),
                        secondaryText: thisPackage.secondaryText,

                        // eslint-disable-next-line arrow-body-style
                        features: packageHeaders.map(({title: featureName}) => {
                            return {
                                value: get(featuresByFeatureName, [featureName, 'value']),
                                a11yValue: get(featuresByFeatureName, [featureName, 'a11yValue']),
                            };
                        }),
                    };
                })
        ))
        .startWith([]);

    // If we have only one package available, we want it to be selected by default
    // We dont want to rely on the Ids, rather the actual priceId to be sure!
    const defaultSelectedPackagePriceId$ = offer$
        .map(matchesProperty('packages.length', 1))
        .decode({
            // if there is exactly one offer, pick it by default
            true: offer$.map('.packages.0.prices.0.priceId'),
            false: undefined,
        })
        .startWith(undefined)
        .skipDuplicates();

    const isLoading$ = bacon.mergeAll(
        offerSettings$.map(true),
        offer$.map(false),

        offer$.errors().mapError(false),
    )
        .toProperty(true)
        .skipDuplicates();

    // So we have packageSelected, and when offers update, the IDs may, or may not change.
    // but we don't know if they do inside of react. So we're going to compare the ids in this new offer that
    // lands, compare to what we had in the packageSelectedBus, and if we can't find it, set ourselves to null in
    // packageSelected$
    const previouslySelectedIdNoLongerInCurrentList$ = offer$
        .combine(selectedPackagePriceIdBus.toProperty(), (offer, packageSelected) => {
            // Don't have a previously selected id, go away.
            if (!packageSelected) {
                return false;
            }

            // THIS IS SEAN'S CODE. BLAME HIM IF YOU DON'T LIKE IT.
            const priceIdsInNewOffer = flatten(offer.packages.map(property('prices')))
                .map(property('priceId'));

            return !priceIdsInNewOffer.includes(packageSelected);
        });

    const selectedPackagePriceId$ = bacon.mergeAll(
        defaultSelectedPackagePriceId$,
        selectedPackagePriceIdBus,   // will toggle between ids and null.
        previouslySelectedIdNoLongerInCurrentList$
            .filter(Boolean)  // Only clears out if we don't have a matching selected package id to the new offers.
            .map(null)
    )
        .startWith(null);

    const voucherRequiredToUnlockOffers$ = prefetchedOffer$
        .map('.offer.voucherRequired')
        .first(); // only ever take the server side variant of this.  Entering vouchers and things WILL cause this voucherRequired to be changed when loading first offer$ value.

    const voucherAccepted$ = offer$
        .map('.voucher.code')
        .map(Boolean);

    const isProductAvailableForSelection$ = bacon.combineTemplate({
        voucherRequiredToUnlockOffers: voucherRequiredToUnlockOffers$,
        voucherAccepted: voucherAccepted$,
    })
        .map(({voucherRequiredToUnlockOffers, voucherAccepted}) => {
            if (voucherRequiredToUnlockOffers) {
                return voucherAccepted;
            } else {
                return true;
            }
        })
        .startWith(false);

    return bacon.combineTemplate({
        props: {
            headers: packageHeaders$,
            packages: packages$,

            isDisabled: isProductAvailableForSelection$.not(),
            isLoading: isLoading$, // offersLoading$,

            onChange: (packagePriceId) => {
                selectedPackagePriceIdBus.push(packagePriceId);
            },

            defaultSelectedPackagePriceId: defaultSelectedPackagePriceId$,
        },
        selectedPackagePriceId: selectedPackagePriceId$,
        isProductAvailableForSelection: isProductAvailableForSelection$,
    });
}

/**
 * This adds `footnoteMarkerCount` to packages based on the presence of `subjectToChangeText`
 * which will later on be used for marker generation
 *
 * @param {Array[Object]} packages  packages
 * @returns {Array[Object]} modified packages
 */
function addFootnoteMarkerCount(packages) {
    let priceChangeCount = 0;

    return packages.map((thisPackage) => {
        if (thisPackage.subjectToChangeText) {
            priceChangeCount = priceChangeCount + 1;

            return {
                ...thisPackage,
                footnoteMarkerCount: priceChangeCount,
            };
        }

        return {
            ...thisPackage,
            footnoteMarkerCount: 0,
        };
    });
}
