import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import property from 'lodash/property';

import {silverLining} from '../../../../common/palette';

const StyledSection = styled.section`
    width: 100%;
`;

const SettingsHeader = styled.header`
    margin-bottom: 14px;
    text-align: ${property('alignment')};
    color: ${silverLining};
`;

const SettingsTitle = styled.h5`
    margin: 0;
    font: var(--mui-header-5);
`;

const SettingsDescription = styled.h3`
    margin: 7px 0 0;
    font: var(--mui-body-copy-2);
`;

const SettingsList = styled.ul`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(${property('minWidth')}, ${property('maxWidth')}));
    grid-gap: 14px;
    grid-template-rows: repeat(auto-fill, 1fr); /* stylelint-disable-line */
    justify-content: ${property('alignment')};
    padding: 0;
    width: 100%;
    list-style: none;
`;

const OR44SetSelec = ({
    children,
    description,
    minTileWidth = '300px',
    maxTileWidth = 'auto',
    tileAlignment = 'center',
    title,
    titleAlignment = 'center',
}) => (
    <StyledSection className="OR44SetSelec">
        <SettingsHeader alignment={titleAlignment}>
            {!!title && (
                <SettingsTitle>{title}</SettingsTitle>
            )}
            {!!description && (
                <SettingsDescription>{description}</SettingsDescription>
            )}
        </SettingsHeader>

        {!!React.Children.count(children) && (
            <SettingsList
                alignment={tileAlignment}
                minWidth={minTileWidth}
                maxWidth={maxTileWidth}
            >
                {React.Children.map(children, (child) => (
                    <li key={child.key}>
                        {child}
                    </li>
                ))}
            </SettingsList>
        )}
    </StyledSection>
);

OR44SetSelec.displayName = 'OR44SetSelec';

OR44SetSelec.propTypes = {
    /** Various settings tile to load, like Notification tiles (TR03-SetTil-IT) or Show tiles (TR02-SetTil-DT) */
    children: propTypes.node,
    /** Description for the settings */
    description: propTypes.string,
    /** Min width for each of the settings tile */
    minTileWidth: propTypes.string,
    /** Max width for each of the settings tile */
    maxTileWidth: propTypes.string,
    /** Alignment for the child i.e. left, center, or right  */
    tileAlignment: propTypes.oneOf(['left', 'center', 'right']),
    /** Title for the settings */
    title: propTypes.string,
    /** Alignment for the title i.e. left, center, or right  */
    titleAlignment: propTypes.oneOf(['left', 'center', 'right']),
};

export default OR44SetSelec;
