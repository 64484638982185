import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {SCREEN_1920_DESKTOP} from '../../../../../common/screen-sizes';

const StyledColGroup = styled.colgroup`
    --or-94-main-col-width: 155px;
    --or-94-feature-col-total-width: calc(100% - var(--or-94-main-col-width));
    --or-94-feature-col-width: ${({featureCount}) => `calc(var(--or-94-feature-col-total-width) / ${featureCount})`};
    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        --or-94-main-col-width: 209px;
    `}
`;

/**
 * Main column will have fixed width:
 * - < 1920: 155px
 * - >= 1920: 209px
 */
const HeaderCol = styled.col`
    width: var(--or-94-main-col-width);
`;

/**
 * The rest of the feature column will share equal widths
 *  feature col width = (100% - main col width) / # of features
 */
const FeatureCol = styled.col`
    width: var(--or-94-feature-col-width);
`;

/**
 * This controls sizing of column widths
 *
 * @param {{featureCount: Number}} props props
 * @returns {React.ReactElement} ColumnGroup
 */
const ColumnGroup = ({featureCount}) => (
    <StyledColGroup featureCount={featureCount}>
        <HeaderCol />
        <FeatureCol span={featureCount} />
    </StyledColGroup>
);

ColumnGroup.displayName = 'ColumnGroup';

ColumnGroup.propTypes = {
    featureCount: propTypes.number,
};

export default ColumnGroup;
