import React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType, srcsetOptionsType} from '@fsa-streamotion/custom-prop-types';
import {SCREEN_375_PHABLET, SCREEN_768_TABLET} from '../../../../common/screen-sizes';
import {ink} from '../../../../common/palette';
import {getLargestImage} from '../../../../common/tile-utils';
import SrcsetSource from '../../../../common/srcset-source';

const IMAGE_MOBILE_MAX_WIDTH_PX = '209';
const IMAGE_DESKTOP_MAX_WIDTH_PX = '310';

const StyledPicture = styled.picture`
    display: block;
    max-width: ${IMAGE_MOBILE_MAX_WIDTH_PX}px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        max-width: ${IMAGE_DESKTOP_MAX_WIDTH_PX}px;
    `}
`;

const StyledImg = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;
    /* Font styling is for alt text. */
    color: ${ink};
    font: var(--quicksilver-body-copy-1);
`;

/**
 * Static image used for the Register Device Pages
 *
 * ### Zeplin links
 * * **375px:** https://zpl.io/z8G8eZ3
 * * **1920px:** https://zpl.io/g8L8dJ1
 */
const GA161RegisterDev = ({
    className,
    defaultImageSrc,
    imageAltText = '',
    srcsetOptions,
    srcsetSizes,
    ...htmlAttributes
}) => (
    <StyledPicture
        {...htmlAttributes}
        className={classnames('GA161RegisterDev', className)}
    >
        <SrcsetSource
            key="srcset"
            minWidthPx={SCREEN_375_PHABLET}
            sizes={srcsetSizes}
            srcsetOptions={srcsetOptions}
        />
        <StyledImg
            alt={imageAltText}
            key="image"
            src={defaultImageSrc || getLargestImage(srcsetOptions)}
        />
    </StyledPicture>
);

GA161RegisterDev.propTypes = {
    /** Additional class name(s) */
    className: classNameType,
    /** Image to use for browsers that don't support `<picture>`. Defaults to largest `srcSetOption` screen size */
    defaultImageSrc: propTypes.string,
    /** Image alt attribute */
    imageAltText: propTypes.string,
    /** A srcset of the image used to display a device to be registered */
    srcsetOptions: srcsetOptionsType,
    /** Srcset sizes */
    srcsetSizes: propTypes.string,
};

GA161RegisterDev.displayName = 'GA161RegisterDev';

export default GA161RegisterDev;
