import React from 'react';
import Ic from '..';

const IC232Help = (args) => (
    <Ic {...args}>
        <g transform="translate(10 10)">
            <circle cx="40.74" cy="39.032" r="2.8" />
            <circle cx="52.262" cy="39.032" r="2.8" />
            <circle cx="29.232" cy="39.032" r="2.8" />
            <path d="M0 80.08l9.394-17.486C-1.617 47.901-.931 27.527 11.044 13.608 23.019-.31 43.06-4.032 59.234 4.662c18.089 9.68 25.625 31.69 17.266 50.426-8.36 18.736-29.774 27.83-49.06 20.834L0 80.08zm28.392-12.796l1.05.42c15.126 5.929 32.254-.94 39.09-15.679 6.838-14.738 1.021-32.251-13.274-39.971-13.076-6.826-29.157-3.422-38.346 8.116-9.19 11.539-8.908 27.973.672 39.19l1.918 2.24-4.2 7.7 13.09-2.016z" />
        </g>
    </Ic>
);

IC232Help.displayName = 'IC232Help';

export default IC232Help;
