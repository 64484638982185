import React from 'react';
import {colorType} from '@fsa-streamotion/custom-prop-types';

import {red, black} from '../../../../common/palette';
import Ic from '..';

const IC20Live = ({color = red, textColor = black, ...otherProps}) => (
    <Ic {...{...otherProps, color}}>
        <path d="M82.5 70.6H15.8c-.8 0-1.4-.6-1.4-1.4V35.9c0-.8.6-1.4 1.4-1.4h66.7c.8 0 1.4.6 1.4 1.4v33.3c0 .8-.7 1.4-1.4 1.4z" fill={color} />
        <path d="M37.4 58.7h-7.8V45.3h3.1v10.8h4.6v2.6zM42.7 58.7h-3.1V45.3h3.1v13.4zM57.6 45.3l-5 13.4h-3l-5-13.4H48l3.1 9.2 3.1-9.2h3.4zM68.4 58.7h-8.9V45.3h8.7v2.5h-5.6v2.7h5.2V53h-5.2v3.1h5.7v2.6z" fill={textColor} />
    </Ic>
);

IC20Live.displayName = 'IC20Live';

IC20Live.propTypes = {
    /** Colour for the LIVE text */
    color: colorType,
    textColor: colorType,
};

export default IC20Live;
