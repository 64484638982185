import React, {useState} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import classnames from 'classnames';
import FocusTrap from 'focus-trap-react';
import noop from 'lodash/noop';

import {mediaQuery, stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {Section, Footer, Button} from '../../../../common/normalized-styled-components';
import CommonTransition from '../../../../common/common-transition';
import {transition, fadeInOut} from '../../../../common/animations';
import {blueCharcoal, black, white} from '../../../../common/palette';
import {SCREEN_415_PHABLET, SCREEN_768_TABLET, SCREEN_1024_DESKTOP, SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';

import IC135Cross from '../../../atoms/ic/135-cross';
import BA01BtnPr from '../../../atoms/ba/01-btn-pr';

const PageContainer = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    ${fadeInOut}
    /* fadeInOut uses opacity in its transition so we have it here too so we don't override it */
    /* stylelint-disable-next-line order/properties-order */
    transition: ${transition('background-color', 'opacity')};

    ${stylesWhen('hasOverlay')`
        background-color: ${rgba(black, 0.9)};
    `}
`;

const Modal = styled.dialog`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border: 0; /** Yucky browser styles */
    border-radius: 5px;
    box-shadow: 0 0 15px 4px ${rgba(black, 0.8)};
    background-color: ${blueCharcoal};
    background-image: linear-gradient(${rgba(blueCharcoal, 0.05)}, transparent 74%);
    padding: 0; /** Yucky browser styles */
    width: 100%;
    max-height: 96vh;
    overflow-y: auto;
    overscroll-behavior: none;

    ${mediaQuery({minWidthPx: SCREEN_415_PHABLET})`
        width: 92%;
    `}

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: 84%;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        width: 725px;
    `}
`;

const ButtonContainer = styled(Footer)`
    position: absolute;
    top: 7px;
    right: 7px;
    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        top: 14px;
        right: 14px;
    `}
`;

const StyledButton = styled(Button)`
    box-sizing: content-box;
    border: 0;
    background: transparent;
    padding: 10px;
    width: 21px;
    height: 21px;
    color: ${white};

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: 30px;
        height: 30px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 40px;
        height: 40px;
    `}
`;

const StyledFooter = styled(Footer)`
    box-sizing: border-box;
    display: flex;
    align-self: center;
    justify-content: center;
    padding: 28px;
    width: 100%;
`;

const OR56Modal = ({
    children,
    isVisible,
    hasOverlay = true,
    onCloseClick = noop,
    canBeDeactivated,
    hasCrossButton,
    className,
    ...htmlAttributes
}) => {
    const [hasFocus, setHasFocus] = useState(false);

    const deactivateFocusTrap = () => {
        if (canBeDeactivated) {
            setHasFocus(false);
            onCloseClick();
        }
    };

    const focusTrapOptions = {
        onDeactivate: deactivateFocusTrap,
        escapeDeactivates: canBeDeactivated,
        clickOutsideDeactivates: canBeDeactivated,
    };

    return (
        <CommonTransition
            in={isVisible}
            onEntered={() => setHasFocus(true)} // Focus trap seems to prevent CommonTransition from fading in so we delay it until the transition ends
        >
            <PageContainer {...htmlAttributes} hasOverlay={hasOverlay}>
                <FocusTrap active={hasFocus} focusTrapOptions={focusTrapOptions}>
                    <Modal className={classnames('OR56Modal', className)} open={isVisible}>
                        {!!children && (
                            <Section>{children}</Section>
                        )}

                        {!!canBeDeactivated && (
                            hasCrossButton ? (
                                <ButtonContainer>
                                    <StyledButton
                                        ariaLabel="Close"
                                        onClick={deactivateFocusTrap}
                                    >
                                        <IC135Cross />
                                    </StyledButton>
                                </ButtonContainer>
                            ) : (
                                <StyledFooter>
                                    <BA01BtnPr
                                        onClick={deactivateFocusTrap}
                                    >
                                        Close
                                    </BA01BtnPr>
                                </StyledFooter>
                            )
                        )}
                    </Modal>
                </FocusTrap>
            </PageContainer>
        </CommonTransition>
    );
};

OR56Modal.propTypes = {
    children: propTypes.node,
    isVisible: propTypes.bool,
    /** Displays an overlay on top of the content behind the modal */
    hasOverlay: propTypes.bool,
    /** Function to call when the Close button is clicked */
    onCloseClick: propTypes.func,
    /** Can this modal be deactivated by clicking close, clicking outside of the modal or pressing Escape? */
    canBeDeactivated: propTypes.bool,
    /** Additional CSS classnames */
    className: classNameType,
    /** For Sign Up Modal diplay cross at the top right corner */
    hasCrossButton: propTypes.bool,
};

OR56Modal.displayName = 'OR56Modal';

export default OR56Modal;
