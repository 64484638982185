import React from 'react';

import Ic from '..';

const IC218XtraInfo = (args) => (
    <Ic {...args}>
        <g transform="scale(2.96 2.96)" fillRule="nonzero">
            <circle fill="#FFF" opacity=".2" cx="16.898" cy="16.898" r="16.898" />
            <path d="M16.907 9.964a1.79 1.79 0 0 1-1.706-1.842A1.793 1.793 0 0 1 17.03 6.4c.982.016 1.77.808 1.771 1.78.003.49-.199.96-.557 1.298a1.801 1.801 0 0 1-1.336.486zM18.369 27.2h-2.915V12.885l2.915-.485v14.8z" fill="currentColor" />
        </g>
    </Ic>
);

IC218XtraInfo.displayName = 'IC218XtraInfo';

export default IC218XtraInfo;
