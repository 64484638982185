import bacon from 'baconjs';

/**
 * Get a standardised `bacon.Error` object, for predictable extraction of error messages
 * Platform have sort of, kinda, halfway implemented the [RF7808 spec for "Problem Details"](https://tools.ietf.org/html/rfc7807#section-3.1)
 * In the future, this function could parse those objects into something we can easily work with (e.g. can be stringed for logs, extended to hold metadata etc)
 *
 * Supports a `toString()` for ease of use (for example, consumption by FISO emitting errors)
 *
 * @param {Object} options see below
 * @param {string} [options.type]           type for identifying the error, for example an HTTP response type or predefined API error type
 * @param {number} [options.statusCode]     a response code e.g. 404
 * @param {Object} [options.debugMessage]   a longer message for debugging purposes
 * @param {Object} [options.message]        an error message for displaying to users (for example in an error modal)
 *
 * @returns {bacon.Error} a bacon error containing an object like {type, message, debugMessage, toString}
 *
 * @example
 * userHasAuth$.flatMapLatest((hasAuth) => {
 *     if (!hasAuth) {
 *          return stringableBaconError({
 *              message: 'Error: please log in first before accessing this page',
 *              debugMessage 'User not logged in when trying to access the top secret page',
 *          });
 *     }
 *
 *     return hasAuth;
 * })
 */
export default function stringableBaconError({
    message,
    statusCode,
    type,

    debugMessage = message,
}) {
    return new bacon.Error({
        type,
        message,
        debugMessage,
        statusCode,
        toString() {
            return debugMessage;
        },
    });
}
