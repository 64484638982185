import React from 'react';
import Ic from '..';
import {fog} from '../../../../common/palette';

const IC51Close = (args) => (
    <Ic {...args}>
        <path d="M49.245 7C25.914 7 7 25.914 7 49.245c0 23.33 18.914 42.244 42.245 42.244 23.33 0 42.244-18.913 42.244-42.244C91.482 25.917 72.573 7.008 49.245 7z" />
        {/* The `x` in the middle will have fixed color #E2E3E6 */}
        <path
            d="M66.572 37.804l-5.887-5.873-11.44 11.441-11.441-11.441-5.873 5.873 11.441 11.441-11.441 11.44 5.873 5.887 11.441-11.455 11.44 11.455 5.887-5.887-11.455-11.44z"
            fill={fog}
        />
    </Ic>
);

IC51Close.displayName = 'IC51Close';

export default IC51Close;
