import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
// import {rgba} from 'polished';
import {Button} from 'normalized-styled-components';
import noop from 'lodash/noop';

import {white} from '../../../../common/palette';
import {kayoGreen, slate} from '../../../../common/deprecated-mui-palette';
import {transition} from '../../../../common/animations';

const StyledButton = styled(Button)`
    appearance: none;
    display: flex;
    align-items: center;
    transition: ${transition('color')};
    border: 0;
    background-color: transparent;
    padding: 0;
    color: ${white};

    &:focus,
    &:hover {
        outline: 0;
        color: ${kayoGreen};
    }

    > :not(:first-child) {
        margin-left: 7px;
    }
`;

const IconBox = styled.span`
    display: inline-block;
    width: 38px;
    height: 38px;
`;

const TextBox = styled.span`
    color: ${slate};
`;

// eslint-disable-next-line jsdoc/require-param
/**
 * @deprecated since version 10.3.2
 *
 * @param {Object} options gets some deprecated stuff
 * @returns {React.Component} BA10BtnBack
 */
const BA10BtnBack = ({
    children,
    icon,
    onClick = noop,
    ...htmlAttributes
}) => (
    <StyledButton
        {...htmlAttributes}
        onClick={onClick}
        type="button"
    >
        <IconBox>
            {icon}
        </IconBox>

        {!!React.Children.count(children) && (
            <TextBox>
                {children}
            </TextBox>
        )}
    </StyledButton>
);

BA10BtnBack.displayName = 'BA10BtnBack';

BA10BtnBack.propTypes = {
    /** IC icon to use */
    icon: propTypes.node,
    /** Content of button, e.g. text */
    children: propTypes.node,
    /** Callback on element click */
    onClick: propTypes.func,
};

export default BA10BtnBack;
