import React from 'react';

import Ic from '..';

const IC22Float = (args) => (
    <Ic {...args}>
        <path fillOpacity=".35" d="M20 35.766h49v34H20z" />
        <path fillRule="nonzero" d="M62 31.766c5.523 0 10 4.477 10 10v22c0 5.523-4.477 10-10 10H27c-5.523 0-10-4.477-10-10v-22c0-5.523 4.477-10 10-10h35zm0 4H27a6 6 0 0 0-5.996 5.775l-.004.225v22a6 6 0 0 0 5.775 5.996l.225.004h35a6 6 0 0 0 5.996-5.775l.004-.225v-22a6 6 0 0 0-5.775-5.996L62 35.766z" />
        <path fillRule="nonzero" d="M52.894 83.638H6.847V52.766h4.12v26.752h41.927zm37.668-58.465-2.596 3.626A20.654 20.654 0 0 0 48 26.005l3.983 1.3a16.475 16.475 0 0 1 31.86 2.123l-3.624-2.542a2.11 2.11 0 0 0-2.93.502 2.1 2.1 0 0 0 .486 2.925l9.408 6.745 6.764-9.493a2.062 2.062 0 0 0-.49-2.881 2.083 2.083 0 0 0-2.895.489z" />
    </Ic>
);

IC22Float.displayName = 'IC22Float';

export default IC22Float;
