import React from 'react';
import propTypes from 'prop-types';
import {Section} from 'normalized-styled-components';
import styled from 'styled-components';
import classnames from 'classnames';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {SCREEN_REALLY_LG_DESKTOP, SCREEN_TABLET} from '../../../../common/screen-sizes';
import {XS_HERO_FIXED_HEIGHT_PX, XL_HERO_FIXED_HEIGHT_PX} from '../../../../common/style-constants';
import {transition} from '../../../../common/animations';
import TM04Ft from '../../tm/04-ft';

const StyledSection = styled(Section)`
    position: relative;
    width: 100%;

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        height: ${XS_HERO_FIXED_HEIGHT_PX}px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_REALLY_LG_DESKTOP})`
        height: ${XL_HERO_FIXED_HEIGHT_PX}px;
    `}
`;

const PrimaryContent = styled.div`
    position: absolute;
    right: 5%;
    bottom: 98px;
    left: 5%;
`;

const Background = styled.div`
    transition: ${transition('opacity')};
    opacity: ${({isVisible}) => isVisible ? 1 : 0};
    height: 100%;
`;

const CAM02HeroSlide = ({
    className,
    isVisible = true,

    isBeingLazy,
    backgroundImage,

    actions,
    description,
    heroHeader,
    label,
    linearProvider,
    title,
}) => (
    <StyledSection className={classnames('CAM02HeroSlide', className)}>
        {!!backgroundImage && (
            <Background isVisible={isVisible}>
                {React.cloneElement(backgroundImage, {isBeingLazy})}
            </Background>
        )}

        <PrimaryContent>
            <TM04Ft
                {...{
                    actions,
                    description,
                    heroHeader,
                    isVisible,
                    label,
                    linearProvider,
                    title,
                }}
            />
        </PrimaryContent>
    </StyledSection>
);

CAM02HeroSlide.propTypes = {
    /** Additional className(s) to apply */
    className: classNameType,
    /** Is slide layer visible (e.g. as toggled by a containing carousel) */
    isVisible: propTypes.bool,

    isBeingLazy: propTypes.any,
    backgroundImage: propTypes.element,

    // TM04Ft props
    actions: propTypes.arrayOf(
        propTypes.shape({
            label: propTypes.node,
            onClick: propTypes.func,
        })
    ),
    description: propTypes.string,
    heroHeader: propTypes.string,
    label: propTypes.node,
    linearProvider: propTypes.node,
    title: propTypes.string,
};

CAM02HeroSlide.displayName = 'CAM02HeroSlide';

export default CAM02HeroSlide;
