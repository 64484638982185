import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import {blanc, vader} from '../../../../common/palette';
import Ic from '..';

const IC122ActionBack = ({
    color = blanc,
    secondaryColor = vader,
    ...otherProps
}) => (
    <Ic {...otherProps}>
        <g transform="translate(7 7)">
            <circle cx="42.7" cy="42.7" r="42.7" fill={color} />
            <path fill={secondaryColor} d="M26.264 48.076a6.008 6.008 0 01-3.472.882h-5.6V35.42h4.606a7.322 7.322 0 013.332.574 3.025 3.025 0 011.722 2.8 3.165 3.165 0 01-1.974 2.982 3.304 3.304 0 012.8 3.22 3.43 3.43 0 01-1.414 3.08zm-3.122-10.024a2.385 2.385 0 00-1.4-.28h-1.316v3.122h1.246a2.482 2.482 0 001.47-.294c.451-.273.711-.776.672-1.302a1.4 1.4 0 00-.672-1.246zM23.8 43.4a2.801 2.801 0 00-1.694-.364h-1.694v3.444h1.75a2.796 2.796 0 001.638-.28 1.695 1.695 0 000-2.8zM42.434 49h-3.458l-.952-2.8h-4.83L32.2 49h-3.262l5.096-13.552h3.304L42.434 49zm-5.138-5.208l-1.694-4.858-1.68 4.858h3.374zm17.976 3.556a6.437 6.437 0 01-4.872 1.834 6.707 6.707 0 01-7.098-7 6.718 6.718 0 017.098-7 6.65 6.65 0 014.648 1.526l-.98 2.632a5.166 5.166 0 00-3.486-1.4 3.89 3.89 0 00-4.06 4.2 3.904 3.904 0 004.06 4.2 4.955 4.955 0 003.64-1.4l1.05 2.408zM68.936 49h-3.808l-3.388-5.6-1.078 1.204V49h-3.164V35.42h3.164v4.942l4.2-4.942H68.6l-4.76 5.39z" />
        </g>
    </Ic>
);

IC122ActionBack.propTypes = {
    /** The icon's primary color */
    color: colorType,
    /** The icon's secondary color */
    secondaryColor: colorType,
};

IC122ActionBack.displayName = 'IC122ActionBack';

export default IC122ActionBack;
