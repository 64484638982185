import React from 'react';
import styled from 'styled-components';
import {Svg} from 'normalized-styled-components';

const StyledSvg = styled(Svg)`
    display: block;
    width: auto;
    height: 100%; /* Scaled by HEIGHT :O */
`;

const GA116ParentalControlsPG = ({
    ...htmlAttributes
}) => (
    <StyledSvg
        {...htmlAttributes}
        viewBox="0 0 69 58"
    >
        <path fill="#FF0" d="M0 0h69v58H0z" />
        <path fill="#000" d="M26.358 22.059h-4.06v6.273h4.06c2.03 0 3.224-1.23 3.224-3.075 0-1.968-1.194-3.198-3.224-3.198zm.239 10.331h-4.298V41H18V18h8.597C31.254 18 34 21.32 34 25.257c0 3.813-2.746 7.133-7.403 7.133z" />
        <path fill="#000" d="M48.799 38.337C46.964 40.274 44.763 41 42.317 41c-2.446 0-4.525-.968-6.115-2.542-2.323-2.3-2.2-5.084-2.2-8.958 0-3.995-.123-6.779 2.2-9.079C37.792 18.847 39.75 18 42.317 18c5.381 0 8.072 3.39 8.683 7.263h-4.525c-.49-2.179-1.834-3.39-4.158-3.39-1.1 0-2.201.485-2.813 1.211-.856.969-1.1 2.058-1.1 6.416 0 4.358.244 5.326 1.1 6.295.612.726 1.712 1.21 2.813 1.21 1.345 0 2.446-.484 3.18-1.21.734-.848 1.1-1.937 1.1-3.027v-.847h-4.28v-3.753H51v3.269c0 3.268-.611 5.326-2.201 6.9z" />
        <path fill="#000" d="M55.59 13.447v31.106H13.41V13.447h42.18zM56.295 12H12v34h45V12h-.705z" />
    </StyledSvg>
);

GA116ParentalControlsPG.displayName = 'GA116ParentalControlsPG';

export default GA116ParentalControlsPG;
