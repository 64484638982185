import {useState, useEffect} from 'react';
import attempt from 'lodash/attempt';
import {
    trackFromAdobeDefinitions,
} from '../../../../../../todo-move-to-widgets-common/utils/adobe';

/**
 * This hook creates event callbacks for package selection
 *
 * @param {Object} options options
 * @param {String} options.defaultSelectedPackagePriceId default selected package price id
 * @param {boolean} options.isDisabled is package selector disabled?
 * @param {boolean} options.isLoading is package selector loading?
 * @param {Function} options.onChange callback when package has been selected
 * @param {Function} options.packages list of packages
 *
 * @returns {{onKeyDown: Function, onPackageSelect: Function, selectedPackagePriceId: String}} flags and callbacks
 */
const usePackageSelector = ({
    defaultSelectedPackagePriceId,
    isDisabled,
    isLoading,
    onChange,
    packages,
}) => {
    const [selectedPackagePriceId, setSelectedPackagePriceId] = useState(defaultSelectedPackagePriceId);

    /** Event callback for when user selects a package (via click or keyboard press)
     *
     * @param {String} packagePriceId price id of focused package
     */
    const onPackageSelect = (packagePriceId) => {
        if (isDisabled || isLoading) {
            return;
        }
        const newSelectedPackagePriceId = packagePriceId === selectedPackagePriceId
            ? null
            : packagePriceId;

        setSelectedPackagePriceId(newSelectedPackagePriceId);

        if (onChange) {
            attempt(onChange(newSelectedPackagePriceId));
        }
    };

    /**
     * Event callback for when user presses enter or spacebar
     * - it should select focused package
     *
     * @param {KeyboardEvent} event event
     * @param {String} packagePriceId price id of focused package
     */
    const onKeyDown = (event, packagePriceId) => {
        if (event.key === 'Enter' || event.key === ' ') { // Space key is ' '
            event.preventDefault(); // Stop the Space key from scrolling the page
            onPackageSelect(packagePriceId);
        }
    };

    useEffect(() => {
        // Find the package with the selectedPackagePriceId
        const selectedPackage = packages
            .find((selectedPackage) => selectedPackage.prices.some(
                (price) => price.priceId === selectedPackagePriceId
            ));

        if (selectedPackage) {
            trackFromAdobeDefinitions({
                definitionsPath: 'eventTracking.signUp.packageFocus',
                eventType: 'view',
                templateValues: {
                    package: selectedPackage.displayName,
                },
            });
        }
    }, [packages, selectedPackagePriceId]);

    return {
        onKeyDown,
        onPackageSelect,
        selectedPackagePriceId,
    };
};

export default usePackageSelector;
