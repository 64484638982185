import React from 'react';
import Ic from '..';

const IC107Pro = (args) => (
    <Ic {...args}>
        <path d="M27.32 28.782c-4.395 0-7.97 3.594-7.97 8.013v38.824c0 4.419 3.575 8.014 7.97 8.014h45.36c4.396 0 7.972-3.595 7.972-8.014V36.795c0-4.419-3.576-8.013-7.972-8.013H27.32zM72.68 87H27.32C21.08 87 16 81.894 16 75.619V36.795c0-6.275 5.079-11.38 11.32-11.38h45.36c6.242 0 11.321 5.105 11.321 11.38v38.824c0 6.275-5.079 11.381-11.32 11.381z" />
        <path d="M48.919 28.744c-.417 0-.833-.155-1.157-.466a1.69 1.69 0 0 1-.054-2.38L61.372 11.52a1.669 1.669 0 0 1 2.367-.055c.67.645.694 1.71.055 2.382L50.129 28.223a1.66 1.66 0 0 1-1.21.52" />
        <path d="M51.083 28.744a1.66 1.66 0 0 1-1.21-.521L36.208 13.847a1.69 1.69 0 0 1 .054-2.382 1.669 1.669 0 0 1 2.367.055l13.664 14.377a1.69 1.69 0 0 1-.053 2.38c-.324.312-.74.467-1.157.467" />
        <path d="M51.083 28.744a1.66 1.66 0 0 1-1.21-.521L36.208 13.847a1.69 1.69 0 0 1 .054-2.382 1.669 1.669 0 0 1 2.367.055l13.664 14.377a1.69 1.69 0 0 1-.053 2.38c-.324.312-.74.467-1.157.467" />
        <g transform="rotate(40 -16.58 88.455)">
            <ellipse
                cx="13.5"
                cy="21.5"
                opacity=".35"
                rx="13.5"
                ry="21.5"
            />
            <path d="M10.729 22.182h6.125" />
            <path fillRule="nonzero" d="M13.5 0c4.556 0 8.585 3.595 11.03 9.1l.26.609C26.188 13.095 27 17.146 27 21.5s-.813 8.405-2.21 11.791l-.26.608C22.085 39.405 18.056 43 13.5 43c-4.556 0-8.585-3.595-11.03-9.1l-.26-.609C.812 29.905 0 25.854 0 21.5s.813-8.405 2.21-11.791l.26-.608C4.915 3.595 8.944 0 13.5 0zm-.917 2.57c-3.565.545-6.822 4.227-8.621 9.578l-.206.64C2.959 15.385 2.5 18.34 2.5 21.5s.46 6.116 1.256 8.711l.206.641c1.799 5.35 5.056 9.033 8.621 9.578V28.431l-1.854.001a1.25 1.25 0 0 1-.128-2.494l.128-.006 1.854-.001v-2.5l-1.854.001a1.25 1.25 0 0 1-.128-2.494l.128-.006 1.854-.001v-2.5l-1.854.001a1.25 1.25 0 0 1-.128-2.494l.128-.006 1.854-.001V2.57zm2.5.14v13.22l1.771.001a1.25 1.25 0 0 1 .128 2.494l-.128.006-1.771-.001v2.5l1.771.001a1.25 1.25 0 0 1 .128 2.494l-.128.006-1.771-.001v2.5l1.771.001a1.25 1.25 0 0 1 .128 2.494l-.128.006-1.771-.001v11.86c3.302-.874 6.269-4.424 7.955-9.439l.206-.64c.797-2.596 1.256-5.551 1.256-8.712 0-3.16-.46-6.116-1.256-8.711l-.206-.641c-1.686-5.015-4.653-8.565-7.954-9.439z" />
        </g>
    </Ic>
);

IC107Pro.displayName = 'IC107Pro';

export default IC107Pro;
