import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';
import {A} from 'normalized-styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {akela, blanc, panther, vader} from '../../../../common/palette';
import {transition} from '../../../../common/animations';
import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';

const StyledLink = styled(A)`
    display: inline-block;
    transition: ${transition('box-shadow', 'background-color', 'color')};
    margin: 0;
    padding: 14px 22px;
    min-width: 236px;
    text-align: center;
    text-decoration: none;
    color: ${rgba(akela, 0.5)};
    font: var(--ionic-body-copy-2);

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding: 23px 34px;
        min-width: 386px;
    `}

    &:hover {
        color: ${akela};
    }

    &:focus {
        outline: 0;
        box-shadow: 0 1px 1px 0 ${rgba(panther, 0.3)};
        background-color: ${blanc};
        color: ${vader};
    }
`;

const BA30TextBtn = React.forwardRef(({
    children,
    className,
    href,
    ...htmlAttributes
}, ref) => (
    <StyledLink
        {...htmlAttributes}
        className={classnames(className, 'BA30TextBtn')}
        ref={ref}
        href={href}
    >
        {children}
    </StyledLink>
));

BA30TextBtn.displayName = 'BA30TextBtn';

BA30TextBtn.propTypes = {
    /** children elements */
    children: propTypes.node,
    /** passed down classnames */
    className: classNameType,
    /** CMS href */
    href: propTypes.string,
};

export default BA30TextBtn;
