import padEnd from 'lodash/padEnd';

/**
 * Split a price into its whole (e.g. dollars) and partial (e.g. cents) components.
 *
 * If the price is integer, only the whole amount will be returned.
 *
 * If the price is not an integer, the whole amount and the partial amount to the specified number of decimal places will be returned.
 *
 * If the specified number of decimal places is zero, only the whole amount will be returned.
 *
 * There is no rounding up.
 *
 * This returns the results as an array of strings to be used for display purposes.
 *
 * @param {number} [price=0]     - the price to display
 * @param {number} decimalPlaces - the number of decimal places
 * @returns {string[]} the stringified price components as described above
 */
export default function splitPrice(price = 0, decimalPlaces) {
    const [units, fraction] = price.toString().split('.');

    return decimalPlaces && parseInt(fraction) ? [units, padEnd(fraction, decimalPlaces, '0').slice(0, decimalPlaces)] : [units];
}
