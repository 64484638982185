import React from 'react';

import Ic from '..';

const IC179Roku = (args) => (
    <Ic {...args}>
        <path d="M21.8 45.9c0-2.2-1.8-4-3.9-4H16v7.9h1.9c2.1 0 3.9-1.7 3.9-3.9m8.8 15.7h-7.1l-5.7-7.9h-1.9v7.8H9.7V38h9c5.2 0 9.4 3.5 9.4 7.9 0 2.6-1.6 5-4.1 6.5l6.6 9.2" />
        <path d="M37.7 47.3c-1.7 0-3.1 2.3-3.1 5.1s1.4 5.1 3.1 5.1c1.7 0 3.1-2.3 3.1-5.1s-1.4-5.1-3.1-5.1m9.7 5.1c0 5.3-4.3 9.6-9.7 9.6-5.3 0-9.6-4.3-9.6-9.6s4.3-9.6 9.6-9.6c5.4 0 9.7 4.2 9.7 9.6m14.8-9.3L55 50.3V43h-6.3v18.5H55V54l7.6 7.5h7.9L60.8 52l8-8v11c0 3.6 2.2 7 7.7 7 2.6 0 5-1.5 6.2-2.8l2.8 2.4h1.3V43.1h-6.3V55c-.7 1.2-1.7 2-3.2 2-1.5 0-2.3-.9-2.3-3.8V43.1H62.2z" />
    </Ic>
);

IC179Roku.displayName = 'IC179Roku';

export default IC179Roku;
