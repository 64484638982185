import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import GA69HudCardBg from '../../../atoms/ga/69-hud-card-bg';
import {silverLining} from '../../../../common/deprecated-mui-palette';

const Container = styled.section`
    position: relative;
    border-radius: 4px;
    padding: 14px 7px;
    overflow: hidden;
`;

const Title = styled.h3`
    margin: 0 0 14px;
    text-align: center;
    color: ${silverLining};
    font: var(--ionic-hud-tile-header-bold);
`;

const OR114HudStatCard = ({
    title,
    className,
    children,
}) => (
    <Container className={classnames(className, 'OR114HudStatCard')}>
        <GA69HudCardBg />
        {!!title && (
            <Title>{title}</Title>
        )}
        {children}
    </Container>
);

OR114HudStatCard.propTypes = {
    /** An optional title for the stats card */
    title: propTypes.string,
    /** Optional extra className(s) */
    className: classNameType,
    /** Child nodes to render */
    children: propTypes.node,
};

OR114HudStatCard.displayName = 'OR114HudStatCard';

export default OR114HudStatCard;
