import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import {slate} from '../../../../common/palette';

import Ic from '..';

const IC270Remove = ({
    color = slate,
    ...args
}) => (
    <Ic {...args}>
        <g fill="none" fillRule="evenodd">
            <g stroke={color} strokeWidth="2.622">
                <path fill={color} fillOpacity=".35" d="M61.987 83H39.014c-3.597 0-6.513-2.948-6.513-6.584l-1.739-44.892h39.476L68.5 76.416c0 3.636-2.916 6.584-6.513 6.584z" />
                <path d="M70.758 24.225H30.244A2.256 2.256 0 0 0 28 26.492v4.878h45v-4.878c0-1.252-1.004-2.267-2.242-2.267zm-27.54-.838v-3.632c0-.97.777-1.755 1.735-1.755h9.792c.958 0 1.735.786 1.735 1.755v3.632" />
                <path strokeLinecap="round" d="M49.849 44.912v22.263m-7.697-22.263v22.263m15.513-22.263v22.263" />
            </g>
        </g>
    </Ic>
);

IC270Remove.propTypes = {
    /** The icon's primary color */
    color: colorType,
};

IC270Remove.displayName = 'IC270Remove';

export default IC270Remove;
