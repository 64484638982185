import {BASE_DEFAULT_SETTINGS, BASE_BILLING_HOSTED_PAYMENT_PAGE, BRAND, ENVIRONMENT} from '../constants';
import {getBrandBillingHostedPaymentPage, getBrandEnvsFromWidgetSettings} from '../envs';

/** * @type {Object.<Brand, Object.<Environment, string>>} */
export const BILLING_HOSTED_PAYMENT_PAGE = {
    [BRAND.BINGE]: {
        ...BASE_BILLING_HOSTED_PAYMENT_PAGE[BRAND.BINGE],
    },
    [BRAND.LIFESTYLE]: {
        ...BASE_BILLING_HOSTED_PAYMENT_PAGE[BRAND.LIFESTYLE],
    },
    [BRAND.FLASH]: {
        ...BASE_BILLING_HOSTED_PAYMENT_PAGE[BRAND.FLASH],
    },
    [BRAND.KAYO]: {
        ...BASE_BILLING_HOSTED_PAYMENT_PAGE[BRAND.KAYO],
    },
};

//  ! NOTE: The following URL was previously set to be the same as Binge, I am changing it to be non-branded

/** * @type {Object.<Environment, string>} */
export const UNBRANDED_RESOURCES_URL = {
    [ENVIRONMENT.production]: 'https://resources.streamotion.com.au/production',
    [ENVIRONMENT.staging]:    'https://resources.streamotion.com.au/staging',
};

// first env is the default  (why do we have other non-defaults? they're a whitelist of allowed envs)
export const DEFAULT_ENV_SETTINGS = {
    ...BASE_DEFAULT_SETTINGS,
};

/**
 * Get the hosted payment page ID for the specified platform environment and origin.
 *
 * @param {Object} options             - (see below)
 * @param {string} options.brand       - brand
 * @param {string} options.platformEnv - platform environment
 * @param {string} options.origin      - origin
 * @returns {string|undefined} the ID of the payment page ID if found
 */
function getBillingHostedPaymentPage({brand, platformEnv, origin}) {
    return getBrandBillingHostedPaymentPage(BILLING_HOSTED_PAYMENT_PAGE, [brand, platformEnv, origin]);
}

/**
 * Given a settings object (e.g. as passed to a fiso widget), return a new
 * object containing ONLY environment properties with default environments
 *
 * @param {Object} settings Settings object, e.g. as passed in to FISO
 * @returns {Object} object only containing environments, where they have been defaulted when invalid/not provided
 */
function envsFromWidgetSettings(settings) {
    return getBrandEnvsFromWidgetSettings(DEFAULT_ENV_SETTINGS, settings);
}

export default {
    UNBRANDED_RESOURCES_URL,
    DEFAULT_ENV_SETTINGS,
    BILLING_HOSTED_PAYMENT_PAGE,
    getBillingHostedPaymentPage,
    envsFromWidgetSettings,
};
