import React, {useEffect, useRef, useState, useMemo} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Abbr, Button, Nav} from 'normalized-styled-components';
import classnames from 'classnames';
import {format, addDays, isBefore, isSameDay, isToday} from 'date-fns';
import invoke from 'lodash/invoke';
import noop from 'lodash/noop';
import get from 'lodash/get';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';

import {stylesIfElse, stylesWhen, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {colorType, classNameType} from '@fsa-streamotion/custom-prop-types';
import {isBrowser} from '@fsa-streamotion/browser-utils';

import {white} from '../../../../common/palette';
import {black, ironStone, kayoGreen, silverLining} from '../../../../common/deprecated-mui-palette';
import IC24ArrowL from '../../../atoms/ic/24-arrow-l';
import IC25ArrowR from '../../../atoms/ic/25-arrow-r';
import {transition} from '../../../../common/animations';

const CarouselWrapper = styled(Nav)`
    display: flex;
    justify-content: space-between;
    overflow: hidden;
`;

const ScrollButton = styled(Button).attrs({
    tabIndex: -1,
})`
    appearance: none;
    display: flex;
    position: relative;
    justify-content: ${({isRight}) => isRight ? 'flex-end' : 'flex-start'};
    order: ${({isRight}) => isRight ? 3 : 1};
    outline: 0;
    border: 0;
    background: transparent;
    padding-bottom: 7px;

    ${stylesWhen('isHidden')`
        visibility: hidden;
    `}

    &::after {
        position: absolute;
        background-image: ${({backgroundColor, isRight}) => `linear-gradient(to ${isRight ? 'left' : 'right'}, ${backgroundColor} 30%, transparent)`};
        width: 50px;
        height: 100%;
        content: '';

        ${stylesIfElse('isRight')`
            left: -50px;
        ``
            right: -50px;
        `}
    }
`;

const ItemsWrapper = styled.ol`
    display: flex;
    order: 2;
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: scroll;
    list-style: none;
    scroll-snap-type: x mandatory;
    scroll-snap-stop: always;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::before,
    &::after {
        min-width: 50%;
        content: '';
        pointer-events: none;
        scroll-snap-align: none;
    }

    &::-webkit-scrollbar {
        display: none;
    }
`;

const NavItem = styled(Button)`
    appearance: none;
    position: relative;
    transition: ${transition('color', 'text-shadow')};
    margin: 0 8px;
    outline: 0;
    border: 0;
    background: transparent;
    max-width: 120px;
    height: 36px;
    overflow: hidden;
    text-transform: uppercase;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${({isSelected}) => isSelected ? white : silverLining};
    font: var(--ionic-body-copy-3);
    scroll-snap-align: center;

    ${stylesWhenNot('isSelected')`
        text-shadow: 0 1px 1px ${black};
        font-weight: 300;
    `}

    &:hover,
    &:focus {
        text-shadow: none;
        color: ${white};
    }

    &::after {
        position: absolute;
        right: 4px;
        bottom: 0;
        left: 4px;
        transition: ${transition('transform')};
        border-radius: 1px;
        background-color: ${kayoGreen};
        height: 2px;
        content: '';

        ${stylesWhenNot('isSelected')`
            transform: scaleX(0);
        `}
    }
`;

const StyledAbbr = styled(Abbr)`
    &[title] {
        text-decoration: none;
    }
`;

const createDateItems = (startDate, endDate) => {
    const dates = [];
    let d = startDate;

    while (isBefore(d, endDate) || isSameDay(d, endDate)) {
        dates.push(d);
        d = addDays(d, 1);
    }

    return dates.map((date) => ({
        label: isToday(date) ? 'Today' : format(date, 'E dd MMM'),
        value: date,
        accessibleText: isToday(date) ? 'Today' : format(date, 'EEEE do MMMM'),
    }));
};

export default function CAM04Range({
    items,
    onSelectItem = noop,
    selectedItemValue,
    backgroundColor = ironStone,
    startDate,
    endDate,
    className,
}) {
    const isDateMode = !items;
    const normalisedItems = items || createDateItems(startDate, endDate);
    const selectedItemIndex = normalisedItems.findIndex(({value}) => isDateMode
        ? isSameDay(selectedItemValue, value)
        : value === selectedItemValue);
    const [hasDoneFirstScroll, setHasDoneFirstScroll] = useState(false);
    const [focusedItemIndex, setFocusedItemIndex] = useState(selectedItemIndex);
    const previousItemRef = useRef(null);
    const focusedItemRef = useRef(null);
    const nextItemRef = useRef(null);

    const refs = [previousItemRef, focusedItemRef, nextItemRef];

    const isAnimated = useMemo(
        () => isBrowser() && !window.matchMedia('(prefers-reduced-motion: reduce), (update: slow)').matches,
        []
    );

    useEffect(() => {
        const currentRef = get(focusedItemRef, 'current');

        if (!currentRef) {
            return;
        }

        // Firefox interrupts my smooth scrolling without await
        scrollIntoView(currentRef, {
            behavior: hasDoneFirstScroll && isAnimated.current ? 'smooth' : 'auto',
            block: 'nearest',
            inline: 'center',
        })
            .then(() => {
                if (!hasDoneFirstScroll) {
                    setHasDoneFirstScroll(true);
                }
            });
    }, [focusedItemIndex, hasDoneFirstScroll]); // eslint-disable-line react-hooks/exhaustive-deps

    // When selectedItemIndex changes, set that guy as the focused item
    useEffect(() => {
        setFocusedItemIndex(selectedItemIndex);
    }, [selectedItemIndex]);

    return (
        <CarouselWrapper
            className={classnames('CAM04Range', className)}
            onKeyDown={(e) => {
                if (e.key === 'ArrowLeft') {
                    e.preventDefault();
                    e.stopPropagation();
                    invoke(previousItemRef, 'current.focus');
                } else if (e.key === 'ArrowRight') {
                    e.preventDefault();
                    e.stopPropagation();
                    invoke(nextItemRef, 'current.focus');
                }
            }}
        >
            <ItemsWrapper>
                {normalisedItems
                    .map(({label, value, accessibleText}, index) => {
                        const isSelected = selectedItemIndex === index;

                        return (
                            <li key={label}>
                                <NavItem
                                    onFocus={() => {
                                        // set focussed item for both keyboard & mouse selection
                                        setFocusedItemIndex(index);
                                    }}
                                    onKeyDown={(event) => {
                                        // only for keyboard selection
                                        if (event.key === 'Enter' || event.key === ' ') {
                                            onSelectItem({label, value, index, event});
                                        }
                                    }}
                                    onMouseDown={(event) => {
                                        // If you click too slowly, the item index changes and it begins to scroll, and onClick will never fire. So do it on mouse down.
                                        onSelectItem({label, value, index, event});
                                    }}
                                    onClick={(event) => {
                                        // don’t do anything if you’re a quick clicker
                                        event.preventDefault();
                                    }}
                                    tabIndex={isSelected ? 0 : -1}
                                    isSelected={isSelected}
                                    ref={refs[index - focusedItemIndex + 1] || null}
                                >
                                    {accessibleText ? (
                                        <StyledAbbr title={accessibleText}>{label}</StyledAbbr>
                                    ) : label}
                                </NavItem>
                            </li>
                        );
                    })}
            </ItemsWrapper>
            <ScrollButton
                isHidden={selectedItemIndex === 0}
                isRight={false}
                backgroundColor={backgroundColor}
                tabIndex={-1}
                onClick={() => {
                    const newIndex = Math.max(selectedItemIndex - 1, 0);

                    onSelectItem({...normalisedItems[newIndex], index: newIndex});
                }}
            >
                <IC24ArrowL
                    ariaLabel="Scroll left"
                    size="16px"
                    color={white}
                />
            </ScrollButton>
            <ScrollButton
                isHidden={selectedItemIndex === normalisedItems.length - 1}
                isRight={true}
                backgroundColor={backgroundColor}
                tabIndex={-1}
                onClick={() => {
                    const newIndex = Math.min(selectedItemIndex + 1, normalisedItems.length - 1);

                    onSelectItem({...normalisedItems[newIndex], index: newIndex});
                }}
            >
                <IC25ArrowR
                    ariaLabel="Scroll right"
                    size="16px"
                    color={white}
                />
            </ScrollButton>
        </CarouselWrapper>
    );
}

CAM04Range.displayName = 'CAM04Range';

CAM04Range.propTypes = {
    /** Items to display if date props not used */
    items: propTypes.arrayOf(propTypes.shape({
        label: propTypes.string,
        value: propTypes.any,
    })),
    /** Callback on select */
    onSelectItem: propTypes.func,
    /** Value of selected item */
    selectedItemValue: propTypes.any,
    /** Background color of carousel buttons */
    backgroundColor: colorType,
    /** Start date of list of dates to generate */
    startDate: propTypes.object,
    /** End date of list of dates to generate */
    endDate: propTypes.object,
    /** Additional CSS classnames to be applied */
    className: classNameType,
};
