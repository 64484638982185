import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import VisuallyHidden from '../../../../common/visually-hidden';
import formatCurrency from '../../../../common/format-currency';
import {black, ink} from '../../../../common/palette';
import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';

const Table = styled.table`
    border: 0;
    width: 100%;
    border-spacing: 0;
    color: ${rgba(ink, 0.7)};
    font: var(--quicksilver-body-copy-3);
`;

const Th = styled.th`
    padding: 0;
    width: 110px;
    text-align: left;
    font: var(--quicksilver-body-copy-3-medium);

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 159px;
    `}
`;

const DetailsTd = styled.td`
    overflow-x: hidden;
    white-space: nowrap;
    mask-image: linear-gradient(to left, transparent 7px, ${black} 35px);
`;

const DateTd = styled.td`
    width: 85px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 115px;
    `}
`;

const PriceTd = styled.td`
    width: 49px;
    text-align: right;
    font: var(--quicksilver-body-copy-3-medium);

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 83px;
    `}
`;

// A table displaying transactions in the user's account.
const MyAccountTables = ({
    billingSummary = [],
    isTransactionView = true,
}) => (
    <Table>
        <VisuallyHidden as="caption">Billing Summary</VisuallyHidden>
        <VisuallyHidden as="thead">
            <tr>
                <th scope="col">Heading</th>
                <th scope="col">Details</th>
                {isTransactionView && <th scope="col">Date</th>}
                <th scope="col">Amount</th>
            </tr>
        </VisuallyHidden>
        <tbody>
            {billingSummary.map(({heading, details, date, amount}, index) => (
                <tr key={index}>
                    {/* Only display first heading for transaction view */}
                    <Th scope="row">{(index === 0 || !isTransactionView) && heading}</Th>
                    <DetailsTd>{details}</DetailsTd>
                    {isTransactionView && <DateTd>{date}</DateTd>}
                    <PriceTd>{formatCurrency({amount})}</PriceTd>
                </tr>
            ))}
        </tbody>
    </Table>
);

MyAccountTables.displayName = 'MyAccountTables';

MyAccountTables.propTypes = {
    /** Array of billing transactions */
    billingSummary: propTypes.arrayOf(
        propTypes.shape({
            /** Table heading */
            heading: propTypes.string,
            /** Billing summary details */
            details: propTypes.string,
            /** Date of transaction */
            date: propTypes.string,
            /** Total amount */
            amount: propTypes.number,
        }),
    ),
    /** Is it transaction view?  */
    isTransactionView: propTypes.bool,
};

export default MyAccountTables;
