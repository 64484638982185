import React from 'react';

import Ic from '..';

const IC04Vol = (args) => (
    <Ic {...args}>
        <path
            opacity=".35"
            d="M21.075 64c3.148 2.96 26.83 17.428 26.851 17.42L48 20.496c-.021-.004-.048-2.62-.074-2.622C37.076 26.609 25.154 32.659 22 35.619L21.075 64z"
        />
        <path d="m11 37.7 4-2v28l-4-2z" />
        <path
            fillRule="nonzero"
            d="M50.272 17.253A4 4 0 0 1 51 19.554v59.753a4 4 0 0 1-6.288 3.282L19 64.664V34.358l25.699-18.075a4 4 0 0 1 5.573.97zM47 19.554l-24 16.88v26.142l24 16.731V19.554z"
        />
        <g fillRule="nonzero" >
            <path d="M59.29 33.964a2 2 0 0 1 2.747-.675C67.546 36.624 71 42.755 71 49.5c0 6.745-3.454 12.876-8.964 16.211a2 2 0 0 1-2.072-3.422C64.277 59.679 67 54.846 67 49.5s-2.723-10.179-7.035-12.79a2 2 0 0 1-.675-2.746z" />
            <path d="M66.285 23.976a2 2 0 0 1 2.742-.694C78.223 28.767 84 38.87 84 50s-5.779 21.235-14.976 26.719a2 2 0 1 1-2.048-3.436C74.966 68.518 80 59.716 80 50c0-9.715-5.032-18.516-13.022-23.281a2 2 0 0 1-.693-2.742z" />
        </g>
    </Ic>
);

IC04Vol.displayName = 'IC04Vol';

export default IC04Vol;
