import get from 'lodash/get';

/**
 * Takes a panel data contents array and massages it into two arrays representing the first and second row for the double double
 * carousel. Each tile in the array contains a portrait image, a landscape image and a title. The rules are:
 *   1. If there's less than or equal to one row of tiles (i.e. no overflow), only show one row, filling left to right
 *   2. If there's more than one row of tiles but less than or equal to two, fill the first row then the second, left to right
 *   3. If there's more tiles than can be visible on two rows, put the first half in the first row and second half in the second row
 *
 * @param {Object}  panelDataContents   An object representing a single "panel" from the API
 * @param {number}  maxVisibleTilesPerRow     Maximum number of visible tiles per row in the carousel
 *
 * @returns {Object} contains {data, firstRow [, secondRow]}
 */
export default function massageToDouble(panelDataContents, maxVisibleTilesPerRow) {
    if (!panelDataContents) {
        return {};
    }

    const tilesCount = panelDataContents.length;
    const isTwoRows = tilesCount > maxVisibleTilesPerRow;
    const isLessThanTwoFullRows = isTwoRows && tilesCount < maxVisibleTilesPerRow * 2;

    return panelDataContents.reduce((carouselRows, item, index) => {
        const {data} = item;
        const {images, title} = get(data, 'contentDisplay');
        const {firstRow, secondRow} = carouselRows;

        const isFirstRow = (
            !isTwoRows                                                    // if there's only one row it has to be the first
            || (isLessThanTwoFullRows && index < maxVisibleTilesPerRow)   // less than two rows and can fit on the first row without overflow
            || index < tilesCount / 2                                     // in the first half, rounded down
        );

        const tile = {image: images.tile, imagePortrait: images.tilePortrait, title: title.value};

        return isFirstRow
            ? {data, firstRow: [...firstRow, tile], secondRow}
            : {data, firstRow, secondRow: [...secondRow, tile]};
    }, isTwoRows ? {firstRow: [], secondRow: []} : {firstRow: []});
}
