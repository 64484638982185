import React from 'react';
import Ic from '..';

const IC140PlayStat = (args) => (
    <Ic {...args}>
        <path fillRule="nonzero" d="M87.6 70L70.4 58.7c4.3-4.2 7-10.4 7-17.3 0-2-.2-4-.7-5.9-2.4-10-10.4-16.9-19.5-16.9-9.7 0-18 7.8-19.8 18.6-.3 1.4-.4 2.8-.4 4.2 0 7.1 2.9 13.4 7.3 17.6L26.9 69.6c-.8.5-1.3 1.4-1.3 2.4v6.9c0 1.5 1.2 2.8 2.8 2.8H86c1.5 0 2.8-1.2 2.8-2.8v-6.5c0-1-.5-1.9-1.2-2.4zM42.2 41.4c0-1.1.1-2.2.3-3.4 1.4-8.2 7.6-14.2 14.7-14.2 6.7 0 12.6 5.3 14.4 12.9.4 1.5.5 3.1.5 4.7 0 9.7-6.7 17.6-15 17.6s-14.9-7.9-14.9-17.6zm41.1 34.7H31.1v-2.5l18.3-11c2.4 1.1 5 1.8 7.8 1.8 3 0 5.8-.8 8.4-2.1L83.3 74v2.1zM11.2 29.8h22.7v2.8H11.2v-2.8zm6.2 8.5h16.5v2.8H17.4v-2.8zm-6.2-18.6h22.7v4.2H11.2v-4.2z" />
    </Ic>
);

IC140PlayStat.displayName = 'IC140PlayStat';

export default IC140PlayStat;
