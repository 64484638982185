import {css} from 'styled-components';

export const SCREEN_PHONE = 0;
export const SCREEN_375_PHABLET = 375;
export const SCREEN_415_PHABLET = 415;
export const SCREEN_568_MOBILE_LANDSCAPE = 568;
export const SCREEN_768_TABLET = 768;
export const SCREEN_1024_DESKTOP = 1024;
export const SCREEN_1280_DESKTOP = 1280;
export const SCREEN_1400_DESKTOP = 1400;
export const SCREEN_1480_DESKTOP = 1480;
export const SCREEN_1680_DESKTOP = 1680;
export const SCREEN_1920_DESKTOP = 1920;
export const SCREEN_2560_DESKTOP = 2560;

export const SMALLEST_SUPPORTED_WIDTH = 320;

export const SCREEN_PORTRAIT = 'portrait';
export const SCREEN_LANDSCAPE = 'landscape';

const screenMinPixel = {
    SCREEN_415_PHABLET,
    SCREEN_768_TABLET,
    SCREEN_1024_DESKTOP,
    SCREEN_1280_DESKTOP,
    SCREEN_2560_DESKTOP,
};

// https://www.styled-components.com/docs/advanced#media-templates
const MEDIA = Object.keys(screenMinPixel)
    .reduce((acc, label) => Object.assign(acc, {
        [label]: (...args) => css`
            @media (min-width: ${screenMinPixel[label]}px) {
                ${css(...args)}
            }
        `,
    }), {});

export default {
    SCREEN_PHONE,
    SCREEN_375_PHABLET,
    SCREEN_415_PHABLET,
    SCREEN_568_MOBILE_LANDSCAPE,
    SCREEN_768_TABLET,
    SCREEN_1024_DESKTOP,
    SCREEN_1280_DESKTOP,
    SCREEN_1480_DESKTOP,
    SCREEN_1680_DESKTOP,
    SCREEN_1920_DESKTOP,
    SCREEN_2560_DESKTOP,
    SMALLEST_SUPPORTED_WIDTH,
    SCREEN_PORTRAIT,
    SCREEN_LANDSCAPE,
    MEDIA,
};
