import React from 'react';

import Ic from '..';

const IC229Settings = (args) => (
    <Ic {...args}>
        <g fillRule="nonzero">
            <path opacity=".35" d="M55.777 15.705H44.22l-2.869 8.083-.824.297c-.563.204-1.137.435-1.729.695l-.447.2-.785.36-7.743-3.675-8.149 8.152 3.678 7.762-.367.788c-.25.538-.48 1.078-.689 1.616l-.2.537-.298.825-8.096 2.871v11.555l8.083 2.857.297.826c.205.567.44 1.145.721 1.777l.175.387.358.784-3.676 7.757 8.151 8.164 7.742-3.676.785.36c.6.274 1.183.52 1.752.737l.425.158.824.297 2.87 8.097h11.552L58.62 76.2l.826-.298c.536-.194 1.076-.41 1.614-.647l.539-.244.788-.367 7.764 3.681 8.171-8.131-3.672-7.753.359-.784c.3-.657.551-1.25.768-1.823l.127-.341.297-.825 8.095-2.87V44.242l-8.095-2.871-.297-.825a25.59 25.59 0 0 0-.647-1.615l-.243-.538-.367-.789 3.678-7.762-8.152-8.165-7.76 3.679-.789-.367c-.537-.25-1.077-.481-1.615-.69l-.537-.2-.825-.298-2.87-8.096zM49.805 34.13c8.66 0 15.68 7.021 15.68 15.685-.01 8.656-7.023 15.67-15.68 15.68-8.66 0-15.68-7.02-15.68-15.682s7.02-15.683 15.68-15.683z" />
            <path d="M41.607 12h16.787l3.164 8.931.402.16.421.18 8.567-4.06L82.789 29.07l-4.059 8.566.18.421.161.402L88 41.624v16.79l-8.929 3.167-.145.359-.197.463 4.062 8.57-11.873 11.814-8.56-4.058-.424.18-.399.161L58.383 88h-16.79l-3.165-8.928-.386-.156-.446-.188-8.556 4.062-11.842-11.86 4.059-8.565-.196-.464-.145-.357L12 58.39V41.599l8.928-3.166.161-.402.18-.421-4.059-8.567 11.844-11.845 8.554 4.062.447-.189.245-.1.142-.055L41.607 12zm14.17 3.705H44.22l-2.869 8.083-.824.297c-.563.204-1.137.435-1.729.695l-.447.2-.785.36-7.743-3.675-8.149 8.152 3.678 7.762-.367.788c-.25.538-.48 1.078-.689 1.616l-.2.537-.298.825-8.096 2.871v11.555l8.083 2.857.297.826c.205.567.44 1.145.721 1.777l.175.387.358.784-3.676 7.757 8.151 8.164 7.742-3.676.785.36c.6.274 1.183.52 1.752.737l.425.158.824.297 2.87 8.097h11.552L58.62 76.2l.826-.298c.536-.194 1.076-.41 1.614-.647l.539-.244.788-.367 7.764 3.681 8.171-8.131-3.672-7.753.359-.784c.3-.657.551-1.25.768-1.823l.127-.341.297-.825 8.095-2.87V44.242l-8.095-2.871-.297-.825a25.59 25.59 0 0 0-.647-1.615l-.243-.538-.367-.789 3.678-7.762-8.152-8.165-7.76 3.679-.789-.367c-.537-.25-1.077-.481-1.615-.69l-.537-.2-.825-.298-2.87-8.096zM49.805 34.13c8.66 0 15.68 7.021 15.68 15.685-.01 8.656-7.023 15.67-15.68 15.68-8.66 0-15.68-7.02-15.68-15.682s7.02-15.683 15.68-15.683zm0 3.704c-6.614 0-11.976 5.363-11.976 11.979 0 6.615 5.362 11.978 11.974 11.978 6.613-.007 11.971-5.367 11.979-11.978 0-6.616-5.362-11.979-11.977-11.979z" />
        </g>
    </Ic>
);

IC229Settings.displayName = 'IC229Settings';

export default IC229Settings;
