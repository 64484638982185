import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {black, silverLining} from '../../../../common/deprecated-mui-palette';
import GA89Tile from '../../../atoms/ga/89-tile';
import IC191Gift from '../../../atoms/ic/191-gift';

const Container = styled(GA89Tile)`
    display: grid;
    grid-template-columns: minmax(0, 1fr) min-content;
    grid-gap: 14px;
    align-items: center;
    padding: 5px;
    width: 100%;
    color: ${silverLining};
    font: var(--ionic-body-copy-3);
`;

const Rows = styled.div`
    padding: 0 9px;
`;

const Title = styled.h3`
    margin: 0;
    font: var(--ionic-body-copy-3);
`;

const BadgeContainer = styled.div`
    display: grid;
    align-items: center;
    align-self: stretch;
    justify-items: center;
    border-radius: 4px;
    background-color: ${black};
    padding: 7px;
    width: 65px;
    min-height: 49px;
    text-align: center;
    font: var(--ionic-info-tile-badge);
`;

const TM26GiftInfo = ({
    className,
    accountBalance,
}) => (
    <Container className={classnames('TM26GiftInfo', className)}>
        <Rows>
            <Title>
                Kayo Gift Card
            </Title>

            Balance remaining: {accountBalance}
        </Rows>

        <BadgeContainer>
            <IC191Gift size="21px" />
            Gift Card applied
        </BadgeContainer>
    </Container>
);

TM26GiftInfo.propTypes = {
    /** Additional class(es) */
    className: classNameType,
    /** Account Balance eg. $25 */
    accountBalance: propTypes.node,
};

TM26GiftInfo.displayName = 'TM26GiftInfo';

export default TM26GiftInfo;
