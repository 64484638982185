import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import BA02FilBtn from '../../../atoms/ba/02-fil-btn';

import CAM01Stand from '../../../molecules/cam/01-stand';
import TM12EpiTile from '../../../molecules/tm/12-epi-tile';

import OR42TagSelec from '../42-tag-selec';

const OuterContainer = styled.section`
    position: relative;
    width: 100%;
`;

/**
 * @deprecated Due to the way VCC is organising its panels, this component which combines OR42TagSelec and CAM01Stand is untenable
 *
 * @param {Object} options - takes some deprecated stuff
 * @param {Array} options.episodeTiles episode titles
 * @param {Array} options.seasonList season list
 * @returns {Function} OR60SeaEpi element
 */
const OR60SeaEpi = ({
    episodeTiles = [],
    seasonList = [],
}) => (
    !!(seasonList.length || episodeTiles.length) && (
        <OuterContainer className="OR60SeaEpi">
            {!!seasonList.length && (
                <OR42TagSelec>
                    {seasonList.map(({href, isSelected, onClick, title}) => (
                        <BA02FilBtn
                            key={title}
                            {...{
                                href,
                                isSelected,
                                onClick,
                            }}
                        >
                            {title}
                        </BA02FilBtn>
                    ))}
                </OR42TagSelec>
            )}
            {!!episodeTiles.length && (
                <CAM01Stand variant="no-loop">
                    {episodeTiles.map(({description, srcsetOptions, onClick, runtime, showName, title}) => (
                        <TM12EpiTile
                            key={title}
                            {...{
                                description,
                                srcsetOptions,
                                onClick,
                                runtime,
                                showName,
                                title,
                            }}
                        />
                    ))}
                </CAM01Stand>
            )}
        </OuterContainer>
    )
);

OR60SeaEpi.displayName = 'OR60SeaEpi';

OR60SeaEpi.propTypes = {
    /** Array of objects for episode tiles */
    episodeTiles: propTypes.arrayOf(propTypes.shape({
        description: propTypes.string,
        srcsetOptions: propTypes.object,
        onClick: propTypes.func,
        runtime: propTypes.string,
        showName: propTypes.string,
        title: propTypes.string,
    })),
    /** Array of data for season list */
    seasonList: propTypes.arrayOf(propTypes.shape({
        href: propTypes.string,
        isSelected: propTypes.bool,
        onClick: propTypes.func,
        title: propTypes.string,
    })),
};

export default OR60SeaEpi;
