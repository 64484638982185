import superagent from 'superagent';
import Cache from 'js-cache';

/**
 * @typedef {Object} Repository
 * @property {(string) => Promise} makeEntitledTokenRequest - Makes a POST request to get an access_token (Entitled)
 * @property {(string) => Promise} makePasswordResetRequest - Makes a POST request to reset password
 */

/**
 * @typedef {Object} ResourceApi
 * @property {string} baseUrl - The base URL of the resource API
 */

export const TOKEN_CACHE_KEY = 'entitled.token';
export const CACHE_TTL_MS = 2500;

/**
 * Repository of APIs used to perform authentication user functionality
 *
 * @param {Object} options                      - (See below)
 * @param {string} options.clientId             - https://auth0.github.io/auth0-spa-js/interfaces/auth0clientoptions.html#client_id
 * @param {string} options.scope                - https://auth0.github.io/auth0-spa-js/interfaces/auth0clientoptions.html#scope
 * @param {string} options.realm                - https://auth0.github.io/auth0-spa-js/interfaces/auth0clientoptions.html#connection
 * @param {ResourceApi} options.tokenServiceApi - Token Service API config
 * @param {ResourceApi} options.auth0TenantApi  - Auth0 Tenant API config
 * @param {number} [options.cacheTtlMs]         - TTL for Token Service API response (defaults to 2.5 seconds)
 * @returns {Repository}                        - Returns the repository
 */
export default function createRepository({
    clientId,
    scope,
    realm,
    tokenServiceApi,
    auth0TenantApi,
    cacheTtlMs = CACHE_TTL_MS,
}) {
    const cache = new Cache();

    /**
     * Captures the first response or error and caches for (n) TTL.
     * This ensures that when an error is thrown from an API we cache the response and
     * limit all subsequent calls.
     *
     * @param {Function} callback           - Function to call
     * @param {string} cacheKey             - Cache key
     * @returns {Promise<Object | Error>}   - Resolves response or error
     */
    const withCache = (callback, cacheKey) => new Promise((resolve, reject) => {
        const cachedValue = cache.get(cacheKey);

        if (cachedValue) {
            return cachedValue instanceof Error
                ? reject(cachedValue)
                : resolve(cachedValue);
        }

        return callback()
            .then((value) => {
                cache.set(cacheKey, value, cacheTtlMs);
                resolve(value);
            })
            .catch((error) => {
                cache.set(cacheKey, error, cacheTtlMs);
                reject(error);
            });
    });

    return {
        makeEntitledTokenRequest: (auth0AccessToken) => withCache(async () => {
            const response = await superagent
                .post(`${tokenServiceApi.baseUrl}/oauth/token`)
                .set('Authorization', `Bearer ${auth0AccessToken}`)
                .accept('json')
                .send({
                    client_id: clientId,
                    scope,
                });

            return response.body;
        }, TOKEN_CACHE_KEY),
        makePasswordResetRequest: (email) => superagent
            .post(`${auth0TenantApi.baseUrl}/dbconnections/change_password`)
            .send({
                client_id: clientId,
                connection: realm, // two different names for the same thing in auth0 apparently
                email,
            }),
    };
}
