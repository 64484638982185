import React from 'react';

import Ic from '..';

const IC258SurpriseMe = (args) => (
    <Ic {...args}>
        <path d="M66.284 55.017c-.17-.239-.445-.38-.739-.38H34.637c-.293 0-.569.141-.739.38l-4.545 6.364c-.198.277-.225.64-.069.944.156.302.467.493.808.493h3.635v11.818c0 1.004.814 1.818 1.818 1.818h29.092c1.004 0 1.818-.814 1.818-1.818V62.818h3.637c.34 0 .652-.19.807-.493.156-.303.13-.667-.068-.944l-4.546-6.364zm-31.18 1.437h21.403L53.26 61H31.857l3.247-4.546zm.441 6.364h18.182c.293 0 .569-.142.74-.381l2.896-4.055v16.254H35.545V62.818zm29.092 11.818h-5.454V58.382l2.897 4.055c.17.239.446.38.739.38h1.818v11.82zM65.547 61h-2.26l-3.247-4.546h5.038L68.325 61h-2.779zM49.405 41.637l.163.286c.125.224.058.506-.155.649-.623.415-1.006 1.107-1.027 1.856-.02.749.323 1.461.923 1.91l.995.746c.22.171.26.487.091.708l-1.083 1.839c-.175.28-.184.633-.024.921.16.29.464.469.794.469s.634-.178.795-.467l1.057-1.797c.68-1.02.445-2.391-.534-3.127l-1-.746c-.128-.095-.2-.246-.196-.405.004-.16.085-.306.218-.395.91-.613 1.263-1.783.843-2.798C52.992 40 57.363 36.219 57.363 31c0-4.017-3.256-7.273-7.272-7.273-4.017 0-7.273 3.256-7.273 7.273 0 5.847 5.48 9.88 6.587 10.636zm.686-16.092c3.01.003 5.451 2.444 5.454 5.455 0 4.436-4.033 7.829-5.454 8.888-1.424-1.056-5.455-4.434-5.455-8.888.003-3.011 2.443-5.452 5.455-5.455z" />
        <path d="M50.091 29.182c1.004 0 1.818.814 1.818 1.818 0 .502.407.909.909.909s.909-.407.909-.91c0-2.008-1.628-3.636-3.636-3.636-.502 0-.91.407-.91.91 0 .502.408.909.91.909zm19.096-1.819c-5.24 0-7.626 4.615-7.626 8.903.058 2.506.64 4.972 1.709 7.24v.326c-.002.256-.2.467-.455.486-.748.047-1.427.455-1.82 1.093-.392.638-.45 1.428-.155 2.117l.49 1.145c.026.06.039.126.039.191 0 .187-.105.358-.273.44-.434.23-.606.764-.389 1.205.218.44.746.629 1.193.425 1.098-.543 1.58-1.849 1.1-2.975l-.492-1.141c-.027-.061-.041-.127-.042-.194.001-.258.202-.472.46-.489 1.103-.077 1.997-.925 2.132-2.023 2.733-.385 11.396-2.17 11.396-9.469-.02-4.007-3.26-7.253-7.268-7.28zm-4.46 14.952c-.838-1.91-1.296-3.964-1.348-6.049 0-3.526 1.796-7.084 5.807-7.084 3.006.02 5.436 2.455 5.45 5.461 0 5.83-7.65 7.351-9.909 7.672z" />
        <path d="M71 31.487c-.435-.25-.992-.101-1.243.334-.25.435-.101.991.334 1.242.562.327.909.928.909 1.579 0 .317-.084.629-.243.903-.233.433-.08.972.346 1.218.425.246.97.11 1.228-.309 1.003-1.739.407-3.962-1.331-4.966zm-40 3.15c-.638 0-1.265.167-1.818.485-.436.251-.585.808-.334 1.243.251.435.808.584 1.243.333.873-.475 1.966-.183 2.485.665.259.418.802.555 1.228.31.425-.247.579-.786.346-1.219-.652-1.123-1.852-1.815-3.15-1.818z" />
        <path d="M36.007 47.992c.235.14.313.443.174.679-.147.307-.223.643-.222.983 0 1.168.872 2.152 2.032 2.292l1.237.15c.258.035.446.263.43.524-.014.24.069.477.23.657.16.18.386.288.626.302h.052c.483 0 .882-.376.91-.857v-.144c-.002-1.167-.874-2.15-2.032-2.29l-1.237-.15c-.246-.029-.432-.237-.432-.486 0-.088.024-.176.068-.252.149-.308.225-.645.223-.986-.006-.714-.341-1.385-.909-1.818.919-2.117 1.417-4.392 1.465-6.699C38.621 35.614 36.234 31 31 31c-4.016.01-7.267 3.264-7.273 7.28 0 7.96 10.312 9.363 11.987 9.54l.293.172zM25.545 38.28c.014-3.008 2.447-5.444 5.455-5.462 4.01 0 5.807 3.558 5.807 7.084-.055 2.086-.514 4.14-1.353 6.05-2.262-.316-9.909-1.83-9.909-7.672zm29.118 9.772l-.909 3.637c-.121.487.175.98.662 1.102.072.018.146.027.22.027.417 0 .78-.285.881-.69l.91-3.636c.086-.318-.006-.658-.241-.889-.235-.23-.577-.316-.894-.223-.316.093-.557.35-.63.672h.001zm-.936-4.325c0 1.506 1.221 2.727 2.727 2.727 1.507 0 2.728-1.22 2.728-2.727 0-1.506-1.221-2.727-2.728-2.727-1.506 0-2.727 1.22-2.727 2.727zm3.636 0c0 .502-.407.91-.909.91s-.909-.408-.909-.91.407-.91.91-.91c.501 0 .908.408.908.91zm-13.856 3.664c-.487.121-.783.614-.662 1.101l.91 3.637c.1.405.464.689.881.689.075 0 .149-.01.221-.027.487-.122.783-.615.662-1.102l-.91-3.637c-.058-.234-.207-.435-.414-.559-.206-.124-.454-.16-.688-.102zm-.69-.937c1.507 0 2.728-1.22 2.728-2.727 0-1.506-1.22-2.727-2.727-2.727-1.506 0-2.727 1.22-2.727 2.727 0 1.506 1.22 2.727 2.727 2.727zm0-3.636c.503 0 .91.407.91.909s-.407.91-.91.91c-.501 0-.908-.408-.908-.91s.407-.91.909-.91z" />
    </Ic>
);

IC258SurpriseMe.displayName = 'IC258SurpriseMe';

export default IC258SurpriseMe;
