import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import {errorState, ink} from '../../../../common/palette';

import Ic from '..';

const IC51Close = ({
    color = errorState,
    secondaryColor = ink,
    ...args
}) => (
    <Ic {...args}>
        <g fillRule="nonzero">
            <path fill={color} d="M49.245 7C25.914 7 7 25.914 7 49.245c0 23.33 18.914 42.244 42.245 42.244 23.33 0 42.244-18.913 42.244-42.244C91.482 25.917 72.573 7.008 49.245 7z" />
            <path fill={secondaryColor} d="m66.572 37.804-5.887-5.873-11.44 11.441-11.441-11.44-5.873 5.872 11.441 11.44-11.44 11.441 5.872 5.887 11.44-11.455 11.441 11.455 5.887-5.887-11.455-11.44z" />
        </g>
    </Ic>
);

IC51Close.propTypes = {
    /** The icon's primary color */
    color: colorType,
    /** The icon's secondary color */
    secondaryColor: colorType,
};

IC51Close.displayName = 'IC51Close';

export default IC51Close;
