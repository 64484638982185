import React from 'react';
import Ic from '..';

const IC235Shows = (args) => (
    <Ic {...args}>
        <path d="M53.832 28.826l12.082-11.83L62.974 14 50.192 26.516 37.41 14l-2.94 2.996 12.082 11.816H12v51.1l76.384-6.594V28.826H53.832zm26.152 36.792L20.4 70.756v-33.53h59.584v28.392zM69.288 86.422H33.77v-2.786l35.532-3.094z" />
    </Ic>
);

IC235Shows.displayName = 'IC235Shows';

export default IC235Shows;
