import {BASE_DEFAULT_SETTINGS, DEFAULT_ENV_ORDER, BASE_BILLING_HOSTED_PAYMENT_PAGE, ENVIRONMENT, BRAND} from '../constants';
import {getBrandBillingHostedPaymentPage, getBrandEnvsFromWidgetSettings} from '../envs';

/** * @type {Object.<Environment, string>} */
export const PRODUCTS_STATS_SEARCH_API = {
    [ENVIRONMENT.production]: 'https://productstatssearchapi.kayosports.com.au',
    [ENVIRONMENT.staging]: 'https://productstatssearchapi-staging.kayosports.com.au',
    [ENVIRONMENT.testing]: 'https://productstatssearchapi-staging.kayosports.com.au',
};

/** * @type {Object.<Environment, string>} */
export const USER_PREFERENCE_API_URL = {
    [ENVIRONMENT.production]: 'https://userpreferenceapi.kayosports.com.au',
    [ENVIRONMENT.staging]: 'https://userpreferenceapi-staging.kayosports.com.au',
    [ENVIRONMENT.testing]: 'https://userpreferenceapi-staging.kayosports.com.au',
};

/** * @type {Object.<Environment, string>} */
export const VIMOND_PLAY_URL = {
    [ENVIRONMENT.production]: 'https://play.kayosports.com.au/api/v2',
    [ENVIRONMENT.staging]: 'https://play-staging.kayosports.com.au/api/v2',
    [ENVIRONMENT.testing]: 'https://play-dev.kayosports.com.au/api/v2',
};

/** * @type {Object.<Environment, string>} */
export const VIMOND_PLAYER_SESSION = {
    [ENVIRONMENT.staging]: 'https://vmndplay-staging.kayosports.com.au',
    [ENVIRONMENT.testing]: 'https://vmndplay-dev.kayosports.com.au',
    [ENVIRONMENT.production]: 'https://vmndplay.kayosports.com.au',
};

/** * @type {Object.<Environment, string>} */
export const VIMOND_SUB_PROFILE = {
    [ENVIRONMENT.staging]: 'https://vmndsubprofile-staging.kayosports.com.au',
    [ENVIRONMENT.testing]: 'https://vmndsubprofile-dev.kayosports.com.au',
    [ENVIRONMENT.production]: 'https://vmndsubprofile.kayosports.com.au',
};

/** * @type {Object.<Environment, string>} */
export const VIMOND_PLAYLIST = {
    [ENVIRONMENT.staging]: 'https://vmndplaylist-staging.kayosports.com.au',
    [ENVIRONMENT.testing]: 'https://vmndplaylist-dev.kayosports.com.au',
    [ENVIRONMENT.production]: 'https://vmndplaylist.kayosports.com.au',
};

/** * @type {Object.<Environment, string>} */
export const VIMOND_DEVICE_REGISTRATION = {
    [ENVIRONMENT.staging]: 'https://vmnddevicereg-staging.kayosports.com.au',
    [ENVIRONMENT.testing]: 'https://vmnddevicereg-dev.kayosports.com.au',
    [ENVIRONMENT.production]: 'https://vmnddevicereg.kayosports.com.au',
};

/** * @type {Object.<Environment, string>} */
export const VIMOND_VIEWING_HISTORY = {
    [ENVIRONMENT.staging]: 'https://vmndhistory-staging.kayosports.com.au',
    [ENVIRONMENT.testing]: 'https://vmndhistory-dev.kayosports.com.au',
    [ENVIRONMENT.production]: 'https://vmndhistory.kayosports.com.au',
};

/** * @type {Object.<Environment, string>} */
export const VIMOND_IMAGE_SERVICE = {
    [ENVIRONMENT.staging]: 'https://vmndims-staging.kayosports.com.au',
    [ENVIRONMENT.testing]: 'https://vmndims-dev.kayosports.com.au',
    [ENVIRONMENT.production]: 'https://vmndims.kayosports.com.au',
};

/**
 * The API formerly known as 'Content API'
 *
 * @type {Object.<Environment, string>}
 */
export const VCC_API_URL = {
    [ENVIRONMENT.production]: 'https://vccapi.kayosports.com.au/v2',
    [ENVIRONMENT.staging]: 'https://vccapi-staging.kayosports.com.au/v2',
    [ENVIRONMENT.testing]: 'https://vccapi-qa.kayosports.com.au/v2',
};

/** * @type {Object.<Environment, Object.<string, string>>} */
export const BILLING_HOSTED_PAYMENT_PAGE = BASE_BILLING_HOSTED_PAYMENT_PAGE[BRAND.KAYO];

/** * @type {Object.<Environment, string>} */
export const CHROMECAST_RECEIVER_APPLICATION_ID = {
    [ENVIRONMENT.production]: '5F2DD73A',
    [ENVIRONMENT.staging]: '8389A230',
    [ENVIRONMENT.testing]: 'CFE34CCE',
    dev1: 'CFE34CCE',
    dev2: '7DDA6386',
    qa1: 'C4359C69',
    qa2: '7B93C1AD',
};

/** * @type {Object.<Environment, string>} */
export const FEEDBACK_IFRAME_URL = {
    [ENVIRONMENT.production]: 'https://streamotion.mcxplatform.com.au/cgi-bin/qwebcorporate.dll?idx=X53D8Y',
    [ENVIRONMENT.staging]: 'https://streamotion.mcxplatform.com.au/cgi-bin/qwebcorporate.dll?idx=X53D8Y',
    [ENVIRONMENT.testing]: 'https://streamotion.mcxplatform.com.au/cgi-bin/qwebcorporate.dll?idx=X53D8Y',
};

/** * @type {Object.<Environment, string>} */
export const RESOURCES_URL = {
    [ENVIRONMENT.production]: 'https://resources.kayosports.com.au/production',
    [ENVIRONMENT.staging]: 'https://resources.kayosports.com.au/staging',
    [ENVIRONMENT.testing]: 'https://resources.kayosports.com.au/development',
};

/** * @type {Object.<Environment, string>} */
export const STREAMOTION_RESOURCES_URL = {
    [ENVIRONMENT.production]: 'https://resources.streamotion.com.au/production',
    [ENVIRONMENT.staging]: 'https://resources.streamotion.com.au/staging',
};

/** * @type {Object.<Environment, string>} */
export const KEY_MOMENTS_API_URL = {
    [ENVIRONMENT.production]: 'https://keymomentsapi.kayosports.com.au',
    [ENVIRONMENT.staging]: 'https://keymomentsapi-staging.kayosports.com.au',
    [ENVIRONMENT.testing]: 'https://keymomentsapi-staging.kayosports.com.au',
};

/** * @type {Object.<Environment, string>} */
export const CDN_SELECTION_API_URL = {
    [ENVIRONMENT.production]: 'https://cdnselectionserviceapi.kayosports.com.au',
    [ENVIRONMENT.staging]: 'https://cdnselectionserviceapi-staging.kayosports.com.au',
    [ENVIRONMENT.testing]: 'https://martian-cdn-selection-service-api.platform-sandbox-foxsports.com.au',
};

/** * @type {Object.<Environment, string>} */
export const STATS_API_URL = {
    [ENVIRONMENT.production]: 'https://statsapi.foxsports.com.au/3.0/api',
    [ENVIRONMENT.staging]: 'https://statsapi-staging.foxsports.com.au/3.0/api',
    [ENVIRONMENT.testing]: 'https://statsapi-staging.foxsports.com.au/3.0/api',
};

/** * @type {Object.<Environment, string>} */
export const STATS_API_KEYS = {
    [ENVIRONMENT.production]: 'ed3058e3-362b-4ac8-b9bf-da7240a5b1e7',
    [ENVIRONMENT.staging]: '5c2c22d5-2ae2-4d9c-b761-813306af5e27',
    [ENVIRONMENT.testing]: '5c2c22d5-2ae2-4d9c-b761-813306af5e27',
};

/** * @type {Object.<Environment, string>} */
// note: fixtures is versioned separately from other VCC stuff
export const FIXTURES_URL = {
    [ENVIRONMENT.production]: 'https://vccapi.kayosports.com.au/v1/fixtures',
    [ENVIRONMENT.staging]: 'https://vccapi-staging.kayosports.com.au/v1/fixtures',
    [ENVIRONMENT.testing]: 'https://vccapi-qa.kayosports.com.au/v1/fixtures',
};

// first env is the default  (why do we have other non-defaults? they're a whitelist of allowed envs)
export const DEFAULT_ENV_SETTINGS = {
    ...BASE_DEFAULT_SETTINGS,
    oztamEnv: DEFAULT_ENV_ORDER,
};

/**
 * Get the hosted payment page ID for the specified platform environment and origin.
 *
 * @param {Object} options             - (see below)
 * @param {string} options.platformEnv - platform environment
 * @param {string} options.origin      - origin
 * @returns {string|undefined} the ID of the payment page ID if found
 */
function getBillingHostedPaymentPage({platformEnv, origin}) {
    return getBrandBillingHostedPaymentPage(BILLING_HOSTED_PAYMENT_PAGE, [platformEnv, origin]);
}

/**
 * Given a settings object (e.g. as passed to a fiso widget), return a new
 * object containing ONLY environment properties with default environments
 *
 * @param {Object} settings Settings object, e.g. as passed in to FISO
 * @returns {Object} object only containing environments, where they have been defaulted when invalid/not provided
 */
function envsFromWidgetSettings(settings) {
    return getBrandEnvsFromWidgetSettings(DEFAULT_ENV_SETTINGS, settings);
}

export default {
    PRODUCTS_STATS_SEARCH_API,
    USER_PREFERENCE_API_URL,
    VIMOND_PLAY_URL,
    VIMOND_PLAYER_SESSION,
    VIMOND_SUB_PROFILE,
    VIMOND_PLAYLIST,
    VIMOND_DEVICE_REGISTRATION,
    VIMOND_VIEWING_HISTORY,
    VIMOND_IMAGE_SERVICE,
    VCC_API_URL,
    BILLING_HOSTED_PAYMENT_PAGE,
    CHROMECAST_RECEIVER_APPLICATION_ID,
    FEEDBACK_IFRAME_URL,
    RESOURCES_URL,
    STREAMOTION_RESOURCES_URL,
    KEY_MOMENTS_API_URL,
    CDN_SELECTION_API_URL,
    STATS_API_URL,
    STATS_API_KEYS,
    FIXTURES_URL,
    DEFAULT_ENV_SETTINGS,
    getBillingHostedPaymentPage,
    envsFromWidgetSettings,
};
