import React from 'react';
import Ic from '..';

const IC229Settings = (args) => (
    <Ic {...args}>
        <g transform="translate(11 11)">
            <path d="M39.2 51.268c-6.572 0-11.9-5.328-11.9-11.9s5.328-11.9 11.9-11.9 11.9 5.328 11.9 11.9c-.008 6.569-5.331 11.892-11.9 11.9zm0-19.6c-4.253 0-7.7 3.447-7.7 7.7s3.447 7.7 7.7 7.7 7.7-3.447 7.7-7.7c-.008-4.25-3.45-7.692-7.7-7.7z" />
            <path d="M46.97 78.554h-15.4l-3.276-9.24c-.854-.308-1.694-.658-2.52-1.036l-8.848 4.2L6.062 61.6l4.2-8.862c-.378-.826-.728-1.652-1.036-2.506L0 46.97v-15.4l9.24-3.276c.308-.854.658-1.694 1.036-2.506l-4.2-8.862L16.94 6.062l8.848 4.2c.826-.378 1.666-.728 2.52-1.036L31.584 0h15.4l3.276 9.24c.854.308 1.694.658 2.506 1.036l8.862-4.2 10.864 10.878-4.2 8.862c.378.812.728 1.652 1.036 2.506l9.24 3.276v15.4l-9.24 3.276c-.308.854-.658 1.68-1.036 2.506l4.2 8.862L61.6 72.478l-8.862-4.2c-.812.378-1.652.728-2.506 1.036l-3.262 9.24zm-9.45-8.4h3.514l2.716-7.63 2.072-.588c1.684-.491 3.309-1.168 4.844-2.016l1.89-1.05 7.308 3.486 2.492-2.492-3.472-7.308 1.036-1.89c.846-1.535 1.518-3.16 2.002-4.844l.602-2.072 7.63-2.716V37.52l-7.63-2.716-.588-2.072c-.49-1.685-1.166-3.31-2.016-4.844l-1.036-1.89 3.472-7.308-2.492-2.492-7.308 3.472-1.89-1.036c-1.535-.85-3.16-1.526-4.844-2.016l-2.072-.602L41.034 8.4H37.52l-2.716 7.63-2.072.588c-1.685.49-3.31 1.166-4.844 2.016l-1.89 1.036-7.308-3.472-2.492 2.492 3.472 7.308-1.036 1.89c-.847 1.536-1.524 3.16-2.016 4.844l-.588 2.072L8.4 37.52v3.514l7.63 2.716.588 2.072c.495 1.683 1.171 3.307 2.016 4.844l1.036 1.89-3.472 7.308 2.492 2.492 7.308-3.486 1.89 1.05c1.535.848 3.16 1.525 4.844 2.016l2.072.588 2.716 7.63z" />
        </g>
    </Ic>
);

IC229Settings.displayName = 'IC229Settings';

export default IC229Settings;
