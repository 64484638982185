import React, {useState, useRef} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import invoke from 'lodash/invoke';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {white} from '../../../../common/palette';
import TB01NavT from '../../../atoms/tb/01-nav-t';

const OuterContainer = styled.nav`
    display: flex;
    align-items: flex-end;
    max-width: 100%;
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    color: ${white};
`;

const StyledUl = styled.ul`
    display: flex;
    margin: 0;
    padding-left: 0;
    height: 54px;
    list-style-type: none;

    & > *:not(:last-of-type) {
        margin-right: 7px;
    }
`;

const NavItemWrapper = styled.div`
    box-sizing: border-box;
    padding: 0 14px;
    min-width: 84px;
`;

const NM07TopNav = React.forwardRef(({
    items,
    className,
    ...htmlAttributes
}, ref) => {
    const [focusedIndex, setFocusedIndex] = useState(null);
    const prevItemRef = useRef(null);
    const nextItemRef = useRef(null);

    return (
        <OuterContainer {...htmlAttributes} className={classnames('NM07TopNav', className)}>
            <StyledUl
                onKeyDown={(e) => {
                    // Left and Right keys navigate between menu items
                    if (e.key === 'ArrowLeft') {
                        invoke(prevItemRef, 'current.focus');
                        e.preventDefault();
                        e.stopPropagation();
                    } else if (e.key === 'ArrowRight') {
                        invoke(nextItemRef, 'current.focus');
                        e.preventDefault();
                        e.stopPropagation();
                    }
                }}
            >
                {items.map(({href, isActive, onClick, title}, index) => (
                    <NavItemWrapper key={title}>
                        <TB01NavT
                            {...{
                                href,
                                isActive,
                                onClick,
                                'onFocus': () => void setFocusedIndex(index),
                                'ref': [prevItemRef, ref, nextItemRef][index - focusedIndex + 1] || null,
                                'tabIndex': isActive ? 0 : -1, // Only one tab catch, the rest is done via keyboard navigation
                                'aria-current': isActive,
                            }}
                        >
                            {title}
                        </TB01NavT>
                    </NavItemWrapper>
                ))}
            </StyledUl>
        </OuterContainer>
    );
});

NM07TopNav.displayName = 'NM07TopNav';

NM07TopNav.propTypes = {
    /** Handed in classname, e.g. for use with styled components */
    className: classNameType,
    /** Array of objects for the navigation links */
    items: propTypes.arrayOf(propTypes.shape({
        href: propTypes.string,
        isActive: propTypes.bool,
        onClick: propTypes.func,
        title: propTypes.string,
    })).isRequired,
};

export default NM07TopNav;
