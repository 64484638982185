import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import {blanc, midnight} from '../../../../common/palette';
import Ic from '..';

const IC262ContextualRelVid = ({
    color = blanc,
    secondaryColor = midnight,
    ...otherProps
}) => (
    <Ic {...otherProps}>
        <g transform="translate(7 7)">
            <circle cx="42.7" cy="42.7" r="42.7" fill={color} />
            <g fill={secondaryColor}>
                <path d="M59.585 31.334v14.39H37V31.333h22.584M64.4 26.6H32.2v23.8h32.2V26.6z" />
                <path d="M53.2 56H26.6V37.8h2.385v15.764H53.2z" />
                <path d="M47.6 61.6H21V43.4h2.385v15.779H47.6z" />
            </g>
        </g>
    </Ic>
);

IC262ContextualRelVid.propTypes = {
    /** The icon's primary color */
    color: colorType,
    /** The icon's secondary color */
    secondaryColor: colorType,
};

IC262ContextualRelVid.displayName = 'IC262ContextualRelVid';

export default IC262ContextualRelVid;
