import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {PERIOD_SHORT, PERIOD_LONG} from '../../../../common/billing-constants';
import {billingPeriodType} from '../../../../common/custom-proptypes';
import {coal, white} from '../../../../common/palette';
import {SCREEN_TABLET} from '../../../../common/screen-sizes';
import splitPrice from '../../../../common/split-price';

import BA22EditBtn from '../../../atoms/ba/22-edit-btn';

const PlanBox = styled.section`
    border-radius: 3px;
    background-color: ${coal};
    padding: 14px 21px;
    color: ${white};
`;

const PlanHeading = styled.h3`
    margin-top: 0;
`;

const PlanName = styled.span`
    text-transform: uppercase;
    font: var(--mui-header-5);
`;

const PlanPrice = styled.span`
    position: relative;
    font: var(--mui-offer-price);
`;

const PlanPriceFraction = styled.sup`
    font: var(--mui-body-copy-5);
    line-height: 1; /* stylelint-disable-line order/properties-order */
    font-weight: normal;

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        position: absolute;
        top: 5px;
    `}
`;

const PlanPeriod = styled.span`
    font: var(--mui-offer-period);
`;

const OfferTagline = styled.p`
    font: var(--mui-body-copy-1);
`;

const OfferDescription = styled.p`
    font: var(--mui-body-copy-4);
`;

const OfferTermsList = styled.ul`
    padding-left: 20px;
    font: var(--mui-body-copy-4);
`;

const OR48SubBlk = ({
    currencyPrefix = '$',
    currencyDecimalPlaces = 2,

    productName,

    // Product rate plan
    originalPrice = 0,
    billingPeriod,
    tagline,
    firstPaymentDescription,
    offerTerms = [],

    isVoucherApplied,
    enterVoucherCodeUrl,
    onClickEnterVoucherCode,
}) => {
    // Show the base price
    const [originalPriceUnits, originalPriceFraction] = splitPrice(originalPrice, currencyDecimalPlaces);
    const planPeriodLabel = PERIOD_SHORT[billingPeriod];

    return (
        <PlanBox>
            <PlanHeading>
                {!!productName && (
                    <React.Fragment>
                        <PlanName>
                            {productName}
                        </PlanName>
                        {' '}
                    </React.Fragment>
                )}

                <PlanPrice hasFraction={!!originalPriceFraction}>
                    {originalPrice ? (
                        <React.Fragment>
                            {currencyPrefix}{originalPriceUnits}

                            {!!originalPriceFraction && (
                                <PlanPriceFraction>
                                    .{originalPriceFraction}
                                </PlanPriceFraction>
                            )}

                            {!!planPeriodLabel && (
                                <PlanPeriod hasFraction={!!originalPriceFraction} aria-label={billingPeriod ? `per ${PERIOD_LONG[billingPeriod]}` : ''}>
                                    <span aria-hidden="true">
                                        /{planPeriodLabel}
                                    </span>
                                </PlanPeriod>
                            )}
                        </React.Fragment>
                    ) : 'Free'}
                </PlanPrice>
            </PlanHeading>

            {!!tagline && (
                <OfferTagline>
                    {tagline}
                </OfferTagline>
            )}

            {!!firstPaymentDescription && (
                <OfferDescription>
                    {firstPaymentDescription}
                </OfferDescription>
            )}

            {(offerTerms.length > 0) && (
                offerTerms.length > 1 ? (
                    <OfferTermsList>
                        {offerTerms.map((line) => (
                            <li key={line}>
                                {line}
                            </li>
                        ))}
                    </OfferTermsList>
                ) : (
                    <OfferDescription>
                        {offerTerms[0]}
                    </OfferDescription>
                )
            )}

            {!!(enterVoucherCodeUrl || onClickEnterVoucherCode) && (
                <div>
                    <BA22EditBtn
                        href={enterVoucherCodeUrl}
                        onClick={onClickEnterVoucherCode}
                    >
                        {isVoucherApplied ? 'Use a different voucher code' : 'Forgot your voucher code?'}
                    </BA22EditBtn>
                </div>
            )}
        </PlanBox>
    );
};

OR48SubBlk.displayName = 'OR48SubBlk';

OR48SubBlk.propTypes = {
    /** Currency prefix */
    currencyPrefix: propTypes.oneOf(['$']),
    /** Currency decimal places */
    currencyDecimalPlaces: propTypes.number,

    /** Selected product name */
    productName: propTypes.string,
    /** Selected rate plan - original price which the user will be charged each billing cycle following the offer period */
    originalPrice: propTypes.number,
    /** Selected rate plan - billing period */
    billingPeriod: billingPeriodType,
    /** Selected rate plan - tagline (text following "Includes your") */
    tagline: propTypes.string,
    /** Formatted first payment date */
    firstPaymentDescription: propTypes.string,
    /** List of terms for the selected plan and any additional terms for the discount */
    offerTerms: propTypes.arrayOf(propTypes.string),

    /** Has a valid voucher been applied? */
    isVoucherApplied: propTypes.bool,
    /** Link to go back to the voucher input (instead of onClickEnterVoucherCode) */
    enterVoucherCodeUrl: propTypes.string,
    /** Action to take when the user wants to enter a voucher code (instead of enterVoucherCodeUrl) */
    onClickEnterVoucherCode: propTypes.func,
};

export default OR48SubBlk;
