/**
 * Return a promise that resolves after the specified millisecond delay, or 0ms (next tick).
 *
 * @param {number} [ms] - the delay in milliseconds
 * @returns {Promise} a promise that resolves after the delay
 */
export default function asyncTimeout(ms) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}
