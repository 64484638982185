import React from 'react';
import propTypes from 'prop-types';
import styled, {ThemeProvider} from 'styled-components';
import get from 'lodash/get';

import {mediaQuery, stylesWhen} from '@fsa-streamotion/styled-component-helpers';

const Container = styled.div.attrs(({theme}) => ({
    className: get(theme, 'isAppDock') ? 'OR31AppDock' : null,
}))`
    box-sizing: border-box;
    margin: 0 auto;

    ${stylesWhen('hasBackground')`
        background: #333;
        padding: 14px;
    `}

    ${stylesWhen('theme.isAppDock')`
        min-width: 320px;

        ${mediaQuery({minWidthPx: 375})`
            min-width: 375px;
            width: 375px;
        `}
    `}
`;

const AppDock = ({isAppDock, hasBackground, appDockBgSrc, children}) => (
    <ThemeProvider theme={{isAppDock, appDockBgSrc}}>
        <Container hasBackground={hasBackground}>
            {children}
        </Container>
    </ThemeProvider>
);

AppDock.displayName = 'AppDock';

AppDock.propTypes = {
    isAppDock: propTypes.bool,
    hasBackground: propTypes.bool,
    appDockBgSrc: propTypes.string,
    children: propTypes.node,
};

export default AppDock;
