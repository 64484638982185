import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import noop from 'lodash/noop';

import {mediaQuery, stylesIfElse} from '@fsa-streamotion/styled-component-helpers';
import {classNameType, srcsetOptionsType} from '@fsa-streamotion/custom-prop-types';

import {Main, Footer} from '../../../common/normalized-styled-components';
import {fog} from '../../../common/palette';
import {SCREEN_768_TABLET, SCREEN_1024_DESKTOP, SCREEN_1280_DESKTOP, SCREEN_1920_DESKTOP} from '../../../common/screen-sizes';
import {Branding, brandingSizeStyles} from '../../../common/branding';

import GA05BgImg from '../../atoms/ga/05-bg-img';
import GA92BrandLogo from '../../atoms/ga/92-brand-logo';
import IC103Loading from '../../atoms/ic/103-loading';
import TM24AlertTile from '../../molecules/tm/24-alert-tile';

const PageWrapper = styled(Main)`
    width: 100%;
    height: 100%;
    color: ${fog};
`;

const StickyWrapper = styled.div`
    display: grid;
    position: relative;
    grid-template: min-content minmax(112px, 1fr) min-content 1fr auto / minmax(0, 1fr);
    grid-template-areas: 'header'
        '.'
        'content'
        '.'
        'footer';
    height: 100%;
    min-height: 100vh;
`;

const StyledGA05BgImg = styled(GA05BgImg)`
    position: fixed;
    top: 0;
    left: 0;
`;

const StyledHeading = styled(Branding)`
    display: flex;
    left: 50%;
    flex-direction: column;
    grid-area: header;
    transform: translateX(-50%);
    margin: 35px 0 0;
`;

const StyledGA92BrandLogo = styled(GA92BrandLogo)`
    ${brandingSizeStyles};
`;

const ContentWrapper = styled.div`
    display: grid;
    grid-area: content;
    grid-gap: 7px;
    align-items: center;
    justify-content: center;
    justify-items: center;
`;

const InstructionsContainer = styled.div`
    margin: 0 auto 27px;
    padding: 0 28px;
    max-width: 292px;
    text-align: center;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        padding: 0;
        max-width: 386px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        max-width: 444px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        max-width: 595px;
    `}
`;

const InstructionsText = styled.p`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    font: var(--infinity-header-5);
`;

const StyledFooter = styled(Footer)`
    display: flex;
    position: absolute;
    bottom: 0;
    flex-direction: column;
    grid-area: footer;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    padding-bottom: 21px;
    width: 100%;

    ${stylesIfElse('hasContextualEducation')`
        padding: 14px 0 20px;
    ``
        padding: 20px 0;
    `}
`;

const ContextualEducationText = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
    width: 100%;
    font: var(--infinity-body-copy-5-light);
`;

const FooterButtons = styled.div`
    display: grid;
    grid-template-columns: min-content;
    grid-gap: 7px;

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        grid-template-columns: min-content min-content;
    `}
`;

const CenteredLoader = styled(IC103Loading).attrs({
    size: '60px',
})`
    margin: 50px auto;
`;

const StyledTM24AlertTile = styled(TM24AlertTile)`
    margin-top: 27px;
`;

const ProfileTemplate = ({
    className,
    children,
    contextualEducationText,
    defaultImageSrc,
    errorMessage,
    footerButtons,
    headerTitle,
    headerDescription,
    isLoading,
    loadingSpinnerId,
    mobilePhabletSrcsetOptions,
    onCancel = noop,
    onKeyDown = noop,
    srcsetOptions,
    ...htmlAttributes
}) => (
    <PageWrapper
        {...htmlAttributes}
        aria-busy={isLoading}
        className={classnames(className, 'ProfileTemplate')}
        onKeyDown={(e) => {
            if (e.key === 'Escape' && !e.defaultPrevented) {
                onCancel(e);
            } else {
                onKeyDown(e);
            }
        }}
    >
        {!!(srcsetOptions || defaultImageSrc) && (
            <StyledGA05BgImg
                alt=""
                defaultImageSrc={defaultImageSrc}
                mobilePhabletSrcsetOptions={mobilePhabletSrcsetOptions}
                srcsetOptions={srcsetOptions}
            />
        )}

        <StickyWrapper>
            <StyledHeading>
                <StyledGA92BrandLogo />
            </StyledHeading>

            <ContentWrapper>
                <InstructionsContainer>
                    {!!headerTitle && <InstructionsText>{headerTitle}</InstructionsText>}
                    {!!headerDescription && <InstructionsText>{headerDescription}</InstructionsText>}
                </InstructionsContainer>
                {isLoading ? <CenteredLoader id={loadingSpinnerId} /> : children}
                {!!errorMessage && (
                    <StyledTM24AlertTile>
                        {errorMessage}
                    </StyledTM24AlertTile>
                )}
            </ContentWrapper>

            {!!(contextualEducationText || footerButtons) && (
                <StyledFooter hasContextualEducation={!!contextualEducationText}>
                    {!!contextualEducationText && (
                        <ContextualEducationText>{contextualEducationText}</ContextualEducationText>
                    )}

                    {!!footerButtons && (
                        <FooterButtons>{footerButtons}</FooterButtons>
                    )}
                </StyledFooter>
            )}
        </StickyWrapper>
    </PageWrapper>
);

ProfileTemplate.displayName = 'ProfileTemplate';

ProfileTemplate.propTypes = {
    /** Optional classnames */
    className: classNameType,
    /** Content node */
    children: propTypes.node,
    /** Configurable copy to display in the footer */
    contextualEducationText: propTypes.node,
    /** Image to use for browsers that don't support <picture>. Defaults to largest srcSetOption screen size */
    defaultImageSrc: propTypes.string,
    /** Optional error message feedback for the user */
    errorMessage: propTypes.string,
    /** Action buttons */
    footerButtons: propTypes.node,
    /** Configurable copy for header title */
    headerTitle: propTypes.node,
    /** Configurable copy for header description */
    headerDescription: propTypes.node,
    /** Is the template in a loading state */
    isLoading: propTypes.bool,
    /** ID of loading spinner `IC103Loading` */
    loadingSpinnerId: propTypes.string,
    /** SrcsetOptions object to use for mobile and phablet viewports */
    mobilePhabletSrcsetOptions: srcsetOptionsType,
    /** Callback on the Escape key */
    onCancel: propTypes.func,
    /** Callback on the Continue button _**(NOTE: Possibly not used at all)**_*/
    onContinue: propTypes.func, // @TODO: Double check if used at all, delete if not!
    /** Callback on any key except the Escape key */
    onKeyDown: propTypes.func,
    /** SrcsetOptions object to use for everything other than mobile and phablet viewports */
    srcsetOptions: srcsetOptionsType,
};

export default ProfileTemplate;
