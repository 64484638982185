import React, {Fragment} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import property from 'lodash/property';
import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {colorType} from '@fsa-streamotion/custom-prop-types';

import {SCREEN_1024_DESKTOP, SCREEN_1680_DESKTOP, SCREEN_1920_DESKTOP} from '../../../../../common/screen-sizes';
import IC298LiveIcon from '../../../../atoms/ic/298-live-icon';

const BroadcastContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 14px;
    overflow: hidden;

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        padding-bottom: 21px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding-bottom: 28px;
    `}
`;

const LiveIconWrapper = styled.span`
    --infinity-live-icon-size: 12px;
    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        --infinity-live-icon-size: 13px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1680_DESKTOP})`
        --infinity-live-icon-size: 14.5px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        --infinity-live-icon-size: 19px;
    `}

    margin-right: 3px;
    margin-bottom: 2px;
    padding-right: 5px;
    width: var(--infinity-live-icon-size);
    min-width: var(--infinity-live-icon-size);
    height: var(--infinity-live-icon-size);
    color: ${property('color')};
`;

const BroadcastLabel = styled.p`
    display: inline-block;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    white-space: nowrap;
    font: var(--infinity-poster-attribute-tags);
`;

const BroadcastExtendedLabel = styled(BroadcastLabel)`
    font-weight: normal;
`;

/*
 * Broadcast status logo & text
 */
const BroadcastStatus = ({label, extendedLabel, color, showHeadlineIcon}) => (
    <BroadcastContainer>
        {!!showHeadlineIcon && (
            <LiveIconWrapper color={color}>
                <IC298LiveIcon />
            </LiveIconWrapper>
        )}
        {!!label && <BroadcastLabel>{label}</BroadcastLabel>}
        {!!extendedLabel && <BroadcastExtendedLabel>{!!label && <Fragment>&nbsp;- </Fragment>}{extendedLabel}</BroadcastExtendedLabel>}
    </BroadcastContainer>
);

BroadcastStatus.propTypes = {
    label: propTypes.string,
    extendedLabel: propTypes.string,
    // eslint-disable-next-line react/boolean-prop-naming
    showHeadlineIcon: propTypes.bool,
    color: colorType,
};

BroadcastStatus.displayName = 'BroadcastStatus';

export default BroadcastStatus;
