import React from 'react';
import Ic from '..';

const IC07Pau = (args) => (
    <Ic {...args}>
        <path d="M33 5H41.4V94.6H33zM58.634 5H67.034V94.6H58.634z" />
    </Ic>
);

IC07Pau.displayName = 'IC07Pau';

export default IC07Pau;
