import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import noop from 'lodash/noop';
import ResizeObserver from 'resize-observer-polyfill';
import {Section} from 'normalized-styled-components';
import classnames from 'classnames';

import {stylesWhenNot, stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';
import {palette as infinityPalette} from '@fsa-streamotion/streamotion-web-infinity';
import {palette as ionicPalette} from '@fsa-streamotion/streamotion-web-ionic';
import {
    animations,
    palette as muiPalette,
} from '@fsa-streamotion/streamotion-web-mui';

import {
    BrandedIC103Loading,
    BrandedTM24AlertTile,
} from '../../../../utils/branded-components';
import {stylesWhenBinge, stylesWhenFlash, stylesWhenKayo, stylesWhenLifestyle} from '../../../../utils/styles-when-brand';

const {transition} = animations;

const {fog} = infinityPalette;
const {blanc} = ionicPalette;
const {white} = muiPalette;

const PaymentSection = styled(Section)`
    ${stylesWhenBinge`
        color: ${blanc};
    `}

    ${stylesWhenFlash`
        color: ${fog};
    `}

    ${stylesWhenKayo`
        color: ${white};
    `}

    ${stylesWhenLifestyle`
        color: ${blanc};
    `}
`;

const ZuoraIframe = styled.div`
    position: relative;
`;

const ZuoraIframeHolder = styled.div`
    transform-origin: center top;
    transition: ${transition('transform', 'opacity', 'height')};

    ${stylesWhenNot('isVisible')`
        transform: scaleY(0);
        opacity: 0;
        height: 0;
    `}

    /* Override Zuora's ugly defaults */
    > iframe {
        background-color: transparent !important; /* stylelint-disable-line declaration-no-important */
        width: 100%;

        ${stylesWhen('isHidden')`
            visibility: hidden !important;
            height: 0;
            width: 0;
        `}
    }
`;

const ZuoraIframeStatus = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

const StyledBrandedIC103Loading = styled(BrandedIC103Loading)`
    /* Sets the color for the prefers-reduced-motion text displayed */
    ${stylesWhenBinge`
        color: ${blanc};
    `}

    ${stylesWhenLifestyle`
        color: ${blanc};
    `}
`;

export default class ZuoraIframeComponent extends React.Component {
    static displayName = 'ZuoraIframeComponent';

    static propTypes = {
        hostedPaymentPageRenderer: propTypes.func,
        onHostedPaymentPageLoaded: propTypes.func,
        paymentsPageLoadingErrorMessage: propTypes.node,
        isSubmitting: propTypes.bool,
        className: classNameType,
        brand: propTypes.string,
    };

    static defaultProps = {
        hostedPaymentPageRenderer: noop,
        onHostedPaymentPageLoaded: noop,
    };

    state = {
        isSummoningPaymentsPage: false,
        isLoadingPaymentsPage: false,
    };

    componentWillUnmount() {
        this.zuoraIframeResizeObserver.disconnect();
    }

    updateIframeHolderWidth = () => {
        window.Z.post('z_hppm_iframe', 'resize');
    };

    zuoraIframeResizeObserver = new ResizeObserver(this.updateIframeHolderWidth);

    onZuoraIframeLoaded = () => {
        this.setState({
            isSummoningPaymentsPage: false,
            isLoadingPaymentsPage: false,
        }, () => {
            this.zuoraIframeResizeObserver.observe(this.zuoraIframeHolderElement);
            this.props.onHostedPaymentPageLoaded();
        });
    };

    zuoraIframeHolderRef = (element) => {
        if (this.zuoraIframeHolderElement !== element) {
            this.zuoraIframeHolderElement = element;

            if (element) {
                this.setState({isSummoningPaymentsPage: true}, () => {
                    this.props.hostedPaymentPageRenderer();
                });

                this.iframeHolderMutationObserver = new MutationObserver((mutations) => {
                    for (const mutation of mutations) {
                        if (mutation.type !== 'childList') {
                            continue;
                        }

                        // If the iframe has been added, listen to its load event
                        for (const node of mutation.addedNodes) {
                            if (!(node instanceof HTMLIFrameElement)) {
                                continue;
                            }

                            node.addEventListener('load', this.onZuoraIframeLoaded);

                            // Show the loading indicator
                            this.setState({isLoadingPaymentsPage: true});
                            break;
                        }

                        // If the iframe has been removed, stop listening to its load event
                        for (const node of mutation.removedNodes) {
                            if (!(node instanceof HTMLIFrameElement)) {
                                continue;
                            }

                            node.removeEventListener('load', this.onZuoraIframeLoaded);
                            break;
                        }
                    }
                });

                this.iframeHolderMutationObserver.observe(element, {childList: true});
            } else if (this.iframeHolderMutationObserver) {
                this.iframeHolderMutationObserver.disconnect();
                delete this.iframeHolderMutationObserver;

                this.setState({
                    isSummoningPaymentsPage: false,
                    isLoadingPaymentsPage: false,
                });
            }
        }
    };

    render() {
        const isIframeStatusVisible = !!(
            this.state.isSummoningPaymentsPage
            || this.state.isLoadingPaymentsPage
            || this.props.paymentsPageLoadingErrorMessage
            || this.props.isSubmitting
        );

        return (
            <PaymentSection
                brand={this.props.brand}
                className={classnames('ZuoraIframeComponent', this.props.className)}
            >
                <ZuoraIframe>
                    <ZuoraIframeHolder
                        id="zuora_payment"
                        ref={this.zuoraIframeHolderRef}
                        isVisible={!isIframeStatusVisible}
                        isHidden={!!this.props.isSubmitting}
                    />

                    {isIframeStatusVisible && (
                        <ZuoraIframeStatus>
                            {(this.state.isLoadingPaymentsPage || !!this.props.isSubmitting) && (
                                <StyledBrandedIC103Loading
                                    brand={this.props.brand}
                                    size="70px"
                                    // Kayo only styling, color prop is not used on ionic or infinity IC103
                                    color={white}
                                />
                            )}
                            {(!!this.props.paymentsPageLoadingErrorMessage && (
                                <BrandedTM24AlertTile brand={this.props.brand}>
                                    {this.props.paymentsPageLoadingErrorMessage}
                                </BrandedTM24AlertTile>
                            ))}
                        </ZuoraIframeStatus>
                    )}
                </ZuoraIframe>
            </PaymentSection>
        );
    }
}
