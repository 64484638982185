import React from 'react';
import bacon from 'baconjs';
import styled from 'styled-components';
import {format, isValid} from 'date-fns';

import {palette} from '@fsa-streamotion/streamotion-web-infinity';

import {BrandedIC43Valid, BrandedIC103Loading} from '../../utils/branded-components';
import {stylesWhenBinge, stylesWhenFlash, stylesWhenKayo} from '../../utils/styles-when-brand';

import {Paragraph} from '../common/styled-elements';
import {localiseMobileNumber} from './phone-utils';

const {flash} = palette;

const UserDetails = styled.span`
    ${stylesWhenBinge`
        font: var(--ionic-body-copy-3);
    `}

    ${stylesWhenFlash`
        font: var(--infinity-body-copy-2-light);
    `}
`;

const StyledSpan = styled.span`
    word-break: break-all;

    ${stylesWhenBinge`
        font: var(--ionic-body-copy-3-bold);
    `}

    ${stylesWhenFlash`
        font: var(--infinity-body-copy-2-light);
    `}

    ${stylesWhenKayo`
        font: var(--mui-body-copy-2);
    `}
`;

const StyledBrandedIC43Valid = styled(BrandedIC43Valid)`
    float: right;
    transform: translateY(2px);
    margin-left: 7px;

    ${stylesWhenFlash`
        color: ${flash};
    `}
`;

export default function getStepUserDetailsStream({
    userDetails$,
    brand,
} = {}) {
    const reactElement$ = userDetails$.map((userDetails) => {
        const userDateOfBirthObject = new Date(userDetails.dateOfBirth);
        const userDisplayDateOfBirth = !!userDateOfBirthObject.valueOf()
            && isValid(userDateOfBirthObject)
            && format(userDateOfBirthObject, 'dd/MM/yyyy');

        return (
            <Paragraph brand={brand} data-cs-mask={true}>
                <StyledBrandedIC43Valid brand={brand} size="23px" />
                <UserDetails brand={brand}>
                    <StyledSpan>{userDetails.email}</StyledSpan>
                    <br />
                    {userDetails.firstName} {userDetails.lastName}
                    <br />
                    {userDetails.phoneNumber && localiseMobileNumber(userDetails.phoneNumber)}
                    <br />
                    {userDisplayDateOfBirth || userDetails.dateOfBirth}
                </UserDetails>
            </Paragraph>
        );
    })
        .startWith((
            <Paragraph brand={brand} style={{width: '40px'}}>
                <BrandedIC103Loading size="40px" brand={brand} />
            </Paragraph>
        ));

    return bacon.combineTemplate({
        reactElement: reactElement$,
        isCompleted: true,
    });
}
