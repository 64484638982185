import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {mediaQuery, stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {SCREEN_TABLET, SCREEN_DESKTOP} from '../../../../../common/screen-sizes';
import VisuallyHidden from '../../../../../common/visually-hidden';

const Z_INDEX_LOGO = 0;
const Z_INDEX_SCORE = Z_INDEX_LOGO + 1;

const LOGO_SIZE_PX = 65;

const Container = styled.article`
    display: flex;
    position: relative;
    align-items: center;
    padding: 0 14px;
    min-width: ${LOGO_SIZE_PX}px;
    min-height: ${LOGO_SIZE_PX}px;
    text-align: center;

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        ${stylesWhen(({tileAlign}) => tileAlign === 'right')`
            flex-direction: row-reverse;
            justify-content: flex-start;
        `}
    `}
`;

const Team = styled.figure`
    margin: 0;
    text-align: center;

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        display: inline-flex;
        flex-direction: row-reverse;
        align-items: center;

        ${stylesWhen(({tileAlign}) => tileAlign === 'right')`
            flex-direction: row;
            justify-content: flex-end;
        `}
    `}
`;

const LogoContainer = styled.span`
    display: inline-block;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    opacity: ${({isPre}) => isPre ? 1 : 0.2};
    z-index: ${Z_INDEX_LOGO};
    width: ${LOGO_SIZE_PX}px;
    height: ${LOGO_SIZE_PX}px;

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        position: static;
        transform: none;
        opacity: 1;
    `}
`;

const TeamScore = styled.p`
    z-index: ${Z_INDEX_SCORE};
    margin: 4px auto 0;
    font: var(--mui-header-6);

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        margin: 0 7px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_DESKTOP})`
        margin-right: 14px;
        margin-left: 14px;
    `}
`;

const Tile = ({
    className,
    hasNoSpoilers,
    logo,
    score,
    matchState,
    name,
    tileAlign,
}) => (
    <Container
        className={className}
        tileAlign={tileAlign}
    >
        <Team tileAlign={tileAlign}>
            <LogoContainer isPre={matchState === 'pre'}>
                {logo}
            </LogoContainer>
            {name}
        </Team>
        {(!hasNoSpoilers && matchState !== 'pre' && score !== null && score !== undefined) && (
            <TeamScore className="BM01GnrBnr__score">
                <VisuallyHidden as="span">Score: </VisuallyHidden>
                {score}
            </TeamScore>
        )}
    </Container>
);

Tile.displayName = 'Tile';

Tile.propTypes = {
    /** className */
    className: classNameType,
    /** Don't show any spoilers, i.e match result, score */
    hasNoSpoilers: propTypes.bool,
    /** Team logo node */
    logo: propTypes.node,
    /** State for the match, before starting (pre,) during (live,) and finished (post) */
    matchState: propTypes.oneOf(['pre', 'live', 'post']),
    /** Team name node */
    name: propTypes.node,
    /** Team score node */
    score: propTypes.node,
    /** Align to the right for a left one, align to the left for a right one */
    tileAlign: propTypes.oneOf(['left', 'right']),
};

export default Tile;
