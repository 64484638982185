import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {coal, white} from '../../../../common/palette';
import {IC42Error} from '../../../atoms/ic';

const Tile = styled.div.attrs({
    role: 'alert',
})`
    display: grid;
    position: relative;
    grid-template-columns: 35px 1fr;
    grid-column-gap: 14px;
    align-items: center;
    border-radius: 4px;
    background-color: ${white};
    padding: 14px;
    color: ${coal};
    font: var(--mui-body-copy-4);

    a {
        color: inherit;
    }
`;

const Content = styled.div`
    flex-grow: 1;
`;

const TM24AlertTile = ({
    children,
    className,
    icon = <IC42Error />,
    ...htmlAttributes
}) => (
    <Tile
        className={classnames('TM24AlertTile', className)}
        {...htmlAttributes}
    >
        {icon}

        <Content>
            {children}
        </Content>
    </Tile>
);

TM24AlertTile.displayName = 'TM24AlertTile';

TM24AlertTile.propTypes = {
    /** Content for the alert panel */
    children: propTypes.node.isRequired,
    /** Optional class(es) */
    className: classNameType,
    /** Icon */
    icon: propTypes.node,
};

export default TM24AlertTile;
