import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {stylesIfElse, stylesWhenNot, stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import IA01Input from '../../../atoms/ia/01-input';
import IA05InputHelp from '../../../atoms/ia/05-input-help';
import {transition} from '../../../../common/animations';
import {white} from '../../../../common/palette';

const InputContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    transition: ${transition('opacity')};
    width: 100%;
    color: ${white};

    ${stylesWhen('disabled')`
        opacity: 0.35;
    `}
`;

const InlineIcon = styled.span`
    position: absolute;
    top: ${stylesIfElse('isCondensed')`10px``21px`};
    right: 14px;
    width: 21px;
    height: 21px;
`;

const InputHelp = styled(IA05InputHelp)`
    ${stylesWhenNot('isFullWidth')`
        align-self: flex-end;
    `}
`;

const IM01Input = ({
    inputRef,
    icon,
    isCondensed,
    contextualHelp,
    isFullWidthContextualHelp = true,
    className,
    type,
    disabled,
    isLightTheme,
    ...htmlAttributes
}) => (
    <InputContainer disabled={!!disabled} className={classnames('IM01Input', className)}>
        <IA01Input
            {...htmlAttributes}
            ref={inputRef}
            type={type}
            disabled={disabled}
            isCondensed={isCondensed}
            hasRevealPasswordButton={type === 'password'}
            hasIcon={!!icon}
            isLightTheme={isLightTheme}
        />
        {!!icon && (
            <InlineIcon isCondensed={isCondensed}>
                {icon}
            </InlineIcon>
        )}
        {!!contextualHelp && (
            <InputHelp
                role="alert"
                isFullWidth={isFullWidthContextualHelp}
            >
                {contextualHelp}
            </InputHelp>
        )}
    </InputContainer>
);

IM01Input.displayName = 'IM01Input';

IM01Input.propTypes = {
    /** Forwarded ref to the input element */
    inputRef: propTypes.oneOfType([propTypes.object, propTypes.func]),
    /** The HTML type of the input field */
    type: propTypes.oneOf(['text', 'password', 'email', 'tel', 'url', 'search', 'date', 'number']),
    /** Is the input field disabled? */
    disabled: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    /** Represents a validation warning, with contextual help */
    contextualHelp: propTypes.node,
    /** Does contextual help occupy the full width of this component? (Default: true) */
    isFullWidthContextualHelp: propTypes.bool,
    /** Represents a successfully validated input */
    icon: propTypes.node,
    /** For a slimmer, more svelte input */
    isCondensed: propTypes.bool,
    /** Light or dark (default) theme */
    isLightTheme: propTypes.bool,
    /** Additional CSS class(es) */
    className: classNameType,
};

export default React.forwardRef((props, ref) => <IM01Input {...props} inputRef={ref} />);
