import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {fadeInOut} from '../../../../common/animations';
import {billingPeriodType} from '../../../../common/custom-proptypes';
import {silverLining, slate, white} from '../../../../common/palette';
import BA01BtnPr from '../../../atoms/ba/01-btn-pr';
import BA08BtnSec from '../../../atoms/ba/08-btn-sec';
import BA14OfferRadBtn from '../../../atoms/ba/14-offer-rad-btn';
import BA20NavBtn from '../../../atoms/ba/20-nav-btn';
import IC103Loading from '../../../atoms/ic/103-loading';
import XX02Feature from '../../../atoms/xx/02-feature';
import XX03ScreenOption from '../../../atoms/xx/03-screen-option';
import TM19PmntCc from '../../../molecules/tm/19-pmnt-cc';
import TM19PmntPp from '../../../molecules/tm/19-pmnt-pp';
import TM19PmntTelstra from '../../../molecules/tm/19-pmnt-telstra';
import TM24AlertTile from '../../../molecules/tm/24-alert-tile';
import XX03FeatureList from '../../../molecules/xx/03-feature-list';
import XX04ScreenOptions from '../../../molecules/xx/04-screen-options';
import OR13FeaBlk from '../../../organisms/or/13-fea-blk';
import TM27InfoTile from '../../../molecules/tm/27-info-tile';
import {IC61Ios} from '../../../atoms/ic';

const ANNUAL_FAMILY_PLAN_NAME = 'Annual';
const PAYMENT_METHOD_COMPONENT = {
    creditCard: TM19PmntCc,
    paypal: TM19PmntPp,
    tbill: TM19PmntTelstra,
};

const InfoText = styled.p`
    margin: 21px 0;
    color: ${white};
    font: var(--mui-body-copy-3);
`;

const ConfirmText = styled.p`
    margin: 21px auto;
    text-align: center;
    color: ${white};
    font: var(--mui-header-7-bold);
`;

const Buttons = styled.div`
    display: grid;
    grid-gap: 7px;
    margin: 0 auto;
    max-width: 210px;
`;

const LOADING_ICON_SIZE = '47px';

const LoadingBox = styled.div`
    padding-top: 21px;
    min-height: ${LOADING_ICON_SIZE};
`;

const LoadingWrapper = styled.div`
    text-align: center;

    ${fadeInOut}
`;

const Groups = styled.div`
    display: grid;
    grid-row-gap: 7px;
`;

const GroupHeading = styled.h3`
    margin: 0 0 14px;
    color: ${white};
    font: var(--mui-header-6-bold);
`;

const OfferTileWrapper = styled.div`
    box-sizing: content-box;
    margin: 0 auto;
    width: 50%;
    max-width: 192px;
`;

const FinePrint = styled.p`
    box-sizing: content-box;
    margin: 0 auto;
    width: 80%;
    text-align: center;
    color: ${slate};
    font: var(--mui-body-copy-4);

    > strong {
        text-transform: uppercase;
        color: ${silverLining};
        font-weight: inherit;
    }
`;

const FeaturesWrapper = styled.div`
    box-sizing: content-box;
    margin: 0 auto 14px;
    max-width: 627px;
`;

// Generic text that we'll likely want to replace with something that contains the billing date and price.
const DEFAULT_INFO_TEXT = [
    'You\'re just one step away from reactivating your Kayo Sports subscription!',
    'If you reactivate your subscription, your current payment method will be charged at the start of the next billing cycle. '
    + 'You can change your payment method below.',
];

const ResubscribeTemplate = ({
    className,
    headingText = 'Reactivate your subscription',
    infoText = DEFAULT_INFO_TEXT,
    confirmText = 'Please confirm your reactivation below.',
    errorMsg,
    paymentMethod = {},
    subscription = {},
    isSubmitting,
    submitButtonText = 'Reactivate Now',
    cancelButtonText = 'Cancel',
    isItunesSubscription,
    itunesSubscriptionTitle,
    itunesSubscriptionMessage,
    onSubmit,
    onCancel,
    onClickChangePaymentMethod,
}) => {
    const {details, method} = paymentMethod;
    const PaymentMethod = PAYMENT_METHOD_COMPONENT[method];

    const {
        billingPeriod,
        currencyDecimalPlaces,
        currencyPrefix,
        features = [],
        numberOfScreens,
        originalPrice,
        planFamily,
        productName,
    } = subscription;

    const showFinePrint = planFamily !== ANNUAL_FAMILY_PLAN_NAME;

    if (isItunesSubscription) {
        return (
            <TM27InfoTile
                icon={<IC61Ios size="43px" />}
                title={itunesSubscriptionTitle}
            >
                {itunesSubscriptionMessage}
            </TM27InfoTile>
        );
    }

    return (
        <Groups className={classnames('ResubscribeTemplate', className)}>
            <GroupHeading>
                {headingText}
            </GroupHeading>
            <section>
                {infoText.map((paragraph) => (
                    <InfoText key={paragraph}>
                        {paragraph}
                    </InfoText>
                ))}

                {!!PaymentMethod && (
                    <Groups>
                        <PaymentMethod {...details} />

                        <BA20NavBtn
                            label="Change payment method"
                            onClick={onClickChangePaymentMethod}
                        />
                    </Groups>
                )}

                <ConfirmText>
                    {confirmText}
                </ConfirmText>
            </section>

            <OfferTileWrapper>
                <BA14OfferRadBtn
                    isCurrent={true}
                    currencyPrefix={currencyPrefix}
                    currencyDecimalPlaces={currencyDecimalPlaces}
                    description={productName}
                    period={billingPeriod}
                    price={originalPrice}
                />
            </OfferTileWrapper>

            <FeaturesWrapper>
                <OR13FeaBlk
                    title=""
                    screenOptions={
                        !!numberOfScreens && (
                            <XX04ScreenOptions listTitle="Devices you can watch on at the same time">
                                <XX03ScreenOption
                                    packageName={productName}
                                    numberOfScreens={numberOfScreens}
                                />
                            </XX04ScreenOptions>
                        )
                    }
                    featureList={
                        !!features.length && (
                            <XX03FeatureList>
                                {features.map((feature) => (
                                    <XX02Feature key={feature}>
                                        {feature}
                                    </XX02Feature>
                                ))}
                            </XX03FeatureList>
                        )
                    }
                />
            </FeaturesWrapper>

            {showFinePrint && (
                <FinePrint>
                    <strong>
                        Note:
                    </strong>
                    {' '}
                    You can switch to another plan after reactivating your subscription.
                </FinePrint>
            )}

            <section>
                <Buttons>
                    <BA01BtnPr
                        type="button"
                        isBlock={true}
                        disabled={isSubmitting}
                        onClick={onSubmit}
                    >
                        {submitButtonText}
                    </BA01BtnPr>
                    <BA08BtnSec
                        type="button"
                        isBlock={true}
                        disabled={isSubmitting}
                        onClick={onCancel}
                    >
                        {cancelButtonText}
                    </BA08BtnSec>
                </Buttons>

                <LoadingBox>
                    {!!isSubmitting && (
                        <LoadingWrapper>
                            <IC103Loading size={LOADING_ICON_SIZE} />
                        </LoadingWrapper>
                    )}

                    {!!(errorMsg && !isSubmitting) && (
                        <TM24AlertTile>
                            {errorMsg}
                        </TM24AlertTile>
                    )}
                </LoadingBox>
            </section>
        </Groups>
    );
};

ResubscribeTemplate.displayName = 'ResubscribeTemplate';

ResubscribeTemplate.propTypes = {
    /** Class name property */
    className: classNameType,
    /** Heading text */
    headingText: propTypes.string,
    /** Info text */
    infoText: propTypes.arrayOf(propTypes.string),
    /** Confirmation text */
    confirmText: propTypes.string,
    /** If set, an error message to be displayed to the user */
    errorMsg: propTypes.string,

    /** The user's current payment method */
    paymentMethod: propTypes.shape({
        method: propTypes.oneOf(['creditCard', 'paypal', 'tbill']),
        details: propTypes.oneOfType([
            // if method === 'creditCard'
            propTypes.shape({
                type: propTypes.oneOf(['mastercard', 'visa']),
                number: propTypes.string,
                expiry: propTypes.string,
            }),
            // if method === 'paypal'
            propTypes.shape({
                email: propTypes.string,
                name: propTypes.string,
            }),
            // if method === 'tbill'
            propTypes.shape({
                name: propTypes.string,
            }),
        ]),
    }),

    /** Cancelled subscription */
    subscription: propTypes.shape({
        productName: propTypes.string,
        currencyPrefix: propTypes.string,
        currencyDecimalPlaces: propTypes.number,
        originalPrice: propTypes.number,
        billingPeriod: billingPeriodType,
        features: propTypes.arrayOf(propTypes.string),
        numberOfScreens: propTypes.number,
    }),

    /** Whether or not the form has been submitted and is awaiting a response */
    isSubmitting: propTypes.bool,
    /** Submit button text */
    submitButtonText: propTypes.string,
    /** Cancel button text */
    cancelButtonText: propTypes.string,
    /** If set shows itunes message */
    isItunesSubscription: propTypes.bool,
    /** Title for the itunes message */
    itunesSubscriptionTitle: propTypes.node,
    /** Content of the itunes message */
    itunesSubscriptionMessage: propTypes.node,
    /** A callback to handle submission of the form data */
    onSubmit: propTypes.func,
    /** A callback to handle going back */
    onCancel: propTypes.func,
    /** Manage Payment button click handler */
    onClickChangePaymentMethod: propTypes.func,
};

export default ResubscribeTemplate;
