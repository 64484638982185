import React, {useEffect, useMemo} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import noop from 'lodash/noop';
import classnames from 'classnames';

import {colorType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {vader} from '../../../common/palette';
import {themeColorCssVariables} from '../../../common/theme-color';

const Z_INDEX_BELOW_CONTENT = -1;

const WebAppContainer = styled.div`
    position: relative;

    &::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: ${Z_INDEX_BELOW_CONTENT};
        background-color: ${vader};
        content: '';
    }
`;

const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    z-index: 0;
    background-color: transparent;
    height: 100%;
`;

const WebApp = ({
    children,
    theme: {colorPrimary, colorSecondary} = {},
    modalComponent,
    greenScreen,
    onMount = noop,
    className,
}) => {
    useEffect(onMount, []); // eslint-disable-line react-hooks/exhaustive-deps
    const themeColours = useMemo(() => themeColorCssVariables({colorPrimary, colorSecondary}), [colorPrimary, colorSecondary]);

    return (
        <WebAppContainer className={classnames('WebApp', className)}>
            <FlexContainer style={themeColours}>
                {greenScreen}
                {children}
            </FlexContainer>
            {modalComponent}
        </WebAppContainer>
    );
};

WebApp.propTypes = {
    children: propTypes.node,
    // An object where each key/value pair will be converted into a CSS variable. Will convert key names to kebab case.
    theme: propTypes.shape({
        colorPrimary: colorType,
        colorSecondary: colorType,
    }),
    greenScreen: propTypes.node,
    modalComponent: propTypes.node,
    onMount: propTypes.func,
    className: classNameType,
};

WebApp.displayName = 'WebApp';

export default WebApp;
