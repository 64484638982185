/**
 * Tells us if an input URL directs us to another origin
 *
 * More info: https://owasp.org/www-project-cheat-sheets/cheatsheets/Unvalidated_Redirects_and_Forwards_Cheat_Sheet
 *
 * @param {string} url - The URL to test
 * @returns {boolean} - true if the url will point us to another host
 */
export function isUrlToAnotherOrigin(url = '') { // eslint-disable-line import/prefer-default-export
    try {
        // Malicious redirects
        if (['//', 'data:', 'javascript:'].some((prefix) => url.startsWith(prefix))) { // eslint-disable-line no-script-url
            return true;
        }

        if (url.includes('\n') || url.includes('\r')) {
            return true;
        }

        const {origin} = new URL(url);

        return origin !== window.location.origin;
    } catch (_) {
        // Catch cases like relative paths, malformed URLs, running on the server, null input
        return false;
    }
}
