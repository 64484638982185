import React from 'react';
import getModuleComponent from './utils/get-module-component';
import getModuleKey from './utils/get-module-key';

/**
 * Retrieves an array of module components based on modules[] from AEM
 *
 * @param {object} options options
 * @param {object[]} options.modules array of modules from AEM
 * @param {string} options.path page path (to be used for warning message)
 * @returns {ReactElement[]} array of module components
 */
const getModuleComponents = ({
    modules = [],
    path,
    ...rest // could be billing api data - do not set generic name (this could vary)
}) => modules.map((module, index) => {
    const {id, type} = module;
    const Component = getModuleComponent({type, id, path});
    const moduleKey = getModuleKey({index, type, id, length: modules.length});

    return (
        <Component
            key={moduleKey}
            moduleKey={moduleKey}
            module={module}
            {...rest}
        />
    );
});

export default getModuleComponents;
