import React from 'react';
import propTypes from 'prop-types';
import {Figure} from 'normalized-styled-components';
import property from 'lodash/property';
import styled from 'styled-components';

import {midnight} from '../../../../common/palette';

const ImageContainer = styled(Figure)`
    position: fixed;
    bottom: 0;
    left: 0;
    margin: 0;
    background-color: ${property('bgColor')};
    background-image: url(${property('src')});
    background-position: center;
    background-repeat: ${({isRepeating}) => isRepeating ? 'repeat' : 'no-repeat'};
    background-size: ${property('bgSize')};
    width: 100%;
    height: 100%;
`;

const XX01PageBackground = ({
    src,
    bgColor = midnight,
    isRepeating = false,
    bgSize = 'cover',
    ...htmlAttributes
}) => (
    <ImageContainer
        aria-hidden="true"
        src={src}
        bgColor={bgColor}
        isRepeating={isRepeating}
        bgSize={bgSize}
        {...htmlAttributes}
    />
);

XX01PageBackground.displayName = 'XX01PageBackground';

XX01PageBackground.propTypes = {
    /** Background image source */
    src: propTypes.string,
    /** Background colour to use, this is mostly only visible during image load */
    bgColor: propTypes.string,
    /** Background type is repeat or fill? (default false) */
    isRepeating: propTypes.bool,
    /** Background size (default cover): https://developer.mozilla.org/en-US/docs/Web/CSS/background-size */
    bgSize: propTypes.string,
};

export default XX01PageBackground;
