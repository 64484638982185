import React from 'react';
import styled from 'styled-components';
import {rgba} from 'polished';
import propTypes from 'prop-types';
import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';

import IC103Loading from '../../../../atoms/ic/103-loading';

import IA05InputHelp from '../../../../atoms/ia/05-input-help';
import {black, white, kayoGreen, slate} from '../../../../../common/palette';
import {transition} from '../../../../../common/animations';

const ChromecastControlsContainer = styled.section`
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-end;
    transition: ${transition('opacity')};
    opacity: ${({isVisible}) => isVisible ? 1 : 0};

    &::before {
        position: absolute;
        bottom: 0;
        background-image: linear-gradient(${rgba(black, 0)}, ${black} 60%);
        width: 100%;
        height: 182px;
        content: '';
        pointer-events: none;
    }
`;

const ScrubberAndButtonRowContainer = styled.div`
    position: relative;
    margin: 14px 21px;
`;

const ButtonRow = styled.div`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
`;

const ButtonRowLayoutItem = styled.div`
    display: flex;
    align-items: center;
    max-width: calc(100% - 60px);
`;

const ElementWrapper = styled.div`
    margin: 0 3px;
`;

const DisplayTimeWrapper = styled(ElementWrapper)`
    text-align: center;

    ${stylesWhen('isTabletUp')`
        min-width: 180px;
    `}
`;

const Divider = styled.div`
    margin: 0 15px;
    border-right: 1px solid ${rgba(white, 0.25)};
    width: 0;
    height: 40px;
`;

const StatusWrapper = styled.header`
    box-sizing: border-box;
    display: inline-flex;
    margin: 0;
    padding: 0 7px;
    width: 100%;
    overflow: hidden;
    font: var(--mui-body-copy-3-bold);
`;

const StyledChromecastStatus = styled.h3`
    margin: 0;
    padding: 0 5px 0 0;
    white-space: nowrap;
    color: ${kayoGreen};
    font: var(--mui-body-copy-3-bold);
`;

const StyledMediaHeading = styled.h3`
    margin: 0;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${slate};
    font: var(--mui-body-copy-3-bold);
`;

const LoadingIconContainer = styled.div`
    display: flex;
    margin-right: 7px;
`;

const StyledIA05InputHelp = styled(IA05InputHelp)`
    margin: 14px;
`;

const VPOR04ChromecastControls = ({
    chromecastButton,
    chromecastStatus,
    displayTime,
    errorMessage,
    goToLiveButton,
    hasControls,
    isLoading,
    isTabletUp,
    isVisible,
    mediaTitle,
    playButton,
    scrubber,
    skipBackButton,
    skipForwardButton,
    volumeSlider,
}) => (
    <ChromecastControlsContainer
        isVisible={isVisible}
        className="VPOR04ChromecastControls"
    >
        {!!errorMessage && (
            <StyledIA05InputHelp hasDownArrow={true}>
                {errorMessage}
            </StyledIA05InputHelp>
        )}
        <ScrubberAndButtonRowContainer>
            {!!hasControls && scrubber}
            <ButtonRow>
                {!!hasControls && (
                    <ButtonRowLayoutItem>
                        {
                            [skipBackButton, playButton, skipForwardButton, goToLiveButton]
                                .filter(Boolean)
                                .map((element, index) => (
                                    <ElementWrapper key={index}>
                                        {element}
                                    </ElementWrapper>
                                ))
                        }
                        {!!volumeSlider && (
                            <React.Fragment>
                                <Divider />
                                <ElementWrapper>
                                    {volumeSlider}
                                </ElementWrapper>
                                <Divider />
                            </React.Fragment>
                        )}
                        {!!displayTime && (
                            <React.Fragment>
                                {!volumeSlider && !!isTabletUp && <Divider />}
                                <DisplayTimeWrapper isTabletUp={isTabletUp}>
                                    {displayTime}
                                </DisplayTimeWrapper>
                                {!!isTabletUp && <Divider />}
                            </React.Fragment>
                        )}
                    </ButtonRowLayoutItem>
                )}
                {!!(chromecastStatus || mediaTitle) && (
                    <StatusWrapper>
                        {!!isLoading && (
                            <LoadingIconContainer>
                                <IC103Loading color={white} size="22px" />
                            </LoadingIconContainer>
                        )}
                        {!!chromecastStatus && (
                            <StyledChromecastStatus>
                                {chromecastStatus}
                                {mediaTitle ? ': ' : ' '}
                            </StyledChromecastStatus>
                        )}
                        {!!mediaTitle && (
                            <StyledMediaHeading>{mediaTitle}</StyledMediaHeading>
                        )}
                    </StatusWrapper>
                )}
                <ElementWrapper>
                    {chromecastButton}
                </ElementWrapper>
            </ButtonRow>
        </ScrubberAndButtonRowContainer>
    </ChromecastControlsContainer>
);

VPOR04ChromecastControls.displayName = 'VPOR04ChromecastControls';

VPOR04ChromecastControls.propTypes = {
    /** Chromecast Button */
    chromecastButton: propTypes.node,
    /** Chromecast Status, e.g: Casting to Kayo Sports */
    chromecastStatus: propTypes.string,
    /** Disply time node */
    displayTime: propTypes.node,
    /** Error message node, could later on contain links */
    errorMessage: propTypes.node,
    /** Go to live button */
    goToLiveButton: propTypes.node,
    /** Are the controls currently visible? */
    hasControls: propTypes.bool,
    /** Is width of this container bigger than tablet sized screen */
    isTabletUp: propTypes.bool,
    /** Is this component visible? */
    isVisible: propTypes.bool,
    /** Title of media currently in chromecast */
    mediaTitle: propTypes.string,
    /** Play or pause button */
    playButton: propTypes.node,
    /** Scrubber node */
    scrubber: propTypes.node,
    /** Skip button */
    skipBackButton: propTypes.node,
    /** Skip forward button */
    skipForwardButton: propTypes.node,
    /** Volume Slider */
    volumeSlider: propTypes.node,
    /** Is Chromecast loading media */
    isLoading: propTypes.bool,
};

export default VPOR04ChromecastControls;
