/* eslint-disable arrow-body-style */
import bacon from 'baconjs';

export default function getPaymentNotRequiredStream({
    telstraOrderItemId,
    startSubscription$,
}) {
    const paymentDetails$ = startSubscription$
        .map(() => {
            // For cash payment we just send nulls everywhere.
            // This _maybe_ might come from a telstra flow though, so have a telstraOrderItemId.
            return {
                telstraOrderItemId,
            };
        });

    const paymentSuccess$ = startSubscription$
        .map(true);

    return bacon.combineTemplate({
        label: 'No payment required',
        reactElement: null,

        paymentLoaded: bacon.later(1000, true).startWith(false),
        paymentErrors: null,

        paymentDetails: paymentDetails$.startWith(null),
        paymentSuccessful: paymentSuccess$.startWith(false),
    });
}
