import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import classnames from 'classnames';
import sortBy from 'lodash/sortBy';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {SCREEN_PHONE, SMALLEST_SUPPORTED_WIDTH, SCREEN_768_TABLET, SCREEN_1920_DESKTOP, SCREEN_1280_DESKTOP} from '../../../../common/screen-sizes';
import {blanc} from '../../../../common/palette';

const DEFAULT_LIST_GAP_SET = [
    {
        breakpoint: SCREEN_PHONE,
        gapPx: 7,
    },
    {
        breakpoint: SCREEN_1280_DESKTOP,
        gapPx: 14,
    },
    {
        breakpoint: SCREEN_1920_DESKTOP,
        gapPx: 21,
    },
];

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const Header = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    text-align: center;
    color: ${blanc};
    font: var(--ionic-body-copy-3-bold);

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        justify-content: initial;
        margin-bottom: 21px;
        text-indent: 21px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        margin-bottom: 35px;
    `}
`;

const listGapSetToQueries = ({listGapSet}) => {
    const sortedSet = sortBy(listGapSet, 'breakpoint');

    return sortedSet.map(({breakpoint, gapPx}) => {
        const isDefault = breakpoint === SCREEN_PHONE || breakpoint === SMALLEST_SUPPORTED_WIDTH;

        if (isDefault && gapPx === 0) {
            // if the gap is 0 for the minimum width, don’t create a useless CSS property
            return null;
        } else if (isDefault) {
            // otherwise, create a default CSS property
            return css`
                grid-gap: ${gapPx}px;
            `;
        }

        // for everything else, create CSS properties inside media queries
        return mediaQuery({minWidthPx: breakpoint})`
            grid-gap: ${gapPx}px;
        `;
    });
};

const StyledList = styled.ul`
    display: grid;
    margin: 0;
    padding: 0;

    ${listGapSetToQueries}
`;

const CM03FeatureList = ({
    header,
    children,
    className,
    listGapSet = DEFAULT_LIST_GAP_SET,
    ...htmlAttributes
}) => (
    <Container {...htmlAttributes} className={classnames('CM03FeatureList', className)}>
        {!!header && <Header>{header}</Header>}

        <StyledList listGapSet={listGapSet}>
            {children}
        </StyledList>
    </Container>
);

CM03FeatureList.propTypes = {
    /** Content above the list items */
    header: propTypes.node,
    /** Content of item, e.g. text */
    children: propTypes.node,
    /** Optional additional class(es) */
    className: classNameType,
    /** Array of list gaps in pixels at breakpoints */
    listGapSet: propTypes.arrayOf(propTypes.shape({
        breakpoint: propTypes.number,
        gapPx: propTypes.number,
    })),
};

CM03FeatureList.displayName = 'CM03FeatureList';

export default CM03FeatureList;
