import React from 'react';

import Ic from '..';

const IC142SwipeLR = (args) => (
    <Ic {...args}>
        <path fillRule="nonzero" d="M35.513 21.446H14.021l6.498-6.484L17.556 12 6 23.556 17.556 35.1l2.963-2.962-6.498-6.484h21.492zm46.337-9.348-2.962 2.962 6.4 6.386H62.595v4.207h22.89l-6.597 6.581 2.963 2.963L93.407 23.64z" />
        <path fillOpacity=".35" d="M59 86c11.598 0 20.308-3.296 25.12-20.829C88.93 47.64 87.78 44.026 59 37.927a49.36 49.36 0 0 0-4.894 2.398c-1.934 1.086 5.519-26.702-5.88-23.919-8.46 2.066-3.929 31.447-5.59 33.726C39.733 54.12 38 58.764 38 64c0 12.15 9.402 22 21 22z" />
        <path fillRule="nonzero" d="M87.05 48.542a6.89 6.89 0 0 0-6.876-6.875h-.475a6.75 6.75 0 0 0-3.298.88 7.127 7.127 0 0 0-6.512-4.276 6.987 6.987 0 0 0-3.717 1.076 7.127 7.127 0 0 0-6.4-3.913h-.154a8.063 8.063 0 0 0-2.655.657v-6.987c.14-5.226.266-9.782-2.264-12.339a6.847 6.847 0 0 0-5.016-1.97 8.384 8.384 0 0 0-6.205 2.361 9.544 9.544 0 0 0-2.543 6.387v26.55a14.882 14.882 0 0 0-4.444 5.674c-3.633 7.993-.14 18.431 1.51 21.771l.14.14c1.746 3.563 5.393 10.997 25.655 10.997 16.378 0 21.227-14.868 21.604-23.42a6.833 6.833 0 0 0 1.65-4.43V48.542zm-7.351-2.683h.475a2.683 2.683 0 0 1 2.683 2.683v12.283a2.683 2.683 0 0 1-2.683 2.683h-.475a2.697 2.697 0 0 1-2.697-2.683V48.542a2.697 2.697 0 0 1 2.725-2.683h-.028zm-19.787-6.232a2.865 2.865 0 0 1 2.878 2.864v1.188c-1.97.166-3.926.464-5.855.894v-2.585a3.004 3.004 0 0 1 2.977-2.361zM45.127 23.529a4.304 4.304 0 0 1 4.556-4.542 2.655 2.655 0 0 1 2.11.643c1.3 1.397 1.188 5.212 1.076 9.306v16.602c-1.398.363-3.033.768-4.807 1.243-.951.257-1.885.574-2.795.95l-.14-24.202zm18.655 60.996c-17.649 0-20.416-5.59-21.897-8.65l-.084-.182c-1.313-2.669-4.387-11.71-1.397-18.166 1.51-3.326 4.346-5.505 8.678-6.665 8.873-2.39 12.576-2.893 14.114-2.963.167 1.873 0 4.388-1.132 5.059-3.172 1.844-14.533 6.68-14.631 6.721l1.677 3.843c.419-.182 11.668-4.975 15.064-6.987 4.192-2.431 3.284-8.901 3.004-10.452a2.683 2.683 0 0 0-.126-.56v-.14a2.92 2.92 0 1 1 5.842 0v20.85a2.92 2.92 0 1 1-5.842 0v-4.681L62.86 63.48v4.78a2.879 2.879 0 0 1-2.878 2.878H59.8a2.865 2.865 0 0 1-2.795-2.879v-2.096l-4.192 1.929v.167A7.07 7.07 0 0 0 59.8 75.33h.112a6.987 6.987 0 0 0 5.939-3.242 7.07 7.07 0 0 0 11.095-4.96 6.85 6.85 0 0 0 2.795.6h1.188c-.867 5.576-4.193 16.797-17.147 16.797z" />
    </Ic>
);

IC142SwipeLR.displayName = 'IC142SwipeLR';

export default IC142SwipeLR;
