import {BASE_DEFAULT_SETTINGS, BASE_BILLING_HOSTED_PAYMENT_PAGE, ENVIRONMENT, BRAND} from '../constants';
import {getBrandBillingHostedPaymentPage, getBrandEnvsFromWidgetSettings} from '../envs';

/** * @type {Object.<Environment, string>} */
export const PLAY_SERVICE_URL = {
    [ENVIRONMENT.production]: 'https://play.lifestyle.com.au',
    [ENVIRONMENT.staging]: 'https://play-staging.lifestyle.com.au',
    [ENVIRONMENT.development]: 'https://play-staging.lifestyle.com.au', // for everything else, use staging
};

/**
 * The API formerly known as 'Content API', and now sometimes known as 'Content Experience API'
 *
 * @type {Object.<Environment, string>}
 */
export const XPAPI_API_URL = {
    [ENVIRONMENT.production]: 'https://api.lifestyle.com.au/v1',
    [ENVIRONMENT.staging]: 'https://api-staging.lifestyle.com.au/v1',
    [ENVIRONMENT.development]: 'https://api-staging.lifestyle.com.au/v1', // for everything else, use staging
};

/** * @type {Object.<Environment, Object.<string, string>>} */
export const BILLING_HOSTED_PAYMENT_PAGE = BASE_BILLING_HOSTED_PAYMENT_PAGE[BRAND.LIFESTYLE];

/** * @type {Object.<Environment, string>} */
export const CHROMECAST_RECEIVER_APPLICATION_ID = {
    [ENVIRONMENT.production]: 'EE252261',
    [ENVIRONMENT.staging]: '4C1FF750',
    dev1: '5A7D330B',
    dev2: 'D2AE37C4',
    qa1: '0C40FD96',
    qa2: '1901C477',
};

/** * @type {Object.<Environment, string>} */
export const FEEDBACK_IFRAME_URL = {
    [ENVIRONMENT.production]: 'https://feedback.lifestyle.com.au/cgi-bin/qwebcorporate.dll',
    [ENVIRONMENT.staging]:    'https://feedback.lifestyle.com.au/cgi-bin/qwebcorporate.dll?preview=1',
};

/** * @type {Object.<Environment, string>} */
export const RESOURCES_URL = {
    [ENVIRONMENT.production]: 'https://resources.streamotion.com.au/production/lifestyle',
    [ENVIRONMENT.staging]:    'https://resources.streamotion.com.au/staging/lifestyle',
};

/** * @type {Object.<Environment, string>} */
export const CDN_SELECTION_API_URL = {
    [ENVIRONMENT.production]: 'https://cdnselectionserviceapi.lifestyle.com.au',
    [ENVIRONMENT.staging]: 'https://cdnselectionserviceapi-staging.lifestyle.com.au',
    [ENVIRONMENT.development]: 'https://cdnselectionserviceapi-staging.lifestyle.com.au', // for everything else, use staging
};

// first env is the default  (why do we have other non-defaults? they're a whitelist of allowed envs)
export const DEFAULT_ENV_SETTINGS = {
    ...BASE_DEFAULT_SETTINGS,
};

/**
 * Get the hosted payment page ID for the specified platform environment and origin.
 *
 * @param {Object} options             - (see below)
 * @param {string} options.platformEnv - platform environment
 * @param {string} options.origin      - origin
 * @returns {string|undefined} the ID of the payment page ID if found
 */
function getBillingHostedPaymentPage({platformEnv, origin}) {
    return getBrandBillingHostedPaymentPage(BILLING_HOSTED_PAYMENT_PAGE, [platformEnv, origin]);
}

/**
 * Given a settings object (e.g. as passed to a fiso widget), return a new
 * object containing ONLY environment properties with default environments
 *
 * @param {Object} settings Settings object, e.g. as passed in to FISO
 * @returns {Object} object only containing environments, where they have been defaulted when invalid/not provided
 */
function envsFromWidgetSettings(settings) {
    return getBrandEnvsFromWidgetSettings(DEFAULT_ENV_SETTINGS, settings);
}

export default {
    PLAY_SERVICE_URL,
    XPAPI_API_URL,
    BILLING_HOSTED_PAYMENT_PAGE,
    CHROMECAST_RECEIVER_APPLICATION_ID,
    FEEDBACK_IFRAME_URL,
    RESOURCES_URL,
    CDN_SELECTION_API_URL,
    DEFAULT_ENV_SETTINGS,
    getBillingHostedPaymentPage,
    envsFromWidgetSettings,
};
