import React, {cloneElement} from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';

import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {knight} from './palette';

const StyledSpan = styled.span`
    position: relative;
    width: 100%;
    height: 100%;

    ${stylesWhen('hasPlaceholderBackground')`
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 3px;
            background: ${knight};
            width: 100%;
            height: 100%;
        }
    `}
`;

export default class LazyImageSwitcher extends React.Component {
    static displayName = 'LazyImageSwitcher';

    static propTypes = {
        /** If true, suppress the image from loading */
        isBeingLazy: propTypes.bool,
        /** Expecting a single react element, which can accept a prop  */
        children: propTypes.element,
        /** CSS class name(s) */
        className: classNameType,
        /** Placeholder component shown before right component is lazy loaded */
        placeholderComponent: propTypes.element,
    }

    static defaultProps = {
        isBeingLazy: false,
    }

    state = {
        isLoaded: !this.props.isBeingLazy,
    }

    clonedElementProps = {
        onLoad: () => void this.setState({isLoaded: true}),
    }

    render() {
        const {children, isBeingLazy, className, placeholderComponent, ...htmlAttributes} = this.props;

        return (
            <StyledSpan
                {...htmlAttributes}
                className={className}
                hasPlaceholderBackground={!this.state.isLoaded && !placeholderComponent}
            >
                {!this.state.isLoaded && !!placeholderComponent && (
                    placeholderComponent
                )}
                {!isBeingLazy && cloneElement(
                    children,
                    this.clonedElementProps,
                )}
            </StyledSpan>
        );
    }
}
