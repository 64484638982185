import React from 'react';
import Ic from '..';

const IC105Home = (args) => (
    <Ic {...args}>
        <path d="M71 47.154v32.382H60.878V49.478l-22.974 3.906v26.012H27.768V47.322L19.368 54v33.726l60.032.252V54L71 47.154zm-28.938 9.8l14.658-2.506V79.48H42.104l-.042-22.526z" />
        <path d="M13.222 51.732L8 45.152 49.692 12 90.81 45.18l-5.278 6.538L49.65 22.766z" />
    </Ic>
);

IC105Home.displayName = 'IC105Home';

export default IC105Home;
