import bacon from 'baconjs';
import noop from 'lodash/noop';

import {isServer} from '@fsa-streamotion/browser-utils';

let isAwaitingFirstClientRender = true;
let resolvePromise;
const isAwaitingFirstClientRenderPromise = new Promise((resolve) => {
    resolvePromise = resolve;
});

/**
 * @typedef {Object} hydrationLifecycle
 * @property {boolean} isAwaitingFirstClientRender static property indicating if hydration has happened yet
 * @property {Promise<boolean>} isAwaitingFirstClientRenderPromise Promise which will resolve to "true" when hydration complete
 * @property {bacon.Observable} isHydrationComplete$ Stream which will emit `[true, end]`, `[false, end]` or `[false, true, end]` depending on lifecycle stage and possibilities
 * @property {Function} onFirstClientRender Call this function to trigger lifecycle events (e.g. on react mount)
 */
const hydrationLifecycle = {
    get isAwaitingFirstClientRender() {
        return isAwaitingFirstClientRender;
    },
    get isAwaitingFirstClientRenderPromise() {
        return isAwaitingFirstClientRenderPromise;
    },
    get isHydrationComplete$() {
        if (!isAwaitingFirstClientRender) {
            return bacon.later(0, true); // hydration was completed previously [true, END]
        } else if (isServer()) {
            return bacon.later(0, false); // hydration will never complete on the server [false, END]
        }

        return bacon.fromPromise(isAwaitingFirstClientRenderPromise) // hydration will happen, but hasn't yet. [false, true, END]
            .map(true)
            .startWith(false);
    },
    onFirstClientRender() {
        if (process.env.NODE_ENV !== 'production') {
            // Send a fetch request to indicate first client render completion (in development environment)
            fetch('http://--------client-hydration-completed--------/')
                .catch(() => undefined)
                .then(() => {
                    console.info(  // eslint-disable-line no-console
                        '%c [HydrationLifecycle] 🙌 First Client Render of web-app component complete',
                        'background: #0000FF; color: yellow;',
                    );
                });
        }

        isAwaitingFirstClientRender = false;
        resolvePromise();
    },
};

// can't work out how to stub this without exporting a function :(
/**
 * @returns {hydrationLifecycle} Object full of hydration lifecycle helper goodness!
 */
export default function getHydrationLifecycle() {
    return hydrationLifecycle;
}
