import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {silverLining} from '../../../../common/palette';

const StyledLink = styled.a`
    display: inline-block;
    margin: 0;
    color: ${silverLining};
    font: var(--mui-body-copy-2);

    &:hover {
        text-decoration: none;
    }
`;

const BA30TextBtn = React.forwardRef(({
    children,
    className,
    href,
    ...htmlAttributes
}, ref) => (
    <StyledLink
        {...htmlAttributes}
        className={classnames(className, 'BA30TextBtn')}
        ref={ref}
        href={href}
    >
        {children}
    </StyledLink>
));

BA30TextBtn.displayName = 'BA30TextBtn';

BA30TextBtn.propTypes = {
    /** children elements */
    children: propTypes.node,
    /** passed down classnames */
    className: classNameType,
    /** CMS href */
    href: propTypes.string,
};

export default BA30TextBtn;
