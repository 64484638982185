import React, {useLayoutEffect, useRef} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {jaws} from '../../../../common/palette';
import {SCREEN_415_PHABLET, SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import BA01BtnPr from '../../../atoms/ba/01-btn-pr';
import {IC103Loading} from '../../../atoms/ic';
import OR115GiftInp from '../115-gift-inp';

const ExpandedGiftInput = styled.div`
    display: grid;
`;

const GiftDesc = styled.p`
    margin: 7px 0 21px;
    color: ${jaws};
    font: var(--ionic-body-copy-2-light);
`;

const ButtonLoaderWrapper = styled.div`
    margin: 0;
    padding-top: 14px;

    ${mediaQuery({minWidthPx: SCREEN_415_PHABLET})`
        margin: 0;
        padding-top: 28px;
    `}
`;

const StyledBA01BtnPr = styled(BA01BtnPr)`
    width: 250px;
    min-height: 50px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 400px;
        min-height: 80px;
    `}
`;

export default function Expanded({
    isValidating,
    codeContextualHelpText,
    pinContextualHelpTexts,
    errorMessage,
    codeHelpButtonText,
    pinHelpButtonText,
    giftCodeLabel,
    giftPinLabel,
    giftCode = '',
    giftPin = '',
    onChangeGiftCode,
    onChangeGiftPin,
    giftCardTitle,
    giftCardDescription,
}) {
    const codeInputRef = useRef();

    useLayoutEffect(() => {
        if (codeInputRef.current) {
            codeInputRef.current.select();
        }
    }, []);

    return (
        <ExpandedGiftInput>
            {giftCardTitle}
            <GiftDesc>{giftCardDescription}</GiftDesc>
            <OR115GiftInp
                ref={codeInputRef}
                disabled={!!isValidating}
                {...{
                    codeContextualHelpText,
                    pinContextualHelpTexts,
                    errorMessage,
                    codeHelpButtonText,
                    pinHelpButtonText,
                    giftCodeLabel,
                    giftPinLabel,
                    giftCode,
                    giftPin,
                    name: 'giftCard',
                    pinName: 'pin',
                    onChangeGiftCode,
                    onChangeGiftPin,
                }}
            />

            <ButtonLoaderWrapper>
                <StyledBA01BtnPr
                    type="submit"
                    isBlock={true}
                    disabled={!!isValidating || giftCode.length !== 30 || giftPin.length !== 4}
                >
                    {isValidating ? (
                        <IC103Loading
                            size="30px"
                        />
                    ) : 'Redeem'}
                </StyledBA01BtnPr>
            </ButtonLoaderWrapper>
        </ExpandedGiftInput>
    );
}

Expanded.displayName = 'Expanded';

Expanded.propTypes = {
    isValidating: propTypes.bool,
    codeContextualHelpText: propTypes.string,
    pinContextualHelpTexts: propTypes.arrayOf(propTypes.string),
    errorMessage: propTypes.string,
    codeHelpButtonText: propTypes.string,
    pinHelpButtonText: propTypes.string,
    giftCodeLabel: propTypes.string,
    giftPinLabel: propTypes.string,
    giftCode: propTypes.string,
    giftPin: propTypes.string,
    onChangeGiftCode: propTypes.func,
    onChangeGiftPin: propTypes.func,
    giftCardTitle: propTypes.node,
    giftCardDescription: propTypes.node,
};
