import React from 'react';
import Ic from '..';

const IC01Ham = (args) => (
    <Ic {...args}>
        <path d="M5.6 21.4h88.9V27H5.6zM5.6 47.2h88.9v5.6H5.6zM5.6 73h88.9v5.6H5.6z" />
    </Ic>
);

IC01Ham.displayName = 'IC01Ham';

export default IC01Ham;
