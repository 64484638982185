import React, {useState} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Button} from 'normalized-styled-components';
import {rgba} from 'polished';
import classnames from 'classnames';

import {stylesIfElse, stylesWhen, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {coal, black, slate, white} from '../../../../common/palette';

// compile issue with having polished’s rgba inside the function
const fadedBlack = rgba(black, 0.7);

const getBackgroundColor = ({isLightTheme}) => isLightTheme ? white : fadedBlack;

const TooltipContainer = styled.dialog`
    display: block;
    position: relative;
    transform-origin: ${({isLeftAligned}) => isLeftAligned ? '18px' : 'calc(100% - 18px)'} 0; /* 11px left + (14px arrow width / 2) === position of the tip of the arrow */
    transition: ${transition('transform', 'opacity')};
    border: 0;
    border-radius: 4px;
    background-color: ${getBackgroundColor};
    padding: 14px;
    width: unset;
    height: unset;
    color: ${({isLightTheme}) => isLightTheme ? coal : slate};
    font: var(--mui-body-copy-4-light);

    ${stylesWhen('isArrowVisible')`
        margin-top: 7px;

        &::before {
            position: absolute;
            top: -14px;
            border-right: 14px solid transparent;
            border-bottom: 14px solid ${getBackgroundColor};
            border-left: 14px solid transparent;
            width: 0;
            height: 0;
            content: '';

            ${stylesIfElse('isLeftAligned')`
                left: 11px;
            ``
                right: 11px;
            `}
        }
    `}

    ${stylesWhenNot('open')`
        transform: scale(0.95);
        opacity: 0;
        pointer-events: none;
    `}

    a {
        color: inherit;
    }
`;

const CloseHelpButton = styled(Button)`
    appearance: none;
    display: block;
    margin-left: auto;
    border: 0;
    background: none;
    padding: 0;
    text-align: right;
    text-decoration: underline;
    color: inherit;
    font-family: inherit;
`;

const BM02Tooltip = ({
    arrowOrientation = 'right',
    children,
    className,
    dismissTooltipText,
    isArrowVisible = true,
    isInitiallyVisible,
    isLightTheme,
    ...htmlAttributes
}) => {
    const [isTooltipVisible, setIsTooltipVisible] = useState(isInitiallyVisible);

    return (
        <TooltipContainer
            {...htmlAttributes}
            className={classnames('BM02Tooltip', className)}
            isArrowVisible={isArrowVisible}
            isLeftAligned={arrowOrientation === 'left'}
            isLightTheme={isLightTheme}
            open={isTooltipVisible}
        >
            {children}
            {!!dismissTooltipText && (
                <CloseHelpButton
                    onClick={() => setIsTooltipVisible(false)}
                    tabIndex={isTooltipVisible ? undefined : -1}
                >
                    {dismissTooltipText}
                </CloseHelpButton>
            )}
        </TooltipContainer>
    );
};

BM02Tooltip.displayName = 'BM02Tooltip';

BM02Tooltip.propTypes = {
    /** Positioning of the arrow header */
    arrowOrientation: propTypes.oneOf(['left', 'right']),
    /** Content for the help dialog */
    children: propTypes.node.isRequired,
    /** className */
    className: classNameType,
    /** Dismiss tooltip text, if set, becomes clicking-to-close-type of modal */
    dismissTooltipText: propTypes.string,
    /** Whether to show the arrow head or not */
    isArrowVisible: propTypes.bool,
    /** Is tooltip visible? */
    isInitiallyVisible: propTypes.bool,
    /** Is light background, dark foreground? */
    isLightTheme: propTypes.bool,
};

export default BM02Tooltip;
