import React from 'react';
import Ic from '..';

const IC106Binge = (args) => (
    <Ic {...args}>
        <path d="M80.5 31.6c-.5-.6-1.3-.9-2.1-.9h-1.8v-.3c0-5.2-3.9-9.5-8.8-10.1-.3-8.1-7-14.7-15.3-14.7-6.5 0-12.2 4.1-14.4 10.1-.7-.1-1.3-.2-2-.2-7 0-12.8 5.7-12.8 12.8 0 .8.1 1.6.2 2.4h-2.1c-.8 0-1.5.3-2.1.9-.5.6-.8 1.3-.7 2.1l5.3 58.2c.1 1.4 1.3 2.5 2.8 2.5H73c1.4 0 2.6-1.1 2.8-2.5l5.3-58.2c.2-.8-.1-1.5-.6-2.1zm-54.3-3.3c0-5.5 4.5-10 10-10 .9 0 1.8.1 2.6.3.3.1.5 0 .8 0-1.3.9-2.1 2.4-2.1 4.1 0 2.8 2.3 5.1 5.1 5.1s5.1-2.3 5.1-5.1-2.3-5.1-5.1-5.1c-.9 0-1.7.3-2.5.7.2-.2.3-.4.3-.6 1.4-5.5 6.4-9.3 12.1-9.3C59.4 8.4 65 14 65 20.9v.5c-.9-.7-1.9-1.1-3.1-1.1-2.8 0-5.1 2.3-5.1 5.1s2.3 5.1 5.1 5.1 5.1-2.3 5.1-5.1c0-.9-.2-1.7-.6-2.4 4.1 0 7.5 3.3 7.5 7.5v.3H26.5c-.2-.9-.3-1.7-.3-2.5zm14-5.6c0-1.3 1.1-2.3 2.3-2.3s2.3 1.1 2.3 2.3-1 2.3-2.3 2.3-2.3-1-2.3-2.3zm24 2.6c0 1.3-1.1 2.3-2.3 2.3s-2.3-1.1-2.3-2.3 1.1-2.3 2.3-2.3 2.3 1 2.3 2.3zM48.6 36.2v52.7H36.8V36.2h11.8zm2.8 0h11.8v52.7H51.4V36.2zm-26.8 0H34v52.7h-4.6l-4.8-52.7zm46 52.7H66V36.2h9.4l-4.8 52.7z" />
    </Ic>
);

IC106Binge.displayName = 'IC106Binge';

export default IC106Binge;
