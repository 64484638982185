/**
 * getLowestFrequency gets lowest frequency to poll, 'false' if frequency not available on
 * any callbacks else the smallest positive value among the callbacks, else '0'
 *
 * @param  {Object[]}   callbacks           Array of callbacks
 * @param  {Number}     callbacks.frequency Polling Frequency
 *
 * @returns {Number|Boolean}  lowest frequency for callbacks or false
 */
export function getLowestFrequency(callbacks = []) {
    return callbacks
        .reduce((lowest, {frequency}) => {
            if (lowest) {
                if (frequency && frequency < lowest) {
                    return frequency;
                }
            } else if (frequency && frequency > 0) {
                return frequency;
            }

            return lowest || false;
        }, false);
}

/**
 * Default validator to use for superagent, relying on its internal "ok" check.
 *
 * @param {Object}  superAgentObject             Super agent object which will have its `responseOk` property returned as a simple validation check
 * @param {Boolean} superAgentObject.responseOk  internal superagent responseOk validation bool
 *
 * @returns {Boolean} True if response is valid, false if it is not valid (e.g. stream should retry)
 */
export const defaultResponseValidator = (superAgentObject) => !!superAgentObject.responseOk;
