import React from 'react';
import Ic from '..';

const IC21Mute = (args) => (
    <Ic {...args}>
        <path d="M51 17.4c-2-.9-4.3-.7-6 .7L26.5 32.9H16c-3.1 0-5.6 2.5-5.6 5.6v23c0 3.1 2.5 5.6 5.6 5.6h10.5L45 81.9c1 .8 2.3 1.2 3.5 1.2.8 0 1.7-.2 2.5-.6 2-.9 3.2-2.9 3.2-5.1v-55c0-2.1-1.2-4.1-3.2-5zm-2.3 60.1l-.1.1L30.3 63v-8.2h-5.6v6.8H16c-.1 0-.1 0-.1-.1v-23c0-.1 0-.1.1-.1h8.7v6.8h5.6V37l18.2-14.6.1.1v55zM89.6 40.3l-2.9-2.9-9.7 9.7-9.7-9.7-3 2.9L74 50l-9.7 9.7 3 2.9 9.7-9.7 9.7 9.7 2.9-2.9-9.7-9.7z" />
    </Ic>
);

IC21Mute.displayName = 'IC21Mute';

export default IC21Mute;
