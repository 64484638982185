import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import noop from 'lodash/noop';
import clamp from 'lodash/clamp';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import RangeTouch from '../../../../../common/range-touch';
import {transition} from '../../../../../common/animations';
import {blanc, ares, panther} from '../../../../../common/palette'; // check blanc color
import {SCREEN_1920_DESKTOP} from '../../../../../common/screen-sizes';
import {TRACK_HEIGHT_PX, TRACK_HEIGHT_PX__LARGE_SCREENS, PLAYHEAD_WIDTH_PX, PLAYHEAD_WIDTH_PX__LARGE_SCREENS} from './constants';

const playHeadColor = (({theme}) => theme.primaryAccent || ares);

// the scrubber thumb should render above every other element on the range
// eg. key moments stems
const THUMB_ZINDEX = 1;

const Range = styled.input`
    appearance: none;
    box-sizing: border-box;
    display: block;
    position: relative;
    margin: 0 -10px;
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 0;
    width: calc(100% + 20px);
    height: ${PLAYHEAD_WIDTH_PX}px;
    vertical-align: middle;
    touch-action: manipulation;

    ${['webkit-slider', 'moz-range', 'ms'].map((vendor) => css`
        /* stylelint-disable */
        &::-${vendor}-thumb {
            appearance: none;
            box-sizing: border-box;
            position: relative;
            transition: ${transition(['background', 'border', 'transform'])};
            border: 2px solid ${playHeadColor};
            border-radius: 100%;
            background: ${playHeadColor};
            cursor: pointer;
            width: ${PLAYHEAD_WIDTH_PX}px;
            height: ${PLAYHEAD_WIDTH_PX}px;
            z-index: ${THUMB_ZINDEX};
            box-shadow: 0 0 10px 0 ${panther};

            ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
                width: ${PLAYHEAD_WIDTH_PX__LARGE_SCREENS}px;
                height: ${PLAYHEAD_WIDTH_PX__LARGE_SCREENS}px;
            `}
        }
        /* stylelint-enable */
    `)}

    ${['webkit-slider-runnable', 'moz-range'].map((vendor) => css`
        /* stylelint-disable */
        &::-${vendor}-track {
            display: flex;
            align-items: center;
            border: 0;
            border-radius: 0;
            background: transparent;
            cursor: pointer;
            width: 100%;
            user-select: none;
        }
        /* stylelint-enable */
    `)}

    &::-ms-track {
        border: 0;
        background: transparent;
        height: ${TRACK_HEIGHT_PX}px;
        color: transparent;

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            height: ${TRACK_HEIGHT_PX__LARGE_SCREENS}px;
        `}
    }

    &::-ms-fill-upper {
        border: 0;
        border-radius: 4px;
        background: transparent;
        user-select: none;
    }

    &::-ms-fill-lower {
        border: 0;
        border-radius: 4px;
        background: ${ares};
        user-select: none;
    }

    &::-ms-tooltip {
        display: none;
    }

    &::-moz-focus-outer {
        border: 0;
    }

    &.tab-focus:focus {
        outline-offset: 3px;
    }

    &:active { /* Pressed styles */
        &::-webkit-slider-thumb {
            border-color: ${blanc};
        }

        &::-moz-range-thumb {
            border-color: ${blanc};
        }

        &::-ms-thumb {
            border-color: ${blanc};
        }
    }

    &:focus { /* Focus styles: e.g. pressed or keyboard focus */
        outline: 0;

        &::-webkit-slider-thumb {
            transform: scale(1.3);
        }

        &::-moz-range-thumb {
            transform: scale(1.3);
        }

        &::-ms-thumb {
            transform: scale(1.3);
        }
    }
`;

export default class SliderControlScrubber extends React.PureComponent {
    static displayName = 'SliderControlScrubber';

    static propTypes = {
        // eslint-disable-next-line react/boolean-prop-naming
        disabled: propTypes.bool,
        displayTime: propTypes.number,
        duration: propTypes.number,
        id: propTypes.string,
        step: propTypes.oneOfType([
            propTypes.number,
            propTypes.oneOf(['any']), // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attr-step
        ]),

        onChange: propTypes.func,
        onMouseDownUp: propTypes.func,
        onChangeHover: propTypes.func,
    };

    static defaultProps = {
        displayTime: 0,
        duration: 0,
        step: 'any',
        onChange: noop,
        onChangeHover: noop,
        onMouseDownUp: () => noop,
    };

    componentDidUpdate() {
        // Every prop update, we will update the value held by the input
        // We don't want to directly bind the value however, or it'll prevent it from being interactive.
        // See https://reactjs.org/docs/uncontrolled-components.html#default-values for more details
        this.scrubberRef.current.value = this.props.displayTime;
    }

    scrubberRef = React.createRef();

    mouseDown = () => {
        this.isMouseDown = true;
        this.props.onChangeHover({
            isHovering: false,
            hoverTime: this.hoverTime,
        });

        this.releaseMouseEvent = this.props.onMouseDownUp();
    };

    mouseUp = () => {
        if (this.isMouseDown) {
            this.isMouseDown = false;
            this.releaseMouseEvent();
        }
    };

    mouseMove = (e) => {
        if (!this.isMouseDown) {
            const xPositionRelativeToContainer = e.clientX - e.target.getBoundingClientRect().left - (PLAYHEAD_WIDTH_PX / 2);
            const width = e.target.clientWidth - PLAYHEAD_WIDTH_PX;

            this.hoverTime = this.props.duration * clamp(xPositionRelativeToContainer / width, 0, 1);

            this.props.onChangeHover({
                isHovering: true,
                hoverTime: this.hoverTime,
            });
        }
    };

    mouseLeave = () => {
        this.props.onChangeHover({
            isHovering: false,
            hoverTime: this.hoverTime || 0,
        });
    };

    render() {
        return (
            <RangeTouch thumbWidth={PLAYHEAD_WIDTH_PX}>
                <Range
                    className="ares-player-scrubber"
                    defaultValue={this.props.displayTime}
                    id={this.props.id}
                    max={this.props.duration}
                    min="0"
                    onChange={this.props.onChange}
                    onMouseDown={this.mouseDown}
                    onTouchStart={this.mouseDown}
                    onMouseLeave={this.mouseLeave}
                    onMouseMove={this.mouseMove}
                    onTouchMove={this.mouseMove}
                    onMouseUp={this.mouseUp}
                    onTouchEnd={this.mouseUp}
                    step={this.props.step}
                    type="range"
                    aria-label="seek to time in video"
                    disabled={this.props.disabled}
                    ref={this.scrubberRef}
                />
            </RangeTouch>
        );
    }
}
