import React from 'react';
import propTypes from 'prop-types';
import {Input} from 'normalized-styled-components';
import {rgba} from 'polished';
import styled from 'styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {fontFamily} from '../../../../common/typography';
import {akela, blanc, panther} from '../../../../common/palette';
import {SCREEN_375_PHABLET, SCREEN_1280_DESKTOP} from '../../../../common/screen-sizes';

const StyledInput = styled(Input)`
    border: 0;
    background-color: ${rgba(blanc, 0.2)};
    width: 40px;
    height: 46px;
    text-align: center;
    text-shadow: 0 1px 1px ${panther};
    color: ${akela};
    font-family: ${fontFamily};
    font-size: 28px;

    ${mediaQuery({minWidthPx: SCREEN_375_PHABLET})`
        width: 50px;
        height: 57px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        width: 68px;
        height: 77px;
        font-size: 38px;
    `}

    &:focus,
    &:hover {
        outline: 0;
        background-color: ${rgba(blanc, 0.4)};
    }

    &:disabled {
        background-color: ${rgba(blanc, 0.1)};
        cursor: not-allowed;
    }

    &::selection {
        background: none;
    }

    :not(:last-of-type) {
        margin-right: 6px;

        ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
            margin-right: 8px;
        `}
    }
`;

const IA06NumberBox = React.forwardRef(({
    'aria-label': ariaLabel,
    disabled,
    onChange,
    onKeyDown,
}, ref) => (
    <StyledInput
        aria-label={ariaLabel}
        disabled={disabled}
        ref={ref}
        onChange={onChange}
        onKeyDown={onKeyDown}
        pattern="[0-9]*"
        inputMode="numeric"
        type="text"
    />
));

IA06NumberBox.propTypes = {
    /** If the field is in a disabled state */
    'disabled': propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    /** Change callback */
    'onChange': propTypes.func,
    /** KeyDown callback */
    'onKeyDown': propTypes.func,
    /** The screenreader assistive label of the input */
    'aria-label': propTypes.string.isRequired,
};

IA06NumberBox.displayName = 'IA06NumberBox';

export default IA06NumberBox;
