import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {white} from '../../../../common/palette';
import {midnight, satellite} from '../../../../common/deprecated-mui-palette';

const StyledHeading = styled.h3`
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-gap: 14px;
    align-items: center;
    margin: 0 0 14px;
    text-align: center;
    color: ${({isLightTheme}) => isLightTheme ? midnight : white};
    font: var(--nucleus-header-6-bold);

    &::before,
    &::after {
        display: block;
        position: relative;
        top: 0%;
        background: ${({isLightTheme}) => isLightTheme ? midnight : white};
        height: 1px;
        content: '';
    }
`;

const StyledFooter = styled.footer`
    text-align: center;
    color: ${({isLightTheme}) => isLightTheme ? midnight : satellite};
    font: var(--nucleus-body-copy-5);
`;

const FeatureListWrapper = styled.div`
    &:not(:last-child) {
        margin-bottom: 21px;
    }
`;

const ScreenOptionsWrapper = styled.div`
    &:not(:last-child) {
        margin-bottom: 35px;
    }
`;

const OR13FeaBlk = ({
    isLightTheme,
    className,
    featureList,
    finePrint,
    screenOptions,
    title,
    ...htmlAttributes
}) => (
    <section
        {...htmlAttributes}
        className={classnames('OR13FeaBlk', className)}
    >
        {!!screenOptions && (
            <ScreenOptionsWrapper>
                {screenOptions}
            </ScreenOptionsWrapper>
        )}

        {!!title && (
            <StyledHeading isLightTheme={isLightTheme}>
                {title}
            </StyledHeading>
        )}

        {!!featureList && (
            <FeatureListWrapper>
                {featureList}
            </FeatureListWrapper>
        )}

        {!!finePrint && (
            <StyledFooter isLightTheme={isLightTheme}>
                {finePrint}
            </StyledFooter>
        )}
    </section>
);

OR13FeaBlk.propTypes = {
    /** Light or dark (default) theme */
    isLightTheme: propTypes.bool,
    /** Additional class name(s) */
    className: classNameType,
    /** e.g. CM03FeatureList */
    featureList: propTypes.node,
    /** Fine print footer */
    finePrint: propTypes.node,
    /** e.g. CM04FeatureBlock */
    screenOptions: propTypes.node,
    /** Prominent text title */
    title: propTypes.node,
};

OR13FeaBlk.displayName = 'OR13FeaBlk';

export default OR13FeaBlk;
