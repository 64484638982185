import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {mediaQuery, stylesIfElse} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {cobalt, ink, white} from '../../../../common/palette';
import {SCREEN_375_PHABLET, SCREEN_768_TABLET, SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import {Section} from '../../../../common/normalized-styled-components';
import IC42Error from '../../../atoms/ic/42-error';
import BA28BtnSec from '../../../atoms/ba/28-btn-sec';

const Tile = styled.div.attrs({
    role: 'alert',
})`
    position: relative;
    text-align: center;
    ${stylesIfElse('isBlock')`
        width: 100%;
    ``
        min-width: 310px;

        ${mediaQuery({minWidthPx: SCREEN_375_PHABLET})`
            min-width: 334px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
            min-width: 580px;
        `}
    `}
`;

const IconWrapper = styled.div`
    margin-right: auto;
    margin-left: auto;
    width: 25px;
    height: 25px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: 36px;
        height: 36px;
    `}
`;

const Heading = styled(Section)`
    display: grid;
    grid-template-columns: auto 25px;
    grid-gap: 11px;
    align-items: center;
    border-radius: 6px 6px 0 0;
    background-color: ${cobalt};
    color: ${white};
    font: var(--quicksilver-body-copy-4-medium);

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        grid-template-columns: auto 36px;
    `}

    ${stylesIfElse('isCompactHeading')`
        height: 42px;
        padding: 0 11px 0 47px;

        ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
            height: 58px;
            padding: 0 11px 0 58px;
        `}
    ``
        padding: 14px 11px 14px 47px;

        ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
            padding: 17px 11px 17px 58px;
        `}
    `}
`;

const AlertMessage = styled.div`
    margin: 0;
    border-radius: 0 0 6px 6px;
    background-color: ${white};
    text-align: center;
    color: ${ink};
    font: var(--quicksilver-body-copy-4-light);

    ${stylesIfElse('isCompactHeading')`
        padding: 13px 25px 14px 28px;

        ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
            padding: 28px 28px 29px 41px;
        `}
    ``
        padding: 21px 36px;

        ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
            padding: 28px 70px;
        `}
    `}
`;

const ButtonContainer = styled.div`
    margin-top: 14px;
    text-align: center;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        margin-top: 28px;
    `}
`;

const StyledBA28BtnSec = styled(BA28BtnSec)`
    width: 204px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: 250px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 338px;
    `}
`;

/**
 * An alert tile with heading, message, and an optional call-to-action button.
 *
 * @param {string} className - Additional class(es) to be applied to the component.
 * @param {boolean} isBlock - Whether the tile fills its parent's width or not.
 * @param {string} heading - Heading text for the alert tile.
 * @param {node} icon - Icon to be displayed beside the heading.
 * @param {node} children - The message to be displayed in the alert tile.
 * @param {string} ctaCopy - Copy to use for call-to-action button.
 * @param {function} onButtonClick - A callback function to be called when the call-to-action button is clicked.
 * @param {boolean} isCompactHeading - Whether to have a lower heading or not
 * @returns {JSX.Element} - A React element representing the alert tile.
 */
const TM24AlertTile = ({
    className,
    isBlock = true,
    heading,
    icon = <IC42Error />,
    children,
    ctaCopy,
    onButtonClick,
    isCompactHeading = false,
    ...htmlAttributes
}) => (
    <Tile
        {...htmlAttributes}
        className={classnames('TM24AlertTile', className)}
        isBlock={isBlock}
    >
        <Heading isCompactHeading={isCompactHeading}>
            {heading}
            <IconWrapper>
                {icon}
            </IconWrapper>
        </Heading>
        <AlertMessage isCompactHeading={isCompactHeading}>
            {children}
            {!!ctaCopy && (
                <ButtonContainer>
                    <StyledBA28BtnSec onClick={onButtonClick}>
                        {ctaCopy}
                    </StyledBA28BtnSec>
                </ButtonContainer>
            )}
        </AlertMessage>
    </Tile>
);

TM24AlertTile.propTypes = {
    /** Additional class(es) */
    className: classNameType,
    /** The message to be displayed in the alert tile. */
    children: propTypes.node.isRequired,
    /** Whether the tile fills its parent's width or not */
    isBlock: propTypes.bool,
    /** Heading text for the alert tile. */
    heading: propTypes.string,
    /** Icon to be displayed beside the heading. */
    icon: propTypes.node,
    /** Copy to use for CTA */
    ctaCopy: propTypes.string,
    /** A callback function */
    onButtonClick: propTypes.func,
    /** Whether to have a lower heading or not */
    isCompactHeading: propTypes.bool,
};

TM24AlertTile.displayName = 'TM24AlertTile';

export default TM24AlertTile;
