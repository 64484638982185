import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import GA89Tile from '../../../atoms/ga/89-tile';
import XX02Feature from '../../../atoms/xx/02-feature';
import XX03FeatureList from '../../xx/03-feature-list';

const TM11VoucherTile = ({
    tagline,
    shortDescription,
    className,
    ...htmlAttributes
}) => (
    <GA89Tile
        title="Promotion applied"
        className={classnames('TM11VoucherTile', className)}
        {...htmlAttributes}
    >
        <XX03FeatureList isBlock={true}>
            {!!tagline && (
                <XX02Feature>
                    {tagline}
                </XX02Feature>
            )}

            {!!shortDescription && (
                <XX02Feature icon={null}>
                    {shortDescription}
                </XX02Feature>
            )}
        </XX03FeatureList>
    </GA89Tile>
);

TM11VoucherTile.displayName = 'TM11VoucherTile';

TM11VoucherTile.propTypes = {
    /** Voucher taglinne */
    tagline: propTypes.string,
    /** Voucher short description */
    shortDescription: propTypes.string,
    /** Optional additional class(es) */
    className: classNameType,
};

export default TM11VoucherTile;
