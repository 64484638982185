/** Blur effect usually used in
 * - header nav and panels stuck next to it like cascade carousel
 * - appdock
 * - tile description
 **/
const backdropFilter = `
    /* Where supported https://caniuse.com/css-backdrop-filter */
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        --webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
    }
`;

export default backdropFilter;
