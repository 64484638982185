import styled from 'styled-components';
import {rgba} from 'polished';

import {Hr} from '../../../../common/normalized-styled-components';
import {ink} from '../../../../common/palette';

/**
 * Horizontal divider
 *  - Used in My Account
 *
 * @see {@link https://zpl.io/dxqvG3E}
 */
const GA20PixelDiv = styled(Hr)`
    margin: 0;
    border-width: 1px 0 0;
    border-style: solid;
    border-color: ${rgba(ink, 0.1)};
`;

GA20PixelDiv.displayName = 'GA20PixelDiv';

export default GA20PixelDiv;
