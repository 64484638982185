import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {Button} from 'normalized-styled-components';
import {rgba} from 'polished';
import classnames from 'classnames';

import {ariaPressedType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {lifestyle, blanc, crimson, midnight} from '../../../../common/palette';
import {transition} from '../../../../common/animations';
import {fontFamily} from '../../../../common/typography';

import IC31Tick from '../../ic/31-tick';
import IC51Close from '../../ic/51-close';

const StyledButton = styled(Button)`
    appearance: none;
    position: relative;
    transition: ${transition('background-color', 'color')};
    border: 0;
    background-color: transparent;
    padding: 0;
    width: 298px;
    height: 58px;
    color: ${rgba(blanc, 0.7)};
    font: 300 16px/1.25 ${fontFamily};

    &:hover,
    &:focus,
    &[aria-pressed='true'] {
        color: ${midnight};
    }

    &[aria-pressed='true'] {
        background-color: ${blanc};
        font-weight: 500;
    }
    /* Put hover/focus styles last so they still work when button is in active state */
    /* stylelint-disable-next-line order/order */
    &:hover,
    &:focus {
        outline: 0;
        background-color: ${rgba(blanc, 0.8)};
    }
`;

const ContentWrapper = styled.span`
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
`;

const LeftSection = styled.span`
    height: 100%;
`;

const RightSection = styled.span`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border: 1px solid ${rgba(blanc, 0.7)};
    padding: 0 15px;
    width: 100%;
    height: 100%;

    /* stylelint-disable-next-line declaration-block-semicolon-newline-after */
    ${LeftSection} + & { /* stylelint-disable-line rule-empty-line-before */
        border-left: none;
    }
`;

const iconBaseStyles = css`
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    pointer-events: none;
`;

const StyledIC31Tick = styled(IC31Tick)`
    ${iconBaseStyles}
    color: ${lifestyle};
`;

const StyledIC51Close = styled(IC51Close)`
    ${iconBaseStyles}
    color: ${crimson};
`;

const BA41RadioGraphic = React.forwardRef(({
    'aria-pressed': isPressed,
    className,
    leftContent,
    children,
    ...htmlAttributes
}, ref) => (
    <StyledButton
        {...htmlAttributes}
        className={classnames('BA41RadioGraphic', className)}
        ref={ref}
        aria-pressed={isPressed}
        type="button"
    >
        <ContentWrapper>
            {!!leftContent && <LeftSection>{leftContent}</LeftSection>}

            <RightSection>{children}</RightSection>

            {isPressed ? <StyledIC31Tick size="17px" /> : <StyledIC51Close size="17px" />}
        </ContentWrapper>
    </StyledButton>
));

/* eslint-disable quote-props */
BA41RadioGraphic.propTypes = {
    /** aria-pressed attribute describes a toggle button */
    'aria-pressed': ariaPressedType,
    /** additional CSS classnames to be applied */
    className: classNameType,
    /** Content to show on the left hand side i.e. a graphic */
    leftContent: propTypes.node,
    /** Content to show on the right hand side */
    children: propTypes.node,
};

BA41RadioGraphic.displayName = 'BA41RadioGraphic';

export default BA41RadioGraphic;
