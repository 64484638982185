import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

const StyledSvg = styled.svg`
    display: block;
    width: auto;
    height: 100%; /* Scaled by HEIGHT :O */
    fill: currentColor;
`;

const GA1094k = ({
    className,
    ...htmlAttributes
}) => (
    <StyledSvg
        {...htmlAttributes}
        className={classnames('GA1094k', className)}
        viewBox="0 0 167 100"
    >
        <path d="M166.666667 0v100H0V0h166.666667zM70.8190476 23.8095238H57.1809524L33.3333333 57.0285714v8.9904762h25.6v10.5142857h11.8857143V66.0190476h7.0095238v-9.5238095h-7.0095238V23.8095238zm29.4095234.152381H87.9619048v52.5714285h12.2666662V59.2380952l4.190477-4.647619L117.6 76.5333333h14.780952l-19.809523-31.6190476 18.514285-20.9523809h-14.628571l-16.228572 19.2v-19.2zM59.2380952 36.3809524v20.3428571H44.5333333l14.7047619-20.3428571z" />
    </StyledSvg>
);

GA1094k.propTypes = {
    /** Additional class(es) */
    className: classNameType,
};

GA1094k.displayName = 'GA1094k';

export default GA1094k;
