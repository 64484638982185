import React from 'react';
import Ic from '..';

const IC156MatchSummary = (args) => (
    <Ic {...args}>
        <path fill="none" d="M0 0h100v100H0z" />
        <path d="M54.25 45.089h25.609v-2.771H54.25v2.771zm0-13.299h17.073v-2.77H54.25v2.77zm0 27.981h34.146V54.23H54.25v5.541zm0 13.299h17.073v-5.54H54.25v5.54zM19.793 58.387h25.609v-2.771H19.793v2.771zm8.536 13.299h17.073v-2.771H28.329v2.771zM11.256 46.474h34.146v-5.541H11.256v5.541zm17.073-13.299h17.073v-5.541H28.329v5.541z" />
    </Ic>
);

IC156MatchSummary.displayName = 'IC156MatchSummary';

export default IC156MatchSummary;
