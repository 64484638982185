import React, {useState} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {ink} from '../../../../common/palette';

import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';

const Container = styled.div`
    opacity: 0.6;
    color: ${ink};
`;

const StyledDetails = styled.details`
    font: var(--quicksilver-body-copy-3);
`;

const StyledSummary = styled.summary`
    display: grid;
    grid-template-columns: 95% 5%;
    margin-bottom: 22px;
    cursor: pointer;
    list-style-type: none;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        margin-bottom: 32px;
    `}
`;

const IconContainer = styled.div`
    justify-self: center;
    width: 17px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 22px;
    `}
`;

/**
 * GM22ExpandForMore
 *  - Used in My Account and Checkout
 *
 * @see {@link https://zpl.io/AmXJ0nB}
 */
const GM22ExpandForMore = ({
    collapseIcon,
    expandIcon,
    label,
    isInitiallyExpanded = false,
    children,
    className,
    ...htmlAttributes
}) => {
    const [isExpanded, setIsExpanded] = useState(isInitiallyExpanded);

    const handleToggleExpand = (event) => {
        event.preventDefault();
        setIsExpanded(!isExpanded);
    };

    return (
        <Container
            {...htmlAttributes}
            className={classnames('GM22ExpandForMore', className)}
        >
            <StyledDetails open={isExpanded} onClick={handleToggleExpand}>
                <StyledSummary>
                    {label}
                    <IconContainer>{isExpanded ? collapseIcon : expandIcon}</IconContainer>
                </StyledSummary>
                {children}
            </StyledDetails>
        </Container>
    );
};

GM22ExpandForMore.displayName = 'GM22ExpandForMore';

GM22ExpandForMore.propTypes = {
    /** Icon for collapsed state */
    collapseIcon: propTypes.node,
    /** Icon for expanded state */
    expandIcon: propTypes.node,
    /** Expander label */
    label: propTypes.string,
    /** Is expander expanded upon loading? */
    isInitiallyExpanded: propTypes.bool,
    /** Expander content */
    children: propTypes.node,
    /** Passed down classnames */
    className: propTypes.string,
};

export default GM22ExpandForMore;
