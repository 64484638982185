/** Default footnote marker for price changes / disclaimers */
export const FOOTNOTE_MARKER = '*';

/**
 * Generate footenote marker
 *
 * @param {Object} options options
 * @param {String} options.marker footnote symbol / char currently defaulted to `*`
 * @param {number} options.count number of char
 *
 * @returns {String} footnoteMarker
 */
export default function generateFootnoteMarker({marker = FOOTNOTE_MARKER, count}) {
    return marker.repeat(count);
}
