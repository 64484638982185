import React from 'react';
import {Svg} from 'normalized-styled-components';
import classnames from 'classnames';

import {colorType, classNameType} from '@fsa-streamotion/custom-prop-types';

const GA43ArrowInd = ({
    className,
    color = 'currentColor',
    ...htmlAttributes
}) => (
    <Svg
        {...htmlAttributes}
        className={classnames('GA43ArrowInd', className)}
        width="9"
        height="14"
        xmlns="http://www.w3.org/2000/svg"
        style={{
            fill: color,
        }}
    >
        <path
            d="M6.532 7.245L.319 12.681l.988 1.128L8.81 7.245 1.307.681.32 1.809z"
            fillRule="nonzero"
        />
    </Svg>
);

GA43ArrowInd.displayName = 'GA43ArrowInd';

GA43ArrowInd.propTypes = {
    /** Additional class(es) */
    className: classNameType,
    /** The fill color of the arrow */
    color: colorType,
};

export default GA43ArrowInd;
