import React from 'react';
import propTypes from 'prop-types';
import {Button} from 'normalized-styled-components';
import noop from 'lodash/noop';
import {rgba} from 'polished';
import styled from 'styled-components';
import classnames from 'classnames';

import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {kayoGreen, coal} from '../../../../common/palette';
import {transition} from '../../../../common/animations';
import IC49Open from '../../ic/49-open';

const StyledButton = styled(Button)`
    appearance: none;
    display: flex;
    position: relative;
    justify-content: center;
    border: 0;
    border-radius: 4px;
    background-color: ${rgba(coal, 0.35)};
    padding: 12px;
    width: 98px;
    height: 98px;
    color: ${kayoGreen};

    ${stylesWhen('isBlock')`
        width: 100%;
        height: calc(100% - 2px); /* if height is 100%, the ::after sits underneath that, and this button in App Dock gets used in a parent with overflow: hidden; so the focussed styling doesn’t appear. */
    `}

    &::after {
        position: absolute;
        right: 0;
        bottom: -2px;
        left: 0;
        transition: ${transition('background-color')};
        border-radius: 1px;
        background-color: transparent;
        height: 2px;
        content: '';
    }

    /* stylelint-disable-next-line order/order */
    &:focus,
    &:hover {
        position: relative;
        outline: 0;

        &::after {
            background-color: ${kayoGreen};
        }
    }
`;

const StyledIC49Open = styled(IC49Open)`
    margin: auto;
`;

const BA05AddBtn = ({
    'aria-label': assistiveLabel,
    isBlock,
    className,
    onClick = noop,
    ...htmlAttributes
}) => (
    <StyledButton
        {...{
            ...htmlAttributes,
        }}
        {...{
            isBlock,
            onClick,
        }}
        className={classnames('BA05AddBtn', className)}
        aria-label={assistiveLabel}
        type="button"
    >
        <StyledIC49Open size="66%" />
    </StyledButton>
);

BA05AddBtn.displayName = 'BA05AddBtn';

/* eslint-disable quote-props */
BA05AddBtn.propTypes = {
    /** Assistive label required for screen readers due to graphical only representation of content */
    'aria-label': propTypes.string.isRequired,
    /** Whether the button fills its parent's width or not */
    isBlock: propTypes.bool,
    /** Callback to call on element click */
    onClick: propTypes.func,
    /** Additional CSS class(es) */
    className: classNameType,
};
/* eslint-enable quote-props */

export default BA05AddBtn;
