// Zeplin: https://zpl.io/mDrAzJ6

// Core colours
export const hubblGreen = '#27f6b0';
export const ink = '#01013f';
export const indigo = '#0026a8';
export const black = '#000000';

// Secondary colours
export const cobalt = '#2b65ff';
export const cloudy = '#f5f5f7';
export const mist = '#dfdfe3';
export const slate = '#67677a';
export const moonlight = '#e7e7eb';
export const rainCloud = '#eeeef0';

// Functional colours
export const errorState = '#ed1f3a';

// Additional colours after swap
export const white = '#ffffff';
export const deepMint = '#00d696';
export const deepIndigo = '#001f94';

// Gradients
export const duskGradient = {
    top: '#0026a8',
    bottom: '#2b65ff',
};
export const auroraGradient = {
    top: '#27f6b0',
    bottom: '#2b65ff',
};

export const gradients = {
    dusk: duskGradient,
    aurora: auroraGradient,
};

/** Palette */
export default {
    hubblGreen,
    ink,
    indigo,
    black,
    cobalt,
    cloudy,
    mist,
    slate,
    moonlight,
    rainCloud,
    errorState,
    white,
    deepMint,
    deepIndigo,
};
