import React from 'react';
import {ThemeProvider} from 'styled-components';
import get from 'lodash/get';
import property from 'lodash/property';

/**
 * Font matrix helper for styled components.
 *
 * When the font matrix is provided by <code>props.theme.font</code>, this will set the font according to the specified
 * entry in the font matrix, if it exists.
 *
 * @example
 * const StyledDiv = styled.div`
 *     ${themeFont('bodyCopy4')}
 * `;
 *
 * function MyComponent() {
 *     return (
 *         <ThemeProvider theme={{fonts: {bodyCopy4: {size: 20, height: 0.83}}}}>
 *             <StyledDiv>
 *                 Some text
 *             </StyledDiv>
 *         </ThemeProvider>
 *     );
 * }
 *
 * @param {string} name - the name of the font matrix entry
 * @returns {Function} the
 */
export function font(name) {
    return ({theme}) => get(theme, ['fonts', name]);
}

/**
 * Generate a styled components <code>ThemeProvider</code> to provide the specified font matrix via the
 * <code>themeFont</code> function.
 *
 * @param {Object}     fontMatrix - the font matrix to provide
 * @param {Function}   [fontSizeToString] - a function that converts the font size, in pixels, to its string value
 *                                          including its units, defaulting to appending px to the font size
 * @returns {Function} the theme provider
 */
export function createFontMatrixProvider(fontMatrix, fontSizeToString = (size) => `${size}px`) {
    // Precompute CSS rules for the entire font matrix
    const fonts = Object
        .entries(fontMatrix)
        .filter(property('1.size'))
        .reduce((acc, [name, {size, height, weight}]) => ({
            ...acc,
            [name]: `font: ${weight || 'normal'} ${fontSizeToString(size)}/${height || 1} Gibson, sans-serif;`,
        }), {});

    function FontMatrixProvider({children}) { // eslint-disable-line react/prop-types
        return (
            <ThemeProvider theme={{fonts}}>
                {children}
            </ThemeProvider>
        );
    }

    FontMatrixProvider.displayName = 'FontMatrixProvider';

    return FontMatrixProvider;
}
