import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import get from 'lodash/get';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {silverLining} from '../../../../common/palette';

const Container = styled.div`
    margin: 7px 0 14px;
`;

const Title = styled.h3`
    margin: 0 0 14px;
    text-align: center;
    color: ${silverLining};
    font: var(--mui-hud-tile-header-bold);
`;

const Columns = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 28px;
    color: ${silverLining};
    font: var(--mui-hud-tile-copy);
`;

const ColumnList = styled.ol`
    margin: 0;
    padding-left: 0;
    list-style: none;
`;

const LeftColumnList = styled(ColumnList)`
    text-align: right;
`;

const IGM29MatchMo = ({
    title,
    teamALines = [],
    teamBLines = [],
    className,
}) => (
    <Container className={classnames('IGM29MatchMo', className)}>
        {!!title && (
            <Title>{title}</Title>
        )}
        <Columns>
            <LeftColumnList>
                {teamALines.map((node, index) => (
                    // For the key, if the node is a react element with a key, use that
                    // Otherwise, key on the stringified node and index
                    <li key={get(node, 'key', `${node}__${index}`)}>
                        {node}
                    </li>
                ))}
            </LeftColumnList>
            <ColumnList>
                {teamBLines.map((node, index) => (
                    // For the key, if the node is a react element with a key, use that
                    // Otherwise, key on the stringified node and index
                    <li key={get(node, 'key', `${node}__${index}`)}>
                        {node}
                    </li>
                ))}
            </ColumnList>
        </Columns>
    </Container>
);

IGM29MatchMo.displayName = 'IGM29MatchMo';

IGM29MatchMo.propTypes = {
    title: propTypes.string,
    teamALines: propTypes.arrayOf(propTypes.node),
    teamBLines: propTypes.arrayOf(propTypes.node),
    className: classNameType,
};

export default IGM29MatchMo;
