import bacon from 'baconjs';
import {getLocalStorageValue} from '@fsa-streamotion/browser-utils';

import {ENVIRONMENT} from '../../utils/constants';
import {
    LOCALSTORAGE_KEY_ID_TOKEN_OVERRIDE,
    LOCALSTORAGE_KEY_AUTH0_ACCESS_TOKEN_OVERRIDE,
    LOCALSTORAGE_KEY_ENTITLED_ACCESS_TOKEN_OVERRIDE,
} from '../constants';
import decodeToken from './decode-token';

/**
 * @typedef Auth0ClientOverrides
 * @property {() => Promise<import('@auth0/auth0-spa-js').IdToken>} _getIdTokenDetails - Resolves details from IdToken
 * @property {() => Promise<string>} _getAuth0AccessToken   - Resolves access_token (Auth0)
 * @property {() => Promise<string>} getAccessToken         - Resolves access_token (Entitled)
 * @property {() => Promise<string>} refreshAccessToken     - Resolves refreshed access_token (Entitled)
 * @property {() => Promise<boolean>} isAuthenticated       - Resolves `true` when user is authenticated
 */

/**
 * Checks whether an E2E test is running by validating that the environment is "staging" and
 * we have all required pre-generated OIDC tokens added to local storage. When an E2E test is running
 * we bypass specific functions required to "get" tokens from Auth0 and Token Service API, and instead
 * we simply return the ones we already have. This ensures we are already in a "logged in" state.
 *
 * @param {string} platformEnv                                  - e.g. production or staging
 * @param {string} clientId                                     - The ID of the Auth0 client
 * @returns {[boolean, null | Function<Auth0ClientOverrides>]}  - Tuple bearing E2E enabled flag and optional methods to override
 */
export default function createE2eOverridesIfEnabled({platformEnv, clientId}) {
    if (platformEnv !== ENVIRONMENT.staging) {
        return [false, null];
    }

    const getE2eTestOverrideEntitledAccessToken = getLocalStorageValue({
        key: `${clientId}_${LOCALSTORAGE_KEY_ENTITLED_ACCESS_TOKEN_OVERRIDE}`,
        defaultValue: null,
    });
    const getE2eOverrideAuth0AccessToken = getLocalStorageValue({
        key: `${clientId}_${LOCALSTORAGE_KEY_AUTH0_ACCESS_TOKEN_OVERRIDE}`,
        defaultValue: null,
    });
    const getE2eTestOverrideIdToken = getLocalStorageValue({
        key: `${clientId}_${LOCALSTORAGE_KEY_ID_TOKEN_OVERRIDE}`,
        defaultValue: null,
    });

    if (
        !getE2eTestOverrideEntitledAccessToken
        || !getE2eOverrideAuth0AccessToken
        || !getE2eTestOverrideIdToken
    ) {
        return [false, null];
    }

    return [true, () => ({
        //  Ensures any accessToken$ calls emit E2E token
        _getTokenStream() {
            return bacon.constant(getE2eTestOverrideEntitledAccessToken);
        },
        _getIdTokenDetails() {
            return Promise.resolve(decodeToken(getE2eTestOverrideIdToken));
        },
        _getAuth0AccessToken() {
            return Promise.resolve(getE2eOverrideAuth0AccessToken);
        },
        getAccessToken() {
            return Promise.resolve(getE2eTestOverrideEntitledAccessToken);
        },
        refreshAccessToken() {
            return Promise.resolve(getE2eTestOverrideEntitledAccessToken);
        },
        isAuthenticated() {
            return Promise.resolve(true);
        },
    })];
}
