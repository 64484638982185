/* eslint-disable max-len, react/jsx-max-props-per-line */

import React from 'react';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import Ic from '../../ic';

// @TODO: Update when final SVGs are provided - using Flash ones for now

/**
 * Optus SubHub Logo
 * - Used in My Account
 */
const GA151Optus = ({
    className,
    ...htmlAttributes
}) => (
    <Ic
        {...htmlAttributes}
        className={classnames('GA151Optus', className)}
        preserveColors={true}
        ariaLabel="Optus SubHub"
    >
        <g fill="none" fillRule="evenodd">
            <rect width="100" height="81.9" fill="#FAFAFA" rx="5" />
            <path fill="#00828A" fillRule="nonzero" d="M20.184 40.614c-2.72-1.444-4.822-1.826-7.247-1.826-4.191 0-7.167 2.122-7.231 5.478-.064 3.11 1.937 4.713 5.63 5.268l.896.136c2.348.345 3.553.642 3.553 1.678 0 1.073-1.231 1.604-2.848 1.604-2.526 0-4.422-.913-5.796-1.814L5 54.494c1.987 1.444 4.912 2.196 7.9 2.196 3.847 0 7.529-1.715 7.529-5.823 0-2.826-2.194-4.393-5.683-4.973l-.744-.123c-1.986-.333-3.693-.592-3.693-1.678 0-1.024 1.193-1.555 2.821-1.555 2.103 0 3.86.716 5.143 1.456l1.91-3.38zm13.102 5.846h-3.39v5.415c0 1.27-.739 1.849-1.75 1.849-1.02 0-1.746-.58-1.746-1.849V46.46H23v5.69c0 3.128 2.303 4.54 5.147 4.54 2.836 0 5.139-1.412 5.139-4.53v-5.7zm9.779-2.397c-1.558 0-2.621.61-3.35 1.478v-6.753h-3.858v17.585h3.735V55.09c.728.94 1.83 1.6 3.473 1.6 3.24 0 5.65-2.785 5.65-6.326 0-3.518-2.41-6.302-5.65-6.302zm-.928 3.566c1.558 0 2.598 1.185 2.598 2.748 0 1.563-1.04 2.747-2.598 2.747-1.519 0-2.582-1.16-2.582-2.747 0-1.588 1.063-2.748 2.582-2.748z" />
            <path fill="#00A3AD" fillRule="nonzero" d="M55.384 38.788h-4.098V56.69h4.098v-7.058h7.232v7.058h4.098V38.788h-4.098v6.892h-7.232zm24.187 7.672h-3.387v5.415c0 1.27-.739 1.849-1.75 1.849-1.02 0-1.749-.58-1.749-1.849V46.46h-3.4v5.69c0 3.128 2.303 4.54 5.15 4.54 2.836 0 5.136-1.412 5.136-4.53v-5.7zm9.779-2.397c-1.558 0-2.62.61-3.349 1.478v-6.753h-3.858v17.585h3.734V55.09c.729.94 1.828 1.6 3.473 1.6 3.24 0 5.65-2.785 5.65-6.326 0-3.518-2.41-6.302-5.65-6.302zm-.928 3.566c1.558 0 2.596 1.185 2.596 2.748 0 1.563-1.038 2.747-2.596 2.747-1.518 0-2.584-1.16-2.584-2.747 0-1.588 1.066-2.748 2.584-2.748z" />
            <path fill="#00A3AD" d="M37.758 30.486c0 1.013-.717 1.495-1.743 1.495-1.027 0-1.697-.482-1.697-1.495v-4.043c0-.177-.036-.265-.248-.325-.165-.049-.445-.088-.814-.088-.37 0-.65.04-.815.088-.212.06-.247.148-.247.325v4.102c0 2.026 1.442 3.128 3.82 3.128 2.39 0 3.843-1.102 3.843-3.128v-4.102c0-.177-.033-.265-.247-.325-.166-.049-.435-.088-.804-.088-.367 0-.635.04-.804.088-.212.06-.244.148-.244.325v4.043zM27.27 33.633c.37 0 .65-.04.815-.088.214-.06.247-.148.247-.325V27.8h2.213c.212 0 .288-.068.345-.216.043-.118.09-.334.09-.61 0-.275-.047-.492-.09-.61-.057-.147-.133-.216-.345-.216h-6.547c-.212 0-.29.07-.345.216-.046.118-.09.335-.09.61 0 .276.044.492.09.61.055.148.133.217.345.217h2.213v5.42c0 .176.033.265.245.324.168.049.445.088.814.088zM7.21 29.836c0 1.27 1.007 2.144 2.413 2.144 1.407 0 2.411-.875 2.411-2.144 0-1.269-1.004-2.144-2.41-2.144-1.407 0-2.415.875-2.415 2.144zm-2.211 0C5 27.623 6.909 26 9.624 26c2.713 0 4.624 1.623 4.624 3.836s-1.911 3.836-4.624 3.836C6.91 33.672 5 32.05 5 29.836zm14.44-.068h-1.62v-2.026h1.62c.758 0 1.238.344 1.238 1.013 0 .649-.48 1.013-1.238 1.013zm.033-3.62h-3.495c-.168 0-.28.089-.28.246v6.826c0 .177.033.266.245.325.168.049.448.088.817.088.367 0 .646-.04.815-.088.212-.06.244-.148.244-.325v-1.888h1.675c2.178 0 3.34-1.043 3.34-2.577 0-1.574-1.162-2.607-3.361-2.607zm25.5 7.525c-1.25 0-2.659-.286-3.742-.876-.144-.078-.212-.177-.155-.354.111-.354.424-.865.646-1.062.147-.128.237-.138.427-.05 1.015.473 1.898.728 2.77.728.947 0 1.485-.275 1.485-.717 0-.384-.391-.531-1.765-.719l-.438-.059c-1.808-.245-2.824-.904-2.791-2.213.033-1.465 1.42-2.35 3.674-2.35 1.119 0 2.224.196 3.117.59.158.069.223.177.168.354-.11.354-.437.856-.66 1.052-.132.118-.244.109-.423.04a5.728 5.728 0 0 0-2.167-.433c-.904 0-1.43.226-1.43.63 0 .344.38.501 1.8.717l.377.06c1.854.284 2.848.914 2.848 2.124 0 1.584-1.55 2.538-3.741 2.538z" />
        </g>
    </Ic>
);

GA151Optus.propTypes = {
    /** Additional class(es) */
    className: classNameType,
};

GA151Optus.displayName = 'GA151Optus';

export default GA151Optus;
