import bacon from 'baconjs';
import superagent from 'superagent';

import {BILLING_API_URL} from '@fsa-streamotion/streamotion-web-widgets-common';

import stringableBaconError from '../../../utils/stringable-bacon-error';

import stagingGiftCardHack from './staging-gift-card-hack';

/**
 * Validate the gift card - check gift code is redeemable for this user
 *
 * @see [Swagger Docs: Validate](https://billingapi-staging.streamotion.com.au/api-doc/index.html#/Gifting/validateGiftCardUsingGET)
 * @see [Validate (note: requires bearer token)](https://billingapi-staging.streamotion.com.au/gifts/{giftCard}/validate?pin={giftCardPin})
 *
 * @param  {Object} options                 - (see below)
 * @param  {string} options.accessToken     - the user's access token
 * @param  {string} options.platformEnv     - billing API environment, e.g. production
 * @param  {string} options.giftCard        - gift card code, e.g. 076750043909877890000000000
 * @param  {string} options.giftCardPin     - gift card PIN, e.g. 1234
 *
 * @returns {bacon.Observable} stream containing response from endpoint
 */
export default function validate({
    accessToken,
    platformEnv,
    giftCard,
    giftCardPin,
}) {
    if (!giftCard) {
        throw new Error('Gift card not defined');
    }
    if (!giftCardPin) {
        throw new Error('Gift card pin not defined');
    }
    const sorry4HaxGiftCard = stagingGiftCardHack({giftCard, platformEnv}); // remove me sometime after launch pls
    const url = `${BILLING_API_URL[platformEnv]}/gifts/${sorry4HaxGiftCard}/validate`;

    return bacon.fromBinder((sink) => {
        superagent
            .get(url)
            .set('Authorization', `Bearer ${accessToken}`)
            .query({
                pin: giftCardPin,
            })
            .then((response) => void sink(response?.body))
            .catch((error) => {
                const errorBody = JSON.parse(error?.response?.text || '""');

                sink(stringableBaconError({
                    code: errorBody?.code,
                    status: error?.status,
                    message: errorBody?.longMessage
                        || errorBody?.message
                        || `An error occurred while trying to validate your gift card ${giftCard} with PIN ${giftCardPin}. Please try again later.`,
                    debugMessage: `Couldn't GET giftCard validate ${giftCard} with PIN ${giftCardPin} (${url}): ${error?.status} ${error?.message}`,
                }));
            })
            .finally(() => sink(new bacon.End()));

        return;
    });
    // .mapError((error) => {
    //     console.error('ARGH API OUTAGE?', {error});

    //     return require('../../../../../test/data/gifts/validate--already-redeemed.json');
    // });
}
