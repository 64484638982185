import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';

import {stylesIfElse} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

const StyledSpan = styled.span`
    display: block;
    position: absolute;
    bottom: 0;
    margin: 0;
    border: 0;
    background-color: currentColor;
    height: 2px;

    ${stylesIfElse('isForBlock')`
        left: 7px;
        width: calc(100% - 14px);
    ``
        left: 10%;
        width: 80%;
    `}
`;

const GA04NavInd = ({className, isForBlock = false}) => (
    <StyledSpan className={className} isForBlock={isForBlock} />
);

GA04NavInd.displayName = 'GA04NavInd';

GA04NavInd.propTypes = {
    /** Whether this indicator is for a block element */
    isForBlock: propTypes.bool,
    /** Additional CSS class(es) */
    className: classNameType,
};

export default GA04NavInd;
