import React from 'react';
import Ic from '..';

const IC281KeySegSkipF = (args) => (
    <Ic {...args}>
        <path fillOpacity=".35" d="M10.036 71.294c-.288-3.23.092-5.58 1.14-7.05 6.94-9.747 15.2-15.786 16.814-17.784-.507-2.364.073-5.3 1.74-8.809 2.81-5.914 9.884-8.43 15.798-5.62 1.348.641 2.564 1.531 3.582 2.623 4.366 4.681 4.11 12.015-.572 16.38a11.59 11.59 0 0 1-4.938 2.728 49.472 49.472 0 0 1-7.112 1.312c-1.791.291-7.973 5.698-18.546 16.22h-7.906zm30.401-31.442a1.917 1.917 0 0 0-.421 2.098c.3.72 1.005 1.19 1.788 1.19.783 0 1.489-.47 1.788-1.19.3-.72.133-1.548-.421-2.098a1.942 1.942 0 0 0-2.734 0z" />
        <g fillRule="nonzero">
            <path d="M50.483 52.844a14.315 14.315 0 0 1-12.948 3.86l-6.75 6.715-3.927-.067v3.01a2.053 2.053 0 0 1-.55 1.466 2.02 2.02 0 0 1-1.43.593h-3.026v2.988a2.019 2.019 0 0 1-1.924 2.014l-11.07.537-.607-.537a1.807 1.807 0 0 1-.518-1.365l.315-7.486a2.02 2.02 0 0 1 .585-1.343L26.42 45.548c-1.196-6.014 1.636-12.11 7.016-15.102a14.29 14.29 0 0 1 16.609 1.964 14.123 14.123 0 0 1 4.601 10.124 14.116 14.116 0 0 1-4.163 10.31zm-21.127 7.229 7.144-7.106.945.269c3.785 1.057 7.85 0 10.63-2.764a10.791 10.791 0 0 0 3.182-7.867 10.796 10.796 0 0 0-3.496-7.733 11.058 11.058 0 0 0-14.265-.403 10.687 10.687 0 0 0-3.589 11.19l.248.94L11.4 65.233l-.225 5.26 7.301-.359v-3.055a2.02 2.02 0 0 1 2.025-2.014h2.981V62.03a2.009 2.009 0 0 1 .597-1.42 2.042 2.042 0 0 1 1.496-.616l3.78.078z" />
            <path d="M45.556 44.91a5.33 5.33 0 0 1-5.788 1.149 5.282 5.282 0 0 1-3.28-4.88 5.282 5.282 0 0 1 3.28-4.881 5.33 5.33 0 0 1 5.788 1.148 5.262 5.262 0 0 1 0 7.464zm-5.119-5.058a1.917 1.917 0 0 0-.421 2.098c.3.72 1.005 1.19 1.788 1.19.783 0 1.489-.47 1.788-1.19.3-.72.133-1.548-.421-2.098a1.942 1.942 0 0 0-2.734 0z" />
        </g>
        <path fillRule="nonzero" d="M94 52 77.702 36.197a1.773 1.773 0 0 0-2.468 0 1.667 1.667 0 0 0 0 2.393L89.064 52l-13.83 13.41a1.667 1.667 0 0 0 0 2.393c.688.667 1.78.667 2.468 0L94 52z" />
    </Ic>
);

IC281KeySegSkipF.displayName = 'IC281KeySegSkipF';

export default IC281KeySegSkipF;
