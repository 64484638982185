import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {satellite} from '../../../../common/palette';
import GA89Tile from '../../../atoms/ga/89-tile';

const Paragraph = styled.p`
    margin: 0;
    padding-top: 4px;
    color: ${satellite};
    font: var(--mui-body-copy-3);
`;

const TM23CancellationTile = ({
    cancellationDate,
    cancellationEffectiveDate,
}) => (
    <GA89Tile title="Subscription awaiting cancellation">
        {!!cancellationDate && (
            <Paragraph>
                Cancellation processed on: {cancellationDate}
            </Paragraph>
        )}

        {cancellationEffectiveDate ? (
            <Paragraph>
                Access available to: {cancellationEffectiveDate}
            </Paragraph>
        ) : (
            <Paragraph>
                Access available until the end of the current billing cycle
            </Paragraph>
        )}
    </GA89Tile>
);

TM23CancellationTile.displayName = 'TM23CancellationTile';

TM23CancellationTile.propTypes = {
    /** Formatted cancellation date */
    cancellationDate: propTypes.string,
    /** Formatted cancellation effective date */
    cancellationEffectiveDate: propTypes.string,
};

export default TM23CancellationTile;
