import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {palette as infinityPalette} from '@fsa-streamotion/streamotion-web-infinity';
import {palette as ionicPalette} from '@fsa-streamotion/streamotion-web-ionic';
import {palette as muiPalette} from '@fsa-streamotion/streamotion-web-mui';

import {
    BrandedBA22EditBtn,
    BrandedIC50Success,
} from '../../../../../utils/branded-components';
import {stylesWhenBinge, stylesWhenFlash, stylesWhenKayo, stylesWhenLifestyle} from '../../../../../utils/styles-when-brand';

import {Paragraph} from '../../../../../widgets/common/styled-elements';

const {blanc} = ionicPalette;
const {flash, white} = infinityPalette;
const {white: muiWhite} = muiPalette;

const Summary = styled.div`
    margin: 0 0 14px;

    ${stylesWhenBinge`
        color: ${blanc};
        font: var(--ionic-body-copy-2-bold);
    `}

    ${stylesWhenFlash`
        color: ${white};
        font: var(--infinity-body-copy-2);
    `}

    ${stylesWhenKayo`
        color: ${muiWhite};
        font: var(--mui-body-copy-2-bold);
    `}

    ${stylesWhenLifestyle`
        color: ${blanc};
        font: var(--nucleus-body-copy-2-bold);
    `}
`;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ContextualHelp = styled.p`
    ${stylesWhenBinge`
        color: ${blanc};
        font: var(--ionic-body-copy-5);
    `}

    ${stylesWhenFlash`
        color: ${white};
        font: var(--infinity-body-copy-5);
    `}

    ${stylesWhenKayo`
        color: ${muiWhite};
        font: var(--mui-body-copy-5);
    `}

    ${stylesWhenLifestyle`
        color: ${blanc};
        font: var(--nucleus-body-copy-5);
    `}
`;

const StyledBrandedIC50Success = styled(BrandedIC50Success)`
    float: right;
    transform: translateY(-2px);
    margin-left: 7px;

    ${stylesWhenFlash`
        color: ${flash};
    `}
`;

const JourneySummary = ({
    header,
    isSkipped,
    onClickEdit,
    children,
    brand,
}) => (
    <Summary
        brand={brand}
        className="journey-summary"
        displayName="summary"
    >
        <HeaderContainer>
            {header}
            {!!isSkipped && <BrandedBA22EditBtn brand={brand} onClick={onClickEdit}>Edit</BrandedBA22EditBtn>}
        </HeaderContainer>

        <Paragraph brand={brand}>
            <StyledBrandedIC50Success brand={brand} size="32px" />
            {children}
        </Paragraph>

        {!!isSkipped && <ContextualHelp brand={brand}>Click edit to change</ContextualHelp>}
    </Summary>
);

JourneySummary.propTypes = {
    header: propTypes.node,
    isSkipped: propTypes.bool,
    onClickEdit: propTypes.func,
    children: propTypes.node,
    brand: propTypes.string,
};

JourneySummary.displayName = 'Journey-Summary';

export default JourneySummary;
