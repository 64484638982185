export default function createSsrOverrides() {
    return {
        async getAccessToken() {
            return null;
        },
        async refreshAccessToken() {
            return;
        },
        async getAccessTokenDetails() {
            return {};
        },
        async getUserDetails() {
            return {};
        },
        async getAccountStatusesByBrand() {
            return {};
        },
        async checkStatusIsActiveSubscription() {
            return false;
        },
        async hasOtherActiveStreamotionSubscriptions() {
            return false;
        },
        async isPartialSignup() {
            return false;
        },
        async isStatusReturning() {
            return false;
        },
        async handleLogin() {
            return;
        },
        async isAuthenticated() {
            return false;
        },
        async requestPasswordChange() {
            return;
        },
        async login() {
            return;
        },
        async buildAuthorizeUrl() {
            return;
        },
        async logout() {
            return;
        },
        async isStatusVerified() {
            return false;
        },
        async getContentEntitlement() {
            return null;
        },
        async canPlayFreemium() {
            return false;
        },
        async canPlayPremium() {
            return false;
        },
        async getSubscribedPpvEvents() {
            return [];
        },
        async hasPpv() {
            return false;
        },
        async getTokenStream() {
            return;
        },
        isReallyAuthenticated: false,
    };
}
