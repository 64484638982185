import React from 'react';
import {midnight} from '../../../../common/palette';
import Ic from '..';

const IC153MultiAdd2 = (args) => (
    <Ic {...args}>
        <circle cx="50" cy="50" r="42.4" />
        <path fill={midnight} d="M61.5 63.5H40l-.8-4.3 9.9-8.9c2.8-2.5 4.4-4.4 4.4-6.9s-1.8-4.1-4.7-4.1c-2.9 0-5.5 1.9-7.1 3.9l-2.9-4.5c.4-.9 4-4.8 10.6-4.8S60 37.4 60 42.5s-3.8 8.2-6.1 10.3l-6.1 5.5h13.6v5.2z" />
    </Ic>
);

IC153MultiAdd2.displayName = 'IC153MultiAdd2';

export default IC153MultiAdd2;
