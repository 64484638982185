import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {Button} from 'normalized-styled-components';
import noop from 'lodash/noop';

import {coal, kayoGreen, white} from '../../../../common/palette';
import IC31Tick from '../../ic/31-tick';
import {transition} from '../../../../common/animations';

const CHECKMARK_SIZE_PX = 15;
const FOCUS_BORDER_WIDTH_PX = 2;
const CHECKMARK_OFFSET_HOVER_PX = (CHECKMARK_SIZE_PX / 2) - (FOCUS_BORDER_WIDTH_PX / 2);

const StyledButtonHover = css`
    --TR02SetTilDT-checkbox-offset: -${CHECKMARK_SIZE_PX / 2}px;
    outline: 0;
    border: ${FOCUS_BORDER_WIDTH_PX}px solid ${kayoGreen};
`;

const StyledButton = styled(Button)`
    appearance: none;
    box-sizing: border-box;
    position: relative;
    border: 2px solid transparent;
    border-radius: 5px;
    background-color: ${white};
    padding: 7px;
    width: 225px;
    max-width: 100%;
    height: 100px;
    color: ${coal};
    font: var(--mui-body-copy-1);
    line-height: 1; /* stylelint-disable-line order/properties-order */

    &:hover,
    &:focus {
        ${StyledButtonHover}
    }

    &[aria-pressed='true'] {
        border-color: ${kayoGreen};

        &:hover,
        &:focus {
            ${StyledButtonHover}
        }
    }
`;

const Checkedmark = styled.span`
    position: absolute;
    top: var(--TR02SetTilDT-checkbox-offset, -${CHECKMARK_OFFSET_HOVER_PX}px);
    right: var(--TR02SetTilDT-checkbox-offset, -${CHECKMARK_OFFSET_HOVER_PX}px);
    transform: scale(${({isPressed}) => isPressed ? '100%' : 0});
    transition: ${transition('transform')};
    width: ${CHECKMARK_SIZE_PX}px;
    height: ${CHECKMARK_SIZE_PX}px;
`;

const TR02SetTilDT = ({
    'aria-pressed': isPressed,
    children,
    onClick = noop,
    ...htmlAttributes
}) => (
    <StyledButton
        {...htmlAttributes}
        type="button"
        onClick={onClick}
        aria-pressed={isPressed}
    >
        {children}
        <Checkedmark isPressed={isPressed}>
            <IC31Tick />
        </Checkedmark>
    </StyledButton>
);

TR02SetTilDT.displayName = 'TR02SetTilDT';

/* eslint-disable quote-props */
TR02SetTilDT.propTypes = {
    /** aria-pressed attribute describes a toggle button */
    'aria-pressed': propTypes.oneOf([true, false, 'mixed']),
    /** Content of button, e.g. text */
    children: propTypes.node.isRequired,
    /** Callback on element click */
    onClick: propTypes.func,
};
/* eslint-enable quote-props */

export default TR02SetTilDT;
