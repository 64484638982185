import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import property from 'lodash/property';

import {stylesWhen, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {colorType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../common/animations';
import {black} from '../../../common/palette';

export {default as IC00Logo} from './00-logo';
export {default as IC01Ham} from './01-ham';
export {default as IC02Sea} from './02-sea';
export {default as IC03Not} from './03-not';
export {default as IC03NotHeadsUpMarketing} from './03-not-heads-up-marketing';
export {default as IC04Vol} from './04-vol';
export {default as IC05Exp} from './05-exp';
export {default as IC06Play} from './06-play';
export {default as IC07Pau} from './07-pau';
export {default as IC08Cc} from './08-cc';
export {default as IC09Pip} from './09-pip';
export {default as IC10Mltv} from './10-mltv';
export {default as IC11AirP} from './11-air-p';
export {default as IC14Skb} from './14-skb';
export {default as IC15Skf} from './15-skf';
export {default as IC19JLive} from './19-j-live';
export {default as IC20Live} from './20-live';
export {default as IC21Mute} from './21-mute';
export {default as IC22Float} from './22-float';
export {default as IC24ArrowL} from './24-arrow-l';
export {default as IC25ArrowR} from './25-arrow-r';
export {default as IC26ArrowU} from './26-arrow-u';
export {default as IC27ArrowD} from './27-arrow-d';
export {default as IC28RelVid} from './28-rel-vid';
export {default as IC30Back} from './30-back';
export {default as IC31Tick} from './31-tick';
export {default as IC32PbRate} from './32-pb-rate';
export {default as IC33Fav} from './33-fav';
export {default as IC38News} from './38-news';
export {default as IC39Score} from './39-score';
export {default as IC39ScoreMarketing} from './39-score-marketing';
export {default as IC42Error} from './42-error';
export {default as IC43Valid} from './43-valid';
export {default as IC48Close} from './48-close';
export {default as IC49Open} from './49-open';
export {default as IC50Success} from './50-success';
export {default as IC52Cross} from './52-cross';
export {default as IC521Up} from './52-1up';
export {default as IC543Up} from './54-3up';
export {default as IC554Up} from './55-4up';
export {default as IC554UpMarketing} from './55-4up-marketing';
export {default as IC562Up} from './56-2up';
export {default as IC59Laptop} from './59-laptop';
export {default as IC60Android} from './60-android';
export {default as IC61Ios} from './61-ios';
export {default as IC62Chrome} from './62-chrome';
export {default as IC63SmartTv} from './63-smart-tv';
export {default as IC64TelstraTv} from './64-telstra-tv';
export {default as IC66AppleTv} from './66-apple-tv';
export {default as IC69Stop} from './69-stop';
export {default as IC71Thtr} from './71-thtr';
export {default as IC72Min} from './72-min';
export {default as IC88Beta} from './88-beta';
export {default as IC97Eq} from './97-eq';
export {default as IC99ExitThtr} from './99-exit-thtr';
export {default as IC103Loading} from './103-loading';
export {default as IC105Home} from './105-home';
export {default as IC106Binge} from './106-binge';
export {default as IC107Prog} from './107-prog';
export {default as IC108Scor} from './108-scor';
export {default as IC109Spor} from './109-spor';
export {default as IC111KeyMomMarketing} from './111-key-mom-marketing';
export {default as IC112Watchlist} from './112-watchlist';
export {default as IC125Grid} from './125-grid';
export {default as IC134Minus} from './134-minus';
export {default as IC135Cross} from './135-cross';
export {default as IC136Fire} from './136-fire';
export {default as IC137Blog} from './137-blog';
export {default as IC140PlayStat} from './140-play-stat';
export {default as IC141Discuss} from './141-discuss';
export {default as IC145HD} from './145-hd';
export {default as IC151Pip} from './151-pip';
export {default as IC152MultiAdd1} from './152-multi-add-1';
export {default as IC153MultiAdd2} from './153-multi-add-2';
export {default as IC154MultiAdd3} from './154-multi-add-3';
export {default as IC155MultiAdd4} from './155-multi-add-4';
export {default as IC156MatchSummary} from './156-match-summary';
export {default as IC159Manhattan} from './159-manhattan';
export {default as IC160Worm} from './160-worm';
export {default as IC161ChampStandings} from './161-champ-standings';
export {default as IC167Synopsis} from './167-synopsis';
export {default as IC168TeamStats} from './168-team-stats';
export {default as IC173KeyMPlayHeadOff} from './173-key-m-play-head-off';
export {default as IC173KeyMPlayHeadOn} from './173-key-m-play-head-on';
export {default as IC174CcOn} from './174-cc-on';
export {default as IC175Casting} from './175-casting';
export {default as IC176InteractiveStats} from './176-interactive-stats';
export {default as IC177MatchCentre} from './177-match-centre';
export {default as IC178Playlists} from './178-playlists';
export {default as IC178PlaylistsMarketing} from './178-playlists-marketing';
export {default as IC179Roku} from './179-roku';
export {default as IC183TabletMobile} from './183-tablet-mobile';
export {default as IC191Gift} from './191-gift';
export {default as IC192ClseCap} from './192-clse-cap';
export {default as IC209Fixtures} from './209-fixtures';
export {default as IC218XtraInfo} from './218-xtra-info';
export {default as IC260Switch} from './260-switch';
export {default as IC261ArrowDTail} from './261-arrow-d-tail';
export {default as IC287Locked} from './287-locked';

/**
 * There are two methods to set the colour of the SVG
 *     1. Set the color of a container element and the colour will be inherited by the SVG
 *     2. Set the color property
 */
const Ic = styled.svg.attrs({
    viewBox: '0 0 100 100',
})`
    display: ${property('display')};
    pointer-events: none; /* Don't allow icons to be event targets */

    ${stylesWhen('hasDropshadow')`
        filter: drop-shadow(1px 1px 1px ${black});
    `}

    ${stylesWhenNot('preserveColors')`
        fill: currentColor;

        * {
            transition: ${transition('fill', 'stroke')};
        }
    `}
`;

const Icon = ({
    hasDropshadow,
    ariaLabel,
    children,
    color,
    preserveColors,
    display = 'block',
    size = '100%',

    ariaHidden = ariaLabel ? undefined : true,
    ...htmlAttributes
}) => ( // using aria-hidden true on the assumption that these icons will only be used in the context of a button and are thus decorative
    <Ic
        {...htmlAttributes}
        hasDropshadow={hasDropshadow}
        aria-hidden={ariaHidden}
        aria-label={ariaLabel}
        color={preserveColors ? null : color}
        preserveColors={preserveColors}
        display={display}
        style={preserveColors ? {
            fill: color,
            stroke: color,
            width: size,
        } : {
            width: size,
        }}
    >
        {children}
    </Ic>
);

Icon.displayName = 'Icon';

Icon.propTypes = {
    ariaHidden: propTypes.bool,  // eslint-disable-line react/boolean-prop-naming
    ariaLabel: propTypes.string,
    children: propTypes.node,
    color: colorType,
    preserveColors: propTypes.bool,  // eslint-disable-line react/boolean-prop-naming
    display: propTypes.oneOf(['block', 'inline-block', 'flex', 'inline-flex']),
    size: propTypes.string,
    hasDropshadow: propTypes.bool,
};

export default Icon;
