import React from 'react';
import propTypes from 'prop-types';
import styled, {keyframes} from 'styled-components';
import classNames from 'classnames';

import {colorType, classNameType} from '@fsa-streamotion/custom-prop-types';

import muid from '../../../../common/muid';
import {visuallyHiddenBaseStyles} from '../../../../common/visually-hidden';
import {fontFamily} from '../../../../common/typography';
import {hubblGreen, cobalt, indigo, white} from '../../../../common/palette';
import Icon from '..';

const StyledIcon = styled(Icon)`
    @media screen and (prefers-reduced-motion: reduce), (update: slow) {
        display: none;
    }
`;

const ReducedMotionLoadingText = styled.span`
    ${visuallyHiddenBaseStyles}

    display: block;
    text-align: center;
    line-height: inherit;
    color: inherit;
    font-family: ${fontFamily};
    font-size: inherit;

    @media screen and (prefers-reduced-motion: reduce), (update: slow) {
        position: static;
        clip: initial;
        width: auto;
        height: auto;
        overflow: auto;
    }
`;

const rotate = keyframes`
    0% {
        stroke-dashoffset: 0;
        ${''/* stroke-dasharray: calc(3.14px * 0.25 * 82) calc(3.14px * 0.75 * 82); */}
        stroke-dasharray: 64px 193px;
    }

    19% {
        ${'' /* stroke-dashoffset: calc(-3.14px * 0.3 * 82);
        stroke-dasharray: calc(3.14px * 0.7 * 82) calc(3.14px * 0.3 * 82); */}
        stroke-dashoffset: -77px;
        stroke-dasharray: 180px 77px;
        animation-timing-function: linear;
    }

    27% {
        ${'' /* stroke-dashoffset: calc(-3.14px * 0.6 * 82);
        stroke-dasharray: calc(3.14px * 0.8 * 82) calc(3.14px * 0.2 * 82); */}
        stroke-dashoffset: -155px;
        stroke-dasharray: 206px 52px;
        animation-timing-function: linear;
    }

    32% {
        ${'' /* stroke-dashoffset: calc(-3.14px * 0.8 * 82);
        stroke-dasharray: calc(3.14px * 0.7 * 82) calc(3.14px * 0.3 * 82); */}
        stroke-dashoffset: -206px;
        stroke-dasharray: 180px 77px;
        animation-timing-function: linear;
    }

    53% {
        ${'' /* stroke-dashoffset: calc(-3.14px * 1.6 * 82);
        stroke-dasharray: calc(3.14px * 0.6 * 82) calc(3.14px * 0.4 * 82); */}
        stroke-dashoffset: -412px;
        stroke-dasharray: 155px 103px;
        animation-timing-function: linear;
    }

    59% {
        ${'' /* stroke-dashoffset: calc(-3.14px * 2 * 82);
        stroke-dasharray: calc(3.14px * 0.3 * 82) calc(3.14px * 0.7 * 82); */}
        stroke-dashoffset: -515px;
        stroke-dasharray: 77px 180px;
        animation-timing-function: ease-out;
    }

    70% {
        ${'' /* stroke-dashoffset: calc(-3.14px * 2.25 * 82);
        stroke-dasharray: calc(3.14px * 0.25 * 82) calc(3.14px * 0.75 * 82); */}
        stroke-dashoffset: -580px;
        stroke-dasharray: 64px 193px;
        animation-timing-function: ease-in-out;
    }

    100% {
        ${'' /* stroke-dashoffset: calc(-3.14px * 2 * 82);
        stroke-dasharray: calc(3.14px * 0.25 * 82) calc(3.14px * 0.75 * 82); */}
        stroke-dashoffset: -515px;
        stroke-dasharray: 64px 193px;
    }
`;

const Stroke = styled.circle`
    fill: transparent;
    stroke-linecap: round;
    animation: ${rotate} linear 2s normal infinite;
`;

const IC103Loading = ({
    className,
    id = muid(),
    display = 'inline-block',
    size,
    backgroundColor = white,
    topColor = hubblGreen,
    middleColor = cobalt,
    bottomColor = indigo,
    ...props
}) => (
    <React.Fragment>
        <StyledIcon id={id} display={display} size={size} {...props} className={classNames('IC103Loading', className)}>
            <defs>
                <linearGradient id={`ic-103-loading-${id}__linear-gradient`} gradientTransform="rotate(90)">
                    <stop offset="-7%" stopColor={topColor} />
                    <stop offset="47%" stopColor={middleColor} />
                    <stop offset="107%" stopColor={bottomColor} />
                </linearGradient>
            </defs>
            <circle
                cx="50" cy="50" r="41" stroke={backgroundColor} fill="transparent"
                strokeWidth="9"
            />
            <Stroke cx="50" cy="50" r="41" stroke={`url(#ic-103-loading-${id}__linear-gradient)`} strokeWidth="9" />
        </StyledIcon>
        <ReducedMotionLoadingText>
            Loading ...
        </ReducedMotionLoadingText>
    </React.Fragment>
);

IC103Loading.displayName = 'IC103Loading';

IC103Loading.propTypes = {
    /** Additional class(es) */
    className: classNameType,
    /** Component ID <br>
     * **NOTE**: for animated SVGs, this is required for animation to work */
    id: propTypes.string,
    /** The loader's display mode */
    display: propTypes.oneOf(['block', 'inline-block']), // there are many other values, but you probably shouldn't set them. update this if you get sick of the warnings though
    /** Width size for the icon component, defaults to 100% */
    size: propTypes.oneOfType([propTypes.string, propTypes.number]),
    /** Color for the background of the loading circle */
    backgroundColor: colorType,
    /** Color for the top 15% of the loading circle */
    topColor: colorType,
    /** Color for the middle 55% of the loading circle */
    middleColor: colorType,
    /** Color for the bottom 30% of the loading circle */
    bottomColor: colorType,
};

export default IC103Loading;
