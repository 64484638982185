import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import noop from 'lodash/noop';
import NM12HorizAvatar from '../../../../molecules/nm/12-horiz-avatar';

const StyledDiv = styled.div`
    display: flex;
    justify-content: space-around;
    padding: 28px 0;
`;

const UserProfileRow = ({
    avatarUrl,
    onProfileClick = noop,
    profileName,
}) => {
    if (!profileName || !avatarUrl) {
        return null;
    }

    return (
        <StyledDiv>
            <NM12HorizAvatar onClick={onProfileClick} imageUrl={avatarUrl}>
                {profileName}
            </NM12HorizAvatar>
        </StyledDiv>
    );
};

UserProfileRow.displayName = 'UserProfileRow';

UserProfileRow.propTypes = {
    /** User profile avatar */
    avatarUrl: propTypes.string,
    /** User profile action when clicked */
    onProfileClick: propTypes.func,
    /** User profile name */
    profileName: propTypes.string,
};

export default UserProfileRow;
