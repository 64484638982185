import React from 'react';
import Ic from '..';

const IC169ContextualPlay = (args) => (
    <Ic {...args}>
        <path d="M49.128 7.397c-23.157 0-41.93 18.772-41.93 41.93 0 23.157 18.773 41.928 41.93 41.928 23.156 0 41.928-18.77 41.928-41.929 0-23.157-18.772-41.929-41.928-41.929m0 5.5c20.088 0 36.43 16.342 36.43 36.43 0 20.087-16.342 36.43-36.43 36.43s-36.43-16.343-36.43-36.43c0-20.088 16.342-36.43 36.43-36.43" />
        <path fillRule="nonzero" d="m45.908 62.785 15.064-11.657a2 2 0 0 0 0-3.164L45.908 36.317a2 2 0 0 0-3.224 1.582v23.304a2 2 0 0 0 3.224 1.582z" />
    </Ic>
);

IC169ContextualPlay.displayName = 'IC169ContextualPlay';

export default IC169ContextualPlay;
