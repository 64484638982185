import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import debounce from 'lodash/debounce';
import smoothscroll from 'smoothscroll-polyfill';
import IntersectionObserver from 'inteobs';
import invoke from 'lodash/invoke';
import findLastIndex from 'lodash/findLastIndex';
import ResizeObserver from 'resize-observer-polyfill';

import {mediaQuery, stylesWhen} from '@fsa-streamotion/styled-component-helpers';

import {white} from '../../../../common/palette';
import {SCREEN_DESKTOP} from '../../../../common/screen-sizes';
import BA26CarouselBtn from '../../../atoms/ba/26-carousel-btn';
import CarouselSlot from './carousel-slot';
import Filmstrip from './filmstrip';

import {Z_INDEX_CONTROLS, LEFT_GUTTER, FILMSTRIP_PREV, FILMSTRIP_CURR, FILMSTRIP_NEXT} from './constants';

const THRESHOLD_PX = 10; // for any edge cases with scroll snap. maybe its not required hmm
const SCROLL_PADDING_PERC = 0.05;

const StyledSection = styled.section`
    position: relative;
    z-index: 0; /* Because children of this component use z-indexes, wrap them in a 0 so they don't interfere with other components on a page */
    margin: 0;
    padding-bottom: 28px;
    width: 100%;
    overflow: hidden;
`;

const CarouselTitle = styled.h3`
    margin: 0 0 0 ${LEFT_GUTTER};
    color: ${white};
    font: var(--mui-header-7-bold);
`;

const ZOOM_ALLOWANCE_PX = 20;

const StyledBA26CarouselBtn = styled(BA26CarouselBtn).attrs({
    stopMarker: 0.75,
})`
    display: none;
    z-index: ${Z_INDEX_CONTROLS};
    margin-top: ${ZOOM_ALLOWANCE_PX / 2}px;
    padding: 0;
    width: ${SCROLL_PADDING_PERC * 100}vw;
    height: calc(100% - ${ZOOM_ALLOWANCE_PX}px);

    ${mediaQuery({minWidthPx: SCREEN_DESKTOP})`
        display: flex;
    `}
`;

const OuterViewport = styled.div`
    position: relative;
    width: 100%;

    @media (hover: hover) { /* try to show nav buttons on a real hover event, not a fake touch-focus one */
        &:hover ${StyledBA26CarouselBtn} {
            display: flex;
        }
    }
`;

const Viewport = styled.div`
    position: relative;
    transform: translateX(0);
    margin: -${ZOOM_ALLOWANCE_PX}px 0;
    padding: ${ZOOM_ALLOWANCE_PX}px 0;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;

    scrollbar-width: none;
    -ms-overflow-style: none;

    ${stylesWhen('isWaitingToMount')`
        &::before {
            display: inline-block;
            margin-left: -200%;
            width: 100%;
            content: '';
        }
    `}

    &::-webkit-scrollbar {
        display: none;
    }

    > * {
        white-space: initial; /* prevent white-space inheritance */
    }
`;

const FILMSTRIPS = [FILMSTRIP_PREV, FILMSTRIP_CURR, FILMSTRIP_NEXT]; // the order here is crucial as we use it for generating react keys

/**
 * ASCII ART JUST FOR YOU GREG <3
 *
 *  - frame size is determined by the viewport. its the number of slides we increment every time nav buttons are pushed
 *  - a film strip is effectively a linear scroll list
 *  - we fake infinite scroll by putting the film strips in an infinite carousel
 *
 *                                                 +-----------------------+
 *                                                 |                       |
 *                                                 |   (BROWSER VIEWPORT)  |
 * +-----------------------------------------------+-----------------------------------------------+-----------------------------------------------+
 * |                film strip -1                  |                film strip 0                   |                film strip 1                   |
 * +-----------------------------------------------+-----------------------------------------------+-----------------------------------------------+
 * |        frame          |         frame         |        frame          |         frame         |        frame          |         frame         |
 * +-----+-----+-----+-----------+-----+-----+-----+-----+-----+-----+-----------+-----+-----+-----+-----+-----+-----+-----------+-----+-----+-----+
 * |     |     |     |     |     |     |     |     |     |     |     |     |     |     |     |     |     |     |     |     |     |     |     |     |
 * |slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|slide|
 * |1    |2    |3    |4    |5    |6    |7    |8    |1    |2    |3    |4    |5    |6    |7    |8    |1    |2    |3    |4    |5    |6    |7    |8    |
 * +-----------------------------------------------+-----------------------------------------------+-----------------------------------------------+
 *                                                 |   (BROWSER VIEWPORT)  |
 *                                                 +-----------------------+
 *
 */

export default class CAM01Stand extends React.Component {
    static displayName = 'CAM01Stand';

    static propTypes = {
        /** Carousel Label */
        label: propTypes.string,
        /** Carousel Slides */
        children: propTypes.node,
        /** For lazy evaluation purposes, what is the maximum slides that could appear on initial render? Use -1 to force eager loading */
        maxSlidesPerPage: propTypes.number,
        /** Show partial fake tiles at the beginning and ends of carousels to make them look infinite. */
        hasHints: propTypes.bool,
    };

    static defaultProps = {
        children: [],
        maxSlidesPerPage: 5,
        hasHints: true,
    };

    state = {
        isActive: false,
        filmstripIndex: 0,
        hasNextButton: false,
        hasNextFrame: false,
        hasPrevButton: false,
        hasPrevFrame: false,
        wakeupIndex: this.props.maxSlidesPerPage, // always show our initial page

        isWaitingToMount: true,
    };

    componentDidMount() {
        smoothscroll.polyfill(); // currently only safari and edge need this. used for the scrolling effect on prev/next button clicks
        this.setNavigationButtonVisibility();

        if (this.props.maxSlidesPerPage < 0) {
            return; // we've been told to load everything. don't bother using an observer
        }

        // for performance reasons, we're going to use a single observer on the carousel
        this.intersectionObserver = new IntersectionObserver((elements) => { // eslint-disable-line compat/compat
            const intersectingEls = elements
                .filter(({intersectionRatio}) => intersectionRatio > 0)
                .map(({target}) => target);

            const highestVisibleIndex = findLastIndex(this.slotEls, (slotEl) => intersectingEls.includes(slotEl));

            const wakeupIndex = Math.min(
                React.Children.count(this.props.children), // don't wake up children we don't have
                highestVisibleIndex + this.props.maxSlidesPerPage, // whatever is visible,
            );

            if (wakeupIndex > this.state.wakeupIndex) {
                // once you wake your children, they wont EVER go back to sleep >_>
                // this means we can stop observing any children before our wakeup index
                this.slotEls
                    .slice(this.state.wakeupIndex, wakeupIndex)
                    .forEach((slotEl) => this.intersectionObserver.unobserve(slotEl));

                this.setState({wakeupIndex});
            }
        });

        this.resizeObserver = new ResizeObserver(this.debouncedHandleResize);
        this.resizeObserver.observe(this.rootRef.current);

        // now connect each of our carousel slot elements to the observer
        this.slotEls.forEach((slotEl) => void this.intersectionObserver.observe(slotEl));
    }

    /**
     * We use this react lifecycle hook to allow us to clean up intersection observer listeners on elements that will soon not exist anymore
     * https://reactjs.org/docs/react-component.html#getsnapshotbeforeupdate
     *
     * @returns {null} returns null
     */
    getSnapshotBeforeUpdate() {
        if (this.intersectionObserver && this.slotEls.length) {
            return this.slotEls.slice(); // hold on to our old slot references. if one or more is removed, we need to disconnect observers
        }

        return null;
    }

    componentDidUpdate(prevProps, prevState, prevSlotEls) {
        if (!prevSlotEls || !prevSlotEls.length || !this.intersectionObserver) {
            return;
        }

        // stop observing elements that have been removed
        prevSlotEls
            .filter((prevSlotEl) => prevSlotEl && !this.slotEls.includes(prevSlotEl))
            .forEach((unobserveEl) => void this.intersectionObserver.unobserve(unobserveEl));

        // start observing elements that have been added
        this.slotEls
            .filter((slotEl) => slotEl && !prevSlotEls.includes(slotEl))
            .forEach((unobserveEl) => void this.intersectionObserver.observe(unobserveEl));
    }

    componentWillUnmount() {
        invoke(this.intersectionObserver, 'disconnect');
        invoke(this.resizeObserver, 'disconnect');
        invoke(this.debouncedSetNavigationButtonVisibility, 'cancel'); // e.g. cancel debounce
        invoke(this.debouncedHandleResize, 'cancel');
        clearTimeout(this.filmstripAnimationTimeout);
    }

    /**
     * We only want to show navigation buttons when the slides can't all be seen in the viewport
     *
     * @param {number} increment optionally provide a number of slides to increment by
     */
    setNavigationButtonVisibility = (increment) => {
        if (!this.visibleFilmStripEl) {
            return;
        }

        const {scrollLeft, scrollWidth} = this.visibleFilmStripEl;
        const {width} = this.visibleFilmStripEl.getBoundingClientRect();
        let hasPrevFrame = scrollLeft > THRESHOLD_PX;
        let hasNextFrame = scrollLeft + width < scrollWidth - THRESHOLD_PX;

        if (increment) {
            const totalScrollPaddingPx = SCROLL_PADDING_PERC * 2 * width; // the viewport is actually a bit smaller than its width, due to the scroll padding margin used by CSS scroll snap

            const newLeft = Math.max(0, scrollLeft + (width * increment) - totalScrollPaddingPx);
            const newRight = Math.min(scrollWidth, newLeft + width);

            this.visibleFilmStripEl.scrollTo({behavior: 'smooth', left: newLeft});

            hasNextFrame = newRight < scrollWidth - THRESHOLD_PX;
            hasPrevFrame = newLeft > THRESHOLD_PX;
        }

        if (this.state.hasNextFrame !== hasNextFrame || this.state.hasPrevFrame !== hasPrevFrame) {
            this.setState({
                hasNextButton: hasNextFrame || hasPrevFrame,
                hasNextFrame,
                hasPrevButton: hasNextFrame || hasPrevFrame,
                hasPrevFrame,
            });
        }
    };

    filmstripAnimationTimeout = null;

    prevFrame = () => void this.setNavigationButtonVisibility(-1);
    nextFrame = () => void this.setNavigationButtonVisibility(1);
    prevFilmstrip = () => {
        this.setState((prevState) => ({
            filmstripIndex: prevState.filmstripIndex - 1,
            wakeupIndex: React.Children.count(this.props.children), // we've effectively jumped to the end of the carousel. Wake up everyone!
        }), () => {
            this.setNavigationButtonVisibility();
            this.viewportEl.scrollLeft = this.viewportEl.clientWidth * 2;
            this.viewportEl.scrollTo({
                behavior: 'smooth',
                left: this.viewportEl.clientWidth,
            });
        });
    };

    nextFilmstrip = () => {
        this.setState((prevState) => ({
            filmstripIndex: prevState.filmstripIndex + 1,
        }), () => {
            this.setNavigationButtonVisibility();
            this.viewportEl.scrollLeft = 0;
            this.viewportEl.scrollTo({
                behavior: 'smooth',
                left: this.viewportEl.clientWidth,
            });
        });
    };

    focusNext = () => {
        const nextSlotIndex = this.slotEls.findIndex((el) => el.contains(document.activeElement)) + 1;

        invoke(
            this.slotEls[nextSlotIndex < this.slotEls.length ? nextSlotIndex : 0].querySelector('button, a'),
            'focus'
        );
    };

    focusPrev = () => {
        const prevSlotIndex = this.slotEls.findIndex((el) => el.contains(document.activeElement)) - 1;

        invoke(
            this.slotEls[prevSlotIndex < 0 ? this.slotEls.length - 1 : prevSlotIndex].querySelector('button, a'),
            'focus'
        );
    };

    handleKeyDown = (event) => {
        switch (event.key) {
            case 'ArrowRight':
            case 'Right':
                event.preventDefault();

                return void this.focusNext();

            case 'ArrowLeft':
            case 'Left':
                event.preventDefault();

                return void this.focusPrev();

            default:
                return;
        }
    };

    filmstripRefs = { // at all times we have three filmstrips. prev should be at the end, next should be at the start. current is the only one we need keep a reference to though
        [FILMSTRIP_PREV]: (filmStripPrevEl) => {
            if (filmStripPrevEl) {
                filmStripPrevEl.scrollLeft = filmStripPrevEl.scrollWidth; // "previous" filmstrip should always be internally scrolled to its end
            }
        },
        [FILMSTRIP_CURR]: (filmStripCurrentEl) => {
            this.visibleFilmStripEl = filmStripCurrentEl;
        },
        [FILMSTRIP_NEXT]: (filmStripNextEl) => {
            if (filmStripNextEl) {
                filmStripNextEl.scrollLeft = 0; // "next" filmstrip should always be internally scrolled to its start
            }
        },
    };

    visibleFilmStripEl = null;
    debouncedSetNavigationButtonVisibility = debounce(() => void this.setNavigationButtonVisibility(), 100); // check if we've reached either end of the carousel after a short interval
    debouncedHandleResize = debounce(() => {
        this.setNavigationButtonVisibility();
        this.viewportEl.scrollLeft = this.viewportEl.clientWidth; // ensure that the current carousel remains in the viewport
    }, 50);

    intersectionObserver = null;
    resizeObserver = null;
    slotEls = [];
    rootRef = React.createRef();
    viewportRef = (el) => {
        if (!el) {
            return;
        }

        if (!this.viewportEl) {
            this.setState({isWaitingToMount: false});
            el.scrollLeft = el.clientWidth;
        }

        this.viewportEl = el;
    };

    activate = () => void this.setState({isActive: true});
    deactivate = () => void this.setState({isActive: false});

    render() {
        const childrenArray = React.Children.toArray(this.props.children); // we need access to indexes and length

        this.slotEls.length = childrenArray.length; // garbage collect unused pointers

        return (
            <StyledSection className="CAM01Stand" onKeyDown={this.handleKeyDown} ref={this.rootRef}>
                {!!this.props.label && (
                    <CarouselTitle>{this.props.label}</CarouselTitle>
                )}

                <OuterViewport
                    onBlur={this.deactivate}
                    onFocus={this.activate}
                    onMouseEnter={this.activate}
                    onMouseLeave={this.deactivate}
                >
                    {!!this.state.hasPrevButton && (
                        <StyledBA26CarouselBtn
                            isActive={this.state.isActive}
                            direction="left"
                            onClick={this.state.hasPrevFrame ? this.prevFrame : this.prevFilmstrip}
                        />
                    )}
                    <Viewport
                        ref={this.viewportRef}
                        isWaitingToMount={this.state.isWaitingToMount}
                    >
                        {FILMSTRIPS // we render the carousel to the DOM three times. this isn't ideal - we only need the other two during a filmstrip transition. if some dev wants to optimise this later that would be awesome but I ran out of time
                            .map((symbol, index) => ({
                                key: this.state.filmstripIndex + index - 1, // subtracting 1 so the initial keys are [-1, 0, 1] where zero is the initially visible filmstrip
                                symbol,
                            }))
                            .map(({key, symbol}) => (
                                <Filmstrip
                                    ref={this.filmstripRefs[symbol]}
                                    onScroll={this.debouncedSetNavigationButtonVisibility}
                                    key={key}
                                    hasNextHint={this.props.hasHints && this.state.hasNextButton}
                                    hasPrevHint={this.props.hasHints && this.state.hasPrevButton}
                                    data-index={key}
                                    aria-hidden={symbol !== FILMSTRIP_CURR}
                                    firstSlideTeaser={childrenArray[0]}
                                    lastSlideTeaser={childrenArray[childrenArray.length - 1]}
                                >
                                    {childrenArray.map(({key, ...slide}, index) => (
                                        <CarouselSlot
                                            tabIndex={symbol === FILMSTRIP_CURR && !index ? 0 : -1}
                                            ref={symbol === FILMSTRIP_CURR // we only care about refs to the current carousel slides
                                                ? (slotEl) => void Object.assign(this.slotEls, {[index]: slotEl}) // specifically not using array.push below as ref callbacks don't fire in DOM order
                                                : null}
                                            isBeingLazy={this.props.maxSlidesPerPage > -1 && index > this.state.wakeupIndex}
                                            key={key}
                                            children={slide}
                                        />
                                    ))}
                                </Filmstrip>
                            ))}
                    </Viewport>
                    {!!this.state.hasNextButton && (
                        <StyledBA26CarouselBtn
                            isActive={this.state.isActive}
                            direction="right"
                            onClick={this.state.hasNextFrame ? this.nextFrame : this.nextFilmstrip}
                        />
                    )}
                </OuterViewport>
            </StyledSection>
        );
    }
}
