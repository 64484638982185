import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import styled from 'styled-components';

import {SCREEN_375_PHABLET, SCREEN_1280_DESKTOP, SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';

const FixedWidthContainer = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 7px;
    margin: 0 auto;
    width: 100%;
    max-width: 278px;
    text-align: center;

    ${mediaQuery({minWidthPx: SCREEN_375_PHABLET})`
        max-width: 333px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        max-width: 408px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        gap: 14px;
    `}
`;

export default FixedWidthContainer;
