import React from 'react';
import Ic from '..';

const IC15Skf = (args) => (
    <Ic {...args}>
        <g transform="translate(8 16)">
            <path d="M56.064 26.6L52.298 26.6 52.298 2.8 46.88 2.8 46.88 0 56.064 0zM72.78 27.02c-3.605.201-7.054-1.492-9.1-4.466l2.17-2.156c1.55 2.276 4.149 3.61 6.902 3.542 3.122 0 6.412-1.848 6.412-5.88s-3.248-5.894-6.412-5.894c-1.741-.07-3.459.42-4.9 1.4l-1.96-1.12L68.3 0h13.902v2.996H71.1l-1.4 7c1.251-.495 2.589-.733 3.934-.7 4.48 0 9.422 2.884 9.422 8.708S78.002 27.02 72.78 27.02zM30.346 29.414L21.946 29.386 23.78 12.684 6.462 8.96 8.842.896 32.194 6.412z" />
            <path d="M30.458 58.52c-5.656.205-11.154-1.893-15.236-5.814-4.082-3.921-6.4-9.33-6.422-14.99-.168-6.916 3.444-11.382 8.652-15.316l.77-5.726-5.698-1.064C5.706 20.748.148 27.342.4 37.926.623 54.109 13.883 67.068 30.066 66.92h.546c7.63-.14 30.8 0 31.01 0v-8.4c-.896-.014-23.422-.14-31.164 0z" />
        </g>
    </Ic>
);

IC15Skf.displayName = 'IC15Skf';

export default IC15Skf;
