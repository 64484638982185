import {VIMOND_GEO_BLOCK_DETAILS_KEY} from '../constants';
import decodeToken from './decode-token';

/**
 * @description Checks the "https://vimond/geoblock" bypass detail in the provided access_token (Entitled)
 *
 * @param {string} accessToken  - Raw JWT access_token (Entitled or Chromecast)
 * @returns {boolean}           - Returns a boolean
 */
export default function hasGeoblockBypassEnabled(accessToken) {
    const accessTokenDetails = decodeToken(accessToken);

    const bypassValue
        = VIMOND_GEO_BLOCK_DETAILS_KEY in accessTokenDetails && accessTokenDetails[VIMOND_GEO_BLOCK_DETAILS_KEY]?.bypass;

    return Boolean(bypassValue);
}
