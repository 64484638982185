import {useEffect, useRef, useState} from 'react';
import get from 'lodash/get';

/**
 * This hook does the following:
 * -  handles:
 *    - prop changes for important flags like mobileNumberExternal and isEditingMobileNumber
 *    - click and key down to cancel mobile update
 * - creates references, callbacks and flags to be used by the mobile update modal
 *
 * @param {Object} options options
 * @param {Boolean} options.isEditingMobileNumber is user currently editing mobile number?
 * @param {String} options.mobileNumberExternal mobile number from props
 * @param {Function} options.onCancelEditMobileNumber callback for cancelling mobile number update
 * @param {Function} options.onSubmitMobileNumber callback for submitting mobile number update
 * @param {Function} options.processMobileNumberInput handler to process mobile number either from props or from input field
 * @param {Function} options.validateMobileNumber mobile number validator
 *
 * @returns {Object} Returns an object with the following properties:
 *   - `isMobileNumberValid {boolean}`: Indicates if the mobile number is valid.
 *   - `mobileNumberEditorRef {React.RefObject}`: Reference to the mobile number editor element.
 *   - `mobileNumberInputRef {React.RefObject}`: Reference to the mobile number input element.
 *   - `onChangeMobileNumber {Function}`: Event handler for changing the mobile number.
 *   - `onClickCloseModalButton {Function}`: Event handler for closing the modal button.
 *   - `onSubmitMobileUpdate {Function}`: Event handler for submitting mobile number update.
 *   - `processedMobileNumber {string}`: The processed mobile number.
 */
const useMobileUpdateModal = ({
    isEditingMobileNumber,
    mobileNumberExternal,
    onCancelEditMobileNumber,
    onSubmitMobileNumber,
    processMobileNumberInput,
    validateMobileNumber,
}) => {
    // State variables
    const [processedMobileNumber, setProcessedMobileNumber] = useState('');
    const [isMobileNumberValid, setIsMobileNumberValid] = useState(false);

    // Track prop changes
    const [prevMobileNumber, setPrevMobileNumber] = useState('');

    // Ref for form and input field
    const mobileNumberInputRef = useRef();
    const mobileNumberEditorRef = useRef();

    /**
     * If mobileNumberExternal or isEditingMobileNumber props updated externally (via prop),
     * - apply it to the states to track it
     * - update processedMobileNumber accordingly
     */
    useEffect(function updateStatesFromProps() {
        // If mobile number updated externally, update processedNumber
        if (prevMobileNumber !== mobileNumberExternal) {
            const processedNumber = processMobileNumberInput(prevMobileNumber, mobileNumberExternal);
            const isValid = validateMobileNumber(processedNumber);

            // Updating state variables
            setProcessedMobileNumber(processedNumber);
            setIsMobileNumberValid(isValid);
            setPrevMobileNumber(mobileNumberExternal);
        }
    }, [mobileNumberExternal, prevMobileNumber]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * This hook manages when to listen for click or key down events
     * - If user is in mobile number edit mode (isEditingMobileNumber is true),
     *      - any click outside of the modal
     *      - or if user presses escape key
     *   then we trigger callback `onCancelEditMobileNumber`
     */
    useEffect(function handleClickAndkeyDownToCancelUpdate() {
        const handleClick = (event) => {
            // Cancel editing if we click outside of modal
            if (!mobileNumberEditorRef.current.contains(event.target)) {
                onCancelEditMobileNumber();
            }
        };

        const handleKeyDown = (event) => {
            // Cancel editing if we press escape
            if (event.key === 'Escape') {
                onCancelEditMobileNumber();
            }
        };

        const startWaitingForCloseEvent = () => {
            document.addEventListener('click', handleClick);
            document.addEventListener('keydown', handleKeyDown);
        };

        const stopWaitingForCloseEvent = () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('click', handleClick);
        };

        // In edit mode
        if (isEditingMobileNumber) {
            // Timeout prevents edge from stalling when we select the input before it's ready
            setTimeout(() => {
                mobileNumberInputRef.current.select();
            });

            startWaitingForCloseEvent();
        // No longer in edit mode
        } else {
            stopWaitingForCloseEvent();
        }

        // On unmount
        return () => {
            stopWaitingForCloseEvent();
        };
    }, [isEditingMobileNumber]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     *  Event handler for mobile number change
     *
     * @param {Event} event event
     * @param {HTMLElement} event.target element that triggered the event
     * @param {String} event.target.value value of element
     **/
    const onChangeMobileNumber = ({target: {value}}) => {
        const processedNumber = processMobileNumberInput(mobileNumberExternal, value);
        const isValid = validateMobileNumber(processedNumber);

        // Updating state variables
        setProcessedMobileNumber(processedNumber);
        setIsMobileNumberValid(isValid);
    };

    /**
     * Event handler for clicking the close button in the modal
     */
    const onClickCloseModalButton = () => {
        onCancelEditMobileNumber();
    };

    /**
     * Event handler for submitting mobile update modal
     * - This passes value of input field to onSubmitMobileNumber callback
     *
     * @param {Event} event event
     */
    const onSubmitMobileUpdate = (event) => {
        event.preventDefault();
        onSubmitMobileNumber(get(event, 'target.elements.mobileNumber.value'));
    };

    return {
        isMobileNumberValid,
        mobileNumberEditorRef,
        mobileNumberInputRef,
        onChangeMobileNumber,
        onClickCloseModalButton,
        onSubmitMobileUpdate,
        processedMobileNumber,
    };
};

export default useMobileUpdateModal;
