import React from 'react';

import Ic from '..';

const IC145HD = (args) => (
    <Ic {...args}>
        <path d="M56.4 45.2h-1.6v8.4h1.6c1.4 0 2.3-.4 2.9-1 .6-.5 1.2-1.4 1.2-3.1s-.7-2.7-1.2-3.2c-.6-.6-1.6-1.1-2.9-1.1z" />
        <path d="M82.3 32.6H17.7c-.7 0-1.2.5-1.2 1.2v32.5c0 .7.5 1.2 1.2 1.2h64.6c.7 0 1.2-.5 1.2-1.2V33.8c0-.7-.5-1.2-1.2-1.2zM48.2 56.4h-3.3v-5.8h-5.5v5.8h-3.2V42.5h3.2v5.4h5.5v-5.4h3.2l.1 13.9zm13.4-1.6c-1.1.9-2.9 1.6-5.4 1.6h-4.7V42.5h4.7c2.4 0 4.2.7 5.4 1.7 1.2.9 2.2 2.5 2.2 5.3 0 2.6-1 4.2-2.2 5.3z" />
    </Ic>
);

IC145HD.displayName = 'IC145HD';

export default IC145HD;
