import React from 'react';
import propTypes from 'prop-types';
import {rgba} from 'polished';
import styled from 'styled-components';
import {Button} from 'normalized-styled-components';
import noop from 'lodash/noop';

import {blanc, onyx} from '../../../../common/palette';
import {transition} from '../../../../common/animations';

const StyledImg = styled.img`
    flex-shrink: 0;
    transition: ${transition('transform', 'border-color')};
    margin-right: 14px;
    border-bottom: 2px solid transparent;
    width: 56px;
    height: 56px;
`;

const StyledButton = styled(Button)`
    appearance: none;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background-color: transparent;
    padding: 0;
    width: 218px;
    height: 56px;
    color: ${blanc};
    font: var(--nucleus-success-header);

    &:focus,
    &:hover {
        ${StyledImg} {
            transform: scale(1.08);
            border-bottom-color: ${blanc};
        }
    }

    &:focus {
        outline: 0;
    }
`;

const StyledDiv = styled.div`
    display: inline-block;
    height: 100%;
    overflow: hidden;
    text-shadow: 0 1px 1px ${rgba(onyx, 0.3)};
    white-space: nowrap;
    /* stylelint-disable-next-line color-no-hex */
    mask-image: linear-gradient(to right, #000 125px, transparent);
`;

const NM06TrigBtn = React.forwardRef(({
    imageUrl,
    'aria-pressed': isPressed,
    children,
    onClick = noop,
    ...htmlAttributes
}, ref) => (
    <StyledButton
        {...htmlAttributes}
        type="button"
        onClick={onClick}
        aria-pressed={isPressed}
        ref={ref}
    >
        {!!imageUrl && (
            <StyledImg
                alt="Avatar"
                src={imageUrl}
            />
        )}
        <StyledDiv>{children}</StyledDiv>
    </StyledButton>
));

/* eslint-disable quote-props */
NM06TrigBtn.propTypes = {
    /** aria-pressed attribute describes a toggle button */
    'aria-pressed': propTypes.oneOf([true, false, 'mixed']),
    /** Content of button, text */
    children: propTypes.node,
    /** Url of the image */
    imageUrl: propTypes.string,
    /** Callback on element click */
    onClick: propTypes.func,
};
/* eslint-enable quote-props */

NM06TrigBtn.displayName = 'NM06TrigBtn';

export default NM06TrigBtn;
