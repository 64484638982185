import React from 'react';
import Ic from '..';

const IC168TeamStats = (args) => (
    <Ic {...args}>
        <path fillRule="nonzero" d="M54.4 41.9h25.7v2.8H54.4v-2.8zm0-13.3h17.1v2.8H54.4v-2.8zm0 25.3h34.2v5.6H54.4v-5.6zm0 13.3h17.1v5.6H54.4v-5.6zM19.9 55.3h25.7v2.8H19.9v-2.8zm8.5 13.3h17.1v2.8H28.4v-2.8zm-17.1-28h34.2v5.6H11.3v-5.6zm17.1-13.4h17.1v5.6H28.4v-5.6z" />
    </Ic>
);

IC168TeamStats.displayName = 'IC168TeamStats';

export default IC168TeamStats;
