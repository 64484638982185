import bacon from 'baconjs';
import superagent from 'superagent';

import {SIGNUP_API} from '@fsa-streamotion/streamotion-web-widgets-common';

import stringableBaconError from '../../../utils/stringable-bacon-error';

/**
 * Set the Terms and Conditions acceptance date for a user
 *
 *  e.g. https://signupapi-staging.streamotion.com.au/user/termsAndConditions/kayo/freemium
 *
 * @see [Swagger Docs](https://signupapi-staging.streamotion.com.au/api-doc/index.html#/Freemium/updateTncAcceptedDate)
 *
 * @param {Object} options                  see below
 * @param {string} options.accessToken      the current user's access token
 * @param {string} options.platformEnv      api/app environment
 * @param {string} [options.product]        product type ('kayo', 'binge', or 'flash')
 * @returns {bacon.Observable}              stream containing return value from API (note: expected to always be empty)
 */
export default function freemiumTermsAndConditions({
    accessToken,
    platformEnv,
    product,
}) {
    const url = `${SIGNUP_API[platformEnv]}/user/termsAndConditions/${product}/freemium`;

    return bacon.fromBinder((sink) => {
        superagent
            .post(url)
            .set('Authorization', `Bearer ${accessToken}`)
            .then((response) => void sink(response?.body))
            .catch((error) => {
                const errorBody = JSON.parse(error?.response?.text || '""');

                sink(stringableBaconError({
                    code: errorBody?.code,
                    status: error.status,
                    message: errorBody?.longMessage
                        || errorBody?.message
                        || errorBody
                        || 'Could not successfully register acceptance of Terms and Conditions. Please try again later',
                    debugMessage: `Accounts-Widgets: couldn't POST termsAndConditions acceptance (${url}): ${error.status} ${error.message}`,
                }));
            })
            .finally(() => void sink(new bacon.End()));

        return;
    });
}
