import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {A, Button, Nav} from 'normalized-styled-components';
import noop from 'lodash/noop';

import {mediaQuery, stylesWhen, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';

import VisuallyHidden from '../../../../common/visually-hidden';
import {SCREEN_TABLET, SCREEN_LG_DESKTOP} from '../../../../common/screen-sizes';
import {coal, satellite, kayoGreen, white} from '../../../../common/palette';

import GA92BrandLogo from '../../../atoms/ga/92-brand-logo';
import IC01Ham from '../../../atoms/ic/01-ham';
import IC02Sea from '../../../atoms/ic/02-sea';
import IC03Not from '../../../atoms/ic/03-not';
import IC174CcOn from '../../../atoms/ic/174-cc-on';
import BA01BtnPr from '../../../atoms/ba/01-btn-pr';
import BA08BtnSec from '../../../atoms/ba/08-btn-sec';

const BADGE_SIZE = '18px';
const BADGE_SIZE_LG = '20px';

const OuterContainer = styled(Nav)`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    background-color: ${coal};
    width: 100%;
    height: 70px;
`;

const HomeLink = styled(A)`
    margin-top: 12px;
    width: 100px;
    color: ${white};

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        margin-left: 40px;
    `}
`;

const ShowHide = styled.div`
    display: none; /* specifically not accessible because App Dock will render NM07TopNav until SCREEN_LG_DESKTOP */
    flex: 1 0 auto;
    align-self: flex-end;
    margin-top: 11px;

    ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
        display: block;
    `}
`;

const RightSideContainer = styled.div`
    display: flex;
    align-items: center;
    text-align: right;
`;

const RightSideCtaContainer = styled.div`
    display: flex;

    ${stylesWhenNot('hasAdjacentSibling')`
        padding-right: 14px;
    `}

    > :not(:last-child) {
        ${mediaQuery({minWidthPx: SCREEN_TABLET})`
            margin-right: 7px;
        `}
    }
`;

const StyledBA01BtnPr = styled(BA01BtnPr)`
    padding: 0 7px;
    min-width: 90px;
    white-space: nowrap;

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        padding: 0 21px;
    `}
`;

const StyledBA08BtnSec = styled(BA08BtnSec)`
    display: none;

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        display: flex; /* Only show secondary button (Sign In) on tablet or larger */
        min-width: 90px;
        white-space: nowrap;
    `}
`;

const HeaderFunctionsContainer = styled.div`
    flex: 1 0 auto;
    margin: 11px 0;
    text-align: right;

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        margin-left: 21px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
        flex-grow: 0;
    `}
`;

const HeaderButton = styled(Button)`
    appearance: none;
    position: relative;
    border-width: 0;
    background-color: transparent;
    padding: 10px;
    width: 46px;
    height: 46px;
    color: ${satellite};

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        margin-right: 7px;
    `}

    &:focus,
    &:hover,
    &[aria-expanded='true'] {
        outline: 0;
        color: ${kayoGreen};
    }

    &[aria-expanded='true']:focus {
        transform: scale(1.1);
    }
`;

const NotificationBadge = styled.span`
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    transform: translate(-2px, -3px);
    border-radius: 50%;
    background-color: ${kayoGreen};
    min-width: ${BADGE_SIZE};
    height: ${BADGE_SIZE};
    color: ${coal};
    font: var(--mui-body-copy-6-bold);
    line-height: 1; /* stylelint-disable-line order/properties-order */

    ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
        height: ${BADGE_SIZE_LG};
        min-width: ${BADGE_SIZE_LG};
        transform: translate(-1px, -4px);
    `}

    ${stylesWhen(({notificationCount}) => notificationCount > 99)`
        border-radius: 9px;
        padding: 0 5px;
        transform: translate(9px, -4px);

        ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
            transform: translate(13px, -5px);
        `}
    `}
`;

const OR33HeaderDsk = ({
    children,
    homeLink = '/',
    homeLinkAssistiveLabel = 'Home page',
    isAppDockOpen,
    isHeaderFunctionsHidden,
    isNotificationModalOpen,
    isSearchModalOpen,
    notificationCount,
    onAppDockClick = noop,
    onHomeLinkClick,
    onNotificationsClick = noop,
    onSearchClick = noop,
    onChromecastClick = noop,
    hasNotificationsIcon,
    hasChromecastIcon,
    hasHeaderButtons = true,
    isChromecastConnected,
    topBarCta = [],
}) => (
    <OuterContainer className="OR33HeaderDsk">
        <HomeLink href={homeLink} title={homeLinkAssistiveLabel} onClick={onHomeLinkClick} aria-label={homeLinkAssistiveLabel}>
            <GA92BrandLogo isInternalMode={true} width="100%" height="38px" />
        </HomeLink>

        <ShowHide>
            {children}
        </ShowHide>

        {!isHeaderFunctionsHidden && (
            <RightSideContainer>
                {!!topBarCta.length && (
                    <RightSideCtaContainer hasAdjacentSibling={hasHeaderButtons}>
                        {topBarCta.map((buttonDetails, index) => {
                            const ButtonComponent = buttonDetails.buttonType === 'primary' ? StyledBA01BtnPr : StyledBA08BtnSec;

                            return (
                                <ButtonComponent
                                    key={`top-bar-cta-${index}`}
                                    isBlock={true}
                                    href={buttonDetails.href}
                                    onClick={buttonDetails.onClick}
                                >
                                    {buttonDetails.text}
                                </ButtonComponent>
                            );
                        })}
                    </RightSideCtaContainer>
                )}
                {!!hasHeaderButtons && (
                    <HeaderFunctionsContainer>
                        {!!hasChromecastIcon && (
                            <HeaderButton onClick={onChromecastClick} aria-pressed={isChromecastConnected} aria-label="Chromecast">
                                <IC174CcOn accentColor={isChromecastConnected ? kayoGreen : white} />
                            </HeaderButton>
                        )}
                        <HeaderButton onClick={onSearchClick} aria-haspopup="true" aria-expanded={isSearchModalOpen}>
                            <IC02Sea ariaLabel="Search" />
                        </HeaderButton>
                        {!!hasNotificationsIcon && (
                            <HeaderButton onClick={onNotificationsClick} aria-haspopup="true" aria-expanded={isNotificationModalOpen}>
                                <IC03Not ariaLabel="Notifications" />
                                {notificationCount > 0 ? (
                                    <NotificationBadge notificationCount={notificationCount}>
                                        {notificationCount > 99 ? '99+' : notificationCount}
                                        <VisuallyHidden> new</VisuallyHidden>
                                    </NotificationBadge>
                                ) : (
                                    <VisuallyHidden>all caught up</VisuallyHidden>
                                )}
                            </HeaderButton>
                        )}
                        <HeaderButton onClick={onAppDockClick} aria-haspopup="true" aria-expanded={isAppDockOpen}>
                            <IC01Ham ariaLabel="Main menu" />
                        </HeaderButton>
                    </HeaderFunctionsContainer>
                )}
            </RightSideContainer>
        )}
    </OuterContainer>
);

OR33HeaderDsk.propTypes = {
    /** NM07TopNav, lol */
    children: propTypes.node,
    /** For the logo link to go home */
    homeLink: propTypes.string,
    /** Assistive label text For the logo link */
    homeLinkAssistiveLabel: propTypes.string,
    /** For aria-expanded */
    isAppDockOpen: propTypes.bool,
    /** Hides the notification, search, and app dock buttons */
    isHeaderFunctionsHidden: propTypes.bool,
    /** For aria-expanded */
    isSearchModalOpen: propTypes.bool,
    /** For aria-expanded */
    isNotificationModalOpen: propTypes.bool,
    /** Is the notification control rendered */
    hasNotificationsIcon: propTypes.bool,
    /** Is the chromecast control rendered */
    hasChromecastIcon: propTypes.bool,
    /** What number to display in the notification badge */
    notificationCount: propTypes.number,
    /** Function for when Hamburger menu is clicked */
    onAppDockClick: propTypes.func,
    /** Function for when Notification bell is clicked */
    onNotificationsClick: propTypes.func,
    /** Function for when Search magnifying-glass is clicked */
    onSearchClick: propTypes.func,
    /** Function for when Chromecast icon is clicked */
    onChromecastClick: propTypes.func,
    /** Callback on home link click */
    onHomeLinkClick: propTypes.func,
    /** Should we show the header functions including the app dock toggle (Hamburger button)? */
    hasHeaderButtons: propTypes.bool,
    /** Is the Chromecast connected */
    isChromecastConnected: propTypes.bool,
    /** CTAs to show on top bar */
    topBarCta: propTypes.arrayOf(
        propTypes.shape({
            text: propTypes.string,
            href: propTypes.string,
            onClick: propTypes.func,
            buttonType: propTypes.oneOf(['primary', 'secondary']),
        })
    ),
};

OR33HeaderDsk.displayName = 'OR33HeaderDsk';

export default OR33HeaderDsk;
