import DEFAULT_AEM_CONFIG_WHEN_BROKE_KAYO from './kayo.json';
import DEFAULT_AEM_CONFIG_WHEN_BROKE_BINGE from './binge.json';

// @TODO: leaving the comment about all _publishUrl values set in the json file(s) here that they need to be updated to their prod ones
// before this is released for Kayo Landing prod launch
export default function getBackupAemConfig({brand}) {
    switch (brand) {
        case 'binge':
            return DEFAULT_AEM_CONFIG_WHEN_BROKE_BINGE;

        case 'flash':
            return DEFAULT_AEM_CONFIG_WHEN_BROKE_KAYO; // @TODO: add Flash's when Flash also gets aem enabled landing page

        case 'kayo':
            return DEFAULT_AEM_CONFIG_WHEN_BROKE_KAYO;

        default:
            console.error('[getBackupAemConfig] Invalid brand to get fallback aem config', {brand});
    }
}
