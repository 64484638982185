import get from 'lodash/get';

export default function getHandleFormSubmit(submitCallback, formFields) {
    return (event) => {
        event.preventDefault();
        const data = formFields.reduce((curr, name) => {
            const element = get(event, ['target', 'elements', name]) || {};

            return Object.assign(curr, {
                [name]: ['checkbox', 'radio'].includes(element.type) ? element.checked : element.value,
            });
        }, {});

        submitCallback(data);
    };
}
