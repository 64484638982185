import {
    getLocalStorageValue,
    removeLocalStorageValue,
    setLocalStorageValue,
} from '@fsa-streamotion/browser-utils';

import {
    POST_LOGIN_REDIRECT_STORAGE_KEY,
    LOCALSTORAGE_KEY_ENTITLED_ACCESS_TOKEN,
    POST_PROFILE_REDIRECT_STORAGE_KEY,
} from '../constants';

/**
 * @typedef {Object} SetOptions     - See below
 * @property {string} value         - The value of to be set into localStorage
 * @property {number} expiresIn     - Expiration date for value set into localStorage
 */

/**
 * @typedef {Object} Persistence
 * @property {(SetOptions) => void} setEntitledAccessToken                  - Sets access_token (Entitled) to localStorage
 * @property {() => string | null} getEntitledAccessToken                   - Returns access_token (Entitled) from localStorage
 * @property {() => void} removeEntitledAccessToken                         - Removes access_token (Entitled) from localStorage
 * @property {(string) => void} setPostLoginRedirectionLocation             - Sets post login redirection value to session storage
 * @property {(string) => void} setPostProfileRedirectionLocation           - Sets post profile redirection value to session storage
 */

/**
 * @param {Object} options                  - (See below)
 * @param {string} options.clientId         - The currently mapped client_id for the Auth0 tenant
 * @param {string} options.platformEnv      - The currently mapped client_id for the Auth0 tenant
 * @param {string} options.normalisedBrand  - Brand string e.g. binge, flash, kayo
 * @returns {Persistence}                   - localStorage/sessionStorage functionality with pre-defined keys
 */
function createPersistence({clientId, platformEnv, normalisedBrand}) {
    const entitledAccessTokenKey = `${clientId}_${LOCALSTORAGE_KEY_ENTITLED_ACCESS_TOKEN}`;
    const postLoginRedirectionKey = [POST_LOGIN_REDIRECT_STORAGE_KEY[normalisedBrand], platformEnv].join('-');
    const postProfileRedirectionKey = [POST_PROFILE_REDIRECT_STORAGE_KEY[normalisedBrand], platformEnv].join('-');

    return {
        setEntitledAccessToken: ({value, expiresIn}) => {
            setLocalStorageValue({key: entitledAccessTokenKey, value, expiresIn});
        },
        getEntitledAccessToken: () => getLocalStorageValue({key: entitledAccessTokenKey, defaultValue: null}),
        removeEntitledAccessToken: () => {
            removeLocalStorageValue(entitledAccessTokenKey);
        },
        setPostLoginRedirectionLocation: (value) => {
            window.sessionStorage.setItem(postLoginRedirectionKey, value);
        },
        setPostProfileRedirectionLocation: (value) => {
            window.sessionStorage.setItem(postProfileRedirectionKey, value);
        },
    };
}

export default createPersistence;
