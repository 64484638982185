import React from 'react';
import styled from 'styled-components';
import {Svg} from 'normalized-styled-components';

const StyledSvg = styled(Svg)`
    display: block;
    width: auto;
    height: 100%; /* Scaled by HEIGHT :O */
`;

const GA117ParentalControlsG = ({
    ...htmlAttributes
}) => (
    <StyledSvg
        {...htmlAttributes}
        viewBox="0 0 69 58"
    >
        <path fill="#33A02C" d="M0 0h69v58H0z" />
        <path fill="#FFF" d="M39.794 42.545C37.977 44.354 35.9 45 33.435 45s-4.542-.775-6.1-2.455C25 40.348 25 37.505 25 33.5c0-3.876 0-6.72 2.336-8.916 1.557-1.68 3.504-2.584 6.1-2.584 5.19 0 7.915 3.489 8.564 7.365h-4.542c-.52-2.196-1.817-3.36-4.023-3.36a4.125 4.125 0 00-2.855 1.164c-.908 1.033-1.038 2.067-1.038 6.331 0 4.393.13 5.556 1.038 6.46.649.776 1.687 1.164 2.855 1.164 1.298 0 2.466-.517 3.115-1.293.778-.775 1.038-1.809 1.038-2.971v-.905h-4.153v-3.618H42v3.23c0 3.36-.52 5.298-2.206 6.978z" />
        <path fill="#FFF" d="M34 9.81c1.137 1.916 20.337 34.741 21.474 36.785H12.4C13.663 44.551 32.863 11.725 34 9.81zm-.632-1.788L10 48h48L34 7l-.632 1.022z" />
    </StyledSvg>
);

GA117ParentalControlsG.displayName = 'GA117ParentalControlsG';

export default GA117ParentalControlsG;
