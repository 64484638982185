import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import classnames from 'classnames';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {colorType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {themeColorType} from '../../../../common/custom-proptypes';
import {transition} from '../../../../common/animations';
import {blanc} from '../../../../common/palette';
import muid from '../../../../common/muid';
import {SCREEN_PHONE, SCREEN_768_TABLET, SCREEN_1280_DESKTOP, SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import SrcsetImg from '../../../../common/srcset-img';
import {defaultBrandLogoSrcset} from './get-brand-logo-srcset';

const ImageWrapper = styled.div`
    display: inline-block;
`;

const StyledSrcsetImage = styled(SrcsetImg)`
    display: inline-block;
`;

const StyledSvg = styled.svg`
    display: inline-block;
`;

const StyledPath = styled.path`
    transition: ${transition('fill')};
`;

const BRANDING_WIDTH_PX = 91;
const BRANDING_WIDTH_LG_DESKTOP_PX = 151;
const BRANDING_WIDTH_PLG_DESKTOP_PX = 227;
const BRANDING_OPTUS_SUBHUB_WIDTH_PX = 162;
const BRANDING_OPTUS_SUBHUB_WIDTH_LG_DESKTOP_PX = 267;
const BRANDING_OPTUS_SUBHUB_WIDTH_PLG_DESKTOP_PX = 404;

// If you're using `Branding` (below), this is the sizesDefinition for optimal scaling of the nested GA92BrandLogo
export const BRANDING_SIZES_DEFINITION = {
    [SCREEN_PHONE]: BRANDING_WIDTH_PX,
    [SCREEN_1280_DESKTOP]: BRANDING_WIDTH_LG_DESKTOP_PX,
    [SCREEN_1920_DESKTOP]: BRANDING_WIDTH_PLG_DESKTOP_PX,
};

export const BRANDING_OPTUS_SUBHUB_SIZES_DEFINITION = {
    [SCREEN_PHONE]: BRANDING_OPTUS_SUBHUB_WIDTH_PX,
    [SCREEN_1280_DESKTOP]: BRANDING_OPTUS_SUBHUB_WIDTH_LG_DESKTOP_PX,
    [SCREEN_1920_DESKTOP]: BRANDING_OPTUS_SUBHUB_WIDTH_PLG_DESKTOP_PX,
};

export const Branding = styled.div`
    position: absolute;
    top: 0;
    left: 21px;
    width: ${({isOptus}) => isOptus ? `${BRANDING_OPTUS_SUBHUB_SIZES_DEFINITION[SCREEN_PHONE]}px` : `${BRANDING_SIZES_DEFINITION[SCREEN_PHONE]}px`};

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        left: 56px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        width: ${({isOptus}) => isOptus ? `${BRANDING_OPTUS_SUBHUB_SIZES_DEFINITION[SCREEN_1280_DESKTOP]}px` : `${BRANDING_SIZES_DEFINITION[SCREEN_1280_DESKTOP]}px`};
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: ${({isOptus}) => isOptus ? `${BRANDING_OPTUS_SUBHUB_SIZES_DEFINITION[SCREEN_1920_DESKTOP]}px` : `${BRANDING_SIZES_DEFINITION[SCREEN_1920_DESKTOP]}px`};
    `}
`;

/*
 *  A small history lesson: You'll see down below that the component is pulling from the resources URL instead of the SVG.
 *  The main reason is that when this component was going through DQA at the time, the main person who looked at the DQA builds of this logo
 *  noticed that the diagonal gradients were not rendered properly on the browser. There was much scrutiny with the appearance of the logo, hence
 *  accessing the resources URL was applied here. Currently, DQA still finds some missing details here, so for the time being, this component
 *  will contain a srcset override prop if the tests are going through Vizard, since migrating to Github Actions prevents the container to access the resource URL
 *  and failing the Vizard tests.
 *
 *  Personally, I don't like to resort to this, but in favour of trying to get the Vizard tests to pass this was the only way to do this
 */

/*
In this pre-launch phase, designers want to be able to change the logo often without needing coordinated releases across devices
So this component is temporarily just an `img` element that points to a resources URL
The comments below are for the old, commented out code, that might be relevant again once we stop pointing this directly at the image.

===================
OLD COMMENTS BELOW:
===================

There are basically 2 variants of this brand logo:
1. BINGE wordmark on top of a "wedge" with a configurable background color (see "color" prop)
2. BINGE wordmark on top of a multicolored red->blue wedge, which designers call a "mesh"

These all require vaguely different SVG code. To keep this file readable we have just fully chunked the SVG code into these distinct 1.5 cases.
While it may be possible to make the code below marginally more concise, we have optimised for readability here (after running all of this through SVGO).

Also note that we're using SVG filters here, which means we need to use IDs. We have attempted to give these IDs names that will not clash with the rest of the product (e.g. "ga92-brand-logo__filter-mesh").
We're operating on a hypothesis here that, while putting this logo on the page twice will effectively mean we have ID clashes, since DOM nodes with those IDs are identical, it won't be a problem.
 */
const GA92BrandLogo = ({
    className,
    color,
    height,
    id = muid(),
    isMesh,
    isOptus,
    srcsetOptions,
    title = 'Binge',
    width = '100%',
    ...htmlAttributes
}) => {
    // ==================================================================
    // ============ BEGIN TEMPORARY PRE-LAUNCH OVERRIDE =================
    // ==================================================================
    // In this pre-launch phase, designers all of a sudden want to be able to change the logo often without needing coordinated releases
    // So this component is temporarily just a img element that points to a resources URL
    return (
        // We use a wrapper div here so this image can be covered up in Viz tests, otherwise it breaks everywhere non-deterministically
        <ImageWrapper className={classnames('GA92BrandLogo', className)}>
            <StyledSrcsetImage
                {...htmlAttributes}
                alt={title}
                style={{
                    width,
                    height,
                }}
                // srcset is important here because otherwise the downsampling can make the logo look blurry
                srcsetOptions={srcsetOptions || defaultBrandLogoSrcset({isOptus})}
            />
        </ImageWrapper>
    );
    // ==================================================================
    // ============= END TEMPORARY PRE-LAUNCH OVERRIDE ==================
    // ==================================================================

    // Including unreachable code here is preferable to commenting, so syntax highlighting stops us from getting too broken
    // The compiler will trim all this out anyway
    // eslint-disable-next-line no-unreachable
    return (
        <StyledSvg
            {...htmlAttributes}
            id={id}
            className={classnames('GA92BrandLogo', className)}
            style={{
                width,
                height,
            }}
            viewBox="0 0 112 79"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>{title}</title>

            <defs>
                <linearGradient id={`ga92-brand-logo-${id}__filter-mesh`} x1="81.223%" x2="19.92%" y1="90.079%" y2="6.735%">
                    <stop offset="0%" stopColor="#f6a42a" />
                    <stop offset="25.873%" stopColor="#ce0042" />
                    <stop offset="39.445%" stopColor="#b00073" />
                    <stop offset="56.679%" stopColor="#740fa4" />
                    <stop offset="79.921%" stopColor="#112ac2" />
                    <stop offset="100%" stopColor="#005cdd" />
                </linearGradient>

                <filter
                    id={`ga92-brand-logo-${id}__filter-copy`}
                    width="109.6%"
                    height="137.1%"
                    x="-4.4%"
                    y="-18.5%"
                    filterUnits="objectBoundingBox"
                >
                    <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation=".5" />
                    <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
            </defs>

            {/* The polygon */}
            <path
                fill={`url(#ga92-brand-logo-${id}__filter-mesh)`}
                d="M16.444-8.449l60.813-7.071L95.444 88.255l-79 8.109z"
                transform="matrix(0 1 1 0 15.521 -16.521)"
            />

            {/* Apply "colour" overlay. Make it transparent when not needed so it can animate "in" */}
            <StyledPath
                fill={(!isMesh && color) || 'transparent'}
                d="M16.444-8.449l60.813-7.071L95.444 88.255l-79 8.109z"
                transform="matrix(0 1 1 0 15.521 -16.521)"
            />

            {/* The "binge" copy */}
            <g filter={`url(#ga92-brand-logo-${id}__filter-copy)`} transform="translate(9.005 31.82)">
                <path
                    d="M72.36 0l-.683 5.541h-8.786l-.273.015c-2.075 0-2.996.556-3.788 1.398-.86.915-1.439 2.139-1.245 3.955.132 1.234.905 2.324 1.713 2.893.89.627 1.592.874 2.612.874.737 0 2.253-.247 2.883-1.993L62.66 8.571l3.672-.001.003-.015h4.954l-1.447 11.7h-4.08l-4.277.006c-2.946 0-5.076-.554-7.342-2.835-1.332-1.328-2.827-3.936-2.827-6.806 0-2.858.825-5.513 2.638-7.39C55.172 1.96 57.19 0 61.182 0H72.36zM38.27.034l5.532 10.701L45.14.034h6.121l-2.536 20.221h-6.112L37.037 9.561l-1.382 10.694h-6.112L32.071.034h6.199zm52.764 0l-.677 5.507H79.759l-.21 1.752h8.787l-.706 5.573h-8.764l-.254 1.818h10.647l-.711 5.57-10.709-.001v.002h-6.11l-.001-.002-.015.001L74.248.034h16.786zm-60.953 0l-.693 5.483.018-.01-1.846 14.748h-6.824l1.325-10.767 7.306-3.96-.074.001c-.378.002-1.784.006-6.73.006l.695-5.501h6.823zM13.424.02c3.842 0 6.957.972 6.375 6.306-.242 2.223-1.042 3.112-2.688 3.658l-.285.077.264.084c2.076.778 2.81 2.104 2.514 4.518-.534 4.157-4.072 5.578-7.546 5.578H.142L2.668.02zm-2.63 12.13H7.346l-.4 3.352h3.423c1.184 0 2.878-.147 3.08-1.69.195-1.482-1.432-1.662-2.657-1.662zm.714-7.17H8.247l-.37 2.897h3.409c1.1-.008 2.041-.118 2.198-1.397.162-1.315-.923-1.5-1.976-1.5z"
                    fill={blanc}
                />
            </g>
        </StyledSvg>
    );
};

GA92BrandLogo.displayName = 'GA92BrandLogo';

GA92BrandLogo.propTypes = {
    /** Additional CSS classname(s) to use */
    className: classNameType,
    /** Optional custom logo color */
    color: propTypes.oneOfType([colorType, themeColorType]),
    /** Optional height of logo */
    height: propTypes.string,
    /** Optional element id used for root node and svg reference uniqueness */
    id: propTypes.string,
    /** Whether to show the mesh logo */
    isMesh: propTypes.bool,
    /** Whether to show the Optus SubHub logo */
    isOptus: propTypes.bool,
    /** Optional srcset to use for the brand logo */
    srcsetOptions: propTypes.objectOf(propTypes.string),
    /** Accessible label for logo */
    title: propTypes.string,
    /** Optional width of logo */
    width: propTypes.string,
};

export default GA92BrandLogo;
