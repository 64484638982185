import styled, {css} from 'styled-components';
import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {
    SCREEN_PHONE,
    SCREEN_768_TABLET,
    SCREEN_1920_DESKTOP,
} from './screen-sizes';

const BRANDING_WIDTH_PX = 133;
const BRANDING_WIDTH_PLG_DESKTOP_PX = 227;

// If you're using `Branding` (below), this is the sizesDefinition for optimal scaling of the nested GA92BrandLogo
export const BRANDING_SIZES_DEFINITION = {
    [SCREEN_PHONE]: BRANDING_WIDTH_PX,
    [SCREEN_1920_DESKTOP]: BRANDING_WIDTH_PLG_DESKTOP_PX,
};

export const Branding = styled.div`
    position: relative;
    top: 14px;
    left: 21px;
    width: ${BRANDING_SIZES_DEFINITION[SCREEN_PHONE]}px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        left: 56px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        top: 28px;
        width: ${BRANDING_SIZES_DEFINITION[SCREEN_1920_DESKTOP]}px;
    `}
`;

export const brandingSizeStyles = css`
    width: ${BRANDING_SIZES_DEFINITION[SCREEN_PHONE]}px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: ${BRANDING_SIZES_DEFINITION[SCREEN_1920_DESKTOP]}px;
    `}
`;
