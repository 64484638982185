import bacon from 'baconjs';
import {isServer, isBrowser} from '@fsa-streamotion/browser-utils';

let appMeasurementLoaded = false;

const adobeReadyStateStream = () => bacon.mergeAll([
    // Tracking library will fire appMeasurementLoaded event when it's ready to accept _satellite calls
    bacon.fromEvent(document, 'appMeasurementLoaded').map('appMeasurementLoaded event'),

    /**
     * There's still a possibility though that this singleton is instantiated after that event has been fired
     *
     * If we see that _satellite is defined, assume that if we don't hear the above event for 2 seconds, that we
     * have simply missed it and can proceed with tracking calls
     *
     * If we see that `_satellite` is undefined, wait 3 seconds before allowing events. This may result in calls through
     * to _satellite whilst it is undefined or prior to the library being loaded. We can't wait forever though, and 3 seconds
     * is reasonable enough. `callAdobeSatelliteTrack` will catch and fail silently anyway and other logic can proceed unimpeded
     * */

    bacon.later(window._satellite ? 2000 : 3000, window._satellite ? 'window._satellite defined' : 'window._satellite undefined'),
]);

(function initAdobeLoadedStateSingleton() {
    if (isBrowser()) {
        adobeReadyStateStream()
            .firstToPromise()
            .then(() => {
                appMeasurementLoaded = true;
            });
    }
}());

export default function getAppMeasurementLoadedEventStream() {
    return isServer() || appMeasurementLoaded
        ? bacon.later(0, {})
        : adobeReadyStateStream().first();
}
