import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {stylesWhenBinge, stylesWhenFlash, stylesWhenKayo, stylesWhenLifestyle} from '../../../../../utils/styles-when-brand';

import brandPropType from '../../../../../common/brand-proptype';
import {SCREEN_375_PHABLET, SCREEN_1280_DESKTOP} from '../../../../../common/screen-sizes';
import VisuallyHidden from '../../../../../common/visually-hidden';

/** **********************************************************************
 *                         TABLE HEADER ROW
 ************************************************************************/

const HeaderCell = styled.th.attrs({
    scope: 'col',
})`
    padding: 0 0 5px;
    word-wrap: break-word;

    ${stylesWhenBinge`
        font: var(--ionic-header-10);
    `}

    ${stylesWhenFlash`
        font: var(--infinity-header-10);
    `}

    ${stylesWhenKayo`
        font: var(--mui-header-10);
    `}

    ${stylesWhenLifestyle`
        font: var(--nucleus-header-10);
    `}

    ${mediaQuery({minWidthPx: SCREEN_375_PHABLET})`
        padding-right: 5px;
        padding-left: 5px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        padding-right: 24px;
        padding-left: 24px;
    `}
`;

const TableHeader = ({
    brand,
    headers,
}) => (
    <React.Fragment>
        <VisuallyHidden as="caption">Our streaming packages</VisuallyHidden>
        <thead>
            <tr>
                <VisuallyHidden as="th" scope="col">Package Name</VisuallyHidden>
                {headers.map(({title, a11yTitle}) => (
                    <HeaderCell
                        brand={brand}
                        key={title}
                        {...(!!a11yTitle && {'aria-label': a11yTitle})}
                    >
                        {title}
                    </HeaderCell>
                ))}
                <VisuallyHidden as="th" scope="col">Selected?</VisuallyHidden>
            </tr>
        </thead>

    </React.Fragment>
);

TableHeader.displayName = 'PackageSelectorTableHeader';

TableHeader.propTypes = {
    brand: brandPropType,
    headers: propTypes.arrayOf(propTypes.shape({
        title: propTypes.node,
        a11yTitle: propTypes.string,
    })),
};

export default TableHeader;
