import React from 'react';

import Ic from '..';

const IC125Grid = (args) => (
    <Ic {...args}>
        <path d="M46.2 22.4H6.7c-.6 0-1.1.5-1.1 1.1v23.3c0 .6.5 1.1 1.1 1.1h39.5c.6 0 1.1-.5 1.1-1.1V23.5c0-.6-.5-1.1-1.1-1.1zm-1.1 23.3H7.8V24.6h37.3v21.1zM93.8 22.5H53.4c-.3 0-.6.2-.6.6v24.3c0 .3.2.6.6.6h40.4c.3 0 .6-.2.6-.6V23c0-.3-.3-.5-.6-.5zm-.5 24.2H53.9V23.6h39.3v23.1zM46.7 52.1H6.2c-.3 0-.6.2-.6.6V77c0 .3.2.6.6.6h40.4c.3 0 .6-.2.6-.6V52.7c0-.3-.2-.6-.5-.6zm-.6 24.3H6.8V53.3h39.3v23.1zM93.8 52.1H53.4c-.3 0-.6.2-.6.6V77c0 .3.2.6.6.6h40.4c.3 0 .6-.2.6-.6V52.7c0-.3-.3-.6-.6-.6zm-.5 24.3H53.9V53.3h39.3v23.1z" />
    </Ic>
);

IC125Grid.displayName = 'IC125Grid';

export default IC125Grid;
