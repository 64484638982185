import React from 'react';
import Ic from '..';

const IC108Scor = (args) => (
    <Ic {...args}>
        <path d="M13.9 57.8c-.2 18 15 33.1 34 32.5 17.4-.6 31.9-14.5 31.9-32.3 0-18.4-15.3-33-34.1-32.4-18.1.7-31.9 15.4-31.8 32.2m25.5-41.1V13h-9.5c-.2-1.3 0-2.6.3-3.8C31.6 3.8 36.5 0 42.3 0h9.1c6.2 0 11.5 4.6 12.2 10.7.1.7.1 1.5.2 2.2h-9.4v3.7c11.8 2.3 21.2 8.2 27.9 18.1 6.7 9.9 8.8 20.7 6.2 32.2-4.7 20.7-23.7 34.1-44.3 32.9C21.5 98.5 6.1 81 4.2 62.2 2 40 17.7 20.5 39.4 16.7m48.8 15.7l-12.4-12c.1-.1.2-.2.3-.4 1.8-1.8 3.7-3.6 5.5-5.4 1.5-1.5 3.6-1.5 5.1 0 2.4 2.3 4.8 4.7 7.2 7 1.5 1.5 1.6 3.5.1 5-1.9 2-3.8 3.9-5.8 5.8M73 58.1c.2 8.6-4.9 18.6-15 23.3-10.1 4.7-22.1 2.6-30-5.2-8.1-8.1-9.7-20.4-4.5-30 5.4-10 15.6-13.9 23.1-13.6v25.6H73z" />
    </Ic>
);

IC108Scor.displayName = 'IC108Scor';

export default IC108Scor;
