import {BASE_DEFAULT_SETTINGS, BASE_BILLING_HOSTED_PAYMENT_PAGE, ENVIRONMENT, BRAND} from '../constants';
import {getBrandBillingHostedPaymentPage, getBrandEnvsFromWidgetSettings} from '../envs';

/** * @type {Object.<Environment, string>} */
// export const PREFERENCES_API = {
//     [ENVIRONMENT.production]: 'https://preferencesapi.flashnews.com.au',
//     [ENVIRONMENT.staging]: 'https://preferencesapi-staging.flashnews.com.au',
// };

/** * @type {Object.<Environment, string>} */
// export const PLAY_SERVICE_URL = {
//     [ENVIRONMENT.production]: 'https://play.flashnews.com.au/api/v1',
//     [ENVIRONMENT.staging]:    'https://play-staging.flashnews.com.au/api/v1',
// };

/**
 * The API formerly known as 'Content API', and now sometimes known as 'Content Experience API'
 *
 * @type {Object.<Environment, string>}
 */
// @TODO: Update these values once finalised
export const XP_API_URL = {
    [ENVIRONMENT.production]:  'https://api.flashnews.com.au/v1',
    [ENVIRONMENT.staging]:     'https://api-staging.flashnews.com.au/v1',
};

const MAGNETO_BASE_BILLING_PAGE = BASE_BILLING_HOSTED_PAYMENT_PAGE[BRAND.MAGNETO];

/** * @type {Object.<Environment, string>} */
export const BILLING_HOSTED_PAYMENT_PAGE = {...MAGNETO_BASE_BILLING_PAGE};

/** * @type {Object.<Environment, string>} */
// export const CHROMECAST_RECEIVER_APPLICATION_ID = {
//     [ENVIRONMENT.production]: '11AEE8A6',
//     [ENVIRONMENT.staging]: 'D6DE5D17',
//     dev1: 'CC9A1D73',
//     dev2: '17724A7F',
//     qa1: 'BC2BA8B1',
//     qa2: '3338D2CD',
// };

/** * @type {Object.<Environment, string>} */
// export const FEEDBACK_IFRAME_URL = {
//     [ENVIRONMENT.production]: 'https://feedback.flashnews.com.au/cgi-bin/qwebcorporate.dll?idx=W58AET',
//     [ENVIRONMENT.staging]:    'https://feedback.kayosports.com.au/cgi-bin/qwebcorporate.dll?preview=1&idx=W58AET',
// };

/** * @type {Object.<Environment, string>} */
export const RESOURCES_URL = {
    [ENVIRONMENT.production]:     'https://resources.streamotion.com.au/production/magneto',
    [ENVIRONMENT.staging]:        'https://resources.streamotion.com.au/staging/magneto',
    [ENVIRONMENT.development]:    'https://resources.streamotion.com.au/staging/magneto',
};

/** * @type {Object.<Environment, string>} */
// export const CDN_SELECTION_API_URL = {
//     [ENVIRONMENT.production]: 'https://cdnselectionserviceapi.flashnews.com.au',
//     [ENVIRONMENT.staging]: 'https://cdnselectionserviceapi-staging.flashnews.com.au',
// };

// first env is the default  (why do we have other non-defaults? they're a whitelist of allowed envs)
export const DEFAULT_ENV_SETTINGS = {...BASE_DEFAULT_SETTINGS};

/**
 * Get the hosted payment page ID for the specified platform environment and origin.
 *
 * @param {Object} options             - (see below)
 * @param {string} options.platformEnv - platform environment
 * @param {string} options.origin      - origin
 * @returns {string|undefined} the ID of the payment page ID if found
 */
function getBillingHostedPaymentPage({platformEnv, origin}) {
    return getBrandBillingHostedPaymentPage(BILLING_HOSTED_PAYMENT_PAGE, [platformEnv, origin]);
}

/**
 * Given a settings object (e.g. as passed to a fiso widget), return a new
 * object containing ONLY environment properties with default environments
 *
 * @param {Object} settings Settings object, e.g. as passed in to FISO
 * @returns {Object} object only containing environments, where they have been defaulted when invalid/not provided
 */
function envsFromWidgetSettings(settings) {
    return getBrandEnvsFromWidgetSettings(DEFAULT_ENV_SETTINGS, settings);
}

// @TODO: Update/remove exports when values are finalised
export default {
    // PREFERENCES_API,
    // PLAY_SERVICE_URL,
    XP_API_URL,
    BILLING_HOSTED_PAYMENT_PAGE,
    // CHROMECAST_RECEIVER_APPLICATION_ID,
    // FEEDBACK_IFRAME_URL,
    RESOURCES_URL,
    // CDN_SELECTION_API_URL,
    DEFAULT_ENV_SETTINGS,
    getBillingHostedPaymentPage,
    envsFromWidgetSettings,
};
