import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import {colorType} from '@fsa-streamotion/custom-prop-types';
import GA78H2hBar from '../../../atoms/ga/78-h2h-bar';
import VisuallyHidden from '../../../../common/visually-hidden';
import {silverLining} from '../../../../common/palette';

const Container = styled.div`
    color: ${silverLining};
`;

const Title = styled.h3`
    margin: 0 0 7px;
    font: var(--mui-hud-tile-copy-light);
`;

const TotalStatValue = styled.span`
    font: var(--mui-hud-tile-header);
`;

const Subtitle = styled.span`
    padding-left: 4px;
    font: var(--mui-hud-tile-copy-light);
`;

const PlayerName = styled.div`
    max-width: calc(50% - 7px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font: var(--mui-hud-tile-copy);
`;

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
`;

const IGM11SecStat = ({
    leftStatValue,
    rightStatValue,
    leftStatTitle,
    rightStatTitle,
    leftStatDescription,
    rightStatDescription,
    color,
    totalStatTitle,
    totalStatSubtitle,
    totalStatValue = leftStatValue + rightStatValue,
}) => (
    <Container className="IGM11SecStat">
        <FlexRow>
            <Title>{totalStatTitle}</Title>
            <div>
                <TotalStatValue>{totalStatValue}</TotalStatValue>
                {!!totalStatSubtitle && (
                    <Subtitle>{totalStatSubtitle}</Subtitle>
                )}
            </div>
        </FlexRow>
        <FlexRow aria-hidden={true}>
            <PlayerName>{leftStatTitle}</PlayerName>
            <PlayerName>{rightStatTitle}</PlayerName>
        </FlexRow>
        <GA78H2hBar
            aria-hidden={true}
            color={color}
            leftValue={leftStatValue}
            leftDescriptionText={leftStatDescription}
            rightValue={rightStatValue}
            rightDescriptionText={rightStatDescription}
        />
        <VisuallyHidden>
            {`${leftStatTitle}: ${[leftStatValue, leftStatDescription].filter(Boolean).join(' ')}, `
            + `${rightStatTitle}: ${[rightStatValue, rightStatDescription].filter(Boolean).join(' ')}`}
        </VisuallyHidden>
    </Container>
);

IGM11SecStat.displayName = 'IGM11SecStat';

IGM11SecStat.propTypes = {
    color: colorType,
    leftStatValue: propTypes.number,
    leftStatDescription: propTypes.node,
    leftStatTitle: propTypes.node,
    rightStatValue: propTypes.number,
    rightStatDescription: propTypes.node,
    rightStatTitle: propTypes.node,
    totalStatValue: propTypes.number,
    totalStatTitle: propTypes.node,
    totalStatSubtitle: propTypes.node,
};

export default IGM11SecStat;
