import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import styled from 'styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {blanc} from '../../../../common/palette';
import GA21CaGrd from '../../../atoms/ga/21-ca-grad';
import GA92BrandLogo, {Branding} from '../../../atoms/ga/92-brand-logo';
import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';

const Z_INDEX_POSTER = 0;
const Z_INDEX_ABOVE_POSTER = 1;

const OuterContainer = styled.section`
    position: relative;
    z-index: ${Z_INDEX_ABOVE_POSTER};
    width: 100%;
`;

// We need to show the background images beneath the main content
const PosterZIndexContainer = styled.div`
    position: relative;
    z-index: ${Z_INDEX_POSTER};
    width: 100%;
`;

const StyledForeground = styled.div`
    box-sizing: border-box;
    display: flex;
    position: absolute;
    bottom: 0;
    left: 50%;
    flex-direction: column;
    align-items: center;
    transform: translateX(-50%);
    padding: 21px 21px 7px;
    width: 100%;
    max-width: 558px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding-bottom: 21px
    `}
`;

const MainHeading = styled.h1`
    margin: 14px 0;
    text-align: center;
    color: ${blanc};
    font: var(--nucleus-header-4);
`;

const StyledGA21CaGrd = styled(GA21CaGrd)`
    position: absolute;
    bottom: 0;
    pointer-events: none;
`;

const OR08MarBlk = ({
    children,
    className,
    poster,
    title = 'Welcome home, sports lovers',
    shouldApplyCarouselGradient,
}) => (
    <OuterContainer className={classnames('OR08MarBlk', className)}>
        <PosterZIndexContainer>
            {poster}
        </PosterZIndexContainer>

        <Branding>
            <GA92BrandLogo />
        </Branding>

        {!!shouldApplyCarouselGradient && <StyledGA21CaGrd />}

        <StyledForeground>
            <MainHeading>{title}</MainHeading>
            {children}
        </StyledForeground>
    </OuterContainer>
);

OR08MarBlk.propTypes = {
    /** Foreground items */
    children: propTypes.node,
    /** Additional class name(s) */
    className: classNameType,
    /** Poster (e.g. GM07SlidingPictures, GA06PostImg, or img element) */
    poster: propTypes.node,
    /** Call to action text */
    title: propTypes.string,
    /** Has carousel gradient? */
    shouldApplyCarouselGradient: propTypes.bool,
};

OR08MarBlk.displayName = 'OR08MarBlk';

export default OR08MarBlk;
