import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {SCREEN_1280_DESKTOP, SCREEN_1920_DESKTOP, SCREEN_768_TABLET} from '../../../../common/screen-sizes';
import {blueCharcoal, white, black} from '../../../../common/palette';
import IC42Error from '../../../atoms/ic/42-error';

const StyledChildrenWrapper = styled.div`
    padding-top: 2px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding-top: 3px;
    `}
`;

const Tile = styled.div.attrs({
    role: 'alert',
})`
    display: grid;
    position: relative;
    grid-template-columns: 30px 1fr;
    grid-column-gap: 14px;
    align-items: center;
    box-shadow: 0 1px 1px 0 ${rgba(black, 0.3)};
    background-color: ${blueCharcoal};
    padding: 14px;
    color: ${rgba(white, 0.78)};
    font: var(--infinity-body-copy-4-light);

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        color: ${white};
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        color: ${white};
        font: var(--infinity-body-copy-3-light);
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        color: ${white};
        font: var(--infinity-body-copy-1-light);
    `}
`;

const TM24AlertTile = ({
    children,
    className,
    icon = <IC42Error />,
    ...htmlAttributes
}) => (
    <Tile
        {...htmlAttributes}
        className={classnames('TM24AlertTile', className)}
    >
        {icon}

        <StyledChildrenWrapper>
            {children}
        </StyledChildrenWrapper>
    </Tile>
);

TM24AlertTile.propTypes = {
    /** Content for the alert panel */
    children: propTypes.node.isRequired,
    /** Additional class(es) */
    className: classNameType,
    /** Icon */
    icon: propTypes.node,
};

TM24AlertTile.displayName = 'TM24AlertTile';

export default TM24AlertTile;
