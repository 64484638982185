import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import classnames from 'classnames';
import noop from 'lodash/noop';
import debounce from 'lodash/debounce';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import GM17ScrollIndicator from '../../../molecules/gm/17-scroll-indicator';
import BA55ImgGallery from '../../../atoms/ba/55-img-gallery';
import SrcsetSource from '../../../../common/srcset-source';
import {white} from '../../../../common/palette';
import {SMALLEST_SUPPORTED_WIDTH, SCREEN_768_TABLET} from '../../../../common/screen-sizes';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
`;

const ImageContainer = styled.div`
    display: flex;
    flex-flow: row nowrap;
    height: 100%;
    overflow: scroll;
    // used to force x-scroll to start position
    scroll-snap-type: ${({isScrollEnabled}) => isScrollEnabled ? 'x mandatory' : 'none'};
    scroll-snap-align: start;

    &::-webkit-scrollbar {
        display: none;
    }
`;

const GalleryContainer = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    border-radius: 10.5px;
    background-color: ${white};
    height: 249px;
    overflow: hidden;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        height: 433px;
    `}
`;

const Image = styled.img`
    scroll-snap-align: center;
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

const Picture = styled.picture`
    display: flex;
    flex: 0 0 100%;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

const LeftIndicator = styled(BA55ImgGallery)`
    position: absolute;
    left: 14px;
`;

const RightIndicator = styled(BA55ImgGallery)`
    position: absolute;
    right: 14px;
`;

/**
 * Image Gallery
 *
 * @see {@link https://zpl.io/p1YB06J}
 */
const OR149ImgGallery = ({
    className,
    images = [],
    index = 0,
    onChange = noop,
    srcsetSizes,
    ...htmlAttributes
}) => {
    const scrollContainerRef = useRef(null);
    const imageRefs = useRef([]);
    const [isScrollEnabled, setIsScrollEnabled] = useState(false);

    useEffect(function scrollImageIntoView() {
        if (imageRefs.current.length === 0) {
            return;
        }
        if (isScrollEnabled) {
            imageRefs.current[index]?.scrollIntoView?.({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center',
            });
        }
    }, [index, images, imageRefs, isScrollEnabled]);

    const handleChange = debounce(onChange, 50);

    // Updates the image index when user scrolls
    useEffect(function initScrollListener() {
        if (imageRefs.current.length <= 1) { // no x-scroll required for single image
            return;
        }

        const scrollRef = scrollContainerRef?.current;

        const scrollHandler = () => {
            const {scrollLeft} = scrollContainerRef.current; // current scroll position

            const childWidth = imageRefs.current[index]?.offsetWidth; // width of current image

            if (childWidth) {
                const newIndex = Math.round(scrollLeft / childWidth); // new index based on scroll position

                handleChange(newIndex);
            }
        };

        scrollRef?.addEventListener('scroll', scrollHandler);

        return () => {
            scrollRef?.removeEventListener('scroll', scrollHandler);
        };
    }, [handleChange, imageRefs, index, onChange]);

    // this is necessary to force the scroll position to the start when images change
    useEffect(function resetScroll() {
        setIsScrollEnabled(false);
        onChange(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [images?.reduce((acc, img) => acc.concat(img?.src), '')]);

    const hasMultipleImages = images.length > 1;

    return (
        <Wrapper className={classnames('OR149ImgGallery', className)} {...htmlAttributes}>
            <GalleryContainer>
                {hasMultipleImages && (
                    <LeftIndicator
                        onClick={() => onChange((index - 1 + images.length) % images.length)}
                        direction="left"
                    />
                )}
                <ImageContainer isScrollEnabled={isScrollEnabled} ref={scrollContainerRef}>
                    {images?.map((image, imageIndex) => (
                        <Picture
                            key={`${image.src}_${imageIndex}`}
                            ref={(ref) => (imageRefs.current[imageIndex] = ref)}
                        >
                            <SrcsetSource
                                key="srcset"
                                minWidthPx={SMALLEST_SUPPORTED_WIDTH}
                                sizes={srcsetSizes}
                                srcsetOptions={image.srcSet}
                            />
                            <Image
                                onLoad={() => setIsScrollEnabled(true)}
                                onError={() => setIsScrollEnabled(true)}
                                src={image.src}
                                alt={image.alt}
                            />
                        </Picture>
                    ))}
                </ImageContainer>
                {hasMultipleImages && (
                    <RightIndicator
                        onClick={() => onChange((index + 1 + images.length) % images.length)}
                        direction="right"
                    />
                )}
            </GalleryContainer>
            {hasMultipleImages && (
                <GM17ScrollIndicator
                    focusIndex={index}
                    length={images.length}
                    onClick={(index) => onChange(index)}
                />
            )}
        </Wrapper>
    );
};

OR149ImgGallery.propTypes = {
    /** Additional class name(s) */
    className: propTypes.string,
    /** list of images to switch between */
    images: propTypes.arrayOf(propTypes.shape({
        src: propTypes.string.isRequired,
        alt: propTypes.string,
        srcSet: propTypes.object,
    })),
    /** index of the active image */
    index: propTypes.number.isRequired,
    /** callback function to be called when the active image changes */
    onChange: propTypes.func,
    /** Srcset sizes */
    srcsetSizes: propTypes.string,
};

OR149ImgGallery.displayName = 'OR149ImgGallery';

export default OR149ImgGallery;
