import React from 'react';
import propTypes from 'prop-types';

import {srcsetOptionsType} from '@fsa-streamotion/custom-prop-types';

import stringifySrcsetOptions, {sizesAtViewportsToSizes} from './stringify-srcset-options';

const SrcsetImg = ({
    alt,
    sizes,
    sizesDefinition,
    srcsetOptions = {},

    ...otherHtmlAttributes
}) => (
    <img
        {...otherHtmlAttributes}
        alt={alt}
        sizes={sizes || sizesAtViewportsToSizes(sizesDefinition)}
        src={Object.values(srcsetOptions)[0] || ''} // set the src to be the first srcset option if exists
        srcSet={stringifySrcsetOptions(srcsetOptions)}
    />
);

SrcsetImg.displayName = 'SrcsetImg';

SrcsetImg.propTypes = {
    alt: propTypes.string.isRequired,
    sizes: propTypes.string,
    sizesDefinition: propTypes.objectOf(propTypes.number),
    srcsetOptions: srcsetOptionsType,
};

export default SrcsetImg;
