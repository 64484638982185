import React from 'react';
import Ic from '..';

const IC220AudioSettings = (args) => (
    <Ic {...args}>
        <path fillRule="nonzero" d="M73.796 47.493h8.462v35.24h-8.462zm-47.132-31.91h-8.461V34.26h-4.415v11.192H31.77V34.26h-5.106V15.583zm.875 25.671h-9.52v-2.798h9.52v2.798zm-9.337 9.626h8.462v31.854h-8.462zm35.47-35.297H45.21v45.2h-4.767v11.193h17.981V60.784h-4.752v-45.2zm.521 52.196h-9.52V64.98h9.52v2.798zm-8.983 9.107h8.462v5.848H45.21zm37.048-45.495V15.583h-8.461v15.808H69.03v11.192h17.98V31.391h-4.753zm.522 6.995h-9.52v-2.798h9.52v2.798z" />
    </Ic>
);

IC220AudioSettings.displayName = 'IC220AudioSettings';

export default IC220AudioSettings;
