import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import classnames from 'classnames';
import {A, Button} from 'normalized-styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {white} from '../../../../common/palette';
import {kayoGreen, silverLining} from '../../../../common/deprecated-mui-palette';
import {transition} from '../../../../common/animations';
import {SCREEN_2560_DESKTOP} from '../../../../common/screen-sizes';
import GA07TileImg from '../../../atoms/ga/07-tile-img';
import standardTileSize, {TILE_SIZES_ATTRIBUTE} from '../../../../common/standard-tile-size';

const baseStyle = css`
    ${standardTileSize};
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: unset;
    transition: ${transition('border-color')};
    border: 3px solid transparent;
    border-radius: 3px;
    background-color: transparent;
    padding: 5px;
    width: 128px;
    text-align: left;

    &:focus,
    &:active {
        outline: 0;
        border-color: ${kayoGreen};
    }

    @media (hover: hover) {
        &:hover {
            outline: 0;
            border-color: ${kayoGreen};
        }
    }
`;

const TileButton = styled(Button)`
    ${baseStyle}
    appearance: none;
`;

const TileLink = styled(A)`
    ${baseStyle}
    display: block;
    text-decoration: none;
`;

const InfoWrapper = styled.span`
    display: flex;
    flex-flow: column;
    padding: 10px;

    ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
        padding: 15px;
    `}
`;

const Header = styled.h3`
    margin: 20px 0 10px;
    color: ${white};
    font: var(--ionic-header-4-bold);
`;

const Text = styled.span`
    margin: 0 0 15px;
    color: ${silverLining};
    font: var(--ionic-body-copy-1);
`;

const TM12EpiTile = React.forwardRef(({
    description,
    href,
    isBeingLazy,
    isStreaming,
    onClick,
    runtime,
    showName,
    srcsetOptions,
    title,
    className,
    ...htmlAttributes
}, ref) => {
    const EpTile = href ? TileLink : TileButton;

    return (
        <EpTile
            {...htmlAttributes}
            className={classnames('TM12EpiTile', className)}
            ref={ref}
            onClick={onClick}
            href={href}
        >
            <GA07TileImg
                caption={showName}
                srcsetOptions={srcsetOptions}
                sizes={TILE_SIZES_ATTRIBUTE}
                isStreaming={isStreaming}
                isBeingLazy={isBeingLazy}
            />
            <InfoWrapper>
                <Header>{title}</Header>
                <Text>{runtime}</Text>
                <Text>{description}</Text>
            </InfoWrapper>
        </EpTile>
    );
});

TM12EpiTile.propTypes = {
    /** CSS class name(s) */
    className: classNameType,
    /** Episode description (synopsis) e.g 'Bob goes to Hollywood and trouble ensues' */
    description: propTypes.string,
    /** If an href is provided, change rendering to use link */
    href: propTypes.string,
    /** Srcset for episode image */
    srcsetOptions: propTypes.object,
    /** Use "is live" treatment on the badge */
    isStreaming: propTypes.bool,
    /** Callback to fire on element click */
    onClick: propTypes.func,
    /** Episode duration/length e.g 55 mins */
    runtime: propTypes.string,
    /** Show name e.g 'on the couch' */
    showName: propTypes.string,
    /** Episode title e.g 'Winter is coming' */
    title: propTypes.string,
    /** Is this component in a lazy state (e.g. yet to be lazy loaded) */
    isBeingLazy: propTypes.bool,
};

TM12EpiTile.displayName = 'TM12EpiTile';

export default TM12EpiTile;
