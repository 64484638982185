import React, {useEffect, useRef} from 'react';
import propTypes from 'prop-types';
import noop from 'lodash/noop';

import TargetAemWrapperWithLoading from '../wrappers/target-aem-wrapper-with-loading';

import brandPropType from '../../../common/brand-proptype';
import aemModuleType from '../../custom-proptypes/aem-module-type';
import useHtml from '../utils/use-html';

/*
 * AEM Module Type: `aem`
 * - HTML fragment from AEM
 */
const Aem = ({
    // aem
    module,

    // from us
    moduleKey,
    onRender = noop,
    isLoading,
    brand,
    commonWidgetSettings,

    // for adobe target
    adobeTargetParams,
}) => {
    const {clientSideFeatureFlags: {useAdobeTarget}} = commonWidgetSettings;
    const {alignment, id: moduleId, url: {_publishUrl: publishUrl}} = module;
    const onReadyForTargetRef = useRef();

    const html = useHtml(publishUrl);

    useEffect(() => {
        onRender();
    }); // We want to run this on every render so the dependency array is intentionally missing

    useEffect(function invokeOnReadyForTargetWhenLoaded() {
        if (!isLoading) {
            try {
                onReadyForTargetRef.current?.();
            } catch (e) {
                console.error('Error while running onReadyForTarget - ignoring');
            }
        }
    }, [isLoading]);

    const Component = (
        <div
            style={{width: '100%'}}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
                __html: html, // this is fine because the html fragments are trusted
            }}
        />
    );

    if (useAdobeTarget) {
        return (
            <TargetAemWrapperWithLoading
                brand={brand}
                alignment={alignment}
                data-module={moduleKey}
                moduleId={moduleId}
                adobeTargetParams={adobeTargetParams}
                render={({onReadyForTarget}) => {
                    onReadyForTargetRef.current = onReadyForTarget;

                    return Component;
                }}
            />
        );
    } else {
        // Note: we won't have loading skeleton or spinner in this case but is ok as the feature flag
        // is temporary and only for development purposes
        return Component;
    }
};

Aem.displayName = 'Aem';

Aem.propTypes = {
    /** Function to execute on each render */
    onRender: propTypes.func,
    /** Module key info for reference */
    moduleKey: propTypes.string,
    /** AEM Module object */
    module: aemModuleType,
    /** Whether we're in a loading state */
    isLoading: propTypes.bool,
    /** Branding to use for the loading spinner */
    brand: brandPropType,
    /** Globalish settings object */
    commonWidgetSettings: propTypes.shape({ // @TODO: complete this shape later
        /** The brand, Flash | Kayo | Binge | Lifestyle */
        brand: brandPropType,
        /** The auth0 user object */
        user: propTypes.object,
        /** Handler helper for our SPA routing */
        newRouteHandler: propTypes.func,
        /** Object containing all the clientside feature flags */
        clientSideFeatureFlags: propTypes.object,
    }),
    /** Adobe Target Params */
    adobeTargetParams: propTypes.object,
};

export default Aem;
