import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {fadeInOut} from '../../../../../common/animations';
import CommonTransition from '../../../../../common/common-transition';
import View from './view';

const Container = styled.section`
    display: flex;
    justify-content: center;
    width: 100%;
    pointer-events: none;
    ${fadeInOut}
`;

const VPTM03ThumbnailCarousel = ({
    urls,
    activeThumbnailUrlIndex,
    activeThumbnailContent,
    position,
    isVisible = true,
}) => (
    <CommonTransition in={isVisible}>
        <Container>
            <View
                {...{
                    urls,
                    activeThumbnailUrlIndex,
                    activeThumbnailContent,
                    position,
                }}
            />
        </Container>
    </CommonTransition>
);

VPTM03ThumbnailCarousel.propTypes = {
    urls: propTypes.any,
    activeThumbnailUrlIndex: propTypes.number,
    activeThumbnailContent: propTypes.node,
    position: propTypes.number,
    isVisible: propTypes.bool,
};

VPTM03ThumbnailCarousel.displayName = 'VPTM03ThumbnailCarousel';

export default VPTM03ThumbnailCarousel;
