import React from 'react';
import propTypes from 'prop-types';

import {srcsetOptionsType, classNameType} from '@fsa-streamotion/custom-prop-types';

import Tile from '../tile';
import Logo from '../logo';
import Name from '../name';

import CricketScore from './score';

const generateScore = ({
    wickets = null, // eslint-disable-line react/prop-types
    runs = null, // eslint-disable-line react/prop-types
    is_all_out: isAllOut = null, // eslint-disable-line react/prop-types
    is_declared: isDeclared = null, // eslint-disable-line react/prop-types
} = {}) => {
    if (wickets === null || runs === null || isAllOut === null) {
        // not enough data to create a score
        return '';
    }

    return (
        <CricketScore
            wickets={wickets}
            runs={runs}
            isAllOut={isAllOut}
            isDeclared={isDeclared}
        />
    );
};

generateScore.propTypes = {
    wickets: propTypes.number,
    runs: propTypes.number,
    is_all_out: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    is_declared: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
};

const CricketTile = ({
    className,
    hasNoSpoilers,
    logoSrcsetOptions,
    matchState,
    teamData = {},
    tileAlign,
}) => (
    <Tile
        className={className}
        hasNoSpoilers={hasNoSpoilers}
        logo={<Logo logoSrcsetOptions={logoSrcsetOptions} name={teamData.name} />}
        matchState={matchState}
        name={<Name code={teamData.code} name={teamData.name} />}
        score={hasNoSpoilers
            ? undefined
            : (
                <React.Fragment>
                    {generateScore(teamData.first_innings)}
                    {generateScore(teamData.second_innings)}
                </React.Fragment>
            )
        }
        tileAlign={tileAlign}
    />
);

CricketTile.displayName = 'CricketTile';

CricketTile.propTypes = {
    /** className */
    className: classNameType,
    /** Don't show any spoilers, i.e match result, score */
    hasNoSpoilers: propTypes.bool,
    /** Team logo */
    logoSrcsetOptions: srcsetOptionsType,
    /** State for the match, before starting (pre,) during (live,) and finished (post) */
    matchState: propTypes.oneOf(['pre', 'live', 'post']),
    /** Name, score info, code, etc. from statsapi's scoreboard.json */
    teamData: propTypes.shape({
        code: propTypes.string,
        first_innings: propTypes.shape({
            runs: propTypes.number,
            wickets: propTypes.number,
            is_all_out: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
            is_declared: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
        }),
        name: propTypes.string,
        second_innings: propTypes.shape({
            runs: propTypes.number,
            wickets: propTypes.number,
            is_all_out: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
            is_declared: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
        }),
    }),
    /** Align to the right for a left one, align to the left for a right one */
    tileAlign: propTypes.oneOf(['left', 'right']),
};

export default CricketTile;
