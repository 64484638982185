import React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {ironStone, black} from '../../../../common/palette';
import GA72HudBg from '../../../atoms/ga/72-hud-bg';

const HudContentContainer = styled.section.attrs({
    role: 'tabpanel',
})`
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    /* This scrollbar-* property is for Firefox 64+ */
    scrollbar-color: ${ironStone} ${rgba(black, 0.8)};

    ::-webkit-scrollbar-track {
        background-color: ${rgba(black, 0.8)};
    }

    ::-webkit-scrollbar {
        background-color: ${rgba(black, 0.8)};
        width: 4px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: ${ironStone};
    }
`;

const IGM01HudContent = ({children, className, ...htmlAttributes}) => (
    <HudContentContainer
        {...htmlAttributes}
        className={classnames('IGM01HudContent', className)}
    >
        <GA72HudBg>
            {children}
        </GA72HudBg>
    </HudContentContainer>
);

IGM01HudContent.displayName = 'IGM01HudContent';

IGM01HudContent.propTypes = {
    /** Child components */
    children: propTypes.node,
    /** Additional Class(es) */
    className: classNameType,
};

export default IGM01HudContent;
