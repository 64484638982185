import React from 'react';
import propTypes from 'prop-types';
import {rgba} from 'polished';
import styled from 'styled-components';

import {stylesWhen, mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {srcsetOptionsType} from '@fsa-streamotion/custom-prop-types';

import LazyImageSwitcher from '../../../../common/lazy-image-switcher';
import {transition} from '../../../../common/animations';
import ResponsiveSizer from '../../../../common/responsive-sizer';
import {fontFamily} from '../../../../common/typography';
import {white} from '../../../../common/palette';
import {black, red} from '../../../../common/deprecated-mui-palette';
import SrcsetImg from '../../../../common/srcset-img';
import {SCREEN_1920_DESKTOP, SCREEN_2560_DESKTOP} from '../../../../common/screen-sizes';
import {TILE_SIZES_ATTRIBUTE} from '../../../../common/standard-tile-size';

const FixtureTile = styled.span`
    box-sizing: border-box;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    transition: ${transition('transform')};
    /* transition delay here prevents scroll jank when fixed cursor triggers scale transformations on scroll */
    transition-delay: 100ms;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 0 30px 0 ${black};
    background: transparent;
    padding: 0 10%;
    height: 100%;
    text-align: center;
    text-transform: uppercase;
    text-shadow: 1px 1px 2px ${rgba(black, 0.75)};
    color: ${white};

    ${stylesWhen('isActive')`
        transform: scale(1.1);
    `}

    &:focus,
    &:active {
        transform: scale(1.1);
    }

    @media (hover: hover) { /* trying to prevent touch devices emulating hover behaviour on long press */
        &:hover {
            transform: scale(1.1);
        }
    }
`;

const Badge = styled.em`
    box-sizing: border-box;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    border-top-right-radius: 4px;
    background-color: ${({isStreaming}) => isStreaming ? red : black};
    padding: 0 7px;
    max-width: 100%;
    overflow: hidden;
    text-shadow: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    font: 600 13px/1.85 ${fontFamily};

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        font-size: 15px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
        font-size: 24px;
    `}
`;

const Heading = styled.h3`
    display: block;
    position: relative;
    margin: 2px 0 4px;
    max-height: 2em;
    overflow: hidden;
    font: 600 22px/1.05 ${fontFamily};

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        font-size: 30px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
        font-size: 42px;
    `}

    @supports (-webkit-line-clamp: 2) { /* Where supported, lets add nice ellipsis on overflow https://caniuse.com/#search=line-clamp */
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        /* stylelint-disable-next-line value-no-vendor-prefix */
        display: -webkit-box;
    }
`;

const StyledLazyImageSwitcher = styled(LazyImageSwitcher)`
    position: absolute;
    top: 0;
    left: 0;
`;

const StyledSrcsetImg = styled(SrcsetImg)`
    border-radius: 4px;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const TextOverImage = styled.span`
    position: relative;
    max-height: 2em;
    overflow: hidden;
    font: 300 14px/1.1 ${fontFamily};

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        font-size: 25px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
        font-size: 34px;
    `}
`;

const GA90FixTil = ({
    badge = '',
    footer,
    header,
    isActive,
    isBeingLazy,
    isStreaming,
    srcsetOptions,
    title,
    ...htmlAttributes
}) => (
    <ResponsiveSizer width={16} height={9} isOverflowVisible={true}>
        <FixtureTile isActive={isActive} {...htmlAttributes}>
            <StyledLazyImageSwitcher isBeingLazy={isBeingLazy}>
                <StyledSrcsetImg alt="" srcsetOptions={srcsetOptions} sizes={TILE_SIZES_ATTRIBUTE} />
            </StyledLazyImageSwitcher>
            {!!header && <TextOverImage>{header}</TextOverImage>}
            {!!title && <Heading>{title}</Heading>}
            {!!footer && <TextOverImage>{footer}</TextOverImage>}

            {!!badge && ( // placed last so it appears over any overlapping content
                <Badge isStreaming={isStreaming}>{badge}</Badge>
            )}
        </FixtureTile>
    </ResponsiveSizer>
);

GA90FixTil.displayName = 'GA90FixTil';

GA90FixTil.propTypes = {
    /** Whether or not to show image */
    isBeingLazy: propTypes.bool,
    /** Text that goes above the lead text */
    header: propTypes.string,
    /** Leading, main text */
    title: propTypes.string,
    /** Text that goes below the lead text */
    footer: propTypes.string,
    /** Bug label (live, replay etc.) */
    badge: propTypes.string,
    /** Force isActive state */
    isActive: propTypes.bool,
    /** Use "is live" treatment on the badge */
    isStreaming: propTypes.bool,
    /** Srcset for background image */
    srcsetOptions: srcsetOptionsType,
};

export default GA90FixTil;
