import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {white} from '../../../../common/palette';
import {SCREEN_PRETTY_LG_DESKTOP} from '../../../../common/screen-sizes';

const ITEM_GAPS_PX = {
    default: 14,
    [SCREEN_PRETTY_LG_DESKTOP]: 26,
};

const CarouselTitle = styled.h3`
    margin: 0;
    color: ${white};
    font: var(--mui-header-7-bold);
`;

const ItemList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    margin: 7px -${ITEM_GAPS_PX.default}px -${ITEM_GAPS_PX.default}px 0;
    padding-left: 0;
    list-style-type: none;

    ${mediaQuery({minWidthPx: SCREEN_PRETTY_LG_DESKTOP})`
        margin-right: -${ITEM_GAPS_PX[SCREEN_PRETTY_LG_DESKTOP]}px;
        margin-bottom: -${ITEM_GAPS_PX[SCREEN_PRETTY_LG_DESKTOP]}px;
    `}
`;

const Item = styled.li`
    flex: 0 0 0%;
    margin: 0 ${ITEM_GAPS_PX.default}px ${ITEM_GAPS_PX.default}px 0;
    padding: 0;

    ${mediaQuery({minWidthPx: SCREEN_PRETTY_LG_DESKTOP})`
        margin-right: ${ITEM_GAPS_PX[SCREEN_PRETTY_LG_DESKTOP]}px;
        margin-bottom: ${ITEM_GAPS_PX[SCREEN_PRETTY_LG_DESKTOP]}px;
    `}
`;

const CAM09Grid = ({
    className,
    label,
    children,
    ...htmlAttributes
}) => (
    <section
        {...htmlAttributes}
        className={classnames('CAM09Grid', className)}
    >
        {!!label && <CarouselTitle>{label}</CarouselTitle>}
        <ItemList>
            {React.Children.map(children, (child) => (
                <Item key={child.key}>{child}</Item>
            ))}
        </ItemList>
    </section>
);

CAM09Grid.displayName = 'CAM09Grid';

CAM09Grid.propTypes = {
    /** Foreground items */
    children: propTypes.node,
    /** className */
    className: classNameType,
    /** Carousel Label */
    label: propTypes.string,
};

export default CAM09Grid;
