import React from 'react';
import Ic from '..';

const IC142SwipeLR = (args) => (
    <Ic {...args}>
        <path d="M35.568 21.464H14.036l6.51-6.496L17.578 12 6 23.578l11.578 11.564 2.968-2.968-6.51-6.496h21.532zm46.424-9.366l-2.968 2.968 6.412 6.398H62.7v4.214h22.932l-6.608 6.594 2.968 2.968L93.57 23.662z" />
        <path d="M87.2 48.61a6.903 6.903 0 00-6.888-6.888h-.476a6.764 6.764 0 00-3.304.882 7.14 7.14 0 00-6.524-4.284 7.002 7.002 0 00-3.724 1.078 7.139 7.139 0 00-6.412-3.92h-.154a8.077 8.077 0 00-2.66.658v-7c.14-5.236.266-9.8-2.268-12.362a6.864 6.864 0 00-5.026-1.974 8.4 8.4 0 00-6.216 2.366A9.558 9.558 0 0041 23.564v26.6a14.91 14.91 0 00-4.452 5.684c-3.64 8.008-.14 18.466 1.512 21.812l.14.14c1.75 3.57 5.404 11.018 25.704 11.018 16.408 0 21.266-14.896 21.644-23.464a6.847 6.847 0 001.652-4.438V48.61zm-7.364-2.688h.476A2.687 2.687 0 0183 48.61v12.306a2.687 2.687 0 01-2.688 2.688h-.476a2.702 2.702 0 01-2.702-2.688V48.61c.004-.72.294-1.407.806-1.912a2.707 2.707 0 011.924-.776h-.028zm-19.824-6.244a2.865 2.865 0 012.038.836c.542.538.846 1.27.846 2.034v1.19a43.847 43.847 0 00-5.866.896v-2.59a3.01 3.01 0 012.982-2.366zM45.2 23.55a4.317 4.317 0 011.26-3.3A4.316 4.316 0 0149.764 19a2.66 2.66 0 012.114.644c1.302 1.4 1.19 5.222 1.078 9.324V45.6c-1.4.364-3.038.77-4.816 1.246-.953.257-1.888.575-2.8.952L45.2 23.55zm18.69 61.11c-17.682 0-20.454-5.6-21.938-8.666l-.084-.182c-1.316-2.674-4.396-11.732-1.4-18.2 1.512-3.332 4.354-5.516 8.694-6.678 8.89-2.394 12.6-2.898 14.14-2.968.168 1.876 0 4.396-1.134 5.068-3.178 1.848-14.56 6.692-14.658 6.734l1.68 3.85c.42-.182 11.69-4.984 15.092-7 4.2-2.436 3.29-8.918 3.01-10.472a2.7 2.7 0 00-.126-.56v-.14a2.926 2.926 0 115.852 0v20.888a2.926 2.926 0 11-5.852 0v-4.69l-4.2 1.932v4.788a2.884 2.884 0 01-2.884 2.884H59.9a2.87 2.87 0 01-2.8-2.884v-2.1l-4.2 1.932v.168c0 3.88 3.12 7.038 7 7.084h.112a7 7 0 005.95-3.248 7.084 7.084 0 0011.116-4.97c.88.395 1.835.6 2.8.602h1.19c-.868 5.586-4.2 16.828-17.178 16.828z" />
    </Ic>
);

IC142SwipeLR.displayName = 'IC142SwipeLR';

export default IC142SwipeLR;
