import React from 'react';
import Ic from '..';

const IC232Help = (args) => (
    <Ic {...args}>
        <defs>
            <linearGradient x1="7.507%" y1="84.549%" x2="43.716%" y2="97.963%" id="ic232-help__linear-gradient">
                <stop stopColor="currentColor" stopOpacity="0" offset="0%" />
                <stop stopColor="currentColor" offset="100%" />
            </linearGradient>
        </defs>
        <g fill="none" fillRule="nonzero">
            <path
                d="M41.845 52.997a3 3 0 1 1 3.155-3 2.987 2.987 0 0 1-3.155 3ZM53.845 52.997a3 3 0 1 1 3.155-3 2.987 2.987 0 0 1-3.155 3ZM65.845 52.997a3 3 0 1 1 3.155-3 2.987 2.987 0 0 1-3.155 3Z"
                fill="currentColor"
            />
            <path
                d="M17.903 47.206c-.413 4.727.121 9.41 1.543 13.835l.006.027.008.096v.151l-.01.204-.021.253-.037.3-.024.167-.066.392-.078.39-.143.628-.128.502-.21.76-.172.577-.184.59-.203.62-.22.65-.238.682-.393 1.081-.43 1.142-.309.793-.327.827-.345.855-.363.884-.579 1.382-.406.952-2.22 5.139 5.015-.778 1.048-.156 1.013-.144 1.452-.192.947-.114 1.335-.146.872-.084.829-.071 1.168-.083.765-.041.718-.03.996-.02h.744l.195.004.611.018.524.026.547.041c1.48.129 2.505.4 3.005.717a35.074 35.074 0 0 0 15.78 5.326c19.375 1.696 36.457-12.637 38.152-32.013 1.695-19.377-12.638-36.458-32.014-38.153C36.68 13.497 19.598 27.83 17.903 47.206ZM55.62 20.173c16.625 1.454 28.923 16.11 27.469 32.736-1.455 16.625-16.111 28.923-32.736 27.469a30.074 30.074 0 0 1-13.537-4.568c-1.296-.823-3.043-1.284-5.282-1.479l-.609-.045-.468-.026-.225-.01-.662-.019-.378-.005h-.702l-.394.005-.744.019-.791.031-.818.045-.434.028-.842.062-.885.075-1.155.113-1.176.13-.788.095.447-1.1.34-.858.322-.832.45-1.193.414-1.138.256-.733.237-.701.221-.677.204-.651.25-.849.185-.674.171-.678.045-.191.113-.51.102-.516.085-.496c.218-1.424.212-2.555-.099-3.52a30.114 30.114 0 0 1-1.322-11.87c1.455-16.626 16.111-28.924 32.736-27.47Z"
                fill="url(#ic232-help__linear-gradient)"
            />
            <path
                d="M17.903 47.206c1.695-19.376 18.777-33.71 38.153-32.014 19.376 1.695 33.71 18.776 32.014 38.153-1.678 19.182-18.437 33.421-37.572 32.06l-.58-.047.435-4.98c16.625 1.454 31.281-10.844 32.736-27.47 1.454-16.624-10.844-31.28-27.469-32.735C38.995 18.718 24.34 31.016 22.884 47.64a30.13 30.13 0 0 0 1.136 11.27l.186.6c.359 1.115.262 2.212-.348 3.882l-.179.47-.205.502-.112.263-.244.552-.416.901-.31.652-.652 1.333-2.254 4.528c-.252.51-.494 1.005-.729 1.487l-.68 1.415c-.438.925-.853 1.818-1.256 2.713l-.496 1.115-.193.441-4.585-1.993.2-.46.204-.46a148.689 148.689 0 0 1 1.606-3.493l.699-1.454.366-.752 2.94-5.917.436-.906.37-.794.213-.473.186-.431.161-.394.138-.359c.022-.057.042-.113.061-.168.115-.324.192-.587.233-.781l.019-.104-.121-.39a35.1 35.1 0 0 1-1.405-12.586l.05-.644Z"
                fill="currentColor"
            />
        </g>
    </Ic>
);

IC232Help.displayName = 'IC232Help';

export default IC232Help;
