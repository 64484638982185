import React from 'react';
import propTypes from 'prop-types';

import Ic from '..';

const IC333Design = ({
    color = '#dfdfdf', // @TODO: this color code does not exist in palette; ask design to add it or perhaps they meant blanc?
    opacity = 0.2,
    ...otherProps
}) => (
    <Ic {...otherProps}>
        <g>
            <g transform="translate(-82 -10)">
                <g transform="translate(79.048 10)">
                    <g transform="translate(3)">
                        <g transform="translate(3 4)">
                            <path
                                d="m74.1 36.4-2 2-7.5 7.6-29.2 29.2c-2.4-.9-3.9-2.3-4.6-4.4-1.6.6-5.3.6-6.9-.9-1.6-1.5-2.3-5-2.3-7.7-2.2-.9-3.6-2.1-4.3-3.7l.4-1.4V57l.1-.1.1-.1.2-.3.2-.2.5-.5 1.1-1.2 1.7-1.8 4.2-4.4 5.9-6.1 4.2-4.3 4.6-4.6 4.5-4.5 4.5-4.5 4.1-4L56 18l18.1 18.4z"
                                opacity={opacity}
                                fill={color}
                            />
                            <path d="M65.8 9c-2.2 0-3.9.5-5.5 1.6-.6.4-1.2.9-2 1.6l-.7.6-3.2 3.2-2.2 2-3.4 3.3-3.5 3.5-4.1 4.2-3.2 3.2-5.6 5.7-4.2 4.3-3.2 3.3-4.1 4.3-2.3 2.5-1 1.2-.7.8-.5.5-.2.2-.3.4-.2.2-.1.2-.2.2s0 .1-.1.1v.1l-.2.7-.6 2.3-1.3 5.2-3 12.2L8.5 84l28.2-6.5 18.4-18.4 20.4-20.4 1.4-1.4 1.5-1.6c3.5-3.7 5.1-6.1 5.1-9.3 0-2.4-.5-4.1-2.2-6.4l-.4-.5-.4-.5-.5-.6-.5-.6-.6-.6-.9-1-1-1.1-.4-.4-.8-.7c-3.8-3.9-6.1-5-10-5zm0 2.6c2.8 0 4.5.7 7.3 3.4l.6.6.3.3.7.7 1 1.1.9 1 .5.6.5.6.5.5.4.5c.3.4.6.8.9 1.1l.3.4c.9 1.3 1.2 2.4 1.2 3.8v.4c0 1.9-.9 3.7-3.1 6.2l-.6.6-.3.3-2.8 2.9-2 2-7.5 7.4-29.2 29.2L12 80.6l3.9-16.1 1.5-6 .4-1.4V57l.1-.1.1-.1.2-.3.2-.2.5-.5 1.1-1.2 1.7-1.8 4.2-4.4 5.9-6.1L36 38l4.6-4.6 4.5-4.5 4.5-4.5 4.1-4L56 18l3.6-3.4.6-.5.5-.4c.4-.3.7-.6 1-.8 1.3-1 2.5-1.3 4.1-1.3z" fill={color} />
                            <path d="m56.8 15.6 19.1 19.1-1.8 1.8L55 17.4z" fill={color} />
                            <path d="m60.8 21.3 1.8 1.9-39 39-1.8-1.9z" fill={color} />
                            <path d="m69.3 29.9 1.9 1.8-39 40-1.9-1.9z" fill={color} />
                            <path d="M17.7 56.5c.1 2.7 1.4 3.9 4.5 4h2.3l-.8 1.8c-2.1 4.7 2.2 9.3 6.6 7.1l.2-.1 2.5-1.4-.6 2.8c-.6 2.5.4 3.7 3.4 4.2h.3l-.3 2.5c-3.7-.5-5.8-2.2-6.1-4.9v-.2h-.1c-5.3 1.2-9.8-3.7-8.8-9.1l.1-.3h-.2c-3.3-.6-5.2-2.6-5.4-6v-.3l2.4-.1z" fill={color} />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </Ic>
);

IC333Design.propTypes = {
    /** Fill color */
    color: propTypes.string,
    /** Fill color opacity */
    opacity: propTypes.number,
};

IC333Design.displayName = 'IC333Design';

export default IC333Design;
