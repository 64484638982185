import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import {ink, slate} from '../../../../common/palette';

import Ic from '..';

const IC313Credit = ({
    color = ink,
    secondaryColor = slate,
    ...args
}) => (
    <Ic {...args}>
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h100v100H0z" />
            <g transform="translate(10 10)">
                <path fill={secondaryColor} fillOpacity=".35" d="M33 23h14v10H33zm0 13h14v39H33z" />
                <rect
                    width="69"
                    height="12.223"
                    x="5.5"
                    y="21.5"
                    stroke={color}
                    strokeWidth="3"
                    rx="6"
                />
                <path
                    stroke={color}
                    strokeWidth="3"
                    d="M69.39 34.5V70a6.48 6.48 0 0 1-1.903 4.596A6.48 6.48 0 0 1 62.89 76.5H18a6.48 6.48 0 0 1-4.596-1.904A6.48 6.48 0 0 1 11.5 70V34.5h57.89z"
                />
                <path fill={color} d="M30 20h3v57h-3zm17 0h3v57h-3z" />
                <path
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                    d="M40 20C45.249 5.755 50.316 1.022 55.201 5.8 60.087 10.58 55.02 15.314 40 20zm0 0C34.751 5.755 29.684 1.022 24.799 5.8 19.913 10.58 24.98 15.314 40 20z"
                />
            </g>
        </g>
    </Ic>
);

IC313Credit.propTypes = {
    /** The icon's primary color */
    color: colorType,
    /** The icon's secondary color */
    secondaryColor: colorType,
};

IC313Credit.displayName = 'IC313Credit';

export default IC313Credit;
