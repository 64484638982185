import React from 'react';
import Ic from '..';

const IC172ContextualMenu = (args) => (
    <Ic {...args}>
        <path d="M50.128 7.397c-23.157 0-41.93 18.772-41.93 41.93 0 23.157 18.773 41.928 41.93 41.928 23.156 0 41.928-18.77 41.928-41.929 0-23.157-18.772-41.929-41.928-41.929m0 5.5c20.088 0 36.43 16.342 36.43 36.43 0 20.087-16.342 36.43-36.43 36.43s-36.43-16.343-36.43-36.43c0-20.088 16.342-36.43 36.43-36.43" />
        <path fillRule="nonzero" d="M37.285 55.875h-3.102l-.527-9.128-2.908 9.128h-2.48l-2.894-9.17-.554 9.17h-3.075l1.01-13.394h3.962l2.77 8.81 2.77-8.81h3.976zm11.538 0h-8.85V42.44h8.67v2.507h-5.54v2.77h5.263v2.507h-5.263v3.102h5.72zm14.779 0h-3.186l-5.887-9.086v9.086h-3.005V42.44h3.366l5.734 9.003V42.44h2.978v13.435zm14.363-5.637a5.776 5.776 0 0 1-1.274 4.155 5.693 5.693 0 0 1-4.336 1.593 5.734 5.734 0 0 1-4.349-1.496 5.831 5.831 0 0 1-1.26-4.155V42.44h3.172v7.798a3.31 3.31 0 0 0 .526 2.23c.47.563 1.179.87 1.911.83a2.382 2.382 0 0 0 1.926-.83c.428-.66.614-1.448.526-2.23V42.44h3.158v7.798z" />
    </Ic>
);

IC172ContextualMenu.displayName = 'IC172ContextualMenu';

export default IC172ContextualMenu;
