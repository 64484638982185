import React from 'react';

import Ic from '..';

const IC115Mic = (args) => (
    <Ic {...args}>
        <path d="M49.6 13.4a9.002 9.002 0 018.974 8.988v29.75a8.99 8.99 0 01-13.577 8.079 8.99 8.99 0 01-4.385-8.079v-29.75A9.003 9.003 0 0149.6 13.4m0-8.4c-9.58.046-17.33 7.809-17.36 17.388v29.75c.03 9.58 7.78 17.342 17.36 17.388 9.596-.023 17.371-7.792 17.402-17.388v-29.75C66.972 12.792 59.196 5.023 49.6 5z" />
        <path d="M76.2 52.754H72c0 12.371-10.029 22.4-22.4 22.4-12.371 0-22.4-10.029-22.4-22.4H23c.002 13.886 10.684 25.434 24.528 26.516v10.584l-15.68 1.4v2.8h35.518v-5.88l-15.666 1.4v-10.22c13.865-1.098 24.543-12.692 24.5-26.6z" />
    </Ic>
);

IC115Mic.displayName = 'IC115Mic';

export default IC115Mic;
