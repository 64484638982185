import React from 'react';
import Ic from '..';

const IC114More = (args) => (
    <Ic {...args}>
        <g transform="translate(22.18 44.332)">
            <ellipse cx="27.896" cy="6.59" rx="6.277" ry="6.59" />
            <ellipse cx="49.516" cy="6.59" rx="6.277" ry="6.59" />
            <ellipse cx="6.277" cy="6.59" rx="6.277" ry="6.59" />
        </g>
    </Ic>
);

IC114More.displayName = 'IC114More';

export default IC114More;
