import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import classNames from 'classnames';

import {mediaQuery, stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {SCREEN_768_TABLET, SCREEN_1920_DESKTOP, SCREEN_1024_DESKTOP} from '../../../../common/screen-sizes';
import {getShortTermTypeText, splitPrice} from '../../../../common/price-term';
import {ink} from '../../../../common/palette';

const Wrapper = styled.span`
    display: inline-block;
`;

const PackageName = styled.div`
    opacity: 0.8;
    text-align: center;
    color: ${ink};
    font: var(--quicksilver-body-copy-4);
`;

const PackagePrice = styled.span`
    position: relative;
    opacity: 0.8;
    color: ${ink};
    font: var(--quicksilver-offer-price);

    ${stylesWhen('isCompact')`
        font: var(--quicksilver-body-copy-1);
    `}
`;

const SmallTextWrapper = styled.span`
    display: inline-block;
    min-width: 22px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        min-width: 30px;
    `}
`;

const PackagePriceDecimal = styled.span`
    position: absolute;
    top: 4px;
    font: var(--quicksilver-offer-period);

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        top: 8px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        top: 6px;
    `}

    ${stylesWhen('isCompact')`
        position: initial;
        font: var(--quicksilver-body-copy-1);
    `}
`;

const PackageTermType = styled.span`
    font: var(--quicksilver-offer-period);

    ${stylesWhen('isCompact')`
        font: var(--quicksilver-body-copy-4);
    `}
`;

const CurrencyPrefixWrapper = styled.span`
    display: inline-block;
    margin-top: 2px;
    vertical-align: text-top;
    font: var(--quicksilver-offer-period);

    ${stylesWhen('isCompact')`
        margin-top: -1px;
        font: var(--quicksilver-body-copy-4);

        ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
            margin-top: -2px;
        `}
    `}
`;

const PackageSecondaryText = styled.div`
    opacity: 0.3;
    margin-top: -5px;
    text-align: right;
    color: ${ink};
    font: var(--quicksilver-body-copy-4-light);

    ${stylesWhen('isCompact')`
        margin-top: -2px;
    `}
`;

// eslint-disable-next-line
/**
 * Pricing term details
 *
 * @see {@link https://zpl.io/Q0GEM34}
 */
const GM25PriceCallout = ({
    className,
    currencyDecimalPlaces = 2,
    currencyPrefix = '$',
    displayAmount,
    displayName,
    displayRegularAmount,
    isCompact,
    regularAmountCopy = 'was',
    secondaryText,
    termType,
}) => {
    const [priceIntegers, priceDecimals] = splitPrice(displayAmount, currencyDecimalPlaces, true);
    const termTypeShortText = getShortTermTypeText(termType);

    const hasPriceChange = displayAmount !== displayRegularAmount;

    return (
        <Wrapper className={classNames('GM25PriceCallout', className)}>
            {!!displayName && (
                <PackageName>
                    {displayName}
                </PackageName>
            )}

            <PackagePrice isCompact={isCompact} aria-label={`${currencyPrefix}${displayAmount} per ${termType}`}>
                <React.Fragment>
                    {!!currencyPrefix && <CurrencyPrefixWrapper isCompact={isCompact}>{currencyPrefix}</CurrencyPrefixWrapper>}
                    {priceIntegers}
                    {(!!priceDecimals || !!termTypeShortText) && (
                        <SmallTextWrapper>
                            {!!priceDecimals && (
                                <PackagePriceDecimal isCompact={isCompact}>
                                    .{priceDecimals}
                                </PackagePriceDecimal>
                            )}

                            {!!termTypeShortText && (
                                <PackageTermType isCompact={isCompact}>
                                    {termTypeShortText}
                                </PackageTermType>
                            )}
                        </SmallTextWrapper>
                    )}
                </React.Fragment>
            </PackagePrice>

            {hasPriceChange && !!displayRegularAmount && (
                <PackageSecondaryText isCompact={isCompact}>
                    <span aria-label={`${regularAmountCopy} ${currencyPrefix}${displayRegularAmount} per ${termType}`}>
                        {regularAmountCopy}&nbsp;
                        <del>
                            {currencyPrefix}{displayRegularAmount}{getShortTermTypeText(termType)}
                        </del>
                    </span>
                </PackageSecondaryText>
            )}

            {!!secondaryText && (
                <PackageSecondaryText isCompact={isCompact}>
                    {secondaryText}
                </PackageSecondaryText>
            )}
        </Wrapper>
    );
};

GM25PriceCallout.propTypes = {
    /** additional CSS classnames to be applied */
    className: classNameType,
    /** how currency decimal places show */
    currencyDecimalPlaces: propTypes.number,
    /** currency prefix */
    currencyPrefix: propTypes.string,
    /** display amount */
    displayAmount: propTypes.number,
    /** display name */
    displayName: propTypes.node,
    /** display regular amount */
    displayRegularAmount: propTypes.number,
    /** enable compact mode, show decimal, currency prefix and period in same line */
    isCompact: propTypes.bool,
    /** text to display before regular amount e.g. "was" */
    regularAmountCopy: propTypes.string,
    /** secondary text placed below the price */
    secondaryText: propTypes.node,
    /** term period */
    termType: propTypes.oneOf(['day', 'week', 'month', 'annual', 'upfront']),
};

GM25PriceCallout.displayName = 'GM25PriceCallout';

export default GM25PriceCallout;
