import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {classNameType, srcsetOptionsType, colorType} from '@fsa-streamotion/custom-prop-types';

import {SCREEN_REALLY_LG_DESKTOP} from '../../../../common/screen-sizes';
import {silverLining} from '../../../../common/palette';
import GA09PlrPic from '../../../atoms/ga/09-plr-pic';
import GA02StatBar from '../../../atoms/ga/02-stat-bar';

const Container = styled.div`
    width: 100%;
`;

const StatBlockPositioner = styled.div`
    display: flex;
`;

const TextContainer = styled.div`
    margin-left: 14px;
    padding-top: 3px;
    width: 100%;
    overflow-x: hidden;
`;

const Title = styled.h4`
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${silverLining};
    font: var(--mui-hud-tile-copy-light);
`;

const LeftRightText = styled.div`
    display: flex;
    justify-content: space-between;
`;

const PlayerName = styled.span`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${silverLining};
    font: var(--mui-hud-tile-copy-bold);
`;

const StatValue = styled.span`
    margin-left: 14px;
    color: ${silverLining};
    font: var(--mui-hud-tile-copy-bold);
`;

const TopAlignedStatBar = styled(GA02StatBar)`
    vertical-align: top;
`;

const SBM01PlrStaBlk = ({
    title,
    playerName,
    pictureSrcsetOptions,
    statValue = 0,
    statMax = 0,
    color = silverLining,
    className,
}) => (
    <Container className={classnames('SBM01PlrStaBlk', className)}>
        <StatBlockPositioner>
            {/* Intentionally leaving alt blank here, because the picture is purely illustrative as we also write the playerName below */}
            <GA09PlrPic srcsetOptions={pictureSrcsetOptions} alt="" size="44px" sizesDefinition={{[SCREEN_REALLY_LG_DESKTOP]: 44}} />
            <TextContainer>
                <Title>{title}</Title>
                <LeftRightText>
                    <PlayerName>{playerName}</PlayerName>
                    <StatValue>{statValue}</StatValue>
                </LeftRightText>
            </TextContainer>
        </StatBlockPositioner>
        <TopAlignedStatBar color={color} current={statValue} total={statMax} />
    </Container>
);

SBM01PlrStaBlk.displayName = 'SBM01PlrStaBlk';

SBM01PlrStaBlk.propTypes = {
    /** Title of the stat, e.g. Most Shots */
    title: propTypes.string,
    /** Name of the player to whom this pertains */
    playerName: propTypes.string,
    /** Srcset image sources for the player or team logo */
    pictureSrcsetOptions: srcsetOptionsType,
    /** The current value achieved by this player */
    statValue: propTypes.number,
    /** The value this player's stat is relative to */
    statMax: propTypes.number,
    /** The color of the stat bar */
    color: colorType,
    /** Optional additional class name */
    className: classNameType,
};

export default SBM01PlrStaBlk;
