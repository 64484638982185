import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import {Section} from 'normalized-styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {smoke} from '../../../../common/palette';
import {SCREEN_375_PHABLET} from '../../../../common/screen-sizes';

const ListContainer = styled(Section)`
    margin: 0 auto;
    width: 100%;
    text-align: center;
    color: ${smoke};
`;

const List = styled.dl`
    display: flex;
    justify-content: space-around;
    margin: 0;
    padding: 7px;
    list-style-type: none;

    ${mediaQuery({minWidthPx: SCREEN_375_PHABLET})`
        padding: 14px;
    `}
`;

const CM04FeatureBlock = ({
    className,
    children,
}) => (
    <ListContainer className={classnames('CM04FeatureBlock', className)}>
        <List>
            {children}
        </List>
    </ListContainer>
);

CM04FeatureBlock.propTypes = {
    /** List items */
    children: propTypes.node,
    /** Optional additional class(es) */
    className: classNameType,
};

CM04FeatureBlock.displayName = 'CM04FeatureBlock';

export default CM04FeatureBlock;
