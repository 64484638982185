import React, {useRef, useEffect, useState} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import clamp from 'lodash/clamp';
import debounce from 'lodash/debounce';
import invoke from 'lodash/invoke';

import {mediaQuery, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';

import {SCREEN_768_TABLET, SCREEN_1920_DESKTOP} from '../../../../../common/screen-sizes';
import EMPTY_IMAGE_SRC from '../../../../../common/empty-image-src';
import {panther} from '../../../../../common/palette';
import VPGA03Thumbnail from '../../../../atoms/vp/ga/03-thumbnail';
import VPTM02HoverThumbnail from '../02-hover-thumbnail';
import Arrow from '../02-hover-thumbnail/arrow';

const Z_INDEX_ARROW = 1;
const SHADOW_SIZE_PX = 30;

const Container = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const CarouselOuter = styled.div`
    /* Margin and padding wizardry so that overflow: hidden doesn't clip the shadows at the top and bottom */
    margin: -${SHADOW_SIZE_PX}px 0;
    padding: ${SHADOW_SIZE_PX}px 0;
    width: 100vw;
    overflow: hidden;
`;

const CarouselInner = styled.div`
    /** This fixes the active thumbnail having a weird offsetLeft in Safari for some reason...? */
    position: relative;
    width: 100%;
`;

const Thumbnails = styled.div`
    display: flex;
    align-items: center;
    box-shadow: 0 0 ${SHADOW_SIZE_PX}px 0 ${panther};
    background-color: ${rgba(panther, 0.48)};
    width: max-content;

    ${VPGA03Thumbnail} {
        display: none;

        ${stylesWhenNot('hideInactiveThumbnails')`
            margin: 0 7px;

            ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
                display: initial;
            `}

            ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
                margin: 0 10px;
            `}
        `}
    }
`;

const StyledArrow = styled(Arrow)`
    position: absolute;
    bottom: 0;
    transform: translate(-50%, 100%);
    z-index: ${Z_INDEX_ARROW};
`;

const ThumbnailCarouselView = ({
    urls = [],
    activeThumbnailUrlIndex = Math.floor(urls.length / 2),
    activeThumbnailContent,
    position,
}) => {
    const containerRef = useRef();
    const carouselRef = useRef();
    const activeThumbnailRef = useRef();
    const arrowRef = useRef();
    const [carouselTranslatePerc, setCarouselTranslatePerc] = useState(0);
    const [arrowPositionPerc, setArrowPositionPerc] = useState(0);
    const [windowSize, setWindowSize] = useState({});

    // On window resize, set a variable so that the calculation effect re-runs
    const debouncedHandleResize = debounce(() => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }, 50);

    // On mount/unmount
    useEffect(() => {
        window.addEventListener('resize', debouncedHandleResize, {passive: true});

        return () => {
            window.removeEventListener('resize', debouncedHandleResize, {passive: true});
            invoke(debouncedHandleResize, 'cancel');
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // Calculate positions for carousel and arrow
    useEffect(() => {
        // Don't run if refs haven't propagated
        if (![
            containerRef.current,
            carouselRef.current,
            arrowRef.current,
            activeThumbnailRef.current,
        ].every(Boolean)) {
            return;
        }

        const containerRect = containerRef.current.getBoundingClientRect();
        const container = {
            screenLeft: containerRect.left,
            screenRight: containerRect.right,
        };

        const carouselRect = carouselRef.current.getBoundingClientRect();
        const carousel = {
            left: carouselRef.current.offsetLeft,
            screenLeft: carouselRect.left,
            screenRight: carouselRect.right,
            width: carouselRef.current.offsetWidth,
        };

        const thumbnail = {
            left: activeThumbnailRef.current.offsetLeft,
            width: activeThumbnailRef.current.scrollWidth,
        };

        // Calculate the translate amount needed to center the active thumbnail over the arrow
        const arrowMidpoint = (containerRef.current.offsetWidth * position) - carousel.left; // Arrow position relative to viewport
        const thumbnailMidpoint = thumbnail.left + (thumbnail.width / 2);
        const translateToThumbnailMidpoint = thumbnailMidpoint / carousel.width; // How much translate we need to center the active thumbnail
        const newTranslate = -(arrowMidpoint / carousel.width) + translateToThumbnailMidpoint;

        // Prevent the active thumbnail from leaving its boundary

        // // This version clamps the active thumbnail to the edges of the viewport
        // const lowerBound = (thumbnail.left - (carousel.width - thumbnail.width)) / carousel.width;
        // const upperBound = thumbnail.left / carousel.width;

        // This version clamps the active thumbnail to this component's parent + half of the arrow width
        const arrowHalfWidth = arrowRef.current.getBoundingClientRect().width / 2;
        const containerLeftToCarouselLeft = Math.abs(container.screenLeft - carousel.screenLeft) - arrowHalfWidth;
        const containerRightToCarouselRight = Math.abs(container.screenRight - carousel.screenRight) - arrowHalfWidth;
        const lowerBound = (thumbnail.left - ((carousel.width - containerRightToCarouselRight) - thumbnail.width)) / carousel.width;
        const upperBound = (thumbnail.left - containerLeftToCarouselLeft) / carousel.width;
        const clampedTranslate = clamp(newTranslate, lowerBound, upperBound);

        setCarouselTranslatePerc(clampedTranslate * -100);
        // This is not actually required but updating this at the same time
        // as carouselTranslatePerc keep them in sync visually when scrubbing
        setArrowPositionPerc(position * 100);
    }, [ // eslint-disable-line react-hooks/exhaustive-deps
        position,
        activeThumbnailUrlIndex,
        windowSize.width,
        windowSize.height,
        containerRef.current,
        carouselRef.current,
        arrowRef.current,
        activeThumbnailRef.current,
    ]);

    const shouldHideInactiveThumbnails = windowSize.width >= 1280;

    return (
        <Container ref={containerRef}>
            <CarouselOuter ref={carouselRef}>
                <CarouselInner style={{transform: `translateX(${carouselTranslatePerc}%)`}}>
                    <Thumbnails hideInactiveThumbnails={shouldHideInactiveThumbnails}>
                        {urls.map((url, index) => index === activeThumbnailUrlIndex
                            ? (
                                <VPTM02HoverThumbnail
                                    ref={activeThumbnailRef}
                                    key={url || index}
                                    src={url || EMPTY_IMAGE_SRC}
                                    content={activeThumbnailContent}
                                    shouldShowArrow={false}
                                />
                            )
                            : <VPGA03Thumbnail key={url || index} src={url || EMPTY_IMAGE_SRC} />)
                        }
                    </Thumbnails>
                </CarouselInner>
            </CarouselOuter>

            <StyledArrow ref={arrowRef} style={{left: `${arrowPositionPerc}%`}} />
        </Container>
    );
};

ThumbnailCarouselView.propTypes = {
    /** Array of thumbnail URLs */
    urls: propTypes.arrayOf(propTypes.string),
    /** Optional index indicating which entry in urls to use as the active thumbnail */
    activeThumbnailUrlIndex: propTypes.number,
    /** Text shown over the active thumbnail */
    activeThumbnailContent: propTypes.node,
    /** A 0-1 value indicating where the arrow/active thumbnail is. 0 for left and 1 for right. */
    position: propTypes.number,
};

ThumbnailCarouselView.displayName = 'ThumbnailCarouselView';

export default ThumbnailCarouselView;
