/* eslint-disable arrow-body-style */
import React from 'react';
import bacon from 'baconjs';

import capitalize from 'lodash/capitalize';
import isEqual from 'lodash/isEqual';
import identity from 'lodash/identity';
import get from 'lodash/get';
import set from 'lodash/set';
import matchesProperty from 'lodash/matchesProperty';
import trim from 'lodash/trim';
import once from 'lodash/once';
import method from 'lodash/method';

import {screenSizes} from '@fsa-streamotion/streamotion-web-ionic';
import {accountsEnvs, getUser, COMMON_RESOURCES_URL, SHOULD_FORCE_REFRESH_USER_TOKEN_STORAGE_KEY} from '@fsa-streamotion/streamotion-web-widgets-common';

import {isBrowser, isServer, getLocalStorageValue, removeLocalStorageValue} from '@fsa-streamotion/browser-utils';
import {hydrateStream} from '@fsa-streamotion/bacon-widget';
import {BrandedBA22EditBtn, BrandedIC103Loading} from '../../utils/branded-components';

import getPreviewVoucherMeta from '../../utils/preview-voucher-meta';
import StyledBaconWidget from '../../../../todo-move-to-widgets-common/utils/styled-bacon-widget';
import {prependQueryString} from '../../../../todo-move-to-widgets-common/utils/add-params-to-url';
import {
    callAdobeSatelliteTrack,
    getEmailHash,
    getTemplatisedPayload,
    initialiseAdobeDefinitionsConfig,
    trackFromAdobeDefinitions,
} from '../../../../todo-move-to-widgets-common/utils/adobe';
import getAppMeasurementLoadedEventStream from '../../../../todo-move-to-widgets-common/utils/adobe/adobe-loaded-state';
import {findFp, isEqualFp} from '../../../../todo-move-to-widgets-common/utils/fp-helpers';

import getKbotStream, {closeKbot, KBOT_STAGES, setKbotStage} from '../../../../todo-move-to-widgets-common/streams/kbot';
import getOfferStream from '../../../../todo-move-to-widgets-common/streams/endpoints/billing/offer';
import getSubscribeToProductStream from '../../../../todo-move-to-widgets-common/streams/endpoints/billing/subscribe';
import getFreemiumTermsAndConditionsStream from '../../../../todo-move-to-widgets-common/streams/endpoints/signup/freemium-terms-and-conditions';

import getBrandDisplayName from '../../../../todo-move-to-widgets-common/utils/get-brand-display-name';
import preventThen from '../../../../todo-move-to-widgets-common/utils/prevent-then';

import {
    MARKETING_STORAGE_KEYS,
    NEW_SIGNUP,
    RETURNING_SIGNUP,
    STORAGE_KEY_PREFIX,
    BACKGROUND_REL_SRCS,
} from '../../../../todo-move-to-widgets-common/utils/constants';
import mapErrorsForFiso from '../../../../todo-move-to-widgets-common/utils/map-errors-for-fiso';
import {imageUrlToSrcsetOptions} from '../../../../todo-move-to-widgets-common/utils/srcset-helpers';
import {RESOURCES_IMWIDTHS} from '../../../../todo-move-to-widgets-common/utils/imwidth';

import cleanAuth0NonceCookies from '../../common/clean-auth0-nonce-cookies';
import getInvalidOfferModalStream from '../common/invalid-offer-stream';
import getInvalidOptusOfferModalStream from '../common/invalid-optus-offer-stream';
import getActiveUserWarningModal from '../common/active-user-warning-modal-stream';
import {Paragraph} from '../common/styled-elements';
import {DISABLE_SIGNUP_HEADING_TEXT, MY_ACCOUNT_LINK} from '../../utils/constants';
import clientSideFeatureFlags from '../../utils/clientside-feature-flags';
import getNormalisedBrand from '../../utils/get-normalised-brand';
import validateBrand from '../../utils/validate-brand';

import CheckoutComponent from '../../components/branded/checkout';

import packageSummaryWidget from '../package-summary';

import {internationaliseMobileNumber} from './phone-utils';

import getStepMobileStream from './step-mobile';
import getStepUserDetailsStream from './step-user-details';
import getStepJourneyStream from './step-journey';
import getStepPaymentStream from './step-payment';
import getStepTermsAndStartSubscriptionStream from './step-terms-and-start-subscription';

const {SCREEN_PHONE, SCREEN_2560_DESKTOP, SCREEN_768_TABLET} = screenSizes;
const {envsFromWidgetSettings} = accountsEnvs;

const MOBILE_IMWIDTHS = RESOURCES_IMWIDTHS.filter((sizePx) => sizePx >= SCREEN_PHONE && sizePx < SCREEN_2560_DESKTOP);
const DESKTOP_IMWIDTHS = RESOURCES_IMWIDTHS.filter((sizePx) => sizePx >= SCREEN_768_TABLET && sizePx < SCREEN_2560_DESKTOP);
const PAYMENT_HEADING = 'Set up your payment details';
const TERMS_AND_CONDITIONS_SUCCESS_SYMBOL = Symbol('Terms and Conditions returns an empty 204 on success, which this symbol represents');
// Tracking codes. Analytics only care about new vs. existing, the "cc-" prefix is a misnomer
const NEW_CREDIT_CARD = 'cc-new';
const EXISTING_CREDIT_CARD = 'cc-existing';

const MAX_ADTECH_WAIT_MS = 5000;

export class Checkout extends StyledBaconWidget {
    static widgetName = 'accw-checkout';
    component = CheckoutComponent;

    clientSideHydratedBus = new bacon.Bus();
    clientSideHydrated$ = this.clientSideHydratedBus.take(1);

    constructor(settings, element) {
        super(settings, element);

        const baseUrl = trim(settings.baseUrl, '/');
        const {platformEnv, ...envSettings} = envsFromWidgetSettings(settings);

        Object.assign(
            this.config,
            {
                commonWidgetSettings: {
                    ...envSettings,
                    brand: validateBrand(getNormalisedBrand(settings.brand)),
                    baseUrl,
                    clientSideFeatureFlags: clientSideFeatureFlags(platformEnv), // these flags specifically pertain to CLIENTSIDE ONLY feature changes. if you need them server side, look at https://bitbucket.foxsports.com.au/projects/MAR/repos/ares-web-server/browse/node-app/src/js/routes/index.js#64-71
                    platformEnv,
                },

                voucher: settings.voucher,
                offerName: settings.offerName,
                selectedPackageId: settings.selectedPackageId,
                selectedPackagePriceId: settings.selectedPackagePriceId,
                termType: settings.termType,

                redirectCheckoutSuccessLink: this.settings.redirectCheckoutSuccessLink,
                redirectOfferLink: this.settings.redirectOfferLink,
            },
        );
    }

    getData(hydration = {}) {
        const {commonWidgetSettings} = this.config;
        const {brand, platformEnv, resourcesEnv} = commonWidgetSettings;
        const refreshUserTokenBus = new bacon.Bus();
        const startSubscriptionBus = new bacon.Bus();

        // my account link
        const myAccountUrl = MY_ACCOUNT_LINK[platformEnv];

        // Optus Subhub Offer and Package IDs
        let ddSubscriptionId;
        let merchantSubscriptionId;

        // The Optus IDs are not whitelisted by FISO, and are unique IDs so would cause cache fragmenting
        // Ensure these values are only checked for client-side where window object is present
        if (isBrowser()) {
            ddSubscriptionId = new URLSearchParams(window.location.search).get('ddSubscriptionId');
            merchantSubscriptionId = new URLSearchParams(window.location.search).get('merchantSubscriptionId');
        }

        if (!brand) {
            console.error('Accounts widgets checkout: no brand in widget config', this.config);
        }

        const getFreemiumTermsAndConditionsStreamOnce = once(getFreemiumTermsAndConditionsStream);

        const redirectOfferLinkWithParams = prependQueryString({
            url: this.config.redirectOfferLink,
            parameters: {
                'offer-name': this.config.offerName,
                'voucher': this.config.voucher,
                'pg': this.config.termType === 'annual' ? 'annual' : 'default',
            },
        });

        const onChangePlanClick = preventThen(() => {
            // User has shown their intent to resubscribe, dont redirect them to freebies!
            sessionStorage.setItem([STORAGE_KEY_PREFIX[brand].shouldPreventFreebiesRedirection, platformEnv].join('-'), true);

            window.location.href = redirectOfferLinkWithParams;
        });

        const {relBgSrc, relBgSrcPortrait} = BACKGROUND_REL_SRCS[brand];

        const user$ = this.clientSideHydrated$
            .flatMapLatest(() => bacon.fromPromise(getUser({
                brand,
                platformEnv,
            })));

        const hasOptusValues$ = bacon.later(0, !!(ddSubscriptionId && merchantSubscriptionId))
            .startWith(false)
            .toProperty();

        const userIsAuthenticated$ = user$.flatMapLatest((user) => bacon.fromPromise(user.isAuthenticated()));

        const redirectToOfferIfSettingsMissing$ = this.clientSideHydrated$
            .doAction(() => {
                closeKbot();
                if (
                    (!this.config.offerName || !this.config.selectedPackageId || !this.config.selectedPackagePriceId)
                    // Do not redirect if Optus IDs are present with no regular offer and package IDs
                    && !(ddSubscriptionId && merchantSubscriptionId)
                ) {
                    console.error(`Error: offerName (${this.config.offerName}), selectedPackageId (${this.config.selectedPackageId}), and selectedPackagePriceId (${this.config.selectedPackagePriceId}) query string parameters are all mandatory: redirecting to redirectOfferLink (${this.config.redirectOfferLink}).`);
                    window.location.replace(this.config.redirectOfferLink);
                }
            });

        const userAccessToken$ = bacon.combineTemplate({
            user: user$,
            userIsAuthenticated: userIsAuthenticated$,
            refreshUserToken: refreshUserTokenBus.startWith(false),
        })
            .flatMapLatest(({user, userIsAuthenticated, refreshUserToken}) => {
                if (!userIsAuthenticated) {
                    return null;
                }

                return bacon.fromPromise(
                    refreshUserToken
                        ? user.refreshAccessToken()
                        : user.getAccessToken()
                );
            });

        const userAccountStatus$ = bacon.combineTemplate({
            user: user$,
            userAccessToken: userAccessToken$,
        })
            .flatMapLatest(({user}) => bacon.fromPromise(user.getAccountStatusesByBrand(brand)));

        const userAccountMainStatus$ = userAccountStatus$
            .map('.account_status')
            .skipDuplicates();
        // const userAccountSubStatus$ = userAccountStatus$
        //     .map('.sub_account_status')
        //     .skipDuplicates();

        const userDetails$ = bacon.combineTemplate({
            user: user$,
            userAccessToken: userAccessToken$,
        })
            .flatMapLatest(({user}) => bacon.fromPromise(user.getUserDetails()))
            .skipDuplicates(isEqual);

        // This little fellow will power all the static stuff on the page.
        const prefetchedOffer$ = hydrateStream(
            hydration.prefetchedOffer$,
            getOfferStream({
                platformEnv,
                brand,
                offer: this.config.offerName,
                voucher: this.config.voucher,
                accessToken: undefined,
                ddSubscriptionId,
                merchantSubscriptionId,
                termType: this.config.termType,
            })
        )
            .toProperty();

        const offer$ = userAccessToken$
            .first()
            .flatMapLatest((accessToken) => getOfferStream({
                platformEnv,
                brand,
                offer: this.config.offerName,
                voucher: this.config.voucher,
                accessToken,
                ddSubscriptionId,
                merchantSubscriptionId,
                termType: this.config.termType,
            }));

        const isOfferInvalid$ = offer$
            .map('.error.code')
            .map(Boolean)
            .startWith(false)
            .skipDuplicates();

        // Need a const clientFetchedOffer$ - which needs to compare prefetched with user token applied.
        // on error, display next best offer flow.

        const packageSummaryWidget$ = packageSummaryWidget({
            ...commonWidgetSettings,

            expandTerms: true,
            redirectForgotVoucherLink: redirectOfferLinkWithParams,

            offer$: prefetchedOffer$.concat(offer$),
            selectedPackageId: this.config.selectedPackageId,
            selectedPackagePriceId: this.config.selectedPackagePriceId,
            termType: this.config.termType,
        }).initComponentStream();

        const selectedPackage$ = offer$
            .map('.packages')
            .map(findFp({packageId: this.config.selectedPackageId}));

        const stepMobile$ = getStepMobileStream({
            offer$,
            user$,
            userDetails$,
            userAccountMainStatus$,
            brand,
            platformEnv,
        });
        const stepUserDetails$ = getStepUserDetailsStream({userDetails$, brand});

        const journeyType$ = offer$.map('.offer.journeys.0').startWith(null);
        const isFreemiumOffer$ = journeyType$.map(isEqualFp('FREEMIUM'));
        const isOptusOffer$ = journeyType$.map(isEqualFp('OPTUS'));
        const isPpvOffer$ = journeyType$.map(isEqualFp('PPV'));

        const eventId$ = offer$.map('.offer.eventId').startWith(null); // Special events like PPV has a value here, null otherwise

        const selectedPackageId$ = isOptusOffer$.decode({
            true: offer$.map('.packages.0.packageId'),
            false: this.config.selectedPackageId,
        });

        const redirectIfNotAuthenticated$ = bacon.combineTemplate({
            isAuthenticated: userIsAuthenticated$,
            user: user$,
            isFreemiumOffer: isFreemiumOffer$,
            isOptusOffer: ddSubscriptionId && merchantSubscriptionId,
            isPpvOffer: isPpvOffer$,
        })
            .filter(({isAuthenticated}) => !isAuthenticated)
            .doAction(({user, isFreemiumOffer, isOptusOffer, isPpvOffer}) => {
                closeKbot();
                if (isFreemiumOffer || isPpvOffer) {
                    user.login({
                        redirectAfterProcessing: window.location.href,
                        metaData: {
                            'page': 'register',
                            'platform': getNormalisedBrand(brand),
                            'offer-name': this.config.offerName,
                            'selected-package-id': this.config.selectedPackagePriceId,
                            'selected-package-price-id': this.config.selectedPackageId,
                            'voucher': this.config.voucher || undefined, // make sure we don't send this as empty string.
                            'telstra-jwt': sessionStorage.getItem('signupTelstraJwt') || undefined,
                        },
                    });
                } else if (isOptusOffer) {
                    user.login({
                        redirectAfterProcessing: window.location.href,
                        metaData: {
                            page: 'register',
                            platform: getNormalisedBrand(brand),
                            partnership: 'optus',
                            ddSubscriptionId,
                            merchantSubscriptionId,
                        },
                    });
                } else {
                    // Left this specifically as .href if people want hit back in their browsers after authentication.
                    window.location.href = redirectOfferLinkWithParams;
                }
            });

        // check if user is already have active subscription
        const isActiveSubscription$ = user$
            .map(method('checkStatusIsActiveSubscription', brand))
            .flatMapLatest(bacon.fromPromise);

        const userHasSignedUpToOfferRedirectLinks$ = bacon.combineTemplate({
            eventId: eventId$,
            isPpvOffer: isPpvOffer$,
            subscribedPpvEvents: user$.map(method('getSubscribedPpvEvents', brand)).flatMapLatest(bacon.fromPromise),
            isActiveSubscription: isActiveSubscription$,
            hasOptusValues:  hasOptusValues$,
        })
            .flatMapLatest(({eventId, isPpvOffer, subscribedPpvEvents, isActiveSubscription, hasOptusValues}) => {
                let newUrl;

                if (hasOptusValues && isActiveSubscription) {
                    // Redirect to checkout success link if both conditions are met
                    newUrl = this.config.redirectCheckoutSuccessLink;
                } else if (hasOptusValues) {
                    // No redirect if only hasOptusValues is true
                } else if (isPpvOffer) {
                    newUrl = subscribedPpvEvents?.includes(eventId) ? this.config.redirectCheckoutSuccessLink : null;
                } else if (sessionStorage.getItem('signupTelstraJwt')) {
                    newUrl = getPreviewVoucherMeta({voucher: this.config.voucher, brand}).location; // logged in user + telstra JWT = queue voucher flow
                } else {
                    newUrl = this.config.redirectCheckoutSuccessLink;
                }

                return newUrl;
            });

        // no redirect for active optus subscription
        const redirectIfNotAlreadySignedUpToOptusOffer$ = bacon.combineTemplate({
            isFreemiumOffer: isFreemiumOffer$,
            userHasSignedUpToOfferRedirectLinks: userHasSignedUpToOfferRedirectLinks$,
            user: user$,
        })
            .map(({isFreemiumOffer, user}) => (
                isFreemiumOffer
                    ? user.canPlayFreemium(brand) // if we're looking at a freemium offer, and the user can already play freemium, signal "true" to move them on
                    : user.checkStatusIsActiveSubscription(brand) // premium offer and user already ACTIVE_SUBSCRIPTION, signal "true" to move them on
            ))
            .flatMapLatest(bacon.fromPromise)
            .filter(Boolean)
            .combine(userHasSignedUpToOfferRedirectLinks$, (_, newUrl) => newUrl)
            .doAction((newUrl) => {
                if (!newUrl) {
                    return; // no redirect
                }

                closeKbot();

                cleanAuth0NonceCookies();

                window.location.replace(newUrl);
            });

        const stepJourney$ = bacon
            .combineTemplate({
                journeyType: journeyType$,
                platformEnv,
                ratePlanId: selectedPackageId$,
                user: user$,
                voucherCode: offer$.map('.voucher.code').startWith(null),
                brand,
            })
            .flatMapLatest(getStepJourneyStream)
            .startWith(null);

        const isJourneyActive$ = stepJourney$.map('.isJourneyActive');

        const isJourneyCompleted$ = stepJourney$
            .map('.isJourneyCompleted')
            .toProperty()
            .skipDuplicates();

        const shouldStepPaymentBeActive$ = isOfferInvalid$.decode({
            true: false,
            false: isJourneyCompleted$,
        });

        const stepPayment$ = getStepPaymentStream({
            isActive$: shouldStepPaymentBeActive$,

            user$,
            userAccountMainStatus$,
            offer$,
            selectedPackageId$,
            brand: commonWidgetSettings.brand,
            platformEnv,
            ddSubscriptionId,
            merchantSubscriptionId,

            startSubscriptionBus,
        });

        const stepPaymentErrorsAsEventStream$ = stepPayment$
            .map('.paymentErrors')
            .toEventStream();

        const stepPaymentDetailsAsEventStream$ = stepPayment$
            .map('.paymentDetails')
            .toEventStream()
            .skipDuplicates(isEqual); // Payment details can come through twice when they shouldn't as other streams fire events around them.

        const processSubscriptionOrRegistration$ = bacon.combineTemplate({
            user: user$,
            journeyDetails: stepJourney$.map('.journeyDetails'),
            paymentDetails: stepPaymentDetailsAsEventStream$.filter(Boolean),
            isFreemium: isFreemiumOffer$,
            ratePlanId: selectedPackageId$,
        })
            .map(({user, ...params}) => ({
                ...params,
                accessToken: bacon.fromPromise(user.getAccessToken()),
                userDetails: bacon.fromPromise(user.getUserDetails()),
            }))
            .flatMapLatest(bacon.combineTemplate)
            .first()
            .flatMapLatest(({
                paymentDetails,
                journeyDetails,
                accessToken,
                userDetails,
                isFreemium,
                ratePlanId,
            }) => {
                if (isFreemium) {
                    return getFreemiumTermsAndConditionsStreamOnce({platformEnv, accessToken, product: brand})
                        .map(TERMS_AND_CONDITIONS_SUCCESS_SYMBOL);
                }

                const {email, firstName, lastName, phoneNumber} = userDetails;
                const campaign = getLocalStorageValue({key: MARKETING_STORAGE_KEYS[brand].campaign});
                const channel = getLocalStorageValue({key: MARKETING_STORAGE_KEYS[brand].channel});
                const extcamp = getLocalStorageValue({key: MARKETING_STORAGE_KEYS[brand].extcamp});
                const marketing = getLocalStorageValue({key: `${STORAGE_KEY_PREFIX[brand].marketingPg}-${commonWidgetSettings.platformEnv}`});
                const referralid = getLocalStorageValue({key: MARKETING_STORAGE_KEYS[brand].referralId});

                return getSubscribeToProductStream({
                    brand: commonWidgetSettings.brand,

                    accessToken,
                    platformEnv,
                    email,
                    firstName,
                    lastName,
                    phoneNumber: internationaliseMobileNumber(phoneNumber),

                    offerCode: this.config.offerName,
                    ratePlanId,
                    voucherCode: this.config.voucher,

                    // Random marketing params that are tracked in billing's adobe. And ours. Sometimes.
                    campaign,
                    channel,
                    marketing,
                    referralId: referralid,
                    source: extcamp,

                    // Journey Details if we have them
                    journeyDetails,

                    // Voucher is being recycled for now, so although it may not apply to the selected product, it always populated these fields.
                    partnerOffer: this.config.voucher,

                    paymentGateway: null, // paymentDetails.paymentGateway overrides this
                    paymentRefId: null, // paymentDetails.refId overrides this

                    // Mash our payment details on top of this default.
                    // They're be setting things specific to Telstra, or Credit Card etc.
                    ...paymentDetails,
                });
            });

        const subscriptionErrors$ = bacon.mergeAll(
            // Clear out errors when we start subscription again.
            startSubscriptionBus.map(null),

            // Take the errors from stepPayment.
            stepPaymentErrorsAsEventStream$
                .filter(Boolean),

            // errors from api response.
            processSubscriptionOrRegistration$
                .map('.error')                         // API body errors are in this object!
                .mapError(identity)                    // take this as a real value if it's API deaded.
                .map('.errorDetails.message')          // And then checkout what message we need to present.
                .filter(Boolean),                      // subscribe call has a specific copy text for message vs longMessage for invalid giftcard that needs to be displayed in checkout only
        )
            .startWith(null);

        // We blank out the page on loading, plus when mobile is not yet verified.
        const hasDisabledSections$ = stepMobile$
            .map('.isCompleted')
            .not()
            .startWith(true);

        const trackFreemiumCall = once(() => trackFromAdobeDefinitions({definitionsPath: 'screenTracking.freemium.termsAndConditions', eventType: 'screenload'}));
        const trackFreemiumTermsAndConditions$ = hasDisabledSections$
            .not()
            .and(isFreemiumOffer$)
            .doAction((shouldTrackFreemium) => {
                if (shouldTrackFreemium) {
                    trackFreemiumCall();
                }
            });

        const isPaymentSkipped$ = stepPayment$
            .map('.isSkipped')
            .not();

        const headerText$ = bacon.combineTemplate({
            stepMobileIsCompleted: stepMobile$.map('.isCompleted'),
            stepUserDetailsIsCompleted: stepUserDetails$.map('.isCompleted'),
            stepPaymentIsCompleted: stepPayment$.map('.isCompleted'),
        })
            .map(({stepMobileIsCompleted /* , stepUserDetailsIsCompleted, stepPaymentIsCompleted */}) => {
                if (!stepMobileIsCompleted) {
                    return 'Verify Your Mobile Number';
                }

                return null;
            })
            .startWith('Verify Your Mobile Number');

        const subscriptionSuccessful$ = bacon.mergeAll(
            // Happy day subscription id returned.
            processSubscriptionOrRegistration$.map('.SubscriptionId').filter(Boolean),

            // Offline processing of subscription.  It'll either all be okay, or shortly tell the user they're inactive again depending how successful the API was.
            processSubscriptionOrRegistration$.map('.AccountId').filter((accountId) => accountId === 'IN_PROGRESS'),

            // The user accepted our freemium terms and conditions! Their checkout journey is now completed
            processSubscriptionOrRegistration$.filter(isEqualFp(TERMS_AND_CONDITIONS_SUCCESS_SYMBOL)),
        ).doAction(() => {
            // clean up local storage;
            removeLocalStorageValue(MARKETING_STORAGE_KEYS[brand].campaign);
            removeLocalStorageValue(MARKETING_STORAGE_KEYS[brand].channel);
            removeLocalStorageValue(MARKETING_STORAGE_KEYS[brand].extcamp);
            removeLocalStorageValue(MARKETING_STORAGE_KEYS[brand].referralId);

            // These are env scoped.
            removeLocalStorageValue(`${STORAGE_KEY_PREFIX[brand].marketingPg}-${commonWidgetSettings.platformEnv}`);
            removeLocalStorageValue(`${STORAGE_KEY_PREFIX[brand].offerName}-${commonWidgetSettings.platformEnv}`);
            removeLocalStorageValue(`${STORAGE_KEY_PREFIX[brand].voucher}-${commonWidgetSettings.platformEnv}`);

            // Telstra session storage items
            window.sessionStorage.removeItem('signupTelstraJwt');
            window.sessionStorage.removeItem('signupTelstraOrderItemNumber');
            window.sessionStorage.removeItem('signupTelstraCarrierBillableOffer');
        });

        // On each step being completed, we need to refresh our user token that'll drive the flow
        // of the signup.
        const refreshUserTokenOnSteps$ = bacon.mergeAll(
            stepMobile$
                .map('.isCompleted')
                .skipDuplicates()
                .skip(1)
                .filter(Boolean),

            subscriptionSuccessful$
        )
            .doAction(() => {
                // Let respective web app know that they need to refresh the access token
                window.sessionStorage.setItem(SHOULD_FORCE_REFRESH_USER_TOKEN_STORAGE_KEY[platformEnv][brand], true);

                refreshUserTokenBus.push(true);
            });

        const isSubscriptionButtonDisabled$ = stepPayment$
            .map(({paymentLoaded, shouldDisableSubscribeButton}) => paymentLoaded && !shouldDisableSubscribeButton)
            .and(shouldStepPaymentBeActive$)
            .not()
            .startWith(true)
            .skipDuplicates();

        const isSubscriptionButtonLoading$ = bacon.mergeAll(
            // Don't be a spinner while we load, use disable for that above.
            startSubscriptionBus.map(true),

            subscriptionErrors$
                .filter(Boolean)
                .map(false),

            // Even after start sub has started, make sure we 'load'
            // when paymentDetails trigger
            stepPaymentDetailsAsEventStream$
                .filter(Boolean)
                .map(true),
        )
            .startWith(false)
            .skipDuplicates();

        const stepTermsAndStartSubscription$ = getStepTermsAndStartSubscriptionStream({
            prefetchedOffer$,

            errors$: subscriptionErrors$,

            isPpvOffer$,
            isSubscriptionButtonDisabled$,
            isSubscriptionButtonLoading$,

            startSubscriptionBus,

            brand,
        });

        const activeUserWarningModal$ = getActiveUserWarningModal({
            brand,
            isActiveSubscription$,
            hasOptusValues$,
            profileUrl: this.config.redirectCheckoutSuccessLink,
        });

        // If Optus offer values are present, use the simplified invalid optus offer stream
        const warningModal$ = (ddSubscriptionId || merchantSubscriptionId)
            ? getInvalidOptusOfferModalStream({
                brand,
                prefetchedOffer$,
                isActiveSubscription$,
                offer$,
            })
            : getInvalidOfferModalStream({
                user$,
                userIsAuthenticated$,
                prefetchedOffer$,
                offer$,
                isFreemiumOffer$,
                isPpvOffer$,
                ddSubscriptionId,
                merchantSubscriptionId,
                selectedPackageId: this.config.selectedPackageId,
                selectedPackagePriceId: this.config.selectedPackagePriceId,
                shouldSelectNextBestOffer: true,      // On error, force the user to pick something.
                validatePackagePriceIdSelection: true,  // Make sure we have package price id all matching

                brand,
                platformEnv,
            });

        initialiseAdobeDefinitionsConfig({brand, resourcesEnv: commonWidgetSettings.resourcesEnv});

        const trackVerification$ = bacon
            .combineTemplate({
                user: user$,
                mobileVerificationCompleted: stepMobile$.map('.isCompleted'),
                accountStatus: userAccountMainStatus$,
            })
            .map(({user, ...params}) => ({
                ...params,
                isReturningUser: bacon.fromPromise(user.isStatusReturning()),
            }))
            .flatMapLatest(bacon.combineTemplate)
            .combine(getAppMeasurementLoadedEventStream(), ({mobileVerificationCompleted, accountStatus, isReturningUser} = {}) => (
                !mobileVerificationCompleted && accountStatus === 'INIT' ? {isReturningUser} : false
            ))
            .skipDuplicates(isEqual)
            .filter(Boolean)
            .doAction(async (/* {isReturningUser} */) => {
                const payload = await getTemplatisedPayload({
                    definitionsPath: 'eventTracking.signUp.personalDetails',
                });

                // @TODO As per Chinmay the definition of return user is different for tracking purpose
                // Let's handle this as part of WEB-26, for now send empty values
                // Keeping the modified code for future reference
                // set(payload, 'data.user.streamotion', payload?.data?.user?.streamotion?.[isReturningUser ? 'existing' : 'nonExisting']);
                set(payload, 'data.user.streamotion', '');

                callAdobeSatelliteTrack(payload?.data?.event?.name, payload);

                trackFromAdobeDefinitions({
                    definitionsPath: 'screenTracking.signup.verification',
                    eventType: 'screenload',
                });

                setKbotStage(KBOT_STAGES.VERIFICATION);
            });

        /* step4:paymentdetails */

        const trackSuccessfulPayment$ = stepMobile$
            .map('.isCompleted')
            .combine(stepPayment$, (mobileVerificationCompleted, {paymentSuccessful, isNewPaymentMethod}) => {
                return {
                    paymentMethod: isNewPaymentMethod ? NEW_CREDIT_CARD : EXISTING_CREDIT_CARD,
                    paymentSuccessful,
                    mobileVerificationCompleted,
                };
            })
            .skipDuplicates(isEqual)
            .filter(({mobileVerificationCompleted, paymentSuccessful}) => mobileVerificationCompleted && paymentSuccessful)
            .doAction(({paymentMethod}) => {
                trackFromAdobeDefinitions({
                    definitionsPath: 'eventTracking.signUp.paymentDetails',
                    eventTypePayloadKey: 'data.event.name',
                    templateValues: {payment: paymentMethod},
                });
            });

        const trackPaymentDetails$ = stepMobile$
            .map('.isCompleted')
            .and(stepPayment$
                .map(({paymentSuccessful, isSkipped}) => !!(paymentSuccessful || isSkipped)))
            .skipDuplicates()
            .filter(Boolean)
            .combine(getAppMeasurementLoadedEventStream(), (isComplete) => isComplete)
            .doAction(() => {
                setKbotStage(KBOT_STAGES.PAYMENT);
                trackFromAdobeDefinitions({
                    definitionsPath: 'screenTracking.signup.paymentDetails',
                    eventType: 'screenload',
                });
            });

        /* step5:confirmation */

        const isConfirmationEventTrackingDoneBus = new bacon.Bus();
        const isConfirmationEventTrackingComplete$ = isConfirmationEventTrackingDoneBus.toProperty(false);
        const isConfirmationEventTrackingStartedBus = new bacon.Bus();
        const isConfirmationEventTrackingStarted$ = isConfirmationEventTrackingStartedBus.toProperty(false);

        const markConfirmationEventTrackingStarted = () => {
            console.debug('[CHECKOUT CONFIRMATION TRACKING][0] Mark confirmation event tracking as started!'); // eslint-disable-line no-console
            isConfirmationEventTrackingStartedBus.push(true);
        };

        const markConfirmationEventTrackingDone = () => {
            console.debug('[CHECKOUT CONFIRMATION TRACKING][2] Mark confirmation event tracking as done!'); // eslint-disable-line no-console
            isConfirmationEventTrackingDoneBus.push(true);
        };

        const logSuccessfulSubscription$ = bacon.combineTemplate({
            selectedPackage: selectedPackage$,
            subscriptionNumber: processSubscriptionOrRegistration$.map('.SubscriptionNumber'),
            user: user$,
            userAccountMainStatus: userAccountMainStatus$,
            wasSuccessfulFreemiumSub: processSubscriptionOrRegistration$.map(isEqualFp(TERMS_AND_CONDITIONS_SUCCESS_SYMBOL)),
            isPpvOffer: isPpvOffer$,
        })
            .map(({isPpvOffer, user, wasSuccessfulFreemiumSub, ...params}) => ({
                ...params,
                accessToken: bacon.fromPromise(user.getAccessToken()),
                auth0ID: bacon.fromPromise(user.getAccessTokenDetails()).map('.sub'),
                userDetails: bacon.fromPromise(user.getUserDetails()),
                isReturningUser: bacon.fromPromise(user.isStatusReturning()),
                isActiveSubscription: bacon.fromPromise(user.checkStatusIsActiveSubscription(brand))
                    .map((isActiveSubscription) => isActiveSubscription || wasSuccessfulFreemiumSub || isPpvOffer), // PPV Signups has a weird delay in registering a user as active, so we force this value to be active
                wasSuccessfulFreemiumSub,
                isConfirmationEventTrackingStarted: isConfirmationEventTrackingStarted$,
            }))
            .flatMapLatest(bacon.combineTemplate)
            .filter('.isActiveSubscription')
            .doAction(async ({
                userDetails,
                auth0ID,
                selectedPackage,
                subscriptionNumber,
                isReturningUser,
                wasSuccessfulFreemiumSub,
                isConfirmationEventTrackingStarted,
            }) => {
                // If this action has already been started, return to avoid duplicate analytics calls
                if (isConfirmationEventTrackingStarted) {
                    return;
                }

                markConfirmationEventTrackingStarted();

                const screenFeature = selectedPackage?.features?.find(
                    matchesProperty('title', 'No. of Screens')
                );

                const templateValues = {
                    auth0ID,
                    MCID: window._satellite?.getVisitorId?.()?.getMarketingCloudVisitorID?.(),
                    hashedEmail: await getEmailHash(get(userDetails, 'email')),
                    productSKU: get(selectedPackage, 'planName'),
                    ratePlan: screenFeature ? `${get(screenFeature, 'value')} Screen` : null,
                    SubscriptionNumber: subscriptionNumber,
                    voucher: this.config.voucher,
                    offer: this.config.offerName,
                    originalPrice: get(selectedPackage, 'prices.0.displayRegularAmount'),
                    finalPrice: get(selectedPackage, 'prices.0.displayAmount'),
                    lifecycle: isReturningUser ? RETURNING_SIGNUP : NEW_SIGNUP,
                };

                const payload = await getTemplatisedPayload({
                    definitionsPath: wasSuccessfulFreemiumSub
                        ? 'eventTracking.freemiumSignUp.registrationConfirmation'
                        : 'eventTracking.signUp.confirmation',
                    templateValues,
                });

                closeKbot();

                set(payload, 'data.user.streamotion', '');
                set(payload, 'data.signup.path', '');

                callAdobeSatelliteTrack(payload?.data?.event?.name, payload);

                trackFromAdobeDefinitions({
                    definitionsPath: 'screenTracking.signup.confirmation',
                    eventType: 'screenload',
                });

                /** ******************************************
                 *  Check if tracking is done and
                 *  toggle / enable `get started` button
                 /*******************************************/

                // if _satellite not defined, toggle without waiting
                if (window._satellite === undefined) {
                    console.debug('[CHECKOUT CONFIRMATION TRACKING][1] _satellite not defined!'); // eslint-disable-line no-console
                    markConfirmationEventTrackingDone();

                    return;
                }

                const listenerArgs = ['finishedAdTechCalls', markConfirmationEventTrackingDone, {once: true}];

                document.addEventListener(...listenerArgs);

                // toggle after 5 seconds without waiting for finishedAdTechCalls event
                setTimeout(() => {
                    document.removeEventListener(...listenerArgs);
                    console.debug(`[CHECKOUT CONFIRMATION TRACKING][1] Timeout (${MAX_ADTECH_WAIT_MS}ms) reached waiting for finishedAdTechCalls!`); // eslint-disable-line no-console
                    markConfirmationEventTrackingDone();
                }, MAX_ADTECH_WAIT_MS);
            });

        const shouldShowCheckoutSuccess$ = processSubscriptionOrRegistration$
            .map((processSubscriptionOrRegistration) => (
                processSubscriptionOrRegistration?.SubscriptionNumber
                || processSubscriptionOrRegistration === TERMS_AND_CONDITIONS_SUCCESS_SYMBOL
            ))
            .filter(Boolean)
            .map(true)
            .startWith(false)
            .skipDuplicates();

        const showJourneyOrPaymentStep$ = bacon.combineTemplate({
            isJourneyActive: isJourneyActive$.toProperty(),
            isJourneyCompleted: isJourneyCompleted$,
            journey: {
                children: stepJourney$.map('.reactElement'),
            },
            paymentCollapsed: {
                heading: isJourneyCompleted$
                    .map((isJourneyCompleted) => !isJourneyCompleted && PAYMENT_HEADING),
                children: bacon.combineWith(
                    journeyType$,
                    isJourneyCompleted$,
                    (journeyType, isJourneyCompleted) => (
                        isJourneyCompleted
                            ? null
                            : <Paragraph brand={brand}>{`Your Payment Details will appear here once you enter your ${capitalize(journeyType)} membership details`}</Paragraph>
                    ),
                ),
            },
            payment: {
                heading: stepPayment$
                    .map('.isSkipped')
                    .map((isSkipped) => isSkipped && PAYMENT_HEADING),
                children: stepPayment$.map('.reactElement'),
            },
            terms: {
                heading: null,
                children: stepTermsAndStartSubscription$.map('.reactElement'),
            },
        })
            .map(({isJourneyActive, isJourneyCompleted, journey, paymentCollapsed, payment, terms}) => {
                // When journey is not complete, we show the users just the paymentCollapsed section
                // When journey details is complete or the flow doest require journey details, we show the users
                // - credit card form +
                // - the terms +
                // - subscribe button.

                const paymentStep = isJourneyCompleted ? [payment, terms] : [paymentCollapsed];

                return isJourneyActive ? [journey, ...paymentStep] : [payment, terms];
            })
            .startWith([]);

        const latestAvailableOffer$ = bacon.mergeAll(
            prefetchedOffer$,
            offer$
        );

        const checkoutContentSections$ = latestAvailableOffer$
            .flatMapLatest(({disableSignup, disableSignupText, packages = []}) => {
                if (disableSignup) {
                    return bacon.combineTemplate({
                        packageSummaryComponent: packageSummaryWidget$.map('.reactElement'),
                    }).flatMapLatest(({packageSummaryComponent}) => {
                        return [
                            {
                                heading: '1. Your plan',
                                subNode: packages?.length > 1
                                && (
                                    <BrandedBA22EditBtn
                                        brand={brand}
                                        href={redirectOfferLinkWithParams}
                                        onClick={onChangePlanClick}
                                    >
                                        Change plan
                                    </BrandedBA22EditBtn>
                                ),
                                children: packageSummaryComponent,
                            },
                            {
                                heading: DISABLE_SIGNUP_HEADING_TEXT,
                                children: (<Paragraph brand={brand}>{disableSignupText}</Paragraph>),
                            },
                        ];
                    });
                } else {
                    return bacon.combineTemplate({
                        packageSummaryComponent: packageSummaryWidget$.map('.reactElement'),
                        userDetailsComponent: stepUserDetails$.map('.reactElement'),
                        journeyOrPaymentHeading: showJourneyOrPaymentStep$
                            .map('.0.heading')
                            .map((heading) => !!heading && `3. ${heading}`),
                        journeyOrPaymentComponent: showJourneyOrPaymentStep$
                            .map('.0.children')
                            .combine(isPaymentSkipped$,
                                (children, isSkipped) => children
                                    || (isSkipped ? null : <BrandedIC103Loading brand={brand} size="40px" />)),
                        paymentOrTermsHeading: showJourneyOrPaymentStep$
                            .map('.1.heading')
                            .map((heading) => !!heading && `4. ${heading}`),
                        paymentOrTermsComponent: showJourneyOrPaymentStep$.map('.1.children'),
                        termsComponent: showJourneyOrPaymentStep$.map('.2.children'),
                    }).flatMapLatest(({
                        packageSummaryComponent,
                        userDetailsComponent,
                        journeyOrPaymentHeading,
                        journeyOrPaymentComponent,
                        paymentOrTermsHeading,
                        paymentOrTermsComponent,
                        termsComponent,
                    }) => {
                        return [
                            {
                                heading: '1. Your plan',
                                subNode: packages?.length > 1
                                && (
                                    <BrandedBA22EditBtn
                                        brand={brand}
                                        href={redirectOfferLinkWithParams}
                                        onClick={onChangePlanClick}
                                    >
                                        Change plan
                                    </BrandedBA22EditBtn>
                                ),
                                children: packageSummaryComponent,
                            },
                            {
                                heading: '2. Your details',
                                children: userDetailsComponent,
                            },
                            {
                                key: 'journey-or-payment',
                                heading: journeyOrPaymentHeading,
                                children: journeyOrPaymentComponent,
                            },
                            {
                                key: 'payment-or-terms',
                                heading: paymentOrTermsHeading,
                                children: paymentOrTermsComponent,
                            },
                            // if we've completed a journey this will be the terms and `Start Subscription` button
                            // otherwise, it will be undefined
                            {
                                key: 'terms',
                                children: termsComponent,
                            },
                        ];
                    });
                }
            });

        const checkoutAnalytics$ = latestAvailableOffer$
            .flatMapLatest(({disableSignup}) => {
                if (disableSignup) {
                    return null;
                } else {
                    return bacon.combineTemplate({
                        trackVerification$,
                        trackPaymentDetails$,
                        logSuccessfulSubscription$,
                        trackSuccessfulPayment$,
                    });
                }
            });

        return bacon.combineTemplate({
            props: {
                preSection: stepMobile$.map('.reactElement'),
                hasDisabledSections: hasDisabledSections$,
                sections: checkoutContentSections$,
                header: headerText$,
                shouldShowCheckoutSuccess: shouldShowCheckoutSuccess$,
                userEmail: userDetails$.map('.email').startWith(undefined),
                redirectCheckoutSuccessLink: this.config.redirectCheckoutSuccessLink,
                isConfirmationEventTrackingComplete: isConfirmationEventTrackingComplete$,
                backgroundSrcsetOptions: imageUrlToSrcsetOptions({imageUrl: `${COMMON_RESOURCES_URL[resourcesEnv]}/auth0/backgrounds/${relBgSrc}`, sizes: DESKTOP_IMWIDTHS}),
                backgroundSrcsetOptionsMobile: imageUrlToSrcsetOptions({imageUrl: `${COMMON_RESOURCES_URL[resourcesEnv]}/auth0/backgrounds/${relBgSrcPortrait}`, sizes: MOBILE_IMWIDTHS}),
                resourcesEnv,
                isOptusOffer: hasOptusValues$,
                activeUserWarningModal: activeUserWarningModal$,
                warningModal: warningModal$,
                brand,
                myAccountUrl,
            },
            hydration: {
                prefetchedOffer$,
            },

            webAppHead: {
                title: `Checkout | ${getBrandDisplayName(brand)}`,
            },
            webAppJsonLd: [],

            _forcedSubscriptions: isServer()
                ? null
                : bacon.mergeAll(
                    // Redirects
                    redirectIfNotAlreadySignedUpToOptusOffer$,
                    redirectToOfferIfSettingsMissing$,
                    redirectIfNotAuthenticated$,
                    refreshUserTokenOnSteps$,
                    trackFreemiumTermsAndConditions$,

                    // Analytics
                    checkoutAnalytics$,
                    getKbotStream({platformEnv, brand}),
                )
                    .filter(false) // Don't let their events cause a re-render
                    .startWith(null),
        })
            .flatMapError(mapErrorsForFiso)
            .doAction(() => {
                hydration = {}; // eslint-disable-line no-param-reassign
            });
    }
}

export default function CheckoutWidget(settings = {}, element = null) {
    return new Checkout(settings, element);
}

Checkout.pageBoot();
