import React from 'react';
import Ic from '..';
import {blueCharcoal} from '../../../../common/palette';

const IC58Info = (args) => (
    <Ic {...args}>
        <path d="M49.245 7C25.914 7 7 25.914 7 49.245c0 23.33 18.914 42.244 42.245 42.244 23.33 0 42.244-18.913 42.244-42.244C91.482 25.917 72.573 7.008 49.245 7z" />
        {/* The `i` in the middle will have fixed color #11121A */}
        <path
            d="M53.649 68.901h-7.562V43.97h7.562zm-3.712-30.042a4.155 4.155 0 1 1 0-8.311 4.019 4.019 0 0 1 4.155 4.156 4.001 4.001 0 0 1-4.155 4.155z"
            fill={blueCharcoal}
        />
    </Ic>
);

IC58Info.displayName = 'IC58Info';

export default IC58Info;
