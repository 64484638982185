import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {Footer, Nav} from '../../../../common/normalized-styled-components';
import {flash, blueCharcoal} from '../../../../common/palette';
import {SCREEN_768_TABLET, SCREEN_1280_DESKTOP} from '../../../../common/screen-sizes';

const StyledFooter = styled(Footer)`
    margin: 0 auto;
    padding: 30px 0;
    max-width: 749px;
    color: ${blueCharcoal};
    font: var(--infinity-body-copy-6-light);

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    `}
`;

const StyledNav = styled(Nav)`
    display: grid;
    grid-template-columns: 50% 50%;
    grid-row-gap: 20px;
    margin-bottom: 35px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        display: block;
        margin-bottom: 40px;
    `}
`;

const Link = styled.a`
    margin-left: 35px;
    text-decoration: none;
    color: inherit;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        margin: 0 10px;
    `}

    &:hover {
        color: ${flash};
    }
`;

const StyledP = styled.p`
    margin: 0 35px 21px;
    text-align: center;

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        margin: 0 0 21px;
    `}
`;

const NM10Footer = ({
    legalCopy,
    links = [],
    year = new Date().getFullYear(),
    className,
    ...htmlAttributes
}) => (
    <StyledFooter
        {...htmlAttributes}
        className={classnames('NM10Footer', className)}
    >
        <StyledNav>
            {links
                .map(({title, ...attributes}) => (
                    <Link
                        key={title}
                        {...attributes}
                    >
                        {title}
                    </Link>
                ))}
        </StyledNav>
        <StyledP>A Hubbl Product</StyledP>
        <StyledP>Copyright &copy; {year} Flash. All Rights Reserved.</StyledP>
        {!!legalCopy && <StyledP>{legalCopy}</StyledP>}
    </StyledFooter>
);

NM10Footer.propTypes = {
    /** Legal blurb */
    legalCopy: propTypes.node,
    /** Links to Privacy Policy, T&Cs, Contact Us, etc */
    links: propTypes.arrayOf(propTypes.shape({
        title: propTypes.string,
        href: propTypes.string,
        attributes: propTypes.object,
    })),
    /** Copyright year */
    year: propTypes.number,
    /** Additional classname(s) */
    className: classNameType,
};

NM10Footer.displayName = 'NM10Footer';

export default NM10Footer;
