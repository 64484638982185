import React from 'react';
import Ic from '..';

const IC132LoopLoader = (args) => (
    <Ic {...args}>
        <path d="M19.08 65.267a34.702 34.702 0 0 1-3.633-12.065L7 52.335a42.696 42.696 0 0 0 5.566 18.37l6.513-5.438zm-2.186-26.394a34.286 34.286 0 0 1 5.956-11.1l-5.385-6.626A42.825 42.825 0 0 0 8.392 38.02l8.502.853zm22.308 43.533a34.296 34.296 0 0 1-11.05-5.983l-6.596 5.466a42.509 42.509 0 0 0 16.797 9.072l.849-8.555zm26.245-2.209a34.313 34.313 0 0 1-12.01 3.649l-.862 8.485a42.22 42.22 0 0 0 18.258-5.522l-5.386-6.612zm26.83-26.464c1.627-16.023-5.793-31.617-19.227-40.409a42.653 42.653 0 0 0-44.564-1.334l5.372 6.668c14.454-7.508 32.166-3.835 42.479 8.81C86.65 40.111 86.751 58.28 76.58 71.04l5.26 6.71a42.902 42.902 0 0 0 10.437-24.017z" />
        <path d="M46.744 39.083 60.98 50.14 46.744 61.199V39.083m-4.175-8.542v39.143L67.8 50.113 42.569 30.54z" />
    </Ic>
);

IC132LoopLoader.displayName = 'IC132LoopLoader';

export default IC132LoopLoader;
