import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Button} from 'normalized-styled-components';
import noop from 'lodash/noop';

import {stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';

import {transition} from '../../../../common/animations';
import {coal, kayoGreen, silverLining} from '../../../../common/deprecated-mui-palette';
import IC31Tick from '../../ic/31-tick';

const CHECKMARK_SIZE_PX = 15;
const FOCUS_BORDER_WIDTH_PX = 2;
const CHECKMARK_OFFSET_HOVER_PX = (CHECKMARK_SIZE_PX / 2) - (FOCUS_BORDER_WIDTH_PX / 2);

const StyledButton = styled(Button)`
    appearance: none;
    box-sizing: border-box;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: ${coal};
    min-width: 300px;
    height: 150px;
    color: ${silverLining};
    font: var(--ionic-body-copy-1);
    line-height: 1; /* stylelint-disable-line order/properties-order */ /* specifically overriding the --ionic property */

    &:hover,
    &:focus {
        outline: 0;
        border: ${FOCUS_BORDER_WIDTH_PX}px solid ${kayoGreen};
    }

    &[aria-pressed='true'] {
        --TR03SetTilIT-checkbox-offset: -${CHECKMARK_SIZE_PX / 2}px;

        &:hover,
        &:focus {
            border-color: ${kayoGreen};
        }
    }
`;

const Checkedmark = styled.span`
    position: absolute;
    top: var(--TR03SetTilIT-checkbox-offset, -${CHECKMARK_OFFSET_HOVER_PX}px);
    right: var(--TR03SetTilIT-checkbox-offset, -${CHECKMARK_OFFSET_HOVER_PX}px);
    transition: ${transition('transform')};
    width: ${CHECKMARK_SIZE_PX}px;
    height: ${CHECKMARK_SIZE_PX}px;

    ${stylesWhenNot('isPressed')`
        transform: scale(0);
    `}
`;

const IconColumn = styled.div`
    margin-right: 28px;
    padding-top: 27px;
    padding-bottom: 27px;
`;

const IconBox = styled.span`
    display: inline-block;
    margin-top: auto;
    margin-bottom: auto;
    width: 52px;
    height: 52px;
`;

const TextColumn = styled.div`
    width: 154px;
    text-align: left;
`;

const TileHeading = styled.h5`
    margin-top: 0;
    margin-bottom: 7px;
    font-size: 18px;
    font-weight: 600;
`;

const TileDescription = styled.p`
    margin: 0;
    color: ${silverLining};
    font: var(--ionic-body-copy-3);
`;

const TR03SetTilIT = ({
    'aria-pressed': isPressed,
    onClick = noop,
    icon,
    title,
    description,
    ...htmlAttributes
}) => (
    <StyledButton
        {...htmlAttributes}
        type="button"
        onClick={onClick}
        aria-pressed={isPressed}
    >
        <IconColumn>
            <IconBox>
                {icon}
            </IconBox>
        </IconColumn>
        <TextColumn>
            <TileHeading>
                {title}
            </TileHeading>
            <TileDescription>
                {description}
            </TileDescription>
        </TextColumn>
        <Checkedmark isPressed={isPressed}>
            <IC31Tick />
        </Checkedmark>
    </StyledButton>
);

/* eslint-disable quote-props */
TR03SetTilIT.propTypes = {
    /** aria-pressed attribute describes a toggle button */
    'aria-pressed': propTypes.oneOf([true, false, 'mixed']),
    /** Callback on element click */
    onClick: propTypes.func,
    /** IC icon to use */
    icon: propTypes.node,
    /** The heading title for the tile */
    title: propTypes.string.isRequired,
    /** The description text for the tile */
    description: propTypes.string.isRequired,
};
/* eslint-enable quote-props */

TR03SetTilIT.displayName = 'TR03SetTilIT';

export default TR03SetTilIT;
