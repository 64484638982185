/* eslint-disable arrow-body-style */
import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import noop from 'lodash/noop';
import {H1} from 'normalized-styled-components';
import {rgba} from 'polished';

import {palette as infinityPalette} from '@fsa-streamotion/streamotion-web-infinity';
import {palette as ionicPalette} from '@fsa-streamotion/streamotion-web-ionic';
import {palette as muiPalette} from '@fsa-streamotion/streamotion-web-mui';

import {stylesWhenBinge, stylesWhenFlash, stylesWhenKayo, stylesWhenLifestyle} from '../../../../utils/styles-when-brand';

import {
    BrandedLogoGraphic,
    BrandedBA01BtnPr,
    BrandedSecondaryBtn,
    BrandedOR56Modal,
} from '../../../../utils/branded-components';
import brandPropType from '../../../../common/brand-proptype';

const {blanc} = ionicPalette;
const {white} = infinityPalette;
const {silverLining} = muiPalette;

const Z_INDEX_OVER_HEADER = 1;

const LOGO_HEIGHT = {
    binge: '44px',
    kayo: '39px',
    flash: '36px',
    lifestyle: '80px',
};

/** **********************************************************************
 *                            OUTER CONTAINER
 ************************************************************************/

const ZIndexPositionContainer = styled.div`
    z-index: ${Z_INDEX_OVER_HEADER};
`;

const StyledBrandedOR56Modal = styled(BrandedOR56Modal)`
    padding-top: 63px;
    padding-bottom: 63px;
`;

/** **********************************************************************
 *                            INNER CONTAINER
 ************************************************************************/

const CenteredWrapper = styled.div`
    display: grid;
    grid-row-gap: 21px;
    justify-content: center;
    justify-items: center;
`;

/** **********************************************************************
 *                            USER GREETING
 ************************************************************************/

const ModalHeader = styled(H1)`
    text-align: center;

    ${stylesWhenBinge`
        color: ${blanc};
        font: var(--ionic-header-5);
        padding-left: 20px;
        padding-right: 20px;
    `}

    ${stylesWhenFlash`
        color: ${white};
        font: var(--infinity-header-5-bold);
        padding-left: 20px;
        padding-right: 20px;
    `}

    ${stylesWhenKayo`
        color: ${silverLining};
        font: var(--mui-header-5-bold);
    `}

    ${stylesWhenLifestyle`
        color: ${blanc};
        font: var(--nucleus-header-5);
        padding-left: 20px;
        padding-right: 20px;
    `}
`;

/** **********************************************************************
 *                            CTA BUTTONS
 ************************************************************************/

const CtaButtonContainer = styled.div`
    display: grid;
    grid-row-gap: 14px;
    align-items: center;

    ${stylesWhenBinge`
        padding-left: 20px;
        padding-right: 20px;
    `}

    ${stylesWhenFlash`
        padding-left: 20px;
        padding-right: 20px;
    `}

    ${stylesWhenLifestyle`
        padding-left: 20px;
        padding-right: 20px;
    `}
`;

const StyledBrandedSecondaryBtn = styled(BrandedSecondaryBtn)`
    ${stylesWhenFlash`
        border: 1px solid ${rgba(white, 0.1)};
    `}
`;

/** **********************************************************************
 *                            MAIN
 ************************************************************************/

const ResubscribeModal = ({
    brand,
    className,
    isModalOpen,
    userGreeting,

    existingUserCta,
    onClickExistingUserCta = noop,

    newUserCta,
    onClickNewUserCta = noop,
    newUserCtaHref,

    // just for vizard - binge
    brandLogoSrcetOptions,
}) => {
    return (
        <ZIndexPositionContainer className={classnames('ResubscribeModal', className)}>
            <StyledBrandedOR56Modal
                brand={brand}
                isVisible={isModalOpen}
                canBeDeactivated={false}
            >
                <CenteredWrapper>
                    <BrandedLogoGraphic
                        brand={brand}
                        height={LOGO_HEIGHT[brand]}
                        // kayo
                        isInternalMode={false}
                        // binge
                        width={brand === 'binge' ? null : '100%'} // workaround for safari stretched logo
                        srcsetOptions={brandLogoSrcetOptions}
                    />

                    {!!userGreeting && (
                        <ModalHeader brand={brand}>
                            {userGreeting}
                        </ModalHeader>
                    )}

                    <CtaButtonContainer brand={brand}>
                        <BrandedBA01BtnPr
                            brand={brand}
                            isBlock={brand === 'kayo'}
                            onClick={onClickExistingUserCta}
                        >
                            {existingUserCta}
                        </BrandedBA01BtnPr>

                        <StyledBrandedSecondaryBtn
                            brand={brand}
                            isBlock={brand === 'kayo'}
                            href={newUserCtaHref}
                            onClick={onClickNewUserCta}
                        >
                            {newUserCta}
                        </StyledBrandedSecondaryBtn>
                    </CtaButtonContainer>

                </CenteredWrapper>
            </StyledBrandedOR56Modal>
        </ZIndexPositionContainer>
    );
};

ResubscribeModal.propTypes = {
    /** Brand name */
    brand: brandPropType,
    /** Additional CSS classname */
    className: propTypes.string,
    /** Is the modal open? */
    isModalOpen: propTypes.bool,
    /** User greeting message */
    userGreeting: propTypes.string,
    /** CTA for existing user */
    existingUserCta: propTypes.string.isRequired,
    /** Function to call when user clicks on existing user cta button */
    onClickExistingUserCta: propTypes.func,
    /** CTA for new customer */
    newUserCta: propTypes.string.isRequired,
    /** Function to call when user clicks on new user cta button */
    onClickNewUserCta: propTypes.func,
    /** Href for new user CTA button */
    newUserCtaHref: propTypes.string,
    /** brand logo img src (just for vizard of Binge) */
    brandLogoSrcetOptions: propTypes.objectOf(propTypes.string),
};

ResubscribeModal.displayName = 'ResubscribeModal';

export default ResubscribeModal;
