import React from 'react';

import Ic from '..';

const IC178PlaylistsMarketing = (args) => (
    <Ic {...args}>
        <path d="M23.1 70h53.4v1.5H23.1zM29.7 74.1h40.4v1.5H29.7zM46.7 55.7c.1.1.2.1.4.1s.3-.1.5-.2l11-8.1c.2-.1.3-.4.3-.6 0-.2-.1-.5-.3-.6l-11-8.1c-.3-.2-.6-.2-.9-.1-.3.1-.4.4-.4.7V55c0 .3.2.6.4.7z" />
        <path d="M80 24.4H20c-.4 0-.7.3-.7.7v41.2c0 .4.3.7.7.7h60c.4 0 .7-.3.7-.7V25.1c.1-.4-.3-.7-.7-.7zm-.7 41.2H20.7V25.9h58.7v39.7z" />
    </Ic>
);

IC178PlaylistsMarketing.displayName = 'IC178PlaylistsMarketing';

export default IC178PlaylistsMarketing;
