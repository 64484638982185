import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {mediaQuery, stylesIfElse, stylesWhen} from '@fsa-streamotion/styled-component-helpers';

import {palette as infinityPalette} from '@fsa-streamotion/streamotion-web-infinity';
import {palette as ionicPalette} from '@fsa-streamotion/streamotion-web-ionic';
import {palette as muiPalette} from '@fsa-streamotion/streamotion-web-mui';
import {palette as nucleusPalette} from '@fsa-streamotion/streamotion-web-nucleus';

import {BrandedIC25ArrowR, BrandedIC31Tick} from '../../../../../utils/branded-components';
import {stylesWhenBinge, stylesWhenFlash, stylesWhenKayo, stylesWhenLifestyle} from '../../../../../utils/styles-when-brand';

import {transition} from '../../../../../common/animations';
import brandPropType from '../../../../../common/brand-proptype';
import {SCREEN_375_PHABLET, SCREEN_1280_DESKTOP} from '../../../../../common/screen-sizes';
import {visuallyHiddenBaseStyles} from '../../../../../common/visually-hidden';

const {flash, fog} = infinityPalette;
const {akela, ares} = ionicPalette;
const {coal, kayoGreen, midnight} = muiPalette;
const {smoke, lifestyle} = nucleusPalette;

const BRANDED_ICON_COLOR = {
    binge: ares,
    flash,
    kayo: kayoGreen,
    lifestyle,
};

/** ************************************************
 * LAST COLUMN: Indicates w/c package is selected
 ***************************************************/

const SelectedIndicatorCell = styled.td`
    position: relative;
    width: 21px;
    /* Force height determination by other table cells. This is so height 100%
    on the ArrowDiv works */
    height: 1px;
    vertical-align: top;
    /*
        Firefox won't respect the computed height though, reverts to 1px for children,
        but is fine with 100%, so use that here.
    */
    @-moz-document url-prefix() { /* stylelint-disable-line */
        height: 100%;
    }
    ${mediaQuery({minWidthPx: SCREEN_375_PHABLET})`
        width: 21px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        width: 34px;
    `}

    ${stylesWhenBinge`
        background-color: ${akela};
    `}

    ${stylesWhenFlash`
        background-color: ${fog};
    `}

    ${stylesWhenKayo`
        background-color: ${coal};

        ${stylesWhen(({isSelected, isDisabled}) => !!isSelected && !isDisabled)`
            background-color: ${midnight};
        `}
    `}

    ${stylesWhenLifestyle`
        background-color: ${smoke};
    `}
`;

const ArrowDiv = styled.table`
    margin: 0 auto;
    height: 100%;
`;

const ArrowWrapper = styled.td`
    width: 14px;
    height: 14px;
    vertical-align: middle;

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        width: 22px;
        height: 22px;
    `}
`;

const TickWrapper = styled.span`
    position: absolute;
    transition: ${transition('opacity')};
    opacity: 0;
    width: 23px;
    height: 23px;

    ${stylesIfElse('isSelected')`
        transform: translate(calc(50% - 3px), -50%);
        opacity: 1;

        ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
            transform: translate(60%, -50%);
            width: 30px;
            height: 30px;
        `}

        > svg {
            opacity: 1;
        }
    ``
        > svg {
            /* Stupid svg’s style="display: block" overrides me */
            opacity: 0;
        }
    `}
`;

const SelectedLabel = styled.span`
    display: none;

    ${stylesWhen('isSelected')`
        display: unset;

        ${visuallyHiddenBaseStyles}
    `}
`;

/** ********************************************
 *                  MAIN
 ***********************************************/

const SelectionIndicatorCell = ({
    brand,
    isDisabled,
    isSelected,
}) => (
    <SelectedIndicatorCell
        brand={brand}
        isDisabled={isDisabled}
        isSelected={isSelected}
    >
        <TickWrapper isSelected={isSelected}>
            <BrandedIC31Tick brand={brand} color={BRANDED_ICON_COLOR[brand]} />
        </TickWrapper>
        <SelectedLabel isSelected={isSelected}>Selected</SelectedLabel>
        <ArrowDiv>
            <tbody>
                <tr>
                    <ArrowWrapper>
                        <BrandedIC25ArrowR brand={brand} color={BRANDED_ICON_COLOR[brand]} />
                    </ArrowWrapper>
                </tr>
            </tbody>
        </ArrowDiv>
    </SelectedIndicatorCell>
);

SelectionIndicatorCell.displayName = 'SelectedPackageIndicatorCell';

SelectionIndicatorCell.propTypes = {
    brand: brandPropType,
    isDisabled: propTypes.bool,
    isSelected: propTypes.bool,
};

export default SelectionIndicatorCell;
