import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType, srcsetOptionsType} from '@fsa-streamotion/custom-prop-types';

import {rgba} from 'polished';
import {ink, mist} from '../../../../common/palette';
import {SCREEN_375_PHABLET, SCREEN_768_TABLET} from '../../../../common/screen-sizes';
import {getLargestImage} from '../../../../common/tile-utils';
import stringifySrcsetOptions from '../../../../common/stringify-srcset-options';

const StyledContainer = styled.section`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px;
    overflow: hidden;
    color: ${ink};
`;

const DeviceImageTile = styled.div`
    border-radius: 6px;
    background-color: ${rgba(mist, 0.3)};
    width: 250px;

    ${mediaQuery({minWidthPx: SCREEN_375_PHABLET})`
        width: 289px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: 348px;
    `}
`;

const DeviceImage = styled.img`
    display: block;
    width: 100%;
    object-fit: cover;
    object-position: center;
`;

const DeviceTitleLogo = styled.img`
    margin-top: 14px;
    width: 139px;

    ${mediaQuery({minWidthPx: SCREEN_375_PHABLET})`
        width: 161px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        margin-top: 22px;
        width: 196px;
    `}
`;

const SpecificationDetails = styled.ul`
    margin-top: 17px;
    margin-bottom: 0;
    padding: 0;
    width: 100%;
    list-style: none;
    font: var(--quicksilver-body-copy-4-light);

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        margin-top: 23.7px;
    `}
`;

const SpecificationItem = styled.li`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
`;

const SpecificationLabel = styled.label`
    white-space: nowrap;
    font-weight: normal;
`;

/**
 * OR144HardwareSpecs
 *  - Hardware details
 *
 * @see {@link https://zpl.io/GEgPM6r}
 */
const OR144HardwareSpecs = ({
    className,
    srcsetSizes,
    deviceImageAltText,
    deviceSrcsetOptions,
    logoImageAltText,
    logoSrcsetOptions,
    modelName,
    serialNumber,
    details,
    ...htmlAttributes
}) => {
    const deviceSpecs = {
        'Model': modelName,
        'Serial Number': serialNumber,
        'Details': details,
    };

    return (
        <StyledContainer
            {...htmlAttributes}
            className={classnames('OR144HardwareSpecs', className)}
        >
            {!!deviceSrcsetOptions && (
                <DeviceImageTile>
                    <DeviceImage
                        alt={deviceImageAltText}
                        src={getLargestImage(deviceSrcsetOptions)}
                        srcSet={stringifySrcsetOptions(deviceSrcsetOptions)}
                        sizes={srcsetSizes}
                    />
                </DeviceImageTile>
            )}

            {!!logoSrcsetOptions && (
                <DeviceTitleLogo
                    alt={logoImageAltText}
                    src={getLargestImage(logoSrcsetOptions)}
                    srcSet={stringifySrcsetOptions(logoSrcsetOptions)}
                    sizes={srcsetSizes}
                />
            )}

            <SpecificationDetails>
                {Object.entries(deviceSpecs).map(([label, value]) => (
                    <SpecificationItem key={label}>
                        <SpecificationLabel>{label}:&nbsp;</SpecificationLabel> {value}
                    </SpecificationItem>
                ))}
            </SpecificationDetails>
        </StyledContainer>
    );
};

OR144HardwareSpecs.propTypes = {
    /** Additional class name(s) */
    className: classNameType,
    /** Srcset sizes */
    srcsetSizes: propTypes.string,
    /** Alt text to use for the device image */
    deviceImageAltText: propTypes.string,
    /** Device srcset options */
    deviceSrcsetOptions: srcsetOptionsType,
    /** Alt text to use for the logo image */
    logoImageAltText: propTypes.string,
    /** Logo srcset options */
    logoSrcsetOptions: srcsetOptionsType,
    /** Device model name, e.g., "55" Glass TV" */
    modelName: propTypes.string,
    /** Device serial number */
    serialNumber: propTypes.string.isRequired,
    /** Details of the hardware specs */
    details: propTypes.node,
};

OR144HardwareSpecs.displayName = 'OR144HardwareSpecs';

export default OR144HardwareSpecs;
