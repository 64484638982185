import React from 'react';
import propTypes from 'prop-types';
import noop from 'lodash/noop';
import styled from 'styled-components';

import OR52PayDtls from '../../../organisms/or/52-pay-dtls';
import IM06ConfirmBox from '../../../molecules/im/06-confirm-box';
import {white} from '../../../../common/palette';
import TM27InfoTile from '../../../molecules/tm/27-info-tile';
import {IC61Ios} from '../../../atoms/ic';

const Byline = styled.p`
    text-align: center;
    color: ${white};
    font: var(--mui-header-7);
`;

const Paragraph = styled.p`
    margin-top: 0;
    text-align: start;
`;

const List = styled.ul`
    margin-bottom: 0;
    padding-left: 18px;
    text-align: start;
`;

export default class UpdatePaymentTemplate extends React.Component {
    static displayName = 'UpdatePaymentTemplate';

    static propTypes = {
        paymentErrorMessages: propTypes.arrayOf(propTypes.node),
        ccQueryText: propTypes.string,
        hasTelstraTBill: propTypes.bool,
        hasPayPal: propTypes.bool,
        confirmButtonText: propTypes.string,
        onDidMount: propTypes.func,
        onHostedPaymentPageLoaded: propTypes.func,
        termsAndConditions: propTypes.node,
        onPaymentChange: propTypes.func,
        onCheckboxChange: propTypes.func,
        onSubmit: propTypes.func,
        showTermsCheckbox: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
        isPaymentLoading: propTypes.bool,
        isItunesSubscription: propTypes.bool,
        itunesSubscriptionTitle: propTypes.node,
        itunesSubscriptionMessage: propTypes.node,
    };

    static defaultProps = {
        onDidMount: noop,
        hasTelstraTBill: false,
        hasPayPal: true,
        termsAndConditions: null,
        showTermsCheckbox: false,
        paymentErrorMessages: [],
    };

    componentDidMount() {
        this.props.onDidMount();
    }

    render() {
        const {
            paymentErrorMessages,
            ccQueryText,
            confirmButtonText,
            termsAndConditions,
            onSubmit,
            hasTelstraTBill,
            hasPayPal,
            onCheckboxChange,
            onPaymentChange,
            showTermsCheckbox,
            isPaymentLoading,
            onHostedPaymentPageLoaded,
            isItunesSubscription,
            itunesSubscriptionTitle,
            itunesSubscriptionMessage,
        } = this.props;

        if (isItunesSubscription) {
            return (
                <TM27InfoTile
                    icon={<IC61Ios size="43px" />}
                    title={itunesSubscriptionTitle}
                >
                    {itunesSubscriptionMessage}
                </TM27InfoTile>
            );
        }

        return (
            <React.Fragment>
                <Byline>
                    Updating your credit card here replaces your existing credit card across all of your Hubbl subscriptions.
                </Byline>
                <OR52PayDtls
                    {...{
                        ccQueryText,
                        hasTelstraTBill,
                        hasPayPal,
                        onPaymentChange,
                        onHostedPaymentPageLoaded,
                    }}
                />
                <IM06ConfirmBox
                    buttonText={confirmButtonText}
                    onConfirmClick={onSubmit}
                    disabled={isPaymentLoading}
                    hasCheckbox={showTermsCheckbox}
                    errorMessage={(
                        paymentErrorMessages.length === 0 // eslint-disable-line no-nested-ternary
                            ? null
                            : paymentErrorMessages.length === 1
                                ? paymentErrorMessages[0]
                                : (
                                    <React.Fragment>
                                        <Paragraph>
                                            Please fix the following errors and try again:
                                        </Paragraph>
                                        <List>
                                            {paymentErrorMessages.map((paymentErrorMessage) => (
                                                <li key={paymentErrorMessage}>
                                                    {paymentErrorMessage}
                                                </li>
                                            ))}
                                        </List>
                                    </React.Fragment>
                                )
                    )}
                    {...{
                        termsAndConditions,
                        onCheckboxChange,
                    }}
                />
            </React.Fragment>
        );
    }
}
