import React from 'react';
import propTypes from 'prop-types';
import {Section, Fieldset} from 'normalized-styled-components';
import styled from 'styled-components';
import {rgba} from 'polished';
import classnames from 'classnames';

import {mediaQuery, stylesWhen, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {black, white} from '../../../../common/palette';
import {SCREEN_REALLY_LG_DESKTOP, SCREEN_DESKTOP, SCREEN_PRETTY_LG_DESKTOP} from '../../../../common/screen-sizes';
import {XS_HERO_FIXED_HEIGHT_PX, XL_HERO_FIXED_HEIGHT_PX} from '../../../../common/style-constants';

import BA12BtnPost from '../../../atoms/ba/12-btn-post';
import GA01ProBar from '../../../atoms/ga/01-pro-bar';

import ScalingTitle from './scaling-title';
import ActionWrapper from './components/action-wrapper';

const ABOVE_DROPSHADOW_ZINDEX = 1;
const TOTAL_BUTTON_INDEX = 2; // 0 is first index, so an index of 2 == 3 buttons

const StyledSection = styled(Section)`
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;
    transition: ${transition('opacity')};
    opacity: ${({isVisible}) => isVisible ? 1 : 0};
    max-height: ${XS_HERO_FIXED_HEIGHT_PX}px;
    color: ${white};
    font: var(--mui-body-copy-1);

    ${stylesWhenNot('isCondensed')`
        ${mediaQuery({minWidthPx: SCREEN_REALLY_LG_DESKTOP})`
            max-height: ${XL_HERO_FIXED_HEIGHT_PX}px;
        `}
    `}
`;

const ButtonRow = styled(Fieldset)`
    margin: 7px 0 0;
    border: 0;
    padding: 0;
    min-width: 0;

    ${stylesWhen('isVisible')`
        > * {
            margin-bottom: 7px;

            &:not(:last-child) {
                margin-right: 7px; /* If they wrap, show some space between them */
            }

            &:nth-child(1) {
                position: relative;
                z-index: ${ABOVE_DROPSHADOW_ZINDEX};
            }
        }
    `}
`;

const CopyArea = styled.header`
    max-width: 460px;

    ${stylesWhen('hasDescription')`
        margin-bottom: 14px;
    `}

    ${stylesWhen('hasLinearProvider')`
        margin-top: 2px;
    `}
`;

const HeroHeader = styled.h5`
    transform: translate3d(0, 0, 0); /* Promote to own composite layer with translate3d to prevent flickering */
    transition: text-indent 300ms ease-out, opacity 100ms ease-in;
    opacity: ${({isVisible}) => isVisible ? 1 : 0};
    margin: 0;
    overflow: hidden;
    text-indent: ${({isVisible}) => isVisible ? '0%' : '-100%'};
    text-transform: uppercase;
    text-shadow: 1px 1px 0 ${rgba(black, 0.5)};
    line-height: 28px;
    font-size: 18px;
    font-weight: 300;

    ${stylesWhenNot('isCondensed')`
        ${mediaQuery({minWidthPx: SCREEN_DESKTOP})`
            font-size: 24px;
        `}
    `}
`;

const Synopsis = styled.p`
    /* Promote to own composite layer with translate3d to prevent flickering */
    transform: translate3d(0, 0, 0);
    transition: opacity 100ms 200ms ease-in;
    opacity: ${({isVisible}) => isVisible ? 1 : 0};
    margin: 0 0 7px;
    max-width: 460px;
    text-shadow: 1px 1px 0 ${rgba(black, 0.5)};
    line-height: 24px;
    font-size: 18px;
    font-weight: 300;

    ${stylesWhenNot('isCondensed')`
        ${mediaQuery({minWidthPx: SCREEN_DESKTOP})`
            line-height: 28px;
            font-size: 20px;
        `}
    `}
`;

const ChannelAndFreemiumStatus = styled.span`
    display: grid;
    grid-template-columns: 1fr auto;
    grid-column-gap: 11px;
    align-items: flex-end;
    min-height: 45px;
`;

/** Progress bar for resume button */
const StyledGA01ProBar = styled(GA01ProBar)`
    position: absolute;
    bottom: -4px;
    left: 0;
    transform: translateY(100%);
    border-radius: 2px;
    background-color: ${rgba(white, 0.2)};
    width: 100%;
    height: 3px;

    /* stylelint-disable-next-line selector-max-empty-lines */
    ${mediaQuery({minWidthPx: SCREEN_PRETTY_LG_DESKTOP})`
        bottom: -7px;
        height: 5px;
    `}

    ::-webkit-progress-bar {
        border-radius: 2px;
    }

    ::-webkit-progress-value {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
    }
`;

export default class TM04Ft extends React.Component {
    static displayName = 'TM04Ft';

    static propTypes = {
        className: classNameType,
        /** Array of buttons to place on the slide - maximum of only 3 will be displayed */
        actions: propTypes.arrayOf(
            propTypes.shape({
                isHiddenOnMobile: propTypes.bool, // used for splitview button
                label: propTypes.node,
                onClick: propTypes.func,
                progress: propTypes.shape({ // for resume button
                    current: propTypes.number,
                    total: propTypes.number,
                }),
            })
        ),
        /** Video Description, e.g. Patty Mills vs Mathew Delladova, impact on the court, who has more? */
        description: propTypes.string,
        /** Time Label to display, e.g. MONDAY 7:30PM EST */
        heroHeader: propTypes.string,
        /** Title of the video, e.g. Mills v Delladova */
        title: propTypes.string,
        /** Is the slide visible, e.g. toggle to animate */
        isVisible: propTypes.bool,
        /** Should the slide be condensed to its smallest form, e.g. for HUD display */
        isCondensed: propTypes.bool,
        /** Used for linear provider images, e.g. ESPN logo */
        linearProvider: propTypes.node,
        /** Inline label, next to the linear provider logo */
        label: propTypes.node,
    };

    static defaultProps = {
        actions: [],
        isVisible: true,
    };

    state = {
        isVisible: this.props.isVisible,
        visibleButtonIndex: this.props.isVisible ? TOTAL_BUTTON_INDEX : -1,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!nextProps.isVisible === !prevState.isVisible) {
            return null;
        } else {
            return {
                isVisible: nextProps.isVisible,
                visibleButtonIndex: -1,
            };
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.isVisible && this.props.isVisible) {
            clearTimeout(this.clearRevealButtonsSequentially);
            this.clearRevealButtonsSequentially = this.revealButtonsSequentially(300, TOTAL_BUTTON_INDEX);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.clearRevealButtonsSequentially);
    }

    revealButtonsSequentially(delayMs, buttonIndex) {
        return setTimeout(() => {
            if (this.state.visibleButtonIndex >= buttonIndex) {
                clearTimeout(this.clearRevealButtonsSequentially);
            } else {
                this.setState((prevState) => ({visibleButtonIndex: prevState.visibleButtonIndex + 1}));
                this.clearRevealButtonsSequentially = this.revealButtonsSequentially(delayMs, buttonIndex);
            }
        }, delayMs);
    }

    render() {
        const createButtons = () => this.props.actions
            .slice(0, 3) // currently just limiting to 3 buttons - similar to old behavior!
            .map(({label, onClick, href, isHiddenOnMobile, disabled, progress, ...htmlAttributes}, index) => (
                <ActionWrapper
                    key={label}
                    isHiddenOnMobile={isHiddenOnMobile}
                    actionButton={(
                        <BA12BtnPost
                            {...htmlAttributes}
                            key={label}
                            href={href}
                            onClick={onClick}
                            disabled={disabled}
                            isHiddenOnMobile={isHiddenOnMobile}
                            isVisible={this.state.visibleButtonIndex >= index}
                            tabIndex={this.props.isVisible ? 0 : -1}
                        >
                            {label}
                        </BA12BtnPost>
                    )}
                    progressBar={(!!progress?.current && (
                        <StyledGA01ProBar
                            current={progress.current}
                            total={progress.total}
                        />
                    ))}

                />

            ));

        return (
            <StyledSection
                className={classnames('TM04Ft', this.props.className)}
                isVisible={this.props.isVisible}
                disabled={!this.props.isVisible}
                isCondensed={this.props.isCondensed}
            >
                {this.props.label
                    ? (
                        <ChannelAndFreemiumStatus>
                            {this.props.linearProvider}
                            {this.props.label}
                        </ChannelAndFreemiumStatus>
                    )
                    : this.props.linearProvider}

                <CopyArea hasDescription={!this.props.description} hasLinearProvider={!!this.props.linearProvider}>
                    {!!this.props.heroHeader && (
                        <HeroHeader isVisible={this.props.isVisible} isCondensed={this.props.isCondensed}>
                            {this.props.heroHeader}
                        </HeroHeader>
                    )}
                    <ScalingTitle isVisible={this.props.isVisible} isCondensed={this.props.isCondensed}>{this.props.title}</ScalingTitle>
                </CopyArea>

                {!!this.props.description && (
                    <Synopsis isVisible={this.props.isVisible} isCondensed={this.props.isCondensed}>{this.props.description}</Synopsis>
                )}

                <ButtonRow isVisible={this.props.isVisible}>
                    {createButtons()}
                </ButtonRow>
            </StyledSection>
        );
    }
}
