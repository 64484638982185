import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import classnames from 'classnames';
import {rgba} from 'polished';
import noop from 'lodash/noop';

import {colorType, srcsetOptionsType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {ironStone} from '../../../../common/palette';
import H2HPercentageSection from './sections/h2h-percentage';
import SponsorSection from './sections/sponsor';
import MarketsSection from './sections/markets';
import LinkSection from './sections/link';

const Card = styled.div`
    box-sizing: border-box;
    display: grid;
    grid-row-gap: 7px;
    align-content: start;
    background-color: ${rgba(ironStone, 0.7)};
    padding: 35px 14px;
    height: 100%;
`;

const OR122MktBlock = ({
    sections = [],
    getTeamColor = noop,
    teamADefaultColor,
    teamBDefaultColor,
    imageUrlToSrcsetOptions,
    onClickOdds = noop,
    onClickLink,
    className,
    ...htmlAttributes
}) => {
    function getSectionComponent(section, index) {
        const key = `[${index}]${section.type}`;

        switch (section.type) {
            case 'h2h-percentage':
                return (
                    <H2HPercentageSection
                        key={key}
                        section={section}
                        getTeamColor={getTeamColor}
                        teamADefaultColor={teamADefaultColor}
                        teamBDefaultColor={teamBDefaultColor}
                    />
                );

            case 'sponsor':
                return (<SponsorSection key={key} section={section} logoSrcsetOptions={imageUrlToSrcsetOptions[section.image]} />);

            case 'markets':
                return (<MarketsSection key={key} section={section} onClickOdds={onClickOdds} />);

            case 'link':
                return (<LinkSection key={key} section={section} onClickLink={onClickLink} />);

            default:
                if (process.env.NODE_ENV !== 'production') {
                    console.warn(`MUI: Unrecognised section type: ${section.type} for OR122MktBlock.`);
                }

                return null;
        }
    }

    return (
        <Card {...htmlAttributes} className={classnames(className, 'OR122MktBlock')}>
            {sections.map((section, index) => getSectionComponent(section, index))}
        </Card>
    );
};

OR122MktBlock.displayName = 'OR122MktBlock';

OR122MktBlock.propTypes = {
    /** Array of objects where each object represents a section */
    sections: propTypes.arrayOf(propTypes.shape({
        type: propTypes.oneOf(['h2h-percentage', 'sponsor', 'markets', 'link']),
        image: propTypes.string,
    })),
    /** Function that returns a color given a sport and team ID. Accepts an object of {sport: 'my-sport', id: 'my-team-id', fallbackColor: 'my-fallback-color'} */
    getTeamColor: propTypes.func,
    /** Color to use for team A if unable to retrieve a color */
    teamADefaultColor: colorType,
    /** Color to use for team B if unable to retrieve a color */
    teamBDefaultColor: colorType,
    /** Object where the key is the image URL and the value is a srcsetOptions object. This approach is taken because we may have multiple sponsor sections with different srcsets. */
    imageUrlToSrcsetOptions: propTypes.objectOf(srcsetOptionsType),
    /** Click handler for odds links */
    onClickOdds: propTypes.func,
    /** Click handler for Go To Markets link */
    onClickLink: propTypes.func,
    /** Optional extra class name(s) for the component */
    className: classNameType,
};

OR122MktBlock.displayName = 'OR122MktBlock';

export default OR122MktBlock;
