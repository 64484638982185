/* eslint-disable max-len */

import React from 'react';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import Ic from '../../ic';

// @TODO: Update when final SVGs are provided - using Flash ones for now

/**
 * Visa Card Logo
 * - Used in My Account
 * - https://zpl.io/jZeqOrA
 */
const GA86Visa = ({
    className,
    ...htmlAttributes
}) => (
    <Ic
        {...htmlAttributes}
        className={classnames('GA86Visa', className)}
        ariaLabel="Visa"
        preserveColors={true}
    >
        <g fill="none" fillRule="evenodd">
            <rect fill="#FAFAFA" width="99.86" height="81.9" y="8.85" rx="2" />
            <g fillRule="nonzero" transform="translate(0, 10)">
                <path
                    fill="#00579F"
                    d="M43.5 53.13h-6.637l4.15-25.665h6.637zM67.556 28.093C66.247 27.573 64.171 27 61.604 27c-6.553 0-11.168 3.495-11.197 8.491-.054 3.686 3.305 5.734 5.817 6.963 2.567 1.256 3.44 2.076 3.44 3.195-.026 1.72-2.075 2.512-3.986 2.512-2.649 0-4.069-.409-6.226-1.365l-.874-.41-.929 5.761c1.557.71 4.424 1.338 7.402 1.366 6.963 0 11.496-3.44 11.55-8.765.026-2.922-1.747-5.16-5.57-6.99-2.322-1.174-3.743-1.966-3.743-3.167.027-1.093 1.202-2.211 3.822-2.211 2.158-.055 3.743.463 4.944.982l.6.273.902-5.542zM76.377 44.038c.546-1.474 2.649-7.18 2.649-7.18-.028.054.545-1.502.873-2.458l.464 2.212s1.257 6.143 1.53 7.426h-5.516zm8.191-16.573h-5.133c-1.583 0-2.786.464-3.469 2.13L66.11 53.13h6.963l1.393-3.85h8.52c.191.901.792 3.85.792 3.85h6.145l-5.354-25.665zM31.32 27.465l-6.499 17.502-.71-3.55c-1.201-4.096-4.97-8.545-9.175-10.758l5.953 22.444h7.018l10.43-25.638H31.32z"
                />
                <path
                    d="M18.786 27.465H8.11L8 27.984c8.329 2.13 13.845 7.263 16.111 13.434L21.79 29.623c-.382-1.64-1.556-2.104-3.004-2.158z"
                    fill="#FAA61A"
                />
            </g>
        </g>
    </Ic>
);

GA86Visa.propTypes = {
    /** Additional class(es) */
    className: classNameType,
};

GA86Visa.displayName = 'GA86Visa';

export default GA86Visa;
