import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {SCREEN_DESKTOP} from '../../../../common/screen-sizes';

import {black} from '../../../../common/palette';

const scalingFontSize = function (titleLength, breakpoint) {
    if (titleLength < 18) {
        return breakpoint === 'mobile' ? 56 : 76;
    } else if (titleLength < 26) {
        return breakpoint === 'mobile' ? 48 : 66;
    } else {
        return breakpoint === 'mobile' ? 38 : 56;
    }
};

const Title = styled.h3`
    transition: opacity 100ms 100ms ease-in;
    opacity: ${({isVisible}) => isVisible ? 1 : 0};
    margin: 0;
    text-transform: uppercase;
    text-shadow: 1px 1px 0 ${rgba(black, 0.5)};
    line-height: 0.92;
    font-size: ${({titleLength, isCondensed}) => isCondensed ? 38 : scalingFontSize(titleLength, 'mobile')}px;
    font-weight: 600;

    ${mediaQuery({minWidthPx: SCREEN_DESKTOP})`
        font-size: ${({titleLength, isCondensed}) => isCondensed ? 38 : scalingFontSize(titleLength, 'desktop')}px;
    `}
`;

const ScalingTitle = ({
    children = '',
    isVisible,
    isCondensed,
}) => (
    <Title titleLength={children.length} isVisible={isVisible} isCondensed={isCondensed}>
        {children}
    </Title>
);

ScalingTitle.displayName = 'ScalingTitle';

ScalingTitle.propTypes = {
    children: propTypes.string,
    isVisible: propTypes.bool,
    isCondensed: propTypes.bool,
};

export default ScalingTitle;
