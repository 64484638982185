import React from 'react';

import Ic from '..';

const IC266ClosedCaptionsOff = (args) => (
    <Ic {...args}>
        <path d="M67.305 22L46.463 43.697l1.3936154-3.7375882c-.774-.7570589-3.6318462-3.6105883-10.8955385-3.6105883-7.74 0-15.2418461 5.5905883-15.2418461 14.85 0 5.3888234 2.4914869 9.4393197 6.1348846 11.8707199L12.55 79H7V22h60.305zM93 22v57H34.139l18.4900598-19.5359095c2.7847611 4.0319825 7.6047713 6.2353213 12.6723248 6.2353213 7.6804616 0 10.6573846-3.2611765 11.2527692-3.7852942L74.4107692 56.44S71.672 59.41 66.7303077 59.41c-4.1089459 0-7.3123058-2.335198-8.2752482-6.101707l9.9904561-10.5555526c3.1630822.5274148 5.0468368 2.1218914 5.7866382 2.7972596L76.316 39.9594118c-.3991168-.3903811-1.3523282-1.3382134-3.1780094-2.1647899L88.085 22H93zM37.8541538 42.58c3.7104679 0 6.1054468 1.4877419 7.3215734 2.4585371L32.9481123 57.7675887c-2.0132011-1.4824206-3.2507277-3.892728-3.2507277-6.9181769 0-5.1829412 3.6318462-8.2694118 8.1567692-8.2694118z" />
        <path d="M86.04834384 10.06607558L11.0000006 89.1700104 13.9915078 92 88.923122 13.01910215z" />
    </Ic>
);

IC266ClosedCaptionsOff.displayName = 'IC266ClosedCaptionsOff';

export default IC266ClosedCaptionsOff;
