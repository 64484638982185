import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import {rgba} from 'polished';
import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {ink} from '../../../../common/palette';
import {SCREEN_768_TABLET, SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';

const Container = styled.div`
    display: grid;
    grid-row-gap: 0;
    justify-content: center;
`;

// Copy Section

const CopyLinesWrapper = styled.div`
    display: grid;
    grid-row-gap: 23px;
    padding-bottom: 25px;
    text-align: center;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        grid-row-gap: 35px;
    `}
`;

const CopyLine = styled.p`
    margin: 0;
    color: ${rgba(ink, 0.7)};
    font: var(--quicksilver-body-copy-3-light);
`;

// Buttons Section

const MyAccountCtasLabel = styled.p`
    margin: 0 0 21px;
    text-align: center;
    color: ${ink};
    font: var(--quicksilver-body-copy-3-medium);
`;

const ButtonWrapper = styled.div`
    display: grid;
    grid-row-gap: 7px;
    justify-items: center;
    margin: 0 auto;
    min-width: 250px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        min-width: 320px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        min-width: 400px;
        grid-row-gap: 14px;
    `}
`;

/**
 * Common component used in My Account
 * - used for sub pages that display some copy & CTA buttons
 */
const MyAccountCtas = ({
    children,
    className,
    copyLines = [],
    label,
    ...htmlAttributes
}) => (
    <Container {...htmlAttributes} className={classnames('MyAccountCtas', className)}>
        {!isEmpty(copyLines) && (
            <CopyLinesWrapper>
                {copyLines.map((copyLine, index) => (
                    <CopyLine key={index}>{copyLine}</CopyLine>
                ))}
            </CopyLinesWrapper>
        )}
        {!!label && <MyAccountCtasLabel>{label}</MyAccountCtasLabel>}
        <ButtonWrapper>
            {children}
        </ButtonWrapper>
    </Container>
);

MyAccountCtas.displayName = 'MyAccountCtas';

MyAccountCtas.propTypes = {
    /** Usually array of buttons */
    children: propTypes.node,
    /** Additional class(es) */
    className: classNameType,
    /** Copy texts to display above the buttons section */
    copyLines: propTypes.arrayOf(propTypes.string),
    /** Label for the buttons section */
    label: propTypes.node,
};

export default MyAccountCtas;
