import {getApiFromRetryWithError} from '@fsa-streamotion/request-manager';
import {getResourcesUrlBasePath} from '@fsa-streamotion/streamotion-web-widgets-common';

import stringableBaconError from '../../../utils/stringable-bacon-error';

import {responseConsideredValidWith404} from '../../response-considered-valid';

export default function getMarketingConfigStream({
    resourcesEnv,
    brand,
    marketingConfigName,
    // used primarily to disable retries while testing
    retries,
}) {
    const url = `${getResourcesUrlBasePath({resourcesEnv, brand})}/marketing/offer/${marketingConfigName}.json`;

    return getApiFromRetryWithError({
        url,
        validateResponse: responseConsideredValidWith404,
        retries,
    })
        .first() // Force it to close, as getApiFromRetryWithError doesn't close by default
        .flatMapLatest((response) => {
            // 404 and 200s are responseConsideredValid === true, parsing errors are not
            if (response.responseConsideredValid && response?.superAgentResponse?.body) {
                return response?.superAgentResponse?.body;
            } else if (response?.superAgentErr?.rawResponse) {
                // superagents error doesn't have any info about parsing errors when running in a node context
                // attempting to access it will just print a stack trace/won't throw, so to differentiate invalid JSON here just
                // attempt to parse the raw response
                try {
                    JSON.parse(response?.superAgentErr?.rawResponse);
                } catch (e) {
                    return stringableBaconError({
                        type: 'syntax-error',
                        message: 'The offer page configuration is not valid',
                        debugMessage: `Widgets utils - getMarketingConfigStream: error while parsing marketing config (${url})`,
                    });
                }
            } else {
                return stringableBaconError({
                    type: 'superagent-error',
                    statusCode: response.responseStatus,
                    message: 'The offer page configuration could not be found',
                    debugMessage: `Widgets utils - getMarketingConfigStream: couldn't get marketing content (${url}): ${response.responseStatus} ${response.superAgentErr}`,
                });
            }
        });
}
