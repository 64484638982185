import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {SCREEN_1680_DESKTOP, SCREEN_1400_DESKTOP} from '../../../../../common/screen-sizes';

import BA01BtnPr from '../../../../atoms/ba/01-btn-pr';

const SignUpCtaRow = styled.div`
    display: flex;
    flex-direction: column;
    grid-row-gap: 14px;
    align-items: center;
    justify-content: space-around;
    padding: 56px 53px 29px;

    ${mediaQuery({minWidthPx: SCREEN_1400_DESKTOP})`
        display: none;
    `} //hide on desktop as sign up and sign in visible in OR33HeaderDsk
`;

const CtaButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 15px;
`;

const StyledBA01BtnPr = styled(BA01BtnPr)`
    padding: 7px 21px;
    min-width: 110px;
    font-size: 14px;

    ${mediaQuery({minWidthPx: SCREEN_1680_DESKTOP})`
        height: 40px;
    `}
`;

const SignUpHeaderRow = ({
    children,
    signUpCtaCopy,
    signInCtaCopy,
    onSignUpClick,
    onSignInClick,
}) => (
    <SignUpCtaRow>
        {children}
        <CtaButtonsWrapper>
            <StyledBA01BtnPr isBlock={false} onClick={onSignUpClick}>{signUpCtaCopy}</StyledBA01BtnPr>
            <StyledBA01BtnPr isBlock={false} onClick={onSignInClick}>{signInCtaCopy}</StyledBA01BtnPr>
        </CtaButtonsWrapper>
    </SignUpCtaRow>
);

SignUpHeaderRow.propTypes = {
    /** Shows Signup CTA label for new users */
    children: propTypes.node,
    /** Sign In CTA copy */
    signInCtaCopy: propTypes.string,
    /** Sign Up CTA copy */
    signUpCtaCopy: propTypes.string,
    /** Action on clicking sign-in */
    onSignUpClick: propTypes.func,
    /** Action on clicking sign-in */
    onSignInClick: propTypes.func,
};

SignUpHeaderRow.displayName = 'SignUpHeaderRow';

export default SignUpHeaderRow;
