import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import get from 'lodash/get';
import invoke from 'lodash/invoke';
import range from 'lodash/range';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {coal, midnight, silverLining} from '../../../../common/palette';
import IC135Cross from '../../../atoms/ic/135-cross';
import IGM28HudBtn from '../28-hud-btn';

const TabList = styled.div.attrs({
    'role': 'tablist',
    'aria-label': 'Heads Up Display',
})`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-image: linear-gradient(to left, ${midnight}, ${midnight} 89%, ${midnight}), linear-gradient(to bottom, ${coal}, ${coal});
    width: 77px;
    height: 100%;
`;

const ButtonSet = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 96px;
`;

class IGM02HudContSelect extends React.Component {
    static displayName = 'IGM02HudContSelect';

    static propTypes = {
        /** Optional extra class names for the component */
        className: classNameType,
        /** A click handler e.g. for closing the HUD */
        onCloseClick: propTypes.func,
        /** Buttons appearing in the middle of the tab list, e.g. Synopsis, Summary */
        middleButtons: propTypes.arrayOf(propTypes.node),
        /** Buttons appearing in the bottom of the tab list, e.g. notifications*/
        lowerButtons: propTypes.arrayOf(propTypes.node),
        /** Whether or not this element is visible, affecting this element's tab accessibility */
        isInteractive: propTypes.bool,
    };

    static defaultProps = {
        middleButtons: [],
        lowerButtons: [],
        isInteractive: true,
    };

    state = {
        focusedButtonIndex: null,
    };

    static getDerivedStateFromProps(props, state) {
        const numButtons = get(props, 'middleButtons.length', 0) + get(props, 'lowerButtons.length', 0);

        return numButtons === get(state, 'buttonRefs.length') ? null : {
            buttonRefs: range(numButtons).map(React.createRef),
        };
    }

    setFocusedButtonIndex = (newIndex) => {
        this.setState(() => ({focusedButtonIndex: newIndex}));
    };

    focusSpecificButton = (index) => {
        invoke(this.state.buttonRefs, [index, 'current', 'focus']);
    };

    focusNextButton = () => {
        const totalButtons = get(this.props, 'middleButtons.length', 0) + get(this.props, 'lowerButtons.length', 0);

        this.focusSpecificButton((totalButtons + (this.state.focusedButtonIndex || 0) + 1) % totalButtons);
    };

    focusPrevButton = () => {
        const totalButtons = get(this.props, 'middleButtons.length', 0) + get(this.props, 'lowerButtons.length', 0);

        this.focusSpecificButton((totalButtons + (this.state.focusedButtonIndex || 0) - 1) % totalButtons);
    };

    handleKeyDown = (e) => {
        switch (e.key) {
            case 'ArrowLeft':
            case 'ArrowUp':
                this.focusPrevButton();
                e.preventDefault();
                break;
            case 'ArrowRight':
            case 'ArrowDown':
                this.focusNextButton();
                e.preventDefault();
                break;
            case 'Home':
                this.focusSpecificButton(0);
                e.preventDefault();
                break;
            case 'End':
                this.focusSpecificButton(get(this.props, 'middleButtons.length', 0) + get(this.props, 'lowerButtons.length', 0) - 1);
                e.preventDefault();
                break;
            case 'Escape':
                invoke(this.props, 'onCloseClick', e);
                break;
            default:
                break;
        }
    };

    render() {
        const {
            onCloseClick,
            middleButtons,
            lowerButtons,
            className,
            isInteractive,
        } = this.props;

        const numMiddleButtons = get(this.props, 'middleButtons.length', 0);

        return (
            <TabList className={classnames('IGM02HudContSelect', className)}>
                <ButtonSet>
                    <IGM28HudBtn
                        aria-label="Close Heads Up Display"
                        onClick={onCloseClick}
                        tabIndex={isInteractive ? 0 : -1}
                    >
                        <IC135Cross color={silverLining} />
                    </IGM28HudBtn>
                </ButtonSet>
                <ButtonSet onKeyDown={this.handleKeyDown}>
                    {middleButtons.map((button, index) => React.cloneElement(
                        button,
                        {
                            onFocus: (e) => {
                                this.setFocusedButtonIndex(index);
                                invoke(button, 'props.onFocus', e);
                            },
                            ref: this.state.buttonRefs[index],
                            tabIndex: isInteractive ? 0 : -1,
                        }
                    ))}
                </ButtonSet>
                <ButtonSet onKeyDown={this.handleKeyDown}>
                    {lowerButtons.map((button, index) => React.cloneElement(
                        button,
                        {
                            onFocus: (e) => {
                                this.setFocusedButtonIndex(index + numMiddleButtons);
                                invoke(button, 'props.onFocus', e);
                            },
                            ref: this.state.buttonRefs[index + numMiddleButtons],
                            tabIndex: isInteractive ? 0 : -1,
                        }
                    ))}
                </ButtonSet>
            </TabList>
        );
    }
}

export default IGM02HudContSelect;
