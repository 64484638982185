import React from 'react';

import Ic from '..';

const IC259BingeCentres = (args) => (
    <Ic {...args}>
        <path d="M76.958 33.062H52.492l6.072-9.252c.416-.716.207-1.637-.476-2.097-.683-.46-1.602-.3-2.093.365l-5.983 9.12-6.182-9.427c-.29-.5-.83-.796-1.402-.77-.572.027-1.082.371-1.327.896-.245.524-.183 1.142.16 1.607l6.272 9.558H22.86c-2.129 0-3.856 1.746-3.86 3.903v30.491c0 1.037.407 2.032 1.13 2.765.725.733 1.706 1.145 2.73 1.145h25.504v4.689H34.667c-.575-.04-1.124.25-1.423.749-.3.5-.3 1.126 0 1.625.299.5.848.788 1.423.75h30.484c.575.038 1.124-.25 1.424-.75.299-.5.299-1.126 0-1.625-.3-.5-.849-.788-1.424-.75H51.455V71.36h25.503c2.13 0 3.856-1.746 3.86-3.903V36.965c-.004-2.157-1.73-3.903-3.86-3.903zm.224 34.457c-.011.42-.351.754-.767.754h-53.92c-.424 0-.768-.347-.768-.775V37.202c.015-.417.354-.747.767-.747h53.921c.424 0 .767.346.767.774v30.29z" />
    </Ic>
);

IC259BingeCentres.displayName = 'IC259BingeCentres';

export default IC259BingeCentres;
