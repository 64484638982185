export const CLASSIFICATION = 'classification';
export const YEARS = 'years';
export const SHOW_EDITION = 'showEdition';
export const SCHEDULE_TAGLINE = 'scheduleTagLine';
export const EPISODE = 'episode';
export const IMDB = 'imdb';
export const DATETIME = 'dateTime';
export const RESOLUTION = 'resolution';
export const SEASONS = 'seasons';
export const LENGTH = 'length';
export const STATUS = 'status';
export const FILMS = 'films';
