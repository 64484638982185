import {useEffect, useState, createRef} from 'react';
import range from 'lodash/range';
import invoke from 'lodash/invoke';

/**
 * Returns a array version of code input but up to specified codeLength only
 *  - if code input is less than codeLength characters, we set array entry to `''`
 *
 * Example:
 *  - INPUT: `{code: '12CD', codeLength: 6}`
 *  - OUTPUT:  `['1', '2', 'C', 'D', '', '']`
 *
 * @param {Object} options options
 * @param {String} options.code code string
 * @param {String} options.codeLength length of code
 * @returns {String[]} code in array form
 */
export const generateCodeArray = ({code = '', codeLength = 0}) => Object.assign(
    Array.from({length: codeLength}, () => ''),
    Object.values(code.slice(0, codeLength).split(''))
);

/**
 * This hook does the following:
 *  - if prop code is provided/updated, update input fields with it
 *  - handle internal movements: key down, input changes and focus
 *  - triggers onComplete callback once code meets 6digit chars requirement
 *
 * @param {{code: String, codeLength: number, onComplete: function, resetAttemptCount: number}} options options
 * @returns {{fieldRefs: React.Ref[], handleInputChange: function, handleKeyDown: function}} field refs and event handlers
 */
const useCodeInput = ({
    code = '',
    codeLength,
    hasError,
    isCodeResetOnErrorEnabled = true,
    onComplete,
    resetAttemptCount,
}) => {
    const fieldRefs = range(codeLength).map(() => createRef(null));
    const [codeArray, setCodeArray] = useState(generateCodeArray({code, codeLength}));

    // Keep track of props
    const [prevCodeLength, setPrevCodeLength] = useState(codeLength);

    /**
     * Update each code input field with new value
     *
     * @param {String[]} newCodeArray array of single chars
     */
    const updateFieldsValue = (newCodeArray) => {
        fieldRefs.forEach((fieldRef, index) => {
            fieldRef.current.value = newCodeArray[index] || '';
        });
    };

    /**
     * Move focus to target index
     *
     * @param {Number} destinationIndex target index
     */
    const moveFocus = (destinationIndex) => {
        // Don't promote along from the last field
        invoke(fieldRefs, [destinationIndex, 'current', 'focus']);
    };

    /**
     * If the code changed from the outside (via prop),
     *  - apply it to the codeArray to update input field values
     */
    useEffect(function updateCodeInputValueWhenCodeChanges() {
        const newCodeArray = generateCodeArray({code, codeLength});

        setCodeArray(newCodeArray);
        updateFieldsValue(newCodeArray);
    }, [code]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * If the codeLength changed from the outside (via prop),
     *  - reset the code array to clear the input field values
     */
    useEffect(function resetCodeInputValueWhenCodeLengthChanges() {
        if (!!prevCodeLength && (codeLength !== prevCodeLength)) {
            const newCodeArray = generateCodeArray({code: '', codeLength});

            setPrevCodeLength(codeLength);
            setCodeArray(newCodeArray);
            updateFieldsValue(newCodeArray);
        }
    }, [codeLength]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * If resetAttemptCount increments or if the component is in an error state,
     *  - reset the code array to clear the input field values
     */
    useEffect(function clearCode() {
        if (resetAttemptCount || (hasError && isCodeResetOnErrorEnabled)) {
            const newCodeArray = generateCodeArray({code: '', codeLength});

            setCodeArray(newCodeArray);
            updateFieldsValue(newCodeArray);
        }
    }, [resetAttemptCount, hasError, isCodeResetOnErrorEnabled]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Triggers `onComplete` callback once the code meets number of codeLength characters
     *  - Excluding white spaces, null and undefined values
     */
    useEffect(function checkIfCodeIsComplete() {
        if (codeArray.filter((x) => !!x?.trim()).length === codeLength) {
            onComplete(codeArray.join(''));
        }
    }, [codeArray, codeLength]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Handles logic around updating internal values, and moving focus between input fields
     *
     * @param {{index: number, targetValue: String}} options options
     */
    const handleInputChange = ({index: fieldIndex, targetValue}) => {
        const inputValue = targetValue.trim();
        const newCodeArray = codeArray.slice(); // make a copy

        // If the new value would be more than one character (e.g. after copy paste),
        // Set the next fields along rather than putting multiple characters into this field
        if (inputValue.length) {
            const inputChars = inputValue.split('');

            inputChars.forEach((char, index) => {
                const targetIndex = fieldIndex + index;

                if (targetIndex < codeLength) {
                    newCodeArray[targetIndex] = char;
                }
            });
        // Clear the input field
        } else {
            newCodeArray[fieldIndex] = '';
        }

        setCodeArray(newCodeArray);

        updateFieldsValue(newCodeArray);

        const nextIndex = inputValue.length
            ? fieldIndex + inputValue.length // If data was entered, go to the first cell where no data exists
            : fieldIndex; // On backspaces, don't change our focus, as that's handled by the onKeyDown event

        moveFocus(nextIndex);
    };

    /**
     * Handles special logic around arrow key navigation and deletions
     *
     * @param {{index: Number, event: Event}} options options
     */
    const handleKeyDown = ({index, event}) => {
        const isBackSpaceOrDelete = ['Backspace', 'Delete'].includes(event.key);

        // Move focus backwards for:
        //  - left arrow key
        //  - Delete key presses for empty fie;ds
        if (event.key === 'ArrowLeft' || (!event.target.value.length && isBackSpaceOrDelete)) {
            moveFocus(index - 1);
        // Move focus forwards for:
        //  - ArrowRight
        } else if (event.key === 'ArrowRight') {
            moveFocus(index + 1);
        }

        // On backspace/delete, we want to clear the field!
        //  > PROB:
        //     But we can't trust the browser to do this because we dont have guarantee about where the cursor is.
        //  > WORKAROUND:
        //     We manually set the input to blank, and prevent the event from propagating to stop handleInputChange
        //    from firing twice.
        if (event.target.value.length && isBackSpaceOrDelete) {
            handleInputChange({index, targetValue: ''});
            event.preventDefault();
        }

        // If the input has a value and user typed a single character (letter, number or special char),
        //  - clear the value
        //  - then let onChange change it
        if (event.target.value.length && event.key.length === 1) {
            fieldRefs[index].current.value = '';
        }
    };

    return {
        fieldRefs,
        handleKeyDown,
        handleInputChange,
    };
};

export default useCodeInput;
