import React from 'react';
import propTypes from 'prop-types';
import {CSSTransition} from 'react-transition-group';
import {TRANSITION_CLASSNAMES, TRANSITION_DURATION_MS} from './animations';

const CommonTransition = ({
    in: isVisible,
    onEnter,
    onEntering,
    onEntered,
    onExit,
    onExiting,
    onExited,
    children,
}) => (
    <CSSTransition
        in={isVisible}
        timeout={TRANSITION_DURATION_MS}
        unmountOnExit={true}
        mountOnEnter={true}
        classNames={TRANSITION_CLASSNAMES}
        appear={true}
        onEnter={onEnter}
        onEntering={onEntering}
        onEntered={onEntered}
        onExit={onExit}
        onExiting={onExiting}
        onExited={onExited}
    >
        {children}
    </CSSTransition>
);

CommonTransition.displayName = 'CommonTransition';

CommonTransition.propTypes = {
    in: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    onEnter: propTypes.func,
    onEntering: propTypes.func,
    onEntered: propTypes.func,
    onExit: propTypes.func,
    onExiting: propTypes.func,
    onExited: propTypes.func,
    children: propTypes.node,
};

export default CommonTransition;
