import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import noop from 'lodash/noop';

import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';

import BA30TextBtn from '../../../atoms/ba/30-text-btn';
import {blanc, vader} from '../../../../common/palette';
import AccordionItem from './accordion-item';

const OuterContainer = styled.section`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const StyledLink = styled(BA30TextBtn)`
    margin: 30px auto 0;
    color: ${({isLightTheme}) => isLightTheme ? vader : blanc};
    font: var(--ionic-body-copy-3-light);
`;

const MainHeading = styled.h2`
    text-align: center;
    color: ${({isLightTheme}) => isLightTheme ? vader : blanc};
    font: var(--ionic-header-4);
`;

const ItemContainer = styled.dl`
    margin: 0;
    width: 100%;
`;

const Footer = styled.div`
    margin-top: 120px;
    text-align: center;
    color: ${({isLightTheme}) => isLightTheme ? vader : blanc};
    font: var(--ionic-body-copy-3-light);
`;

const ContactUsCopy = styled.p`
    display: inline;

    ${stylesWhen('isBold')`
        font: var(--ionic-body-copy-3-bold);
    `}
`;

const OR17AccBlk = ({
    isLightTheme,
    collapseIcon,
    contactUsCopy = 'Not sure of something? Contact our Binge support crew',
    contactUsHref,
    expandIcon,
    footerCopy,
    items = [],
    title = 'Frequently asked questions',
    onExpandItem = noop,
}) => {
    const safeContactUsCopy = typeof contactUsCopy === 'string'
        ? contactUsCopy
        : addBoldMarkupFromConfig(contactUsCopy);

    return (
        <OuterContainer className="OR17AccBlk">
            {!!title && (
                <MainHeading isLightTheme={isLightTheme}>
                    {title}
                </MainHeading>
            )}
            <ItemContainer>
                {items.map(({id, isExpanded, title, definition} = {}) => (
                    <AccordionItem
                        key={id}
                        isLightTheme={isLightTheme}
                        title={title}
                        isExpanded={isExpanded}
                        collapseIcon={collapseIcon}
                        expandIcon={expandIcon}
                        onExpandItem={() => void onExpandItem(title)}
                    >
                        {definition}
                    </AccordionItem>
                ))}
            </ItemContainer>
            {!!contactUsHref && (
                <StyledLink
                    isLightTheme={isLightTheme}
                    href={contactUsHref}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {safeContactUsCopy}
                </StyledLink>
            )}
            {!!footerCopy && (
                <Footer isLightTheme={isLightTheme}>{footerCopy}</Footer>
            )}
        </OuterContainer>
    );
};

OR17AccBlk.propTypes = {
    /** Light or dark (default) theme */
    isLightTheme: propTypes.bool,
    /** Icon for collapsed state */
    collapseIcon: propTypes.node,
    /** "Contact Us" hyperlink copy */
    contactUsCopy: propTypes.oneOfType([propTypes.array, propTypes.string]),
    /** "Contact Us" hyperlink url */
    contactUsHref: propTypes.string,
    /** Icon for expanded state */
    expandIcon: propTypes.node,
    /** Footer block text */
    footerCopy: propTypes.string,
    /** Array of question-answer objects, containing title (question) and definition (answer) */
    items: propTypes.arrayOf(
        propTypes.shape({
            id: propTypes.string,
            definition: propTypes.node,
            isExpanded: propTypes.bool,
            title: propTypes.node,
        }),
    ),
    /** To handle click events on accordion items */
    onExpandItem: propTypes.func,
    /** Title of the Accordion block */
    title: propTypes.string,
};

OR17AccBlk.displayName = 'OR17AccBlk';

export default OR17AccBlk;

/**
 * Embolden sections of copy as defined in the marketing config.
 *
 * @param {Array}    contactUsCopy array of objects that together form the copy
 * @param {Object}   contactUsCopy.options see below
 * @param {string}   [contactUsCopy.options.copy] text to be displayed
 * @param {boolean}  [contactUsCopy.options.isBold] whether or not to embolden the text
 *
 * @returns {React.Component} <ContactUsCopy />
 */
function addBoldMarkupFromConfig(contactUsCopy = []) {
    return contactUsCopy.map(({copy = '', isBold} = {}, index) => (
        <ContactUsCopy key={index} isBold={isBold}>{copy}</ContactUsCopy>
    ));
}
