import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import clamp from 'lodash/clamp';
import property from 'lodash/property';
import {rgba, readableColor, mix} from 'polished';
import {stylesIfElse} from '@fsa-streamotion/styled-component-helpers';
import {colorType} from '@fsa-streamotion/custom-prop-types';

import {white} from '../../../../common/palette';
import {ironStone, slate} from '../../../../common/deprecated-mui-palette';

const StatBar = styled.div`
    display: inline-block;
    background-image: linear-gradient(to left, ${({color}) => rgba(color, 0.46)}, ${property('color')});
    height: 22px;
`;

const LeftSideBar = styled(StatBar)`
    border-radius: ${({isFull}) => isFull ? '11px' : '11px 0 0 11px'};
`;

const RightSideBar = styled(StatBar)`
    border-radius: ${({isFull}) => isFull ? '11px' : '0 11px 11px 0'};
`;

const ValueString = styled.span`
    position: absolute;
    top: 3px;
    font: var(--nucleus-hud-tile-copy-bold);

    ${stylesIfElse('isLeft')`
        left: 14px;
    ``
        right: 14px;
    `}
`;

const LightText = styled.span`
    padding-left: 4px;
    font: var(--nucleus-hud-tile-copy-light);
`;

const Positioner = styled.div`
    position: relative;
    margin: 14px 0;
    color: ${white};
`;

const GA78H2hBar = ({
    color = slate,
    leftValue,
    rightValue,
    leftDescriptionText,
    rightDescriptionText,
    backgroundColor = ironStone,
    ...htmlAttributes
}) => {
    const leftPercentage = clamp(Math.round((100 * leftValue) / Math.max(leftValue + rightValue, 1)), 0, 100);
    const rightPercentage = 100 - leftPercentage;
    const highContrastLabelColor = readableColor(mix(0.27, color, backgroundColor));

    return (
        <Positioner {...htmlAttributes}>
            <ValueString style={{color: highContrastLabelColor}} isLeft={true}>
                {leftValue}
                {!!leftDescriptionText && (
                    <LightText>{leftDescriptionText}</LightText>
                )}
            </ValueString>
            <ValueString style={{color: highContrastLabelColor}} isLeft={false}>
                {rightValue}
                {!!rightDescriptionText && (
                    <LightText>{rightDescriptionText}</LightText>
                )}
            </ValueString>
            <LeftSideBar style={{width: `${leftPercentage}%`}} color={color} isFull={leftPercentage === 100} />
            <RightSideBar style={{width: `${rightPercentage}%`}} color={color} isFull={rightPercentage === 100} />
        </Positioner>
    );
};

GA78H2hBar.displayName = 'GA78H2hBar';

GA78H2hBar.propTypes = {
    /** The color to which the progress bar fades, used for text contrast determination */
    backgroundColor: colorType,
    /** Progress bar base color */
    color: colorType,
    /** Left side value */
    leftValue: propTypes.number,
    /** Right side value */
    rightValue: propTypes.number,
    /** Extra text for the left side */
    leftDescriptionText: propTypes.string,
    /** Extra text for the right side */
    rightDescriptionText: propTypes.string,
};

export default GA78H2hBar;
