import React from 'react';

import Ic from '..';

const IC118Tap = (args) => (
    <Ic {...args}>
        <path d="M22.638 34.712A18.775 18.775 0 0121 26.984 18.981 18.981 0 0132.732 9.483 18.982 18.982 0 0157.54 34.25l-3.878-1.61a14.783 14.783 0 00-22.871-17.199 14.784 14.784 0 00-4.317 17.563l-3.836 1.708z" />
        <path d="M78.736 52.926a6.903 6.903 0 00-6.888-6.888H71.4a6.755 6.755 0 00-3.29.882 7.141 7.141 0 00-6.51-4.27 7.002 7.002 0 00-3.724 1.078 7.124 7.124 0 00-6.412-3.934h-.224a8.248 8.248 0 00-2.66.672V37.4c0-1.4 0-2.688.07-3.962.154-5.236.28-9.8-2.254-12.362a6.86 6.86 0 00-5.124-1.876A8.315 8.315 0 0035 21.552a9.52 9.52 0 00-2.506 6.342v26.6a15.034 15.034 0 00-4.466 5.6C24.458 68.2 28 78.63 29.596 81.99l.098.168c1.75 3.584 5.39 11.018 25.69 11.018 16.422 0 21.28-14.882 21.644-23.45a6.856 6.856 0 001.708-4.494V52.926zM71.4 50.238h.49a2.703 2.703 0 012.688 2.688v12.306a2.685 2.685 0 01-2.688 2.688h-.49a2.685 2.685 0 01-2.688-2.688V52.926a2.703 2.703 0 012.688-2.688zm-19.88-6.244c.761 0 1.491.302 2.03.84.538.539.84 1.269.84 2.03v1.204a40.98 40.98 0 00-5.852.882V46.374a3.024 3.024 0 012.982-2.38zM36.75 27.88a4.314 4.314 0 014.522-4.48c1.484 0 1.96.476 2.114.644 1.288 1.4 1.19 5.208 1.078 9.324 0 1.302-.084 2.674-.084 4.074V49.93c-1.4.35-3.038.756-4.83 1.246-.955.25-1.89.568-2.8.952V27.88zm18.634 61.096c-17.682 0-20.44-5.6-21.924-8.666l-.098-.182c-1.302-2.66-4.382-11.732-1.4-18.2 1.512-3.332 4.34-5.516 8.68-6.692 8.89-2.394 12.6-2.884 14.14-2.954.182 1.876 0 4.396-1.12 5.068C50.4 59.156 39.06 64 38.962 64l1.638 3.906c.42-.182 11.69-4.998 15.078-7 4.2-2.422 3.304-8.904 3.01-10.458a1.916 1.916 0 00-.112-.56v-.154a2.926 2.926 0 115.852 0v20.93a2.926 2.926 0 11-5.852 0v-4.69l-4.2 1.932v4.802c0 .761-.302 1.491-.84 2.03a2.873 2.873 0 01-2.03.84h-.112a2.87 2.87 0 01-2.8-2.87v-2.1l-4.2 1.918v.182a7.084 7.084 0 007 7.07h.112a6.998 6.998 0 005.95-3.276 7.18 7.18 0 004.074 1.288 7.113 7.113 0 007-6.258 6.836 6.836 0 002.8.588h.49c.238.014.476.014.714 0-.826 5.628-4.158 16.856-17.15 16.856z" />
    </Ic>
);

IC118Tap.displayName = 'IC118Tap';

export default IC118Tap;
