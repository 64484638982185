import React from 'react';
import Ic from '..';

const IC209Fixtures = (args) => (
    <Ic {...args}>
        <path d="M24.819 28.28c-4.977 0-9.026 3.502-9.026 7.807v37.825c0 4.305 4.049 7.807 9.026 7.807H76.18c4.977 0 9.026-3.502 9.026-7.807V36.087c0-4.305-4.049-7.806-9.026-7.806H24.82zm0 56.72C17.75 85 12 80.026 12 73.912V36.087C12 29.973 17.75 25 24.819 25H76.18C83.25 25 89 29.973 89 36.087v37.825C89 80.026 83.25 85 76.181 85H24.82z" />
        <path d="M28.031 26.751A2.033 2.033 0 0 1 26 24.721v-8.69c0-1.12.911-2.031 2.031-2.031s2.031.911 2.031 2.031v8.69c0 1.12-.91 2.03-2.03 2.03m43.999 0A2.033 2.033 0 0 1 70 24.721v-8.69c0-1.12.911-2.031 2.031-2.031s2.031.911 2.031 2.031v8.69c0 1.12-.91 2.03-2.03 2.03M14 39h73v3.5H14z" />
        <path opacity=".35" d="M85.207 42.5v31.412c0 4.227-3.903 7.68-8.755 7.804l-.27.003H24.818c-4.887 0-8.879-3.376-9.022-7.573l-.005-.234V42.5h69.415z" />
        <path d="M36.585 50.792a2.794 2.794 0 0 1-5.585 0 2.794 2.794 0 0 1 5.585 0m16.585 0a2.794 2.794 0 0 1-5.585 0 2.794 2.794 0 0 1 5.585 0m16.581 0a2.79 2.79 0 1 1-5.582 0 2.79 2.79 0 1 1 5.582 0M36.585 62.377a2.794 2.794 0 0 1-5.585 0 2.794 2.794 0 0 1 5.585 0m16.585 0a2.794 2.794 0 0 1-5.585 0 2.794 2.794 0 0 1 5.585 0m16.581 0a2.79 2.79 0 1 1-5.582 0 2.79 2.79 0 1 1 5.582 0M36.585 73.965a2.796 2.796 0 0 1-2.793 2.793A2.796 2.796 0 0 1 31 73.965a2.796 2.796 0 0 1 2.792-2.795 2.796 2.796 0 0 1 2.793 2.795m16.585 0a2.796 2.796 0 0 1-2.793 2.793 2.796 2.796 0 0 1-2.792-2.793 2.796 2.796 0 0 1 2.792-2.795 2.796 2.796 0 0 1 2.793 2.795m16.581 0a2.796 2.796 0 0 1-2.792 2.793 2.794 2.794 0 0 1 0-5.588 2.796 2.796 0 0 1 2.792 2.795" />
    </Ic>
);

IC209Fixtures.displayName = 'IC209Fixtures';

export default IC209Fixtures;
