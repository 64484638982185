import React, {Component} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import IntersectionObserver from 'inteobs';
import classnames from 'classnames';
import invoke from 'lodash/invoke';
import isEmpty from 'lodash/isEmpty';

import {stylesWhen, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {coal, ironStone} from '../../../../common/palette';

const HINT_AT_ROWS_ABOVE_BUFFER = 18;
const ROW_HEIGHT = 110; // not great that we need to hardcode this, but its currently based on BA04TeamTi with a nav indicator

const getExtraRowsBuffer = ({numRows}) => numRows > 1 ? HINT_AT_ROWS_ABOVE_BUFFER : 0;
const getHeightOfExpandedMenu = ({numRows}) => numRows * ROW_HEIGHT;
const getHeightOfCondensedMenu = ({numRows}) => ROW_HEIGHT + getExtraRowsBuffer({numRows});

// The transitions here are longer than the standard ones, very intentionally
const customTransition = (...props) => props
    .map((prop) => `${prop} 0.3s ease-in`)
    .join(', ');

const StyledNav = styled.nav`
    border-top: 1px solid ${ironStone};
    height: ${getHeightOfCondensedMenu}px; /* This is the component actually holding DOM height for layout purposes (e.g. during sticky and expanded states) */
`;

const StickyContainer = styled.div`
    transition: ${customTransition('height', 'margin-bottom')};
    outline: 0;
    padding-bottom: 14px;
    width: 100%;
    overflow-y: hidden;

    ${stylesWhen('isExpanded')`
        margin-bottom: ${getHeightOfCondensedMenu}px;

        ${stylesWhenNot('isSticky')`
            transform: translate3d(0, 0, 0); /* Render on top of page content in stacking context */
        `}
    `}
`;

const MenuRowsContainer = styled.ol`
    box-sizing: border-box;
    transition: ${customTransition('transform')};
    margin: 0;
    background-color: ${coal};
    padding: 0 5%;
    width: 100%;
    overflow-y: hidden;
    list-style: none;

    ${stylesWhenNot('isExpanded')`
        transform: translateY(${({numRows}) => getExtraRowsBuffer({numRows}) - getHeightOfExpandedMenu({numRows: numRows - 1})}px);
    `}

    ${stylesWhen('isSticky')`
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1; /* As this is fixed, it has different stacking context */
    `}
`;

const MenuRow = styled.li`
    margin-top: 7px;
`;

// https://developers.google.com/web/updates/2017/09/sticky-headers
// When we scroll past this we go into sticky mode
const Sentinel = styled.div`
    position: absolute;
    left: 0;
    margin-top: -1px;
    width: 100%;
    /* observer must have at least 1px width and height to work on edge */
    height: 1px;
`;

export default class OR95CascadeCar extends Component {
    static displayName = 'OR95CascadeCar';

    static propTypes = {
        /** The carousels to render as part of the nav */
        children: propTypes.node.isRequired,
        /** Additional class name(s) to apply to the root component */
        className: classNameType,
        /** Whether to be initially expanded or not */
        isInitiallyExpanded: propTypes.bool,
    };

    state = {
        isSticky: false,
        isExpanded: this.props.isInitiallyExpanded,
    };

    componentDidMount() {
        this.onKeyUp = ({key}) => {
            if (this.menu && key === 'Tab') {
                if (document.activeElement === this.menu || this.menu.contains(document.activeElement)) {
                    this.expand();
                } else {
                    this.contract();
                }
            }
        };

        // Intersection observer to make menu stick on scroll
        this.intersectionObserver = new IntersectionObserver(([topSentinel]) => { // eslint-disable-line compat/compat
            if (isEmpty(topSentinel)) {
                return;
            }

            if (topSentinel.boundingClientRect.bottom < topSentinel.rootBounds.top) {
                this.setState({isSticky: true, isExpanded: false});
            } else if (topSentinel.boundingClientRect.bottom >= topSentinel.rootBounds.top) {
                this.setState({isSticky: false});
            }
        });

        this.intersectionObserver.observe(this.topSentinel);

        // Observer to see when a user tabs into/out of the menu
        document.addEventListener('keyup', this.onKeyUp);
    }

    componentWillUnmount() {
        this.intersectionObserver.disconnect();
        document.removeEventListener('keyup', this.onKeyUp);
    }

    expand = () => void this.setState({isExpanded: true});
    contract = () => void this.setState({isExpanded: false});

    handleKeyDown = (e) => {
        const childrenArray = [...this.menu.childNodes];
        const currentRowIndex = childrenArray.findIndex((node) => node.contains(document.activeElement));
        const isArrowDown = ['ArrowDown', 'Down'].includes(e.key);
        const isArrowUp = ['ArrowUp', 'Up'].includes(e.key);

        if (isArrowDown || isArrowUp) {
            e.preventDefault();

            if (isArrowDown && childrenArray.length - 1 > currentRowIndex) {
                invoke(childrenArray[currentRowIndex + 1].querySelector('a, button'), 'focus');
            } else if (isArrowUp && currentRowIndex > 0) {
                invoke(childrenArray[currentRowIndex - 1].querySelector('a, button'), 'focus');
            }
        }
    };

    render() {
        const {children, className, ...htmlAttributes} = this.props;
        const numRows = React.Children.count(children);

        return (
            <StyledNav
                {...htmlAttributes}
                className={classnames('OR95CascadeCar', className)}
                numRows={numRows}
                onKeyDown={this.handleKeyDown}
            >
                <Sentinel ref={(el) => { this.topSentinel = el; }} />
                <StickyContainer
                    isSticky={this.state.isSticky}
                    isExpanded={this.state.isExpanded}
                    numRows={numRows}
                    tabIndex={-1} // This section is focusable in Firefox, so prevent that
                >
                    <MenuRowsContainer
                        ref={(el) => { this.menu = el; }}
                        isSticky={this.state.isSticky}
                        isExpanded={this.state.isExpanded}
                        numRows={numRows}
                        onMouseOver={this.expand}
                        onFocus={this.expand}
                        onMouseOut={this.contract}
                        onBlur={this.contract}
                    >
                        {React.Children.map(children, (child) => (
                            <MenuRow key={child.key}>
                                {child}
                            </MenuRow>
                        ))}
                    </MenuRowsContainer>
                </StickyContainer>
            </StyledNav>
        );
    }
}
