import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import {ink} from '../../../../common/palette';

import Ic from '..';

const IC316AppleLogo = ({
    secondaryColor = ink,
    ...args
}) => (
    <Ic {...args}>
        <path fill="none" d="M0 0h100v100H0z" />
        <g fillRule="nonzero" transform="translate(10 10)">
            <ellipse cx="39.787" cy="40" rx="39.787" ry="40" />
            <path fill={secondaryColor} d="M60.53 28.092c-.327.252-6.113 3.484-6.113 10.67 0 8.31 7.363 11.25 7.583 11.324-.034.179-1.17 4.027-3.882 7.947-2.418 3.45-4.944 6.894-8.786 6.894-3.843 0-4.831-2.212-9.267-2.212-4.322 0-5.86 2.285-9.374 2.285-3.514 0-5.967-3.192-8.786-7.113C18.639 53.284 16 46.132 16 39.344c0-10.888 7.142-16.662 14.171-16.662 3.735 0 6.849 2.43 9.193 2.43 2.232 0 5.713-2.575 9.962-2.575 1.61 0 7.397.145 11.205 5.555zM47.31 17.927c1.757-2.066 3-4.934 3-7.801 0-.398-.034-.801-.107-1.126-2.86.106-6.26 1.887-8.312 4.245-1.61 1.815-3.113 4.682-3.113 7.589 0 .437.073.874.107 1.014.181.033.475.072.769.072 2.565 0 5.791-1.702 7.656-3.993z" />
        </g>
    </Ic>
);

IC316AppleLogo.propTypes = {
    /** The icon's secondary color */
    secondaryColor: colorType,
};

IC316AppleLogo.displayName = 'IC316AppleLogo';

export default IC316AppleLogo;
