import React from 'react';

import Ic from '..';

const IC191Gift = (args) => (
    <Ic {...args}>
        <path fillRule="nonzero" d="M73.5 25.92h-5.4c3.5-3.1 4.2-8.5 1.3-12.3-1.4-1.9-3.6-3.2-6-3.5-2.4-.3-4.8.3-6.7 1.7-2.4 1.8-4.6 6.4-6.8 13.8-2.2-7.5-4.4-12-6.8-13.8-1.9-1.5-4.3-2.1-6.7-1.7-2.4.3-4.5 1.6-6 3.5-2.9 3.8-2.2 9.2 1.3 12.3h-5.4c-4.6 0-8.3 3.7-8.3 8.3v46.9c0 4.6 3.7 8.3 8.3 8.3h46.9c4.6 0 8.3-3.7 8.3-8.3v-46.9c.3-4.6-3.4-8.3-8-8.3zm2.7 8.3v22.1H51.4v-24.9h22.1c1.5 0 2.7 1.3 2.7 2.8zm-17.8-20.2c1.3-1 3-1.4 4.6-1.2 1.6.2 3.1 1.1 4.1 2.4 2.1 2.8 1.5 6.7-1.2 8.8-1.1.8-3.7 1.7-9.7 1.8h-3.4c2.4-8 4.4-10.8 5.6-11.8zm-24.3 10.1c-2.8-2.1-3.3-6-1.2-8.8 1-1.3 2.5-2.2 4.1-2.4.3 0 .6-.1.9-.1 1.3 0 2.6.4 3.7 1.3 1.3 1 3.3 3.8 5.6 11.9h-3.4c-6.1-.2-8.7-1.1-9.7-1.9zm-7.6 7.3h22.1v24.8H23.8v-22c0-1.5 1.2-2.8 2.7-2.8zm-2.7 49.7v-22.1h24.8v24.8H26.5c-1.5.1-2.7-1.2-2.7-2.7zm49.7 2.8H51.4v-24.9h24.8v22.1c0 1.5-1.2 2.8-2.7 2.8z" />
    </Ic>
);

IC191Gift.displayName = 'IC191Gift';

export default IC191Gift;
