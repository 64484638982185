/**
 * Converts an ArrayBuffer into a hex string
 * stole this helper from
 * https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
 *
 * @param {ArrayBuffer} buffer  an ArrayBuffer to be converted to a hex string
 * @returns {string}            string of hex codes
 */
export function ab2HexString(buffer) {
    const byteArray = new Uint8Array(buffer);

    const hexCodes = [...byteArray].map((value) => {
        const hexCode = value.toString(16);
        const paddedHexCode = hexCode.padStart(2, '0');

        return paddedHexCode;
    });

    return hexCodes.join('');
}

/**
 * Converts a UTF-8 string to an ArrayBuffer
 *
 * @param {string} str      string to convert
 * @returns {ArrayBuffer}   new ArrayBuffer representing the string
 */
export function str2ab(str) {
    if (window.TextEncoder) {
        return new TextEncoder().encode(str);
    }

    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);

    for (let i = 0; i < str.length; i++) {
        bufView[i] = str.charCodeAt(i);
    }

    return buf;
}
