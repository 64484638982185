import React from 'react';
import Ic from '..';

const IC05Exp = (args) => (
    <Ic {...args}>
        <path d="M11.1 44H5.6V19.4c0-1.5 1.2-2.8 2.8-2.8H43v5.6H11.1V44zM42.9 83.3H8.3c-1.5 0-2.8-1.2-2.8-2.8V57.7h5.6v20.1h31.8v5.5zM91.7 83.3H56.8v-5.6h32.1v-20h5.6v22.8c-.1 1.6-1.3 2.8-2.8 2.8zM94.4 44h-5.6V22.2h-32v-5.6h34.9c1.5 0 2.8 1.2 2.8 2.8L94.4 44z" />
    </Ic>
);

IC05Exp.displayName = 'IC05Exp';

export default IC05Exp;
