import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';

import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {buttonType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {blanc, onyx, midnight} from '../../../../common/palette';

const StyledElement = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: ${transition('background-color', 'opacity', 'color', 'text-shadow')};
    border-radius: 6px;
    background-color: ${rgba(blanc, 0.25)};
    padding: 11px 40px;
    text-shadow: 0 1px 1px ${rgba(onyx, 0.3)};
    white-space: nowrap;
    color: ${blanc};
    font: var(--nucleus-body-copy-2-bold);

    ${stylesWhen('isBlock')`
        width: 100%;
    `}

    &:hover,
    &:focus {
        outline: 0;
        background-color: ${rgba(blanc, 0.8)};
        text-shadow: none;
        color: ${midnight};
    }

    &:disabled {
        background-color: ${rgba(blanc, 0.1)};
        cursor: not-allowed;
        color: ${rgba(blanc, 0.1)};
    }

    &:active {
        background-color: ${blanc};
        text-shadow: none;
        color: ${midnight};
    }
`;

const ButtonSubText = styled.span`
    display: block;
    font: var(--nucleus-body-copy-4);
`;

// Styled(Button) is overriding some default behaviour that we would like this to inherit from StyledElement
const StyledButton = styled.button`
    appearance: none;
    border: 0;
`;

// Styled(A) is overriding some default behaviour that we would like this to inherit from StyledElement
const StyledLink = styled.a`
    text-decoration: none;
`;

const BA46BtnPrMarketing = React.forwardRef(({
    className,
    href,
    isBlock,
    onClick,
    primaryText,
    subText,
    type = 'button',
    ...htmlAttributes
}, ref) => (
    <StyledElement
        as={href ? StyledLink : StyledButton}
        {...htmlAttributes}
        {...{
            ref,
            href,
            isBlock,
            onClick,
            type: href ? undefined : type,
        }}
        className={classnames('BA46BtnPrMarketing', className)}
    >
        {!!primaryText && primaryText}
        {!!subText && <ButtonSubText>{subText}</ButtonSubText>}
    </StyledElement>
));

BA46BtnPrMarketing.propTypes = {
    /** additional CSS classnames to be applied */
    className: classNameType,
    /** If it’s actually a link, provide a href */
    href: propTypes.string,
    /** Should this button stretch horizontally to the whole container? */
    isBlock: propTypes.bool,
    /** Callback on element click */
    onClick: propTypes.func,
    /** Primary text on the button */
    primaryText: propTypes.string,
    /** Sub text on the button */
    subText: propTypes.string,
    /** The HTML type of the button */
    type: buttonType,
};

BA46BtnPrMarketing.displayName = 'BA46BtnPrMarketing';

export default BA46BtnPrMarketing;
