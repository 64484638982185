/* eslint-disable arrow-body-style */
import React from 'react';
import bacon from 'baconjs';
import matchesProperty from 'lodash/matchesProperty';
import isEqual from 'lodash/isEqual';
import find from 'lodash/find';
import method from 'lodash/method';

import {BrandedIC103Loading, BrandedOR52TabPanels} from '../../utils/branded-components';

import {getPaymentMethod} from '../../../../todo-move-to-widgets-common/streams/endpoints/my-account/payment-method';
import {BILLING_PRODUCT_NAME_MAP} from '../../../../todo-move-to-widgets-common/utils/constants';

import {Paragraph} from '../common/styled-elements';

import getPaymentNotRequiredStream from './step-payment--not-required';
import getPaymentTbillStream from './step-payment--tbill';
import getPaymentReuseStream from './step-payment--reuse';
import getPaymentCreditCardStream from './step-payment--credit-card';
import getPaymentOptusStream from './step-payment--optus';

const BILLING_TYPE_EXISTING = 'REUSE_EXISTING';
const BILLING_TYPE_TBILL = 'TBILL';
const BILLING_TYPE_CREDIT = 'CREDIT_CARD';
const BILLING_TYPE_OPTUS = 'OPTUS';

// @TODO Pull in the billing types
// This is the new responses from COG. But I think /subscribe/ares uses the old ones below....?
const BILLING_TYPES_ORDERED = [
    BILLING_TYPE_EXISTING,
    BILLING_TYPE_TBILL,
    BILLING_TYPE_CREDIT,
    BILLING_TYPE_OPTUS,
];

// Shoving here cause I think it's important somewhere....
// export const PAYMENT_METHOD = {
//     paypal: 'paypal',
//     creditCard: 'creditCard',
//     tbill: 'tbill',
// };

export default function getStepPaymentStream({
    isActive$,

    user$,
    // userAccountMainStatus$,
    offer$,
    selectedPackageId$,
    brand,
    platformEnv,
    ddSubscriptionId,
    merchantSubscriptionId,

    startSubscriptionBus,
} = {}) {
    // const paymentPageLoadedBus = new bacon.Bus();
    // const submitPaymentErrorBus = new bacon.Bus();
    // const paymentMethod$ = paymentChangeBus.toProperty(initialPaymentMethod);

    const isPpvOffer$ = offer$.map(method('offer.journeys.includes', 'PPV')).startWith();
    const isOptusOffer$ = offer$.map(method('offer.journeys.includes', 'OPTUS')).startWith();

    const selectedPackage$ = bacon.combineTemplate({
        packages: offer$.map('.packages'),
        selectedPackageId: selectedPackageId$,
    })
        .map(({packages = [], selectedPackageId}) => packages?.find(
            matchesProperty('packageId', selectedPackageId) // @TODO What if we don't have a selected package?  :grimace:
        ))
        .skipDuplicates(isEqual);

    const isPaymentRequired$ = selectedPackage$
        .map('.paymentMethodRequired');

    const paymentMethodsAvailable$ = bacon.combineTemplate({
        isOptusOffer: isOptusOffer$,
        paymentMethods: selectedPackage$.map('.paymentMethodsAvailable'),
    })
        .map(({paymentMethods = [], isOptusOffer}) => {
            return {
                isOptusOffer,
                paymentMethods: paymentMethods
                    .filter((paymentMethod) => BILLING_TYPES_ORDERED.includes(paymentMethod))
                    .sort((a, b) => BILLING_TYPES_ORDERED.indexOf(a) - BILLING_TYPES_ORDERED.indexOf(b)),
            };
        })
        .map(({isOptusOffer, paymentMethods}) => {
            const isTelstraCarrierBillableOffer = window.sessionStorage.getItem('signupTelstraCarrierBillableOffer') === 'true';

            return paymentMethods
                .filter((paymentMethod) => {
                    // If we're told TBILL is available, but we don't have word from telstra
                    // it's carrier billable from their JWT, we need to remove it.
                    if (paymentMethod === BILLING_TYPE_TBILL && !isTelstraCarrierBillableOffer) {
                        return false;
                    } else if (paymentMethod === BILLING_TYPE_OPTUS && !isOptusOffer) {
                        // If OPTUS is available, but the offer does not have OPTUS journey, we need to remove it
                        // This situation can occur on Flash since the API returns all payment methods
                        return false;
                    } else {
                        return true;
                    }
                });
        });

    const existingPaymentMethod$ = bacon.combineTemplate({
        user: user$,
        paymentMethodsAvailable: paymentMethodsAvailable$,
    })
        .flatMapLatest(({paymentMethodsAvailable, user}) => {
            const reuseExistingPayment = paymentMethodsAvailable.includes('REUSE_EXISTING');

            if (reuseExistingPayment) {
                return bacon.fromPromise(user.getAccessToken())
                    .flatMapLatest((accessToken) => getPaymentMethod({
                        accessToken,
                        platformEnv,
                    }))
                    // Delightfully, this existingPaymentMethods is an array, with a specific account Type
                    // So we check each product name, i.e, kayo, ares, flash, for an existing payment method
                    .map((existingPaymentMethods) => (
                        existingPaymentMethods.find(({accountType}) => find(Object.values(BILLING_PRODUCT_NAME_MAP), {accountType})) // first look for existingPaymentMethods on our known 1st party products
                            || find(existingPaymentMethods, {status: 'Active'}) // then look for any on all products including third party subscriptions
                            || false // otherwise return "false"
                    ));
            } else {
                return false;
            }
        });

    const currentPaymentMethodIndexBus = new bacon.Bus();
    const currentPaymentMethodIndex$ = currentPaymentMethodIndexBus.toProperty(0);

    const paymentControllers$ = bacon.combineTemplate({
        user: user$,
        isPaymentRequired: isPaymentRequired$,
        existingPaymentMethod: existingPaymentMethod$,
        paymentMethodsAvailable: paymentMethodsAvailable$,
        isPpvOffer: isPpvOffer$,
        paymentText: offer$.map('.offer.paymentText'),
    })
        .flatMapLatest(({user, isPaymentRequired, existingPaymentMethod, paymentMethodsAvailable = [], isPpvOffer, paymentText}) => {
            if (!isPaymentRequired) {
                return bacon.combineAsArray(getPaymentNotRequiredStream({
                    startSubscription$: startSubscriptionBus
                        .toEventStream(),  // Won't need any fancy 'are you active' stream here, but remapping to startSubscription$ for consistency. But want event to pop (vs property) still.
                }));
            }

            const telstraOrderItemId = window.sessionStorage.getItem('signupTelstraOrderItemNumber') || undefined;

            return bacon.combineAsArray(paymentMethodsAvailable.map((paymentMethod, index) => {
                const paymentMethodActive$ = currentPaymentMethodIndex$
                    .map((activePaymentMethodIndex) => activePaymentMethodIndex === index)
                    .toProperty(); // specifically a property because we're going to look at it 'after' it's been set.

                switch (paymentMethod) {
                    case BILLING_TYPE_EXISTING:
                        return getPaymentReuseStream({
                            user,
                            brand,
                            platformEnv,
                            existingPaymentMethod,
                            paymentMethod,
                            telstraOrderItemId,

                            startSubscription$: startSubscriptionBus
                                .toEventStream()
                                .filter(paymentMethodActive$),
                        });

                    case BILLING_TYPE_CREDIT:
                        return getPaymentCreditCardStream({
                            user,
                            isPpvOffer,
                            brand,
                            platformEnv,
                            paymentMethod,
                            telstraOrderItemId,

                            startSubscription$: startSubscriptionBus
                                .toEventStream()
                                .filter(paymentMethodActive$),

                            // we use this to change our label from New Credit Card, Existing Credit Card, or just Credit Card.
                            // default 'Credit Card'.
                            existingPaymentMethod,
                            paymentText,
                        });

                    case BILLING_TYPE_TBILL:
                        return getPaymentTbillStream({
                            user,
                            brand,
                            paymentMethod,
                            platformEnv,
                            telstraOrderItemId,

                            startSubscription$: startSubscriptionBus
                                .toEventStream()
                                .filter(paymentMethodActive$),
                        });

                    case BILLING_TYPE_OPTUS:
                        return getPaymentOptusStream({
                            user,
                            paymentMethod,
                            platformEnv,
                            ddSubscriptionId,
                            merchantSubscriptionId,

                            startSubscription$: startSubscriptionBus
                                .toEventStream()
                                .filter(paymentMethodActive$),
                        });

                    default:
                        // This shouldn't happen, as we filter crap out we don't know about.
                        console.error(`AccW-StepPayment: Unknown paymentMethod '${paymentMethod}'`);

                        return new bacon.Error(`AccW-StepPayment: Unknown paymentMethod '${paymentMethod}'`);
                }
            }));
        });

    const onlyAvailablePaymentIsCreditCardOrOptus$ = paymentMethodsAvailable$
        .map((paymentMethodsAvailable = []) => {
            return paymentMethodsAvailable.length === 1
                && (paymentMethodsAvailable.includes(BILLING_TYPE_CREDIT) || paymentMethodsAvailable.includes(BILLING_TYPE_OPTUS));
        });

    const paymentLabelsAndChildren$ = paymentControllers$.map((paymentController) => {
        return paymentController.map(({label, reactElement}) => ({label, children: reactElement})); // just keep the label + react element, not the controller things.
    });

    const activePaymentMethod$ = bacon.combineTemplate({
        paymentControllers: paymentControllers$,
        currentPaymentMethodIndex: currentPaymentMethodIndex$,
    })
        .map(({paymentControllers, currentPaymentMethodIndex}) => paymentControllers[currentPaymentMethodIndex]);

    const userSpecificPaymentStreamsResolved$ = bacon.combineTemplate({
        // We only care for offer$ and existingPayment$ streams resolving and we'll show spinner loading stuff.
        // If those resolve, the actual items themselves can do their own loading states as required, while still showing tabs.
        offer$,
        existingPaymentMethod$,
    })
        .map(true)
        .startWith(false);

    const reactElement$ = bacon.combineTemplate({
        isActive: isActive$,
        isLoading: userSpecificPaymentStreamsResolved$.not(),
        isPaymentRequired: isPaymentRequired$,
        onlyAvailablePaymentIsCreditCardOrOptus: onlyAvailablePaymentIsCreditCardOrOptus$,
        paymentLabelsAndChildren: paymentLabelsAndChildren$,

        supportingPaymentSelectTextElement: null, // Use this for overall supporting text above payment tabs vs for selected payment reuse.

        userSpecificPaymentStreamsResolved$,

        onSectionChange: ({index /* , label, event */}) => {
            currentPaymentMethodIndexBus.push(index);
        },
    })
        .map(({
            isActive,
            isLoading,
            isPaymentRequired,
            onlyAvailablePaymentIsCreditCardOrOptus,
            paymentLabelsAndChildren,

            supportingPaymentSelectTextElement,

            onSectionChange,
        }) => {
            if (!isPaymentRequired || !isActive) {
                return null;
            }

            if (isLoading) {
                return (
                    <Paragraph brand={brand} style={{width: '40px'}}>
                        <BrandedIC103Loading brand={brand} />
                    </Paragraph>
                );
            }

            if (onlyAvailablePaymentIsCreditCardOrOptus) {
                return paymentLabelsAndChildren[0].children;
            }

            return (
                <React.Fragment>
                    {supportingPaymentSelectTextElement}
                    <BrandedOR52TabPanels
                        isBlock={true}
                        onTabChange={onSectionChange}
                        sections={paymentLabelsAndChildren}
                        shouldPersistDom={true}
                        brand={brand}
                    />
                </React.Fragment>
            );
        })
        .startWith((
            <Paragraph brand={brand} style={{width: '40px'}}>
                <BrandedIC103Loading brand={brand} />
            </Paragraph>
        ));

    return bacon.combineTemplate({
        reactElement: reactElement$.startWith(null),

        giftCode: activePaymentMethod$.map('.giftCode').startWith(undefined),
        giftPin: activePaymentMethod$.map('.giftPin').startWith(undefined),
        shouldDisableSubscribeButton: activePaymentMethod$.map('.shouldDisableSubscribeButton').startWith(undefined),

        isSkipped: isPaymentRequired$.startWith(false),

        paymentLoaded: activePaymentMethod$.map('.paymentLoaded').startWith(false),
        paymentErrors: activePaymentMethod$.map('.paymentErrors').startWith(null),
        paymentDetails: activePaymentMethod$.map('.paymentDetails').startWith(null),
        paymentSuccessful: activePaymentMethod$.map('.paymentSuccessful').startWith(null),

        isNewPaymentMethod: activePaymentMethod$.map(({paymentMethod}) => paymentMethod === BILLING_TYPE_CREDIT).startWith(false),
    });
}
