import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {blanc} from '../../../../../common/palette';
import {SCREEN_1920_DESKTOP, SCREEN_2560_DESKTOP} from '../../../../../common/screen-sizes';

const List = styled.ul`
    margin: 0;
    border-right: 1px solid ${rgba(blanc, 0.15)};
    background-color: transparent;
    padding: 0;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    /* This scrollbar-* property is for Firefox 64+ */
    scrollbar-color: ${blanc} transparent;

    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    ::-webkit-scrollbar {
        background-color: transparent;
        width: 2px;

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            width: 3px;
        `}
    }

    ::-webkit-scrollbar-thumb {
        background-color: ${blanc};
    }
`;

const ListItem = styled.li`
    margin: 5px 0;
    width: 255px;
    overflow-x: hidden;
    list-style: none;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 345px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
        max-width: 460px;
    `}
`;

const VPTM06TraySectionList = ({
    children,
}) => (
    <List className="VPTM06TraySectionList" role="tablist">
        {React.Children.map(children, ({key, ...child}) => (
            <ListItem key={key} role="presentation">
                {child}
            </ListItem>
        ))}
    </List>
);

VPTM06TraySectionList.propTypes = {
    children: propTypes.any,
};

VPTM06TraySectionList.displayName = 'VPTM06TraySectionList';

export default VPTM06TraySectionList;
