/* eslint-disable arrow-body-style */
import React from 'react';
import bacon from 'baconjs';
import styled from 'styled-components';

import {GA151Optus, TM19Pmnt, screenSizes} from '@fsa-streamotion/streamotion-web-ionic';
import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

const {SCREEN_1920_DESKTOP, SCREEN_1280_DESKTOP} = screenSizes;

// import {Paragraph} from '../common/styled-elements';

const OverflowEllipsis = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const StyledTM19Pmnt = styled(TM19Pmnt)`
    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        width: 333px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 400px;
    `}
`;

export default function getPaymentOptusStream({
    user,
    paymentMethod,
    ddSubscriptionId,
    merchantSubscriptionId,

    startSubscription$,
}) {
    const label = 'Pay with Optus SubHub';

    const paymentDetails$ = startSubscription$
        .map({
            ddSubscriptionId,
            merchantSubscriptionId,
        });

    const paymentSuccess$ = startSubscription$
        .map(true);

    const userDetails$ = bacon.fromPromise(user.getUserDetails());

    const reactElement$ = userDetails$.map(({firstName, lastName}) => (
        <React.Fragment>
            {/* <Paragraph>
                Insert words about Optus SubHub payment here that's not in zeplin.
            </Paragraph> */}

            {/* This Implementation exists due to TM19PmntOptus mere existence causing unrelated vizard tests to fail in Ionic
            * CAM01Stand/last-item-focus on sizes 768+
            * OR117GiftCheckout/default on size 1280
            * Are you a bad enough dude to rescue TM19PmntOptus?
            */}
            <StyledTM19Pmnt
                className="TM19PmntOptus"
                icon={<GA151Optus />}
                isSelected={true}
            >
                <div>
                    {label}
                </div>

                <OverflowEllipsis>
                    {`${firstName} ${lastName}`}
                </OverflowEllipsis>
            </StyledTM19Pmnt>
        </React.Fragment>
    ));

    return bacon.combineTemplate({
        label,
        reactElement: reactElement$,

        paymentLoaded: bacon.later(1000, true).startWith(false),
        paymentErrors: null,

        paymentMethod,

        paymentDetails: paymentDetails$.startWith(null),
        paymentSuccessful: paymentSuccess$.startWith(false),
    });
}
