import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';
import {ares, vader} from '../../../../common/palette';

import Ic from '..';

const IC58Info = ({
    color = ares,
    secondaryColor = vader,
    ...otherProps
}) => (
    <Ic {...otherProps}>
        <path fill={color} d="M49.7 7C26.117 7 7 26.117 7 49.7c0 23.583 19.117 42.7 42.7 42.7 23.583 0 42.7-19.117 42.7-42.7C92.392 26.12 73.28 7.008 49.7 7z" />
        <path fill={secondaryColor} d="M54.152 69.569h-7.644v-25.2h7.644zM50.4 39.203a4.2 4.2 0 110-8.4 4.057 4.057 0 014.2 4.2 4.049 4.049 0 01-4.2 4.2z" />
    </Ic>
);

IC58Info.propTypes = {
    /** The icon's primary color */
    color: colorType,
    /** The icon's secondary color */
    secondaryColor: colorType,
};

IC58Info.displayName = 'IC58Info';

export default IC58Info;
