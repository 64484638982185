import React from 'react';
import propTypes from 'prop-types';
import {rgba} from 'polished';
import styled from 'styled-components';
import classnames from 'classnames';
import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {black, ink, hubblGreen, white} from '../../../../common/palette';
import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';

import {IC31Tick} from '../../../atoms/ic';

const TICK_ABOVE_CONTAINER = 1;

const StyledSection = styled.section`
    position: relative;
    outline: 0;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 ${rgba(black, 0.15)};
    background-color: ${white};
    color: ${rgba(ink, 0.8)};
    font: var(--quicksilver-body-copy-3);
`;

const TickWrapper = styled.span`
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -30%);
    z-index: ${TICK_ABOVE_CONTAINER};
    width: 25px;
    height: 25px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        transform: translate(37%, -37%);
        width: 30px;
        height: 30px;
    `}
`;

/**
 * TM38ConfirmBlk
 *  - used in order review page
 *
 * @see {@link https://zpl.io/gge93yZ}
 */
const TM38ConfirmBlk = ({
    children,
    className,
    hasCheckMark = true,
    ...htmlAttributes
}) => (
    <StyledSection
        {...htmlAttributes}
        className={classnames('TM38ConfirmBlk', className)}
    >
        {!!hasCheckMark && (
            <TickWrapper>
                <IC31Tick color={hubblGreen} />
            </TickWrapper>
        )}
        {children}
    </StyledSection>
);

TM38ConfirmBlk.propTypes = {
    /** Content of tile, e.g. payment method details */
    children: propTypes.node,
    /** Additional class name(s) */
    className: classNameType,
    /** Display checkmark on the top right corner?  */
    hasCheckMark: propTypes.bool,
};

TM38ConfirmBlk.displayName = 'TM38ConfirmBlk';

export default TM38ConfirmBlk;
