import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import {Svg} from 'normalized-styled-components';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {white} from '../../../../common/palette';
import muid from '../../../../common/muid';

const StyledSvg = styled(Svg)`
    display: inline-block;
`;

const GA131Wordmark = ({
    className,
    height,
    isMesh,
    title = 'Binge',
    width = '100%',
    id = muid(),
    ...htmlAttributes
}) => (
    <StyledSvg
        {...htmlAttributes}
        className={classnames('GA131Wordmark', className)}
        id={id}
        viewBox="0 0 278 61"
        style={{
            width,
            height,
        }}
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>{title}</title>

        <defs>
            {!!isMesh && (
                <React.Fragment>
                    <linearGradient x1="72.043%" y1="55.715%" x2="30.693%" y2="44.945%" id={`ga131-wordmark-${id}__linear-gradient--top}`}>
                        <stop stopColor="#F6A42A" offset="0%" />
                        <stop stopColor="#CE0042" offset="23.218%" />
                        <stop stopColor="#BC007B" offset="40.976%" />
                        <stop stopColor="#7F00BB" offset="57.194%" />
                        <stop stopColor="#1F21D7" offset="79.631%" />
                        <stop stopColor="#00C5F0" offset="100%" />
                    </linearGradient>
                    <linearGradient x1="100%" y1="54.783%" x2="2.2%" y2="46.494%" id={`ga131-wordmark-${id}__linear-gradient--bottom`}>
                        <stop stopColor="#F6A42A" offset="0%" />
                        <stop stopColor="#CE0042" offset="25.873%" />
                        <stop stopColor="#B00073" offset="39.445%" />
                        <stop stopColor="#740FA4" offset="56.679%" />
                        <stop stopColor="#461AC9" offset="68.341%" />
                        <stop stopColor="#1F21D7" offset="79.921%" />
                        <stop stopColor="#227EFF" offset="100%" />
                    </linearGradient>
                </React.Fragment>
            )}
            <path fill={white} d="M220.49 0l-2.087 16.601h-26.827l-.83.046c-6.338 0-9.15 1.664-11.568 4.188-2.624 2.74-4.391 6.407-3.8 11.848.403 3.697 2.763 6.965 5.228 8.669 2.717 1.877 4.864 2.617 7.977 2.617 2.249 0 6.878-.74 8.802-5.97l-6.513-12.32 11.213-.001.006-.048h15.127l-4.42 35.055h-11.269l-14.243.018c-8.996 0-15.499-1.662-22.417-8.494-4.065-3.978-8.63-11.793-8.63-20.39 0-8.562 2.517-16.519 8.052-22.14C168.01 5.874 174.17 0 186.359 0h34.13zM116.407.102L133.3 32.164 137.383.102h18.688l-7.744 60.583h-18.66l-17.022-32.039-4.221 32.04h-18.66L97.48.101h18.927zm161.093 0l-2.068 16.5h-32.355l-.64 5.248h26.827l-2.157 16.697H240.35l-.777 5.446h32.506l-2.17 16.688h-32.692v.004h-18.66l.001-.004h-.048l7.741-60.58H277.5zm-186.093 0l-2.113 16.425.052-.027-5.636 44.185H62.876l4.045-32.259 22.306-11.863h-.024c-.802.006-4.573.02-20.75.021L70.573.102h20.834zM40.55.059c11.73 0 21.24 2.914 19.466 18.893-.74 6.662-3.183 9.326-8.208 10.961l-.872.23.806.253c6.339 2.33 8.58 6.302 7.676 13.533-1.628 12.455-12.43 16.713-23.037 16.713H0L7.71.06zM32.52 36.4H21.997l-1.22 10.044h10.65c3.603-.018 8.597-.526 9.204-5.062.595-4.441-4.375-4.982-8.113-4.982zm2.376-21.48H24.744L23.616 23.6h10.186c3.453-.01 6.443-.27 6.933-4.184.492-3.941-2.818-4.494-6.032-4.494z" id={`ga131-wordmark-${id}__text`} />
        </defs>
        <g fill="none" fillRule="evenodd">
            <mask id={`ga131-wordmark-${id}__mask`} fill="#fff">
                <use xlinkHref={`#ga131-wordmark-${id}__text`} />
            </mask>
            <use fill={`url(#ga131-wordmark-${id}__linear-gradient--top)`} xlinkHref={`#ga131-wordmark-${id}__text`} />
            <path fill={`url(#ga131-wordmark-${id}__linear-gradient--bottom)`} mask={`url(#ga131-wordmark-${id}__mask)`} d="M-1-18h279v91H-1z" />
        </g>
    </StyledSvg>
);

GA131Wordmark.displayName = 'GA131Wordmark';

GA131Wordmark.propTypes = {
    /** Additional CSS classname(s) to use */
    className: classNameType,
    /** Element ID */
    id: propTypes.string,
    /** Whether or not to use the mesh colours */
    isMesh: propTypes.bool,
    /** Accessible label for logo */
    title: propTypes.string,
    /** Optional width of logo */
    width: propTypes.string,
    /** Optional height of logo */
    height: propTypes.string,
};

export default GA131Wordmark;
