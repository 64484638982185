import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import {hubblGreen, ink} from '../../../../common/palette';

import Ic from '..';

const IC31Tick = ({
    color = hubblGreen,
    secondaryColor = ink,
    ...args
}) => (
    <Ic {...args}>
        <path fill={color} d="M99.731 49.865a49.865 49.865 0 0 1-49.866 49.866A49.865 49.865 0 0 1 0 49.865a49.865 49.865 0 0 1 99.731 0z" />
        <path fill={secondaryColor} d="M38.029 76.105 19.945 58.024l6.933-6.931 11.15 11.15 34.758-34.76 6.931 6.948z" />
    </Ic>
);

IC31Tick.propTypes = {
    /** The icon's primary color */
    color: colorType,
    /** The icon's secondary color */
    secondaryColor: colorType,
};

IC31Tick.displayName = 'IC31Tick';

export default IC31Tick;
