import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {srcsetOptionsType} from '@fsa-streamotion/custom-prop-types';

import {stringifySrcsetOptions} from '../../../../../common/stringify-srcset-options';

const LOGO_SIZE_PX = 65;

const TeamLogo = styled.img`
    display: block;
    margin: 0;
    width: ${LOGO_SIZE_PX}px;
    height: ${LOGO_SIZE_PX}px;
    overflow: hidden; /* otherwise alt text looks broken on image 404 */
    font: var(--mui-body-copy-1);
`;

const Logo = ({
    logoSrcsetOptions,
    name = '',
}) => (
    <TeamLogo
        srcSet={stringifySrcsetOptions(logoSrcsetOptions)}
        alt={name}
        sizes={`${LOGO_SIZE_PX}px`}
    />
);

Logo.displayName = 'Logo';

Logo.propTypes = {
    /** Team logo */
    logoSrcsetOptions: srcsetOptionsType,
    /** Team name */
    name: propTypes.string,
};

export default Logo;
