import React from 'react';
import bacon from 'baconjs';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import getContent from '../../../../../todo-move-to-widgets-common/streams/endpoints/xpapi/content';
import panelDataContents from '../panel-data-contents';

import TabbedCarousel from './tabbed-carousel-component';
import massageToDouble from './massage-to-double';

const NoopComponent = Object.assign(() => null, {displayName: 'DoublePanel (empty)'});

// @TODO: get rid of this helper method once "description" field is available on Binge API.
function getpanelDescriptionByBrand(description, title, brand) {
    return brand === 'binge' && title
        ? `All the ${title} you can handle`
        : description;
}

export default function double({
    commonWidgetSettings,
    hydration = {},
    panelData = {},
    allPanelsData,
    key = get(panelData, ['links', 'panels']),
}) {
    const {platformEnv, profile, resourcesEnv, brand} = commonWidgetSettings;

    const extendedTabTitleBus = new bacon.Bus();
    const maxVisibleTilesPerRowBus = new bacon.Bus();
    const requestedContentsBus = new bacon.Bus();

    const panelDataContents$ = panelDataContents({
        hydration: hydration.panelDataContents$,
        panelData,
        platformEnv,
        profile,
        shouldEvaluate: true,
    })
        .startWith()
        .skipDuplicates();

    const requestedContents$ = requestedContentsBus
        .flatMapLatest((url) => getContent({url}))
        .startWith({});

    const maxVisibleTilesPerRow$ = maxVisibleTilesPerRowBus
        .toProperty(0)
        .skipDuplicates();

    const extendedTabTitle$ = extendedTabTitleBus
        .toProperty({title: getpanelDescriptionByBrand(panelData?.description, panelData?.title, brand), index: 0});

    const onSelectItem = ({value, description, title, index, event}) => {
        event.preventDefault();
        requestedContentsBus.push(value);
        extendedTabTitleBus.push({title: getpanelDescriptionByBrand(description, title, brand), index});
    };

    const onMaxVisibleTilesPerRowUpdate = (maxVisibleTiles) => {
        maxVisibleTilesPerRowBus.push(maxVisibleTiles);
    };

    const reactElement$ = bacon.combineTemplate({
        extendedTabTitle: extendedTabTitle$,
        requestedContents: requestedContents$,
        panelDataContents: panelDataContents$,
        maxVisibleTilesPerRow: maxVisibleTilesPerRow$,
    })
        .map(({
            extendedTabTitle = {},
            requestedContents = {},
            panelDataContents,
            maxVisibleTilesPerRow,
        }) => {
            if (Array.isArray(panelDataContents) && !panelDataContents.length) {
                if (process.env.NODE_ENV !== 'production') {
                    console.warn(`Panel ${panelData.title} removed from page as it was empty`);
                }

                // No content state: remove from DOM
                return <NoopComponent key={key} />;
            }

            const contents = isEmpty(requestedContents)
                ? panelDataContents // initial panel
                : get(requestedContents, 'contents', []); // subsequent panels triggered by users clicking on tabs

            const massagedDoubleCarouselContent = massageToDouble(contents, maxVisibleTilesPerRow);

            return (
                <TabbedCarousel
                    allPanelsData={allPanelsData}
                    extendedTabTitle={extendedTabTitle}
                    tabbedCarouselKey={key}
                    massagedDoubleCarouselContent={massagedDoubleCarouselContent}
                    onMaxVisibleTilesPerRowUpdate={onMaxVisibleTilesPerRowUpdate}
                    onSelectItem={onSelectItem}
                    panelDataContents={panelDataContents}
                    brand={brand}
                    resourcesEnv={resourcesEnv}
                />
            );
        });

    return bacon.combineTemplate({
        reactElement: reactElement$,
        hydration: {
            panelDataContents$,
        },
        data: {
            contents: panelDataContents$,
        },
    });
}
