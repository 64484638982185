import React from 'react';
import Ic from '..';

const IC231Profiles = (args) => (
    <Ic {...args}>
        <defs>
            <linearGradient x1="36.087%" y1="82.225%" x2="55.956%" y2="73.662%" id="ic231-profiles__linear-gradient">
                <stop stopColor="currentColor" stopOpacity="0" offset="0%" />
                <stop stopColor="currentColor" offset="100%" />
            </linearGradient>
        </defs>
        <g fill="none" fillRule="nonzero">
            <path
                d="M33.582 35.54c4.34-.08 8.42 2.109 10.773 5.85 2.838 4.837 2.34 9.617-1.42 16.884l-.44.833-.13.24.169.073.16.065.17.065.368.13c.129.042.264.086.405.13l.447.131.494.136.545.14 1.42.342.988.228c5.457 1.247 7.462 4.233 7.66 9.427l.009.317.006 12.027-5 .002-.005-11.917-.007-.25-.022-.47c-.157-2.647-.878-3.563-3.53-4.208l-2.117-.495-1.047-.259-.642-.169-.599-.167c-2.696-.783-4.037-1.581-4.766-3.153l-.076-.171-.064-.153-.044-.16a4.832 4.832 0 0 1 .622-3.922l.025-.037.35-.65.382-.734.354-.705c.34-.693.637-1.345.894-1.962l.243-.605c1.377-3.565 1.297-5.929.032-8.202l-.106-.185a7.407 7.407 0 0 0-6.333-3.448l-.276.008h-.217c-2.63-.109-5.115 1.185-6.49 3.341-1.538 2.536-1.578 5.162.223 9.434l.277.637.303.662.162.34.343.7.371.728.196.375.151.285.032.049a4.906 4.906 0 0 1 .653 3.591l-.057.247-.056.216-.093.203c-.69 1.499-1.802 2.273-4.159 3.024l-.524.161-.564.162-.607.164a62.38 62.38 0 0 1-.319.083l-1.025.258-1.837.445-.196-.001-.322.08-.367.103-.338.106c-1.625.545-2.226 1.324-2.424 3.303l-.035.412c-.005.072-.01.145-.013.219l-.02.463c-.004.08-.006.162-.008.245l-.01.519-.001.825.002.29V82.56h-5V72.234l-.003-.722.002-.664.01-.61.018-.562c.008-.18.017-.353.028-.52l.037-.482c.116-1.241.357-2.142.821-3.154 1.084-2.364 3.183-3.905 6.407-4.718l.64-.151.176-.001 1.21-.292.866-.219.505-.134.664-.189.39-.12.356-.12.328-.12.32-.13.049-.023-.343-.656-.42-.831c-3.458-6.978-3.902-11.65-1.207-16.295l.162-.273a12.365 12.365 0 0 1 10.604-5.707l.225.006.232-.007Z"
                fill="currentColor"
            />
            <path
                d="M67.582 15.54c4.34-.08 8.42 2.109 10.773 5.85 2.838 4.837 2.34 9.617-1.42 16.884l-.44.833-.13.24.169.073.16.065.17.065.368.13c.129.042.264.086.405.13l.447.131.494.136.545.14 1.42.342.988.228c5.457 1.247 7.462 4.233 7.66 9.427l.009.324.006 29.036h-5l-.005-28.931-.007-.25-.022-.47c-.157-2.647-.878-3.563-3.53-4.208l-2.117-.495-1.047-.259-.642-.169-.599-.167c-2.696-.783-4.037-1.581-4.766-3.153l-.076-.171-.064-.153-.044-.16a4.832 4.832 0 0 1 .622-3.922l.025-.037.35-.65.382-.734.354-.705c.34-.693.637-1.345.894-1.962l.243-.605c1.377-3.565 1.297-5.929.032-8.202l-.106-.185a7.407 7.407 0 0 0-6.333-3.448l-.276.008h-.217c-2.63-.109-5.115 1.185-6.49 3.341-1.538 2.536-1.578 5.162.223 9.434l.277.637.303.662.162.34.343.7.371.728.196.375.151.285.032.049a4.906 4.906 0 0 1 .653 3.591l-.057.247-.056.216-.093.203c-.69 1.499-1.802 2.273-4.159 3.024l-.524.161-.564.162-.607.164a62.38 62.38 0 0 1-.319.083l-1.025.258-1.837.445-.196-.001-.322.08-.367.103-.338.106c-1.625.545-2.226 1.324-2.424 3.303l-.035.412c-.005.072-.01.145-.013.219l-.02.463c-.004.08-.006.162-.008.245l-.01.519-.001.825.002.29v6.59h-5v-6.571l-.003-.722.002-.664.01-.61.018-.562c.008-.18.017-.353.028-.52l.037-.482c.116-1.241.357-2.142.821-3.154 1.084-2.364 3.183-3.905 6.407-4.718l.64-.151.176-.001 1.21-.292.866-.219.505-.134.664-.189.39-.12.356-.12.328-.12.32-.13.049-.023-.343-.656-.42-.831c-3.458-6.978-3.902-11.65-1.207-16.295l.162-.273a12.365 12.365 0 0 1 10.604-5.707l.225.006.232-.007Z"
                fill="url(#ic231-profiles__linear-gradient)"
            />
        </g>
    </Ic>
);

IC231Profiles.displayName = 'IC231Profiles';

export default IC231Profiles;
