import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {classNameType, colorType} from '@fsa-streamotion/custom-prop-types';

import muid from '../../../../common/muid';
import {visuallyHiddenBaseStyles} from '../../../../common/visually-hidden';
import {fontFamily} from '../../../../common/typography';

import Ic from '..';
import {lifestyle, sunset} from '../../../../common/palette';

const ReducedMotionLoadingText = styled.span`
    ${visuallyHiddenBaseStyles}

    display: block;
    text-align: center;
    line-height: inherit;
    color: inherit;
    font-family: ${fontFamily};
    font-size: inherit;
`;

const IcWrapper = styled.div``;

const Wrapper = styled.div`
    @media screen and (prefers-reduced-motion: reduce), (update: slow) {
        > ${IcWrapper} {
            display: none;
        }

        > ${ReducedMotionLoadingText} {
            position: static;
            clip: initial;
            width: auto;
            height: auto;
            overflow: auto;
        }
    }
`;

const Stroke = styled.circle`
    @keyframes spinner {
        0% {
            stroke-dashoffset: 0;
            ${''/* stroke-dasharray: calc(3.14px * 0.25 * 82) calc(3.14px * 0.75 * 82); */}
            stroke-dasharray: 64px 193px;
        }

        19% {
            ${'' /* stroke-dashoffset: calc(-3.14px * 0.3 * 82);
            stroke-dasharray: calc(3.14px * 0.7 * 82) calc(3.14px * 0.3 * 82); */}
            stroke-dashoffset: -77px;
            stroke-dasharray: 180px 77px;
            animation-timing-function: linear;
        }

        27% {
            ${'' /* stroke-dashoffset: calc(-3.14px * 0.6 * 82);
            stroke-dasharray: calc(3.14px * 0.8 * 82) calc(3.14px * 0.2 * 82); */}
            stroke-dashoffset: -155px;
            stroke-dasharray: 206px 52px;
            animation-timing-function: linear;
        }

        32% {
            ${'' /* stroke-dashoffset: calc(-3.14px * 0.8 * 82);
            stroke-dasharray: calc(3.14px * 0.7 * 82) calc(3.14px * 0.3 * 82); */}
            stroke-dashoffset: -206px;
            stroke-dasharray: 180px 77px;
            animation-timing-function: linear;
        }

        53% {
            ${'' /* stroke-dashoffset: calc(-3.14px * 1.6 * 82);
            stroke-dasharray: calc(3.14px * 0.6 * 82) calc(3.14px * 0.4 * 82); */}
            stroke-dashoffset: -412px;
            stroke-dasharray: 155px 103px;
            animation-timing-function: linear;
        }

        59% {
            ${'' /* stroke-dashoffset: calc(-3.14px * 2 * 82);
            stroke-dasharray: calc(3.14px * 0.3 * 82) calc(3.14px * 0.7 * 82); */}
            stroke-dashoffset: -515px;
            stroke-dasharray: 77px 180px;
            animation-timing-function: ease-out;
        }

        70% {
            ${'' /* stroke-dashoffset: calc(-3.14px * 2.25 * 82);
            stroke-dasharray: calc(3.14px * 0.25 * 82) calc(3.14px * 0.75 * 82); */}
            stroke-dashoffset: -580px;
            stroke-dasharray: 64px 193px;
            animation-timing-function: ease-in-out;
        }

        100% {
            ${'' /* stroke-dashoffset: calc(-3.14px * 2 * 82);
            stroke-dasharray: calc(3.14px * 0.25 * 82) calc(3.14px * 0.75 * 82); */}
            stroke-dashoffset: -515px;
            stroke-dasharray: 64px 193px;
        }
    }
    fill: transparent;
    stroke-linecap: round;
    animation: spinner ease-in 2s normal infinite;
`;

// @TODO-LifeStyle: have asked the design team to clarify the gradient values for lifestyle
const IC103Loading = ({
    display = 'inline-block',
    id = muid(),
    size,
    backgroundColor = '#373948',
    topColor = lifestyle,
    middleColor = lifestyle,
    bottomColor = sunset,
    ...props
}) => (
    <Wrapper>
        <IcWrapper>
            <Ic id={id} display={display} size={size} {...props}>
                <defs>
                    <linearGradient id={`ic-103-loading-${id}__linear-gradient`} x1="85.534%" y1="94.743%" x2="90.69%" y2="-2.517%">
                        <stop stopColor={bottomColor} offset="0%" stopOpacity="50%" />
                        <stop stopColor={middleColor} offset="50%" />
                        <stop stopColor={middleColor} offset="85%" />
                        <stop stopColor={topColor} offset="100%" />
                    </linearGradient>
                </defs>
                <circle
                    cx="50" cy="50" r="41" stroke={backgroundColor} fill="transparent"
                    strokeWidth="9"
                />
                <Stroke cx="50" cy="50" r="41" stroke={`url(#ic-103-loading-${id}__linear-gradient)`} strokeWidth="9" />
            </Ic>
        </IcWrapper>
        <ReducedMotionLoadingText>
            Loading ...
        </ReducedMotionLoadingText>
    </Wrapper>
);

IC103Loading.propTypes = {
    /** Additional class(es) */
    className: classNameType,
    /** Component ID <br>
     * **NOTE**: for animated SVGs, this is required for animation to work */
    id: propTypes.string,
    /** The loader's display mode */
    display: propTypes.oneOf(['block', 'inline-block']), // there are many other values, but you probably shouldn't set them. update this if you get sick of the warnings though
    /** Width size for the icon component, defaults to 100% */
    size: propTypes.oneOfType([propTypes.string, propTypes.number]),
    /** Color for the background of the loading circle */
    backgroundColor: colorType,
    /** Color for the top 15% of the loading circle */
    topColor: colorType,
    /** Color for the middle 55% of the loading circle */
    middleColor: colorType,
    /** Color for the bottom 30% of the loading circle */
    bottomColor: colorType,
};

IC103Loading.displayName = 'IC103Loading';

export default IC103Loading;
