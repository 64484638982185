import padStart from 'lodash/padStart';

const SECONDS_IN_DAY = 86400;
const SECONDS_IN_HOUR = 3600;
const SECONDS_IN_MINUTE = 60;
const MINUTES_IN_HOUR = 60;

/**
 * Given an amount of SECONDS, format into a nice display format, with sensible leading zeros where needed
 * We're using seconds here because working with HTML5 video where all the APIs work in seconds
 *
 * - Only numbers are supported.
 * - Negative numbers are converted to positive.
 * - Partial seconds are rounded DOWN
 * - Non finite numbers default to 0:00
 *
 *     0 seconds          0:00
 *     3 seconds          0:03
 *   3.5 seconds          0:03
 *    30 seconds          0:30
 *    90 seconds          1:30
 *   900 seconds         15:00
 *  3600 seconds       1:00:00
 * 36000 seconds      10:00:00
 * 86400 seconds    1:00:00:00
 *
 *          null          0:00
 *      Infinity          0:00
 *     undefined          0:00
 *
 * @param  {number} secondsToFormat  Number of seconds to format into a nice label.
 *
 * @returns {string}                 Nicely formatted time string
 */
export function formatSecondsIntoStandardLabel(secondsToFormat) { // eslint-disable-line import/prefer-default-export
    if (!Number.isFinite(secondsToFormat)) { // don't try and parse anything exotic
        return '0:00';
    }

    const positiveSeconds = Math.abs(secondsToFormat); // convert negative numbers to positive
    const days = Math.floor(positiveSeconds / (SECONDS_IN_DAY));
    const hours = Math.floor((positiveSeconds - (days * SECONDS_IN_DAY)) / SECONDS_IN_HOUR);
    const minutes = Math.floor(((positiveSeconds - (days * SECONDS_IN_DAY)) - (hours * SECONDS_IN_HOUR)) / MINUTES_IN_HOUR);
    const seconds = Math.floor(positiveSeconds) % SECONDS_IN_MINUTE;

    return [
        days || null,  // days
        hours || null, // hours
        minutes,       // always show minutes
        seconds,       // always show seconds
    ]
        .reduce((timeUnits, time) => {
            if (timeUnits.length) { // this isn't the first applicable time unit we've found. pad it with zeros and add to the stack
                timeUnits.push(padStart(time, 2, '0'));
            } else if (time !== null) { // we've found our first time unit! just show the number with no padLeft
                timeUnits.push(time);
            }

            return timeUnits;
        }, [])
        .join(':');
}
