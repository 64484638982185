import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {SCREEN_1024_DESKTOP, SCREEN_1920_DESKTOP} from '../../../../../common/screen-sizes';
import {ListItem} from './common';

const TagsContainer = styled.ul`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
    padding: 14px 0 0;
    height: 1em;
    overflow: hidden;
    font: var(--infinity-poster-attribute-tags);

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        padding-top: 21px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding-top: 28px;
    `}
`;

const Tags = ({children}) => (
    <TagsContainer>
        {React.Children.map(children, (tag) => (
            <ListItem
                separator="·"
                separatorOpacity={0.4}
            >{tag}
            </ListItem>
        ))}
    </TagsContainer>
);

Tags.propTypes = {
    children: propTypes.arrayOf(propTypes.node),
};

Tags.displayName = 'Tags';

export default Tags;
