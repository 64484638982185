import React from 'react';
import propTypes from 'prop-types';

import {srcsetOptionsType, classNameType} from '@fsa-streamotion/custom-prop-types';

import Tile from '../tile';
import Logo from '../logo';
import Name from '../name';

import FootballScore from './score';

const FootballTile = ({
    className,
    hasNoSpoilers,
    logoSrcsetOptions,
    matchState,
    otherTeamData,
    teamData,
    tileAlign,
}) => (
    <Tile
        className={className}
        hasNoSpoilers={hasNoSpoilers}
        logo={<Logo logoSrcsetOptions={logoSrcsetOptions} name={teamData.name} />}
        matchState={matchState}
        name={<Name code={teamData.code} name={teamData.name} />}
        score={hasNoSpoilers
            ? undefined
            : (
                <FootballScore
                    score={teamData.score}
                    shootoutScore={teamData.shoot_out_score}
                    isShootoutScoreVisible={!(teamData.shoot_out_score === null && otherTeamData.shoot_out_score === null)}
                />
            )
        }
        tileAlign={tileAlign}
    />
);

FootballTile.propTypes = {
    /** className */
    className: classNameType,
    /** Don't show any spoilers, i.e match result, score */
    hasNoSpoilers: propTypes.bool,
    /** Team logo */
    logoSrcsetOptions: srcsetOptionsType,
    /** State for the match, before starting (pre,) during (live,) and finished (post) */
    matchState: propTypes.oneOf(['pre', 'live', 'post']),
    /** Name, score info, code, etc. of opposing team from statsapi's scoreboard.json */
    otherTeamData: propTypes.shape({
        code: propTypes.string,
        name: propTypes.string,
        score: propTypes.number,
        shoot_out_score: propTypes.number,
    }),
    /** Name, score info, code, etc. from statsapi's scoreboard.json */
    teamData: propTypes.shape({
        code: propTypes.string,
        name: propTypes.string,
        score: propTypes.number,
        shoot_out_score: propTypes.number,
    }),
    /** Align to the right for a left one, align to the left for a right one */
    tileAlign: propTypes.oneOf(['left', 'right']),
};

FootballTile.displayName = 'FootballTile';

export default FootballTile;
