/* stylelint-disable custom-property-pattern */
import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {Sup} from 'normalized-styled-components';
import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {stylesWhenBinge, stylesWhenFlash, stylesWhenKayo, stylesWhenLifestyle} from '../../../../../utils/styles-when-brand';

import brandPropType from '../../../../../common/brand-proptype';
import {
    SCREEN_375_PHABLET,
    SCREEN_415_PHABLET,
    SCREEN_1280_DESKTOP,
    SCREEN_1920_DESKTOP,
} from '../../../../../common/screen-sizes';

import splitPrice from './split-price';

const TERM_TYPE_SHORT = {
    day: 'day',
    week: 'wk',
    month: 'mth',
    annual: 'yr',
};

export const getShortTermTypeText = (termType) => TERM_TYPE_SHORT[termType]
    ? `/${TERM_TYPE_SHORT[termType]}`
    : '';

/** ********************************************
 *                   WRAPPER
 ***********************************************/

const brandingFonts = css`
    ${stylesWhenBinge`
        --pricing-column-package-name-font: var(--ionic-body-copy-5);
        --pricing-column-package-price-font: var(--ionic-plan-price);
        --pricing-column-package-price-decimals-font: var(--ionic-body-copy-6);
        --pricing-column-package-period-font: var(--ionic-body-copy-6);
        --pricing-column-package-secondary-text-font: var(--ionic-body-copy-6);
    `}

    ${stylesWhenFlash`
        --pricing-column-package-name-font: var(--infinity-body-copy-5);
        --pricing-column-package-price-font: var(--infinity-plan-price);
        --pricing-column-package-price-decimals-font: var(--infinity-body-copy-6);
        --pricing-column-package-period-font: var(--infinity-body-copy-6);
        --pricing-column-package-secondary-text-font: var(--infinity-body-copy-6);

    `}

    ${stylesWhenKayo`
        --pricing-column-package-name-font: var(--mui-body-copy-5);
        --pricing-column-package-price-font: var(--mui-plan-price);
        --pricing-column-package-price-decimals-font: var(--mui-body-copy-6);
        --pricing-column-package-period-font: var(--mui-body-copy-6);
        --pricing-column-package-secondary-text-font: var(--mui-body-copy-6);
    `}

    ${stylesWhenLifestyle`
        --pricing-column-package-name-font: var(--nucleus-body-copy-5);
        --pricing-column-package-price-font: var(--nucleus-plan-price);
        --pricing-column-package-price-decimals-font: var(--nucleus-body-copy-6);
        --pricing-column-package-period-font: var(--nucleus-body-copy-6);
        --pricing-column-package-secondary-text-font: var(--nucleus-body-copy-6);
    `}
`;

const HeaderCell = styled.th.attrs({
    scope: 'row',
})`
    ${brandingFonts};
    /* For 320 viewport, lessen right and left padding so decimal price,
       footnote marker and package period stay in place */
    padding: 28px 2px 21px 15px;
    text-align: left;

    ${mediaQuery({minWidthPx: SCREEN_375_PHABLET})`
        padding-right: 10px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_415_PHABLET})`
        padding-right: 21px;
        padding-left: 28px;
    `}
`;

const HeaderCellContentWrapper = styled.span`
    display: block;
    position: relative;
`;

/** ********************************************
 *          PACKAGE PRICING DETAILS
 ***********************************************/

// line 1 - Package name

const PackageName = styled.span`
    display: block;
    text-transform: uppercase;
    font: var(--pricing-column-package-name-font);
`;

// line 2: price & term

const PackagePrice = styled.span`
    position: relative;
    font: var(--pricing-column-package-price-font);
`;

// FREE price wrapper
const StyledUppercase = styled.span`
    text-transform: uppercase;
`;

// Wrapper for decimals & footnote marker
const SupContainer = styled(Sup)`
    position: absolute;
    top: 2px;

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        top: 0;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        top: -1px;
    `}
`;

const PackagePriceDecimals = styled.span`
    font: var(--pricing-column-package-price-decimals-font);
`;

const FootnoteMarker = styled.span`
    padding-left: 2px;
    /* NOTE: Leaving this for all brands for now until footnote marker font is available in binge & flash */
    font: var(--mui-footnote-marker-2);
`;

const PackagePeriod = styled.span`
    margin-left: 2px;
    font: var(--pricing-column-package-period-font);
`;

// Line 3: Secondary text (price[1]): ie. "or $100/yr", "was $15/mth"

const PackageSecondaryText = styled.span`
    display: block;
    font: var(--pricing-column-package-secondary-text-font);
`;

/** ********************************************
 *                  MAIN
 ***********************************************/

const PricingColumnCell = ({
    brand,
    currencyDecimalPlaces,
    currencyPrefix,
    displayName,
    footnoteMarker,
    secondaryText,
    prices,
}) => {
    // Picking the first price for now, the prices array should only contain one value.
    const [{termType, displayAmount, displayRegularAmount}] = prices;
    const [priceIntegers, priceDecimals] = splitPrice(displayAmount, currencyDecimalPlaces);

    const hasPriceChange = displayRegularAmount !== displayAmount;

    return (
        <HeaderCell brand={brand}>
            <HeaderCellContentWrapper>

                <PackageName>
                    {displayName}
                </PackageName>

                {displayAmount === 0
                    ? (
                        <PackagePrice>
                            <StyledUppercase>Free</StyledUppercase>
                        </PackagePrice>
                    )
                    : (
                        <PackagePrice aria-label={`${currencyPrefix}${displayAmount} per ${termType}`}>
                            {currencyPrefix}{priceIntegers}

                            {(!!priceDecimals || !!footnoteMarker) && (
                                <SupContainer>
                                    {!!priceDecimals && (
                                        <PackagePriceDecimals>
                                            .{priceDecimals}
                                        </PackagePriceDecimals>
                                    )}

                                    {/* Only appear if there's no price change, because it doesn't make sense to display on secondarytext as well */}
                                    {!!footnoteMarker && !hasPriceChange && (
                                        <FootnoteMarker>
                                            {footnoteMarker}
                                        </FootnoteMarker>
                                    )}

                                </SupContainer>
                            )}

                            <PackagePeriod>
                                {getShortTermTypeText(termType)}
                            </PackagePeriod>
                        </PackagePrice>
                    )}

                {hasPriceChange && (
                    <PackageSecondaryText>
                        <span aria-label={`Was ${currencyPrefix}${displayRegularAmount} per ${termType}`}>
                            was
                            {' '}
                            <del>
                                {currencyPrefix}{displayRegularAmount}{footnoteMarker || ''}{getShortTermTypeText(termType)}
                            </del>
                        </span>
                    </PackageSecondaryText>
                )}

                <PackageSecondaryText>
                    <StyledUppercase>{secondaryText}</StyledUppercase>
                </PackageSecondaryText>

            </HeaderCellContentWrapper>
        </HeaderCell>
    );
};

PricingColumnCell.displayName = 'PackageNameAndPriceCell';

PricingColumnCell.propTypes = {
    brand: brandPropType,
    currencyDecimalPlaces: propTypes.number,
    currencyPrefix: propTypes.string,
    displayName: propTypes.string,
    footnoteMarker: propTypes.string,
    secondaryText: propTypes.string,
    prices: propTypes.arrayOf(propTypes.shape({
        termType: propTypes.oneOf(['annual', 'month', 'week', 'day']),
        displayRegularAmount: propTypes.number,
        displayAmount: propTypes.number,
    })),
};

export default PricingColumnCell;
