import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import noop from 'lodash/noop';
import invoke from 'lodash/invoke';
import ResizeObserver from 'resize-observer-polyfill';

import {mediaQuery, stylesWhen, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';

import {white} from '../../../../common/palette';
import {SCREEN_PHABLET} from '../../../../common/screen-sizes';
import BA21PayBtn from '../../../atoms/ba/21-pay-btn';
import BA22EditBtn from '../../../atoms/ba/22-edit-btn';
import {IC103Loading} from '../../../atoms/ic';
import TM24AlertTile from '../../../molecules/tm/24-alert-tile';
import OR121TooltipCta from '../121-tooltip-cta';

const PAYMENT_METHODS = {
    paypal: 'paypal',
    creditCard: 'creditCard',
    tbill: 'tbill',
};

const PaymentSection = styled.section`
    display: grid;
    grid-row-gap: 28px;
    color: ${white};
`;

const PaymentOptions = styled.div`
    display: grid;
    grid-auto-columns: 1fr;
    grid-gap: 7px;

    ${stylesWhenNot('theme.isAppDock')`
        ${mediaQuery({minWidthPx: SCREEN_PHABLET})`
            grid-gap: 21px;
        `}
    `}
`;

const PaymentButton = styled(BA21PayBtn)`
    grid-row: 1;
`;

const PaymentConfirmText = styled.p`
    margin: 0;
    font: var(--mui-body-copy-3);
`;

const ZuoraIframe = styled.div`
    position: relative;

    ${stylesWhen('hide')`
        display: none;
    `}
`;

const ZuoraIframeHolder = styled.div`
    opacity: ${({isVisible}) => isVisible ? 1 : 0};
    /* Override Zuora's ugly defaults */
    > iframe {
        background-color: transparent !important; /* stylelint-disable-line declaration-no-important */
        width: 100%;
    }
`;

const ZuoraIframeStatus = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

export default class OR52PayDtls extends React.Component {
    static displayName = 'OR52PayDtls';

    static propTypes = {
        /** Help text for why we need credit card details */
        ccHelpText: propTypes.string,
        /** Query text for why we need credit card details */
        ccQueryText: propTypes.string,
        /** Payment method */
        initialPaymentMethod: propTypes.oneOf(Object.values(PAYMENT_METHODS)),
        /** Does the customer have Telstra TBilling */
        hasTelstraTBill: propTypes.bool,
        /** Is PayPal available as a payment option? */
        hasPayPal: propTypes.bool,
        /** Pass the relevant payment change to the parent */
        onPaymentChange: propTypes.func,
        /** Asynchronously open a Zuora iframe */
        hostedPaymentPageRenderer: propTypes.func,
        /** Called when the hosted payment page has been loaded */
        onHostedPaymentPageLoaded: propTypes.func,
        /** Error message to show when loading the payments page failed */
        paymentsPageLoadingErrorMessage: propTypes.node,
        /** Gift card UI, if any, applicable to the current payment method, as provided by onPaymentChange */
        giftCard: propTypes.node,
        /** Label for Telstra button */
        telstraButtonLabel: propTypes.string,
        /** Telstra payment confirmation text */
        telstraPaymentConfirmationText: propTypes.string,
    };

    static defaultProps = {
        ccHelpText: 'We use your credit card to help verify your identity and to commence the paid period of your subscription after the free trial.', // eslint-disable-line max-len
        ccQueryText: 'Why do you need my card?',
        initialPaymentMethod: PAYMENT_METHODS.creditCard,
        hasPayPal: true,
        hostedPaymentPageRenderer: noop,
        onHostedPaymentPageLoaded: noop,
        onPaymentChange: noop,
        telstraButtonLabel: 'Pay with Telstra',
        telstraPaymentConfirmationText: 'By agreeing to the Terms and Conditions below, your Kayo Sports membership will continue to be added and billed through Pay with Telstra.', // eslint-disable-line max-len
    };

    state = {
        paymentMethod: this.props.initialPaymentMethod,
        isHostedPaymentPageContainerRendered: this.props.initialPaymentMethod === PAYMENT_METHODS.creditCard,
        isSummoningPaymentsPage: false,
        isLoadingPaymentsPage: false,
    };

    componentWillUnmount() {
        this.zuoraIframeResizeObserver.disconnect();
    }

    updateIframeHolderWidth = () => {
        invoke(window, 'Z.post', 'z_hppm_iframe', 'resize');
    };

    zuoraIframeResizeObserver = new ResizeObserver(this.updateIframeHolderWidth);

    updateConfirmButtonText = (paymentMethod) => () => {
        this.props.onPaymentChange(paymentMethod);
        this.setState({paymentMethod});

        if (paymentMethod === PAYMENT_METHODS.creditCard && !this.state.isHostedPaymentPageContainerRendered) {
            this.setState({isHostedPaymentPageContainerRendered: true});
        }
    };

    onZuoraIframeLoaded = () => {
        this.setState({
            isSummoningPaymentsPage: false,
            isLoadingPaymentsPage: false,
        }, () => {
            this.zuoraIframeResizeObserver.observe(this.zuoraIframeHolderElement);
            this.props.onHostedPaymentPageLoaded();
        });
    };

    zuoraIframeHolderRef = (element) => {
        if (this.zuoraIframeHolderElement !== element) {
            this.zuoraIframeHolderElement = element;

            if (element) {
                this.setState({isSummoningPaymentsPage: true}, () => {
                    this.props.hostedPaymentPageRenderer();
                });

                this.iframeHolderMutationObserver = new MutationObserver((mutations) => {
                    for (const mutation of mutations) {
                        if (mutation.type !== 'childList') {
                            continue;
                        }

                        // If the iframe has been added, listen to its load event
                        for (const node of mutation.addedNodes) {
                            if (!(node instanceof HTMLIFrameElement)) {
                                continue;
                            }

                            node.addEventListener('load', this.onZuoraIframeLoaded);

                            // Show the loading indicator
                            this.setState({isLoadingPaymentsPage: true});
                            break;
                        }

                        // If the iframe has been removed, stop listening to its load event
                        for (const node of mutation.removedNodes) {
                            if (!(node instanceof HTMLIFrameElement)) {
                                continue;
                            }

                            node.removeEventListener('load', this.onZuoraIframeLoaded);
                            break;
                        }
                    }
                });

                this.iframeHolderMutationObserver.observe(element, {childList: true});
            } else if (this.iframeHolderMutationObserver) {
                this.iframeHolderMutationObserver.disconnect();
                delete this.iframeHolderMutationObserver;

                this.setState({
                    isSummoningPaymentsPage: false,
                    isLoadingPaymentsPage: false,
                });
            }
        }
    };

    render() {
        const {
            ccHelpText,
            ccQueryText,
            hasTelstraTBill,
            hasPayPal,
            telstraButtonLabel,
            telstraPaymentConfirmationText,
        } = this.props;

        const {paymentMethod, isHostedPaymentPageContainerRendered} = this.state;

        const isCreditCardFlow = paymentMethod === PAYMENT_METHODS.creditCard;
        const isPaypalFlow = paymentMethod === PAYMENT_METHODS.paypal;
        const isTelstraFlow = paymentMethod === PAYMENT_METHODS.tbill;

        const isIframeStatusVisible = (
            this.state.isSummoningPaymentsPage
            || this.state.isLoadingPaymentsPage
            || this.props.paymentsPageLoadingErrorMessage
        );

        return (
            <PaymentSection className="OR52PayDtls">
                {!!(hasTelstraTBill || hasPayPal) && (
                    <PaymentOptions>
                        {!!hasTelstraTBill && (
                            <PaymentButton
                                aria-pressed={isTelstraFlow}
                                onClick={this.updateConfirmButtonText(PAYMENT_METHODS.tbill)}
                            >
                                {telstraButtonLabel}
                            </PaymentButton>
                        )}

                        <PaymentButton
                            aria-pressed={isCreditCardFlow}
                            onClick={this.updateConfirmButtonText(PAYMENT_METHODS.creditCard)}
                        >
                            Credit card
                        </PaymentButton>

                        {!!hasPayPal && (
                            <PaymentButton
                                aria-pressed={isPaypalFlow}
                                onClick={this.updateConfirmButtonText(PAYMENT_METHODS.paypal)}
                            >
                                PayPal
                            </PaymentButton>
                        )}
                    </PaymentOptions>
                )}

                {!!isHostedPaymentPageContainerRendered && (
                    <ZuoraIframe hide={!isCreditCardFlow}>
                        <ZuoraIframeHolder
                            id="zuora_payment"
                            ref={this.zuoraIframeHolderRef}
                            isVisible={!isIframeStatusVisible}
                        />

                        {
                            isIframeStatusVisible ? (
                                <ZuoraIframeStatus>
                                    {this.state.isLoadingPaymentsPage ? (
                                        <IC103Loading
                                            size="70px"
                                            color={white}
                                        />
                                    ) : (!!this.props.paymentsPageLoadingErrorMessage && (
                                        <TM24AlertTile>
                                            {this.props.paymentsPageLoadingErrorMessage}
                                        </TM24AlertTile>
                                    ))}
                                </ZuoraIframeStatus>
                            ) : (
                                <OR121TooltipCta
                                    cta={(
                                        <BA22EditBtn
                                            className="OR52PayDtls__help-text"
                                        >
                                            {ccQueryText}
                                        </BA22EditBtn>
                                    )}
                                    isLightTheme={true}
                                >
                                    {ccHelpText}
                                </OR121TooltipCta>
                            )
                        }
                    </ZuoraIframe>
                )}

                {this.props.giftCard}

                {isPaypalFlow && (
                    <PaymentConfirmText>
                        You will be redirected to PayPal to complete your payment.
                    </PaymentConfirmText>
                )}

                {isTelstraFlow && (
                    <PaymentConfirmText>{telstraPaymentConfirmationText}</PaymentConfirmText>
                )}
            </PaymentSection>
        );
    }
}
