import bacon from 'baconjs';
import superagent from 'superagent';
import get from 'lodash/get';
import invoke from 'lodash/invoke';

import {nicerStringify} from '@fsa-streamotion/browser-utils';
import {getApiFromRetryWithError} from '@fsa-streamotion/request-manager';
import {BILLING_API_URL} from '@fsa-streamotion/streamotion-web-widgets-common';

import {BILLING_PRODUCT_NAME_MAP} from '../../../utils/constants';

const getBaconErrorGenerator = ({brand, platformEnv}) => ({url, errorCode, errorMessage}) => (
    new bacon.Error({
        errorCode,
        errorMessage,
        fullErrorMessage: `Accounts-Widgets: couldn't get offers (${url}): ${JSON.stringify({errorCode, errorMessage, brand, platformEnv})}`,
    })
);

/**
 * Get all sign-up offers with an optional voucher code. The voucher is applied to the offers if it is valid.npm add
 *
 * Examples:
 * http://martian-billing.martian-billing-pr-34.dev.cluster.foxsports-gitops-prod.com.au/v2/offers/ares/
 * http://martian-billing.martian-billing-pr-34.dev.cluster.foxsports-gitops-prod.com.au/v2/offers/kayo/LAUNCH
 * http://martian-billing.martian-billing-pr-34.dev.cluster.foxsports-gitops-prod.com.au/v2/offers/kayo/BASICONLY
 * http://martian-billing.martian-billing-pr-34.dev.cluster.foxsports-gitops-prod.com.au/v2/offers/kayo/INVALID
 * http://martian-billing.martian-billing-pr-34.dev.cluster.foxsports-gitops-prod.com.au/v2/offers/kayo?voucher=COG001
 *
 * @param {Object} options                            - (see below)
 * @param {string} [options.platformEnv]              - Billing API environment
 * @param {string} [options.offer='']                 - offer plan family
 * @param {string} [options.brand]                    - brand (kayo, binge, flash)
 * @param {string} [options.accessToken]              - optional access token to customise offer
 * @param {string} [options.retries]                  - optional how many times to retry
 * @param {string} [options.voucher]                  - an optional voucher code
 * @param {string} [options.ddSubscriptionId]         - an optional ddSubscriptionId code for Optus SubHub
 * @param {string} [options.merchantSubscriptionId]   - an optional merchantSubscriptionId code for Optus SubHub
 * @param {string} [options.termType]                 - an optional termType (monthly | annual)
 *
 * @returns {bacon.Observable} a stream that emits the endpoint response body
 */
export default function getOfferStream({
    accessToken,
    brand,
    offer = '', // API to give us a default offer
    platformEnv,
    retries,
    voucher,
    ddSubscriptionId,
    merchantSubscriptionId,
    termType,
} = {}) {
    if (!brand) {
        return bacon.later(0, new bacon.Error('Accounts Widgets getOfferStream: Cannot fetch offer without brand'));
    }

    const generateBaconError = getBaconErrorGenerator({brand, platformEnv});

    const queryParams = nicerStringify({
        voucher: voucher || undefined, // ?voucher= doesn't work....
        ddSubscriptionId: ddSubscriptionId || undefined,
        merchantSubscriptionId: merchantSubscriptionId || undefined,
        termType,
    });
    const product = BILLING_PRODUCT_NAME_MAP[brand];
    const url = `${BILLING_API_URL[platformEnv]}/v2/offers/${encodeURIComponent(product)}/${encodeURIComponent(offer ?? '')}${queryParams ? `?${queryParams}` : ''}`;

    if (!accessToken) {
        return getApiFromRetryWithError({
            url,
            retries,
        })
            .flatMapLatest((response) => {
                if (response.responseConsideredValid) {
                    return response.parseResponseText();
                } else {
                    const errorMessage = get(response, 'superAgentErr.message')
                        || invoke(response, 'superAgentErr.toString')
                        || 'superAgentErr object missing';

                    return generateBaconError({url, errorCode: response.responseStatus, errorMessage});
                }
            });
    }

    return bacon.fromBinder((sink) => {
        superagent
            .get(url)
            .set('Authorization', `Bearer ${accessToken}`)
            .timeout(30 * 1000)
            .then((response) => void sink(response.body))
            .catch((error) => {
                const errorBody = JSON.parse(error?.response?.text || '""');
                const errorMessage = get(errorBody, 'message', error.toString());

                sink(generateBaconError({url, errorCode: error.status, errorMessage}));
            })
            .finally(() => sink(new bacon.End()));

        return;
    });
}
