import React from 'react';
import propTypes from 'prop-types';
import {rgba} from 'polished';
import styled from 'styled-components';
import {Button} from 'normalized-styled-components';
import classnames from 'classnames';

import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {ariaPressedType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {coal, kayoGreen, white} from '../../../../common/palette';
import {transition} from '../../../../common/animations';
import IC31Tick from '../../ic/31-tick';

const CHECKMARK_SIZE_PX = 21;
const CHECKMARK_OFFSET_HOVER_PX = CHECKMARK_SIZE_PX / -2;

const StyledButton = styled(Button)`
    appearance: none;
    box-sizing: border-box;
    position: relative;
    transition: ${transition('border-color')};
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: ${rgba(coal, 0.6)};
    padding: 0;
    min-width: 156px;
    height: 50px;
    color: ${white};
    font: var(--mui-body-copy-3);
    line-height: 1; /* stylelint-disable-line order/properties-order */

    ${stylesWhen('isBlock')`
        width: 100%;
    `}

    &:focus,
    &:hover {
        outline: 0;
        border-color: ${kayoGreen};
    }

    &[aria-pressed='true'] {
        &:focus,
        &:hover {
            outline: 0;
            border-color: ${kayoGreen};
        }
    }
`;

const Checkedmark = styled.span`
    position: absolute;
    top: var(--BA03RadBtn-checkbox-offset, ${CHECKMARK_OFFSET_HOVER_PX}px);
    right: var(--BA03RadBtn-checkbox-offset, ${CHECKMARK_OFFSET_HOVER_PX}px);
    transform: scale(${({isPressed}) => isPressed ? '100%' : 0});
    transition: 300ms cubic-bezier(0.68, -0.55, 0.27, 1.55); /* https://easings.net/#easeInOutBack */
`;

const BA03RadBtn = ({
    'aria-pressed': isPressed,
    children,
    className,
    hasDropshadow,
    onClick,
    ...htmlAttributes
}) => (
    <StyledButton
        {...htmlAttributes}
        {...{
            onClick,
        }}
        className={classnames('BA03RadBtn', className)}
        aria-pressed={isPressed}
        type="button"
    >
        {children}
        <Checkedmark isPressed={isPressed} role="presentation">
            <IC31Tick size={`${CHECKMARK_SIZE_PX}px`} hasDropshadow={hasDropshadow} />
        </Checkedmark>
    </StyledButton>
);

BA03RadBtn.displayName = 'BA03RadBtn';

/* eslint-disable quote-props */
BA03RadBtn.propTypes = {
    /** aria-pressed attribute describes a toggle button */
    'aria-pressed': ariaPressedType,
    /** Display checkmark's dropshadow  */
    hasDropshadow: propTypes.bool,
    /** Callback on element click */
    onClick: propTypes.func,
    /** Content of button, e.g. text */
    children: propTypes.node.isRequired,
    /** Additional CSS class(es) */
    className: classNameType,
};
/* eslint-enable quote-props */

export default BA03RadBtn;
