import React, {useState, useRef} from 'react';
import styled from 'styled-components';
import attempt from 'lodash/attempt';
import invoke from 'lodash/invoke';
import classnames from 'classnames';
import propTypes from 'prop-types';
import {rgba} from 'polished';
import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {Sup, Button, Section} from '../../../../common/normalized-styled-components';
import {ink, black, white} from '../../../../common/palette';
import {SCREEN_1280_DESKTOP, SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import {getShortTermTypeText, splitPrice} from '../../../../common/price-term';

const Wrapper = styled(Section)`
    display: inline-flex;
    position: relative;
    gap: 8px;
    align-items: stretch;
    justify-content: center;

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        gap: 14px;
    `}
`;

const StyledButton = styled(Button)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 4px solid transparent;
    border-radius: 6px;
    box-shadow: 0 5px 10px 0 ${rgba(black, 0.15)};
    background-color: ${rgba(white, 0.3)};
    padding: 28px 3px;
    width: 106px;
    height: 106px;

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        width: 162px;
        height: 162px;
    `}

    /** Focus / Hover */
    &:focus,
    &:hover {
        transform: scale(1.07);
        transition: transform 0.1s ease-in-out;
        outline: none;
        outline-offset: 4px;
        border: none;
        background-color: ${rgba(white, 0.3)};
        cursor: pointer;
    }
    /** Pressed / Selected */
    &:active,
    &[aria-pressed='true'] {
        transform: scale(1);
        outline: 1px solid ${ink};
        outline-offset: 4px;
        border: none;
        width: 106px;
        height: 106px;

        ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
            transform: scale(1);
            width: 162px;
            height: 162px;
        `}
    }
    /** Selected */
    &[aria-pressed='true'] {
        transform: scale(1);
        outline-offset: 4px;
        border: none;
        background-color: ${white};
        width: 106px;
        height: 106px;

        ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
            transform: scale(1);
            width: 162px;
            height: 162px;
        `}
    }
`;

const StyledDisplayName = styled.div`
    align-self: center;
    opacity: 0.8;
    max-width: 123px;
    color: ${ink};
    font: var(--quicksilver-body-copy-4);
`;

const StyledPrice = styled.div`
    position: relative;
    top: 2px;
    align-self: baseline;
    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        align-self: center;
    `}

    opacity: 0.8;
    color: ${ink};
    font: var(--quicksilver-offer-price-footer);
`;

const StyledPriceDecimal = styled.span`
    opacity: 0.8;
    font: var(--quicksilver-offer-period-footer);
`;

const CurrencyPrefixWrapper = styled.span`
    font: var(--quicksilver-offer-period-footer);
`;

const FootnoteMarker = styled.span`
    padding-left: 3px;
`;

const StyledTermType = styled.span`
    font: var(--quicksilver-offer-period-footer);
`;

const StyledSecondaryText = styled.div`
    opacity: 0.3;
    margin-top: -5px;
    text-align: center;
    color: ${ink};
    font: var(--quicksilver-offer-period-footer);

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
         margin-top: 3px;
    `}
`;

/**
 * Size selctor CTAs
 * - GM10SizePicker used as a size picker.
 *
 * @see {@link https://zpl.io/ggeg9oD}
 */

const GM10SizePicker = ({
    className,
    defaultSelectedSizeId,
    onChange,
    sizeSelector = [],
    ...htmlAttributes
}) => {
    const [focusedSizeId, setFocusedSizeId] = useState(defaultSelectedSizeId);
    const [selectedSizeId, setSelectedSizeId] = useState(defaultSelectedSizeId);
    const previousItemRef = useRef(null);
    const nextItemRef = useRef(null);
    const focusedItemRef = useRef(null);

    const refs = [previousItemRef, focusedItemRef, nextItemRef];
    const wrapperRef = useRef(null);

    const handleSizeSelect = (sizeId) => {
        setSelectedSizeId(sizeId);
        setFocusedSizeId(sizeId);

        if (onChange) {
            attempt(onChange(sizeId));
        }
    };

    return (
        <Wrapper
            {...htmlAttributes}
            className={classnames('GM10SizePicker', className)}
            ref={wrapperRef}
        >
            {sizeSelector.map(({
                displayName,
                currencyPrefix = '$',
                displayAmount,
                displayRegularAmount,
                termType,
                footnoteMarker,
                currencyDecimalPlaces = 2,
                sizeId,
            }, index) => {
                const [priceIntegers, priceDecimals] = splitPrice(displayAmount, currencyDecimalPlaces, true);
                const hasPriceChange = displayAmount !== displayRegularAmount;
                const isFocused = focusedSizeId === sizeId;
                const ariaLabel = `${currencyPrefix}${displayAmount} ${termType ? `per ${termType}` : 'outright'}`;

                return (
                    <StyledButton
                        id={sizeId}
                        isFocused={isFocused}
                        key={sizeId}
                        ref={refs[index - selectedSizeId + 1] || null}
                        onClick={() => {
                            handleSizeSelect(sizeId);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'ArrowLeft') {
                                e.preventDefault();
                                e.stopPropagation();
                                invoke(previousItemRef, 'current.focus');
                            } else if (e.key === 'ArrowRight') {
                                e.preventDefault();
                                e.stopPropagation();
                                invoke(nextItemRef, 'current.focus');
                            }
                        }}
                        onFocus={() => {
                            // set focussed item for both keyboard & mouse selection
                            setFocusedSizeId(sizeId);
                        }}
                        aria-pressed={sizeId === selectedSizeId}
                    >
                        {displayName && (
                            <StyledDisplayName>
                                {displayName}
                            </StyledDisplayName>
                        )}

                        <StyledPrice aria-label={ariaLabel} termType={termType}>
                            {currencyPrefix && (
                                <Sup>
                                    <CurrencyPrefixWrapper>
                                        {currencyPrefix}
                                    </CurrencyPrefixWrapper>
                                </Sup>
                            )}
                            {priceIntegers}
                            <Sup>
                                {priceDecimals && (
                                    <StyledPriceDecimal>
                                        .{priceDecimals}{footnoteMarker}
                                    </StyledPriceDecimal>
                                )}
                                {/* Only appear if there's no price change */}
                                {footnoteMarker && !hasPriceChange && (
                                    <FootnoteMarker>
                                        {footnoteMarker}
                                    </FootnoteMarker>
                                )}
                            </Sup>
                            {termType && (
                                <StyledTermType>
                                    {getShortTermTypeText(termType)}
                                </StyledTermType>
                            )}
                        </StyledPrice>

                        {hasPriceChange && displayRegularAmount && (
                            <StyledSecondaryText>
                                <span aria-label={ariaLabel}>
                                    <del>
                                        {currencyPrefix}{displayRegularAmount}
                                    </del>
                                    {getShortTermTypeText(termType)}
                                </span>
                            </StyledSecondaryText>
                        )}
                    </StyledButton>
                );
            })}
        </Wrapper>
    );
};

GM10SizePicker.propTypes = {
    /** Additional class name(s) */
    className: classNameType,
    /** Array of sizePicker objects */
    sizeSelector: propTypes.arrayOf(propTypes.shape({
        /** ID of selected size */
        sizeId: propTypes.number,
        /** currency prefix */
        currencyPrefix: propTypes.string,
        /** how currency decimal places show */
        currencyDecimalPlaces: propTypes.number,
        /** display name */
        displayName: propTypes.string,
        /** term period */
        termType: propTypes.oneOf(['day', 'week', 'month', 'annual']),
        /** display regular amount */
        displayRegularAmount: propTypes.number,
        /** display amount */
        displayAmount: propTypes.number,
        /** Footnote marker next to the price like `*, ^, ~ or #` */
        footnoteMarker: propTypes.string,
    })),
    /** Default ID of selected size*/
    defaultSelectedSizeId: propTypes.number,
    /** Callback to call on element click */
    onChange: propTypes.func,
};

GM10SizePicker.displayName = 'GM10SizePicker';

export default GM10SizePicker;
