import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {A, Img, Section} from 'normalized-styled-components';
import classnames from 'classnames';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {panther, white, akela} from '../../../../common/palette';
import {
    SCREEN_768_TABLET,
    SCREEN_1280_DESKTOP,
    SCREEN_1920_DESKTOP,
} from '../../../../common/screen-sizes';

const StyledSection = styled(Section)`
    box-sizing: border-box;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    background-color: ${white};
    color: ${panther};
    font: var(--ionic-body-copy-3);
`;

const HeaderBrandLogo = styled(Img)`
    height: 62px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        height: 76px;
    `}
`;

const ProductHeading = styled.div`
    display: flex;
    margin-top: 35px;
    border-top: 1px solid ${akela};
    padding-top: 42px;
    font: var(--ionic-body-copy-4-bold);
`;

const ProductHeadingLogo = styled(Img)`
    margin-left: 7px;
    height: 19px;
`;

const Logos = styled.div`
    display: grid;
    grid-template-columns: repeat(4, min-content);
    grid-gap: 26px;
    justify-items: center;
    padding: 39px 0 0;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        grid-template-columns: none;
        grid-auto-flow: column;
        grid-gap: 36px;
    `}
`;

const StyledImg = styled(Img)`
    height: 36px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        height: 50px;
    `}
`;

const ContentHeading = styled.h3`
    margin: 21px 0;
    text-align: center;
    font: var(--ionic-header-6-bold);

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        margin-bottom: 28px;
    `}
`;

const StyledParagraph = styled.p`
    margin: 0;
    text-align: center;
    font: var(--ionic-body-copy-3-light);

    &:not(:last-of-type) {
        margin-bottom: 21px;

        ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
            margin-bottom: 28px;
        `}
    }
`;

const StyledLink = styled(A)`
    margin-top: 28px;
    text-align: center;
    text-decoration: none;
    color: ${panther};
    font: var(--ionic-body-copy-4);

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        margin-top: 49px;
    `}

    &:hover {
        text-decoration: underline;
    }
`;

const OR128StreamotionBlk = ({
    headerLogo,
    productLogos = [],
    heading,
    paragraphs = [],
    footerText,
    footerLink,
    productHeadingLogoUrl,
    className,
    ...htmlAttributes
}) => (
    <StyledSection {...htmlAttributes} className={classnames('OR128StreamotionBlk', className)}>
        {!!headerLogo && headerLogo.src && (
            <HeaderBrandLogo src={headerLogo.src} alt={headerLogo.alt || ''} />
        )}

        {!!heading && (<ContentHeading>{heading}</ContentHeading>)}

        {(Array.isArray(paragraphs) && !!paragraphs.length) && paragraphs.map((paragraph, index) => (
            <StyledParagraph key={index}>{paragraph}</StyledParagraph>
        ))}

        {!!(footerText && footerLink) && <StyledLink href={footerLink}>{footerText}</StyledLink>}

        <ProductHeading>More services by <ProductHeadingLogo src={productHeadingLogoUrl} alt="Hubbl" /></ProductHeading>

        {(Array.isArray(productLogos) && !!productLogos.length) && (
            <Logos>
                {productLogos.map(({src, alt} = {}) => !!src && (
                    <StyledImg src={src} alt={alt} key={alt} />
                ))}
            </Logos>
        )}
    </StyledSection>
);

OR128StreamotionBlk.propTypes = {
    /* Header logo */
    headerLogo: propTypes.shape({
        src: propTypes.string,
        alt: propTypes.string,
    }),
    /* Path to Product heading logo */
    productHeadingLogoUrl: propTypes.string.isRequired,
    /* Array containing path and alt to other products */
    productLogos: propTypes.arrayOf(propTypes.shape({
        src: propTypes.string,
        alt: propTypes.string,
    })),
    /* Heading Text */
    heading: propTypes.node,
    /* Content paragraphs */
    paragraphs: propTypes.arrayOf(propTypes.node),
    /* Footer link text */
    footerText: propTypes.string,
    /* Destination url for footer link */
    footerLink: propTypes.string,
    /** Additional class name(s) to apply to the root component */
    className: classNameType,
};

OR128StreamotionBlk.displayName = 'OR128StreamotionBlk';

export default OR128StreamotionBlk;
