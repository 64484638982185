import React, {useState} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Section, Footer, Button} from 'normalized-styled-components';
import {rgba} from 'polished';
import classnames from 'classnames';
import FocusTrap from 'focus-trap-react';
import noop from 'lodash/noop';

import {mediaQuery, stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import CommonTransition from '../../../../common/common-transition';
import {transition, fadeInOut} from '../../../../common/animations';
import {coal, midnight, silverLining} from '../../../../common/palette';
import {SCREEN_PHABLET, SCREEN_TABLET, SCREEN_LG_DESKTOP} from '../../../../common/screen-sizes';

import IC52Cross from '../../../atoms/ic/52-cross';

const PageContainer = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    ${fadeInOut}
    /* fadeInOut uses opacity in its transition so we have it here too so we don't override it */
    /* stylelint-disable-next-line order/properties-order */
    transition: ${transition('background-color', 'opacity')};

    ${stylesWhen('hasOverlay')`
        background-color: ${rgba(coal, 0.6)};
    `}
`;

const Modal = styled.dialog`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border: 0; /** Yucky browser styles */
    border-radius: 5px;
    background-color: ${midnight};
    padding: 60px 28px;
    width: 100%;
    max-height: 96vh;
    overflow-y: auto;
    overscroll-behavior: none;

    ${mediaQuery({minWidthPx: SCREEN_PHABLET})`
        width: 92%;
    `}

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        padding-top: 74px;
        padding-bottom: 74px;
        width: 84%;
    `}

    ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
        padding-top: 138px;
        padding-bottom: 138px;
        width: 935px;
    `}
`;

const StyledFooter = styled(Footer)`
    position: absolute;
    top: 7px;
    right: 7px;

    ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
        top: 14px;
        right: 14px;
    `}
`;

const StyledButton = styled(Button)`
    box-sizing: content-box;
    border: 0;
    background: transparent;
    padding: 10px;
    width: 25px;
    height: 25px;
    color: ${silverLining};

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        width: 35px;
        height: 35px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
        width: 40px;
        height: 40px;
    `}
`;

const OR56Modal = ({
    children,
    isVisible,
    hasOverlay = true,
    onCloseClick = noop,
    canBeDeactivated,
    className,
    ...htmlAttributes
}) => {
    const [hasFocus, setHasFocus] = useState(false);

    const deactivateFocusTrap = () => {
        if (canBeDeactivated) {
            setHasFocus(false);
            onCloseClick();
        }
    };

    const focusTrapOptions = {
        onDeactivate: deactivateFocusTrap,
        escapeDeactivates: canBeDeactivated,
        clickOutsideDeactivates: canBeDeactivated,
    };

    return (
        <CommonTransition
            in={isVisible}
            onEntered={() => setHasFocus(true)} // Focus trap seems to prevent CommonTransition from fading in so we delay it until the transition ends
        >
            <PageContainer {...htmlAttributes} hasOverlay={hasOverlay}>
                <FocusTrap active={hasFocus} focusTrapOptions={focusTrapOptions}>
                    <Modal className={classnames('OR56Modal', className)} open={isVisible}>
                        {!!children && (
                            <Section>{children}</Section>
                        )}

                        {!!canBeDeactivated && (
                            <StyledFooter>
                                <StyledButton
                                    ariaLabel="Close"
                                    onClick={deactivateFocusTrap}
                                >
                                    <IC52Cross />
                                </StyledButton>
                            </StyledFooter>
                        )}
                    </Modal>
                </FocusTrap>
            </PageContainer>
        </CommonTransition>
    );
};

OR56Modal.displayName = 'OR56Modal';

OR56Modal.propTypes = {
    children: propTypes.node,
    isVisible: propTypes.bool,
    /** Displays an overlay on top of the content behind the modal */
    hasOverlay: propTypes.bool,
    /** Function to call when the Close button is clicked */
    onCloseClick: propTypes.func,
    /** Can this modal be deactivated by clicking close, clicking outside of the modal or pressing Escape? */
    canBeDeactivated: propTypes.bool,
    /** Additional CSS classnames */
    className: classNameType,
};

export default OR56Modal;
