import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import {hubblGreen} from '../../../../common/palette';

import Ic from '..';

const IC291RecordActive = ({
    secondaryColor = hubblGreen,
    ...args
}) => (
    <Ic {...args}>
        <g transform="translate(7 7)">
            <circle cx="43" cy="43" r="22" fill={secondaryColor} />
            <path fillRule="nonzero" d="M43 0C19.252 0 0 19.252 0 43s19.252 43 43 43 43-19.252 43-43S66.748 0 43 0zm0 7.432c19.644 0 35.568 15.924 35.568 35.568S62.644 78.568 43 78.568 7.432 62.644 7.432 43 23.356 7.432 43 7.432z" />
        </g>
    </Ic>
);

IC291RecordActive.propTypes = {
    /** The icon's secondary color */
    secondaryColor: colorType,
};

IC291RecordActive.displayName = 'IC291RecordActive';

export default IC291RecordActive;
