import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import ResizeObserver from 'resize-observer-polyfill';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import property from 'lodash/property';
import invoke from 'lodash/invoke';
import {Button} from 'normalized-styled-components';

import {mediaQuery, stylesWhen} from '@fsa-streamotion/styled-component-helpers';

import {SCREEN_PRETTY_LG_DESKTOP} from '../../../common/screen-sizes';
import {midnight, coal, kayoGreen, satellite, silverLining} from '../../../common/palette';
import {transition} from '../../../common/animations';

import GA92BrandLogo from '../../atoms/ga/92-brand-logo';
import IC02Sea from '../../atoms/ic/02-sea';
import IC30Back from '../../atoms/ic/30-back';
import XX01PageBackground from '../../atoms/xx/01-page-background';

const StyledHeader = styled.div`
    box-sizing: border-box;
    position: relative;
    align-items: center;
    justify-content: center;
    /* As this is fixed, it has different stacking context */
    z-index: 1;  /* stylelint-disable-line scale-unlimited/declaration-strict-value */
    width: 100%;
    color: ${silverLining};

    ${stylesWhen('hasHeaderShade')`
        &::after {
            position: absolute;
            background: linear-gradient(to top, rgba(10, 10, 16, 0), ${midnight});
            width: 100%;
            top: 100%;
            height: 213px;
            content: '';
            pointer-events: none;
        }
    `}
`;

const HeaderTop = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    height: 96px;

    ${mediaQuery({minWidthPx: SCREEN_PRETTY_LG_DESKTOP})`
        height: 156px;
    `}
`;

const BackButton = styled(Button).attrs({type: 'button'})`
    appearance: none;
    position: absolute;
    top: 50%;
    left: 21px;
    transform: translateY(-50%);
    transition: ${transition('color')};
    border: 0;
    background-color: transparent;
    padding: 0;
    width: 28px;
    height: 28px;
    color: ${satellite};

    ${mediaQuery({minWidthPx: SCREEN_PRETTY_LG_DESKTOP})`
        left: 42px;
        width: 49px;
        height: 49px;
    `}

    &:focus,
    &:hover {
        outline: 0;
        color: ${kayoGreen};
    }
`;

const Wrapper = styled.div`
    box-sizing: border-box;
    position: relative;
    margin: 0 auto;
    padding: 21px;
    width: 100%;
    max-width: 1164px;

    ${mediaQuery({minWidthPx: SCREEN_PRETTY_LG_DESKTOP})`
        max-width: 1518px;
    `}
`;

const HeaderWrapper = styled(Wrapper)`
    padding: 0;
    width: 100%;
    text-align: center;
`;

const LogoWrapper = styled.div`
    display: inline-block;
    width: 97px;

    ${mediaQuery({minWidthPx: SCREEN_PRETTY_LG_DESKTOP})`
        width: 131px;
    `}
`;

const SearchButton = styled.button`
    position: absolute;
    top: 50%;
    right: 56px;
    transform: translateY(-50%);
    transition: ${transition('color')};
    margin: 0;
    border: 0;
    background-color: transparent;
    padding: 0;
    width: 34px;
    height: 34px;
    color: ${silverLining};

    &:focus,
    &:hover {
        outline: 0;
        color: ${kayoGreen};
    }
`;

const PageWrapper = styled(Wrapper)`
    padding-bottom: 98px;

    ${mediaQuery({minWidthPx: SCREEN_PRETTY_LG_DESKTOP})`
        height: 206px;
    `}
`;

const PageContainer = styled.div`
    width: 100%;
    height: calc(100vh - ${property('headerHeight')}px);
    overflow-y: auto;
`;

const StyledFooter = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
`;

export default class Onboarding extends React.Component {
    static displayName = 'Onboarding';

    static propTypes = {
        bgSrc: propTypes.string,
        bgSize: propTypes.string,
        isBgRepeating: propTypes.bool,
        children: propTypes.node,
        footer: propTypes.node,
        hasHeader: propTypes.bool,
        hasHeaderShade: propTypes.bool,
        hasSearch: propTypes.bool,
        headerContent: propTypes.node,
        onBackClick: propTypes.func,
        onSearchClick: propTypes.func,
    };

    static defaultProps = {
        hasHeader: true,
    };

    state = {
        headerHeight: 0,
    };

    componentWillUnmount() {
        invoke(this.resizeObserver, 'disconnect');
        invoke(this.debouncedSetHeaderHeight, 'cancel');
    }

    previousHeaderEl = null;
    resizeObserver = null;

    debouncedSetHeaderHeight = debounce(([headerEl]) => {
        this.setState({
            headerHeight: get(headerEl, 'target.clientHeight', 0),
        });
    }, 100);

    onHeaderRef = (nextHeaderEl) => {
        if (nextHeaderEl === null) {
            this.previousHeaderEl = null;

            return void invoke(this.resizeObserver, 'disconnect'); // nothing to watch or maybe we're unmounting. destroy any resizeObservers
        } else if (nextHeaderEl === this.previousHeaderEl) {
            return; // we have a reference and its still correct. move along
        }

        if (!this.resizeObserver) {
            this.resizeObserver = new ResizeObserver(this.debouncedSetHeaderHeight);
        }

        if (this.previousHeaderEl) {
            this.resizeObserver.unobserve(this.previousHeaderEl);
        }

        this.resizeObserver.observe(nextHeaderEl);
        this.previousHeaderEl = nextHeaderEl;
    };

    handleKeyDown = (e) => {
        const childrenArray = [...e.currentTarget.childNodes];
        const currentRowIndex = childrenArray.findIndex((node) => node.contains(document.activeElement));
        const isArrowDown = ['ArrowDown', 'Down'].includes(e.key);
        const isArrowUp = ['ArrowUp', 'Up'].includes(e.key);

        if (isArrowDown || isArrowUp) {
            e.preventDefault();

            if (isArrowDown && childrenArray.length - 1 > currentRowIndex) {
                invoke(childrenArray[currentRowIndex + 1].querySelector('a, button'), 'focus');
            } else if (isArrowUp && currentRowIndex > 0) {
                invoke(childrenArray[currentRowIndex - 1].querySelector('a, button'), 'focus');
            }
        }
    };

    render() {
        const {
            bgSrc,
            bgSize,
            isBgRepeating,
            children,
            footer,
            hasHeader,
            hasHeaderShade,
            hasSearch,
            headerContent,
            onBackClick,
            onSearchClick,
        } = this.props;

        return (
            <React.Fragment>
                {!!bgSrc && <XX01PageBackground src={bgSrc} bgColor={coal} isRepeating={isBgRepeating} bgSize={bgSize} />}
                {!!hasHeader && (
                    <StyledHeader
                        ref={this.onHeaderRef}
                        hasHeaderShade={hasHeaderShade}
                    >
                        <HeaderTop>
                            <HeaderWrapper>
                                <LogoWrapper>
                                    <GA92BrandLogo isInternalMode={false} />
                                </LogoWrapper>
                                {!!hasSearch && (
                                    <SearchButton onClick={onSearchClick} aria-label="Search">
                                        <IC02Sea />
                                    </SearchButton>
                                )}
                            </HeaderWrapper>
                            {!!onBackClick && (
                                <BackButton onClick={onBackClick} aria-label="Back">
                                    <IC30Back />
                                </BackButton>
                            )}
                        </HeaderTop>
                        {!!headerContent && (
                            <Wrapper>
                                {headerContent}
                            </Wrapper>
                        )}
                    </StyledHeader>
                )}
                <PageContainer headerHeight={this.state.headerHeight}>
                    <PageWrapper onKeyDown={this.handleKeyDown}>
                        {children}
                    </PageWrapper>
                </PageContainer>
                {footer && <StyledFooter>{footer}</StyledFooter>}
            </React.Fragment>
        );
    }
}
