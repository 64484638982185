import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {srcsetOptionsType} from '@fsa-streamotion/custom-prop-types';

import {SCREEN_1920_DESKTOP, SCREEN_768_TABLET} from '../../../../common/screen-sizes';
import SrcsetSource from '../../../../common/srcset-source';

const StyledDiv = styled.div`
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 480px;
    overflow: hidden;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        height: 734px;
    `}
`;

const StyledPicture = styled.picture`
    display: block;
    margin: 0;
    width: 100%;
    height: 100%;
`;

const StyledImg = styled.img`
    display: block;
    transform: translateX(-50%); /* horizontally centre the image inside the container */
    margin-left: 50%; /* horizontally centre the image inside the container */
    height: 100%; /* take the height from the parent; scale the width according to aspect ratio of the image itself */
`;

const GA06PostImg = ({
    alt = '',
    srcsetLandscape,
    srcsetPortrait,
    fallbackImgSrc,
    ...htmlAttributes
}) => (
    <StyledDiv {...htmlAttributes}>
        <StyledPicture>
            <SrcsetSource
                minWidthPx={srcsetPortrait ? SCREEN_768_TABLET : undefined}
                srcsetOptions={srcsetLandscape}
            />

            {!!srcsetPortrait && (
                <SrcsetSource srcsetOptions={srcsetPortrait} />
            )}

            {/*
                Now, potentially you could add an additional source here using akamai crop policies. However, beware that the tradeoff involves losing the ability to do srcset
                <source src="some-fixed-url?impolicy...blah blah" />
            */}

            <StyledImg src={fallbackImgSrc} alt={alt} />
        </StyledPicture>
    </StyledDiv>
);

GA06PostImg.displayName = 'GA06PostImg';

GA06PostImg.propTypes = {
    /** Image alt attribute */
    alt: propTypes.string,
    /** srcset attribute for non-mobile devices */
    srcsetLandscape: srcsetOptionsType,
    /** srcset attribute for mobile devices */
    srcsetPortrait: srcsetOptionsType,
    /** fallback url */
    fallbackImgSrc: propTypes.string,
};

export default GA06PostImg;
