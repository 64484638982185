import React from 'react';
import classnames from 'classnames';
import {Hr} from 'normalized-styled-components';
import {rgba} from 'polished';
import styled from 'styled-components';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {slate} from '../../../../common/palette';

const StyledHr = styled(Hr)`
    margin: 0;
    border-width: 1px 0 0;
    border-style: solid;
    border-color: ${rgba(slate, 0.2)};
`;

const GA20PixelDiv = ({className, ...htmlAttributes}) => (
    <StyledHr
        {...htmlAttributes}
        className={classnames('GA20PixelDiv', className)}
    />
);

GA20PixelDiv.displayName = 'GA20PixelDiv';

GA20PixelDiv.propTypes = {
    /** Additional class(es) */
    className: classNameType,
};

export default GA20PixelDiv;
