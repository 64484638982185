import React from 'react';

import Ic from '..';

const IC306Twitter = (args) => (
    <Ic {...args}>
        <path d="M50,0 C77.6142375,0 100,22.3857625 100,50 C100,77.6142375 77.6142375,100 50,100 C22.3857625,100 0,77.6142375 0,50 C0,22.3857625 22.3857625,0 50,0 Z M19.2,21.032 L43.1,52.987 L19.05,78.968 L24.463,78.968 L45.519,56.221 L62.531,78.968 L81.951,78.968 L56.706,45.216 L79.092,21.032 L73.679,21.032 L54.288,41.981 L38.62,21.032 L19.2,21.032 Z M27.16,25.018 L35.622,25.018 L73.99,74.98 L65.528,74.98 L27.16,25.018 Z" />
    </Ic>
);

IC306Twitter.displayName = 'IC306Twitter';

export default IC306Twitter;
