import React from 'react';
import styled from 'styled-components';
import {rgba} from 'polished';
import propTypes from 'prop-types';
import classnames from 'classnames';

import {stylesWhen, mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {blanc, onyx} from '../../../../../common/palette';
import {
    SCREEN_768_TABLET,
    SCREEN_1024_DESKTOP,
    SCREEN_1280_DESKTOP,
    SCREEN_1920_DESKTOP,
    SCREEN_2560_DESKTOP,
} from '../../../../../common/screen-sizes';
import {transition} from '../../../../../common/animations';

const LowerControlsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transform: translateY(${({isVisible}) => isVisible ? 0 : '100%'});
    transition: ${transition('height', 'transform')};
    padding: 14px;
    max-width: 100%;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        padding: 21px 40px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding: 21px 70px 70px;
    `}

    &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(to bottom, ${rgba(onyx, 0)}, ${rgba(onyx, 0.7)} 93%);
        width: 100%;
        height: 52px;
        content: '';
        pointer-events: none;

        ${stylesWhen(({isSeeking, hasThumbnailCarousel}) => isSeeking && hasThumbnailCarousel)`
            background-image: linear-gradient(to bottom, ${rgba(onyx, 0)}, ${rgba(onyx, 0.9)} 90%);
        `}

        ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
            height: 103px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
            height: 137px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
            height: 172px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            height: 258px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
            height: 344px;
        `}
    }
`;

const ScrubberAndButtonRowContainer = styled.div`
    position: relative;
`;

const ButtonRow = styled.div`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const ButtonRowLayoutItem = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 14px;
    align-items: center;

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        grid-column-gap: 21px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        grid-column-gap: 28px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
        grid-column-gap: 35px;
    `}
`;

const CentreControlsButtonRow = styled(ButtonRowLayoutItem)`
    display: none;

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        display: grid;
    `}
`;

const Divider = styled.div`
    border-right: 2px solid ${rgba(blanc, 0.2)};
    width: 0;
    height: 16px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        height: 24px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        height: 28px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        height: 48px;
    `}
`;

const SkipButtonWrapper = styled.div`
    margin: 14px 0;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        margin-bottom: 19px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        margin-bottom: 35px;
    `}
`;

const DisplayTimeContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ScrubberWrapper = styled.div`
    padding: 6px 0;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        padding: 13px 0;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding: 21px 0;
    `}
`;

const VPOR01LowerControls = ({
    className,

    chaptersButton,
    displayTime,
    fullscreenButton,
    goToLiveButton,
    hoverThumbnail,
    nextEpisodeButton,
    playButton,
    popOutPlayerButton,
    relatedVideosButton,
    scrubber,
    skipBackButton,
    skipForwardButton,
    skipIntroButton,
    thumbnailCarousel,
    volumeSlider,

    isSeeking,
    isVisible,
}) => (
    <LowerControlsContainer
        isSeeking={isSeeking}
        hasThumbnailCarousel={!!thumbnailCarousel}
        isVisible={isVisible}
        className={classnames('VPOR01LowerControls', className)}
    >
        {!!skipIntroButton && (
            <SkipButtonWrapper>
                {skipIntroButton}
            </SkipButtonWrapper>
        )}
        {!!thumbnailCarousel && thumbnailCarousel}
        <ScrubberAndButtonRowContainer>
            {!!hoverThumbnail && hoverThumbnail}
            {!!scrubber && (
                <ScrubberWrapper>
                    {scrubber}
                </ScrubberWrapper>
            )}
            <ButtonRow>
                <ButtonRowLayoutItem>
                    <CentreControlsButtonRow>
                        {!!skipBackButton && skipBackButton}
                        {!!playButton && playButton}
                        {!!skipForwardButton && skipForwardButton}
                        {!!(skipBackButton || playButton || skipForwardButton) && <Divider />}
                    </CentreControlsButtonRow>
                    <ButtonRowLayoutItem>
                        {!!goToLiveButton && goToLiveButton}
                        {!!volumeSlider && volumeSlider}
                        {!!(volumeSlider || goToLiveButton) && <Divider />}
                    </ButtonRowLayoutItem>

                    {!!displayTime && (
                        <DisplayTimeContainer>
                            {displayTime}
                        </DisplayTimeContainer>
                    )}
                </ButtonRowLayoutItem>
                <ButtonRowLayoutItem>
                    {!!relatedVideosButton && relatedVideosButton}
                    {!!chaptersButton && chaptersButton}
                    {!!nextEpisodeButton && nextEpisodeButton}
                    {!!(fullscreenButton || popOutPlayerButton) && (
                        <Divider />
                    )}
                    {!!popOutPlayerButton && popOutPlayerButton}
                    {!!fullscreenButton && fullscreenButton}
                </ButtonRowLayoutItem>
            </ButtonRow>
        </ScrubberAndButtonRowContainer>
    </LowerControlsContainer>
);

VPOR01LowerControls.propTypes = {
    /** Additional class(es) */
    className: classNameType,

    relatedVideosButton: propTypes.node,
    chaptersButton: propTypes.node,
    displayTime: propTypes.node,
    fullscreenButton: propTypes.node,
    goToLiveButton: propTypes.node,
    hoverThumbnail: propTypes.node,
    nextEpisodeButton: propTypes.node,
    playButton: propTypes.node,
    popOutPlayerButton: propTypes.node,
    scrubber: propTypes.node,
    skipBackButton: propTypes.node,
    skipForwardButton: propTypes.node,
    skipIntroButton: propTypes.node,
    thumbnailCarousel: propTypes.node,
    volumeSlider: propTypes.node,

    /** determines if the thumbnails are visible */
    isSeeking: propTypes.bool,
    isVisible: propTypes.bool,
};

VPOR01LowerControls.displayName = 'VPOR01LowerControls';

export default VPOR01LowerControls;
