import React from 'react';

import {black} from '../../../../common/palette';
import Ic from '..';

const IC19JLive = (args) => (
    <Ic {...args}>
        <path d="M78.6 22.8L65.4 9.6l-3.9 3.9L70 22H30C17.4 22 6.8 31 4.8 43.3l5.5.9c1.6-9.6 9.9-16.6 19.6-16.6h40l-8.5 8.5 3.9 3.9 13.3-13.3c.5-.5.8-1.2.8-2s-.3-1.3-.8-1.9zm18.8 25.1H28c-.9 0-1.7.8-1.7 1.7v39.2c0 .9.8 1.7 1.7 1.7h69.4c.9 0 1.7-.8 1.7-1.7V49.6c0-1-.7-1.7-1.7-1.7z" />
        <path fill={black} d="M46 78.7H35.1V60h4.4v15.1H46v3.6zM54 78.7h-4.4V60H54v18.7zM75.2 60l-6.9 18.7h-4.2L57.2 60h4.7l4.4 12.9L70.6 60h4.6zM90.8 78.7H78.4V60h12.1v3.5h-7.7v3.8h7.3v3.5h-7.3v4.3h8v3.6z" />
    </Ic>
);

IC19JLive.displayName = 'IC19JLive';

export default IC19JLive;
