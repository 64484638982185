import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

function createMediaQueries({imagesWithBreakpoints = []}) {
    return css`
        ${imagesWithBreakpoints.map(({minWidth, imageUrl}, index) => {
        // Default to first breakpoint's image
        if (index === 0) {
            return css`
                background-image: url(${imageUrl});
            `;
        }

        return css`
            @media
            (min-width: ${minWidth}px)
            {
                background-image: url(${imageUrl});
            }
        `;
    })}
    `;
}

const LabelImage = styled.div`
    display: block;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 100%;
    height: 100%;

    ${createMediaQueries}
`;

const GA27ConLabel = ({
    label,
    imagesWithBreakpoints,
    className,
}) => (
    <LabelImage
        className={classnames('GA27ConLabel', className)}
        role="img"
        aria-label={label}
        imagesWithBreakpoints={imagesWithBreakpoints}
    />
);

GA27ConLabel.displayName = 'GA27ConLabel';

GA27ConLabel.propTypes = {
    /** Text to use for aria-label */
    label: propTypes.string,
    /** Array of objects containing minWidth and imageUrl. Should be ordered from smallest to largest breakpoint. */
    imagesWithBreakpoints: propTypes.arrayOf(propTypes.shape({
        minWidth: propTypes.number,
        imageUrl: propTypes.string,
    })),
    /** Additional class(es) */
    className: classNameType,
};

export default GA27ConLabel;
