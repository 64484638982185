import getDecimalPlaces from './get-decimal-places';

export default function round(number, step) {
    if (step < 1) {
        const places = getDecimalPlaces(step);

        return parseFloat(number.toFixed(places));
    } else {
        return (Math.round(number / step) * step);
    }
}
