import styled from 'styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {
    SCREEN_768_TABLET,
    SCREEN_2560_DESKTOP,
} from '../../../../../common/screen-sizes';
import {blanc} from '../../../../../common/palette';

const Arrow = styled.div`
    border: 6px solid transparent;
    border-bottom-width: 0;
    border-top-color: ${blanc};
    width: 0;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        border-width: 7px 7px 0 7px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
        border-width: 8px 8px 0 8px;
    `}
`;

Arrow.displayName = 'Arrow';

export default Arrow;
