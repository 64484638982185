import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import pick from 'lodash/pick';

import {
    BrandedBA08BtnSec,
    BrandedBA22EditBtn,
    BrandedOR12VchInp,
} from '../../../../utils/branded-components';

const StyledSpan = styled.span`
    display: block;
    text-align: center;
`;

const VoucherFormWrapper = styled.div`
    display: grid;
    grid-gap: 7px;
`;

const StyledBrandedBA08BtnSec = styled(BrandedBA08BtnSec)`
    padding: 0;
`;

const VoucherForm = ({
    brand,
    voucherDisplay,
    onContinueWithoutVoucher,
    className,
}) => {
    const voucherInputProps = pick(voucherDisplay, [
        'headingText', 'isExpanded', 'onExpand', 'hasExpandButton', 'label', 'inputId', 'inputName', 'voucherCode', 'onChangeVoucherCode', 'errorIcon', 'errorMessage', 'submitText', 'isSubmitDisabled', 'onSubmit', 'displaySubtext', 'displayHeader', 'ref',
    ]);

    return (
        <VoucherFormWrapper className={classNames('VoucherForm', className)}>
            <BrandedOR12VchInp brand={brand} isBlockForm={true} {...voucherInputProps} data-cs-override-id="voucher" />
            {!!voucherDisplay.isVoucherRequiredTemplate && (
                <StyledBrandedBA08BtnSec
                    brand={brand}
                    onClick={onContinueWithoutVoucher}
                    {...brand === 'kayo' && {isBlock: true}}
                >
                    Continue without voucher
                </StyledBrandedBA08BtnSec>
            )}
            {!!voucherDisplay.showRemoveVoucher && (
                <BrandedBA22EditBtn brand={brand} onClick={voucherDisplay.onRemoveVoucher}>
                    <StyledSpan>Remove Voucher</StyledSpan>
                </BrandedBA22EditBtn>
            )}
        </VoucherFormWrapper>
    );
};

VoucherForm.displayName = 'Voucher Form';

VoucherForm.propTypes = {
    /** Product brand name */
    brand: propTypes.string,
    /** Additional CSS class(es) */
    className: propTypes.string,
    /** Voucher display controls */
    voucherDisplay: propTypes.shape({
        isVoucherRequiredTemplate: propTypes.bool,
        showRemoveVoucher: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
        onRemoveVoucher: propTypes.func,
    }),
    /** Callback for clicking on "Continue without voucher" button*/
    onContinueWithoutVoucher: propTypes.func,
};

export default VoucherForm;
