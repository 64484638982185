import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {palette as infinityPalette} from '@fsa-streamotion/streamotion-web-infinity';
import {palette as ionicPalette, screenSizes} from '@fsa-streamotion/streamotion-web-ionic';
import {palette as muiPalette} from '@fsa-streamotion/streamotion-web-mui';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {
    BrandedBA01BtnPr,
    BrandedIC103Loading,
    BrandedIC42Error,
    BrandedIM01Input,
} from '../../../../../utils/branded-components';
import {stylesWhenBinge, stylesWhenFlash, stylesWhenKayo, stylesWhenLifestyle} from '../../../../../utils/styles-when-brand';

import {Paragraph} from '../../../../../widgets/common/styled-elements';
import Summary from '../summary';

const {blanc} = ionicPalette;
const {fog} = infinityPalette;
const {white} = muiPalette;

const {SCREEN_1920_DESKTOP} = screenSizes;

const StyledBrandedIM01Input = styled(BrandedIM01Input)`
    max-width: 300px;
`;

const StyledBrandedBA01BtnPr = styled(BrandedBA01BtnPr)`
    margin: 25px 0;
    width: 250px;
    height: 50px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 400px;
        height: 80px;
    `}

    ${stylesWhenFlash`
        width: 228px;
        height: 40px;

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            width: 333px;
            height: 60px;
        `}
    `}
`;

const StyledForm = styled.form`
    display: flex;
    flex-flow: column nowrap;

    &:invalid {
        ${StyledBrandedBA01BtnPr} {
            opacity: 0.35;
            pointer-events: none;
        }
    }
`;

const StyledH2 = styled.h2`
    display: block;
    margin: 0;
    width: 100%;

    ${stylesWhenBinge`
        color: ${blanc};
        font: var(--ionic-body-copy-2-bold);
    `}

    ${stylesWhenFlash`
        color: ${fog};
        font: var(--infinity-body-copy-2);
    `}

    ${stylesWhenKayo`
        color: ${white};
        font: var(--mui-body-copy-2-bold);
    `}

    ${stylesWhenLifestyle`
        color: ${blanc};
        font: var(--nucleus-body-copy-2-bold);
    `}
`;

const StyledSpan = styled.span`
    word-break: break-all;

    ${stylesWhenFlash`
        font: var(--infinity-body-copy-2-light);
    `}
`;

const MEMBERSHIP_FIELD_NAME = 'membership-id';

const Journey = ({
    isCompleted,
    isLoading,
    acceptedMembership,
    errorMessage,
    onSubmit,
    onChange,
    brand,
}) => {
    let icon = null;

    if (isCompleted) {
        return (
            <Summary brand={brand} header="3. Membership Number">
                <StyledSpan brand={brand}>{acceptedMembership}</StyledSpan>
            </Summary>
        );
    }

    if (errorMessage) {
        icon = <BrandedIC42Error brand={brand} />;
    } else if (isLoading) {
        icon = <BrandedIC103Loading brand={brand} />;
    }

    return (
        <StyledForm
            brand={brand}
            onSubmit={(event) => {
                event.preventDefault();
                onSubmit({
                    membershipId: event.target[MEMBERSHIP_FIELD_NAME].value,
                });
            }}
        >
            <StyledH2 brand={brand}>
                3. Membership Number
            </StyledH2>

            <Paragraph brand={brand}>To be eligible for this offer, please enter your membership number</Paragraph>

            <StyledBrandedIM01Input
                autocomplete="off"
                brand={brand}
                contextualHelp={errorMessage}
                disabled={isLoading}
                icon={icon}
                label="Membership Number"
                name={MEMBERSHIP_FIELD_NAME}
                onChange={onChange}
                required={true}
            />

            <StyledBrandedBA01BtnPr
                brand={brand}
                disabled={isLoading}
                type="submit"
            >
                Continue
            </StyledBrandedBA01BtnPr>
        </StyledForm>
    );
};

Journey.propTypes = {
    /** is form completed? */
    isCompleted: propTypes.bool,
    /** is submission loading? */
    isLoading: propTypes.bool,
    /** accepted membership id */
    acceptedMembership: propTypes.string,
    /** error message */
    errorMessage: propTypes.string,
    /** callback on change */
    onChange: propTypes.func,
    /** callback on submit */
    onSubmit: propTypes.func,
    /** brand name */
    brand: propTypes.string,
};

Journey.displayName = 'Journey-Clubs';

export default Journey;
