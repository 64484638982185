export const TERM_TYPE = {
    annual: 'annual',
    monthly: 'monthly',
};

/**
 * Gets the normalised term type based on the provided term type from AEM template.
 * If the term type is not recognised, it defaults to 'monthly' and logs a warning.
 *
 * @param {Object} options - The options for getting the normalised term type.
 * @param {string} options.termType - The term type to be checked .
 * @param {string} options.pg - The PG value for logging purposes.
 * @param {string} options.brand - The brand value for logging purposes.
 *
 * @returns {string} - The normalised term type, or 'monthly' if the term type is unrecognised.
 */
const normaliseTermType = ({
    termType,
    pg,
    brand,
}) => {
    const normalisedTermType = TERM_TYPE[termType];

    if (!normalisedTermType) {
        // eslint-disable-next-line no-console
        console.warn(`[NORMALISE TERM TYPE]: Unrecognised termType: [${termType}]. Defaulting to "monthly".`, {pg, brand});
    }

    return normalisedTermType || TERM_TYPE.monthly;
};

export default normaliseTermType;
