import propTypes from 'prop-types';
import invoke from 'lodash/invoke';

export const tabIndexType = propTypes.oneOf([-1, 0]);
export const termTypePropType = propTypes.oneOf(['annual', 'month', 'week', 'day']);

export function deprecatedType(props, propName, componentName) {
    if (props[propName] !== undefined) {
        return new Error(`Prop ${propName} in ${componentName} has been deprecated`);
    }
}
/**
 * Validate theme color. Can be rgb or rgba.
 *
 * @param  {Object} props           Component's props object
 * @param  {string} propName        Given name of prop to validate
 * @param  {string} componentName   Display name of component
 *
 * @returns {undefined|Error} Returns error on validation failure
 * @example rgb(var(--nucleus-theme, 255,25,43)) is valid
 */

export function themeColorType(props, propName, componentName) {
    // color prop is always `ThemeColor` object, use toString() to get the value
    const propValue = invoke(props[propName], 'toString');

    if (
        typeof propValue === 'string'
        && /rgba?\(var\(--([a-z]|-)*,\s*(\d{1,3},){2,3}\d{1,3}(,((0\.\d*)|0|1))?\)\)/.test(propValue)
    ) {
        return;
    } else {
        return new Error(`Invalid color code ${propValue} provided to ${propName} in ${componentName}`);
    }
}

export default {
    tabIndexType,
    termTypePropType,
    deprecatedType,
    themeColorType,
};
