import React from 'react';
import Ic from '..';

const IC09Pip = (args) => (
    <Ic {...args}>
        <path d="M0,7h100v85.7H0V7z M42.9,45.1v38.1h47.6V45.1H42.9z" />
    </Ic>
);

IC09Pip.displayName = 'IC09Pip';

export default IC09Pip;
