import React from 'react';
import propTypes from 'prop-types';
import {Button} from 'normalized-styled-components';
import {rgba} from 'polished';
import styled from 'styled-components';
import classnames from 'classnames';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {blanc, panther, vader} from '../../../../common/palette';
import {transition} from '../../../../common/animations';
import {SCREEN_1680_DESKTOP, SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';

const StyledButton = styled(Button)`
    appearance: none;
    display: flex;
    position: relative;
    justify-content: center;
    transition: ${transition('background-color', 'color')};
    border: 0;
    border-radius: 50%;
    box-shadow: 0 1px 1px 0 ${rgba(panther, 0.3)};
    background-color: ${rgba(blanc, 0.2)};
    padding: 11px;
    width: 40px;
    height: 40px;
    color: ${blanc};

    ${mediaQuery({minWidthPx: SCREEN_1680_DESKTOP})`
        padding: 13px;
        width: 50px;
        height: 50px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding: 17px;
        width: 60px;
        height: 60px;
    `}

    &:focus,
    &:hover {
        outline: 0;
        background-color: ${rgba(blanc, 0.8)};
        color: ${vader};
    }

    &:active {
        background-color: ${blanc};
        color: ${vader};
    }
`;

const BA38BtnIconPrimary = ({
    'aria-label': assistiveLabel,
    children,
    className,
    onClick,
    ...htmlAttributes
}) => (
    <StyledButton
        {...htmlAttributes}
        aria-label={assistiveLabel}
        className={classnames('BA38BtnIconPrimary', className)}
        title={assistiveLabel}
        onClick={onClick}
        type="button"
    >
        {children}
    </StyledButton>
);

BA38BtnIconPrimary.displayName = 'BA38BtnIconPrimary';

/* eslint-disable quote-props */
BA38BtnIconPrimary.propTypes = {
    /** Child to accept */
    children: propTypes.node.isRequired,
    /** additional CSS classnames to be applied */
    className: classNameType,
    /** Assistive label required for screen readers due to graphical only representation of content */
    'aria-label': propTypes.string.isRequired,
    /** Callback to call on element click */
    onClick: propTypes.func,
};
/* eslint-enable quote-props */

export default BA38BtnIconPrimary;
