import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {mediaQuery, stylesWhen, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {fadeInOut, transition} from '../../../../common/animations';
import CommonTransition from '../../../../common/common-transition';
import {white} from '../../../../common/palette';
import {kayoGreen} from '../../../../common/deprecated-mui-palette';
import {SCREEN_768_TABLET} from '../../../../common/screen-sizes';

import BA01BtnPr from '../../../atoms/ba/01-btn-pr';
import {IC103Loading, IC50Success} from '../../../atoms/ic';

const PerDtls = styled.section`
    position: relative;
`;

const Container = styled.div`
    transition: ${transition('opacity')};

    ${stylesWhen('isLoading')`
        opacity: 0.35;
    `}
`;

const Loader = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;

    ${fadeInOut}
`;

const StyledForm = styled.form`
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 7px;

    ${stylesWhenNot('theme.isAppDock')`
        ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
            grid-template-columns: 1fr 1fr;
            grid-auto-rows: auto;
            grid-gap: 21px 14px;
        `}
    `}
`;

const ButtonRow = styled.div`
    margin: 25px auto;
    width: 100%;
    max-width: 320px;

    ${stylesWhenNot('theme.isAppDock')`
        ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
            grid-column: 1 / -1;
        `}
    `}
`;

const IconHolder = styled.div`
    margin: 0 auto;
    width: 40px;
    height: 40px;

    ${stylesWhenNot('theme.isAppDock')`
        ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
            width: 50px;
            height: 50px;
        `}
    `}
`;

const OR50PerDtls = ({
    isLoading,
    isContinueDisabled,
    isSubmitting,
    isSuccessful,
    onClickContinue,
    onSubmit,
    continueButtonLabel = 'Create Account',
    children,
    footer,
    className,
}) => (
    <PerDtls className={classnames('OR50PerDtls', className)}>
        <Container isLoading={isLoading}>
            <StyledForm onSubmit={onSubmit}>
                {children}
                <ButtonRow>
                    {isSuccessful ? ( // eslint-disable-line no-nested-ternary
                        <IconHolder>
                            <IC50Success color={kayoGreen} />
                        </IconHolder>
                    ) : isSubmitting ? (
                        <IconHolder>
                            <IC103Loading color={white} />
                        </IconHolder>
                    ) : (
                        <BA01BtnPr
                            type={onSubmit ? 'submit' : 'button'}
                            isBlock={true}
                            disabled={isContinueDisabled}
                            onClick={onClickContinue}
                        >
                            {continueButtonLabel}
                        </BA01BtnPr>
                    )}
                </ButtonRow>
            </StyledForm>

            {footer}
        </Container>

        <CommonTransition in={isLoading}>
            <Loader>
                <IC103Loading
                    color={white}
                    size="50px"
                />
            </Loader>
        </CommonTransition>
    </PerDtls>
);

OR50PerDtls.propTypes = {
    /** Is the form loading? */
    isLoading: propTypes.bool,
    /** Continue button disabled? */
    isContinueDisabled: propTypes.bool,
    /** Display a loading spinner instead of the button? */
    isSubmitting: propTypes.bool,
    /** Display a success indicator instead of the button? */
    isSuccessful: propTypes.bool,
    /** Continue click callback */
    onClickContinue: propTypes.func,
    /** Form submit callback */
    onSubmit: propTypes.func,
    /** Label on the continue button */
    continueButtonLabel: propTypes.string,
    /** Form fields */
    children: propTypes.node,
    /** Optional footer */
    footer: propTypes.node,
    /** Additional class(es) */
    className: classNameType,
};

OR50PerDtls.displayName = 'OR50PerDtls';

export default OR50PerDtls;
