import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {palette as infinityPalette} from '@fsa-streamotion/streamotion-web-infinity';
import {palette as ionicPalette} from '@fsa-streamotion/streamotion-web-ionic';
import {palette as quicksilverPalette} from '@fsa-streamotion/streamotion-web-quicksilver';
import {palette as nucleusPalette} from '@fsa-streamotion/streamotion-web-nucleus';
import brandPropType from '../../../common/brand-proptype';

import {
    stylesWhenBinge,
    stylesWhenFlash,
    stylesWhenKayo,
    stylesWhenMagneto,
    stylesWhenLifestyle,
} from '../../../utils/styles-when-brand';

const {eli} = ionicPalette;
const {smoke} = infinityPalette;
const {ink} = quicksilverPalette;
const {slate} = nucleusPalette;

const LegalDisclaimerParagraph = styled.p`
    margin-top: 0;
    margin-bottom: 14px;
    text-align: center;
    color: ${eli};

    a {
        text-decoration: none;
        color: inherit;
    }

    /* stylelint-disable order/order */
    ${stylesWhenBinge`
        font: var(--ionic-body-copy-5);

        a {
            font: var(--ionic-body-copy-5-bold);
        }
    `}

    ${stylesWhenFlash`
        font: var(--infinity-body-copy-4);
        color: ${smoke};

        a {
            text-decoration: underline;
            font: var(--infinity-body-copy-4-bold);
        }
    `}

    ${stylesWhenKayo`
        font: var(--mui-body-copy-5);

        a {
            font: var(--mui-body-copy-5-bold);
        }
    `}

    ${stylesWhenLifestyle`
        font: var(--nucleus-body-copy-5);
        color: ${slate};

        a {
            font: var(--nucleus-body-copy-5-bold);
    `}

    ${stylesWhenMagneto`
        color: ${ink};
        opacity: 0.6;
        font: var(--quicksilver-body-copy-4-light);
        width: 100%;
        max-width: 538px; // magneto has no packages and should match the width of the form inputs

        a {
            text-decoration: underline;
        }
    `}
    /* stylelint-enable order/order */
`;

const LegalDisclaimerComponent = ({
    brand,
    children = [],
}) => (
    <div className="legal-disclaimer">
        {React.Children.map(children, (child) => (
            <LegalDisclaimerParagraph brand={brand}>
                {child}
            </LegalDisclaimerParagraph>
        ))}
    </div>
);

LegalDisclaimerComponent.displayName = 'LegalDisclaimerComponent';

LegalDisclaimerComponent.propTypes = {
    /**  brand (flash|binge|kayo|lifestyle) */
    brand: brandPropType,
    children: propTypes.arrayOf(propTypes.element),
};

export default LegalDisclaimerComponent;
