import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {classNameType, srcsetOptionsType} from '@fsa-streamotion/custom-prop-types';

import {SCREEN_REALLY_LG_DESKTOP} from '../../../../common/screen-sizes';
import GA09PlrPic from '../../../atoms/ga/09-plr-pic';

const Container = styled.div`
    display: grid;
    grid-template-columns: 77px 77px;
    grid-gap: 70px;
    justify-content: center;
    justify-items: center;
    margin: 14px 0 7px;
    width: 100%;
    overflow: hidden;
`;

const IGM27HudVersusTiles = ({
    teamAName,
    teamBName,
    teamALogoSrcset,
    teamBLogoSrcset,
    className,
}) => (
    <Container className={classnames('IGM27HudVersusTiles', className)}>
        <GA09PlrPic srcsetOptions={teamALogoSrcset} alt={teamAName} size="77px" sizesDefinition={{[SCREEN_REALLY_LG_DESKTOP]: 77}} />
        <GA09PlrPic srcsetOptions={teamBLogoSrcset} alt={teamBName} size="77px" sizesDefinition={{[SCREEN_REALLY_LG_DESKTOP]: 77}} />
    </Container>
);

IGM27HudVersusTiles.displayName = 'IGM27HudVersusTiles';

IGM27HudVersusTiles.propTypes = {
    /** Team A Name */
    teamAName: propTypes.string.isRequired,
    /** Team B Name */
    teamBName: propTypes.string.isRequired,
    /** Team A srcset for image */
    teamALogoSrcset: srcsetOptionsType,
    /** Team B srcset for image */
    teamBLogoSrcset: srcsetOptionsType,
    /** Optional Additional class names */
    className: classNameType,
};

export default IGM27HudVersusTiles;
