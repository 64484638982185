import React from 'react';
import classnames from 'classnames';
import property from 'lodash/property';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';

import {stylesWhen, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {white, blueCharcoal} from '../../../../common/palette';
import backdropFilter from '../../../../common/backdrop-filter';
import useCascadeCarousel from './use-cascade-carousel';

// Some of the transitions here are longer than the standard ones, very intentionally
const customTransition = (...cssProperties) => cssProperties
    .map(({cssProperty, time}) => `${cssProperty} ${time || '0.3s'} ease-in`)
    .join(', ');

/** ********************************************
 *                  WRAPPER
 ***********************************************/

/**
 *  This is the component actually holding DOM height for
 * layout purposes (e.g. during sticky and expanded states)
 */
const StyledNav = styled.nav`
    height: ${property('contractedHeightPx')}px;
`;

/** ********************************************
 *                  SCROLL
 ***********************************************/

// https://developers.google.com/web/updates/2017/09/sticky-headers
// When we scroll past this, we go into sticky mode & change background styling
const ScrollSentinel = styled.div`
    position: absolute;
    left: 0;
    margin-top: -1px;
    width: 100%;
    /* intersection observer must have at least 1px width and height to work on edge */
    height: 1px;
`;

/** ********************************************
 *                  STICKY
 ***********************************************/

const StickyContainer = styled.div`
    transition: ${customTransition({cssProperty: 'height'}, {cssProperty: 'margin-bottom'})};
    outline: 0;
    padding-bottom: 14px;
    width: 100%;
    overflow-y: hidden;

    ${stylesWhen(({isExpanded, isSticky}) => isExpanded && !isSticky)`
        transform: translate3d(0, 0, 0); /* Render on top of page content in stacking context */
    `}
`;

/** ********************************************
 *              ROWS OF CAROUSELS
 ***********************************************/

const RowsContainer = styled.ol`
    box-sizing: border-box;
    transition: ${customTransition({cssProperty: 'transform'}, {cssProperty: 'background-color', time: '0.1s'})};
    margin: 0;
    background-image: linear-gradient(to top, ${rgba(blueCharcoal, 0.5)} 2%, ${rgba(blueCharcoal, 0.15)} 99%); /* to top version of OR33HeaderDsk default state */
    padding: 0;
    width: 100%;
    overflow-y: hidden;
    list-style: none;

    ${stylesWhen('isScrolled')`
        ${backdropFilter}
        background-color: ${rgba(blueCharcoal, 0.8)};
    `}

    ${stylesWhenNot('isExpanded')`
        transform: translateY(${property('contractedTranslateYPx')}px);
    `}

    ${stylesWhen('isSticky')`
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1; /* As this is fixed, it has different stacking context */
    `}
`;

const RowItem = styled.li`
    border-bottom: 1px solid ${rgba(white, 0.1)}; /* similar to OR33HeaderDsk */
    padding-top: 5px;
`;

/** ********************************************
 *              MAIN
 ***********************************************/

const OR95CascadeCar = ({
    children,
    className,
    isInitiallyExpanded,
    isScrolled,
    ...htmlAttributes
}) => {
    const {
        contract,
        contractedHeightPx,
        contractedTranslateYPx,
        expand,
        handleKeyDown,
        isExpanded,
        isSticky,
        rowsContainerRef,
        scrollSentinelRef,
    } = useCascadeCarousel({
        isInitiallyExpanded,
        numChildren: React.Children.count(children),
    });

    return (
        <StyledNav
            {...htmlAttributes}
            className={classnames('OR95CascadeCar', className)}
            contractedHeightPx={contractedHeightPx}
            onKeyDown={handleKeyDown}
        >
            <ScrollSentinel ref={scrollSentinelRef} />
            <StickyContainer
                isSticky={isSticky}
                isExpanded={isExpanded}
                tabIndex={-1} // This section is focusable in Firefox, so prevent that
            >
                <RowsContainer
                    ref={rowsContainerRef}
                    contractedTranslateYPx={contractedTranslateYPx}
                    isSticky={isSticky}
                    isExpanded={isExpanded}
                    isScrolled={isScrolled}
                    onMouseOver={expand}
                    onFocus={expand}
                    onMouseOut={contract}
                    onBlur={contract}
                >
                    {React.Children.map(children, (child) => (
                        <RowItem>{child}</RowItem>
                    ))}
                </RowsContainer>
            </StickyContainer>
        </StyledNav>
    );
};

OR95CascadeCar.displayName = 'OR95CascadeCar';

OR95CascadeCar.propTypes = {
    /** The carousels to render as part of the nav */
    children: propTypes.node.isRequired,
    /** Additional class name(s) to apply to the root component */
    className: classNameType,
    /**
     * Whether to be initially expanded or not for multi-rows <br>
     * - _**NOTE:** Contracted view shows 30% peek of 2nd to the bottom + bottom row_
     */
    isInitiallyExpanded: propTypes.bool,
    /** Did user scroll down (moved from position Y:0)? This changes background styling */
    isScrolled: propTypes.bool,
};

export default OR95CascadeCar;
