import React, {cloneElement} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import property from 'lodash/property';

import {tabIndexType} from '../../../../../common/custom-proptypes';
import {CONTENT_EDGE_SPACING_PERCENT} from '../../../../../common/style-constants';
import {Z_INDEX_ABOVE_NEXT_TILE} from './constants';

const StyledDiv = styled.div`
    box-sizing: border-box;
    flex: 0 0 auto;
    /* Because this carousel item's parent width is extremely long, a % width doesn't work and so we used vw. But then this carousel doesn't line up with the carousels above/below it because of the vw/% difference when scrollbars are applied. */
    /* stylelint-disable-next-line function-parentheses-space-inside */
    width: calc(
        ((${property('viewportWidthPx')}px * ${1 - (0.02 * CONTENT_EDGE_SPACING_PERCENT)}) / var(--cam-05-tiles-per-row))
        - (
            var(--cam-05-gutter)
            * ((var(--cam-05-tiles-per-row) - 1) / var(--cam-05-tiles-per-row))
        )
    ); /* stylelint-disable-line function-parentheses-space-inside */

    @supports (scroll-snap-align: start) { /* Edge and FF currently don't properly implement. Causes more problems than its worth... */
        scroll-snap-align: start;
        scroll-margin: 0;
    }

    &:not(:last-child) {
        margin-right: var(--cam-05-gutter);
    }

    > * {
        &:focus,
        &:hover {
            z-index: ${Z_INDEX_ABOVE_NEXT_TILE};
        }
    }
`;

const CarouselSlot = React.forwardRef(({
    children,
    isBeingLazy = true,
    tabIndex,
    viewportWidthPx,
    ...htmlAttributes
}, ref) => (
    <StyledDiv {...htmlAttributes} ref={ref} viewportWidthPx={viewportWidthPx}>
        {cloneElement(
            React.Children.only(children),
            {isBeingLazy, tabIndex},
        )}
    </StyledDiv>
));

CarouselSlot.propTypes = {
    tabIndex: tabIndexType,
    children: propTypes.element,
    isBeingLazy: propTypes.bool,
    viewportWidthPx: propTypes.number,
};

CarouselSlot.displayName = 'CarouselSlot';

export default CarouselSlot;
