import React from 'react';

import Ic from '..';

const IC61Ios = (args) => (
    <Ic {...args}>
        <path d="M65.6 51.8c.1 8.7 7.7 11.6 7.7 11.6-.1.2-1.2 4.1-4 8.2-2.4 3.5-4.8 7-8.8 7.1-3.8.1-5.1-2.3-9.4-2.3-4.4 0-5.8 2.2-9.4 2.4-3.7.2-6.6-3.7-9-7.2-4.9-7.1-8.7-20.1-3.7-28.9 2.5-4.3 7-7.1 11.9-7.2 3.7-.1 7.2 2.5 9.4 2.5 2.3 0 6.5-3.1 11-2.6 1.9.1 7.1.8 10.5 5.7-.2 0-6.2 3.5-6.2 10.7m-7.2-21.4c2-2.5 3.3-5.8 3-9.2-2.9.1-6.4 2-8.4 4.3-1.9 2.1-3.5 5.6-3.1 8.8 3.3.5 6.6-1.4 8.5-3.9" />
    </Ic>
);

IC61Ios.displayName = 'IC61Ios';

export default IC61Ios;
