import React from 'react';
import Ic from '..';

const IC12RR = (args) => (
    <Ic {...args}>
        <path d="M58 37.527L58 42.818 78.717 27.466 78.717 72.534 58 57.182 58 62.473 83 81 83 19zM44.601 35.91v28.096L25.998 50l18.603-14.09M53 19L12 50l41 31V19z" />
    </Ic>
);

IC12RR.displayName = 'IC12RR';

export default IC12RR;
