import get from 'lodash/get';
import Aem from '../aem';
import PackageSelector from '../package-selector';
import TempModule from '../temp-module';
import HeroBanner from '../hero-banner';
import PromoModalCta from '../promo-modal-cta';

/** Module type to component mapping */
const MODULE_CONSTRUCTOR = {
    'aem': Aem,
    'package-selector': PackageSelector,
    'hero-banner': HeroBanner,
    'promo-modal-cta': PromoModalCta,
};

/**
 * Retrieves the component associated with the module type from AEM
 *
 * @param {Object} options  options
 * @param {string} options.type  module type
 * @param {string} options.id  module ID
 * @param {string} options.path path of the page loaded
 * @returns {React.Component}  module component
 */
const getModuleComponent = ({
    type,
    id,
    path,
}) => {
    if (!MODULE_CONSTRUCTOR[type]) {
        // eslint-disable-next-line no-console
        console.warn(`ACC-WIDGETS: Unrecognised module type: [${type}] on moduleId: [${id}] on page [${path}]. Defaulting to TempModule.`);
    }

    // @TODO: Temporarily defaulting unmapped modules to TempModule - Please remove this later on!
    return get(MODULE_CONSTRUCTOR, type, TempModule);
};

export default getModuleComponent;
