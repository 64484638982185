import React from 'react';
import Ic from '..';

const IC07Pau = (args) => (
    <Ic {...args}>
        <path d="M27.8 5.6h7.4v88.9h-7.4zM64.8 5.6h7.4v88.9h-7.4z" />
    </Ic>
);

IC07Pau.displayName = 'IC07Pau';

export default IC07Pau;
