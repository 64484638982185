import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {black, fog, white} from '../../../../common/palette';
import {Section} from '../../../../common/normalized-styled-components';
import IC103Loading from '../../../atoms/ic/103-loading';
import BA01BtnPr from '../../../atoms/ba/01-btn-pr';
import TM24AlertTile from '../24-alert-tile';

const Container = styled(Section)`
    box-sizing: border-box;
    border-radius: 4px;
    padding: 21px;
    width: 100%;
    text-shadow: 0 1px 1px ${rgba(black, 0.3)};
    color: ${rgba(fog, 0.7)};
    font: var(--infinity-body-copy-3-light);
`;

const StyledHeading = styled.h3`
    margin: 7px 0;
    text-align: center;
    color: ${white};
    font: var(--infinity-header-6);
`;

const StyledP = styled.p`
    margin: 14px;
    text-align: center;
`;

const ButtonContainer = styled.div`
    margin: 20px;
    text-align: center;
`;

const TM25UpdateTile = ({
    className,
    ctaCopy,
    error,
    isLoading,
    onButtonClick,
    subtext,
    title,
    ...htmlAttributes
}) => (
    <Container
        {...htmlAttributes}
        className={classnames('TM25UpdateTile', className)}
    >
        <StyledHeading>{title}</StyledHeading>
        <StyledP>{subtext}</StyledP>
        <ButtonContainer>
            {isLoading ? (
                <IC103Loading size="47px" />
            ) : (
                <BA01BtnPr
                    onClick={onButtonClick}
                >
                    {ctaCopy}
                </BA01BtnPr>
            )}
        </ButtonContainer>
        {!!error && (
            <TM24AlertTile>
                {error}
            </TM24AlertTile>
        )}
    </Container>
);

TM25UpdateTile.propTypes = {
    /** Additional class(es) */
    className: classNameType,
    /** Copy to use for CTA */
    ctaCopy: propTypes.node,
    /** Smaller descriptive text */
    subtext: propTypes.node.isRequired,
    /** Is trying to cancel adjustment currently */
    isLoading: propTypes.bool,
    /** Cancel adjustment error */
    error: propTypes.string,
    /** Cancel adjustment button click handler */
    onButtonClick: propTypes.func,
    /** Large descriptor text */
    title: propTypes.node.isRequired,
};

TM25UpdateTile.displayName = 'TM25UpdateTile';

export default TM25UpdateTile;
