import {CONTENT_EDGE_SPACING_PERCENT} from '../../../../../common/style-constants';

export const ZOOM_ALLOWANCE_PX = 60; // because overflow: scroll doesn't play nice with the hover effect of our tiles, we need to allow some space
export const Z_INDEX_FILMSTRIP = 0;
export const Z_INDEX_CONTROLS = Z_INDEX_FILMSTRIP + 1;
export const VIEWPORT_PERC = (100 - (2 * CONTENT_EDGE_SPACING_PERCENT));
export const SCROLL_DURATION_MS = 500;
export const FILMSTRIP_PREV = Symbol('Previous filmstrip');
export const FILMSTRIP_CURR = Symbol('Currently visible filmstrip');
export const FILMSTRIP_NEXT = Symbol('Next filmstrip');
export const Z_INDEX_ABOVE_NEXT_TILE = 1;
