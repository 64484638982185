import styled from 'styled-components';

import {white} from '../../../../common/palette';

const SectionHeading = styled.h2`
    margin-top: 0;
    margin-bottom: 7px;
    text-align: center;
    color: ${white};
    font: var(--mui-header-7-bold);
`;

export default SectionHeading;
