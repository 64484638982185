/* eslint-disable react-hooks/rules-of-hooks */
import {useEffect, useState} from 'react';
import {isServer} from '@fsa-streamotion/browser-utils';

export default function useMatchMedia(mediaQueryString, defaultValue) {
    if (isServer()) {
        return defaultValue;
    }

    const [matches, setMatches] = useState(defaultValue);

    useEffect(function callSetMatchesOnChange() {
        const mediaQueryList = window.matchMedia(mediaQueryString);

        setMatches(mediaQueryList.matches);

        const listener = (event) => {
            setMatches(event.matches);
        };

        mediaQueryList.addEventListener('change', listener);

        return () => mediaQueryList.removeEventListener('change', listener);
    }, [mediaQueryString]);

    return matches;
}
