import React from 'react';
import Ic from '..';

const IC236Movies = (args) => (
    <Ic {...args}>
        <path d="M61.164 21a11.774 11.774 0 11-11.76 11.76c.008-6.492 5.268-11.752 11.76-11.76m0-7a18.774 18.774 0 1018.774 18.76c0-4.978-1.978-9.752-5.5-13.27A18.75 18.75 0 0061.164 14zm19.922 44.618v12.838l8.4 5.278v-24.36z" />
        <path d="M29.16 32.76a11.773 11.773 0 11-4.488 22.653A11.773 11.773 0 0117.4 44.534c.008-6.494 5.266-11.759 11.76-11.774m0-7c-10.369 0-18.774 8.405-18.774 18.774 0 10.369 8.405 18.774 18.774 18.774 10.369 0 18.774-8.405 18.774-18.774 0-10.369-8.405-18.774-18.774-18.774zm47.264 28.224a25.903 25.903 0 01-7 3.57v21.154H28.18v-8.064a26.613 26.613 0 01-7-1.232v16.296h55.3V53.956l-.056.028z" />
    </Ic>
);

IC236Movies.displayName = 'IC236Movies';

export default IC236Movies;
