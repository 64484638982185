import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import {blanc, midnight} from '../../../../common/palette';
import Ic from '..';

const IC169ContextualPlay = ({
    color = blanc,
    secondaryColor = midnight,
    ...otherProps
}) => (
    <Ic {...otherProps}>
        <g transform="translate(7 7)">
            <circle cx="42.7" cy="42.7" r="42.7" fill={color} />
            <path fill={secondaryColor} d="M35.868 26.712v31.794L56.42 42.602z" />
        </g>
    </Ic>
);

IC169ContextualPlay.propTypes = {
    /** The icon's primary color */
    color: colorType,
    /** The icon's secondary color */
    secondaryColor: colorType,
};

IC169ContextualPlay.displayName = 'IC169ContextualPlay';

export default IC169ContextualPlay;
