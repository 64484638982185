import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import get from 'lodash/get';

import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';

import {white, black, satellite} from '../../../../../common/palette';
import VPBA01CtrlBtn from '../../../../atoms/vp/ba/01-ctrl-btn';
import {IC26ArrowU, IC27ArrowD} from '../../../../atoms/ic';
import {transition, TRANSITION_CLASSNAMES} from '../../../../../common/animations';
import CommonTransition from '../../../../../common/common-transition';
import VPTM06TraySectionList from '../../../../molecules/vp/tm/06-tray-section-list';
import VPBA02TraySectionListItem from '../../../../atoms/vp/ba/02-tray-section-list-item';

export const TRAY_HEIGHT_PX = 141;

// !isVisible should always correspond to 0 for the transition to work
const getTrayPosition = (isTop, isVisible) => {
    if (isVisible) {
        return isTop ? TRAY_HEIGHT_PX : TRAY_HEIGHT_PX * -1;
    } else {
        return 0;
    }
};

const Tray = styled.section`
    display: flex;
    position: relative;
    align-items: center;
    transition: ${transition('margin-top')};
    background-image: linear-gradient(${rgba(black, 0.5)}, ${black});
    height: ${TRAY_HEIGHT_PX}px;

    ${stylesWhen('isTop')`
        background-image: linear-gradient(${black}, ${rgba(black, 0.5)});
        margin-top: ${({isVisible}) => isVisible ? 0 : TRAY_HEIGHT_PX * -1}px;
    `}

    /* stylelint-disable no-duplicate-selectors */
    &.${TRANSITION_CLASSNAMES.enterActive},
    &.${TRANSITION_CLASSNAMES.enterDone},
    &.${TRANSITION_CLASSNAMES.exit} {
        margin-top: ${({isTop}) => getTrayPosition(isTop, true)}px;
    }

    &.${TRANSITION_CLASSNAMES.enter},
    &.${TRANSITION_CLASSNAMES.exitActive},
    &.${TRANSITION_CLASSNAMES.exitDone} {
        margin-top: ${({isTop}) => getTrayPosition(isTop, false)}px;
    }
    /* stylelint-enable no-duplicate-selectors */
`;

const Content = styled.section`
    flex-grow: 1;
    width: auto;
    overflow: hidden;
`;

const CloseButtonContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    height: fit-content;

    ${stylesWhen('isTop')`
        bottom: 0;
        top: auto;
    `}
`;

const TraySectionListContainer = styled.div`
    box-sizing: border-box;
    flex-shrink: 0;
    height: 100%;
`;

// necessary as it doesn't necessarily conform to the height of the list
const Divider = styled.div`
    margin: 0 15px 0 3px;
    border-right: 1px solid ${rgba(white, 0.25)};
    width: 0;
    height: 80%;
`;

export default class VPOR02Tray extends React.Component {
    static displayName = 'VPOR02Tray';

    static propTypes = {
        tabItems: propTypes.arrayOf(propTypes.shape({
            name: propTypes.string,
            node: propTypes.node,
        })),
        isTop: propTypes.bool,
        isVisible: propTypes.bool,
        onTrayClose: propTypes.func,
    };

    static defaultProps = {
        tabItems: [],
        isTop: true,
    };

    state = {
        activeIndex: 0,
    };

    render() {
        const {
            tabItems,
            isTop,
            isVisible,
            onTrayClose,
        } = this.props;

        const activeTraySectionContent = get(tabItems, [this.state.activeIndex, 'node'], null);

        return (
            <CommonTransition in={isVisible}>
                <Tray isVisible={isVisible} isTop={isTop} className="VPOR02Tray">
                    <TraySectionListContainer>
                        <VPTM06TraySectionList>
                            {tabItems.map(({name}, index) => (
                                <VPBA02TraySectionListItem
                                    key={name}
                                    label={name}
                                    isActive={this.state.activeIndex === index}
                                    onClick={() => this.setState({activeIndex: index})}
                                />
                            ))}
                        </VPTM06TraySectionList>
                    </TraySectionListContainer>
                    <Divider />
                    <Content role="tabpanel">
                        {activeTraySectionContent}
                    </Content>
                    <CloseButtonContainer isTop={isTop}>
                        <VPBA01CtrlBtn onClick={onTrayClose} size="34px" aria-label={`Close ${isTop ? 'upper' : 'lower'} tray`} tabIndex="-1">
                            {isTop ? <IC26ArrowU color={satellite} /> : <IC27ArrowD color={satellite} />}
                        </VPBA01CtrlBtn>
                    </CloseButtonContainer>
                </Tray>
            </CommonTransition>
        );
    }
}
