import React from 'react';
import Ic from '..';

const IC13FF = (args) => (
    <Ic {...args}>
        <path d="M42 37.527L42 42.818 21.286 27.466 21.286 72.534 42 57.182 42 62.473 17 81 17 19zM55.396 35.91v28.096L74.006 50l-18.61-14.09M47 19l41 31-41 31V19z" />
    </Ic>
);

IC13FF.displayName = 'IC13FF';

export default IC13FF;
