import React, {useEffect, useRef, useState} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import property from 'lodash/property';

import {stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';

import {CONTENT_EDGE_SPACING_PERCENT} from '../../../../../common/style-constants';
import {white} from '../../../../../common/palette';
import {ZOOM_ALLOWANCE_PX} from './constants';

const StyledFilmstrip = styled.section`
    display: grid;
    position: relative;
    flex: 0 0 0%;
    grid-template: auto 1fr / 1fr;
    margin: ${ZOOM_ALLOWANCE_PX * -1}px calc(var(--cam-05-gutter) / 2);
    padding: ${ZOOM_ALLOWANCE_PX}px 0;

    ${stylesWhenNot('hasNextHint')`
        &:last-child{
            &::after {
                display: block;
                min-width: calc(${CONTENT_EDGE_SPACING_PERCENT}vw - var(--cam-05-gutter));
                content: '';
            }
        }
    `}

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }
`;

const TitleContainer = styled.div`
    position: sticky;
    left: ${CONTENT_EDGE_SPACING_PERCENT}%;
    /* A sneaky way to keep the title sticking to the beginning of the final slide when changing panels */
    padding-right: ${property('paddingRight')}px;
    width: fit-content;
    white-space: nowrap;
    color: ${white};
    font: var(--infinity-header-8-bold);
`;

const PanelContainer = styled.div`
    display: flex;
`;

const FilmStrip = React.forwardRef(({
    children,
    hasNextHint,
    hasPrevHint,
    onScroll,
    carouselWidthPx,
    title,
    ...htmlAttributes
}, ref) => {
    const [titleWidth, setTitleWidth] = useState(0);
    const titleContainerRef = useRef();

    useEffect(function calculateTitleWidthAfterRender() {
        if (titleContainerRef) {
            const {width} = titleContainerRef.current.getBoundingClientRect();

            setTitleWidth(width);
        }
    }, []);

    return (
        <StyledFilmstrip
            {...htmlAttributes}
            hasNextHint={hasNextHint}
            ref={ref}
            onScroll={onScroll}
        >
            <TitleContainer
                ref={titleContainerRef}
                paddingRight={titleWidth === 0 || titleWidth > carouselWidthPx ? 0 : carouselWidthPx - titleWidth}
            >
                {title}
            </TitleContainer>
            <PanelContainer>{children}</PanelContainer>
        </StyledFilmstrip>
    );
});

FilmStrip.propTypes = {
    carouselWidthPx: propTypes.number,
    children: propTypes.node,
    hasNextHint: propTypes.bool,
    hasPrevHint: propTypes.bool,
    onScroll: propTypes.func,
    firstSlideTeaser: propTypes.element,
    lastSlideTeaser: propTypes.element,
    title: propTypes.node,
};

FilmStrip.displayName = 'FilmStrip';

export default FilmStrip;
