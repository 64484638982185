import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import GA93Telstra from '../../../atoms/ga/93-telstra';
import TM19Pmnt from '../19-pmnt';

const OverflowEllipsis = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const TM19PmntTelstra = ({
    name,
    className,
    ...htmlAttributes
}) => (
    <TM19Pmnt
        {...htmlAttributes}
        className={classnames('TM19PmntTelstra', className)}
        icon={<GA93Telstra />}
    >
        <div>
            Pay with Telstra
        </div>

        <OverflowEllipsis>
            {name}
        </OverflowEllipsis>
    </TM19Pmnt>
);

TM19PmntTelstra.displayName = 'TM19PmntTelstra';

TM19PmntTelstra.propTypes = {
    /** Name of the user */
    name: propTypes.string.isRequired,
    /** Additional class(es) */
    className: classNameType,
};

export default TM19PmntTelstra;
