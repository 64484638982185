import doublePanel from './double';

export default function panel({
    commonWidgetSettings,
    key,
    onRequestVideoPlay,
    panelData = {},
    profileId,
    shouldEvaluate = true,
    ...restSettings
}) {
    if (panelData.panelType !== 'double') {
        if (process.env.NODE_ENV !== 'production') {
            console.warn(`Accounts-Widgets: Unrecognised panel type: ${panelData.panelType} on panelId: ${panelData.id} on page ${commonWidgetSettings.path}`);
        }

        // Fail gracefully on invalid panelType
        return {
            reactElement: null,
        };
    }

    return doublePanel({
        ...restSettings,
        commonWidgetSettings,
        key,
        onRequestVideoPlay,
        panelData,
        profileId,
        shouldEvaluate,
    });
}
