import React from 'react';
import classNames from 'classnames';
import property from 'lodash/property';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {srcsetOptionsType, classNameType} from '@fsa-streamotion/custom-prop-types';

import SrcsetImg from '../../../../common/srcset-img';

const StyledSrcsetImage = styled(SrcsetImg)`
    width: ${property('size')};
    height: ${property('size')};
`;

const GA09PlrPic = ({
    className,
    srcsetOptions,
    size = '100%',
    alt = '',
    ...htmlAttributes
}) => (
    <StyledSrcsetImage
        alt={alt}
        className={classNames('GA09PlrPic', className)}
        size={size}
        srcsetOptions={srcsetOptions}
        {...htmlAttributes}
    />
);

GA09PlrPic.displayName = 'GA09PlrPic';

GA09PlrPic.propTypes = {
    /** Image text if the image does not load in time */
    alt: propTypes.string,
    /** Additional class(es) */
    className: classNameType,
    /** Srcset for a Player Image */
    srcsetOptions: srcsetOptionsType,
    /** Size of the image */
    size: propTypes.string,
};

export default GA09PlrPic;
