import React from 'react';
import propTypes from 'prop-types';

import Ic from '..';

const IC06Play = (args) => (
    <Ic {...args}>
        <path
            fillOpacity={0.35}
            strokeWidth={7}
            stroke="currentColor"
            d="M24.556 11.828c.464-.146.57-.11.657-.044l49.584 37.818a.498.498 0 0 1 0 .796L25.213 88.216a.498.498 0 0 1-.803-.398V12.182c0-.139.056-.264.146-.354z"
        />
    </Ic>
);

IC06Play.propTypes = {
    /* Color of the fill inside the play icon */
    fillColor: propTypes.string,
};

IC06Play.displayName = 'IC06Play';

export default IC06Play;
