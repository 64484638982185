import React, {useMemo} from 'react';
import propTypes from 'prop-types';
import {Section} from 'normalized-styled-components';
import {rgba} from 'polished';
import styled from 'styled-components';
import classnames from 'classnames';
import property from 'lodash/property';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {colorType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {
    SCREEN_768_TABLET,
    SCREEN_1024_DESKTOP,
    SCREEN_1480_DESKTOP,
    SCREEN_1920_DESKTOP,
} from '../../../../common/screen-sizes';
import {onyx} from '../../../../common/palette';
import {CONTENT_EDGE_SPACING_PERCENT} from '../../../../common/style-constants';
import {UP_NEXT_VIDEO_CONTENT_TOP_PX, UP_NEXT_VIDEO_CONTENT_TOP_PX__LARGE} from '../02-up-next/up-next-common';

const Z_INDEX_ON_TOP_OF_CONTENT = 1;
const Z_INDEX_ON_TOP_OF_GRADIENT = 2;

const StyledSectionWithGradients = styled(Section)`
    position: relative;
    background-color: ${property('gradientColor')}; /* give us a nicer themed background colour if we're waiting on the main image to load */
    padding-bottom: 0;
    width: 100%;
    height: 100%; /* take the height of the container, so long as its taller than our minimum ratio */

    &::before { /* Top gradient */
        position: absolute;
        bottom: 0;
        z-index: ${Z_INDEX_ON_TOP_OF_CONTENT};
        /** Sorry stylelint, this structure is better in our opinion :P */
        /* stylelint-disable */
        background: linear-gradient(
            to top,
            ${({gradientColor}) => `${gradientColor} 14%,
            ${rgba(gradientColor, 0.45)} 63%`},
            transparent 81%
        );
        /* stylelint-enable */
        width: 100%;
        height: 417px;
        content: '';
        pointer-events: none;

        ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
            bottom: -8vw;
            background: linear-gradient(to bottom, transparent 17%, ${property('gradientColor')});
            height: 24vw;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
            background: linear-gradient(
                to top,
                ${({gradientColor}) => `${gradientColor} 10%,
                ${rgba(gradientColor, 0.6)} 60%,
                transparent`}
            );
        `}
    }

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        z-index: ${Z_INDEX_ON_TOP_OF_CONTENT};
        background-color: ${rgba(onyx, 0.5)};
        width: 100%;
        height: 200vh;
        content: '';
    }
`;

const ContentPlacement = styled.div`
    position: absolute;
    top: ${UP_NEXT_VIDEO_CONTENT_TOP_PX}px;
    right: ${CONTENT_EDGE_SPACING_PERCENT}%;
    left: 30%;
    z-index: ${Z_INDEX_ON_TOP_OF_GRADIENT};

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        top: ${UP_NEXT_VIDEO_CONTENT_TOP_PX__LARGE}px;
        left: 25%;
        transform: translateY(100%);
    `}
`;

const Background = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        height: calc(100% + 8vw);
    `}

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        visibility: hidden;
        background: linear-gradient(to right, ${({gradientColor}) => `${gradientColor}, ${rgba(gradientColor, 0.6)} 34%, transparent 66%`});
        width: 75vw;
        height: 100%;
        content: '';

        ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
            visibility: visible;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
            width: 56.25vw;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1480_DESKTOP})`
            width: 64.83vw;
        `}
    }

    > * {
        filter: brightness(80%);
    }
`;

export default function XXUpNextHero({
    backgroundImage,
    children,
    className,
    gradientColor = onyx,
    ...htmlAttributes
}) {
    const safeGradientColor = useMemo(() => (
        /^#[a-fA-F0-9]{6}$/.test(gradientColor)
            ? gradientColor
            : onyx
    ), [gradientColor]);

    return (
        <StyledSectionWithGradients
            {...htmlAttributes}
            className={classnames('XXUpNextHero', className)}
            gradientColor={safeGradientColor}
        >
            <Background gradientColor={safeGradientColor}>
                {backgroundImage}
            </Background>

            <ContentPlacement>
                {children}
            </ContentPlacement>
        </StyledSectionWithGradients>
    );
}

XXUpNextHero.displayName = 'XXUpNextHero';

XXUpNextHero.propTypes = {
    /** Background image, e.g. GA05BgImg */
    backgroundImage: propTypes.node,
    /** Heading Content, e.g. TM04 */
    children: propTypes.node,
    /** Additional className(s) to apply */
    className: classNameType,
    /** Applied to the left and bottom of the slide. Specifically NOT using the global CSS property: the hero should never inherit colours from elsewhere */
    gradientColor: colorType,
};
