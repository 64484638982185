import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';
import {Section, Sup} from 'normalized-styled-components';

import {mediaQuery, stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {SCREEN_LG_DESKTOP, SCREEN_PRETTY_LG_DESKTOP} from '../../../../common/screen-sizes';
import {PERIOD_SHORT} from '../../../../common/billing-constants';
import {billingPeriodType} from '../../../../common/custom-proptypes';
import {slate, white, black, coal, midnight, silverLining} from '../../../../common/palette';
import splitPrice from '../../../../common/split-price';
import IC31Tick from '../../../atoms/ic/31-tick';

const StyledSection = styled(Section)`
    border: 1px solid ${rgba(slate, 0.7)};
    border-radius: 6px;
`;

const HeaderBox = styled.div`
    display: flex;
    position: relative;
    border-radius: 6px;
    box-shadow: 0 1px 1px 0 ${rgba(black, 0.3)};
    background-color: ${white};
    padding: 14px;
`;

const PlanInfo = styled.div`
    flex: 2;
`;

const PlanPrice = styled.div`
    position: inherit;
    flex: 0 1 auto;
    margin: auto;
    padding-right: 7px;
    color: ${coal};
    font: var(--mui-sub-price);
`;

const Heading = styled.h3`
    margin: 0;
    text-transform: uppercase;
    color: ${coal};
    font: var(--mui-header-7-bold);
`;

const Summary = styled.div`
    color: ${coal};
    font: var(--mui-body-copy-4);
`;

const PlanPriceFraction = styled(Sup)`
    position: absolute;
    top: 0;
    font: var(--mui-body-copy-4);
    line-height: 1; /* stylelint-disable-line order/properties-order */

    ${mediaQuery({minWidthPx: SCREEN_PRETTY_LG_DESKTOP})`
        top: 3px;
    `}
`;

const PlanPeriod = styled.span`
    color: ${coal};
    font: var(--mui-offer-period);
`;

const Checkedmark = styled.span`
    position: absolute;
    top: -11px;
    right: -11px;
    width: 23px;
    height: 23px;

    ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
        width: 30px;
        height: 30px;
    `}
`;

const Wrapper = styled.div`
    opacity: 0.7;
`;

const List = styled.ul`
    margin: 0;
    padding: 19px 14px 20px 34px;
    text-shadow: 0 1px 1px ${rgba(black, 0.3)};
    color: ${silverLining};
    font: var(--mui-body-copy-4);

    ${stylesWhen('hasSeparator')`
        border-top: 1px solid ${slate};
    `}
`;

const ListItem = styled.li`
    padding-left: 10px;
    font: var(--mui-body-copy-4-light);

    :not(:last-of-type) {
        margin-bottom: 7px;
    }

    > a {
        color: inherit;
        font: var(--mui-body-copy-4);

        &:focus,
        &:hover {
            text-decoration: none;
        }
    }
`;

const ListPrePostItem = styled(ListItem)`
    list-style-type: none;
`;

const UpperCase = styled.span`
    text-transform: uppercase;
`;

const OR49SumPnlMyAccount = ({
    billingPrice = 0,
    billingPeriod = 'month',
    children,
    className,
    currencyDecimalPlaces = 2,
    currencyPrefix = '$',
    listItems = [],
    listItemsPreNode,
    listItemsPostNode,
    heading = '',
    summary = '',
}) => {
    const [billingPriceUnits, billingPriceFraction] = splitPrice(billingPrice, currencyDecimalPlaces);
    const planPeriodLabel = PERIOD_SHORT[billingPeriod];
    const hasChildren = !!React.Children.count(children);

    return (
        <StyledSection className={classnames('OR49SumPnl', className)}>
            <HeaderBox>
                <PlanInfo>
                    {!!heading && (
                        <Heading>{heading}</Heading>
                    )}
                    {!!summary && (
                        <Summary>{summary}</Summary>
                    )}
                </PlanInfo>
                <PlanPrice hasFraction={!!billingPriceFraction} aria-label={billingPrice ? `${currencyPrefix}${billingPrice} per ${billingPeriod}` : undefined}>
                    {billingPrice ? (
                        <React.Fragment>
                            {currencyPrefix}{billingPriceUnits}

                            {!!billingPriceFraction && (
                                <PlanPriceFraction>
                                    .{billingPriceFraction}
                                </PlanPriceFraction>
                            )}

                            {!!planPeriodLabel && (
                                <PlanPeriod>
                                    /{planPeriodLabel}
                                </PlanPeriod>
                            )}
                        </React.Fragment>
                    ) : <UpperCase>Free</UpperCase>}
                </PlanPrice>
                <Checkedmark>
                    <IC31Tick secondaryColor={midnight} />
                </Checkedmark>
            </HeaderBox>
            {(hasChildren || !!listItems.length) && (
                <Wrapper>
                    {children}
                    {!!(listItems.length || listItemsPreNode || listItemsPostNode) && (
                        <List hasSeparator={hasChildren}>
                            {!!listItemsPreNode && <ListPrePostItem>{listItemsPreNode}</ListPrePostItem>}
                            {listItems.map((descLine, index) => (
                                <ListItem key={index}>
                                    {descLine}
                                </ListItem>
                            ))}
                            {!!listItemsPostNode && <ListPrePostItem>{listItemsPostNode}</ListPrePostItem>}
                        </List>
                    )}
                </Wrapper>
            )}
        </StyledSection>
    );
};

OR49SumPnlMyAccount.displayName = 'OR49SumPnlMyAccount';

OR49SumPnlMyAccount.propTypes = {
    /** Contents, like the offer features */
    children: propTypes.node,
    /** Classname if passed down */
    className: classNameType,
    /** Currency decimal places */
    currencyDecimalPlaces: propTypes.number,
    /** Currency prefix */
    currencyPrefix: propTypes.oneOf(['$']),
    /** Heading */
    heading: propTypes.string,
    /** Optional summary to display when the header is sticking or we want to show the summary for any other reason */
    summary: propTypes.node,
    /** Billing price */
    billingPrice: propTypes.number,
    /** Billing period eg. month */
    billingPeriod: billingPeriodType,
    /** Terms, other features, etc. */
    listItems: propTypes.arrayOf(propTypes.node),
    /** Node that goes above listItems */
    listItemsPreNode: propTypes.node,
    /** Node that goes below listItems */
    listItemsPostNode: propTypes.node,
};

export default OR49SumPnlMyAccount;
