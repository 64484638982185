import React from 'react';
import Ic from '..';

const IC109Spor = (args) => (
    <Ic {...args}>
        <g opacity=".35" >
            <path d="M26.679 31.299c-5.577 0-10.789 5.593-13.354 13.94l-.22.744-.205.758c-.793 3.057-1.24 6.436-1.24 10.012 0 3.577.447 6.956 1.24 10.013l.205.757.22.744c2.565 8.347 7.777 13.94 13.354 13.94 5.576 0 10.788-5.593 13.353-13.94l.22-.744.205-.757c.793-3.057 1.24-6.436 1.24-10.013 0-3.576-.447-6.955-1.24-10.012l-.205-.758-.22-.743c-2.565-8.348-7.777-13.941-13.353-13.941z" />
            <path d="M55.679 16.753c-6.007 0-10.875 4.884-10.875 10.91 0 6.024 4.868 10.908 10.875 10.908 6.006 0 10.875-4.884 10.875-10.909s-4.87-10.909-10.875-10.909z" />
            <path d="M71.214 45.844c-10.01 0-18.125 8.14-18.125 18.182 0 10.042 8.115 18.182 18.125 18.182s18.125-8.14 18.125-18.182c0-10.042-8.115-18.182-18.125-18.182z" />
        </g>
        <path d="M26.679 27.662c10.296 0 18.642 13.025 18.642 29.091 0 16.067-8.346 29.091-18.642 29.091-10.297 0-18.643-13.024-18.643-29.09 0-16.067 8.346-29.092 18.643-29.092zM13.325 45.24l-.22.743-.205.758c-.793 3.057-1.24 6.436-1.24 10.012 0 3.577.447 6.956 1.24 10.013l.205.757.22.744c2.486 8.088 7.456 13.591 12.836 13.925V64.545h-2.849c-.429 0-.776-.465-.776-1.039 0-.532.3-.971.686-1.031l.09-.007h2.849V58.31h-2.849c-.429 0-.776-.464-.776-1.038 0-.533.3-.972.686-1.032l.09-.007h2.849v-4.157h-2.849c-.429 0-.776-.464-.776-1.038 0-.533.3-.972.686-1.032l.09-.007h2.849V31.314c-5.38.333-10.35 5.836-12.836 13.925zm14.907-13.797V50h2.848c.43 0 .777.465.777 1.039 0 .533-.3.972-.686 1.032l-.09.007h-2.85v4.155h2.85c.428 0 .776.466.776 1.04 0 .533-.3.972-.686 1.032l-.09.007h-2.85v4.155h2.85c.428 0 .776.466.776 1.04 0 .532-.3.971-.686 1.031l-.09.007h-2.85l.001 17.518c4.975-.926 9.475-6.23 11.8-13.796l.22-.744.205-.757c.793-3.057 1.24-6.436 1.24-10.013 0-3.576-.447-6.955-1.24-10.012l-.205-.758-.22-.743c-2.325-7.567-6.825-12.87-11.8-13.797z" />
        <path d="M55.679 13.117c8.008 0 14.5 6.512 14.5 14.545 0 8.034-6.492 14.546-14.5 14.546-8.009 0-14.5-6.512-14.5-14.546 0-8.033 6.491-14.545 14.5-14.545zM66.443 29.22h-21.53c.755 5.286 5.287 9.35 10.766 9.35 5.478 0 10.01-4.064 10.764-9.35zM55.68 16.753c-5.833 0-10.593 4.606-10.863 10.389H66.54c-.27-5.783-5.03-10.389-10.862-10.389z" />
        <path d="M71.214 42.208c12.012 0 21.75 9.768 21.75 21.818s-9.738 21.818-21.75 21.818-21.75-9.768-21.75-21.818c0-6.462 2.801-12.269 7.251-16.264l.1-.088a21.63 21.63 0 0 1 14.4-5.466zM60.88 78.838l-.052.09a17.996 17.996 0 0 0 9.868 3.273v-9.788c-4.2.454-7.8 2.924-9.816 6.425zm11.887-6.484.001 9.788a18.013 18.013 0 0 0 10.038-4.138c-2.157-3.307-5.787-5.447-10.039-5.65zm-2.07-6.77H53.154a18.16 18.16 0 0 0 6.031 12.043c2.394-4.031 6.61-6.847 11.51-7.302v-4.741zm18.577 0H72.767v4.69c4.852.2 9.022 2.58 11.573 6.29a18.135 18.135 0 0 0 4.934-10.98zm-31.126-14.16-.18.192a18.144 18.144 0 0 0-4.871 11.89h17.6v-4.312c-5.408-.213-10.08-3.294-12.55-7.77zm26.236.11-.137.239c-2.375 4.048-6.583 6.884-11.479 7.362v4.37l16.564.001a18.145 18.145 0 0 0-4.948-11.972zm-24.61-1.612-.052.044c2.044 4.079 6.168 6.928 10.975 7.148V45.851a18.011 18.011 0 0 0-10.922 4.071zm12.994-4.012v11.135c4.393-.498 8.12-3.203 10.054-6.982a18.006 18.006 0 0 0-10.054-4.153z" />
    </Ic>
);

IC109Spor.displayName = 'IC109Spor';

export default IC109Spor;
