import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {colorType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {shapeRotate} from '../../../../common/animations';
import {kayoGreen} from '../../../../common/palette';
import Ic from '..';

// Wrap it in animating keyframes instead of doing svg animation because edge doesn't support the latter
const RotatingContainer = styled.span`
    display: ${({display = 'inline-block'}) => display};
    animation: ${shapeRotate} 0.5s linear infinite;
`;

const StyledPath = styled.path`
    opacity: 0.5;
`;

// WARNING: This SVG is a placeholder that was randomly taken from the internet and should not be used in production
const IC103Loading = ({color = kayoGreen, size, display, className, ...props}) => (
    <RotatingContainer
        display={display}
        className={className}
        style={{
            width: size,
            height: size,
        }}
    >
        <Ic {...props} color={color}>
            <StyledPath d="M50,0C22.4,0,0,22.4,0,50c0,27.6,22.4,50,50,50s50-22.4,50-50C100,22.4,77.6,0,50,0z M50,88.9 c-21.5,0-38.9-17.4-38.9-38.9c0-21.5,17.4-38.9,38.9-38.9S88.9,28.5,88.9,50C88.9,71.5,71.5,88.9,50,88.9z" />
            <path d="M69.5,16.3L75,6.7C67.6,2.5,59.1,0,50,0l0,0v11.1l0,0C57.1,11.1,63.7,13,69.5,16.3z" />
        </Ic>
    </RotatingContainer>
);

IC103Loading.displayName = 'IC103Loading';

IC103Loading.propTypes = {
    className: classNameType,
    color: colorType,
    display: propTypes.oneOf(['block', 'inline-block']), // there are many other values, but you probably shouldn't set them. update this if you get sick of the warnings though
    size: propTypes.oneOfType([propTypes.string, propTypes.number]),
};

export default IC103Loading;
