import React from 'react';
import bacon from 'baconjs';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';

import {isBrowser, isServer, removeLocalStorageValue} from '@fsa-streamotion/browser-utils';
import {
    accountsEnvs,
    decodeToken,
    getAllBrandsSubAccountStatus,
    POST_LOGIN_REDIRECT_STORAGE_KEY,
    POST_PROFILE_REDIRECT_STORAGE_KEY,
    CONTENT_ENTITLEMENT,
    getUser,
} from '@fsa-streamotion/streamotion-web-widgets-common';

import cleanAuth0NonceCookies from '../../common/clean-auth0-nonce-cookies';
import {isUrlToAnotherOrigin} from '../../../../todo-move-to-widgets-common/utils/is-url-to-another-origin';
import {LOGIN_TRACKING_SENT} from '../../../../todo-move-to-widgets-common/streams/login-tracking';
import validateBrand from '../../utils/validate-brand';
import getNormalisedBrand from '../../utils/get-normalised-brand';

import replaceLocation from '../../../../todo-move-to-widgets-common/utils/browser';
import StyledBaconWidget from '../../../../todo-move-to-widgets-common/utils/styled-bacon-widget';
import {STORAGE_KEY_PREFIX, MARKETING_STORAGE_KEYS} from '../../../../todo-move-to-widgets-common/utils/constants';
import {
    getEmailHash,
    initialiseAdobeDefinitionsConfig,
    trackFromAdobeDefinitions,
} from '../../../../todo-move-to-widgets-common/utils/adobe';

import RedirectComponent from '../../components/branded/redirect';

const {envsFromWidgetSettings} = accountsEnvs;

const PLAYBACK_DETAILS_STORAGE_KEY = 'ares-playback-details';
const HAS_ODDS_AND_WAGERING_LOCALSTORAGE_KEY = 'kayoOddsAndWagering';
const HAS_NO_SPOILERS_LOCALSTORAGE_KEY = 'kayoNoSpoilers';
const MAX_ADTECH_WAIT_MS = 5000; // @TODO: Update this solution after launch

class Redirect extends StyledBaconWidget {
    static widgetName = 'accw-redirect';
    component = RedirectComponent;

    constructor(settings, element) {
        super(settings, element);

        Object.assign(
            this.config,
            {
                commonWidgetSettings: {
                    brand: validateBrand(getNormalisedBrand(settings.brand)),
                    ...envsFromWidgetSettings(settings),
                },
                redirectUrl: trim(settings.redirectUrl, '/') || (isBrowser() && window.location.origin),
            },
        );
    }

    async trackEvent({
        auth0ID,
        MCID,
        resourcesEnv,
        userContentEntitlement,
        hashedEmail,
        bingeStatus,
        flashStatus,
        kayoStatus,
        lifestyleStatus,
    }) {
        await initialiseAdobeDefinitionsConfig({resourcesEnv});

        const signupStatus = ({
            [CONTENT_ENTITLEMENT.PREMIUM]: 'full-access',
            [CONTENT_ENTITLEMENT.FREEMIUM]: 'free-only',
            [CONTENT_ENTITLEMENT.NO_FREEMIUM_ALLOWED]: 'signup-registration',
        })[userContentEntitlement];

        await Promise.all([
            trackFromAdobeDefinitions({
                definitionsPath: 'eventTracking.login.manual',
                eventTypePayloadKey: 'data.event.name',
                templateValues: {
                    auth0ID,
                    MCID,
                    hashedEmail,
                    signupStatus,
                    bingeStatus,
                    flashStatus,
                    kayoStatus,
                    lifestyleStatus,
                },
            }),
            trackFromAdobeDefinitions({
                definitionsPath: 'visitorSync',
                eventType: 'syncvisitor',
                templateValues: {auth0ID},
            }),
        ]);

        if (window._satellite === undefined || window.AfxIdentity === undefined) {
            return; // if _satellite or AfxIdentity not defined resolve without waiting
        }

        window.sessionStorage.setItem(LOGIN_TRACKING_SENT, true);  // Set the session flag so we're not tracking any more login or auto-login events

        await new Promise((resolve) => {
        // redirect when finishedAdTechCalls event dispatched
            document.addEventListener('finishedAdTechCalls', resolve, {once: true});

            // redirect after 5 seconds without waiting for finishedAdTechCalls event
            setTimeout(() => {
                document.removeEventListener('finishedAdTechCalls', resolve, {once: true});

                resolve();
            }, MAX_ADTECH_WAIT_MS);
        });
    }

    getData() {
        const {redirectUrl, commonWidgetSettings: {platformEnv, resourcesEnv, brand}} = this.config;
        const userPromise = getUser({brand, platformEnv});

        const handleRedirect$ = isServer()
            ? bacon.later(0, {})
            : bacon.fromPromise(userPromise)
                .map(async (user) => {
                    const redirectLocalStorageKey = [POST_LOGIN_REDIRECT_STORAGE_KEY[brand], platformEnv].join('-');
                    const loginRedirectUrl = window.sessionStorage.getItem(redirectLocalStorageKey);

                    // Don't use the redirect from sessionStorage if it's to another origin or if it's falsey
                    // See https://owasp.org/www-project-cheat-sheets/cheatsheets/Unvalidated_Redirects_and_Forwards_Cheat_Sheet
                    const targetRedirectUrl = (!loginRedirectUrl || isUrlToAnotherOrigin(loginRedirectUrl))
                        ? redirectUrl
                        : loginRedirectUrl;
                    const redirectQueryParams = new URLSearchParams(window.location.search);

                    // Always remove local storage key for redirect url before navigating anywhere.
                    // We have the var captured in loginRedirectUrl.
                    window.sessionStorage.removeItem(redirectLocalStorageKey);

                    // Ensure a new mobile verification code is sent automatically after login
                    window.sessionStorage.removeItem('verificationCodeSent');

                    if (redirectQueryParams.has('code')) { // code means a auth0 login success
                        try {
                            const appState = await user.handleLogin();
                            const accessTokenBlob = await user.getAccessToken();
                            const accessTokenDetails = decodeToken(accessTokenBlob);

                            if (appState) { // logging this out in case it starts being populated. we can remove this logging once we understand the SDK a bit better
                                console.info({appState}); // eslint-disable-line no-console
                            }

                            cleanAuth0NonceCookies();

                            if (accessTokenDetails) { // Track only if they're logged in
                                const {
                                    bingeStatus,
                                    flashStatus,
                                    kayoStatus,
                                    lifestyleStatus,
                                } = getAllBrandsSubAccountStatus({accessTokenDetails});

                                const userDetails = await user.getUserDetails();
                                const hashedEmail = await getEmailHash(userDetails?.email);
                                const martianId = accessTokenDetails?.['http://foxsports.com.au/martian_id'];

                                initialiseAdobeDefinitionsConfig({resourcesEnv, brand});

                                await this.trackEvent({
                                    MCID: window._satellite?.getVisitorId?.()?.getMarketingCloudVisitorID?.(),
                                    auth0ID: accessTokenDetails?.sub,
                                    hashedEmail,
                                    resourcesEnv,
                                    userContentEntitlement: user.getContentEntitlement(),
                                    bingeStatus,
                                    flashStatus,
                                    kayoStatus,
                                    lifestyleStatus,
                                });

                                window.AfxIdentity?.reportData({martianId});
                            } else {
                                console.debug('[EVENT TRACKING SKIPPED FOR LOGIN-SUCCESS-MANUAL] accessTokenDetails is null', {accessTokenDetails}); // eslint-disable-line no-console
                            }

                            replaceLocation(targetRedirectUrl);
                        } catch (error) {
                            // Login not successful, something like Invalid state / missing params / auth errors
                            // Just log it here, and let the user go back to the application to initiate the flow again
                            console.error('Login failed with error', error.toString());

                            return {
                                header: 'There was an error processing this request',
                                message: error.toString(),
                                redirectUrl: targetRedirectUrl,
                            };
                        }
                    } else if (redirectQueryParams.has('error')) { // auth0 login error
                        return {
                            header: 'There was an error processing this request',
                            message: (
                                <React.Fragment>
                                    {redirectQueryParams.get('error')}<br />
                                    <em>{redirectQueryParams.get('error_description')}</em>
                                </React.Fragment>
                            ),
                            redirectUrl: targetRedirectUrl,
                        };
                    } else { // safe to assume it's logout
                        // HEY DEVELOPERS! Put all the local/session/cookie storage clean up logic here.
                        const redirectLocalStorageKey = [POST_PROFILE_REDIRECT_STORAGE_KEY[brand], platformEnv].join('-');

                        const isLogoutViaCheckout = window.sessionStorage.getItem('is-logout-via-checkout');

                        if (isLogoutViaCheckout) {
                            //  Coming from '/checkout' via the 'Sign In' button, we need to clear this temp storage
                            //  before going through to the login page
                            window.sessionStorage.removeItem('is-logout-via-checkout');
                            removeLocalStorageValue(`${STORAGE_KEY_PREFIX.shouldPreventFreebiesRedirection}-${platformEnv}`);
                        }

                        // Auth0 Nonce cookies (which may not be in use anymore, but leave for iframe fallbacks.)
                        // for kayo this was commented out, not sure why
                        cleanAuth0NonceCookies();

                        // Remove Playback Test details storage so it reruns on next page load.
                        // for flash this was commented out and kayo did not have this at all
                        if (brand === 'binge') {
                            removeLocalStorageValue(PLAYBACK_DETAILS_STORAGE_KEY);
                        }

                        // Remove spoilers and odds and wagering from storage
                        if (brand === 'kayo') {
                            removeLocalStorageValue(HAS_ODDS_AND_WAGERING_LOCALSTORAGE_KEY);
                            removeLocalStorageValue(HAS_NO_SPOILERS_LOCALSTORAGE_KEY);
                        }

                        // clean up local storage;
                        removeLocalStorageValue(MARKETING_STORAGE_KEYS[brand].campaign);
                        removeLocalStorageValue(MARKETING_STORAGE_KEYS[brand].channel);
                        removeLocalStorageValue(MARKETING_STORAGE_KEYS[brand].extcamp);
                        removeLocalStorageValue(MARKETING_STORAGE_KEYS[brand].referralId);

                        // These are env scoped
                        removeLocalStorageValue(`${STORAGE_KEY_PREFIX[brand].marketingPg}-${platformEnv}`);
                        removeLocalStorageValue(`${STORAGE_KEY_PREFIX[brand].offerName}-${platformEnv}`);
                        removeLocalStorageValue(`${STORAGE_KEY_PREFIX[brand].voucher}-${platformEnv}`);

                        sessionStorage.removeItem('signupTelstraJwt');
                        sessionStorage.removeItem('signupTelstraOrderItemNumber');
                        sessionStorage.removeItem('signupTelstraCarrierBillableOffer');

                        // clean up loginTrackingSent
                        window.sessionStorage.removeItem(LOGIN_TRACKING_SENT);

                        // Any redirect after profile selection
                        window.sessionStorage.removeItem(redirectLocalStorageKey);

                        // Push this navigation into the stack, let things clean up.
                        setTimeout(() => {
                            replaceLocation(targetRedirectUrl);
                        });
                    }
                })
                .flatMapLatest(bacon.fromPromise)
                .startWith({});

        return bacon.combineTemplate({
            props: {
                brand,
                header: handleRedirect$.map('.header'),
                message: handleRedirect$.map('.message'),
                redirectUrl: handleRedirect$.map('.redirectUrl'),
                isLoading: handleRedirect$.map(isEmpty),
            },

            hydration: {},
        });
    }
}

export default function RedirectWidget(settings = {}, element = null) {
    return new Redirect(settings, element);
}

Redirect.pageBoot();
