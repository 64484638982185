import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import IntersectionObserver from 'inteobs';
import noop from 'lodash/noop';
import {rgba} from 'polished';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {black, white, midnight, kayoGreen} from '../../../../common/palette';
import {SCREEN_LG_DESKTOP, SCREEN_PRETTY_LG_DESKTOP, SCREEN_TABLET} from '../../../../common/screen-sizes';
import BA32OfferFeature from '../../../atoms/ba/32-offer-feature';
import CAM03Finite from '../../../molecules/cam/03-finite';
import SrcsetSource from '../../../../common/srcset-source';

const StyledSection = styled.section`
    margin: 0;
    width: 100%;
    color: ${({isLightTheme}) => isLightTheme ? midnight : white};
`;

const StyledP = styled.p`
    margin: 42px auto;
    padding: 0 21px;
    max-width: 820px;
    text-align: center;
    font: var(--mui-body-copy-3);

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
        padding-bottom: 28px;
    }
`;

const FooterLink = styled.a`
    display: block;
    margin: 0 auto;
    max-width: 820px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    color: ${kayoGreen};
    font: var(--mui-body-copy-3);

    &:hover {
        text-decoration: underline;
    }
`;

const StyleImageSwitcher = styled.span`
    width: 100%;
    height: 100%;
`;

const CarouselContainer = styled.ol`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 0;
    margin: 0;
    padding: 0;
    height: 375px;
    list-style: none;

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        height: 400px;;
    `}

    ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
        height: 500px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_PRETTY_LG_DESKTOP})`
        height: 600px;
    `}
`;

const ImageListItem = styled.li`
    display: ${({isActive}) => isActive ? 'block' : 'none'};
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const StyledPicture = styled.picture`
    height: 100%;
`;

const ImageIcon = styled.img`
    width: 100%;
`;

const StyledImg = styled.img`
    transform: translateX(-50%);
    margin-left: 50%;
    height: 100%;
`;

const BottomContainer = styled.div`
    background: ${({isLightTheme}) => isLightTheme ? white : midnight};
    padding-bottom: 70px;
`;

const TopContainer = styled.div`
    background-color: ${midnight};
`;

const Title = styled.h2`
    margin: 0;
    padding: 50px 0;
    text-align: center;
    text-shadow: 0 1px 1px ${rgba(black, 0.3)};
    color: ${white};
    font: var(--mui-header-4);
`;

const FooterBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 45px;
    text-align: center;
`;

const FooterBlockTitle = styled.div`
    border-top: 1px solid ${({isLightTheme}) => rgba(isLightTheme ? midnight : white, 0.2)};
    padding-top: 14px;
    width: 90%;
    max-width: 375px;
    font: var(--mui-body-copy-4-bold);
`;

const FooterBlockList = styled.ul`
    display: flex;
    flex-direction: column;
    margin: 21px 0 0;
    padding: 0;
    list-style: none;

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        flex-direction: row;
    `}
`;

const FooterBlockItem = styled.li`
    padding: 0;

    &:not(:last-of-type) {
        margin-bottom: 15px;

        ${mediaQuery({minWidthPx: SCREEN_TABLET})`
            margin-bottom: 0;
            margin-right: 28px;
        `}
    }
`;

const FooterBlockImage = styled.img`
    height: 52px;
`;

export default class OR120PictureCar extends React.Component {
    static displayName = 'OR120PictureCar';

    static propTypes = {
        /** URL that the footer text links to */
        footerLink: propTypes.string,
        /** Label for the footer link */
        footerText: propTypes.string,
        /** Additional block below footer text */
        footerBlock: propTypes.shape({
            title: propTypes.string,
            itemList: propTypes.arrayOf(propTypes.shape({
                link: propTypes.string,
                icon: propTypes.string,
                iconTitle: propTypes.string,
            })),
        }),
        /** Text above the CarouselContainer (optional) */
        title: propTypes.string,
        /** Is the organism going to be light themed or not */
        isLightTheme: propTypes.bool,
        /** List of buttons and corresponding images + descriptions to display */
        itemList: propTypes.arrayOf(
            propTypes.shape({
                description: propTypes.arrayOf(propTypes.string),
                icon: propTypes.node,
                iconUrl: propTypes.string,
                imageDesktop: propTypes.string,
                imageMobile: propTypes.string,
            })
        ),
        /** Callback for every time an icon button is clicked */
        onClickItem: propTypes.func,
    };

    static defaultProps = {
        itemList: [],
        onClickItem: noop,
    };

    state = {
        activeIndex: 0,
        canLoadAllImages: false,
    };

    componentDidMount() {
        this.observer = new IntersectionObserver(([topSentinel]) => { // eslint-disable-line compat/compat
            if (topSentinel.isIntersecting) {
                this.disconnectObserver();
                this.setState({canLoadAllImages: true});
            }
        });

        this.observer.observe(this.componentRef.current);
    }

    componentWillUnmount() {
        this.disconnectObserver();
    }

    disconnectObserver = () => void this.observer.disconnect();

    componentRef = React.createRef();

    render() {
        const {
            isLightTheme,
            onClickItem,
            itemList = [],
            footerText,
            footerLink,
            footerBlock,
            title,
        } = this.props;

        const {
            canLoadAllImages,
            activeIndex,
        } = this.state;

        const {description} = itemList[activeIndex] || {};

        return (
            <StyledSection
                className="OR120PictureCar"
                isLightTheme={isLightTheme}
                aria-live="polite"
                ref={this.componentRef}
            >
                <TopContainer>
                    {!!title && <Title>{title}</Title>}
                    <CarouselContainer>
                        {
                            itemList
                                .map(({srcsetPortrait, srcsetLandscape, fallbackImgSrc}, index) => (
                                    <ImageListItem
                                        isActive={index === activeIndex}
                                        key={index}
                                    >
                                        {(index === 0 || canLoadAllImages) ? (
                                            <StyledPicture>
                                                <SrcsetSource srcsetOptions={srcsetLandscape} minWidthPx={SCREEN_TABLET} />
                                                {!!srcsetPortrait && (
                                                    <SrcsetSource srcsetOptions={srcsetPortrait} />
                                                )}
                                                <StyledImg src={fallbackImgSrc} alt="" />
                                            </StyledPicture>
                                        ) : (
                                            <StyleImageSwitcher />
                                        )}
                                    </ImageListItem>
                                ))
                        }
                    </CarouselContainer>
                </TopContainer>

                <BottomContainer isLightTheme={isLightTheme}>
                    <CAM03Finite
                        isCentered={true}
                        itemGapPx={20}
                        isLightTheme={isLightTheme}
                    >
                        {itemList
                            .map(({title, icon, iconUrl}, index) => (
                                <BA32OfferFeature
                                    isPressed={activeIndex === index}
                                    isLightTheme={isLightTheme}
                                    key={index}
                                    title={title}
                                    onClick={() => {
                                        this.setState(() => ({
                                            activeIndex: index,
                                            canLoadAllImages: true,
                                        }));
                                        onClickItem(title);
                                    }}
                                >
                                    {iconUrl
                                        ? <ImageIcon src={iconUrl} />
                                        : icon}
                                </BA32OfferFeature>
                            ))}
                    </CAM03Finite>

                    {description
                        .filter(Boolean)
                        .map((text, index) => <StyledP key={index}>{text}</StyledP>)
                    }

                    {!!footerText && (
                        <FooterLink href={footerLink} isLightTheme={isLightTheme}>
                            {footerText}
                        </FooterLink>
                    )}

                    {!!footerBlock && (
                        <FooterBlock>
                            {footerBlock.title && <FooterBlockTitle isLightTheme={isLightTheme}>{footerBlock.title}</FooterBlockTitle>}
                            {(Array.isArray(footerBlock.itemList) && footerBlock.itemList.length) && (
                                <FooterBlockList>
                                    {footerBlock.itemList.map(({link, icon, iconTitle}) => (
                                        <FooterBlockItem key={`${icon}___${iconTitle}`}>
                                            <a href={link}>
                                                <FooterBlockImage src={icon} alt={iconTitle || ''} />
                                            </a>
                                        </FooterBlockItem>
                                    ))}
                                </FooterBlockList>
                            )}
                        </FooterBlock>
                    )}
                </BottomContainer>
            </StyledSection>
        );
    }
}
