import React from 'react';
import Ic from '..';

const IC105Home = (args) => (
    <Ic {...args}>
        <g transform="scale(3.44 3.44)">
            <path d="M14.358 4.11l9.066 7.093v11.89c0 .768-.465 1.284-1.183 1.284l-4.684-.001v-7.733h-6.132v7.733h-4.9c-.667.001-1.234-.572-1.234-1.281V11.204l9.067-7.094z" />
        </g>
    </Ic>
);

IC105Home.displayName = 'IC105Home';

export default IC105Home;
