import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import classnames from 'classnames';
import get from 'lodash/get';
import noop from 'lodash/noop';

import {mediaQuery, stylesWhen, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {fadeInOut, scaleInOut, transition} from '../../../../common/animations';
import CommonTransition from '../../../../common/common-transition';
import {flash, blueCharcoal, fog} from '../../../../common/palette';
import {SCREEN_768_TABLET, SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import {Button, Section} from '../../../../common/normalized-styled-components';

import BA01BtnPr from '../../../atoms/ba/01-btn-pr';
import BA28BtnSec from '../../../atoms/ba/28-btn-sec';
import IA05InputHelp from '../../../atoms/ia/05-input-help';
import IM05InputPre from '../../../molecules/im/05-input-pre';
import IM04Code from '../../../molecules/im/04-code';
import IC103Loading from '../../../atoms/ic/103-loading';
import {IC32Success, IC42Error, IC43Valid, IC135Cross} from '../../../atoms/ic';

const StyledSection = styled(Section)`
    position: relative;
    text-align: center;
`;

const VerificationContainer = styled.div`
    display: grid;
    grid-row-gap: 29px;
    justify-items: center;
    transition: ${transition('opacity')};

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        grid-template-columns: repeat(2, minmax(384px, 1fr));
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        grid-template-columns: repeat(2, minmax(450px, 1fr));
        grid-column-gap: 80px;
    `}

    ${fadeInOut}

    &[disabled] {
        opacity: 0.35;
    }
`;

const VerificationInputContainer = styled.div`
    display: grid;
    grid-row-gap: 7px;
    justify-items: center;
`;

const EditContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    ${stylesWhenNot('isNewMobileNumber')`
        position: absolute;
        top: 0;
    `}

    ${scaleInOut}
`;

const EditMobileNumberEditor = styled.form`
    box-sizing: border-box;
    display: grid;
    position: relative;
    flex-basis: 600px;
    grid-row-gap: 28px;
    justify-items: center;
    margin: 7px;
    border-radius: 3px;
    box-shadow: 0 0 21px ${blueCharcoal};
    background: ${rgba(blueCharcoal, 0.85)};
    padding: 28px 49px;
`;

const EditMobileNumberHeading = styled.h4`
    margin: 0;
    padding-bottom: 7px;
    text-align: center;
    color: ${fog};
    font: var(--infinity-header-5);
`;

const EditMobileNumberSubheading = styled.p`
    margin: 0;
    text-align: center;
    color: ${fog};
    font: var(--infinity-body-copy-5);
`;

const CloseButton = styled(Button).attrs({
    'type': 'button',
    'aria-label': 'Close Message',
})`
    appearance: none;
    box-sizing: content-box;
    position: absolute;
    top: 0;
    right: 0;
    transition: ${transition('color')};
    border: 0;
    background: transparent;
    padding: 14px;
    width: 20px;
    color: ${rgba(fog, 0.42)};

    &:hover,
    &:focus {
        outline: 0;
        color: ${rgba(fog, 0.84)};
    }
`;

const InfoBox = styled.div`
    margin: 14px 0;
    max-width: 333px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        max-width: 450px;
    `}
`;

const MainInfoMessage = styled.p`
    margin: 0;
    color: ${fog};
    font: var(--infinity-header-7);
    font-weight: normal;
`;

const MobileNumber = styled.span`
    white-space: nowrap;
`;

const ContextHelpContainer = styled.div`
    margin: 0 auto;
    width: 100%;
    max-width: 320px;
`;

const InputContainer = styled.div`
    display: inline-block;
    position: relative;
`;

const IconPositioner = styled.span`
    display: grid;
    align-self: center;
`;

const SmallHelpText = styled.p`
    margin: 0;
    color: ${fog};
    font: var(--infinity-header-7);
    font-weight: normal;
`;

const ButtonsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    justify-items: center;

    ${stylesWhen('hasError')`
        grid-column-gap: 20px;
    `}
`;

const Options = styled.div`
    display: grid;
    grid-template-areas: 'top' 'bottom';
    grid-template-columns: 250px;
    grid-row-gap: 7px;
    justify-content: start;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        grid-template-columns: 400px;
    `}
`;

const TopOption = styled.div`
    grid-area: top;
    align-self: end;
`;

const BottomOption = styled.div`
    grid-area: bottom;
    align-self: start;
`;

const ButtonIconBox = styled.div`
    width: 30px;
    height: 30px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: 35px;
        height: 35px;
    `}
`;

const SuccessIconBox = styled.div`
    width: 40px;
    height: 40px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: 50px;
        height: 50px;
    `}
`;

const StyledIC103Loading = styled(IC103Loading)`
    width: 30px;
    height: 30px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: 35px;
        height: 35px;
    `}
`;

const StyledIC43Valid = styled(IC43Valid)`
    display: inline-block;
    margin-bottom: -3px;
    margin-left: 10px;
`;

export default class OR53Veri extends React.Component {
    static displayName = 'OR53Veri';

    static propTypes = {
        /** Optional CSS class(es) */
        className: classNameType,
        /** An optional error message to denote an incorrect verification code */
        errorMessage: propTypes.node,
        /** If the resend code button is in a loading state */
        isResendLoading: propTypes.bool,
        /** If the verification was successful */
        isSuccessful: propTypes.bool,
        /** If a verification request is loading */
        isVerificationLoading: propTypes.bool,
        /** The help text to render beneath the main info message */
        lowerHelpText: propTypes.string,
        /** The length of a valid verification code */
        numberOfDigits: propTypes.number,
        /** Verification code (if controlling from the outside) */
        verificationCode: propTypes.string,
        /** Complete callback, called when the user has filled the whole verification code */
        onComplete: propTypes.func,
        /** Callback when user clicks resend */
        onResendClick: propTypes.func,
        /** The mobile number to display for confirmation and prepopulating the mobile number editor */
        mobileNumber: propTypes.string,
        /** Mobile number edit button was clicked */
        onClickEditMobileNumber: propTypes.func,
        /** Are we editing the mobile number? */
        isEditingMobileNumber: propTypes.bool,
        /** Handle cancellation of editing the mobile number */
        onCancelEditMobileNumber: propTypes.func,
        /** Mobile number submit handler */
        onSubmitMobileNumber: propTypes.func,
        /** Is the updated mobile number being submitted? */
        isSubmittingMobileNumber: propTypes.bool,
        /** Mobile */
        mobileNumberError: propTypes.node,
        /** Has the mobile number been successfully submitted? */
        hasSubmittedMobileNumber: propTypes.bool,
        /** Validate and format a change to the mobile number in the editor: (oldValue, newValue) => result */
        processMobileNumberInput: propTypes.func,
        /** Validate a mobile number for completeness: (mobileNumber) => true/false */
        validateMobileNumber: propTypes.func,
    };

    static defaultProps = {
        numberOfDigits: 6,
        onSubmitMobileNumber: noop,
        onCancelEditMobileNumber: noop,
        processMobileNumberInput: (oldMobileNumber, newMobileNumber) => newMobileNumber,
        validateMobileNumber: () => true,
    };

    constructor(props) {
        super(props);

        const mobileNumber = props.processMobileNumberInput('', props.mobileNumber);
        const isMobileNumberValid = props.validateMobileNumber(mobileNumber);

        this.state = {
            mobileNumber,
            isMobileNumberValid,
        };
    }

    static getDerivedStateFromProps(props, state) {
        const newState = {
            isEditingMobileNumber: props.isEditingMobileNumber, // track this for subsequent comparisons
        };

        if (props.isEditingMobileNumber && !state.isEditingMobileNumber) {
            const mobileNumber = props.processMobileNumberInput('', props.mobileNumber);
            const isMobileNumberValid = props.validateMobileNumber(mobileNumber);

            Object.assign(newState, {mobileNumber, isMobileNumberValid});
        }

        return newState;
    }

    componentDidUpdate(prevProps) {
        if (this.props.isEditingMobileNumber && !prevProps.isEditingMobileNumber) {
            // timeout prevents edge from stalling when we select the input before it's ready
            setTimeout(() => this.mobileNumberInputRef.current.select());
            this.startWaitingForCloseEvent();
        } else if (prevProps.isEditingMobileNumber && !this.props.isEditingMobileNumber) {
            this.stopWaitingForCloseEvent();
        }
    }

    mobileNumberEditorRef = React.createRef();
    mobileNumberInputRef = React.createRef();

    startWaitingForCloseEvent() {
        document.addEventListener('click', this.handleClick);
        document.addEventListener('keydown', this.handleKeyDown);
    }

    stopWaitingForCloseEvent() {
        document.removeEventListener('keydown', this.handleKeyDown);
        document.removeEventListener('click', this.handleClick);
    }

    handleClick = (event) => {
        // Cancel editing if we click outside
        if (this.props.isEditingMobileNumber && !this.mobileNumberEditorRef.current.contains(event.target)) {
            this.props.onCancelEditMobileNumber();
        }
    };

    handleKeyDown = (event) => {
        // Cancel editing if we press escape
        if (this.props.isEditingMobileNumber && event.key === 'Escape') {
            this.props.onCancelEditMobileNumber();
        }
    };

    onClickCloseButton = () => {
        this.props.onCancelEditMobileNumber();
    };

    onChangeMobileNumber = ({target: {value}}) => {
        const mobileNumber = this.props.processMobileNumberInput(this.state.mobileNumber, value); // eslint-disable-line react/no-access-state-in-setstate
        const isMobileNumberValid = this.props.validateMobileNumber(mobileNumber);

        this.setState({
            mobileNumber,
            isMobileNumberValid,
        });
    };

    onSubmit = (event) => {
        event.preventDefault();
        this.props.onSubmitMobileNumber(get(event, 'target.elements.mobileNumber.value'));
    };

    render() {
        const {
            errorMessage,
            isResendLoading,
            isVerificationLoading,
            isSuccessful,
            lowerHelpText,
            numberOfDigits,
            verificationCode,
            onComplete,
            onResendClick,
            onClickEditMobileNumber,
            isEditingMobileNumber,
            isSubmittingMobileNumber,
            mobileNumberError,
            hasSubmittedMobileNumber,
            className,
            // omit these from htmlAttributes
            onCancelEditMobileNumber, // eslint-disable-line no-unused-vars
            onSubmitMobileNumber, // eslint-disable-line no-unused-vars
            ...htmlAttributes
        } = this.props;

        const {isMobileNumberValid} = this.state;

        return (
            <StyledSection
                {...htmlAttributes}
                disabled={isEditingMobileNumber}
                className={classnames('OR53Veri', className)}
            >
                <CommonTransition in={!!this.props.mobileNumber}>
                    <VerificationContainer disabled={isEditingMobileNumber}>
                        <VerificationInputContainer>
                            <InfoBox>
                                <MainInfoMessage>
                                    Please enter the code we sent to your mobile, <MobileNumber>{this.props.mobileNumber}</MobileNumber>
                                </MainInfoMessage>
                            </InfoBox>

                            <InputContainer>
                                <IM04Code
                                    numberOfDigits={numberOfDigits}
                                    value={verificationCode}
                                    onComplete={onComplete}
                                    disabled={isResendLoading || isVerificationLoading}
                                />
                            </InputContainer>

                            {!!errorMessage && !isVerificationLoading && (
                                <ContextHelpContainer>
                                    <IA05InputHelp>
                                        {errorMessage}
                                    </IA05InputHelp>
                                </ContextHelpContainer>
                            )}

                            {!!lowerHelpText && (
                                <InfoBox>
                                    <SmallHelpText>
                                        {lowerHelpText}
                                    </SmallHelpText>
                                </InfoBox>
                            )}
                        </VerificationInputContainer>

                        {isSuccessful || isVerificationLoading ? (
                            <IconPositioner>
                                {
                                    /* eslint-disable no-nested-ternary */
                                    isSuccessful ? (
                                        <SmallHelpText>
                                            Mobile Verified
                                            <StyledIC43Valid size="22px" color={flash} />
                                        </SmallHelpText>
                                    )
                                        : isVerificationLoading
                                            ? <IC103Loading size="22px" />
                                            : null
                                    /* eslint-enable no-nested-ternary */
                                }
                            </IconPositioner>
                        ) : (
                            <ButtonsContainer hasError={errorMessage}>
                                {!!errorMessage && <IC42Error size="22px" color={fog} />}
                                <Options>
                                    <TopOption>
                                        <BA28BtnSec
                                            isBlock={true}
                                            onClick={onResendClick}
                                            disabled={isResendLoading || isVerificationLoading}
                                        >
                                            {isResendLoading ? (
                                                <ButtonIconBox>
                                                    <StyledIC103Loading />
                                                </ButtonIconBox>
                                            ) : 'Resend Code'}
                                        </BA28BtnSec>
                                    </TopOption>

                                    <BottomOption>
                                        <BA28BtnSec
                                            isBlock={true}
                                            onClick={onClickEditMobileNumber}
                                            disabled={isResendLoading || isVerificationLoading}
                                        >
                                            Update Mobile
                                        </BA28BtnSec>
                                    </BottomOption>
                                </Options>
                            </ButtonsContainer>
                        )}
                    </VerificationContainer>
                </CommonTransition>

                <CommonTransition in={isEditingMobileNumber}>
                    <EditContainer isNewMobileNumber={!this.props.mobileNumber}>
                        <EditMobileNumberEditor
                            ref={this.mobileNumberEditorRef}
                            onSubmit={this.onSubmit}
                        >
                            {this.props.mobileNumber ? (
                                <CloseButton
                                    disabled={isSubmittingMobileNumber}
                                    onClick={this.onClickCloseButton}
                                >
                                    <IC135Cross />
                                </CloseButton>
                            ) : (
                                <div>
                                    <EditMobileNumberHeading>
                                        Please enter your mobile number
                                    </EditMobileNumberHeading>
                                    <EditMobileNumberSubheading>
                                        We're going to send a verification code to your phone
                                    </EditMobileNumberSubheading>
                                </div>
                            )}

                            <IM05InputPre
                                ref={this.mobileNumberInputRef}
                                type="tel"
                                label="Mobile number"
                                name="mobileNumber"
                                value={this.state.mobileNumber}
                                onChange={this.onChangeMobileNumber}
                                contextualHelp={mobileNumberError}
                                icon={mobileNumberError ? <IC42Error color={fog} /> : undefined}
                                disabled={isSubmittingMobileNumber || hasSubmittedMobileNumber}
                            />

                            {hasSubmittedMobileNumber ? (
                                <SuccessIconBox>
                                    <IC32Success color={flash} />
                                </SuccessIconBox>
                            ) : (
                                <BA01BtnPr
                                    type="submit"
                                    onClick={() => { /* eslint-disable-line no-empty-function */
                                        /*
                                            This is because noop not considered onClick, and with no onClick we're a div and not a button...
                                        */
                                    }}
                                    disabled={isSubmittingMobileNumber || !isMobileNumberValid}
                                >
                                    {isSubmittingMobileNumber ? ( // eslint-disable-line no-nested-ternary
                                        <ButtonIconBox>
                                            <StyledIC103Loading />
                                        </ButtonIconBox>
                                    ) : this.props.mobileNumber ? (
                                        'Update'
                                    ) : (
                                        'Submit'
                                    )}
                                </BA01BtnPr>
                            )}
                        </EditMobileNumberEditor>
                    </EditContainer>
                </CommonTransition>
            </StyledSection>
        );
    }
}
