import React from 'react';
import Ic from '..';

const IC03Not = (args) => (
    <Ic {...args}>
        <path d="M42.837 20.48c8.693-1.046 18.092 5.147 18.963 12.554l1.384 11.451.207 1.744.9 1.52 10.036 16.975-51.645 6.234 5.758-18.913.554-1.688-.208-1.757L27.5 37.804c-.858-7.281 6.423-15.817 14.714-17.212l.623-.112m-.941-8.299l-1.094.154c-12.167 2.064-23.047 14.143-21.58 26.501L20.51 49.66 11 80.806l76.962-9.304-16.5-28.077-1.384-11.437C68.597 19.337 54.603 10.605 41.896 12.18zm-3.323 70.954a13.588 13.588 0 0012.43 5.282c4.777-.572 8.902-3.64 10.853-8.072l-23.283 2.79z" />
    </Ic>
);

IC03Not.displayName = 'IC03Not';

export default IC03Not;
