import querystring from 'querystring';

/**
 * The URL class (e.g. `new URL(someUrl)`) is super cool, but a bit verbose for our use cases
 *
 * Here's a nicer way to add search params to a URL and get returned a new URL object
 * It doesn't work with relative links ('./something'|'/something')
 *
 * @param {string} url URL string to modify
 * @param {Object|string} searchParams `Object` or `string` representation of search params (will use `URLSearchParams` for parsing)
 *
 * @returns {URL} a new `URL` object with params set
 *
 * @example
 * // using query string
 * addParamsToUrl('https://kayosports.com.au?foo=bar', 'hungry=lunch&walk=true');
 * // returns https://kayosports.com.au?foo=bar&hungry=lunch&walk=true
 *
 * @example
 * // using query params object will stringify objects
 * addParamsToUrl('https://kayosports.com.au?foo=bar', {hungry: 'lunch', walk: true});
 * // returns https://kayosports.com.au?foo=bar&hungry=lunch&walk=true
 *
 * @example
 * // using query params object will strip out undefined values
 * addParamsToUrl('https://kayosports.com.au?foo=bar', {hungry: 'lunch', walk: undefined});
 * // returns https://kayosports.com.au?foo=bar&hungry=lunch
 */
export default function addParamsToUrl(url, searchParams) {
    if (!url) {
        return;
    }

    const newUrlObj = new URL(url);

    if (!searchParams) {
        return newUrlObj;
    }

    const newParams = typeof searchParams === 'string'
        ? [...new URLSearchParams(searchParams).entries()]
        : Object.entries(searchParams).filter(([, value]) => value !== undefined);

    newParams.forEach(([key, value]) => {
        newUrlObj.searchParams.set(key, value);
    });

    return newUrlObj;
}

export function prependQueryString({
    parameters = {},
    url = '',
}) {
    if (!Object.keys(parameters).length) {
        return url;
    }

    const [path = '', queryString = ''] = url.split('?');

    const urlWithQueryString = `${path}?${querystring.stringify(parameters)}`;

    return queryString ? `${urlWithQueryString}&${queryString}` : urlWithQueryString;
}
