import React from 'react';
import Ic from '..';

const IC49Open = (args) => (
    <Ic {...args}>
        <path d="M89.4 46h-35V11H46v35H11v8.4h35v35h8.4v-35h35z" />
    </Ic>
);

IC49Open.displayName = 'IC49Open';

export default IC49Open;
