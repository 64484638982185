import React, {useEffect} from 'react';
import propTypes from 'prop-types';
import get from 'lodash/get';
import noop from 'lodash/noop';
import kebabCase from 'lodash/kebabCase';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {BA04ChnlTil} from '@fsa-streamotion/streamotion-web-infinity';
import {getMediaQueries, palette, screenSizes, CONTENT_EDGE_SPACING_PERCENT} from '@fsa-streamotion/streamotion-web-ionic';
import {getResourcesUrlBasePath} from '@fsa-streamotion/streamotion-web-widgets-common';

import {
    BrandedBA43CatTile,
    BrandedCAM01Stand,
    BrandedCAM03Finite,
    BrandedGA07TileImg,
    BrandedTM14ShowTil,
} from '../../../utils/branded-components';

import {stylesWhenBinge, stylesWhenFlash} from '../../../utils/styles-when-brand';

import {templateUrlToSrcsetOptions} from '../../../../../todo-move-to-widgets-common/utils/srcset-helpers';
import {VMNDIMS_IMWIDTHS} from '../../../../../todo-move-to-widgets-common/utils/imwidth';
import LoadingIndicator from './loader';

const {white} = palette;

const {SCREEN_375_PHABLET, SCREEN_768_TABLET, SCREEN_1024_DESKTOP, SCREEN_1280_DESKTOP} = screenSizes; // @todo Are these consistent across the component libraries?

const BREAKPOINT_PAGE_SIZES = {
    [SCREEN_375_PHABLET]: 3,
    [SCREEN_768_TABLET]: 5,
    [SCREEN_1024_DESKTOP]: 6,
    [SCREEN_1280_DESKTOP]: 5,
};

// Keep the number of image widths to just what we need so we can avoid unneccessary markup
const minSizePx = 225;
const maxSizePx = 1024;
const minSizePortraitPx = 150;
const maxSizePortraitPx = 320;
const maxPixelDensity = 3;

// Including 150 here so that our smallest supported phones get really small images
const VMNDIMS_IMWIDTHS_EXTENDED = [150, ...VMNDIMS_IMWIDTHS];

const IMWIDTHS = VMNDIMS_IMWIDTHS.filter((sizePx) => (
    sizePx >= minSizePx
    && sizePx <= (maxSizePx * maxPixelDensity)
));

const IMWIDTHS_PORTRAIT = VMNDIMS_IMWIDTHS_EXTENDED.filter((sizePx) => (
    sizePx >= minSizePortraitPx
    && sizePx <= (maxSizePortraitPx * maxPixelDensity)
));

const DEFAULT_SRCSET_SIZE = '30vw';

const SRCSET_SIZES = Object.entries(BREAKPOINT_PAGE_SIZES)
    .map(([breakpoint, numVisibleTiles]) => `(min-width: ${breakpoint}px) ${(100 - (CONTENT_EDGE_SPACING_PERCENT * 2)) / numVisibleTiles}vw`)
    .concat(DEFAULT_SRCSET_SIZE)
    .join(',');

const MAX_SLIDES_PER_PAGE = Math.max(...Object.values(BREAKPOINT_PAGE_SIZES));

const SELECTOR_TILE_SIZE_ABOVE_0 = 80;
const SELECTOR_TILE_SIZE_ABOVE_1280 = 100;
const SELECTOR_TILE_IMAGE_SIZES = VMNDIMS_IMWIDTHS.filter((size) => size > SELECTOR_TILE_SIZE_ABOVE_1280 * 3);

const PanelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const StyledCAM03Finite = styled(BrandedCAM03Finite)`
    flex-shrink: 0;
    margin-top: 28px;
    width: 100%;

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        margin-top: 49px;
    `}
`;

const StyledBA04ChnlTil = styled(BA04ChnlTil)`
    img {
        width: ${SELECTOR_TILE_SIZE_ABOVE_0}px;
        height: ${SELECTOR_TILE_SIZE_ABOVE_0}px;

        ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
            width: ${SELECTOR_TILE_SIZE_ABOVE_1280}px;
            height: ${SELECTOR_TILE_SIZE_ABOVE_1280}px;
        `}
    }
`;

const StyledCAM01Stand = styled(BrandedCAM01Stand)`
    padding-top: 9px;
`;

const StyledTM14ShowTil = styled(BrandedTM14ShowTil)`
    outline: 0;

    & + & {
        margin-top: 20px;
    }
`;

const ExtendedTitle = styled.p`
    margin-top: 28px;
    text-align: center;
    color: ${white};

    ${stylesWhenBinge`
        font: var(--ionic-body-copy-2);

        ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
            margin-top: 42px;
        `}
    `}

    ${stylesWhenFlash`
        font: var(--infinity-body-copy-2);

        ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
            margin-top: 24px;
        `}
    `}
`;

function getImagePath({brand, resourcesEnv, title, isSelected}) {
    return `${getResourcesUrlBasePath({brand, resourcesEnv})}/marketing/images/content-block/${kebabCase(title)}${isSelected ? '-selected' : ''}.png?`
        + 'imwidth=${WIDTH}'; // eslint-disable-line no-template-curly-in-string
}

const TabbedCarousel = ({
    allPanelsData = {},
    extendedTabTitle,
    massagedDoubleCarouselContent = {},
    onMaxVisibleTilesPerRowUpdate = noop,
    onSelectItem = noop,
    panelDataContents = [],
    tabbedCarouselKey,
    brand,
    resourcesEnv,
}) => {
    const {data, firstRow = [], secondRow = []} = massagedDoubleCarouselContent;

    useEffect(function updateMaxVisibleTilesPerRow(maxVisibleTilesPerRow) {
        const mediaQueries = getMediaQueries(Object.keys(BREAKPOINT_PAGE_SIZES));
        const [, currentQuery] = mediaQueries.find(([, query]) => window.matchMedia(query).matches);
        const mediaQueryList = window.matchMedia(currentQuery);

        const onMatchMedia = () => {
            const [currentBreakpoint] = mediaQueries.find(([, query]) => window.matchMedia(query).matches);
            const maxVisibleTilesPerRow = BREAKPOINT_PAGE_SIZES[currentBreakpoint];

            onMaxVisibleTilesPerRowUpdate(maxVisibleTilesPerRow);

            mediaQueryList.removeListener(onMatchMedia);
        };

        if (!maxVisibleTilesPerRow) {
            onMatchMedia();
        }

        mediaQueryList.addListener(onMatchMedia);
    });

    return (
        <PanelWrapper key={tabbedCarouselKey}>
            <StyledCAM03Finite
                brand={brand}
                initialFocusIndex={extendedTabTitle.index}
                isCentered={true}
            >
                {get(allPanelsData, 'panels', []).map(({description, title, links = {}}, index) => (
                    brand === 'flash'
                        ? (
                            <StyledBA04ChnlTil
                                ariaLabel={title}
                                key={title}
                                isPressed={index === extendedTabTitle.index}
                                onClick={(event) => onSelectItem({value: links.panels, description, title, index, event})}
                                tileImageSrcset={templateUrlToSrcsetOptions({
                                    templateUrl: getImagePath({brand, resourcesEnv, title, isSelected: false}),
                                    sizes: SELECTOR_TILE_IMAGE_SIZES,
                                })}
                                focusedTileImageSrcset={templateUrlToSrcsetOptions({
                                    templateUrl: getImagePath({brand, resourcesEnv, title, isSelected: true}),
                                    sizes: SELECTOR_TILE_IMAGE_SIZES,
                                })}
                            />
                        )
                        : (
                            <BrandedBA43CatTile
                                brand={brand}
                                key={title}
                                onClick={(event) => onSelectItem({value: links.panels, description, title, index, event})}
                                isPressed={index === extendedTabTitle.index}
                                value={links.panels}
                            >
                                {title}
                            </BrandedBA43CatTile>
                        )
                ))}
            </StyledCAM03Finite>
            {!!extendedTabTitle.title && (
                <ExtendedTitle brand={brand}>{extendedTabTitle.title}</ExtendedTitle>
            )}
            <StyledCAM01Stand
                brand={brand}
                hasHints={false}
                lazyLoadImages={true}
                maxSlidesPerPage={MAX_SLIDES_PER_PAGE}
            >
                {isEmpty(panelDataContents)
                    ? Array.from(
                        {length: MAX_SLIDES_PER_PAGE + 1},
                        (_, index) => <LoadingIndicator key={`loader__${index}`} brand={brand} resourcesEnv={resourcesEnv} />
                    )
                    : firstRow.map(({image, imagePortrait, title}, index) => (
                        <React.Fragment
                            key={`${index}-${title}`}
                        >
                            <StyledTM14ShowTil
                                brand={brand}
                                tabIndex="0"
                                {...data}
                                card={(
                                    <BrandedGA07TileImg
                                        brand={brand}
                                        caption={title}
                                        srcsetOptions={templateUrlToSrcsetOptions({templateUrl: image, sizes: IMWIDTHS})}
                                        srcsetOptionsPortrait={
                                            templateUrlToSrcsetOptions({templateUrl: imagePortrait, sizes: IMWIDTHS_PORTRAIT})
                                        }
                                        srcsetSizes={SRCSET_SIZES}
                                    />
                                )}
                            />
                            {!!secondRow[index] && (
                                <StyledTM14ShowTil
                                    brand={brand}
                                    tabIndex="0"
                                    {...data}
                                    card={(
                                        <BrandedGA07TileImg
                                            brand={brand}
                                            caption={secondRow[index].title}
                                            srcsetOptions={
                                                templateUrlToSrcsetOptions(
                                                    {templateUrl: secondRow[index].image, sizes: IMWIDTHS}
                                                )
                                            }
                                            srcsetOptionsPortrait={
                                                templateUrlToSrcsetOptions(
                                                    {templateUrl: secondRow[index].imagePortrait, sizes: IMWIDTHS_PORTRAIT}
                                                )
                                            }
                                            srcsetSizes={SRCSET_SIZES}
                                        />
                                    )}
                                />
                            )}
                        </React.Fragment>
                    ))
                }
            </StyledCAM01Stand>
        </PanelWrapper>
    );
};

TabbedCarousel.displayName = 'TabbedCarousel';

TabbedCarousel.propTypes = {
    /** Data for all the tabs and their panels */
    allPanelsData: propTypes.object,

    /** Contains the extended tab title and it's index in the list of tabs */
    extendedTabTitle: propTypes.object,

    /** Images arranged into two rows */
    massagedDoubleCarouselContent: propTypes.object,

    /** Sends the max visible tiles per row */
    onMaxVisibleTilesPerRowUpdate: propTypes.func,

    /** Handler for tab selection */
    onSelectItem: propTypes.func,

    /** Array of tiles */
    panelDataContents: propTypes.arrayOf(propTypes.object),

    /** Key */
    tabbedCarouselKey: propTypes.string,

    /** Resources environment for loading images */
    resourcesEnv: propTypes.string,

    /** The brand like Kayo, Binge */
    brand: propTypes.string,
};

export default TabbedCarousel;
