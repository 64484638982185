import React, {cloneElement} from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import property from 'lodash/property';
import styled from 'styled-components';
import {rgba} from 'polished';

import {mediaQuery, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {colorType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {Section} from '../../../../common/normalized-styled-components';
import {transition} from '../../../../common/animations';
import {CONTENT_EDGE_SPACING_PERCENT} from '../../../../common/style-constants';
import {SCREEN_1024_DESKTOP, SCREEN_1680_DESKTOP, SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import {fog, white, black, flash} from '../../../../common/palette';

import BA01BtnPr from '../../../atoms/ba/01-btn-pr';
import GA01ProBar from '../../../atoms/ga/01-pro-bar';

import asNavigationRow from '../../../util/navigation/hocs/as-row';
import ActionWrapper from './components/action-wrapper';
import BroadcastStatus from './components/broadcast-status';
import InfoLineItems from './components/info-line-items';
import Tags from './components/tags';
import Title from './components/title';

/**
 * Main Content Wrapper
 */
const StyledSection = styled(Section)`
    position: relative;
    transition: ${transition('opacity')};
    width: 100%;
    max-width: 306px;
    text-shadow: 0 1px 1px ${rgba(black, 0.3)};
    color: ${white};

    ${stylesWhenNot('isVisible')`
        opacity: 0;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        max-width: 386px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1680_DESKTOP})`
        max-width: 476px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        max-width: 675px;
    `}
`;

/**
 * Upper section anchor & wrapper: from broadcast status up to provider logo
 */
const AnchorPointPositioner = styled.div`
    position: absolute;
    top: 15px;
    transform: translateY(-100%);
    width: 100%;

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        top: 27px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1680_DESKTOP})`
        top: 30px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        top: 47px;
    `}
`;

/**
 * Upper section: news logo
 */
const ProviderLogo = styled.img`
    padding-bottom: 14px;
    max-width: 74px;
    max-height: 25px;
    object-fit: contain;

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        padding-bottom: 21px;
        max-width: 98px;
        max-height: 33px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding-bottom: 28px;
        max-width: 136px;
        max-height: 46px;
    `}
`;

/** Upper section: episode or editorial title (max 2 lines) */
const SubTitle = styled.div`
    padding: 7px 0 0;
    max-height: 2em;
    overflow: hidden;
    text-transform: uppercase;
    font: var(--infinity-poster-subtitle);

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        padding-top: 14px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding-top: 21px;
    `}
`;

/**
 * Upper section: colored bar line on left of anchor
 */
const LeftBorderedContent = styled.div`
    ${stylesWhenNot('isPreview')`
        position: relative;

        &::before {
            position: absolute;
            left: calc(1px - ${CONTENT_EDGE_SPACING_PERCENT}vw);
            background-color: ${property('color')};
            width: 4px;
            height: calc(100% - 3px);
            content: '';

            ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
                width: 10px;
            `}
        }
    `}
`;

/**
 * Lower section wrapper: from synposis down to buttons
 */
const LowerContentWrapper = styled.div`
    margin-top: 10px;
    padding-top: 13px;

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        padding-top: 22px;
        margin-top: 9px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1680_DESKTOP})`
        padding-top: 37px;
        margin-top: 8px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding-top: 47px;
    `}
`;

/**
 * Lower section: synopsis (long text section)
 */
const Synpopsis = styled.p`
    margin: 0;
    padding-top: 14px;
    color: ${fog};
    font: var(--infinity-poster-synopsis);

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        padding-top: 21px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding-top: 28px;
    `}
`;

/**
 * Button row: action buttons + add to watchlist button
 */
const ButtonRow = asNavigationRow(
    styled.div`
        display: flex;
        flex-direction: row;
        padding-top: 21px;

        ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
            padding-top: 28px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            padding-top: 35px;
        `}
    `
);

/** Progress bar for watch button */
const StyledGA01ProBar = styled(GA01ProBar)`
    position: absolute;
    bottom: -4px;
    left: 0;
    transform: translateY(100%);
    width: 100%;
    height: 3px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        bottom: -7px;
        height: 5px;
    `}
`;

const createButtons = ({isVisible, actions, addButton, shouldCtasBeUntabbable}) => actions
    .map(({label, onClick, href, progress, disabled}, index) => (
        <ActionWrapper
            key={label}
            actionButton={(
                <BA01BtnPr
                    tabIndex={shouldCtasBeUntabbable || !(index === 0 && isVisible) ? -1 : undefined}
                    href={href}
                    onClick={onClick}
                    disabled={disabled}
                >
                    {label}
                </BA01BtnPr>
            )}
            progressBar={(!!progress && (
                <StyledGA01ProBar
                    current={progress.current}
                    total={progress.total}
                    isThemed={true} // In the context of this component the progress bar should always be themed.
                />
            ))}
        />
    ))
    // Doing this as a concat because the Nav HOC only works if the children are all in the same array
    .concat(!!isVisible && [!!addButton && cloneElement(addButton, {key: 'add-button', tabIndex: shouldCtasBeUntabbable ? '-1' : undefined})]);

const TM04Ft = React.forwardRef(({
    accentColor = flash,
    actions = [],
    addButton,
    broadcastStatus,
    infoLineItems = [],
    isPreview = false,
    isVisible = true,
    providerLogo,
    shouldCtasBeUntabbable,
    subTitle,
    synopsis,
    tags = [],
    title,
    className,
}, ref) => (
    <StyledSection
        isVisible={isVisible}
        ref={ref}
        className={classnames('TM04Ft', className)}
    >
        <AnchorPointPositioner>
            <LeftBorderedContent color={accentColor} isPreview={isPreview}>
                {!!providerLogo && <ProviderLogo src={providerLogo.src} alt={providerLogo.alt} />}
                {!!broadcastStatus && <BroadcastStatus color={accentColor} {...broadcastStatus} />}
                <Title {...title} />
                {!!subTitle && <SubTitle>{subTitle}</SubTitle>}
                {
                    Array.isArray(infoLineItems)
                    && infoLineItems.length > 0
                    && <InfoLineItems hasSubtitle={!!subTitle}>{infoLineItems}</InfoLineItems>
                }
            </LeftBorderedContent>
        </AnchorPointPositioner>
        <LowerContentWrapper>
            {!!synopsis && <Synpopsis>{synopsis}</Synpopsis>}
            {Array.isArray(tags) && tags.length > 0 && <Tags>{tags}</Tags>}
        </LowerContentWrapper>
        <ButtonRow ref={ref} willResetFocusIndexOnBlur={true}>
            {createButtons({isVisible, actions, addButton, shouldCtasBeUntabbable})}
        </ButtonRow>
    </StyledSection>
));

TM04Ft.propTypes = {
    /** Color used by broadcast icon and left border line */
    accentColor: colorType,
    /** Array of buttons to place on the tile's button row, e.g. Watch, Trailer) */
    actions: propTypes.arrayOf(
        propTypes.shape({
            label: propTypes.string,
            onClick: propTypes.func,
            progress: propTypes.shape({
                current: propTypes.number,
                total: propTypes.number,
            }),
        })
    ),
    /** "Add to watchlist" button */
    addButton: propTypes.node,
    /** News freshness status to display, eg. 'Live - Breaking News' or 'Catchup' */
    broadcastStatus: propTypes.shape({
        label: propTypes.string,
        extendedLabel: propTypes.string,
        showHeadlineIcon: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    }),
    /** Additional CSS classnames to be applied */
    className: classNameType,
    /** Array of nodes to show on the info line,
     *  e.g.  Boardcast details - like when it started (`STARTED 15 MINS AGO`)
     *  or how long the news video is and when it happened ([`30 MINS | 30/01/2020`]) */
    infoLineItems: propTypes.arrayOf(propTypes.node),
    /** Is TM04Ft rendered as iceberg/preview */
    isPreview: propTypes.bool,
    /** Is the slide visible (for fade in/out animations) */
    isVisible: propTypes.bool,
    /** Used for linear provider images, e.g. Skynews logo */
    providerLogo: propTypes.shape({
        src: propTypes.string.isRequired,
        alt: propTypes.string,
    }),
    /** Whether the CTA children should be tabbable */
    shouldCtasBeUntabbable: propTypes.bool,
    /** Subtitle used for editorial title or episode title (max 2 lines) */
    subTitle: propTypes.string,
    /** Video Description, e.g. Covering the latest technology, innovation, finance, marketing
     * news from across Australia and the world. _This is designed to be a longer sentence._ */
    synopsis: propTypes.string,
    /** Tags, e.g. Politics / National / World */
    tags: propTypes.arrayOf(propTypes.node),
    /** The title possibly broken up into multiple lines for diff stylisation <br/>
     * - `value`: this will be singe line title <br/>
     * - `size`: this will be style of single line title <br/>
     * - `lines[]`: this will be the multi-line title <br/>
     **/
    title: propTypes.shape({
        lines: propTypes.arrayOf(
            propTypes.shape({
                value: propTypes.string,
                size: propTypes.oneOf(['small', 'medium', 'large', 'default']),
            })
        ),
        value: propTypes.string,
        size: propTypes.oneOf(['small', 'medium', 'large', 'default']),
    }).isRequired,
};

TM04Ft.displayName = 'TM04Ft';

export default TM04Ft;
