import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import {Button, Footer} from 'normalized-styled-components';
import classnames from 'classnames';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import {akela, ares, blanc, panther, vader} from '../../../../common/palette';

import IC339RemoveAsset from '../../ic/339-remove-asset';
import IC340RemoveAssetFilled from '../../ic/340-remove-asset-filled';

const StyledFooter = styled(Footer)`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin: 0;
    border: none;
    box-shadow: 0 11px 30px 0 ${rgba(panther, 0.3)};
    background-color: ${rgba(panther, 0.4)};
    width: 100%;
    color: ${akela};
    font: var(--ionic-tile-descriptor);
`;

const IconWrapper = styled.span`
    display: block;
    border-width: 0 0 0 3px;
    border-style: solid;
    border-left-color: transparent;
    background-color: transparent;
    padding: 0 10px 1px 5px;
    width: 18px;
    height: 20px;
    clip-path: polygon(0 0, 85% 0, 100% 100%, 0% 100%);

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        border-left-width: 5px;
        padding-top: 2px;
        padding-bottom: 2px;
        width: 30px;
        height: 30px;
    `}
`;

const RemoveButton = styled(Button)`
    appearance: none;
    flex: 0 0 auto;
    border: 0;
    background-color: transparent;
    padding: 0;
    width: 50px;
    text-align: left;
    color: ${vader};
    font: var(--ionic-tile-descriptor);

    .RemoveContinueWatchingIcon {
        color: ${akela};
    }

    .RemoveContinueWatchingIconActive {
        display: none;
    }

    /* stylelint-disable-next-line order/order */
    &:focus,
    &:hover,
    &:active {
        outline: 0;

        ${IconWrapper} {
            border-left-color: ${ares};
            background-color: ${blanc};
        }

        /* stylelint-disable-next-line order/order */
        &::before {
            box-sizing: border-box;
            display: block;
            position: absolute;
            transform: translateY(20px);
            border-left: 3px solid ${ares};
            box-shadow: 0 3px 3px 0 ${rgba(panther, 0.3)};
            background-color: ${blanc};
            padding: 4px 10px;
            font: var(--ionic-body-copy-6-light);
            content: 'Remove title';

            ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
                border-left-width: 5px;
                transform: translateY(33px);
            `}
        }

        .RemoveContinueWatchingIcon {
            display: none;
        }

        .RemoveContinueWatchingIconActive {
            display: block;
        }
    }
`;

const ChildrenWrapper = styled.div`
    flex: 1 1 auto;
    padding: 7px;
    overflow-x: hidden;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding: 11px;
    `}
`;

const GA104TMFooter = ({
    tooltipRemoveCopy,
    tooltipRemoveCopyA11y,
    onClickRemoveFunc,
    children,
    className,
    ...args
}) => (
    <StyledFooter {...args} className={classnames('GA104TMFooter', className)}>
        {!!tooltipRemoveCopy && !!onClickRemoveFunc && (
            <RemoveButton onClick={onClickRemoveFunc} data-title={tooltipRemoveCopy} aria-label={tooltipRemoveCopyA11y}>
                <IconWrapper>
                    <IC339RemoveAsset className="RemoveContinueWatchingIcon" />
                    <IC340RemoveAssetFilled className="RemoveContinueWatchingIconActive" />
                </IconWrapper>
            </RemoveButton>
        )}
        <ChildrenWrapper>
            {children}
        </ChildrenWrapper>
    </StyledFooter>
);

GA104TMFooter.propTypes = {
    /** Additional className(s) to apply */
    className: classNameType,
    /** Copy for the Remove from Continue Watching button */
    tooltipRemoveCopy: propTypes.string,
    /** Accessible copy for the Remove from Continue Watching button */
    tooltipRemoveCopyA11y: propTypes.string,
    /** Function to run when removing from Continue Watching */
    onClickRemoveFunc: propTypes.func,
    /** Children of the Footer */
    children: propTypes.node,
};

GA104TMFooter.displayName = 'GA104TMFooter';

export default GA104TMFooter;
