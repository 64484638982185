import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import property from 'lodash/property';

import {stylesWhen, mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {SCREEN_768_TABLET} from '../../../../../common/screen-sizes';

const StyledDiv = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    pointer-events: all;

    ${stylesWhen(({verticallyAlign}) => ['top', 'bottom'].includes(verticallyAlign))`
        position: absolute;
        align-items: ${({verticallyAlign}) => verticallyAlign === 'top' ? 'flex-start' : 'flex-end'};
        ${property('verticallyAlign')}: 0;
    `}
`;

const Controls = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 14px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        grid-gap: 40px;
    `}

    ${({horizontallyAlign}) => css`
        position: absolute;
        ${horizontallyAlign}: 0; /* interpolating the actual rule value here */
    `}
`;

const ScreenArea = ({verticallyAlign, leftControls, centreControls, rightControls}) => (
    <StyledDiv verticallyAlign={verticallyAlign}>
        {!!leftControls && (
            <Controls horizontallyAlign="left">
                {leftControls}
            </Controls>
        )}
        {!!centreControls && (
            <Controls>
                {centreControls}
            </Controls>
        )}
        {!!rightControls && (
            <Controls horizontallyAlign="right">
                {rightControls}
            </Controls>
        )}
    </StyledDiv>
);

ScreenArea.propTypes = {
    centreControls: propTypes.node,
    leftControls: propTypes.node,
    rightControls: propTypes.node,
    verticallyAlign: propTypes.oneOf(['top', 'bottom']),
};

ScreenArea.displayName = 'ScreenArea';

export default ScreenArea;
