import React from 'react';
import Ic from '..';

const IC99ExitThtr = (args) => (
    <Ic {...args}>
        <path d="M79.4 31.5H20.6c-1.5 0-2.8 1.2-2.8 2.8v44.9c0 1.5 1.2 2.8 2.8 2.8h58.8c1.5 0 2.8-1.2 2.8-2.8V34.3c0-1.5-1.3-2.8-2.8-2.8zm-2.8 44.9H23.4V37.1h53.3l-.1 39.3z" /><path d="M93.1 18H6.9c-.8 0-1.4.6-1.4 1.4v46.4c0 .8.6 1.4 1.4 1.4h7v-2.8H8.3V20.8h83.3v43.6H86v2.8h7c.8 0 1.4-.6 1.4-1.4V19.4c0-.8-.6-1.4-1.3-1.4z" />
    </Ic>
);

IC99ExitThtr.displayName = 'IC99ExitThtr';

export default IC99ExitThtr;
