import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {palette} from '@fsa-streamotion/streamotion-web-ionic';

import addParamsToUrl from '../../../../todo-move-to-widgets-common/utils/add-params-to-url';

const DESKTOP_MIN_SIZE_PX = 1024;
const MOBILE_WIDTH_PX = '306px';

export const VOUCHER_IMAGE_DESKTOP_MAX_WIDTH_PX = '850px';
const {white} = palette;

const StyledPicture = styled.picture`
    width: 100%;
    max-width: ${MOBILE_WIDTH_PX};

    ${mediaQuery({minWidthPx: DESKTOP_MIN_SIZE_PX})`
        max-width: ${VOUCHER_IMAGE_DESKTOP_MAX_WIDTH_PX};
    `}
`;

const StyledImg = styled.img`
    display: block;
    width: 100%;
    /* Font styling is for alt text. Because it's expected to be really long in some cases... */
    color: ${white};
    font: var(--ionic-body-copy-1); /* @TODO - Kayo and Flash variation - should we drop the ionic/mui prefix from font matrix? */
`;

export default function VoucherImage({
    imageAltText,
    imageLinkDesktop,
    imageLinkMobile,
    ...htmlAttributes
}) {
    return (
        <StyledPicture {...htmlAttributes}>
            <source
                srcSet={desktopSrcset(imageLinkDesktop)}
                sizes={VOUCHER_IMAGE_DESKTOP_MAX_WIDTH_PX}
                media={`(min-width: ${DESKTOP_MIN_SIZE_PX}px)`}
            />

            <source
                srcSet={mobileSrcset(imageLinkMobile)}
                sizes={MOBILE_WIDTH_PX}
            />

            <StyledImg
                src={addParamsToUrl(imageLinkDesktop, {imwidth: DESKTOP_MIN_SIZE_PX})}
                alt={imageAltText}
            />
        </StyledPicture>
    );
}

VoucherImage.displayName = 'VoucherImage';

/*
    Note: if you move this component into Ionic, pass in `srcsetOptions` (with predefined `?imwidth` applied)
    But pay attention to the fact we're doing PIXEL DENSITY based srcset here (as opposed to viewport size)
    This is because we render the voucher at two fixed widths and then optimise the image around that

    The imwidth set we're using is the one
*/
VoucherImage.propTypes = {
    imageLinkMobile: propTypes.string,
    imageLinkDesktop: propTypes.string,
    imageAltText: propTypes.string.isRequired,
};

function mobileSrcset(imageUrl) {
    return [
        addParamsToUrl(imageUrl, {imwidth: 320}),          // 1x   306
        //                                                 // 1.5x (no good size available)
        `${addParamsToUrl(imageUrl, {imwidth: 768})} 2x`,  // 2x   306
        `${addParamsToUrl(imageUrl, {imwidth: 1024})} 3x`, // 3x   306
        `${addParamsToUrl(imageUrl, {imwidth: 1280})} 4x`, // 4x   306
        `${addParamsToUrl(imageUrl, {imwidth: 1920})} 5x`, // 5x   306
    ]
        .join(', ');
}

function desktopSrcset(imageUrl) {
    return [
        addParamsToUrl(imageUrl, {imwidth: 1024}),           // 1x   850
        `${addParamsToUrl(imageUrl, {imwidth: 1280})} 1.5x`, // 1.5x 850
        `${addParamsToUrl(imageUrl, {imwidth: 1920})} 2x`,   // 2x   850
        `${addParamsToUrl(imageUrl, {imwidth: 2560})} 3x`,   // 3x   850
        `${addParamsToUrl(imageUrl, {imwidth: 3840})} 4x`,   // 4x   850
        `${addParamsToUrl(imageUrl, {imwidth: 5000})} 5x`,   // 5x   850
    ]
        .join(', ');
}
