import React from 'react';
import Ic from '..';

const IC284Topics = (args) => (
    <Ic {...args}>
        <defs>
            <linearGradient x1="52.302%" y1="4.17%" x2="48.358%" y2="86.874%" id="ic284-topics__linear-gradient">
                <stop stopColor="currentColor" stopOpacity="0" offset="0%" />
                <stop stopColor="currentColor" offset="100%" />
            </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd">
            <path
                d="m47.38 18.123 36.02.148.001 33.153-5 .001L78.4 23.25l-28.77-.119-31.143 35.335 25.121 25.688 1.278 1.385-3.674 3.39-1.228-1.332-28.318-28.952z"
                fill="currentColor"
                fillRule="nonzero"
            />
            <path
                d="m60.577 41.81 4.959.642L58.83 96.17l-4.959-.643z"
                fill="url(#ic284-topics__linear-gradient)"
                fillRule="nonzero"
                transform="rotate(41 59.703 68.99)"
            />
            <circle fill="currentColor" transform="rotate(41 63.5 35.5)" cx="63.5" cy="35.5" r="4.5" />
        </g>
    </Ic>
);

IC284Topics.displayName = 'IC284Topics';

export default IC284Topics;
