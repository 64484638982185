import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {mediaQuery, stylesWhen} from '@fsa-streamotion/styled-component-helpers';

import {auroraGradient, cobalt} from '../../../../common/palette';
import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import {IC313Credit} from '../../../atoms/ic';

const Wrapper = styled.div`
    display: inline-block;
    font: var(--quicksilver-body-copy-3-light);

    ${stylesWhen('isBlock')`
        display: block;
        width: '100%';
    `}
`;

const InnerWrapper = styled.div`
    display: flex;
    flex-direction: row;

    ${stylesWhen('hasTopLinearBorder')`
        padding: 21px 14px 0;

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            padding: 31px 20px 0;
        `}
    `}
`;

const TitleWrapper = styled.div`
    flex: 1;
    -webkit-background-clip: text; /* stylelint-disable-line property-no-vendor-prefix */
    -webkit-text-fill-color: transparent;
    background-clip: text;
    background-image: linear-gradient(to left, ${auroraGradient.top}, ${auroraGradient.bottom});
    color: transparent;

    ${stylesWhen('isBlock')`
        text-align: center;
        width: 100%;
    `}
`;

const ExtraContentWrapper = styled.div`
    flex-shrink: 0;
    margin-left: 15px;
    text-align: end;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        margin-left: 20px;
    `}
`;

const IconWrapper = styled.span`
    display: inline-block;
    margin: 0 2px -4px 0;
    padding: 0 2px;
    width: 20px;
    height: 20px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 26px;
        height: 26px;
        margin-right: 4px;
    `}
`;

const Indicator = styled.div`
    height: 2px;

    ${stylesWhen('visible')`
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        background-image: linear-gradient(to left, ${auroraGradient.top}, ${auroraGradient.bottom});;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        height: 3.4px;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
    `}
`;

/**
 * Credit Indicator
 *
 * @see {@link https://zpl.io/Q0GEM34}
 */
const GM24CreditIndicator = ({
    className,
    copyText = 'Stack & Save Monthly Credit Unlocked',
    priceCallout,
    hasTopLinearBorder = true,
    copyIcon = <IC313Credit secondaryColor={cobalt} />,
    isBlock = false,
    ...htmlAttributes
}) => (
    <Wrapper className={classnames('GM24CreditIndicator', className)} isBlock={isBlock} {...htmlAttributes}>
        <Indicator visible={hasTopLinearBorder} />

        <InnerWrapper hasTopLinearBorder={hasTopLinearBorder}>
            <TitleWrapper isBlock={isBlock}>
                {!!copyIcon && <IconWrapper>{React.cloneElement(copyIcon, {color: auroraGradient.bottom})}</IconWrapper>}
                {copyText}
            </TitleWrapper>

            {!!priceCallout && <ExtraContentWrapper>{priceCallout}</ExtraContentWrapper>}
        </InnerWrapper>
    </Wrapper>
);

GM24CreditIndicator.propTypes = {
    /** additional CSS classnames to be applied */
    className: propTypes.string,
    /** price callout content on rhs (e.g. `$10/mth`) */
    priceCallout: propTypes.node,
    /** main text on lhs */
    copyText: propTypes.string,
    /** show/hide indicator line on top */
    hasTopLinearBorder: propTypes.bool,
    /** prefix copy icon */
    copyIcon: propTypes.node,
    /** enable block style, text will be aligned center */
    isBlock: propTypes.bool,
};

GM24CreditIndicator.displayName = 'GM24CreditIndicator';

export default GM24CreditIndicator;
