import React from 'react';
import Ic from '..';

const IC30BackGlass = (args) => (
    <Ic {...args}>
        <g transform="translate(11 21)">
            <path d="M67.414 16.659c-2.815-1.552-6.037-2.224-9.251-2.224h-.028l-34.802.054V2.791l-1.638-1.634a3.97 3.97 0 0 0-5.597-.007L.934 16.199a3.156 3.156 0 0 0 0 4.483l15.164 15.05a3.97 3.97 0 0 0 5.597-.008l1.638-1.634V22.385l34.816-.048h.014c3.18 0 6.167 1.229 8.418 3.474 2.588 2.574 3.84 6.13 3.414 9.83-.702 6.077-6.25 10.457-12.383 10.457H32.839v3.954c0 .433.09.852.254 1.23.524 1.565 1.962 2.718 3.71 2.718h20.499c10.903 0 20.423-8.403 20.692-19.278.185-7.422-3.69-14.273-10.58-18.063" />
        </g>
    </Ic>
);

IC30BackGlass.displayName = 'IC30BackGlass';

export default IC30BackGlass;
