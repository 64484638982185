import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {coal, white} from '../../../../common/palette';
import GA21CaGrd from '../../../atoms/ga/21-ca-grad';
import GA92BrandLogo from '../../../atoms/ga/92-brand-logo';
import {SCREEN_TABLET, SCREEN_LG_DESKTOP, SCREEN_PRETTY_LG_DESKTOP} from '../../../../common/screen-sizes';
import SrcsetImg from '../../../../common/srcset-img';

const Z_INDEX_POSTER = 0;
const Z_INDEX_ABOVE_POSTER = 1;

const OuterContainer = styled.section`
    position: relative;
    z-index: ${Z_INDEX_ABOVE_POSTER};
    width: 100%;
`;

// We need to show the background images beneath the main content
const PosterZIndexContainer = styled.div`
    position: relative;
    z-index: ${Z_INDEX_POSTER};
    width: 100%;
`;

const StyledForeground = styled.div`
    box-sizing: border-box;
    position: absolute;
    bottom: 21px;
    left: 50%;
    transform: translateX(-50%);
    padding: 7px;
    width: 100%;
    max-width: 1680px;
`;

const MainHeading = styled.h1`
    text-align: center;
    color: ${white};
    font: var(--mui-header-2-bold);
`;

const StyledGA21CaGrd = styled(GA21CaGrd)`
    position: absolute;
    bottom: 0;
    background: linear-gradient(transparent, transparent 50%, ${rgba(coal, 0.9)});
    pointer-events: none;
`;

const BRANDING_WIDTH_PX = 91;
const BRANDING_WIDTH_LG_DESKTOP_PX = 151;
const BRANDING_WIDTH_PLG_DESKTOP_PX = 170;

const Branding = styled.div`
    display: grid;
    position: absolute;
    top: 14px;
    left: 14px;
    grid-auto-flow: row;
    grid-gap: 14px;
    width: ${BRANDING_WIDTH_PX}px;

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        top: 24px;
        left: 24px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
        top: 30px;
        left: 30px;
        width: ${BRANDING_WIDTH_LG_DESKTOP_PX}px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_PRETTY_LG_DESKTOP})`
        top: 45px;
        left: 45px;
        width: ${BRANDING_WIDTH_PLG_DESKTOP_PX}px;
    `}
`;

const LinkIfHasHref = ({children, href, ...rest}) => ( // eslint-disable-line react/prop-types, react/display-name
    href ? <a {...rest} href={href}>{children}</a> : children
);

const StyledSrcsetImg = styled(SrcsetImg)`
    width: 100%;
`;

function getPartnerLogoSrcset(imageUrl) {
    return [BRANDING_WIDTH_PX, BRANDING_WIDTH_LG_DESKTOP_PX, BRANDING_WIDTH_PLG_DESKTOP_PX]
        .reduce((srcSet, size) => ({...srcSet, [size]: `${imageUrl}?imwidth=${size}`}), {}); // using imwidth in MUI is not ideal. bad sean
}

const OR08MarBlk = ({
    children,
    poster,
    title = 'Welcome home, sports lovers',
    shouldApplyCarouselGradient,

    partnerLogoUrl,
    partnerLogoAlt = 'Kayo sports partner logo',
    partnerClickUrl,
}) => (
    <OuterContainer className="OR08MarBlk">
        <PosterZIndexContainer>
            {poster}
        </PosterZIndexContainer>

        <Branding>
            <GA92BrandLogo
                isInternalMode={false}
                isSportsMode={true}
                title="Kayo Sports"
            />
            {!!partnerLogoUrl && (
                <LinkIfHasHref href={partnerClickUrl} target="_blank" rel="noreferrer noopener">
                    <StyledSrcsetImg
                        srcsetOptions={getPartnerLogoSrcset(partnerLogoUrl)}
                        alt={partnerLogoAlt}
                    />
                </LinkIfHasHref>
            )}
        </Branding>

        {!!shouldApplyCarouselGradient && <StyledGA21CaGrd />}

        <StyledForeground>
            <MainHeading>{title}</MainHeading>
            {children}
        </StyledForeground>
    </OuterContainer>
);

OR08MarBlk.propTypes = {
    /** Poster (e.g. GM07SlidingPictures, GA06PostImg, or img element) */
    poster: propTypes.node,
    /** Foreground items */
    children: propTypes.node,
    /** Call to action text */
    title: propTypes.string,
    /** Has carousel gradient? */
    shouldApplyCarouselGradient: propTypes.bool,

    /** If provided, will render a partner logo */
    partnerLogoUrl: propTypes.string,
    /** Accessible text for partner logo */
    partnerLogoAlt: propTypes.string,
    /** Clickthrough URL on partner logo */
    partnerClickUrl: propTypes.string,
};

OR08MarBlk.displayName = 'OR08MarBlk';

export default OR08MarBlk;
