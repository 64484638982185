import React from 'react';
import propTypes from 'prop-types';
import {rgba} from 'polished';
import styled, {css} from 'styled-components';
import {Button, A} from 'normalized-styled-components';
import classnames from 'classnames';

import {buttonType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {kayoGreen, slate, midnight} from '../../../../common/palette';
import GA43ArrowInd from '../../ga/43-arrow-ind';

const baseStyle = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: ${transition('border-color')};
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: ${rgba(midnight, 0.7)};
    padding: 15px 15px 18px;
    width: 100%;
    color: ${slate};
    font: var(--mui-body-copy-3);

    &:hover,
    &:focus {
        outline: 0;
        border-color: ${kayoGreen};
    }
`;

const StyledButton = styled(Button)`
    ${baseStyle}

    appearance: none;
`;

const StyledLink = styled(A)`
    ${baseStyle}

    box-sizing: border-box;
    text-decoration: none;
`;

const BA20NavBtn = ({ // exporting for storybook, which can't cope with forwardRef for props documentation
    className,
    href,
    label,
    type = href ? undefined : 'button',

    onClick,

    ref,

    ...htmlAttributes
}) => {
    const Cta = href ? StyledLink : StyledButton;

    return (
        <Cta
            {...htmlAttributes}
            {...{
                ref,
                href,
                type,
                onClick,
            }}
            className={classnames('BA20NavBtn', className)}
            type={href ? undefined : type}
        >
            {label} <GA43ArrowInd />
        </Cta>
    );
};

BA20NavBtn.displayName = 'BA20NavBtn';

BA20NavBtn.propTypes = {
    /** additional CSS classnames to be applied */
    className: classNameType,
    /** hyperlink URL */
    href: propTypes.string,
    /** label of the button */
    label: propTypes.string.isRequired,
    /** Callback to fire on button click */
    onClick: propTypes.func,
    /** react ref, e.g. as passed in by React.forwardRef */
    ref: propTypes.string,
    /** html button type */
    type: buttonType,
};

export default React.forwardRef((props, ref) => <BA20NavBtn {...props} ref={ref} />);
