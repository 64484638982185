import querystring from 'querystring';

/**
 * Copied over from HAWK Widgets to Kayo, then to here
 * PrependQueryString dissects a url and appends parameters before it
 * this is useful for order specific query parameters (don't ask)
 *
 * @example
 * prependQueryString({
 *     parameters: {
 *         test: true,
 *     },
 *     url: 'http://www.example.com?example=true'
 * });
 * // => 'http://www.example.com?test=true&example=true'
 *
 * @param  {Object} options                 - see below
 * @param  {Object} options.parameters      - The parameters to prepend to the querystring.
 * @param  {string} options.url             - The original url to prepend the new parameters to.
 * @returns {number} Returns the url with the querystring prepended
 */
export default function prependQueryString({
    parameters = {},
    url = '',
}) {
    if (!Object.keys(parameters).length) {
        return url;
    }

    const [path = '', queryString = ''] = url.split('?');

    const urlWithQueryString = `${path}?${querystring.stringify(parameters)}`;

    return queryString ? `${urlWithQueryString}&${queryString}` : urlWithQueryString;
}
