import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {white, midnight} from '../../../../common/palette';

const GamblingMessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${white};
    width: 361px;
    height: 82px;
    text-align: center;
    color: ${midnight};
`;

const StyledTitle = styled.div`
    padding-bottom: 5px;
    overflow: hidden;
    text-transform: uppercase;
    text-overflow: ellipsis;
    white-space: nowrap;
    font: var(--mui-gambling-message-bold);
`;

const StyledSubtitle = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font: var(--mui-gambling-message-light);
`;

const TM39GamblingTil = ({
    title,
    subtitle,
}) => (
    <GamblingMessageContainer>
        <StyledTitle>{title}</StyledTitle>
        {!!subtitle && (<StyledSubtitle>{subtitle}</StyledSubtitle>)}
    </GamblingMessageContainer>
);

TM39GamblingTil.displayName = 'TM39GamblingTil';

TM39GamblingTil.propTypes = {
    /** Panel title */
    title: propTypes.string,
    /** Panel subtitle */
    subtitle: propTypes.string,
};

export default TM39GamblingTil;
