import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';

import {black, white, satellite} from '../../../../../common/palette';
import {formatSecondsIntoStandardLabel} from '../../../../../common/format-time';
import {transition} from '../../../../../common/animations';

const FloatingDisplayTime = styled.span`
    position: absolute;
    top: ${({isVisible}) => isVisible ? '-23px' : '0'};
    left: 0;
    transition: ${transition(['top'])};
    background: ${rgba(black, 0.5)};
    padding: 3px;
    color: ${white};
    font: var(--mui-body-copy-5-bold);
`;

const RegularDisplayTime = styled.span`
    white-space: nowrap;
    color: ${satellite};
    font: var(--mui-body-copy-3-bold);
`;

const Divider = styled.span`
    color: ${satellite};
`;

const VPTM01DisplayTime = ({
    currentTime,
    duration,
    isLiveStream,
    isOnEdge,
    scrubberType,
    isVisible,
    isMobileLandscapeUp,
}) => {
    const DisplayTime = isMobileLandscapeUp ? RegularDisplayTime : FloatingDisplayTime;

    switch (!!isLiveStream && scrubberType) {
        case 'none':
            return null;

        case 'from-edge':
            return (
                <DisplayTime role="timer" isVisible={isVisible} isMobileLandscapeUp={isMobileLandscapeUp}>
                    {isOnEdge ? '0:00' : `-${formatSecondsIntoStandardLabel(duration - currentTime)}`}
                </DisplayTime>
            );

        default:
        case false:
        case 'default':
            return (
                <DisplayTime role="timer" isVisible={isVisible} isMobileLandscapeUp={isMobileLandscapeUp}>
                    {formatSecondsIntoStandardLabel(currentTime)}
                    <Divider> / </Divider>
                    {formatSecondsIntoStandardLabel(duration)}
                </DisplayTime>
            );
    }
};

VPTM01DisplayTime.displayName = 'VPTM01DisplayTime';

VPTM01DisplayTime.propTypes = {
    currentTime: propTypes.number,
    duration: propTypes.number,
    isLiveStream: propTypes.bool,
    isOnEdge: propTypes.bool,
    scrubberType: propTypes.string,
    isVisible: propTypes.bool,
    isMobileLandscapeUp: propTypes.bool,
};

export default VPTM01DisplayTime;
