import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import classnames from 'classnames';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {srcsetOptionsType} from '@fsa-streamotion/custom-prop-types';
import {rgba} from 'polished';
import BA22EditBtn from '../../../atoms/ba/22-edit-btn';

import {SCREEN_768_TABLET} from '../../../../common/screen-sizes';
import {auroraGradient, ink, mist, white} from '../../../../common/palette';
import {getLargestImage} from '../../../../common/tile-utils';
import stringifySrcsetOptions from '../../../../common/stringify-srcset-options';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    background-color: ${white};
    padding-top: 14px;
    color: ${ink};
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

const Content = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 0 14px;
    margin-bottom: 14px;
    font: var(--quicksilver-body-copy-4);
`;

const Description = styled.div`
    margin-top: 7px;
    font: var(--quicksilver-body-copy-3);
`;

const ImageTile = styled.div`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-right: 6px;
    margin-left: 14px;
    border-radius: 2.8px;
    background-color: ${rgba(mist, 0.3)};
    padding: 5px;
    width: 102px;
    height: 102px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: 133px;
        height: 133px;
        margin-left: 21px;
    `}
`;

const DeviceImage = styled.img`
    display: block;
    width: 100%;
    object-fit: cover;
    object-position: center;
`;

const Title = styled.div`
    display: flex;
    flex: 1;
    font: var(--quicksilver-body-copy-1);
`;

const FootnoteWrapper = styled.div`
    flex: 1;
    opacity: 0.6;
    margin: 7px 14px 14px;
    font: var(--quicksilver-body-copy-4);

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        margin: 21px 14px 21px 21px;
    `}
`;

const Footnote = styled.div`
    margin-top: 7px;
`;

const Quantity = styled.div`
    padding-bottom: ${({hasPaddingBottom}) => hasPaddingBottom ? '7px' : 0};
`;

const PriceWrapper = styled.div`
    display: flex;
    margin-right: 14px;
    margin-bottom: 4px;
`;

const DiscountContainer = styled.div`
    border-radius: 0 0 6px 6px;
    background-image: linear-gradient(to left, ${auroraGradient.top}, ${auroraGradient.bottom});
    padding: 7px;
    width: 100%;
    text-align: center;
    color: ${white};
    font: var(--quicksilver-body-copy-4);
`;

/**
 * Item Summary Card
 *
 * @see {@link https://zpl.io/Q0GEMRm}
 */
const OR148ItemSummaryCard = ({
    className,
    copyDescription,
    copyDiscount,
    copyFootnote,
    copyTitle,
    onClickEdit,
    priceCallout,
    quantity,
    thumbnailAltText,
    thumbnailSrcsetOptions,
    thumbnailSrcsetSizes,
    ...htmlAttributes
}) => (
    <Wrapper className={classnames('OR148ItemSummaryCard', className)} {...htmlAttributes}>
        <Row>
            {!!thumbnailSrcsetOptions && (
                <ImageTile aria-label="Card thumbnail">
                    <DeviceImage
                        alt={thumbnailAltText}
                        src={getLargestImage(thumbnailSrcsetOptions)}
                        srcSet={stringifySrcsetOptions(thumbnailSrcsetOptions)}
                        sizes={thumbnailSrcsetSizes}
                    />
                </ImageTile>
            )}

            <Content>
                <Row>
                    <Title aria-label="Card title">{copyTitle}</Title>
                    {!!onClickEdit && <BA22EditBtn onClick={onClickEdit}>Edit</BA22EditBtn>}
                </Row>

                {!!copyDescription && <Description aria-label="Card description">{copyDescription}</Description>}
            </Content>
        </Row>

        <Row>
            <FootnoteWrapper>
                {quantity > 1 && (
                    <Quantity hasPaddingBottom={!!copyFootnote}>
                        Quantity: {quantity}
                    </Quantity>
                )}
                <Footnote aria-label="Card footnote">{copyFootnote}</Footnote>
            </FootnoteWrapper>
            <PriceWrapper>{priceCallout}</PriceWrapper>
        </Row>

        {!!copyDiscount && (
            <Row>
                <DiscountContainer>{copyDiscount}</DiscountContainer>
            </Row>
        )}
    </Wrapper>
);

OR148ItemSummaryCard.propTypes = {
    /** Additional class name(s) */
    className: propTypes.string,
    /** copy description show below title */
    copyDescription: propTypes.node,
    /** copy highlighting the applied discount */
    copyDiscount: propTypes.string,
    /** copy footnote show below thumbnail */
    copyFootnote: propTypes.node,
    /** copy title */
    copyTitle: propTypes.node,
    /** on edit callback */
    onClickEdit: propTypes.func,
    /** GM25PriceCallout */
    priceCallout: propTypes.node,
    /** quantity of devices to purchase */
    quantity: propTypes.number,
    /** Thumbnail img alt text */
    thumbnailAltText: propTypes.string,
    /** Thumbnail srcset options */
    thumbnailSrcsetOptions: srcsetOptionsType,
    /** Thumbnail srcset sizes */
    thumbnailSrcsetSizes: propTypes.string,
};

OR148ItemSummaryCard.displayName = 'OR148ItemSummaryCard';

export default OR148ItemSummaryCard;
