import React, {cloneElement} from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';

import {classNameType, srcsetOptionsType} from '@fsa-streamotion/custom-prop-types';

import EMPTY_IMAGE_SRC from '../../../../common/empty-image-src';
import {getCtaType, StyledTile, StyledHoverLine} from '../../../../common/tile-utils';

const TM34JumpTil = React.forwardRef(({
    card,
    className,
    href,
    isBeingLazy,
    label,
    lazySrc = EMPTY_IMAGE_SRC,
    onClick,
    ...htmlAttributes
}, ref) => {
    const Cta = getCtaType(href, onClick);

    return (
        <StyledTile className={classnames('TM34JumpTil', className)}>
            <Cta
                {...htmlAttributes}
                ref={ref}
                aria-label={label}
                href={href}
                onClick={onClick}
            >
                {cloneElement(
                    card,
                    {isBeingLazy, srcsetOptionsLazyLoadPlaceholder: lazySrc}
                )}
            </Cta>
            <StyledHoverLine />
        </StyledTile>
    );
});

TM34JumpTil.propTypes = {
    /** Tile image which will take in isBeingLazy and/or lazySrc props */
    card: propTypes.node,
    /** Additional class(es) */
    className: classNameType,
    /** If the component is intended as an anchor tag, this is the link to redirect the user to */
    href: propTypes.string,
    /** Is the inner component supposed to be lazy loaded? */
    isBeingLazy: propTypes.bool,
    /** A string to label the component via aria-label */
    label: propTypes.string,
    /** A URL or path to an image to be lazy loaded */
    lazySrc: srcsetOptionsType, // @TODO: This should be called lazySrcsetOptions
    /** If the component does not have a href property, use this event handler */
    onClick: propTypes.func,
};

TM34JumpTil.displayName = 'TM34JumpTil';

export default TM34JumpTil;
