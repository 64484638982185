import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import property from 'lodash/property';
import {rgba} from 'polished';
import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {colorType} from '@fsa-streamotion/custom-prop-types';

import {black, errorState, white} from '../../../../common/palette';

const ContextualHelp = styled.div`
    position: relative;
    margin-top: 7px;
    border-left: 4px solid ${property('messageColor')};
    box-shadow: 0 1px 1px 0 ${rgba(black, 0.2)}, 0 -1px 2px -1px ${rgba(black, 0.2)};
    background-color: ${white};
    padding: 14px;
    color: ${black};
    font: var(--quicksilver-body-copy-4-light);

    a {
        color: inherit;
    }
`;

const StyledArrow = styled.div`
    position: absolute;
    top: -13px;
    right: 11px;
    filter: drop-shadow(0 -1px 1px ${rgba(black, 0.1)});
    border-right: 14px solid transparent;
    border-bottom: 14px solid ${white};
    border-left: 14px solid transparent;
    width: 0;
    height: 0;

    ${stylesWhen('isLeftAligned')`
        left: 11px;
    `}

    ${stylesWhen('hasDownArrow')`
        transform: rotate(180deg);
        top: unset;
        bottom: -13px;
    `}
`;

/**
 * ### Input Contextual Help
 * A help text box that attaches to input atoms to provide contextual information
 **/
const IA05InputHelp = ({
    arrowOrientation = 'right',
    children,
    hasDownArrow,
    messageColor = errorState,
    showArrow = true,
    ...htmlAttributes
}) => (
    <ContextualHelp
        {...htmlAttributes}
        hasDownArrow={hasDownArrow}
        messageColor={messageColor}
    >
        {!!showArrow && (
            <StyledArrow
                hasDownArrow={hasDownArrow}
                isLeftAligned={arrowOrientation === 'left'}
            />
        )}
        {children}
    </ContextualHelp>
);

IA05InputHelp.propTypes = {
    /** Positioning of the arrow header */
    arrowOrientation: propTypes.oneOf(['left', 'right']),
    /** Whether to show the arrow in downward direction */
    hasDownArrow: propTypes.bool,
    /** Content for the help dialog */
    children: propTypes.node.isRequired,
    /** Whether to show the arrow head or not */
    showArrow: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    /** Optional color to denote message type */
    messageColor: colorType,
};

IA05InputHelp.displayName = 'IA05InputHelp';

export default IA05InputHelp;
