import React from 'react';

import Ic from '..';

const IC132LoopLoader = (args) => (
    <Ic {...args}>
        <path d="M19.152 65.354a34.642 34.642 0 01-3.654-12.082L7 52.404A42.604 42.604 0 0012.6 70.8l6.552-5.446zm-2.198-26.432a34.296 34.296 0 015.992-11.116l-5.418-6.636A42.822 42.822 0 008.4 38.068l8.554.854zm22.442 43.596a34.568 34.568 0 01-11.116-5.992L21.644 82a42.82 42.82 0 0016.898 9.086l.854-8.568zM65.8 80.306a34.642 34.642 0 01-12.082 3.654l-.868 8.498a42.628 42.628 0 0018.368-5.53L65.8 80.306zm26.992-26.502A43.077 43.077 0 0028.616 12l5.404 6.678c14.541-7.52 32.36-3.84 42.736 8.822C87.13 40.163 87.232 58.358 77 71.136l5.292 6.72a42.873 42.873 0 0010.5-24.052z" />
        <path d="M46.984 39.132l14.322 11.074L46.984 61.28V39.132m-4.2-8.554v39.2l25.382-19.6-25.382-19.6z" />
    </Ic>
);

IC132LoopLoader.displayName = 'IC132LoopLoader';

export default IC132LoopLoader;
