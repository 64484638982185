import get from 'lodash/get';

import {ENVIRONMENT} from './constants';

/** @typedef {import('./constants').Environment} Environment */

/** * @type {Object.<Environment, string>} */
export const PROFILE_API = {
    [ENVIRONMENT.production]: 'https://profileapi.streamotion.com.au',
    [ENVIRONMENT.staging]: 'https://profileapi-staging.streamotion.com.au',
    [ENVIRONMENT.development]: 'https://profileapi-sit.streamotion.com.au',
    // Kayo only
    [ENVIRONMENT.testing]: 'https://profileapi-testing.streamotion.com.au', // coming soon
};

/** * @type {Object.<Environment, string>} */
export const SIGNUP_API = {
    [ENVIRONMENT.production]: 'https://signupapi.streamotion.com.au',
    [ENVIRONMENT.staging]: 'https://signupapi-staging.streamotion.com.au',
    [ENVIRONMENT.development]: 'https://signupapi-sit.streamotion.com.au',
    // Kayo only
    [ENVIRONMENT.testing]: 'https://signupapi-dev.streamotion.com.au',
};

/** * @type {Object.<Environment, string>} */
export const BILLING_API_URL = {
    [ENVIRONMENT.production]: 'https://billingapi.streamotion.com.au',
    [ENVIRONMENT.staging]: 'https://billingapi-staging.streamotion.com.au',
    [ENVIRONMENT.development]: 'https://billingapi-sit.streamotion.com.au',
    // Kayo only
    [ENVIRONMENT.testing]: 'https://billingapi-testing.streamotion.com.au', // yet to be connected.
};

/** * @type {Object.<Environment, string>} */
export const CHROMECAST_TOKEN_API_URL = {
    [ENVIRONMENT.production]: 'https://tokenservice.streamotion.com.au',
    [ENVIRONMENT.staging]: 'https://tokenservice-staging.streamotion.com.au',
    // Kayo only
    [ENVIRONMENT.testing]: 'https://chromecasttokenapi-dev.streamotion.com.au',
};

/** * @type {Object.<Environment, string>} */
export const COMMON_RESOURCES_URL = {
    [ENVIRONMENT.production]: 'https://resources.streamotion.com.au/production/common',
    [ENVIRONMENT.staging]: 'https://resources.streamotion.com.au/staging/common',
    [ENVIRONMENT.development]: 'https://resources.streamotion.com.au/staging/common',
};

export const getBrandEnvsFromWidgetSettings = (defaultEnvSettings, settings) => Object.entries(defaultEnvSettings)
    .reduce((envs, [envKey, [defaultEnv, ...otherValidEnvs]]) => {
        const envValue = settings[envKey];

        return {...envs, [envKey]: otherValidEnvs.includes(envValue) ? envValue : defaultEnv};
    }, {});

export const getBrandBillingHostedPaymentPage = (billingHostedPaymentPage, path) => get(billingHostedPaymentPage, path);

/**
 * Given a brand name and platformEnv, provide the base location for resources config
 *
 * @param {Object} options See below
 * @param {string} options.brand brand name for widget eg: kayo, binge, flash
 * @param {string} options.resourcesEnv environment for config  eg: 'production', 'staging'
 * @returns {string|undefined} resources url base path
 */
export function getResourcesUrlBasePath({brand, resourcesEnv = 'production'}) {
    if (!brand) {
        console.error('UMW: Cannot get resources url without brand');

        return;
    }

    return `https://resources.streamotion.com.au/${resourcesEnv}/${brand}`;
}
