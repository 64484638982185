import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {transition} from '../../../../common/animations';
import {coal, white, kayoGreen} from '../../../../common/palette';
import {SCREEN_PHABLET} from '../../../../common/screen-sizes';
import IC43Valid from '../../ic/43-valid';

const TickIconContainer = styled.span`
    position: absolute;
    top: 6px;
    left: 6px;
`;

const StyledInput = styled.input`
    margin-right: 35px;

    &:checked + ${TickIconContainer} {
        opacity: 1;
    }

    &:not(:checked) + ${TickIconContainer} {
        opacity: 0;
    }

    + ${TickIconContainer} {
        transition: ${transition('opacity')};
    }
`;

const StyledLabel = styled.label`
    display: flex;
    position: relative;
    height: 42px;

    ${mediaQuery({minWidthPx: SCREEN_PHABLET})`
        align-items: center;
    `}

    &::before {
        display: block;
        position: absolute;
        top: 0;
        border: 1px solid ${white};
        border-radius: 10px;
        background-color: ${coal};
        padding: 0;
        width: 40px;
        height: 40px;
        content: '';
    }
`;

const LabelText = styled.span`
    display: inline-block;
    color: ${white};
    font: var(--mui-body-copy-4);

    a {
        color: inherit;
    }
`;

const IA02Check = React.forwardRef(({
    isChecked,
    label,
    ...htmlAttributes
}, ref) => (
    <StyledLabel>
        <StyledInput
            type="checkbox"
            defaultChecked={isChecked}
            ref={ref}
            {...htmlAttributes}
        />
        <TickIconContainer>
            <IC43Valid color={kayoGreen} size="30px" />
        </TickIconContainer>
        <LabelText>{label}</LabelText>
    </StyledLabel>
));

IA02Check.displayName = 'IA02Check';

IA02Check.propTypes = {
    /** Set the initial state of the checkbox */
    isChecked: propTypes.bool,
    /** Label for the checkbox */
    label: propTypes.node.isRequired,
};

export default IA02Check;
