import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {SCREEN_768_TABLET} from '../../../../common/screen-sizes';
import {ink} from '../../../../common/palette';

const IMAGE_MOBILE_MAX_WIDTH_PX = '280';
const IMAGE_DESKTOP_MAX_WIDTH_PX = '380';

const StyledPicture = styled.picture`
    display: block;
    max-width: ${IMAGE_MOBILE_MAX_WIDTH_PX}px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        max-width: ${IMAGE_DESKTOP_MAX_WIDTH_PX}px;
    `}
`;

const StyledImg = styled.img`
    width: 100%;
    /* Font styling is for alt text. */
    color: ${ink};
    font: var(--quicksilver-body-copy-1);
`;

/**
 * Migrate Apps - Marketing sourced image of migrate-able apps
 *
 * @param {string} className - Additional class(es) to be applied to the component.
 * @param {string} imageAltText - Image alt attribute
 * @param {string} imageLinkDesktop - srcset attribute for non-mobile devices
 * @param {string} imageLinkMobile - srcset attribute for mobile devices
 * @returns {React.ReactElement} - A React element representing the migrate apps image.
 *
 * @see {@link https://zpl.io/z8G8v9z}
 */
const GA159MigrateApps = ({
    className,
    imageAltText = '',
    imageLinkDesktop,
    imageLinkMobile,
    ...htmlAttributes
}) => (
    <StyledPicture
        {...htmlAttributes}
        className={classnames('GA159MigrateApps', className)}
    >
        <source
            srcSet={`${imageLinkDesktop} ${IMAGE_DESKTOP_MAX_WIDTH_PX}w`}
            sizes={`${IMAGE_DESKTOP_MAX_WIDTH_PX}px`}
            media={`(min-width: ${SCREEN_768_TABLET}px)`}
        />

        <source
            srcSet={`${imageLinkMobile} ${IMAGE_MOBILE_MAX_WIDTH_PX}w`}
            sizes={`${IMAGE_MOBILE_MAX_WIDTH_PX}px`}
        />

        <StyledImg
            src={imageLinkMobile}
            alt={imageAltText}
        />
    </StyledPicture>
);

GA159MigrateApps.propTypes = {
    /** Additional class name(s) */
    className: classNameType,
    /** Image alt attribute */
    imageAltText: propTypes.string,
    /** srcset attribute for non-mobile devices */
    imageLinkDesktop: propTypes.string,
    /** srcset attribute for mobile devices */
    imageLinkMobile: propTypes.string,
};

GA159MigrateApps.displayName = 'GA159MigrateApps';

export default GA159MigrateApps;
