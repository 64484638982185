import React from 'react';

import Ic from '..';

const IC112Watchlist = ({
    ...otherProps
}) => (
    <Ic {...otherProps}>
        <g>
            <g transform="translate(-257 -10)">
                <g transform="translate(245.452 10)">
                    <g transform="translate(12.452)">
                        <path fill="none" d="M-.5 0h100v100H-.5z" />
                        <g transform="translate(4.48 5.016)">
                            <g>
                                <g transform="translate(6.707)">
                                    <defs>
                                        <filter
                                            id="Adobe_OpacityMaskFilter"
                                            filterUnits="userSpaceOnUse"
                                            x={31.3}
                                            y={15.9}
                                            width={38}
                                            height={3.1}
                                        >
                                            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
                                        </filter>
                                    </defs>
                                    <mask
                                        maskUnits="userSpaceOnUse"
                                        x={31.3}
                                        y={15.9}
                                        width={38}
                                        height={3.1}
                                        id="mask-2_00000071524023233727375530000007046436130493584256_"
                                    >
                                        <g filter="url(#Adobe_OpacityMaskFilter)">
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M28.3 12.9h44V22h-44z"
                                            />
                                        </g>
                                    </mask>
                                    <path
                                        d="M31.8 15.9c-.3 0-.5.2-.5.5v2.1c0 .3.2.5.5.5h37c.3 0 .5-.2.5-.5v-2.1c0-.3-.2-.5-.5-.5h-37z"
                                        opacity="0.2"
                                        mask="url(#mask-2_00000071524023233727375530000007046436130493584256_)"
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        fill="#dfdfdf"
                                    />
                                    <defs>
                                        <filter
                                            id="Adobe_OpacityMaskFilter_00000078013145729937873270000006857063569731021982_"
                                            width="44"
                                            height="9.1"
                                            x="28.3"
                                            y="12.9"
                                            filterUnits="userSpaceOnUse"
                                        >
                                            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
                                        </filter>
                                    </defs>
                                    <mask
                                        id="mask-2_00000119803666371692083680000001938665970375532687_"
                                        width="44"
                                        height="9.1"
                                        x="28.3"
                                        y="12.9"
                                        maskUnits="userSpaceOnUse"
                                    >
                                        <g filter="url(#Adobe_OpacityMaskFilter_00000078013145729937873270000006857063569731021982_)">
                                            <path
                                                d="M28.3 12.9h44V22h-44z"
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                            />
                                        </g>
                                    </mask>
                                    <path
                                        d="M31.8 15.9c-.3 0-.5.2-.5.5v2.1c0 .3.2.5.5.5h37c.3 0 .5-.2.5-.5v-2.1c0-.3-.2-.5-.5-.5h-37zm37 6.1h-37c-1.9 0-3.5-1.6-3.5-3.5v-2.1c0-1.9 1.6-3.5 3.5-3.5h37c1.9 0 3.5 1.6 3.5 3.5v2.1c0 1.9-1.6 3.5-3.5 3.5z"
                                        mask="url(#mask-2_00000119803666371692083680000001938665970375532687_)"
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        fill="#dfdfdf"
                                    />
                                </g>
                                <g transform="translate(6.707 3.727)">
                                    <defs>
                                        <filter
                                            id="Adobe_OpacityMaskFilter_00000163047688605744332040000012831126247848567698_"
                                            width="38"
                                            height="3.1"
                                            x="31.3"
                                            y="25.5"
                                            filterUnits="userSpaceOnUse"
                                        >
                                            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
                                        </filter>
                                    </defs>
                                    <mask
                                        id="mask-4_00000090286377100714035570000016481524734216280475_"
                                        width="38"
                                        height="3.1"
                                        x="31.3"
                                        y="25.5"
                                        maskUnits="userSpaceOnUse"
                                    >
                                        <g filter="url(#Adobe_OpacityMaskFilter_00000163047688605744332040000012831126247848567698_)">
                                            <path
                                                d="M28.3 22.5h44v9.1h-44z"
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                            />
                                        </g>
                                    </mask>
                                    <path
                                        d="M31.8 25.5c-.3 0-.5.2-.5.5v2.1c0 .3.2.5.5.5h37c.3 0 .5-.2.5-.5V26c0-.3-.2-.5-.5-.5h-37z"
                                        opacity="0.2"
                                        mask="url(#mask-4_00000090286377100714035570000016481524734216280475_)"
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        fill="#dfdfdf"
                                    />
                                    <defs>
                                        <filter
                                            id="Adobe_OpacityMaskFilter_00000010307036223692598990000017159104033001157767_"
                                            width="44"
                                            height="9.1"
                                            x="28.3"
                                            y="22.5"
                                            filterUnits="userSpaceOnUse"
                                        >
                                            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
                                        </filter>
                                    </defs>
                                    <mask
                                        id="mask-4_00000158015441979307133120000000154517060863159701_"
                                        width="44"
                                        height="9.1"
                                        x="28.3"
                                        y="22.5"
                                        maskUnits="userSpaceOnUse"
                                    >
                                        <g filter="url(#Adobe_OpacityMaskFilter_00000010307036223692598990000017159104033001157767_)">
                                            <path
                                                d="M28.3 22.5h44v9.1h-44z"
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                            />
                                        </g>
                                    </mask>
                                    <path
                                        d="M31.8 25.5c-.3 0-.5.2-.5.5v2.1c0 .3.2.5.5.5h37c.3 0 .5-.2.5-.5V26c0-.3-.2-.5-.5-.5h-37zm37 6.1h-37c-1.9 0-3.5-1.6-3.5-3.5V26c0-1.9 1.6-3.5 3.5-3.5h37c1.9 0 3.5 1.6 3.5 3.5v2.1c0 1.9-1.6 3.5-3.5 3.5z"
                                        mask="url(#mask-4_00000158015441979307133120000000154517060863159701_)"
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        fill="#dfdfdf"
                                    />
                                </g>
                                <g transform="translate(0 11.69)">
                                    <defs>
                                        <filter
                                            id="Adobe_OpacityMaskFilter_00000080920021628927088340000006274826222926013627_"
                                            width="62"
                                            height="3.1"
                                            x="14"
                                            y="46"
                                            filterUnits="userSpaceOnUse"
                                        >
                                            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
                                        </filter>
                                    </defs>
                                    <mask
                                        id="mask-6_00000152963219809348428800000010500037731506446985_"
                                        width="62"
                                        height="3.1"
                                        x="14"
                                        y="46"
                                        maskUnits="userSpaceOnUse"
                                    >
                                        <g filter="url(#Adobe_OpacityMaskFilter_00000080920021628927088340000006274826222926013627_)">
                                            <path
                                                d="M11 43h68v9.1H11z"
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                            />
                                        </g>
                                    </mask>
                                    <path
                                        d="M14.5 46c-.3 0-.5.2-.5.5v2.1c0 .3.2.5.5.5h61c.3 0 .5-.2.5-.5v-2.1c0-.3-.2-.5-.5-.5h-61z"
                                        opacity="0.2"
                                        mask="url(#mask-6_00000152963219809348428800000010500037731506446985_)"
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        fill="#dfdfdf"
                                    />
                                    <defs>
                                        <filter
                                            id="Adobe_OpacityMaskFilter_00000153680768539428834030000011293288227791674778_"
                                            width="68"
                                            height="9.1"
                                            x="11"
                                            y="43"
                                            filterUnits="userSpaceOnUse"
                                        >
                                            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
                                        </filter>
                                    </defs>
                                    <mask
                                        id="mask-6_00000088103554301561732920000010769064187848905395_"
                                        width="68"
                                        height="9.1"
                                        x="11"
                                        y="43"
                                        maskUnits="userSpaceOnUse"
                                    >
                                        <g filter="url(#Adobe_OpacityMaskFilter_00000153680768539428834030000011293288227791674778_)">
                                            <path
                                                d="M11 43h68v9.1H11z"
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                            />
                                        </g>
                                    </mask>
                                    <path
                                        d="M14.5 46c-.3 0-.5.2-.5.5v2.1c0 .3.2.5.5.5h61c.3 0 .5-.2.5-.5v-2.1c0-.3-.2-.5-.5-.5h-61zm61 6.1h-61c-1.9 0-3.5-1.6-3.5-3.5v-2.1c0-1.9 1.6-3.5 3.5-3.5h61c1.9 0 3.5 1.6 3.5 3.5v2.1c0 1.9-1.6 3.5-3.5 3.5z"
                                        mask="url(#mask-6_00000088103554301561732920000010769064187848905395_)"
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        fill="#dfdfdf"
                                    />
                                </g>
                                <g transform="translate(0 7.709)">
                                    <defs>
                                        <filter
                                            id="Adobe_OpacityMaskFilter_00000125571099097518112600000005183656507186929028_"
                                            width="62"
                                            height="3.1"
                                            x="14"
                                            y="35.7"
                                            filterUnits="userSpaceOnUse"
                                        >
                                            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
                                        </filter>
                                    </defs>
                                    <mask
                                        id="mask-8_00000059274027982611518160000015368241025025895048_"
                                        width="62"
                                        height="3.1"
                                        x="14"
                                        y="35.7"
                                        maskUnits="userSpaceOnUse"
                                    >
                                        <g filter="url(#Adobe_OpacityMaskFilter_00000125571099097518112600000005183656507186929028_)">
                                            <path
                                                d="M11 32.7h68v9.1H11z"
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                            />
                                        </g>
                                    </mask>
                                    <path
                                        d="M14.5 35.7c-.3 0-.5.2-.5.5v2.1c0 .3.2.5.5.5h61c.3 0 .5-.2.5-.5v-2.1c0-.3-.2-.5-.5-.5h-61z"
                                        opacity="0.2"
                                        mask="url(#mask-8_00000059274027982611518160000015368241025025895048_)"
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        fill="#dfdfdf"
                                    />
                                    <defs>
                                        <filter
                                            id="Adobe_OpacityMaskFilter_00000021113185901642237070000001435716897734214321_"
                                            width="68"
                                            height="9.1"
                                            x="11"
                                            y="32.7"
                                            filterUnits="userSpaceOnUse"
                                        >
                                            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
                                        </filter>
                                    </defs>
                                    <mask
                                        id="mask-8_00000083797342377569000860000002041644119332193207_"
                                        width="68"
                                        height="9.1"
                                        x="11"
                                        y="32.7"
                                        maskUnits="userSpaceOnUse"
                                    >
                                        <g filter="url(#Adobe_OpacityMaskFilter_00000021113185901642237070000001435716897734214321_)">
                                            <path
                                                d="M11 32.7h68v9.1H11z"
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                            />
                                        </g>
                                    </mask>
                                    <path
                                        d="M14.5 35.7c-.3 0-.5.2-.5.5v2.1c0 .3.2.5.5.5h61c.3 0 .5-.2.5-.5v-2.1c0-.3-.2-.5-.5-.5h-61zm61 6.1h-61c-1.9 0-3.5-1.6-3.5-3.5v-2.1c0-1.9 1.6-3.5 3.5-3.5h61c1.9 0 3.5 1.6 3.5 3.5v2.1c0 2-1.6 3.5-3.5 3.5z"
                                        mask="url(#mask-8_00000083797342377569000860000002041644119332193207_)"
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        fill="#dfdfdf"
                                    />
                                </g>
                                <g transform="translate(0 15.671)">
                                    <defs>
                                        <filter
                                            id="Adobe_OpacityMaskFilter_00000058568490303047117200000000406629510106679225_"
                                            width="62"
                                            height="3.1"
                                            x="14"
                                            y="56.2"
                                            filterUnits="userSpaceOnUse"
                                        >
                                            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
                                        </filter>
                                    </defs>
                                    <mask
                                        id="mask-10_00000020379324814932137280000008189558164387324857_"
                                        width="62"
                                        height="3.1"
                                        x="14"
                                        y="56.2"
                                        maskUnits="userSpaceOnUse"
                                    >
                                        <g filter="url(#Adobe_OpacityMaskFilter_00000058568490303047117200000000406629510106679225_)">
                                            <path
                                                d="M11 53.2h68v9.1H11z"
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                            />
                                        </g>
                                    </mask>
                                    <path
                                        d="M14.5 56.2c-.3 0-.5.2-.5.5v2.1c0 .3.2.5.5.5h61c.3 0 .5-.2.5-.5v-2.1c0-.3-.2-.5-.5-.5h-61z"
                                        opacity="0.2"
                                        mask="url(#mask-10_00000020379324814932137280000008189558164387324857_)"
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        fill="#dfdfdf"
                                    />
                                    <defs>
                                        <filter
                                            id="Adobe_OpacityMaskFilter_00000152963176469866014890000012555217541973184134_"
                                            width="68"
                                            height="9.1"
                                            x="11"
                                            y="53.2"
                                            filterUnits="userSpaceOnUse"
                                        >
                                            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
                                        </filter>
                                    </defs>
                                    <mask
                                        id="mask-10_00000001622635009332125300000003886514968781333681_"
                                        width="68"
                                        height="9.1"
                                        x="11"
                                        y="53.2"
                                        maskUnits="userSpaceOnUse"
                                    >
                                        <g filter="url(#Adobe_OpacityMaskFilter_00000152963176469866014890000012555217541973184134_)">
                                            <path
                                                d="M11 53.2h68v9.1H11z"
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                            />
                                        </g>
                                    </mask>
                                    <path
                                        d="M14.5 56.2c-.3 0-.5.2-.5.5v2.1c0 .3.2.5.5.5h61c.3 0 .5-.2.5-.5v-2.1c0-.3-.2-.5-.5-.5h-61zm61 6.1h-61c-1.9 0-3.5-1.6-3.5-3.5v-2.1c0-1.9 1.6-3.5 3.5-3.5h61c1.9 0 3.5 1.6 3.5 3.5v2.1c0 1.9-1.6 3.5-3.5 3.5z"
                                        mask="url(#mask-10_00000001622635009332125300000003886514968781333681_)"
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        fill="#dfdfdf"
                                    />
                                </g>
                                <g>
                                    <defs>
                                        <filter
                                            id="Adobe_OpacityMaskFilter_00000073699491039032587210000008557722018730901686_"
                                            width="13.8"
                                            height="13.8"
                                            x="14"
                                            y="15.9"
                                            filterUnits="userSpaceOnUse"
                                        >
                                            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
                                        </filter>
                                    </defs>
                                    <mask
                                        id="mask-12_00000057829357564982658660000014107269922103481785_"
                                        width="13.8"
                                        height="13.8"
                                        x="14"
                                        y="15.9"
                                        maskUnits="userSpaceOnUse"
                                    >
                                        <g filter="url(#Adobe_OpacityMaskFilter_00000073699491039032587210000008557722018730901686_)">
                                            <path
                                                d="M11 12.9h19.9v19.8H11z"
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                            />
                                        </g>
                                    </mask>
                                    <path
                                        d="M14.3 15.9h-.1c-.2.1-.2.2-.2.2v13.3s0 .1.1.2.2 0 .2 0L27.8 23s.1-.1.1-.2-.1-.2-.1-.2L14.4 16c0-.1-.1-.1-.1-.1z"
                                        opacity="0.2"
                                        mask="url(#mask-12_00000057829357564982658660000014107269922103481785_)"
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        fill="#dfdfdf"
                                    />
                                    <defs>
                                        <filter
                                            id="Adobe_OpacityMaskFilter_00000073721222596508652710000018433215708281163425_"
                                            width="19.9"
                                            height="19.8"
                                            x="11"
                                            y="12.9"
                                            filterUnits="userSpaceOnUse"
                                        >
                                            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
                                        </filter>
                                    </defs>
                                    <mask
                                        id="mask-12_00000098923954263213054940000016066301327652844425_"
                                        width="19.9"
                                        height="19.8"
                                        x="11"
                                        y="12.9"
                                        maskUnits="userSpaceOnUse"
                                    >
                                        <g filter="url(#Adobe_OpacityMaskFilter_00000073721222596508652710000018433215708281163425_)">
                                            <path
                                                d="M11 12.9h19.9v19.8H11z"
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                            />
                                        </g>
                                    </mask>
                                    <path
                                        d="M14.3 15.9h-.1c-.2.1-.2.2-.2.2v13.3s0 .1.1.2.2 0 .2 0L27.8 23s.1-.1.1-.2-.1-.2-.1-.2L14.4 16c0-.1-.1-.1-.1-.1zm0 16.8c-.6 0-1.2-.2-1.7-.5-1-.6-1.6-1.6-1.6-2.8V16.1c0-1.1.6-2.2 1.6-2.8 1-.6 2.2-.6 3.2-.1l13.4 6.7c1.1.6 1.8 1.7 1.8 2.9s-.7 2.3-1.7 2.9l-13.4 6.7c-.6.2-1.1.3-1.6.3z"
                                        mask="url(#mask-12_00000098923954263213054940000016066301327652844425_)"
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        fill="#dfdfdf"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </Ic>
);

IC112Watchlist.displayName = 'IC112Watchlist';

export default IC112Watchlist;
