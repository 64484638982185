/* eslint-disable camelcase */
import React from 'react';
import get from 'lodash/get';
import propTypes from 'prop-types';
import styled from 'styled-components';
import ordinal from 'ordinal';
import {silverLining} from '../../../../common/palette';
import VisuallyHidden from '../../../../common/visually-hidden';

const Container = styled.div`
    padding: 0 7px;
    color: ${silverLining};
`;

const Header = styled.h2`
    margin: 0;
    font: var(--mui-hud-tile-header-bold);
`;

const LargeText = styled.span`
    font: var(--mui-hud-tile-score);
`;

const LargeTextBold = styled.span`
    font: var(--mui-hud-tile-score-bold);
`;

const LightText = styled.span`
    font: var(--mui-hud-tile-copy-light);
`;

const MarginTextLine = styled.p`
    margin: 7px 0 14px;
    font: var(--mui-hud-tile-copy-light);
`;

const TeamScoreLine = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const UndecoratedAbbr = styled.abbr`
    text-decoration: none;
`;

// For these series codes we specify which day we're up to (because they're basically Test Matches)
// Derived from https://foxsportsau.atlassian.net/wiki/spaces/OM/pages/257523971/Match+Centre+Templates#MatchCentreTemplates-CricketTESTseriestypes
const MULTI_DAY_MATCH_SERIES_CODES = [
    'Test',
    'TM',
    'SHEF',
    'WTEST',
    'WTM',
];

// Note that our prop names/structure here is a copy of the scoreboard API response
// This component typically expects an API response to be passed in as its props as-is
// See https://statsapi.foxsports.com.au/3.0/api/sports/cricket/matches/ODI2018-191805/scoreboard.json?userkey=A00239D3-45F6-4A0A-810C-54A347F144C2
const OR80GenSmHd = ({
    team_A = {},
    team_B = {},
    match_number,
    series = {},
    day,
    innings,
    score_summary,
    match_status_normalised,
}) => {
    const currentTeam = get(team_A, 'first_innings.is_current') || get(team_A, 'second_innings.is_current')
        ? team_A
        : team_B;
    const isSecondInnings = !!get(currentTeam, 'second_innings.is_current');
    const currentInnings = isSecondInnings ? currentTeam.second_innings : currentTeam.first_innings;
    const isPreGame = match_status_normalised === 'pre';
    const isPostGame = match_status_normalised === 'post';

    return (
        <Container className="OR80GenSmHd">
            <Header>{`${team_A.name} vs ${team_B.name}`}</Header>
            <MarginTextLine>
                {!!match_number && !!series.code && `${ordinal(match_number)} `}
                {!!series.code && (
                    <React.Fragment>
                        <UndecoratedAbbr title={series.name}>
                            {MULTI_DAY_MATCH_SERIES_CODES.includes(series.code) ? 'Test' : 'Match'}
                        </UndecoratedAbbr>
                        {': '}
                    </React.Fragment>
                )}
                {MULTI_DAY_MATCH_SERIES_CODES.includes(series.code) && !!day && `Day ${day} - `}
                {isPreGame ? 'Pre Game' : `${ordinal(innings)} Innings`}
            </MarginTextLine>
            {!isPreGame && (
                <TeamScoreLine>
                    <span>
                        <LargeTextBold>
                            <UndecoratedAbbr title={currentTeam.name}>{`${currentTeam.code} `}</UndecoratedAbbr>
                        </LargeTextBold>
                        {isSecondInnings && (
                            <React.Fragment>
                                <LargeText aria-hidden="true">{`${get(currentTeam, 'first_innings.runs')} & `}</LargeText>
                                <VisuallyHidden>{`First innings runs: ${get(currentTeam, 'first_innings.runs')}`}</VisuallyHidden>
                            </React.Fragment>
                        )}
                        <LargeTextBold aria-hidden="true">
                            {`${currentInnings.wickets}/${currentInnings.runs}${(currentInnings.is_all_out || currentInnings.is_declared || isPostGame) ? '' : '*'}`}
                        </LargeTextBold>
                        <VisuallyHidden>
                            {`Current figures: ${currentInnings.wickets} for ${currentInnings.runs}`}
                        </VisuallyHidden>
                    </span>
                    <span>
                        <LargeText>{`${currentInnings.overs} `}</LargeText>
                        <LightText>Overs</LightText>
                    </span>
                </TeamScoreLine>
            )}
            {!!score_summary && (
                <MarginTextLine>{score_summary}</MarginTextLine>
            )}
        </Container>
    );
};

OR80GenSmHd.displayName = 'OR80GenSmHd';

const inningsPropType = propTypes.shape({
    runs: propTypes.number,
    wickets: propTypes.number,
    is_all_out: propTypes.bool,
    is_declared: propTypes.bool,
    is_current: propTypes.bool,
    overs: propTypes.number,
});

const teamPropType = propTypes.shape({
    name: propTypes.string,
    code: propTypes.string,
    first_innings: inningsPropType,
    second_innings: inningsPropType,
});

OR80GenSmHd.propTypes = {
    team_A: teamPropType,
    team_B: teamPropType,
    match_number: propTypes.number,
    series: propTypes.shape({
        code: propTypes.string,
        name: propTypes.string,
    }),
    day: propTypes.number,
    innings: propTypes.number,
    score_summary: propTypes.string,
    match_status_normalised: propTypes.oneOf(['pre', 'live', 'post']),
};

export default OR80GenSmHd;
