import React from 'react';
import Ic from '..';

const IC260Switch = (args) => (
    <Ic {...args}>
        <path d="M86.129 54.457l-9.226 9.339V27.512h-8.35v36.284l-9.226-9.339-5.9 5.972 19.301 19.536 19.301-19.536zM45.411 38.132L26.109 18.596 6.808 38.132l5.9 5.972 9.227-9.324v36.269h8.349V34.78l9.227 9.324z" />
    </Ic>
);

IC260Switch.displayName = 'IC260Switch';

export default IC260Switch;
