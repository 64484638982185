import Cookies from 'js-cookie';

/**
 * Because auth0 keeps adding these nonce cookies, with many tabs open (maybe 14+)
 * the request header gets overloaded and you're faced with a 400 Bad Request.
 * This seems to mostly affect Tab Hoarders as well as QA as they may need many tabs open.
 */
export default function cleanAuth0NonceCookies() {
    Object
        .keys(Cookies.get() || {})
        .forEach((cookieName) => {
            if (cookieName.startsWith('a0.spajs.txs.')) {
                Cookies.remove(cookieName);
            }
        });
}
