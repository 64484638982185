import React from 'react';
import Ic from '..';

const IC06Play = (args) => (
    <Ic {...args}>
        <path d="M79.4 47.8L24 6.2c-.9-.6-2-.7-3-.2-.9.5-1.5 1.4-1.5 2.5v83.1c0 1.1.6 2 1.5 2.5.4.2.8.3 1.2.3.6 0 1.2-.2 1.7-.6l55.4-41.6c.7-.5 1.1-1.3 1.1-2.2s-.3-1.7-1-2.2zM25.1 86V14l48 36-48 36z" />
    </Ic>
);

IC06Play.displayName = 'IC06Play';

export default IC06Play;
