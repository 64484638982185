import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Button} from 'normalized-styled-components';
import classnames from 'classnames';

import {stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {classNameType, srcsetOptionsType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {blanc} from '../../../../common/palette';
import SrcsetImg from '../../../../common/srcset-img';
import IC31Tick from '../../ic/31-tick';

const StyledSrcsetImage = styled(SrcsetImg)`
    transition: ${transition('opacity')};
    opacity: 1;
    margin-bottom: -4px;
`;

const StyledButton = styled(Button).attrs(({isPressed}) => ({
    'type': 'button',
    'aria-pressed': !!isPressed,
}))`
    appearance: none;
    position: relative;
    border: none;
    background-color: transparent;
    padding: 0;
    width: 106px;
    /* The order of rules here is important, so the 0.4 opacity is overridden by the others */
    &[aria-pressed='false'] > ${StyledSrcsetImage} {
        opacity: 0.4;
    }

    /* stylelint-disable selector-type-case, selector-type-no-unknown, order/order */
    &:hover > ${StyledSrcsetImage},
    &:focus > ${StyledSrcsetImage} {
        opacity: 0.8;
    }

    &:active > ${StyledSrcsetImage} {
        opacity: 1;
    }
    /* stylelint-enable selector-type-case, selector-type-no-unknown, order/order */

    &:focus {
        outline: 0;
    }
`;

const Text = styled.span`
    display: inline-block;
    padding: 7px 10px 0;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    word-break: break-word;
    overflow-wrap: break-word;
    color: ${blanc};
    font: var(--ionic-body-copy-3-light);

    @supports (-webkit-line-clamp: 3) { /* Where supported, lets add nice ellipsis on overflow https://caniuse.com/#search=line-clamp */
        /* stylelint-disable-next-line value-no-vendor-prefix */
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
`;

const Checkedmark = styled.span`
    position: absolute;
    top: 4px;
    right: 4px;
    transition: ${transition('transform')};
    width: 23px;
    height: 23px;

    ${stylesWhenNot('isVisible')`
        transform: scale(0);
    `}
`;

const BA33LogoBtn = React.forwardRef(({
    'aria-label': assistiveLabel,
    className,
    isPressed,
    children,
    srcsetOptions,
    ...htmlAttributes
}, ref) => (
    <StyledButton
        {...htmlAttributes}
        className={classnames('BA33LogoBtn', className)}
        ref={ref}
        title={assistiveLabel}
        aria-label={assistiveLabel}
        isPressed={isPressed}
    >
        <StyledSrcsetImage
            alt=""
            sizes="106px"
            width="106px"
            height="106px"
            srcsetOptions={srcsetOptions}
        />
        {!!children && (
            <Text>{children}</Text>
        )}
        <Checkedmark isVisible={isPressed} aria-hidden={true}>
            <IC31Tick />
        </Checkedmark>
    </StyledButton>
));

/* eslint-disable quote-props */
BA33LogoBtn.propTypes = {
    /** Assistive label required for screen readers due to graphical only representation of content */
    'aria-label': propTypes.string,
    /** additional CSS classnames to be applied */
    className: classNameType,
    /** Logo srcset options */
    srcsetOptions: srcsetOptionsType.isRequired,
    /** aria-pressed attribute describes a toggle button */
    isPressed: propTypes.oneOf([true, false, 'mixed']),
    /** Subtitle for the logo */
    children: propTypes.node,
};
/* eslint-enable quote-props */

BA33LogoBtn.displayName = 'BA33LogoBtn';

export default BA33LogoBtn;
