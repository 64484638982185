import React from 'react';
import propTypes from 'prop-types';
import styled, {ThemeProvider} from 'styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';
import {Aside} from 'normalized-styled-components';
import noop from 'lodash/noop';

import {mediaQuery, stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {srcsetOptionsType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {SCREEN_1280_DESKTOP, SCREEN_415_PHABLET, SMALLEST_SUPPORTED_WIDTH} from '../../../../common/screen-sizes';
import {transition} from '../../../../common/animations';
import {panther, vader} from '../../../../common/palette';
import GA05BgImg from '../../../atoms/ga/05-bg-img';
import AppDockHeader from './components/app-dock-header';

const AppDock = styled(Aside)`
    position: relative;
    transition: ${transition('transform', 'box-shadow')};
    border-bottom-left-radius: 3px;
    background-color: ${vader};
    width: ${SMALLEST_SUPPORTED_WIDTH}px;
    height: calc(100vh - 69px);
    overflow: hidden;
    font: var(--ionic-body-copy-3);

    ${stylesWhen('isActive')`
        transform: translateX(-100%);
        box-shadow: 7px -7px 21px ${rgba(panther, 0.5)};
    `}

    ${mediaQuery({minWidthPx: SCREEN_415_PHABLET})`
        width: 375px;
    `}
`;

const InnerContainer = styled.div.attrs(({selectedIndex = 0, style}) => ({
    style: {
        ...style,
        transform: `translateX(${100 * -selectedIndex}%)`,
    },
}))`
    box-sizing: border-box;
    display: grid;
    grid-auto-columns: 100%;
    transition: ${transition('transform')};
    height: 100%;
`;

const Z_INDEX_BELOW_OTHER_APP_DOCK_ITEMS = -1;

const BgImgWrapper = styled.div`
    position: absolute;
    top: 67px;
    z-index: ${Z_INDEX_BELOW_OTHER_APP_DOCK_ITEMS};
    width: 100%;
    height: inherit;

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        top: 0;
    `}
`;

const StyledGA05BgImg = styled(GA05BgImg)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 688px;
`;

const FadeImage = styled.div`
    position: absolute;
    top: 688px;
    background: linear-gradient(to top, ${vader} 90%, ${rgba(vader, 0.6)} 49%, ${rgba(vader, 0)} 13%);
    width: 100%;
    height: 92px;
`;

const OR31AppDock = ({
    children,
    isActive,
    className,
    isAuthenticated,
    bgSrcsetOptions,

    // header
    dockNavItems,
    avatarUrl,
    onProfileClick = noop,
    profileName,
    ctaMessage,
    signInCtaCopy = 'Sign In',
    signUpCtaCopy = 'Sign Up',
    signUpUrl = '/',
    onClickLogin = noop,

    selectedIndex,
}) => {
    const cards = React.Children.toArray(children).filter(Boolean);

    return (
        <AppDock
            className={classnames('OR31AppDock', className)}
            isActive={isActive}
            aria-hidden={!isActive}
        >
            <BgImgWrapper>
                <StyledGA05BgImg srcsetOptions={bgSrcsetOptions} />
                <FadeImage />
            </BgImgWrapper>
            <AppDockHeader
                dockNavItems={dockNavItems}
                isAuthenticated={isAuthenticated}

                avatarUrl={avatarUrl}
                onProfileClick={onProfileClick}
                profileName={profileName}

                ctaMessage={ctaMessage}
                signInCtaCopy={signInCtaCopy}
                signUpCtaCopy={signUpCtaCopy}
                signUpUrl={signUpUrl}
                onSignInClick={onClickLogin}
            />

            <ThemeProvider theme={{isAppDock: true}}>
                <InnerContainer
                    hidden={!isActive || cards.length < 1} // Prevent children from being tabbed while preserving parent's transition
                    selectedIndex={selectedIndex}
                >
                    {cards.map((child, index) => !!child && React.cloneElement(child, {
                        isFirst: index === 0,
                        isCurrent: isActive && (index === selectedIndex),
                    }))}
                </InnerContainer>
            </ThemeProvider>
        </AppDock>
    );
};

OR31AppDock.propTypes = {
    /** Optional additional class(es) */
    className: classNameType,
    /** A stack of app dock cards (OR108AppDockCard) from bottom to top */
    children: propTypes.node,
    /** Is the app dock currently active (i.e. open, visible and clickable)? */
    isActive: propTypes.bool,
    /** User is authenticated and logged in */
    isAuthenticated: propTypes.bool,
    /** Srcset options for the background image */
    bgSrcsetOptions: srcsetOptionsType,

    // ==== HEADER SECTION === //
    // DOCK NAV
    /** `NM02DockNav` prop: **menuItems** */
    dockNavItems: propTypes.any,
    // USER PROFILE
    /** User profile avatar */
    avatarUrl: propTypes.string,
    /** User profile action when clicked */
    onProfileClick: propTypes.func,
    /** User profile name */
    profileName: propTypes.string,
    // SIGN UP CTA SECTION
    /** Shows Sign up CTA label for new users */
    ctaMessage: propTypes.string,
    /** Sign In CTA copy */
    signInCtaCopy: propTypes.string,
    /** Sign Up CTA copy */
    signUpCtaCopy: propTypes.string,
    /** URL for sign-up */
    signUpUrl: propTypes.string,
    /** Action on clicking the sign in button in Sign up CTA section */
    onClickLogin: propTypes.func,

    /** The index of the selected card */
    selectedIndex: propTypes.number,
};

OR31AppDock.displayName = 'OR31AppDock';

export default OR31AppDock;
