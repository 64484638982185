import bacon from 'baconjs';
import invoke from 'lodash/invoke';

import {loadStyle, loadScript, isServer, isBrowser} from '@fsa-streamotion/browser-utils';

const USER_INTERACTION_THROTTLE_MS = 3000;
const USER_INACTIVE_TIMEOUT_MS = 2 * 60 * 1000; // 2 minutes is when you're 'inactive'

export const KBOT_STAGES = {
    OFFERS: 'OFFERS',
    PERSONAL_DETAILS: 'PERSONAL_DETAILS', // Do we need this anymore? The Personal Details stuff is now hosted on Auth0
    VERIFICATION: 'VERIFICATION',
    PAYMENT: 'PAYMENT',
};

const KBOT_SETTINGS = {
    production: {
        eswScriptLocation: 'https://martian.my.salesforce.com/embeddedservice/5.0/esw.min.js',
        initEswLocation: 'https://service.force.com',
        cssLocation: '',
        salesforceLink: 'https://martian.my.salesforce.com',
        forceLink: 'https://help.kayosports.com.au',

        [KBOT_STAGES.OFFERS]: {
            chatId: '00D7F000006kE7Q',
            pageOrBotName: 'Offer_Page',

            optionsBaseLiveAgentContentURL: 'https://c.la1-c1-hnd.salesforceliveagent.com/content',
            optionsDeploymentId: '5727F000000gkcj',
            optionsButtonId: '5737F0000004ldE',
            optionsBaseLiveAgentURL: 'https://d.la1-c1-hnd.salesforceliveagent.com/chat',
            optionsEswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I7F000000CbqyUAC_16c1d6d8f52',
            optionsIsOfflineSupportEnabled: false,
        },
        [KBOT_STAGES.PERSONAL_DETAILS]: {
            chatId: '00D7F000006kE7Q',
            pageOrBotName: 'Checkout_Stage_1',

            optionsBaseLiveAgentContentURL: 'https://c.la1-c1-hnd.salesforceliveagent.com/content',
            optionsDeploymentId: '5727F000000gkcj',
            optionsButtonId: '5737F0000004lcz',
            optionsBaseLiveAgentURL: 'https://d.la1-c1-hnd.salesforceliveagent.com/chat',
            optionsEswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I7F000000CbqvUAC_16c1d6c2f4e',
            optionsIsOfflineSupportEnabled: false,
        },
        [KBOT_STAGES.VERIFICATION]: {
            chatId: '00D7F000006kE7Q',
            pageOrBotName: 'Checkout_Stage_2',

            optionsBaseLiveAgentContentURL: 'https://c.la1-c1-hnd.salesforceliveagent.com/content',
            optionsDeploymentId: '5727F000000gkcj',
            optionsButtonId: '5737F0000004ld4',
            optionsBaseLiveAgentURL: 'https://d.la1-c1-hnd.salesforceliveagent.com/chat',
            optionsEswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I7F000000CbqwUAC_16c1d6ccd7d',
            optionsIsOfflineSupportEnabled: false,
        },
        [KBOT_STAGES.PAYMENT]: {
            chatId: '00D7F000006kE7Q',
            pageOrBotName: 'Checkout_Stage_3',

            optionsBaseLiveAgentContentURL: 'https://c.la1-c1-hnd.salesforceliveagent.com/content',
            optionsDeploymentId: '5727F000000gkcj',
            optionsButtonId: '5737F0000004ld9',
            optionsBaseLiveAgentURL: 'https://d.la1-c1-hnd.salesforceliveagent.com/chat',
            optionsEswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I7F000000CbqxUAC_16c1d6d3508',
            optionsIsOfflineSupportEnabled: false,
        },
    },
    staging: {
        eswScriptLocation: 'https://martian--uat.my.salesforce.com/embeddedservice/5.0/esw.min.js',
        initEswLocation: 'https://service.force.com',
        cssLocation: '',
        salesforceLink: 'https://martian--uat.my.salesforce.com/',
        forceLink: 'https://uat-martian.cs58.force.com',

        [KBOT_STAGES.OFFERS]: {
            chatId: '00D0l0000009qtV',
            pageOrBotName: 'Offer_Page',

            optionsBaseLiveAgentContentURL: 'https://c.la1-c1cs-hnd.salesforceliveagent.com/content',
            optionsDeploymentId: '5727F000000gkcj',
            optionsButtonId: '5730l0000008OZz',
            optionsBaseLiveAgentURL: 'https://d.la1-c1cs-hnd.salesforceliveagent.com/chat',
            optionsEswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I0l0000004CDAEA2_16b0c6e2bff',
            optionsIsOfflineSupportEnabled: false,
        },
        [KBOT_STAGES.PERSONAL_DETAILS]: {
            chatId: '00D0l0000009qtV',
            pageOrBotName: 'Checkout_Stage_1',

            optionsBaseLiveAgentContentURL: 'https://c.la1-c1cs-hnd.salesforceliveagent.com/content',
            optionsDeploymentId: '5727F000000gkcj',
            optionsButtonId: '5730l0000008OZk',
            optionsBaseLiveAgentURL: 'https://d.la1-c1cs-hnd.salesforceliveagent.com/chat',
            optionsEswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I0l0000004CCvEAM_16b0c6bef5b',
            optionsIsOfflineSupportEnabled: false,
        },
        [KBOT_STAGES.VERIFICATION]: {
            chatId: '00D0l0000009qtV',
            pageOrBotName: 'Checkout_Stage_2',

            optionsBaseLiveAgentContentURL: 'https://c.la1-c1cs-hnd.salesforceliveagent.com/content',
            optionsDeploymentId: '5727F000000gkcj',
            optionsButtonId: '5730l0000008OZp',
            optionsBaseLiveAgentURL: 'https://d.la1-c1cs-hnd.salesforceliveagent.com/chat',
            optionsEswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I0l0000004CD0EAM_16b0c6cb46f',
            optionsIsOfflineSupportEnabled: false,
        },
        [KBOT_STAGES.PAYMENT]: {
            chatId: '00D0l0000009qtV',
            pageOrBotName: 'Checkout_Stage_3',

            optionsBaseLiveAgentContentURL: 'https://c.la1-c1cs-hnd.salesforceliveagent.com/content',
            optionsDeploymentId: '5727F000000gkcj',
            optionsButtonId: '5730l0000008OZu',
            optionsBaseLiveAgentURL: 'https://d.la1-c1cs-hnd.salesforceliveagent.com/chat',
            optionsEswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I0l0000004CD5EAM_16b0c6d79d8',
            optionsIsOfflineSupportEnabled: false,
        },
    },
};

let kbot$;
let kbotStage;

function initEsw(gslbBaseUrl, platformEnv) {
    window.embedded_svc.settings.defaultMinimizedText = 'Ask Help Bot'; // (Defaults to Chat with an Expert)
    window.embedded_svc.settings.disabledMinimizedText = 'Help Bot Offline'; // (Defaults to Agent Offline)

    window.embedded_svc.settings.displayHelpButton = true;
    window.embedded_svc.settings.language = '';
    window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
    window.embedded_svc.settings.entryFeature = 'LiveAgent';

    window.embedded_svc.init(
        KBOT_SETTINGS[platformEnv].salesforceLink,
        KBOT_SETTINGS[platformEnv].forceLink,
        gslbBaseUrl,
        KBOT_SETTINGS[platformEnv][kbotStage].chatId,
        KBOT_SETTINGS[platformEnv][kbotStage].pageOrBotName,
        {
            baseLiveAgentContentURL: KBOT_SETTINGS[platformEnv][kbotStage].optionsBaseLiveAgentContentURL,
            deploymentId:            KBOT_SETTINGS[platformEnv][kbotStage].optionsDeploymentId,
            buttonId:                KBOT_SETTINGS[platformEnv][kbotStage].optionsButtonId,
            baseLiveAgentURL:        KBOT_SETTINGS[platformEnv][kbotStage].optionsBaseLiveAgentURL,
            eswLiveAgentDevName:     KBOT_SETTINGS[platformEnv][kbotStage].optionsEswLiveAgentDevName,
            isOfflineSupportEnabled: KBOT_SETTINGS[platformEnv][kbotStage].optionsIsOfflineSupportEnabled,
        }
    );
}

export default function getKbotStream({platformEnv, brand}) {
    if (isServer() || brand !== 'kayo') {
        return bacon.later(0, undefined);
    }

    if (kbot$) {
        return kbot$.startWith();
    }

    const isUserInteracting$ = bacon.mergeAll(
        bacon.fromEvent(document.body, 'mousemove'),
        bacon.fromEvent(document.body, 'keydown'),
        bacon.fromEvent(document.body, 'click'),
        bacon.fromEvent(window, 'scroll'),
        bacon.fromEvent(window, 'touchstart')
    )
        .throttle(USER_INTERACTION_THROTTLE_MS)
        .map(true)
        .startWith(true);

    const isUserActivelyInteracting$ = isUserInteracting$
        .flatMapLatest((() => bacon.later(USER_INACTIVE_TIMEOUT_MS, false)));

    kbot$ = isUserActivelyInteracting$
        .not()
        .filter(Boolean)
        .take(1)
        .doAction(() => {
            // If we have a style, load a style.
            if (KBOT_SETTINGS[platformEnv].cssLocation) {
                loadStyle({url: KBOT_SETTINGS[platformEnv].cssLocation});
            }

            if (window.embedded_svc) {
                initEsw(KBOT_SETTINGS[platformEnv].initEswLocation, platformEnv);
            } else {
                loadScript({url: KBOT_SETTINGS[platformEnv].eswScriptLocation})
                    .then(() => {
                        initEsw(null, platformEnv);
                    });
            }
        })
        .startWith();

    return kbot$;
}

export function setKbotStage(stage) {
    if (isBrowser()) {
        kbotStage = KBOT_STAGES[stage];
    }
}

export function closeKbot() {
    if (isBrowser()) {
        invoke(document.querySelector('.embeddedServiceSidebar'), 'remove');
    }
}
