import React from 'react';
import {midnight} from '../../../../common/palette';
import Ic from '..';

const IC154MultiAdd3 = (args) => (
    <Ic {...args}>
        <circle cx="50" cy="50" r="42.4" />
        <path fill={midnight} d="M49.5 64c-6.1 0-9.7-3.4-10.7-4.9L42 55c1.2 1.4 4.3 3.6 7.2 3.6 2.9 0 5.1-1.5 5.1-4.1 0-2.6-2-3.9-4.7-3.9H47v-4.8h2.6c2.3 0 3.9-1.1 3.9-3.4S51.6 39 49.3 39c-2.3 0-4.6.9-7 2.8l-2.5-4.3c2-1.8 4.7-3.6 10.1-3.6 5.3 0 10.2 2.9 10.2 7.8 0 3.9-2.2 5.6-4.2 6.4v.1c2.5.6 5.1 3 5.1 6.8-.1 5.8-5.5 9-11.5 9z" />
    </Ic>
);

IC154MultiAdd3.displayName = 'IC154MultiAdd3';

export default IC154MultiAdd3;
