import React from 'react';
import Ic from '..';

const IC177MatchCentre = (args) => (
    <Ic {...args}>
        <path d="M66 57.1h2.9v-13h-5.3v2.1H66zM34.2 46.3c1.3 0 2.1.7 2.1 1.8s-.7 2-2 3.1l-4.4 4 .4 1.9h9.6v-2.4h-6.1l2.7-2.4c1-.9 2.7-2.3 2.7-4.6s-1.8-3.9-4.7-3.9c-3 0-4.6 1.8-4.7 2.2l1.3 2c.6-.8 1.8-1.7 3.1-1.7zM48.9 54.3h3.5v3.4h-3.5zM49 44.1h3.5v3.4H49z" />
        <path d="M81.9 29.6H18.1c-.4 0-.7.3-.7.7v40.2c0 .4.3.7.7.7h12.8v5.2h2.8v-5.2h32.7v5.2h2.8v-5.2H82c.4 0 .7-.3.7-.7V30.4c0-.4-.4-.8-.8-.8zm-.7 40.3H18.8V31.1h62.4v38.8zM39.4 23.5h21.3v2.4H39.4z" />
    </Ic>
);

IC177MatchCentre.displayName = 'IC177MatchCentre';

export default IC177MatchCentre;
