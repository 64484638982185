type Args = {
    prefix?: string;
    location: string;
    propFullName: string;
    actualType: string;
    componentName: string;
    expected: string;
};

/**
 * Generate a `propTypes` validation error.
 *
 * @param prefix        - an optional prefix
 * @param location      - the location of the value being valiated (e.g. `"prop"`)
 * @param propFullName  - the full name of the prop
 * @param actualType    - the actual type of the prop value
 * @param componentName - the name of the component
 * @param expected      - a description of the expectation
 * @returns a `propTypes` validation error
 */
export default function generateError({
    prefix = '',
    location,
    propFullName,
    actualType,
    componentName,
    expected,
}: Args): Error {
    return new Error(
        `${prefix}Invalid ${location} \`${propFullName}\` of type \`${actualType}\` supplied to \`${componentName}\`. Expected ${expected}`
    );
}
