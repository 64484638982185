import React from 'react';
import Ic from '..';

const IC49Open = (args) => (
    <Ic {...args}>
        <path d="M88.516 45.598H53.911V10.993h-8.306v34.605H11v8.306h34.605v34.605h8.306V53.904h34.605z" />
    </Ic>
);

IC49Open.displayName = 'IC49Open';

export default IC49Open;
