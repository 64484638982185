import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import Ic from '..';

const IC272RemoveSelected = ({
    color,
    ...args
}) => (
    <Ic color={color} {...args}>
        <path d="M69.928 31.024 68.19 75.916c0 3.636-2.916 6.584-6.513 6.584H38.704c-3.597 0-6.513-2.948-6.513-6.584l-1.739-44.892h39.476zm-7.89 8.243c-1.265-.565-2.805-.11-3.44 1.016L47.362 60.21l-5.499-5.096c-.98-.908-2.603-.938-3.623-.065-1.021.872-1.054 2.316-.073 3.224l10.524 9.751 14.488-25.696c.635-1.126.124-2.496-1.14-3.06zm8.41-19.042H29.934a2.256 2.256 0 0 0-2.244 2.267v4.878h45v-4.878c0-1.252-1.004-2.267-2.242-2.267z" />
        <path stroke={color} strokeWidth="2.622" d="M42.908 18.387v-3.632c0-.97.777-1.755 1.735-1.755h9.792c.958 0 1.735.786 1.735 1.755v3.632" />
    </Ic>
);

IC272RemoveSelected.propTypes = {
    /** The icon's primary color */
    color: colorType,
};

IC272RemoveSelected.displayName = 'IC272RemoveSelected';

export default IC272RemoveSelected;
