import React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {midnight} from '../../../../common/palette';

const StyledContainer = styled.div`
    box-sizing: border-box;
    background-color: ${rgba(midnight, 0.8)};
    padding: 21px 7px;
    min-height: 100%;
    object-fit: contain;
    backdrop-filter: blur(7px);
`;

const GA72HudBg = ({className, children}) => (
    <StyledContainer className={classnames('GA72HudBg', className)}>
        {children}
    </StyledContainer>
);

GA72HudBg.displayName = 'GA72HudBg';

GA72HudBg.propTypes = {
    /** Children components inside */
    children: propTypes.node,
    /** Additional class(es) */
    className: classNameType,
};

export default GA72HudBg;
