// https://app.zeplin.io/project/5d6daadba2daef5e3d4a6e06/screen/5d6df094c42b5e5c7cf859a7

// Core colors
export const ares = '#1787f7';
export const blanc = '#fafafa';
export const panther = '#000000';

// Dark/Natural/Light
export const vader = '#050a19';
export const knight = '#050f23';
export const white = '#ffffff';

// Typography
export const moonstruck = '#e6ebf0';
export const jaws = '#c8d2dc';
export const akela = '#dadbe0';
export const eli = '#5b5d68';
export const selene = '#181d2b';
export const silverLining = '#d2d2db';

// Gradient
export const poseidon = '#050f23';

// Contextual
export const blade = '#d41a3e';

export const active = '#0f559e';

/**
 * @deprecated Removed as part of branding release. Use vader for now.
 */
export const kong = vader;

/** Binge Palette */
export default {
    ares,
    blanc,
    panther,
    vader,
    knight,
    white,
    moonstruck,
    jaws,
    akela,
    eli,
    selene,
    silverLining,
    poseidon,
    blade,
    active,
    kong,
};
