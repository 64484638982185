import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import classNames from 'classnames';
import {classNameType} from '@fsa-streamotion/custom-prop-types';
import {white} from '../../../../common/palette';

const PanelBg = styled.section`
    border-radius: 6px;
    background-color: ${white};
`;

const GA153PanelBg = ({
    children,
    className,
    ...htmlAttributes
}) => (
    <PanelBg
        className={classNames('GA153PanelBg', className)}
        {...htmlAttributes}
    >
        {children}
    </PanelBg>
);

GA153PanelBg.propTypes = {
    /** Child to accept */
    children: propTypes.node.isRequired,
    /** Additional CSS class(es) */
    className: classNameType,
};

GA153PanelBg.displayName = 'GA153PanelBg';

export default GA153PanelBg;
