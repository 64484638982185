import React from 'react';

import Ic from '..';

const IC03NotOff = (args) => (
    <Ic {...args}>
        <g fillOpacity=".15" stroke="currentColor" strokeWidth="3">
            <path fillRule="nonzero" d="M50 12.5c.203 0 .385.087.518.222a.79.79 0 0 1 .228.555v6.643c6.157.209 11.68 2.994 15.558 7.32a22.452 22.452 0 0 1 5.737 15.03v17.77c0 .573.245 1.148.634 1.542l5.627 5.705c.7.71 1.084 1.657 1.084 2.66v1.742c0 1.04-.415 1.983-1.088 2.666a3.643 3.643 0 0 1-2.6 1.094H24.302a3.643 3.643 0 0 1-2.6-1.094 3.785 3.785 0 0 1-1.09-2.666v-1.743c0-1.002.385-1.949 1.085-2.659l5.627-5.705c.39-.394.634-.97.634-1.543V42.27c0-5.78 2.174-11.055 5.737-15.03 3.877-4.325 9.401-7.11 15.558-7.319v-6.643a.79.79 0 0 1 .228-.555A.728.728 0 0 1 50 12.5z" />
            <path d="M38.937 75.769c3.257.007 8.072.016 12.7.021h.86l1.274.002h5.048c1.135 0 2.13-.003 2.92-.005l.148-.002-.02.297c-.21 2.414-1.306 4.587-2.897 6.279l-.22.228a11.579 11.579 0 0 1-8.244 3.442c-3.22 0-6.134-1.316-8.244-3.442a11.765 11.765 0 0 1-3.325-6.82z" />
        </g>
        <path d="M72.584 13.93 30.047 88.172a1.512 1.512 0 0 1-2.072.556 1.532 1.532 0 0 1-.56-2.086L69.954 12.4a1.512 1.512 0 0 1 2.072-.555c.728.423.978 1.355.56 2.086z" />
    </Ic>
);

IC03NotOff.displayName = 'IC03NotOff';

export default IC03NotOff;
