import React from 'react';

import Ic from '..';

const IC112Watchlist = (args) => (
    <Ic {...args}>
        <path d="M62 94.5c-.9 0-1.9-.4-2.6-1L41.2 76.6 23.1 93.5c-1.1 1-2.7 1.3-4.1.7-1.4-.6-2.3-2-2.3-3.5v-71c0-1.5 1.2-2.8 2.8-2.8h19.1v5.6H22.2v64.3l17.1-15.9c1.1-1 2.7-1 3.8 0l17.2 16V50.2h5.6v40.5c0 1.5-.9 2.9-2.3 3.5-.5.2-1 .3-1.6.3z" />
        <path d="M63.7 5.6C53 5.6 44.3 14.3 44.3 25S53 44.4 63.7 44.4 83.2 35.7 83.2 25 74.4 5.6 63.7 5.6zm8.6 20.7h-7.2v7.3h-2.8v-7.3h-7.2v-2.6h7.2v-7.3h2.8v7.3h7.2v2.6z" />
    </Ic>
);

IC112Watchlist.displayName = 'IC112Watchlist';

export default IC112Watchlist;
