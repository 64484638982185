import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {silverLining, slate, woodsmoke} from '../../../../common/palette';

const Tile = styled.section`
    box-sizing: border-box;
    border-radius: 4px;
    background-color: ${woodsmoke};
    padding: 14px;
    width: 100%;
    color: ${slate};
    font: var(--mui-body-copy-3);
`;

const StyledHeading = styled.h4`
    margin: 0 0 7px;
    color: ${silverLining};
    font: var(--mui-header-8-bold);
`;

const GA89Tile = ({
    children,
    className,
    title,
}) => (
    <Tile className={classnames('GA89Tile', className)}>
        {!!title && (
            <StyledHeading>{title}</StyledHeading>
        )}

        {children}
    </Tile>
);

GA89Tile.displayName = 'GA89Tile';

GA89Tile.propTypes = {
    /** Chidren components */
    children: propTypes.node,
    /** Additional class(es) */
    className: classNameType,
    /** Title of the tile */
    title: propTypes.node,
};

export default GA89Tile;
