import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import {kayoGreen} from '../../../../common/palette';
import Ic from '..';

const IC50Success = ({color = kayoGreen, ...otherProps}) => (
    <Ic {...{...otherProps, color}}>
        <path d="M31.8,71.9L91.7,12l8.3,8.1L31.8,88L0,56.3l8.3-8.1L31.8,71.9z" />
    </Ic>
);

IC50Success.displayName = 'IC50Success';

IC50Success.propTypes = {
    color: colorType,
};

export default IC50Success;
