import React, {useState} from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import classnames from 'classnames';
import {rgba} from 'polished';

import {stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {IC50Success, IC135Cross} from '../../../atoms/ic';
import {cobalt, ink, white} from '../../../../common/palette';
import {Button, Section} from '../../../../common/normalized-styled-components';
import {transition} from '../../../../common/animations';

const SuccessTile = styled(Section)`
    box-sizing: border-box;
    position: relative;
    transform-origin: top center;
    transition: ${transition('height', 'padding', 'transform')};
    border-radius: 4px;
    background-color: ${white};
    padding: 21px 35px 47px;
    width: 100%;
    text-align: center;

    ${stylesWhenNot('isVisible')`
        transform: scaleY(0);
        padding: 0;
        height: 0;
    `}
`;

const Heading = styled.h3`
    margin: 7px 9px;
    text-align: center;
    color: ${rgba(ink, 0.8)};
    font: var(--quicksilver-success-header);
`;

const Byline = styled.p`
    margin: 0;
    text-align: center;
    color: ${rgba(ink, 0.7)};
    font: var(--quicksilver-success-text);
`;

const StyledButton = styled(Button).attrs({
    'type': 'button',
    'aria-label': 'Close Message',
})`
    appearance: none;
    box-sizing: content-box;
    position: absolute;
    top: 0;
    left: 0;
    transition: ${transition('color')};
    border: 0;
    background: transparent;
    padding: 16px;
    width: 16px;
    color: ${rgba(ink, 0.7)};

    &:hover,
    &:focus {
        outline: 0;
        color: ${ink};
    }
`;

const IconWrapper = styled.div`
    margin-right: auto;
    margin-left: auto;
    width: 100px;
    height: 100px;
`;

/**
 * A component that displays a success tile with an optional dismiss button.
 *
 * @param {string} className - Additional class(es) to apply to the component.
 * @param {string|React.ReactNode} heading='Success!' - The heading text or React node.
 * @param {React.ReactNode} icon=<IC50Success color={cobalt} /> - The icon to use for the banner.
 * @param {boolean} isDismissable=true - Whether or not the tile can be dismissed.
 * @param {string} message - The message to display.
 * @param {Object} htmlAttributes - Additional attributes to apply to the HTML element.
 * @returns {React.ReactNode} - The rendered component.
 */
const TM22SuccessTile = ({
    className,
    heading = 'Success!',
    icon = <IC50Success color={cobalt} size="100px" />,
    isDismissable = true,
    message,
    ...htmlAttributes
}) => {
    const [isVisible, setIsVisible] = useState(true);

    return (
        <SuccessTile
            {...htmlAttributes}
            className={classnames('TM22SuccessTile', className)}
            isVisible={isVisible}
        >
            {!!isDismissable && (
                <StyledButton onClick={() => void setIsVisible(false)}>
                    <IC135Cross size="21px" color={ink} />
                </StyledButton>
            )}
            <IconWrapper>
                {icon}
            </IconWrapper>
            <Heading>
                {heading}
            </Heading>
            <Byline>
                {message}
            </Byline>
        </SuccessTile>
    );
};

TM22SuccessTile.propTypes = {
    /** Additional class(es) */
    className: classNameType,
    /** Heading text */
    heading: propTypes.node,
    /** Icon to use for banner */
    icon: propTypes.node,
    /** Whether tile can be closed or not */
    isDismissable: propTypes.bool,
    /** Message to be displayed */
    message: propTypes.string,
};

TM22SuccessTile.displayName = 'TM22SuccessTile';

export default TM22SuccessTile;
