import propTypes from 'prop-types';

/**
 * Validate against aria-current attributes.
 *
 * @see https://www.w3.org/TR/wai-aria-1.1/#aria-current
 */
const ariaCurrentType = propTypes.oneOf([
    'date',
    'location',
    'page',
    'step',
    'time',
    'true',
    true,
]);

export default ariaCurrentType;
