import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import propTypes from 'prop-types';
import {rgba} from 'polished';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {ink} from '../../../../common/palette';
import {getShortTermTypeText} from '../../../../common/price-term';

const Wrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: ${rgba(ink, 0.03)};
    padding: 9px;
    min-width: 91px;
    height: 26px;
`;

const SubTotalContainer = styled.div`
    color: ${rgba(ink, 0.8)};
    font: var(--quicksilver-body-copy-4-medium);
`;

const StyledSpan = styled.span`
    font: var(--quicksilver-body-copy-4-light);
`;

/**
 * GM16SubTotal
 *  - Component to display SubTotal.
 *
 * @see {@link https://zpl.io/DlrpP1v}
 */

const GM16SubTotal = ({
    className,
    billingPrice,
    billingPeriod = 'month',
    currencyPrefix = '$',
    ...htmlAttributes
}) => {
    const planPeriodLabel = getShortTermTypeText(billingPeriod);

    return (
        <Wrapper
            {...htmlAttributes}
            className={classnames('GM16SubTotal', className)}
        >
            <SubTotalContainer>
                <StyledSpan>
                    {currencyPrefix}
                </StyledSpan>
                {billingPrice}
                <StyledSpan>
                    {planPeriodLabel}
                </StyledSpan>
            </SubTotalContainer>
        </Wrapper>
    );
};

GM16SubTotal.propTypes = {
    /** Additional class name(s) */
    className: classNameType,
    /** Currency prefix */
    currencyPrefix: propTypes.oneOf(['$']),
    /** Billing price */
    billingPrice: propTypes.number,
    /** Billing period eg. month */
    billingPeriod: propTypes.oneOf(['annual', 'month', 'week', 'day', 'annually', 'monthly', 'weekly', 'daily']),
};

GM16SubTotal.displayName = 'GM16SubTotal';

export default GM16SubTotal;
