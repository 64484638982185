import React from 'react';
import {colorType} from '@fsa-streamotion/custom-prop-types';
import {panther} from '../../../../common/palette';
import Ic from '..';

const IC17Plus = ({
    color,
    secondaryColor = panther,
    ...otherProps
}) => (
    <Ic {...otherProps}>
        <g transform="translate(7 7)">
            <path fill={color} d="M42.7 0C19.117 0 0 19.117 0 42.7c0 23.583 19.117 42.7 42.7 42.7 23.583 0 42.7-19.117 42.7-42.7C85.392 19.12 66.28.008 42.7 0z" />
            <path fill={secondaryColor} d="M62.748 38.5L46.9 38.5 46.9 23.366 38.5 23.366 38.5 38.5 21.952 38.5 21.952 46.9 38.5 46.9 38.5 63.56 38.514 63.56 46.9 63.56 46.9 46.9 62.748 46.9z" />
        </g>
    </Ic>
);

IC17Plus.propTypes = {
    /** Icon main color */
    color: colorType,
    /** Icon secondary color */
    secondaryColor: colorType,
};

IC17Plus.displayName = 'IC17Plus';

export default IC17Plus;
