import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {SCREEN_1280_DESKTOP, SCREEN_415_PHABLET} from '../../../../common/screen-sizes';
import {smoke, slate} from '../../../../common/palette';

import BA22EditBtn from '../../../atoms/ba/22-edit-btn';

const GiftCardButtonWrapper = styled.div`
    margin: 0 auto;
    width: 206px;

    ${mediaQuery({minWidthPx: SCREEN_415_PHABLET})`
        margin: 0;
        width: 300px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        width: 300px;
    `}
`;

const StyledP = styled.p`
    margin: 0;
    text-decoration: underline;
    color: ${slate};

    &:hover {
        text-decoration: none;
    }
`;

const RemainingBalance = styled.p`
    color: ${smoke};
    font: var(--nucleus-body-copy-3);
`;

const Collapsed = ({
    balance,
    buttonLabel,
    onClickExpand,
    giftCardTitle,
}) => (
    <GiftCardButtonWrapper>
        {giftCardTitle}
        <BA22EditBtn
            onClick={onClickExpand}
        >
            <StyledP>{buttonLabel}</StyledP>
        </BA22EditBtn>
        {!!balance && <RemainingBalance>{`Gift card applied: ${balance} Remaining`}</RemainingBalance>}
    </GiftCardButtonWrapper>
);

Collapsed.propTypes = {
    onClickExpand: propTypes.func,
    balance: propTypes.string,
    buttonLabel: propTypes.string,
    giftCardTitle: propTypes.node,
};

Collapsed.displayName = 'Collapsed';

export default Collapsed;
