import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import {indigo, ink} from '../../../../common/palette';

import Ic from '..';

const IC131Power = ({
    color = indigo,
    secondaryColor = ink,
    ...args
}) => (
    <Ic {...args}>
        <g fill={color} fillRule="nonzero" transform="translate(6.4 7)">
            <circle cx="42.621" cy="42.621" r="42.621" fill={secondaryColor} />
            <path d="m46.813 39.756-8.385.014V16.503h8.385z" />
            <path d="M42.62 69.101a27.53 27.53 0 0 1-19.647-8.188c-10.813-10.96-10.813-28.573 0-39.533l2.977 2.949c-9.21 9.315-9.21 24.306 0 33.621a23.448 23.448 0 0 0 33.342 0c9.21-9.315 9.21-24.306 0-33.621l2.976-2.949c10.813 10.96 10.813 28.573 0 39.533A27.53 27.53 0 0 1 42.621 69.1z" />
        </g>
    </Ic>
);

IC131Power.propTypes = {
    /** The icon's primary color */
    color: colorType,
    /** The icon's secondary color */
    secondaryColor: colorType,
};

IC131Power.displayName = 'IC131Power';

export default IC131Power;
