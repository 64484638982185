import React from 'react';
import bacon from 'baconjs';
import isEmpty from 'lodash/isEmpty';
import matchesProperty from 'lodash/matchesProperty';

import {BrandedOR117GiftCheckout} from '../../utils/branded-components';

import getValidateGiftCard from '../../../../todo-move-to-widgets-common/streams/endpoints/gifts/validate';

export default function getStepGiftCardStream({
    brand,
    platformEnv,
    user,
    startSubscription$,
    paymentErrorMessages$ = bacon.constant(undefined),
}) {
    // Redeeming only happens on the subscribe call. So this `isRedeeming$` is the
    // user actually redeeming - i.e. clicking the `Subscribe` button.
    // We separately have a button labelled `Redeem` which only calls validate.
    const isRedeeming$ = startSubscription$
        .not()
        .and(paymentErrorMessages$.map(isEmpty))
        .startWith(false);

    const expandGiftCardBus = new bacon.Bus();
    const removeGiftCodeBus = new bacon.Bus();
    const submitGiftCodeBus = new bacon.Bus();
    const giftCodeBus = new bacon.Bus();
    const giftPinBus = new bacon.Bus();

    const thirtyCharacterCode$ = giftCodeBus.filter(matchesProperty('length', 30));
    const fourCharacterPin$ = giftPinBus.filter(matchesProperty('length', 4));

    // We validate the gift card when the user clicks the `Redeem` button. Note: this
    // isn't actually redeeming, merely validating. Redeeming only happens on the subscribe
    // call which ends the journey - i.e. clicking `Start Subscription`.
    const validateGiftCard$ = bacon.combineTemplate({
        giftCardCode: thirtyCharacterCode$,
        giftCardPin: fourCharacterPin$,
    })
        .sampledBy(submitGiftCodeBus) // only validate when the user clicks `Redeem
        .filter(({giftCardCode, giftCardPin}) => (
            giftCardCode && giftCardPin
        )) // do we have a thirty character code and four character PIN when this button was clicked
        .map(async ({giftCardCode, giftCardPin}) => ({
            accessToken: await user.getAccessToken(),
            giftCard: giftCardCode,
            giftCardPin,
            platformEnv,
        }))
        .flatMapLatest(bacon.fromPromise)
        .flatMapLatest(getValidateGiftCard)
        .toProperty();

    const cardValidatedBalance$ = validateGiftCard$
        .map('.balance')
        .startWith();

    const giftCardErrorMsg$ = validateGiftCard$.errors().mapError('.message');

    const isValidGiftCard$ = bacon.mergeAll(
        giftCodeBus.map(false),
        giftPinBus.map(false),
        validateGiftCard$.skipErrors().map(true),
    )
        .startWith(false);

    const isCollapsed$ = bacon
        .mergeAll(
            expandGiftCardBus.map(false),
            removeGiftCodeBus.map(true)
        )
        .startWith(true)
        .skipDuplicates();

    const giftCode$ = bacon
        .mergeAll(
            giftCodeBus,
            removeGiftCodeBus.map(''),
        )
        .toProperty('')
        .skipDuplicates();

    const giftPin$ = bacon
        .mergeAll(
            giftPinBus,
            removeGiftCodeBus.map(''),
        )
        .toProperty('')
        .skipDuplicates();

    const errorMessage$ = bacon
        .mergeAll(
            giftCardErrorMsg$,
            removeGiftCodeBus.map(''),
            submitGiftCodeBus.map(''),
            giftCode$.map(''),
            giftPin$.map('')
        )
        .startWith('');

    const hasValidated$ = bacon
        .mergeAll(
            cardValidatedBalance$.map(Boolean),
            removeGiftCodeBus.map(false)
        )
        .startWith(false)
        .skipDuplicates();

    const isValidating$ = bacon.mergeAll(
        submitGiftCodeBus.awaiting(
            bacon
                .mergeAll(cardValidatedBalance$, errorMessage$, removeGiftCodeBus)
                .filter(Boolean)
        ).startWith(),
        isRedeeming$,
    )
        .startWith(false);

    // Passed up to the checkout to disable / enable subscription button
    // Prevents bad / incomplete gift card data being used in subscription call
    const shouldDisableSubscribeButton$ = bacon.combineTemplate({
        isCollapsed: isCollapsed$,
        isValidating: isValidating$.skipErrors(),
        isValidGiftCard: isValidGiftCard$,
        isEmptyGiftCode: giftCode$.map(isEmpty),
        isEmptyGiftPin: giftPin$.map(isEmpty),
        isEmptyErrorMessage: errorMessage$.map(isEmpty),
    }).map(({isCollapsed, isValidating, isValidGiftCard, isEmptyGiftCode, isEmptyGiftPin, isEmptyErrorMessage}) => (
        isCollapsed // collapsed state means we're in a step unrelated to gift cards, so they can progress
        || isValidGiftCard // if we have a validated gift card, the user is able to progress
        || (
            !isValidating // if there is no pending validation and all the GC fields are empty, then they can progress past gift cards
                && isEmptyGiftCode
                && isEmptyGiftPin
                && isEmptyErrorMessage
        )
    ))
        .not()
        .startWith(false);

    const reactElement$ = bacon
        .combineTemplate({
            giftCode: giftCode$,
            giftPin: giftPin$,
            onChangeGiftCode: (giftCode) => void giftCodeBus.push(giftCode),
            onChangeGiftPin: (giftPin) => void giftPinBus.push(giftPin),
            cardValidatedBalance: cardValidatedBalance$,
            errorMessage: errorMessage$,
            hasValidated: hasValidated$,
            isCollapsed: isCollapsed$,
            isValidating: isValidating$,
            isValidGiftCard: isValidGiftCard$,
            onSubmitGiftCode: () => void submitGiftCodeBus.push(),
            onClickExpand: () => void expandGiftCardBus.push(),
            onClickRemoveGiftCode: () => void removeGiftCodeBus.push(),
            giftCardTitle: 'Hubbl Gift Card',
            giftCardDescription: 'Gift Card will automatically apply to your next-billed Hubbl subscription.',
        })
        .map((props) => (
            <BrandedOR117GiftCheckout
                brand={brand}
                {...props}
            />
        ));

    return bacon.combineTemplate({
        reactElement: reactElement$,
        giftCode: giftCode$,
        giftPin: giftPin$,
        shouldDisableSubscribeButton: shouldDisableSubscribeButton$,
    });
}
