import React from 'react';
import Ic from '..';

const IC02Sea = (args) => (
    <Ic {...args}>
        <path d="M46.463 20.369c10.433-.006 19.841 6.323 23.836 16.034 3.995 9.712 1.79 20.892-5.587 28.325-7.377 7.434-18.473 9.656-28.11 5.63-9.638-4.024-15.918-13.504-15.913-24.018.023-14.334 11.549-25.948 25.774-25.97m0-8.369C32.673 12 20.24 20.372 14.963 33.21c-5.274 12.84-2.355 27.618 7.399 37.442 9.753 9.824 24.42 12.76 37.159 7.438 12.739-5.322 21.042-17.853 21.036-31.749 0-9.11-3.592-17.847-9.986-24.287A33.95 33.95 0 0046.463 12zm31.975 59.976a41.37 41.37 0 01-5.841 5.941l10.05 10.127 5.868-5.97-10.077-10.098z" />
    </Ic>
);

IC02Sea.displayName = 'IC02Sea';

export default IC02Sea;
