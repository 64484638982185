import bacon from 'baconjs';

import {isBrowser, isServer} from '@fsa-streamotion/browser-utils';

import {getFeed, stopFeed} from './request-manager';
import {DEFAULT_CACHE_TTL_MS, DEFAULT_REQUEST_TIMEOUT_MS} from './constants';

const defaultResponseValidator = (superAgentObject) => superAgentObject.responseOk;

/**
 * Get a stream for an API endpoint
 *
 * @param  {Object}   options                   (see below)
 * @param  {Number}   options.cacheTtlMs        How long this result should stay valid in client cache default 3,000ms server / 30,000ms client.
 * @param  {Number}   options.freqMs            How frequently (in milliseconds) to poll
 * @param  {Number}   options.requestTimeoutMs  How long (in milliseconds) to wait before abandoning request
 * @param  {String}   options.url               URL to fetch
 * @param  {Function} options.validateResponse  Superagent response object validator that returns <code>true</code> or <code>false</code> if the response is valid or not valid respectively
 *
 * @returns {EventStream}                        Bacon EventStream (will return errors, mapError to catch if required)
 */
export function getApiFromBinder({
    cacheTtlMs = DEFAULT_CACHE_TTL_MS,
    freqMs,
    requestTimeoutMs = DEFAULT_REQUEST_TIMEOUT_MS,
    url,
    validateResponse = defaultResponseValidator,
}) {
    return bacon.fromBinder((sink) => {
        const onResponse = function (superAgentObject) {
            if (validateResponse(superAgentObject)) {
                sink(superAgentObject);
            } else {
                sink(new bacon.Error(superAgentObject));
            }
        };

        getFeed({url, onResponse, freqMs, cacheTtlMs, requestTimeoutMs});

        return () => void stopFeed(url, onResponse);
    });
}

/**
 * Get a stream for an API endpoint, and on connection issues
 * retry the request x times.
 *
 * After failing all retries, the final error will be released to the
 * stream as a value.
 *
 * @param  {Object}      options                  (see below)
 * @param  {Number}      options.cacheTtlMs       How long this result should stay valid in client cache default 3,000ms server / 30,000ms client.
 * @param  {Number}      options.delayMs          Retry interval on error (in milliseconds) - e.g. 5 seconds between unsuccessful attempts
 * @param  {Number}      options.freqMs           How frequently (in milliseconds) to poll
 * @param  {Number}      options.requestTimeoutMs How long (in milliseconds) to wait before abandoning request
 * @param  {Number}      options.retries          How many retries (not including initial) attempts
 * @param  {String}      options.url              URL to fetch
 * @param  {Function}    options.validateResponse Superagent response object validator that returns <code>true</code> or <code>false</code> if the response is valid or not valid respectively
 *
 * @returns {EventStream}                          Bacon EventStream (Returns errors AS values in stream)
 */
export function getApiFromRetryWithError({
    cacheTtlMs = DEFAULT_CACHE_TTL_MS,
    delayMs = 5 * 1000,
    freqMs = 0,
    requestTimeoutMs = DEFAULT_REQUEST_TIMEOUT_MS,
    retries = isBrowser() ? 4 : -1,
    url,
    validateResponse = defaultResponseValidator,
}) {
    return bacon.retry({
        source: () => getApiFromBinder({url, freqMs, cacheTtlMs, validateResponse, requestTimeoutMs}),
        retries,
        isRetryable: () => {
            if (isServer()) {
                return false;
            }

            // Only retry if we're not using a frequency.
            if (freqMs) {
                return false;
            } else {
                // On retry - switch to null (or leave as null) to not obtain cached 404 results.
                freqMs = null; // eslint-disable-line no-param-reassign

                return true;
            }
        },

        // on failure / error in the stream, delay for x
        // long before going again.
        delay: () => delayMs,
    })
        .mapError((error) => error);
}
