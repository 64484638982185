import React, {Component} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import IntersectionObserver from 'inteobs';
import classnames from 'classnames';
import invoke from 'lodash/invoke';

import {mediaQuery, stylesWhen, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {coal, ironStone} from '../../../../common/palette';
import {SCREEN_TABLET, SCREEN_LG_DESKTOP, SCREEN_REALLY_LG_DESKTOP} from '../../../../common/screen-sizes';

const HINT_AT_ROWS_ABOVE_BUFFER = 18;
const ROW_HEIGHT = 110; // not great that we need to hardcode this, but its currently based on BA04TeamTi with a nav indicator
const SUNDRY_ROW_HEIGHT = 87; // Sundry padding top (27px,) row height (49px,) and padding bottom (11px)

const getExtraRowsBuffer = ({numRows}) => numRows > 1 ? HINT_AT_ROWS_ABOVE_BUFFER : 0;
const getHeightOfExpandedMenu = ({numRows}) => (numRows * ROW_HEIGHT) + SUNDRY_ROW_HEIGHT;
const getHeightOfCondensedMenu = ({numRows}) => ROW_HEIGHT + getExtraRowsBuffer({numRows}) + SUNDRY_ROW_HEIGHT;

// The transitions here are longer than the standard ones, very intentionally
const customTransition = (...props) => props
    .map((prop) => `${prop} 0.3s ease-in`)
    .join(', ');

const StyledNav = styled.nav`
    border-top: 1px solid ${ironStone};
    height: ${getHeightOfCondensedMenu}px; /* This is the component actually holding DOM height for layout purposes (e.g. during sticky and expanded states) */
`;

const StickyContainer = styled.div`
    transition: ${customTransition('height', 'margin-bottom')};
    outline: 0;
    padding-bottom: 14px;
    width: 100%;
    overflow-y: hidden;

    ${stylesWhen('isExpanded')`
        margin-bottom: ${getHeightOfCondensedMenu}px;
        transform: translate3d(0, 0, 0); /* Render on top of page content in stacking context */
    `}
`;

const Expander = styled.div`
    transition: ${customTransition('transform')};
    background-color: ${ironStone};
    width: 100%;
    overflow-y: hidden;

    ${stylesWhenNot('isExpanded')`
        transform: translateY(${({numRows}) => (getExtraRowsBuffer({numRows}) + SUNDRY_ROW_HEIGHT) - getHeightOfExpandedMenu({numRows: numRows - 1})}px);
    `}
`;

const MenuRowsContainer = styled.ol`
    margin: 0;
    background-color: ${coal};
    padding: 0 5%;
    list-style: none;
`;

const MenuRow = styled.li`
    padding-top: 7px;
`;

// https://developers.google.com/web/updates/2017/09/sticky-headers
// When we scroll past this we go into sticky mode
const Sentinel = styled.div`
    position: absolute;
    left: 0;
    margin-top: -1px;
    width: 100%;
    /* observer must have at least 1px width and height to work on edge */
    height: 1px;
`;

const SundryContainer = styled.div`
    display: grid;
    align-items: center;
    margin: 0 auto;
    padding: 27px 5% 11px;
    max-width: ${SCREEN_LG_DESKTOP}px;
    min-height: 49px;

    ${stylesWhen('hasBettingSponsor')`
        ${mediaQuery({minWidthPx: SCREEN_TABLET})`
            grid-template-columns: 1fr auto;
            grid-gap: 32px;
            justify-content: center;
        `}

        ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
            grid-template-columns: auto 1fr auto;
            grid-gap: 77px;

            /* mimic the first cell, for spacing -_- */
            &::before {
                content: '';
            }
        `}
    `}
`;

const BettingSelectorWrapper = styled.div`
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    overflow: hidden;
    text-align: right;

    ${mediaQuery({minWidthPx: SCREEN_TABLET})`
        position: unset;
        width: 93px;
        height: unset;
        overflow: unset;
        clip: unset;
    `}

    ${mediaQuery({minWidthPx: SCREEN_REALLY_LG_DESKTOP})`
        text-align: left;
    `}
`;

export default class OR125FixtureControls extends Component {
    static displayName = 'OR125FixtureControls';

    static propTypes = {
        /** The carousels to render as part of the nav */
        children: propTypes.node.isRequired,
        /** Additional class name(s) to apply to the root component */
        className: classNameType,
        /** Whether to be initially expanded or not */
        isInitiallyExpanded: propTypes.bool,
        /** round selector element (eie. date-selector) */
        roundSelector: propTypes.node.isRequired,
        /** betting sponsor element (shows up on right side corner of round selector) */
        bettingSponsor: propTypes.node,
    };

    state = {
        isExpanded: this.props.isInitiallyExpanded,
    };

    componentDidMount() {
        this.onKeyUp = ({key}) => {
            if (this.menu && key === 'Tab') {
                if (document.activeElement === this.menu || this.menu.contains(document.activeElement)) {
                    this.expand();
                } else {
                    this.contract();
                }
            }
        };

        // Intersection observer to make menu stick on scroll
        this.intersectionObserver = new IntersectionObserver(([topSentinel]) => { // eslint-disable-line compat/compat
            if (topSentinel.intersectionRatio === 0) {
                this.setState({isExpanded: false});
            }
        });

        this.intersectionObserver.observe(this.topSentinel);

        // Observer to see when a user tabs into/out of the menu
        document.addEventListener('keyup', this.onKeyUp);
    }

    componentWillUnmount() {
        this.intersectionObserver.disconnect();
        document.removeEventListener('keyup', this.onKeyUp);
    }

    expand = () => void this.setState({isExpanded: true});
    contract = () => void this.setState({isExpanded: false});

    handleKeyDown = (e) => {
        const childrenArray = [...this.menu.childNodes];
        const currentRowIndex = childrenArray.findIndex((node) => node.contains(document.activeElement));
        const isArrowDown = ['ArrowDown', 'Down'].includes(e.key);
        const isArrowUp = ['ArrowUp', 'Up'].includes(e.key);

        if (isArrowDown || isArrowUp) {
            e.preventDefault();

            if (isArrowDown && childrenArray.length - 1 > currentRowIndex) {
                invoke(childrenArray[currentRowIndex + 1].querySelector('a, button'), 'focus');
            } else if (isArrowUp && currentRowIndex > 0) {
                invoke(childrenArray[currentRowIndex - 1].querySelector('a, button'), 'focus');
            }
        }
    };

    render() {
        const {children, className, bettingSponsor, roundSelector, ...htmlAttributes} = this.props;
        const numRows = React.Children.count(children);

        return (
            <StyledNav
                {...htmlAttributes}
                className={classnames('OR125FixtureControls', className)}
                numRows={numRows}
                onKeyDown={this.handleKeyDown}
            >
                <Sentinel ref={(el) => { this.topSentinel = el; }} />
                <StickyContainer
                    isExpanded={this.state.isExpanded}
                    numRows={numRows}
                    tabIndex={-1} // This section is focusable in Firefox, so prevent that
                >
                    <Expander
                        isExpanded={this.state.isExpanded}
                        numRows={numRows}
                    >
                        <MenuRowsContainer
                            ref={(el) => { this.menu = el; }}
                            onMouseOver={this.expand}
                            onFocus={this.expand}
                            onMouseOut={this.contract}
                            onBlur={this.contract}
                        >
                            {React.Children.map(children, (child) => (
                                <MenuRow key={child.key}>
                                    {child}
                                </MenuRow>
                            ))}
                        </MenuRowsContainer>
                        <SundryContainer hasBettingSponsor={!!bettingSponsor}>
                            {roundSelector}
                            {!!bettingSponsor && (
                                <BettingSelectorWrapper>
                                    {bettingSponsor}
                                </BettingSelectorWrapper>
                            )}
                        </SundryContainer>
                    </Expander>
                </StickyContainer>
            </StyledNav>
        );
    }
}
