import React from 'react';
import styled from 'styled-components';
import {kayoGreen} from '../../../../common/deprecated-mui-palette';

const StyledSpan = styled.span`
    display: block;
    margin: 0;
    border: 0;
    border-radius: 4px;
    background-color: ${kayoGreen};
    width: 114px;
    height: 4px;
`;

const GA04NavInd = () => (
    <StyledSpan />
);

GA04NavInd.displayName = 'GA04NavInd';

export default GA04NavInd;
