import React from 'react';
import {rgba} from 'polished';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Section, Footer} from 'normalized-styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {palette as infinityPalette, screenSizes} from '@fsa-streamotion/streamotion-web-infinity';
import {palette as ionicPalette} from '@fsa-streamotion/streamotion-web-ionic';
import {palette as muiPalette} from '@fsa-streamotion/streamotion-web-mui';
import {palette as nucleusPalette} from '@fsa-streamotion/streamotion-web-nucleus';

import {Paragraph} from '../../../widgets/common/styled-elements';
import {stylesWhenBinge, stylesWhenFlash, stylesWhenKayo, stylesWhenLifestyle} from '../../../utils/styles-when-brand';
import {BrandedBA01BtnPr, BrandedBA22EditBtn, BrandedSuccessIcon, BrandedIC103Loading} from '../../../utils/branded-components';

const {SCREEN_1280_DESKTOP, SCREEN_1920_DESKTOP, SCREEN_768_TABLET} = screenSizes;

const {fog, flash, white} = infinityPalette;
const {ares, blanc} = ionicPalette;
const {coal, kayoGreen, white: muiWhite} = muiPalette;
const {lifestyle} = nucleusPalette;

const StyledSection = styled(Section)`
    box-sizing: border-box;
    position: relative;
    top: 71px;
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;
    text-align: center;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        top: 113px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        top: 167px;
    `}
`;

const StyledBrandedSuccessIcon = styled(BrandedSuccessIcon)`
    margin-bottom: 25px;
    width: 100px;
    height: 100px;

    ${stylesWhenBinge`
        color: ${ares};
    `}

    ${stylesWhenFlash`
        color: ${flash};
    `}

    ${stylesWhenKayo`
        color: ${kayoGreen};
    `}

    ${stylesWhenLifestyle`
        color: ${lifestyle};
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 140px;
        height: 140px;
        margin-bottom: 32px;
    `}
`;

const Heading = styled.div`
    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        margin-bottom: 21px;
    `}

    ${stylesWhenBinge`
        color: ${blanc};
        font: var(--ionic-header-4-bold);
    `}

    ${stylesWhenFlash`
        color: ${white};
        font: var(--infinity-header-4-bold);
    `}

    ${stylesWhenKayo`
        color: ${muiWhite};
        font: var(--mui-header-4-bold);
    `}

    ${stylesWhenLifestyle`
        color: ${blanc};
        font: var(--nucleus-header-4-bold);
    `}
`;

const MainText = styled.p`
    margin-bottom: 28px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        margin-bottom: 35px;
    `}

    ${stylesWhenBinge`
        color: ${blanc};
        font: var(--ionic-body-copy-2);
    `}

    ${stylesWhenFlash`
        color: ${fog};
        font: var(--infinity-body-copy-2);
    `}

    ${stylesWhenKayo`
        color: ${muiWhite};
        font: var(--mui-body-copy-2);
    `}

    ${stylesWhenLifestyle`
        color: ${blanc};
        font: var(--nucleus-body-copy-2);
    `}
`;

const StyledText = styled.span`
    display: block;
    margin: 8px 0 14px;
    word-wrap: break-word;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        margin: 10px 0 21px;
    `}
`;

const ContextualHelp = styled(Paragraph)`
    margin-bottom: 42px;
    color: ${rgba(fog, 0.7)};

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        margin-bottom: 56px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        margin-bottom: 77px;
    `}
`;

const StyledSpan = styled.span`
    display: block;
    text-align: center;
`;

const StyledFooter = styled(Footer)`
    margin: 0 auto;
    width: 250px;
    color: ${white};

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 400px;
    `}
`;

const CheckoutSuccess = ({
    brand,
    redirectCheckoutSuccessLink,
    isConfirmationEventTrackingComplete,
    heading,
    userEmail,
    myAccountUrl,
    ...htmlAttributes
}) => (
    <StyledSection
        {...htmlAttributes}
        className="checkout-success"
    >
        <StyledBrandedSuccessIcon brand={brand} />

        <Heading brand={brand}>
            {heading}
        </Heading>

        <MainText brand={brand}>
            <StyledText>You are now logged in using this email</StyledText>
            <StyledText>{userEmail}</StyledText>
        </MainText>

        <ContextualHelp brand={brand}>
            Wrong email? To change your email address visit:
            <BrandedBA22EditBtn
                brand={brand}
                href={myAccountUrl}
                target="_blank"
            >
                <StyledSpan>{myAccountUrl}</StyledSpan>
            </BrandedBA22EditBtn>
        </ContextualHelp>

        <StyledFooter>
            <BrandedBA01BtnPr
                brand={brand}
                href={redirectCheckoutSuccessLink}
                isBlock={true}
                disabled={!isConfirmationEventTrackingComplete}
            >
                {isConfirmationEventTrackingComplete
                    ? 'Get Started'
                    // Kayo only styling, color prop is not used on ionic or infinity IC103
                    : <BrandedIC103Loading brand={brand} size="30px" color={coal} /> }
            </BrandedBA01BtnPr>
        </StyledFooter>
    </StyledSection>
);

CheckoutSuccess.propTypes = {
    brand: propTypes.string,
    redirectCheckoutSuccessLink: propTypes.string,
    isConfirmationEventTrackingComplete: propTypes.bool,
    heading: propTypes.string,
    userEmail: propTypes.string,
    myAccountUrl: propTypes.string,
};

CheckoutSuccess.displayName = 'CheckoutSuccess';

export default CheckoutSuccess;
