import React from 'react';
import Ic from '..';

const IC180ContextualBackRoku = (args) => (
    <Ic {...args}>
        <path d="M48.929 8C25.772 8 7 26.772 7 49.929c0 23.158 18.772 41.929 41.929 41.929 23.157 0 41.929-18.771 41.929-41.93C90.858 26.773 72.086 8 48.928 8m0 5.5c20.09 0 36.43 16.342 36.43 36.429 0 20.088-16.34 36.43-36.43 36.43-20.086 0-36.429-16.342-36.429-36.43 0-20.087 16.343-36.43 36.43-36.43" />
        <path fillRule="nonzero" d="m27.815 50.542 16.177-18.754 6.289 5.43-11.386 13.2L50.073 62.8l-6.164 5.568z" />
        <path fillRule="nonzero" d="m46.647 50.835 4.048 4.742h19.253v-9.912H50.86z" />
    </Ic>
);

IC180ContextualBackRoku.displayName = 'IC180ContextualBackRoku';

export default IC180ContextualBackRoku;
