import type {PropType} from './types.js';

type TypeName =
    | 'string'
    | 'number'
    | 'bigint'
    | 'boolean'
    | 'symbol'
    | 'undefined'
    | 'object'
    | 'function'
    | 'null'
    | 'array'
    | 'date'
    | 'regexp';

export default function getTypeName(propValue: PropType): TypeName {
    if (propValue === null || propValue === undefined) {
        return `${propValue}`;
    }

    const propType = typeof propValue;

    if (propType === 'object') {
        if (Array.isArray(propValue)) {
            return 'array';
        }

        if (propValue instanceof Date) {
            return 'date';
        }

        if (propValue instanceof RegExp) {
            return 'regexp';
        }
    }

    return propType;
}
