import React from 'react';
import Ic from '..';

const IC237Watchlist = (args) => (
    <Ic {...args}>
        <g transform="translate(39.953 17.916)">
            <path opacity=".35" d="M3.506 3.01a.488.488 0 0 0-.489.488v2.119c0 .269.218.489.489.489H40.54c.27 0 .49-.22.49-.489v-2.12a.49.49 0 0 0-.49-.487H3.506z" />
            <path d="M3.506 3.01a.488.488 0 0 0-.489.488v2.119c0 .269.218.489.489.489H40.54c.27 0 .49-.22.49-.489v-2.12a.49.49 0 0 0-.49-.487H3.506zM40.54 9.116H3.506A3.506 3.506 0 0 1 0 5.617v-2.12A3.505 3.505 0 0 1 3.506 0H40.54a3.507 3.507 0 0 1 3.508 3.498v2.119a3.507 3.507 0 0 1-3.508 3.499z" />
        </g>
        <g transform="translate(39.953 31.227)">
            <path opacity=".35" d="M3.506 3.01a.488.488 0 0 0-.489.488v2.119c0 .269.218.488.489.488H40.54a.49.49 0 0 0 .49-.488v-2.12a.49.49 0 0 0-.49-.487H3.506z" />
            <path d="M3.506 3.01a.488.488 0 0 0-.489.488v2.119c0 .269.218.488.489.488H40.54a.49.49 0 0 0 .49-.488v-2.12a.49.49 0 0 0-.49-.487H3.506zM40.54 9.115H3.506A3.505 3.505 0 0 1 0 5.617v-2.12A3.505 3.505 0 0 1 3.506 0H40.54a3.507 3.507 0 0 1 3.508 3.498v2.119a3.507 3.507 0 0 1-3.508 3.498z" />
        </g>
        <g transform="translate(16 59.666)">
            <path opacity=".35" d="M3.506 3.01a.488.488 0 0 0-.489.488v2.119c0 .269.218.488.489.488h60.987a.49.49 0 0 0 .49-.488v-2.12a.49.49 0 0 0-.49-.487H3.506z" />
            <path d="M3.506 3.01a.488.488 0 0 0-.489.488v2.119c0 .269.218.488.489.488h60.987a.49.49 0 0 0 .49-.488v-2.12a.49.49 0 0 0-.49-.487H3.506zm60.987 6.105H3.506A3.505 3.505 0 0 1 0 5.617v-2.12A3.505 3.505 0 0 1 3.506 0h60.987A3.506 3.506 0 0 1 68 3.498v2.119a3.506 3.506 0 0 1-3.507 3.498z" />
        </g>
        <g transform="translate(16 45.446)">
            <path opacity=".35" d="M3.506 3.01c-.27 0-.489.22-.489.489v2.12c0 .268.218.487.489.487h60.987a.49.49 0 0 0 .49-.488v-2.12a.49.49 0 0 0-.49-.488H3.506z" />
            <path d="M3.506 3.01c-.27 0-.489.22-.489.489v2.12c0 .268.218.487.489.487h60.987a.49.49 0 0 0 .49-.488v-2.12a.49.49 0 0 0-.49-.488H3.506zm60.987 6.106H3.506A3.505 3.505 0 0 1 0 5.618v-2.12A3.506 3.506 0 0 1 3.506 0h60.987A3.507 3.507 0 0 1 68 3.499v2.12a3.507 3.507 0 0 1-3.507 3.497z" />
        </g>
        <g transform="translate(16 73.885)">
            <path opacity=".35" d="M3.506 3.01c-.27 0-.489.22-.489.489v2.12c0 .268.218.487.489.487h60.987a.49.49 0 0 0 .49-.488v-2.12a.49.49 0 0 0-.49-.488H3.506z" />
            <path d="M3.506 3.01c-.27 0-.489.22-.489.489v2.12c0 .268.218.487.489.487h60.987a.49.49 0 0 0 .49-.488v-2.12a.49.49 0 0 0-.49-.488H3.506zm60.987 6.106H3.506A3.505 3.505 0 0 1 0 5.618v-2.12A3.506 3.506 0 0 1 3.506 0h60.987A3.507 3.507 0 0 1 68 3.499v2.12a3.507 3.507 0 0 1-3.507 3.497z" />
        </g>
        <g transform="translate(16 17.915)">
            <path opacity=".35" d="M3.241 3.012a.212.212 0 0 0-.115.034c-.11.066-.11.162-.11.193v13.35c0 .03 0 .127.11.192.114.068.197.022.224.006l13.4-6.68a.207.207 0 0 0 .11-.193c0-.137-.091-.185-.12-.201L3.38 3.055c-.036-.019-.083-.043-.139-.043z" />
            <path d="M3.241 3.012a.212.212 0 0 0-.115.034c-.11.066-.11.162-.11.193v13.35c0 .03 0 .127.11.192.114.068.197.022.224.006l13.4-6.68a.207.207 0 0 0 .11-.193c0-.137-.091-.185-.12-.201L3.38 3.055c-.036-.019-.083-.043-.139-.043zm.01 16.817c-.58 0-1.16-.156-1.682-.47a3.209 3.209 0 0 1-1.57-2.77V3.238c0-1.144.587-2.18 1.57-2.77a3.228 3.228 0 0 1 3.19-.092l13.359 6.659c1.097.578 1.76 1.674 1.76 2.878a3.225 3.225 0 0 1-1.76 2.876l-13.39 6.675a3.17 3.17 0 0 1-1.477.364z" />
        </g>
    </Ic>
);

IC237Watchlist.displayName = 'IC237Watchlist';

export default IC237Watchlist;
