import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {SCREEN_DESKTOP} from '../../../../../common/screen-sizes';

const TeamName = styled.figcaption`
    display: none;
    font: var(--mui-body-copy-2);

    ${mediaQuery({minWidthPx: SCREEN_DESKTOP})`
        display: inline-block;
    `}
`;

const TeamCode = styled.abbr`
    margin: 0 14px;
    text-decoration: none;
    line-height: 1;
`;

const Name = ({
    code,
    name,
}) => (
    <TeamName aria-hidden="true">
        <TeamCode title={name}>{code}</TeamCode>
    </TeamName>
);

Name.displayName = 'Name';

Name.propTypes = {
    /** Team code (three-letter acronym) */
    code: propTypes.string,
    /** Team name */
    name: propTypes.string,
};

export default Name;
