import React, {cloneElement} from 'react';
import styled, {css} from 'styled-components';
import propTypes from 'prop-types';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import EMPTY_IMAGE_SRC from '../../../../common/empty-image-src';

const baseCssToReplaceWithAsOperatorInStyledComponentsV4 = css`
    display: block;
    position: relative;
    transform: translateZ(0);
    width: 100%;
`;

const NonInteractive = styled.div`
    ${baseCssToReplaceWithAsOperatorInStyledComponentsV4}
`;

const StyledButton = styled(NonInteractive.withComponent('button'))`
    ${baseCssToReplaceWithAsOperatorInStyledComponentsV4}

    appearance: none;
    border: 0;
    background: transparent;
    padding: 0;

    &:focus {
        outline: none;
    }
`;

const StyledLink = styled(NonInteractive.withComponent('a'))`
    ${baseCssToReplaceWithAsOperatorInStyledComponentsV4}

    text-decoration: inherit;
    color: inherit;

    &:focus {
        outline: none;
    }
`;

const ProgressBarContainer = styled.span`
    position: absolute;
    bottom: -14px;
    left: 0;
    width: 100%;
`;

class TM21VideoTile extends React.Component {
    static displayName = 'TM21VideoTile';

    static propTypes = {
        card: propTypes.node,
        className: classNameType,
        href: propTypes.string,
        isBeingLazy: propTypes.bool,
        label: propTypes.string,
        lazySrc: propTypes.string,
        onClick: propTypes.func,
        progressBar: propTypes.node,
        innerRef: propTypes.oneOfType([propTypes.object, propTypes.func]),
    };

    static defaultProps = {
        isBeingLazy: false,
        lazySrc: EMPTY_IMAGE_SRC,
    };

    state = {
        isActive: false,
    };

    activate = () => void this.setState({isActive: true});
    deactivate = () => void this.setState({isActive: false});

    render() {
        const {innerRef, card, className, href, isBeingLazy, label, lazySrc, onClick, progressBar, ...htmlAttributes} = this.props;
        const Cta = getCtaType(href, onClick);

        return (
            <Cta
                {...htmlAttributes}
                ref={innerRef}
                aria-label={label}
                className={classnames('TM21VideoTile', className)}
                href={href}
                onBlur={this.deactivate}
                onClick={onClick}
                onFocus={this.activate}
                onMouseEnter={this.activate}
                onMouseLeave={this.deactivate}
            >
                {!!progressBar && (
                    <ProgressBarContainer>{progressBar}</ProgressBarContainer>
                )}
                {cloneElement(
                    card,
                    {isActive: this.state.isActive, isBeingLazy, lazySrc, srcsetOptionsLazyLoadPlaceholder: {1: lazySrc}}
                )}
            </Cta>
        );
    }
}

function getCtaType(href, onClick) {
    if (href) {
        return StyledLink;
    } else if (onClick) {
        return StyledButton;
    } else {
        return NonInteractive;
    }
}

const ForwardedTM21VideoTile = React.forwardRef((props, ref) => <TM21VideoTile innerRef={ref} {...props} />);

ForwardedTM21VideoTile.displayName = 'TM21VideoTile';

export default ForwardedTM21VideoTile;
