import React from 'react';
import get from 'lodash/get';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType, srcsetOptionsType} from '@fsa-streamotion/custom-prop-types';

import {A, Button, Img} from '../../../../common/normalized-styled-components';
import SrcsetSource from '../../../../common/srcset-source';
import {tabIndexType} from '../../../../common/custom-proptypes';
import EMPTY_IMAGE_SRC from '../../../../common/empty-image-src';
import {transition} from '../../../../common/animations';
import {white, black, fog} from '../../../../common/palette';
import LazyImageSwitcher from '../../../../common/lazy-image-switcher';

import {
    SCREEN_768_TABLET,
    SCREEN_1024_DESKTOP,
    SCREEN_1280_DESKTOP,
    SCREEN_2560_DESKTOP,
} from '../../../../common/screen-sizes';
import {StyledHoverLine as HoverLine} from '../../../../common/tile-utils';

const StyledHoverLine = styled(HoverLine)`
    --tile-utils-hoverline-margin-top: 8px;
    grid-area: hover-line;
    transform: scale(1.05);
    width: auto;

    ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
        --tile-utils-hoverline-margin-top: 12px;
    `}
`;

const Container = styled.div`
    box-sizing: border-box;
    display: grid;
    grid-template: 'title title' auto
        'picture description' max-content
        'hover-line .' auto
        / 46% auto;
    width: 100%;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        padding: 0 5%;

        grid-template: 'picture title' minmax(max-content, 1fr)
            'picture description' minmax(auto, 1fr)
            'hover-line .' auto
            / 46% auto;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        padding: 10px 0 0;

        grid-template: 'picture' auto
            'hover-line' auto
            'title' auto
            'description' auto;
    `}
`;

const wrapperStyles = css`
    display: block;
    position: relative;
    grid-area: picture;
    transition: ${transition('box-shadow', 'border', 'transform', 'margin')};
    outline: 0;
    border-bottom: 2px solid transparent; /* Avoid page re-layouts by just switching color on active state */
    box-shadow: 0 0 30px 0 ${rgba(black, 0.3)};
    padding-bottom: 56.25%; /* always expecting a 16x9 image */
    height: 0;
    overflow: hidden;

    &:not([disabled]):focus,
    &:not([disabled]):hover {
        /* This tile appears as carousel item on <1024 viewport; need to handle active state */
        transform: scale(1.05);
        box-shadow: 0 0 30px 0 ${rgba(black, 0.6)};

        ~ ${StyledHoverLine} {
            visibility: visible;
        }
    }

    &[disabled] {
        opacity: 0.2;
    }
`;

const textStyles = css`
    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        margin-left: 14px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        margin-left: 0;
    `}
`;

const LinkWrapper = styled(A)`
    text-decoration: none;
    color: inherit;

    ${wrapperStyles}
`;

const ButtonWrapper = styled(Button)`
    appearance: none;
    border: 0;
    background: transparent;
    padding: 0;

    ${wrapperStyles}
`;

const DivWrapper = styled.div`
    ${wrapperStyles}
`;

const StyledLazyImageSwitcher = styled(LazyImageSwitcher)`
    position: absolute;
    top: 0;
    left: 0;
`;

const StyledPicture = styled.picture`
    display: block;
`;

const StyledImg = styled(Img)`
    width: 100%;
`;

const Description = styled.div`
    grid-area: description;
    transition: ${transition('opacity')};
    margin-left: 14px;
    text-shadow: 0 1px 1px ${rgba(black, 0.3)};
    color: ${fog};
    font: var(--infinity-body-copy-4);
    font-weight: 300;

    ${textStyles}

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        opacity: 0.8;
    `}

    &[disabled] {
        color: ${rgba(fog, 0.2)};
    }
`;

const Header = styled.div`
    display: flex;
    grid-area: title;
    justify-content: space-between;
    margin-top: 7px;

    ${textStyles}

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        align-self: end;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        align-self: auto;
    `}
`;

const Title = styled.h3`
    margin: 0 0 7px;
    text-shadow: 0 1px 1px ${rgba(black, 0.3)};
    color: ${white};
    font: var(--infinity-tile-title);
`;

const SubHeader = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 7px;
`;

const SubHeaderInfoLine = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    text-transform: uppercase;
`;

const BadgeImg = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
`;

const InfoLineItem = styled.span`
    display: flex;
    align-items: center;
    text-shadow: 0 1px 1px ${rgba(black, 0.3)};
    color: ${fog};
    font: var(--infinity-body-copy-6-bold);

    :not(:last-of-type)::after {
        opacity: 0.2;
        margin: 0 7px;
        content: '|';
    }
`;

/**
 * Takes an object of srcset key/values and returns the largest image.
 *
 * @param {Object} srcsetOptions    - list of srcSet sizes
 * @returns {string}                - the largest img size
 */
function getLargestImage(srcsetOptions = {}) {
    const [firstValue] = Object.entries(srcsetOptions)
        .sort(([sizePxA], [sizePxB]) => sizePxB - sizePxA);

    return get(firstValue, '1');
}

const SRCSET_SIZES = [
    `(min-width: ${SCREEN_1024_DESKTOP}px) 33vw`,
    `(min-width: ${SCREEN_1280_DESKTOP}px) 20vw`,
    '54vw',
]
    .filter(Boolean)
    .join(',');

const PlaceholderComponent = ({lazySrc, placholderCaption = 'Loading Image'}) => (
    <StyledPicture>
        <StyledImg
            src={lazySrc}
            alt={placholderCaption}
        />
    </StyledPicture>
);

PlaceholderComponent.displayName = 'PlaceholderComponent';

PlaceholderComponent.propTypes = {
    /** Path to the lazy loading image */
    lazySrc: propTypes.string,
    /** Caption for the image */
    placholderCaption: propTypes.string,
};

const TM13ShoCon = React.forwardRef(({
    badgeSrcsetOptions,
    className,
    defaultImageSrc,
    description,
    isDisabled = false,
    href,
    imageCaption,
    infoLine,
    isBeingLazy,
    lazySrc,
    onClick,
    srcsetOptions,
    title,
    tabIndex,
    ...htmlAttributes
}, ref) => {
    const PictureWrapper = getCtaType(href, onClick, isDisabled);

    return (
        <Container
            {...htmlAttributes}
            ref={ref}
            className={classnames('TM13ShoCon', className)}
        >
            <PictureWrapper
                {...isDisabled
                    ? {disabled: true}
                    : {href, onClick, tabIndex}}
            >
                <StyledLazyImageSwitcher
                    isBeingLazy={isBeingLazy}
                    placeholderComponent={lazySrc && <PlaceholderComponent lazySrc={lazySrc} />}
                >
                    <StyledPicture>
                        <SrcsetSource srcsetOptions={srcsetOptions} sizes={SRCSET_SIZES} />
                        <StyledImg
                            src={defaultImageSrc || getLargestImage(srcsetOptions)}
                            alt={imageCaption}
                        />
                    </StyledPicture>
                </StyledLazyImageSwitcher>

                {!!badgeSrcsetOptions && (
                    // ensure that this always mirrors the <picture> above
                    <StyledPicture>
                        <SrcsetSource
                            srcsetOptions={badgeSrcsetOptions}
                            sizes={SRCSET_SIZES}
                        />

                        <BadgeImg
                            src={getLargestImage(badgeSrcsetOptions)}
                            alt=""
                        />
                    </StyledPicture>
                )}
            </PictureWrapper>
            <StyledHoverLine />
            <Header>
                <div>
                    <Title>{title}</Title>
                    {!!infoLine && (
                        <SubHeader>
                            <SubHeaderInfoLine>
                                {infoLine.map(({type, value}) => <InfoLineItem key={`${type}-${value}`}>{value}</InfoLineItem>)}
                            </SubHeaderInfoLine>
                        </SubHeader>
                    )}
                </div>
            </Header>
            <Description disabled={isDisabled}>{description}</Description>
        </Container>
    );
});

TM13ShoCon.displayName = 'TM13ShoCon';

TM13ShoCon.propTypes = {
    /** Srcset for the badge overlay. (i.e. "Live", etc) */
    badgeSrcsetOptions: srcsetOptionsType,
    /** Additional CSS classnames to be applied */
    className: classNameType,
    /** Fall back image if there's no srcset options for image */
    defaultImageSrc: propTypes.string,
    /** A short description of the VOD */
    description: propTypes.string,
    /** URL to redirect the user once clicked */
    href: propTypes.string,
    /** Caption for the image, if the image cannot load */
    imageCaption: propTypes.string,
    /** An array of values to be shown as extra information */
    infoLine: propTypes.arrayOf(propTypes.shape({
        value: propTypes.node,
        type: propTypes.string,
    })),
    /** Is the tile being lazy loaded? */
    isBeingLazy: propTypes.bool,
    /** Is this future content that is not currently available to view */
    isDisabled: propTypes.bool,
    /** Path to the image used when lazy loading */
    lazySrc: propTypes.string,
    /** On click handler for the tile */
    onClick: propTypes.func,
    /** Srcset for the tile's image */
    srcsetOptions: srcsetOptionsType,
    /** The tab index of the tile when navigated for accessibility */
    tabIndex: tabIndexType,
    /** Title of the tile */
    title: propTypes.string,
};

TM13ShoCon.defaultProps = {
    isBeingLazy: false,
    lazySrc: EMPTY_IMAGE_SRC,
};

export default TM13ShoCon;

function getCtaType(href, onClick, isDisabled) {
    if (href && !isDisabled) {
        return LinkWrapper;
    } else if (onClick && !isDisabled) {
        return ButtonWrapper;
    } else {
        return DivWrapper;
    }
}
