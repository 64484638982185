import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import {blanc, vader} from '../../../../common/palette';
import Ic from '..';

const IC131Power = ({
    color = blanc,
    secondaryColor = vader,
    ...otherProps
}) => (
    <Ic {...otherProps}>
        <g transform="translate(7 7)">
            <circle cx="42.7" cy="42.7" r="42.7" fill={color} />
            <path fill={secondaryColor} d="M46.9 39.83l-8.4.014v-23.31h8.4z" />
            <path fill={secondaryColor} d="M42.7 69.23a27.578 27.578 0 01-19.684-8.204c-10.833-10.98-10.833-28.626 0-39.606l2.982 2.954c-9.227 9.332-9.227 24.352 0 33.684a23.494 23.494 0 0033.404 0c9.227-9.332 9.227-24.352 0-33.684l2.982-2.954c10.833 10.98 10.833 28.626 0 39.606A27.578 27.578 0 0142.7 69.23z" />
        </g>
    </Ic>
);

IC131Power.propTypes = {
    /** The icon's primary color */
    color: colorType,
    /** The icon's secondary color */
    secondaryColor: colorType,
};

IC131Power.displayName = 'IC131Power';

export default IC131Power;
