import React from 'react';
import propTypes from 'prop-types';

import Ic from '..';

const IC04Vol = ({volumePercentage = 0.5, ...args}) => (
    <Ic {...args}>
        <path d="M54.134,16 L54.134,83.046 L25,63.88 L25,35.152 L54.134,16 Z M45.734,31.68 L33.4,39.8 L33.4,59.4 L45.734,67.464 L45.734,31.68 Z M19.4,35.656 L19.4,63.292 L11,62.522 L11,36.426 L19.4,35.656 Z" />
        <path opacity={volumePercentage > 0.5 ? 1 : volumePercentage + 0.5} d="M67,66.176 L64.886,62.536 C69.2762118,59.9995811 71.9803698,55.3142446 71.9803698,50.244 C71.9803698,45.1737554 69.2762118,40.4884189 64.886,37.952 L66.986,34.312 C72.6708355,37.6036303 76.1710623,43.6749678 76.1710623,50.244 C76.1710623,56.8130322 72.6708355,62.8843697 66.986,66.176 L67,66.176 Z" />
        <path opacity={volumePercentage <= 0.5 ? 0 : volumePercentage} d="M77.5,73.232 L75.4,69.592 C82.2810501,65.5775251 86.5121793,58.2104835 86.5121793,50.244 C86.5121793,42.2775165 82.2810501,34.9104749 75.4,30.896 L77.5,27.2 C85.6831111,31.9640842 90.7167322,40.7191138 90.7167322,50.188 C90.7167322,59.6568862 85.6831111,68.4119158 77.5,73.176 L77.5,73.232 Z" />
    </Ic>
);

IC04Vol.propTypes = {
    /** Percentage in decimal form */
    volumePercentage: propTypes.number,
};

IC04Vol.displayName = 'IC04Vol';

export default IC04Vol;
