import React from 'react';
import Ic from '..';

const IC11AirP = (args) => (
    <Ic {...args}>
        <path d="M49.8 64.948l13.664 18.2H36.136l13.664-18.2m0-7l-22.064 29.4h44.128L49.8 57.948z" />
        <path d="M5 12L5 79.438 26.686 79.438 32.986 71.038 13.4 71.038 13.4 20.4 86.2 20.4 86.2 71.038 66.6 71.038 72.9 79.438 94.6 79.438 94.6 12z" />
    </Ic>
);

IC11AirP.displayName = 'IC11AirP';

export default IC11AirP;
