import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import styled from 'styled-components';

import {colorType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {blueCharcoal} from '../../../../common/palette';

const StyledDiv = styled.div`
    background: ${({from, fromPerc, to}) => `linear-gradient(to bottom, ${from} ${fromPerc}%, ${to} 100%)`};
    width: ${({widthPx}) => widthPx ? `${widthPx}px` : '100%'};
    height: ${({heightPx}) => heightPx ? `${heightPx}px` : '100%'};
`;

const GA21CaGrd = ({
    className,
    colorFrom = 'transparent',
    colorTo = blueCharcoal,
    fromPerc = 60,
    heightPx,
    widthPx,
    ...htmlAttributes
}) => (
    <StyledDiv
        {...htmlAttributes}
        className={classnames('GA21CaGrd', className)}
        heightPx={heightPx}
        fromPerc={fromPerc}
        from={colorFrom}
        to={colorTo}
        widthPx={widthPx}
    />
);

GA21CaGrd.propTypes = {
    /** Additional class(es) */
    className: classNameType,
    /** Color value that gradient starts from */
    colorFrom: colorType,
    /** Color value that gradient transitions to */
    colorTo: colorType,
    /** Percentage for colorFrom */
    fromPerc: propTypes.number,
    /** Height in pixels */
    heightPx: propTypes.number,
    /** Width in pixels */
    widthPx: propTypes.number,
};

GA21CaGrd.displayName = 'GA21CaGrd';

export default GA21CaGrd;
