import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import Ic from '..';
import {cobalt, hubblGreen} from '../../../../common/palette';

const IC157Offer = ({
    color = cobalt,
    secondaryColor = hubblGreen,
    ...args
}) => (
    <Ic {...args}>
        <g xmlns="http://www.w3.org/2000/svg" fill="none" fillRule="evenodd">
            <path
                d="m87.502 22.658 5.69 25.94a6 6 0 0 1-1.619 5.527l-41.17 41.17a6 6 0 0 1-8.485 0L11.283 64.662a6 6 0 0 1 0-8.486l41.243-41.242a6 6 0 0 1 5.372-1.65L82.77 18.05a6 6 0 0 1 4.73 4.607zm-7.887 4.305c-1.41-1.409-3.686-1.416-5.086-.016-1.4 1.4-1.392 3.677.017 5.086 1.409 1.409 3.686 1.416 5.086.016 1.4-1.4 1.392-3.677-.017-5.086z"
                fill={color}
            />
            <path
                d="m65.53 41.705.322.323c-7.895 7.895-7.895 20.696 0 28.592l-.133.133c-7.895-7.896-20.697-7.896-28.592 0l-.323-.323c7.896-7.896 7.896-20.697 0-28.592l.133-.133c7.896 7.895 20.697 7.895 28.592 0z"
                fill={secondaryColor}
            />
        </g>
    </Ic>
);

IC157Offer.displayName = 'IC157Offer';

IC157Offer.propTypes = {
    /** The icon's primary color */
    color: colorType,
    /** The icon's secondary color */
    secondaryColor: colorType,
};

export default IC157Offer;
