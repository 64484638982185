// @TODO: Kayo & Flash
import React from 'react';
import propTypes from 'prop-types';
import get from 'lodash/get';

import {
    CONTENT_EDGE_SPACING_PERCENT,
    GA07TileImg,
    screenSizes,
    TM14ShowTil,
} from '@fsa-streamotion/streamotion-web-ionic';
import {srcsetOptionsType} from '@fsa-streamotion/custom-prop-types';

import {templateUrlToSrcsetOptions} from '../../../../../todo-move-to-widgets-common/utils/srcset-helpers';
import {VMNDIMS_IMWIDTHS} from '../../../../../todo-move-to-widgets-common/utils/imwidth';

const {SCREEN_375_PHABLET, SCREEN_768_TABLET, SCREEN_1024_DESKTOP, SCREEN_1280_DESKTOP} = screenSizes;

const BREAKPOINT_PAGE_SIZES = {
    [SCREEN_375_PHABLET]: 3,
    [SCREEN_768_TABLET]: 5,
    [SCREEN_1024_DESKTOP]: 6,
    [SCREEN_1280_DESKTOP]: 5,
};

// Keep the number of image widths to just what we need so we can avoid unneccessary markup
const minSizePx = 225;
const maxSizePx = 1024;
const minSizePortraitPx = 150;
const maxSizePortraitPx = 320;
const maxPixelDensity = 3;

// Including 150 here so that our smallest supported phones get really small images
const VMNDIMS_IMWIDTHS_EXTENDED = [150, ...VMNDIMS_IMWIDTHS];

const IMWIDTHS = VMNDIMS_IMWIDTHS.filter((sizePx) => (
    sizePx >= minSizePx
    && sizePx <= (maxSizePx * maxPixelDensity)
));

const IMWIDTHS_PORTRAIT = VMNDIMS_IMWIDTHS_EXTENDED.filter((sizePx) => (
    sizePx >= minSizePortraitPx
    && sizePx <= (maxSizePortraitPx * maxPixelDensity)
));

const DEFAULT_SRCSET_SIZE = '30vw';

const SRCSET_SIZES = Object.entries(BREAKPOINT_PAGE_SIZES)
    .map(([breakpoint, numVisibleTiles]) => `(min-width: ${breakpoint}px) ${(100 - (CONTENT_EDGE_SPACING_PERCENT * 2)) / numVisibleTiles}vw`)
    .concat(DEFAULT_SRCSET_SIZE)
    .join(',');

const StandardTile = ({
    clickthrough = {},
    contentDisplay = {},
    interactions = {},
    defaultLandscapeImageSrcOptions,
    defaultPortraitImageSrcsetOptions,
    ...htmlAttributes // needed props passed by cloneElement such as zIndex
}) => (
    <TM14ShowTil
        {...htmlAttributes}
        currentProgressPerc={contentDisplay?.resume?.percentage}
        onClick={(event) => interactions.onClick?.({event, type: get(clickthrough, 'buttons.0.type')})} // For loader tiles interactions.onClick is undefined, so invoke if defined
        footerText={contentDisplay?.resume?.label/* || contentDisplay?.title?.value*/} // @TODO waiting on team to confirm if I need to pass all the time
        href={interactions.href}
        card={(
            <GA07TileImg
                badgeSrcsetOptions={templateUrlToSrcsetOptions({
                    templateUrl: contentDisplay?.images?.badge,
                    sizes: IMWIDTHS,
                })}
                badgePortraitSrcsetOptions={templateUrlToSrcsetOptions({
                    templateUrl: contentDisplay?.images?.badgePortrait,
                    sizes: IMWIDTHS,
                })}
                caption=""
                isStreaming={clickthrough.isStreaming}
                srcsetOptions={contentDisplay?.images?.tile
                    ? templateUrlToSrcsetOptions({
                        templateUrl: contentDisplay?.images?.tile,
                        sizes: IMWIDTHS,
                    })
                    : defaultLandscapeImageSrcOptions}
                srcsetOptionsLazyLoadPlaceholder={defaultLandscapeImageSrcOptions}
                srcsetOptionsPortrait={contentDisplay?.images?.tilePortrait
                    ? templateUrlToSrcsetOptions({
                        templateUrl: contentDisplay?.images?.tilePortrait,
                        sizes: IMWIDTHS_PORTRAIT,
                    })
                    : defaultPortraitImageSrcsetOptions}
                srcsetOptionsPortraitLazyLoadPlaceholder={defaultLandscapeImageSrcOptions}
                srcsetSizes={SRCSET_SIZES}
            />
        )}
    />
);

StandardTile.displayName = 'StandardTile';

StandardTile.propTypes = {
    clickthrough: propTypes.shape({
        isStreaming: propTypes.any,
    }),
    contentDisplay: propTypes.shape({
        editorialLabel: propTypes.any,
        progress: propTypes.any,
        title: propTypes.any,
    }),
    interactions: propTypes.shape({
        href: propTypes.any,
        onClick: propTypes.any,
    }),
    defaultLandscapeImageSrcOptions: srcsetOptionsType,
    defaultPortraitImageSrcsetOptions: srcsetOptionsType,
};

export default StandardTile;
