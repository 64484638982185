/* eslint-disable jsdoc/require-returns, jsdoc/require-param */
import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import {rgba} from 'polished';

import IC103Loading from '../components/atoms/ic/103-loading';
import {cloudy} from './palette';

const Overlay = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    background-color: ${rgba(cloudy, 0.85)};
    min-width: 100vw;
    min-height: 100vh;
`;

const StyledIC103Loading = styled(IC103Loading)`
    position: fixed;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    pointer-events: none;
`;

/**
 * LoadingOverlay is used by page templates:
 * - NavEnhancedTemplate
 * - PageWrapper
 */
const LoadingOverlay = ({
    loadingSpinnerId,
    loadingSpinnerAriaLabel = 'Loading page',
    loadingElement = (
        <StyledIC103Loading
            size="70px"
            id={loadingSpinnerId}
            ariaLabel={loadingSpinnerAriaLabel}
        />
    ),
}) => (
    <Overlay className="LoadingOverlay">
        {!!loadingSpinnerId && loadingElement}
    </Overlay>
);

LoadingOverlay.displayName = 'LoadingOverlay';

LoadingOverlay.propTypes = {
    /** ID of loading spinner `IC103Loading` */
    loadingSpinnerId: propTypes.string,
    /** Aria label for loading spinner */
    loadingSpinnerAriaLabel: propTypes.func,
    /** An element to be shown, if not defined then will render IC103Loading */
    loadingElement: propTypes.element,
};

export default LoadingOverlay;
