import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';
import {red, black} from '../../../../common/deprecated-mui-palette';
import Ic from '..';

const IC20Live = ({color = red, textColor = black, ...otherProps}) => (
    <Ic {...{...otherProps, color}}>
        <g transform="translate(13 29)">
            <path fill={color} d="M0 0H73.178V43.33H0z" />
            <path fill={textColor} d="M24.738 27.916L16.898 27.916 16.898 14.392 20.062 14.392 20.062 25.298 24.738 25.298zM30.128 27.916L26.95 27.916 26.95 14.392 30.128 14.392zM45.136 14.392L40.11 27.916 37.086 27.916 32.074 14.392 35.448 14.392 38.612 23.702 41.776 14.392zM56 27.916L47.068 27.916 47.068 14.392 55.832 14.392 55.832 16.926 50.232 16.926 50.232 19.726 55.524 19.726 55.524 22.26 50.218 22.26 50.218 25.396 56 25.396z" />
        </g>
    </Ic>
);

IC20Live.propTypes = {
    /** Colour for the background */
    color: colorType,
    /** Colour for the LIVE text */
    textColor: colorType,
};

IC20Live.displayName = 'IC20Live';

export default IC20Live;
