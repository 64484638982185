import React from 'react';

import Ic from '..';

const IC59Laptop = (args) => (
    <Ic {...args}>
        <path d="M17.1 72.2h65.7V74c0 .9-1.5 1-1.5 1H18.6c-1.5 0-1.5-1-1.5-1v-1.8zM78.3 69.5H21.7c-.4 0-.7-.3-.7-.7V25.7c0-.4.3-.7.7-.7h56.7c.4 0 .7.3.7.7v43.1c0 .4-.3.7-.8.7zm-55.9-1.4h55.2V26.5H22.4v41.6z" />
    </Ic>
);

IC59Laptop.displayName = 'IC59Laptop';

export default IC59Laptop;
