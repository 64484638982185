import React from 'react';
import Ic from '..';

const IC06Play = (args) => (
    <Ic {...args}>
        <path d="M29.415 21.09l36.827 28.403-36.827 28.403V21.09M21 4v91l59-45.507L21 4z" />
    </Ic>
);

IC06Play.displayName = 'IC06Play';

export default IC06Play;
