export const SCREEN_PHONE = 0;
export const SCREEN_375_PHABLET = 375;
export const SCREEN_415_PHABLET = 415;
export const SCREEN_568_MOBILE_LANDSCAPE = 568;
export const SCREEN_667_PHABLET_LANDSCAPE = 667;
export const SCREEN_768_TABLET = 768;
export const SCREEN_1024_DESKTOP = 1024;
export const SCREEN_1280_DESKTOP = 1280;
export const SCREEN_1480_DESKTOP = 1480;
export const SCREEN_1680_DESKTOP = 1680;
export const SCREEN_1920_DESKTOP = 1920;
export const SCREEN_2560_DESKTOP = 2560;
export const SCREEN_3840_TV = 3840;

export const SMALLEST_SUPPORTED_WIDTH = 320;

export const SCREEN_PORTRAIT = 'portrait';
export const SCREEN_LANDSCAPE = 'landscape';
