import React from 'react';

import Ic from '..';

const IC260Switch = (args) => (
    <Ic {...args}>
        <path d="M86.8 54.644l-9.282 9.282V27.862h-8.4v36.064l-9.282-9.282L53.9 60.58l19.418 19.418L92.736 60.58zM45.836 38.418L26.418 19 7 38.418l5.936 5.936 9.282-9.268v36.05h8.4v-36.05l9.282 9.268z" />
    </Ic>
);

IC260Switch.displayName = 'IC260Switch';

export default IC260Switch;
