import DEFAULT_MARKETING_CONFIG_WHEN_BROKE_BINGE from './binge.json';
import DEFAULT_MARKETING_CONFIG_WHEN_BROKE_KAYO from './kayo.json';

export default function getBackupMarketingConfig({brand}) {
    switch (brand) {
        case 'binge':
            return DEFAULT_MARKETING_CONFIG_WHEN_BROKE_BINGE;

        case 'flash':
            return DEFAULT_MARKETING_CONFIG_WHEN_BROKE_BINGE; // @TODO

        case 'kayo':
            return DEFAULT_MARKETING_CONFIG_WHEN_BROKE_KAYO;

        default:
            console.error('Invalid brand to get fallback offer');
    }
}
