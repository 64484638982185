import React from 'react';
import {colorType} from '@fsa-streamotion/custom-prop-types';

import {kayoGreen} from '../../../../common/palette';
import Ic from '..';

const IC174CcOn = ({accentColor = kayoGreen, ...otherProps}) => (
    <Ic {...otherProps} accentColor={accentColor}>
        <path d="M92 83.3H54.6v-5.6h34.6V22.2H11.4v12.1H5.9V19.4c0-1.5 1.2-2.8 2.8-2.8H92c1.5 0 2.8 1.2 2.8 2.8v61.1c0 1.6-1.3 2.8-2.8 2.8z" />
        <path color={accentColor} d="M13.7 83.3c-.6-1.9-1.6-3.7-3.1-5.1-1.3-1.3-3-2.3-4.7-3v-3.8c2.8.7 5.3 2.2 7.3 4.2s3.5 4.5 4.2 7.3c0 .1.1.3.1.4h-3.8zM25.9 83.3c-1.2-4.9-3.8-9.4-7.4-13-3.5-3.5-7.9-6-12.6-7.3v-5.6c.2 0 .4.1.6.1 6 1.4 11.5 4.5 15.8 8.9 4.5 4.5 7.6 10.1 9 16.3 0 .2.1.4.1.5-.5.1-5.3.1-5.5.1zM39.4 83.3C38.1 74.8 34.1 67 28 60.9s-13.7-10-22.1-11.4v-7.4c.2 0 .4.1.5.1 10.2 1.5 19.4 6.2 26.7 13.5 7.4 7.4 12.1 16.8 13.6 27.1 0 .2 0 .3.1.5h-7.4z" />
    </Ic>
);

IC174CcOn.displayName = 'IC174CcOn';

IC174CcOn.propTypes = {
    /** The colour of the small radio signals on the icon */
    accentColor: colorType,
};

export default IC174CcOn;

