import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Footer, Header, Main} from 'normalized-styled-components';
import FocusTrap from 'focus-trap-react';

import {mediaQuery, stylesIfElse, stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {palette as infinityPalette, screenSizes, visuallyHiddenBaseStyles} from '@fsa-streamotion/streamotion-web-infinity';
import {palette as ionicPalette} from '@fsa-streamotion/streamotion-web-ionic';
import {palette as muiPalette} from '@fsa-streamotion/streamotion-web-mui';
import {palette as nucleusPalette} from '@fsa-streamotion/streamotion-web-nucleus';

import {stylesWhenBinge, stylesWhenFlash, stylesWhenKayo, stylesWhenLifestyle} from '../../../utils/styles-when-brand';

import {
    BrandedLogoContainer,
    BrandedGA05BgImg,
    BrandedGA92BrandLogo,
} from '../../../utils/branded-components';

import brandPropType from '../../../common/brand-proptype';
import CheckoutSuccess from './checkout-success';

const {blanc, eli} = ionicPalette;
const {smoke, white} = infinityPalette;
const {coal, kayoGreen, shark, silverLining} = muiPalette;
const {slate} = nucleusPalette;

const {SCREEN_1280_DESKTOP, SCREEN_1920_DESKTOP, SCREEN_1024_DESKTOP, SCREEN_768_TABLET} = screenSizes;

const StyledMain = styled(Main)`
    ${stylesWhenKayo`
        background: ${coal};
        min-height: 100vh; /* Because body has a different background colour! */
    `}

    a {
        color: inherit;
    }
`;

const Wrapper = styled.div`
    display: grid;
    position: relative;
    grid-auto-flow: row;
    grid-gap: 42px;
    justify-items: stretch;
    margin: 0 auto;
    max-width: 708px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        grid-template-columns: 1fr 1fr;

        ${stylesIfElse('hasPreSection')`
            grid-template-areas: "header header"
                                 "presection presection"
                                 "summarypanel other"
                                 "footer footer";
            grid-template-rows: auto auto 1fr auto;
        ``
            grid-template-areas: "header header"
                                 "summarypanel other"
                                 "footer footer";
            grid-template-rows: auto 1fr auto;
        `}
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        grid-template-columns: 333px 1fr;
        max-width: 990px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        grid-template-columns: 433px 1fr;
        max-width: 1340px;
    `}
`;

const HeaderWrapper = styled(Header)`
    ${visuallyHiddenBaseStyles}

    margin: 35px 175px 0;
    text-align: center;

    ${stylesWhenBinge`
        color: ${blanc};
        font: var(--ionic-header-4);
    `}

    ${stylesWhenFlash`
        color: ${white};
        font: var(--infinity-header-4-bold);
    `}

    ${stylesWhenKayo`
        position: absolute;
        border: 0;
        clip: rect(1px, 1px, 1px, 1px);
        padding: 0;
        width: 1px;
        height: 1px;
        overflow: hidden;
        color: ${silverLining};
        font: var(--mui-header-4);
    `}

    ${stylesWhenLifestyle`
        color: ${blanc};
        font: var(--nucleus-header-4);
    `}

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        position: static;
        grid-area: header;
        width: unset;
        height: unset;
        overflow: unset;
        clip: unset;
    `}
`;

const PreSectionWrapper = styled.div`
    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        grid-area: presection;
    `}
`;

const disabledSectionsStyle = stylesWhen('hasDisabledSections')`
    opacity: 0.3;
    pointer-events: none;
`;

const SummaryPanelWrapper = styled.div`
    padding: 0 14px;

    ${disabledSectionsStyle}

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        grid-area: summarypanel;
        padding: 0;
    `}
`;

const OtherSectionsWrapper = styled.div`
    display: grid;
    grid-auto-flow: row;
    grid-gap: 35px;
    padding: 0 14px;

    ${disabledSectionsStyle}

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        grid-area: other;
        padding: 0;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        grid-gap: 46px;
    `}
`;

const SectionTopRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
`;

const SectionHeading = styled.h2`
    flex: 1 1 auto;
    margin: 0;
    padding-right: 14px;

    ${stylesWhenBinge`
        color: ${blanc};
        font: var(--ionic-body-copy-2-bold);
    `}

    ${stylesWhenFlash`
        color: ${white};
        font: var(--infinity-body-copy-2);
    `}

    ${stylesWhenKayo`
        color: ${silverLining};
        font: var(--mui-body-copy-2);
    `}

    ${stylesWhenLifestyle`
        color: ${blanc};
        font: var(--nucleus-body-copy-2-bold);
    `}
`;

const SectionSubNode = styled.div`
    flex: 0 1 auto;
    white-space: nowrap;
    ${stylesWhenBinge`
        color: ${eli};
        font: var(--ionic-body-copy-5);
    `}

    ${stylesWhenFlash`
        color: ${smoke};
        font: var(--infinity-body-copy-5);
    `}

    ${stylesWhenKayo`
        color: ${shark};
        font: var(--mui-body-copy-5);
    `}

    ${stylesWhenLifestyle`
        color: ${slate};
        font: var(--nucleus-body-copy-5);
    `}
`;

const FooterWrapper = styled(Footer)`
    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        grid-area: footer;
    `}
`;

const StyledBrandedGA05BgImg = styled(BrandedGA05BgImg)`
    opacity: 0.5;

    ${stylesWhenKayo`
        position: absolute;
        height: 100%
    `}
`;

const StyledBrandedGA92BrandLogo = styled(BrandedGA92BrandLogo)`
    ${stylesWhenKayo`
        fill: ${kayoGreen};
    `}
`;

const StyledBranding = styled(BrandedLogoContainer)`
    ${stylesWhenBinge`
        position: relative;
        margin: 0;
    `}

    ${stylesWhenFlash`
        left: 21px;
        margin-bottom: 54px;

        ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
            left: 56px;
        `}
    `}

    ${stylesWhenLifestyle`
        position: relative;
        margin: 0;
    `}
`;

export default function Checkout({
    header,
    backgroundSrcsetOptions,
    backgroundSrcsetOptionsMobile,
    preSection,
    sections = [],
    hasDisabledSections,
    redirectCheckoutSuccessLink,
    isConfirmationEventTrackingComplete,
    shouldShowCheckoutSuccess,
    userEmail,
    footer,
    isOptusOffer,
    warningModal,
    brand,
    myAccountUrl,
}) {
    const [summaryPanel, ...restSections] = sections;

    return (
        <StyledMain brand={brand}>
            {!!backgroundSrcsetOptions && (
                <StyledBrandedGA05BgImg
                    brand={brand}
                    srcsetOptions={backgroundSrcsetOptions}
                    mobilePhabletSrcsetOptions={backgroundSrcsetOptionsMobile}
                />
            )}
            <StyledBranding brand={brand} isOptus={isOptusOffer}>
                <StyledBrandedGA92BrandLogo brand={brand} isOptus={isOptusOffer} />
            </StyledBranding>
            {shouldShowCheckoutSuccess ? (
                <CheckoutSuccess
                    brand={brand}
                    redirectCheckoutSuccessLink={redirectCheckoutSuccessLink}
                    isConfirmationEventTrackingComplete={isConfirmationEventTrackingComplete}
                    heading={brand === 'kayo' ? 'That\'s a wrap!' : 'Ready to Go!'}
                    userEmail={userEmail}
                    myAccountUrl={myAccountUrl}
                />
            ) : (
                <Wrapper hasPreSection={!!preSection}>
                    <HeaderWrapper brand={brand}>
                        {header}
                    </HeaderWrapper>
                    {!!preSection && (
                        <FocusTrap
                            active={!!hasDisabledSections}
                            focusTrapOptions={{
                                escapeDeactivates: false,
                                // refs don’t work with FocusTrap because it occurs before the ref value is generated
                                // this is only to stop FocusTrap from erroring if there are no tabbable elements within preSection
                                fallbackFocus: () => document.querySelector('#template-checkout-presection-wrapper'),
                            }}
                        >
                            <PreSectionWrapper id="template-checkout-presection-wrapper" tabIndex={-1}>
                                {preSection}
                            </PreSectionWrapper>
                        </FocusTrap>
                    )}
                    <SummaryPanelWrapper hasDisabledSections={hasDisabledSections}>
                        <SectionTopRow>
                            <SectionHeading brand={brand}>{summaryPanel.heading}</SectionHeading>
                            <SectionSubNode brand={brand}>{summaryPanel.subNode}</SectionSubNode>
                        </SectionTopRow>
                        {summaryPanel.children}
                    </SummaryPanelWrapper>
                    <OtherSectionsWrapper hasDisabledSections={hasDisabledSections}>
                        {restSections
                            .filter(({children}) => !!children)
                            .map(({key, heading, subNode, children}) => ((
                                <div key={key || heading}>
                                    <SectionTopRow>
                                        <SectionHeading brand={brand}>{heading}</SectionHeading>
                                        <SectionSubNode brand={brand}>{subNode}</SectionSubNode>
                                    </SectionTopRow>
                                    {children}
                                </div>
                            )
                            ))}
                    </OtherSectionsWrapper>
                    <FooterWrapper>{footer}</FooterWrapper>
                </Wrapper>
            )}

            {warningModal}
        </StyledMain>
    );
}

Checkout.displayName = 'Checkout';

Checkout.propTypes = {
    /** Text that goes above main content */
    header: propTypes.node,
    /** Srcset for desktop background image */
    backgroundSrcsetOptions: propTypes.object,
    /** Srcset for mobile background image */
    backgroundSrcsetOptionsMobile: propTypes.object,
    /** Section above main content - contains mobile verification step */
    preSection: propTypes.node,
    /** Contain content for checkout steps */
    sections: propTypes.arrayOf(propTypes.shape({
        heading: propTypes.node,
        subNode: propTypes.node,
        children: propTypes.node,
    })),
    hasDisabledSections: propTypes.bool,
    /** Text that goes below main content */
    footer: propTypes.node,
    /** Modal displayed when an invalid offer is selected */
    warningModal: propTypes.node,
    /** Brand name */
    brand: brandPropType,
    /** Whether confirmation event tracking has completed */
    isConfirmationEventTrackingComplete: propTypes.bool,
    /** Link to redirect user to web app */
    redirectCheckoutSuccessLink: propTypes.string,
    /** Whether we should display checkout success screen */
    shouldShowCheckoutSuccess: propTypes.bool,
    /** User email displayed in checkout success screen */
    userEmail: propTypes.string,
    /** my-account link */
    myAccountUrl: propTypes.string,
    /** Whether display Optus SubHub logo */
    isOptusOffer: propTypes.bool,
};
