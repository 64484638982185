import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import property from 'lodash/property';

import {stylesWhen, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {colorType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../common/animations';
import {black} from '../../../common/palette';

export {default as IC01Ham} from './01-ham';
export {default as IC02Sea} from './02-sea';
export {default as IC03Not} from './03-not';
export {default as IC08Cc} from './08-cc';
export {default as IC24ArrowL} from './24-arrow-l';
export {default as IC25ArrowR} from './25-arrow-r';
export {default as IC26ArrowU} from './26-arrow-u';
export {default as IC27ArrowD} from './27-arrow-d';
export {default as IC31Tick} from './31-tick';
export {default as IC32Success} from './32-success';
export {default as IC42Error} from './42-error';
export {default as IC43Valid} from './43-valid';
export {default as IC48Close} from './48-close';
export {default as IC49Open} from './49-open';
export {default as IC51Close} from './51-close';
export {default as IC57Download} from './57-download';
export {default as IC58Info} from './58-info';
export {default as IC105Home} from './105-home';
export {default as IC114More} from './114-more';
export {default as IC135Cross} from './135-cross';
export {default as IC218XtraInfoHud} from './218-xtra-info-hud';
export {default as IC228MyAccount} from './228-my-account';
export {default as IC229Settings} from './229-settings';
export {default as IC231Profiles} from './231-profiles';
export {default as IC232Help} from './232-help';
export {default as IC233About} from './233-about';
export {default as IC234Home} from './234-home';
export {default as IC237Watchlist} from './237-watchlist';
export {default as IC238More} from './238-more';
export {default as IC239WatchTrailer} from './239-watch-trailer';
export {default as IC245Dolby51} from './245-dolby-5-1';
export {default as IC246Edit} from './246-edit';
export {default as IC247FaqPlus} from './247-faq-plus';
export {default as IC248FaqMinus} from './248-faq-minus';
export {default as IC260Switch} from './260-switch';
export {default as IC261ArrowDTail} from './261-arrow-d-tail';
export {default as IC283Channels} from './283-channels';
export {default as IC284Topics} from './284-topics';
export {default as IC289NotOff} from './289-not-off';
export {default as IC297Preferences} from './297-preferences';
export {default as IC298LiveIcon} from './298-live-icon';
// export {default as IC } from './';

/**
 * There are two methods to set the colour of the SVG
 *     1. Set the color of a container element and the colour will be inherited by the SVG
 *     2. Set the color property
 */
const Ic = styled.svg.attrs({
    viewBox: '0 0 100 100',
})`
    display: ${property('display')};
    pointer-events: none; /* Don't allow icons to be event targets */

    ${stylesWhen('hasDropshadow')`
        filter: drop-shadow(1px 1px 1px ${black});
    `}

    ${stylesWhenNot('preserveColors')`
        fill: currentColor;

        * {
            transition: ${transition('fill', 'stroke')};
        }
    `}
`;

const Icon = ({
    hasDropshadow,
    ariaLabel,
    children,
    color,
    preserveColors,
    display = 'block',
    size = '100%',

    ariaHidden = ariaLabel ? undefined : true,
    ...htmlAttributes
}) => ( // using aria-hidden true on the assumption that these icons will only be used in the context of a button and are thus decorative
    <Ic
        {...htmlAttributes}
        hasDropshadow={hasDropshadow}
        aria-hidden={ariaHidden}
        aria-label={ariaLabel}
        color={preserveColors ? null : color}
        preserveColors={preserveColors}
        display={display}
        style={preserveColors ? {
            fill: color,
            stroke: color,
            width: size,
        } : {
            width: size,
        }}
    >
        {children}
    </Ic>
);

Icon.propTypes = {
    ariaHidden: propTypes.bool,  // eslint-disable-line react/boolean-prop-naming
    ariaLabel: propTypes.string,
    children: propTypes.node,
    color: colorType,
    preserveColors: propTypes.bool,  // eslint-disable-line react/boolean-prop-naming
    display: propTypes.oneOf(['block', 'inline-block', 'flex', 'inline-flex']),
    size: propTypes.string,
    hasDropshadow: propTypes.bool,
};

Icon.displayName = 'Icon';

export default Icon;
