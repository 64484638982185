import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {SCREEN_768_TABLET, SCREEN_1280_DESKTOP, SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import {white, fog} from '../../../../common/palette';

const StyledSection = styled.section`
    margin: 0 auto;
    width: 292px;
    max-width: 100%;
    text-align: center;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: 386px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        width: 444px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 595px;
    `}
`;

const StyledTitle = styled.h2`
    margin: 0 0 14px;
    text-align: center;
    color: ${white};
    font: var(--infinity-header-3-bold);

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        margin-bottom: 21px;
    `}
`;

const StyledP = styled.p`
    padding: 0 20px;
    text-align: center;
    color: ${fog};
    font: var(--infinity-body-copy-3);
`;

const OR55SkipOnboarding = ({
    className,
    copy,
    title,
    ...htmlAttributes
}) => (
    <StyledSection
        {...htmlAttributes}
        className={classnames('OR55SkipOnboarding', className)}
    >
        {!!title && (
            <StyledTitle>{title}</StyledTitle>
        )}

        {!!copy && (
            <StyledP>{copy}</StyledP>
        )}
    </StyledSection>
);

OR55SkipOnboarding.propTypes = {
    /** Additional classes */
    className: classNameType,
    /** Content copy */
    copy: propTypes.node,
    /** Content title */
    title: propTypes.node,
};

OR55SkipOnboarding.displayName = 'OR55SkipOnboarding';

export default OR55SkipOnboarding;
