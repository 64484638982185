// @TODO: move this to @fsa-streamotion/streamotion-web-widgets-common
// Keep this here temporarily until we finalize the URLs
const AEM_URL = {
    kayo: {
        development: 'https://content-dev.streamotion.com.au/graphql/execute.json/kayo/',
        staging: 'https://content-staging.streamotion.com.au/graphql/execute.json/kayo/',
        production: 'https://content.streamotion.com.au/graphql/execute.json/kayo/',
    },
    binge: {
        development: 'https://content-dev.streamotion.com.au/graphql/execute.json/binge/',
        staging: 'https://content-staging.streamotion.com.au/graphql/execute.json/binge/',
        production: 'https://content.streamotion.com.au/graphql/execute.json/binge/',
    },
};

export default AEM_URL;
