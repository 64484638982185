{
    "isLightTheme": false,
    "title": "The world’s best shows on Australia’s newest streaming service.",
    "headerImages": {
       "posterImages": [
          "https://resources.streamotion.com.au/production/binge/marketing/images/biglittlelies-header-landscape.png"
       ],
       "posterImagesMobile": [
          "https://resources.streamotion.com.au/production/binge/marketing/images/biglittlelies-header-portrait.png"
       ]
    },
    "backgroundImage": "https://resources.streamotion.com.au/production/binge/marketing/images/scarlett-face.png",
    "packageSelectPrompt": "Select from the packages below",
    "packagesSupplementaryList": {
       "header": "Every subscription includes",
       "items": [
          "Watch on popular devices",
          "Binge over 10,000+ hours",
          "Cancel anytime",
          "Ad-free on demand",
          "Dolby Digital sound^",
          "Watchlist, Surprise me! and more..."
       ]
    },
    "marketingItems": [
       {
          "type": "streamotionBlurb",
          "adobeId": "streamotion-blurb",
          "title": "STREAMOTION",
          "backgroundImage": "https://resources.streamotion.com.au/production/binge/marketing/images/scarlett-face.png",
          "icons": [
            "ares",
            "kayo",
            "watchAfl",
            "watchNrl"
          ],
          "subTitle": "Streamotion. Unleashing the potential of streaming.",
          "copy": "Streamotion’s mission is simple. We want to connect people to the content they love in ways they’ve never thought of. Streaming is one thing, but it’s the experience around that we’re always striving to improve. That means whenever you’re enjoying downtime, either watching your favourite entertainment on Binge or the world’s best sport on Kayo, it’s the industry leading features that make life better for everyone that connects with us.",
          "footerLink": "https://help.binge.com.au",
          "footerText": "LEARN MORE STREAMOTION"
       }
    ]
}
