import React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';
import PanelTile from './slide';

const GenericCarouselLoadingIndicator = ({
    className,
    brand,
    resourcesEnv,
    ...htmlAttributes
}) => (
    <PanelTile
        brand={brand}
        resourcesEnv={resourcesEnv}
        className={classnames('default-loading-indicator', className)}
        {...htmlAttributes}
    />
);

GenericCarouselLoadingIndicator.displayName = 'GenericCarouselLoadingIndicator';

GenericCarouselLoadingIndicator.propTypes = {
    brand: propTypes.string,

    /** Resources environment for the loading images */
    resourcesEnv: propTypes.string,

    /** Passed down classnames */
    className: propTypes.string,
};

export default GenericCarouselLoadingIndicator;
