import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import styled from 'styled-components';
import {Svg} from 'normalized-styled-components';

import {colorType, classNameType} from '@fsa-streamotion/custom-prop-types';

const StyledSvg = styled(Svg).attrs({
    xmlns: 'http://www.w3.org/2000/svg',
})`
    display: inline-block;
`;

const GA127KayoLogo = ({
    className,
    height,
    isVerticallyCentered,
    title = 'Kayo',
    width = '100%',
    color = '#fff', // specifically kayo white, not blanc
    ...htmlAttributes
}) => (
    <StyledSvg
        {...htmlAttributes}
        viewBox={isVerticallyCentered ? '0 -30 300 190' : '0 0 300 160'}
        className={classnames('GA127KayoLogo', className)}
        fill={color}
        style={{
            width,
            height,
        }}
    >
        <title>{title}</title>

        <path d="M46.7 64.1l21.7 46.7h12.2L54.4 54.5zM53.1 110.8h12.4L44.8 66.4l-8 9.8z" />
        <path d="M18.4 110.8h11.1l4.9-35.4L95.6 0H82.2L24 71.3zM78.7 0H66.5L40.4 32.3 44.9 0H34l-9.3 66.4zM20.5 0L5.1 110.8h11L31.6 0zM186.4 88.9c-6.3 0-7.6-5.5-7.1-8.1l8.3-56.7h-11.1l-7.9 57.3c-1.1 13 7.9 20.8 22.8 17.5l1.9-12.9s-3.7 2.9-6.9 2.9z" />
        <path d="M180.2 101.8c-2.3-.4-15.4-3.6-14.1-20.7l7.8-57h-10.7L155 79.9c-3 22.3 12.5 34.8 34.6 32l1.4-10.3c-3.5.6-7.2.6-10.8.2z" />
        <path d="M201.7 121.5L215.8 24h-11.5l-14.4 101.3c-.8 5.3-6.7 10.1-12.2 8.7l-1.5 11c17.7 1.6 23.8-12.3 25.5-23.5z" />
        <path d="M228.7 24h-10.3l-14 96.4c-1.6 11.9-7.1 29.2-28.5 27.1l-1.4 10.1c30.7 1.1 38.5-20.5 39.8-31.4L228.7 24zM103.6 81.7l3.6-26.3c2.2-9.8 12.5-11.1 15.7-6.8l1.6-11.6c-2.4-.8-5-1.1-7.5-1.1-8.3 0-18.5 6.9-19.8 16.6l-4.1 29.9c-.8 7.8 4 15.7 15 16.5 2.8.2 5.5-.3 8.1-1.3l1.5-11c-9.5 7.1-14.8-1.1-14.1-4.9z" />
        <path d="M90.7 82.5l4.1-29.8c2.5-15.3 18.5-21.9 30.1-18.1l1.4-10.1c-28.9-4-40.6 18.1-41.9 28.1L80.3 82c-1.2 9.1 2.6 32.7 34 29.1l1.5-10.8c-12 4-26.8-3.3-25.1-17.8zM140.3 24.1h-11l-11.9 86.7h10.7zM153.1 24.1h-10.3l-12.2 86.7h10.6zM270.9 21.6c-26.3 0-35.7 21.1-37 31.6l-4.1 30.4c-1.3 9.4 3.1 30.2 29.1 30.2 19.5 0 34.2-12.7 36.8-31.7l4-28.7c1.7-11.7-3.1-31.8-28.8-31.8zm13.6 62.6c-.3 1.8-3.9 19.8-24.8 19.8-16.6 0-19.9-14.6-19.2-21.1.3-2.5 4.2-31.1 4.7-33.5.7-3.4 6.6-17.8 23.2-17.8 21.7 0 20.8 19.2 20.4 22.3-.2 2-4 28.7-4.3 30.3z" />
        <path d="M268.4 33.8c-15.3 0-20.4 13.5-21.1 16.1-.6 2.3-4.5 31.2-4.7 33.8-.4 5.4 2.3 18.1 17.4 18.1 14.9 0 21.4-11.3 22.3-17.9.2-1.6 4.1-28.1 4.3-30.2s1.5-19.9-18.2-19.9zM261.2 91c-5.7 0-8-5-7.5-9l3.9-28.1c.8-5.1 5.9-9.7 10.8-9.7 4.1-.1 7.6 3.2 7.6 7.4 0 .5 0 1-.1 1.5L272 81.4c-.7 5-5.8 9.6-10.8 9.6z" />
    </StyledSvg>
);

GA127KayoLogo.propTypes = {
    /** Additional CSS classname(s) to use */
    className: classNameType,
    /** Accessible label for logo */
    title: propTypes.string,
    /** Optional width of logo */
    width: propTypes.string,
    /** Optional height of logo */
    height: propTypes.string,
    /** optional color of logo */
    color: colorType,
    /** optional vertical centering */
    isVerticallyCentered: propTypes.bool,
};

GA127KayoLogo.displayName = 'GA127KayoLogo';

export default GA127KayoLogo;
