/* eslint-disable max-len */

import React from 'react';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import Ic from '../../ic';

// @TODO: Fix/Address in WEB-2225
const GA86Visa = ({
    className,
    ...htmlAttributes
}) => (
    <Ic
        {...htmlAttributes}
        className={classnames('GA86Visa', className)}
        ariaLabel="Visa"
        preserveColors={true}
    >
        <g fill="none" fillRule="evenodd">
            <rect width="99.86" height="81.909" y="8.85" fill="#FFF" rx="4" />
            <polygon fill="#F1AD2B" points="1.665 80.234 98.333 80.234 98.333 71.567 1.665 71.567" />
            <polygon fill="#182E66" points="1.665 28.699 98.333 28.699 98.333 20.032 1.665 20.032" />
            <path fill="#182E66" d="M49.1652778,38.2467593 L44.0699074,62.0685185 L37.9069444,62.0685185 L43.0027778,38.2467593 L49.1652778,38.2467593 Z M75.0930556,53.6291667 L78.3375,44.6828704 L80.2037037,53.6291667 L75.0930556,53.6291667 Z M81.9726852,62.0685185 L87.6708333,62.0685185 L82.6921296,38.2467593 L77.4356481,38.2467593 C76.250463,38.2467593 75.2523148,38.9337963 74.8101852,39.9930556 L65.5625,62.0685185 L72.0351852,62.0685185 L73.3199074,58.5111111 L81.225463,58.5111111 L81.9726852,62.0685185 Z M65.8837963,54.2916667 C65.9106481,48.0050926 57.1930556,47.6564815 57.2509259,44.8472222 C57.2699074,43.9935185 58.0837963,43.0847222 59.8643519,42.8523148 C60.7467593,42.7384259 63.1819444,42.6462963 65.9425926,43.9180556 L67.0222222,38.8643519 C65.5388889,38.3282407 63.6310185,37.8115741 61.2574074,37.8115741 C55.1648148,37.8115741 50.8787037,41.0476852 50.8444444,45.6856481 C50.8055556,49.1152778 53.9064815,51.0277778 56.2375,52.1699074 C58.6407407,53.337037 59.4462963,54.0861111 59.4347222,55.1291667 C59.4180556,56.7273148 57.5175926,57.4351852 55.7490741,57.4615741 C52.6486111,57.5101852 50.8518519,56.6240741 49.4194444,55.9560185 L48.3009259,61.1787037 C49.7430556,61.8384259 52.4009259,62.4134259 55.1523148,62.4425926 C61.6291667,62.4425926 65.8648148,59.2444444 65.8837963,54.2916667 Z M40.3592593,38.2467593 L30.3736111,62.0685185 L23.8597222,62.0685185 L18.9453704,43.0569444 C18.6476852,41.8884259 18.387963,41.4583333 17.4819444,40.9648148 C16,40.1592593 13.5532407,39.4064815 11.4023148,38.9384259 L11.5476852,38.2467593 L22.0347222,38.2467593 C23.3708333,38.2467593 24.5722222,39.1356481 24.8777778,40.674537 L27.4736111,54.4601852 L33.8847222,38.2467593 L40.3592593,38.2467593 Z" />
        </g>
    </Ic>
);

GA86Visa.displayName = 'GA86Visa';

GA86Visa.propTypes = {
    /** Additional class(es) */
    className: classNameType,
};

export default GA86Visa;
