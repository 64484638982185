import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import {ink} from '../../../../common/palette';

import Ic from '..';

const IC218XtraInfoDefault = ({
    secondaryColor = ink,
    ...args
}) => (
    <Ic {...args}>
        <g fillRule="nonzero" transform="translate(7 8)">
            <circle cx="42.245" cy="42.245" r="42.245" fill={secondaryColor} opacity=".2" />
            <path d="M52.798 60.508v6.435c0 .708-.57 1.282-1.272 1.282H34.728a1.277 1.277 0 0 1-1.272-1.282v-6.435c0-.708.57-1.282 1.272-1.282h2.66c.703 0 1.272-.574 1.272-1.282V41.405c0-.708-.57-1.282-1.272-1.282h-2.66a1.277 1.277 0 0 1-1.272-1.282v-6.43c0-.709.57-1.283 1.272-1.283h11.593c.703 0 1.273.574 1.273 1.282v25.534c0 .708.57 1.282 1.272 1.282h2.66c.703 0 1.272.574 1.272 1.282zm-10.944-35.79c2.39 0 4.327-1.952 4.327-4.359S44.244 16 41.854 16c-2.39 0-4.326 1.952-4.326 4.359s1.937 4.359 4.326 4.359z" />
        </g>
    </Ic>
);

IC218XtraInfoDefault.propTypes = {
    /** The icon's secondary color */
    secondaryColor: colorType,
};
IC218XtraInfoDefault.displayName = 'IC218XtraInfoDefault';

export default IC218XtraInfoDefault;
