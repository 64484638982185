/*
Temporary hack to get around the double encoding issue causing
Disney+ to be displayed as Disney%2B to the users
Reference: https://foxsportsau.atlassian.net/browse/WEB-3672
*/
export default function disneyHack(productOrPromoName) {
    if (!productOrPromoName) {
        return '';
    }

    return productOrPromoName.replace(/Disney%2B/g, 'Disney+');
}
