import {isServer} from '@fsa-streamotion/browser-utils';

const CLIENTSIDE_FEATURE_FLAGS = {
    useAdobeTarget: {
        key: 'use-adobe-target',
        defaultValue: true,
    },
};

/**
 * Feature flags for clientside only functionality
 *
 * These query params should be set in the URL, but without all the fuss of having them whitelisted through the web server/akamai cache,
 * so they will only be picked up clientside
 *
 * @param {string} platformEnv production or staging. just in case you want to change platformEnv param on builds, this is appended to session storage keys
 * @returns {Object} feature flag object
 */
export default function clientSideFeatureFlags(platformEnv) {
    if (isServer()) {
        return Object.entries(CLIENTSIDE_FEATURE_FLAGS)
            .reduce((flagsObj, [prop]) => ({...flagsObj, [prop]: null}), {}); // on server, return everything as `null`. you really need to not be relying on this SSR or you'll have hydration issues
    }

    const searchParams = new URLSearchParams(location.search);

    const clearClientSideFeatureFlags = !!searchParams.get('clear-client-side-feature-flags'); // clobber feature flag session storage

    return Object.entries(CLIENTSIDE_FEATURE_FLAGS)
        .reduce((flagsObj, [prop, {key, defaultValue}]) => {
            const sessionStorageKey = `${platformEnv}-${key}`;
            const searchParamValue = searchParams.get(key);
            const sessionStorageValue = sessionStorage.getItem(sessionStorageKey);
            let value;

            if (clearClientSideFeatureFlags) {
                console.info('clear-client-side-feature-flags detected: clearing session storage related to flags'); // eslint-disable-line no-console

                sessionStorage.removeItem(sessionStorageKey);

                value = defaultValue;
            } else if (searchParamValue !== null) { // null means param was not present
                sessionStorage.setItem(sessionStorageKey, searchParamValue); // if we have a value in the URL, save it to session storage for later. note that URL takes precedence over session storage

                try {
                    value = JSON.parse(searchParamValue);
                } catch {
                    value = searchParamValue;
                }
            } else if (sessionStorageValue === null) {
                value = defaultValue;
            } else {
                try {
                    value = JSON.parse(sessionStorageValue);
                } catch {
                    value = sessionStorageValue;
                }
            }

            return {
                ...flagsObj,
                [prop]: value,
            };
        }, {});
}
