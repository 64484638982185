import React from 'react';
import Ic from '..';

const IC24ArrowL = (args) => (
    <Ic {...args}>
        <path d="M67.9 90.9L29 52c-1.1-1.1-1.1-2.8 0-3.9l38.9-39 3.9 3.9-36.9 37 36.9 36.9-3.9 4z" />
    </Ic>
);

IC24ArrowL.displayName = 'IC24ArrowL';

export default IC24ArrowL;
