import React from 'react';
import propTypes from 'prop-types';
import noop from 'lodash/noop';

import SectionHeading from '../section-heading';
import MarketSubsection from './market-subsection';

const MarketsSection = ({
    section: {title, markets = []},
    onClickOdds = noop,
}) => (
    <section>
        <SectionHeading>{title}</SectionHeading>

        {markets.map((market, index) => <MarketSubsection market={market} onClickOdds={onClickOdds} key={index} />)}
    </section>
);

MarketsSection.propTypes = {
    /** Object containing data about this section */
    section: propTypes.shape({
        title: propTypes.string,
        markets: propTypes.arrayOf(propTypes.object),
    }),
    /** Click handler for odds links */
    onClickOdds: propTypes.func,
};

MarketsSection.displayName = 'MarketsSection';

export default MarketsSection;
