import React from 'react';
import Ic from '..';

const IC43Valid = (args) => (
    <Ic {...args}>
        <path d="M17.073 53.187a4.105 4.105 0 0 1 5.856.284l14.824 16.667L81.521 20.91a4.104 4.104 0 0 1 5.856-.284 4.273 4.273 0 0 1 .29 5.975L37.754 82.747l-20.97-23.585a4.273 4.273 0 0 1 .29-5.975z" />
    </Ic>
);

IC43Valid.displayName = 'IC43Valid';

export default IC43Valid;
