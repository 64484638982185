import React, {useEffect, useRef, useState} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import IntersectionObserver from 'inteobs';

import {stylesWhen, mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {blanc} from '../../../../common/palette';
import {BoxedPanelRow} from '../../../templates/panel';
import {IC103Loading} from '../../../atoms/ic';
import {SCREEN_768_TABLET} from '../../../../common/screen-sizes';
import {transition} from '../../../../common/animations';

const Wrapper = styled.div`
    display: grid;
    grid-template-rows: auto 1fr;
    max-height: calc(100vh - 180px);
    overflow: hidden;
`;

const MessageBlock = styled(BoxedPanelRow)`
    margin-bottom: 28px;
    color: ${blanc};
    font: var(--ionic-body-copy-4-light);

    ${stylesWhen('isLoading')`
        opacity: 0.3;
    `}

    a {
        color: inherit;
    }
`;

const Results = styled.div`
    transition: ${transition('mask-image')};
    padding-bottom: 30px;
    overflow-y: auto;

    ${stylesWhen('isFading')`
        mask-image: linear-gradient(to top, black 86%, transparent 100%);
    `}

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        padding-bottom: 92px;
    `}
`;

const FadeSentinel = styled.div`
    width: 1px;
    height: 1px;
`;

const StyledIC103Loading = styled(IC103Loading)`
    display: block;
    margin: 0 auto;
`;

const OR62SearchResultBlk = React.forwardRef(({
    children,
    className,
    isLoading,
    message,
    ...htmlAttributes
}, ref) => {
    const fadeSentinelRef = useRef(null);
    const [isFading, setIsFading] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsFading(false);
                } else {
                    setIsFading(true);
                }
            }
        );

        if (fadeSentinelRef.current) {
            observer.observe(fadeSentinelRef.current);
        }

        return () => observer.disconnect();
    }, [fadeSentinelRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Wrapper
            {...htmlAttributes}
            aria-busy={isLoading}
            ref={ref}
            className={classnames('OR62SearchResultBlk', className)}
        >
            {!!message && (
                <MessageBlock isLoading={isLoading}>{message}</MessageBlock>
            )}

            {!!isLoading && React.Children.count(children) === 0 && (
                <StyledIC103Loading size="44px" />
            )}

            <Results isFading={isFading}>
                <FadeSentinel ref={fadeSentinelRef} />
                {children}
            </Results>
        </Wrapper>
    );
});

OR62SearchResultBlk.propTypes = {
    children: propTypes.node,
    className: classNameType,
    isLoading: propTypes.bool,
    message: propTypes.node,
};

OR62SearchResultBlk.displayName = 'OR62SearchResultBlk';

export default OR62SearchResultBlk;
