import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import noop from 'lodash/noop';
import classnames from 'classnames';
import {mediaQuery, stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {colorType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {CONTENT_EDGE_SPACING_PERCENT} from '../../../../common/style-constants';
import {ink, black, white} from '../../../../common/palette';
import {NAV_BAR_HEIGHT_PX} from '../../../../common/header-nav-constants';
import {SCREEN_1280_DESKTOP} from '../../../../common/screen-sizes';

import LogoHomeLink from './common/left-side-content/logo-home-link';
import SignupCtaSection from './common/right-side-content/signup-cta-section';
import HeaderFunctionSection from './common/right-side-content/header-function-section';

/** ********************************************
 *                  WRAPPER
 ***********************************************/

// Adding an outer wrapper so we can add the bottom shadow
const OuterWrapper = styled.div`
    /* This is for the bottom gradient shadow */
    ${stylesWhen('isAppDockOpen')`
        &::after {
            position: absolute;
            left: 0;
            background-image: linear-gradient(to bottom, ${rgba(black, 0.05)}, transparent 98%);
            width: 100%;
            height: 14px;
            content: '';
        }
    `};
`;

const MainContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    background-color: ${white};
    padding: 0 ${CONTENT_EDGE_SPACING_PERCENT}%;
    width: 100%;
    height: ${NAV_BAR_HEIGHT_PX}px;
`;

/** ********************************************
 *                  LEFT SIDE
 ***********************************************/

const LeftSideContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    height: ${NAV_BAR_HEIGHT_PX}px;
`;

const ShowHideTopNavMenu = styled.div`
    display: none; /* specifically not accessible because App Dock will render NM07TopNav until SCREEN_1280_DESKTOP */
    height: 100%;

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        display: block;
    `}
`;

/** ********************************************
 *                  RIGHT SIDE
 ***********************************************/

const RightSideContainer = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    text-align: right;
`;

/**
 * Navigation header component for NavEnhancedTemplate
 * - left side: has the brand logo that links to homepage
 * - right side: has the function icon button/s for: app dock
 *
 * @see {@link https://zpl.io/V4Xd7WQ}
 */
const OR33HeaderDsk = ({
    className,
    isAppDockOpen,

    // left side container
    // -> home link logo
    brandLogoId,
    homeLink = '/',
    homeLinkAssistiveLabel = 'Home page',
    onHomeLinkClick,
    // -> nav
    children,

    // right side container
    isRightSideContainerHidden,
    // -> signup cta section
    hasSignupCtaSection,
    ctaMessage,
    signInCtaCopy = 'Sign In',
    signUpCtaCopy = 'Sign Up',
    signUpUrl,
    onSigninClick = noop,
    onSignUpClick = noop,

    // -> header function section
    hasHeaderButtons = true,
    headerButtonsColor = ink,

    // --> appdock
    hasAppDock = true,
    onAppDockClick = noop,

    ...htmlAttributes
}) => (
    <OuterWrapper isAppDockOpen={isAppDockOpen}>
        <MainContainer
            {...htmlAttributes}
            className={classnames('OR33HeaderDsk', className)}
            isAppDockOpen={isAppDockOpen}
        >
            <LeftSideContainer>
                <LogoHomeLink
                    {...{
                        brandLogoId,
                        homeLink,
                        homeLinkAssistiveLabel,
                        onHomeLinkClick,
                    }}
                />

                <ShowHideTopNavMenu>
                    {children}
                </ShowHideTopNavMenu>

            </LeftSideContainer>

            {!isRightSideContainerHidden && (
                <RightSideContainer>
                    {!!hasSignupCtaSection && (
                        <SignupCtaSection
                            {...{
                                ctaMessage,
                                signInCtaCopy,
                                signUpCtaCopy,
                                signUpUrl,
                                onSigninClick,
                                onSignUpClick,
                            }}
                        />
                    )}

                    {!!hasHeaderButtons && (
                        <HeaderFunctionSection
                            {...{
                                headerButtonsColor,
                                // App Dock
                                hasAppDock,
                                isAppDockOpen,
                                onAppDockClick,
                            }}
                        />
                    )}
                </RightSideContainer>
            )}
        </MainContainer>
    </OuterWrapper>
);

OR33HeaderDsk.displayName = 'OR33HeaderDsk';

OR33HeaderDsk.propTypes = {
    /** Classnames for restyling purposes */
    className: classNameType,
    /** For `aria-expanded` & also **border-bottom styling** */
    isAppDockOpen: propTypes.bool,

    // --- Left Side Container
    /** Hydrated Id for the logo */
    brandLogoId: propTypes.string,
    /** For the logo link to go home */
    homeLink: propTypes.string,
    /** Assistive label text for the logo link */
    homeLinkAssistiveLabel: propTypes.string,
    /** Callback on home link click */
    onHomeLinkClick: propTypes.func,
    /** NM07TopNav component (visible only in >= 1280 viewports) */
    children: propTypes.node,

    // --- Right Side Container
    /** Hides the entire right side container: signup block, notification, search, and app dock buttons */
    isRightSideContainerHidden: propTypes.bool,

    // ---> Signup CTA Section
    /** Indicates whether the Signup CTA section is rendered (visible only in >= 1400 viewports) */
    hasSignupCtaSection: propTypes.bool,
    /** Shows Signup CTA label for new users */
    ctaMessage: propTypes.string,
    /** Action on clicking sign-in */
    onSigninClick: propTypes.func,
    /** Sign In CTA copy */
    signInCtaCopy: propTypes.string,
    /** Sign Up CTA copy */
    signUpCtaCopy: propTypes.string,
    /** URL for sign-up */
    signUpUrl: propTypes.string,
    /** Action on clicking sign-up */
    onSignUpClick: propTypes.func,

    // ---> Header Function Section
    /** Indicates whether the header functions section, including the app dock toggle (Hamburger button), should be shown */
    hasHeaderButtons: propTypes.bool,
    /** Accent color for header button icons hover/focus state and Chromecast connected icon  */
    headerButtonsColor: colorType,

    // --->> Appdock
    /** Indicates whether the appdock icon is visible */
    hasAppDock: propTypes.bool,
    /** Function for when the Hamburger menu is clicked */
    onAppDockClick: propTypes.func,
};

export default OR33HeaderDsk;
