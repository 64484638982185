import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {A, Button} from 'normalized-styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';

import {buttonType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {blanc} from '../../../../common/palette';

const commonStyle = css`
    margin: 0;
    border: 0;
    background: transparent;
    cursor: pointer;
    padding: 0;
    text-decoration: underline;
    color: ${rgba(blanc, 0.8)};
    font: var(--nucleus-body-copy-3);

    &:hover {
        text-decoration: none;
    }
`;

const StyledButton = styled(Button)`
    ${commonStyle}
    appearance: none;
`;

const StyledLink = styled(A)`
    ${commonStyle}
`;

const StyledContent = styled.span`
    display: block;
    height: 100%;
    text-align: start;
`;

const BA22EditBtn = ({
    children,
    className,
    href,
    onClick,
    type = 'button',
    ...htmlAttributes
}) => {
    const StyledElement = href ? StyledLink : StyledButton;

    return (
        <StyledElement
            {...htmlAttributes}
            {...{
                href,
                onClick,
                type: href ? undefined : type,
            }}
            className={classnames('BA22EditBtn', className)}
        >
            {!!(children && React.Children.count(children)) && (
                <StyledContent>
                    {children}
                </StyledContent>
            )}
        </StyledElement>
    );
};

BA22EditBtn.displayName = 'BA22EditBtn';

BA22EditBtn.propTypes = {
    /** Content of button, e.g. text */
    children: propTypes.node.isRequired,
    /** additional CSS classnames to be applied */
    className: classNameType,
    /* If it’s actually a link, provide a href */
    href: propTypes.string,
    /** Callback on element click */
    onClick: propTypes.func,
    /** The HTML type of the button */
    type: buttonType,
};

export default BA22EditBtn;
