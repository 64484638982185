import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';

import cuid from 'cuid';
import bacon from 'baconjs';
import serialize from 'serialize-javascript';

import get from 'lodash/get';
import noop from 'lodash/noop';
import result from 'lodash/result';

export default class BaseBaconWidget {
    static widgetName = 'base-widget';
    component = Object.assign(({children}) => children, {displayName: this.constructor.name}); // eslint-disable-line no-invalid-this

    // These can be dynamically adjusted before rendering content to the server.
    contentType = 'text/html';
    cacheSuccessForSeconds = 30;
    cacheFailureForSeconds = 10;
    onErrorStatusCode = 400;

    element = null;
    settings = {};
    config = {};
    closeStreams = noop;

    // Marks if a widget has been hydrated once previously.
    // Subsequent calls to render use ReactDOM.render
    isHydrated = false;

    constructor(settings = {}, element = null) {
        this.element = element;
        this.settings = settings;
    }

    initBrowser(hydration = {}) {
        this.closeStreams = this.getData(hydration)
            .onValue(({props}) => {
                const shouldHydrate = this.isHydrated === false && Object.keys(hydration).length;

                this.renderClient(props, shouldHydrate);
                this.isHydrated = true;
            });
    }

    initServer() {
        return new Promise((resolve, reject) => {
            this.closeStreams = this.getData()
                .take(1)
                .subscribe((event) => {
                    if (result(event, 'isError')) {
                        const errorMessage = get(event, 'error', '');
                        const errorMessageHtml = errorMessage
                            .replace(/&/g, '&amp;')
                            .replace(/</g, '&lt;')
                            .replace(/>/g, '&gt;')
                            .replace(/"/g, '&quot;');

                        console.error(`Error: ${this.constructor.widgetName} failed to render: ${errorMessage}`); // error so slack gets notified.

                        reject({
                            html: `<div class="dev-only" style="display: none;"><pre><code>${errorMessageHtml}</code></pre></div>`,
                            expiresIn: this.cacheFailureForSeconds * 1000, // in ms
                            statusCode: this.onErrorStatusCode,
                        });
                    } else if (result(event, 'hasValue')) {
                        resolve({
                            contentType: this.contentType,
                            html: this.renderServer(result(event, 'value')),
                            expiresIn: this.cacheSuccessForSeconds * 1000, // in ms
                        });
                    }
                });
        });
    }

    initComponentStream(hydration = {}, key) {
        const data = this.getData(hydration);

        return bacon.combineTemplate({
            data,
            reactElement: data.map((data) => <this.component {...data.props} key={key} />),
            hydration: data.map('.hydration'),
        });
    }

    getData(/* hydration = {} */) {
        throw `getData method implemented for ${this.constructor.widgetName}`;
    }

    renderClient(props, shouldHydrate) {
        if (shouldHydrate) {
            if (process.env.NODE_ENV !== 'production') {
                console.info(`Widget ${this.constructor.widgetName}: renderClient() hydrated 💦 at`, this.element); // eslint-disable-line no-console
            }

            ReactDOM.hydrate(<this.component {...props} />, this.element);
        } else {
            ReactDOM.render(<this.component {...props} />, this.element);
        }
    }

    renderServer({props, hydration} = {}) {
        const widgetName = this.constructor.widgetName; // eslint-disable-line prefer-destructuring
        const uniqueId = cuid();
        const fisoBootSettings = {
            settings: this.settings,
            hydration,
        };
        const {prependedHtml = '', html = '', appendedHtml = ''} = this.renderServerComponent(props);

        /* eslint-disable prefer-template */
        return (
            prependedHtml
            + `<div id="${uniqueId}">${html}</div>`
            + '<script>'
            + `window.fisoBoot=window.fisoBoot||{};window.fisoBoot['${widgetName}']=window.fisoBoot['${widgetName}']||{};`
            + `window.fisoBoot['${widgetName}']['${uniqueId}']=${serialize(fisoBootSettings, {isJSON: true})};`
            + '</script>'
            + appendedHtml
        );
        /* eslint-enable prefer-template */
    }

    renderServerComponent(props) {
        return {
            html: ReactDOMServer.renderToString(<this.component {...props} />),
        };
    }

    remove() {
        if (this.closeStreams) {
            this.closeStreams();
            this.closeStreams = null;
        }

        if (this.element) {
            ReactDOM.unmountComponentAtNode(this.element);
        }
    }

    static pageBoot() {
        // Make sure we're a browser, have fsWidgetsBoots and that
        // this particular tagName has been populated or just move on.
        if (typeof window === 'undefined'
            || !window.fisoBoot
            || typeof window.fisoBoot[this.widgetName] !== 'object') {
            return;
        }

        Object.keys(window.fisoBoot[this.widgetName]).forEach((elementId) => {
            const domNode = document.getElementById(elementId);

            if (!domNode) {
                console.error(`Widget ${this.widgetName}: pageBoot() failed, no element found for ID ${elementId}`);

                return;
            }

            let fisoBootArguments = window.fisoBoot[this.widgetName][elementId];
            let widgetInstance;

            if (typeof fisoBootArguments === 'string') {
                fisoBootArguments = JSON.parse(fisoBootArguments);
            }

            if (fisoBootArguments.booted) {
                return;
            }

            const {settings, hydration} = fisoBootArguments;

            try {
                widgetInstance = new this(settings, domNode);
                widgetInstance.initBrowser(hydration);
                fisoBootArguments.booted = true;
            } catch (e) {
                console.error(`Widget ${this.widgetName}: pageBoot() failed for domNode`, domNode);

                throw e;
            }
        });

        if (process.env.NODE_ENV !== 'production') {
            console.info(`Widget ${this.widgetName}: pageBoot() complete`); // eslint-disable-line no-console
        }
    }
}
