const SPECIAL_STAGING_GIFT_CARD = '076750043909877890000000000'; // Valid (can be redeemed many times) but is 27 characters

/**
 * Note: in staging, codes are abnormal and can be less than the normal 30 characters
 * As a terrible hack, if the platformEnv is staging and the code is SPECIAL_STAGING_GIFT_CARD, we'll trim subsequent characters
 * to preserve the frontend validation
 *
 * @param {Object} options see below
 * @param {Object} options.platformEnv platform env, to check if staging
 * @param {Object} options.giftCard gift card to amybe override, if we're in staging
 *
 * @returns {string} Gift Card Code, maybe overridden if staging
 */
export default function stagingGiftCardHack({platformEnv, giftCard}) {
    return platformEnv === 'staging' && giftCard?.startsWith?.(SPECIAL_STAGING_GIFT_CARD)
        ? SPECIAL_STAGING_GIFT_CARD
        : giftCard;
}
