import React from 'react';

import Ic from '..';

const IC543Up = (args) => (
    <Ic {...args}>
        <path d="M63.6 32.2H7c-.6 0-1.1.5-1.1 1.1v33.3c0 .6.5 1.1 1.1 1.1h56.6c.6 0 1.1-.5 1.1-1.1V33.3c0-.6-.5-1.1-1.1-1.1zm-1.1 33.3H8.1V34.4h54.4v31.1zM93.5 32.3H70.7c-.3 0-.6.2-.6.6v14.4c0 .3.2.6.6.6h22.9c.3 0 .6-.2.6-.6V32.8c-.1-.3-.4-.5-.7-.5zM93 46.7H71.2V33.4H93v13.3zM93.5 52.3H70.7c-.3 0-.6.2-.6.6v14.4c0 .3.2.6.6.6h22.9c.3 0 .6-.2.6-.6V52.8c-.1-.3-.4-.5-.7-.5zM93 66.7H71.2V53.4H93v13.3z" />
    </Ic>
);

IC543Up.displayName = 'IC543Up';

export default IC543Up;
