import React from 'react';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {satellite} from '../../../../common/palette';

const GA43ArrowInd = ({className, ...htmlAttributes}) => (
    <svg {...htmlAttributes} className={classnames('GA43ArrowInd', className)} width="9" height="14" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.532 7.245L.319 12.681l.988 1.128L8.81 7.245 1.307.681.32 1.809z"
            fill={satellite}
            fillRule="nonzero"
        />
    </svg>
);

GA43ArrowInd.displayName = 'GA43ArrowInd';

GA43ArrowInd.propTypes = {
    /** Additional class(es) */
    className: classNameType,
};

export default GA43ArrowInd;
