import React from 'react';
import styled from 'styled-components';

import classnames from 'classnames';
import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {SCREEN_768_TABLET} from '../../../../common/screen-sizes';

import BA42NavBtnSec from '../42-nav-btn-sec';

const StyledBA42NavBtnSec = styled(BA42NavBtnSec)`
    padding: 14px 21px 14px 14px;
    min-height: 60px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        padding: 21px 35px 21px 21px;
        min-height: 84px;
    `}
`;

/**
 * BA59NavBtnSecCarded
 * - A variation of BA42NavBtnSec component that can be a link or a button
 * - For use within carded components like OR153PaymentBlk
 * - @see {@link https://zpl.io/GEgPM6r}
 */
const BA59NavBtnSecCarded = ({
    className,
    ...htmlAttributes
}) => (
    <StyledBA42NavBtnSec
        {...htmlAttributes}
        className={classnames('BA59NavBtnSecCarded', className)}
    />
);

BA59NavBtnSecCarded.propTypes = {
    /** additional CSS classnames to be applied */
    className: classNameType,
};

BA59NavBtnSecCarded.displayName = 'BA59NavBtnSecCarded';

export default BA59NavBtnSecCarded;
