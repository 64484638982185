import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import {silverLining} from '../../../../common/palette';

const Container = styled.div`
    color: ${silverLining};
`;

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const Title = styled.h3`
    margin: 0 0 7px;
    font: var(--mui-hud-tile-copy-light);
`;

const Subtitle = styled.div`
    font: var(--mui-hud-tile-copy-light);
`;

const NormalText = styled.div`
    font: var(--mui-hud-tile-copy);
`;

const IGM30SecStatTil = ({
    title,
    playerName,
    subtitle,
    description,
}) => (
    <Container className="IGM30SecStatTil">
        <FlexRow>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
        </FlexRow>
        <FlexRow>
            <NormalText>{playerName}</NormalText>
            <NormalText>{description}</NormalText>
        </FlexRow>
    </Container>
);

IGM30SecStatTil.displayName = 'IGM30SecStatTil';

IGM30SecStatTil.propTypes = {
    title: propTypes.string,
    playerName: propTypes.string,
    subtitle: propTypes.string,
    description: propTypes.string,
};

export default IGM30SecStatTil;
