import React, {useEffect} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import invoke from 'lodash/invoke';

import {mediaQuery, stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {silverLining} from '../../../../common/palette';
import {TILE_PX_WIDTHS} from '../../../../common/standard-tile-size';
import {
    SMALLEST_SUPPORTED_WIDTH,
    SCREEN_PRETTY_LG_DESKTOP,
    SCREEN_REALLY_LG_DESKTOP,
    SCREEN_LG_DESKTOP,
} from '../../../../common/screen-sizes';
import {IC103Loading} from '../../../atoms/ic';

const ResultsBlock = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, ${TILE_PX_WIDTHS[SMALLEST_SUPPORTED_WIDTH]}px);
    grid-gap: 16px;
    justify-content: center;
    margin: 0 auto;
    padding: 0;
    max-width: calc(100% - 14px);

    ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
        max-width: calc(100% - 112px);
        grid-gap: 18px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_PRETTY_LG_DESKTOP})`
        grid-template-columns: repeat(auto-fill, ${TILE_PX_WIDTHS[SCREEN_PRETTY_LG_DESKTOP]}px);
    `}

    ${mediaQuery({minWidthPx: SCREEN_REALLY_LG_DESKTOP})`
        grid-gap: 27px;
        max-width: calc(100% - 282px);
        grid-template-columns: repeat(auto-fill, ${TILE_PX_WIDTHS[SCREEN_REALLY_LG_DESKTOP]}px);
    `}

    &[aria-busy='true'] {
        position: relative;
        min-height: 44px;
    }
`;

const MessageBlock = styled.div`
    box-sizing: border-box;
    margin: 0 auto;
    padding: 28px 14px;
    max-width: 800px;
    text-align: center;
    color: ${silverLining};
    font: var(--mui-body-copy-3);

    ${stylesWhen('isLoading')`
        opacity: 0.3;
    `}

    a {
        color: inherit;
    }
`;

const StyledIC103Loading = styled(IC103Loading)`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
`;

const OR62SearchResultBlk = ({
    children,
    className,
    isLoading,
    message,
    onLoadFocusRef,
    ...htmlAttributes
}) => {
    useEffect(function focusSearchInputField() {
        invoke(onLoadFocusRef, 'current.focus');
    }, [onLoadFocusRef]);

    return (
        <div className={classnames('OR62SearchResultBlk', className)}>
            {!!message && (
                <MessageBlock isLoading={isLoading}>{message}</MessageBlock>
            )}
            <ResultsBlock
                {...htmlAttributes}
                aria-busy={isLoading}
            >
                {!!isLoading && React.Children.count(children) === 0 && (
                    <StyledIC103Loading size="44px" />
                )}
                {children}
            </ResultsBlock>
        </div>
    );
};

OR62SearchResultBlk.displayName = 'OR62SearchResultBlk';

OR62SearchResultBlk.propTypes = {
    children: propTypes.node,
    className: classNameType,
    isLoading: propTypes.bool,
    message: propTypes.node,
    onLoadFocusRef: propTypes.object,
};

export default OR62SearchResultBlk;
