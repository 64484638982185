import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import classnames from 'classnames';
import {mix} from 'polished';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {buttonType, classNameType} from '@fsa-streamotion/custom-prop-types';
import {A, Button} from '../../../../common/normalized-styled-components';

import {transition} from '../../../../common/animations';
import {flash, blueCharcoal, smoke, fog, white} from '../../../../common/palette';
import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';

import IC25ArrowR from '../../ic/25-arrow-r';

const SubLabelWrapper = styled.span`
    flex: 0 0 auto;
    transition: ${transition('color')};
    padding-right: 14px;
    color: ${smoke};
`;

const ArrowWrapper = styled.span`
    flex: 0 0 auto;
    transition: ${transition('color')};
    width: 11px;
    height: 11px;
    color: ${fog};

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 14px;
        height: 14px;
    `}
`;

const baseStyle = css`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    transition: ${transition('color', 'background-color')};
    padding: 5px 20px 5px 25px;
    width: 100%;
    min-height: 64px;
    letter-spacing: 0.4px;
    color: ${smoke};
    font: var(--infinity-body-copy-3);

    &:focus,
    &:hover {
        outline: 0;
        text-shadow: currentColor 0 0 1px;
        color: ${flash};

        /* stylelint-disable-next-line declaration-block-semicolon-newline-after ,rule-empty-line-before */
        ${SubLabelWrapper},
        ${ArrowWrapper} {
            color: ${flash};
        }
    }

    &:active,
    &:focus,
    &[aria-pressed='true'],
    &[aria-current='true'] {
        background-color: ${mix(0.6, flash, blueCharcoal)};
        text-shadow: currentColor 0 0 1px;
        color: ${white};

        /* stylelint-disable-next-line declaration-block-semicolon-newline-after, rule-empty-line-before */
        ${SubLabelWrapper},
        ${ArrowWrapper} {
            color: ${white};
        }
    }
`;

const StyledButton = styled(Button).attrs(({isPressed}) => ({
    'aria-pressed': isPressed,
}))`
    ${baseStyle}

    appearance: none;
    border: 0;
    background: none;
    text-align: left;
`;

const StyledLink = styled(A).attrs(({isPressed}) => ({
    'aria-current': isPressed,
}))`
    ${baseStyle}

    text-decoration: none;
`;

const LabelWrapper = styled.span`
    flex: 1 1 auto;
    padding: 0 14px;
    overflow-x: hidden;
    white-space: nowrap;
    mask-image: linear-gradient(to left, transparent 7px, #000 35px); /* stylelint-disable-line color-no-hex */
`;

const BA42NavBtnSec = React.forwardRef(({
    className,
    href,
    isPressed,
    label,
    subLabel,
    type = href ? undefined : 'button',

    onClick,

    ...htmlAttributes
}, ref) => {
    const Cta = href ? StyledLink : StyledButton;

    return (
        <Cta
            {...htmlAttributes}
            className={classnames('BA42NavBtnSec', className)}
            href={href}
            isPressed={isPressed}
            onClick={onClick}
            ref={ref}
            type={type}
        >
            <LabelWrapper>{label}</LabelWrapper>
            {!!subLabel && <SubLabelWrapper>{subLabel}</SubLabelWrapper>}
            <ArrowWrapper><IC25ArrowR height="100%" /></ArrowWrapper>
        </Cta>
    );
});

BA42NavBtnSec.propTypes = {
    /** Additional CSS class(es) */
    className: classNameType,
    /** If it’s actually a link, provide a href */
    href: propTypes.string,
    /** aria-pressed attribute describes a toggle button */
    isPressed: propTypes.oneOf([true, false, 'mixed']),
    /** label of the button */
    label: propTypes.string.isRequired,
    /** secondary text in between label and arrow */
    subLabel: propTypes.string,
    /** callback to fire on button click */
    onClick: propTypes.func,
    /** react ref, e.g. as passed in by React.forwardRef */
    ref: propTypes.string,
    /** html button type */
    type: buttonType,
};

BA42NavBtnSec.displayName = 'BA42NavBtnSec';

export default BA42NavBtnSec;
