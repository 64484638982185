import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {silverLining} from '../../../../common/palette';

const StyledUnorderedList = styled.ul`
    display: inline-flex;
    margin: 0 auto;
    padding: 0;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    /* This scrollbar-* property is for Firefox 64+ */
    scrollbar-color: ${silverLining} transparent;

    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    ::-webkit-scrollbar {
        background-color: transparent;
        height: 6px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: ${silverLining};
    }
`;

const StyledListItem = styled.li`
    list-style-type: none;
    white-space: nowrap;

    &:not(:last-child) {
        margin-right: 7px;
    }
`;

const StyledHeader = styled.h2`
    margin: 7px auto;
    color: ${silverLining};
    font: var(--mui-body-copy-3);
`;

const OR42TagSelec = ({
    title,
    children,
    className,
    ...htmlAttributes
}) => (
    <section {...htmlAttributes} className={classnames('OR42TagSelec', className)}>
        {!!title && <StyledHeader>{title}</StyledHeader>}
        <StyledUnorderedList>
            {React.Children.map(children, (child) => (
                <StyledListItem key={child.key}>
                    {child}
                </StyledListItem>
            ))}
        </StyledUnorderedList>
    </section>
);

OR42TagSelec.displayName = 'OR42TagSelec';

OR42TagSelec.propTypes = {
    /** List of tags to filter by */
    children: propTypes.arrayOf(propTypes.node),
    /** Heading that appears above tag selector */
    title: propTypes.string,
    /** CSS classname which can be passed in, e.g. by styled components styled() function */
    className: classNameType,
};

export default OR42TagSelec;
