import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import noop from 'lodash/noop';

import {mediaQuery, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';

import {SCREEN_1920_DESKTOP, SCREEN_1280_DESKTOP, SCREEN_768_TABLET} from '../../../../common/screen-sizes';
import useMatchMedia from '../../../../utils/use-match-media';

const StyledDiv = styled.div`
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 480px;
    overflow: hidden;

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        height: 600px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        height: 900px;
    `}
`;

const StyledVideo = styled.video`
    object-fit: cover;
    width: 100%;
    height: 100%;

    ${stylesWhenNot('isVideoLoaded')`
        display: none;
    `}
`;

const OfferVideo = ({
    srcLandscape,
    srcPortrait,
    fallbackImages,
    isVideoLoaded,
    setIsVideoLoaded = noop,
}) => {
    const shouldAutoPlay = !useMatchMedia('(prefers-reduced-motion)', false);
    const source = useMatchMedia(`(min-width: ${SCREEN_768_TABLET}px)`, false)
        ? srcLandscape
        : srcPortrait;

    const onCanPlayThrough = () => {
        setIsVideoLoaded(true);
    };

    return (
        <StyledDiv>
            <StyledVideo
                src={source}
                autoPlay={shouldAutoPlay}
                playsInline={true}
                loop={true}
                muted={true}
                onCanPlayThrough={onCanPlayThrough}
                onContextMenu={(event) => event.preventDefault()} // Prevent right-hand click menu from showing up
                isVideoLoaded={isVideoLoaded}
            />

            {!isVideoLoaded && fallbackImages}
        </StyledDiv>
    );
};

OfferVideo.displayName = 'OfferVideo';

OfferVideo.propTypes = {
    /** Desktop video source */
    srcLandscape: propTypes.string,
    /** Mobile video source */
    srcPortrait: propTypes.string,
    /** Fallback images component (GM07SlidingPictures) */
    fallbackImages: propTypes.node,
    /** Is video loaded? */
    isVideoLoaded: propTypes.bool,
    /** Set video loaded state */
    setIsVideoLoaded: propTypes.func,
};

export default OfferVideo;
