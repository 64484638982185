import {css, keyframes} from 'styled-components';

import {palette as infinityPalette} from '@fsa-streamotion/streamotion-web-infinity';
import {palette as ionicPalette} from '@fsa-streamotion/streamotion-web-ionic';

import {stylesWhenBinge, stylesWhenLifestyle} from '../../../../utils/styles-when-brand';

const LOADING_ANIMATION_OPACITY_LOW = 0.05;
const LOADING_ANIMATION_OPACITY_HIGH = 0.2;

const {white} = infinityPalette;
const {blanc} = ionicPalette;

export const loadingBaseStyles = css`
    background-color: ${white};

    ${stylesWhenBinge`
        background-color: ${blanc};
    `}

    ${stylesWhenLifestyle`
        background-color: ${blanc};
    `}
`;

const pulseKeyframes = keyframes`
    from {
        opacity: ${LOADING_ANIMATION_OPACITY_LOW};
    }

    to {
        opacity: ${LOADING_ANIMATION_OPACITY_HIGH};
    }
`;

export const loadingAnimationStyles = css`
    animation: ${pulseKeyframes} 2s infinite alternate ease-in-out;
`;
