import React from 'react';
import get from 'lodash/get';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import classnames from 'classnames';
import {A, Img} from 'normalized-styled-components';
import {rgba} from 'polished';

import {mediaQuery, stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType, srcsetOptionsType} from '@fsa-streamotion/custom-prop-types';

import SrcsetSource from '../../../../common/srcset-source';
import {tabIndexType} from '../../../../common/custom-proptypes';
import EMPTY_IMAGE_SRC from '../../../../common/empty-image-src';
import {transition} from '../../../../common/animations';
import {smoke, blanc, onyx} from '../../../../common/palette';
import theme from '../../../../common/theme-color';
import LazyImageSwitcher from '../../../../common/lazy-image-switcher';

import {IC239WatchTrailer} from '../../../atoms/ic';
import BA39BtnIconSecondary from '../../../atoms/ba/39-btn-icon-secondary';
import GA01ProBar from '../../../atoms/ga/01-pro-bar';
import GA106Imdb from '../../../atoms/ga/106-imdb';
import GA107Hd from '../../../atoms/ga/107-hd';
import GA108Sd from '../../../atoms/ga/108-sd';
import GA1094k from '../../../atoms/ga/109-4k';

import {
    SCREEN_768_TABLET,
    SCREEN_1024_DESKTOP,
    SCREEN_1280_DESKTOP,
    SCREEN_1920_DESKTOP,
    SCREEN_2560_DESKTOP,
} from '../../../../common/screen-sizes';

import InfoLineItem from './info-line-item';
import parseDateTime from './parse-date-time';

const IconStyles = css`
    height: 10px;

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        height: 11px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
        height: 12px;
    `}
`;

const StyledGA108Sd = styled(GA108Sd)`
    ${IconStyles}
`;

const StyledGA107Hd = styled(GA107Hd)`
    ${IconStyles}
`;

const StyledGA1094k = styled(GA1094k)`
    ${IconStyles}
`;

const StyledGA106Imdb = styled(GA106Imdb)`
    ${IconStyles}

    padding-right: 5px;
`;

const QUALITY_ICONS = {
    'SD': <StyledGA108Sd ariaLabel="SD" />,
    'HD': <StyledGA107Hd ariaLabel="HD" />,
    '4K': <StyledGA1094k ariaLabel="4K" />,
};

const Container = styled.div`
    box-sizing: border-box;
    display: grid;
    grid-template: 'title title' auto
        'picture description' max-content / 46% auto;
    width: 100%;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        padding: 0 5%;

        grid-template: 'picture title' max-content
            'picture description' auto / 46% auto;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        padding: 10px 0 0;

        grid-template: 'picture' auto
            'title' auto
            'description' auto;
    `}
`;

const wrapperStyles = css`
    display: block;
    position: relative;
    grid-area: picture;
    transition: ${transition('box-shadow', 'border', 'transform', 'margin')};
    outline: 0;
    border-bottom: 2px solid transparent; /* Avoid page re-layouts by just switching color on active state */
    box-shadow: 0 0 30px 0 ${rgba(onyx, 0.3)};
    padding-bottom: 56.25%; /* always expecting a 16x9 image */
    height: 0;
    overflow: hidden;

    ${stylesWhen('isActive')`
        /* This tile appears as carousel item on <1024 viewport; need to handle active state */
        transform: scale(1.08);
        box-shadow: 0 0 30px 0 ${rgba(onyx, 0.6)};
        border-color: ${blanc};
    `}

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        margin-bottom: 14px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        margin-bottom: 21px;
    `};
`;

const LinkWrapper = styled(A)`
    ${wrapperStyles}
`;

const DivWrapper = styled.div`
    ${wrapperStyles}
`;

const StyledLazyImageSwitcher = styled(LazyImageSwitcher)`
    position: absolute;
    top: 0;
    left: 0;

    ${stylesWhen('hasTrailer')`
        cursor: pointer;
    `}

    ${stylesWhen('isPreview')`
        opacity: 0.2;
        cursor: auto;
    `}
`;

const StyledPicture = styled.picture`
    display: block;
`;

const StyledGA01ProBar = styled(GA01ProBar)`
    position: absolute;
    bottom: 0;
`;

const StyledImg = styled(Img)`
    width: 100%;
`;

const Description = styled.div`
    grid-area: description;
    transition: ${transition('opacity')};
    margin-left: 14px;
    text-shadow: 0 1px 1px ${rgba(onyx, 0.3)};
    color: ${smoke};
    font: var(--nucleus-body-copy-4);
    font-weight: 300;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        order: 3;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        opacity: 0.8;
        margin-left: 0;
    `}
`;

const HeaderAndActions = styled.div`
    display: flex;
    grid-area: title;
    justify-content: space-between;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        order: 2;
        margin-left: 14px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        margin-left: 0;
    `}
`;

const Title = styled.h3`
    margin: 0 0 7px;
    text-shadow: 0 1px 1px ${rgba(onyx, 0.3)};
    color: ${blanc};
    font: var(--nucleus-episode-tile-title);
`;

const SubHeader = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 7px;
`;

const EditorialLabel = styled.div`
    width: 100%;
    text-transform: uppercase;
    text-shadow: 0 1px 1px ${rgba(onyx, 0.3)};
    color: ${({isThemed}) => isThemed ? theme.primaryColor : blanc};
    font: var(--nucleus-body-copy-6-bold);
    font-weight: 600;
`;

const SubHeaderInfoLine = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`;

const Actions = styled.div`
    display: flex;
    flex-direction: row-reverse;
`;

const StyledBA39BtnIconSecondary = styled(BA39BtnIconSecondary)`
    margin-left: 7px;
`;

const WatchTrailerWrapperInner = styled.div`
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: ${transition('transform')};
    cursor: pointer;
    width: 100%;
    height: 100%;
    text-align: center;
    color: ${blanc};
    font: var(--nucleus-body-copy-6-bold);

    ${stylesWhen('isActive')`
        {* When the tile is scaling (while hovering), the play icon and "Watch Trailer" text both stay the same (static / not scaling) *}
        transform: scale(0.93);
    `}
`;

const WatchTrailerWrapperOuter = styled.div`
    position: absolute;
    background: ${rgba(onyx, 0.6)};
    width: 100%;
    height: 100%;
`;

const BadgeImg = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
`;

/**
 * Takes an object of srcset key/values and returns the largest image.
 *
 * @param {Object} srcsetOptions    - list of srcSet sizes
 * @returns {string}                - the largest img size
 */
function getLargestImage(srcsetOptions = {}) {
    const [firstValue] = Object.entries(srcsetOptions)
        .sort(([sizePxA], [sizePxB]) => sizePxB - sizePxA);

    return get(firstValue, '1');
}

const SRCSET_SIZES = [
    `(min-width: ${SCREEN_1024_DESKTOP}px) 33vw`,
    `(min-width: ${SCREEN_1280_DESKTOP}px) 20vw`,
    '54vw',
]
    .filter(Boolean)
    .join(',');

class TM13ShoCon extends React.Component {
    static displayName = 'TM13ShoCon';

    static propTypes = {
        badgePortraitSrcsetOptions: srcsetOptionsType,
        badgeSrcsetOptions: srcsetOptionsType,
        imageCaption: propTypes.string,
        defaultImageSrc: propTypes.string,
        lazySrc: propTypes.string,
        editorialLabel: propTypes.string,
        srcsetOptions: srcsetOptionsType,
        title: propTypes.string,
        description: propTypes.string,
        actions: propTypes.arrayOf(propTypes.shape({
            label: propTypes.string,
            onClick: propTypes.func,
            content: propTypes.node,
        })),
        className: classNameType,
        infoLine: propTypes.arrayOf(propTypes.shape({
            value: propTypes.string,
            type: propTypes.string,
        })),
        progress: propTypes.shape({
            current: propTypes.number,
            total: propTypes.number,
        }),
        isBeingLazy: propTypes.bool,
        isThemed: propTypes.bool,
        innerRef: propTypes.oneOfType([propTypes.object, propTypes.func]),
        onClick: propTypes.func,
        href: propTypes.string,
        hasContent: propTypes.bool,
        hasTrailer: propTypes.bool,
        tabIndex: tabIndexType,
    };

    static defaultProps = {
        isBeingLazy: false,
        lazySrc: EMPTY_IMAGE_SRC,
    };

    state = {
        isActive: false,
    };

    activate = () => void this.setState({isActive: true});
    deactivate = () => void this.setState({isActive: false});

    render() {
        const {
            actions,
            badgeSrcsetOptions,
            className,
            defaultImageSrc,
            description,
            editorialLabel,
            hasContent,
            hasTrailer,
            href,
            imageCaption,
            infoLine,
            innerRef,
            isBeingLazy,
            isThemed = true,
            onClick,
            progress,
            srcsetOptions,
            title,
            tabIndex,
            ...htmlAttributes
        } = this.props;

        const PictureWrapper = (hasTrailer || hasContent) ? LinkWrapper : DivWrapper;
        const isPreview = !hasContent && !hasTrailer;

        return (
            <Container
                {...htmlAttributes}
                ref={innerRef}
                className={classnames('TM13ShoCon', className)}
            >
                <HeaderAndActions>
                    <div>
                        <Title>{title}</Title>
                        {(!!editorialLabel || !!infoLine) && (
                            <SubHeader>
                                {!!editorialLabel && (<EditorialLabel isThemed={isThemed}>{editorialLabel}</EditorialLabel>)}
                                <SubHeaderInfoLine>
                                    {/* TODO Refactor- InfolineItems has to be passes as nodes from LifeStyle Widgt - Ref: WEB-2187 */}
                                    {(infoLine || []).map(({type, value, transmissionTime}) => {
                                        switch (type) {
                                            case 'imdb':
                                                if (value !== '-') {
                                                    return (
                                                        <InfoLineItem
                                                            icon={<StyledGA106Imdb ariaLabel="IMDB" />}
                                                            description={value}
                                                            key={`${type}-${value}`}
                                                        />
                                                    );
                                                }

                                                return '';
                                            case 'dateTime':
                                                /* eslint-disable react/jsx-curly-brace-presence */
                                                return (
                                                    <InfoLineItem
                                                        description={parseDateTime(value, transmissionTime)}
                                                        key={`${type}-${value}`}
                                                    />
                                                );
                                            case 'quality':
                                                if (QUALITY_ICONS[value]) {
                                                    return <InfoLineItem icon={QUALITY_ICONS[value]} key={`${type}-${value}`} />;
                                                } else {
                                                    return <InfoLineItem description={value} key={`${type}-${value}`} />;
                                                }

                                            default:
                                                return <InfoLineItem description={value} key={`${type}-${value}`} />;
                                        }
                                    })}
                                </SubHeaderInfoLine>
                            </SubHeader>
                        )}
                    </div>
                    {!!(actions && actions.length) && (
                        <Actions>
                            {actions.map(({label, onClick, content}) => (
                                <StyledBA39BtnIconSecondary key={label} aria-label={label} onClick={onClick}>
                                    {content}
                                </StyledBA39BtnIconSecondary>
                            ))}
                        </Actions>
                    )}
                </HeaderAndActions>

                <PictureWrapper
                    href={href}
                    isActive={!isPreview && this.state.isActive}
                    onBlur={this.deactivate}
                    onClick={onClick}
                    onFocus={this.activate}
                    onMouseEnter={this.activate}
                    onMouseLeave={this.deactivate}
                    tabIndex={tabIndex}
                >
                    <StyledLazyImageSwitcher isBeingLazy={isBeingLazy} hasTrailer={hasTrailer} isPreview={isPreview}>
                        <StyledPicture>
                            <SrcsetSource srcsetOptions={srcsetOptions} sizes={SRCSET_SIZES} />
                            <StyledImg
                                src={defaultImageSrc || getLargestImage(srcsetOptions)}
                                alt={imageCaption}
                            />
                        </StyledPicture>
                    </StyledLazyImageSwitcher>

                    {!!badgeSrcsetOptions && (
                        // ensure that this always mirrors the <picture> above
                        <StyledPicture>
                            <SrcsetSource
                                srcsetOptions={badgeSrcsetOptions}
                                sizes={SRCSET_SIZES}
                            />

                            <BadgeImg
                                src={getLargestImage(badgeSrcsetOptions)}
                                alt=""
                            />
                        </StyledPicture>
                    )}

                    {!!hasTrailer && (
                        <WatchTrailerWrapperOuter>
                            <WatchTrailerWrapperInner isActive={this.state.isActive}>
                                <IC239WatchTrailer size="42px" />
                                <div>Watch Trailer</div>
                            </WatchTrailerWrapperInner>
                        </WatchTrailerWrapperOuter>
                    )}
                    {!!progress && (
                        <StyledGA01ProBar
                            current={progress.current}
                            total={progress.total}
                            isThemed={isThemed}
                        />
                    )}
                </PictureWrapper>
                <Description>{description}</Description>
            </Container>
        );
    }
}

const TM13ShoConWithRef = React.forwardRef((props, ref) => <TM13ShoCon innerRef={ref} {...props} />);

TM13ShoConWithRef.displayName = 'TM13ShoCon';

export default TM13ShoConWithRef;
