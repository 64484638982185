import React from 'react';
import Ic from '..';

const IC10Mltv = (args) => (
    <Ic {...args}>
        <path d="M51 14h35v23H51z" opacity=".35" />
        <path d="M88.2 9.2H48.7c-1.5 0-2.8 1.2-2.8 2.8v27.8c0 1.5 1.2 2.8 2.8 2.8h39.5c1.5 0 2.8-1.2 2.8-2.8V11.9c0-1.5-1.3-2.7-2.8-2.7zm-2.8 27.7h-34V14.7h34v22.2zM53 57.4H10.4c-.8 0-1.4.6-1.4 1.4v30.7c0 .8.6 1.4 1.4 1.4H53c.8 0 1.4-.6 1.4-1.4V58.8c0-.8-.7-1.4-1.4-1.4zm-1.4 30.7H11.8V60.2h39.8v27.9zM16.505 49.724c.1-11.4 5.8-21.4 15-27.1l-1.248 7.25a2.077 2.077 0 0 0 1.698 2.4 2.081 2.081 0 0 0 2.401-1.7l2.616-15.25-14.912-2.285a2.115 2.115 0 0 0-2.405 1.735 2.087 2.087 0 0 0 1.698 2.407l6.552 1.143c-10.4 6.9-16.9 18.6-17 31.4h5.6zM83.6 49.6c-.1 11.4-5.8 21.4-15 27.1l1.247-7.25a2.077 2.077 0 0 0-1.697-2.4 2.081 2.081 0 0 0-2.402 1.7L63.132 84l14.912 2.285a2.115 2.115 0 0 0 2.406-1.735 2.087 2.087 0 0 0-1.699-2.407L72.2 81c10.4-6.9 16.9-18.6 17-31.4h-5.6z" />
    </Ic>
);

IC10Mltv.displayName = 'IC10Mltv';

export default IC10Mltv;
