import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import styled, {css} from 'styled-components';
import {
    ariaPressedType,
    buttonType,
    classNameType,
} from '@fsa-streamotion/custom-prop-types';
import {A, Button} from '../../../../common/normalized-styled-components';

import {ink, rainCloud} from '../../../../common/palette';
import baseCtaButtonStyles, {ChildrenWrapper} from '../../../../common/button-utils/base-styles';
import CheckMarkCircle from '../../../../common/button-utils/checked-marked-circle';

const baseStyles = css`
    ${baseCtaButtonStyles}
    background-color: ${rainCloud};
    color: ${ink};

    &:disabled {
        opacity: 0.35;
    }
`;

const StyledButton = styled(Button).attrs(({isPressed}) => ({
    'aria-pressed': isPressed,
}))`
    ${baseStyles}

    appearance: none;
    border: 0;
`;

const StyledLink = styled(A).attrs(({isPressed}) => ({
    'aria-current': isPressed,
}))`
    ${baseStyles}

    text-decoration: none;
`;

/**
 * Secondary CTA Button
 * - Used for the main secondary user action of the page.
 * - 9 slice scalable button
 * - text is dynamic length
 *
 * @see {@link https://zpl.io/jZmQo7O}
 */
const BA28BtnSec = React.forwardRef(({
    children,
    className,
    disabled,
    href,
    isBlock,
    isPressed,
    onClick,
    type = 'button',
    ...htmlAttributes
}, ref) => {
    const isCtaLink = href && !disabled; // coerce disabled CTAs into being buttons
    const StyledElement = isCtaLink ? StyledLink : StyledButton;

    return (
        <StyledElement
            {...htmlAttributes}
            className={classnames('BA28BtnSec', className)}
            disabled={disabled}
            href={href}
            isBlock={isBlock}
            isPressed={isPressed}
            onClick={onClick}
            ref={ref}
            // We only want to put a type for buttons
            type={isCtaLink ? undefined : type}
        >
            <ChildrenWrapper data-content={children}>
                {children}
            </ChildrenWrapper>
            <CheckMarkCircle isPressed={isPressed && !disabled} />
        </StyledElement>
    );
});

BA28BtnSec.propTypes = {
    /** Content of button, e.g. text */
    children: propTypes.node.isRequired,
    /** Additional CSS class(es) */
    className: classNameType,
    /** Is this button disabled? */
    disabled: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    /** If it’s actually a link, provide a href */
    href: propTypes.string,
    /** Whether the button fills its parent's width or not */
    isBlock: propTypes.bool,
    /** passed to `aria-pressed` for buttons or `aria-current` for anchors to indicate selection status */
    isPressed: ariaPressedType,
    /** Callback on element click */
    onClick: propTypes.func,
    /** The HTML type of the button */
    type: buttonType,
};

BA28BtnSec.displayName = 'BA28BtnSec';

export default BA28BtnSec;
