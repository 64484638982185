import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';

import {colorType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {midnight} from '../../../../common/palette';

// const GRADIENT = [
//     'to bottom',
//     'transparent',
//     'transparent 55%',
//     `${rgba(midnight, 0.8)} 75%`,
//     `${midnight} 100%`,
// ].join(', ');

const StyledDiv = styled.div`
    background: ${({from, fromPerc, to}) => `
        linear-gradient(
            to bottom,
            ${from},
            ${from} ${fromPerc}%,
            ${rgba(to, 0.8)} 75%,
            ${to} 100%
        )
    `};
    width: ${({widthPx}) => widthPx ? `${widthPx}px` : '100%'};
    height: ${({heightPx}) => heightPx ? `${heightPx}px` : '100%'};
`;

const GA21CaGrd = ({
    className,
    colorFrom = 'transparent',
    fromPerc = 55,
    colorTo = midnight,
    heightPx,
    widthPx,
    ...htmlAttributes
}) => (
    <StyledDiv
        {...htmlAttributes}
        className={className}
        heightPx={heightPx}
        fromPerc={fromPerc}
        from={colorFrom}
        to={colorTo}
        widthPx={widthPx}
    />
);

GA21CaGrd.displayName = 'GA21CaGrd';

GA21CaGrd.propTypes = {
    /** Additional class(es) */
    className: classNameType,
    /** Color value that gradient starts from */
    colorFrom: colorType,
    /** Color value that gradient transitions to */
    colorTo: colorType,
    /** Percentage for colorFrom */
    fromPerc: propTypes.number,
    /** Height in pixels */
    heightPx: propTypes.number,
    /** Width in pixels */
    widthPx: propTypes.number,
};

export default GA21CaGrd;
