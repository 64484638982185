import React from 'react';

import Ic from '..';

const ICTabletMobile = (args) => (
    <Ic {...args}>
        <path d="M71.8 42.8H61.3V28.3c0-2.2-1.8-4-4-4H30.6c-2.2 0-4 1.8-4 4v41.2c0 2.2 1.8 4 4 4h25v.6c0 .9.7 1.6 1.6 1.6h14.5c.9 0 1.6-.7 1.6-1.6V44.4c.1-.9-.6-1.6-1.5-1.6zm-43.7-13c0-.9.7-1.6 1.6-1.6h28.6c.9 0 1.6.7 1.6 1.6v12.9h-2.6c-.9 0-1.6.7-1.6 1.6V70H29.4c-.7-.2-1.3-.8-1.3-1.6V29.8zm44.1 43.1c0 .4-.3.8-1 .8l-13.5-.1c-.7 0-.9-.4-1-.8V44.9c0-.3.2-.5.5-.5h4.2c0 .6.5 1 1.1 1h3.9c.6 0 1-.4 1.1-1h4.2c.3 0 .5.2.5.5v28z" />
        <path d="M63.7 71.2h1.6v1.6h-1.6z" />
        <path transform="matrix(.00466 -1 1 .00466 -12.738 131.206)" d="M59.1 71.6h.8v.7h-.8z" />
        <path transform="matrix(.00466 -1 1 .00466 -2.81 141.18)" d="M69.1 71.6h.8v.7h-.8z" />
    </Ic>
);

ICTabletMobile.displayName = 'ICTabletMobile';

export default ICTabletMobile;
