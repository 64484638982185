import React from 'react';
import classNames from 'classnames';
import property from 'lodash/property';
import {Progress} from 'normalized-styled-components';
import {rgba} from 'polished';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {colorType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {slate, ironStone} from '../../../../common/palette';

const StyledProgress = styled(Progress)`
    appearance: none;
    border: 0;
    border-radius: 2px;
    background-color: ${rgba(ironStone, 0.7)};
    width: 100%;
    height: 4px;

    ::-moz-progress-bar {
        border-radius: 2px;
        background-color: ${property('color')};
    }

    ::-webkit-progress-value {
        border-radius: 2px 0 0 2px;
        background-color: ${property('color')};
    }

    ::-webkit-progress-bar {
        border-radius: 2px;
        background-color: inherit;
    }

    ::-ms-fill {
        border: none;
        background-color: ${property('color')};
    }

    /* stylelint-disable-next-line order/order */
    ${stylesWhen('isRightToLeft')`
        transform: rotate(180deg);
    `}
`;

const GA02StatBar = ({
    className,
    color = slate,
    current = 0,
    isRightToLeft,
    total = 100,
    ...htmlAttributes
}) => (
    <StyledProgress
        className={classNames('GA02StatBar', className)}
        color={color}
        isRightToLeft={isRightToLeft}
        max={total}
        value={current}
        {...htmlAttributes}
    />
);

GA02StatBar.displayName = 'GA02StatBar';

GA02StatBar.propTypes = {
    /** Additional CSS class(es) */
    className: classNameType,
    /** The optional team color for the progress bar */
    color: colorType,
    /** Current value of progress bar, e.g. team penalties */
    current: propTypes.number,
    /** Makes the progress bar go from right to left instead of left to right */
    isRightToLeft: propTypes.bool,
    /** Total value of progress bar, e.g. combined penalties */
    total: propTypes.number,
};

export default GA02StatBar;
