import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import {gradients} from '@fsa-streamotion/streamotion-web-quicksilver';

const {aurora} = gradients;

// @TODO: Delete this later!

/**
 * This is just a placeholder to demonstrate aem modules
 *
 *  We should be splitting up the modules into separate
 * components so we can easily map with AEM module list
 */
const Wrapper = styled.div`
    border: 1px dashed ${aurora.bottom};
    padding: 10px;
    color: ${aurora.top};
    font: var(--quicksilver-body-copy-3);
`;

const TempModule = ({
    moduleKey,
}) => (
    <Wrapper key={moduleKey} data-module={moduleKey}>
        {moduleKey}
    </Wrapper>
);

TempModule.displayName = 'TempModule';

TempModule.propTypes = {
    /** Module key info for reference */
    moduleKey: propTypes.string,
};

export default TempModule;
