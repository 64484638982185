import React from 'react';

import Ic from '..';

const IC238More = (args) => (
    <Ic {...args}>
        <path d="M20.634 39L25.5 54.139h.07L30.366 39h6.947L39 62h-5.358l-.914-15.667h-.07L27.666 62h-4.318l-5.034-15.667h-.098L17.372 62H12l1.758-23h6.876zm28.887 6c5.154 0 8.979 3.243 8.979 8.514 0 5.27-3.852 8.486-8.98 8.486-5.127 0-9.02-3.215-9.02-8.486 0-5.271 3.866-8.514 9.02-8.514zM71 45.313l-.262 4.967h-.235a4.159 4.159 0 00-1.918-.366 3.127 3.127 0 00-2.457 1.21 6.06 6.06 0 00-.828 3.475V62H60V45.313h5.3v2.364c.687-1.649 2.287-2.707 4.044-2.673a3.686 3.686 0 011.656.31zm14.678 1.873a7.676 7.676 0 012.315 5.803c.028.785-.04 1.75-.04 1.75H76.328a3.744 3.744 0 001.354 2.568c.779.64 1.783.94 2.787.833a9.131 9.131 0 004.9-1.556l2.085 2.754a10.854 10.854 0 01-7.744 2.644c-5.039 0-8.711-3.112-8.711-8.497s3.976-8.47 8.807-8.47a7.712 7.712 0 015.87 2.171zM49.52 49.181c-2.331 0-3.688 1.821-3.688 4.236s1.37 4.236 3.688 4.236c2.316 0 3.687-1.821 3.687-4.236s-1.384-4.236-3.687-4.236zm30.217-.736a3.325 3.325 0 00-3.382 3.263l6.723.014a3.273 3.273 0 00-3.341-3.277z" />
    </Ic>
);

IC238More.displayName = 'IC238More';

export default IC238More;
