import React from 'react';
import Ic from '..';

const IC167Synopsis = (args) => (
    <Ic {...args}>
        <g fillRule="nonzero" transform="translate(16.583 26.166)">
            <rect width="52.755" height="8.369" x="14.395" rx="4.184" />
            <rect width="52.755" height="5.579" x="14.395" y="16.64" rx="2.79" />
            <rect width="52.755" height="4.184" x="14.395" y="31.188" rx="2.092" />
            <rect width="52.755" height="2.79" x="14.395" y="45.75" rx="1.395" />
            <rect width="8.8" height="8.369" rx="4.184" />
            <rect width="8.8" height="5.579" y="16.64" rx="2.79" />
            <rect width="8.8" height="4.184" y="31.188" rx="2.092" />
            <rect width="8.8" height="2.79" y="45.75" rx="1.395" />
        </g>
    </Ic>
);

IC167Synopsis.displayName = 'IC167Synopsis';

export default IC167Synopsis;
