/* stylelint-disable custom-property-pattern */
import styled, {css} from 'styled-components';
import {rgba} from 'polished';

import {stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';

import {palette as infinityPalette} from '@fsa-streamotion/streamotion-web-infinity';
import {palette as ionicPalette} from '@fsa-streamotion/streamotion-web-ionic';
import {palette as muiPalette} from '@fsa-streamotion/streamotion-web-mui';
import {palette as nucleusPalette} from '@fsa-streamotion/streamotion-web-nucleus';

import {stylesWhenBinge, stylesWhenFlash, stylesWhenKayo, stylesWhenLifestyle} from '../../../../../utils/styles-when-brand';

import {transition} from '../../../../../common/animations';

const {blueCharcoal, flash, white} = infinityPalette;
const {ares, blanc, knight, panther} = ionicPalette;
const {coal, midnight, slate} = muiPalette;
const {lifestyle, ink, onyx} = nucleusPalette;

/*
 * NOTE:
 *  Moved this out of index file because it's also referenced by FeatureColumnCells
 *   > Keeping it within index causes circular dependencies
 */

const brandingColors = css`
    ${stylesWhenBinge`
        --package-row-border-top-color: ${rgba(blanc, 0.2)};
        --package-row-focus-shadow-color: ${ares};
        --package-row-hover-background-color: ${rgba(blanc, 0.8)};
        --package-row-hover-color: ${knight};
        --package-row-pressed-background-color: ${blanc};
        --package-row-pressed-color: ${panther};
    `}

    ${stylesWhenFlash`
        --package-row-border-top-color: ${rgba(white, 0.2)};
        --package-row-focus-shadow-color: ${flash};
        --package-row-hover-background-color: ${rgba(white, 0.8)};
        --package-row-hover-color: ${blueCharcoal};
        --package-row-pressed-background-color: ${white};
        --package-row-pressed-color: ${blueCharcoal};
    `}

    ${stylesWhenKayo`
        --package-row-border-top-color: ${rgba(slate, 0.1)};
        --package-row-focus-shadow-color: ${slate};
        --package-row-hover-background-color: ${coal};
        --package-row-hover-color: ${slate};
        --package-row-pressed-background-color: ${midnight};
        --package-row-pressed-color: ${white};
        color: ${white}; /* overwrite table color slate */
    `}

    ${stylesWhenLifestyle`
        --package-row-border-top-color: ${rgba(blanc, 0.2)};
        --package-row-focus-shadow-color: ${lifestyle};
        --package-row-hover-background-color: ${rgba(blanc, 0.8)};
        --package-row-hover-color: ${ink};
        --package-row-pressed-background-color: ${blanc};
        --package-row-pressed-color: ${onyx};
    `}
`;

const PackageRow = styled.tr.attrs({
    role: 'button',
    tabIndex: 0,
})`
    ${brandingColors}
    transition: ${transition('background-color', 'color')};
    outline: 0;
    border-top: 1px solid var(--package-row-border-top-color);
    background-color: transparent;

    ${stylesWhenNot('isDisabled')`
        &:focus {
            box-shadow: 0 0 3px 1px var(--package-row-focus-shadow-color);
        }

        &:hover {
            background-color: var(--package-row-hover-background-color);
            color: var(--package-row-hover-color);
        }
    `}

    &[aria-pressed='true'] {
        box-shadow: none;
        background-color: var(--package-row-pressed-background-color);
        color: var(--package-row-pressed-color);
    }
`;

PackageRow.displayName = 'PackageRow';

export default PackageRow;
