import React from 'react';
import Ic from '..';

const IC30Back = (args) => (
    <Ic {...args}>
        <path d="M64.168 92.336L22 50.168 64.168 8l5.936 5.936-36.232 36.232L70.104 86.4z" />
    </Ic>
);

IC30Back.displayName = 'IC30Back';

export default IC30Back;
