import React, {useEffect, useState} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import get from 'lodash/get';
import noop from 'lodash/noop';
import {Section} from 'normalized-styled-components';
import classnames from 'classnames';

import {mediaQuery, stylesIfElse, stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition, TRANSITION_CLASSNAMES} from '../../../../../common/animations';
import CommonTransition from '../../../../../common/common-transition';
import {SCREEN_1920_DESKTOP, SCREEN_2560_DESKTOP} from '../../../../../common/screen-sizes';
import {onyx} from '../../../../../common/palette';

import VPBA04CloseTray, {CLOSE_BUTTON_MAX_HEIGHT_PX} from '../../../../atoms/vp/ba/04-close-tray';
import VPBA02TraySectionListItem from '../../../../atoms/vp/ba/02-tray-section-list-item';
import VPTM06TraySectionList from '../../../../molecules/vp/tm/06-tray-section-list';

const TRAY_TOTAL_MIN_HEIGHT_PX = 195;
const TRAY_MIN_HEIGHT_PX = TRAY_TOTAL_MIN_HEIGHT_PX - CLOSE_BUTTON_MAX_HEIGHT_PX;

const Tray = styled(Section)`
    box-sizing: border-box;
    display: flex;
    position: absolute;

    right: 0;
    left: 0;
    ${({isTop}) => isTop ? 'top: 0' : 'bottom: 0'};

    align-items: center;
    transition: ${transition('transform')};
    background-image: linear-gradient(to ${({isTop}) => isTop ? 'top' : 'bottom'}, ${rgba(onyx, 0.6)}, ${onyx});
    backdrop-filter: blur(10px);
    min-height: ${TRAY_MIN_HEIGHT_PX}px;
    max-height: 75vh;
    /* Disabled as stylelint doesn't seem able to parse what is going on here. These are not actually duplicate selectors */
    /* stylelint-disable-next-line no-duplicate-selectors */
    &.${TRANSITION_CLASSNAMES.enterActive},
    &.${TRANSITION_CLASSNAMES.enterDone},
    &.${TRANSITION_CLASSNAMES.exit} {
        transform: translateY(0);
    }

    /* stylelint-disable-next-line no-duplicate-selectors */
    &.${TRANSITION_CLASSNAMES.enter},
    &.${TRANSITION_CLASSNAMES.exitActive},
    &.${TRANSITION_CLASSNAMES.exitDone} {
        transform: translateY(calc(${({isTop}) => (isTop ? -1 : 1)} * (100% + ${CLOSE_BUTTON_MAX_HEIGHT_PX}px)));
    }
`;

const Content = styled(Section)`
    flex-grow: 1;
    width: auto;
    overflow: hidden;
`;

const CloseButtonContainer = styled.div`
    position: absolute;
    right: 0;
    line-height: 0;

    ${stylesIfElse('isTop')`
        top: 100%;
    ``
        bottom: 100%;
    `}
`;

const TraySectionListContainer = styled.div`
    align-self: stretch;
    padding: 10px 0;

    ${stylesWhen('isScrollable')`
        max-height: ${TRAY_MIN_HEIGHT_PX}px;
    `}

    ${stylesWhen('isTop')`
        padding-right: 42px;

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            padding-right: 50px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
            padding-right: 66px;
        `}
    `}
`;

export default function VPOR02Tray({
    className,
    isScrollable = true,
    isTop = true,
    isVisible,
    onTrayClose,
    tabItems = [],
}) {
    const [activeIndex, setActiveIndex] = useState(0);
    const activeTraySectionContent = get(tabItems, [activeIndex, 'node'], null);

    useEffect(() => {
        setActiveIndex(0);
    }, [isVisible]);

    return (
        <CommonTransition in={isVisible}>
            <Tray isVisible={isVisible} isTop={isTop} className={classnames('VPOR02Tray', className)}>
                <TraySectionListContainer isScrollable={isScrollable} isTop={isTop}>
                    <VPTM06TraySectionList>
                        {tabItems.map(({name, onTabItemClick = noop}, index) => (
                            <VPBA02TraySectionListItem
                                key={name}
                                label={name}
                                isActive={activeIndex === index}
                                onClick={(e) => {
                                    setActiveIndex(index);
                                    onTabItemClick(e);
                                }}
                            />
                        ))}
                    </VPTM06TraySectionList>
                </TraySectionListContainer>
                <Content role="tabpanel">
                    {activeTraySectionContent}
                </Content>
                <CloseButtonContainer isTop={isTop}>
                    <VPBA04CloseTray onClick={onTrayClose} isUpperTray={isTop} aria-label={`Close ${isTop ? 'upper' : 'lower'} tray`} tabIndex="-1" />
                </CloseButtonContainer>
            </Tray>
        </CommonTransition>
    );
}

VPOR02Tray.displayName = 'VPOR02Tray';

VPOR02Tray.propTypes = {
    /** Additional class(es) */
    className: classNameType,
    tabItems: propTypes.arrayOf(propTypes.shape({
        name: propTypes.string,
        node: propTypes.node,
        onTabItemClick: propTypes.func,
    })),
    isTop: propTypes.bool,
    isVisible: propTypes.bool,
    isScrollable: propTypes.bool,
    onTrayClose: propTypes.func,
};
