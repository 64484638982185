import {format, differenceInCalendarDays, isValid} from 'date-fns';

export default function localiseContentDisplay({values, ...rest} = {}, isTimezoneAvailable = true) {
    const {startTime, endTime} = values || {};
    const nowTimeDate = new Date();
    const startTimeDate = new Date(startTime);
    const endTimeDate = new Date(endTime);
    const stringModifier = isTimezoneAvailable ? '' : ' AEST';

    // endTime: "2018-10-17T02:42:00Z"
    // startTime: "2018-10-17T00:00:00Z"
    // rest: {
    //     badge: "REPLAY"
    //     footer: "Full Time"
    //     header: "NBA - ${DATE_HUMANISED}"
    //     heroHeader: "NBA - COMPLETE"
    //     title: "Celtics v 76ers"
    //     type: "live-fixture-teams"
    //     images: {
    //          default: "/series-logos/1x1/rugby/2.png?imwidth=${WIDTH}",
    //          16x9: "/series-logos/16x9/rugby/2.png?imwidth=${WIDTH}",
    //     }
    // }

    return Object.entries(rest)
        .map(([key, value]) => {
            if (typeof value !== 'string') {
                return {[key]: value};
            }

            const newValue = value
                // ${TIME} → example: 7:30PM
                .replace(/\${TIME}/g, makeTime({startTimeDate}) + stringModifier)

                // ${DATE} → example 11 DEC, 01 DEC
                .replace(/\${DATE}/g, makeDate({nowTimeDate, startTimeDate}))

                // ${DURATION} → example: 8-11 DEC (start and end date will be set)
                // ${DURATION} → example: 8 DEC (start and end date will be set)
                .replace(/\${DURATION}/g, makeDuration({nowTimeDate, startTimeDate, endTimeDate}))

                // ${DATE_HUMANISED} → today, tomorrow, friday, 25th OCT
                .replace(/\${DATE_HUMANISED}/g, makeHumanised({nowTimeDate, startTimeDate}));

            return {[key]: newValue};
        })
        .reduce((contentDisplay, obj) => ({...contentDisplay, ...obj}), {});
}

export function makeTime({startTimeDate}) {
    if (!isValid(startTimeDate)) {
        return '';
    }

    return format(startTimeDate, 'h:mmA');
}

export function makeDate({nowTimeDate, startTimeDate}) {
    if (!isValid(startTimeDate)) {
        return '';
    }

    const isThisYear = format(nowTimeDate, 'yyyy') === format(startTimeDate, 'yyyy');

    if (isThisYear) {
        return format(startTimeDate, 'dd MMM');
    } else {
        return format(startTimeDate, 'dd MMM yy');
    }
}

export function makeDuration({nowTimeDate, startTimeDate, endTimeDate}) {
    if (!isValid(startTimeDate)) {
        return '';
    }

    if (!isValid(endTimeDate)) {
        return makeDate({nowTimeDate, startTimeDate});
    }

    const isEndThisYear = format(nowTimeDate, 'yyyy') === format(endTimeDate, 'yyyy');

    const startDay = format(startTimeDate, 'd');
    const startMonthShort = format(startTimeDate, 'MMM');

    const endDay = format(endTimeDate, 'd');
    const endMonthShort = format(endTimeDate, 'MMM');
    const endYear = format(endTimeDate, 'yyyy');

    // 1 January
    if (startDay === endDay && startMonthShort === endMonthShort) {
        return `${startDay} ${startMonthShort}`;
    }

    // 1-2 January
    if (startDay !== endDay && startMonthShort === endMonthShort) {
        return `${startDay}-${endDay} ${startMonthShort}`;
    }

    // startMonthShort !== endMonthShort is here;
    if (isEndThisYear) {
        // 29 Jan - 03 Feb
        return `${startDay} ${startMonthShort} - ${endDay} ${endMonthShort}`;
    } else {
        // 29 Jan - 03 Feb 2019
        // 29 Jan - 03 Feb 2017
        return `${startDay} ${startMonthShort} - ${endDay} ${endMonthShort} ${endYear}`;
    }
}

export function makeHumanised({nowTimeDate, startTimeDate}) {
    if (!isValid(startTimeDate)) {
        return '';
    }

    // Between 2017-01-01T23:59 and 2017-01-02T00:01 there is zero days, but one calendar day
    const daysDiff = differenceInCalendarDays(startTimeDate, nowTimeDate);
    const isToday = daysDiff === 0;
    const isYesterday = daysDiff === -1;
    const isTomorrow = daysDiff === 1;
    // const isOlderThanYesterday = daysDiff < -1;
    const isWithinForwardWeek = daysDiff <= 7 && daysDiff >= 1;
    const isThisYear = format(nowTimeDate, 'yyyy') === format(startTimeDate, 'yyyy');
    let dateDisplay;

    if (isToday) {
        dateDisplay = 'today';
    } else if (isTomorrow) {
        dateDisplay = 'tomorrow';
    } else if (isYesterday) {
        dateDisplay = 'yesterday';
    } else if (isWithinForwardWeek) {
        dateDisplay = format(startTimeDate, 'dddd');
    } else if (isThisYear) {
        dateDisplay = format(startTimeDate, 'dd MMM');
    } else {
        dateDisplay = format(startTimeDate, 'dd MMM yy');
    }

    // If API moves away from having "${DATE_HUMANISED} ${TIME}" together, and we want time added, this is where you do it.
    // dateDisplay += ' ' + format(startTimeDate, 'h:mmA');

    return dateDisplay;
}
