/* eslint-disable max-len */

import React from 'react';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import Ic from '../../ic';

// @TODO: Update when final SVGs are provided - using Flash ones for now

/**
 * Mastercard Logo
 * - Used in My Account
 * - https://zpl.io/jZeqOrA
 */
const GA87Mastercard = ({
    className,
    ...htmlAttributes
}) => (
    <Ic
        {...htmlAttributes}
        className={classnames('GA87Mastercard', className)}
        ariaLabel="Mastercard"
        preserveColors={true}
    >
        <g fill="none" fillRule="evenodd">
            <rect fill="#FAFAFA" width="99.86" height="81.9" y="8.85" rx="2" />
            <g fillRule="nonzero" transform="translate(0, 10)">
                <path fill="#FF5F00" d="M40 24h20v33H40z" />
                <path
                    d="M41.974 40.497c-.006-6.29 2.954-12.232 8.026-16.115-8.616-6.605-20.99-5.643-28.424 2.21-7.435 7.854-7.435 19.962 0 27.816 7.435 7.853 19.808 8.815 28.424 2.21-5.073-3.885-8.033-9.83-8.026-16.12z"
                    fill="#EB001B"
                />
                <path
                    d="M81.46 54v-.829h.333V53H81v.171h.313V54h.147zM83 54v-1h-.24l-.28.714L82.2 53h-.24v1h.173v-.757l.26.65h.18l.26-.65V54H83zM84 40.503c0 7.848-4.594 15.007-11.83 18.436-7.237 3.43-15.845 2.528-22.17-2.323 5.07-3.886 8.029-9.827 8.029-16.116 0-6.29-2.96-12.23-8.029-16.116 6.325-4.85 14.933-5.753 22.17-2.323C79.406 25.49 84 32.649 84 40.497v.006z"
                    fill="#F79E1B"
                />
            </g>
        </g>
    </Ic>
);

GA87Mastercard.propTypes = {
    /** Additional class(es) */
    className: classNameType,
};

GA87Mastercard.displayName = 'GA87Mastercard';

export default GA87Mastercard;
