import React, {useEffect, useState, Fragment} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Main, Section} from 'normalized-styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {palette as ionicPalette, screenSizes} from '@fsa-streamotion/streamotion-web-ionic';
import {palette as infinityPalette} from '@fsa-streamotion/streamotion-web-infinity';
import {palette as muiPalette, screenSizes as muiScreenSizes} from '@fsa-streamotion/streamotion-web-mui';
import {palette as nucleusPalette} from '@fsa-streamotion/streamotion-web-nucleus';

import {stylesWhenBinge, stylesWhenFlash, stylesWhenKayo, stylesWhenLifestyle} from '../../../utils/styles-when-brand';

import {VOUCHER_IMAGE_DESKTOP_MAX_WIDTH_PX} from '../voucher-image';

import brandPropType from '../../../common/brand-proptype';
import Header from './header';
import OfferLoadingSkeleton from './loading-skeleton';
import BackgroundImage from './background-image';
import OfferContent from './content';
import VoucherForm from './voucher-form';
import Footer from './footer';

const {
    SCREEN_768_TABLET,
    SCREEN_1024_DESKTOP,
    SCREEN_1920_DESKTOP,
    SCREEN_1280_DESKTOP,
} = screenSizes;

const {
    SCREEN_TABLET,
    SCREEN_LG_DESKTOP,
    SCREEN_PRETTY_LG_DESKTOP,
} = muiScreenSizes;

const {vader} = ionicPalette;
const {black} = infinityPalette;
const {coal, ironStone} = muiPalette;
const {midnight} = nucleusPalette;

const StyledMain = styled(Main)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;

    ${stylesWhenBinge`
        --acw-offer-button-width: 278px;
        --acw-offer-button-padding: 12px;
        background: ${vader};

        ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
            --acw-offer-button-width: 290px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            --acw-offer-button-width: 408px;
        `}
    `}

    ${stylesWhenFlash`
        --acw-offer-button-width: 228px;
        background: ${black};

        ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
            --acw-offer-button-width: 290px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            --acw-offer-button-width: 408px;
        `}
    `}

    ${stylesWhenLifestyle`
        --acw-offer-button-width: 278px;
        --acw-offer-button-padding: 12px;
        background: ${midnight};

        ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
            --acw-offer-button-width: 290px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            --acw-offer-button-width: 408px;
        `}
    `}
`;

const StyledSection = styled(Section)`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    z-index: 0;
    width: 100%;

    ${stylesWhenBinge`
        padding-top: 26px;
        padding-bottom: 38px;

        ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
            padding-top: 86px;
            padding-bottom: 112px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
            padding-top: 92px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            padding-top: 62px;
            padding-bottom: 80px;
        `}
    `}

    ${stylesWhenFlash`
        padding: 56px 0;

        ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
            padding: 86px 0;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
            padding: 92px 0;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            padding: 125px 0;
        `}
    `}

    ${stylesWhenKayo`
        background-color: ${ironStone};

        ${mediaQuery({minWidthPx: SCREEN_TABLET})`
            padding-top: 86px;
            padding-bottom: 112px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
            padding-top: 92px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_PRETTY_LG_DESKTOP})`
            padding-top: 62px;
            padding-bottom: 80px;
        `}

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(to bottom, ${coal}, transparent);
            width: 100%;
            height: 29px;
            content: '';

            ${mediaQuery({minWidthPx: SCREEN_TABLET})`
                height: 35px;
            `}

            ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
                height: 44px;
            `}
        }
    `}

    ${stylesWhenLifestyle`
        padding-top: 26px;
        padding-bottom: 38px;

        ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
            padding-top: 86px;
            padding-bottom: 112px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
            padding-top: 92px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            padding-top: 62px;
            padding-bottom: 80px;
        `}
    `}
`;

const ThinContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 7px;
    width: 100%;
    max-width: 600px;
`;

const ContentWrapper = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    max-width: ${VOUCHER_IMAGE_DESKTOP_MAX_WIDTH_PX};
`;

const OfferComponent = ({
    brand,
    footnote,
    voucherDisplay,
    footerConfig,
    onContinueWithoutVoucher,
    isLoading,
    isSignUpDisabled,
    warningModal,
    promoModal,
    children,
    ...props
}) => {
    useEffect(function listenAndHandlePageshow() {
        // Safari has an issue where it caches offer page if you Continue then hit back button, leading to an infinite loading state
        const listener = ({persisted}) => persisted
            ? window.location.reload()
            : null;

        window.addEventListener('pageshow', listener);

        return () => window.removeEventListener('pageshow', listener);
    }, []);

    const isKayo = brand === 'kayo';
    const {backgroundImage} = props.marketingDisplay;
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);

    const OfferContentWrapper = isKayo ? ContentWrapper : Fragment;

    const voucherForm = (
        <VoucherForm
            {...{
                brand,
                voucherDisplay,
                onContinueWithoutVoucher,
            }}
        />
    );

    return (
        <Fragment>
            <StyledMain brand={brand}>
                <Header
                    {...{
                        brand,
                        isLoading,
                        isVideoLoaded,
                        setIsVideoLoaded,
                        ...props,
                    }}
                />

                <StyledSection
                    brand={brand}
                    id="products"
                >

                    <BackgroundImage {...{brand, backgroundImage}} />

                    {/* If we're still loading, we show a blank 'skeleton' of what might be on the page */}
                    {isLoading
                        ? <OfferLoadingSkeleton brand={brand} />
                        : (
                            <OfferContentWrapper>
                                <OfferContent
                                    {...isKayo && {footnote}}
                                    {...{
                                        brand,
                                        voucherForm,
                                        voucherDisplay,
                                        isSignUpDisabled,
                                        ...props,
                                    }}
                                />
                            </OfferContentWrapper>
                        )
                    }

                    {!isSignUpDisabled && !voucherDisplay.isVoucherRequiredTemplate && (
                        <ThinContainer>
                            {voucherForm}
                        </ThinContainer>
                    )}

                </StyledSection>

                {children}

                {warningModal || promoModal}

            </StyledMain>

            {/* We'll always render a <Footer />, if `footerConfig` is falsy we'll
                use default config. This way we're backwards compatible with all
                marketing configs.
             */}
            <Footer {...footerConfig} brand={brand} />
        </Fragment>
    );
};

OfferComponent.displayName = 'Offer';

OfferComponent.propTypes = {
    /** Product brand name */
    brand: brandPropType,
    marketingDisplay: propTypes.shape({
        /**  Background image URL */
        backgroundImage: propTypes.string,
    }),
    /** Footnote message - usually used for price change disclaimers */
    footnote: propTypes.node,
    voucherDisplay: propTypes.shape({
        isVoucherRequiredTemplate: propTypes.bool,
        showRemoveVoucher: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
        onRemoveVoucher: propTypes.func,
    }),
    footerConfig: propTypes.shape({
        /** Legal copy blurb */
        legalCopy: propTypes.string,
        /** Links to T&C, Privacy, etc */
        links: propTypes.arrayOf(propTypes.object),
        /** user id */
        userId: propTypes.string,
    }),
    /** Is SignUp disabled? */
    isSignUpDisabled: propTypes.bool,
    /** Whether the page is in the loading state */
    isLoading: propTypes.bool,
    /** Warning modal */
    warningModal: propTypes.node,
    /** Promotion modal (only displayed if there is no warning modal) */
    promoModal: propTypes.node,
    /** Children */
    children: propTypes.arrayOf(propTypes.element),
    /** Callback for clicking on "Continue without voucher" button*/
    onContinueWithoutVoucher: propTypes.func,
};

export default OfferComponent;
