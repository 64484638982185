// await window.takeScreenshot('basic', target.firstChild.firstChild.getBoundingClientRect());

/**
 * Wrapper for window.takeScreenshot that disallows duplicate file/test names.
 *
 * @param   {string}  name  Name of the file/test. Throws on found duplicates.
 * @param   {...any}  args  Arguments passed to window.takeScreenshot after a name for the file.
 * @throws  {Error}         Thrown error if duplicate name use detected.
 * @returns {Promise}       window.takeScreenshot(name, ...args)
 */
export default async function takeScreenshot(name, ...args) {
    // Should be the only file calling for window.takeScreenshot. Everything else considered an error.
    // eslint-disable-next-line no-restricted-properties
    return window.takeScreenshot(name, ...args);
}
