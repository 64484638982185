import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {rgba} from 'polished';
import classnames from 'classnames';
import {Button} from 'normalized-styled-components';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {slate, kayoGreen, white, silverLining} from '../../../../common/palette';
import IC27ArrowD from '../../ic/27-arrow-d';

const StyledButtonHover = css`
    outline: 0;
    border-color: ${kayoGreen};
`;

const StyledButton = styled(Button).attrs({type: 'button'})`
    appearance: none;
    transition: ${transition('background', 'color', 'border-color')};
    border: 1px solid ${silverLining};
    border-radius: 4px;
    background-color: transparent;
    padding: 15px 0;
    height: 50px;
    white-space: nowrap;
    color: ${silverLining};
    font: var(--mui-primary-button);

    &:hover,
    &:focus {
        ${StyledButtonHover}
    }

    &[aria-pressed='true'] {
        background-color: ${rgba(slate, 0.35)};
        color: ${white};
        font: var(--mui-primary-button-live);

        &:hover,
        &:focus {
            ${StyledButtonHover}
        }
    }
`;

const IconWrapper = styled.span`
    position: relative;
    bottom: -20px;
    left: calc(50% - 10px);
    color: ${slate};
`;

const BA21PayBtn = ({
    'aria-pressed': isPressed,
    children,
    className,
    onClick,
    ...htmlAttributes
}) => (
    <StyledButton
        {...htmlAttributes}
        {...{
            onClick,
        }}
        className={classnames('BA21PayBtn', className)}
        aria-pressed={isPressed}
    >
        {children}
        {!!isPressed && (
            <IconWrapper>
                <IC27ArrowD size="16px" />
            </IconWrapper>
        )}
    </StyledButton>
);

BA21PayBtn.displayName = 'BA21PayBtn';

/* eslint-disable quote-props */
BA21PayBtn.propTypes = {
    /** aria-pressed attribute describes a toggle button */
    'aria-pressed': propTypes.oneOf([true, false, 'mixed']),
    /** Content of button, e.g. text */
    children: propTypes.node.isRequired,
    /** Callback on element click */
    onClick: propTypes.func,
    /** Additional CSS class(es) */
    className: classNameType,
};
/* eslint-enable quote-props */

export default BA21PayBtn;
