import React, {useState, useCallback} from 'react';
import propTypes from 'prop-types';
import noop from 'lodash/noop';
import styled from 'styled-components';
import {rgba} from 'polished';

import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {Button, Input} from '../../../../common/normalized-styled-components';

import {transition} from '../../../../common/animations';
import {black, white} from '../../../../common/palette';
import {fontFamily, lineHeight} from '../../../../common/typography';

const StyledLabel = styled.label`
    position: relative;
    border-radius: 3px;
    width: 100%;
    height: 44px;
    color: ${white};
`;

const FloatingLabelText = styled.span`
    position: absolute;
    top: 45%;
    left: 7px;
    transform: translateY(-50%);
    transition: ${transition('transform', 'opacity', 'color')};
    opacity: ${({isVisible}) => isVisible ? 1 : 0};
    line-height: ${lineHeight};
    font-family: ${fontFamily};
    font-size: 11px;

    ${stylesWhen('isVisible')`
        transform: translateY(-19px);
    `}
`;

const IconButton = styled(Button)`
    position: absolute;
    top: 50%;
    right: ${({hasIcon}) => hasIcon ? 49 : 14}px;
    transform: translateY(-50%);
    border: 0;
    border-bottom: 1px solid ${white};
    background: transparent;
    padding: 0;
    color: ${white};
    font: var(--infinity-body-copy-4);

    &:hover,
    &:focus {
        outline: 0;
    }
`;

const StyledInput = styled(Input)`
    box-sizing: border-box;
    transition: ${transition('box-shadow', 'border-color')};
    border: 0;
    border-bottom: 1px solid ${rgba(white, 0.2)};
    border-radius: 0;
    background-color: inherit;
    padding: 14px 7px 5px;
    width: 100%;
    height: 100%;
    color: ${white};

    ${stylesWhen(({hasRevealPasswordButton, hasIcon}) => !hasRevealPasswordButton && !!hasIcon)`
        /* has icon, but not reveal password */
        padding-right: 49px;
    `}

    ${stylesWhen(({hasRevealPasswordButton, hasIcon}) => !!hasRevealPasswordButton && !hasIcon)`
        /* has reveal password, but not the icon */
        padding-right: 63px;
    `}

    ${stylesWhen(({hasRevealPasswordButton, hasIcon}) => !!(hasRevealPasswordButton && hasIcon))`
        /* has both reveal password and icon */
        padding-right: 105px;
    `}

    ${stylesWhen('isTextCentered')`
        text-align: center;
    `}

    &::placeholder {
        color: ${rgba(white, 0.5)};
    }

    &:empty::placeholder {
        color: ${rgba(white, 0.5)};
    }

    /* stylelint-disable-next-line */
    &::-ms-reveal,
    &::-ms-clear {
        display: none; /* Hide the eyeball icon on Edge */
    }

    &:invalid,
    &:required {
        /* Rely on our own custom designs to communicate invalid and required states */
        box-shadow: none;
    }

    &:empty + ${FloatingLabelText} {
        color: ${rgba(white, 0.5)};
    }

    &:focus {
        outline: 0;
        border-color: ${white};
    }

    &:-webkit-autofill + ${FloatingLabelText} { /* make label legible when background goes yellow due to chrome autofill form */
        color: ${black};
    }

    &:disabled {
        opacity: 0.5;
        border-bottom-color: ${rgba(white, 0.1)};

        &:hover {
            cursor: not-allowed;
        }
    }
`;

const IA01Input = React.forwardRef(({
    defaultValue,
    hasIcon,
    hasRevealPasswordButton,
    isTextCentered,
    label,
    onBlur = noop,
    onChange = noop,
    onFocus = noop,
    pattern,
    type = 'text',
    ...htmlAttributes
}, forwardedRef) => {
    const ref = forwardedRef || React.createRef();
    const [hasFocus, setHasFocus] = useState(false);
    const [hasValue, setHasValue] = useState(!!defaultValue);
    const [showPassword, setShowPassword] = useState(false);

    const handleChange = useCallback(function handleChange(event) {
        setHasValue(!!event.target.value);
        onChange(event);
    }, [onChange]);

    const handleFocus = useCallback(function handleFocus(event) {
        setHasFocus(true);
        onFocus(event);
    }, [onFocus]);

    const handleBlur = useCallback(function handleBlur(event) {
        setHasFocus(false);
        onBlur(event);
    }, [onBlur]);

    const toggleShowPassword = useCallback(function handleToggleShowPassword(event) {
        event.preventDefault();
        setShowPassword(!showPassword);
    }, [showPassword]);

    const showLabel = hasValue || hasFocus;

    const edgeInlineStyleOverride = {font: 'var(--infinity-body-copy-3)'}; // Setting inline style due to Edge and Styled Components not working well with CSS variables

    const placeholder = showLabel ? null : label;

    return (
        <StyledLabel>
            <StyledInput
                {...htmlAttributes}
                defaultValue={defaultValue}
                hasIcon={hasIcon}
                hasRevealPasswordButton={hasRevealPasswordButton}
                isTextCentered={isTextCentered}
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={handleFocus}
                pattern={pattern}
                placeholder={placeholder}
                ref={ref}
                style={edgeInlineStyleOverride}
                title={label}
                type={showPassword ? 'text' : type}
            />

            <FloatingLabelText isVisible={showLabel}>
                {label}
            </FloatingLabelText>

            {type === 'password' && (
                <IconButton
                    aria-label="Reveal Password"
                    aria-pressed={!!showPassword}
                    hasIcon={hasIcon}
                    hidden={!hasRevealPasswordButton}
                    onClick={toggleShowPassword}
                    type="button"
                >
                    {showPassword ? 'Hide' : 'Show'}
                </IconButton>
            )}
        </StyledLabel>
    );
});

IA01Input.propTypes = {
    /** If supplied, will be initial value of the search field */
    defaultValue: propTypes.string,
    /** Disable the input field? */
    disabled: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    /** Placeholder label for input field, which floats above on text input */
    label: propTypes.string.isRequired,
    /** The HTML type of the input field */
    type: propTypes.oneOf(['text', 'password', 'email', 'tel', 'url', 'search', 'number']),
    /** For password inputs, whether or not to render the show/hide button */
    hasRevealPasswordButton: propTypes.bool,
    /** Does it have an icon? */
    hasIcon: propTypes.bool,
    /** Is text centered */
    isTextCentered: propTypes.bool,
    /** Callback on text field value change */
    onChange: propTypes.func,
    /** Focus event handler */
    onFocus: propTypes.func,
    /** Blur event handler */
    onBlur: propTypes.func,
    /** Validation regex pattern */
    pattern: propTypes.string,
};

IA01Input.displayName = 'IA01Input';

export default IA01Input;
