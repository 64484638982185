import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import noop from 'lodash/noop';
import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {SCREEN_1400_DESKTOP} from '../../../../../../common/screen-sizes';
import {ink} from '../../../../../../common/palette';
import BA01BtnPr from '../../../../../atoms/ba/01-btn-pr';
import BA28BtnSec from '../../../../../atoms/ba/28-btn-sec';
import BA52ConversionBtn from '../../../../../atoms/ba/52-conversion-btn';

const SignupCtaContainer = styled.section`
    display: none;

    ${mediaQuery({minWidthPx: SCREEN_1400_DESKTOP})`
        display: flex;
        padding: 0 20px;
    `}
`;

/** ********************************************
 *             On Header Nav
 ***********************************************/

const SignupCtaMessage = styled.p`
    display: block;
    padding-right: 30px;
    max-height: 1.2em;
    overflow: hidden;
    color: ${ink};
    font: var(--quicksilver-body-copy-4-light);
    mask-image: linear-gradient(to right, #000 520px, transparent); /* stylelint-disable-line color-no-hex */

    ${mediaQuery({minWidthPx: SCREEN_1400_DESKTOP})`
        font-size: 16px;
    `}
`;

const CtaButtonsWrapper = styled.span`
    display: flex;
    align-items: center;
    column-gap: 10px;
`;

const ctaButtonStyles = css`
    padding: 7px 21px;
    min-width: 110px;
    min-height: 40px;
    font-size: 14px;
`;

const StyledBA01BtnPr = styled(BA01BtnPr)`
    ${mediaQuery({minWidthPx: SCREEN_1400_DESKTOP})`
        ${ctaButtonStyles}
    `}
`;

const StyledBA28BtnSec = styled(BA28BtnSec)`
    ${mediaQuery({minWidthPx: SCREEN_1400_DESKTOP})`
        ${ctaButtonStyles}
    `}
`;

/** ********************************************
 *             Footer
 ***********************************************/

const StyledBA52ConversionBtn = styled(BA52ConversionBtn)`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;

    ${mediaQuery({minWidthPx: SCREEN_1400_DESKTOP})`
        display: none;
    `}
`;

// eslint-disable-next-line jsdoc/require-param, jsdoc/require-returns
/**
 * This section contains the Signup/Signin CTA section
 * - on screens `< 1400`, it shows up as sticky footer
 * - on screens `>= 1400`, it shows up on the right side of the header nav
 */
const SignupCtaSection = ({
    ctaMessage,
    signInCtaCopy,
    signUpCtaCopy,
    signUpUrl,
    onSigninClick = noop,
    onSignUpClick = noop,
}) => (
    <React.Fragment>
        <SignupCtaContainer>
            {!!ctaMessage && (
                <SignupCtaMessage>
                    {ctaMessage}
                </SignupCtaMessage>
            )}

            <CtaButtonsWrapper>
                <StyledBA01BtnPr
                    isBlock={false}
                    href={signUpUrl}
                    onClick={onSignUpClick}
                >
                    {signUpCtaCopy}
                </StyledBA01BtnPr>

                <StyledBA28BtnSec
                    isBlock={false}
                    onClick={onSigninClick}
                >
                    {signInCtaCopy}
                </StyledBA28BtnSec>
            </CtaButtonsWrapper>
        </SignupCtaContainer>

        {/* *** SHOW AS FOOTER ON SMALLER SCREENS *** */}
        {!!ctaMessage && (
            <StyledBA52ConversionBtn
                href={signUpUrl}
                onClick={onSignUpClick}
            >
                {ctaMessage}
            </StyledBA52ConversionBtn>
        )}
    </React.Fragment>
);

SignupCtaSection.displayName = 'SignupCtaSection';

SignupCtaSection.propTypes = {
    /** Shows Signup CTA label for new users */
    ctaMessage: propTypes.string,
    /** Sign In CTA copy */
    signInCtaCopy: propTypes.string,
    /** Sign Up CTA copy */
    signUpCtaCopy: propTypes.string,
    /** URL for sign-up */
    signUpUrl: propTypes.string,
    /** Action on clicking sign-in */
    onSigninClick: propTypes.func,
    /** Action on clicking sign-up */
    onSignUpClick: propTypes.func,
};

export default SignupCtaSection;
