import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import {Section} from 'normalized-styled-components';
import {rgba} from 'polished';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {blanc, panther} from '../../../../common/palette';

const StyledSection = styled(Section)`
    margin: 0 auto;
    width: 100%;
    text-align: center;
    text-shadow: 0 0 3px ${rgba(panther, 0.3)};
    color: ${blanc};
`;

const StyledH2 = styled.h2`
    margin: 0 0 5px;
    font: var(--ionic-header-4);
`;

const Copy = styled.p`
    margin: 0;
    font: var(--ionic-body-copy-2);
`;

const OR16MarBlk = ({
    className,
    displayHeader,
    displaySubtext,
    ...htmlAttributes
}) => (
    <StyledSection
        {...htmlAttributes}
        className={classnames('OR16MarBlk', className)}
    >
        {!!displayHeader && <StyledH2>{displayHeader}</StyledH2>}
        {!!displaySubtext && <Copy>{displaySubtext}</Copy>}
    </StyledSection>
);

OR16MarBlk.propTypes = {
    /** Additional class name(s) */
    className: classNameType,
    /** CMS block heading copy */
    displayHeader: propTypes.node,
    /** CMS copy */
    displaySubtext: propTypes.node,
};

OR16MarBlk.displayName = 'OR16MarBlk';

export default OR16MarBlk;
