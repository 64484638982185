import React from 'react';

import Ic from '..';

const IC562Up = (args) => (
    <Ic {...args}>
        <path d="M46.3 62.6H7.1c-.6 0-1.1-.5-1.1-1.1v-23c0-.6.5-1.1 1.1-1.1h39.2c.6 0 1.1.5 1.1 1.1v23c0 .6-.5 1.1-1.1 1.1zM8.2 60.4h37V39.6h-37v20.8zM93.4 62.6H53.2c-.3 0-.6-.2-.6-.6V37.9c0-.3.2-.6.6-.6h40.3c.3 0 .6.2.6.6v24.2c-.1.3-.4.5-.7.5zm-39.7-1.1h39.2v-23H53.7v23z" />
    </Ic>
);

IC562Up.displayName = 'IC562Up';

export default IC562Up;
