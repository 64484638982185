import React from 'react';
import Ic from '..';

const IC25ArrowR = (args) => (
    <Ic {...args}>
        <path fillRule="nonzero" d="M35.566 89.276 30 83.766l33.976-33.628L30 16.509 35.566 11 75.11 50.138z" />
    </Ic>
);

IC25ArrowR.displayName = 'IC25ArrowR';

export default IC25ArrowR;
