import styled from 'styled-components';
import {A} from 'normalized-styled-components';
import {rgba} from 'polished';

import {transition} from '../../../../common/animations';
import {silverLining, slate, white} from '../../../../common/palette';

const StyledLink = styled(A)`
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: ${transition('border-color', 'color')};
    border: 1px solid ${rgba(silverLining, 0.25)};
    border-radius: 5px;
    background-color: transparent;
    padding: 7px 14px;
    max-width: 367px;
    min-height: 40px;
    text-align: center;
    text-decoration: none;
    color: ${slate};
    font: var(--mui-hud-tile-copy);

    &:focus,
    &:hover {
        outline: 0;
        border-color: ${white};
        color: ${white};
    }
`;

export default StyledLink;
