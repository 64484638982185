import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import {Main} from 'normalized-styled-components';

import {palette as infinityPalette} from '@fsa-streamotion/streamotion-web-infinity';
import {palette as ionicPalette} from '@fsa-streamotion/streamotion-web-ionic';
import {palette as muiPalette} from '@fsa-streamotion/streamotion-web-mui';
import {palette as nucleusPalette} from '@fsa-streamotion/streamotion-web-nucleus';

import brandPropType from '../../../common/brand-proptype';
import {
    stylesWhenBinge,
    stylesWhenFlash,
    stylesWhenKayo,
    stylesWhenLifestyle,
} from '../../../utils/styles-when-brand';

const {fog} = infinityPalette;
const {akela} = ionicPalette;
const {slate} = muiPalette;
const {smoke: nucleusSmoke} = nucleusPalette;

const StyledMain = styled(Main)`
    ${stylesWhenBinge`
        background-color: ${rgba(akela, 0.1)};
    `}

    ${stylesWhenFlash`
        background-color: ${rgba(fog, 0.1)};
    `}

    ${stylesWhenKayo`
        background-color: ${rgba(slate, 0.1)};
    `}

    ${stylesWhenLifestyle`
        background-color: ${rgba(nucleusSmoke, 0.1)};
    `}
`;

const LandingPageTemplate = ({
    brand,
    children,
    warningModal,
}) => (
    <StyledMain brand={brand}>
        {children}
        {warningModal}
    </StyledMain>
);

LandingPageTemplate.propTypes = {
    /** Brand name */
    brand: brandPropType,
    /** Children of template */
    children: propTypes.node,
    /** Warning modal */
    warningModal: propTypes.node,
};

LandingPageTemplate.displayName = 'LandingPageTemplate';

export default LandingPageTemplate;
