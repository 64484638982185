import React from 'react';

import Ic from '..';

const IC05Exp = (args) => (
    <Ic {...args}>
        <path d="M95 57.237v15.462c0 5.988-4.871 10.86-10.858 10.86H62.27v-5h21.872A5.866 5.866 0 0 0 90 72.699V57.237h5zm-85 0v15.462a5.867 5.867 0 0 0 5.859 5.86h22.917v5H15.859C9.871 83.559 5 78.687 5 72.699V57.237h5zm85-14.916V26.859C95 20.871 90.129 16 84.142 16H62.27v5h21.872A5.865 5.865 0 0 1 90 26.859v15.462h5zm-85 0V26.859A5.866 5.866 0 0 1 15.859 21h22.917v-5H15.859C9.871 16 5 20.871 5 26.859v15.462h5z" />
    </Ic>
);

IC05Exp.displayName = 'IC05Exp';

export default IC05Exp;
