import React, {useState, useEffect, useRef} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {stylesWhen, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import BM02Tooltip from '../../../atoms/bm/02-tooltip';

const Z_INDEX = 1; // Tooltips appear below buttons otherwise.

const TooltipWrapper = styled.div`
    position: absolute;
    transform-origin: ${({isLeftAligned}) => isLeftAligned ? '11px' : 'calc(100% - 11px)'} 0;
    transition: ${transition('transform', 'opacity')};
    z-index: ${Z_INDEX};
    padding-top: 14px;

    ${stylesWhenNot('isTooltipVisible')`
        transform: scale(0.95);
        opacity: 0;
        pointer-events: none;
    `}

    ${stylesWhenNot('isLeftAligned')`
        right: 0;
    `}

    ${stylesWhen('isFullWidthTooltip')`
        width: 100%;
    `}
`;

const OR121TooltipCta = ({
    arrowOrientation = 'left',
    className,
    cta,
    isArrowVisible,
    isLightTheme,
    isFullWidthTooltip,
    children,
    ...htmlAttributes
}) => {
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);

    const tooltipWrapperRef = useRef(null);

    const onActivateTooltip = () => {
        setIsTooltipVisible(true);
    };

    const onDeactivateTooltip = () => {
        // To future devs: if there’s a link/button/tabbable element inside the tooltip, and the user tabs to it, the tooltip is going to close :grimace: it’s crappy, but this is how the cookie crumbles.
        setIsTooltipVisible(false);
    };

    const handleClickAway = (e) => {
        if (!(e.target === tooltipWrapperRef.current || tooltipWrapperRef.current.contains(e.target))) {
            onDeactivateTooltip();
        }
    };

    useEffect(() => {
        document.addEventListener('touchend', handleClickAway, {passive: true});

        return () => {
            document.removeEventListener('touchend', handleClickAway, {passive: true});
        };
    });

    const CtaWithTooltip = React.cloneElement(cta, {
        'aria-haspopup': true,
        'onClick': onActivateTooltip,
        'onMouseOver': onActivateTooltip,
        'onFocus': onActivateTooltip,
        'onMouseOut': onDeactivateTooltip,
        'onBlur': onDeactivateTooltip,
    });

    return (
        <div
            {...htmlAttributes}
            className={classnames('OR121TooltipCta', className)}
            ref={tooltipWrapperRef}
        >
            {CtaWithTooltip}
            <TooltipWrapper
                isTooltipVisible={isTooltipVisible}
                isLeftAligned={arrowOrientation === 'left'}
                isFullWidthTooltip={isFullWidthTooltip}
            >
                <BM02Tooltip
                    arrowOrientation={arrowOrientation}
                    isArrowVisible={isArrowVisible}
                    isLightTheme={isLightTheme}
                    isInitiallyVisible={true}
                >
                    {children}
                </BM02Tooltip>
            </TooltipWrapper>
        </div>
    );
};

OR121TooltipCta.displayName = 'OR121TooltipCta';

OR121TooltipCta.propTypes = {
    /** Foreground items */
    children: propTypes.node,
    /** className */
    className: classNameType,
    /** The element upon which the tooltip will be controlled */
    cta: propTypes.element,
    /** Positioning of the arrow header */
    arrowOrientation: propTypes.oneOf(['left', 'right']),
    /** Whether to show the arrow head or not */
    isArrowVisible: propTypes.bool,
    /** Should tooltip take up full width? */
    isFullWidthTooltip: propTypes.bool,
    /** Is light background, dark foreground? */
    isLightTheme: propTypes.bool,
};

export default OR121TooltipCta;
