import React from 'react';
import {colorType} from '@fsa-streamotion/custom-prop-types';
import Ic from '..';

const IC136Fire = ({color, ...args}) => (
    <Ic {...args} color={color}>
        <g fillRule="evenodd" fill={color}>
            <path fillRule="nonzero" d="M63 51.1V11.3c0-1.5-1.2-2.8-2.8-2.8H39.8c-1.5 0-2.8 1.2-2.8 2.8v39.8c-5.8 4.2-9.3 10.8-9.3 18.1 0 12.3 10 22.2 22.2 22.2 12.3 0 22.2-10 22.2-22.2.1-7.2-3.3-13.9-9.1-18.1zM50 85.9c-9.2 0-16.7-7.5-16.7-16.7 0-5.8 3-11.1 7.9-14.2.8-.5 1.3-1.4 1.3-2.4V14.1h14.8v38.5c0 1 .5 1.9 1.3 2.4 5 3.1 7.9 8.4 7.9 14.2.2 9.2-7.3 16.7-16.5 16.7z" />
            <path d="M51.4 61.5V29.7h-2.8v31.8c-3.7.7-6.4 3.8-6.4 7.7 0 4.3 3.5 7.8 7.8 7.8s7.8-3.5 7.8-7.8c0-3.8-2.8-7-6.4-7.7z" />
        </g>
    </Ic>
);

IC136Fire.displayName = 'IC136Fire';

IC136Fire.propTypes = {
    color: colorType,
};

export default IC136Fire;
