import React from 'react';
import Ic from '..';

const IC260Switch = (args) => (
    <Ic {...args}>
        <path d="m86.129 54.457-9.226 9.339V27.512h-8.35v36.284l-9.226-9.339-5.9 5.972 19.3 19.537L92.03 60.429zM45.41 38.132l-19.3-19.536L6.807 38.132l5.9 5.972 9.226-9.324v36.27h8.35V34.78l9.226 9.324z" />
    </Ic>
);

IC260Switch.displayName = 'IC260Switch';

export default IC260Switch;
