import React from 'react';
import Ic from '..';

const IC283Channels = (args) => (
    <Ic {...args}>
        <defs>
            <linearGradient x1="0%" y1="71.388%" x2="77.994%" y2="70.633%" id="ic283-channels__linear-gradient">
                <stop stopColor="currentColor" stopOpacity="0" offset="0%" />
                <stop stopColor="currentColor" offset="99.231%" />
                <stop stopColor="currentColor" offset="100%" />
            </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd">
            <path fill="url(#ic283-channels__linear-gradient)" fillRule="nonzero" d="M88.5 30v48.5H13v-5h70.5V30z" />
            <path fill="currentColor" d="m39.5 43 20 9.957L39.5 63z" />
            <path fill="currentColor" fillRule="nonzero" d="M88.551 27.5v5H15.5v39.95h-5V27.5z" />
        </g>
    </Ic>
);

IC283Channels.displayName = 'IC283Channels';

export default IC283Channels;
