import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import {rgba} from 'polished';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {ironStone} from '../../../../common/palette';

const Z_INDEX_BELOW_OTHER_STUFF = -1;

const StyledDiv = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: ${Z_INDEX_BELOW_OTHER_STUFF};
    background-color: ${rgba(ironStone, 0.3)};
    width: 100%;
    height: 100%;
`;

const GA69HudCardBg = ({className, ...htmlAttributes}) => (
    <StyledDiv
        {...htmlAttributes}
        className={classNames('GA69HudCardBg', className)}
    />
);

GA69HudCardBg.displayName = 'GA69HudCardBg';

GA69HudCardBg.propTypes = {
    /** Additional class(es) */
    className: classNameType,
};

export default GA69HudCardBg;
