import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import property from 'lodash/property';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import {Section} from 'normalized-styled-components';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {billingPeriodType} from '../../../common/custom-proptypes';
import {midnight, white, slate, silverLining} from '../../../common/palette';

import BA20NavBtn from '../../atoms/ba/20-nav-btn';
import TM24AlertTile from '../../molecules/tm/24-alert-tile';
import TM27InfoTile from '../../molecules/tm/27-info-tile';
import {IC61Ios} from '../../atoms/ic';
import BA08BtnSec from '../../atoms/ba/08-btn-sec';
import BA03RadBtn from '../../atoms/ba/03-rad-btn';

const GroupHeading = styled.h3`
    margin: 0 0 14px;
    padding: 0 14px;
    color: ${white};
    font: var(--mui-header-6-bold);
`;

const Group = styled(Section)`
    margin: 0 0 35px;
`;

const Groups = styled.div`
    display: grid;
    grid-row-gap: 7px;
`;

const BottomGroups = styled.div`
    display: grid;
    grid-row-gap: 35px;
`;

const TabNavList = styled.ul`
    display: grid;
    grid-row-gap: 7px;
    margin: 0;
    padding: 0;
    list-style-type: none;
`;

const TabNavListItem = styled.li`
    display: flex;
    border-radius: 4px;
    background-color: ${midnight};
`;

const ButtonWrapper = styled.div`
    text-align: center;
`;

const ErrorMessageWrapper = styled.div`
    margin: 0 10px;
`;

const ResubscribeText = styled.p`
    margin: 0;
    padding: 0 14px;
    color: ${slate};
    font: var(--mui-body-copy-3);

    > strong {
        color: ${silverLining};
        font-weight: normal;
    }
`;

const CancellationContainer = styled.section`
    padding-top: 28px;
`;

const OddsAndWageringToggleButton = styled(BA03RadBtn)`
    margin-bottom: 7px;
`;

// putting the copy in this span to work around the Safari no-focus-on-click bug (https://bugs.webkit.org/show_bug.cgi?id=22261)
const OddsAndWageringBtnCopy = styled.span`
    position: absolute;
    left: 14px;
    transform: translateY(100%);
    color: ${silverLining};
`;

// using this wrapper to handle MS Edge
const OddsAndWageringBtnCopyWrapper = styled.div`
    position: relative;
    height: 100%;
`;

const OddsAndWageringLearnMoreLink = styled.a`
    padding-left: 14px;
    color: ${silverLining};
    font: var(--mui-body-copy-3);
`;

export default function AccountTemplate({
    accountMenuItems = [],
    isItunesSubscription,
    itunesSubscriptionTitle,
    itunesSubscriptionMessage,
    currentSubscription,
    subscriptionAndBillingTiles,
    subscriptionAndBillingMenuItems = [],
    oddsAndWageringPreference = {},
    oddsAndWageringPreferenceClick = noop,
    isRootUser,
    oddsAndWageringLearnMoreUrl,
    wageringAndFixturesToggle,
    preferenceUpdateErrorMessage,
    additionalMenuItemGroups = [],

    onClickCancelSubscription,
    onClickResubscribe,
    resubscribeTextLines = [],

    isPendingAdjustment,
    adjustmentType,
}) {
    const {subscribed, name, toggle_name: toggleName} = oddsAndWageringPreference;

    return (
        <Groups>
            {accountMenuItems.length > 0 && (
                <Group>
                    <GroupHeading>
                        Account
                    </GroupHeading>
                    <TabNavList>
                        {accountMenuItems.map(({label, ...rest}) => (
                            <TabNavListItem key={label}>
                                <BA20NavBtn {...{label, ...rest}} />
                            </TabNavListItem>
                        ))}
                    </TabNavList>
                </Group>
            )}

            {(!!currentSubscription || subscriptionAndBillingMenuItems.length > 0) && (
                <Group>
                    <GroupHeading>
                        Subscription and billing
                    </GroupHeading>

                    {isItunesSubscription ? (
                        <TM27InfoTile
                            icon={<IC61Ios size="43px" />}
                            title={itunesSubscriptionTitle}
                        >
                            {itunesSubscriptionMessage}
                        </TM27InfoTile>
                    ) : (
                        <Groups>
                            {subscriptionAndBillingTiles}
                            {subscriptionAndBillingMenuItems.length > 0 && (
                                <TabNavList>
                                    {subscriptionAndBillingMenuItems.map(({label, ...rest}) => (
                                        <TabNavListItem key={label}>
                                            <BA20NavBtn {...{label, ...rest}} />
                                        </TabNavListItem>
                                    ))}
                                </TabNavList>
                            )}
                        </Groups>
                    )}
                </Group>
            )}

            {wageringAndFixturesToggle && (!isEmpty(oddsAndWageringPreference) && isRootUser) && (
                <Group>
                    <GroupHeading>
                        Odds
                    </GroupHeading>

                    <OddsAndWageringToggleButton
                        isBlock={true}
                        aria-pressed={!!subscribed}
                        title="Change your odds setting"
                        onClick={() => void oddsAndWageringPreferenceClick(!subscribed)}
                    >
                        <OddsAndWageringBtnCopyWrapper>
                            <OddsAndWageringBtnCopy>
                                {
                                    subscribed
                                        ? name
                                        : toggleName
                                }
                            </OddsAndWageringBtnCopy>
                        </OddsAndWageringBtnCopyWrapper>
                    </OddsAndWageringToggleButton>

                    <OddsAndWageringLearnMoreLink
                        href={oddsAndWageringLearnMoreUrl}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        Learn More
                    </OddsAndWageringLearnMoreLink>
                </Group>
            )}

            {!!preferenceUpdateErrorMessage && (
                <ErrorMessageWrapper>
                    <TM24AlertTile>
                        {preferenceUpdateErrorMessage}
                    </TM24AlertTile>
                </ErrorMessageWrapper>
            )}

            {additionalMenuItemGroups.filter(property('menuItems.length')).map(({name, menuItems}) => (
                <Group key={name}>
                    <GroupHeading>
                        {name}
                    </GroupHeading>
                    <TabNavList>
                        {menuItems.map(({label, ...rest}) => (
                            <TabNavListItem key={label}>
                                <BA20NavBtn {...{label, ...rest}} />
                            </TabNavListItem>
                        ))}
                    </TabNavList>
                </Group>
            ))}

            {!isItunesSubscription && !!currentSubscription && (
                <CancellationContainer>
                    {isPendingAdjustment && adjustmentType === 'Cancel' ? (
                        <BottomGroups>
                            <ResubscribeText>
                                {resubscribeTextLines.join(' ')}
                            </ResubscribeText>
                            <ButtonWrapper>
                                <BA08BtnSec
                                    isBlock={false}
                                    onClick={onClickResubscribe}
                                >
                                    Reactivate Now
                                </BA08BtnSec>
                            </ButtonWrapper>
                        </BottomGroups>
                    ) : (
                        <ButtonWrapper>
                            <BA08BtnSec
                                isBlock={false}
                                onClick={onClickCancelSubscription}
                            >
                                Cancel subscription
                            </BA08BtnSec>
                        </ButtonWrapper>
                    )}
                </CancellationContainer>
            )}
        </Groups>
    );
}

AccountTemplate.displayName = 'AccountTemplate';

AccountTemplate.propTypes = {
    /** Account menu items */
    accountMenuItems: propTypes.arrayOf(propTypes.shape({
        /** Label */
        label: propTypes.string,
        /** URL */
        href: propTypes.string,
        /** Click handler */
        onClick: propTypes.func,
    })),
    currentSubscription: propTypes.shape({
        /** Currency prefix */
        currencyPrefix: propTypes.oneOf(['$']),
        /** Currency decimal places */
        currencyDecimalPlaces: propTypes.number,
        /** Product name */
        productName: propTypes.string,
        /** Original price which the user will be charged each billing cycle following the offer period */
        originalPrice: propTypes.number,
        /** Billing period */
        billingPeriod: billingPeriodType,
        /** List of features to display */
        features: propTypes.arrayOf(propTypes.string),
        /** List of additional details to display */
        additionalDetails: propTypes.arrayOf(propTypes.string),
        /** Optional additional class(es) */
        className: classNameType,
    }),
    /** E.g. subscription, promotions and payment tiles */
    subscriptionAndBillingTiles: propTypes.node,
    subscriptionAndBillingMenuItems: propTypes.arrayOf(propTypes.shape({
        /** Label */
        label: propTypes.string,
        /** URL */
        href: propTypes.string,
        /** Click handler */
        onClick: propTypes.func,
    })),
    oddsAndWageringPreference: propTypes.shape({
        /** Button text when wagering on */
        name: propTypes.string,
        /** Button text when wagering off */
        toggle_name: propTypes.string,
        subscribed: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    }),
    oddsAndWageringPreferenceClick: propTypes.func,
    isRootUser: propTypes.bool,
    oddsAndWageringLearnMoreUrl: propTypes.string,
    wageringAndFixturesToggle: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    /** Error message to display if the update fails */
    preferenceUpdateErrorMessage: propTypes.string,
    additionalMenuItemGroups: propTypes.arrayOf(propTypes.shape({
        name: propTypes.string,
        menuItems: propTypes.arrayOf(propTypes.shape({
            /** Label */
            label: propTypes.string,
            /** URL */
            href: propTypes.string,
            /** Click handler */
            onClick: propTypes.func,
        })),
    })),
    /** Cancel subscription button click handler */
    onClickCancelSubscription: propTypes.func,
    /** Reactivate now button click handler */
    onClickResubscribe: propTypes.func,
    /** Resubscription sales text */
    resubscribeTextLines: propTypes.arrayOf(propTypes.string),

    isItunesSubscription: propTypes.bool,
    itunesSubscriptionTitle: propTypes.node,
    itunesSubscriptionMessage: propTypes.node,
    isPendingAdjustment: propTypes.bool,
    adjustmentType: propTypes.string,
};
