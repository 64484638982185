import {rgba} from 'polished';
import {css} from 'styled-components';
import {
    mediaQuery,
    stylesIfElse,
} from '@fsa-streamotion/styled-component-helpers';

import {transition} from '../animations';
import {black, cobalt, indigo, ink, white} from '../palette';
import {SCREEN_1920_DESKTOP} from '../screen-sizes';

const interactiveStyles = css`
    /** FOCUS / HOVER STATE */
    &:hover,
    &:focus {
        outline: 0;
        background-color: ${cobalt};
    }
    /** ON PRESS / SELECTED STATE */
    &:active,
    &[aria-pressed='true'] {
        background-color: ${indigo};
    }
    /** SELECTED & IN FOCUS/HOVER STATE */
    &[aria-pressed='true'] {
        &:hover,
        &:focus {
            outline: 0;
            background-color: ${cobalt};
        }
    }
`;

const iconBaseCtaStyles = css`
    appearance: none;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    transition: ${transition('background-color', 'color')};
    border: 0;
    border-radius: 50%;
    box-shadow: 0 1px 1px 0 ${rgba(black, 0.3)};
    padding: 8px;
    width: 40px;
    height: 40px;
    object-fit: contain;
    color: ${white};

    ${({backgroundColor}) => backgroundColor && `
        background-color: ${backgroundColor};
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding: 10px;
        width: 60px;
        height: 60px;
    `}

    ${stylesIfElse('disabled')`
        opacity: 0.6;
        background-color: ${rgba(ink, 0.2)};
        cursor: default;
        color: ${rgba(ink, 0.3)};

        /** SELECTED & DISABLED */
        &[aria-pressed='true'] {
            background-color: ${cobalt};
            color: ${white};
        }
    ``
        ${interactiveStyles}
    `}
`;

export default iconBaseCtaStyles;
