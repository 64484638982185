import pick from 'lodash/pick';
import get from 'lodash/get';
import invoke from 'lodash/invoke';
import omit from 'lodash/omit';
import subtract from 'lodash/subtract';
import divide from 'lodash/divide';
import add from 'lodash/add';
import multiply from 'lodash/multiply';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';

/**
 * Set of fp style wrappers around traditional lodash functions
 * (as lodash/fp is an unusually large package)
 */

/**
 * FP Helper for lodash/pick
 *
 * @param {string|string[]} paths Path(s) to pick
 * @returns {Function} partial function
 */
export function pickFp(paths) {
    return (object) => pick(object, paths);
}

/**
 * FP Helper for lodash/get
 *
 * @param {string} path Obj path to get
 * @param {*} [defaultValue] default value to return on `undefined`
 * @returns {Function} partial function
 */
export function getFp(path, defaultValue) {
    return (object) => get(object, path, defaultValue);
}

/**
 * FP Helper for lodash/invoke
 *
 * @param {string} path Obj path to invoke
 * @param {...*} args args to apply to function
 * @returns {Function} partial function
 */
export function invokeFp(path, ...args) {
    return (object) => invoke(object, path, ...args);
}

/**
 * FP Helper for lodash/omit
 *
 * @param {string|string[]} paths Path(s) to omit
 * @returns {Function} partial function
 */
export function omitFp(paths = []) {
    return (object) => omit(object, paths);
}

/**
 * FP Helper for lodash/find. Fixed arity of 2
 *
 * @param {Function|Object} predicate Use object shorthand, or callback function
 * @returns {Function} partial function
 */
export function findFp(predicate) {
    return (object) => find(object, predicate);
}

// Math Helpers

/**
 * FP Helper for lodash/add
 * adding two numbers
 *
 * @param {number} addend The second number in an addition
 * @returns {Function} partial function
 */
export function addFp(addend) {
    return (augend) => add(augend, addend);
}

/**
 * FP Helper for lodash/subtract
 * subtracting two numbers
 *
 * @param {number} subtrahend The second number in a subtraction
 * @returns {Function} partial function
 *
 * @example
 * const takeAway5 = subtractFp(2);
 *
 * [1, 100].map(takeAway5); // [-4, 95]
 */
export function subtractFp(subtrahend) {
    return (minuend) => subtract(minuend, subtrahend);
}

/**
 * FP Helper for lodash/divide
 * dividing two numbers
 *
 * @param {number} divisor The second number in a division
 * @returns {Function} partial function
 *
 * @example
 * const halveIf = divideFp(2);
 *
 * [1, 100].map(doubleIt); // [0.5, 50]
 */
export function divideFp(divisor) {
    return (dividend) => divide(dividend, divisor);
}

/**
 * FP Helper for lodash/multiply
 * multiplying two numbers
 *
 * @param {number} multiplicand The second number in a multiplication
 * @returns {Function} partial function
 *
 * @example
 * const doubleIt = multiplyFp(2);
 *
 * [1, 100].map(doubleIt); // [2, 200]
 */
export function multiplyFp(multiplicand) {
    return (multiplier) => multiply(multiplier, multiplicand);
}

/**
 * FP Helper for getting the largest number
 *
 * @param {number} knownValue Known value to compare
 * @returns {Function} partial function
 *
 * @example
 * const atLeast10 = maxFp(10);
 *
 * [1, 100].map(atLeast10); // [10, 100]
 */
export function maxFp(knownValue) {
    return (nextValue) => Math.max(knownValue, nextValue);
}

/**
 * FP Helper for getting the smallest number
 *
 * @param {number} knownValue Known values to find the smallest of
 * @returns {Function} partial function
 *
 * @example
 * const capTo10 = minFp(10);
 *
 * [1, 100].map(capTo10); // [1, 10]
 */
export function minFp(knownValue) {
    return (nextValue) => Math.min(knownValue, nextValue);
}

/**
 * Logging point-free javascript can be a bit annoying. Use this fella and you'll be fine, its basically the same as a .doLog() in bacon
 * Note that it only copes with unary functions (e.g. functions that accept/return a single value)
 *
 * @param {...*} args Optional prefixes to the console.log call
 * @returns {Function} a function which will log arguments given to it and return them
 *
 * @example
 * // simple log
 * flow(
 *    property('something'),
 *    logFp(), // console.log(something)
 *    pickFp(['someProp', 'someOtherProp']),
 * );
 *
 * @example
 * // log with title
 * flow(
 *    property('something'),
 *    logFp('Some Title'), // console.log('Some Title', something)
 *    pickFp(['someProp', 'someOtherProp']),
 * );
 */
export function logFp(...args) {
    return (payload) => {
        console.log(...args, payload); // eslint-disable-line no-console

        return payload;
    };
}

/**
 * FP Helper for lodash/isEqual
 *
 * @see [isEqual](https://lodash.com/docs/4.17.15#isEqual)
 *
 * @example
 * // filtering on specific values
 * ['one', 'two', 'three'].filter(isEqualFp('two')) // ['two']
 *
 * @param {*} value The value to compare
 * @returns {Function} partial function wrapping isEqual
 */
 export function isEqualFp(value) {
    return (other) => isEqual(other, value);
}
