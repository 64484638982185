import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import classnames from 'classnames';

import {mediaQuery, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';
import {Button} from '../../../../common/normalized-styled-components';
import {white, black, flash, buttonActiveState} from '../../../../common/palette';
import {transition} from '../../../../common/animations';
import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';

const StyledButton = styled(Button)`
    appearance: none;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    transition: ${transition('background-color', 'color')};
    border: 0;
    border-radius: 50%;
    box-shadow: 0 1px 1px 0 ${rgba(black, 0.3)};
    background-color: ${rgba(white, 0.2)};
    padding: 8px;
    width: 40px;
    height: 40px;
    object-fit: contain;
    color: ${white};

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding: 10px;
        width: 60px;
        height: 60px;
    `}

    &:hover,
    &:focus {
        ${stylesWhenNot('disabled')`
            outline: 0;
            background-color: ${flash};
        `}
    }

    &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
        color: ${rgba(white, 0.8)};
    }

    &:active {
        background-color: ${buttonActiveState};
        color: ${white};
    }
`;

const BA38BtnIconPrimary = React.forwardRef(({
    'aria-label': assistiveLabel,
    children,
    onClick,
    disabled = false,
    className,
    ...htmlAttributes
}, ref) => (
    <StyledButton
        {...htmlAttributes}
        aria-label={assistiveLabel}
        disabled={disabled}
        title={assistiveLabel}
        onClick={onClick}
        className={classnames('BA38BtnIconPrimary', className)}
        type="button"
        ref={ref}
    >
        {children}
    </StyledButton>
));

/* eslint-disable quote-props */
BA38BtnIconPrimary.propTypes = {
    /** Child to accept */
    children: propTypes.node.isRequired,
    /** Assistive label required for screen readers due to graphical only representation of content */
    'aria-label': propTypes.string.isRequired,
    /** Callback to call on element click */
    onClick: propTypes.func,
    /** Disable the button */
    disabled: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    /** Additional CSS class(es) */
    className: classNameType,
};
/* eslint-enable quote-props */

BA38BtnIconPrimary.displayName = 'BA38BtnIconPrimary';

export default BA38BtnIconPrimary;
