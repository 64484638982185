import React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

const StyledSvg = styled.svg`
    stroke: ${({color}) => color ? color : 'currentColor'};
`;

const GA23CaArrow = ({
    className,
    color,
}) => (
    <StyledSvg
        className={classnames('GA23CaArrow', className)}
        color={color}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 57 98"
    >
        <path
            fill="none"
            strokeLinecap="square"
            strokeWidth="8"
            d="M6 92l45-43L6 6"
        />
    </StyledSvg>
);

GA23CaArrow.displayName = 'GA23CaArrow';

GA23CaArrow.propTypes = {
    /** Additional class(es) */
    className: classNameType,
    /** Colour of the arros */
    color: propTypes.string,
};

export default GA23CaArrow;
