import React, {useEffect, useMemo} from 'react';
import propTypes from 'prop-types';
import {Section, Button} from 'normalized-styled-components';
import {rgba} from 'polished';
import styled from 'styled-components';
import classnames from 'classnames';
import property from 'lodash/property';
import noop from 'lodash/noop';

import {mediaQuery, stylesWhenNot, stylesWhen, stylesIfElse} from '@fsa-streamotion/styled-component-helpers';
import {colorType, classNameType} from '@fsa-streamotion/custom-prop-types';

import GA23CaArrow from '../../../atoms/ga/23-ca-arrow';
import {
    SCREEN_768_TABLET,
    SCREEN_1024_DESKTOP,
    SCREEN_1280_DESKTOP,
    SCREEN_1480_DESKTOP,
    SCREEN_1680_DESKTOP,
    SCREEN_1920_DESKTOP,
    SCREEN_2560_DESKTOP,
} from '../../../../common/screen-sizes';
import {transition} from '../../../../common/animations';
import {blanc, onyx} from '../../../../common/palette';
import {CONTENT_EDGE_SPACING_PERCENT} from '../../../../common/style-constants';

const Z_INDEX_ON_TOP_OF_CONTENT = 1;
const ZINDEX_ACTIVE_SLIDE = 1;

// TODO: In the future when movies comes in, this slide will change its height depending on whether or not the child TM04Ft has certain attributes, like:
// extendedInfo
// tags

const NavBtn = styled(Button)`
    appearance: none;
    display: none;
    position: absolute;
    top: 2px;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);
    transition: ${transition('color', 'opacity')};
    opacity: 0.5;
    border: 0;
    background: 0;
    padding: 0;
    height: 37px;
    color: ${blanc};

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        display: flex; /* On smaller screen sizes only show the arrow on hover, as they will probably swipe instead */
        height: 35px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        height: 52px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1680_DESKTOP})`
        height: 60px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        height: 75px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
        height: 85px;
    `}

    &:hover,
    &:focus {
        opacity: 1;
        outline: 0;
    }
`;

const NextButton = styled(NavBtn)`
    right: ${CONTENT_EDGE_SPACING_PERCENT / -2}%;
    z-index: ${ZINDEX_ACTIVE_SLIDE};
`;

const BaseStyledSection = styled(Section)`
    display: flex;
    position: relative;
    flex-direction: column;
    padding-bottom: 0;
    width: 100%;
    height: 100%; /* take the height of the container, so long as its taller than our minimum ratio */
`;

const Iceberg = styled(BaseStyledSection)`
    position: absolute;
    top: 0;
    left: 91vw;
    opacity: 0.6;
    z-index: ${Z_INDEX_ON_TOP_OF_CONTENT};
    pointer-events: none;
`;

const StyledSectionWithGradients = styled(BaseStyledSection)`
    /* transitions out/in the flat background color alongside the background image when a trailer is playing */
    ${stylesIfElse('isBackgroundVisible')`
        transition: background-color 2s ease-in-out;
        background-color: ${property('gradientColor')};
    ``
        transition: background-color 0.2s ease-in-out;
        background-color: transparent;
    `}

    /* immediately hide the entire section, as this is NOT the active slide */
    ${stylesWhenNot('isVisible')`
        opacity: 0;
    `}

    &::before, /* these horizontal gradients meet along an opaque centre */
    &::after {
        position: absolute;
        bottom: 0;
        opacity: ${({isVisible}) => isVisible ? 1 : 0};
        width: 100%;
        content: '';
        pointer-events: none;
    }

    &::before { /* Top gradient */
        bottom: 0;
        z-index: ${Z_INDEX_ON_TOP_OF_CONTENT};
        /** Sorry stylelint, this structure is better in our opinion :P */
        /* stylelint-disable */
        background: linear-gradient(
            to top,
            ${({gradientColor}) => `${gradientColor} 14%,
            ${rgba(gradientColor, 0.45)} 63%`},
            transparent 81%
        );
        /* stylelint-enable */
        height: 417px;

        ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
            bottom: -8vw;
            background: linear-gradient(to bottom, transparent 17%, ${property('gradientColor')});
            height: 24vw;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
            background: linear-gradient(
                to top,
                ${({gradientColor}) => `${gradientColor} 10%,
                ${rgba(gradientColor, 0.6)} 60%,
                transparent`}
            );
        `}
    }

    &::after { /* Bottom gradient */
        transform: translateY(100%);
        /** Sorry stylelint, this structure is better in our opinion :P */
        /* stylelint-disable */
        background: linear-gradient(
            to bottom,
            ${({gradientColor}) => `${gradientColor} 4%,
            ${rgba(gradientColor, 0.45)} 47%`},
            transparent 100%
        );
        /* stylelint-enable */
        height: 114px;

        ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
            bottom: -8vw;
            background: linear-gradient(to top, transparent, ${property('gradientColor')});
            height: 90px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
            height: 120px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1480_DESKTOP})`
            height: 140px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            height: 180px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
            height: 420px;
        `}
    }

    &:hover ${NextButton} {
        display: flex;
    }
`;

const TopSpacer = styled.div`
    width: 100%;
    height: 59%;
    min-height: 26%;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        height: 50%;
    `}
`;

const ContentPlacement = styled.div`
    position: relative;
    z-index: ${Z_INDEX_ON_TOP_OF_CONTENT};
    margin-right: ${CONTENT_EDGE_SPACING_PERCENT}%;
    margin-bottom: 15px;
    margin-left: ${CONTENT_EDGE_SPACING_PERCENT}%;

    ${stylesWhen('hasRaisedCta')`
        margin-top: var(--CAM02HeroSlide-raised-cta, 40px);
    `}
`;

const Background = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    /* transitions out the background that is below the content */
    ${stylesIfElse('isBackgroundVisible')`
        transition: none;
        opacity: 1;
    ``
        transition: opacity 2s ease-in-out;
        opacity: 0;
    `}

    width: 100%;
    height: 100%;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        height: calc(100% + 8vw);
    `}

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        visibility: hidden;
        background: linear-gradient(to right, ${({gradientColor}) => `${gradientColor}, ${rgba(gradientColor, 0.6)} 34%, transparent 66%`});
        width: 75vw;
        height: 100%;
        content: '';

        ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
            visibility: visible;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
            width: 56.25vw;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1480_DESKTOP})`
            width: 64.83vw;
        `}
    }
`;

const TransitionContainer = styled.div`
    transition: opacity 0.2s ease-in-out;
    opacity: ${({isVisible}) => isVisible ? 1 : 0};

    width: 100%;
    height: 100%;
`;

const PrevButton = styled(NavBtn)`
    left: ${CONTENT_EDGE_SPACING_PERCENT / -2}%;
    z-index: ${ZINDEX_ACTIVE_SLIDE + 1};
`;

const CAM02HeroSlide = React.forwardRef(({
    children,
    color,
    backgroundImage,
    isBackgroundVisible,
    className,
    gradientColor = onyx,
    hasRaisedCta,
    isBeingLazy,
    isPreview,
    isVisible = true,
    onNext,
    onPrev,
    isChildOfActiveHero = false,
    updateTheme = noop,
}, ref) => {
    const StyledSection = isPreview ? Iceberg : StyledSectionWithGradients;

    useEffect(() => {
        if (!!ref && isVisible && isChildOfActiveHero) {
            updateTheme({colorPrimary: color, colorSecondary: gradientColor});
        }
    }, [backgroundImage]); // eslint-disable-line react-hooks/exhaustive-deps

    const safeGradientColor = useMemo(() => {
        if (/^#[a-fA-F0-9]{6}$/.test(gradientColor)) {
            return gradientColor;
        }

        return onyx;
    }, [gradientColor]);

    return (
        <StyledSection
            className={classnames('CAM02HeroSlide', className)}
            gradientColor={safeGradientColor}
            isVisible={isVisible}
            isBackgroundVisible={isBackgroundVisible}
        >
            {!isPreview // in preview mode, the slide will appear "on top" of the active slide so doesn't have a background
                && React.isValidElement(backgroundImage) // stop React.cloneElement exceptions on unexpected data
                && (
                    <Background isVisible={isVisible} gradientColor={safeGradientColor} isBackgroundVisible={isBackgroundVisible}>
                        <TransitionContainer isVisible={isVisible}>
                            {React.cloneElement(backgroundImage, {isBeingLazy})}
                        </TransitionContainer>
                    </Background>
                )}
            <TopSpacer />
            <ContentPlacement hasRaisedCta={hasRaisedCta}>
                {!isPreview && !!onPrev && (
                    <PrevButton
                        onClick={onPrev}
                        aria-label="View previous"
                        tabIndex="-1"
                    >
                        <GA23CaArrow isLeft={true} />
                    </PrevButton>
                )}

                {React.Children.map(
                    children,
                    (child, index) => index === 0 && React.isValidElement(child) ? (
                        React.cloneElement(child, {isPreview, isVisible})
                    ) : child,
                )}

                {!isPreview && !!onNext && (
                    <NextButton
                        onClick={onNext}
                        aria-label="View next"
                        tabIndex="-1"
                    >
                        <GA23CaArrow />
                    </NextButton>
                )}
            </ContentPlacement>
        </StyledSection>
    );
});

CAM02HeroSlide.propTypes = {
    /** Content of the slide, e.g. TM04Ft, or in some cases it can be null such as hero carousel loader */
    children: propTypes.node,
    /** Background image, e.g. a video or category poster */
    backgroundImage: propTypes.element,
    /** Additional className(s) to apply */
    className: classNameType,
    /** Main themed color */
    color: colorType,
    /** Applied to the left and bottom of the slide. Specifically NOT using the global CSS property: the hero should never inherit colours from elsewhere */
    gradientColor: colorType,
    /** Is slide layer visible (e.g. as toggled by a containing carousel) */
    isVisible: propTypes.bool,
    /** Is the background visible, a false value makes the background entirely transparent so content below is visible */
    isBackgroundVisible: propTypes.bool,
    /** Whether the slide is in the active hero */
    isChildOfActiveHero: propTypes.bool,
    /** Callback to update the global themes e.g. colors */
    updateTheme: propTypes.func,
    /** Should the slide be in a lazy/pre-load state: e.g. hold off loading its images */
    isBeingLazy: propTypes.any,
    /** Should the CTAs sit higher up the slide? For example if the container is extra tall, so the CTAs are not offscreen */
    hasRaisedCta: propTypes.bool,
    /** Is this slide a preview "iceberg" */
    isPreview: propTypes.bool,
    /** Callback for "prev" navigation button. Leave undefined to have no carousel button */
    onPrev: propTypes.func,
    /** Callback for "next" navigation button. Leave undefined to have no carousel button */
    onNext: propTypes.func,
};

CAM02HeroSlide.displayName = 'CAM02HeroSlide';

export default CAM02HeroSlide;
