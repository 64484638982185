import {useState} from 'react';
import attempt from 'lodash/attempt';

/**
 * This hook creates event callbacks for package selection
 *
 * @param {Object} options options
 * @param {String} options.defaultSelectedPackageId default selected package tier id
 * @param {boolean} options.isDeselectingAllowed is deselecting a package allowed?
 * @param {boolean} options.isDisabled is package selector disabled?
 * @param {boolean} options.isLoading is package selector loading?
 * @param {Function} options.onChange callback when package has been selected
 *
 * @returns {{onKeyDown: Function, onPackageSelect: Function, selectedPackageId: String}} flags and callbacks
 */
const usePackageSelector = ({
    defaultSelectedPackageId,
    isDeselectingAllowed,
    isDisabled,
    isLoading,
    onChange,
}) => {
    const [selectedPackageId, setSelectedPackageId] = useState(defaultSelectedPackageId);

    /** Event callback for when user selects a package (via click or keyboard press)
     *
     * @param {String} packageId price id of focused package
     */
    const onPackageSelect = (packageId) => {
        if (isDisabled || isLoading) {
            return;
        }

        const newSelectedPackagePriceId = (isDeselectingAllowed && packageId === selectedPackageId)
            ? null // if they click on a previously selected package AND deselecting is allowed, deselect it
            : packageId;

        setSelectedPackageId(newSelectedPackagePriceId);

        if (onChange) {
            attempt(onChange(newSelectedPackagePriceId));
        }
    };

    /**
     * Event callback for when user presses enter or spacebar
     * - it should select focused package
     *
     * @param {KeyboardEvent} event event
     * @param {String} packageId price id of focused package
     */
    const onKeyDown = (event, packageId) => {
        if (event.key === 'Enter' || event.key === ' ') { // Space key is ' '
            event.preventDefault(); // Stop the Space key from scrolling the page
            onPackageSelect(packageId);
        }
    };

    return {
        onKeyDown,
        onPackageSelect,
        selectedPackageId,
    };
};

export default usePackageSelector;
