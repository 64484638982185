import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {Section} from '../../../../common/normalized-styled-components';
import {ink, white} from '../../../../common/palette';
import {SCREEN_1920_DESKTOP, SCREEN_768_TABLET} from '../../../../common/screen-sizes';
import GM11Expander from '../../../molecules/gm/11-expander';
import GM25PriceCallout from '../../../molecules/gm/25-price-callout';
import {IC26ArrowU, IC27ArrowD} from '../../../atoms/ic';
import BA22EditBtn from '../../../atoms/ba/22-edit-btn';

const Container = styled(Section)`
    display: flex;
    position: relative;
    flex-direction: column;
    border-radius: 6px;
    background-color: ${white};
    padding: 21px 14px;
    overflow: hidden;
    color: ${rgba(ink, 0.8)};
    font: var(--quicksilver-body-copy-3);

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding: 28px 21px;
    `}
`;

const SubtotalWrapper = styled.div`
    margin-bottom: 14px;
    font: var(--quicksilver-body-copy-4);

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        margin-bottom: 21px;
    `}
`;

const Title = styled.div`
    display: flex;
    flex: 1;
    font: var(--quicksilver-header-6);
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

const PayTodayPrice = styled(GM25PriceCallout)`
    height: 35px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        height: 50px;
    `}
`;

const StyledBA22EditBtn = styled(BA22EditBtn)`
    position: absolute;
    right: 30px;
`;

const StyledGM11Expander = styled(GM11Expander)`
    width: 100%;
`;

const LineItemName = styled.div`
    flex: 1;
    margin-right: 20px;
`;

function formatPrice(price) {
    const maximumFractionDigits = price % 1 ? 2 : 0;

    return new Intl.NumberFormat('en-AU', {style: 'currency', currency: 'AUD', maximumFractionDigits}).format(price);
}

/**
 * OR140SubscriptionCard
 *  - Used on Review Order Page for New Subscriptions block and Pay Today block
 *
 * @see {@link https://zpl.io/O0gmnZ8}
 */
const OR140SubscriptionDetails = ({
    className,
    creditApplied,
    detailedContent,
    isMoreInfoInitiallyExpanded = false,
    payToday,
    onClickEdit,
    shouldCollapseLineItems,
    subtotal,
    title,
    ...htmlAttributes
}) => (
    <Container
        {...htmlAttributes}
        className={classnames('OR140SubscriptionDetails', className)}
    >
        {(Number.isFinite(subtotal) || !!creditApplied) && (
            <SubtotalWrapper>
                {Number.isFinite(subtotal) && (
                    <Row>
                        <LineItemName>Subtotal</LineItemName>
                        {formatPrice(subtotal)}
                    </Row>
                )}
                {!!creditApplied && (
                    <Row>
                        <LineItemName>Credit Applied</LineItemName>
                        {
                            // Credit will always be displayed as a negative value
                            formatPrice(-Math.abs(creditApplied))
                        }
                    </Row>
                )}
            </SubtotalWrapper>
        )}

        <Row>
            <Title>{title}</Title>
            {Number.isFinite(payToday) && <PayTodayPrice displayAmount={payToday} />}
        </Row>

        {!!detailedContent && (
            <Row>
                {shouldCollapseLineItems ? (
                    <StyledGM11Expander
                        expandButtonLabel="More Info"
                        expandIcon={<IC27ArrowD />}
                        collapseButtonLabel="Less Info"
                        collapseIcon={<IC26ArrowU />}
                        isInitiallyExpanded={isMoreInfoInitiallyExpanded}
                    >
                        {detailedContent}
                    </StyledGM11Expander>
                )
                    : detailedContent}
            </Row>
        )}

        {!!onClickEdit && <StyledBA22EditBtn onClick={onClickEdit}>Edit</StyledBA22EditBtn>}
    </Container>
);

OR140SubscriptionDetails.propTypes = {
    /** Credit/discount applied to order */
    creditApplied: propTypes.number,
    /** Subscriptions content */
    detailedContent: propTypes.node,
    /** Is more info section initially expanded? */
    isMoreInfoInitiallyExpanded: propTypes.bool,
    /** Total payment required for order */
    payToday: propTypes.number,
    /** Callback when the user clicks edit */
    onClickEdit: propTypes.func,
    /** Whether we should collapse line items inside a More Info button */
    shouldCollapseLineItems: propTypes.bool,
    /** Subtotal of the order price without credits applied */
    subtotal: propTypes.number,
    /** Title */
    title: propTypes.node,
    /** Additional class name(s) */
    className: classNameType,
};

OR140SubscriptionDetails.displayName = 'OR140SubscriptionCard';
export default OR140SubscriptionDetails;
