import React from 'react';
import styled from 'styled-components';
import property from 'lodash/property';
import propTypes from 'prop-types';
import classnames from 'classnames';
import {rgba} from 'polished';
import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {srcsetOptionsType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {indigo, mist} from '../../../../common/palette';
import {SCREEN_375_PHABLET, SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import stringifySrcsetOptions from '../../../../common/stringify-srcset-options';
import {getLargestImage} from '../../../../common/tile-utils';

const StyledDiv = styled.div`
    box-sizing: border-box;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    background-color: ${rgba(mist, 0.3)};
    padding: 10px 5px;
    width: 110px;
    height: 110px;
    color: ${indigo};

    ${mediaQuery({minWidthPx: SCREEN_375_PHABLET})`
        width: 145px;
        height: 145px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        border-radius: 4.7px;
        width: 170px;
        height: 170px;
        padding: 14px 18px;
    `}
`;

/* ************************************
*           HARDWARE IMAGE
**************************************/

const ImageWrapper = styled.div`
    margin-top: auto;
    margin-bottom: auto;
    width: 100px;
    height: 70px;

    ${mediaQuery({minWidthPx: SCREEN_375_PHABLET})`
        width: 130px;
        height: 100px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 135px;
        height: 115px;
    `}
`;

const DeviceImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
`;

/* ************************************
*                LABEL
**************************************/

const TileLabel = styled.div`
    margin-top: auto;
    margin-bottom: auto;
    max-width: 90%;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    font: var(--quicksilver-body-copy-3-light);
`;

/* ************************************
*              COUNT
**************************************/

const ItemCount = styled.div`
    display: flex;
    position: absolute;
    top: -7.5px;
    right: -7.5px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${mist};
    width: 20px;
    height: 20px;
    font: var(--quicksilver-body-copy-5-medium);

    ${mediaQuery({minWidthPx: SCREEN_375_PHABLET})`
        width: 24px;
        height: 24px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        top: -8.5px;
        right: -8.5px;
        width: 28px;
        height: 28px;
        font: var(--quicksilver-body-copy-4-medium);
    `}

    &::before {
        content: '${property('itemCount')}';
    }
`;

/**
 * ### TM37HardwareTile
 *
 * A tile component that displays: device image, label & device count
 */
const TM37HardwareTile = ({
    className,
    itemCount,
    label,
    srcsetOptions,
    srcsetSizes,
    ...htmlAttributes
}) => (
    <StyledDiv
        {...htmlAttributes}
        className={classnames('TM37HardwareTile', className)}
    >
        {!!itemCount && (
            <ItemCount itemCount={itemCount} />
        )}
        {!!srcsetOptions && (
            <ImageWrapper>
                <DeviceImage
                    src={getLargestImage(srcsetOptions)}
                    srcSet={stringifySrcsetOptions(srcsetOptions)}
                    sizes={srcsetSizes}
                    alt={label}
                />
            </ImageWrapper>
        )}
        {!!label && (
            <TileLabel>{label}</TileLabel>
        )}
    </StyledDiv>
);

TM37HardwareTile.displayName = 'TM37HardwareTile';

TM37HardwareTile.propTypes = {
    /** Additional class(es) */
    className: classNameType,
    /** Count of devices of this type */
    itemCount: propTypes.number,
    /** Tile label and also alt text for image */
    label: propTypes.string,
    /** Srcset for tile image */
    srcsetOptions: srcsetOptionsType,
    /** Srcset sizes */
    srcsetSizes: propTypes.string,
};

export default TM37HardwareTile;
