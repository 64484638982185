export const DISABLE_SIGNUP_HEADING_TEXT = 'Sign up and Registration is currently unavailable'; // eslint-disable-line import/prefer-default-export

export const MY_ACCOUNT_LINK = {
    staging: 'https://staging.hubbl.com.au/my-account',
    production: 'https://hubbl.com.au/my-account',
};

export const BRAND_NAMES = ['binge', 'flash', 'kayo', 'lifestyle', 'magneto'];
export const TERM_OPTIONS = ['day', 'week', 'month', 'annual'];

export const FEEDBACK_IFRAME_URL_PACKAGE_ID = '69KUJA';
