import React from 'react';
import Ic from '..';

const IC28RelVid = (args) => (
    <Ic {...args}>
        <path d="M79.648 27.4v25.48H40.266V27.4h39.382m8.4-8.4H31.866v42.28h56.182V19z" />
        <path d="M68.98 71.136H22.038V39.664h4.2v27.272H68.98z" />
        <path d="M58.942 81.048H12V49.576h4.2v27.272h42.742z" />
    </Ic>
);

IC28RelVid.displayName = 'IC28RelVid';

export default IC28RelVid;
