import styled, {css} from 'styled-components';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {SCREEN_PHONE, SMALLEST_SUPPORTED_WIDTH} from '../../../../common/screen-sizes';

const spacerSetToQueries = ({sizeSet}) => {
    if (!sizeSet) {
        return null;
    }

    const sortedSet = Object.keys(sizeSet).sort();

    return sortedSet.map((key) => {
        const sizeValue = sizeSet[key];
        const isDefault = parseFloat(key) === SCREEN_PHONE
            || parseFloat(key) === SMALLEST_SUPPORTED_WIDTH;
        const isSizeZero = new RegExp(/^[0]+[a-z%]*$/).test(sizeValue);

        if (isDefault && isSizeZero) {
            // if the height is 0 for the minimum width, don’t create a useless CSS property
            return null;
        } else if (isDefault) {
            // otherwise, create a default CSS property
            return css`
                padding-bottom: ${sizeValue};
            `;
        }

        // for everything else, create CSS properties inside media queries
        return mediaQuery({minWidthPx: key})`
            padding-bottom: ${sizeValue};
        `;
    });
};

// Usage
// <VerticalSpacer sizeSet={{320: '5%', 415: '14px', 768: '2em'}} />
// <VerticalSpacer sizeFixed="14px" />

const VerticalSpacer = styled.div.attrs(({sizeFixed, sizeSet}) => !sizeSet && ({
    style: {
        paddingBottom: sizeFixed,
    },
    className: 'VerticalSpacer',
}))`
    display: block;
    content: '';

    ${spacerSetToQueries}
`;

VerticalSpacer.displayName = 'VerticalSpacer';

export default VerticalSpacer;
