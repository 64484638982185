import React from 'react';
import Ic from '..';

const IC01Ham = (args) => (
    <Ic {...args}>
        <g fillRule="nonzero" transform="translate(8.966 16.931)">
            <rect width="81.924" height="5.793" rx="2.897" />
            <rect width="81.924" height="5.793" y="30.009" rx="2.897" />
            <rect width="81.924" height="5.793" y="60.018" rx="2.897" />
        </g>
    </Ic>
);

IC01Ham.displayName = 'IC01Ham';

export default IC01Ham;
