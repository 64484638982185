import React from 'react';
import propTypes from 'prop-types';

import {srcsetOptionsType} from '@fsa-streamotion/custom-prop-types';

import stringifySrcsetOptions from './stringify-srcset-options';

const SrcsetSource = ({srcsetOptions, minWidthPx, minHeightPx, orientation, sizes}) => {
    const mediaQuery = [
        minWidthPx && `(min-width: ${minWidthPx}px)`,
        minHeightPx && `(min-height: ${minHeightPx}px)`,
        orientation && `(orientation: ${orientation})`,
    ]
        .filter(Boolean)
        .join(' and ');

    return (
        <source
            media={mediaQuery || undefined}
            sizes={sizes}
            srcSet={stringifySrcsetOptions(srcsetOptions)}
        />
    );
};

SrcsetSource.displayName = 'SrcsetSource';

SrcsetSource.propTypes = {
    srcsetOptions: srcsetOptionsType,
    minWidthPx: propTypes.number,
    minHeightPx: propTypes.number,
    orientation: propTypes.oneOf([
        'landscape',
        'portrait',
    ]),
    sizes: propTypes.string,
};

export default SrcsetSource;
