import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';

import {mediaQuery, stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType, srcsetOptionsType} from '@fsa-streamotion/custom-prop-types';

import {A} from '../../../common/normalized-styled-components';
import {smoke, fog, white} from '../../../common/palette';
import {SCREEN_PHONE, SMALLEST_SUPPORTED_WIDTH, SCREEN_1280_DESKTOP, SCREEN_1920_DESKTOP} from '../../../common/screen-sizes';
import VisuallyHidden from '../../../common/visually-hidden';

import IC103Loading from '../../atoms/ic/103-loading';
import IC24ArrowL from '../../atoms/ic/24-arrow-l';
import GA05BgImg from '../../atoms/ga/05-bg-img';
import TM24AlertTile from '../../molecules/tm/24-alert-tile';

const spacerSetToQueries = ({sizeSet}) => {
    if (!sizeSet) {
        return null;
    }

    const sortedSet = Object.keys(sizeSet).sort();

    return sortedSet.map((key) => {
        const sizeValue = sizeSet[key];
        const isDefault = parseFloat(key) === SCREEN_PHONE
            || parseFloat(key) === SMALLEST_SUPPORTED_WIDTH;
        const isSizeZero = new RegExp(/^[0]+[a-z%]*$/).test(sizeValue);

        if (isDefault && isSizeZero) {
            // if the height is 0 for the minimum width, don’t create a useless CSS property
            return null;
        } else if (isDefault) {
            // otherwise, create a default CSS property
            return css`
                margin-bottom: ${sizeValue};
            `;
        }

        // for everything else, create CSS properties inside media queries
        return mediaQuery({minWidthPx: key})`
            margin-bottom: ${sizeValue};
        `;
    });
};

// Usage
// <VerticalSpacer sizeSet={{320: '5%', 415: '14px', 768: '2em'}} />
// <VerticalSpacer sizeFixed="14px" />

export const VerticalSpacer = styled.div.attrs(({sizeFixed, sizeSet}) => !sizeSet && ({
    style: {
        marginBottom: sizeFixed,
    },
}))`
    display: block;
    content: '';

    ${spacerSetToQueries}
`;

VerticalSpacer.displayName = 'VerticalSpacer';

const MyAccountCtasLabel = styled.p`
    margin: 0 0 21px;
    text-align: center;
    color: ${white};
    font: var(--infinity-body-copy-3-bold);
`;

const ButtonWrapper = styled.table`
    margin: 0 auto;
    min-width: 250px;
    border-spacing: 14px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        min-width: 320px;
    `}
`;

const StyledTable = styled.table`
    border: 0;
    padding: 0 40px;
    width: 100%;
    color: ${smoke};
    font: var(--infinity-body-copy-5);
`;

const StyledTh = styled.th`
    width: fit-content;
    text-align: left;
    color: ${fog};
    font: var(--infinity-body-copy-5-bold);

    &::after {
        content: ':';
    }
`;

const StyledTd = styled.td`
    text-align: right;
`;

export const MyAccountTransactions = ({
    transactionsList = [],
}) => (
    <StyledTable>
        <VisuallyHidden as="caption">Payment history</VisuallyHidden>
        <VisuallyHidden as="thead">
            <tr>
                <th scope="col">Type</th>
                <th scope="col">Name</th>
                <th scope="col">Date</th>
                <th scope="col">Amount</th>
            </tr>
        </VisuallyHidden>
        <tbody>
            {transactionsList.map(({type, name, date, amount}) => (
                <tr key={`${date}__${name}`}>
                    <StyledTh scope="row">{type}</StyledTh>
                    <td>{name}</td>
                    <StyledTd>{date}</StyledTd>
                    <StyledTd>{amount}</StyledTd>
                </tr>
            ))}
        </tbody>
    </StyledTable>
);

MyAccountTransactions.displayName = 'MyAccountTransactions';

MyAccountTransactions.propTypes = {
    transactionsList: propTypes.arrayOf(
        propTypes.shape({
            type: propTypes.string,
            name: propTypes.string,
            date: propTypes.string,
            amount: propTypes.string,
        }),
    ),
};

export const MyAccountCtas = ({
    label,
    children,
}) => (
    <React.Fragment>
        {!!label && <MyAccountCtasLabel>{label}</MyAccountCtasLabel>}
        <ButtonWrapper>
            <tbody>
                {React.Children.map(children, (child) => (
                    <tr key={child.key}>
                        <td>
                            {child}
                        </td>
                    </tr>
                ))}
            </tbody>
        </ButtonWrapper>
    </React.Fragment>
);

MyAccountCtas.displayName = 'MyAccountCtas';

MyAccountCtas.propTypes = {
    label: propTypes.node,
    children: propTypes.node,
};

const HeadingWrapper = styled.div`
    display: grid;
    grid-template-columns: 44px [heading] auto  44px;
    align-items: center;
    margin-bottom: 49px;
    padding-top: 28px;
`;

const StyledLink = styled(A)`
    box-sizing: border-box;
    display: inline-block;
    padding: 5px 24px 5px 0; /* design is left-aligned arrow, but keep the good clickable size */
    height: 100%;
    text-decoration: none;
    color: ${rgba(white, 0.7)};

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        padding-right: 20px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding-right: 12px;
    `}

    &:focus,
    &:hover {
        outline: 0;
        color: ${white};
    }
`;

const Heading = styled.h1`
    grid-column-start: heading;
    margin: 0;
    padding: 0 14px;
    text-align: center;
    text-transform: capitalize;
    color: ${white};
    font: var(--infinity-header-5-bold);
`;

const Wrapper = styled.div`
    height: 100%;
    min-height: 100vh;
`;

const StyledGA05BgImg = styled(GA05BgImg)`
    position: fixed;
    top: 0;
    left: 0;
`;

const Page = styled.div`
    box-sizing: border-box;
    position: relative;
    margin: 0 auto;
    padding: 70px 14px 21px;
    width: 100%;
    max-width: 644px;
    color: ${smoke};
    font: var(--infinity-body-copy-4);

    ${stylesWhen('isApp')`
        padding-top: 0;
    `}
`;

const ErrorMessageContainer = styled.div`
    margin: 0 auto 14px;
    width: 100%;
    max-width: 565px;
`;

const StyledIC103Loading = styled(IC103Loading)`
    margin: 0 auto;
`;

const loadingSpinner = (
    <StyledIC103Loading
        color={white}
        display="block"
        size="70px"
        ariaLabel="Loading page"
    />
);

const MyAccountPage = ({
    backUrl,
    banner,
    children,
    className,
    defaultImageSrc,
    errorMessage = '',
    isApp,
    isLoading,
    mobilePhabletSrcsetOptions,
    onBackClick,
    srcsetOptions,
    title,
}) => {
    let content;

    if (errorMessage) {
        content = (
            <ErrorMessageContainer>
                <TM24AlertTile>
                    {errorMessage}
                </TM24AlertTile>
            </ErrorMessageContainer>
        );
    } else if (isLoading) {
        content = loadingSpinner;
    } else {
        content = children;
    }

    return (
        <Wrapper className={classnames('TemplateMyAccountPage', className)}>
            {!!(srcsetOptions || defaultImageSrc) && (
                <StyledGA05BgImg
                    srcsetOptions={srcsetOptions}
                    defaultImageSrc={defaultImageSrc}
                    mobilePhabletSrcsetOptions={mobilePhabletSrcsetOptions}
                />
            )}
            <Page isApp={isApp}>
                {banner}
                <HeadingWrapper>
                    {!!(onBackClick || backUrl) && (
                        <StyledLink aria-label="Go back" href={backUrl} onClick={onBackClick}>
                            <IC24ArrowL />
                        </StyledLink>
                    )}
                    <Heading>
                        {title}
                    </Heading>
                </HeadingWrapper>

                {content}
            </Page>
        </Wrapper>
    );
};

MyAccountPage.propTypes = {
    /** URL to visit on back click */
    backUrl: propTypes.string,
    /** Banner that seemingly goes above the template itself */
    banner: propTypes.node,
    /** children to display with page */
    children: propTypes.node,
    /** Additional class(es) */
    className: classNameType,
    /** Image to use for browsers that don't support `<picture>`. Defaults to largest `srcSetOption` screen size */
    defaultImageSrc: propTypes.string,
    /** Generic error message that replaces all content */
    errorMessage: propTypes.node,
    /** Whether the page is viewed within an app */
    isApp: propTypes.bool,
    /** Is the page loading */
    isLoading: propTypes.bool,
    /** SrcsetOptions object to use for mobile and phablet viewports */
    mobilePhabletSrcsetOptions: srcsetOptionsType,
    /** What to do on back click */
    onBackClick: propTypes.func,
    /** SrcsetOptions object to use for everything other than mobile and phablet viewports */
    srcsetOptions: srcsetOptionsType,
    /** Title of the page */
    title: propTypes.string,
};

MyAccountPage.displayName = 'MyAccountPage';

export default MyAccountPage;
