import React from 'react';
import Ic from '..';

const IC27ArrowD = (args) => (
    <Ic {...args}>
        <path d="M50 71.8c-.7 0-1.4-.3-2-.8L9.1 32.1l3.9-3.9 37 36.9 36.9-36.9 3.9 3.9L52 71c-.6.5-1.3.8-2 .8z" />
    </Ic>
);

IC27ArrowD.displayName = 'IC27ArrowD';

export default IC27ArrowD;
