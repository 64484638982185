import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import CommonTransition from '../../../../../common/common-transition';
import {fadeInOut} from '../../../../../common/animations';
import HoverThumbnailView from './view';

const Container = styled.section`
    pointer-events: none;
    ${fadeInOut}
`;

const VPTM02HoverThumbnail = ({
    url,
    isVisible = false,
    isTabletUp,
    thumbnailWidth,
}) => (
    <CommonTransition in={isVisible}>
        <Container>
            <HoverThumbnailView url={url} isTabletUp={isTabletUp} thumbnailWidth={thumbnailWidth} />
        </Container>
    </CommonTransition>
);

VPTM02HoverThumbnail.displayName = 'VPTM02HoverThumbnail';

VPTM02HoverThumbnail.propTypes = {
    /** Image URL */
    url: propTypes.any,
    /** Is the component visible? */
    isVisible: propTypes.bool,
    /** Is the screen larger than a tablet? */
    isTabletUp: propTypes.bool,
    /** Thumbnail width*/
    thumbnailWidth: propTypes.number,
};

export default VPTM02HoverThumbnail;
