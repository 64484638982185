import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import noop from 'lodash/noop';
import property from 'lodash/property';
import classnames from 'classnames';
import {rgba} from 'polished';

import {mediaQuery, stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {colorType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {A, Button} from '../../../../common/normalized-styled-components';
import {transition} from '../../../../common/animations';
import VisuallyHidden from '../../../../common/visually-hidden';

import {CONTENT_EDGE_SPACING_PERCENT} from '../../../../common/style-constants';
import {SCREEN_1024_DESKTOP, SCREEN_1280_DESKTOP, SCREEN_1400_DESKTOP} from '../../../../common/screen-sizes';
import {black, blueCharcoal, fog, white} from '../../../../common/palette';
import backdropFilter from '../../../../common/backdrop-filter';

import BA01BtnPr from '../../../atoms/ba/01-btn-pr';
import BA28BtnSec from '../../../atoms/ba/28-btn-sec';
import BA52ConversionBtn from '../../../atoms/ba/52-conversion-btn';
import GA92BrandLogo from '../../../atoms/ga/92-brand-logo';
import {
    IC01Ham,
    IC02Sea,
    IC03Not,
    IC08Cc,
} from '../../../atoms/ic';

const BADGE_SIZE = '18px';
const BADGE_SIZE_LG = '20px';

const NAV_BAR_HEIGHT = '69px';

const OuterContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    padding: 0 ${CONTENT_EDGE_SPACING_PERCENT}%;
    width: 100%;
    height: ${NAV_BAR_HEIGHT};

    &::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: ${transition('background-color', 'opacity')};
        /* Default state */
        border-bottom: 1px solid ${rgba(white, 0.1)};
        background-image: linear-gradient(to bottom, ${rgba(blueCharcoal, 0.5)} 2%, ${rgba(blueCharcoal, 0.15)} 99%);
        content: '';
        /* Scrolled state */
        ${stylesWhen('isScrolled')`
            ${backdropFilter}
            background-color: ${rgba(blueCharcoal, 0.8)};
        `}
        /* Appdock open state */
        ${stylesWhen('isAppDockOpen')`
            ${backdropFilter}
            border-bottom: 1px solid ${rgba(black, 0.3)};
            background-color: transparent;
            background-image: linear-gradient(to bottom, ${rgba(blueCharcoal, 0.8)}, ${rgba(blueCharcoal, 0.7)} 99%);
        `}
    }
`;

const LeftSideContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    height: ${NAV_BAR_HEIGHT};
`;

const HomeLink = styled(A)`
    align-self: flex-start;
    text-decoration: none;
`;

const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    height: ${NAV_BAR_HEIGHT};
`;

const ShowHideTopNavMenu = styled.div`
    display: none; /* specifically not accessible because App Dock will render NM07TopNav until SCREEN_1280_DESKTOP */
    height: 100%;

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        display: block;
    `}
`;

const RightSideContainer = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    text-align: right;
`;

const SignupCtaContainer = styled.div`
    display: none;

    ${mediaQuery({minWidthPx: SCREEN_1400_DESKTOP})`
        display: flex;
        padding: 0 20px;
    `}
`;

const SignupCtaMessage = styled.p`
    display: block;
    padding-right: 30px;
    max-height: 1.2em;
    overflow: hidden;
    color: ${fog};
    font: var(--infinity-body-copy-4-light);
    mask-image: linear-gradient(to right, #000 520px, transparent); /* stylelint-disable-line color-no-hex */

    ${mediaQuery({minWidthPx: SCREEN_1400_DESKTOP})`
        font-size: 16px;
    `}
`;

const CtaButtonsWrapper = styled.span`
    display: flex;
    align-items: center;
    column-gap: 10px;
`;

const ctaButtonStyles = css`
    padding: 7px 21px;
    min-width: 110px;
    font-size: 14px;
`;

const StyledBA01BtnPr = styled(BA01BtnPr)`
    ${mediaQuery({minWidthPx: SCREEN_1400_DESKTOP})`
        ${ctaButtonStyles}
        height: 40px;
    `}
`;

const StyledBA28BtnSec = styled(BA28BtnSec)`
    ${mediaQuery({minWidthPx: SCREEN_1400_DESKTOP})`
        ${ctaButtonStyles}
        min-height: 40px;
    `}
`;

const HeaderFunctionsContainer = styled.div`
    flex: 1 0 auto;
    margin: 11px 0;
    text-align: right;

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        flex-grow: 0;
    `}
`;

const HeaderButton = styled(Button)`
    appearance: none;
    position: relative;
    transition: ${transition('color')};
    border-width: 0;
    background-color: transparent;
    padding: 8px;
    width: 40px;
    height: 40px;
    color: ${rgba(white, 0.7)};

    &:focus,
    &:hover {
        outline: 0;
        color: ${property('color')};
    }

    &::after {
        position: absolute;
        right: 5px;
        bottom: -15px;
        left: 5px;
        transform: scaleX(0);
        transition: ${transition('transform')};
        background-color: ${white};
        height: 2px;
        content: '';
    }

    &[aria-current='true']::after {
        transform: none;
    }

    /* stylelint-disable-next-line order/order */
    &[aria-current='true'],
    &[aria-expanded='true'] {
        outline: 0;
        color: ${white};
    }

    &[aria-expanded='true']:focus {
        transform: scale(1.1);
    }
`;

const NotificationBadge = styled.span`
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    transform: translate(-2px, -3px);
    border-radius: 50%;
    background-color: ${white};
    min-width: ${BADGE_SIZE};
    height: ${BADGE_SIZE};
    color: ${black};
    /* font: var(--infinity-body-copy-6-bold); */ /* @TODO: This font was undefined when it was worked on. Need to reinclude this later */
    line-height: 1; /* stylelint-disable-line order/properties-order */

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        height: ${BADGE_SIZE_LG};
        min-width: ${BADGE_SIZE_LG};
        transform: translate(-1px, -4px);
    `}

    ${stylesWhen(({notificationCount}) => notificationCount > 99)`
        border-radius: 9px;
        padding: 0 5px;
        transform: translate(9px, -4px);

        ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
            transform: translate(13px, -5px);
        `}
    `}
`;

const StyledBA52ConversionBtn = styled(BA52ConversionBtn)`
    position: fixed;
    bottom: 0;
    left: 0;

    ${mediaQuery({minWidthPx: SCREEN_1400_DESKTOP})`
        display: none;
    `}
`;

const OR33HeaderDsk = ({
    brandLogoId,
    className,
    color = white,
    isScrolled = false,

    // left side container
    children,
    homeLink = '/',
    homeLinkAssistiveLabel = 'Home page',
    onHomeLinkClick,

    // right side container
    isHeaderFunctionsHidden,
    isAppDockOpen,
    onAppDockClick = noop,
    hasHeaderButtons = true,
    // > signup cta
    hasSignupCtaSection,
    ctaMessage,
    signInCtaCopy = 'Sign In',
    signUpCtaCopy = 'Sign Up',
    signUpUrl = '/',
    onSigninClick = noop,
    // > chromecast
    hasChromecastIcon,
    isChromecastConnected,
    onChromecastClick = noop,
    // > search
    hasSearchIcon,
    isSearchFullPage,
    isSearchModalOpen,
    onSearchClick = noop,
    // > notifications
    hasNotificationsIcon,
    isNotificationModalOpen,
    notificationCount,
    onNotificationsClick = noop,

    ...htmlAttributes
}) => (
    <OuterContainer
        {...htmlAttributes}
        className={classnames('OR33HeaderDsk', className)}
        isAppDockOpen={isAppDockOpen}
        isScrolled={isScrolled}
    >
        <LeftSideContainer>
            <HomeLink
                href={homeLink}
                title={homeLinkAssistiveLabel}
                onClick={onHomeLinkClick}
                aria-label={homeLinkAssistiveLabel}
            >
                <LogoContainer>
                    <GA92BrandLogo width="133px" id={brandLogoId} />
                </LogoContainer>
            </HomeLink>

            <ShowHideTopNavMenu>
                {children}
            </ShowHideTopNavMenu>
        </LeftSideContainer>

        {!isHeaderFunctionsHidden && (
            <RightSideContainer>
                {!!ctaMessage && !!hasSignupCtaSection && (
                    <StyledBA52ConversionBtn href={signUpUrl}>
                        {ctaMessage}
                    </StyledBA52ConversionBtn>
                )}

                {!!hasSignupCtaSection && (
                    <SignupCtaContainer>
                        {!!ctaMessage && (
                            <SignupCtaMessage>
                                {ctaMessage}
                            </SignupCtaMessage>
                        )}

                        <CtaButtonsWrapper>
                            <StyledBA01BtnPr isBlock={false} href={signUpUrl}>{signUpCtaCopy}</StyledBA01BtnPr>
                            <StyledBA28BtnSec isBlock={false} onClick={onSigninClick}>{signInCtaCopy}</StyledBA28BtnSec>
                        </CtaButtonsWrapper>
                    </SignupCtaContainer>
                )}

                {!!hasHeaderButtons && (
                    <HeaderFunctionsContainer>
                        {!!hasChromecastIcon && (
                            <HeaderButton onClick={onChromecastClick} aria-pressed={isChromecastConnected} aria-label="Chromecast" color={color}>
                                <IC08Cc isConnected={isChromecastConnected} accentColor={color} />
                            </HeaderButton>
                        )}
                        {!!hasSearchIcon && (
                            <HeaderButton onClick={onSearchClick} aria-haspopup="true" aria-expanded={isSearchModalOpen} aria-current={!!isSearchFullPage} color={color}>
                                <IC02Sea ariaLabel="Search" />
                            </HeaderButton>
                        )}
                        {!!hasNotificationsIcon && (
                            <HeaderButton
                                onClick={onNotificationsClick}
                                aria-haspopup="true"
                                aria-expanded={isNotificationModalOpen}
                                color={color}
                            >
                                <IC03Not ariaLabel="Notifications" />
                                {notificationCount > 0 ? (
                                    <NotificationBadge notificationCount={notificationCount}>
                                        {notificationCount > 99 ? '99+' : notificationCount}
                                        <VisuallyHidden> new</VisuallyHidden>
                                    </NotificationBadge>
                                ) : (
                                    <VisuallyHidden>all caught up</VisuallyHidden>
                                )}
                            </HeaderButton>
                        )}
                        <HeaderButton onClick={onAppDockClick} aria-haspopup="true" aria-expanded={isAppDockOpen} color={color}>
                            <IC01Ham ariaLabel="Main menu" />
                        </HeaderButton>
                    </HeaderFunctionsContainer>
                )}
            </RightSideContainer>
        )}
    </OuterContainer>
);

OR33HeaderDsk.displayName = 'OR33HeaderDsk';

OR33HeaderDsk.propTypes = {
    /** Hydrated Id for the logo */
    brandLogoId: propTypes.string,
    /** Classnames for restyling purposes */
    className: classNameType,
    /** Accent color for header button icons and Chromecast connected icon (driven by VCC API) */
    color: colorType,
    /** Did user scroll down (moved from position Y:0)? This changes background styling */
    isScrolled: propTypes.bool,

    // LEFT SIDE CONTAINER
    /** NM07TopNav component */
    children: propTypes.node,
    /** For the logo link to go home */
    homeLink: propTypes.string,
    /** Assistive label text For the logo link */
    homeLinkAssistiveLabel: propTypes.string,
    /** Callback on home link click */
    onHomeLinkClick: propTypes.func,

    // RIGHT SIDE CONTAINER
    /** Hides the entire right side container: notification, search, and app dock buttons */
    isHeaderFunctionsHidden: propTypes.bool,
    /** For aria-expanded & also background styling */
    isAppDockOpen: propTypes.bool,
    /** Function for when Hamburger menu is clicked */
    onAppDockClick: propTypes.func,
    // > SIGNUP CTA
    /** Is the Signup CTA section rendered (visible only in >= 1920 viewports) */
    hasSignupCtaSection: propTypes.bool,
    /** Shows Signup CTA label for new users */
    ctaMessage: propTypes.string,
    /** Sign In CTA copy */
    signInCtaCopy: propTypes.string,
    /** Sign Up CTA copy */
    signUpCtaCopy: propTypes.string,
    /** URL for sign-up */
    signUpUrl: propTypes.string,
    /** Action on clicking sign-in */
    onSigninClick: propTypes.func,
    /** Should we show the header functions including the app dock toggle (Hamburger button)? */
    hasHeaderButtons: propTypes.bool,
    // > CHROMECAST
    /** Is the chromecast control rendered */
    hasChromecastIcon: propTypes.bool,
    /** Is the Chromecast connected */
    isChromecastConnected: propTypes.bool,
    /** Function for when Chromecast icon is clicked */
    onChromecastClick: propTypes.func,
    // > SEARCH
    /** Is the search control rendered */
    hasSearchIcon: propTypes.bool,
    /** For route is /search or equivalent */
    isSearchFullPage: propTypes.bool,
    /** For aria-expanded */
    isSearchModalOpen: propTypes.bool,
    /** Function for when Search magnifying-glass is clicked */
    onSearchClick: propTypes.func,
    // > NOTIFICATION
    /** Is the notification control rendered */
    hasNotificationsIcon: propTypes.bool,
    /** For aria-expanded */
    isNotificationModalOpen: propTypes.bool,
    /** What number to display in the notification badge */
    notificationCount: propTypes.number,
    /** Function for when Notification bell is clicked */
    onNotificationsClick: propTypes.func,
};

export default OR33HeaderDsk;
