/**
 * Get module key
 * - used as value for data-module html attr
 * - used as component key
 *
 * @param {object} options options
 * @param {number} options.index current index in array
 * @param {string} options.type module type
 * @param {string} options.id module id
 * @param {number} options.length length of array
 *
 * @example
 *  getModuleKey({index: 0, type: 'some-type', id: 'some-id', length: 10})
 *  moduleKey: `1/10 some-type | some-id`
 *
 * @returns {String}  moduleKey
 */
const getModuleKey = ({index, type, id, length}) => `${index + 1}/${length} ${type} | ${id}`;

export default getModuleKey;
