import {isBrowser} from '@fsa-streamotion/browser-utils';
import {Observable, of, throwError, timer} from 'rxjs';
import {catchError, mergeMap, retryWhen} from 'rxjs/operators';

import {getFeed, stopFeed} from './request-manager';
import {DEFAULT_CACHE_TTL_MS, DEFAULT_REQUEST_TIMEOUT_MS, DEFAULT_RETRY_COUNT, DEFAULT_RETRY_DELAY_MS} from './constants';

const defaultResponseValidator = (superAgentObject) => superAgentObject.responseOk;

/**
 * Api call retry strategy with delay and max number of retries
 *
 * @param {Object} options - (see below)
 * @param {number} [options.frequencyMs=0] - How frequency[ms] to poll
 * @param {number} [options.maxRetryAttempts] - How many times to retry the api call
 * @param {number} [options.retryDelayMs] - How often[ms] to retry the api call
 *
 * @returns {function} - A function that returns an Rx Observable
 */
const delayRetryStrategy = ({
    frequencyMs = 0,
    maxRetryAttempts = DEFAULT_RETRY_COUNT,
    retryDelayMs = DEFAULT_RETRY_DELAY_MS,
} = {}) => (errors) => errors.pipe(
    mergeMap((error, i) => {
        const retryAttempt = i + 1;

        if (!isBrowser() || retryAttempt > maxRetryAttempts || frequencyMs) {
            return throwError(error);
        }

        return timer(retryDelayMs);
    })
);

function getFrequency(frequencyMs, isRetried) {
    if (isRetried) {
        return frequencyMs ? frequencyMs : null;
    } else {
        return frequencyMs;
    }
}

/**
 * Get an observable for an API endpoint
 *
 * @param {Object} options - (see below)
 * @param {string} options.url - URL to fetch
 * @param {number} options.frequencyMs - How frequency[ms] to poll
 * @param {Number} options.requestTimeoutMs - How long (in milliseconds) to wait before abandoning request
 * @param {number} options.cacheTtlMs - How long[ms] this result should stay valid in client cache default 3,000ms server / 30,000ms client.
 * @param {function} options.isResponseValid - Superagent response object validator that returns <code>true</code> or <code>false</code> if the response is valid or not valid respectively
 *
 * @returns {Observable} - Rx Observable
 */
export function getApiFromBinderRx({
    url,
    frequencyMs,
    requestTimeoutMs = DEFAULT_REQUEST_TIMEOUT_MS,
    cacheTtlMs = DEFAULT_CACHE_TTL_MS,
    isResponseValid = defaultResponseValidator,
}) {
    return Observable.create((observer) => {
        const onResponse = (superAgentObject) => {
            if (isResponseValid(superAgentObject)) {
                observer.next(superAgentObject);
            } else {
                observer.error(superAgentObject);
            }
        };

        getFeed({
            cacheTtlMs,
            freqMs: frequencyMs,
            onResponse,
            requestTimeoutMs,
            url,
        });

        return () => void stopFeed(url, onResponse);
    });
}

/**
 * Get a stream for an API endpoint, and on connection issues retry the request x times.
 *
 * After failing all retries, the final error will be released to the stream as a value.
 *
 * @param {Object} options (see below)
 * @param {string} options.url  URL to fetch
 * @param {number} options.frequencyMs How frequency[ms] to poll
 * @param {number} options.retryDelayMs How long[ms] to wait to wait between retries
 * @param {number} options.maxRetryAttempts How many retries (not including initial) attempts
 * @param {Number} options.requestTimeoutMs How long (in milliseconds) to wait before abandoning request
 * @param {number} options.cacheTtlMs How long[ms] this result should stay valid in client cache default 3,000ms server / 30,000ms client.
 * @param {function} options.isResponseValid Superagent response object validator that returns <code>true</code> or <code>false</code> if the response is valid or not valid respectively
 *
 * @returns {Observable} - Rx Observable
 */
export function getApiFromRetryWithErrorRx({
    url,
    frequencyMs = 0,
    retryDelayMs = DEFAULT_RETRY_DELAY_MS,
    maxRetryAttempts = DEFAULT_RETRY_COUNT,
    requestTimeoutMs = DEFAULT_REQUEST_TIMEOUT_MS,
    cacheTtlMs = DEFAULT_CACHE_TTL_MS,
    isResponseValid = defaultResponseValidator,
}) {
    let isRetried = false;

    return of(1)
        .pipe(
            mergeMap((() => {
                const callFrequency = getFrequency(frequencyMs, isRetried);

                if (!isRetried) {
                    isRetried = true;
                }

                return getApiFromBinderRx({url, frequencyMs: callFrequency, cacheTtlMs, isResponseValid, requestTimeoutMs});
            })),
            retryWhen(delayRetryStrategy({frequencyMs, maxRetryAttempts, retryDelayMs})),
            catchError((e) => of(e))
        );
}
