import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';

import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {Section} from '../../../../common/normalized-styled-components';
import {white, smoke, black} from '../../../../common/palette';

const StyledSection = styled(Section)`
    margin: 0 auto;
    width: 100%;
    text-shadow: 0 0 3px ${rgba(black, 0.3)};
    color: ${white};

    ${stylesWhen('isHorizontallyCentered')`
        text-align: center;
    `}
`;

const StyledH2 = styled.h2`
    margin: 0 0 7px;
    font: var(--infinity-body-copy-2);
`;

const Copy = styled.p`
    margin: 0;
    color: ${smoke};
    font: var(--infinity-header-8-light);
`;

const OR130AccountBlk = ({
    className,
    displayHeader,
    displaySubtext,
    isHorizontallyCentered,
    ...htmlAttributes
}) => (
    <StyledSection
        {...htmlAttributes}
        className={classnames('OR130AccountBlk', className)}
        isHorizontallyCentered={isHorizontallyCentered}
    >
        {!!displayHeader && <StyledH2>{displayHeader}</StyledH2>}
        {!!displaySubtext && <Copy>{displaySubtext}</Copy>}
    </StyledSection>
);

OR130AccountBlk.propTypes = {
    /** Additional class name(s) */
    className: classNameType,
    /** CMS block heading copy */
    displayHeader: propTypes.node,
    /** CMS copy */
    displaySubtext: propTypes.node,
    /** Whether to horizontally centre the text */
    isHorizontallyCentered: propTypes.bool,
};

OR130AccountBlk.displayName = 'OR130AccountBlk';

export default OR130AccountBlk;
