import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';

import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';

import GA106Imdb from '../components/atoms/ga/106-imdb';
import GA108Sd from '../components/atoms/ga/108-sd';
import GA107Hd from '../components/atoms/ga/107-hd';
import GA1094k from '../components/atoms/ga/109-4k';

const INFOLINE_ICONS = {
    'SD': <GA108Sd ariaLabel="SD" />,
    'HD': <GA107Hd ariaLabel="HD" />,
    '4K': <GA1094k ariaLabel="4K" />,
    'IMDB': <GA106Imdb ariaLabel="IMDB" />,
};

const IconContainer = styled.div`
    display: inline-block;
    height: 0.9em;
`;

const Description = styled.span`
    ${stylesWhen('isNotFirstChild')`
        padding-left: 0.25em;
    `}
`;

const InfoLineItem = ({
    icon,
    description,
}) => (
    <React.Fragment>
        {!!icon && !!INFOLINE_ICONS[icon] && (
            <IconContainer>
                {INFOLINE_ICONS[icon]}
            </IconContainer>
        )}
        {!!description && (
            <Description isNotFirstChild={!!icon && !!description} >
                {description}
            </Description>
        )}
    </React.Fragment>
);

InfoLineItem.propTypes = {
    icon: propTypes.node,
    description: propTypes.string,
};

InfoLineItem.displayName = 'InfoLineItem';

export default InfoLineItem;
