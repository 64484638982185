import React from 'react';
import propTypes from 'prop-types';
// import styled from 'styled-components';
// import {rgba} from 'polished';
import {Button} from 'normalized-styled-components';
import noop from 'lodash/noop';
import styled from 'styled-components';

import {white} from '../../../../common/palette';
import {kayoGreen} from '../../../../common/deprecated-mui-palette';
import {transition} from '../../../../common/animations';

const StyledButton = styled(Button)`
    appearance: none;
    border: 0;
    background-color: transparent;
    padding: 12px;
    width: 58px;
    height: 58px;
    color: ${white};

    &::after {
        position: absolute;
        right: 0;
        bottom: -3px;
        left: 0;
        transition: ${transition('background-color')};
        border-radius: 2px;
        background-color: transparent;
        height: 3px;
        content: '';
    }

    /* stylelint-disable-next-line order/order */
    &:focus,
    &:hover {
        position: relative;
        outline: 0;

        &::after {
            background-color: ${kayoGreen};
        }
    }
`;

// eslint-disable-next-line jsdoc/require-param
/**
 * @deprecated since version 10.3.2
 *
 * @param {Object} options gets some deprecated stuff
 * @returns {React.Component} BA09BtnSettings
 */
const BA09BtnSettings = ({
    'aria-label': ariaLabel,
    children,
    icon,
    onClick = noop,
    ...htmlAttributes
}) => (
    <StyledButton
        {...htmlAttributes}
        aria-label={ariaLabel}
        onClick={onClick}
        type="button"
    >
        {icon}
        {children}
    </StyledButton>
);

BA09BtnSettings.displayName = 'BA09BtnSettings';

/* eslint-disable quote-props */
BA09BtnSettings.propTypes = {
    /** Assistive label required for text-only button */
    'aria-label': propTypes.string.isRequired,
    /** IC icon to use */
    icon: propTypes.node,
    /** Content of button, e.g. text */
    children: propTypes.node.isRequired,
    /** Callback on element click */
    onClick: propTypes.func,
};
/* eslint-enable quote-props */

export default BA09BtnSettings;
