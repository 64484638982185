import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import constants from '../../../../common/constants';
import {ink, gradients} from '../../../../common/palette';
import {SCREEN_768_TABLET} from '../../../../common/screen-sizes';

const {dusk} = gradients;

export const TERM_TYPE_SHORT = {
    day: 'day',
    week: 'wk',
    month: 'mth',
    annual: 'yr',
};

const StyledContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 5px 12px;
    height: 28px;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
    object-fit: contain;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        padding: 11px 18px;
        height: 41px;
    `}

    &::before {
        position: absolute;
        border: 1px solid transparent;
        border-radius: 4px;
        background: linear-gradient(80deg, ${dusk.bottom}, ${dusk.top}) border-box;
        content: '';
        inset: 0;
        mask: linear-gradient(${ink} 0 0) padding-box, linear-gradient(${ink} 0 0);
        /* stylelint-disable-next-line property-no-vendor-prefix */
        -webkit-mask-composite: xor;
        mask-composite: exclude;
    }
`;

const StyledSavingTag = styled.div`
    opacity: 0.8;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-background-clip: text;
    background-clip: text;
    background-image: linear-gradient(265deg, ${dusk.top} 103%, ${dusk.bottom});
    max-width: 201px;
    color: transparent;
    font: var(--quicksilver-body-copy-4);
    /* stylelint-disable-next-line color-no-hex */
    mask-image: linear-gradient(to right, #000 189px, transparent);

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        font-size: 20px;
        max-width: 278px;
        /* stylelint-disable-next-line color-no-hex */
        mask-image: linear-gradient(to right, #000 260px, transparent);
    `}
`;

// eslint-disable-next-line
/**
 * GM13SavingsTag
 *  - This component is used to display saved amount
 *
 * @see {@link https://zpl.io/DlrpP1v}
 */

const GM13SavingsTag = ({
    className,
    currencyPrefix = '$',
    savingAmount,
    termType,
    ...htmlAttributes
}) => {
    const termLabel = TERM_TYPE_SHORT[termType];

    return (
        <StyledContainer
            {...htmlAttributes}
            className={classnames('GM13SavingsTag', className)}
        >
            <StyledSavingTag>
                You Save {currencyPrefix}{savingAmount}/{termLabel}
            </StyledSavingTag>
        </StyledContainer>
    );
};

GM13SavingsTag.propTypes = {
    /** Additional class name(s) */
    className: classNameType,
    /** Currency prefix */
    currencyPrefix: propTypes.oneOf(['$']),
    /** saving price */
    savingAmount: propTypes.number,
    /** period eg. month */
    termType: propTypes.oneOf(constants.TERM_OPTIONS),
};

GM13SavingsTag.displayName = 'GM13SavingsTag';

export default GM13SavingsTag;
