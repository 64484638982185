import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {A, Button} from 'normalized-styled-components';

import {stylesWhen, stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';

import {transition} from '../../../../common/animations';
import {white, black, satellite, kayoGreen} from '../../../../common/palette';

const TabNav = styled.ul`
    box-sizing: border-box;
    display: flex;
    justify-content: space-evenly;
    margin: 0;
    padding: 0;
    width: 100%;
    list-style-type: none;
    user-select: none;
`;

const TabNavItem = styled.li`
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    text-align: center;
`;

const tabNavStyles = css`
    display: flex;
    align-items: center;
    justify-content: center;
    transition: ${transition('border-bottom', 'color')};
    outline: none;
    border: 0;
    border-bottom: solid 1px ${black};
    background: transparent;
    padding: 10px;
    height: 100%;
    text-decoration: none;
    color: ${satellite};
    font: var(--mui-body-copy-3);

    &:focus,
    &:hover,
    &:active {
        border-bottom-color: ${white};
    }

    /* stylelint-disable-next-line order/order */
    ${stylesWhen('isSelected')`
        border-bottom-color: ${white};
        color: ${white};

        ${stylesWhenNot('isDisabled')`
            &:focus,
            &:hover,
            &:active {
                border-bottom-color: ${kayoGreen};
            }
        `}
    `}
`;

const TabNavA = styled(A)`
    ${tabNavStyles}
`;

const TabNavButton = styled(Button)`
    ${tabNavStyles}
`;

const TabNavNonInteractive = styled.div`
    ${tabNavStyles}
`;

const NM08TabNav = ({
    navItems = [],
    selected,
}) => (
    <nav>
        <TabNav className="NM08TabNav">
            {
                navItems.map(({label, href, onClick, isDisabledOnSelected}) => {
                    const isSelected = label === selected;
                    const isDisabled = isSelected && isDisabledOnSelected;
                    const Cta = getCtaType({href, onClick, isDisabled});

                    return (
                        <TabNavItem key={label}>
                            <Cta
                                href={href}
                                onClick={isDisabled ? undefined : onClick}
                                isSelected={isSelected}
                                isDisabled={isDisabled}
                            >
                                {label}
                            </Cta>
                        </TabNavItem>
                    );
                })
            }
        </TabNav>
    </nav>
);

NM08TabNav.displayName = 'NM08TabNav';

NM08TabNav.propTypes = {
    navItems: propTypes.arrayOf(propTypes.shape({
        label: propTypes.string,
        href: propTypes.string,
        isDisabledOnSelected: propTypes.bool,
        onClick: propTypes.func,
    })),
    selected: propTypes.string,
};

export default NM08TabNav;

function getCtaType({href, onClick, isDisabled}) {
    if (href && !isDisabled) {
        return TabNavA;
    } else if (onClick && !isDisabled) {
        return TabNavButton;
    } else {
        return TabNavNonInteractive;
    }
}
