import bacon from 'baconjs';
import superagent from 'superagent';

import {BILLING_API_URL} from '@fsa-streamotion/streamotion-web-widgets-common';

import stringableBaconError from '../../../utils/stringable-bacon-error';
import {BILLING_PRODUCT_NAME_MAP} from '../../../utils/constants';

/**
 * Update the default payment method for the current user
 *
 * @see [Swagger Docs](https://billingapi-staging.streamotion.com.au/api-doc/index.html#/My-Account/updateDefaultPaymentMethodUsingPUT)
 * @see [Update payment method endpoint (note: requires bearer token)](https://billingapi-staging.streamotion.com.au/my-account/ares/payment-method)
 *
 * @param {Object} options                 (see below)
 * @param {string} options.accessToken     the user's access token
 * @param {string} options.brand           Product name
 * @param {string} options.paymentGateway  Payment gateway used, e.g. creditCard or paypal to be updated against the user.
 * @param {string} options.paymentRefId    Reference ID of the payment method - This is the refId returned
 * @param {string} options.platformEnv     billing API environment, e.g. production
 * @param {string} options.brand           brand, e.g. binge
 * @returns {bacon.Observable} Response from API
 */
export default function paymentMethod({
    accessToken,
    paymentGateway,
    paymentRefId,
    platformEnv,
    brand = 'flash',
}) {
    const product = BILLING_PRODUCT_NAME_MAP[brand];
    const url = `${BILLING_API_URL[platformEnv]}/my-account/${product}/payment-method`;

    return bacon.fromBinder((sink) => {
        superagent
            .put(url)
            .set('Authorization', `Bearer ${accessToken}`)
            .accept('json')
            .send({
                paymentGateway,
                paymentRefId,
            })
            .then((response) => void sink(response.body))
            .catch((error) => {
                const errorBody = JSON.parse(error?.response?.text || '""');

                sink(stringableBaconError({
                    code: errorBody?.code || error?.status,
                    message: errorBody?.longMessage || 'An error occurred while saving your payment method change. Please try again later.',
                    debugMessage: `Couldn't PUT my-account payment method (${url}): ${error?.status} ${error?.shortMessage} ${error?.message}`,
                }));
            })
            .finally(() => void sink(new bacon.End()));

        return;
    });
}

/* Will return array [{
    accountType: "kayo"
    cardType: "Visa"
    expiration: "2030-05"
    id: "2c92c0f971b049670171b9128250743a"
    invoiceOwnerAccountId: "2c92c0f971b049680171b9128eda288c"
    number: "************1111"
    paypalEmail: null
    status: "Active"
    type: "CreditCard"
}] */
export function getPaymentMethod({
    accessToken,
    platformEnv,
}) {
    const url = `${BILLING_API_URL[platformEnv]}/my-account/payment-method`;

    return bacon.fromBinder((sink) => {
        superagent
            .get(url)
            .set('Authorization', `Bearer ${accessToken}`)
            .accept('json')
            .then((response) => void sink(response.body))
            .catch((error) => {
                const errorBody = JSON.parse(error?.response?.text || '""');

                sink(stringableBaconError({
                    code: errorBody?.code || error?.status,
                    message: errorBody?.longMessage || 'An error occurred while getting your existing payment method. Please try again later.',
                    debugMessage: `Couldn't GET my-account payment method (${url}): ${error?.status} ${error?.shortMessage} ${error?.message}`,
                }));
            })
            .finally(() => void sink(new bacon.End()));

        return;
    });
}
