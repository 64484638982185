import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';
import {stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';
import {ariaPressedType, buttonType, classNameType} from '@fsa-streamotion/custom-prop-types';

import {A, Button} from '../../../../common/normalized-styled-components';
import {transition} from '../../../../common/animations';
import {hubblGreen, ink, cobalt, indigo, black} from '../../../../common/palette';

const ShadowDiv = styled.div`
    opacity: 0.5;
    width: 100%;
    height: 14px;
`;

const interactiveStyles = css`
    /** FOCUS / HOVER STATE */
    &:hover,
    &:focus {
        outline: 0;
        /** Disable the stylelint, this structure is more readable */
        /* stylelint-disable */
        background-image: linear-gradient(
            to bottom,
            ${rgba(indigo, 0)} 30%,
            ${rgba(indigo, 0.4)}
        ), linear-gradient(
            to bottom,
            ${hubblGreen},
            ${hubblGreen}
        );
        text-decoration: none;
        color: ${ink};
    }

    /** ON PRESS / SELECTED STATE */
    &:active,
    &[aria-pressed='true'],
    &[aria-current='true'] {
        background-color: ${cobalt};
        text-decoration: none;
        color: ${ink};
    }
`;

const baseStyles = css`
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    transform-origin: left center;
    transition: ${transition('background-color', 'color')};
    background-color: ${hubblGreen};
    padding: 0 21px;
    width: 100%;
    min-height: 40px;
    overflow: hidden;
    text-align: center;
    text-decoration: underline;
    white-space: nowrap;
    color: ${ink};
    font: var(--quicksilver-nav-copy-default);
    font-weight: normal;
    object-fit: contain;

    ${stylesWhenNot('disabled')`
        ${interactiveStyles};
    `}
`;

const StyledButton = styled(Button).attrs(({isPressed}) => ({
    'aria-pressed': isPressed,
}))`
    ${baseStyles}
    appearance: none;
    border: 0;
`;

const StyledLink = styled(A).attrs(({isPressed}) => ({
    'aria-current': isPressed,
}))`
    ${baseStyles};
`;

const StyledSpan = styled.span`
    width: 100%;
    max-width: 278px;
    mask-image: linear-gradient(to left, transparent, ${black} 21px);
`;

/**
 * Footer CTA
 * - This is used to display sticky footer in mobile view
 *
 * @see {@link https://zpl.io/Dlx38Qv}
 */
const BA52ConversionBtn = React.forwardRef(({
    children,
    className,
    disabled,
    href,
    isPressed,
    onClick,
    type = 'button',
    ...htmlAttributes
}, ref) => {
    const isCtaLink = href && !disabled; // coerce disabled CTAs into being buttons
    const StyledElement = isCtaLink ? StyledLink : StyledButton;

    return (
        <div className={classnames('BA52ConversionBtn', className)}>
            <ShadowDiv />
            <StyledElement
                {...htmlAttributes}
                {...{
                    disabled,
                    href,
                    isPressed,
                    onClick,
                    ref,
                    type: isCtaLink ? undefined : type,
                }}
            >
                <StyledSpan>{children}</StyledSpan>
            </StyledElement>
        </div>
    );
});

BA52ConversionBtn.propTypes = {
    /** Content of button, e.g. text */
    children: propTypes.node.isRequired,
    /** additional CSS classnames to be applied */
    className: classNameType,
    /** Is this button disabled? */
    disabled: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    /** If it’s actually a link, provide a href */
    href: propTypes.string,
    /** passed to `aria-pressed` for buttons or `aria-current` for anchors to indicate selection status */
    isPressed: ariaPressedType,
    /** Callback on element click */
    onClick: propTypes.func,
    /** The HTML type of the button */
    type: buttonType,
};

BA52ConversionBtn.displayName = 'BA52ConversionBtn';

export default BA52ConversionBtn;
