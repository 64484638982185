import styled from 'styled-components';
import {rgba} from 'polished';
import {ironStone} from '../../../../common/deprecated-mui-palette';

const Z_INDEX_BELOW_OTHER_STUFF = -1;

const GA69HudCardBg = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: ${Z_INDEX_BELOW_OTHER_STUFF};
    background-color: ${rgba(ironStone, 0.3)};
    width: 100%;
    height: 100%;
`;

GA69HudCardBg.displayName = 'GA69HudCardBg';

export default GA69HudCardBg;
