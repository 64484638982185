import React from 'react';
import Ic from '..';

const IC297Preferences = (args) => (
    <Ic {...args}>
        <defs>
            <linearGradient x1="72.279%" y1="21.271%" x2="70.55%" y2="81.152%" id="ic297-preferences__linear-gradient">
                <stop stopColor="currentColor" stopOpacity="0" offset="0%" />
                <stop stopColor="currentColor" offset="100%" />
            </linearGradient>
        </defs>
        <g fill="none" fillRule="nonzero">
            <path
                d="M77.065 25.482c10.95 11.384 13.14 28.73 5.046 42.594-9.897 16.952-31.628 22.902-48.79 13.47l-.518-.291 2.479-4.342c14.86 8.483 33.897 3.396 42.51-11.358 6.884-11.789 5.12-26.497-4.023-36.283l-.308-.324 3.604-3.466Z"
                fill="url(#ic297-preferences__linear-gradient)"
            />
            <path
                d="M32.946 19.19c17.085-9.762 38.888-4.109 49.006 12.605l.303.51-4.32 2.52C69.342 20.09 50.306 15.03 35.428 23.53 20.564 32.024 15.484 50.834 24.07 65.553c8.51 14.586 27.251 19.692 42.061 11.543l.448-.25 2.48 4.34c-17.256 9.861-39.328 3.994-49.308-13.114C9.763 50.95 15.675 29.06 32.946 19.19Z"
                fill="currentColor"
            />
            <path
                d="m64.233 35.298 3.477 3.12L43.564 64.74 32.29 52.451l3.477-3.12 7.797 8.495z"
                fill="currentColor"
            />
        </g>
    </Ic>
);

IC297Preferences.displayName = 'IC297Preferences';

export default IC297Preferences;
