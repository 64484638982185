import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import GA01ProBar from '../components/atoms/ga/01-pro-bar';

import {SCREEN_PRETTY_LG_DESKTOP, SCREEN_REALLY_LG_DESKTOP} from './screen-sizes';
import {black, white} from './palette';

const ProgressBarContainer = styled.span`
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 0 0 4px 4px;
    background-image: linear-gradient(to bottom, transparent, ${black});
    width: 100%;
    height: 30%;
`;

const ProgressLabel = styled.span`
    padding-bottom: 5px;
    padding-left: 5px;
    text-align: left;
    letter-spacing: 0.42px;
    color: ${white};
    font: var(--mui-continue-watching);

    ${mediaQuery({minWidthPx: SCREEN_PRETTY_LG_DESKTOP})`
        padding-left: 9px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_REALLY_LG_DESKTOP})`
        padding-left: 12px;
    `}
`;

const StyledGA01ProBar = styled(GA01ProBar)`
    border-radius: 0 0 4px 4px;
    background-color: ${rgba(white, 0.2)};

    ::-webkit-progress-bar {
        border-radius: 0 0 4px 4px;
    }

    ::-webkit-progress-value {
        border-bottom-left-radius: 4px;
    }
`;

const TileProgressBar = ({
    currentPercentage = 0,
    progressLabel,
    barColor = white,
}) => (
    <ProgressBarContainer>
        <ProgressLabel>{progressLabel}</ProgressLabel>
        <StyledGA01ProBar current={currentPercentage} progressColor={barColor} />
    </ProgressBarContainer>
);

TileProgressBar.displayName = 'TileProgressBar';

TileProgressBar.propTypes = {
    barColor: propTypes.string,
    currentPercentage: propTypes.number,
    progressLabel: propTypes.string,
};

export default TileProgressBar;
