/**
 * Format an amount of currency.
 *
 * Note: Don't specify a value for <code>currencyDecimalPlaces</code> less than the number of decimal places
 * of the currency, otherwise rounding may occur.
 *
 * @example
 * // returns "$25"
 * format({amount: 25, currencyPrefix: '$', currencyDecimalPlaces: 2})
 *
 * @example
 * // returns "$9.50"
 * format({amount: 9.5, currencyPrefix: '$', currencyDecimalPlaces: 2})
 *
 * @example
 * // returns "$10"
 * format({amount: 10.001, currencyPrefix: '$', currencyDecimalPlaces: 2})
 *
 * @param {Object} [options={}]                          - (see below)
 * @param {number} [options.amount=0]                    - amount
 * @param {string} [options.currencyPrefix='$']          - symbol
 * @param {number} [options.currencyDecimalPlaces=0]     - decimal places
 * @returns {string} the formatted currency
 */
export default function formatCurrency({
    amount = 0,
    currencyPrefix = '$',
    currencyDecimalPlaces = 2,
} = {}) {
    const normalizedAmount = parseFloat(amount.toFixed(currencyDecimalPlaces));

    return `${currencyPrefix}${currencyDecimalPlaces && !Number.isInteger(normalizedAmount) ? normalizedAmount.toFixed(currencyDecimalPlaces) : normalizedAmount}`;
}
