import React from 'react';
import propTypes from 'prop-types';
import noop from 'lodash/noop';
import styled from 'styled-components';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import handleSubmitForm from '../../../../common/get-handle-form-submit';
import {white} from '../../../../common/palette';
import BA01BtnPr from '../../../atoms/ba/01-btn-pr';
import IC103Loading from '../../../atoms/ic/103-loading';
import IC42Error from '../../../atoms/ic/42-error';
import IM01Input from '../../../molecules/im/01-input';

const LoginFormOverlay = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

const Form = styled.form`
    padding: 14px;
    width: 100%;
    max-width: 560px;
    text-align: center;
`;

const FormFieldContainer = styled.div`
    display: grid;
    grid-gap: 7px;
    justify-items: center;
    margin: 0 auto;
    max-width: 320px;
`;

const ErrorText = styled.p`
    margin: 0;
    color: ${white};
    font: var(--mui-body-copy-4);
`;

const ErrorIcon = styled(IC42Error)`
    display: inline-block;
    margin-right: 7px;
    vertical-align: top;
`;

const OR107CondensedSignIn = ({
    className,
    errorMsg,
    isLoading,
    name,
    onSubmit = noop,
}) => (
    <LoginFormOverlay className={classnames('OR107CondensedSignIn', className)}>
        <Form
            onSubmit={handleSubmitForm(onSubmit, ['email', 'password'])}
            name={name}
            method="POST"
            // prevents form submission before js executes (see martian-web-server for implementation)
            data-disabled-form-submit="true"
        >
            <FormFieldContainer>
                <IM01Input
                    type="email"
                    label="Email Address"
                    name="email"
                    required={true}
                    disabled={isLoading}
                    isCondensed={true}
                />
                <IM01Input
                    type="password"
                    label="Password"
                    name="password"
                    required={true}
                    disabled={isLoading}
                    isCondensed={true}
                />
                {isLoading ? (
                    <IC103Loading size="47px" />
                ) : (
                    <BA01BtnPr type="submit">Login</BA01BtnPr>
                )}
                {!!errorMsg && (
                    <ErrorText role="alert">
                        <ErrorIcon size="16px" />
                        {errorMsg}
                    </ErrorText>
                )}
            </FormFieldContainer>
        </Form>
    </LoginFormOverlay>
);

OR107CondensedSignIn.displayName = 'OR107CondensedSignIn';

OR107CondensedSignIn.propTypes = {
    /** Class name property */
    className: classNameType,
    /** If set, an error message to be displayed to the user */
    errorMsg: propTypes.string,
    /** Whether or not the form is in a loading state */
    isLoading: propTypes.bool,
    /** A custom HTML name for the form */
    name: propTypes.string,
    /** A callback to handle submission of the form data */
    onSubmit: propTypes.func,
};

export default OR107CondensedSignIn;
