import React from 'react';
import propTypes from 'prop-types';
import {black} from '../../../../common/palette';
import Ic from '..';

/* eslint-disable quote-props */
const RATE_PATH_MAP = {
    '1': <path fill={black} d="M56.2 77.7h-4.3v-16h-3.5v-3.1h7.8v19.1zM77.2 77.7h-5.1L69 73.1l-3 4.6h-5.1l5.5-7.1-4.9-6.6h5l2.6 4.1 2.5-4.1h5l-4.9 6.6 5.5 7.1z" />,
    '1.5': <path fill={black} d="M41.3 77.7H37v-16h-3.5v-3.1h7.8v19.1zM50.9 78c-1.6 0-2.6-.9-2.6-2.4s1.1-2.4 2.6-2.4 2.6.9 2.6 2.4c0 1.6-1.1 2.4-2.6 2.4zM65.4 78c-3.8 0-6.2-1.8-7.1-3.1l2.1-2.6c1.1 1.5 3.1 2.3 4.7 2.3s3.6-.9 3.6-3.2c0-2.3-1.9-3.2-3.6-3.2-1.2 0-2.6.4-3.5 1L59.7 68l1.5-9.4h11.2V62h-8l-.6 3.4c.6-.2 1.3-.4 2.6-.4 2.9 0 6.7 1.8 6.7 6.3-.1 4.5-3.9 6.7-7.7 6.7zM92.1 77.7H87l-3-4.5-3.1 4.6h-5.1l5.5-7.1-4.9-6.6h5l2.6 4.1 2.5-4.1h5l-4.9 6.6 5.5 7z" />,
    '2': <path fill={black} d="M60.6 77.7h-14l-.6-2.8 6.5-5.9c1.8-1.6 2.9-2.9 2.9-4.5s-1.2-2.7-3.1-2.7c-1.9 0-3.6 1.2-4.6 2.6l-1.9-2.9c.2-.6 2.6-3.2 6.9-3.2 4.3 0 7 2.3 7 5.7s-2.5 5.4-4 6.7l-4 3.6h8.9v3.4zM79.4 77.7h-5.1l-3.1-4.6-3.1 4.6H63l5.5-7.1-4.9-6.6h5l2.6 4.1 2.5-4.1h5l-4.9 6.6 5.6 7.1z" />,
    'slo': <path fill={black} d="M48.9 76.5c-1.4 1.1-3.1 1.6-5.2 1.6-2.8 0-5.2-1.4-6.8-2.9l2.2-3.3c1.4 1.4 3.5 2.4 5 2.4 1.4 0 2.6-.7 2.6-2s-1.4-1.8-2.2-2.1c-.8-.3-2.3-.8-2.9-1.1-.5-.2-1.4-.6-2.3-1.4-.8-.8-1.5-1.9-1.5-3.7 0-1.8.9-3.3 2-4.2 1.1-.9 2.8-1.5 5.1-1.5s4.8 1.1 6.1 2.4l-2 3.2c-1.1-1.1-2.8-1.9-4.3-1.9-1.4 0-2.5.6-2.5 1.7 0 1.2 1.1 1.6 1.9 1.9.8.3 2.2.8 2.9 1.1.5.2 1.8.7 2.7 1.6.8.8 1.5 2 1.5 3.7 0 1.7-.9 3.4-2.3 4.5zM66.3 77.7H55.2V58.6h4.5V74h6.6v3.7zM78.1 78.1c-5.7 0-10.2-3.7-10.2-9.9s4.5-9.9 10.2-9.9c5.7 0 10.2 3.7 10.2 9.9s-4.5 9.9-10.2 9.9zm0-16c-3.2 0-5.6 2.3-5.6 6s2.4 6 5.6 6c3.2 0 5.6-2.3 5.6-6s-2.4-6-5.6-6z" />,
};
/* eslint-enable quote-props */

const IC32PbRate = ({rate = 'slo', ...args}) => (
    <Ic {...args}>
        <path d="M6.8 54.8c-.3-2.2-.3-4.4-.2-6.6l-5.5-.4c-.2 2.5-.1 5 .2 7.5 0 .4.1.8.2 1.2.3 2.3.9 4.6 1.6 6.8l5.3-1.8c-.8-2.2-1.3-4.4-1.6-6.7zM12.8 70.2l-4.5 3.2c1.3 1.9 2.8 3.6 4.4 5.3.5.4.9.9 1.4 1.3.4.4.8.7 1.3 1.1.4.4.9.7 1.3 1 .4.3.9.7 1.4 1 .5.3.9.6 1.4.9.1 0 .1.1.2.1l2.9-4.7c-3.9-2.4-7.2-5.5-9.8-9.2zM16 27.2l-4-3.8c-3 3-5.3 6.5-7.2 10.3-.4.9-.9 1.9-1.2 2.9l5.2 2c1.6-4.3 4-8.1 7.2-11.4zM74.3 30l-.3-.5c-.3-.4-.6-.9-.8-1.3-.1-.2-.3-.4-.4-.6l-4.5 3.2c2.6 3.7 4.5 7.8 5.5 12.3l5.4-1.2c-1-4.3-2.7-8.3-4.9-11.9zM37.9 11.2c-.5 0-1 .1-1.5.1-.4 0-.8.1-1.2.1-.3 0-.5.1-.8.1-.3 0-.5.1-.8.1-.3 0-.5.1-.8.1h-.1c-.6.1-1.2.2-1.8.4-.2.1-.5.1-.7.2l-3 .9c-2.2.8-4.4 1.8-6.4 3l2.8 4.8c4.9-2.8 10.4-4.3 16-4.5l-.2-5.6c-.4.2-.9.2-1.5.3zM49.4 17.8c4.4 1.2 8.4 3.2 12 6l3.4-4.4c-4.1-3.2-8.9-5.6-14-7l-1.4 5.4z" />
        <path d="M37.9 11.2c-.5 0-1 .1-1.5.1-.4 0-.8.1-1.2.1-.3 0-.5.1-.8.1s-.5.1-.8.1-.5.1-.8.1h-.1c-.6.1-1.2.2-1.8.4-.2.1-.5.1-.7.2l-3 .9c-2.2.8-4.4 1.8-6.4 3l2.8 4.8c4.9-2.8 10.4-4.3 16-4.5l-.2-5.6c-.4.2-.9.2-1.5.3zm11.5 6.6c4.4 1.2 8.4 3.2 12 6l3.4-4.4c-4.1-3.2-8.9-5.6-14-7l-1.4 5.4zm47.9 29.8H27.9c-.9 0-1.7.8-1.7 1.7v39.2c0 .9.8 1.7 1.7 1.7h69.4c.9 0 1.7-.8 1.7-1.7V49.3c0-1-.7-1.7-1.7-1.7z" />
        {RATE_PATH_MAP[rate]}
    </Ic>
);

IC32PbRate.displayName = 'IC32PbRate';

IC32PbRate.propTypes = {
    rate: propTypes.oneOf(['1', '1.5', '2', 'slo']),
};

export default IC32PbRate;
