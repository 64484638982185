import React from 'react';
import Ic from '..';

const IC173ContextualPlayPause = (args) => (
    <Ic {...args}>
        <path d="M49.128 7.397c-23.157 0-41.93 18.772-41.93 41.93 0 23.157 18.773 41.928 41.93 41.928 23.156 0 41.928-18.77 41.928-41.929 0-23.157-18.772-41.929-41.928-41.929m0 5.5c20.088 0 36.43 16.342 36.43 36.43 0 20.087-16.342 36.43-36.43 36.43s-36.43-16.343-36.43-36.43c0-20.088 16.342-36.43 36.43-36.43" />
        <path fillRule="nonzero" d="M28.266 60.501V38.035l14.972 11.233zM55.08 38.035h5.54v22.466h-5.54zm11.026 0h5.54v22.466h-5.54z" />
    </Ic>
);

IC173ContextualPlayPause.displayName = 'IC173ContextualPlayPause';

export default IC173ContextualPlayPause;
