import React, {cloneElement, useState} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';
import {A, Sup} from 'normalized-styled-components';
import get from 'lodash/get';

import {stylesWhenNot, stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {TERM_TYPE_SHORT} from '../../../../common/billing-constants';
import {termTypePropType} from '../../../../common/custom-proptypes';
import splitPrice from '../../../../common/split-price';
import {akela, blanc, vader, ares} from '../../../../common/palette';

import {
    Title as TM01Title,
    SubTitle as TM01SubTitle,
    DescWrapper as TM01DescWrapper,
} from '../01-vouch';
import CA01Feature from '../../../atoms/ca/01-feature';
import GA20PixelDiv from '../../../atoms/ga/20-pixel-div';
import GA89Tile from '../../../atoms/ga/89-tile';
import IC25ArrowR from '../../../atoms/ic/25-arrow-r';
import IC50Success from '../../../atoms/ic/50-success';

import CM03FeatureList from '../../cm/03-feature-list';

const StyledCA01Feature = styled(CA01Feature)`
    color: ${rgba(blanc, 0.7)};
    font: var(--ionic-package-inclusions);
`;

const PlanPrice = styled.span`
    position: relative;
    flex-shrink: 0;
    padding-left: 21px;
    color: ${blanc};
    font: var(--ionic-sub-price);
`;

const RegularPriceBadge = styled.span`
    display: block;
    color: ${rgba(akela, 0.7)};
    font: var(--ionic-voucher-terms);
`;

const IconWrapper = styled.span`
    display: block;
    width: 60px;
    line-height: 0;
    color: ${blanc};
`;

const CtaTextWrapper = styled.span`
    display: block;
    flex: 1 0 auto;
    text-align: right;
    color: ${rgba(akela, 0.7)};
    font: var(--ionic-body-copy-3-light);
    user-select: none;
`;

const StyledGA89Tile = styled(GA89Tile).attrs(
    ({isPressed}) => isPressed && {
        'aria-current': isPressed,
    }
)`
    display: block;
    border: 1px solid ${rgba(akela, 0.1)};
    border-radius: 6px;
    background-color: transparent;
    padding: 0;
    text-decoration: none;
    color: ${blanc};
    font: var(--ionic-voucher-terms);

    ${stylesWhen('hasAction')`
        &:hover {
            border-color: ${akela};
        }
    `}

    &:focus,
    &[aria-current='true'] {
        border-color: ${akela};
        background-color: ${blanc};
        color: ${vader};

        /* stylelint-disable order/order,declaration-block-semicolon-newline-after,
           rule-empty-line-before,selector-type-case,selector-type-no-unknown,
           no-duplicate-selectors */
        ${PlanPrice},
        ${RegularPriceBadge},
        ${IconWrapper},
        ${CtaTextWrapper},
        ${TM01Title},
        ${TM01SubTitle} {
            color: inherit;
        }
        ${StyledCA01Feature},
        ${TM01DescWrapper} {
            color: ${rgba(vader, 0.7)};
        }
        /* stylelint-enable order/order,declaration-block-semicolon-newline-after,
           rule-empty-line-before,selector-type-case,selector-type-no-unknown */

        ${GA20PixelDiv} {
            border-color: ${akela};
        }
    }
`;

const CurrentPlan = styled.section`
    padding: ${(props) => (props.isShortForm ? '14px' : '21px')} 14px;
`;

const PlanDetails = styled.div`
    display: flex;
    position: relative;
    margin: 8px 0 0;
`;

const PlanDescription = styled.div`
    flex-grow: 1;
`;

const PlanName = styled.h1`
    display: block;
    margin-top: 0;
    margin-bottom: 7px;
    text-transform: uppercase;
    font: var(--ionic-package-name);
`;

const PlanPriceFraction = styled(Sup)`
    position: absolute;
    top: 3px;
    font: var(--ionic-billing-frequency);
`;

const StyledCM03FeatureList = styled(CM03FeatureList)`
    margin-left: 4px;
`;

const TermWrapper = styled.span`
    font: var(--ionic-billing-frequency);
`;

const IconCtaWrapper = styled.span`
    display: flex;

    ${stylesWhenNot('hasNameOrPrice')`
        align-items: center;
    `}
`;

const StyledIC25ArrowR = styled(IC25ArrowR)`
    margin-bottom: -2px;
    margin-left: 8px;
`;

const ChildWrapper = styled.span`
    display: block;
`;

const TM10SubTile = ({
    children,
    className,
    ctaText,
    currencyDecimalPlaces = 2,
    currencyPrefix = '$',
    displayName,
    href,
    icon,
    isPressed,
    features = [],
    price = {},
    onClick,
    shouldShowPrice = true,
    ...htmlAttributes
}) => {
    const {termType, displayRegularAmount, displayAmount} = price;
    const [priceIntegers, priceDecimals] = splitPrice(displayAmount, currencyDecimalPlaces);
    const termTypeShort = get(TERM_TYPE_SHORT, termType);
    const hasNameOrPrice = displayAmount >= 0 || !!displayName;
    const hasAction = !!(href || onClick);
    const hasPlanDescription = !!(displayName || features.length);
    const hasPlanPrice = displayAmount >= 0;
    const hasPlanDetails = hasPlanDescription || hasPlanPrice;
    const [hasFocus, setHasFocus] = useState(false);

    return (
        <StyledGA89Tile
            {...htmlAttributes}
            as={hasAction ? A : undefined}
            href={href}
            className={classnames('TM10SubTile', className)}
            isPressed={isPressed}
            hasAction={hasAction}
            onClick={onClick}
            onFocus={() => setHasFocus(true)}
            onBlur={() => setHasFocus(false)}
        >
            <CurrentPlan isShortForm={!hasPlanDetails}>
                {!!(icon || ctaText) && (
                    <IconCtaWrapper hasNameOrPrice={hasNameOrPrice}>
                        {!!icon && (
                            <IconWrapper>
                                {cloneElement(icon, {isMesh: hasFocus})}
                            </IconWrapper>
                        )}
                        {!!ctaText && hasAction && (
                            <CtaTextWrapper>
                                {ctaText}
                                <StyledIC25ArrowR
                                    size="14px"
                                    display="inline-block"
                                />
                            </CtaTextWrapper>
                        )}
                    </IconCtaWrapper>
                )}
                {hasPlanDetails && (
                    <PlanDetails>
                        {hasPlanDescription && (
                            <PlanDescription>
                                {!!displayName && (
                                    <PlanName>
                                        {displayName}
                                    </PlanName>
                                )}
                                {!!features.length && (
                                    <StyledCM03FeatureList isBlock={true} listGapSet={[{breakpoint: 0, gapPx: 0}]}>
                                        {features.map(({title, a11yTitle, value, a11yValue, shouldNotShowTitle = false}) => (
                                            <StyledCA01Feature
                                                key={`${title}__${value}`}
                                                icon={<IC50Success color={ares} size="14px" />}
                                                {...(!!(a11yTitle || a11yValue) && ({
                                                    'aria-label': `${a11yTitle}: ${a11yValue}`,
                                                }))}
                                            >
                                                {shouldNotShowTitle ? value : `${title}: ${value}`}
                                            </StyledCA01Feature>
                                        ))}
                                    </StyledCM03FeatureList>
                                )}
                            </PlanDescription>
                        )}

                        {!!shouldShowPrice && hasPlanPrice && (
                            <PlanPrice
                                aria-label={
                                    displayAmount > 0
                                        ? `${currencyPrefix}${displayAmount} per ${termType}`
                                        : undefined
                                }
                            >
                                {displayAmount ? (
                                    <React.Fragment>
                                        {currencyPrefix}{priceIntegers}

                                        {!!priceDecimals && (
                                            <PlanPriceFraction>
                                                .{priceDecimals}
                                            </PlanPriceFraction>
                                        )}

                                        {!!termTypeShort && (
                                            <TermWrapper>
                                                /{termTypeShort}
                                            </TermWrapper>
                                        )}

                                        {displayAmount === displayRegularAmount && (
                                            <RegularPriceBadge>
                                                Regular Price
                                            </RegularPriceBadge>
                                        )}
                                    </React.Fragment>
                                ) : (
                                    'Free'
                                )}
                            </PlanPrice>
                        )}
                    </PlanDetails>
                )}
            </CurrentPlan>

            {React.Children.map(children, (child) => {
                if (child === null) {
                    return null;
                }

                return (
                    <ChildWrapper key={child.key}>
                        <GA20PixelDiv />
                        {child}
                    </ChildWrapper>
                );
            })}
        </StyledGA89Tile>
    );
};

TM10SubTile.propTypes = {
    /** Children */
    children: propTypes.node,
    /** Optional additional class(es) */
    className: classNameType,
    /** The whole thing is a CTA, but put the pertinent CTA text here */
    ctaText: propTypes.string,
    /** Whether, and how many decimal places to display */
    currencyDecimalPlaces: propTypes.number,
    /** What currency symbol to use */
    currencyPrefix: propTypes.oneOf(['$']),
    /** Name of the package */
    displayName: propTypes.string,
    /** Link to go to */
    href: propTypes.string,
    /** Icon */
    icon: propTypes.node,
    /** is active/selected */
    isPressed: propTypes.bool,
    /** Package features */
    features: propTypes.arrayOf(propTypes.shape({
        title: propTypes.node,
        a11yTitle: propTypes.string,
        value: propTypes.node,
        a11yValue: propTypes.string,
        /** Should feature title not be displayed */
        shouldNotShowTitle: propTypes.bool,
    })),
    /** Package pricing, right now we’re pulling out month specifically */
    price: propTypes.shape({
        termType: termTypePropType,
        displayRegularAmount: propTypes.number,
        displayAmount: propTypes.number,
    }),
    /** Should package price be displayed */
    shouldShowPrice: propTypes.bool,
    /** Click handler for parent node */
    onClick: propTypes.func,
};

TM10SubTile.displayName = 'TM10SubTile';

export default TM10SubTile;
