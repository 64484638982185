import React from 'react';
import Ic from '..';

const IC71Thtr = (args) => (
    <Ic {...args}>
        <path d="M77.6 80.3H22.4c-.8 0-1.4-.6-1.4-1.4v-6.6h2.8v5.2h52.5v-5.2H79v6.6c0 .8-.6 1.4-1.4 1.4zM91.6 68.7H8.4c-1.5 0-2.8-1.2-2.8-2.8V21.1c0-1.5 1.2-2.8 2.8-2.8h83.3c1.5 0 2.8 1.2 2.8 2.8v44.8c-.1 1.5-1.3 2.8-2.9 2.8zm-80.5-5.6h77.7V23.8H11.1v39.3z" />
    </Ic>
);

IC71Thtr.displayName = 'IC71Thtr';

export default IC71Thtr;
