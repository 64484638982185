import React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {ink, black, hubblGreen} from '../../../../common/palette';

const RibbonWrapper = styled.span`
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    transform: rotate(45deg) translate(40px, -10px);
    background-color: ${hubblGreen};
    padding: 2px 21px 1px;
    width: 110px;
    overflow-x: hidden;
    text-align: center;
    text-transform: uppercase;
    font: var(--quicksilver-ribbon-text);
`;

const RibbonMask = styled.span`
    display: block;
    padding: 0 14px 0 20px;
    min-height: 17px;
    overflow-x: hidden;
    white-space: nowrap;
    color: ${ink};
    mask-image: linear-gradient(to left, transparent 14px, ${black} 21px);
`;

const GA125PromoRibbon = ({
    children = 'Applied',
    className,
}) => (
    <RibbonWrapper className={classnames('GA125PromoRibbon', className)}>
        <RibbonMask>{children}</RibbonMask>
    </RibbonWrapper>
);

GA125PromoRibbon.displayName = 'GA125PromoRibbon';

GA125PromoRibbon.propTypes = {
    /** additional CSS classnames to be applied */
    className: classNameType,
    /** text on the ribbon */
    children: propTypes.node,
};

export default GA125PromoRibbon;
