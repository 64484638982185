import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import {Section, Sup} from 'normalized-styled-components';
import classnames from 'classnames';

import {mediaQuery, stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';
import {SCREEN_LG_DESKTOP} from '../../../../common/screen-sizes';
import {PERIOD_SHORT} from '../../../../common/billing-constants';
import {billingPeriodType} from '../../../../common/custom-proptypes';
import {white, midnight, kayoGreen} from '../../../../common/palette';
import splitPrice from '../../../../common/split-price';

import IC31Tick from '../../ic/31-tick';

const StyledIC31Tick = styled(IC31Tick)`
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    transition: ${transition('opacity')};
    opacity: 0;
    width: 23px;
    height: 23px;

    ${mediaQuery({minWidthPx: SCREEN_LG_DESKTOP})`
        width: 30px;
        height: 30px;
    `}
`;

const StyledSection = styled(Section)`
    appearance: none;
    display: flex;
    position: relative;
    flex-direction: column;
    transition: ${transition('background-color', 'color')};
    border: 1px solid ${rgba(kayoGreen, 1)};
    box-shadow: 0 1px 1px 0 ${rgba(midnight, 0.3)};
    background: transparent;
    padding: 29px 0;
    min-width: 138px;
    text-align: center;
    text-shadow: 0 1px 1px ${rgba(midnight, 0.3)};
    color: ${white};

    ${stylesWhen('isActive')`
        background-color: ${white};
        text-shadow: none;
        color: ${midnight};

        ${StyledIC31Tick} {
            opacity: 1;
        }

        &::before {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            border-width: 12px;
            border-style: solid;
            border-color: ${kayoGreen} transparent transparent ${kayoGreen};
            content: '';
        }
    `}
`;

const PackagePrice = styled.div`
    position: inherit;
    margin: auto;
    font: var(--mui-header-1-bold);
`;

const PackageName = styled.h3`
    margin: 0;
    text-transform: uppercase;
    font: var(--mui-body-copy-3);
`;

const SupContainer = styled(Sup)`
    position: absolute;
    top: 2px;
`;

const DisplayMantissa = styled.span`
    font: var(--mui-body-copy-3);
`;

const FootnoteMarker = styled.span`
    position: absolute;
    padding-left: 2px;
    font: var(--mui-footnote-marker-2);
`;

const TermType = styled.span`
    font: var(--mui-body-copy-3);
`;

const BA44OfferBtn = ({
    currencyDecimalPlaces = 2,
    currencyPrefix = '$',
    isActive,
    name,
    displayAmount,
    termType,
    className,
    footnoteMarker,
    ...htmlAttributes
}) => {
    const [displayAmountCharacteristic, displayAmountMantissa] = splitPrice(displayAmount, currencyDecimalPlaces);
    const termTypeShort = PERIOD_SHORT[termType];

    return (
        <StyledSection
            {...htmlAttributes}
            {...{
                isActive,
            }}
            className={classnames('BA44OfferBtn', className)}
        >

            {!!name && (
                <PackageName>{name}</PackageName>
            )}
            <PackagePrice hasMantissa={!!displayAmountMantissa} aria-label={displayAmount ? `${currencyPrefix}${displayAmount} per ${termType}` : undefined}>
                {displayAmount ? (
                    <React.Fragment>
                        {currencyPrefix}{displayAmountCharacteristic}

                        {(!!displayAmountMantissa || !!footnoteMarker) && (
                            <SupContainer>
                                {!!displayAmountMantissa && (
                                    <DisplayMantissa>
                                        .{displayAmountMantissa}
                                    </DisplayMantissa>
                                )}

                                {!!footnoteMarker && (
                                    <FootnoteMarker>
                                        {footnoteMarker}
                                    </FootnoteMarker>
                                )}
                            </SupContainer>
                        )}

                        {!!termTypeShort && (
                            <TermType>
                                /{termTypeShort}
                            </TermType>
                        )}
                    </React.Fragment>
                ) : 'Free'}
            </PackagePrice>
            <StyledIC31Tick />
        </StyledSection>
    );
};

BA44OfferBtn.displayName = 'BA44OfferBtn';

BA44OfferBtn.propTypes = {
    /** Currency decimal places */
    currencyDecimalPlaces: propTypes.number,
    /** Currency prefix */
    currencyPrefix: propTypes.oneOf(['$']),
    /** Is the package you are up/downgrading to */
    isActive: propTypes.bool,
    /** Name of the package */
    name: propTypes.string,
    /** Package price */
    displayAmount: propTypes.number,
    /** Footnote marker next to price like `*, ^, ~ or #` */
    footnoteMarker: propTypes.string,
    /** Package term duration */
    termType: billingPeriodType,
    /** additional CSS classnames to be applied */
    className: classNameType,
};

export default BA44OfferBtn;
