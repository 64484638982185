import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';

import Ic from '..';

import {ink} from '../../../../common/palette';

const IC328TelstraLogoCntnr = ({
    secondaryColor = ink,
    ...args
}) => (
    <Ic {...args}>
        <g transform="translate(10 10)">
            <ellipse cx="39.787" cy="40" fillRule="nonzero" rx="39.787" ry="40" />
            <g fill={secondaryColor}>
                <path d="m62.5 15-1.311 8.09c-.22 1.35-1.402 2.343-2.79 2.343h-9.074l-4.786 27.594c-.232 1.337-1.408 2.314-2.785 2.314H30.72l5.188-29.908H22.95l1.311-8.09c.22-1.35 1.402-2.343 2.79-2.343H62.5z" />
                <path d="M15.157 34.26c3.059-3.59 9.723-3.427 17.105-.205l-4.055 23.372h13.547l.172-.003c2.334-.078 4.305-1.763 4.701-4.046l1.401-8.07c7.849 8.267 11.062 17.461 7.165 22.035-4.583 5.379-17.26 2.332-28.315-6.803-11.056-9.136-16.303-20.902-11.721-26.28z" />
            </g>
        </g>
    </Ic>
);

IC328TelstraLogoCntnr.propTypes = {
    /** The icon's secondary color */
    secondaryColor: colorType,
};

IC328TelstraLogoCntnr.displayName = 'IC328TelstraLogoCntnr';

export default IC328TelstraLogoCntnr;
