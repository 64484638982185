import React from 'react';
import propTypes from 'prop-types';

import Ic from '..';

const IC04Vol = ({volumePercentage = 0.5, ...args}) => (
    <Ic {...args}>
        <path d="M51 17.4c-2-.9-4.3-.7-6 .7L26.5 32.9H16c-3.1 0-5.6 2.5-5.6 5.6v23c0 3.1 2.5 5.6 5.6 5.6h10.5L45 81.9c1 .8 2.3 1.2 3.5 1.2.8 0 1.7-.2 2.5-.6 2-.9 3.2-2.9 3.2-5.1v-55c0-2.1-1.2-4.1-3.2-5zm-2.3 60.1l-.1.1L30.3 63v-8.2h-5.6v6.8H16c-.1 0-.1 0-.1-.1v-23c0-.1 0-.1.1-.1h8.7v6.8h5.6V37l18.2-14.6.1.1v55z" />
        <path d="M65.3 34.2l-2.1 3.6c4.3 2.5 7 7.2 7 12.2 0 5-2.7 9.7-7 12.2l2.1 3.6c5.6-3.3 9.1-9.3 9.1-15.8S71 37.5 65.3 34.2z" opacity={volumePercentage > 0.5 ? 1 : volumePercentage + 0.5} />
        <path d="M75.8 26.5L73 31.3c6.6 3.8 10.7 11 10.7 18.6S79.6 64.7 73 68.5l2.8 4.8c8.3-4.8 13.5-13.8 13.5-23.4 0-9.7-5.2-18.6-13.5-23.4z" opacity={volumePercentage <= 0.5 ? 0 : volumePercentage} />
    </Ic>
);

IC04Vol.displayName = 'IC04Vol';

IC04Vol.propTypes = {
    volumePercentage: propTypes.number,
};
export default IC04Vol;
