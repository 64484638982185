import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import IA05InputHelp from '../../../atoms/ia/05-input-help';
import IA07InputPre from '../../../atoms/ia/07-input-pre';
import {blanc} from '../../../../common/palette';

const InputContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    color: ${blanc};
`;

const IM05InputPre = React.forwardRef(({
    contextualHelp,
    defaultValue,
    label,
    onChange,
    ...htmlAttributes
}, ref) => (
    <InputContainer className="IM05InputPre">
        <IA07InputPre
            {...{
                defaultValue,
                label,
                onChange,
                ...htmlAttributes,
            }}
            ref={ref}
        />
        {!!contextualHelp && (
            <IA05InputHelp>
                {contextualHelp}
            </IA05InputHelp>
        )}
    </InputContainer>
));

IM05InputPre.propTypes = {
    /** Represents a validation warning, with contextual help */
    contextualHelp: propTypes.node,
    /** Passed into atom IA07InputPre, for its attribute value */
    defaultValue: propTypes.string,
    /** Passed into atom IA07InputPre, for its label text */
    label: propTypes.node,
    /** Passed into atom IA07InputPre, for what to do as input changes value */
    onChange: propTypes.func,
};

IM05InputPre.displayName = 'IM05InputPre';

export default IM05InputPre;
