import React from 'react';

import Ic from '..';

const IC71Thtr = (args) => (
    <Ic {...args}>
        <path fillOpacity=".35" d="M8.886 18h83v54h-83z" />
        <path fillRule="nonzero" d="M83.474 14c6.627 0 12 5.373 12 12v37c0 6.627-5.373 12-12 12h-66c-6.627 0-12-5.373-12-12V26c0-6.627 5.373-12 12-12h66zm0 4h-66a8 8 0 0 0-7.996 7.75l-.004.25v37a8 8 0 0 0 7.75 7.996l.25.004h66a8 8 0 0 0 7.996-7.75l.004-.25V26a8 8 0 0 0-7.75-7.996l-.25-.004zm-54 61v2.968h42.789V79h4v6.968H25.475V79z" />
    </Ic>
);

IC71Thtr.displayName = 'IC71Thtr';

export default IC71Thtr;
