import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {Section} from '../../../../common/normalized-styled-components';
import {ink, white} from '../../../../common/palette';

const StyledContainer = styled(Section)`
    box-sizing: border-box;
    display: grid;
    border-radius: 6px;
    background-color: ${white};
    min-width: 333px;
    overflow: hidden;
`;

const ButtonWrapper = styled.div`
    display: grid;
    border-top: 1px solid ${rgba(ink, 0.1)};
`;

/**
 * OR153PaymentMethodBlk
 *  - Used in My Account and Checkout
 *
 * @see {@link https://zpl.io/GEgPM6r}
 */
const OR153PaymentMethodBlk = ({
    children,
    className,
    paymentTile,
    ...htmlAttributes
}) => (
    <StyledContainer
        {...htmlAttributes}
        className={classnames('OR153PaymentMethodBlk', className)}
    >
        {paymentTile}

        {React.Children.map(children, (child) => (
            <ButtonWrapper>
                {child}
            </ButtonWrapper>
        ))}
    </StyledContainer>
);

OR153PaymentMethodBlk.propTypes = {
    /** cta buttons of component */
    children: propTypes.node,
    /** prop of component, e.g. payment tile*/
    paymentTile: propTypes.node,
    /** Additional class name(s) */
    className: classNameType,
};

OR153PaymentMethodBlk.displayName = 'OR153PaymentMethodBlk';

export default OR153PaymentMethodBlk;
