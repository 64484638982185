import React from 'react';

import {colorType} from '@fsa-streamotion/custom-prop-types';
import {white} from '../../../../common/palette';
import {midnight} from '../../../../common/deprecated-mui-palette';
import Ic from '..';

const IC88Beta = ({color = white, textColor = midnight, ...otherProps}) => (
    <Ic {...{...otherProps, color}}>
        <path d="M16.144 32h72.448c.768 0 1.408.7115385 1.408 1.5653846v.1423077l-3.84 33.8692308C86.032 68.4307692 85.392 69 84.752 69H11.408C10.64 69 10 68.2884615 10 67.4346154V67.15l4.864-33.8692308C14.864 32.5692308 15.504 32 16.144 32z" fill={color} />
        <path d="M35.951828 46.12444444c0 2.56-1.3075269 4.12444445-3.0073119 4.97777778 1.5690323.56888889 2.6150538 1.84888888 2.6150538 3.69777778 0 4.5511111-3.5303226 6.4-7.06064517 6.4H20L22.74580645 42h7.06064516c2.61505379 0 6.14537639.42666667 6.14537639 4.12444444zm-5.6223656 1.56444445c0-.85333333-.65376348-.99555556-1.30752692-.99555556H27.7144086l-.39225806 2.70222223h1.30752688c.91526882-.14222223 1.69978498-.56888889 1.69978498-1.70666667zm-.3922581 6.96888891c0-.9955556-.78451613-1.1377778-1.56903226-1.1377778h-1.83053763l-.39225807 2.9866667h1.83053764c1.17677419 0 1.96129032-.5688889 1.96129032-1.8488889zM39.0898925 42h12.0292473l-.7845161 4.97777778h-6.5376345l-.2615053 1.99111111h6.1453763l-.6537634 4.97777781h-6.1453764L42.6202151 56.08h6.6683871l-.7845162 4.9777778h-12.16L39.0898925 42zm13.0752688 0h13.5982796l-.6537635 4.97777778h-3.9225806L59.0950538 61.2h-5.7531183l2.092043-14.22222222h-3.9225807L52.1651613 42zm25.4967742 0L80.8 61.2h-6.0146237l-.2615053-2.7022222h-4.7070968L68.7707527 61.2h-5.7531183L71.6473118 42h6.0146237zm-6.1453764 11.6622222h2.4843011l-.5230107-4.97777776-1.9612904 4.97777776z" fill={textColor} />
    </Ic>
);

IC88Beta.propTypes = {
    color: colorType,
    textColor: colorType,
};
IC88Beta.displayName = 'IC88Beta';

export default IC88Beta;
