import styled, {css} from 'styled-components';
import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {
    SCREEN_1024_DESKTOP,
    SCREEN_1280_DESKTOP,
    SCREEN_1920_DESKTOP,
    SCREEN_2560_DESKTOP,
    SCREEN_768_TABLET,
    SMALLEST_SUPPORTED_WIDTH,
} from './screen-sizes';
import {A, Button} from './normalized-styled-components';
import {flash} from './palette';
import {transition} from './animations';

//  This is pulled straight from ionic's standard-tile-size.js
const TILE_PX_WIDTHS = {
    [SMALLEST_SUPPORTED_WIDTH]: 265,
    [SCREEN_768_TABLET]: 244,
    [SCREEN_1024_DESKTOP]: 266,
    [SCREEN_1280_DESKTOP]: 281,
    [SCREEN_1920_DESKTOP]: 344,
    [SCREEN_2560_DESKTOP]: 344,
};

/** CSS STYLES */

const baseCss = css`
    display: block;
    position: relative;
    width: 100%;
`;

const interactiveCss = css`
    &:hover,
    &:focus {
        transform: scale(1.07);
        outline: none;
    }
    /**
     * For the next couple of lines, Chrome and Edge does not play well when blur() and animation are used together
     * so we only target Firefox and Safari to have the animations
     * Taken from: https://www.browserstack.com/guide/create-browser-specific-css (this may change with further updates)
     */
    /* Firefox */
    /* stylelint-disable-next-line */
    @-moz-document url-prefix() {
        transition: ${transition('transform')};
    }
    /* Safari */
    /* stylelint-disable-next-line */
    @media not all and (min-resolution:.001dpcm){
        @supports (-webkit-appearance:none) and (stroke-color:transparent) {
            transition: ${transition('transform')};
        }
    }
`;

/**
 * We need to get total height of the hoverline container
 * so we can use it to adjust the carousel < > button to vertically centered
 */
export const hoverLineSizeStyles = css`
    --tile-utils-hoverline-height: 2px;
    --tile-utils-hoverline-margin-top: 7px;
    --tile-utils-hoverline-height-total: calc(var(--tile-utils-hoverline-height) + var(--tile-utils-hoverline-margin-top));
    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        --tile-utils-hoverline-height: 4px;
    `};
`;

/** CTA COMPONENTS */

const NonInteractive = styled.div`
    ${baseCss}
`;

const StyledButton = styled(Button)`
    ${baseCss}

    appearance: none;
    border: 0;
    background: transparent;
    padding: 0;
`;

const StyledLink = styled(A)`
    ${baseCss}

    text-decoration: inherit;
    color: inherit;
`;

/** TILE COMPONENTS */

export const StyledHoverLine = styled.div`
    ${hoverLineSizeStyles}
    visibility: hidden;
    margin: var(--tile-utils-hoverline-margin-top) 7px 0;
    background-color: ${flash};
    width: auto;
    height: var(--tile-utils-hoverline-height);
`;

export const StyledTile = styled.div`
    ${baseCss}
    ${interactiveCss}

    &:hover {
        ${StyledHoverLine} {
            visibility: visible;
        }
    }
`;

/** TILE DECORATORS - for story & vizard tests only */

export const TileWrapper = styled.div`
    width: ${TILE_PX_WIDTHS[SMALLEST_SUPPORTED_WIDTH]}px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: ${TILE_PX_WIDTHS[SCREEN_768_TABLET]}px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        width: ${TILE_PX_WIDTHS[SCREEN_1024_DESKTOP]}px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        width: ${TILE_PX_WIDTHS[SCREEN_1280_DESKTOP]}px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: ${TILE_PX_WIDTHS[SCREEN_1920_DESKTOP]}px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
        width: ${TILE_PX_WIDTHS[SCREEN_2560_DESKTOP]}px;
    `}
`;

/** TILE UTIL FUNCTIONS */

/**
 * Builds an object containing different widths for a single image URL
 *
 * @param {string} imageURL url to the image
 * @returns {Object} an object with keys related to width length
 */
export function getSrcSetOptions(imageURL) {
    return Object.keys(TILE_PX_WIDTHS)
        .reduce(
            (result, width) => (
                {
                    ...result,
                    [width]: `${imageURL}?$imwidth=${width}`,
                }
            ),
            {}
        );
}

/**
 * Get CTA component element
 *
 * @param {string} href url
 * @param {Function} onClick callback function
 * @returns {*} either a `StyledLink`, `StyledButton` or just a `NonInteractive` div
 */
export function getCtaType(href, onClick) {
    if (href) {
        return StyledLink;
    } else if (onClick) {
        return StyledButton;
    } else {
        return NonInteractive;
    }
}
