import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';

import zip from 'lodash/zip';

import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';
import {palette as infinityPalette} from '@fsa-streamotion/streamotion-web-infinity';
import {palette as ionicPalette} from '@fsa-streamotion/streamotion-web-ionic';
import {palette as muiPalette} from '@fsa-streamotion/streamotion-web-mui';
import {palette as nucleusPalette} from '@fsa-streamotion/streamotion-web-nucleus';

import OfferVideo from '../../common/offer-video';
import {loadingBaseStyles, loadingAnimationStyles} from '../loading-skeleton/constants';
import FixedWidthContainer from '../fixed-width-container';
import {SCREEN_768_TABLET, SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import brandPropType from '../../../../common/brand-proptype';
import {stylesWhenBinge, stylesWhenFlash, stylesWhenKayo, stylesWhenLifestyle} from '../../../../utils/styles-when-brand';
import isNoop from '../../../../utils/check-if-noop';

import {
    BrandedBA01BtnPr,
    BrandedBA46BtnPrMarketing,
    BrandedBA08BtnSec,
    BrandedIC103Loading,
    BrandedGA06PostImg,
    BrandedGM07SlidingPictures,
    BrandedOR08MarBlk,
} from '../../../../utils/branded-components';

const ZINDEX_FOR_SIGN_IN = 2; // Because offer hero image is one itself, and we'll stack over it.

const {black} = infinityPalette;
const {vader} = ionicPalette;
const {coal, silverLining, slate} = muiPalette;
const {midnight} = nucleusPalette;

const StyledBrandedLoginBA01BtnPr = styled(BrandedBA01BtnPr)`
    position: absolute;
    top: 14px;
    right: 23px;
    z-index: ${ZINDEX_FOR_SIGN_IN};

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        top: 28px;
        right: 42px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        top: 43px;
        right: 56px;
    `}

    ${stylesWhenFlash`
        background-color: ${rgba(black, 0.2)};
    `}

    ${stylesWhenBinge`
        background-color: ${rgba(vader, 0.2)};
    `}

    ${stylesWhenLifestyle`
        background-color: ${rgba(midnight, 0.2)};
    `}

    ${stylesWhenKayo`
        border: 1px solid ${rgba(slate, 0.35)};
        background-color: ${rgba(coal, 0.35)};
        color: ${silverLining};

        &:hover {
            color: ${coal};
        }
    `}
`;

const StyledBrandedGA06PostImg = styled(BrandedGA06PostImg)`
    height: 100%;
`;

const StyledBrandedBA46BtnPrMarketing = styled(BrandedBA46BtnPrMarketing)`
    min-width: var(--acw-offer-button-width);
`;

const StyledBrandedBA28BtnSec = styled(BrandedBA08BtnSec)`
    min-width: var(--acw-offer-button-width);
`;

const BrandedFakeCtaButton = styled.div`
    ${loadingBaseStyles}
    ${loadingAnimationStyles}
    min-width: var(--acw-offer-button-width);
    height: 79px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        height: 96px;
    `}
`;

const Header = ({
    brand,
    marketingDisplay,
    offerDisplay,
    signInWords,
    displayResubscribeButton,
    onSignIn,
    onClickOfferCta,
    onResubscribe,
    isSignInLoading,
    isLoading,
    isVideoLoaded,
    setIsVideoLoaded,

    // just for vizard - binge
    brandLogoSrcsetOptions,
}) => (
    <BrandedOR08MarBlk
        brand={brand}
        brandLogoSrcsetOptions={brandLogoSrcsetOptions}
        title={marketingDisplay.title}
        poster={(
            <React.Fragment>
                {!!onSignIn && !isNoop(onSignIn) && (
                    <StyledBrandedLoginBA01BtnPr
                        onClick={onSignIn}
                        disabled={!!isSignInLoading}
                        brand={brand}
                    >
                        {isSignInLoading ? <BrandedIC103Loading size="30px" brand={brand} /> : signInWords}
                    </StyledBrandedLoginBA01BtnPr>
                )}

                <OfferVideo
                    srcLandscape={marketingDisplay.posterVideo}
                    srcPortrait={marketingDisplay.posterVideoMobile}
                    fallbackImages={(
                        <BrandedGM07SlidingPictures brand={brand}>
                            {zip(
                                marketingDisplay.posterImageSrcsetOptionsLandscapeArray,
                                marketingDisplay.posterImageSrcsetOptionsPortraitArray,
                            ).map(([
                                posterImageSrcsetOptionsLandscape,
                                posterImageSrcsetOptionsPortrait,
                            ], index) => (
                                <StyledBrandedGA06PostImg
                                    key={`${index}_${marketingDisplay.fallbackImgSrc}`}
                                    srcsetLandscape={posterImageSrcsetOptionsLandscape}
                                    srcsetPortrait={posterImageSrcsetOptionsPortrait}
                                    fallbackImgSrc={marketingDisplay.fallbackImgSrc}
                                    brand={brand}
                                />
                            ))}
                        </BrandedGM07SlidingPictures>
                    )}
                    isVideoLoaded={isVideoLoaded}
                    setIsVideoLoaded={setIsVideoLoaded}
                />
            </React.Fragment>
        )}
    >
        {isLoading
            ? <BrandedFakeCtaButton brand="flash" />
            : (
                <FixedWidthContainer>
                    <StyledBrandedBA46BtnPrMarketing
                        primaryText={offerDisplay.ctaHeader}
                        subText={offerDisplay.ctaSubtext}
                        href="#products"
                        isBlock={true}
                        onClick={onClickOfferCta}
                        brand={brand}
                    />
                    {!!displayResubscribeButton && !!onResubscribe && (
                        <StyledBrandedBA28BtnSec
                            onClick={onResubscribe}
                            isBlock={true}
                            id="resubscribe"
                            brand={brand}
                        >
                            {marketingDisplay.resubscribeButtonText}
                        </StyledBrandedBA28BtnSec>
                    )}
                </FixedWidthContainer>
            )
        }
    </BrandedOR08MarBlk>
);

Header.displayName = 'OfferHeader';

Header.propTypes = {
    /** brand name */
    brand: brandPropType,
    /** Marketing display config */
    marketingDisplay: propTypes.shape({
        /** Call to action text for OR08MarBlk */
        title: propTypes.string,

        /** Video sources for Offer header  */
        posterVideo: propTypes.string,
        posterVideoMobile: propTypes.string,

        /**  SrcSetOptions or Src for OR08MarBlk poster images (e.g. GM07SlidingPictures, GA06PostImg, or img element) */
        posterImageSrcsetOptionsLandscapeArray: propTypes.arrayOf(propTypes.object),
        posterImageSrcsetOptionsPortraitArray: propTypes.arrayOf(propTypes.object),
        fallbackImgSrc: propTypes.string,

        /** Resubscription button text */
        resubscribeButtonText: propTypes.node,
    }),
    offerDisplay: propTypes.shape({
        /** Primary text on BA46BtnPrMarketing button */
        ctaHeader: propTypes.string,
        /** Sub text on BA46BtnPrMarketing button */
        ctaSubtext: propTypes.string,
    }),
    /** SignIn words */
    signInWords: propTypes.string,
    /** Should show resubscription button or not */
    displayResubscribeButton: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    /** Callback on SignIn click */
    onSignIn: propTypes.func,
    /** Callback on Offer click */
    onClickOfferCta: propTypes.func,
    /** callback on resubscribe click */
    onResubscribe: propTypes.func,
    /** Is SignIn button loading? */
    isSignInLoading: propTypes.bool,
    /** Whether the page is in the loading state */
    isLoading: propTypes.bool,
    /** Is video loaded? */
    isVideoLoaded: propTypes.bool,
    /** Set video loaded state */
    setIsVideoLoaded: propTypes.func,
    /** brand logo img src (just for vizard of Binge) */
    brandLogoSrcsetOptions: propTypes.objectOf(propTypes.string),
};

export default Header;
