import React from 'react';

import Ic from '..';

const IC117SwipeD = (args) => (
    <Ic {...args}>
        <path fillOpacity=".35" d="M59 91c11.598 0 20.308-3.296 25.12-20.829C88.93 52.64 87.78 49.026 59 42.927a49.36 49.36 0 0 0-4.894 2.398c-1.934 1.086 5.519-26.702-5.88-23.919-8.46 2.066-3.929 31.447-5.59 33.726C39.733 59.12 38 63.764 38 69c0 12.15 9.402 22 21 22z" />
        <path fillRule="nonzero" d="m33.137 22.308-6.4 6.386V6h-4.193v22.89l-6.582-6.582L13 25.27l11.556 11.543L36.1 25.27zm53.925 30.687a6.875 6.875 0 0 0-6.875-6.876h-.475a6.987 6.987 0 0 0-3.298.867A7.057 7.057 0 0 0 66.185 43.8a7.099 7.099 0 0 0-6.4-3.927h-.154a8.063 8.063 0 0 0-2.655.657v-3.005c0-1.397 0-2.697.07-3.968.14-5.227.266-9.782-2.264-12.34a6.833 6.833 0 0 0-5.114-1.914 8.384 8.384 0 0 0-6.205 2.362 9.405 9.405 0 0 0-2.515 6.33v26.55a14.743 14.743 0 0 0-4.458 5.59c-3.619 8.007-.14 18.432 1.51 21.786l.083.181C39.83 85.666 43.477 93.1 63.74 93.1c16.377 0 21.226-14.868 21.603-23.42a6.89 6.89 0 0 0 1.72-4.402V52.995zm-7.35-2.683h.475a2.683 2.683 0 0 1 2.683 2.683v12.283a2.683 2.683 0 0 1-2.683 2.669h-.475a2.683 2.683 0 0 1-2.683-2.67V52.996a2.683 2.683 0 0 1 2.669-2.683h.014zm-19.815-6.247a2.865 2.865 0 0 1 2.795 2.865v1.202a39.56 39.56 0 0 0-5.856.894V46.44a3.046 3.046 0 0 1 3.06-2.376zM45.14 27.981a4.29 4.29 0 0 1 4.5-4.486 2.655 2.655 0 0 1 2.11.643c1.3 1.398 1.188 5.213 1.076 9.307 0 1.313-.07 2.683-.07 4.08v12.451c-1.397.35-3.032.769-4.807 1.244a21.94 21.94 0 0 0-2.809.894V27.981zm18.614 60.997c-17.65 0-20.416-5.59-21.898-8.65l-.084-.196c-1.313-2.655-4.387-11.71-1.397-18.166 1.51-3.326 4.346-5.506 8.678-6.68 8.873-2.375 12.577-2.878 14.114-2.948.181 1.872 0 4.388-1.132 5.058-3.172 1.845-14.533 6.68-14.631 6.722l1.677 3.843c.42-.182 11.682-4.975 15.064-6.987 4.192-2.418 3.284-8.888 3.004-10.439a2.501 2.501 0 0 0-.126-.559v-.154a2.935 2.935 0 0 1 5.856 0v20.864a2.935 2.935 0 0 1-5.856 0v-4.682l-4.192 1.915v4.793a2.865 2.865 0 0 1-2.795 2.795h-.125a2.865 2.865 0 0 1-2.795-2.795v-2.096l-4.192 1.928v.168a7.07 7.07 0 0 0 6.987 6.987h.125a7.07 7.07 0 0 0 5.94-3.27 6.987 6.987 0 0 0 4.052 1.286 7.099 7.099 0 0 0 6.987-6.247c.88.39 1.832.59 2.795.587h.475c.237.015.475.015.712 0-.922 5.701-4.29 16.923-17.243 16.923z" />
    </Ic>
);

IC117SwipeD.displayName = 'IC117SwipeD';

export default IC117SwipeD;
