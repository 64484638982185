import React from 'react';
import propTypes from 'prop-types';

import {
    BrandedCA02FeatureStacked,
    BrandedCM04FeatureBlock,
    BrandedOR49SumPnl,
} from '../../../utils/branded-components';

import {termTypePropType} from '../../../widgets/web-app-head/custom-prop-types';
import brandPropType from '../../../common/brand-proptype';

export default function PackageSummary({
    brand,
    shouldRenderBlank,
    shouldShowPrice,
    heading,
    secondaryText,
    summary,
    currencyDecimalPlaces,
    currencyPrefix,
    listItems,
    listItemsPreNode,
    listItemsPostNode,
    price,
    termType,
    footnoteMarker,
    features = [],
}) {
    if (shouldRenderBlank) {
        return null;
    }

    // remove the cross items, and remove the tick code (but keep the item)
    // some items are nodes so we have to sidestep them
    /* eslint-disable no-template-curly-in-string */
    const filteredListItems = listItems
        ?.map((listItem) => {
            if (typeof listItem !== 'string') {
                return listItem;
            }

            return listItem.includes('${cross}')
                ? undefined
                : listItem.replace('${tick}', '').trim();
        })
        ?.filter(Boolean);
    /* eslint-enable no-template-curly-in-string */

    return (
        <BrandedOR49SumPnl
            billingPeriod={termType}
            billingPrice={price}
            {...{
                brand,
                currencyDecimalPlaces,
                currencyPrefix,
                footnoteMarker,
                heading,
                secondaryText,
                listItems: filteredListItems,
                listItemsPostNode,
                listItemsPreNode,
                summary,
                shouldShowPrice,
            }}
        >
            {(!!features.length) && (
                <BrandedCM04FeatureBlock brand={brand}>
                    {features.map(({title, a11yTitle, value, a11yValue}) => (
                        <BrandedCA02FeatureStacked
                            key={`${title}__${value}`}
                            brand={brand}
                            {...{
                                title,
                                a11yTitle,
                                value,
                                a11yValue,
                            }}
                        />
                    ))}
                </BrandedCM04FeatureBlock>
            )}
        </BrandedOR49SumPnl>
    );
}

PackageSummary.displayName = 'PackageSummary';

PackageSummary.propTypes = {
    /** Brand name */
    brand: brandPropType,
    /** Should it show no summary */
    shouldRenderBlank: propTypes.bool,
    /** Should it show price */
    shouldShowPrice: propTypes.bool,
    /** Heading */
    heading: propTypes.string,
    /** Optional text to display for ad tier package */
    secondaryText: propTypes.string,
    /** Optional summary to display when the header is sticking or we want to show the summary for any other reason */
    summary: propTypes.node,
    /** Currency decimal places */
    currencyDecimalPlaces: propTypes.number,
    /** Currency prefix */
    currencyPrefix: propTypes.oneOf(['$']),
    /** Terms, other features, etc. */
    listItems: propTypes.arrayOf(propTypes.node),
    /** Node that goes above listItems */
    listItemsPreNode: propTypes.node,
    /** Node that goes below listItems */
    listItemsPostNode: propTypes.node,
    /** Billing price */
    price: propTypes.number,
    /** Billing period type eg. month */
    termType: termTypePropType,
    /** Footnote marker next to the price like `*, ^, ~ or #`
     *  <br>_NOTE: **Currently** only used in Kayo._
     */
    footnoteMarker: propTypes.string,
    /** The title, accessible title, value and accessible value of the package feature */
    features: propTypes.arrayOf(propTypes.shape({
        title: propTypes.node,
        value: propTypes.node,
        a11yTitle: propTypes.string,
        a11yValue: propTypes.string,
    })),
};
