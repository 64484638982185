export const PERIOD_SHORT = {
    day: 'day',
    week: 'wk',
    month: 'mth',
    annual: 'yr',
};

export const PERIOD_LONG = {
    day: 'day',
    week: 'week',
    month: 'month',
    annual: 'year',
};

export const PERIOD_ADJECTIVE = {
    day: 'daily',
    week: 'weekly',
    month: 'monthly',
    annual: 'annual',
};

export const GIFT_CARD_REDEEM_INFO_TEXT = 'Subscribed to more than one Hubbl service? Your Hubbl Gift Card credit applies automatically to whichever of your subscriptions is due to be billed next. You can’t choose which service to apply the Gift Card credit to. Gift Cards can only be applied to accounts that use a credit card';

export const TERM_TYPE_SHORT = { // eslint-disable-line import/prefer-default-export
    day: 'day',
    week: 'wk',
    month: 'mth',
    annual: 'yr',
};

export default {
    PERIOD_SHORT,
    PERIOD_LONG,
    PERIOD_ADJECTIVE,
    GIFT_CARD_REDEEM_INFO_TEXT,
    TERM_TYPE_SHORT,
};
