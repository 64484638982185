import React, {createRef} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import {rgba} from 'polished';
import noop from 'lodash/noop';

import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {Section} from '../../../../common/normalized-styled-components';
import {cloudy, ink} from '../../../../common/palette';
import IC103Loading from '../../../atoms/ic/103-loading';
import PackageRow from './common/package-row';
import FeatureColumnCells from './common/feature-column-cells';
import TableHeader from './common/table-header';
import PricingColumnCell from './common/pricing-column-cell';
import ColumnGroup from './common/column-sizing';
import usePackageSelector from './common/use-package-selector';

const Wrapper = styled(Section)`
    position: relative;
    width: 100%;
`;

const Table = styled.table`
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    color: ${ink};
    font: var(--quicksilver-body-copy-3);

    ${stylesWhen('isDisabled')`
        opacity: 0.3;
        cursor: not-allowed;
    `}
`;

const StyledTableBody = styled.tbody`
    border-bottom: 1px solid ${cloudy};
    background-color: ${rgba(ink, 0.1)};
`;

const LoadingWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
`;

/** ********************************************
 *                  MAIN
 ***********************************************/

const OR94PackageSelector = ({
    className,
    defaultSelectedPackageId,
    headers = [],
    isDeselectingAllowed = false,
    isDisabled,
    isLoading,
    onChange = noop,
    packages = [],
    ...htmlAttributes
}) => {
    const {
        onKeyDown,
        onPackageSelect,
        selectedPackageId,
    } = usePackageSelector({
        defaultSelectedPackageId,
        isDeselectingAllowed,
        isDisabled,
        isLoading,
        onChange,
    });

    const packagesWithRefs = packages.map(({ref, ...otherProps}) => ({
        ref: ref || createRef(),
        ...otherProps,
    }));

    return (
        <Wrapper
            {...htmlAttributes}
            className={classnames('OR94PackageSelector', className)}
        >
            <Table isDisabled={isDisabled || isLoading}>
                <ColumnGroup featueCount={headers.length} />
                <TableHeader headers={headers} />
                <StyledTableBody>
                    {packagesWithRefs.map(({
                        ref,
                        displayName,
                        secondaryText = '',
                        currencyPrefix = '$',
                        features,
                        prices,
                        tierId, // use this as packageId
                        currencyDecimalPlaces = 2,
                        footnoteMarker,
                    }, index) => (
                        <PackageRow
                            id={tierId}
                            key={`package-${index}-${tierId}`}
                            ref={ref}
                            isDisabled={isDisabled || isLoading}
                            onKeyDown={(event) => onKeyDown(event, tierId)}
                            onClick={() => onPackageSelect(tierId)}
                            aria-pressed={tierId === selectedPackageId}
                        >
                            <PricingColumnCell
                                currencyDecimalPlaces={currencyDecimalPlaces}
                                currencyPrefix={currencyPrefix}
                                displayName={displayName}
                                footnoteMarker={footnoteMarker}
                                prices={prices}
                                secondaryText={secondaryText}
                            />

                            <FeatureColumnCells
                                features={features}
                                isDisabled={isDisabled || isLoading}
                            />
                        </PackageRow>
                    ))}
                </StyledTableBody>
            </Table>
            {!!isLoading && (
                <LoadingWrapper>
                    <IC103Loading color={ink} />
                </LoadingWrapper>
            )}
        </Wrapper>
    );
};

OR94PackageSelector.propTypes = {
    /** className. */
    className: classNameType,
    /** If provided, the package with this ID will be selected by default on initial load. */
    defaultSelectedPackageId: propTypes.string,
    /** Whether deselecting a package is allowed. */
    isDeselectingAllowed: propTypes.bool,
    /** Whether a package is selectable. */
    isDisabled: propTypes.bool,
    /** Whether component is in loading state. */
    isLoading: propTypes.bool,
    /** Callback when user clicks on a package */
    onChange: propTypes.func,
    /** Table th headings. */
    headers: propTypes.arrayOf(propTypes.shape({
        a11yTitle: propTypes.string,
        title: propTypes.node,
    })),
    /** PackagesPricing Details. */
    packages: propTypes.arrayOf(propTypes.shape({
        currencyDecimalPlaces: propTypes.number,
        currencyPrefix: propTypes.string,
        displayName: propTypes.string,
        features: propTypes.arrayOf(propTypes.shape({
            a11yValue: propTypes.string,
            value: propTypes.node,
        })),
        footnoteMarker: propTypes.string,
        prices: propTypes.arrayOf(propTypes.shape({
            displayAmount: propTypes.number,
            displayRegularAmount: propTypes.number,
            termType: propTypes.string,
        })),
        secondaryText: propTypes.string,
        tierId: propTypes.string,
    })),
};

OR94PackageSelector.displayName = 'OR94PackageSelector';

export default OR94PackageSelector;
