import React from 'react';
import Ic from '..';

const IC11AirP = (args) => (
    <Ic {...args}>
        <path d="M91.7 16.7H8.3c-1.5 0-2.8 1.2-2.8 2.8v61.1c0 1.5 1.2 2.8 2.8 2.8h19.9v-5.6H11.1V22.2h77.8v55.6H72.4v5.6h19.2c1.5 0 2.8-1.2 2.8-2.8V19.4c0-1.5-1.2-2.7-2.7-2.7z" /><path d="M50.8 63.7c-.4-.5-1.3-.5-1.7 0l-18.8 25c-.2.3-.3.7-.1 1.1s.5.6.9.6h37.5c.4 0 .8-.2.9-.6s.1-.8-.1-1.1l-18.6-25z" />
    </Ic>
);

IC11AirP.displayName = 'IC11AirP';

export default IC11AirP;
