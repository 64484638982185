import React from 'react';
import Ic from '..';

const IC247FAQPlus = (args) => (
    <Ic {...args}>
        <path d="M87.516 45.605H52.91V11h-8.306v34.605H10v8.306h34.605v34.605h8.306V53.91h34.605z" />
    </Ic>
);

IC247FAQPlus.displayName = 'IC247FAQPlus';

export default IC247FAQPlus;
