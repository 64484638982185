import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {transition} from '../../../../common/animations';

const HudContainer = styled.dialog`
    position: static;
    transition: ${transition('transform')};
    margin: 0;
    border: none;
    background: transparent;
    padding: 0;
    height: 100%;
    pointer-events: all;

    &:not([open]) {
        /* Don't hide it when closed, so it can slide out */
        display: block;
        transform: translateX(100%);
        pointer-events: none;
    }
`;

const HudContentPositioner = styled.div`
    display: flex;
    flex-direction: row-reverse;
    height: 100%;
`;

const OR74HudTray = ({isVisible, tabList, tabPanels, className}) => (
    <HudContainer
        isVisible={isVisible}
        open={isVisible}
        className={classnames('OR74HudTray', className)}
    >
        <HudContentPositioner>
            {tabList}
            {tabPanels}
        </HudContentPositioner>
    </HudContainer>
);

OR74HudTray.displayName = 'OR74HudTray';

OR74HudTray.propTypes = {
    className: classNameType,
    tabList: propTypes.node,
    tabPanels: propTypes.node,
    isVisible: propTypes.bool,
};

export default OR74HudTray;
