import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import property from 'lodash/property';

import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';
import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {fadeInOut, transition} from '../../../../common/animations';
import CommonTransition from '../../../../common/common-transition';
import {satellite, white} from '../../../../common/palette';
import BA01BtnPr from '../../../atoms/ba/01-btn-pr';
import BA08BtnSec from '../../../atoms/ba/08-btn-sec';
import IC30Back from '../../../atoms/ic/30-back';
import IC103Loading from '../../../atoms/ic/103-loading';
import TM22SuccessTile from '../../../molecules/tm/22-success-tile';
import TM24AlertTile from '../../../molecules/tm/24-alert-tile';

export const Card = styled.section`
    box-sizing: border-box;
    grid-row: 1;
    /* We have extra bottom padding to accommodate for when mobile browser URL bars are in, and vh doesn't factor that in */
    padding-bottom: 60px;
    overflow-x: hidden;
    overflow-y: scroll;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;

    ${stylesWhen('theme.appDockBgSrc')`
        background: url(${property('theme.appDockBgSrc')});
    `}

    &::-webkit-scrollbar {
        display: none;
    }

    &[aria-hidden='true'] {
        pointer-events: none;
    }
`;

const AppDockCardHeader = styled.header`
    display: flex;
    align-items: center;
    padding: 14px 0 7px;
`;

const AppDockCardBackButton = styled.button`
    box-sizing: border-box;
    transition: ${transition('color')};
    outline: 0;
    border: 0;
    background: transparent;
    padding: 7px;
    width: 44px;
    height: 44px;
    color: ${satellite};

    &:hover,
    &:focus {
        color: ${white};
    }
`;

const AppDockCardHeaderText = styled.h3`
    flex-grow: 1;
    margin: 0;
    padding: 0 58px 0 14px;
    text-align: center;
    color: ${satellite};
    font: var(--mui-body-copy-3);
`;

const SuccessTile = styled(TM22SuccessTile).attrs({
    role: 'alert',
})`
    transform-origin: 50% 0;
    transition: ${transition('transform', 'opacity')};

    &.exit {
        transform: scale(1);
        opacity: 1;
    }

    &.exit-active {
        transform: scale(0.95);
        opacity: 0;
    }
`;

const Container = styled.div`
    position: relative;

    ${stylesWhen('hasPadding')`
        padding: 7px 14px;
    `}
`;

const ContentContainer = styled.div`
    color: ${white};
    font: var(--mui-body-copy-3);

    ${fadeInOut}
`;

const LOADING_ICON_SIZE = '60px';

const LoadingIconContainer = styled.div`
    display: flex;
    position: absolute;
    top: 72px;
    right: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    height: 200px;

    ${fadeInOut}
`;

const LoginMessage = styled.section`
    margin-top: 0;
    text-align: center;
    color: ${white};
    font: var(--mui-body-copy-3);

    ${fadeInOut}
`;

const ButtonContainer = styled.div`
    display: grid;
    grid-row-gap: 7px;
    margin: 0 auto;
    max-width: 250px;
    text-align: center;
`;

const LoginMessageHeading = styled.h4`
    margin: 0;
    padding: 35px 0;
    font: var(--mui-header-3-bold);
`;

const ErrorMessageContainer = styled.div`
    margin: 0 auto 14px;
    width: 100%;
    max-width: 565px;
`;

export default class OR108AppDockCard extends React.Component {
    static displayName = OR108AppDockCard;

    static propTypes = {
        /** Is this the first card in the stack? If so, don't show the header. */
        isFirst: propTypes.bool,
        /** Is this the currently selected card? */
        isCurrent: propTypes.bool,
        /** Does the card have padding? */
        hasPadding: propTypes.bool,
        /** Title */
        title: propTypes.string,
        /** Back button click handler */
        onClickBack: propTypes.func,
        /** Is the content loading? */
        isLoading: propTypes.bool,
        /** Is the user logged in? */
        isLoggedIn: propTypes.bool,
        /** Does the current user need to be logged in to view the content? */
        isLoginRequired: propTypes.bool,
        /** Success message to display */
        message: propTypes.string,
        /** Is the success message visible? */
        isMessageVisible: propTypes.bool,
        /** Additional class(es) */
        className: classNameType,
        /** Content */
        children: propTypes.node,
        /** Short message to display if login is required and the user isn't logged in */
        notLoggedInMessage: propTypes.string,
        /** Text to display on the offers button */
        offersCta: propTypes.string,
        /** Link to the offers page */
        offersHref: propTypes.string,
        /** Offers button/link click handler */
        onClickOffers: propTypes.func,
        /** Text to display on the login button/link */
        loginCta: propTypes.string,
        /** Link to the login page */
        loginHref: propTypes.string,
        /** Generic error message that replaces all content */
        errorMessage: propTypes.node,
        /** Login button/link click handler */
        onClickLogin: propTypes.func,
    };

    static defaultProps = {
        hasPadding: true,
        isLoggedIn: true,
        isLoginRequired: true,
        notLoggedInMessage: 'You are not signed in!',
        offersCta: 'Start your free trial now',
        offersHref: '/',
        loginCta: 'Sign in',
        loginHref: '/login',
        errorMessage: '',
    };

    componentDidUpdate(prevProps) {
        // When this card is made the currently selected card, scroll its contents to the top.
        if (!prevProps.isCurrent && this.props.isCurrent) {
            this.cardRef.current.scrollTo(0, 0);
        }
    }

    cardRef = React.createRef();

    render() {
        const {
            isFirst,
            isCurrent,
            hasPadding,
            title,
            onClickBack,
            isLoading,
            isLoggedIn,
            isLoginRequired,
            message,
            isMessageVisible,
            className,
            children,
            notLoggedInMessage,
            offersCta,
            offersHref,
            onClickOffers,
            loginCta,
            loginHref,
            errorMessage,
            onClickLogin,
            ...htmlAttributes
        } = this.props;

        return (
            <Card
                ref={this.cardRef}
                {...htmlAttributes}
                aria-hidden={!isCurrent}
                className={classnames('OR108AppDockCard', className)}
            >
                <CommonTransition in={!!isMessageVisible}>
                    <SuccessTile message={message} />
                </CommonTransition>

                {!isFirst && (
                    <AppDockCardHeader>
                        <AppDockCardBackButton onClick={onClickBack} aria-label="Back">
                            <IC30Back size="16px" />
                        </AppDockCardBackButton>
                        <AppDockCardHeaderText>
                            {title}
                        </AppDockCardHeaderText>
                    </AppDockCardHeader>
                )}

                <Container hasPadding={hasPadding}>
                    <CommonTransition in={isLoading}>
                        <LoadingIconContainer>
                            <IC103Loading
                                className="IC103Loading"
                                size={LOADING_ICON_SIZE}
                            />
                        </LoadingIconContainer>
                    </CommonTransition>

                    <CommonTransition in={!!errorMessage}>
                        <ErrorMessageContainer>
                            <TM24AlertTile>
                                {errorMessage}
                            </TM24AlertTile>
                        </ErrorMessageContainer>
                    </CommonTransition>

                    <CommonTransition in={!errorMessage && !isLoading}>
                        {isLoggedIn || !isLoginRequired ? (
                            <ContentContainer>
                                {children}
                            </ContentContainer>
                        ) : (
                            <LoginMessage>
                                <LoginMessageHeading>
                                    {notLoggedInMessage}
                                </LoginMessageHeading>

                                <ButtonContainer>
                                    {!!offersCta && (
                                        <BA01BtnPr
                                            isBlock={true}
                                            href={offersHref}
                                            onClick={onClickOffers}
                                        >
                                            {offersCta}
                                        </BA01BtnPr>
                                    )}

                                    {!!loginCta && (
                                        <BA08BtnSec
                                            isBlock={true}
                                            href={loginHref}
                                            onClick={onClickLogin}
                                        >
                                            {loginCta}
                                        </BA08BtnSec>
                                    )}
                                </ButtonContainer>
                            </LoginMessage>
                        )}
                    </CommonTransition>
                </Container>
            </Card>
        );
    }
}
