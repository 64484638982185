import React from 'react';
import propTypes from 'prop-types';
import {rgba} from 'polished';
import {Button, A} from 'normalized-styled-components';
import styled, {css} from 'styled-components';
import noop from 'lodash/noop';
import classnames from 'classnames';

import {classNameType} from '@fsa-streamotion/custom-prop-types';

import {coal, white, kayoGreen} from '../../../../common/palette';
import {transition} from '../../../../common/animations';

const baseStyle = css`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: ${transition('border-color')};
    border: 2px solid ${({isSelected}) => isSelected ? rgba(kayoGreen, 0.35) : 'transparent'};
    border-radius: 4px;
    background-color: ${rgba(coal, 0.6)};
    padding: 0 42px;
    min-width: 160px;
    height: 50px;
    color: ${white};
    font: var(--mui-body-copy-1-bold);

    &:hover,
    &:focus {
        outline: 0;
        border-color: ${kayoGreen};
    }
`;

const StyledButton = styled(Button).attrs(({isSelected}) => ({
    'type': 'button',
    'aria-pressed': isSelected,
}))`
    ${baseStyle};
    appearance: none;
`;

const StyledLink = styled(A).attrs(({isSelected}) => ({
    'aria-current': isSelected,
}))`
    ${baseStyle};
    text-decoration: none;
`;

const BA02FilBtn = React.forwardRef(({
    children,
    href,
    isSelected,
    className,
    onClick = noop,
    ...htmlAttributes
}, ref) => {
    const Cta = href ? StyledLink : StyledButton;

    return (
        <Cta
            {...htmlAttributes}
            {...{
                ref,
                href,
                onClick,
                isSelected,
            }}
            className={classnames('BA02FilBtn', className)}
        >
            {children}
        </Cta>
    );
});

BA02FilBtn.propTypes = {
    /** Content of button, e.g. text */
    children: propTypes.node.isRequired,
    /** Hyperlink value. Providing this will cause the "button" to render as an anchor <a> instead */
    href: propTypes.string,
    /** Is this element in a "selected" state? E.g. filter currently applied */
    isSelected: propTypes.bool,
    /** Callback on element click */
    onClick: propTypes.func,
    /** Additional CSS class(es) */
    className: classNameType,
};

BA02FilBtn.displayName = 'BA02FilBtn';

export default BA02FilBtn;
