/**
 * Creates a function that is restricted to invoking provided function once.
 * Any subsequent calls while the initial request is still pending will be resolved when the first call has resolved.
 * Order of invocation and resolution is first-in-first-out.
 *
 * @param {(...args?: unknown[]) => Promise<unknown>} callback - The function to invoke and resolve once
 * @returns {(...args?: unknown[]) => Promise<unknown>} - The value resolved from the initial function invocation
 */
function singlePromise(callback) {
    let isFetching = false;
    let pendingRequests = [];

    const resolveAndClearPending = async (...args) => {
        let value;
        let error;

        try {
            value = await callback(...args);
        } catch (err) {
            error = err;
        }

        for (const {resolve, reject} of pendingRequests) {
            if (value) {
                resolve(value);
            } else {
                reject(error);
            }
        }

        isFetching = false;
        pendingRequests = [];
    };

    return (...args) => new Promise((resolve, reject) => {
        pendingRequests.push({resolve, reject});

        if (isFetching) {
            return;
        }

        isFetching = true;

        return resolveAndClearPending(...args);
    });
}

export default singlePromise;
